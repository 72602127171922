import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };
    constructor(private router: Router, private snackBar: MatSnackBar) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let data = JSON.parse(sessionStorage.getItem('currentUser'));
        if (data) {
            const tokenReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${data.token}`, 'Cache-Control': 'no-cache',
                    Pragma: 'no-cache', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
                }
            });
            return next.handle(tokenReq).pipe(
                map((event: HttpEvent<any>) => {
                    // if (event instanceof HttpResponse) {
                    // }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.showError(error)
                    return throwError(error);
                })
            );
        }
        else {
            const tokenReq = req.clone({
                setHeaders: { 'Content-Type': 'application/json' }
            });
            return next.handle(tokenReq).pipe(
                map((event: HttpEvent<any>) => {
                    // if (event instanceof HttpResponse) {
                    // }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    // this.showError(error)
                    this.UnauthorizedMAC(error);
                    return throwError(error);
                })
            );
        }
    }

  private showError(err: any) {
    console.log("err.status", err.status);
        if (err.status == 401) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: 'Your Session has been expired, Please login Again.', ...this.configSuccess });
            this.router.navigate(['/UserLogout']);
        } 

    }
    private UnauthorizedMAC(err: any) {
        if (err.status == 401) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: 'User Not Authorized to Use This Machine', ...this.configSuccess });
            this.router.navigate(['Page403Error']);
        } 
    }
}
