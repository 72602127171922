import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import {
  float,
  FLOAT,
} from "html2canvas/dist/types/css/property-descriptors/float";
declare var $: any;

@Component({
  selector: "app-rptreport-configuration",
  templateUrl: "./rptreport-configuration.component.html",
  styleUrls: ["./rptreport-configuration.component.scss"],
})
export class RptreportConfigurationComponent implements OnInit {
  private _MasterService;
  showSpinner: boolean = false;
  loading: boolean = false;
  Id: any = 0;
  slabName: any;
  currentUser: any;
  JsonData: any[] = [];
  dataSource: any;
  slabdataSource: any;
  showSlab: boolean = false;
  showSlab1: boolean = false;
  slab_Data: any[] = [];
  dialogreturn: any;
  div_add_btn: boolean = true;
  div_updt_btn: boolean = false;
  SlabId: any = 0;
  SlabList: any = [];
  SlabCharges: any[] = [];
  SelectSlab: any = "";
  Type: any = "";
  slabJsonData: any[] = [];
  isDisabled: boolean = false;
  slb_add_btn: boolean = true;
  slb_updt_btn: boolean = false;
  NPAList: any;

  displayedColumns: string[] = ["Id", "Slab_name", "EditAction"];
  displayedColumnslist: string[] = ["Id", "Slab_name", "Slab_Type", "Action"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Slab List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getSlabsList();
    this.getReportSlabConfigList();
  }

  OnOpenReportModal() {
    $("#AddNewReportModal").modal("show");
    $("#AddNewReportModal").css("z-index", "1050");

    this.div_add_btn = true;
    this.div_updt_btn = false;
    this.getReportSlablist();
  }

  OnCloseReportModal() {
    this.slabName = "";
    $("#AddNewReportModal").modal("hide");
  }

  onSaveReportSlab() {
    this._MasterService
      .Save_Report_Slab_Master({
        Id: this.Id,
        slabName: this.slabName,
        CommandName: "Add",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Successfully Save",
          ...this.configSuccess,
        });
        this.getReportSlablist();
        this.getSlabsList();
        this.slabName = "";
        $("#AddNewReportModal").modal("show");
        $("#AddNewReportModal").css("z-index", "1050");
      });
  }

  getReportSlablist() {
    this._MasterService
      .Get_Report_Slab_Master({ LoginUserId: this.currentUser.userId })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
          this.showSlab = true;
        }
      });
  }
  ChangeType() {
    if (this.Type == "DPD") {
      this.SlabCharges = [];
      this.SlabCharges.push(
        {
          From: -9999,
          To: 0,
        },
        {
          From: 1,
          To: "",
        }
      );

      this.isDisabled = false;
      this.slb_updt_btn = false;
      this.slb_add_btn = true;
    } else {
      this.SlabCharges = [];
      this.SlabCharges.push({
        From: 0,
        To: "",
      });
    }
  }
  ConfigurationSlabModal() {
    this.SlabCharges = [];
    this.SlabCharges.push({
      From: "0",
      To: "",
      FromNPA: "",
      ToNPA: "",
      Classification: 0,
    });

    this.isDisabled = false;
    this.slb_updt_btn = false;
    this.slb_add_btn = true;
    this.getNPAList();
    $("#ConfigurationSLModal").modal("show");
    $("#ConfigurationSLModal").css("z-index", "1050");
  }

  onDeleteSlab(row) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to delete selected slab ? " + row.Slab_name
    );

    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Save_Report_Slab_Master({
            Id: row.Id,
            slabName: row.Slab_name,
            CommandName: "Delete",
            LoginUserId: this.currentUser.userId,
          })
          .subscribe((response: any) => {
            //console.log(response);
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getReportSlablist();
              this.getSlabsList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  onEditSlab(row) {
    this.SlabId = row.Id;
    this.slabName = row.Slab_name;
    //console.log(this.SlabId);
    this.div_add_btn = false;
    this.div_updt_btn = true;

    $("#AddNewProductModal").modal("show");
    $("#AddNewProductModal").css("z-index", "1050");
  }
  onupdateReportSlab(SlabId) {
    this._MasterService
      .Save_Report_Slab_Master({
        Id: SlabId,
        slabName: this.slabName,
        CommandName: "Update",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((response: any) => {
        console.log(response);
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getReportSlablist();
          this.getSlabsList();
          this.slabName = "";
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  getSlabsList() {
    this._MasterService
      .Get_Report_Slab_Master({ LoginUserId: this.currentUser.userId })
      .subscribe((result) => {
        this.SlabList = JSON.parse(JSON.stringify(result));
      });
  }

  initializeSlabDetail(i) {
    this.SlabCharges.push({
      From: this.SlabCharges[i].To,
      To: "",
      FromNPA: Number(this.SlabCharges[i].ToNPA + 1),
      ToNPA: "",
    });
  }
  addMoreData(index: any) {
    this.initializeSlabDetail(index);
  }

  removeData(index: any) {
    this.SlabCharges.splice(index, 1);
    for (let i = 0; i < this.SlabCharges.length; i++) {
      if (i != 0) {
        if (this.Type == "IRR" || this.Type == "LTV") {
          this.SlabCharges[i].From = this.SlabCharges[i - 1].To;
        } else {
          this.SlabCharges[i].From = this.SlabCharges[i - 1].To;
          this.SlabCharges[i].FromNPA =
            Number(this.SlabCharges[i - 1].ToNPA) + 1;
        }
      }
    }
  }

  ToValueChange(i) {
    if (this.Type != "NPA") {
      if (this.SlabCharges[i].To <= this.SlabCharges[i].From) {
        this.SlabCharges[i].To = "";
        if (i + 1 < this.SlabCharges.length) this.SlabCharges[i + 1].From = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To not less than From",
          ...this.configSuccess,
        });
      } else {
        if (i + 1 < this.SlabCharges.length) {
          this.SlabCharges[i + 1].From = this.SlabCharges[i].To;
          for (let i = 0; i < this.SlabCharges.length; i++) {
            if (this.SlabCharges[i].To <= this.SlabCharges[i].From) {
              this.SlabCharges[i].To = "";
              this.SlabCharges[i].From = this.SlabCharges[i - 1].To;
            }
          }
        }
      }
    }
    if (this.Type == "NPA") {
      if (this.SlabCharges[i].ToNPA <= this.SlabCharges[i].FromNPA) {
        this.SlabCharges[i].ToNPA = "";
        if (i + 1 < this.SlabCharges.length)
          this.SlabCharges[i + 1].FromNPA = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To not less than From",
          ...this.configSuccess,
        });
      } else {
        if (i + 1 < this.SlabCharges.length) {
          this.SlabCharges[i + 1].FromNPA = this.SlabCharges[i].ToNPA + 1;
          for (let i = 0; i < this.SlabCharges.length; i++) {
            if (this.SlabCharges[i].ToNPA <= this.SlabCharges[i].FromNPA) {
              this.SlabCharges[i].ToNPA = "";
              this.SlabCharges[i].FromNPA = this.SlabCharges[i - 1].ToNPA + 1;
            }
          }
        }
      }
    }
  }
  getNPAList() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "NPA" })
      .subscribe((res: any) => {
        console.log(res);
        this.NPAList = res;
      });
  }
  onSaveSlabConfig() {
    var json = { Slab: this.SlabCharges };
    //console.log("json", json);
    this._MasterService
      .Save_Report_Slab_Config({
        Id: this.SelectSlab,
        Type: this.Type,
        CommandName: "Add",
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(json),
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });

        this.closeConfigurationSLModal();
        this.getReportSlabConfigList();
      });
  }
  closeConfigurationSLModal() {
    $("#ConfigurationSLModal").modal("hide");
  }

  getReportSlabConfigList() {
    this._MasterService
      .Get_Report_Slab_Config_List({ LoginUserId: this.currentUser.userId })
      .subscribe((res: any) => {
        //console.log("res",res);
        if (res.length > 0) {
          this.slabJsonData = JSON.parse(JSON.stringify(res));
          this.slabdataSource = new MatTableDataSource(this.slabJsonData);
          this.slabdataSource.sort = this.sort;
          this.showSpinner = false;
        }
      });
  }

  onEditSlabList(row) {
    this._MasterService
      .Get_Report_Slab_Config({
        Id: row.Id,
        Type: row.Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.SelectSlab = res[0].Id;
          this.Type = res[0].Slab_Type;
          this.SlabCharges = res;
          this.isDisabled = true;
          this.slb_updt_btn = true;
          this.slb_add_btn = false;
        }
      });
    this.getNPAList();
    $("#ConfigurationSLModal").modal("show");
    $("#ConfigurationSLModal").css("z-index", "1050");
  }
  onDeleteSlabList(row) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to delete selected slab ? " + row.Slab_name
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Delete_Report_Slab_Config({
            Id: row.Id,
            Type: row.Slab_Type,
            LoginUserId: this.currentUser.userId,
          })
          .subscribe((response: any) => {
            this.getReportSlabConfigList();
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  onUpdateSlabConfig(SelectSlab) {
    var json = { Slab: this.SlabCharges };
    //console.log("json", json);
    this._MasterService
      .Save_Report_Slab_Config({
        Id: SelectSlab,
        Type: this.Type,
        CommandName: "Update",
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(json),
      })
      .subscribe((res: any) => {
        console.log(res);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });

        this.closeConfigurationSLModal();
      });
  }
}
