// #region Import Used Components and Services
import { Injectable } from "@angular/core";
import { throwError, Observable, BehaviorSubject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { GetrSaveresponseModel } from "../../Shared/Models/app.MasterResponseModel";

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(
    private _Route: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  // Start Partner_Get_UpdateBooking_List
  public Partner_Get_UpdateBooking_List(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_UpdateBooking_List";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_UpdateBooking_List

  // Start Partner_Get_Existing_Loan_Details
  public Partner_Get_Existing_Loan_Details(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Get_Existing_Loan_Details";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Existing_Loan_Details

  // Start Partner_Save_Loan_Details
  public Partner_Save_Loan_Details(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Save_Loan_Details";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Save_Loan_Details

  // Start Partner_Get_Loan_Details
  public Partner_Get_Loan_Details(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Loan_Details";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Loan_Details

  // Start Partner_Save_ReceiptDeduction
  public Partner_Save_ReceiptDeduction(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Save_ReceiptDeduction";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Save_ReceiptDeduction

  // Start Partner_Get_ReceiptDeduction
  public Partner_Get_ReceiptDeduction(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_ReceiptDeduction";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_ReceiptDeduction

  // Start Partner_Delete_ReceiptDeduction
  public Partner_Delete_ReceiptDeduction(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Delete_ReceiptDeduction";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Delete_ReceiptDeduction

  // Start Partner_Get_Amortization_Chart
  public Partner_Get_Amortization_Chart(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Amortization_Chart";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Amortization_Chart

  // Start Partner_Get_DV_Details
  public Partner_Get_DV_Details(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_DV_Details";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_DV_Details

  // Start PartnerSave_Acc_VoucherMaster
  public PartnerSave_Acc_VoucherMaster(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/PartnerSave_Acc_VoucherMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // End PartnerSave_Acc_VoucherMaster

  // Start Partner_Get_ModifyBooking_List
  public Partner_Get_ModifyBooking_List(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_ModifyBooking_List";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_ModifyBooking_List

  // Start Partner_Booking_List
  public Partner_Booking_List(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Partner/Partner_Booking_List";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Booking_List

  // Start Partner_Get_DueList
  public Partner_Get_DueList(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Partner/Partner_Get_DueList";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_DueList

  // Start Partner_Get_PaymentList
  public Partner_Get_PaymentList(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_PaymentList";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_PaymentList

  // Start Partner_Get_Search_Loan
  public Partner_Get_Search_Loan(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Search_Loan";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Search_Loan

  // Start Partner_Get_Receipt_Entry
  public Partner_Get_Receipt_Entry(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Receipt_Entry";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Receipt_Entry

  // Start Partner_Get_ACC_Leadger_Loan_OD
  public Partner_Get_ACC_Leadger_Loan_OD(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_ACC_Leadger_Loan_OD";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_ACC_Leadger_Loan_OD

  // Start Partner_Get_Auto_Closer_List
  public Partner_Get_Auto_Closer_List(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Auto_Closer_List";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Auto_Closer_List

  // Start Partner_Compare_Report
  public Partner_Compare_Report(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Compare_Report";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Compare_Report

  // Start Partner_EMI_Compare_Report
  public Partner_EMI_Compare_Report(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_EMI_Compare_Report";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_EMI_Compare_Report

  // Start Partner_Get_Dashboard
  public Partner_Get_Dashboard(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Dashboard";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Dashboard

  // Start Partner_Get_Loan_Closer_Detail
  public Partner_Get_Loan_Closer_Detail(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Loan_Closer_Detail";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Loan_Closer_Detail

  // Start Partner_Get_Loan_Closer_Due_Detail
  public Partner_Get_Loan_Closer_Due_Detail(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Get_Loan_Closer_Due_Detail";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Loan_Closer_Due_Detail

  // Start PartnerGet_ChargesHeads_ForDropdown
  public PartnerGet_ChargesHeads_ForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/PartnerGet_ChargesHeads_ForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // End PartnerGet_ChargesHeads_ForDropdown

  // #Report_BranchWisePortfolio
  public Report_BranchWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_BranchWisePortfolio`,
      requestData
    );
  }
  // #end Report_BranchWisePortfolio

  // #Report_ProductWisePortfolio
  public Report_ProductWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_ProductWisePortfolio`,
      requestData
    );
  }
  // #end Report_ProductWisePortfolio

  // #Report_Amount_WisePortfolio
  public Report_Amount_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_Amount_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Amount_WisePortfolio

  // #Report_Tenure_WisePortfolio
  public Report_Tenure_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_Tenure_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Tenure_WisePortfolio

  // #Report_IRR_WisePortfolio
  public Report_IRR_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_IRR_WisePortfolio`,
      requestData
    );
  }
  // #end Report_IRR_WisePortfolio

  // #Report_LTV_WisePortfolio
  public Report_LTV_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_LTV_WisePortfolio`,
      requestData
    );
  }
  // #end Report_LTV_WisePortfolio
  // #Partner_Save_Closer_Voucher
  public Partner_Save_Closer_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Save_Closer_Voucher`,
      requestData
    );
  }
  // #end Report_LTV_WisePortfolio

  // #Get_Partner_Loan_Balance
  public Get_Partner_Loan_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Get_Partner_Loan_Balance`,
      requestData
    );
  }
  // #end Get_Partner_Loan_Balance
  // #Partner_Report_Future_Flow
  public Partner_Report_Future_Flow(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Partner/Partner_Report_Future_Flow`,
      requestData
    );
  }
  // #end Partner_Report_Future_Flow

  // Start Partner_Get_Pre_Closer_List
  public Partner_Get_Pre_Closer_List(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Pre_Closer_List";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Get_Pre_Closer_List

  // // Start Partner_Get_Auto_Closer_List
  // public Partner_Get_Auto_Closer_List(requestModel: any) {
  //   var apiUrl = constantUrl.apiEndpoint + "/api/Partner/Partner_Get_Auto_Closer_List";
  //   return this.http.post(apiUrl, requestModel);
  // }
  // // End Partner_Get_Auto_Closer_List

  // Start Partner_Report_Gender_WisePortfolio
  public Partner_Report_Gender_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report_Gender_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Report_Gender_WisePortfolio

  // Start Partner_Report_Religion_WisePortfolio
  public Partner_Report_Religion_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report_Religion_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Report_Religion_WisePortfolio

  // Start Partner_Report_Caste_WisePortfolio
  public Partner_Report_Caste_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report_Caste_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Report_Caste_WisePortfolio

  // Start Partner_Report__Marital_WisePortfolio
  public Partner_Report__Marital_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report__Marital_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Report__Marital_WisePortfolio

  // Start Partner_Report_Profile_WisePortfolio
  public Partner_Report_Profile_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report_Profile_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }
  // End Partner_Report_Profile_WisePortfolio

  // Start Partner_Report_Natureofwork_WisePortfolio
  public Partner_Report_Natureofwork_WisePortfolio(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Partner_Report_Natureofwork_WisePortfolio";
    return this.http.post(apiUrl, requestModel);
  }

  public Acc_Save_Import_Partner_Receipt_Voucher(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Partner/Acc_Save_Import_Partner_Receipt_Voucher";
    return this.http.post(apiUrl, requestModel);
  }
  
  // End Partner_Report_Natureofwork_WisePortfolio
}
