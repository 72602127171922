<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 align-items-end formborder">
    <div class="col-md-3 p-1">
      <span> Select Product </span>
      <select
        name="FilterProductId"
        id="FilterProductId"
        (change)="getTVRQuestionsList()"
        [(ngModel)]="FilterProductId"
        class="form-control input-text-css"
      >
        <option value="">Select Product</option>
        <option
          *ngFor="let productList of FilterProductList"
          [value]="productList.ProductId"
        >
          {{ productList.Product_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-9 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="onOpenTVRQuestionModal()"
      >
        Add New
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="dataSource"
      matSort
      style="overflow: auto; max-width: 100%; height: 60vh"
    >
      <ng-container matColumnDef="QueId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Question">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Question
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Question }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Question_Hindi">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Question Hindi
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Question_Hindi }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="QuestionType">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Question Type
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.QuestionType }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Question_For">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Question For
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.Question_For }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateOn">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Create On
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanProduct">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanProduct }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="onEditTVRQuestion(row)"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onDeleteTVRQuestion(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="addNewTVRQuestion"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveTVRQuestionData()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ HeaderText }} TVR Question
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Question </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refTVRQuestion="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refTVRQuestion.invalid,
                  'alert-warning':
                    refTVRQuestion.invalid &&
                    (refTVRQuestion.dirty ||
                      refTVRQuestion.touched ||
                      refTVRQuestion.untouched)
                }"
                [(ngModel)]="TVRQuestion"
                placeholder="TVR Question"
                name="TVRQuestion"
                id="TVRQuestion"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">Question Hindi</div>
            <div class="col-md-8 p-1">
              <input
                type="text"
                [(ngModel)]="TVRQuestionHindi"
                placeholder="TVR Question Hindi"
                name="TVRQuestionHindi"
                id="TVRQuestionHindi"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Question Type </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="TVRQuestionType"
                id="TVRQuestionType"
                #refTVRQuestionType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refTVRQuestionType.invalid,
                  'alert-warning':
                    refTVRQuestionType.invalid &&
                    (refTVRQuestionType.dirty ||
                      refTVRQuestionType.touched ||
                      refTVRQuestionType.untouched)
                }"
                [(ngModel)]="TVRQuestionType"
                (change)="onChangeTVRQuestionType()"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Type</option>
                <option value="Boolean">Boolean</option>
                <option value="Integer">Integer</option>
                <option value="Text">Text</option>
                <option value="Selection">Selection</option>
                <option value="Multi-Selection">Multi-Selection</option>
                <option value="Upload">Upload</option>
              </select>
            </div>
          </div>
          <div
            *ngIf="
              TVRQuestionType == 'Selection' ||
              TVRQuestionType == 'Multi-Selection'
            "
            class="row m-0"
          >
            <div class="col-md-4 p-0"></div>
            <div class="col-md-8 p-0">
              <div class="p-1 text-right" style="width: 100%">
                <i
                  class="fa fa-plus-circle font-size-15"
                  (click)="onAddNewRow()"
                  style="color: green; cursor: pointer"
                  aria-label="true"
                ></i>
              </div>
              <div
                *ngFor="let question of QuestionTypeOptions; let i = index"
                class="row m-0 font-size-12 align-items-center"
              >
                <div class="col-11 p-1">
                  <input
                    type="text"
                    [(ngModel)]="question.TVR_Questions_Option"
                    placeholder=""
                    name="question{{ i }}"
                    id="question{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-1 p-1">
                  <i
                    *ngIf="QuestionTypeOptions.length > 1"
                    (click)="onDeleteRow(i)"
                    class="fa fa-trash style-delete"
                    style="cursor: pointer"
                    aria-label="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">Product</div>
            <div
              class="col-md-8 p-1"
              style="max-height: 170px; overflow: auto; border: 1px solid #dddd"
            >
              <div *ngFor="let productList of FilterProductList; let i = index">
                <mat-checkbox
                  id="productList{{ i }}"
                  name="productList{{ i }}"
                  [(ngModel)]="productList.isAcive"
                  class="example-margin font-size-12"
                >
                  {{ productList.Product_Name }} </mat-checkbox
                ><br />
              </div>
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">Question For</div>
            <div class="col-md-8 p-1" style="max-height: 170px; overflow: auto">
              <div>
                <mat-checkbox [(ngModel)]="Higher" id="Higher" name="H"
                  >Borrower</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="CoBrowser"
                  id="Co-Browser"
                  name="C"
                  style="padding-left: 10px"
                  >Co-Borrower</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="Guarantor"
                  id="Guarantor"
                  name="G"
                  style="padding-left: 10px"
                  >Guarantor</mat-checkbox
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveTVRQuestionData()"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
            class="mt-3 btn font-size-12 button-btn"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
