import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  Injectable,
} from "@angular/core";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { ReportsService } from "../../_Reports/services/reports.service";
import { LosService } from "../../_LOS/services/los.service";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { constantUrl } from "src/app/Shared/constantUrl";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-lms-receipt-book",
  templateUrl: "./lms-receipt-book.component.html",
  styleUrls: ["./lms-receipt-book.component.scss"],
})
export class LmsReceiptBookComponent implements OnInit {
  showSpinner: boolean = false;
  dataSource: any;
  voucherdatefrom: any = new Date();
  voucherdateto: any = new Date();
  executiveList: any;
  Branch: any = 0;
  loading: boolean = false;
  BranchesList: any;
  currentUser: any;
  executive: any = 0;
  LoanAcNo: any = "";
  CurrentDate: any;
  customerDetail: any;
  receiptNo: any;
  createdOn: any;
  createdBy: any;
  addressModel: any = { Address: "" };
  Voucher_Cheque_No: any;
  SearchApplication_No: any = "";
  SearchLoanAcNo: any = "";
  systemconfig: any = {
    CompanyName: "",
    CompanyHeadOfficeAddress: "",
    CompanyPhoneNo: "",
    ComapnyEmail: "",
  };

  SelectSourceType: any = "";

  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any = "";
  leadSourceDropdown: any;
  totalCalculator: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[] = [
    "VoucherId",
    "CaseNo",
    "Voucher_Date",
    "Voucher_Type",
    "Voucher_ReceiptNo_Book",
    "customer_name",
    "Branch_Name",
    "SourceType",
    "SourceName",
    "Amount",
    "Installment_Amount",
    "Interest_Overdue_Amount",
    "Other_Amount",
    "Account_Name",
    "Collection_By",
    "User_Name",
    "CollectionExecutive",
    "Voucher_CreateOn",
    "Voucher_Narration",
    "Location",
  ];

  JsonData: any;
  Remarks: any;
  LoanId: any;
  CompanyInformation: any;
  TableData: any[] = [];
  voucherid: any;
  CompanyProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  AmountInWord: string = "";

  constructor(
    private losService: LosService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private LmsService: LmsService,
    private reportsService: ReportsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Receipt Book");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.BindExecuite();
    this.getBranchesList();
    this.getLeadSourceDropdown();
    this.Get_PartnerForDropdown();
  }
  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe(
      (result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      }
    );
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  onsearch() {
    this.showSpinner = true;
    this.LmsService.LMS_RECEIPT_BOOK({
      From: this.voucherdatefrom,
      To: this.voucherdateto,
      CollectionExecutive: this.executive,
      Branch_Id: this.Branch,
      Application_No: this.SearchApplication_No,
      LoanAcNo: this.SearchLoanAcNo,
      SourceType: this.SelectSourceType || "",
      SourceId: this.LeadSourceName || 0,
      LoginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      //console.log(res, this.ToDate );
      if (res.length > 0) {
        this.JsonData = res;
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      } else {
        this.dataSource = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    });
  }
  BindExecuite() {
    this._MasterService
      .Get_Employee_ByBranch_Department({
        BranchId: 0,
        DeptId: 0,
        LoginUserId: this.currentUser.userId,
        Type: "Lower",
      })
      .subscribe((res: any) => {
        this.executiveList = res;
      });
  }
  DateChange() {
    if (this.voucherdatefrom > this.voucherdateto) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Date To greater than Date From.",
        ...this.configSuccess,
      });
    }
  }

  GetData(data) {
    if (data == true) {
      this.onsearch();
    }
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Receipt-Book`,
      sheet: "Receipt-Book",
    });
  }

  printLetter() {
    $("#ReceiptPrint").modal("hide");
    // this.loading = true;
    setTimeout(() => {
      this.downloadPdf();
    }, 1000);
  }
  downloadPdf() {
    // var data = document.getElementById('pdfSenctionDownload');
    var prtContent = document.getElementById("pdfReceiptDownload");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcel() {
    this.JsonData.forEach((obj: any) => {
      delete obj.Id;
      delete obj.VoucherId;
      delete obj.ChagesHead;
      delete obj.Voucher_Total_Tax;
      delete obj.Voucher_Tag;
      delete obj.Voucher_CreateOn;
      delete obj.CustomerId;
      delete obj.Loan_Id;
      delete obj.Voucher_Cheque_No;
    });
    this.exportAsExcelFile(this.JsonData, "Receipt-Book");
  }
  // Excel export in backend code end here

  onclick_Receipt(row) {
    this.LmsService.GSTReceiptDetails({
      LoanId: row.Loan_Id,
      VoucherId: row.VoucherId,
    }).subscribe((result: any) => {
      console.log("Receipt Details : ", result);
      this.CompanyInformation = result.Item1[0];
      this.TableData = result.Item2.filter(
        (originalData) => originalData.TranType == "CR"
      );

      // console.log("Company Information",this.CompanyInformation)

      this.AmountInWord = new ToWords().convert(
        parseInt(this.totaltypewisemodal("Total"))
      );

      $("#ReceiptPrint").modal("show");
      $("#ReceiptPrint").css("z-index", "1050");
    });
  }

  OnCloseVoucherModel() {
    $("#ReceiptPrint").modal("hide");
  }

  totaltypewisemodal(row): string {
    // console.log("JSON Data",this.JsonData2);

    if (this.TableData != null) {
      let sum = 0;
      for (let i = 0; i < this.TableData.length; i++) {
        sum += Number(this.TableData[i][row]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    // return 0;
    return "0";
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  PrintDoc(divName, Header) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
        
     

          @media print {
            @page {
              
                size: A4;
                margin: 10px 20px 20px 20px; 
                content: counter(page) "/" counter(pages);

         
             
              
            }
            #foot {
              content: counter(page) "/" counter(pages);
              counter-increment: page;
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
            
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

.invoice {
  margin-top:35px;
  max-height: 100%;
  max-width: 100%;
  // margin: 0 auto;
  /* padding: 10px; */
  border: 2px solid black;
  color:black;
}

 .header {
  background-color: lightblue;
  height: 40px;
  border-bottom: 1px solid black;
  
} 

.header h2 {
  text-align: center;
  margin:0px;
  font-weight: 500;
}
   




.company-info {
  /* display: flex; */
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #000000;
  height: 180px
}
  
  
  
.info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: 130px;
}

.company-name {
  text-align: center;
  word-wrap: normal;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: bold;
  font-size: 24px;
  margin-right: 110px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  
}
.company-details{
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  // margin-right: 20px;
  top: 2px;
  right: 65px;
  
 
}
.logo {
  margin-top: 40px;
  // margin-left: 10px;
  position: absolute;
  top: 59px;
}

.logo img {
  max-height: 50px;
}

.party-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
  justify-content: start;
}
.party-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-right: 1px solid black;
  padding-right: 340px;
}

.invoice-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom:0px;

}
.invoice-no{
  display: flex;
  justify-content: space-between;

}
.invoice-details p {
  text-align: right;
 
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: right;
  width: 56.72px;
}

.invoice-table th {
  background-color: #f0f0f0;
}
.Total-Amount{
  /* width:800px;
  height:70px;
  border:1px solid black; 
  margin: 40px; 
  position: absolute;
  left: 240px; */
  margin-bottom: 50px;
}
.amount{
  text-decoration: underline;
  margin:5px;
}

.footer{
  position:relative;
  top:5px;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  padding:10px;
  align-items: end;
}
.created{
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  width:950px;
  /* margin: 15px; */
  position: relative;
  top:50px;
  
}

/* .created span{
  margin:10px;
} */


.signature{
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  bottom: 10px;
}
 

#createdOn{
  margin-top:15px;
  margin-bottom: 15px;
}

#sign{
  margin-top:30px;
  margin-left:0px;
}
         
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}
        <footer></footer>
        </body>
      </html>`);
    popupWin.document.close();
  }
}
