<div class="row m-0 col-md-12 formborder">
  <div class="col-md-4">
    <span> Select Category </span>
    <select
      name="SelectCategory"
      id="SelectCategory"
      #refSelectCategory="ngModel"
      [(ngModel)]="SelectCategory"
      class="form-control input-text-css"
      required
    >
      <option value="0">All</option>
      <option *ngFor="let category of categoryList" [value]="category.Id">
        {{ category.Category_Name }}
      </option>
    </select>
  </div>

  <div class="text-right col-md-2 my-1 pr-2">
    <button
      (click)="OnSearchDurableManufacture()"
      type="button"
      class="mt-3 btn font-size-12 button-btn"
    >
      Search
    </button>
  </div>

  <div class="text-right col-md-2 my-1 pr-2">
    <button
      (click)="OnAddDurableManufacture()"
      type="button"
      class="mt-3 btn font-size-12 button-btn"
    >
      Add
    </button>
  </div>

  <div class="col-md-4 mt-3 text-right">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            (keyup)="applyFilter($event.target.value)"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="table-responsive mt-2">
  <mat-table [dataSource]="dataSourceManufacture">
    <ng-container matColumnDef="Id">
      <mat-header-cell
        class="grid-header"
        style="max-width: 50px"
        *matHeaderCellDef
        >#</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="grid-cell"
        style="max-width: 50px; max-height: 5px"
        >{{ i + 1 }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="Manufacture_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Manufacture Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Manufacture_Name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Category_Names">
      <mat-header-cell class="grid-header" *matHeaderCellDef
        >Category Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Category_Names
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
        >Action</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
        <i
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          aria-label="true"
          (click)="editManufactureName(row)"
        ></i>
        <i
          class="fa fa-trash style-delete"
          (click)="deleteManufactureName(row)"
          aria-label="true"
        ></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumn"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumn"></mat-row>
  </mat-table>
</div>

<div
  class="modal fade in"
  id="editConsumerDurableManufacturing"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSaveDurableManufacture()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ ButtonName }} Durable Manufacture
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Manufacture Name </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refmanufactureName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refmanufactureName.invalid,
                  'alert-warning':
                    refmanufactureName.invalid &&
                    (refmanufactureName.dirty ||
                      refmanufactureName.touched ||
                      refmanufactureName.untouched)
                }"
                [(ngModel)]="manufactureName"
                placeholder="Add Manufacture Name"
                name="manufactureName"
                id="manufactureName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div
            class="table-responsive mt-2"
            style="height: 360px; overflow: auto"
          >
            <mat-table
              #NatureTableModal
              [dataSource]="dataSourceCat"
              matSort
              matTableExporter
              style="height: 300px; max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                >
                  #
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Category_Name">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Category</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Category_Name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ParentIds">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Select
                  <input
                    type="checkbox"
                    name="SelectAll"
                    [(ngModel)]="allSelected"
                    (click)="!allSelected ? selectAll() : deSelectAll()"
                  />
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <input
                    type="checkbox"
                    name="{{ row.Id }}"
                    id="{{ row.Id }}"
                    [disabled]="row.isDisabled"
                    [(ngModel)]="row.isActive"
                    (click)="deSelectAllCheckBox(row.isActive)"
                  />
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedcategoryColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedcategoryColumns"
              ></mat-row>
            </mat-table>
            <div class="row m-0 align-items-center" style="background: #fff">
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnSaveDurableManufacture()"
            class="mt-3 btn font-size-12 button-btn"
            [disabled]="!f.form.valid"
            data-dismiss="modal"
            aria-label="Close"
          >
            {{ SaveUpdateButton }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
