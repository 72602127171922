import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdc-details',
  templateUrl: './pdc-details.component.html',
  styleUrls: ['./pdc-details.component.scss']
})
export class PdcDetailsComponent implements OnInit {
  @Input() PDCDetail;

  constructor() { }

  ngOnInit(): void {
    console.log("PDC Details",this.PDCDetail)
  }

}
