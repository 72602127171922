import { Component, Input, OnInit } from '@angular/core';
import { constantUrl } from 'src/app/Shared/constantUrl';
@Component({
  selector: 'app-income-subcard-info',
  templateUrl: './income-subcard-info.component.html',
  styleUrls: ['./income-subcard-info.component.scss']
})
export class IncomeSubcardInfoComponent implements OnInit {
  @Input() incomeData: any;
  @Input() applicationNo:any;
  income=""
  constructor() { 
  }
  
  ngOnInit(): void {
    this.income=`${constantUrl.customerProfilePicUrl}`
   
  }

}
