import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
declare var $: any;

@Component({
    selector: 'app-lms-loan-auto-closer',
    templateUrl: './lms-loan-auto-closer.component.html',
    styleUrls: ['./lms-loan-auto-closer.component.scss']
})
export class LmsLoanAutoCloserComponent implements OnInit {

    currentUser: any;
  showSpinner: boolean = false;
   Todate: any = new Date();
   FileCheckingList: any[] = [];
    displayedCustomerColumns: string[] = [];
    dataSourceCustomer: any;
    @ViewChild("sortCustomerList", { static: true }) sortCustomer: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(paginator: MatPaginator) {
    if (paginator != undefined) {
      this.dataSourceCustomer.paginator = paginator;
    }
  }

    constructor(private dataSharingService: DataSharingService,
        private _EncrDecrService: EncrDecrService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private lmsService: LmsService) {

        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    }


    configSuccess: MatSnackBarConfig = {
        panelClass: "style-success",
        duration: 5000,
        horizontalPosition: "right",
        verticalPosition: "top",
    };

    ngOnInit() {        
        this.dataSharingService.HeaderTitle.next("Loan For Auto Closer");
      this.displayedCustomerColumns = ['LoanId', 'LoanNo', 'ApplicationNo', 'Product', 'Branch', 'CustomertName', 'PhoneNo', 'LoanAmount', 'Loan_Date', 'ExpiryDate','ODInterestBalance' ,'EditAction'];
        this.LMS_Get_Loan_For_Auto_Closer();
    }

  LMS_Get_Loan_For_Auto_Closer() {
    if (!this.Todate) {
      this.Todate = new Date();
    }
        this.showSpinner = true;
      this.lmsService.LMS_Get_Loan_For_Auto_Closer({ LoginUserId: this.currentUser.userId, Todate: this.Todate }).subscribe((res: any) => {
            if (res) {      
              this.FileCheckingList = JSON.parse(JSON.stringify(res));          
                this.dataSourceCustomer = new MatTableDataSource(res);
                this.dataSourceCustomer.sort = this.sortCustomer;
              this.dataSourceCustomer.paginator = this.paginator;
                this.showSpinner = false;
            }
        });
    }

    goToDetail(row) {
        this.router.navigate([`loan/precloser/R/${this._EncrDecrService.encrypt(encodeURIComponent(row.LoanId))}/${this._EncrDecrService.encrypt(encodeURIComponent("0"))}`]);
  }

  applyFilter(filterValue: string) {
    this.dataSourceCustomer.filter = filterValue.trim().toLowerCase();
  }


  

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_loan_auto_closer") as HTMLTableElement;
    const worksheetName = 'LOAN AUTO CLOSER'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }



}
