import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { ReportsService } from "../../_Reports/services/reports.service";

// // Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

// Excel export code end here

@Component({
  selector: "app-account-interest-calculation",
  templateUrl: "./account-interest-calculation.component.html",
  styleUrls: ["./account-interest-calculation.component.scss"],
})
export class AccountInterestCalculationComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  chhead: any = "";
  showSpinner: boolean = false;
  filteredAccountHead: Observable<any[]>;
  dataSource: any;
  roi: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  FileCheckingList: any[] = [];
  currentUser: any;
  JsonData: any[] = [];
  AccountNameSearch: any;
  AccountHead: any;
  AccountHeadName: any;
  AccountControl = new UntypedFormControl();
  AccountHeadDropdown: any[] = [];
  VoucherDate: any;
  maxDate: any;
  minDate: any;
  FyearId: any;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  displayedColumns: string[] = [
    "AccountId",
    "Account_Name",
    "Voucher_Date",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Interest_Days",
    "Interest_Amount",
  ];

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private reportsService: ReportsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Account Interest Calculation");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getAccounthead();
    this.getFinancialYear();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });

    // this.onSearchAccountInterest()
  }

  onAccountHeadhSelect(event: any) {
    this.AccountHead = event.option.value.AccountId;
    this.AccountHeadName = event.option.value.Account_Name;
    console.log("hdgjhfgwjdfhgjhdf", this.AccountHead, this.AccountHeadName);
    this.roi = this.AccountHeadDropdown.filter(
      (x) => x.AccountId == event.option.value.AccountId
    )[0].Interest;
  }

  displayFn(account: any): string {
    // console.log(
    // this.AccountHeadDropdown.filter(x=>x.AccountId==accountId)[0].Account_Name)
    // return "dfsfdsfds";
    return account && account.Account_Name ? account.Account_Name : "";
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  getAccounthead() {
    this.lmsService.Get_Account_Master({}).subscribe((res: any) => {
      this.AccountHeadDropdown = res.filter((x) => x.Interest_Enable == true);
      //for (var i = 0; i < res.length; i++) {
      //  this.chargesHeadDropdown.push({ id: res[i]["AccountId"], name: res[i]["Account_Name"]});
      //}
      this.filteredAccountHead = this.AccountControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value.Account_Name
        ),
        map((name) =>
          name ? this._filter(name) : this.AccountHeadDropdown.slice()
        )
      );
    });
  }

  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Date To greater than Date From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = moment(res[0].fystart).format("MM/DD/YYYY");
          this.VoucherDateTo = new Date();
        });
    }
  }
  getFinancialYear() {
    this.lmsService
      .Get_FinancialYear({ Id: this.FyearId })
      .subscribe((res: any) => {
        this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
        console.log("edfd", this.minDate);
        this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
        // if (this.maxDate > new Date() && this.minDate < new Date())
        //   this.VoucherDateTo = new Date();
        // else
        //   this.VoucherDateTo = this.maxDate ;
        // this.dataSource = null;
      });
  }
  onSearchAccountInterest() {
    this.showSpinner = true;
    console.log("edfd", this.minDate);
    //  this.VoucherDateFrom = (moment(this.VoucherDateFrom).format('MM/DD/YYYY'));
    // this.VoucherDateTo = (moment(this.VoucherDateTo).format('MM/DD/YYYY'));
    console.log("ch", this.chhead);

    // this.minDate;
    let MA = {
      AccountId: this.chhead.AccountId,
      Interest: parseFloat(this.roi),
      From: this.VoucherDateFrom,
      To: this.VoucherDateTo,
      StartYear: this.minDate.getFullYear(),
    };
    this.reportsService
      .Report_ACC_Interest_Leadger_By_Daily(MA)
      .subscribe((res: any) => {
        //console.log("Resd :" ,res);

        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.FileCheckingList);
          //this.dataSourceDaily.sort = this.sort;
          //this.dataSourceDaily.paginator = this.paginator;
          console.log(this.dataSource.data, "vsda");
          this.showSpinner = false;

          this.AccountNameSearch = this.AccountHeadDropdown.filter(
            (x) => x.AccountId == this.chhead
          )[0].Account_Name;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  calculateACTranTypeDR() {
    if (this.dataSource != null && this.dataSource != "") {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSource != null && this.dataSource != "") {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeTotal() {
    if (this.dataSource != null && this.dataSource != "") {
      var lastBalance = this.dataSource.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }
  calculateACInsterestDays() {
    if (this.dataSource != null && this.dataSource != "") {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["Interest_Days"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACInsterestAmount() {
    if (this.dataSource != null && this.dataSource != "") {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["Interest_Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "account_interest_calculation"
    ) as HTMLTableElement;
    const worksheetName = "ACCOUNT INTEREST CALCULATION"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
