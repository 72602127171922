import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { PartnerService } from "../services/partner.service";


@Component({
  selector: 'app-partner-modify-booking-list',
  templateUrl: './partner-modify-booking-list.component.html',
  styleUrls: ['./partner-modify-booking-list.component.scss']
})
export class PartnerModifyBookingListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  AcCatgList: any = [];
  ACCCategoryType: any = 0;
  Loan_Acc_Type: any = 'Co-Lending';
  LoanStatus:any='Live'
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor( 
    private lmsService: LmsService,
    private _Partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    ) { 
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Modify Booking List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.onSearchPartnerModifyBookingList();
    this.onChangeType();
  }

  onSearchPartnerModifyBookingList() {
    this.showSpinner = true;
    this.displayedColumnsLoan = [
      "LoanId", "Partner_LoanAcNo", "LMS_LoanAcNo", "LoanCreatedOn", "Application_No", "Customer", "Branch", "Product", "LoanAMount",
      "EMIAmount", "DueDate", "Loan_Acc_Category", "Action"
    ];

    this._Partnerservice.Partner_Get_ModifyBooking_List({ LoginUserId: this.currentUser.userId, LoanAccCateoryId: this.ACCCategoryType, IsClose :this.LoanStatus=='Live'?0:1})
    .subscribe((res: any) => {
      //console.log(res);
      this.dataSourceLoan = null;
      if(res.length > 0){
        this.dataSourceLoan = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSourceLoan.sort = this.sort;
        this.dataSourceLoan.paginator = this.paginator;
      }
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Partner-ModifyBooking-list`,
      sheet: "Partner-ModifyBooking-list",
    });
  }

  goToDetailLoan(data: any) {
    this.router.navigate([
      `/partner-dashboard/loan-info/${encodeURIComponent(data.LoanId)}/${"A"}`,
    ]);
  }

  onChangeType() {
    this.ACCCategoryType = 0;
    this.lmsService.Get_ACC_Category_ForDropdown_By_Type({ Type: this.Loan_Acc_Type }).subscribe((result) => {
      this.AcCatgList = JSON.parse(JSON.stringify(result));
     
    });
  }

}
