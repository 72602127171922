<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keydown.enter)="onsearch()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span class="required-lable"> From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            [(ngModel)]="voucherdatefrom"
            (dateChange)="DateChange()"
            required
            #refFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
        name="FromDate"
        [(ngModel)]="voucherdatefrom"
        id="FromDate"
        placeholder="From Date"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
        required
        #refFromDate="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refFromDate.invalid,
          'alert-warning':
            refFromDate.invalid &&
            (refFromDate.dirty ||
              refFromDate.touched ||
              refFromDate.untouched)
        }"
      /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            [(ngModel)]="voucherdateto"
            (dateChange)="DateChange()"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
        name="ToDate"
        [(ngModel)]="voucherdateto"
        id="ToDate"
        placeholder="To Date"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
        required
        #refToDate="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refToDate.invalid,
          'alert-warning':
            refToDate.invalid &&
            (refToDate.dirty || refToDate.touched || refToDate.untouched)
        }"
      /> -->
      </div>
      <div class="col-md-3">
        <span> Executive</span>
        <select
          name="executive"
          id="executive"
          required
          [(ngModel)]="executive"
          class="form-control input-text-css"
        >
          <option value="0">Select Executive</option>
          <option *ngFor="let cat of executiveList" [value]="cat.EmpId">
            {{ cat.EmpName }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <span> Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          #refSelectGender="ngModel"
          [(ngModel)]="Branch"
          class="form-control input-text-css"
        >
          <option value="0">Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <span> Application No. </span>
        <input
          name="SearchApplication_No"
          [(ngModel)]="SearchApplication_No"
          id="SearchApplication_No"
          placeholder="Application No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span> Loan A/C No. </span>
        <input
          name="SearchLoanAcNo"
          [(ngModel)]="SearchLoanAcNo"
          id="SearchLoanAcNo"
          placeholder="Loan A/C No."
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-3">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <div
          *ngIf="
            SelectSourceType == 'Agent' ||
            SelectSourceType == 'DSA' ||
            SelectSourceType == 'Dealer' ||
            SelectSourceType == 'Sales Executive'
          "
        >
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
            (change)="LMS_Get_DV_List()"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onsearch()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-4">
        <form action="" class="search-text mt-3">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div
    class="table-responsive table-striped mt-3"
    [hidden]="!dataSource"
    style="height: 55vh; overflow: auto"
  >
    <table
      class="table-bordered"
      matSort
      mat-table
      [dataSource]="dataSource"
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="VoucherId">
        <th mat-sort-header mat-header-cell *matHeaderCellDef class="width50">
          #
        </th>
        <td mat-cell *matCellDef="let row; let i = index" class="width50">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Voucher_Cheque_No">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Voucher Cheque No
        </th>
        <td mat-cell *matCellDef="let row">{{ row.Voucher_Cheque_No }}</td>
      </ng-container>

      <ng-container matColumnDef="CaseNo">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>CaseNo</th>
        <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
      </ng-container>

      <ng-container matColumnDef="Voucher_Date">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Voucher Date</th>
        <td mat-cell *matCellDef="let row">{{ row.Voucher_Date }}</td>
      </ng-container>

      <ng-container matColumnDef="Voucher_Type">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Voucher Type</th>
        <td mat-cell *matCellDef="let row">{{ row.Voucher_Type }}</td>
      </ng-container>

      <ng-container matColumnDef="Voucher_ReceiptNo_Book">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Receipt No Book
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.Voucher_ReceiptNo_Book }}
          <!-- <a href="javascript:void(0)" (click)="onclick_Receipt(row)">
            {{ row.Voucher_ReceiptNo_Book }}
          </a> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="customer_name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let row">{{ row.customer_name }}</td>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Branch Name</th>
        <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
      </ng-container>

      <ng-container matColumnDef="Amount">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Total Amount</th>
        <td mat-cell *matCellDef="let row">{{ row.Amount }}</td>
      </ng-container>

      <ng-container matColumnDef="Account_Name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Account Name</th>
        <td mat-cell *matCellDef="let row">{{ row.Account_Name }}</td>
      </ng-container>

      <ng-container matColumnDef="SourceName">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Source Name</th>
        <td mat-cell *matCellDef="let row">{{ row.SourceName }}</td>
      </ng-container>

      <ng-container matColumnDef="SourceType">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Source Type</th>
        <td mat-cell *matCellDef="let row">{{ row.SourceType }}</td>
      </ng-container>

      <ng-container matColumnDef="Installment_Amount">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Installment Amount
        </th>
        <td mat-cell *matCellDef="let row">{{ row.Installment_Amount }}</td>
      </ng-container>

      <ng-container matColumnDef="Interest_Overdue_Amount">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Interest Overdue Amount
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.Interest_Overdue_Amount }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Other_Amount">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Other Amount</th>
        <td mat-cell *matCellDef="let row">{{ row.Other_Amount }}</td>
      </ng-container>

      <ng-container matColumnDef="Collection_By">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Collection By</th>
        <td mat-cell *matCellDef="let row">{{ row.Collection_By }}</td>
      </ng-container>

      <ng-container matColumnDef="User_Name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>User Name</th>
        <td mat-cell *matCellDef="let row">{{ row.User_Name }}</td>
      </ng-container>

      <ng-container matColumnDef="CollectionExecutive">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Collection Executive
        </th>
        <td mat-cell *matCellDef="let row">{{ row.CollectionExecutive }}</td>
      </ng-container>

      <ng-container matColumnDef="Voucher_CreateOn">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Voucher Create On
        </th>
        <td mat-cell *matCellDef="let row">{{ row.Voucher_CreateOn }}</td>
      </ng-container>

      <ng-container matColumnDef="Voucher_Narration">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Narration</th>
        <td mat-cell *matCellDef="let row">{{ row.Voucher_Narration }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="Customer_Relation">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Customer Relation
        </th>
        <td mat-cell *matCellDef="let row">{{ row.Customer_Relation }}</td>
      </ng-container>

      <ng-container matColumnDef="Customer_RelationName">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          Customer Relation Name
        </th>
        <td mat-cell *matCellDef="let row">{{ row.Customer_RelationName }}</td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="row m-0" style="background: #fff" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button class="btn font-size-12 button-btn" (click)="exportExcel()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
