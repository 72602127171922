<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div>
  <!--  style="height: 320px; max-width: 100%; overflow: auto;" -->
  <div class="row m-0">
    <div class="col-md-6 p-1 mt-2">
      <h1 class="fs-12 m-0 mb-2">Policy Mismatch:</h1>
    </div>
    <div class="col-md-6 p-1 text-right mt-2">
      <button
        class="mt-3 btn font-size-12 button-btn"
        *ngIf="Processid != 5"
        (click)="OnManualDeviation()"
      >
        Add Manual Deviation
      </button>
    </div>
    <div class="col-md-12 p-1">
      <div
        class="table-responsive"
        [hidden]="LoanCustomerRelated1 && LoanCustomerRelated1.length == 0"
      >
        <mat-table [dataSource]="dataSourceLoanCustomer1">
          <ng-container matColumnDef="Id">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="Value">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Value
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Minimum">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Minimum</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Minimum
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Maximum">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Maximum</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Maximum
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Col_Val">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Value</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Col_Val
            }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayLoanCustomerRelatedColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayLoanCustomerRelatedColumns"
            class="Reject"
          ></mat-row>
        </mat-table>
      </div>

      <div
        class="table-responsive mt-2"
        [hidden]="DocumentRelated1 && DocumentRelated1.length == 0"
      >
        <mat-table [dataSource]="dataSourceDocument1">
          <ng-container matColumnDef="Id">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="Value">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Value
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="KYC_DocNumber">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >KYC Doc Number</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.KYC_DocNumber
            }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayDocumentRelatedColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayDocumentRelatedColumns"
            class="Reject"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div class="row m-0 mt-2" [hidden]="JsonData.length == 0">
    <div class="col-md-12 p-1">
      <h1 class="fs-12 m-0 mb-2">Policy Deviation:</h1>
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Value">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Value</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.value
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Minimum">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Minimum</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Minimum
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Maximum">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Maximum</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Maximum
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Col_Val">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Col Val</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Col_Val
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="CreatedBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Created By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CreatedBy
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApprovedBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Approved By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ApprovedBy
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Status
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                class="fa fa-eye btn_detail"
                (click)="OnOpenDeviation(row)"
              ></i>
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style ml-2"
                (click)="OnClickApprove(row)"
                aria-label="true"
                *ngIf="
                  row.ApprovedById == currentUser.userId &&
                  row.Status == 'Pending'
                "
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedDeviationColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedDeviationColumns"
            [ngClass]="{
              Approve: row.Status == 'Approve',
              Pending: row.Status == 'Pending',
              Reject: row.Status == 'Reject'
            }"
          >
          </mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <form #ddf="ngForm" novalidate (ngSubmit)="onSaveDeviation()">
    <!-- <div class="row m-0 p-1 mt-4" [hidden]="LoanCustomerRelated && LoanCustomerRelated.length == 0">
      <div class="col-md-12 bg-success1"> Loan & Customer Related Deviation:</div>
  </div> -->
    <div
      class="row m-0"
      [hidden]="
        Processid == 5 ||
        (LoanCustomerRelated && LoanCustomerRelated.length == 0)
      "
    >
      <div class="col-md-12 p-1">
        <h1 class="fs-12 m-0 mb-2">Policy Related Deviation:</h1>
        <div class="table-responsive">
          <mat-table [dataSource]="dataSourceLoanCustomer">
            <ng-container matColumnDef="Id">
              <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
                >{{ i + 1 }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="Value">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Value
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Minimum">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Minimum</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Minimum
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Maximum">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Maximum</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Maximum
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Col_Val">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Value</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Col_Val
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="UserAssign">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >User Assign</mat-header-cell
              >
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <select
                  name="UserAssignBy{{ i }}"
                  id="UserAssignBy{{ i }}"
                  *ngIf="row.IsDeviation == true && row.Deviation == 'True'"
                  [(ngModel)]="row.UserAssign"
                  #refUserAssignBy="ngModel"
                  class="form-control input-text-css"
                >
                  <option value="" selected="selected">Select User</option>
                  <option
                    *ngFor="let cat of UserAssignList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Remarks</mat-header-cell
              >
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <input
                  type="text"
                  #reRemark="ngModel"
                  *ngIf="row.IsDeviation == true && row.Deviation == 'True'"
                  placeholder="Remarks"
                  [(ngModel)]="row.Remark"
                  name="Remark{{ i }}"
                  id="Remark{{ i }}"
                  class="form-control input-text-css"
                  [required]="row.UserAssign != ''"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && reRemark.invalid,
                    'alert-warning':
                      reRemark.invalid &&
                      (reRemark.dirty || reRemark.touched || reRemark.untouched)
                  }"
                />
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayLoanCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayLoanCustomerColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>

    <!-- <div class="row m-0 p-1 mt-2" [hidden]="DocumentRelated && DocumentRelated.length == 0">
      <div class="col-md-12 bg-success1">Document Related Deviation:</div>
  </div> -->
    <div
      class="row m-0"
      [hidden]="
        Processid == 5 || (DocumentRelated && DocumentRelated.length == 0)
      "
    >
      <div class="col-md-12 p-1">
        <h1 class="fs-12 m-0 mb-2">Document Related Deviation:</h1>
        <div class="table-responsive">
          <mat-table [dataSource]="dataSourceDocument">
            <ng-container matColumnDef="Id">
              <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
                >{{ i + 1 }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="Value">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Value
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="KYC_DocNumber">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >KYC Doc Number</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.KYC_DocNumber
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="UserAssign">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >User Assign</mat-header-cell
              >
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <select
                  name="UserAssignBy2{{ i }}"
                  id="UserAssignBy2{{ i }}"
                  *ngIf="row.IsDeviation == true && row.Deviation == 'True'"
                  [(ngModel)]="row.UserAssign"
                  #refUserAssignBy2="ngModel"
                  class="form-control input-text-css"
                >
                  <option value="" selected="selected">Select User</option>
                  <option
                    *ngFor="let cat of UserAssignList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Remarks</mat-header-cell
              >
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <input
                  type="text"
                  #reRemark2="ngModel"
                  *ngIf="row.IsDeviation == true && row.Deviation == 'True'"
                  placeholder="Remarks"
                  [(ngModel)]="row.Remark"
                  name="Remark2{{ i }}"
                  id="Remark2{{ i }}"
                  class="form-control input-text-css"
                  [required]="row.UserAssign != ''"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && reRemark.invalid,
                    'alert-warning':
                      reRemark2.invalid &&
                      (reRemark2.dirty ||
                        reRemark2.touched ||
                        reRemark2.untouched)
                  }"
                />
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayDocumentColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayDocumentColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>

    <div class="row m-0" [hidden]="Processid == 5 || ManualRelated.length == 0">
      <div class="col-md-12 p-1">
        <h1 class="fs-12 m-0 mb-2">Manual Related Deviation:</h1>
        <div class="table-responsive">
          <mat-table [dataSource]="dataSourceManual">
            <ng-container matColumnDef="Id">
              <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
                >{{ i + 1 }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Type
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Value">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Value</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Value
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Process_Name">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Process_Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Process_Name
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="CreatedOn">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >CreatedOn</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.CreatedOn
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >CreatedBy</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.CreatedBy
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="UserAssign">
              <mat-header-cell class="grid-header mw170" *matHeaderCellDef
                >User Assign</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw170"
              >
                <select
                  name="UserAssignBy{{ i }}"
                  id="UserAssignBy{{ i }}"
                  [(ngModel)]="row.UserAssign"
                  #refUserAssignBy="ngModel"
                  class="form-control input-text-css"
                >
                  <option value="" selected="selected">Select User</option>
                  <option
                    *ngFor="let cat of UserAssignList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell class="grid-header mw170" *matHeaderCellDef
                >Remarks</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw170"
              >
                <input
                  type="text"
                  #reRemark="ngModel"
                  placeholder="Remarks"
                  [(ngModel)]="row.Remark"
                  name="Remark{{ i }}"
                  id="Remark{{ i }}"
                  class="form-control input-text-css"
                  [required]="row.UserAssign != ''"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && reRemark.invalid,
                    'alert-warning':
                      reRemark.invalid &&
                      (reRemark.dirty || reRemark.touched || reRemark.untouched)
                  }"
                />
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Action">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Action</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <i
                  *ngIf="row.CreatedById == currentUser.userId"
                  class="fa fa-trash style-delete fas"
                  (click)="onDeleteManualDeviation(row.Id)"
                  style="cursor: pointer"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayManualRelatedColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayManualRelatedColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="col-md-12 p-1 text-right">
        <button
          type="button"
          (click)="onSaveDeviation()"
          class="mt-2 btn font-size-12 button-btn"
          [disabled]="!ddf.form.valid"
          *ngIf="
            Processid != 5 &&
            (LoanCustomerRelated.length > 0 ||
              DocumentRelated.length > 0 ||
              ManualRelated.length > 0)
          "
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="DeviationModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Deviation Update
        </h6>
        <button
          type="button"
          (click)="OnCloseDeviation()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ddf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0">
            <ng-container *ngFor="let Remarks of AllRemarks; let i = index">
              <div class="col-md-12">
                <div class="row" [ngClass]="{ 'mt-1': AllRemarks.length > 1 }">
                  <div class="col-md-6">
                    <span>Created By : {{ Remarks.CreatedByName }}</span>
                  </div>
                  <div class="col-md-6 text-right">
                    <span>Created On : {{ Remarks.CreatedOn }}</span>
                  </div>
                </div>
                <textarea
                  rows="2"
                  class="form-control input-text-css"
                  disabled
                  >{{ Remarks.RequestRemark }}</textarea
                >
              </div>
            </ng-container>
            <div class="col-md-12 mt-2">
              <span class="required-lable">Deviation Remarks</span>
              <textarea
                rows="2"
                name="DeviationRemark"
                id="DeviationRemark"
                [(ngModel)]="DeviationModel.DeviationRemark"
                #refDeviationRemark="ngModel"
                placeholder="DeviationRemark"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ddf.submitted && refDeviationRemark.invalid,
                  'alert-warning':
                    refDeviationRemark.invalid &&
                    (refDeviationRemark.dirty ||
                      refDeviationRemark.touched ||
                      refDeviationRemark.untouched)
                }"
              ></textarea>
            </div>
            <div class="col-md-12 mt-2">
              <span class="required-lable">Status</span>
              <select
                name="Status"
                id="Status"
                #refStatus="ngModel"
                [(ngModel)]="DeviationModel.Status"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ddf.submitted && refStatus.invalid,
                  'alert-warning':
                    refStatus.invalid &&
                    (refStatus.dirty ||
                      refStatus.touched ||
                      refStatus.untouched)
                }"
              >
                <option value="">Select Status</option>
                <option value="Approve">Approve</option>
                <option value="Reject">Reject</option>
                <option value="Forward">Forward</option>
              </select>
            </div>
            <div
              class="col-md-12 mt-2"
              *ngIf="DeviationModel.Status == 'Forward'"
            >
              <span class="required-lable">Forward To</span>
              <select
                name="ForwardedBy"
                id="ForwardedBy"
                [(ngModel)]="DeviationModel.ForwardedBy"
                #refForwardedBy="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ddf.submitted && refForwardedBy.invalid,
                  'alert-warning':
                    refForwardedBy.invalid &&
                    (refForwardedBy.dirty ||
                      refForwardedBy.touched ||
                      refForwardedBy.untouched)
                }"
              >
                <option value="">Select User</option>
                <option *ngFor="let cat of UserAssignList" [value]="cat.EmpId">
                  {{ cat.EmpName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseDeviationModel()"
            class="mt-2 btn font-size-12 button-btn mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnUpdateDeviation()"
            class="mt-2 btn font-size-12 button-btn"
            [disabled]="!ddf.form.valid"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="DeviationRemarkModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Deviation Remarks
        </h6>
        <button
          type="button"
          (click)="OnCloseDeviation()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ddf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <ng-container *ngFor="let Remarks of AllRemarks; let i = index">
              <div class="col-md-12">
                <div class="row" [ngClass]="{ 'mt-1': AllRemarks.length > 1 }">
                  <div class="col-md-6">
                    <span>Created By : {{ Remarks.CreatedByName }}</span>
                  </div>
                  <div class="col-md-6 text-right">
                    <span>Created On : {{ Remarks.CreatedOn }}</span>
                  </div>
                </div>
                <textarea
                  rows="2"
                  class="form-control input-text-css"
                  disabled
                  >{{ Remarks.RequestRemark }}</textarea
                >
              </div>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseDeviation()"
            class="mt-2 btn font-size-12 button-btn mr-2"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ManualDeviationModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Manual Deviation
        </h6>
        <button
          type="button"
          (click)="OnCloseManualDeviation()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ff="ngForm" novalidate class="formborder">
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable">Type</span>
              <select
                name="Type"
                id="Type"
                #refType="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refType.invalid,
                  'alert-warning':
                    refType.invalid &&
                    (refType.dirty || refType.touched || refType.untouched)
                }"
                [(ngModel)]="Type"
                class="form-control input-text-css"
                required
              >
                <option value="Loan">Loan</option>
                <option value="Customer">Customer</option>
              </select>
            </div>
            <div class="col-md-12 p-1" *ngIf="Type == 'Customer'">
              <span class="required-lable">Select Customer Name</span>
              <select
                name="Name"
                id="Name"
                #refName="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refName.invalid,
                  'alert-warning':
                    refName.invalid &&
                    (refName.dirty || refName.touched || refName.untouched)
                }"
                [(ngModel)]="Customer"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Customer Name</option>
                <option
                  *ngFor="let list of CustomerDropdown"
                  [value]="list.CustomerId"
                >
                  {{ list.Customer }}
                </option>
              </select>
            </div>
            <div class="col-md-12 p-1">
              <span required-lable> Deviation Detail </span>
              <input
                type="text"
                #refRemark="ngModel"
                [(ngModel)]="DeviationDetail"
                placeholder="Remark"
                name="Remark"
                required
                id="Remark"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ff.submitted && refRemark.invalid,
                  'alert-warning':
                    refRemark.invalid &&
                    (refRemark.dirty ||
                      refRemark.touched ||
                      refRemark.untouched)
                }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnSaveManualDeviation()"
            class="mt-2 btn font-size-12 button-btn mr-2"
            data-dismiss="modal"
            [disabled]="!ff.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="OnCloseDeviation()"
            class="mt-2 btn font-size-12 button-btn mr-2"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
