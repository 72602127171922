import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-creditpolicy",
  templateUrl: "./creditpolicy.component.html",
  styleUrls: ["./creditpolicy.component.scss"],
})
export class CreditpolicyComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = ["ChagesHeadID", "ChagesHead", "EditAction"];
  RequestModel: RequestModel = new RequestModel();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  policydata: any = [];
  Policy: any = "Manual";
  UpdatedOn: any = "";
  LastUpdatedBy: any = "";
  inViewMode: boolean = true;
  LoanProductList: any = [];
  LoanBranchList: any = [];
  EmployeeAndAmount: any = [];
  GetEmployeeDropdown: any = [];
  GetRecomm_EmpIdDropdown: any = [];
  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Credit Assignment Policy");

    this._MasterService
      .QuickLoan_Employee_Assignment_Dropdown({ ProcessId: 2, BranchId: 0 })
      .subscribe((res: any) => {
        this.GetEmployeeDropdown = res;
      });
    this._MasterService
      .QuickLoan_Employee_Assignment_Dropdown({ ProcessId: 20, BranchId: 0 })
      .subscribe((res: any) => {
        this.GetRecomm_EmpIdDropdown = res;
      });
    this.getcreditAssignmentpolicy(false);
  }

  getcreditAssignmentpolicy(changepolicy) {
    this.showSpinner = true;
    this._MasterService.LOS_GetCreditAssignmentPolicy().subscribe((result) => {
      this.policydata = JSON.parse(JSON.stringify(result));

      if (this.policydata.Item1) {
        if (changepolicy == false)
          this.Policy = this.policydata.Item1[0].CurrentPolicy;
        this.UpdatedOn = this.policydata.Item1[0].LastUpdatedOn;
        this.LastUpdatedBy = this.policydata.Item1[0].LastUpdatedBy;
        this.LoanProductList = this.policydata.Item2;
        this.LoanBranchList = this.policydata.Item4;

        this.EmployeeAndAmount =
          this.policydata.Item3.length > 0
            ? this.policydata.Item3
            : [{ EmpId: "", Recomm_EmpId: "", FromAmount: 1, ToAmount: "" }];

        this.inViewMode = true;
        this.showSpinner = false;
        if (this.Policy == "Product") {
          this.LoanProductList.forEach((obj: any) => {
            this._MasterService
              .Employee_Dropdown_For_Product_OR_Branch({
                ProcessId: 2,
                BranchId: 0,
                ProductId: obj.ProductId,
              })
              .subscribe((res: any) => {
                obj.GetEmployeeDropdown = res;
              });
            this._MasterService
              .Employee_Dropdown_For_Product_OR_Branch({
                ProcessId: 20,
                BranchId: 0,
                ProductId: obj.ProductId,
              })
              .subscribe((res: any) => {
                obj.GetRecomm_EmpIdDropdown = res;
              });
          });
        }
        if (this.Policy == "Branch") {
          this.LoanBranchList.forEach((obj: any) => {
            this._MasterService
              .Employee_Dropdown_For_Product_OR_Branch({
                ProcessId: 2,
                BranchId: obj.BranchId,
                ProductId: 0,
              })
              .subscribe((res: any) => {
                obj.GetEmployeeDropdown = res;
              });
            this._MasterService
              .Employee_Dropdown_For_Product_OR_Branch({
                ProcessId: 20,
                BranchId: obj.BranchId,
                ProductId: 0,
              })
              .subscribe((res: any) => {
                obj.GetRecomm_EmpIdDropdown = res;
              });
          });
        }
      }
    });
  }

  OnEditCurrentPolicy() {
    this.inViewMode = false;
  }

  onAddNewAmount() {
    let employeeId =
      this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].EmpId;
    let Recomm_EmpId =
      this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].Recomm_EmpId;

    if (
      employeeId == "" ||
      employeeId == 0 ||
      employeeId == "0" ||
      employeeId == null
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Employee.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      Recomm_EmpId == "" ||
      Recomm_EmpId == 0 ||
      Recomm_EmpId == "0" ||
      Recomm_EmpId == null
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Credit Recommendation Employee.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].ToAmount == ""
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Enter To Amount.",
        ...this.configSuccess,
      });
      return;
    }
    this.EmployeeAndAmount.push({
      EmpId: "",
      Recomm_EmpId: "",
      FromAmount:
        parseFloat(
          this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].ToAmount
        ) + 1,
      ToAmount: "",
    });
  }

  onDeleteAmount(index) {
    this.EmployeeAndAmount.splice(index, 1);
  }
  onChange(event: any, data: any) {
    let _v = event.target.value;
    let _count = 0;
    this.EmployeeAndAmount.forEach((obj: any) => {
      if (obj.EmpId == _v) {
        _count = _count + 1;
      }
    });
    // if (_count > 1) {
    //   this.snackBar.openFromComponent(SnackbarComponent, {
    //     data: "employee already selected please choose another employee",
    //     ...this.configSuccess,
    //   });
    //   event.target.value = "";
    //   data.EmpId = "";
    //   return false;
    // }
  }
  onChangeRecomm_EmpId(event: any, data: any) {
    let _v = event.target.value;
    let _Recomm_EmpIdcount = 0;
    this.EmployeeAndAmount.forEach((obj: any) => {
      if (obj.Recomm_EmpId == _v) {
        _Recomm_EmpIdcount = _Recomm_EmpIdcount + 1;
      }
    });
    // if (_Recomm_EmpIdcount > 1) {
    //   this.snackBar.openFromComponent(SnackbarComponent, {
    //     data: "employee already selected please choose another employee",
    //     ...this.configSuccess,
    //   });
    //   event.target.value = "";
    //   data.Recomm_EmpId = "";
    //   return false;
    // }
  }
  onSubmitData() {
    var EmpID = 0;
    if (this.EmployeeAndAmount.length > 1) {
      this.EmployeeAndAmount.map((item) => {
        EmpID = item.EmpId;

        if (item.EmpId == "") {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please select employee.",
            ...this.configSuccess,
          });
          return;
        } else if (item.Recomm_EmpId == "") {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please select Recomm_EmpId.",
            ...this.configSuccess,
          });
          return;
        } else if (item.ToAmount == "") {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "ToAmount not accept blank.",
            ...this.configSuccess,
          });
          return;
        }
        if (item.FromAmount >= item.ToAmount) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please enter correct Toamount.",
            ...this.configSuccess,
          });
          return;
        }
        if (item.EmpId == EmpID) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please Choose another Employee.",
            ...this.configSuccess,
          });
          return;
        }
      });
    }
    let amountData = [];
    if (
      this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].EmpId != "" &&
      this.EmployeeAndAmount[this.EmployeeAndAmount.length - 1].ToAmount != ""
    ) {
      this.EmployeeAndAmount.map((item) => {
        amountData.push({
          EmployeeId: item.EmpId,
          Recomm_EmpId: item.Recomm_EmpId,
          FromAmount: item.FromAmount,
          ToAmount: item.ToAmount,
        });
      });
    }
    let productData = [];
    this.LoanProductList.map((item) => {
      productData.push({
        ProductId: item.ProductId,
        EmployeeId: item.EmpId,
        Recomm_EmpId: item.Recomm_EmpId,
      });
    });
    let BranchData = [];
    this.LoanBranchList.map((item) => {
      BranchData.push({
        BranchId: item.BranchId,
        EmployeeId: item.EmpId,
        Recomm_EmpId: item.Recomm_EmpId,
      });
    });
    let saveData = {
      CurrentPolicy: {
        Policy: this.Policy,
        LoginUserId: this.data.userId,
      },
      Product: productData,
      Amount: amountData,
      Branch: BranchData,
    };

    this.RequestModel.JSON = JSON.stringify(saveData);
    this._MasterService
      .LOS_SaveCreditAssignmentPolicy(this.RequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getcreditAssignmentpolicy(false);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
