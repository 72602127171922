<div class="d-flex align-items-center justify-content-between formborder">
  <div class="col-md-3 p-1">
    <span> Select Product </span>
    <select
      name="FilterProuctId"
      id="FilterProuctId"
      (change)="getAssetdetailList()"
      [(ngModel)]="FilterProuctId"
      class="form-control input-text-css"
    >
      <option value="">Select Product</option>
      <option
        *ngFor="let ProductListDropdown of ProductListDropdown"
        [value]="ProductListDropdown.ProductId"
      >
        {{ ProductListDropdown.Product_Name }}
      </option>
    </select>
  </div>
  <div class="col-md-3 p-1" style="text-align: right">
    <button
      type="button"
      class="mt-3 btn font-size-12 button-btn"
      (click)="onAddNew()"
    >
      <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
    </button>
  </div>
  <div class="col-md-6 p-1 mt-2">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-2">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-1">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="AssetId">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw50"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell mw50">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssetQuestion">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Asset Question
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AssetQuestion }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssetType">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px; padding-left: 10px"
          *matHeaderCellDef
        >
          Asset Type
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px; padding-left: 10px"
        >
          {{ row.AssetType }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Options">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 250px; padding-left: 10px"
          *matHeaderCellDef
        >
          Options
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          data-html="true"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          style="max-width: 250px; padding-left: 10px"
          title="{{
            row.Options.replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
              .replace('@@', '<br />')
          }}"
        >
          {{
            row.Options.slice(0, 20)
              .replace("@@", ",")
              .replace("@@", ",")
              .replace("@@", ",")
              .replace("@@", ",")
          }}{{ row.Options.length > 20 ? "..." : "" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="IsMandatoryDisplay">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw100"
          *matHeaderCellDef
        >
          Is Mandatory</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell mw100">
          {{ row.IsMandatoryDisplay }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Create On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateBy">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Create By
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateBy }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px"
        >
          <i
            class="fa fa-pencil-alt font-size-12 mr-2"
            (click)="OnEdit(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewProductAssetDetailModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveassetdetail()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ TitleName }} Product Asset Detail
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select Product: </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="FilterProuctId1"
                id="FilterProuctId1"
                [(ngModel)]="FilterProuctId1"
                #refFilterProuctId1="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refFilterProuctId1.invalid,
                  'alert-warning':
                    refFilterProuctId1.invalid &&
                    (refFilterProuctId1.dirty ||
                      refFilterProuctId1.touched ||
                      refFilterProuctId1.untouched)
                }"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Product</option>
                <option
                  *ngFor="let ProductListDropdown of ProductListDropdown"
                  [value]="ProductListDropdown.ProductId"
                >
                  {{ ProductListDropdown.Product_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Asset Question: </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refAssetQuestion="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAssetQuestion.invalid,
                  'alert-warning':
                    refAssetQuestion.invalid &&
                    (refAssetQuestion.dirty ||
                      refAssetQuestion.touched ||
                      refAssetQuestion.untouched)
                }"
                [(ngModel)]="AssetQuestion"
                placeholder="Asset Question"
                name="AssetQuestion"
                id="AssetQuestion"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Asset Type: </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="SelectAssetType"
                id="SelectAssetType"
                #refSelectAssetType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refSelectAssetType.invalid,
                  'alert-warning':
                    refSelectAssetType.invalid &&
                    (refSelectAssetType.dirty ||
                      refSelectAssetType.touched ||
                      refSelectAssetType.untouched)
                }"
                [(ngModel)]="SelectAssetType"
                (change)="onChangeQuestionType()"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Asset Type</option>
                <option value="Integer">Integer</option>
                <option value="Boolean">Boolean</option>
                <option value="Text">Text</option>
                <option value="Select">Select</option>
                <option value="Calendar">Calendar</option>
                <option value="Upload">Upload</option>
              </select>
            </div>
          </div>
          <div *ngIf="SelectAssetType == 'Select'" class="row m-0">
            <div class="col-md-4 p-0"></div>
            <div class="col-md-8 p-0">
              <div class="p-1 text-right" style="width: 100%">
                <i
                  class="fa fa-plus-circle font-size-15"
                  (click)="onAddNewRow()"
                  style="color: green; cursor: pointer"
                  aria-label="true"
                ></i>
              </div>
              <div
                *ngFor="let question of QuestionTypeOptions; let i = index"
                class="row m-0 font-size-12 align-items-center"
              >
                <div class="col-11 p-1">
                  <input
                    type="text"
                    [(ngModel)]="question.Questions_Option"
                    placeholder=""
                    name="question{{ i }}"
                    id="question{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-1 p-1">
                  <i
                    *ngIf="QuestionTypeOptions.length > 1"
                    (click)="onDeleteRow(i)"
                    class="fa fa-trash style-delete"
                    style="cursor: pointer"
                    aria-label="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Is Mandatory: </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="SelectIsMandatory"
                id="SelectIsMandatory"
                #refSelectIsMandatory="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refSelectIsMandatory.invalid,
                  'alert-warning':
                    refSelectIsMandatory.invalid &&
                    (refSelectIsMandatory.dirty ||
                      refSelectIsMandatory.touched ||
                      refSelectIsMandatory.untouched)
                }"
                [(ngModel)]="SelectIsMandatory"
                class="form-control input-text-css"
                required
              >
                <option value="">Is Mandatory</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveassetdetail()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
