import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-lms-loan-statement-search',
  templateUrl: './lms-loan-statement-search.component.html',
  styleUrls: ['./lms-loan-statement-search.component.scss']
})
export class LmsLoanStatementSearchComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  CustomerList: any[] = [];
  showSpinner: boolean = false;  
  Customer: any = {}; loading: boolean = false;
  selectedId: any;
  dataSourceCustomer: any;
  assetTotal: any;
  displayedCustomerColumns: string[] = [];
  dataAssetItemSource: any;
  customerDataSource: any;
  customerData: any;
  
  Type: any = "0";
  

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SelectType : any = '';

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = []; 
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private lmsService: LmsService,
    private router: Router,
    private encdec: EncrDecrService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Statement");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.Customer.CloseCase = '';

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.displayedCustomerColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    })
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    })
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    })
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0}).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    })
  }

  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId : '',
      Area : '',
      SubArea : '',
      SalesEx : '',
      CollectionEx : '',
      LoanAccountingCatId : '',
    };
  }

  onSearchCustomerDetail() {
    this.showSpinner = true;
    this.Customer.LoginUserId = this.currentUser.userId;
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
        if (res.length > 0) {
          if(res.length == 1){
            this.goToDetail(res[0]);
          }
          else {
            this.CustomerList = JSON.parse(JSON.stringify(res));
            if (this.Type != "0") {
              var type;
              type = this.Type == "1" ? "LOS" : "LMS";
              this.CustomerList = this.CustomerList.filter(item => item.Type == type);
            }
            this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
            this.dataSourceCustomer.sort = this.sort;
            this.dataSourceCustomer.paginator = this.paginator;
          }
        }
        else{
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.dataSourceCustomer =null;
        }
        this.showSpinner = false;
      });
  }

  goToDetail(row) {
    sessionStorage.removeItem("LoanSummary");
    sessionStorage.setItem("LoanSummary", JSON.stringify(row));
    //console.log("customer", row);
    this.router.navigate([
      `/lms-dashboard/lms-loanStatement`,
    ]);
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_loan_statement_search") as HTMLTableElement;
    const worksheetName = 'LOAN STATEMENT SEARCH'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  public highlightRow(i) {
    this.selectedId = i;
  }
}
