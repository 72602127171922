<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  class="row m-0 mb-1 mt-2 col-md-12 align-items-center justify-content-between formborder"
>
  <h1 class="fs-12 m-0"></h1>
  <app-case-type-info (close)="GetData($event)" [index]="999978465123102">
    <button
      type="button"
      class="mt-1 btn font-size-12 button-btn"
      color="primary"
    >
      Add Case Type
    </button>
  </app-case-type-info>
</div>

<div class="row m-0" [hidden]="!dataSource">
  <div class="col-md-12 p-1">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Type
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Short_Case_Type_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Short Case Type Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Short_Case_Type_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Case_Type_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Case Type Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Case_Type_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Parent_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Under ACT / Section</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Parent_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px"
          >
            <app-case-type-info
              (close)="GetData($event)"
              [CaseTypeSummary]="row"
              [index]="i"
            >
              <i class="fas fa-lg fa-edit margin-right-4xs cursor-style"></i>
            </app-case-type-info>
            <i
              class="fa fa-trash style-delete"
              aria-label="true"
              (click)="OnDeleteCaseType(row)"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
