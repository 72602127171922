import { Component, OnInit } from "@angular/core";
import { MasterService } from "../Shared/app.Masters.Service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LmsService } from "../_Lms/services/lms.service";
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { SnackbarComponent } from "../snackbar/snackbar.component";
import { MatTableDataSource } from "@angular/material/table";
declare var $;

@Component({
  selector: "app-emi-calculator",
  templateUrl: "./emi-calculator.component.html",
  styleUrls: ["./emi-calculator.component.scss"],
})
export class EmiCalculatorComponent implements OnInit {
  currentUser: any;
  stepEmiForm: boolean = false;
  showSpinner: boolean = false;
  Name: any = "Months";
  loading: boolean = false;
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "ROI",
    Adv_Inst: 0,
    DueDate: new Date(),
  };
  effectiveIrrData: any=0.00;
  stepEMI: any[] = [];
  AmortizationColumns: string[] = [];
  emiIrr: any;
  AmortizationSource: any;
  stepEmiData: any;
  isFrequency: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  selectedScheme: any = {};
  constructor(
    private _MasterService: MasterService,
    private snackBar: MatSnackBar,
    private LmsService: LmsService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.cEIModel = {};
    this.AmortizationSource = null;
    // this.stepEMI = [];
    this.cEIModel.IRR_CalculateBy = "FLAT_EMI";
    this.cEIModel.EMI_Type = "Monthly";
    this.stepEmiForm = false;
    this.cEIModel.Adv_Inst = 0;
  }

  onCloseEMICalculator() {
    $("#EMICalculator").modal("hide");
  }
  openModal() {
    $("#EMICalculator").modal("show");
    this.cEIModel = {};
    this.AmortizationSource = null;
    //  this.stepEMI = [];
    this.cEIModel.IRR_CalculateBy = "FLAT_EMI";
    this.cEIModel.EMI_Type = "Monthly";
    this.cEIModel.Adv_Inst = 0;

    this.stepEmiForm = false;
    this.emiIrr = null;
  }

  onCalculateEmiAndIRR() {
    console.log("cEIModel", this.cEIModel);
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
        +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "ROI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        +(this.cEIModel.EMI_Type == "Monthly" ? this.cEIModel.Adv_Inst : 0) <
        +this.selectedScheme.AdvanceEMI
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      this.calculateEmiIrr();
    } else {
      this.calculateEmiIrr();
    }
  }

  calculateEmiIrr() {
    this.stepEmiForm = true;
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }
      if (this.stepEmiData != undefined && +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }

    this.getCalculateEMIIRR(_data);
  }

  getCalculateEMIIRR(data: any) {
    this.stepEmiForm = true;
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.LmsService.GetEMICalculate(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }
      if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }
      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }

      var ReqModel = {};
      if (
        this.stepEMI.length > 0 &&
        this.cEIModel.IRR_CalculateBy == "STEP_EMI"
      ) {
        let _stepEmi: any[] = [];
        this.stepEMI.forEach((obj: any) => {
          _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
        });
        ReqModel = {
          LoanAmount: this.cEIModel.NetFinance_Amt,
          Disbursement_Amt: this.emiIrr.Disbursement_Amt,
          LoanPeriod: this.cEIModel.No_Of_Inst,
          Application_EMIType: this.cEIModel.EMI_Type,
          NoOfEMI: this.cEIModel.Tenure,
          NoOfEMI_Adv:
            this.cEIModel.EMI_Type == "Monthly" ? this.cEIModel.Adv_Inst : 0,
          DisbIRR: this.emiIrr.Disbursement_IRR,
          DueDate: this.cEIModel.DueDate,
          LoanDate: this.cEIModel.Loan_Date,
          IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
          DaysInYear: this.currentUser.DaysInYear,
          JSON: JSON.stringify({ StepIRR: _stepEmi }),
        };
        this._MasterService
          .LMS_EMI_Calculator_With_StepEmi(ReqModel)
          .subscribe((resAmor: any) => {
            console.log("resAmor", resAmor);
            this.effectiveIrrData = resAmor[0].effectiveIrrData;
            this.AmortizationColumns = [
              "PERIOD",
              "DueDate",
              "EMI_Amount",
              "PRINCIPAL",
              "INTEREST",
              "Principle_OS",
              "export",
            ];
            this.AmortizationSource = new MatTableDataSource(resAmor);
          });
      } else {
        ReqModel = {
          LoanAmount: this.cEIModel.NetFinance_Amt,
          Disbursement_Amt: this.emiIrr.Disbursement_Amt,
          LoanPeriod: this.cEIModel.No_Of_Inst,
          Application_EMIType: this.cEIModel.EMI_Type,
          EMIAmount: this.emiIrr.EMI_Amt,
          RateOfInterest: this.emiIrr.ROI,
          NoOfEMI: this.cEIModel.Tenure,
          AdvanceInst:
            this.cEIModel.EMI_Type == "Monthly" ? this.cEIModel.Adv_Inst : 0,
          DisbIRR: this.emiIrr.Disbursement_IRR,
          InterestAmount: this.emiIrr.Interest_Amt,
          DueDate: this.cEIModel.DueDate,
          LoanDate: this.cEIModel.Loan_Date,
          IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
          DaysInYear: this.currentUser.DaysInYear,
        };
        this._MasterService
          .LMS_EMI_Calculator_Without_StepEmi(ReqModel)
          .subscribe((resAmor: any) => {
            console.log("resAmor", resAmor);
            this.effectiveIrrData = resAmor[0].effectiveIrrData;
            this.AmortizationColumns = [
              "PERIOD",
              "DueDate",
              "EMI_Amount",
              "PRINCIPAL",
              "INTEREST",
              "Principle_OS",
              "export",
            ];
            this.AmortizationSource = new MatTableDataSource(resAmor);
          });
      }
    });
  }

  handleByChange(event: any) {
    //   this.emiIrr = undefined;
    //   if (this.cEIModel.IRR_CalculateBy == 'ROI') {
    //       delete this.cEIModel.EMI_Amount;
    //   }
    //   else {
    //       delete this.cEIModel.Flat_Rate;
    //   }
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
    //this.stepEmiForm = false;
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst) {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }

      if (
        this.cEIModel.EMI_Type == "Daily" ||
        this.cEIModel.EMI_Type == "Weekly" ||
        this.cEIModel.EMI_Type == "Fortnightly" ||
        this.cEIModel.EMI_Type == "ByWeekly" ||
        this.cEIModel.EMI_Type == "FourthWeekly"
      )
        this.Name = "Days";
      else this.Name = "Months";
    } else {
      this.cEIModel.Tenure = "";
    }
    var diffMonth =
      (this.cEIModel.DueDate.getFullYear() -
        this.cEIModel.Loan_Date.getFullYear()) *
        12 +
      (this.cEIModel.DueDate.getMonth() - this.cEIModel.Loan_Date.getMonth());

    if (this.cEIModel.EMI_Type == "ByMonthly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (2 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Quarterly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (3 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "HalfYearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (6 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Yearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (12 - diffMonth);
    }

    this.setAdvanceInstl();
  }
  handleChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_Type == "Flat") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Advance Installment not greater than No. Of Installment !!!",
        ...this.configSuccess,
      });
    }
  }
  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.stepEmiForm = true;
      this.cEIModel.EMI_Type = "Monthly";
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
      this.isFrequency = true;
    else this.isFrequency = false;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    }
    // if (+data.ToEMI == +this.cEIModel.No_Of_Inst) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Another Step can not be added. You can add till number of installment.", ...this.configSuccess });
    //     return;
    // }
    else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To EMI should be greater than From EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }
  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount) {
          TotalEmi =
            TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
              +this.stepEMI[i].EMI_Amount;
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }
  totaltypewise(type) {
    if (
      this.AmortizationSource &&
      this.AmortizationSource != null &&
      this.AmortizationSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.AmortizationSource.filteredData.length; i++) {
        sum += Number(this.AmortizationSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Amortization`,
      sheet: "Amortization",
    });
  }
  //DueDateChange() {
  //  var diffMonth = (this.cEIModel.DueDate.getFullYear() - this.cEIModel.Loan_Date.getFullYear()) * 12 + (this.cEIModel.DueDate.getMonth() - this.cEIModel.Loan_Date.getMonth())
  //  this.cEIModel.Tenure = this.cEIModel.Tenure - (6-diffMonth)+1
  //}
}
