import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { LegalService } from '../services/Legal.service';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-legal-hearing-info',
  templateUrl: './legal-hearing-info.component.html',
  styleUrls: ['./legal-hearing-info.component.scss']
})
export class LegalHearingInfoComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;

  dataSourceHearing: any;
  displayedColumnsHearing: string[] =['Hearing_Id', 'NextHearingDate', 'Hearing_Session', 'Hearing_Stage', 'Hearing_Status', 'Hearing_Action'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  
  @Input() LegalSummary: any;


  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _LegalService: LegalService,
    private dialog: DialogService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next('Legal Hearing Info');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.GetLegalHearingDetailList();
  }

  GetLegalHearingDetailList() {
    this.showSpinner = true;
    this._LegalService.Legal_Hearing_Detail_List({ LegalId: this.LegalSummary.LegalId }).subscribe((res: any) => {
      if(res.length > 0){
        this.dataSourceHearing = new MatTableDataSource(res);
        this.dataSourceHearing.sort = this.sort;
        this.dataSourceHearing.paginator = this.paginator;
      }
      this.showSpinner = false;
    });
  }
}
