<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div style="height: 270px; max-width: 100%; overflow: auto">
  <div class="row m-0 mb-2 mt-2 align-items-center justify-content-between">
    <h1 class="fs-12 m-0">Customer Reference</h1>
    <button
      type="button"
      class="btn font-size-12 button-btn"
      (click)="addCustomerRef()"
      [hidden]="customerlist.length == 0"
      *ngIf="PageData.currentTab == 1 && Status != 'C'"
    >
      Add Customer Reference
    </button>
  </div>
  <div class="row m-0">
    <div class="col-md-12 p-1">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerRefSource">
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="Neighbour">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Neighbour
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ReferenceType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Mobile">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Mobile</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ContactNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Ref_Relation">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Ref Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Association">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Association
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.KnowYear }} Yr
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Address">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Address
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Address }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Remark">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Remark
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Comments }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CreatedOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              CreatedOn
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CreateOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-pencil-alt right"
                style="cursor: pointer; color: green; font-size: 16px"
                aria-label="true"
                (click)="EditCustomerRef(row)"
                *ngIf="
                  PageData.currentTab == 1 &&
                  Status != 'C' &&
                  (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                "
              ></i>
              <i
                class="fa fa-minus-circle font-size-15 ml-3"
                style="color: red; cursor: pointer"
                aria-label="true"
                (click)="deleteCustomerRef(row)"
                *ngIf="
                  PageData.currentTab == 1 &&
                  Status != 'C' &&
                  (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                "
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedCustomerRefColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerRefColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button
    type="button"
    (click)="CheckCustomerRefInfo()"
    class="btn font-size-12 button-btn"
    *ngIf="PageData.currentTab == 1 && Status != 'C'"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>

<div
  class="modal fade in"
  id="ReferenceData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Reference
        </h6>
        <button
          type="button"
          (click)="onCloseReferenceData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #ldff="ngForm" novalidate>
          <div class="row m-0 mt-1">
            <div class="col-md-2">
              <span class="required-lable"> Select Relation </span>
              <select
                name="SelectNeighbour"
                id="SelectNeighbour"
                [(ngModel)]="customerRefModel.SelectNeighbour"
                class="form-control input-text-css"
                required
                #refSelectNeighbour="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refSelectNeighbour.invalid,
                  'alert-warning':
                    refSelectNeighbour.invalid &&
                    (refSelectNeighbour.dirty ||
                      refSelectNeighbour.touched ||
                      refSelectNeighbour.untouched)
                }"
              >
                <option value="Neighbour">Neighbour</option>
                <option value="Office">Office</option>
                <option value="Friends">Friends</option>
                <option value="Colleague">Colleague</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="required-lable"> Name </span>
              <input
                type="text"
                #refFirstName="ngModel"
                [(ngModel)]="customerRefModel.RefName"
                required
                placeholder="Name"
                name="RefName"
                id="RefName"
                class="form-control input-text-css"
                #refRefName="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefName.invalid,
                  'alert-warning':
                    refRefName.invalid &&
                    (refRefName.dirty ||
                      refRefName.touched ||
                      refRefName.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable"> Mobile </span>
              <input
                type="text"
                numbersOnly
                maxlength="10"
                minlength="10"
                #refRefMobile="ngModel"
                required
                [(ngModel)]="customerRefModel.RefMobile"
                placeholder="Mobile No"
                name="RefMobile"
                id="RefMobile"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefMobile.invalid,
                  'alert-warning':
                    refRefMobile.invalid &&
                    (refRefMobile.dirty ||
                      refRefMobile.touched ||
                      refRefMobile.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable"> Ref Relation </span>
              <select
                name="RefRelation"
                id="RefRelation"
                [(ngModel)]="customerRefModel.RefRelation"
                #refRefRelation="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefRelation.invalid,
                  'alert-warning':
                    refRefRelation.invalid &&
                    (refRefRelation.dirty ||
                      refRefRelation.touched ||
                      refRefRelation.untouched)
                }"
              >
                <option value="">Select Customer</option>
                <option
                  [value]="cus.CustomerId"
                  *ngFor="let cus of customerlist"
                >
                  {{ cus.Customer }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="col-md-3">
              <span class="required-lable"> Year of Association </span>
              <select
                name="Association"
                id="Association"
                [(ngModel)]="customerRefModel.Association"
                class="form-control input-text-css"
                required
                #refAssociation="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refAssociation.invalid,
                  'alert-warning':
                    refAssociation.invalid &&
                    (refAssociation.dirty ||
                      refAssociation.touched ||
                      refAssociation.untouched)
                }"
              >
                <option value="">Select</option>
                <option value="1">1 Year</option>
                <option value="2">2 Years</option>
                <option value="3">3 Years</option>
                <option value="4">4 Years</option>
                <option value="5">5 Years</option>
                <option value="6">6 Years</option>
                <option value="7">7 Years</option>
                <option value="8">8 Years</option>
                <option value="9">9 Years</option>
                <option value="10">10 Years</option>
                <option value="11">11 Years</option>
                <option value="12">12 Years</option>
                <option value="13">13 Years</option>
                <option value="14">14 Years</option>
                <option value="15">15 Years</option>
                <option value="16">16 Years</option>
                <option value="17">17 Years</option>
                <option value="18">18 Years</option>
                <option value="19">19 Years</option>
                <option value="20">20 Years</option>
                <option value="21">21 Years</option>
                <option value="22">22 Years</option>
                <option value="23">23 Years</option>
                <option value="24">24 Years</option>
                <option value="25">25 Years</option>
                <option value="26">26 Years</option>
                <option value="27">27 Years</option>
                <option value="28">28 Years</option>
                <option value="29">29 Years</option>
                <option value="30">30 Years</option>
                <option value="31">31 Years</option>
                <option value="32">32 Years</option>
                <option value="33">33 Years</option>
                <option value="34">34 Years</option>
                <option value="35">35 Years</option>
                <option value="36">36 Years</option>
                <option value="37">37 Years</option>
                <option value="38">38 Years</option>
                <option value="39">39 Years</option>
                <option value="40">40 Years</option>
                <option value="41">41 Years</option>
                <option value="42">42 Years</option>
                <option value="43">43 Years</option>
                <option value="44">44 Years</option>
                <option value="45">45 Years</option>
                <option value="46">46 Years</option>
                <option value="47">47 Years</option>
                <option value="48">48 Years</option>
                <option value="49">49 Years</option>
                <option value="50">50 Years</option>
              </select>
            </div>
            <div class="col-md-9">
              <span class="required-lable"> Address </span>
              <input
                type="text"
                name="Address"
                id="Address"
                #refAddress="ngModel"
                [(ngModel)]="customerRefModel.Address"
                required
                placeholder="Address"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refAddress.invalid,
                  'alert-warning':
                    refAddress.invalid &&
                    (refAddress.dirty ||
                      refAddress.touched ||
                      refAddress.untouched)
                }"
              />
            </div>

            <div class="col-md-12">
              <hr />
            </div>

            <div class="col-md-12 text-right">
              <button
                type="button"
                (click)="onCloseReferenceData()"
                class="btn btn-light font-size-12 mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                *ngIf="!isSaveCustomerRef"
                (click)="onSaveCustomerRef('ref')"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ldff.form.valid"
              >
                Submit
              </button>
              <button
                type="button"
                *ngIf="isSaveCustomerRef"
                (click)="onUpdateCustomerRef('ref')"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ldff.form.valid"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
