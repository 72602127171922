<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-2">
    <div class="col-md-12 p-1">
        <div class="table-responsive">
            <mat-table [dataSource]="dataSourceHearing" matSort matTableExporter id="exportReport"
                style="height: 290px; max-width: 100%; overflow: auto;">
                <ng-container matColumnDef="Hearing_Id">
                    <mat-header-cell class="grid-header" *matHeaderCellDef style="max-width:50px">#</mat-header-cell>
                    <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width:50px">
                        {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="NextHearingDate">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Next Hearing Date</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.NextHearingDate}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Hearing_Session">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Hearing Session</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Hearing_Session}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Hearing_Stage">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Hearing Stage</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{row.Hearing_Stage}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Hearing_Status">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Hearing Status</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{row.Hearing_Status}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Hearing_Action">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Hearing Action</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Hearing_Action}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsHearing"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsHearing"></mat-row>
            </mat-table>
            <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
        </div>
    </div>
</div>