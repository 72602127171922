<div
  class="col-md-12 d-flex align-items-center justify-content-between formborder"
>
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-3 mr-2 text-right">
    <button
      type="button"
      class="mt-3 btn font-size-12 button-btn"
      (click)="onAddNature()"
    >
      Add
    </button>
  </div>
</div>
<!-- Table -->
<div>
  <div class="mt-3">
    <div class="">
      <div class="table-responsive" *ngIf="dataCSource">
        <mat-table
          #NatureTable
          [dataSource]="dataCSource"
          matSort
          matTableExporter
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Int_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Nature">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Nature of Work</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Value
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-height: 5px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                aria-label="true"
                (click)="editNature(row.Id, NatureAdd)"
              >
              </i>
              <i
                class="fa fa-trash style-delete"
                style="cursor: pointer"
                aria-label="true"
                (click)="deleteNature(row.Id)"
              >
              </i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedNatureColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedNatureColumns"
          ></mat-row>
        </mat-table>

        <div class="row m-0 align-items-center">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal Sheet-->
<div
  class="modal fade in"
  id="NatureModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ modalSheetName }}
        </h6>
        <button
          type="button"
          (click)="onCloseNature()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #NatureAdd="ngForm"
          id="NatureAdd"
          class="form-group form"
          (ngSubmit)="onSave()"
        >
          <ng-container class="form-group my-1">
            <div
              class="col-md-12 d-flex align-items-center justify-content-between formborder"
            >
              <div
                class="container row my-1 col-md-5 ml-3"
                style="padding-left: 0px !important"
              >
                <b
                  ><label for="text-nature" class="label"
                    >Nature of Work</label
                  ></b
                >
                <input
                  class="form-control"
                  [class.is-invalid]="
                    NatureAdd.controls['text-nature']?.touched &&
                    !!NatureAdd.controls['text-nature'].errors?.required
                  "
                  required
                  type="text"
                  id="text-nature"
                  name="text-nature"
                  placeholder="Enter Nature of Work"
                  [(ngModel)]="textNature"
                  style="width: 97%"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    NatureAdd.controls['text-nature']?.touched &&
                    !!NatureAdd.controls['text-nature'].errors?.required
                  "
                  style="display: block"
                >
                  * Field Required
                </div>
              </div>

              <div class="col-md-4" style="margin-top: 21px">
                <form action="" class="search-text">
                  <div class="bg-light rounded rounded-pill shadow-sm">
                    <div class="input-group">
                      <input
                        type="text"
                        id="FilterInputSearch"
                        (keyup)="applyFiltermodel($event.target.value)"
                        name="FilterInputSearch"
                        placeholder="What're you searching for?"
                        class="form-control border-0 bg-light"
                      />
                      <div class="input-group-append">
                        <button
                          id="button-addon1"
                          type="submit"
                          class="btn btn-link text-primary"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-container>
          <div
            class="table-responsive mt-2"
            style="height: 360px; overflow: auto"
          >
            <mat-table
              #NatureTableModal
              [dataSource]="dataSource"
              matSort
              matTableExporter
              style="height: 300px; max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                >
                  #
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Value">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Category</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Value
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="ParentIds">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Select
                  <input
                    type="checkbox"
                    name="SelectAll"
                    [(ngModel)]="allSelected"
                    (click)="!allSelected ? selectAll() : deSelectAll()"
                  />
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <input
                    type="checkbox"
                    name="{{ row.Id }}"
                    id="{{ row.Id }}"
                    [(ngModel)]="row.isActive"
                    (click)="deSelectAllCheckBox(row.isActive)"
                  />
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedNatureModelColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedNatureModelColumns"
              ></mat-row>
            </mat-table>
            <div class="row m-0 align-items-center" style="background: #fff">
              <div class="col-md-4"></div>
            </div>
          </div>
          <ng-container>
            <div class="col mt-2 text-right">
              <button
                type="submit"
                form="NatureAdd"
                class="mt-3 btn font-size-12 button-btn"
                [ngClass]="NatureAdd.invalid ? 'btn-disabled' : 'btn-secondary'"
                [disabled]="!NatureAdd.form.valid"
              >
                {{ btnName }}
              </button>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
