import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: "app-mst-loan-charges",
  templateUrl: "./mst-loan-charges.component.html",
  styleUrls: ["./mst-loan-charges.component.scss"],
})
export class MstLoanChargesComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  FilterProductId: any = "";
  RequestModel: RequestModel = new RequestModel();
  SaveLoanProductRequestModel: RequestModel = new RequestModel();
  DeleteLoanProductRequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = [
    "ChargeHeadId",
    "ChagesHead",
    "Account_HeadId",
    "ChargeType",
    "ChargeValues",
    "Charge_ApplyOn",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  LoanChargesList: any = [];
  FilterProductList: any = [];
  //add loan document
  ChargesHeadDropdown: any = [];
  ChargeHeadId: any = "";
  Account_HeadId: any = "";
  Charge_ApplyOn: any = "";
  ChargeType: any = "";
  ChargeValues: any = "";
  isEditable: boolean = false;
  HeaderText: string = "Add";
  dialogreturn: any;
  chargableAccount: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Product Charges");
    this._MasterService.GetProductForDropdown().subscribe((result) => {
      this.FilterProductList = JSON.parse(JSON.stringify(result));
      this.FilterProductId =
        this.FilterProductList.length > 0
          ? this.FilterProductList[0].ProductId
          : "";
      this.getProductChagesList();
      this.Get_ChargeableAccountForDropdown();
    });
    this._MasterService.GetProductChargesHeadDropDown().subscribe((result) => {
      this.ChargesHeadDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getProductChagesList() {
    this.showSpinner = true;
    this.RequestModel.ProductId =
      this.FilterProductId == "" ? 0 : parseInt(this.FilterProductId);
    this._MasterService
      .GetProductCharges(this.RequestModel)
      .subscribe((result) => {
        this.LoanChargesList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.LoanChargesList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  onDeleteLoanProduct(row) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this.DeleteLoanProductRequestModel.ProductId = row.ProductId;
        this.DeleteLoanProductRequestModel.ChargeHeadId = row.ChargeHeadId;
        this._MasterService
          .DeleteLoanProductCharges(this.DeleteLoanProductRequestModel)
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getProductChagesList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  onOpenLoanProductModal() {
    this.isEditable = false;
    $("#addNewLoanProduct").modal("show");
    $("#addNewLoanProduct").css("z-index", "1050");
  }

  OnClose() {
    this.ChargeHeadId = "";
    this.Account_HeadId = "";
    this.Charge_ApplyOn = "";
    this.ChargeType = "";
    this.ChargeValues = "";
    this.HeaderText = "Add";
    $("#addNewLoanProduct").modal("hide");
  }

  OneditLoanProduct(row) {
    this.isEditable = true;
    this.HeaderText = "Edit";
    this.ChargeHeadId = parseInt(row.ChargeHeadId);
    this.Account_HeadId = parseInt(row.Account_HeadId);
    this.Charge_ApplyOn = row.Charge_ApplyOn;
    this.ChargeType = row.ChargeType;
    this.ChargeValues = row.ChargeValues;
    $("#addNewLoanProduct").modal("show");
    $("#addNewLoanProduct").css("z-index", "1050");
  }

  onSaveLoanProductData() {
    this.SaveLoanProductRequestModel.ProductId = parseInt(this.FilterProductId);
    this.SaveLoanProductRequestModel.ChargeHeadId = parseInt(this.ChargeHeadId);
    this.SaveLoanProductRequestModel.Charge_AccountHeadId = parseInt(
      this.Account_HeadId
    );
    this.SaveLoanProductRequestModel.ChargeApplyOn = this.Charge_ApplyOn;
    this.SaveLoanProductRequestModel.ChargeType = this.ChargeType;
    this.SaveLoanProductRequestModel.ChargeValues = parseFloat(
      this.ChargeValues
    );
    this.SaveLoanProductRequestModel.LoginUserId = this.data.userId;
    this._MasterService
      .SaveLoanProductCharges(this.SaveLoanProductRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getProductChagesList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  Get_ChargeableAccountForDropdown() {
    this.lmsService
      .Get_ChargeableAccountForDropdown({})
      .subscribe((res: any) => {
        this.chargableAccount = res;
      });
  }
}
