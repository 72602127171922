<div>
  <!-- <app-mat-spinner-overlay *ngIf="showSpinnner"></app-mat-spinner-overlay> -->
  <form #df="ngForm" (keydown.enter)="onSearchLoanBalance()">
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span class="required-lable"> From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            [(ngModel)]="Fromdate"
            id="FromDate"
            class="form-control input-text-css"
            
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="Fromdate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
          [(ngModel)]="Todate"
          id="ToDate"
            class="form-control input-text-css"
           
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="Todate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-3">
        <button
          (click)="onSearchLoanBalance()"
          type="button"
          class="font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-3">
        <mat-form-field [hidden]="!dataSource">
          <input
            matInput
            (keyup)="applyFilter($event.target.value)"
            placeholder="Search ...."
          />
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12">
      <hr />
    </div>
  </div>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12">
      <div class="table-responsive" style="height: 300px; overflow: auto">
        <table
          mat-table
          class="table-bordered"
          matSort
          [dataSource]="dataSource"
          matTableExporter
          #exporter="matTableExporter"
          id="DueList"
        >
          <ng-container matColumnDef="Loan_Id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan No</th>
            <td mat-cell *matCellDef="let row">
              {{ row.LoanAcNo }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Application_No">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Application No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Application_No }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="CustomerName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.CustomerName }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Branch_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Branch Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Branch_Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Product_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Product Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Product_Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Loan_Acc_Category">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Loan Acc Category
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Loan_Acc_Category }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="DPD">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD</th>
            <td mat-cell *matCellDef="let row">
              {{ row.DPD }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Opening_Stock">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Opening Stock
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Opening_Stock }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Addition_Stock">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Stock Addition
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Addition_Stock }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Deleation_Stock">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Stock Deleation
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Deleation_Stock }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Closing_Stock">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Closing Stock
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Closing_Stock }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Opening_Inst">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Opening Inst
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Opening_Inst }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Addition_Inst">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Inst Addition
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Addition_Inst }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Deleation_Inst">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Inst Deleation
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Deleation_Inst }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Closing_Inst">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Closing Inst
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Closing_Inst }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Opening_Interest">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Opening Interest
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Opening_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Addition_Interest">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Interest Addition
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Addition_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Deleation_Interest">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Interest Deleation
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Deleation_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Closing_Interest">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Closing Interest
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Deleation_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button class="btn font-size-12 button-btn" (click)="exportTable()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <table
    id="loan_balance_multi"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Application No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Customer Name
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Branch Name
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Product Name
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Acc Category
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        DPD
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Opening Stock
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Stock Addition
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Stock Deleation
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Closing Stock
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Opening Inst
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Inst Addtion
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Inst Deleation
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Closing Inst
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Opening Interest
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Interest Addition
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Interest Deleation
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Closing Interest
      </th>
    </tr>

    <tr *ngFor="let x of JsonData; let i = index">
      <td>{{ i }}</td>

      <td>{{ x.LoanAcNo }}</td>
      <td>{{ x.Application_No }}</td>
      <td>{{ x.CustomerName }}</td>
      <td>{{ x.Branch_Name }}</td>
      <td>{{ x.Product_Name }}</td>
      <td>{{ x.Loan_Acc_Category }}</td>

      <td>{{ x.DPD }}</td>
      <td>{{ x.Opening_Stock }}</td>
      <td>{{ x.Addition_Stock }}</td>
      <td>{{ x.Deleation_Stock }}</td>
      <td>{{ x.Closing_Stock }}</td>
      <td>{{ x.Opening_Inst }}</td>
      <td>{{ x.Addition_Inst }}</td>
      <td>{{ x.Deleation_Inst }}</td>
      <td>{{ x.Closing_Inst }}</td>
      <td>{{ x.Opening_Interest }}</td>
      <td>{{ x.Addition_Interest }}</td>
      <td>{{ x.Deleation_Interest }}</td>
      <td>{{ x.Deleation_Interest }}</td>
    </tr>
  </table>
</div>
