import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
declare var $: any;
import { DialogService } from "../../Shared/dialog.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerService } from "../services/partner.service"

@Component({
  selector: 'app-partner-amortization',
  templateUrl: './partner-amortization.component.html',
  styleUrls: ['./partner-amortization.component.scss']
})
export class PartnerAmortizationComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  tab: any;

  dataSource: any;
  displayedColumns: string[] = [
    "Period", "DueDate", "PEMI_Amount", "Principle", "Interest", "Outstanding_Principle",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  amortizationListList: any[] = [];

  @Input() loanSummary: any;
  @Input() PageType: any;
  Partner_LoanId: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataSharingService: DataSharingService, 
    private dialog: DialogService, 
    private _Partnerservice: PartnerService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Amortization Chart');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.Partner_LoanId = sessionStorage.getItem('Partner_LoanId');
    this.Get_Amortization_Chart();
    
    if(this.Partner_LoanId == undefined){
      this._Partnerservice.Partner_Get_Existing_Loan_Details({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
        this.Partner_LoanId = res[0].Partner_LoanId;
        this.Get_Amortization_Chart();
      }); 
    } 

    this.tab = 'plist';
  }

  Get_Amortization_Chart() {
    this._Partnerservice.Partner_Get_Amortization_Chart({ LoanId: this.Partner_LoanId }).subscribe((res: any) => {
      this.amortizationListList = res;
      this.dataSource = new MatTableDataSource(res);
      this.getTotal();
      this.showSpinner = false;
    });
  }

  getTotal() {
    let emiTotal = 0, principleTotal = 0, interestTotal = 0;
    this.amortizationListList.forEach((obj: any) => {
      emiTotal = emiTotal + obj.PEMI_Amount;
      principleTotal = principleTotal + obj.Principle;
      interestTotal = interestTotal + obj.Interest;
    });
    
    if (this.amortizationListList.length > 0) {
      this.amortizationListList.push({ DueDate: "Total", PEMI_Amount: emiTotal, Principle: principleTotal, Interest: interestTotal,isBold:true });
    }
  }

  exportTable(exporter: any) {
    exporter.exportTable('xls', { 
      fileName: `partner-amortization-list`, 
      sheet: 'partner-amortization-list' 
    });
  }

  changeTabList(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }
  onNextStep() {
    this.action.emit("next") 
  }
}
