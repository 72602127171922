<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #dbf="ngForm"
  class="formborder"
  (ngSubmit)="onSaveNewProduct()"
  novalidate
>
  <div *ngIf="isShowProductCategory == true" class="row m-0">
    <div class="col-md-4 p-1"></div>
    <div class="col-md-8 p-1">
      <div class="row m-0 align-items-end add-new-product">
        <div class="col-8 p-1">
          <span> Add New Product Category: </span>
          <input
            type="text"
            [(ngModel)]="NewProductCategoryName"
            placeholder="Product Category"
            name="NewProductCategoryName"
            id="NewProductCategoryName"
            class="form-control input-text-css mt-2"
          />
        </div>
        <div class="col-4 p-1 text-center">
          <i
            class="fa fa-save mr-3 btn_detail"
            title="Save"
            (click)="saveProductCategory()"
          ></i>
          <i
            class="fa fa-trash style-delete"
            title="Delete"
            (click)="onDeleteProductCat()"
            style="cursor: pointer; font-size: large"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 mt-3">
    <div
      class="col-md-4 p-1"
      *ngIf="ProductId == null || ProductId == '' || IsUpdate"
    >
      <span class="required-lable"> Product Category : </span>
      <select
        name="SelectProductCategory"
        id="SelectProductCategory"
        #refSelectProductCategory="ngModel"
        [ngClass]="{
          'is-invalid':
            dbf.submCategoryitted && refSelectProductCategory.invalid,
          'alert-warning':
            refSelectProductCategory.invalid &&
            (refSelectProductCategory.dirty ||
              refSelectProductCategory.touched ||
              refSelectProductCategory.untouched)
        }"
        [(ngModel)]="SelectProductCategory"
        class="form-control input-text-css"
        required
        [disabled]="IsDisabled"
      >
        <option value="">Select Category</option>
        <option
          *ngFor="let productCategory of ProductCategoryDropdown"
          [value]="productCategory.ProductCatId"
        >
          {{ productCategory.Product_Category }}
        </option>
      </select>
    </div>
    <div
      class="p-1"
      [ngClass]="
        ProductId == null || ProductId == '' || IsUpdate
          ? 'col-md-4'
          : 'col-md-6'
      "
    >
      <span class="required-lable"> Product Name : </span>
      <input
        required
        type="text"
        #refProductName="ngModel"
        name="ProductName"
        id="ProductName"
        placeholder="Product Name"
        [(ngModel)]="ProductName"
        class="form-control input-text-css"
        [disabled]="IsDisabled"
        [ngClass]="{
          'is-invalid': dbf.submitted && refProductName.invalid,
          'alert-warning':
            refProductName.invalid &&
            (refProductName.dirty ||
              refProductName.touched ||
              refProductName.untouched)
        }"
      />
    </div>
    <div
      class="p-1"
      [ngClass]="
        ProductId == null || ProductId == '' || IsUpdate
          ? 'col-md-4'
          : 'col-md-6'
      "
    >
      <span class="required-lable"> Product Short Name : </span>
      <input
        required
        type="text"
        #refProductShortName="ngModel"
        name="ProductShortName"
        id="ProductShortName"
        [(ngModel)]="ProductShortName"
        placeholder="Product Short Name"
        class="form-control input-text-css"
        [disabled]="IsDisabled"
        [ngClass]="{
          'is-invalid': dbf.submitted && refProductShortName.invalid,
          'alert-warning':
            refProductShortName.invalid &&
            (refProductShortName.dirty ||
              refProductShortName.touched ||
              refProductShortName.untouched)
        }"
      />
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span> CRC Allow For : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="CRC_Co_Browser"
          id="CRC-Co-Borrower"
          name="CRC-Co-Borrower"
          [disabled]="IsDisabled"
        >
          Co-Borrower
        </mat-checkbox>
      </div>
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="CRC_Guarantor"
          id="CRC-Guarantor"
          name="CRC-Guarantor"
          [disabled]="IsDisabled"
        >
          Guarantor
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span> FI Allow For : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="FI_Co_Browser"
          id="FI-Co-Borrower"
          name="FI-Co-Borrower"
          [disabled]="IsDisabled"
        >
          Co-Borrower
        </mat-checkbox>
      </div>
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="FI_Guarantor"
          id="FI-Guarantor"
          name="FI-Guarantor"
          [disabled]="IsDisabled"
        >
          Guarantor
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span> TVR Allow For : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="TVR_Co_Browser"
          id="TVR-Co-Borrower"
          name="TVR-Co-Borrower"
          [disabled]="IsDisabled"
        >
          Co-Borrower
        </mat-checkbox>
      </div>
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="TVR_Guarantor"
          id="TVR-Guarantor"
          name="TVR-Guarantor"
          [disabled]="IsDisabled"
        >
          Guarantor
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span>Personal Discussion Allow For : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="PD_Co_Browser"
          id="PD-Co-Borrower"
          name="PD-Co-Borrower"
          [disabled]="IsDisabled"
        >
          Co-Borrower
        </mat-checkbox>
      </div>
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="PD_Guarantor"
          id="PD-Guarantor"
          name="PD-Guarantor"
          [disabled]="IsDisabled"
        >
          Guarantor
        </mat-checkbox>
      </div>
    </div>
  </div>
  <!--<div class="row m-0">
    <div class="col-md-4 p-1">
      <span> Multiple Tranche Allow : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-12 p-1">
        <mat-checkbox [(ngModel)]="multipleTrunche" id="multipleTrunche" name="multipleTrunche">

        </mat-checkbox>
      </div>
    </div>
  </div>-->
  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span>Is Mendatory : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-3 p-1">
        <mat-checkbox
          [(ngModel)]="Insurance"
          id="Insurance"
          name="Insurance"
          [disabled]="IsDisabled"
        >
          Insurance
        </mat-checkbox>
      </div>
      <div class="col-3 p-1">
        <mat-checkbox
          [(ngModel)]="CustomerRefrence"
          id="CustomerRefrence"
          name="CustomerRefrence"
          [disabled]="IsDisabled"
        >
          Customer Refrence
        </mat-checkbox>
      </div>
      <div class="col-3 p-1">
        <mat-checkbox
          [(ngModel)]="NACHPDC"
          id="NACHPDC"
          name="NACHPDC"
          [disabled]="IsDisabled"
        >
          NACHPDC
        </mat-checkbox>
      </div>
      <!-- <div class="col-3 p-1">
        <button type="button" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px;"
        mat-raised-button color="primary" (click)="preCloser()">
          Config Pre-Closure
        </button>
      </div> -->
    </div>
  </div>

  <div class="row col-md-12">
    <div class="col-md-4 p-1">
      <span>ROI Input Method:</span>
    </div>

    <!-- <mat-radio-group [(ngModel)]="ROI_Input_mathod"> -->
    <div class="col-4 p-1">
      <!-- <mat-radio-button value="Daily">Daily</mat-radio-button> -->

      <input
        type="radio"
        id="Daily"
        name="Daily"
        value="Daily"
        (change)="onChange($event)"
        checked
        [(ngModel)]="ROI_Input_mathod"
      />
      <label for="Daily">Daily</label>
    </div>

    <div class="col-4 p-1">
      <!-- <mat-radio-button value="Daily">Daily</mat-radio-button> -->

      <input
        type="radio"
        id="Anually"
        name="Anually"
        value="Anually"
        (change)="onChange($event)"
        checked
        [(ngModel)]="ROI_Input_mathod"
      />
      <label for="Anually">Anually</label>
    </div>

    <!-- <div class="col-4 p-1">
        <mat-radio-button value="annually">Annually</mat-radio-button>
      </div>
    </mat-radio-group> -->
  </div>

  <div class="row m-0">
    <div class="col-md-4 p-1">
      <span> Login Fee For : </span>
    </div>
    <div class="col-md-8 p-0 row m-0">
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="Bank"
          id="Bank"
          name="Bank"
          [disabled]="IsDisabled"
        >
          Bank
        </mat-checkbox>
      </div>
      <div class="col-6 p-1">
        <mat-checkbox
          [(ngModel)]="Cash"
          id="Cash"
          name="Cash"
          [disabled]="IsDisabled"
        >
          Cash
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="row m-0 col-md-12">
    <div class="col-md-6 p-1" style="display: none">
      <mat-checkbox
        [(ngModel)]="IsODDetail"
        id="ODDetail"
        name="ODDetail"
        [disabled]="IsDisabled"
      >
        OD Interest Detail View By Default In Loan Ledger.
      </mat-checkbox>
    </div>

    <div class="col-md-6 p-1">
      <mat-checkbox
        [(ngModel)]="isEqualAmortizationSplit"
        id="isEqualAmortizationSplit"
        name="isEqualAmortizationSplit"
        [disabled]="IsDisabled"
      >
        Amortization is equal split.
      </mat-checkbox>
    </div>

    <div class="col-md-6 p-1">
      <mat-checkbox
        [(ngModel)]="Generate_Amortization_AfterPayment"
        id="Generate_Amortization_AfterPayment"
        name="Generate_Amortization_AfterPayment"
        [disabled]="IsDisabled"
      >
        Generate amortisation on final disbursement
      </mat-checkbox>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-12 text-right">
      <!-- <button type="button" (click)="OnCloseProductModal()" class="btn btn-light font-size-12" data-dismiss="modal">
        Cancel
      </button> -->
      <button
        type="button"
        (click)="onSaveNewProduct()"
        *ngIf="IsSave"
        class="btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!dbf.form.valid"
      >
        Next
      </button>
      <button
        type="button"
        (click)="onSaveNewProduct()"
        *ngIf="IsUpdate"
        class="btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!dbf.form.valid"
      >
        Update
      </button>
      <button
        type="button"
        (click)="onEditProduct()"
        *ngIf="IsEdit"
        class="btn font-size-12 button-btn"
        [class.spinner]="loading"
      >
        Edit
      </button>
    </div>
  </div>
</form>
