import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";

@Component({
  selector: "app-customer-kyc-verification-detail",
  templateUrl: "./customer-kyc-verification-detail.component.html",
  styleUrls: ["./customer-kyc-verification-detail.component.scss"],
})
export class CustomerKycVerificationDetailComponent implements OnInit {
  showSpinner: boolean = false;
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();
  FyearId: any;
  maxDate: any;
  minDate: any;

  DataSource: any;

  JsonData: any[] = [];

  displayedColumns: string[] = [
    "id",

    "Step_Name",
    "DoucumentNumber",
    "CreatedBy",
    "CreatedOn",
  ];
  selectedId: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Step_Name: any = "";
  stepNameDropdown: any;
  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("KYC Verification Detail");
    this.DropdownFor_KycVerificationApi();
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Date To greater than Date From.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }
  DropdownFor_KycVerificationApi() {
    this.reportsService
      .DropdownFor_KycVerificationApi()
      .subscribe((res: any) => {
        this.stepNameDropdown = res;
        console.log("Step Name", this.stepNameDropdown);
      });
  }

  searchCustomerKycVerificationDetails() {
    this.reportsService
      .Customer_KYC_Verification_Detail({
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        Step_Name: this.Step_Name,
      })
      .subscribe((res: any) => {
        if (res && res.length > 0) {
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.JsonData = res;

          this.DataSource.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataSource = null;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  exportexcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "export-kyc-verification-detail"
    ) as HTMLTableElement;
    const worksheetName = "KYC VERIFICATION DETAIL"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
