<div>
  <form #f="ngForm" (ngSubmit)="onSaveSubArealData()" novalidate>
    <div class="row m-0 col-md-12 align-items-end formborder">
      <div class="col-md-2">
        <span> Under Sub Area : </span>
        <input
          type="text"
          name="UnderSubArea"
          id="UnderSubArea"
          #refSelectGender="ngModel"
          [(ngModel)]="UnderSubArea"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span> Under Area </span>
        <select
          name="UnderArea"
          id="UnderArea"
          (change)="changeArea($event)"
          #refSelectGender="ngModel"
          [(ngModel)]="UnderArea"
          class="form-control input-text-css"
          required
        >
          <option *ngFor="let area of AreaList" [value]="area.Int_Id">
            {{ area.Area_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span> Under Branch </span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
          required
        >
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="float-right row m-0 col-md-3">
        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ....">
        </mat-form-field> -->
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-3 d-flex justify-content-end">
        <button
          type="button"
          *ngIf="saveBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="onSaveSubArealData()"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
        <button
          type="button"
          (click)="UpdateSubArea()"
          *ngIf="updateBtn"
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataCustomerSource">
      <ng-container matColumnDef="Number">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SubArea">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          SubArea
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Sub_Area_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Area">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Area
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Area_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            aria-label="true"
            (click)="editCollectionSubArea(row)"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="removeSubArea(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedCustomerColumns"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedCustomerColumns"
      ></mat-row>
    </mat-table>
  </div>
  <div class="col-md-4">
    <button
      class="mt-3 btn font-size-12 button-btn float-right"
      (click)="exportexcelNew()"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
</div>

<table
  id="sub-area-table"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sub Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Sub_Area_Name }}</td>
    <td>{{ x.Area_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
  </tr>
</table>
