import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DepositService } from "../services/deposit.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommonFunctions } from "../../CommonComponent/function/commonfunctions";
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-deposit-authorize-list',
  templateUrl: './deposit-authorize-list.component.html',
  styleUrls: ['./deposit-authorize-list.component.scss']
})
export class DepositAuthorizeListComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];
  status: any = "Pending";
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  FDRDetail: any;
  Age: any;
  MajorityDate: any;
  TodayDate: any = new Date();

  constructor(
    private dataSharingService: DataSharingService, 
    private router: Router, 
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService, 
    public snackBar: MatSnackBar, 
    private MasterService: MasterService,
    private _DepositService: DepositService,
    private lmsService: LmsService,
    private _commonfunctions: CommonFunctions,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit Authorization');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.displayedColumns = [
      "Deposit_Id", "Deposit_Type", "Deposit_No", "Date_of_FDRD", "CustomerName", "Deposit_Amount", "Interest_Amount", 
      "Maturity_Amount", "Date_of_Maturity", "Auto_Renewal", "Created_By", "Created_Date", "Modify_By", "Modify_On", "Action"
    ];
    this.DepositFDR_Pending_Authorization();
  }

  DepositFDR_Pending_Authorization() {
    this.showSpinner = true;
    this._DepositService.Deposit_FDR_Pending_Authorization({ Status: this.status, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      console.log("RESULT : ",res);
      if (res.length > 0) {
        this.FDRDetail = res[0];
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(this.FileCheckingList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else{
        /*this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });*/
        this.dataSource = '';
      }
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Deposit-Authorize-List`,
      sheet: "Deposit-Authorize-List",
    });
  }
  goToDetail(row) {
    this.router.navigate([
      `deposit-dashboard/Deposit-Info/${encodeURIComponent(this._EncrDecrService.encrypt(row.Deposit_Id))}/${encodeURIComponent(this._EncrDecrService.encrypt('Authorize'))}/${encodeURIComponent(this._EncrDecrService.encrypt(this.status))}`
    ]);
  }

  
  PrintDeposit(row, divName, Header) {
    
    this.FDRDetail = row;
    this.FDRDetail.Customer_DOB = new Date(row.Customer_DOB);
    this.getAge(row.Customer_DOB);
    
    setTimeout(() => {
      this._commonfunctions.PrintFDRDeposit(divName, Header, row.Deposit_No);
    }, 500);
  }

  getAge(CustomerDOB) {
    console.log("CustomerDOB", CustomerDOB);
    var date1 = new Date(CustomerDOB);
    var date2 = new Date();

    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24); //Diference in Days
    var year = Days / 365;
    this.Age = Math.round(year);

    var DayofAdult = 6575;
    var DiffDays = Math.round(DayofAdult) - Math.round(Days);
    
    this.MajorityDate = new Date(new Date().setDate( new Date().getDate() + DiffDays ));
  }
}
