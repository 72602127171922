import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DepositService } from "../services/deposit.service";


@Component({
  selector: 'app-deposit-dashboard',
  templateUrl: './deposit-dashboard.component.html',
  styleUrls: ['./deposit-dashboard.component.scss']
})
export class DepositDashboardComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dashboardData: any[] = [];
  iconname: string ="../../assets/images/lms/";

  constructor(
    private dataSharingService: DataSharingService,
    private DepositService: DepositService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit Dashboard');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
  }

  
}
