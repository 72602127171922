<div>
  <div class="row m-0 align-items-end">
    <div class="col-md-3 p-1">
      <span>Employee :</span>
      <select
        name="EmployeeID"
        id="EmployeeID"
        [(ngModel)]="EmployeeID"
        class="form-control input-text-css"
        required
      >
        <option value="0">Select Employee</option>
        <option *ngFor="let emp of GetEmployeeDropdown" [value]="emp.EmpId">
          {{ emp.EmpName }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span>From Date:</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          [(ngModel)]="FromDate"
          class="form-control input-text-css"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3 p-1">
      <span>To Date:</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          [(ngModel)]="ToDate"
          class="form-control input-text-css"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3 p-1">
      <button
        type="button"
        (click)="getUserLog()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Search
      </button>
    </div>
  </div>

  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 80px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 80px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 110px"
          *matHeaderCellDef
        >
          Application No
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 110px"
        >
          {{ row.Application_No }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="User_Name">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          User Name
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.User_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Activity">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Activity
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Activity }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Sub_Activity">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Sub_Activity
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Sub_Activity }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CompleteOn">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 300px"
          *matHeaderCellDef
        >
          Complete On
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 300px"
        >
          {{ row.CompleteOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TAT_Hr">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          TAT(hrs)
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 50px"
        >
          {{ row.TAT_Hr }}
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
