import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-lms-pdc-cheque-list',
  templateUrl: './lms-pdc-cheque-list.component.html',
  styleUrls: ['./lms-pdc-cheque-list.component.scss']
})
export class LmsPdcChequeListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  DataSource: any;
  DataSourceChequeDetails: any;
  JsonDataDetails: any;
  BranchesList: any[] = [];
  BranchId: any = 0;

  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();
  LoanNo: any;
  JsonData: any[] = [];
  JsonChequeData: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    'Loan_Id', 'LoanAcNo', 'Application_No', 'Branch_Name', 'Loan_Date', 'Application_ExpiryDate', 'LoanClosed_date', 
    'Application_LoanAmount', 'Customer', 'CustomerType', 'SPDC_Chq', 'RPDC_Chq', 'Application_Status', 'rpdc_Used','remaningRpdc', 'Action'
  ];

  displayedChequeColumns: string[] = ['Id', 'PdcType', 'AccountNo', 'BankName', 'BankBranch', 'BankIFSC', 'BankAcType', 'CHNo','RPDCCheque'];

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('PDC Cheque List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getBranchesList();
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  DateChange() {
    if (this.FromDate != '' && this.ToDate != '') {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Date will be greater than to From Date.", ...this.configSuccess });
        this.FromDate = '';
        this.ToDate = '';
      }
    }
  }

  OnSearchPDCChequeList(){
    this.showSpinner = true;

    this.lmsService.LMS_Get_PDC_Cheque_List({ 
      LoginUserId: this.currentUser.userId, From: this.FromDate, To: this.ToDate, Branch_Id: this.BranchId, Loan_No: this.LoanNo
    })
    .subscribe((res: any) => {
      console.log("Result123 : ", res);
      //this.customerRefModel = res;
      if (res.Item1.length > 0) {
        this.JsonData = JSON.parse(JSON.stringify(res.Item1));
        this.JsonDataDetails = JSON.parse(JSON.stringify(res.Item2));
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.Item1)));
        this.DataSource.sort = this.sort;
        this.DataSource.paginator = this.paginator;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.DataSource = null;
      }
      this.showSpinner = false;
    });
  }

  

  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  }

  goToPDCChequeListDetails(row: any) {
    this.showSpinner = true;

    this.lmsService.LMS_Get_PDC_Cheque_Details({
      CustomerId: row.CustomerId, LoanId: row.Loan_Id})
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonChequeData = JSON.parse(JSON.stringify(res));
          this.DataSourceChequeDetails = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          this.DataSourceChequeDetails.sort = this.sort;
          this.DataSourceChequeDetails.paginator = this.paginator;
          $('#CustomerChequeDetailsModel').modal('show');
          $("#CustomerChequeDetailsModel").css("z-index", "1050");
        }
        else {
          this.DataSourceChequeDetails = null;
        }
        this.showSpinner = false;
      })
    
  }
  onCloseCustomerChequeDetailsModel() {
    $('#CustomerChequeDetailsModel').modal('hide');

  }
  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_pdc_cheque_list") as HTMLTableElement;
    const worksheetName = 'LMS PDC CHEQUE LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  exportTableDetails() {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("lms_pdc_cheque_list_Details") as HTMLTableElement;
    const worksheetName = 'LMS PDC CHEQUE Details'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();

  }
  exportChequeDetailTable() {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("lms_pdc_cheque_Details") as HTMLTableElement;
    const worksheetName = 'LMS PDC CHEQUE DETAILS'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

}
