import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { LmsService } from "../services/lms.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-lms-waiver-authorised",
  templateUrl: "./lms-waiver-authorised.component.html",
  styleUrls: ["./lms-waiver-authorised.component.scss"],
})
export class LmsWaiverAuthorisedComponent implements OnInit {
  showSpinner: boolean = false;
  dataSource: any;
  status: any = "Pending";
  currentUser: any;
  Data: any[] = [];
  displayedColumns: string[] = [
    "Waivar_Id",
    "ApplicationNo",
    "LoanAcNo",
    "Customer",
    "Branch",
    "Product",
    "Loan_Date",
    "Amount",
    "Creation_Remark",
    "CreateOn",
    "AuthorizBy",
    "AuthorizRemark",
    "AuthorizOn",
    "Status",
    "Action",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private dataSharingService: DataSharingService,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Waiver List");
    this.Get_Waiver_List_By_Status();
  }

  Get_Waiver_List_By_Status() {
    if (this.status == "Pending") {
      this.displayedColumns = [
        "Waivar_Id",
        "ApplicationNo",
        "LoanAcNo",
        "Customer",
        "Branch",
        "Product",
        "Loan_Date",
        "Request_Total_Amount",
        "CreateOn",
        "Creation_Remark",
        "AuthorizBy",
        "Status",
        "Action",
      ];
    } else if (this.status == "Approved") {
      this.displayedColumns = [
        "Waivar_Id",
        "ApplicationNo",
        "LoanAcNo",
        "Customer",
        "Branch",
        "Product",
        "Loan_Date",
        "Request_Total_Amount",
        "Approved_Total_Amount",
        "Creation_Remark",
        "CreateOn",
        "AuthorizBy",
        "AuthorizOn",
        "AuthorizRemark",
        "Status",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "Waivar_Id",
        "ApplicationNo",
        "LoanAcNo",
        "Customer",
        "Branch",
        "Product",
        "Loan_Date",
        "Request_Total_Amount",
        "Creation_Remark",
        "CreateOn",
        "RejectedBy",
        "RejectedOn",
        "RejectedRemark",
        "Status",
        "Action",
      ];
    }

    this.lmsService
      .Get_Waiver_List_By_Status({
        LoginUserId: this.currentUser.userId,
        Status: this.status,
        Type: "Authorized",
      })
      .subscribe((res: any) => {
        console.log("data", res);
        this.Data = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "WAIVER AUTHORISED LIST",
      sheet: "WAIVER AUTHORISED LIST",
    });
  }
  GetData(data) {
    if (data == true) {
      this.Get_Waiver_List_By_Status();
    }
  }
}
