import { Component, OnInit,ViewChild,Output,EventEmitter } from '@angular/core';
import { MasterService } from 'src/app/Shared/app.Masters.Service';
declare var $: any;
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
//

@Component({
  selector: 'app-search-loan',
  templateUrl: './search-loan.component.html',
  styleUrls: ['./search-loan.component.scss']
})
export class SearchLoanComponent implements OnInit {
  

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  CustomerType: any = "0";
  SelectType: any = "";
  dataSourceCustomer: any;
  showSpinner: boolean = false;
  displayedCustomerColumns: string[] = [];
  CustomerList: any[] = [];
  Customer: any = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  currentUser: any;

  constructor(
    private _MasterService: MasterService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {


    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    
  }

  onSearchCustomerDetail() {
    this.showSpinner = true;

    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
      
            this.displayedCustomerColumns = [
              "CustomerId",
              "Type",
              "Application_No",
              "LoanAcNo",
              "CustomertName",
              "FatherName",
              "Customer_Gender",
              "GenderAge",
              "PhoneNo",
              "EditAction",
            ];

            this.CustomerList = JSON.parse(JSON.stringify(res));
            if (this.CustomerType != "0") {
              var type;
              if (this.CustomerType == "1") type = "LOS";
              else type = "LMS";
              this.CustomerList = this.CustomerList.filter(
                (item) => item.Type == type
              );
            }
            this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
            this.dataSourceCustomer.sort = this.sort;
            this.dataSourceCustomer.paginator = this.paginator;
         
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceCustomer = null;
        }
        this.showSpinner = false;
      });
  }
 

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }





  searchCustomer() {
    this.dataSourceCustomer = null;
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
  }

  onCloseReceipt() {
    $("#CustomerReceipt").modal("hide");
    this.close.emit(true);
  }



  goToDetail(){
    $("#CustomerReceipt").modal("hide");


  }
}






