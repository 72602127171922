<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-2 mb-2">
  <div class="col-md-6">
    <button
      class="btn btn-outline-green btn-lg btn-block"
      [ngClass]="{ active: tab == 'Amortization' }"
      (click)="changeTab('Amortization')"
    >
      Amortization
    </button>
  </div>
  <div class="col-md-6">
    <button
      class="btn btn-outline-green btn-lg btn-block"
      [ngClass]="{ active: tab == 'Allocation' }"
      (click)="changeTab('Allocation')"
    >
      EMI Received Allocation
    </button>
  </div>
</div>

<div class="" *ngIf="tab == 'Amortization'">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[]"
      >
        <ng-container matColumnDef="Period">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Period</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{
            row.Period
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="DueDate">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Due Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <ng-container *ngIf="row.isBold"
              ><b> {{ row.DueDate }}</b></ng-container
            >
            <ng-container *ngIf="!row.isBold">{{ row.DueDate }}</ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="EMI_Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <ng-container *ngIf="row.isBold"
              ><b> {{ row.EMI_Amount }}</b></ng-container
            >
            <ng-container *ngIf="!row.isBold">{{
              row.EMI_Amount
            }}</ng-container>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef>{{
            totaltypewiseAmortization("EMI_Amount")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Principle">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Principle</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <ng-container *ngIf="row.isBold"
              ><b> {{ row.Principle }}</b></ng-container
            >
            <ng-container *ngIf="!row.isBold">{{ row.Principle }}</ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewiseAmortization("Principle")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Interest">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Interest</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <ng-container *ngIf="row.isBold"
              ><b> {{ row.Interest }}</b></ng-container
            >
            <ng-container *ngIf="!row.isBold">{{ row.Interest }}</ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewiseAmortization("Interest")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Outstanding_Principle">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Outstanding Principle</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Outstanding_Principle
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></mat-footer-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-2">
          <button
            style="margin-top: 15px"
            class="mt-2 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-2">
          <button
            class="mt-2 btn font-size-12 button-btn"
            (click)="printdata()"
          >
            <i
              class="fa fa-print mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="tab == 'Allocation'">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSourceAllocation"
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="['Action']"
      >
        <!-- [ngClass]="{'fixheight': PageType == '' || PageType == undefined,'fixheight1': PageType == 'L', 'fixheight2': PageType == 'A'}" -->
        <ng-container matColumnDef="Voucher_Id">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{
            i + 1
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="VoucherDate">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VoucherDate
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Voucher_No
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="EMI_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.EMI_No
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="AllocatedAmt">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Allocated Amt</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.AllocatedAmt
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{
            totaltypewise("AllocatedAmt")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Principle">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Principle</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.Principle
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{
            totaltypewise("Principle")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Interest">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Interest</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.Interest
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{
            totaltypewise("Interest")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            <app-lms-voucher-view-model
              [voucherid]="row.Voucher_Id"
              [VoucherFrom]="'Allocation'"
              [index]="i"
            >
              <i
                class="fa fa-arrow-circle-right btn_detail"
                aria-label="true"
              ></i>
            </app-lms-voucher-view-model>
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="grid-header"
          ></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsAllocation; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsAllocation"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsAllocation; sticky: true"
          style="background: #4dc3a3"
        ></mat-footer-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-2 p-2">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable(exporter)"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-4 p-2">
          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn"
            mat-raised-button
            (click)="LMSUpdateLoanEMIAllocation()"
          >
            Regenerate Allocation
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="amortization_chart"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th
      colspan="6"
      style="text-align: right; background-color: #4dc3a3; font-size: 20px"
    >
      {{ currentUser.CompanyName }}
    </th>
    <!-- 
    <th
      colspan="2"
      style="text-align: center; background-color: #4dc3a3; font-size: 20px"
    >
      <img
        src="{{ EmployeeProfileBaseUrl }}logo.png"
        alt="logo"
        style="height: 35px !important; width: 85px !important"
      />
    </th> -->
  </tr>
  <tr>
    <th colspan="6" style="text-align: center; background-color: #4dc3a3">
      Address : {{ currentUser.CompanyAddress }}
    </th>
  </tr>
  <tr>
    <th colspan="6" style="text-align: center; background-color: #4dc3a3">
      Amortization Chart
    </th>
  </tr>
  <tr>
    <th
      colspan="6"
      style="
        text-align: center;
        background-color: #4dc3a3;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      "
    >
      Borrower Name:{{ loanSummary.Customer }} (<strong>{{
        loanSummary.LoanNo
      }}</strong
      >)
    </th>
  </tr>
  <tr>
    <td colspan="2"><strong>Borrower :</strong> {{ loanSummary.Customer }}</td>
    <td colspan="2"><strong>Loan No :</strong> {{ loanSummary.LoanNo }}</td>
    <td colspan="2"><strong>Loan Date :</strong> {{ loanSummary.LoanDate }}</td>
  </tr>
  <tr>
    <td colspan="2">
      <strong>Expiry Date :</strong> {{ loanSummary.ExpiryDate }}
    </td>
    <td colspan="2"><strong>Product :</strong> {{ loanSummary.Product }}</td>
    <td colspan="2"><strong>Branch :</strong> {{ loanSummary.Branch }}</td>
  </tr>
  <tr>
    <td colspan="2">
      <strong>No of EMI :</strong> {{ loanSummary.No_Of_Instl }}
    </td>
    <td colspan="2">
      <strong>Loan Amount :</strong> {{ loanSummary.LoanAmount }}
    </td>
    <td colspan="2">
      <strong>Agreement Value :</strong> {{ loanSummary.AgreementValue }}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <strong>Interest Amount :</strong> {{ loanSummary.InterestAmt }}
    </td>
    <td colspan="2">
      <strong>ROI :</strong> {{ loanSummary.RateOfInterest.toFixed(2) }}%
    </td>
    <td
      colspan="2"
      *ngIf="
        loanSummary.EMI_Type !== 'Daily' &&
        loanSummary.EMI_Type !== 'Weekly' &&
        loanSummary.EMI_Type !== 'Fortnightly' &&
        loanSummary.EMI_Type !== 'ByWeekly' &&
        loanSummary.EMI_Type !== 'FourthWeekly'
      "
    >
      <strong>IRR :</strong> {{ loanSummary.IRR.toFixed(2) }}%
    </td>
  </tr>
  <tr>
    <th style="background: #4dc3a3; text-align: left">Period</th>
    <th style="background: #4dc3a3; text-align: center">Due Date</th>
    <th style="background: #4dc3a3; text-align: left">EMI Amount</th>
    <th style="background: #4dc3a3; text-align: left">Principle</th>
    <th style="background: #4dc3a3; text-align: left">Interest</th>
    <th style="background: #4dc3a3; text-align: left">Outstanding Principle</th>
  </tr>
  <tr *ngFor="let obj of amortizationListList; let i = index">
    <td>{{ i + 1 }}</td>
    <td style="text-align: center">
      <strong>{{ obj.DueDate }}</strong>
    </td>
    <td style="text-align: right">
      <strong>{{ obj.EMI_Amount }}</strong>
    </td>
    <td style="text-align: right">
      <strong>{{ obj.Principle }}</strong>
    </td>
    <td style="text-align: right">
      <strong>{{ obj.Interest }}</strong>
    </td>
    <td style="text-align: right">
      <strong>{{ obj.Outstanding_Principle }}</strong>
    </td>
  </tr>
</table>

<div
  class="modal fade in"
  id="amortization_chart_print"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Amortization Chart
        </h6>
        <button
          type="button"
          (click)="amortization_chart_print_Close()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              mat-raised-button
              class="btn font-size-12 button-btn ml-2"
              (click)="
                onDocsPrint('AmortizationChartContent', 'Amortization Chart')
              "
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="AmortizationChartContent">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>

              <div>
                <h5
                  style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                  "
                >
                  Amortization Chart
                </h5>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                margin-top: 20px;
                margin-bottom: 10px;
                padding: 10px;
                border: 1px solid black;
                border-radius: 10px;
                background-color: rgb(235, 232, 228);
              "
            >
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span><B>Borrower :</B> {{ loanSummary.Customer }}</span>
              </div>

              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span
                  ><B>Loan No : {{ loanSummary.LoanNo }}</B></span
                >
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span>
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span><B>Expiry Date :</B> {{ loanSummary.ExpiryDate }}</span>
              </div>

              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span><B>Product :</B> {{ loanSummary.Product }}</span>
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span><B>Branch :</B> {{ loanSummary.Branch }}</span>
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span c><B>No of EMI :</B> {{ loanSummary.No_Of_Instl }}</span>
              </div>

              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span class="fs-12"
                  ><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span
                >
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span class="fs-12"
                  ><B>Agreement Value :</B>
                  {{ loanSummary.AgreementValue }}</span
                >
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span
                  ><B>Interest Amount :</B> {{ loanSummary.InterestAmt }}</span
                >
              </div>
              <div style="flex: 0 0 calc(33.333% - 20px)">
                <span
                  ><B>ROI :</B>
                  {{ loanSummary.RateOfInterest.toFixed(2) }}%</span
                >
              </div>
              <div
                style="flex: 0 0 calc(33.333% - 20px)"
                *ngIf="
                  loanSummary.EMI_Type !== 'Daily' &&
                  loanSummary.EMI_Type !== 'Weekly' &&
                  loanSummary.EMI_Type !== 'Fortnightly' &&
                  loanSummary.EMI_Type !== 'ByWeekly' &&
                  loanSummary.EMI_Type !== 'FourthWeekly'
                "
              >
                <span><B>IRR :</B> {{ loanSummary.IRR.toFixed(2) }}%</span>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table
              id="amortization_chart"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <tr>
                <th style="background: #4dc3a3 !important; text-align: right">
                  Period
                </th>
                <th style="background: #4dc3a3 !important; text-align: right">
                  Due Date
                </th>
                <th style="background: #4dc3a3 !important; text-align: right">
                  EMI Amount
                </th>
                <th style="background: #4dc3a3 !important; text-align: right">
                  Principle
                </th>

                <th style="background: #4dc3a3 !important; text-align: right">
                  Interest
                </th>
                <th style="background: #4dc3a3 !important; text-align: right">
                  Outstanding Principle
                </th>
              </tr>
              <tr
                style="text-align: right"
                *ngFor="let obj of amortizationListList; let i = index"
              >
                <td>{{ i + 1 }}</td>

                <td style="text-align: right">
                  <strong>{{ obj.DueDate }}</strong>
                </td>

                <td style="text-align: right">
                  <strong>{{ obj.EMI_Amount }}</strong>
                </td>
                <td style="text-align: right">
                  <strong>{{ obj.Principle }}</strong>
                </td>
                <td style="text-align: right">
                  <strong>{{ obj.Interest }}</strong>
                </td>
                <td style="text-align: right">
                  <strong>{{ obj.Outstanding_Principle }}</strong>
                </td>
              </tr>
            </table>

            <div
              id="abc"
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date & Time: {{ Cur_Date | date : "dd/MM/yyyy hh:mm:ss" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
