<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchPartnerUpdtBookingList()"
    class=""
  >
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-4">
        <span class="required-lable"> Partner</span>
        <select
          name="ACCCategoryType"
          id="ACCCategoryType"
          [(ngModel)]="ACCCategoryType"
          class="form-control input-text-css"
          #refACCCategoryType="ngModel"
        >
          <option value="0">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Type </span>
        <select
          name="dv"
          id="dv"
          [(ngModel)]="dv"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refdv="ngModel"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refdv.invalid,
            'alert-warning':
              refdv.invalid && (refdv.dirty || refdv.touched || refdv.untouched)
          }"
        >
          <!--<option value="">Select Type</option>-->
          <option value="All">All</option>
          <option value="Pending">DV Creation Pending</option>
          <option value="LoanPending">Loan Creation Pending</option>
        </select>
      </div>
      <div class="col-md-2 mt-1">
        <button
          type="button"
          (click)="onSearchPartnerUpdtBookingList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-4 mt-3" [hidden]="!dataSourceLoan">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="row" [hidden]="!dataSourceLoan">
    <div class="col-md-12 mt-3">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporterLoanInfo="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="LoanId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >

            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanCreatedOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanCreatedOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Application_No }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAMount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAMount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMIAmount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DueDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Acc_Category">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Acc_Category }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetailLoan(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="btn font-size-12 button-btn"
              (click)="exportTable(exporterLoanInfo)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSize]="20"
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
