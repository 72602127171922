<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div
  class="row m-0 mt-4"
  *ngIf="
    PageAcess &&
    PageAcess !== undefined &&
    PageAcess !== '' &&
    PageAcess !== null &&
    PageAcess[0].View == 1
  "
>
  <form
    #f="ngForm"
    class="col-md-12 formborder"
    novalidate
    style="overflow: hidden"
  >
    <div>
      <div class="row m-0" *ngIf="isNew">
        <div class="col-md-3 p-1">
          <span class="required-lable"> Branch </span>
          <select
            name="Branch_Id"
            (change)="getAppNo_AND_LoanNo()"
            id="Branch_Id"
            #refBranch_Id="ngModel"
            required
            [disabled]="loanInfoModel.AppInfo.isDisable"
            [ngClass]="{
              'is-invalid': f.submitted && refBranch_Id.invalid,
              'alert-warning':
                refBranch_Id.invalid &&
                (refBranch_Id.dirty ||
                  refBranch_Id.touched ||
                  refBranch_Id.untouched)
            }"
            [(ngModel)]="loanInfoModel.AppInfo.Branch_Id"
            class="form-control input-text-css"
          >
            <option value="">Select Branch</option>
            <option
              *ngFor="let branch of BranchesList"
              [value]="branch.BranchId"
            >
              {{ branch.Branch_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 p-1" style="display: none">
          <span> Application No </span>
          <input
            name="ApplicationNo"
            id="ApplicationNo"
            #refApplicationNo="ngModel"
            [disabled]="loanInfoModel.AppInfo.isDisable"
            [(ngModel)]="loanInfoModel.AppInfo.ApplicationNo"
            readonly
            placeholder="ApplicationNo"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable"> Application Date </span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ApplicationDate"
              id="ApplicationDate"
              #refApplicationDate="ngModel"
              required
              [disabled]="loanInfoModel.AppInfo.isDisable"
              [ngClass]="{
                'is-invalid': f.submitted && refApplicationDate.invalid,
                'alert-warning':
                  refApplicationDate.invalid &&
                  (refApplicationDate.dirty ||
                    refApplicationDate.touched ||
                    refApplicationDate.untouched)
              }"
              [(ngModel)]="loanInfoModel.AppInfo.ApplicationDate"
              class="form-control input-text-css"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <!-- <input
            name="ApplicationDate"
            id="ApplicationDate"
            #refApplicationDate="ngModel"
            required
            [disabled]="loanInfoModel.AppInfo.isDisable"
            [ngClass]="{
              'is-invalid': f.submitted && refApplicationDate.invalid,
              'alert-warning':
                refApplicationDate.invalid &&
                (refApplicationDate.dirty ||
                  refApplicationDate.touched ||
                  refApplicationDate.untouched)
            }"
            [(ngModel)]="loanInfoModel.AppInfo.ApplicationDate"
            value="{{
              loanInfoModel.AppInfo.ApplicationDate | date : 'dd/MM/yyyy'
            }}"
            placeholder="DD/MM/YYYY"
            class="form-control input-text-css"
            [maxDate]="currentDate"
            [(bsValue)]="loanInfoModel.AppInfo.ApplicationDate"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable"> Loan Product </span>
          <select
            name="LoanProduct_Id"
            id="LoanProduct_Id"
            #refLoanProduct_Id="ngModel"
            required
            [disabled]="loanInfoModel.AppInfo.isDisable"
            [ngClass]="{
              'is-invalid': f.submitted && refLoanProduct_Id.invalid,
              'alert-warning':
                refLoanProduct_Id.invalid &&
                (refLoanProduct_Id.dirty ||
                  refLoanProduct_Id.touched ||
                  refLoanProduct_Id.untouched)
            }"
            [(ngModel)]="loanInfoModel.AppInfo.LoanProduct_Id"
            class="form-control input-text-css"
          >
            <option value="">Select Products</option>
            <ng-container *ngFor="let productg of ProductList">
              <option
                *ngIf="productg.Product_IsActive == true"
                [value]="productg.ProductId"
              >
                {{ productg.Product }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable"> Branch Head </span>
          <select
            name="SelectBranchHead"
            id="SelectBranchHead"
            #refSelectBranchHead="ngModel"
            required
            [ngClass]="{
              'is-invalid': f.submitted && refSelectBranchHead.invalid,
              'alert-warning':
                refSelectBranchHead.invalid &&
                (refSelectBranchHead.dirty ||
                  refSelectBranchHead.touched ||
                  refSelectBranchHead.untouched)
            }"
            [(ngModel)]="loanInfoModel.LoanInfo.SelectBranchHead"
            class="form-control input-text-css"
          >
            <option value="">Select Branch Head</option>
            <option *ngFor="let head of branchHeadData" [value]="head.EmpId">
              {{ head.Emp_FirstName + " " + head.Emp_LastName }}
            </option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="loanInfoModel.LoanInfo.isEdit">
        <div class="row m-0">
          <div class="col-md-3 p-1" *ngIf="!isNew">
            <span class="required-lable"> Application Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="picker2"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="ApplicationDate"
                id="ApplicationDate"
                #refApplicationDate="ngModel"
                required
                [disabled]="loanSummary.LoanNo != null"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refApplicationDate.invalid,
                  'alert-warning':
                    refApplicationDate.invalid &&
                    (refApplicationDate.dirty ||
                      refApplicationDate.touched ||
                      refApplicationDate.untouched)
                }"
                [(ngModel)]="loanInfoModel.AppInfo.ApplicationDate"
                [max]="currentDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable"> Source Type </span>
            <select
              name="SelectSourceType"
              id="SelectSourceType"
              #refSelectSourceType="ngModel"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refSelectSourceType.invalid,
                'alert-warning':
                  refSelectSourceType.invalid &&
                  (refSelectSourceType.dirty ||
                    refSelectSourceType.touched ||
                    refSelectSourceType.untouched)
              }"
              (change)="Get_PartnerForDropdown()"
              [(ngModel)]="loanInfoModel.LoanInfo.SelectSourceType"
              class="form-control input-text-css"
            >
              <option value="">Select Source Type</option>
              <option
                *ngFor="let lead of leadSourceDropdown"
                [value]="lead.Lead_Source"
              >
                {{ lead.Lead_Source }}
              </option>
            </select>
          </div>
          <ng-container
            *ngIf="
              loanInfoModel.LoanInfo.SelectSourceType == 'Agent' ||
              loanInfoModel.LoanInfo.SelectSourceType == 'DSA' ||
              loanInfoModel.LoanInfo.SelectSourceType == 'Dealer' ||
              loanInfoModel.LoanInfo.SelectSourceType == 'Sales Executive'
            "
          >
            <div class="col-md-3 p-1">
              <span class="required-lable"> Source Name</span>
              <select
                name="LeadSourceName"
                id="LeadSourceName"
                #refLeadSourceName="ngModel"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refLeadSourceName.invalid,
                  'alert-warning':
                    refLeadSourceName.invalid &&
                    (refLeadSourceName.dirty ||
                      refLeadSourceName.touched ||
                      refLeadSourceName.untouched)
                }"
                [(ngModel)]="loanInfoModel.LoanInfo.LeadSourceName"
                class="form-control input-text-css"
              >
                <option value="">Select Lead Reference</option>
                <option
                  *ngFor="let partner of partnerDropdown"
                  [value]="partner.PartnerId"
                >
                  {{ partner.Partner_Name }}
                </option>
              </select>
            </div>
          </ng-container>
          <ng-container
            *ngIf="loanInfoModel.LoanInfo.SelectSourceType == 'Reference'"
          >
            <div class="col-md-3 p-1">
              <span class="required-lable"> Source Name</span>
              <input
                type="text"
                #refLeadReference="ngModel"
                [(ngModel)]="loanInfoModel.LoanInfo.LeadReference"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refLeadReference.invalid,
                  'alert-warning':
                    refLeadReference.invalid &&
                    (refLeadReference.dirty ||
                      refLeadReference.touched ||
                      refLeadReference.untouched)
                }"
                placeholder="Lead Reference"
                name="LeadReference"
                id="LeadReference"
                class="form-control input-text-css"
              />
            </div>
          </ng-container>

          <div class="col-md-3 p-1">
            <span class="required-lable"> Source Area</span>
            <select
              name="SelectSourceArea"
              id="SelectSourceArea"
              #refSelectSourceArea="ngModel"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refSelectSourceArea.invalid,
                'alert-warning':
                  refSelectSourceArea.invalid &&
                  (refSelectSourceArea.dirty ||
                    refSelectSourceArea.touched ||
                    refSelectSourceArea.untouched)
              }"
              [(ngModel)]="loanInfoModel.LoanInfo.SelectSourceArea"
              class="form-control input-text-css"
            >
              <option value="">Select Source Area</option>
              <ng-container *ngFor="let area of areaData">
                <option
                  *ngIf="area.Type.trim() == 'Source Area'"
                  [value]="area.Int_Id"
                >
                  {{ area.Area_Name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="col-md-3 p-1">
            <span class="required-lable"> Sales Executive</span>
            <select
              name="SalesExecutive"
              id="SalesExecutive"
              #refSalesExecutive="ngModel"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refSalesExecutive.invalid,
                'alert-warning':
                  refSalesExecutive.invalid &&
                  (refSalesExecutive.dirty ||
                    refSalesExecutive.touched ||
                    refSalesExecutive.untouched)
              }"
              [(ngModel)]="loanInfoModel.LoanInfo.SalesExecutive"
              class="form-control input-text-css"
            >
              <option value="">Select Sales Executive</option>
              <option
                *ngFor="let collection of SalesExecutiveData"
                [value]="collection.EmpId"
              >
                {{ collection.EmpName }}
              </option>
            </select>
          </div>

          <div class="col-md-3 p-1">
            <span> Collection Executive</span>
            <select
              name="CollectionExecutive"
              id="CollectionExecutive"
              #refCollectionExecutive="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.CollectionExecutive"
              class="form-control input-text-css"
            >
              <option value="0">Select Collection Executive</option>
              <option
                *ngFor="let collection of CollectionExecutiveData"
                [value]="collection.EmpId"
              >
                {{ collection.Emp_FirstName }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span> Collection Area</span>
            <select
              name="SelectArea"
              id="SelectArea"
              (change)="changeArea()"
              #refSelectArea="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.SelectArea"
              class="form-control input-text-css"
            >
              <option value="0">Select Collection Area</option>
              <!-- <option *ngFor="let area of areaData" [value]="area.Int_Id"> {{area.Area_Name}} </option> -->
              <ng-container *ngFor="let area of areaData">
                <option
                  *ngIf="area.Type.trim() == 'Collection Area'"
                  [value]="area.Int_Id"
                >
                  {{ area.Area_Name }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span> Collection Sub Area</span>
            <select
              name="SelectSubarea"
              id="SelectSubarea"
              #refSelectSubarea="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.SelectSubarea"
              class="form-control input-text-css"
            >
              <option value="0">Select Collection SubArea</option>
              <option
                *ngFor="let subarea of subAreaData"
                [value]="subarea.Int_Id"
              >
                {{ subarea.Sub_Area_Name }}
              </option>
            </select>
          </div>
          <div
            class="col-md-3 p-1"
            *ngIf="loanSummary.LoanNo != '' && loanSummary.LoanNo != undefined"
          >
            <span> CRC Agency Loan Type </span>
            <select
              name="SelectCRCType"
              id="SelectCRCType"
              #refSelectCRCType="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.CRC_LoanType"
              [ngClass]="{
                'is-invalid': f.submitted && refSelectCRCType.invalid,
                'alert-warning':
                  refSelectCRCType.invalid &&
                  (refSelectCRCType.dirty ||
                    refSelectCRCType.touched ||
                    refSelectCRCType.untouched)
              }"
              [disabled]="inViewMode"
              class="form-control input-text-css"
              required
            >
              <option value="0">Select Loan Type</option>
              <option *ngFor="let Type of CRC_Type" [value]="Type.Id">
                {{ Type.Value + "[" + Type.Code + "]" }}
              </option>
            </select>
          </div>

          <div
            class="col-md-3 p-1"
            *ngIf="loanSummary.LoanNo != '' && loanSummary.LoanNo != undefined"
          >
            <span> SUIT FILED STATUS </span>
            <select
              name="Select_SUITFILEDSTATUS"
              id="Select_SUITFILEDSTATUS"
              #refSelect_SUITFILEDSTATUS="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.SUITFILEDSTATUS"
              [disabled]="inViewMode"
              class="form-control input-text-css"
            >
              <option value="0">Select SUIT FILED STATUS</option>
              <option
                *ngFor="let Type of SUITFILEDSTATUSList"
                [value]="Type.Id"
              >
                {{ Type.Value }}
              </option>
            </select>
          </div>

          <div
            class="col-md-3 p-1"
            *ngIf="loanSummary.LoanNo != '' && loanSummary.LoanNo != undefined"
          >
            <span> Loan Segment </span>
            <select
              name="select_Loan_Segment"
              id="select_Loan_Segment"
              #refselect_Loan_Segment="ngModel"
              [(ngModel)]="loanInfoModel.LoanInfo.Loan_Segment"
              [disabled]="inViewMode"
              class="form-control input-text-css"
            >
              <option value="0">Select Loan Segment</option>

              <option *ngFor="let Type of loanSegmentList" [value]="Type.Id">
                {{ Type.Value }}
              </option>
            </select>
          </div>

          <div
            class="col-md-2 mt-3"
            *ngIf="loanSummary.LoanNo != '' && loanSummary.LoanNo != undefined"
          >
            <mat-checkbox
              name="IsSMSAllow"
              [(ngModel)]="loanInfoModel.LoanInfo.IsSMSAllow"
              class="mt-3"
            >
              SMS Allow
            </mat-checkbox>
          </div>
          <div
            class="col-md-2 mt-3"
            *ngIf="loanSummary.LoanNo != '' && loanSummary.LoanNo != undefined"
          >
            <mat-checkbox
              name="IsCibil"
              [(ngModel)]="loanInfoModel.LoanInfo.IsCibil"
              class="mt-3"
            >
              Cibil Allow
            </mat-checkbox>
          </div>
          <div class="col-md-12 p-1">
            <span class="required-lable"> Loan Purpose </span>
            <textarea
              [(ngModel)]="loanInfoModel.LoanInfo.LoanPurpose"
              rows="3"
              maxlength="150"
              #refLoanPurpose="ngModel"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refLoanPurpose.invalid,
                'alert-warning':
                  refLoanPurpose.invalid &&
                  (refLoanPurpose.dirty ||
                    refLoanPurpose.touched ||
                    refLoanPurpose.untouched)
              }"
              placeholder="Loan Purpose"
              name="LoanPurpose"
              id="LoanPurpose"
              class="form-control input-text-css"
            ></textarea>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loanInfoModel.LoanInfo.isEdit">
        <div class="m-0 clientOuterDiv" *ngIf="loanInfoModel.LoanInfo">
          <div class="leftPart">
            <div
              style="
                padding: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                style="
                  height: 7.8rem;
                  width: 8rem;
                  border-radius: 5%;
                  object-fit: cover;
                "
                src="{{ CustomerPicDetail }}"
                alt="Profile Pic"
              />
            </div>

            <!-- src="{{ customerProfileBaseUrl }}{{
              customerData.CustomerId
            }}/{{ customerData.ProfilePic }}" -->

            <div class="personalDetails">
              <div style="display: flex; flex-direction: column; gap: 2px">
                <B>
                  <span
                    style="
                      font-weight: bold;
                      font-size: 1.08rem;
                      color: #000000;
                    "
                    >{{ customerData.Customer }}</span
                  ></B
                >

                <span
                  style="
                    font-weight: semi-bold;
                    font-size: 14px;
                    color: #000000;
                  "
                  >{{ customerData.Relation_Name }}
                </span>
              </div>

              <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Mobile No.</div>
                <!-- <div
                  style="
                    color: #1d4ed8;
                    float: right;
                    font-size: 1.125rem;
                    font-weight: 500;

                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.PhoneNo }}

                  <span>
                    <i
                      *ngIf="customerData.PhoneNoIsVerified === true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </span>
                </div> -->

                <div
                  *ngIf="currentUser.IsMasking"
                  style="
                    color: #1d4ed8;
                    float: right;
                    font-size: 1.125rem;
                    font-weight: 500;

                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.PhoneNo | mask }}

                  <span>
                    <i
                      *ngIf="customerData.PhoneNoIsVerified === true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </span>
                </div>

                <div
                  *ngIf="!currentUser.IsMasking"
                  style="
                    color: #1d4ed8;
                    float: right;
                    font-size: 1.125rem;
                    font-weight: 500;

                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.PhoneNo }}

                  <span>
                    <i
                      *ngIf="customerData.PhoneNoIsVerified === true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </span>
                </div>

               
              </div>

              <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Email Id:</div>
                <div
                  style="
                    color: #1d4ed8;
                    font-size: 1.125rem;
                    font-weight: 500;

                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.Email ? "customerData.Email" : "Nil" }}
                </div>
              </div>
              <!--  -->

              <!-- <span
                  *ngIf="
                    row.Present_LatLong != '' &&
                    row.Present_LatLong != null &&
                    row.Present_LatLong != ','
                  "
                >
                  {{ row.Present_Address }}
                  Location-
                  <a
                    target="_blank"
                    [href]="
                      'http://maps.google.com/maps?q=' + row.Present_LatLong
                    "
                    ><i class="fa-solid fa-location-dot"></i
                  ></a>
                </span>
                <span
                  *ngIf="
                    row.Present_LatLong == '' || row.Present_LatLong == null
                  "
                >
                  {{ row.Present_Address }}
                </span> -->

              <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Present Address:</div>
                <div
                  style="
                    color: black;
                    float: right;
                    font-size: 1rem;

                    display: flex;
                    flex: 1;
                  "
                >
                  <span
                    *ngIf="
                      CustomerAddress.Present_LatLong != '' &&
                      CustomerAddress.Present_LatLong != null &&
                      CustomerAddress.Present_LatLong != ','
                    "
                  >
                    {{ CustomerAddress.Present_Address }}
                    Location-
                    <a
                      target="_blank"
                      [href]="
                        'http://maps.google.com/maps?q=' +
                        CustomerAddress.Present_LatLong
                      "
                      ><i class="fa-solid fa-location-dot"></i
                    ></a>
                  </span>
                  <span
                    *ngIf="
                      CustomerAddress.Present_LatLong == '' ||
                      CustomerAddress.Present_LatLong == null
                    "
                  >
                    {{ CustomerAddress.Present_Address }}
                  </span>
                </div>
              </div>

              <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Permanent Address:</div>
                <div
                  style="
                    color: black;
                    float: right;
                    font-size: 1rem;

                    display: flex;
                    flex: 1;
                  "
                >
                  <span
                    *ngIf="
                      CustomerAddress.Permanent_LatLong != '' &&
                      CustomerAddress.Permanent_LatLong != null &&
                      CustomerAddress.Permanent_LatLong != ','
                    "
                  >
                    {{ CustomerAddress.Permanent_LatLong }}
                    Location-
                    <a
                      target="_blank"
                      [href]="
                        'http://maps.google.com/maps?q=' +
                        CustomerAddress.Permanent_LatLong
                      "
                      ><i class="fa-solid fa-location-dot"></i
                    ></a>
                  </span>
                  <span
                    *ngIf="
                      CustomerAddress.Permanent_LatLong == '' ||
                      CustomerAddress.Permanent_LatLong == null ||
                      CustomerAddress.Permanent_LatLong == ','
                    "
                  >
                    {{ CustomerAddress.Permanent_Address }}
                  </span>
                </div>
              </div>

              <!-- <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Religion</div>
                <div
                  style="
                    color: #000000;
                    float: right;
                    font-size: 1.125rem;
                    font-weight: 500;
                   
                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.Religion }}
                </div>
              </div> -->
              <!-- <div class="clientPersonalDetails">
                <div class="clientDetailsColName">Caste</div>
                <div
                  style="
                    color: #000000;
                    float: right;
                    font-size: 1.125rem;
                    font-weight: 500;
                   
                    display: flex;
                    flex: 1;
                  "
                >
                  {{ customerData.Cast }}
                </div>
              </div> -->
            </div>

            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
              "
            >
              <button
                type="button"
                (click)="viewCustomerDetails()"
                style="width: max-content !important"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!f.form.valid"
              >
                View Details
              </button>
            </div>
          </div>

          <div class="rightPart">
            <div class="loanDetails">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 5px;
                  margin-bottom: 10px;
                "
              >
                <div class="col-md-6 bg-success1">Loan Details</div>
                <div>
                  <button
                    type="button"
                    (click)="
                      loanInfoModel.LoanInfo.isEdit =
                        !loanInfoModel.LoanInfo.isEdit
                    "
                    *ngIf="
                      !loanInfoModel.LoanInfo.isEdit &&
                      PageAcess &&
                      PageAcess[0].Edit == 1 &&
                      !loanSummary.CloseDate
                    "
                    class="btn font-size-12 button-btn"
                    mat-raised-button
                    style="border-radius: 5px"
                  >
                    Edit
                  </button>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <h6>Loan No</h6>
                    <p>
                      {{ loanSummary.LoanNo }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Loan Date</h6>
                    <p>{{ loanSummary.LoanDate }}</p>
                  </div>

                  <div class="col-md-3">
                    <h6>Application No</h6>
                    <p>
                      {{ loanSummary.ApplicationNo }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Borrower</h6>
                    <p>{{ loanSummary.Customer }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <h6>Branch</h6>
                    <p>{{ loanSummary.Branch }}</p>
                  </div>
                  <div class="col-md-3">
                    <h6>Loan Amount</h6>
                    <p>{{ loanSummary.LoanAmount }}</p>
                  </div>
                  <div class="col-md-3">
                    <h6>Loan Acc Type</h6>
                    <p>
                      {{ loanSummary.Loan_Acc_Type }}
                    </p>
                  </div>

                  <div class="col-md-3">
                    <h6>Product</h6>
                    <p>{{ loanSummary.Product }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <h6>Product Category</h6>
                    <p>
                      {{ loanSummary.Product_Category }}
                    </p>
                  </div>

                  <div class="col-md-3">
                    <h6>Installment Amount</h6>
                    <p>
                      {{ loanSummary.EMIAmount }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Expiry Date</h6>
                    <p>
                      {{ loanSummary.ExpiryDate }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Close Date</h6>
                    <p>
                      {{ loanSummary.CloseDate }}
                    </p>
                  </div>
                </div>

                <!-- <div class="row">
                  <div
                    class="col-md-3"
                    *ngIf="
                      loanSummary.LoanNo != null && loanSummary.LoanNo != ''
                    "
                  >
                    <h6>Cibil Allow</h6>
                    <p>{{ loanInfoModel.LoanInfo.IsCibil ? "Yes" : "No" }}</p>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="loanInfoModel.LoanInfo.NOC_Reason"
                  >
                    <h6>
                      {{
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? "NOC Un-Block"
                          : "NOC Block"
                      }}
                      Remarks
                    </h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.NOC_Reason }}
                    </p>
                  </div>

                  <div class="col-md-3">
                    <button
                      type="button"
                      (click)="nocModel()"
                      mat-raised-button
                      color="primary"
                      *ngIf="
                        loanSummary.LoanNo != null && loanSummary.LoanNo != ''
                      "
                      [class.spinner]="loading"
                      [disabled]="!f.form.valid"
                      style="line-height: 22px !important; font-size: 12px"
                      [style.background-color]="
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? 'rgba(10, 46, 108, 0.7019607843)'
                          : 'red'
                      "
                    >
                      {{
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? "Block NOC"
                          : "Un-Block NOC"
                      }}
                    </button>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="loanInfoModel.LoanInfo.Is_Noc_Block === 1"
                  >
                    <p style="color: #db1f1f; font-weight: bold">
                      NOC Is Blocked !!!
                    </p>
                  </div>
                </div> -->
              </div>
            </div>

            <div
              class="deatails"
              *ngIf="
                loanSummary.CloseDate == null ||
                loanSummary.CloseDate == '' ||
                loanSummary.CloseDate == undefined
              "
            >
              <div class="col-md-6 bg-success1" style="margin-bottom: 5px">
                Installment's Details
              </div>
              <table class="table">
                <thead></thead>
                <tr>
                  <th>&nbsp;</th>
                  <th class="text-center">
                    <h6>Total</h6>
                  </th>
                  <th class="text-center">
                    <h6>Matured</h6>
                  </th>
                  <th class="text-center">
                    <h6>Remaining</h6>
                  </th>
                  <th class="text-center">
                    <h6>Received</h6>
                  </th>
                  <th class="text-center">
                    <h6>Overdue</h6>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">
                    <h6><B>Nos.</B></h6>
                  </th>
                  <td class="text-center">
                    <h6>
                      {{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      {{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      {{
                        EMIDetails != undefined ? EMIDetails.Remaining_EMI : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      {{
                        EMIDetails != undefined ? EMIDetails.Received_EMI : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
                          : ""
                      }}
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th class="text-center">
                    <h6>Amount</h6>
                  </th>
                  <td class="text-center">
                    <h6>
                      &#8377;
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.TotalEMI_Amount
                          : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      &#8377;
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.MatureEMI_Amount
                          : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      &#8377;
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.Remaining_EMI_Amount
                          : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      &#8377;
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.Received_EMI_Amount
                          : ""
                      }}
                    </h6>
                  </td>
                  <td class="text-center">
                    <h6>
                      &#8377;
                      {{
                        EMIDetails != undefined
                          ? EMIDetails.MatureEMI_Amount -
                            EMIDetails.Received_EMI_Amount
                          : ""
                      }}
                    </h6>
                  </td>
                </tr>
              </table>
            </div>

            <div class="collectionDetails">
              <div class="col-md-6 bg-success1 mb-4">Collection Details</div>
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <h6>Application Date</h6>
                    <p>
                      {{
                        loanInfoModel.AppInfo.ApplicationDate
                          | date : "dd/MM/yyyy"
                      }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Branch Head</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.BranchHeadName }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Source Type</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.SourceType }}
                    </p>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="loanInfoModel.LoanInfo.SourceType == 'Reference'"
                  >
                    <h6>Source Name</h6>
                    <p>{{ loanInfoModel.LoanInfo.LeadReference }}</p>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="
                      loanInfoModel.LoanInfo.SourceType == 'DSA' ||
                      loanInfoModel.LoanInfo.SourceType == 'Agent' ||
                      loanInfoModel.LoanInfo.SourceType == 'Sales Executive' ||
                      loanInfoModel.LoanInfo.SourceType == 'Dealer'
                    "
                  >
                    <h6>Source Name</h6>
                    <p>{{ loanInfoModel.LoanInfo.Partner_Name }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <h6>Source Area</h6>
                    <p>{{ loanInfoModel.LoanInfo.SourceAreaName }}</p>
                  </div>
                  <div class="col-md-3">
                    <h6>Sales Executive</h6>
                    <p>{{ loanInfoModel.LoanInfo.SalesExecutiveName }}</p>
                  </div>
                  <div class="col-md-3">
                    <h6>Collection Executive</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.CollectionExecutiveName }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>Collection Area</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.AreaName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <h6>Collection Sub Area</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.SubareaName }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <h6>CRC Type</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.CRC_LoanTypeName }}
                    </p>
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="
                      loanInfoModel.LoanInfo.SUITFILEDSTATUS_Name != '' &&
                      loanInfoModel.LoanInfo.SUITFILEDSTATUS_Name != null
                    "
                  >
                    <h6>SUIT FILED STATUS</h6>
                    <p>
                      {{ loanInfoModel.AppInfo.SUITFILEDSTATUS_Name }}
                    </p>
                  </div>

                  <div class="col-md-3">
                    <h6>Loan Segment</h6>
                    <p>
                      {{ loanSegementView }}
                    </p>
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="
                      loanSummary.LoanNo != null && loanSummary.LoanNo != ''
                    "
                  >
                    <h6>SMS Allow</h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.IsSMSAllow ? "Yes" : "No" }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-md-3"
                    *ngIf="
                      loanSummary.LoanNo != null && loanSummary.LoanNo != ''
                    "
                  >
                    <h6>Cibil Allow</h6>
                    <p>{{ loanInfoModel.LoanInfo.IsCibil ? "Yes" : "No" }}</p>
                  </div>

                  <div class="col-md-3">
                    <button
                      type="button"
                      (click)="nocModel()"
                      mat-raised-button
                      color="primary"
                      *ngIf="
                        loanSummary.LoanNo != null && loanSummary.LoanNo != ''
                      "
                      [class.spinner]="loading"
                      [disabled]="!f.form.valid"
                      style="line-height: 22px !important; font-size: 12px"
                      [style.background-color]="
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? 'rgba(10, 46, 108, 0.7019607843)'
                          : 'red'
                      "
                    >
                      {{
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? "Block NOC"
                          : "Un-Block NOC"
                      }}
                    </button>
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="loanInfoModel.LoanInfo.NOC_Reason"
                  >
                    <h6>
                      {{
                        loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
                        loanInfoModel.LoanInfo.Is_Noc_Block === null
                          ? "NOC Un-Block"
                          : "NOC Block"
                      }}
                      Remarks
                    </h6>
                    <p>
                      {{ loanInfoModel.LoanInfo.NOC_Reason }}
                    </p>
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="loanInfoModel.LoanInfo.Is_Noc_Block === 1"
                  >
                    <p style="color: #db1f1f; font-weight: bold">
                      NOC Is Blocked !!!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <hr />
  </form>

  <div class="row m-0 mt-3 col-md-12">
    <div
      class="col-md-12 text-right d-flex align-items-center justify-content-end"
    >
      <button
        type="button"
        (click)="SaveLoanInfo()"
        class="mt-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!f.form.valid"
      >
        Next <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="nocBlocked"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{
            loanInfoModel.LoanInfo.Is_Noc_Block === 0 ||
            loanInfoModel.LoanInfo.Is_Noc_Block === null
              ? "NOC Block"
              : "NOC Un-Block"
          }}
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #ff="ngForm" novalidate>
          <div class="col-md-12 row">
            <div class="col-md-12 p-1">
              <span class="required-lable">Remarks</span>
            </div>
            <div class="col-md-12 p-1" style="width: 100%">
              <input
                (keyup)="onKey($event)"
                type="text"
                name="Remarks"
                id=""
                [(ngModel)]="remarksNOC"
                class="form-control input-text-css"
                required
                #refremarksNOC="ngModel"
                placeholder="Remarks"
                [ngClass]="{
                  'is-invalid': ff.submitted && refremarksNOC.invalid,
                  'alert-warning':
                    refremarksNOC.invalid &&
                    (refremarksNOC.dirty ||
                      refremarksNOC.touched ||
                      refremarksNOC.untouched)
                }"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="OnSave()"
          [disabled]="!ff.form.valid"
          class="btn font-size-12 button-btn"
          data-dismiss="modal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
