<div class="grid-container ">
  <div class="  grid-row-con">
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Process</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Executive </div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Status</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Assign On</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Completed On</div>
    </div>
    <div class="grid-items ">
      <div class="grid-sub-item-value font-weight-bold"> TAT (Hrs) </div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"> Remark </div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"> LatLong </div>
    </div>
  </div>

  <div class=" mt-1 grid-row-con" *ngFor="let row of TatReportsList">



    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Process</div> -->
      <div class="grid-sub-item-value"> {{row.Process}} </div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Executive </div> -->
      <div class="grid-sub-item-value"> {{row.ProcessExecutive}} </div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Status</div> -->
      <div class="grid-sub-item-value"> {{row.ProcessStatus}} </div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Assign On</div> -->
      <div class="grid-sub-item-value"> {{row.AssignedOn}} </div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Completed On</div> -->
      <div class="grid-sub-item-value"> {{row.CompletedOn}} </div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> TAT (Hrs) </div> -->
      <div class="grid-sub-item-value">{{row.TAT_Hr}}</div>
    </div>

    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Remark </div> -->
      <div class="grid-sub-item-value"> {{row.Remark}} </div>
    </div>
    <div class="grid-items">
      <!-- <div class="grid-sub-item"> Remark </div> -->
      <div class="grid-sub-item-value">
        <a target="_blank"  *ngIf="row.LatLong!='' && row.LatLong!=null && row.LatLong!=','" [href]="'http://maps.google.com/maps?q=' + row.LatLong">
          <i class="fa-solid fa-location-dot"></i>
        </a>
      </div>
    </div>
  </div>
</div>
