import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'input[numbersOnly]'
})
export class NumberDirective {

    @Input('numbersOnly') numbersOnly: boolean;
    _keyCode: any[] = [];
    str = '';
    constructor(private _el: ElementRef) {
    }
    // @HostListener('keydown', ['$event']) onkeyDown(event: any) {
    //     if (event.shiftKey || event.altKey || event.ctrlKey) {
    //         event.preventDefault();
    //     } else {
    //         if (this.numbersOnly) {
    //             this._keyCode = [8, 9, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 190];
    //             if ((event.keyCode == 110 || event.keyCode == 190) && ((this._el.nativeElement.value.length == 0 || this._el.nativeElement.value.indexOf('.') > 0))) {
    //                 event.preventDefault();
    //             }
    //             else if (this._keyCode.indexOf(event.keyCode) == -1) {
    //                 event.preventDefault();
    //             }
    //         }
    //         else {
    //             this._keyCode = [8, 9, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
    //             if (this._keyCode.indexOf(event.keyCode) == -1) {
    //                 event.preventDefault();
    //             }
    //         }
    //     }
    // }
    @HostListener('input', ['$event']) onInputChange(event:any) {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        if ( initalValue !== this._el.nativeElement.value) {
          event.stopPropagation();
        }
      }
}



@Directive({
    selector: 'input[maxLength]'
})
export class MaxLengthDirective {

    constructor(private _el: ElementRef) { }
    @Input('maxLength') maxLength: number;

    @HostListener('keydown', ['$event']) onkeyDown(event) {
        if (event.keyCode !== 8 && this._el.nativeElement.value.length >= this.maxLength) {
            event.preventDefault();
        }
    }
}


@Directive({
    selector: 'input[dateConvert]'
})
export class DateConvertDirective {

    _keyCode: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab','Delete'];
    private el: HTMLInputElement;
    constructor(private _el: ElementRef) {
        this.el = this._el.nativeElement;
    }
    ngOnInit() {
        setTimeout(() => {
            this.el.value = this.formateValue(this.el.value || '');
        }, 200)
    }
    @HostListener('keydown', ['$event']) onkeyDown(event: any) {
        if (this._keyCode.indexOf(event.key) == -1) {
            event.preventDefault();
            return;
        }
        this.el = this.el
    }
    @HostListener('keyup', ['$event']) onkeyUp(event: any) {

        if (this.el.value.length > 10) {
            event.preventDefault();
            this.el.value = this.el.value.substring(0, 10);
            return;
        }
        if (event.key == 'Backspace') {
            return;
        }
        this.el.value = this.formateValue(this.el.value || '');
    }
    formateValue(value: any) {
        if (value.indexOf('/') == 1) {
            return '0' + value;
        }
        if (value.indexOf('/') == -1 && value.length == 2) {
            return value + '/';
        }
        if (value.indexOf('/') !== -1 && value.length == 5) {
            return value + '/';
        }
        return value;
    }
}


@Directive({
    selector: 'input[alphaOnly]'
})
export class AlphaDirective {

    constructor(private _el: ElementRef) {
    }
    @HostListener('keydown', ['$event']) onkeyDown(event: any) {
        if (/[a-zA-Z]/.test(event.key)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }
}

@Directive({
  selector: 'input[alphaNumericOnly]'
})
export class AlphaNumericDirective {

  constructor(private _el: ElementRef) {
  }
  @HostListener('keydown', ['$event']) onkeyDown(event: any) {
    if (/a-zA-Z0–9 _/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}

@Directive({
  selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
