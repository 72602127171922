import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
declare var $: any;

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { LosService } from "src/app/_LOS/services/los.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-branch-wise-due-vs-collection",
  templateUrl: "./branch-wise-due-vs-collection.component.html",
  styleUrls: ["./branch-wise-due-vs-collection.component.scss"],
})
export class BranchWiseDueVsCollectionComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  BranchesList: any = [];

  DataSource: any = [];
  DataSourceModel: any = [];
  displayedColumns: string[] = [
    "Id",
    "Name",
    "Due_Case",
    "EMI_Due",
    "Total_Principle_Due",
    "Total_Interest_Due",

    "Received_Case",
    "EMI_Collection",
    "Total_Principle_Received",
    "Total_Interest_Received",

    "Action",
  ];
  Name: any = "";
  displayedColumnsModel: string[] = [];
  //Form value
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  FileCheckingList: any;
  ToDate: any = new Date();
  SearchBy: any = "Branch";
  AccountingCatDropdown: any[] = [];
  LoanAccountCategory: any = "0";
  FyearId: any;
  maxDate: any;
  minDate: any;
  JsonData: any[] = [];
  JsonDataDetail: any[] = [];
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator, static: true })
  paginatorlist: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 55000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private lmsService: LmsService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Due vs Collection");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();

    this.Get_Loan_Accounting_Category_ForDropdown();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          this.DataSource = null;
        });
    });
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccountingCatDropdown = res;
      });
  }
  onSearchBusinessData() {
    this.showSpinner = true;
    this.reportsService
      .Report_Branch_Wise_DUE_VS_Collection({
        From: this.FromDate,
        To: this.ToDate,
        SearchBy: this.SearchBy,
        LoginUserId: this.currentUser.userId,
        LoanAccountCategory: this.LoanAccountCategory,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
          this.showSpinner = false;
          this.getReport();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
          this.DataSource = null;
        }
      });
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  exportexcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "branch_wise_duevscoll_summary"
    ) as HTMLTableElement;
    const worksheetName = "DUE Vs COLLECTION SUMMARY"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  exportexcelDetail(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "branch_wise_duevscoll_detail"
    ) as HTMLTableElement;
    const worksheetName = "DUE Vs COLLECTION DETAILED"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  getDataforTable(res) {
    this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
    this.DataSource.sort = this.sort;
    this.DataSource.paginator = this.paginator;
    this.showSpinner = false;
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }

  totaltypewiseCases(type) {
    if (
      this.DataSource != null &&
      this.DataSource != undefined &&
      this.DataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      //return sum > 0 ? sum.toFixed(2) : 0;
      return sum;
    }
    return 0;
  }

  totaltypewise(type) {
    if (
      this.DataSource != null &&
      this.DataSource != undefined &&
      this.DataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      //return sum > 0 ? sum.toFixed(2) : 0;
      return sum.toFixed(2);
    }
    return 0;
  }

  totaltypewise1(type) {
    if (
      this.DataSourceModel != null &&
      this.DataSourceModel != undefined &&
      this.DataSourceModel != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.DataSourceModel.filteredData.length; i++) {
        sum += Number(this.DataSourceModel.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  goToDetails(row) {
    this.showSpinner = true;
    this.paginatorlist.pageIndex = 0;
    this.Name = row.Name;
    this.displayedColumnsModel = [
      "Id",
      "LoanAcNo",
      "CustomerName",
      "DueDate",
      "EMIType",
      "EMI_Due",
      "Total_Principle_Due",
      "Total_Interest_Due",
      "EMI_Collection",
      "Total_Principle_Received",
      "Total_Interest_Received",
      "Last_Recv_Date",
      "Last_Recv_Amount",
      "SourceType",
      "SourceName",
    ];

    this.reportsService
      .Report_Branch_Wise_DUE_VS_Collection_Detail({
        From: this.FromDate,
        To: this.ToDate,
        Id: row.Id,
        SearchBy: this.SearchBy,
        LoginUserId: this.currentUser.userId,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.JsonDataDetail = JSON.parse(JSON.stringify(res));
          console.log("RES", this.JsonDataDetail);
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginatorlist;
          $("#ViewBusinessCollectionDetails").modal("show");
          $("#ViewBusinessCollectionDetails").css("z-index", "1050");
          this.showSpinner = false;
        } else {
          this.showSpinner = true;
          this.DataSourceModel = null;
        }
      });
  }
  applyFilter(filterValue: string) {
    this.DataSourceModel.filter = filterValue.trim().slice();
  }
  getReport() {
    this.reportsService
      .Report_Branch_Wise_DUE_VS_Collection_Detail({
        From: this.FromDate,
        To: this.ToDate,
        Id: 0,
        SearchBy: "",
        LoginUserId: this.currentUser.userId,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        console.log("Result", this.FileCheckingList);
        //this.exportAsExcelFile(res, "Business vs Collection");
        this.showSpinner = false;
      });
  }

  onCloseBusinessCollectionDetails() {
    $("#ViewBusinessCollectionDetails").modal("hide");
    this.Name = "";
  }

  exportDetail(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("detailExport") as HTMLTableElement;
    const worksheetName = "DUE Vs COLLECTION DETAILED"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
