<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-3 mt-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        style="display: none"
        class="form-control input-text-css"
        (change)="changeFilter()"
        [(ngModel)]="status"
      >
        <option value="Active">Active</option>
        <option value="Deactive">Deactive</option>
      </select>
    </div>
    <div class="col-md-6 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="OnOpenProductModal()"
      >
        New Product
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ProductId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 40px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 40px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product Category
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ShortName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Short Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ShortName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CRC">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          CRC
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CRC }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="FI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          FI
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.FI }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="TVR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          TVR
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TVR }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="MultipleTranche">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Multiple Tranche
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.MultipleTranche }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Insurance">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Insurancee
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Insurance }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerReference">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Reference
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerReference }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="NACHPDC">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          NACH-PDC
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.NACHPDC }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ActiveStatus">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Active Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div
            *ngIf="row.Product_IsActive == true"
            style="width: 100%; color: #02e47c"
          >
            {{ row.ActiveStatus }}
          </div>
          <div
            *ngIf="row.Product_IsActive == false"
            style="width: 100%; color: orangered"
          >
            {{ row.ActiveStatus }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fa fa-trash style-delete"
            (click)="onDeleteProduct(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToProductDetail(row)"
            style="margin-left: 16px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewProductModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #dbf="ngForm" (ngSubmit)="onSaveNewProduct()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add New Product
          </h6>
          <button
            type="button"
            (click)="OnCloseProductModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Category : </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="SelectProductCategory"
                id="SelectProductCategory"
                #refSelectProductCategory="ngModel"
                [ngClass]="{
                  'is-invalid':
                    dbf.submitted && refSelectProductCategory.invalid,
                  'alert-warning':
                    refSelectProductCategory.invalid &&
                    (refSelectProductCategory.dirty ||
                      refSelectProductCategory.touched ||
                      refSelectProductCategory.untouched)
                }"
                [(ngModel)]="SelectProductCategory"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Product</option>
                <option
                  *ngFor="let productCategory of ProductCategoryDropdown"
                  [value]="productCategory.ProductCatId"
                >
                  {{ productCategory.Product_Category }}
                </option>
              </select>
            </div>
          </div>

          <div
            *ngIf="isShowProductCategory == true"
            class="row m-0"
            style="margin: 10px 0px !important"
          >
            <div class="col-md-4 p-1"></div>
            <div class="col-md-8 p-1">
              <div class="row m-0 align-items-end add-new-product">
                <div class="col-8 p-1">
                  <span> Add New Product Category: </span>
                  <input
                    type="text"
                    [(ngModel)]="NewProductCategoryName"
                    placeholder="Product Category"
                    name="NewProductCategoryName"
                    id="NewProductCategoryName"
                    class="form-control input-text-css mt-2"
                  />
                </div>
                <div class="col-4 p-1 text-center">
                  <i
                    class="fa fa-save mr-3"
                    title="Save"
                    (click)="saveProductCategory()"
                    style="cursor: pointer; color: green; font-size: large"
                  ></i>
                  <i
                    class="fa fa-trash style-delete"
                    title="Delete"
                    (click)="onDeleteProductCat()"
                    style="cursor: pointer; font-size: large"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Name : </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refProductName="ngModel"
                [ngClass]="{
                  'is-invalid': dbf.submitted && refProductName.invalid,
                  'alert-warning':
                    refProductName.invalid &&
                    (refProductName.dirty ||
                      refProductName.touched ||
                      refProductName.untouched)
                }"
                [(ngModel)]="ProductName"
                placeholder="Product Name"
                name="ProductName"
                id="ProductName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Short Name : </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refProductShortName="ngModel"
                [ngClass]="{
                  'is-invalid': dbf.submitted && refProductShortName.invalid,
                  'alert-warning':
                    refProductShortName.invalid &&
                    (refProductShortName.dirty ||
                      refProductShortName.touched ||
                      refProductShortName.untouched)
                }"
                [(ngModel)]="ProductShortName"
                placeholder="Product Short Name"
                name="ProductShortName"
                id="ProductShortName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> CRC Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="CRC_Co_Browser"
                  id="CRC-Co-Browser"
                  name="CRC-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="CRC_Guarantor"
                  id="CRC-Guarantor"
                  name="CRC-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> FI Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="FI_Co_Browser"
                  id="FI-Co-Browser"
                  name="FI-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="FI_Guarantor"
                  id="FI-Guarantor"
                  name="FI-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> TVR Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="TVR_Co_Browser"
                  id="TVR-Co-Browser"
                  name="TVR-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="TVR_Guarantor"
                  id="TVR-Guarantor"
                  name="TVR-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> Multiple Tranche Allow : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-12 p-1">
                <mat-checkbox
                  [(ngModel)]="multipleTrunche"
                  id="multipleTrunche"
                  name="multipleTrunche"
                >
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span>LMS : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-3 p-1">
                <mat-checkbox
                  [(ngModel)]="Insurance"
                  id="Insurance"
                  name="Insurance"
                >
                  Insurance
                </mat-checkbox>
              </div>
              <div class="col-3 p-1">
                <mat-checkbox
                  [(ngModel)]="CustomerRefrence"
                  id="CustomerRefrence"
                  name="CustomerRefrence"
                >
                  Customer Refrence
                </mat-checkbox>
              </div>
              <div class="col-3 p-1">
                <mat-checkbox [(ngModel)]="NACHPDC" id="NACHPDC" name="NACHPDC">
                  NACHPDC
                </mat-checkbox>
              </div>
              <div class="col-3 p-1">
                <button
                  type="button"
                  class="ml-4 font-size-12 button-btn"
                  (click)="preCloser()"
                >
                  Config Pre-Closure
                </button>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <h1
                style="
                  font-size: 12px;
                  background: #4dc3a3;
                  margin-bottom: 10px;
                  padding: 10px;
                  color: #fff;
                "
              >
                Product Charges
              </h1>
            </div>
            <div class="col-md-12 row m-0 p-0">
              <div class="col-md-1 p-1">
                <span>&nbsp;</span>
              </div>
              <div class="col-md-3 p-1">
                <span style="font-weight: bold">Charges head</span>
              </div>
              <div class="col-md-2 p-1">
                <span style="font-weight: bold">Account head</span>
              </div>
              <div class="col-md-2 p-1">
                <span style="font-weight: bold">Charges type</span>
              </div>
              <div class="col-md-2 p-1">
                <span style="font-weight: bold">Charges Value</span>
              </div>
              <div class="col-md-2 p-1">
                <span style="font-weight: bold">Charges ApplyOn</span>
              </div>
            </div>
            <div
              class="col-md-12 row m-0 p-0"
              *ngFor="let charges of chargesHead; let i = index"
            >
              <div class="col-md-1 p-1">
                <mat-checkbox
                  [(ngModel)]="charges.select"
                  id="multipleTrunche{{ i }}"
                  name="multipleTrunche{{ i }}"
                >
                </mat-checkbox>
              </div>
              <div class="col-md-2 p-1">
                <span> {{ charges.ChagesHead }} </span>
              </div>
              <div class="col-md-3 p-1">
                <select
                  name="Account_HeadId{{ i }}"
                  id="Account_HeadId{{ i }}"
                  [(ngModel)]="charges.Account_HeadId"
                  class="form-control input-text-css"
                  [required]="charges.select"
                  #refAccount_HeadId="ngModel"
                  [ngClass]="{
                    'is-invalid': dbf.submitted && refAccount_HeadId.invalid,
                    'alert-warning':
                      refAccount_HeadId.invalid &&
                      (refAccount_HeadId.dirty ||
                        refAccount_HeadId.touched ||
                        refAccount_HeadId.untouched)
                  }"
                >
                  <option value="">Select</option>
                  <option
                    *ngFor="let ca of chargableAccount"
                    [value]="ca.AccountId"
                  >
                    {{ ca.Account_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <select
                  name="chargeType{{ i }}"
                  id="chargeType{{ i }}"
                  [(ngModel)]="charges.Charge_Type"
                  class="form-control input-text-css"
                  [required]="charges.select"
                  #refchargeType="ngModel"
                  [ngClass]="{
                    'is-invalid': dbf.submitted && refchargeType.invalid,
                    'alert-warning':
                      refchargeType.invalid &&
                      (refchargeType.dirty ||
                        refchargeType.touched ||
                        refchargeType.untouched)
                  }"
                >
                  <option value="">Select</option>
                  <option value="Fixed">Fixed</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Open">Open</option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <input
                  type="number"
                  [(ngModel)]="charges.Charge_Values"
                  placeholder="Charges Value"
                  name="ChargeValues{{ i }}"
                  id="ChargeValues{{ i }}"
                  class="form-control input-text-css"
                  [required]="charges.select && charges.Charge_Type != 'Open'"
                  #refChargeValues="ngModel"
                  [ngClass]="{
                    'is-invalid': dbf.submitted && refChargeValues.invalid,
                    'alert-warning':
                      refChargeValues.invalid &&
                      (refChargeValues.dirty ||
                        refChargeValues.touched ||
                        refChargeValues.untouched)
                  }"
                />
              </div>
              <div class="col-md-2 p-1">
                <select
                  name="chargeApplyOn{{ i }}"
                  id="chargeApplyOn{{ i }}"
                  [(ngModel)]="charges.Charge_ApplyOn"
                  class="form-control input-text-css"
                  [required]="charges.select && charges.Charge_Type != 'Open'"
                  #refchargeApplyOn="ngModel"
                  [ngClass]="{
                    'is-invalid': dbf.submitted && refchargeApplyOn.invalid,
                    'alert-warning':
                      refchargeApplyOn.invalid &&
                      (refchargeApplyOn.dirty ||
                        refchargeApplyOn.touched ||
                        refchargeApplyOn.untouched)
                  }"
                >
                  <option value="">Select</option>
                  <option value="Loan Amount">Loan Amount</option>
                  <option value="POS Outstanding (Only Due)">
                    POS Outstanding (Only Due)
                  </option>
                  <option value="POS Balance (Due + Future)">
                    POS Balance (Due + Future)
                  </option>
                  <option value="Installment Outstanding (Only Due)">
                    Installment Outstanding (Only Due)
                  </option>
                  <option value="Installment Balance (Due + Future)">
                    Installment Balance (Due + Future)
                  </option>
                  <option value="Per Instance">Per Instance</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseProductModal()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveNewProduct()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!dbf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="AddPreColserModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ddf="ngForm" (ngSubmit)="onSavePreColserCharges()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Pre-Closer Charges
          </h6>
          <button
            type="button"
            (click)="OnClosePreColserModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 row m-0 p-0">
            <div class="col-md-3 p-1">
              <span style="font-weight: bold">Lock-IN Period</span>
              <input
                type="number"
                [(ngModel)]="LockInPeriod"
                placeholder="Lock-IN Period"
                name="LockInPeriod"
                id="LockInPeriod"
                class="form-control input-text-css"
                required
                #refLockInPeriods="ngModel"
                (change)="LockInPeriodChange()"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refLockInPeriods.invalid,
                  'alert-warning':
                    refLockInPeriods.invalid &&
                    (refLockInPeriods.dirty ||
                      refLockInPeriods.touched ||
                      refLockInPeriods.untouched)
                }"
              />
            </div>
            <div class="col-md-3 p-1">
              <span style="font-weight: bold"> Broken Period Interest </span>
              <mat-checkbox
                [(ngModel)]="Broken_Period_Interest"
                id="Broken_Period_Interest"
                name="Broken_Period_Interest"
              >
              </mat-checkbox>
            </div>
          </div>
          <form #df="ngForm" novalidate>
            <div class="row m-0 add-remove-btn" [hidden]="!showSlab">
              <div class="col-md-12 row m-0 p-0">
                <div class="col-md-3 p-1">
                  <span style="font-weight: bold"></span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight: bold">From Month</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight: bold">To Month</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight: bold">Charges Value</span>
                </div>
                <div class="col-md-1 p-1">
                  <span style="font-weight: bold">Charges ApplyOn</span>
                </div>
              </div>

              <div
                class="col-md-12 row m-0 p-0"
                *ngFor="let charges of pre_CloserCharges; let i = index"
              >
                <div class="col-md-3 p-1">
                  <span>{{ charges.Name }} </span>
                </div>
                <div class="col-md-2 p-1">
                  <input
                    type="number"
                    [(ngModel)]="charges.From_Values"
                    placeholder="From"
                    name="From_Values{{ i }}"
                    id="From_Values{{ i }}"
                    class="form-control input-text-css"
                    required
                    #refFrom_Values="ngModel"
                    readonly
                    [ngClass]="{
                      'is-invalid': df.submitted && refFrom_Values.invalid,
                      'alert-warning':
                        refFrom_Values.invalid &&
                        (refFrom_Values.dirty ||
                          refFrom_Values.touched ||
                          refFrom_Values.untouched)
                    }"
                  />
                </div>
                <div class="col-md-2 p-1">
                  <input
                    type="number"
                    [(ngModel)]="charges.To_Values"
                    placeholder="To"
                    name="To_Values{{ i }}"
                    id="To_Values{{ i }}"
                    (change)="ToValueChange(i)"
                    class="form-control input-text-css"
                    required
                    #refTo_Values="ngModel"
                    min="{{ charges.From_Values }}"
                    [ngClass]="{
                      'is-invalid': df.submitted && refTo_Values.invalid,
                      'alert-warning':
                        refTo_Values.invalid &&
                        (refTo_Values.dirty ||
                          refTo_Values.touched ||
                          refTo_Values.untouched)
                    }"
                  />
                </div>
                <div class="col-md-2 p-1">
                  <input
                    type="number"
                    [(ngModel)]="charges.Charge_Values"
                    placeholder="Charges Value"
                    name="ChargeValues{{ i }}"
                    id="ChargeValues{{ i }}"
                    class="form-control input-text-css"
                    required
                    #refChargeValues="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refChargeValues.invalid,
                      'alert-warning':
                        refChargeValues.invalid &&
                        (refChargeValues.dirty ||
                          refChargeValues.touched ||
                          refChargeValues.untouched)
                    }"
                  />
                </div>
                <div class="col-md-1 p-1">
                  <input
                    type="text"
                    [(ngModel)]="charges.chargeApplyOn"
                    name="chargeApplyOn{{ i }}"
                    id="chargeApplyOn{{ i }}"
                    class="form-control input-text-css"
                    value="POS"
                    readonly
                  />
                </div>

                <button
                  type="button"
                  class="remove"
                  (click)="removeData(i)"
                  [ngClass]="{ 'no-head': i != 0 }"
                  *ngIf="pre_CloserCharges.length > 1"
                >
                  -
                </button>
                <button
                  type="button"
                  class="addmore"
                  (click)="addMoreData(i)"
                  [ngClass]="{
                    both: pre_CloserCharges.length > 1,
                    'no-head': i != 0
                  }"
                  [disabled]="!df.form.valid"
                  *ngIf="i == pre_CloserCharges.length - 1"
                >
                  +
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClosePreColserModal()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSavePreColserCharges()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!df.form.valid || !ddf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
