import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";

import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { constantUrl } from "src/app/Shared/constantUrl";
declare var $: any;

class MyUploadAdapter {
  searchText: string = "";
  loader: any;

  constructor(loader) {
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (
        file //{
      ) =>
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          let _data: any = {};
          _data.DocName = file.name;
          reader.readAsDataURL(file);
          let $this = this;
          reader.onload = function () {
            _data.DocData = reader.result.toString().split(";base64,").pop();
            return $this.uploadFile(resolve, reject, _data);
          };
        })
    );
  }
  uploadFile(resolve, reject, data) {
    $.ajax({
      url: `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadTemplateDoc`,
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json;",
      success: (result, status, xhr) => {
        return resolve({
          default: constantUrl.SenctionTemplateImageUrl + result,
        });
      },
      error: (error, status, xhr) => {
        return reject(`Couldn't upload file: ${data.DocName}.`);
      },
    });
  }
}
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

@Component({
  selector: "app-legal-letter-template",
  templateUrl: "./legal-letter-template.component.html",
  styleUrls: ["./legal-letter-template.component.scss"],
})
export class LegalLetterTemplateComponent implements OnInit {
  currentUser: any;
  private _encdec;
  showSpinner: boolean = false;
  templateData: any = "";
  loading: boolean = false;
  productDropdown: any[] = [];
  ProductId: any = "";
  Type: any = "";
  AddType: any = "";
  TemplateId: any = "";
  TemplateName: any = "";
  TemplateDropdown: any[] = [];
  getTemplate: any[] = [];
  JsonData: any[] = [];
  form: FormGroup;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  //config: any;
  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: "500px",
    maxHeight: "500px",
    placeholder: "Enter text here...",
    translate: "no",
    sanitize: false,
    toolbarPosition: "top",
    defaultFontName: "Comic Sans MS",
    defaultFontSize: "5",
    defaultParagraphSeparator: "p",
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      signature: ["", Validators.required],
    });
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.LOSHeaderTitle.next("Legal Letter Template");
    this.getProductDropdown();
  }

  OnChangeType() {
    if (this.AddType) {
      this.templateData = "";
      if (this.AddType == "Create") this.TemplateId = "";
      this.TemplateName = "";
      this.GetTemplateType();
    }
  }

  GetTemplateType() {
    this._MasterService
      .Get_Template_Type({
        Type: this.Type,
        ProductId: this.ProductId == "" ? 0 : parseInt(this.ProductId),
      })
      .subscribe((result: any) => {
        this.TemplateDropdown = result;
        if (this.ProductId == "" ? 0 : parseInt(this.ProductId) > 0) {
          this.templateData = "";
        }
      });
    this.getTemplateSideMenu();
  }

  geLegalLetter() {
    if (this.TemplateId == "" ? 0 : parseInt(this.TemplateId) > 0)
      this.TemplateName = this.TemplateDropdown.filter(
        (x) => x.Id == (this.TemplateId == "" ? 0 : parseInt(this.TemplateId))
      )[0].Template_Name;

    this._MasterService
      .Get_Template_Data({
        Id: this.TemplateId,
        ProductId: this.ProductId == "" ? 0 : this.ProductId,
      })
      .subscribe((res: any) => {
        console.log("Get_Template", res);
        this.templateData = res[0].TemplateData;
      });
  }

  SaveUpdateTemplate() {
    this.showSpinner = true;
    console.log(
      "Type:",
      this.Type,
      "Template_Name:",
      this.TemplateName,
      "LoginUserId:",
      this.currentUser.userId,
      "Id:",
      this.TemplateId == "" ? 0 : parseInt(this.TemplateId)
    );
    this.templateData = this.templateData.replaceAll(
      '<a class="lead fs-14 mb-2 p_data ng-star-inserted">',
      ""
    );
    this.templateData = this.templateData.replaceAll("</a>", "");
    this._MasterService
      .Save_Update_Template({
        Type: this.Type,
        Template_Name: this.TemplateName,
        LoginUserId: this.currentUser.userId,
        Id: this.TemplateId == "" ? 0 : parseInt(this.TemplateId),
        ProductId: this.ProductId == "" ? 0 : this.ProductId,
        TemplateData: this.templateData,
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.GetTemplateType();
          this.AddType = "";
          this.TemplateName = "";
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.TemplateId = "";
        this.showSpinner = false;
        this.loading = false;
      });
  }

  getTemplateSideMenu() {
    this._MasterService.GetTemplate_SideMenu().subscribe((res: any) => {
      this.getTemplate = res;
      console.log("abc", res);
      this.JsonData = JSON.parse(JSON.stringify(res));
      if (this.Type != "sanctionletter") {
        for (var i = 0; i < this.JsonData.length; i++) {
          if (this.JsonData[i].SideMenuName == "[Senction_Conditions]") {
            this.JsonData.splice(i, 1);
          }
        }
      }
      if (this.Type != "ListOfDocument") {
        for (var i = 0; i < this.JsonData.length; i++) {
          if (this.JsonData[i].SideMenuName == "[LOD]") {
            this.JsonData.splice(i, 1);
          }
        }
      }

      if (
        this.productDropdown.length > 0 &&
        this.productDropdown.find((x) => x.ProductId == this.ProductId)
          .Category != "Vehicle Loan"
      ) {
        for (var i = 0; i < this.JsonData.length; i++) {
          if (this.JsonData[i].SideMenuName == "[DealerName]") {
            this.JsonData.splice(i, 1);
          }
        }
      }
      if (
        this.productDropdown &&
        this.productDropdown.find((x) => x.ProductId == this.ProductId)
          .Category != "Vehicle Loan"
      ) {
        for (var i = 0; i < this.JsonData.length; i++) {
          if (this.JsonData[i].SideMenuName == "[VehicleModel]") {
            this.JsonData.splice(i, 1);
          }
        }
      }
    });
  }

  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
  }

  setProductIdZero(value: any) {
    this.getTemplateSideMenu();
    this.TemplateName = "";
    this.AddType = "";
    this.ProductId = "";
    this.TemplateId = "";
  }
  printData(value: any) {
    console.log(value);
  }
}
