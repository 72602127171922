import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MenusModel, newMenuModal, newMenuModalNew } from '../../_LOS/_layout/Models/MenusModel';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogService } from '../../Shared/dialog.service'
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: 'app-partner-layout',
  templateUrl: './partner-layout.component.html',
  styleUrls: ['./partner-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(400))
    ])
  ]
})
export class PartnerLayoutComponent implements OnInit {
  private data: any;
  Menuslinks: any;
  CheckLoad: any;
  username = "";
  setAutoHide: boolean = false;
  autoHide: number = 2000;
  action: boolean = false;
  actionButtonLabel: string = 'Retry';
  show: boolean = false
  plusMinus: any = '+';
  checkIn: boolean = false;
  active: any = "";
  recentActiveCheckIn: boolean = false;
  recentActive: any = "";
  lastaccess: Date;
  currentlogin: Date;
  MenuslinksNew: any[];
  mysubscription: any;
  activeroute: any; dialogreturn: any;
  liveclock: any;
  HeaderTitle: string = '';

  MenusModel: MenusModel = new MenusModel();
  private _userservice;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  Partner_LoanId: any;

  constructor(
    private _Route: Router, public snackBar: MatSnackBar, private http: HttpClient, 
    private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private _location: Location, private dataSharingService: DataSharingService, 
    private dialog: DialogService,
    private lmsService: LmsService,
  ) 
  { 
    this._userservice = _userlayoutservice;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Portfolio');
    console.log(this.dataSharingService.getMenuItemByType('Portfolio'));
    if (!this.dataSharingService.getMenuItemByType('Portfolio')) {
      this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Portfolio' }).subscribe((res: any) => {
        this.dataSharingService.setMenuItem('Portfolio', res);
      });
    }
    this.dataSharingService.getMenuItem().subscribe((res:any)=>{
      if(res['Portfolio']){
        this.MenuslinksNew=res['Portfolio'];
      }
    });
    this.dataSharingService.getHeaderTitle().subscribe(value => {
      this.HeaderTitle = value||"Portfolio";
    });

    this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
      if (value != null && value != "" && value != 'null' && value != 'undefined') {
        this.Partner_LoanId = value;
      } 
    }); 
  }

  //#region < isLinkActive>
  public isLinkActive(url: string): boolean {
    return this._Route.url.indexOf(url) > -1;
  }
  //#region < toggle>
  toggle() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.plusMinus = "-";
    else
      this.plusMinus = "+";

  }
  //#region < toggleNew>
  toggleNew(currentMenu) {
    this.recentActive = "";
    this.recentActiveCheckIn = false;
    if (currentMenu.MM_IsParent == true) {
      this.MenuslinksNew.forEach(element => {
        if (element === currentMenu) {
          currentMenu.MM_IsShow = !currentMenu.MM_IsShow;
          sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
        } else {
          element.MM_IsShow = true;
          sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
        }
      });
    }
  }
  //#endregion

  //#region < getStateNew>
  getStateNew(currentMenu) {
    if (!currentMenu.MM_IsShow) {
      return 'down';
    } else {
      return 'up';
    }
  }
  //#endregion
  onsubsubmenuclick(e) {
    $('.nav').on('click', function (e) {
      //if ($(this).hasClass('dropdown-menu-form')) {
      e.stopPropagation();
      // }
    });
  }
  onMenuclick() {
  }
  onChangeFyear(event) {
    // return this._Route.url.indexOf(url) > -1;
    console.log("event", event);
  }
}
