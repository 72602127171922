<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="onSearchPartnerBookingList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-2">
        <span class="required-lable"> Start Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="StartDate"
            [(ngModel)]="StartDate"
            id="StartDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refStartDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refStartDate.invalid,
              'alert-warning':
                refStartDate.invalid &&
                (refStartDate.dirty ||
                  refStartDate.touched ||
                  refStartDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="StartDate"
          [(ngModel)]="StartDate"
          id="StartDate"
          placeholder="Start Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refStartDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refStartDate.invalid,
            'alert-warning':
              refStartDate.invalid &&
              (refStartDate.dirty ||
                refStartDate.touched ||
                refStartDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> End Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="EndDate"
            [(ngModel)]="EndDate"
            id="EndDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refEndDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refEndDate.invalid,
              'alert-warning':
                refEndDate.invalid &&
                (refEndDate.dirty || refEndDate.touched || refEndDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="EndDate"
          [(ngModel)]="EndDate"
          id="EndDate"
          placeholder="End Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refEndDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refEndDate.invalid,
            'alert-warning':
              refEndDate.invalid &&
              (refEndDate.dirty || refEndDate.touched || refEndDate.untouched)
          }"
        /> -->
      </div>
      <!--<div class="col-md-3">
        <span class="required-lable"> Select Type </span>
        <select
          name="Loan_Acc_Type"
          id="Loan_Acc_Type"
          [(ngModel)]="Loan_Acc_Type"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refLoan_Acc_Type="ngModel"
          required disabled
          [ngClass]="{
            'is-invalid': df.submitted && refLoan_Acc_Type.invalid,
            'alert-warning':
              refLoan_Acc_Type.invalid &&
              (refLoan_Acc_Type.dirty ||
                refLoan_Acc_Type.touched ||
                refLoan_Acc_Type.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-3">
        <span class="required-lable"> Partner</span>
        <select
          name="ACCategType"
          id="ACCategType"
          [(ngModel)]="ACCategType"
          class="form-control input-text-css"
          #refACCategType="ngModel"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refACCategType.invalid,
            'alert-warning':
              refACCategType.invalid &&
              (refACCategType.dirty ||
                refACCategType.touched ||
                refACCategType.untouched)
          }"
        >
          <option value="">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchPartnerBookingList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="DataSource"
      matSort
      matTableExporter
      #exporterLoanInfo="matTableExporter"
    >
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="PLoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Partner A/C</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.PLoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LMS_LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer A/C</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LMS_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="LoanDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Loan Date </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.LoanDate }} </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
      </ng-container> -->

      <ng-container matColumnDef="Product_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Product</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product_Name }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Loan_Acc_Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Category</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Loan_Acc_Category }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("LoanAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="InterestAmt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Interest Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.InterestAmt }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("InterestAmt") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="AgreementValue">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Agreement Value</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AgreementValue }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("AgreementValue") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LoanEMIAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanEMIAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("LoanEMIAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="No_of_EMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >No of EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.No_of_EMI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LoanTenure">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Tenure</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanTenure }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="ROI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >ROI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ROI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Case_IRR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Case IRR</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Case_IRR }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Disbursement_IRR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Disbursement IRR</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Disbursement_IRR }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Due_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Due Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Due_Date }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Application_ExpiryDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Expiry Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_ExpiryDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="AdvInstl">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Adv. Instl</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AdvInstl }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMI_Frexvency">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Frequency</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.EMI_Frexvency }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMI_Structure">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Structure</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.EMI_Structure }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        (click)="highlightRow(row)"
        [class.highlight]="row.PLoan_Id == selectedId"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporterLoanInfo)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorlist
          [pageSize]="20"
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
