<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="row m-0 mt-1 font-size-11 justify-content-center"
    style="display: none"
  >
    <mat-radio-group
      [(ngModel)]="filterValue"
      (change)="onfilterValue()"
      aria-label="Select an option"
    >
      <mat-radio-button value="">All</mat-radio-button>
      <mat-radio-button value="LOS">LOS</mat-radio-button>
      <mat-radio-button value="LMS">LMS</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ProcessId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Id
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Process }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ProcessGroup">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process Group
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ProcessGroup }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px; justify-content: center"
          *matHeaderCellDef
        >
          Application
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px; justify-content: center"
        >
          {{ row.Application }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Sequence">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px; justify-content: center"
          *matHeaderCellDef
        >
          Sequence
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px; justify-content: center"
        >
          {{ row.Sequence }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Level">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 60px; justify-content: center"
          *matHeaderCellDef
        >
          Level
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 60px; justify-content: center"
        >
          {{ row.Level }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="IsMandatoryText">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px; justify-content: center"
          *matHeaderCellDef
        >
          Mandatory
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px; justify-content: center"
        >
          {{ row.IsMandatoryText }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ProcessAt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process At
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ProcessAt }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="DeafultTAT">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px; justify-content: center"
          *matHeaderCellDef
        >
          Default TAT
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px; justify-content: center"
        >
          {{ row.DeafultTAT }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="RecommedToRejectAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="max-width: 100px; justify-content: center"
        >
          IS Rejection Allowed
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px; justify-content: center"
        >
          {{ row.IsRecommedToReject }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 60px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 60px"
        >
          <i
            class="fa fa-pencil-alt font-size-12"
            style="cursor: pointer"
            (click)="OnOpenEditLoanProcess(row.ProcessId)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="EditLoanProcessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveLoanProcessData()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Update Process
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">
              <span class="required-lable">Process Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refProcessName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refProcessName.invalid,
                  'alert-warning':
                    refProcessName.invalid &&
                    (refProcessName.dirty ||
                      refProcessName.touched ||
                      refProcessName.untouched)
                }"
                [(ngModel)]="ProcessName"
                placeholder="Process Name"
                name="ProcessName"
                id="ProcessName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">
              <span class="required-lable">Group Name </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refGroupName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroupName.invalid,
                  'alert-warning':
                    refGroupName.invalid &&
                    (refGroupName.dirty ||
                      refGroupName.touched ||
                      refGroupName.untouched)
                }"
                [(ngModel)]="GroupName"
                placeholder="Group Name"
                name="GroupName"
                id="GroupName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">
              <span class="required-lable">Mandatory </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="SelectMandatory"
                id="SelectMandatory"
                #refSelectMandatory="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refSelectMandatory.invalid,
                  'alert-warning':
                    refSelectMandatory.invalid &&
                    (refSelectMandatory.dirty ||
                      refSelectMandatory.touched ||
                      refSelectMandatory.untouched)
                }"
                [(ngModel)]="SelectMandatory"
                class="form-control input-text-css"
                required
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">
              <span class="required-lable">Process At </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="ProcessAt"
                id="ProcessAt"
                #refProcessAt="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refProcessAt.invalid,
                  'alert-warning':
                    refProcessAt.invalid &&
                    (refProcessAt.dirty ||
                      refProcessAt.touched ||
                      refProcessAt.untouched)
                }"
                [(ngModel)]="ProcessAt"
                class="form-control input-text-css"
                required
              >
                <option value="Branch">Branch</option>
                <option value="Branch,HO">Branch,HO</option>
                <option value="HO">HO</option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">
              <span class="required-lable">Default TAT (Mints) </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refDefaultTAT="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDefaultTAT.invalid,
                  'alert-warning':
                    refDefaultTAT.invalid &&
                    (refDefaultTAT.dirty ||
                      refDefaultTAT.touched ||
                      refDefaultTAT.untouched)
                }"
                [(ngModel)]="DefaultTAT"
                placeholder="Default TAT"
                name="DefaultTAT"
                id="DefaultTAT"
                class="form-control input-text-css"
              />
            </div>
          </div>

          <div class="row m-0 font-size-12" *ngIf="IsRecommedToRejectMandatory">
            <div class="col-md-3 p-1">
              <div class="form-check">
                <input
                  type="checkbox"
                  name="IsRecommedToReject"
                  class="form-check-input"
                  id="IsRecommedToReject"
                  [(ngModel)]="IsRecommedToReject"
                />
                <label class="form-check-label fw-7" for="IsRecommedToReject">
                  IS Rejection Allowed
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveLoanProcessData()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
