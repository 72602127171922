<div>
  <form #f="ngForm" novalidate>

    <div class="row mt-4">
      <div class="col-md-12">
        <h4> Loan Accounting Category Master </h4>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <span> Loan Accounting category </span>
        <input type="text" name="SelectLoanAccountingCategory" id="SelectLoanAccountingCategory"
          [(ngModel)]="SelectLoanAccountingCategory" class="form-control input-text-css">
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <p>Product</p>
      </div>
    </div>
    <div class="row">
       <div class="col-md-12">
        <mat-form-field>
          <mat-label>Select Product</mat-label>
          <mat-select [formControl]="toppings" multiple>
            <ng-container *ngFor="let topping of ProductList">
              <mat-option *ngIf="topping.ActiveStatus=='Active'"  [value]="topping.ProductId">{{topping.Product}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
       </div>
    </div>
    <hr>

    <div class="mb-2">
      <button type="button" (click)="onSaveData()" *ngIf="saveBtn" class="font-size-12 button-btn"  [class.spinner]="loading">
        Save
      </button>
      <button type="button" (click)="UpdateLoanAcct()" *ngIf="updateBtn"class="font-size-12 button-btn"
        [class.spinner]="loading" [disabled]="!f.form.valid">
        Update
      </button>
    </div>
  </form>

  <div class="row mt-4">
    <div class="col-md-8">
      <div class="table-responsive ">
        <mat-table [dataSource]="dataCustomerSource">
          <ng-container matColumnDef="Number">
            <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>
              #
            </mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
              {{ (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Accounting Category">
            <mat-header-cell class="grid-header" style="max-width: 200px;max-height:5px" *matHeaderCellDef>
              Accounting Category
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width: 200px;max-height:5px">
              {{row.Loan_Accounting_Category}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell class="grid-header" style="max-width: 240px;max-height: 138px;" *matHeaderCellDef>
              Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width: 240px;max-height: 138px;">
              {{row.ProductName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell class="grid-header j-c-center" style="max-height:5px" *matHeaderCellDef>
              Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width: 150px;max-height:5px">
              <i *ngIf="!inViewMode" class="fa fa-pencil-alt right" style="cursor: pointer;color:green;font-size:16px;"
                aria-label="true" (click)="editLoanAcct(row)"></i>
              <i class="fa fa-trash style-delete ml-3" (click)="removeAccountModel(row)" *ngIf="!inViewMode"
                style="cursor: pointer;" aria-label="true"></i>

            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedCustomerColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedCustomerColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

</div>
