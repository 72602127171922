import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { ToWords } from "to-words";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerService } from "../services/partner.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-partner-account-info",
  templateUrl: "./partner-account-info.component.html",
  styleUrls: ["./partner-account-info.component.scss"],
})
export class PartnerAccountInfoComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  tab: any;
  rdDetailData: any;
  ReceiptData: any = {};
  ReceiptDeduction: any = [];
  inquiryId: any;
  inquiryDetail: any;
  loading: boolean = false;
  rdDataSource: any = "";
  CurrentDate: any = new Date();
  ApplicationId: any;
  applicationId: any;
  applicationDetail: any = "";

  isCalEMITableView: boolean = true;
  isCalEMIForm: boolean = false;
  disabled: boolean = true;
  stepEmiForm: boolean = true;
  ProductId: any;

  productDropdown: any[] = [];
  chargesDropdown: any[] = [];
  dataSource: any;
  displayedColumns: string[] = [
    "SchemeId",
    "Scheme",
    "Amount",
    "ROI",
    "Period",
    "AdvanceEMI",
    "Select",
  ];
  selectedScheme: any = {};
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    IRR_CalculateBy: "Reducing_ROI",
  };
  stepEMI: any[] = [];
  emiIrr: any;
  stepEmiData: any;
  //LoanId: any;
  //type: any;
  LoanDetail: any;
  IsReceiptSave: boolean = true;
  IsReceiptUpdate: boolean = false;
  IsCloseBtn: boolean = false;
  // @Input() Type: any = '';
  // @Input() LoanId: any;
  // @Input() applicationNo: any;
  @Input() loanSummary: any;
  @Input() PageType: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  Voucher_Detail: any = { TaxSlab: [] };
  chargesHeadDropdown: any[] = [];
  customerdataSource: any;
  customerdisplayedColumns: string[] = [
    "LoanId",
    "LoanNo",
    "Product_Name",
    "Customer",
    "PhoneNo",
    "Branch_Name",
    "Action",
  ];
  totalCalculator: any;
  dataSourceCustomer: any;
  Customer: any = {};
  displayedCustomerSearchColumns: string[] = [
    "Type",
    "Application_No",
    "LoanAcNo",
    "CustomertName",
    "FatherName",
    "Customer_Gender",
    "GenderAge",
    "PhoneNo",
    "EditAction",
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  CustomerList: any[] = [];
  Type: any = "0";

  @Input("isEdit") isEdit: boolean = false;
  isFrequency: boolean = false;
  Btn_Action: any;
  Tenure_Name: string;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private router: Router,
    private losService: LosService,
    private LmsService: LmsService,
    private _Partnerservice: PartnerService,
    private DatePipe: DatePipe
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Account Info");
    this.GetReceiptDeduction();
    this.ProductId = this.loanSummary.ProductId;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getProductDropdown();
    this.tab = "list";
    this.getLoanAccount();
  }

  getLoanAccount() {
    this.stepEmiForm = false;
    this.stepEMI = [];
    this.showSpinner = true;
    this._Partnerservice
      .Partner_Get_Existing_Loan_Details({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("Partner", res);
        if (res[0].Partner_LoanId == 0) {
          this.cEIModel.Asset_Cost = res[0].AssetCost;
          this.cEIModel.NetFinance_Amt = res[0].NetFinance;
          //this.cEIModel.IRR_CalculateBy = res[0].IRR_CalculateBy;
          this.cEIModel.EMI_Type = res[0].EMI_Type;
          this.cEIModel.No_Of_Inst = res[0].No_Of_Instl;
          this.cEIModel.Tenure = res[0].Tenure;
          this.cEIModel.Adv_Inst = res[0].Adv_Instl;
          //this.cEIModel.Reducing_ROI = res[0].Case_IRR;
          this.cEIModel.Flat_Rate = res[0].Flat_Rate;
          this.cEIModel.EMI_Amount = res[0].EMIAmount;
          this.cEIModel.Case_IRR = res[0].Case_IRR;

          this.cEIModel.OldNetFinance = res[0].OldNetFinance;
          this.cEIModel.LoanAccountingId = res[0].LoanAccountingId;
          this.cEIModel.Partner_LoanId = res[0].Partner_LoanId;

          this.cEIModel.Reducing_ROI = res[0].Loan_Acc_Partner_IRR;
          this.cEIModel.Loan_Acc_Partner_IRR = res[0].Loan_Acc_Partner_IRR;
          this.cEIModel.Loan_Acc_Partner_Fund = res[0].Loan_Acc_Partner_Fund;
          this.Btn_Action = "Save";
        } else {
          this.dataSharingService.LmsHeaderLoanId.next(res[0].Partner_LoanId);
          /*this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
          if (value != null && value != "" && value != 'null' && value != 'undefined') {
            console.log("Partner_LoanId 1", value);
          } 
        });*/

          this.cEIModel.Partner_LoanId = res[0].Partner_LoanId;
          this.cEIModel.OldNetFinance = res[0].OldNetFinance;
          this.cEIModel.LoanAccountingId = res[0].LoanAccountingId;

          this.Get_Partner_Loan_Details();

          this.Partner_Get_Loan_Details();
          this.GetReceiptDeduction();
          this.Btn_Action = "Update";

          //Partner_LoanId set For amortization chart
          sessionStorage.removeItem("Partner_LoanId");
          sessionStorage.setItem(
            "Partner_LoanId",
            this.cEIModel.Partner_LoanId
          );
        }
      });
  }

  Partner_Get_Loan_Details() {
    this._Partnerservice
      .Partner_Get_Loan_Details({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("Partnernew", res);
        if (res.length > 0) {
          this.cEIModel.Asset_Cost = res[0].AssetCost;
          //this.cEIModel.NetFinance_Amt = res[0].NetFinance;
          //this.cEIModel.IRR_CalculateBy = res[0].IRR_CalculateBy;
          this.cEIModel.EMI_Type = res[0].EMI_Type;
          this.cEIModel.No_Of_Inst = res[0].No_Of_Instl;
          this.cEIModel.Tenure = res[0].Tenure;
          this.cEIModel.Adv_Inst = res[0].Adv_Instl;
          this.cEIModel.Reducing_ROI = res[0].Case_IRR;
          this.cEIModel.Flat_Rate = res[0].Flat_Rate;
          this.cEIModel.EMI_Amount = res[0].EMIAmount;
          this.cEIModel.Case_IRR = res[0].Case_IRR;
          this.cEIModel.NetFinance_Amt = res[0].NetFinance;

          this.cEIModel.part_loan_no = res[0].PLoanAcNo;

          // this.cEIModel.part_loan_date = new Date(moment(res[0].PLoan_Date, "DD/MM/YYYY").toString())
          //this.cEIModel.part_emi_st_date = new Date(moment(res[0].FirstEMIDate, "DD/MM/YYYY").toString())
          this.cEIModel.part_loan_date = new Date(res[0].PLoan_Date);
          this.cEIModel.part_emi_st_date = new Date(res[0].FirstEMIDate);
        }
      });
  }
  /*getLoanAccount() {
    this.stepEMI = [];
    this.showSpinner = true;
    this.LmsService.FinancialDetailsForUpdate({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      // isCalEMITableView
      console.log("Res : ", res);
      if (res.Item1.length > 0) {
        this.LoanDetail = res.Item1[0];
        //this.isCalEMITableView = false;
        //this.isCalEMIForm = true;
        if (res.Item1[0].IRR_CalculateBy) {
          this.cEIModel.Asset_Cost = res.Item1[0].AssetCost ? (res.Item1[0].AssetCost == -1 || res.Item1[0].AssetCost == 0) ? 0 : res.Item1[0].AssetCost : 0;
          this.cEIModel.NetFinance_Amt = res.Item1[0].NetFinance;
          this.cEIModel.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
          this.cEIModel.EMI_Type = 'Monthly';
          this.cEIModel.No_Of_Inst = res.Item1[0].No_Of_Instl;
          this.cEIModel.Tenure = res.Item1[0].Tenure;
          this.cEIModel.Adv_Inst = res.Item1[0].Adv_Instl;
          this.cEIModel.MgmtFee = res.Item1[0].ManagementFee;
          this.cEIModel.First_EMI_Date = new Date(res.Item1[0].FirstEMIDate);
          this.cEIModel.Flat_Rate = res.Item1[0].Flat_Rate;
          this.cEIModel.EMI_Amount = res.Item1[0].EMIAmount;
          this.cEIModel.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;

          $(function () {
            $("#Flat_Rate").val(res.Item1[0].Flat_Rate);

          });

          $(function () {
            $("#EMI_Amounts").val(res.Item1[0].EMIAmount);

          });


          let data = {
            Disbursement_Amt: res.Item1[0].DisbursementAmt,
            Interest_Amt: res.Item1[0].InterestAmt,
            Agreement_Value: res.Item1[0].AgreementValue,
            LTV: res.Item1[0].LTV,
            ROI: res.Item1[0].Disbursement_IRR,
            EMI_Amt: res.Item1[0].EMIAmount,
            Case_IRR: res.Item1[0].Case_IRR,
            Disbursement_IRR: res.Item1[0].Disbursement_IRR,
            Margin: res.Item1[0].Margin
          }
          this.emiIrr = data;
          if (res.Item1[0].IRR_CalculateBy == "ROI") {
            // this.cEIModel.IsStep = true;
            this.stepEMI.push({
              FromEMI: 1,
              ToEMI: this.cEIModel.No_Of_Inst,
              EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
              NoOfInstl: this.cEIModel.No_Of_Inst,
              TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
              disable: true,
            });
            document.getElementById("CalculateByFlat")['checked'] = true;
            //var amt = this.emiIrr.ROI.toFixed(2);
            //$(function () {
            //  $("#Flat_Rate").val(amt);
            //});
          }
          if (res.Item1[0].IRR_CalculateBy == "STEP_EMI") {
            let stepArr: any[] = [];
            this.cEIModel.IsStep = true;
            this.stepEMI = res.Item2;

            for (let i = 0; i < res.Item2.length; i++) {
              this.stepEMI[i].EMI_Amount = res.Item2[i].EMIAmount;
              this.stepEMI[i].NoOfInstl = res.Item2[i].NoOfEMI
              for (
                let j = +this.stepEMI[i].FromEMI;
                j <= +this.stepEMI[i].ToEMI;
                j++
              ) {
                if (+this.stepEMI[i].EMI_Amount >= 0) {
                  stepArr.push(this.stepEMI[i].EMI_Amount);
                }
              }
            }
            this.getTotlaEmiAndAmount();

          }
          if (res.Item1[0].IRR_CalculateBy == "FLAT_EMI") {
            this.stepEMI.push({
              FromEMI: 1,
              ToEMI: this.cEIModel.No_Of_Inst,
              EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
              NoOfInstl: this.cEIModel.No_Of_Inst,
              TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
              disable: true,
            });
            document.getElementById("CalculateByEMI")['checked'] = true;
            //var amt = this.emiIrr.EMI_Amt.toFixed(2);
            //$(function () {
            //  $("#EMI_Amounts").val(amt);
            //});
          }



        }
      }

      this.showSpinner = false;
    });

  }*/

  CalCGSTSGST() {
    let amt = this.ReceiptData.Amount;
    let pert = (this.ReceiptData.Amount * 9) / 100;
    this.ReceiptData.CGST = pert;
    this.ReceiptData.SGST = pert;
    this.ReceiptData.TotalAmount = amt + pert + pert;
  }

  EmiCalculator() {
    this.isCalEMITableView = true;
    this.isCalEMIForm = false;
  }

  LOS_GetInquiryDetails() {
    this.showSpinner = true;
    this.losService
      .LOS_GetInquiryDetails({ InquiryId: this.inquiryId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.inquiryDetail = res[0];
        this.cEIModel.Purpose = this.inquiryDetail.Purpose;
        this.cEIModel.NetFinance_Amt = this.inquiryDetail.LoanAmount;
      });
  }
  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
    this.showSpinner = false;
  }
  geSchemeList() {
    this.showSpinner = false;
    this._MasterService
      .LOS_GetSchemeToValidateLoan({ ProductId: this.cEIModel.ProductId })
      .subscribe((result: any) => {
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(result))
        );
        this.showSpinner = false;
      });
  }
  onCheckboxChange(e: any, data: any) {
    if (this.selectedScheme.SchemeId == data.SchemeId) {
      this.selectedScheme = {};
    } else {
      this.selectedScheme = data;
      this.cEIModel.Adv_Inst = data.AdvanceEMI;
    }
  }
  onClose() {
    this.isCalEMITableView = false;
    this.isCalEMIForm = true;
    this.IsCloseBtn = false;
  }
  changeRDTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
      // this.onRDOpen(false);
    }
    // this.ReceiptData = {};
    // this.IsReceiptSave = true;
    // this.IsReceiptUpdate = false;
    if (tab == "new") {
      this.initializeVoucherDetail();
      this.getChargeshead();
    }
  }

  onRDOpen(isOpen: boolean = true) {
    this.showSpinner = true;
    this.losService
      .LOS_GetApplicationCharges({ ApplicationId: this.ApplicationId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.rdDetailData = res;
        this.rdDataSource = new MatTableDataSource(res.Item2);
        this.rdDetailData.New = {
          ApplicationId: this.ApplicationId,
          LoginUserId: this.currentUser.userId,
          ChargeHeadId: "",
          ChargeReceiptDeduction: "",
        };

        //this.LOS_GetApplicationProductChargesHeadsForDropdown();

        // if (isOpen) {
        //    this.tab = 'list';
        //     this.LOS_GetApplicationProductChargesHeadsForDropdown();
        //     $('#rddetailModel').modal('show');
        //     $("#rddetailModel").css("z-index", "1050");
        // }
      });
    this.showSpinner = false;
  }

  onSaveRdDetail() {
    this.showSpinner = true;
    // this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    // this.ReceiptData.CollectionBy = 'Amit';
    // this.ReceiptData.CollectionOn = new Date();
    // this.ReceiptData.Int_Id = 0;
    // this.ReceiptData.IsActive = 0;
    if (this.Voucher_Detail.TaxSlab) {
      this.Voucher_Detail.IsTax = 0;
    }

    this._Partnerservice
      .Partner_Save_ReceiptDeduction({
        JSON: JSON.stringify({ Loaninfo: this.Voucher_Detail }),
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.tab = "list";
          this.GetReceiptDeduction();
          this.Voucher_Detail = {};
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        //this.rdDataSource = new MatTableDataSource(res.Item2);
      });
  }
  onUpdateRdDetail() {
    this.showSpinner = true;
    this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    this.ReceiptData.CollectionBy = "Amit";
    this.ReceiptData.CollectionOn = new Date();
    this.ReceiptData.IsActive = 0;
    this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe(
      (res: any) => {
        this.showSpinner = false;
        this.tab = "list";
        this.GetReceiptDeduction();
        this.ReceiptData = {};
        //this.rdDataSource = new MatTableDataSource(res.Item2);
      }
    );
  }
  GetReceiptDeduction() {
    this._Partnerservice
      .Partner_Get_ReceiptDeduction({ LoanId: this.cEIModel.Partner_LoanId })
      .subscribe((res: any) => {
        console.log("REST : ", res);
        this.ReceiptDeduction = res;
      });
  }

  LOS_GetApplicationProductChargesHeadsForDropdown() {
    this.losService
      .LOS_GetApplicationProductChargesHeadsForDropdown({
        ApplicationId: this.ApplicationId,
      })
      .subscribe((res: any) => {
        this.chargesDropdown = res;
      });
  }

  OnFinancialDetailClose(event: any) {
    // if (event) {
    //     this.Get_Partner_Loan_Details();
    // }
  }
  onEditFinanceDetail() {
    this.isCalEMITableView = true;
    this.isCalEMIForm = false;
    this.IsCloseBtn = true;
    //this.getLoanAccount();
  }
  Get_Partner_Loan_Details() {
    this.showSpinner = true;
    this._Partnerservice
      .Partner_Get_Loan_Details({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.applicationDetail = res[0];
        console.log("application details", this.applicationDetail);

        if (
          this.applicationDetail.EMI_Type == "Daily" ||
          this.applicationDetail.EMI_Type == "Weekly" ||
          this.applicationDetail.EMI_Type == "Fortnightly" ||
          this.applicationDetail.EMI_Type == "ByWeekly" ||
          this.applicationDetail.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";

        this.dataSharingService.LmsHeaderNetFinance.next(
          this.applicationDetail.NetFinance
        );
        this.isCalEMITableView = false;
        this.isCalEMIForm = true;
        this.IsCloseBtn = false;
      });
  }

  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
        +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "ROI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      this.calculateEmiIrr();
    } else {
      this.calculateEmiIrr();
    }
  }

  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }
      if (this.stepEmiData != undefined && +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }
  //  if (this.cEIModel.Adv_Inst > 0) {
      _data.DaysInYear = this.currentUser.DaysInYear;
    //  this.LmsService.LMS_GetCalculateEMIIRR(_data).subscribe((res: any) => {
    //    if (res.length > 0) {
    //      _data.NetFinance_Amt =
    //        this.cEIModel.NetFinance_Amt -
    //        this.cEIModel.Adv_Inst * res[0].EMI_Amt;
    //      _data.No_Of_Inst = this.cEIModel.No_Of_Inst - this.cEIModel.Adv_Inst;
    //      console.log("_data.NetFinance_Amt", _data.NetFinance_Amt);
    //      this.getCalculateEMIIRR(_data);
    //    }
    //  });
    //} else {
      this.getCalculateEMIIRR(_data);
   // }
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.LmsService.LMS_GetCalculateEMIIRR(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }
    });
  }

  handleByChange(event: any) {
    //   this.emiIrr = undefined;
    //   if (this.cEIModel.IRR_CalculateBy == 'ROI') {
    //       delete this.cEIModel.EMI_Amount;
    //   }
    //   else {
    //       delete this.cEIModel.Flat_Rate;
    //   }
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
    //this.stepEmiForm = false;
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst) {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    }
    this.setAdvanceInstl();
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Advance Installment not greater than No. Of Installment !!!",
        ...this.configSuccess,
      });
    }
  }
  handleChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_Type == "Flat") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    }
    // if (+data.ToEMI == +this.cEIModel.No_Of_Inst) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Another Step can not be added. You can add till number of installment.", ...this.configSuccess });
    //     return;
    // }
    else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To EMI should be greater than From EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }
  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount) {
          TotalEmi =
            TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
              +this.stepEMI[i].EMI_Amount;
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }

  onSaveApplication() {
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }

    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }

    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }

    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI" &&
      +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI multiply with installment should be equal or grater than net finance.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_Type == "Step") {
      let length = this.stepEMI.length;
      let ToEmi = this.stepEMI[length - 1].ToEMI;
      if (+ToEmi != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Add EMI step.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
        +this.stepEmiData.totalAmount
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
          ...this.configSuccess,
        });
        return;
      }
    }
    let _stepEmi: any[] = [];
    this.stepEMI.forEach((obj: any) => {
      _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
    });
    //let dataLogs = {
    //  Application: this.dataSharingService.cleanObject({

    //    Asset_Cost: this.cEIModel.Asset_Cost,
    //    NetFinance_Amt: this.cEIModel.NetFinance_Amt,
    //    Flat_Rate: this.emiIrr.ROI,
    //    No_Of_Inst: this.cEIModel.No_Of_Inst,
    //    Tenure: this.cEIModel.Tenure,
    //    Adv_Inst: this.cEIModel.Adv_Inst,
    //    MgmtFee: 0,  // this.cEIModel.MgmtFee,
    //    EMI_Type: this.cEIModel.EMI_Type,
    //    Disbursement_Amt: this.emiIrr.Disbursement_Amt,
    //    Interest_Amt: this.emiIrr.Interest_Amt,
    //    Agreement_Value: this.emiIrr.Agreement_Value,
    //    LTV: this.emiIrr.LTV,
    //    EMI_Amt: this.emiIrr.EMI_Amt,
    //    Case_IRR: this.emiIrr.Case_IRR,
    //    Disbursement_IRR: this.emiIrr.Disbursement_IRR,
    //    IRR_Type: this.cEIModel.IRR_Type,
    //    ROI: this.emiIrr.ROI,
    //    EMI_Amount: this.cEIModel.EMI_Amount,
    //    IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
    //  }),
    //  StepIRR: _stepEmi,
    //};
    if (
      this.emiIrr.Interest_Amt == null ||
      this.emiIrr.Interest_Amt == 0 ||
      this.emiIrr.Interest_Amt == ""
    ) {
      this.emiIrr.Interest_Amt = 0;
      this.emiIrr.Case_IRR = 0;
      this.emiIrr.Disbursement_IRR = 0;
    }

    var data = {
      partner_LoanMaster: {
        LMS_LoanId: this.loanSummary.LoanId,
        Loan_Acc_CategoryId: this.cEIModel.LoanAccountingId,
        PLoanAcNo: this.cEIModel.part_loan_no,
        PLoan_Date: this.cEIModel.part_loan_date,
        PEligibility_LoanAmount: this.cEIModel.NetFinance_Amt,
        PEligibility_IRRate: this.cEIModel.Loan_Acc_Partner_IRR,
        PLoanCreatedBy: this.currentUser.userId,
        Partner_LoanId: this.cEIModel.Partner_LoanId,
      },
      partner_LoanFinancial: {
        AssetCost: this.cEIModel.Asset_Cost,
        LoanAmount: this.cEIModel.NetFinance_Amt,
        LoanTenure: this.cEIModel.Tenure,
        LoanDuration_Month: this.cEIModel.No_Of_Inst,
        LoanEMIAmount: this.emiIrr.EMI_Amt,
        InterestAmt: this.emiIrr.Interest_Amt,
        AgreementValue: this.emiIrr.Agreement_Value,
        AdvInstl: this.cEIModel.Adv_Inst,
        DisbursementAmt: this.emiIrr.Disbursement_Amt,
        LTV: this.emiIrr.LTV,
        Case_IRR: this.emiIrr.Case_IRR,
        Disbursement_IRR: this.emiIrr.Disbursement_IRR,
        EMIType: this.cEIModel.EMI_Type,
        FirstEMIDate: this.cEIModel.part_emi_st_date,
        Flat_Rate: this.emiIrr.ROI,
        IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
      },
      StepIRR: _stepEmi,
    };
    console.log("data : ", data);
    this._Partnerservice
      .Partner_Save_Loan_Details({ JSON: JSON.stringify(data) })
      .subscribe((res: any) => {
        if (this.cEIModel.Adv_Inst > 0) {
          for (let i = 0; i < this.cEIModel.Adv_Inst; i++) {
            this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
            this.ReceiptData.CollectionBy = this.currentUser.userId;
            this.ReceiptData.CollectionOn = new Date();
            this.ReceiptData.Int_Id = 0;
            this.ReceiptData.IsActive = 0;
            this.ReceiptData.Charges = "Advance EMI";
            this.ReceiptData.Type = "Deduction";
            this.ReceiptData.Amount = this.emiIrr.EMI_Amt;
            this.ReceiptData.CGST = 0;
            this.ReceiptData.SGST = 0;
            this.ReceiptData.TotalAmount = this.emiIrr.EMI_Amt;

            this._Partnerservice
              .Partner_Save_ReceiptDeduction(this.ReceiptData)
              .subscribe((res: any) => {
                this.showSpinner = false;
              });
          }
          this.GetReceiptDeduction();
        }

        this.loading = false;
        this.isCalEMITableView = false;
        this.isCalEMIForm = true;
        this.IsCloseBtn = false;

        this.getLoanAccount();

        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          setTimeout(() => {
            this.dataSharingService.LmsHeaderProduct.next(this.ProductId);
          }, 50);

          this.getLoanAccount();

          if (this.PageType == "A") {
            this.router.navigate([
              `/partner-dashboard/loan-info/${encodeURIComponent(
                this.loanSummary.LoanId
              )}/${"A"}`,
            ]);
          }

          this.action.emit("next");
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  Get_ReceiptDeduction(data: any) {
    var Id = data.Int_Id;
    this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    this.ReceiptData.CollectionBy = "Amit";
    this.ReceiptData.CollectionOn = new Date();
    this.ReceiptData.Int_Id = data.Int_Id;
    this.ReceiptData.IsActive = 0;
    this.ReceiptData.Charges = data.Charges;
    this.ReceiptData.Type = data.Type;
    this.ReceiptData.Amount = data.Amount;
    this.ReceiptData.CGST = data.CGST;
    this.ReceiptData.SGST = data.SGST;
    this.ReceiptData.TotalAmount = data.TotalAmount;
    this.IsReceiptSave = false;
    this.IsReceiptUpdate = true;
    //this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe((res: any) => {
    //  this.showSpinner = false;

    //  //this.rdDataSource = new MatTableDataSource(res.Item2);
    //});
    this.changeTab("new");
  }
  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
      // this.onRDOpen(false);
    }
  }

  removeReceiptDeduction(data: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._Partnerservice
            .Partner_Delete_ReceiptDeduction({
              Id: data.Id,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.GetReceiptDeduction();
              }
            });
        }
      });
  }

  initializeVoucherDetail() {
    this.Voucher_Detail = {
      LoanId: this.cEIModel.Partner_LoanId,
      LoginUserId: this.currentUser.userId,
      DeductionLoanId: this.cEIModel.Partner_LoanId,
      CaseNo: this.applicationDetail.PLoanAcNo,
      CustomerId: this.loanSummary.CustomerId,
      Is_LOSAppliaction: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: this.loanSummary.BranchId,
      TranType: "CR",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      Customer: this.loanSummary.Customer,
      Branch_Name: this.loanSummary.Branch,
      ProductId: this.loanSummary.ProductId,
      TaxSlab: [],
    };
  }
  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.loanSummary.ProductId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  searchCustomer() {
    this.customerdataSource = new MatTableDataSource([]);
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100);
  }
  Get_Acc_Search_LoanNo() {
    this.LmsService.Get_Acc_Search_LoanNo({
      SearchOn: "LoanNo",
      SearchValue: this.Voucher_Detail.SearchValue,
    }).subscribe((res: any) => {
      if (res.length > 0) {
        if (res.length == 1) {
          this.selectSearchData(res[0]);
        }
        this.customerdataSource = new MatTableDataSource(res);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Search not found.",
          ...this.configSuccess,
        });
      }
    });
  }
  selectSearchData(data: any) {
    this.Voucher_Detail.DeductionLoanId = data.LoanId;
    this.Voucher_Detail.CaseNo = data.Application_No;
    this.Voucher_Detail.Customer = data.CustomertName;
    this.Voucher_Detail.CustomerId = data.CustomerId;
    this.Voucher_Detail.ProductId = data.ProductId;
    this.Voucher_Detail.BranchId = data.BranchId;
    this.Voucher_Detail.Branch_Name = data.Branch_Name;
    this.Voucher_Detail.Is_LOSAppliaction = data.Type == "LMS" ? 0 : 1;
    this.Voucher_Detail.ChargeHeadId = "";
    this.Voucher_Detail.Amount = "";
    this.Voucher_Detail.TaxSlab = [];
    this.getChargeshead();
    this.OnCloseSearch();
  }
  OnCloseSearch() {
    $("#CustomerReceipt").modal("hide");
    $("#Customer").focus();
    this.Voucher_Detail.SearchValue = "";
  }
  onChangeChargesHead(data: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.LmsService.Get_AccountHead_For_VoucherEntry({
      HeadId: data.ChargeHeadId,
      VoucherSubType: "Loan",
      ProductId: data.ProductId || 0,
      TaxType: data.TranType,
    }).subscribe((res: any) => {
      if (res.Item1[0]) {
        if (res.Item1[0].AccountId) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        } else {
          data.ChargeHeadId = "";
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "category does not have account head.",
            ...this.configSuccess,
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data Found.",
          ...this.configSuccess,
        });
      }
    });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(
        2
      );
    });
    this.getTotalCalculation();
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    _total = parseFloat(_total) + parseFloat(this.Voucher_Detail.Amount) || 0;
    _totalCR =
      parseFloat(_totalCR) + parseFloat(this.Voucher_Detail.Amount) || 0;
    this.Voucher_Detail.TaxSlab.forEach((tobj: any) => {
      _total = parseFloat(_total) + parseFloat(tobj.TaxAmount) || 0;
      _totalTX = parseFloat(_totalTX) + parseFloat(tobj.TaxAmount) || 0;
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }
  onSearchCustomerDetail() {
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedCustomerSearchColumns = [
            "Type",
            "Application_No",
            "LoanAcNo",
            "CustomertName",
            "FatherName",
            "Customer_Gender",
            "GenderAge",
            "PhoneNo",
            "EditAction",
          ];

          //console.log(res);
          this.CustomerList = JSON.parse(JSON.stringify(res));

          this.CustomerList = this.CustomerList.filter(
            (item) => item.Type == "LMS"
          );

          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.showSpinner = false;
        }
      });
  }

  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.stepEmiForm = true;
      this.cEIModel.EMI_Type = "Monthly";
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
      this.isFrequency = false;
    else this.isFrequency = this.isEdit;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    /*this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }*/

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEmiForm = false;
      this.stepEMI = [];
    } else {
      this.stepEmiForm = true;
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }

  onClickNext() {
    this.action.emit("next");
  }
}
