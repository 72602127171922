import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { constantUrl } from "../../Shared/constantUrl";
import { DialogService } from '../../Shared/dialog.service';


@Component({
  selector: 'app-los-pending-document-list',
  templateUrl: './los-pending-document-list.component.html',
  styleUrls: ['./los-pending-document-list.component.scss']
})
export class LosPendingDocumentListComponent implements OnInit {
  applicationDetail: any;
  CustomerDropdown: any[] = [];
  CustomerAssetsDropdown: any[] = [];
  docDropDown: any[] = [];
  currentUser: any; selectedRowIndex: number = -1; kycModel: any;
  docModal: any = { DocId:'0-0', DocumentImages: [] };
  Application_No: any; Customer: any; DocName: any;
  PendingDocumentList: any[] = [];
  showSpinner: boolean = false; loading: boolean = false;
  status: any = 'Pending';
  dataSource: any;
  displayedColumns: string[] = [];
  applicationId: any;
  @Input() ApplicationId: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  IsKYC: boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  HeaderText: string = 'Pending Document';
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  today:Date=new Date();

  @Input() TypeLoan: any;
  @Input() LoanStatus: any;
  AllowExtension: any = constantUrl.AllowExtension;
  // dialog: any;

  constructor(
    private dataSharingService: DataSharingService, 
    private encdec: EncrDecrService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private snackBar: MatSnackBar, 
    private losService: LosService,
    private dialog: DialogService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.applicationId = decodeURIComponent(param.get("id"));//encdec.decrypt(param.get("id"));
    });
  }

  ngOnInit() {
    console.log("LoanStatus", this.LoanStatus);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.ApplicationId != null && this.ApplicationId != undefined)
      this.applicationId = this.ApplicationId;
    else
      this.dataSharingService.LOSHeaderTitle.next('Pending Document List');
    this.LOS_GetPendingDocListWEB();
    if (this.LoanStatus == undefined)
      this.LoanStatus ='P'
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
  LOS_GetPendingDocListWEB() {
    this.showSpinner = true;
   
    this.displayedColumns = ['ApplicationId', 'Category','CustomerId', 'Customer','DocumentId', 'DocName', 'Action'];
    console.log(this.applicationId);
    this.losService.LOS_GetPendingDocListWEB({ ApplicationId: this.applicationId }).subscribe((res: any) => {
      
      if(this.TypeLoan == 'QuickLoan'){
        this.PendingDocumentList = JSON.parse(JSON.stringify(res.Item2.filter(x => x.Category != "KYC")));
      }
      else{
        this.PendingDocumentList = JSON.parse(JSON.stringify(res.Item2));
      }
      
      this.applicationDetail = res.Item1[0];
      this.dataSource = new MatTableDataSource(this.PendingDocumentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
      this.docModal = {
        ApplicationId: this.applicationId,
        LoginUserId: this.currentUser.userId,
        DocId: "0-0",
        AssetName: "",
        No_Of_Pages: 1,
        IsReceipt: false,
        IsOrignalDoc: "1",
        DocumentImages: [],
        Type: 'Loan',
        KYC_DocId:0,
        ApplicationNo: this.applicationDetail.ApplicationNo
      };
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip()
      }, 1000)
    })
  }
  exportTable(exporter: any) {
    exporter.exportTable('xls', { fileName: `PendingDocument-${this.status}-list`, sheet: 'PendingDocuments' });
  }
  DoucumentCategoryDrodown(data) {
    this.losService
      .LOS_GetApplicationReqDocForDropDown({
        ApplicationId: data.ApplicationIdentity, Type: this.docModal.Type
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.docDropDown = res;
       // this.LOSAssetInfoDropDown();
        this.docModal.DocId = data.DocumentId + '-' + data.Doc_CategoryId;
        this.docModal.KYC_DocId = data.DocumentId;
        if (this.docModal.DocId.split('-')[1] == '1')
          this.IsKYC = true;
        else
          this.IsKYC = false;
        $('#addPendingDocument').modal('show');
        $("#addPendingDocument").css("z-index", "1000");
      });
  }
  onAddPendingDocument(data) {
    this.HeaderText = 'Pending Document';
    console.log(data);
    this.Customer = data.Customer;
    this.DocName = data.DocName;
    this.docModal.ApplicationId = this.applicationDetail.ApplicationIdentity;
    this.docModal.Type = data.Type;
    this.DoucumentCategoryDrodown(data);
   
    if (this.docModal != undefined && this.docModal != null && this.docModal.Type == 'Customer') {
      this.losService.GetCustomerByApplication({ ApplicationId: this.applicationDetail.ApplicationIdentity }).subscribe((result) => {
        this.CustomerDropdown = JSON.parse(JSON.stringify(result));
        let _find =  this.CustomerDropdown.find((obj: any) => { return obj.CustomerId == data.CustomerIdentity});
        this.docModal.Name = _find.Customer + '-' + _find.CustomerId;
      });
    }
    if (this.docModal != undefined && this.docModal != null && this.docModal.Type == 'Loan') {
     this.docModal.Name = "Other";
    }
    
    if (this.docModal.DocId.split('-')[1] == '1')
      this.IsKYC = true;
    else
      this.IsKYC = false;
    //this.kycModel = { Customer: data.Customer, KYC_DocNumber: data.Doc_Number, DocName: data.DocName, KYC_DocFile: data.Doc_FileName, LoginUserId: this.currentUser.userId };
    this.kycModel = { CustomerId: data.CustomerId, CustomerIdentity: data.CustomerIdentity, DocName: data.DocName, KYC_DocId: data.DocumentId, KYC_DocNumber: "", KYC_DocFile: "", KYC_IssuedDate: "", KYC_ExpiredDate: "", LoginUserId: this.currentUser.userId };
    

  }
  back() {
    this.router.navigate([`/rpt/pendingdocumentapplicationlist/`]);
  }
  fileChangeListener(data: any, files: any) {
    let reader = new FileReader();
    let _pre = this.today.getTime();
    let _docImg: any = {};
    data = _pre + "_" + files[0].name;
    _docImg.DocFileName = data;
    this.kycModel.KYC_DocFile = data;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      _docImg.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
    this.docModal.DocumentImages.push(_docImg);
    
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.KYC_DocFile = "";
    delete data.DocData;
  }
  OnClose() {
    $('#addPendingDocument').modal('hide');
    this.close.emit();
  }

  onSavePendingDocumentData() {
    this.showSpinner = true;

    console.log(this.kycModel);
    this.kycModel.KYC_IsVerified = 0;
    this.uploadKycDocs(this.kycModel);
    if (this.kycModel.CustomerId != 0) {
      this.losService.LOS_SaveCustomerKYCDocApp(this.kycModel).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LOS_GetPendingDocListWEB();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
    } else {
      this.losService.LOS_SaveApplicationDocument({ ApplicationId: this.applicationId, DocId: this.kycModel.KYC_DocId, DocTitle: this.kycModel.DocName, DocFileName: this.kycModel.KYC_DocFile, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          //this.LOS_GetApplicationDetailsForSaleProcess();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
    }
  }
  uploadKycDocs(data: any) {
    if (data.KYC_DocFile) {
      this.losService.UploadCustomerDoc({
        CustomerID: data.CustomerIdentity, DocName: this.docModal.DocumentImages[0].DocFileName,DocData: this.docModal.DocumentImages[0].DocData }).subscribe((res: any) => {

      });
    }
  }
  fileChangeListenerD(files: any, input: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      input.value = "";
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      input.value = "";
      return;
    }
    
    for (let i = 0; i < files.length; i++) {
      let _pre = this.today.getTime();
      let reader = new FileReader();
      let _docImg: any = {};
      _docImg.DocFileName = _pre + "_" +files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  async onSaveDoc() {
    let re=1
    if(this.docModal.IsOrignalDoc==0){
      await this.dialog.openConfirmDialog(`Are you sure you want to add this dcoument,because this document is not deleted.`)
      .afterClosed().toPromise().then(dialogResult => {
        if (dialogResult == false) {
          re=0;
          return;
        }
      
      })
    }
    if(re==0){
      this.OnClose(); 
      return ;
    }


    let _data = { ...this.docModal };
    if (+this.docModal.No_Of_Pages <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No Of Pages required and should be grater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.docModal.DocumentImages.length) {
      this.loading = true;
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        if (this.IsKYC) {
          this.losService
            .UploadCustomerDoc({
              DocName: this.docModal.DocumentImages[i].DocFileName,
              CustomerID: this.docModal.Name.split('-')[1],
              DocData: this.docModal.DocumentImages[i].DocData,
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
        } else {
          this.losService
            .UploadCrcDoc({
              DocName: this.docModal.DocumentImages[i].DocFileName,
              ApplicationNo: this.docModal.ApplicationNo,
              DocData: this.docModal.DocumentImages[i].DocData,
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
        }
        if (_err) {
          break;
        }
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload doc file.",
        ...this.configSuccess,
      });
    }
  }
  saveDoc() {
    var IsOrignalDoc;
    if (this.docModal.IsOrignalDoc == "1")
      IsOrignalDoc = 0;
    else
      IsOrignalDoc = 1;

    if (this.docModal.DocUId == undefined)
      this.docModal.DocUId = 0;
    if (this.docModal.Remark == undefined)
      this.docModal.Remark = "";
    if (this.IsKYC) {
      let docImage = ''
      if (this.docModal.DocumentImages.length > 1) {
        docImage = this.docModal.DocumentImages[1].DocFileName;

      }
      this.losService
        .LOS_SaveCustomerKYCDocApp({ CustomerId: this.docModal.Name.split('-')[1], KYC_DocId: this.docModal.DocId.split('-')[0], KYC_DocNumber: this.docModal.DocTitle, KYC_DocFile: this.docModal.DocumentImages[0].DocFileName, KYC_DocFile1: docImage, LoginUserId: this.currentUser.userId, KYC_IssuedDate: this.docModal.KYC_IssuedDate, KYC_ExpiredDate: this.docModal.KYC_ExpiredDate, KYC_IsVerified:0 })
        .subscribe((res: any) => {
          this.loading = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.LOS_GetPendingDocListWEB();
            this.OnClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });

    }
    else {
      let _data = {
        Document: {
          ApplicationId: this.docModal.ApplicationId,
          LoginUserId: this.docModal.LoginUserId,
          DocTitle: this.docModal.DocTitle,
          Remark: this.docModal.Remark,
          DocId: this.docModal.DocId.split('-')[0],
          AssetName: this.docModal.AssetName,
          No_Of_Pages: this.docModal.No_Of_Pages,
          IsReceipt: this.docModal.IsReceipt,
          IsOrignalDoc: IsOrignalDoc,
          DocUId:0
        },
        DocumentImages: []
      };
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        _data.DocumentImages.push({ DocImage: this.docModal.DocumentImages[i].DocFileName });
      }
      this.losService.LOS_SaveAppliactionDocumentJson({
        JSON: JSON.stringify(_data)
      }).subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.LOS_GetPendingDocListWEB();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
    }
  }


  DateChange() {
    console.log(this.docModal.KYC_IssuedDate , this.docModal.KYC_ExpiredDate );
    if (this.docModal.KYC_ExpiredDate != null) {
      if (this.docModal.KYC_IssuedDate > this.docModal.KYC_ExpiredDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Date Of Expire is greater than Date Of Issue.", ...this.configSuccess });
      
        this.docModal.KYC_ExpiredDate = ''; 
      }
    }
  }
}
