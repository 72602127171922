import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from 'moment';
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";


@Component({
  selector: 'app-npa-summary-report',
  templateUrl: './npa-summary-report.component.html',
  styleUrls: ['./npa-summary-report.component.scss']
})
export class NpaSummaryReportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  SlabData: any[] = [];
  displayedColumns: string[];
  displayedDetailColumns: string[];
  DataSource: any;
  dataSourceDetail: any;
  selectedId: any;
  JsonData: any[] = [];
  JsonData1: any[] = [];

  //Filter Parameter
  ToDate: any = new Date();
  SlabId: any = "";

  FyearId: any;
  minDate: any;
  

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(paginator: MatPaginator) {
    if(paginator != undefined){
      this.DataSource.paginator = paginator;
    }
  }





  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("NPA Summary Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getSlabsList();

    this.displayedColumns = [
      "Id", "NPA_Type", "Count", "Gross_Portfolio", "Provision_Amount", "NETPortfolio", "Gross_Percentage", "NETPortfolio_Percentage",  "Action"
    ];

    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          //console.log("Get_FinancialYear", this.minDate);
        });
      }
    )
  }


  getSlabsList() {
    this.masterService.Get__Slab_dropdown({ Type: "NPA", LoginUserId: this.currentUser.userId }).subscribe((res) => {
      this.SlabData = JSON.parse(JSON.stringify(res));
    });
  }

  onSearchNPADetailReport(){
    this.showSpinner = true;
    this.reportsService.Report_NPA_Summary({ slabId: this.SlabId, To: this.ToDate }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        res.forEach(x => {
          x.EMIAmount = parseFloat(x.EMIAmount).toFixed(2);
        });
        this. JsonData1=JSON.parse(JSON.stringify(res));
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.paginator = this.paginator;
        this.DataSource.sort = this.sort;
        this.showSpinner = false;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
      }
    });
  }


  exportTable() : void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("npa_summary") as HTMLTableElement;
    const worksheetName = 'NPA SUMMARY'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  exportTable1(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("npa_summary_details") as HTMLTableElement;
    const worksheetName = 'NPA SUMMARY DETAILS'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  } 
  
  totaltypewise1(type) {
    if (this.dataSourceDetail != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceDetail.length; i++) {
        sum += Number(this.dataSourceDetail[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  }

  onclickDetail(row) {
    this.showSpinner = true;
    this.displayedDetailColumns = ["Id", "LoanAcNo", "Loan_Date", "CustomerName", "DPD_Days", "DPD_Month", "Exposure", "Principal_OS", 
    "Interest_Reversal", "Charges_OS", "GrossPortfolio", "Provision_Rate", "Provision_Amount", "NetPortfolio", "NPA_Type", "DUE_ON",
    "TotalCollection", "Collection_Principal", "Collection_Interest", "Product_Name", "Branch_Name", "Customer_PhoneNo",
      "Address", "LoanAmount", "EMI_Frequency", "EMIAmount", "Loan_Tenure", "TotalEMI_NOS", "Future_Interst","Last_Recv_Date"];

    
    this.dataSourceDetail = '';
    this.reportsService.Report_NPA_Detail({ slabId: this.SlabId, To: this.ToDate, StartYear: this.minDate.getFullYear() }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        $('#ViewNPAStatementModel').modal('show');
        $("#ViewNPAStatementModel").css("z-index", "1050");
        res = res.filter(item => item.NPA_Type == row.NPA_Type);
        this.JsonData = JSON.parse(JSON.stringify(res));
        this.dataSourceDetail = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSourceDetail = this.dataSourceDetail.filteredData.filter(item => item.NPA_Type == row.NPA_Type);
        
        this.dataSourceDetail.matPaginator = this.matPaginator;
        this.dataSourceDetail.sort = this.sort;
        this.showSpinner = false;
      }
    });
    
  }
  applyFilter(filterValue:any){
    // console.log("filterValue",filterValue)
    this.dataSourceDetail.filter=filterValue.trim().toLowerCase();
  }
  onCloseNPAStatementModel() {
    $('#ViewNPAStatementModel').modal('hide');
  }

  ConvertToNumber(val){
    return Number(val);
  }
  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  onPageChange(paginatorName: string, event: any) {
    if (paginatorName === 'paginator1') {
      // Handle pagination for data set 1
    } else if (paginatorName === 'paginator2') {
      // Handle pagination for data set 2
    }
  }




}
