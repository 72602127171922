import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { LmsService } from '../../_Lms/services/lms.service';
import { QuickLoanService } from '../../_QuickLoan/services/quickLoan.service';

@Component({
  selector: 'app-quickloan-process-level',
  templateUrl: './quickloan-process-level.component.html',
  styleUrls: ['./quickloan-process-level.component.scss']
})
export class QuickloanProcessLevelComponent implements OnInit {
  private _MasterService; 
  showSpinner: boolean = false;
  currentUser: any;
  FilterProductId: any = '';

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  SelectProductCategory: any = ''; 
  ProductName: string = "";
  ProductShortName: string = '';
  ProductCategoryDropdown: any = [];

  ProcessModel: any = [];
  ProcessDropdown: any = [];
  ProcessFirst: any = [];
  ProcessLast: any = [];

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService,
    private _QuickLoanService: QuickLoanService,
  ) {  
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next('QLS Process');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.FilterProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      this.getProductDetail();
      this.QuickLoan_ProcessMaster();
      this.QuickLoan_Get_ProcessLevel();
    });
    this.getProductCategory();
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }
  getProductDetail() {
    this._MasterService.GetProductListById({ ProductId: this.FilterProductId }).subscribe((result) => {
      if (result.length > 0) {
        this.SelectProductCategory = result[0].ProductCatId;
        this.ProductName = result[0].Product;
        this.ProductShortName = result[0].ShortName;
      }
    });
  }

  QuickLoan_Get_ProcessLevel(){
    this._QuickLoanService.QuickLoan_Get_ProcessLevel({ ProductId: this.FilterProductId }).subscribe((res: any) => {
      console.log("res", res);
      
      setTimeout(() => {
        //this.ProcessModel = res;
        if(res.length > 0){
          let PLast = res[res.length - 1];
          var find = [];
          find = res.filter(x => x.Login_Fee_Check == true);
          if (find.length == 0) {
            //res[0].Login_Fee_Check = true;
          }
          this.ProcessModel = res.filter(x => x.ProcessId != PLast.ProcessId);
          this.ProcessLast = PLast;
          this.IsEdit = true;
          this.IsSave = false;
          this.IsDisabled = true;
          
        }
        else{
          this.SelectProcessData();
          this.IsEdit = false;
          this.IsSave = true;
          this.IsDisabled = false;
        }
      }, 300);
    });
  }

  QuickLoan_ProcessMaster(){
    this._QuickLoanService.QuickLoan_ProcessMaster().subscribe((res: any) => {
      this.ProcessDropdown = res;
    });
  }

  SelectProcessData(){
    this.ProcessFirst = this.ProcessDropdown[0];

    this.ProcessModel.push({
      ProcessId: this.ProcessFirst.ProcessId,
      Level: 1,
      Completed: true,
      Hold: false,
      Rejected: false,
      SendBack: false,
      Login_Fee_Check: false,
      Mannual_Assignment:false,
      Deviation_Check : false
    });

    this.ProcessLast = this.ProcessDropdown[this.ProcessDropdown.length-1];
    this.ProcessLast.Level = this.ProcessModel.length + 1;
    this.ProcessLast.Completed = true;
    this.ProcessLast.Hold = false;
    this.ProcessLast.Rejected = false;
    this.ProcessLast.SendBack = false;
    this.ProcessLast.Login_Fee_Check = false;
    this.ProcessLast.Mannual_Assignment = false;
    this.ProcessLast.Deviation_Check = false;
  }

  addMoreData() {
    this.ProcessModel.push({
      ProcessId: '',
      Level: this.ProcessModel.length + 1,
      Completed: true,
      Hold: false,
      Rejected: false,
      SendBack: false,
      Login_Fee_Check: false,
      Mannual_Assignment: false,
     Deviation_Check: false
    });
    this.ProcessLast.Level = this.ProcessModel.length + 1;
    this.ProcessLast.Completed = true;
    this.ProcessLast.Hold = false;
    this.ProcessLast.Rejected = false;
    this.ProcessLast.SendBack = false;
    this.ProcessLast.Login_Fee_Check = false;
    this.ProcessLast.Mannual_Assignment = false;

    this.ProcessLast.Deviation_Check = false;
  }

  removeData(index: any) {
    this.ProcessModel.splice(index, 1);
    // Update level
    this.ProcessModel.forEach((obj: any, index: any) => {
      obj.Level = index + 1;
    });
    this.ProcessLast.Level = this.ProcessModel.length + 1;
  }

  onChangeProcess(data: any, dindex: any) {
    let _find = this.ProcessModel.find((obj: any, index: any) => {
      return index != dindex && obj.ProcessId == data.ProcessId;
    });
    
    if (_find) {
      data.ProcessId = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This Process already exist.", ...this.configSuccess });
      data.ProcessId = null;
      //this.ProcessModel[dindex].ProcessId=null;
      return;
    }
  }

  SaveProcessLevel(){
    delete this.ProcessLast.Process_Name;
    let _data = {
      Data: this.ProcessModel
    }
    _data.Data.push(this.ProcessLast);
    console.log("_data", _data);
    
    this._QuickLoanService.QuickLoan_Save_ProcessLevel({ JSON: JSON.stringify(_data), ProductId: this.FilterProductId }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.QuickLoan_Get_ProcessLevel();
        this.IsUpdate = false;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
    });
  }

  onEditProcess(){
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }
  ProcessCheckboxChange(index: number) {
    this.ProcessModel.forEach(x => {
      x.Login_Fee_Check = false;
    })
    this.ProcessModel[index].Login_Fee_Check = true;
    this.ProcessLast.Login_Fee_Check = false;
  }
  ProcessLastCheckboxChange() {
    this.ProcessModel.forEach(x => {
      x.Login_Fee_Check = false;
    })
  }
}
