<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="OnSearchLoanClosedList()"
    class="formborder"
  >
    <div class="row m-0">
      <div class="col-md-2 p-1">
        <span> From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            (dateChange)="DateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
        /> -->
      </div>
      <div class="col-md-2 p-1">
        <span> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            (dateChange)="DateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
        /> -->
      </div>
      <div class="col-md-2 p-1">
        <span>Select Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          [(ngModel)]="BranchId"
          class="form-control input-text-css"
        >
          <option value="0">Select Branch</option>
          <option *ngFor="let item of BranchesList" [value]="item.BranchId">
            {{ item.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <span>Product</span>
        <select
          name="ProductId"
          id="ProductId"
          #refSelectGender="ngModel"
          [(ngModel)]="ProductId"
          class="form-control input-text-css"
        >
          <option value="0" selected>Select Product</option>
          <option
            *ngFor="let product of ProductList"
            [value]="product.ProductId"
          >
            {{ product.Product }}
          </option>
        </select>
      </div>
      <div class="col-md-1 p-1">
        <button
          type="button"
          (click)="OnSearchLoanClosedList()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
      <div class="col-md-3 mt-2" [hidden]="!DataSource">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!DataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Loan_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 50px"
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 57px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                (click)="goToLoanClosedDetails(row)"
                aria-label="true"
                style="color: blue; cursor: pointer"
              >
                {{ row.LoanAcNo }}</a
              >
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Loan_Date | date : "dd, MMM yyyy"
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-left grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Branch_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Product_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_LoanAmount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-left grid-header">{{
              totaltypewise("Application_LoanAmount")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_ExpiryDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Expiry Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_ExpiryDate | date : "dd, MMM yyyy"
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanClosed_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Closed Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanClosed_Date | date : "dd, MMM yyyy"
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalWaiver_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Total Waiver Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TotalWaiver_Amount
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanClosed_By">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Closed By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanClosed_By
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="NoOfNOC_Print">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >No Of NOC Print</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NoOfNOC_Print
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LastNOC_PrintBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Last NOC Print By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LastNOC_PrintBy
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LastNOC_PrintOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Last NOC Print On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LastNOC_PrintOn
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-left grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell text-center"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToLoanClosedDetails(row)"
                aria-label="true"
              ></i>
              <!--<div class="col-md-2 row m-0 justify-content-end">
                <app-printnocletter [LoanId]="row.Loan_Id" [index]="i"></app-printnocletter>
              </div>-->
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 100%"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center" style="background: #fff">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_loan_closed_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closed Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Waiver Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Closed By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of NOC Print
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last NOC Print By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last NOC Print On
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date | date : "dd, MMM yyyy" }}</td>
    <td>{{ x.Customer_Name }}</td>

    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Application_ExpiryDate | date : "dd, MMM yyyy" }}</td>
    <td>{{ x.LoanClosed_Date | date : "dd, MMM yyyy" }}</td>
    <td>{{ x.TotalWaiver_Amount }}</td>
    <td>{{ x.LoanClosed_By }}</td>
    <td>{{ x.NoOfNOC_Print }}</td>
    <td>{{ x.LastNOC_PrintBy }}</td>
    <td style="text-align: right">{{ x.LastNOC_PrintOn }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="6"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_LoanAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
    </tr>
  </tfoot>
</table>
