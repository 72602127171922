<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-2 formborder" *ngIf="dvDetail">
    <div class="col-md-2">
      <h6 class="fs-12">Application No</h6>
      <p class="lead fs-12">{{ dvDetail.ApplicationNo }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Branch</h6>
      <p class="lead fs-12">{{ dvDetail.Branch }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan Product</h6>
      <p class="lead fs-12">{{ dvDetail.Product }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Borrower Name</h6>
      <p class="lead fs-12">
        <app-lms-customer-view-model
          [customerId]="dvDetail.CustomerId"
          [index]="1000087452"
          >{{ dvDetail.Customer }}</app-lms-customer-view-model
        >
      </p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan Amount</h6>
      <p class="lead fs-12">₹{{ dvDetail.LoanAmount }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Tenure</h6>
      <p class="lead fs-12">{{ dvDetail.LoanTenure }} {{ Tenure_Name }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan No</h6>
      <p class="lead fs-12">{{ dvDetail.LoanNo }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan Date</h6>
      <p class="lead fs-12">{{ dvDetail.LoanDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">First EMI Date</h6>
      <p class="lead fs-12">{{ dvDetail.FirstEMIDate }}</p>
    </div>

    <div class="col-md-2">
      <h6 class="fs-12">Emi Amount</h6>
      <p class="lead fs-12">{{ dvDetail.EMIAmount }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">No Of Inst</h6>
      <p class="lead fs-12">{{ dvDetail.NoOfInstallment }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">TAT</h6>
      <p class="lead fs-12">{{ dvDetail.TAT_Hr }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Voucher Status</h6>
      <p class="lead fs-12">{{ dvDetail && dvDetail.VoucherStatus }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Created By</h6>
      <p class="lead fs-12">{{ dvDetail.CreatedBy }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Created On</h6>
      <p class="lead fs-12">{{ dvDetail.CreatedOn }}</p>
    </div>

    <div class="col-md-2">
      <h6 class="fs-12">Case IRR</h6>
      <p class="lead fs-12">{{ dvDetail.IRR | number : "1.2-2" }} %</p>
    </div>

    <div class="col-md-2">
      <h6 class="fs-12">ROI</h6>
      <p class="lead fs-12">{{ flat_ROI | number : "1.2-2"  }} %</p>
    </div>

    <div
      class="col-md-2"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Authorized'"
    >
      <h6 class="fs-12">Authorized On</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedOn }}</p>
    </div>
    <div
      class="col-md-2"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Authorized'"
    >
      <h6 class="fs-12">Authorized By</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedBy }}</p>
    </div>
    <div
      class="col-md-4"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Authorized'"
    >
      <h6 class="fs-12">Authorized Remark</h6>
      <p class="lead fs-12">{{ dvDetail.Remark }}</p>
    </div>
    <div
      class="col-md-2"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Reverted'"
    >
      <h6 class="fs-12">Reverted On</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedOn }}</p>
    </div>
    <div
      class="col-md-2"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Reverted'"
    >
      <h6 class="fs-12">Reverted By</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedBy }}</p>
    </div>
    <div
      class="col-md-4"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Reverted'"
    >
      <h6 class="fs-12">Reverted Remark</h6>
      <p class="lead fs-12">{{ dvDetail.Remark }}</p>
    </div>
  </div>

  <div class="mt-3">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSourceCustomerDetail">
        <ng-container matColumnDef="CustomerId">
          <mat-header-cell
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px; max-height: 5px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell class="grid-header" *matHeaderCellDef>
            Customer
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            <!--<app-los-customer-view-model [customerId]="row.CustomerId">{{row.Customer}}</app-los-customer-view-model>-->
            <app-lms-customer-view-model
              [customerId]="row.CustomerId"
              [index]="i"
              >{{ row.Customer }}</app-lms-customer-view-model
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerType">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GenderAge">
          <mat-header-cell class="grid-header" *matHeaderCellDef>
            Gender Age
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.GenderAge }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PhoneNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Phone No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <div *ngIf="currentUser.IsMasking">
              {{ row.PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.PhoneNo }}
            </div>

            <i
              *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
              class="fa fa-check-circle ml-2"
              style="color: green"
            ></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Relation_With_Hirer">
          <mat-header-cell class="grid-header" *matHeaderCellDef>
            Relation With Hirer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Relation_With_Hirer }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExistingCustomer">
          <mat-header-cell class="grid-header" *matHeaderCellDef>
            Existing
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ExistingCustomer }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedCustomerDetailColumns"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedCustomerDetailColumns"
        ></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="row m-0 mb-4">
    <div class="tab row m-0 col-md-12 p-0">
      <div
        class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
        [ngClass]="{ active: tab == 'deduction' }"
        (click)="changeTab('deduction')"
      >
        Deductions
      </div>
      <div
        class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
        [ngClass]="{ active: tab == 'receipt' }"
        (click)="changeTab('receipt')"
      >
        Receipts
      </div>
    </div>

    <!-- deduction -->
    <div class="row m-0 col-md-12 p-0 mt-2" *ngIf="tab == 'deduction'">
      <div class="main-container m-0 p-0">
        <form #df="ngForm" novalidate class="formborder">
          <div
            class="row m-0 mt-2 add-remove"
            *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index"
          >
            <div class="col-md-12 row m-0 type-column">
              <div class="type">
                <span *ngIf="i == 0">Type</span>
                <select
                  name="Type{{ i }}"
                  id="Type{{ i }}"
                  required
                  #refType="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                  [(ngModel)]="vdData.Type"
                  class="form-control pl-1 input-text-css"
                  disabled
                >
                  <option [value]="'DR'">DR</option>
                  <option [value]="'CR'">CR</option>
                </select>
              </div>
              <div class="row m-0 feild">
                <ng-container
                  *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Loan'"
                >
                  <div class="col-md-2 pr-0">
                    <span *ngIf="i == 0">Loan/App No.</span>
                    <!-- <span *ngIf="i==0">Search</span> -->
                    <div class="input-btn">
                      <input
                        type="text"
                        readonly
                        name="ApplicationNo{{ i }}"
                        id="ApplicationNo{{ i }}"
                        [(ngModel)]="vdData.ApplicationNo"
                        class="form-control input-text-css"
                      />
                      <button type="button" class="primary-btn ml-2 search">
                        <i
                          class="fa fa-search"
                          aria-hidden="true"
                          style="font-size: 11px"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-2 pr-0">
                    <span *ngIf="i == 0">Customer</span>
                    <input
                      type="text"
                      name="Customer{{ i }}"
                      id="Customer{{ i }}"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="vdData.Customer"
                    />
                  </div>
                  <div class="col-md-2 pr-0">
                    <span *ngIf="i == 0">Branch</span>
                    <input
                      type="text"
                      name="Branch{{ i }}"
                      id="Branch{{ i }}"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="vdData.Branch"
                    />
                  </div>
                  <div class="col-md-2 pr-0">
                    <span class="" *ngIf="i == 0">Category</span>
                    <select
                      name="ChagesHeadId{{ i }}"
                      id="ChagesHeadId{{ i }}"
                      #refChagesHeadId="ngModel"
                      [ngClass]="{
                        'is-invalid': df.submitted && refChagesHeadId.invalid,
                        'alert-warning':
                          refChagesHeadId.invalid &&
                          (refChagesHeadId.dirty ||
                            refChagesHeadId.touched ||
                            refChagesHeadId.untouched)
                      }"
                      [(ngModel)]="vdData.ChagesHeadId"
                      class="form-control input-text-css"
                      disabled
                    >
                      <option value="">Select Category</option>
                      <option
                        *ngFor="let item of chargesHeadDropdown"
                        [value]="item.Id"
                      >
                        {{ item.ChagesHead }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 pr-0">
                    <span *ngIf="i == 0">Account</span>
                    <input
                      type="text"
                      name="AccountId{{ i }}"
                      id="AccountId{{ i }}"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="vdData.Account"
                    />
                    <!--<select
    name="AccountId{{ i }}"
    id="AccountId{{ i }}"
    required
    #refAccountId="ngModel"
    [ngClass]="{
      'is-invalid': df.submitted && refAccountId.invalid,
      'alert-warning':
        refAccountId.invalid &&
        (refAccountId.dirty ||
          refAccountId.touched ||
          refAccountId.untouched)
    }"
    [(ngModel)]="vdData.AccountId"
    class="form-control input-text-css"
    disabled
  >
    <option value="">Select Head</option>
    <option
      *ngFor="let item of accountHeadDropdown"
      [value]="item.AccountId"
    >
      {{ item.Account_Name }}
    </option>
  </select>-->
                  </div>
                  <div class="col-md-1 pr-0 pl-1">
                    <span *ngIf="i == 0">Amount(DR)</span>
                    <!-- <input type="number" min="0" name="Amount{{ i }}" id="Amount{{ i }}" *ngIf="vdData.Type=='DR'"
                           class="form-control input-text-css text-right pr-3" [required]="vdData.Type=='DR'"
                           #refAmount="ngModel" (change)="onChangeAmount($event,vdData)"
                           (keyup.enter)="onChangeAmount($event,vdData)"
                           [ngClass]="{ 'is-invalid': df.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched) }"
                           [(ngModel)]="vdData.Amount" readonly> -->
                    <input
                      type="number"
                      min="0"
                      name="Amount{{ i }}"
                      id="Amount{{ i }}"
                      *ngIf="vdData.Type == 'DR'"
                      class="form-control input-text-css text-right pr-3"
                      [required]="vdData.Type == 'DR'"
                      #refAmount="ngModel"
                      [ngClass]="{
                        'is-invalid': df.submitted && refAmount.invalid,
                        'alert-warning':
                          refAmount.invalid &&
                          (refAmount.dirty ||
                            refAmount.touched ||
                            refAmount.untouched)
                      }"
                      [(ngModel)]="vdData.Amount"
                      readonly
                    />
                    <input
                      type="number"
                      name="AmountD{{ i }}"
                      id="AmountD{{ i }}"
                      readonly
                      class="form-control input-text-css text-right pr-3"
                      *ngIf="vdData.Type == 'CR'"
                    />
                  </div>
                  <div class="col-md-1 pr-0 pl-1">
                    <span *ngIf="i == 0">Amount(CR)</span>
                    <!-- <input type="number" min="0" name="Amount{{ i }}" *ngIf="vdData.Type=='CR'" id="Amount{{ i }}"
                      class="form-control input-text-css text-right pr-3" [required]="vdData.Type=='CR'"
                      #refAmount="ngModel" (change)="onChangeAmount($event,vdData)"
                      (keyup.enter)="onChangeAmount($event,vdData)"
                      [ngClass]="{ 'is-invalid': df.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched) }"
                      [(ngModel)]="vdData.Amount" readonly> -->
                    <input
                      type="number"
                      min="0"
                      name="AmountC{{ i }}"
                      *ngIf="vdData.Type == 'CR'"
                      id="AmountC{{ i }}"
                      class="form-control input-text-css text-right pr-3"
                      [required]="vdData.Type == 'CR'"
                      #refAmount="ngModel"
                      [ngClass]="{
                        'is-invalid': df.submitted && refAmount.invalid,
                        'alert-warning':
                          refAmount.invalid &&
                          (refAmount.dirty ||
                            refAmount.touched ||
                            refAmount.untouched)
                      }"
                      [(ngModel)]="vdData.Amount"
                      readonly
                    />
                    <input
                      type="number"
                      name="AmountD{{ i }}"
                      id="AmountD{{ i }}"
                      readonly
                      class="form-control input-text-css text-right pr-3"
                      *ngIf="vdData.Type == 'DR'"
                    />
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="vdData.TaxSlab && vdData.TaxSlab.length > 0">
              <div
                class="col-md-12 row m-0 p-0 mt-2"
                *ngFor="let taxData of vdData.TaxSlab; let ti = index"
              >
                <div class="col-md-2 pr-0">
                  <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                </div>
                <div class="col-md-3 pr-0">
                  <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                </div>
                <div class="col-md-1 p-0">
                  <span><b>Rate :</b>&nbsp;{{ taxData.TaxPercentage }} %</span>
                </div>
                <div class="col-md-4 pr-0">
                  <span><b>Account :</b>&nbsp;{{ taxData.Account }}</span>
                </div>
                <div class="col-md-1 text-right pl-0 pr-3">
                  <input
                    type="text"
                    required
                    readonly
                    name="Amount{{ i }}"
                    id="Amount{{ i }}"
                    *ngIf="vdData.Type == 'DR'"
                    [(ngModel)]="taxData.Amount"
                    (change)="onChangeTax()"
                    class="form-control input-text-css text-right pr-3"
                  />

                  <!-- <span>
                    <b></b>&nbsp;<ng-container *ngIf="vdData.Type=='DR'">
                      {{taxData.Amount.toFixed(2)}}
                    </ng-container>
                  </span> -->
                </div>
                <div class="col-md-1 text-right pl-1 pr-3">
                  <input
                    type="text"
                    required
                    name="Amount{{ i }}"
                    id="Amount{{ i }}"
                    readonly
                    *ngIf="vdData.Type == 'CR'"
                    [(ngModel)]="taxData.Amount"
                    (change)="onChangeTax()"
                    class="form-control input-text-css text-right pr-3"
                  />
                  <!-- <span>
                    <b></b>&nbsp;<ng-container *ngIf="vdData.Type=='CR'">
                      {{taxData.Amount.toFixed(2)}}
                    </ng-container>
                  </span> -->
                </div>
              </div>
            </ng-container>
          </div>

          <div
            class="col-md-12 row m-0 p-0 pr-5 mt-2"
            *ngIf="totalCalculator && voucherModel.Voucher_Detail.length > 0"
          >
            <div
              class="pr-0"
              [ngClass]="{
                'col-md-10': voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                'col-md-8':
                  voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
              }"
            >
              <span><b>Grand Total :</b></span>
            </div>
            <div
              class="text-right pr-1"
              [ngClass]="{
                'col-md-1': voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                'col-md-2':
                  voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
              }"
            >
              <span>{{ totalCalculator.totalDR.toFixed(2) }}</span>
            </div>
            <div
              class="text-right pr-1"
              [ngClass]="{
                'col-md-1': voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                'col-md-2':
                  voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
              }"
            >
              <span>{{ totalCalculator.totalCR.toFixed(2) }}</span>
            </div>
          </div>
          <div
            class="row m-0 mt-2 mb-2"
            *ngIf="voucherModel.Voucher_Detail.length > 0"
          >
            <div class="col-md-12">
              <span class="">Voucher Narration</span>
              <input
                type="text"
                #refVoucher_Narration="ngModel"
                [ngClass]="{
                  'is-invalid': df.submitted && refVoucher_Narration.invalid,
                  'alert-warning':
                    refVoucher_Narration.invalid &&
                    (refVoucher_Narration.dirty ||
                      refVoucher_Narration.touched ||
                      refVoucher_Narration.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
                name="Voucher_Narration"
                id="Voucher_Narration"
                class="form-control input-text-css"
                readonly
              />
            </div>
          </div>
          <!-- form -->
          <div *ngIf="PageId == 'null' || PageId == ''">
            <div class="row m-0 m-0 mt-3 mb-2">
              <div class="col-md-4">
                <h6 class="fs-12">Status</h6>
                <select
                  name="FilterStatus"
                  id="FilterStatus"
                  class="form-control input-text-css"
                  #refFilterStatus="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': df.submitted && refFilterStatus.invalid,
                    'alert-warning':
                      refFilterStatus.invalid &&
                      (refFilterStatus.dirty ||
                        refFilterStatus.touched ||
                        refFilterStatus.untouched)
                  }"
                  [(ngModel)]="authRevertStatus"
                  [disabled]="
                    dvDetail && dvDetail.VoucherStatus == 'Authorized'
                  "
                >
                  <option value="">Select Status</option>
                  <option value="Authorized">Authorized</option>
                  <option value="Reverted">Reverted</option>
                </select>
              </div>
              <div class="col-md-8">
                <h6 class="fs-12">Remark</h6>
                <input
                  type="text"
                  #refVoucher_remark="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': df.submitted && refVoucher_remark.invalid,
                    'alert-warning':
                      refVoucher_remark.invalid &&
                      (refVoucher_remark.dirty ||
                        refVoucher_remark.touched ||
                        refVoucher_remark.untouched)
                  }"
                  [(ngModel)]="voucherRemark"
                  name="Voucher_remark"
                  id="Voucher_remark"
                  class="form-control input-text-css"
                  [disabled]="
                    dvDetail && dvDetail.VoucherStatus == 'Authorized'
                  "
                />
              </div>
            </div>

            <div class="row col-md-12 m-0 mt-1 mb-2 justify-content-end">
              <div class="col-md-4 p-0">
                <button
                  type="button"
                  class="mt-3 btn font-size-12 button-btn"
                  (click)="OnClickTrackLoan()"
                >
                  Customer Loan Track
                </button>
              </div>
              <div class="col-md-4 p-0">
                <button
                  type="button"
                  class="ml-4 mt-3 btn font-size-12 button-btn"
                  (click)="ShowCAMReport()"
                  [class.spinner]="loading"
                >
                  CAM Report
                </button>
              </div>
              <div
                class="col-md-4 p-0 text-right"
                *ngIf="dvDetail && dvDetail.VoucherStatus != 'Authorized'"
              >
                <button
                  type="button"
                  class="ml-4 mt-3 btn font-size-12 button-btn"
                  (click)="saveAuthorizeDetail()"
                  [class.spinner]="loading"
                  [disabled]="!df.form.valid"
                >
                  Save
                </button>
                <button
                  type="button"
                  (click)="closeAuthorizeDetail()"
                  class="ml-4 mt-3 btn font-size-12 button-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- receipt -->
    <div class="row m-0 col-md-12 p-0 mt-2" *ngIf="tab == 'receipt'">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Application No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ChagesHead">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              ChagesHead
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ChagesHead }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Account">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Account
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TaxPercentage">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Tax(%)
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TaxPercentage }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              CollectionOn
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CollectionOn }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CollectionBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              CollectionBy
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CollectionBy }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
    <button
      type="button"
      (click)="Print()"
      [class.spinner]="loading"
      class="font-size-12 button-btn"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Authorized'"
    >
      Print
    </button>
    <button
      type="button"
      class="btn btn-light font-size-12"
      (click)="onCancel()"
      *ngIf="dvDetail && dvDetail.VoucherStatus == 'Authorized'"
      [class.spinner]="loading"
    >
      Cancel
    </button>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanTrack"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Loan Track
        </h6>
        <button
          type="button"
          class="close"
          (click)="CloseTrackLoan()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <ng-container
              *ngFor="let Cdata of CustomerTrackList; let i = index"
            >
              <thead>
                <tr class="grid-cell">
                  <th class="bg-success1">#</th>
                  <th class="bg-success1">CustomerId</th>
                  <th class="bg-success1">Customer</th>
                  <th class="bg-success1">Phone No.</th>
                  <th class="bg-success1" colspan="8">Present Address</th>
                  <th class="bg-success1" colspan="3">Gender/Age</th>
                </tr>
              </thead>
              <tr class="grid-cell">
                <td>{{ i + 1 }}</td>
                <td>{{ Cdata.CustomerId }}</td>
                <td>{{ Cdata.Customer }}</td>

                <td>
                  <div *ngIf="currentUser.IsMasking">
                    {{ Cdata.Customer_PhoneNo | mask }}
                  </div>

                  <div *ngIf="!currentUser.IsMasking">
                    {{ Cdata.Customer_PhoneNo }}
                  </div>
                </td>
                <td colspan="8">{{ Cdata.Present_Address }}</td>
                <td colspan="3">
                  {{ Cdata.Customer_Gender == "M" ? "Male" : "Female" }}
                </td>
              </tr>
              <tr>
                <th class="bg-success1" colspan="15">Loan Details :</th>
              </tr>
              <thead>
                <tr class="grid-cell">
                  <th>Loan A/C No.</th>
                  <th>Customer Type</th>
                  <th>Product Name</th>
                  <th>Total EMI</th>
                  <th>Total EMI Amount</th>
                  <th>Mature EMI</th>
                  <th>Mature EMI Amount</th>
                  <th>Remaining EMI</th>
                  <th>Remaining EMI Amount</th>
                  <th>Received EMI</th>
                  <th>Received EMI Amount</th>
                  <th>OD Balnce</th>
                  <th>Other Dues</th>
                  <th>POS</th>
                  <th>Status</th>
                </tr>
              </thead>
              <ng-container
                *ngFor="let CList of CustomerLoanList; let i = index"
              >
                <tr
                  class="grid-cell"
                  *ngIf="Cdata.CustomerId == CList.CustomerId"
                >
                  <td>
                    {{
                      CList.LoanAcNo == null
                        ? CList.ApplicationNo
                        : CList.LoanAcNo
                    }}
                  </td>
                  <td>
                    {{
                      CList.AC_CustomerType == "H"
                        ? "Borrower"
                        : CList.AC_CustomerType == "C"
                        ? "Co-Borrower"
                        : "Guaranter"
                    }}
                  </td>
                  <td>{{ CList.Product_Name }}</td>
                  <td>{{ CList.TotalEMI }}</td>
                  <td>{{ CList.TotalEMI_Amount }}</td>
                  <td>{{ CList.MatureEMI }}</td>
                  <td>{{ CList.MatureEMI_Amount }}</td>
                  <td>{{ CList.Remaining_EMI }}</td>
                  <td>{{ CList.Remaining_EMI_Amount }}</td>
                  <td>{{ CList.Received_EMI }}</td>
                  <td>{{ CList.Received_EMI_Amount }}</td>
                  <td>{{ CList.ODBalnce }}</td>
                  <td>{{ CList.OtherDues }}</td>
                  <td>{{ CList.POS }}</td>
                  <td>{{ CList.Loan_Status }}</td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="15">&nbsp;</td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div
    class="modal fade in"
    id="CAMReport"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 1140px"
    >
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            CAM Report
          </h6>
          <button
            type="button"
            class="close"
            (click)="HideCAMReport()"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <app-main-card-info [loanId]="dvDetail.LoanId"></app-main-card-info>
        </div>
      </div>
    </div>
  </div>
</div>
