<div class="col-md-12 float-rigth">
  <div class="col-md-2 text-right">
    <button
      type="button"
      (click)="downloadFile()"
      class="mt-3 font-size-12 button-btn"
    >
      Search
    </button>
  </div>
</div>
