<div>
  <form #f="ngForm" novalidate class="formborder d-flex align-items-center">
    <div class="row col-md-12">
      <div class="col-md-4">
        <span> Insurance Company Name </span>
        <input
          type="text"
          name="Insurance_company"
          [(ngModel)]="Insurance_com"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2" *ngFor="let item of checkboxesDataList">
        <div class="form-check" style="margin-top: 22px">
          <!-- <input class="form-check-input" type="checkbox" name="AssestInsurance" (click)="AssestInsurance()"> -->
          <input
            type="checkbox"
            [(ngModel)]="item.isChecked"
            id="{{ item.id }}"
            (change)="changeSelection()"
            [ngModelOptions]="{ standalone: true }"
          />
          <label class="form-check-label" for="defaultCheck1">
            {{ item.InsuranceType }}
          </label>
        </div>
      </div>
      <div class="col-md-2">
        <div _ngcontent-oqu-c393="" class="toggle-button-cover">
          <div _ngcontent-oqu-c393="" class="button-cover">
            <div
              _ngcontent-oqu-c393=""
              id="button-1"
              class="button r"
              style="margin: -20px auto 0 auto !important"
            >
              <input
                _ngcontent-oqu-c393=""
                type="checkbox"
                name="FilterStatusId"
                id="FilterStatusId"
                class="checkbox ng-valid ng-dirty ng-touched"
                ng-reflect-name="FilterStatusId"
                ng-reflect-model="false"
              />
              <div _ngcontent-oqu-c393="" class="knobs"></div>
              <div _ngcontent-oqu-c393="" class="layer"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="md-2 mt-2">
        <button
          type="button"
          (click)="SaveInsuranceCompany(f)"
          *ngIf="saveBtn"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
        <button
          type="button"
          (click)="UpdateInsuranceCmpy(f)"
          *ngIf="updateBtn"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3">
    <div class="">
      <div class="table-responsive">
        <mat-table [dataSource]="GetInsuranceCompany">
          <ng-container matColumnDef="Int_Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Insurance Company">
            <mat-header-cell
              class="grid-header"
              style="max-width: 400px"
              *matHeaderCellDef
            >
              Insurance Company
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 400px"
            >
              {{ row.Insurance_company }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Insurance Type">
            <mat-header-cell class="grid-header" style="" *matHeaderCellDef>
              Insurance Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="">
              {{ row.Insurance_type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell class="grid-header" style="" *matHeaderCellDef>
              Stauts
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="">
              <p
                *ngIf="row.Status == 1; else elsee"
                [ngStyle]="{ color: 'green' }"
              >
                Active
              </p>
              <ng-template #elsee>
                <p [ngStyle]="{ color: 'red' }">Deactive</p>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style=""
              *matHeaderCellDef
            >
              Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                *ngIf="!inViewMode"
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                aria-label="true"
                (click)="editInsuranceCmpy(row)"
              ></i>
              <i
                class="fa fa-trash style-delete"
                (click)="status(row)"
                *ngIf="!inViewMode"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
