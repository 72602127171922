import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from '../../AuthGuard/DataSharingService';

import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DepositService } from "../services/deposit.service";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;

@Component({
  selector: 'app-deposit-list',
  templateUrl: './deposit-list.component.html',
  styleUrls: ['./deposit-list.component.scss']
})
export class DepositListComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  Customer: any = {};
  BranchesList: any = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private dataSharingService: DataSharingService, 
    private router: Router, 
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService, 
    public snackBar: MatSnackBar, 
    private _DepositService: DepositService,
    private lmsService: LmsService
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.Customer = {
      CloseCase: '',
      BranchId : '',
    };

    this.getBranchesList();

    this.displayedColumns = [
      "Deposit_Id", "Deposit_Type", "Deposit_No", "Date_of_FDRD", "CustomerName", "Deposit_Amount", "Interest_Amount", "Maturity_Amount", "Date_of_Maturity", "Auto_Renewal","Action"
    ];
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  DepositList() {
    this.showSpinner = true;
    this._DepositService.Deposit_FDR_List({ LoginUserId: this.currentUser.userId, JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
      console.log("RESULT : ",res);
      if (res.length > 0) {
        if(res.length == 1){
          this.goToDetail(res[0]);
        }
        else {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.FileCheckingList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.dataSource =null;
      }
      this.showSpinner = false;
    });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Deposit-List`,
      sheet: "Deposit-List",
    });
  }
  goToDetail(row) {
    this.router.navigate([`deposit-dashboard/Deposit-Info/${encodeURIComponent(this._EncrDecrService.encrypt(row.Deposit_Id))}`]);
  }
  //goToDetailLoan(data: any) {
  //  this.router.navigate([
  //    `/lms/loan-info/${encodeURIComponent(data.LoanId)}/${"L"}`,
  //  ]);
  //}

  AddNewFDRD(){
    this.router.navigate([`deposit-dashboard/Deposit-Info`]);
  }
}
