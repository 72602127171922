import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { QuickLoanService } from "../services/quickLoan.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";

@Component({
  selector: "app-quickloan-inquiry-report",
  templateUrl: "./quickloan-inquiry-report.component.html",
  styleUrls: ["./quickloan-inquiry-report.component.scss"],
})
export class QuickloanInquiryReportComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  fromDate: any;
  toDate: any;
  Status: any = "All";
  empId: any = "All";
  currentUser: any;
  EmployeeNameList: any = [];
  dataSource: any;
  displayedColumns: string[] = [
    "InquiryId",
    "Inq_Source",
    "Inq_InquiryNo",
    "Inq_CustomerName",
    "Inq_CustomerAddress",
    "Inq_State",
    "Inq_District",
    "Inq_Tehsil",
    "Inq_PinCode",
    "Inq_ContactNumber",
    "Inq_Email",
    "Inq_Purpose",
    "Inq_LoanAmount",
    "status",
    "Inq_CreatedBy",
    "Inq_AssignedBy",
    "Inq_AssignedTo",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  JsonData: any[] = [];
  showSpinner: boolean;
  constructor(
    private dataSharingService: DataSharingService,
    private QuickLoanService: QuickLoanService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private quickLoanService: QuickLoanService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Inquiry Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getEmployeeList();
  }
  getEmployeeList() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.quickLoanService
      .LOS_GetManagerDashboardByTeam({
        ManagerId: this.currentUser.userId,
        TeamMemberId: 0,
      })
      .subscribe((res: any) => {
        this.EmployeeNameList = res.Item1;
      });
  }

  onSearchInquiryReport() {
    this.showSpinner = true;
    this.QuickLoanService.Quick_Loan_Lead_Report({
      fromDate: this.fromDate,
      toDate: this.toDate,
      Status: this.Status == "All" ? "" : this.Status,
      empId: this.empId == "All" ? "0" : this.empId,
      loginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      console.log("Result123 : ", res);

      if (res.length > 0) {
        this.JsonData = res;
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.dataSource = null;
      }

      this.showSpinner = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("inquiry_report") as HTMLTableElement;
    const worksheetName = "INQUIRY REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
