<section class="main-content-wrapper">
    <div class="row m-0">
      <div class="col-md-12">
        <nav class="">
          <div class="nav nav-tabs" id="nav-tab">
            <a routerLink="/lms-accounting/topup/topup-receipt" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
               class="nav-item" id="nav-receipt-tab">RECEIPT</a>
            <a routerLink="/lms-accounting/topup/topup-payment" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
               class="nav-item" id="nav-payment-tab">PAYMENT</a>
            <a routerLink="/lms-accounting/topup/topup-journal" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
               class="nav-item" id="nav-journal-tab">JOURNAL</a>
            
          </div>
        </nav>
        
        <router-outlet></router-outlet>
       
      </div>
    </div>
  </section>
