import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LmsService } from "src/app/_Lms/services/lms.service";
import * as moment from "moment";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;
import * as XLSX from "xlsx";
@Component({
  selector: "app-insurance-details",
  templateUrl: "./insurance-details.component.html",
  styleUrls: ["./insurance-details.component.scss"],
})
export class InsuranceDetailsComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  BranchesList: any = [];
  AssetsModel: any = [];
  FyearId: any;
  minDate: any;
  ToDate: any = new Date();
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth() - 1);
  Branch: any = "0";
  Category: any = "0";

  @ViewChild("assets_details") table: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatPaginator) paginator2: MatPaginator;

  DataSourceProperty: any;
  DataSourceVehicle: any;
  DataSourceCustomer: any;

  displayedColumnsProperty: string[];
  displayedColumnsVehicle: string[];
  displayedColumnsCustomer: string[];
  JsonData: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Insurance Details");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          //console.log("Get_FinancialYear", this.minDate);
        });
    });

    this.displayedColumnsProperty = [
      "Id",
      "LoanAcNo",
      "Loan_Date",
      "ExpiryDate",
      "Branch_Name",
      "Product_Name",
      "Customer",
      "TypeofProperty",
      "Application_LoanAmount",
      "PropertyOwnerName",
      "NatureofProperty",
      "OwnershipType",
      "OwnershipDocument",
      "propertyAddress",
      "TotalArea",
      "ConstructedArea",

      "TypeofMortgage",
      "MortgageSingedBy",
      "ValuationAmount",
      "ValuationDoneBy",
      "ValuationRemark",
      "GeoLocation",
      "InsuranceCompany",
      "Insurance_Branch",
      "AssetPolicyType",
      "AssetAgentName",
      "AssetCoverNoteNo",
      "AssetInsuranceDate",
      "AssetRenewalDate",
      "AssetIdvAmount",
      "AssetPremiumAmount",
      "AssetNomineeRelation",
      "AssetNomineeName",
    ];

    this.displayedColumnsVehicle = [
      "Id",
      "LoanAcNo",
      "Loan_Date",
      "ExpiryDate",
      "Branch_Name",
      "Product_Name",
      "Customer",

      "Application_LoanAmount",

      "ValuationAmount",
      "ValuationDoneBy",
      "ValuationRemark",
      "GeoLocation",
      "InsuranceCompany",
      "Insurance_Branch",
      "AssetPolicyType",
      "AssetAgentName",
      "AssetCoverNoteNo",
      "AssetInsuranceDate",
      "AssetRenewalDate",
      "AssetIdvAmount",
      "AssetPremiumAmount",
      "AssetNomineeRelation",
      "AssetNomineeName",
    ];

    this.displayedColumnsCustomer = [
      "Id",
      "LoanAcNo",
      "Loan_Date",
      "ExpiryDate",
      "Branch_Name",
      "Product_Name",
      "Customer",
      "InsuranceCustomer",
      "Customer_DOB",
      "CustomerInsAgentName",
      "InsuranceCompany",
      "Insurance_Branch",
      "CustomerInsExpireDate",
      "CustomerInsInsuranceDate",
      "CustomerInsNomineeName",
      "CustomerInsNomineeRelationHirer",
      "CustomerInsPolicyNo",

      "CustomerInsPolicyType",
      "CustomerInsPremiumAmount",
      "CustomerInsPremiumNextDueDate",
      "CustomerInsSumAssured",
    ];
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  onSearchInsuranceDetails() {
    this.showSpinner = true;
    this.DataSourceProperty = null;
    this.DataSourceVehicle = null;
    this.DataSourceCustomer = null;
    this.reportsService
      .Report_Get_Insurance_List({
        // LoanAcNo: this.AssetsModel.LoanNo,
        ProductCatg: this.Category,
        Branch_Id: this.Branch,
        From: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("res", res);

        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          if (this.Category == "Property Loan") {
            this.DataSourceProperty = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceProperty.paginator = this.paginator;
          } else if (this.Category == "Vehicle Loan") {
            this.DataSourceVehicle = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceVehicle.paginator = this.paginator1;
          } else {
            this.DataSourceCustomer = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceCustomer.paginator = this.paginator2;
          }
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  exportTable(): void {
    const table = document.getElementById(
      "insurance_details"
    ) as HTMLTableElement;
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "INSURANCE DETAILS.xlsx");
  }

  applyFilterVehicle(filterValue: any) {
    this.DataSourceVehicle.filter = filterValue.trim().toLowerCase();
  }

  applyFilterProperty(filterValue: any) {
    this.DataSourceProperty.filter = filterValue.trim().toLowerCase();
  }

  applyFilterCustomer(filterValue: any) {
    this.DataSourceCustomer.filter = filterValue.trim().toLowerCase();
  }
}
