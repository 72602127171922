import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DialogService } from '../../Shared/dialog.service'
import { MasterService } from '../../Shared/app.Masters.Service';
import { ToWords } from 'to-words';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import * as moment from 'moment';
declare var $: any;
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerService } from "../services/partner.service";
import { LmsService } from '../../_Lms/services/lms.service';

@Component({
  selector: 'app-partner-dv-detail',
  templateUrl: './partner-dv-detail.component.html',
  styleUrls: ['./partner-dv-detail.component.scss']
})
export class PartnerDvDetailComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  LoanId: any;
  VoucherId: any;
  dvDetail: any;
  tab: any = 'deduction';
  ACDetailList: any;
  deductionList: any[] = [];
  receiptList: any[] = [];
  dataSource: any;
  Bind_Voucher: any = 1;
  Partner_LoanId: any;
  VoucherDateTo: any;
  @Input() PageType: any;
  @Input() loanSummary: any;

  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  chargesHeadDropdownData:any[]=[];

  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];

  totalCalculator: { totalCR: number; totalDR: number; totalCRTX: number; totalDRTX: number; totalCRInWord: string; totalDRInWord: string; };

  displayedColumns: string[] = ["Id", "Type", "ApplicationNo", "ChagesHead", "Account", "TaxPercentage", "Amount", "CollectionOn", "CollectionBy"];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  Voucher_Detail_NEW_DEDUCTION: any = { TaxSlab: [] };
  totalCalculatorNewDeduction: any;
  Customer: any = {};

  IsEdit: boolean = false;

  constructor(
    public snackBar: MatSnackBar, 
    private route: ActivatedRoute, 
    private _Route: Router,
    private _EncrDecrService: EncrDecrService,
    private dataSharingService: DataSharingService,
    private _Partnerservice: PartnerService,
    private lmsService: LmsService,
    private dialog: DialogService,
    private _MasterService: MasterService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Disbursement Voucher Detail');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
    this.Get_Acc_Accounts_OtherThan_BankCash();
    this.Partner_LoanId = sessionStorage.getItem('Partner_LoanId');
    this.Partner_Get_DV_Details();

    if(this.Partner_LoanId == undefined){
      this._Partnerservice.Partner_Get_Existing_Loan_Details({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
        this.Partner_LoanId = res[0].Partner_LoanId;
        this.Partner_Get_DV_Details();
      }); 
    }
  }

  Partner_Get_DV_Details() {
    $('[data-toggle="tooltip"]').tooltip();
    //this.showSpinner = true;
    this.voucherModel.Voucher_Detail = []
    this._Partnerservice.Partner_Get_DV_Details({ LoanId: this.Partner_LoanId, VoucherId: 0 }).subscribe((res: any) => {
      if (res.Item1[0].CODE > 0) {
        this.chargesHeadDropdownData = [];

        this.dvDetail = res.Item1[0];
        if (res.Item2[0].length > 0 && res.Item2[0].VoucherId && res.Item2[0].VoucherId > 0) {
          this.VoucherDateTo = new Date(res.Item2[0].VoucherDate.split("-").reverse().join("-"));
        } else {
          this.VoucherDateTo = new Date(res.Item1[0].LoanDate.split("-").reverse().join("-"));
        }
        console.log("RESS", res);
        this.ACDetailList = res.Item2;
        this.deductionList = res.Item2;
        this.receiptList = res.Item3;
        this.dataSource = new MatTableDataSource(this.receiptList);
        this.showSpinner = false;
        this.initializeVoucher();
        this.deductionList.map((item) => {
          this.chargesHeadDropdownData.push({ Id: item.ChagesHeadId, ChagesHead: item.ChagesHead })
          if (!item.IsTax) {
            this.voucherModel.Voucher_Detail.push({
              ...item,
              isDisbled: true,
              IsLoanChargeable: true,
              isShowIcon: false,
              TaxSlab: []
            });
          } else if (this.voucherModel.Voucher_Detail.length > 1) {
            this.voucherModel.Voucher_Detail[this.voucherModel.Voucher_Detail.length - 1].TaxSlab.push(item);
          }
        });
        this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
          if (Number(obj.Bind_Voucher == null ? 0 : Number(obj.Bind_Voucher)) > this.Bind_Voucher) {
            this.Bind_Voucher = Number(obj.Bind_Voucher);
          }
          if (this.deductionList.filter(result => result.Bind_Voucher === obj.Bind_Voucher && result.IsTax === true).length > 0) {
            obj.GSTModel = {
              PartyType: 'Existing_Party',
              Acc_GSTNo: obj.GST_No,
              Customer: obj.Customer,
              Account_Name: obj.Customer,
              Slab_Tax: this.deductionList.filter(result => result.Bind_Voucher === obj.Bind_Voucher && result.IsTax === true)[0].SlabName
            }
          }
        });
        console.log("this.voucherModel.Voucher_Detail", this.voucherModel.Voucher_Detail);
        this.getTotalCalculation();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res.Item1[0].Msg,
          ...this.configSuccess,
        });
        this._Route.navigate([`/partner-dashboard/partner-updateBooking-list`]); 

       
      }
      })

  }

  onChangeCRDR(data: any) {
    data.ApplicationNo = "";
    data.CustomerID = "";
    data.Is_LOSAppliaction = "0";
    data.ChagesHeadId = "";
    data.ChagesHead = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer = "";
    data.Branch = "";
    data.isDisbled = false;
    data.isShowIcon = true,
      data.TaxSlab = [];
    data.IsLoanChargeable = false;
    this.getTotalCalculation();
  }

  saveVoucher() {
    this.voucherModel.Voucher.Voucher_Date = this.VoucherDateTo;
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _voucheretail: any[] = [];
    // let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //let _total: any = 0;
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalTXCR: any = 0;
    let _totalTXDR: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = parseFloat(_totalCR) + parseFloat(obj.Amount);
      }
      else {
        _totalDR = parseFloat(_totalDR) + parseFloat(obj.Amount);
      }
      _voucheretail.push(this.dataSharingService.cleanObject({
        VoucherId: obj.Id,
        CaseNo: obj.ApplicationNo,
        CustomerId: obj.CustomerID,
        Is_LOSAppliaction: 0,
        ChargeHeadId: obj.ChagesHeadId,
        ChargeHeadCategory: obj.Account,
        AccountId: obj.AccountId,
        BranchId: obj.BranchId,
        TranType: obj.Type,
        IsTax: obj.IsTax ? 1 : 0,
        Tax_Per: obj.TaxPercentage,
        Amount: obj.Amount,
        GST_Type: obj.TaxSlab.length > 0 ? obj.GSTModel.PartyType : '',
        GST_No: obj.TaxSlab.length > 0 ? obj.GSTModel.Acc_GSTNo : '',
        Party_AccountName: obj.TaxSlab.length > 0 ? obj.GSTModel.Customer : '',
        HSN_Code: obj.TaxSlab.length > 0 ? obj.GSTModel.HSNCode : '',
        Slab_Name: obj.TaxSlab.length > 0 ? obj.GSTModel.Slab_Tax : '',
        Bind_Voucher: obj.Bind_Voucher,
        Type: obj.TaxSlab.length > 0 ? 'Input' : ''
      }));
      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalTXCR = parseFloat(_totalTXCR )+ parseFloat(tobj.Amount);
          _totalCR = parseFloat(_totalCR) + parseFloat(tobj.Amount);
        }
        else {
          _totalTXDR = parseFloat(_totalTXDR) + parseFloat(tobj.Amount);
          _totalDR = parseFloat(_totalDR) + parseFloat(tobj.Amount);
        }
        _voucheretail.push(this.dataSharingService.cleanObject({
          VoucherId: obj.VoucherId,
          CaseNo: obj.ApplicationNo,
          CustomerId: "",
          Is_LOSAppliaction: 0,
          ChargeHeadId: obj.ChagesHeadId,
          ChargeHeadCategory: tobj.TaxType,
          AccountId: tobj.AccountId,
          BranchId: obj.BranchId,
          TranType: obj.Type,
          IsTax: 1,
          Tax_Per: tobj.TaxPercentage,
          Amount: tobj.Amount,
          Bind_Voucher: obj.Bind_Voucher,
          Type: ''
        }))
      });
    });

    

    _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    _vouvher.Voucher_Total_Debit_Amount = _totalDR;
    _vouvher.Voucher_Total_Tax = _totalTXCR + _totalTXDR;
    _vouvher.VoucherId = this.voucherModel.Voucher_Detail[0].VoucherId;

    if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Total Debit Amount must be equal to Total Credit Amount.", ...this.configSuccess });
      return;
    }

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: _voucheretail
    }

    //console.log("_finalData", _finalData);
    this._Partnerservice.PartnerSave_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      if (res && res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        //this.voucherModel.Voucher_Detail = [];
        //this.totalCalculator = undefined;
        this._Route.navigate(["/partner-dashboard/partner-updateBooking-list"]); 
      } else if (res) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }

  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = parseFloat(_totalCR) + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = parseFloat(_totalDR) + parseFloat(obj.Amount) || 0;
      }

      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalCR = parseFloat(_totalCR) + parseFloat(tobj.Amount) || 0;
          _totalCRTX = parseFloat(_totalCRTX) + parseFloat(tobj.Amount) || 0;
        }
        else {
          _totalDR = parseFloat(_totalDR) + parseFloat(tobj.Amount) || 0;
          _totalDRTX = parseFloat(_totalDRTX) + parseFloat( tobj.Amount) || 0;
        }
      });
    });

    this.totalCalculator = {
      totalCR: Number(_totalCR),
      totalDR: Number(_totalDR),
      totalCRTX: Number(_totalCRTX),
      totalDRTX: Number(_totalDRTX),
      totalCRInWord: new ToWords().convert(Number(_totalCR)),
      totalDRInWord: new ToWords().convert(Number(_totalDR))
    }
  }

  removeData(index: any, data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove.`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } 
      else {
        this._Partnerservice.Partner_Delete_ReceiptDeduction({ Id: data.Id, LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.Partner_Get_DV_Details();
        })
      }
    });
    // this.voucherModel.Voucher_Detail.splice(index, 1);
    // this.getTotalCalculation();
  }

  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherNewDetail();
    $("#dvDetailNewDeduction").modal("show");
    $("#dvDetailNewDeduction").css("z-index", "1050");
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      Voucher_LoanId: this.dvDetail.LoanId,
      VoucherId: this.VoucherId,
      Voucher_Type: 'Disbursement',
      Voucher_Sub_Type: 'Partner',
      Voucher_Tag: '',
      Voucher_Date: this.VoucherDateTo,
      Voucher_No: this.dvDetail.VoucherNo,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: this.dvDetail.Voucher_Narration,
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId
    };

    setTimeout(() => {
      //this.GetVoucherNo();
    }, 500)
  }

  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService.Get_Acc_Accounts_ForJVandContra({ IsJournal: 1 }).subscribe((res: any) => {
      this.accountHeadDropdown = res;
    });
  }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  onChangeAccountHead(data: any, index: any) {
    if (!data.AccountId) {
      data.Amount = "";
      delete data.IsLoanChargeable;
      this.getTotalCalculation();
      return;
    }
    let _find = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == Number(data.AccountId) })
    data.IsLoanChargeable = _find.IsLoanChargeable;
    if (_find.IsLoanChargeable) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please search Application and choose category for this account head.", ...this.configSuccess });
    }
    data.ChagesHeadId = "";
    data.Amount = "";
    data.ApplicationNo = "";
    data.Customer = "";
    data.CustomerID = "";
    data.ProductId = "";
    data.BranchId = "";
    data.Branch = "";
    data.LoanAccountingCategoryId = "";
    this.getTotalCalculation();
  }

  OnCloseSearchNew() {
    $("#dvDetailNewDeduction").modal("hide");
  }

  onChangeChargesHead(data: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: data.ChargeHeadId, VoucherSubType: 'Partner', ProductId: data.LoanAccountingCategoryId , TaxType: data.TranType }).subscribe((res: any) => {
      if (res.Item1[0]) {
        if (res.Item1[0].AccountId) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        }
        else {
          data.ChargeHeadId = "";
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not linked with this category.", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
      }
    });
  }

  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    });
    this.getTotalCalculationNew();
  }

  getTotalCalculationNew() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    _total = parseFloat(_total) + parseFloat(this.Voucher_Detail_NEW_DEDUCTION.Amount) || 0;
    _totalCR = parseFloat(_totalCR) + parseFloat(this.Voucher_Detail_NEW_DEDUCTION.Amount0) || 0;
    this.Voucher_Detail_NEW_DEDUCTION.TaxSlab.forEach((tobj: any) => {
      _total = parseFloat(_total) + parseFloat(tobj.TaxAmount) || 0;
      _totalTX = parseFloat(_totalTX) + parseFloat(tobj.TaxAmount) || 0;
    });
    this.totalCalculatorNewDeduction = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total))
    }
  }

  onSaveNewRdDetail() {
    this.showSpinner = true;
    if(this.Voucher_Detail_NEW_DEDUCTION.TaxSlab){
      this.Voucher_Detail_NEW_DEDUCTION.IsTax = 0;
    }
    this._Partnerservice.Partner_Save_ReceiptDeduction({ JSON: JSON.stringify({ Loaninfo: this.Voucher_Detail_NEW_DEDUCTION }) }).subscribe((res: any) => {
      //console.log("RES : ", res);
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.Partner_Get_DV_Details();
        this.OnCloseSearchNew();
      }
      else { this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess }); }
    });
  }

  initializeVoucherNewDetail() {
    //console.log("POP : ", this.ACDetailList);
    this.Voucher_Detail_NEW_DEDUCTION = {
      LoanId: this.dvDetail.LoanId,
      LoginUserId: this.currentUser.userId,
      DeductionLoanId: this.dvDetail.LoanId,
      CaseNo: this.dvDetail.LoanNo,
      CustomerId: this.loanSummary.CustomerId,
      Is_LOSAppliaction: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: this.ACDetailList[0].BranchId,
      TranType: "CR",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      Customer: this.dvDetail.Customer,
      Branch_Name: this.dvDetail.Branch,
      ProductId: this.loanSummary.ProductId,
      LoanAccountingCategoryId: this.loanSummary.LoanAccountingCategoryId,
      TaxSlab: [],
      GSTModel: {},
      Bind_Voucher: this.Bind_Voucher,
      Type: ""
    };
    this.getChargeshead();
  }

  getChargeshead() {
    this._Partnerservice.PartnerGet_ChargesHeads_ForDropdown({ LoanAccountingId: this.loanSummary.LoanAccountingCategoryId }).subscribe((res: any) => {
      this.chargesHeadDropdown = res;
    });
  }

  EditVoucher(){
    this.IsEdit = true;
  }
  DateChange() {
    if (this.VoucherDateTo == '' || this.VoucherDateTo == null || this.VoucherDateTo == undefined) {
      if (this.ACDetailList.VoucherId && this.ACDetailList.VoucherId > 0) {
        this.VoucherDateTo = new Date(moment(this.ACDetailList.VoucherDate).format('MM/DD/YYYY'));;
      } else {
        this.VoucherDateTo = new Date(moment(this.dvDetail.LoanDate).format('MM/DD/YYYY'));;
      }
    }
  }
}
