<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="formborder">
    <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
      <div class="row m-0 h-b w-b" style="width: 100%">
        <h1 class="fs-12 m-0">Loan Details</h1>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Application No</h6>
          <p class="lead fs-12">{{ loanDetailObj.ApplicationNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Product</h6>
          <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">
            ₹ {{ loanDetailObj.AssetCost ? loanDetailObj.AssetCost : "0" }}
          </p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Net Finance</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure (Months)</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanTenure }} Months</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ loanDetailObj.No_Of_Installment }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.AgreementValue }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.InterestAmt }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ loanDetailObj.LTV }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanEMIAmount }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ loanDetailObj.ROI }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">IRR Calculate By</h6>
          <p class="lead fs-12">{{ loanDetailObj.IRR_CalculateBy }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ loanDetailObj.Case_IRR }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">EMI Due Date</h6>
          <p class="lead fs-12">
            {{ loanDetailObj.EMI_DueDate | date : "dd/MM/yyyy" }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Int_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Id
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          <app-los-customer-view-model
            [customerId]="row.CustomerId"
            [index]="i"
          >
            {{ row.Customer }}
          </app-los-customer-view-model>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          

          <div *ngIf="data.IsMasking">
            {{ row.PhoneNo | mask }}
          </div>

          <div *ngIf="!data.IsMasking">
            {{ row.PhoneNo }}
          </div>
          



          <i
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 13px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Relation_With_Hirer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Relation With Borrower
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Relation_With_Hirer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Present_Address">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 300px; max-width: 300px"
          *matHeaderCellDef
        >
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 300px; max-width: 300px"
        >
          {{ row.Present_Address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ExistingCustomer">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 90px; max-width: 90px"
          *matHeaderCellDef
        >
          Existing
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 90px; max-width: 90px"
        >
          {{ row.ExistingCustomer }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div class="formborder mt-2">
    <ng-container>
      <div
        class="row m-0 mt-2 h-b w-b justify-content-between align-items-center"
        style="width: 100%"
      >
        <h1 class="fs-12 m-0">Detail</h1>
        <button
          type="button"
          (click)="onViewLoanStatement()"
          class="btn btn-light font-size-12"
          style="padding: 3px 5px"
        >
          View Loan Statement
        </button>
      </div>
      <form #rdf="ngForm" novalidate>
        <div class="row m-0 mt-3" id="printData">
          <div style="width: 100%; padding-left: 20px; padding-right: 20px">
            <div class="row m-0 mt-3">
              <div class="col-md-3">
                <h6 class="fs-12">Status</h6>
                <select
                  name="CloserType"
                  id="CloserType"
                  [disabled]="PreCloserDetails.CloserId > 0"
                  (change)="StatusChange()"
                  required
                  #refCloserType="ngModel"
                  [(ngModel)]="PreCloserDetails.CloserType"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refCloserType.invalid,
                    'alert-warning':
                      refCloserType.invalid &&
                      (refCloserType.dirty ||
                        refCloserType.touched ||
                        refCloserType.untouched)
                  }"
                  class="form-control input-text-css"
                  style="width: 185px; display: inline-block"
                >
                  <option value="">Select Status</option>
                  <option *ngFor="let item of StatusList" [value]="item.Id">
                    {{ item.Value }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Last Transaction Date</h6>
                <p>{{ PreCloserDetails.lastTrasactionDate }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Effective Date</h6>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate"
                    [min]="MinEffectiveDate"
                    [max]="PreCloserDetails.NextDueDate"
                    name="LoanCloser_EffectiveDate"
                    id="LoanCloser_EffectiveDate"
                    (dateChange)="onEffectiveDateChange()"
                    [disabled]="
                      PreCloserDetails.Closer_RequestStatus != 'Created'
                    "
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    style="right: 0 !important"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <!--<input type="text" [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate" [minDate]="MinEffectiveDate"
         [maxDate]="PreCloserDetails.NextDueDate" name="LoanCloser_EffectiveDate"
         id="LoanCloser_EffectiveDate"
         value="{{ PreCloserDetails.LoanCloser_EffectiveDate | date: 'dd/MM/yyyy' }}"
         placeholder="DD/MM/YYYY" [(bsValue)]="PreCloserDetails.LoanCloser_EffectiveDate"
         class="form-control input-text-css" bsDatepicker style="width: 160px;"
         (bsValueChange)="onEffectiveDateChange()"
         [disabled]="PreCloserDetails.Closer_RequestStatus != 'Created'" />-->
              </div>
            </div>

            <table style="margin-bottom: 20px" class="mt-3">
              <tbody>
                <tr style="background: #120eeb29">
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Account Head</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Due</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Received</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b> Settled /Account Sold</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Balance/WriteOff</b>
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let preCloserData of PreCloserMethodList;
                    let i = index
                  "
                  style="height: 43px"
                >
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    {{ preCloserData.ChagesHead }}
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ preCloserData.DueAmount }}
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ preCloserData.ReceiveAmount }}
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <input
                      type="text"
                      [(ngModel)]="preCloserData.WaiverAmount"
                      placeholder="amount"
                      name="WaiverAmount{{ i }}"
                      id="WaiverAmount{{ i }}"
                      class="form-control input-text-css"
                      [disabled]=" PreCloserDetails.CloserId > 0 || (CloserTypeValue != 'Settled' && CloserTypeValue != 'Post (WO) Settled' && CloserTypeValue != 'Written Off and Account Sold' && CloserTypeValue != 'Account Sold')" style="width: 90px; margin: 0px auto"(ngModelChange)="onChangeWaiverAmt($event, preCloserData)"/>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ preCloserData.Balance }}
                  </td>
                </tr>
                <tr
                  *ngIf="PreCloserMethodList.length > 0 && totalCalculator"
                  style="background: #120eeb29"
                >
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Total :</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ totalCalculator.totalDueAmount?.toFixed(2) }}</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ totalCalculator.totalReceiveAmount?.toFixed(2) }}</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ totalCalculator.totalWaiverAmount?.toFixed(2) }}</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ totalCalculator.totalBalance?.toFixed(2) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="PreCloserDetails.CloserId <= 0 && (CloserTypeValue == 'Settled' ||  CloserTypeValue == 'Post (WO) Settled' ||  CloserTypeValue == 'Written Off and Account Sold' || CloserTypeValue == 'Account Sold')">
          <div class="row m-0">
            <div class="col-md-12"><h1 class="fs-12 h-b">Payment Mode</h1></div>
          </div>

          <div class="row m-0 align-items-center">
            <div class="col-md-3">
              <span class="required-lable">Select Tag </span>
              <select
                name="Voucher_Tag"
                id="Voucher_Tag"
                required
                (change)="onVoucherTagChange()"
                class="form-control input-text-css"
                #refVoucher_Tag="ngModel"
                [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refVoucher_Tag.invalid,
                  'alert-warning':
                    refVoucher_Tag.invalid &&
                    (refVoucher_Tag.dirty ||
                      refVoucher_Tag.touched ||
                      refVoucher_Tag.untouched)
                }"
              >
                <option value="Bank">Bank</option>
                <option value="CASH">CASH</option>
              </select>
            </div>

            <div class="col-md-3">
              <span class="required-lable">Select Account</span>
              <select
                name="DRAccountId"
                id="DRAccountId"
                required
                #refDRAccountId="ngModel"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refDRAccountId.invalid,
                  'alert-warning':
                    refDRAccountId.invalid &&
                    (refDRAccountId.dirty ||
                      refDRAccountId.touched ||
                      refDRAccountId.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.DRAccountId"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let data of accountTagDropdown"
                  [value]="data.AccountId"
                >
                  {{ data.Account_Name }}
                </option>
              </select>
            </div>
            <div
              class="col-md-3"
              *ngIf="voucherModel.Voucher.Voucher_Tag == 'Bank'"
            >
              <span class="required-lable">Select Mode of Payment</span>
              <select
                name="Voucher_Mode_of_Payment"
                id="Voucher_Mode_of_Payment"
                required
                (change)="onChangeMode()"
                class="form-control input-text-css"
                #refVoucher_Mode_of_Payment="ngModel"
                [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                [ngClass]="{
                  'is-invalid':
                    rdf.submitted && refVoucher_Mode_of_Payment.invalid,
                  'alert-warning':
                    refVoucher_Mode_of_Payment.invalid &&
                    (refVoucher_Mode_of_Payment.dirty ||
                      refVoucher_Mode_of_Payment.touched ||
                      refVoucher_Mode_of_Payment.untouched)
                }"
              >
                <option value="">Select Mode Of Payment</option>
                <option value="Cheque">Cheque</option>
                <option value="RTGS">RTGS</option>
                <option value="Gateway">E-Gateway</option>
                <option value="NEFT">NEFT</option>
              </select>
            </div>
            <div class="col-md-3">
              <span class="required-lable">Select Collection By</span>
              <select
                name="Collection_By"
                id="Collection_By"
                required
                #refCollection_By="ngModel"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refCollection_By.invalid,
                  'alert-warning':
                    refCollection_By.invalid &&
                    (refCollection_By.dirty ||
                      refCollection_By.touched ||
                      refCollection_By.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Collection_By"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let data of employeeDropdown"
                  [value]="data.EmpId"
                >
                  {{ data.EmpName }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row m-0 mt-1"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != ''"
          >
            <ng-container
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
            >
              <div class="col-md-4">
                <span class="required-lable">Cheque No</span>
                <input
                  type="text"
                  required
                  #refVoucher_Cheque_No="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refVoucher_Cheque_No.invalid,
                    'alert-warning':
                      refVoucher_Cheque_No.invalid &&
                      (refVoucher_Cheque_No.dirty ||
                        refVoucher_Cheque_No.touched ||
                        refVoucher_Cheque_No.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
                  name="Voucher_Cheque_No"
                  id="Voucher_Cheque_No"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span class="required-lable">Cheque Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="pickervcd"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_Cheque_Date"
                    id="Voucher_Cheque_Date"
                    #refVoucher_Cheque_Date="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        rdf.submitted && refVoucher_Cheque_Date.invalid,
                      'alert-warning':
                        refVoucher_Cheque_Date.invalid &&
                        (refVoucher_Cheque_Date.dirty ||
                          refVoucher_Cheque_Date.touched ||
                          refVoucher_Cheque_Date.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervcd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervcd></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4">
                <span class="required-lable">Bank Name</span>
                <input
                  type="text"
                  required
                  #refVoucher_Bank_Name="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refVoucher_Bank_Name.invalid,
                    'alert-warning':
                      refVoucher_Bank_Name.invalid &&
                      (refVoucher_Bank_Name.dirty ||
                        refVoucher_Bank_Name.touched ||
                        refVoucher_Bank_Name.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
                  name="Voucher_Bank_Name"
                  id="Voucher_Bank_Name"
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>
            <ng-container
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
            >
              <div class="col-md-4">
                <span class="required-lable">Ref No</span>
                <input
                  type="text"
                  required
                  #refVoucher_E_Ref_No="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refVoucher_E_Ref_No.invalid,
                    'alert-warning':
                      refVoucher_E_Ref_No.invalid &&
                      (refVoucher_E_Ref_No.dirty ||
                        refVoucher_E_Ref_No.touched ||
                        refVoucher_E_Ref_No.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
                  name="Voucher_E_Ref_No"
                  id="Voucher_E_Ref_No"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span class="required-lable">Transaction Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="pickervetd"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_E_Transaction_Date"
                    id="Voucher_E_Transaction_Date"
                    #refVoucher_E_Transaction_Date="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        rdf.submitted && refVoucher_E_Transaction_Date.invalid,
                      'alert-warning':
                        refVoucher_E_Transaction_Date.invalid &&
                        (refVoucher_E_Transaction_Date.dirty ||
                          refVoucher_E_Transaction_Date.touched ||
                          refVoucher_E_Transaction_Date.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="
                      voucherModel.Voucher.Voucher_E_Transaction_Date
                    "
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervetd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervetd></mat-datepicker>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row m-0 mt-1">
            <div class="col-md-12">
              <span class="">Voucher Narration</span>
              <input
                type="text"
                #refVoucher_Narration="ngModel"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refVoucher_Narration.invalid,
                  'alert-warning':
                    refVoucher_Narration.invalid &&
                    (refVoucher_Narration.dirty ||
                      refVoucher_Narration.touched ||
                      refVoucher_Narration.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
                name="Voucher_Narration"
                id="Voucher_Narration"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="row col-md-12 m-0 mt-5 justify-content-end">
          <button
            type="button"
            (click)="onSaveRequest()"
            *ngIf="PreCloserDetails.CloserId <= 0 && PreCloserDetails.IsClosed==0 && savebutton"
            class="btn font-size-12 button-btn"
            [disabled]="!rdf.form.valid"
          >
            Save
          </button>
          <table>
            <tr *ngIf="PreCloserDetails.CloserId > 0 && PreCloserDetails.IsClosed!=1">
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Undo Remark:</h6>
              </td>
              <td style="padding: 5px" colspan="2">
                <textarea
                  [(ngModel)]="undoRemark"
                  placeholder="Undo Remark"
                  name="undoRemark"
                  id="undoRemark"
                  class="form-control input-text-css"
                ></textarea>
              </td>
            </tr>
          </table>
          <button
            type="button"
            *ngIf="PreCloserDetails.CloserId > 0 && PreCloserDetails.IsClosed!=1"
            (click)="onUndoCloserRequest()"
            class="btn font-size-12 button-btn"
          >
            Undo Loan
          </button>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewLoanStatementPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div
        cdkDragHandle
        class="modal-header"
        cdkDragHandle
        style="padding: 10px"
      >
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseViewLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="PrintLoanStatementPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Print loan statement
        </h6>
        <button
          type="button"
          (click)="onClosePrintLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="loanStatement" id="pdfPrintReport">
        <div style="padding: 50px">
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              margin-right: -15px;
              margin-left: -15px;
            "
          >
            <div style="width: 70%; float: left">
              <p class="printStatemant_P">To,</p>
              <p></p>
              <p class="printStatemant_P">{{ CustomerName }}</p>
              <p [innerHtml]="CustomerAddress"></p>
              <p style="text-align: left; font-weight: 600">
                Subject :- Pre-Payment outstanding of your
                {{ LoanAllDetail[0].Product }} Loan Account No :
                {{ LoanAllDetail[0].LoanNo }}
              </p>
              <p>Dear {{ CustomerName }},</p>
            </div>
            <div style="width: 30%; float: left; text-align: right">
              <!-- <img src="../../../assets/images/logo.png" alt="" style="width: 100px;"> -->
              <img src="{{ BasePath }}logo.png" alt="" style="width: 100px" />
              <p>
                {{ data.FullName }}<br />
                Date : {{ today | date : "dd/MM/yyyy" }} {{ time }}
              </p>
            </div>
          </div>
          <br />

          <p>
            We value your relationship with {{ loanStatement.Item1[0].Comapny }}
            In response to your request for Pre-Payment outstanding of your
            above-mentioned Loan No.Please Find the detail as mentioned Below:
          </p>
          <div>
            <table style="width: 60%; margin-bottom: 10px">
              <tbody>
                <tr style="background: #120eeb29">
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      font-weight: bold;
                    "
                  >
                    Account Head
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                      font-weight: bold;
                    "
                  >
                    Balance (₹)
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let preCloserData of PrintCloserMethodList;
                    let i = index
                  "
                >
                  <td style="border: 1px solid #ddd; padding: 5px 10px 2px">
                    {{ preCloserData.ChagesHead }}
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                    "
                  >
                    {{ preCloserData.Balance }}
                  </td>
                </tr>
                <tr style="background: #120eeb29">
                  <td style="border: 1px solid #ddd; padding: 5px 10px 2px">
                    <b>Total</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                    "
                  >
                    <b>{{ printtotalCalculator.totalBalance?.toFixed(2) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="LoanChargesRecoverableList">
            <p style="text-align: left; font-weight: 600">
              Detail Of Loan Charges Recoverable
            </p>
            <table style="width: 55%; margin-bottom: 10px">
              <tbody>
                <tr style="background: #120eeb29">
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Head</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Amount</b>
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let LoanCharges of LoanChargesRecoverableList;
                    let i = index
                  "
                  style="height: 43px"
                >
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    {{ LoanCharges.ChagesHead }}
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ LoanCharges.Amount }}
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Total</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ LoanChargesRecoverable.Due_Amount?.toFixed(2) }}</b>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Received</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{
                      LoanChargesRecoverable.Received_Amount?.toFixed(2)
                    }}</b>
                  </td>
                </tr>
                <tr style="background: #120eeb29">
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Balance</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ LoanChargesRecoverable_Balance?.toFixed(2) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style="text-align: left; font-weight: 600">Kindly note that:</p>
          <p>
            1. Above calculation assumes that your last instalment has been
            cleared.
          </p>
          <p>
            2. We have taken the date of prepayment as
            <b>{{
              PreCloserDetails.LoanCloser_EffectiveDate | date : "dd/MM/yyyy"
            }}</b
            >. For each day beyond this date as additional interest will be
            charged.
          </p>
          <p>
            3. On prepayment of the loan, the company shall try to prevent
            payment of the subsequence month's instalment. As a precaution, we
            advise you to make a stop payment request for your next month's
            instalment. In case the next month's instalment is debited from your
            account, the amount will be refunded, subject to clearance.
          </p>
          <p>
            4. The above-mentioned amount is valid subject to clearance of all
            the cheques / instalments till date.
          </p>
          <p>
            5. Prepayment charges is applicable on the outstanding amount of the
            facility or total interest (unexpired period interest and
            outstanding interest) whichever is lower
          </p>
          <p>
            6. To release the Collateral/NOC of this loan, please close all the
            loan accounts which are linked to this collateral.
          </p>
          <p>
            7. Following documents would be refunded to you within 30 days of
            Prepayment of your Loan.
          </p>
          <p class="ml-4">
            &bull; No Objection Certificate and The document of Title in respect
            of the immovable Property under Pledge (if any)
          </p>
          <p class="ml-4">
            &bull; Subject to all other obligation towards
            {{ loanStatement.Item1[0].Comapny }} is satisfied including cross
            liability.
          </p>
          <p>
            8. This FC outstanding is having charges accrued up till
            <b>{{
              PreCloserDetails.LoanCloser_EffectiveDate | date : "dd/MM/yyyy"
            }}</b
            >.
          </p>
          <p>
            9. All charges which got accrued today does not include in this FC
            and has to be paid in addition.
          </p>
          <p>
            If you require any further details of your LOAN account, please
            contact us on the Telephone Number given below. Our Customer Service
            Representative would be glad to assist you. Thanking you and your
            patronage in future.
          </p>

          <p>
            <B>{{ loanStatement.Item1[0].Comapny }}</B
            ><br />
            {{ loanStatement.Item1[0].ComapnyAddress }}<br />
            {{ loanStatement.Item1[0].ComapnyPhoneno }}<br />
            {{ loanStatement.Item1[0].ComapnyEmail }}<br />
            {{ loanStatement.Item1[0].ComapnyGSTNo }}
          </p>
          <p class="text-center">
            No signature is required as this is system generated statement
          </p>
        </div>
      </div>
      <div style="width: 100%; text-align: right; padding: 20px">
        <button class="btn font-size-12 button-btn" (click)="downloadPdf()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Print
        </button>
      </div>
    </div>
  </div>
</div>
