import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { LmsService } from '../../_Lms/services/lms.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-mst-loan-series',
  templateUrl: './mst-loan-series.component.html',
  styleUrls: ['./mst-loan-series.component.scss']
})
export class MstLoanSeriesComponent implements OnInit {
  private data: any; showSpinner: boolean = false;
  dataSource: any; loading: boolean = false;
  displayedColumns: string[] = ['Id', 'Series', 'Acc_Category', 'Product', 'EditAction'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  LoanSeriesList: any = [];
  dialogreturn: any;
  ProductDropDown: any[] = [];
  AccountingCatDropdown: any[] = [];
  loanSeriesModal: any = {};
  dropdownSettings = {
    singleSelection: false,
    idField: 'ProductId',
    textField: 'Product_Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  constructor(private dataSharingService: DataSharingService, private dialog: DialogService, public snackBar: MatSnackBar, private masterService: MasterService, private lmsService: LmsService) {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Loan Series')
    this.getLoanSeriesList();
    this.GetProductForDropdown();
    this.Get_Loan_Accounting_Category_ForDropdown();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getLoanSeriesList() {
    this.showSpinner = true;
    this.lmsService.LMS_Get_Loan_Series_For_List({}).subscribe((result) => {
      this.LoanSeriesList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.LoanSeriesList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }
  GetProductForDropdown() {
    this.masterService.GetProductForDropdown().subscribe((res: any) => {
      this.ProductDropDown = res;
    })
  }
  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({}).subscribe((res: any) => {
      this.AccountingCatDropdown = res;
    })
  }
  onAddLoanSeries() {
    this.loanSeriesModal = { Loan_SeriesId: 0, Loan_Accounting_CatId: "", Loan_Products: [], LoginUserId: this.data.userId };
    $('#AddLSModal').modal('show');
    $("#AddLSModal").css("z-index", "1050");
  }

  OnClose() {
    $('#AddLSModal').modal('hide');
    this.loanSeriesModal={};
  }
  OnEditSeries(data: any) {
    let _product: any[] = data.ProductIds.split(',');
    let _productCat: any[] = [];
    _product.forEach((obj: any) => {
      const _find = this.ProductDropDown.find((fobj: any) => { return fobj.ProductId == parseInt(obj) });
      _productCat.push(_find);
    });
    this.loanSeriesModal = { Loan_SeriesId: data.Id, Loan_Series: data.Series, Loan_Accounting_CatId: data.LoanAccCategoryId, Loan_Products: _productCat, LoginUserId: this.data.userId };
    $('#AddLSModal').modal('show');
    $("#AddLSModal").css("z-index", "1050");
  }

  onSaveLoanSeries() {
    let _productid: any[] = [];
    this.loanSeriesModal.Loan_Products.forEach((obj: any) => {
      _productid.push(obj.ProductId);
    });
    if (_productid.length == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please select atleast one product.", ...this.configSuccess });
      return;
    }
    let _data = { ...this.loanSeriesModal, Loan_Products: _productid.join(',') }
    this.lmsService.Save_Loan_NO_Series(_data).subscribe((response) => {
      if (response[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.OnClose();
        this.getLoanSeriesList();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  OnDeleteSeries(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete?');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.lmsService.Delete_Loan_No_Series({ Loan_SeriesId: row.Id, LoginUserId: this.data.userId }).subscribe((response) => {
          if (response[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            this.getLoanSeriesList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
