import { Component, OnInit } from "@angular/core";
import { LmsService } from "../services/lms.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { saveAs } from "file-saver";

@Component({
  selector: "app-cibil-api-test",
  templateUrl: "./cibil-api-test.component.html",
  styleUrls: ["./cibil-api-test.component.scss"],
})
export class CibilApiTestComponent implements OnInit {
  constructor(private lmsService: LmsService, private http: HttpClient) {}

  private apiUrl =
    "https://www.test.transuniondecisioncentre.co.in/DC/TUCL/TU.DE.Pont/Documents/15984469";

  ngOnInit(): void {}

  downloadFile(): void {
    this.lmsService
      .FetchTransunionDecisionDocument({ Identifier: "16186907" })
      .subscribe((response: any) => {
        if (response != null) {
          saveAs(response, "aaa.pdf");
        }
      });
    // const headers = new HttpHeaders({
    //   Authorization: `Bearer lOLj9ZQWFVihkPa4YeHds14TWYLbwTspHsj7IxI00OwP3M5hJkVH7AuZnmA6hB2Xj3QnlyDBepSUC1xdtWePN1eFfe9swu9QCVyLDFUl-hmFxketabyq3cxHES4ui8n3Jn_K6_3JFbPJr50OeMIYCSb1S2A6f6eeQwYBV8um6hSOXIQggJLCCVyhkKOMJ8pTEG3gUla3Us_APjXsMpbkkHP3fFHec0RiGuZfbrDjIn7WTDT7cnThXyf3hEkDxCJt8r_P6rAuAeGREhOwXwVBE2LamTm38fvwfcnNS7NBxnTJLAc-TLmEm7OvBvRYNokcDm4389mAFiO_J-NYL2WIR99QFHA3Zvni4xc83ItuJK47r7bK77iSmiDeLq-KzbBT`,
    //   "Access-Control-Allow-Origin": "*",
    //   "content-type": "application/json",
    // });
    // this.http
    //   .get(`${this.apiUrl}`, { headers, responseType: "blob" })
    //   .subscribe(
    //     (response: any) => {
    //       const contentDispositionHeader: string = response.headers.get(
    //         "Content-Disposition"
    //       );
    //       const fileName = contentDispositionHeader
    //         .split(";")[1]
    //         .trim()
    //         .split("=")[1];
    //       saveAs(responseBody, fileName);
    //     },
    //     (error: any) => {
    //       console.error("Error: ", error);
    //       // show error message to the user
    //     }
    //   );
  }

  saveAs(blob: Blob, fileName: string) {
    console.log("Save as pdf fn");
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  // getCibilDataFemina() {
  //   this.lmsService.Get_Cibil_Data_Femina({}).subscribe((response: Blob) => {
  //     const blobUrl = window.URL.createObjectURL(response);
  //     const link = document.createElement("a");
  //     link.href = blobUrl;
  //     link.setAttribute("download", "file.pdf");

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(blobUrl);
  //   });
  // }
}
