export class MenusModel {


  public userId: number;
  public MM_ID: number;
  public MM_Name: string;
  public MM_iconName: string;
  public MM_Url: string;
  public MM_ParentID: number;
  public MM_IsShow: boolean;
  public MM_IsParent: boolean;
}

export var newMenuModal = []
export var newMenuModalNew = [
  {
    "MM_ID": 1,
    "MM_Name": "Home",
    "MM_Url": "/LOS/Dashboard",
    "MM_iconName": "fa fa-home",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 2,
    "MM_Name": "My Dashboard",
    "MM_Url": "/LOS/my-dashboard",
    "MM_iconName": "fa fa-home",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 2,
    "MM_Name": "Search Application",
    "MM_Url": "/LOS/search/application",
    "MM_iconName": "fa fa-search",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 3,
    "MM_Name": "Search Customer",
    "MM_Url": "/LOS/Search/customer",
    "MM_iconName": "fa fa-search",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 4,
    "MM_Name": "TAT Report",
    "MM_Url": "/LOS/TAT/Report",
    "MM_iconName": "fa fa-clock",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 5,
    "MM_Name": "CAM Report",
    "MM_Url": "/LOS/cam/report",
    "MM_iconName": "fa fa-file-image",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 6,
    "MM_Name": "Status Report",
    "MM_Url": "/LOS/status/report",
    "MM_iconName": "fa fa-file-image",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 7,
    "MM_Name": "Hold Inquiry",
    "MM_Url": "/rpt/holdinquiry",
    "MM_iconName": "fa fa-file-image",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  {
    "MM_ID": 8,
    "MM_Name": "Pending Documents",
    "MM_Url": "/rpt/pendingdocumentapplicationlist",
    "MM_iconName": "fa fa-file-image",
    "MM_ParentID": 0,
    "MM_IsShow": true,
    "MM_IsParent": false
  },
  //{
  //  "MM_ID": 9,
  //  "MM_Name": "Rejected Applications",
  //  "MM_Url": "/LOS/rejected/application",
  //  "MM_iconName": "fa fa-file-image",
  //  "MM_ParentID": 0,
  //  "MM_IsShow": true,
  //  "MM_IsParent": false
  //} 
]
