<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="searchCustomerKycVerificationDetails()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          class="form-control input-text-css"
          (dateChange)="DateChange()"
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          class="form-control input-text-css"
          (dateChange)="DateChange()"
          required
          #reffToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffToDate.invalid,
            'alert-warning':
              reffToDate.invalid &&
              (reffToDate.dirty || reffToDate.touched || reffToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>

    <div class="col-md-3">
      <span class="required-lable"> Step Name </span>
      <select
        name="Step_Name"
        id="Step_Name"
        #refStep_Name="ngModel"
        required
        [ngClass]="{
          'is-invalid': df.submitted && refStep_Name.invalid,
          'alert-warning':
            refStep_Name.invalid &&
            (refStep_Name.dirty ||
              refStep_Name.touched ||
              refStep_Name.untouched)
        }"
        [(ngModel)]="Step_Name"
        class="form-control input-text-css"
      >
        <option value="">Select Step Name</option>
        <option
          *ngFor="let stepName of stepNameDropdown"
          [value]="stepName.Step_Name"
        >
          {{ stepName.Step_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-2">
      <button
        type="button"
        (click)="searchCustomerKycVerificationDetails()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="float-end col-md-3 mt-2" [hidden]="!DataSource">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!DataSource">
  <div class="table-responsive" style="height: 350px; overflow: auto">
    <table
      mat-table
      class="table-bordered"
      matSort
      [dataSource]="DataSource"
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container matColumnDef="Step_Name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Step Name</th>
        <td mat-cell *matCellDef="let row">{{ row.Step_Name }}</td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container matColumnDef="DoucumentNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Doucument Number
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.DoucumentNumber }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container matColumnDef="CreatedBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
        <td mat-cell *matCellDef="let row">
          {{ row.CreatedBy }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container matColumnDef="CreatedOn">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>CreatedOn</th>
        <td mat-cell *matCellDef="let row">
          {{ row.CreatedOn }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="sticky-footer fontcolor"
        style="background: #4dc3a3"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></tr>
    </table>
  </div>
</div>

<div class="mt-2 row col-md-12" [hidden]="!DataSource">
  <div class="col-md-4">
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportexcel()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="export-kyc-verification-detail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Step Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Doucument Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CreatedBy
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created On
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Step_Name }}</td>
    <td>{{ x.DoucumentNumber }}</td>
    <td>{{ x.CreatedBy }}</td>
    <td>{{ x.CreatedOn }}</td>
  </tr>
</table>
