<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #dbf="ngForm" (ngSubmit)="onSearchAccuredReport()" novalidate>
  <div class="d-flex justify-content-between align-items-center formborder">
    <div class="col-md-2 p-1">
      <span>Report Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="reportDate"
          id="reportDate"
          required
          #refreportDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="reportDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refreportDate.invalid,
            'alert-warning':
              refreportDate.invalid &&
              (refreportDate.dirty ||
                refreportDate.touched ||
                refreportDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <button
        [disabled]="!dbf.form.valid"
        type="button"
        (click)="onSearchAccuredReport()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Search
      </button>
    </div>

    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!DataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_LoanAmount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_FirstEMIDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >First EMI Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_FirstEMIDate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_EMIType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >EMI Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_EMIType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Interest_From">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Interest From</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Interest_From }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Interest_To">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Interest To</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Interest_To }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Days_Diff">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Days Difference</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Days_Diff }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Interest">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Interest</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">
            {{ row.Interest }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Accured_Interest">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Accured Interest</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Accured_Interest }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>

      <div class="col-md-8 text-right">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  id="accured-report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      First EMI Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest From
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest To
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Days Diff
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Accured Interest
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Application_FirstEMIDate }}</td>
    <td>{{ x.Application_EMIType }}</td>
    <td>{{ x.Interest_From }}</td>
    <td>{{ x.Interest_To }}</td>
    <td>{{ x.Days_Diff }}</td>
    <td>{{ x.Interest }}</td>
    <td>{{ x.Accured_Interest }}</td>
  </tr>
</table>
