<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #dbf="ngForm" (ngSubmit)="onSearchAssetsDetails()" novalidate>
  <div class="row m-0 formborder">
    <div class="col-md-2 p-1">
      <span class="required-lable">Assets Category : </span>
      <select
        name="Category"
        id="Category"
        #refCategory="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refCategory.invalid,
          'alert-warning':
            refCategory.invalid &&
            (refCategory.dirty || refCategory.touched || refCategory.untouched)
        }"
        [(ngModel)]="AssetsModel.Category"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Product</option>
        <option
          *ngFor="let Product of ProductCatgDropdown"
          [value]="Product.Product_Category"
          [hidden]="Product.Product_Category == 'Un-Secured Loan'"
        >
          {{ Product.Product_Category }}
        </option>
      </select>
    </div>

    <div class="col-md-2 p-1">
      <span>From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="AssetsModel.FromDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span>To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refToDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="AssetsModel.ToDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span> Branch</span>
      <select
        name="Branch"
        id="Branch"
        #refSelectGender="ngModel"
        [(ngModel)]="AssetsModel.Branch"
        class="form-control input-text-css"
      >
        <option value="0">Select Branch</option>
        <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
          {{ branch.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-1 p-1 text-right mr-1">
      <button
        type="button"
        (click)="onSearchAssetsDetails()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!dbf.form.valid"
      >
        Search
      </button>
    </div>

    <div
      class="col-md-3 mt-2"
      *ngIf="AssetsModel.Category == 'Property Loan' && DataSourceProperty"
    >
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilterProperty($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div
      class="col-md-3 mt-2"
      *ngIf="AssetsModel.Category == 'Vehicle Loan' && DataSourceVehicle"
    >
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilterVehicle($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div
      class="col-md-3 mt-2"
      style="margin-left: 2px"
      *ngIf="AssetsModel.Category == 'Gold Loan' && DataSourceGoldLoan"
    >
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilterGold($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div
  class="mt-3"
  *ngIf="AssetsModel.Category == 'Property Loan' && DataSourceProperty"
>
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceProperty"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SalesExecutive">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Sales Executive</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SalesExecutive
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TypeofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Collateral_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Collateral Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Collateral_Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.Application_LoanAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PropertyOwnerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Owner</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PropertyOwnerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NatureofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nature of Property</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.NatureofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipDocument">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Document</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipDocument
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Address">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 150px"
            >Property Address</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
            >{{ row.Address }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="City">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >City</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.City
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Tasil">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Tehsil</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Tasil
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="District">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >District</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.District
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="State">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >State</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.State
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TotalArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Total Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell"
            >{{ row.TotalArea }} {{ row.UnitofMeasurement }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="ConstructedArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Constructed Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell"
            >{{ row.ConstructedArea }} {{ row.UnitofMeasurement }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="TypeofMortgage">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Type of Mortgage</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofMortgage
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MortgageSingedBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Mortgage Singed By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.MortgageSingedBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MarketValue">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Market Value</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.MarketValue
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.ValuationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="LegalReportBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Legal Report By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LegalReportBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationRemark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Remark</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationRemark
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationRemarkDoc">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Remark Doc</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationRemarkDoc
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SearchReportRemark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Search Report Remark</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportRemark
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SearchReportRemarkDoc">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Search Report Remark Doc</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportRemarkDoc
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GeoLocation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Geo Location</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.GeoLocation
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneByOther">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By Other</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneByOther
          }}</mat-cell>
        </ng-container>
<!-- 
        <ng-container matColumnDef="SearchReportDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Search Report Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportDoneBy
          }}</mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="LegalReportDoneByOther">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Legal Report Done By Other</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportDoneByOther
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsProperty; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsProperty"
        ></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="mt-3"
  *ngIf="AssetsModel.Category == 'Vehicle Loan' && DataSourceVehicle"
>
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceVehicle"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="height: 372px; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SalesExecutive">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Sales Executive</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SalesExecutive
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CollectionExecutive">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Collection Executive</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CollectionExecutive
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Collateral_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Collateral Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Collateral_Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_LoanAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleManufacture">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Manufacture</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleManufacture
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleCategory">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Category</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleCategory
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleModelName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Model Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleModelName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Variant">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Variant</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Variant
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleRegistationNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle RC</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleRegistationNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="EngineNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Engine No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.EngineNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ChassisNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Chassis No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ChassisNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="KeyNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Key No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.KeyNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="RegistationDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Registation Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.RegistationDate | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="RegistationExpiryDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Registation Expiry Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.RegistationExpiryDate | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="FuelType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Fuel Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.FuelType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Color">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Color</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Color
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Vehicle_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Vehicle_Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Vehicle_SubType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Sub Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Vehicle_SubType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MefgYear">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Mfg. Year</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.MefgYear
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleCost">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Cost</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleCost
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Dealer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Dealer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Dealer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DealerContactPerson">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Dealer Contact Person</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DealerContactPerson
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DealerContactNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Dealer Contact No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DealerContactNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Category">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Category</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Category
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="VehicleCondition">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Vehicle Condition</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VehicleCondition
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DONo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >DO No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DONo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DODoDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >DO Do Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DODoDate | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="EstimationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Estimation Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.EstimationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InvoiceNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Invoice No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InvoiceNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InvoiceDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Invoice Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InvoiceDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InvoiceValue">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Invoice Value</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InvoiceValue
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DOIssuedinfavourof">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >DO Issued in favour of</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DOIssuedinfavourof
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Remark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Remark</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Remark
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDate | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OtherValuationType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Other Valuation Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OtherValuationType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OtherValuationAmt">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Other Valuation Amt</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OtherValuationAmt
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="FitnessAuthority">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Fitness Authority</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.FitnessAuthority
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="FitnessUpto">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Fitness Upto</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.FitnessUpto
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PermitAuthority">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Permit Authority</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PermitAuthority
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PermitUpto">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Permit Upto</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PermitUpto | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PermitType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Permit Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PermitType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExtraInfo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Extra Info</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ExtraInfo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="RoadTaxUpto">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Road Tax Upto</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.RoadTaxUpto | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Rout">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Route</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Rout
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="HPNEndorsment">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >HPN Endorsment</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.HPNEndorsment
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DateofEndorsment">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Date of Endorsment</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DateofEndorsment
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsVehicle; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsVehicle"
        ></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="mt-3"
  *ngIf="AssetsModel.Category == 'Gold Loan' && DataSourceGoldLoan"
>
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceGoldLoan"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="height: 372px; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRefG.pageIndex * paginatorRefG.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="SalesExecutive">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Sales Executive</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SalesExecutive
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Collateral_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Collateral Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Collateral_Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TotalItem_Qty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Total Item Qty</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TotalItem_Qty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TotalValuationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TotalValuationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuerPhoneNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuer Phone No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuerPhoneNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuerFirmName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuer Firm Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuerFirmName
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsGoldLoan; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsGoldLoan"
        ></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<table
  id="assets_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="AssetsModel.Category == 'Property Loan' && DataSourceProperty"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collateral Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Owner
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nature of Property
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Document
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Address
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Constructed Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type of Motgage
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mortgage Singed By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Market Value
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Done By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Legal Report By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Remark
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Remark Doc
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Search Report Remark
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Search Report Remark Doc
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Geo Location
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      State
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      City
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tehsil
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      District
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Done by Other
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Search Report Done By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Search Report Done By Other
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.TypeofProperty }}</td>
    <td>{{ x.Collateral_Type }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.PropertyOwnerName }}</td>
    <td>{{ x.NatureofProperty }}</td>
    <td>{{ x.OwnershipType }}</td>
    <td>{{ x.OwnershipDocument }}</td>
    <td>{{ x.Address }}</td>
    <td>{{ x.TotalArea }} {{ x.UnitofMeasurement }}</td>
    <td>{{ x.ConstructedArea }} {{ x.UnitofMeasurement }}</td>
    <td>{{ x.TypeofMortgage }}</td>
    <td>{{ x.MortgageSingedBy }}</td>
    <td>{{ x.MarketValue }}</td>
    <td>{{ x.ValuationAmount }}</td>
    <td>{{ x.ValuationDoneBy }}</td>
    <td>{{ x.LegalReportBy }}</td>
    <td>{{ x.ValuationRemark }}</td>
    <td>{{ x.ValuationRemarkDoc }}</td>
    <td>{{ x.SearchReportRemark }}</td>
    <td>{{ x.SearchReportRemarkDoc }}</td>
    <td>{{ x.GeoLocation }}</td>
    <td>{{ x.State }}</td>
    <td>{{ x.City }}</td>
    <td>{{ x.Tasil }}</td>
    <td>{{ x.District }}</td>
    <td>{{ x.ValuationDoneByOther }}</td>
    <td>{{ x.SearchReportDoneBy }}</td>

    <td>{{ x.SearchReportDoneByOther }}</td>
  </tr>
</table>
<table
  id="assets_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="AssetsModel.Category == 'Vehicle Loan' && DataSourceVehicle"
>
  <thead>
    <tr>
      <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Application No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Date
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Branch
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Product
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Sales Executive
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Collection Executive
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Customer
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Collateral Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Amount
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Manufacture
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Category
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Model Name
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Variant
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle RC
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Engine No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Chassis No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Key No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Registation Date
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Registation Expiry Date
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Fuel Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Color
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Sub Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Mfg. Year
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Cost
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Dealer
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Dealer Contact Person
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Dealer Contact No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Category
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Vehicle Condition
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        DO No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        DO Do Date
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Estimation Amount
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Invoice No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Invoice Date
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Invoice Value
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        DO Issued in favour of
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Remark
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Valuation Done By
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Valuation Date
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Valuation Amount
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Valuation Type
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Other Valuation Type
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Other Valuation Amt
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Fitness Authority
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Fitness Upto
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Permit Authority
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Permit Upto
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Permit Type
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Extra Info
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Road Tax Upto
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Route
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        HPN Endorsment
      </th>

      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Date of Endorsment
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let x of JsonData; let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ x.LoanAcNo }}</td>
      <td>{{ x.Application_No }}</td>
      <td>{{ x.Loan_Date | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.Branch_Name }}</td>
      <td>{{ x.Product_Name }}</td>
      <td>{{ x.SalesExecutive }}</td>
      <td>{{ x.CollectionExecutive }}</td>
      <td>{{ x.Customer }}</td>
      <td>{{ x.Type }}</td>
      <td>{{ x.Collateral_Type }}</td>
      <td>{{ x.Application_LoanAmount }}</td>
      <td>{{ x.VehicleManufacture }}</td>
      <td>{{ x.VehicleCategory }}</td>
      <td>{{ x.VehicleModelName }}</td>
      <td>{{ x.Variant }}</td>
      <td>{{ x.VehicleRegistationNo }}</td>
      <td>{{ x.EngineNo }}</td>
      <td>{{ x.ChassisNo }}</td>
      <td>{{ x.KeyNo }}</td>
      <td>{{ x.RegistationDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.RegistationExpiryDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.FuelType }}</td>
      <td>{{ x.Color }}</td>
      <td>{{ x.Vehicle_Type }}</td>
      <td>{{ x.Vehicle_SubType }}</td>
      <td>{{ x.MefgYear }}</td>
      <td>{{ x.VehicleCost }}</td>
      <td>{{ x.Dealer }}</td>
      <td>{{ x.DealerContactPerson }}</td>
      <td>{{ x.DealerContactNo }}</td>
      <td>{{ x.Category }}</td>
      <td>{{ x.VehicleCondition }}</td>
      <td>{{ x.DONo }}</td>
      <td>{{ x.DODoDate }}</td>

      <td>{{ x.EstimationAmount }}</td>
      <td>{{ x.InvoiceNo }}</td>
      <td>{{ x.InvoiceDate }}</td>
      <td>{{ x.InvoiceValue }}</td>
      <td>{{ x.DOIssuedinfavourof }}</td>
      <td>{{ x.Remark }}</td>
      <td>{{ x.ValuationDoneBy }}</td>
      <td>{{ x.ValuationDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.ValuationAmount }}</td>
      <td>{{ x.ValuationType }}</td>
      <td>{{ x.OtherValuationType }}</td>
      <td>{{ x.OtherValuationAmt }}</td>
      <td>{{ x.FitnessAuthority }}</td>
      <td>{{ x.FitnessUpto }}</td>
      <td>{{ x.PermitAuthority }}</td>
      <td>{{ x.PermitUpto | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.PermitType }}</td>
      <td>{{ x.ExtraInfo }}</td>
      <td>{{ x.RoadTaxUpto | date : "dd/MM/yyyy" }}</td>
      <td>{{ x.Rout }}</td>
      <td>{{ x.HPNEndorsment }}</td>
      <td>{{ x.DateofEndorsment }}</td>
    </tr>
  </tbody>
</table>
<table
  id="assets_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="AssetsModel.Category == 'Gold Loan' && DataSourceGoldLoan"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collateral Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Item Qty
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Done By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuer Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuer Firm Name
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Collateral_Type }}</td>
    <td>{{ x.TotalItem_Qty }}</td>
    <td>{{ x.TotalValuationAmount }}</td>
    <td>{{ x.TotalValuationAmount }}</td>
    <td>{{ x.ValuationDate }}</td>
    <td>{{ x.ValuerPhoneNo }}</td>
    <td>{{ x.ValuerFirmName }}</td>
  </tr>
</table>
