import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;

@Component({
  selector: 'app-mstcourier',
  templateUrl: './mstcourier.component.html',
  styleUrls: ['./mstcourier.component.scss']
})
export class MstcourierComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  dataSource: any; loading: boolean = false;
  displayedColumns: string[] = ['Courierid', 'CourierCompanyName', 'CourierComapnyAddress', 'CourierCompanyPhoneNo', 'EditAction'];
  RequestModel: RequestModel = new RequestModel();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  CourierList: any = [];

  Courierid: any = 0;
  CourierCompanyName: any = '';
  CourierComapnyAddress: any = '';
  CourierCompanyPhoneNo: any = '';

  TitleName: string = 'New';

  constructor(private dataSharingService: DataSharingService, public snackBar: MatSnackBar, private MasterService: MasterService) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Courier Company');
    this.getCourierList();
  }


  getCourierList() {
    this.showSpinner = true;
    this._MasterService.GetCourierMasterList().subscribe((result) => {
      this.CourierList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.CourierList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddNdwCourier() {
    $('#AddNewCourierModal').modal('show');
    $("#AddNewCourierModal").css("z-index", "1050");
  }

  OnClose() {
    $('#AddNewCourierModal').modal('hide');
  }

  onSaveCourier() {
    this.RequestModel.Courierid = this.Courierid;
    this.RequestModel.CourierCompanyName = this.CourierCompanyName;
    this.RequestModel.CourierComapnyAddress = this.CourierComapnyAddress;
    this.RequestModel.CourierCompanyPhoneNo = this.CourierCompanyPhoneNo;
    this._MasterService.LOS_SaveCourierMaster(this.RequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getCourierList();
        this.TitleName = 'New';
        this.Courierid = 0;
        this.CourierCompanyName = '';
        this.CourierComapnyAddress = '';
        this.CourierCompanyPhoneNo = '';
        this.OnClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getCourierList();
        this.Courierid = 0;
        this.CourierCompanyName = '';
        this.CourierComapnyAddress = '';
        this.CourierCompanyPhoneNo = '';
        this.TitleName = 'New';
        this.OnClose();
      }
    });
  }

  OnEditCourierData(selectedRow) {
    this.Courierid = selectedRow.Courierid;
    this.CourierCompanyName = selectedRow.CourierCompanyName;
    this.CourierComapnyAddress = selectedRow.CourierComapnyAddress;
    this.CourierCompanyPhoneNo = selectedRow.CourierCompanyPhoneNo;
    this.TitleName = 'Edit';
    this.onAddNdwCourier();
  }

}