import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-consumer-durable-category",
  templateUrl: "./consumer-durable-category.component.html",
  styleUrls: ["./consumer-durable-category.component.scss"],
})
export class ConsumerDurableCategoryComponent implements OnInit {
  public get dialog(): DialogService {
    return this._dialog;
  }
  public set dialog(value: DialogService) {
    this._dialog = value;
  }

  showSpinner: boolean = false;
  categoryName: any;
  ButtonName: string = "Add";
  SaveUpdateButton: string = "Save";

  dataSourceCategory: any;
  displayedColumn: string[] = ["Id", "Category_Name", "Action"];
  categoryId: any;
  Category_Name: any;
  currentUser: any;
  dataSource: MatTableDataSource<unknown>;

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private _dialog: DialogService,
    public snackBar: MatSnackBar
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Consumer Durable Category");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Master_Consumer_Durable_Category();
  }

  Get_Master_Consumer_Durable_Category() {
    this.showSpinner = true;
    this._MasterService
      .Get_Master_Consumer_Durable_Category()
      .subscribe((response) => {
        this.dataSourceCategory = new MatTableDataSource(
          JSON.parse(JSON.stringify(response))
        );
      });
  }

  applyFilter(filterValue: string) {
    this.dataSourceCategory.filter = filterValue.trim().toLowerCase();
  }

  OnAddDurableCategory() {
    this.categoryId = 0;
    this.ButtonName = "Add";
    this.SaveUpdateButton = "Save";
    this.categoryName = "";
    $("#editCategory").modal("show");
    $("#editCategory").css("z-index", "1050");
  }

  editCategoryName(row) {
    this.ButtonName = "Edit";
    this.SaveUpdateButton = "Update";

    this.categoryName = row.Category_Name;
    this.categoryId = row.Id;

    $("#editCategory").modal("show");
    $("#editCategory").css("z-index", "1050");
  }

  OnSaveDurableCategory() {
    this._MasterService
      .Save_Master_Consumer_Durable_Category({
        Id: this.categoryId,
        LoginUserId: this.currentUser.userId,
        Category_Name: this.categoryName,
        SearchType: this.SaveUpdateButton == "Save" ? "Insert" : "Update",
      })
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        if (res[0].CODE >= 0) {
          this.Get_Master_Consumer_Durable_Category();
          console.log("aaaaaa", res);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnClose() {
    this.categoryId = 0;
    this.categoryName = "";
    $("#editCategory").modal("hidden");
  }

  deleteCategoryName(row) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._MasterService
            .Save_Master_Consumer_Durable_Category({
              Id: row.Id,
              LoginUserId: this.currentUser.userId,
              Category_Name: row.categoryName,
              SearchType: "Delete",
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              console.log("delete", res);
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.Get_Master_Consumer_Durable_Category();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
}
