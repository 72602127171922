import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { PartnerService } from "../services/partner.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: 'app-partner-bulk-payment',
  templateUrl: './partner-bulk-payment.component.html',
  styleUrls: ['./partner-bulk-payment.component.scss']
})
export class PartnerBulkPaymentComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  DataSource: any;

  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();
  
  selectedId: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  OverDueAmtInput: any;
  AcCatgList: any = [];
  ACCategType: any = '';
  Loan_Acc_Type: any = 'Co-Lending';
  PaymentEMI: any = 'Partner';
  FileCheckingList: any[] = [];

  TotPayment: any;
  accountTagDropdown: any[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  loading: boolean = false;
  Finalvoucheretail: any[] = []
  AccountDetail: any[] = [];
  InstallmentId: any = 34;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[] = [];
 
  constructor( 
    private lmsService: LmsService,
    private partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    ) { 
    
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Bulk Payment List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.initializeVoucher();
    this.Get_Acc_Accounts_By_Tag();
    this.onChangeType();
  }

  onSearchBulkPaymentList() {
    this.initializeVoucherDetail();
    this.FileCheckingList = [];
    this.DataSource = null;
    this.PaymentEMI = "Partner";
    this.displayedColumns = [
      "PLoan_Id", "LMS_LoanAcNo", "PLoanAcNo", "CustomerName", "EMI_No", "DueDate","LMS_EMI_AMount", "EMI_AMount", "OverDue_EMI_AMount", "OverDueAmtInput"
    ] 
    let _finalData = {
      Search: { FromDate: this.FromDate, ToDate: this.ToDate, LoanAccountingCatId: this.ACCategType }
    }

    this.showSpinner = true;    
    this.partnerservice.Partner_Get_PaymentList({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      
      this.FileCheckingList = JSON.parse(JSON.stringify(res));
      //Insert value in new key (OverDueAmtInput) on index value
      this.FileCheckingList.forEach(x => {
        if (x.OverDue_EMI_AMount < 0) {
          x.OverDueAmtInput =0;
        } else {
          x.OverDueAmtInput = x.OverDue_EMI_AMount;
        }
      });

      this.voucherModel.Voucher_Detail = this.FileCheckingList;
      //Insert value in new key (OverDueAmtInput) on index value
      this.DataSource = new MatTableDataSource(this.FileCheckingList);
      this.DataSource.sort = this.sort;
      this.DataSource.paginator = this.paginator;
      this.showSpinner = false;
      this.onChangeAmount(this.FileCheckingList);
      this.getAccountDetail(this.InstallmentId);
    });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Bulk-Payment`,
      sheet: "Bulk-Payment",
    });
  }

  DateChange() {
    if (this.FromDate != '' && this.ToDate != '') {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "End Date should be greater than to Start Date.", ...this.configSuccess });
        this.FromDate = '';
        this.ToDate = '';
      }
    }
  }

  onChangeType() {
    this.displayedColumns = [];
    this.FileCheckingList = [];
    this.AccountDetail = [];
    this.DataSource = null;
    this.ACCategType = '';
    this.lmsService.Get_ACC_Category_ForDropdown_By_Type({ Type: this.Loan_Acc_Type }).subscribe((result) => {
      this.AcCatgList = JSON.parse(JSON.stringify(result));
    });
  }

  totaltypewise(type) {
    if(this.DataSource != undefined){
      if (this.DataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i][type]);
        }
        return (sum > 0) ? sum.toFixed(2) : '';      
      }
      return '';
    }
  }

  public highlightRow(row) {
    this.selectedId = row.PLoan_Id;
  }

  onChangeAmount(data: any) {
    this.TotPayment = '';
    if(this.DataSource != undefined){
      if (this.DataSource != null) {
        if (this.PaymentEMI == 'Partner') {
          let sum = 0;
          for (let i = 0; i < this.DataSource.filteredData.length; i++) {
            sum += Number(this.DataSource.filteredData[i]['OverDueAmtInput']);
          }
          this.TotPayment = sum.toFixed(2);
        }
        if (this.PaymentEMI == 'Customer') {
          let sum = 0;
          for (let i = 0; i < this.DataSource.filteredData.length; i++) {
            sum += Number(this.DataSource.filteredData[i]['LMS_EMI_AMount']);
          }
          this.TotPayment = sum.toFixed(2);
        }
      }
    }
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Payment',
      Voucher_Sub_Type: 'Partner',
      Voucher_Tag: 'Bank',
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      LoginUserId: this.currentUser.userId
    };
  }

  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: '',
      CustomerId: '',
      Is_LOSAppliaction: 0,
      ChargeHeadId: 34,
      PayAmount: "",
      AccountId: "",
      BranchId: 0,
      TranType: "DR",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
    });
  }

  GetVoucherNo() {
    this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: 'Loan', VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
    })
  }

  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }

  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }

  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    }
    else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }

  getAccountDetail(ChargeHeadId) {
    var data = { AccountId: 0, ChargeHeadCategory: "" };
    this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: ChargeHeadId, VoucherSubType: 'Partner', ProductId: this.ACCategType, TaxType: 'CR' })
      .subscribe((res: any) => {
        this.AccountDetail = [];
      console.log("res ", res);
      if (res.Item1[0]) {
        data.AccountId = res.Item1[0].AccountId;
        data.ChargeHeadCategory = res.Item1[0].AccountName;
        //data.ChargeHeadId = ChargeHeadId;
        this.AccountDetail.push({
          AccountId: data.AccountId,
          ChargeHeadCategory: data.ChargeHeadCategory,
          ChargeHeadId: ChargeHeadId
        });console.log("this.AccountDetail", this.AccountDetail);
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
      }
      console.log(this.AccountDetail);
    });

  }
  saveVoucher() {
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _findDR = this.AccountDetail.find((obj: any) => { return obj.ChargeHeadId == this.InstallmentId });
    let _findCR = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    this.Finalvoucheretail = [];
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.OverDueAmtInput > 0) {
        this.Finalvoucheretail.push(this.dataSharingService.cleanObject({
          VoucherId: obj.VoucherId,
          CaseNo: obj.PLoanAcNo,
          CustomerId: obj.CustomerId,
          Is_LOSAppliaction: obj.Is_LOSAppliaction,
          ChargeHeadId: this.InstallmentId,
          ChargeHeadCategory: _findDR.ChargeHeadCategory,
          AccountId: _findDR.AccountId,
          BranchId: obj.BranchId,
          TranType: 'DR',
          IsTax: obj.IsTax,
          Tax_Per: obj.Tax_Per,
          Amount: obj.OverDueAmtInput
        }));
      }
    });

    this.Finalvoucheretail.push(this.dataSharingService.cleanObject({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      Is_LOSAppliaction: 0,
      ChargeHeadId: "",
      ChargeHeadCategory: _findCR.Account_Name,
      AccountId: _findCR.AccountId,
      BranchId: 0,
      TranType: 'CR',
      IsTax: 0,
      Tax_Per: 0,
      Amount: this.TotPayment
    }));
    _vouvher.Voucher_Total_Credit_Amount = this.TotPayment;
    _vouvher.Voucher_Total_Debit_Amount = this.TotPayment;
    _vouvher.Voucher_Date = this.voucherModel.Voucher.VoucherDate;

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: this.Finalvoucheretail
    }

    console.log("FinalJson", _finalData);
    this.lmsService.Save_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.showSpinner = false;
        this.ACCategType = "";
        this.Loan_Acc_Type = "";
        this.FromDate= new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        this.ToDate = new Date();
        this.DataSource = null;
        this.displayedColumns = [];
        this.FileCheckingList = [];
        this.Finalvoucheretail = [];
        this.voucherModel = { Voucher: {}, Voucher_Detail: [] };
        this.initializeVoucher();
        //this.onSearchBulkPaymentList();
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  changeACCategType() {
    this.displayedColumns = [];
    this.FileCheckingList = [];
    this.AccountDetail = [];
    this.DataSource = null;
  }
  onChangePaymentEMI() {
    if (this.PaymentEMI == 'Partner') {
      this.FileCheckingList.forEach(x => {
        if (x.OverDue_EMI_AMount < 0) {
          x.OverDueAmtInput = 0;
        } else {
          x.OverDueAmtInput = x.OverDue_EMI_AMount;
        }
      });
    }
    if (this.PaymentEMI == 'Customer') {
      this.FileCheckingList.forEach(x => {
        
        x.OverDueAmtInput = x.LMS_EMI_AMount;
     
      });
    }
    this.voucherModel.Voucher_Detail = this.FileCheckingList;
    //Insert value in new key (OverDueAmtInput) on index value
    this.DataSource = new MatTableDataSource(this.FileCheckingList);
    this.DataSource.sort = this.sort;
    this.DataSource.paginator = this.paginator;
    this.showSpinner = false;
    this.onChangeAmount(this.FileCheckingList);
    this.getAccountDetail(this.InstallmentId);
  }
}
