import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { constantUrl } from "src/app/Shared/constantUrl";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { QuickLoanService } from "../services/quickLoan.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { LosService } from "src/app/_LOS/services/los.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;

@Component({
  selector: "app-quickloan-bank-analysis",
  templateUrl: "./quickloan-bank-analysis.component.html",
  styleUrls: ["./quickloan-bank-analysis.component.scss"],
})
export class QuickloanBankAnalysisComponent implements OnInit {
  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  DocName: any = "";
  showSpinner: boolean = false;
  currentUser: any;
  Row: any;
  today: Date = new Date();
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  bankNameFoirCart: any = "other";
  fileNoFoirCart: any;
  productTypeFoirCart: any = "salariedLoan";
  passwordFoirCart: any;
  fileNameAttachment: any;
  dataSourceFoirCart: any;
  CustomerId: any;
  DocData: any;
  BankAnalysisData: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  lastUploadedFileName = '';
  CustomerList: any;
  displayedColumns: any[] = [];
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort;
  dataUrl: any;
  docId: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private _Route: Router,
    private dialog: DialogService,
    private dataSharingService: DataSharingService,

    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Bank Analysis");
    console.log("loan Summary", this.loanSummary);
    this.getCustomerList();
    this.fileNoFoirCart = this.loanSummary.ApplicationNo;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getQuickLoan_GetBankStatementAnalysis(0, null, false);
  }

  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
      // this.Product_Category = result[0].Product_Category;
      // this.DealerId = result[0].DealerId;
    });
  }

  fileChangeListenerFOIR(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.DocData;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.DocName = _pre + "_" + files[0].name;
    this.lastUploadedFileName = this.DocName;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  clearFileAttachment() {}

  removeFileFoir(docFileInput: HTMLInputElement): void {
    this.DocName = "";

    docFileInput.value = "";
  }
  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {
      if (res == true) {
        var json = {
          password:
            this.passwordFoirCart == undefined ? "" : this.passwordFoirCart,
          bank: this.bankNameFoirCart == undefined ? "" : this.bankNameFoirCart,
          name: this.fileNoFoirCart == undefined ? "" : this.fileNoFoirCart,
          productType:
            this.productTypeFoirCart == undefined
              ? ""
              : this.productTypeFoirCart,
        };
        this._QuickLoanService
          .Save_BankStatementAnalysis({
            LoanId: Number(this.loanSummary.LoanId),
            CustomerId: Number(
              this.CustomerId == undefined ? 0 : this.CustomerId
            ),
            Request: JSON.stringify(json),
            DocName: this.DocName,
            LoginUserId: Number(this.currentUser.userId),
          })
          .subscribe((res: any) => {
            if (res.length > 0) {
              this.QuickLoan_Save_PageProcess("Bank Analysis");
              this.getQuickLoan_GetBankStatementAnalysis(0, null, false);
              this.passwordFoirCart = "";
              (this.CustomerId = ""), (this.DocName = "");
              this.bankNameFoirCart = "other";
              this.productTypeFoirCart = "salariedLoan";
            }
          });
      }
    });
  }
  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanSummary.ProductId,
        Loan_Id: this.loanSummary.LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }
  onSubmitFoirCart() {
    this.uploadDoc({
      ApplicationNo: this.loanSummary.ApplicationNo,
      DocName: this.DocName,
      DocData: this.DocData,
    });
  }
  applyFilter(filterValue: string) {
    this.dataSourceFoirCart.filter = filterValue.trim().toLowerCase();
  }

  getQuickLoan_GetBankStatementAnalysis(id: any, result: any, view: any) {
    this.displayedColumns = [
      "CustomerId",
      "Application_No",
      "Customer_Name",
      "Status",
      "EmployeeName",
      "DocName",
      "View",
      "Delete",
    ];

    this._QuickLoanService
      .QuickLoan_GetBankStatementAnalysis({
        LoanId: Number(this.loanSummary.LoanId),
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.BankAnalysisData = JSON.parse(JSON.stringify(res));
          if (id > 0) {
            var find = this.BankAnalysisData.find((x) => x.Id == id);
            if (find) {
              this.docId = find.DocId;
              this.blobToBase64Async(result, this.docId, view);
            }
          }

          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = null;
        }
      });
  }
  onSearchPendingStatus(row: any) {
    this._QuickLoanService
      .BankAnalysisUpload({
        LoanId: Number(this.loanSummary.LoanId),
        Request: row.Request,
        CustomerId: row.CustomerId,
        LoginUserId: this.currentUser.userId,
        ApplicationNo: row.Application_No,
        Id: row.Id,
        DocName: row.DocName,
        uploaddocUrl: this.docBaseUrl,
      })
      .subscribe((res: any) => {
        this.showSpinner = true;
        if (res.length > 0) {
          this.docId = res[0].MSG;
          this.showSpinner = false;
          this.Row = row;
          this.onDownloadWithoutView();
        }
      });
  }

  onDownloadWithoutView() {
    this.showSpinner = true;
    setTimeout(() => {
      this._QuickLoanService
        .DownloadAnalysisFile({
          DocId: this.docId,
        })
        .subscribe((res: any) => {
          if (res != null) {
            console.log("res", res);
            if (res.size == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Try Again!!!",
                ...this.configSuccess,
              });

              this.showSpinner = false;
            } else {
              console.log("res", res);
              this.getQuickLoan_GetBankStatementAnalysis(
                this.Row.Id,
                res,
                false
              );
              // $("#DownloadModel").modal("hide");
              this.Row = null;
              this.docId = 0;
            }
          }
        });
    }, 8000);
  }

  blobToBase64Async(blob: Blob, filename, view): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let $this = this;
      fileReader.onerror = (e) => reject(fileReader.error);

      fileReader.onload = function () {
        $this.dataUrl = fileReader.result.toString().split(";base64,").pop();
        $this.uploadData(filename + ".xlsx", view);
        return;
      };
      if (blob) {
        fileReader.readAsDataURL(blob);
      }
    });
  }
  uploadData(filename, view) {
    this.losService
      .UploadCrcDoc({
        DocName: filename,
        ApplicationNo: this.loanSummary.ApplicationNo,
        DocData: this.dataUrl,
      })
      .subscribe((res: any) => {
        if (view == true) {
          var url =
            this.docBaseUrl +
            this.loanSummary.ApplicationNo +
            this.Row.DocId +
            ".xlsx";
          window.open(url, "_blank");
        }
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Bank Statement Analysis Upload successfully!!!",
          ...this.configSuccess,
        });

        this.showSpinner = false;
      });
  }
  onDeleteBankStatement(row) {
    this._QuickLoanService
      .QuickLoan_DeleteBankStatementAnalysis({
        Id: Number(row.Id),
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.showSpinner = false;
          this.getQuickLoan_GetBankStatementAnalysis(0, null, false);
        }
      });
  }
}
