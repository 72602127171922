<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mb-3">
    <div class="col-md-3 p-1">
      <select name="FilterStatusId" id="FilterStatusId" class="form-control input-text-css"
              (change)="getWelcomeLetter()" [(ngModel)]="ProductId">
        <option value=""> Select Products </option>
        <option *ngFor="let product of productDropdown" [value]="product.ProductId">
          {{product.Product_Name}}
        </option>
      </select>
    </div>
  </div>
  <form #f="ngForm" (ngSubmit)="onSavetemplate()" novalidate *ngIf="ProductId">
    <div class="row m-0">
      <div class="col-md-10">
        <ckeditor [editor]="Editor" name="templateData" [config]="config" [(ngModel)]="templateData">
        </ckeditor>
      </div>
      <div class="col-md-2">
        <p class="lead fs-14 mb-2">[Application_No]</p>
        <p class="lead fs-14 mb-2">[Customer_Name]</p>
        <p class="lead fs-14 mb-2">[Customer_Relation]</p>
        <p class="lead fs-14 mb-2">[Customer_Address]</p>
        <p class="lead fs-14 mb-2">[Customer_PhoneNo]</p>
        <p class="lead fs-14 mb-2">[Loan_Product]</p>
        <p class="lead fs-14 mb-2">[Loan_Amount]</p>
        <p class="lead fs-14 mb-2">[Margin_Amount]</p>
        <p class="lead fs-14 mb-2">[Tenure_Month]</p>
        <p class="lead fs-14 mb-2">[No_Of_instalment]</p>
        <p class="lead fs-14 mb-2">[EMI_Amount]</p>
        <p class="lead fs-14 mb-2">[EMI_Advance]</p>
        <p class="lead fs-14 mb-2">[ROI]</p>
        <p class="lead fs-14 mb-2">[IRR]</p>
        <p class="lead fs-14 mb-2">[Date]</p>
        <p class="lead fs-14 mb-2">[Date_Of_EMI]</p>
        <p class="lead fs-14 mb-2">[Day_Of_EMI]</p>
        <p class="lead fs-14 mb-2">[EMI_Frequency]</p>

      </div>
    </div>
    <div class="text-right mb-2">
      <button type="button" (click)="onSavetemplate()" class="btn font-size-12 button-btn" [class.spinner]="loading" [disabled]="!f.form.valid">
        Save
      </button>
    </div>
  </form>
</div>
