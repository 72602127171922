import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-chargeshead",
  templateUrl: "./chargeshead.component.html",
  styleUrls: ["./chargeshead.component.scss"],
})
export class ChargesheadComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = ["ChagesHeadID", "ChagesHead", "EditAction"];
  RequestModel: RequestModel = new RequestModel();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  StateList: any = [];
  ChagesHeadID: any = 0;
  ChagesHead: any = "";
  ButtonName: string = "Add Charges Head";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Charges Head");
    this.getChargesHeadList();
  }

  getChargesHeadList() {
    this.showSpinner = true;
    this._MasterService.ChargesHeadsList().subscribe((result) => {
      this.StateList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.StateList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  onSaveChargesHead() {
    this.RequestModel.ChagesHeadID = this.ChagesHeadID;
    this.RequestModel.ChagesHead = this.ChagesHead;
    this._MasterService
      .SaveChargesHeadMaster(this.RequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getChargesHeadList();
          this.ButtonName = "Add Charges Head";
          this.ChagesHeadID = 0;
          this.ChagesHead = "";
        } else {
          this.ButtonName = "Add Charges Head";
          this.getChargesHeadList();
          this.ChagesHeadID = 0;
          this.ChagesHead = "";
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnEditChargesHead(selectedChargeheadId, selectedChargeheadName) {
    this.ChagesHeadID = selectedChargeheadId;
    this.ChagesHead = selectedChargeheadName;
    this.ButtonName = "Edit Charges Head";
  }
}
