<form #f="ngForm" (ngSubmit)="onSubmitData()" novalidate>
  <div class="table-responsive">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr class="table-green">
          <th colspan="5">Current Policy</th>
        </tr>
        <tr class="text-center">
          <th>#</th>
          <th>Current Policy</th>
          <th>Updated On</th>
          <th>Last Updated By</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>1</td>
          <td>
            <select
              name="Policy"
              id="Policy"
              [disabled]="inViewMode"
              [(ngModel)]="Policy"
              #refPolicy="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refPolicy.invalid,
                'alert-warning':
                  refPolicy.invalid &&
                  (refPolicy.dirty || refPolicy.touched || refPolicy.untouched)
              }"
              (change)="getcreditAssignmentpolicy(true)"
              class="form-control input-text-css"
              required
              style="padding: 0.175rem 0.35rem; height: auto"
            >
              <option value="Manual">Manual</option>
              <option value="Product">Product</option>
              <option value="Branch">Branch</option>
              <option value="Amount">Amount</option>
            </select>
          </td>
          <td>{{ UpdatedOn }}</td>
          <td>{{ LastUpdatedBy }}</td>
          <td>
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              style="cursor: pointer"
              (click)="OnEditCurrentPolicy()"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="Policy == 'Product'" class="table-responsive mt-2">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr class="table-green">
          <th colspan="5">Loan Product and Employee Mapping</th>
        </tr>
        <tr class="text-center">
          <th>#</th>
          <th>Loan Product</th>
          <th>Product Short Name</th>
          <th>Credit Recommendation Employee</th>
          <th>Credit Approval Employee</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let loanProductList of LoanProductList; let i = index"
          class="text-center"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ loanProductList.Product_Name }}</td>
          <td>{{ loanProductList.Product_ShortName }}</td>
          <td>
            <select
              name="Recomm_EmpId{{ i }}"
              id="Recomm_EmpId{{ i }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              [(ngModel)]="loanProductList.Recomm_EmpId"
              class="form-control input-text-css"
              required
              #refRecomm_EmpId="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refRecomm_EmpId.invalid,
                'alert-warning':
                  refRecomm_EmpId.invalid &&
                  (refRecomm_EmpId.dirty ||
                    refRecomm_EmpId.touched ||
                    refRecomm_EmpId.untouched)
              }"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of loanProductList.GetRecomm_EmpIdDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="Employee{{ i }}"
              id="Employee{{ i }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              [(ngModel)]="loanProductList.EmpId"
              class="form-control input-text-css"
              required
              #refEmployee="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refEmployee.invalid,
                'alert-warning':
                  refEmployee.invalid &&
                  (refEmployee.dirty ||
                    refEmployee.touched ||
                    refEmployee.untouched)
              }"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of loanProductList.GetEmployeeDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="Policy == 'Amount'" class="table-responsive mt-2">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr class="table-green">
          <th colspan="5">Employee and Amount Mapping</th>
        </tr>
        <tr class="text-center">
          <th>#</th>
          <th>Credit Recommendation Employee</th>
          <th>Credit Approval Employee</th>
          <th>From Amount</th>
          <th>To Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let empAndAmount of EmployeeAndAmount; let i = index"
          class="text-center"
        >
          <td>{{ i + 1 }}</td>

          <td>
            <select
              name="Recomm_EmpId{{ i }}"
              id="Recomm_EmpId{{ i }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              (change)="onChangeRecomm_EmpId($event, empAndAmount)"
              [(ngModel)]="empAndAmount.Recomm_EmpId"
              class="form-control input-text-css"
              required
              #refRecomm_EmpId="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refRecomm_EmpId.invalid,
                'alert-warning':
                  refRecomm_EmpId.invalid &&
                  (refRecomm_EmpId.dirty ||
                    refRecomm_EmpId.touched ||
                    refRecomm_EmpId.untouched)
              }"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of GetRecomm_EmpIdDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="Employee{{ i }}"
              id="Employee{{ i }}"
              [(ngModel)]="empAndAmount.EmpId"
              required
              #refEmployee="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refEmployee.invalid,
                'alert-warning':
                  refEmployee.invalid &&
                  (refEmployee.dirty ||
                    refEmployee.touched ||
                    refEmployee.untouched)
              }"
              style="padding: 0.175rem 0.35rem; height: auto"
              class="form-control input-text-css"
              (change)="onChange($event, empAndAmount)"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of GetEmployeeDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td class="text-right">{{ empAndAmount.FromAmount }}</td>
          <td>
            <input
              name="ToAmount{{ i }}"
              id="ToAmount{{ i }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              [(ngModel)]="empAndAmount.ToAmount"
              class="form-control input-text-css text-right"
            />
          </td>
          <td>
            <i
              *ngIf="EmployeeAndAmount.length == i + 1"
              class="fa fa-plus-circle font-size-12 mr-2"
              (click)="onAddNewAmount()"
              style="cursor: pointer; color: rgb(0, 128, 0)"
              aria-label="true"
            ></i>
            <i
              *ngIf="EmployeeAndAmount.length == i + 1 && i != 0"
              class="fa fa-trash style-delete"
              (click)="onDeleteAmount(i)"
              style="cursor: pointer"
              aria-label="true"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="Policy == 'Branch'" class="table-responsive mt-2">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr class="table-green">
          <th colspan="5">Loan Branch and Employee Mapping</th>
        </tr>
        <tr class="text-center">
          <th>#</th>
          <th>Loan Branch</th>
          <th>Branch Code</th>
          <th>Credit Recommendation Employee</th>
          <th>Credit Approval Employee</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let loanbranchlist of LoanBranchList; let k = index"
          class="text-center"
        >
          <td>{{ k + 1 }}</td>
          <td>{{ loanbranchlist.Branch_Name }}</td>
          <td>{{ loanbranchlist.Branch_Code }}</td>
          <td>
            <select
              name="Recomm_EmpId1{{ k }}"
              id="Recomm_EmpId1{{ k }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              [(ngModel)]="loanbranchlist.Recomm_EmpId"
              class="form-control input-text-css"
              required
              #refRecomm_EmpId="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refRecomm_EmpId.invalid,
                'alert-warning':
                  refRecomm_EmpId.invalid &&
                  (refRecomm_EmpId.dirty ||
                    refRecomm_EmpId.touched ||
                    refRecomm_EmpId.untouched)
              }"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of loanbranchlist.GetRecomm_EmpIdDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="Employee1{{ k }}"
              id="Employee1{{ k }}"
              style="padding: 0.175rem 0.35rem; height: auto"
              [(ngModel)]="loanbranchlist.EmpId"
              class="form-control input-text-css"
              required
              #refEmployee1="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refEmployee1.invalid,
                'alert-warning':
                  refEmployee1.invalid &&
                  (refEmployee1.dirty ||
                    refEmployee1.touched ||
                    refEmployee1.untouched)
              }"
            >
              <option value="">Select Employee</option>
              <option
                *ngFor="let emp of loanbranchlist.GetEmployeeDropdown"
                [value]="emp.EmpId"
              >
                {{ emp.EmpName }}
              </option>
            </select>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-3 text-right">
    <button
      type="submit"
      [disabled]="!f.form.valid"
      class="mt-3 btn font-size-12 button-btn"
    >
      Save
    </button>
  </div>
</form>
