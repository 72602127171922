<div>
  <form #f="ngForm" novalidate class="formborder">
    <div class="row col-md-12">
      <div class="col-md-5">
        <span class="required-lable"> Insurance Branch </span>
        <input
          type="text"
          name="Insurance_branch"
          id=""
          [(ngModel)]="Insurance_bran"
          class="form-control input-text-css"
          required
          #refInsurance_bran="ngModel"
          placeholder="Insurance Branch Name"
          [ngClass]="{
            'is-invalid': f.submitted && refInsurance_bran.invalid,
            'alert-warning':
              refInsurance_bran.invalid &&
              (refInsurance_bran.dirty ||
                refInsurance_bran.touched ||
                refInsurance_bran.untouched)
          }"
        />
      </div>

      <div class="col-md-5">
        <span class="required-lable"> Insurance Company</span>
        <select
          [(ngModel)]="Insurancecomp"
          name="SelectInsuranceCompany"
          class="form-control input-text-css"
          required
          #refInsurancecomp="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refInsurancecomp.invalid,
            'alert-warning':
              refInsurancecomp.invalid &&
              (refInsurancecomp.dirty ||
                refInsurancecomp.touched ||
                refInsurancecomp.untouched)
          }"
        >
          <option value="">Select Insurance Company</option>
          <ng-container *ngFor="let item of GetCompanyName">
            <ng-container *ngIf="item.Status == '1'">
              <option [value]="item.Int_Id">
                {{ item.Insurance_company }}
              </option>
            </ng-container>
          </ng-container>
        </select>
      </div>
      <div class="col-md-2">
        <div [ngClass]="'btns'">
          <button
            type="button"
            (click)="SaveInsuranceBranch(f)"
            *ngIf="saveBtn"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="UpdateLoanAcct(f)"
            *ngIf="updateBtn"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- <div class="row col-md-12 mt-1">
    <div class="col-md-12"> -->
  <div class="table-responsive mt-2">
    <mat-table [dataSource]="GetInsuranceBranch">
      <ng-container matColumnDef="Number">
        <mat-header-cell
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px"
          >{{ i + 1 }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="Insurance Branch">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Insurance Branch</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Insurance_Branch
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Insurance Company">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Insurance Company</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Insurance_Company
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Stauts</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <p *ngIf="row.Status == 1; else elsee" [ngStyle]="{ color: 'green' }">
            Active
          </p>
          <ng-template #elsee>
            <p [ngStyle]="{ color: 'red' }">Deactive</p>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 150px; max-height: 5px"
        >
          <i
            *ngIf="!inViewMode"
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            aria-label="true"
            (click)="editInsuranceBrnc(row)"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onDelete(row)"
            *ngIf="!inViewMode"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedCustomerColumns"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedCustomerColumns"
      ></mat-row>
    </mat-table>
  </div>
  <!-- </div>
  </div> -->
</div>
<div
  class="modal fade in"
  id="insuranceBranch"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Edit Insurance Branch
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body row m-0 align-items-center formborder m-3">
        <form #ff="ngForm" novalidate>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6 p-1">
              <span class="required-lable"> Insurance Branch </span>
            </div>
            <div class="col-md-6 p-1">
              <input
                type="text"
                name="Insurance_branch"
                id=""
                [(ngModel)]="update_Insurance_bran"
                class="form-control input-text-css"
                required
                #refupdate_Insurance_bran="ngModel"
                placeholder="Insurance Branch Name"
                [ngClass]="{
                  'is-invalid':
                    ff.submitted && refupdate_Insurance_bran.invalid,
                  'alert-warning':
                    refupdate_Insurance_bran.invalid &&
                    (refupdate_Insurance_bran.dirty ||
                      refupdate_Insurance_bran.touched ||
                      refupdate_Insurance_bran.untouched)
                }"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6 p-1">
              <span class="required-lable"> Insurance Company</span>
            </div>
            <div class="col-md-6 p-1">
              <select
                [(ngModel)]="update_Insurancecomp"
                name="SelectInsuranceCompany"
                class="form-control input-text-css"
                required
                #refupdate_Insurancecomp="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refupdate_Insurancecomp.invalid,
                  'alert-warning':
                    refupdate_Insurancecomp.invalid &&
                    (refupdate_Insurancecomp.dirty ||
                      refupdate_Insurancecomp.touched ||
                      refupdate_Insurancecomp.untouched)
                }"
              >
                <option value="">Select Insurance Company</option>
                <ng-container *ngFor="let item of GetCompanyName">
                  <ng-container *ngIf="item.Status == '1'">
                    <option [value]="item.Int_Id">
                      {{ item.Insurance_company }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="border: none">
        <button
          type="button"
          (click)="OnUpdate()"
          [disabled]="!ff.form.valid"
          class="mt-3 btn font-size-12 button-btn"
          data-dismiss="modal"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
