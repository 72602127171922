import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-questions-tvr",
  templateUrl: "./questions-tvr.component.html",
  styleUrls: ["./questions-tvr.component.scss"],
})
export class QuestionsTVRComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  FilterProductId: any = "";
  RequestModel: RequestModel = new RequestModel();
  SaveTVRQuestionsRequestModel: RequestModel = new RequestModel();
  EditTVRQuestionsRequestModel: RequestModel = new RequestModel();
  DeleteTVRQuestionsRequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = [
    "QueId",
    "Question",
    "Question_Hindi",
    "QuestionType",
    "Question_For",
    "CreateOn",
    "LoanProduct",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  TVRQuestionsList: any = [];
  FilterProductList: any = [];
  QuestionTypeOptions: any = [];
  //add
  TVRQueId: number = 0;
  HeaderText: string = "Add";
  TVRQuestion: any = "";
  TVRQuestionType: any = "";
  TVRQuestionOptions: any = "";
  TVRQuestionProducts: any = "";
  TVRQuestionHindi: any = "";
  dialogreturn: any;
  TVRQuestionFor: any = "";
  Higher: boolean = false;
  CoBrowser: boolean = false;
  Guarantor: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("TVR Questions");
    this._MasterService.GetProductForDropdown().subscribe((result) => {
      this.FilterProductList = JSON.parse(JSON.stringify(result));
      this.FilterProductList.map((item) => {
        item.isAcive = false;
      });
    });
    this.getTVRQuestionsList();
  }

  getTVRQuestionsList() {
    this.showSpinner = true;
    this.RequestModel.ProductId =
      this.FilterProductId == "" ? 0 : parseInt(this.FilterProductId);
    this._MasterService
      .LOSGetTVRQuestionsList(this.RequestModel)
      .subscribe((result) => {
        this.TVRQuestionsList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.TVRQuestionsList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  onChangeTVRQuestionType() {
    this.QuestionTypeOptions = [{ TVR_Questions_Option: "" }];
  }

  onAddNewRow() {
    this.QuestionTypeOptions.push({ TVR_Questions_Option: "" });
  }

  onDeleteRow(selectedIndex) {
    this.QuestionTypeOptions.splice(selectedIndex, 1);
  }

  onDeleteTVRQuestion(row) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this.DeleteTVRQuestionsRequestModel.TVRQueId = row.QueId;
        this.DeleteTVRQuestionsRequestModel.LoginUserId = this.data.userId;
        this._MasterService
          .LOSDeleteTVRQuestion(this.DeleteTVRQuestionsRequestModel)
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getTVRQuestionsList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  onOpenTVRQuestionModal() {
    $("#addNewTVRQuestion").modal("show");
    $("#addNewTVRQuestion").css("z-index", "1050");
  }

  OnClose() {
    this.TVRQueId = 0;
    this.TVRQuestion = "";
    this.TVRQuestionType = "";
    this.TVRQuestionOptions = "";
    this.TVRQuestionHindi = "";
    this.TVRQuestionProducts = "";
    this.TVRQuestionFor = "";
    this.Higher = false;
    this.CoBrowser = false;
    this.Guarantor = false;
    this.QuestionTypeOptions = [];
    this.FilterProductList.map((item) => {
      item.isAcive = false;
    });
    this.HeaderText = "Add";
    $("#addNewTVRQuestion").modal("hide");
  }

  onEditTVRQuestion(row) {
    this.HeaderText = "Edit";
    this.EditTVRQuestionsRequestModel.TVRQueId = row.QueId;
    this._MasterService
      .LOSGetTVRQuestionDetails(this.EditTVRQuestionsRequestModel)
      .subscribe((response) => {
        this.TVRQueId = response.Item1[0].TVR_QueId;
        this.TVRQuestion = response.Item1[0].TVR_Question;
        this.TVRQuestionHindi = response.Item1[0].TVR_Question_Hindi;
        this.TVRQuestionType = response.Item1[0].TVR_QuestionType;
        this.TVRQuestionFor = response.Item1[0].TVR_QuestionFor;
        console.log(this.TVRQuestionFor);
        if (this.TVRQuestionFor.includes("C")) {
          this.CoBrowser = true;
        }
        if (this.TVRQuestionFor.includes("G")) {
          this.Guarantor = true;
        }
        if (this.TVRQuestionFor.includes("H")) {
          this.Higher = true;
        }
        this.FilterProductList.map((item) => {
          response.Item2.map((item1) => {
            if (item.ProductId == item1.ProductId) {
              item.isAcive = true;
            }
          });
        });

        response.Item3.map((item) => {
          this.QuestionTypeOptions.push({
            TVR_Questions_Option: item.TVR_Questions_Option,
          });
        });

        $("#addNewTVRQuestion").modal("show");
        $("#addNewTVRQuestion").css("z-index", "1050");
      });
  }
  onChange(event: any) {
    console.log(event.source.name); // will contain "hello"
    //console.log(event.source.checked); // will contain the checked state of the checkbox
  }
  onSaveTVRQuestionData() {
    this.TVRQuestionOptions = Array.prototype.map
      .call(this.QuestionTypeOptions, function (item) {
        return item.TVR_Questions_Option;
      })
      .join("@@");

    let FilterProductData = this.FilterProductList.filter(
      (a) => a.isAcive == true
    );
    this.TVRQuestionProducts = Array.prototype.map
      .call(FilterProductData, function (item) {
        return item.ProductId;
      })
      .join(",");

    this.SaveTVRQuestionsRequestModel.TVRQueId = this.TVRQueId;
    this.SaveTVRQuestionsRequestModel.TVRQuestion = this.TVRQuestion;
    this.SaveTVRQuestionsRequestModel.TVRQuestionType = this.TVRQuestionType;
    this.SaveTVRQuestionsRequestModel.TVRQuestionOptions =
      this.TVRQuestionOptions;
    this.SaveTVRQuestionsRequestModel.TVRQuestion_Hindi = this.TVRQuestionHindi;
    this.SaveTVRQuestionsRequestModel.TVRQuestionProducts =
      this.TVRQuestionProducts;
    this.SaveTVRQuestionsRequestModel.LoginUserId = this.data.userId;
    this.TVRQuestionFor = "";
    if (this.Higher == true) {
      this.TVRQuestionFor = "H,";
    }
    if (this.CoBrowser == true) {
      this.TVRQuestionFor = this.TVRQuestionFor + "C,";
    }
    if (this.Guarantor == true) {
      this.TVRQuestionFor = this.TVRQuestionFor + "G";
    }
    this.SaveTVRQuestionsRequestModel.TVRQuestionFor =
      this.TVRQuestionFor.trim();
    this._MasterService
      .SaveTVRQuestions(this.SaveTVRQuestionsRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getTVRQuestionsList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
