<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keyup.enter)="onSearchENachResponse()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="PostDateFrom"
          [(ngModel)]="PostDateFrom"
          id="PostDateFrom"
          class="form-control input-text-css"
          required
          #refPostDateFrome="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refPostDateFrome.invalid,
            'alert-warning':
              refPostDateFrome.invalid &&
              (refPostDateFrome.dirty ||
                refPostDateFrome.touched ||
                refPostDateFrome.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="PostDateFrom"
        [(ngModel)]="PostDateFrom"
        id="PostDateFrom"
        placeholder="From Date"
        bsDatepicker
        class="form-control input-text-css"
        required
        #refPostDateFrome="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refPostDateFrome.invalid,
          'alert-warning':
            refPostDateFrome.invalid &&
            (refPostDateFrome.dirty ||
              refPostDateFrome.touched ||
              refPostDateFrome.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-3">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="PostDateTo"
          [(ngModel)]="PostDateTo"
          id="PostDateTo"
          class="form-control input-text-css"
          required
          #refPostDateToe="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refPostDateToe.invalid,
            'alert-warning':
              refPostDateToe.invalid &&
              (refPostDateToe.dirty ||
                refPostDateToe.touched ||
                refPostDateToe.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="PostDateTo"
        [(ngModel)]="PostDateTo"
        id="PostDateTo"
        placeholder="To Date"
        bsDatepicker
        class="form-control input-text-css"
        required
        #refPostDateToe="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refPostDateToe.invalid,
          'alert-warning':
            refPostDateToe.invalid &&
            (refPostDateToe.dirty ||
              refPostDateToe.touched ||
              refPostDateToe.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-3">
      <span class="required-lable">Loan No</span>
      <input
        name="LoanAcNo"
        [(ngModel)]="LoanAcNo"
        id="LoanAcNo"
        placeholder="Loan No"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <button
        type="button"
        (click)="onSearchENachResponse()"
        class="font-size-12 button-btn mt-3"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
  </div>
</form>


<form
  #f="ngForm"
  (ngSubmit)="TransactionVerification()"
  novalidate
  [hidden]="!dataSource"
>
  <div class="row m-0 p-0">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [hiddenColumns]="[displayedColumns.indexOf('Assign')]"
        >
          <!-- style="height: 360px; max-width: 100%; overflow: auto;" -->
          <ng-container matColumnDef="Loan_Id" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total: </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Identifier">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Identifier</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Identifier }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="AccountName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >AccountName</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AccountName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DueDate | date : "dd/MM/yyyy" }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMI_Amount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerBankname">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Bank</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerBankname }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>

        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              type="button"
              [disabled]="!f.form.valid"
              (click)="TransactionVerification()"
              [class.spinner]="loading"
              mat-raised-button
              class="font-size-12 button-btn"
            >
              Update Status
            </button>
          </div>
          <div class="col-md-8 text-right">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              class="mat-paginator-sticky"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
