<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="OnSearchReport_Exposure()"
  class="formborder "
>
  <div class="m-0 col-md-12 p-0 d-flex align-items-center">
    <div class="col-md-2">
      <span class="required-lable">As On Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateFrom"
          id="DateFrom"
          required
          #refDateFrom="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="DateFrom"
          [ngClass]="{
            'is-invalid': df.submitted && refDateFrom.invalid,
            'alert-warning':
              refDateFrom.invalid &&
              (refDateFrom.dirty || refDateFrom.touched || refDateFrom.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="DateFrom"
        [(ngModel)]="DateFrom"
        id="DateFrom"
        required
        #refDateFrom="ngModel"
        placeholder="Date"
        class="form-control input-text-css"
        bsDatepicker
        [ngClass]="{
          'is-invalid': df.submitted && refDateFrom.invalid,
          'alert-warning':
            refDateFrom.invalid &&
            (refDateFrom.dirty || refDateFrom.touched || refDateFrom.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-2">
      <span class="required-lable"> Accounting Category </span>
      <select
        name="Loan_AccountingCategoryId"
        id="Loan_AccountingCategoryId"
        [(ngModel)]="LoanAccountCategory"
        class="form-control input-text-css"
      >
        <option value="0">All</option>
        <option
          *ngFor="let AccountingCat of AccountingCatDropdown"
          [value]="AccountingCat.Loan_CategoryId"
        >
          {{ AccountingCat.Loan_category }}
        </option>
      </select>
    </div>
    <div class="col-md-2 mt-2">
      <button
        type="button"
        (click)="OnSearchReport_Exposure()"
        [disabled]="!df.form.valid"
       
        class="mt-2 btn font-size-12 button-btn"
       
      >
        Search
      </button>
    </div>

    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive" style="overflow: auto;height:65vh;">
      <table mat-table
             class="table-bordered"
             matSort
             [dataSource]="dataSource"
             matTableExporter
             #exporter="matTableExporter"
             id="DueList">
        <ng-container matColumnDef="Loan_Id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan No</th>
          <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Loan_Date  }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiry Date</th>
          <td mat-cell *matCellDef="let row">{{ row.ExpiryDate }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <ng-container matColumnDef="Gender">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Gender
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Gender }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="DisbursmentAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Disbursment Amount
          </th>
          <td mat-cell *matCellDef="let row">{{ row.DisbursmentAmount }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <ng-container matColumnDef="Branch_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Product_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">{{ row.Product_name }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Acc_Category">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Loan A/c Category
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Acc_Category }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Application_Case_IRR">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>IRR</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Application_Case_IRR.toFixed(2) }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Application_LoanTenure">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Tenure</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Application_LoanTenure }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Application_LTV">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>LTV</th>
          <td mat-cell *matCellDef="let row">{{ row.Application_LTV }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="EMIAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>EMI Amount</th>
          <td mat-cell *matCellDef="let row">{{ row.EMIAmount }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="FirstEMIDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            First EMI Date
          </th>
          <td mat-cell *matCellDef="let row">{{ row.FirstEMIDate }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="DPD_Days">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD Days</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.DPD_Days }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">Total</th>
        </ng-container>
        <ng-container matColumnDef="Total_Principal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Total_Principal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Total_Principal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Total_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Total_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Total_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Total_AgreedValue">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Total_AgreedValue }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Total_AgreedValue") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Balance_Due_Principal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Balance_Due_Principal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Balance_Due_Principal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Balance_Due_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Balance_Due_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Balance_Due_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Balance_Due_Installment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Balance_Due_Installment }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Balance_Due_Installment") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Received_Principal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Received_Principal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Received_Principal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Received_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Received_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Received_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Received_Installment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Received_Installment }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Received_Installment") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="CurrentDue_POS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.CurrentDue_POS }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("CurrentDue_POS") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="CurrentDue_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.CurrentDue_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("CurrentDue_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="CurrentDue_INST">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.CurrentDue_INST }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("CurrentDue_INST") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="FutureDue_POS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.FutureDue_POS }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("FutureDue_POS") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="FutureDue_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.FutureDue_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("FutureDue_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="FutureDue_INST">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.FutureDue_INST }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("FutureDue_INST") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="TotalBalance_POS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.TotalBalance_POS }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("TotalBalance_POS") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="TotalBalance_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.TotalBalance_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("TotalBalance_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="TotalBalance_INST">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.TotalBalance_INST }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("TotalBalance_INST") }}
          </th>
        </ng-container>

        <!-- Top header column start here -->
        <ng-container matColumnDef="row-first">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="16"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Particulars
          </th>
        </ng-container>
        <ng-container matColumnDef="Diff_Allocation">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Diff Allocation
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Diff_Allocation }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Diff_Allocation") }}
          </th>
        </ng-container>
        <!-- Top header column start here -->

        <ng-container matColumnDef="row-second">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Agreement
          </th>
        </ng-container>
        <ng-container matColumnDef="row-third">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Due UpTo Date
          </th>
        </ng-container>
        <ng-container matColumnDef="row-fourth">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Received UpTo Date
          </th>
        </ng-container>
        <ng-container matColumnDef="row-fifth">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Balance Current Due
          </th>
        </ng-container>
        <ng-container matColumnDef="row-sixth">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Balance Future Due
          </th>
        </ng-container>
        <ng-container matColumnDef="row-seventh">
          <th mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="3"
              style="
              border-right: 1px #f7f9f9 solid;
              border-bottom: 0.5px;
              text-align: center;
            ">
            Total Due
          </th>
        </ng-container>

        <!-- Top header column end here -->
        <!-- Top header row start here -->
        <tr mat-header-row
            *matHeaderRowDef="
            [
              'row-first',
              'row-second',
              'row-third',
              'row-fourth',
              'row-fifth',
              'row-sixth',
              'row-seventh'
            ];
            sticky: true
          "
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"></tr>
        <!-- Top header row end here -->

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"></tr>
        <tr mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"></tr>
      </table>
    </div>
  </div>
</div>
<div class="row m-0 ml-3"  [hidden]="!dataSource">
  <div class="col-md-4 mt-3">
    <button class="mt-2 btn font-size-12 button-btn" (click)="exportexcelNew()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-3">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="exposure_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th
      colspan="16"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Particulars
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Agreement
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Due UpTo Date
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Received UpTo Date
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Balance Current Due
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Balance Future Due
    </th>
    <th
      colspan="3"
      style="background: #4dc3a3 !important; border: 1px solid black"
    >
      Total Due
    </th>
  </tr>

  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gender
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan ACC Category
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">IRR</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">LTV</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      First EMI Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursment Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td style="text-align: right">{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date  }}</td>
    <td style="text-align: right">{{ x.ExpiryDate }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Gender }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_name }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.Application_Case_IRR }}</td>
    <td>{{ x.Application_LoanTenure }}</td>
    <td>{{ x.Application_LTV }}</td>
    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.FirstEMIDate }}</td>
    <td>{{ x.DisbursmentAmount }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.Total_Principal }}</td>
    <td>{{ x.Total_Interest }}</td>
    <td>{{ x.Total_AgreedValue }}</td>
    <td>{{ x.Balance_Due_Principal }}</td>
    <td>{{ x.Balance_Due_Interest }}</td>
    <td>{{ x.Balance_Due_Installment }}</td>
    <td>{{ x.Received_Principal }}</td>
    <td>{{ x.Received_Interest }}</td>
    <td>{{ x.Received_Installment }}</td>
    <td>{{ x.CurrentDue_POS }}</td>
    <td>{{ x.CurrentDue_Interest }}</td>
    <td>{{ x.CurrentDue_INST }}</td>
    <td>{{ x.FutureDue_POS }}</td>
    <td>{{ x.FutureDue_Interest }}</td>
    <td>{{ x.FutureDue_INST }}</td>
    <td>{{ x.TotalBalance_POS }}</td>
    <td>{{ x.TotalBalance_Interest }}</td>
    <td>{{ x.TotalBalance_INST }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="16"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Principal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_AgreedValue") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance_Due_Principal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance_Due_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance_Due_Installment") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Received_Principal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Received_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Received_Installment") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("CurrentDue_POS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("CurrentDue_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("CurrentDue_INST") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureDue_POS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureDue_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureDue_INST") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("TotalBalance_POS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("TotalBalance_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("TotalBalance_INST") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
