<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="onSearchPartnerBookingList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            [(ngModel)]="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            [(ngModel)]="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>

        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <!--<div class="col-md-3">
        <span class="required-lable"> Select Type </span>
        <select
          name="Loan_Acc_Type"
          id="Loan_Acc_Type"
          [(ngModel)]="Loan_Acc_Type"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refLoan_Acc_Type="ngModel"
          required disabled
          [ngClass]="{
            'is-invalid': df.submitted && refLoan_Acc_Type.invalid,
            'alert-warning':
              refLoan_Acc_Type.invalid &&
              (refLoan_Acc_Type.dirty ||
                refLoan_Acc_Type.touched ||
                refLoan_Acc_Type.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-3">
        <span class="required-lable"> Partner</span>
        <select
          name="ACCategType"
          id="ACCategType"
          [(ngModel)]="ACCategType"
          class="form-control input-text-css"
          #refACCategType="ngModel"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refACCategType.invalid,
            'alert-warning':
              refACCategType.invalid &&
              (refACCategType.dirty ||
                refACCategType.touched ||
                refACCategType.untouched)
          }"
        >
          <option value="">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-1 justify-content-end">
        <button
          type="button"
          (click)="onSearchPartnerBookingList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" [hidden]="!DataSource">
    <mat-table
      [dataSource]="DataSource"
      matSort
      matTableExporter
      #exporterLoanInfo="matTableExporter"
      style="height: 370px; max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px; border-right: 1px #f7f9f9 solid"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Partner</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_LoanAcNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Co">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Co
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Co }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Co_name">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Co Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Co_name }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="Partner_LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_LoanAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_LoanAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_NoOfEMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >No Of EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_NoOfEMI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_EMIAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_EMIAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_EMIAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_InterestAmt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_InterestAmt }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_InterestAmt") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_AgreementAmt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Agreed Value</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_AgreementAmt }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_AgreementAmt") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_IRR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >IRR</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_IRR }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_ROI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >ROI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_ROI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_LoanDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_LoanDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_DueDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Due Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_DueDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_ExpiryDate">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Expiry Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_ExpiryDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Amt</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_LoanAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_LoanAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_NoOfEMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >No Of EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_NoOfEMI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_EMIAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Amt</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_EMIAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_EMIAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_InterestAmt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Interest Amt</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_InterestAmt }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_InterestAmt") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_AgreementAmt">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Agreed Value</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_AgreementAmt }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_AgreementAmt") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_IRR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >IRR</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_IRR }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_ROI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >ROI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_ROI }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_LoanDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_LoanDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_DueDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Due Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_DueDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_ExpiryDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Expiry Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_ExpiryDate }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <!-- Top header column start here -->
      <ng-container matColumnDef="row-first">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="1"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>

      <ng-container matColumnDef="row-second">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="2"
          style="border-right: 1px #f7f9f9 solid"
        >
          Loan A/C No.
        </th>
      </ng-container>

      <ng-container matColumnDef="row-third">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>

      <ng-container matColumnDef="row-fourth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="10"
          style="border-right: 1px #f7f9f9 solid"
        >
          Partner
        </th>
      </ng-container>

      <ng-container matColumnDef="row-fifth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="10"
          style="border-right: 1px #f7f9f9 solid"
        >
          Customer
        </th>
      </ng-container>
      <!-- Top header column end here -->

      <!-- Top header row start here -->
      <tr
        mat-header-row
        *matHeaderRowDef="
          ['row-first', 'row-second', 'row-third', 'row-fourth', 'row-fifth'];
          sticky: true
        "
      ></tr>
      <!-- Top header row end here -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        (click)="highlightRow(row)"
        [class.highlight]="row.PLoan_Id == selectedId"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporterLoanInfo)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorlist
          [pageSize]="20"
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
