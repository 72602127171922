<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div style="height: 335px; max-width: 100%; overflow-y: auto; overflow-x: hidden;">
  <form #f="ngForm" (ngSubmit)="onSaveCustomerFileChecking()" novalidate class="formborder">
    <div class="row m-0 mt-2 h-b">
      <div class="col-md-1 pl-1"><b>#</b></div>
      <div class="col-md-6 pl-1"><b>Checking Points</b></div>
      <div class="col-md-2 pl-1"><b>Select</b></div>
      <div class="col-md-3 pl-1"><b>Remark</b></div>
    </div>
    <div class="row m-0 mt-2" *ngFor="let question of questionData; let i = index">
      <div class="col-md-1">{{ i + 1 }}</div>
      <div class="col-md-6">{{ question.FileCheckingName }}</div>
      <div class="col-md-2 pl-1">
        <span> </span>
        <select name="QS{{ i }}" id="QS{{ i }}" #refQS="ngModel"
                class="form-control input-text-css" required [(ngModel)]="question.SelectOption" [disabled]="IsDisabled"
                [ngClass]="{'is-invalid': f.submitted && refQS.invalid, 'alert-warning': refQS.invalid && (refQS.dirty || refQS.touched || refQS.untouched)}">
          <option value="" [selected]="question.SelectOption === ''">Select</option>
          <ng-container *ngFor="let option of question.FileCheckingDropdown">
            <option [value]="option">{{ option }}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-3 pl-1">
        <span></span>
        <textarea 
                  rows="1"
                  #refQT="ngModel"
                  name="QTRemark{{ i }}"
                  id="QTRemark{{ i }}"
                  [disabled]="IsDisabled"
                  class="form-control input-text-css"
                  [(ngModel)]="question.Remark"
                  placeholder="Remark"></textarea>

      </div>
    </div>


    <div class="row m-0">
    
      <div class="text-right" [ngClass]="'col-md-12'">
        <button type="button" (click)="onEditFileChecking()" *ngIf="IsEdit && PageData.currentTab == 1 && Status != 'C' && (Status == 'R'?Revert_IsEdit==1 ?true:false :true)"
                class="btn  font-size-12 button-btn mt-4">
          Edit
        </button>
        <button type="button" (click)="onSaveCustomerFileChecking()" *ngIf="IsSave" class="btn  font-size-12 button-btn mt-4"
                [class.spinner]="loading" [disabled]="!f.form.valid">
          Save
        </button>
        <button type="button" (click)="onSaveCustomerFileChecking()" *ngIf="IsUpdate" class="btn  font-size-12 button-btn mt-4"
                [class.spinner]="loading" [disabled]="!f.form.valid">
          Update
        </button>
      </div>
    </div>
  </form>
    </div>

