<form #f="ngForm" novalidate>
  <!-- <div class="row mt-4">
      <div class="col-md-12">
        <h4> CRC Type Master </h4>
      </div>
    </div> -->
  <div class="row m-0 col-md-12 p-0 formborder align-items-center">
    <div class="col-md-3">
      <span> CRC Loan Category </span>
      <input
        type="text"
        name="CrcType"
        id="CrcType"
        [(ngModel)]="CrcType"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-2">
      <span> CRC Agency </span>
      <select
        name="CRCAgency"
        id="CRCAgency"
        [(ngModel)]="CRCAgency"
        class="form-control input-text-css"
        required
      >
        <option *ngFor="let Type of CRC_Agency" [value]="Type.Id">
          {{ Type.Value }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span> CRC Code</span>
      <input
        type="text"
        name="crcCode"
        id="crcCode"
        [(ngModel)]="crcCode"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="onSaveCrc()"
        class="mt-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!f.form.valid"
      >
        Save
      </button>
      <!-- <button type="button" (click)="UpdateCrc()" *ngIf="updateBtn"
        style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
        [class.spinner]="loading" [disabled]="!f.form.valid">
        Update
      </button> -->
    </div>
    <div class="col-md-3">
      <!-- <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search"/>
      </mat-form-field> -->
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="row mt-4">
  <div class="col-md-12">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataCustomerSource"
        matSort
        style="overflow: auto; height: 60vh; max-width: 100%"
      >
        <ng-container matColumnDef="Number">
          <mat-header-cell
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC Type">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 800px"
          >
            CBC Type
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 800px"
          >
            {{ row.Crc_Type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC Agent">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 300px"
          >
            CBC Agency
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 300px"
          >
            {{ row.Crc_Agency }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Crc_Code">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 300px"
          >
            CBC Code
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 300px"
          >
            {{ row.Crc_Code }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            class="grid-header j-c-center"
            *matHeaderCellDef
            style="max-width: 150px"
          >
            Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 150px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              aria-label="true"
              (click)="editCrc(row)"
            ></i>
            <i
              class="fa fa-trash style-delete"
              (click)="removeCrcModel(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedCustomerColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedCustomerColumns"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="crcType"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Edit CRC
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body row m-0 align-items-center formborder m-3">
        <form #crc="ngForm" novalidate>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6 p-1">
              <span class="required-lable"> CRC Loan Category </span>
            </div>
            <div class="col-md-6 p-1">
              <input
                type="text"
                name="CRC_Loan"
                id="CRC_Loan"
                [(ngModel)]="Upadte_cRC_Loan"
                class="form-control input-text-css"
                required
                #refCRC_Loan="ngModel"
                placeholder="CRC Loan Category"
                [ngClass]="{
                  'is-invalid': crc.submitted && refCRC_Loan.invalid,
                  'alert-warning':
                    refCRC_Loan.invalid &&
                    (refCRC_Loan.dirty ||
                      refCRC_Loan.touched ||
                      refCRC_Loan.untouched)
                }"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6 p-1">
              <span class="required-lable"> CRC Agency</span>
            </div>
            <div class="col-md-6 p-1">
              <select
                name="SelectCRCAgency"
                id="update_crcAgency"
                [(ngModel)]="update_crcAgency"
                class="form-control input-text-css"
                required
              >
                <option *ngFor="let Type of CRC_Agency" [value]="Type.Id">
                  {{ Type.Value }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6 p-1">
              <span class="required-lable"> CRC Code </span>
            </div>
            <div class="col-md-6 p-1">
              <input
                type="text"
                name="CRC_Code"
                id="CRC_Code"
                [(ngModel)]="Upadte_cRC_Code"
                class="form-control input-text-css"
                required
                #refUpadte_cRC_Code="ngModel"
                placeholder="CRC Code"
                [ngClass]="{
                  'is-invalid': crc.submitted && refUpadte_cRC_Code.invalid,
                  'alert-warning':
                    refUpadte_cRC_Code.invalid &&
                    (refUpadte_cRC_Code.dirty ||
                      refUpadte_cRC_Code.touched ||
                      refUpadte_cRC_Code.untouched)
                }"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="border: none">
        <button
          type="button"
          (click)="OnUpdate()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!crc.form.valid"
          data-dismiss="modal"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
