import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mask",
})
export class NumberMaskPipe implements PipeTransform {
  transform(number: string): string {
    if (!number) {
      return "";
    }

    const visibleLastDigits = 3;
    const visibleFirstDigits = 2;
    let maskedSection = number.slice(visibleFirstDigits, -visibleLastDigits);
    let visibleSection = number.slice(-visibleLastDigits);
    let visibleFirstSection = number.slice(0, visibleFirstDigits);
    return (
      visibleFirstSection + maskedSection.replace(/./g, "X") + visibleSection
    );
  }
}
