import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { ReportsService } from "../../_Reports/services/reports.service";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { constantUrl } from "src/app/Shared/constantUrl";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DatePipe } from "@angular/common";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-cash-book",
  templateUrl: "./cash-book.component.html",
  styleUrls: ["./cash-book.component.scss"],
})
export class CashBookComponent implements OnInit {
  chhead: any = "";
  showSpinner: boolean = false;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalCalculator: any;
  loading: boolean = false;
  ShowDelete: boolean = false;
  FileCheckingList: any[] = [];
  dataSource: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  AccountHead: any;
  AccountHeadName: any;

  AccountHeadDropdown: any[] = [];

  displayedColumns: string[] = [
    "voucherid",
    "VoucherDate",
    "Voucher_No",
    "CaseNo",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
    "ViewAction",
  ];
  displayedColumnsModel: string[] = [
    "voucherid",
    "VoucherDate",
    "Voucher_No",
    "CaseNo",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
    "ViewAction",
  ];
  displayedColumnsExport: string[] = [
    "voucherid",
    "VoucherDate",
    "Voucher_No",
    "CaseNo",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild("Paginglist", { read: MatPaginator }) Paginglist: MatPaginator;

  JsonData: any[] = [];
  groupList: any[] = [];
  groupDropdown: any[] = [];
  BankList: any[] = [];
  groupModel: any = {};
  dropdownSettings: any = {};
  myForm: UntypedFormGroup;
  AccountControl = new UntypedFormControl();
  disabled = false;
  ShowFilter = true;
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  filteredAccountHead: Observable<any[]>;
  displayedLoanColumns: string[] = [
    "Type",
    "CaseNo",
    "Customer",
    "Branch",
    "Category",
    "AccountHead",
    "AmountDR",
    "AmountCR",
  ];
  displayedAccountingColumns: string[] = [
    "AccountHead",
    "Branch",
    "AmountDR",
    "AmountCR",
  ];
  limitSelection = false;
  FyearId: any;
  maxDate: any;
  minDate: any;
  Obj: any;
  pageTitle: any = "Cash Book";

  TotRow: any = 50;
  BalanceType: any = 1;
  HiddenContent: boolean = false;
  Cur_Date: any = new Date();

  dataSourceMonthly: any;
  dataSourceDaily: any;
  dataSourceModel: any;

  displayedColumnsMonthly: string[] = [
    "Id",
    "Month_Year",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];
  displayedColumnsDaily: string[] = [
    "Id",
    "Voucher_Date",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private reportsService: ReportsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder,
    private _commonfunctions: CommonFunctions
  ) {}
  ngOnInit() {
    //debugger;
    this.dataSharingService.HeaderTitle.next(this.pageTitle);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getAccounthead();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });
  }
  onAccountHeadhSelect(event: any) {
    this.AccountHead = event.option.value.AccountId;
    this.AccountHeadName = event.option.value.Account_Name;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }
  getAccounthead() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: "Cash", LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.AccountHeadDropdown = res;

        this.filteredAccountHead = this.AccountControl.valueChanges.pipe(
          startWith(""),
          map((value) =>
            typeof value === "string" ? value : value.Account_Name
          ),
          map((name) =>
            name ? this._filter(name) : this.AccountHeadDropdown.slice()
          )
        );
      });
  }

  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  onSearchAccountStatement(Addmore: boolean) {
    if (this.AccountHead != null) {
      this.showSpinner = true;
      if (this.BalanceType == 0) {
        this.dataSourceMonthly = "";
        this.dataSourceDaily = "";

        this.lmsService
          .Get_Acc_Leadger({
            AccountId: this.AccountHead,
            From: this.VoucherDateFrom,
            To: this.VoucherDateTo,
            StartYear: this.minDate.getFullYear(),
          })
          .subscribe((res: any) => {
            //console.log(NextCnt,res);
            if (res.length > 0) {
              this.FileCheckingList = JSON.parse(JSON.stringify(res));

              this.JsonData = this.FileCheckingList;
              this.dataSource = new MatTableDataSource(this.FileCheckingList);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;

              setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip();
              }, 1000);
              this.showSpinner = false;
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "No data found",
                ...this.configSuccess,
              });
              this.showSpinner = false;
            }
          });
      } else if (this.BalanceType == 1) {
        this.dataSource = "";
        this.dataSourceDaily = "";
        this.GetACCLeadgerByMonthly();
      } else {
        this.dataSource = "";
        this.dataSourceMonthly = "";
        this.GetACCLeadgerByDaily();
      }
    }
  }
  GetACCLeadgerByMonthly() {
    this.FileCheckingList = [];
    this.showSpinner = true;
    this.reportsService
      .Report_ACC_Leadger_By_Monthly({
        AccountId: this.AccountHead,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        //console.log("Resd :" ,res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceMonthly = new MatTableDataSource(
            this.FileCheckingList
          );

          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  GetACCLeadgerByDaily() {
    this.FileCheckingList = [];
    this.showSpinner = true;
    this.reportsService
      .Report_ACC_Leadger_By_Daily({
        AccountId: this.AccountHead,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        //console.log("Resd :" ,res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceDaily = new MatTableDataSource(this.FileCheckingList);

          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  LoadMore() {
    this.onSearchAccountStatement(true);
  }

  goToDetail(row) {
    $("#VoucherDetail").modal("show");
    $("#VoucherDetail").css("z-index", "1050");
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        if (
          this.voucherModel.Voucher.Voucher_Type == "Receipt" ||
          this.voucherModel.Voucher.Voucher_Type == "Journal" ||
          this.voucherModel.Voucher.Voucher_Type == "Payment"
        )
          this.ShowDelete = true;
        else this.ShowDelete = false;
        this.voucherModel.Voucher_Detail.forEach((x) => {
          x.AmountDR = 0.0;
          x.AmountCR = 0.0;
          if (x.TranType == "CR") x.AmountCR = x.Amount;
          if (x.TranType == "DR") x.AmountDR = x.Amount;
        });
        if (
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == "" ||
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == null
        )
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = "CASH";
        this.onVoucherTagChange();
      });
  }
  OnCloseVoucherDetail() {
    $("#VoucherDetail").modal("hide");
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      _total = _total + obj.Amount || 0;
      _totalCR = _totalCR + obj.Amount || 0;
      obj.TaxSlab.forEach((tobj: any) => {
        _total = _total + tobj.TaxAmount || 0;
        _totalTX = _totalTX + tobj.TaxAmount || 0;
      });
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }

  onChangeChargesHead(data: any, dindex: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        ProductId: data.ProductId || 0,
        TaxType: data.TranType,
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;
            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
          } else {
            data.ChargeHeadId = "";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "category does not have account head.",
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
      });
  }
  onVoucherTagChange() {
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = "CASH";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date),
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeDR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeTotal() {
    if (this.dataSource != null) {
      var lastBalance = this.dataSource.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }
  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "VoucherDate To greater than VoucherDate From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.VoucherDateTo = new Date();
        });
    }
  }
  DeleteVoucher() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove this Voucher.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService
            .Delete_Voucher({
              VoucherId: this.voucherModel.Voucher.VoucherId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                if (
                  this.voucherModel.Voucher_Detail.find((obj: any) => {
                    return obj.ChargeHeadId == 34;
                  })
                )
                  this.lmsService
                    .LMS_Update_EMI_Allocation({
                      VoucherId: this.voucherModel.Voucher.VoucherId,
                    })
                    .subscribe((res1: any) => {});

                this.OnCloseVoucherDetail();
                this.onSearchAccountStatement(false);
              }
            });
        }
      });
  }

  printdata() {
    $("#CashBookPrintModel").modal("show");
    $("#CashBookPrintModel").css("z-index", "1050");
  }

  onCloseCashBookPrintModel() {
    $("#CashBookPrintModel").modal("hide");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }

  datePipe: DatePipe = new DatePipe("en-US");

  // onPrint(divName, type) {
  //   var File_Name;
  //   // File_Name = type == 1 ? 'Accounting-statement.pdf' : 'SOA.pdf';
  //   this.HiddenContent = true;
  //   let DATA: any = document.getElementById(divName);
  //   html2canvas(DATA).then((canvas) => {
  //     let fileWidth = 208;
  //     let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //     canvas.getContext('webgl');
  //     const FILEURI = canvas.toDataURL('image/jpg');
  //     let PDF = new jsPDF('p', 'mm', 'a4');
  //     let position = 30;

  //     var pageHeight= PDF.internal.pageSize.getHeight();
  //     var pagecount = Math.ceil(fileHeight / pageHeight);
  //     //console.log(pagecount);
  //     PDF.addImage(FILEURI, 'JPEG', 1, position, fileWidth, 0);

  //     if (pagecount > 0) {
  //       var j = 1;
  //       while (j != pagecount) {
  //           PDF.addPage('p');
  //           PDF.addImage(FILEURI, 'JPEG', 2, -(j * pageHeight), fileWidth-4, 0);
  //           j++;
  //       }
  //     }

  //     PDF.setFontSize(8);
  //     PDF.setTextColor(33, 37, 41);
  //     PDF.text(this.currentUser.CompanyName, 3, 20);
  //     PDF.text('Printed By : '+this.currentUser.username, 3, 285);
  //     PDF.text('Printed On : '+ this.datePipe.transform(new Date(), 'dd/MM/yyyy hh:mm:ss'), 160, 285);
  //     // PDF.save(File_Name);
  //   });
  // }

  GetData(data) {
    if (data == true) {
      this.onSearchAccountStatement(false);
    }
  }

  totaltypeDaily(type) {
    if (this.dataSourceDaily != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceDaily.filteredData.length; i++) {
        sum += Number(this.dataSourceDaily.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
  totaltypeMonthly(type) {
    if (this.dataSourceMonthly != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceMonthly.filteredData.length; i++) {
        sum += Number(this.dataSourceMonthly.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypeModel(type) {
    if (this.dataSourceModel != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceModel.filteredData.length; i++) {
        sum += Number(this.dataSourceModel.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypeModelBal() {
    if (
      this.dataSourceModel != null &&
      this.dataSourceModel.filteredData.length > 0
    ) {
      var lastBalance = this.dataSourceModel.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }

  calculateMonthlyBalance() {
    if (this.dataSourceMonthly != null) {
      var lastBalance = this.dataSourceMonthly.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  calculateDailyBalance() {
    if (this.dataSourceDaily != null) {
      var lastBalance = this.dataSourceDaily.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  goToDetailModel(row) {
    this.FileCheckingList = [];
    this.dataSourceModel = "";

    var firstDayOfMonth, lastDayOfMonth, RowIndex, DayMonth;

    if (this.BalanceType == 1) {
      RowIndex = this.dataSourceMonthly.data.indexOf(row, 0);

      DayMonth = new Date(row.Month_Year);
      firstDayOfMonth = new Date(
        DayMonth.getFullYear(),
        DayMonth.getMonth(),
        1
      );
      lastDayOfMonth = new Date(
        DayMonth.getFullYear(),
        DayMonth.getMonth() + 1,
        0
      );
    } else {
      firstDayOfMonth = row.Voucher_Date;
      lastDayOfMonth = row.Voucher_Date;
    }

    //console.log("firstDayOfMonth", firstDayOfMonth, "----- lastDayOfMonth", lastDayOfMonth);
    this.showSpinner = true;
    this.lmsService
      .Get_Acc_Leadger({
        AccountId: row.AccountId,
        From: firstDayOfMonth,
        To: lastDayOfMonth,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("res", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceModel = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceModel.sort = this.sort;
        this.dataSourceModel.paginator = this.Paginglist;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 100);
        this.showSpinner = false;
        $("#VoucherModel").modal("show");
        $("#VoucherModel").css("z-index", "1050");
      });
  }
  OnCloseVoucherModel() {
    $("#VoucherModel").modal("hide");
  }

  applyFilter(filterValue: string) {
    this.dataSourceModel.filter = filterValue.trim().toLowerCase();
  }
  exportExcel() {
    this.showSpinner = true;
    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.AccountHead,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("RES", res);
        //remove or hide a key & value of an array
        res.forEach(function (item) {
          delete item["voucherid"];
          delete item["ChagesHead"];
          delete item["Voucher_Type"];
          delete item["Customer_Name"];
          delete item["HeadId"];
          delete item["DaysLate"];
          delete item["ODInterest"];
          delete item["ODCredit"];
          delete item["ODTotal"];
          delete item["Balance"];
          delete item["Voucher_Cheque_Clear_Date"];
          delete item["Receipt_No"];

          item.TotalBalance = item.TranTypeTotal;

          delete item.TranTypeTotal;
        });

        res.push({
          VoucherDate: "",
          Voucher_No: "",
          CaseNo: "",
          accHead: "Total",
          TranTypeDR: this.calculateACTranTypeDR(),
          TranTypeCR: this.calculateACTranTypeCR(),
          TotalBalance: this.calculateACTranTypeTotal(),
          Narration: "",
        });
        this.exportAsExcelFile(res, "Accounting Statement");
        this.showSpinner = false;
      });
  }
  getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "Cash Book";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName,
    };
  };
  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  //
  exportExcelAnnual(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "cash_book_annually"
    ) as HTMLTableElement;
    const worksheetName = "CASH BOOK ANNUALLY"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportExcelMonthly(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "cash_book_monthly"
    ) as HTMLTableElement;
    const worksheetName = "CASH BOOK MONTHLY"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  exportExcelDaily(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "cash_book_daily"
    ) as HTMLTableElement;
    const worksheetName = "CASH BOOK DAILY"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportExcelMonth_Details(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "cash_book_monthly_details"
    ) as HTMLTableElement;
    const worksheetName = "CASH BOOK DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilterMonthly(filterValue: String) {
    // dataSourceMonthly
    this.dataSourceMonthly.filter = filterValue.trim().toLowerCase();
  }

  applyFilterAnnually(filterValue: String) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterDaily(filterValue: String){
    this.dataSourceDaily.filter = filterValue.trim().toLowerCase();
  }
}
