<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    *ngIf="Type == '' || Type == null || Type == undefined"
    class="formborder"
  >
    <form
      #df="ngForm"
      class="row m-0 col-md-12 p-0"
      (ngSubmit)="Search()"
      novalidate
    >
      <div class="col-md-2 p-1">
        <span> Select Search </span>
        <select
          name="SearchType"
          id="SearchType"
          [(ngModel)]="SearchType"
          (change)="ChangeSearchType()"
          class="form-control input-text-css"
        >
          <option value="Status">Status</option>

          <option value="CreatedBy">Created By</option>
          <option value="FollowupBy">Followup By</option>
          <option value="CreatedOn">Created On</option>
          <option value="FollowupOn">Followup On</option>
          <option value="CompletedOn">Completed On</option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <span> Type</span>
        <select
          name="Type"
          id="Type"
          [(ngModel)]="CustomerType"
          #refType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refType.invalid,
            'alert-warning':
              refType.invalid &&
              (refType.dirty || refType.touched || refType.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Type</option>
          <option value="Notes">Customer Notes</option>
          <option value="Collection">Customer Collection Follow-Up</option>
          <option value="Follow-Up">Customer Other Follow-Up</option>
          <option value="Customer Request">Customer Request</option>
          <option value="Internal Request">Internal Request</option>
          <option value="Customer Task">Customer Task</option>
          <option value="Internal Task">Internal Task</option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <span> Status </span>
        <select
          name="Status"
          id="Status"
          [(ngModel)]="status"
          #refStatus="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refStatus.invalid,
            'alert-warning':
              refStatus.invalid &&
              (refStatus.dirty || refStatus.touched || refStatus.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Status</option>
          <option value="Pending">Pending</option>
          <option value="Completed">Completed</option>
          <option value="Completed">Forwarded</option>
        </select>
      </div>

      <div
        class="col-md-2 p-1"
        *ngIf="SearchType == 'CreatedBy' || SearchType == 'FollowupBy'"
      >
        <span> {{ SearchType }} </span>
        <select
          name="Employee"
          id="Employee"
          required
          [(ngModel)]="SearchValue"
          #refEmployee="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refEmployee.invalid,
            'alert-warning':
              refEmployee.invalid &&
              (refEmployee.dirty ||
                refEmployee.touched ||
                refEmployee.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select</option>
          <option *ngFor="let cat of EmployeeList" [value]="cat.EmpId">
            {{ cat.EmpName }}
          </option>
        </select>
      </div>

      <div
        class="col-md-2 p-1"
        *ngIf="
          SearchType == 'CreatedOn' ||
          SearchType == 'FollowupOn' ||
          SearchType == 'CompletedOn'
        "
      >
        <span> From Date </span>
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div
        class="col-md-2 p-1"
        *ngIf="
          SearchType == 'CreatedOn' ||
          SearchType == 'FollowupOn' ||
          SearchType == 'CompletedOn'
        "
      >
        <span> To Date</span>
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div class="col-md-2 p-1 text-right">
        <button
          (click)="Search()"
          [disabled]="!df.form.valid"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
      <div class="col-md-2 p-1 text-right">
        <button (click)="AddNew()" class="mt-3 btn font-size-12 button-btn">
          Add New
        </button>
      </div>

      <div class="col-md-3 p-1 mt-2">
        <!--<mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Search ...."
      />
    </mat-form-field>-->
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                #refFilterInputSearch="ngModel"
                [(ngModel)]="FilterInputSearch"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </form>
  </div>
  <div class="table-responsive mt-1">
    <mat-table
      [dataSource]="dataSource"
      style="height: 60vh; width: 100%; overflow: auto"
      matTableExporter
      #exporter="matTableExporter"
      matSort
    >
      <ng-container matColumnDef="Id">
        <mat-header-cell class="grid-header mw50" *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell *matCellDef="let row; let i = index" class="">
          {{ i + 1 }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Type">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Type</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Type }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Title">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Title</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Title }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="LoanAcNo">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Loan No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            (click)="onEditNotesRecom(row)"
            aria-label="true"
            style="color: blue; cursor: pointer"
          >
            {{ row.LoanAcNo }}</a
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="VisitLocation">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Visit Location</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.VisitLocation }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerResponse">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Customer Response</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerResponse }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreatedBy">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Created By</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreatedBy }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreatedOn">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Created On</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreatedOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="FollowupDate">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >FollowUp Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.FollowupDate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="FollowupBy">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Followup By</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.FollowupBy }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Completed_On">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Completed On</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Completed_On }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Status</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Status }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="TAT">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >TAT(Hr)</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TAT_Hr }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Creation_LatLong">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Creation</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            *ngIf="
              row.Creation_LatLong != '' &&
              row.Creation_LatLong != null &&
              row.Creation_LatLong != ','
            "
            [href]="'http://maps.google.com/maps?q=' + row.Creation_LatLong"
            target="_blank"
            ><i class="fa-solid fa-location-dot"></i
          ></a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Completed_LatLong">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Completed</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            *ngIf="
              row.Completed_LatLong != '' &&
              row.Completed_LatLong != null &&
              row.Completed_LatLong != ','
            "
            [href]="'http://maps.google.com/maps?q=' + row.Completed_LatLong"
            target="_blank"
            ><i class="fa-solid fa-location-dot"></i
          ></a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="View">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <!--<i class="fa fa-pencil-alt font-size-12 mr-2" (click)="onEditNotesRecom(row)" title="Edit This" style="cursor: pointer;"
      aria-label="true" *ngIf="PageAcess[0].Edit == 1"></i>-->
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="onEditNotesRecom(row)"
            title=""
            style="cursor: pointer"
            aria-label="true"
          ></i>
          <i
            class="fa fa-times ml-2"
            aria-hidden="true"
            style="cursor: pointer"
            *ngIf="row.Type == 'Notes'"
            (click)="onDeleteNote(row)"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row
        style="text-align: center !important"
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="text-align: center !important"
      ></mat-row>
    </mat-table>
  </div>
  <div class="row m-0 align-items-center" style="background: #fff">
    <div class="col-md-4">
      <button class="btn font-size-12 button-btn" (click)="exportTable()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
  </div>
</div>

<table
  id="request_task"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Title
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Visit Location
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Response
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Follow Up By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Follow Up Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Completed On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(hr.)
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Type }}</td>
    <td>{{ x.Title }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.VisitLocation }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.CustomerResponse }}</td>

    <td>{{ x.CreatedBy }}</td>
    <td>{{ x.CreatedOn }}</td>
    <td>{{ x.FollowupBy }}</td>
    <td>{{ x.FollowupDate }}</td>
    <td>{{ x.Completed_On }}</td>
    <td>{{ x.Status }}</td>
    <td>{{ x.TAT_Hr }}</td>
  </tr>
</table>
