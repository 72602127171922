<section class="main-content-wrapper">
  <div *ngIf="loanSummary">
    <div class="row m-0" *ngIf="CompletedPage && CompletedPage.length > 0">
      <div class="col-md-12 mb-1">
        <div class="container">
          <div style="position: relative">
            <mat-progress-bar
              mode="determinate"
              [value]="progress"
              class="style1"
            ></mat-progress-bar>

            <span
              style="position: absolute; top: 0.5em"
              [ngStyle]="{ left: progress + '%' }"
            ></span>
          </div>

          <div class="d-flex justify-content-end">
            <span class="completed_progress_bar"
              >{{ progress }}% completed
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 col-md-12 mt-0">
      <div
        class="col-md-12 mb-1"
        [ngClass]="
          loanSummary.CloseDate != null
            ? 'alert alert-danger'
            : loanSummary.Loan_Acc_Type != 'Own Fund'
            ? 'alert alert-info'
            : 'alert alert-success'
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 10px;
            margin-bottom: 10px;
          "
        >
          <div>
            <i
              class="fa fa-info-circle Inquiry-style"
              *ngIf="inquiryId && Processid == 1"
              aria-hidden="true"
              (click)="ViewInquiry()"
            ></i>

            <i
              class="fa fa-inr Inquiry-style"
              *ngIf="PageModel.CustomerInfo.length > 0"
              (click)="OnFeeAdd(loanSummary.ProductId)"
              aria-hidden="true"
              style="padding: 0; font-size: 1.5em"
            ></i>
          </div>

          <div>
            <i
              class="fas fa-comment-dots btn"
              aria-hidden="true"
              (click)="openChatModal()"
              style="padding: 0; font-size: 1.5em; color: #0c5460 !important"
            >
            </i>
          </div>
        </div>

        <div class="grid-container formborder">
          <div class="grid-item" *ngIf="loanSummary.LoanNo">
            <span class="fs-12"><B>Loan No :</B> {{ loanSummary.LoanNo }}</span>
          </div>
          <div class="grid-item" *ngIf="loanSummary.LoanDate">
            <span class="fs-12"
              ><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span
            >
          </div>
          <div class="grid-item">
            <span class="fs-12"
              ><B>Application No:</B> {{ loanSummary.ApplicationNo }}</span
            >
          </div>
          <div class="grid-item">
            <span class="fs-12"
              ><B>Application Date :</B>
              {{ loanSummary.Application_Date }}</span
            >
          </div>
          <div class="grid-item">
            <span class="fs-12"><B>Branch :</B> {{ loanSummary.Branch }}</span>
          </div>

          <div class="grid-item" *ngIf="loanSummary.LoanAmount">
            <span class="fs-12"
              ><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span
            >
          </div>

          <div class="grid-item">
            <span class="fs-12"
              ><B>Product :</B> {{ loanSummary.Product }}</span
            >
          </div>
          <div class="grid-item">
            <span class="fs-12"
              ><B>Borrower :</B> {{ loanSummary.Customer }}</span
            >
          </div>
          <div class="grid-item">
            <span class="fs-12">
              <B>Tenure :</B>
              {{ loanSummary.Tenure > 0 ? loanSummary.Tenure : "" }} (
              {{ loanSummary.Tenure > 0 ? Tenure_Name : "" }})
            </span>
          </div>

          <div class="grid-item" *ngIf="loanSummary.ExpiryDate">
            <span class="fs-12"
              ><B>Expiry Date :</B> {{ loanSummary.ExpiryDate }}</span
            >
          </div>
          <div class="grid-item" *ngIf="loanSummary.CloseDate">
            <span class="fs-12"
              ><B>Close Date :</B> {{ loanSummary.CloseDate }}</span
            >
          </div>
          <div class="grid-item" *ngIf="loanSummary.Loan_Acc_Category">
            <span class="fs-12"
              ><B>Loan A/C Category :</B>
              {{ loanSummary.Loan_Acc_Category }}</span
            >
          </div>
        </div>

        <div
          class="text-right mb-2"
          style="padding-top: 11px"
          *ngIf="
            Status != 'C' &&
            Processid == 20 &&
            (Legal_Process.length > 0 || Valuation_Process.length > 0)
          "
        >
          <button
            type="button"
            (click)="OnProcessAssignment()"
            mat-raised-button
            class="btn font-size-12 button-btn mr-3"
          >
            Legal/Valuation Process Assignment
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="row col-md-12 m-0 mt-2 justify-content-end" *ngIf="inquiryId && Processid==1">

        <button type="button" class="ml-4"
          style="background-color: #28a745; border-radius: 20px; height: 27px; color: #ffffff; font-size: 12px;  margin-right: 85px !important; margin-top: 4px; "
          mat-raised-button color="primary" (click)="ViewInquiry()" [class.spinner]="loading">
          View Inquiry
        </button>
      </div>-->
  <mat-tab-group
    class="Loan_Layout"
    [selectedIndex]="currentTab"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab
      [aria-label]="LabelLoan"
      *ngIf="
        LoanInfo &&
        LoanInfo[0].MM_Name == 'Loan Info' &&
        Processid != 16 &&
        Processid != 17
      "
    >
      <ng-template mat-tab-label
        ><span>{{ LoanInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-loan-info
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="LoanInfo[0]"
          [isNew]="isNew"
          [ProductId]="ProductId"
          [Status]="Status"
          [ProcessName]="ProcessName"
          [InquiryId]="inquiryId"
          (action)="onChildAction($event)"
        ></app-quickloan-loan-info>
      </ng-template>
    </mat-tab>

    <!--     
    Processid != 16 &&
    Processid != 17 -->

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="CustomerInfo && CustomerInfo[0].MM_Name == 'Customer Info'"
      [aria-label]="LabelCustomer"
    >
      <ng-template mat-tab-label
        ><span>{{ CustomerInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-customer-detail
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="CustomerInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-customer-detail>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        AccountInfo &&
        AccountInfo[0].MM_Name == 'Account Info' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelAccount"
    >
      <ng-template mat-tab-label
        ><span>{{ AccountInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-account-info
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="AccountInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-account-info>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        CustomerRef &&
        CustomerRef[0].MM_Name == 'Customer Reference' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelCustomerRef"
    >
      <ng-template mat-tab-label
        ><span>{{ CustomerRef[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-customer-reference
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="CustomerRef[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-customer-reference>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        IncExpInfo &&
        IncExpInfo[0].MM_Name == 'Customer Income and expenditure' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelIncExp"
      [isNew]="isNew"
    >
      <ng-template mat-tab-label
        ><span>{{ IncExpInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-income-expenditure
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="IncExpInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-income-expenditure>
      </ng-template>
    </mat-tab>

    <mat-tab [aria-label]="LabelBusinessAnalysis"
             [disabled]="TabDisabled"
             *ngIf="
        BusinessAnalysis &&
        BusinessAnalysis[0].MM_Name == 'Bank Statement Analysis' &&
        Processid != 16 &&
        Processid != 17
      "
             [aria-label]="LabelIncExp"
             [isNew]="isNew">
        <ng-template mat-tab-label><span>{{ BusinessAnalysis[0].MM_Short_Name }}</span></ng-template>
        <ng-template matTabContent>
            <app-quickloan-bank-analysis [loanSummary]="loanSummary"
                                         [Revert_IsEdit]="Revert_IsEdit"
                                         [Processid]="Processid"
                                         [PageData]="BusinessAnalysis[0]"
                                         [isNew]="isNew"
                                         [ProductId]="ProductId"
                                         [Status]="Status"
                                         [ProcessName]="ProcessName"
                                         [InquiryId]="inquiryId"
                                         (action)="onChildAction($event)"></app-quickloan-bank-analysis>
        </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        PendingDocs &&
        PendingDocs[0].MM_Name == 'Pending Document' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelDocs"
    >
      <ng-template mat-tab-label
        ><span>{{ PendingDocs[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-documents
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="PendingDocs[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-documents>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        CRCInfo &&
        CRCInfo[0].MM_Name == 'CRC' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelCRC"
    >
      <ng-template mat-tab-label
        ><span>{{ CRCInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-crc
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="CRCInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-crc>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        PDCInfo &&
        PDCInfo[0].MM_Name == 'PDC' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelPDC"
    >
      <ng-template mat-tab-label
        ><span>{{ PDCInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-bank-pdc
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="PDCInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-bank-pdc>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        AssetsInfo &&
        AssetsInfo[0].MM_Name == 'Assets Detail' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelAssets"
    >
      <ng-template mat-tab-label
        ><span>{{ AssetsInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-assets-info
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="AssetsInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-assets-info>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        FamilyMemberInfo &&
        FamilyMemberInfo[0].MM_Name == 'Family Member' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelFamilyMem"
    >
      <ng-template mat-tab-label
        ><span>{{ FamilyMemberInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-family-member
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="FamilyMemberInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-family-member>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        FiQuestionsInfo &&
        FiQuestionsInfo[0].MM_Name == 'FI Questions' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelFIQues"
    >
      <ng-template mat-tab-label
        ><span>{{ FiQuestionsInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-fi-questions
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="FiQuestionsInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-fi-questions>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        TVRQestionsInfo &&
        TVRQestionsInfo[0].MM_Name == 'TVR Questions' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelTVRQues"
    >
      <ng-template mat-tab-label
        ><span>{{ TVRQestionsInfo[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-tvr-questions
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="TVRQestionsInfo[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-tvr-questions>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        LegalProcess &&
        LegalProcess[0].MM_Name == 'Legal Process' &&
        Processid != 17
      "
      [aria-label]="LabelLegalProcess"
    >
      <ng-template mat-tab-label
        ><span>{{ LegalProcess[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-legal-process
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="LegalProcess[0]"
          [isNew]="isNew"
          [ProductId]="ProductId"
          [Status]="Status"
          [ProcessName]="ProcessName"
          [InquiryId]="inquiryId"
          (action)="onChildAction($event)"
        ></app-quickloan-legal-process>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        ValuationProcess &&
        ValuationProcess[0].MM_Name == 'Valuation Process' &&
        Processid != 16
      "
      [aria-label]="LabelValuationProcess"
    >
      <ng-template mat-tab-label
        ><span>{{ ValuationProcess[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-valuation-process
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="ValuationProcess[0]"
          [isNew]="isNew"
          [ProductId]="ProductId"
          [Status]="Status"
          [ProcessName]="ProcessName"
          [InquiryId]="inquiryId"
          (action)="onChildAction($event)"
        ></app-quickloan-valuation-process>
      </ng-template>
    </mat-tab>

    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        Insurance &&
        Insurance[0].MM_Name == 'Insurance' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelInsurance"
    >
      <ng-template mat-tab-label
        ><span>{{ Insurance[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-insurance
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="Insurance[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-insurance>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        PersonalDiscussion &&
        PersonalDiscussion[0].MM_Name == 'Personal Discussion' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelPersonalDiscussion"
      label="LabelPersonalDiscussion"
    >
      <ng-template mat-tab-label><span>Personal Discussion</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-pd-questions
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="PersonalDiscussion[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-pd-questions>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        FOIR && FOIR[0].MM_Name == 'FOIR' && Processid != 16 && Processid != 17
      "
      [aria-label]="LabelFOIR"
      label="LabelFOIR"
    >
      <ng-template mat-tab-label><span>FOIR</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-foir
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="FOIR[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-foir>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        FileChecking &&
        FileChecking[0].MM_Name == 'FileChecking' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelFileChecking"
      label="LabelFileChecking"
    >
      <ng-template mat-tab-label><span>File Checking</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-FileChecking
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="FileChecking[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-FileChecking>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="TabDisabled"
      *ngIf="
        SanctionConditions &&
        SanctionConditions[0].MM_Name == 'Sanction Conditions' &&
        Processid != 16 &&
        Processid != 17
      "
      [aria-label]="LabelSanctionConditions"
    >
      <ng-template mat-tab-label
        ><span>{{ SanctionConditions[0].MM_Short_Name }}</span></ng-template
      >
      <ng-template matTabContent>
        <app-sanction-conditions
          [loanSummary]="loanSummary"
          [Revert_IsEdit]="Revert_IsEdit"
          [Processid]="Processid"
          [PageData]="SanctionConditions[0]"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-sanction-conditions>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="TabDisabled" *ngIf="GenerateLoan && Processid == 6">
      <ng-template mat-tab-label><span>Generate Loan No</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-generate-loanno
          [loanSummary]="loanSummary"
          [Processid]="Processid"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-generate-loanno>
      </ng-template>
    </mat-tab>

    <!--<mat-tab *ngIf="inquiryId && Processid == 1" tabindex="10" label="Lead">
      <ng-template mat-tab-label><span>Inquiry Info</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-deviation  [inquiryId]="inquiryId"  (action)="onChildAction($event)"></app-quickloan-deviation>
      </ng-template>
    </mat-tab>-->
    <mat-tab [disabled]="TabDisabled" *ngIf="Deviation" label="Deviation">
      <ng-template mat-tab-label
        ><span>Deviation/Final Submit</span></ng-template
      >
      <ng-template matTabContent>
        <app-quickloan-deviation
          [loanSummary]="loanSummary"
          [Processid]="Processid"
          [Status]="Status"
          [ProcessName]="ProcessName"
          (action)="onChildAction($event)"
        ></app-quickloan-deviation>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <!-- <div class="row m-0 ">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0 ">
    <div class="col-md-10">
        <span>Remarks</span>
        <textarea rows="1" name="Remarks" id="Remark" [(ngModel)]="Remarks" #refRemark="ngModel"  placeholder="Remarks"
            class="form-control input-text-css"></textarea>
    </div>
    <div class="col-md-2 mt-3 text-right">
        <button type="button" class="btn_default" mat-raised-button style="margin-top: 4px;">
            Final Submit
        </button>
    </div>
  </div> -->
  <hr />

  <ng-container *ngIf="ShowFinalTab">
    <form #sdf="ngForm" novalidate class="formborder">
      <div class="row m-0"></div>
      <div class="row m-0 col-md-12">
        <div *ngIf="Status != 'R' && Status != 'C'" [ngClass]="'col-md-3'">
          <span class="required-lable">Action</span>
          <select
            name="SelectStatus"
            id="SelectStatus"
            #refSelectStatus="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="SelectStatus"
            required
            [ngClass]="{
              'is-invalid': sdf.submitted && refSelectStatus.invalid,
              'alert-warning':
                refSelectStatus.invalid &&
                (refSelectStatus.dirty ||
                  refSelectStatus.touched ||
                  refSelectStatus.untouched)
            }"
          >
            <option value="">Select Action</option>
            <option
              value="Completed"
              *ngIf="
                Completed &&
                (Processid != 6 || (NextProcessId > 0 && Processid == 6))
              "
            >
              To Be Continued
            </option>
            <option value="Hold" *ngIf="Hold">Hold</option>
            <option value="Reject" *ngIf="Rejected">Reject</option>
          </select>
        </div>

        <div
          class="col-md-3"
          *ngIf="
            Status != 'C' &&
            Processid != 16 &&
            Processid != 17 &&
            SelectStatus != 'Reject' &&
            NextProcessId > 0
          "
          [ngClass]="SendBack ? 'col-md-3' : 'col-md-3'"
        >
          <span>Next Process Name</span>
          <input
            name="NextProcessName"
            id="NextProcessName"
            [(ngModel)]="NextProcessName"
            #refNextProcessName="ngModel"
            readonly
            placeholder="Next Process Name"
            class="form-control input-text-css"
          />
        </div>

        <div
          class="col-md-3"
          *ngIf="
            Status != 'C' &&
            Processid != 16 &&
            Processid != 17 &&
            SelectStatus != 'Reject' &&
            NextProcessId > 0
          "
          [ngClass]="SendBack ? 'col-md-3' : 'col-md-3'"
        >
          <span class="required-lable">Assigned Employee</span>
          <select
            name="SelectAssignEmp"
            id="SelectAssignEmp"
            #refSelectAssignEmp="ngModel"
            class="form-control input-text-css"
            [disabled]="
              !(Processid == 10) &&
              (Status == 'R' || (Status == 'P' && !Mannual_Assignment))
            "
            [(ngModel)]="SelectAssignEmp"
            required
            [ngClass]="{
              'is-invalid': sdf.submitted && refSelectAssignEmp.invalid,
              'alert-warning':
                refSelectAssignEmp.invalid &&
                (refSelectAssignEmp.dirty ||
                  refSelectAssignEmp.touched ||
                  refSelectAssignEmp.untouched)
            }"
          >
            <option value="">Select Assign Employee</option>
            <option *ngFor="let row of AsignEmpData" [value]="row.EmpId">
              {{ row.EmpName }}
            </option>
          </select>
        </div>

        <!-- *ngIf="Status != 'R' && Status != 'C' && SelectStatus == 'Completed'" -->
        <div [ngClass]="'col-md-3'" *ngIf="NextProcessId > 0">
          <span>Score</span>
          <select
            name="Score"
            id="Score"
            #refScore="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="Score"
            required
            [ngClass]="{
              'is-invalid': sdf.submitted && refScore.invalid,
              'alert-warning':
                refScore.invalid &&
                (refScore.dirty || refScore.touched || refScore.untouched)
            }"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>

        <div
          [ngClass]="
            Processid > 1 && Status != 'R' && Status != 'C'
              ? 'col-md-9'
              : 'col-md-9'
          "
          *ngIf="Status != 'C'"
        >
          <span>Remarks</span>
          <textarea
            rows="1"
            name="Remarks"
            id="Remark"
            [(ngModel)]="Remarks"
            #refRemark="ngModel"
            placeholder="Remarks"
            class="form-control input-text-css"
          ></textarea>
        </div>
        <div class="col-md-3 mt-3 text-right" *ngIf="Status != 'C'">
          <button
            type="button"
            class="mt-2 btn font-size-12 button-btn"
            style="margin-top: 3px"
            (click)="QuickLoanFinalSubmit()"
            *ngIf="FinalSubmit"
            [disabled]="Status == 'C' || !sdf.form.valid"
          >
            Final Submit
          </button>
        </div>

        <div
          class="col-md-3 mt-3"
          *ngIf="Processid > 1 && Status != 'R' && Status != 'C' && SendBack"
        >
          <button
            type="button"
            class="mt-2 btn font-size-12 button-btn"
            (click)="OnClickSendBack()"
          >
            Send Back To Process
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</section>

<div
  class="modal fade in"
  id="SendBackModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Send Back To Process
        </h6>
        <button
          type="button"
          (click)="OnCloseSendBack()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ddf="ngForm" novalidate class="formborder">
        <div class="modal-body">
          <div class="row m-0 mb-1">
            <div class="col-md-1 p-1 h-b">#</div>
            <div class="col-md-2 p-1 h-b">Process Name</div>
            <div class="col-md-2 p-1 h-b">Employee Name</div>
            <div class="col-md-1 p-1 h-b">Edit</div>
            <div class="col-md-6 p-1 h-b">Remark</div>
          </div>
          <div
            class="row m-0 mb-1"
            *ngFor="let Process of AllProcess; let i = index"
          >
            <div class="col-md-1 p-1">
              <mat-checkbox
                class="example-margin"
                name="IsSelected{{ i }}"
                id="IsSelected{{ i }}"
                [(ngModel)]="Process.IsSelected"
              ></mat-checkbox>
            </div>
            <div class="col-md-2 p-1">
              {{ Process.Process_Name }}
            </div>
            <div class="col-md-2 p-1">
              {{ Process.Employee_Name }}
            </div>
            <div class="col-md-1 p-1">
              <mat-checkbox
                name="IsEdit{{ i }}"
                *ngIf="Process.IsSelected == true"
                id="IsEdit{{ i }}"
                [(ngModel)]="Process.IsEdit"
              ></mat-checkbox>
            </div>
            <div class="col-md-6 p-1">
              <textarea
                rows="2"
                name="Remark{{ i }}"
                id="Remark{{ i }}"
                [(ngModel)]="Process.Remark"
                #refRemark="ngModel"
                placeholder="Remark"
                class="form-control input-text-css"
                rows="1"
                [required]="Process.IsSelected == true"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refRemark.invalid,
                  'alert-warning':
                    refRemark.invalid &&
                    (refRemark.dirty ||
                      refRemark.touched ||
                      refRemark.untouched)
                }"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseSendBack()"
            class="mt-2 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnSaveSendBack()"
            class="mt-2 btn font-size-12 button-btn"
            [disabled]="!ddf.form.valid"
          >
            Send Back
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ProcessAssignmentModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Process Assign
        </h6>
        <button
          type="button"
          (click)="OnCloseProcessAssignmentModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #df="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 mb-1" *ngIf="Legal_Process?.length > 0">
            <div class="col-md-1 p-1">
              <mat-checkbox
                class="example-margin"
                name="IsSelectedLegal"
                id="IsSelectedLegal"
                [(ngModel)]="Process.IsSelectedLegal"
              ></mat-checkbox>
            </div>
            <div class="col-md-6 p-1">Legal Process</div>
            <div class="col-md-5 p-1">
              <select
                name="LegalAssignBy"
                id="LegalAssignBy"
                #refLegalAssignBy="ngModel"
                [required]="Process.IsSelectedLegal == true"
                [ngClass]="{
                  'is-invalid': df.submitted && refLegalAssignBy.invalid,
                  'alert-warning':
                    refLegalAssignBy.invalid &&
                    (refLegalAssignBy.dirty ||
                      refLegalAssignBy.touched ||
                      refLegalAssignBy.untouched)
                }"
                [(ngModel)]="Process.LegalAssignBy"
                class="form-control input-text-css"
                [disabled]="!Process.IsSelectedLegal"
                required
              >
                <option value="">Select Assign By</option>
                <option
                  *ngFor="let collection of AsignEmpDataLegal"
                  [value]="collection.EmpId"
                >
                  {{ collection.EmpName }}
                </option>
              </select>
              <!--<textarea rows="2" name="Remark{{i}}" id="Remark{{i}}" [(ngModel)]="Process.Remark" #refRemark="ngModel"
              placeholder="Remark" class="form-control input-text-css" rows="1" [required]="Process.IsSelected == true"
              [ngClass]="{'is-invalid': ddf.submitted && refRemark.invalid, 'alert-warning': refRemark.invalid && (refRemark.dirty || refRemark.touched || refRemark.untouched)}"></textarea>-->
            </div>
          </div>
          <div class="row m-0 mb-1" *ngIf="Valuation_Process?.length > 0">
            <div class="col-md-1 p-1">
              <mat-checkbox
                class="example-margin"
                name="IsSelectedValuation"
                id="IsSelectedValuation"
                [(ngModel)]="Process.IsSelectedValuation"
              ></mat-checkbox>
            </div>
            <div class="col-md-6 p-1">Valuation Process</div>
            <div class="col-md-5 p-1">
              <select
                name="ValuationAssignBy"
                id="ValuationAssignBy"
                #refValuationAssignBy="ngModel"
                [required]="Process.IsSelectedValuation == true"
                [ngClass]="{
                  'is-invalid': df.submitted && refValuationAssignBy.invalid,
                  'alert-warning':
                    refValuationAssignBy.invalid &&
                    (refValuationAssignBy.dirty ||
                      refValuationAssignBy.touched ||
                      refValuationAssignBy.untouched)
                }"
                [(ngModel)]="Process.ValuationAssignBy"
                class="form-control input-text-css"
                [disabled]="!Process.IsSelectedValuation"
              >
                <option value="">Select Assign By</option>
                <option
                  *ngFor="let collection of AsignEmpDataVal"
                  [value]="collection.EmpId"
                >
                  {{ collection.EmpName }}
                </option>
              </select>
              <!--<textarea rows="2" name="Remark{{i}}" id="Remark{{i}}" [(ngModel)]="Process.Remark" #refRemark="ngModel"
              placeholder="Remark" class="form-control input-text-css" rows="1" [required]="Process.IsSelected == true"
              [ngClass]="{'is-invalid': ddf.submitted && refRemark.invalid, 'alert-warning': refRemark.invalid && (refRemark.dirty || refRemark.touched || refRemark.untouched)}"></textarea>-->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseProcessAssignmentModel()"
            class="btn font-size-12 button-btn mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnSaveProcessAssignment()"
            class="btn font-size-12 button-btn"
            [disabled]="
              !df.form.valid ||
              (Process.IsSelectedLegal == false &&
                Process.IsSelectedValuation == false)
            "
          >
            Assign
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="InquiryModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Inquiry
        </h6>
        <button
          type="button"
          (click)="OnCloseInquiryModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ddf="ngForm" novalidate class="formborder">
        <div class="modal-body">
          <div class="row m-0 col-md-12 mt-0" *ngIf="inquiryDetail">
            <div class="col-md-12 mb-1" [ngClass]="'alert alert-success'">
              <div class="row">
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Inquiry No :</B> {{ inquiryDetail.InquiryNo }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Customer :</B> {{ inquiryDetail.CustomerName }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Address :</B> {{ inquiryDetail.CustomerAddress }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Contact Number :</B>
                    {{ inquiryDetail.ContactNumber }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Email :</B> {{ inquiryDetail.Inq_Email }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Source :</B> {{ inquiryDetail.Source }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Branch :</B> {{ inquiryDetail.Branch }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Loan Amount :</B> {{ inquiryDetail.LoanAmount }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Inquiry Status :</B> {{ inquiryDetail.Status }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Inquiry CreateOn :</B>
                    {{ inquiryDetail.CreateOn }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Inquiry AssignOn :</B>
                    {{ inquiryDetail.AssignedOn }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>TAT :</B> {{ inquiryDetail.TAT }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Purpose :</B> {{ inquiryDetail.Purpose }}</span
                  >
                </div>
                <div class="col-md-4">
                  <span class="fs-12">
                    <B>Next Followup Date :</B>
                    {{ inquiryDetail.NextFollowUp }}
                  </span>
                </div>
                <div class="col-md-4">
                  <span class="fs-12"
                    ><B>Hold Reason :</B> {{ inquiryDetail.Reason }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseInquiryModel()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="LoanFeeModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Loan Fee
        </h6>
        <button
          type="button"
          (click)="OnCloseFeeAdd()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Body Starts Here -->

        <div class="row m-0 mt-3">
          <div class="col-md-4">
            <div class="bg-success1">Charge Head</div>
          </div>
          <div class="col-md-4">
            <div class="bg-success1">Balance Amount</div>
          </div>
        </div>
        <!-- Copied Here-->
        <form #LF="ngForm" novalidate autocomplete="off">
          <div
            class="col-md-12 m-0 type-column mt-2"
            *ngFor="let chd of chargesHeadData; let i = index"
          >
            <div class="row feild">
              <ng-container>
                <div class="col-md-4">
                  <select
                    (change)="ValidateChargeHead(chd)"
                    (focusout)="
                      onChangeChargeHead(
                        chd.Id,
                        'Loan',
                        loanSummary.ProductId,
                        'CR',
                        loanSummary.ApplicationNo,
                        chd
                      )
                    "
                    #refChargeHead="ngModel"
                    class="form-control input-text-css"
                    name="chargeHead{{ i }}"
                    [(ngModel)]="chd.Id"
                    required
                    [ngClass]="{ 'alert-warning': refChargeHead.invalid }"
                  >
                    <option value="undefined" disabled>Select Option</option>
                    <option
                      *ngFor="let chargeHead of chargesHeadDropDownData"
                      [value]="chargeHead.Id"
                      [name]="chargeHead.Id"
                      [hidden]="
                        hiddenId.includes(chargeHead.Id) ? 'hidden' : ''
                      "
                    >
                      {{ chargeHead.ChagesHead }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <input
                    required
                    [min]="1"
                    [value]="0.0"
                    type="number"
                    name="Amount{{ i }}"
                    id="Amount{{ i }}"
                    (keyup)="onAmtChange(chd)"
                    [ngClass]="{ 'alert-warning': refAmt.invalid }"
                    #refAmt="ngModel"
                    class="form-control input-text-css right-text pr-3"
                    [(ngModel)]="chd.Balance"
                    (blur)="calculateGrandTotal()"
                  />
                </div>
              </ng-container>
              <button type="button" class="remove mx-1" (click)="removeData(i)">
                -
              </button>
              <button
                type="button"
                class="addmore mx-1"
                [disabled]="!!!chd.Id"
                (click)="addMoreData(chd)"
                *ngIf="i == currentIndex"
              >
                +
              </button>
            </div>
            <div class="row" *ngIf="chd.TaxData?.length > 0">
              <div class="col-md-12">
                <ng-container *ngFor="let tax of chd.TaxData; let j = index">
                  <div class="row">
                    <div class="col-md-4 my-1">
                      <input
                        type="text"
                        class="form-control input-text-css right-text pr-3"
                        disabled
                        [value]="tax.TaxType"
                      />
                    </div>
                    <div class="col-md-4 my-1">
                      <input
                        (blur)="calculateGrandTotal()"
                        type="number"
                        name="taxAmt{{ i }}-{{ j }}"
                        [value]="tax['ttAmt']"
                        [(ngModel)]="tax['ttAmt']"
                        class="form-control input-text-css right-text pr-3"
                      />
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!--ends-->

          <div class="col-md-12 row p-1 mt-2 pl-3 m-0" style="width: 71.5%">
            <div class="col-md-11 bg-success1 p-1">
              <div class="row">
                <div class="col-md-6 text-left">Grand Total :</div>
                <div class="col-md-6 text-left" style="padding-right: 52px">
                  <div>{{ grandTotal }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">Payment Mode</h1>
            </div>
          </div>
          <!--Payment-->
          <!-- Payment Mode-->
          <div class="row m-0 align-items-center">
            <div class="col-md-3">
              <span class="required-lable">Select Tag </span>
              <select
                #refTag="ngModel"
                [ngClass]="{ 'alert-warning': refTag.invalid }"
                (change)="onTagChange(paymentDetails['V_Tag'])"
                name="Voucher_Tag"
                id="Voucher_Tag"
                required
                class="form-control input-text-css"
                [(ngModel)]="paymentDetails['V_Tag']"
              >
                <option [value]="undefined" disabled>Select Tag</option>
                <option value="Bank">Bank</option>
                <option value="CASH">Cash</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="col-md-3">
              <span class="required-lable">Select Account</span>
              <select
                [ngClass]="{ 'alert-warning': refAcc.invalid }"
                #refAcc="ngModel"
                name="AccountId"
                id="AccountId"
                required
                class="form-control input-text-css"
                [(ngModel)]="paymentDetails['AccountId']"
              >
                <option [value]="undefined" disabled>Select Account</option>
                <option
                  *ngFor="let data of AccountDropDown"
                  [value]="data.AccountId"
                >
                  {{ data.Account_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-3" *ngIf="paymentDetails['V_Tag'] == 'Bank'">
              <span class="required-lable">Select Mode of Payment</span>
              <select
                [ngClass]="{ 'alert-warning': refMOD.invalid }"
                #refMOD="ngModel"
                name="Voucher_Mode_of_Payment"
                id="Voucher_Mode_of_Payment"
                required
                [(ngModel)]="paymentDetails['MOP']"
                class="form-control input-text-css"
              >
                <option [value]="undefined" disabled>
                  Select Mode Of Payment
                </option>
                <option value="Cheque">Cheque</option>
                <option value="RTGS">RTGS</option>
                <option value="Gateway">E-Gateway</option>
                <option value="NEFT">NEFT</option>
                <option value="IMPS">IMPS</option>
                <option value="Transfer">Transfer</option>
              </select>
            </div>
          </div>
          <!-- Based on  -->

          <div class="row m-0 mt-1">
            <ng-container *ngIf="paymentDetails['MOP'] == 'Cheque'">
              <div class="col-md-4">
                <span class="required-lable">Cheque No</span>
                <input
                  [ngClass]="{ 'alert-warning': refChNo.invalid }"
                  #refChNo="ngModel"
                  name="chequeNo"
                  type="text"
                  required
                  class="form-control input-text-css"
                  [(ngModel)]="paymentDetails['ChequeNo']"
                />
                <!-- [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Cheque_No.invalid, 'alert-warning': refVoucher_Cheque_No.invalid  && (refVoucher_Cheque_No.dirty || refVoucher_Cheque_No.touched || refVoucher_Cheque_No.untouched) }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No" name="Voucher_Cheque_No" id="Voucher_Cheque_No" -->
              </div>
              <div class="col-md-4">
                <span class="required-lable">Cheque Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="pickervcd"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_Cheque_Date"
                    [(ngModel)]="paymentDetails['ChequeDate']"
                    required
                    id="Voucher_Cheque_Date"
                    class="form-control input-text-css"
                    [ngClass]="{ 'alert-warning': refChDa.invalid }"
                    #refChDa="ngModel"
                  />
                  <!-- [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Cheque_Date.invalid, 'alert-warning': refVoucher_Cheque_Date.invalid  && (refVoucher_Cheque_Date.dirty || refVoucher_Cheque_Date.touched || refVoucher_Cheque_Date.untouched) }" -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervcd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervcd></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4">
                <span class="required-lable">Bank Name</span>
                <input
                  [ngClass]="{ 'alert-warning': refBN.invalid }"
                  #refBN="ngModel"
                  name="bankName"
                  type="text"
                  required
                  class="form-control input-text-css"
                  [(ngModel)]="paymentDetails['BankName']"
                />
                <!-- [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Bank_Name.invalid, 'alert-warning': refVoucher_Bank_Name.invalid  && (refVoucher_Bank_Name.dirty || refVoucher_Bank_Name.touched || refVoucher_Bank_Name.untouched) }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name" name="Voucher_Bank_Name" id="Voucher_Bank_Name" -->
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                paymentDetails['MOP'] == 'RTGS' ||
                paymentDetails['MOP'] == 'Gateway' ||
                paymentDetails['MOP'] == 'NEFT'
              "
            >
              <div class="col-md-4">
                <span class="required-lable">Ref No</span>
                <input
                  [ngClass]="{ 'alert-warning': refRef.invalid }"
                  #refRef="ngModel"
                  name="RefNo"
                  type="text"
                  required
                  class="form-control input-text-css"
                  [(ngModel)]="paymentDetails['RefNo']"
                />
                <!-- [ngClass]="{ 'is-invalid': df.submitted && refVoucher_E_Ref_No.invalid, 'alert-warning': refVoucher_E_Ref_No.invalid  && (refVoucher_E_Ref_No.dirty || refVoucher_E_Ref_No.touched || refVoucher_E_Ref_No.untouched) }"
                [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No" name="Voucher_E_Ref_No" id="Voucher_E_Ref_No" -->
              </div>
              <div class="col-md-4">
                <span class="required-lable">Transaction Date </span>
                <div class="datepicker_feild">
                  <input
                    [ngClass]="{ 'alert-warning': refTrD.invalid }"
                    #refTrD="ngModel"
                    name="TranDate"
                    [(ngModel)]="paymentDetails['TranDate']"
                    [matDatepicker]="pickervetd"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_E_Transaction_Date"
                    id="Voucher_E_Transaction_Date"
                    required
                    class="form-control input-text-css"
                  />

                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervetd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervetd></mat-datepicker>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- ends here payment -->

          <div class="row m-0 mt-1">
            <div class="col-md-12">
              <span class="">Remark</span>
              <input
                [(ngModel)]="paymentDetails['remark']"
                type="text"
                name="Voucher_Narration"
                id="Voucher_Narration"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-2 justify-content-end">
            <!--<button type="button" class="ml-4 btn btn-light font-size-12">Cancel</button>-->

            <button
              type="button"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              (click)="onSaveLoginFee()"
              [disabled]="LF.form.invalid"
              mat-raised-button
              [color]="LF.form.invalid ? 'secondary' : 'primary'"
            >
              Save
            </button>
          </div>
        </form>

        <div class="row m-0 mt-2 col-md-12 p-0" [hidden]="!dataSource">
          <div class="col-md-12">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSource"
                matTableExporter
                #exporter="matTableExporter"
                style="height: 290px; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="Vid">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Voucher_Type">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Voucher Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voucher_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Voucher_Sub_Type">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Voucher_Sub_Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voucher_Sub_Type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Voucher_Tag">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Voucher_Tag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voucher_Tag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Voucher_Date">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Voucher_Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voucher_Date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ChagesHead">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >ChagesHead</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ChagesHead }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Account_Name">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Account_Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Account_Name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="IsTax">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >IsTax</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.IsTax == 1 ? "True" : "False" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Tax_Per">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Tax_Per</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Tax_Per }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Amount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Status">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Reason">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Reason</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Reason }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CreatedBy">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >CreatedBy</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CreatedBy }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  [ngClass]="row.Status == 'Rejected' ? 'Rejected' : ''"
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <div class="row m-0 align-items-center">
                <!--<div class="col-md-4">
                  <button class="btn btn-success font-size-12" (click)="exportTable(exporter)">
                    <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
                  </button>
                </div>-->
                <div class="col-md-8">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="chatModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">Chats</h6>
        <button
          type="button"
          (click)="onCloseChatModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="table-responsive mt-1">
            <mat-table
              [dataSource]="dataSourceChat"
              style="height: 250px; width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell mw50"
                  >{{ i + 1 }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="Sender">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Sender</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Sender
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="SendDate">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Send Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.SendDate
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Receiver">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Receiver</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Receiver
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="DateRead">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Date Read</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.DateRead
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="MSG" style="min-width: 100px">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Message</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <div style="text-align: justify; text-justify: inter-word">
                    {{ row.MSG }}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Document_Attachment">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  ><i class="fa fa-paperclip"></i
                ></mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <div *ngIf="!!row.Document_Attachment">
                    <a
                      target="_blank"
                      href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                        row.Document_Attachment
                      }}"
                    >
                      <i class="fa fa-eye btn"></i>
                    </a>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedTableColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedTableColumns"
              ></mat-row>
            </mat-table>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <button
              class="mt-2 btn font-size-12 button-btn ml-3"
              (click)="exportChatExcel()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="export-chat"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sender
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Send Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Receiver
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Date Read
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Message
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Sender }}</td>
    <td>{{ x.SendDate }}</td>
    <td>{{ x.Receiver }}</td>
    <td>{{ x.DateRead }}</td>
    <td>{{ x.MSG }}</td>
  </tr>
</table>
