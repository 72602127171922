<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #f="ngForm" (keydown.enter)="DepositList()" novalidate>
    <div class="row m-0">
      <div class="col-md-12 p-1 text-right">
        <button
          type="button"
          class="btn_default"
          mat-raised-button
          (click)="AddNewFDRD()"
        >
          Add New Deposit
        </button>
      </div>
    </div>

    <div class="row m-0">
      <div class="col-md-3">
        <span>Customer Name</span>
        <input
          name="CustomerName"
          id="CustomerName"
          [(ngModel)]="Customer.CustomerName"
          placeholder="Customer Name"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Customer Phone</span>
        <input
          name="CustomerPhone"
          id="CustomerPhone"
          [(ngModel)]="Customer.CustomerPhone"
          placeholder="Customer Phone"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>PAN Card</span>
        <input
          name="PANCard"
          id="PANCard"
          [(ngModel)]="Customer.PANCard"
          placeholder="PAN Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Passport No.</span>
        <input
          name="Passport"
          id="Passport"
          [(ngModel)]="Customer.Passport"
          placeholder="Passport No."
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-3">
        <span>Voter ID Card</span>
        <input
          name="VoterID"
          id="VoterID"
          [(ngModel)]="Customer.VoterID"
          placeholder="Voter ID Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Driving License</span>
        <input
          name="DL"
          id="DL"
          [(ngModel)]="Customer.DL"
          placeholder="Driving License"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Aadhaar Card</span>
        <input
          name="Aadhaar"
          id="Aadhaar"
          [(ngModel)]="Customer.Aadhaar"
          placeholder="Aadhaar Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>FDRD No</span>
        <input
          name="FDRDNo"
          id="FDRDNo"
          [(ngModel)]="Customer.FDRDNo"
          placeholder="FDRD No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DepositFrom"
            id="DepositFrom"
            [(ngModel)]="Customer.DepositFrom"
            class="form-control input-text-css"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="DepositFrom"
          id="DepositFrom"
          [(ngModel)]="Customer.DepositFrom"
          placeholder="Date From"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
      <div class="col-md-3">
        <span>Date To</span>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DepositTo"
            id="DepositTo"
            [(ngModel)]="Customer.DepositTo"
            class="form-control input-text-css"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="DepositTo"
          id="DepositTo"
          [(ngModel)]="Customer.DepositTo"
          placeholder="Date To"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
      <div class="col-md-3">
        <span> Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          [(ngModel)]="Customer.BranchId"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Close Case</span>
        <select
          name="CloseCase"
          id="CloseCase"
          [(ngModel)]="Customer.CloseCase"
          class="form-control input-text-css"
        >
          <option value="">Select Close Case</option>
          <option value="1">Close Case</option>
        </select>
      </div>

      <div class="col-md-1">
        <button
          type="button"
          (click)="DepositList()"
          class="mt-3 btn font-size-12 button-btn"
          mat-raised-button
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0 col-md-12 p-0" *ngIf="dataSource">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="height: 220px; max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Deposit_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Deposit_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Deposit Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Deposit_Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Deposit_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >FDR No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Deposit_No }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_of_FDRD">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Date Of FDR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Date_of_FDRD }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              CustomerName
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Deposit_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Deposit_Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Interest Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Interest_Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Maturity_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Maturity Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Maturity_Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_of_Maturity">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Date Of Maturity</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Date_of_Maturity }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Auto_Renewal">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Auto Renewal</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Auto_Renewal }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right"
                (click)="goToDetail(row)"
                style="cursor: pointer; color: green; font-size: 16px"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
