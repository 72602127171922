import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import * as moment from 'moment';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
@Component({
  selector: 'app-employee_assign_branch',
  templateUrl: './employee_assign_branch.component.html',
  styleUrls: ['./employee_assign_branch.component.scss']
})
export class EmployeeAssignBranchComponent implements OnInit {
  private data: any; showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();
  SelectAll: boolean = false;
  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = "0";
  popupValue: any;
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  dataSource: any;
  dataSourceBranchAssign: any;
  displayedColumns: any = [];
  selectedBranchId: any;
  SelectBranch: any = '';
  AssignBranchList: any;
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  isEdit: boolean = false;
  SelectedEmpIds: any = '';
  isDisable: boolean = true;

  ShowFilter = true;

  limitSelection = false;

  RoleDropdown: any[] = [];

  BaseBranchesList: any[] = [];
  BaseBranchId: any = '';
  SelectRoleId: any = '';
  EmpManagerId: any;
  EmployeeName: any;
  GroupId: any = '';
  Ischecked: boolean = false;
  groupDropdown: any[] = [];
  IsCreateEmployee: boolean = true;

  DepartmentList: any[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    private lmsService: LmsService,
  ) {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Employee Register');

    this.EmployeeId = sessionStorage.getItem('getEmployeeID');
    if (this.EmployeeId != "0") {
      this.isEdit = true;
      this.IsCreateEmployee = false;
      this.Get_Master();
      this.getEmployeeData();
    }


    this.Get_Acc_GroupDropdown();
    this.getnew_branchList();

  }
  getnew_branchList() {

    this._MasterService.Get_Employee_Branch_NotAssigned_List({ EmpId: this.EmployeeId}).subscribe((res: any) => {
      this.AssignBranchList = res;
    });
  }
  Get_Master() {
    this.showSpinner = true;
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });
    this._MasterService.ReportingManagerForDropdown(this.ReportingManagerRequestModel).subscribe((result) => {
      this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getEmployeeData() {
    this.BranchesList = [];
    this.RequestModel.EmpId = this.EmployeeId;
    this._MasterService.GetEmployeeDetails(this.RequestModel).subscribe((result) => {
      //console.log("RESULT :",result);

      this.EmployeeDetailData = result;
      if (this.EmployeeDetailData.Item1[0]) {

        this.BaseBranchesList = this.EmployeeDetailData.Item2;
        this.EmployeeName = this.EmployeeDetailData.Item1[0].Emp_FirstName + ' ' + this.EmployeeDetailData.Item1[0].Emp_LastName;
        // this.LastName = this.EmployeeDetailData.Item1[0].Emp_LastName;
        this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
        this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;

      }


      this.BranchesList = this.EmployeeDetailData.Item2;
      this.BranchesList.map((item) => {
        item.isActiveSelect = item.BranchAlradyAssigned;
        item.isDisable = item.BranchAlradyAssigned;
      });




    });
  }







  onChangeAssignBranch(event: MatCheckboxChange, selectedBranchId) {
    this.BranchesList.map((item) => {
      if (item.BranchId == selectedBranchId) {
        item.isActiveSelect = event.checked;
      }
    });
  }


  OnClose() {
    this._Route.navigate(['employee/list']);
  }



  onSaveEmployeeDetailData() {

    this.BranchesList.map((item) => {
      // if (item.BranchAlradyAssigned == true) {
      this.SelectedBranchesData.push({
        "Id": item.EmpBranchId,
        "EmpBranch_EmpId": this.EmployeeId,
        "EmpBranch_BranchId": item.BranchId,
        "EmpBranch_IsActive": item.isActiveSelect == true ? 1 : 0,
        "EmpBranch_CreateBy": this.data.userId,
        "EmpBranch_ModifyBy": this.data.userId
      });
      // }
    });

    //this.LoanProcessList.map((item) => {
    //  // if (item.ProcessAlradyAssigned == true) {
    //  this.SelectedLoanProcessData.push({
    //    "Id": item.EmpProcessId,
    //    "EmpProcess_EmpId": parseInt(this.EmployeeId),
    //    "EmpProcess_ProcessId": item.ProcessId,
    //    "EmpProcess_IsActive": item.isActiveSelect == true ? 1 : 0,
    //    "EmpProcess_CreateBy": this.data.userId,
    //    "EmpProcess_ModifyBy": this.data.userId
    //  });
    //  // }
    //});
    //console.log('DeptId', DeptId);
    let saveEmployeeDetail = {
      "Employee": {
        "EmpId": this.EmployeeId
      },
      //"Employee": {
      //  "EmpId": parseInt(this.EmployeeId),
      //  "Emp_FirstName": this.FirstName,
      //  "Emp_LastName": this.LastName,
      //  "Emp_Gender": this.SelectGender,
      //  "Emp_DOB": moment(this.DateOfBirth).format('YYYY-MM-DD'),
      //  "Emp_PhoneNo": this.PhoneNo,
      //  "Emp_PhoneNo1": this.AlternetPhoneNo,
      //  "Emp_RoleId": parseInt(this.SelectRoleId),
      //  "Emp_DepartmentId": DeptId, //parseInt(this.SelectDeptId),
      //  "Emp_Email": this.EnterEmail,
      //  "Emp_LoginId": this.EmployeeLoginId,
      //  "Emp_Password": this.EmployeePassword,
      //  "Emp_PresentAddress": this.PresentAddress,
      //  "Emp_PermanentAddress": this.PermanentAddress,
      //  "Emp_AadharNo": this.AadharNo,
      //  "Emp_Image": this.profilePicture != '' ? this.CurrentDate.getTime() + '_' + this.UploadImageName : this.UploadImageName,
      //  "Emp_ManagerId": parseInt(this.EmpManagerId),
      //  "Emp_Createby": this.data.userId,
      //  "Emp_ModifyBy": this.data.userId,
      //  "Base_BranchId": this.BaseBranchId,
      //  "GroupId": this.GroupId,
      //  "Emp_PAN_No": this.Emp_PAN_No,
      //  "Emp_Code": this.Emp_Code
      //},
      "EmployeeBranch": this.SelectedBranchesData,
      // "EmployeeProcess": this.SelectedLoanProcessData
    }


    this.SaveEmployeeDataRequestModel.JSON = JSON.stringify(saveEmployeeDetail);

    this._MasterService.SaveEmployeDetails(this.SaveEmployeeDataRequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        //  this.OnClose();
        this.getEmployeeData();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  onDeleteAssignBranch(selectedBranchId) {
    this.popupValue = 'Delete';
    //this.EmpDeleteAssBranchRequestModel.EmpBranchId = selectedBranchId;
    //this._MasterService.DeleteEmployeeBranch(this.EmpDeleteAssBranchRequestModel).subscribe((response) => {
    //  if (response[0].CODE == 1) {
    //    this.BranchesList.map((item) => {
    //      if (item.EmpBranchId == selectedBranchId) {
    //        item.isDisable = false;
    //        item.BranchAlradyAssigned = false;
    //        item.isActiveSelect = false;
    //      }
    //    });
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
    //  }
    //});
    this.selectedBranchId = selectedBranchId;
    //this.SelectedEmpIds = this.EmployeeId;
    this._MasterService.Get_Hierarchy_Upper_Lower({ LoginUserId: this.EmployeeId, Type: 'Lower', BranchId: selectedBranchId }).subscribe((response: any) => {
      if (response.length > 0) {
        var empIds = JSON.parse(JSON.stringify(response));
        empIds.map((item) => {
          this.SelectedEmpIds = this.SelectedEmpIds+','+item.EmpId ;

        })
        this.displayedColumns = ["Emp_Name", "Designation"];
        this.dataSource = JSON.parse(JSON.stringify(response));
        $('#DeleteBranchModel').modal('show');
        $("#DeleteBranchModel").css("z-index", "1050");
      }
      else {
        this.DeleteAllAssignmentBranch();
      }
    })

  }
  onCloseDeleteBranchModel() {
    this.selectedBranchId = null;
    this.SelectedEmpIds = '';
    $('#DeleteBranchModel').modal('hide');
  }


  onChangeCreateEmpAcc(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.Ischecked = true;
    } else {
      this.Ischecked = false;
    }
  }

  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  DeleteAllAssignmentBranch() {

    this._MasterService.Delete_Employee_Assign_Branch({ EmpIds: this.SelectedEmpIds, currentEmpId: this.EmployeeId, BranchId: this.selectedBranchId }).subscribe((response) => {
      if (response[0].CODE == 1) {
        this.BranchesList.map((item) => {
          if (item.BranchId == this.selectedBranchId) {
            item.isDisable = false;
            item.BranchAlradyAssigned = false;
            item.isActiveSelect = false;
            this.onCloseDeleteBranchModel();
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
  onNewBranchAssign() {
    this.getnew_branchList();
    this._MasterService.Get_Hierarchy_Upper_Lower({ LoginUserId:this.EmployeeId, Type: 'Upper', BranchId: 0 }).subscribe((response: any) => {
      if (response.length > 0) {
        var empIds = JSON.parse(JSON.stringify(response));
        empIds.map((item) => {
          this.SelectedEmpIds = this.SelectedEmpIds + ','+item.EmpId;

        })
        this.displayedColumns = ["Emp_Name", "Designation"];
        this.dataSourceBranchAssign = JSON.parse(JSON.stringify(response));

      }
    })
    this.SelectBranch = '';
    $('#AssignBranchModel').modal('show');
    $("#AssignBranchModel").css("z-index", "1050");
    console.log("SelectedEmpIds", this.SelectedEmpIds);
  }
  onCloseAssignBranchModel() {
    this.getnew_branchList();
    this.SelectedEmpIds = '';
    this.SelectBranch = '';
    $('#AssignBranchModel').modal('hide');

  }
  BranchAssignment() {
    $('#AssignBranchModel').modal('hide');
    this._MasterService.Save_Upper_Employee_BranchAssign({ EmpIds: this.SelectedEmpIds, currentEmpId: this.EmployeeId, BranchId: this.SelectBranch, LoginUserId: this.data.userId }).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.SelectedEmpIds = '';
        this.SelectBranch = '';
        this.getEmployeeData();
        this.getnew_branchList();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });

      } else {
        this.SelectedEmpIds = '';
        this.SelectBranch = '';
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });

  }

  onChangeSelectAll() {
    for (let i = 0; i < this.BranchesList.length; i++) {
      if (!this.BranchesList[i].isDisable) {
        this.BranchesList[i].isActiveSelect = this.SelectAll;
      }

    }
  }
}
