import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { constantUrl } from "../../Shared/constantUrl";
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
} from "@angular/forms";
declare var $: any;

@Component({
  selector: "app-mst-partner",
  templateUrl: "./mst-partner.component.html",
  styleUrls: ["./mst-partner.component.scss"],
})
export class MstPartnerComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  dataSource: any;
  loading: boolean = false;
  AccountHeadDropdown: any[] = [];
  BranchList: any[] = [];
  displayedColumns: string[] = [
    "PartnerId",
    "Partner",
    "Type",
    "MobileNo",
    "Address",
    "PartnerBank",
    "AccountNo",
    "Edit",
    "Delete",
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  PartnerList: any = [];
  partnerModel: any = {};
  currentUser: any;
  groupDropdown: any[] = [];
  IsCreateAccount: boolean = true;
  stateDropdown: any[] = [];
  districkDropdown: any[] = [];
  Ischecked: boolean = false;
  tehasilDropdown: any[] = [];
  PartnerAttachment: any = [{}];
  DocModel: any[] = [];
  today: Date = new Date();
  AllowExtension: any = constantUrl.AllowExtension;
  docBaseUrl: any = constantUrl.OthersDocUrl;
  form: FormGroup;
  GetBranchesRequestModel: RequestModel = new RequestModel();
  BranchForm: UntypedFormGroup;
  UserDropdownSettings: any = {};
  branchIDs: any[] = [];
  Branch: any;
  SelectBranchId: any = "";
  ShowFilter = true;
  UpdateBranch: any;
  userFormUpdate: UntypedFormGroup;
  branch_name: any[];

  constructor(
    private lmsService: LmsService,
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private fb: UntypedFormBuilder
  ) {
    this._MasterService = MasterService;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.form = this.fb.group({
      Branch: [null],
    });
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Partner List");
    this.RequestModel.PartnerType = "";
    this.getPartnerList();
    // this.OnOpenPartnerModal();

    this.Get_Acc_GroupDropdown();
    this.GetBranchDropdown();
    // this.BranchForm = this.fb.group({
    //   multiSelectBranchName: [],
    // });
    this.userFormUpdate = this.fb.group({
      UpdateBranch: [[], this.minSelectedValidator(1)],
    });
    this.UserDropdownSettings = {
      singleSelection: false,
      idField: "BranchId",
      textField: "Branch_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };
  }

  minSelectedValidator(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value.length >= min) {
        return null;
      }
      return { minSelected: true };
    };
  }

  OnUserSelect(e) {
    this.branchIDs.push({ BranchId: Number(e.BranchId) });
    console.log("on select one", this.branchIDs);
  }

  OnUserDeSelect(e) {
    let index = this.branchIDs.findIndex(
      (item) => item.BranchId === e.BranchId
    );
    // this.empIDs.indexOf(e["EmpId"]);
    this.branchIDs.splice(index, 1);
    console.log("on deslect one", this.branchIDs);
  }

  OnSelectAll(_$event) {
    this.branchIDs = [];
    this.BranchList.forEach((x) => {
      this.branchIDs.push({ BranchId: x.BranchId });
    });
    console.log("select all", this.branchIDs);
  }

  OnDeSelectAll(_$event) {
    this.branchIDs = [];
    console.log("deselect all", this.branchIDs);
  }

  // OnBranchSelect(item: any) {
  //   this.SelectBranchId = this.Branch.map((element) => element.BranchId).join(
  //     ","
  //   );
  //   console.log("SelectBranchId", this.SelectBranchId);
  // }
  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  getPartnerList() {
    this.showSpinner = true;
    this._MasterService
      .Get_PartnerList(this.RequestModel)
      .subscribe((result: any) => {
        this.PartnerList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.PartnerList);
        console.log("Data Source", this.dataSource);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
        this.get_Acc_AccountList();
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `partner-${this.RequestModel.PartnerType}-list`,
      sheet: "Partner",
    });
  }

  OnClose() {
    this.partnerModel = {};
    $("#partnerModel").modal("hide");
  }
  onDeletePartner(data: any, e: any = null) {
    if (e) {
      e.stopPropagation();
    }
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this._MasterService
            .Delete_Partner({
              PartnerId: data.PartnerId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.getPartnerList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  GetStateDropdown() {
    this._MasterService.GetState().subscribe((res: any) => {
      this.stateDropdown = res;
      this.GetDistrickDropdown();
    });
  }
  GetDistrickDropdown() {
    this.RequestModel.StateID = this.partnerModel.State;
    this.RequestModel.StateId = this.partnerModel.State;
    this._MasterService
      .GetDistricts(this.RequestModel)
      .subscribe((res: any) => {
        this.districkDropdown = res;
        this.GetTehasilDropdown();
      });
  }
  GetTehasilDropdown() {
    if (this.partnerModel.District_Id != "") {
      this.RequestModel.DistrictId = this.partnerModel.District_Id;
      this._MasterService.GetTahsil(this.RequestModel).subscribe((res: any) => {
        this.tehasilDropdown = res;
      });
    }
  }

  GetBranchDropdown() {
    this.branchIDs = [];
    this.branch_name = [];
    this._MasterService
      .GetBranchesDropdown(this.GetBranchesRequestModel)
      .subscribe((result) => {
        this.BranchList = JSON.parse(JSON.stringify(result));
        // this.Branch = result;
        console.log("Branch List", this.BranchList);
      });
  }

  onSavePartner() {
    this.PartnerAttachment.splice(this.PartnerAttachment.length - 1, 1);
    console.log("this.PartnerAttachment", this.PartnerAttachment);
    this.UploadPartnerDoc();
    this.loading = true;
    this.partnerModel.Partner_AccountId = Number(
      this.partnerModel.Partner_AccountId
    );

    var finalBranches = [];
    this.partnerModel.UpdateBranch.forEach((obj: any) => {
      if (obj.BranchId != 0 && obj.BranchId != "0") {
        if (finalBranches.length > 0) {
          var _find = finalBranches.find((x) => x.BranchId == obj.BranchId);
          if (!_find) {
            finalBranches.push(obj);
          }
        } else {
          finalBranches.push(obj);
        }
      }
    });
    // console.log("finalBranchesfinalBranches", finalBranches);
    this.partnerModel.Partner_Branches = finalBranches
      .map((element) => element.BranchId)
      .join(",");

    this.partnerModel.GroupId = Number(this.partnerModel.GroupId);
    console.log("partner model on save", this.partnerModel);
    this._MasterService
      .Save_Partner({
        JSON: JSON.stringify({
          Partner: this.dataSharingService.cleanObject(this.partnerModel),
          Attachment: this.dataSharingService.cleanObject(
            this.PartnerAttachment
          ),
        }),
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.getPartnerList();
          this.PartnerAttachment = [];
          this.PartnerAttachment.push({});
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onChangeCreateAcc(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.Ischecked = true;
    } else {
      this.Ischecked = false;
    }
  }
  fileChangeListenerAttachment(files: any, input: any) {
    if (!this.PartnerAttachment[this.PartnerAttachment.length - 1].Title) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter  title.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }

    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.PartnerAttachment.find((obj) => {
      return obj.DocFileName == _fn;
    });

    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same file can not be allow again.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }

    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.PartnerAttachment[this.PartnerAttachment.length - 1].DocFileName =
      _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;

    reader.readAsDataURL(files[0]);
    input.value = "";
    this.PartnerAttachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };
  }
  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.PartnerAttachment.splice(i, 1);
  }
  UploadPartnerDoc() {
    this.showSpinner = true;
    for (let i = 0; i < this.DocModel.length; i++) {
      this.lmsService.UploadOthersDoc(this.DocModel[i]).subscribe(
        (res: any) => {},
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }

  OnOpenPartnerModal(data: any, e: any = null) {
    if (e) {
      e.stopPropagation();
    }
    if (data.PartnerId) {
      this._MasterService
        .Get_PartnerDetail({ PartnerId: data.PartnerId })
        .subscribe((res: any) => {
          if (res.Item1.length > 0) {
            this.partnerModel = res.Item1[0];
            console.log(
              "Partner Model Partner Banches",
              this.partnerModel.Partner_Branches
            );
            this.PartnerAttachment = res.Item2;

            this.partnerModel.LoginUserId = this.currentUser.userId;

            this.partnerModel.State = this.partnerModel.StateId;
            this.GetStateDropdown();
            this.partnerModel.Tehsil_Id = this.partnerModel.TehsilId;
            this.partnerModel.District_Id = this.partnerModel.DistrictId;
            this.partnerModel.GroupId = this.partnerModel.GroupId;

            if (this.partnerModel.Partner_Branches != null) {
              var branches = this.partnerModel.Partner_Branches?.split(",");
              var arr = [];
              if (branches.length > 0) {
                branches.forEach((obj: any) => {
                  this.branchIDs.push({ BranchId: Number(obj) });
                  arr.push(
                    this.BranchList.filter((item) => item.BranchId == obj)[0]
                  );
                });
              }
              this.partnerModel.UpdateBranch = arr;
            }
            console.log("this.branchIDs", this.branchIDs);
            this.partnerModel.Partner_AccountId = Number(
              this.partnerModel.Partner_AccountId
            );
            if (Number(this.partnerModel.Partner_AccountId) > 0) {
              this.IsCreateAccount = false;
              this.Ischecked = true;
            } else {
              this.Ischecked = false;
            }

            $("#View").modal("show");
            $("#View").css("z-index", "1050");
          }
        });
    } else {
      this.partnerModel.PartnerId = 0;
      this.partnerModel.LoginUserId = this.currentUser.userId;
      this.partnerModel.Partner_Type = "";
      this.partnerModel.Tehsil_Id = "";
      this.partnerModel.District_Id = "";
      this.partnerModel.State = 29;
      this.partnerModel.Partner_AccountId = 0;
      this.partnerModel.GroupId = 0;
      // this.partnerModel.Branch = "";
      this.GetStateDropdown();
      this.PartnerAttachment = [];
      this.PartnerAttachment.push({});
      $("#partnerModel").modal("show");
      $("#partnerModel").css("z-index", "1050");
    }
  }
  edit() {
    $("#View").modal("hide");
    this.PartnerAttachment.push({});
    // this.branchIDs = [];

    $("#partnerModel").modal("show");
    $("#partnerModel").css("z-index", "1050");
  }
  ViewData() {
    $("#View").modal("show");
    $("#View").css("z-index", "1050");
  }

  onCloseView() {
    $("#View").modal("hide");
  }

  get_Acc_AccountList() {
    this.lmsService.Get_Account_Master({}).subscribe((res: any) => {
      this.AccountHeadDropdown = res;
    });
  }
  Get_Group() {
    this.partnerModel.GroupId = this.AccountHeadDropdown.find((obj: any) => {
      return obj.AccountId == this.partnerModel.Partner_AccountId;
    }).GroupId;
  }
}
