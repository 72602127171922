import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { QuickLoanService } from 'src/app/_QuickLoan/services/quickLoan.service';

@Component({
  selector: 'app-rbi_dnb2-report',
  templateUrl: './rbi_dnb2-report.component.html',
  styleUrls: ['./rbi_dnb2-report.component.scss']
})
export class RBIDNB2ReportComponent implements OnInit {
  showSpinner: boolean;
  constructor(private _QuickLoanService:QuickLoanService) { }

  ngOnInit(): void {
   
   
  }

}
