import { Component, Input, OnInit } from '@angular/core';
import { constantUrl } from '../../../Shared/constantUrl';
@Component({
  selector: 'app-FI-Question-subcard-info',
  templateUrl: './FI-Question-subcard-info.component.html',
  styleUrls: ['./FI-Question-subcard-info.component.scss']
})
export class FIQuestionSubcardInfoComponent implements OnInit {
  @Input() FIQuestionData: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
