<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #f="ngForm"
    (keydown.enter)="LoanList()"
    novalidate
    class="row m-0 col-md-12 p-0"
  >
    <form #df="ngForm" novalidate>
      <div class="row m-0 col-md-12 p-0 formborder">
        <div class="col-md-2">
          <span>Loan No.</span>
          <input
            name="LoanNo"
            [(ngModel)]="Customer.LoanNo"
            id="LoanNo"
            placeholder="Loan No."
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Application No.</span>
          <input
            name="ApplicationNo"
            [(ngModel)]="Customer.ApplicationNo"
            id="ApplicationNo"
            placeholder="Application No."
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Customer Name</span>
          <input
            name="CustomerName"
            [(ngModel)]="Customer.CustomerName"
            id="CustomerName"
            placeholder="Customer Name"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Customer Phone</span>
          <input
            name="CustomerPhone"
            [(ngModel)]="Customer.CustomerPhone"
            id="CustomerPhone"
            placeholder="Customer Phone"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>PAN Card</span>
          <input
            name="PANCard"
            [(ngModel)]="Customer.PANCard"
            id="PANCard"
            placeholder="PAN Card"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Passport No.</span>
          <input
            name="Passport"
            [(ngModel)]="Customer.Passport"
            id="Passport"
            placeholder="Passport No."
            class="form-control input-text-css"
          />
        </div>

        <div class="col-md-2">
          <span>Voter ID Card</span>
          <input
            name="VoterID"
            [(ngModel)]="Customer.VoterID"
            id="VoterID"
            placeholder="Voter ID Card"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Driving License</span>
          <input
            name="DrivingLicence"
            [(ngModel)]="Customer.DrivingLicence"
            id="DL"
            placeholder="Driving License"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Aadhaar Card</span>
          <input
            name="Aadhaar"
            [(ngModel)]="Customer.Aadhaar"
            id="Aadhaar"
            placeholder="Aadhaar Card"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Vehicle No</span>
          <input
            name="VehicleNo"
            [(ngModel)]="Customer.VehicleNo"
            id="VehicleNo"
            placeholder="Vehicle No"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-2">
          <span>Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="LoanDateFrom"
              id="LoanDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="Customer.LoanDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2">
          <span>Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker2"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="LoanDateTo"
              id="LoanDateTo"
              class="form-control input-text-css"
              [(ngModel)]="Customer.LoanDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
        </div>

        <div class="col-md-4">
          <span>Select Type</span>
          <select
            name="Type"
            id="Type"
            class="form-control input-text-css"
            [(ngModel)]="SelectType"
            (change)="SearchChange()"
          >
            <option value="">Select Type</option>
            <option value="Branch">Branch</option>
            <option value="CollArea">Collection Area</option>
            <option value="CollSubArea">Collection Sub Area</option>
            <option value="CollExecutive">Collection Executive</option>
            <option value="SalesExecutive">Sales Executive</option>
            <option value="EngineNo">Engine No.</option>
            <option value="ChassisNo">Chassis No.</option>
            <option value="LoanAccCatg">Loan A/C Category</option>
          </select>
        </div>

        <div class="col-md-4" *ngIf="SelectType != ''">
          <div *ngIf="SelectType == 'Branch'">
            <span> Branch</span>
            <select
              name="BranchId"
              id="BranchId"
              #refSelectGender="ngModel"
              [(ngModel)]="Customer.BranchId"
              class="form-control input-text-css"
            >
              <option value="">Select Branch</option>
              <option
                *ngFor="let branch of BranchesList"
                [value]="branch.BranchId"
              >
                {{ branch.Branch_Name }}
              </option>
            </select>
          </div>

          <div *ngIf="SelectType == 'EngineNo'">
            <span>Engine No.</span>
            <input
              name="EngineNo"
              [(ngModel)]="Customer.EngineNo"
              id="EngineNo"
              placeholder="Engine No."
              class="form-control input-text-css"
            />
          </div>

          <div *ngIf="SelectType == 'ChassisNo'">
            <span>Chassis No.</span>
            <input
              name="ChassisNo"
              [(ngModel)]="Customer.ChassisNo"
              id="ChassisNo"
              placeholder="Chassis No."
              class="form-control input-text-css"
            />
          </div>

          <div *ngIf="SelectType == 'CollArea'">
            <span> Collection Area</span>
            <select
              name="Area"
              id="Area"
              [(ngModel)]="Customer.Area"
              class="form-control input-text-css"
            >
              <option value="">Select Collection Area</option>
              <ng-container *ngFor="let area of areaData">
                <option
                  *ngIf="area.Type.trim() == 'Collection Area'"
                  [value]="area.Int_Id"
                >
                  {{ area.Area_Name }}
                </option>
              </ng-container>
            </select>
          </div>

          <div *ngIf="SelectType == 'CollSubArea'">
            <span> Collection Sub Area</span>
            <select
              name="SubArea"
              id="SubArea"
              [(ngModel)]="Customer.SubArea"
              class="form-control input-text-css"
            >
              <option value="">Select Collection SubArea</option>
              <option
                *ngFor="let subarea of subAreaData"
                [value]="subarea.Int_Id"
              >
                {{ subarea.Sub_Area_Name }}
              </option>
            </select>
          </div>

          <div *ngIf="SelectType == 'SalesExecutive'">
            <span> Sales Executive</span>
            <select
              name="SalesEx"
              id="SalesEx"
              [(ngModel)]="Customer.SalesEx"
              class="form-control input-text-css"
            >
              <option value="">Select Sales Executive</option>
              <option
                *ngFor="let SalesExec of SalesExecutiveData"
                [value]="SalesExec.EmpId"
              >
                {{ SalesExec.EmpName }}
              </option>
            </select>
          </div>

          <div *ngIf="SelectType == 'CollExecutive'">
            <span> Collection Executive</span>
            <select
              name="CollectionEx"
              id="CollectionEx"
              [(ngModel)]="Customer.CollectionEx"
              class="form-control input-text-css"
            >
              <option value="">Select Collection Executive</option>
              <option
                *ngFor="let CollExec of CollectionExecutiveData"
                [value]="CollExec.EmpId"
              >
                {{ CollExec.Emp_FirstName }}
              </option>
            </select>
          </div>

          <div *ngIf="SelectType == 'LoanAccCatg'">
            <span>Accounting Category </span>
            <select
              name="LoanAccountingCatId"
              id="LoanAccountingCatId"
              [(ngModel)]="Customer.LoanAccountingCatId"
              class="form-control input-text-css"
            >
              <option value="">Select Accounting Category</option>
              <option
                *ngFor="let AcCat of AccCatDropdown"
                [value]="AcCat.Loan_CategoryId"
              >
                {{ AcCat.Loan_category }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2" [hidden]="!dataSourceLoan">
          <span class="required-lable">Select Swap Type</span>
          <select
            name="SwapType"
            id="SwapType"
            class="form-control input-text-css"
            [(ngModel)]="SwapType"
            required
            #refSwapType="ngModel"
            (change)="ChangeSwapType()"
            [ngClass]="{
              'is-invalid': df.submitted && refSwapType.invalid,
              'alert-warning':
                refSwapType.invalid &&
                (refSwapType.dirty ||
                  refSwapType.touched ||
                  refSwapType.untouched)
            }"
          >
            <option value="">Select Swap Type</option>
            <option value="Branch">Branch</option>

            <option value="LoanAccCatg">Loan A/C Category</option>
          </select>
        </div>

        <div class="col-md-2">
          <button
            type="button"
            (click)="LoanList()"
            class="mt-3 btn font-size-12 button-btn"
          >
            Search
          </button>
        </div>
      </div>

      <div class="table-responsive mt-2" [hidden]="!dataSourceLoan">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Select">
            <mat-header-cell
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 50px"
            >
              <mat-checkbox
                name="Select"
                id="Select"
                [(ngModel)]="SelectAll"
                (change)="checkUncheckAll($event)"
                [checked]="IsSelected"
                *ngIf="SwapType == 'Branch'"
              ></mat-checkbox>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px"
            >
              <div *ngIf="SwapType == 'Branch'; else elseBlock">
                <mat-checkbox
                  class="example-margin"
                  name="IsSelected{{ i }}"
                  id="IsSelected{{ i }}"
                  [(ngModel)]="row.IsSelected"
                  [checked]="row.IsSelected"
                ></mat-checkbox>
              </div>
              <ng-template #elseBlock>
                <mat-checkbox
                  class="example-margin"
                  name="IsSelected{{ i }}"
                  id="IsSelected{{ i }}"
                  [(ngModel)]="row.IsSelected"
                  [checked]="LoanId == row.LoanIdentity"
                  (change)="OnCheckSwapChechBox($event, row)"
                  [disabled]="!SwapType"
                ></mat-checkbox>
              </ng-template>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Acc_Category">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Acc Category</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Acc_Category }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="LoanAMount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAMount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSourceLoan ? totaltypewise("LoanAMount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanCreatedOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanCreatedOn }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsLoan; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>

        <div
          class="row col-md-12 pl-0 pb-2 m-0 align-items-center"
          style="background: #fff"
          *ngIf="SwapType != ''"
        >
          <div class="col-md-10">
            <div class="row col-md-12" *ngIf="SwapType == 'Branch'">
              <div class="col-md-3">
                <span class="required-lable">Branch</span>
                <select
                  name="SwapBranchId"
                  id="SwapBranchId"
                  [(ngModel)]="SwapModel.BranchId"
                  class="form-control input-text-css"
                  required
                  #refSwapBranchId="ngModel"
                  (change)="OnChangeBranch()"
                  [ngClass]="{
                    'is-invalid': df.submitted && refSwapBranchId.invalid,
                    'alert-warning':
                      refSwapBranchId.invalid &&
                      (refSwapBranchId.dirty ||
                        refSwapBranchId.touched ||
                        refSwapBranchId.untouched)
                  }"
                >
                  <option value="">Select Branch</option>
                  <option
                    *ngFor="let branch of BranchesList"
                    [value]="branch.BranchId"
                  >
                    {{ branch.Branch_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <span class="required-lable">Area</span>
                <select
                  name="SwapArea"
                  id="SwapArea"
                  [(ngModel)]="SwapModel.Area"
                  class="form-control input-text-css"
                  required
                  #refSwapArea="ngModel"
                  (change)="OnChangeArea()"
                  [ngClass]="{
                    'is-invalid': df.submitted && refSwapArea.invalid,
                    'alert-warning':
                      refSwapArea.invalid &&
                      (refSwapArea.dirty ||
                        refSwapArea.touched ||
                        refSwapArea.untouched)
                  }"
                >
                  <option value="">Select Collection Area</option>
                  <ng-container *ngFor="let area of SwapareaData">
                    <option
                      *ngIf="area.Type.trim() == 'Collection Area'"
                      [value]="area.Int_Id"
                    >
                      {{ area.Area_Name }}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-3">
                <span class="required-lable">Sub Area</span>
                <select
                  name="SwapSubArea"
                  id="SwapSubArea"
                  [(ngModel)]="SwapModel.SubArea"
                  class="form-control input-text-css"
                  required
                  #refSwapSubArea="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refSwapSubArea.invalid,
                    'alert-warning':
                      refSwapSubArea.invalid &&
                      (refSwapSubArea.dirty ||
                        refSwapSubArea.touched ||
                        refSwapSubArea.untouched)
                  }"
                >
                  <option value="">Select Sub Area</option>
                  <option
                    *ngFor="let subarea of SwapsubAreaData"
                    [value]="subarea.Int_Id"
                  >
                    {{ subarea.Sub_Area_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <span class="required-lable">Collection Executive</span>
                <select
                  name="SwapCollectionEx"
                  id="SwapCollectionEx"
                  [(ngModel)]="SwapModel.CollectionEx"
                  class="form-control input-text-css"
                  required
                  #refSwapCollectionEx="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refSwapCollectionEx.invalid,
                    'alert-warning':
                      refSwapCollectionEx.invalid &&
                      (refSwapCollectionEx.dirty ||
                        refSwapCollectionEx.touched ||
                        refSwapCollectionEx.untouched)
                  }"
                >
                  <option value="">Select Collection Executive</option>
                  <option
                    *ngFor="let collection of CollExecData"
                    [value]="collection.EmpId"
                  >
                    {{ collection.Emp_FirstName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row col-md-12" *ngIf="SwapType == 'Product'">
              <div class="col-md-3">
                <span class="required-lable">Product</span>
                <select
                  name="ProductId"
                  id="ProductId"
                  [(ngModel)]="SwapModel.ProductId"
                  class="form-control input-text-css"
                  required
                  #refProductId="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refProductId.invalid,
                    'alert-warning':
                      refProductId.invalid &&
                      (refProductId.dirty ||
                        refProductId.touched ||
                        refProductId.untouched)
                  }"
                >
                  <option value="" selected>Select Product</option>
                  <option
                    *ngFor="let product of ProductList"
                    [value]="product.ProductId"
                  >
                    {{ product.Product }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row col-md-12" *ngIf="SwapType == 'LoanAccCatg'">
              <div class="col-md-3">
                <span class="required-lable">Accounting Category</span>
                <select
                  name="LoanAccountingId"
                  id="LoanAccountingId"
                  [(ngModel)]="SwapModel.LoanAccountingId"
                  class="form-control input-text-css"
                  required
                  #refSwapLoanAccountingId="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      df.submitted && refSwapLoanAccountingId.invalid,
                    'alert-warning':
                      refSwapLoanAccountingId.invalid &&
                      (refSwapLoanAccountingId.dirty ||
                        refSwapLoanAccountingId.touched ||
                        refSwapLoanAccountingId.untouched)
                  }"
                >
                  <option value="">Select Accounting Category</option>
                  <option
                    *ngFor="let AcCat of AccCatDropdown"
                    [value]="AcCat.Loan_CategoryId"
                  >
                    {{ AcCat.Loan_category }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-right">
            <button
              type="button"
              (click)="SaveLoanSwap()"
              class="mt-3 btn font-size-12 button-btn"
              [disabled]="!df.form.valid"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </form>
</div>
<div id="swapdata"></div>
