<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="formborder" style="padding: 0px !important">
    <h1 class="fs-12 h-b">Loan Information</h1>

    <div class="row m-0 mt-1">
      <!--<div class="col-md-2">
        <select name="Type" id="Type" class="form-control input-text-css" [(ngModel)]="Type" (change)="showHead()" required>
          <option value="Application" selected>Application No.</option>
          <option value="case">Case No.</option>
        </select>
      </div>-->
      <div class="col-md-2">
        <input
          required
          type="text"
          placeholder=""
          name="AppCaseNo"
          id="AppCaseNo"
          readonly
          [(ngModel)]="AppCaseNo"
          (change)="getDetail(ReceiptDetail)"
          class="form-control input-text-css"
        />
        <button
          type="button"
          (click)="searchCustomer()"
          class="primary-btn ml-2 search"
        >
          <i
            class="fa fa-search"
            aria-hidden="true"
            style="font-size: 12px"
          ></i>
        </button>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Loan Product</h6>
        <p class="fs-12">{{ ReceiptDetail.Product }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Branch</h6>
        <p class="fs-12">{{ ReceiptDetail.Branch }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">CreateOn</h6>
        <p class="fs-12">{{ ReceiptDetail.ApplicationCreateOn }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Net Finance</h6>
        <p class="fs-12">{{ ReceiptDetail.NetFinance }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Interest Amount</h6>
        <p class="fs-12">{{ ReceiptDetail.InterestAmt }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Agreement Value</h6>
        <p class="fs-12">{{ ReceiptDetail.AgreementValue }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">First EMI Date</h6>
        <p class="fs-12">{{ ReceiptDetail.FirstEMIDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Expiry Date</h6>
        <p class="fs-12">{{ ReceiptDetail.ExpiryDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">No of Installment</h6>
        <p class="fs-12">{{ ReceiptDetail.No_Of_Instl }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">EMI Amount</h6>
        <p class="fs-12">{{ ReceiptDetail.EMIAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Asset Cost</h6>
        <p class="fs-12">{{ ReceiptDetail.AssetCost }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">ROI</h6>
        <p class="fs-12">{{ ReceiptDetail.Flat_Rate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Case IRR</h6>
        <p class="fs-12">{{ ReceiptDetail.Case_IRR }}</p>
      </div>

      <div
        class="col-md-2"
        *ngIf="
          UPI_Id &&
          currentUser.UPI_Prefix != '' &&
          currentUser.UPI_Prefix != null &&
          currentUser.UPI_Prefix != undefined &&
          UPI_IsActive == 1
        "
      >
        <h6 class="fs-12">UPI</h6>
        <a class="fs-12" (click)="GetQR()">{{ UPI_Id }}</a>
      </div>
    </div>
  </div>

  <div class="formborder mt-2" style="padding: 0px !important">
    <h1 class="fs-12 h-b" *ngIf="AppCaseNo">Customer Information</h1>

    <div class="row m-0 mt-1" *ngIf="AppCaseNo">
      <div class="col-md-3">
        <h6 class="fs-12">Customer Name</h6>
        <p class="fs-12">{{ customerDetail.Customer }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Mobile No.</h6>

        <p *ngIf="currentUser.IsMasking">
          {{ customerDetail.PhoneNo | mask }}
        </p>

        <p *ngIf="!currentUser.IsMasking">
          {{ customerDetail.PhoneNo }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Customer Address</h6>
        <p class="fs-12" style="line-height: normal">
          {{ addressModel.Address }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Email Id</h6>
        <p class="fs-12">{{ customerDetail.Email }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">State</h6>
        <p class="fs-12">{{ addressModel.State_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">District</h6>
        <p class="fs-12">{{ addressModel.District_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Tehsil</h6>
        <p class="fs-12">{{ addressModel.Tehsil_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Pin-Code</h6>
        <p class="fs-12">{{ addressModel.PinCode }}</p>
      </div>
    </div>
  </div>

  <div class="formborder mt-2" style="padding: 0px !important">
    <h1 class="fs-12 h-b" *ngIf="AppCaseNo">Receipt Detail</h1>

    <div class="row m-0 mt-1" *ngIf="AppCaseNo">
      <div class="col-md-2">
        <h6 class="fs-12">Receipt Date</h6>
        <h6 class="fs-12">{{ CurrentDate }}</h6>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Voucher No.</h6>
        <h6 class="fs-12">{{ voucherModel.Voucher.Voucher_No }}</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12">Mannual Receipt Date</h6>
        <h6 class="fs-12">
          <div class="datepicker_feild">
            <input
              [matDatepicker]="pickervcd"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ReceiptDate"
              id="ReceiptDate"
              #refReceiptDate="ngModel"
              class="form-control input-text-css"
              [max]="CDate"
              [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickervcd"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickervcd></mat-datepicker>
          </div>
        </h6>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Mannual Receipt No.</h6>
        <h6 class="fs-12">
          <input
            type="text"
            placeholder=""
            [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptNo_Manual"
            name="Voucher_ReceiptNo_Manual"
            id="Voucher_ReceiptNo_Manual"
            class="form-control input-text-css right-text pr-3"
          />
        </h6>
      </div>
    </div>

    <form #df="ngForm" novalidate *ngIf="AppCaseNo">
      <div
        class="row m-0 mt-3"
        [hidden]="!showCaseForm"
        *ngFor="let head of caseHead; let i = index"
      >
        <!-- <div class="col-md-4 ">
        <h6 class="fs-12 bold">Account Head</h6>
      </div>
      <div class="col-md-4 ">
        <h6 class="fs-12 bold" style=" text-align: center;">Balance Amount</h6>
      </div>
      <div class="col-md-2 ">
        <h6 class="fs-12 bold" style=" text-align: center;">Deposit Amount</h6>
      </div> -->
        <div class="col-md-4">
          <div class="bg-success1">Account Head</div>
        </div>
        <div class="col-md-4 text-right">
          <div class="bg-success1">Balance Amount</div>
        </div>
        <div class="col-md-3 text-right">
          <div class="bg-success1">Deposit Amount</div>
        </div>

        <div class="col-md-4">
          <h6 class="fs-12 form-control input-text-css">Installment</h6>
        </div>
        <div class="col-md-4">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="head.Installment"
            name="Installment{{ i }}"
            id="Installment{{ i }}"
            class="form-control input-text-css right-text pr-3"
            disabled
          />
        </div>
        <div class="col-md-3">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="PayInstallment"
            appTwoDigitDecimaNumber
            (change)="getcaseTotalAmount('Installment')"
            name="overdueEMI"
            id="overdueEMI"
            class="form-control input-text-css pr-3 text-right"
          />
        </div>

        <div class="col-md-4">
          <h6 class="fs-12 form-control input-text-css">Overdue Interest</h6>
        </div>
        <div class="col-md-4">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="head.Interest"
            name="Interest{{ i }}"
            id="Interest{{ i }}"
            class="form-control input-text-css right-text pr-3"
            disabled
          />
        </div>
        <div class="col-md-3">
          <input
            required
            type="text"
            placeholder=""
            name="overdueInt"
            [(ngModel)]="PayInterest"
            appTwoDigitDecimaNumber
            (change)="getcaseTotalAmount('Interest')"
            id="overdueInt"
            class="form-control input-text-css text-right pr-3"
          />
        </div>

        <div class="col-md-4">
          <h6 class="fs-12 form-control input-text-css">Other Dues</h6>
        </div>
        <div class="col-md-4">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="head.OtherDues"
            name="OtherDues{{ i }}"
            id="OtherDues{{ i }}"
            class="form-control input-text-css right-text pr-3"
            disabled
          />
        </div>
        <div class="col-md-3">
          <input
            required
            type="text"
            placeholder=""
            name="OtherDues"
            id="OtherDues"
            [(ngModel)]="PayOtherDues"
            appTwoDigitDecimaNumber
            (change)="getcaseTotalAmount('OtherDues')"
            class="form-control input-text-css text-right pr-3"
          />
        </div>
        <div class="col-md-1">
          <button
            type="button"
            class="addmore both no-head"
            (click)="addMoreData()"
            *ngIf="voucherModel.Voucher_Detail.length == 0"
          >
            +
          </button>
        </div>
      </div>
      <div class="row m-0 mt-3" [hidden]="!showApplicationForm">
        <div class="col-md-4">
          <div class="bg-success1">Account Head</div>
        </div>
        <div class="col-md-4 text-right">
          <div class="bg-success1">Balance Amount</div>
        </div>
        <div class="col-md-3 text-right">
          <div class="bg-success1">Deposit Amount</div>
        </div>
      </div>
      <form #mdf="ngForm" novalidate>
        <div
          class="row m-0 add-remove-btn"
          *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index"
        >
          <div class="col-md-12 row m-0 type-column mt-2">
            <div class="row feild">
              <ng-container>
                <div class="col-md-4">
                  <!--<span class="required-lable" [hidden]="!showCaseForm">Account Head</span>-->
                  <input
                    type="search"
                    class="form-control input-text-css"
                    (keyup.enter)="onChangeChargesHead(vdData, i)"
                    (change)="onChangeChargesHead(vdData, i)"
                    [(ngModel)]="vdData.ChargeHeadCategory"
                    id="ChargeHeadId{{ i }}"
                    name="ChargeHeadId{{ i }}"
                    list="dynmicChargeHead"
                    autocomplete="off"
                    required
                    #refChargeHeadId="ngModel"
                    placeholder="Select Head"
                    [ngClass]="{
                      'is-invalid': mdf.submitted && refChargeHeadId.invalid,
                      'alert-warning':
                        refChargeHeadId.invalid &&
                        (refChargeHeadId.dirty ||
                          refChargeHeadId.touched ||
                          refChargeHeadId.untouched)
                    }"
                  />
                  <datalist id="dynmicChargeHead">
                    <option
                      *ngFor="let item of chargesHeadDropdown"
                      [value]="item.ChagesHead"
                      title="item.ChagesHead"
                    ></option>
                  </datalist>
                </div>
                <div class="col-md-4">
                  <!--<span [hidden]="!showCaseForm"> Balance Amount</span>-->
                  <input
                    type="text"
                    name="Amount{{ i }}"
                    id="Amount{{ i }}"
                    class="form-control input-text-css right-text pr-3"
                    (change)="onChangeAmount($event, vdData)"
                    readonly
                    [(ngModel)]="vdData.Amount"
                  />
                </div>
                <div class="col-md-3">
                  <!--<span class="required-lable" [hidden]="!showCaseForm">Amount</span>-->
                  <input
                    type="text"
                    min="0"
                    name="PayAmount{{ i }}"
                    id="PayAmount{{ i }}"
                    [(ngModel)]="vdData.PayAmount"
                    class="form-control input-text-css text-right pr-3"
                    required
                    #refAmount="ngModel"
                    appTwoDigitDecimaNumber
                    (change)="onChangeAmount($event, vdData)"
                    (keyup.enter)="onChangeAmount($event, vdData)"
                    [ngClass]="{
                      'is-invalid': mdf.submitted && refAmount.invalid,
                      'alert-warning':
                        refAmount.invalid &&
                        (refAmount.dirty ||
                          refAmount.touched ||
                          refAmount.untouched)
                    }"
                  />
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="vdData.TaxSlab1 && vdData.TaxSlab1.length > 0">
            <div
              class="col-md-12 row mt-2 m-0 p-0 mb-1"
              *ngFor="let taxData of vdData.TaxSlab1; let ti = index"
            >
              <div class="col-md-4">
                <span class="form-control1">{{ taxData.TaxSlab_Type }}</span>
              </div>
              <div class="col-md-4">
                <span class="form-control1">
                  {{
                    taxData.TypeIGST == true
                      ? "IGST"
                      : taxData.TypeGST == true
                      ? "SGST"
                      : "CGST"
                  }}
                </span>
                <!-- <span class="form-control1 pr-3" readonly>0.00</span> -->
              </div>
              <div class="col-md-3">
                <input
                  type="text"
                  required
                  name="TaxAmount{{ i }}{{ ti }}"
                  id="TaxAmount{{ i }}{{ ti }}"
                  appTwoDigitDecimaNumber
                  [(ngModel)]="taxData.TaxAmount"
                  (change)="onChangeTax()"
                  class="form-control input-text-css text-right pr-3"
                />
              </div>
            </div>
          </ng-container>

          <button
            type="button"
            class="remove"
            (click)="removeData(i)"
            [ngClass]="{ 'no-head': i != 0 }"
          >
            <!-- *ngIf="voucherModel.Voucher_Detail.length>1"-->
            -
          </button>
          <button
            type="button"
            class="addmore"
            (click)="addMoreData()"
            [ngClass]="{
              both: voucherModel.Voucher_Detail.length > 1,
              'no-head': i != 0
            }"
            [disabled]="!mdf.form.valid"
            *ngIf="i == voucherModel.Voucher_Detail.length - 1"
          >
            +
          </button>
        </div>
        <div
          class="col-md-12 row p-1 mt-2 pl-3 m-0"
          *ngIf="totalCalculator"
          style="width: 98.8%"
        >
          <div class="col-md-11 bg-success1 p-1">
            <div class="row">
              <div class="col-md-4 text-left">Grand Total :</div>
              <div class="col-md-5 text-right" style="padding-right: 52px">
                <div class="pl-2">
                  {{
                    totalCalculator.total == undefined
                      ? ""
                      : totalCalculator.total.toFixed(2)
                  }}
                </div>
              </div>
              <div class="col-md-3 text-right" style="padding-right: 30px">
                {{
                  totalCalculator.payTotal == undefined
                    ? ""
                    : totalCalculator.payTotal.toFixed(2)
                }}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="col-md-12 row m-0 p-0 pr-4 mt-0" *ngIf="totalCalculator">
        <div class="col-md-12">
          <p class="bold" style="line-height: normal">
            <b>Deposite Amount In Word :</b>&nbsp;{{
              totalCalculator.payTotalInWord
            }}
          </p>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-md-12"><h1 class="fs-12 h-b">Payment Mode</h1></div>
      </div>

      <div class="row m-0 align-items-center">
        <div class="col-md-3">
          <span class="required-lable">Select Tag </span>
          <select
            name="Voucher_Tag"
            id="Voucher_Tag"
            required
            (change)="onVoucherTagChange()"
            class="form-control input-text-css"
            #refVoucher_Tag="ngModel"
            [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucher_Tag.invalid,
              'alert-warning':
                refVoucher_Tag.invalid &&
                (refVoucher_Tag.dirty ||
                  refVoucher_Tag.touched ||
                  refVoucher_Tag.untouched)
            }"
          >
            <option value="Bank">Bank</option>
            <option value="CASH">CASH</option>
          </select>
        </div>

        <div class="col-md-3">
          <span class="required-lable">Select Account</span>
          <select
            name="DRAccountId"
            id="DRAccountId"
            required
            #refDRAccountId="ngModel"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': df.submitted && refDRAccountId.invalid,
              'alert-warning':
                refDRAccountId.invalid &&
                (refDRAccountId.dirty ||
                  refDRAccountId.touched ||
                  refDRAccountId.untouched)
            }"
            [(ngModel)]="voucherModel.Voucher.DRAccountId"
          >
            <option value="">Select Account</option>
            <option
              *ngFor="let data of accountTagDropdown"
              [value]="data.AccountId"
            >
              {{ data.Account_Name }}
            </option>
          </select>
        </div>
        <div
          class="col-md-3"
          *ngIf="voucherModel.Voucher.Voucher_Tag == 'Bank'"
        >
          <span class="required-lable">Select Mode of Payment</span>
          <select
            name="Voucher_Mode_of_Payment"
            id="Voucher_Mode_of_Payment"
            required
            (change)="onChangeMode()"
            class="form-control input-text-css"
            #refVoucher_Mode_of_Payment="ngModel"
            [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucher_Mode_of_Payment.invalid,
              'alert-warning':
                refVoucher_Mode_of_Payment.invalid &&
                (refVoucher_Mode_of_Payment.dirty ||
                  refVoucher_Mode_of_Payment.touched ||
                  refVoucher_Mode_of_Payment.untouched)
            }"
          >
            <option value="">Select Mode Of Payment</option>
            <option value="Cheque">Cheque</option>
            <option value="RTGS">RTGS</option>
            <option value="Gateway">E-Gateway</option>
            <option value="NEFT">NEFT</option>
            <option value="IMPS">IMPS</option>
            <option value="Transfer">Transfer</option>
          </select>
        </div>
        <div class="col-md-3">
          <span class="required-lable">Select Collection By</span>
          <select
            name="Collection_By"
            id="Collection_By"
            required
            #refCollection_By="ngModel"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': df.submitted && refCollection_By.invalid,
              'alert-warning':
                refCollection_By.invalid &&
                (refCollection_By.dirty ||
                  refCollection_By.touched ||
                  refCollection_By.untouched)
            }"
            [(ngModel)]="voucherModel.Voucher.Collection_By"
          >
            <option value="">Select Account</option>
            <option *ngFor="let data of employeeDropdown" [value]="data.EmpId">
              {{ data.EmpName }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="row m-0 mt-1"
        *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != ''"
      >
        <ng-container
          *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
        >
          <div class="col-md-4">
            <span class="required-lable">Cheque No</span>
            <input
              type="text"
              required
              #refVoucher_Cheque_No="ngModel"
              [ngClass]="{
                'is-invalid': df.submitted && refVoucher_Cheque_No.invalid,
                'alert-warning':
                  refVoucher_Cheque_No.invalid &&
                  (refVoucher_Cheque_No.dirty ||
                    refVoucher_Cheque_No.touched ||
                    refVoucher_Cheque_No.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
              name="Voucher_Cheque_No"
              id="Voucher_Cheque_No"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <span class="required-lable">Cheque Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="pickervcd"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="Voucher_Cheque_Date"
                id="Voucher_Cheque_Date"
                #refVoucher_Cheque_Date="ngModel"
                [ngClass]="{
                  'is-invalid': df.submitted && refVoucher_Cheque_Date.invalid,
                  'alert-warning':
                    refVoucher_Cheque_Date.invalid &&
                    (refVoucher_Cheque_Date.dirty ||
                      refVoucher_Cheque_Date.touched ||
                      refVoucher_Cheque_Date.untouched)
                }"
                class="form-control input-text-css"
                [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickervcd"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickervcd></mat-datepicker>
            </div>
            <!-- <input name="Voucher_Cheque_Date" id="Voucher_Cheque_Date" required #refVoucher_Cheque_Date="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid, 'alert-warning': refVoucher_Cheque_Date.invalid  && (refVoucher_Cheque_Date.dirty || refVoucher_Cheque_Date.touched || refVoucher_Cheque_Date.untouched) }"
          [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
          [(bsValue)]="voucherModel.Voucher.Voucher_Cheque_Date"
          value="{{ voucherModel.Voucher.Voucher_Cheque_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
          class="form-control input-text-css" bsDatepicker> -->
          </div>
          <div class="col-md-4">
            <span class="required-lable">Bank Name</span>
            <input
              type="text"
              required
              #refVoucher_Bank_Name="ngModel"
              [ngClass]="{
                'is-invalid': df.submitted && refVoucher_Bank_Name.invalid,
                'alert-warning':
                  refVoucher_Bank_Name.invalid &&
                  (refVoucher_Bank_Name.dirty ||
                    refVoucher_Bank_Name.touched ||
                    refVoucher_Bank_Name.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
              name="Voucher_Bank_Name"
              id="Voucher_Bank_Name"
              class="form-control input-text-css"
            />
          </div>
          <!--<div class="col-md-3">
        <span class="">Cheque Clear Date </span>
        <div class="datepicker_feild">
          <input [matDatepicker]="pickervccd" dateConvert placeholder="DD/MM/YYYY" name="Voucher_Cheque_Clear_Date"
            id="Voucher_Cheque_Clear_Date" #refVoucher_Cheque_Clear_Date="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Clear_Date.invalid, 'alert-warning': refVoucher_Cheque_Clear_Date.invalid  && (refVoucher_Cheque_Clear_Date.dirty || refVoucher_Cheque_Clear_Date.touched || refVoucher_Cheque_Clear_Date.untouched) }"
            class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date">
          <mat-datepicker-toggle matSuffix [for]="pickervccd"></mat-datepicker-toggle>
          <mat-datepicker #pickervccd></mat-datepicker>
        </div>-->
          <!-- <input name="Voucher_Cheque_Clear_Date" id="Voucher_Cheque_Clear_Date" #refVoucher_Cheque_Clear_Date="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Clear_Date.invalid, 'alert-warning': refVoucher_Cheque_Clear_Date.invalid  && (refVoucher_Cheque_Clear_Date.dirty || refVoucher_Cheque_Clear_Date.touched || refVoucher_Cheque_Clear_Date.untouched) }"
        [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date"
        [(bsValue)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date"
        value="{{ voucherModel.Voucher.Voucher_Cheque_Clear_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" bsDatepicker> -->
          <!--</div>-->
        </ng-container>
        <ng-container
          *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
        >
          <div class="col-md-4">
            <span class="required-lable">Ref No</span>
            <input
              type="text"
              required
              #refVoucher_E_Ref_No="ngModel"
              [ngClass]="{
                'is-invalid': df.submitted && refVoucher_E_Ref_No.invalid,
                'alert-warning':
                  refVoucher_E_Ref_No.invalid &&
                  (refVoucher_E_Ref_No.dirty ||
                    refVoucher_E_Ref_No.touched ||
                    refVoucher_E_Ref_No.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
              name="Voucher_E_Ref_No"
              id="Voucher_E_Ref_No"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <span class="required-lable">Transaction Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="pickervetd"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="Voucher_E_Transaction_Date"
                id="Voucher_E_Transaction_Date"
                #refVoucher_E_Transaction_Date="ngModel"
                [ngClass]="{
                  'is-invalid':
                    df.submitted && refVoucher_E_Transaction_Date.invalid,
                  'alert-warning':
                    refVoucher_E_Transaction_Date.invalid &&
                    (refVoucher_E_Transaction_Date.dirty ||
                      refVoucher_E_Transaction_Date.touched ||
                      refVoucher_E_Transaction_Date.untouched)
                }"
                class="form-control input-text-css"
                [max]="voucherModel.Voucher.Voucher_Date"
                [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickervetd"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickervetd></mat-datepicker>
            </div>
            <!-- <input name="Voucher_E_Transaction_Date" id="Voucher_E_Transaction_Date" required
          #refVoucher_E_Transaction_Date="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refVoucher_E_Transaction_Date.invalid, 'alert-warning': refVoucher_E_Transaction_Date.invalid  && (refVoucher_E_Transaction_Date.dirty || refVoucher_E_Transaction_Date.touched || refVoucher_E_Transaction_Date.untouched) }"
          [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
          [(bsValue)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
          value="{{ voucherModel.Voucher.Voucher_E_Transaction_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
          class="form-control input-text-css" bsDatepicker> -->
          </div>
        </ng-container>
      </div>
      <div class="row m-0 mt-1">
        <div class="col-md-12">
          <span class="">Voucher Narration</span>
          <input
            type="text"
            #refVoucher_Narration="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucher_Narration.invalid,
              'alert-warning':
                refVoucher_Narration.invalid &&
                (refVoucher_Narration.dirty ||
                  refVoucher_Narration.touched ||
                  refVoucher_Narration.untouched)
            }"
            [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
            name="Voucher_Narration"
            id="Voucher_Narration"
            class="form-control input-text-css"
          />
        </div>
      </div>
      <div class="row col-md-12 m-0 mt-2 justify-content-end">
        <!--<button type="button" class="ml-4 btn btn-light font-size-12">Cancel</button>-->

        <button
          type="button"
          class="ml-4 mt-3 btn font-size-12 button-btn"
          (click)="saveVoucher()"
          [class.spinner]="loading"
          [disabled]="!df.form.valid || !mdf.form.valid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Receipt
        </h6>
        <button
          type="button"
          (click)="onCloseReceipt()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <span>Type</span>
              <select
                name="Type"
                id="Type"
                class="form-control input-text-css"
                [(ngModel)]="CustomerType"
              >
                <option [value]="0">All</option>
                <option [value]="1">LOS Only</option>
                <option [value]="2">LMS Only</option>
              </select>
            </div>
            <div class="col-md-2">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-2">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Date From</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateFrom"
                  id="LoanDateFrom"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateFrom"
                [(ngModel)]="Customer.LoanDateFrom"
                id="LoanDateFrom"
                placeholder="Date From"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>
            <div class="col-md-2">
              <span>Date To</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateTo"
                  id="LoanDateTo"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateTo"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateTo"
                [(ngModel)]="Customer.LoanDateTo"
                id="LoanDateTo"
                placeholder="Date To"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>

            <!--<div class="col-md-3">
              <span>Close Case</span>
              <select name="CloseCase" id="CloseCase" class="form-control input-text-css" [(ngModel)]="Customer.CloseCase">
                <option value="">Select Close Case</option>
                <option value="1">Close Case</option>
              </select>
            </div>-->
            <div class="col-md-3">
              <span>Select Type</span>
              <select
                name="SelectType"
                id="SelectType"
                class="form-control input-text-css"
                [(ngModel)]="SelectType"
                (change)="SearchChange()"
              >
                <option value="">Select Type</option>
                <option value="Branch">Branch</option>
                <option value="CollArea">Collection Area</option>
                <option value="CollSubArea">Collection Sub Area</option>
                <option value="CollExecutive">Collection Executive</option>
                <option value="SalesExecutive">Sales Executive</option>
                <option value="EngineNo">Engine No.</option>
                <option value="ChassisNo">Chassis No.</option>
                <option value="LoanAccCatg">Loan A/C Category</option>
              </select>
            </div>
            <div class="col-md-3">
              <div *ngIf="SelectType == 'Branch'">
                <span> Branch</span>
                <select
                  name="BranchId"
                  id="BranchId"
                  #refSelectGender="ngModel"
                  [(ngModel)]="Customer.BranchId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Branch</option>
                  <option
                    *ngFor="let branch of BranchesList"
                    [value]="branch.BranchId"
                  >
                    {{ branch.Branch_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'EngineNo'">
                <span>Engine No.</span>
                <input
                  name="EngineNo"
                  [(ngModel)]="Customer.EngineNo"
                  id="EngineNo"
                  placeholder="Engine No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'ChassisNo'">
                <span>Chassis No.</span>
                <input
                  name="ChassisNo"
                  [(ngModel)]="Customer.ChassisNo"
                  id="ChassisNo"
                  placeholder="Chassis No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'CollArea'">
                <span> Collection Area</span>
                <select
                  name="Area"
                  id="Area"
                  [(ngModel)]="Customer.Area"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Area</option>
                  <ng-container *ngFor="let area of areaData">
                    <option
                      *ngIf="area.Type.trim() == 'Collection Area'"
                      [value]="area.Int_Id"
                    >
                      {{ area.Area_Name }}
                    </option>
                  </ng-container>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollSubArea'">
                <span> Collection Sub Area</span>
                <select
                  name="SubArea"
                  id="SubArea"
                  [(ngModel)]="Customer.SubArea"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection SubArea</option>
                  <option
                    *ngFor="let subarea of subAreaData"
                    [value]="subarea.Int_Id"
                  >
                    {{ subarea.Sub_Area_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'SalesExecutive'">
                <span> Sales Executive</span>
                <select
                  name="SalesEx"
                  id="SalesEx"
                  [(ngModel)]="Customer.SalesEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Sales Executive</option>
                  <option
                    *ngFor="let SalesExec of SalesExecutiveData"
                    [value]="SalesExec.EmpId"
                  >
                    {{ SalesExec.EmpName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollExecutive'">
                <span> Collection Executive</span>
                <select
                  name="CollectionEx"
                  id="CollectionEx"
                  [(ngModel)]="Customer.CollectionEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Executive</option>
                  <option
                    *ngFor="let CollExec of CollectionExecutiveData"
                    [value]="CollExec.EmpId"
                  >
                    {{ CollExec.Emp_FirstName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'LoanAccCatg'">
                <span>Accounting Category </span>
                <select
                  name="LoanAccountingCatId"
                  id="LoanAccountingCatId"
                  [(ngModel)]="Customer.LoanAccountingCatId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Accounting Category</option>
                  <option
                    *ngFor="let AcCat of AccCatDropdown"
                    [value]="AcCat.Loan_CategoryId"
                  >
                    {{ AcCat.Loan_category }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-3 text-right">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>

            <div class="col-md-3 mt-2" [hidden]="!dataSourceCustomer">
              <form action="" class="search-text">
                <div class="bg-light rounded rounded-pill shadow-sm">
                  <div class="input-group">
                    <input
                      type="text"
                      id="FilterInputSearch"
                      (keyup)="applyFilter($event.target.value)"
                      name="FilterInputSearch"
                      placeholder="What're you searching for?"
                      class="form-control border-0 bg-light"
                    />
                    <div class="input-group-append">
                      <button
                        id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </form>

        <div class="mt-2" [hidden]="!dataSourceCustomer">
          <div class="">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSourceCustomer"
                #sortList="matSort"
                matSort
                id="exportReport"
                style="height: 280px; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.Type
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Application_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                    >Aplication No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    {{ row.Application_No }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    Loan No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.LoanAcNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell"
                    >{{ row.CustomertName }}({{
                      row.AC_CustomerType
                    }})</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relation Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.FatherName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.Customer_Gender
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                  >
                    Age</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.GenderAge
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Phone No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div *ngIf="currentUser.IsMasking">
                      {{ row.PhoneNo | mask }}
                    </div>

                    <div *ngIf="!currentUser.IsMasking">
                      {{ row.PhoneNo }}
                    </div>

                    <i
                      *ngIf="row.Customer_PhoneNo_IsVerified == true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    View Detail</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center mw100"
                  >
                    <i
                      class="fa fa-arrow-circle-right arrow-circle action-btn"
                      (click)="goToDetail(row)"
                      aria-label="true"
                    ></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedCustomerColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedCustomerColumns"
                ></mat-row>
              </mat-table>

              <mat-paginator
                #paginatorRef
                #PaginatorList
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="modal-body">
        <div class="row m-0 mt-3">
          <form #lff="ngForm"  novalidate>
            <div class="row m-0 ">
              <div class="col-md-6 mb-3">
                <span> Search By: </span>
                <select name="FilterSearchBy" id="FilterSearchBy" class="form-control input-text-css" required>
                  <option value="">Select</option>
                  <option value="Pan">PAN Card</option>
                  <option value="Passport">Passport Copy</option>
                  <option value="VoterId">Voter ID Card</option>
                  <option value="DL">Driving License</option>
                  <option value="Aadhar">Aadhaar Card</option>
                </select>
              </div>
              <div class="col-md-6">
                <span>Doc No </span>
                <input type="text" required name="number" id="number" class="form-control input-text-css" />
              </div>
            </div>
          </form>
          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button type="button" (click)="onCloseReceipt()" class="btn btn-light font-size-12" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" (click)="onOkReceipt()" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px"
              mat-raised-button color="primary" [class.spinner]="loading" [disabled]="!lff.form.valid">
              Search
            </button>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ReceiptPrint"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel"></h6>
        <button
          type="button"
          (click)="onCloseReceiptPrint()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              type="button"
              class="mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
              (click)="printLetter()"
            >
              Print
            </button>
          </div>
          <div class="col-md-12">
            <div id="pdfReceiptDownload">
              <div>
                <p style="text-align: center; letter-spacing: 7px">
                  OFFICE COPY
                </p>
                <p style="text-align: center; font-size: 10px">
                  In case Contract is Terminated this Receipt is issued strictly
                  without prejudice
                </p>
                <h5 style="text-align: center">
                  {{ systemconfig.CompanyName }}
                </h5>
                <p style="text-align: center">
                  {{ systemconfig.CompanyHeadOfficeAddress }}
                </p>
                <p style="text-align: center">
                  Ph. {{ systemconfig.CompanyPhoneNo }} Email.
                  {{ systemconfig.ComapnyEmail }}
                </p>
                <p style="text-align: center; text-decoration: underline">
                  <i>RECEIPT</i>
                </p>
              </div>

              <div style="font-style: italic; margin: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <div style="width: 50%; float: left">
                    Receipt No: {{ receiptNo }}
                  </div>
                  <div style="width: 50%; float: left; text-align: right">
                    Dated: {{ CurrentDate }}
                  </div>
                  <div style="width: 100%; float: left">
                    Loan/Application File No:
                    {{ LoanAcNo == "" ? Application_No : LoanAcNo }}
                  </div>
                </div>

                <!-- <div style="display: flex; flex-wrap: wrap;">
                  <p style="float: left;flex: 0 0 66.66666667%;max-width: 66.66666667%;">Receipt No:{{receiptNo}}</p>
                  <p style="float: right;width: 100%;flex: 0 0 33.33333333%;max-width: 33.33333333%;">Dated:{{CurrentDate}}</p>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <p style="float: left;width: 100%;flex: 0 0 33.33333333%;max-width: 33.33333333%;">Loan File No: {{LoanAcNo}}</p>
                </div> -->

                <hr />

                <div>
                  <p>
                    Received with thanks from: {{ customerDetail.Customer }}
                    {{ customerDetail.Relation_Name }}
                  </p>
                  <p>Address: {{ addressModel.Address }}</p>
                  <p *ngIf="totalCalculator">
                    a sum of Rs. {{ totalCalculator.payTotalInWord }}
                  </p>
                  <p>
                    By: Cash/Cheque/DD no.
                    <span *ngIf="Voucher_Cheque_No">{{
                      Voucher_Cheque_No
                    }}</span>
                  </p>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <div style="width: 35%; float: left">
                    <div
                      *ngIf="totalCalculator"
                      style="
                        text-decoration: underline;
                        padding: 7px;
                        box-sizing: border-box;
                        border: 2px solid #565656;
                        border-radius: 5px;
                        width: 200px;
                        font-size: 16px;
                        text-align: right;
                        font-weight: bold;
                      "
                    >
                      **{{ totalCalculator.payTotal }}
                    </div>
                  </div>
                  <div style="width: 65%; float: left; text-align: right">
                    For: {{ systemconfig.CompanyName }}
                  </div>
                </div>
                <!-- <div>
                  <div style="text-align:right"><B>For:</B> {{systemconfig.CompanyName}}</div>
                  <div *ngIf="totalCalculator" style="text-decoration: underline;padding: 8px;box-sizing: border-box;border: 2px solid #565656;border-radius: 5px;width: 200px; font-size:16px; text-align:right;">**{{totalCalculator.payTotal}}</div>
                </div> -->
              </div>

              <div
                style="
                  border-top: 1px dotted #000;
                  margin-top: 1.5rem;
                  padding-top: 15px;
                "
              >
                <p style="text-align: center; letter-spacing: 7px">
                  CUSTOMER COPY
                </p>
                <p style="text-align: center; font-size: 10px">
                  In case Contract is Terminated this Receipt is issued strictly
                  without prejudice
                </p>
                <h5 style="text-align: center">
                  {{ systemconfig.CompanyName }}
                </h5>
                <p style="text-align: center">
                  {{ systemconfig.CompanyHeadOfficeAddress }}
                </p>
                <p style="text-align: center">
                  Ph. {{ systemconfig.CompanyPhoneNo }} Email.
                  {{ systemconfig.ComapnyEmail }}
                </p>
                <p style="text-align: center; text-decoration: underline">
                  <i>RECEIPT</i>
                </p>
              </div>
              <div style="font-style: italic; margin: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <div style="width: 50%; float: left">
                    Receipt No: {{ receiptNo }}
                  </div>
                  <div style="width: 50%; float: left; text-align: right">
                    Dated: {{ CurrentDate }}
                  </div>
                  <div style="width: 100%; float: left">
                    Loan/Application File No:
                    {{ LoanAcNo == "" ? Application_No : LoanAcNo }}
                  </div>
                </div>

                <hr />

                <div>
                  <p>
                    Received with thanks from: {{ customerDetail.Customer }}
                    {{ customerDetail.Relation_Name }}
                  </p>
                  <p>Address: {{ addressModel.Address }}</p>
                  <p *ngIf="totalCalculator">
                    a sum of Rs. {{ totalCalculator.payTotalInWord }}
                  </p>
                  <p>
                    By: Cash/Cheque/DD no.
                    <span *ngIf="Voucher_Cheque_No">{{
                      Voucher_Cheque_No
                    }}</span>
                  </p>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <div style="width: 35%; float: left">
                    <div
                      *ngIf="totalCalculator"
                      style="
                        text-decoration: underline;
                        padding: 7px;
                        box-sizing: border-box;
                        border: 2px solid #565656;
                        border-radius: 5px;
                        width: 200px;
                        font-size: 16px;
                        text-align: right;
                        font-weight: bold;
                      "
                    >
                      **{{ totalCalculator.payTotal }}
                    </div>
                  </div>
                  <div style="width: 65%; float: left; text-align: right">
                    For: {{ systemconfig.CompanyName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="GSTModalWindow"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Party Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseGSTModal()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #cf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 mt-1">
            <div class="col-md-4">
              <span class="form-check-inline">
                <label class="form-check-label" for="Existing Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="Existing_Party"
                    (change)="onPartyTypeChange()"
                  />
                  Existing Party
                </label>
              </span>
            </div>
            <div class="col-md-8">
              <span class="form-check-inline">
                <label class="form-check-label" for="New Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="New_Party"
                    (change)="onPartyTypeChange()"
                  />
                  New Party
                </label>
              </span>
            </div>
          </div>

          <div class="row m-0 mt-2">
            <div class="col-md-3">
              <span class="required-lable">Tax Slab</span>
            </div>
            <div class="col-md-9">
              <select
                name="Slab_Tax"
                id="Slab_Tax"
                #refSlab_Tax="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refSlab_Tax.invalid,
                  'alert-warning':
                    refSlab_Tax.invalid &&
                    (refSlab_Tax.dirty ||
                      refSlab_Tax.touched ||
                      refSlab_Tax.untouched)
                }"
                [(ngModel)]="GSTModel.Slab_Tax"
              >
                <option value="">Select Slab</option>
                <option
                  [value]="slab.TaxSlab_Name"
                  *ngFor="let slab of slabList"
                >
                  {{ slab.TaxSlab_Name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'Existing_Party' &&
              voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
            "
          >
            <div class="col-md-3"><span>Account</span></div>
            <div class="col-md-9">
              <select
                name="AccountHeadId"
                id="AccountHeadId"
                #refAccountHeadId="ngModel"
                [(ngModel)]="GSTModel.AccountHeadId"
                class="form-control input-text-css"
                (change)="onAccountHeadChange()"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let item of AccountHeadForGST"
                  [value]="item.AccountId"
                >
                  {{ item.Account_Name }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'New_Party' ||
              (GSTModel.PartyType == 'Existing_Party' &&
                voucherModel.Voucher.Voucher_Sub_Type == 'Loan')
            "
          >
            <div class="col-md-3"><span class="required-lable">Name</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refCustomer="ngModel"
                class="form-control input-text-css"
                placeholder="Name"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
                [(ngModel)]="GSTModel.Customer"
                name="Customer"
                id="Customer"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              />
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>GST No.</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refAcc_GSTNo="ngModel"
                class="form-control input-text-css"
                placeholder="GST No."
                [(ngModel)]="GSTModel.Acc_GSTNo"
                name="Acc_GSTNo"
                id="Acc_GSTNo"
              />
              <!--[disabled]="GSTModel.PartyType == 'Existing_Party'"-->
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>State</span></div>
            <div class="col-md-9">
              <select
                name="State"
                id="State"
                #refState="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="GSTModel.StateId"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>HSN Code</span></div>
            <div class="col-md-9">
              <input
                name="HSNCode"
                id="HSNCode"
                [(ngModel)]="GSTModel.HSNCode"
                placeholder="HSN Code"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light font-size-12"
            (click)="OnCloseGSTModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn ml-4"
            (click)="saveGSTDetails()"
            [class.spinner]="loading"
            [disabled]="!cf.form.valid"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="QRModalWindow"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 220px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          QR Code
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseQRModal()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #cf="ngForm" novalidate>
        <div class="modal-body">
          <div class="col-md-12 p-0 mb-2">
            <qr-code
              [value]="qrInfo"
              size="150"
              class="QR"
              *ngIf="AppCaseNo && AppCaseNo"
            ></qr-code>
          </div>
          <div class="col-md-12 p-0 mb-2">
            <h6 class="UPIId">{{ UPI_Id }}</h6>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="ReceiptBill"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      class="modal-content"
      style="width: 780px"
    >
      <div
        cdkDragHandle
        class="modal-header"
        style="padding: 2px; border: none"
      >
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Receipt Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherModel()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div id="ReceiptBillPrint">
          <div class="invoice">
            <div class="header">
              <h2>Receipt</h2>
            </div>
            <div class="company-info">
              <div class="logo">
                <!-- Your company logo here -->
                <img src="{{ CompanyProfileBaseUrl }}logo.png" />
              </div>
              <div class="info">
                <p class="company-name">
                  <strong>{{ CompanyInformation?.CompanyName }}</strong>
                </p>
                <div class="company-details">
                  <span>{{ CompanyInformation?.CompanyAddress }}</span>
                  <span>GST No.:{{ CompanyInformation?.CompanyGSTNo }}</span>
                  <!-- <span>PAN No.: {{ CompanyInformation?.ComapnyPanNo }}</span> -->
                  <span
                    >Phone No.: {{ CompanyInformation?.CompanyPhoneNo }}</span
                  >
                  <span>Email Id: {{ CompanyInformation?.ComapnyEmail }}</span>
                </div>
              </div>
              <div class="invoice-no">
                <span
                  ><strong
                    >Receipt No.:{{ CompanyInformation?.Receipt_No }}</strong
                  ></span
                >
                <span
                  ><strong
                    >Receipt Date:
                    {{ CompanyInformation?.Voucher_Date }}</strong
                  ></span
                >
              </div>
            </div>

            <!-- <hr /> -->
            <div class="party-info">
              <div class="party-details">
                <span><strong>Branch Address:-</strong></span>
                <br />
                <span>{{ CompanyInformation?.Branch_Name }}</span>
                <span>{{ CompanyInformation?.Branch_Address }}</span>
                <span>Phone NO.:{{ CompanyInformation?.Branch_PhoneNo }}</span>
                <span>Email ID:{{ CompanyInformation?.Branch_Email }}</span>
                <span>GST No.:{{ CompanyInformation?.Branch_GSTNo }}</span>
              </div>
              <!-- <div class="invoice-details">
                <p><strong>Invoice No.: INV123</strong></p>
                <p><strong>Invoice Date: 2023-07-07</strong></p>
              </div> -->
              <div class="invoice-details">
                <span><strong>Customer Address:-</strong></span>
                <span
                  ><strong
                    >Loan No./Application No.:{{
                      CompanyInformation?.LoanAcNo
                    }}</strong
                  ></span
                >
                <span>{{ CompanyInformation?.Customer_Name }}</span>
                <span>Address:{{ CompanyInformation?.Customer_Address }}</span>
                <span
                  >Phone no.:{{ CompanyInformation?.Customer_PhoneNo }}</span
                >
                <span>Email ID:{{ CompanyInformation?.Customer_Email }}</span>
                <span>GST No.:{{ CompanyInformation?.Customer_GSTNo }}</span>
              </div>
            </div>
            <table class="invoice-table">
              <thead>
                <tr>
                  <th colspan="6" style="text-align: left">
                    Particulars(Descriptions & Specifications)
                  </th>
                  <th style="text-align: center">HSN</th>
                  <th style="text-align: center">Amount</th>
                  <th style="text-align: center">SGST</th>
                  <th style="text-align: center">CGST</th>
                  <th style="text-align: center">IGST</th>
                  <th style="text-align: center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of TableData; let i = index">
                  <td style="text-align: left" colspan="6">
                    {{ x.ChargeHeadCategory }}
                  </td>

                  <td></td>
                  <td style="text-align: right">{{ x.Amount }}</td>
                  <td style="text-align: right">{{ x.SGST }}</td>
                  <td style="text-align: right">{{ x.CGST }}</td>
                  <td style="text-align: right">{{ x.IGST }}</td>
                  <td style="text-align: right">{{ x.Total }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="6" style="text-align: right">Total:</th>
                  <th></th>
                  <th>{{ totaltypewisemodal("Amount") }}</th>
                  <th>{{ totaltypewisemodal("SGST") }}</th>
                  <th>{{ totaltypewisemodal("CGST") }}</th>
                  <th>{{ totaltypewisemodal("IGST") }}</th>
                  <th>{{ totaltypewisemodal("Total") }}</th>
                </tr>
              </tfoot>
            </table>

            <div class="Total-Amount">
              <p>Amount In Word:- {{ AmountInWord }} only</p>
            </div>

            <div class="footer">
              <div class="created">
                <span style="margin-bottom: 10px"
                  >Narration:-{{ CompanyInformation?.Voucher_Narration }}</span
                >
                <span
                  >Mode of Payment:- {{ CompanyInformation?.Mode_of_Payment }}
                  <span>{{ CompanyInformation?.Voucher_Cheque_or_RefNo }}</span>
                </span>
                <span id="createdOn"
                  >Created On:-{{ CompanyInformation?.CreateOn }}</span
                >
                <span>Created By:-{{ CompanyInformation?.CreateBy }}</span>
              </div>
              <div class="signature">
                <span>{{ CompanyInformation?.CompanyName }}</span>
                <span id="sign"><strong>Authorised Signatory</strong></span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-2 mb-2">
          <button
            type="button"
            class="btn font-size-12 button-btn ml-2"
            (click)="PrintDoc('ReceiptBillPrint', 'Receipt Bill')"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
