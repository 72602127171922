import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }

  exportMatTableToExcel(dataSource: MatTableDataSource<any>, columns: any[], filename: string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Set columns headers in the Excel sheet
    worksheet.columns = columns.map(column => ({ header: column.header, key: column.field }));

    // Populate data rows in the Excel sheet
    dataSource.data.forEach(item => {
      worksheet.addRow(item);
    });

    const buffer = workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}.xlsx`;
      a.click();

      window.URL.revokeObjectURL(url);
    });
  }
}
