<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    class="formborder"
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchAccountStatement(false)"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">Account Head</span>

        <input
          type="text"
          [formControl]="AccountControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="chhead"
          name="chhead"
          required
          [class.alert-warning]="chhead == ''"
          placeholder="Account Head"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onAccountHeadhSelect($event)"
        >
          <mat-option
            *ngFor="let account of filteredAccountHead | async"
            [value]="account"
          >
            {{ account.Account_Name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-2">
        <span> Balance Type</span>
        <select
          name="BalanceType"
          id="BalanceType"
          class="form-control input-text-css"
          [(ngModel)]="BalanceType"
        >
          <option [value]="0">Annually</option>
          <option [value]="1">Monthly</option>
          <option [value]="2">Daily</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            (dateChange)="DateChange()"
            [min]="minDate"
            [max]="maxDate"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            required
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          (ngModelChange)="DateChange()"
          id="VoucherDateFrom"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher From"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            required
            #reffVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && reffVoucherDateTo.invalid,
              'alert-warning':
                reffVoucherDateTo.invalid &&
                (reffVoucherDateTo.dirty ||
                  reffVoucherDateTo.touched ||
                  reffVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>

        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          (ngModelChange)="DateChange()"
          id="VoucherDateTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher To"
          class="form-control input-text-css"
          bsDatepicker
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span> Entry</span>
        <select
          name="TransationDetail"
          id="TransationDetail"
          class="form-control input-text-css"
          [(ngModel)]="TransationDetail"
        >
          <option [value]="1">As Per Book</option>
          <option [value]="0">As Per Bank</option>
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSearchAccountStatement(false)"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid || !chhead"
        >
          Search
        </button>
      </div>
      <div class="col-md-12 float-right">
        <div class="col-md-4 float-right" *ngIf="dataSource">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilterAnnually($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light mt-2"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-4 float-right" *ngIf="dataSourceMonthly">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilterMonthly($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light mt-2"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-4 float-right" *ngIf="dataSourceDaily">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilterDaily($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light mt-2"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive" style="overflow: auto">
        <table
          class="table-bordered"
          mat-table
          matSort
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="voucherid">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="width50"
            >
              #
            </th>
            <td mat-cell *matCellDef="let row; let i = index" class="width50">
              {{ PaginatorList.pageIndex * PaginatorList.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="VoucherDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Voucher Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Voucher_Cheque_Clear_Date">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Clear Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Voucher_Cheque_Clear_Date }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Voucher_No">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Voucher No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="CaseNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
            <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="accHead">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>A/C Head</th>
            <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
            <th mat-footer-cell *matFooterCellDef>Total</th>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
            >
              DR Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeDR }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeDR() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
            >
              CR Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeCR }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeCR() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TranTypeTotal">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
            >
              Balance
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeTotal }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeTotal() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Narration">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Narration</th>
            <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="ViewAction">
            <th
              mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              View Detail
            </th>
            <td
              mat-cell
              *matCellDef="let row; let i = index"
              class="text-center"
            >
              <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                &nbsp;
              </div>
              <ng-template #elseBlock>
                <app-lms-voucher-view-model
                  [voucherid]="row.voucherid"
                  (close)="GetData($event)"
                  [index]="i"
                >
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                    aria-label="true"
                  ></i>
                </app-lms-voucher-view-model>
              </ng-template>
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-3 mr-3 pt-2 pb-1" [hidden]="!dataSource">
    <div class="col-md-2">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="exportExcelAnnual()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-2">
      <button class="mt-3 btn font-size-12 button-btn" (click)="printdata()">
        <i
          class="fa fa-print mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Print
      </button>
    </div>
    <div class="col-md-8 text-right">
      <mat-paginator
        #PaginatorList
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <div class="mt-3" *ngIf="dataSourceMonthly">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceMonthly"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 60px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 60px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Month_Year">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Month Year</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Month_Year }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Account_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Account Name
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="accHead">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Account Head</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.accHead }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              totaltypeMonthly("TranTypeDR")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >CR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              totaltypeMonthly("TranTypeCR")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeTotal">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeTotal }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateMonthlyBalance()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 100px; justify-content: center"
              *matHeaderCellDef
            >
              View Detail
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell text-center"
              style="max-width: 100px"
            >
              <div
                *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
              >
                &nbsp;
              </div>
              <ng-template #elseBlock>
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetailModel(row)"
                  style="cursor: pointer; font-size: 16px"
                  aria-label="true"
                ></i>
              </ng-template>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 100px"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsMonthly; sticky: true"
          ></mat-header-row>
          <mat-row
            [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
            *matRowDef="let row; columns: displayedColumnsMonthly"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsMonthly; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>

        <div class="row m-0 pt-2 pb-1">
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTableMonthly()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="printdata()"
            >
              <i
                class="fa fa-print mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Print
            </button>
          </div>
          <div class="col-md-8 text-right"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3" *ngIf="dataSourceDaily">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceDaily"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 60px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 60px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Account_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Account Name
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Voucher Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_Date | date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              totaltypeDaily("TranTypeDR")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >CR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              totaltypeDaily("TranTypeCR")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeTotal">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeTotal }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateDailyBalance()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 100px; justify-content: center"
              *matHeaderCellDef
            >
              View Detail
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell text-center"
              style="max-width: 100px"
            >
              <div
                *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
              >
                &nbsp;
              </div>
              <ng-template #elseBlock>
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetailModel(row)"
                  style="cursor: pointer; font-size: 16px"
                  aria-label="true"
                ></i>
              </ng-template>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 100px"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsDaily; sticky: true"
          ></mat-header-row>
          <mat-row
            [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
            *matRowDef="let row; columns: displayedColumnsDaily"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsDaily; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>

        <div class="row m-0 pt-2 pb-1">
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTableDaily()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="printdata()"
            >
              <i
                class="fa fa-print mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Print
            </button>
          </div>
          <div class="col-md-8 text-right"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="VoucherModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherModel()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div
              class="table-responsive"
              style="height: 363px; max-width: 100%; overflow: auto"
            >
              <table
                class="table-bordered"
                mat-table
                matSort
                [dataSource]="dataSourceModel"
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="voucherid">
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="width50"
                  >
                    #
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="width50"
                  >
                    {{ Paginglist.pageIndex * Paginglist.pageSize + (i + 1) }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="VoucherDate">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Voucher Date
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>
                <ng-container matColumnDef="Voucher_Cheque_Clear_Date">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Clear Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.Voucher_Cheque_Clear_Date }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>
                <ng-container matColumnDef="Voucher_No">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Voucher No
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="CaseNo">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Loan No
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="accHead">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    A/C Head
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
                  <th mat-footer-cell *matFooterCellDef>Total</th>
                </ng-container>

                <ng-container matColumnDef="TranTypeDR">
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-right"
                  >
                    DR Amount
                  </th>
                  <td mat-cell *matCellDef="let row" class="text-right">
                    {{ row.TranTypeDR }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ dataSourceModel ? totaltypeModel("TranTypeDR") : "" }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="TranTypeCR">
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-right"
                  >
                    CR Amount
                  </th>
                  <td mat-cell *matCellDef="let row" class="text-right">
                    {{ row.TranTypeCR }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ dataSourceModel ? totaltypeModel("TranTypeCR") : "" }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="TranTypeTotal">
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-right"
                  >
                    Balance
                  </th>
                  <td mat-cell *matCellDef="let row" class="text-right">
                    {{ row.TranTypeTotal }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ dataSourceModel ? totaltypeModelBal() : "" }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="Narration">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Narration
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="ViewAction">
                  <th
                    mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    View Detail
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="text-center"
                  >
                    <div
                      *ngIf="row.accHead == 'Opening Balance'; else elseBlock"
                    >
                      &nbsp;
                    </div>
                    <ng-template #elseBlock>
                      <app-lms-voucher-view-model
                        [voucherid]="row.voucherid"
                        (close)="GetData($event)"
                        [index]="i"
                      >
                        <i
                          class="fa fa-arrow-circle-right arrow-circle action-btn"
                          aria-label="true"
                        ></i>
                      </app-lms-voucher-view-model>
                    </ng-template>
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsModel; sticky: true"
                ></tr>
                <tr
                  mat-row
                  [ngClass]="{ Row_Bold: row.accHead == 'Opening Balance' }"
                  *matRowDef="let row; columns: displayedColumnsModel"
                ></tr>
                <tr
                  mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedColumnsModel; sticky: true"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="row m-0 pt-2 pb-1">
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportExcelDetails()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-12 text-right">
            <mat-paginator
              #Paginglist
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="BankBook" style="display: none">
  <table class="table-bordered">
    <tr style="background: #4dc3a3 !important; text-align: left">
      <th colspan="2">Account Head</th>
      <th colspan="2">Date From</th>
      <th colspan="2">Date To</th>
    </tr>
    <tr style="text-align: left">
      <td colspan="2">{{ AccountHeadName }}</td>
      <td colspan="2">{{ VoucherDateFrom | date : "dd/MM/YY" }}</td>
      <td colspan="2">{{ VoucherDateTo | date : "dd/MM/YY" }}</td>
    </tr>
  </table>
  <table class="table-bordered" mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="voucherid">
      <th mat-sort-header mat-header-cell *matHeaderCellDef class="width50">
        #
      </th>
      <td mat-cell *matCellDef="let row; let i = index" class="width50">
        {{ PaginatorList.pageIndex * PaginatorList.pageSize + (i + 1) }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="VoucherDate">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Voucher Date</th>
      <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="Voucher_Cheque_Clear_Date">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Clear Date</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Voucher_Cheque_Clear_Date }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="Voucher_No">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Voucher No</th>
      <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="CaseNo">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
      <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="accHead">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>A/C Head</th>
      <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
      <th mat-footer-cell *matFooterCellDef>Total</th>
    </ng-container>

    <ng-container matColumnDef="TranTypeDR">
      <th mat-sort-header mat-header-cell *matHeaderCellDef class="text-right">
        DR Amount
      </th>
      <td mat-cell *matCellDef="let row" class="text-right">
        {{ row.TranTypeDR }}
      </td>
      <th mat-footer-cell *matFooterCellDef class="text-right">
        {{ dataSource ? calculateACTranTypeDR() : "" }}
      </th>
    </ng-container>

    <ng-container matColumnDef="TranTypeCR">
      <th mat-sort-header mat-header-cell *matHeaderCellDef class="text-right">
        CR Amount
      </th>
      <td mat-cell *matCellDef="let row" class="text-right">
        {{ row.TranTypeCR }}
      </td>
      <th mat-footer-cell *matFooterCellDef class="text-right">
        {{ dataSource ? calculateACTranTypeCR() : "" }}
      </th>
    </ng-container>

    <ng-container matColumnDef="TranTypeTotal">
      <th mat-sort-header mat-header-cell *matHeaderCellDef class="text-right">
        Balance
      </th>
      <td mat-cell *matCellDef="let row" class="text-right">
        {{ row.TranTypeTotal }}
      </td>
      <th mat-footer-cell *matFooterCellDef class="text-right">
        {{ dataSource ? calculateACTranTypeTotal() : "" }}
      </th>
    </ng-container>

    <ng-container matColumnDef="Narration">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Narration</th>
      <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumnsExport; sticky: true"
      style="background: #4dc3a3 !important; text-align: left"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsExport"></tr>
    <tr
      mat-footer-row
      class="sticky-footer fontcolor"
      *matFooterRowDef="displayedColumnsExport; sticky: true"
      style="background: #4dc3a3 !important; text-align: left"
    ></tr>
  </table>
</div>

<table
  id="bank_book_annually"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Clear Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      A/C Head
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Narration
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.VoucherDate }}</td>
    <td>{{ x.Voucher_Cheque_Clear_Date }}</td>
    <td>{{ x.Voucher_No }}</td>
    <td>{{ x.CaseNo }}</td>
    <td>{{ x.accHead }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.TranTypeTotal }}</td>
    <td>{{ x.Narration }}</td>
  </tr>
  <tfoot>
    <tr class="table-footer">
      <td
        colspan="6"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeDR() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeCR() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeTotal() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
    </tr>
  </tfoot>
</table>

<table
  id="bank_book_monthly"
  *ngIf="dataSourceMonthly"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Month Year
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Acocunt Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Month_Year }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.TranTypeTotal }}</td>
  </tr>
  <tfoot>
    <tr class="table-footer">
      <td
        colspan="3"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypeMonthly("TranTypeDR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypeMonthly("TranTypeCR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateMonthlyBalance() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="bank_book_daily"
  *ngIf="dataSourceDaily"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Month Year
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Acocunt Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Voucher_Date | date }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.TranTypeTotal }}</td>
  </tr>
  <tfoot>
    <tr class="table-footer">
      <td
        colspan="3"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypeDaily("TranTypeDR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypeDaily("TranTypeCR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateDailyBalance() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="bank_book_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Clear Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      A/C Head
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Narration
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.VoucherDate }}</td>
    <td>{{ x.Voucher_Cheque_Clear_Date }}</td>
    <td>{{ x.Voucher_No }}</td>
    <td>{{ x.CaseNo }}</td>
    <td>{{ x.accHead }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.TranTypeTotal }}</td>
    <td>{{ x.Narration }}</td>
  </tr>
  <tfoot>
    <tr class="table-footer">
      <td
        colspan="6"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSourceModel ? totaltypeModel("TranTypeDR") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSourceModel ? totaltypeModel("TranTypeCR") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSourceModel ? totaltypeModelBal() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
    </tr>
  </tfoot>
</table>

<div
  class="modal fade in"
  id="BankBookPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cash Book
        </h6>
        <button
          type="button"
          (click)="onCloseBankBookPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              mat-raised-button
              class="mt-3 btn font-size-12 button-btn ml-2"
              (click)="onDocsPrint('BankBookcontent', 'Bank Book')"
            >
              Print
            </button>
          </div>
        </div>
        <div class="col-md-10 offset-1" id="BankBookcontent">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>
          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Bank Book for : {{ AccountHeadName }}
          </div>
          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
              "
            >
              Bank Book for the period of -
              {{ VoucherDateFrom | date : "dd/MM/yyyy" }} to
              {{ VoucherDateTo | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table
              id="bank_book_annually"
              *ngIf="dataSource"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr class="table-header">
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    #
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Voucher Date
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Clear Date
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Voucher No
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Loan No
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    A/C Head
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    DR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    CR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Balance
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Narration
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.VoucherDate }}</td>
                <td>{{ x.Voucher_Cheque_Clear_Date }}</td>
                <td>{{ x.Voucher_No }}</td>
                <td>{{ x.CaseNo }}</td>
                <td>{{ x.accHead }}</td>
                <td>{{ x.TranTypeDR }}</td>
                <td>{{ x.TranTypeCR }}</td>
                <td>{{ x.TranTypeTotal }}</td>
                <td>{{ x.Narration }}</td>
              </tr>

              <tr class="table-footer">
                <td
                  colspan="6"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <b>Total:</b>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{
                    dataSource ? calculateACTranTypeDR() : ""
                  }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{
                    dataSource ? calculateACTranTypeCR() : ""
                  }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{
                    dataSource ? calculateACTranTypeTotal() : ""
                  }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                ></td>
              </tr>
            </table>

            <table
              id="bank_book_monthly"
              *ngIf="dataSourceMonthly"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr class="table-header">
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    #
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Month Year
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Acocunt Name
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    DR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    CR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Balance
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.Month_Year }}</td>
                <td>{{ x.Account_Name }}</td>
                <td>{{ x.TranTypeDR }}</td>
                <td>{{ x.TranTypeCR }}</td>
                <td>{{ x.TranTypeTotal }}</td>
              </tr>

              <tr class="table-footer">
                <td
                  colspan="3"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <b>Total:</b>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ totaltypeMonthly("TranTypeDR") }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ totaltypeMonthly("TranTypeCR") }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateMonthlyBalance() }}</strong>
                </td>
              </tr>
            </table>
            <!-- <div style="float: right; text-align: right; font-family:'Times New Roman', Times, serif;">Issue
              Date : {{Cur_Date | date:'dd/MM/yyyy'}}
            </div> -->
            <table
              id="bank_book_daily"
              *ngIf="dataSourceDaily"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr class="table-header">
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    #
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Month Year
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Acocunt Name
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    DR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    CR Amount
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Balance
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.Voucher_Date | date }}</td>
                <td>{{ x.Account_Name }}</td>
                <td>{{ x.TranTypeDR }}</td>
                <td>{{ x.TranTypeCR }}</td>
                <td>{{ x.TranTypeTotal }}</td>
              </tr>

              <tr class="table-footer">
                <td
                  colspan="3"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <b>Total:</b>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ totaltypeDaily("TranTypeDR") }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ totaltypeDaily("TranTypeCR") }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateDailyBalance() }}</strong>
                </td>
              </tr>
            </table>
            <div
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date & Time: {{ Cur_Date | date : "dd/MM/yyyy hh:mm:ss" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
