import {
  CdkDragDrop,
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import * as moment from "moment";
import { MatPaginator } from "@angular/material/paginator";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LosService } from "src/app/_LOS/services/los.service";
// import * as XLSX from 'xlsx';
// import htmlToXlsx from 'html-to-xlsx';

@Component({
  selector: "app-resport-dump-data",
  templateUrl: "./resport-dump-data.component.html",
  styleUrls: ["./resport-dump-data.component.scss"],
})
export class ResportDumpDataComponent implements OnInit, OnDestroy {
  currentUserId: any;
  DateSelected = new Date();
  showSpinner = false;
  selectedRow = -1;
  columns: any[] = [];
  columnshow = [];
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginatorRef") paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;

  constructor(
    private snackBar: MatSnackBar,
    private reportService: ReportsService,
    private changeDetection: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private losService: LosService
  ) {
    this.currentUserId = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).userId;
    var k = localStorage.getItem("keys");
    if (k != null) {
      this.displayedColumns = JSON.parse(k).keys;
    }
  }
  ngOnDestroy(): void {
    localStorage.setItem(
      "keys",
      JSON.stringify({ keys: this.displayedColumns })
    );
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Master Component");
    this.dataSource.sort = this.sort;
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  selectHighligh(row) {
    this.selectedRow = row;
  }

  checkedChange(event) {
    var column = event.target.id;
    if (event.target.checked) {
      this.AddColumnToDisplay(column);
    } else {
      this.removeColumnFromDisplay(column);
    }
    console.log("sdfsdfsdf", event.target.checked);
    this.changeDetection.detectChanges();
  }

  onColumnDrop(event: CdkDragDrop<string[]>): void {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    console.log("event ", event);
    if (event.container.id == "DeleteContainer")
      this.removeColumnFromDisplay(event.item.data.name);

    if (event.item.dropContainer.id == "DeleteContainer") {
      var col = event.item.data.name;
      this.AddColumnToDisplayPosition(col, currentIndex);
    }

    moveItemInArray(this.displayedColumns, previousIndex, currentIndex);
  }

  removeColumnFromDisplay(str) {
    var ind = this.displayedColumns.indexOf(str);
    if (ind >= 0) this.displayedColumns.splice(ind, 1);
    if (!this.columnshow.includes(str)) this.columnshow.push(str);
  }

  AddColumnToDisplay(str) {
    if (!this.displayedColumns.includes(str)) this.displayedColumns.push(str);
    var ind = this.columnshow.indexOf(str);
    if (ind >= 0) this.columnshow.splice(ind, 1);
  }
  AddColumnToDisplayPosition(str, position) {
    if (!this.displayedColumns.includes(str))
      this.displayedColumns.splice(position, 0, str);

    var ind = this.columnshow.indexOf(str);
    if (ind >= 0) this.columnshow.splice(ind, 1);
  }

  //Get Requests

  Get_Dump_Data() {
    this.showSpinner = true;
    this.reportService
      .Report_Dump_Data({
        Emp_Id: this.currentUserId,
        Date: moment(this.DateSelected).format("YYYY-MM-DD"),
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe(
        (result: any[]) => {
          if (result.length > 0) {
            this.dataSource = new MatTableDataSource(result);
            this.dataSource.paginator = this.paginator;
            this.columns = [];
            //get all keys
            var key = Object.keys(result[0]);
            // add to column variable
            if (this.displayedColumns.length == 0) this.displayedColumns = key;
            key.forEach((x) => {
              this.columns.push({ field: x });
              if (!this.displayedColumns.includes(x)) {
                this.columnshow.push(x);
              }
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.showSpinner = false;
            this.dataSource = null;
          }
          this.showSpinner = false;
        },
        () => {
          this.showSpinner = false;
        }
      );
  }

  exportTableAsXLSX(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "master_component"
    ) as HTMLTableElement;
    const worksheetName = "MASTER COMPONENT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
}
