import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-crc-master',
  templateUrl: './crc-master.component.html',
  styleUrls: ['./crc-master.component.scss']
})
export class CrcMasterComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = 0;
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  applicationId: any = 95;
  dataCustomerSource: any;
  CrcType: any = '';
  crcCode:any='';
  CRCAgency: any = '';
  saveBtn:boolean = true;
  updateBtn:boolean = false;
  Id:number;
  CRC_Agency: any;
  Upadte_cRC_Loan:any;
  update_crcAgency:any;
  Upadte_cRC_Code:any;
  

  



  displayedCustomerColumns: string[] = ['Number', 'CRC Type', 'CRC Agent', 'Crc_Code','Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('CRC Type Master');
    // this._MasterService.GetRoleForDropdown().subscribe((result) => {
    //   this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    // });
    this.getAgency();
    this.LMS_GetCrcMaster();
  }

  LMS_GetCrcMaster() {
    this.showSpinner = true;
    this._LmsService.Get_Lms_CrcMaster().subscribe((response) => {
      this.dataCustomerSource = new MatTableDataSource(response);
    });
  }
  getAgency() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'CRC AGENCY' }).subscribe((res: any) => {
      console.log(res);
      this.CRC_Agency = res;
    });

  }
  onSaveCrc() {
    let saveData  = {
      "Int_Id": 0,
      "Crc_Type": this.CrcType,
      "Crc_Agency": this.CRCAgency,
      "Crc_Code":this.crcCode,
      "IsActive": 0,
      
    }

    this._LmsService.Save_Lms_Crc_Master(saveData).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetCrcMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  removeCrcModel(data: any) {
    
    this.dialog.openConfirmDialog(`Are you sure you want to delete this Crc Type ${data.Crc_Type}.`)
    .afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
      this._LmsService.Delete_Lms_CrcModelMaster({ Int_Id: data.Int_Id }).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetCrcMaster();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
      }
    });
  }
  
  applyFilter(filterValue:string){
    this.dataCustomerSource.filter=filterValue.trim().toLowerCase();
  }

  OnClose(){
    
    $('#crcType').modal('hide');
  }
  
  editCrc(data:any) {
    // console.log('this.areaId',data);
    this.Upadte_cRC_Loan = data.Crc_Type.trim();
    this.update_crcAgency = data.Crc_AgencyId;
    this.Upadte_cRC_Code=data.Crc_Code,
    this.Id = data.Int_Id;
    this.saveBtn = false;
    this.updateBtn = true;
    $('#crcType').modal('show');
    $("#crcType").css("z-index", "1050");
  }

  // UpdateCrc() {
  //   this.saveBtn = true;
  //   this.updateBtn = false;
  //   let saveData = {
  //     "Int_Id": this.Id,
  //     "Crc_Type": this.CrcType,
  //     "Crc_Agency": this.CRCAgency,
  //     "Crc_Code":this.crcCode,
  //     "IsActive": 0,
  //   }

  //   this._LmsService.Save_Lms_Crc_Master(saveData).subscribe((response) => {
  //     if (response[0].CODE == 0) {
  //       this.Id = 0;
  //       this.LMS_GetCrcMaster();
  //       this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //     } else {
  //       this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //     }
  //   });
  // }
  OnUpdate(){
    this.saveBtn = true;
    this.updateBtn = false;
    let saveData = {
      "Int_Id": this.Id,
      "Crc_Type": this.Upadte_cRC_Loan,
      "Crc_Agency": this.update_crcAgency,
      "Crc_Code":this.Upadte_cRC_Code,
      "IsActive": 0,
    }

    this._LmsService.Save_Lms_Crc_Master(saveData).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.Id = 0;
        this.LMS_GetCrcMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }


}
