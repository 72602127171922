import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
import * as moment from "moment";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";

@Component({
  selector: "app-lms-amortization",
  templateUrl: "./lms-amortization.component.html",
  styleUrls: ["./lms-amortization.component.scss"],
})
export class LmsAmortizationComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "Period",
    "DueDate",
    "EMI_Amount",
    "Principle",
    "Interest",
    "Outstanding_Principle",
  ];
  FileCheckingList: any[] = [];
  dataSourceAllocation: any;
  Cur_Date: any = new Date();
  // loanSummary: any = {};

  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  displayedColumnsAllocation: string[] = [
    "Voucher_Id",
    "VoucherDate",
    "Voucher_No",
    "EMI_No",
    "AllocatedAmt",
    "Principle",
    "Interest",
    "Action",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loading: boolean = false;
  amortizationListList: any[] = [];

  @Input() loanSummary: any;

  @Input() PageType: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  tab: any = "Amortization";

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private MasterService: MasterService,
    private dialog: DialogService,
    private _commonfunctions: CommonFunctions
  ) {}

  ngOnInit() {
    console.log("loan sunmmary", this.loanSummary);
    this.dataSharingService.HeaderTitle.next("Amortization Chart");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.showSpinner = true;
    this.LMS_Get_Amortization_Chart();
    this.LMS_Get_EMI_Allocation();
    console.log("data", this.amortizationListList);
  }
  LMS_Get_Amortization_Chart() {
    this.lmsService
      .LMS_Get_Amortization_Chart({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        res.forEach((val) =>
          this.amortizationListList.push(Object.assign({}, val))
        );
        // this.amortizationListList = res;
        //this.dataSource = new MatTableDataSource(res);
        res.forEach((val) =>
          this.FileCheckingList.push(Object.assign({}, val))
        );

        this.FileCheckingList = JSON.parse(
          JSON.stringify(this.FileCheckingList)
        );
        var data = [];
        res.forEach((val) => data.push(Object.assign({}, val)));

        console.log("red", res);
        this.dataSource = new MatTableDataSource(data);
        this.getTotal();
        this.showSpinner = false;
      });
  }
  getTotal() {
    var Interest = 0,
      principal = 0;
    this.amortizationListList.forEach((obj: any) => {
      if (obj.EMI_Amount == 0) {
        Interest = Interest + obj.Interest;
        principal = principal + obj.Principle;
      } else {
        obj.Interest = obj.Interest + Interest;
        obj.Principle = obj.Principle + principal;
        Interest = 0;
        principal = 0;
      }
    });
    let emiTotal = 0,
      principleTotal = 0,
      interestTotal = 0,
      outstanding_Principle = 0;

    this.amortizationListList = this.amortizationListList.filter(
      (item) => item.EMI_Amount !== 0
    );
    this.amortizationListList.forEach((obj: any, index: any) => {
      emiTotal = emiTotal + obj.EMI_Amount;
      principleTotal = principleTotal + obj.Principle;
      interestTotal = interestTotal + obj.Interest;
      outstanding_Principle = outstanding_Principle + obj.Outstanding_Principle;
    });
    if (this.amortizationListList.length > 0) {
      this.amortizationListList.push({
        DueDate: "Total",
        EMI_Amount: emiTotal,
        Principle: principleTotal,
        Interest: interestTotal,
        Outstanding_Principle: "",
        isBold: true,
      });
    }
    console.log("data", this.amortizationListList);
  }
  // exportTable(exporter: any) {
  //   exporter.exportTable('xls', { fileName: `lms-amortization-list`, sheet: 'Amortization' });
  // }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "amortization_chart"
    ) as HTMLTableElement;
    const worksheetName = "Amortization_chart"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  // calculateEmiTotal() {

  //   let emiTotal = 0, principleTotal = 0, interestTotal = 0;
  //   this.amortizationListList.forEach((obj: any) => {
  //     emiTotal = emiTotal + obj.EMI_Amount;
  //     principleTotal = principleTotal + obj.Principle;
  //     interestTotal = interestTotal + obj.Interest;
  //   });
  //   if (this.amortizationListList.length > 0) {
  //     this.amortizationListList.push({ DueDate: "Total", EMI_Amount: emiTotal, Principle: principleTotal, Interest: interestTotal,isBold:true });
  //   }

  // if (this.dataSource != undefined) {
  //   if (
  //     this.dataSource != null &&
  //     this.dataSource != undefined
  //   ) {
  //     let sum = 0.0;
  //     for (let i = 0; i < this.dataSource.filteredData.length; i++) {
  //       sum += Number(this.dataSource.filteredData[i]["EMI_Amount"]);
  //     }
  //     return sum.toFixed(2);
  //   }
  //   return 0;
  // }
  // }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  LMS_Get_EMI_Allocation() {
    this.lmsService
      .LMS_Get_EMI_Allocation({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        this.dataSourceAllocation = new MatTableDataSource(res);
        this.showSpinner = false;
      });
  }

  totaltypewise(type) {
    if (this.dataSourceAllocation != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceAllocation.filteredData.length; i++) {
        sum += Number(this.dataSourceAllocation.filteredData[i][type]);
      }
      return sum > 0 ? sum : "";
    }
    return 0;
  }

  totaltypewiseAmortization(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum : "";
    }
    return 0;
  }

  LMSUpdateLoanEMIAllocation() {
    this.dialog
      .openConfirmDialog(`Are you sure? you want to regenerate allocation.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          // console.log("ernk;jvad",this.loanSummary)
          this.lmsService
            .LMS_Update_Loan_EMIAllocation({ Loan_Id: this.loanSummary.LoanId })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.LMS_Get_EMI_Allocation();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  amortization_chart_print_Close() {
    $("#amortization_chart_print").modal("hide");
  }

  printdata() {
    $("#amortization_chart_print").modal("show");
    $("#amortization_chart_print").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }
}
