<div class="row m-0 formborder">
  <div class="col-md-4 text-right">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            (keyup)="applyFilter($event.target.value)"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="text-right col-md-4 my-1 pr-2">
    <button
      (click)="OnAddDurableCategory()"
      type="button"
      class="btn font-size-12 button-btn"
    >
      Add
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <mat-table [dataSource]="dataSourceCategory">
    <ng-container matColumnDef="Id">
      <mat-header-cell
        class="grid-header"
        style="max-width: 50px"
        *matHeaderCellDef
        >#</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="grid-cell"
        style="max-width: 50px; max-height: 5px"
        >{{ i + 1 }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="Category_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Category Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Category_Name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
        >Action</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
        <i
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          aria-label="true"
          (click)="editCategoryName(row)"
        ></i>
        <i
          class="fa fa-trash style-delete"
          (click)="deleteCategoryName(row)"
          aria-label="true"
        ></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumn"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumn"></mat-row>
  </mat-table>
</div>

<div
  class="modal fade in"
  id="editCategory"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSaveDurableCategory()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ ButtonName }} Category
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Category Name </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refcategoryName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refcategoryName.invalid,
                  'alert-warning':
                    refcategoryName.invalid &&
                    (refcategoryName.dirty ||
                      refcategoryName.touched ||
                      refcategoryName.untouched)
                }"
                [(ngModel)]="categoryName"
                placeholder="Add Category Name"
                name="categoryName"
                id="categoryName"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnSaveDurableCategory()"
            class="mt-3 btn font-size-12 button-btn"
            [disabled]="!f.form.valid"
            data-dismiss="modal"
            aria-label="Close"
          >
            {{ SaveUpdateButton }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
