import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, Type } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DialogService } from '../../Shared/dialog.service'
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { DatePipe } from '@angular/common';
import { PartnerService } from "../services/partner.service";

@Component({
  selector: 'app-partner-layout-tab',
  templateUrl: './partner-layout-tab.component.html',
  styleUrls: ['./partner-layout-tab.component.scss']
})
export class PartnerLayoutTabComponent implements OnInit {
  isNew: boolean = false;
  currentUser: any;
  LoanId: any;
  Type: any;
  loanSummary: any = {};
  isGetDetail: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;

  PLoanDetail: any;
  PLoan_ID: any;
  PageType: any;


  constructor(private route: ActivatedRoute, private datePipe: DatePipe, 
    private MasterService: MasterService, private _Route: Router, 
    private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private http: HttpClient, 
    private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private _location: Location, 
    private dataSharingService: DataSharingService, private dialog: DialogService,
    private LmsService: LmsService,
    private _Partnerservice: PartnerService,
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.Type = decodeURIComponent(param.get("Type"));
      this.PageType = this.Type;
      console.log("Type :", this.Type);
      this.isNew = param.keys.length == 0 || this.Type.toLowerCase() == 'a';
      if (this.isNew) {
        this.isGetDetail = true;
      }
      this.getLoanInfo();
    });

  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
  }

  getLoanInfo() {
    this.LmsService.LMS_Get_Loan_Summary({ EncLoanId: this.LoanId }).subscribe((res: any) => {
      this.loanSummary = res[0];
      this.getActiveTab();
      
      sessionStorage.removeItem("Partner_LoanId");
      this._Partnerservice.Partner_Get_Existing_Loan_Details({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
        this.PLoan_ID = res[0].Partner_LoanId;
        sessionStorage.setItem('Partner_LoanId', res[0].Partner_LoanId);
        if(res[0].Partner_LoanId > 0){
          this.Get_Partner_Loan_Details();
        }
      }); 
    });
  }
  getActiveTab() {
    this.currentTab = 0;
    if (this.loanSummary.IsLoanInfo) {
      this.currentTab = 0;
      this.lastFillTab = 0;
    }
    if (this.loanSummary.IsAccountInfo) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsLoanNo || this.loanSummary.IsAmortizationInfos) {
      this.currentTab = 5;
      this.lastFillTab = 5;
     
      if(this.Type == 'L'){
        this.currentTab = 0;
      }
    }
    
    this.isGetDetail = true;
  }
  onChildAction(event: any) {
    if (event == "next") {
      if (this.currentTab - 1 == this.lastFillTab) {
        this.lastFillTab = this.lastFillTab + 1;
      }
      if (this.currentTab == 5 && !this.loanSummary.LoanNo) {
        this.getLoanInfo();
      }
      if (this.currentTab == 1 && !this.loanSummary.Customer) {
        this.getLoanInfo();
      }
      this.currentTab = this.currentTab + 1;
    }
  }
  onTabChange(event: any) {
    this.currentTab = event.index;
  }

  // this is for Partner Top box information
  Get_Partner_Loan_Details() {
    this._Partnerservice.Partner_Get_Loan_Details({ LoanId: this.loanSummary.LoanId}).subscribe((res: any) => {
      this.PLoanDetail = res[0];
    });
  }
}
