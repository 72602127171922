import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import * as moment from "moment";

@Component({
  selector: "app-rptchargecollection",
  templateUrl: "./rptchargecollection.component.html",
  styleUrls: ["./rptchargecollection.component.scss"],
})
export class RptchargecollectionComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "Id",
    "ApplicationNo",
    "Customer",
    "Branch",
    "LoanProduct",
    "ChagesHead",
    "AmountTaken",
    "ReceiptDeduction",
    "CollectionOn",
    "CollectionBy",
  ];
  RequestModel: RequestModel = new RequestModel();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ChargeCollection: any = [];
  ChargeHeadId: any = "0";

  FromDate: any = "";
  ToDate: any = "";
  CurrentDate: any = new Date();
  ChargesHeadDropDown: any = [];

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    let now = moment();

    this.ToDate = moment(now, "DD/MM/YYYY").toDate();
    this.FromDate = moment(now, "DD/MM/YYYY").add(0, "days").toDate();
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Charges Collection Report");
    this._MasterService.GetProductChargesHeadDropDown().subscribe((result) => {
      this.ChargesHeadDropDown = JSON.parse(JSON.stringify(result));
    });
  }

  getChargeCollection() {
    if (this.FromDate == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select From Date.",
        ...this.configSuccess,
      });
      return;
    }

    if (this.ToDate == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select To Date.",
        ...this.configSuccess,
      });
      return;
    }
    this.showSpinner = true;
    this.RequestModel.ChargeHeadId = this.ChargeHeadId;
    this.RequestModel.FromDate = moment(this.FromDate).format("YYYY/MM/DD");
    this.RequestModel.ToDate = moment(this.ToDate).format("YYYY/MM/DD");
    this._MasterService
      .LOS_rptChargeReports(this.RequestModel)
      .subscribe((result) => {
        this.ChargeCollection = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.ChargeCollection);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
}
