<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="row m-0 formborder d-flex align-items-center">
  <div class="col-md-3">
    <span>Select Status</span>
    <!-- (change)="GetLegalCasesList()" -->
    <select
      name="Status"
      id="Status"
      #refStatus="ngModel"
      class="form-control input-text-css"
      [(ngModel)]="Status"
    >
      <option value="">Select Status</option>
      <option value="Pending">Pending For Suit Filed</option>
      <option value="Legal">Legal Cases</option>
    </select>
  </div>

  <div class="col-md-2 text-right mt-3">
    <button
      type="button"
      (click)="GetLegalCasesList()"
      class="btn font-size-12 button-btn"
    >
      Search
    </button>
  </div>
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-2 mt-2 text-right">
    <app-legal-cases-model
      (close)="GetData($event)"
      [dindex]="7878868656589877989"
    >
      <button
        type="button"
        class="btn font-size-12 button-btn"
        style="width: max-content"
      >
        Add New Case
      </button>
    </app-legal-cases-model>
  </div>
</div>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        id="exportReport"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="LegalId">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Case_Filed_By">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Case Filed By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Case_Filed_By }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Date_Of_Case_Filing">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Case Filed Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Date_Of_Case_Filing }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Created_On">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Created On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Created_On }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Notice_Type">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Notice Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Notice_Type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DPD_Days">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >DPD Days</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DPD_Days
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Total_OverDUE_EMI__Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Overdue EMI Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Total_OverDUE_EMI__Amount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 60px"
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 60px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              aria-label="true"
              (click)="GoToDetails(row)"
              *ngIf="Status == 'Legal'"
            ></i>
            <app-legal-cases-model
              [LoanData]="row"
              (close)="GetData($event)"
              *ngIf="Status == 'Pending'"
              [dindex]="i"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                aria-label="true"
              ></i>
            </app-legal-cases-model>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="cold-md-12 d-flex justify-content-between align-items-center">
        <div class="col-md-4">
          <button class="btn font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="legal_cases_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th
      *ngIf="Status == 'Pending'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Created On
    </th>
    <th
      *ngIf="Status == 'Pending'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Notice Type
    </th>
    <th
      *ngIf="Status == 'Legal'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Case Filed By
    </th>
    <th
      *ngIf="Status == 'Legal'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Case Filed Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OverDue EMI Amount
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td *ngIf="Status == 'Pending'">{{ x.Created_On }}</td>

    <td *ngIf="Status == 'Pending'">{{ x.Notice_Type }}</td>
    <td *ngIf="Status == 'Legal'">{{ x.Case_Filed_By }}</td>
    <td *ngIf="Status == 'Legal'">{{ x.Date_Of_Case_Filing }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.Total_OverDUE_EMI__Amount }}</td>
  </tr>
</table>
