<section class="main-content-wrapper"> 
  <div id="wrapper" >
    <aside id="sidebar-wrapper" style="background-color: #11539942 !important; ">
      <div class="sidebar-nav">
        <!-- menus -->
        <nav id="side-bar" class="side-bar">
          <div class="side-bar-content">
            <div class="side-bar-menu">
              <ul>
                <li *ngFor="let menu of MenuslinksNew"
                  [ngClass]="{'active': !menu.MM_IsShow , 'side-bar-dropdown':menu.MM_IsParent , 'header-menu':menu.MM_ParentID !== 0}">


                  <a *ngIf="menu.MM_ParentID == 0"
                    [class.nav-bar-active]="isLinkActive((!menu.MM_IsParent)&&[menu.MM_Url])"
                    [routerLink]="(menu.MM_IsParent)?[]:[menu.MM_Url]" (click)='toggleNew(menu)'>
                    <i class={{menu.MM_iconName}} style="font-size:11px" aria-hidden="true"></i><span
                      style="font-size:11px">{{menu.MM_Name}}</span>
                  </a>
                  <div *ngIf="menu.MM_IsParent" class="side-bar-submenu" [@slide]="getStateNew(menu)">
                    <ul>
                      <li *ngFor="let submenu of MenuslinksNew">
                        <a *ngIf="menu.MM_ID == submenu.MM_ParentID" (click)="onMenuclick()"
                          [class.nav-bar-active]="isLinkActive((!submenu.MM_IsParent)&&[submenu.MM_Url])"
                          [routerLink]="(submenu.MM_IsParent)?[]:[submenu.MM_Url]">
                          <span style="margin-left: 14px;font-size:11px"><i class="fa fa-arrow-right"
                              style="font-size:11px" aria-hidden="true"></i>{{submenu.MM_Name}}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </div>
    </aside>

    <div id="navbar-wrapper">
      <nav class="navbar navbar-inverse">
        <div class="container-fluid" style=" display: initial!important;">
          <div class="row m-0">
            <div class="navbar-header col-md-2">
              <a href="#" class="navbar-brand" id="sidebar-toggle"><i class="fa fa-bars"></i></a>
            </div>
            <div class="font-size-15 col-md-6" style="text-align: center;">
              <span class="navbar-text">{{HeaderTitle}} </span >
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </nav>
    </div>

    <div class="main contents">
      <router-outlet></router-outlet>
    </div>
  </div>   

</section>
