import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { MatTableDataSource } from "@angular/material/table";

import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { DepositService } from "../services/deposit.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { LosService } from '../../_LOS/services/los.service';
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-deposit-add',
  templateUrl: './deposit-add.component.html',
  styleUrls: ['./deposit-add.component.scss']
})
export class DepositAddComponent implements OnInit {
  currentUser: any; 
  showSpinner: boolean = false;
  loading: boolean = false;
  FDR_Detail: any = {};
  DepositId: any = 0;
  FDR_Customer: any = {};
  FDR_Nominee: any;

  @Input() DepositSummary: any;
  IsUpdate: boolean = false;
  IsSave: boolean = false;

  ProductList: any = [];
  ProductListDropdown: any = [];
  BranchesList: any = [];
  partnerDropdown: any = [];
  SalesExecutiveData: any = [];
  leadSourceDropdown: any = [];

  CData: any[] = [];
  NomineeData: any[] = [];
  AmortizationData: any=[];

  dataCustomerSource: any;
  dataCustomerNominee: any;
  dataAmortization: any;
  displayedCustomerColumns: string[] = ['CustomerId', 'Customer', 'CustomerType', 'GenderAge', 'Relation_Type', 'PhoneNo', 'Present_Address', 'Action'];
  displayedNomineeColumns: string[] = ['NomineeId', 'Nominee_Relation', 'Nominee_Name', 'Nominee_DOB', 'Nominee_Ratio', 'Action'];
  displayedAmortizationColumns: string[] = ['Id', 'Period', 'Due_Date', 'Interest', 'Payment', 'Interest_Balance'];

  CustomerSummary: any;
  CustomerBankDropdown: any = [];
  accountTagDropdown: any[] = [];
  BankDetails: any[] = [];
  BankInfo: any;
  CustomerId: any = '';
  PageFrom: any;
  Statustype: any;

  Status: any = '';
  Remark: any;
  
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  showLess_ProductDetail: any = [];
  ProductAll: any = [];
  ProductModel: any = [];
  Product_Data: any;
  minDate: any;
  BtnSave: boolean = true;
  BtnUpdate: boolean = false;
  index: any;
  NomineeSelect: any;
  Tenure_Max: any;
  Tenure_Min: any;
  PRD_Start_Date: any;
  PRD_End_Date: any;

  dialogreturn: any;
  MinDateFDRD: any;
  MaxDateFDRD: any;

  constructor(
    private dataSharingService: DataSharingService,
    private _Route: Router,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private _DepositService: DepositService,
    private losService: LosService,
    private lmsService: LmsService,
    private _MasterService: MasterService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit Product');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
    this.FDR_Detail = {
      Deposit_Id: 0,
      Deposit_Type: '', 
      Deposit_ProductId: '',
      Deposit_BranchId: '',
      SourceType : '',
      Deposit_Interest_Type : '',
      Deposit_Cummulative_Frequency : '',
      Deposit_Payment_Frequency : '',
      Received_Type: '',
      Pay_Mode: '',
      AccountId: '',
      Pay_BankId:'',
      Deposit_CustomerCategory: '',
      Deposit_ModeOfOperation: ''
    };

    this.getBranchesList();
    this.getLeadSourceDropdown();
    this.Deposit_Get_Product_Master();

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.DepositId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("id")));
      this.PageFrom = this._EncrDecrService.decrypt(decodeURIComponent(param.get("page")));
      this.Statustype = this._EncrDecrService.decrypt(decodeURIComponent(param.get("type")));
      
      if (Number(this.DepositId) > 0) {
        this.Deposit_Get_FDR_Detail();
        this.IsUpdate = true;
        this.IsSave = false;
      }
      else {
        this.IsUpdate = false;
        this.IsSave = true;
      }
    });
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  getLeadSourceDropdown() {
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
    })
  }

  ChangeDepositType() {
    let _filterData = [];
    //console.log("this.ProductList top", this.ProductList);
    if (this.FDR_Detail.Deposit_Type == 'FD') {
      _filterData = this.ProductList.filter(item => item.ProductCategory == 'FD');
    }
    else {
      _filterData = this.ProductList.filter(item => item.ProductCategory == 'RD');
    }
    this.ProductListDropdown = _filterData;
  }

  Deposit_Get_Product_Master(){
    this._DepositService.Deposit_Get_Product_Master({ProductId : 0}).subscribe((res : any) => {
      //console.log("Deposit_Get_Product_Master", res);
      this.ProductList = res.Item1;

      //console.log("this.ProductList 1:", this.ProductList);
    });
  }

  Get_PartnerForDropdown(isChange: boolean = true) {
    if (isChange) {
      this.FDR_Detail.SourceName = "";
    }
    if (this.FDR_Detail.SourceType == 'Agent' || this.FDR_Detail.SourceType == 'DSA' || this.FDR_Detail.SourceType == 'Dealer') {
      this.losService.Get_PartnerForDropdown({ PartnerType: this.FDR_Detail.SourceType }).subscribe((res: any) => {
        this.partnerDropdown = res;
      });
    }

    if (this.FDR_Detail.SelectSourceType == 'Sales Executive') {
        this.partnerDropdown = [];
        this.SalesExecutiveData.forEach((obj: any) => {
          this.partnerDropdown.push({
            PartnerId: obj.EmpId,
            Partner_Name: obj.EmpName
          });
      });
    }
  }

  GetDepositNumber(){
    this.FDR_Detail.Deposit_No = '';
    this._DepositService.Deposit_GetFDRNo({ BranchId : this.FDR_Detail.Deposit_BranchId, DepositType: this.FDR_Detail.Deposit_Type })
    .subscribe((res : any) => {
      this.FDR_Detail.Deposit_No = res[0].DepositNo;
    });
  }

  onChangeTenure(){
    //this.FDR_Detail.Deposit_Interest_Type = '';
    this.FDR_Detail.Deposit_Interest_Rate = '';
    //this.FDR_Detail.Deposit_Cummulative_Frequency = '';
    //this.FDR_Detail.Date_of_Maturity = '';
    console.log("this.Tenure_Max", this.Tenure_Max);
    console.log("this.Tenure_Min", this.Tenure_Min);
    if(this.FDR_Detail.Deposit_Tenure <= this.Tenure_Max && this.FDR_Detail.Deposit_Tenure >= this.Tenure_Min){
      this._DepositService.Deposit_Get_Interest_And_Frequency({
        ProductId : this.FDR_Detail.Deposit_ProductId, Tenure: this.FDR_Detail.Deposit_Tenure, Is_seniorcitizen: this.FDR_Detail.Is_seniorcitizen
      }).subscribe((res : any) => {
        console.log("onChangeTenure", res);
        //this.FDR_Detail.Deposit_Interest_Type = res[0].Deposit_Interest_Type;
        this.FDR_Detail.Deposit_Interest_Rate = res[0].Rate_Of_Interest;
        //this.FDR_Detail.Deposit_Cummulative_Frequency = res[0].Deposit_Cummulative_Frequency;
        //this.FDR_Detail.Deposit_Payment_Frequency = res[0].Deposit_Payment_Frequency;
        this.GetCalculateInterest_Maturity();
        this.GetInterestByPaymentFrequency();
      });
    }
    else{
      this.FDR_Detail.Deposit_Tenure = '';
      this.snackBar.openFromComponent(SnackbarComponent, { 
        data: `Tenure between ${this.Tenure_Min} months to ${this.Tenure_Max} months as per product policy !!`, ...this.configSuccess 
      });
    }
    
  }
  
  onChangeFDRDDate(){
    this.minDate = new Date(moment(this.FDR_Detail.Date_of_FDRD).format('MM/DD/YYYY'));
    this.GetCalculateInterest_Maturity();
    if(this.FDR_Detail.Deposit_Payment_Frequency != 'OnMaturity'){
      this.GetInterestByPaymentFrequency();
    }
  }

  GetCalculateInterest_Maturity(){
    this.FDR_Detail.Interest_Amount = '';
    this.FDR_Detail.Maturity_Amount = '';
    this.FDR_Detail.Date_of_Maturity = new Date(moment(this.FDR_Detail.Date_of_FDRD).add(Number(this.FDR_Detail.Deposit_Tenure), 'months').format());
    this.FDR_Detail.Lockin_Date = new Date(moment(this.FDR_Detail.Date_of_FDRD).add(Number(this.FDR_Detail.Deposit_Lockin_Period), 'months').format());

    let Int_Type = this.FDR_Detail.Deposit_Interest_Type == 'Cumulative' ? 'Cummulative' : this.FDR_Detail.Deposit_Interest_Type;

    this._DepositService.GetCalculateInterest_Maturity({
      Amount : this.FDR_Detail.Deposit_Amount, Tenure: this.FDR_Detail.Deposit_Tenure, RateOfInterest: this.FDR_Detail.Deposit_Interest_Rate, 
      Interst_Frequency: this.FDR_Detail.Deposit_Cummulative_Frequency, InterestType: Int_Type
    }).subscribe((res : any) => {
      //console.log("GetCalculateInterest_Maturity", res);
      this.FDR_Detail.Interest_Amount = res[0].Interest_Amt;
      this.FDR_Detail.Maturity_Amount = res[0].Maturity_Amt;
    });
  }

  onChangeDepositPaymentFrequency(){
    if(this.FDR_Detail.Deposit_Payment_Frequency != 'OnMaturity'){
      this.GetInterestByPaymentFrequency();
    }
  }

  OnChangeProduct(data: any){
    console.log("data", data);
    if (this.DepositId == 0) {
      this.FDR_Detail.ProductName = data.ProductName;
      this.FDR_Detail.Deposit_ProductId = data.ProductId;
    }
    
    this.GetProductData(this.FDR_Detail.Deposit_ProductId);
    this.OnCloseSearchProduct();
  }

  SearchProduct() {
    this.FDR_Detail.Deposit_BranchId = '';
    this.FDR_Detail.Deposit_No = '';
    this.FDR_Detail.SourceType = '';

    this.FDR_Detail.Deposit_Amount = '';
    this.FDR_Detail.Deposit_Tenure = '';
    this.FDR_Detail.Deposit_Interest_Type = '';
    this.FDR_Detail.Deposit_Interest_Rate = '';
    this.FDR_Detail.Deposit_Cummulative_Frequency = '';
    this.FDR_Detail.Deposit_Payment_Frequency = '';
    this.FDR_Detail.Date_of_FDRD = '';
    this.FDR_Detail.Date_of_Maturity = '';
    this.FDR_Detail.Lockin_Date = '';
    this.FDR_Detail.Received_Date = '';
    this.FDR_Detail.Received_Type = '';
    this.FDR_Detail.Received_ChequeNo = '';
    this.FDR_Detail.Received_ChequeAmount = '';
    this.FDR_Detail.Received_ChqueDate = '';
    this.FDR_Detail.Received_Bank_Name = '';
    this.FDR_Detail.Received_Reference_No = '';
    this.FDR_Detail.Interest_Amount = '';
    this.FDR_Detail.Interest_AmountPF = '';
    //this.FDR_Detail.Deposit_IsPreMature = false;
    this.FDR_Detail.Auto_Renewal = false;
    this.FDR_Detail.Pay_Mode = '';
    this.FDR_Detail.AccountId = '';
    this.FDR_Detail.Pay_BankId = '';

    $("#Product_Data").modal("show");
    $("#Product_Data").css("z-index", "1050");
    this.getProductDetail();
  }
  OnCloseSearchProduct() {
    $("#Product_Data").modal("hide");
  }

  showResult(Product, index: any) {
    this.showLess_ProductDetail[index] = true;
    this.ProductModel = this.ProductAll.filter(item => item.ProductId == Product.ProductId);
    console.log("this.ProductModel", this.ProductModel);
  }
  hideResult(index: any) {
    this.showLess_ProductDetail[index] = false;
  }

  getProductDetail() {
    this._DepositService.Deposit_Get_All_Product_By_Type({DepositType : this.FDR_Detail.Deposit_Type}).subscribe((res: any) => {
      console.log("res", res);
      this.ProductAll = res;
      //this.Product_Data = Array.from(new Set(res.map((element) => element.ProductId)));
      
      this.Product_Data = res.filter((res, index, self) => index === self.findIndex((t) => (t.ProductId === res.ProductId)));
      console.log(this.Product_Data);
    });
  }

  GetProductData(ProductId){
    console.log("ProductId", ProductId);
    let data = this.ProductList.filter(item => item.ProductId == ProductId)[0];
    console.log("Productdata", data);
    this.FDR_Detail.Deposit_IsPreMature = data.IsPreMature;
    this.FDR_Detail.Deposit_Lockin_Period = data.Lockin_Period;
    this.FDR_Detail.Deposit_Interest_Type = data.Interest_Type;
    this.FDR_Detail.Deposit_Cummulative_Frequency = data.Cummulative_Frequency;
    this.FDR_Detail.Deposit_Payment_Frequency = data.Payment_Frequency;
    this.PRD_Start_Date = data.Start_Date;
    this.PRD_End_Date = data.End_Date;
    this.Tenure_Max = data.Tenure_Max;
    this.Tenure_Min = data.Tenure_Min;

    this.MinDateFDRD = new Date(moment(this.PRD_Start_Date).format('MM/DD/YYYY'));
    this.MaxDateFDRD = new Date(moment(this.PRD_End_Date).format('MM/DD/YYYY'));
  }

  GetInterestByPaymentFrequency(){
    this.FDR_Detail.Interest_AmountPF = '';
    this.FDR_Detail.Maturity_AmountPF = '';
    let Int_Type = this.FDR_Detail.Deposit_Interest_Type == 'Cumulative' ? 'Cummulative' : this.FDR_Detail.Deposit_Interest_Type;
    
    let Payment_Fr, Cummulative_Fr;
    if(this.FDR_Detail.Deposit_Payment_Frequency == "Monthly"){
      Payment_Fr = 1;
    }
    else if(this.FDR_Detail.Deposit_Payment_Frequency == "Quarterly"){
      Payment_Fr = 3;
    } 
    else if(this.FDR_Detail.Deposit_Payment_Frequency == "HalfYearly"){
      Payment_Fr = 6;
    } 
    else if(this.FDR_Detail.Deposit_Payment_Frequency == "Yearly"){
      Payment_Fr = 12;
    } 

    if(this.FDR_Detail.Deposit_Cummulative_Frequency == "Monthly"){
      Cummulative_Fr = 1;
    }
    else if(this.FDR_Detail.Deposit_Cummulative_Frequency == "Quarterly"){
      Cummulative_Fr = 3;
    } 
    else if(this.FDR_Detail.Deposit_Cummulative_Frequency == "HalfYearly"){
      Cummulative_Fr = 6;
    } 
    else if(this.FDR_Detail.Deposit_Cummulative_Frequency == "Yearly"){
      Cummulative_Fr = 12;
    } 

    let _data = {
      Data: {
        Frequency: Cummulative_Fr, PaymentFrequency: Payment_Fr,
        Deposit_Amount: this.FDR_Detail.Deposit_Amount, Deposit_Tenure: this.FDR_Detail.Deposit_Tenure, 
        Deposit_Interest_Rate: this.FDR_Detail.Deposit_Interest_Rate, Date_of_FDRD: this.FDR_Detail.Date_of_FDRD, 
        Deposit_Interest_Type: Int_Type
      }
    };
    //console.log("_data", _data);
    this._DepositService.Deposit_Get_Interest_By_paymentFrequency({ JSON: JSON.stringify(_data) }).subscribe((res : any) => {
      console.log("GetInterestByPaymentFrequency", res);
      this.FDR_Detail.Interest_AmountPF = res[0].Interest_Amt;
      this.FDR_Detail.Maturity_AmountPF = res[0].Maturity_Amt;
    });
  }

  GetData(data) {
    //console.log("data", data);
    let RelationType;
    if(data[0].ExistingCustomer == 'Yes'){
      RelationType = data[0].Relation_Type+" "+data[0].RelationName;
    }
    else{
      RelationType = data[0].Customer_Relation_Type+" "+data[0].Customer_Relation_FirstName+" "+data[0].Customer_Relation_LastName;
    }

    let Cust_exist = this.CData.filter(x => x.CustomerId == data[0].CustomerId);
    //console.log("Cust_exist", Cust_exist);
    
    if(Cust_exist.length > 0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This customer already exist !!.", ...this.configSuccess });
    }
    else{
      this.CData.push({
        CustomerId: data[0].CustomerId,
        Customer: data[0].CustomertName,
        CustomerType: data[0].CustomerType,
        GenderAge: data[0].Customer_Gender+" "+ data[0].GenderAge,
        PhoneNo: data[0].PhoneNo,
        Present_Address: data[0].Present_Address,
        Relation_Type: RelationType,
        ExistingCustomer: data[0].ExistingCustomer,
      });
    }
    this.CustomerSummary = this.CData;
    //console.log("CData", this.CData);
    this.dataCustomerSource = new MatTableDataSource(JSON.parse(JSON.stringify(this.CData)));
    this.CustomerBankDetail();
  }

  onSaveNewDeposit() {
    if(this.FDR_Detail.Deposit_Payment_Frequency != 'OnMaturity'){
      if(this.FDR_Detail.Interest_AmountPF > 0){
        this.FDR_Detail.Interest_Amount = this.FDR_Detail.Interest_AmountPF;
        this.FDR_Detail.Maturity_Amount = this.FDR_Detail.Maturity_AmountPF;
  
        delete this.FDR_Detail.Interest_AmountPF;
        delete this.FDR_Detail.Maturity_AmountPF;
      }
    }
    
    
    this.FDR_Detail.Deposit_Interest_Type = this.FDR_Detail.Deposit_Interest_Type == 'Cumulative' ? 'Cummulative' : this.FDR_Detail.Deposit_Interest_Type;

    this.CData.forEach((obj: any) => {
      //obj.Customer_Id = obj.CustomerId;
      //obj.Customer_Type = obj.CustomerType;

      delete obj.Customer;
      delete obj.GenderAge;
      delete obj.PhoneNo;
      //delete obj.CustomerId;
      //delete obj.CustomerType;
    });

    let _data = {
      FDR_Detail: this.FDR_Detail,
      FDR_Customer: this.CData,
      FDR_Nominee: this.FDR_Nominee
    }
    console.log('_data', _data);
    _data.FDR_Detail.LoginUserId = this.currentUser.userId;
    this._DepositService.Deposit_Save_FDRD({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this._Route.navigate([`/deposit-dashboard/Deposit-List`]);
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
      this.loading = false;
    });
  }

  AddFDRNominee(){
    this.FDR_Nominee = [];
    this.FDR_Nominee.push({ 
      Id: 0,
      Nominee_Relation: "",
      Nominee_Name: "", 
      Nominee_DOB: "", 
      Nominee_Ratio: 100
    });
    $(`#addNominee`).modal("show");
    $(`#addNominee`).css("z-index", "1050");
  }

  onCloseNomineeModel(){
    $(`#addNominee`).modal("hide");
  }

  addNomineeDetail() {
    if (this.FDR_Nominee.length > 0) {
      let _prev = this.FDR_Nominee[this.FDR_Nominee.length - 1];
      if (_prev.Nominee_Relation == "" || _prev.Nominee_Name == "" || _prev.Nominee_DOB == "" || _prev.Nominee_Ratio == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
      //_prev.Nominee_Ratio = "";
    }
    this.FDR_Nominee.push({ 
      Id: 0,
      Nominee_Relation: "",
      Nominee_Name: "", 
      Nominee_DOB: "", 
      Nominee_Ratio: ""
    });
  }

  removeNomineeDetail(index: any){
    this.FDR_Nominee.splice(index, 1);
  }

  onChangeNomineeRatio(index: any){
    if(this.FDR_Nominee[index].Nominee_Ratio == 0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Nominee Ratio should be greater than 0.", ...this.configSuccess });
      this.FDR_Nominee[index].Nominee_Ratio = '';
    } 
  }

  CustomerBankDetail() {
    let Customer_Ids = this.CData.map(element => element.CustomerId).join(",");
    this._DepositService.Deposit_CustomerBankDetail({ CustomerIds: Customer_Ids }).subscribe((res) => {
      this.CustomerBankDropdown = res;

      setTimeout(() => {
        this.onChangeRepaymentBank();
      }, 500);
      //console.log("this.CustomerBankDropdown", this.CustomerBankDropdown);
    });
  }

  Get_Acc_Accounts_By_Tag() {
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.FDR_Detail.Pay_Mode, Voucher_Date: new Date() }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }

  onPayModeChange() {
    this.FDR_Detail.Received_Type = "";
    this.FDR_Detail.Received_ChequeNo = "";
    this.FDR_Detail.Received_ChequeAmount = "";
    this.FDR_Detail.Received_ChqueDate = "";
    this.FDR_Detail.Received_Bank_Name = "";
    this.FDR_Detail.Received_Reference_No = "";

    this.Get_Acc_Accounts_By_Tag();
  }

  onSaveNominee(){
    //NomineeData.push(this.FDR_Nominee);
    let _total: any = 0;
    let i = 1;
    this.FDR_Nominee.forEach((obj: any) => {
      _total = _total + Number(obj.Nominee_Ratio);
    });

    if (_total == 100) {
      if(this.FDR_Nominee.length == 1){
        this.NomineeData.push({ 
          Id: i,
          Nominee_Relation: this.FDR_Nominee[0].Nominee_Relation,
          Nominee_Name: this.FDR_Nominee[0].Nominee_Name, 
          Nominee_DOB: new Date(this.FDR_Nominee[0].Nominee_DOB),
          Nominee_Ratio: this.FDR_Nominee[0].Nominee_Ratio
        });
      }
      else{
        this.FDR_Nominee.forEach((obj: any) => {
          this.NomineeData.push({ 
            Id: i,
            Nominee_Relation: obj.Nominee_Relation,
            Nominee_Name: obj.Nominee_Name, 
            Nominee_DOB: new Date(obj.Nominee_DOB),
            Nominee_Ratio: obj.Nominee_Ratio
          });
          i++;
        });
      }

      this.onCloseNomineeModel();
      //console.log("this.NomineeData", this.NomineeData);
      this.dataCustomerNominee = new MatTableDataSource(JSON.parse(JSON.stringify(this.NomineeData)));
    }
    else{
      /*this.FDR_Nominee.forEach((obj: any) => {
        obj.Nominee_Ratio = '';
      });*/
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Nominee Total Ratio should be equal to 100%.", ...this.configSuccess });
    }
  }
  
  onUpdateNominee(data){
    this.NomineeSelect;
    this.dataCustomerNominee = new MatTableDataSource(JSON.parse(JSON.stringify(this.NomineeData)));
  }

  onChangeRepaymentBank(){
    //console.log("this.CustomerBankDropdown 2", this.CustomerBankDropdown);
    this.BankInfo = this.CustomerBankDropdown.filter(x => x.Id == this.FDR_Detail.Pay_BankId)[0];
    //console.log("this.BankInfo", this.BankInfo);
  }

  AddCustomerBank(){
    this.BankDetails = [];
    if(this.CData.length > 0){
      this.BankDetails.push({ 
        BeneficiaryName: "", 
        AccountNo: "", 
        BankName: "", 
        BankBranch: "", 
        BankAcType: "", 
        BankIFSC: "",
        BankMICRCode: ""
      });
      $(`#addCustomerBank`).modal("show");
      $(`#addCustomerBank`).css("z-index", "1050");
    }
    else{
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please add customer first !!", ...this.configSuccess });
    }
  }

  onCloseCustomerBankModel() {
    $(`#addCustomerBank`).modal("hide");
  }

  addBankDetail1() {
    if (this.BankDetails.length > 0) {
      let _prev = this.BankDetails[this.BankDetails.length - 1];
      if (_prev.BeneficiaryName == "" || _prev.AccountNo == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.BankDetails.push({ 
      BeneficiaryName: "",
      AccountNo: "", 
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: ""
    });
  }
  
  removeBankDetail1(index: any) {
    this.BankDetails.splice(index, 1);
  }
  
  onSaveCustomerBank(){
    let UpperData = [];
    this.BankDetails.forEach((obj: any) => {
      UpperData.push({
        CustomerId: this.CustomerId,
        BeneficiaryName : obj.BeneficiaryName.toUpperCase(),
        AccountNo : obj.AccountNo.toUpperCase(),
        BankName : obj.BankName.toUpperCase(),
        BankBranch : obj.BankBranch.toUpperCase(),
        BankAcType : obj.BankAcType.toUpperCase(),
        BankIFSC : obj.BankIFSC.toUpperCase(),
        BankMICRCode : obj.BankMICRCode,
      });
    });
    
    let _data = {
      CustomerBankDetail: UpperData
    }
    
    console.log('_data', _data);
    this._MasterService.Save_CustomerBankDetail({
      JSON: JSON.stringify(_data), Customer_Id: this.CustomerId, LoginUserId: this.currentUser.userId
    }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.onCloseCustomerBankModel();
        this.CustomerBankDetail();
      }
      this.showSpinner = false;
    });
  }
  ToUpperText(obj) {
    for (var prop in obj) {
      obj[prop] = obj[prop].toUpperCase();
    }
    return obj;
  }

  EditNominee(row: any, i: any){
    this.NomineeSelect = row;
    this.index = i;
    this.BtnSave = false;
    this.BtnUpdate = true;
    this.NomineeData = [];
    this.NomineeData.push({ 
      Id: row.Id,
      Nominee_Relation: row.Nominee_Relation,
      Nominee_Name: row.Nominee_Name, 
      Nominee_DOB: new Date(moment(row.Nominee_DOB).format()),
      Nominee_Ratio: Number(row.Nominee_Ratio)
    });
    this.FDR_Nominee = this.NomineeData;
    $(`#addNominee`).modal("show");
    $(`#addNominee`).css("z-index", "1050");
  }

  Deposit_Get_FDR_Detail(){
    this._DepositService.Deposit_Get_FDR_Detail({ LoginUserId: this.currentUser.userId, Deposit_Id: this.DepositId }).subscribe((res: any) => {
      console.log('res', res);
      this.FDR_Detail = res.Item1[0];
      this.CData = res.Item2;
      this.NomineeData = res.Item3;
      if(this.PageFrom == 'Authorize'){
        this.AmortizationData = res.Item4;
      }
      
      this.Remark = res.Item1[0].Remark;
      this.dataCustomerSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.Item2)));
      this.dataCustomerNominee = new MatTableDataSource(JSON.parse(JSON.stringify(res.Item3)));
      this.dataAmortization = new MatTableDataSource(JSON.parse(JSON.stringify(res.Item4)));
      
     
      this.OnChangeProduct(res.Item1[0]);
      
      setTimeout(() => {
        this.ChangeDepositType();
        this.GetProductData(res.Item1[0].Deposit_ProductId);
      }, 500);

      this.FDR_Detail.Date_of_FDRD = new Date(moment(res.Item1[0].Date_of_FDRD).format('MM/DD/YYYY'));

      this.FDR_Detail.Date_of_Maturity = new Date(moment(res.Item1[0].Date_of_Maturity).format());
      this.FDR_Detail.Lockin_Date = new Date(moment(this.FDR_Detail.Date_of_FDRD).add(Number(this.FDR_Detail.Deposit_Lockin_Period), 'months').format());
      this.FDR_Detail.Received_Date = new Date(moment(res.Item1[0].Received_Date).format());
      this.FDR_Detail.Received_ChqueDate = new Date(moment(res.Item1[0].Received_ChqueDate).format());

      this.Status = this.Statustype == "Pending" ? '' : this.Statustype;
      
      setTimeout(() => {
        this.GetCalculateInterest_Maturity();
        this.GetInterestByPaymentFrequency();
        this.CustomerBankDetail();
      }, 800);

      /*setTimeout(() => {
        this.CustomerBankDetail();
      }, 1000);*/

      //this.FDR_Detail.Pay_Mode = res.Item1[0].Received_Type == '' ? "CASH" : "Bank";
      this.Get_Acc_Accounts_By_Tag();
      this.showSpinner = false;
    });
  }

  closeAuthorizeDetail(){
    this._Route.navigate(["/deposit-dashboard/deposit-authorize"]);
  }

  saveAuthorizeDetail(){
    this._DepositService.Deposit_Update_Authorization_FDRD({
      Deposit_Id: this.DepositId, Status: this.Status, Remark: this.Remark, LoginUserId: this.currentUser.userId
    }).subscribe((res: any) => {            
      if(res && res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this._Route.navigate(["/deposit-dashboard/deposit-authorize"]);
      } 
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }

  DeleteCustomerDetail(index: any){
    this.dialogreturn = this.dialog.openConfirmDialog('Do you want to delete this Customer !!');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.CData.splice(index, 1);
        if(this.CData.length == 0){
          this.dataCustomerNominee = null;
        }
        this.CustomerSummary = this.CData;
        this.dataCustomerSource = new MatTableDataSource(JSON.parse(JSON.stringify(this.CData)));
      }
    });
  }

  DeleteNomineeDetail(index: any){
    this.dialogreturn = this.dialog.openConfirmDialog('Do you want to delete this Nominee !!');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.NomineeData.splice(index, 1);
        this.dataCustomerNominee = new MatTableDataSource(JSON.parse(JSON.stringify(this.NomineeData)));
      }
    });
  }

  onChangeNomineeRelation(data: any, dindex: any){
    let _find = this.FDR_Nominee.find((obj: any, index: any) => {
      return index != dindex && obj.Nominee_Relation == data.Nominee_Relation;
    });
    if (_find) {
      data.Nominee_Relation = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This relation already selected.", ...this.configSuccess });
      data.Nominee_Relation = "";
      return;
    }
  }

  onChangeDepositAmount(){
    this.FDR_Detail.Received_ChequeAmount = this.FDR_Detail.Deposit_Amount;
  }
}
