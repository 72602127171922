import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { QuickLoanService } from 'src/app/_QuickLoan/services/quickLoan.service';

@Component({
  selector: 'app-tat-report',
  templateUrl: './tat-report.component.html',
  styleUrls: ['./tat-report.component.scss']
})
export class TATReportComponent implements OnInit {
  @Input() loanId;
  TatReportsList: any;
  showSpinner: boolean;
  constructor(private _QuickLoanService:QuickLoanService) { }

  ngOnInit(): void {
   
    this._QuickLoanService.Quick_rpt_TAT_Report({ LoanId: this.loanId}).subscribe((res) => {
      console.log("result: ", res);
      this.TatReportsList = JSON.parse(JSON.stringify(res)).Item2;
      this.showSpinner = false;
      let sum = 0;
    
    });
  }

}
