<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
    <div class="row m-0 h-b w-b" style="width: 100%">
      <h1 class="fs-12 m-0">Loan Details</h1>
    </div>
    <div class="row m-0 mt-3">
      <div class="col-md-2">
        <h6 class="fs-12">Loan No</h6>
        <p class="lead fs-12">{{ loanDetailObj.LoanNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Loan Date</h6>
        <p class="lead fs-12">{{ loanDetailObj.LoanDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Application No</h6>
        <p class="lead fs-12">{{ loanDetailObj.ApplicationNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Product</h6>
        <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Branch</h6>
        <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Asset Cost</h6>
        <p class="lead fs-12 fw-7">
          ₹ {{ loanDetailObj.AssetCost ? loanDetailObj.AssetCost : "0" }}
        </p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Net Finance</h6>
        <!-- .toFixed(2) -->
        <p class="lead fs-12">₹ {{ loanDetailObj.LoanAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Tenure (Months)</h6>
        <p class="lead fs-12">{{ loanDetailObj.LoanTenure }} Months</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">No of Installment</h6>
        <p class="lead fs-12">{{ loanDetailObj.No_Of_Installment }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Agreemnent Value</h6>
        <p class="lead fs-12">₹ {{ loanDetailObj.AgreementValue }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Interest Amount</h6>
        <p class="lead fs-12">₹ {{ loanDetailObj.InterestAmt }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">LTV</h6>
        <p class="lead fs-12">{{ loanDetailObj.LTV }}%</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">EMI Amount</h6>
        <p class="lead fs-12">₹ {{ loanDetailObj.LoanEMIAmount }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">ROI</h6>
        <p class="lead fs-12">{{ loanDetailObj.ROI }}%</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">IRR Calculate By</h6>
        <p class="lead fs-12">{{ loanDetailObj.IRR_CalculateBy }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Case IRR</h6>
        <p class="lead fs-12">{{ loanDetailObj.Case_IRR }}%</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">EMI Due Date</h6>
        <p class="lead fs-12">
          {{ loanDetailObj.EMI_DueDate | date : "dd/MM/yyyy" }}
        </p>
      </div>
    </div>
  </ng-container>

  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Int_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Id
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          <app-los-customer-view-model
            [customerId]="row.CustomerId"
            [index]="i"
          >
            {{ row.Customer }}
          </app-los-customer-view-model>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.PhoneNo }}
          <i
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 13px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Relation_With_Hirer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Relation With Borrower
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Relation_With_Hirer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Present_Address">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 300px; max-width: 300px"
          *matHeaderCellDef
        >
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 300px; max-width: 300px"
        >
          {{ row.Present_Address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ExistingCustomer">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 90px; max-width: 90px"
          *matHeaderCellDef
        >
          Existing
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 90px; max-width: 90px"
        >
          {{ row.ExistingCustomer }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <ng-container>
    <div
      class="row m-0 mt-2 h-b w-b justify-content-between align-items-center"
      style="width: 100%"
    >
      <h1 class="fs-12 m-0">Pre Closer Detail</h1>
      <button
        type="button"
        (click)="onViewLoanStatement()"
        class="btn btn-light font-size-12"
        style="padding: 3px 5px"
      >
        View Loan Statement
      </button>
    </div>

    <div class="row m-0 mt-3" id="printData">
      <!-- <div class="col-md-3">
          <h6 class="fs-12">Request Status</h6>
          <p class="lead fs-12">{{ PreCloserDetails.Closer_RequestStatus }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Request On</h6>
          <p class="lead fs-12">{{ PreCloserDetails.LoanCloser_RequestOn }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Request By</h6>
          <p class="lead fs-12">{{ PreCloserDetails.LoanCloser_RequestBy }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Effective Date</h6>
          <p class="lead fs-12">{{ PreCloserDetails.LoanCloser_EffectiveDate }}</p>
        </div> -->

      <div style="width: 100%; padding-left: 20px; padding-right: 20px">
        <table style="margin-bottom: 20px">
          <tbody>
            <tr>
              <td>
                <h6
                  style="
                    display: inline-block;
                    margin: 0px;
                    margin-right: 10px;
                    font-size: 12px;
                  "
                >
                  Request Status:
                </h6>
                <p style="display: inline-block; font-weight: 700">
                  {{ PreCloserDetails.Closer_RequestStatus }}
                </p>
              </td>
              <td style="text-align: end">
                <h6
                  style="
                    display: inline-block;
                    margin: 0px;
                    margin-right: 10px;
                    font-size: 12px;
                  "
                >
                  Pre-Closure Effective Date:
                </h6>
                <div style="display: inline-block">
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate"
                      [min]="today"
                      [max]="PreCloserDetails.NextDueDate"
                      name="LoanCloser_EffectiveDate"
                      id="LoanCloser_EffectiveDate"
                      class="form-control input-text-css"
                      style="width: 160px"
                      (dateChange)="onEffectiveDateChange()"
                      [disabled]="
                        PreCloserDetails.Closer_RequestStatus != 'Created' ||
                        true
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                  <!-- <input
                    type="text"
                    [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate"
                    [minDate]="today"
                    [maxDate]="PreCloserDetails.NextDueDate"
                    name="LoanCloser_EffectiveDate"
                    id="LoanCloser_EffectiveDate"
                    value="{{
                      PreCloserDetails.LoanCloser_EffectiveDate
                        | date : 'dd/MM/yyyy'
                    }}"
                    placeholder="DD/MM/YYYY"
                    [(bsValue)]="PreCloserDetails.LoanCloser_EffectiveDate"
                    class="form-control input-text-css"
                    bsDatepicker
                    style="width: 160px"
                    (bsValueChange)="onEffectiveDateChange()"
                    [disabled]="
                      PreCloserDetails.Closer_RequestStatus != 'Created' || true
                    "
                  /> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style="margin-bottom: 20px">
          <tbody>
            <tr>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Head</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Due</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Received</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Waiver</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Balance</b>
              </td>
            </tr>
            <tr
              *ngFor="let preCloserData of PreCloserMethodList; let i = index"
            >
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                {{ preCloserData.ChagesHead }}
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                {{ preCloserData.DueAmount }}
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                {{ preCloserData.ReceiveAmount }}
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <input
                  type="text"
                  [(ngModel)]="preCloserData.WaiverAmount"
                  placeholder="amount"
                  name="WaiverAmount{{ i }}"
                  id="WaiverAmount{{ i }}"
                  *ngIf="preCloserData.IsWaiver_Eligible"
                  class="form-control input-text-css"
                  [disabled]="
                    isExpired ||
                    PreCloserDetails.totalWaiverAmount == 0 ||
                    isShowForward ||
                    true
                  "
                  style="width: 90px; margin: 0px auto"
                  (ngModelChange)="onChangeWaiverAmt(i)"
                />
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                {{ preCloserData.Balance }}
              </td>
            </tr>
            <tr *ngIf="PreCloserMethodList.length > 0 && totalCalculator">
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>Total :</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>{{ totalCalculator.totalDueAmount.toFixed(2) }}</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>{{ totalCalculator.totalReceiveAmount.toFixed(2) }}</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b>{{ totalCalculator.totalWaiverAmount.toFixed(2) }}</b>
              </td>
              <td
                style="
                  border: 1px solid #b5b0b0;
                  padding: 5px;
                  text-align: center;
                "
              >
                <b *ngIf="totalCalculator.totalBalance">{{
                  totalCalculator.totalBalance.toFixed(2)
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <table style="margin-bottom: 20px">
          <tbody>
            <tr>
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Net Receivable Amount:</h6>
              </td>
              <td style="padding: 5px">
                <p style="font-size: 12px" *ngIf="totalCalculator.totalBalance">
                  {{ totalCalculator.totalBalance.toFixed(2) }}
                </p>
              </td>
              <td style="padding: 5px">
                <p style="font-size: 12px">
                  Your Waiver Limit On this Loan No:
                  <b>{{ PreCloserDetails.Waiver_Limit_Per_Amt_R }}</b> Rs
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Amount To Be Deposit:</h6>
              </td>
              <td style="padding: 5px">
                <input
                  type="text"
                  [(ngModel)]="PreCloserDetails.CustomerAgreed_Amount"
                  placeholder="amount"
                  name="CustomerAgreed_Amount"
                  id="CustomerAgreed_Amount"
                  (blur)="onChangeAgreeAmount()"
                  style="width: 300px"
                  [disabled]="
                    PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' ||
                    isExpired ||
                    true
                  "
                  class="form-control input-text-css"
                />
              </td>
              <td
                style="padding: 5px"
                *ngIf="
                  isShowForward ||
                  PreCloserDetails.Closer_RequestStatus == 'Waiver Pending'
                "
              >
                <div style="display: inline-block; margin-right: 20px">
                  <h6 style="font-size: 12px">
                    Select User For Waiver Approval:
                  </h6>
                </div>
                <div style="display: inline-block">
                  <select
                    name="Waiver_GivenBy"
                    id="Waiver_GivenBy"
                    [(ngModel)]="PreCloserDetails.Waiver_GivenBy"
                    class="form-control input-text-css"
                    style="width: 300px"
                    [disabled]="
                      isExpired ||
                      PreCloserDetails.Closer_RequestStatus != 'Created' ||
                      true
                    "
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let user of wavieruserList"
                      [value]="user.EmpId"
                    >
                      {{ user.Employee }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr *ngIf="PreCloserMethodList.length > 0 && totalCalculator">
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Waiver Required Amount</h6>
              </td>
              <td style="padding: 5px">
                <p style="font-size: 12px">
                  {{ PreCloserDetails.totalWaiverAmount.toFixed(2) }}
                </p>
              </td>
            </tr>
            <tr>
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Upload Closer Request Doc:</h6>
              </td>
              <td style="padding: 5px">
                <input
                  type="file"
                  (change)="fileChangeEvent($event)"
                  *ngIf="!PreCloserDetails.CloserRequestDoc"
                  name="Request_Doc"
                  accept=".png, .jpg, .jpeg"
                  id="Request_Doc"
                />
                <a
                  href="{{ loandocBaseUrl }}{{ LoanId }}/{{
                    PreCloserDetails.CloserRequestDoc
                  }}"
                  target="_blank"
                  *ngIf="PreCloserDetails.CloserRequestDoc"
                >
                  {{ PreCloserDetails.CloserRequestDoc }}
                </a>
                <i
                  class="fa fa-times ml-2"
                  style="cursor: pointer; font-size: larger"
                  (click)="onRemoveImg()"
                  aria-label="true"
                  *ngIf="PreCloserDetails.CloserRequestDoc"
                ></i>
              </td>
            </tr>

            <tr>
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Pre-Closer Remark:</h6>
              </td>
              <td style="padding: 5px" colspan="2">
                <textarea
                  [(ngModel)]="PreCloserDetails.LoanCloser_Remark"
                  placeholder="Pre-Closer Remark"
                  name="LoanCloser_Remark"
                  id="LoanCloser_Remark"
                  class="form-control input-text-css"
                  [disabled]="
                    PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' ||
                    isExpired ||
                    true
                  "
                ></textarea>
              </td>
            </tr>
            <tr
              *ngIf="PreCloserDetails.Closer_RequestStatus == 'Waiver Pending'"
            >
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Waiver Remark:</h6>
              </td>
              <td style="padding: 5px" colspan="2">
                <textarea
                  [(ngModel)]="PreCloserDetails.Waiver_Remark"
                  placeholder="Waiver Remark"
                  name="Waiver_Remark"
                  id="Waiver_Remark"
                  class="form-control input-text-css"
                  [disabled]="isExpired || !isWavierGivenByMe || true"
                ></textarea>
              </td>
            </tr>
            <tr *ngIf="isUndoRemark">
              <td style="padding: 5px">
                <h6 style="font-size: 12px">Undo Remark:</h6>
              </td>
              <td style="padding: 5px" colspan="2">
                <textarea
                  [(ngModel)]="undoRemark"
                  placeholder="Undo Remark"
                  name="undoRemark"
                  id="undoRemark"
                  class="form-control input-text-css"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!PreCloserDetails.IsEligbleForPreCloser && !showSpinner">
        <p style="color: #db1f1f; text-align: right; font-weight: bold">
          This Loan Is Not Eligible For Pre Closer
        </p>
      </div>
      <div
        *ngIf="
          PreCloserDetails.Closer_RequestStatus == 'Closed' && !showSpinner
        "
      >
        <p style="color: #db1f1f; font-weight: bold">
          This Loan Has Been Already Closed.
        </p>
      </div>
      <div *ngIf="isExpired && !showSpinner">
        <p style="color: #db1f1f; font-weight: bold">
          Pre-Closer Effective Date Is Expired. To Create New Request, Please
          Delete This Request
        </p>
      </div>
    </div>
    <div class="row col-md-12 m-0 mt-5 justify-content-end">
      <!-- <button type="button"
          *ngIf="PreCloserDetails.IsEligbleForPreCloser&&PreCloserDetails.Closer_RequestStatus != 'Closed'&&isMyCloserRequest"
          (click)="onDeleteCloserRequest()" style="background-color: #28a745; color: #ffffff; font-size: 12px"
          mat-raised-button color="primary">
          Delete Request
        </button> -->
      <button
        type="button"
        *ngIf="
          true ||
          (PreCloserDetails.IsEligbleForPreCloser &&
            PreCloserDetails.Closer_RequestStatus == 'Closed' &&
            isMyCloserRequest)
        "
        (click)="onUndoCloserRequest()"
        class="btn font-size-12 button-btn"
      >
        Undo Loan Closer
      </button>
      <!-- <ng-conatiner
          *ngIf="(PreCloserDetails.IsEligbleForPreCloser&&PreCloserDetails.Closer_RequestStatus !== 'Closed')&&!isExpired">
          <button type="button" *ngIf="PreCloserDetails.Closer_RequestStatus == 'Created'" (click)="onSaveAsDraft()"
            style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary">
            Save As Draft
          </button>
          <button type="button" *ngIf="PreCloserDetails.Closer_RequestStatus == 'Created'"
            (click)="onPrintPreClosureStatement()" class="ml-2"
            style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary">
            Print PreClosure Statement
          </button>
          <button type="button"
            *ngIf="!isShowForward&&(PreCloserDetails.Closer_RequestStatus == 'Created'||PreCloserDetails.Closer_RequestStatus == 'Waiver Approved')"
            (click)="onPreClosureClose()" class="ml-2" style="background-color: #28a745; color: #ffffff; font-size: 12px"
            mat-raised-button color="primary">
            Loan Closed
          </button>
          <button type="button"
            *ngIf="(isShowForward&&PreCloserDetails.Closer_RequestStatus !== 'Waiver Pending')||(PreCloserDetails.Closer_RequestStatus !== 'Created'&&PreCloserDetails.Closer_RequestStatus !== 'Closed'&&PreCloserDetails.Closer_RequestStatus !== 'Waiver Pending')"
            (click)="onForwardToSeniorWaiverApproval()" class="ml-2"
            style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary">
            Forward To Senior For Waiver Approval
          </button>
          <button type="button" *ngIf="PreCloserDetails.Closer_RequestStatus == 'Waiver Pending'&&isWavierGivenByMe"
            (click)="onWaieverApproved()" class="ml-2" style="background-color: #28a745; color: #ffffff; font-size: 12px"
            mat-raised-button color="primary">
            Waiver Approved
          </button>
          <button type="button" *ngIf="PreCloserDetails.Closer_RequestStatus == 'Waiver Pending'&&isWavierGivenByMe"
            (click)="onWaieverReject()" class="ml-2" style="background-color: #28a745; color: #ffffff; font-size: 12px"
            mat-raised-button color="primary">
            Waiver Rejected
          </button>
        </ng-conatiner> -->
      <button
        type="button"
        (click)="onCancel()"
        class="btn btn-light font-size-12 ml-2"
      >
        Cancel
      </button>
    </div>
  </ng-container>
</div>

<div
  class="modal fade in"
  id="ViewLoanStatementPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseViewLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>
