import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";

@Component({
  selector: "app-case-type-list",
  templateUrl: "./case-type-list.component.html",
  styleUrls: ["./case-type-list.component.scss"],
})
export class CaseTypeListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  dataSource: any;
  loading: boolean = false;

  displayedColumns: string[] = [
    "Id",
    "Type",
    "Short_Case_Type_Name",
    "Case_Type_Name",
    "Parent_Name",
    "Action",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SearchId: any = 0;
  SearchBy: any = "List";
  JsonData: any;

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Case Type List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLegalCaseType();
  }

  GetLegalCaseType() {
    this._MasterService
      .Get_Legal_Case_Type({ Id: this.SearchId, SearchBy: this.SearchBy })
      .subscribe((res: any) => {
        console.log("Get_Legal_Case_Type", res);

        if (res.length > 0) {
          this.JsonData = res;
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.dataSource = null;
        }
      });
  }

  GetData(data) {
    if (data == true) {
      this.GetLegalCaseType();
    }
  }

  OnDeleteCaseType(row) {
    console.log("row", row);

    this.dialog
      .openConfirmDialog(`Are you sure you want to delete this member.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;

          this._MasterService
            .Save_Legal_Case_Type({
              Type: row.Type,
              Case_Type_Name: row.Case_Type_Name,
              Short_Case_Type_Name: row.Short_Case_Type_Name,
              ParentId: row.ParentId,
              Id: row.Id,
              LoginUserId: this.currentUser.userId,
              SearchBy: "Delete",
            })
            .subscribe((response) => {
              if (response[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.GetLegalCaseType();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
}
