<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-4 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="getPartnerList()"
        [(ngModel)]="RequestModel.PartnerType"
      >
        <option value="">All</option>
        <option value="DSA">DSA</option>
        <option value="Agent">Sales Agent</option>
        <option value="Dealer">Dealer</option>
        <option value="Advocate">Advocate</option>
        <option value="Valuer">Valuer</option>
        <option value="Seizer">Repo Agent</option>
        <option value="Collection">Collection Agent</option>
      </select>
    </div>
    <div class="col-md-4 text-right">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="text-right col-md-4 my-1 pr-2">
      <button
        (click)="OnOpenPartnerModal({}, $event)"
        type="button"
        class="btn font-size-12 button-btn"
      >
        Register a new Partner
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3" [hidden]="!dataSource">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[
        displayedColumns.indexOf('Edit'),
        displayedColumns.indexOf('Delete')
      ]"
    >
      <ng-container matColumnDef="PartnerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Partner">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Partner
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Type == "Seizer" ? "Repo Agent" : row.Type }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="MobileNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Mobile No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.MobileNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PartnerBank">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Partner Bank
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.PartnerBank }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AccountNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Account No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AccountNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Address">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          title="{{ row.Address }}"
        >
          {{ row.Address.length > 0 ? row.Address.slice(0, 20) : ""
          }}{{ row.Address.length > 20 ? "..." : "" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Edit">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          *matHeaderCellDef
        >
          View
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
          <i
            class="fa fa-eye"
            (click)="OnOpenPartnerModal(row, $event)"
            style="cursor: pointer; color: green; font-size: 16px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Delete">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          *matHeaderCellDef
        >
          Delete
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
          <i
            class="fa fa-trash style-delete"
            (click)="onDeletePartner(row, $event)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i class="fa fa-file-export mr-1"></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="partnerModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSavePartner()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Partner Details
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0">
            <div class="col-md-6 p-1">
              <span class="required-lable"> Partner Name </span>
              <input
                required
                type="text"
                #refPartner_Name="ngModel"
                [(ngModel)]="partnerModel.Partner_Name"
                placeholder="Partner Name"
                name="Partner_Name"
                id="Partner_Name"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_Name.invalid,
                  'alert-warning':
                    refPartner_Name.invalid &&
                    (refPartner_Name.dirty ||
                      refPartner_Name.touched ||
                      refPartner_Name.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Partner type </span>
              <select
                name="Partner_Type"
                id="Partner_Type"
                #refPartner_Type="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_Type.invalid,
                  'alert-warning':
                    refPartner_Type.invalid &&
                    (refPartner_Type.dirty ||
                      refPartner_Type.touched ||
                      refPartner_Type.untouched)
                }"
                [(ngModel)]="partnerModel.Partner_Type"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Partner type</option>
                <option value="DSA">DSA</option>
                <option value="Agent">Sales Agent</option>
                <option value="Dealer">Dealer</option>
                <option value="Advocate">Advocate</option>
                <option value="Valuer">Valuer</option>
                <option value="Seizer">Repo Agent</option>
                <option value="Collection">Collection Agent</option>
              </select>
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Partner Address </span>
              <textarea
                required
                rows="3"
                #refPartner_Address="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_Address.invalid,
                  'alert-warning':
                    refPartner_Address.invalid &&
                    (refPartner_Address.dirty ||
                      refPartner_Address.touched ||
                      refPartner_Address.untouched)
                }"
                [(ngModel)]="partnerModel.Partner_Address"
                placeholder="Partner Address"
                name="Partner_Address"
                id="Partner_Address"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> State </span>
              <select
                name="State"
                id="State"
                #refState="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refState.invalid,
                  'alert-warning':
                    refState.invalid &&
                    (refState.dirty || refState.touched || refState.untouched)
                }"
                [(ngModel)]="partnerModel.State"
                class="form-control input-text-css"
                required
                (change)="GetDistrickDropdown()"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> District </span>
              <select
                name="District_Id"
                id="District_Id"
                #refDistrict_Id="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDistrict_Id.invalid,
                  'alert-warning':
                    refDistrict_Id.invalid &&
                    (refDistrict_Id.dirty ||
                      refDistrict_Id.touched ||
                      refDistrict_Id.untouched)
                }"
                [(ngModel)]="partnerModel.District_Id"
                class="form-control input-text-css"
                required
                (change)="GetTehasilDropdown()"
              >
                <option value="">Select District</option>
                <option
                  *ngFor="let district of districkDropdown"
                  [value]="district.DistrictId"
                >
                  {{ district.District_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Tehsil </span>
              <select
                name="Tehsil_Id"
                id="Tehsil_Id"
                #refTehsil_Id="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refTehsil_Id.invalid,
                  'alert-warning':
                    refTehsil_Id.invalid &&
                    (refTehsil_Id.dirty ||
                      refTehsil_Id.touched ||
                      refTehsil_Id.untouched)
                }"
                [(ngModel)]="partnerModel.Tehsil_Id"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Tehsil</option>
                <option
                  *ngFor="let tehsil of tehasilDropdown"
                  [value]="tehsil.TehsilId"
                >
                  {{ tehsil.Tehsil_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Partner MobileNo </span>
              <input
                required
                type="text"
                #refPartner_MobileNo="ngModel"
                numbersOnly
                minlength="10"
                maxlength="10"
                [(ngModel)]="partnerModel.Partner_MobileNo"
                placeholder="Partner MobileNo"
                name="Partner_MobileNo"
                id="Partner_MobileNo"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_MobileNo.invalid,
                  'alert-warning':
                    refPartner_MobileNo.invalid &&
                    (refPartner_MobileNo.dirty ||
                      refPartner_MobileNo.touched ||
                      refPartner_MobileNo.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner BankName </span>
              <input
                type="text"
                #refPartner_BankName="ngModel"
                [(ngModel)]="partnerModel.Partner_BankName"
                placeholder="Partner BankName"
                name="Partner_BankName"
                id="Partner_BankName"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_BankName.invalid,
                  'alert-warning':
                    refPartner_BankName.invalid &&
                    (refPartner_BankName.dirty ||
                      refPartner_BankName.touched ||
                      refPartner_BankName.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner BankBranch </span>
              <input
                type="text"
                #refPartner_BankBranch="ngModel"
                [(ngModel)]="partnerModel.Partner_BankBranch"
                placeholder="Partner BankBranch"
                name="Partner_BankBranch"
                id="Partner_BankBranch"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_BankBranch.invalid,
                  'alert-warning':
                    refPartner_BankBranch.invalid &&
                    (refPartner_BankBranch.dirty ||
                      refPartner_BankBranch.touched ||
                      refPartner_BankBranch.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner BankIFSCCode </span>
              <input
                type="text"
                #refPartner_BankIFSCCode="ngModel"
                [(ngModel)]="partnerModel.Partner_BankIFSCCode"
                placeholder="Partner BankIFSCCode"
                name="Partner_BankIFSCCode"
                id="Partner_BankIFSCCode"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_BankIFSCCode.invalid,
                  'alert-warning':
                    refPartner_BankIFSCCode.invalid &&
                    (refPartner_BankIFSCCode.dirty ||
                      refPartner_BankIFSCCode.touched ||
                      refPartner_BankIFSCCode.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner AccountHolderName </span>
              <input
                type="text"
                #refPartner_AccountHolderName="ngModel"
                [(ngModel)]="partnerModel.Partner_AccountHolderName"
                placeholder="Partner AccountHolderName"
                name="Partner_AccountHolderName"
                id="Partner_AccountHolderName"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid':
                    f.submitted && refPartner_AccountHolderName.invalid,
                  'alert-warning':
                    refPartner_AccountHolderName.invalid &&
                    (refPartner_AccountHolderName.dirty ||
                      refPartner_AccountHolderName.touched ||
                      refPartner_AccountHolderName.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner AccountNo </span>
              <input
                type="text"
                #refPartner_AccountNo="ngModel"
                numbersOnly
                [(ngModel)]="partnerModel.Partner_AccountNo"
                placeholder="Partner AccountNo"
                name="Partner_AccountNo"
                id="Partner_AccountNo"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refPartner_AccountNo.invalid,
                  'alert-warning':
                    refPartner_AccountNo.invalid &&
                    (refPartner_AccountNo.dirty ||
                      refPartner_AccountNo.touched ||
                      refPartner_AccountNo.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner PayOutAmount </span>
              <input
                type="text"
                numbersOnly
                [(ngModel)]="partnerModel.Partner_PayOutAmount"
                placeholder="Partner PayOutAmount"
                name="Partner_PayOutAmount"
                id="Partner_PayOutAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner PayoutPercenatge </span>
              <input
                type="text"
                numbersOnly
                [(ngModel)]="partnerModel.Partner_PayoutPercenatge"
                placeholder="Partner PayoutPercenatge"
                name="Partner_PayoutPercenatge"
                id="Partner_PayoutPercenatge"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Partner PAN No. </span>
              <input
                type="text"
                pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                [(ngModel)]="partnerModel.Partner_PAN_No"
                placeholder="Partner PAN No."
                name="Partner_PAN_No"
                id="Partner_PAN_No"
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-12 p-1">
              <span class="required-lable">Select Branch Name</span>

              <form [formGroup]="userFormUpdate">
                <ng-multiselect-dropdown
                  [(ngModel)]="partnerModel.UpdateBranch"
                  name="UpdateBranch"
                  [placeholder]="'Select Branch'"
                  [data]="BranchList"
                  formControlName="UpdateBranch"
                  [settings]="UserDropdownSettings"
                  (onDeSelect)="OnUserDeSelect($event)"
                  (onSelectAll)="OnSelectAll($event)"
                  (onSelect)="OnUserSelect($event)"
                  (onDeSelectAll)="OnDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
              </form>

              <!-- <form [formGroup]="BranchForm">
                <ng-multiselect-dropdown
                  name="Branch"
                  [placeholder]="'Select Branch'"
                  [data]="BranchList"
                  formControlName="Branch"
                  [settings]="BranchDropdownSettings"
                  [(ngModel)]="Branch"
                  (onDeSelect)="OnBranchSelect($event)"
                  (onSelect)="OnBranchSelect($event)"
                >
                </ng-multiselect-dropdown>
              </form> -->
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 mt-4 p-1">
              <mat-checkbox
                id="IsEmployee"
                name="IsEmployee"
                [hidden]="partnerModel.PartnerId > 0"
                [(ngModel)]="Ischecked"
                (change)="onChangeCreateAcc($event)"
              >
                Do you want to create account ?
              </mat-checkbox>
            </div>
            <div class="col-md-12 mt-2 p-1" *ngIf="partnerModel.PartnerId > 0">
              <span class="required-lable">Select Account</span>
              <select
                name="Partner_AccountId"
                id="Partner_AccountId"
                required
                #refEmp_AccountId="ngModel"
                (change)="Get_Group()"
                [(ngModel)]="partnerModel.Partner_AccountId"
                class="form-control input-text-css"
              >
                <option value="0">Select Account</option>
                <option
                  *ngFor="let data of AccountHeadDropdown"
                  [value]="data.AccountId"
                >
                  {{ data.Account_Name }}
                </option>
              </select>
            </div>
            <div
              class="col-md-12 p-1"
              *ngIf="Ischecked || partnerModel.PartnerId > 0"
            >
              <span class="required-lable">Select Group</span>
              <select
                name="GroupId"
                id="GroupId"
                required
                [disabled]="partnerModel.PartnerId > 0"
                #refGroupId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroupId.invalid,
                  'alert-warning':
                    refGroupId.invalid &&
                    (refGroupId.dirty ||
                      refGroupId.touched ||
                      refGroupId.untouched)
                }"
                [(ngModel)]="partnerModel.GroupId"
                class="form-control input-text-css"
              >
                <option value="0">Select Group</option>
                <option
                  *ngFor="let data of groupDropdown"
                  [value]="data.GroupId"
                >
                  {{ data.Group_Name }}
                  <ng-container *ngIf="data.Group_Tag"
                    >({{ data.Group_Tag }})</ng-container
                  >
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12 mt-2 upload">
              <b> Upload Documents </b>
              <input
                #docFile5
                type="file"
                [multiple]="false"
                accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                (change)="
                  fileChangeListenerAttachment(docFile5.files, docFile5)
                "
                style="display: none"
              />
              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of PartnerAttachment; let i = index"
              >
                <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                <div class="col-md-3">
                  <input
                    type="text"
                    placeholder="Title"
                    name="title{{ i }}"
                    id="title{{ i }}"
                    #refTitle="ngModel"
                    [(ngModel)]="report.Title"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <i
                      class="fa fa-upload ml-2"
                      *ngIf="!report.DocFileName"
                      (click)="docFile5.click()"
                      aria-hidden="true"
                    ></i>
                    <ng-container *ngIf="report.DocFileName">
                      <div>
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                        >{{ report.DocFileName }}
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFileAttachment(i)"
                        ></i>
                      </div>
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSavePartner()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid && userFormUpdate.invalid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="View"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Edit Partner Detail
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body formborder m-3">
        <ng-container>
          <div class="row m-0 mt-3">
            <div class="col-md-2">
              <h6 class="fs-12">Partner Name</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Partner Type</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_Type }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Partner Address</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_Address }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">State</h6>
              <p class="lead fs-12">{{ partnerModel?.State_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">District</h6>
              <p class="lead fs-12">{{ partnerModel?.District_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tehsil</h6>
              <p class="lead fs-12">{{ partnerModel?.Tehsil_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Partner Mobile No</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_MobileNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Partner Bank Name</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_BankName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Bank Branch</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_BankBranch }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">IFSC Code</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_BankIFSCCode }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Account Holder Name</h6>
              <p class="lead fs-12">
                {{ partnerModel?.Partner_AccountHolderName }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Account No</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_AccountNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Payout Amount</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_PayOutAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Payout Percentage</h6>
              <p class="lead fs-12">
                {{ partnerModel?.Partner_PayoutPercenatge }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">PAN No</h6>
              <p class="lead fs-12">{{ partnerModel?.Partner_PAN_No }}</p>
            </div>
          </div>

          <div class="row mt-2" *ngIf="PartnerAttachment.length > 0">
            <div class="col-md-12 mt-2">
              <b> Upload Document </b>
              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of PartnerAttachment; let i = index"
              >
                <div class="col-md-1 pl-0">
                  {{ i + 1 }}
                </div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{ report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a
                      href="{{ docBaseUrl }}/{{ report.DocFileName }}"
                      target="_blank"
                      *ngIf="report.DocFileName"
                      >{{ report.DocFileName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right mb-2" style="margin-right: 50px">
            <button
              (click)="edit()"
              type="button"
              class="mt-3 btn font-size-12 button-btn"
            >
              Edit
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
