import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DataSharingService } from '../AuthGuard/DataSharingService';
import { PresenceService } from '../_Chatbox/_service/presence.service';
import { MasterService } from '../Shared/app.Masters.Service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Component({
    template: ''
})
export class UserLogoutComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  constructor( private snackBar: MatSnackBar,private _MasterService: MasterService, private _Route: Router, private dataSharingService: DataSharingService,private presenceService:PresenceService) {

    }


    ngOnInit() {
        this.dataSharingService.RefreshLoginPage.next('false');

     
      this._MasterService.Logout().subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

          sessionStorage.removeItem('currentUser');
          sessionStorage.clear();
          this.presenceService.currentUser.next(null);
        }
      });

        this._Route.navigate(['Login']);
    }
}
