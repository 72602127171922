<app-mat-spinner-overlay *ngIf="loading"></app-mat-spinner-overlay>
<div class="dropdown">
  <button
    class="mt-2 btn font-size-12 button-btn dropdown-toggle mat-button-wrapper"
    style="padding: 10px"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Print {{ textName }}
  </button>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    style="max-height: 95px; overflow: auto"
  >
    <a
      class="dropdown-item"
      *ngFor="let x of dropdownLanguages"
      (click)="Get_Template(x.Id)"
      >{{ x.Template_Name }}</a
    >
  </div>
</div>

<div
  class="modal fade in"
  id="Template{{ TemplateName }}model"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <button
          type="button"
          class="font-size-12 button-btn"
          color="primary"
          [class.spinner]="loading"
          (click)="printLetter()"
        >
          Print {{ textName }}
        </button>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div
        class="modal-body"
        id="pdf{{ TemplateName }}LetterDownload"
        style="overflow-wrap: anywhere"
      >
        <div class="row mb-0">
          <div class="col-md-12 ck-content">
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>

      <div id="LODPrint" style="display: none">
        <table
          class="MsoTableGrid"
          width="1000px"
          border="1"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr>
              <!--<th class="WD-66">Document Type</th>-->
              <th class="WD-66">Document Title</th>
              <th class="WD-66">In Favour Of</th>
              <th class="WD-66">Date Of Executation</th>
              <th class="WD-66">Executation By</th>
              <th class="WD-66">No Of Pages</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of LODData; let i = index">
              <!--<td>{{ data.DocType }}</td>-->
              <td>{{ data.DocTitle }}</td>
              <td>{{ data.Remark }}</td>
              <td>{{ data.DateOfExecution }}</td>
              <td>{{ data.ExecutedBy }}</td>
              <td>{{ data.No_Of_Pages }}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
