<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keydown.enter)="onSearchBusinessData()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            [(ngModel)]="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span> Accounting Category</span>
        <select
          name="LoanAcCategoryId"
          id="LoanAcCategoryId"
          [(ngModel)]="LoanAcCategoryId"
          class="form-control input-text-css"
          #refLoanAcCategoryId="ngModel"
        >
          <option value="0">All</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Report Type </span>
        <select
          name="ReportType"
          id="ReportType"
          [(ngModel)]="ReportType"
          class="form-control input-text-css"
          (change)="onChangeReportTypes()"
          required
          #refReportType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refReportType.invalid,
            'alert-warning':
              refReportType.invalid &&
              (refReportType.dirty ||
                refReportType.touched ||
                refReportType.untouched)
          }"
        >
          <option value="">Select Report Type</option>
          <option *ngFor="let row of ArrTypes" [value]="row.id">
            {{ row.value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" *ngIf="SlabDropdown">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="''">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSearchBusinessData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-2" *ngIf="DataDiv">
    <div class="">
      <div class="table-responsive mt-3">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 40px"
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              style="max-width: 40px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Branch_Name
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Product_Name
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Gender
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Religion">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Religion</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Religion
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Cast">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Caste</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Cast
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="MaritalStatus">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Marital Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.MaritalStatus
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Customer_Profile">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Profile</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_Profile
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef=" Natureofwork">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Nature of work</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Natureofwork
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="SlabName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Slab Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.SlabName
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="NoOfLoans">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Count</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NoOfLoans
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
              totaltypewise("NoOfLoans")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="POS_OS">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >POS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.POS_OS
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
              totaltypewise("POS_OS")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="IN_Percentage">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >IN %</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.IN_Percentage
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
              totaltypewise("IN_Percentage")
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDueListDetails(row)"
                *ngIf="row.NoOfLoans > 0"
                aria-label="true"
              ></i>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-12 p-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-5 mt-4">
      <div id="ampiechart2"></div>
    </div> -->
  </div>
</div>

<div
  class="modal fade in"
  id="ViewDueListDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Details
        </h6>
        <button
          type="button"
          (click)="onCloseDueListDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceModel"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="PLoan_Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px; border-right: 1px #f7f9f9 solid"
                *matHeaderCellDef
              >
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 50px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PLoanAcNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Partner</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.PLoanAcNo }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="LMS_LoanAcNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Customer</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.LMS_LoanAcNo }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PLoan_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Loan Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.PLoan_Date
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.CustomerName
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="grid-header"
                >Total</mat-footer-cell
              >
            </ng-container>

            <ng-container matColumnDef="LoanAmount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >Loan Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.LoanAmount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{ DataSourceModel ? totaltypewiseDueList("LoanAmount") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMIAmount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.EMIAmount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{ DataSourceModel ? totaltypewiseDueList("EMIAmount") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Loan_Tenure">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Loan Tenure</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Loan_Tenure
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="DPD_Days">
              <mat-header-cell
                mat-sort-header
                class="grid-header text-right"
                *matHeaderCellDef
                >DPD</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.DPD_Days
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="OverDUE_EMI">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.OverDUE_EMI
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-right grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_OverDUE_EMI_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_OverDUE_EMI_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_OverDUE_EMI_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_OverDUE_Principal_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >POS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_OverDUE_Principal_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_OverDUE_Principal_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="overDue_Interest_AMount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.overDue_Interest_AMount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("overDue_Interest_AMount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Last_Recv_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Last Recv Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Last_Recv_Date
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Last_Recv_Amount">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Last Recv Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Last_Recv_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Last_Recv_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TotalEMI_NOS">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.TotalEMI_NOS
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_EMI_AMount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amt</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_EMI_AMount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_EMI_AMount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_Principal_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >POS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_Principal_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_Principal_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_Interest_AMount">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_Interest_AMount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_Interest_AMount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMI_DUE_NOS">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.EMI_DUE_NOS
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-right grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMI_DUE">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amt</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.EMI_DUE
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Principal_DUE">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >POS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Principal_DUE
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel ? totaltypewiseDueList("Principal_DUE") : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Interest_DUE">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Interest_DUE
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel ? totaltypewiseDueList("Interest_DUE") : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="receved_EMI">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.receved_EMI
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-right grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_Recv_EMI_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_Recv_EMI_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_Recv_EMI_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_Recv_Principal_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >POS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_Recv_Principal_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_Recv_Principal_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Total_Recv_Interest_AMount">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Total_Recv_Interest_AMount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Total_Recv_Interest_AMount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="current_Due_EMI_No">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.current_Due_EMI_No
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-right grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="current_Due_Amount">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.current_Due_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("current_Due_Amount")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Balance_Principle">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >POS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Balance_Principle
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Balance_Principle")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Balance_Interest">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Balance_Interest
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel
                    ? totaltypewiseDueList("Balance_Interest")
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Other_Charges">
              <mat-header-cell
                class="grid-header text-right"
                *matHeaderCellDef
                style="border-right: 1px #f7f9f9 solid"
                >Other Dues</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Other_Charges
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel ? totaltypewiseDueList("Other_Charges") : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Penal_Interest">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef
                >LPI</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.Penal_Interest
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{
                  DataSourceModel ? totaltypewiseDueList("Penal_Interest") : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMI_Frequency">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >EMI Frequency</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.EMI_Frequency
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="current_Due_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Current Due Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.current_Due_Date
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer Phone No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Customer_PhoneNo
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Address</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Address
              }}</mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
                >Action</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-center">
                <i
                  class="fa fa-arrow-circle-right btn_detail"
                  (click)="goToLoanStatement(row)"
                  aria-label="true"
                ></i>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="grid-header"
              ></mat-footer-cell>
            </ng-container>

            <!-- Top header column start here -->
            <ng-container matColumnDef="row-first">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="1"
                style="border-right: 1px #f7f9f9 solid"
              >
                &nbsp;
              </th>
            </ng-container>
            <ng-container matColumnDef="row-second">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="2"
                style="border-right: 1px #f7f9f9 solid"
              >
                Loan A/C No.
              </th>
            </ng-container>
            <ng-container matColumnDef="row-third">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="5"
                style="border-right: 1px #f7f9f9 solid"
              >
                &nbsp;
              </th>
            </ng-container>

            <ng-container matColumnDef="row-fourth">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="7"
                style="border-right: 1px #f7f9f9 solid"
              >
                OVER DUE
              </th>
            </ng-container>
            <ng-container matColumnDef="row-fifth">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="4"
                style="border-right: 1px #f7f9f9 solid"
              >
                Agreement
              </th>
            </ng-container>
            <ng-container matColumnDef="row-sixth">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="4"
                style="border-right: 1px #f7f9f9 solid"
              >
                DUES
              </th>
            </ng-container>
            <ng-container matColumnDef="row-seventh">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="4"
                style="border-right: 1px #f7f9f9 solid"
              >
                Received
              </th>
            </ng-container>
            <ng-container matColumnDef="row-eighth">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="5"
                style="border-right: 1px #f7f9f9 solid"
              >
                Balances
              </th>
            </ng-container>
            <ng-container matColumnDef="row-nineth">
              <th
                mat-header-cell
                class="grid-header"
                *matHeaderCellDef
                [attr.colspan]="6"
              >
                &nbsp;
              </th>
            </ng-container>
            <!-- Top header column end here -->
            <!-- Top header row start here -->
            <tr
              mat-header-row
              *matHeaderRowDef="
                [
                  'row-first',
                  'row-second',
                  'row-third',
                  'row-fourth',
                  'row-fifth',
                  'row-sixth',
                  'row-seventh',
                  'row-eighth',
                  'row-nineth'
                ];
                sticky: true
              "
            ></tr>
            <!-- Top header row end here -->
            <mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsModel; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>

          <div class="row m-0 align-items-center">
            <div class="col-md-4">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportTable(exporter)"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8">
              &nbsp;
              <mat-paginator
                #paginatorRef
                [pageSizeOptions]="[20, 50]"
                class="mat-paginator-sticky"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
        <!--<div class="table-responsive">
    <mat-table [dataSource]="DataSourceModel" matSort matTableExporter #exporter="matTableExporter"
      style="height: 400px; max-width: 100%; overflow: auto; overflow-x: scroll;">
      <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumnsModel">
        <mat-header-cell mat-sort-header class="grid-header"  *matHeaderCellDef>
          {{ col == 'Id' ? "#" : col.split('_').join(' ') }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          {{
            col == 'Id' ? paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) : row[col]
          }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsModel; sticky: true"></mat-header-row>
      <mat-row  *matRowDef="let row;columns:displayedColumnsModel"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff;">
      <div class="col-md-4">
        <button class="btn btn-success font-size-12" (click)="exportTable(exporter)">
          <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div>-->
      </div>
    </div>
  </div>
</div>
