<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchPartnerModifyBookingList()"
  >
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-2">
        <span class="required-lable"> Loan Status</span>
        <select
          name="LoanStatus"
          id="LoanStatus"
          [(ngModel)]="LoanStatus"
          class="form-control input-text-css"
          #refLoanStatus="ngModel"
          required
        >
          <option value="Live">Live</option>
          <option value="Close">Close</option>
        </select>
      </div>
      <!--<div class="col-md-2">
        <span class="required-lable"> Select Type </span>
        <select
          name="Loan_Acc_Type"
          id="Loan_Acc_Type"
          [(ngModel)]="Loan_Acc_Type"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refLoan_Acc_Type="ngModel"
          required
                disabled
          [ngClass]="{
            'is-invalid': df.submitted && refLoan_Acc_Type.invalid,
            'alert-warning':
              refLoan_Acc_Type.invalid &&
              (refLoan_Acc_Type.dirty ||
                refLoan_Acc_Type.touched ||
                refLoan_Acc_Type.untouched)
          }"
        >-->
          <!--<option value="">Select Type</option>-->
          <!--<option value="All">All</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-3">
        <span class="required-lable"> Partner</span>
        <select
          name="ACCCategoryType"
          id="ACCCategoryType"
          [(ngModel)]="ACCCategoryType"
          class="form-control input-text-css"
          #refACCCategoryType="ngModel"
        >
          <option value="All">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSearchPartnerModifyBookingList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div
        class="col-md-3 mt-2"
        style="margin-left: 50px !important"
        [hidden]="!dataSourceLoan"
      >
        <!-- <mat-form-field><input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ...."></mat-form-field> -->
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="row my-2" [hidden]="!dataSourceLoan">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporterLoanInfo="matTableExporter"
          style="width: 1250px; overflow: auto"
        >
          <ng-container matColumnDef="LoanId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >

            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Partner_LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Partner A/C</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Partner_LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LMS_LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer A/C</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LMS_LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanCreatedOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanCreatedOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Application_No }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAMount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAMount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMIAmount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DueDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Acc_Category">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Acc_Category }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetailLoan(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
        </mat-table>
      </div>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable(exporterLoanInfo)"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSize]="20"
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
