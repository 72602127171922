import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;

@Component({
  selector: 'app-insurance-company',
  templateUrl: './insurance-company.component.html',
  styleUrls: ['./insurance-company.component.scss']
})
export class InsuranceCompanyComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = 0;
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  applicationId: any = 95;
  dataCustomerSource: any;
  InsuranceCompanyName: any = '';
  SelectInsuranceType: any = '';
  saveBtn: boolean = true;
  updateBtn: boolean = false;
  Id: number;
  Int_Id: any;
  // InsuranceType:any = ['Life Insurance','Asset Insurance'];
  InsuranceType: string = 'false';
  AssestInsurances: string = 'false';

  GetInsuranceCompany: any;
  Insurance_com: any;
  item: any;

  selectedItemsList: any = [{ InsuranceType: '' }];
  checkedIDs = [];


  checkboxesDataList = [
    {
      id: 'C001',
      InsuranceType: 'Assest Insurance',
      isChecked: false
    },
    {
      id: 'C002',
      InsuranceType: 'Life Insurance',
      isChecked: false,
    }
  ]


  displayedCustomerColumns: string[] = ['Int_Id', 'Insurance Company', 'Insurance Type', 'Status', 'Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }



  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Insurance Company');
    this.fetchSelectedItems()
    this.fetchCheckedIDs()
    this.LMS_GetInsuranceCompany()
  }

  changeSelection() {
    this.fetchSelectedItems()
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      if (value.isChecked) {
        console.log(value, 'value');
      }
      return value.isChecked
    });

  }

  fetchCheckedIDs() {
    this.checkedIDs = []
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        console.log(value.InsuranceType, 'this.checkedIDs');
        this.checkedIDs.push(value.id);
      }
    });
  }


  SaveInsuranceCompany(formValue) {
    let InsuranceTypeVal = "";
    this.selectedItemsList.forEach(element => {
      InsuranceTypeVal += element.InsuranceType + ',';
    });
    let formValues = { Insurance_Company_Name: formValue.value.Insurance_company, Insurance_Type: InsuranceTypeVal, Status: 1, IsActive: 1 };
    this.LmsService.Save_InsuranceCompany(formValues).subscribe((res) => {
      if (res[0].CODE == 0) {
        this.LMS_GetInsuranceCompany();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }

  UpdateInsuranceCmpy(formValue) {
    let InsuranceTypeVal = "";
    this.selectedItemsList.forEach(element => {
      InsuranceTypeVal += element.InsuranceType + ',';
    });
    console.log("formValue.value", formValue.value);
    let formValues = { Int_Id: this.Int_Id, Insurance_Company_Name: formValue.value.Insurance_company, Insurance_Type: InsuranceTypeVal, Status: 1, IsActive: 1 };
    this.LmsService.Save_InsuranceCompany(formValues).subscribe((res) => {
      if (res[0].CODE == 0) {
        this.LMS_GetInsuranceCompany();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  LMS_GetInsuranceCompany() {
    this.showSpinner = true;
    this.LmsService.GetInsuranceCompany().subscribe((res: any) => {
      if (res) {
        this.GetInsuranceCompany = new MatTableDataSource(res);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Server Error', ...this.configSuccess });
      }
    });
  }


  status(val) {
    if (val.Status == 1) {
      this.LmsService.Save_InsuranceCompany({ Int_Id: val.Int_Id, Status: 0, StatusVal: 'STATUS' }).subscribe((res) => {
        if (res[0].CODE == 0) {
          this.LMS_GetInsuranceCompany();
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
    } else {
      this.LmsService.Save_InsuranceCompany({ Int_Id: val.Int_Id, Status: 1, StatusVal: 'STATUS' }).subscribe((res) => {
        if (res[0].CODE == 0) {
          this.LMS_GetInsuranceCompany();
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
    }

  }

  editInsuranceCmpy(val) {
    console.log(val.Insurance_type.split(','), 'edit', val.Insurance_type.split(',').length);
    var filtered = val.Insurance_type.split(',').filter(function () { return true });
    this.Int_Id = val.Int_Id;
    var i = 0;
    if (filtered) {
      this.checkboxesDataList.forEach((ele) => {
        ele.isChecked = false;
        if (filtered.find(elem => elem === "Assest Insurance") && ele.id == "C001") {
          console.log(ele.InsuranceType + '=' + 'Assest Insurance');
          //$('#C001').val($(this).is(':checked'));
          var element1 = <HTMLInputElement>document.getElementById("C001");
          element1.checked = true;
          ele.isChecked = true;
        } else if (filtered.find(elem => elem === "Life Insurance") && ele.id == "C002") {
          // ele.isChecked = true;
          console.log(ele.InsuranceType + '=' + 'Life Insurance');
          // $('#C002').attr('checked');
          // $('#C002').val($(this).is(':checked'));
          var element = <HTMLInputElement>document.getElementById("C002");
          element.checked = true;
          ele.isChecked = true;
        }
        i = i + 1;
        // {
        //   id: 'C001',
        //   InsuranceType: 'Assest Insurance',
        //   isChecked: false
        // },
        // {
        //   id: 'C002',
        //   InsuranceType: 'Life Insurance',
        //   isChecked: false,
        // }
      });
      console.log("this.checkboxesDataList", this.checkboxesDataList);
      console.log("this.selectedItemsList", this.selectedItemsList);
      this.fetchSelectedItems();
    }

    this.saveBtn = false;
    this.updateBtn = true;
    this.Id = val.Int_Id;
    this.Insurance_com = val.Insurance_company;
    // this.item.isChecked = val.Insurance_Type;
  }

  UpdateLoanAcct() {
    this.saveBtn = true;
    this.updateBtn = false;
    let update = {
      // "Loan_Accounting_Category": this.SelectLoanAccountingCategory,
      "Product": '',
      "Int_Id": this.Id,
      "IsActive": 0
    }
    this._LmsService.Save_Lms_loanAccounting(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetInsuranceCompany();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  DeleteInsuranceCompany(val) {
    let update = {
      "Int_Id": val.Int_Id,
      "IsActive": 0,
      "StatusVal": "Del",
      "Insurance_company": "finnux",
      "Insurance_type": "finnux"
    }
    this._LmsService.Save_InsuranceCompany(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetInsuranceCompany();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

}
