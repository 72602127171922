import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { map, startWith } from "rxjs/operators";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-lms-case-wise-acc-balance-loan",
  templateUrl: "./lms-case-wise-acc-balance-loan.component.html",
  styleUrls: ["./lms-case-wise-acc-balance-loan.component.scss"],
})
export class LmsCaseWiseAccBalanceLoanComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  DataSource: any;
  displayedColumns: string[] = [];

  FromDate: any = ""; //new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = ""; //new Date();
  Type: any = "";

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  IncomeAccDropdown: any[] = [];
  IncomeAccControl = new UntypedFormControl();
  filteredIncAccDropdown: Observable<any[]>;
  IncomeAcc: any;
  AccountIncome: any;
  Jsondata: any[] = [];

  BalanceAccDropdown: any[] = [];
  BalanceAccControl = new UntypedFormControl();
  filteredBalAccDropdown: Observable<any[]>;
  BalanceAcc: any;
  AccountBalance: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  FyearId: any;
  maxDate: any;
  minDate: any;

  constructor(
    private lmsService: LmsService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private _MasterService: MasterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Case Wise Account Balance Loan");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getIncomeAccount();
    this.getBalanceAccount();

    this.displayedColumns = [
      "Id",
      "caseNo",
      "opDR",
      "opCR",
      "opening",
      "DRsum",
      "CRsum",
      "Closing",
    ];

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          this.DataSource = null;
        });
    });
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date should be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.IncomeAccDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(AccInc: any): string {
    return AccInc && AccInc.Account_Name ? AccInc.Account_Name : "";
  }
  getIncomeAccount() {
    this.lmsService.Get_Income_Account({}).subscribe((res: any) => {
      this.IncomeAccDropdown = res;
      this.filteredIncAccDropdown = this.IncomeAccControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value.Account_Name
        ),
        map((name) =>
          name ? this._filter(name) : this.IncomeAccDropdown.slice()
        )
      );
    });
  }
  OnIncomeAccSelect(event: any) {
    this.IncomeAcc = event.option.value.AccountId;
  }

  private _filter1(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.BalanceAccDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  getBalanceAccount() {
    this.lmsService.Get_Balance_Account({}).subscribe((res: any) => {
      this.BalanceAccDropdown = res;
      this.filteredBalAccDropdown = this.BalanceAccControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value.Account_Name
        ),
        map((name) =>
          name ? this._filter1(name) : this.BalanceAccDropdown.slice()
        )
      );
    });
  }
  OnBalanceAccSelect(event: any) {
    this.IncomeAcc = event.option.value.AccountId;
  }
  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  onSearchCaseWiseAccBalance() {
    this.showSpinner = true;
    this.lmsService
      .ACC_CaseWise_Account_Balance_Loan({
        fromdate: this.FromDate,
        toDate: this.ToDate,
        accountid: this.IncomeAcc,
      })
      .subscribe((res: any) => {
        console.log("Resultsss", res);
        if (res.length > 0) {
          this.Jsondata = JSON.parse(JSON.stringify(res));
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "case_wise_acc_deatils_loan"
    ) as HTMLTableElement;
    const worksheetName = "CASE-WISE ACCOUNT BALANCE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  totaltypewise(type) {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i][type]);
        }
        return sum.toFixed(2);
      }
      return "";
    }
  }
}
