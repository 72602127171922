<section class="main-content-wrapper" style="padding:0px !important">
  

  <mat-tab-group [selectedIndex]="currentEmployeeTab" (selectedTabChange)="onTabChange($event)">
    <mat-tab class="mt-2 btn  font-size-11 button-btn mattab-active" selected>
      <ng-template mat-tab-label><span>Employee Detail</span></ng-template>
      <ng-template matTabContent>
        <app-mstemployeenewregister [EmployeeSummary]="EmployeeSummary"></app-mstemployeenewregister>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Branch Assignment</span></ng-template>
      <ng-template matTabContent>
        <app-employee_assign_branch [EmployeeSummary]="EmployeeSummary"></app-employee_assign_branch>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Product Assignment</span></ng-template>
      <ng-template matTabContent>
        <app-employee_assign_product [EmployeeSummary]="EmployeeSummary"></app-employee_assign_product>
      </ng-template>
    </mat-tab>
    <!--<mat-tab [disabled]="TabDisabled">
    <ng-template mat-tab-label><span>Assign LOS Process</span></ng-template>
    <ng-template matTabContent>
      <app-employee_assign_los_process [EmployeeSummary]="EmployeeSummary"></app-employee_assign_los_process>
    </ng-template>
  </mat-tab>-->

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Assign Quick Loan Process</span></ng-template>
      <ng-template matTabContent>
        <app-employee_assign_Quick_process [EmployeeSummary]="EmployeeSummary"></app-employee_assign_Quick_process>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
   
</section>
