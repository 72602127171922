import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";

@Component({
  selector: "app-mst-zone-list",
  templateUrl: "./mst-zone-list.component.html",
  styleUrls: ["./mst-zone-list.component.scss"],
})
export class MstZoneListComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = [
    "ZoneId",
    "Zone_Name",
    "BranchCount",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ZoneList: any = [];
  ZoneId: any = 0;
  ZoneName: any = "";
  ButtonName: string = "Add Zone";
  dialogreturn: any;
  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  getZoneList() {
    this.showSpinner = true;
    this._MasterService.GetZoneList(this.RequestModel).subscribe((result) => {
      this.ZoneList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.ZoneList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Zone List");
    this.getZoneList();
  }

  onSaveZone() {
    this.RequestModel.ZoneId = this.ZoneId;
    this.RequestModel.Zone_Name = this.ZoneName;
    this.RequestModel.LoginUserId = this.data.userId;
    this._MasterService
      .SaveZoneMaster(this.RequestModel)
      .subscribe((response) => {
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getZoneList();
          this.ButtonName = "Add Zone";
          this.ZoneId = 0;
          this.ZoneName = "";
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnEditZone(selectedZoneId, selectedZoneName) {
    this.ZoneId = selectedZoneId;
    this.ZoneName = selectedZoneName;
    this.ButtonName = "Edit Zone";
  }
  OnDeleteZoneData(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Delete_Zone_Master({ Zone_Id: row.ZoneId })
          .subscribe((response) => {
            if (response[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getZoneList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
}
