import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { SnackbarComponent } from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-lms-dv-status',
  templateUrl: './lms-dv-status.component.html',
  styleUrls: ['./lms-dv-status.component.scss']
})
export class LmsDvStatusComponent implements OnInit {
  Customer: any = {};
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  dataSource: any;

  PageId:any;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };


  SelectType : any = '';
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = []; 
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("DV Status");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.DVStatusList();

    this.Customer.Status='';
    this.Customer.CloseCase = '';

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    });
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0}).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    });
  }
  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId : '',
      Area : '',
      SubArea : '',
      SalesEx : '',
      CollectionEx : '',
      LoanAccountingCatId : '',
    };
  }

  DVStatusList() {
    this.showSpinner = true;
    this.displayedColumns = [
      "LoanId",	"LoanNo",	"LoanDate",	"ApplicationNo", "Customer", "Branch",	"Product",	"LoanAmount",	
      "Voucher_Status",	"CreatedBy",	"RevertedBy",	"AuthorizedBy", "action"
    ];

    this.lmsService
      .Get_All_DV_List({ LoginUserId: this.currentUser.userId, JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
          //console.log(res);
          if (res.length > 0) {
            if(res.length == 1){
              this.goToDetail(res[0]);
            }
            else {
              this.FileCheckingList = JSON.parse(JSON.stringify(res));
              this.dataSource = new MatTableDataSource(this.FileCheckingList);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.showSpinner = false;
            }
          }
          else{
            this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
            this.showSpinner = false;
          }
    });
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("dv_status") as HTMLTableElement;
    const worksheetName = 'DV STATUS'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }



  goToDetail(data: any) {
    this.PageId = "1";
    this.router.navigate([`/lms/dvauthorize/${encodeURIComponent(data.LoanId)}/${encodeURIComponent(this._EncrDecrService.encrypt(data.VoucherId))}/${encodeURIComponent(this.PageId)}`]);
  }
}
