import { Component, OnInit } from "@angular/core";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { DialogService } from "src/app/Shared/dialog.service";
@Component({
  selector: "app-parking-yard",
  templateUrl: "./parking-yard.component.html",
  styleUrls: ["./parking-yard.component.scss"],
})
export class ParkingYardComponent implements OnInit {
  private _MasterService;
  RequestModel: RequestModel = new RequestModel();
  SaveDistictRequestModel: RequestModel = new RequestModel();

  loading: boolean = false;
  parkingYard: any = {};
  parking_name: any = "";
  parking_yard_add: any = "";
  FilterStateList: any = [];
  StateId: any = "";
  DistrictId: any = "";
  DistrictList: any = [];
  tehasilDropdown: any = [];
  Tehsil_Id: any;
  currentUser: any;
  dataSource: any;
  displayedColumns: any;
  Id: number;
  BtnName: any = "Save";
  parkValue: any = "Add";

  row: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    private MasterService: MasterService,
    public snackBar: MatSnackBar,
    private dialog: DialogService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.getParkingYardList();
    this.getStateList();
    this.dataSharingService.HeaderTitle.next("Parking Yard List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  getParkingYardList() {
    this._MasterService.Get_ParkingYard_List({}).subscribe((res: any) => {
      if (res.length > 0) {
        this.displayedColumns = [
          "Parking_Name",
          "Yard_Address",
          "State_Name",
          "District_Name",
          "Tehsil_Name",
          "Action",
        ];
        // console.log(res)
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
      }
    });
  }
  getStateList() {
    this._MasterService.GetState().subscribe((result) => {
      this.FilterStateList = JSON.parse(JSON.stringify(result));
    });
  }
  getTehsilList() {
    this._MasterService
      .GetTahsil({ DistrictId: this.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdown = res;
      });
  }
  getDistrictList() {
    this.RequestModel.StateId = this.StateId == "" ? 0 : parseInt(this.StateId);
    this._MasterService
      .GetDistrictsList(this.RequestModel)
      .subscribe((result) => {
        this.DistrictList = JSON.parse(JSON.stringify(result));
      });
  }
  addParkingYard() {
    this.getStateList();
    this.parkValue = "Add";

    $("#parking-yard").modal("show");
    $("#parking-yard").css("z-index", "1050");
  }

  OnClose() {
    this.BtnName = "Save";
    this.parking_name = "";
    this.parking_yard_add = "";
    this.StateId = "";
    this.DistrictId = "";
    this.Tehsil_Id = "";
    this.DistrictList = [];
    this.tehasilDropdown = [];
    this.Id = 0;
    $("#parking-yard").modal("hidden");
  }

  onChangeState() {
    this.getDistrictList();
  }
  onChangeDistrict() {
    this.getTehsilList();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onSaveparkingYard() {
    this.loading = true;
    this.parkingYard.Parking_Name = this.parking_name;
    this.parkingYard.Yard_Address = this.parking_yard_add;
    this.parkingYard.StateId = this.StateId;
    this.parkingYard.DistrictId = this.DistrictId;
    this.parkingYard.TehsilId = this.Tehsil_Id;
    console.log("", this.parkingYard);
    this._MasterService
      .Save_Update_Delete_ParkingYard({
        JSON: JSON.stringify(
          this.dataSharingService.cleanObject(this.parkingYard)
        ),
        Type: this.BtnName == "Save" ? "Create" : "Update",
        LoginUserId: this.currentUser.userId,
        Id: this.Id,
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.getParkingYardList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  DeleteParkingYard() {
    let update = {};
  }
  deleteParkingDetails(val) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == 1) {
          this._MasterService
            .Save_Update_Delete_ParkingYard({
              JSON: "",
              Type: "Delete",
              LoginUserId: this.currentUser.userId,
              Id: val.Yard_Id,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.getParkingYardList();
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  editParkingYard(row) {
    console.log("asd", row);
    this.BtnName = "Update";
    this.parkValue = "Edit";
    this.Id = row.Yard_Id;
    this.parking_name = row.Parking_Name;
    this.parking_yard_add = row.Yard_Address;
    this.StateId = row.StateId;
    this.DistrictId = row.DistrictId;
    this.Tehsil_Id = row.TehsilId;

    this.onChangeState();
    this.onChangeDistrict();
    $("#parking-yard").modal("show");
    $("#parking-yard").css("z-index", "1050");
  }
}
