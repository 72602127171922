import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
@Component({
  selector: "app-account-master-detail",
  templateUrl: "./account-master-detail.component.html",
  styleUrls: ["./account-master-detail.component.scss"],
})
export class AccountMasterDetailComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loading: boolean = false;
  accountModel: any = { Account: {}, AccountDetail: {} };
  groupDropdown: any[] = [];
  slabList: any[] = [];
  taxSlabData: any[] = [];
  dataTaxSlabSource: any;
  displayedTaxSlabColumns: string[] = [
    "Id",
    "TaxSlab_Type",
    "TaxSlab_Rate",
    "Account_Name",
    "TaxType",
    "IsInterState",
  ];
  BranchesList: any[] = [];
  dataBranchSource: any;
  displayedBranchColumns: string[] = [
    "BranchId",
    "Branch_Name",
    "Branch_Code",
    "Branch_Type",
    "District_Name",
    "Tehsil_Name",
    "Branch_PhoneNo",
    "Branch_Head",
  ];
  stateDropdown: any[] = [];
  districkDropdown: any[] = [];
  tehasilDropdown: any[] = [];
  AccountId: any;
  groupControl = new UntypedFormControl();
  filteredGroupDropdown: Observable<any[]>;
  isGroupDisable: boolean = false;
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private lmsService: LmsService,
    private dialog: DialogService,
    private masterService: MasterService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.AccountId = decodeURIComponent(param.get("id"));
    });
  }
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Account Master");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.AccountId != "new") {
      this.Getacc_AccountDetail();
    } else {
      this.addNewAccount({});
    }
  }
  addNewAccount(data: any) {
    this.accountModel = {
      Account: {
        AccountId: data.AccountId || 0,
        Account_GroupId: data.Account_GroupId || "",
        Account_GroupTag: data.Account_GroupTag || "",
        Account_Name: data.Account_Name || "",
        Account_AliasName: data.Account_AliasName || "",
        Account_OpenDate: data.Account_OpenDate
          ? new Date(data.Account_OpenDate)
          : new Date(),
        Account_CloseDate: data.Account_CloseDate
          ? new Date(data.Account_CloseDate)
          : "",
        Account_OpeningBalance: data.Account_OpeningBalance || 0,
        Account_DebitCredit: data.Account_DebitCredit || "",
        Account_IsLoanChargeable: data.Account_IsLoanChargeable || false,
        Account_IsGSTEnable: data.Account_IsGSTEnable || false,
        Opening_Enable: data.Opening_Enable || false,
        Interest_Enable: data.Interest_Enable || false,
        Interest: data.Interest || 0,
        Account_BranchIds: data.Account_BranchIds || "",
        LoginUserId: this.currentUser.userId,
        Account_TaxSlab: "",
        Account_IsNACH_Enable: data.Account_IsNACH_Enable || false,
        Account_ACH_Code: data.Account_ACH_Code || "",
        Account_NACH_Code: data.Account_NACH_Code,
        IsEditLoanChargeable:
          data.IsEditLoanChargeable == undefined
            ? true
            : data.IsEditLoanChargeable,
      },
    };
    console.log("accountModel", this.accountModel.IsEditLoanChargeable);
    this.Get_Acc_GroupDropdown();
    if (data.Account_IsGSTEnable) {
      this.Get_TaxSlab_Dropdown();
    }
    if (data.Account_Tax) {
      let _tax = JSON.parse(data.Account_Tax);
      this.accountModel.Account.Account_TaxSlab = _tax[0].TaxSlab;
      this.Get_TaxSlab_Details(_tax);
    }
    this.getBranchesList(data.Account_BranchIds || "");
    if (data.AccountId > 0) {
      let _accountDetail = JSON.parse(data.Account_Detail) || [];
      switch (this.accountModel.Account.Account_GroupTag) {
        case "Loan":
          this.Loan(_accountDetail[0] || {});
          break;
        case "D&T":
          this.Tax(_accountDetail[0] || {});
          break;
        case "Bank":
          this.Bank(_accountDetail[0] || {});
          break;
        case "Cash":
          this.Cash(_accountDetail[0] || {});
          break;
        case "Asset":
          this.FillAsset(_accountDetail || []);
          break;
        default:
          this.Sundry(_accountDetail[0] || {});
      }
    }
  }
  Getacc_AccountDetail() {
    this.showSpinner = true;
    this.lmsService
      .Getacc_AccountDetail({ AccountIdentity: this.AccountId })
      .subscribe((res: any) => {
        const accountDetails = res[0];

        this.showSpinner = false;
        this.isGroupDisable = accountDetails.IsGroupDisable;
        this.addNewAccount(res[0]);
      });
  }
  _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.groupDropdown.filter(
      (option) => option.Group_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  onGroupSelect(event: any) {
    this.accountModel.Account.Account_GroupId = event.option.value.GroupId;
    this.setGroupTag();
  }
  setGroupTag() {
    let _find = this.groupDropdown.find((obj: any) => {
      return obj.GroupId == +this.accountModel.Account.Account_GroupId;
    });
    if (_find) {
      this.accountModel.Account.Account_GroupTag = _find.Group_Tag;
    } else {
      this.accountModel.Account.Account_GroupTag = "";
    }
    switch (this.accountModel.Account.Account_GroupTag) {
      case "Loan":
        this.Loan({});
        break;
      case "D&T":
        this.Tax({});
        break;
      case "Bank":
        this.Bank({});
        break;
      case "Cash":
        this.Cash({});
        break;
      case "Asset":
        this.Asset({});
        break;
      default:
        this.Sundry({});
    }
  }
  handleIsGSTEnableChange() {
    if (this.accountModel.Account.Account_IsGSTEnable) {
      if (this.slabList.length == 0) {
        this.Get_TaxSlab_Dropdown();
      }
    }
  }
  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = this.getDistinctObjects(res, "TaxSlab_Name");
      console.log("this.slabList", this.slabList);
    });
  }
  getDistinctObjects(array: any[], property: string): any[] {
    return array.filter(
      (obj, index, self) =>
        index === self.findIndex((item) => item[property] === obj[property])
    );
  }
  Get_TaxSlab_Details(_tax: any[] = []) {
    if (this.accountModel.Account.Account_TaxSlab != "") {
      this.lmsService
        .Get_TaxSlab_Details({
          TaxSlab: this.accountModel.Account.Account_TaxSlab,
        })
        .subscribe((res: any) => {
          this.taxSlabData = res;
          if (_tax.length > 0) {
            this.taxSlabData.forEach((obj: any) => {
              let _find = _tax.find((telm: any) => {
                return telm.TaxType == obj.TaxSlab_Type;
              });
              if (_find) {
                obj.IsChecked = true;
              }
            });
          }
          this.dataTaxSlabSource = new MatTableDataSource(this.taxSlabData);
        });
    } else {
      this.taxSlabData = [];
      this.dataTaxSlabSource = new MatTableDataSource(this.taxSlabData);
    }
  }
  onSaveAccount() {
    let _accountTax: any[] = [];
    let _branches: any[] = [];
    this.BranchesList.forEach((obj: any) => {
      if (obj.IsChecked) {
        _branches.push(obj.BranchId);
      }
    });
    this.taxSlabData.forEach((obj: any) => {
      console.log(obj.IsInterState);
      if (obj.IsChecked) {
        _accountTax.push({
          TaxSlab: obj.TaxSlab_Name,
          TaxType: obj.TaxSlab_Type,
          TaxRate: obj.TaxSlab_Rate,
          TaxAccountHead: obj.AccountId,
          TaxType1: obj.TaxType,
          IsInterState: obj.IsInterState,
        });
      }
    });
    if (!this.accountModel.Account.Account_GroupId) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select Group.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.accountModel.Account.Account_IsGSTEnable == 1 &&
      _accountTax.length == 0
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select Account Tax Slab.",
        ...this.configSuccess,
      });
      return;
    }

    let _data: any = {
      Account: this.dataSharingService.cleanObject({
        ...this.accountModel.Account,
      }),
    };
    _data.Account.Account_IsLoanChargeable = _data.Account
      .Account_IsLoanChargeable
      ? 1
      : 0;
    _data.Account.Account_IsGSTEnable = _data.Account.Account_IsGSTEnable
      ? 1
      : 0;
    if (this.accountModel.Account.AccountId > 0)
      _data.Account.Opening_Enable = _data.Account.Opening_Enable ? 1 : 0;
    _data.Account.Interest_Enable = _data.Account.Interest_Enable ? 1 : 0;

    if (_data.Account.Interest_Enable == 0) _data.Account.Interest = 0;
    _data.Account.Account_BranchIds = _branches.join(",");
    if (_accountTax.length > 0) {
      _data.Account_Tax = _accountTax;
    }
    if (this.accountModel.Type == "Sundry") {
      _data.AccountDetail_Sundary = this.dataSharingService.cleanObject({
        ...this.accountModel.AccountDetail,
      });
      _data.AccountDetail_Sundary.GSTApplicable = _data.AccountDetail_Sundary
        .GSTApplicable
        ? 1
        : 0;
      _data.AccountDetail_Sundary.TDSApplicable = _data.AccountDetail_Sundary
        .TDSApplicable
        ? 1
        : 0;
    }
    if (this.accountModel.Type == "Loan") {
      _data.AccountDetail_Loan = this.dataSharingService.cleanObject({
        ...this.accountModel.AccountDetail,
      });
      _data.AccountDetail_Loan.GSTApplicable = _data.AccountDetail_Loan
        .GSTApplicable
        ? 1
        : 0;
      _data.AccountDetail_Loan.TDSApplicable = _data.AccountDetail_Loan
        .TDSApplicable
        ? 1
        : 0;
    }
    if (this.accountModel.Type == "Tax") {
      _data.AccountDetail_Tax = this.dataSharingService.cleanObject({
        ...this.accountModel.AccountDetail,
      });
    }
    if (this.accountModel.Type == "Bank") {
      _data.AccountDetail_Bank = this.dataSharingService.cleanObject({
        ...this.accountModel.AccountDetail,
      });
      _data.AccountDetail_Bank.Bank_Disbursment_Payment_Allowed = _data
        .AccountDetail_Bank.Bank_Disbursment_Payment_Allowed
        ? 1
        : 0;
      _data.AccountDetail_Bank.Bank_Cash_Deposit_Allowed = _data
        .AccountDetail_Bank.Bank_Cash_Deposit_Allowed
        ? 1
        : 0;
      _data.AccountDetail_Bank.Bank_Other_Payment_Allowed = _data
        .AccountDetail_Bank.Bank_Other_Payment_Allowed
        ? 1
        : 0;
    }
    if (this.accountModel.Type == "Cash") {
      _data.AccountDetail_Cash = this.dataSharingService.cleanObject({
        ...this.accountModel.AccountDetail,
      });
      _data.AccountDetail_Cash.Cash_Disbursment_Payment_Allowed = _data
        .AccountDetail_Cash.Cash_Disbursment_Payment_Allowed
        ? 1
        : 0;
      _data.AccountDetail_Cash.Cash_Other_Payment_Allowed = _data
        .AccountDetail_Cash.Cash_Other_Payment_Allowed
        ? 1
        : 0;
    }
    if (this.accountModel.Type == "Asset") {
      let _AccountDetail: any[] = [];
      this.accountModel.AccountDetail.forEach((element: any) => {
        _AccountDetail.push(
          this.dataSharingService.cleanObject({ ...element })
        );
      });
      _data.AccountDetail_Asset = { ..._AccountDetail };
    }
    this.showSpinner = true;
    this.lmsService
      .Save_AccAccountMasters({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.router.navigate(["/lms-accounting/accounting-master"]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  goToback() {
    this.router.navigate(["/lms-accounting/accounting-master"]);
  }
  getBranchesList(branches: any) {
    this.masterService.GetBranches({}).subscribe((result: any) => {
      this.BranchesList = result;
      if (branches) {
        let _branch: any[] = branches.split(",");
        this.BranchesList.forEach((obj: any) => {
          if (_branch.indexOf(obj.BranchId + "") > -1) {
            obj.IsChecked = true;
          }
        });
      }
      this.dataBranchSource = new MatTableDataSource(this.BranchesList);
    });
  }
  GetStateDropdown() {
    this.masterService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }
  GetDistrickDropdown(isChange: boolean = true) {
    if (isChange) {
      this.accountModel.AccountDetail.District = "";
      this.accountModel.AccountDetail.Teshil = "";
    }
    this.masterService
      .GetDistrickDropdown({ StateID: this.accountModel.AccountDetail.State })
      .subscribe((res: any) => {
        this.districkDropdown = res;
      });

    if (this.accountModel.AccountDetail.GSTApplicable == true) {
      if (
        this.accountModel.AccountDetail.State == "" ||
        this.accountModel.AccountDetail.State == undefined ||
        this.accountModel.AccountDetail.State == null
      ) {
        this.accountModel.AccountDetail.GSTApplicable = false;
        const checkbox = <HTMLInputElement>(
          document.getElementById("GSTApplicable")
        );

        if (checkbox != null) {
          checkbox.checked = false;
        }

        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please  select state !!!",
          ...this.configSuccess,
        });
      }
    }
  }
  GetTehasilDropdown(isChange: boolean = true) {
    if (isChange) {
      this.accountModel.AccountDetail.Teshil = "";
    }
    this.masterService
      .GetTehasilDropdown({
        DistrictId: this.accountModel.AccountDetail.District,
      })
      .subscribe((res: any) => {
        this.tehasilDropdown = res;
      });
  }
  Sundry(data: any) {
    this.accountModel.Type = "Sundry";
    this.accountModel.AccountDetail = {
      AcountId: data.AcountId || 0,
      Contact_Person_Name: data.Contact_Person_Name || "",
      MobileNo: data.MobileNo || "",
      Email: data.Email || "",
      Address: data.Address || "",
      State: data.State || "",
      Teshil: data.Teshil || "",
      District: data.District || "",
      PinCode: data.PinCode || "",
      PanNo: data.PanNo || "",
      GSTApplicable: data.GSTApplicable || false,
      GSTNo: data.GSTNo || "",
      TDSApplicable: data.TDSApplicable || false,
      RegNo: data.RegNo || "",
      Bank_AccountName: data.Bank_AccountName || "",
      Bank_Name: data.Bank_Name || "",
      Bank_IFSC: data.Bank_IFSC || "",
      Bank_Branch: data.Bank_Branch || "",
    };
    this.GetStateDropdown();
    if (data.State) {
      this.GetDistrickDropdown(false);
    }
    if (data.District) {
      this.GetTehasilDropdown(false);
    }
  }
  Loan(data: any) {
    this.accountModel.Type = "Loan";
    this.accountModel.AccountDetail = {
      AcountId: data.AcountId || 0,
      Contact_Person_Name: data.Contact_Person_Name || "",
      MobileNo: data.MobileNo || "",
      Email: data.Email || "",
      Address: data.Address || "",
      State: data.State || "",
      Teshil: data.Teshil || "",
      District: data.District || "",
      PinCode: data.PinCode || "",
      PanNo: data.PanNo || "",
      GSTApplicable: data.GSTApplicable || false,
      GSTNo: data.GSTNo || "",
      TDSApplicable: data.TDSApplicable || false,
      RegNo: data.RegNo || "",
      Bank_AccountName: data.Bank_AccountName || "",
      Bank_Name: data.Bank_Name || "",
      Bank_IFSC: data.Bank_IFSC || "",
      Bank_Branch: data.Bank_Branch || "",
      LoanType: data.LoanType || "",
      LoanNo: data.LoanNo || "",
      LoanAmount: data.LoanAmount || "",
      LoanROI: data.LoanROI || "",
      NoOfInstallment: data.NoOfInstallment || "",
      FirstEMIDate: data.FirstEMIDate ? new Date(data.FirstEMIDate) : "",
      ExpiryDate: data.ExpiryDate ? new Date(data.ExpiryDate) : "",
      Attachment: data.Attachment || "",
      CollateralDetail: data.CollateralDetail || "",
    };
    this.GetStateDropdown();
    if (data.State) {
      this.GetDistrickDropdown(false);
    }
    if (data.District) {
      this.GetTehasilDropdown(false);
    }
  }
  Tax(data: any) {
    this.accountModel.Type = "Tax";
    this.accountModel.AccountDetail = {
      AcountId: data.AcountId || 0,
      TaxType: data.TaxType || "",
      TaxParcentage: data.TaxParcentage || "",
    };
  }
  Bank(data: any) {
    this.accountModel.Type = "Bank";
    this.accountModel.AccountDetail = {
      AcountId: data.AcountId || 0,
      Bank_Name: data.Bank_Name || "",
      Bank_IFSC: data.Bank_IFSC || "",
      Bank_Branch: data.Bank_Branch || "",
      Branch_Address: data.Branch_Address || "",
      Bank_AccountNo: data.Bank_AccountNo || "",
      Bank_AccountType: data.Bank_AccountType || "",
      Bank_PhoneNo: data.Bank_PhoneNo || "",
      Bank_Disbursment_Payment_Allowed:
        data.Bank_Disbursment_Payment_Allowed || false,
      Bank_Disbursment_Payment_Limit: data.Bank_Disbursment_Payment_Limit || "",
      Bank_Cash_Deposit_Allowed: data.Bank_Cash_Deposit_Allowed || false,
      Bank_Cash_Withdrawal_Allowed: data.Bank_Cash_Withdrawal_Allowed || false,
      Bank_Other_Payment_Allowed: data.Bank_Other_Payment_Allowed || false,
      Bank_Other_Payment_Limit: data.Bank_Other_Payment_Limit || "",
    };
  }
  Cash(data: any) {
    this.accountModel.Type = "Cash";
    this.accountModel.AccountDetail = {
      AcountId: data.AcountId || 0,
      Cash_Disbursment_Payment_Allowed:
        data.Cash_Disbursment_Payment_Allowed || false,
      Cash_Disbursment_Payment_Limit: data.Cash_Disbursment_Payment_Limit || "",
      Cash_Other_Payment_Allowed: data.Cash_Other_Payment_Allowed || false,
      Cash_Other_Payment_Limit: data.Cash_Other_Payment_Limit || "",
      Cash_Cash_Received_Limit: data.Cash_Cash_Received_Limit || "",
    };
  }
  Asset(data: any) {
    this.accountModel.Type = "Asset";
    this.accountModel.AccountDetail = [
      {
        AcountId: data.AcountId || 0,
        Assets_Name: data.Assets_Name || "",
        Assets_Description: data.Assets_Description || "",
        Assets_SerialNo: data.Assets_SerialNo || "",
        Invoice_No: data.Invoice_No || "",
        Invoice_Date: data.Invoice_Date ? new Date(data.Invoice_Date) : "",
        Invoice_Amount: data.Invoice_Amount || "",
        Depreciation_Rate_IT_Act: data.Depreciation_Rate_IT_Act || "",
        Depreciation_Rate_Co_Act: data.Depreciation_Rate_Co_Act || "",
      },
    ];
  }
  addMoreAsset(data: any) {
    this.accountModel.AccountDetail.push({
      AcountId: data.AcountId || 0,
      Assets_Name: data.Assets_Name || "",
      Assets_Description: data.Assets_Description || "",
      Assets_SerialNo: data.Assets_SerialNo || "",
      Invoice_No: data.Invoice_No || "",
      Invoice_Date: data.Invoice_Date ? new Date(data.Invoice_Date) : "",
      Invoice_Amount: data.Invoice_Amount || "",
      Depreciation_Rate_IT_Act: data.Depreciation_Rate_IT_Act || "",
      Depreciation_Rate_Co_Act: data.Depreciation_Rate_Co_Act || "",
    });
  }
  FillAsset(data: any[]) {
    this.accountModel.Type = "Asset";
    this.accountModel.AccountDetail = [];
    data.forEach((obj: any) => {
      this.addMoreAsset(obj);
    });
  }
  removeAsset(index: any) {
    this.accountModel.AccountDetail.splice(index, 1);
  }
  onChangeCheckbox(checkbox: any, modalvalue: any) {
    if (
      this.accountModel.AccountDetail.hasOwnProperty(checkbox) &&
      !this.accountModel.AccountDetail[checkbox]
    ) {
      this.accountModel.AccountDetail[modalvalue] = "";
    }
    if (
      this.accountModel.Account.hasOwnProperty(checkbox) &&
      !this.accountModel.Account[checkbox]
    ) {
      this.accountModel.Account[modalvalue] = "";
    }
    if (this.accountModel.AccountDetail.GSTApplicable == true) {
      if (
        this.accountModel.AccountDetail.State == "" ||
        this.accountModel.AccountDetail.State == undefined ||
        this.accountModel.AccountDetail.State == null
      ) {
        this.accountModel.AccountDetail.GSTApplicable = false;
        const checkboxdata = <HTMLInputElement>(
          document.getElementById("GSTApplicable")
        );

        if (checkboxdata != null) {
          checkboxdata.checked = false;
        }
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please  select state !!!",
          ...this.configSuccess,
        });
      }
    }
  }
  displayFn(data: any): string {
    return data && data.Group_Name ? data.Group_Name : "";
  }
}
