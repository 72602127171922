
<div class="row m-0 mt-2">
  <div class="col-md-12 p-1"><div class=" bg-success1">Assets Info:</div></div>
</div>
<form #f="ngForm" novalidate *ngIf="AssetsForm=='Form'" class="formborder">
  <ng-container *ngIf="Collateral=='Vehicle'">
    <div class="row m-0 mt-3" *ngIf="Info">
      <div class="col-md-12"><h1 class="fs-12 h-b">{{ Info.Vehicle_SubType }}</h1></div>
      <div class="col-md-2">
        <h6 class="fs-12">Vehicle Manufacture</h6> <p class="lead fs-12">{{ Info.VehicleManufacture }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Vehicle Category</h6> <p class="lead fs-12">{{ Info.VehicleCategory }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Vehicle Model Name</h6> <p class="lead fs-12">{{ Info.VehicleModelName }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Variant</h6> <p class="lead fs-12">{{ Info.Variant }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Manufacture Year</h6> <p class="lead fs-12">{{ Info.MefgYear }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Vehicle Registation No</h6> <p class="lead fs-12">{{ Info.VehicleRegistationNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Registation Date</h6> <p class="lead fs-12">{{ Info.RegistationDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Registation Expiry Date</h6> <p class="lead fs-12">{{ Info.RegistationExpiryDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Fuel Type</h6> <p class="lead fs-12">{{ Info.FuelType }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
        <h6 class="fs-12">Road Tax Upto</h6> <p class="lead fs-12">{{ Info.RoadTaxUpto }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Color</h6> <p class="lead fs-12">{{ Info.Color }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
        <h6 class="fs-12">Fitness Upto</h6> <p class="lead fs-12">{{ Info.FitnessUpto }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
        <h6 class="fs-12">Permit Upto</h6> <p class="lead fs-12">{{ Info.PermitUpto }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Vehicle Cost</h6> <p class="lead fs-12">{{ Info.VehicleCost }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info?.Vehicle_SubType=='Commercial'">
        <h6 class="fs-12">Route</h6> <p class="lead fs-12">{{ Info.Rout }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Engine No</h6> <p class="lead fs-12">{{ Info.EngineNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Chassis No</h6> <p class="lead fs-12">{{ Info.ChassisNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Key No</h6> <p class="lead fs-12">{{ Info.KeyNo }}</p>
      </div>
      <!--<div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
        <h6 class="fs-12">Legal Report Done By</h6> <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
        <h6 class="fs-12">Legal Report Remark</h6> <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
        <h6 class="fs-12">Legal Report Done On</h6> <p class="lead fs-12">{{Info.SearchReportDoneOn }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Legal Report Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.SearchReportRemarkDoc}}" target="_blank"
             *ngIf="Info.SearchReportRemarkDoc">{{Info.SearchReportRemarkDoc}}</a>
        </p>
      </div>-->
    </div>
    <div class="row m-0 mt-3" *ngIf="PendingValuation.length>0 && PendingValuation[0].Id!=''&& PendingValuation[0].Id!=0">

      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{ PendingValuation[0].Process_VendorName }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Date</h6> <p class="lead fs-12">{{ PendingValuation[0].ProcessDone_on }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{PendingValuation[0].Process_Remark }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc1</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment1}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment1">{{PendingValuation[0].Attachment1}}</a>
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc2</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment2}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment2">{{PendingValuation[0].Attachment2}}</a>
        </p>
      </div>
    </div>

    <div class="row m-0 mt-3" *ngIf="Info?.Vehicle_Type=='New'">
      <div class="col-md-2">
        <h6 class="fs-12">DO. No.</h6> <p class="lead fs-12">{{ Info.DONo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">DO. Do Date</h6> <p class="lead fs-12">{{ Info.DODoDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Estimation Amount</h6> <p class="lead fs-12">{{ Info.EstimationAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Invoice No.</h6> <p class="lead fs-12">{{ Info.InvoiceNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Invoice Date</h6> <p class="lead fs-12">{{ Info.InvoiceDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Invoice Value</h6> <p class="lead fs-12">{{ Info.InvoiceValue }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Dealer Contact Person</h6> <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Dealer Contact No.</h6> <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Quotation in favour of</h6> <p class="lead fs-12">{{ Info.DOIssuedinfavourof }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Remark</h6> <p class="lead fs-12">{{ Info.Remark }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Dealer</h6> <p class="lead fs-12">{{ Info.DealerName }}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 mt-2  upload" *ngIf="AssetsAttachment.length>0">
        <b> Uploaded Vehicle Images </b>

        <div class="row m-0 mt-2 align-items-center"
             *ngFor="let report of AssetsAttachment; let i = index">
          <div class="col-md-1 pl-0">
            {{ i + 1 }}
          </div>
          <div class="col-md-3">
            <p class="lead fs-12">{{report.Title }}</p>

          </div>
          <div class="col-md-3">
            <p class="m-0 row">
              <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{report.DocFileName}}" target="_blank"
                 *ngIf="report.DocFileName">{{report.DocFileName}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mb-2" style="margin-right: 50px;" *ngIf="!isUpdateValuation">
      <button type="button" (click)="editAsset()" *ngIf="PageData.currentTab == 1"
              class="btn font-size-12 button-btn" [class.spinner]="loading">
        Edit
      </button>
    </div>
   
  </ng-container>
  <ng-container *ngIf="Collateral=='Gold'">
    <div class="table-responsive mt-4 mb-4">
      <mat-table [dataSource]="dataviewAssetItemSource">
        <ng-container matColumnDef="Id">
          <mat-header-cell class="grid-header" style="max-width: 50px" *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell" style="max-width: 50px; max-height: 5px">
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ItemName">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Item Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ItemName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ItemType">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Item Type</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ItemType }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Qty">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Qty</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Qty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PurityCT">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Purity CT</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.PurityCT }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GrossWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Gross Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.GrossWt}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="StoneWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Stone Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.StoneWt}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OtherWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Other Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.OtherWt}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NetWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Net Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.NetWt}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PureWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Pure Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{(row.NetWt * (row.PurityCT*fixrate24ct)/100).toFixed(2)}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValulationRate_PerCT">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Rate</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.ValulationRate_PerCT}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValulationAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.ValulationAmount}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Image">
          <mat-header-cell class="grid-header" *matHeaderCellDef>View</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{row.Image1}}" target="_blank" *ngIf="row.Image1">
              <i *ngIf="row.Image1" class="fa fa-eye" style="font-size: large"></i>
            </a>
            <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{row.Image2}}" class="ml-2" target="_blank" *ngIf="row.Image2">
              <i *ngIf="row.Image2" class="fa fa-eye" style="font-size: large"></i>
            </a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedViewAssetItemColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedViewAssetItemColumns"></mat-row>

      </mat-table>
    </div>

    <!--<div class="row m-0 mt-3" *ngIf="Info">
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
      <h6 class="fs-12">Legal Report Done By</h6> <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
    </div>
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
      <h6 class="fs-12">Legal Report Remark</h6> <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
    </div>
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
      <h6 class="fs-12">Legal Report Done On</h6> <p class="lead fs-12">{{Info.SearchReportDoneOn }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Legal Report Doc</h6>
      <p class="lead fs-12">
        <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.SearchReportRemarkDoc}}" target="_blank"
           *ngIf="Info.SearchReportRemarkDoc">{{Info.SearchReportRemarkDoc}}</a>
      </p>
    </div>-->
    <!--<div class="col-md-3">
      <h6 class="fs-12">Valution Done By</h6> <p class="lead fs-12">{{ Info.ValulationDoneBy }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Valution Date</h6> <p class="lead fs-12">{{ Info.ValulationDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Total Valution Amount</h6> <p class="lead fs-12">{{ Info.TotalValulationAmount }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Valuer PhoneNo</h6> <p class="lead fs-12">{{ Info.ValuerPhoneNo }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Valuer FirmName</h6> <p class="lead fs-12">{{ Info.ValuerFirmName }}</p>
    </div>-->
    <!--</div>-->
    <div class="row m-0 mt-3" *ngIf="PendingValuation.length>0 && PendingValuation[0].Id!=''&& PendingValuation[0].Id!=0">

      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{ PendingValuation[0].Process_VendorName }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Date</h6> <p class="lead fs-12">{{ PendingValuation[0].ProcessDone_on }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{PendingValuation[0].Process_Remark }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment1}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment1">{{PendingValuation[0].Attachment1}}</a>
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc2</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment2}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment2">{{PendingValuation[0].Attachment2}}</a>
        </p>
      </div>
    </div>
    <!--<div class="row m-0 mt-3" *ngIf="Info.ValuationDoneBy">
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{ Info.PValuationDoneBy }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Date</h6> <p class="lead fs-12">{{ Info.PValuationDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{ Info.PValuationAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Type</h6> <p class="lead fs-12">{{ Info.PValuationType }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Type</h6> <p class="lead fs-12">{{ Info.POtherValuationType }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Amount</h6> <p class="lead fs-12">{{ Info.POtherValuationAmt }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{Info.PValuationRemark }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.PValuationDoc}}" target="_blank"
             *ngIf="Info.PValuationDoc">{{Info.PValuationDoc}}</a>
        </p>
      </div>
    </div>-->
    <div class="text-right mb-2" style="margin-right: 50px;" *ngIf="!isUpdateValuation">
      <button type="button" (click)="editAsset()"  *ngIf="PageData.currentTab == 1"
              class="btn font-size-12 button-btn" [class.spinner]="loading">
        Edit
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="Collateral=='Property'">
    <div class="row m-0 mt-3" *ngIf="Info">
      <div class="col-md-2">
        <h6 class="fs-12">Property Owner Name</h6> <p class="lead fs-12">{{ Info.CustomerId }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Address Of Property</h6> <p class="lead fs-12">{{ Info.Address}}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">State</h6> <p class="lead fs-12">{{ Info.State }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">District</h6> <p class="lead fs-12">{{ Info.District }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Tehsil</h6> <p class="lead fs-12">{{ Info.tasil }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Type of Property</h6> <p class="lead fs-12">{{ Info.TypeofProperty }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Nature of Property</h6> <p class="lead fs-12">{{ Info.NatureofProperty }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Ownership Type</h6> <p class="lead fs-12">{{ Info.OwnershipType }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Ownership Document</h6> <p class="lead fs-12">{{ Info.OwnershipDocument }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">UnitofMeasurement</h6> <p class="lead fs-12">{{ Info.UnitofMeasurement }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Total Area</h6> <p class="lead fs-12">{{ Info.TotalArea }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Constructed Area</h6> <p class="lead fs-12">{{Info.ConstructedArea }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Type of Mortgage</h6> <p class="lead fs-12">{{Info.TypeofMortgage }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Mortgage Singed By</h6> <p class="lead fs-12">{{Info.MortgageSingedBy }}</p>
      </div>
      <!--<div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{Info.ValuationAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{Info.ValuationDoneBy }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{Info.ValuationRemark }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.ValuationDoneByID!=''&&Info.ValuationDoneByID==0">
        <h6 class="fs-12">Valution Done By Name</h6> <p class="lead fs-12">{{Info.ValuationDoneByOther }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Search Report Done By</h6> <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Search Report Remark</h6> <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
        <h6 class="fs-12">Search Report Done By Name</h6> <p class="lead fs-12">{{Info.SearchReportDoneByOther }}</p>
      </div>-->
      <!--<div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
        <h6 class="fs-12">Legal Report Done By</h6> <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
        <h6 class="fs-12">Legal Report Remark</h6> <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
      </div>
      <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
        <h6 class="fs-12">Legal Report Done On</h6> <p class="lead fs-12">{{Info.SearchReportDoneOn }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Legal Report Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.SearchReportRemarkDoc}}" target="_blank"
             *ngIf="Info.SearchReportRemarkDoc">{{Info.SearchReportRemarkDoc}}</a>
        </p>
      </div>-->

      <div class="col-md-2">
        <h6 class="fs-12">GeoLocation</h6> <p class="lead fs-12">
  <a target="_blank" *ngIf=" Info.GeoLocation!='' &&  Info.GeoLocation!=null &&  Info.GeoLocation!=','" [href]="'http://maps.google.com/maps?q=' +  Info.GeoLocation">
    <i class="fa-solid fa-location-dot"></i>
  </a>
</p>
      </div>
    </div>
    <!--<div class="row m-0 mt-3" *ngIf="Info">
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
      <h6 class="fs-12">Legal Report Done By</h6> <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
    </div>
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID">
      <h6 class="fs-12">Legal Report Remark</h6> <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
    </div>
    <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
      <h6 class="fs-12">Legal Report Done On</h6> <p class="lead fs-12">{{Info.SearchReportDoneOn }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Legal Report Doc</h6>
      <p class="lead fs-12">
        <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.SearchReportRemarkDoc}}" target="_blank"
           *ngIf="Info.SearchReportRemarkDoc">{{Info.SearchReportRemarkDoc}}</a>
      </p>
    </div>
    </div>-->
    <!--<div class="row m-0 mt-3" *ngIf="Info.ValuationDoneBy">
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{ Info.PValuationDoneBy }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Date</h6> <p class="lead fs-12">{{ Info.PValuationDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{ Info.PValuationAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Type</h6> <p class="lead fs-12">{{ Info.PValuationType }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Type</h6> <p class="lead fs-12">{{ Info.POtherValuationType }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Amount</h6> <p class="lead fs-12">{{ Info.POtherValuationAmt }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{Info.PValuationRemark }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{Info.PValuationDoc}}" target="_blank"
             *ngIf="Info.PValuationDoc">{{Info.PValuationDoc}}</a>
        </p>
      </div>
    </div>-->
    <div class="row m-0 mt-3" *ngIf="PendingValuation.length>0 && PendingValuation[0].Id!=''&& PendingValuation[0].Id!=0">

      <div class="col-md-2">
        <h6 class="fs-12">Valuation Done By</h6> <p class="lead fs-12">{{ PendingValuation[0].Process_VendorName }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Date</h6> <p class="lead fs-12">{{ PendingValuation[0].ProcessDone_on }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Type</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Type2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Other Valuation Amount</h6> <p class="lead fs-12">{{ PendingValuation[0].Valuation_Amount2 }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Valuation Remark</h6> <p class="lead fs-12">{{PendingValuation[0].Process_Remark }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment1}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment1">{{PendingValuation[0].Attachment1}}</a>
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Valuation Doc2</h6>
        <p class="lead fs-12">
          <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{PendingValuation[0].Attachment2}}" target="_blank"
             *ngIf="PendingValuation[0].Attachment2">{{PendingValuation[0].Attachment2}}</a>
        </p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 mt-2  upload" *ngIf="AssetsAttachment.length>0">
        <b> Uploaded Property Images </b>

        <div class="row m-0 mt-2 align-items-center"
             *ngFor="let report of AssetsAttachment; let i = index">
          <div class="col-md-1 pl-0">
            {{ i + 1 }}
          </div>
          <div class="col-md-3">
            <p class="lead fs-12">{{report.Title }}</p>

          </div>
          <div class="col-md-3">
            <p class="m-0 row">
              <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{report.DocFileName}}" target="_blank"
                 *ngIf="report.DocFileName">{{report.DocFileName}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mb-2" style="margin-right: 50px;" *ngIf="!isUpdateValuation">
      <button type="button" (click)="editAsset()"  *ngIf="PageData.currentTab == 1"
              class="btn font-size-12 button-btn" [class.spinner]="loading">
        Edit
      </button>
    </div>
  </ng-container>
  <ng-container>
    <div class="table-responsive mt-4 mb-4" *ngIf="dataValuationSource">
      <mat-table [dataSource]="dataValuationSource">
        <ng-container matColumnDef="Id">
          <mat-header-cell class="grid-header" style="max-width: 50px" *matHeaderCellDef>
            #
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell" style="max-width: 50px; max-height: 5px">
            {{ i + 1 }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Process_VendorName">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Vendor Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Process_VendorName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Valuation_Type">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Valuation Type</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Valuation_Type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Valuation_Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Valuation Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Valuation_Amount }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Valuation_Type2">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Valuation Type2</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Valuation_Type2 }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Valuation_Amount2">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Valuation Amount2</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Valuation_Amount2 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ProcessDone_on">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Done on</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ProcessDone_on }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Attachment1">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Attachment1</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{row.Attachment1}}" target="_blank" *ngIf="row.Attachment1">{{row.Attachment1}}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Attachment2">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Attachment2</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a href="{{applicationDocUrl}}{{loanSummary.ApplicationNo}}/{{row.Attachment2}}" target="_blank" *ngIf="row.Attachment2">{{row.Attachment2}}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Process_Remark">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Remark</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.Process_Remark}}</mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedValuationColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedValuationColumns"></mat-row>
      </mat-table>

    </div>
  </ng-container>
  <ng-container *ngIf="isUpdateValuation && Processid==17">
    <div class="row m-0 mt-1">
      <div class="col-md-3 p-1">
        <span class="required-lable">Valuation Done By</span>
        <select name="ValuationDoneBy" id="ValuationDoneBy" #refValuationDoneBy="ngModel" [disabled]="IsDisabled" required
                [ngClass]="{ 'is-invalid': f.submitted && refValuationDoneBy.invalid, 'alert-warning': refValuationDoneBy.invalid && (refValuationDoneBy.dirty || refValuationDoneBy.touched || refValuationDoneBy.untouched) }"
                [(ngModel)]="ValuationDoneBy" class="form-control input-text-css">
          <option value="">Select Valuation Done By</option>
          <option *ngFor="let collection of CollectionExecutiveData" [value]="collection.PartnerId">{{collection.Partner_Name}}</option>

        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Valuation Date</span>
        <!--<input name="ValuationDate " id="ValuationDate " #refValuationDate="ngModel" [disabled]="IsDisabled" [minDate]="Application_Date" [maxDate]="CurrentDate"
         [ngClass]="{ 'is-invalid': f.submitted && refValuationDate.invalid, 'alert-warning': refValuationDate.invalid  && (refValuationDate.dirty || refValuationDate.touched || refValuationDate.untouched) }"
         [(ngModel)]="ValuationDate" value="{{ValuationDate | date:'dd/MM/yyyy' }}" class="form-control input-text-css"
         [(bsValue)]="ValuationDate" bsDatepicker required>-->
        <div class="datepicker_feild">
          <input [matDatepicker]="picker"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="ValuationDate"
                 id="ValuationDate"
                 #refValuationDate="ngModel"
                 [min]="Application_Date"
                 [max]="CurrentDate"
                 [disabled]="IsDisabled"
                 required
                 [ngClass]="{ 'is-invalid': f.submitted && refValuationDate.invalid, 'alert-warning': refValuationDate.invalid  && (refValuationDate.dirty || refValuationDate.touched || refValuationDate.untouched) }"
                 class="form-control input-text-css"
                 [(ngModel)]="ValuationDate" />
          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Valution Amount</span>
        <input required type="number" min="0" numbersOnly #refValuationAmount="ngModel" placeholder="Valution Amount" [disabled]="IsDisabled"
               [ngClass]="{ 'is-invalid': f.submitted && refValuationAmount.invalid, 'alert-warning': refValuationAmount.invalid && (refValuationAmount.dirty || refValuationAmount.touched || refValuationAmount.untouched) }"
               [(ngModel)]="ValutionAmount" name="ValuationAmount" id="ValuationAmount"
               class="form-control input-text-css">
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable">Valuation Type</span>
        <select name="ValuationType" id="ValuationType" #refValuationType="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && refValuationType.invalid, 'alert-warning': refValuationType.invalid && (refValuationType.dirty || refValuationType.touched || refValuationType.untouched) }"
                [disabled]="IsDisabled" required (change)="ChangeValuationType()"
                [(ngModel)]="ValuationType" class="form-control input-text-css">
          <option value="">Select Valuation Type</option>
          <option value="Market Value">Market Value</option>
          <option value="Actual Value">Actual Value</option>
          <option value="Religible Value">Realisable Value</option>
          <option value="Invoice Value">Invoice Value</option>
          <option value="Registry Value">Registry Value</option>

        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="">Other Valuation Type</span>
        <!--<input type="text" #refOtherValuationType="ngModel" [(ngModel)]="OtherValuationType" placeholder="Other Valuation Type"
        name="OtherValuationType" id="OtherValuationType" class="form-control input-text-css" [disabled]="IsDisabled">-->
        <select name="OtherValuationType" id="OtherValuationType" #refOtherValuationType="ngModel" [disabled]="IsDisabled" (change)="ChangeValuationType()"
                [(ngModel)]="OtherValuationType" class="form-control input-text-css">
          <option value="">Select Valuation Type</option>
          <option value="Market Value">Market Value</option>
          <option value="Actual Value">Actual Value</option>
          <option value="Religible Value">Realisable Value</option>
          <option value="Invoice Value">Invoice Value</option>
          <option value="Registry Value">Registry Value</option>

        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="">Other Valuation Amount</span>
        <input type="number" min="0" numbersOnly #refOtherValuationAmt="ngModel" [disabled]="IsDisabled" [(ngModel)]="OtherValuationAmount"
               name="OtherValuationAmt" id="OtherValuationAmt" class="form-control input-text-css" placeholder="Other Valuation Amount">
      </div>
      <div class="col-md-6 p-1">
        <p class="m-4 row">
          Upload Valuation Report:
          <i class="fa fa-upload ml-2" *ngIf="!ValuationRemarkDoc" (click)="docFile1.click()" aria-hidden="true"></i>
          <ng-container *ngIf="ValuationRemarkDoc">
            <div>
              <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
              <ng-container *ngIf="ValuationDocData">{{ValuationRemarkDoc}}</ng-container>
              <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{ValuationRemarkDoc}}" class="ml-3" target="_blank" *ngIf="!ValuationDocData">
                {{ ValuationRemarkDoc }}
              </a>
              <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer" (click)="removeFile1(docFile1)"></i>
            </div>
          </ng-container>
        </p>
        <input #docFile1 type="file" [multiple]="false" accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
               (change)="fileChangeListener(docFile1.files)" style="display: none" />
      </div>

      <div class="col-md-6 p-1">
        <p class="m-4 row">
          Upload Valuation Report:
          <i class="fa fa-upload ml-2" *ngIf="!ValuationRemarkDoc1" (click)="docFile2.click()" aria-hidden="true"></i>
          <ng-container *ngIf="ValuationRemarkDoc1">
            <div>
              <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
              <ng-container *ngIf="ValuationDocData1">{{ValuationRemarkDoc1}}</ng-container>
              <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{ValuationRemarkDoc1}}" class="ml-3" target="_blank" *ngIf="!ValuationDocData1">
                {{ ValuationRemarkDoc1 }}
              </a>
              <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer" (click)="removeFile2(docFile2)"></i>
            </div>
          </ng-container>
        </p>
        <input #docFile2 type="file" [multiple]="false" accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
               (change)="fileChangeListener1(docFile2.files)" style="display: none" />
      </div>
      <div class="col-md-9 p-1">
        <span class="required-lable">Remark</span>
        <textarea type="text" #refValuationRemark="ngModel" name="ValuationRemark" id="ValuationRemark" rows="1" required
                  [(ngModel)]="ValuationRemark" class="form-control input-text-css" placeholder="Remark" [disabled]="IsDisabled"
                  [ngClass]="{ 'is-invalid': f.submitted && refValuationRemark.invalid, 'alert-warning': refValuationRemark.invalid && (refValuationRemark.dirty || refValuationRemark.touched || refValuationRemark.untouched) }"></textarea>
      </div>
    </div>
    <div class="text-right mb-2" style="margin-right: 50px;" *ngIf="PageData.currentTab == 1">
      <button type="button" (click)="SaveValuationProcess()"
              class="btn font-size-12 button-btn" [class.spinner]="loading" [disabled]="Status == 'C' ||!f.form.valid">
        {{ButtonName}}
      </button>
    </div>
  </ng-container>
</form>
