<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div>
    <form #df="ngForm" class="formborder">
      <div class="row col-md-12">
        <div class="col-md-3">
          <label for="swapDate" class="required-lable">Due Date From</label>
          <div class="datepicker_feild">
            <input
              required
              [matDatepicker]="picker1"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="swapDate"
              id="swapDate"
              class="form-control input-text-css"
              [(ngModel)]="swapDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>
        </div>

        <div class="col-md-3">
          <label class="required-lable">Existing Bank</label>
          <select
            name="BankInCompany"
            id="BankInCompany"
            #refPresentBankInCompany="ngModel"
            [(ngModel)]="BankInCompany"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Present Bank In Company</option>
            <option *ngFor="let bank of BankList" [value]="bank.AccountId">
              {{ bank.Account_Name }}
            </option>
          </select>
        </div>

        <div class="col-md-1">
          <button
            [disabled]="df.form.invalid"
            type="button"
            (click)="onSearchNachforSwap()"
            class="mt-4 btn font-size-12 button-btn"
          >
            Search
          </button>
        </div>

        <div class="col-md-3 mt-3 ml-4">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-3" *ngIf="dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                name="Select"
                id="Select"
                [(ngModel)]="SelectAll"
                (change)="checkUncheckAll($event)"
                [checked]="IsSelected"
              ></mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <mat-checkbox
                class="example-margin"
                name="IsSelected{{ i }}"
                id="IsSelected{{ i }}"
                [(ngModel)]="row.IsSelected"
                [checked]="row.IsSelected"
              ></mat-checkbox>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerName
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Branch_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerBankname">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Bank name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerBankname
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerAccountNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Account No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerAccountNo
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalNachPending">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Total Nach Pending</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">{{
              row.TotalNachPending
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="NachCode">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Nach Code</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NachCode
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 pt-2 pb-2 align-items-center col-md-12">
          <div class="col-md-4">
            <label class="required-lable">Swap into bank</label>
            <select
              name="swapBankDropdown"
              id="swapBankDropdown"
              #refswapBankDropdown="ngModel"
              [(ngModel)]="swapBankDropdown"
              class="form-control input-text-css"
            >
              <option value="" selected>Select Present Bank In Company</option>
              <option *ngFor="let bank of BankList" [value]="bank.AccountId">
                {{ bank.Account_Name }}
              </option>
            </select>
          </div>

          <div class="col-md-4">
            <button
              type="button"
              (click)="onClickSwapBankNach()"
              class="mt-4 btn font-size-12 button-btn"
            >
              Swap Nach Bank
            </button>
          </div>
          <div class="col-md-4 text-right mt-3">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="swap_nach_source"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Nach Pending
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nach Code
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.CustomerBankname }}</td>
    <td>{{ x.CustomerAccountNo }}</td>
    <td>{{ x.TotalNachPending }}</td>
    <td>{{ x.NachCode }}</td>
  </tr>
</table>
