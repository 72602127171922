import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
declare var $: any;
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { constantUrl } from "../../Shared/constantUrl";
import { LosService } from "../../_LOS/services/los.service";

@Component({
  selector: "app-mst-branches",
  templateUrl: "./mst-branches.component.html",
  styleUrls: ["./mst-branches.component.scss"],
})
export class MstBranchesComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetBranchDetailRequestModel: RequestModel = new RequestModel();
  GetEmployeeDropdownRequestModel: RequestModel = new RequestModel();
  SaveBranchRequestModel: RequestModel = new RequestModel();
  DeleteBranchRequestModel: RequestModel = new RequestModel();
  GetDistrictRequestModel: RequestModel = new RequestModel();
  GetFilterDistrictRequestModel: RequestModel = new RequestModel();
  GetTehsilRequestModel: RequestModel = new RequestModel();

  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "BranchId",
    "Branch_Name",
    "Branch_Code",
    "Branch_Type",
    "District_Name",
    "Tehsil_Name",
    "Branch_PhoneNo",
    "Branch_Head",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  FilterZoneId: any = "";
  FilterDistrictId: any = "";
  FilterDistrictList: any = [];
  ZoneListDropdown: any = [];
  BranchesList: any = [];
  StateList: any = [];
  DistrictList: any = [];
  TehsilList: any = [];
  GetBranchDetail: any = {};
  GetEmployeeDropdown: any = [];

  BranchID: any = 0;
  BranchName: any = "";
  BranchType: any = "";
  SelectZoneId: any = "";
  StateId: any = "";
  DistrictId: any = "";
  TehsilId: any = "";
  Address: any = "";
  LandMark: any = "";
  Pincode: any = "";
  PhoneNo: any = "";
  BranchSetupDate: any = new Date();
  BranchHead: any = "";
  Remarks: any = "";
  BranchCode: any = "";
  GSTNo: any = "";
  EmailId: any = "";

  // google map
  lat = 26.92207;
  lng = 75.778885;
  animation: string = "DROP";
  // delete branch reason
  DeletionReason: string = "";
  dialogreturn: any;

  today: Date = new Date();
  BranchModel: any = {};
  UploadImageBase64: any = "";

  FilterStateId: any = "";
  stateDropdown: any[] = [];
  FilterInputSearch: any;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private losService: LosService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Branch List");
    this._MasterService.GetZoneListDropdown().subscribe((result) => {
      this.ZoneListDropdown = JSON.parse(JSON.stringify(result));
    });
    this.GetFilterDistrictRequestModel.StateId = 29;
    this._MasterService
      .GetDistricts(this.GetFilterDistrictRequestModel)
      .subscribe((result) => {
        this.FilterDistrictList = JSON.parse(JSON.stringify(result));
      });
    this.getBranchesList();

    this.BranchModel = {
      Branch_Is_Rented: "",
      Branch_ContractFrom: "",
      Branch_ContractTo: "",
      Branch_Landlord_Name: "",
      Branch_Landlord_PanNo: "",
      Branch_Monthly_Rent: "",
      Branch_Rent_Due_Date: "",
      Branch_PaymentInFavourOf: "",
      Branch_AgrrementDoc: "",
    };

    this.GetStateDropdown();
  }

  getBranchesList() {
    this.showSpinner = true;
    this.RequestModel.ZoneId =
      this.FilterZoneId == "" ? 0 : parseInt(this.FilterZoneId);
    this.RequestModel.DistrictId =
      this.FilterDistrictId == "" ? 0 : parseInt(this.FilterDistrictId);
    this._MasterService.GetBranches(this.RequestModel).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.BranchesList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    console.log("filterValue", filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // start google map
  mapClicked($event: any) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
  }

  markerOver() {
    this.animation = "BOUNCE";
  }

  markerOut() {
    this.animation = "";
  }
  //end google map

  OnOpenBranchModal(selectBranchId) {
    this._MasterService.GetState().subscribe((result) => {
      this.StateList = JSON.parse(JSON.stringify(result));
    });
    this.GetEmployeeDropdownRequestModel.BranchId = 0;
    this.GetEmployeeDropdownRequestModel.ProcessId = 0;
    this._MasterService
      .GetEmployeeDropdown(this.GetEmployeeDropdownRequestModel)
      .subscribe((result) => {
        this.GetEmployeeDropdown = JSON.parse(JSON.stringify(result));
      });

    this.BranchModel.Branch_Is_Rented = "";

    this.BranchID = selectBranchId;
    if (this.BranchID != 0) {
      this.GetBranchDetailRequestModel.BranchId = this.BranchID;
      this._MasterService
        .GetBrancheDetails(this.GetBranchDetailRequestModel)
        .subscribe((result) => {
          this.GetBranchDetail = result[0];
          console.log("this.GetBranchDetail", result);
          if (result.length > 0) {
            this.BranchName = this.GetBranchDetail.Branch_Name;
            this.BranchCode = this.GetBranchDetail.Branch_Code;
            this.BranchType = this.GetBranchDetail.Branch_Type;
            this.SelectZoneId = this.GetBranchDetail.Branch_ZoneId;
            this.StateId = this.GetBranchDetail.Branch_StateId;
            this.onChangeState();
            this.DistrictId = this.GetBranchDetail.Branch_DistrictId;
            this.onChangeDistrict();
            this.TehsilId = this.GetBranchDetail.Branch_TehsilId;
            this.Address = this.GetBranchDetail.Branch_Address;
            this.LandMark = this.GetBranchDetail.Branch_LandMark;
            this.Pincode = this.GetBranchDetail.Branch_PinCode;
            this.PhoneNo = this.GetBranchDetail.Branch_PhoneNo;
            this.BranchSetupDate = new Date(
              this.GetBranchDetail.Branch_SetupDate
            );
            this.BranchHead = this.GetBranchDetail.Branch_HeadId;
            this.Remarks = this.GetBranchDetail.Branch_Remark;
            this.lat = parseFloat(
              String(this.GetBranchDetail.Branch_LatLong).split(",")[0]
            );
            this.lng = parseFloat(
              String(this.GetBranchDetail.Branch_LatLong).split(",")[1]
            );
            this.GSTNo = this.GetBranchDetail.Branch_GSTNo;
            this.EmailId = this.GetBranchDetail.Branch_EmailId;
            this.BranchModel.Branch_Is_Rented =
              this.GetBranchDetail.Branch_Is_Rented;
            this.BranchModel.Branch_ContractFrom = new Date(
              this.GetBranchDetail.Branch_ContractFrom
            );
            this.BranchModel.Branch_ContractTo = new Date(
              this.GetBranchDetail.Branch_ContractTo
            );
            this.BranchModel.Branch_Landlord_Name =
              this.GetBranchDetail.Branch_Landlord_Name;
            this.BranchModel.Branch_Landlord_PanNo =
              this.GetBranchDetail.Branch_Landlord_PanNo;
            this.BranchModel.Branch_Monthly_Rent =
              this.GetBranchDetail.Branch_Monthly_Rent;
            this.BranchModel.Branch_Rent_Due_Date = new Date(
              this.GetBranchDetail.Branch_Rent_Due_Date
            );
            this.BranchModel.Branch_AgrrementDoc =
              this.GetBranchDetail.Branch_AgrrementDoc;
            this.BranchModel.Branch_PaymentInFavourOf =
              this.GetBranchDetail.Branch_PaymentInFavourOf;
            this.UploadImageBase64 =
              this.GetBranchDetail.Branch_AgrrementDoc != ""
                ? constantUrl.OthersDocUrl +
                  this.GetBranchDetail.Branch_AgrrementDoc
                : "";
          }
        });
    }

    $("#AddNewBranchModal").modal("show");
    $("#AddNewBranchModal").css("z-index", "1050");
  }

  onChangeState() {
    this.DistrictList = [];
    this.TehsilList = [];
    this.GetDistrictRequestModel.StateId = parseInt(this.StateId);
    this._MasterService
      .GetDistricts(this.GetDistrictRequestModel)
      .subscribe((result) => {
        this.DistrictList = JSON.parse(JSON.stringify(result));
      });
  }

  onChangeDistrict() {
    this.GetTehsilRequestModel.StateId = parseInt(this.StateId);
    this.GetTehsilRequestModel.DistrictId = parseInt(this.DistrictId);
    this._MasterService
      .GetTahsil(this.GetTehsilRequestModel)
      .subscribe((result) => {
        this.TehsilList = JSON.parse(JSON.stringify(result));
      });
  }

  OnClose() {
    this.BranchID = 0;
    this.BranchName = "";
    this.BranchCode = "";
    this.BranchType = "";
    this.SelectZoneId = "";
    this.StateId = "";
    this.DistrictId = "";
    this.TehsilId = "";
    this.Address = "";
    this.LandMark = "";
    this.Pincode = "";
    this.PhoneNo = "";
    this.BranchSetupDate = "";
    this.BranchHead = "";
    this.Remarks = "";
    this.DistrictList = [];
    this.TehsilList = [];
    $("#AddNewBranchModal").modal("hide");
  }

  onSaveBranchData() {
    let saveBranchJSON = {
      Branch: {
        BranchId: this.BranchID,
        Branch_ZoneId: parseInt(this.SelectZoneId),
        Branch_Name: this.BranchName,
        Branch_Code: this.BranchCode,
        Branch_DistrictId: parseInt(this.DistrictId),
        Branch_TehsilId: parseInt(this.TehsilId),
        Branch_Address: this.Address,
        Branch_LandMark: this.LandMark,
        Branch_PhoneNo: this.PhoneNo,
        Branch_PinCode: this.Pincode,
        Branch_Type: this.BranchType,
        Branch_HeadId: this.BranchHead,
        Branch_SetupDate: new Date(this.BranchSetupDate).toLocaleDateString(
          "fr-CA"
        ),
        Branch_LatLong: `${this.lat},${this.lng}`,
        Branch_CreateBy: this.data.userId,
        Branch_ModifyBy: this.data.userId,
        Branch_Remark: this.Remarks,
        Branch_Is_Rented: this.BranchModel.Branch_Is_Rented,
        Branch_ContractFrom: this.BranchModel.Branch_ContractFrom,
        Branch_ContractTo: this.BranchModel.Branch_ContractTo,
        Branch_Landlord_Name: this.BranchModel.Branch_Landlord_Name,
        Branch_Landlord_PanNo: this.BranchModel.Branch_Landlord_PanNo,
        Branch_Monthly_Rent: this.BranchModel.Branch_Monthly_Rent,
        Branch_Rent_Due_Date: this.BranchModel.Branch_Rent_Due_Date,
        Branch_AgrrementDoc: this.BranchModel.Branch_AgrrementDoc,
        Branch_PaymentInFavourOf: this.BranchModel.Branch_PaymentInFavourOf,
        Branch_GSTNo: this.GSTNo,
        Branch_EmailId: this.EmailId,
      },
    };

    //Upload Document
    this.uploadBranchAgreementDocs(this.BranchModel);

    this.SaveBranchRequestModel.JSON = JSON.stringify(saveBranchJSON);
    this._MasterService
      .SaveBranchDetails(this.SaveBranchRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getBranchesList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onOpenDeleteModal(selectedBranchId) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this.BranchID = selectedBranchId;
        $("#DeleteBranchModal").modal("show");
        $("#DeleteBranchModal").css("z-index", "1050");
      }
    });
  }

  onDeleteBranch() {
    this.DeleteBranchRequestModel.BranchId = this.BranchID;
    this.DeleteBranchRequestModel.LoginUserId = this.data.userId;
    this.DeleteBranchRequestModel.DeletionReason = this.DeletionReason;
    this._MasterService
      .DeleteBranchDetails(this.DeleteBranchRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getBranchesList();
          this.OnCloseDeleteModal();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnCloseDeleteModal() {
    this.BranchID = 0;
    this.DeletionReason = "";
    $("#DeleteBranchModal").modal("hide");
  }

  fileChangeListener(data: any, files: any) {
    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.Branch_AgrrementDoc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Branch_AgrrementDoc = "";
    delete data.DocData;
  }

  uploadBranchAgreementDocs(data: any) {
    if (data.DocData) {
      this._MasterService
        .SaveOtherDocPath({
          DocName: data.Branch_AgrrementDoc,
          DocData: data.DocData,
        })
        .subscribe((res: any) => {
          console.log("res", res);
        });
    }
  }

  onChangeBranchType() {
    if (this.BranchModel.Branch_Is_Rented == 0) {
      this.BranchModel = {
        Branch_Is_Rented: 0,
        Branch_ContractFrom: "",
        Branch_ContractTo: "",
        Branch_Landlord_Name: "",
        Branch_Landlord_PanNo: "",
        Branch_Monthly_Rent: "",
        Branch_Rent_Due_Date: "",
        Branch_PaymentInFavourOf: "",
        Branch_AgrrementDoc: "",
      };
    } else {
      this.BranchModel = {
        Branch_Is_Rented: 1,
        Branch_ContractFrom: "",
        Branch_ContractTo: "",
        Branch_Landlord_Name: "",
        Branch_Landlord_PanNo: "",
        Branch_Monthly_Rent: "",
        Branch_Rent_Due_Date: "",
        Branch_PaymentInFavourOf: "",
        Branch_AgrrementDoc: "",
      };
    }
  }

  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;

      this.GetDistrickDropdown();
    });
  }

  GetDistrickDropdown() {
    this.losService
      .GetDistrickDropdown({ StateID: this.FilterStateId })
      .subscribe((res: any) => {
        this.FilterDistrictList = res;
      });
  }
}
