<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span>Select Document</span>
      <select
        name="FilterDocument"
        id="FilterDocument"
        (change)="filterDocuments()"
        [(ngModel)]="FilterDocument"
        class="form-control input-text-css"
      >
        <option value="">Select Document</option>
        <option value="KYC">KYC</option>
        <option value="Income Proof">Income Proof</option>
        <option value="Property">Property</option>
        <option value="Vehicle">Vehicle</option>
      </select>
    </div>
  </div>

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="DocId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Type }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Document Category
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Documnet">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Document
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Documnet }}
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
