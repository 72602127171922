<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="getDayBookData(false)"
    class="formborder"
  >
    <div
      class="row m-0 col-md-12 p-0 d-flex justify-content-between align-items-center"
    >
      <div class="col-md-3">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CaseDateFrom"
            id="VoucherDateFrom"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            required
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="CaseDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Date From"
          (ngModelChange)="DateChange()"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span>Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            required
            #refVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateTo.invalid,
              'alert-warning':
                refVoucherDateTo.invalid &&
                (refVoucherDateTo.dirty ||
                  refVoucherDateTo.touched ||
                  refVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>

        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          (ngModelChange)="DateChange()"
          id="VoucherDateTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Date To"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateTo.invalid,
            'alert-warning':
              refVoucherDateTo.invalid &&
              (refVoucherDateTo.dirty ||
                refVoucherDateTo.touched ||
                refVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="getDayBookData(false)"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-3">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto;height: 60vh;"
        >
          <ng-container
            [matColumnDef]="col"
            *ngFor="let col of displayedColumns"
          >
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              {{
                col == "Row_Num"
                  ? "#"
                  : col == "Voucher_Narration"
                  ? "Narration"
                  : col.split("_").join(" ")
              }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <div *ngIf="col == 'TransDR' || col == 'TransCR'; else elseBlock">
                {{ row[col] > 0 ? row[col] : "" }}
              </div>
              <ng-template #elseBlock>
                {{ row[col] }}
              </ng-template>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              'bg-warning':
                row.Account_Name == null && row.Account_Name != 'Total',
              'bg-green': row.Account_Name == 'Total',
              'bg-danger':
                row.Account_Name == 'Total' && row.TransDR != row.TransCR
            }"
          ></mat-row>
        </mat-table>
        <div class="row m-0 pt-2 pb-1">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportExcel()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 text-right">
            <div *ngIf="dataSource.filteredData.length >= TotRow">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="LoadMore()"
              >
                <i
                  class="fa fa-spinner mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Load More
              </button>
            </div>
            <!-- <mat-paginator #paginatorRef [pageSizeOptions]="[50,100,200]" showFirstLastButtons> </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="day_book"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Narrartion
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Trans DR
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Trans CR
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ x.Row_Num }}</td>
    <td>{{ x.Voucher_Date }}</td>
    <td>{{ x.Voucher_Type }}</td>

    <td>{{ x.Voucher_No }}</td>
    <td>{{ x.Voucher_Narration }}</td>
    <td>{{ x.CaseNo }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.TransDR }}</td>
    <td>{{ x.TransCR }}</td>
  </tr>
</table>
