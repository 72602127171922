import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DepositService } from "../services/deposit.service";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;

@Component({
  selector: 'app-deposit-detail',
  templateUrl: './deposit-detail.component.html',
  styleUrls: ['./deposit-detail.component.scss']
})
export class DepositDetailComponent implements OnInit {
  currentUser: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showSpinner: boolean = false;
  Customer: any = {};
  BranchesList: any = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private dataSharingService: DataSharingService, 
    private router: Router, 
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService, 
    public snackBar: MatSnackBar, 
    private MasterService: MasterService,
    private _DepositService: DepositService,
    private lmsService: LmsService
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

   
  }



}
