<form #f="ngForm" novalidate>
  <div class="row m-0 formborder">
    <div class="col-md-5 p-1">
      <span class="required-lable"> Company Name </span>
      <input
        required
        type="text"
        #refCompanyName="ngModel"
        maxlength="250"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyName.invalid,
          'alert-warning':
            refCompanyName.invalid &&
            (refCompanyName.dirty ||
              refCompanyName.touched ||
              refCompanyName.untouched)
        }"
        [(ngModel)]="CompanyName"
        placeholder="Enter Company Name"
        name="CompanyName"
        id="CompanyName"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable">Default CRC Agency </span>
      <select
        name="Agency"
        id="Agency"
        #refAgency="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refAgency.invalid,
          'alert-warning':
            refAgency.invalid &&
            (refAgency.dirty || refAgency.touched || refAgency.untouched)
        }"
        [(ngModel)]="CRC_Agency"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Agency</option>
        <option *ngFor="let Type of CRC_Agency_Bind" [value]="Type.Id">
          {{ Type.Value }}
        </option>
      </select>
    </div>
    <div class="col-md-2 p-1" style="text-align: center">
      <button
        (click)="Add_Current_FinancialYear()"
        class="btn font-size-12 button-btn"
      >
        Add Current Financial Year
      </button>
    </div>
    <div class="col-md-10 p-1">
      <span class="required-lable"> Company Head Office Address </span>
      <input
        required
        type="text"
        #refCompanyHOAddress="ngModel"
        maxlength="150"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyHOAddress.invalid,
          'alert-warning':
            refCompanyHOAddress.invalid &&
            (refCompanyHOAddress.dirty ||
              refCompanyHOAddress.touched ||
              refCompanyHOAddress.untouched)
        }"
        [(ngModel)]="CompanyHOAddress"
        placeholder="Enter Company HO Address"
        name="CompanyHOAddress"
        id="CompanyHOAddress"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-10 p-1">
      <span class="required-lable"> Company Corporate Office Address </span>
      <input
        type="text"
        #refcompanyCorporateAddress="ngModel"
        maxlength="150"
        [(ngModel)]="companyCorporateAddress"
        placeholder="Enter Company Corporate Address"
        name="companyCorporateaddress"
        id="companyCorporateaddress"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span> State Code </span>
      <select
        name="FilterStateId"
        id="FilterStateId"
        [(ngModel)]="FilterStateId"
        class="form-control input-text-css"
      >
        <option value="">Select State</option>
        <option
          *ngFor="let StateList of FilterStateList"
          [value]="StateList.StateId"
        >
          {{ StateList.State_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Company Date of incorporation </span>
      <div class="datepicker_feild">
        <input
          required
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="CompanyDateofIncorporation"
          id="CompanyDateofIncorporation"
          #refCompanyIncorporationDate="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refCompanyIncorporationDate.invalid,
            'alert-warning':
              refCompanyIncorporationDate.invalid &&
              (refCompanyIncorporationDate.dirty ||
                refCompanyIncorporationDate.touched ||
                refCompanyIncorporationDate.untouched)
          }"
          [(ngModel)]="CompanyDateofIncorporation"
          class="form-control input-text-css"
          [(ngModel)]="CompanyDateofIncorporation"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Company Reg. No </span>
      <input
        required
        type="text"
        #refCompanyRegNo="ngModel"
        maxlength="50"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyRegNo.invalid,
          'alert-warning':
            refCompanyRegNo.invalid &&
            (refCompanyRegNo.dirty ||
              refCompanyRegNo.touched ||
              refCompanyRegNo.untouched)
        }"
        [(ngModel)]="CompanyRegNoName"
        placeholder="Enter Company Reg No"
        name="CompanyRegNo"
        id="CompanyRegNo"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Company GST. No </span>
      <input
        required
        type="text"
        #refCompanyGSTNo="ngModel"
        maxlength="50"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyGSTNo.invalid,
          'alert-warning':
            refCompanyGSTNo.invalid &&
            (refCompanyGSTNo.dirty ||
              refCompanyGSTNo.touched ||
              refCompanyGSTNo.untouched)
        }"
        [(ngModel)]="CompanyGSTNo"
        placeholder="Enter Company GST No"
        name="CompanyGSTNo"
        id="CompanyGSTNo"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Company TAN No </span>
      <input
        required
        type="text"
        #refCompanyTANNo="ngModel"
        maxlength="50"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyTANNo.invalid,
          'alert-warning':
            refCompanyTANNo.invalid &&
            (refCompanyTANNo.dirty ||
              refCompanyTANNo.touched ||
              refCompanyTANNo.untouched)
        }"
        [(ngModel)]="CompanyTANNo"
        placeholder="Enter Company TAN No"
        name="CompanyTANNo"
        id="CompanyTANNo"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Company PAN No </span>
      <input
        required
        type="text"
        #refCompanyPANNo="ngModel"
        maxlength="10"
        minlength="10"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyPANNo.invalid,
          'alert-warning':
            refCompanyPANNo.invalid &&
            (refCompanyPANNo.dirty ||
              refCompanyPANNo.touched ||
              refCompanyPANNo.untouched)
        }"
        [(ngModel)]="CompanyPANNo"
        placeholder="Enter Company PAN No"
        name="CompanyPANNo"
        id="CompanyPANNo"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Company Phone No </span>
      <input
        required
        type="text"
        numbersOnly
        maxlength="10"
        minlength="10"
        #refCompanyPhoneNo="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyPhoneNo.invalid,
          'alert-warning':
            refCompanyPhoneNo.invalid &&
            (refCompanyPhoneNo.dirty ||
              refCompanyPhoneNo.touched ||
              refCompanyPhoneNo.untouched)
        }"
        [(ngModel)]="CompanyPhoneNo"
        placeholder="Enter Company Phone No"
        name="CompanyPhoneNo"
        id="CompanyPhoneNo"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Company Phone No 1</span>
      <input
        required
        type="text"
        numbersOnly
        maxlength="10"
        minlength="10"
        #refCompanyPhoneNo1="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refCompanyPhoneNo1.invalid,
          'alert-warning':
            refCompanyPhoneNo1.invalid &&
            (refCompanyPhoneNo1.dirty ||
              refCompanyPhoneNo1.touched ||
              refCompanyPhoneNo1.untouched)
        }"
        [(ngModel)]="CompanyPhoneNo1"
        placeholder="Enter Company Phone No 1"
        name="CompanyPhoneNo1"
        id="CompanyPhoneNo1"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span> Company WhatsApp No </span>
      <input
        required
        type="text"
        #refCompanyWhatAppNo="ngModel"
        maxlength="50"
        [(ngModel)]="CompanyWhatAppNo"
        placeholder="Enter Company WhatsApp No"
        name="CompanyWhatAppNo"
        id="CompanyWhatAppNo"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span> Company Email</span>
      <input
        required
        type="text"
        #refCompanyPhoneNo1="ngModel"
        maxlength="50"
        [(ngModel)]="ComapnyEmail"
        placeholder="Enter Company Email"
        name="ComapnyEmail"
        id="ComapnyEmail"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Rejected Application Reopen Days </span>
      <input
        required
        type="number"
        #refReopenDays="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refReopenDays.invalid,
          'alert-warning':
            refReopenDays.invalid &&
            (refReopenDays.dirty ||
              refReopenDays.touched ||
              refReopenDays.untouched)
        }"
        [(ngModel)]="ReopenDays"
        placeholder="Enter Rejected Application Reopen Days"
        name="ReopenDays"
        id="ReopenDays"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable">Application No Prefix </span>
      <input
        required
        type="text"
        #refApplicationNoPrefix="ngModel"
        maxlength="2"
        [ngClass]="{
          'is-invalid': f.submitted && refApplicationNoPrefix.invalid,
          'alert-warning':
            refApplicationNoPrefix.invalid &&
            (refApplicationNoPrefix.dirty ||
              refApplicationNoPrefix.touched ||
              refApplicationNoPrefix.untouched)
        }"
        [(ngModel)]="ApplicationNoPrefix"
        placeholder="Enter Application No Prefix"
        name="ApplicationNoPrefix"
        id="ApplicationNoPrefix"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Branch Code Prefix </span>
      <input
        required
        type="text"
        #refBranchCodePrefix="ngModel"
        maxlength="2"
        [ngClass]="{
          'is-invalid': f.submitted && refBranchCodePrefix.invalid,
          'alert-warning':
            refBranchCodePrefix.invalid &&
            (refBranchCodePrefix.dirty ||
              refBranchCodePrefix.touched ||
              refBranchCodePrefix.untouched)
        }"
        [(ngModel)]="BranchCodePrefix"
        placeholder="Enter Branch Code Prefix"
        name="BranchCodePrefix"
        id="BranchCodePrefix"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Enquiry No Prefix </span>
      <input
        required
        type="text"
        #refEnquiryNoPrefix="ngModel"
        maxlength="2"
        [ngClass]="{
          'is-invalid': f.submitted && refEnquiryNoPrefix.invalid,
          'alert-warning':
            refEnquiryNoPrefix.invalid &&
            (refEnquiryNoPrefix.dirty ||
              refEnquiryNoPrefix.touched ||
              refEnquiryNoPrefix.untouched)
        }"
        [(ngModel)]="EnquiryNoPrefix"
        placeholder="Enter Enquiry No Prefix"
        name="EnquiryNoPrefix"
        id="EnquiryNoPrefix"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Upload File Size (KB)</span>
      <input
        required
        type="text"
        #refUploadMaxSize_In_KB="ngModel"
        numbersOnly
        [ngClass]="{
          'is-invalid': f.submitted && refUploadMaxSize_In_KB.invalid,
          'alert-warning':
            refUploadMaxSize_In_KB.invalid &&
            (refUploadMaxSize_In_KB.dirty ||
              refUploadMaxSize_In_KB.touched ||
              refUploadMaxSize_In_KB.untouched)
        }"
        [(ngModel)]="UploadMaxSize_In_KB"
        placeholder="Upload File Size"
        name="UploadMaxSize_In_KB"
        id="UploadMaxSize_In_KB"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> NPA DPD Days</span>
      <input
        required
        type="text"
        #refNPA_DPD="ngModel"
        numbersOnly
        [ngClass]="{
          'is-invalid': f.submitted && refNPA_DPD.invalid,
          'alert-warning':
            refNPA_DPD.invalid &&
            (refNPA_DPD.dirty || refNPA_DPD.touched || refNPA_DPD.untouched)
        }"
        [(ngModel)]="NPA_DPD"
        placeholder="NPA DPD Days"
        name="NPA_DPD"
        id="NPA_DPD"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-5 p-1">
      <span class="required-lable"> Limit Of Cash Receipt </span>
      <input
        required
        type="number"
        #refLimitOfCashReceipt="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refLimitOfCashReceipt.invalid,
          'alert-warning':
            refLimitOfCashReceipt.invalid &&
            (refLimitOfCashReceipt.dirty ||
              refLimitOfCashReceipt.touched ||
              refLimitOfCashReceipt.untouched)
        }"
        [(ngModel)]="LimitOfCashReceipt"
        placeholder="Limit Of Cash Receipt"
        name="LimitOfCashReceipt"
        id="LimitOfCashReceipt"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-5 p-1">
      <span class="required-lable"> Limit Of Cash Payment </span>
      <input
        required
        type="number"
        #refLimitOfCashPayment="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refLimitOfCashPayment.invalid,
          'alert-warning':
            refLimitOfCashPayment.invalid &&
            (refLimitOfCashPayment.dirty ||
              refLimitOfCashPayment.touched ||
              refLimitOfCashPayment.untouched)
        }"
        [(ngModel)]="LimitOfCashPayment"
        placeholder="Limit Of Cash Payment"
        name="LimitOfCashPayment"
        id="LimitOfCashPayment"
        class="form-control input-text-css"
      />
    </div>
  </div>

  <div class="row m-0 formborder">
    <div class="col-md-2 p-1">
      <span class="required-lable"> Days In Year </span>
      <input required
             type="number"
             #refDaysInYear="ngModel"
             [ngClass]="{
          'is-invalid': f.submitted && refDaysInYear.invalid,
          'alert-warning':
            refDaysInYear.invalid &&
            (refDaysInYear.dirty ||
              refDaysInYear.touched ||
              refDaysInYear.untouched)
        }"
             [(ngModel)]="DaysInYear"
             placeholder="Enter Days In Year"
             name="DaysInYear"
             id="DaysInYear"
             class="form-control input-text-css" />
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable"> Data Freeze Date </span>
      <div class="datepicker_feild">
        <input [matDatepicker]="picker2"
               dateConvert
               placeholder="DD/MM/YYYY"
               name="Data_Freeze"
               id="Data_Freeze"
               #refData_Freeze="ngModel"
               class="form-control input-text-css"
               [ngClass]="{
            'is-invalid': f.submitted && refData_Freeze.invalid,
            'alert-warning':
              refData_Freeze.invalid &&
              (refData_Freeze.dirty ||
                refData_Freeze.touched ||
                refData_Freeze.untouched)
          }"
               [(ngModel)]="Data_Freeze" />
        <mat-datepicker-toggle matSuffix
                               [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-2">
      <span class="form-check-inline"
            style="padding-top: 21px; margin-left: 33px">
        <label class="form-check-label"> Loan No.Generation: </label>
      </span>
    </div>
    <div class="col-md-3 p-2">
      <span class="form-check-inline" style="padding-top: 20px !important">
        <label class="form-check-label" for="Mannual">
          <input type="radio"
                 class="form-check-input"
                 id="Mannual"
                 name="LoanSeries"
                 #refLoanSeries="ngModel"
                 [ngClass]="{
              'is-invalid': f.submitted && refLoanSeries.invalid,
              'alert-warning':
                refLoanSeries.invalid &&
                (refLoanSeries.dirty ||
                  refLoanSeries.touched ||
                  refLoanSeries.untouched)
            }"
                 [(ngModel)]="LoanSeries"
                 required
                 value="Mannual" />Mannual
        </label>
      </span>
      <span class="form-check-inline">
        <label class="form-check-label" for="Automatic">
          <input type="radio"
                 class="form-check-input"
                 id="Automatic"
                 name="LoanSeries"
                 #refLoanSeries="ngModel"
                 [ngClass]="{
              'is-invalid': f.submitted && refLoanSeries.invalid,
              'alert-warning':
                refLoanSeries.invalid &&
                (refLoanSeries.dirty ||
                  refLoanSeries.touched ||
                  refLoanSeries.untouched)
            }"
                 [(ngModel)]="LoanSeries"
                 required
                 value="Automatic" />Automatic
        </label>
      </span>
    </div>
    <div class="col-md-2 mt-4">
      <div>
        <mat-checkbox name="Is_NOC_Block"
                      [(ngModel)]="Is_NOC_Block"></mat-checkbox>
        <span class="ml-2">NOC Block</span>
      </div>
    </div>
    <div class="col-md-2 mt-4" hidden>
      <div>
        <mat-checkbox name="IsMasking"
                      [(ngModel)]="IsMasking"></mat-checkbox>
        <span class="ml-2">Masking Allow</span>
      </div>
    </div>
    
    <div class="col-md-3 p-1">
      <span class="required-lable"> Company Logo </span>
      <input type="file"
             name="CompanyLogo"
             id="CompanyLogo"
             [(ngModel)]="CompanyLogo"
             accept=".jpg, .jpeg, .png"
             (change)="handleUpload($event)" />
    </div>
    <div class="col-md-3 p-1">
      <img src="{{ UploadImageBase64 }}" alt="" style="width: 150px" />
    </div>

    <div class="col-md-10 p-1" style="text-align: center">
      <button type="submit"
              [disabled]="!f.form.valid"
              (click)="onSaveData()"
              class="btn font-size-12 button-btn">
        Update
      </button>
    </div>
  </div>
</form>
