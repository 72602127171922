<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span> Select Product </span>
      <select
        name="FilterProuctId"
        id="FilterProuctId"
        (change)="getProductList()"
        [(ngModel)]="FilterProuctId"
        class="form-control input-text-css"
      >
        <option value="">Select Product</option>
        <option
          *ngFor="let ProductListDropdown of ProductListDropdown"
          [value]="ProductListDropdown.ProductId"
        >
          {{ ProductListDropdown.Product_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1"></div>
    <div class="col-md-6 p-1 text-right">
      <button
        class="btn font-size-12 button-btn"
        (click)="OnOpenProductModal(0)"
      >
        New Product
      </button>
    </div>
  </div>
  <div class="col-md-12 mt-2 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <td><b>Workflow Created On :</b></td>
          <td>{{ WorkflowCreatedOn }}</td>
          <td><b>Workflow Created By:</b></td>
          <td>{{ WorkflowCreatedBy }}</td>
        </tr>
        <tr>
          <td><b>Workflow Last Modify On:</b></td>
          <td>{{ WorkflowModifiedOn }}</td>
          <td><b>Workflow Modify By:</b></td>
          <td>{{ WorkflowModifiedBy }}</td>
        </tr>
      </thead>
    </table>
  </div>

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ProcessId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GroupName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          GroupName
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GroupName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Process }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Squance">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Sequence
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Squance }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="IsMandatory">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          IsMandatory
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.IsMandatory }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Section">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Section
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox
            (change)="onChangeSelection($event, row)"
            [disabled]="row.isDisable"
            [checked]="row.isChecked"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fa fa-trash style-delete"
            *ngIf="row.isShowDelete"
            (click)="onDeleteProcess(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>

<div class="col-md-12 p-1 text-right">
  <button class="btn font-size-12 button-btn" (click)="OnSaveProductSection()">
    Save
  </button>
</div>

<div
  class="modal fade in"
  id="AddProductModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #dbf="ngForm" (ngSubmit)="onSaveNewProduct()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add New Product
          </h6>
          <button
            type="button"
            (click)="OnCloseProductModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Category : </span>
            </div>
            <div class="col-md-6 p-1">
              <select
                name="SelectProductCategory"
                id="SelectProductCategory"
                #refSelectProductCategory="ngModel"
                [ngClass]="{
                  'is-invalid':
                    dbf.submitted && refSelectProductCategory.invalid,
                  'alert-warning':
                    refSelectProductCategory.invalid &&
                    (refSelectProductCategory.dirty ||
                      refSelectProductCategory.touched ||
                      refSelectProductCategory.untouched)
                }"
                [(ngModel)]="SelectProductCategory"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Product</option>
                <option
                  *ngFor="let productCategory of ProductCategoryDropdown"
                  [value]="productCategory.ProductCatId"
                >
                  {{ productCategory.Product_Category }}
                </option>
              </select>
            </div>
            <div class="col-md-2 p-1 text-right">
              <button
                class="btn font-size-12 button-btn"
                style="padding: 0.375rem 0.55rem"
              >
                Add New
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Name : </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refProductName="ngModel"
                [ngClass]="{
                  'is-invalid': dbf.submitted && refProductName.invalid,
                  'alert-warning':
                    refProductName.invalid &&
                    (refProductName.dirty ||
                      refProductName.touched ||
                      refProductName.untouched)
                }"
                [(ngModel)]="ProductName"
                placeholder="Product Name"
                name="ProductName"
                id="ProductName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Product Short Name : </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refProductShortName="ngModel"
                [ngClass]="{
                  'is-invalid': dbf.submitted && refProductShortName.invalid,
                  'alert-warning':
                    refProductShortName.invalid &&
                    (refProductShortName.dirty ||
                      refProductShortName.touched ||
                      refProductShortName.untouched)
                }"
                [(ngModel)]="ProductShortName"
                placeholder="Product Short Name"
                name="ProductShortName"
                id="ProductShortName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> CRC Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="CRC_Co_Browser"
                  id="CRC-Co-Browser"
                  name="CRC-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="CRC_Guarantor"
                  id="CRC-Guarantor"
                  name="CRC-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> FI Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="FI_Co_Browser"
                  id="FI-Co-Browser"
                  name="FI-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="FI_Guarantor"
                  id="FI-Guarantor"
                  name="FI-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span> TVR Allow For : </span>
            </div>
            <div class="col-md-8 p-0 row m-0">
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="TVR_Co_Browser"
                  id="TVR-Co-Browser"
                  name="TVR-Co-Browser"
                >
                  Co-Borrower
                </mat-checkbox>
              </div>
              <div class="col-6 p-1">
                <mat-checkbox
                  [(ngModel)]="TVR_Guarantor"
                  id="TVR-Guarantor"
                  name="TVR-Guarantor"
                >
                  Guarantor
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseProductModal()"
            class="btn button-btn font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveNewProduct()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!dbf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
