<div class="grid-container ">
    <div class="  grid-row-con">
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Type</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Value </div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Minimum</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Maximum</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Current Value</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Status</div>
        </div>
        <div class="grid-items ">
            <div class="grid-sub-item-value font-weight-bold"> Approved By </div>
        </div>
        <div class="grid-items ">
            <div class="grid-sub-item-value font-weight-bold"> Remark </div>
        </div>
       
    </div>

    <div class=" mt-1 grid-row-con" *ngFor="let row of data">



        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Process</div> -->
            <div class="grid-sub-item-value"> {{row.Type}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Executive </div> -->
            <div class="grid-sub-item-value"> {{row.Value}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Status</div> -->
            <div class="grid-sub-item-value"> {{row.Minimum}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Assign On</div> -->
            <div class="grid-sub-item-value"> {{row.Maximum}} </div>
        </div>
        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Assign On</div> -->
            <div class="grid-sub-item-value"> {{row.Col_Val}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Completed On</div> -->
            <div class="grid-sub-item-value"> {{row.Status}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> TAT (Hrs) </div> -->
            <div class="grid-sub-item-value">{{row.Approved_BY}}</div>
        </div>
        <div class="grid-items">
            <!-- <div class="grid-sub-item"> TAT (Hrs) </div> -->
            <div class="grid-sub-item-value">{{row.DeviationRemark}}</div>
        </div>

       
    </div>
</div>