import { Component, OnInit, ViewChild, Input, Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
// import { MatTableExporterModule } from "ngx-mat-table-exporter";

declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { ReportsService } from "../../_Reports/services/reports.service";

interface TrialBalance {
  Name_type?: string;
  CustomerName?: string;
  Product_Name?: string;
  Loan_Acc_Category?: string;
  Type_Which?: string;
  DPD_Days: number;
  OP_OD?: number;
  Total_OP_Future?: number;
  OP_FU_Current?: number;
  OP_FU_Next?: number;
  Current_Due?: number;
  Total_Due?: number;
  Collection_OP_OD?: number;
  Collection_Current?: number;
  Collection_Future?: number;
  ODInterestBalance?: number;
  Total_Collection?: number;
  CL_OD?: number;
  LastReceivedDate: number;
  Efficiency_OD?: number;
  Efficiency_Current?: number;
  Efficiency_Total?: number;
  Customer_PhoneNo?: string;
  Customer_Address?: string;
  DueDate?: string;
  DueEMI?: string;
  SourceType?: string;
  SourceName?: string;
  SalesExecutive?: string;
  VehicleNo: number;
  Alternate_PhoneNo?: string;
  Co_Borrower_Name?: string;
  Co_Borrower_MobileNo?: string;
  guarantor_Name?: string;
  guarantor_MobileNo?: string;
  PDC_Type?: string;
  Companybank?: string;
  UmrnNumber?: string;
  EMIFrequency?: string;
  LoanDate?: string;
  FirstEMIDate?: string;
  children?: TrialBalance[];
}

interface ExampleFlatNode {
  expandable: boolean;
  Name_type?: string;
  CustomerName?: string;
  Product_Name?: string;
  Loan_Acc_Category?: string;
  Type_Which?: string;
  DPD_Days: number;
  OP_OD?: number;
  Total_OP_Future?: number;
  OP_FU_Current?: number;
  OP_FU_Next?: number;
  Current_Due?: number;
  Total_Due?: number;
  Collection_OP_OD?: number;
  Collection_Current?: number;
  Collection_Future?: number;
  ODInterestBalance?: number;

  Total_Collection?: number;
  CL_OD?: number;
  LastReceivedDate: number;
  Efficiency_OD?: number;
  Efficiency_Current?: number;
  Efficiency_Total?: number;
  Customer_PhoneNo?: string;
  Customer_Address?: string;
  DueDate?: string;
  DueEMI?: string;
  level: number;
  SourceType?: string;
  SourceName?: string;
  SalesExecutive?: string;
  VehicleNo: number;
  Alternate_PhoneNo?: string;
  Co_Borrower_Name?: string;
  Co_Borrower_MobileNo?: string;
  guarantor_Name?: string;
  guarantor_MobileNo?: string;
  PDC_Type?: string;
  Companybank?: string;
  UmrnNumber?: string;
  EMIFrequency?: string;
  LoanDate?: string;
  FirstEMIDate?: string;
}

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FLOAT } from "html2canvas/dist/types/css/property-descriptors/float";
import { LosService } from "src/app/_LOS/services/los.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-collection-efficiency",
  templateUrl: "./collection-efficiency.component.html",
  styleUrls: ["./collection-efficiency.component.scss"],
})
export class CollectionEfficiencyComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  dataSourceTree: any;
  JsonData: any[] = [];
  ExpandBtn: boolean = true;
  CollaspeBtn: boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  LPI: boolean = false;
  FromDate: any;
  ToDate: any;
  Type: any = 0;
  LoanNo: any;

  selectedRow: any = null;

  FyearId: any;
  maxDate: any;
  minDate: any;

  Branches: any = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedCTreeolumns = [
    "Name_type",
    "CustomerName",
    "Product_Name",
    "Loan_Acc_Category",
    "Type_Which",
    "DPD_Days",
    "OP_OD",
    "OP_FU_Current",
    "OP_FU_Next",
    "Current_Due",
    "DueDate",
    "DueEMI",
    "Total_Due",
    "Collection_OP_OD",
    "Collection_Current",
    "Collection_Future",
    "Total_Collection",
    "Efficiency_Total",
    "CL_OD",
    "LastReceivedDate",
    "ODInterestBalance",
    "SourceType",
    "SourceName",
    "SalesExecutive",
    "VehicleNo",
    "Customer_PhoneNo",
    "Alternate_PhoneNo",
    "Customer_Address",
    "Co_Borrower_Name",
    "Co_Borrower_MobileNo",
    "guarantor_Name",
    "guarantor_MobileNo",
    "PDC_Type",
    "Companybank",
    "UmrnNumber",
    "EMIFrequency",
    "LoanDate",
    "FirstEMIDate",
  ];

  //TREE_DATA: TrialBalance[]=[];
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  private transformer = (node: TrialBalance, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Name_type: node.Name_type,
      Product_Name: node.Product_Name,
      Loan_Acc_Category: node.Loan_Acc_Category,
      CustomerName: node.CustomerName,
      Type_Which: node.Type_Which,
      DPD_Days: node.DPD_Days,
      OP_OD: node.OP_OD,
      Total_OP_Future: node.Total_OP_Future,
      OP_FU_Current: node.OP_FU_Current,
      OP_FU_Next: node.OP_FU_Next,
      Current_Due: node.Current_Due,
      Total_Due: node.Total_Due,
      Collection_OP_OD: node.Collection_OP_OD,
      Collection_Current: node.Collection_Current,
      Collection_Future: node.Collection_Future,
      ODInterestBalance: node.ODInterestBalance,
      Total_Collection: node.Total_Collection,
      CL_OD: node.CL_OD,
      LastReceivedDate: node.LastReceivedDate,
      Efficiency_OD: node.Efficiency_OD,
      Efficiency_Current: node.Efficiency_Current,
      Efficiency_Total: node.Efficiency_Total,
      Customer_PhoneNo: node.Customer_PhoneNo,
      Customer_Address: node.Customer_Address,
      DueDate: node.DueDate,
      DueEMI: node.DueEMI,
      level: level,
      SourceType: node.SourceType,
      SourceName: node.SourceName,
      SalesExecutive: node.SalesExecutive,
      VehicleNo: node.VehicleNo,
      Alternate_PhoneNo: node.Alternate_PhoneNo,
      Co_Borrower_Name: node.Co_Borrower_Name,
      Co_Borrower_MobileNo: node.Co_Borrower_MobileNo,
      guarantor_Name: node.guarantor_Name,
      guarantor_MobileNo: node.guarantor_MobileNo,
      PDC_Type: node.PDC_Type,
      Companybank: node.Companybank,
      UmrnNumber: node.UmrnNumber,
      EMIFrequency: node.EMIFrequency,
      LoanDate: node.LoanDate,
      FirstEMIDate: node.FirstEMIDate,
    };
  };
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  selectedId: any;
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any = [];
  LeadSourceName: any;
  leadSourceDropdown: any;

  AccountingCatDropdown: any[] = [];
  LoanAccountCategory: any = "0";
  efficiencyDisplayTotal: string;
  constructor(
    private dataSharingService: DataSharingService,
    private masterService: MasterService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Collection Efficiency");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();

    this.Get_Loan_Accounting_Category_ForDropdown();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          //this.dataSourceTree = null;
        });
    });
  }
  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccountingCatDropdown = res;
      });
  }
  unflatten(arr) {
    var tree = [],
      mappedArr = [],
      arrElem,
      mappedElem;

    if (this.Type == 0) {
      //First map the nodes of the array to an object -> create a hash table.
      for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.BranchId] = arrElem;
        mappedArr[arrElem.BranchId]["children"] = [];
      }
      for (var BranchId in mappedArr) {
        this.Branches.push({
          BranchId: mappedArr[BranchId].BranchId,
        });
        tree.push({
          BranchId: mappedArr[BranchId].BranchId,
          Name_type: mappedArr[BranchId].Branch_Name,
          //OP_OD: mappedArr[BranchId].OP_OD,
          children: [],
        });
      }
      console.log("Branches :", this.Branches);
      for (var i = 0; i < tree.length; i++) {
        tree[i].OP_OD = 0;
        tree[i].OP_FU_Current = 0;
        tree[i].OP_FU_Next = 0;
        tree[i].Current_Due = 0;
        tree[i].Total_Due = 0;
        tree[i].Collection_OP_OD = 0;
        tree[i].Collection_Current = 0;
        tree[i].Collection_Future = 0;
        tree[i].ODInterestBalance = 0;
        tree[i].Total_Collection = 0;

        tree[i].CL_OD = 0;
        tree[i].Efficiency_OD = 0;
        tree[i].Efficiency_Current = 0;
        tree[i].Efficiency_Total = 0;
        for (var j = 0; j < arr.length; j++) {
          if (arr[j].BranchId == tree[i].BranchId) {
            tree[i].OP_OD = tree[i].OP_OD + arr[j].OP_OD;
            tree[i].OP_FU_Current =
              tree[i].OP_FU_Current + arr[j].OP_FU_Current;
            tree[i].OP_FU_Next = tree[i].OP_FU_Next + arr[j].OP_FU_Next;
            tree[i].Current_Due = tree[i].Current_Due + arr[j].Current_Due;
            tree[i].Total_Due = tree[i].Total_Due + arr[j].Total_Due;
            tree[i].Collection_OP_OD =
              tree[i].Collection_OP_OD + arr[j].Collection_OP_OD;
            tree[i].Collection_Current =
              tree[i].Collection_Current + arr[j].Collection_Current;
            tree[i].Collection_Future =
              tree[i].Collection_Future + arr[j].Collection_Future;
            tree[i].ODInterestBalance =
              tree[i].ODInterestBalance + arr[j].ODInterestBalance;

            tree[i].Total_Collection =
              tree[i].Total_Collection + arr[j].Total_Collection;

            tree[i].CL_OD = tree[i].CL_OD + arr[j].CL_OD;
            tree[i].Efficiency_OD =
              (tree[i].Collection_OP_OD / tree[i].OP_OD) * 100;
            tree[i].Efficiency_Current =
              (tree[i].Collection_Current / tree[i].Current_Due) * 100;
            tree[i].Efficiency_Total =
              (tree[i].Total_Collection / tree[i].Total_Due) * 100;

            tree[i]["children"].push({
              BranchId: arr[j].BranchId,
              Name_type: arr[j].LoanAcNo,
              Type_Which: arr[j].CollectionExecutive_Name,
              DPD_Days: arr[j].DPD_Days,
              OP_OD: arr[j].OP_OD,
              OP_FU_Current: arr[j].OP_FU_Current,
              OP_FU_Next: arr[j].OP_FU_Next,
              Current_Due: arr[j].Current_Due,
              Total_Due: arr[j].Total_Due,
              Collection_OP_OD: arr[j].Collection_OP_OD,
              Collection_Current: arr[j].Collection_Current,
              Collection_Future: arr[j].Collection_Future,
              ODInterestBalance: arr[j].ODInterestBalance,
              Total_Collection: arr[j].Total_Collection,

              CL_OD: arr[j].CL_OD,
              Efficiency_OD: (arr[j].Collection_OP_OD / arr[j].OP_OD) * 100,
              Efficiency_Current:
                (arr[j].Collection_Current / arr[j].Current_Due) * 100,
              Efficiency_Total:
                (arr[j].Total_Collection / arr[j].Total_Due) * 100,
              CollectionExecutive: arr[j].CollectionExecutive,

              ProductId: arr[j].ProductId,
              Product_Name: arr[j].Product_Name,
              Loan_Acc_Category: arr[j].Loan_Acc_Category,

              CustomerName: arr[j].CustomerName,
              Total_OP_Future: arr[j].Total_OP_Future,

              Customer_PhoneNo: arr[j].Customer_PhoneNo,
              Customer_Address: arr[j].Customer_Address,
              DueDate: arr[j].DueDate,
              DueEMI: arr[j].DueEMI,
              SourceType: arr[j].SourceType,
              SourceName: arr[j].SourceName,
              LastReceivedDate: arr[j].LastReceivedDate,
              VehicleNo: arr[j].VehicleNo,
              SalesExecutive: arr[j].SalesExecutive,
              Alternate_PhoneNo: arr[j].Alternate_PhoneNo,
              Co_Borrower_Name: arr[j].Co_Borrower_Name,
              Co_Borrower_MobileNo: arr[j].Co_Borrower_MobileNo,
              guarantor_Name: arr[j].guarantor_Name,
              guarantor_MobileNo: arr[j].guarantor_MobileNo,

              PDC_Type: arr[j].PDC_Type,
              Companybank: arr[j].Companybank,
              UmrnNumber: arr[j].UmrnNumber,
              EMIFrequency: arr[j].EMIFrequency,
              LoanDate: arr[j].LoanDate,
              FirstEMIDate: arr[j].FirstEMIDate,
            });
          }
        }
      }
    } else {
      for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.CollectionExecutive] = arrElem;
        mappedArr[arrElem.CollectionExecutive]["children"] = [];
      }
      for (var CollectionExecutive in mappedArr) {
        tree.push({
          CollectionExecutive:
            mappedArr[CollectionExecutive].CollectionExecutive,
          Name_type: mappedArr[CollectionExecutive].CollectionExecutive_Name,
          children: [],
        });
      }

      for (var i = 0; i < tree.length; i++) {
        tree[i].OP_OD = 0;
        tree[i].OP_FU_Current = 0;
        tree[i].OP_FU_Next = 0;
        tree[i].Current_Due = 0;
        tree[i].Total_Due = 0;
        tree[i].Collection_OP_OD = 0;
        tree[i].Collection_Current = 0;
        tree[i].Collection_Future = 0;
        tree[i].ODInterestBalance = 0;
        tree[i].Total_Collection = 0;

        tree[i].CL_OD = 0;
        tree[i].Efficiency_OD = 0;
        tree[i].Efficiency_Current = 0;
        tree[i].Efficiency_Total = 0;
        for (var j = 0; j < arr.length; j++) {
          if (arr[j].CollectionExecutive == tree[i].CollectionExecutive) {
            tree[i].OP_OD = tree[i].OP_OD + arr[j].OP_OD;
            tree[i].OP_FU_Current =
              tree[i].OP_FU_Current + arr[j].OP_FU_Current;
            tree[i].OP_FU_Next = tree[i].OP_FU_Next + arr[j].OP_FU_Next;
            tree[i].Current_Due = tree[i].Current_Due + arr[j].Current_Due;
            tree[i].Total_Due = tree[i].Total_Due + arr[j].Total_Due;
            tree[i].Collection_OP_OD =
              tree[i].Collection_OP_OD + arr[j].Collection_OP_OD;
            tree[i].Collection_Current =
              tree[i].Collection_Current + arr[j].Collection_Current;
            tree[i].Collection_Future =
              tree[i].Collection_Future + arr[j].Collection_Future;
            tree[i].ODInterestBalance =
              tree[i].ODInterestBalance + arr[j].ODInterestBalance;

            tree[i].Total_Collection =
              tree[i].Total_Collection + arr[j].Total_Collection;

            tree[i].CL_OD = tree[i].CL_OD + arr[j].CL_OD;
            tree[i].Efficiency_OD =
              (tree[i].Collection_OP_OD / tree[i].OP_OD) * 100;
            tree[i].Efficiency_Current =
              (tree[i].Collection_Current / tree[i].Current_Due) * 100;
            // tree[i].Efficiency_Total = tree[i].Efficiency_Total + arr[j].Efficiency_Total;
            tree[i].Efficiency_Total =
              (tree[i].Total_Collection / tree[i].Total_Due) * 100;

            tree[i]["children"].push({
              CollectionExecutive: arr[j].CollectionExecutive,
              Name_type: arr[j].LoanAcNo,
              Type_Which: arr[j].Branch_Name,
              DPD_Days: arr[j].DPD_Days,
              OP_OD: arr[j].OP_OD,
              OP_FU_Current: arr[j].OP_FU_Current,
              OP_FU_Next: arr[j].OP_FU_Next,
              Current_Due: arr[j].Current_Due,
              Total_Due: arr[j].Total_Due,
              Collection_OP_OD: arr[j].Collection_OP_OD,
              Collection_Current: arr[j].Collection_Current,
              Collection_Future: arr[j].Collection_Future,
              ODInterestBalance: arr[j].ODInterestBalance,

              Total_Collection: arr[j].Total_Collection,

              CL_OD: arr[j].CL_OD,
              LastReceivedDate: arr[j].LastReceivedDate,
              Efficiency_OD: (arr[j].Collection_OP_OD / arr[j].OP_OD) * 100,
              Efficiency_Current:
                (arr[j].Collection_Current / arr[j].Current_Due) * 100,
              Efficiency_Total:
                (arr[j].Total_Collection / arr[j].Total_Due) * 100,
              Customer_PhoneNo: arr[j].Customer_PhoneNo,
              Customer_Address: arr[j].Customer_Address,
              ProductId: arr[j].ProductId,
              Product_Name: arr[j].Product_Name,
              Loan_Acc_Category: arr[j].Loan_Acc_Category,
              CustomerName: arr[j].CustomerName,
              Total_OP_Future: arr[j].Total_OP_Future,
              DueDate: arr[j].DueDate,
              DueEMI: arr[j].DueEMI,
              SourceType: arr[j].SourceType,
              SourceName: arr[j].SourceName,
              SalesExecutive: arr[j].SalesExecutive,
              VehicleNo: arr[j].VehicleNo,
              Alternate_PhoneNo: arr[j].Alternate_PhoneNo,
              Co_Borrower_Name: arr[j].Co_Borrower_Name,
              Co_Borrower_MobileNo: arr[j].Co_Borrower_MobileNo,
              guarantor_Name: arr[j].guarantor_Name,
              guarantor_MobileNo: arr[j].guarantor_MobileNo,
              PDC_Type: arr[j].PDC_Type,
              Companybank: arr[j].Companybank,
              UmrnNumber: arr[j].UmrnNumber,
              EMIFrequency: arr[j].EMIFrequency,
              LoanDate: arr[j].LoanDate,
              FirstEMIDate: arr[j].FirstEMIDate,
            });
          }
        }
      }
    }

    this.totaltypewise(tree);
    return tree;
  }

  onSearchCollectionEfficiency() {
    if (this.LPI == true)
      this.displayedCTreeolumns = [
        "Name_type",
        "CustomerName",
        "Product_Name",
        "Loan_Acc_Category",
        "LoanDate",
        "FirstEMIDate",
        "Type_Which",
        "DPD_Days",
        "OP_OD",
        "OP_FU_Current",
        "OP_FU_Next",
        "Current_Due",
        "DueDate",
        "DueEMI",
        "Total_Due",
        "Collection_OP_OD",
        "Collection_Current",
        "Collection_Future",
        "Total_Collection",
        "Efficiency_Total",
        "Efficiency_Current",
        "Efficiency_OD",
        "CL_OD",
        "LastReceivedDate",
        "ODInterestBalance",
        "SourceType",
        "SourceName",
        "SalesExecutive",
        "VehicleNo",
        "Customer_PhoneNo",
        "Alternate_PhoneNo",
        "Customer_Address",
        "Co_Borrower_Name",
        "Co_Borrower_MobileNo",
        "guarantor_Name",
        "guarantor_MobileNo",
        "PDC_Type",
        "Companybank",
        "UmrnNumber",
        "EMIFrequency",
        "LoanDate",
        "FirstEMIDate",
      ];
    else
      this.displayedCTreeolumns = [
        "Name_type",
        "CustomerName",
        "Product_Name",
        "Loan_Acc_Category",
        "LoanDate",
        "FirstEMIDate",
        "Type_Which",
        "DPD_Days",
        "OP_OD",
        "OP_FU_Current",
        "OP_FU_Next",
        "Current_Due",
        "DueDate",
        "DueEMI",
        "Total_Due",
        "Collection_OP_OD",
        "Collection_Current",
        "Collection_Future",
        "Total_Collection",
        "Efficiency_Total",
        "Efficiency_Current",
        "Efficiency_OD",
        "CL_OD",
        "LastReceivedDate",
        "SourceType",
        "SourceName",
        "SalesExecutive",
        "VehicleNo",
        "Customer_PhoneNo",
        "Alternate_PhoneNo",
        "Customer_Address",
        "Co_Borrower_Name",
        "Co_Borrower_MobileNo",
        "guarantor_Name",
        "guarantor_MobileNo",
        "PDC_Type",
        "Companybank",
        "UmrnNumber",
        "EMIFrequency",
      ];
    this.showSpinner = true;
    this.reportsService
      .Report_Collection_Efficiency({
        LoginUserId: this.currentUser.userId,
        From: this.FromDate,
        To: this.ToDate,
        LoanAcNo: this.LoanNo,
        LoanAccountCategory: this.LoanAccountCategory,
        LPI: this.LPI == true ? 1 : 0,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        console.log("RES", res);
        if (res.length > 0) {
          this.JsonData = res.filter(
            (item) =>
              item.OP_OD > 0 ||
              item.Total_OP_Future > 0 ||
              item.OP_FU_Current > 0 ||
              item.OP_FU_Next > 0 ||
              item.Current_Due > 0 ||
              item.Total_Due > 0 ||
              item.Collection_OP_OD > 0 ||
              item.Collection_Current > 0 ||
              item.Collection_Future > 0 ||
              item.ODInterestBalance > 0 ||
              item.Total_Collection > 0 ||
              item.CL_OD > 0
          );

          //console.log("this.JsonData", this.JsonData);
          this.dataSourceTree = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
          );

          var tree = this.unflatten(this.JsonData);
          this.dataSourceTree.data = tree;
          this.ExpandBtn = true;
          this.CollaspeBtn = false;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
          this.dataSourceTree = null;
        }
      });
  }

  DateChange() {
    if (this.FromDate > this.ToDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Date should be greater than From Date.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.ToDate = new Date();
        });
    }
  }
  totaltypewise1(type) {
    //   console.log("dataSourceTree", this.dataSourceTree.filteredData)

    if (this.dataSourceTree != null) {
      let sum = 0;
      for (
        let i = 0;
        i < this.dataSourceTree._treeControl.dataNodes.length;
        i++
      ) {
        if (
          this.dataSourceTree._treeControl.dataNodes[i].CustomerName == "" ||
          this.dataSourceTree._treeControl.dataNodes[i].CustomerName ==
            undefined
        )
          sum += Number(this.dataSourceTree._treeControl.dataNodes[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
  }

  calculateTotalEfficiency(): number {
    const totalCollection: number = Number(
      this.totaltypewise1("Total_Collection")
    );
    const totalDue: number = Number(this.totaltypewise1("Total_Due"));

    if (totalDue === 0) {
      console.error("Total Due is zero, cannot calculate efficiency.");
      return 0;
    }

    const efficiency: number = (totalCollection / totalDue) * 100;

    return parseFloat(efficiency.toFixed(2));
  }

  showEfficiencyTotalPercent(): void {
    const efficiencyTotal = this.calculateTotalEfficiency();
    this.efficiencyDisplayTotal = `${efficiencyTotal}%`;
  }

  calculateEfficiencyCurrent(): number {
    const totalCollectionCurrent: number = Number(
      this.totaltypewise1("Collection_Current")
    );
    const totalCurrentDue: number = Number(this.totaltypewise1("Current_Due"));

    if (totalCurrentDue === 0) {
      console.error("Total Due is zero, cannot calculate efficiency.");
      return 0;
    }

    const efficiencyCurrent: number =
      (totalCollectionCurrent / totalCurrentDue) * 100;

    return parseFloat(efficiencyCurrent.toFixed(2) + "%");
  }

  calculateEfficiencyOD() {
    const totalCollection_OP_OD: number = Number(
      this.totaltypewise1("Collection_OP_OD")
    );
    const totalOP_OD: number = Number(this.totaltypewise1("OP_OD"));

    if (totalOP_OD === 0) {
      console.error("Total Due is zero, cannot calculate efficiency.");
      return 0;
    }

    const efficiencyCurrent: number =
      (totalCollection_OP_OD / totalOP_OD) * 100;

    return parseFloat(efficiencyCurrent.toFixed(2) + "%");
  }

  totaltypewise(row) {
    var aa;

    var child;
    row.forEach((obj: any) => {
      child = obj.children.filter((r) => r.BranchId === obj.BranchId);

      child.forEach((data: any) => {
        //console.log("data ::", data);
      });

      //console.log("child ::", child.OP_OD);
    });
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcelDetails(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "collection_efficency_detail"
    ) as HTMLTableElement;
    const worksheetName = "COLLECTION EFFICIENCY DETAIL"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportExcelSummary(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "COLLECTION EFFICIENCY SUMMARY",
      sheet: "COLLECTION EFFICIENCY SUMMARY",
    });
  }

  OnClickExpandAll() {
    this.ExpandBtn = false;
    this.CollaspeBtn = true;
    this.treeControl.expandAll();
  }
  OnClickCollaspeAll() {
    this.ExpandBtn = true;
    this.CollaspeBtn = false;
    this.treeControl.collapseAll();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  selectRow(row: any): void {
    this.selectedRow = row;
  }
}
