
<div [hidden]="!showreceiptForm" class="receiptForm">
  <h1 class="fs-12 h-b align-items-center">Login Fee Receipt</h1>
  <form #f="ngForm" (ngSubmit)="searchLoginFeeReceipt()" novalidate >
  

    <div class="row m-0 font-size-12 align-items-center">
      <div class="col-md-6 p-1"> <span class="required-lable">File No.</span> </div>
      <div class="col-md-6 p-1">
        <input required type="text" #refFileId="ngModel" numbersOnly
               [ngClass]="{ 'is-invalid': f.submitted && refFileId.invalid, 'alert-warning': refFileId.invalid  && (refFileId.dirty || refFileId.touched || refFileId.untouched) }"
               [(ngModel)]="FileId" placeholder="File No." name="FileId" id="FileId"
               class="form-control input-text-css">
      </div>
    </div>
    <div class="row m-0 font-size-12 align-items-center">
      <div class="col-md-6 p-1"> <span class="required-lable"> Mobile No.</span> </div>
      <div class="col-md-6 p-1">
        <input required type="text" #refMobileNo="ngModel" numbersOnly maxlength="10" minlength="10"
               [ngClass]="{ 'is-invalid': f.submitted && refMobileNo.invalid, 'alert-warning': refMobileNo.invalid  && (refMobileNo.dirty || refMobileNo.touched || refMobileNo.untouched) }"
               [(ngModel)]="MobileNo" placeholder="Mobile No." name="MobileNo" id="MobileNo"
               class="form-control input-text-css">
      </div>
    </div>
    <div class="modal-footer">

      <button type="button" class="btn font-size-12 button-btn" [disabled]="!f.form.valid" (click)="searchLoginFeeReceipt()"> Search </button>

    </div>
  </form>
</div>
<div id="container" *ngIf="receiptDetail" [hidden]="!showreceiptDetail">
  <button type="button" class="font-size-12 button-btn" [class.spinner]="loading" (click)="printLetter()">
    Print
  </button>
  <div id="pdfReceiptDownload">
    <div>
      <p style="text-align: center;letter-spacing: 7px;">OFFICE COPY</p>
      <p  style="text-align: center;">In case Contract is Terminated this Receipt is issued strictly without prejudice</p>
      <h3 style="text-align: center;">{{receiptDetail.CompanyName}}</h3>
      <p  style="text-align: center;">{{receiptDetail.CompanyAddress}}</p>
      <p  style="text-align: center;">Ph. {{receiptDetail.CompanyPhone}}  Email. {{receiptDetail.CompanyEmail}}</p>
      <p  style="text-align: center;text-decoration: underline;"><i>RECEIPT</i></p>

    </div>
    <div style="font-style: italic;margin: 10px;">
      <div  style="display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;">
        <p style="float: left;flex: 0 0 66.66666667%;max-width: 66.66666667%;">Receipt No:{{receiptDetail.ReceiptNo}}</p>
        <p style="float: right;width: 100%;flex: 0 0 33.33333333%;max-width: 33.33333333%;">Dated:{{receiptDetail.CollectionDate}}</p>

      </div>
      <div style="display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;">
        <p style="float: left;width: 100%;flex: 0 0 33.33333333%;max-width: 33.33333333%;">Loan File No: {{receiptDetail.LoanFileNo}}</p>
      </div>

      <hr />
      <div>
        <p>Received with thanks from: {{receiptDetail.Customer}}</p>
        <p>Address: {{receiptDetail.CustomerAddress}}</p>
        <p>a sum of Rs. {{receiptDetail.FeeAmount}} -towards login fee</p>
        <p>By: Cash/Cheque/DD no.{{receiptDetail.FeeCollectedBy}}</p>
      </div>
      <div>
        <div style="text-align:right">For {{receiptDetail.CompanyName}}</div>
        <div style="text-decoration: underline;padding: 19px;box-sizing: border-box;border: 2px solid #565656;border-radius: 5px;width: 100px;"> ****{{receiptDetail.FeeAmount}}</div>

      </div>

    </div>

  </div>
</div>
