<div>
  <div style="width: 100%; float: left; text-align: center; padding-top: 50px;">
    <!-- <img src="../../assets/images/logo.png" style="width: 30%;" /> -->
    <img src="{{ BasePath }}logo.png" style="width: 30%;" />
  </div> 
  <br />
  <div style="font-size: x-large; clear: both; text-align: center; color: #2a8290; padding-top: 25px;">
    POWERED BY FINNAUX TECHSOLUTIONS PRIVATE LIMITED
  </div>
</div>
