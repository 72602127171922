import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";

import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";

@Component({
  selector: "app-court-list",
  templateUrl: "./court-list.component.html",
  styleUrls: ["./court-list.component.scss"],
})
export class CourtListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  dataSource: any;
  loading: boolean = false;
  FilterStateId: any = "";

  displayedColumns: string[] = [
    "Id",
    "State_Name",
    "District_Name",
    "Court_Name",
    "Action",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  RequestModel: RequestModel = new RequestModel();
  DistrictDropDown: any;
  FilterStateList: any = [];

  SearchId: any = 0;
  SearchBy: any = "List";

  CourtModel: any = [];
  JsonData: any;

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Court List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetCourtList();
    this.GetState();
  }

  GetState() {
    this._MasterService.GetState().subscribe((result) => {
      this.FilterStateList = result;
    });
  }

  getDistrictList() {
    this.RequestModel.StateId =
      this.CourtModel.StateId == "" ? 0 : parseInt(this.CourtModel.StateId);
    this._MasterService.GetDistricts(this.RequestModel).subscribe((result) => {
      console.log("GetDistricts", result);
      this.DistrictDropDown = JSON.parse(JSON.stringify(result));
    });
  }

  GetCourtList() {
    this._MasterService
      .Get_Court_Establishment({ Id: this.SearchId, SearchBy: this.SearchBy })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData = res;
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.dataSource = null;
        }
      });
  }

  OnAddCourt() {
    this.CourtModel.Id = 0;
    this.CourtModel.StateId = "";
    this.CourtModel.DistrictId = "";
    this.CourtModel.Court_Name = "";
    this.CourtModel.SearchBy = "Insert";
    $("#AddCourt").modal("show");
    $("#AddCourt").css("z-index", "1050");
  }

  OnClose() {
    $("#AddCourt").modal("hide");
  }

  OnSaveCourt() {
    this._MasterService
      .Save_Court_Establishment({
        StateId: this.CourtModel.StateId,
        DistrictId: this.CourtModel.DistrictId,
        Court_Name: this.CourtModel.Court_Name,
        Id: this.CourtModel.Id,
        LoginUserId: this.currentUser.userId,
        SearchBy: this.CourtModel.SearchBy,
      })
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetCourtList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnEditCourt(row) {
    console.log("row", row);
    this.CourtModel.Id = row.Id;
    this.CourtModel.StateId = row.StateId;
    this.CourtModel.Court_Name = row.Court_Name;
    this.CourtModel.SearchBy = "Update";

    this.getDistrictList();
    this.CourtModel.DistrictId = row.DistrictId;

    $("#AddCourt").modal("show");
    $("#AddCourt").css("z-index", "1050");
  }

  OnDeleteCourt(row: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete this member.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.CourtModel.Id = row.Id;
          this.CourtModel.StateId = row.StateId;
          this.CourtModel.Court_Name = row.Court_Name;
          this.CourtModel.SearchBy = "Delete";
          this.CourtModel.DistrictId = row.DistrictId;
          this.OnSaveCourt();
        }
      });
  }
}
