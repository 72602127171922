<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-2">
      <span>Status</span>
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="LMS_Get_DV_List()"
        [(ngModel)]="status"
      >
        <option value="Pending">Pending</option>
        <option value="Authorized">Authorized</option>
        <option value="Reverted">Reverted</option>
      </select>
    </div>

    <div class="col-md-3">
      <span> Source Type </span>
      <select
        name="SelectSourceType"
        id="SelectSourceType"
        #refSelectSourceType="ngModel"
        (change)="Get_PartnerForDropdown()"
        [(ngModel)]="SelectSourceType"
        class="form-control input-text-css"
      >
        <option value="">Select Source Type</option>
        <option
          *ngFor="let lead of leadSourceDropdown"
          [value]="lead.Lead_Source"
        >
          {{ lead.Lead_Source }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <div
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive'
        "
      >
        <span> Source Name</span>
        <select
          name="LeadSourceName"
          id="LeadSourceName"
          #refLeadSourceName="ngModel"
          [(ngModel)]="LeadSourceName"
          class="form-control input-text-css"
          (change)="LMS_Get_DV_List()"
        >
          <option value="">Select Lead Reference</option>
          <option
            *ngFor="let partner of partnerDropdown"
            [value]="partner.PartnerId"
          >
            {{ partner.Partner_Name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
          [hiddenColumns]="[displayedColumns.indexOf('Action')]"
        >
          <ng-container matColumnDef="LoanId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                (click)="goToDetail(row)"
                aria-label="true"
                style="color: blue; cursor: pointer"
                >{{ row.LoanNo }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_Executive">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Executive
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Executive }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAmount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="VoucherNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Voucher No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.VoucherNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="VoucherDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Voucher Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.VoucherDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CreatedBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Created By
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CreatedBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="SourceType">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Source Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.SourceType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="SourceName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Source Name
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.SourceName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AuthorizedBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Authorized By
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AuthorizedBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header j-c-center"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                aria-label="true"
                (click)="goToDetail(row)"
              ></i>
              <i
                class="fa fa-print"
                *ngIf="status == 'Authorized'"
                style="
                  padding-left: 5px;
                  cursor: pointer;
                  color: green;
                  font-size: 16px;
                "
                aria-label="true"
                (click)="Print(row)"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="dv_authorize_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Authorized By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
  </tr>

  <tr *ngFor="let x of dvList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.LoanNo }}</td>
    <td>{{ x.LoanDate }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.VoucherNo }}</td>
    <td>{{ x.VoucherDate }}</td>
    <td>{{ x.CreatedBy }}</td>
    <td>{{ x.AuthorizedBy }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
  </tr>
</table>
