import { Component, OnInit , Injectable} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { QuickLoanService } from '../../_QuickLoan/services/quickLoan.service';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl as constantUrl } from 'src/app/Shared/constantUrl' ;
// Excel export code start here
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
// Excel export code end here
@Component({
  selector: 'app-reports-attendance',
  templateUrl: './reports-attendance.component.html',
  styleUrls: ['./reports-attendance.component.scss']
})
export class ReportsAttendanceComponent implements OnInit {
  
  showSpinner: boolean = false;
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date(); 
  CurrentDate: any = new Date();
  JsonData: any[] = [];
  JsonDataEmp: any[] = [];
  JsonDATA: any[] = [];
 
 
  EmployeeNameList:any=[];
  row:any=[];
  SelectedType:any='';
  SelectedEmployee:any='';
  maxDate: any;
  minDate: any;
  apiDocUrl=constantUrl.apiProfilePicUrl
  currentUser:any;
  // selectedId:any;
  dataSource: any;
  displayedColumns: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
 
                    



  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private quickLoanService :QuickLoanService,
    private snackBar: MatSnackBar
 ) { 
    let now = moment();
    this.ToDate = moment(now, 'DD/MM/YYYY').toDate();
    this.FromDate = moment(now, 'DD/MM/YYYY').add(0, 'days').toDate();
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Attendance Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getEmployeeList();
  }
  getEmployeeList(){
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.quickLoanService.LOS_GetManagerDashboardByTeam({ ManagerId: this.currentUser.userId, TeamMemberId: 0 }).subscribe((res: any) => {

      this.EmployeeNameList=res.Item1;
    })

  }
  
  DateChange() {
    if (this.SelectedType=='ByEmployee') {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Date will be greater than to From Date.", ...this.configSuccess });
        this.FromDate = '';
        this.ToDate = '';
      }
    }
  }
  applyFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  onSearchAttendance(){
    // this.JsonData=res;
    if(this.SelectedType=="ByTeam"){

      this.showSpinner = true;
      this.reportsService.Reports_Attendance_ByTeam({ ManagerId:this.currentUser.userId, Todate: this.ToDate })
        .subscribe((res: any) => {
          if(res.length>0){
            this.showSpinner = false;
          this.displayedColumns = [ 'EmpId','EMP_Name','Role_Name','Attendance_Date','In_Time','In_LatLong','In_Attachment','OUT_Time',
            'OUT_LatLong','OUT_Attachment','TotalTime'
          ];
          // console.log(res)
          this.JsonData = JSON.parse(JSON.stringify(res));

          this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          this.showSpinner = false;
        }
        });
    }else{
      this.showSpinner = true;
      this.reportsService.Reports_Attendance_ByEmployee({ EmpId:this.SelectedEmployee, Fromdate:this.FromDate,Todate: this.ToDate })
        .subscribe((res: any) => {
          if(res.length>0){
            this.showSpinner = false;
          this.displayedColumns = [ 'Attendance_Date','In_Time','In_LatLong','In_Attachment','OUT_Time',
            'OUT_LatLong','OUT_Attachment','TotalTime'
          ];
          // console.log(res)
          this.JsonData = JSON.parse(JSON.stringify(res));
          // this.JsonDataEmp = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          
        }
        });
    }
  }
   // Excel export in backend start here
   public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, excelFileName + new  Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcel(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("reports_attendance") as HTMLTableElement;
    const worksheetName = 'ATTENDANCE REPORTS'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();}
  
    
}


  
  
