import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { TimeScale } from "chart.js";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DialogService } from "src/app/Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-file-checking-master",
  templateUrl: "./file-checking-master.component.html",
  styleUrls: ["./file-checking-master.component.scss"],
})
export class FileCheckingMasterComponent implements OnInit {
  showSpinner: boolean = false;
  FilterProductId: any = "";
  FilterProductList: any = [];
  loading: boolean = false;
  productDropdown: any;
  fileCheckingList: any;
  dataSource: any;
  HeaderText: string = "Add";
  RequestModel: RequestModel = new RequestModel();
  SaveFileCheckingMastersRequestModel: RequestModel = new RequestModel();
  EditFileCheckingMastersRequestModel: RequestModel = new RequestModel();
  DeleteFileCheckingMastersRequestModel: RequestModel = new RequestModel();

  fileCheckingName: any;
  fileType: any;

  QuestionTypeOptions: any = [];

  displayedColumns: string[] = ["Id", "Name", "LoanProduct", "EditAction"];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  FIQuestion: any;
  FIQuestionType: any = "Selection";
  FIQuestionOptions: any = "";
  FIQuestionProducts: any = "";
  FIQueId: number;
  currentUser: any;
  productList: string;
  dialogreturn: any;
  Id: number;
  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    public snackBar: MatSnackBar,
    private dialog: DialogService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("File Checking Master");
    this.getProductDropdown();
    this.GetFileCheckingMaster_List();
  }

  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));
      this.productDropdown.map((item) => {
        item.isAcive = false;
      });
      this.showSpinner = false;
    });
    this.showSpinner = false;
  }

  GetFileCheckingMaster_List() {
    this.showSpinner = true;

    this._MasterService
      .GetFileCheckingMaster_List({ ProductId: this.FilterProductId || 0 })
      .subscribe((result) => {
        console.log("Result of get api call", result);
        this.fileCheckingList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.fileCheckingList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDeleteFileCheckingMaster(row) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == true) {
          this.DeleteFileCheckingMastersRequestModel.Id = row.Id;
          this.DeleteFileCheckingMastersRequestModel.LoginUserId =
            this.currentUser.userId;
          this._MasterService
            .Delete_File_Checking(this.DeleteFileCheckingMastersRequestModel)
            .subscribe((response) => {
              if (response[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.GetFileCheckingMaster_List();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  onEditFileCheckingMaster(row) {
    this.EditFileCheckingMastersRequestModel.Id = row.Id;

    this.HeaderText = "Edit";
    this._MasterService
      .GetFileCheckingMaster_Details(this.EditFileCheckingMastersRequestModel)
      .subscribe((response: any) => {
        console.log("Result", response);
        this.FIQueId = response?.Item1[0].Id;
        this.FIQuestion = response.Item1[0].Name;

        this.productDropdown.map((item) => {
          response.Item2.map((item1) => {
            if (item.ProductId == item1.ProductId) {
              item.isAcive = true;
            }
          });
        });

        response.Item3.map((item) => {
          this.QuestionTypeOptions.push({
            FI_Questions_Option: item.Options,
          });
        });
      });
    $("#addNewFileCheckingMasterData").modal("show");
    $("#addNewFileCheckingMasterData").css("z-index", "1050");
  }

  onOpenFileCheckingMasterModal() {
    $("#addNewFileCheckingMasterData").modal("show");
    $("#addNewFileCheckingMasterData").css("z-index", "1050");
  }

  // onChangeQuestionType() {
  //   this.QuestionTypeOptions = [{ FI_Questions_Option: "" }];
  // }

  onAddNewRow() {
    this.QuestionTypeOptions.push({ FI_Questions_Option: "" });
    // console.log("add new row", this.fileTypeOptions);
  }

  onDeleteRow(selectedIndex) {
    this.QuestionTypeOptions.splice(selectedIndex, 1);
  }

  onSaveFileCheckingMaster() {
    this.FIQuestionOptions = Array.prototype.map
      .call(this.QuestionTypeOptions, function (item) {
        return item.FI_Questions_Option;
      })
      .join(",");

    let FilterProductData = this.productDropdown.filter(
      (a) => a.isAcive == true
    );

    this.FIQuestionProducts = Array.prototype.map
      .call(FilterProductData, function (item) {
        return item.ProductId;
      })
      .join(",");

    this.SaveFileCheckingMastersRequestModel.Id = this.FIQueId;
    this.SaveFileCheckingMastersRequestModel.Name = this.FIQuestion;

    this.SaveFileCheckingMastersRequestModel.Options = this.FIQuestionOptions;
    this.SaveFileCheckingMastersRequestModel.Products = this.FIQuestionProducts;
    this.SaveFileCheckingMastersRequestModel.LoginUserId =
      this.currentUser.userId;

    this._MasterService
      .Save_FileCheckingMaster(this.SaveFileCheckingMastersRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          console.log("Data Saved");
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetFileCheckingMaster_List();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        $("#addNewFileCheckingMasterData").modal("hide");
      });
  }

  OnClose() {
    this.FIQueId = 0;
    this.FIQuestion = "";

    this.FIQuestionType = "Selection";
    this.FIQuestionOptions = "";
    this.FIQuestionProducts = "";
    this.QuestionTypeOptions = [];
    this.productDropdown.map((item) => {
      item.isAcive = false;
    });

    this.HeaderText = "Add";
    $("#addNewFileCheckingMasterData").modal("hide");
  }
}
