import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { constantUrl } from '../../Shared/constantUrl';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { LmsService } from '../../_Lms/services/lms.service';
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { LosService } from '../../_LOS/services/los.service';
import { QuickLoanService } from '../services/quickLoan.service';
declare var $: any;


@Component({
  selector: 'app-quickloan-valuation-process',
  templateUrl: './quickloan-valuation-process.component.html',
  styleUrls: ['./quickloan-valuation-process.component.scss']
})
export class QuickLoanValuationProcessComponent implements OnInit {
  @ViewChild('CArea') ConstArea: ElementRef;
  @ViewChild('CAddArea') ConstAddArea: ElementRef;

  private data: any; private _MasterService; showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  applicationDetail: any = '';
  InvoiceNo: any = ''; DealerContactPerson: any = '';
  InvoiceValue: any = ''; DealerContactNo: any = '';
  IssuedInFavourOf: any = ''; Remark: any = '';
  FuelTypeOption: any;
  Collateral: any;
  loading: boolean = false; CurrentDate: any = new Date();
  isUpdateValuation: boolean = true;
  // register new role
  EmployeeId: any = 0;
  Addi_ProductCat: boolean = false;
  SelectRoleId: any = ''; FirstName: any = ''; LastName: any = '';
  SelectGender: any = ''; DateOfBirth: any = ''; PresentAddress: any = '';
  PermanentAddress: any = ''; PhoneNo: any = ''; AlternetPhoneNo: any = '';
  EnterEmail: any = ''; AadharNo: any = ''; EmployeeLoginId: any = '';
  EmployeePassword: any = ''; EmpManagerId: any = '';
  UploadImageBase64: any = '';
  UploadImageName: string = '';
  profilePicture: string = '';
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  duplicateData: any;

  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  // copy employee
  CopyEmployeeDropdown: any = []; vehicleCategoryData: any = [];
  CopyEmployeeId: any = ''; vehicleModelData: any = [];
  isShowCopyEmployee: boolean = false; ManufactureData: any = [];
  commercialVehicle: any = 'Commercial';
  SearchReportDoneOn: any;
  SelectVehicleCategory: any = ''; FuelType: any = ''; RoadTaxUpto: any = '';
  RegistationExpiryDate: any = ''; SelectFuelType: any = ''; Color: any = '';
  FitnessUpto: any = ''; Dealer: any = '0'; PermitUpto: any = ''; VehicleCost: any = '';
  Route: any = ''; EngineNo: any = ''; ChassisNo: any = ''; KeyNo: any = ''; DeliveryorderNo: any = '';
  DeliveryorderDate: any = ''; DoIssuedFavour: any = ''; DealerContact: any = ''; LTVBase: any = '';
  ValuationGrid: any = ''; ValuationDoneBy: any = '0'; GridValue: any = ''; PermitNo: any = '';
  PermitIssuedDate: any = ''; PermitExpireDate: any = ''; PermitNature: any = ''; RouteDescription: any = '';
  VehicleManufacture: any = '0'; VehicleModel: any = '0'; Variant: any = ''; ManufactureYear: any = '';
  VehicleRegistationNo: any = ''; RegistationDate: any = ''; InvoiveNo: any = ''; InvoiveDate: any = ''; ValuationType: any = ''; OtherValuationType: any = ''; OtherValuationAmount: any = '';

  // property form
  PropertyOwnerName: any = ''; AddressOfProperty: any = ''; Location: any = '';
  TypeOfProperty: any = ''; NatureOfProperty: any = ''; OwnershipDocument: any = '';
  OwnershipType: any = ''; UnitOfMeasurement: any = ''; TotalArea: any = '';
  ConstructedArea: any = ''; ValutionAmount: any = ''; SearchReportDoneBy: any = '';
  TypeOfMortgage: any = ''; MortgageSingedBy: any = '';
  OwnershipTypeList: any; NatureOfPropertyList: any = ''; OwnershipDocumentList: any = '';
  TypeOfPropertyList: any = '';
  EstimationAmount: any = ''; ValuationRemarkDoc: any = ''; SearchReportRemarkDoc: any = ''; ValuationRemarkDoc1: any = '';
  Valuation_Technical: any;
  propertyForm: string = '';
  usedVehicle: boolean = false;
  newVehicle: boolean = true;
  Showdditional: boolean = false;
  ConsumerGoldForm: any;
  PersonalForm: any;
  AgricultureForm: any;
  today: Date = new Date();
  // LoanAcNo: any;
  vehicleCategoryId: any;
  LoanId: any;
  type: any;
  ButtonName: any = 'Save';
  CustomerList: any = [];
  DealerList: any;
  isUpdate: boolean = false;
  isEdit: boolean = false;
  AssetTableView: boolean = false;
  Info: any;
  AssetsForm: any = 'Form';
  saveBtn: boolean = true;
  updateBtn: boolean = false;
  nextBtn: boolean = true;
  customerModel: any = {};
  latitude: any = '';
  longitude: any = '';
  SearchReportRemark: any;
  ValuationRemark: any;
  Collateral_Type: any;
  ValuationDate: any;
  InvoiceDate: any;
  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource:any;

  displayedColumns: any[] = ['Collateral_Type', 'Collateral', 'ValuationType', 'ValuationAmount', 'ValuationDate', 'View_Detail'];
  dataSource: any;
  getLmsHeaderProduct: string;
  LoanAccounting: Object;
  ProductList: any = [];
  ProductCatId: any = '0';
  AgricultureForms: string;
  AssetsForms: string;
  PersonalForms: string;
  ConsumerGoldForms: string;
  propertyForms: string;
  vehicleCategoryIds: any;
  AssetsList: any = [];
  ProductCategoryDropdown: any = [];
  CollectionExecutiveData: any = [];
  stateDropdown: any[] = [];
  districkDropdownReg: any[] = [];
  tehasilDropdownReg: any[] = [];
  NextRoute: any;
  CRCTab: any;
  FITVRTab: any;
  ChequeDetailTab: any;
  DocModel: any[] = [];
  DocModel1: any = {};
  vehicle_type: any = 'New';
  SearchDocData: any;
  ValuationDocData: any;
  ValuationDocData1: any;
  SearchReportDoneByOther: any;
  ValuationDoneByOther: any;
  //Gold
  Item: any = {};
  dataAssetItemSource: any;
  displayedAssetItemColumns: string[] = [];
  ItemArr: any = [];
  AssetsAttachment: any = [{}];
  assetTotal: any;
  assetModel: any = {};
  displayedViewAssetItemColumns: any;
  dataviewAssetItemSource: any;
  ID: any = 0;
  PrimaryCount: any = 0;
  val_sum: any = 0;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  dataValuationSource: any;
  PendingValuation: any = [];
  displayedValuationColumns: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  fixrate24ct: any = 100 / 24;
  IsDisabled: boolean = false;

  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;
  Application_Date: any = new Date();
  AllowExtension: any = constantUrl.AllowExtension;

  constructor(
    private _Route: Router,
    private activedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private LmsService: LmsService,
    private DecriptService: EncrDecrService,
    private losService: LosService,
    private _QuickLoanService: QuickLoanService,
  ) {
    this._MasterService = MasterService;

  }


  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    this.Application_Date = new Date(this.loanSummary.Application_Date.split("-").reverse().join("-") + ' 00:00:00');
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
    this.Item.PurityCT = 12;
    this.Item.ValulationAmount = '';
    this.Item.ItemType = '';

    this.displayedAssetItemColumns = [
      "Id", "ItemName", "ItemType", "Qty", "PurityCT", "GrossWt", "StoneWt", "OtherWt", "NetWt", "PureWt",
      "ValulationRate_PerCT", "ValulationAmount", "Action"
    ];
    this.displayedViewAssetItemColumns = [
      "Id", "ItemName", "ItemType", "Qty", "PurityCT", "GrossWt", "StoneWt", "OtherWt", "NetWt", "PureWt",
      "ValulationRate_PerCT", "ValulationAmount", "Image"
    ];

    this.updateBtn = false;
    this.isEdit = false;

    this.AssetsForm = '';
    this.LMS_GetAllUserAssetInfo();


    this.getProductDropdown();

    //this.getProductCategory();
    //this.getCustomerList();
    //this.getFuelType();
    //this.getDealerList();
    //this.getNatureOfProperty();
    //this.getOwnershipType();
    //this.getOwnershipDocument();
    //this.getTypeOfProperty();

    this.GetValuer();
    //this.GetStateDropdown()
    //this.LMS_GetAssetInfoMaster();
    //this.LMS_GetVehicleMaster();
  }
  setProductId(value) {
    this.getLmsHeaderProduct = value;
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  GetDistrickDropdownReg(event: any) {
    this.losService.GetDistrickDropdown({ StateID: parseInt(this.customerModel.Firm_RegState) }).subscribe((res: any) => {
      this.districkDropdownReg = res;
    });
  }
  GetTehasilDropdownReg(event: any) {
    this.losService.GetTehasilDropdown({ DistrictId: parseInt(this.customerModel.Firm_RegDistrict) }).subscribe((res: any) => {
      this.tehasilDropdownReg = res;
    });
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  onCopyPresentAdd(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.PermanentAddress = this.PresentAddress;
    } else {
      this.PermanentAddress = '';
    }
  }

  LMS_GetVehicleMaster() {
    //this.LmsService.Get_Lms_VehicleMaster().subscribe((response) => {
    //  this.vehicleCategoryData = response;
    //});
    this._MasterService.GetVehicleCategoryForDropdown({}).subscribe((response: any) => {
      this.vehicleCategoryData = response;
    });
  }

  LMS_GetVehicleModelMaster(event) {
    this.showSpinner = true;

    //this.LmsService.GET_VEHICLEMODEL_MASTER({ Vehicle_Category_Id: this.SelectVehicleCategory, Vehicle_Manufacture: this.VehicleManufacture }).subscribe((response) => {
    //  this.vehicleModelData = response;
    //});
    if ((typeof this.VehicleManufacture != 'undefined' && this.VehicleManufacture) && (typeof this.SelectVehicleCategory != 'undefined' && this.SelectVehicleCategory)) {
      this._MasterService.GetVehicleModelForDropdown({ ManufactureId: this.VehicleManufacture, CategoryId: this.SelectVehicleCategory }).subscribe((response: any) => {
        this.vehicleModelData = response;
      });
    } else {

      this.vehicleModelData = [];
    }
  }

  LMS_GetAssetInfoMaster() {
    this.showSpinner = true;
    //this.LmsService.Get_Lms_AssetInfo().subscribe((response) => {
    //  this.ManufactureData = response;
    //});
    this._MasterService.GetManufactureForDropdown({}).subscribe((response: any) => {
      this.ManufactureData = response;
    });
  }

  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
    });
  }
  getDealerList() {
    this.LmsService.GET_Partner_List({ Type: 'Dealer' }).subscribe((result) => {
      this.DealerList = JSON.parse(JSON.stringify(result));
    });
  }

  GetValuer() {
    this.losService.Get_PartnerForDropdown({ PartnerType: 'Valuer' }).subscribe((res: any) => {
      this.CollectionExecutiveData = res;
    });
  }

  LMS_GetAllUserAssetInfo() {
    let data = {
      Loan_Id: this.loanSummary.LoanId
    }
    this.LmsService.LMS_GetAllUserAssetInfo(data).subscribe((response: any) => {

      if (response.length > 0) {
        this.Showdditional = true;
        this.AssetsList = response;
        this.ID = response[0].AssetId;
       // console.log("response[0].SearchReportDoneBy", response[0].SearchReportDoneBy);
        //this.SearchReportDoneBy = response[0].SearchReportDoneBy;
        //this.SearchReportDoneOn = new Date(response[0].SearchReportDoneOn);
        //this.SearchReportRemark = response[0].SearchReportRemark;
        //this.SearchReportRemarkDoc = response[0].SearchDocData;


        //this.ValuationDoneBy = response[0].PValuationDoneBy;
        //this.ValuationDate = response[0].PValuationDate;
        //this.ValutionAmount = response[0].PValuationAmount;
        //this.ValuationType = response[0].PValuationType;
        //this.OtherValuationType = response[0].POtherValuationType;
        //this.OtherValuationAmount = response[0].POtherValuationAmt;
        //this.ValuationRemarkDoc = response[0].PValuationDoc;
        //this.ValuationRemark = response[0].PValuationRemark;
        //if (response[0].PValuationDoneBy) {
        //  this.isUpdateValuation = false;
        //  this.isEdit = true;
        //} else {
        //  this.isUpdateValuation = true;
        //  this.isEdit = false;
        //}
        let saveData = { JSON: '' }
        let data = {
          AssetInfo: {
            ProcessType: 'Valuation',
            Assets_Id: this.ID,
            AssetType: response[0].Collateral,
            LoanId: this.loanSummary.LoanId,
            loginUserId: JSON.parse(sessionStorage.getItem("currentUser")).userId
          }
        }
        let asset = JSON.stringify(data);
        saveData.JSON = asset;
        this._QuickLoanService.QuickLoan_GetAssets_Valuation_Technical(saveData).subscribe((res: any) => {
          if (res.length > 0) {
            this.Valuation_Technical = res;
            if (this.Valuation_Technical.filter(x => x.ProcessStatus == 'Completed')) {
              this.displayedValuationColumns = ["Id", "Process_VendorName", "Valuation_Type", "Valuation_Amount", "Valuation_Type2", "Valuation_Amount2", "ProcessDone_on", "Attachment1", "Attachment2", "Process_Remark"];
              this.dataValuationSource = new MatTableDataSource(this.Valuation_Technical.filter(x => x.ProcessStatus == 'Completed'));
            }
            this.PendingValuation = this.Valuation_Technical.filter(x => x.ProcessStatus == 'Pending')
            if (this.PendingValuation && this.PendingValuation.length > 0) {
              this.isUpdateValuation = false;
            } else {
              this.isUpdateValuation = true;
            }
          }
        });
        this.GetValuer();
        this.Collateral = response[0].Collateral;
        this.Collateral_Type = response[0].Collateral_Type;
        this.ViewData(response[0]);

        for (let i = 0; i < response.length; i++) {
          if (response[i]["Collateral_Type"] == "Primary Collateral" || response[i]["Collateral_Type"] == "Primary") {
            this.val_sum += Number(response[i]["ValuationAmount"]);
            this.PrimaryCount = i + 1;
          }
        }
      }
      this.OpenModel();
    });
  }

  selectVehicleCategory(event) {
    this.commercialVehicle = this.SelectVehicleCategory;
  }

  onCloseKYC() {
    $('#kycModel').modal('hide');
    $('#additionalcollateral').modal('hide');
  }
  fileChangeListenerAttachment(files: any, input: any) {
    if (!this.AssetsAttachment[this.AssetsAttachment.length - 1].Title) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter  title.", ...this.configSuccess });
      input.value = "";
      return;
    }

    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.AssetsAttachment.find((obj) => {
      return obj.DocFileName == _fn;
    });

    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Same file can not be allow again.", ...this.configSuccess });
      input.value = "";
      return;
    }

    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      input.value = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      input.value = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.AssetsAttachment[this.AssetsAttachment.length - 1].DocFileName = _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;
    _data.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    input.value = "";
    this.AssetsAttachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };
  }

  fileChangeListener(files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.ValuationRemarkDoc = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.ValuationDocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  fileChangeListener1(files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.ValuationRemarkDoc1 = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.ValuationDocData1 = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.AssetsAttachment.splice(i, 1);
  }
  removeFile(input: any) {
    input.value = "";
    this.ValuationRemarkDoc = "";
    this.ValuationDocData = "";
  }
  removeFile2(input: any) {
    input.value = "";
    this.ValuationRemarkDoc1 = "";
    this.ValuationDocData1 = "";
  }
  removeFile1(input: any) {
    input.value = "";
    this.SearchReportRemarkDoc = "";
    this.SearchDocData = "";
  }
  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {

    })
  }
  UploadCrcDoc() {
    this.showSpinner = true;
    for (let i = 0; i < this.DocModel.length; i++) {
      this.losService.UploadCrcDoc(this.DocModel[i]).subscribe(
        (res: any) => {

        },
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }
  //saveAssetInfo(Collateral_Type) {
  //  let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
  //  let saveData: any = {};
  //  this.AssetsAttachment.splice(this.AssetsAttachment.length-1, 1);
  //  console.log("this.AssetsAttachment", this.AssetsAttachment);
  //  let updateAssets;
  //  this.UploadCrcDoc();
  //  if (Collateral_Type == 'Primary Collateral' || Collateral_Type == 'Primary') {
  //    if (this.propertyForm == 'Property' || this.AddressOfProperty || this.TypeOfProperty) {
  //      // if Property detail adding in Primary Collateral
  //      if (this.ValuationDocData) {
  //        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.ValuationRemarkDoc, DocData: this.ValuationDocData });
  //      }
  //      if (this.SearchDocData) {
  //        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.SearchReportRemarkDoc, DocData: this.SearchDocData });
  //      }
  //      let GeoLocation = this.latitude + "," + this.longitude;
  //      saveData = {
  //        Property: this.dataSharingService.cleanObject({
  //          CustomerId: this.PropertyOwnerName,
  //          TypeofProperty: this.TypeOfProperty, NatureofProperty: this.NatureOfProperty, OwnershipType: this.OwnershipType,
  //          OwnershipDocument: this.OwnershipDocument, UnitofMeasurement: this.UnitOfMeasurement, Address: this.AddressOfProperty,
  //          TotalArea: this.TotalArea, ConstructedArea: this.ConstructedArea, TypeofMortgage: this.TypeOfMortgage,
  //          MortgageSingedBy: this.MortgageSingedBy, ValuationDoneBy: parseInt(this.ValuationDoneBy),
  //          ValuationAmount: this.ValutionAmount, GeoLocation: GeoLocation, LegalReportBy: localUser.userId,
  //          ValuationRemark: this.ValuationRemark, ValuationRemarkDoc: this.ValuationRemarkDoc, SearchReportRemark: this.SearchReportRemark, SearchReportRemarkDoc: this.SearchReportRemarkDoc,
  //          Collateral_Type: Collateral_Type, LoanId: this.loanSummary.LoanId, CreatedBy: localUser.userId,
  //          state: this.customerModel.Firm_RegState, District: this.customerModel.Firm_RegDistrict,
  //          tasil: this.customerModel.Firm_RegTehsil, ValuationDoneByOther: this.ValuationDoneByOther,
  //          SearchReportDoneByOther: this.SearchReportDoneByOther, SearchReportDoneBy: this.SearchReportDoneBy
  //        }),
  //        data: {
  //          LoanId: this.loanSummary.LoanId,
  //          AssetType: 'Property',
  //          AssetId: this.ID,
  //          CreatedBy: localUser.userId
  //        },
  //        Attachment:this.AssetsAttachment

  //      }
  //      //var RemoveAmount = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID }).ValuationAmount;
  //      var RemoveAmount = 0;
  //      var AmountList = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID });
  //      if (AmountList)
  //        RemoveAmount = AmountList.ValuationAmount;
  //      this.val_sum = Number(this.ValutionAmount) + Number(this.val_sum) - Number(RemoveAmount);
  //      let LTV = this.ValutionAmount == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //    }
  //    else if (this.propertyForm == 'Vehicle') {
  //      saveData = {
  //        Vehicle: this.dataSharingService.cleanObject({
  //          VehicleManufacture: parseInt(this.VehicleManufacture),
  //          VehicleCategory: parseInt(this.SelectVehicleCategory), VehicleModelName: parseInt(this.VehicleModel), Variant: this.Variant,
  //          FuelType: this.SelectFuelType, Color: this.Color, VehicleRegistationNo: this.VehicleRegistationNo,
  //          RegistationDate: this.RegistationDate,
  //          RegistationExpiryDate: this.RegistationExpiryDate,
  //          EngineNo: this.EngineNo, ChassisNo: this.ChassisNo, KeyNo: this.KeyNo,
  //          HPNEndorsment: null,
  //          DateofEndors: this.PermitIssuedDate,
  //          MefgYear: this.ManufactureYear,
  //          Category: '', VehicleCondition: '', DONo: this.DeliveryorderNo,
  //          DODoDate: this.DeliveryorderDate,
  //          EstimationAmount: this.EstimationAmount, InvoiceNo: this.InvoiceNo,
  //          InvoiceDate: this.InvoiceDate,
  //          InvoiceValue: this.InvoiceValue, DealerContactPerson: this.DealerContactPerson,
  //          DealerContactNo: this.DealerContactNo,
  //          DOIssuedinfavourof: this.IssuedInFavourOf, DealerId: parseInt(this.Dealer), Remark: this.Remark,
  //          ValuationDoneBy: this.ValuationDoneBy,
  //          ValuationDate: this.ValuationDate,
  //          ValuationAmount: this.ValutionAmount,
  //          ValuationType: this.ValuationType, OtherValuationType: this.OtherValuationType, OtherValuationAmt: this.OtherValuationAmount, FitnessAuthority: '',
  //          FitnessUpto: this.FitnessUpto,
  //          PermitAuthority: '', PermitUpto: this.PermitUpto,
  //          PermitType: '', ExtraInfo: '', Collateral_Type: Collateral_Type, LoanId: this.loanSummary.LoanId,
  //          CreatedBy: localUser.userId, RoadTaxUpto: this.RoadTaxUpto, VehicleCost: this.VehicleCost,
  //          Route: this.Route, vehicle_type: this.vehicle_type,
  //          Vehicle_Subtype: this.commercialVehicle
  //        }),
  //        data: {
  //          LoanId: this.loanSummary.LoanId,
  //          AssetType: 'Vehicle',
  //          AssetId: this.ID,
  //          CreatedBy: localUser.userId
  //        },
  //        Attachment: this.AssetsAttachment
  //      }
  //      console.log("this.AssetsList", this.AssetsList);
  //      var RemoveAmount = 0;
  //      var AmountList = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID });
  //      if (AmountList)
  //        RemoveAmount = AmountList.ValuationAmount;
  //      this.val_sum = Number(this.VehicleCost) + Number(this.val_sum) - Number(RemoveAmount);
  //      let LTV = this.VehicleCost == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //      if (saveData.Vehicle.RegistationDate) {
  //        saveData.Vehicle.RegistationDate = moment(this.RegistationDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.RegistationDate = null;
  //      }

  //      if (saveData.Vehicle.RegistationExpiryDate) {
  //        saveData.Vehicle.RegistationExpiryDate = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.RegistationExpiryDate = null;
  //      }

  //      if (saveData.Vehicle.DateofEndors) {
  //        saveData.Vehicle.DateofEndors = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.DateofEndors = null;
  //      }

  //      if (saveData.Vehicle.MefgYear) {
  //        saveData.Vehicle.MefgYear = moment(this.ManufactureYear).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.MefgYear = null;
  //      }

  //      if (saveData.Vehicle.DODoDate) {
  //        saveData.Vehicle.DODoDate = moment(this.RegistationDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.DODoDate = null;
  //      }

  //      if (saveData.Vehicle.FitnessUpto) {
  //        saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.FitnessUpto = null;
  //      }
  //      if (saveData.Vehicle.PermitUpto) {
  //        saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.PermitUpto = null;
  //      }
  //    }

  //    if (this.ConsumerGoldForm == 'ConsumerGoldForm') {
  //      saveData = this.dataSharingService.cleanObject(this.save_GoldAsset(Collateral_Type));
  //      var RemoveAmount = 0;
  //      var AmountList = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID });
  //      if (AmountList)
  //        RemoveAmount = AmountList.ValuationAmount;
  //      this.val_sum = Number(this.assetModel.TotalValulationAmount) + Number(this.val_sum) - Number(RemoveAmount);
  //      let LTV = this.val_sum == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //    }
  //    // if vehicle detail adding in Primary Collateral
  //    // if (this.VehicleManufacture || this.SelectVehicleCategory || this.commercialVehicle == 'Commercial' || this.commercialVehicle == 'Non-Commercial') {

  //    // } else {

  //    // }
  //  }

  //  if (Collateral_Type == 'Additional Collateral' || Collateral_Type == 'Additional') {

  //    // if Property detail adding in Primary Collateral
  //    // propertyForms
  //    if (this.propertyForms == 'Propertys' || this.AddressOfProperty || this.TypeOfProperty) {
  //      if (this.ValuationDocData) {
  //        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.ValuationRemarkDoc, DocData: this.ValuationDocData });
  //      }
  //      if (this.SearchDocData) {
  //        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.SearchReportRemarkDoc, DocData: this.SearchDocData });
  //      }
  //      let GeoLocation = this.latitude + "," + this.longitude;
  //      saveData = {
  //        Property: this.dataSharingService.cleanObject({
  //          CustomerId: this.PropertyOwnerName,
  //          TypeofProperty: this.TypeOfProperty, NatureofProperty: this.NatureOfProperty, OwnershipType: this.OwnershipType,
  //          OwnershipDocument: this.OwnershipDocument, UnitofMeasurement: this.UnitOfMeasurement, Address: this.AddressOfProperty,
  //          TotalArea: this.TotalArea, ConstructedArea: this.ConstructedArea, TypeofMortgage: this.TypeOfMortgage,
  //          MortgageSingedBy: this.MortgageSingedBy, ValuationDoneBy: parseInt(this.ValuationDoneBy),
  //          ValuationAmount: this.ValutionAmount, GeoLocation: GeoLocation, LegalReportBy: localUser.userId,
  //          ValuationRemark: this.ValuationRemark, ValuationRemarkDoc: this.ValuationRemarkDoc, SearchReportRemark: this.SearchReportRemark, SearchReportRemarkDoc: this.SearchReportRemarkDoc,
  //          Collateral_Type: Collateral_Type, LoanId: this.loanSummary.LoanId, CreatedBy: localUser.userId,
  //          state: this.customerModel.Firm_RegState, District: this.customerModel.Firm_RegDistrict,
  //          tasil: this.customerModel.Firm_RegTehsil, ValuationDoneByOther: this.ValuationDoneByOther,
  //          SearchReportDoneByOther: this.SearchReportDoneByOther, SearchReportDoneBy: this.SearchReportDoneBy
  //        }),
  //        data: {
  //          LoanId: this.loanSummary.LoanId,
  //          AssetType: 'Property',
  //          AssetId: this.ID,
  //          CreatedBy: localUser.userId
  //        },
  //        Attachment: this.AssetsAttachment
  //      }
  //    }
  //    else if (this.propertyForms == 'Vehicles') {

  //      // if Vehicle detail adding in Primary Collateral
  //      saveData = {
  //        Vehicle: this.dataSharingService.cleanObject({
  //          VehicleManufacture: parseInt(this.VehicleManufacture),
  //          VehicleCategory: parseInt(this.SelectVehicleCategory), VehicleModelName: parseInt(this.VehicleModel), Variant: this.Variant,
  //          FuelType: this.SelectFuelType, Color: this.Color, VehicleRegistationNo: this.VehicleRegistationNo,
  //          RegistationDate: this.RegistationDate,
  //          RegistationExpiryDate: this.RegistationExpiryDate,
  //          EngineNo: this.EngineNo, ChassisNo: this.ChassisNo, KeyNo: this.KeyNo,
  //          HPNEndorsment: null,
  //          DateofEndors: this.PermitIssuedDate,
  //          MefgYear: this.ManufactureYear,
  //          Category: '', VehicleCondition: '', DONo: this.DeliveryorderNo,
  //          DODoDate: this.DeliveryorderDate,
  //          EstimationAmount: this.EstimationAmount, InvoiceNo: this.InvoiceNo,
  //          InvoiceDate: this.InvoiceDate,
  //          InvoiceValue: this.InvoiceValue, DealerContactPerson: this.DealerContactPerson,
  //          DealerContactNo: this.DealerContactNo,
  //          DOIssuedinfavourof: this.IssuedInFavourOf, DealerId: parseInt(this.Dealer), Remark: this.Remark,
  //          ValuationDoneBy: this.ValuationDoneBy,
  //          ValuationDate: this.ValuationDate,
  //          ValuationAmount: this.ValutionAmount,
  //          ValuationType: this.ValuationType, OtherValuationType: this.OtherValuationType, OtherValuationAmt: this.OtherValuationAmount, FitnessAuthority: '',
  //          FitnessUpto: this.FitnessUpto,
  //          PermitAuthority: '', PermitUpto: this.PermitUpto,
  //          PermitType: '', ExtraInfo: '', Collateral_Type: Collateral_Type, LoanId: this.loanSummary.LoanId,
  //          CreatedBy: localUser.userId, RoadTaxUpto: this.RoadTaxUpto, VehicleCost: this.VehicleCost,
  //          Route: this.Route, vehicle_type: this.vehicle_type,
  //          Vehicle_Subtype: this.commercialVehicle
  //        }),
  //        data: {
  //          LoanId: this.loanSummary.LoanId,
  //          AssetType: 'Vehicle',
  //          AssetId: this.ID,
  //          CreatedBy: localUser.userId
  //        },
  //        Attachment: this.AssetsAttachment
  //      }

  //      if (saveData.Vehicle.RegistationDate) {
  //        saveData.Vehicle.RegistationDate = moment(this.RegistationDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.RegistationDate = null;
  //      }

  //      if (saveData.Vehicle.RegistationExpiryDate) {
  //        saveData.Vehicle.RegistationExpiryDate = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.RegistationExpiryDate = null;
  //      }

  //      if (saveData.Vehicle.DateofEndors) {
  //        saveData.Vehicle.DateofEndors = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.DateofEndors = null;
  //      }

  //      if (saveData.Vehicle.MefgYear) {
  //        saveData.Vehicle.MefgYear = moment(this.ManufactureYear).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.MefgYear = null;
  //      }

  //      if (saveData.Vehicle.DODoDate) {
  //        saveData.Vehicle.DODoDate = moment(this.RegistationDate).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.DODoDate = null;
  //      }

  //      if (saveData.Vehicle.FitnessUpto) {
  //        saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.FitnessUpto = null;
  //      }
  //      if (saveData.Vehicle.PermitUpto) {
  //        saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format('YYYY-MM-DD')
  //      } else {
  //        saveData.Vehicle.PermitUpto = null;
  //      }
  //    }
  //    if (this.ConsumerGoldForms == 'ConsumerGoldForms') {
  //      saveData = this.dataSharingService.cleanObject(this.save_GoldAsset(Collateral_Type));
  //    }
  //  }

  //  this.LmsService.SaveUserAssetInfo({ JSON: JSON.stringify(saveData) }).subscribe((response) => {
  //    this.onCloseKYC();
  //    this.LMS_GetAllUserAssetInfo();
  //    this.AssetsAttachment = [{}];
  //    this.DocModel = [];
  //    this.VehicleManufacture = null; this.SelectVehicleCategory = null;
  //    this.AddressOfProperty = null; this.TypeOfProperty = null;
  //    this.propertyForms == ''; this.propertyForm = '';
  //   // this.resetForm();
  //    if (response[0].CODE == 0) {
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //    } 
  //    else {
  //      this.QuickLoan_Save_PageProcess('Assets Detail');
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //    }
  //    if (updateAssets != null && updateAssets != undefined) {
  //      this.LmsService.Update_AssetsCost({ Loan_Id: this.loanSummary.LoanId, AssetsCost: updateAssets.Asset_Cost, LTV: updateAssets.LTVAmount }).subscribe((response) => {
  //      });
  //    }
  //  });
  //}


  //save_GoldAsset(Collateral_Type) {
  //  if (this.ItemArr.length <= 0) {
  //    this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Add atleast 1 item.', ...this.configSuccess });
  //    return;
  //  }
  //  this.assetModel.Collateral = 'Gold';
  //  this.assetModel.Collateral_Type = Collateral_Type;
  //  this.assetModel.LoginUserId = JSON.parse(sessionStorage.getItem("currentUser")).userId;
  //  let _data: any = {};
  //  _data.GOLD = { ...this.assetModel, TotalItem_Qty: this.ItemArr.length };
  //  _data.GOLD_ITEM = this.ItemArr;
  //  delete _data.GOLD.Item;
  //  delete _data.GOLD.ItemArr;
  //  _data.GOLD_ITEM.forEach((obj: any) => {
  //    if (obj.Image1Data) {
  //      this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: obj.Image1, DocData: obj.Image1Data });
  //    }
  //    if (obj.Image2Data) {
  //      this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: obj.Image2, DocData: obj.Image2Data });
  //    }
  //  });
  //  _data.data = {
  //    LoanId: this.loanSummary.LoanId,
  //    AssetType: 'Gold',
  //    AssetId: this.ID
  //  }
  //  //this.losService.LOS_SaveAssetsGOLD({ JSON: JSON.stringify(_data), AssetId: 0 }).subscribe((res: any) => {
  //  //  this.loading = false;
  //  //  if (res[0].CODE >= 0) {
  //  //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //  //    this.LMS_GetAllUserAssetInfo(this.LoanAcNo);
  //  //    this.VehicleManufacture = null; this.SelectVehicleCategory = null;
  //  //    this.AddressOfProperty = null; this.TypeOfProperty = null;
  //  //    this.propertyForms == ''; this.propertyForm = ''; this.ConsumerGoldForms = '';
  //  //    this.ConsumerGoldForm='';
  //  //    this.resetForm();
  //  //  } else {
  //  //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //  //  }
  //  //});
  //  return _data;
  //}
  ViewData(row) {
    this.LmsService.ViewAssetInfo({ SearchBy: row.Collateral, Id: row.AssetId }).subscribe((response: any) => {
      console.log("response :", response);
      this.Collateral = row.Collateral;
      this.Collateral_Type = row.Collateral_Type;
      this.Info = response.Item1[0];
      //this.ID = response.Item1[0].AssetId;
      this.AssetsAttachment = response.Item2;
      if (this.Collateral == 'Gold') {
        this.dataviewAssetItemSource = new MatTableDataSource(response.Item3);
        this.dataAssetItemSource = new MatTableDataSource(response.Item3);
        this.ItemArr = response.Item3;
      }
      //this.editAssetInfo();
    });

  }
  onCloseView() {
    $('#View').modal('hide');
  }
  GetConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      //this.ConstArea.nativeElement.focus();
      this.ConstructedArea = 0;
      return;
    }
  }
  GetAdtConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      this.ConstAddArea.nativeElement.focus();
      this.ConstAddArea.nativeElement.value = 0;
      return;
    }
  }
  editAssetItem(data: any, index: any) {
    this.Item = data;
    this.ItemArr.selectedIndex = index;
  }
  deleteAssetItem(index: any) {
    this.ItemArr.splice(index, 1);
    this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
    if (this.ItemArr.selectedIndex == index) {
      delete this.ItemArr.selectedIndex;
    }
    this.getAssetTotal();
  }
  getDataProperty() {
    // this.ID = this.Info.Id;
    this.PropertyOwnerName = this.Info.PropertyOwnerID;
    this.AddressOfProperty = this.Info.Address;
    this.Location = this.Info.GeoLocation;
    this.latitude = this.Info.GeoLocation.split(',')[0];
    this.longitude = this.Info.GeoLocation.split(',')[1];
    this.TypeOfProperty = this.Info.TypeofPropertyID;
    this.NatureOfProperty = this.Info.NatureofPropertyID;
    this.OwnershipDocument = this.Info.OwnershipDocumentID;
    this.OwnershipType = this.Info.OwnershipTypeID;
    this.UnitOfMeasurement = this.Info.UnitofMeasurement;
    this.TotalArea = this.Info.TotalArea;
    this.ConstructedArea = this.Info.ConstructedArea;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationDoneByOther = this.Info.ValuationDoneByOther;
    this.ValuationRemark = this.Info.ValuationRemark;
    this.SearchReportDoneByOther = this.Info.SearchReportDoneByOther;
    this.SearchReportRemark = this.Info.SearchReportRemark;
    this.ValuationRemarkDoc = this.Info.ValuationRemarkDoc;
    this.SearchReportRemarkDoc = this.Info.SearchReportRemarkDoc;
    // this.SearchReportDoneBy = this.Info.SearchReportDoneByID;
    this.TypeOfMortgage = this.Info.TypeofMortgage;
    this.MortgageSingedBy = this.Info.MortgageSingedByID;
    this.customerModel.Firm_RegState = this.Info.StateID;
    this.customerModel.Firm_RegDistrict = this.Info.DistrictID;
    this.customerModel.Firm_RegTehsil = this.Info.tasilID;
    this.GetDistrickDropdownReg(this.customerModel.Firm_RegState);
    this.GetTehasilDropdownReg(this.customerModel.Firm_RegDistrict);
  }

  getDataVehicle() {
    // this.ID = this.Info.Id;

    this.VehicleManufacture = this.Info.VehicleManufactureID;
    this.SelectVehicleCategory = this.Info.VehicleCategoryID;
    this.VehicleModel = this.Info.VehicleModelNameID;
    this.LMS_GetVehicleModelMaster(event);

    this.Variant = this.Info.Variant;
    this.ManufactureYear = this.Info.MefgYear == null ? '' : new Date(this.Info.MefgYear);
    this.VehicleRegistationNo = this.Info.VehicleRegistationNo;
    this.RegistationDate = this.Info.RegistationDate == null ? '' : new Date(this.Info.RegistationDate);
    this.RegistationExpiryDate = this.Info.RegistationExpiryDate == null ? '' : new Date(this.Info.RegistationExpiryDate);

    this.SelectFuelType = this.Info.FuelTypeID;

    // if (this.Info.TypeofProperty == 'Commercial') {
    this.RoadTaxUpto = this.Info.RoadTaxUpto == null ? '' : new Date(this.Info.RoadTaxUpto);
    this.Color = this.Info.Color;
    this.FitnessUpto = this.Info.FitnessUpto == null ? '' : new Date(this.Info.FitnessUpto);
    this.PermitUpto = this.Info.PermitUpto == null ? '' : new Date(this.Info.PermitUpto);
    this.VehicleCost = this.Info.VehicleCost;
    this.Route = this.Info.Rout;
    this.EngineNo = this.Info.EngineNo;
    this.ChassisNo = this.Info.ChassisNo;
    this.KeyNo = this.Info.KeyNo;
    // }EngineNo

    this.DeliveryorderNo = this.Info.DONo;
    this.DeliveryorderDate = this.Info.DODoDate == null ? '' : new Date(this.Info.DODoDate);
    this.InvoiceNo = this.Info.InvoiceNo;
    this.InvoiceValue = this.Info.InvoiceValue;
    this.DealerContactPerson = this.Info.DealerContactPerson;
    this.DealerContactNo = this.Info.DealerContactNo;
    this.IssuedInFavourOf = this.Info.DOIssuedinfavourofID;
    this.Remark = this.Info.Remark;
    this.Dealer = this.Info.DealerId;
    this.EstimationAmount = this.Info.EstimationAmount;
    this.InvoiceDate = this.Info.InvoiceDate == null ? '' : new Date(this.Info.InvoiceDate);
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationType = this.Info.ValuationType;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.OtherValuationAmount = this.Info.OtherValuationAmt;
    this.OtherValuationType = this.Info.OtherValuationType;
    this.ValuationDate = this.Info.ValuationDate == null ? '' : new Date(this.Info.ValuationDate);

    if (this.Info.Vehicle_Type == 'New') {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = 'New';
    }
    if (this.Info.Vehicle_Type == 'Used') {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = 'Used';

    }
    if (this.Info.Vehicle_SubType == 'Commercial') {
      this.commercialVehicle = 'Commercial';
    }

    if (this.Info.Vehicle_SubType == 'Non-Commercial') {
      this.commercialVehicle = 'Non-Commercial';
    }


  }
  getDataGold() {
    //this.ID = this.Info.Id;
    this.assetModel.Collateral = 'Gold';
    this.assetModel.Collateral_Type = this.Info.Collateral_Type;
    this.assetModel.LoginUserId = JSON.parse(sessionStorage.getItem("currentUser")).userId;
    this.assetModel.ValulationDoneBy = this.Info.ValulationDoneByID;
    this.assetModel.ValulationDate = new Date(this.Info.ValulationDate);
    this.assetModel.TotalValulationAmount = this.Info.TotalValulationAmount;
    this.assetModel.ValuerPhoneNo = this.Info.ValuerPhoneNo;
    this.assetModel.ValuerFirmName = this.Info.ValuerFirmName;

    this.getAssetTotal();
  }
  //editAssetInfo() {
  //  this.saveBtn = false;
  //  this.isEdit = true;
  //  this.isUpdate = false;
  //  this.IsDisabled = true;

  //  this.AssetsAttachment.push({});
  //  if (this.Info.Collateral_Type == "Primary Collateral" || this.Info.Collateral_Type == "Primary") {
  //    //var value = this.loanSummary.ProductId;
  //    if (this.loanSummary.Product_Category == 'Property Loan') {
  //      this.AssetsForm = 'Form';
  //      this.propertyForm = 'Property';
  //      this.ConsumerGoldForm = '';
  //      this.PersonalForm = '';
  //    }
  //    if (this.loanSummary.Product_Category == 'Vehicle Loan') {
  //      this.AssetsForm = 'Form';
  //      this.propertyForm = 'Vehicle';
  //      this.ConsumerGoldForm = '';
  //      this.PersonalForm = '';
  //    }
  //    if (this.loanSummary.Product_Category == 'Gold Loan') {
  //      this.AssetsForm = 'Form';
  //      this.ConsumerGoldForm = 'ConsumerGoldForm';
  //      this.propertyForm = '';
  //    }
  //    //if (this.loanSummary.Product_Category == 'Consumer Durable Loan') {
  //    //  this.AssetsForm = 'Form';
  //    //  this.ConsumerGoldForm = 'ConsumerGoldForm';
  //    //  this.propertyForm = '';
  //    //}
  //    if (this.loanSummary.Product_Category == 'Un-Secured Loan' || this.loanSummary.Product_Category == 'Consumer Durable Loan' || this.loanSummary.Product_Category == 'Loan Against Securities' || this.loanSummary.Product_Category == 'Agricultural Loan') {
  //      this.AssetsForm == 'Forms';
  //      this.ConsumerGoldForm = '';
  //      this.propertyForm = '';
  //      this.PersonalForm = 'PersonalForms';
  //    }
  //    if (this.Info.Collateral == 'Property') {
  //      this.propertyForm == 'Property'

  //      this.getDataProperty();
  //    }

  //    if (this.Info.Collateral == 'Vehicle') {
  //      this.propertyForm == 'Vehicle'
  //      this.getDataVehicle();
  //    }
  //    if (this.Info.Collateral == 'Gold') {
  //      this.ConsumerGoldForm == 'ConsumerGoldForm'
  //      this.getDataGold();
  //    }
  //  }

  //  if (this.Info.Collateral_Type == "Additional Collateral" || this.Info.Collateral_Type == "Additional") {

  //    this.Addi_ProductCat = true;
  //    this.additional_collateral_category(this.Info.Collateral + ' Loan');
  //    if (this.Info.Collateral == 'Property') {

  //      this.propertyForms == 'Propertys'
  //      this.getDataProperty();
  //    }

  //    if (this.Info.Collateral == 'Vehicle') {
  //      //this.additional_collateral_category('5-0');
  //      this.propertyForms == 'Vehicles';
  //      $('#additionalcollateral').modal('show');
  //      $("#additionalcollateral").css("z-index", "1050");
  //      this.getDataVehicle();
  //    }
  //    if (this.Info.Collateral == 'Gold') {
  //      this.ConsumerGoldForms == 'ConsumerGoldForms'
  //      //this.additional_collateral_category('9-0');
  //      $('#additionalcollateral').modal('show');
  //      $("#additionalcollateral").css("z-index", "1050");
  //      this.getDataGold();
  //    }
  //  }
  //}

  next() {
    let route = '';
    if (this.loanSummary.Product_Category == 'Un-Secured Loan' || (this.dataSource != undefined && this.dataSource.filteredData.length != 0 && (this.dataSource.filteredData.some((item) => item.Collateral_Type == "Primary Collateral") || this.dataSource.filteredData.some((item) => item.Collateral_Type == "Primary")))) {
      this.LmsService.SaveNext_Loan({ Loan_Id: this.loanSummary.LoanId, CommandName: 'Asset', isStatus: 1 }).subscribe((response) => {

      });
      this.action.emit("next");
    }
  }

  //updateAssetInfo() {
  //  // this.isUpdate = false;
  //  // this.isEdit = true;
  //  // this.AssetForm = 'Form';
  //  // this.AssetTableView = true;

  //  let saveData = {
  //    "Int_Id": 1,
  //    "VehicleManufacture": parseInt(this.VehicleManufacture),
  //    "VehicleCategory": this.vehicleCategoryId,
  //    "VehicleModel": parseInt(this.VehicleModel), "Variant": this.Variant,
  //    "ManufactureYear": moment(this.ManufactureYear).format('DD-MM-YYYY HH:mm'),
  //    "VehicleRegistationNo": this.VehicleRegistationNo,
  //    "RegistationDate": moment(this.RegistationDate).format('DD-MM-YYYY'),
  //    "RegistationExpiryDate": moment(this.RegistationExpiryDate).format('DD-MM-YYYY HH:mm'),
  //    "FuelType": this.SelectFuelType,
  //    "RoadTaxUpto": moment(this.RoadTaxUpto).format('DD-MM-YYYY HH:mm'),
  //    "Color": this.Color,
  //    "FitnessUpto": this.FitnessUpto,
  //    "Dealer": this.Dealer,
  //    "PermitUpto": moment(this.PermitUpto).format('DD-MM-YYYY HH:mm'),
  //    "VehicleCost": this.VehicleCost,
  //    "Route": this.Route, "EngineNo": this.EngineNo, "ChassisNo": this.ChassisNo, "KeyNo": this.KeyNo,
  //    "DeliveryOrderNo": this.DeliveryorderNo,
  //    "DeliveryOrderDate": this.DeliveryorderDate,
  //    "DoIssuedFavourOf": this.DoIssuedFavour, "DealerContactPerson": this.DealerContact,
  //    "LtvBase": this.LTVBase, "ValuationGridIDV": this.ValuationGrid, "ValuationDoneBy": this.ValuationDoneBy,
  //    "GridValue": this.GridValue, "PermitNo": this.PermitNo,
  //    "PermitIssuedDate": this.PermitIssuedDate,
  //    "PermitExpiryDate": this.PermitExpireDate,
  //    "PermitNature": this.PermitNature, "RouteDescription": this.RouteDescription, "IsActive": 0,

  //    "PropertyOwnerName": this.PropertyOwnerName, "AddressOfProperty": this.AddressOfProperty,
  //    "Location": this.Location, "TypeOfProperty": this.TypeOfProperty,
  //    "NatureOfProperty": this.NatureOfProperty, "OwnershipDocument": this.OwnershipDocument,
  //    "OwnershipType": this.OwnershipType, "UnitOfMeasurement": this.UnitOfMeasurement,
  //    "TotalArea": this.TotalArea, "ConstructedArea": this.ConstructedArea,
  //    "ValutionAmount": this.ValutionAmount, "SearchReportDoneBy": this.SearchReportDoneBy,
  //    "TypeOfMortgage": this.TypeOfMortgage, "MortgageSingedBy": this.MortgageSingedBy,


  //    JSON: ''
  //  }

  //  if (saveData.DeliveryOrderDate) {
  //    saveData.DeliveryOrderDate = moment(this.DeliveryorderDate).format('DD-MM-YYYY HH:mm')
  //  } else {
  //    saveData.DeliveryOrderDate = "";
  //  }

  //  if (saveData.FitnessUpto) {
  //    saveData.FitnessUpto = moment(this.FitnessUpto).format('DD-MM-YYYY HH:mm')
  //  } else {
  //    saveData.FitnessUpto = "";
  //  }

  //  if (saveData.PermitIssuedDate) {
  //    saveData.PermitIssuedDate = moment(this.PermitIssuedDate).format('DD-MM-YYYY HH:mm')
  //  } else {
  //    saveData.PermitIssuedDate = "";
  //  }
  //  if (saveData.PermitExpiryDate) {
  //    saveData.PermitExpiryDate = moment(this.PermitExpireDate).format('DD-MM-YYYY HH:mm')
  //  } else {
  //    saveData.PermitExpiryDate = "";
  //  }

  //  let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
  //  let Data = {
  //    AssetInfo: {
  //      LoanId: this.loanSummary.LoanId,
  //      ModifyBy: localUser.userId,
  //      UpdatedAt: moment(new Date()).format('DD-MM-YYYY HH:mm'),
  //    }
  //  }

  //  let asset = JSON.stringify(Data)
  //  saveData.JSON = asset;

  //  if (this.propertyForm == 'Property') {
  //    saveData.VehicleManufacture = 0; saveData.VehicleModel = 0;
  //    saveData.FitnessUpto = ""; saveData.ManufactureYear = "";
  //    saveData.PermitExpiryDate = ""; saveData.PermitIssuedDate = "";
  //    saveData.PermitUpto = ""; saveData.RoadTaxUpto = "";
  //    saveData.RegistationDate = ""; saveData.RegistationExpiryDate = "";
  //    saveData.Dealer = 0;
  //  } else {

  //  }
  //  let updateAssets;
  //  if (this.Info.Collateral_Type == 'Primary Collateral' || this.Info.Collateral_Type == 'Primary') {
  //    if (this.loanSummary.Product_Category == 'Property Loan') {

  //      this.val_sum = this.ValutionAmount + this.val_sum;
  //      let LTV = this.ValutionAmount == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //    }
  //    if (this.loanSummary.Product_Category == 'Vehicle Loan') {
  //      this.val_sum = this.VehicleCost + this.val_sum;
  //      let LTV = this.VehicleCost == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //    }
  //    if (this.loanSummary.Product_Category == 'Gold Loan') {
  //      this.val_sum = this.assetModel.TotalValulationAmount + this.val_sum;
  //      let LTV = this.val_sum == 0 ? 100 : ((this.loanSummary.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
  //      updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
  //    }
  //  }
  //  this.LmsService.SaveUserAssetInfo(saveData).subscribe((response) => {
  //    if (response[0].CODE == 0) {
  //      this.isUpdate = false;
  //      this.isEdit = true;
  //      this.AssetTableView = true;
  //      this.AssetsForm = '';
  //      this.saveBtn = false;
  //      this.updateBtn = false;
  //      if (updateAssets != null && updateAssets != undefined) {
  //        this.LmsService.Update_AssetsCost({ Loan_Id: this.loanSummary.LoanId, AssetsCost: updateAssets.Asset_Cost, LTV: updateAssets.LTVAmount }).subscribe((response) => {
  //          this.ngOnInit();
  //        });
  //      }

  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });

  //    } else {
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //    }
  //  });
  //}

  selectVehicleType(type: string) {
    if (type == 'New') {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = 'New';
    }
    if (type == 'Used') {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = 'Used';

    }
    if (type == 'Commercial') {
      this.commercialVehicle = 'Commercial';
    }

    if (type == 'Non-Commercial') {
      this.commercialVehicle = 'Non-Commercial';
    }
    if (this.RegistationDate != '') {
      var year = this.RegistationDate.getFullYear();
      var month = this.RegistationDate.getMonth();
      var day = this.RegistationDate.getDate();
      if (this.commercialVehicle == 'Commercial') {
        var c = new Date(year + 10, month, day - 1);
        this.RegistationExpiryDate = c;
      }
      if (this.commercialVehicle == 'Non-Commercial') {
        var c = new Date(year + 15, month, day - 1);
        this.RegistationExpiryDate = c;
      }
    }
  }

  selectAgricultureForm(type: string) {
    if (type == 'Property') {
      this.propertyForm = 'Property';
    }
    if (type == 'Vehicle') {
      this.propertyForm = 'Vehicle';
    }
  }


  OpenModel() {
    console.log("this.loanSummary", this.loanSummary);

    //this.resetForm();
    this.customerModel.Firm_RegState = ''
    this.customerModel.Firm_RegDistrict = '';
    this.customerModel.Firm_RegTehsil = '';

    if (this.loanSummary.Product_Category == 'Property Loan') {
      this.AssetsForm = 'Form';
      this.propertyForm = 'Property';
      this.ConsumerGoldForm = '';
      this.PersonalForm = '';
    }
    if (this.loanSummary.Product_Category == 'Vehicle Loan') {
      this.AssetsForm = 'Form';
      this.propertyForm = 'Vehicle';
      this.ConsumerGoldForm = '';
      this.PersonalForm = '';
    }
    if (this.loanSummary.Product_Category == 'Gold Loan') {
      this.AssetsForm = 'Form';
      this.ConsumerGoldForm = 'ConsumerGoldForm';
      this.propertyForm = '';
    }

    /*if (this.loanSummary.Product_Category == 'Consumer Durable Loan') {
      this.AssetsForm = 'Form';
      this.ConsumerGoldForm = 'ConsumerGoldForm';
      this.propertyForm = '';
    }*/

    if (this.loanSummary.Product_Category == 'Un-Secured Loan' || this.loanSummary.Product_Category == 'Consumer Durable Loan' || this.loanSummary.Product_Category == 'Loan Against Securities' || this.loanSummary.Product_Category == 'Agricultural Loan') {
      this.AssetsForm == 'Forms';
      this.ConsumerGoldForm = '';
      this.propertyForm = '';
      this.PersonalForm = 'PersonalForms';
    }

    this.isEdit = false;
    this.isUpdate = false;

    //this.AssetsAttachment.push({});
    if (this.Info?.Collateral_Type == "Primary Collateral" || this.Info?.Collateral_Type == "Primary") {
      //var value = this.loanSummary.ProductId;
      if (this.loanSummary.Product_Category == 'Property Loan') {
        this.AssetsForm = 'Form';
        this.propertyForm = 'Property';
        this.ConsumerGoldForm = '';
        this.PersonalForm = '';
      }
      if (this.loanSummary.Product_Category == 'Vehicle Loan') {
        this.AssetsForm = 'Form';
        this.propertyForm = 'Vehicle';
        this.ConsumerGoldForm = '';
        this.PersonalForm = '';
      }
      if (this.loanSummary.Product_Category == 'Gold Loan') {
        this.AssetsForm = 'Form';
        this.ConsumerGoldForm = 'ConsumerGoldForm';
        this.propertyForm = '';
      }
      //if (this.loanSummary.Product_Category == 'Consumer Durable Loan') {
      //  this.AssetsForm = 'Form';
      //  this.ConsumerGoldForm = 'ConsumerGoldForm';
      //  this.propertyForm = '';
      //}
      if (this.loanSummary.Product_Category == 'Un-Secured Loan' || this.loanSummary.Product_Category == 'Consumer Durable Loan' || this.loanSummary.Product_Category == 'Loan Against Securities' || this.loanSummary.Product_Category == 'Agricultural Loan') {
        this.AssetsForm == 'Forms';
        this.ConsumerGoldForm = '';
        this.propertyForm = '';
        this.PersonalForm = 'PersonalForms';
      }
      if (this.Info.Collateral == 'Property') {
        this.propertyForm == 'Property'

        this.getDataProperty();
      }

      if (this.Info.Collateral == 'Vehicle') {
        this.propertyForm == 'Vehicle'
        this.getDataVehicle();
      }
      if (this.Info.Collateral == 'Gold') {
        this.ConsumerGoldForm == 'ConsumerGoldForm'
        this.getDataGold();
      }
    }
  }


  // start form  additional collateral
  OpenModel_Additional_Collateral() {
    //  this.resetForm();
    $('#additionalcollateral').modal('show');
    $("#additionalcollateral").css("z-index", "1050");
    //var element2 = <HTMLInputElement>document.getElementById("New1");
    //element2.checked = true;
    //this.commercialVehicle = 'Commercial';
    //var element3 = <HTMLInputElement>document.getElementById("Commercial1");
    //element3.checked = true;
  }

  // dropdown value get
  getProductDropdown() {
    this._MasterService.GetProductList({ Emp_Id: this.data.userId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
    });
  }


  selectProducts(event: any) {
    this.additional_collateral_category(event.target.selectedOptions[0].label);
  }


  additional_collateral_category(value) {
    if (value) {
      //this.vehicleCategoryIds = value.split('-')[0];

      let productCat = value.trim();
      this.ProductCatId = this.ProductCategoryDropdown.filter(x => x.Product_Category == value)[0].ProductCatId;
      if (productCat == 'Property Loan') {
        this.AssetsForms == 'Forms';
        this.propertyForms = 'Propertys';
        this.ConsumerGoldForms = '';
        this.PersonalForms = '';
      }
      if (productCat == 'Vehicle Loan') {
        this.AssetsForms == 'Forms';
        this.propertyForms = 'Vehicles';
        this.ConsumerGoldForms = '';
        this.PersonalForms = '';
      }
      if (productCat == 'Gold Loan') {
        this.AssetsForms == 'Forms';
        this.ConsumerGoldForms = 'ConsumerGoldForms';
        this.propertyForms = '';
      }
      if (productCat == 'Un-Secured Loan' || productCat == 'Consumer Durable Loan' || productCat == 'Loan Against Securities' || productCat == 'Agricultural Loan') {
        this.AssetsForms == 'Forms';
        this.ConsumerGoldForms = '';
        this.propertyForms = '';
        this.PersonalForms = 'PersonalForms';
      }

    }
  }
  // end form  additional collateral

  //ChangeRegistationDate(event: any) {
  //  if (this.RegistationDate && this.ManufactureYear) {
  //    if (this.RegistationDate < this.ManufactureYear) {
  //      this.snackBar.openFromComponent(SnackbarComponent, {
  //        data: "Registation Date greater than ManufactureYear.",
  //        ...this.configSuccess,
  //      });
  //      this.RegistationDate = '';
  //    }
  //    var year = this.RegistationDate.getFullYear();
  //    var month = this.RegistationDate.getMonth();
  //    var day = this.RegistationDate.getDate();
  //    if (this.commercialVehicle == 'Commercial') {
  //      var c = new Date(year + 10, month, day - 1);
  //      this.RegistationExpiryDate = c;
  //    }
  //    if (this.commercialVehicle == 'Non-Commercial') {
  //      var c = new Date(year + 15, month, day - 1);
  //      this.RegistationExpiryDate = c;
  //    }
  //  }
  //}
  //ChangeExpiryDate(event: any) {
  //  if (this.RegistationExpiryDate < this.RegistationDate) {
  //    this.snackBar.openFromComponent(SnackbarComponent, {
  //      data: "Registation Expiry Date greater than Registation Date.",
  //      ...this.configSuccess,
  //    });
  //    this.RegistationExpiryDate = '';
  //  }
  //}
  //ChangeDeliveryorderDate(event: any) {
  //  if (this.DeliveryorderDate > this.RegistationDate) {
  //    this.snackBar.openFromComponent(SnackbarComponent, {
  //      data: "Delivery Order Date less than Registation Date.",
  //      ...this.configSuccess,
  //    });
  //    this.DeliveryorderDate = '';
  //  }
  //}
  //ChangeInvoiveDateDate(event: any) {
  //  if (this.InvoiveDate < this.DeliveryorderDate) {
  //    this.snackBar.openFromComponent(SnackbarComponent, {
  //      data: "Invoive  Date greater than Delivery Order Date.",
  //      ...this.configSuccess,
  //    });
  //    this.InvoiveDate = '';
  //  }
  //  if (this.InvoiveDate > this.RegistationDate) {
  //    this.snackBar.openFromComponent(SnackbarComponent, {
  //      data: "Invoive  Date less than Registation Date.",
  //      ...this.configSuccess,
  //    });
  //    this.InvoiveDate = '';
  //  }
  //}
  //getFuelType() {
  //  this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'FUEL TYPE' }).subscribe((res: any) => {
  //    this.FuelTypeOption = res;
  //  });

  //}

  //checkRegNonumber() {
  //  this.LmsService.GET_VEHICLEREGISTRATION_DETAILS({ Vehicle_No: this.VehicleRegistationNo }).subscribe((res: any) => {
  //    this.duplicateData = res;
  //    if (res.length > 0) {
  //      $('#duplicateData').modal('show');
  //      $("#duplicateData").css("z-index", "1050");
  //    }
  //  })
  //}
  //onCloseduplicateData() {
  //  $('#duplicateData').modal('hide');
  //}
  //getOwnershipType() {
  //  this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'OWNERSHIP TYPE' }).subscribe((res: any) => {
  //    this.OwnershipTypeList = res;
  //  });

  //}
  //getOwnershipDocument() {
  //  this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'OWNERSHIP DOCUMENT' }).subscribe((res: any) => {
  //    this.OwnershipDocumentList = res;
  //  });

  //}
  //getNatureOfProperty() {
  //  this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'NATURE OF PROPERTY' }).subscribe((res: any) => {
  //    this.NatureOfPropertyList = res;
  //  });

  //}
  //getTypeOfProperty() {
  //  this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'TYPE OF PROPERTY' }).subscribe((res: any) => {
  //    this.TypeOfPropertyList = res;
  //  });

  //}
  imageChangeListener(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    $this.Item.Image1 = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.Item.Image1Data = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeImage(input: any) {
    input.value = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
  }
  imageChangeListener1(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    $this.Item.Image2 = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.Item.Image2Data = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeImage1(input: any) {
    input.value = "";
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
  }
  saveAssetItem() {
    if (!this.Item.Image1) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please select Image1.', ...this.configSuccess });
      return;
    }
    //if (!this.Item.Image2) {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please select Image2.', ...this.configSuccess });
    //  return;
    //}
    if (this.ItemArr.hasOwnProperty('selectedIndex')) {
      this.ItemArr[this.ItemArr.selectedIndex] = JSON.parse(JSON.stringify(this.Item));
      this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
      this.Item.Image2 = "";
      this.Item.Image2Data = "";
      this.Item.Image1 = "";
      this.Item.Image1Data = "";
      this.Item = {};
      this.Item.PurityCT = 12;
      delete this.ItemArr.selectedIndex;
      this.getAssetTotal();
    }
    else {
      this.ItemArr.push(JSON.parse(JSON.stringify(this.Item)));
      this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
      this.Item = {};
      this.Item.Image2 = "";
      this.Item.Image2Data = "";
      this.Item.Image1 = "";
      this.Item.Image1Data = "";
      this.Item.PurityCT = 12;
      this.getAssetTotal();
    }

  }
  getAssetTotal() {
    if (this.ItemArr.length == 0) {
      this.assetTotal = undefined;
    }
    else {
      this.assetTotal = { Qty: 0, GrossWt: 0, StoneWt: 0, OtherWt: 0, NetWt: 0, TotalAmount: 0 }
      this.ItemArr.forEach((obj: any) => {
        this.assetTotal.Qty = Number(this.assetTotal.Qty) + Number(obj.Qty);
        this.assetTotal.GrossWt = Number(this.assetTotal.GrossWt) + Number(obj.GrossWt);
        this.assetTotal.StoneWt = Number(this.assetTotal.StoneWt) + Number(obj.StoneWt);
        this.assetTotal.NetWt = Number(this.assetTotal.NetWt) + Number(obj.NetWt);
        this.assetTotal.TotalAmount = Number(this.assetTotal.TotalAmount) + Number(obj.ValulationAmount);
      });
      this.assetModel.TotalValulationAmount = this.assetTotal.TotalAmount;
    }
  }
  ChangeWT() {
    if (Number(this.Item.GrossWt) < (Number(this.Item.StoneWt)) + Number(this.Item.OtherWt)) {
      //this.Item.GrossWt = '';
      //this.Item.StoneWt = '';
      this.Item.OtherWt = '';
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Gross Wt greater than (StoneWT+OtherWT) ', ...this.configSuccess });
      return;
    }
    else {
      if (this.Item.GrossWt && this.Item.StoneWt != undefined && this.Item.OtherWt != undefined && this.Item.GrossWt != undefined) {
        this.Item.NetWt = (Number(this.Item.GrossWt) - (Number(this.Item.StoneWt) + Number(this.Item.OtherWt))).toFixed(2);
        this.Item.PureWt = Number(this.Item.NetWt * (this.Item.PurityCT * this.fixrate24ct) / 100).toFixed(2);
        //console.log("Item.PureWt2", this.Item.PureWt);
      }
      else {
        this.Item.NetWt = '';
      }
      if (this.Item.NetWt && this.Item.NetWt != undefined && this.Item.PurityCT && this.Item.PurityCT != undefined) {
        (this.Item.ValulationAmount = Number(this.Item.PureWt) * Number(this.Item.ValulationRate_PerCT)).toFixed(2);
      } else {
        this.Item.ValulationAmount = '';
      }
    }
  }

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
    this._Route.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  onEditAssetsInfo() {
    this.IsDisabled = false;
    this.isEdit = false;
    this.updateBtn = true;
  }

  QuickLoan_Save_PageProcess(PageType) {
    /*console.log(
      "PageType", PageType,
      "this.loanSummary", this.loanSummary,
      "this.Processid", this.Processid,
      "this.LoginUserId", this.data.userId,
      "Page_Id", this.PageData.MM_Id
    );*/
    this._QuickLoanService.QuickLoan_Save_PageProcess({
      PageType: PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId,
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.data.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
  SaveValuationProcess() {
    let saveData = { JSON: '' }
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.ValuationDocData) {
      this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.ValuationRemarkDoc, DocData: this.ValuationDocData });
    }
    if (this.ValuationDocData1) {
      this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: this.ValuationRemarkDoc1, DocData: this.ValuationDocData1 });
    }
    let Data = {
      AssetInfo: {
        LoanId: this.loanSummary.LoanId,
        AssetType: this.Collateral,
        AssetId: this.ID,
        CreatedBy: localUser.userId,
        ValuationDoneBy: this.ValuationDoneBy,
        ValuationDate: this.ValuationDate,
        ValuationAmount: this.ValutionAmount,
        ValuationType: this.ValuationType,
        OtherValuationType: this.OtherValuationType,
        OtherValuationAmt: this.OtherValuationAmount,
        ValuationRemark: this.ValuationRemark,
        ValuationDoc: this.ValuationRemarkDoc,
        ValuationDoc1: this.ValuationRemarkDoc1,
        Id: this.PendingValuation && this.PendingValuation.length > 0 ? this.PendingValuation[0].Id : 0
        //SearchReportRemark: this.SearchReportRemark,
        //SearchReportDoneBy: this.SearchReportDoneBy,
        ////SearchReportDoneOn: this.SearchReportDoneOn,
        //SearchDocData: this.SearchReportRemarkDoc
      }
    }
    console.log("Data", Data);
    let asset = JSON.stringify(Data)
    saveData.JSON = asset;

    this._QuickLoanService.QuickLoan_Update_ValuationProcess(saveData).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.QuickLoan_Save_PageProcess('Valuation Process');
        this.ValuationDocData = '';
        this.LMS_GetAllUserAssetInfo();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });

      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
  editAsset() {
    this.isUpdateValuation = true;
    this.isEdit = false;
    this.ValuationDoneBy = this.PendingValuation[0].Process_Vendor_id;
    this.ValuationDate = this.PendingValuation[0].ProcessDone_on;
    this.ValutionAmount = this.PendingValuation[0].Valuation_Amount;
    this.ValuationType = this.PendingValuation[0].Valuation_Type;
    this.OtherValuationType = this.PendingValuation[0].Valuation_Type2;
    this.OtherValuationAmount = this.PendingValuation[0].Valuation_Amount2;
    this.ValuationRemarkDoc = this.PendingValuation[0].Attachment1;
    this.ValuationRemarkDoc1 = this.PendingValuation[0].Attachment2;
    this.ValuationRemark = this.PendingValuation[0].Process_Remark;
    this.ButtonName = 'Update';
  }
  ChangeValuationType() {

  }
}

