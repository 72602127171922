<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #fd="ngForm" (ngSubmit)="onSaveNewDeposit()" novalidate>
  <div class="row m-0 mt-2 p-1">
    <div class="col-md-12 p-1 bg-success1">Financial Details:</div>
  </div>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span class="required-lable">Deposit Type</span>
      <select
        name="Deposit_Type"
        id="Deposit_Type"
        #refDeposit_Type="ngModel"
        (change)="ChangeDepositType()"
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Type.invalid,
          'alert-warning':
            refDeposit_Type.invalid &&
            (refDeposit_Type.dirty ||
              refDeposit_Type.touched ||
              refDeposit_Type.untouched)
        }"
        [(ngModel)]="FDR_Detail.Deposit_Type"
        class="form-control input-text-css"
        required
        [disabled]="Statustype != '' && Statustype != 'Reverted'"
      >
        <option value="">Select Type</option>
        <option value="FD">Fixed Deposit (FD)</option>
        <option value="RD">Recurring Deposit (RD)</option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span class="required-lable">Product Name</span>
      <!-- <select name="Deposit_ProductId" id="Deposit_ProductId" #refDeposit_ProductId="ngModel" [(ngModel)]="FDR_Detail.Deposit_ProductId" 
        class="form-control input-text-css" required (change)="OnChangeProduct()"[disabled]="Statustype != '' && Statustype != 'Reverted'"
        [ngClass]="{ 'is-invalid': fd.submitted && refDeposit_ProductId.invalid, 'alert-warning': refDeposit_ProductId.invalid  && (refDeposit_ProductId.dirty || refDeposit_ProductId.touched || refDeposit_ProductId.untouched) }">
        <option value=""> Select Product </option>
        <option *ngFor="let Product of ProductListDropdown" [value]="Product.ProductId">{{Product.ProductName}}</option>
      </select> -->
      <input
        required
        type="text"
        readonly
        name="ProductName"
        id="ProductName"
        [(ngModel)]="FDR_Detail.ProductName"
        class="form-control input-text-css"
        (keyup.enter)="SearchProduct()"
      />
      <button
        type="button"
        (click)="SearchProduct()"
        class="primary_btn ml-2 search"
        [disabled]="
          FDR_Detail.Deposit_Type == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
      >
        <i class="fa fa-search" aria-hidden="true" style="font-size: 11px"></i>
      </button>
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Interest Type</span>
      <select
        name="Deposit_Interest_Type"
        id="Deposit_Interest_Type"
        #refDeposit_Interest_Type="ngModel"
        disabled
        required
        [(ngModel)]="FDR_Detail.Deposit_Interest_Type"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Interest_Type.invalid,
          'alert-warning':
            refDeposit_Interest_Type.invalid &&
            (refDeposit_Interest_Type.dirty ||
              refDeposit_Interest_Type.touched ||
              refDeposit_Interest_Type.untouched)
        }"
      >
        <option value="">Select Category</option>
        <option value="Simple">Simple</option>
        <option value="Cumulative">Cumulative</option>
      </select>
    </div>

    <div
      class="col-md-3 p-1"
      *ngIf="FDR_Detail.Deposit_Interest_Type == 'Cumulative'"
    >
      <span class="required-lable">Cummulative Frequency</span>
      <select
        name="Deposit_Cummulative_Frequency"
        id="Deposit_Cummulative_Frequency"
        #refDeposit_Cummulative_Frequency="ngModel"
        disabled
        required
        [(ngModel)]="FDR_Detail.Deposit_Cummulative_Frequency"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid':
            fd.submitted && refDeposit_Cummulative_Frequency.invalid,
          'alert-warning':
            refDeposit_Cummulative_Frequency.invalid &&
            (refDeposit_Cummulative_Frequency.dirty ||
              refDeposit_Cummulative_Frequency.touched ||
              refDeposit_Cummulative_Frequency.untouched)
        }"
      >
        <option value="">Select Category</option>
        <option value="Monthly">Monthly</option>
        <option value="Quarterly">Quarterly</option>
        <option value="HalfYearly">Half-Yearly</option>
        <option value="Yearly">Yearly</option>
      </select>
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Payment Frequency</span>
      <select
        name="Deposit_Payment_Frequency"
        id="Deposit_Payment_Frequency"
        #refDeposit_Payment_Frequency="ngModel"
        required
        [(ngModel)]="FDR_Detail.Deposit_Payment_Frequency"
        class="form-control input-text-css"
        (change)="onChangeDepositPaymentFrequency()"
        [disabled]="
          FDR_Detail.Deposit_Interest_Type == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Payment_Frequency.invalid,
          'alert-warning':
            refDeposit_Payment_Frequency.invalid &&
            (refDeposit_Payment_Frequency.dirty ||
              refDeposit_Payment_Frequency.touched ||
              refDeposit_Payment_Frequency.untouched)
        }"
      >
        <option value="">Select Category</option>
        <option
          value="Monthly"
          [hidden]="
            FDR_Detail.Deposit_Interest_Type == 'Cumulative' &&
            (FDR_Detail.Deposit_Cummulative_Frequency == 'Monthly' ||
              FDR_Detail.Deposit_Cummulative_Frequency == 'Quarterly' ||
              FDR_Detail.Deposit_Cummulative_Frequency == 'HalfYearly' ||
              FDR_Detail.Deposit_Cummulative_Frequency == 'Yearly')
          "
        >
          Monthly
        </option>
        <option
          value="Quarterly"
          [hidden]="
            (FDR_Detail.Deposit_Interest_Type == 'Cumulative' &&
              FDR_Detail.Deposit_Cummulative_Frequency == 'Quarterly') ||
            FDR_Detail.Deposit_Cummulative_Frequency == 'HalfYearly' ||
            FDR_Detail.Deposit_Cummulative_Frequency == 'Yearly'
          "
        >
          Quarterly
        </option>
        <option
          value="HalfYearly"
          [hidden]="
            (FDR_Detail.Deposit_Interest_Type == 'Cumulative' &&
              FDR_Detail.Deposit_Cummulative_Frequency == 'HalfYearly') ||
            FDR_Detail.Deposit_Cummulative_Frequency == 'Yearly'
          "
        >
          Half-Yearly
        </option>
        <option
          value="Yearly"
          [hidden]="
            FDR_Detail.Deposit_Interest_Type == 'Cumulative' &&
            FDR_Detail.Deposit_Cummulative_Frequency == 'Yearly'
          "
        >
          Yearly
        </option>
        <option value="OnMaturity">On Maturity</option>
      </select>
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Deposit Amount</span>
      <input
        type="text"
        name="Deposit_Amount"
        id="Deposit_Amount"
        #refDeposit_Amount="ngModel"
        [(ngModel)]="FDR_Detail.Deposit_Amount"
        placeholder="Deposit Amount"
        class="form-control input-text-css"
        required
        numbersOnly
        (change)="onChangeDepositAmount()"
        [disabled]="
          FDR_Detail.Deposit_Interest_Type == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Amount.invalid,
          'alert-warning':
            refDeposit_Amount.invalid &&
            (refDeposit_Amount.dirty ||
              refDeposit_Amount.touched ||
              refDeposit_Amount.untouched)
        }"
      />
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Tenure (Month)</span>
      <input
        type="text"
        #refDeposit_Tenure="ngModel"
        name="Deposit_Tenure"
        id="Deposit_Tenure"
        numbersOnly
        (change)="onChangeTenure()"
        required
        [disabled]="
          FDR_Detail.Deposit_Amount == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [(ngModel)]="FDR_Detail.Deposit_Tenure"
        placeholder="Tenure (Month)"
        class="form-control input-text-css"
        minlength="1"
        maxlength="3"
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Tenure.invalid,
          'alert-warning':
            refDeposit_Tenure.invalid &&
            (refDeposit_Tenure.dirty ||
              refDeposit_Tenure.touched ||
              refDeposit_Tenure.untouched)
        }"
      />
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Interest Rate</span>
      <input
        type="text"
        #refDeposit_Interest_Rate="ngModel"
        name="Deposit_Interest_Rate"
        id="Deposit_Interest_Rate"
        required
        disabled
        [(ngModel)]="FDR_Detail.Deposit_Interest_Rate"
        placeholder="Interest Rate"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_Interest_Rate.invalid,
          'alert-warning':
            refDeposit_Interest_Rate.invalid &&
            (refDeposit_Interest_Rate.dirty ||
              refDeposit_Interest_Rate.touched ||
              refDeposit_Interest_Rate.untouched)
        }"
      />
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable"
        >Date of
        {{ FDR_Detail.Deposit_Type == "" ? "FD" : FDR_Detail.Deposit_Type }}
        Application</span
      >
      <!-- <input type="text" name="Date_of_FDRD" id="Date_of_FDRD" [(ngModel)]="FDR_Detail.Date_of_FDRD" placeholder="Date of {{FDR_Detail.Deposit_Type == '' ? 'FD' : FDR_Detail.Deposit_Type}} Application" 
        [minDate]="MinDateFDRD" [maxDate]="MaxDateFDRD" (change)="onChangeFDRDDate()"
        class="form-control input-text-css" #refDate_of_FDRD="ngModel" required bsDatepicker [disabled]="FDR_Detail.Deposit_Interest_Rate == '' || (Statustype != '' && Statustype != 'Reverted')"
        [(ngModel)]="FDR_Detail.Date_of_FDRD" class="form-control input-text-css" [ngClass]="{ 'is-invalid': fd.submitted && refDate_of_FDRD.invalid, 'alert-warning': refDate_of_FDRD.invalid  && (refDate_of_FDRD.dirty || refDate_of_FDRD.touched || refDate_of_FDRD.untouched) }"> -->
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="Date_of_FDRD"
          id="Date_of_FDRD"
          [min]="MinDateFDRD"
          [max]="MaxDateFDRD"
          #refDate_of_FDRD="ngModel"
          (dateChange)="onChangeFDRDDate()"
          required
          [disabled]="
            FDR_Detail.Deposit_Interest_Rate == '' ||
            (Statustype != '' && Statustype != 'Reverted')
          "
          [ngClass]="{
            'is-invalid': fd.submitted && refDate_of_FDRD.invalid,
            'alert-warning':
              refDate_of_FDRD.invalid &&
              (refDate_of_FDRD.dirty ||
                refDate_of_FDRD.touched ||
                refDate_of_FDRD.untouched)
          }"
          class="form-control input-text-css"
          [(ngModel)]="FDR_Detail.Date_of_FDRD"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Maturity Date</span>

      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="Date_of_Maturity"
          id="Date_of_Maturity"
          [(ngModel)]="FDR_Detail.Date_of_Maturity"
          #refDate_of_Maturity="ngModel"
          disabled
          required
          [(ngModel)]="FDR_Detail.Date_of_Maturity"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': fd.submitted && refDate_of_Maturity.invalid,
            'alert-warning':
              refDate_of_Maturity.invalid &&
              (refDate_of_Maturity.dirty ||
                refDate_of_Maturity.touched ||
                refDate_of_Maturity.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="Date_of_Maturity"
        id="Date_of_Maturity"
        [(ngModel)]="FDR_Detail.Date_of_Maturity"
        placeholder="Maturity Date"
        bsDatepicker
        class="form-control input-text-css"
        #refDate_of_Maturity="ngModel"
        disabled
        dateConvert
        required
        [(ngModel)]="FDR_Detail.Date_of_Maturity"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refDate_of_Maturity.invalid,
          'alert-warning':
            refDate_of_Maturity.invalid &&
            (refDate_of_Maturity.dirty ||
              refDate_of_Maturity.touched ||
              refDate_of_Maturity.untouched)
        }"
      /> -->
    </div>

    <!-- <div class="col-md-3 mt-4 p-1" *ngIf="FDR_Detail.Date_of_FDRD != ''">
      <div class="form-check">
        <input type="checkbox" name="Deposit_IsPreMature" class="form-check-input" id="Deposit_IsPreMature" [(ngModel)]="FDR_Detail.Deposit_IsPreMature" disabled>
        <label class="form-check-label" for="Deposit_IsPreMature" style="margin-top: 2px;">PreMature Enable</label>
      </div>
    </div> -->

    <div class="col-md-3 p-1" *ngIf="FDR_Detail.Date_of_FDRD != ''">
      <span class="required-lable">Freeze UpTo</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker3"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="Lockin_Date"
          id="Lockin_Date"
          #refVoucher_Date="ngModel"
          class="form-control input-text-css"
          #refLockin_Date="ngModel"
          disabled
          required
          [(ngModel)]="FDR_Detail.Lockin_Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </div>
      <!-- <input
        name="Lockin_Date"
        id="Lockin_Date"
        [(ngModel)]="FDR_Detail.Lockin_Date"
        placeholder="Freeze UpTo"
        bsDatepicker
        class="form-control input-text-css"
        #refLockin_Date="ngModel"
        disabled
        required
        [(ngModel)]="FDR_Detail.Lockin_Date"
        class="form-control input-text-css"
      /> -->
    </div>

    <!-- <div class="col-md-3 p-1">
      <span class="required-lable">Interest Amount</span>
      <input type="text" #refInterest_Amount="ngModel" name="Interest_Amount" id="Interest_Amount" disabled required
        [(ngModel)]="FDR_Detail.Interest_Amount" placeholder="Interest Amount" class="form-control input-text-css"
        [ngClass]="{ 'is-invalid': fd.submitted && refInterest_Amount.invalid, 'alert-warning': refInterest_Amount.invalid  && (refInterest_Amount.dirty || refInterest_Amount.touched || refInterest_Amount.untouched) }">
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Maturity Amount</span>
      <input type="text" #refMaturity_Amount="ngModel" name="Maturity_Amount" id="Maturity_Amount" disabled required
        [(ngModel)]="FDR_Detail.Maturity_Amount" placeholder="Maturity Amount" class="form-control input-text-css" 
        [ngClass]="{ 'is-invalid': fd.submitted && refMaturity_Amount.invalid, 'alert-warning': refMaturity_Amount.invalid  && (refMaturity_Amount.dirty || refMaturity_Amount.touched || refMaturity_Amount.untouched) }">
    </div> -->

    <div class="col-md-3 p-1">
      <span class="required-lable">Branch</span>
      <select
        name="Deposit_BranchId"
        id="Deposit_BranchId"
        #refDeposit_BranchId="ngModel"
        [(ngModel)]="FDR_Detail.Deposit_BranchId"
        class="form-control input-text-css"
        required
        (change)="GetDepositNumber()"
        [disabled]="
          FDR_Detail.Lockin_Date == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_BranchId.invalid,
          'alert-warning':
            refDeposit_BranchId.invalid &&
            (refDeposit_BranchId.dirty ||
              refDeposit_BranchId.touched ||
              refDeposit_BranchId.untouched)
        }"
      >
        <option value="">Select Branch</option>
        <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
          {{ branch.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span class="required-lable"
        >{{
          FDR_Detail.Deposit_Type == "" ? "FD" : FDR_Detail.Deposit_Type
        }}
        No</span
      >
      <input
        type="text"
        name="Deposit_No"
        id="Deposit_No"
        #refDeposit_No="ngModel"
        [(ngModel)]="FDR_Detail.Deposit_No"
        disabled
        placeholder="{{
          FDR_Detail.Deposit_Type == '' ? 'FD' : FDR_Detail.Deposit_Type
        }} No"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_No.invalid,
          'alert-warning':
            refDeposit_No.invalid &&
            (refDeposit_No.dirty ||
              refDeposit_No.touched ||
              refDeposit_No.untouched)
        }"
      />
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Source Type</span>
      <select
        name="SourceType"
        id="SourceType"
        #refSourceType="ngModel"
        required
        (change)="Get_PartnerForDropdown()"
        [ngClass]="{
          'is-invalid': fd.submitted && refSourceType.invalid,
          'alert-warning':
            refSourceType.invalid &&
            (refSourceType.dirty ||
              refSourceType.touched ||
              refSourceType.untouched)
        }"
        [(ngModel)]="FDR_Detail.SourceType"
        class="form-control input-text-css"
        [disabled]="
          FDR_Detail.Deposit_BranchId == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
      >
        <option value="">Select Source Type</option>
        <option
          *ngFor="let lead of leadSourceDropdown"
          [value]="lead.Lead_Source"
        >
          {{ lead.Lead_Source }}
        </option>
      </select>
    </div>
    <div
      class="col-md-3 p-1"
      *ngIf="
        FDR_Detail.SourceType == 'Agent' ||
        FDR_Detail.SourceType == 'DSA' ||
        FDR_Detail.SourceType == 'Dealer' ||
        FDR_Detail.SourceType == 'Sales Executive'
      "
    >
      <span class="required-lable">Source Name</span>
      <select
        name="SourceName"
        id="SourceName"
        #refSourceName="ngModel"
        required
        [(ngModel)]="FDR_Detail.SourceName"
        class="form-control input-text-css"
        [disabled]="
          FDR_Detail.SourceType == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refSourceName.invalid,
          'alert-warning':
            refSourceName.invalid &&
            (refSourceName.dirty ||
              refSourceName.touched ||
              refSourceName.untouched)
        }"
      >
        <option value="">Select Lead Reference</option>
        <option
          *ngFor="let partner of partnerDropdown"
          [value]="partner.PartnerId"
        >
          {{ partner.Partner_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1" *ngIf="FDR_Detail.SourceType == 'Reference'">
      <span class="required-lable">Source Name</span>
      <input
        type="text"
        name="SourceName"
        id="SourceName"
        #refSourceName="ngModel"
        [(ngModel)]="FDR_Detail.SourceName"
        required
        placeholder="Source Name"
        class="form-control input-text-css"
        [disabled]="
          FDR_Detail.SourceType == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refSourceName.invalid,
          'alert-warning':
            refSourceName.invalid &&
            (refSourceName.dirty ||
              refSourceName.touched ||
              refSourceName.untouched)
        }"
      />
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Customer Category</span>
      <select
        name="Deposit_CustomerCategory"
        id="Deposit_CustomerCategory"
        #refDeposit_CustomerCategory="ngModel"
        class="form-control input-text-css"
        (change)="ChangeDepositType()"
        [disabled]="
          FDR_Detail.SourceType == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [(ngModel)]="FDR_Detail.Deposit_CustomerCategory"
        required
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_CustomerCategory.invalid,
          'alert-warning':
            refDeposit_CustomerCategory.invalid &&
            (refDeposit_CustomerCategory.dirty ||
              refDeposit_CustomerCategory.touched ||
              refDeposit_CustomerCategory.untouched)
        }"
      >
        <option value="">Select Category</option>
        <option value="Director">Director</option>
        <option value="ShareHolder">Share Holder</option>
        <option value="DirectorsRelatives">Director's Relatives</option>
        <option value="Staff">Staff</option>
        <option value="Others">Others</option>
      </select>
    </div>

    <div class="col-md-12 mt-1 p-1">
      <div class="form-check">
        <input
          type="checkbox"
          name="Auto_Renewal"
          id="Auto_Renewal"
          class="form-check-input"
          [(ngModel)]="FDR_Detail.Auto_Renewal"
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
        />
        <label
          class="form-check-label"
          for="Auto_Renewal"
          style="margin-top: 2px"
          >Auto Renewal</label
        >
      </div>
    </div>
    <div class="col-md-12 mt-1 p-1">
      <div class="form-check">
        <input
          type="checkbox"
          name="Is_seniorcitizen"
          id="Is_seniorcitizen"
          class="form-check-input"
          [(ngModel)]="FDR_Detail.Is_seniorcitizen"
          (change)="onChangeTenure()"
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
        />
        <label
          class="form-check-label"
          for="Is_seniorcitizen"
          style="margin-top: 2px"
          >Is Senior Citizen</label
        >
      </div>
    </div>
  </div>

  <div
    class="row m-0 mb-1 p-1 mt-2 col-md-12 align-items-center justify-content-between bg-success1"
  >
    <h1 class="fs-12 m-0">Customer Details:</h1>
    <app-add-customer-info
      [TypeFrom]="'Deposit'"
      [loan_Summary]="DepositSummary"
      [Customer_Summary]="CustomerSummary"
      (close)="GetData($event)"
      [index]="999978465123102"
      [Heading]="'Add'"
    >
      <button
        type="button"
        class="btn_green"
        color="primary"
        [disabled]="
          FDR_Detail.Deposit_CustomerCategory == '' ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [hidden]="Statustype != '' && Statustype != 'Reverted'"
      >
        Add Customer
      </button>
    </app-add-customer-info>
  </div>

  <div class="row m-0">
    <div class="col-md-12 p-1">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerSource">
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
                >{{ row.Customer }}</app-lms-customer-view-model
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Customer Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerType
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Gender/Age</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.GenderAge }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ExistingCustomer">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Is Existing</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ExistingCustomer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Relation_Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Relation_Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Phone No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.PhoneNo
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Present_Address">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Present Address</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Present_Address
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 80px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell j-c-center"
              style="max-width: 80px"
            >
              <!-- <app-add-customer-info [TypeFrom]="'Deposit'" [loan_Summary]="DepositSummary" [Customer_Summary]="DepositSummary" [CustomerData]="row" [index]="i" [Heading]="'Edit'">
                <i class="fa fa-pencil-alt right btn_detail" [hidden]="Statustype != '' && Statustype != 'Reverted'"></i>
              </app-add-customer-info> -->
              <i
                class="fa fa-minus-circle font-size-15 ml-2 btn_delete"
                (click)="DeleteCustomerDetail(i)"
                title="Remove this Customer"
                aria-label="true"
                [hidden]="Statustype != '' && Statustype != 'Reverted'"
              ></i>
              <!--  *ngIf="Statustype != '' && Statustype != 'Reverted'" *ngIf="row.CustomerType != 'Primary'" -->
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <ng-container *ngIf="CData.length > 0">
    <div class="row m-0 mt-2 p-1">
      <div class="col-md-12 p-1 bg-success1">Maturity Details:</div>
    </div>
    <div class="row m-0">
      <div
        class="p-1"
        [ngClass]="
          FDR_Detail.Deposit_Payment_Frequency != 'OnMaturity' &&
          FDR_Detail.Interest_AmountPF > 0
            ? 'col-md-6'
            : 'col-md-12'
        "
      >
        <div class="alert alert-primary">
          <h6>On Maturity:</h6>
          <hr />
          <B>Deposit Amount :</B> {{ FDR_Detail.Deposit_Amount }}<br />
          <B>Interest Amount :</B> {{ FDR_Detail.Interest_Amount }}<br />
          <B>Maturity Amount :</B> {{ FDR_Detail.Maturity_Amount }}
        </div>
      </div>
      <div
        class="col-md-6 p-1"
        *ngIf="
          FDR_Detail.Deposit_Payment_Frequency != 'OnMaturity' &&
          FDR_Detail.Interest_AmountPF > 0
        "
      >
        <div class="alert alert-info">
          <h6>On Payment Frequency:</h6>
          <hr />
          <B>Deposit Amount :</B> {{ FDR_Detail.Deposit_Amount }}<br />
          <B>Interest Amount :</B> {{ FDR_Detail.Interest_AmountPF }}<br />
          <B>Maturity Amount :</B> {{ FDR_Detail.Maturity_AmountPF }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row m-0 mt-2 p-1">
    <div class="col-md-12 p-1 bg-success1">Amount Received Details:</div>
  </div>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span class="required-lable">Received Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker4"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="Received_Date"
          id="Received_Date"
          [(ngModel)]="FDR_Detail.Received_Date"
          class="form-control input-text-css"
          [disabled]="
            CData.length == 0 || (Statustype != '' && Statustype != 'Reverted')
          "
          [(ngModel)]="FDR_Detail.Received_Date"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': fd.submitted && refReceived_Date.invalid,
            'alert-warning':
              refReceived_Date.invalid &&
              (refReceived_Date.dirty ||
                refReceived_Date.touched ||
                refReceived_Date.untouched)
          }"
          #refReceived_Date="ngModel"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker4"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </div>
      <!-- <input
        type="text"
        name="Received_Date"
        id="Received_Date"
        [(ngModel)]="FDR_Detail.Received_Date"
        placeholder="Received Date"
        [minDate]="minDate"
        class="form-control input-text-css"
        #refReceived_Date="ngModel"
        required
        bsDatepicker
        [disabled]="
          CData.length == 0 || (Statustype != '' && Statustype != 'Reverted')
        "
        [(ngModel)]="FDR_Detail.Received_Date"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refReceived_Date.invalid,
          'alert-warning':
            refReceived_Date.invalid &&
            (refReceived_Date.dirty ||
              refReceived_Date.touched ||
              refReceived_Date.untouched)
        }"
      /> -->
    </div>

    <div class="col-md-3 p-1">
      <span class="required-lable">Bank/Cash</span>
      <select
        name="Pay_Mode"
        id="Pay_Mode"
        required
        #refPay_Mode="ngModel"
        [disabled]="
          CData.length == 0 || (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refPay_Mode.invalid,
          'alert-warning':
            refPay_Mode.invalid &&
            (refPay_Mode.dirty || refPay_Mode.touched || refPay_Mode.untouched)
        }"
        [(ngModel)]="FDR_Detail.Pay_Mode"
        class="form-control input-text-css"
        (change)="onPayModeChange()"
      >
        <option value="">Select Type</option>
        <option value="Bank">Bank</option>
        <option value="Cash">Cash</option>
      </select>
    </div>

    <div class="col-md-3 p-1" *ngIf="FDR_Detail.Pay_Mode != ''">
      <span class="required-lable">Select Account</span>
      <select
        name="AccountId"
        id="AccountId"
        required
        #refAccountId="ngModel"
        [disabled]="Statustype != '' && Statustype != 'Reverted'"
        [ngClass]="{
          'is-invalid': fd.submitted && refAccountId.invalid,
          'alert-warning':
            refAccountId.invalid &&
            (refAccountId.dirty ||
              refAccountId.touched ||
              refAccountId.untouched)
        }"
        [(ngModel)]="FDR_Detail.AccountId"
        class="form-control input-text-css"
      >
        <option value="">Select Account</option>
        <option
          *ngFor="let data of accountTagDropdown"
          [value]="data.AccountId"
        >
          {{ data.Account_Name }}
        </option>
      </select>
    </div>

    <div
      class="col-md-3 p-1"
      *ngIf="FDR_Detail.AccountId != '' && FDR_Detail.Pay_Mode == 'Bank'"
    >
      <span class="required-lable">Received Type</span>
      <select
        name="Received_Type"
        id="Received_Type"
        #refReceived_Type="ngModel"
        required
        [disabled]="Statustype != '' && Statustype != 'Reverted'"
        [(ngModel)]="FDR_Detail.Received_Type"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': fd.submitted && refReceived_Type.invalid,
          'alert-warning':
            refReceived_Type.invalid &&
            (refReceived_Type.dirty ||
              refReceived_Type.touched ||
              refReceived_Type.untouched)
        }"
      >
        <option value="">Select Mode</option>
        <option value="Cheque">Cheque</option>
        <option value="RTGS">RTGS</option>
        <option value="Gateway">E-Gateway</option>
        <option value="NEFT">NEFT</option>
      </select>
    </div>

    <ng-container
      *ngIf="
        FDR_Detail.Received_Type != '' && FDR_Detail.Received_Type == 'Cheque'
      "
    >
      <div class="col-md-3 p-1">
        <span class="required-lable">Cheque No</span>
        <input
          type="text"
          name="Received_ChequeNo"
          id="Received_ChequeNo"
          [(ngModel)]="FDR_Detail.Received_ChequeNo"
          placeholder="Cheque No"
          class="form-control input-text-css"
          #refReceived_ChequeNo="ngModel"
          required
          maxlength="6"
          minlength="6"
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
          numbersOnly
          [(ngModel)]="FDR_Detail.Received_ChequeNo"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': fd.submitted && refReceived_ChequeNo.invalid,
            'alert-warning':
              refReceived_ChequeNo.invalid &&
              (refReceived_ChequeNo.dirty ||
                refReceived_ChequeNo.touched ||
                refReceived_ChequeNo.untouched)
          }"
        />
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable">Cheque Amount</span>
        <input
          type="text"
          name="Received_ChequeAmount"
          id="Received_ChequeAmount"
          [(ngModel)]="FDR_Detail.Received_ChequeAmount"
          placeholder="Cheque Amount"
          numbersOnly
          class="form-control input-text-css"
          #refReceived_ChequeAmount="ngModel"
          required
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
          [(ngModel)]="FDR_Detail.Received_ChequeAmount"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': fd.submitted && refReceived_ChequeAmount.invalid,
            'alert-warning':
              refReceived_ChequeAmount.invalid &&
              (refReceived_ChequeAmount.dirty ||
                refReceived_ChequeAmount.touched ||
                refReceived_ChequeAmount.untouched)
          }"
        />
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable">Cheque Date </span>
        <div class="datepicker_feild">
          <input
            name="Received_ChqueDate"
            id="Received_ChqueDate"
            [matDatepicker]="pickervcd"
            dateConvert
            placeholder="Cheque Date"
            #refReceived_ChqueDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="FDR_Detail.Received_ChqueDate"
            [disabled]="Statustype != '' && Statustype != 'Reverted'"
            [ngClass]="{
              'is-invalid': fd.submitted && refReceived_ChqueDate.invalid,
              'alert-warning':
                refReceived_ChqueDate.invalid &&
                (refReceived_ChqueDate.dirty ||
                  refReceived_ChqueDate.touched ||
                  refReceived_ChqueDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickervcd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickervcd></mat-datepicker>
        </div>
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable">Bank Name</span>
        <input
          type="text"
          #refReceived_Bank_Name="ngModel"
          name="Received_Bank_Name"
          id="Received_Bank_Name"
          required
          placeholder="Bank Name"
          [ngClass]="{
            'is-invalid': fd.submitted && refReceived_Bank_Name.invalid,
            'alert-warning':
              refReceived_Bank_Name.invalid &&
              (refReceived_Bank_Name.dirty ||
                refReceived_Bank_Name.touched ||
                refReceived_Bank_Name.untouched)
          }"
          [(ngModel)]="FDR_Detail.Received_Bank_Name"
          class="form-control input-text-css"
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
        />
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        FDR_Detail.Received_Type != '' &&
        FDR_Detail.Received_Type != 'Cheque' &&
        FDR_Detail.Received_Type != 'CASH'
      "
    >
      <div class="col-md-3 p-1">
        <span class="required-lable">Ref No</span>
        <input
          type="text"
          name="Received_Reference_No"
          id="Received_Reference_No"
          [(ngModel)]="FDR_Detail.Received_Reference_No"
          required
          #refReceived_Reference_No="ngModel"
          class="form-control input-text-css"
          placeholder="Ref No"
          [disabled]="Statustype != '' && Statustype != 'Reverted'"
          [ngClass]="{
            'is-invalid': fd.submitted && refReceived_Reference_No.invalid,
            'alert-warning':
              refReceived_Reference_No.invalid &&
              (refReceived_Reference_No.dirty ||
                refReceived_Reference_No.touched ||
                refReceived_Reference_No.untouched)
          }"
        />
      </div>
    </ng-container>
  </div>

  <!-- <div class="row m-0">
    <div class="col-md-6 mt-2 p-1"><h1 class="fs-12 m-0">Customer Details</h1></div>
    <div class="col-md-6 p-1 text-right">
      <app-add-customer-info [TypeFrom]="'Deposit'" [loan_Summary]="DepositSummary" [Customer_Summary]="CustomerSummary" (close)="GetData($event)" [index]="999978465123102"  [Heading]="'Add'">
        <button type="button" class="btn_green" color="primary">Add Customer</button>
      </app-add-customer-info>
    </div>
  </div> -->

  <div
    class="row m-0 mb-1 p-1 mt-3 col-md-12 align-items-center justify-content-between bg-success1"
  >
    <h1 class="fs-12 m-0">Nominee Details:</h1>
    <button
      type="button"
      class="btn_green"
      color="primary"
      (click)="AddFDRNominee()"
      [hidden]="Statustype != '' && Statustype != 'Reverted'"
    >
      Add Nominee
    </button>
    <!-- <button type="button" class="btn_green" color="primary" (click)="AddFDRNominee()" [disabled]="CData.length == 0" [hidden]="Statustype != '' && Statustype != 'Reverted'">Add Nominee</button> -->
  </div>

  <div class="row m-0">
    <div class="col-md-12 p-1">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerNominee">
          <ng-container matColumnDef="NomineeId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Nominee_Relation">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Nominee Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Nominee_Relation
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Nominee_Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Nominee Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Nominee_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Nominee_DOB">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Nominee DOB</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Nominee_DOB | date : "dd/MM/yyyy" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Nominee_Ratio">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Nominee Ratio %</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Nominee_Ratio }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 80px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell j-c-center"
              style="max-width: 80px"
            >
              <!-- <i class="fa fa-pencil-alt right btn_detail" (click)="EditNominee(row, i)" [hidden]="Statustype != '' && Statustype != 'Reverted'"></i> -->
              <i
                class="fa fa-minus-circle font-size-15 ml-2 btn_delete"
                (click)="DeleteNomineeDetail(i)"
                title="Remove this Nominee"
                aria-label="true"
                [hidden]="Statustype != '' && Statustype != 'Reverted'"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedNomineeColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedNomineeColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div
    class="row m-0 mb-1 p-1 mt-3 col-md-12 align-items-center justify-content-between"
  >
    <div class="col-md-4 p-1">
      <span class="required-lable">Repayment Bank</span>
      <select
        name="Pay_BankId"
        id="Pay_BankId"
        #refPay_BankId="ngModel"
        required
        [(ngModel)]="FDR_Detail.Pay_BankId"
        class="form-control input-text-css"
        (change)="onChangeRepaymentBank()"
        [disabled]="
          NomineeData.length == 0 ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refPay_BankId.invalid,
          'alert-warning':
            refPay_BankId.invalid &&
            (refPay_BankId.dirty ||
              refPay_BankId.touched ||
              refPay_BankId.untouched)
        }"
      >
        <option value="">Select Bank</option>
        <option *ngFor="let Bank of CustomerBankDropdown" [value]="Bank.Id">
          {{ Bank.AccountNo }} - ({{ Bank.BankName }})
        </option>
      </select>
    </div>
    <div class="col-md-4 p-1">
      <span class="required-lable">Mode Of Opertion</span>
      <select
        name="Deposit_ModeOfOperation"
        id="Deposit_ModeOfOperation"
        #refDeposit_ModeOfOperation="ngModel"
        required
        [(ngModel)]="FDR_Detail.Deposit_ModeOfOperation"
        class="form-control input-text-css"
        [disabled]="
          NomineeData.length == 0 ||
          (Statustype != '' && Statustype != 'Reverted')
        "
        [ngClass]="{
          'is-invalid': fd.submitted && refDeposit_ModeOfOperation.invalid,
          'alert-warning':
            refDeposit_ModeOfOperation.invalid &&
            (refDeposit_ModeOfOperation.dirty ||
              refDeposit_ModeOfOperation.touched ||
              refDeposit_ModeOfOperation.untouched)
        }"
      >
        <option value="">Select Mode</option>
        <option value="Self">Self</option>
        <option value="EitherORSurvivor" [hidden]="CData.length == 1">
          Either OR Survivor
        </option>
        <option value="Joint" [hidden]="CData.length == 1">Joint</option>
      </select>
    </div>
    <div class="col-md-4 p-1 text-right">
      <button
        type="button"
        class="btn_green"
        color="primary"
        (click)="AddCustomerBank()"
        [disabled]="NomineeData.length == 0"
        [hidden]="Statustype != '' && Statustype != 'Reverted'"
      >
        Add Bank
      </button>
    </div>
  </div>

  <ng-container *ngIf="FDR_Detail.Pay_BankId != ''">
    <div class="row m-0">
      <div class="col-md-12 p-1"><hr /></div>
    </div>

    <div class="row m-0">
      <div class="col-md-2 p-1">
        <span>Beneficiary Name</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.CustomerBankName : "" }}
        </div>
      </div>
      <div class="col-md-2 p-1">
        <span>A/C No.</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.AccountNo : "" }}
        </div>
      </div>
      <div class="col-md-2 p-1">
        <span>Bank Name</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.BankName : "" }}
        </div>
      </div>
      <div class="col-md-2 p-1">
        <span>Bank Branch</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.BankBranch : "" }}
        </div>
      </div>
      <div class="col-md-2 p-1">
        <span>Bank IFSC</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.BankIFSC : "" }}
        </div>
      </div>
      <div class="col-md-2 p-1">
        <span>A/C Type</span>
        <div class="form-control input-text-css disabled">
          {{ BankInfo ? BankInfo.BankAcType : "" }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="AmortizationData && AmortizationData.length > 0">
    <div
      class="row m-0 mb-1 p-1 mt-3 col-md-12 align-items-center justify-content-between"
      [hidden]="Statustype == 'Reverted' || Statustype == 'Pending'"
    >
      <h1 class="fs-12 m-0">Amortization Details:</h1>
    </div>
    <div
      class="row m-0"
      [hidden]="Statustype == 'Reverted' || Statustype == 'Pending'"
    >
      <div class="col-md-12 p-1">
        <div class="table-responsive">
          <mat-table [dataSource]="dataAmortization">
            <ng-container matColumnDef="Id">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Period">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Period</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Period
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Due_Date">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Due Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Due_Date }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Interest">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Interest</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Interest }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Payment">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Payment</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Payment }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Interest_Balance">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Mature Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Interest_Balance }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedAmortizationColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedAmortizationColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row m-0">
    <div class="col-md-12 p-1"><hr /></div>
  </div>

  <div class="row m-0" *ngIf="PageFrom != 'Authorize'">
    <div class="col-md-12 p-1 text-right">
      <button
        type="button"
        (click)="onSaveNewDeposit()"
        *ngIf="IsSave"
        [class.spinner]="loading"
        class="btn font-size-12 button-btn"
        [disabled]="
          !fd.form.valid || CData.length == 0 || NomineeData.length == 0
        "
      >
        Save
      </button>
      <button
        type="button"
        (click)="onSaveNewDeposit()"
        *ngIf="IsUpdate"
        mat-raised-button
        [class.spinner]="loading"
        class="btn font-size-12 button-btn"
        [disabled]="
          !fd.form.valid || CData.length == 0 || NomineeData.length == 0
        "
      >
        Update
      </button>
    </div>
  </div>
  <div *ngIf="PageFrom == 'Authorize'">
    <div class="row m-0">
      <div class="col-md-4 p-1">
        <span class="required-lable">Status</span>
        <select
          name="Status"
          id="Status"
          class="form-control input-text-css"
          [(ngModel)]="Status"
          #refStatus="ngModel"
          required
          [disabled]="Statustype != 'Pending'"
          [ngClass]="{
            'is-invalid': fd.submitted && refStatus.invalid,
            'alert-warning':
              refStatus.invalid &&
              (refStatus.dirty || refStatus.touched || refStatus.untouched)
          }"
        >
          <option value="">Select Status</option>
          <option value="Authorized">Authorized</option>
          <option value="Reverted">Reverted</option>
        </select>
      </div>
      <div class="col-md-8 p-1">
        <span class="required-lable">Remark</span>
        <input
          type="text"
          [(ngModel)]="Remark"
          name="Remark"
          id="Remark"
          class="form-control input-text-css"
          required
          placeholder="Remark"
          #refRemark="ngModel"
          [disabled]="Statustype != 'Pending'"
          [ngClass]="{
            'is-invalid': fd.submitted && refRemark.invalid,
            'alert-warning':
              refRemark.invalid &&
              (refRemark.dirty || refRemark.touched || refRemark.untouched)
          }"
        />
      </div>
    </div>

    <div class="row col-md-12 m-0 mt-1 p-1 justify-content-end">
      <button
        type="button"
        (click)="closeAuthorizeDetail()"
        class="ml-4 btn btn-light font-size-12"
        [hidden]="Statustype != '' && Statustype != 'Reverted'"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn font-size-12 button-btn"
        mat-raised-button
        (click)="saveAuthorizeDetail()"
        [class.spinner]="loading"
        [disabled]="!fd.form.valid"
        *ngIf="Statustype == 'Pending'"
      >
        Save
      </button>
      <button
        type="button"
        (click)="onSaveNewDeposit()"
        *ngIf="Statustype == 'Reverted'"
        mat-raised-button
        [class.spinner]="loading"
        class="btn font-size-12 button-btn"
        [disabled]="!fd.form.valid"
      >
        Update
      </button>
    </div>
  </div>
</form>

<div
  class="modal fade in"
  id="addNominee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Nominee
        </h6>
        <button
          type="button"
          (click)="onCloseNomineeModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <form #ancf="ngForm" (ngSubmit)="onSaveNominee()" novalidate>
          <div class="row m-0">
            <div class="col-md-12 bg-success1">Nominee Details:</div>
          </div>
          <div
            class="row mt-2"
            *ngFor="let Nominee of FDR_Nominee; let i = index"
          >
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-3">
                  <span class="required-lable">Nominee Relation</span>
                  <select
                    name="Nominee_Relation{{ i }}"
                    id="Nominee_Relation{{ i }}"
                    #refNominee_Relation="ngModel"
                    (change)="onChangeNomineeRelation(Nominee, i)"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refNominee_Relation.invalid,
                      'alert-warning':
                        refNominee_Relation.invalid &&
                        (refNominee_Relation.dirty ||
                          refNominee_Relation.touched ||
                          refNominee_Relation.untouched)
                    }"
                    [(ngModel)]="Nominee.Nominee_Relation"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Brother">Brother</option>
                    <!-- <option value="Father In-Law"> Father In-Law</option>
                    <option value="Mother In-Law"> Mother In-Law</option>
                    <option value="Brother In-Law"> Brother In-Law</option>
                    <option value="Friend"> Friend</option>
                    <option value="Other"> Other</option>
                    <option value="None"> None</option> -->
                  </select>
                </div>

                <div class="col-md-3">
                  <span class="required-lable">Nominee Name</span>
                  <input
                    type="text"
                    name="Nominee_Name{{ i }}"
                    id="Nominee_Name{{ i }}"
                    [(ngModel)]="Nominee.Nominee_Name"
                    #refNominee_Name="ngModel"
                    placeholder="Nominee Name"
                    class="form-control input-text-css"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refNominee_Name.invalid,
                      'alert-warning':
                        refNominee_Name.invalid &&
                        (refNominee_Name.dirty ||
                          refNominee_Name.touched ||
                          refNominee_Name.untouched)
                    }"
                  />
                </div>

                <div class="col-md-3">
                  <span class="required-lable">Nominee DOB</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker5"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="Nominee_DOB{{ i }}"
                      id="Nominee_DOB{{ i }}"
                      [(ngModel)]="Nominee.Nominee_DOB"
                      #refNominee_DOB="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refNominee_DOB.invalid,
                        'alert-warning':
                          refNominee_DOB.invalid &&
                          (refNominee_DOB.dirty ||
                            refNominee_DOB.touched ||
                            refNominee_DOB.untouched)
                      }"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </div>
                  <!-- <input
                    type="text"
                    name="Nominee_DOB{{ i }}"
                    id="Nominee_DOB{{ i }}"
                    [(ngModel)]="Nominee.Nominee_DOB"
                    #refNominee_DOB="ngModel"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-text-css"
                    required
                    value="{{ Nominee.Nominee_DOB | date : 'dd/MM/yyyy' }}"
                    [(bsValue)]="Nominee.Nominee_DOB"
                    bsDatepicker
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refNominee_DOB.invalid,
                      'alert-warning':
                        refNominee_DOB.invalid &&
                        (refNominee_DOB.dirty ||
                          refNominee_DOB.touched ||
                          refNominee_DOB.untouched)
                    }"
                  /> -->
                </div>

                <div class="col-md-3">
                  <span class="required-lable">Nominee Ratio %</span>
                  <input
                    type="text"
                    name="Nominee_Ratio{{ i }}"
                    id="Nominee_Ratio{{ i }}"
                    [(ngModel)]="Nominee.Nominee_Ratio"
                    #refNominee_Ratio="ngModel"
                    placeholder="Nominee Ratio"
                    class="form-control input-text-css"
                    maxlength="3"
                    numbersOnly
                    required
                    (change)="onChangeNomineeRatio(i)"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refNominee_Ratio.invalid,
                      'alert-warning':
                        refNominee_Ratio.invalid &&
                        (refNominee_Ratio.dirty ||
                          refNominee_Ratio.touched ||
                          refNominee_Ratio.untouched)
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-1 pt-3">
              <button
                type="button"
                class="remove"
                (click)="removeNomineeDetail(i)"
                *ngIf="i < FDR_Nominee.length && FDR_Nominee.length > 1"
              >
                -
              </button>
              <button
                type="button"
                class="addmore"
                (click)="addNomineeDetail()"
                *ngIf="i == FDR_Nominee.length - 1"
              >
                +
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onCloseNomineeModel()"
              class="btn btn-light font-size-12"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveNominee()"
              *ngIf="BtnSave"
              class="ml-4 btn_default"
              mat-raised-button
              [class.spinner]="loading"
              [disabled]="!ancf.form.valid"
            >
              Save
            </button>
            <button
              type="button"
              (click)="onUpdateNominee()"
              *ngIf="BtnUpdate"
              class="ml-4 btn_default"
              mat-raised-button
              [class.spinner]="loading"
              [disabled]="!ancf.form.valid"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addCustomerBank"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Bank Details
        </h6>
        <button
          type="button"
          (click)="onCloseCustomerBankModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <form #rf="ngForm" (ngSubmit)="onSaveCustomerBank()" novalidate>
          <div class="row m-0">
            <div class="col-md-3 p-0">
              <span class="required-lable">Customer</span>
              <select
                name="CustomerId"
                id="CustomerId"
                required
                placeholder="Select Customer Name"
                [(ngModel)]="CustomerId"
                class="form-control input-text-css"
                #refCustomerId="ngModel"
                [ngClass]="{
                  'is-invalid': rf.submitted && refCustomerId.invalid,
                  'alert-warning':
                    refCustomerId.invalid &&
                    (refCustomerId.dirty ||
                      refCustomerId.touched ||
                      refCustomerId.untouched)
                }"
              >
                <option value="">Select Customer</option>
                <option
                  *ngFor="let customer of CData"
                  [value]="customer.CustomerId"
                >
                  {{ customer.Customer }} ({{ customer.CustomerType }})
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-12 bg-success1">Bank Details:</div>
          </div>
          <div
            class="row mt-2"
            *ngFor="let CBank of BankDetails; let i = index"
          >
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-2">
                  <span class="required-lable">Beneficiary Name</span>
                  <textarea
                    rows="1"
                    name="BeneficiaryName{{ i }}"
                    id="BeneficiaryName{{ i }}"
                    [(ngModel)]="CBank.BeneficiaryName"
                    #refBeneficiaryName="ngModel"
                    placeholder="Beneficiary"
                    class="form-control input-text-css"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refBeneficiaryName.invalid,
                      'alert-warning':
                        refBeneficiaryName.invalid &&
                        (refBeneficiaryName.dirty ||
                          refBeneficiaryName.touched ||
                          refBeneficiaryName.untouched)
                    }"
                  ></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">A/C No</span>
                  <textarea
                    rows="1"
                    name="AccountNo{{ i }}"
                    id="AccountNo{{ i }}"
                    [(ngModel)]="CBank.AccountNo"
                    #refAccountNo="ngModel"
                    placeholder="A/C No"
                    class="form-control input-text-css"
                    (change)="ChangeACNo(CBank, i)"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refAccountNo.invalid,
                      'alert-warning':
                        refAccountNo.invalid &&
                        (refAccountNo.dirty ||
                          refAccountNo.touched ||
                          refAccountNo.untouched)
                    }"
                  ></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">Bank Name</span>
                  <textarea
                    rows="1"
                    name="BankName{{ i }}"
                    id="BankName{{ i }}"
                    [(ngModel)]="CBank.BankName"
                    #refBankName="ngModel"
                    placeholder="Bank Name"
                    class="form-control input-text-css"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refBankName.invalid,
                      'alert-warning':
                        refBankName.invalid &&
                        (refBankName.dirty ||
                          refBankName.touched ||
                          refBankName.untouched)
                    }"
                  ></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">Bank Branch</span>
                  <textarea
                    rows="1"
                    name="BankBranch{{ i }}"
                    id="BankBranch{{ i }}"
                    [(ngModel)]="CBank.BankBranch"
                    #refBankBranch="ngModel"
                    placeholder="Bank Branch"
                    class="form-control input-text-css"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refBankBranch.invalid,
                      'alert-warning':
                        refBankBranch.invalid &&
                        (refBankBranch.dirty ||
                          refBankBranch.touched ||
                          refBankBranch.untouched)
                    }"
                  ></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">A/C Type</span>
                  <select
                    name="BankAcType{{ i }}"
                    id="BankAcType{{ i }}"
                    [(ngModel)]="CBank.BankAcType"
                    #refBankAcType="ngModel"
                    class="form-control input-text-css"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refBankAcType.invalid,
                      'alert-warning':
                        refBankAcType.invalid &&
                        (refBankAcType.dirty ||
                          refBankAcType.touched ||
                          refBankAcType.untouched)
                    }"
                  >
                    <option value="">Select A/C Type</option>
                    <option value="SB">Saving A/C</option>
                    <option value="CC">Cash Credit A/C</option>
                    <option value="OD">Over Draft A/C</option>
                    <option value="CA">Current A/C</option>
                  </select>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">IFSC Code</span>
                  <input
                    type="text"
                    name="BankIFSC{{ i }}"
                    id="BankIFSC{{ i }}"
                    [(ngModel)]="CBank.BankIFSC"
                    #refBankIFSC="ngModel"
                    placeholder="IFSC Code"
                    class="form-control input-text-css"
                    required
                    [disabled]="CBank.IsEdit == 0"
                    maxlength="11"
                    [ngClass]="{
                      'is-invalid': rf.submitted && refBankIFSC.invalid,
                      'alert-warning':
                        refBankIFSC.invalid &&
                        (refBankIFSC.dirty ||
                          refBankIFSC.touched ||
                          refBankIFSC.untouched)
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <span>MICR Code</span>
              <input
                type="text"
                name="BankMICRCode{{ i }}"
                id="BankMICRCode{{ i }}"
                [(ngModel)]="CBank.BankMICRCode"
                #refBankMICRCode="ngModel"
                placeholder="MICR Code"
                class="form-control input-text-css"
                [disabled]="CBank.IsEdit == 0"
                maxlength="9"
                numbersOnly
              />
            </div>
            <div class="col-md-1 pt-3">
              <button
                type="button"
                class="remove"
                (click)="removeBankDetail1(i)"
                *ngIf="
                  (i < BankDetails.length && BankDetails.length > 1) ||
                  (CBank.IsEdit == 1 && BankDetails.length > 0)
                "
              >
                -
              </button>
              <button
                type="button"
                class="addmore"
                (click)="addBankDetail1()"
                *ngIf="i == BankDetails.length - 1"
              >
                +
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onCloseCustomerBankModel()"
              class="btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveCustomerBank()"
              class="ml-4 btn font-size-12 button-btn"
              mat-raised-button
              [class.spinner]="loading"
              [disabled]="!rf.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Product_Data"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Product
        </h6>
        <button
          type="button"
          (click)="OnCloseSearchProduct()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let Product of Product_Data; let i = index">
          <div class="row m-0 slideDiv" style="position: sticky">
            <div class="col-md-5 p-1">
              <B>Product :</B> {{ Product.ProductName }}
            </div>
            <div class="col-md-6 p-1">
              <B>Interest Type :</B> {{ Product.Interest_Type }}
            </div>
            <div class="col-md-1 p-1 text-right">
              <span class="staticIcon">
                <mat-icon
                  *ngIf="!showLess_ProductDetail[i]"
                  (click)="showResult(Product, i)"
                  >expand_more</mat-icon
                >
                <mat-icon
                  *ngIf="showLess_ProductDetail[i]"
                  (click)="hideResult(i)"
                  >expand_less</mat-icon
                >
              </span>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>

          <div class="row m-0" *ngIf="showLess_ProductDetail[i]">
            <div class="col-md-12 p-1">
              <div class="row m-0">
                <div class="col-md-12 p-1 bg-success1">Financial Details:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">
                  <B class="fs-12"
                    >{{
                      FDR_Detail.Deposit_Type == ""
                        ? "FD"
                        : FDR_Detail.Deposit_Type
                    }}
                    Min Amount :
                  </B>
                  {{ ProductModel[0].Amount_Min }}
                </div>
                <div class="col-md-3 p-1">
                  <B class="fs-12"
                    >{{
                      FDR_Detail.Deposit_Type == ""
                        ? "FD"
                        : FDR_Detail.Deposit_Type
                    }}
                    Max Amount :
                  </B>
                  {{ ProductModel[0].Amount_Max }}
                </div>
                <div class="col-md-3 p-1">
                  <B class="fs-12">Tenure Min : </B>
                  {{ ProductModel[0].Tenure_Min }}
                </div>
                <div class="col-md-3 p-1">
                  <B class="fs-12">Tenure Max : </B>
                  {{ ProductModel[0].Tenure_Max }}
                </div>

                <div class="col-md-3 p-1">
                  <B class="fs-12">Cummulative Frequency : </B>
                  {{ ProductModel[0].Cummulative_Frequency }}
                </div>
                <div class="col-md-3 p-1">
                  <B class="fs-12">Payment Frequency : </B>
                  {{ ProductModel[0].Payment_Frequency }}
                </div>
                <div class="col-md-3 p-1">
                  <B class="fs-12">Lockin Period : </B>
                  {{ ProductModel[0].Lockin_Period }}
                </div>
              </div>

              <div class="row m-0 p-1">
                <div class="col-md-12 p-1 bg-success1">Interest Details:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">From Month</div>
                <div class="col-md-3 p-1">To Month</div>
                <div class="col-md-3 p-1">Rate of Intrest (Standard)</div>
                <div class="col-md-3 p-1">Rate of Intrest (Senior Citizen)</div>
              </div>
              <div class="row m-0" *ngFor="let PRModel of ProductModel">
                <div class="col-md-3 p-1">
                  <div class="p-1 form-control1 input-text-css">
                    {{ PRModel.From_Month }}
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="p-1 form-control1 input-text-css">
                    {{ PRModel.To_Month }}
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="p-1 form-control1 input-text-css">
                    {{ PRModel.Rate_Of_Interest }}
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="p-1 form-control1 input-text-css">
                    {{ PRModel.Rate_Of_Interest_SC }}
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-md-12 p-1 text-right">
                  <i
                    class="fa fa-arrow-circle-right btn_detail"
                    title="Select this product"
                    (click)="OnChangeProduct(Product)"
                  ></i>
                </div>
              </div>
              <div class="col-md-12 p-1"><hr /></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
