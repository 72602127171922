import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
declare var $: any;

@Component({
  selector: 'app-lms-payment-authorization',
  templateUrl: './lms-payment-authorization.component.html',
  styleUrls: ['./lms-payment-authorization.component.scss']
})
export class LmsPaymentAuthorizationComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  LoanId: any;
  dvDetail: any;
  PaymentDetail: any;
  PaymentRemark:any = "";

  dataSourceCustomerDetail: any;
  displayedCustomerDetailColumns: string[] = [];
  TotalAmt: any;
  
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private route: ActivatedRoute, 
    private _Route: Router, 
    public snackBar: MatSnackBar, 
    private dataSharingService: DataSharingService,
    private lmsService: LmsService, 
    private router: Router, 
    private _MasterService: MasterService, 
    private _EncrDecrService: EncrDecrService 
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Payment Authorization");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedCustomerDetailColumns = ['Int_Id', 'Customer', 'CustomerType', 'GenderAge', 'PhoneNo', 'Relation_With_Hirer', 'ExistingCustomer'];  
    
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("id")));
      this.LMS_Get_Payment_Detail();
    });
  }

  closeAuthorizeDetail(){
    this.router.navigate(["/lms/paymentauthorization"]);
  }

  LMS_Get_Payment_Detail() {  
    this.showSpinner = true;
    this.lmsService.LMS_Get_Payment_Detail({ LoanId: this.LoanId }).subscribe((res: any) => {
      //console.log("res", res);
      this.dvDetail = res.Item1[0];
      this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
      this.PaymentDetail = res.Item3;
      this.showSpinner = false;
    });
  }

  ChangeDisbAmt(){
    let _total: any = 0;
    this.PaymentDetail.forEach((obj: any) => {
      _total = _total + Number(obj.Disbursment_Amount);
      this.TotalAmt = _total;
    });
  }

  saveAuthorizeDetail(){
    let _total: any = 0;
    let Status: any;

    this.PaymentDetail.forEach((obj: any) => {
      _total = _total + Number(obj.Disbursment_Amount);
    });

    if(_total > this.dvDetail.DisbursementAmt){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Payment can not be greater than Authorized Disbursement Amount!!", ...this.configSuccess });
      return;
    }

    if(_total == 0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Disbursement Amount should be greater than 0", ...this.configSuccess });
      return;
    }
    
    if(_total > 0 && _total <= this.dvDetail.DisbursementAmt){
      Status = _total == this.dvDetail.DisbursementAmt ? "Authorized" : "Hold";
      this.PaymentDetail.forEach((obj: any) => {
        delete obj.Bankname;
        delete obj.Branch;
        delete obj.AccType;
        delete obj.AccountNo;
        delete obj.IFSCCode;
        delete obj.Disbursment_Customer;
        delete obj.Loan_Id;

        obj.Remark = this.PaymentRemark;
        obj.Status = Status;
      });
    }
    let _data = {
      PDC: this.PaymentDetail,
    }
    
    //console.log("_data", _data);
    this.lmsService.LMS_Update_Payment_Disbursment({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      console.log("res", res);
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.closeAuthorizeDetail();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
    });
  }
}
