import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
declare var $: any;

@Component({
  selector: 'app-quickloan-deviation-list',
  templateUrl: './quickloan-deviation-list.component.html',
  styleUrls: ['./quickloan-deviation-list.component.scss']
})
export class QuickloanDeviationListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  FileCheckingList: any[] = [];
  DataSource: any;
  displayedColumns: string[] = ["Loan_Id", "Application_No", "Customer", "Branch", "Product", "LoanAmount", "Deviation", "Action"];// "CreatedBy", "ApprovedBy",
  UserAssignList:any;

  DeviationData: any = [];
  DeviationRemark: any;
  Status: any;
  ForwardedBy: any;
  IsForwarded: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  Processid: any;
  SelectStatus: any = 'Pending';

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private losService: LosService,
    private MasterService:MasterService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next('Deviation Approval List');

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Processid = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Pid")));   
      console.log("this.Processid", this.Processid);  
    });

    this.GetDeviationApprovalList();
  }

  GetDeviationApprovalList(){
    this.showSpinner = true;
    this.losService.Deviation_For_ApprovalList({Type : 'QuickLoan', Status: this.SelectStatus, LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      //console.log("res", res);
      this.FileCheckingList = JSON.parse(JSON.stringify(res));
      this.DataSource = new MatTableDataSource(this.FileCheckingList);
      this.DataSource.sort = this.sort;
      this.DataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  GetUserUpline(){
    this.MasterService.Get_Employee_ByBranch_Department({ BranchId: 0, DeptId: 0, LoginUserId: this.currentUser.userId, Type: "Upper" }).subscribe((res: any) => {
      this.UserAssignList = res.filter(x => x.EmpId !=  this.currentUser.userId);
    });
  }

  exportTable() : void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("quickloandeviationlist") as HTMLTableElement;
    const worksheetName = 'QUICK LOAN DEVIATION LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
    
  }

  goToDetail(row: any) {
    console.log("row", row);
    let Processid = this._EncrDecrService.encrypt(encodeURIComponent(this.Processid));
    let ProductId = this._EncrDecrService.encrypt(encodeURIComponent(row.ProductId));
    let Loan_Id = this._EncrDecrService.encrypt(encodeURIComponent(row.LoanIdentity));
    var Status = this.SelectStatus == 'Completed' ? 'C' : 'D';
    var RevertedProcessId = this._EncrDecrService.encrypt(encodeURIComponent(0));
    console.log("this.Processid", this.Processid);
   // this.router.navigate([`quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${Loan_Id}/${Status}`]);
    this.router.navigate([`quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${Loan_Id}/${Status}/${RevertedProcessId}`]);
    /*this.router.navigate([
      `/LOS/deviation/approval-list/${encodeURIComponent(data.Loan_Id)}`,
    ]);*/
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }
}
