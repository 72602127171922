<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 align-items-end formborder">
    <div class="col-md-3 p-1">
      <span> Select Employee </span>
      <select
        name="FilterEmployeeId"
        id="FilterEmployeeId"
        [(ngModel)]="FilterEmployeeId"
        class="form-control input-text-css"
        (change)="onselectemployee()"
      >
        <option value="">Select Employee</option>
        <option *ngFor="let emp of GetEmployeeDropdown" [value]="emp.EmpId">
          {{ emp.EmpName }}
        </option>
      </select>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="EmpId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EmployeeName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Employee Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.EmployeeName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Designation">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Designation
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Designation }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          PhoneNo
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.PhoneNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ReportingManger">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Reporting Manger</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ReportingManger }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_RegOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Emp_RegOn
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Emp_RegOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="onOpenUpdateSwapModal(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="employeeSwap"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveSwapData()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Select New Manager For Swap
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-3 p-1">
              <span class="required-lable"> Reporting Manager </span>
            </div>
            <div class="col-md-9 p-1">
              <select
                name="AssignedToEmployee"
                id="AssignedToEmployee"
                #refAssignedToEmployee="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAssignedToEmployee.invalid,
                  'alert-warning':
                    refAssignedToEmployee.invalid &&
                    (refAssignedToEmployee.dirty ||
                      refAssignedToEmployee.touched ||
                      refAssignedToEmployee.untouched)
                }"
                [(ngModel)]="AssignedToEmployee"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Manager</option>
                <option
                  *ngFor="let employeeSwap of employeeSwapDropdown"
                  [value]="employeeSwap.EmpId"
                >
                  {{ employeeSwap.EmployeeName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveSwapData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
