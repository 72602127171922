<section class="main-content-wrapper">

  <div *ngIf="ProductSummary">
    <div class="row col-md-12 m-0 mt-0">
      <div class="col-md-12 mb-1 alert alert-success" >
        <div class="row">
          <div class="col-md-4"><span class="fs-12"><B>Product Category  :</B> {{ ProductSummary.ProductCategory }}</span></div>
          <div class="col-md-4"><span class="fs-12"><B>Product Name :</B> {{ ProductSummary.ProductName }}</span></div>
          <div class="col-md-4"><span class="fs-12"><B>Product Short Name :</B> {{ ProductSummary.ProductShortName }}</span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row col-md-12 m-0 mt-0">
    <div class="col-md-12 p-0">
      <mat-tab-group class="loan_tab" [selectedIndex]="currentTab" (selectedTabChange)="onTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label><span>Product</span></ng-template>
          <ng-template matTabContent>
            <app-deposit-product-details [ProductSummary]="ProductSummary"></app-deposit-product-details>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="TabDisabled">
          <ng-template mat-tab-label><span>Account Add</span></ng-template>
          <ng-template matTabContent>
            <app-deposit-product-account-details [ProductSummary]="ProductSummary"></app-deposit-product-account-details>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
