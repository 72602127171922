import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { PartnerService } from "../services/partner.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";


@Component({
  selector: 'app-partner-emi-compare',
  templateUrl: './partner-emi-compare.component.html',
  styleUrls: ['./partner-emi-compare.component.scss']
})
export class PartnerEmiCompareComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  DataSource: any;

  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  AcCatgList: any = [];
  ACCategType: any = '';
  Loan_Acc_Type: any = 'Co-Lending';
  
  selectedId: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorlist', { read: MatPaginator, static: true }) paginatorlist: MatPaginator;
  

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[] = [
    "PLoan_Id", "Partner_LoanAcNo", "Customer_LoanAcNo", "CustomerName", "Co", "Co_name", "Customer_DUE_EMI", "Customer_DUE_Principal", "Customer_DUE_Interest", 
    "Partner_DUE_EMI", "Partner_DUE_Principal", "Partner_DUE_Interest", "DUE_Diff_EMI", "DUE_Diff_Principal", "DUE_Diff_Interest", 
    "Customer_Recv_EMI_Amount", "Customer_Recv_Principal", "Customer_Recv_Interest", "Partner_Recv_EMI_Amount", "Partner_Recv_Principal", 
    "Partner_Recv_Interest", "Recv_Diff_EMI", "Recv_Diff_Principal", "Recv_Diff_Interest"
  ];
 
  constructor( 
    private lmsService: LmsService,
    private partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    ) { 
    
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Booking List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.onChangeType();
  }

  onSearchPartnerBookingList() {
    this.showSpinner = true;    
    this.partnerservice.Partner_EMI_Compare_Report({ FromDate: this.FromDate, ToDate: this.ToDate, LoanAccountingId: this.ACCategType })
    .subscribe((res: any) => {
      console.log(res);
      this.showSpinner = false;
      if(res.length > 0){
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.sort = this.sort;
        this.DataSource.paginator = this.paginatorlist;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.DataSource = '';
      }
    });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Partner-Loan-Compare`,
      sheet: "Partner-Loan-Compare",
    });
  }

  onChangeType() {
    this.ACCategType = '';
    this.lmsService.Get_ACC_Category_ForDropdown_By_Type({ Type: this.Loan_Acc_Type }).subscribe((result) => {
      this.AcCatgList = JSON.parse(JSON.stringify(result));
    });
  }

  DateChange() {
    if (this.FromDate != '' && this.ToDate != '') {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Date should be greater than to From Date.", ...this.configSuccess });
        this.FromDate = '';
        this.ToDate = '';
      }
    }
  }

  totaltypewise(type) {
    if(this.DataSource != undefined){
      if (this.DataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i][type]);
        }
        return (sum > 0) ? sum.toFixed(2) : '';      
      }
      return '';
    }
  }

  public highlightRow(row) {
    this.selectedId = row.PLoan_Id;
  }

}
