import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from "@angular/router";
import { SnackbarComponent } from '../../snackbar/snackbar.component';
declare var $: any;


@Component({
  selector: 'app-loan-delete-authorization',
  templateUrl: './loan-delete-authorization.component.html',
  styleUrls: ['./loan-delete-authorization.component.scss']
})
export class LoanDeleteAuthorizationComponent implements OnInit {
  showSpinner: boolean = false;
  loading: boolean = false;
  currentUser: any;
  Status: any = 'Pending';
  SearchStatus = 'Pending';
  Row: any = {};
  FileCheckingList: any[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedDetailColumns: string[] = ['Deleted_Id', 'Application_No', 'LoanAcNo', 'Customer_name', 'Branch_Name', 'Product_Name', 'Application_LoanAmount','Action'];
  dataSourceCustomerDetail: any;
  // Status: any = '';
  customerlist: any[] = [];
  Detail: any;
  Status_Data:any;

  Note: any;
  dataSourceDetail: any;
  displayedCustomerDetailColumns: string[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };


  constructor(private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private snackBar: MatSnackBar,
    private _EncrDecrService: EncrDecrService,
    private lmsService: LmsService,
    private router: Router,
    private encdec: EncrDecrService
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Loan Delete For Authorized");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.onSearchDetail();
  }

  onSearchDetail() {
    this.showSpinner = true;
    this.dataSourceDetail = null;
    this.lmsService.Authorized_For_Loan_Delete({ LoginUserId: this.currentUser.userId, Status: this.SearchStatus }).subscribe((res: any) => {
      if (res.length > 0) {

        this.Status_Data=res;
        console.log("Detailssssspp",this.Status_Data);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));  
        this.dataSourceDetail = new MatTableDataSource(res);
        this.dataSourceDetail.paginator = this.paginator;
      }
      this.showSpinner = false;
    });
  }

  goToDetail(row) {
    this.Row = row;

  if(row.request_Status=="Pending"){
    $(`#LoanDetail`).modal("show");
    $(`#LoanDetail`).css("z-index", "1050");
    this.LoanDetail(row);
  }
  else{
    $(`#status_rejected`).modal("show");
    $(`#status_rejected`).css("z-index", "1050"); 
    this.LoanDetail(row);
  }
      
  }

  onCloseLoanDetail() {
    $(`#LoanDetail`).modal("hide");
  
  }

  onCloseStatusRejectedModal(){
    $(`#status_rejected`).modal("hide");

  }
  LoanDetail(row) {
    this.showSpinner = true;
    if (row.Type == 'partner' || row.Type == 'Partner') {
      
      this.lmsService.Partner_Detail({ Loan_id: row.Loan_Id }).subscribe((res: any) => {
        if (res.Item1.length > 0) {
          this.displayedCustomerDetailColumns = ['CustomerId', 'Customer', 'CustomerType', 'GenderAge', 'PhoneNo', 'Relation_With_Hirer', 'ExistingCustomer'];
          this.Detail = res.Item1[0];
          this.customerlist = res.Item2;
          this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
        }
        this.showSpinner = false;
      });
    } else {
      this.lmsService.LMS_GetLoanDetails({ Loan_Id: row.Loan_Id }).subscribe((res: any) => {
        if (res.Item1.length > 0) {
          this.displayedCustomerDetailColumns = ['CustomerId', 'Customer', 'CustomerType', 'GenderAge', 'PhoneNo', 'Relation_With_Hirer', 'ExistingCustomer'];
          this.Detail = res.Item1[0];
          this.customerlist = res.Item2;
          this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
        }
        this.showSpinner = false;
      });
    }
  }
  onSaveForDelete() {
    this.lmsService.Loan_Delete_Or_Rejected({ 
      LoginUserId: this.currentUser.userId, Deleted_Id: this.Row.Deleted_Id, Note: this.Note, Status: this.Status 
    }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.onCloseLoanDetail();
        this.onSearchDetail();
        this.Row = {};
        this.Note = '';
        this.Status = '';
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }



  applyFilter(filterValue: string) {
    this.dataSourceDetail.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_loan_dlt") as HTMLTableElement;
    const worksheetName = 'LOAN DELETE'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }


}
