<div class="card-container" style="padding:0;height:100%">
    <div class="title-head" style="border-bottom: 1px solid black;background-color: #4dc3a3;border-radius: 9px 9px 0 0 ;">
        <h1 class="main-tit" style="line-height: 2.2;margin:0;">PDC Details</h1>
    </div>
    <div style="min-height: 100%;" class="text-center"   *ngIf="!PDCDetail">
        No Information Available!!
    </div>
    <div id="displaymedia"  class="cnt-data" style="margin:10px" *ngIf="PDCDetail">
        <div class="income" >
          
            <div class="cont">
                <div *ngFor="let item of PDCDetail">
                    <div class="cont-Card" >
                        <div class="grd">
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.PdcType">Type: {{item["PdcType"]}}</div>
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.Ac">Account: {{item["Ac"]}}</div>
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.Bankname">Bank Name: {{item["Bankname"]}} </div>
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.IFSCCode">IFSC Code: {{item["IFSCCode"]}} </div>
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.Branch">Branch: {{item["Branch"]}} </div>
                            <div class="grd-item" data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.ChequeNo">Cheque No: {{item["ChequeNo"]}} </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    <!-- Data to display during print -->
    <div id="displayprnt"  *ngIf="PDCDetail">
        <div class="grid-container ">
            <div class="  grid-row-con">
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> Type</div>
                </div>
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> Account </div>
                </div>
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> Bank Name</div>
                </div>
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> IFSC Code</div>
                </div>
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> Branch</div>
                </div>
                <div class="grid-items">
                    <div class="grid-sub-item-value font-weight-bold"> Cheque  No.</div>
                </div>
               
               
            </div>
        
            <div class=" mt-1 grid-row-con" *ngFor="let row of PDCDetail">
        
        
        
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Process</div> -->
                    <div class="grid-sub-item-value"> {{row.PdcType}} </div>
                </div>
        
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Executive </div> -->
                    <div class="grid-sub-item-value" > {{row.Ac}} </div>
                </div>
        
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Status</div> -->
                    <div class="grid-sub-item-value"> {{row.Bankname}} </div>
                </div>
        
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Assign On</div> -->
                    <div class="grid-sub-item-value"> {{row.IFSCCode}} </div>
                </div>
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Assign On</div> -->
                    <div class="grid-sub-item-value"> {{row.Branch}} </div>
                </div>
        
                <div class="grid-items">
                    <!-- <div class="grid-sub-item"> Completed On</div> -->
                    <div class="grid-sub-item-value"> {{row.ChequeNo}} </div>
                </div>       
               
            </div>
        </div>
       
    </div>
</div>