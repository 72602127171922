<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div *ngIf="!IsViewCRCFIll">
  <div
    style="height: 270px; max-width: 100%; overflow-y: auto; overflow-x: hidden"
  >
    <!-- <div class="row m-0 mt-2">
            <div class="col-md-12 p-1"><h5>CRC Page</h5></div>
        </div> -->
    <div class="row m-0 mt-1">
      <div class="col-md-12 p-1">
        <div
          id="carouselExampleControls"
          class="carousel slide col-md-12 p-0 mt-1"
          data-interval="false"
          data-wrap="false"
        >
          <div class="" [ngClass]="{ 'pl-4': !first, 'pr-4': !last }">
            <div
              class="carousel-item active"
              style="background-color: transparent"
            >
              <div class="row m-0" *ngIf="customerData && customerData.Item2">
                <div
                  class="col-md-3 p-0"
                  [ngClass]="{ 'pl-2 pr-2': i == 1 }"
                  *ngFor="
                    let item of customerData.Item2.slice(0, 3);
                    let i = index
                  "
                >
                  <div class="card">
                    <div class="card-body p-2">
                      <div class="row m-0 mb-3 align-items-center">
                        <div class="col-md-3 p-0">
                          <img
                            style="height: 45px; width: 45px"
                            src="{{ customerProfileBaseUrl }}{{
                              item.CustomerId
                            }}/{{ item.ProfilePic }}"
                            class="user-img rounded-circle img-thumbnail"
                            alt=""
                            onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                          />
                        </div>
                        <div class="col-md-9 p-0">
                          <h6 class="col-md-12 mb-2 fs-12">
                            {{ item.Customer }}
                          </h6>
                          <p class="col-md-12 card-subtitle fs-12 text-muted">
                            {{
                              item.CustomerType == "Hirer"
                                ? "Borrower"
                                : item.CustomerType
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="row m-0 mb-2 align-items-center">
                        <h6 class="m-0 col-md-5 p-0 fs-12">Status</h6>
                        <p class="lead m-0 col-md-7 p-0 fs-12">
                          {{ item.CRC_Status }}
                        </p>
                      </div>
                      <div class="row m-0 mb-2 align-items-center">
                        <h6 class="m-0 col-md-5 p-0 fs-12">CRC Score</h6>
                        <p class="lead m-0 col-md-7 p-0 fs-12">
                          {{ item.CreditVisionScore }}
                        </p>
                      </div>
                      <div class="row m-0 mb-2 align-items-center">
                        <h6 class="m-0 col-md-5 p-0 fs-12">Personal Score</h6>
                        <p class="lead m-0 col-md-7 p-0 fs-12">
                          {{ item.CRC_PersonalLoanScore }}
                        </p>
                      </div>
                      <div class="row m-0 mb-2 align-items-center">
                        <h6 class="m-0 col-md-5 p-0 fs-12">Create On</h6>
                        <p class="lead m-0 col-md-7 p-0 fs-12">
                          {{ item.CRC_CreateOn }}&nbsp;
                        </p>
                      </div>
                      <div class="row m-0 mb-2 align-items-center">
                        <h6 class="m-0 col-md-5 p-0 fs-12">CRC Report</h6>
                        <p
                          class="lead m-0 col-md-7 p-0 fs-12"
                          *ngIf="item.CRC_Applicable == 'No'"
                        >
                          N/A
                        </p>
                        <p
                          class="lead m-0 col-md-7 p-0 fs-12"
                          *ngIf="item.CRC_Applicable == 'Yes'"
                        >
                          <ng-container *ngIf="item.CRC_Status == 'Pending'"
                            >N/A</ng-container
                          >
                          <ng-container *ngIf="item.CRC_Status != 'Pending'">
                            <a
                              href="{{ docBaseUrl }}{{
                                loanSummary.ApplicationNo
                              }}/{{ item.CRC_ReportName }}"
                              target="_blank"
                              *ngIf="item.isView != 1"
                            >
                              <i class="fa fa-link" aria-hidden="true"></i>
                            </a>
                            <i
                              class="fa fa-link"
                              *ngIf="item.isView == 1"
                              (click)="getCRCThirdDetail(item.CustomerId)"
                              style="font-size: large; cursor: pointer"
                            ></i>

                            <a
                              href="{{ docBaseUrl }}{{
                                loanSummary.ApplicationNo
                              }}/{{ item.CRC_ReportName1 }}"
                              target="_blank"
                              *ngIf="item.CRC_ReportName1"
                              [ngClass]="{ 'ml-2': item.CRC_ReportName1 }"
                            >
                              <i class="fa fa-link" aria-hidden="true"></i>
                            </a>
                          </ng-container>
                        </p>
                      </div>
                      <div class="row m-0 justify-content-center">
                        <button
                          type="button"
                          class="btn btn-sm w100"
                          *ngIf="
                            item.CRC_Applicable == 'No' &&
                            PageData.currentTab == 1 &&
                            Status != 'C'
                          "
                          [ngClass]="{
                            'btn-outline-secondary':
                              item.CRC_Status == 'Pending',
                            'btn-info': item.CRC_Status != 'Pending'
                          }"
                        >
                          {{ item.CRC_Status == "Pending" ? "N/A" : "" }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm w100"
                          (click)="ViewCustomerCrc(item)"
                          [ngClass]="{
                            'btn-outline-secondary':
                              item.CRC_Status == 'Pending',
                            'btn-info': item.CRC_Status != 'Pending'
                          }"
                          *ngIf="
                            item.CRC_Applicable == 'Yes' &&
                            PageData.currentTab == 1 &&
                            Status != 'C'
                          "
                        >
                          {{ item.CRC_Status == "Pending" ? "Fill" : "View" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="customerData && customerData.Item2">
              <div class="carousel-item" *ngIf="customerData.Item2.length > 3">
                <div class="row m-0">
                  <div
                    class="col-md-4 p-0"
                    [ngClass]="{ 'pl-2 pr-2': i == 1 }"
                    *ngFor="
                      let item of customerData.Item2.slice(3, 6);
                      let i = index
                    "
                  >
                    <div class="card">
                      <div class="card-body p-2">
                        <div class="row m-0 mb-3 align-items-center">
                          <div class="col-md-3 p-0">
                            <img
                              style="height: 45px; width: 45px"
                              src="{{ customerProfileBaseUrl }}{{
                                item.CustomerId
                              }}/{{ item.ProfilePic }}"
                              class="user-img rounded-circle img-thumbnail"
                              alt=""
                              onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                            />
                          </div>
                          <div class="col-md-9 p-0">
                            <h6 class="col-md-12 mb-2 fs-12">
                              {{ item.Customer }}
                            </h6>
                            <p class="col-md-12 card-subtitle fs-12 text-muted">
                              {{
                                item.CustomerType == "Hirer"
                                  ? "Borrower"
                                  : item.CustomerType
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Status</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_Status }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">CRC Score</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CreditVisionScore }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Personal Score</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_PersonalLoanScore }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Create On</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_CreateOn }}&nbsp;
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">CRC Report</h6>
                          <p
                            class="lead m-0 col-md-7 p-0 fs-12"
                            *ngIf="item.CRC_Applicable == 'No'"
                          >
                            N/A
                          </p>
                          <p
                            class="lead m-0 col-md-7 p-0 fs-12"
                            *ngIf="item.CRC_Applicable == 'Yes'"
                          >
                            <ng-container *ngIf="item.CRC_Status == 'Pending'"
                              >N/A</ng-container
                            >
                            <ng-container *ngIf="item.CRC_Status != 'Pending'">
                              <a href="{{ docBaseUrl }}{{
     loanSummary.ApplicationNo
   }}/{{ item.CRC_ReportName }}"
                                 target="_blank"
                                 *ngIf="item.isView != 1">
                                <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                              <i class="fa fa-link"
                                 *ngIf="item.isView == 1"
                                 (click)="getCRCThirdDetail(item.CustomerId)"
                                 style="font-size: large; cursor: pointer"></i>

                              <a href="{{ docBaseUrl }}{{
                                  loanSummary.ApplicationNo
                                }}/{{ item.CRC_ReportName1 }}"
                                 target="_blank"
                                 *ngIf="item.CRC_ReportName1"
                                 [ngClass]="{ 'ml-2': item.CRC_ReportName1 }">
                                <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                            </ng-container>
                          </p>
                        </div>
                        <div class="row m-0 justify-content-center">
                          <button
                            type="button"
                            class="btn btn-sm w100"
                            *ngIf="
                              item.CRC_Applicable == 'No' &&
                              PageData.currentTab == 1 &&
                              Status != 'C'
                            "
                            [ngClass]="{
                              'btn-outline-secondary':
                                item.CRC_Status == 'Pending',
                              'btn-info': item.CRC_Status != 'Pending'
                            }"
                          >
                            {{ item.CRC_Status == "Pending" ? "N/A" : "" }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm w100"
                            (click)="ViewCustomerCrc(item)"
                            [ngClass]="{
                              'btn-outline-secondary':
                                item.CRC_Status == 'Pending',
                              'btn-info': item.CRC_Status != 'Pending'
                            }"
                            *ngIf="
                              item.CRC_Applicable == 'Yes' &&
                              PageData.currentTab == 1 &&
                              Status != 'C'
                            "
                          >
                            {{ item.CRC_Status == "Pending" ? "Fill" : "View" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item" *ngIf="customerData.Item2.length > 6">
                <div class="row m-0">
                  <div
                    class="col-md-4 p-0"
                    [ngClass]="{ 'pl-2 pr-2': i == 1 }"
                    *ngFor="
                      let item of customerData.Item2.slice(6, 9);
                      let i = index
                    "
                  >
                    <div class="card">
                      <div class="card-body p-2">
                        <div class="row m-0 mb-3 align-items-center">
                          <div class="col-md-3 p-0">
                            <img
                              style="height: 45px; width: 45px"
                              src="{{ customerProfileBaseUrl }}{{
                                item.CustomerId
                              }}/{{ item.ProfilePic }}"
                              class="user-img rounded-circle img-thumbnail"
                              alt=""
                              onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                            />
                          </div>
                          <div class="col-md-9 p-0">
                            <h6 class="col-md-12 mb-2 fs-12">
                              {{ item.Customer }}
                            </h6>
                            <p class="col-md-12 card-subtitle fs-12 text-muted">
                              {{
                                item.CustomerType == "Hirer"
                                  ? "Borrower"
                                  : item.CustomerType
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Status</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_Status }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">CRC Score</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CreditVisionScore }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Personal Score</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_PersonalLoanScore }}
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">Create On</h6>
                          <p class="lead m-0 col-md-7 p-0 fs-12">
                            {{ item.CRC_CreateOn }}&nbsp;
                          </p>
                        </div>
                        <div class="row m-0 mb-2 align-items-center">
                          <h6 class="m-0 col-md-5 p-0 fs-12">CRC Report</h6>
                          <p
                            class="lead m-0 col-md-7 p-0 fs-12"
                            *ngIf="item.CRC_Applicable == 'No'"
                          >
                            N/A
                          </p>
                          <p
                            class="lead m-0 col-md-7 p-0 fs-12"
                            *ngIf="item.CRC_Applicable == 'Yes'"
                          >
                            <ng-container *ngIf="item.CRC_Status == 'Pending'"
                              >N/A</ng-container
                            >
                            <ng-container *ngIf="item.CRC_Status != 'Pending'">
                              <a href="{{ docBaseUrl }}{{
     loanSummary.ApplicationNo
   }}/{{ item.CRC_ReportName }}"
                                 target="_blank"
                                 *ngIf="item.isView != 1">
                                <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                              <i class="fa fa-link"
                                 *ngIf="item.isView == 1"
                                 (click)="getCRCThirdDetail(item.CustomerId)"
                                 style="font-size: large; cursor: pointer"></i>

                              <a href="{{ docBaseUrl }}{{
                                  loanSummary.ApplicationNo
                                }}/{{ item.CRC_ReportName1 }}"
                                 target="_blank"
                                 *ngIf="item.CRC_ReportName1"
                                 [ngClass]="{ 'ml-2': item.CRC_ReportName1 }">
                                <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                            </ng-container>
                          </p>
                        </div>
                        <div class="row m-0 justify-content-center">
                          <button
                            type="button"
                            class="btn btn-sm w100"
                            *ngIf="
                              item.CRC_Applicable == 'No' &&
                              PageData.currentTab == 1 &&
                              Status != 'C'
                            "
                            [ngClass]="{
                              'btn-outline-secondary':
                                item.CRC_Status == 'Pending',
                              'btn-info': item.CRC_Status != 'Pending'
                            }"
                          >
                            {{ item.CRC_Status == "Pending" ? "N/A" : "" }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm w100"
                            *ngIf="
                              item.CRC_Applicable == 'Yes' &&
                              PageData.currentTab == 1 &&
                              Status != 'C'
                            "
                            [ngClass]="{
                              'btn-outline-secondary':
                                item.CRC_Status == 'Pending',
                              'btn-info': item.CRC_Status != 'Pending'
                            }"
                            (click)="ViewCustomerCrc(item)"
                          >
                            {{ item.CRC_Status == "Pending" ? "Fill" : "View" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item" *ngIf="customerData.Item2.length > 9">
                <div class="row m-0">
                  <div
                    class="col-md-4 p-0"
                    [ngClass]="{ 'pl-2 pr-2': i == 1 }"
                    *ngFor="
                      let item of customerData.Item2.slice(9, 12);
                      let i = index
                    "
                  >
                    <div class="card">
                      <div class="card-body p-2">
                        <div class="card-body p-2">
                          <div class="row m-0 mb-3 align-items-center">
                            <div class="col-md-3 p-0">
                              <img
                                style="height: 45px; width: 45px"
                                src="{{ customerProfileBaseUrl }}{{
                                  item.CustomerId
                                }}/{{ item.ProfilePic }}"
                                class="user-img rounded-circle img-thumbnail"
                                alt=""
                                onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                              />
                            </div>
                            <div class="col-md-9 p-0">
                              <h6 class="col-md-12 mb-2 fs-12">
                                {{ item.Customer }}
                              </h6>
                              <p
                                class="col-md-12 card-subtitle fs-12 text-muted"
                              >
                                {{
                                  item.CustomerType == "Hirer"
                                    ? "Borrower"
                                    : item.CustomerType
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">Status</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_Status }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">CRC Score</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CreditVisionScore }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">
                              Personal Score
                            </h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_PersonalLoanScore }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">Create On</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_CreateOn }}&nbsp;
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">CRC Report</h6>
                            <p
                              class="lead m-0 col-md-7 p-0 fs-12"
                              *ngIf="item.CRC_Applicable == 'No'"
                            >
                              N/A
                            </p>
                            <p
                              class="lead m-0 col-md-7 p-0 fs-12"
                              *ngIf="item.CRC_Applicable == 'Yes'"
                            >
                              <ng-container *ngIf="item.CRC_Status == 'Pending'"
                                >N/A</ng-container
                              >
                              <ng-container *ngIf="item.CRC_Status != 'Pending'">
                                <a href="{{ docBaseUrl }}{{
     loanSummary.ApplicationNo
   }}/{{ item.CRC_ReportName }}"
                                   target="_blank"
                                   *ngIf="item.isView != 1">
                                  <i class="fa fa-link" aria-hidden="true"></i>
                                </a>
                                <i class="fa fa-link"
                                   *ngIf="item.isView == 1"
                                   (click)="getCRCThirdDetail(item.CustomerId)"
                                   style="font-size: large; cursor: pointer"></i>

                                <a href="{{ docBaseUrl }}{{
                                    loanSummary.ApplicationNo
                                  }}/{{ item.CRC_ReportName1 }}"
                                   target="_blank"
                                   *ngIf="item.CRC_ReportName1"
                                   [ngClass]="{ 'ml-2': item.CRC_ReportName1 }">
                                  <i class="fa fa-link" aria-hidden="true"></i>
                                </a>
                              </ng-container>
                            </p>
                          </div>
                          <div class="row m-0 justify-content-center">
                            <button
                              type="button"
                              class="btn btn-sm w100"
                              *ngIf="
                                item.CRC_Applicable == 'No' &&
                                PageData.currentTab == 1 &&
                                Status != 'C'
                              "
                              [ngClass]="{
                                'btn-outline-secondary':
                                  item.CRC_Status == 'Pending',
                                'btn-info': item.CRC_Status != 'Pending'
                              }"
                            >
                              {{ item.CRC_Status == "Pending" ? "N/A" : "" }}
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm w100"
                              *ngIf="
                                item.CRC_Applicable == 'Yes' &&
                                PageData.currentTab == 1 &&
                                Status != 'C'
                              "
                              [ngClass]="{
                                'btn-outline-secondary':
                                  item.CRC_Status == 'Pending',
                                'btn-info': item.CRC_Status != 'Pending'
                              }"
                              (click)="ViewCustomerCrc(item)"
                            >
                              {{
                                item.CRC_Status == "Pending" ? "Fill" : "View"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item" *ngIf="customerData.Item2.length > 12">
                <div class="row m-0">
                  <div
                    class="col-md-4 p-0"
                    [ngClass]="{ 'pl-2 pr-2': i == 1 }"
                    *ngFor="
                      let item of customerData.Item2.slice(12, 15);
                      let i = index
                    "
                  >
                    <div class="card">
                      <div class="card-body p-2">
                        <div class="card-body p-2">
                          <div class="row m-0 mb-3 align-items-center">
                            <div class="col-md-3 p-0">
                              <img
                                style="height: 45px; width: 45px"
                                src="{{ customerProfileBaseUrl }}{{
                                  item.CustomerId
                                }}/{{ item.ProfilePic }}"
                                class="user-img rounded-circle img-thumbnail"
                                alt=""
                                onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                              />
                            </div>
                            <div class="col-md-9 p-0">
                              <h6 class="col-md-12 mb-2 fs-12">
                                {{ item.Customer }}
                              </h6>
                              <p
                                class="col-md-12 card-subtitle fs-12 text-muted"
                              >
                                {{
                                  item.CustomerType == "Hirer"
                                    ? "Borrower"
                                    : item.CustomerType
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">Status</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_Status }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">CRC Score</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CreditVisionScore }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">
                              Personal Score
                            </h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_PersonalLoanScore }}
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">Create On</h6>
                            <p class="lead m-0 col-md-7 p-0 fs-12">
                              {{ item.CRC_CreateOn }}&nbsp;
                            </p>
                          </div>
                          <div class="row m-0 mb-2 align-items-center">
                            <h6 class="m-0 col-md-5 p-0 fs-12">CRC Report</h6>
                            <p
                              class="lead m-0 col-md-7 p-0 fs-12"
                              *ngIf="item.CRC_Applicable == 'No'"
                            >
                              N/A
                            </p>
                            <p
                              class="lead m-0 col-md-7 p-0 fs-12"
                              *ngIf="item.CRC_Applicable == 'Yes'"
                            >
                              <ng-container *ngIf="item.CRC_Status == 'Pending'"
                                >N/A</ng-container
                              >
                              <ng-container *ngIf="item.CRC_Status != 'Pending'">
                                <a href="{{ docBaseUrl }}{{
     loanSummary.ApplicationNo
   }}/{{ item.CRC_ReportName }}"
                                   target="_blank"
                                   *ngIf="item.isView != 1">
                                  <i class="fa fa-link" aria-hidden="true"></i>
                                </a>
                                <i class="fa fa-link"
                                   *ngIf="item.isView == 1"
                                   (click)="getCRCThirdDetail(item.CustomerId)"
                                   style="font-size: large; cursor: pointer"></i>

                                <a href="{{ docBaseUrl }}{{
                                    loanSummary.ApplicationNo
                                  }}/{{ item.CRC_ReportName1 }}"
                                   target="_blank"
                                   *ngIf="item.CRC_ReportName1"
                                   [ngClass]="{ 'ml-2': item.CRC_ReportName1 }">
                                  <i class="fa fa-link" aria-hidden="true"></i>
                                </a>
                              </ng-container>
                            </p>
                          </div>
                          <div class="row m-0 justify-content-center">
                            <button
                              type="button"
                              class="btn btn-sm w100"
                              *ngIf="
                                item.CRC_Applicable == 'No' &&
                                PageData.currentTab == 1 &&
                                Status != 'C'
                              "
                              [ngClass]="{
                                'btn-outline-secondary':
                                  item.CRC_Status == 'Pending',
                                'btn-info': item.CRC_Status != 'Pending'
                              }"
                            >
                              {{ item.CRC_Status == "Pending" ? "N/A" : "" }}
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm w100"
                              *ngIf="
                                item.CRC_Applicable == 'Yes' &&
                                PageData.currentTab == 1 &&
                                Status != 'C'
                              "
                              [ngClass]="{
                                'btn-outline-secondary':
                                  item.CRC_Status == 'Pending',
                                'btn-info': item.CRC_Status != 'Pending'
                              }"
                              (click)="ViewCustomerCrc(item)"
                            >
                              {{
                                item.CRC_Status == "Pending" ? "Fill" : "View"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                [ngClass]="{ 'd-done': first }"
                role="button"
                data-slide="prev"
                *ngIf="customerData.Item2.length > 3"
                style="width: auto; color: #000; font-size: 24px"
              >
                <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                [ngClass]="{ 'd-done': last }"
                role="button"
                data-slide="next"
                *ngIf="customerData.Item2.length > 3"
                style="width: auto; color: #000; font-size: 24px"
              >
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                <span class="sr-only">Next</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row col-md-12 m-0 mt-3 justify-content-end">
    <button
      type="button"
      (click)="onNext()"
      class="btn font-size-12 button-btn ml-4"
      mat-raised-button
      [class.spinner]="loading"
    >
      <!-- *ngIf="PageData.currentTab == 1 && Status != 'C'"   *ngIf="emiIrr" -->
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
<app-quickloan-crc-fill
  *ngIf="IsViewCRCFIll"
  [loanSummary]="loanSummary"
  [Status]="Status"
  [Revert_IsEdit]="Revert_IsEdit"
  [CustomerId]="selectedCustomerId"
  (close)="OnCRCDetailClose()"
></app-quickloan-crc-fill>

<div
  class="modal fade in"
  id="cibilReportmain"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cibil Report
        </h6>

        <button
          type="button"
          (click)="onCloseCibilmainReport()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body" style="font-size: 10px">
        <ng-container *ngIf="cibilResponseMain">
          <!--<app-quickloan-cibil-report [cibilResponse]="cibilResponseMain">
  </app-quickloan-cibil-report>-->
          <div class="row m-0">
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                class="btn font-size-12 button-btn ml-2"
                (click)="onDocsPrint('TransUnionCIBIL')"
              >
                Print
              </button>
            </div>
          </div>

          <div id="TransUnionCIBIL">
            <div class="d-flex flex-column gap-3 CibilData">
              <div>
                <h1 class="font-weight-bold text-secondary">
                  TransUnion CIBIL
                </h1>
              </div>

              <div class="bg-secondary">
                <span class="text-black fs-16 p-2">CONSUMER CIR</span>
              </div>

              <div
                class="customerDetails d-flex flex-row justify-content-between mt-2"
              >
                <div class="leftSection d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="fs-12 font-weight-bold">CONSUMER:</div>
                    <span class="lead fs-12">
                      {{ CibilData?.consumerCreditData[0].names[0].name }}
                    </span>
                  </div>

                  <div class="d-flex align-items-center mt-0">
                    <div class="fs-12 font-weight-bold">MEMBER ID:</div>
                    <span class="lead fs-12">
                      {{
                        CibilData?.consumerCreditData[0].tuefHeader
                          .enquiryMemberUserId
                      }}
                    </span>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="fs-12 font-weight-bold">
                      MEMBER REFERENCE NUMBER:
                    </div>
                    <span class="lead fs-12">
                      {{
                        CibilData?.consumerCreditData[0].tuefHeader.memberRefNo
                      }}
                    </span>
                  </div>
                </div>

                <div class="rightSection d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="fs-12 font-weight-bold">DATE:</div>
                    <span class="lead fs-12">
                      {{
                        CibilData?.consumerCreditData[0].tuefHeader
                          .dateProcessed | dateFormat
                      }}
                    </span>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="fs-12 font-weight-bold">TIME:</div>
                    <span class="lead fs-12">
                      {{
                        CibilData?.consumerCreditData[0].tuefHeader
                          .timeProcessed | dateFormat
                      }}
                    </span>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="fs-12 font-weight-bold">CONTROL NUMBER:</div>
                    <span class="lead fs-12">
                      {{
                        CibilData?.consumerCreditData[0].tuefHeader
                          .enquiryControlNumber
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <hr style="color: black" />
                </div>
              </div>

              <div class="mt-3">
                <div class="">
                  <div class="font-weight-bold text-lg h6">
                    CONSUMER INFORMATION:
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="lead fs-12">NAME:</div>
                  <span class="fs-12 font-weight-bold">
                    {{ CibilData?.consumerCreditData[0].names[0].name }}
                  </span>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex">
                    <div class="lead fs-12">DATE OF BIRTH:</div>
                    <span class="fs-12 font-weight-bold">
                      {{
                        CibilData?.consumerCreditData[0].names[0].birthDate
                          | dateFormat
                      }}
                    </span>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="lead fs-12 mr-2">GENDER:</div>
                    <span class="fs-12 font-weight-bold">
                      {{
                        CibilData?.consumerCreditData[0].names[0].gender == "1"
                          ? "Female"
                          : "Male"
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">
                  CIBIL TRANSUNION SCORE(S):
                </div>

                <div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">SCORE NAME</th>
                        <th scope="col">SCORE</th>
                        <th scope="col">SCORING FACTORS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let score of CibilData?.consumerCreditData[0]?.scores
                        "
                      >
                        <td>
                          {{
                            score?.scoreName != undefined
                              ? score?.scoreName == "CIBILTUSC3"
                                ? "CreditVision© Score"
                                : score?.scoreName == "PLSCORE"
                                ? "Personal Loan Score"
                                : ""
                              : ""
                          }}
                        </td>
                        <td class="font-weight-bold text-lg Score">
                          {{ score?.score != undefined ? score.score : "" }}
                        </td>
                        <!-- EMIDetails != undefined ? EMIDetails.MatureEMI : ""  -->
                        <td>
                          <ul *ngIf="score?.reasonCodes != undefined">
                            <li *ngFor="let reason of score?.reasonCodes">
                              {{
                                findCibilScore(
                                  reason.reasonCodeValue,
                                  score.scoreName
                                ) == null
                                  ? ""
                                  : findCibilScore(
                                      reason.reasonCodeValue,
                                      score.scoreName
                                    )
                              }}
                            </li>
                          </ul>
                          <br />
                          <!-- <span>PRESENCE OF DELINQUENCY</span> -->
                        </td>
                      </tr>
                      <div class="border-bottom"></div>
                      <!-- <tr>
                <td>PERSONAL LOAN SCORE</td>
                <td class="font-weight-bold text-lg">533</td>
                <td>
                  <span
                    >ONE OR MORE TRADES WITH SETTLED DEBT IN THE PAST 24
                    MONTHS.</span
                  >
                  <br />
                  <span>OVER DUE AMOUNT IS TOO HIGH.</span>
                  <br />
                  <span
                    >TOTAL HIGH CREDIT OF DELINQUENCY IS TOO HIGH.</span
                  >
                  <br />
                </td>
              </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="p-3 mb-2 bg-light text-dark">
                <div class="fs-16 font-weight-bold">
                  POSSIBLE RANGE FOR CREDITVISION SCORE
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p class="fs-12">
                    Consumer with at least one trade on the bureau in the last
                    36 months :
                  </p>
                  <span>300(High risk) to 900(low risk)</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p>
                    Consumer not in CIBIL database or history older than 36
                    months :
                  </p>
                  <span class="text-left">-1</span>
                </div>
                <div class="fs-12">
                  <p>
                    *At least one tradeline with information updated in last 36
                    months is required.
                  </p>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="p-3 mb-2 bg-light text-dark">
                <div class="fs-16 font-weight-bold">
                  POSSIBLE RANGE FOR PERSONAL LOAN SCORE
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p class="fs-12">
                    Consumer with more than 1 month credit history :
                  </p>
                  <span>300(High risk) to 900(low risk)</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p>
                    Consumer not in CIBIL database or with insufficient
                    information for scoring:
                  </p>
                  <span class="text-left">-1</span>
                </div>
                <div class="fs-12">
                  <p>
                    *At least one tradeline with information updated in last 24
                    months is required.In case of error in scoring a value of
                    '0' is returned.
                  </p>
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">
                  IDENTIFICATIONS(S):
                </div>

                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">IDENTIFICATION TYPE</th>
                        <th scope="col">IDENTIFICATION NUMBER</th>
                        <th scope="col">ISSUE DATE</th>
                        <th scope="col">EXPIRATION DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let ids of CibilData?.consumerCreditData[0]?.ids
                        "
                      >
                        <td>
                          {{
                            ids?.idType != undefined
                              ? ids?.idType == "01"
                                ? "Income Tax ID Number (PAN)"
                                : ids?.idType == "02"
                                ? "Passport Number"
                                : ids?.idType == "03"
                                ? "Voter ID Number"
                                : ids?.idType == "04"
                                ? "Driver’s License Number"
                                : ids?.idType == "05"
                                ? "Ration Card Number"
                                : ids?.idType == "06"
                                ? "Universal ID Number (UID) / Aadhaar Number"
                                : ids?.idType == "07"
                                ? "Additional ID 1 (For Future Use)"
                                : ids?.idType == "08"
                                ? "Additional ID 2 (For Future Use)"
                                : ids?.idType == "09"
                                ? "CKYC"
                                : ids?.idType == "10"
                                ? "NREGA Card Number"
                                : ""
                              : ""
                          }}
                        </td>
                        <td>
                          {{ ids?.idNumber != undefined ? ids?.idNumber : "" }}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <!-- <tr>
                <td>UNIVERSAL ID NUMBER</td>
                <td>981103767927</td>
                <td></td>
                <td></td>
              </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">TELEPHONE(S):</div>

                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">TELEPHONE TYPE</th>
                        <th scope="col">TELEPHONE NUMBER</th>
                        <th scope="col">TELEPHONE EXTENSIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let telephoneNo of CibilData?.consumerCreditData[0]
                            ?.telephones
                        "
                      >
                        <td>
                          {{
                            telephoneNo?.telephoneType == "01"
                              ? "Mobile Phone"
                              : telephoneNo?.telephoneType == "02"
                              ? "Home Phone"
                              : telephoneNo?.telephoneType == "03"
                              ? "Office Phone"
                              : "Not Classified" || ""
                          }}
                        </td>
                        <td>{{ telephoneNo?.telephoneNumber || "" }}</td>
                        <td>{{ telephoneNo?.enquiryEnriched || "" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">EMAIL CONTACT(S):</div>

                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">EMAIL ADDRESS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let email of CibilData?.consumerCreditData[0]?.emails
                        "
                      >
                        <td>
                          {{
                            email?.emailID != undefined ? email?.emailID : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">ADDRESS(ES):</div>
                <div>
                  <table class="table table-striped">
                    <tbody>
                      <tr
                        *ngFor="
                          let address of CibilData?.consumerCreditData[0]
                            ?.addresses
                        "
                      >
                        <td>
                          <div>
                            ADDRESS:{{ address.line1 }},{{ address.line5 }},{{
                              address.stateCode
                            }},{{ address.pinCode }}
                          </div>
                          <div class="row m-0">
                            <div class="col-md-4">
                              CATEGORY:{{
                                address.addressCategory == "01"
                                  ? "Permanent Address"
                                  : address.addressCategory == "02"
                                  ? "Residence Address"
                                  : address.addressCategory == "03"
                                  ? "Office Address"
                                  : address.addressCategory == "05"
                                  ? "Mortgage Property address"
                                  : "Not Categorized"
                              }}
                            </div>
                            <div class="col-md-4">
                              RESIDENCE CODE:{{
                                address.residenceCode == "01"
                                  ? "Owned"
                                  : "Rented"
                              }}
                            </div>
                            <div class="col-md-4">
                              DATE REPORTED:{{
                                address.dateReported | dateFormat
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--<div *ngFor="let address of CibilData?.consumerCreditData[0]?.addresses">
          <div class="lead fs-12 mt-2">
            ADDRESS:{{ address.line1 }},{{ address.line5 }},{{
            address.stateCode
            }},{{ address.pinCode }}
          </div>
          <div class="d-flex align-items-center justify-content-between mt-2">
            <div class="lead fs-12">CATEGORY:{{ address.addressCategory=='01'?'Permanent Address':address.addressCategory=='02'?'Residence Address': address.addressCategory=='03'?'Office Address':address.addressCategory=='05'?'Mortgage Property address':'Not Categorized'}}</div>
            <div class="lead fs-12">RESIDENCE CODE:{{ address.residenceCode=='01'?'Owned':'Rented' }}</div>
            <div class="lead fs-12">DATE REPORTED:{{ address.dateReported| dateFormat }}</div>
          </div>
        </div>-->
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">
                  EMPLOYMENT INFORMATION:
                </div>

                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">ACCOUNT TYPE</th>
                        <th scope="col">DATE REPORTED</th>
                        <th scope="col">OCCUPATION CODE</th>
                        <th scope="col">INCOME</th>
                        <th scope="col">NET/GROSS INCOME INDICATOR</th>
                        <th scope="col">MONTHLY/ANNUAL INCOME INDICATOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let empInfo of CibilData?.consumerCreditData[0]
                            ?.employment
                        "
                      >
                        <td>
                          {{
                            findItemById(empInfo?.accountType) == null
                              ? ""
                              : findItemById(empInfo?.accountType)
                          }}
                        </td>
                        <td>{{ empInfo?.dateReported | dateFormat }}</td>
                        <td>
                          {{
                            empInfo?.occupationCode == "01"
                              ? "Salaried"
                              : empInfo?.occupationCode == "02"
                              ? "Self Employed Professional"
                              : empInfo?.occupationCode == "03"
                              ? "Self Employed"
                              : empInfo?.occupationCode == "04"
                              ? "Others"
                              : ""
                          }}
                        </td>
                        <td>Not Available</td>
                        <td>Not Available</td>
                        <td>Not Available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">
                  SUMMARY:
                  <p>ACCOUNT(S)</p>
                </div>

                <div>
                  <table
                    class="table table-striped"
                    *ngIf="CibilData?.consumerSummaryData"
                  >
                    <thead>
                      <tr>
                        <th scope="col">ACCOUNT TYPE</th>
                        <th scope="col">ACCOUNTS</th>
                        <th scope="col">ADVANCES</th>
                        <th scope="col">BALANCE</th>
                        <th scope="col">DATE OPENED</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ALL ACCOUNTS</td>
                        <td>
                          <span style="border-bottom: 1px solid gray">
                            TOTAL:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .totalAccounts
                            }}
                          </span>

                          <br />
                          <span style="border-bottom: 1px solid gray">
                            OVERDUE:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .overdueAccounts
                            }}
                          </span>
                          <br />
                          <span style="border-bottom: 1px solid gray">
                            ZERO-BALANCE:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .zeroBalanceAccounts
                            }}
                          </span>
                        </td>
                        <td>
                          HIGH CR/SANC. AMT:{{
                            CibilData?.consumerSummaryData.accountSummary
                              .highCreditAmount
                          }}
                        </td>
                        <td>
                          <span style="border-bottom: 1px solid gray">
                            CURRENT:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .currentBalance
                            }}
                          </span>
                          <br />
                          <span style="border-bottom: 1px solid gray">
                            OVERDUE:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .overdueBalance
                            }}
                          </span>
                        </td>
                        <td>
                          <span style="border-bottom: 1px solid gray">
                            RECENT:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .recentDateOpened | dateFormat
                            }}
                          </span>
                          <br />
                          <span style="border-bottom: 1px solid gray">
                            OLDEST:{{
                              CibilData?.consumerSummaryData.accountSummary
                                .oldestDateOpened | dateFormat
                            }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">ENQUIRIES</div>

                <div>
                  <table
                    class="table table-striped"
                    *ngIf="CibilData?.consumerSummaryData"
                  >
                    <thead>
                      <tr>
                        <th scope="col">ENQUIRY PURPOSE</th>
                        <th scope="col">TOTAL</th>
                        <th scope="col">PAST 30 DAYS</th>
                        <th scope="col">PAST 12 MONTHS</th>
                        <th scope="col">PAST 24 MONTHS</th>
                        <th scope="col">RECENT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ALL ENQUIRIES</td>
                        <td>
                          {{
                            CibilData?.consumerSummaryData.inquirySummary
                              .inquiryPast30Days
                          }}
                        </td>
                        <td>
                          {{
                            CibilData?.consumerSummaryData.inquirySummary
                              .inquirySummary
                          }}
                        </td>
                        <td>
                          {{
                            CibilData?.consumerSummaryData.inquirySummary
                              .inquiryPast12Months
                          }}
                        </td>
                        <td>
                          {{
                            CibilData?.consumerSummaryData.inquirySummary
                              .inquiryPast24Months
                          }}
                        </td>
                        <td>
                          {{
                            CibilData?.consumerSummaryData.inquirySummary
                              .recentInquiryDate | dateFormat
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">ACCOUNT(S):</div>

                <div>
                  <table class="table table-striped-cibil">
                    <tbody>
                      <tr>
                        <td>
                          <table
                            *ngFor="
                              let accountData of CibilData
                                ?.consumerCreditData[0]?.accounts
                            "
                          >
                            <tr>
                              <th scope="col">ACCOUNT</th>
                              <th scope="col">DATES</th>
                              <th scope="col">ACCOUNTS</th>
                              <th scope="col">STATUS</th>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  >MEMBER NAME:{{
                                    accountData.memberShortName
                                  }}</span
                                >
                                <br />
                                <span>ACCOUNT NUMBER:NOT DISCLOSED</span>
                                <br />
                                <span>
                                  TYPE:{{
                                    findItemById(accountData.accountType) ==
                                    null
                                      ? ""
                                      : findItemById(accountData.accountType)
                                  }}
                                </span>
                                <br />
                                <span
                                  >OWNERSHIP:{{
                                    accountData.ownershipIndicator == 1
                                      ? "Individual"
                                      : accountData.ownershipIndicator == 2
                                      ? "Authorised User (refers to supplementary credit card holder)"
                                      : accountData.ownershipIndicator == 3
                                      ? "Guarantor"
                                      : accountData.ownershipIndicator == 4
                                      ? "Joint"
                                      : accountData.ownershipIndicator == 5
                                      ? "Deceased"
                                      : ""
                                  }}</span
                                >
                              </td>
                              <td>
                                <span
                                  >OPENED:{{
                                    accountData.dateOpened | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >LAST PAYMENT:{{
                                    accountData?.lastPaymentDate | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >CLOSED:{{
                                    accountData?.dateClosed | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >REPORTED AND CERTIFIED:{{
                                    accountData.dateReported | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >PMT HIST START:{{
                                    accountData.paymentStartDate | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >PMT HIST END:{{
                                    accountData.paymentEndDate | dateFormat
                                  }}</span
                                >
                              </td>
                              <td>
                                <span
                                  >SANCTIONED:{{
                                    accountData.highCreditAmount
                                  }}</span
                                >
                                <br />
                                <span
                                  >CURRENT BALANCE:{{
                                    accountData.currentBalance
                                  }}</span
                                >
                                <br />
                                <span
                                  >OVERDUE:{{ accountData.amountOverdue }}</span
                                >
                                <br />
                                <span
                                  >EMI:{{
                                    accountData.paymentEndDate | dateFormat
                                  }}</span
                                >
                                <br />
                                <span
                                  >PMT FREQ:{{
                                    accountData.paymentFrequency
                                  }}</span
                                >
                                <br />
                                <span
                                  >REPAYMENT TENURE:{{
                                    accountData.paymentEndDate | dateFormat
                                  }}</span
                                >
                                <br />
                                <span>
                                  INTEREST RATE:{{ accountData.interestRate }}
                                </span>
                                <br />
                                <span
                                  >ACTUAL PAYMENT:{{
                                    accountData.actualPaymentAmount
                                  }}</span
                                >
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colspan="4">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td colspan="15">
                                        DAYS PAST DUE/ASSET CLASSIFICATION (UP
                                        TO 36 MONTHS; LEFT TO RIGHT)
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        *ngFor="
                                          let payment of accountData.onedue
                                        "
                                      >
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.score }}
                                        </p>
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.sDate }}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        *ngFor="
                                          let payment of accountData.twodue
                                        "
                                      >
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.score }}
                                        </p>
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.sDate }}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        *ngFor="
                                          let payment of accountData.threedue
                                        "
                                      >
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.score }}
                                        </p>
                                        <p
                                          style="margin-bottom: 5px !important"
                                        >
                                          {{ payment.sDate }}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <p class="lead fs-12">
                    DAYS PAST DUE/ASSET CLASSIFICATION(UP TO 36 MONTHS;LEFT TO
                    RIGHT)
                  </p>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">ENQUIRIES:</div>

                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">MEMBER</th>
                        <th scope="col">ENQUIRY DATE</th>
                        <th scope="col">ENQUIRY PURPOSE</th>
                        <th scope="col">ENQUIRY AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let enquiry of CibilData?.consumerCreditData[0]
                            ?.enquiries
                        "
                      >
                        <td>{{ enquiry.memberShortName }}</td>
                        <td>{{ enquiry.enquiryDate | dateFormat }}</td>
                        <td>
                          {{
                            findItemById(enquiry.enquiryPurpose) == null
                              ? ""
                              : findItemById(enquiry.enquiryPurpose)
                          }}
                        </td>
                        <td>{{ enquiry.enquiryAmount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>
              <div class="mt-3">
                <div class="font-weight-bold text-lg h6">
                  END OF REPORT ON
                  {{ CibilData?.consumerCreditData[0].names[0].name }}
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
