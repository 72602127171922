import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { ReportsService } from "../services/reports.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { LosService } from "src/app/_LOS/services/los.service";

@Component({
  selector: "app-collection-efficeincy-detail",
  templateUrl: "./collection-efficeincy-detail.component.html",
  styleUrls: ["./collection-efficeincy-detail.component.scss"],
})
export class CollectionEfficeincyDetailComponent implements OnInit {
  currentUserId: any;
  currentUser: any;
  // DateSelected = moment(Date.now()).format("DD-MM-YYYY");
  DateSelected: Date;

  // DateSelected=Date.now();
  DateTo = new Date();
  // DateTo=Date.now();
  showSpinner = false;
  selectedRow = -1;
  LPI = false;
  columns: any[] = [];
  columnshow = [];
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginatorRef") paginator: MatPaginator;
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any = [];
  LeadSourceName: any;
  leadSourceDropdown: any;

  constructor(
    private reportService: ReportsService,
    private changeDetection: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private losService: LosService
  ) {
    this.currentUserId = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).userId;
    this.currentUser = JSON.parse(
      sessionStorage.getItem("currentUser")
    );
    var k = localStorage.getItem("keys1");
    if (k != null) {
      this.displayedColumns = JSON.parse(k).keys1;
    }
  }
  ngOnDestroy(): void {
    localStorage.setItem(
      "keys1",
      JSON.stringify({ keys1: this.displayedColumns })
    );
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Collection Efficiency Details");
    this.dataSource.sort = this.sort;
    const currentDate = new Date();
    this.DateSelected = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  selectHighligh(row) {
    this.selectedRow = row;
  }

  checkedChange(event) {
    var column = event.target.id;
    if (event.target.checked) {
      this.AddColumnToDisplay(column);
    } else {
      this.removeColumnFromDisplay(column);
    }
    console.log("sdfsdfsdf", event.target.checked);
    this.changeDetection.detectChanges();
  }

  onColumnDrop(event: CdkDragDrop<string[]>): void {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    console.log("event ", event);
    if (event.container.id == "DeleteContainer")
      this.removeColumnFromDisplay(event.item.data.name);

    if (event.item.dropContainer.id == "DeleteContainer") {
      var col = event.item.data.name;
      this.AddColumnToDisplayPosition(col, currentIndex);
    }

    moveItemInArray(this.displayedColumns, previousIndex, currentIndex);
  }

  removeColumnFromDisplay(str) {
    var ind = this.displayedColumns.indexOf(str);
    if (ind >= 0) this.displayedColumns.splice(ind, 1);
    if (!this.columnshow.includes(str)) this.columnshow.push(str);
  }

  AddColumnToDisplay(str) {
    if (!this.displayedColumns.includes(str)) this.displayedColumns.push(str);
    var ind = this.columnshow.indexOf(str);
    if (ind >= 0) this.columnshow.splice(ind, 1);
  }
  AddColumnToDisplayPosition(str, position) {
    if (!this.displayedColumns.includes(str))
      this.displayedColumns.splice(position, 0, str);

    var ind = this.columnshow.indexOf(str);
    if (ind >= 0) this.columnshow.splice(ind, 1);
  }

  //Get Requests

  Get_Data() {
    this.showSpinner = true;
    this.reportService
      .Report_Collection_Efficiency_Detail({
        LoginUserId: this.currentUserId,
        FromDate: moment(this.DateSelected, "DD-MM-YYYY").format("YYYY-MM-DD"),
        ToDate: moment(this.DateTo, "DD-MM-YYYY").format("YYYY-MM-DD"),
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe(
        (result: any[]) => {
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.columns = [];
          this.displayedColumns = [];
          //get all keys1
          var key = Object.keys(result[0]);
          // add to column variable
          if (this.displayedColumns.length == 0) this.displayedColumns = key;
          key.forEach((x) => {
            this.columns.push({ field: x });
            if (!this.displayedColumns.includes(x)) {
              this.columnshow.push(x);
            }
          });
          this.showSpinner = false;
        },
        () => {
          this.showSpinner = false;
          this.dataSource = null;
        }
      );
  }

  exportTableAsXLSX(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "collectionEfficiencyDetails_component"
    ) as HTMLTableElement;
    const worksheetName = "COLLECTION EFFICIENCY DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
