<div>
  <div class="row m-0 align-items-end">
    <div class="col-md-3 p-1">
      <span>Charges Head:</span>
      <select
        name="ChargeHeadId"
        id="ChargeHeadId"
        [(ngModel)]="ChargeHeadId"
        class="form-control input-text-css"
      >
        <option value="0">All</option>
        <option
          *ngFor="let chargesHeadDropDown of ChargesHeadDropDown"
          [value]="chargesHeadDropDown.Id"
        >
          {{ chargesHeadDropDown.ChagesHead }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span>From Date:</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3 p-1">
      <span>To Date:</span>

      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refVoucher_Date="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3 p-1">
      <button
        type="button"
        (click)="getChargeCollection()"
        class="btn font-size-12 button-btn"
      >
        Search
      </button>
    </div>
  </div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 80px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 80px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ApplicationNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 110px"
          *matHeaderCellDef
        >
          Application No
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 110px"
        >
          {{ row.ApplicationNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanProduct">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanProduct }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ChagesHead">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Chages Head
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ChagesHead }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AmountTaken">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px"
          *matHeaderCellDef
        >
          Amount
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px"
        >
          {{ row.AmountTaken.toFixed(2) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ReceiptDeduction">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Receipt Deduction
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ReceiptDeduction }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CollectionOn">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 110px"
          *matHeaderCellDef
        >
          Collection On
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 110px"
        >
          {{ row.CollectionOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CollectionBy">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 110px"
          *matHeaderCellDef
        >
          Collection By
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 110px"
        >
          {{ row.CollectionBy }}
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
