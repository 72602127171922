import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-consumer-durable-model-master",
  templateUrl: "./consumer-durable-model-master.component.html",
  styleUrls: ["./consumer-durable-model-master.component.scss"],
})
export class ConsumerDurableModelMasterComponent implements OnInit {
  public get dialog(): DialogService {
    return this._dialog;
  }
  public set dialog(value: DialogService) {
    this._dialog = value;
  }

  SelectCategory: any = "0";
  SelectManufacture: any = "0";
  SelectProduct: any = "0";
  categoryList: any[] = [];
  manufactureList: any[] = [];
  productList: any[] = [];
  categoryName: any[] = [];
  categoryId: any[] = [];
  currentUser: any;
  dataSourceModel: any;

  displayedColumn: string[] = [
    "Id",
    "Category_Name",
    "Manufacture_Name",
    "Product_Name",
    "Model_Name",
    "Action",
  ];

  ButtonName: string = "Add";
  SaveUpdateButton: string = "Save";
  SelectCategoryModel: any = "";
  SelectManufactureModel: any = "";
  SelectProductModel: any = "";
  modelName: any;
  dataSource: MatTableDataSource<unknown>;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  modelId: any;

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private _dialog: DialogService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Consumer Durable Model");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Master_Consumer_Durable_Category();
    this.Get_Master_Consumer_Durable_Model();
  }

  Get_Master_Consumer_Durable_Category() {
    this.categoryId = [];
    this.categoryName = [];

    this._MasterService
      .Get_Master_Consumer_Durable_Category()
      .subscribe((response) => {
        this.categoryList = JSON.parse(JSON.stringify(response));
      });
  }

  getManufactureNames(Category_Id, IsBlank: any = true) {
    this.manufactureList = [];
    if (IsBlank) {
      this.SelectManufactureModel = "";
      this.SelectProductModel = "";
    }
    this._MasterService
      .Get_Master_Consumer_Durable_Manufacture({
        Category_Id: Category_Id,
      })
      .subscribe((response) => {
        this.manufactureList = JSON.parse(JSON.stringify(response));
      });
  }

  getProductName(Category_Id, Manufacture_Id, IsBlank: any = true) {
    console.log("Manufacture Change");
    if (IsBlank) {
      this.SelectProductModel = "";
    }
    this._MasterService
      .Get_Master_Consumer_Durable_Product({
        Category_Id: Category_Id,
        Manufacture_Id: Manufacture_Id,
      })
      .subscribe((response) => {
        console.log("Get_Master_Consumer_Durable_Product", response);
        this.productList = JSON.parse(JSON.stringify(response));
      });
  }

  OnSearchConsumerDurableModel() {
    this.Get_Master_Consumer_Durable_Model();
  }

  Get_Master_Consumer_Durable_Model() {
    this._MasterService
      .Get_Master_Consumer_Durable_Model({
        Category_Id: this.SelectCategory,
        Manufacture_Id: this.SelectManufacture,
        ProductId: this.SelectProduct,
      })
      .subscribe((response) => {
        console.log("Get_Master_Consumer_Durable_Model", response);

        this.dataSourceModel = new MatTableDataSource(
          JSON.parse(JSON.stringify(response))
        );
      });
  }

  applyFilter(filterValue: string) {
    this.dataSourceModel.filter = filterValue.trim().toLowerCase();
  }

  OnAddDurableModel() {
    this.SaveUpdateButton = "Save";
    this.ButtonName = "Add";
    this.modelId = 0;
    $("#editConsumerDurableModel").modal("show");
    $("#editConsumerDurableModel").css("z-index", "1050");
  }

  OnClose() {
    this.SelectCategory = "0";
    this.SelectManufacture = "0";
    this.SelectProduct = "0";
    this.modelId = 0;
    this.SelectCategoryModel = "";
    this.SelectManufactureModel = "";
    this.SelectProductModel = "";
    this.modelName = "";

    $("#editConsumerDurableModel").modal("hidden");
  }

  editModelName(row) {
    this.getProductName(row.Category_Id, row.Manufacture_Id, false);
    this.getManufactureNames(row.Category_Id, false);

    this.SaveUpdateButton = "Update";
    this.ButtonName = "Edit";

    this.modelId = row.Id;
    this.SelectProductModel = row.Product_Id;
    this.SelectCategoryModel = row.Category_Id;
    this.SelectManufactureModel = row.Manufacture_Id;

    this.modelName = row.Model_Name;

    $("#editConsumerDurableModel").modal("show");
    $("#editConsumerDurableModel").css("z-index", "1050");
  }

  deleteModelName(row) {
    this.SelectCategoryModel = row.Category_Id;
    this.SelectManufactureModel = row.Manufacture_Id;
    this.SelectProductModel = row.Product_Id;

    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._MasterService
            .Save_Master_Consumer_Durable_Model({
              Id: row.Id,
              Model_Name: row.modelName,
              Product_Id: row.SelectProductModel,
              LoginUserId: this.currentUser.userId,
              SearchType: "Delete",
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.Get_Master_Consumer_Durable_Model();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  OnSaveAndEditDurableModel() {
    this._MasterService
      .Save_Master_Consumer_Durable_Model({
        Id: this.modelId,
        Model_Name: this.modelName,
        Product_Id: this.SelectProductModel,
        LoginUserId: this.currentUser.userId,
        SearchType: this.SaveUpdateButton == "Save" ? "Insert" : "Update",
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.Get_Master_Consumer_Durable_Model();
          console.log("IF CODE===0", res);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
