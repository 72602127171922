import { Component, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { constantUrl } from "../../Shared/constantUrl";
declare var $: any;
import { ToWords } from 'to-words';
import * as moment from 'moment';


@Component({
  selector: 'app-contra-voucher',
  templateUrl: './contra-voucher.component.html',
  styleUrls: ['./contra-voucher.component.scss']
})
export class ContraVoucherComponent implements OnInit {
  showSpinner: boolean = false; data: any;
  currentUser: any;
  docModal: any = { DocumentImages: [] };
  today: Date = new Date();
  loading: boolean = false;
  CurrentDate: any = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  empBranchDropdown: any[] = [];
  selectedData: any = {};
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ["LoanId", "LoanNo", "Product_Name", "Customer", "PhoneNo", "Branch_Name", "Action"];
  totalCalculator: any;
  isBankTag: boolean = false;
  Data_FreezeDate: any;
  selectedSearchIndex: any;
  Days_AllowedDateMin: any = new Date();
  Days_AllowedDateMax: any = new Date();
  CheckDate: any;
  Days_Allowed: any;
  Bind_Voucher: any = 1;
  voucherAdd: boolean = true;
  FyearId: any;
  maxDate: any;
  Min_Date: any;

  AllowExtension: any = constantUrl.AllowExtension;


  constructor(
    private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private _MasterService: MasterService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
  ) {
    this.CurrentDate = new Date();
    this.dataSharingService.HeaderTitle.next("Voucher Contra");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {

    this._MasterService.Get_Page_Rights_Of_EMP({ MMID: '196', LoginUserId: this.currentUser.userId }).subscribe((result: any) => {
      
      if (result.length > 0) {
        let AllPage = result;

        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") == '' || AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") == null) {
        
          this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
          this.dataSharingService.RefreshLoginPage.next('false');
          sessionStorage.removeItem('currentUser');
          sessionStorage.clear();
          this._Route.navigate(['Login']);
          return;
        }
        else if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") != null) {
          if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra")[0].View != 1) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
            this.dataSharingService.RefreshLoginPage.next('false');
            sessionStorage.removeItem('currentUser');
            sessionStorage.clear();
            this._Route.navigate(['Login']);
            return;
          }
        }
      }
    })
    if (new Date(this.currentUser.SubscriptionDate) <= new Date()) {
      this.voucherAdd = false;
    } else {
      this.voucherAdd = true;
    }
    this.Data_FreezeDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    this.Days_Allowed = this.currentUser.Days_Allowed;
    //var Days_AllowedDate = new Date();
    this.Days_AllowedDateMin.setDate(this.Days_AllowedDateMin.getDate() - this.Days_Allowed);
    this.Days_AllowedDateMax.setDate(this.Days_AllowedDateMax.getDate() + this.Days_Allowed);

    if (this.Days_AllowedDateMin > this.Data_FreezeDate) {
      this.CheckDate = this.Days_AllowedDateMin;
    } else {
      this.CheckDate = this.Data_FreezeDate;
    }
    this.getChargeshead();
    this.initializeVoucher();
    this.initializeVoucherDetail();
    this.Get_Acc_Accounts_OtherThan_BankCash();
    this.Get_EmployeeBranchForDropdown();

    this.getFyear();

    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.Min_Date = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.maxDate = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
          if (this.maxDate > new Date() && this.Min_Date < new Date())
            this.voucherModel.Voucher.Voucher_Date = new Date();
          else
            this.voucherModel.Voucher.Voucher_Date = this.maxDate;
        });
      }
    )
  }

  initializeVoucher() {
    this.isBankTag = false;
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Contra',
      Voucher_Sub_Type: 'Accounting',
      Voucher_Tag: '',
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId
    };
    setTimeout(() => {
      this.GetVoucherNo();
      //this.Get_Acc_Accounts_By_Tag();
    }, 500)

    if (this.maxDate > new Date() && this.Min_Date < new Date())
      this.voucherModel.Voucher.Voucher_Date = new Date();
    else this.voucherModel.Voucher.Voucher_Date = this.maxDate;
  }
  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      Is_LOSAppliaction: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: "",
      TranType: this.totalCalculator && this.totalCalculator.totalCR > this.totalCalculator.totalDR ? 'DR' : 'CR',
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: [],
      GSTModel: {},
      Bind_Voucher: this.Bind_Voucher,
      Type: ""
    });
  }
  getChargeshead() {
    this._MasterService.Get_ChargesHeadsByProduct_ForDropdow({ ProductId: this.selectedData.ProductId }).subscribe((res: any) => {
      this.chargesHeadDropdown[this.voucherModel.Voucher_Detail.length - 1] = res;
    });
  }
  Get_EmployeeBranchForDropdown() {
    this._MasterService.Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId }).subscribe((res: any) => {
      this.empBranchDropdown = res;
    });
  }
  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService.Get_Acc_Accounts_ForJVandContra({ IsJournal: 0 }).subscribe((res: any) => {
      this.accountHeadDropdown = res;
    });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    }
    else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  GetVoucherNo() {
    this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
    })
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }
  Get_Acc_Search_LoanNo() {
    this.lmsService.Get_Acc_Search_LoanNo({ SearchOn: this.selectedData.Is_LOSAppliaction == 1 ? 'ApplicationNo' : 'LoanNo', SearchValue: this.selectedData.SearchValue }).subscribe((res: any) => {
      if (res.length > 0) {
        if (res.length == 1) {
          this.selectSearchData(res[0])
        }
        this.dataSource = new MatTableDataSource(res);
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Search not found.", ...this.configSuccess });
      }
    });
  }
  selectSearchData(data: any) {
    this.selectedData.CaseNo = data.LoanNo;
    this.selectedData.Customer = data.Customer;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.BranchId = data.BranchId;
    this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.OnCloseSearch();
  }
  onVoucherSubTypeChange() {
    this.GetVoucherNo();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    this.getTotalCalculation();
  }
  onVoucherTagChange() {
    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherDateChange() {
    if (this.voucherModel.Voucher.Voucher_Date < this.Min_Date || this.voucherModel.Voucher.Voucher_Date > this.maxDate) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Enter Voucher Date between selected financial year.", ...this.configSuccess });
      this.voucherModel.Voucher.Voucher_Date = '';
    }
    this.GetVoucherNo();
    // this.Get_Acc_Accounts_By_Tag();
  }
  searchCustomer(data: any, index: any) {
    this.selectedSearchIndex = index;
    this.selectedData = data;
    this.dataSource = new MatTableDataSource([]);
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100)
  }
  OnCloseSearch() {
    $("#CustomerReceipt").modal("hide");
    $("#Customer" + this.selectedSearchIndex).focus();
    this.selectedData.SearchValue = "";
  }
  onChangeChargesHead(data: any, dindex: any) {
    let ChargeId, ChargeData1;
    let ChargeData = this.accountHeadDropdown.filter(x => x.Account_Name == data.ChargeHeadCategory);
    //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';

    //console.log("ChargeData", ChargeData);

    if (ChargeData.length > 0) {
      ChargeId = ChargeData[0].AccountId;
      this.lmsService.Get_Account_Balance({ AccountId: ChargeData[0].AccountId, ToDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {

        this.snackBar.openFromComponent(SnackbarComponent, { data: "Current Balance of " + ChargeData[0].Account_Name + ' is ' + res[0].Balance, ...this.configSuccess });

      })
    }
    else {
     
      ChargeId = '';
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
      return
    }

    /*if (!ChargeId) {
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      //this.getTotalCalculation();
      return;
    }*/
    let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
      //return index != dindex && Number(obj.ChargeHeadCategory) == Number(ChargeId)
      return index != dindex && obj.ChargeHeadCategory == data.ChargeHeadCategory;
    });
    //console.log("_find", _find);
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
      data.ChargeHeadId = "";
      this.voucherModel.Voucher_Detail[dindex].ChargeHeadId = "";
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      data.GSTModel = {};
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        data.BranchId = "";
      }
      //this.getTotalCalculation();
      return;
    }
    data.Amount = "";
    data.AccountId = "";
    //data.ChargeHeadCategory = "";
    data.TaxSlab = [];
    //this.getTotalCalculation();
    this.checkIsBankTag();
    this.lmsService.Get_AccountHead_For_VoucherEntry({
      HeadId: ChargeId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
      ProductId: data.ProductId || 0, TaxType: data.TranType
    }).subscribe((res: any) => {
      if (res.Item1[0]) {
        let _findA = this.accountHeadDropdown.find((obj: any) => { return obj.Account_Name == res.Item1[0].AccountName });
        if (_findA) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Account head not found.", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
      }
    });
  }
  checkIsBankTag() {
    this.isBankTag = false;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {

      let _findAc = this.accountHeadDropdown.find((aobj: any) => { return aobj.Account_Name == obj.ChargeHeadCategory });
      if (_findAc && _findAc.GroupTag && _findAc.GroupTag.toLowerCase() == 'bank' && obj.TranType == 'CR') {
        this.isBankTag = true;
        return;
      }
    });
    if (!this.isBankTag) {
      this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  onChangeAccountHead(data: any, index: any) {
    if (!data.AccountId) {
      data.Amount = "";
      delete data.IsLoanChargeable;
      this.getTotalCalculation();
      return;
    }
    let _find = this.accountHeadDropdown.find((obj: any) => { return obj.Account_Name == data.ChargeHeadCategory })
    data.IsLoanChargeable = _find.IsLoanChargeable;
    if (_find.IsLoanChargeable) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please search Application and choose category for this account head.", ...this.configSuccess });
    }
    data.ChargeHeadId = "";
    data.Amount = "";
    data.CaseNo = "";
    data.Customer = "";
    data.CustomerId = "";
    data.ProductId = "";
    data.BranchId = "";
    data.Branch_Name = "";
    this.getTotalCalculation();
  }
  onChangeCRDR(data: any) {
    data.CaseNo = "";
    data.CustomerId = "";
    data.Is_LOSAppliaction = "1";
    data.ChargeHeadId = "";
    data.ChargeHeadCategory = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer = "";
    data.Branch_Name = "";
    data.TaxSlab = [];
    this.getTotalCalculation();
  }
  onRefresh(data: any) {
    data.CaseNo = "";
    data.CustomerId = "";
    data.Is_LOSAppliaction = "1";
    data.ChargeHeadId = "";
    data.ChargeHeadCategory = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer = "";
    data.Branch_Name = "";
    data.TaxSlab = [];
    this.getTotalCalculation();
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    this.voucherModel.Voucher_Detail.BranchId = this.empBranchDropdown.filter(x => x.Branch_Name == data.Branch_Name)[0].BranchId;

    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    });
    this.getTotalCalculation();
  }
  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherDetail();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    this.getTotalCalculation();
  }
  saveVoucher() {
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _voucheretail: any[] = [];
    // let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //let _total: any = 0;
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalTXCR: any = 0;
    let _totalTXDR: any = 0;
    var ChargeId;
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
      ChargeId = '';
    }
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount);
      }
      else {
        _totalDR = _totalDR + parseFloat(obj.Amount);
      }
      _voucheretail.push(this.dataSharingService.cleanObject({
        VoucherId: obj.VoucherId,
        CaseNo: obj.CaseNo,
        CustomerId: obj.CustomerId,
        Is_LOSAppliaction: obj.Is_LOSAppliaction,
        ChargeHeadId: ChargeId,
        ChargeHeadCategory: obj.ChargeHeadCategory,
        AccountId: obj.AccountId,
        BranchId: obj.BranchId,
        TranType: obj.TranType,
        IsTax: obj.IsTax,
        Tax_Per: obj.Tax_Per,
        Amount: obj.Amount,
        Bind_Voucher: obj.Bind_Voucher,
      }));
      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.TranType == "CR") {
          _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
          _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
        }
        else {
          _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
          _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
        }
        _voucheretail.push(this.dataSharingService.cleanObject({
          VoucherId: obj.VoucherId,
          CaseNo: obj.CaseNo,
          CustomerId: obj.CustomerId,
          Is_LOSAppliaction: obj.Is_LOSAppliaction,
          ChargeHeadId: ChargeId,
          ChargeHeadCategory: tobj.TaxType,
          AccountId: tobj.TaxAccountId,
          BranchId: obj.BranchId,
          TranType: obj.TranType,
          IsTax: 1,
          Tax_Per: tobj.TaxRate,
          Amount: tobj.TaxAmount,
          Bind_Voucher: obj.Bind_Voucher,
        }))
      });
    });

    _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    _vouvher.Voucher_Total_Debit_Amount = _totalDR;
    _vouvher.Voucher_Total_Tax = _totalTXCR + _totalTXDR;
    if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Total Debit Amount should be equal to Total Credit.", ...this.configSuccess });
      return;
    }

    let Attachment: any[] = [];
    if (this.docModal.DocumentImages.length) {
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        Attachment.push({ Doc_path: this.docModal.DocumentImages[i].DocFileName });
      }
    }
    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: _voucheretail,
      Voucher_Attachment: Attachment
    }

    this.lmsService.Acc_Save_Voucher({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.onSaveDoc();
        this.initializeVoucher();
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();
        this.totalCalculator = undefined;
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  onChangeTax() {
    this.getTotalCalculation();
  }
  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + Number(obj.Amount) || 0;
      }
      else {
        _totalDR = _totalDR + Number(obj.Amount) || 0;
      }
      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.TranType == "CR") {
          _totalCR = _totalCR + parseFloat(tobj.TaxAmount) || 0;
          _totalCRTX = _totalCRTX + parseFloat(tobj.TaxAmount) || 0;
        }
        else {
          _totalDR = _totalDR + parseFloat(tobj.TaxAmount) || 0;
          _totalDRTX = _totalDRTX + parseFloat(tobj.TaxAmount) || 0;
        }
      });
    });
    this.totalCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(Number(_totalCR)),
      totalDRInWord: new ToWords().convert(Number(_totalDR))
    }
  }
  fileChangeListenerD(files: any, input: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages.DocFileName = "";
      delete this.docModal.DocumentImages.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages.DocFileName = "";
      delete this.docModal.DocumentImages.DocData;
      return;
    }

    let $this = this;
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let _pre = this.today.getTime();
      let _docImg: any = {};
      _docImg.DocFileName = _pre + "_" + files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  onSaveDoc() {
    let _data = { ...this.docModal };

    if (this.docModal.DocumentImages.length) {


      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        this.lmsService
          .UploadAccountDoc({
            DocName: this.docModal.DocumentImages[i].DocFileName,
            DocData: this.docModal.DocumentImages[i].DocData,
          })
          .subscribe(
            (res: any) => {
              if ((i + 1) == this.docModal.DocumentImages.length) {
                //this.saveDoc();
              }
            },
            (err: any) => {
              _err = true;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "File not uploaded.",
                ...this.configSuccess,
              });
            }
          );
        if (_err) {
          break;
        }

      }
      this.docModal = { DocumentImages: [] };
    }
  }

  getFyear() {
    this.lmsService.Get_FinancialYear({ Id: 0 }).subscribe((res: any) => {
      let FyearDropdown = res;
      let FyearId = FyearDropdown[FyearDropdown.length - 1].Id;
      var Url = this._Route.url.split('?')[0];
      let FyearIdsessionStorage = parseInt(JSON.parse(sessionStorage.getItem('FyearId')));
      this._Route.navigate([Url], { queryParams: { fyear: FyearIdsessionStorage > 0 ? FyearIdsessionStorage : FyearId, BranchId: 0 } });
    });
  }
  onChangebranch(data) {
    let BranchId, BranchData1;
    let BranchData = this.empBranchDropdown.filter(x => x.Branch_Name == data.Branch_Name);
    //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';

    //console.log("ChargeData", ChargeData);

    if (BranchData.length > 0) {
      BranchId = BranchData[0].AccountId;
    }
    else {
      BranchId = '';
      data.Amount = "";
      data.Branch_Name = "";
      // data.TaxSlab = [];
      data.TaxSlab1 = [];
      data.GSTModel = {};
      this.getTotalCalculation();
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Branch Name not found", ...this.configSuccess });
      return
    }
  }
}
