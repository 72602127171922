export class LoginModel
{
 
  public Username: string = "";
  public Password: string = "";  
  public IPAddreess: string = "";
  public UserLoginID: string = "";
  public MobileNo: string = "";
  public MacAddresss: string = "";
}
