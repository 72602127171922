import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  Injectable,
} from "@angular/core";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";

import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
declare var $: any;
// import { LmsService } from "../../_Lms/services/lms.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { LosService } from "../_LOS/services/los.service";
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { MasterService } from "../Shared/app.Masters.Service";
import { ReportsService } from "../_Reports/services/reports.service";
import { DialogService } from "../Shared/dialog.service";
import { LmsService } from "../_Lms/services/lms.service";
import { SnackbarComponent } from "../snackbar/snackbar.component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
@Component({
  selector: "app-lms-receipt-book-team",
  templateUrl: "./lms-receipt-book-team.component.html",
  styleUrls: ["./lms-receipt-book-team.component.scss"],
})
export class LmsReceiptBookTeamComponent implements OnInit {
  showSpinner: boolean = false;
  dataSource: any;
  voucherdatefrom: any = new Date();
  voucherdateto: any = new Date();
  executiveList: any;
  Branch: any = 0;
  executive: any = 0;
  currentUser: any;
  BranchesList: any;
  leadSourceDropdown: any;
  SelectSourceType: any = "";
  partnerDropdown: any;
  LeadSourceName: any = "";
  SalesExecutiveData: any;
  SearchApplication_No: any = "";
  SearchLoanAcNo: any = "";
  JsonData: any;

  constructor(
    private losService: LosService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private LmsService: LmsService,
    private reportsService: ReportsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder
  ) {}

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[] = [
    "VoucherId",
    "CaseNo",
    "Voucher_Date",
    "Voucher_Type",
    "Voucher_ReceiptNo_Book",
    "customer_name",
    "Branch_Name",
    "SourceType",
    "SourceName",

    "Amount",
    "Installment_Amount",
    "Interest_Overdue_Amount",
    "Other_Amount",
    "Account_Name",
    "Collection_By",
    "User_Name",
    "CollectionExecutive",
    "Voucher_CreateOn",
    "Voucher_Narration",
  ];

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Receipt Book By Team");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.BindExecuite();
    this.getBranchesList();
    this.getLeadSourceDropdown();
    this.Get_PartnerForDropdown();
  }

  DateChange() {
    if (this.voucherdatefrom > this.voucherdateto) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Date To greater than Date From.",
        ...this.configSuccess,
      });
    }
  }

  BindExecuite() {
    this._MasterService
      .Get_Employee_ByBranch_Department({
        BranchId: 0,
        DeptId: 0,
        LoginUserId: this.currentUser.userId,
        Type: "Lower",
      })
      .subscribe((res: any) => {
        this.executiveList = res;
      });
  }

  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe(
      (result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      }
    );
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onsearch() {
    this.showSpinner = true;
    this.LmsService.LMS_Receipt_Book_By_Team({
      From_Date: this.voucherdatefrom,
      To_Date: this.voucherdateto,
      Voucher_CreateBy: this.executive,
      Branch_Id: this.Branch,
      Application_No: this.SearchApplication_No,
      LoanAcNo: this.SearchLoanAcNo,
      SourceType: this.SelectSourceType || "",
      SourceId: this.LeadSourceName || 0,
      LoginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      console.log(res, "response of api call");
      if (res.length > 0) {
        this.JsonData = res;
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      } else {
        this.dataSource = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    });
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcel() {
    this.JsonData.forEach((obj: any) => {
      delete obj.VoucherId;
      delete obj.ChagesHead;
      delete obj.Voucher_Total_Tax;
      delete obj.Voucher_Tag;
      delete obj.Voucher_CreateOn;
      delete obj.CustomerId;
      delete obj.Loan_Id;
      delete obj.Voucher_Cheque_No;
    });

    this.exportAsExcelFile(this.JsonData, "Receipt-Book");
  }
}
