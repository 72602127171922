import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { LosService } from '../../_LOS/services/los.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../../_Lms/services/lms.service';
import * as moment from 'moment';
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { constantUrl } from '../../Shared/constantUrl';
import { QuickLoanService } from '../../_QuickLoan/services/quickLoan.service';

@Component({
  selector: 'app-lms-income-expenditure',
  templateUrl: './lms-income-expenditure.component.html',
  styleUrls: ['./lms-income-expenditure.component.scss']
})
export class LMSIncomeExpenditureComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  customerData: any = {};
  IncExpData: any;
  CustomerId: any;
  IncomeTypeDropdown: any = [];
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;

  isIncomeEdit: boolean = false;
  IncomeModel: any = [];
  ExpenseModel: any = [];
  ObligationModel: any = [];
  totalCalculatorObligation: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();


  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  @Input() loanSummary: any;

  totalCalculator: any;
  totalCalculator1: any;
  today: Date = new Date();

  Margin: any;
  Eligibility: any;
  EMIEligibility: any;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;

  //BtnAddView: any = 'Fill';

  docBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService,
  ) { }

  ngOnInit(): void {

    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.dataSharingService.HeaderTitle.next('Income & Exp.');

   
      this.IsEdit = true;
      this.IsSave = false;
      this.IsDisabled = true;
      this.LMS_GetLoanDetails();
   
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      console.log("LMS_GetLoanDetails", res);
      this.showSpinner = false;
      this.customerData = res.Item2;
      this.GetIncomeExpensesList();
    })
  }

  GetIncomeExpensesList() {
    //console.log("this.loanSummary", this.loanSummary);
    this._QuickLoanService.QuickLoan_Get_Income_Expenses_List({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
      //console.log("RES @@@@222", res);
      this.IncExpData = res;

      this.customerData.forEach((obj: any) => {
        res.find((x) => {
          if (obj.CustomerId == x.Customer_Id) {
            obj.Income_Total_Amount = x.Income_Total_Amount;
            obj.Expenses_Total_Amount = x.Expenses_Total_Amount;
            obj.Obligation_Total_Amount = x.Obligation_Total_Amount;
          }
        });
      });

      //this.BtnAddView = this.IncExpData && this.IncExpData.Expenses_Total_Amount > 0 ? 'View' : 'Fill';
    })
  }


  InitializeObligationDetail() {
    this.ObligationModel.push({
      Customer_Id: this.CustomerId,
      Loan_Id: this.loanSummary.LoanId,
      Type: "Obligation",
      Frequency: "Monthly",
      Amount: "",
      Nature: "",
      Institution_From: "",
      Total_Amount: "",
      Remark: "",
      Doc_FileName: "",
      LoginUserId: this.currentUser.userId,
    });
  }
  addMoreObligation() {
    if (this.ObligationModel.length > 0) {
      let _prev = this.ObligationModel[this.ObligationModel.length - 1];
      if (
        _prev.Nature == "" ||
        _prev.Institution_From == "" ||
        _prev.Amount == ""
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.onChangeAmountObligation();
    this.InitializeObligationDetail();
  }
  removeObligation(index: any) {
    this.ObligationModel.splice(index, 1);
    this.onChangeAmountObligation();
  }
  onChangeAmountObligation() {
    let _total: any = 0;
    this.ObligationModel.forEach((obj: any) => {
      _total = _total + parseFloat(obj.Amount) || 0;
    });

    this.totalCalculatorObligation = {
      total: parseFloat(_total),
    };
    if (this.totalCalculatorObligation) {
      this.Margin = this.totalCalculatorObligation.total - this.totalCalculatorObligation.total;
    }
  }

  InitializeIncomeDetail() {
    this.IncomeModel.push({
      Customer_Id: this.CustomerId,
      Loan_Id: this.loanSummary.LoanId,
      Type: "Income",
      Frequency: "Monthly",
      Amount: "",
      Nature: "",
      Institution_From: "",
      Total_Amount: "",
      Remark: "",
      Doc_FileName: "",
      LoginUserId: this.currentUser.userId,
    });
  }

  InitializeExpenseDetail() {
    this.ExpenseModel.push({
      Customer_Id: this.CustomerId,
      Loan_Id: this.loanSummary.LoanId,
      Type: "Expense",
      Frequency: "Monthly",
      Amount: "",
      Nature: "",
      Institution_From: "",
      Total_Amount: "",
      Remark: "",
      Doc_FileName: "",
      LoginUserId: this.currentUser.userId,
    });
  }

  AddCustomerIncExp(row: any) {
    this.CustomerId = row.CustomerId;
    this.QuickLoan_Get_Income_Expenses_Detail();
    this.get_IncomeDropdownList();
    $('#IncomeExpenditure').modal('show');
    $("#IncomeExpenditure").css("z-index", "1050");
  }

  onCloseIncomeExpenditure() {
    $('#IncomeExpenditure').modal('hide');
  }

  addMoreIncome() {
    if (this.IncomeModel.length > 0) {
      let _prev = this.IncomeModel[this.IncomeModel.length - 1];
      if (_prev.Nature == "" || _prev.Institution_From == "" || _prev.Amount == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.InitializeIncomeDetail();
  }
  removeIncome(index: any) {
    this.IncomeModel.splice(index, 1);
    this.onChangeAmount();
  }

  addMoreExpense() {
    if (this.ExpenseModel.length > 0) {
      let _prev = this.ExpenseModel[this.ExpenseModel.length - 1];
      if (_prev.Nature == "" || _prev.Institution_From == "" || _prev.Amount == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.InitializeExpenseDetail();
  }
  removeExpense(index: any) {
    this.ExpenseModel.splice(index, 1);
    this.onChangeAmount1();
  }

  onChangeAmount() {
    let _total: any = 0;
    this.IncomeModel.forEach((obj: any) => {
      _total = _total + parseFloat(obj.Amount) || 0;
    });

    this.totalCalculator = {
      total: parseFloat(_total)
    }
    if (this.totalCalculator1) {
      this.Margin = this.totalCalculator.total - this.totalCalculator1.total;
    }
  }

  onChangeAmount1() {
    let _total: any = 0;
    this.ExpenseModel.forEach((obj: any) => {
      _total = _total + parseFloat(obj.Amount) || 0;
    });

    this.totalCalculator1 = {
      total: parseFloat(_total)
    }
    if (this.totalCalculator) {
      this.Margin = this.totalCalculator.total - this.totalCalculator1.total;
    }
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.Doc_FileName = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Please upload correct file`, ...this.configSuccess });
      files.value = "";
      data.Doc_FileName = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.Doc_FileName = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Doc_FileName = "";
    delete data.DocData;
  }

  fileChangeListener1(data: any, files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.Doc_FileName = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Please upload correct file`, ...this.configSuccess });
      files.value = "";
      data.Doc_FileName = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Doc_FileName = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile1(data: any, input: any) {
    input.value = "";
    data.Doc_FileName = "";
    delete data.DocData;
  }

  uploadIncomeDocs(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService.UploadCustomerDoc({ CustomerID: id, DocName: obj.Doc_FileName, DocData: obj.DocData }).subscribe((res: any) => {
        });
      }
    });
  }

  uploadIncomeDocs1(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService.UploadCustomerDoc({ CustomerID: id, DocName: obj.Doc_FileName, DocData: obj.DocData }).subscribe((res: any) => {
        });
      }
    });
  }

  onChangeEligibilityRatio() {
    this.EMIEligibility = this.Margin * this.Eligibility / 100;
  }

  QuickLoan_Get_Income_Expenses_Detail() {
    this._QuickLoanService.QuickLoan_Get_Income_Expenses_Detail({ LoanId: this.loanSummary.LoanId, CustomerId: this.CustomerId }).subscribe((res: any) => {
      console.log("this.QuickLoan_Get_Income_Expenses_Detail", res);

      if (res.length > 0) {
        this.IncomeModel = res.filter(x => x.Type == "Income");
        this.onChangeAmount();

        this.ExpenseModel = res.filter(x => x.Type == "Expense");
        this.onChangeAmount1();

        this.ObligationModel = res.filter((x) => x.Type == "Obligation");
        this.onChangeAmountObligation();
        this.IsEdit = true;
        this.IsSave = false;
        this.IsDisabled = true;
        this.IsUpdate = false;
        if (this.IncomeModel.length == 0) {
          this.InitializeIncomeDetail();
        }
        if (this.ExpenseModel.length == 0) {
          this.InitializeExpenseDetail();
        }
        if (this.ObligationModel.length == 0) {
          this.InitializeObligationDetail();
        }
      }
      else {
        this.IncomeModel = [];
        this.ExpenseModel = [];
        this.ObligationModel = [];
        this.totalCalculator = null;
        this.totalCalculator1 = null;
        this.Margin = null;

        this.InitializeIncomeDetail();
        this.InitializeExpenseDetail();
        this.InitializeObligationDetail();
        this.IsEdit = false;
        this.IsSave = true;
        this.IsDisabled = false;
      }
    });
  }

  onSaveIncomeExpenditure() {
    this.IncomeModel.forEach((obj: any) => {
      obj.Total_Amount = obj.Amount * 12; //this.totalCalculator.total;
      obj.Loan_Id = this.loanSummary.LoanId;
      obj.LoginUserId = this.currentUser.userId;
    });

    this.ExpenseModel.forEach((obj: any) => {
      obj.Total_Amount = obj.Amount * 12; //this.totalCalculator1.total;
      obj.Loan_Id = this.loanSummary.LoanId;
      obj.LoginUserId = this.currentUser.userId;
    });
    this.ObligationModel.forEach((obj: any) => {
      obj.Total_Amount = obj.Amount * 12; //this.totalCalculator1.total;
      obj.Loan_Id = this.loanSummary.LoanId;
      obj.LoginUserId = this.currentUser.userId;
    });
    var Result = this.IncomeModel.concat(this.ExpenseModel).concat(this.ObligationModel);

    let _data = {
      Income_and_Expenses: Result
    }
    console.log("_data", _data);

    this._QuickLoanService.QuickLoan_Save_Income_Expenses_Detail({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.uploadIncomeDocs(this.CustomerId, this.IncomeModel);
        this.uploadIncomeDocs1(this.CustomerId, this.ExpenseModel);
        this.uploadIncomeDocs1(this.CustomerId, this.ObligationModel);
        this.LMS_GetLoanDetails();
        this.onCloseIncomeExpenditure();
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
    });
  }

  onEditIncomeExpense() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  CheckCustomerIncomeExpInfo() {
    // if (this.IncExpData.length > 0) {
    this.LmsService.SaveNext_Loan({

      Loan_Id: this.loanSummary.LoanId, CommandName: 'IncomeExp', isStatus: 1
    }).subscribe((response) => {
      this.action.emit("next")
    })
    //}
    //else {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Income & Expense.", ...this.configSuccess });
    //}
  }


  get_IncomeDropdownList() {
    this._MasterService.GetCustomer_Profile_Master_For_Dropdown({ Type: 'Nature of work', P_ID: 0 }).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.IncomeTypeDropdown = res;
        }
      })
  }
  onChangeIncomeType(data: any, dindex: any) {
    let Nature, ChargeData1;
    let IncomeType = this.IncomeTypeDropdown.filter(x => x.Value == data.Nature);

    if (IncomeType.length <= 0) {
      data.Nature = "";
     // this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
      return
    }

  }
}
