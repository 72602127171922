import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-lms-modify-booking-list",
  templateUrl: "./lms-modify-booking-list.component.html",
  styleUrls: ["./lms-modify-booking-list.component.scss"],
})
export class LmsModifyBookingListComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  DealerList: any[] = [];
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  leadSourceDropdown: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  SelectSourceType: string;
  partnerDropdown: any;
  LeadSourceName: string;
  SourceType: any;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(
    paginator: MatPaginator
  ) {
    if (paginator != undefined) {
      this.dataSourceLoan.paginator = paginator;
    }
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.dataSourceLoan.sort = this.sort;
    }
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Customer: any = {};
  SelectType: any = "";

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService
  ) {
    sessionStorage.removeItem("LoanSummary");
  }

  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next("View-Modify Loan");
    this.dataSharingService.HeaderTitle.next("View-Modify Loan");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    //this.LoanList();
    this.Customer.CloseCase = "";

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
    this.getDealerList();
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();

    this.displayedColumnsLoan = [
      "LoanId",
      "LoanAcNo",
      "Application_No",
      "Customer",
      "Branch",
      "Product",
      "LoanAMount",
      "LoanCreatedOn",
      "Assign",
    ];
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.Customer.SourceType == "Agent" ||
      this.Customer.SourceType == "DSA" ||
      this.Customer.SourceType == "Dealer"
    ) {
      console.log("if condition logggg");
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.Customer.SourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
          console.log("Partner dropdown", this.partnerDropdown);
        });
    } else if (this.Customer.SourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
    } else {
      this.LeadSourceName = "";
    }
    this.LeadSourceName = "";
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
      SourceId: "",
      SourceType: "",
    };
  }

  LoanList() {
    this.showSpinner = true;

    this.losService.setFilterDropdownValue({
      "file-checking-list": "Completed",
    });

    this.lmsService
      .LMS_Get_ModifyBooking_List({
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(this.Customer),
      })
      .subscribe((res: any) => {
        //console.log("RESULT : ",res);
        if (res.length > 0) {
          if (res.length == 1) {
            this.goToDetailLoan(res[0]);
          } else {
            this.FileCheckingList = JSON.parse(JSON.stringify(res));
            this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
            this.dataSourceLoan.sort = this.sort;
            this.dataSourceLoan.paginator = this.paginator;
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceLoan = null;
        }
        this.showSpinner = false;
      });
  }

  goToDetailLoan(data: any) {
    //console.log("data : ",data);
    this.router.navigate([
      `/lms/loan-info/${encodeURIComponent(data.LoanId)}/${"L"}`,
    ]);
  }

  goToDetail(data: any) {
    // let Application_No = this.encdec.decrypt(data.Application_No);
    // if (Application_No) {
    this.router.navigate([
      `/lms/loan-info/${encodeURIComponent(data.ApplicationId)}/${"A"}`,
    ]);
    //}
  }
  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "lms_modify_booking_list"
    ) as HTMLTableElement;
    const worksheetName = "MODIFY BOOKING LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  getDealerList() {
    this.lmsService.GET_Partner_List({ Type: "Dealer" }).subscribe((result) => {
      this.DealerList = JSON.parse(JSON.stringify(result));
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }
}
