<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-3">
    <div class="col-md-6 p-0">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'plist' }" (click)="changeTabList('plist')">
        Partner Loan Statement
      </button>
    </div>
    <div class="col-md-6 pr-0">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'clist' }" (click)="changeTabList('clist')">
        Customer Loan Statement
      </button>
    </div>
  </div>

  <div class="row m-0 mt-2" *ngIf="tab=='plist'">
    <app-lms-loanStatement [loanSummary]="PLoanSummary" [PageType]="PageType" [PLoanType]="PLoanType" [FirstEMIDate]="FirstEMIDate"></app-lms-loanStatement>
  </div>
  <div *ngIf="tab=='clist'">
    <app-lms-loanStatement [loanSummary]="loanSummary" [PageType]="PageType"></app-lms-loanStatement>
  </div>
</div>