import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../AuthGuard/DataSharingService';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var $: any;
import { DatePipe } from '@angular/common';
import { constantUrl } from "../Shared/constantUrl";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MasterService } from '../Shared/app.Masters.Service';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { PresenceService } from '../_Chatbox/_service/presence.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-commonheader',
  templateUrl: './commonheader.component.html',
  styleUrls: ['./commonheader.component.scss']
})
export class CommonheaderComponent implements OnInit {
  CompanyName: any;
  private data: any;
  username: any = "";
  FullName: any = "";
  loginButtonShow: any = '';
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  employeeid: any; ProfilePic: any;
  PasswordModel: any = [];
  MobileAPKUrl: any = constantUrl.OthersDocUrl;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  CompanyLogo: any;
  SUB_Heading: any;
  constructor(private datepipe: DatePipe,
    private dataSharingService: DataSharingService, 
    private router: Router,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private _EncrDecrService: EncrDecrService,
    private presenceService: PresenceService
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      var obj = JSON.parse(JSON.stringify(event));
      if (obj.url != '/false') {
        this.loginButtonShow = obj.url;
      }
      if (obj.url == '/Home' || obj.url == '/') {
        sessionStorage.removeItem('currentUser');
        sessionStorage.clear();
        this.username = '';
      }
    });

    let usr=JSON.parse(sessionStorage.getItem('currentUser'));
    //User active
    if(usr){
      presenceService.currentUser.next(usr);
    }
  }

  getUsername() {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log("this.currentUser", this.data);
    if (this.data != null) {
      this.username = this.data.username;
      this.FullName = this.data.FullName;
      this.employeeid = this.data.userId;
      this.ProfilePic = this.data.ProfilePic;
      this.CompanyName = this.data.CompanyName;
      this.CompanyLogo = this.data.CompanyLogo;
      var Chkdate = new Date(this.data.SUB_Date.split('T')[0]);
      var Time = Chkdate.getTime() - new Date(this.datepipe.transform(new Date(), 'yyyy-MM-dd')).getTime();
      console.log("day", Time / (1000 * 3600 * 24));
      if (Time / (1000 * 3600 * 24) > 0 && Time / (1000 * 3600 * 24) < 10) {
        this.SUB_Heading = 'Your subscription is going to Expired on ' + this.datepipe.transform(this.data.SUB_Date.split('T')[0], 'dd-MM-yyyy') +'!!!'
      }
      else if (Time / (1000 * 3600 * 24) <0) {
        this.SUB_Heading = 'Your subscription is Expired Renew Immediately!!!'
      }
      else {
        this.SUB_Heading = ''
      }
    }
    else{
      this.CompanyLogo = 'logo.png';
    }
  }

  ngOnInit() {
    this.getUsername();
    this.dataSharingService.getRefreshLoginPage().subscribe(value => {
      if (value == 'false') {
        this.username = '';
      } else {
        this.getUsername();
      }
    });

    $('#menu').click(function () {
      $('#test').animate({ height: 'toggle' }, 100, function () {
      
      });
    });
  }

  ChangePassword(){
    $('#test').animate({height: 'toggle'}, 100, function () { });
    this.PasswordModel = {
      Old_Password : '',
      New_Password : '',
      Confirm_Password : ''
    };

    $('#ChangePasswordModal').modal('show');
    $("#ChangePasswordModal").css("z-index", "1050");
  }

  OnCloseChangePasswordModal(){
    $('#ChangePasswordModal').modal('hide');
  }
  onUpdateChangePassword(){
    if(this.PasswordModel.New_Password == this.PasswordModel.Confirm_Password){
      this._MasterService.Update_Password({
        EmpId: this.data.userId.toString(), Old_Password: this.PasswordModel.Old_Password, New_Password: this.PasswordModel.New_Password
      }).subscribe((res: any) => {     
        
        if(res && res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.OnCloseChangePasswordModal();
          this.router.navigate([`/UserLogout`]);
        } 
        else{
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
    }
    else{
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Confirm Password Same As New Password !!.", ...this.configSuccess });
    }
  }
 
}
