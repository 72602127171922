import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";

@Component({
  selector: "app-mst-lms-loan-swap",
  templateUrl: "./mst-lms-loan-swap.component.html",
  styleUrls: ["./mst-lms-loan-swap.component.scss"],
})
export class MstLmsLoanSwapComponent implements OnInit {
  currentUser: any;
  JsonData: any[] = [];
  showSpinner: boolean = false;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Customer: any = {};
  SelectType: any = "";

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  SwapModel: any = {};
  SelectAll: any;
  IsSelected: boolean = false;
  SwapType: any = "";
  LoanId: any;

  CollExecData: any = [];
  ProductList: any = [];
  SwapareaData: any = [];
  SwapsubAreaData: any = [];

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private _MasterService: MasterService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Swap");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumnsLoan = [
      "Select",
      "LoanId",
      "LoanAcNo",
      "Customer",
      "Loan_Acc_Category",
      "Branch",
      "Product",
      "LoanAMount",
      "LoanCreatedOn",
    ];

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.GetCollExecData();
    this.getProductDropdown();
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  // Dropdown for tabledata below filter parameter
  GetCollExecData() {
    this.lmsService
      .Get_Lms_CollectionExecutive({ Branch_Id: this.SwapModel.BranchId })
      .subscribe((res) => {
        this.CollExecData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByBranch() {
    this.lmsService
      .Get_Lms_Area_ByBranch({ Branch_Id: this.SwapModel.BranchId })
      .subscribe((res: any) => {
        this.SwapareaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByBranch() {
    this.lmsService
      .Get_Lms_SubArea_ByArea({ Area_Id: Number(this.SwapModel.Area) })
      .subscribe((res: any) => {
        this.SwapsubAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((res) => {
        this.ProductList = JSON.parse(JSON.stringify(res));
      });
  }

  OnChangeBranch() {
    this.SwapModel.Area = "";
    this.getAreaByBranch();
  }
  OnChangeArea() {
    this.SwapModel.SubArea = "";
    this.getSubAreaByBranch();
  }

  LoanList() {
    this.showSpinner = true;
    this.Customer.swap = 1;
    this.lmsService
      .LMS_Get_ModifyBooking_List({
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(this.Customer),
      })
      .subscribe((res: any) => {
        console.log("RESULT : ", res);
        this.showSpinner = false;
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.JsonData.forEach((x) => {
            x.IsSelected = false;
          });
          this.dataSourceLoan = new MatTableDataSource(this.JsonData);

          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceLoan = "";
        }
      });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Loan-Swap`,
      sheet: "Loan-Swap",
    });
  }

  SearchChange() {
    this.Customer = {
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  checkUncheckAll(event) {
    this.JsonData.forEach((x) => {
      if (event.checked == true) {
        x.IsSelected = true;
      }
      if (event.checked == false) {
        x.IsSelected = false;
      }
    });
    this.dataSourceLoan = new MatTableDataSource(this.JsonData);
  }

  OnCheckSwapChechBox(event: any, row: any) {
    if (this.LoanId == row.LoanIdentity) {
      this.LoanId = "";
    } else {
      this.LoanId = row.LoanIdentity;
    }
  }

  ChangeSwapType() {
    this.IsSelected = false;
    this.LoanId = "";
    this.SelectAll = "";
    this.JsonData.forEach((x) => {
      x.IsSelected = false;
    });

    this.SwapModel = {
      BranchId: "",
      Area: "",
      SubArea: "",
      CollectionEx: "",
      ProductId: "",
      LoanAccountingId: "",
    };
    let e = document.getElementById("swapdata");
    e.scrollIntoView({
      block: "end",
      behavior: "smooth",
      inline: "center",
    });
  }

  SaveLoanSwap() {
    this.showSpinner = true;
    var LoanIds = "",
      json;
    this.JsonData.map((x) => {
      if (x.IsSelected == true) {
        LoanIds = LoanIds + x.LoanIdentity + ",";
      }
    });

    this.SwapModel.LoanIds =
      this.SwapType == "Branch" ? LoanIds : this.LoanId.toString();
    json = { Search: this.SwapModel };

    if (LoanIds != "" || this.LoanId != "") {
      this.lmsService
        .LMS_Loan_Swap({ JSON: JSON.stringify(json) })
        .subscribe((res: any) => {
          this.showSpinner = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.LoanList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select atleast one checkbox",
        ...this.configSuccess,
      });
      this.showSpinner = false;
    }
  }

  totaltypewise(type) {
    if (this.dataSourceLoan != undefined) {
      if (this.dataSourceLoan != null) {
        let sum = 0;
        for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
          sum += Number(this.dataSourceLoan.filteredData[i][type]);
        }
        return sum > 0 ? sum.toFixed(2) : "";
      }
      return "";
    }
  }
}
