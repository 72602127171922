import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-mstscheme",
  templateUrl: "./mstscheme.component.html",
  styleUrls: ["./mstscheme.component.scss"],
})
export class MstschemeComponent implements OnInit {
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "SchemeId",
    "Scheme",
    "Product",
    "Amount",
    "ROI",
    "Period",
    "AdvanceEMI",
    "Status",
    "SchemeState",
    "Edit",
    "Delete",
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  schemeList: any = [];
  schemeModel: any = {};
  currentUser: any;
  productDropdown: any[] = [];
  stateDropdown: any[] = [];
  ProductId: any = "";
  CurrentDate: any = new Date();
  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Scheme List");

    this.getProductDropdown();
  }
  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));
      this.ProductId =
        this.productDropdown.length > 0
          ? this.productDropdown[0].ProductId
          : "";
      this.geSchemeList();
      this.showSpinner = false;
    });
  }
  geSchemeList() {
    if (this.ProductId != "") {
      this.showSpinner = false;
      this._MasterService
        .Get_SchemeList({ ProductId: this.ProductId })
        .subscribe((result: any) => {
          this.schemeList = JSON.parse(JSON.stringify(result));
          this.dataSource = new MatTableDataSource(this.schemeList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        });
    }
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", { fileName: `Scheme-list`, sheet: "Scheme" });
  }
  onAddScheme(data: any, e: any = null) {
    if (e) {
      e.stopPropagation();
    }
    if (data.SchemeId) {
      this._MasterService
        .Get_SchemeDetail({ SchemeId: data.SchemeId })
        .subscribe((res: any) => {
          this.schemeModel = res[0];
          this.schemeModel.LoginUserId = this.currentUser.userId;
          this.schemeModel.State = [];
          let _state: any[] = this.schemeModel.Scheme_States.split(",");
          _state.forEach((obj) => {
            this.schemeModel.State.push(parseInt(obj));
          });
          this.schemeModel.Scheme_FromDate = new Date(
            this.schemeModel.Scheme_FromDate
          );
          this.schemeModel.Scheme_EndDate = new Date(
            this.schemeModel.Scheme_EndDate
          );
          this.GetStateDropdown();
          $("#schemeModel").modal("show");
          $("#schemeModel").css("z-index", "1050");
        });
    } else {
      this.schemeModel.SchemeId = 0;
      this.schemeModel.LoginUserId = this.currentUser.userId;
      this.schemeModel.Scheme_ProductId = this.ProductId;
      this.GetStateDropdown();
      $("#schemeModel").modal("show");
      $("#schemeModel").css("z-index", "1050");
    }
  }
  OnClose() {
    this.schemeModel = {};
    $("#schemeModel").modal("hide");
  }
  onDeleteScheme(data: any, e: any = null) {
    if (e) {
      e.stopPropagation();
    }
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this._MasterService
            .Delete_Scheme({
              SchemeId: data.SchemeId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.geSchemeList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  GetStateDropdown() {
    this._MasterService.GetState().subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }
  onSaveScheme() {
    if (this.schemeModel.Scheme_MaxAmount < this.schemeModel.Scheme_MinAmount) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Max Amount should be greater than Min Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.schemeModel.Scheme_MaxROI < this.schemeModel.Scheme_MinROI) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Max ROI should be greater than Min ROI.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.schemeModel.Scheme_EndDate < this.schemeModel.Scheme_FromDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "End Date should be greater than From Date.",
        ...this.configSuccess,
      });
      return;
    }
    this.schemeModel.Scheme_States = this.schemeModel.State.join(",");
    this.loading = true;
    this._MasterService
      .Save_Scheme({ JSON: JSON.stringify({ Scheme: this.schemeModel }) })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.geSchemeList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
