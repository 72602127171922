import { Component, OnInit } from '@angular/core';
import { constantUrl } from "../Shared/constantUrl";
import { MasterService } from '../Shared/app.Masters.Service';
import { Router } from '@angular/router';
import devtools from 'devtools-detect';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  BasePath: any = constantUrl.apiProfilePicUrl;
  currentUser: any;
  CompanyLogo: any = 'logo.png';

  constructor(private _Masterservice:MasterService,private _Route:Router) { }

  ngOnInit() {
    this._Masterservice.CheckMACAllowed({ "MacAddresss": this._Masterservice.getMacAddress() }).subscribe(response => {
      if (!(response[0]["CODE"] == 0 && response[0]["MSG"] == "Allow")) {
        this._Route.navigate([`Page403Error`]);
      }
      console.log("H Start h", response)
    })
    //if (environment.production && devtools.isOpen) {
    //  this._Route.navigate([`Page403Error`]);
    //}
    if (environment.production) {
      var allow;
      if (isNaN(+allow)) allow = 100;
      var start = +new Date();
      debugger;
      var end = +new Date();
      if (isNaN(start) || isNaN(end) || end - start > allow) {
        this._Route.navigate([`Page403Error`]);
      }

    }
  }

}
