<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchNachDetail()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span>Loan No</span>
        <input
          type="text"
          [(ngModel)]="caseNo"
          placeholder="Loan No"
          name="caseNo"
          id="caseNo"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Loan Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CaseDateFrom"
            id="CaseDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="CaseDateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="CaseDateFrom"
          [(ngModel)]="CaseDateFrom"
          id="CaseDateFrom"
          placeholder="Case From"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-2">
        <span>Loan Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CaseDateTo"
            id="CaseDateTo"
            class="form-control input-text-css"
            [(ngModel)]="CaseDateTo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="CaseDateTo"
          [(ngModel)]="CaseDateTo"
          id="CaseDateTo"
          placeholder="Case To"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-2">
        <span>Status</span>
        <select
          name="SearchStatus"
          id="SearchStatus"
          [(ngModel)]="SearchStatus"
          class="form-control input-text-css"
        >
          <option value="">Select Status</option>
          <option value="Generated">Generated</option>
          <option value="Pending For Generate">Pending For Generate</option>
        </select>
      </div>
      <div class="col-md-3">
        <span>Company Bank</span>
        <select
          name="BankInCompany"
          id="BankInCompany"
          #refPresentBankInCompany="ngModel"
          [(ngModel)]="BankInCompany"
          class="form-control input-text-css"
        >
          <option value="" selected>Select Present Bank In Company</option>
          <option *ngFor="let bank of BankList" [value]="bank.AccountId">
            {{ bank.Account_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <span>Company Branch</span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
        >
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <input
          style="margin: 20px 0px 0px 0px; height: 24px; width: 22px"
          [(ngModel)]="Regenerate"
          class="form-check-input"
          type="checkbox"
          name="exampleRadios1"
          id="Endorsed1"
          value="option2"
        />
        <label
          style="margin: 19px 0px 0px 31px"
          class="form-check-label"
          for="exampleRadios1"
        >
          Pending For Regenerate
        </label>
      </div>

      <div class="col-md-6 text-right">
        <button
          type="button"
          (click)="onSearchNachDetail()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSourceLoan">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [hiddenColumns]="[displayedColumnsLoan.indexOf('Action')]"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="chqId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 40px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 40px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CaseNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CaseDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              <div style="width: 105px">Customer Name</div></mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="text-transform: capitalize"
            >
              {{ row.CustomerName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="BranchBank">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Bank</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Bankname }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PdcType">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Pdc Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.PdcType
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerACType">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              ><div style="width: 100px">Account Type</div></mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AccType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PresentOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Present On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.PresentOnCompanyBank }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PendingDays">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Pending Days</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.PendingDays }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.status }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 40px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 40px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            [ngClass]="{
              Pending: row.status == 'Pending',
              Approved: row.status == 'Approved' || row.status == 'Generated',
              Failed: row.status == 'Failed',
              Aweted: row.status == 'Aweted'
            }"
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
              [class.spinner]="loading"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              class="mat-paginator-sticky"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="NachDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Details
        </h6>
        <button
          type="button"
          (click)="onCloseNachDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (ngSubmit)="onSaveNachDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0" *ngIf="applicationDetail">
            <div class="col-md-3">
              <h6 class="fs-12">Loan Ac No</h6>
              <p class="lead fs-12 fw-7">{{ applicationDetail.LoanAcNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Date</h6>
              <p class="lead fs-12">{{ applicationDetail.Loan_Date }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer Name</h6>
              <p class="lead fs-12">{{ applicationDetail.CustomerName }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Bank Name</h6>
              <p class="lead fs-12">{{ applicationDetail.Bankname }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Branch</h6>
              <p class="lead fs-12">{{ applicationDetail.Branch }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer A/C Type</h6>
              <p class="lead fs-12">{{ applicationDetail.AccType }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Customer A/c No.</h6>
              <p class="lead fs-12">{{ applicationDetail.Ac }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Status</h6>
              <p class="lead fs-12">{{ applicationDetail.status }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.LoanAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">EMI Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.EMIAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">No. Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.NoOfEMI }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Expiry Date Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.ExpiryEMIDate }}</p>
            </div>
          </div>
          <div
            class="row m-0 col-md-12 p-0"
            *ngIf="applicationDetail && PdcType != 'RPDC'"
          >
            <div class="col-md-3" *ngIf="StatusDB != 'Pending'">
              <h6 class="fs-12">Nach Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.NachAmount }}</p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB != 'Pending'">
              <h6 class="fs-12">Present On Company Bank</h6>
              <p class="lead fs-12">
                {{ applicationDetail.PresentOnCompanyBank }}
              </p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB != 'Pending'">
              <h6 class="fs-12">Present Bank In Company</h6>
              <p class="lead fs-12">
                {{ applicationDetail.PresentBankInCompanyName }}
              </p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB != 'Pending'">
              <h6 class="fs-12">Frequency</h6>
              <p class="lead fs-12">{{ applicationDetail.Frequency }}</p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB != 'Pending'">
              <h6 class="fs-12">Debit Type</h6>
              <p class="lead fs-12">{{ applicationDetail.DebitType }}</p>
            </div>
            <div class="col-md-3" *ngIf="applicationDetail.From != null">
              <h6 class="fs-12">From</h6>
              <p class="lead fs-12">{{ applicationDetail.From }}</p>
            </div>
            <div class="col-md-3" *ngIf="applicationDetail.To != null">
              <h6 class="fs-12">To</h6>
              <p class="lead fs-12">{{ applicationDetail.To }}</p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB == 'Failed'">
              <h6 class="fs-12">Reason</h6>
              <p class="lead fs-12">{{ applicationDetail.Reason }}</p>
            </div>
          </div>
          <!--<div class="row m-0  col-md-12 p-0" *ngIf="applicationDetail">
            <div class="col-md-3" *ngIf="PdcType == 'RPDC'">
              <h6 class="fs-12">Chqeue From</h6>
              <p class="lead fs-12">{{ applicationDetail.ChFrom }}</p>
            </div>
            <div class="col-md-3" *ngIf="PdcType == 'RPDC'">
              <h6 class="fs-12">Cheque To</h6>
              <p class="lead fs-12">{{ applicationDetail.ChTo }}</p>
            </div>
          </div>-->
          <div
            class="row m-0 col-md-12 p-0"
            *ngIf="StatusDB == 'Failed' || StatusDB == 'Approved'"
          >
            <div class="col-md-3">
              <h6 class="fs-12">Status Date</h6>
              <p class="lead fs-12">{{ applicationDetail.StatusDate }}</p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB == 'Approved'">
              <h6 class="fs-12">Nach Code</h6>
              <p class="lead fs-12">{{ applicationDetail.NachCode }}</p>
            </div>
            <!--<div class="col-md-3" *ngIf="StatusDB=='Approved'">
              <h6 class="fs-12">EMI From</h6>
              <p class="lead fs-12">{{ applicationDetail.EMIFrom }}</p>
            </div>
            <div class="col-md-3" *ngIf="StatusDB=='Approved'">
              <h6 class="fs-12">EMI To</h6>
              <p class="lead fs-12">{{ applicationDetail.EMITo }}</p>
            </div>-->
          </div>

          <hr class="col-md-12 p-0" />
          <div class="row m-0 col-md-12 p-0">
            <div class="row m-0 col-md-12 p-0" [hidden]="!IsFillUpDetail">
              <div class="col-md-4">
                <span>NACH Amount</span>
                <input
                  type="number"
                  [(ngModel)]="NachAmount"
                  placeholder="NACH Amount"
                  required
                  name="NachAmount"
                  id="NachAmount"
                  #refNachAmount="ngModel"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refNachAmount.invalid,
                    'alert-warning':
                      refNachAmount.invalid &&
                      (refNachAmount.dirty ||
                        refNachAmount.touched ||
                        refNachAmount.untouched)
                  }"
                />
              </div>
              <div class="col-md-4">
                <span>Present On Company Bank </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker3"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="PresentOnCompanyBank"
                    [(ngModel)]="PresentOnCompanyBank"
                    required
                    id="PresentOnCompanyBank"
                    class="form-control input-text-css"
                    #refPresentOnCompanyBank="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refPresentOnCompanyBank.invalid,
                      'alert-warning':
                        refPresentOnCompanyBank.invalid &&
                        (refPresentOnCompanyBank.dirty ||
                          refPresentOnCompanyBank.touched ||
                          refPresentOnCompanyBank.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
                <!-- <input
                  name="PresentOnCompanyBank"
                  [(ngModel)]="PresentOnCompanyBank"
                  required
                  id="PresentOnCompanyBank"
                  placeholder="DD/MM/YYYY"
                  #refPresentOnCompanyBank="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refPresentOnCompanyBank.invalid,
                    'alert-warning':
                      refPresentOnCompanyBank.invalid &&
                      (refPresentOnCompanyBank.dirty ||
                        refPresentOnCompanyBank.touched ||
                        refPresentOnCompanyBank.untouched)
                  }"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-4">
                <span>Present Bank In Company</span>
                <select
                  name="PresentBankInCompany"
                  id="PresentBankInCompany"
                  #refPresentBankInCompany="ngModel"
                  [(ngModel)]="PresentBankInCompany"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refPresentBankInCompany.invalid,
                    'alert-warning':
                      refPresentBankInCompany.invalid &&
                      (refPresentBankInCompany.dirty ||
                        refPresentBankInCompany.touched ||
                        refPresentBankInCompany.untouched)
                  }"
                >
                  <option value="" selected>
                    Select Present Bank In Company
                  </option>
                  <option
                    *ngFor="let bank of BankList"
                    [value]="bank.AccountId"
                  >
                    {{ bank.Account_Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0" [hidden]="!IsFillUpDetail">
              <div class="col-md-6">
                <span>Frequency:</span>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="Mthly"
                  (change)="checkFrequency($event)"
                  [checked]="Mthly"
                  class="form-check-input"
                  type="checkbox"
                  name="Mthly"
                  id="Mthly"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="Mthly"
                >
                  Mthly
                </label>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="Qtly"
                  (change)="checkFrequency($event)"
                  [checked]="Qtly"
                  class="form-check-input"
                  type="checkbox"
                  name="Qtly"
                  id="Qtly"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="Qtly"
                >
                  Qtly
                </label>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="HYrly"
                  (change)="checkFrequency($event)"
                  [checked]="HYrly"
                  class="form-check-input"
                  type="checkbox"
                  name="HYrly"
                  id="HYrly"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="HYrly"
                >
                  H-Yrly
                </label>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="presented"
                  (change)="checkFrequency($event)"
                  [checked]="presented"
                  class="form-check-input"
                  type="checkbox"
                  name="presented"
                  id="presented"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="presented"
                >
                  As & when presented
                </label>
              </div>
              <div class="col-md-6">
                <span>Debit Type:</span>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="Fixed"
                  (change)="checkDebitType($event)"
                  [checked]="Fixed"
                  class="form-check-input"
                  type="checkbox"
                  name="Fixed"
                  id="Fixed"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="Fixed"
                >
                  Fixed Amount
                </label>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="Maximum"
                  (change)="checkDebitType($event)"
                  [checked]="Maximum"
                  class="form-check-input"
                  type="checkbox"
                  name="Maximum"
                  id="Maximum"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="Maximum"
                >
                  Maximum Amount
                </label>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0" [hidden]="!IsFillUpDetail">
              <div class="col-md-6">
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="Period"
                  (change)="checkPeriod($event)"
                  [checked]="Period"
                  class="form-check-input"
                  type="checkbox"
                  name="Period"
                  id="Period"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="Period"
                >
                  Period
                </label>
                <input
                  style="margin: 17px 0px 0px 10px; height: 24px; width: 18px"
                  [ngModel]="UntilCancelled"
                  (change)="checkPeriod($event)"
                  [checked]="UntilCancelled"
                  class="form-check-input"
                  type="checkbox"
                  name="UntilCancelled"
                  id="UntilCancelled"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="UntilCancelled"
                >
                  Until Cancelled
                </label>
              </div>
              <div class="col-md-3" [hidden]="!Period">
                <span>From</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker4"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="From"
                    [(ngModel)]="From"
                    required
                    id="From"
                    class="form-control input-text-css"
                    #refFrom="ngModel"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
                <!-- <input
                  name="From"
                  [(ngModel)]="From"
                  required
                  id="From"
                  placeholder="DD/MM/YYYY"
                  #refFrom="ngModel"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3" [hidden]="!Period">
                <span>To</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker5"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="To"
                    [(ngModel)]="To"
                    required
                    id="To"
                    [min]="From"
                    class="form-control input-text-css"
                    #refTo="ngModel"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker5"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker5></mat-datepicker>
                </div>
                <!-- <input
                  name="To"
                  [(ngModel)]="To"
                  required
                  id="To"
                  [minDate]="From"
                  placeholder="DD/MM/YYYY"
                  refTo="ngModel"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0" [hidden]="!IsFillDetail">
              <div
                class="col-md-4"
                *ngIf="
                  status == 'Failed' ||
                  status == 'Approved' ||
                  status == 'Aweted'
                "
              >
                <span>Status</span>
                <select
                  name="status"
                  id="status"
                  #refSelectGender="ngModel"
                  [(ngModel)]="status"
                  class="form-control input-text-css"
                  (change)="statusChange($event)"
                >
                  <option value="">Select Status</option>
                  <!--<option value="Pending">Pending</option>
                  <option value="Aweted">Aweted</option>-->
                  <option value="Failed">Failed</option>
                  <option value="Approved">Approved</option>
                </select>
              </div>
              <div class="col-md-4" *ngIf="status == 'Failed'">
                <span>Reason</span>
                <input
                  name="Reason"
                  [(ngModel)]="Reason"
                  id="Reason"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-4"
                *ngIf="status == 'Failed' || status == 'Approved'"
              >
                <span>Status Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker6"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="StatusDate"
                    [(ngModel)]="StatusDate"
                    (dateChange)="changeDate($event)"
                    id="StatusDate"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker6"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker6></mat-datepicker>
                </div>
                <!-- <input
                  name="StatusDate"
                  [(ngModel)]="StatusDate"
                  (ngModelChange)="changeDate($event)"
                  id="StatusDate"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-4" *ngIf="status == 'Approved'">
                <span>UMRN</span>
                <input
                  type="text"
                  [(ngModel)]="NachCode"
                  placeholder="NachCode"
                  name="NachCode"
                  id="NachCode"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  status == 'Approved' ||
                  status == 'Pending For Generate' ||
                  status == 'Generated'
                "
              >
                <span>EMI From</span>
                <input
                  name="EMIFrom"
                  [(ngModel)]="EMIFrom"
                  (change)="EMIFromChange($event)"
                  id="EMIFrom"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  status == 'Approved' ||
                  status == 'Pending For Generate' ||
                  status == 'Generated'
                "
              >
                <span>EMI To</span>
                <input
                  name="EMITo"
                  [(ngModel)]="EMITo"
                  disabled
                  id="EMITo"
                  class="form-control input-text-css"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
          <button
            type="button"
            (click)="onCloseNachDetail()"
            class="mt-3 btn font-size-12 button-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveRepresentDetail()"
            [class.spinner]="loading"
            [hidden]="!isShowRepresent"
            class="mt-3 btn font-size-12 button-btn"
          >
            Re-Present
          </button>
          <button
            type="button"
            (click)="onSaveNachDetail()"
            [class.spinner]="loading"
            [hidden]="!isShowSave"
            class="mt-3 btn font-size-12 button-btn"
          >
            Save
          </button>
          <!--<button type="button" (click)="onEditNachDetail()" [class.spinner]="loading" [hidden]="!isShowEdit"
            style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary">
            Edit
          </button>-->
          <button
            type="button"
            (click)="onSaveNachDetail()"
            [class.spinner]="loading"
            [hidden]="!isShowUpdate"
            class="mt-3 btn font-size-12 button-btn"
          >
            Update
          </button>
          <button
            type="button"
            (click)="onGenerateRPDC()"
            [class.spinner]="loading"
            [hidden]="!isShowGenerate"
            class="mt-3 btn font-size-12 button-btn"
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_pdc_registration"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pdc Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer A/c Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Present On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pending Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.CustomerName }}</td>

    <td>{{ x.Bankname }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.PdcType }}</td>
    <td>{{ x.AccType }}</td>
    <td>{{ x.PresentOnCompanyBank }}</td>
    <td>{{ x.PendingDays }}</td>
    <td>{{ x.status }}</td>
  </tr>
</table>
