
<div class="row m-0 mt-1 mb-1" style="text-align:center">
  <!-- end col -->
  <div class="col-md-3" *ngIf="ConfigurationAllow">

    <div *ngIf="ConfigurationAllow" class="p-0 px-3" style="cursor:pointer" (click)="OnConfigClick()">
      <div class="card-box widget-box-two widget-two-custom first-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center ">
              <!-- <i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i> -->
              <img src="../../assets/images/Configuration_img.png" class="rounded-circle" alt="Configuration" style="width: 90%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body  col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Configuration">
              Configuration
            </p>
            <p class="font-weight-medium my-2"> <span data-plugin="counterup">Configuration Management</span></p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-md-3" *ngIf="QUICKLOANAllow">
    <div *ngIf="QUICKLOANAllow" class="p-0 pt-1 px-3" style="cursor:pointer;" (click)="OnQuickLoanClick()">


      <div class="card-box widget-box-two widget-two-custom seventh-box-bg">


        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!--<div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center">-->
              <!--<i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i>-->
              <img src="../../assets/images/LOS_IMG.png" class="rounded-circle" alt="Quick Loan" style="width: 100%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Quick Loan">Quick Loan</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Manage Quick Loan</span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left"> Manage Portfolio </h5>
        <p class="text-justify">Various type of Loan related Printable/Exportable reports can be seen here. MIS and Analytical reports required by Management can be extracted from this panel. </p>
      </div>-->
    </div>
  </div>
  <!-- end col -->
  
  <div class="col-md-3" *ngIf="LMSAllow">
    <div *ngIf="LMSAllow" class="p-0 pt-1 px-3" style="cursor:pointer" (click)="OnLMSClick()">
      <div class="card-box widget-box-two widget-two-custom third-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!-- <div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center"> -->
              <!-- <i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i> -->
              <img src="../../assets/images/LMS_IMG.png" class="rounded-circle" alt="LMS" style="width: 90%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="LMS">LMS</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Loan Management System</span>
              <!--<span data-plugin="counterup">Manage your Loans and Collections</span>-->
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left"> LMS </h5>
        <p style="text-align:justify">
          All loan applications booked in LOS will be shown here to convert the application into Loan. Direct entry of Loan application also can be done. LMS contains the process of disbursement of Loan amount , EMI billing and receiving, NACH mandate registration, NACH/PDC Process, Pre-closure of loan, Loan Re-scheduling, Loan related accounting vouchers, PDDs, Customer track, NOC management,  Customer’s ledger etc..
          This panel will also have the printable/exportable reports for Sales, Operations, Collections, Closure.
        </p>
      </div>-->
    </div>

  </div>
  <div class="col-md-3" *ngIf="ACCOUNTINGAllow">
    <div *ngIf="ACCOUNTINGAllow" class="p-0 pt-1 px-3" style="cursor:pointer" (click)="OnAccountingClick()">
      <div class="card-box widget-box-two widget-two-custom fifth-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!-- <div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center"> -->
              <!-- <i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i> -->
              <img src="../../assets/images/Accounting.jpg" class="rounded-circle" alt="Accounting" style="width: 90%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Accounting">Accounting</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Manage your Accounting</span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left"> Accounting </h5>
        <p style="text-align:justify">There is integrated accounting system has been develop to view/generate the Financial Accounting figures. Automatic vouchers will be posted for disbursement, EMI billing, NACH posting and bouncing. Creation of Accounting group and accounting head will be managed from here only. Voucher entry and day to day financial accounting related reports like Cash book, Bank Book, account ledger, Bank reconciliation, Journal Book, Trial Balance, Balance sheet, P&L account, Voucher audit and voucher audit trail etc. can be seen here. </p>
      </div>-->
    </div>
  </div>
  <div class="col-md-3" *ngIf="ConfigurationAllow && QUICKLOANAllow && LMSAllow && ACCOUNTINGAllow">

  </div>
  
  <div class="col-md-3" *ngIf="REPORTAllow">
    <div *ngIf="REPORTAllow" class="p-0 pt-1 px-3" style="cursor:pointer" (click)="OnReportsClick()">
      <div class="card-box widget-box-two widget-two-custom fourth-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!-- <div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center"> -->
              <!-- <i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i> -->
              <img src="../../assets/images/Report.jpg" class="rounded-circle" alt="Reports" style="width: 100%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Reports">Reports</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Manage your Reports</span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left"> Reports </h5>
        <p style="text-align:justify">Various type of Loan related Printable/Exportable reports can be seen here. MIS and Analytical reports required by Management can be extracted from this panel. </p>
      </div>-->
    </div>
  </div>
  <div class="col-md-3" *ngIf="PORTFOLIOAllow">

    <div *ngIf="PORTFOLIOAllow" class="p-0 pt-1 px-3" style="cursor:pointer" (click)="OnpPartnerClick()">
      <div class="card-box widget-box-two widget-two-custom sixth-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!--<div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center">-->
              <!--<i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i>-->
              <img src="../../assets/images/portfolio.png" class="rounded-circle" alt="Portfolio" style="width: 100%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Portfolio">Managed Portfolio</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Manage your Portfolio</span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left"> Manage Portfolio </h5>
        <p class="text-justify">Various type of Loan related Printable/Exportable reports can be seen here. MIS and Analytical reports required by Management can be extracted from this panel. </p>
      </div>-->
    </div>
  </div>
  <div class="col-md-3" *ngIf="DEPOSITAllow">
    <div *ngIf="DEPOSITAllow" class="p-0 pt-2 px-3" style="cursor: pointer;" (click)="OnDepositClick()">
      <div class="card-box widget-box-two widget-two-custom eighth-box-bg">
        <div class="media  row m-0">
          <div class=" col-md-12">
            <div class="avatar-lg widget-two-icon align-self-center">
              <!--<div class="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center">-->
              <!--<i class="fa fa-users avatar-title font-30 text-white" style="font-size:33px"></i>-->
              <img src="../../assets/images/Deposit.png" class="rounded-circle" alt="Deposit" style="width: 90%;">
            </div>
          </div>
          <div class="wigdet-two-content media-body col-md-12">
            <p class="m-0 text-uppercase font-weight-bolder font-size-15 text-truncate" title="Deposits">Deposits</p>
            <p class="font-weight-medium my-2">
              <span data-plugin="counterup">Manage Deposits</span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-12 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
        <h5 style="text-align:left">Deposit </h5>
        <p class="text-justify">Various type of Loan related Printable/Exportable reports can be seen here. MIS and Analytical reports required by Management can be extracted from this panel. </p>
      </div>-->
    </div>
  </div>
</div>




  <!--<div class="col-md-6" *ngIf="chartOptions && chartOptions1">
    <marquee width="100%" height="auto" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="4" direction="up" loop="true">
      <div id="chart" style="margin:0px!important">
        <apx-chart [series]="chartOptions.series"
                   [chart]="chartOptions.chart"
                   [labels]="chartOptions.labels"
                   [title]="chartOptions.title"
                   [theme]="chartOptions.theme"
                   [responsive]="chartOptions.responsive"></apx-chart>

      </div>

      <div id="chart1" style="margin:0px!important">
        <apx-chart [series]="chartOptions1.series"
                   [chart]="chartOptions1.chart"
                   [dataLabels]="chartOptions1.dataLabels"
                   [plotOptions]="chartOptions1.plotOptions"
                   [xaxis]="chartOptions1.xaxis"></apx-chart>
      </div>


    </marquee>
  </div>-->
  <!--<div class="row m-0">
    <div *ngIf="ConfigurationAllow" class="col-md-3 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
    <h5> Configuration </h5>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
  </div>-->
  <!--<div *ngIf="LOSAllow" class="col-md-3 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> LOS </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
    <div *ngIf="LMSAllow" class="col-md-3 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> LMS </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
    <div *ngIf="ACCOUNTINGAllow" class="col-md-3 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> Accounting </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
    <div *ngIf="REPORTAllow" class="col-md-3 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> Management Report </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
  </div>-->
  <!--<div class="row m-0" style="text-align:center;justify-content:center;">

  </div>-->
  <!--<div class="row m-0" style="justify-content:center;">
    <div *ngIf="ACCOUNTINGAllow" class="col-md-4 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> Accounting </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
    <div *ngIf="REPORTAllow" class="col-md-4 p-0 pt-2 px-3" style="border: 0px solid;border-radius: 10px;">
      <h5> Management Report </h5>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
    </div>
  </div>-->
