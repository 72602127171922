import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { DialogService } from "../../Shared/dialog.service";

declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { QuickLoanService } from "../services/quickLoan.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-quickloan-crc-fill",
  templateUrl: "./quickloan-crc-fill.component.html",
  styleUrls: ["./quickloan-crc-fill.component.scss"],
})
export class QuickloanCrcFillComponent implements OnInit {
  currentUser: any;
  isShowSave: boolean = true;
  isShowEdit: boolean = false;
  showSpinner: boolean = false;
  DetailCRCShow: boolean = false;
  isShowUpdate: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 10000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  // openConfirmDialog() {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: "390px",
  //     disableClose: true,
  //     data: { message: "Are you sure?" },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     // Handle the OK button action here if needed
  //     if (result) {
  //       console.log("OK clicked");
  //     }
  //   });
  // }

  crcModel: any = {};
  CRC_Type: any;
  CRCList: any = [];
  datasourceCRC: any;
  displayedCRCColumns: any = [
    "CRC_AgencyName",
    "CRC_LoanTypeName",
    "CRC_CreditVisionScore",
    "CRC_PersonalLoanScore",
    "CRC_Remarks",
    "Origin",
    "Action",
  ];
  SelectCRCType: any;
  ApplicationDetail: any;
  LoanId: any;
  DocModel: any = {};
  DocModel1: any = {};
  loading: boolean = false;
  FormCRCShow: boolean = true;
  CurrentDate: any = new Date();
  dataUrl: any;
  @Input() Status: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  Response: any;
  cibilResponse: any;
  error: any = true;
  kycList: any[] = [];
  CibilApiShow: boolean = false;
  AddButtonShow: boolean = true;
  CRC_Agency: any;
  today: Date = new Date();
  inViewMode: boolean = false;
  CibilApidecisioncentreShow: boolean = false;

  crifResponse: any;
  criferror: any;

  Type: any;
  @Input("Data") Data: any;
  @Input("isEdit") isEdit: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  //Type: any;
  @Input() loanSummary: any;
  @Input() CustomerId: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() Processid: any;
  @Input() PageData: any;
  @Input() ProcessName: any;
  @Input() Revert_IsEdit: any;
  CRC_LoanTypeDropDown: any[] = [];
  CRC_Agency_Bind: any;
  CRIFApiShow: boolean = false;

  constructor(
    private LmsService: LmsService,
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private _MasterService: MasterService,
    private _QuickLoanService: QuickLoanService,

    private dialog: DialogService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {});
  }
  ngOnInit(): void {
    this.CustomerId = this.CustomerId;
    this.LoanId = this.loanSummary.LoanId;
    this.getAgency();
    //this.dataSharingService.LOSHeaderTitle.next('CRC Fill');
    this.dataSharingService.LOSHeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLmsCRCCustomerDetail();
  }

  getOrigin(row: any): string {
    if (
      (row.CRC_Agency == 1 && row.isView == 1) ||
      (row.CRC_Agency == 4 && row.isViewCrifApi == 1)
    ) {
      return "Automatic";
    } else {
      return "Manual";
    }
  }

  GetLmsCRCCustomerDetail() {
    // this.Type = Type;
    this.showSpinner = true;

    this.LmsService.GetLmsCRCCustomerDetail({
      Loan_Id: this.loanSummary.LoanId,
      CId: this.CustomerId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      this.ApplicationDetail = res.Item1[0];

      //console.log("Res : ", res);
      this.kycList = res.Item2;
      var inViewMode =
        this.ApplicationDetail.CRC_Status == "Completed" ||
        this.ApplicationDetail.CRC_Status == "Rejected";
      this.crcModel = res.Item3[0] || {
        CRC_Agency: this.ApplicationDetail.DefaultCRCAgency || "",
        CRC_Recommendation: "",
      };
      this.datasourceCRC = new MatTableDataSource(res.Item3);
      this.CRCList = res.Item3;

      if (
        this.ApplicationDetail.TransUnionCibilAPI &&
        this.ApplicationDetail.IsFirm != 1
      ) {
        var _find = this.CRCList.find((obj: any) => {
          return obj.CRC_Agency == 1;
        });

        if (!_find) {
          var _find1 = this.CRCList.find((obj: any) => {
            return obj.CRC_Agency == this.ApplicationDetail.DefaultCRCAgency;
          });

          if (!_find1) {
            if (
              this.ApplicationDetail.DefaultCRCAgency == 1 ||
              this.ApplicationDetail.DefaultCRCAgency == "1"
            ) {
              this.CibilApiShow = true;

              this.AddButtonShow = false;
            } else {
              this.CibilApiShow = false;

              this.AddButtonShow = true;
            }
          } else {
            this.CibilApiShow = true;

            this.AddButtonShow = true;
          }
        } else {
          this.CibilApiShow = false;

          this.AddButtonShow = true;
        }
      }

      if (
        this.ApplicationDetail.Transuniondecisioncentre &&
        this.ApplicationDetail.IsFirm != 1
      ) {
        var _find = this.CRCList.find((obj: any) => {
          return obj.CRC_Agency == 1;
        });
        if (!_find) {
          var _find1 = this.CRCList.find((obj: any) => {
            return obj.CRC_Agency == this.ApplicationDetail.DefaultCRCAgency;
          });

          if (!_find1) {
            if (
              this.ApplicationDetail.DefaultCRCAgency == 1 ||
              this.ApplicationDetail.DefaultCRCAgency == "1"
            ) {
              this.CibilApidecisioncentreShow = true;
              this.AddButtonShow = false;
            } else {
              this.CibilApidecisioncentreShow = false;
              this.AddButtonShow = true;
            }
          } else {
            this.CibilApidecisioncentreShow = true;
            this.AddButtonShow = true;
          }
        } else {
          this.CibilApidecisioncentreShow = false;
          this.AddButtonShow = true;
        }
      }

      //Crif
      if (
        this.ApplicationDetail.CRIFAPI &&
        this.ApplicationDetail.IsFirm != 1
      ) {
        var _find = this.CRCList.find((obj: any) => {
          return obj.CRC_Agency == 4;
        });
        if (!_find) {
          var _find1 = this.CRCList.find((obj: any) => {
            return obj.CRC_Agency == this.ApplicationDetail.DefaultCRCAgency;
          });

          if (!_find1) {
            if (
              this.ApplicationDetail.DefaultCRCAgency == 4 ||
              this.ApplicationDetail.DefaultCRCAgency == "4"
            ) {
              this.CRIFApiShow = true;
              this.AddButtonShow = false;
            } else {
              this.CRIFApiShow = false;
              this.AddButtonShow = true;
            }
          } else {
            this.CRIFApiShow = true;
            this.AddButtonShow = true;
          }
        } else {
          this.CRIFApiShow = false;
          this.AddButtonShow = true;
        }
      }

      //console.log("this.crcModel", this.crcModel);
      if (res.Item3[0] != undefined) {
        if (
          this.crcModel.CRC_OldestAccountOpenDate != null &&
          this.crcModel.CRC_OldestAccountOpenDate != ""
        )
          this.crcModel.CRC_OldestAccountOpenDate = new Date(
            this.crcModel.CRC_OldestAccountOpenDate
          );
        if (
          this.crcModel.CRC_RecentAccountOpenDate != null &&
          this.crcModel.CRC_RecentAccountOpenDate != ""
        )
          this.crcModel.CRC_RecentAccountOpenDate = new Date(
            this.crcModel.CRC_RecentAccountOpenDate
          );
        if (
          this.crcModel.CRC_LastEnquiryDate != null &&
          this.crcModel.CRC_LastEnquiryDate != ""
        )
          this.crcModel.CRC_LastEnquiryDate = new Date(
            this.crcModel.CRC_LastEnquiryDate
          );
      }
      if (this.crcModel.CRC_Agency) {
        this.GET_CRC_LoanType(false);
      }
      this.getCRCType(this.crcModel.CRC_Agency);

      this.crcModel.CRC_CreateBy = this.currentUser.userId;
      //console.log("inViewMode ", inViewMode);
      if (inViewMode == true) {
        this.DetailCRCShow = true;
        this.FormCRCShow = false;
        this.isShowEdit = true;
        this.isShowSave = false;
        this.isShowUpdate = false;
      } else {
        this.DetailCRCShow = false;
        this.FormCRCShow = true;
        this.getCRCType(this.crcModel.CRC_Agency);
        this.isShowEdit = false;
        this.isShowSave = true;
        this.isShowUpdate = false;
      }
      this._MasterService
        .Get_CibilAPI_Request_Response({
          Loan_Id: this.LoanId,
          CId: this.CustomerId,
        })
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.CibilApiShow = false;
            this.AddButtonShow = true;
            this.CibilApidecisioncentreShow = false;
          }
        });
    });
  }
  getCRCType(event: any) {
    this.LmsService.GET_CRC_MASTER_BY_CRCAGENCY({
      Crc_Agency: this.crcModel.CRC_Agency,
    }).subscribe((res: any) => {
      this.CRC_Type = res;
    });
  }
  getAgency() {
    this._MasterService
      .Commaon_Master({ Commands: "Select", Type: "CRC AGENCY" })
      .subscribe((res: any) => {
        this.CRC_Agency_Bind = res;
      });
  }
  fileChangeListener(files: any) {
    // debugger;
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB && Ext != "pdf") {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName = "";
      this.DocModel.DocName = "";
      this.DocModel.DocData = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName = "";
      this.DocModel.DocName = "";
      this.DocModel.DocData = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.crcModel.CRC_ReportName = _pre + "_" + files[0].name;
    this.DocModel.DocName = _pre + "_" + files[0].name;
    this.DocModel.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModel.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(input: any) {
    input.value = "";
    this.crcModel.CRC_ReportName = "";
    this.DocModel.DocName = "";
    this.DocModel.DocData = "";
  }
  fileChangeListener1(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB && Ext != "pdf") {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName1 = "";
      this.DocModel1.DocName = "";
      this.DocModel1.DocData = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName1 = "";
      this.DocModel1.DocName = "";
      this.DocModel1.DocData = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    if (
      this.crcModel.CRC_ReportName &&
      this.crcModel.CRC_ReportName.split("_")[1] == files[0].name
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same File can not be upload.",
        ...this.configSuccess,
      });
      return;
    }
    let _pre = this.today.getTime();
    this.crcModel.CRC_ReportName1 = _pre + "_" + files[0].name;
    this.DocModel1.DocName = _pre + "_" + files[0].name;
    this.DocModel1.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModel1.DocData = reader.result
        .toString()
        .split(";base64,")
        .pop();
      return;
    };
  }
  removeFile1(input: any) {
    input.value = "";
    this.crcModel.CRC_ReportName1 = "";
    this.DocModel1.DocName = "";
    this.DocModel1.DocData = "";
  }
  onSaveCrc() {
    if (!this.DocModel.DocData) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload CRC report.",
        ...this.configSuccess,
      });
    } else {
      this.UploadCrcDoc();
    }
  }

  UpdateCrc() {
    if (this.DocModel.DocData) {
      this.losService.UploadCrcDoc(this.DocModel).subscribe((res: any) => {
        if (this.DocModel1.DocData) {
          this.UpdateCrcDoc1();
        } else {
          this.UpdateCrcReport();
        }
      });
    } else if (!this.DocModel.DocData && this.DocModel1.DocData) {
      this.UpdateCrcDoc1();
    } else {
      //this.UploadCrcDoc();
      if (!this.crcModel.CRC_ReportName) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please upload CRC report.",
          ...this.configSuccess,
        });
      } else {
        this.UpdateCrcReport();
      }
    }
  }

  UpdateCrc1() {
    //console.log("update this.crcModel", this.crcModel);
    if (!this.DocModel.DocData) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload CRC report.",
        ...this.configSuccess,
      });
    } else {
      //this.UploadCrcDoc();
      this.showSpinner = true;

      this.losService.UploadCrcDoc(this.DocModel).subscribe(
        (res: any) => {
          if (this.DocModel1.DocData) {
            this.UpdateCrcDoc1();
          } else {
            // this.saveCrcReport();
            this.losService.UploadCrcDoc(this.DocModel1).subscribe(
              (res: any) => {
                this.UpdateCrcReport();
              },
              (err: any) => {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
          }
        },
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }
  UpdateCrcReport() {
    this.loading = true;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.LoanId = this.loanSummary.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    // console.log("this.Update", this.crcModel);
    this.LmsService.LmsUpdateCrc({
      JSON: JSON.stringify({
        CRC: this.dataSharingService.cleanObject(this.crcModel),
      }),
    }).subscribe((res: any) => {
      this.loading = false;
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        //this.QuickLoan_Save_PageProcess('CRC');
        this.OnClose(true);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  saveCrcReport() {
    this.loading = true;
    //console.log("this.crcModel", this.crcModel);
    this.crcModel.LoanId = this.loanSummary.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.CRC_CreateBy = this.currentUser.userId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    this.LmsService.LmsSaveCrc({
      JSON: JSON.stringify({
        CRC: this.dataSharingService.cleanObject(this.crcModel),
      }),
    }).subscribe((res: any) => {
      this.loading = false;
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        //this.QuickLoan_Save_PageProcess('CRC');
        this.OnClose(true);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  UploadCrcDoc() {
    this.showSpinner = true;
    this.losService.UploadCrcDoc(this.DocModel).subscribe(
      (res: any) => {
        if (this.DocModel1.DocData) {
          this.UploadCrcDoc1();
        } else {
          this.saveCrcReport();
        }
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }
  UploadCrcDoc1() {
    this.losService.UploadCrcDoc(this.DocModel1).subscribe(
      (res: any) => {
        this.saveCrcReport();
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }
  UpdateCrcDoc1() {
    this.losService.UploadCrcDoc(this.DocModel1).subscribe(
      (res: any) => {
        this.UpdateCrcReport();
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }

  OnClose(isRefresh: boolean = false) {
    // setTimeout(() => {
    //   this.dataSharingService.LmsHeaderLoanNo.next(this.LoanNo);
    // }, 2);

    //this.router.navigate([
    //  `/lms/lms-crc-list`,
    //]);
    // let element: HTMLElement = document.getElementById("CrcInfo") as HTMLElement;
    // element.click();
    this.close.emit(isRefresh);
    //this.action.emit(isRefresh);
  }
  EditCRC() {
    this.isShowEdit = false;
    this.isShowSave = false;
    this.inViewMode = false;
    this.DetailCRCShow = false;
    this.FormCRCShow = true;
    this.isShowUpdate = true;
  }

  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanSummary.ProductId,
        Loan_Id: this.loanSummary.LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }
  getCRCDetail(data: any, isEdit: boolean) {
    this.crcModel = data;
    this.GET_CRC_LoanType(false);
    this.isShowEdit = true;
    this.isShowSave = false;
    this.isShowUpdate = false;
    this.inViewMode = true;
    if (
      this.crcModel.CRC_OldestAccountOpenDate != null &&
      this.crcModel.CRC_OldestAccountOpenDate != "" &&
      this.crcModel.CRC_OldestAccountOpenDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_OldestAccountOpenDate = new Date(
        this.crcModel.CRC_OldestAccountOpenDate
      );
    else {
      this.crcModel.CRC_OldestAccountOpenDate = null;
    }
    if (
      this.crcModel.CRC_RecentAccountOpenDate != null &&
      this.crcModel.CRC_RecentAccountOpenDate != "" &&
      this.crcModel.CRC_RecentAccountOpenDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_RecentAccountOpenDate = new Date(
        this.crcModel.CRC_RecentAccountOpenDate
      );
    else this.crcModel.CRC_RecentAccountOpenDate = null;
    if (
      this.crcModel.CRC_LastEnquiryDate != null &&
      this.crcModel.CRC_LastEnquiryDate != "" &&
      this.crcModel.CRC_LastEnquiryDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_LastEnquiryDate = new Date(
        this.crcModel.CRC_LastEnquiryDate
      );
    else this.crcModel.CRC_LastEnquiryDate = null;

    this.crcModel.CRC_ApplicationId = this.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    this.crcModel.CRC_CreateBy = this.currentUser.userId;
    //  this.crcModel.CRC_OldestAccountOpenDate = new Date(this.crcModel.CRC_OldestAccountOpenDate);
    //   this.crcModel.CRC_RecentAccountOpenDate = new Date(this.crcModel.CRC_RecentAccountOpenDate);
    //   this.crcModel.CRC_LastEnquiryDate = new Date(this.crcModel.CRC_LastEnquiryDate);

    $("#AdditionalCRC").modal("show");
    $("#AdditionalCRC").css("z-index", "1050");
  }
  RemoveElementFromArray(element: number) {
    this.CRC_Agency_Bind.forEach((value, index) => {
      if (value.Id == element) this.CRC_Agency_Bind.splice(index, 1);
    });
  }
  GET_CRC_LoanType(isChange: boolean = true) {
    if (isChange) {
      this.crcModel.CRC_LoanType = "";
    }
    this.losService
      .GET_CRC_MASTER_BY_CRCAGENCY({
        Crc_Agency: this.crcModel.CRC_Agency,
        Is_Firm: this.ApplicationDetail.IsFirm,
      })
      .subscribe((res: any) => {
        this.CRC_LoanTypeDropDown = res;
      });
  }
  AddCRC() {
    if (this.CRCList.length == 0) {
      this.crcModel.CRC_Agency = this.ApplicationDetail.deDefaultCRCAgency;
      this.GET_CRC_LoanType();
      this.crcModel = {
        CRC_Agency: this.ApplicationDetail.DefaultCRCAgency || "",
        CRC_LoanType: "",
        CRC_Recommendation: "",
      };
    } else {
      this.crcModel = {
        CRC_Agency: "",
        CRC_LoanType: "",
        CRC_Recommendation: "",
      };
    }

    // this.CRC_Agency_Bind.forEach((obj, index) => {

    if (this.CibilApiShow || this.CibilApidecisioncentreShow) {
      this.RemoveElementFromArray(1);
    }

    if (this.CRIFApiShow) {
      this.RemoveElementFromArray(4);
    }

    this.CRCList.forEach((obj1) => {
      this.RemoveElementFromArray(Number(obj1.CRC_Agency));
      //if (Number(obj1.CRC_Agency) === Number(obj.Id)) {

      //  this.CRC_Agency_Bind.splice(index, 1);
      //}
    });

    // });

    //this.CRC_Agency_Bind = this.CRC_Agency_Bind.filter(x => x.Id == "Cheque & Repayment")
    this.isShowEdit = false;
    this.isShowSave = true;
    this.isShowUpdate = false;
    this.inViewMode = false;

    console.log(" this.crcModel ", this.crcModel);
    $("#AdditionalCRC").modal("show");
    $("#AdditionalCRC").css("z-index", "1050");
  }
  Delete_CRC(data: any) {
    this.loading = true;
    this.crcModel = data;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.LoanId = this.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    // console.log("this.Update", this.crcModel);
    this.crcModel.Mode = "Delete";

    this.UpdateCrc();

    this.OnAdditionalCRCClose();
  }

  OnAdditionalCRCClose() {
    this.getAgency();
    $("#AdditionalCRC").modal("hide");
  }
  CheckCibil() {
    this.CibilApiShow = false;
    // console.log(

    //   "(this.ApplicationDetail.LoanAmount",

    //   this.ApplicationDetail.LoanAmount

    // );

    if (
      this.ApplicationDetail.LoanAmount == undefined ||
      this.ApplicationDetail.LoanAmount == null ||
      this.ApplicationDetail.LoanAmount == 0
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please fill Account Information!!",
        ...this.configSuccess,
      });
    } else {
      let LoanAmount: string =
        "0".repeat(9 - this.ApplicationDetail.LoanAmount.toString().length) +
        this.ApplicationDetail.LoanAmount.toString();

      var ids = [];

      this.kycList.forEach((value, index) => {
        if (value.Doc_Type_Cibil != "" && value.Doc_Type_Cibil != null) {
          ids.push({
            index: "I0" + (index + 1).toString(),

            idNumber: value.KYC_DocNumber,

            idType: value.Doc_Type_Cibil,
          });
        }
      });
      var replacementArray = [
        "!",
        "#",
        "$",
        "%",
        "&",
        "*",
        ",",
        "/",
        "?",
        "@",
        "/",
        " ^ ",
        " | ",
        "~",
        " + ",
        "=",
      ];
      for (let i = 0; i < replacementArray.length; i++) {
        this.ApplicationDetail.Customer_FirstName =
          this.ApplicationDetail.Customer_FirstName.replace(
            replacementArray[i],
            ""
          );
        this.ApplicationDetail.Customer_LastName =
          this.ApplicationDetail.Customer_LastName.replace(
            replacementArray[i],
            ""
          );
        this.ApplicationDetail.Line1 = this.ApplicationDetail.Line1.replace(
          replacementArray[i],
          ""
        );
        this.ApplicationDetail.Line2 = this.ApplicationDetail.Line2.replace(
          replacementArray[i],
          ""
        );
        ids.forEach((value, index) => {
          value.idNumber = value.idNumber.replace(replacementArray[i], "");
        });
      }
      var chunks = this.splitStringIntoChunks(this.ApplicationDetail.Line1, 35);

      let _data: any = {
        gstStateCode: this.ApplicationDetail.gstStateCode,
        enquiryPurpose: this.ApplicationDetail.enquiryPurpose,
        memberRefNo: this.ApplicationDetail.ApplicationNo,
        firstName: this.ApplicationDetail.Customer_FirstName,
        middleName: "",
        lastName: this.ApplicationDetail.Customer_LastName,
        birthDate: this.ApplicationDetail.DOB,
        gender: this.ApplicationDetail.GenderCode,
        telephoneNumber: this.ApplicationDetail.Customer_PhoneNo,
        addressStateCode: this.ApplicationDetail.address_StateCode,
        pinCode: this.ApplicationDetail.PinCode,

        line1: chunks[0] == undefined ? "" : chunks[0],
        line2: chunks[1] == undefined ? "" : chunks[1],
        line3: chunks[2] == undefined ? "" : chunks[2],
        line4: chunks[3] == undefined ? "" : chunks[3],
        line5:
          this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0] ==
          undefined
            ? ""
            : this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0],
        residenceCode: this.ApplicationDetail.residenceCode,
        enquiryAmount: LoanAmount,
        ids: ids,
      };

      console.log(
        "_data",

        JSON.stringify({
          loginUserId: this.currentUser.userId,

          loanId: this.LoanId,

          CustomerId: this.CustomerId,

          JSON: JSON.stringify(_data),
        })
      );

      console.log("_data111", JSON.stringify(_data));

      //$("#cibilReport").modal("show");

      //$("#cibilReport").css("z-index", "1050");

      this.LmsService.FetchCivilData({
        loginUserId: this.currentUser.userId,
        loanId: this.LoanId,
        CustomerId: this.CustomerId,
        JSON: _data,
      }).subscribe((res: any) => {
        console.log("res", res);
        this.Response = res;
        this.cibilResponse = res;
        this.error = this.cibilResponse?.controlData?.success;

        console.log("Cibil JSON", this.cibilResponse);
        if (this.cibilResponse?.controlData?.success == "true") {
          $("#cibilReport").modal("show");
          $("#cibilReport").css("z-index", "1050");
          this.CibilApiShow = false;
        } else if (this.cibilResponse?.Description == "Invalid API Key") {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: this.cibilResponse.Description,
            ...this.configSuccess,
          });
        } else {
          $("#cibilReport").modal("show");
          $("#cibilReport").css("z-index", "1050");
          this.CibilApiShow = true;
        }
      });
    }
  }

  splitStringIntoChunks(str: string, size: number): string[] {
    let chunks: string[] = [];
    let words = str.split(" ");
    let currentChunk = "";

    for (let word of words) {
      if (currentChunk.length + word.length + 1 <= size) {
        currentChunk += (currentChunk ? " " : "") + word;
      } else {
        if (currentChunk) {
          chunks.push(currentChunk);
        }
        currentChunk = word;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk);
    }

    return chunks;
  }
  onCloseCibilReport() {
    this.OnClose(true);
    $("#cibilReport").modal("hide");
  }
  getCRCThirdDetail() {
    this._MasterService
      .Get_CibilAPI_Request_Response({
        Loan_Id: this.LoanId,
        CId: this.CustomerId,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.cibilResponse = JSON.parse(res[0].response);

          console.log("Cibil JSON", this.cibilResponse);
          $("#cibilReport").modal("show");
          $("#cibilReport").css("z-index", "1050");
        }
      });
  }

  CheckCibildecisioncentre() {
    this.showSpinner = true;
    if (
      this.ApplicationDetail.LoanAmount == undefined ||
      this.ApplicationDetail.LoanAmount == null ||
      this.ApplicationDetail.LoanAmount == 0
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please fill Account Information!!",
        ...this.configSuccess,
      });
    } else {
      let LoanAmount: string =
        "0".repeat(9 - this.ApplicationDetail.LoanAmount.toString().length) +
        this.ApplicationDetail.LoanAmount.toString();

      var ids = [];
      this.kycList.forEach((value, index) => {
        if (value.Doc_Type_Cibil != "" && value.Doc_Type_Cibil != null) {
          ids.push({
            IdNumber: value.KYC_DocNumber,
            IdType: value.Doc_Type_Cibil,
          });
        }
      });
      var replacementArray = [
        "!",
        "#",
        "$",
        "%",
        "&",
        "*",
        ",",
        "/",
        "?",
        "@",
        "/",
        " ^ ",
        " | ",
        "~",
        " + ",
        "=",
      ];
      for (let i = 0; i < replacementArray.length; i++) {
        this.ApplicationDetail.Customer_FirstName =
          this.ApplicationDetail.Customer_FirstName.replace(
            replacementArray[i],
            ""
          );
        this.ApplicationDetail.Customer_LastName =
          this.ApplicationDetail.Customer_LastName.replace(
            replacementArray[i],
            ""
          );
        this.ApplicationDetail.Line1 = this.ApplicationDetail.Line1.replace(
          replacementArray[i],
          ""
        );
        this.ApplicationDetail.Line2 = this.ApplicationDetail.Line2.replace(
          replacementArray[i],
          ""
        );
        ids.forEach((value, index) => {
          value.IdNumber = value.IdNumber.replace(replacementArray[i], "");
        });
      }
      var chunks = this.splitStringIntoChunks(this.ApplicationDetail.Line1, 35);

      let _data: any = {
        gstStateCode: this.ApplicationDetail.gstStateCode,
        enquiryPurpose: this.ApplicationDetail.enquiryPurpose,
        memberRefNo: this.ApplicationDetail.ApplicationNo,
        firstName: this.ApplicationDetail.Customer_FirstName,
        middleName: "",
        email:
          this.ApplicationDetail.Email == null
            ? ""
            : this.ApplicationDetail.Email,
        lastName: this.ApplicationDetail.Customer_LastName,
        birthDate: this.ApplicationDetail.DOB,
        gender: this.ApplicationDetail.Gender,
        telephoneNumber: this.ApplicationDetail.Customer_PhoneNo,
        addressStateCode: this.ApplicationDetail.address_StateCode,
        pinCode: this.ApplicationDetail.PinCode,

        line1: chunks[0] == undefined ? "" : chunks[0],
        line2: chunks[1] == undefined ? "" : chunks[1],
        line3: chunks[2] == undefined ? "" : chunks[2],
        line4: chunks[3] == undefined ? "" : chunks[3],
        line5:
          this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0] ==
          undefined
            ? ""
            : this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0],
        residenceCode: this.ApplicationDetail.residenceCode,
        enquiryAmount: LoanAmount,
        ids: ids,
      };
      this.showSpinner = true;
      this.LmsService.FetchTransunionDecisionCentreData({
        loginUserId: this.currentUser.userId,
        loanId: this.LoanId,
        CustomerId: this.CustomerId,
        JSON: _data,
      }).subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].CODE != 0) {
            this.LmsService.FetchTransunionDecisionDocument({
              Identifier: res[0].CODE.toString(),
            }).subscribe((response: any) => {
              if (response != null) {
                this.saveAs(response, res[0].MSG);
                this.blobToBase64Async(response, res[0].MSG);
              }
            });
          } else {
            this.dialog
              .openDialog(res[0].MSG)
              .afterClosed()
              .subscribe((dialogResult) => {});
          }
        }
        this.showSpinner = false;
      });
    }
    this.showSpinner = false;
  }
  uploadData(filename) {
    this.DocModel1.DocName = filename;
    this.DocModel1.ApplicationNo = this.loanSummary.ApplicationNo;

    this.DocModel1.DocData = this.dataUrl;

    this.losService.UploadCrcDoc(this.DocModel1).subscribe((res: any) => {
      console.log("upload success");
      this.showSpinner = false;
      this.OnClose(true);
    });
  }
  saveAs(blob: Blob, fileName: string) {
    console.log("Save as pdf fn");
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
  blobToBase64Async(blob: Blob, filename): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let $this = this;
      fileReader.onerror = (e) => reject(fileReader.error);

      fileReader.onload = function () {
        $this.dataUrl = fileReader.result.toString().split(";base64,").pop();
        $this.uploadData(filename);
        return;
      };
      fileReader.readAsDataURL(blob);
    });
  }

  CheckCRIF() {
    this.showSpinner = true;
    this.CRIFApiShow = false;

    const day1 = this.ApplicationDetail.DOB.substring(0, 2);
    const month1 = this.ApplicationDetail.DOB.substring(2, 4);
    const year1 = this.ApplicationDetail.DOB.substring(4, 8);

    const DOB = `${day1}-${month1}-${year1}`;
    var ids = [];
    this.kycList.forEach((value, index) => {
      if (value.Doc_Type_Crif != "" && value.Doc_Type_Crif != null) {
        ids.push({
          TYPE: "ID" + value.Doc_Type_Crif.toString(),
          VALUE: value.KYC_DocNumber,
        });
      }
    });
    const currentDate = new Date();

    // Format date and time as DDMMYYYYHHmmSS
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    // Generate a random 6-digit number
    const randomNum = Math.floor(100000 + Math.random() * 900000);

    // Concatenate the result
    var ReferenceNo = `${day}${month}${year}${hours}${minutes}${seconds}${randomNum}`;

    let _data: any = {
      REQUEST_FILE: {
        HEADER_SEGMENT: {
          PRODUCT_TYPE: "",
          PRODUCT_VER: "",
          USER_ID: "",
          USER_PWD: "",
          REQ_MBR: "",
          INQ_DT_TM: "",
          REQ_VOL_TYPE: "",
          REQ_ACTN_TYPE: "",
          AUTH_FLG: "",
          AUTH_TITLE: "",
          RES_FRMT: "",
          MEMBER_PREF_OVERRIDE: "",
          RES_FRMT_EMBD: "",
          LOS_NAME: "",
          LOS_VENDOR: "",
          LOS_VERSION: "",
          REQ_SERVICES_TYPE: "",
        },
        INQUIRY: {
          APPLICANT_SEGMENT: {
            APPLICANT_ID: this.ApplicationDetail.ApplicationNo,
            FIRST_NAME: this.ApplicationDetail.Customer_FirstName,
            MIDDLE_NAME: "",
            LAST_NAME: this.ApplicationDetail.Customer_LastName,
            DOB: {
              DOB_DT: DOB,
              AGE: "",
              AGE_AS_ON: "",
            },
            RELATIONS: [
              {
                TYPE: "K01",
                VALUE: this.ApplicationDetail.Relation,
              },
            ],
            IDS: ids,
            ADDRESSES: [
              {
                TYPE: "D04",
                ADDRESS_TEXT: this.ApplicationDetail.Line1,
                CITY: this.ApplicationDetail.Line2,
                STATE: this.ApplicationDetail.address_TextStateCode,
                LOCALITY: "",
                PIN: this.ApplicationDetail.PinCode,
                COUNTRY: "INDIA",
              },
            ],
            GENDER: this.ApplicationDetail.GenderCodeCrif,
            INCOME: "",
            EMPLOYMENT_TYPE: "",
            EMPLOYER_NAME: "",
            PHONES: [
              {
                TYPE: "P04",
                VALUE: this.ApplicationDetail.PhoneNo,
              },
            ],
            EMAILS: [
              {
                EMAIL: "",
              },
            ],
            ACCOUNT_NUMBER: "",
          },
          APPLICATION_SEGMENT: {
            INQUIRY_UNIQUE_REF_NO: ReferenceNo,
            CREDIT_RPT_ID: "",
            CREDIT_RPT_TRN_DT_TM: "",
            CREDIT_INQ_PURPS_TYPE: "CP06",
            CREDIT_INQUIRY_STAGE: "",
            CLIENT_CONTRIBUTOR_ID: "",
            BRANCH_ID: "",
            APPLICATION_ID: this.ApplicationDetail.ApplicationNo,
            ACNT_OPEN_DT: "",
            LOAN_AMT: this.ApplicationDetail.LoanAmount,
            LTV: "",
            TERM: "",
            LOAN_TYPE: "A01",
            LOAN_TYPE_DESC: "auto loan",
          },
        },
      },
    };

    console.log("_data", _data);

    this.LmsService.FetchCrifData({
      loginUserId: this.currentUser.userId,
      loanId: this.LoanId,
      CustomerId: this.CustomerId,
      JSON: _data,
    }).subscribe((res: any) => {
      console.log("res", res);
      this.Response = res;
      this.crifResponse = res;
      console.log("Crif JSON", this.crifResponse);
      var _find = this.crifResponse.includes("html");
      if (!_find) {
        this.showSpinner = false;
        var criferror1 = JSON.parse(this.crifResponse);
        criferror1 = criferror1?.["CIR-REPORT-FILE"]?.ERRORS;
        console.log("criferror", criferror1);
        this.criferror = [];
        criferror1.forEach((value, index) => {
          this.criferror.push({
            errorCode: value?.["ERROR-CODE"],
            errorMessage: value?.["ERROR-DESC"],
          });
        });

        $("#crifReport").modal("show");
        $("#crifReport").css("z-index", "1050");
        this.CRIFApiShow = true;
      } else {
        this.showSpinner = false;
        const blob = new Blob([this.crifResponse.toString()], {
          type: "text/html",
        });
        this.blobToBase64Async(
          blob,
          "Crif_" +
            this.loanSummary.ApplicationNo +
            "_" +
            this.CustomerId.toString() +
            ".html"
        );
        //$("#crifReport").modal("show");
        //$("#crifReport").css("z-index", "1050");
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Crif Report Successfully Saved!!",
          ...this.configSuccess,
        });
        this.CRIFApiShow = false;
      }
    });
  }
  onCloseCrifReport() {
    this.OnClose(true);
    $("#crifReport").modal("hide");
  }
}
