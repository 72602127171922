<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-3 p-1">
      <span> Select State </span>
      <select
        name="FilterStateId"
        id="FilterStateId"
        (change)="getDistrictList()"
        [(ngModel)]="FilterStateId"
        class="form-control input-text-css"
      >
        <option value="">Select State</option>
        <option
          *ngFor="let StateList of FilterStateList"
          [value]="StateList.StateId"
        >
          {{ StateList.State_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 mt-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="onOpenDistrictModal()"
      >
        Add New District
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="dataSource"
      matSort
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="DistrictId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="State_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >State Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.State_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="District_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          District Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.District_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TehsilCount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Tehsil Count
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TehsilCount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OneditDistrict(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div class="col-md-12 d-flex align-items-center justify-content-between">
    <div class="col-md-4">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>

    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddEditDistrictModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveDistrictData()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ ButtonName }} District
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select State</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="StateId"
                id="StateId"
                #refStateId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refStateId.invalid,
                  'alert-warning':
                    refStateId.invalid &&
                    (refStateId.dirty ||
                      refStateId.touched ||
                      refStateId.untouched)
                }"
                [(ngModel)]="StateId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let StateList of FilterStateList"
                  [value]="StateList.StateId"
                >
                  {{ StateList.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">District Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refDistrictName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDistrictName.invalid,
                  'alert-warning':
                    refDistrictName.invalid &&
                    (refDistrictName.dirty ||
                      refDistrictName.touched ||
                      refDistrictName.untouched)
                }"
                [(ngModel)]="DistrictName"
                placeholder="District Name"
                name="DistrictName"
                id="DistrictName"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveDistrictData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="District_List"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      State Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      District Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tehsil Count
    </th>
  </tr>

  <tr *ngFor="let x of ZoneList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.State_Name }}</td>
    <td>{{ x.District_Name }}</td>
    <td>{{ x.TehsilCount }}</td>
  </tr>
</table>
