import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../_LOS/services/los.service";
import { MasterService } from '../Shared/app.Masters.Service';
import { SnackbarComponent } from "../snackbar/snackbar.component";
declare var $: any;


@Component({
  selector: 'app-deviation-module',
  templateUrl: './deviation-module.component.html',
  styleUrls: ['./deviation-module.component.scss']
})
export class DeviationModuleComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  @Input() ApplicationDetail: any;
  @Input() Show:boolean;
  customerDataSource: any;
  UserAssignList:any;
  LoanRelated:any=[];
  customerRelated: any = [];
 CRCRelated: any = [];
  DocumentRelated:any=[];

  LoanRelated_DataSource: any;
  CRCRelated_DataSource: any;
  displayLoanRelatedColumns:string[]=["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];
  CustomerRelated_DataSource:any;
  displayCustomerRelatedColumns: string[] = ["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];
  displayCRCRelatedColumns: string[] = ["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];
  DocumentRelated_DataSource:any;
  displayDocumentRelatedColumns:string[]=["Id", "Value", "KYC_DocNumber", "UserAssign", "Remark"];
  

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  UserAssignBy: any;
  Remark: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private losService: LosService,
    private MasterService:MasterService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next('Deviation');
    this.UserAssignBy = '';
   
  }

  OnClickDeviationCheck() {
    if (this.Show == true) {
      this.losService.LOS_DeviationCheck_On_sendToCredit({
        ApplicationId: this.ApplicationDetail.ApplicationId, productId: this.ApplicationDetail.ProductId
      })
        .subscribe((res: any) => {
          //console.log("res", res);
          this.LoanRelated = res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == "True");
          this.customerRelated = res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == "True");
          this.DocumentRelated = res.Item3.filter(x => x.Deviation == "True" && x.IsDeviation == "True");

          this.LoanRelated.forEach((obj: any) => {
            obj.UserAssign = '';
          });

          this.customerRelated.forEach((obj: any) => {
            obj.UserAssign = '';
          });

          this.DocumentRelated.forEach((obj: any) => {
            obj.UserAssign = '';
          });

          this.LoanRelated_DataSource = new MatTableDataSource(res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == "True"));
          this.customerDataSource = new MatTableDataSource(res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == "True"));
          this.DocumentRelated_DataSource = new MatTableDataSource(res.Item3.filter(x => x.Deviation == "True" && x.IsDeviation == "True"));

          this.MasterService.Get_Employee_ByBranch_Department({ BranchId: 0, DeptId: 0, LoginUserId: this.currentUser.userId, Type: "Upper" }).subscribe((res: any) => {
            this.UserAssignList = res.filter(x => x.EmpId != this.currentUser.userId);
          });


          this.displayLoanRelatedColumns = ["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];
          this.displayCustomerRelatedColumns = ["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];
          this.displayDocumentRelatedColumns = ["Id", "Value", "KYC_DocNumber", "UserAssign", "Remark"];


          $("#DeviationModel").modal("show");
          $("#DeviationModel").css("z-index", "1050");
        });
    }
    else {
      this.MasterService.Get_Employee_ByBranch_Department({ BranchId: 0, DeptId: 0, LoginUserId: this.currentUser.userId, Type: "Upper" }).subscribe((res: any) => {
        this.UserAssignList = res.filter(x => x.EmpId != this.currentUser.userId);
      });

      this.CRCRelated = this.ApplicationDetail.filter(x => x.Deviation == "True" && x.IsDeviation == "True");
      this.CRCRelated.forEach((obj: any) => {
        obj.UserAssign = '';
      });
      console.log(" this.ApplicationDetail", this.ApplicationDetail);
      this.CRCRelated_DataSource = new MatTableDataSource(this.ApplicationDetail.filter(x => x.Deviation == "True" && x.IsDeviation == "True"));
          $("#DeviationModel").modal("show");
          $("#DeviationModel").css("z-index", "1050");
    }
  }
  OnCloseDeviation() {
    $("#DeviationModel").modal("hide");
  }

  onSaveDeviation(){
    var arr: any = [];
    this.LoanRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.ApplicationDetail.ApplicationIdentity,
          Type: x.Type,
          Value: x.Value,
          Minimum: x.Minimum,
          Maximum: x.Maximum,
          Col_Val: x.Col_Val,
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    this.customerRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.ApplicationDetail.ApplicationIdentity,
          Type: x.Type,
          Value: x.Value,
          Minimum: x.Minimum,
          Maximum: x.Maximum,
          Col_Val: x.Col_Val,
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    this.DocumentRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.ApplicationDetail.ApplicationIdentity,
          Type: x.Type,
          Value: x.Value,
          Minimum: '',
          Maximum: '',
          Col_Val: '',
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    this.CRCRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.ApplicationDetail[0].ApplicationIdentity,
          Type: x.Type,
          Value: x.Value,
          Minimum: x.Minimum,
          Maximum: x.Maximum,
          Col_Val: x.Col_Val,
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    if(arr.length == 0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please fill atleast single data", ...this.configSuccess });
      return
    }
    
    let _data = {
      Deviation: arr
    }
    console.log("_data", _data);
    this.losService.Deviation_Creation_sendToCredit({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.OnCloseDeviation();
        this.close.emit(true);
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
    });
  }
}
