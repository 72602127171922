import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
declare var $: any;

@Component({
  selector: 'app-mst-role',
  templateUrl: './mst-role.component.html',
  styleUrls: ['./mst-role.component.scss']
})
export class MstRoleComponent implements OnInit {

  private data: any; showSpinner: boolean = false;
  dataSource: any; loading: boolean = false;
  displayedColumns: string[] = ['RoleId', 'Role_Name', 'Role_Level', 'Waiver_Amount', 'Waiver_Percentage', 'Configuration', 'Accounting', 'LMS', 'LOS', 'Reports', 'Portfolio', 'Deposit', 'QuickLoan','Mobile', 'EditAction'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  RoleList: any = [];
  dialogreturn: any;
  TitleName: string = 'New';
  RoleModel: any = { RoleName: "", RoleLevel: "", RoleId: 0 };
  RoleMenu: any = {};

  constructor(private dataSharingService: DataSharingService, private dialog: DialogService, public snackBar: MatSnackBar, private _MasterService: MasterService) {
    //this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Designation Master');
    this.getRoleList();
  }


  getRoleList() {
    this.showSpinner = true;
    this._MasterService.GetRoleForList({}).subscribe((result) => {
      this.RoleList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.RoleList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddRole() {
    this.RoleModel = { RoleName: "", RoleLevel: "", RoleId: 0, IsConfigurationAllow: false, IsLOSAllow: false, IsLMSAllow: false, IsAccountingAllow: false, IsReportsAllow: false, IsDepositAllow: false, IsPortfolioAllow: false, IsQuickLoanAllow: false, IsMobileAllow: false, Waiver_Amount: '', Waiver_Percentage: '' };
    this.Get_Menu_By_Role();
    $('#AddNewRoleModal').modal('show');
    $("#AddNewRoleModal").css("z-index", "1050");
  }

  OnClose() {
    $('#AddNewRoleModal').modal('hide');
  }
  Get_Menu_By_Role() {
    this.RoleMenu = {};
    this._MasterService.Get_Menu_By_Role({ RoleId: this.RoleModel.RoleId }).subscribe((res: any) => {
      this.splitMenu('Configuration', res);
      this.splitMenu('LOS', res);
      this.splitMenu('Accounting', res);
      this.splitMenu('Mobile', res);
      this.splitMenu('LMS', res);
      this.splitMenu('Reports', res);
      this.splitMenu('Portfolio', res);
      this.splitMenu('Deposit', res);
      this.splitMenu('QuickLoan', res);
    });
  }
  onChangeMenuSelect(data: any, parent: any) {
    if (data.Menu_Assigned && parent) {
      parent.Menu_Assigned = true;
     
    }
    if (data.Menu_Assigned) {
      for (let i = 0; i < data.Children.length; i++) {
        data.Children[i].Menu_Assigned = true;
      }
    } else {
      for (let i = 0; i < data.Children.length; i++) {
        data.Children[i].Menu_Assigned = false;
      }
    }

  }
  splitMenu(type: any, data: any[]) {
    let _allmenu = data.filter((obj: any) => { return obj.MenuFor == type });
    let _Menu = _allmenu.filter((obj: any) => { return obj.ParentID == 0 });
    this.makeTree(_allmenu, _Menu);
    this.RoleMenu[type] = _Menu;
  }
  makeTree(allData: any[], parentData: any[]) {
    for (let i = 0; i < parentData.length; i++) {
      let children = allData.filter((obj: any) => obj.ParentID == parentData[i].MenuId);
      parentData[i].Children = children;
      this.makeTree(allData, children);
    }
  }
  getSelectedRoleId(data:any[],container:any[]){
    data.forEach((obj:any) => {
      if(obj.Menu_Assigned){
        container.push(obj.MenuId);
        if(obj.Children.length>0){
          this.getSelectedRoleId(obj.Children,container);
        }
      }
    });
  }
  onSaveRoleMaster() {
    let _roleId:any[]=[];
    if(this.RoleModel.IsConfigurationAllow){
      this.getSelectedRoleId(this.RoleMenu.Configuration,_roleId);
    }
    if(this.RoleModel.IsLOSAllow){
      this.getSelectedRoleId(this.RoleMenu.LOS,_roleId);
    }
    if(this.RoleModel.IsLMSAllow){
      this.getSelectedRoleId(this.RoleMenu.LMS,_roleId);
    }
    if(this.RoleModel.IsAccountingAllow){
      this.getSelectedRoleId(this.RoleMenu.Accounting,_roleId);
    }
    if (this.RoleModel.IsMobileAllow) {
      this.getSelectedRoleId(this.RoleMenu.Mobile, _roleId);
    }
    if(this.RoleModel.IsReportsAllow){
      this.getSelectedRoleId(this.RoleMenu.Reports,_roleId);
    }
    if (this.RoleModel.IsPortfolioAllow) {
      this.getSelectedRoleId(this.RoleMenu.Portfolio, _roleId);
    }
    if (this.RoleModel.IsDepositAllow) {
      this.getSelectedRoleId(this.RoleMenu.Deposit, _roleId);
    }
    if (this.RoleModel.IsQuickLoanAllow) {
      this.getSelectedRoleId(this.RoleMenu.QuickLoan, _roleId);
    }
    this.RoleModel.RoleMenus=_roleId.join(',');
    this._MasterService.Save_Role(this.RoleModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getRoleList();
        this.TitleName = 'New';
        this.OnClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getRoleList();
        this.TitleName = 'New';
        this.OnClose();
      }
    });
  }

  OnEditRoleData(selectedRow: any) {
    this.RoleModel = { RoleName: selectedRow.Role_Name, RoleLevel: selectedRow.Role_Level, RoleId: selectedRow.RoleId, IsConfigurationAllow: selectedRow.Configuration == 'Yes', IsLOSAllow: selectedRow.LOS == 'Yes', IsLMSAllow: selectedRow.LMS == 'Yes', IsAccountingAllow: selectedRow.Accounting == 'Yes', IsReportsAllow: selectedRow.Reports == 'Yes', IsPortfolioAllow: selectedRow.Portfolio == 'Yes', IsDepositAllow: selectedRow.Deposit == 'Yes', IsQuickLoanAllow: selectedRow.QuickLoan == 'Yes', IsMobileAllow: selectedRow.Mobile == 'Yes', Waiver_Amount: selectedRow.Waiver_Amount, Waiver_Percentage: selectedRow.Waiver_Percentage};
    this.TitleName = 'Edit';
    this.Get_Menu_By_Role();
    $('#AddNewRoleModal').modal('show');
    $("#AddNewRoleModal").css("z-index", "1050");
  }

  OnDeleteRoleData(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete?');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this._MasterService.Delete_Role({ RoleId: row.RoleId }).subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            this.getRoleList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  /*onChangeIsLOSAllow(event: MatCheckboxChange){
    if (event.checked == false) {
      this.RoleMenu.LOS.map((item) => {      
        item.Menu_Assigned = event.checked;
      });
    }
  }
  onChangeIsLMSAllow(event: MatCheckboxChange){
    if (event.checked == false) {
      this.RoleMenu.LMS.map((item) => {      
        item.Menu_Assigned = event.checked;

        console.log("item.Children", item.Children);
        item.Children.map((x) => { 
          x.Menu_Assigned = event.checked;
        });
      });
    }
    console.log("this.RoleMenu.LMS", this.RoleMenu.LMS);
    
  }*/
}
