import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from "../services/lms.service";
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { constantUrl } from '../../Shared/constantUrl';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { LosService } from '../../_LOS/services/los.service';
import { DialogService } from '../../Shared/dialog.service';
import { DatePipe } from '@angular/common'
declare var $: any;


@Component({
  selector: 'app-request-and-task',
  templateUrl: './request-and-task.component.html',
  styleUrls: ['./request-and-task.component.scss']
})
export class RequestAndTaskComponent implements OnInit {
  FilterInputSearch: any;
  dataSource: any;
  status: any = "Pending";
  SearchType: any = "Status";
  Type: any = '';
  CustomerType: any = '';
  SearchValue: any = "Pending";
  displayedColumns: string[] = [
    "Id",
    "Type",
    "Title",
    "LoanAcNo",
    "VisitLocation",
    "Customer",
    "CustomerResponse",
    "CreatedBy",
    "CreatedOn",    
    "FollowupBy",
    "FollowupDate",
    "Completed_On",
    "Status",
    "TAT",
    "Creation_LatLong",
    "Completed_LatLong",
    "View"];




  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  currentUser: any;
  showSpinner: boolean = false;
  FromDate: any;
  ToDate: any;
  FileCheckingList: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  EmployeeList: any[] = [];

  constructor(private _Route: Router,private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute, public datepipe: DatePipe, private MasterService: MasterService, private dialog: DialogService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Type = param.get("Type");
      if (this.Type != '' && this.Type != null && this.Type != undefined) {
        var TyeValue;
        if (this.Type == 'FOLLOWUP')
          TyeValue = 'Follow-Up,Collection';
        if (this.Type == 'REQUEST')
          TyeValue = 'Customer Request,Internal Request';
        if (this.Type == 'TASK')
          TyeValue = 'Customer Task,Internal Task';
        this.lmsService.LMS_Dashboard_CRM_Request_Task({ LoginUserId: this.currentUser.userId, Type: TyeValue }).subscribe((res: any) => {

          this.dataSource = new MatTableDataSource(res);
          this.showSpinner = false;
        });
      }
     
      else {
        this.LMS_Get_NoteRecommendation();
        this.EmployeeDropDown();
        this.Search();
      }
     
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Request And Task');
 
  }

  LMS_Get_NoteRecommendation() {
    this.lmsService.LMS_CRM_Request_Task({ LoginUserId: this.currentUser.userId, SearchBy: '', SearchValue: '' }).subscribe((res: any) => {
      this.FileCheckingList = JSON.parse(JSON.stringify(res)); 
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.showSpinner = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  Search() {
    //console.log("this.FromDate", this.datepipe.transform(this.FromDate, 'yyyy-MM-dd'));
    //console.log("this.ToDate", this.ToDate);

    if (this.SearchType == 'CreatedOn' || this.SearchType == 'FollowupOn' || this.SearchType == 'CompletedOn') {
      this.SearchValue = this.datepipe.transform(this.FromDate, 'yyyy-MM-dd') + ',' + this.datepipe.transform(this.ToDate, 'yyyy-MM-dd');
    }
    this.lmsService.LMS_CRM_Request_Task({ LoginUserId: this.currentUser.userId, SearchBy: this.SearchType, SearchValue: this.SearchValue, Type: this.CustomerType, status:this.status }).subscribe((res: any) => {
      if (res.length > 0) {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      }
      else {
        this.FileCheckingList = [];
        this.dataSource = null;
        this.showSpinner = false;
      }
    });
  }
  EmployeeDropDown() {
    this.MasterService.Get_Employee_ByBranch_Department({ BranchId: 0, DeptId: 0, LoginUserId: this.currentUser.userId, Type: 'Lower' }).subscribe((res: any) => {
      this.EmployeeList = res;
    });
  }
  ChangeSearchType() {
    this.SearchValue = "";
  }
  onDeleteNote(row) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ${row.Title} from Loan.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService.LMS_Delete_NoteRecommendation({ Id: row.Id, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.LMS_Get_NoteRecommendation();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          })
        }
      });
  }
  onEditNotesRecom(row) {
 
    this._Route.navigate([`lms-dashboard/request-and-task-detail/${encodeURIComponent(row.Id)}`]);
  }
  AddNew() {
    this._Route.navigate(['lms-dashboard/request-and-task-detail']);

  }
  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("request_task") as HTMLTableElement;
    const worksheetName = 'REQUEST TASK'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }


}
