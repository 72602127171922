import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import * as moment from 'moment';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: 'app-employee_assign_los_process',
  templateUrl: './employee_assign_los_process.component.html',
  styleUrls: ['./employee_assign_los_process.component.scss']
})
export class EmployeeAssignLosProcessComponent implements OnInit {
  private data: any; showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();

  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = 0;
 
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
 
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  isEdit: boolean = false;
  LoanProcessList: any = [];
  isDisable: boolean = true;

  ShowFilter = true;
 
  limitSelection = false;

  Ischecked: boolean = false;
  groupDropdown: any[] = [];
  IsCreateEmployee: boolean = true;

  SelectRoleId: any = '';
  EmpManagerId: any;
  EmployeeName: any;
  SelectedLoanProcessData: any = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private _Route: Router, 
    private dataSharingService: DataSharingService, 
    public snackBar: MatSnackBar, 
    private _MasterService: MasterService, 
    private fb: UntypedFormBuilder,
    private lmsService: LmsService,
  ) {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Employee Register');

    this.EmployeeId = sessionStorage.getItem('getEmployeeID');
    if (parseInt(this.EmployeeId) > 0) {
      this.isEdit = true;
      this.IsCreateEmployee = false;
      this.Get_Master();
      this.getEmployeeData();
    }
  


  }

  Get_Master() {
    this.showSpinner = true;
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });
    this._MasterService.ReportingManagerForDropdown(this.ReportingManagerRequestModel).subscribe((result) => {
      this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
    });
  }
  getEmployeeData() {
    this.LoanProcessList = [];
    this.RequestModel.EmpId = parseInt(this.EmployeeId);
    this._MasterService.GetEmployeeDetails(this.RequestModel).subscribe((result) => {
      //console.log("RESULT :",result);
      
      this.EmployeeDetailData = result;
   
      this.EmployeeName = this.EmployeeDetailData.Item1[0].Emp_FirstName + ' ' + this.EmployeeDetailData.Item1[0].Emp_LastName;
      // this.LastName = this.EmployeeDetailData.Item1[0].Emp_LastName;
      this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
      this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;

      this.LoanProcessList = this.EmployeeDetailData.Item3;
      this.LoanProcessList.map((item) => {
        item.isActiveSelect = item.ProcessAlradyAssigned;
        item.isDisable = item.ProcessAlradyAssigned;
      });



    });
  }

  
 

 


  onChangeAssignProcess(event: MatCheckboxChange, selectedProcessId) {
    this.LoanProcessList.map((item) => {
      if (item.ProcessId == selectedProcessId) {
        item.isActiveSelect = event.checked;
      }
    });
  }
  OnClose() {
    this._Route.navigate(['employee/list']);
  }

 

  onSaveEmployeeDetailData() {

    //this.BranchesList.map((item) => {
    //  // if (item.BranchAlradyAssigned == true) {
    //  this.SelectedBranchesData.push({
    //    "Id": item.EmpBranchId,
    //    "EmpBranch_EmpId": parseInt(this.EmployeeId),
    //    "EmpBranch_BranchId": item.BranchId,
    //    "EmpBranch_IsActive": item.isActiveSelect == true ? 1 : 0,
    //    "EmpBranch_CreateBy": this.data.userId,
    //    "EmpBranch_ModifyBy": this.data.userId
    //  });
    //  // }
    //});

    this.LoanProcessList.map((item) => {
      // if (item.ProcessAlradyAssigned == true) {
      this.SelectedLoanProcessData.push({
        "Id": item.EmpProcessId,
        "EmpProcess_EmpId": parseInt(this.EmployeeId),
        "EmpProcess_ProcessId": item.ProcessId,
        "EmpProcess_IsActive": item.isActiveSelect == true ? 1 : 0,
        "EmpProcess_CreateBy": this.data.userId,
        "EmpProcess_ModifyBy": this.data.userId
      });
      // }
    });
    //console.log('DeptId', DeptId);
    let saveEmployeeDetail = {
      "Employee": {
        "EmpId": parseInt(this.EmployeeId)
      },
      //"Employee": {
      //  "EmpId": parseInt(this.EmployeeId),
      //  "Emp_FirstName": this.FirstName,
      //  "Emp_LastName": this.LastName,
      //  "Emp_Gender": this.SelectGender,
      //  "Emp_DOB": moment(this.DateOfBirth).format('YYYY-MM-DD'),
      //  "Emp_PhoneNo": this.PhoneNo,
      //  "Emp_PhoneNo1": this.AlternetPhoneNo,
      //  "Emp_RoleId": parseInt(this.SelectRoleId),
      //  "Emp_DepartmentId": DeptId, //parseInt(this.SelectDeptId),
      //  "Emp_Email": this.EnterEmail,
      //  "Emp_LoginId": this.EmployeeLoginId,
      //  "Emp_Password": this.EmployeePassword,
      //  "Emp_PresentAddress": this.PresentAddress,
      //  "Emp_PermanentAddress": this.PermanentAddress,
      //  "Emp_AadharNo": this.AadharNo,
      //  "Emp_Image": this.profilePicture != '' ? this.CurrentDate.getTime() + '_' + this.UploadImageName : this.UploadImageName,
      //  "Emp_ManagerId": parseInt(this.EmpManagerId),
      //  "Emp_Createby": this.data.userId,
      //  "Emp_ModifyBy": this.data.userId,
      //  "Base_BranchId": this.BaseBranchId,
      //  "GroupId": this.GroupId,
      //  "Emp_PAN_No": this.Emp_PAN_No,
      //  "Emp_Code": this.Emp_Code
      //},
     // "EmployeeBranch": this.SelectedBranchesData,
      "EmployeeProcess": this.SelectedLoanProcessData
    }

  
    this.SaveEmployeeDataRequestModel.JSON = JSON.stringify(saveEmployeeDetail);

    this._MasterService.SaveEmployeDetails(this.SaveEmployeeDataRequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      //  this.OnClose();
        this.getEmployeeData();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
  onDeleteAssignProcess(selectedProcessId) {

    this.EmpDeleteAssProcessRequestModel.EmpProcessId = selectedProcessId;
    this._MasterService.DeleteEmployeeProcess(this.EmpDeleteAssProcessRequestModel).subscribe((response) => {
      if (response[0].CODE == 1) {
        this.LoanProcessList.map((item) => {
          if (item.EmpProcessId == selectedProcessId) {
            item.isDisable = false;
            item.ProcessAlradyAssigned = false;
            item.isActiveSelect = false;
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }




}
