import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { MasterService } from 'src/app/Shared/app.Masters.Service';
declare var $: any;
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';


@Component({
  selector: 'app-future-flow',
  templateUrl: './future-flow.component.html',
  styleUrls: ['./future-flow.component.scss']
})
export class FutureFlowComponent implements OnInit {
  showSpinner: boolean = false;
  div_list: boolean = false;
  pageTitle: any = "Future Flow";

  DataSource: any = [];
  DataSourceModel: any = [];
  displayedColumns: string[] = ["Id", "Month_Year", "No_Of_Case", "Installment_Amount", "Principal", "Interest"]; // , "Action"
  displayedColumnsModel: string[] = [];
  JsonData: any[] = [];

  //Form value
  st_month_year: any;
  en_month_year: any;
  FyearId: any;
  Classification: any = "0";
  NPAList: any;
  LoanAcCatId: any = "0";
  AccountingCatDropdown: any[] = [];


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectProduct: any=0;
  ProductList: any;
  currentUser: any;
  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private _MasterService:MasterService
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.pageTitle);
    this.getNPAList();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.st_month_year = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.en_month_year = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
          this.DataSource = null;
        });
      }
    );

    this.Get_Loan_Accounting_Category_ForDropdown();
    this.getProductDropdown()
  }

  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 }).subscribe((res: any) => {
      this.AccountingCatDropdown = res;
    })
  }
  getProductDropdown() {
    this._MasterService.GetProductList({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
    });
  }

  GetReportFutureFlow() {
    this.showSpinner = true;
    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();

    let EnMonth = this.en_month_year.getMonth() + 1;
    let EnYear = this.en_month_year.getFullYear();

    this.reportsService.Report_Future_Flow({ StartMonth: StMonth, StartYear: StYear, ProductId: this.SelectProduct, LoanAcCatId: parseInt(this.LoanAcCatId == '' && this.LoanAcCatId == null ? '0' : this.LoanAcCatId), NPAType: this.Classification }).subscribe((res: any) => {
      //console.log("res", res);
      if (res.length > 0) {
        this. JsonData=JSON.parse(JSON.stringify(res));
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.paginator = this.paginator;

        this.DataSource.sort = this.sort;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.DataSource = new MatTableDataSource([]);
      }
      this.showSpinner = false;
    });
  }

  exportTable() : void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("Future-flow") as HTMLTableElement;
    const worksheetName = 'FUTURE FLOW'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  totaltypewise(type) {
    if (this.DataSource && this.DataSource != null && this.DataSource != '') {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  } 

  goToDetails(){

  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }
  getNPAList() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "NPA" })
      .subscribe((res: any) => {
        console.log(res);
        this.NPAList = res;
      });
  }
 
}
