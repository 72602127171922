import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MobileService } from "../../Shared/app.Mobile.Service";
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { constantUrl } from '../../Shared/constantUrl';
import { QuickLoanService } from '../services/quickLoan.service';
import { LosService } from "../../_LOS/services/los.service";


@Component({
  selector: 'app-quickloan-fi-questions',
  templateUrl: './quickloan-fi-questions.component.html',
  styleUrls: ['./quickloan-fi-questions.component.scss']
})
export class QuickloanFiQuestionsComponent implements OnInit {
  showSpinner: boolean = false;
  FIQuestionModel: any = {};
  loading: boolean = false;
  currentUser: any;
  @Input() Revert_IsEdit: any;
  questionData: any[] = [];
  questionOptionData: any[] = [];

  //Final Submit
  Recommendation: any = '';
  Remarks: any;
  AllAnswer: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;

  CustomerId: any;
  customerData: any = {};
  CustomerFI: any = {};
  ShowDiv: boolean = false;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private _MobileService: MobileService,
    private LmsService: LmsService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private _QuickLoanService: QuickLoanService,
    private _LosService: LosService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LMS_GetLoanDetails();
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      console.log("LMS_GetLoanDetails", res);
      this.showSpinner = false;
      this.customerData = res.Item2;
      if(res.Item2[0]){
        this.OnClickFiQuestions(res.Item2[0]);
      }
    })
  }
  OnClickFiQuestions(row){
    this.ShowDiv = true;
    console.log("row", row);
    
    this.CustomerId = row.CustomerId;

   
    this.GetLosCustomerFIQuestionAnswer();
  }

  GetLosCustomerFIQuestionAnswer() {
    //this.showSpinner = true;
    this._MobileService.LOS_GetFIQuestionsApp({ ApplicationId: this.loanSummary.LoanId, CustomerId: this.CustomerId }).subscribe((res: any) => {
      console.log("RES :", res);
      this.showSpinner = false;
      this.questionData = res;
      this.Recommendation = res[0].Recommendation;
      this.Remarks = res[0].Remark;
      this.AllAnswer = res.filter(x => x.FIAnswer != "" && x.FIAnswer != null);
      console.log("this.AllAnswer", this.AllAnswer);
      if(this.AllAnswer.length > 0){
        this.IsEdit = true;
        this.IsSave = false;
        this.IsDisabled = true;
      }

      else {
        var currentTab = this.PageData.currentTab == 1 && this.Status != 'C' && (this.Status == 'R' ? this.Revert_IsEdit == 1 ? true : false : true);
        if (currentTab == true) {
          this.IsEdit = false;
          this.IsSave = true;
          this.IsDisabled = false;
        }
        else {
          this.IsDisabled = true;
        }

      }
      
      
      this.questionData.forEach((obj: any) => {
          if (obj.QuestionType == 'Multi-Selection' && obj.FIAnswer != null) {
            obj.FIAnswer = obj.FIAnswer.split(',');
          }
      })
      this.questionData.forEach((obj: any) => {
        if (obj.QuestionType == 'Selection' || obj.QuestionType == 'Multi-Selection') {
          obj.questionOptionData = obj.QuestionOptions.split('@@');
        }
      })
    })
  }

  fileChangeListener(data: any, files: any) {
    /*console.log("this.questionData[ this.questionData.length - 1].title", this.questionData[ this.questionData.length - 1].title);
    
    if (!this.questionData[ this.questionData.length - 1].title ) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter title.", ...this.configSuccess});
      files.value = "";
      return;
    }*/
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.FIAnswer = "";
      delete data.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      data.FIAnswer = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.FIAnswer = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.FIAnswer = "";
    delete data.DocData;
  }

  onSaveCustomerFIAnswer(){
    this.loading = true;
    var FIAnswer;
    var arr = [];
    console.log("this.questionData : ", this.questionData);
    this.questionData.forEach((obj: any) => {
      if (obj.QuestionType == 'Multi-Selection') {
        arr.push({
          FI_ApplicationId : this.loanSummary.LoanId,
          FI_CustomerId : this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer : obj.FIAnswer.join(','),
          FI_LoginUserId : this.currentUser.userId
        });
      }
      else if (obj.QuestionType == 'Upload') {
        arr.push({
          FI_ApplicationId : this.loanSummary.LoanId,
          FI_CustomerId : this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer : obj.FIAnswer,
          DocData : obj.DocData,
          FI_LoginUserId : this.currentUser.userId
        });
        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: obj.FIAnswer, DocData: obj.DocData });
      }
      else {
        arr.push({
          FI_ApplicationId : this.loanSummary.LoanId,
          FI_CustomerId : this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer : obj.FIAnswer,
          FI_LoginUserId : this.currentUser.userId
        });
      }
    });
    this.FIQuestionModel = arr;
    this.CustomerFI.FI_ApplicationId = this.loanSummary.LoanId;
    this.CustomerFI.FI_CustomerId = this.CustomerId;
    this.CustomerFI.FI_LoginUserId = this.currentUser.userId;
    this.CustomerFI.FI_Remark = this.Remarks;
    this.CustomerFI.FI_Recommendation = this.Recommendation;
    this.CustomerFI.FI_DoneBy = this.currentUser.userId;
    this.CustomerFI.FI_DoneOn = new Date();
    FIAnswer = { "FIAnswer": this.FIQuestionModel, "CustomerFI": this.CustomerFI };
    
    console.log("this.FIAnswer : ", FIAnswer);
    this._MobileService.LOS_SaveCustomerFIAnswerApp({ JSON : JSON.stringify(FIAnswer) }).subscribe((response: any) => {
      //console.log(response);
      if (response[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.GetLosCustomerFIQuestionAnswer();
        this.QuickLoan_Save_PageProcess('FI Questions');
        this.action.emit("next");
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
      this.loading = false;
    });
  }

  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {
    });
  }

  onEditCustomerFIAnswer(){
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  CheckCustomerFIQuestions() {
    this.action.emit("next");
    /*if (this.AllAnswer && this.AllAnswer.length > 0) {
      this.action.emit("next");
    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add FI Questions.", ...this.configSuccess });
    }*/
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
}
