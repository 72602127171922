<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 align-items-end formborder">
    <div class="col-md-2">
      <span>Select Zone</span>
      <select
        name="FilterZoneId"
        id="FilterZoneId"
        (change)="getBranchesList()"
        [(ngModel)]="FilterZoneId"
        class="form-control input-text-css"
      >
        <option value="">All Zone</option>
        <option
          *ngFor="let zoneListDropdown of ZoneListDropdown"
          [value]="zoneListDropdown.ZoneId"
        >
          {{ zoneListDropdown.Zone_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <span>Select State</span>
      <select
        name="FilterStateId"
        id="FilterStateId"
        #refFilterStateId="ngModel"
        (change)="GetDistrickDropdown()"
        [(ngModel)]="FilterStateId"
        class="form-control input-text-css"
        required
      >
        <option value="">Select State</option>
        <option *ngFor="let state of stateDropdown" [value]="state.StateId">
          {{ state.State_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <span>Select District</span>
      <select
        name="FilterDistrictId"
        id="FilterDistrictId"
        [(ngModel)]="FilterDistrictId"
        class="form-control input-text-css"
        (change)="getBranchesList()"
        [disabled]="!FilterStateId"
      >
        <option value="">All District</option>
        <option
          *ngFor="let filterDistrictList of FilterDistrictList"
          [value]="filterDistrictList.DistrictId"
        >
          {{ filterDistrictList.District_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-4 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="OnOpenBranchModal(0)"
      >
        Add Branch
      </button>
    </div>
  </div>

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="BranchId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Code">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Code
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Code }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="District_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          District
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.District_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Tehsil_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Tehsil
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Tehsil_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_PhoneNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Head">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch Head
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Head }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnOpenBranchModal(row.BranchId)"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onOpenDeleteModal(row.BranchId)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 col-md-12 mt-2">
      <div class="col-md-4">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                #refFilterInputSearch="ngModel"
                [(ngModel)]="FilterInputSearch"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewBranchModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveBranchData()" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Register New Branch
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-white fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="formborder">
            <div class="row m-0">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Branch Name </span>
                <input
                  required
                  type="text"
                  #refBranchName="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBranchName.invalid,
                    'alert-warning':
                      refBranchName.invalid &&
                      (refBranchName.dirty ||
                        refBranchName.touched ||
                        refBranchName.untouched)
                  }"
                  [(ngModel)]="BranchName"
                  placeholder="Branch Name"
                  name="BranchName"
                  id="BranchName"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 p-1">
                <span class="required-lable"> Branch Code </span>
                <input
                  required
                  type="text"
                  #refBranchCode="ngModel"
                  maxlength="3"
                  minlength="3"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBranchCode.invalid,
                    'alert-warning':
                      refBranchCode.invalid &&
                      (refBranchCode.dirty ||
                        refBranchCode.touched ||
                        refBranchCode.untouched)
                  }"
                  [(ngModel)]="BranchCode"
                  placeholder="Branch Code"
                  name="BranchCode"
                  id="BranchCode"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 p-1">
                <span class="required-lable"> Branch Type </span>
                <select
                  name="BranchType"
                  id="BranchType"
                  #refBranchType="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBranchType.invalid,
                    'alert-warning':
                      refBranchType.invalid &&
                      (refBranchType.dirty ||
                        refBranchType.touched ||
                        refBranchType.untouched)
                  }"
                  [(ngModel)]="BranchType"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Branch">Branch</option>
                  <option value="HO">HO</option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Select Zone </span>
                <select
                  name="SelectZoneId"
                  id="SelectZoneId"
                  #refSelectZoneId="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refSelectZoneId.invalid,
                    'alert-warning':
                      refSelectZoneId.invalid &&
                      (refSelectZoneId.dirty ||
                        refSelectZoneId.touched ||
                        refSelectZoneId.untouched)
                  }"
                  [(ngModel)]="SelectZoneId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Zone</option>
                  <option
                    *ngFor="let zoneListDropdown of ZoneListDropdown"
                    [value]="zoneListDropdown.ZoneId"
                  >
                    {{ zoneListDropdown.Zone_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Select State </span>
                <select
                  name="StateId"
                  id="StateId"
                  #refStateId="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refStateId.invalid,
                    'alert-warning':
                      refStateId.invalid &&
                      (refStateId.dirty ||
                        refStateId.touched ||
                        refStateId.untouched)
                  }"
                  [(ngModel)]="StateId"
                  (change)="onChangeState()"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select State</option>
                  <option
                    *ngFor="let stateList of StateList"
                    [value]="stateList.StateId"
                  >
                    {{ stateList.State_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Select District </span>
                <select
                  name="DistrictId"
                  id="DistrictId"
                  #refDistrictId="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refDistrictId.invalid,
                    'alert-warning':
                      refDistrictId.invalid &&
                      (refDistrictId.dirty ||
                        refDistrictId.touched ||
                        refDistrictId.untouched)
                  }"
                  [(ngModel)]="DistrictId"
                  (change)="onChangeDistrict()"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select District</option>
                  <option
                    *ngFor="let districtList of DistrictList"
                    [value]="districtList.DistrictId"
                  >
                    {{ districtList.District_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Select Tehsil </span>
                <select
                  name="TehsilId"
                  id="TehsilId"
                  #refTehsilId="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refTehsilId.invalid,
                    'alert-warning':
                      refTehsilId.invalid &&
                      (refTehsilId.dirty ||
                        refTehsilId.touched ||
                        refTehsilId.untouched)
                  }"
                  [(ngModel)]="TehsilId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Tehsil</option>
                  <option
                    *ngFor="let tehsilList of TehsilList"
                    [value]="tehsilList.TehsilId"
                  >
                    {{ tehsilList.Tehsil_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Address </span>
                <input
                  required
                  type="text"
                  #refAddress="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refAddress.invalid,
                    'alert-warning':
                      refAddress.invalid &&
                      (refAddress.dirty ||
                        refAddress.touched ||
                        refAddress.untouched)
                  }"
                  [(ngModel)]="Address"
                  placeholder="Address"
                  name="Address"
                  id="Address"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> LandMark </span>
                <input
                  required
                  type="text"
                  #refLandMark="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLandMark.invalid,
                    'alert-warning':
                      refLandMark.invalid &&
                      (refLandMark.dirty ||
                        refLandMark.touched ||
                        refLandMark.untouched)
                  }"
                  [(ngModel)]="LandMark"
                  placeholder="LandMark"
                  name="LandMark"
                  id="LandMark"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Pincode </span>
                <input
                  required
                  type="text"
                  numbersOnly
                  minlength="6"
                  maxlength="6"
                  #refPincode="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refPincode.invalid,
                    'alert-warning':
                      refPincode.invalid &&
                      (refPincode.dirty ||
                        refPincode.touched ||
                        refPincode.untouched)
                  }"
                  [(ngModel)]="Pincode"
                  placeholder="Pincode"
                  name="Pincode"
                  id="Pincode"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Phone No </span>
                <input
                  required
                  maxlength="10"
                  minlength="10"
                  numbersOnly
                  type="text"
                  #refPhoneNo="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refPhoneNo.invalid,
                    'alert-warning':
                      refPhoneNo.invalid &&
                      (refPhoneNo.dirty ||
                        refPhoneNo.touched ||
                        refPhoneNo.untouched)
                  }"
                  [(ngModel)]="PhoneNo"
                  placeholder="Phone No"
                  name="PhoneNo"
                  id="PhoneNo"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Branch Setup Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="BranchSetupDate"
                    id="BranchSetupDate"
                    #refBranchSetupDate="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refBranchSetupDate.invalid,
                      'alert-warning':
                        refBranchSetupDate.invalid &&
                        (refBranchSetupDate.dirty ||
                          refBranchSetupDate.touched ||
                          refBranchSetupDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="BranchSetupDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Select Branch Head </span>
                <select
                  name="BranchHead"
                  id="BranchHead"
                  #refBranchHead="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBranchHead.invalid,
                    'alert-warning':
                      refBranchHead.invalid &&
                      (refBranchHead.dirty ||
                        refBranchHead.touched ||
                        refBranchHead.untouched)
                  }"
                  [(ngModel)]="BranchHead"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Branch Head</option>
                  <option
                    *ngFor="let getEmployeeDropdown of GetEmployeeDropdown"
                    [value]="getEmployeeDropdown.EmpId"
                  >
                    {{ getEmployeeDropdown.EmpName }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Branch Rented/Owned </span>
                <select
                  name="Branch_Is_Rented"
                  id="Branch_Is_Rented"
                  #refBranch_Is_Rented="ngModel"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBranch_Is_Rented.invalid,
                    'alert-warning':
                      refBranch_Is_Rented.invalid &&
                      (refBranch_Is_Rented.dirty ||
                        refBranch_Is_Rented.touched ||
                        refBranch_Is_Rented.untouched)
                  }"
                  [(ngModel)]="BranchModel.Branch_Is_Rented"
                  (change)="onChangeBranchType()"
                  required
                >
                  <option value="">Select Branch Type</option>
                  <option value="1">Rented</option>
                  <option value="0">Owned</option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span>Email Id </span>
                <input
                  type="text"
                  #refEmailId="ngModel"
                  [(ngModel)]="EmailId"
                  placeholder="Phone No"
                  name="EmailId"
                  id="EmailId"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span>GST No.</span>
                <input
                  type="text"
                  #refGSTNo="ngModel"
                  [(ngModel)]="GSTNo"
                  placeholder="GST No."
                  name="GSTNo"
                  id="GSTNo"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="row m-0" *ngIf="BranchModel.Branch_Is_Rented == '1'">
              <div class="col-md-4 p-1">
                <span>Contract From</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker2"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Branch_ContractFrom"
                    id="Branch_ContractFrom"
                    [(ngModel)]="BranchModel.Branch_ContractFrom"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4 p-1">
                <span>Contract To</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker3"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Branch_ContractTo"
                    id="Branch_ContractTo"
                    [(ngModel)]="BranchModel.Branch_ContractTo"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4 p-1">
                <span>Landlord Name</span>
                <input
                  type="text"
                  name="Branch_Landlord_Name"
                  id="Branch_Landlord_Name"
                  [(ngModel)]="BranchModel.Branch_Landlord_Name"
                  placeholder="Landlord Name"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span>Landlord PAN</span>
                <input
                  type="text"
                  name="Branch_Landlord_PanNo"
                  id="Branch_Landlord_PanNo"
                  [(ngModel)]="BranchModel.Branch_Landlord_PanNo"
                  placeholder="Landlord PAN No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span>Monthly Rent</span>
                <input
                  type="text"
                  name="Branch_Monthly_Rent"
                  id="Branch_Monthly_Rent"
                  [(ngModel)]="BranchModel.Branch_Monthly_Rent"
                  placeholder="Monthly Rent"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 p-1">
                <span>Rent Due Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker4"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Branch_Rent_Due_Date"
                    id="Branch_Rent_Due_Date"
                    [(ngModel)]="BranchModel.Branch_Rent_Due_Date"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
              </div>
              <div class="col-md-4 p-1">
                <span>Payment in Favour Of</span>
                <input
                  type="text"
                  name="Branch_PaymentInFavourOf"
                  id="Branch_PaymentInFavourOf"
                  [(ngModel)]="BranchModel.Branch_PaymentInFavourOf"
                  placeholder="Payment In Favour Of"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-8 p-1">
                <span> Agreement PDF</span>
                <p class="m-0 mt-2 row">
                  <i
                    class="fa fa-upload ml-2"
                    *ngIf="!BranchModel.Branch_AgrrementDoc"
                    (click)="docFile.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="BranchModel.Branch_AgrrementDoc">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                      <a
                        href="{{ UploadImageBase64 }}"
                        target="_blank"
                        *ngIf="BranchModel.Branch_AgrrementDoc"
                      >
                        {{ BranchModel.Branch_AgrrementDoc }}
                      </a>
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFile(BranchModel, docFile)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docFile
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener(BranchModel, docFile.files)"
                  style="display: none"
                />
              </div>
            </div>
            <div class="row m-0">
              <div class="col-md-12 p-1">
                <span class="required-lable"> Remarks </span>
                <textarea
                  required
                  rows="3"
                  #refRemarks="ngModel"
                  maxlength="500"
                  [ngClass]="{
                    'is-invalid': f.submitted && refRemarks.invalid,
                    'alert-warning':
                      refRemarks.invalid &&
                      (refRemarks.dirty ||
                        refRemarks.touched ||
                        refRemarks.untouched)
                  }"
                  [(ngModel)]="Remarks"
                  placeholder="Remarks"
                  name="Remarks"
                  id="Remarks"
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span> Branch Geo Code </span>
              <label class="mx-3 ml-4"> lat: {{ lat }}, lng: {{ lng }} </label>

              <agm-map
                [latitude]="lat"
                [longitude]="lng"
                (mapClick)="mapClicked($event)"
              >
                <agm-marker
                  [latitude]="lat"
                  [longitude]="lng"
                  [animation]="animation"
                  (mouseOver)="markerOver()"
                  (mouseOut)="markerOut()"
                ></agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveBranchData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="DeleteBranchModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #dbf="ngForm" (ngSubmit)="onDeleteBranch()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Register New Branch
          </h6>
          <button
            type="button"
            (click)="OnCloseDeleteModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-white fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable"> Deletion Reason </span>
              <textarea
                required
                rows="3"
                #refDeletionReason="ngModel"
                [ngClass]="{
                  'is-invalid': dbf.submitted && refDeletionReason.invalid,
                  'alert-warning':
                    refDeletionReason.invalid &&
                    (refDeletionReason.dirty ||
                      refDeletionReason.touched ||
                      refDeletionReason.untouched)
                }"
                [(ngModel)]="DeletionReason"
                placeholder="Enter Deletion Reason"
                name="DeletionReason"
                id="DeletionReason"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseDeleteModal()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onDeleteBranch()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!dbf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
