import { Component, OnInit, ViewChild } from "@angular/core";
import { LmsService } from "../../_Lms/services/lms.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;
@Component({
  selector: "app-accured-interest",
  templateUrl: "./accured-interest.component.html",
  styleUrls: ["./accured-interest.component.scss"],
})
export class AccuredInterestComponent implements OnInit {
  showSpinner: boolean = false;
  reportDate: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  FyearId: any;
  maxDate: any;
  minDate: any;

  displayedColumns: string[];
  DataSource: any = [];
  JsonData: any[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Accured Interest");
    this.onSearchAccuredReport();
  }

  onSearchAccuredReport() {
    console.log("Clicked");
    this.showSpinner = true;
    this.DataSource = null;
    this.reportsService
      .Report_Accured_Interest({
        ReportDate: this.reportDate,
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
          this.displayedColumns = [
            "Id",
            "LoanAcNo",
            "Customer_Name",
            "Loan_Date",
            "Application_LoanAmount",
            "Application_FirstEMIDate",
            "Application_EMIType",
            "Interest_From",
            "Interest_To",
            "Days_Diff",
            "Interest",
            "Accured_Interest",
          ];
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.DataSource = null;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }
  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("accured-report") as HTMLTableElement;
    const worksheetName = "ACCURED REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
