<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #dff="ngForm"
    class="row m-0 col-md-12 p-0 formborder"
    (ngSubmit)="onSaveNote()"
    novalidate
  >
    <div class="col-md-4 p-1" *ngIf="TableId == 0">
      <span class="required-lable"> Type</span>
      <select
        name="Type"
        id="Type"
        [(ngModel)]="noteModel.Type"
        [disabled]="TableId > 0"
        (change)="ChangeType()"
        class="form-control input-text-css"
      >
        <option value="">Select Type</option>
        <option value="Notes">Customer Notes</option>
        <option value="Collection">Customer Collection Follow-Up</option>
        <option value="Follow-Up">Customer Other Follow-Up</option>
        <option value="Customer Request">Customer Request</option>
        <option value="Internal Request">Internal Request</option>
        <option value="Customer Task">Customer Task</option>
        <option value="Internal Task">Internal Task</option>
      </select>
    </div>

    <div class="col-md-6" *ngIf="TableId == 0">
      <app-customer-search
        [CustomerType]="LMS"
        [index]="0"
        (close)="GetData($event)"
      >
        <button
          type="button"
          [hidden]="!showBtn"
          [class.spinner]="loading"
          class="ml-4 mt-4 btn font-size-12 button-btn"
        >
          Search
        </button>
      </app-customer-search>
    </div>
    <div
      class="p-0 text-right"
      [ngClass]="AllData.length > 0 ? 'col-md-12' : 'col-md-2'"
    >
      <button
        type="button"
        (click)="OnNoteClose()"
        class="btn font-size-12 button-btn"
        [ngClass]="AllData.length == 0 ? 'mt-4' : 'mb-2'"
      >
        <i class="fa fa-undo"></i> Back
      </button>
    </div>

    <div class="row m-0 col-md-12 p-0" *ngIf="LoanId > 0 && ApplicationDetail">
      <div class="table-responsive mt-2">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th colspan="6" class="bg-color">Loan Detail:</th>
            </tr>
          </thead>
          <tr>
            <th><h6>Application No</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.ApplicationNo
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Application Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.ApplicationCreateOn
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Loan No:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.LoanAcNo
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th><h6>Loan Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Loan_Date
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Branch:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined ? ApplicationDetail.Branch : ""
                }}
              </h6>
            </td>
            <th><h6>Product:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Product
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th><h6>Loan Purpose:</h6></th>
            <td>
              {{
                ApplicationDetail != undefined
                  ? ApplicationDetail.LoanPurpose
                  : ""
              }}
            </td>
            <th><h6>Expiry Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.ExpiryDate
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Close Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.CloseDate
                    : ""
                }}
              </h6>
            </td>
          </tr>
        </table>
      </div>
      <div class="table-responsive mt-2">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th colspan="6" class="bg-color">Customer Detail:</th>
            </tr>
          </thead>
          <tr>
            <th><h6>#</h6></th>
            <th><h6>Name</h6></th>
            <th><h6>Type</h6></th>
            <th><h6>Gender/Age</h6></th>
            <th><h6>Phone No</h6></th>
            <th><h6>Present Address</h6></th>
          </tr>
          <tr *ngFor="let data of customerData; let i = index">
            <td>
              <h6>{{ i + 1 }}</h6>
            </td>
            <td>
              <h6>
                <app-lms-customer-view-model
                  [customerId]="data.CustomerId"
                  [index]="i"
                >
                  {{ data.Customer }}
                </app-lms-customer-view-model>
              </h6>
            </td>
            <td>
              <h6>
                {{
                  data.CustomerType == "Hirer" ? "Borrower" : data.CustomerType
                }}
              </h6>
            </td>
            <td>
              <h6>{{ data.GenderAge }}</h6>
            </td>
            <td>
              <h6 *ngIf="currentUser.IsMasking">
                {{ data.PhoneNo | mask }}
              </h6>

              <h6 *ngIf="!currentUser.IsMasking">
                {{ data.PhoneNo }}
              </h6>
            </td>
            <td>
              <h6>{{ data.Present_Address }}</h6>
            </td>
          </tr>
        </table>
      </div>

      <div class="table-responsive mt-2">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th colspan="6" class="bg-color">Finance Detail:</th>
            </tr>
          </thead>
          <tr>
            <th><h6>Asset Cost:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.AssetCost.toFixed(2)
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Net Finance:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.NetFinance.toFixed(2)
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Interest Amount:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.InterestAmt.toFixed(2)
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th><h6>Agreemnent Value:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.AgreementValue.toFixed(2)
                    : ""
                }}
              </h6>
            </td>
            <th><h6>LTV:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined ? ApplicationDetail.LTV : ""
                }}
                %
              </h6>
            </td>
            <th><h6>Margin:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Margin.toFixed(2)
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th>
              <h6>Tenure ({{ Tenure_Name }}):</h6>
            </th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined ? ApplicationDetail.Tenure : ""
                }}
                {{ Tenure_Name }}
              </h6>
            </td>
            <th><h6>No of Installment:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.No_Of_Instl
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Adv. Installment:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Adv_Instl
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th><h6>ROI:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Flat_Rate
                    : ""
                }}
                %
              </h6>
            </td>
            <th><h6>Case IRR:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.Case_IRR
                    : ""
                }}
                %
              </h6>
            </td>
            <th><h6>First EMI Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.FirstEMIDate
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th><h6>Expiry Date:</h6></th>
            <td>
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.ExpiryDate
                    : ""
                }}
              </h6>
            </td>
            <th><h6>Close Date:</h6></th>
            <td colspan="3">
              <h6>
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.CloseDate
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr *ngIf="IRR_CalculateBy == 'STEP_EMI'">
            <td colspan="6">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-color">Step EMI Detail:</th>
                  </tr>
                </thead>
                <tr>
                  <th class="text-right"><h6>#</h6></th>
                  <th class="text-right"><h6>From EMI</h6></th>
                  <th class="text-right"><h6>To EMI</h6></th>
                  <th class="text-right"><h6>EMI Amount</h6></th>
                  <th class="text-right"><h6>No of EMI</h6></th>
                  <th class="text-right"><h6>Total Amount</h6></th>
                </tr>
                <tr *ngFor="let StepData of stepEMI; let i = index">
                  <td>
                    <h6>{{ i + 1 }}</h6>
                  </td>
                  <td class="text-right">
                    <h6 class="form-control1">{{ StepData.FromEMI }}</h6>
                  </td>
                  <td class="text-right">
                    <h6 class="form-control1">{{ StepData.ToEMI }}</h6>
                  </td>
                  <td class="text-right">
                    <h6 class="form-control1">{{ StepData.EMI_Amount }}</h6>
                  </td>
                  <td class="text-right">
                    <h6 class="form-control1">{{ StepData.NoOfInstl }}</h6>
                  </td>
                  <td class="text-right">
                    <h6 class="form-control1">{{ StepData.TotalAmount }}</h6>
                  </td>
                </tr>
                <tr>
                  <th colspan="4" class="text-right">
                    <h6><B>Total</B></h6>
                  </th>
                  <th class="text-right">
                    <h6 class="form-control1">
                      <B>{{ stepEmiData.totalEmi }}</B>
                    </h6>
                  </th>
                  <th class="text-right">
                    <h6 class="form-control1">
                      <B>{{ stepEmiData.totalAmount }}</B>
                    </h6>
                  </th>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

      <div class="table-responsive mt-2">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th colspan="8" class="bg-color">
                <h5><B>EMI Detail:</B></h5>
              </th>
            </tr>
          </thead>
          <tr>
            <th>&nbsp;</th>
            <th class="text-center"><h6>Total EMI</h6></th>
            <th class="text-center"><h6>Mature EMI</h6></th>
            <th class="text-center"><h6>Remaining EMI</h6></th>
            <th class="text-center"><h6>Received EMI</h6></th>
            <th class="text-center"><h6>Overdue EMI</h6></th>
          </tr>
          <tr>
            <th class="text-center"><h6>EMI</h6></th>
            <td class="text-center">
              <h6>{{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}</h6>
            </td>
            <td class="text-center">
              <h6>{{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}</h6>
            </td>
            <td class="text-center">
              <h6>
                {{ EMIDetails != undefined ? EMIDetails.Remaining_EMI : "" }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                {{ EMIDetails != undefined ? EMIDetails.Received_EMI : "" }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                {{
                  EMIDetails != undefined
                    ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
                    : ""
                }}
              </h6>
            </td>
          </tr>
          <tr>
            <th class="text-center"><h6>Amount</h6></th>
            <td class="text-center">
              <h6>
                &#8377;
                {{ EMIDetails != undefined ? EMIDetails.TotalEMI_Amount : "" }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                &#8377;
                {{ EMIDetails != undefined ? EMIDetails.MatureEMI_Amount : "" }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                &#8377;
                {{
                  EMIDetails != undefined ? EMIDetails.Remaining_EMI_Amount : ""
                }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                &#8377;
                {{
                  EMIDetails != undefined ? EMIDetails.Received_EMI_Amount : ""
                }}
              </h6>
            </td>
            <td class="text-center">
              <h6>
                &#8377;
                {{
                  EMIDetails != undefined
                    ? EMIDetails.MatureEMI_Amount -
                      EMIDetails.Received_EMI_Amount
                    : ""
                }}
              </h6>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="mt-2 row m-0 col-md-12 p-0 formborder"
      *ngIf="AddForm && (TableId == undefined || TableId == 0)"
    >
      <div class="col-md-3 p-1" *ngIf="noteModel.Type != 'Notes'">
        <span> Select Department</span>
        <select
          name="Department"
          id="Department"
          required
          [disabled]="noteModel.Type == 'Collection'"
          #refDepartment="ngModel"
          (change)="Get_Employee_ByBranch_Department()"
          [(ngModel)]="noteModel.Department"
          placeholder="Department"
          [ngClass]="{
            'is-invalid': dff.submitted && refDepartment.invalid,
            'alert-warning':
              refDepartment.invalid &&
              (refDepartment.dirty ||
                refDepartment.touched ||
                refDepartment.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Department</option>
          <option *ngFor="let cat of DepartmentList" [value]="cat.DeptId">
            {{ cat.Dept_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1" *ngIf="noteModel.Type == 'Collection'">
        <span>Visit Location</span>
        <select
          name="AddressType"
          id="AddressType"
          [(ngModel)]="noteModel.AddressType"
          placeholder="Address Type"
          #refAddressType="ngModel"
          required
          [ngClass]="{
            'is-invalid': dff.submitted && refAddressType.invalid,
            'alert-warning':
              refAddressType.invalid &&
              (refAddressType.dirty ||
                refAddressType.touched ||
                refAddressType.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Visit Location</option>
          <option value="Home">Home Address</option>
          <option value="Work">Work Address</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="col-md-3 p-1" *ngIf="noteModel.Type == 'Collection'">
        <span>Customer</span>
        <select
          name="Customer"
          id="Customer"
          required
          #refCustomer="ngModel"
          [(ngModel)]="noteModel.Customer"
          placeholder="Customer"
          [ngClass]="{
            'is-invalid': dff.submitted && refCustomer.invalid,
            'alert-warning':
              refCustomer.invalid &&
              (refCustomer.dirty ||
                refCustomer.touched ||
                refCustomer.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Customer</option>
          <option *ngFor="let cat of customerData" [value]="cat.Customer">
            {{ cat.Customer }}
          </option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">
          {{
            noteModel.Type == "Collection" ? "Default Reason" : "Request Title"
          }}
        </span>
        <input
          type="text"
          #refDocTitle="ngModel"
          [disabled]="TableId > 0"
          *ngIf="
            noteModel.Type != 'Customer Request' &&
            noteModel.Type != 'Collection'
          "
          [(ngModel)]="noteModel.Title"
          placeholder="Title"
          [ngClass]="{
            'is-invalid': dff.submitted && refDocTitle.invalid,
            'alert-warning':
              refDocTitle.invalid &&
              (refDocTitle.dirty ||
                refDocTitle.touched ||
                refDocTitle.untouched)
          }"
          name="DocTitle"
          id="DocTitle"
          class="form-control input-text-css"
          required
        />

        <select
          name="Title"
          id="Title"
          #refTitle="ngModel"
          required
          *ngIf="
            noteModel.Type == 'Customer Request' ||
            noteModel.Type == 'Collection'
          "
          [(ngModel)]="noteModel.Title"
          placeholder="Title"
          [ngClass]="{
            'is-invalid': dff.submitted && refTitle.invalid,
            'alert-warning':
              refTitle.invalid &&
              (refTitle.dirty || refTitle.touched || refTitle.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Request</option>
          <option *ngFor="let cat of RequestList" [value]="cat.Value">
            {{ cat.Value }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1" *ngIf="noteModel.Type == 'Collection'">
        <span class="required-lable">Customer Response</span>

        <select
          name="CustomerResponse"
          id="CustomerResponse"
          #refCustomerResponse="ngModel"
          required
          [(ngModel)]="noteModel.CustomerResponse"
          placeholder="CustomerResponse"
          [ngClass]="{
            'is-invalid': dff.submitted && refCustomerResponse.invalid,
            'alert-warning':
              refCustomerResponse.invalid &&
              (refCustomerResponse.dirty ||
                refCustomerResponse.touched ||
                refCustomerResponse.untouched)
          }"
          (change)="CustomerResponseChange()"
          class="form-control input-text-css"
        >
          <option value="">Select Customer Response</option>
          <option *ngFor="let cat of CustomerResponseList" [value]="cat.Value">
            {{ cat.Value }}
          </option>
        </select>
      </div>
      <div
        class="p-1"
        *ngIf="noteModel.Type == 'Collection' && IsShowFollowup"
        [ngClass]="noteModel.Type == 'Collection' ? 'col-md-2' : 'col-md-3'"
      >
        <span class="required-lable"> Followup By</span>
        <input
          type="text"
          disabled
          [(ngModel)]="ApplicationDetail.CollectionExecutiveName"
          placeholder="Title"
          name="CollectionExecutiveName1"
          id="CollectionExecutiveName1"
          class="form-control input-text-css"
        />
      </div>
      <div
        class="p-1"
        *ngIf="
          noteModel.Type != 'Notes' &&
          noteModel.Type != 'Collection' &&
          IsShowFollowup
        "
        [ngClass]="
          noteModel.Type == 'Collection' || noteModel.Type == 'Follow-Up'
            ? 'col-md-2'
            : 'col-md-3'
        "
      >
        <span> Followup By </span>
        <select
          name="FollowupBy"
          id="FollowupBy"
          required
          [(ngModel)]="noteModel.FollowupBy"
          #refFollowupBy="ngModel"
          [ngClass]="{
            'is-invalid': dff.submitted && refFollowupBy.invalid,
            'alert-warning':
              refFollowupBy.invalid &&
              (refFollowupBy.dirty ||
                refFollowupBy.touched ||
                refFollowupBy.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Followup By</option>
          <option *ngFor="let cat of FollowupByList" [value]="cat.EmpId">
            {{ cat.EmpName }}
          </option>
        </select>
      </div>
      <div
        class="p-1"
        *ngIf="
          IsShowFollowup &&
          (noteModel.Type == 'Follow-Up' || noteModel.Type == 'Collection')
        "
        [ngClass]="
          noteModel.Type == 'Collection' || noteModel.Type == 'Follow-Up'
            ? 'col-md-2'
            : 'col-md-3'
        "
      >
        <span class="">Followup Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Voucher_Date"
            id="Voucher_Date"
            #refVoucher_Date="ngModel"
            [min]="today"
            [ngClass]="{
              'is-invalid': dff.submitted && refVoucher_Date.invalid,
              'alert-warning':
                refVoucher_Date.invalid &&
                (refVoucher_Date.dirty ||
                  refVoucher_Date.touched ||
                  refVoucher_Date.untouched)
            }"
            class="form-control input-text-css"
            [(ngModel)]="noteModel.FollowupDate"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div
        class="p-1"
        *ngIf="noteModel.Type != 'Notes'"
        [ngClass]="
          noteModel.Type == 'Collection' || noteModel.Type == 'Follow-Up'
            ? 'col-md-2'
            : 'col-md-3'
        "
      >
        <span>Priority</span>
        <select
          name="Priority"
          id="Priority"
          required
          [(ngModel)]="noteModel.Priority"
          #refPriority="ngModel"
          [ngClass]="{
            'is-invalid': dff.submitted && refPriority.invalid,
            'alert-warning':
              refPriority.invalid &&
              (refPriority.dirty ||
                refPriority.touched ||
                refPriority.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Priority</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
          <option value="Urgent">Urgent</option>
        </select>
      </div>
      <div class="col-md-8 p-1">
        <span> Remark </span>
        <textarea
          rows="3"
          #refRemark="ngModel"
          [(ngModel)]="noteModel.Remark"
          placeholder=" Remark "
          [ngClass]="{
            'is-invalid': dff.submitted && refRemark.invalid,
            'alert-warning':
              refRemark.invalid &&
              (refRemark.dirty || refRemark.touched || refRemark.untouched)
          }"
          name="Remark"
          id="Remark"
          class="form-control input-text-css"
        ></textarea>
      </div>
    </div>

    <div
      *ngIf="AllData.length > 0 && AllData[0].Status == 'Completed'"
      class="row m-0 col-md-12 p-0"
    >
      <ng-container>
        <div
          class="row m-0 h-b w-b align-items-center justify-content-between"
          style="width: 100%"
        >
          <h1 class="fs-12 m-0">
            <b style="color: #ffff !important">
              {{ AllData[0].Status }}
              {{
                AllData[0].Priority ? "" : "(" + AllData[0].Priority + ")"
              }}</b
            >
          </h1>
        </div>
        <div class="row m-0 col-md-12 p-0">
          <div class="col-md-2">
            <h6 class="fs-12">Type</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].Type }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Title</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].Title }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Created By</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].CreatedBy }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Created On</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].CreatedOn }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Completed By</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].FollowupBy_Name }}</p>
          </div>
          <div
            class="col-md-2"
            *ngIf="
              AllData[0].Type == 'Follow-Up' || AllData[0].Type == 'Collection'
            "
          >
            <h6 class="fs-12">Followup Date</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].FollowupDate }}</p>
          </div>

          <div class="col-md-2" *ngIf="AllData[0].Status == 'Completed'">
            <h6 class="fs-12">Completed On</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].CompletedOn }}</p>
          </div>

          <div class="col-md-2" *ngIf="AllData[0].Status == 'Completed'">
            <h6 class="fs-12">Completed Remark</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].Completed_Remark }}</p>
          </div>

          <div class="col-md-2" *ngIf="this.noteModel.Type == 'Collection'">
            <h6 class="fs-12">Visit Location</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].AddressType }}</p>
          </div>

          <div class="col-md-2" *ngIf="this.noteModel.Type == 'Collection'">
            <h6 class="fs-12">Customer</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].Customer }}</p>
          </div>

          <div class="col-md-2" *ngIf="this.noteModel.Type == 'Collection'">
            <h6 class="fs-12">Customer Response</h6>
            <p class="lead fs-12 fw-7">{{ AllData[0].CustomerResponse }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-0">
          <div class="row mt-2" *ngIf="AllData[0].Status == 'Completed'">
            <div class="col-md-12 mt-2 upload" *ngIf="Attachment.length > 0">
              <b> Attachments: </b>
              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of Attachment; let i = index"
              >
                <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{ report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a
                      href="{{ OthersDocUrl }}/{{ report.DocFileName }}"
                      target="_blank"
                      *ngIf="report.DocFileName"
                      >{{ report.DocFileName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row m-0 col-md-12 p-0">
      <ng-container *ngFor="let data of AllData">
        <div
          class="row m-0 h-b w-b align-items-center justify-content-between"
          style="width: 100%"
        >
          <h1 class="fs-12 m-0">
            <b style="color: #000000 !important"
              >{{ data.Process }}
              {{
                data.Priority == null
                  ? ""
                  : data.Priority == ""
                  ? ""
                  : "(" + data.Priority + ")"
              }}</b
            >
          </h1>
        </div>

        <div class="row m-0 col-md-12 p-0">
          <div class="col-md-2">
            <h6 class="fs-12">Type</h6>
            <p class="lead fs-12 fw-7">{{ data.Type }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Title</h6>
            <p class="lead fs-12 fw-7">{{ data.Title }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Created By</h6>
            <p class="lead fs-12 fw-7">{{ data.CreatedBy }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Created On</h6>
            <p class="lead fs-12 fw-7">{{ data.CreatedOn }}</p>
          </div>
          <div class="col-md-2" *ngIf="data.Type == 'Collection'">
            <h6 class="fs-12">Visit Location</h6>
            <p class="lead fs-12 fw-7">{{ data.AddressType }}</p>
          </div>
          <div class="col-md-2" *ngIf="data.Type == 'Collection'">
            <h6 class="fs-12">Customer</h6>
            <p class="lead fs-12 fw-7">{{ data.Customer }}</p>
          </div>
          <div class="col-md-2" *ngIf="data.Type == 'Collection'">
            <h6 class="fs-12">Customer Response</h6>
            <p class="lead fs-12 fw-7">{{ data.CustomerResponse }}</p>
          </div>

          <div class="col-md-2">
            <h6 class="fs-12">Followup By</h6>
            <p class="lead fs-12 fw-7">{{ data.FollowupBy_Name }}</p>
          </div>
          <div
            class="col-md-2"
            *ngIf="data.Type == 'Follow-Up' || data.Type == 'Collection'"
          >
            <h6 class="fs-12">Followup Date</h6>
            <p class="lead fs-12 fw-7">{{ data.FollowupDate }}</p>
          </div>
          <div class="col-md-2" *ngIf="data.Status == 'Forwarded'">
            <h6 class="fs-12">Forwarded On</h6>
            <p class="lead fs-12 fw-7">{{ data.CompletedOn }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Remarks</h6>
            <p class="lead fs-12 fw-7">{{ data.Remarks }}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <!--<div class="row m-0 mt-3" *ngIf="TableId> 0">
    <div class="col-md-4 p-1 border-div"><span class="bold"> Type</span></div>
    <div class="col-md-8 p-1 border-div">{{ ActionData.Type }}</div>
    <div class="col-md-4 p-1 border-div"><span class="bold"> Title</span></div>
    <div class="col-md-8 p-1 border-div">{{ ActionData.Title }}</div>
    <div class="col-md-4 p-1 border-div"><span class="bold"> Remark</span></div>
    <div class="col-md-8 p-1 border-div">{{ ActionData.Remarks }}</div>
    <div class="col-md-4 p-1 border-div"><span class="bold"> Followup By</span></div>
    <div class="col-md-8 p-1 border-div">{{ ActionData.FollowupBy_Name }}</div>
    <div class="col-md-4 p-1 border-div" *ngIf="noteModel.Type=='Follow-Up' || noteModel.Type=='Collection'">
      <span class="bold"> Follow-up Date</span>
    </div>
    <div class="col-md-8 p-1 border-div" *ngIf="noteModel.Type=='Follow-Up' || noteModel.Type=='Collection'">
      {{ ActionData.Date }}
    </div>
  </div>-->

    <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
      <!--<button type="button" (click)="OnNoteClose()" style="background-color: #28a745; color: #ffffff; font-size: 12px;" class="btn btn-light font-size-12 mr-3">
        Back
      </button>-->
      <button
        type="button"
        (click)="onCompleted()"
        class="mr-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
        *ngIf="
          TableId > 0 &&
          AllData.length > 0 &&
          AllData[0].Status == 'Pending' &&
          (AllData[0].FollowupBy == currentUser.userId ||
            AllData[0].CreatedById == currentUser.userId)
        "
      >
        Complete
      </button>
      <button
        type="button"
        (click)="onForworded()"
        class="mr-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
        *ngIf="
          TableId > 0 &&
          AllData.length > 0 &&
          AllData[0].Status == 'Pending' &&
          (AllData[0].FollowupBy == currentUser.userId ||
            AllData[0].CreatedById == currentUser.userId)
        "
      >
        Forward
      </button>

      <button
        type="button"
        (click)="onSaveNote()"
        class="mr-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!dff.form.valid"
        *ngIf="AddForm && (TableId == undefined || TableId == 0)"
      >
        Save
      </button>

      <!--<button type="button" (click)="onUpdateNotesRecom()" style="background-color: #28a745; color: #ffffff; font-size: 12px;"
            mat-raised-button color="primary" [class.spinner]="loading" [disabled]="!df.form.valid" *ngIf="TableId > 0">
      Update
    </button>-->
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="noteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          CRM Task & Request
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1" *ngIf="noteModel">
          <form
            #df="ngForm"
            class="row m-0 col-md-12 p-0"
            (ngSubmit)="onSaveForworded()"
            novalidate
          >
            <div class="row m-0 mt-3" *ngIf="TableId > 0">
              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Type</span>
              </div>
              <div class="col-md-8 p-1 border-div">{{ ActionData.Type }}</div>

              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Title</span>
              </div>
              <div class="col-md-8 p-1 border-div">{{ ActionData.Title }}</div>

              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Remark</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.Remarks }}
              </div>

              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Followup By</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.FollowupBy_Name }}
              </div>

              <div
                class="col-md-4 p-1 border-div"
                *ngIf="
                  noteModel.Type == 'Follow-Up' ||
                  noteModel.Type == 'Collection'
                "
              >
                <span class="bold"> Follow-up Date</span>
              </div>
              <div
                class="col-md-8 p-1 border-div"
                *ngIf="
                  noteModel.Type == 'Follow-Up' ||
                  noteModel.Type == 'Collection'
                "
              >
                {{ ActionData.FollowupDate }}
              </div>
            </div>
            <div class="row m-0 mt-3" *ngIf="TableId > 0">
              <div class="col-md-12 p-1">
                <span class="required-lable"> Type</span>
                <select
                  name="updateType"
                  id="updateType"
                  [(ngModel)]="updateModel.Type"
                  (change)="Get_FolluwuBy_Department()"
                  class="form-control input-text-css"
                  required
                  #refupdate="ngModel"
                  placeholder="Department"
                  [ngClass]="{
                    'is-invalid': df.submitted && refupdate.invalid,
                    'alert-warning':
                      refupdate.invalid &&
                      (refupdate.dirty ||
                        refupdate.touched ||
                        refupdate.untouched)
                  }"
                >
                  <option
                    *ngIf="
                      noteModel.Type == 'Follow-Up' ||
                      noteModel.Type == 'Collection'
                    "
                    value="Collection"
                  >
                    Collection Follow-Up
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Follow-Up' ||
                      noteModel.Type == 'Collection'
                    "
                    value="Follow-Up"
                  >
                    Other Follow-Up
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Customer Task' ||
                      noteModel.Type == 'Customer Request'
                    "
                    value="Customer Request"
                  >
                    Customer Request
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Customer Task' ||
                      noteModel.Type == 'Customer Request'
                    "
                    value="Customer Task"
                  >
                    Customer Task
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Internal Task' ||
                      noteModel.Type == 'Internal Request'
                    "
                    value="Internal Request"
                  >
                    Internal Request
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Internal Task' ||
                      noteModel.Type == 'Internal Request'
                    "
                    value="Internal Task"
                  >
                    Internal Task
                  </option>
                </select>
              </div>
              <div class="col-md-12 p-1">
                <span> Select Department</span>
                <select
                  name="updateDepartment"
                  id="updateDepartment"
                  [disabled]="noteModel.Type == 'Collection'"
                  [(ngModel)]="updateModel.Department"
                  placeholder="Department"
                  class="form-control input-text-css"
                >
                  <option value="0">Select Department</option>
                  <option
                    *ngFor="let cat of DepartmentList"
                    [value]="cat.DeptId"
                  >
                    {{ cat.Dept_Name }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 p-1">
                <span class="required-lable"> Title</span>
                <input
                  type="text"
                  disabled
                  [(ngModel)]="updateModel.Title"
                  placeholder="Title"
                  name="updateDocTitle"
                  id="updateDocTitle"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-12 p-1">
                <span> Remark </span>
                <textarea
                  rows="3"
                  #refupdateRemark="ngModel"
                  [(ngModel)]="updateModel.Remark"
                  placeholder=" Remark "
                  [ngClass]="{
                    'is-invalid': df.submitted && refupdateRemark.invalid,
                    'alert-warning':
                      refupdateRemark.invalid &&
                      (refupdateRemark.dirty ||
                        refupdateRemark.touched ||
                        refupdateRemark.untouched)
                  }"
                  name="updateRemark"
                  id="updateRemark"
                  class="form-control input-text-css"
                ></textarea>
              </div>

              <div
                class="col-md-12 p-1"
                *ngIf="updateModel.Type == 'Collection'"
              >
                <span class="required-lable"> Followup By</span>
                <input
                  type="text"
                  disabled
                  [(ngModel)]="ApplicationDetail.CollectionExecutiveName"
                  placeholder="Title"
                  name="CollectionExecutiveName"
                  id="CollectionExecutiveName"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-12 p-1"
                *ngIf="
                  updateModel.Type != 'Notes' &&
                  updateModel.Type != 'Collection'
                "
              >
                <span> Followup By </span>
                <select
                  name="updateFollowupBy"
                  id="updateFollowupBy"
                  required
                  [(ngModel)]="updateModel.FollowupBy"
                  #refupdateFollowupBy="ngModel"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': df.submitted && refupdateFollowupBy.invalid,
                    'alert-warning':
                      refupdateFollowupBy.invalid &&
                      (refupdateFollowupBy.dirty ||
                        refupdateFollowupBy.touched ||
                        refupdateFollowupBy.untouched)
                  }"
                >
                  <option value="">Followup By</option>
                  <option
                    *ngFor="let cat of FollowupByList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-12 p-1"
                *ngIf="
                  noteModel.Type == 'Follow-Up' ||
                  noteModel.Type == 'Collection'
                "
              >
                <span class="">Followup Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [min]="today"
                    name="updateFollowupDate"
                    id="updateFollowupDate"
                    #refupdateFollowupDate="ngModel"
                    [(ngModel)]="updateModel.FollowupDate"
                    required
                    [ngClass]="{
                      'is-invalid':
                        df.submitted && refupdateFollowupDate.invalid,
                      'alert-warning':
                        refupdateFollowupDate.invalid &&
                        (refupdateFollowupDate.dirty ||
                          refupdateFollowupDate.touched ||
                          refupdateFollowupDate.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="OnClose()"
                class="btn font-size-12 button-btn mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onSaveForworded()"
                class="mr-3 btn font-size-12 button-btn"
                [disabled]="!df.form.valid"
                [class.spinner]="loading"
              >
                Forward
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="CompletedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          CRM Task & Request
        </h6>
        <button
          type="button"
          (click)="OnCompletedModalClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1">
          <ng-container>
            <form
              #df="ngForm"
              class="row m-0 col-md-12 p-0"
              (ngSubmit)="OnCompletedDone()"
            >
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Remark</span>
                  <textarea
                    rows="3"
                    #RefCompletedRemark="ngModel"
                    required
                    [(ngModel)]="CompletedRemark"
                    placeholder=" Remark "
                    [ngClass]="{
                      'is-invalid': df.submitted && RefCompletedRemark.invalid,
                      'alert-warning':
                        RefCompletedRemark.invalid &&
                        (RefCompletedRemark.dirty ||
                          RefCompletedRemark.touched ||
                          RefCompletedRemark.untouched)
                    }"
                    name="CompletedRemark"
                    id="CompletedRemark"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
                <div class="col-md-12 mt-2 upload">
                  <B> Upload Docs/Images </B>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="
                      fileChangeListenerAttachment(docFile.files, docFile)
                    "
                    style="display: none"
                  />
                  <div
                    class="row m-0 mt-2 align-items-center"
                    *ngFor="let report of Attachment; let i = index"
                  >
                    <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        placeholder="Title"
                        name="title{{ i }}"
                        id="title{{ i }}"
                        #refTitle="ngModel"
                        [(ngModel)]="report.Title"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <p class="m-0 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!report.DocFileName"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="report.DocFileName">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ report.DocFileName }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFileAttachment(i)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
                  <button
                    type="button"
                    (click)="OnNoteClose()"
                    class="btn btn-light font-size-12 mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    (click)="OnCompletedDone()"
                    class="mr-3 btn font-size-12 button-btn"
                    [disabled]="!df.form.valid"
                    [class.spinner]="loading"
                  >
                    Complete
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
