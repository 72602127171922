import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-mstemployeeswap",
  templateUrl: "./mstemployeeswap.component.html",
  styleUrls: ["./mstemployeeswap.component.scss"],
})
export class MstemployeeswapComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetSwapEmployeeRequestModel: RequestModel = new RequestModel();
  SaveSwapEmployeeRequestModel: RequestModel = new RequestModel();
  GetBranchDetailRequestModel: RequestModel = new RequestModel();
  GetEmployeeDropdownRequestModel: RequestModel = new RequestModel();
  FilterType: any = "QuickLoan";
  dataSource: any;
  displayedColumns: string[] = [
    "APId",
    "ApplicationNo",
    "Branch",
    "LoanProduct",
    "Process",
    "AssignedOn",
    "Status",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading: boolean = false;
  FilterEmployeeId: any = "";
  EmployeeList: any = [];
  GetEmployeeDropdown: any = [];
  PendingList: any = [];
  dialogreturn: any;
  // swap employee
  AssignedToEmployee: any = "";
  APId: any = 0;
  employeeSwapDropdown: any = [];

  constructor(
    private _Route: Router,
    private dialog: DialogService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    //  sessionStorage.removeItem('getEmployeeID');
  }
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Employee Swap on Application");
    this.GetEmployeeDropdownRequestModel.BranchId = 0;
    this.GetEmployeeDropdownRequestModel.ProcessId = 0;
    this._MasterService
      .GetEmployeeDropdown(this.GetEmployeeDropdownRequestModel)
      .subscribe((result) => {
        this.GetEmployeeDropdown = JSON.parse(JSON.stringify(result));
        this.FilterEmployeeId =
          this.GetEmployeeDropdown.length > 1
            ? this.GetEmployeeDropdown[0].EmpId
            : "";
        this.onselectemployee();
      });
  }

  onselectemployee() {
    if (this.FilterEmployeeId == "") {
      this.dataSource = [];
      return;
    }

    if (this.FilterType == "LOS") {
      this.showSpinner = true;
      this.displayedColumns = [
        "APId",
        "ApplicationNo",
        "Branch",
        "LoanProduct",
        "Process",
        "AssignedOn",
        "Status",
        "EditAction",
      ];

      this.RequestModel.EmpId = parseInt(this.FilterEmployeeId);
      this._MasterService
        .LOSGetPendingApplicationForSWAP(this.RequestModel)
        .subscribe((result) => {
          this.PendingList = JSON.parse(JSON.stringify(result));
          this.dataSource = new MatTableDataSource(this.PendingList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        });
    } else {
      this.showSpinner = true;

      this.RequestModel.EmpId = parseInt(this.FilterEmployeeId);
      this._MasterService
        .Quick_Loan_Get_Pending_Application_ForSWAP(this.RequestModel)
        .subscribe((result) => {
          this.PendingList = JSON.parse(JSON.stringify(result));
          this.dataSource = new MatTableDataSource(this.PendingList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        });
    }
  }

  onOpenUpdateSwapModal(selectedRow) {
    this.APId = selectedRow.APId;
    this.GetSwapEmployeeRequestModel.EmpId = selectedRow.AP_EmpId;
    this.GetSwapEmployeeRequestModel.ProcessId = selectedRow.AP_ProcessId;
    this.GetSwapEmployeeRequestModel.BranchId =
      selectedRow.Application_BranchId;
    this.GetSwapEmployeeRequestModel.LoanId = selectedRow.Loan_Id;

    if (this.FilterType == "LOS") {
      this._MasterService
        .LOS_GetEmployeeForSWAP(this.GetSwapEmployeeRequestModel)
        .subscribe((result) => {
          this.employeeSwapDropdown = JSON.parse(JSON.stringify(result));
        });
    } else {
      this._MasterService
        .QuickLoan_Employee_Assignment_Dropdown({
          ProcessId: this.GetSwapEmployeeRequestModel.ProcessId,
          BranchId: this.GetSwapEmployeeRequestModel.BranchId,
          LoanId: this.GetSwapEmployeeRequestModel.LoanId,
        })
        .subscribe((res: any) => {
          this.employeeSwapDropdown = JSON.parse(JSON.stringify(res));
        });
    }
    $("#employeeSwap").modal("show");
    $("#employeeSwap").css("z-index", "1050");
  }

  OnClose() {
    this.AssignedToEmployee = "";
    this.APId = 0;
    $("#employeeSwap").modal("hide");
  }

  onSaveSwapData() {
    this.SaveSwapEmployeeRequestModel.APId = this.APId;
    this.SaveSwapEmployeeRequestModel.AssignedTo = parseInt(
      this.AssignedToEmployee
    );
    this.SaveSwapEmployeeRequestModel.LoginUserId = this.data.userId;
    if (this.FilterType == "LOS") {
      this._MasterService
        .LOS_SaveUserApplicationSwap(this.SaveSwapEmployeeRequestModel)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
            this.onselectemployee();
            this.OnClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this._MasterService
        .QuickLoan_SaveUserApplicationSwap(this.SaveSwapEmployeeRequestModel)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
            this.onselectemployee();
            this.OnClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
