import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from '../../Shared/dialog.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from "../../_Lms/services/lms.service";

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { UntypedFormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
declare var $: any;


@Component({
  selector: 'app-lms-lending-assignment',
  templateUrl: './lms-lending-assignment.component.html',
  styleUrls: ['./lms-lending-assignment.component.scss']
})
export class LmsLendingAssignmentComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  ShowFilter = true;
  dataSource: any;
  displayedColumns: string[] = [];
  selectedId: any;
  SelectAll: any;
  SelectChk: any;
  JsonData: any[] = [];
  IsSelected: boolean = false;
  AssignTo: any = '';

  BranchForm: UntypedFormGroup;
  ProductForm: UntypedFormGroup;

  BrancheDropdown: any = [];
  ProductDropdown: any = [];

  Lending_Dropdown: any = [];

  Branches: any;
  BranchDropdownSettings: any = {};

  Products: any;
  ProductDropdownSettings: any = {};

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(paginator: MatPaginator) {
    if (paginator != undefined) {
      this.dataSource.paginator = paginator;
    }
  }
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  LendingModel: any = {};

  chhead: any = '';
  chhead1: any = '';
  AccountControl = new UntypedFormControl();
  AccountControl1 = new UntypedFormControl();
  AccountHead: any;
  AccountHeadDropdown: any[] = [];
  filteredAccountHead: Observable<any[]>;
  filteredAccountHead1: Observable<any[]>;

  BranchesId: any = '';
  ProductIds: any = '';


  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private lmsService: LmsService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Lending Assignment");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getBranchesList();
    this.getProductDropdown();
    this.Get_LendingList();
    this.getAccounthead();
    this.getAccounthead1();
    
    // Branch Settings
    this.BranchForm = this.fb.group({
      Branches: []
    });
    this.BranchDropdownSettings = {
      singleSelection: false,
      idField: 'BranchId',
      textField: 'Branch_Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: this.ShowFilter
    };

    // Product Settings
    this.ProductForm = this.fb.group({
      Products: []
    });
    this.ProductDropdownSettings = {
      singleSelection: false,
      idField: 'ProductId',
      textField: 'Product',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: this.ShowFilter
    };

    this.displayedColumns = [
      "Select",  "LoanAcNo", "Application_No", "Loan_Date", "ExpiryDate", "LoanClosed_Date","ROI","LTV", "FirstEMIDate", "Product_Name", 
      "Branch_Name", "CollectionExecutive", "Area_Name", "Sub_Area_Name", "CustomerName","Gender", "Customer_PhoneNo", "RelationName", "LoanAmount", 
      "EMI_Frequency", "EMIAmount", "Loan_Tenure", "TotalEMI_NOS", "DPD_Days", "TotalEMI_Amt", "MatureEMI_Amt", "ReceivedEMI_Amt", 
      "OverDueEMI_Amt", "FutureEMI_Amt", "TotalEMI_No", "MatureEMI_No", "ReceivedEMI_No", "OverDueEMI_No", "FutureEMI_No", 
      "TotalPrincipal_Amt", "PrincipalDUE_Amt", "PrincipalReceived_Amt", "PrincipalOverdue_Amt", "PrincipalFuture_Amt", "TotalInterest_Amt", 
      "InterestDUE_Amt", "InterestReceived_Amt", "InterestOverdue_Amt", "InterestFuture_Amt", "OtherDues_Amt", "Chq_Bounce", 
      "Panel_Interest","DisbursmentAmount", "AssignToCompany"
    ];

  }

  onAccountHeadhSelect(event: any) {
    this.dataSource = '';
    this.AccountHead = event.option.value.AccountId;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(option => option.Account_Name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }
  getAccounthead() {
    this.lmsService.Loan_Lending_List().subscribe((res: any) => {
      this.AccountHeadDropdown = res;
      this.AccountHead = res[1].AccountId;
      this.filteredAccountHead = this.AccountControl.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.Account_Name)),
        map((name) => name ? this._filter(name) : this.AccountHeadDropdown.slice()),
        //tap((x) => x ? this.AccountControl.setValue(this.AccountHeadDropdown[1]) : '')
      );
    });
  }


  onAccountHeadhSelect1(event: any) {
    this.AssignTo = event.option.value.AccountId;
  }
  private _filter1(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.Lending_Dropdown.filter(option => option.Account_Name.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn1(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }
  getAccounthead1() {
    this.lmsService.Get_LendingList().subscribe((res: any) => {
      //console.log("res", res);
      this.Lending_Dropdown = res;
      this.filteredAccountHead1 = this.AccountControl1.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.Account_Name)),
        map((name) => name ? this._filter1(name) : this.Lending_Dropdown.slice())
      );
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BrancheDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getProductDropdown() {
    this._MasterService.GetProductList({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.ProductDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  Get_LendingList() {
    this.lmsService.Get_LendingList().subscribe((result) => {
      this.Lending_Dropdown = JSON.parse(JSON.stringify(result));
    });
  }


  onBranchSelect(item: any) {
    this.BranchesId = this.Branches.map(element => element.BranchId).join(",");
  }

  onProductsSelect(item: any) {
    this.ProductIds = this.Products.map(element => element.ProductId).join(",");
  }

  onOpenSearchModal(){
    $("#SearchInfo").modal("show");
    $("#SearchInfo").css("z-index", "1050");
  }
  OnCloseSearchModal() {
    $("#SearchInfo").modal("hide");
  }

  onSearchLendingAssign() {
    this.showSpinner = true;
    this.dataSource = '';
    
    this.LendingModel.LoanProductIds = this.ProductIds;
    this.LendingModel.BranchIds = this.BranchesId;
    this.LendingModel.AssignToCompany  = this.AccountHead;

    let _finalData = {
      ReportData: this.LendingModel
    }
    console.log("_finalData :",_finalData);
    this.OnCloseSearchModal();
   
    this.showSpinner = true;
    this.lmsService.Report_Lending_Eligibility({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        this.JsonData = JSON.parse(JSON.stringify(res));
        this.JsonData.forEach(x => {
          x.IsSelected = false;
        });
        this.dataSource = new MatTableDataSource(this.JsonData);
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
       
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
       
      }
    });
  }

  OnSaveCollExAssign() {
    var LoanIds = '';
    this.JsonData.map((x) => {
      if (x.IsSelected == true) {
        LoanIds = LoanIds + x.Loan_Id + ',';
      }
    });

    if (LoanIds == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please select atleast one checkbox', ...this.configSuccess });
      return;
    }

    console.log("AssignTo:", this.AssignTo, "LoanIds:", LoanIds);
    
    this.lmsService.Save_Loan_Assign_Lending({ AssignTo: this.AssignTo, LoanIds: LoanIds }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.onSearchLendingAssign();
        this.getAccounthead();
        this.getAccounthead1();
      }
      else { this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess }); }
    });
  }

  totaltypewise(type) {
    if (this.dataSource != undefined && this.dataSource !='') {
      if (this.dataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i][type]);
        }
        return (sum > 0) ? sum.toFixed(2) : '';
      }
      return '';
    }
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lending_assignment") as HTMLTableElement;
    const worksheetName = 'LENDING ASSIGNMENT'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  checkUncheckAll(event) {
    //let type = event.source.name;
    //console.log("type", type);
    this.JsonData.forEach(x => {
      if (event.checked == true) {
        x.IsSelected = true;
      }
      if (event.checked == false) {
        x.IsSelected = false;
      }
    });
    this.dataSource = new MatTableDataSource(this.JsonData);
  }

  DragNDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }
}
