// #region Import Used Components and Services
import { Injectable } from "@angular/core";
import { throwError, Observable, BehaviorSubject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { GetrSaveresponseModel } from "../../Shared/Models/app.MasterResponseModel";
// #endregion

@Injectable({
  providedIn: "root",
})
export class QuickLoanService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(
    private _Route: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  // #region GetQuickLoanDashboardCount
  public GetQuickLoanDashboardCount(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/GetQuickLoanDashboardCount";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #start QuickLoan_Save_Application
  public QuickLoan_Save_Application(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_Application`,
      requestData
    );
  }
  // #end QuickLoan_Save_Application

  // #start QuickLoan_Get_Application_Process_List
  public QuickLoan_Get_Application_Process_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Application_Process_List`,
      requestData
    );
  }
  // #end QuickLoan_Get_Application_Process_List

  // #start QuickLoan_ProcessMaster
  public QuickLoan_ProcessMaster() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_ProcessMaster`,
      null
    );
  }
  // #end QuickLoan_ProcessMaster

  // #start QuickLoan_Save_ProcessLevel
  public QuickLoan_Save_ProcessLevel(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_ProcessLevel`,
      requestData
    );
  }
  // #end QuickLoan_Save_ProcessLevel

  // #start QuickLoan_Get_ProcessLevel
  public QuickLoan_Get_ProcessLevel(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_ProcessLevel`,
      requestData
    );
  }
  // #end QuickLoan_Get_ProcessLevel

  // #start QuickLoan_Get_Process_MenuTabs
  public QuickLoan_Get_Process_MenuTabs(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Process_MenuTabs`,
      requestData
    );
  }
  // #end QuickLoan_Get_Process_MenuTabs

  // #start QuickLoan_Save_Process_MenuTabs
  public QuickLoan_Save_Process_MenuTabs(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_Process_MenuTabs`,
      requestData
    );
  }
  // #end QuickLoan_Save_Process_MenuTabs

  // #start QuickLoan_Get_Income_Expenses_List
  public QuickLoan_Get_Income_Expenses_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Income_Expenses_List`,
      requestData
    );
  }
  // #end QuickLoan_Get_Income_Expenses_List

  // #start QuickLoan_Save_Income_Expenses_Detail
  public QuickLoan_Save_Income_Expenses_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_Income_Expenses_Detail`,
      requestData
    );
  }
  // #end QuickLoan_Save_Income_Expenses_Detail

  // #start QuickLoan_Get_Income_Expenses_Detail
  public QuickLoan_Get_Income_Expenses_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Income_Expenses_Detail`,
      requestData
    );
  }
  // #end QuickLoan_Get_Income_Expenses_Detail

  // #start QuickLoan_Save_PageProcess
  public QuickLoan_Save_PageProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_PageProcess`,
      requestData
    );
  }
  // #end QuickLoan_Save_PageProcess

  // #start QuickLoan_Save_FinalSubmitProcess
  public QuickLoan_Save_FinalSubmitProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_FinalSubmitProcess`,
      requestData
    );
  }
  // #end QuickLoan_Save_FinalSubmitProcess

  // #start QuickLoan_Delete_Income_Expenses_Detail
  public QuickLoan_Delete_Income_Expenses_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Delete_Income_Expenses_Detail`,
      requestData
    );
  }
  // #end QuickLoan_Delete_Income_Expenses_Detail

  // #start QuickLoan_Get_DeviationApply
  public QuickLoan_Get_DeviationApply(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_DeviationApply`,
      requestData
    );
  }
  // #end QuickLoan_Get_DeviationApply

  // #start QuickLoan_Get_DeviationForApproval
  public QuickLoan_Get_DeviationForApproval(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_DeviationForApproval`,
      requestData
    );
  }
  // #end QuickLoan_Get_DeviationForApproval

  // #start QuickLoan_Get_Process_Menu
  public QuickLoan_Get_Process_Menu(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Process_Menu`,
      requestData
    );
  }
  // #end QuickLoan_Get_Process_Menu

  // #start QuickLoan_CompletedPage
  public QuickLoan_CompletedPage(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_CompletedPage`,
      requestData
    );
  }
  // #end QuickLoan_CompletedPage

  // #start QuickLoan_Send_Back
  public QuickLoan_Send_Back(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Send_Back`,
      requestData
    );
  }
  // #end QuickLoan_Send_Back

  // #start QuickLoan_Update_Reverted_Process
  public QuickLoan_Update_Reverted_Process(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Update_Reverted_Process`,
      requestData
    );
  }
  // #end QuickLoan_Update_Reverted_Process

  // #start QuickLoan_Get_PriviouseProcessList
  public QuickLoan_Get_PriviouseProcessList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_PriviouseProcessList`,
      requestData
    );
  }
  // #end QuickLoan_Get_PriviouseProcessList
  // #start Quick_SaveSendFileHOProcess
  public Quick_SaveSendFileHOProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Quick_SaveSendFileHOProcess`,
      requestData
    );
  }
  // #end Quick_SaveSendFileHOProcess
  // #start Quick_GetCourierListReceiveHO
  public Quick_GetCourierListReceiveHO(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Quick_GetCourierListReceiveHO`,
      requestData
    );
  }
  // #end Quick_GetCourierListReceiveHO

  // #start QuickLoan_Save_FileReceivingHO
  public QuickLoan_Save_FileReceivingHO(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_FileReceivingHO`,
      requestData
    );
  }
  // #end QuickLoan_Save_FileReceivingHO

  // #start QuickLoan_UpdateStatus_Process
  public QuickLoan_UpdateStatus_Process(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_UpdateStatus_Process`,
      requestData
    );
  }
  // #end QuickLoan_UpdateStatus_Process

  // #start QuickLoan_TATReportList
  public QuickLoan_TATReportList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_TATReportList`,
      requestData
    );
  }
  // #end QuickLoan_TATReportList

  // #start Quick_rpt_TAT_Report
  public Quick_rpt_TAT_Report(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Quick_rpt_TAT_Report`,
      requestData
    );
  }
  // #end Quick_rpt_TAT_Report

  // #start QuickLoan_Update_ValuationProcess
  public QuickLoan_Update_ValuationProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Update_ValuationProcess`,
      requestData
    );
  }
  // #end QuickLoan_Update_ValuationProcess

  // #start QuickLoan_Update_LegalProcess
  public QuickLoan_Update_LegalProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Update_LegalProcess`,
      requestData
    );
  }
  // #end QuickLoan_Update_LegalProcess

  // #start QuickLoan_Get_NextAllProcess
  public QuickLoan_Get_NextAllProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_NextAllProcess`,
      requestData
    );
  }
  // #end QuickLoan_Get_NextAllProcess

  // #start QuickLoan_Assign_Mannual
  public QuickLoan_Assign_Mannual(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Assign_Mannual`,
      requestData
    );
  }
  // #end QuickLoan_Assign_Mannual

  // #start QuickLoan_GetAssets_Valuation_Technical
  public QuickLoan_GetAssets_Valuation_Technical(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_GetAssets_Valuation_Technical`,
      requestData
    );
  }
  // #end QuickLoan_GetAssets_Valuation_Technical
  // #start QuickLoan_Get_PD_Questions_List
  public QuickLoan_Get_PD_Questions_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_PD_Questions_List`,
      requestData
    );
  }
  // #end QuickLoan_Get_PD_Questions_List

  // #start QuickLoan_Save_Customer_PDAnswer
  public QuickLoan_Save_Customer_PDAnswer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_Customer_PDAnswer`,
      requestData
    );
  }
  // #end QuickLoan_Save_Customer_PDAnswer

  // #start QuickLoan_Get_Sanction_Conditions
  public QuickLoan_Get_Sanction_Conditions(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Sanction_Conditions`,
      requestData
    );
  }
  // #end QuickLoan_Get_Sanction_Conditions

  // #start QuickLoan_Save_Sanction_Conditions
  public QuickLoan_Save_Sanction_Conditions(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Save_Sanction_Conditions`,
      requestData
    );
  }
  // #end QuickLoan_Save_Sanction_Conditions

  // #start QuickLoan_Delete_Sanction_Conditions
  public QuickLoan_Delete_Sanction_Conditions(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Delete_Sanction_Conditions`,
      requestData
    );
  }
  // #end QuickLoan_Delete_Sanction_Conditions

  // #start QuickLoan_Update_Financial_Sanction
  public QuickLoan_Update_Financial_Sanction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Update_Financial_Sanction`,
      requestData
    );
  }
  // #end QuickLoan_Update_Financial_Sanction
  // #start QuickLoan_Get_Financial_Proposed_And_Sanction
  public QuickLoan_Get_Financial_Proposed_And_Sanction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Financial_Proposed_And_Sanction`,
      requestData
    );
  }
  // #end QuickLoan_Get_Financial_Proposed_And_Sanction
  // #start QuickLoan_Get_StepEMI
  public QuickLoan_Get_StepEMI(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_StepEMI`,
      requestData
    );
  }
  // #end QuickLoan_Get_StepEMI

  // #start QuickLoan_Completed_Sanction_Conditions
  public QuickLoan_Completed_Sanction_Conditions(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Completed_Sanction_Conditions`,
      requestData
    );
  }
  // #end QuickLoan_Completed_Sanction_Conditions

  // #start QuickLoan_Accept_Financial_Sanction
  public QuickLoan_Accept_Financial_Sanction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Accept_Financial_Sanction`,
      requestData
    );
  }
  // #end QuickLoan_Accept_Financial_Sanction

  // #start Quick_Loan_Get_Manager_Dashboard_ByTeam
  public Quick_Loan_Get_Manager_Dashboard_ByTeam(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Quick_Loan_Get_Manager_Dashboard_ByTeam`,
      requestData
    );
  }
  // #end Quick_Loan_Get_Manager_Dashboard_ByTeam

  // #start QuickLoan_Get_Manager_Dashboard_ByProcess
  public QuickLoan_Get_Manager_Dashboard_ByProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/QuickLoan_Get_Manager_Dashboard_ByProcess`,
      requestData
    );
  }
  // #end QuickLoan_Get_Manager_Dashboard_ByProcess
  // #start QuickLoan_Pending_FeeList
  public QuickLoan_Pending_FeeList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Pending_FeeList`,
      requestData
    );
  }
  // #end QuickLoan_Pending_FeeList
  // #region QuickLoan_Save_Fees_Detail
  public QuickLoan_Save_Fees_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Save_Fees_Detail`,
      requestData
    );
  }
  // #endregion QuickLoan_Save_Fees_Detail
  // #region Deviation_Manual_Creation
  public Deviation_Manual_Creation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Deviation_Manual_Creation`,
      requestData
    );
  }
  // #endregion Deviation_Manual_Creation

  // #region QuickLoan_GetManual_Deviation
  public QuickLoan_GetManual_Deviation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_GetManual_Deviation`,
      requestData
    );
  }
  // #endregion QuickLoan_GetManual_Deviation
  // #region Delete_Application_Deviation
  public Delete_Application_Deviation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Delete_Application_Deviation`,
      requestData
    );
  }
  // #endregion Delete_Application_Deviation
  // #region LOS_GetManagerDashboardByProcess
  public LOS_GetManagerDashboardByTeam(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LOS/LOS_GetManagerDashboardByTeam`,
      requestData
    );
  }
  // #endregion
  // #region QuickLoan_Master_Report_Summary
  public QuickLoan_Master_Report_Summary(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Master_Report_Summary`,
      requestData
    );
  }
  // #endregion

  // #region QuickLoan_Master_Report_Detail
  public QuickLoan_Master_Report_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Master_Report_Detail`,
      requestData
    );
  }
  // #endregion

  // #region Quick_Loan_Lead_Report
  public Quick_Loan_Lead_Report(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Quick_Loan_Lead_Report`,
      requestData
    );
  }
  // #endregion

  // #region QuickLoan_Get_FOIR_Detail
  public QuickLoan_Get_FOIR_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Get_FOIR_Detail`,
      requestData
    );
  }
  // #endregion

  // #region QuickLoan_Save_FOIR_Detail
  public QuickLoan_Save_FOIR_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Save_FOIR_Detail`,
      requestData
    );
  }
  // #endregion

  // #region QuickLoan_Get_Inc_Exp_By_LoginUser
  public QuickLoan_Get_Inc_Exp_By_LoginUser(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Get_Inc_Exp_By_LoginUser`,
      requestData
    );
  }
  // #endregion

  // #region QuickLoan_Delete_Inc_Exp_By_LoginUser
  public QuickLoan_Delete_Inc_Exp_By_LoginUser(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Delete_Inc_Exp_By_LoginUser`,
      requestData
    );
  }
  // #endregion QuickLoan_Delete_Inc_Exp_By_LoginUser

  // #region QuickLoan_GetFileChecking
  public QuickLoan_GetFileChecking(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_GetFileChecking`,
      requestData
    );
  }
  // #endregion QuickLoan_GetFileChecking

  // #region Quick_Save_FileChecking
  public Quick_Save_FileChecking(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Quick_Save_FileChecking`,
      requestData
    );
  }
  // #endregion Quick_Save_FileChecking

  // #region QuickLoan_ApplicationStatus_Report
  public QuickLoan_ApplicationStatus_Report(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_ApplicationStatus_Report`,
      requestData
    );
  }
  // #endregion QuickLoan_ApplicationStatus_Report

  // #region QuickLoan_Application_Status
  public QuickLoan_Application_Status(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_Application_Status`,
      requestData
    );
  }
  // #endregion QuickLoan_Application_Status

  // #start LoanInNeedAPI
  public LoanInNeedAPI(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/LoanInNeedAPI`,
      requestData
    );
  }
  // #end LoanInNeedAPI

  // #start LoanInNeedRejected
  public LoanInNeedRejected(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/LoanInNeedRejected`,
      requestData
    );
  }
  // #end LoanInNeedRejected

  // #start QuickLoan_Proceed_Customer_Lead
  public QuickLoan_Proceed_Customer_Lead(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LOS/QuickLoan_Proceed_Customer_Lead`,
      requestData
    );
  }
  // #end QuickLoan_Proceed_Customer_Lead

  // #start Get_Customer_Exists_For_panNo_AdhaarNo
  public Get_Customer_Exists_For_panNo_AdhaarNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Get_Customer_Exists_For_panNo_AdhaarNo`,
      requestData
    );
  }
  // #end Get_Customer_Exists_For_panNo_AdhaarNo

  // #region BankAnalysisUpload
  public BankAnalysisUpload( requestData: any ) {
  //  const headers = new HttpHeaders().set("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/BankAnalysisUpload`, requestData);
  }
  // #endregion
  // #region DownloadAnalysisFile
  public DownloadAnalysisFile(requestData: any) {
    const headers = new HttpHeaders().set("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/DownloadAnalysisFile`, requestData, { headers, responseType: "blob" });
  }
  // #endregion

  
  // #start Save_BankStatementAnalysis
  public Save_BankStatementAnalysis(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_BankStatementAnalysis`,
      requestData
    );
  }
  // #end Save_BankStatementAnalysis

  // #start Get_Customer_Exists_For_panNo_AdhaarNo
  public QuickLoan_GetBankStatementAnalysis(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_GetBankStatementAnalysis`,
      requestData
    );
  }
  // #end QuickLoan_GetBankStatementAnalysis


  // #start QuickLoan_DeleteBankStatementAnalysis
  public QuickLoan_DeleteBankStatementAnalysis(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/QuickLoan_DeleteBankStatementAnalysis`,
      requestData
    );
  }
  // #end QuickLoan_DeleteBankStatementAnalysis
  
}
