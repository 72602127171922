<div>
  <form #f="ngForm" novalidate>
    <!-- <div class="row mt-4">
      <div class="col-md-12">
        <h4> Vehicle Category Master </h4>
      </div>
    </div> -->

    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-4">
        <span class="required-lable">Vehicle Category</span>
        <input type="text" name="SelectVehicleCategory" id="SelectVehicleCategory" [(ngModel)]="SelectVehicleCategory"
          class="form-control input-text-css" #refSelectVehicleCategory="ngModel" required
          [ngClass]="{'is-invalid': f.submitted && refSelectVehicleCategory.invalid, 'alert-warning': refSelectVehicleCategory.invalid  && (refSelectVehicleCategory.dirty || refSelectVehicleCategory.touched || refSelectVehicleCategory.untouched) }">
      </div>
      <!--<div class="col-md-3">
        <span> Vehicle Manufacture </span>
        <select name="SelectManufacture" id="SelectManufacture" [(ngModel)]="SelectManufacture"
          class="form-control input-text-css" required>
          <option *ngFor="let data of ManufactureData" [value]="data.Int_Id"> {{data.Manufacture}} </option>
        </select>
      </div>-->
      <div class="col-md-4">
        <button type="button" (click)="onSaveVehicleCatData()" *ngIf="saveBtn" class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading" [disabled]="!f.form.valid">
          Save
        </button>
        <button type="button" (click)="UpdateVehicleCategory()" *ngIf="updateBtn"
          class="mt-3 btn font-size-12 button-btn" [class.spinner]="loading" [disabled]="!f.form.valid">
          Update
        </button>
      </div>

      <div class="col-md-4 p-1 mt-2">
        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ....">
        </mat-form-field> -->
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input type="text" id="FilterInputSearch" (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch" placeholder="What're you searching for?"
                class="form-control border-0 bg-light">
                <div class="input-group-append">
                  <button id="button-addon1" type="submit" class="btn btn-link text-primary"><i class="fa fa-search"></i></button>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataCustomerSource">
      <ng-container matColumnDef="Number">
        <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
          {{ (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Vehicle Category">
        <mat-header-cell class="grid-header" *matHeaderCellDef>Vehicle Category</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">{{row.Vehicle_Category}}</mat-cell>
      </ng-container>

      <!--<ng-container matColumnDef="Vehicle Manufacture">
            <mat-header-cell class="grid-header" style="max-width: 200px;max-height:5px" *matHeaderCellDef>Vehicle Manufacture</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width: 200px;max-height:5px">{{row.Manufacture}}</mat-cell>
          </ng-container>-->

      <ng-container matColumnDef="Action">
        <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
          <i class="fas fa-lg fa-edit margin-right-4xs cursor-style"   aria-label="true"
            (click)="editVehicleCat(row)"></i>
          <i class="fa fa-trash style-delete" (click)="removeVehicleCat(row)" aria-label="true"></i>
        </mat-cell>

      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedCustomerColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedCustomerColumns"></mat-row>
    </mat-table>
  </div>
</div>