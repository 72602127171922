LoanFinancialDetail
<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="formborder mt-2">
    <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
      <div class="row m-0 h-b w-b" style="width: 100%">
        <h1 class="fs-12 m-0">Loan Details</h1>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-3">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanNo }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Application No</h6>
          <p class="lead fs-12">{{ loanDetailObj.ApplicationNo }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Loan Product</h6>
          <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Int_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>
      <!-- 
      <ng-container matColumnDef="CustomerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Customer Id </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CustomerId}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <!-- <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Customer}} </mat-cell> -->
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          <app-los-customer-view-model
            [customerId]="row.CustomerId"
            [index]="i"
          >
            {{ row.Customer }}
          </app-los-customer-view-model>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
         

          <div *ngIf="currentUser.IsMasking">
            {{ row.PhoneNo | mask }}
          </div>

          <div *ngIf="!currentUser.IsMasking">
            {{ row.PhoneNo }}
          </div>


           
          <i
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 13px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Relation_With_Hirer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          <div style="width: max-content !important">
            Relation With Borrower
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Relation_With_Hirer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Present_Address">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 250px; max-width: 250px; text-align: center"
          *matHeaderCellDef
        >
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 250px; max-width: 250px"
        >
          {{ row.Present_Address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ExistingCustomer">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 90px; max-width: 90px"
          *matHeaderCellDef
        >
          Existing
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 90px; max-width: 90px"
        >
          {{ row.ExistingCustomer }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div class="mt-2 formborder">
    <ng-container *ngFor="let applicationDetail of LoanFinancialDetail">
      <div
        class="row m-0 h-b w-b align-items-center justify-content-between"
        style="width: 100%"
      >
        <h1 class="fs-12 m-0">
          Loan Financial Details:
          <b>{{ applicationDetail.Type }}</b>
        </h1>
        <h6
          class="fs-12"
          *ngIf="
            (LoanAllDetail[0] ? LoanAllDetail[0].Is_Rescheduled : false) &&
            applicationDetail.Type == 'Current'
          "
        >
          This Loan is already Rescheduled
        </h6>
        <button
          style="width: 80px; height: 30px"
          *ngIf="
            !(LoanAllDetail[0] ? LoanAllDetail[0].Is_Rescheduled : false) &&
            applicationDetail.Type == 'Current'
          "
          type="button"
          class="btn font-size-12 button-btn p-0"
          (click)="onEditFinanceDetail()"
        >
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <i
              class="fa fa-edit ml-2"
              style="color: black; font-size: 13px"
            ></i>
          </div>
        </button>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">₹ {{ applicationDetail.AssetCost }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Net Finance</h6>
          <!-- .toFixed(2) -->
          <p class="lead fs-12">₹ {{ applicationDetail.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ applicationDetail.ROI }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure</h6>
          <p class="lead fs-12">
            {{ applicationDetail.LoanTenure }} {{ Tenure_Name }}
          </p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.No_Of_Installment }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Adv. Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.AdvInstl }}</p>
        </div>
        <!-- <div class="col-md-2">
          <h6 class="fs-12">Management Fee</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.ManagementFee }}</p>
        </div> -->
        <div class="col-md-2">
          <h6 class="fs-12">Disbursement Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.DisbursementAmt }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.AgreementValue }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.InterestAmt }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.LoanEMIAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ applicationDetail.Case_IRR }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Disbursement IRR</h6>
          <p class="lead fs-12">{{ applicationDetail.Disbursement_IRR }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ applicationDetail.LTV }}%</p>
        </div>
        <!-- <div class="col-md-2">
          <h6 class="fs-12">Margin</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.Margin }}</p>
        </div> -->
        <!-- <div class="col-md-2">
          <h6 class="fs-12">First EMI Date</h6>
          <p class="lead fs-12">{{ applicationDetail.FirstEMIDate }}</p>
        </div> -->
        <div class="col-md-2">
          <h6 class="fs-12">EMI Due Date</h6>
          <p class="lead fs-12">{{ applicationDetail.EMI_DueDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Regenerated By</h6>
          <p class="lead fs-12">{{ applicationDetail.Executive }}</p>
        </div>
        <div class="col-md-2">
          <!-- <h6 class="fs-12" *ngIf="applicationDetail.Type == 'Current'">
            Loan Date
          </h6> -->
          <!-- <h6 class="fs-12" *ngIf="!(applicationDetail.Type == 'Current')">
            Regenerated On
          </h6> -->

          <h6 class="fs-12">Regenerated On</h6>
          <p class="lead fs-12">{{ applicationDetail.DATE }}</p>
        </div>
        <div class="col-md-4">
          <h6 class="fs-12">Regenerated Remark</h6>
          <p class="lead fs-12">{{ applicationDetail.Remark }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="modal fade in"
  id="loanFinancialDetailsModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Financial Details
        </h6>
        <button
          type="button"
          (click)="onCloseDialog()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isCalEMITableView">
          <form
            #eif="ngForm"
            class="row m-0 formborder"
            (ngSubmit)="onCalculateEmiAndIRR()"
            novalidate
          >
            <div class="row m-0 p-0 col-md-12 mt-3">
              <div class="col-md-2 mb-3">
                <span>Asset Cost (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  #refAsset_Cost="ngModel"
                  [(ngModel)]="cEIModel.Asset_Cost"
                  disabled
                  placeholder="Asset Cost"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refAsset_Cost.invalid,
                    'alert-warning':
                      refAsset_Cost.invalid &&
                      (refAsset_Cost.dirty ||
                        refAsset_Cost.touched ||
                        refAsset_Cost.untouched)
                  }"
                  name="Asset_Cost"
                  id="Asset_Cost"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 mb-3">
                <span> Net Finance (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refNetFinance_Amt="ngModel"
                  [(ngModel)]="cEIModel.NetFinance_Amt"
                  placeholder="Net Finance"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                    'alert-warning':
                      refNetFinance_Amt.invalid &&
                      (refNetFinance_Amt.dirty ||
                        refNetFinance_Amt.touched ||
                        refNetFinance_Amt.untouched)
                  }"
                  name="NetFinance_Amt"
                  id="NetFinance_Amt"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span class="required-lable"> Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                  [(ngModel)]="cEIModel.IRR_CalculateBy"
                  class="form-control input-text-css"
                  (change)="setType($event)"
                  required
                >
                  <option value="ROI">Flat ROI</option>
                  <option value="FLAT_EMI">EMI Amt (₹)</option>
                  <option
                    [hidden]="
                      cEIModel.EMI_Type == 'Daily' ||
                      cEIModel.EMI_Type == 'Weekly' ||
                      cEIModel.EMI_Type == 'Fortnightly' ||
                      cEIModel.EMI_Type == 'ByWeekly' ||
                      cEIModel.EMI_Type == 'FourthWeekly'
                    "
                    value="STEP_EMI"
                  >
                    Step EMI
                  </option>
                  <option value="Reducing_ROI">Reducing ROI</option>
                </select>
              </div>

              <div class="col-md-3 mb-3">
                <span
                  class="required-lable"
                  *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'"
                >
                  {{ cEIModel.IRR_CalculateBy }}</span
                >
                <input
                  type="text"
                  appTwoDigitDecimaNumber
                  required
                  #refReducing_ROI="ngModel"
                  [(ngModel)]="cEIModel.Reducing_ROI"
                  placeholder="Reducing_ROI"
                  *ngIf="cEIModel.IRR_CalculateBy == 'Reducing_ROI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refReducing_ROI.invalid,
                    'alert-warning':
                      refReducing_ROI.invalid &&
                      (refReducing_ROI.dirty ||
                        refReducing_ROI.touched ||
                        refReducing_ROI.untouched)
                  }"
                  name="Reducing_ROI"
                  id="Reducing_ROI"
                  class="form-control input-text-css"
                />
                <input
                  type="text"
                  placeholder="Flat Rate"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
                />
                <input
                  type="text"
                  appTwoDigitDecimaNumber
                  required
                  #refFlat_Rate="ngModel"
                  [(ngModel)]="cEIModel.Flat_Rate"
                  placeholder="Flat Rate"
                  *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refFlat_Rate.invalid,
                    'alert-warning':
                      refFlat_Rate.invalid &&
                      (refFlat_Rate.dirty ||
                        refFlat_Rate.touched ||
                        refFlat_Rate.untouched)
                  }"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                />

                <input
                  type="text"
                  placeholder="EMI Amount"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
                />
                <input
                  type="text"
                  numbersOnly="true"
                  required
                  #refEMI_Amount="ngModel"
                  [(ngModel)]="cEIModel.EMI_Amount"
                  placeholder="EMI Amount"
                  *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Amount.invalid,
                    'alert-warning':
                      refEMI_Amount.invalid &&
                      (refEMI_Amount.dirty ||
                        refEMI_Amount.touched ||
                        refEMI_Amount.untouched)
                  }"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                />
              </div>

              <div class="col-md-2 mb-3">
                <span>Loan Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [(ngModel)]="cEIModel.Loan_Date"
                    [min]="minDate"
                    required
                    #refLoan_Date="ngModel"
                    name="Loan_Date"
                    id="Loan_Date"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <!-- <input
                  type="text"
                  [(ngModel)]="cEIModel.Loan_Date"
                  [min]="minDate"
                  required
                  #refLoan_Date="ngModel"
                  name="Loan_Date"
                  id="Loan_Date"
                  value="{{ cEIModel.Loan_Date | date : 'dd/MM/yyyy' }}"
                  placeholder="DD/MM/YYYY"
                  [(bsValue)]="cEIModel.Loan_Date"
                  class="form-control input-text-css"
                  bsDatepicker
                  [ngClass]="{
                    'is-invalid': eif.submitted && refLoan_Date.invalid,
                    'alert-warning':
                      refLoan_Date.invalid &&
                      (refLoan_Date.dirty ||
                        refLoan_Date.touched ||
                        refLoan_Date.untouched)
                  }"
                /> -->
              </div>
            </div>
            <div class="row m-0 p-0 col-md-12">
              <div class="col-md-2 mb-3">
                <span> EMI Frequency</span>
                <select
                  name="EMI_Type"
                  id="EMI_Type"
                  #refEMI_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Type.invalid,
                    'alert-warning':
                      refEMI_Type.invalid &&
                      (refEMI_Type.dirty ||
                        refEMI_Type.touched ||
                        refEMI_Type.untouched)
                  }"
                  [(ngModel)]="cEIModel.EMI_Type"
                  class="form-control input-text-css"
                  (change)="setTenure()"
                  required
                >
                  <!-- <option value="Daily" *ngIf="isFrequency==false"> Daily</option>
            <option value="Weekly" *ngIf="isFrequency==false"> Weekly</option>
            <option value="Fortnightly" *ngIf="isFrequency==false"> Fortnightly</option> -->
                  <option
                    value="Daily"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Daily
                  </option>
                  <option
                    value="Weekly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Weekly
                  </option>
                  <option
                    value="ByWeekly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Two-Weekly
                  </option>
                  <option
                    value="FourthWeekly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Four-Weekly
                  </option>
                  <option
                    value="Fortnightly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Fortnightly
                  </option>
                  <option value="Monthly">Monthly</option>
                  <option
                    value="ByMonthly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Bi-Monthly
                  </option>
                  <option
                    value="Quarterly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Quarterly
                  </option>
                  <option
                    value="HalfYearly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Half Yearly
                  </option>
                  <option
                    value="Yearly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Yearly
                  </option>
                </select>
              </div>
              <div class="col-md-2 mb-3">
                <span> No Of Installments </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refNo_Of_Inst="ngModel"
                  [(ngModel)]="cEIModel.No_Of_Inst"
                  placeholder="No Of Installments"
                  (blur)="setTenure()"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                    'alert-warning':
                      refNo_Of_Inst.invalid &&
                      (refNo_Of_Inst.dirty ||
                        refNo_Of_Inst.touched ||
                        refNo_Of_Inst.untouched)
                  }"
                  name="No_Of_Inst"
                  id="No_Of_Inst"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span> Tenure (Month) </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  disabled
                  #refTenure="ngModel"
                  [(ngModel)]="cEIModel.Tenure"
                  placeholder="Tenure"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refTenure.invalid,
                    'alert-warning':
                      refTenure.invalid &&
                      (refTenure.dirty ||
                        refTenure.touched ||
                        refTenure.untouched)
                  }"
                  name="Tenure"
                  id="Tenure"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-3 mb-3"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <span> Instl In Adv. </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refAdv_Inst="ngModel"
                  [(ngModel)]="cEIModel.Adv_Inst"
                  (blur)="setAdvanceInstl()"
                  placeholder="Instl In Adv."
                  [ngClass]="{
                    'is-invalid': eif.submitted && refAdv_Inst.invalid,
                    'alert-warning':
                      refAdv_Inst.invalid &&
                      (refAdv_Inst.dirty ||
                        refAdv_Inst.touched ||
                        refAdv_Inst.untouched)
                  }"
                  name="Adv_Inst"
                  id="Adv_Inst"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 mb-3">
                <span> Due Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker2"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [(ngModel)]="cEIModel.First_EMI_Date"
                    [min]="minDate"
                    required
                    #refFirst_EMI_Date="ngModel"
                    name="First_EMI_Date"
                    id="First_EMI_Date"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refFirst_EMI_Date.invalid,
                      'alert-warning':
                        refFirst_EMI_Date.invalid &&
                        (refFirst_EMI_Date.dirty ||
                          refFirst_EMI_Date.touched ||
                          refFirst_EMI_Date.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
                <!-- <input
                  type="text"
                  [(ngModel)]="cEIModel.First_EMI_Date"
                  [min]="minDate"
                  required
                  #refFirst_EMI_Date="ngModel"
                  name="First_EMI_Date"
                  id="First_EMI_Date"
                  value="{{ cEIModel.First_EMI_Date | date : 'dd/MM/yyyy' }}"
                  placeholder="DD/MM/YYYY"
                  [(bsValue)]="cEIModel.First_EMI_Date"
                  class="form-control input-text-css"
                  bsDatepicker
                  [ngClass]="{
                    'is-invalid': eif.submitted && refFirst_EMI_Date.invalid,
                    'alert-warning':
                      refFirst_EMI_Date.invalid &&
                      (refFirst_EMI_Date.dirty ||
                        refFirst_EMI_Date.touched ||
                        refFirst_EMI_Date.untouched)
                  }"
                /> -->
              </div>

              <!-- <div class="col-md-12 mb-3" *ngIf="cEIModel.IRR_CalculateBy == 'STEP_EMI'"> -->
              <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
                <div class="row m-0 mt-3 justify-content-center">
                  <div class="table-border">
                    <div class="item-row header">
                      <div class="lead w50 fs-14">#</div>
                      <div class="lead w120 fs-14">From EMI</div>
                      <div class="lead w120 fs-14">To EMI</div>
                      <div class="lead fs-14">EMI Amount</div>
                      <div class="lead w120 fs-14">No of EMI</div>
                      <div class="lead w150 fs-14">Total Amount</div>
                    </div>
                    <div
                      class="item-row"
                      *ngFor="let semi of stepEMI; let i = index"
                    >
                      <div class="w50">{{ i + 1 }}</div>
                      <div class="w120">
                        <input
                          type="text"
                          numbersOnly
                          readonly
                          [(ngModel)]="semi.FromEMI"
                          placeholder="From EMI"
                          name="FromEMI{{ i }}"
                          id="FromEMI{{ i }}"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="w120">
                        <input
                          type="text"
                          numbersOnly
                          [disabled]="semi.disable"
                          [(ngModel)]="semi.ToEMI"
                          placeholder="To EMI"
                          name="ToEMI{{ i }}"
                          id="ToEMI{{ i }}"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="">
                        <input
                          type="text"
                          numbersOnly
                          [disabled]="semi.disable"
                          [(ngModel)]="semi.EMI_Amount"
                          placeholder="EMI Amount"
                          name="EMI_Amount{{ i }}"
                          id="EMI_Amount{{ i }}"
                          class="form-control input-text-css mr-3 emi-amount"
                        />
                        <button
                          type="button"
                          *ngIf="!semi.disable"
                          class="primary-btn"
                          (click)="addNextStep(semi)"
                        >
                          <i
                            class="fa fa-check-circle"
                            style="font-size: 18px"
                          ></i>
                        </button>
                        <button
                          type="button"
                          class="primary-btn"
                          style="margin-left: 10px"
                          *ngIf="!semi.disable && i > 0"
                          (click)="removeLastStep(i)"
                        >
                          <i
                            class="fa fa-minus-circle"
                            style="font-size: 18px"
                          ></i>
                        </button>
                      </div>
                      <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                      <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                    </div>
                    <div class="item-row header" *ngIf="stepEmiData">
                      <div class="lead w50 fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14"><b>Total</b></div>
                      <div class="lead w120 fs-14">&nbsp;</div>
                      <div class="lead fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14">
                        <b>{{ stepEmiData.totalEmi }}</b>
                      </div>
                      <div class="lead w150 fs-14">
                        <b>{{ stepEmiData.totalAmount }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 col-md-12 justify-content-center">
              <button
                type="button"
                (click)="onCalculateEmiAndIRR()"
                class="pl-5 pr-5 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Calculate EMI and IRR
              </button>
            </div>
            <div
              class="row col-md-12 m-0 p-0 mt-3"
              id="scrollto"
              *ngIf="emiIrr"
            >
              <div class="col-md-3">
                <h6 class="fs-12">Disbursement Amount</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Disbursement_Amt.toFixed(2) }}
                </p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Interest Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Interest_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Agreement Value</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Agreement_Value.toFixed(2) }}
                </p>
              </div>
              <div class="row m-0 pl-0 pr-0 col-md-3">
                <div class="col-md-6 pr-1">
                  <h6 class="fs-12">LTV</h6>
                  <p class="lead fs-12">{{ emiIrr.LTV.toFixed(2) }} %</p>
                </div>
                <div class="col-md-6 pl-1">
                  <h6 class="fs-12">ROI</h6>
                  <p class="lead fs-12">{{ emiIrr.ROI.toFixed(2) }} %</p>
                </div>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">EMI Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Case IRR</h6>
                <p class="lead fs-12">{{ emiIrr.Case_IRR.toFixed(2) }} %</p>
              </div>
              <div
                class="col-md-3"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <h6 class="fs-12">Disbursement IRR</h6>
                <p class="lead fs-12">
                  {{ emiIrr.Disbursement_IRR.toFixed(2) }} %
                </p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Margin</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Margin.toFixed(2) }}</p>
              </div>
              <div class="col-md-12">
                <h6 class="required-lable">Remarks</h6>
                <textarea
                  [(ngModel)]="RestructureRemark"
                  required
                  placeholder="Remarks"
                  name="RestructureRemark"
                  id="RestructureRemark"
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </div>
            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseDialog()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onSaveApplication()"
                class="ml-4 btn font-size-12 button-btn"
              >
                Save
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
