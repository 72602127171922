<!--<a href="javascript:void(0)" (click)="onEditFinanceDetail()">
  <ng-content></ng-content>
</a>-->
<button
  type="button"
  class="btn btn-success font-size-12"
  (click)="onEditFinanceDetail()"
>
  <i
    *ngIf="!isEdit"
    class="fa fa-eye"
    style="font-size: medium; cursor: pointer"
  ></i>
  <div style="float: right; padding-left: 10px" *ngIf="!isEdit">
    View Financial
  </div>
  <div *ngIf="isEdit">
    <i class="fa fa-edit ml-2" style="color: black; font-size: 13px"></i>
  </div>
</button>

<div
  class="modal fade in"
  id="updateFinancialDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ isEdit ? "Update" : "View" }} Financial Details
        </h6>
        <button
          type="button"
          (click)="OnFinancialDetailClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <form
            #eif="ngForm"
            class="row m-0"
            (ngSubmit)="onCalculateEmiAndIRR()"
            novalidate
          >
            <div class="col-md-4" style="display: none">
              <span> Select loan Product </span>
              <select
                name="FilterStatusId"
                id="FilterStatusId"
                class="form-control input-text-css"
                (change)="geSchemeList()"
                [(ngModel)]="cEIModel.ProductId"
                required
                disabled
                #refFilterStatusId="ngModel"
                [ngClass]="{
                  'is-invalid': eif.submitted && refFilterStatusId.invalid,
                  'alert-warning':
                    refFilterStatusId.invalid &&
                    (refFilterStatusId.dirty ||
                      refFilterStatusId.touched ||
                      refFilterStatusId.untouched)
                }"
              >
                <option value="">Select Product</option>
                <option
                  *ngFor="let product of productDropdown"
                  [value]="product.ProductId"
                >
                  {{ product.Product_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-12 mt-3">
              <div class="table-responsive" *ngIf="cEIModel.ProductId">
                <mat-table [dataSource]="dataSchemeSource">
                  <ng-container matColumnDef="SchemeId">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                    >
                      #
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Scheme">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Scheme
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Scheme }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Amount">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Amount }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ROI">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      ROI (%)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ROI }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Period">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Period
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Period }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="AdvanceEMI">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      AdvanceEMI
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.AdvanceEMI }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Select">
                    <mat-header-cell
                      class="grid-header j-c-center"
                      style="max-width: 60px"
                      *matHeaderCellDef
                    >
                      Select
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell j-c-center"
                      style="max-width: 60px"
                    >
                      <input
                        type="checkbox"
                        [value]="row.SchemeId"
                        [disabled]="!isEdit"
                        [checked]="selectedScheme.SchemeId == row.SchemeId"
                        (change)="onCheckboxChange($event, row)"
                      />
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedSchemeColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedSchemeColumns"
                  ></mat-row>
                </mat-table>
                <p *ngIf="schemeArr.length == 0">No Scheme Available</p>
              </div>
            </div>
            <div class="row m-0 p-0 col-md-12 mt-3">
              <div class="col-md-3 mb-3">
                <span>Asset Cost (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  #refAsset_Cost="ngModel"
                  [(ngModel)]="cEIModel.Asset_Cost"
                  placeholder="Asset Cost"
                  [disabled]="!isEdit"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refAsset_Cost.invalid,
                    'alert-warning':
                      refAsset_Cost.invalid &&
                      (refAsset_Cost.dirty ||
                        refAsset_Cost.touched ||
                        refAsset_Cost.untouched)
                  }"
                  name="Asset_Cost"
                  id="Asset_Cost"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span> Net Finance (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refNetFinance_Amt="ngModel"
                  [disabled]="!isEdit"
                  [(ngModel)]="cEIModel.NetFinance_Amt"
                  placeholder="Net Finance"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                    'alert-warning':
                      refNetFinance_Amt.invalid &&
                      (refNetFinance_Amt.dirty ||
                        refNetFinance_Amt.touched ||
                        refNetFinance_Amt.untouched)
                  }"
                  name="NetFinance_Amt"
                  id="NetFinance_Amt"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span class="required-lable"> Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                  [disabled]="!isEdit"
                  [(ngModel)]="cEIModel.IRR_CalculateBy"
                  class="form-control input-text-css"
                  (change)="setType($event)"
                  required
                >
                  <option value="ROI">Flat ROI</option>
                  <option value="FLAT_EMI">EMI Amt (₹)</option>
                  <option value="STEP_EMI">Step EMI</option>
                  <option value="Redusing_ROI">Redusing ROI</option>
                </select>
              </div>

              <div class="col-md-3 mb-3">
                <span
                  class="required-lable"
                  *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'"
                >
                  {{ cEIModel.IRR_CalculateBy }}</span
                >
                <input
                  type="text"
                  numbersOnly="true"
                  required
                  #refRedusing_ROI="ngModel"
                  [(ngModel)]="cEIModel.Redusing_ROI"
                  placeholder="Redusing_ROI"
                  [disabled]="!isEdit"
                  *ngIf="cEIModel.IRR_CalculateBy == 'Redusing_ROI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refRedusing_ROI.invalid,
                    'alert-warning':
                      refRedusing_ROI.invalid &&
                      (refRedusing_ROI.dirty ||
                        refRedusing_ROI.touched ||
                        refRedusing_ROI.untouched)
                  }"
                  name="Redusing_ROI"
                  id="Redusing_ROI"
                  class="form-control input-text-css"
                />
                <input
                  type="text"
                  placeholder="Flat Rate"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
                />
                <input
                  type="text"
                  numbersOnly="true"
                  required
                  #refFlat_Rate="ngModel"
                  [(ngModel)]="cEIModel.Flat_Rate"
                  placeholder="Flat Rate"
                  [disabled]="!isEdit"
                  *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refFlat_Rate.invalid,
                    'alert-warning':
                      refFlat_Rate.invalid &&
                      (refFlat_Rate.dirty ||
                        refFlat_Rate.touched ||
                        refFlat_Rate.untouched)
                  }"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                />

                <input
                  type="text"
                  placeholder="EMI Amount"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
                />
                <input
                  type="text"
                  numbersOnly="true"
                  required
                  [disabled]="!isEdit"
                  #refEMI_Amount="ngModel"
                  [(ngModel)]="cEIModel.EMI_Amount"
                  placeholder="EMI Amount"
                  *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Amount.invalid,
                    'alert-warning':
                      refEMI_Amount.invalid &&
                      (refEMI_Amount.dirty ||
                        refEMI_Amount.touched ||
                        refEMI_Amount.untouched)
                  }"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                />
              </div>

              <!--<div class="col-md-2 mb-3">
    <span class="form-check-inline">
      <label class="form-check-label" for="CalculateByFlat">
        ROI (%)
        <input
          type="radio"
          class="form-check-input"
          id="CalculateByFlat"
          name="IRR_CalculateBy"
          #refIRR_CalculateBy="ngModel"
          [ngClass]="{
            'is-invalid':
              eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_CalculateBy"
          required
          value="ROI"
          (change)="handleByChange($event)"
        />
      </label>
    </span>

    <input
      type="text"
      placeholder="Flat Rate"
      name="Flat_Rate"
      id="Flat_Rate"
      class="form-control input-text-css"
      disabled
      *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
    />
    <input
      type="text"
      numbersOnly="true"
      required
      #refFlat_Rate="ngModel"
      [(ngModel)]="cEIModel.Flat_Rate"
      placeholder="Flat Rate"
      *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
      [ngClass]="{
        'is-invalid': eif.submitted && refFlat_Rate.invalid,
        'alert-warning':
          refFlat_Rate.invalid &&
          (refFlat_Rate.dirty ||
            refFlat_Rate.touched ||
            refFlat_Rate.untouched)
      }"
      name="Flat_Rate"
      id="Flat_Rate"
      class="form-control input-text-css"
    />
  </div>
  <div class="col-md-2 mb-3">
    <span class="form-check-inline">
      <label class="form-check-label" for="CalculateByEMI">
        EMI Amt (₹)
        <input
          type="radio"
          class="form-check-input"
          id="CalculateByEMI"
          name="IRR_CalculateBy"
          #refIRR_CalculateBy="ngModel"
          [ngClass]="{
            'is-invalid':
              eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_CalculateBy"
          required
          value="FLAT_EMI"
          (change)="handleByChange($event)"
        />
      </label>
    </span>

    <input
      type="text"
      placeholder="EMI Amount"
      name="EMI_Amount"
      id="EMI_Amount"
      class="form-control input-text-css"
      disabled
      *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
    />
    <input
      type="text"
      numbersOnly="true"
      required
      #refEMI_Amount="ngModel"
      [(ngModel)]="cEIModel.EMI_Amount"
      placeholder="EMI Amount"
      *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
      [ngClass]="{
        'is-invalid': eif.submitted && refEMI_Amount.invalid,
        'alert-warning':
          refEMI_Amount.invalid &&
          (refEMI_Amount.dirty ||
            refEMI_Amount.touched ||
            refEMI_Amount.untouched)
      }"
      name="EMI_Amount"
      id="EMI_Amount"
      class="form-control input-text-css"
    />
  </div>

  <div class="col-md-2 mb-3">
    <span class="form-check-inline">
      <label class="form-check-label" for="CalculateByEMI"
        >Step EMI
        <input
          type="radio"
          class="form-check-input"
          id="CalculateByEMI"
          name="IRR_CalculateBy"
          #refIRR_CalculateBy="ngModel"
          [ngClass]="{
            'is-invalid':
              eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_CalculateBy"
          required
          value="STEP_EMI"
          (change)="handleByChange($event)"
        />
      </label>
    </span>
  </div>-->

              <div class="col-md-3 mb-3">
                <span> EMI Frequency</span>
                <select
                  name="EMI_Type"
                  id="EMI_Type"
                  #refEMI_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Type.invalid,
                    'alert-warning':
                      refEMI_Type.invalid &&
                      (refEMI_Type.dirty ||
                        refEMI_Type.touched ||
                        refEMI_Type.untouched)
                  }"
                  [disabled]="!isFrequency"
                  [(ngModel)]="cEIModel.EMI_Type"
                  class="form-control input-text-css"
                  (change)="setTenure()"
                  required
                >
                  <!-- <option value=""> EMI Frequancy </option> -->
                  <!-- <option value="Daily">Daily</option> -->
                  <!-- <option value="Weekly">Weekly</option> -->
                  <!-- <option value="Fortnightly">Fortnightly</option> -->
                  <option
                    value="Daily"
                    [hidden]="
                      cEIModel.IRR_CalculateBy == 'STEP_EMI' ||
                      cEIModel.IRR_CalculateBy == 'Reducing_ROI'
                    "
                  >
                    Daily
                  </option>
                  <option
                    value="Weekly"
                    [hidden]="
                      cEIModel.IRR_CalculateBy == 'STEP_EMI' ||
                      cEIModel.IRR_CalculateBy == 'Reducing_ROI'
                    "
                  >
                    Weekly
                  </option>
                  <option
                    value="Fortnightly"
                    [hidden]="
                      cEIModel.IRR_CalculateBy == 'STEP_EMI' ||
                      cEIModel.IRR_CalculateBy == 'Reducing_ROI'
                    "
                  >
                    Fortnightly
                  </option>
                  <option value="Monthly">Monthly</option>
                  <option
                    value="ByMonthly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Bi-Monthly
                  </option>
                  <option
                    value="Quarterly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Quarterly
                  </option>
                  <option
                    value="HalfYearly"
                    [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                  >
                    Half Yearly
                  </option>
                  <option
                    value="Yearly"
                    [hidden]="
                      cEIModel.IRR_CalculateBy == 'Reducing_ROI' ||
                      cEIModel.IRR_CalculateBy == 'STEP_EMI'
                    "
                  >
                    Yearly
                  </option>
                  <option
                    value="Bullet"
                    [hidden]="
                      cEIModel.IRR_CalculateBy == 'STEP_EMI' ||
                      cEIModel.IRR_CalculateBy == 'Reducing_ROI'
                    "
                  >
                    Bullet
                  </option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <span> No Of Installments </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refNo_Of_Inst="ngModel"
                  [disabled]="!isEdit"
                  [(ngModel)]="cEIModel.No_Of_Inst"
                  placeholder="No Of Instl"
                  (blur)="setTenure()"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                    'alert-warning':
                      refNo_Of_Inst.invalid &&
                      (refNo_Of_Inst.dirty ||
                        refNo_Of_Inst.touched ||
                        refNo_Of_Inst.untouched)
                  }"
                  name="No_Of_Inst"
                  id="No_Of_Inst"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span> Tenure </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refTenure="ngModel"
                  [disabled]="!isEdit"
                  [(ngModel)]="cEIModel.Tenure"
                  placeholder="Tenure"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refTenure.invalid,
                    'alert-warning':
                      refTenure.invalid &&
                      (refTenure.dirty ||
                        refTenure.touched ||
                        refTenure.untouched)
                  }"
                  name="Tenure"
                  id="Tenure"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mb-3">
                <span> Instl In Adv. </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refAdv_Inst="ngModel"
                  (blur)="setAdvanceInstl()"
                  [disabled]="!isEdit"
                  [(ngModel)]="cEIModel.Adv_Inst"
                  placeholder="Instl In Adv."
                  [ngClass]="{
                    'is-invalid': eif.submitted && refAdv_Inst.invalid,
                    'alert-warning':
                      refAdv_Inst.invalid &&
                      (refAdv_Inst.dirty ||
                        refAdv_Inst.touched ||
                        refAdv_Inst.untouched)
                  }"
                  name="Adv_Inst"
                  id="Adv_Inst"
                  class="form-control input-text-css"
                />
              </div>
              <!-- <div class="col-md-4 mb-3">
    <span> Mgmt. Fee (Rs.) </span>
    <input
      type="text"
      numbersOnly
      required
      #refMgmtFee="ngModel"
      [disabled]="!isEdit"
      [(ngModel)]="cEIModel.MgmtFee"
      placeholder="Mgmt. Fee"
      [ngClass]="{
        'is-invalid': eif.submitted && refMgmtFee.invalid,
        'alert-warning':
          refMgmtFee.invalid &&
          (refMgmtFee.dirty ||
            refMgmtFee.touched ||
            refMgmtFee.untouched)
      }"
      name="MgmtFee"
      id="MgmtFee"
      class="form-control input-text-css"
    />
  </div> -->
              <!-- <div class="col-md-4 mb-3">
    <span> Preferable EMI Date</span>
    <input
      name="First_EMI_Date"
      required
      id="First_EMI_Date"
      #refFirst_EMI_Date="ngModel"
      [disabled]="!isEdit"
      [ngClass]="{
        'is-invalid': eif.submitted && refFirst_EMI_Date.invalid,
        'alert-warning':
          refFirst_EMI_Date.invalid &&
          (refFirst_EMI_Date.dirty ||
            refFirst_EMI_Date.touched ||
            refFirst_EMI_Date.untouched)
      }"
      [(ngModel)]="cEIModel.First_EMI_Date"
      [minDate]="CurrentDate"
      value="{{ cEIModel.First_EMI_Date | date: 'dd/MM/yyyy' }}"
      placeholder="DD/MM/YYYY"
      class="form-control input-text-css"
      [(bsValue)]="cEIModel.First_EMI_Date"
      bsDatepicker
    />
  </div> -->
              <div
                class="col-md-12 mb-3"
                *ngIf="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
              >
                <!-- <div class="form-check-inline" *ngIf="!isEdit">
      <label class="form-check-label" for="flateIrr">
        <input
          type="radio"
          class="form-check-input"
          id="flateIrr"
          name="optradio"
          [attr.disabled]="!isEdit"
          #refIRR_Type="ngModel"
          [ngClass]="{
            'is-invalid': eif.submitted && refIRR_Type.invalid,
            'alert-warning':
              refIRR_Type.invalid &&
              (refIRR_Type.dirty ||
                refIRR_Type.touched ||
                refIRR_Type.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_Type"
          required
          value="Flat"
          (change)="handleChange($event)"
        />Flat EMI
      </label>
    </div>
    <div class="form-check-inline" *ngIf="!isEdit">
      <label class="form-check-label" for="stepIrr">
        <input
          type="radio"
          class="form-check-input"
          id="stepIrr"
          name="optradio"
          [attr.disabled]="!isEdit"
          #refIRR_Type="ngModel"
          [ngClass]="{
            'is-invalid': eif.submitted && refIRR_Type.invalid,
            'alert-warning':
              refIRR_Type.invalid &&
              (refIRR_Type.dirty ||
                refIRR_Type.touched ||
                refIRR_Type.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_Type"
          required
          value="Step"
          (change)="handleChange($event)"
        />Step EMI
      </label>
    </div>
    <div class="form-check-inline" *ngIf="isEdit">
      <label class="form-check-label" for="flateIrr">
        <input
          type="radio"
          class="form-check-input"
          id="flateIrr"
          name="optradio"
          #refIRR_Type="ngModel"
          [ngClass]="{
            'is-invalid': eif.submitted && refIRR_Type.invalid,
            'alert-warning':
              refIRR_Type.invalid &&
              (refIRR_Type.dirty ||
                refIRR_Type.touched ||
                refIRR_Type.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_Type"
          required
          value="Flat"
          (change)="handleChange($event)"
        />Flat EMI
      </label>
    </div>
    <div class="form-check-inline" *ngIf="isEdit">
      <label class="form-check-label" for="stepIrr">
        <input
          type="radio"
          class="form-check-input"
          id="stepIrr"
          name="optradio"
          #refIRR_Type="ngModel"
          [ngClass]="{
            'is-invalid': eif.submitted && refIRR_Type.invalid,
            'alert-warning':
              refIRR_Type.invalid &&
              (refIRR_Type.dirty ||
                refIRR_Type.touched ||
                refIRR_Type.untouched)
          }"
          [(ngModel)]="cEIModel.IRR_Type"
          required
          value="Step"
          (change)="handleChange($event)"
        />Step EMI
      </label>
    </div> -->
                <div class="row m-0 mt-3 justify-content-center">
                  <div class="table-border">
                    <div class="item-row header">
                      <div class="lead w50 fs-14">#</div>
                      <div class="lead w120 fs-14">From EMI</div>
                      <div class="lead w120 fs-14">To EMI</div>
                      <div class="lead fs-14">EMI Amount</div>
                      <div class="lead w120 fs-14">No of EMI</div>
                      <div class="lead w150 fs-14">Total Amount</div>
                    </div>
                    <div
                      class="item-row"
                      *ngFor="let semi of stepEMI; let i = index"
                    >
                      <div class="w50">{{ i + 1 }}</div>
                      <div class="w120">
                        <input
                          type="text"
                          numbersOnly
                          readonly
                          [(ngModel)]="semi.FromEMI"
                          placeholder="From EMI"
                          name="FromEMI{{ i }}"
                          id="FromEMI{{ i }}"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="w120">
                        <input
                          type="text"
                          numbersOnly
                          [disabled]="semi.disable || !isEdit"
                          [(ngModel)]="semi.ToEMI"
                          placeholder="To EMI"
                          name="ToEMI{{ i }}"
                          id="ToEMI{{ i }}"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="">
                        <input
                          type="text"
                          numbersOnly
                          [disabled]="semi.disable || !isEdit"
                          [(ngModel)]="semi.EMI_Amount"
                          placeholder="EMI Amount"
                          name="EMI_Amount{{ i }}"
                          id="EMI_Amount{{ i }}"
                          class="form-control input-text-css mr-3 emi-amount"
                        />
                        <button
                          type="button"
                          *ngIf="!semi.disable && isEdit"
                          class="primary-btn"
                          (click)="addNextStep(semi)"
                        >
                          <i
                            class="fa fa-check-circle"
                            style="font-size: 18px"
                          ></i>
                        </button>
                        <button
                          type="button"
                          class="primary-btn"
                          style="margin-left: 10px"
                          *ngIf="!semi.disable && i > 0 && isEdit"
                          (click)="removeLastStep(i)"
                        >
                          <i
                            class="fa fa-minus-circle"
                            style="font-size: 18px"
                          ></i>
                        </button>
                      </div>
                      <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                      <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                    </div>
                    <div class="item-row header" *ngIf="stepEmiData">
                      <div class="lead w50 fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14"><b>Total</b></div>
                      <div class="lead w120 fs-14">&nbsp;</div>
                      <div class="lead fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14">
                        <b>{{ stepEmiData.totalEmi }}</b>
                      </div>
                      <div class="lead w150 fs-14">
                        <b>{{ stepEmiData.totalAmount }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row m-0 col-md-12 justify-content-center"
              *ngIf="isEdit"
            >
              <button
                type="button"
                (click)="onCalculateEmiAndIRR()"
                class="pl-5 pr-5 font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!eif.form.valid"
              >
                Calculate EMI and IRR
              </button>
            </div>
            <div
              class="row col-md-12 m-0 p-0 mt-3"
              id="scrollto"
              *ngIf="emiIrr"
            >
              <div class="col-md-3">
                <h6 class="fs-12">Disbursement Amount</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Disbursement_Amt.toFixed(2) }}
                </p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Interest Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Interest_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Agreement Value</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Agreement_Value.toFixed(2) }}
                </p>
              </div>
              <div class="row m-0 pl-0 pr-0 col-md-3">
                <div class="col-md-6 pr-1">
                  <h6 class="fs-12">LTV</h6>
                  <p class="lead fs-12">{{ emiIrr.LTV }} %</p>
                </div>
                <div class="col-md-6 pl-1">
                  <h6 class="fs-12">ROI</h6>
                  <p class="lead fs-12">{{ emiIrr.ROI }} %</p>
                </div>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">EMI Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Case IRR</h6>
                <p class="lead fs-12">{{ emiIrr.Case_IRR }} %</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Disbursement IRR</h6>
                <p class="lead fs-12">{{ emiIrr.Disbursement_IRR }} %</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Margin</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Margin.toFixed(2) }}</p>
              </div>
            </div>
          </form>
          <div
            class="row col-md-12 m-0 mt-3 justify-content-end"
            *ngIf="emiIrr"
          >
            <button
              type="button"
              (click)="OnFinancialDetailClose()"
              class="btn btn-light font-size-12"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveFinancialDetail()"
              class="ml-4 font-size-12 button-btn"
              *ngIf="isEdit"
              [class.spinner]="loading"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
