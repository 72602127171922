import { Component, Input, OnInit } from "@angular/core";
import { LmsService } from "../../services/lms.service";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-attached-document",
  templateUrl: "./attached-document.component.html",
  styleUrls: ["./attached-document.component.scss"],
})
export class AttachedDocumentComponent implements OnInit {
  attachedDocumentsList: any;
  @Input() loanId;
  @Input() ApplicationNumber: any;

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;

  constructor(private LmsService: LmsService) {}

  ngOnInit(): void {
    this.LmsService.LMS_Get_Loan_Card_Info({ LoanId: this.loanId }).subscribe(
      (res) => {
        // console.log("result: ", res);
        this.attachedDocumentsList = JSON.parse(JSON.stringify(res)).Item18;
      }
    );
  }
}
