import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";

@Component({
  selector: "app-lms-booking-list-list",
  templateUrl: "./lms-booking-list.component.html",
  styleUrls: ["./lms-booking-list.component.scss"],
})
export class LmsBookingListComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  FileCheckingList1: any[] = [];

  showSpinner: boolean = false;
  dataSource: any;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  displayedColumns: string[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('paginatorlist', { read: MatPaginator }) paginatorlist: MatPaginator;
  @ViewChild('SortTable1', { read: MatSort }) sort1: MatSort;

  SESS_BranchId_LMSCP: any;
  SESS_BranchId: any;
  AddLoan: boolean = true;
  DivLOSCP: boolean = true;
  DivLMSCP: boolean = true;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService
  ) { }

  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next("Update Booking List");
    this.dataSharingService.HeaderTitle.next("Update Booking List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (new Date(this.currentUser.SubscriptionDate) <= new Date()) {
      this.AddLoan = false;
    } else {
      this.AddLoan = true;
    }

    // Get session of BranchId from Dashboad Box click start here
    if(sessionStorage.getItem("SESS_LMS")){
      sessionStorage.removeItem("SESS_LOS");
      this.DivLOSCP = false;
      this.SESS_BranchId_LMSCP = sessionStorage.getItem("SESS_BranchId");
    }

    if(sessionStorage.getItem("SESS_LOS")){
      sessionStorage.removeItem("SESS_LMS");
      this.DivLMSCP = false;
      this.SESS_BranchId = sessionStorage.getItem("SESS_BranchId");
    }
    // Get session of BranchId from Dashboad Box click end here
    
    this.LOS_GetFileCheckingHOList();
  }

  LOS_GetFileCheckingHOList() {
    this.showSpinner = true;

    this.displayedColumns = [
      "ApplicationId", "Application_No", "Customer", "Branch", "Product", "ApplicationCreatedOn", "FileCheckingHO_Executive",
      "FileCheckingHO_Status", "FileCheckingHO_TAT_Hr", "ComplotedOn", "Assign",
    ];
    this.displayedColumnsLoan = [
      "LoanId", "LoanAcNo", "Application_No", "Customer", "Branch", "Product", "Customer_Executive", "LoanCreatedOn", "Status", "Assign"
    ];

    this.losService.setFilterDropdownValue({"file-checking-list": 'Completed',});

    this.lmsService.LMS_GETLOS_UPDATEBOOKING_LIST({ 
        FileCheckingHO_Status: 'Completed', LoginUserId: this.currentUser.userId, BranchId : this.SESS_BranchId
      })
      .subscribe((res: any) => {
        if(res.length > 0){
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.FileCheckingList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
          sessionStorage.removeItem("SESS_LOS");
        
        }
        else
          this.showSpinner = false;
      });

    this.lmsService
      .LMS_GetUpdateBookingList({LoginUserId: this.currentUser.userId, BranchId : this.SESS_BranchId_LMSCP}).subscribe((res: any) => {
        //console.log(res);
        if(res.length > 0){
          this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList1);
          this.dataSourceLoan.sort = this.sort1;
          this.dataSourceLoan.paginator = this.paginatorlist;

          sessionStorage.removeItem("SESS_LMS");
          this.showSpinner = false;
        }
        else
          this.showSpinner = false;
      });
         
  }

  applyFilter(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }
  AddNewLoan() {
    this.router.navigate([`/lms/loan-info/`]);
  }
  goToDetailLoan(data: any) {
    // let loanNo = this.encdec.decrypt(data.LoanAcNo);
    // console.log('loanNo', loanNo);
    //if (loanNo) {
    this.router.navigate([
      `/lms/loan-info/${encodeURIComponent(data.LoanId)}/${"L"}`,
    ]);
    // }
  }

  goToDetail(data: any) {
    // let Application_No = this.encdec.decrypt(data.Application_No);
    // if (Application_No) {
    this.router.navigate([
      `/lms/loan-info/${encodeURIComponent(data.ApplicationId)}/${"A"}`,
    ]);
    //}
  }
  exportTable1(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_update_booking_list") as HTMLTableElement;
    const worksheetName = 'UPDATE BOOKING LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }


  exportTable2(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_update_booking_list1") as HTMLTableElement;
    const worksheetName = 'UPDATE BOOKING LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  

  applyFilter1(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }
}
