import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MasterService } from '../../Shared/app.Masters.Service';

import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DepositService } from "../services/deposit.service";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;

@Component({
  selector: 'app-deposit-product-account-details',
  templateUrl: './deposit-product-account-details.component.html',
  styleUrls: ['./deposit-product-account-details.component.scss']
})
export class DepositProductAccountDetailsComponent implements OnInit {
  DefaultData: any = 0;
  chargesHead: any[] = [];
  chargableAccount: any[] = [];
  ChargesModel: any = { Charges_Detail: [] };
  data: any; 
  loading: boolean = false;
  showSpinner: boolean = false;
  ProductId: any = 0;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(private dataSharingService: DataSharingService,
    private router: Router,
    private route: ActivatedRoute,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private _DepositService: DepositService,
    private lmsService: LmsService  ) { }

  ngOnInit() {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    this.Get_ChargeableAccountForDropdown();
    this.Get_DefaultChargeHeadsForDropdown();
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      if (Number(this.ProductId) > 0) {
        this.Get_ProductDefaultChargeableDetails();
      }
    });
  }

  Get_DefaultChargeHeadsForDropdown() {
    this._DepositService.Deposit_Get_ChargeHeads_ForDropdown({ Is_Deafult : true}).subscribe((res: any) => {
      this.DefaultData = res.length;
      this.chargesHead = res;
     this.Get_ProductDefaultChargeableDetails();
    })
  }

  Get_ChargeableAccountForDropdown() {
    this.lmsService.Get_ChargeableAccountForDropdown({}).subscribe((res: any) => {
      this.chargableAccount = res;
    })
  }

  Get_ProductDefaultChargeableDetails() {
    this._DepositService.Deposit_Get_Product_Charges({ ProductId: this.ProductId }).subscribe((res: any) => {
      this.chargesHead.forEach((obj: any) => {
        let _find = res.find((ele: any) => { return ele.ChargeHeadId == obj.Id })
        if (_find) {
          obj.select = true;
          obj.ChargeHeadId = obj.Id;
          obj.Account_HeadId = _find.Account_HeadId;

        }
        else {
          obj.ChargeHeadId = obj.Id;
          obj.select = res.count > 0 ? false : true;
          obj.Account_HeadId = "";

        }
      });
      //console.log("this.chargesHead", this.chargesHead)
      let data;
      data = res;
      if (data.length > 0) {
        this.ChargesModel.Charges_Detail = data.map(obj => {
          //Rename json key like Charge_HeadId to ChagesHead and more like this
          return {
            "Select": true,
            "ChagesHead": obj.Charge_HeadId,
            "Account_HeadId": obj.Charge_Account_HeadId,
          }
        }
        );
      }

    })
  }
  onSaveProductCharges() {
    let _data = {
      DepositProductCharges: [],
    }
    _data.DepositProductCharges = JSON.parse(JSON.stringify(this.chargesHead.filter((obj: any) => { return obj.select })));
    _data.DepositProductCharges.forEach((obj: any) => {
      delete obj.select;
      delete obj.Id;
      delete obj.ChagesHead;
    })
    this.ChargesModel.Charges_Detail.forEach((obj: any) => {
      _data.DepositProductCharges.push({
        ChargeHeadId: Number(obj.ChagesHead),
        Account_HeadId: Number(obj.Account_HeadId)
      });
    });
    this._DepositService.Deposit_Save_Product_Charges({ ProductId: this.ProductId, LoginUserId: this.data.userId, JSON: JSON.stringify(_data) }).subscribe((response) => {
      if (response[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        //this.OnCloseProductModal();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
}
