import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { LmsService } from "../services/lms.service";
declare var $: any;
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { PartnerService } from "src/app/_Partner/services/partner.service";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from '../../Shared/dialog.service';
@Component({
  selector: "app-lms-waiver-creation",
  templateUrl: "./lms-waiver-creation.component.html",
  styleUrls: ["./lms-waiver-creation.component.scss"],
})
export class LmsWaiverCreationComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  WaiwerStatus: any=0;
  @Input() LoanId: any;
  @Input() index: any;
  @Input() Status: any = 'Pending';
  @Input() Waivar_Id: any = 0;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  originalData: any = {};
  dataCustomerSource: any;
  Type: any = "Approved"
  displayedCustomerColumns: string[] = [
    "Number",
    "CustomerId",
    "Customer",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
    "Relation_With_Hirer",
    "ExistingCustomer",
  ];
  questionData: any[] = [];
  dataSourcePendingWaiver: any=null;
  dataSourceApprovedWaiver: any = null
  displayedPendingWaiver: string[] = [
    "Number",
    "ChagesHead",
    "Request_Amount",
    "CreatedBy",
    "CreateOn",
    "ApprovedBy"
  ];
  displayedApprovedWaiver: string[] = [
    "Number",
    "ChagesHead",
    "Request_Amount",
    "Approved_Amount",
    "CreatedBy",
    "CreateOn",
    "ApprovedBy",
    "ApprovedOn"
  ];
  dataCustomerDocSource: any;


  ChargeHeadId: "";
  ChargeHeadCategory: "";
  ChargesModel: any = { Charges: {}, Charges_Detail: [] };
  Bind_Voucher: any;
  accountHeadDropdown: any[] = [];
  IsGSTEnable: any;
  selectedData: any = {};
  // i: number = 0;
  currentUser: any;
  Remark: any;
  Request_Total_Amount: any = 0;
  Approved_Total_Amount: any = 0;
  chargesHeadDropdown: any[] = [];
  arr = [];
  AuthorizBy: any = 0;
  Amount: any;
  AuthorizByName: any = ''
  constructor(
    private lmsService: LmsService,
    public snackBar: MatSnackBar,
    private dialog: DialogService,
    private _Partnerservice: PartnerService,
    private _MasterService: MasterService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  onCloseReceipt() {
    $(`.CustomerInformation${this.index}`).modal("hide");
    this.close.emit(true);
  }
  onOpenViewDetailModal() {
    this.getChargesHeadDropdown();
    // this.showSpinner = true;
    if (this.Status == undefined) {
      this.Status='Pending'
    }
    if (this.Waivar_Id > 0) {
      this.lmsService
        .Get_Waiver_Detail_By_Id({ Waivar_Id: this.Waivar_Id })
        .subscribe((result: any) => {
          if (result.length > 0) {
            this.ChargesModel.Charges_Detail = result;
            if (this.Status == 'Pending')
              this.ChargesModel.Charges.Remark = result[0].Remark;

            if (this.Status == 'Auth-Pending') {
              this.ChargesModel.Charges_Detail.forEach((obj: any) => {
                obj.Approved_Amount = obj.Request_Amount;

              })
            }
            this.getTotalCalculation();
          }
        })
    }
    this.initializeCharges();
    this.initializeChargesDetail();
   

    this.lmsService
      .LMS_Get_Loan_Detail_with_Waiver({ Loan_Id: this.LoanId, Waivar_Id: this.Waivar_Id })
      .subscribe((result: any) => {
        console.log("Data1: ", result);
        this.originalData = result.Item1[0];
        // console.log("DATAAAAA",this.originalData);
        this.dataCustomerSource = new MatTableDataSource(result.Item2);
        this.dataSourceApprovedWaiver = new MatTableDataSource(result.Item3.filter(x => x.Status == "Approved"));

        this.dataSourcePendingWaiver = new MatTableDataSource(result.Item3.filter(x => x.Status == "Pending"));
        this.WaiwerStatus = result.Item3.filter(x => x.Status == "Pending").length;
       // this.WaiwerStatus = result.Item1[0].WaiwerStatus;
        // console.log()
       // this.dataCustomerDocSource = new MatTableDataSource(result.Item4);
       // this.questionData = result.Item5;
        // this.questionData.forEach((obj) => {
        //   if (obj.QuestionType == "Calendar") {
        //     obj.Answer = obj.Answer ? new Date(obj.Answer) : null;
        //   }
        // });
      });

    $(`.CustomerInformation${this.index}`).modal("show");
    $(`.CustomerInformation${this.index}`).css("z-index", "1050");
  }

  getChargesHeadDropdown() {
    this.lmsService
      .Get_Charges_Head_For_Waiver_Dropdown({ Loan_Id: this.LoanId, Waivar_Id: this.Waivar_Id })
      .subscribe((result: any) => {
        console.log("Data", result);
        this.chargesHeadDropdown = JSON.parse(JSON.stringify(result));
        console.log("dropdown dtaa", this.chargesHeadDropdown)

      });
  }

  addMoreData() {
    this.initializeChargesDetail();
  }
  removeData(index: any) {
    this.ChargesModel.Charges_Detail.splice(index, 1);
    this.getTotalCalculation();
  }
  initializeChargesDetail() {
    this.ChargesModel.Charges_Detail.push({
      ChargeHeadId: "",
      Request_Amount: "",
      Approved_Amount:""
    });
  }
  initializeCharges() {
    this.ChargesModel.Charges = {
      Loan_Id: this.LoanId,
      Request_Total_Amount: this.Request_Total_Amount,
      Approved_Total_Amount: this.Approved_Total_Amount,
      Remark: this.Remark,
      LoginUserId: this.currentUser.userId

    };
    setTimeout(() => {

    }, 500);
  }
  onChangeChargesHead(data: any, dindex: any) {
    let _find = this.ChargesModel.Charges_Detail.find((obj: any, index: any) => { return (index != dindex && obj.ChargeHeadId == data.ChargeHeadId); });

    if (_find) {
      this.ChargesModel.Charges_Detail[dindex].ChargeHeadId = "";
      setTimeout(() => {

      }, 500);
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This Charges head already selected.",
        ...this.configSuccess,
      });
    }

  }

  getTotalCalculation() {
    var Amount = 0;
    this.Request_Total_Amount = 0;
    this.Approved_Total_Amount = 0;
    this.ChargesModel.Charges_Detail.forEach((obj: any) => {
      this.Request_Total_Amount = this.Request_Total_Amount + parseFloat(obj.Request_Amount == '' ? 0 : obj.Request_Amount);
      Amount = Amount + + parseFloat(obj.Request_Amount == '' ? 0 : obj.Request_Amount);
      
      this.Approved_Total_Amount = this.Approved_Total_Amount + parseFloat(obj.Approved_Amount == '' || obj.Approved_Amount ==null ? 0 : obj.Approved_Amount);

    });
    Amount = Amount + this.originalData.TotalWaiwer;
    this.lmsService.Get_Waiver_Employee_Upper_Level({ LoginUserId: this.currentUser.userId, Amount: Amount })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.AuthorizBy = res[0].EmpId;
          this.AuthorizByName = res[0].Emp_Name;
        }
        else {
          this.AuthorizBy = 0;
          this.AuthorizByName = '';
        }
      })
  }
  onChangeAmount(data: any, dindex: any) {

    if (parseFloat(dindex.Request_Amount) < parseFloat(dindex.Approved_Amount)) {
      dindex.Approved_Amount = '';
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Approved Amount not greater than Request Amount.",
        ...this.configSuccess,
      });
    } else {
      this.getTotalCalculation();
    }
  }
  saveWaiverRequest() {
    this.dialog.openConfirmDialog(`Are you sure you want to Waiver ?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        }
        else {
          this.ChargesModel.Charges.Request_Total_Amount = this.Request_Total_Amount;
          this.ChargesModel.Charges.Approved_Total_Amount = this.Approved_Total_Amount;

          
          this.ChargesModel.Charges.AuthorizBy = this.AuthorizBy;
          let _finalData = {
            Waivar: this.ChargesModel.Charges,
            WaivarDetail: this.ChargesModel.Charges_Detail
          };

          console.log("_finalData", _finalData);
          console.log("JSON_finalData", JSON.stringify(_finalData));
          this.lmsService.Save_Waiver_Created({ JSON: JSON.stringify(_finalData), Waivar_Id: this.Waivar_Id })
            .subscribe((res: any) => {
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.onCloseReceipt();
              }
            })
        }

      });
  }

  totaltypewise(type) {
    if (this.dataSourceApprovedWaiver != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceApprovedWaiver.filteredData.length; i++) {
        sum += Number(this.dataSourceApprovedWaiver.filteredData[i][type]);
      }
      return (sum > 0) ? sum : "";
    }
    return 0;
  }
  totalPendingtypewise(type) {
    if (this.dataSourcePendingWaiver != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourcePendingWaiver.filteredData.length; i++) {
        sum += Number(this.dataSourcePendingWaiver.filteredData[i][type]);
      }
      return (sum > 0) ? sum : "";
    }
    return 0;
  }
  saveWaiverApproved() {
    this.dialog.openConfirmDialog(`Are you sure you want to ` + this.Type +` this Waiver ?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        }
        else {
          let _finalData = {
            WaivarDetail: this.ChargesModel.Charges_Detail
          };
          this.lmsService.Save_Waiver_Authorized({ LoginUserId: this.currentUser.userId, Remark: this.ChargesModel.Charges.Remark, Status: this.Type, Waiver_Id: this.Waivar_Id, Total_Approved_Amount: this.Approved_Total_Amount, JSON: JSON.stringify(_finalData) })
            .subscribe((res: any) => {
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.onCloseReceipt();
              }
            })

        }
      })

  }
}
