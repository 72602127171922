<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row col-md-12  m-0 formborder d-flex align-items-center justify-content-between">
  <div class="col-md-4 p-1">
    <span> Status</span>
    <select name="status"
            id="status"
            class="form-control input-text-css"
            (change)="LMS_Get_DV_List()"
            [(ngModel)]="status">
      <option value="Pending">Pending</option>
      <option value="Hold">Hold</option>
      <option value="Completed">Completed</option>
    </select>
  </div>
  <div class="col-md-4 p-1 ">
    <span> Source Type </span>
    <select name="SelectSourceType"
            id="SelectSourceType"
            #refSelectSourceType="ngModel"
            (change)="Get_PartnerForDropdown()"
            [(ngModel)]="SelectSourceType"
            class="form-control input-text-css">
      <option value="">Select Source Type</option>
      <option *ngFor="let lead of leadSourceDropdown"
              [value]="lead.Lead_Source">
        {{ lead.Lead_Source }}
      </option>
    </select>
  </div>

  <div class="col-md-4 p-1">
    <div *ngIf="
      SelectSourceType == 'Agent' ||
      SelectSourceType == 'DSA' ||
      SelectSourceType == 'Dealer' ||
      SelectSourceType == 'Sales Executive'
    ">
      <span> Source Name</span>
      <select name="LeadSourceName"
              id="LeadSourceName"
              #refLeadSourceName="ngModel"
              [(ngModel)]="LeadSourceName"
              class="form-control input-text-css"
              (change)="LMS_Get_DV_List()">
        <option value="">Select Lead Reference</option>
        <option *ngFor="let partner of partnerDropdown"
                [value]="partner.PartnerId">
          {{ partner.Partner_Name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-7 text-right">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input type="text"
                 id="FilterInputSearch"
                 (keyup)="applyFilter($event.target.value)"
                 name="FilterInputSearch"
                 placeholder="What're you searching for?"
                 class="form-control border-0 bg-light" />
          <div class="input-group-append">
            <button id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3">
  <div class="">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSource"
                 matSort
                 matTableExporter
                 #exporter="matTableExporter"
                 [hiddenColumns]="[displayedColumns.indexOf('Action')]">
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           style="max-width: 50px"
                           *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    style="max-width: 50px"
                    class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Application No</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_No }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Date</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
            Loan No
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
            Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Product</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Source Type</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceType }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="SourceName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Source Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceName }}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
            Loan Amount
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_LoanAmount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>DISB Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Paid_Amount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Paid Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Amount - row.Pending_Amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Pending_Amount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Pending Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Pending_Amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Hold_Amount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Hold Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Hold_Amount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header
                           class="grid-header j-c-center"
                           style="max-width: 60px"
                           *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    class="grid-cell j-c-center"
                    style="max-width: 60px">
            <i class="fa fa-arrow-circle-right arrow-circle action-btn"
               aria-label="true"
               (click)="goToDetail(row)"></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row [ngClass]="{
            Disbursment: row.Bankname == '' || row.Bankname == null
          }"
                 *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button class="mt-3 btn  font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[10, 20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_payment_authorization"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursement Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pending Amount
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.Amount }}</td>
    <td>{{ x.Pending_Amount }}</td>
  </tr>
</table>
