import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { QuickLoanService } from "../services/quickLoan.service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-quickloan-appilcation-status-report",
  templateUrl: "./quickloan-appilcation-status-report.component.html",
  styleUrls: ["./quickloan-appilcation-status-report.component.scss"],
})
export class QuickloanAppilcationStatusReportComponent implements OnInit {
  showSpinner: boolean = false;
  DateFrom: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  DateTo: any = new Date();
  DataSource: any;
  JsonData: any;
  selectedId: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    "Id",
    "Application_No",
    "CustomerName",
    "LoanAcNo",
    "Process_Name",
    "empName",
    "BranchName",
    "ProductName",
    "ApplicationCreatedOn",
    "SourceType",
    "SourceName",
    "SalesExecutive",
    "RequiredAmount",
    "Sanction_Or_ApprovedAmount",
    "TAT_Hr",
  ];
  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private _QuickLoanService: QuickLoanService
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Application Current Status");
  }

  searchQuickloanApplicationStatusReport() {
    this._QuickLoanService
      .QuickLoan_ApplicationStatus_Report({
        fromDate: this.DateFrom,
        toDate: this.DateTo,
      })
      .subscribe((response: any) => {
        console.log("Api call data", response);
        if (response.length > 0) {
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(response))
          );
          this.DataSource.paginator = this.paginator;
          this.JsonData = response;
          console.log("Data", response);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataSource = null;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "application_status_report"
    ) as HTMLTableElement;
    const worksheetName = "APPLICATION STATUS REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }


  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

}
