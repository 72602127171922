import { Component, OnInit, Input } from "@angular/core";
import { LmsService } from "../../services/lms.service";

@Component({
  selector: "app-foir-card",
  templateUrl: "./foir-card.component.html",
  styleUrls: ["./foir-card.component.scss"],
})
export class FoirCardComponent implements OnInit {
  @Input() loanId;
  IncomeModel: any = [];
  ExpenseModel: any = [];
  resultItem19CurrentEmi: number = 0;
  TotalObligation: any;
  CurrentEmi: any;
  ObligationModel: any = [];
  foirCardDetails: any;
  totalCalculator: any = { total: 0.0 };
  totalCalculator1: any = { total: 0.0 };
  totalMonthlyExpenseCalculated: { total: number };
  totalMonthlyIncomeCalculated: { total: number };
  totalCalculatorObligation: any = { total: 0.0 };
  totalMonthlyObligationCalculated: { total: number } = { total: 0 };
  Margin: number;
  EMIEligibility: number = 0;
  emiElegibilityPercent: number = 0;
  constructor(private LmsService: LmsService) {}

  ngOnInit(): void {
    this.LmsService.LMS_Get_Loan_Card_Info({ LoanId: this.loanId }).subscribe(
      (result: any) => {
        console.log("main api call response", result);
        // this.emiElegibilityPercent = JLMS_Get_Loan_Card_InfoSON.parse(
        //   JSON.stringify(result.Item19.Eligibility_Percentage)
        // );
        console.log("EMI Eligibility", this.emiElegibilityPercent);
        // console.log("foirCardDetails: ", this.foirCardDetails);

        if (result) {
          // this.EMIEligibility =
          //   (this.Margin * result.Item19.Eligibility_Percentage) / 100;
          this.resultItem19CurrentEmi = result?.Item19[0]?.CurrentEMI;
          this.IncomeModel = result.Item19.filter((x) => x.Type == "Income");
          console.log("INCOME MODEL", this.IncomeModel);
          this.ExpenseModel = result.Item19.filter((x) => x.Type == "Expense");
          console.log("Expense MOdel", this.ExpenseModel);

          this.ObligationModel = result.Item19.filter(
            (x) => x.Type == "Obligation"
          );

          console.log("Obligation Model", this.ObligationModel);

          this.CurrentEmi = this.resultItem19CurrentEmi ?? 0;
          let _total: any = 0;

          let totalMonthlyIncome: any = 0;
          this.IncomeModel.forEach((obj: any) => {
            _total =
              _total +
                parseFloat(
                  obj.FOIR_Amount == undefined || obj.FOIR_Amount == ""
                    ? 0
                    : obj.FOIR_Amount
                ) || 0;
            totalMonthlyIncome =
              totalMonthlyIncome + parseFloat(obj.Amount) || 0;
          });

          this.totalCalculator = {
            total: parseFloat(_total),
          };
          this.totalMonthlyIncomeCalculated = {
            total: parseFloat(totalMonthlyIncome),
          };
          let _totalExp: any = 0;
          let totalMonthlyExpense: any = 0;
          this.ExpenseModel.forEach((obj: any) => {
            _totalExp = _totalExp + parseFloat(obj.FOIR_Amount) || 0;
            totalMonthlyExpense =
              totalMonthlyExpense + parseFloat(obj.Amount) || 0;
          });
          this.totalCalculator1 = {
            expAmount: parseFloat(_totalExp),
          };
          this.totalMonthlyExpenseCalculated = {
            total: parseFloat(totalMonthlyExpense),
          };
          this.Margin =
            this.totalCalculator.total - this.totalCalculator1.expAmount;

          let _totalObligation: any = 0;
          let totalMonthlyObligation: any = 0;
          this.ObligationModel.forEach((obj: any) => {
            _totalObligation =
              _totalObligation + parseFloat(obj.FOIR_Amount) || 0;
            totalMonthlyObligation =
              totalMonthlyObligation + parseFloat(obj.Amount) || 0;
          });
          this.totalCalculatorObligation = {
            total: parseFloat(_totalObligation),
          };
          this.TotalObligation =
            parseFloat(_totalObligation) + parseFloat(this.CurrentEmi);
          this.totalMonthlyObligationCalculated = {
            total: parseFloat(totalMonthlyObligation),
          };
        } else {
          this.IncomeModel = [];
          this.ExpenseModel = [];
          this.ObligationModel = [];
        }
      }
    );
  }

  getPercentage(): string {
    const total = this.totalCalculator.total;
    const expAmount = this.totalCalculator1.expAmount;

    if (total === 0) {
      return "0.00"; // Avoid division by zero
    }

    const percentage = (expAmount / total) * 100;
    return isFinite(percentage) ? percentage.toFixed(2) : "0.00";
  }

  getObligationPercentage(): string {
    const total = this.totalCalculator?.total ?? 0;
    const obligation = this.TotalObligation ?? 0;
  
    if (total === 0) {
      return '0.00'; // Avoid division by zero
    }
  
    const percentage = (obligation / total) * 100;
    return isFinite(percentage) ? percentage.toFixed(2) : '0.00';
  }


  getAdjustedObligationPercentage(): string {
    const total = (this.totalCalculator?.total ?? 0) - (this.totalCalculator1?.expAmount ?? 0);
    const obligation = this.TotalObligation ?? 0;
  
    if (total === 0) {
      return '0.00'; // Avoid division by zero
    }
  
    const percentage = (obligation / total) * 100;
    return isFinite(percentage) ? percentage.toFixed(2) : '0.00';
  }
}
