import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import * as XLSX from "xlsx";
import { ActivatedRoute, Params } from "@angular/router";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { ReportsService } from "../../_Reports/services/reports.service";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { constantUrl } from "src/app/Shared/constantUrl";

interface TrialBalance {
  Group_Name: string;
  OpeningBalanceDR?: number;
  OpeningBalanceCR?: number;
  TransDR?: number;
  TransCR?: number;
  ClosingBalanceDR?: number;
  ClosingBalanceCR?: number;
  AccountId?: number;
  children?: TrialBalance[];
}

interface ExampleFlatNode {
  expandable: boolean;
  Group_Name: string;
  OpeningBalanceDR?: number;
  OpeningBalanceCR?: number;
  TransDR?: number;
  TransCR?: number;
  ClosingBalanceDR?: number;
  ClosingBalanceCR?: number;
  level: number;
  AccountId: number;
}

@Component({
  selector: "app-trial-balance",
  templateUrl: "./trial-balance.component.html",
  styleUrls: ["./trial-balance.component.scss"],
})
export class TrialBalanceComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalCalculator: any;
  loading: boolean = false;
  FileCheckingList: any[] = [];
  dataSource: any;
  dataSourceAccount: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  AccountHead: any;
  TransationDetail: any = "1";
  AccountName: any;
  AccountHeadDropdown: any[] = [];
  selectGroupId: any = 0;
  displayedCTreeolumnsprint: string[] = [];
  displayedAccountColumns: string[] = [
    "voucherid",
    "Voucher_Date",
    "Voucher_No",
    "CaseNo",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
    "ViewAction",
  ];

  displayedColumns: string[] = [
    "AccountId",
    "Account_Name",
    "ClosingBalanceDR",
    "ClosingBalanceCR",
    "ViewAction",
  ];

  displayedCTreeolumns = [
    "Group_Name",
    "OpeningBalanceDR",
    "OpeningBalanceCR",
    "TransDR",
    "TransCR",
    "ClosingBalanceDR",
    "ClosingBalanceCR",
    "Action",
  ];

  @ViewChild("tree") tree;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("Paginglist", { read: MatPaginator }) Paginglist: MatPaginator;

  groupList: any[] = [];
  groupDropdown: any[] = [];
  BankList: any[] = [];
  groupModel: any = {};
  dropdownSettings: any = {};
  myForm: UntypedFormGroup;
  AccountControl = new UntypedFormControl();
  disabled = false;
  ShowFilter = true;
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  ShowDelete: boolean = false;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  filteredAccountHead: Observable<any[]>;
  displayedLoanColumns: string[] = [
    "Type",
    "CaseNo",
    "Customer",
    "Branch",
    "Category",
    "AccountHead",
    "AmountCR",
    "AmountDR",
  ];
  displayedAccountingColumns: string[] = [
    "AccountHead",
    "Branch",
    "AmountCR",
    "AmountDR",
  ];
  limitSelection = false;
  FyearId: any;
  maxDate: any;
  minDate: any;
  IsGroupWise: any = "0";
  TREE_DATA: TrialBalance[] = [];
  div_tree: boolean = false;
  div_list: boolean = false;
  tree_data: any;
  JsonData: any[] = [];
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  TotRow: any = 50;
  rowdata: any;

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  private transformer = (node: TrialBalance, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Group_Name: node.Group_Name,
      OpeningBalanceDR: node.OpeningBalanceDR,
      OpeningBalanceCR: node.OpeningBalanceCR,
      TransDR: node.TransDR,
      TransCR: node.TransCR,
      ClosingBalanceDR: node.ClosingBalanceDR,
      ClosingBalanceCR: node.ClosingBalanceCR,
      AccountId: node.AccountId,
      level: level,
    };
  };
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSourceTree: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  BalanceType: any = 0;
  DivAnnualy: boolean = true;
  DivMonthly: boolean = false;
  DivDaily: boolean = false;
  dataSourceModel: any;
  dataSourceMonthly: any = "";
  dataSourceDaily: any = "";

  displayedColumnsMonthly: string[] = [
    "Id",
    "Month_Year",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];
  displayedColumnsDaily: string[] = [
    "Id",
    "VoucherDate",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];

  displayedColumnsModel: string[] = [
    "voucherid",
    "VoucherDate",
    "Voucher_No",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
    "ViewAction",
  ];

  ExpandBtn: boolean = true;
  CollaspeBtn: boolean = false;
  FileCheckingList1: any;
  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private _commonfunctions: CommonFunctions
  ) {
    //this.div_list = true;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Trial Balance");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Acc_GroupDropdown();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });
    //this.dropdownSettings = {
    //  singleSelection: true,
    //  idField: 'AccountId',
    //  textField: 'Account_Name',
    // // selectAllText: 'Select All',
    ////  unSelectAllText: 'UnSelect All',
    //  allowSearchFilter: this.ShowFilter
    //};
    //this.filteredAccountHead = this.AccountControl.valueChanges.pipe(startWith(''),
    //  map(value => typeof value === 'string' ? value : value.name),
    //  map(name => name ? this._filter(name) : this.AccountHeadDropdown.slice())
    //);
  }

  public highlightRow(row) {
    this.selectedId = row.AccountId;
  }

  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    let sh;
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.GroupId] = arrElem;
      mappedArr[arrElem.GroupId]["children"] = [];
    }

    for (var GroupId in mappedArr) {
      if (mappedArr.hasOwnProperty(GroupId)) {
        mappedElem = mappedArr[GroupId];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.Parent_GroupId) {
          mappedArr[mappedElem["Parent_GroupId"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  onAccountHeadhSelect(event: any) {
    this.AccountHead = event.option.value.AccountId;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }

  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  onSearchAccountStatement() {
    this.showSpinner = true;
    //var from = new Date(this.VoucherDateFrom.getDate() + 1);
    //var to = new Date(this.VoucherDateTo.getDate() + 1);
    this.ExpandBtn = true;
    this.CollaspeBtn = false;
    if (this.IsGroupWise == 1) {
      this.lmsService
        .Get_TrialBalance({
          From: this.VoucherDateFrom,
          To: this.VoucherDateTo,
          StartYear: this.minDate.getFullYear(),
        })
        .subscribe((res: any) => {
          console.log("Res", res);
          if (res.length > 0) {
            let Data;
            res.forEach((x) => {
              console.log("x", x);
              if (
                x.OpeningBalanceDR > 0 &&
                x.OpeningBalanceCR > 0 &&
                x.TranTypeDR > 0 &&
                x.TranTypeCR > 0 &&
                x.ClosingBalanceDR > 0 &&
                x.ClosingBalanceCR > 0
              ) {
                Data = x;
              }
            });

            //res = res.filter(x => x.OpeningBalanceDR > 0 && x.OpeningBalanceCR > 0 && x.TranTypeDR > 0 && x.TranTypeCR > 0 && x.ClosingBalanceDR > 0 && x.ClosingBalanceCR > 0);

            this.FileCheckingList = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(this.FileCheckingList);
            this.dataSource.sort = this.sort;
            //this.dataSource.paginator = this.paginator;
            this.getChargeshead();
            this.showSpinner = false;
            this.div_tree = false;
            this.div_list = true;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.showSpinner = false;
          }
        });
    }
    if (this.IsGroupWise == 0) {
      this.BalanceType = 0;
      this.dataSourceMonthly = "";
      this.dataSourceDaily = "";
      this.DivAnnualy = true;
      this.DivMonthly = false;
      this.DivDaily = false;
      //this.displayedCTreeolumns = ['Group_Name', 'TranTypeDR', 'TranTypeCR', 'Action'];
      this.lmsService
        .Trail_Balance_GroupBY({
          From: this.VoucherDateFrom,
          To: this.VoucherDateTo,
          StartYear: this.minDate.getFullYear(),
          groupId: this.selectGroupId,
        })
        .subscribe((res: any) => {
          if (res.length > 0) {
            //console.log(res);
            var tree = this.unflatten(res);
            this.JsonData = JSON.parse(JSON.stringify(tree));
            let tree_data = JSON.parse(JSON.stringify(tree, null, " "));
            this.tree_data = tree_data;
            console.log("this.tree_data", tree_data);
            this.dataSourceTree.data = tree_data;
            this.getChargeshead();
            this.showSpinner = false;
            this.div_tree = true;
            this.div_list = false;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.showSpinner = false;
          }
        });
    }
  }
  goToDetail(row) {
    this.rowdata = "";
    this.rowdata = row;
    this.dataSourceAccount = "";
    this.onSearchAccountingDetail(false);

    /*$('#AccountingDetail').modal('show');
    $("#AccountingDetail").css("z-index", "1050"); */

    /*this.lmsService
      .Get_Acc_Leadger({ AccountId: row.AccountId, From: this.VoucherDateFrom, To: this.VoucherDateTo, StartYear: this.minDate.getFullYear()  })
      .subscribe((res: any) => {
        console.log(res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.AccountName = row.Account_Name;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip()
        }, 1000)
        this.showSpinner = false;
      });*/
  }

  onSearchAccountingDetail(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = "";
    this.dataSourceDaily = "";
    this.DivAnnualy = true;
    this.DivMonthly = false;
    this.DivDaily = false;

    this.showSpinner = true;
    this.loading = true;

    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("RES", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));

        $("#AccountingDetail").modal("show");
        $("#AccountingDetail").css("z-index", "1050");

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/

        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
        console.log("wwdwd", this.FileCheckingList1);
        this.dataSourceAccount.paginator = this.paginator;
        this.AccountName = this.rowdata.Group_Name;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
        this.showSpinner = false;
        this.loading = false;
      });
  }
  LoadMore() {
    this.onSearchAccountingDetail(true);
  }

  OnCloseVoucherDetail() {
    $("#VoucherDetail").modal("hide");
  }
  OnCloseAccountingDetail() {
    $("#AccountingDetail").modal("hide");
  }
  goToDetailVoucher(row) {
    $("#VoucherDetail").modal("show");
    $("#VoucherDetail").css("z-index", "1060");
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        if (
          this.voucherModel.Voucher.Voucher_Type == "Receipt" ||
          this.voucherModel.Voucher.Voucher_Type == "Journal" ||
          this.voucherModel.Voucher.Voucher_Type == "Payment"
        )
          this.ShowDelete = true;
        else this.ShowDelete = false;
        this.voucherModel.Voucher_Detail.forEach((x) => {
          x.AmountDR = 0.0;
          x.AmountCR = 0.0;
          if (x.TranType == "CR") x.AmountCR = x.Amount;
          if (x.TranType == "DR") x.AmountDR = x.Amount;
        });
        if (
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == "" ||
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == null
        )
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = "CASH";
        this.onVoucherTagChange();
      });
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      _total = _total + obj.Amount || 0;
      _totalCR = _totalCR + obj.Amount || 0;
      obj.TaxSlab.forEach((tobj: any) => {
        _total = _total + tobj.TaxAmount || 0;
        _totalTX = _totalTX + tobj.TaxAmount || 0;
      });
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }
  //onChangeMode() {
  //  if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
  //    this.voucherModel.Voucher.Voucher_Cheque_No = "";
  //    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
  //    this.voucherModel.Voucher.Voucher_Bank_Name = "";
  //    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
  //  }
  //  else {
  //    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
  //    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
  //  }
  //}
  onChangeChargesHead(data: any, dindex: any) {
    //let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
    //  return index != dindex && obj.CaseNo == data.CaseNo && obj.ChargeHeadId == data.ChargeHeadId
    //});
    //if (_find) {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
    //  data.ChargeHeadId = "";
    //  data.Amount = "";
    //  data.AccountId = "";
    //  data.ChargeHeadCategory = "";
    //  data.TaxSlab = [];
    //  if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
    //    data.BranchId = "";
    //  }
    //  this.getTotalCalculation();
    //  return;
    //}
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        ProductId: data.ProductId || 0,
        TaxType: data.TranType,
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;
            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
          } else {
            data.ChargeHeadId = "";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "category does not have account head.",
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
      });
  }
  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = "CASH";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date),
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  OPTranChange() {
    if (this.TransationDetail == "1") {
      this.displayedColumns = [
        "AccountId",
        "Account_Name",
        "OpeningBalanceDR",
        "OpeningBalanceCR",
        "TranTypeDR",
        "TranTypeCR",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
        "ViewAction",
      ];
      this.displayedCTreeolumns = [
        "Group_Name",
        "OpeningBalanceDR",
        "OpeningBalanceCR",
        "TransDR",
        "TransCR",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "AccountId",
        "Account_Name",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
        "ViewAction",
      ];
      this.displayedCTreeolumns = [
        "Group_Name",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
        "Action",
      ];
    }
  }
  calculateTranTypeDRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateTranTypeCRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateOpeningBalanceDRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["OpeningBalanceDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateOpeningBalanceCRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["OpeningBalanceCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateClosingBalanceDRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["ClosingBalanceDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateClosingBalanceCRTotal() {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i]["ClosingBalanceCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateACTranTypeDR() {
    if (this.dataSourceAccount != undefined) {
      if (
        this.dataSourceAccount != null &&
        this.dataSourceAccount != undefined
      ) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
          sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateACTranTypeCR() {
    if (this.dataSourceAccount != undefined) {
      if (this.dataSourceAccount != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
          sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateACTranTypeTotal() {
    if (this.dataSourceAccount != null) {
      /*let sum = 0;
      let DR = this.calculateACTranTypeDR()
      let CR = this.calculateACTranTypeCR();
      sum = Number(DR) - Number(CR);
      if (sum > 0)
        return sum.toFixed(2).toString() + " DR"
      else
        return sum.toFixed(2).toString().split('-')[1] != undefined ? sum.toFixed(2).toString().split('-')[1] + " CR" : 0.00;*/
      var lastBalance =
        this.dataSourceAccount.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }

  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "VoucherDate To greater than VoucherDate From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.VoucherDateTo = new Date();
        });
    }
  }
  DeleteVoucher() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove this Voucher.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService
            .Delete_Voucher({
              VoucherId: this.voucherModel.Voucher.VoucherId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                if (
                  this.voucherModel.Voucher_Detail.find((obj: any) => {
                    return obj.ChargeHeadId == 34;
                  })
                )
                  this.lmsService
                    .LMS_Update_EMI_Allocation({
                      VoucherId: this.voucherModel.Voucher.VoucherId,
                    })
                    .subscribe((res1: any) => {});

                this.OnCloseVoucherDetail();
                this.onSearchAccountStatement();
              }
            });
        }
      });
  }

  /*calculateTRTotalByCR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }*/
  calculateTRTotalByCRDR(type) {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      let tr_type = type == 1 ? "TranTypeCR" : "TranTypeDR";
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i][tr_type]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceDR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["OpeningBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }
  calculateOpeningBalanceCR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["OpeningBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }
  calculateTransDR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["TransDR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }
  calculateTransCR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["TransCR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }
  calculateClosingBalanceDR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["ClosingBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }
  calculateClosingBalanceCR() {
    if (this.dataSourceTree.data != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceTree.data.length; i++) {
        sum += Number(this.dataSourceTree.data[i]["ClosingBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0.0;
  }

  printdata() {}
  GetData(data) {
    if (data == true) {
      this.onSearchAccountStatement();
    }
  }

  ChangeBalanceType() {
    if (this.BalanceType == 0) {
      this.DivAnnualy = true;
      this.DivMonthly = false;
      this.DivDaily = false;
      this.GetACCLeadgerByAnnualy(false);
    } else if (this.BalanceType == 1) {
      this.DivMonthly = true;
      this.DivAnnualy = false;
      this.DivDaily = false;
      this.GetACCLeadgerByMonthly();
    } else {
      this.DivDaily = true;
      this.DivAnnualy = false;
      this.DivMonthly = false;
      this.GetACCLeadgerByDaily();
    }
  }

  totaltypeDaily(type) {
    if (this.dataSourceDaily != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceDaily.filteredData.length; i++) {
        sum += Number(this.dataSourceDaily.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
  totaltypeMonthly(type) {
    if (this.dataSourceMonthly != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceMonthly.filteredData.length; i++) {
        sum += Number(this.dataSourceMonthly.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
  calculateMonthlyBalance() {
    if (this.dataSourceMonthly != null) {
      var lastBalance = this.dataSourceMonthly.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  calculateDailyBalance() {
    if (this.dataSourceDaily != null) {
      var lastBalance = this.dataSourceDaily.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  GetACCLeadgerByAnnualy(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = "";
    this.dataSourceDaily = "";

    this.FileCheckingList = [];
    /*if (Addmore == false) {
      this.FileCheckingList = [];
    }

    if (this.FileCheckingList.length > 0) {
      NextCnt = this.dataSourceAccount.filteredData.length;
    }*/

    this.showSpinner = true;
    this.loading = true;

    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("RES", res);

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.dataSourceAccount.paginator = this.paginator;
        this.showSpinner = false;
        this.loading = false;
      });
  }
  GetACCLeadgerByMonthly() {
    this.FileCheckingList = [];
    this.showSpinner = true;

    this.reportsService
      .Report_ACC_Leadger_By_Monthly({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("Report_ACC_Leadger_By_Monthly :", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceMonthly = new MatTableDataSource(
            this.FileCheckingList
          );
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  GetACCLeadgerByDaily() {
    this.FileCheckingList = [];
    this.showSpinner = true;
    this.reportsService
      .Report_ACC_Leadger_By_Daily({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("Report_ACC_Leadger_By_Daily :", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceDaily = new MatTableDataSource(this.FileCheckingList);
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  goToDetailModel(row) {
    this.FileCheckingList = [];
    this.dataSourceModel = "";

    var firstDayOfMonth, lastDayOfMonth, RowIndex, DayMonth;

    if (this.BalanceType == 1) {
      RowIndex = this.dataSourceMonthly.data.indexOf(row, 0);
      if (RowIndex == 1) {
        firstDayOfMonth = this.VoucherDateFrom;
        lastDayOfMonth = new Date(
          this.VoucherDateFrom.getFullYear(),
          this.VoucherDateFrom.getMonth() + 1,
          0
        );
      } else {
        DayMonth = new Date(row.Month_Year);
        firstDayOfMonth = new Date(
          DayMonth.getFullYear(),
          DayMonth.getMonth(),
          1
        );
        lastDayOfMonth = new Date(
          DayMonth.getFullYear(),
          DayMonth.getMonth() + 1,
          0
        );
      }
    } else {
      firstDayOfMonth = row.Voucher_Date;
      lastDayOfMonth = row.Voucher_Date;
    }

    //console.log("firstDayOfMonth", firstDayOfMonth, "----- lastDayOfMonth", lastDayOfMonth);
    this.showSpinner = true;
    this.lmsService
      .Get_Acc_Leadger({
        AccountId: row.AccountId,
        From: firstDayOfMonth,
        To: lastDayOfMonth,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("Get_Acc_Leadger :", res);
        $("#VoucherModel").modal("show");
        $("#VoucherModel").css("z-index", "1050");
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceModel = new MatTableDataSource(this.FileCheckingList);
        //this.dataSourceModel.sort = this.sort;
        //this.dataSourceModel.paginator = this.Paginglist;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 100);
        this.showSpinner = false;
      });
  }
  OnCloseVoucherModel() {
    $("#VoucherModel").modal("hide");
  }

  totaltypeModel(type) {
    if (this.dataSourceModel != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceModel.filteredData.length; i++) {
        sum += Number(this.dataSourceModel.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypeModelBal() {
    if (this.dataSourceModel != null) {
      var lastBalance = this.dataSourceModel.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }

  OnClickExpandAll() {
    this.ExpandBtn = false;
    this.CollaspeBtn = true;
    this.treeControl.expandAll();
  }
  OnClickCollaspeAll() {
    this.ExpandBtn = true;
    this.CollaspeBtn = false;
    this.treeControl.collapseAll();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    //const uri = "data:application/vnd.ms-excel;base64,";
    //const template =
    //  '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    //const base64 = (s: string): string =>
    //  window.btoa(unescape(encodeURIComponent(s)));
    //const format = (s: string, c: { [key: string]: string }): string =>
    //  s.replace(/{(\w+)}/g, (m, p) => c[p]);

    //const table = document.getElementById("trial_balance") as HTMLTableElement;
    //const worksheetName = "TRAIL BALANCE"; // Set the desired worksheet name here
    //const ctx = { worksheet: worksheetName, table: table.innerHTML };
    //const downloadLink = document.createElement("a");

    //// Generate the data URI for the Excel file
    //downloadLink.href = uri + base64(format(template, ctx));

    //// Set the file name for the download
    //downloadLink.download = worksheetName + ".xls";

    //// Trigger the download
    //downloadLink.click();
    // const flattenedData = this.flattenTreeData(this.dataSourceTree.data);
    console.log("this.tree_data", this.tree_data);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.flattenTree(this.tree_data)
    );
    // ws['!rows'] = [{ hpx: 20, s:{ bold: true } }];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "tree_data.xlsx");
  }

  flattenTree(nodes: any[], parentName: string = ""): any[] {
    let flatData: any[] = [];

    nodes.forEach((node) => {
      const nodeName = parentName
        ? `${parentName} > ${node.Group_Name}`
        : node.Group_Name;
      const AccountName =
        node.AccountId > 0 && node.AccountId != null ? node.Group_Name : "";
      const Group_DR =
        node.AccountId > 0 && node.AccountId != null
          ? ""
          : parseFloat(node.ClosingBalanceDR);
      const Group_CR =
        node.AccountId > 0 && node.AccountId != null
          ? ""
          : parseFloat(node.ClosingBalanceCR);

      const Account_DR =
        node.AccountId > 0 && node.AccountId != null
          ? parseFloat(node.ClosingBalanceDR)
          : "";
      const Account_CR =
        node.AccountId > 0 && node.AccountId != null
          ? parseFloat(node.ClosingBalanceCR)
          : "";

      flatData.push({
        Group: nodeName,
        AccountName: AccountName,
        Opening_DR: node.OpeningBalanceDR,
        Opening_CR: node.OpeningBalanceCR,
        Transaction_DR: node.TransDR,
        Transaction_CR: node.TransCR,
        Account_CL_DR: Account_DR,
        Account_CL_CR: Account_CR,
        Group_CL_DR: Group_DR,
        Group_CL_CR: Group_CR,
      });

      if (node.children) {
        flatData = [...flatData, ...this.flattenTree(node.children, nodeName)];
      }
    });

    return flatData;
  }
  exportToExcel(data: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
  onCloseAccountStatementPrintModel() {
    $("#AccountStatementPrintModel").modal("hide");
  }

  printtrialdata() {
    if (this.TransationDetail == "1") {
      this.displayedCTreeolumnsprint = [
        "Group_Name",
        "OpeningBalanceDR",
        "OpeningBalanceCR",
        "TransDR",
        "TransCR",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
      ];
    } else {
      this.displayedCTreeolumnsprint = [
        "Group_Name",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
      ];
    }
    $("#AccountStatementPrintModel").modal("show");
    $("#AccountStatementPrintModel").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintTrailDocuments(divName, Header, "");
  }

  onDocsPrintAccounthead(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }

  exportTableAccountHead(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "trial_balance_AccountHead"
    ) as HTMLTableElement;
    const worksheetName = "TRAIL BALANCE ACCOUNT HEAD WISE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  onClose() {
    $("#trailbalaceaccountheadmodel").modal("hide");
  }

  printtrialdataaccounthead() {
    $("#trailbalaceaccountheadmodel").modal("show");
    $("#trailbalaceaccountheadmodel").css("z-index", "1050");
  }

  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
}
