import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DialogService } from '../../Shared/dialog.service'
import { MasterService } from '../../Shared/app.Masters.Service';

declare var $: any;

@Component({
  selector: 'app-voucher-entry-layout',
  templateUrl: './voucher-entry-layout.component.html',
  styleUrls: ['./voucher-entry-layout.component.scss']
})
export class VoucherEntryLayoutComponent implements OnInit {
  PageAcess_Receipt: any;
  PageAcess_Payment: any;
  PageAcess_journal: any;
  PageAcess_Contra: any;
  showSpinner = false;
  //#region < Constructor AppUserLayoutComponent >
  constructor(private MasterService: MasterService, private _Route: Router, public snackBar: MatSnackBar, private http: HttpClient, private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private _location: Location, private dataSharingService: DataSharingService, private dialog: DialogService) {
    var data = JSON.parse(sessionStorage.getItem('currentUser'));
    this._Route.navigate(['/lms-accounting/voucher/voucher']);
    this.MasterService.Get_Page_Rights_Of_EMP({ MMID: '69', LoginUserId: data.userId }).subscribe((result: any) => {
      this.showSpinner = true;

      
      if (result.length > 0) {
        
       
        let AllPage = result;
        console.log("all pages", AllPage);
        if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != null) {
          this.PageAcess_Receipt = AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt");
        }
        if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != null) {
          this.PageAcess_Payment = AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment");
        }
        if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != null) {
          this.PageAcess_Contra = AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra");
        }
        if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != null) {
          this.PageAcess_journal = AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal");
        }

        const navreceipttab = document.getElementById('nav-receipt-tab');
        const navpaymenttab = document.getElementById('nav-payment-tab');
        const navjournaltab = document.getElementById('nav-journal-tab');
        const navcontratab = document.getElementById('nav-contra-tab');

        if (navcontratab != null && this.PageAcess_Contra != undefined && this.PageAcess_Contra != null && this.PageAcess_Contra[0].Add == 1) {

          document.getElementById("nav-contra-tab").className = "nav-item tab-active";
          navcontratab.removeAttribute("hidden");
          this._Route.navigate(['/lms-accounting/voucher/voucher-contra']);
        }
        else {
          navcontratab.setAttribute("hidden", "true");
        }

        if (navjournaltab != null && this.PageAcess_journal != undefined && this.PageAcess_journal != null && this.PageAcess_journal[0].Add == 1) {

          document.getElementById("nav-journal-tab").className = "nav-item tab-active";
          document.getElementById("nav-contra-tab").className = "nav-item";
          this._Route.navigate(['/lms-accounting/voucher/voucher-journal']);
          navjournaltab.removeAttribute("hidden");
        }
        else {
          navjournaltab.setAttribute("hidden", "true");
        }

        if (navpaymenttab != null && this.PageAcess_Payment != undefined && this.PageAcess_Payment != null && this.PageAcess_Payment[0].Add == 1) {

          document.getElementById("nav-payment-tab").className = "nav-item tab-active";
          document.getElementById("nav-journal-tab").className = "nav-item";
          document.getElementById("nav-contra-tab").className = "nav-item";
          navpaymenttab.removeAttribute("hidden");
          this._Route.navigate(['/lms-accounting/voucher/voucher-payment']);
        }
        else {
          navpaymenttab.setAttribute("hidden", "true");
        }

        if (navreceipttab != null && this.PageAcess_Receipt != undefined && this.PageAcess_Receipt != null && this.PageAcess_Receipt[0].Add == 1) {

          document.getElementById("nav-receipt-tab").className = "nav-item tab-active";
          document.getElementById("nav-payment-tab").className = "nav-item";
          document.getElementById("nav-journal-tab").className = "nav-item";
          document.getElementById("nav-contra-tab").className = "nav-item";
          navreceipttab.removeAttribute("hidden");
          this._Route.navigate(['/lms-accounting/voucher/voucher-receipt']);

        }
        else {
          navreceipttab.setAttribute("hidden", "true");
        }
        this.showSpinner = false;
      }
    });

  }

  ngOnInit() {
   
   
  }
  
  // @HostListener('document:keydown.alt.c', ['$event'])
  // @HostListener('document:keydown.alt.j', ['$event'])
  // @HostListener('document:keydown.alt.r', ['$event'])
  // @HostListener('document:keydown.alt.p', ['$event'])
  // onKeyDown(e: KeyboardEvent) {
  //   console.log((e.key === 'R' || e.key === 'r'))
  //   if (e.key === 'R' || e.key === 'r') {
  //     this._Route.navigate(['/lms-accounting/voucher/voucher-receipt']);
  //   }
  //   if (e.key === 'J' || e.key === 'j') {
  //     this._Route.navigate(['/lms-accounting/voucher/voucher-journal']);
  //   }
  //   if (e.key === 'C' || e.key === 'c') {
  //     this._Route.navigate(['/lms-accounting/voucher/voucher-receipt']);
  //   }
  //   if (e.key === 'P' || e.key === 'p') {
  //     this._Route.navigate(['/lms-accounting/voucher/voucher-contra']);
  //   }
  // }
}
