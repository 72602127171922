<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="formborder mt-2">
    <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
      <div class="row col-md-12 m-0">
        <div class="col-md-12 h-b w-b">
          <h1 class="fs-12 m-0">Loan Details</h1>
        </div>
      </div>
      <div class="row col-md-12 m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">Partner A/C</h6>
          <p class="lead fs-12">{{ loanDetailObj.Partner_LoanNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Customer A/C</h6>
          <p class="lead fs-12">{{ loanDetailObj.LMS_LoanNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanDate }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Product</h6>
          <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">
            ₹ {{ loanDetailObj.AssetCost ? loanDetailObj.AssetCost : "0" }}
          </p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Net Finance</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure (Months)</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanTenure }} Months</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ loanDetailObj.No_Of_Installment }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.AgreementValue }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.InterestAmt }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ loanDetailObj.LTV }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanEMIAmount }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ loanDetailObj.ROI }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">IRR Calculate By</h6>
          <p class="lead fs-12">{{ loanDetailObj.IRR_CalculateBy }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ loanDetailObj.Case_IRR }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">EMI Due Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.EMI_DueDate }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="mt-2">
    <div class="">
      <div class="table-responsive mt-2">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Int_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>

          <!-- <ng-container matColumnDef="CustomerId">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Customer Id </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CustomerId}} </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <app-los-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
                >{{ row.Customer }}</app-los-customer-view-model
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Customer Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Gender Age
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.GenderAge }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Phone No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <!-- {{ row.PhoneNo }} -->

              <div *ngIf="data.IsMasking">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!data.IsMasking">
                {{ row.PhoneNo }}
              </div>

              <i
                class="fa fa-check-circle ml-2"
                style="color: green; font-size: 13px"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Relation_With_Hirer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Relation With Borrower</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Relation_With_Hirer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Present_Address">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="min-width: 300px; max-width: 300px"
              *matHeaderCellDef
            >
              Address
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="min-width: 300px; max-width: 300px"
            >
              {{ row.Present_Address }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ExistingCustomer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="min-width: 90px; max-width: 90px"
              *matHeaderCellDef
            >
              Existing
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="min-width: 90px; max-width: 90px"
            >
              {{ row.ExistingCustomer }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div class="formborder mt-2">
    <ng-container>
      <div class="row col-md-12 m-0 mt-3">
        <div class="col-md-6 h-b w-b" style="padding-top: 11px">
          <h1 class="fs-12 m-0">Pre Closer Detail</h1>
        </div>
        <div class="col-md-6 h-b w-b text-right">
          <button
            type="button"
            (click)="onViewLoanStatement()"
            class="btn btn-light font-size-12"
            style="padding: 3px 5px"
          >
            View Loan Statement
          </button>
        </div>
      </div>

      <div class="row m-0 mt-3" id="printData">
        <div class="col-md-12">
          <table style="margin-bottom: 20px">
            <tbody>
              <tr>
                <td>
                  <h6
                    style="
                      display: inline-block;
                      margin: 0px;
                      margin-right: 10px;
                      font-size: 12px;
                    "
                  >
                    Request Status:
                  </h6>
                  <p
                    style="display: inline-block; font-weight: 700; margin: 0px"
                    [ngClass]="{
                      'text-danger':
                        PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Rejected',
                      'text-success':
                        PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Approved'
                    }"
                  >
                    {{ PreCloserDetails.Closer_RequestStatus }}
                  </p>
                  <p *ngIf="PreCloserDetails.TotalWaiver_Approved">
                    Approved Waiver Amount:<b>
                      ₹{{ PreCloserDetails.TotalWaiver_Approved }}</b
                    >
                  </p>
                </td>
                <td>
                  <h6
                    style="
                      display: inline-block;
                      margin: 0px;
                      margin-right: 10px;
                      font-size: 12px;
                    "
                  >
                    Closer Type:
                  </h6>
                  <select
                    name="CloserType"
                    id="CloserType"
                    [disabled]="closerId > 0"
                    [(ngModel)]="PreCloserDetails.CloserType"
                    class="form-control input-text-css"
                    style="width: 300px; display: inline-block"
                  >
                    <option value="">Select Closer Type</option>
                    <option value="Regular Close">Regular Close</option>
                    <option value="Pre-Closer">Pre-Closer</option>
                    <option value="Repossess Sold">Repossess Sold</option>
                    <option value="Settled Case">Settled Case</option>
                    <option value="Cancelled Case">Cancelled Case</option>
                    <option value="Account Sold">Account Sold</option>
                  </select>
                </td>
                <td style="text-align: end">
                  <h6
                    style="
                      display: inline-block;
                      margin: 0px;
                      margin-right: 10px;
                      font-size: 12px;
                    "
                  >
                    Pre-Closure Effective Date:
                  </h6>
                  <div style="display: inline-block">
                    <!--<input type="text" [(ngModel)]="MinEffectiveDate"
    [maxDate]="PreCloserDetails.NextDueDate" [disabled]="closerId>0" name="LoanCloser_EffectiveDate" id="LoanCloser_EffectiveDate"
    value="{{ MinEffectiveDate | date: 'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
    [(bsValue)]="MinEffectiveDate" class="form-control input-text-css" bsDatepicker
    style="width: 160px;" (bsValueChange)="onEffectiveDateChange()" />-->

                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        [(ngModel)]="MinEffectiveDate"
                        [min]="MinEffectiveDate"
                        [max]="PreCloserDetails.NextDueDate"
                        name="LoanCloser_EffectiveDate"
                        id="LoanCloser_EffectiveDate"
                        (dateChange)="onEffectiveDateChange()"
                        [disabled]="closerId > 0"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                        style="right: 0 !important"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered table-striped bg-white">
            <thead>
              <tr>
                <th>Head</th>
                <th>Due</th>
                <th>Payment</th>
                <th>Waiver</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let preCloserData of PreCloserMethodList; let i = index"
              >
                <td>{{ preCloserData.ChagesHead }}</td>

                <td>{{ preCloserData.DueAmount }}</td>
                <td>{{ preCloserData.Payment }}</td>
                <td>
                  <input
                    type="text"
                    [(ngModel)]="preCloserData.WaiverAmount"
                    [disabled]="closerId > 0"
                    placeholder="amount"
                    name="WaiverAmount{{ i }}"
                    id="WaiverAmount{{ i }}"
                    class="form-control input-text-css"
                    style="width: 90px; height: 25px"
                    (ngModelChange)="onChangeWaiverAmt($event, preCloserData)"
                  /><!-- *ngIf="preCloserData.IsWaiver_Eligible"-->
                  <!--<ng-container *ngIf="!preCloserData.IsWaiver_Eligible && preCloserData.WaiverAmount > 0 ">
        {{preCloserData.WaiverAmount > 0 ? preCloserData.WaiverAmount : 0}}
      </ng-container>-->
                </td>
                <td>{{ preCloserData.Balance }}</td>
              </tr>
              <tr *ngIf="PreCloserMethodList.length > 0 && totalCalculator">
                <th>Total :</th>
                <th>{{ totalCalculator.totalDueAmount?.toFixed(2) }}</th>
                <th>{{ totalCalculator.totalReceiveAmount?.toFixed(2) }}</th>
                <th>{{ totalCalculator.totalWaiverAmount?.toFixed(2) }}</th>
                <th>{{ totalCalculator.totalBalance?.toFixed(2) }}</th>
              </tr>
            </tbody>
          </table>
          <!-- <div class="row m-0">
            <div class="col-md-2 p-1">Net Receivable Amount:</div>
            <div class="col-md-3 p-1">₹ {{totalCalculator?.totalBalance?.toFixed(2)}}</div>
            <div class="col-md-1 p-1">&nbsp;</div>
            <div class="col-md-3 p-1">Your Waiver Limit On this Loan No:</div>
            <div class="col-md-3 p-1">₹ {{PreCloserDetails.Waiver_Limit_Per_Amt_R}}</div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-2 p-1">Amount To Be Deposit:</div>
            <div class="col-md-3 p-1">
              <input type="text" [(ngModel)]="PreCloserDetails.CustomerAgreed_Amount" placeholder="amount" name="CustomerAgreed_Amount" 
                id="CustomerAgreed_Amount" (blur)="onChangeAgreeAmount()" class="form-control input-text-css"
                [disabled]="PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' || PreCloserDetails.Closer_RequestStatus == 'Waiver Approved' || isExpired" />
            </div>
            <div class="col-md-1 p-1">&nbsp;</div>
            <div class="col-md-6 p-1" *ngIf="isShowForward||PreCloserDetails.Closer_RequestStatus != 'Created'">
              <div class="row">
                <div class="col-md-6">Select User For Waiver Approval:</div>
                <div class="col-md-6">
                  <select name="Waiver_GivenBy" id="Waiver_GivenBy" [(ngModel)]="PreCloserDetails.Waiver_GivenBy"
                    class="form-control input-text-css" [disabled]="isExpired||(PreCloserDetails.Closer_RequestStatus != 'Created')">
                    <option value="">Select</option>
                    <option *ngFor="let user of wavieruserList" [value]="user.EmpId">{{user.Employee}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 mt-2" *ngIf="PreCloserMethodList.length > 0 && totalCalculator">
            <div class="col-md-2 p-1">Waiver Required Amount</div>
            <div class="col-md-10 p-1">₹ {{PreCloserDetails?.totalWaiverAmount?.toFixed(2)}}</div>
          </div> -->

          <div class="row m-0 mt-2">
            <div class="col-md-2 p-1">Upload Closer Request Doc:</div>
            <div class="col-md-2 p-1">
              <input
                type="file"
                (change)="fileChangeEvent($event)"
                *ngIf="!PreCloserDetails.CloserRequestDoc"
                name="Request_Doc"
                accept=".png, .jpg, .jpeg"
                id="Request_Doc"
              />
              <a
                href="{{ loandocBaseUrl }}{{ LoanId }}/{{
                  PreCloserDetails.CloserRequestDoc
                }}"
                target="_blank"
                *ngIf="PreCloserDetails.CloserRequestDoc"
              >
                {{ PreCloserDetails.CloserRequestDoc }}
              </a>
              <i
                class="fa fa-times ml-2"
                style="cursor: pointer; font-size: larger"
                (click)="onRemoveImg()"
                aria-label="true"
                *ngIf="PreCloserDetails.CloserRequestDoc"
              ></i>
            </div>
            <div class="col-md-8 p-1"></div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-2 p-1">Pre-Closer Remark:</div>
            <div class="col-md-10 p-1">
              <textarea
                [(ngModel)]="PreCloserDetails.LoanCloser_Remark"
                [disabled]="closerId > 0"
                placeholder="Pre-Closer Remark"
                name="LoanCloser_Remark"
                id="LoanCloser_Remark"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="
              PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' ||
              PreCloserDetails.Waiver_Remark?.length > 0
            "
          >
            <div class="col-md-2 p-1">Waiver Remark:</div>
            <div class="col-md-10 p-1">
              <textarea
                [(ngModel)]="PreCloserDetails.Waiver_Remark"
                [disabled]="closerId > 0"
                placeholder="Waiver Remark"
                name="Waiver_Remark"
                id="Waiver_Remark"
                class="form-control input-text-css"
                [disabled]="isExpired || !isWavierGivenByMe"
              ></textarea>
            </div>
          </div>
          <div class="row m-0 mt-2" *ngIf="isUndoRemark">
            <div class="col-md-2 p-1">Undo Remark:</div>
            <div class="col-md-10 p-1">
              <textarea
                [(ngModel)]="undoRemark"
                placeholder="Undo Remark"
                [disabled]="closerId > 0"
                name="undoRemark"
                id="undoRemark"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="PreCloserDetails?.LoanCloserRequestBy_Name"
          >
            <div class="col-md-2 p-1">Loan Closer Request By:</div>
            <div class="col-md-10 p-1">
              {{ PreCloserDetails?.LoanCloserRequestBy_Name }} on
              {{ PreCloserDetails?.LoanCloser_RequestOn }}
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="PreCloserDetails?.WaiverGivenBy_Name"
          >
            <div class="col-md-2 p-1">Waiver Given By:</div>
            <div class="col-md-10 p-1">
              {{ PreCloserDetails?.WaiverGivenBy_Name }} on
              {{ PreCloserDetails?.Waiver_GivenOn }}
            </div>
          </div>
          <div class="row m-0 mt-2" *ngIf="PreCloserDetails?.LoanCloserBy_Name">
            <div class="col-md-2 p-1">Loan Closed By:</div>
            <div class="col-md-10 p-1">
              {{ PreCloserDetails?.LoanCloserBy_Name }} on
              {{ PreCloserDetails?.LoanClosed_Date }}
            </div>
          </div>
        </div>

        <div
          class="col-md-12"
          *ngIf="
            PreCloserDetails.Closer_RequestStatus == 'Closed' && !showSpinner
          "
        >
          <p style="color: #db1f1f; font-weight: bold">
            This Loan Has Been Already Closed.
          </p>
        </div>
      </div>
      <div class="row col-md-12 m-0 mt-2 justify-content-end">
        <ng-conatiner>
          <button
            type="button"
            (click)="onPreClosureClose()"
            class="ml-2"
            *ngIf="closerId == 0"
            [disabled]="totalCalculator.totalBalance != 0"
            class="mt-3 btn font-size-12 button-btn"
          >
            Loan Closed
          </button>
        </ng-conatiner>
        <button
          type="button"
          (click)="onCancel()"
          class="mt-3 btn font-size-12 button-btn ml-2"
        >
          Cancel
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanStatementPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Statement
        </h6>
        <button
          type="button"
          (click)="onCloseLoanStatementPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [loanSummary]="loanSummary"
          [PageType]="PageType"
          [PLoanType]="PLoanType"
          [FirstEMIDate]="FirstEMIDate"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>
