<div class="dropdown">
  <button
    class="btn font-size-12 button-btn dropdown-toggle mat-button-wrapper"
    style="padding: 3px"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Print NOC Letter
  </button>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    style="max-height: 95px; overflow: auto"
  >
    <a
      class="dropdown-item"
      *ngFor="let x of dropdownLanguages"
      (click)="Get_Template(x.Id)"
      >{{ x.Template_Name }}</a
    >
  </div>
</div>

<div
  class="modal fade in"
  id="NOCmodel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="
    background-color: rgba(0, 0, 0, 0.5);
    text-align: initial;
    overflow: auto;
  "
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <button
          type="button"
          class="btn font-size-12 button-btn"
          mat-raised-button
          color="primary"
          [class.spinner]="loading"
          (click)="printLetter()"
        >
          print NOC Letter
        </button>
        <button
          type="button"
          class="close btn font-size-12 button-btn"
          (click)="OnClose()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body" id="pdfNOCDownload">
        <div class="row mb-0" style="font-size: 10px">
          <div class="col-md-12 ck-content">
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
