import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from "@angular/router";
import { SnackbarComponent } from '../../snackbar/snackbar.component';
declare var $: any;

@Component({
  selector: 'app-dvedit',
  templateUrl: './dvedit.component.html',
  styleUrls: ['./dvedit.component.scss']
})
export class DveditComponent implements OnInit {
  showSpinner: boolean = false;
  LoanNo: any ; loading: boolean = false;
  currentUser: any;
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "VoucherId", "CaseNo", "CustomerName", "Voucher_Date", "ViewAction"
  ];
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  constructor(private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private _EncrDecrService: EncrDecrService,
    private lmsService: LmsService,
    private router: Router,
    public snackBar: MatSnackBar,
    private encdec: EncrDecrService) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("DV Edit");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  onSearchDVDetail() {
    this.showSpinner = true;
    this.lmsService.LMS_Get_DV_Voucher({ LoanAcNo: this.LoanNo }).subscribe((res: any) => {
      if (res.length > 0) {
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Loan No Not Found or Loan Closed!!!", ...this.configSuccess });
        this.showSpinner = false;
      }
    }
    );
  }
  goToDetail(data: any) {
    // var inquiryId = this.encdec.encrypt(data.InquiryId);

    this.router.navigate([
      `/lms/dvdetail/${encodeURIComponent(data.LoanId)}/${encodeURIComponent(this._EncrDecrService.encrypt(data.VoucherId))}/${'true'}`,
    ]);
  }
}
