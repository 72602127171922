import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";

declare var $: any;

@Component({
  selector: "app-partner-balance",
  templateUrl: "./partner-balance.component.html",
  styleUrls: ["./partner-balance.component.scss"],
})
export class PartnerBalanceComponent implements OnInit {
  private data: any;
  showSpinner: boolean = false;
  currentUser: any;
  RequestModel: RequestModel = new RequestModel();
  VoucherDateFrom: any;
  VoucherDateTo: any;
  FyearId: any;
  maxDate: any;
  minDate: any;
  DataSource: any;
  PartnerList: any = [];
  partnerModel: any = {};
  FileCheckingList: any[] = [];
  FileCheckingList1: any[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  Partner_Type: any;
  rowdata: any;
  dataSourceAccount: any;
  BalanceType = 0;
  dataSourceMonthly: any;
  dataSourceDaily: any;
  DivAnnualy = true;
  DivMonthly = false;
  DivDaily = false;
  AccountName: any;
  ShowDelete: boolean = false;
  accountTagDropdown: any[] = [];


  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };

  displayedColumns: string[] = [
    "AccountId",
    "Account_Name",
    "OpeningBalance",
    "TranTypeDR",
    "TranTypeCR",
    "ClosingBalance",
    "ViewAction",
  ];

  displayedAccountColumns: string[] = [
    "voucherid",
    "Voucher_Date",
    "Voucher_No",
    "CaseNo",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Narration",
    "ViewAction",
  ];

  displayedColumnsMonthly: string[] = [
    "Id",
    "Month_Year",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];
  displayedColumnsDaily: string[] = [
    "Id",
    "VoucherDate",
    "Account_Name",
    "TranTypeDR",
    "TranTypeCR",
    "TranTypeTotal",
    "Action",
  ];
  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private router: Router,
    private route: ActivatedRoute,
    private MasterService: MasterService,
    private reportsService: ReportsService
  ) {
    this.MasterService = MasterService;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Partner Balance");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.RequestModel.PartnerType = "";
    this.getPartnerList();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          console.log("fgfhgfhfh", res);
          this.minDate = new Date(
            moment(res[res.length - 1].fystart).format("MM/DD/YYYY")
          );
          this.maxDate = new Date(
            moment(res[res.length - 1].fyent).format("MM/DD/YYYY")
          );
          this.VoucherDateFrom = new Date(
            moment(res[res.length - 1].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.DataSource = null;
        });
    });
  }

  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "VoucherDate To greater than VoucherDate From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.VoucherDateTo = new Date();
        });
    }
  }

  getPartnerList() {
    // this.showSpinner = true;
    this.MasterService.Get_PartnerList(this.RequestModel).subscribe(
      (result: any) => {
        this.PartnerList = JSON.parse(JSON.stringify(result));
        console.log("partnerData", result);
        this.FileCheckingList1 = JSON.parse(JSON.stringify(result));
        // console.log("data",this.FileCheckingList1);
        // this.dataSource = new MatTableDataSource(this.PartnerList);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        // this.showSpinner = false;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      }
    );
  }

  onSearchPartnerBalance() {
    // this.showSpinner = true;
    this.reportsService
      .Get_Dealers_Balance({
        DateFrom: this.VoucherDateFrom,
        DateTo: this.VoucherDateTo,
        Fyear: this.minDate.getFullYear(),
        PartnerType: this.Partner_Type,
      })

      .subscribe((res: any) => {
        this.showSpinner = false;
        console.log("Data", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.DataSource = new MatTableDataSource(this.FileCheckingList);
        this.DataSource.sort = this.sort;
      });
  }

  calculateOPBalanceTotal() {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i]["OP_Balance"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }

  calculateCLBalanceTotal() {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i]["CL_Balance"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }

  calculateTranTypeDRTotal() {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i]["TranTypeDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }

  calculateTranTypeCRTotal() {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0.0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i]["TranTypeCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(row) {
    this.rowdata = "";
    this.rowdata = row;
    this.dataSourceAccount = "";
    this.onSearchAccountingDetail(false);
  }

  onSearchAccountingDetail(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = "";
    this.dataSourceDaily = "";
    this.DivAnnualy = true;
    this.DivMonthly = false;
    this.DivDaily = false;

    this.showSpinner = true;
    // this.loading = true;

    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("RES", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));

        $("#AccountingDetail").modal("show");
        $("#AccountingDetail").css("z-index", "1050");

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/

        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        // this.dataSourceAccount.paginator = this.paginator;
        this.AccountName = this.rowdata.Group_Name;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
        this.showSpinner = false;
        // this.loading = false;
      });
  }

  OnCloseAccountingDetail() {
    $("#AccountingDetail").modal("hide");
  }

  GetACCLeadgerByAnnualy(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = "";
    this.dataSourceDaily = "";

    this.FileCheckingList = [];
    /*if (Addmore == false) {
      this.FileCheckingList = [];
    }

    if (this.FileCheckingList.length > 0) {
      NextCnt = this.dataSourceAccount.filteredData.length;
    }*/

    this.showSpinner = true;
    // this.loading = true;

    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("RES", res);

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.dataSourceAccount.paginator = this.paginator;
        this.showSpinner = false;
        // this.loading = false;
      });
  }

  goToDetailVoucher(row) {
    $("#VoucherDetail").modal("show");
    $("#VoucherDetail").css("z-index", "1060");
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        if (
          this.voucherModel.Voucher.Voucher_Type == "Receipt" ||
          this.voucherModel.Voucher.Voucher_Type == "Journal" ||
          this.voucherModel.Voucher.Voucher_Type == "Payment"
        )
          this.ShowDelete = true;
        else this.ShowDelete = false;
        this.voucherModel.Voucher_Detail.forEach((x) => {
          x.AmountDR = 0.0;
          x.AmountCR = 0.0;
          if (x.TranType == "CR") x.AmountCR = x.Amount;
          if (x.TranType == "DR") x.AmountDR = x.Amount;
        });
        if (
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == "" ||
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == null
        )
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = "CASH";
        this.onVoucherTagChange();
      });
  }

  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }

  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = "CASH";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date),
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }

  GetACCLeadgerByMonthly() {
    this.FileCheckingList = [];
    this.showSpinner = true;

    this.reportsService
      .Report_ACC_Leadger_By_Monthly({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("Report_ACC_Leadger_By_Monthly :", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceMonthly = new MatTableDataSource(
            this.FileCheckingList
          );
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  totaltypeMonthly(type) {
    if (this.dataSourceMonthly != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceMonthly.filteredData.length; i++) {
        sum += Number(this.dataSourceMonthly.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  calculateMonthlyBalance() {
    if (this.dataSourceMonthly != null) {
      var lastBalance = this.dataSourceMonthly.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  GetACCLeadgerByDaily() {
    this.FileCheckingList = [];
    this.showSpinner = true;
    this.reportsService
      .Report_ACC_Leadger_By_Daily({
        AccountId: this.rowdata.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("Report_ACC_Leadger_By_Daily :", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceDaily = new MatTableDataSource(this.FileCheckingList);
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  goToDetailModel(row) {}

  totaltypeDaily(type) {
    if (this.dataSourceDaily != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceDaily.filteredData.length; i++) {
        sum += Number(this.dataSourceDaily.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  GetData(data) {
    if (data == true) {
      // this.onSearchAccountStatement();
    }
  }

  ChangeBalanceType() {
    if (this.BalanceType == 0) {
      this.DivAnnualy = true;
      this.DivMonthly = false;
      this.DivDaily = false;
      this.GetACCLeadgerByAnnualy(false);
    } else if (this.BalanceType == 1) {
      this.DivMonthly = true;
      this.DivAnnualy = false;
      this.DivDaily = false;
      this.GetACCLeadgerByMonthly();
    } else {
      this.DivDaily = true;
      this.DivAnnualy = false;
      this.DivMonthly = false;
      this.GetACCLeadgerByDaily();
    }
  }

  calculateACTranTypeDR() {
    if (this.dataSourceAccount != undefined) {
      if (
        this.dataSourceAccount != null &&
        this.dataSourceAccount != undefined
      ) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
          sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeDR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateACTranTypeCR() {
    if (this.dataSourceAccount != undefined) {
      if (this.dataSourceAccount != null) {
        let sum = 0.0;
        for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
          sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeCR"]);
        }
        return sum.toFixed(2);
      }
      return 0;
    }
  }
  calculateACTranTypeTotal() {
    if (this.dataSourceAccount != null) {
      /*let sum = 0;
      let DR = this.calculateACTranTypeDR()
      let CR = this.calculateACTranTypeCR();
      sum = Number(DR) - Number(CR);
      if (sum > 0)
        return sum.toFixed(2).toString() + " DR"
      else
        return sum.toFixed(2).toString().split('-')[1] != undefined ? sum.toFixed(2).toString().split('-')[1] + " CR" : 0.00;*/
      var lastBalance =
        this.dataSourceAccount.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }

  public highlightRow(row) {
    this.selectedId = row.Id;
  }


  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("partner_balance") as HTMLTableElement;
    const worksheetName = "PARTNER BALANCE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }




}
