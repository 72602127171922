
  <form #f="ngForm" novalidate>
    <div class="row m-0">
      <div class="col-md-3">
        <span class="required-lable">Type</span>
        <input type="text" name="Type" id="Type" [(ngModel)]="Type" class="form-control input-text-css" required placeholder="Type" 
          [disabled]="updateBtn" #refType="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refType.invalid, 'alert-warning': refType.invalid  && (refType.dirty || refType.touched || refType.untouched) }">
      </div>
    
      <div class="col-md-3">
        <span class="required-lable">Value</span>
        <input type="text" name="Value" id="Value" [(ngModel)]="Value" class="form-control input-text-css" required 
          placeholder="Value" #refValue="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refValue.invalid, 'alert-warning': refValue.invalid  && (refValue.dirty || refValue.touched || refValue.untouched) }">
      </div>
      <div class="col-md-3 mt-3">
        <button type="button" *ngIf="saveBtn" (click)="onSaveMaster('Insert')"
          mat-raised-button class="btn_default" [class.spinner]="loading" [disabled]="!f.form.valid">
          Save
        </button>
        <button type="button" (click)="onSaveMaster('Update')" *ngIf="updateBtn"  class="font-size-12 button-btn"
          [class.spinner]="loading" [disabled]="!f.form.valid">
          Update
        </button>
      </div>
      <div class="col-md-3">
        <mat-form-field><input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ...."></mat-form-field>
      </div>
    </div>
  </form>

  <div class="row m-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="dataMasterSource" style="height: 350px; max-width: 100%; overflow: auto;">
          <ng-container matColumnDef="Id">
            <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>
              #
            </mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;">
              {{ (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{row.Type}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Value">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Value</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{row.Value}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i class="fa fa-pencil-alt right btn_detail" aria-label="true" (click)="edit(row)"></i>
              <i class="fa fa-minus-circle font-size-15 ml-3 btn_delete" (click)="removeModel(row)" aria-label="true"></i>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedMasterColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedMasterColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div>
