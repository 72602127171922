import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;

@Component({
  selector: "app-enach-nupay",
  templateUrl: "./enach-nupay.component.html",
  styleUrls: ["./enach-nupay.component.scss"],
})
export class EnachNupayComponent implements OnInit {
  showSpinner: boolean = false;
  LoanAcNo: any;
  Status: any = "Pending";
  status: any;
  LoanDateFrom: any;
  LoanDateTo: any;
  currentUser: any;
  FileCheckingList: any;

  dataSourceEnachNupay: any;
  displayedColumnsEnachNupay: string[] = [];
  ChequeId: MatTableDataSource<unknown>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  applicationDetail: any;
  StatusDate: any = new Date();
  EMIFrom: any = 0;
  EMITo: any = 0;
  Loan_Id: any;
  LastEMI: any;
  identifier: any;
  Id: any;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.LoanDateFrom = new Date();
    this.LoanDateTo = new Date();
    this.dataSharingService.HeaderTitle.next("NUPAY ENACH Generation");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumnsEnachNupay = [
      "Id",
      "LoanAcNo",
      "CustomerName",
      "Loan_Date",
      "PresentBankInCompanyName",
      "PresentBankInCompany",
      "Status",
      "checkButton",
      "Action",
    ];
    //"Identifier",
  }

  onSearchEnachNupay() {
    console.log("search button clicked");
    this.showSpinner = true;

    this.lmsService
      .LMS_Get_Nupay_NachStatus({
        fromDate: this.LoanDateFrom,
        toDate: this.LoanDateTo,
        LoanAcNo: this.LoanAcNo,
        status: this.Status,
      })
      .subscribe((res: any) => {
        // console.log("res : ", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.ChequeId = res[0].chqId;
          this.identifier = res[0].Identifier;
          // console.log("CehqueID", this.ChequeId);
          this.dataSourceEnachNupay = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          // this.dataSourceEnachNupay = new MatTableDataSource(
          //   this.FileCheckingList
          // );
          this.dataSourceEnachNupay.sort = this.sort;
          this.dataSourceEnachNupay.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.dataSourceEnachNupay = null;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  onSearchPendingStatus(row) {
    console.log("Pending Button Clicked");
    this.lmsService
      .Nupay_Mandate_Registration_CheckStatus({
        Identifier: this.identifier,
        LoanId: row.Loan_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res[0].CODE == 0) {
          console.log("code into if condition");
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onSearchEnachNupay();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  goToDetail(row) {
    this.Loan_Id = row.Loan_Id;
    this.changeDate();
    this.status = row.status;
    this.Id = row.Id;
    this.lmsService
      .Lms_Get_Nach_Au_Detail({
        LoanAcNo: row.LoanAcNo,
        Id: row.chqId,
        SearchStatus: row.Status,
      })
      .subscribe((res: any) => {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        console.log("Data for Get Nach Info", this.FileCheckingList);
        if (res.length > 0) {
          this.applicationDetail = res[0];
          $("#addAUEnachInfo").modal("show");
          $("#addAUEnachInfo").css("z-index", "1050");
        }
      });
  }

  OnClose() {
    $("#addAUEnachInfo").modal("hide");
  }

  EMIFromChange() {}

  onSavenNupayEnachInfo() {
    if (this.EMIFrom == 0 || this.EMITo == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI From or EMI To not equal to 0!!",
        ...this.configSuccess,
      });
    } else {
      this.lmsService
        .LMS_Save_Nupay_ENachInfo({
          EMIFrom: this.EMIFrom,
          EMITo: this.EMITo,
          Loan_Id: this.Loan_Id,
          LoginUserId: this.currentUser.userId,
          chqId: this.ChequeId,
          Id: this.Id,
          StatusDate: this.StatusDate,
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            console.log("Data Saved");
            this.OnClose();
            this.onSearchEnachNupay();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
  changeDate() {
    this.EMIFrom = 0;
    this.EMITo = 0;
    this.lmsService
      .LMS_GET_NACH_EMI_DETAI({
        StatusDate: this.StatusDate,
        Loan_Id: this.Loan_Id,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].CODE == 0) {
            // console.log(res);
            this.EMIFrom = res[0].EMI;
            this.EMITo = res[res.length - 1].EMI;
            this.LastEMI = res[res.length - 1].EMI;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.EMIFrom = 0;
            this.EMITo = 0;
          }
        }
      });
  }

  onExport(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `AU-ENACH-EXPORT`,
      sheet: "AU-ENACH-EXPORT",
    });
  }
}
