<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 formborder d-flex align-items-center">
  <div class="col-md-3 p-1">
    <span class="required-lable">Select Status</span>
    <select
      name="SelectStatus"
      id="SelectStatus"
      #refSelectStatus="ngModel"
      class="form-control input-text-css"
      [(ngModel)]="SelectStatus"
      (change)="QuickLoanList()"
    >
      <option value="">Select Status</option>
      <option value="Completed">Completed</option>
      <option value="Pending">Pending</option>
      <option value="Reverted">Reverted</option>
      <option value="Hold">Hold</option>
      <option value="Reject">Rejected</option>
    </select>
  </div>

  <div class="col-md-2">
    <span> Source Type </span>
    <select
      name="SelectSourceType"
      id="SelectSourceType"
      #refSelectSourceType="ngModel"
      (change)="Get_PartnerForDropdown()"
      [(ngModel)]="SelectSourceType"
      class="form-control input-text-css"
    >
      <option value="">Select Source Type</option>
      <option
        *ngFor="let lead of leadSourceDropdown"
        [value]="lead.Lead_Source"
      >
        {{ lead.Lead_Source }}
      </option>
    </select>
  </div>

  <ng-container
    *ngIf="
      SelectSourceType == 'Agent' ||
      SelectSourceType == 'DSA' ||
      SelectSourceType == 'Dealer' ||
      SelectSourceType == 'Sales Executive'
    "
  >
    <div class="col-md-3">
      <span> Source Name</span>
      <select
        name="LeadSourceName"
        id="LeadSourceName"
        (change)="QuickLoanList()"
        #refLeadSourceName="ngModel"
        [(ngModel)]="LeadSourceName"
        class="form-control input-text-css"
      >
        <option value="">Select Lead Reference</option>
        <option
          *ngFor="let partner of partnerDropdown"
          [value]="partner.PartnerId"
        >
          {{ partner.Partner_Name }}
        </option>
      </select>
    </div>
  </ng-container>
  <div class="col-md-2 p-1 text-right mt-2" *ngIf="Processid == 1 && AddLoan">
    <button class="mt-3 btn font-size-12 button-btn" (click)="OnClickNewLoan()">
      New Application
    </button>
  </div>
  <div
    class="col-md-2 p-1 mt-4"
    *ngIf="ApplicationData.length > 0 && SelectStatus == 'Pending'"
  >
    <button
      type="button"
      *ngIf="ApplicationData.length > 0 && SelectStatus == 'Pending'"
      (click)="onOpenSendHO()"
      mat-raised-button
      class="btn font-size-12 button-btn"
      [class.spinner]="loading"
      style="width: max-content"
    >
      Send File To Head Office
    </button>
  </div>
  <div class="col-md-3 p-1 mt-2">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3">
  <div class="">
    <div class="table-responsive" *ngIf="Processid != 7">
      <mat-table
        [dataSource]="dataSource"
        matSort
        style="height: 60vh; overflow: auto"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 40px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 40px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="Application_No">
          <mat-header-cell
            mat-sort-headergoToHoldRejectLoan
            class="grid-header"
            *matHeaderCellDef
          >
            App No
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_No }}
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="Application_No">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            App No
          </mat-header-cell>

          <mat-cell *matCellDef="let row" class="grid-cell">
            <a
              style="cursor: pointer"
              *ngIf="Processid != 4 || SelectStatus == 'Completed'"
              (click)="goToDetail(row)"
              aria-label="true"
              [hidden]="SelectStatus == 'Hold' || SelectStatus == 'Reject'"
              >{{ row.Application_No }}</a
            >

            <a
              style="cursor: pointer"
              *ngIf="
                (SelectStatus == 'Hold' || SelectStatus == 'Reject') &&
                row.RejectOrHoldBy == currentUser.userId &&
                (row.ProcessStatus == 'Reject' || row.ProcessStatus == 'Hold')
              "
              (click)="goToHoldRejectLoan(row)"
              aria-label="true"
              >{{ row.Application_No }}</a
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_CreateOn">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            App Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_CreateOn }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer_Name }} {{ row.Customer_RelationName }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Product
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Loan Amount
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_LoanAmount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_CreateBy">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Created By
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_CreateBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            SourceType
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SourceName">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Source Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanPurpose">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Loan Purpose
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_LoanPurpose }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RevertedRemark">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Revert Remark
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.RevertedRemark }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="TAT">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            TAT
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <i
              (click)="GoToTATReportDetail(row)"
              class="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              *ngIf="Processid != 4 || SelectStatus == 'Completed'"
              (click)="goToDetail(row)"
              aria-label="true"
              [hidden]="SelectStatus == 'Hold' || SelectStatus == 'Reject'"
            ></i>
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              *ngIf="
                (SelectStatus == 'Hold' || SelectStatus == 'Reject') &&
                row.RejectOrHoldBy == currentUser.userId &&
                (row.ProcessStatus == 'Reject' || row.ProcessStatus == 'Hold')
              "
              (click)="goToHoldRejectLoan(row)"
              aria-label="true"
            ></i>

            <input
              type="checkbox"
              [value]="row.ApplicationId"
              [checked]="ApplicationData.indexOf(row.Loan_Id) > -1"
              *ngIf="Processid == 4 && SelectStatus == 'Pending'"
              (change)="onCheckboxChange($event, row)"
            />
          </mat-cell>
        </ng-container>
        <!-- Header and Row Declarations -->
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button class="3 btn font-size-12 button-btn" (click)="exportexcel()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>

    <div class="table-responsive" *ngIf="Processid == 7">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[displayedColumns.indexOf('Action')]"
      >
        <ng-container matColumnDef="CourierId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CourierBy">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Sending By
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CourierBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Courier">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Agency
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Courier }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NoOfApplications">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >No Of Applications</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.NoOfApplications }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SendBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Send By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SendBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SendDate">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Send Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SendDate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="TrackingNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Tracking No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.TrackingNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 60px"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 60px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              (click)="goToReceiveDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="row m-0 col-md-12 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>

        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="sen-to-ho"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSendToHo()" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            File Send to Head Office
          </h6>
          <button
            type="button"
            (click)="onCloseSendHO()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="table-responsive mt-3">
              <mat-table [dataSource]="dataSelectedSource">
                <ng-container matColumnDef="ApplicationId">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Application_No">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Application No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Application_No
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Customer</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Customer_Name }} {{ row.Customer_RelationName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Branch_Name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Product">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Loan Product</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Product_Name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAmount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Loan Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Application_LoanAmount
                  }}</mat-cell>
                </ng-container>

                <ng-container
                  matColumnDef="Action"
                  *ngIf="SelectStatus == 'Pending'"
                >
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 60px"
                    *matHeaderCellDef
                  >
                    Action
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center"
                    style="max-width: 60px"
                  >
                    <i
                      class="fa fa-minus-circle btn_delete"
                      (click)="onRemoveSelected($event, row)"
                      aria-label="true"
                    ></i>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedSelectedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedSelectedColumns"
                ></mat-row>
              </mat-table>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6 p-1">
              <span class="required-lable"> Sending By </span>
              <select
                name="SendingBy"
                id="SendingBy"
                #refSendingBy="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refSendingBy.invalid,
                  'alert-warning':
                    refSendingBy.invalid &&
                    (refSendingBy.dirty ||
                      refSendingBy.touched ||
                      refSendingBy.untouched)
                }"
                [(ngModel)]="sendToModal.SendingBy"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Sending</option>
                <option value="Courier">Courier</option>
                <option value="ByPost">By Post</option>
                <option value="ByBus">By Bus</option>
                <option value="ByHand">By Hand</option>
              </select>
            </div>
            <div
              class="col-md-6 p-1"
              *ngIf="
                !sendToModal.SendingBy || sendToModal.SendingBy == 'Courier'
              "
            >
              <span class="required-lable"> Agency </span>
              <select
                name="AgencyId"
                id="AgencyId"
                #refAgencyId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAgencyId.invalid,
                  'alert-warning':
                    refAgencyId.invalid &&
                    (refAgencyId.dirty ||
                      refAgencyId.touched ||
                      refAgencyId.untouched)
                }"
                [(ngModel)]="sendToModal.AgencyId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Agency</option>
                <option
                  *ngFor="let agency of AgencyDropDown"
                  [value]="agency.CourierId"
                >
                  {{ agency.CourierCompanyName }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1" *ngIf="sendToModal.SendingBy == 'ByPost'">
              <span class="required-lable"> Post Detail </span>
              <input
                required
                type="text"
                #refByPost_Detail="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refByPost_Detail.invalid,
                  'alert-warning':
                    refByPost_Detail.invalid &&
                    (refByPost_Detail.dirty ||
                      refByPost_Detail.touched ||
                      refByPost_Detail.untouched)
                }"
                [(ngModel)]="sendToModal.ByPost_Detail"
                placeholder="Post Detail"
                name="ByPost_Detail"
                id="ByPost_Detail"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1" *ngIf="sendToModal.SendingBy == 'ByBus'">
              <span class="required-lable"> Bus detail </span>
              <input
                required
                type="text"
                #refByBus_Detail="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refByBus_Detail.invalid,
                  'alert-warning':
                    refByBus_Detail.invalid &&
                    (refByBus_Detail.dirty ||
                      refByBus_Detail.touched ||
                      refByBus_Detail.untouched)
                }"
                [(ngModel)]="sendToModal.ByBus_Detail"
                placeholder="Bus Detail"
                name="ByBus_Detail"
                id="ByBus_Detail"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1" *ngIf="sendToModal.SendingBy == 'ByHand'">
              <span class="required-lable"> Detail </span>
              <input
                required
                type="text"
                #refByHand_Detail="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refByHand_Detail.invalid,
                  'alert-warning':
                    refByHand_Detail.invalid &&
                    (refByHand_Detail.dirty ||
                      refByHand_Detail.touched ||
                      refByHand_Detail.untouched)
                }"
                [(ngModel)]="sendToModal.ByHand_Detail"
                placeholder="By hand Detail"
                name="ByHand_Detail"
                id="ByHand_Detail"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Tracking Number </span>
              <input
                required
                type="text"
                #refTrackerNo="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refTrackerNo.invalid,
                  'alert-warning':
                    refTrackerNo.invalid &&
                    (refTrackerNo.dirty ||
                      refTrackerNo.touched ||
                      refTrackerNo.untouched)
                }"
                [(ngModel)]="sendToModal.TrackerNo"
                placeholder="Tracking Number"
                name="TrackerNo"
                id="TrackerNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Sending Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="SendDate"
                  id="SendDate"
                  #refSendDate="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': f.submitted && refSendDate.invalid,
                    'alert-warning':
                      refSendDate.invalid &&
                      (refSendDate.dirty ||
                        refSendDate.touched ||
                        refSendDate.untouched)
                  }"
                  [(ngModel)]="sendToModal.SendDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="SendDate"
                id="SendDate"
                #refSendDate="ngModel"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refSendDate.invalid,
                  'alert-warning':
                    refSendDate.invalid &&
                    (refSendDate.dirty ||
                      refSendDate.touched ||
                      refSendDate.untouched)
                }"
                [(ngModel)]="sendToModal.SendDate"
                value="{{ sendToModal.SendDate | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                [maxDate]="CurrentDate"
                class="form-control input-text-css"
                [(bsValue)]="sendToModal.SendDate"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Note </span>
              <textarea
                required
                rows="3"
                #refNote="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refNote.invalid,
                  'alert-warning':
                    refNote.invalid &&
                    (refNote.dirty || refNote.touched || refNote.untouched)
                }"
                [(ngModel)]="sendToModal.Note"
                placeholder="Note"
                name="Note"
                id="Note"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="SelectStatus == 'Pending'">
          <button
            type="button"
            (click)="onCloseSendHO()"
            class="mt-2 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSendToHo()"
            class="mt-2 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="HoldReject"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #fs="ngForm" (ngSubmit)="QuickLoanUpdateStatusProcess()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Loan Action
          </h6>
          <button
            type="button"
            (click)="OnCloseHoldReject()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable"> Status </span>
              <select
                name="Status"
                id="Status"
                #refStatus="ngModel"
                [ngClass]="{
                  'is-invalid': fs.submitted && refStatus.invalid,
                  'alert-warning':
                    refStatus.invalid &&
                    (refStatus.dirty ||
                      refStatus.touched ||
                      refStatus.untouched)
                }"
                [(ngModel)]="Status"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Status</option>
                <option value="InProcess">In Process</option>
                <option value="Reject" [hidden]="SelectStatus != 'Hold'">
                  Rejected
                </option>
              </select>
            </div>

            <div class="col-md-12 p-1" *ngIf="Status == 'Reject'">
              <span class="required-lable"> Remarks </span>
              <textarea
                required
                rows="3"
                #refRemarks="ngModel"
                [ngClass]="{
                  'is-invalid': fs.submitted && refRemarks.invalid,
                  'alert-warning':
                    refRemarks.invalid &&
                    (refRemarks.dirty ||
                      refRemarks.touched ||
                      refRemarks.untouched)
                }"
                [(ngModel)]="Remarks"
                placeholder="Remarks"
                name="Remarks"
                id="Remarks"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseHoldReject()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="QuickLoanUpdateStatusProcess()"
            class="btn font-size-12 button-btn"
            [disabled]="!fs.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- View TAT Report Modal Sheet-->
<div
  class="modal fade in"
  id="ViewTATReportModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Details
        </h6>
        <button
          type="button"
          (click)="onCloseTATReportModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <table>
          <tbody>
            <tr>
              <td>
                <div><strong>Application No:</strong></div>
                {{ Application_No }}
              </td>
              <td>
                <div><strong>Customer:</strong></div>
                <div *ngIf="CustomerInfo == ''">&nbsp;</div>
                {{ CustomerInfo }}
              </td>
              <td>
                <div><strong>Product:</strong></div>
                {{ Product }}
              </td>
              <td>
                <div><strong>Branch:</strong></div>
                {{ Branch }}
              </td>
              <td>
                <div><strong>Loan Amount:</strong></div>
                ₹ {{ LoanAmount }}
              </td>
              <td>
                <div><strong>Loan Duration(Month):</strong></div>
                {{ LoanDuration_Month }}
                {{ LoanDuration_Month != "" ? "Months" : "" }}
              </td>
              <td>
                <div><strong>Created On:</strong></div>
                {{ ApplicationCreatedOn }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="table-responsive mt-3 mb-10"
          *ngIf="dataSourceDetail.data.length > 0"
        >
          <mat-table
            #TATTable
            [dataSource]="dataSourceDetail"
            matSort
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="ProcessId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
              >
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Process">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Process
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Process }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ProcessExecutive">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Executive
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ProcessExecutive }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ProcessStatus">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Status
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ProcessStatus }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AssignedOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Assign On
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.AssignedOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CompletedOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Completed On
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CompletedOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TAT_Hr">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                TAT (Hrs)
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TAT_Hr }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Remark
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Remark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LatLong">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                LatLong
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                <a
                  target="_blank"
                  *ngIf="
                    row.LatLong != '' &&
                    row.LatLong != null &&
                    row.LatLong != ','
                  "
                  [href]="'http://maps.google.com/maps?q=' + row.LatLong"
                >
                  <i class="fa-solid fa-location-dot"></i>
                </a>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedDetailColumns"
            ></mat-header-row>
            <mat-row
              [ngClass]="
                !!row.AssignedOn && !!!row.CompletedOn ? 'Pending' : ''
              "
              [ngClass]="{
                'pending-row':
                  row.ProcessStatus === 'Pending' ||
                  row.ProcessStatus === 'Reverted'
              }"
              *matRowDef="let row; columns: displayedDetailColumns"
            ></mat-row>
          </mat-table>
          <!-- <mat-paginator #paginatorRef [pageSizeOptions]="[50]" showFirstLastButtons> </mat-paginator> -->
        </div>

        <table style="text-align: center">
          <tbody>
            <tr>
              <td class="time-content">
                <div>
                  <strong
                    >Process Completion Time Taken:
                    {{ ProcessComplition }}</strong
                  >
                </div>
              </td>
              <td class="time-content">
                <div>
                  <strong>Pending Since: {{ PendingSince }}</strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<table
  id="quickloan_loan_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      App No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      App Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Purpose
    </th>
    <!-- <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT
    </th> -->
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Application_CreateOn }}</td>
    <td>{{ x.Customer_Name }} {{ x.Customer_RelationName }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Application_CreateBy }}</td>
    <td>{{ x.Application_LoanPurpose }}</td>
    <td>{{ x.RevertedRemark }}</td>
  </tr>
</table>
