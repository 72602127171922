import { Component, OnInit, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LosService } from "src/app/_LOS/services/los.service";

declare var $: any;

@Component({
  selector: 'app-lms-payment-authorization-list',
  templateUrl: './lms-payment-authorization-list.component.html',
  styleUrls: ['./lms-payment-authorization-list.component.scss']
})
export class LmsPaymentAuthorizationListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  status: any = "Pending";
  dataSource: any;
  displayedColumns: string[] = [];
  FileCheckingList: any[] = [];


  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute, 
    private lmsService: LmsService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService,
    private losService: LosService
  ) {

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.status = (param.get("status"));
      
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Payment Authorization List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
    if (this.status == '' || this.status == null || this.status == undefined) {
      this.status = 'Pending';
    }
    if (this.status == 'Hold') {
      this.displayedColumns = [
        "Loan_Id",  "Loan_Date", "LoanAcNo", "Product_Name", "Application_LoanAmount", "Customer_Name",
        "Amount", "Paid_Amount", "Pending_Amount", "Hold_Amount", "SourceType", "SourceName", "Action"
      ];
    }
    else {
      this.displayedColumns = [
        "Loan_Id", "Loan_Date", "LoanAcNo", "Product_Name", "Application_LoanAmount", "Customer_Name",
        "Amount", "Pending_Amount", "SourceType", "SourceName", "Action"
      ];
    }
    
    this.LMS_Get_DV_List();
  }
  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }
  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
      this.LMS_Get_DV_List();
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
      this.LMS_Get_DV_List();
    } else {
      this.LeadSourceName = "";
      this.LMS_Get_DV_List();
    }
  }

  LMS_Get_DV_List() {
    if (this.status == 'Hold') {
      this.displayedColumns = [
        "Loan_Id",  "Loan_Date", "LoanAcNo", "Product_Name", "Application_LoanAmount", "Customer_Name",
        "Amount", "Paid_Amount", "Pending_Amount", "Hold_Amount", "SourceType","SourceName", "Action"
      ];
    }
    else {
      this.displayedColumns = [
        "Loan_Id", "Loan_Date", "LoanAcNo", "Product_Name", "Application_LoanAmount", "Customer_Name",
        "Amount", "Pending_Amount",  "SourceType", "SourceName", "Action"
      ];
    }
    
    this.showSpinner = true;
    this.lmsService.LMS_Get_Payment_List_ByStatus({
      DV_Status: this.status, LoginUserId: this.currentUser.userId, SourceType: this.SelectSourceType || "", SourceId: this.LeadSourceName || 0})
    .subscribe((res: any) => {
      //console.log("res", res);
      this.FileCheckingList = JSON.parse(JSON.stringify(res));
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_payment_authorization") as HTMLTableElement;
    const worksheetName = 'PAYMENT AUTHORIZATION'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  goToDetail(data: any) {
    if (data.Bankname != null && data.Bankname != '') {
      this.router.navigate([
        `/lms/disbursment-payment-detail/${this._EncrDecrService.encrypt(encodeURIComponent(data.Loan_Id))}/${this.status}`,
      ]);
    } else {
      this.dialog.openDialog(`Add Disbursment Detail In Cheques And Repayment Tab!!`)
        .afterClosed().subscribe(dialogResult => {
        })
    }
   
  }



}
