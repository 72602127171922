// #region Import Used Components and Services
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestModel } from '../Shared/Models/app.MasterRequestModel';
import {
  DestrictResponseModel, GetProcessListModal, StateResponseModel, TahsilResponseModel, ZoneListResponseModel,
  GetBranchResponseModel, GetrSaveresponseModel, GetBranchDropdownResponseModel, GetEmployeeListResponseModel,
  EmployeeDropdownResponseModel, DestrictListResponseModel, TahsilListResponseModel,
  ProductForDropdownResponseModel, ZoneResponseModel, GetBranchDetailResponseModel, ReportingManagerForDropdownResponseModel, GetRoleForDropdownResponseModel,
  GetProductCategoryResponseModel, GetProductRequiredDOcResponseModel, GetDocumentMasterResponseModel, GetProductListResponseModel, GetProcesswisemployeeListresponseModel,
  GetProductChargesResponseModel,
  ProductChargesHeadDropDownResponseModel

} from '../Shared/Models/app.MasterResponseModel';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../snackbar/snackbar.component'
import { constantUrl } from '../../../src/app/Shared/constantUrl';
// #endregion

@Injectable({
  providedIn: 'root'
})

export class MobileService {
  // #region Variable Declarations and Models 
  //private data: any;
  //token: any;
  // #endregion

  // #region constructor ListuserService
  constructor(private _Route: Router, private http: HttpClient, public snackBar: MatSnackBar) { }
  
  // #LOS_GetApplicationDetailsApp
  public LOS_GetApplicationDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetApplicationDetailsApp`, requestData);
  }
  // #end LOS_GetApplicationDetailsApp

  // #Get_CustomerDetailsApp
  public Get_CustomerDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/Get_CustomerDetailsApp`, requestData);
  }
  // #end Get_CustomerDetailsApp

  // #LOS_UpdateCustomerDetailsApp
  public LOS_UpdateCustomerDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_UpdateCustomerDetailsApp`, requestData);
  }
  // #end LOS_UpdateCustomerDetailsApp

  // #Get_CustomerAddressApp
  public Get_CustomerAddressApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/Get_CustomerAddressApp`, requestData);
  }
  // #end Get_CustomerAddressApp

  // #LOS_SaveCustomerAddressApp
  public LOS_SaveCustomerAddressApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerAddressApp`, requestData);
  }
  // #end LOS_SaveCustomerAddressApp

  // #LOS_GetCustomerKYCDocApp
  public LOS_GetCustomerKYCDocApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerKYCDocApp`, requestData);
  }
  // #end LOS_GetCustomerKYCDocApp

  // #LOS_SaveCustomerKYCDocApp
  public LOS_SaveCustomerKYCDocApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerKYCDocApp`, requestData);
  }
  // #end LOS_SaveCustomerKYCDocApp

  // #LOS_GetCustomerMemberApp
  public LOS_GetCustomerMemberApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerMemberApp`, requestData);
  }
  // #end LOS_GetCustomerMemberApp

  // #LOS_SaveCustomerMemberApp
  public LOS_SaveCustomerMemberApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerMemberApp`, requestData);
  }
  // #end LOS_SaveCustomerMemberApp

  // #LOS_GetCustomerIncomeApp
  public LOS_GetCustomerIncomeApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerIncomeApp`, requestData);
  }
  // #end LOS_GetCustomerIncomeApp

  // #LOS_SaveCustomerIncomeDetailsApp
  public LOS_SaveCustomerIncomeDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerIncomeDetailsApp`, requestData);
  }
  // #end LOS_SaveCustomerIncomeDetailsApp

  // #LOS_GetCustomerExpenditureApp
  public LOS_GetCustomerExpenditureApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerExpenditureApp`, requestData);
  }
  // #end LOS_GetCustomerExpenditureApp

  // #LOS_SaveCustomerExpenditureApp
  public LOS_SaveCustomerExpenditureApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerExpenditureApp`, requestData);
  }
  // #end LOS_SaveCustomerExpenditureApp

  // #LOS_GetCustomerReferenceApp
  public LOS_GetCustomerReferenceApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerReferenceApp`, requestData);
  }
  // #end LOS_GetCustomerReferenceApp

  // #LOS_SaveCustomerReferenceApp
  public LOS_SaveCustomerReferenceApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerReferenceApp`, requestData);
  }
  // #end LOS_SaveCustomerReferenceApp
  
  // #LOS_GetCustomerExistingLoanApp
  public LOS_GetCustomerExistingLoanApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetCustomerExistingLoanApp`, requestData);
  }
  // #end LOS_GetCustomerExistingLoanApp
  
  // #LOS_SaveLOSCustomerExistingLoanApp
  public LOS_SaveLOSCustomerExistingLoanApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveLOSCustomerExistingLoanApp`, requestData);
  }
  // #end LOS_SaveLOSCustomerExistingLoanApp
  
  // #LOS_GetFIQuestionsApp
  public LOS_GetFIQuestionsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetFIQuestionsApp`, requestData);
  }
  // #end LOS_GetFIQuestionsApp
  
  // #LOS_SaveCustomerFIAnswerApp
  public LOS_SaveCustomerFIAnswerApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerFIAnswerApp`, requestData);
  }
  // #end LOS_SaveCustomerFIAnswerApp
  
  // #LOS_GetAssetDetailApp
  public LOS_GetAssetDetailApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetAssetDetailApp`, requestData);
  }
  // #end LOS_GetAssetDetailApp
  
  // #LOS_SaveAssetDetailsApp
  public LOS_SaveAssetDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveAssetDetailsApp`, requestData);
  }
  // #end LOS_SaveAssetDetailsApp

  // #LOS_CompleteProcessAPP
  public LOS_CompleteProcessAPP(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_CompleteProcessAPP`, requestData);
  }
  // #end LOS_CompleteProcessAPP
  
}

