
<div class="panel panel-default">
  <div class="panel-heading"><b style="color:orangered">Unauthorized Access</b></div>
  <div class="panel-body">
    <form #f="ngForm" novalidate>

      <div class="row">
        <div class="col-md-6"> 
          <div>
            <h1 style="color:orangered">You are not allowed to access this page.</h1>
            <br />
            <h5>
              The page request you want to access is authorized by your administartor.
              Please contact as if this is not what you wished to see.<br />
              <br />
              You can send email to <b style="color:orangered">support@mdlogic.com</b> for assistance on the same,
              Please attach snapshot of the page with the details.
            </h5>
          </div>
          <img src="../../assets/images/unauthorized-4f5f465f4ff4wefqrrqfcjdlasdaldkasd.gif" />
        </div>
      </div>
    </form>
        </div>
      </div>
