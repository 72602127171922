import { Component, OnInit } from '@angular/core';
import { } from '@agm/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit {
  // google map
  lat = 26.922070;
  lng = 75.778885;
  zoom = 16;
  animation: string = 'DROP';
  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.lat = Number(param.get("lat"));
      this.lng = Number(param.get("lng"));
    });
  }

  ngOnInit() {
  }

  // start google map
  mapClicked($event: any) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
  }
  markerOver() {
    this.animation = 'BOUNCE';
  }

  markerOut() {
    this.animation = '';
  }
}
