<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keydown.enter)="OnSearchPDCChequeList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span> From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            (dateChange)="DateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            (dateChange)="DateChange()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span>Select Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          [(ngModel)]="BranchId"
          class="form-control input-text-css"
        >
          <option value="0">Select Branch</option>
          <option *ngFor="let item of BranchesList" [value]="item.BranchId">
            {{ item.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Loan No.</span>
        <input
          name="LoanNo"
          [(ngModel)]="LoanNo"
          id="LoanNo"
          placeholder="Loan No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2 mt-3">
        <button
          type="button"
          (click)="OnSearchPDCChequeList()"
          class="btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" [hidden]="!DataSource">
    <mat-table
      [dataSource]="DataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="height: 55vh; max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="Loan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="max-width: 50px"
          >#</mat-header-cell
        >
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell mw50">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          style="max-width: 57px"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            (click)="goToPDCChequeListDetails(row)"
            aria-label="true"
            style="color: blue; cursor: pointer"
            >{{ row.LoanAcNo }}</a
          >
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Application No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_No }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Branch Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Loan_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Loan_Date | date : "dd, MMM yyyy" }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-left grid-header"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="Application_ExpiryDate">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Expiry Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{
            row.Application_ExpiryDate
              | date
                : "dd, MMM
              yyyy"
          }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LoanClosed_date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Closed Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanClosed_date | date : "dd, MMM yyyy" }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Application_LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_LoanAmount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-left grid-header">
          {{ totaltypewise("Application_LoanAmount") }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer Type</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="SPDC_Chq">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >SPDC Cheque</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SPDC_Chq }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="RPDC_Chq">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >RPDC Cheque</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.RPDC_Chq }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Application_Status">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Status</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_Status }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="rpdc_Used">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >RPDC Used</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.rpdc_Used }}</mat-cell
        >
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="remaningRpdc">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Remaining RPDC</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.remaningRpdc }}</mat-cell
        >
        <mat-footer-cell
          *matFooterCellDef
          class="text-left grid-header"
        ></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-center">
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToPDCChequeListDetails(row)"
            aria-label="true"
          ></i>
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          style="max-width: 100%"
        ></mat-footer-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-2">
        <button class="btn font-size-12 button-btn" (click)="exportTable()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-2">
        <button
          class="btn font-size-12 button-btn"
          (click)="exportTableDetails()"
          style="width: max-content"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export Details
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_pdc_cheque_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closed Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      SPDC Cheque
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Cheque
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Used
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Remaining Rpdc
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Loan_Date | date : "dd, MMM yyyy" }}</td>
    <td>{{ x.Application_ExpiryDate | date : "dd, MMM yyyy" }}</td>
    <td>{{ x.LoanClosed_Date }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.CustomerType }}</td>
    <td>{{ x.SPDC_Chq }}</td>
    <td>{{ x.RPDC_Chq }}</td>
    <td>{{ x.Application_Status }}</td>
    <td>{{ x.rpdc_Used }}</td>
    <td>{{ x.remaningRpdc }}</td>
  </tr>

  <tr>
    <td
      colspan="7"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    >
      <b>Total:</b>
    </td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    >
      <strong>{{ totaltypewise("Application_LoanAmount") }}</strong>
    </td>
    <td style="background: #4dc3a3 !important"></td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
    <td style="background: #4dc3a3 !important">
      <b></b>
    </td>
  </tr>
</table>

<table
  id="lms_pdc_cheque_list_Details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      LoanAc No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pdc Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank A/c Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank IFSC
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Cheque No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Cheque
    </th>
  </tr>
  <tr *ngFor="let x of JsonDataDetails; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.CustomerType }}</td>
    <td>{{ x.PdcType }}</td>
    <td>{{ x.BankAcType }}</td>
    <td>{{ x.AccountNo }}</td>
    <td>{{ x.BankIFSC }}</td>
    <td>{{ x.BankName }}</td>
    <td>{{ x.BankBranch }}</td>

    <td>{{ x.CHNo }}</td>

    <td>{{ x.RPDCCheque }}</td>
  </tr>
</table>

<div
  class="modal fade in"
  id="CustomerChequeDetailsModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cheque Details
        </h6>
        <button
          type="button"
          (click)="onCloseCustomerChequeDetailsModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive mt-3" [hidden]="!DataSourceChequeDetails">
          <mat-table
            [dataSource]="DataSourceChequeDetails"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 55vh; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="width: 50px !important"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="width: 50px !important"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="PdcType">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="width: 100px !important"
                *matHeaderCellDef
                >Pdc Type</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="width: 100px !important"
              >
                {{ row.PdcType }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="AccountNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Account No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.AccountNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BankName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bank Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BankName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BankBranch">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bank Branch</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BankBranch }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BankIFSC">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bank IFSC</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BankIFSC }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BankAcType">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bank A/c Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BankAcType }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CHNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Cheque No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CHNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="RPDCCheque">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >RPDC Cheque</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.RPDCCheque }}
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedChequeColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedChequeColumns"
            ></mat-row>
          </mat-table>
          <div class="row m-0 align-items-center" style="background: #fff">
            <div class="col-md-4">
              <button
                class="btn font-size-12 button-btn"
                (click)="exportChequeDetailTable()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8">
              <mat-paginator
                #paginatorRef
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_pdc_cheque_Details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pdc Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank IFSC
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bank A/c Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Cheque No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Cheque
    </th>
  </tr>
  <tr *ngFor="let x of JsonChequeData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.PdcType }}</td>
    <td>{{ x.AccountNo }}</td>
    <td>{{ x.BankName }}</td>
    <td>{{ x.BankBranch }}</td>
    <td>{{ x.BankIFSC }}</td>
    <td>{{ x.BankAcType }}</td>
    <td>{{ x.CHNo }}</td>
    <td>{{ x.RPDCCheque }}</td>
  </tr>
</table>
