import { Component, OnInit } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { PartnerService } from "../services/partner.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-partner-dashboard",
  templateUrl: "./partner-dashboard.component.html",
  styleUrls: ["./partner-dashboard.component.scss"],
})
export class PartnerDashboardComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dashboardData: any[] = [];
  iconname: string = "../../assets/images/lms/";

  gradientColors = [
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
  ];

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    private _Partnerservice: PartnerService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Partner Dashboard");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetPartnerDashboard();
  }

  GetPartnerDashboard() {
    this.showSpinner = true;
    this._Partnerservice.Partner_Get_Dashboard({}).subscribe((res: any) => {
      console.log("Dashboard : ", res);
      this.dashboardData = res;
      this.showSpinner = false;
    });
  }
  getDetail(ShortName: any) {
    switch (ShortName) {
      case "LCP":
        this._Route.navigate(["/partner-dashboard/partner-updateBooking-list"]);
        break;
      // case 'DCP': this._Route.navigate(['lms/dvauthorize']); break;
    }
  }
}
