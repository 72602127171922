import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
import * as moment from 'moment';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-collection-sub-area',
  templateUrl: './collection-sub-area.component.html',
  styleUrls: ['./collection-sub-area.component.scss']
})
export class CollectionSubAreaComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  UnderSubArea: any = '';
  UnderArea: any = '';
  SelectBranch: any = '';
  AreaList: any = [];
  // register new role
  EmployeeId: any = 0;
  // bind data
  BranchesList: any = [];
  // copy employee
  CopyEmployeeId: any = '';
  applicationId: any = 95;
  dataCustomerSource: any;
  saveBtn:boolean = true;
  updateBtn:boolean = false;
  subAreaId:number;

  displayedCustomerColumns: string[] = ['Number', 'SubArea', 'Area', 'Branch', 'Action'];
  JsonData: any;

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Sub Area Master');
    // this._MasterService.GetRoleForDropdown().subscribe((result) => {
    //   this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    // });

    this.LMS_GetSubAreaMaster();
    //this.getBranchesList();
    this.LMS_GetAreaMaster();
  }

  getBranchesList() {
    this.RequestModel.ZoneId = 0;
    this.RequestModel.DistrictId = 0;
    this._MasterService.GetBranches(this.RequestModel).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  LMS_GetAreaMaster() {
    this.showSpinner = true;
    this._LmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: 0 }).subscribe((response) => {
      this.AreaList = response;
    });
  }

  LMS_GetSubAreaMaster() {
    this.showSpinner = true;
    this._LmsService.Get_Lms_SubArea().subscribe((response) => {
      console.log('Get_Lms_Area', response);
      this.dataCustomerSource = new MatTableDataSource(response);
      this.JsonData = response;
    });
  }

  onSaveSubArealData() {
    let saveSubAreaDetail = {
      "Sub_Area_Name": this.UnderSubArea,
      "Area_Id": this.UnderArea,
      "Branch_Id": this.SelectBranch,
      "Int_Id": 0,
      "IsActive": 0
    }
    console.log('onSaveSubArealData', saveSubAreaDetail);
    this._LmsService.Save_Lms_SubArea(saveSubAreaDetail).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetSubAreaMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  removeSubArea(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete this Sub Area ${data.Sub_Area_Name}.`)
    .afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
        this._LmsService.Delete_Lms_SubAreaMaster({ Int_Id: data.Int_Id }).subscribe((res: any) => {
          this.showSpinner = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.LMS_GetSubAreaMaster();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        })
      }
    });
  }

  changeArea(event:any) {
    console.log('event',this.UnderArea);
    this._LmsService.Get_Lms_Branch_ByArea({ Area_Id: this.UnderArea }).subscribe((res: any) => {
      this.BranchesList = res;
      if(res.length>0) {
        this.SelectBranch = res[0].BranchId;
      }
    })
  }

  editCollectionSubArea(data:any) {
    this.UnderSubArea = data.Sub_Area_Name.trim();
    this.UnderArea = data.Area_Id;
    this.SelectBranch = data.Branch_Id;
    this.subAreaId = data.Int_Id;
    this.saveBtn = false;
    this.updateBtn = true;

    this._LmsService.Get_Lms_Branch_ByArea({ Area_Id: data.Area_Id }).subscribe((res: any) => {
      this.BranchesList = res;
      if(res.length>0) {
        this.SelectBranch = res[0].BranchId;
      }
    })
  }

  UpdateSubArea() {
    this.saveBtn = true;
    this.updateBtn = false;
    let updateSubAreaDetail = {
      "Int_Id": this.subAreaId,
      "Sub_Area_Name": this.UnderSubArea,
      "Area_Id": this.UnderArea,
      "Branch_Id": this.SelectBranch,
      "IsActive": 0
    }
    this._LmsService.Save_Lms_SubArea(updateSubAreaDetail).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.subAreaId = 0;
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.LMS_GetSubAreaMaster();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  applyFilter(filterValue:string){
    this.dataCustomerSource.filter = filterValue.trim().toLowerCase();
  }



  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("sub-area-table") as HTMLTableElement;
    const worksheetName = "SUB AREA EXPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

}
