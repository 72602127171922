<app-mat-spinner-overlay
  [mode]="mode"
  [value]="progressImport"
  *ngIf="showSpinner"
>
</app-mat-spinner-overlay>
<ng-container>
    <div class="row top-btn mt-2">
      <div class="col-md-6"></div>
      <div class="col-md-3">
        <label class="btn font-size-12 button-btn" for="upload">Import</label>
        <input
          type="file"
          id="upload"
          (change)="onImportExcel($event)"
          style="visibility: hidden"
          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
      <div class="col-md-3">
        <button
          (click)="exportAsExcelFile('Template')"
          class="btn font-size-12 button-btn"
          style="width: max-content"
        >
          Download Template
        </button>
      </div>
    </div>
    <div>
      <h6 *ngIf="Matchhdata.length > 0">Data Match</h6>
      <div
        class="table-responsive"
        style="margin-top: 20px"
        *ngIf="Matchhdata.length > 0"
      >
        <mat-table
          [dataSource]="dataSourceBulkImport"
          matSort
          matTableExporter
          #exporterTable="matTableExporter"
        >
          <ng-container matColumnDef="VoucherDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >VoucherDate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.VoucherDate
            }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Receipt_No">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Receipt_No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Receipt_No
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Receipt_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >Receipt_Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.Receipt_Date
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Loan_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >Loan_No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.Loan_No
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >EMI_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.EMI_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="OD_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >OD_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.OD_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Other_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Other_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Other_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Narration">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Narration</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Narration
            }}</mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsBulkImport"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsBulkImport"
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center my-2">
          <div class="col-md-4">
            <button
              class="btn font-size-12 button-btn"
              (click)="exportTable(exporterTable)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <!-- <div class="col-md-8">
              <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
            </div> -->
        </div>
      </div>
  
      <div class="row Top-Margin" *ngIf="Matchhdata.length > 0">
        <div class="col-md-12"><h1 class="fs-12 h-b">Payment Mode</h1></div>
      </div>
      <form #df="ngForm" novalidate *ngIf="Matchhdata.length > 0">
        <div class="row m-0 align-items-center">
          <div class="col-md-3">
            <span class="required-lable">Select Tag </span>
            <select
              name="Voucher_Tag"
              id="Voucher_Tag"
              required
              (change)="onVoucherTagChange()"
              class="form-control input-text-css"
              #refVoucher_Tag="ngModel"
              [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
              [ngClass]="{
                'is-invalid': df.submitted && refVoucher_Tag.invalid,
                'alert-warning':
                  refVoucher_Tag.invalid &&
                  (refVoucher_Tag.dirty ||
                    refVoucher_Tag.touched ||
                    refVoucher_Tag.untouched)
              }"
            >
              <option value="Bank">Bank</option>
              <option value="CASH">CASH</option>
              <option value="Other">Other</option>
            </select>
          </div>
  
          <div class="col-md-3">
            <span class="required-lable">Select Account</span>
            <select
              name="DRAccountId"
              id="DRAccountId"
              required
              #refDRAccountId="ngModel"
              class="form-control input-text-css"
              [ngClass]="{
                'is-invalid': df.submitted && refDRAccountId.invalid,
                'alert-warning':
                  refDRAccountId.invalid &&
                  (refDRAccountId.dirty ||
                    refDRAccountId.touched ||
                    refDRAccountId.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.DRAccountId"
            >
              <option value="">Select Account</option>
              <option
                *ngFor="let data of accountTagDropdown"
                [value]="data.AccountId"
              >
                {{ data.Account_Name }}
              </option>
            </select>
          </div>
          <!--<div class="col-md-3" *ngIf="voucherModel.Voucher.Voucher_Tag=='Bank'">
              <span class="required-lable">Select Mode of Payment</span>
              <select name="Voucher_Mode_of_Payment" id="Voucher_Mode_of_Payment" required (change)="onChangeMode()" class="form-control input-text-css"
                      #refVoucher_Mode_of_Payment="ngModel" [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                      [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Mode_of_Payment.invalid, 'alert-warning': refVoucher_Mode_of_Payment.invalid  && (refVoucher_Mode_of_Payment.dirty || refVoucher_Mode_of_Payment.touched || refVoucher_Mode_of_Payment.untouched) }">
                <option value="">Select Mode Of Payment</option>
                <option value="Cheque">Cheque</option>
                <option value="RTGS">RTGS</option>
                <option value="Gateway">E-Gateway</option>
                <option value="NEFT">NEFT</option>
              </select>
            </div>-->
          <!--<div class="col-md-3">
              <span class="required-lable">Select Collection By</span>
              <select name="Collection_By" id="Collection_By" required #refCollection_By="ngModel" class="form-control input-text-css"
                      [ngClass]="{ 'is-invalid': df.submitted && refCollection_By.invalid, 'alert-warning': refCollection_By.invalid  && (refCollection_By.dirty || refCollection_By.touched || refCollection_By.untouched) }"
                      [(ngModel)]="voucherModel.Voucher.Collection_By">
                <option value=""> Select Account </option>
                <option *ngFor="let data of employeeDropdown" [value]="data.EmpId">{{data.EmpName}}</option>
              </select>
            </div>-->
        </div>
  
        <!--<div class="row m-0 mt-1" *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment!=''">
            <ng-container *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment=='Cheque'">
              <div class="col-md-4">
                <span class="required-lable">Cheque No</span>
                <input type="text" required #refVoucher_Cheque_No="ngModel"
                       [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Cheque_No.invalid, 'alert-warning': refVoucher_Cheque_No.invalid  && (refVoucher_Cheque_No.dirty || refVoucher_Cheque_No.touched || refVoucher_Cheque_No.untouched) }"
                       [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No" name="Voucher_Cheque_No" id="Voucher_Cheque_No" class="form-control input-text-css">
              </div>
              <div class="col-md-4">
                <span class="required-lable">Cheque Date </span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="pickervcd" dateConvert placeholder="DD/MM/YYYY" name="Voucher_Cheque_Date"
                         id="Voucher_Cheque_Date" #refVoucher_Cheque_Date="ngModel"
                         [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Cheque_Date.invalid, 'alert-warning': refVoucher_Cheque_Date.invalid  && (refVoucher_Cheque_Date.dirty || refVoucher_Cheque_Date.touched || refVoucher_Cheque_Date.untouched) }"
                         class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date">
                  <mat-datepicker-toggle matSuffix [for]="pickervcd"></mat-datepicker-toggle>
                  <mat-datepicker #pickervcd></mat-datepicker>
                </div>
  
              </div>
              <div class="col-md-4">
                <span class="required-lable">Bank Name</span>
                <input type="text" required #refVoucher_Bank_Name="ngModel"
                       [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Bank_Name.invalid, 'alert-warning': refVoucher_Bank_Name.invalid  && (refVoucher_Bank_Name.dirty || refVoucher_Bank_Name.touched || refVoucher_Bank_Name.untouched) }"
                       [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name" name="Voucher_Bank_Name" id="Voucher_Bank_Name" class="form-control input-text-css">
              </div>
  
            </ng-container>
            <ng-container *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment!='Cheque'">
              <div class="col-md-4">
                <span class="required-lable">Ref No</span>
                <input type="text" required #refVoucher_E_Ref_No="ngModel"
                       [ngClass]="{ 'is-invalid': df.submitted && refVoucher_E_Ref_No.invalid, 'alert-warning': refVoucher_E_Ref_No.invalid  && (refVoucher_E_Ref_No.dirty || refVoucher_E_Ref_No.touched || refVoucher_E_Ref_No.untouched) }"
                       [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No" name="Voucher_E_Ref_No" id="Voucher_E_Ref_No" class="form-control input-text-css">
              </div>
              <div class="col-md-4">
                <span class="required-lable">Transaction Date </span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="pickervetd" dateConvert placeholder="DD/MM/YYYY" name="Voucher_E_Transaction_Date"
                         id="Voucher_E_Transaction_Date" #refVoucher_E_Transaction_Date="ngModel"
                         [ngClass]="{ 'is-invalid': df.submitted && refVoucher_E_Transaction_Date.invalid, 'alert-warning': refVoucher_E_Transaction_Date.invalid  && (refVoucher_E_Transaction_Date.dirty || refVoucher_E_Transaction_Date.touched || refVoucher_E_Transaction_Date.untouched) }"
                         class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date">
                  <mat-datepicker-toggle matSuffix [for]="pickervetd"></mat-datepicker-toggle>
                  <mat-datepicker #pickervetd></mat-datepicker>
                </div>
  
              </div>
            </ng-container>
  
          </div>-->
        <!--<div class="row m-0 mt-1">
            <div class="col-md-12">
              <span class="">Voucher Narration</span>
              <input type="text" #refVoucher_Narration="ngModel"
                     [ngClass]="{ 'is-invalid': df.submitted && refVoucher_Narration.invalid, 'alert-warning': refVoucher_Narration.invalid  && (refVoucher_Narration.dirty || refVoucher_Narration.touched || refVoucher_Narration.untouched) }"
                     [(ngModel)]="voucherModel.Voucher.Voucher_Narration" name="Voucher_Narration" id="Voucher_Narration" class="form-control input-text-css">
            </div>
          </div>-->
        <div class="row col-md-12 m-0 mt-2 justify-content-end">
          <!--<button type="button" class="ml-4 btn btn-light font-size-12">Cancel</button>-->
  
          <button
            type="button"
            class="ml-4 btn font-size-12 button-btn"
            *ngIf="HideBtn == 1"
            (click)="saveVoucher()"
            [class.spinner]="loading"
            [disabled]="!df.form.valid"
          >
            Save
          </button>
        </div>
      </form>
      <h6 *ngIf="MisMatchdata.length > 0">Data Mismatch:</h6>
      <div
        class="table-responsive"
        style="margin-top: 20px"
        *ngIf="MisMatchdata.length > 0"
      >
        <mat-table
          [dataSource]="dataSourceBulkImport_NotMatch"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="VoucherDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >VoucherDate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.VoucherDate
            }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Receipt_No">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Receipt_No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Receipt_No
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Receipt_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >Receipt_Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.Receipt_Date
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Loan_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw100"
              *matHeaderCellDef
              >Loan_No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.Loan_No
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >EMI_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.EMI_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="OD_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >OD_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.OD_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Other_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Other_Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Other_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Narration">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Narration</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Narration
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="NotMach">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
              >Not Match Data</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NotMach
            }}</mat-cell>
          </ng-container>
  
          <mat-header-row
            *matHeaderRowDef="displayedColumnsNotMatchImport"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNotMatchImport"
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center my-2" >
          <div class="col-md-4">
            <button
              class="btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <!-- <div class="col-md-8">
                <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
              </div> -->
        </div>
      </div>
    </div>
  </ng-container>
