<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchBusinessData()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Report Type </span>
        <select
          name="ReportType"
          id="ReportType"
          [(ngModel)]="ReportType"
          class="form-control input-text-css"
          (change)="onChangeReportTypes()"
          required
          #refReportType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refReportType.invalid,
            'alert-warning':
              refReportType.invalid &&
              (refReportType.dirty ||
                refReportType.touched ||
                refReportType.untouched)
          }"
        >
          <option value="">Select Report Type</option>
          <option *ngFor="let row of ArrTypes" [value]="row.id">
            {{ row.value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" *ngIf="SlabDropdown">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="''">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchBusinessData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="DataDiv">
    <div class="">
      <div class="table-responsive mt-3">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 40px"
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              style="max-width: 40px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Loan_Acc_Category">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan A\c Category</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Acc_Category }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Gender }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Religion">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Religion</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Religion }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Cast">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Caste</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Cast }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="MaritalStatus">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Marital Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.MaritalStatus }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Customer_Profile">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Profile</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Profile }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Natureofwork">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Nature of work</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Natureofwork }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="SlabName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Slab Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.SlabName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="NoOfLoans">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Count</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.NoOfLoans }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("NoOfLoans") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="POS_OS">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >POS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.POS_OS == null ? 0 : row.POS_OS }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("POS_OS") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="AvgPosPerLoan">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200 text-right"
              *matHeaderCellDef
              >Avg Pos Per Loan</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell mw200 text-right"
              >{{ (row.POS_OS / row.NoOfLoans).toFixed(2) }}</mat-cell
            >
            <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              {{
                dataSource
                  ? (
                      totaltypewise("POS_OS") / totaltypewise("NoOfLoans")
                    ).toFixed(2)
                  : ""
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="IN_Percentage">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >IN %</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.IN_Percentage }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("IN_Percentage") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDueListDetails(row)"
                *ngIf="row.NoOfLoans > 0"
                aria-label="true"
              ></i>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-12 p-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-4"></div> -->
    <div
      class="col-md-12 card mt-3 shadow p-3 mb-5 rounded align-center formborder"
      style="background-color: #eef1f2; border-radius: 10px !important"
      *ngIf="chartdata != null"
    >
      <div class="d-flex align-items-centre justify-content-center">
        <app-charts [chartdata]="chartdata" [Types]="Types"></app-charts>
      </div>
    </div>
    <!-- <div class="col-md-2"></div> -->
  </div>
</div>

<div
  class="modal fade in"
  id="ViewDueListDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Details
        </h6>
        <button
          type="button"
          (click)="onCloseDueListDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceModel"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="
              height: 400px;
              max-width: 100%;
              overflow: auto;
              overflow-x: scroll;
            "
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayedColumnsModel"
            >
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                {{ col == "Id" ? "#" : col.split("_").join(" ") }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                {{
                  col == "Id"
                    ? paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    : row[col]
                }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></mat-header-row>
            <mat-row
              (click)="highlightRow(row)"
              [class.highlight]="row.Loan_Id == selectedId"
              *matRowDef="let row; columns: displayedColumnsModel"
            ></mat-row>
          </mat-table>
          <div class="row m-0 align-items-center">
            <div class="col-md-2">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportTableAsXLSX()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>

            <div class="col-md-4">
              <form action="" class="search-text">
                <div class="bg-light rounded rounded-pill shadow-sm">
                  <div class="input-group">
                    <input
                      type="text"
                      id="FilterInputSearch"
                      (keyup)="applyFilter($event.target.value)"
                      name="FilterInputSearch"
                      placeholder="What're you searching for?"
                      class="form-control border-0 bg-light"
                    />
                    <div class="input-group-append">
                      <button
                        id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-6">
              <mat-paginator
                #paginatorRef
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="portfolio_model"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th
      *ngFor="let col of displayedColumnsModel"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      {{ col == "Id" ? "#" : col.split("_").join(" ") }}
    </th>
  </tr>
  <tr *ngFor="let row of FileCheckingList; let i = index">
    <td *ngFor="let col of displayedColumnsModel">
      {{
        col == "Id"
          ? paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
          : row[col]
      }}
    </td>
  </tr>
</table>

<table
  id="portfolio_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Count
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <!-- <th style="background: #4dc3a3 !important; border: 1px solid white">
      Avg POS Per Loan
    </th> -->
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      IN %
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList1; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Name }}</td>
    <td>{{ x.NoOfLoans }}</td>
    <td>{{ x.POS_OS == null ? 0 : x.POS_OS }}</td>
    <!-- <td>{{ (x.POS_OS / x.NoOfLoans).toFixed(2) }}</td> -->
    <td>{{ x.IN_Percentage }}</td>
    <!-- <td>{{ x.accHead }}</td> -->
  </tr>

  <tfoot>
    <tr class="table-footer">
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("NoOfLoans") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("POS_OS") }}</strong>
      </td>
      <!-- <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        {{
          dataSource
            ? (totaltypewise("POS_OS")  / totaltypewise("NoOfLoans")).toFixed(2)
            : ""
        }}
      </td> -->
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("IN_Percentage") }}</strong>
      </td>
      <!-- <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td> -->
    </tr>
  </tfoot>
</table>
