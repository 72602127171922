<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="col-md-12 d-flex justify-content-between align-items-center formborder"
  >
    <div class="col-md-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-3 float-right">
      <button class="btn font-size-12 button-btn" (click)="addMACAdress()">
        Add MAC Address
      </button>
    </div>
  </div>
</div>

<div class="table-responsive mt-2">
  <table
    class="table-container"
    mat-table
    matSort
    [dataSource]="dataSource"
    id="mac_address"
  >
    <ng-container matColumnDef="Machine_Id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ i + 1 }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="MAC_Address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>MAC Address</th>
      <td mat-cell *matCellDef="let row">
        {{ row.MAC_Address }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Device_Type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Device Type</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Device_Type }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Device_Name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Device Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Device_Name }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Userids">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Employee Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Userids }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>
        Action
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="d-flex flex-row align-items-center justify-content-center mt-3"
      >
        <i
          *ngIf="!inViewMode"
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          (click)="EditMACAddress(row)"
        ></i>
        <i
          *ngIf="!inViewMode"
          class="fa fa-trash style-delete"
          (click)="onDelete(row)"
          aria-label="true"
        ></i>
      </mat-cell>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="sticky-footer fontcolor"
      style="background: #4dc3a3; height: 5px"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button class="btn font-size-12 button-btn" (click)="exportTable()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="mac_add"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ma="ngForm" (ngSubmit)="onSaveMACAddress()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add MAC Adress
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <div class="modal-body formborder m-3">
          <div class="col-md-12 p-1 d-flex">
            <div class="col-md-4 p-1">
              <span> Device Type </span>
              <select
                name="DeviceType"
                id=""
                required
                #refDeviceType="ngModel"
                [ngClass]="{
                  'is-invalid': ma.submitted && refDeviceType.invalid,
                  'alert-warning':
                    refDeviceType.invalid &&
                    (refDeviceType.dirty ||
                      refDeviceType.touched ||
                      refDeviceType.untouched)
                }"
                class="form-control input-text-css"
                [(ngModel)]="DeviceType"
              >
                <option value="">Select Type</option>
                <option value="Mobile">Mobile</option>
                <option value="PC">PC</option>
              </select>
            </div>
            <div class="col-md-4 p-1">
              <span class="required-lable">Device Name</span>
              <input
                type="text"
                name="DeviceName"
                id=""
                [(ngModel)]="DeviceName"
                class="form-control input-text-css"
                required
                #refDeviceName="ngModel"
                placeholder="Add Device Name"
                [ngClass]="{
                  'is-invalid': ma.submitted && refDeviceName.invalid,
                  'alert-warning':
                    refDeviceName.invalid &&
                    (refDeviceName.dirty ||
                      refDeviceName.touched ||
                      refDeviceName.untouched)
                }"
              />
            </div>
            <div class="col-md-4 p-1">
              <span class="required-lable">MAC Address</span>
              <input
                type="text"
                name="mac_add"
                id=""
                [(ngModel)]="MAC_add"
                class="form-control input-text-css"
                required
                #refmac_add="ngModel"
                placeholder="Add MAC Address"
                [ngClass]="{
                  'is-invalid': ma.submitted && refmac_add.invalid,
                  'alert-warning':
                    refmac_add.invalid &&
                    (refmac_add.dirty ||
                      refmac_add.touched ||
                      refmac_add.untouched)
                }"
              />
            </div>
          </div>
          <div class="col-md-12 p-1" style="margin-left: 2px">
            <span class="required-lable">Select Username</span>
            <form [formGroup]="userForm">
              <ng-multiselect-dropdown
                [(ngModel)]="empIDs"
                name="UserName"
                [placeholder]="'Select User Name'"
                [data]="EmployeeList"
                formControlName="UserName"
                [settings]="UserDropdownSettings"
                (onDeSelect)="OnUserDeSelect($event)"
                (onSelectAll)="OnSelectAll($event)"
                (onSelect)="OnUserSelect($event)"
                (onDeSelectAll)="OnDeSelectAll($event)"
              >
              </ng-multiselect-dropdown>
            </form>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="onSaveMACAddress()"
            [disabled]="!ma.form.valid"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="mac_add_updated"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <form #maupdate="ngForm" novalidate>
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Update MAC Address
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="col-md-12 p-1 d-flex">
            <div class="col-md-4 p-1">
              <span> Device Type </span>
              <select
                name="DeviceType"
                id=""
                class="form-control input-text-css"
                disabled
                [(ngModel)]="UpdateDeviceType"
              >
                <option value="">Select Type</option>
                <option value="Mobile">Mobile</option>
                <option value="PC">PC</option>
              </select>
            </div>
            <div class="col-md-4 p-1">
              <span>Device Name</span>
              <input
                type="text"
                name="DeviceName"
                id=""
                [(ngModel)]="updateDeviceName"
                class="form-control input-text-css"
                required
                #refupdateDeviceName="ngModel"
                placeholder="Add Device Name"
                disabled
                [ngClass]="{
                  'is-invalid':
                    maupdate.submitted && refupdateDeviceName.invalid,
                  'alert-warning':
                    refupdateDeviceName.invalid &&
                    (refupdateDeviceName.dirty ||
                      refupdateDeviceName.touched ||
                      refupdateDeviceName.untouched)
                }"
              />
            </div>
            <div class="col-md-4 p-1">
              <span>MAC Address</span>
              <input
                type="text"
                name="mac_add"
                id=""
                [(ngModel)]="update_MAC_add"
                class="form-control input-text-css"
                required
                #refupdate_MAC_add="ngModel"
                placeholder="Add MAC Address"
                disabled
                [ngClass]="{
                  'is-invalid': maupdate.submitted && refupdate_MAC_add.invalid,
                  'alert-warning':
                    refupdate_MAC_add.invalid &&
                    (refupdate_MAC_add.dirty ||
                      refupdate_MAC_add.touched ||
                      refupdate_MAC_add.untouched)
                }"
              />
            </div>
          </div>
          <div class="col-md-12 p-1">
            <span class="required-lable">Select Username</span>
            <form [formGroup]="userFormUpdate">
              <ng-multiselect-dropdown
                [(ngModel)]="UpdateUserName"
                name="UpdateUserName"
                [placeholder]="'Select User Name'"
                [data]="EmployeeList"
                formControlName="UpdateUserName"
                [settings]="UserDropdownSettings"
                (onDeSelect)="OnUserDeSelect($event)"
                (onSelectAll)="OnSelectAll($event)"
                (onSelect)="OnUserSelect($event)"
                (onDeSelectAll)="OnDeSelectAll($event)"
              >
              </ng-multiselect-dropdown>
            </form>
          </div>
          <div class="modal-footer" style="border: none">
            <button
              type="button"
              (click)="onUpdate()"
              class="mt-3 btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<table
  id="mac_address"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      MAC Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Device Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Device Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Action
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ x.Machine_Id }}</td>
    <td>{{ x.MAC_Address }}</td>
    <td>{{ x.Device_Type }}</td>
    <td>{{ x.Device_Name }}</td>
  </tr>
</table>
