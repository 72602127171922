<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0 d-flex align-items-center formborder">
    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-8 text-right">
      <button
        type="button"
        (click)="newScheduleRegenerate()"
        class="mt-3 btn font-size-12 button-btn"
      >
        New Schedule Regenerate
      </button>
    </div>
  </div>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Loan_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                [ngClass]="{
                  NotShow:
                    row.LoanClosed_Date == '' || row.LoanClosed_Date == null
                }"
                (click)="goToLoanDetail(row)"
                aria-label="true"
                style="color: blue; cursor: pointer"
                >{{ row.LoanNo }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Application No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              ₹ {{ row.LoanAmount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="MobileNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Mobile No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="currentUser.IsMasking">
                {{ row.MobileNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMasking">
                {{ row.MobileNo }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RestructuredOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Regenerated On
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.RestructuredOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RestructuredBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Regenerated By
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.RestructuredBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EditAction">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 100px"
              *matHeaderCellDef
              >View Detail</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 100px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                [ngClass]="{
                  NotShow:
                    row.LoanClosed_Date == '' || row.LoanClosed_Date == null
                }"
                (click)="goToLoanDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="SearchLoanDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Loan for Schedule Re-Generate
        </h6>
        <button
          type="button"
          (click)="onCloseSearchLoanDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-2">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Vehicle No</span>
              <input
                name="VehicleNo"
                [(ngModel)]="Customer.VehicleNo"
                id="VehicleNo"
                placeholder="Vehicle No"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Date From</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateFrom"
                  id="LoanDateFrom"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>

              <!-- <input
                name="LoanDateFrom"
                [(ngModel)]="Customer.LoanDateFrom"
                id="LoanDateFrom"
                placeholder="Date From"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>
            <div class="col-md-2">
              <span>Date To</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateTo"
                  id="LoanDateTo"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateTo"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateTo"
                [(ngModel)]="Customer.LoanDateTo"
                id="LoanDateTo"
                placeholder="Date To"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>

            <div class="col-md-3" style="display: none">
              <span>Close Case</span>
              <select
                name="CloseCase"
                id="CloseCase"
                class="form-control input-text-css"
                [(ngModel)]="Customer.CloseCase"
              >
                <option value="">Select Close Case</option>
                <option value="1">Close Case</option>
              </select>
            </div>
            <div class="col-md-3">
              <span>Select Type</span>
              <select
                name="SelectType"
                id="SelectType"
                class="form-control input-text-css"
                [(ngModel)]="SelectType"
                (change)="SearchChange()"
              >
                <option value="">Select Type</option>
                <option value="Branch">Branch</option>
                <option value="CollArea">Collection Area</option>
                <option value="CollSubArea">Collection Sub Area</option>
                <option value="CollExecutive">Collection Executive</option>
                <option value="SalesExecutive">Sales Executive</option>
                <option value="EngineNo">Engine No.</option>
                <option value="ChassisNo">Chassis No.</option>
                <option value="LoanAccCatg">Loan A/C Category</option>
              </select>
            </div>
            <div class="col-md-3">
              <div *ngIf="SelectType == 'Branch'">
                <span> Branch</span>
                <select
                  name="BranchId"
                  id="BranchId"
                  #refSelectGender="ngModel"
                  [(ngModel)]="Customer.BranchId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Branch</option>
                  <option
                    *ngFor="let branch of BranchesList"
                    [value]="branch.BranchId"
                  >
                    {{ branch.Branch_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'EngineNo'">
                <span>Engine No.</span>
                <input
                  name="EngineNo"
                  [(ngModel)]="Customer.EngineNo"
                  id="EngineNo"
                  placeholder="Engine No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'ChassisNo'">
                <span>Chassis No.</span>
                <input
                  name="ChassisNo"
                  [(ngModel)]="Customer.ChassisNo"
                  id="ChassisNo"
                  placeholder="Chassis No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'CollArea'">
                <span> Collection Area</span>
                <select
                  name="Area"
                  id="Area"
                  [(ngModel)]="Customer.Area"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Area</option>
                  <ng-container *ngFor="let area of areaData">
                    <option
                      *ngIf="area.Type.trim() == 'Collection Area'"
                      [value]="area.Int_Id"
                    >
                      {{ area.Area_Name }}
                    </option>
                  </ng-container>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollSubArea'">
                <span> Collection Sub Area</span>
                <select
                  name="SubArea"
                  id="SubArea"
                  [(ngModel)]="Customer.SubArea"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection SubArea</option>
                  <option
                    *ngFor="let subarea of subAreaData"
                    [value]="subarea.Int_Id"
                  >
                    {{ subarea.Sub_Area_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'SalesExecutive'">
                <span> Sales Executive</span>
                <select
                  name="SalesEx"
                  id="SalesEx"
                  [(ngModel)]="Customer.SalesEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Sales Executive</option>
                  <option
                    *ngFor="let SalesExec of SalesExecutiveData"
                    [value]="SalesExec.EmpId"
                  >
                    {{ SalesExec.EmpName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollExecutive'">
                <span> Collection Executive</span>
                <select
                  name="CollectionEx"
                  id="CollectionEx"
                  [(ngModel)]="Customer.CollectionEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Executive</option>
                  <option
                    *ngFor="let CollExec of CollectionExecutiveData"
                    [value]="CollExec.EmpId"
                  >
                    {{ CollExec.Emp_FirstName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'LoanAccCatg'">
                <span>Accounting Category </span>
                <select
                  name="LoanAccountingCatId"
                  id="LoanAccountingCatId"
                  [(ngModel)]="Customer.LoanAccountingCatId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Accounting Category</option>
                  <option
                    *ngFor="let AcCat of AccCatDropdown"
                    [value]="AcCat.Loan_CategoryId"
                  >
                    {{ AcCat.Loan_category }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-3 text-right">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        <div class="mt-3" [hidden]="!dataSourceCustomer">
          <div class="">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSourceCustomer"
                #sortCustomerList="matSort"
                matSort
                id="exportReport"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="LoanId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 150px"
                    *matHeaderCellDef
                    >Loan No</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px"
                    >{{ row.LoanNo }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="ApplicationNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 150px"
                    *matHeaderCellDef
                  >
                    Aplication No
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px"
                    >{{ row.ApplicationNo }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="Product">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Product</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Product
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Branch
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Customert Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CustomertName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Father Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.FatherName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 150px"
                    *matHeaderCellDef
                    >Phone No</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px"
                    >{{ row.PhoneNo }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="Loan_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 150px"
                    *matHeaderCellDef
                    >Loan Date
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px"
                    >{{ row.Loan_Date }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 100px"
                    *matHeaderCellDef
                    >View Detail</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center"
                    style="max-width: 100px"
                  >
                    <i
                      class="fa fa-arrow-circle-right btn_detail"
                      (click)="goToDetail(row)"
                      aria-label="true"
                    ></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedCustomerColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedCustomerColumns"
                ></mat-row>
              </mat-table>

              <mat-paginator
                #paginatorRef
                #paginatorCustomerList
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="schedule_regenerate"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mobile No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Regenerated On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Regenerated By
    </th>
  </tr>

  <tr *ngFor="let x of RestructuredLoanList; let i = index">
    <td>{{ i + 1 }}</td>

    <td>{{ x.LoanNo }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Customer }}</td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.MobileNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.MobileNo }}
      </div>
    </td>
    <td>{{ x.RestructuredOn }}</td>
    <td>{{ x.RestructuredBy }}</td>
  </tr>
</table>
