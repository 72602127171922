import { Component, OnInit, ViewChild } from "@angular/core";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
@Component({
  selector: "app-receipt-detail",
  templateUrl: "./receipt-detail.component.html",
  styleUrls: ["./receipt-detail.component.scss"],
})
export class ReceiptDetailComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  chargesHeadDropdown: any;
  ChargesHead: any = "0";
  CaseNo: any;
  datefrom: any = new Date();
  dateto: any = new Date();
  Receipt_Type: any = "All";
  ReceiptDatasource: any;
  JsonData: any[] = [];
  showSpinner = false;
  DisplayedColumn: any[];
  constructor(
    private _MasterService: MasterService,
    private _LMSService: LmsService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Receipt Book Details");
    this.getChargesHead();
  }

  getChargesHead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        console.log("Charges Head", res);

        this.chargesHeadDropdown = res;
      });
  }

  getReceiptBookDetails() {
    this.showSpinner = true;
    let requestModel = {
      FromDate: moment(this.datefrom).format("YYYY-MM-DD"),
      ToDate: moment(this.dateto).format("YYYY-MM-DD"),
      Receipt_Type: this.Receipt_Type,
      CaseNo: this.CaseNo,
      ChagesHead: this.ChargesHead,
    };
    this._LMSService
      .LMS_Receipt_detail(requestModel)
      .subscribe((response: any) => {
        if (response.length > 0) {
          this.DisplayedColumn = [
            "Number",
            "ReceiptNo",
            "Voucher_Date",
            "ReceiptDate",
            "CaseNo",
            "Customer_Name",
            "ChagesHead",
            "Account_Name",
            "Branch_Name",
            "Amount",
            "TranType",
            "Voucher_CreateBy",
            "Collection_Executive",
            "Collection_By",
            "Origin",
            "location",
            "Voucher_Narration",
            "Voucher_Tag",
            "Voucher_Type",
          ];
          this.JsonData = response;
          console.log("json Data response", this.JsonData);
          this.ReceiptDatasource = new MatTableDataSource(response);
          this.ReceiptDatasource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.ReceiptDatasource = null;
          this.showSpinner = false;
        }
      });
  }

  totaltypewise(type) {
    if (this.ReceiptDatasource != null) {
      let sum = 0;
      for (let i = 0; i < this.ReceiptDatasource.filteredData.length; i++) {
        sum += Number(this.ReceiptDatasource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "receipt_details"
    ) as HTMLTableElement;
    const worksheetName = "RECEIPT BOOK DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.ReceiptDatasource.filter = filterValue.trim().toLowerCase();
  }
}
