import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;

@Component({
  selector: "app-productassetdetail",
  templateUrl: "./productassetdetail.component.html",
  styleUrls: ["./productassetdetail.component.scss"],
})
export class ProductassetdetailComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  SaveRequestModel: RequestModel = new RequestModel();
  QuestionTypeOptions: any = [];
  FilterProuctId: any = "";
  FilterProuctId1: any = "";
  ProductListDropdown: any = [];
  displayedColumns: string[] = [
    "AssetId",
    "Product",
    "AssetQuestion",
    "AssetType",
    "Options",
    "IsMandatoryDisplay",
    "CreateOn",
    "CreateBy",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: any;
  loading: boolean = false;
  AssetDetail: [];
  SelectAssetType: string = "";
  AssetQuestion: string;
  SelectIsMandatory: string;
  AssetId: number;
  TitleName: string = "New";
  ismandatory: string;
  FIQuestionType: any = "";
  FIQuestionOptions: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Product Asset Detail");
    this._MasterService.Get_ALLProductDropdown().subscribe((result) => {
      this.ProductListDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getAssetdetailList() {
    if (this.FilterProuctId == "") {
      this.dataSource = [];
      return;
    }
    this.showSpinner = true;
    this.RequestModel.ProductId = parseInt(this.FilterProuctId);
    this._MasterService
      .Get_LoanProductAssetDetail(this.RequestModel)
      .subscribe((result) => {
        this.AssetDetail = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.AssetDetail);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onChangeQuestionType() {
    this.QuestionTypeOptions = [{ Questions_Option: "" }];
  }

  onAddNewRow() {
    this.QuestionTypeOptions.push({ Questions_Option: "" });
  }

  onDeleteRow(selectedIndex) {
    this.QuestionTypeOptions.splice(selectedIndex, 1);
  }

  onAddNew() {
    console.log(this.FilterProuctId, "prid");
    this.FilterProuctId1 = this.FilterProuctId;
    $("#AddNewProductAssetDetailModal").modal("show");
    $("#AddNewProductAssetDetailModal").css("z-index", "1050");
  }

  OnClose() {
    this.onCloseData();
    $("#AddNewProductAssetDetailModal").modal("hide");
  }

  OnEdit(selectedRow) {
    this.FilterProuctId1 = selectedRow.ProductId;
    this.AssetId = selectedRow.AssetId;
    this.AssetQuestion = selectedRow.AssetQuestion;
    this.SelectAssetType = selectedRow.AssetType;
    this.SelectIsMandatory = selectedRow.IsMandatory;
    if (this.SelectAssetType == "Select") {
      this.QuestionTypeOptions = [];
      selectedRow.Options.split("@@").map((item) => {
        this.QuestionTypeOptions.push({
          Questions_Option: item,
        });
      });
    }
    this.TitleName = "Edit";
    $("#AddNewProductAssetDetailModal").modal("show");
    $("#AddNewProductAssetDetailModal").css("z-index", "1050");
  }

  onSaveassetdetail() {
    let AssetQuestionOptions = Array.prototype.map
      .call(this.QuestionTypeOptions, function (item) {
        return item.Questions_Option;
      })
      .join("@@");

    this.SaveRequestModel.AssetId = this.AssetId;
    this.SaveRequestModel.Asset_ProductId = this.FilterProuctId1;
    this.SaveRequestModel.Asset_Question = this.AssetQuestion;
    this.SaveRequestModel.Asset_QuestionType = this.SelectAssetType;
    this.SaveRequestModel.Asset_QuestionOptions = AssetQuestionOptions;
    this.SaveRequestModel.Asset_IsMandatory = this.SelectIsMandatory;
    this.SaveRequestModel.LoginUserId = this.data.userId;

    this._MasterService
      .Save_ProductAssets(this.SaveRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getAssetdetailList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onCloseData() {
    this.TitleName = "New";
    this.AssetId = 0;
    this.FilterProuctId1 = "";
    this.AssetQuestion = "";
    this.SelectAssetType = "";
    this.SelectIsMandatory = "";
    this.onChangeQuestionType();
  }
}
