import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { ReportsService } from "../services/reports.service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-difference-emi-allocation",
  templateUrl: "./difference-emi-allocation.component.html",
  styleUrls: ["./difference-emi-allocation.component.scss"],
})
export class DifferenceEmiAllocationComponent implements OnInit {
  showSpinner: boolean = false;
  DataSource: any = [];
  JsonData: any[] = [];

  displayedColumns: string[] = [
    "Loan_Id",
    "LoanAcNo",
    "CustomerName",
    "Loan_Date",
    "LoanClosed_Date",
    "Branch_Name",
    "Product_Name",
    "diff",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Difference EMI Allocation");
    this.onLoadDiffEmiAllocation();
  }

  onLoadDiffEmiAllocation() {
    this.showSpinner = true;
    this.reportsService
      .Difference_LMS_Get_Loan_EMIAllocation({})
      .subscribe((res: any) => {
        console.log("Report :", res);
        this.JsonData = res;
        this.DataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.DataSource.paginator = this.paginator;
        this.DataSource.sort = this.sort;
        this.showSpinner = false;
      });
  }

  updateDiffEmiAllocation() {
    this.reportsService
      .Difference_LMS_Update_Loan_EMIAllocation({})
      .subscribe((response: any) => {
        // console.log("MSG :", response);
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.onLoadDiffEmiAllocation();
        }
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  exportExcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "diff-emi-allocation"
    ) as HTMLTableElement;
    const worksheetName = "DIFFERENCE EMI ALLOCATION"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
