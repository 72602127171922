<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="d-flex align-items-center justify-content-between formborder">
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button
    type="button"
    class="btn font-size-12 button-btn"
    (click)="onAddDepartment()"
  >
    <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
  </button>
</div>

<div class="mt-2">
  <div class="table-responsive mt-1">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="DeptId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Dept_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Department Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Dept_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 170px"
          *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 170px"
        >
          <div
            [hidden]="
              row.Dept_Name == 'Administration' ||
              row.Dept_Name == 'Collection' ||
              row.Dept_Name == 'Credit & Risk' ||
              row.Dept_Name == 'Finance & Accounts' ||
              row.Dept_Name == 'Human Resource (HR)' ||
              row.Dept_Name == 'IT & Network' ||
              row.Dept_Name == 'Legal & Compliance' ||
              row.Dept_Name == 'Marketing & Sales' ||
              row.Dept_Name == 'Operations'
            "
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              (click)="OnEditDepartmentData(row)"
              *ngIf="row.Role_Level != 0"
              style="cursor: pointer"
              aria-label="true"
            ></i>
            <i
              aria-label="true"
              class="fa fa-trash style-delete"
              (click)="OnDeleteDeleteData(row)"
              *ngIf="row.Role_Level != 0"
            ></i>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewDeptModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <form #f="ngForm" (ngSubmit)="onSaveDepartmentMaster()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ DepartmentModel.DeptId == 0 ? "Add" : "Edit" }} Department Master
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Department Name :</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refDept_Name="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDept_Name.invalid,
                  'alert-warning':
                    refDept_Name.invalid &&
                    (refDept_Name.dirty ||
                      refDept_Name.touched ||
                      refDept_Name.untouched)
                }"
                [(ngModel)]="DepartmentModel.Dept_Name"
                placeholder="Department Name"
                name="Dept_Name"
                id="Dept_Name"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveDepartmentMaster()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
