import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";

@Component({
  selector: "app-quickloan-my-lead-detail",
  templateUrl: "./quickloan-my-lead-detail.component.html",
  styleUrls: ["./quickloan-my-lead-detail.component.scss"],
})
export class QuickLoanMyLeadtDetailComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  inquiryId: any;
  inquiryDetail: any;
  inViewMode: boolean = false;
  loading: boolean = false;
  holdModel: any = {};
  revertModel: any = {};
  rejectModel: any = {};
  CurrentDate: any = new Date();
  tomorrowDate: any = new Date();

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,

    private router: Router,
    private losService: LosService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.inquiryId = decodeURIComponent(param.get("id")); // encdec.decrypt(param.get("id"));
    });
  }

  ngOnInit() {
    this.tomorrowDate.setDate(new Date().getDate() + 1);
    this.dataSharingService.LOSHeaderTitle.next("Lead Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.LOS_GetInquiryDetails();
  }
  LOS_GetInquiryDetails() {
    this.showSpinner = true;
    this.losService
      .LOS_GetInquiryDetails({ InquiryId: this.inquiryId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.inquiryDetail = res[0];
        this.inViewMode = this.inquiryDetail.Status != "Assigned";
      });
  }
  onHold(e: any) {
    e.stopPropagation();
    this.holdModel.InquiryId = this.inquiryId;
    this.holdModel.LoginUserId = this.currentUser.userId;
    this.holdModel.HoldReason = "";
    this.holdModel.NextFollowUp = null;
    this.holdModel.InquiryNo = this.inquiryDetail.InquiryNo;
    $("#hold").modal("show");
    $("#hold").css("z-index", "1050");
  }
  onHoldClose() {
    this.holdModel = {};
    $("#hold").modal("hide");
  }
  onHoldInquiry() {
    this.loading = true;
    this.losService
      .LOS_SaveHoldInquiry(this.holdModel)
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onHoldClose();
          this.router.navigate(["/quickloan-dashboard/quick-loan/my-lead"]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onRevert(e: any) {
    e.stopPropagation();
    this.revertModel.InquiryId = this.inquiryId;
    this.revertModel.LoginUserId = this.currentUser.userId;
    this.revertModel.RevertReason = "";
    this.revertModel.InquiryNo = this.inquiryDetail.InquiryNo;
    $("#revert").modal("show");
    $("#revert").css("z-index", "1050");
  }
  onRevertClose() {
    this.revertModel = {};
    $("#revert").modal("hide");
  }
  onRevertInquiry() {
    this.loading = true;
    this.losService
      .LOS_SaveRevertInquiry(this.revertModel)
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onRevertClose();
          this.router.navigate(["/LOS/my-lead"]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onReject(e: any) {
    e.stopPropagation();
    this.rejectModel.InquiryId = this.inquiryId;
    this.rejectModel.LoginUserId = this.currentUser.userId;
    this.rejectModel.RejectReason = "";
    this.rejectModel.InquiryNo = this.inquiryDetail.InquiryNo;
    $("#reject").modal("show");
    $("#reject").css("z-index", "1050");
  }
  onRejectClose() {
    this.revertModel = {};
    $("#reject").modal("hide");
  }
  onRejectInquiry() {
    this.loading = true;
    this.losService
      .LOS_SaveRejectInquiry(this.rejectModel)
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onRejectClose();
          this.router.navigate(["/quickloan-dashboard/quick-loan/my-lead"]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onProceed() {
    //var inquiryId = this.encdec.encrypt(this.inquiryId);

    //this.router.navigate([
    //  `/quickloan-dashboard/quick-loan/my-lead/${encodeURIComponent(this.inquiryId)}/process`,
    //]);
    let Processid = this.encdec.encrypt(encodeURIComponent(1));
    let ProductId = this.encdec.encrypt(encodeURIComponent(0));
    let inquiryId = this.encdec.encrypt(encodeURIComponent(this.inquiryId));

    this.router.navigate([
      `quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${inquiryId}`,
    ]);
  }
  OnClose() {
    this.router.navigate(["/quickloan-dashboard/quick-loan/my-lead"]);
  }
}
