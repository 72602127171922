<div class="grid-container">
  <div class="grid-row-con">
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Category</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Type</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Doc Type</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Doc Title</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">No Of Pages</div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold">Remark</div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold">Upload By</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Doc File Name</div>
    </div>
  </div>

  <div class="mt-1 grid-row-con" *ngFor="let row of attachedDocumentsList">
    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Category }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Type }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.DocType }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.DocTitle }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">{{ row.No_Of_Pages }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Remark }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.UploadBy }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        <a
          href="{{ docBaseUrl }}{{ ApplicationNumber.Application_No }}/{{
            row.UD_DocFileName
          }}"
          target="_blank"
        >
          <i class="fa fa-eye" style="font-size: large"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- <a
          target="_blank"
          *ngIf="
            row.UD_DocFileName != '' &&
            row.UD_DocFileName != null &&
            row.UD_DocFileName != ','
          "
          href="{{ Doc_Path }}{{ ApplicationNumber }}/{{ row.UD_DocFileName }}"
        >
          <i
            class="fa fa-paperclip mr-2"
            aria-hidden="true"
            style="font-size: 18px"
          ></i>
        </a> -->
