import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { constantUrl } from '../../Shared/constantUrl';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import * as moment from 'moment';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = 0;
  SelectRoleId: any = ''; FirstName: any = ''; LastName: any = '';
  SelectGender: any = ''; DateOfBirth: any = ''; PresentAddress: any = '';
  PermanentAddress: any = ''; PhoneNo: any = ''; AlternetPhoneNo: any = '';
  EnterEmail: any = ''; AadharNo: any = ''; EmployeeLoginId: any = '';
  EmployeePassword: any = ''; EmpManagerId: any = '';
  UploadImageBase64: any = '';
  UploadImageName: string = '';
  profilePicture: string = '';
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  applicationId: any = 95;
  dataCustomerSource: any;
  vehicleCategory: any = [];
  VehicleModelName: any = '';
  SelectVehicleCategory: any = '';
  SelectManufacture: any = '';
  ManufactureData: any = [];
  saveBtn:boolean = true;
  updateBtn:boolean = false;
  Id:number;


  displayedCustomerColumns: string[] = ['Number', 'Vehicle Model Name', 'Vehicle Category', 'Manufacture Name', 'Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Vehicle Model Master');
    // this._MasterService.GetRoleForDropdown().subscribe((result) => {
    //   this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    // });

    this.LMS_GetVehicleModelMaster();
    this.LMS_GetVehicleMaster();
    this.LMS_GetAssetInfoMaster();
  }

  //getEmployeeData() {
  //  this.BranchesList = [];
  //  this.LoanProcessList = [];
  //  this.EmployeeId = sessionStorage.getItem('getEmployeeID');
  //  this.RequestModel.EmpId = parseInt(this.EmployeeId);
  //  this._MasterService.GetEmployeeDetails(this.RequestModel).subscribe((result) => {
  //    this.EmployeeDetailData = result;
  //    if (this.EmployeeDetailData.Item1[0]) {
  //      this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
  //      this.onChangeRoles();
  //      this.FirstName = this.EmployeeDetailData.Item1[0].Emp_FirstName;
  //      this.LastName = this.EmployeeDetailData.Item1[0].Emp_LastName;
  //      this.SelectGender = this.EmployeeDetailData.Item1[0].Emp_Gender;
  //      this.DateOfBirth = new Date(moment(this.EmployeeDetailData.Item1[0].Emp_DOB).format('MM/DD/YYYY'));
  //      this.PresentAddress = this.EmployeeDetailData.Item1[0].Emp_PresentAddress;
  //      this.PermanentAddress = this.EmployeeDetailData.Item1[0].Emp_PermanentAddress;
  //      this.PhoneNo = this.EmployeeDetailData.Item1[0].Emp_PhoneNo;
  //      this.AlternetPhoneNo = this.EmployeeDetailData.Item1[0].Emp_PhoneNo1;
  //      this.EnterEmail = this.EmployeeDetailData.Item1[0].Emp_Email;
  //      this.AadharNo = this.EmployeeDetailData.Item1[0].Emp_AadharNo;
  //      this.EmployeeLoginId = this.EmployeeDetailData.Item1[0].Emp_LoginId;
  //      this.EmployeePassword = this.EmployeeDetailData.Item1[0].Emp_Password;
  //      this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;
  //      this.UploadImageName = this.EmployeeDetailData.Item1[0].Emp_Image;
  //      this.UploadImageBase64 = this.EmployeeDetailData.Item1[0].Emp_Image != '' ? constantUrl.apiProfilePicUrl + this.EmployeeDetailData.Item1[0].Emp_Image : '';
  //    }
  //    this.BranchesList = this.EmployeeDetailData.Item2;
  //    this.BranchesList.map((item) => {
  //      item.isActiveSelect = item.BranchAlradyAssigned;
  //      item.isDisable = item.BranchAlradyAssigned;
  //    });
  //    this.LoanProcessList = this.EmployeeDetailData.Item3;
  //    this.LoanProcessList.map((item) => {
  //      item.isActiveSelect = item.ProcessAlradyAssigned;
  //      item.isDisable = item.ProcessAlradyAssigned;
  //    });
  //  });
  //}

  onChangeRoles() {
    this.ReportingManagerDropdown = [];
    this.EmpManagerId = '';
    this.CopyEmployeeDropdown = [];
    this.CopyEmployeeId = '';
    if (this.SelectRoleId != '') {
      this.ReportingManagerRequestModel.EmployeeRole = parseInt(this.SelectRoleId);
      this._MasterService.ReportingManagerForDropdown(this.ReportingManagerRequestModel).subscribe((result) => {
        this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
      });
      if (this.EmployeeId == 0) {
        this.CopyEmployeeRequestModel.EmpRoleId = parseInt(this.SelectRoleId);
        this._MasterService.LOSGetEmployeeForCopyDropdown(this.CopyEmployeeRequestModel).subscribe((result) => {
          this.CopyEmployeeDropdown = JSON.parse(JSON.stringify(result));
        });
      }
    }
    if (this.EmployeeId == 0) {
      this.isShowCopyEmployee = false;
      this.BranchesList.map((item) => {
        item.isActiveSelect = false;
        item.isDisable = false;
        item.BranchAlradyAssigned = false;
      });
      this.LoanProcessList.map((item) => {
        item.isActiveSelect = false;
        item.isDisable = false;
        item.ProcessAlradyAssigned = false;
      });
    }
  }

  LMS_GetVehicleModelMaster() {
    this.showSpinner = true;
    this._LmsService.Get_Lms_VehicleModelMaster().subscribe((response) => {
      console.log(response);
      this.dataCustomerSource = new MatTableDataSource(response);
    });
  }

  LMS_GetVehicleMaster() {
    //this._LmsService.Get_Lms_VehicleMaster().subscribe((response) => {
    //  this.vehicleCategory = response;
    //});
    this._MasterService.GetVehicleCategoryForDropdown({}).subscribe((response: any) => {
      this.vehicleCategory = response;
    });
  }

  LMS_GetAssetInfoMaster() {
    this.showSpinner = true;
    //this._LmsService.Get_Lms_AssetInfo().subscribe((response) => {
    //  this.ManufactureData = response;
    //});
    this._MasterService.GetManufactureForDropdown({}).subscribe((response: any) => {
      this.ManufactureData = response;
    });
  }

  onSaveVehicleModel() {
    let saveEmployeeDetail = {
      "Int_Id": 0,
      "Vehicle_Model_Name": this.VehicleModelName,
      "Vehicle_Category_Id": this.SelectVehicleCategory,
      "AssetInfo_Id": this.SelectManufacture,
      "IsActive": 1,
    }
    this._LmsService.Save_Lms_VehicleModelMaster(saveEmployeeDetail).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.VehicleModelName = "";
        this.SelectManufacture = '';
        this.SelectVehicleCategory = '';
        this.LMS_GetVehicleModelMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  removeVehicleModel(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete this Vehicle Model ${data.Vehicle_Model_Name}.`)
    .afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
      this._LmsService.Delete_Lms_VehicleModelMaster({ Int_Id: data.Int_Id }).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.VehicleModelName = "";
          this.SelectManufacture = '';
          this.SelectVehicleCategory = '';
          this.LMS_GetVehicleModelMaster();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
      }
    });
  }

  editVehicleModel(data: any) {
    console.log('this.areaId', data);
   this.LMS_GetVehicleMaster();
   // this.LMS_GetAssetInfoMaster();
    this.VehicleModelName = data.Vehicle_Model_Name.trim();
    this.SelectManufacture = data.AssetInfo_Id;

    this.LMS_GetVehicleMaster();
    this.SelectVehicleCategory = data.Vehicle_Category_Id;
  
    this.Id = data.Int_Id;
    this.saveBtn = false;
    this.updateBtn = true;
  }

  UpdateVehicleModel() {
    this.saveBtn = true;
    this.updateBtn = false;
    let updateData = {
      "Int_Id": this.Id,
      "Vehicle_Model_Name": this.VehicleModelName,
      "Vehicle_Category_Id": this.SelectVehicleCategory,
      "AssetInfo_Id": this.SelectManufacture,
      "IsActive": 1,
    }
    this._LmsService.Save_Lms_VehicleModelMaster(updateData).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.Id = 0;
        this.VehicleModelName = "";
        this.SelectManufacture = '';
        this.SelectVehicleCategory = '';
        this.LMS_GetVehicleModelMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }


  applyFilter(filterValue: string) {
    this.dataCustomerSource.filter = filterValue.trim().toLowerCase();
  }


}
