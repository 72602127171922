import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from "../services/lms.service";
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { constantUrl } from '../../Shared/constantUrl';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';
import { LosService } from '../../_LOS/services/los.service';
import { map } from 'rxjs/operators';
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;


@Component({
  selector: 'app-lms-pdd-detail',
  templateUrl: './lms-pdd-detail.component.html',
  styleUrls: ['./lms-pdd-detail.component.scss']
})
export class LmsPddDetailComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  showDiv: boolean = false;
  RC_HPNEndorsment: boolean = false;
  Invoice_HPNEndorsment: boolean = false;
  PolicyTypeAssetsOption: any;
  UpdateType: any;
  BranchesList: any = [];
  ProductList: any = [];
  displayedColumns: string[] = [];
  DataSource: any = [];
  selectedId: any;
  dataSource: any = [];
  RCVerified_Button: boolean = false;
  //Form parameter
  LoanNo: any;
  SelectProduct: any = "";//3andVehicle Loan
  SelectBranch: any = 0;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  InsuranceInfo: any = {};
  CustomerDetailShow: boolean;
  AssetsDetailShow: boolean;
  Type: string;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(paginator: MatPaginator) {
    this.DataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.DataSource.sort = this.sort;
    }
  }

  ProductCatId: any;
  Product_Category: any;
  Collateral: any;
  Collateral_Type: any;
  Info: any;
  SelectType: any = "";
  ToDate: any = new Date();
  LoanId: any;
  ApplicationNo: any;
  AssetsAttachment: any = [{}];
  Insurance_Company_List: any;
  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  docBaseUrl: any;
  LoanAmount: any;
  LoanAcNo: any;
  loading: boolean = false;

  isEdit: boolean = false;
  isUpdate: boolean = false;
  AssetsForm: any = 'Form';
  propertyForm: string = '';
  Addi_ProductCat: boolean = false;
  AssetsForms: string;
  propertyForms: string;
  saveBtn: boolean = true;
  updateBtn: boolean = true;
  ID: any = 0;
  val_sum: any = 0;
  latitude: any = '';
  longitude: any = '';
  ValuationDoneByOther: any;
  ValuationRemark: any;
  SearchReportDoneByOther: any;
  SearchReportRemark: any;
  customerModel: any = {};
  ValuationDate: any;
  newVehicle: boolean = true;
  usedVehicle: boolean = false;
  vehicle_type: any = 'New';
  stateDropdown: any[] = [];
  districkDropdownReg: any[] = [];
  tehasilDropdownReg: any[] = [];
  duplicateData: any;
  ProductCategoryDropdown: any = [];
  InvoiceDate: any;
  CustomerList: any = [];
  DealerList: any;
  CollectionExecutiveData: any = [];
  today: Date = new Date();
  DocModel: any[] = [];
  DocModel1: any = {};
  ValuationDocData: any;
  SearchDocData: any;
  AgricultureForm: any;
  colDisplay = [];
  Showdditional: boolean = false;
  AssetsList: any = [];
  assetsDetail: any;
  Item: any = {};
  dataAssetItemSource: any;
  displayedAssetItemColumns: string[] = [];
  ItemArr: any = [];
  assetTotal: any;
  assetModel: any = {};
  displayedViewAssetItemColumns: any;
  ProductType = "";
  rowData = {}
  updatedIndex: any;
  updateInsurance: any;
  CopyEmployeeDropdown: any = []; vehicleCategoryData: any = [];
  CopyEmployeeId: any = ''; vehicleModelData: any = [];
  isShowCopyEmployee: boolean = false; ManufactureData: any = [];
  commercialVehicle: any = 'Commercial';

  SelectVehicleCategory: any = ''; FuelType: any = ''; RoadTaxUpto: any = '';
  RegistationExpiryDate: any = ''; SelectFuelType: any = ''; Color: any = '';
  FitnessUpto: any = ''; Dealer: any = '0'; PermitUpto: any = ''; VehicleCost: any = '';
  Route: any = ''; EngineNo: any = ''; ChassisNo: any = ''; KeyNo: any = ''; DeliveryorderNo: any = '';
  DeliveryorderDate: any = ''; DoIssuedFavour: any = ''; DealerContact: any = ''; LTVBase: any = '';
  ValuationGrid: any = ''; ValuationDoneBy: any = '0'; GridValue: any = ''; PermitNo: any = '';
  PermitIssuedDate: any = ''; PermitExpireDate: any = ''; PermitNature: any = ''; RouteDescription: any = '';
  VehicleManufacture: any = '0'; VehicleModel: any = '0'; Variant: any = ''; ManufactureYear: any = '';
  VehicleRegistationNo: any = ''; RegistationDate: any = ''; InvoiveNo: any = ''; InvoiveDate: any = ''; ValuationType: any = ''; OtherValuationType: any = ''; OtherValuationAmount: any = '';

  // property form
  PropertyOwnerName: any = ''; AddressOfProperty: any = ''; Location: any = '';
  TypeOfProperty: any = ''; NatureOfProperty: any = ''; OwnershipDocument: any = '';
  OwnershipType: any = ''; UnitOfMeasurement: any = ''; TotalArea: any = '';
  ConstructedArea: any = ''; ValutionAmount: any = ''; SearchReportDoneBy: any = '';
  TypeOfMortgage: any = ''; MortgageSingedBy: any = '';
  OwnershipTypeList: any; NatureOfPropertyList: any = ''; OwnershipDocumentList: any = '';
  TypeOfPropertyList: any = '';
  EstimationAmount: any = ''; ValuationRemarkDoc: any = ''; SearchReportRemarkDoc: any = '';
  InvoiceNo: any = ''; DealerContactPerson: any = '';
  InvoiceValue: any = ''; DealerContactNo: any = '';
  IssuedInFavourOf: any = ''; Remark: any = '';
  FuelTypeOption: any;
  UpdatedPDDDetails = {}
  SelectSubType: any = "";
  @ViewChild('CArea') ConstArea: ElementRef;
  @ViewChild('CAddArea') ConstAddArea: ElementRef;
  RCLastVerfiedDate: any;
  RCKYC_IsVerified: any;
  RCKey: any;
  RCSMS_URL: any;
  APIResponse: any;
  CurrentDate: any = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  constructor(
    private dataSharingService: DataSharingService,
    private LmsService: LmsService,
    private _MasterService: MasterService,
    public snackBar: MatSnackBar,
    private losService: LosService,
    private datepipe: DatePipe,
    private dialog: DialogService,
  ) {

  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("PDD Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getProductDropdown();
    this.getBranchesList();

    this.getProductCategory();
    this.GetCollectionExecutive();

    this.getFuelType();
    this.getDealerList();
    this.getNatureOfProperty();
    this.getOwnershipType();
    this.getOwnershipDocument();
    this.getTypeOfProperty();

    this.GetStateDropdown()
    this.LMS_GetAssetInfoMaster();
    this.LMS_GetVehicleMaster();
  }
  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getProductDropdown() {
    this._MasterService.GetProductForDropdown().subscribe((result) => {
      //console.log("ProductList : ",result);
      this.ProductList = JSON.parse(JSON.stringify(result));
    });
  }

  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      //console.log("BranchesList : ",result);
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  getOwnershipType() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'OWNERSHIP TYPE' }).subscribe((res: any) => {
      this.OwnershipTypeList = res;
    });
  }
  getOwnershipDocument() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'OWNERSHIP DOCUMENT' }).subscribe((res: any) => {
      this.OwnershipDocumentList = res;
    });
  }
  getNatureOfProperty() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'NATURE OF PROPERTY' }).subscribe((res: any) => {
      this.NatureOfPropertyList = res;
    });
  }
  getTypeOfProperty() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'TYPE OF PROPERTY' }).subscribe((res: any) => {
      this.TypeOfPropertyList = res;
    });
  }

  onSearchPDDDetail() {
    let date = ""

    date = moment(new Date(this.ToDate)).format('YYYY-MM-DD')
    //if (this.SelectType == 'RC') {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleRegistationNo",  "RegistationExpiryDate", "PDD", "Action"
    //  ];
    //} else {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleRegistationNo", "InvoiceNo", "AssetRenewalDate", "RegistationExpiryDate", "PDD", "Action"
    //  ];
    //}
    this.showSpinner = true;
    let ProdData;


    ProdData = this.SelectProduct.split('and');

    this.LmsService.LMS_Get_PDD_Detail({ LoanAcNo: this.LoanNo, ProductId: ProdData[0], ProductCatg: 'Vehicle Loan', Branch_Id: this.SelectBranch, LoginUserId: this.currentUser.userId, ToDate: date, Type: this.SelectType, SubType: this.SelectSubType })
      .pipe(map((x: any[]) => {
        x.forEach(element => {
          if (element["Insurance"] == "1900-01-01" || element["Insurance"] == "0")
            element["Insurance"] = null
        });
        return x
      })).subscribe((res: any) => {
        console.log("Result : ", res);
        this.showSpinner = false;
        if (res.length > 0) {
          this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
          this.showDiv = true;
          this.DataSource.filterPredicate = (record, filter) => {
            return record.Type == filter
          }
          this.filterTableDataAccoType()
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.DataSource = new MatTableDataSource([]);
          this.showDiv = false
        }
      });
  }

  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "PDD Detail",
      sheet: "PDD Detail",
    });
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  ViewData(row) {
    console.log("ROwwww", row);
    console.log("prosuct", this.SelectProduct);


    var arr = this.SelectProduct.split("and");
    this.ProductCatId = arr[0];
    this.Product_Category = arr[1];

    var CArr = arr[1].split(" ");
    this.Collateral = CArr[0];

    this.Collateral_Type = row.Collateral_Type;
    this.LoanId = row.Loan_Id;
    this.ApplicationNo = row.Application_No;
    this.LoanAmount = row.Application_LoanAmount;
    this.LoanAcNo = row.LoanAcNo;

    this.Item.Image2 = "";
    this.Item.Image2Data = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
    this.Item.PurityCT = 12;
    this.Item.ValulationAmount = '';
    this.Item.ItemType = '';

    //this.activedRoute.paramMap.subscribe((param: ParamMap) => {

    this.updateBtn = false;
    this.isEdit = false;
    this.saveBtn = true;

    this.AssetsForm = '';

    this.getCustomerList();

    this.LmsService.ViewAssetInfo({ SearchBy: this.Collateral, Id: row.Id }).subscribe((response: any) => {
      //console.log("response :", response);
      this.Info = response.Item1[0];
      this.AssetsAttachment = response.Item2;
      this.RC_HPNEndorsment = response.Item1[0].RC_HPNEndorsment;
      this.Invoice_HPNEndorsment = response.Item1[0].Invoice_HPNEndorsment;
      this.UpdateType = this.SelectType;
      this.ChangeUpdateType();
      //this.UpdateType = '';
      // $('#View').modal('show');
    
      $('#editPddDe').modal('show');
      $("#editPddDe").css("z-index", "1050");
    });
  }
  onCloseView() {
    $('#View').modal('hide');
  }

  editAssetInfo(Id: any) {
    this.isEdit = false;
    this.isUpdate = true;
    $('#View').modal('hide');
    this.AssetsAttachment.push({});
    if (this.Info.Collateral_Type == "Primary Collateral" || this.Info.Collateral_Type == "Primary") {
      //var value = this.loanSummary.ProductId;
      if (this.Product_Category == 'Property Loan') {
        this.AssetsForm = 'Form';
        this.propertyForm = 'Property';
      }
      if (this.Product_Category == 'Vehicle Loan') {
        this.AssetsForm = 'Form';
        this.propertyForm = 'Vehicle';
      }


      if (this.Info.Collateral == 'Property') {
        this.propertyForm == 'Property'
        $('#kycModel').modal('show');
        $("#kycModel").css("z-index", "1050");

        this.getDataProperty();
      }

      if (this.Info.Collateral == 'Vehicle') {
        this.propertyForm == 'Vehicle'

        $('#kycModel').modal('show');
        $("#kycModel").css("z-index", "1050");
        this.getDataVehicle();
      }
    }

    if (this.Info.Collateral_Type == "Additional Collateral" || this.Info.Collateral_Type == "Additional") {
      this.Addi_ProductCat = true;
      this.additional_collateral_category(this.Info.Collateral + ' Loan');
      if (this.Info.Collateral == 'Property') {

        this.propertyForms == 'Propertys'
        $('#additionalcollateral').modal('show');
        $("#additionalcollateral").css("z-index", "1050");
        this.getDataProperty();
      }


      if (this.Info.Collateral == 'Vehicle') {
        //this.additional_collateral_category('5-0');
        this.propertyForms == 'Vehicles';
        $('#additionalcollateral').modal('show');
        $("#additionalcollateral").css("z-index", "1050");
        this.getDataVehicle();
      }
    }
  }

  additional_collateral_category(value) {
    if (value) {
      //this.vehicleCategoryIds = value.split('-')[0];

      let productCat = value.trim();
      if (productCat == 'Property Loan') {
        this.AssetsForms == 'Forms';
        this.propertyForms = 'Propertys';
      }
      if (productCat == 'Vehicle Loan') {
        this.AssetsForms == 'Forms';
        this.propertyForms = 'Vehicles';
      }
    }
  }

  getDataProperty() {
    this.saveBtn = false;
    this.updateBtn = true;
    this.ID = this.Info.Id;
    this.PropertyOwnerName = this.Info.PropertyOwnerID;
    this.AddressOfProperty = this.Info.Address;
    this.Location = this.Info.GeoLocation;
    this.latitude = this.Info.GeoLocation.split(',')[0];
    this.longitude = this.Info.GeoLocation.split(',')[1];
    this.TypeOfProperty = this.Info.TypeofPropertyID;
    this.NatureOfProperty = this.Info.NatureofPropertyID;
    this.OwnershipDocument = this.Info.OwnershipDocumentID;
    this.OwnershipType = this.Info.OwnershipTypeID;
    this.UnitOfMeasurement = this.Info.UnitofMeasurement;
    this.TotalArea = this.Info.TotalArea;
    this.ConstructedArea = this.Info.ConstructedArea;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationDoneByOther = this.Info.ValuationDoneByOther;
    this.ValuationRemark = this.Info.ValuationRemark;
    this.SearchReportDoneByOther = this.Info.SearchReportDoneByOther;
    this.SearchReportRemark = this.Info.SearchReportRemark;
    this.ValuationRemarkDoc = this.Info.ValuationRemarkDoc;
    this.SearchReportRemarkDoc = this.Info.SearchReportRemarkDoc;
    this.SearchReportDoneBy = this.Info.LegalReportByID;
    this.TypeOfMortgage = this.Info.TypeofMortgage;
    this.MortgageSingedBy = this.Info.MortgageSingedByID;
    this.customerModel.Firm_RegState = this.Info.StateID;
    this.customerModel.Firm_RegDistrict = this.Info.DistrictID;
    this.customerModel.Firm_RegTehsil = this.Info.tasilID;
    this.GetDistrickDropdownReg(this.customerModel.Firm_RegState);
    this.GetTehasilDropdownReg(this.customerModel.Firm_RegDistrict);
  }

  getDataVehicle() {
    this.saveBtn = false;
    this.updateBtn = true;
    this.ID = this.Info.Id;

    this.VehicleManufacture = this.Info.VehicleManufactureID;
    this.SelectVehicleCategory = this.Info.VehicleCategoryID;
    this.VehicleModel = this.Info.VehicleModelNameID;
    this.LMS_GetVehicleModelMaster(event);

    this.Variant = this.Info.Variant;
    this.ManufactureYear = new Date(this.Info.MefgYear);
    this.VehicleRegistationNo = this.Info.VehicleRegistationNo == null ? this.Info.VehicleRegistationNo : this.Info.VehicleRegistationNoto.UpperCase();
    this.RegistationDate = new Date(this.Info.RegistationDate);
    this.RegistationExpiryDate = new Date(this.Info.RegistationExpiryDate);
    this.SelectFuelType = this.Info.FuelTypeID;

    // if (this.Info.TypeofProperty == 'Commercial') {
    this.RoadTaxUpto = new Date(this.Info.RoadTaxUpto);
    this.Color = this.Info.Color;
    this.FitnessUpto = new Date(this.Info.FitnessUpto);
    this.PermitUpto = new Date(this.Info.PermitUpto);
    this.VehicleCost = this.Info.VehicleCost;
    this.Route = this.Info.Rout;
    this.EngineNo = this.Info.EngineNo;
    this.ChassisNo = this.Info.ChassisNo;
    this.KeyNo = this.Info.KeyNo;
    // }EngineNo

    this.DeliveryorderNo = this.Info.DONo;
    this.DeliveryorderDate = new Date(this.Info.DODoDate);
    this.InvoiceNo = this.Info.InvoiceNo;
    this.InvoiceValue = this.Info.InvoiceValue;
    this.DealerContactPerson = this.Info.DealerContactPerson;
    this.DealerContactNo = this.Info.DealerContactNo;
    this.IssuedInFavourOf = this.Info.DOIssuedinfavourofID;
    this.Remark = this.Info.Remark;
    this.Dealer = this.Info.DealerID;
    this.EstimationAmount = this.Info.EstimationAmount;
    this.InvoiveDate = new Date(this.Info.InvoiceDate);
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationType = this.Info.ValuationType;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.OtherValuationAmount = this.Info.OtherValuationAmt;
    this.OtherValuationType = this.Info.OtherValuationType;
    this.ValuationDate = new Date(this.Info.ValuationDate);
    if (this.Info.Vehicle_Type == 'New') {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = 'New';
    }
    if (this.Info.Vehicle_Type == 'Used') {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = 'Used';

    }
    if (this.Info.Vehicle_SubType == 'Commercial') {
      this.commercialVehicle = 'Commercial';
    }

    if (this.Info.Vehicle_SubType == 'Non-Commercial') {
      this.commercialVehicle = 'Non-Commercial';
    }
  }

  LMS_GetVehicleMaster() {
    this._MasterService.GetVehicleCategoryForDropdown({}).subscribe((response: any) => {
      this.vehicleCategoryData = response;
    });
  }
  LMS_GetVehicleModelMaster(event) {
    if ((typeof this.VehicleManufacture != 'undefined' && this.VehicleManufacture) && (typeof this.SelectVehicleCategory != 'undefined' && this.SelectVehicleCategory)) {
      this._MasterService.GetVehicleModelForDropdown({ ManufactureId: this.VehicleManufacture, CategoryId: this.SelectVehicleCategory }).subscribe((response: any) => {
        this.vehicleModelData = response;
      });
    } else {
      this.vehicleModelData = [];
    }
  }

  LMS_GetAssetInfoMaster() {
    this._MasterService.GetManufactureForDropdown({}).subscribe((response: any) => {
      this.ManufactureData = response;
    });
  }

  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.LoanId }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
    });
  }
  getDealerList() {
    this.LmsService.GET_Partner_List({ Type: 'Dealer' }).subscribe((result) => {
      this.DealerList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.LmsService.Get_Lms_CollectionExecutive({ Branch_Id: this.SelectBranch }).subscribe((response) => {
      this.CollectionExecutiveData = response;
    });
  }

  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  GetDistrickDropdownReg(event: any) {
    this.losService.GetDistrickDropdown({ StateID: parseInt(this.customerModel.Firm_RegState) }).subscribe((res: any) => {
      this.districkDropdownReg = res;
    });
  }
  GetTehasilDropdownReg(event: any) {
    this.losService.GetTehasilDropdown({ DistrictId: parseInt(this.customerModel.Firm_RegDistrict) }).subscribe((res: any) => {
      this.tehasilDropdownReg = res;
    });
  }

  checkRegNonumber() {
    this.LmsService.GET_VEHICLEREGISTRATION_DETAILS({ Vehicle_No: this.VehicleRegistationNo }).subscribe((res: any) => {
      this.duplicateData = res;
      if (res.length > 0) {
        $('#duplicateData').modal('show');
        $("#duplicateData").css("z-index", "1050");
      }
    })
  }
  onCloseduplicateData() {
    $('#duplicateData').modal('hide');
  }

  LMS_GetAllUserAssetInfo() {
    let data = {
      Loan_Id: this.LoanId
    }
    this.LmsService.LMS_GetAllUserAssetInfo(data).subscribe((response: any) => {
      this.Showdditional = true;
      this.AssetsList = response;
      this.dataSource = new MatTableDataSource(response);
      for (let i = 0; i < response.length; i++) {
        if (response[i]["Collateral_Type"] == "Primary Collateral" || response[i]["Collateral_Type"] == "Primary")
          this.val_sum += Number(response[i]["ValuationAmount"]);
      }
    });
  }

  selectVehicleCategory(event) {
    this.commercialVehicle = this.SelectVehicleCategory;
  }

  onCloseKYC() {
    $('#kycModel').modal('hide');
    $('#additionalcollateral').modal('hide');
  }
  selectProducts(event: any) {
    this.additional_collateral_category(event.target.selectedOptions[0].label);
  }

  selectVehicleType(type: string) {
    if (type == 'New') {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = 'New';
    }
    if (type == 'Used') {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = 'Used';
      this.Invoice_HPNEndorsment = false;

    }
    if (type == 'Commercial') {
      this.commercialVehicle = 'Commercial';
    }

    if (type == 'Non-Commercial') {
      this.commercialVehicle = 'Non-Commercial';
    }
    if (this.RegistationDate != '') {
      var year = new Date(this.RegistationDate).getFullYear();
      var month = new Date(this.RegistationDate).getMonth();
      var day = new Date(this.RegistationDate).getDate();
      if (this.commercialVehicle == 'Commercial') {
        var c = new Date(year + 10, month, day - 1);
        this.RegistationExpiryDate = c;
      }
      if (this.commercialVehicle == 'Non-Commercial') {
        var c = new Date(year + 15, month, day - 1);
        this.RegistationExpiryDate = c;
      }
    }
  }

  ChangeRegistationDate(event: any) {
    if (this.RegistationDate && this.ManufactureYear) {
      if (this.RegistationDate < this.ManufactureYear) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Registation Date greater than ManufactureYear.",
          ...this.configSuccess,
        });
        this.RegistationDate = '';
      }
      var year = this.RegistationDate.getFullYear();
      var month = this.RegistationDate.getMonth();
      var day = this.RegistationDate.getDate();
      if (this.commercialVehicle == 'Commercial') {
        var c = new Date(year + 10, month, day - 1);
        this.RegistationExpiryDate = c;
      }
      if (this.commercialVehicle == 'Non-Commercial') {
        var c = new Date(year + 15, month, day - 1);
        this.RegistationExpiryDate = c;
      }
    }
  }
  ChangeExpiryDate(event: any) {
    if (this.RegistationExpiryDate < this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Registation Expiry Date greater than Registation Date.",
        ...this.configSuccess,
      });
      this.RegistationExpiryDate = '';
    }
  }
  ChangeDeliveryorderDate(event: any) {
    if (this.RegistationDate != null && this.RegistationDate != undefined && this.RegistationDate != '' && this.DeliveryorderDate > this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Delivery Order Date less than Registation Date.",
        ...this.configSuccess,
      });
      this.DeliveryorderDate = "";
    }
    else if (this.DeliveryorderDate > this.CurrentDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Delivery Order Date less than Current Date.",
        ...this.configSuccess,
      });
      this.DeliveryorderDate = "";
    }
  }
  ChangeInvoiveDateDate(event: any) {
    if (event < this.DeliveryorderDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Invoive  Date greater than Delivery Order Date.",
        ...this.configSuccess,
      });
      this.InvoiveDate = "";
    }
    if (this.RegistationDate != '' && this.RegistationDate != null && this.RegistationDate != undefined && event > this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Invoive  Date less than Registation Date.",
        ...this.configSuccess,
      });
      this.InvoiveDate = "";
    }
  }
  getFuelType() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'FUEL TYPE' }).subscribe((res: any) => {
      this.FuelTypeOption = res;
    });

  }

  fileChangeListenerAttachment(files: any, input: any) {
    if (
      !this.AssetsAttachment[
        this.AssetsAttachment.length - 1
      ].Title
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter  title.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.AssetsAttachment.find((obj) => {
      return obj.DocFileName == _fn;
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same file can not be allow again.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.AssetsAttachment[
      this.AssetsAttachment.length - 1
    ].DocFileName = _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;
    _data.ApplicationNo = this.ApplicationNo;
    reader.readAsDataURL(files[0]);
    input.value = "";
    this.AssetsAttachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };


  }
  fileChangeListener(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.ValuationRemarkDoc = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.ValuationDocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  fileChangeListener1(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.SearchReportRemarkDoc = _pre + '_' + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.SearchDocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.AssetsAttachment.splice(i, 1);
  }
  removeFile(input: any) {
    input.value = "";
    this.ValuationRemarkDoc = "";
    this.ValuationDocData = "";
  }
  removeFile1(input: any) {
    input.value = "";
    this.SearchReportRemarkDoc = "";
    this.SearchDocData = "";
  }
  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {

    })
  }
  UploadCrcDoc() {
    for (let i = 0; i < this.DocModel.length; i++) {
      this.losService.UploadCrcDoc(this.DocModel[i]).subscribe(
        (res: any) => {

        },
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }

  GetConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      this.ConstructedArea = 0;
      return;
    }
  }
  GetAdtConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      this.ConstAddArea.nativeElement.focus();
      this.ConstAddArea.nativeElement.value = 0;
      return;
    }
  }

  selectAgricultureForm(type: string) {
    if (type == 'Property') {
      this.propertyForm = 'Property';
    }
    if (type == 'Vehicle') {
      this.propertyForm = 'Vehicle';
    }
  }

  saveAssetInfo(Collateral_Type) {
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let saveData: any = {};
    this.AssetsAttachment.splice(this.AssetsAttachment.length - 1, 1);
    //console.log("this.AssetsAttachment", this.AssetsAttachment);
    let updateAssets;
    this.UploadCrcDoc();
    if (Collateral_Type == 'Primary Collateral' || Collateral_Type == 'Primary') {
      if (this.propertyForm == 'Property' || this.AddressOfProperty || this.TypeOfProperty) {
        // if Property detail adding in Primary Collateral
        if (this.ValuationDocData) {
          this.uploadDoc({ ApplicationNo: this.ApplicationNo, DocName: this.ValuationRemarkDoc, DocData: this.ValuationDocData });
        }
        if (this.SearchDocData) {
          this.uploadDoc({ ApplicationNo: this.ApplicationNo, DocName: this.SearchReportRemarkDoc, DocData: this.SearchDocData });
        }
        let GeoLocation = this.latitude + "," + this.longitude;
        saveData = {
          Property: this.dataSharingService.cleanObject({
            CustomerId: this.PropertyOwnerName,
            TypeofProperty: this.TypeOfProperty, NatureofProperty: this.NatureOfProperty, OwnershipType: this.OwnershipType,
            OwnershipDocument: this.OwnershipDocument, UnitofMeasurement: this.UnitOfMeasurement, Address: this.AddressOfProperty,
            TotalArea: this.TotalArea, ConstructedArea: this.ConstructedArea, TypeofMortgage: this.TypeOfMortgage,
            MortgageSingedBy: this.MortgageSingedBy, ValuationDoneBy: parseInt(this.ValuationDoneBy),
            ValuationAmount: this.ValutionAmount, GeoLocation: GeoLocation, LegalReportBy: localUser.userId,
            ValuationRemark: this.ValuationRemark, ValuationRemarkDoc: this.ValuationRemarkDoc, SearchReportRemark: this.SearchReportRemark, SearchReportRemarkDoc: this.SearchReportRemarkDoc,
            Collateral_Type: Collateral_Type, LoanId: this.LoanId, CreatedBy: localUser.userId,
            state: this.customerModel.Firm_RegState, District: this.customerModel.Firm_RegDistrict,
            tasil: this.customerModel.Firm_RegTehsil, ValuationDoneByOther: this.ValuationDoneByOther,
            SearchReportDoneByOther: this.SearchReportDoneByOther, SearchReportDoneBy: this.SearchReportDoneBy
          }),
          data: {
            LoanId: this.LoanId,
            AssetType: 'Property',
            AssetId: this.ID,
            CreatedBy: localUser.userId
          },
          Attachment: this.AssetsAttachment

        }
        //var RemoveAmount = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID }).ValuationAmount;
        var RemoveAmount = 0;
        var AmountList = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID });
        if (AmountList)
          RemoveAmount = AmountList.ValuationAmount;
        this.val_sum = Number(this.ValutionAmount) + Number(this.val_sum) - Number(RemoveAmount);
        let LTV = this.ValutionAmount == 0 ? 100 : ((this.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      }
      else if (this.propertyForm == 'Vehicle') {
        saveData = {
          Vehicle: this.dataSharingService.cleanObject({
            VehicleManufacture: parseInt(this.VehicleManufacture),
            VehicleCategory: parseInt(this.SelectVehicleCategory), VehicleModelName: parseInt(this.VehicleModel), Variant: this.Variant,
            FuelType: this.SelectFuelType, Color: this.Color, VehicleRegistationNo: this.VehicleRegistationNo == null ? '' : this.VehicleRegistationNo.toUpperCase(),
            RegistationDate: this.RegistationDate,
            RegistationExpiryDate: this.RegistationExpiryDate,
            EngineNo: this.EngineNo, ChassisNo: this.ChassisNo, KeyNo: this.KeyNo,

            DateofEndors: this.PermitIssuedDate,
            MefgYear: this.ManufactureYear,
            Category: '', VehicleCondition: '', DONo: this.DeliveryorderNo,
            DODoDate: this.DeliveryorderDate,
            EstimationAmount: this.EstimationAmount, InvoiceNo: this.InvoiceNo,
            InvoiceDate: this.InvoiceDate,
            InvoiceValue: this.InvoiceValue, DealerContactPerson: this.DealerContactPerson,
            DealerContactNo: this.DealerContactNo,
            DOIssuedinfavourof: this.IssuedInFavourOf, DealerId: parseInt(this.Dealer), Remark: this.Remark,
            ValuationDoneBy: this.ValuationDoneBy,
            ValuationDate: this.ValuationDate,
            ValuationAmount: this.ValutionAmount,
            ValuationType: this.ValuationType, OtherValuationType: this.OtherValuationType, OtherValuationAmt: this.OtherValuationAmount, FitnessAuthority: '',
            FitnessUpto: this.FitnessUpto,
            PermitAuthority: '', PermitUpto: this.PermitUpto,
            PermitType: '', ExtraInfo: '', Collateral_Type: Collateral_Type, LoanId: this.LoanId,
            CreatedBy: localUser.userId, RoadTaxUpto: this.RoadTaxUpto, VehicleCost: this.VehicleCost,
            Route: this.Route, vehicle_type: this.vehicle_type,
            Vehicle_Subtype: this.commercialVehicle,
            InvoiceHPNEndorsment: this.Invoice_HPNEndorsment == true ? 1 : 0,
            RCHPNEndorsment: this.RC_HPNEndorsment == true ? 1 : 0
          }),
          data: {
            LoanId: this.LoanId,
            AssetType: 'Vehicle',
            AssetId: this.ID,
            CreatedBy: localUser.userId
          },
          Attachment: this.AssetsAttachment
        }
        //console.log("this.AssetsList", this.AssetsList);
        var RemoveAmount = 0;
        var AmountList = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID });
        if (AmountList)
          RemoveAmount = AmountList.ValuationAmount;
        this.val_sum = Number(this.VehicleCost) + Number(this.val_sum) - Number(RemoveAmount);
        let LTV = this.VehicleCost == 0 ? 100 : ((this.LoanAmount / (this.val_sum == 0 ? 1 : this.val_sum)) * 100);
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
        //if (saveData.Vehicle.RegistationDate) {
        //  saveData.Vehicle.RegistationDate = moment(this.RegistationDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.RegistationDate = null;
        //}

        //if (saveData.Vehicle.RegistationExpiryDate) {
        //  saveData.Vehicle.RegistationExpiryDate = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.RegistationExpiryDate = null;
        //}

        //if (saveData.Vehicle.DateofEndors) {
        //  saveData.Vehicle.DateofEndors = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.DateofEndors = null;
        //}

        if (saveData.Vehicle.MefgYear) {
          saveData.Vehicle.MefgYear = this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear();
        } else {
          saveData.Vehicle.MefgYear = null;
        }

        //if (saveData.Vehicle.DODoDate) {
        //  saveData.Vehicle.DODoDate = moment(this.RegistationDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.DODoDate = null;
        //}

        //if (saveData.Vehicle.FitnessUpto) {
        //  saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.FitnessUpto = null;
        //}
        //if (saveData.Vehicle.PermitUpto) {
        //  saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.PermitUpto = null;
        //}
      }
    }

    if (Collateral_Type == 'Additional Collateral' || Collateral_Type == 'Additional') {

      // if Property detail adding in Primary Collateral
      // propertyForms
      if (this.propertyForms == 'Propertys' || this.AddressOfProperty || this.TypeOfProperty) {
        if (this.ValuationDocData) {
          this.uploadDoc({ ApplicationNo: this.ApplicationNo, DocName: this.ValuationRemarkDoc, DocData: this.ValuationDocData });
        }
        if (this.SearchDocData) {
          this.uploadDoc({ ApplicationNo: this.ApplicationNo, DocName: this.SearchReportRemarkDoc, DocData: this.SearchDocData });
        }
        let GeoLocation = this.latitude + "," + this.longitude;
        saveData = {
          Property: this.dataSharingService.cleanObject({
            CustomerId: this.PropertyOwnerName,
            TypeofProperty: this.TypeOfProperty, NatureofProperty: this.NatureOfProperty, OwnershipType: this.OwnershipType,
            OwnershipDocument: this.OwnershipDocument, UnitofMeasurement: this.UnitOfMeasurement, Address: this.AddressOfProperty,
            TotalArea: this.TotalArea, ConstructedArea: this.ConstructedArea, TypeofMortgage: this.TypeOfMortgage,
            MortgageSingedBy: this.MortgageSingedBy, ValuationDoneBy: parseInt(this.ValuationDoneBy),
            ValuationAmount: this.ValutionAmount, GeoLocation: GeoLocation, LegalReportBy: localUser.userId,
            ValuationRemark: this.ValuationRemark, ValuationRemarkDoc: this.ValuationRemarkDoc, SearchReportRemark: this.SearchReportRemark, SearchReportRemarkDoc: this.SearchReportRemarkDoc,
            Collateral_Type: Collateral_Type, LoanId: this.LoanId, CreatedBy: localUser.userId,
            state: this.customerModel.Firm_RegState, District: this.customerModel.Firm_RegDistrict,
            tasil: this.customerModel.Firm_RegTehsil, ValuationDoneByOther: this.ValuationDoneByOther,
            SearchReportDoneByOther: this.SearchReportDoneByOther, SearchReportDoneBy: this.SearchReportDoneBy
          }),
          data: {
            LoanId: this.LoanId,
            AssetType: 'Property',
            AssetId: this.ID,
            CreatedBy: localUser.userId
          },
          Attachment: this.AssetsAttachment
        }
      }
      else if (this.propertyForms == 'Vehicles') {

        // if Vehicle detail adding in Primary Collateral
        saveData = {
          Vehicle: this.dataSharingService.cleanObject({
            VehicleManufacture: parseInt(this.VehicleManufacture),
            VehicleCategory: parseInt(this.SelectVehicleCategory), VehicleModelName: parseInt(this.VehicleModel), Variant: this.Variant,
            FuelType: this.SelectFuelType, Color: this.Color, VehicleRegistationNo: this.VehicleRegistationNo == null ? '' : this.VehicleRegistationNo.toUpperCase(),
            RegistationDate: this.RegistationDate,
            RegistationExpiryDate: this.RegistationExpiryDate,
            EngineNo: this.EngineNo, ChassisNo: this.ChassisNo, KeyNo: this.KeyNo,

            DateofEndors: this.PermitIssuedDate,
            MefgYear: this.ManufactureYear,
            Category: '', VehicleCondition: '', DONo: this.DeliveryorderNo,
            DODoDate: this.DeliveryorderDate,
            EstimationAmount: this.EstimationAmount, InvoiceNo: this.InvoiceNo,
            InvoiceDate: this.InvoiceDate,
            InvoiceValue: this.InvoiceValue, DealerContactPerson: this.DealerContactPerson,
            DealerContactNo: this.DealerContactNo,
            DOIssuedinfavourof: this.IssuedInFavourOf, DealerId: parseInt(this.Dealer), Remark: this.Remark,
            ValuationDoneBy: this.ValuationDoneBy,
            ValuationDate: this.ValuationDate,
            ValuationAmount: this.ValutionAmount,
            ValuationType: this.ValuationType, OtherValuationType: this.OtherValuationType, OtherValuationAmt: this.OtherValuationAmount, FitnessAuthority: '',
            FitnessUpto: this.FitnessUpto,
            PermitAuthority: '', PermitUpto: this.PermitUpto,
            PermitType: '', ExtraInfo: '', Collateral_Type: Collateral_Type, LoanId: this.LoanId,
            CreatedBy: localUser.userId, RoadTaxUpto: this.RoadTaxUpto, VehicleCost: this.VehicleCost,
            Route: this.Route, vehicle_type: this.vehicle_type,
            Vehicle_Subtype: this.commercialVehicle,
            InvoiceHPNEndorsment: this.Invoice_HPNEndorsment == true ? 1 : 0,
            RCHPNEndorsment: this.RC_HPNEndorsment == true ? 1 : 0
          }),
          data: {
            LoanId: this.LoanId,
            AssetType: 'Vehicle',
            AssetId: this.ID,
            CreatedBy: localUser.userId
          },
          Attachment: this.AssetsAttachment
        }

        //if (saveData.Vehicle.RegistationDate) {
        //  saveData.Vehicle.RegistationDate = moment(this.RegistationDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.RegistationDate = null;
        //}

        //if (saveData.Vehicle.RegistationExpiryDate) {
        //  saveData.Vehicle.RegistationExpiryDate = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.RegistationExpiryDate = null;
        //}

        //if (saveData.Vehicle.DateofEndors) {
        //  saveData.Vehicle.DateofEndors = moment(this.RegistationExpiryDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.DateofEndors = null;
        //}

        if (saveData.Vehicle.MefgYear) {
          saveData.Vehicle.MefgYear = this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear();
        } else {
          saveData.Vehicle.MefgYear = null;
        }

        //if (saveData.Vehicle.DODoDate) {
        //  saveData.Vehicle.DODoDate = moment(this.RegistationDate).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.DODoDate = null;
        //}

        //if (saveData.Vehicle.FitnessUpto) {
        //  saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.FitnessUpto = null;
        //}
        //if (saveData.Vehicle.PermitUpto) {
        //  saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format('YYYY-MM-DD')
        //} else {
        //  saveData.Vehicle.PermitUpto = null;
        //}
      }
    }

    this.LmsService.SaveUserAssetInfo({ JSON: JSON.stringify(saveData) }).subscribe((response) => {
      this.onCloseKYC();
      this.LMS_GetAllUserAssetInfo();
      this.AssetsAttachment = [{}];
      this.DocModel = [];
      this.VehicleManufacture = null; this.SelectVehicleCategory = null;
      this.AddressOfProperty = null; this.TypeOfProperty = null;
      this.propertyForms == ''; this.propertyForm = '';
      this.resetForm();
      this.onSearchPDDDetail();

      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
      if (updateAssets != null && updateAssets != undefined) {
        this.LmsService.Update_AssetsCost({ Loan_Id: this.LoanId, AssetsCost: updateAssets.Asset_Cost, LTV: updateAssets.LTVAmount }).subscribe((response) => {
        });
      }
    });
  }

  resetForm() {
    this.saveBtn = true;
    this.updateBtn = false;
    this.isEdit = false;
    this.ID = 0;
    this.VehicleManufacture = '';
    this.SelectVehicleCategory = '';
    this.VehicleModel = '';
    this.Variant = '';
    this.ManufactureYear = '';
    this.VehicleRegistationNo = '';
    this.RegistationDate = '';
    this.RegistationExpiryDate = '';
    this.SelectFuelType = '';
    this.RoadTaxUpto = '';
    this.Color = '';
    this.FitnessUpto = '';
    this.PermitUpto = '';
    this.VehicleCost = '';
    this.Route = '';
    this.EngineNo = '';
    this.ChassisNo = '';
    this.KeyNo = '';
    this.DeliveryorderNo = '';
    this.DeliveryorderDate = '';
    this.EstimationAmount = '';
    this.InvoiceNo = '';
    this.InvoiceValue = '';
    this.InvoiveDate = '';
    this.InvoiveNo = '';
    this.DealerContactPerson = '';
    this.DealerContactNo = '';
    this.IssuedInFavourOf = '';
    this.Remark = '';
    this.Dealer = '';
    this.ValuationDoneBy = '';
    this.ValutionAmount = '';
    this.ValuationGrid = '';
    this.ValuationType = '';
    this.OtherValuationType = '';
    this.OtherValuationAmount = '';
    this.PropertyOwnerName = '';
    this.AddressOfProperty = '';
    this.Location = '';
    this.customerModel = [];
    this.TypeOfProperty = '';
    this.NatureOfProperty = '';
    this.OwnershipDocument = '';
    this.OwnershipType = '';
    this.UnitOfMeasurement = '';
    this.TotalArea = '';
    this.ConstructedArea = '';
    this.SearchReportDoneBy = '';
    this.TypeOfMortgage = '';
    this.MortgageSingedBy = '';
    this.latitude = '';
    this.longitude = '';
    this.newVehicle = true;
    this.usedVehicle = false;
    this.vehicle_type = 'New';
    this.commercialVehicle = 'Commercial';
    this.ValuationDocData = ''
    this.SearchDocData = ''
    this.SearchReportDoneByOther = '';
    this.ValuationDoneByOther = '';
    this.SearchReportDoneBy = '';
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
    this.Item.PurityCT = 12;
    this.ItemArr = [];
    this.dataAssetItemSource = '';
    this.assetModel = [];
    this.Addi_ProductCat = false;

  }

  filterTableDataAccoType() {
    if (this.SelectSubType == 'Pending') {
      if (this.SelectType == 'RC') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "VehicleRegistationNo", "RegistationDate", "RegistationExpiryDate", "RC_HPNEndorsment", "PDD", "Action"
        ];
      }
      if (this.SelectType == 'Invoice') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "InvoiceNo", "Invoice_HPNEndorsment", "PDD", "Action"
        ];
      }
      if (this.SelectType == 'Insurance') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "Insurance", "AssetInsuranceEndorsed", "PDD", "Action"
        ];
      }
    } else {
      if (this.SelectType == 'RC') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "VehicleRegistationNo", "RegistationDate", "RegistationExpiryDate", "RC_HPNEndorsment", "PDD", "ViewAsset"
        ];
      }
      if (this.SelectType == 'Invoice') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "InvoiceNo", "Invoice_HPNEndorsment", "PDD", "ViewAsset"
        ];
      }
      if (this.SelectType == 'Insurance') {
        this.displayedColumns = [
          "Id", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleModelName", "Insurance", "AssetInsuranceEndorsed", "PDD", "ViewAsset"
        ];
      }
    }
    //if (this.ProductType == "New-Non-Commercial" && this.SelectType == 'RC')  {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleRegistationNo", "RegistationExpiryDate", "InvoiceNo", "Insurance", "AssetInsuranceEndorsed", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "PDD","Action"
    //  ];
    //}

    //else if (this.ProductType == "Used-Non-Commercial" ) {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleRegistationNo", "RegistationExpiryDate", "Insurance", "AssetInsuranceEndorsed", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "PDD", "ViewInsurance", "Action"
    //  ];
    //}

    //else if (this.ProductType == "New-Commercial" ) {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo", "Application_LoanAmount", "VehicleRegistationNo", "RegistationExpiryDate", "InvoiceNo", "Insurance", "AssetInsuranceEndorsed", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "PDD", "ViewInsurance", "Action"
    //  ];
    //}

    //else if (this.ProductType == "Used-Commercial" ) {
    //  this.displayedColumns = [
    //    "Id", "Application_No", "LoanAcNo", "Loan_Date", "Customer", "Customer_PhoneNo",  "Application_LoanAmount", "VehicleRegistationNo", "RegistationExpiryDate","Insurance", "AssetInsuranceEndorsed", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "PDD", "ViewInsurance", "Action"
    //  ];
    //}

    this.DataSource.filter = this.ProductType
  }

  colDisplayDefine(rowData) {
    // emptyData=[]
    this.colDisplay = []
    this.updatedIndex = rowData.Id;
    this.updateInsurance = rowData.InsuranceId;

    console.log(rowData, "sdfsdfsdf");


    //if PDD

    if (rowData["Insurance"] == null || rowData["Insurance"] == '') this.colDisplay.push("Insurance")

    if (this.ProductType == 'New-Non-Commercial') {
      for (let key in rowData) {
        if (["VehicleRegistationNo", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "InvoiceNo", "RegistationExpiryDate"].includes(key) && !!!rowData[key])
          this.colDisplay.push(key)
      }
      return;

    }
    if (this.ProductType == 'Used-Non-Commercial') {
      for (let key in rowData) {
        if (["VehicleRegistationNo", "AssetInsuranceEndorsed", "RC_HPNEndorsment", "RegistationExpiryDate"].includes(key) && !!!rowData[key])
          this.colDisplay.push(key)
      }
      return;
    }
    if (this.ProductType == 'New-Commercial') {
      for (let key in rowData) {
        if (["VehicleRegistationNo", "InvoiceNo", "FitnessUpto", "Invoice_HPNEndorsment", "RC_HPNEndorsment", "RC_HPNEndorsment", "PermitUpto", "RegistationExpiryDate"].includes(key) && !!!rowData[key])
          this.colDisplay.push(key)
      }
      return;
    }
    if (this.ProductType == 'Used-Commercial') {
      for (let key in rowData) {
        if (["VehicleRegistationNo", "AssetInsuranceEndorsed", "RC_HPNEndorsment", "RegistationExpiryDate", "FitnessUpto", "PermitUpto"].includes(key) && !!!rowData[key])
          this.colDisplay.push(key)
      }
      return;
    }


  }

  showEditModalSheet(rowData) {
    this.UpdateType = this.SelectType;

    this.colDisplayDefine(rowData)
    this.ViewData(rowData)

    // $('#editPddDe').modal('show');
  }

  closePddEditModal() {
    $('#editPddDe').modal('hide');
    this.UpdatedPDDDetails = {}
  }

  onSaveEditedPDDDetail() {
    //remmove Undefined Keys
    //for (let x in this.UpdatedPDDDetails) {
    //  if (this.UpdatedPDDDetails[x] == undefined) {
    //    delete this.UpdatedPDDDetails[x];
    //  }

    //}
    //this.UpdatedPDDDetails['RegistationExpiryDate'] = moment(this.UpdatedPDDDetails['RegistationExpiryDate']).format("YYYY-MM-DD")
    //this.UpdatedPDDDetails['RegistrationIssueDate'] = moment(this.UpdatedPDDDetails['RegistrationIssueDate']).format("YYYY-MM-DD")
    this.UpdatedPDDDetails['RC_HPNEndorsment'] = this.UpdatedPDDDetails['RC_HPNEndorsment'] == true ? 1 : 0;
    this.UpdatedPDDDetails['Invoice_HPNEndorsment'] = this.UpdatedPDDDetails['Invoice_HPNEndorsment'] == true ? 1 : 0;
    this.UpdatedPDDDetails['AssetInsuranceEndorsed'] = this.UpdatedPDDDetails['AssetInsuranceEndorsed'] == true ? 'Yes' : 'No';
    this.UpdatedPDDDetails['Loan_Id'] = this.LoanId;
    this.UpdatedPDDDetails['LoginUserId'] = this.currentUser.userId;


    // console.log("Updated PDD Details",this.UpdatedPDDDetails);
    let JsonrequestModel = JSON.stringify({ "Vehicle": { ...this.UpdatedPDDDetails }, "UpdateType": this.UpdateType, "InsuranceId": this.updateInsurance })
    let finalRequestModel = { JSON: JsonrequestModel, Id: this.updatedIndex }
    console.log("finalRequestModel", finalRequestModel);
    this.LmsService.LMS_Update_PDD_Detail(finalRequestModel).subscribe((resp) => {
      // console.log("Response Got",resp);
      if (resp[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: resp[0]["MSG"], ...this.configSuccess });
        this.onSearchPDDDetail();
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: resp[0]["MSG"], ...this.configSuccess });
      }
    })
    this.closePddEditModal()
    this.UpdatedPDDDetails = {}


  }
  calculateDifferenceDate(date: string) {
    if (new Date(date.split("-").reverse().join("-")) >= new Date(this.ToDate)) {
      return 0
    }
    return -1;
  }
  goToDetail(data: any) {
    console.log(data.Int_Id);
    this.LmsService.GetInsuranceDetailsById({ Int_Id: data.InsuranceId }).subscribe((res: any) => {
      this.loading = false;

      //this.get_InsuranceInfo(this.LoanNo);
      this.showSpinner = false;
      this.InsuranceInfo = res[0];
      console.log("edit", this.InsuranceInfo);
      //this.LoanNo = this.InsuranceInfo["LoanAcNo"];
      this.Type = this.InsuranceInfo["Type"];
      if (this.Type == "Assets") {
        this.CustomerDetailShow = false;
        this.AssetsDetailShow = true;
      }
      if (this.Type == "Customer") {
        this.CustomerDetailShow = true;
        this.AssetsDetailShow = false;
      }

      // this.IntId = data.Int_Id;
      // this.Name = this.InsuranceInfo["Name"];
      // this.AssetInsuranceCompany = this.InsuranceInfo["AssetInsuranceCompany"];
      // this.AssetInsuranceCompanyName = this.InsuranceInfo["AssetInsuranceCompanyName"];
      // this.AssetInsuranceCompanyBranch = this.InsuranceInfo["AssetInsuranceCompanyBranch"];
      // this.AssetInsuranceCompanyBranchName = this.InsuranceInfo["AssetInsuranceCompanyBranchName"];
      // this.AssetAgentName = this.InsuranceInfo["AssetAgentName"];
      // this.AssetInsuranceDate = this.InsuranceInfo["AssetInsuranceDate"];
      // this.AssetRenewalDate = this.InsuranceInfo["AssetRenewalDate"];
      // this.AssetCoverNoteNo = this.InsuranceInfo["AssetCoverNoteNo"];
      // this.AssetPolicyType = this.InsuranceInfo["AssetPolicyType"];
      // this.AssetPolicyTypeName = this.InsuranceInfo["AssetPolicyTypeName"];
      // this.AssetIDVAmount = this.InsuranceInfo["AssetIdvAmount"];
      // this.AssetPremiumAmount = this.InsuranceInfo["AssetPremiumAmount"];
      // this.AssetNomineeName = this.InsuranceInfo["AssetNomineeName"];
      // this.AssetNomineeRelationwithHirer = this.InsuranceInfo["AssetNomineeRelation"];
      // this.AssetNomineeRelationwithHirerName = this.InsuranceInfo["AssetNomineeRelationName"];
      // this.AssetPremiumAmountLoan = this.InsuranceInfo["AssetPremiumAmountDeducted"];

      // this.CustomerInsHirerLifeInsCmny = this.InsuranceInfo["CustomerInsHirerLifeInsurance"];
      // this.CustomerInsHirerLifeInsCmnyName = this.InsuranceInfo["CustomerInsHirerLifeInsuranceName"];
      // this.CustomerInsAssetInsuranceCompanyBranch = this.InsuranceInfo["CustomerInsAssetInsuranceCompany"];
      // this.CustomerInsAssetInsuranceCompanyBranchName = this.InsuranceInfo["CustomerInsAssetInsuranceCompanyName"];
      // this.CustomerInsAgentName = this.InsuranceInfo["CustomerInsAgentName"];
      // this.CustomerInsInsuranceDate = this.InsuranceInfo["CustomerInsInsuranceDate"];
      // this.CustomerInsExpireDate = this.InsuranceInfo["CustomerInsExpireDate"];
      // this.CustomerInsPolicyNo = this.InsuranceInfo["CustomerInsPolicyNo"];
      // this.CustomerInsPolicyType = this.InsuranceInfo["CustomerInsPolicyType"];
      // this.CustomerInsPolicyTypeName = this.InsuranceInfo["CustomerInsPolicyTypeName"];
      // this.CustomerInsSumAssured = this.InsuranceInfo["CustomerInsSumAssured"];
      // this.CustomerInsPremiumAmount = this.InsuranceInfo["CustomerInsPremiumAmount"];
      // this.CustomerInsPremiumNextDueDate = this.InsuranceInfo["CustomerInsPremiumNextDueDate"];
      // this.CustomerInsNomineeName = this.InsuranceInfo["CustomerInsNomineeName"];
      // this.CustomerInsPremiumAmountDeducted = this.InsuranceInfo["CustomerInsPremiumAmountDeducted"];
      // this.CustomerInsNomineeRelationHirer = this.InsuranceInfo["CustomerInsNomineeRelationHirer"];
      // this.AssetInsuranceEndorsed = this.InsuranceInfo["AssetInsuranceEndorsed"];
      $('#InsuranceDetailModel').modal('show');
      $("#InsuranceDetailModel").css("z-index", "1050");
    })

  }
  onCloseInsuranceDetail() {
    $('#InsuranceDetailModel').modal('hide');
  }
  checkDuplication_Vehicle(Type: any) {
    //if (this.Info.EngineNo != '' && this.Info.EngineNo != null && this.Info.EngineNo != undefined) {
    //  if (this.Info.EngineNo != this.UpdatedPDDDetails['EngineNo']) {
    //    this.UpdatedPDDDetails['EngineNo']=''
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: "EngineNo not match existin data!!", ...this.configSuccess });

    //  }
    //}
    //if (this.Info.ChassisNo != '' && this.Info.ChassisNo != null && this.Info.ChassisNo != undefined) {
    //  if (this.Info.ChassisNo != this.UpdatedPDDDetails['ChassisNo']) {
    //   // this.UpdatedPDDDetails['ChassisNo']=''
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: "ChassisNo not match existin data!!", ...this.configSuccess });

    //  }
    //}

  }
  ChangeUpdateType() {
    this.UpdatedPDDDetails = {};
    if (this.UpdateType == 'RC') {
      this.UpdatedPDDDetails['VehicleRegistationNo'] = this.Info.VehicleRegistationNo == null ? '' : this.Info.VehicleRegistationNo.toUpperCase();
      this.UpdatedPDDDetails['RegistrationIssueDate'] = this.datepipe.transform(this.Info.RegistationDate, 'yyyy-MM-dd');
      this.UpdatedPDDDetails['RegistrationExpiryDate'] = this.datepipe.transform(this.Info.RegistationExpiryDate, 'yyyy-MM-dd');
      this.UpdatedPDDDetails['RC_HPNEndorsment'] = this.Info.RC_HPNEndorsment;
      this.RCVerification();
    } if (this.UpdateType == 'Invoice') {
      this.UpdatedPDDDetails['InvoiceDate'] = this.datepipe.transform(this.Info.InvoiceDate, 'yyyy-MM-dd');;
      this.UpdatedPDDDetails['InvoiceNo'] = this.Info.InvoiceNo;

      this.UpdatedPDDDetails['Invoice_HPNEndorsment'] = this.Info.Invoice_HPNEndorsment;
    
    }
    if (this.UpdateType == 'Insurance') {
      this.getInsuranceCompany();
      this.getAssetsPolicyType();
    }

  }
  getInsuranceCompany() {
    this.LmsService.GetInsuranceCompanyList().subscribe((result) => {
      this.Insurance_Company_List = JSON.parse(JSON.stringify(result));
      console.log(result);
    });
  }
  changeAssetRenewalDate(event: any) {
    //this.AssetRenewalDate = this.AssetInsuranceDate
    var year = event._i.year;
    var month = event._i.month;
    var day = event._i.date;
    var c = new Date(year + 1, month, day - 1);
    console.log("new date", c);
    this.UpdatedPDDDetails['AssetRenewalDate'] = c;
  }
  getAssetsPolicyType() {
    this.LmsService.LMS_Commaon_Master({
      Commands: 'Select', Type: 'ASSETS POLICY TYPE'
    }).subscribe((res: any) => {
      console.log(res);
      this.PolicyTypeAssetsOption = res;
    });

  }
  changeRenewalDate(event: any) {
    if (this.UpdatedPDDDetails['AssetRenewalDate'] < this.UpdatedPDDDetails['AssetInsuranceDate']) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Asset Renewal Date greater than Asset Insurance Date.",
        ...this.configSuccess,
      });
      this.UpdatedPDDDetails['AssetRenewalDate'] = '';
    }
  }
  filterSelectType() {
    this.DataSource = null;
    this.displayedColumns = [];
  }
  ViewDetail(row) {
    this.assetsDetail = row;
    var arr = this.SelectProduct.split("and");
    this.ProductCatId = arr[0];
    this.Product_Category = arr[1];

    var CArr = arr[1].split(" ");
    this.Collateral = CArr[0];

    this.Collateral_Type = row.Collateral_Type;
    this.LmsService.ViewAssetInfo({ SearchBy: this.Collateral, Id: row.Id }).subscribe((response: any) => {
      //console.log("response :", response);
      this.Info = response.Item1[0];
    });
    if (this.SelectType = 'Insurance') {
      this.LmsService.GetInsuranceDetailsById({ Int_Id: row.InsuranceId }).subscribe((res: any) => {
        this.loading = false;

        //this.get_InsuranceInfo(this.LoanNo);
        this.showSpinner = false;
        this.InsuranceInfo = res[0];
        this.Type = this.InsuranceInfo["Type"];

      })
    }
    $('#View').modal('show');
    $("#View").css("z-index", "1050");

  }
  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    const charUpper = char.toUpperCase();

    this.VehicleRegistationNo += charUpper;

    event.preventDefault();
  }


  RCVerification() {
    if (this.UpdatedPDDDetails['VehicleRegistationNo']) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "RC" })
        .subscribe((res: any) => {
          if (res.length == 0) {

            this.RCVerified_Button = false;
          } else {
            this.Get_Alredy_Verified_KYC();
            this.RCVerified_Button = true;
            this.RCKey = res[0].Key;
            this.RCSMS_URL = res[0].SMS_URL;
          }

        });
    }
  }
  Get_Alredy_Verified_KYC() {
    this._MasterService
      .Get_Alredy_Verified_KYC({
        DocumentId: 17,
        KYC_DocNumber: this.UpdatedPDDDetails['VehicleRegistationNo'] 
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.APIResponse = {
            KYC_DocId: 17,
            DoucumentNumber: JSON.parse(res[0].APIResponse).data.rc_number,
            IsVerified: JSON.parse(res[0].APIResponse).success,
            rc_number: JSON.parse(res[0].APIResponse).data.rc_number,
            fit_up_to: JSON.parse(res[0].APIResponse).data.fit_up_to,
            registration_date: JSON.parse(res[0].APIResponse).data.registration_date,
            owner_name: JSON.parse(res[0].APIResponse).data.owner_name,
            father_name: JSON.parse(res[0].APIResponse).data.father_name,
            present_address: JSON.parse(res[0].APIResponse).data.present_address,
            permanent_address: JSON.parse(res[0].APIResponse).data.permanent_address,
            mobile_number: JSON.parse(res[0].APIResponse).data.mobile_number,
            vehicle_category: JSON.parse(res[0].APIResponse).data.vehicle_category,
            vehicle_chasi_number: JSON.parse(res[0].APIResponse).data.vehicle_chasi_number,
            vehicle_engine_number: JSON.parse(res[0].APIResponse).data.vehicle_engine_number,
            maker_description: JSON.parse(res[0].APIResponse).data.maker_description,
            maker_model: JSON.parse(res[0].APIResponse).data.maker_model,
            body_type: JSON.parse(res[0].APIResponse).data.body_type,
            fuel_type: JSON.parse(res[0].APIResponse).data.fuel_type,
            color: JSON.parse(res[0].APIResponse).data.color,
            norms_type: JSON.parse(res[0].APIResponse).data.norms_type,
            financer: JSON.parse(res[0].APIResponse).data.financer,
            financed: JSON.parse(res[0].APIResponse).data.financed,
            insurance_company: JSON.parse(res[0].APIResponse).data.insurance_company,
            insurance_policy_number: JSON.parse(res[0].APIResponse).data.insurance_policy_number,
            insurance_upto: JSON.parse(res[0].APIResponse).data.insurance_upto,
            manufacturing_date: JSON.parse(res[0].APIResponse).data.manufacturing_date,
            manufacturing_date_formatted: JSON.parse(res[0].APIResponse).data.manufacturing_date_formatted,
            registered_at: JSON.parse(res[0].APIResponse).data.registered_at,
            latest_by: JSON.parse(res[0].APIResponse).data.latest_by,
            less_info: JSON.parse(res[0].APIResponse).data.less_info,
            tax_upto: JSON.parse(res[0].APIResponse).data.tax_upto,
            tax_paid_upto: JSON.parse(res[0].APIResponse).data.tax_paid_upto,
            cubic_capacity: JSON.parse(res[0].APIResponse).data.cubic_capacity,
            vehicle_gross_weight: JSON.parse(res[0].APIResponse).data.vehicle_gross_weight,
            no_cylinders: JSON.parse(res[0].APIResponse).data.no_cylinders,
            seat_capacity: JSON.parse(res[0].APIResponse).data.seat_capacity,
            sleeper_capacity: JSON.parse(res[0].APIResponse).data.sleeper_capacity,
            standing_capacity: JSON.parse(res[0].APIResponse).data.standing_capacity,
            wheelbase: JSON.parse(res[0].APIResponse).data.wheelbase,
            unladen_weight: JSON.parse(res[0].APIResponse).data.unladen_weight,
            vehicle_category_description: JSON.parse(res[0].APIResponse).data.vehicle_category_description,
            pucc_number: JSON.parse(res[0].APIResponse).data.pucc_number,
            pucc_upto: JSON.parse(res[0].APIResponse).data.pucc_upto,
            permit_number: JSON.parse(res[0].APIResponse).data.permit_number,
            permit_issue_date: JSON.parse(res[0].APIResponse).data.permit_issue_date,
            permit_valid_from: JSON.parse(res[0].APIResponse).data.permit_valid_from,
            permit_valid_upto: JSON.parse(res[0].APIResponse).data.permit_valid_upto,
            permit_type: JSON.parse(res[0].APIResponse).data.permit_type,
            national_permit_number: JSON.parse(res[0].APIResponse).data.national_permit_number,
            national_permit_upto: JSON.parse(res[0].APIResponse).data.national_permit_upto,
            national_permit_issued_by: JSON.parse(res[0].APIResponse).data.national_permit_issued_by,
            non_use_status: JSON.parse(res[0].APIResponse).data.non_use_status,
            non_use_from: JSON.parse(res[0].APIResponse).data.non_use_from,
            non_use_to: JSON.parse(res[0].APIResponse).data.non_use_to,
            blacklist_status: JSON.parse(res[0].APIResponse).data.blacklist_status,
            noc_details: JSON.parse(res[0].APIResponse).data.noc_details,
            owner_number: JSON.parse(res[0].APIResponse).data.owner_number,
            rc_status: JSON.parse(res[0].APIResponse).data.rc_status,
            masked_name: JSON.parse(res[0].APIResponse).data.masked_name,
            challan_details: JSON.parse(res[0].APIResponse).data.challan_details,
            variant: JSON.parse(res[0].APIResponse).data.variant,
            rto_code: JSON.parse(res[0].APIResponse).data.rto_code,

            message_code: JSON.parse(res[0].APIResponse).message_code,
          }
          this.RCLastVerfiedDate = res[0].LastVerfiedDate;
          this.RCKYC_IsVerified = 1;
        } else {
          this.RCLastVerfiedDate = "";
          this.RCKYC_IsVerified = 0;
        }


      })
  }
  onVerification(ReVerify) {
    if (ReVerify == 1) {
      this.dialog
        .openConfirmDialog(
          `Are you sure you want to Re-Verified this RC ?`
        )
        .afterClosed()
        .subscribe((dialogResult) => {
          if (dialogResult == false) {
            return;
          } else {
            this.KYCVerifyByAPI();
          }
        });
    } else {
      this.KYCVerifyByAPI();
    }
  }

  KYCVerifyByAPI() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.UpdatedPDDDetails['VehicleRegistationNo'],
      KYC_DocId: 17,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
    };
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.UpdatedPDDDetails['VehicleRegistationNo'],

          DocumentId: 17,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        this.RCKYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 17,
          //DoucumentNumber: this.VehicleRegistationNo,
          //Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .full_name,
          //IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .success,
          //category: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .category,
          //message_code: JSON.parse(data.KYC_Verification.APIResponse)
          //  .message_code,



          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data.rc_number,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
          rc_number: JSON.parse(data.KYC_Verification.APIResponse).data.rc_number,
          fit_up_to: JSON.parse(data.KYC_Verification.APIResponse).data.fit_up_to,
          registration_date: JSON.parse(data.KYC_Verification.APIResponse).data.registration_date,
          owner_name: JSON.parse(data.KYC_Verification.APIResponse).data.owner_name,
          father_name: JSON.parse(data.KYC_Verification.APIResponse).data.father_name,
          present_address: JSON.parse(data.KYC_Verification.APIResponse).data.present_address,
          permanent_address: JSON.parse(data.KYC_Verification.APIResponse).data.permanent_address,
          mobile_number: JSON.parse(data.KYC_Verification.APIResponse).data.mobile_number,
          vehicle_category: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_category,
          vehicle_chasi_number: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_chasi_number,
          vehicle_engine_number: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_engine_number,
          maker_description: JSON.parse(data.KYC_Verification.APIResponse).data.maker_description,
          maker_model: JSON.parse(data.KYC_Verification.APIResponse).data.maker_model,
          body_type: JSON.parse(data.KYC_Verification.APIResponse).data.body_type,
          fuel_type: JSON.parse(data.KYC_Verification.APIResponse).data.fuel_type,
          color: JSON.parse(data.KYC_Verification.APIResponse).data.color,
          norms_type: JSON.parse(data.KYC_Verification.APIResponse).data.norms_type,
          financer: JSON.parse(data.KYC_Verification.APIResponse).data.financer,
          financed: JSON.parse(data.KYC_Verification.APIResponse).data.financed,
          insurance_company: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_company,
          insurance_policy_number: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_policy_number,
          insurance_upto: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_upto,
          manufacturing_date: JSON.parse(data.KYC_Verification.APIResponse).data.manufacturing_date,
          manufacturing_date_formatted: JSON.parse(data.KYC_Verification.APIResponse).data.manufacturing_date_formatted,
          registered_at: JSON.parse(data.KYC_Verification.APIResponse).data.registered_at,
          latest_by: JSON.parse(data.KYC_Verification.APIResponse).data.latest_by,
          less_info: JSON.parse(data.KYC_Verification.APIResponse).data.less_info,
          tax_upto: JSON.parse(data.KYC_Verification.APIResponse).data.tax_upto,
          tax_paid_upto: JSON.parse(data.KYC_Verification.APIResponse).data.tax_paid_upto,
          cubic_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.cubic_capacity,
          vehicle_gross_weight: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_gross_weight,
          no_cylinders: JSON.parse(data.KYC_Verification.APIResponse).data.no_cylinders,
          seat_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.seat_capacity,
          sleeper_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.sleeper_capacity,
          standing_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.standing_capacity,
          wheelbase: JSON.parse(data.KYC_Verification.APIResponse).data.wheelbase,
          unladen_weight: JSON.parse(data.KYC_Verification.APIResponse).data.unladen_weight,
          vehicle_category_description: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_category_description,
          pucc_number: JSON.parse(data.KYC_Verification.APIResponse).data.pucc_number,
          pucc_upto: JSON.parse(data.KYC_Verification.APIResponse).data.pucc_upto,
          permit_number: JSON.parse(data.KYC_Verification.APIResponse).data.permit_number,
          permit_issue_date: JSON.parse(data.KYC_Verification.APIResponse).data.permit_issue_date,
          permit_valid_from: JSON.parse(data.KYC_Verification.APIResponse).data.permit_valid_from,
          permit_valid_upto: JSON.parse(data.KYC_Verification.APIResponse).data.permit_valid_upto,
          permit_type: JSON.parse(data.KYC_Verification.APIResponse).data.permit_type,
          national_permit_number: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_number,
          national_permit_upto: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_upto,
          national_permit_issued_by: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_issued_by,
          non_use_status: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_status,
          non_use_from: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_from,
          non_use_to: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_to,
          blacklist_status: JSON.parse(data.KYC_Verification.APIResponse).data.blacklist_status,
          noc_details: JSON.parse(data.KYC_Verification.APIResponse).data.noc_details,
          owner_number: JSON.parse(data.KYC_Verification.APIResponse).data.owner_number,
          rc_status: JSON.parse(data.KYC_Verification.APIResponse).data.rc_status,
          masked_name: JSON.parse(data.KYC_Verification.APIResponse).data.masked_name,
          challan_details: JSON.parse(data.KYC_Verification.APIResponse).data.challan_details,
          variant: JSON.parse(data.KYC_Verification.APIResponse).data.variant,
          rto_code: JSON.parse(data.KYC_Verification.APIResponse).data.rto_code,

          message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code,
        };
      } else {
        this.RCKYC_IsVerified = 0;
      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (
              JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
            ) {
              this.RCKYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: JSON.parse(data.KYC_Verification.APIResponse).message,
                ...this.configSuccess,
              });
            }
          }
        });
    });

  }
}
