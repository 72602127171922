<div>
  <form #f="ngForm" novalidate>
    <!-- <div class="row mt-4"><div class="col-md-12"><h4> Vehicle Model Master </h4></div></div> -->
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span class="required-lable"> Vehicle Model Name </span>
        <input
          type="text"
          name="VehicleModelName"
          id="VehicleModelName"
          [(ngModel)]="VehicleModelName"
          placeholder="Vehicle Model Name"
          class="form-control input-text-css"
          #refVehicleModelName="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refVehicleModelName.invalid,
            'alert-warning':
              refVehicleModelName.invalid &&
              (refVehicleModelName.dirty ||
                refVehicleModelName.touched ||
                refVehicleModelName.untouched)
          }"
        />
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Manufacture Name </span>
        <select
          name="SelectManufacture"
          id="SelectManufacture"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectManufacture"
          class="form-control input-text-css"
          #refSelectManufacture="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refSelectManufacture.invalid,
            'alert-warning':
              refSelectManufacture.invalid &&
              (refSelectManufacture.dirty ||
                refSelectManufacture.touched ||
                refSelectManufacture.untouched)
          }"
        >
          <option value="">Select Manufacture Name</option>
          <option *ngFor="let data of ManufactureData" [value]="data.Id">
            {{ data.Manufacture }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable"> Vehicle Category </span>
        <select
          name="SelectVehicleCategory"
          id="SelectVehicleCategory"
          [(ngModel)]="SelectVehicleCategory"
          class="form-control input-text-css"
          #refSelectVehicleCategory="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refSelectVehicleCategory.invalid,
            'alert-warning':
              refSelectVehicleCategory.invalid &&
              (refSelectVehicleCategory.dirty ||
                refSelectVehicleCategory.touched ||
                refSelectVehicleCategory.untouched)
          }"
        >
          <option value="">Select Vehicle Category</option>
          <option *ngFor="let data of vehicleCategory" [value]="data.Id">
            {{ data.Vehicle_Category }}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSaveVehicleModel()"
          *ngIf="saveBtn"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
        <button
          type="button"
          (click)="UpdateVehicleModel()"
          *ngIf="updateBtn"
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
      </div>
      <div class="col-md-2 p-1 mt-2 ml-4">
       
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <!-- <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12"> -->
  <div class="table-responsive mt-2">
    <mat-table
      [dataSource]="dataCustomerSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="height: 420px; max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="Number">
        <mat-header-cell
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px"
          >{{ i + 1 }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="Vehicle Model Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Vehicle Model Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Vehicle_Model_Name
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Vehicle Category">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Vehicle Category</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Vehicle_Category
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Manufacture Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >Manufacture Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Manufacture
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            aria-label="true"
            (click)="editVehicleModel(row)"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="removeVehicleModel(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        class="sticky-footer"
        *matHeaderRowDef="displayedCustomerColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedCustomerColumns"
      ></mat-row>
    </mat-table>
  </div>
  <!-- </div>
  </div> -->
</div>
