import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { LosService } from "src/app/_LOS/services/los.service";
import { DialogService } from "src/app/Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-lms-death-theft-claim",
  templateUrl: "./lms-death-theft-claim.component.html",
  styleUrls: ["./lms-death-theft-claim.component.scss"],
})
export class LmsDeathTheftClaimComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  Type: any = "0";
  SelectType: any = "";

  Customer: any = {};
  CustomerList: any = [];
  displayedCustomerColumns: string[] = [];
  dataSourceCustomer: any;

  claimType: any = "";
  Insurance: any = "";
  theftDeathDate: any;
  firDate: any;
  firNumber: any;
  policeStation: any;
  claimStatus: any = "Pending";
  claimStatusModel: any;
  stopInstalment: boolean = false;
  FirCopyDoc: any;
  editCliamForm: any = "";

  stopInstalmentNumeric: number = 0;

  @ViewChild("sortCustomerList", { static: true }) sortCustomer: MatSort;
  @ViewChild("paginatorCustomerList", { static: true })
  paginatorCustomer: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loanIdRequest: any;
  filteredInsuranceDetails: any[] = [];

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  applicatioNumber: any;

  deathCertificateModel: any = {
    DeathCertificateDoc: null,
    DocData: null,
  };

  firReportModel: any = {
    FIRReportDoc: null,
    DocData: null,
  };
  Id: any;
  loanId: any;
  InsuranceId: any;
  dataSource: any;
  displayedColumns: string[] = [];
  JsonData: any[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedInsuranceId: any;
  IdForRequst: any;

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private _LosService: LosService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Death/Theft Claim");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    console.log("this.currentUser.userId", this.currentUser.userId);
    this.displayedCustomerColumns = [
      "LoanId",
      "LoanNo",
      "ApplicationNo",
      "Product",
      "Branch",
      "CustomertName",
      "FatherName",
      "PhoneNo",
      "Loan_Date",
      "EditAction",
    ];

    this.getDeathTheftList();
  }

  convertToDate(dateString: string): Date | null {
    if (dateString) {
      // Parse date from string
      const [month, day, year] = dateString.split(" ")[0].split("/");
      return new Date(+year, +month - 1, +day); // Month is zero-based
    }
    return null;
  }

  newDeathTheftClaim() {
    console.log("Claim");
    this.SelectType = "";
    this.Customer.CloseCase = "";
    this.dataSourceCustomer = null;
    $("#SearchLoanPreCloserModel").modal("show");
    $("#SearchLoanPreCloserModel").css("z-index", "1050");
  }

  onCloseSearchLoanPreCloser() {
    this.CustomerList = [];
    this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
    this.dataSourceCustomer.sort = this.sortCustomer;
    this.dataSourceCustomer.paginator = this.paginatorCustomer;
    this.Type = "0";
    this.Customer = {};
    $("#SearchLoanPreCloserModel").modal("hide");
  }

  onSearchClaimModel() {}

  onSearchCustomerDetail() {
    this.showSpinner = true;

    let loanNO = this.Customer.LoanNo
      ? this.Customer.LoanNo == null ||
        this.Customer.LoanNo == "" ||
        this.Customer.LoanNo == undefined
      : true;
    let ApplicationNo = this.Customer.ApplicationNo
      ? this.Customer.ApplicationNo == null ||
        this.Customer.ApplicationNo == "" ||
        this.Customer.ApplicationNo == undefined
      : true;
    let CustomerName = this.Customer.CustomerName
      ? this.Customer.CustomerName == null ||
        this.Customer.CustomerName == "" ||
        this.Customer.CustomerName == undefined
      : true;
    let CustomerPhone = this.Customer.CustomerPhone
      ? this.Customer.CustomerPhone == null ||
        this.Customer.CustomerPhone == "" ||
        this.Customer.CustomerPhone == undefined
      : true;
    let PANCard = this.Customer.PANCard
      ? this.Customer.PANCard == null ||
        this.Customer.PANCard == "" ||
        this.Customer.PANCard == undefined
      : true;
    let Passport = this.Customer.Passport
      ? this.Customer.Passport == null ||
        this.Customer.Passport == "" ||
        this.Customer.Passport == undefined
      : true;
    let VoterID = this.Customer.VoterID
      ? this.Customer.VoterID == null ||
        this.Customer.VoterID == "" ||
        this.Customer.VoterID == undefined
      : true;
    let DrivingLien = this.Customer.DL
      ? this.Customer.DL == null ||
        this.Customer.DL == "" ||
        this.Customer.DL == undefined
      : true;
    let Aadhaar = this.Customer.Aadhaar
      ? this.Customer.Aadhaar == null ||
        this.Customer.Aadhaar == "" ||
        this.Customer.Aadhaar == undefined
      : true;

    let VehicleNo = this.Customer.VehicleNo
      ? this.Customer.VehicleNo == null ||
        this.Customer.VehicleNo == "" ||
        this.Customer.VehicleNo == undefined
      : true;
    let LoanDateFrom = this.Customer.LoanDateFrom
      ? this.Customer.LoanDateFrom == null ||
        this.Customer.LoanDateFrom == "" ||
        this.Customer.LoanDateFrom == undefined
      : true;
    let LoanDateTo = this.Customer.LoanDateTo
      ? this.Customer.LoanDateTo == null ||
        this.Customer.LoanDateTo == "" ||
        this.Customer.LoanDateTo == undefined
      : true;
    let CloseCase = this.Customer.CloseCase
      ? this.Customer.CloseCase == null ||
        this.Customer.CloseCase == "" ||
        this.Customer.CloseCase == undefined
      : true;
    let BranchId = this.Customer.BranchId
      ? this.Customer.BranchId == null ||
        this.Customer.BranchId == "" ||
        this.Customer.BranchId == undefined
      : true;
    let EngineNo = this.Customer.EngineNo
      ? this.Customer.EngineNo == null ||
        this.Customer.EngineNo == "" ||
        this.Customer.EngineNo == undefined
      : true;
    let ChassisNo = this.Customer.ChassisNo
      ? this.Customer.ChassisNo == null ||
        this.Customer.ChassisNo == "" ||
        this.Customer.ChassisNo == undefined
      : true;
    let Area = this.Customer.Area
      ? this.Customer.Area == null ||
        this.Customer.Area == "" ||
        this.Customer.Area == undefined
      : true;
    let SubArea = this.Customer.SubArea
      ? this.Customer.SubArea == null ||
        this.Customer.SubArea == "" ||
        this.Customer.SubArea == undefined
      : true;
    let SalesEx = this.Customer.SalesEx
      ? this.Customer.SalesEx == null ||
        this.Customer.SalesEx == "" ||
        this.Customer.SalesEx == undefined
      : true;
    let CollectionEx = this.Customer.CollectionEx
      ? this.Customer.CollectionEx == null ||
        this.Customer.CollectionEx == "" ||
        this.Customer.CollectionEx == undefined
      : true;
    let LoanAccountingCatId = this.Customer.LoanAccountingCatId
      ? this.Customer.LoanAccountingCatId == null ||
        this.Customer.LoanAccountingCatId == "" ||
        this.Customer.LoanAccountingCatId == undefined
      : true;

    if (
      loanNO &&
      ApplicationNo &&
      CustomerName &&
      CustomerPhone &&
      PANCard &&
      Passport &&
      VoterID &&
      DrivingLien &&
      Aadhaar &&
      VehicleNo &&
      LoanDateFrom &&
      LoanDateTo &&
      CloseCase &&
      BranchId &&
      EngineNo &&
      ChassisNo &&
      Area &&
      SubArea &&
      SalesEx &&
      CollectionEx &&
      LoanAccountingCatId
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter atleast one fields.",
        ...this.configSuccess,
      });
      this.CustomerList = [];
      this.dataSourceCustomer = null;
      /*this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
      this.dataSourceCustomer.sort = this.sortCustomer;
      this.dataSourceCustomer.paginator = this.paginatorCustomer;*/
      this.showSpinner = false;
      return false;
    }
    console.log("this.Customer", this.Customer);
    this.Customer.LoginUserId = this.currentUser.userId;
    this.lmsService
      .LMS_Get_Search_Loans_JSON({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          if (res.length == 1) {
            this.goToDetail(res[0]);
          } else {
            this.CustomerList = JSON.parse(JSON.stringify(res));
            if (this.Type != "0") {
              var type;
              if (this.Type == "1") type = "LOS";
              else type = "LMS";
              this.CustomerList = this.CustomerList.filter(
                (item) => item.Type == type
              );
            }
            this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
            this.dataSourceCustomer.sort = this.sortCustomer;
            this.dataSourceCustomer.paginator = this.paginatorCustomer;
            this.showSpinner = false;
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  onCloseClaimForm() {
    $("#claimFormModel").modal("hide");
  }
  goToDetail(row) {
    this.editCliamForm = "Save";

    this.claimType = "";
    this.Insurance = "";
    this.theftDeathDate = "";
    this.firDate = "";
    this.firNumber = "";
    this.policeStation = "";
    this.claimStatus = "";
    this.deathCertificateModel.DeathCertificateDoc = "";
    this.firReportModel.FIRReportDoc = "";

    console.log("Row Data", row);
    this.loanIdRequest = row.LoanId;
    this.applicatioNumber = row.ApplicationNo;
    this.Id = row.CloserId;
    this.loanId = row.LoanId;
    console.log("Loan id for request", this.loanIdRequest);
    this.onCloseSearchLoanPreCloser();

    this.getInsuranceDetails();

    $("#claimFormModel").modal("show");
    $("#claimFormModel").css("z-index", "1050");
  }

  getInsuranceDetails() {
    this.lmsService
      .GetInsuranceDetails({ LoanId: this.loanIdRequest })
      .subscribe((res: any) => {
        console.log("result of insurance api call", res);
        this.InsuranceId = res.Int_Id;
        console.log("this.InsuranceId", this.InsuranceId);

        if (this.claimType === "Death") {
          this.filteredInsuranceDetails = res.filter(
            (item) => item.Type === "Customer"
          );
        } else {
          this.filteredInsuranceDetails = res;
        }

        console.log("filteredInsuranceDetails", this.filteredInsuranceDetails);

        if (this.filteredInsuranceDetails.length > 0) {
          this.selectedInsuranceId = this.filteredInsuranceDetails[0].Int_Id;
          console.log(
            "Automatically selected Insurance ID:",
            this.selectedInsuranceId
          );
        } else {
          console.warn("No insurance details available to select.");
        }
      });
  }

  fileChangeListenerDeathCertificate(files: any) {
    const reader = new FileReader();
    const timestamp = new Date().getTime(); // Unique prefix for the file name

    // Set file name with a timestamp prefix
    this.deathCertificateModel.DeathCertificateDoc = `${timestamp}_${files[0].name}`;

    // Read the file as Base64
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      // Store the Base64 content in `DocData`
      this.deathCertificateModel.DocData = reader.result
        ?.toString()
        .split(";base64,")
        .pop();
    };
    console.log(
      "this.deathCertificateModel.DocData",
      this.deathCertificateModel.DeathCertificateDoc
    );
  }

  removeDeathCertificate(input: any) {
    // Clear the input value
    input.value = "";
    // Reset the model properties
    this.deathCertificateModel.DeathCertificateDoc = null;
    delete this.deathCertificateModel.DocData;
  }

  fileChangeListenerFIRReport(files: any) {
    const reader = new FileReader();
    const timestamp = new Date().getTime(); // Unique prefix for the file name

    // Set file name with a timestamp prefix
    this.firReportModel.FIRReportDoc = `${timestamp}_${files[0].name}`;

    // Read the file as Base64
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      // Store the Base64 content in `DocData`
      this.firReportModel.DocData = reader.result
        ?.toString()
        .split(";base64,")
        .pop();
    };
  }

  removeFIRReport(input: any) {
    // Clear the input value
    input.value = "";
    // Reset the model properties
    this.firReportModel.FIRReportDoc = null;
    delete this.firReportModel.DocData;
  }

  onCheckboxChange(event: MatCheckboxChange): void {
    this.stopInstalmentNumeric = event.checked ? 1 : 0; // Set 1 for checked, 0 for unchecked
  }
  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  SaveDeathTheftDetails() {
    const requestPayload = {
      LoanId: this.loanId,
      Insurance_Id: this.selectedInsuranceId,
      ClaimType: this.claimType,
      Loss_Theft_Date: this.theftDeathDate,
      FIR_Date: this.firDate,
      FIR_No: this.firNumber,
      CRC_CreateBy: this.currentUser.userId,
      Police_Station: this.policeStation,
      Claim_Status: this.claimStatusModel,
      InstallmentStop: this.stopInstalmentNumeric,
      Death_Cerificate_Doc: this.deathCertificateModel.DeathCertificateDoc,
      FIR_Copy_Doc: this.firReportModel.FIRReportDoc,
    };

    let _finalData = {
      Claim: requestPayload,
    };

    this.lmsService
      .LMS_Save_InsuranceClaim_Theft_Loass({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          if (this.deathCertificateModel.DeathCertificateDoc) {
            this.uploadDoc({
              ApplicationNo: this.applicatioNumber,
              DocName: this.deathCertificateModel.DeathCertificateDoc,
              DocData: this.deathCertificateModel.DocData,
            });
          } else {
            this.uploadDoc({
              ApplicationNo: this.applicatioNumber,
              DocName: this.firReportModel.FIRReportDoc,
              DocData: this.firReportModel.DocData,
            });
          }

          this.getDeathTheftList();
          this.onCloseClaimForm();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDeathTheftList() {
    this.displayedColumns = [
      "Id",
      "LoanAcNo",
      "Customer_Name",
      "InsuranceName",
      "ClaimType",
      "Loss_Theft_Date",
      "FIR_Date",
      "FIR_No",
      "Police_Station",
      "Claim_Status",
      "Death_Cerificate_Doc",
      "FIR_Copy_Doc",
      "Action",
    ];

    this.showSpinner = true;
    this.lmsService
      .LMS_Get_InsuranceClaim_Theft_Loass({
        LoginUserId: this.currentUser.userId,
        Claim_Status: this.claimStatus,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
        this.showSpinner = false;
      });
  }

  editDeathLossClaim(row) {
    console.log("Row Data", row);
    this.IdForRequst = row.Id;
    this.loanIdRequest = row.LoanId;
    this.getInsuranceDetails();

    this.editCliamForm = "Update";

    this.claimType = row.ClaimType;
    this.Insurance = row.Insurance_Id;
    this.firNumber = row.FIR_No;
    this.policeStation = row.Police_Station;
    this.claimStatusModel = row.Claim_Status;
    this.theftDeathDate = this.convertToDate(row.Loss_Theft_Date);
    this.firDate = this.convertToDate(row.FIR_Date);
    this.deathCertificateModel.DeathCertificateDoc = row.Death_Cerificate_Doc;
    this.firReportModel.FIRReportDoc = row.FIR_Copy_Doc;
    // Death_Cerificate_Doc: this.deathCertificateModel.DeathCertificateDoc,
    // FIR_Copy_Doc: this.firReportModel.FIRReportDoc,

    this.stopInstalment = row.InstallmentStop;

    $("#claimFormModel").modal("show");
    $("#claimFormModel").css("z-index", "1050");
  }

  updateDeathTheftLoss() {
    // console.log("update button clicked");

    const requestPayload = {
      Id: this.IdForRequst,
      LoanId: this.loanId,
      Insurance_Id: this.selectedInsuranceId,
      ClaimType: this.claimType,
      Loss_Theft_Date: this.theftDeathDate,
      FIR_Date: this.firDate,
      FIR_No: this.firNumber,
      CRC_CreateBy: this.currentUser.userId,
      Police_Station: this.policeStation,
      Claim_Status: this.claimStatusModel,
      InstallmentStop: this.stopInstalmentNumeric,
      Death_Cerificate_Doc: this.deathCertificateModel.DeathCertificateDoc,
      FIR_Copy_Doc: this.firReportModel.FIRReportDoc,
    };

    let _finalData = {
      Claim: requestPayload,
    };

    console.log("Save req obj", _finalData);

    this.lmsService
      .LMS_Save_InsuranceClaim_Theft_Loass({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          if (this.deathCertificateModel.DeathCertificateDoc) {
            this.uploadDoc({
              ApplicationNo: this.applicatioNumber,
              DocName: this.deathCertificateModel.DeathCertificateDoc,
              DocData: this.deathCertificateModel.DocData,
            });
          } else {
            this.uploadDoc({
              ApplicationNo: this.applicatioNumber,
              DocName: this.firReportModel.FIRReportDoc,
              DocData: this.firReportModel.DocData,
            });
          }

          this.getDeathTheftList();
          this.onCloseClaimForm();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  deleteDeathTheftLoss(row) {
    this.IdForRequst = row.Id;

    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == 1) {
          this.lmsService
            .LMS_Delete_InsuranceClaim_Theft_Loass({
              Id: this.IdForRequst,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.getDeathTheftList();
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "death_theft_table"
    ) as HTMLTableElement;
    const worksheetName = "DEATH/THEFT CLAIM EXPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
