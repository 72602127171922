import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-subcard-info',
  templateUrl: './loan-subcard-info.component.html',
  styleUrls: ['./loan-subcard-info.component.scss']
})
export class LoanSubcardInfoComponent implements OnInit {
  @Input() loandata: any
  constructor() { }

  ngOnInit(): void {
    console.log(this.loandata)
  }

}
