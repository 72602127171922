import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-crif-report",
  templateUrl: "./crif-report.component.html",
  styleUrls: ["./crif-report.component.scss"],
})
export class CrifReportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  SlabData: any[] = [];
  DataSource: any;
  JSONData: any[] = [];

  //Filter Parameter
  CRCModel: any = {
    NewCode: "",
    NewShortName: "",
    OldCode: "",
    OldShortName: "",
    CloseFromDate: "",
    ReportDate: "",
    SlabId: "",
  };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    "Id",
    "Customer_FirstName",
    "Customer_MiddleName",
    "Customer_LastName",
    "Customer_DOB",
    "Gender",
    "Income_Tax_Number",
    "Passport_Number",
    "Voter_Id_Number",
    "Driving_License_Number",
    "Ration_Card_Number",
    "Universal_Id_Number",
    "Additional_Id1_Number",
    "TelephoneNumber_1",
    "TelephoneNumber_1_Type",
    "TelephoneNumber_2",
    "TelephoneNumber_2_Type",
    "TelephoneNumber_3",
    "TelephoneNumber_3_Type",
    "Telephone_Extension",
    "Email_Id1",
    "Address1",
    "city1",
    "District1",
    "State_Code1",
    "Pincode1",
    "Address_Category1",
    "Residence_Code1",
    "Address2",
    "city2",
    "District2",
    "State_Code2",
    "Pincode2",
    "Address_Category2",
    "Residence_Code2",
    "New_Member_Code",
    "New_Member_Short_Name",
    "New_Account_Number",
    "New_Account_Type",
    "New_Ownerhip_Indicator",
    "Date_Opened",
    "Date_Of_Last_Payment",
    "Date_Closed",
    "DateReported",
    "High_Credit",
    "Current_Balance",
    "Amount_Overdue",
    "DPD_Days",
    "OldCode",
    "OldShort_Name",
    "Old_Account_Number",
    "Old_Account_Type",
    "Old_Ownerhip_Indicator",
    "Suit_Filed",
    "WriteOff_Settled_Status",
    "Assets_Classification",
    "value_Of_collateral",
    "Collateral_Type",
    "Credit_Limit",
    "Cash_Limit",
    "RateOf_Interest",
    "Repayment_Tenure",
    "EMI_Amount",
    "Writen_Of_Amount",
    "Writen_Of_Principle",
    "Settlement_Amount",
    "Payment_Frequency",
    "Actual_Payment_Amount",
    "Customer_Occupation",
    "Income",
    "Net_Gross_Income_Indicator",
    "Monthly_Annual_Income_Indicator",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private masterService: MasterService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Crif Consumer");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getSlabsList();
  }

  getSlabsList() {
    this.masterService
      .Get__Slab_dropdown({ Type: "NPA", LoginUserId: this.currentUser.userId })
      .subscribe((res) => {
        this.SlabData = JSON.parse(JSON.stringify(res));
      });
  }

  onSearchCRCReport() {
    this.showSpinner = true;
    this.reportsService
      .Report_Crif_Consumer(this.CRCModel)
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          res.forEach((x) => {
            x.RateOf_Interest = parseFloat(x.RateOf_Interest).toFixed(2);
            x.EMI_Amount = parseFloat(x.EMI_Amount).toFixed(2);
          });

          this.JSONData = res;
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.paginator = this.paginator;
          this.DataSource.sort = this.sort;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcel() {
    this.exportAsExcelFile(this.JSONData, "crif-consumer");
  }
  // Excel export in backend code end here

  public highlightRow(row) {
    var RowIndex = this.DataSource.data.indexOf(row, 0);
    this.selectedId = RowIndex;
  }
}
