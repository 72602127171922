<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keyup.enter)="onSearchNachDetail()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-3">
      <span>Loan No</span>
      <input
        type="text"
        [(ngModel)]="SearchModel.LoanAcNo"
        placeholder="Loan No"
        name="LoanAcNo"
        id="LoanAcNo"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Date From</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="LoanDateFrom"
          id="LoanDateFrom"
          class="form-control input-text-css"
          [(ngModel)]="SearchModel.LoanDateFrom"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3">
      <span>Date To</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="CaseDateTo"
          id="CaseDateTo"
          class="form-control input-text-css"
          [(ngModel)]="SearchModel.CaseDateTo"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-3">
      <span>Status</span>
      <select
        name="Status"
        id="Status"
        [(ngModel)]="SearchModel.Status"
        class="form-control input-text-css"
      >
        <option value="">Select Status</option>
        <option value="Aweted">Awaited</option>
        <option value="Failed">Failed</option>
        <option value="Approved">Approved</option>
      </select>
    </div>
    <div class="col-md-3">
      <span>Company Bank</span>
      <select
        name="CompanyBank"
        id="CompanyBank"
        #refCompanyBank="ngModel"
        [(ngModel)]="SearchModel.CompanyBank"
        class="form-control input-text-css"
      >
        <option value="">Select Present Bank In Company</option>
        <option *ngFor="let bank of BankList" [value]="bank.AccountId">
          {{ bank.Account_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <span>Company Branch</span>
      <select
        name="CompanyBranch"
        id="CompanyBranch"
        #refCompanyBranch="ngModel"
        [(ngModel)]="SearchModel.CompanyBranch"
        class="form-control input-text-css"
      >
        <option value="">Select Present Branch</option>
        <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
          {{ branch.Branch_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-6 text-right">
      <button
        type="button"
        (click)="onSearchNachDetail()"
        class="mt-3 font-size-12 button-btn"
      >
        Search
      </button>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!dataSourceLoan">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSourceLoan"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[displayedColumnsLoan.indexOf('Action')]"
        style="height: 300px; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 40px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 40px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No.</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a
              (click)="goToDetail(row)"
              aria-label="true"
              style="color: blue; cursor: pointer"
              >{{ row.LoanAcNo }}</a
            >

            <!-- {{ row.LoanAcNo }} -->
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_date">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Loan Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CustomerName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Bankname">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Bank</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Bankname }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AccType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >A/c Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AccType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Ac">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >A/C No.</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Ac }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PresentBankInCompanyName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Present Bank in Company Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.PresentBankInCompanyName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PresentOnCompanyBank">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Present On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.PresentOnCompanyBank | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PendingDays">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Pending Days</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.PendingDays }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EMIFrom">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >EMI From</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMIFrom }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EMITo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >EMI To</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMITo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
            <i
              class="fa fa-arrow-circle-right btn_detail"
              (click)="goToDetail(row)"
              aria-label="true"
              *ngIf="row.status == 'Aweted'"
            ></i
            >&nbsp;
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsLoan; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsLoan"></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center" style="background: #fff">
        <div class="col-md-4">
          <button
            type="button"
            (click)="onExport(exporter)"
            [class.spinner]="loading"
            class="font-size-12 button-btn"
          >
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            class="mat-paginator-sticky"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="NachDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Details
        </h6>
        <button
          type="button"
          (click)="onCloseNachDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #f="ngForm" (ngSubmit)="onSaveNachDetail()" novalidate>
          <div class="row m-0" *ngIf="applicationDetail">
            <div class="col-md-3">
              <h6 class="fs-12">Loan Ac No</h6>
              <p class="lead fs-12 fw-7">{{ applicationDetail.LoanAcNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Date</h6>
              <p class="lead fs-12">{{ applicationDetail.Loan_date }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer Name</h6>
              <p class="lead fs-12">{{ applicationDetail.CustomerName }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Bank Name</h6>
              <p class="lead fs-12">{{ applicationDetail.Bankname }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Branch</h6>
              <p class="lead fs-12">{{ applicationDetail.Branch }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">A/C Type</h6>
              <p class="lead fs-12">{{ applicationDetail.AccType }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">A/c No.</h6>
              <p class="lead fs-12">{{ applicationDetail.Ac }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Status</h6>
              <p class="lead fs-12">{{ applicationDetail.status }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.LoanAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">EMI Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.EMIAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">No. Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.NoOfEMI }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Expiry Date Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.ExpiryEMIDate }}</p>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-3">
              <h6 class="fs-12">Nach Amount</h6>
              <p class="lead fs-12">
                {{ applicationDetail ? applicationDetail.NachAmount : "" }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Present On</h6>
              <p class="lead fs-12">
                {{
                  applicationDetail
                    ? (applicationDetail.PresentOnCompanyBank
                      | date : "dd/MM/yyyy")
                    : ""
                }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Present Bank In Company</h6>
              <p class="lead fs-12">
                {{
                  applicationDetail
                    ? applicationDetail.PresentBankInCompanyName
                    : ""
                }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Frequency</h6>
              <p class="lead fs-12">
                {{ applicationDetail ? applicationDetail.Frequency : "" }}
              </p>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12"><hr /></div>
          </div>

          <div class="row m-0">
            <div class="col-md-4">
              <span class="required-lable">EMI From</span>
              <input
                type="text"
                name="EMIFrom"
                [(ngModel)]="EMIFrom"
                id="EMIFrom"
                class="form-control input-text-css"
                placeholder="EMI From"
                #refEMIFrom="ngModel"
                required
                (change)="EMIFromChange()"
                [ngClass]="{
                  'is-invalid': f.submitted && refEMIFrom.invalid,
                  'alert-warning':
                    refEMIFrom.invalid &&
                    (refEMIFrom.dirty ||
                      refEMIFrom.touched ||
                      refEMIFrom.untouched)
                }"
              />
            </div>
            <div class="col-md-4">
              <span>EMI To</span>
              <input
                type="text"
                name="EMITo"
                [(ngModel)]="EMITo"
                id="EMITo"
                class="form-control input-text-css"
                disabled
              />
            </div>
          </div>
          <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
            <button
              type="button"
              (click)="onCloseNachDetail()"
              class="font-size-12 button-btn mr-3"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveNachDetail()"
              [class.spinner]="loading"
              class="font-size-12 button-btn"
              [disabled]="!f.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
