<div class="grid-container ">
  <div class="  grid-row-con">
    <div class="grid-items">


      <div class="grid-sub-item-value font-weight-bold"> Type</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Loan Amount</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Interest</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Agreement</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> EPI</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold"> Frequency </div>
    </div>
    <div class="grid-items ">
      <div class="grid-sub-item-value font-weight-bold"> Tenure </div>
    </div>
    <!--<div class="grid-items ">
      <div class="grid-sub-item-value font-weight-bold"> ROI </div>
    </div>-->
    <div class="grid-items ">
      <div class="grid-sub-item-value font-weight-bold"> IRR </div>
    </div>
  </div>
  <div class=" mt-1 grid-row-con" *ngFor="let row of financialdata">



    <div class="grid-items">
      <div class="grid-sub-item-value"> {{row.Type}} </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value"> {{row.LoanAmount}} </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value"> {{row.InterestAmt}} </div>
    </div>

    <div class="grid-items"> 
      <div class="grid-sub-item-value"> {{row.AgreementValue}} </div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value"> {{row.No_Of_Installment}} </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value"> {{row.EMIType}} </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{row.LoanTenure}}</div>
    </div>
    <!--<div class="grid-items">
      <div class="grid-sub-item-value">{{row.ROI}}</div>
    </div>-->
    <div class="grid-items">
       
      <div class="grid-sub-item-value">{{row.IRR}}</div>
    </div>

  </div>
</div>
