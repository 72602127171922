// #region Import Used Components and Services
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../../snackbar/snackbar.component'
import { constantUrl } from 'src/app/Shared/constantUrl';
import {
  GetrSaveresponseModel
} from '../../Shared/Models/app.MasterResponseModel';
// #endregion

@Injectable({
  providedIn: 'root'
})

export class LegalService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(private _Route: Router, private http: HttpClient, public snackBar: MatSnackBar) {
  }

  // #Start Legal_Get_AssignList
  public Legal_Get_AssignList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Legal_Get_AssignList`, requestData);
  }
  // #End Legal_Get_AssignList

  // #Start Save_Legal_Master
  public Save_Legal_Master(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Save_Legal_Master`, requestData);
  }
  // #End Save_Legal_Master

  // #Start Legal_Get_LegalCasesList
  public Legal_Get_LegalCasesList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Legal_Get_LegalCasesList`, requestData);
  }
  // #End Legal_Get_LegalCasesList

  // #Start Get_Customer_For_Legal_Notice
  public Get_Customer_For_Legal_Notice(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Get_Customer_For_Legal_Notice`, requestData);
  }
  // #End Get_Customer_For_Legal_Notice

  // #Start Save_Legal_Notice
  public Save_Legal_Notice(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Save_Legal_Notice`, requestData);
  }
  // #End Save_Legal_Notice

  // #Start Send_Legal_NoticeDetail_By_LoanId
  public Send_Legal_NoticeDetail_By_LoanId(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Send_Legal_NoticeDetail_By_LoanId`, requestData);
  }
  // #End Send_Legal_NoticeDetail_By_LoanId

  // #Start Send_OR_Delete_Legal_Notice
  public Send_OR_Delete_Legal_Notice(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Send_OR_Delete_Legal_Notice`, requestData);
  }
  // #End Send_OR_Delete_Legal_Notice

  // #Start Get_Legal_Notice_Send_Detail
  public Get_Legal_Notice_Send_Detail(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Get_Legal_Notice_Send_Detail`, requestData);
  }
  // #End Get_Legal_Notice_Send_Detail

  // #Start Legal_HearingList
  public Legal_HearingList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Legal_HearingList`, requestData);
  }
  // #End Legal_HearingList

  // #Start Save_Legal_Hearing
  public Save_Legal_Hearing(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Save_Legal_Hearing`, requestData);
  }
  // #End Save_Legal_Hearing

  // #Start Legal_Hearing_Detail_List
  public Legal_Hearing_Detail_List(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Legal_Hearing_Detail_List`, requestData);
  }
  // #End Legal_Hearing_Detail_List

  // #Start Legal_Case_Detail_ById
  public Legal_Case_Detail_ById(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Legal_Case_Detail_ById`, requestData);
  }
  // #End Legal_Case_Detail_ById

  // #Start Get_Legal_Notice_Send_ByLoan
  public Get_Legal_Notice_Send_ByLoan(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Get_Legal_Notice_Send_ByLoan`, requestData);
  }
  // #End Get_Legal_Notice_Send_ByLoan

  // #Start Save_Legal_Document
  public Save_Legal_Document(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Save_Legal_Document`, requestData);
  }
  // #End Save_Legal_Document

  // #Start Get_Legal_Document
  public Get_Legal_Document(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Get_Legal_Document`, requestData);
  }
  // #End Get_Legal_Document
}