import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-change-loan-no",
  templateUrl: "./change-loan-no.component.html",
  styleUrls: ["./change-loan-no.component.scss"],
})
export class ChangeLoanNoComponent implements OnInit {
  showSpinner: boolean = false;
  LoanNo: any;
  newLoanNo: any;
  showDetails: boolean = true;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  customerDetail: any;
  CustomerDataSource: any;
  displayedCustomerColumns: string[] = [
    "Customer",
    "FatherName",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
  ];
  currentUser: any;
  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Change Loan No");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.customerDetail.LoanNo = this.newLoanNo;
  }

  handleOldLoanNo(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window["clipboardData"];
    let pastedLoanNo = clipboardData.getData("text");
    pastedLoanNo = pastedLoanNo.trim();
    this.LoanNo = pastedLoanNo;
  }

  handleNewLoanNo(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window["clipboardData"];
    let pastedLoanNo = clipboardData.getData("text");
    pastedLoanNo = pastedLoanNo.trim();
    this.newLoanNo = pastedLoanNo.substring(0, 12);
  }

  loanNochange() {
    this.showDetails = false;
  }
  onSearchLoanNumber() {
    this.showSpinner = true;
    this._MasterService
      .LMS_Get_Loan_Detail_For_LoanAcNo({ LoanAcNo: this.LoanNo })
      .subscribe((res: any) => {
        this.showSpinner = false;
        console.log("CODE", res.Item2[0].CODE);

        if (res.Item2[0].CODE == -1) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res.Item2[0].MSG,
            ...this.configSuccess,
          });
        } else {
          console.log("Ekse condition");
          this.customerDetail = res.Item1[0];
          console.log("Customer Details", this.customerDetail);
          this.CustomerDataSource = new MatTableDataSource(res.Item2);
          this.showDetails = true;
        }
      });
  }

  onUpdateLoanNo() {
    this._MasterService
      .Update_Loanac_No({
        loginUserId: this.currentUser.userId,
        oldLoanAcNo: this.LoanNo,
        NewLoanNo: this.newLoanNo,
      })
      .subscribe((res) => {
        console.log("Updated");
        if (res[0].CODE == -1) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.showDetails = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.newLoanNo = "";
          this.LoanNo = "";
          this.showDetails = false;
        }
      });
  }
}
