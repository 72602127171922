<div class="card-container" style="padding: 0;height:100%">
    <div class="title-head"
        style="border-bottom: 1px solid black;background-color: #4dc3a3;border-radius: 9px 9px 0 0 ;">
        <h1 class="main-tit" style="line-height: 2.2;margin:0;">Family Details</h1>
    </div>
    <div style="min-height: 100%;" class="text-center"   *ngIf="!familyData">
        No Information Available!!
    </div>
    <div class="cnt-data" style="margin:10px" *ngIf="familyData">
        <div class="income">
            <!-- <div class="name-sub" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
            <h1 style="margin-bottom: 2px;">Income Details</h1>
        </div> -->
            <div class="cont">
                <div *ngFor="let item of familyData">
                    <div class="cont-Card">
                        <div class="grd">
                            <div class="grd-item">{{item["Member_Name"]}}</div>
                            <div class="grd-item">{{item["Member_Reletion"]}}</div>
                            <div class="grd-item">{{item["Member_ContactNumber"]}} </div>
                            <div class="grd-item">{{item["Member_OccupationType"]}} </div>
                            <div class="grd-item">{{item["Member_HowMuchEarn"]}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>