import { Component, Input, OnInit } from '@angular/core';
import { LmsService } from '../../services/lms.service';

@Component({
  selector: 'app-receipt-deduction',
  templateUrl: './receipt-deduction.component.html',
  styleUrls: ['./receipt-deduction.component.scss']
})
export class ReceiptDeductionComponent implements OnInit {
@Input() loanId;

receiptData=[];
  constructor(private _LMSService:LmsService) {
    
   }

  ngOnInit(): void {
    this._LMSService.GetReceiptDeduction({Loan_Id:this.loanId}).subscribe((x:any[])=>{
      this.receiptData=x;
    })

  }

}
