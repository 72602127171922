import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { MasterService } from '../../app/Shared/app.Masters.Service';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthGuardService implements CanActivate {
  data: any;
  url: any;
  HasViewAccess: number = 1;
  IsValid: boolean;
  constructor(private MasterService: MasterService, private router: Router) { }

  canActivate(route) {
   
    if (environment.production) {
      var allow;
      if (isNaN(+allow)) allow = 100;
      var start = +new Date();
      debugger;
      var end = +new Date();
      if (isNaN(start) || isNaN(end) || end - start > allow) {
        this.router.navigate([`Page403Error`]);
      }

    }
    //let roles = route.data["roles"] as Array<string>;
    console.log('data roles : ' + route._routerState.url);
    //console.log(roles[0]);   
    if (sessionStorage.getItem('currentUser')) {
      var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      this.MasterService.Check_URL(currentUser.RoleId, route._routerState.url);
      return true;
      //}    
    }

    //else if (sessionStorage.getItem('currentUser') == null){
    //  console.log('null');
    //  return false;
    //}
    // not logged in so redirect to Unauthorised page
    // this.router.navigate(['/Unauthorized/access']);
    return false;
  }
}


