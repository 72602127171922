<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keydown.enter)="onSearchCRCReport()">
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span class="required-lable"> New Code </span>
        <input
          name="NewCode"
          [(ngModel)]="CRCModel.NewCode"
          id="NewCode"
          placeholder="New Code"
          class="form-control input-text-css"
          required
          #refNewCode="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refNewCode.invalid,
            'alert-warning':
              refNewCode.invalid &&
              (refNewCode.dirty || refNewCode.touched || refNewCode.untouched)
          }"
        />
      </div>
      <div class="col-md-3">
        <span class="required-lable"> New Short Name </span>
        <input
          name="NewShortName"
          [(ngModel)]="CRCModel.NewShortName"
          id="NewShortName"
          placeholder="New Short Name"
          class="form-control input-text-css"
          required
          #refNewShortName="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refNewShortName.invalid,
            'alert-warning':
              refNewShortName.invalid &&
              (refNewShortName.dirty ||
                refNewShortName.touched ||
                refNewShortName.untouched)
          }"
        />
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Old Code </span>
        <input
          name="OldCode"
          [(ngModel)]="CRCModel.OldCode"
          id="OldCode"
          placeholder="Old Code"
          class="form-control input-text-css"
          required
          #refOldCode="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refOldCode.invalid,
            'alert-warning':
              refOldCode.invalid &&
              (refOldCode.dirty || refOldCode.touched || refOldCode.untouched)
          }"
        />
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Old Short Name </span>
        <input
          name="OldShortName"
          [(ngModel)]="CRCModel.OldShortName"
          id="OldShortName"
          placeholder="Old Short Name"
          class="form-control input-text-css"
          required
          #refOldShortName="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refOldShortName.invalid,
            'alert-warning':
              refOldShortName.invalid &&
              (refOldShortName.dirty ||
                refOldShortName.touched ||
                refOldShortName.untouched)
          }"
        />
      </div>

      <div class="col-md-3">
        <span class="required-lable"> Close From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CloseFromDate"
            [(ngModel)]="CRCModel.CloseFromDate"
            id="CloseFromDate"
            placeholder="Close From Date"
            class="form-control input-text-css"
            required
            #refCloseFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refCloseFromDate.invalid,
              'alert-warning':
                refCloseFromDate.invalid &&
                (refCloseFromDate.dirty ||
                  refCloseFromDate.touched ||
                  refCloseFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Report Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ReportDate"
            [(ngModel)]="CRCModel.ReportDate"
            id="ReportDate"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            required
            #refReportDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refReportDate.invalid,
              'alert-warning':
                refReportDate.invalid &&
                (refReportDate.dirty ||
                  refReportDate.touched ||
                  refReportDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="CRCModel.SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-3 text-right">
        <button
          type="button"
          (click)="onSearchCRCReport()"
          class="font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="row m-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0" [hidden]="!DataSource">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="height: 300px; max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_FirstName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer First Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_FirstName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_MiddleName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Middle Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_MiddleName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_LastName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Last Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_LastName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_DOB">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer DOB</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer_DOB
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Gender === 1 ? "Female" : "Male" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Income_Tax_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >PAN No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Income_Tax_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Passport_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Passport No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Passport_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Voter_Id_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Votter ID</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Voter_Id_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Driving_License_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Driving License Number</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Driving_License_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Ration_Card_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Ration Card Number</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Ration_Card_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Universal_Id_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Universal Id No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Universal_Id_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Additional_Id1_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Additional Id 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Additional_Id1_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_1_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 1 Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_1_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_2_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 2 Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_2_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_3">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 3</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_3
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="TelephoneNumber_3_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No 3 Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.TelephoneNumber_3_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Telephone_Extension">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone Extension</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Telephone_Extension
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Email_Id1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Email Id 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Email_Id1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Address1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Address1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="city1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >City 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.city1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="District1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >District 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.District1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="State_Code1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >State Code 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.State_Code1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Pincode1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Pincode 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Pincode1
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Address_Category1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address Category 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <span [ngSwitch]="row.Address_Category1">
                <span *ngSwitchCase="01">Permanent Address</span>
                <span *ngSwitchCase="02">Residence Address</span>
                <span *ngSwitchCase="03">Office Address</span>
                <span *ngSwitchDefault>Not Categorized</span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Residence_Code1">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Residence Code 1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Residence_Code1 === 01 ? "Owned" : "Rented" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Address2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Address2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="city2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >City 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.city2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="District2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >District 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.District2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="State_Code2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >State Code 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.State_Code2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Pincode2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Pincode 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Pincode2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Address_Category2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address Category 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <span [ngSwitch]="row.Address_Category2">
                <span *ngSwitchCase="01">Permanent Address</span>
                <span *ngSwitchCase="02">Residence Address</span>
                <span *ngSwitchCase="03">Office Address</span>
                <span *ngSwitchDefault>Not Categorized</span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Residence_Code2">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Residence Code 2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Residence_Code2 === 01 ? "Owned" : "Rented" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="New_Member_Code">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >New Member Code</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.New_Member_Code
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="New_Member_Short_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >New Member Short Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.New_Member_Short_Name
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="New_Account_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >New Account No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.New_Account_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="New_Account_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >New Account Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.New_Account_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="New_Ownerhip_Indicator">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >New Ownership Indicator</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <span [ngSwitch]="row.New_Ownerhip_Indicator">
                <span *ngSwitchCase="01">Borrower</span>
                <span *ngSwitchCase="03">Guarantor</span>
                <span *ngSwitchCase="04">Coborrower</span>
                <span *ngSwitchDefault>Joint</span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_Opened">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Date Opened</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Date_Opened
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_Of_Last_Payment">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Date of Last Payment</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Date_Of_Last_Payment
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_Closed">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Date Closed</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Date_Closed
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="DateReported">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Date Reported</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.DateReported
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="High_Credit">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >High Credit</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.High_Credit
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Current_Balance">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Current Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Current_Balance
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Amount_Overdue">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Amt Overdue</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Amount_Overdue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DPD Days</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.DPD_Days
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="OldCode">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Old Code</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.OldCode
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="OldShort_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Old Short Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.OldShort_Name
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Old_Account_Number">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Old Acc No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Old_Account_Number
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Old_Account_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Old Acc Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Old_Account_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Old_Ownerhip_Indicator">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Old Ownership Indicator</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Old_Ownerhip_Indicator
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Suit_Filed">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Suit Filed</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell"
              >{{ row.Suit_Filed }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="WriteOff_Settled_Status">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Written-off Settled Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.WriteOff_Settled_Status
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Assets_Classification">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Asset Classification</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <span [ngSwitch]="row.Assets_Classification">
                <span *ngSwitchCase="01">Standard</span>
                <span *ngSwitchCase="02">Substandard</span>
                <span *ngSwitchCase="03">Doubtful</span>
                <span *ngSwitchCase="04">Loss</span>
                <span *ngSwitchDefault>Special Mention Account</span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value_Of_collateral">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Value of Collateral</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.value_Of_collateral
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Collateral_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collateral Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Collateral_Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Credit_Limit">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Credit Limit</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Credit_Limit
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Cash_Limit">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Cash Limit</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Cash_Limit
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="RateOf_Interest">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Rate of Interest</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.RateOf_Interest
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Repayment_Tenure">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Repayment Tenure</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Repayment_Tenure
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.EMI_Amount
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Writen_Of_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Written off Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Writen_Of_Amount
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Writen_Of_Principle">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Written of Principal Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Writen_Of_Principle
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Settlement_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Settlement Amt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Settlement_Amount
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Payment_Frequency">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Payment Frequency</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <span [ngSwitch]="row.Payment_Frequency">
                <span *ngSwitchCase="03">Monthly</span>
                <span *ngSwitchCase="04">Quarterly</span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actual_Payment_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Actual Payment Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Actual_Payment_Amount
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_Occupation">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Occupation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell"
              >{{ row.Customer_Occupation }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Income">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Income</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Income
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Net_Gross_Income_Indicator">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Net Gross Income Indicator</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Net_Gross_Income_Indicator }}
              <!-- {{row.Net_Gross_Income_Indicator === 'M' ? 'Monthly' : 'Annual'}} -->
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Monthly_Annual_Income_Indicator">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Monthly/Annual Income Indicator</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Monthly_Annual_Income_Indicator }}
              <!-- {{row.Monthly_Annual_Income_Indicator === 'M' ? 'Monthly' : 'Annual'}} -->
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [class.highlight]="i == selectedId"
            *matRowDef="let row; let i = index; columns: displayedColumns"
          ></mat-row>
        </mat-table>

        <div class="row m-0 align-items-center">
          <div class="col-md-6">
            <button class="btn font-size-12 button-btn" (click)="exportExcel()">
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-6">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
