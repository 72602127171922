import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { LegalService } from '../services/Legal.service';
declare var $: any;
import * as moment from 'moment';

// Create multiple PDF file 
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';



@Component({
  selector: 'app-legal-notice-attachments',
  templateUrl: './legal-notice-attachments.component.html',
  styleUrls: ['./legal-notice-attachments.component.scss']
})
export class LegalNoticeAttachmentsComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;

  dataSource: any;
  displayedColumns: string[] =[
    'Id', 'Sent_By', 'Sent_On', 'Dispatched_Mode', 'Dispached_Date', 'Dispached_Remark', 'Tracking_No', 'Agency', 
    'CourierCompanyName', 'Dispatched_Detail', 'Notice_Data'
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  
  @Input() LegalSummary: any;


  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _LegalService: LegalService,
    private dialog: DialogService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next('Legal Notice Attachment');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.GetLegalNoticeSendByLoan();
    console.log("this.LegalSummary", this.LegalSummary);
    
  }

  GetLegalNoticeSendByLoan() {
    this.showSpinner = true;
    this._LegalService.Get_Legal_Notice_Send_ByLoan({ LoanId: this.LegalSummary.Loan_Id, CustomerId: this.LegalSummary.Customer_Id }).subscribe((res: any) => {
      console.log("res", res);
      
      if(res.length > 0){
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      this.showSpinner = false;
    });
  }

  ViewPDF(row){
    var html = htmlToPdfmake(row.Notice_Data);
    var win = window.open('', '_blank');
    const documentDefinition = { content: html, defaultStyle:{ fontSize: 10} };
    pdfMake.createPdf(documentDefinition).open({}, win);
  }
}
