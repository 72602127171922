// #region Import Used Components and Services
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonFunctions {
  PrintDocuments(divName, Header, LoanNo) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${Header} - ${LoanNo}</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
          thead th, thead td {
            border-bottom-width: 1px;
           
          }
          
          th{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          td{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          body{
            width:1060px
          }
          .bg-success1 {
            background: #0e5c86 !important;
            font-weight: 500;
            color: #000;
            font-size: 12px;
          }
          .form-control1 {
            display: block;
            width: 100%;
            height: calc(1.0em + 0.75rem + 2px);
            padding: 0.120rem 0.3rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #888;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
          .table th, .table td {
            padding: 0.25rem 0.25rem 0 0.25rem !important;
            vertical-align: middle;
          }
     

          @media print {
            // @page {
              
            //     size: A4;
            //     margin: 10px 20px 20px 20px; 
            //     content: counter(page) "/" counter(pages);

         
             
              
            // }
            #foot {
              content: counter(page) "/" counter(pages);
              counter-increment: page;
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
          
         
          .sign_hr {
            margin-top: 2rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 1);
          }

        //  .annexB{
        //   margin-bottom: 20rem;
        //  }
          
         
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}
        <footer></footer>
        </body>
      </html>`);
    popupWin.document.close();
  }

  PrintFDRDeposit(divName, Header, Deposit_No) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${Header} - ${Deposit_No}</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
          thead th, thead td {
            border-bottom-width: 1px;
          }
          
          th{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          td{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          body{
            width:1060px
          }
          .bg-success1 {
            background: #0e5c86 !important;
            font-weight: 500;
            color: #000;
            font-size: 12px;
          }
          .form-control1 {
            display: block;
            width: 100%;
            height: calc(1.0em + 0.75rem + 2px);
            padding: 0.120rem 0.3rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #888;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
          .table th, .table td {
            padding: 0.25rem 0.25rem 0 0.25rem !important;
            vertical-align: middle;
          }
          @media print {
            @page {
              size: A4;
              margin: 10px 20px 20px 20px; 
              content: counter(page) "/" counter(pages);
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }

            #abc::before{
              counter-increment: fot;
            content: counter('fot') "Section";
            }
          }
          .sign_hr {
            margin-top: 2rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 1);
          }
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  PrintBalanceSheet(divName, Header, LoanNo) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${Header} - ${LoanNo}</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
          thead th, thead td {
            border-bottom-width: 1px;
          }
          
          th{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          td{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          body{
            width:1060px
          }
          .bg-success1 {
            background: #0e5c86 !important;
            font-weight: 500;
            color: #000;
            font-size: 12px;
          }
          .form-control1 {
            display: block;
            width: 100%;
            height: calc(1.0em + 0.75rem + 2px);
            padding: 0.120rem 0.3rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #888;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
          .table th, .table td {
            padding: 0.25rem 0.25rem 0 0.25rem !important;
            vertical-align: middle;
          }
          @media print {
            body {
              margin: 40px 0 40px 0;
              height: auto;
            }
            @page {
              size: A4;
              margin: 30px 20px 40px 20px; 
              content: counter(page) "/" counter(pages);
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
            .mt-5 {
              margin-top: 2.5rem !important;
            }
            .header{
              position: fixed;
              top: 20px;
              bottom: 40px;
            }
          }

            .page-header, .page-header-space {
                height: 100px;
              }

              .page-footer, .page-footer-space {
                height: 20px;

              }

              .page-footer {
                position: fixed;
                bottom: 0;
                width: 100%;
               
                
              }

              .page-header {
                position: fixed;
                top: 0mm;
                width: 100%;
                
              }

              .page {
                page-break-after: always;
              }

              //@page {
              //  margin: 20mm
              //}

              //@media print {
              //   thead {display: table-header-group;} 
              //   tfoot {display: table-footer-group;}
   
              //   button {display: none;}
   
              //   body {margin: 0;}
              //}
          .sign_hr {
            margin-top: 2rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 1);
          }
          </style>
        </head>
        <body onload="window.print(); window.close()">


<div >
${printContents}
</div>
</body>
      </html>`);
    popupWin.document.close();
  }

  PrintTrailDocuments(divName, Header, LoanNo) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${Header} - ${LoanNo}</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
          thead th, thead td {
            border-bottom-width: 1px;
           
          }
          
          th{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          td{
            font-size: 14px;
            padding: 0.20rem !important;
            vertical-align: top;
            border: 1px solid #333;
          }
          body{
            width:1060px
          }
          .bg-success1 {
            background: #0e5c86 !important;
            font-weight: 500;
            color: #000;
            font-size: 12px;
          }
          .form-control1 {
            display: block;
            width: 100%;
            height: calc(1.0em + 0.75rem + 2px);
            padding: 0.120rem 0.3rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #888;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
          .table th, .table td {
            padding: 0.25rem 0.25rem 0 0.25rem !important;
            vertical-align: middle;
          }
     

          @media print {
            // @page {
              
            //     size: A4;
            //     margin: 10px 20px 20px 20px; 
            //     content: counter(page) "/" counter(pages);

         
             
              
            // }
            #foot {
              content: counter(page) "/" counter(pages);
              counter-increment: page;
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
          
         
          .sign_hr {
            margin-top: 2rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 1);
          }
          .mat-icon-button{
            border: none;
            background-color: #fff;
          }
          //.cdk-column-Group_Name{
          //  font-weight:bold;
          //}
         
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}
        <footer></footer>
        </body>
      </html>`);
    popupWin.document.close();
  }

  PrintCibil(divName, Header, LoanNo) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${Header} - ${LoanNo}</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>


          .printMainDiv{
          displat:flex;
          flex-direction:column;
          gap:10px
          }

          @media print {
            body {
              margin: 40px 0 40px 0;
              height: auto;
            }
            @page {
              size: A4;
              margin: 30px 20px 40px 20px; 
              content: counter(page) "/" counter(pages);
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
            .Score {
              font-size: 30px;
            }
            .row:before,
            .row:after{
               display: table;
              content: " ";
            }
            .mt-5 {
              margin-top: 2.5rem !important;
            }
             .col-md-4 {
                width: 33.33333333%;
              }
            .header{
              position: fixed;
              top: 20px;
              bottom: 40px;
            }
          }

            .page-header, .page-header-space {
                height: 100px;
              }

              .page-footer, .page-footer-space {
                height: 20px;

              }

              .page-footer {
                position: fixed;
                bottom: 0;
                width: 100%;
               
                
              }

              .page-header {
                position: fixed;
                top: 0mm;
                width: 100%;
                
              }

              .page {
                page-break-after: always;
              }

          .sign_hr {
            margin-top: 2rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 1);
          }
          </style>
        </head>
        <body onload="window.print(); window.close()">


<div >
${printContents}
</div>
</body>
      </html>`);
    popupWin.document.close();
  }
}
