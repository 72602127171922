<div class="mb-3">
  <div class="row m-0">
    <div class="col-md-3 p-2 box" routerLink="/lms-dashboard/lms-nach-registration">
      <div class="d-flex" style="border: 1px solid #DDDD;border-radius: 5px;">
        <div class="box-img">
          <img src="../../assets/images/app.png" alt="img" style="width: 100%;">
        </div>
        <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content" style="width: 80%;">
          <span> NACH Registration </span>
        </div>
      </div>
    </div>
    <div class="col-md-3 p-2 box" routerLink="/lms-dashboard/lms-nach-process">
      <div class="d-flex" style="border: 1px solid #DDDD;border-radius: 5px;">
        <div class="box-img">
          <img src="../../assets/images/app.png" alt="img" style="width: 100%;">
        </div>
        <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content" style="width: 80%;">
          <span> NACH Process </span>
        </div>
      </div>
    </div>
  </div>
</div>
