import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import * as moment from "moment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { map, startWith, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
@Component({
  selector: "app-loan-customer-list",
  templateUrl: "./loan-customer-list.component.html",
  styleUrls: ["./loan-customer-list.component.scss"],
})
export class LoanCustomerListComponent implements OnInit {
  [x: string]: any;
  showSpinner: boolean = false;
  dataSource: any;
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();
  currentUser: any;
  FyearId: any;
  maxDate: any;
  minDate: any;
  SalesEx: any = "All";
  BranchesList: any = [];
  ProductList: any = [];
  SalesExecutiveData: any = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  private _MasterService;

  DataSource: any = [];
  JsonData: any[] = [];
  SelectBranch: any = "All";
  ProductId: any = "All";
  LoanAccountCategory: any = "All";
  SalesExecutive: any = "All";
  displayedColumns: string[] = [
    "#",
    "LoanAcNo",
    "Loan_Date",
    "Branch_Name",
    "Product_Name",
    "Application_LoanAmount",
    "SalesExecutive",
    "CollectionExecutive",
    "AC_CustomerType",

    "Customer_Name",
    "Customer_Relation",
    "Relation_Name",
    "Address1",
    "Address2",
    "Customer_PhoneNo",
    "Customer_WhatsAppNo",
    "Customer_Email",
    "CRC_CreditVisionScore",
    "CRC_PersonalLoanScore",
    "CRC_Remark",
    "FI_Remark",
    "TVR_Remark",
    "Total_Expense",
    "Total_Income",
    "AssignTo",
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  AccountingCatDropdown: any;
  losService: any;
  chhead: any = "";
  AccountHeadDropdown: any;
  filteredAccountHead: Observable<any[]>;
  AccountControl = new UntypedFormControl();

  constructor(
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,

    private snackBar: MatSnackBar,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Loan Customer Details");
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          this.DataSource = null;
        });
    });

    this.getBranchesList();
    this.getProductDropdown();
    this.Get_Loan_Accounting_Category_ForDropdown();
    this.GetSalesExecutive();
    this.getAccounthead();
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
        console.log("Product List", this.ProductList);
      });
  }
  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccountingCatDropdown = res;
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  onAccountHeadhSelect(event: any) {
    this.dataSource = "";
    this.AccountHead = event.option.value.AccountId;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }

  getAccounthead() {
    this.lmsService.Loan_Lending_List().subscribe((res: any) => {
      this.AccountHeadDropdown = res;
      this.AccountHead = res[1].AccountId;
      this.filteredAccountHead = this.AccountControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value.Account_Name
        ),
        map((name) =>
          name ? this._filter(name) : this.AccountHeadDropdown.slice()
        )
        //tap((x) => x ? this.AccountControl.setValue(this.AccountHeadDropdown[1]) : '')
      );
    });
  }

  onSearchLoanCustomerList() {
    this.showSpinner = true;
    this.reportsService
      .Loan_Customer_Details({
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        BranchId: this.SelectBranch == "All" ? 0 : this.SelectBranch,
        ProductId: this.ProductId == "All" ? 0 : this.ProductId,
        LoanAcCategory:
          this.Loan_AccountingCategoryId == "All"
            ? 0
            : this.Loan_AccountingCategoryId,
        SalesExecutive: this.SalesEx == "All" ? 0 : this.SalesEx,
        AssignTo: this.chhead.AccountId == "" ? 0 : this.chhead.AccountId,
      })
      .subscribe((res: any) => {
        console.log("Report :", res);
        this.JsonData = res;
        this.DataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.DataSource.paginator = this.paginator;
        this.DataSource.sort = this.sort;
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  exportExcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "loan-customer-deatils"
    ) as HTMLTableElement;
    const worksheetName = "LOAN CUSTOMER DEATILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
