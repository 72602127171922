<section class="main-content-wrapper">
  <div class="row col-md-12 m-0 mt-0">
    <div class="col-md-12 p-0">
      <mat-tab-group
        class="loan_tab"
        [selectedIndex]="currentGSTTab"
        (selectedTabChange)="onTabChange($event)"
      >
        <mat-tab>
          <ng-template mat-tab-label><span>GST Details</span></ng-template>
          <ng-template matTabContent>
            <div style="margin-top: 10px">
              <app-mat-spinner-overlay *ngIf="showSpinner">
              </app-mat-spinner-overlay>
              <form
                #df="ngForm"
                novalidate
                (keydown.enter)="OnSearchGSTDetail()"
                class="formborder"
              >
                <div
                  class="row m-0 col-md-12 p-0"
                  style="display: flex; align-items: center"
                >
                  <div class="col-md-2">
                    <span> From Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker"
                        dateConvert
                        [min]="minDate"
                        [max]="maxDate"
                        placeholder="DD/MM/YYYY"
                        name="FromDate"
                        id="FromDate"
                        class="form-control input-text-css"
                        [(ngModel)]="FromDate"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                    <!-- <input
                      name="FromDate"
                      [(ngModel)]="FromDate"
                      id="FromDate"
                      placeholder="From Date"
                      class="form-control input-text-css"
                      bsDatepicker
                      (ngModelChange)="DateChange()"
                    /> -->
                  </div>
                  <div class="col-md-2">
                    <span> To Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker2"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="ToDate"
                        id="ToDate"
                        [min]="minDate"
                        [max]="maxDate"
                        class="form-control input-text-css"
                        [(ngModel)]="ToDate"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </div>
                    <!-- <input
                      name="ToDate"
                      [(ngModel)]="ToDate"
                      id="ToDate"
                      placeholder="To Date"
                      class="form-control input-text-css"
                      bsDatepicker
                      (ngModelChange)="DateChange()"
                    /> -->
                  </div>

                  <div class="col-md-2">
                    <span class="required-lable"> Account Name </span>
                    <select
                      name="AccountNameDropdown"
                      id="AccountNameDropdown"
                      [(ngModel)]="AccountNameDropdown"
                      class="form-control input-text-css"
                    >
                      <option value="0">All</option>
                      <option
                        *ngFor="let accountName of accountList"
                        [value]="accountName.Id"
                      >
                        {{ accountName.Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-1">
                    <button
                      type="button"
                      (click)="OnSearchGSTDetail()"
                      class="mt-3 btn font-size-12 button-btn"
                    >
                      Search
                    </button>
                  </div>

                  <div class="col-md-4 ml-4">
                    <form action="" class="search-text">
                      <div class="bg-light rounded rounded-pill shadow-sm">
                        <div class="input-group">
                          <input
                            type="text"
                            id="FilterInputSearch"
                            (keyup)="applyFilter($event.target.value)"
                            name="FilterInputSearch"
                            placeholder="What're you searching for?"
                            class="form-control border-0 bg-light"
                          />
                          <div class="input-group-append">
                            <button
                              id="button-addon1"
                              type="submit"
                              class="btn btn-link text-primary"
                            >
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>

              <div class="mt-3" [hidden]="!DataSource">
                <div class="">
                  <div class="table-responsive">
                    <mat-table
                      [dataSource]="DataSource"
                      matSort
                      matTableExporter
                      #exporter="matTableExporter"
                      style="max-width: 100%; overflow: auto"
                    >
                      <ng-container matColumnDef="Id">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="max-width: 50px"
                          >#</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell mw50"
                        >
                          {{ i + 1 }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          style="max-width: 57px"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceDate">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice Date</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceDate }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="LoanacNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Loan No/App No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.LoanacNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="PartyName">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Party Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.PartyName }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Acc_GSTNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >GST No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Acc_GSTNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Account_Name">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Account Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Account_Name }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                          >Total</mat-footer-cell
                        >
                      </ng-container>

                      <ng-container matColumnDef="HSN_Code">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >HSN Code</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.HSN_Code }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Taxable_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Taxable</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Taxable_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("Taxable_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="SGST_Rate_Input">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>SGST Rate Input</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.SGST_Rate_Input}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="SGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >SGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.SGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("SGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="CGST_Rate_Input">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CGST Rate Input</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.CGST_Rate_Input}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="CGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >CGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.CGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("CGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="IGST_Rate_Input">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>IGST Rate Input</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.IGST_Rate_Input}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="IGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >IGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.IGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("IGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Invoice_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >Invoice</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Invoice_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("Invoice_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Taxable_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Taxable</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Taxable_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("Taxable_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="SGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>SGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.SGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="SGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >SGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.SGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("SGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="CGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.CGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="CGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >CGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.CGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("CGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="IGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>IGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.IGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="IGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >IGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.IGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("IGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Invoice_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >Invoice</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Invoice_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewise("Invoice_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >View Detail</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell text-center"
                        >
                       
                          <i
                            class="fa fa-eye mr-1"
                            (click)="goToDetailModelEye(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>
                          <!--</app-lms-voucher-view-model>-->

                          <i
                            class="fa fa-print mr-1"
                            (click)="goToDetailModel(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>

                          <!-- <i
        class="fa fa-arrow-circle-right"
        (click)="goToDetailModel(row)"
        style="cursor: pointer; color: green; font-size: 16px"
        aria-label="true"
      ></i> -->
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <!-- Top header column start here -->
                      <ng-container matColumnDef="header-row-first">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="8"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Particulars
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="header-row-second">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="5"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Amount Input
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="header-row-third">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="5"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Amount Output
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="header-row-last">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="1"
                        >
                          &nbsp;
                        </th>
                      </ng-container>
                      <!-- Top header column end here -->
                      <!-- Top header row start here -->
                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          [
                            'header-row-first',
                            'header-row-second',
                            'header-row-third',
                            'header-row-last'
                          ];
                          sticky: true
                        "
                      ></tr>
                      <!-- Top header row end here -->
                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                      <tr
                        mat-footer-row
                        class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumns; sticky: true"
                        style="background: #4dc3a3"
                      ></tr>
                    </mat-table>
                    <div class="row m-0 align-items-center">
                      <div class="col-md-4">
                        <button
                          class="mt-3 btn font-size-12 button-btn"
                          (click)="exportToExcel()"
                        >
                          <i
                            class="fa fa-file-export mr-1"
                            style="font-size: medium; cursor: pointer"
                          ></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade in"
              id="invoiceBill"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              data-backdrop="false"
              style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
            >
              <div
                class="modal-dialog modal-xl modal-dialog-centered"
                role="document"
              >
                <div
                  cdkDrag
                  cdkDragRootElement=".cdk-overlay-pane"
                  class="modal-content"
                  style="width: 780px"
                >
                  <div
                    cdkDragHandle
                    class="modal-header"
                    style="padding: 2px; border: none"
                  >
                    <h6
                      class="modal-title"
                      id="myModalLabel"
                      name="myModalLabel"
                    >
                      GST Detail
                    </h6>
                    <button
                      type="button"
                      class="close"
                      (click)="OnCloseVoucherModel()"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div id="invoicePrint">
                      <div class="invoice">
                        <div class="header">
                          <h2>Invoice</h2>
                        </div>
                        <div class="company-info">
                          <div class="logo">
                            <!-- Your company logo here -->
                            <img src="{{ CompanyProfileBaseUrl }}logo.png" />
                          </div>
                          <div class="info">
                            <p class="company-name">
                              <strong>{{ gstDetail?.CompanyName }}</strong>
                            </p>
                            <div class="company-details">
                              <span>{{ gstDetail?.CompanyAddress }}</span>
                              <span>GST No.:{{ gstDetail?.CompanyGSTNo }}</span>
                              <span
                                >PAN No.: {{ gstDetail?.ComapnyPanNo }}</span
                              >
                              <span>
                                Phone No.:
                                {{ gstDetail?.CompanyPhoneNo }}
                              </span>
                              <span
                                >Email Id: {{ gstDetail?.ComapnyEmail }}</span
                              >
                            </div>
                          </div>
                          <div class="invoice-no">
                            <span>
                              <strong
                                >Invoice No.: {{ gstDetail?.InvoiceNo }}</strong
                              >
                            </span>
                            <span>
                              <strong>
                                Invoice Date:
                                {{ gstDetail?.InvoiceDate }}
                              </strong>
                            </span>
                          </div>
                        </div>

                        <!-- <hr /> -->
                        <div class="party-info">
                          <div class="party-details">
                            <span><strong>Invoice From:-</strong></span>
                            <span>{{ gstDetail?.PartyFrom_Name }}</span>
                            <span>{{ gstDetail?.PartyFrom_Address }}</span>
                            <span>
                              {{ gstDetail?.PartyFrom_StateName }}-{{
                                gstDetail?.PartyFrom_PINCode
                              }}
                            </span>
                            <span
                              >GST No.:{{ gstDetail?.PartyFrom_GSTNo }}</span
                            >
                          </div>
                          <!-- <div class="invoice-details">
                            <p><strong>Invoice No.: INV123</strong></p>
                            <p><strong>Invoice Date: 2023-07-07</strong></p>
                          </div> -->
                          <div class="invoice-details">
                            <span><strong>Invoice To:-</strong></span>
                            <span>{{ gstDetail?.PartyName }}</span>
                            <span>Address</span>
                            <span>
                              {{ gstDetail?.Party_State }}-{{
                                gstDetail?.PartyPinCode
                              }}
                            </span>
                            <span>GST No.: {{ gstDetail?.Acc_GSTNo }}</span>
                          </div>
                        </div>
                        <table
                          class="invoice-table"
                          *ngIf="JsonData2.length > 0"
                        >
                          <thead>
                            <tr>
                              <th colspan="6" style="text-align: left">
                                Particulars(Descriptions & Specifications)
                              </th>
                              <th style="text-align: center">HSN</th>
                              <th style="text-align: center">Amount</th>
                              <th style="text-align: center">SGST</th>
                              <th style="text-align: center">CGST</th>
                              <th style="text-align: center">IGST</th>
                              <th style="text-align: center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of JsonData2; let i = index">
                              <td style="text-align: left" colspan="6">
                                {{ x.ChargeHeadCategory }}
                              </td>

                              <td></td>
                              <td>{{ x.Amount }}</td>
                              <td>{{ x.SGST }}</td>
                              <td>{{ x.CGST }}</td>
                              <td>{{ x.IGST }}</td>
                              <td>{{ x.Total }}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="6" style="text-align: right">
                                Total:
                              </th>
                              <th></th>
                              <th>{{ totaltypewisemodal("Amount") }}</th>
                              <th>{{ totaltypewisemodal("SGST") }}</th>
                              <th>{{ totaltypewisemodal("CGST") }}</th>
                              <th>{{ totaltypewisemodal("IGST") }}</th>
                              <th>{{ totaltypewisemodal("Total") }}</th>
                            </tr>
                          </tfoot>
                        </table>

                        <div class="Total-Amount">
                          <p>Amount In Word:- {{ AmountInWord }} only</p>
                        </div>

                        <div class="footer">
                          <span>{{ gstDetail?.CompanyName }}</span>
                          <span id="sign"
                            ><strong>Authorised Signatory</strong></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-2 mb-2">
                      <button
                        type="button"
                        class="mt-3 btn font-size-12 button-btn"
                        (click)="PrintDoc('invoicePrint', 'Invoice Print')"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table
              id="gst_details"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                display: none;
              "
            >
              <tr>
                <th
                  colspan="8"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Particulars
                </th>
                <th
                  colspan="5"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Amount Input
                </th>
                <th
                  colspan="5"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Amount Output
                </th>
              </tr>
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  #
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice Date
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Loan No/App No.
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Party Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  GST No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Account Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  HSN Code
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Taxable
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  SGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CGST
                </th>

                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  IGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Taxable
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  SGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CGST
                </th>

                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  IGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice
                </th>
              </tr>

              <tr *ngFor="let x of JsonData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.InvoiceDate }}</td>
                <td>{{ x.InvoiceNo }}</td>
                <td>{{ x.LoanacNo }}</td>
                <td>{{ x.PartyName }}</td>
                <td>{{ x.Acc_GSTNo }}</td>
                <td>{{ x.Account_Name }}</td>
                <td>{{ x.HSN_Code }}</td>
                <td>{{ x.Taxable_Amount_Input }}</td>
                <td>{{ x.SGST_Amount_Input }}</td>
                <td>{{ x.CGST_Amount_Input }}</td>

                <td>{{ x.IGST_Amount_Input }}</td>
                <td>{{ x.Invoice_Amount_Input }}</td>
                <td>{{ x.Taxable_Amount_Output }}</td>
                <td>{{ x.SGST_Amount_Output }}</td>
                <td>{{ x.CGST_Amount_Output }}</td>
                <td>{{ x.IGST_Amount_Output }}</td>
                <td>{{ x.Invoice_Amount_Output }}</td>
              </tr>

              <tfoot>
                <tr>
                  <td
                    colspan="7"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  ></td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("Taxable_Amount_Input") }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("SGST_Amount_Input") }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("CGST_Amount_Input") }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("IGST_Amount_Input") }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("Invoice_Amount_Input") }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ totaltypewise("Taxable_Amount_Output") }}
                    </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("SGST_Amount_Output") }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("CGST_Amount_Output") }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ totaltypewise("IGST_Amount_Output") }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ totaltypewise("Invoice_Amount_Output") }}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="TabDisabled">
          <ng-template mat-tab-label
            ><span>GST Input Details</span></ng-template
          >

          <ng-template matTabContent>
            <div style="margin-top: 10px">
              <app-mat-spinner-overlay *ngIf="showSpinner">
              </app-mat-spinner-overlay>
              <form
                #df="ngForm"
                novalidate
                (keydown.enter)="OnSearchGSTDetailInput()"
                class="formborder"
              >
                <div
                  class="row m-0 col-md-12 p-0"
                  style="display: flex; align-items: center"
                >
                  <div class="col-md-2">
                    <span> From Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="pickerInput"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="FromDateInput"
                        id="FromDateInput"
                        class="form-control input-text-css"
                        [(ngModel)]="FromDateInput"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerInput"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerInput></mat-datepicker>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <span> To Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="pickerInput2"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="ToDateInput"
                        id="ToDateInput"
                        class="form-control input-text-css"
                        [(ngModel)]="ToDateInput"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerInput2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerInput2></mat-datepicker>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <span class="required-lable"> Account Name </span>
                    <select
                      name="AccountNameDropdownInput"
                      id="AccountNameDropdownInput"
                      [(ngModel)]="AccountNameDropdownInput"
                      class="form-control input-text-css"
                    >
                      <option value="0">All</option>
                      <option
                        *ngFor="let accountName of accountList"
                        [value]="accountName.Id"
                      >
                        {{ accountName.Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-1">
                    <button
                      type="button"
                      (click)="OnSearchGSTDetailInput()"
                      class="mt-3 btn font-size-12 button-btn"
                    >
                      Search
                    </button>
                  </div>

                  <div class="col-md-4 ml-4">
                    <form action="" class="search-text">
                      <div class="bg-light rounded rounded-pill shadow-sm">
                        <div class="input-group">
                          <input
                            type="text"
                            id="FilterInputSearch"
                            (keyup)="applyFilterInput($event.target.value)"
                            name="FilterInputSearch"
                            placeholder="What're you searching for?"
                            class="form-control border-0 bg-light"
                          />
                          <div class="input-group-append">
                            <button
                              id="button-addon1"
                              type="submit"
                              class="btn btn-link text-primary"
                            >
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>

              <div class="mt-3" [hidden]="!DataSourceInput">
                <div class="">
                  <div class="table-responsive">
                    <mat-table
                      [dataSource]="DataSourceInput"
                      matSort
                      matTableExporter
                      #exporter="matTableExporter"
                      style="max-width: 100%; overflow: auto"
                    >
                      <ng-container matColumnDef="Id">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="max-width: 50px"
                          >#</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell mw50"
                        >
                          {{ i + 1 }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          style="max-width: 57px"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceDate">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice Date</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceDate }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="LoanacNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Loan No/App No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.LoanacNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="PartyName">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Party Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.PartyName }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Acc_GSTNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >GST No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Acc_GSTNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Account_Name">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Account Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Account_Name }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                          >Total</mat-footer-cell
                        >
                      </ng-container>

                      <ng-container matColumnDef="HSN_Code">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >HSN Code</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.HSN_Code }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Taxable_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Taxable</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Taxable_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseInput("Taxable_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="SGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >SGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.SGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseInput("SGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="CGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >CGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.CGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseInput("CGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="IGST_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >IGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.IGST_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseInput("IGST_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Invoice_Amount_Input">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >Invoice</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Invoice_Amount_Input }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseInput("Invoice_Amount_Input") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >View Detail</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell text-center"
                        >
                          <!--<app-lms-voucher-view-model
        [voucherid]="row.InvoiceNo"
        [index]="i"
      >-->
                          <i
                            class="fa fa-eye mr-1"
                            (click)="goToDetailModelEye(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>
                          <!--</app-lms-voucher-view-model>-->

                          <i
                            class="fa fa-print mr-1"
                            (click)="goToDetailModel(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="header-row-first">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="8"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Particulars
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="header-row-second">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="5"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Amount Input
                        </th>
                      </ng-container>

                      <ng-container matColumnDef="header-row-third">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="1"
                        >
                          &nbsp;
                        </th>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          [
                            'header-row-first',
                            'header-row-second',
                            'header-row-third'
                          ];
                          sticky: true
                        "
                      ></tr>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumnsInput; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumnsInput"
                      ></tr>
                      <tr
                        mat-footer-row
                        class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumnsInput; sticky: true"
                        style="background: #4dc3a3"
                      ></tr>
                    </mat-table>
                    <div class="row m-0 align-items-center">
                      <div class="col-md-4">
                        <button
                          class="mt-3 btn font-size-12 button-btn"
                          (click)="exportToExcelInput()"
                        >
                          <i
                            class="fa fa-file-export mr-1"
                            style="font-size: medium; cursor: pointer"
                          ></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade in"
              id="invoiceBill"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              data-backdrop="false"
              style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
            >
              <div
                class="modal-dialog modal-xl modal-dialog-centered"
                role="document"
              >
                <div
                  cdkDrag
                  cdkDragRootElement=".cdk-overlay-pane"
                  class="modal-content"
                  style="width: 780px"
                >
                  <div
                    cdkDragHandle
                    class="modal-header"
                    style="padding: 2px; border: none"
                  >
                    <h6
                      class="modal-title"
                      id="myModalLabel"
                      name="myModalLabel"
                    >
                      GST Detail
                    </h6>
                    <button
                      type="button"
                      class="close"
                      (click)="OnCloseVoucherModel()"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div id="invoicePrint">
                      <div class="invoice">
                        <div class="header">
                          <h2>Invoice</h2>
                        </div>
                        <div class="company-info">
                          <div class="logo">
                            <img src="{{ CompanyProfileBaseUrl }}logo.png" />
                          </div>
                          <div class="info">
                            <p class="company-name">
                              <strong>{{ gstDetail?.CompanyName }}</strong>
                            </p>
                            <div class="company-details">
                              <span>{{ gstDetail?.CompanyAddress }}</span>
                              <span>GST No.:{{ gstDetail?.CompanyGSTNo }}</span>
                              <span
                                >PAN No.: {{ gstDetail?.ComapnyPanNo }}</span
                              >
                              <span>
                                Phone No.:
                                {{ gstDetail?.CompanyPhoneNo }}
                              </span>
                              <span
                                >Email Id: {{ gstDetail?.ComapnyEmail }}</span
                              >
                            </div>
                          </div>
                          <div class="invoice-no">
                            <span>
                              <strong
                                >Invoice No.: {{ gstDetail?.InvoiceNo }}</strong
                              >
                            </span>
                            <span>
                              <strong>
                                Invoice Date:
                                {{ gstDetail?.InvoiceDate }}
                              </strong>
                            </span>
                          </div>
                        </div>

                        <div class="party-info">
                          <div class="party-details">
                            <span><strong>Invoice From:-</strong></span>
                            <span>{{ gstDetail?.PartyFrom_Name }}</span>
                            <span>{{ gstDetail?.PartyFrom_Address }}</span>
                            <span>
                              {{ gstDetail?.PartyFrom_StateName }}-{{
                                gstDetail?.PartyFrom_PINCode
                              }}
                            </span>
                            <span
                              >GST No.:{{ gstDetail?.PartyFrom_GSTNo }}</span
                            >
                          </div>

                          <div class="invoice-details">
                            <span><strong>Invoice To:-</strong></span>
                            <span>{{ gstDetail?.PartyName }}</span>
                            <span>Address</span>
                            <span>
                              {{ gstDetail?.Party_State }}-{{
                                gstDetail?.PartyPinCode
                              }}
                            </span>
                            <span>GST No.: {{ gstDetail?.Acc_GSTNo }}</span>
                          </div>
                        </div>
                        <table
                          class="invoice-table"
                          *ngIf="JsonData2.length > 0"
                        >
                          <thead>
                            <tr>
                              <th colspan="6" style="text-align: left">
                                Particulars(Descriptions & Specifications)
                              </th>
                              <th style="text-align: center">HSN</th>
                              <th style="text-align: center">Amount</th>
                              <th style="text-align: center">SGST</th>
                              <th style="text-align: center">CGST</th>
                              <th style="text-align: center">IGST</th>
                              <th style="text-align: center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of JsonData2; let i = index">
                              <td style="text-align: left" colspan="6">
                                {{ x.ChargeHeadCategory }}
                              </td>

                              <td></td>
                              <td>{{ x.Amount }}</td>
                              <td>{{ x.SGST }}</td>
                              <td>{{ x.CGST }}</td>
                              <td>{{ x.IGST }}</td>
                              <td>{{ x.Total }}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="6" style="text-align: right">
                                Total:
                              </th>
                              <th></th>
                              <th>{{ totaltypewisemodal("Amount") }}</th>
                              <th>{{ totaltypewisemodal("SGST") }}</th>
                              <th>{{ totaltypewisemodal("CGST") }}</th>
                              <th>{{ totaltypewisemodal("IGST") }}</th>
                              <th>{{ totaltypewisemodal("Total") }}</th>
                            </tr>
                          </tfoot>
                        </table>

                        <div class="Total-Amount">
                          <p>Amount In Word:- {{ AmountInWord }} only</p>
                        </div>

                        <div class="footer">
                          <span>{{ gstDetail?.CompanyName }}</span>
                          <span id="sign"
                            ><strong>Authorised Signatory</strong></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-2 mb-2">
                      <button
                        type="button"
                        class="mt-3 btn font-size-12 button-btn"
                        (click)="PrintDoc('invoicePrint', 'Invoice Print')"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table
              id="gst_details_input"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                display: none;
              "
            >
              <tr>
                <th
                  colspan="8"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Particulars
                </th>
                <th
                  colspan="5"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Amount Input
                </th>
              </tr>
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  #
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice Date
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Loan No/App No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Party Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  GST No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Account Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  HSN Code
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Taxable
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  SGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CGST
                </th>

                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  IGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice
                </th>
              </tr>

              <tr *ngFor="let x of JsonDataInput; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.InvoiceDate }}</td>
                <td>{{ x.InvoiceNo }}</td>
                <td>{{ x.LoanacNo }}</td>
                <td>{{ x.PartyName }}</td>
                <td>{{ x.Acc_GSTNo }}</td>
                <td>{{ x.Account_Name }}</td>
                <td>{{ x.HSN_Code }}</td>
                <td>{{ x.Taxable_Amount_Input }}</td>
                <td>{{ x.SGST_Amount_Input }}</td>
                <td>{{ x.CGST_Amount_Input }}</td>
                <td>{{ x.IGST_Amount_Input }}</td>
                <td>{{ x.Invoice_Amount_Input }}</td>
              </tr>

              <tfoot>
                <tr>
                  <td
                    colspan="7"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  ></td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseInput("Taxable_Amount_Input")
                    }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseInput("SGST_Amount_Input")
                    }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseInput("CGST_Amount_Input")
                    }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseInput("IGST_Amount_Input")
                    }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseInput("Invoice_Amount_Input")
                    }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="TabDisabled">
          <ng-template mat-tab-label
            ><span>GST Output Details</span></ng-template
          >

          <ng-template matTabContent>
            <div style="margin-top: 10px">
              <app-mat-spinner-overlay *ngIf="showSpinner">
              </app-mat-spinner-overlay>
              <form
                #df="ngForm"
                novalidate
                (keydown.enter)="OnSearchGSTDetailOutput()"
                class="formborder"
              >
                <div
                  class="row m-0 col-md-12 p-0"
                  style="display: flex; align-items: center"
                >
                  <div class="col-md-2">
                    <span> From Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="pickerOuput"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="FromDateOutput"
                        id="FromDateOutput"
                        class="form-control input-text-css"
                        [(ngModel)]="FromDateOutput"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerOuput"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerOuput></mat-datepicker>
                    </div>
                    <!-- <input
                      name="FromDate"
                      [(ngModel)]="FromDate"
                      id="FromDate"
                      placeholder="From Date"
                      class="form-control input-text-css"
                      bsDatepicker
                      (ngModelChange)="DateChange()"
                    /> -->
                  </div>
                  <div class="col-md-2">
                    <span> To Date </span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="pickerOutput2"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="ToDateOutput"
                        id="ToDateOutput"
                        class="form-control input-text-css"
                        [(ngModel)]="ToDateOutput"
                        (dateChange)="DateChange()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerOutput2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerOutput2></mat-datepicker>
                    </div>
                    <!-- <input
                      name="ToDate"
                      [(ngModel)]="ToDate"
                      id="ToDate"
                      placeholder="To Date"
                      class="form-control input-text-css"
                      bsDatepicker
                      (ngModelChange)="DateChange()"
                    /> -->
                  </div>

                  <div class="col-md-2">
                    <span class="required-lable"> Account Name </span>
                    <select
                      name="AccountNameDropdownOutput"
                      id="AccountNameDropdownOutput"
                      [(ngModel)]="AccountNameDropdownOutput"
                      class="form-control input-text-css"
                    >
                      <option value="0">All</option>
                      <option
                        *ngFor="let accountName of accountList"
                        [value]="accountName.Id"
                      >
                        {{ accountName.Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-1">
                    <button
                      type="button"
                      (click)="OnSearchGSTDetailOutput()"
                      class="mt-3 btn font-size-12 button-btn"
                    >
                      Search
                    </button>
                  </div>

                  <div class="col-md-4 ml-4">
                    <form action="" class="search-text">
                      <div class="bg-light rounded rounded-pill shadow-sm">
                        <div class="input-group">
                          <input
                            type="text"
                            id="FilterInputSearch"
                            (keyup)="applyFilterOutput($event.target.value)"
                            name="FilterInputSearch"
                            placeholder="What're you searching for?"
                            class="form-control border-0 bg-light"
                          />
                          <div class="input-group-append">
                            <button
                              id="button-addon1"
                              type="submit"
                              class="btn btn-link text-primary"
                            >
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>

              <div class="mt-3" [hidden]="!DataSourceOutput">
                <div class="">
                  <div class="table-responsive">
                    <mat-table
                      [dataSource]="DataSourceOutput"
                      matSort
                      matTableExporter
                      #exporter="matTableExporter"
                      style="max-width: 100%; overflow: auto"
                    >
                      <ng-container matColumnDef="Id">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="max-width: 50px"
                          >#</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell mw50"
                        >
                          {{ i + 1 }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          style="max-width: 57px"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceDate">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice Date</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceDate }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="InvoiceNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Invoice No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.InvoiceNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="LoanacNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Loan No/App No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.LoanacNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>
                      <ng-container matColumnDef="PartyName">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Party Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.PartyName }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Acc_GSTNo">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >GST No</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Acc_GSTNo }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Account_Name">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Account Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Account_Name }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                          >Total</mat-footer-cell
                        >
                      </ng-container>

                      <ng-container matColumnDef="HSN_Code">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >HSN Code</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.HSN_Code }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Taxable_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >Taxable</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Taxable_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseOutput("Taxable_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="SGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>SGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.SGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="SGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >SGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.SGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseOutput("SGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="CGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.CGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="CGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >CGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.CGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseOutput("CGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="IGST_Rate_Output">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>IGST Rate Output</mat-header-cell>
    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.IGST_Rate_Output}}</mat-cell>
    <mat-footer-cell *matFooterCellDef class="text-left grid-header"></mat-footer-cell>
  </ng-container> -->

                      <ng-container matColumnDef="IGST_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >IGST</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.IGST_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseOutput("IGST_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Invoice_Amount_Output">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          style="border-right: 1px #f7f9f9 solid"
                          >Invoice</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">
                          {{ row.Invoice_Amount_Output }}
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        >
                          {{ totaltypewiseOutput("Invoice_Amount_Output") }}
                        </mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          *matHeaderCellDef
                          >View Detail</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell text-center"
                        >
                          <!--<app-lms-voucher-view-model
        [voucherid]="row.InvoiceNo"
        [index]="i"
      >-->
                          <i
                            class="fa fa-eye mr-1"
                            (click)="goToDetailModelEye(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>
                          <!--</app-lms-voucher-view-model>-->

                          <i
                            class="fa fa-print mr-1"
                            (click)="goToDetailModel(row)"
                            style="
                              cursor: pointer;
                              color: green;
                              font-size: 16px;
                            "
                            aria-label="true"
                          ></i>

                          <!-- <i
        class="fa fa-arrow-circle-right"
        (click)="goToDetailModel(row)"
        style="cursor: pointer; color: green; font-size: 16px"
        aria-label="true"
      ></i> -->
                        </mat-cell>
                        <mat-footer-cell
                          *matFooterCellDef
                          class="text-left grid-header"
                        ></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="header-row-first">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="8"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Particulars
                        </th>
                      </ng-container>
                      <ng-container matColumnDef="header-row-second">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="5"
                          style="border-right: 1px #f7f9f9 solid"
                        >
                          Amount Output
                        </th>
                      </ng-container>

                      <ng-container matColumnDef="header-row-third">
                        <th
                          mat-header-cell
                          class="grid-header"
                          *matHeaderCellDef
                          [attr.colspan]="1"
                        >
                          &nbsp;
                        </th>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          [
                            'header-row-first',
                            'header-row-second',
                            'header-row-third'
                          ];
                          sticky: true
                        "
                      ></tr>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumnsOutput; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumnsOutput"
                      ></tr>
                      <tr
                        mat-footer-row
                        class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumnsOutput; sticky: true"
                        style="background: #4dc3a3"
                      ></tr>
                    </mat-table>
                    <div class="row m-0 align-items-center">
                      <div class="col-md-4">
                        <button
                          class="mt-3 btn font-size-12 button-btn"
                          (click)="exportToExcelOutput()"
                        >
                          <i
                            class="fa fa-file-export mr-1"
                            style="font-size: medium; cursor: pointer"
                          ></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade in"
              id="invoiceBill"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              data-backdrop="false"
              style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
            >
              <div
                class="modal-dialog modal-xl modal-dialog-centered"
                role="document"
              >
                <div
                  cdkDrag
                  cdkDragRootElement=".cdk-overlay-pane"
                  class="modal-content"
                  style="width: 780px"
                >
                  <div
                    cdkDragHandle
                    class="modal-header"
                    style="padding: 2px; border: none"
                  >
                    <h6
                      class="modal-title"
                      id="myModalLabel"
                      name="myModalLabel"
                    >
                      GST Detail
                    </h6>
                    <button
                      type="button"
                      class="close"
                      (click)="OnCloseVoucherModel()"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div id="invoicePrint">
                      <div class="invoice">
                        <div class="header">
                          <h2>Invoice</h2>
                        </div>
                        <div class="company-info">
                          <div class="logo">
                            <!-- Your company logo here -->
                            <img src="{{ CompanyProfileBaseUrl }}logo.png" />
                          </div>
                          <div class="info">
                            <p class="company-name">
                              <strong>{{ gstDetail?.CompanyName }}</strong>
                            </p>
                            <div class="company-details">
                              <span>{{ gstDetail?.CompanyAddress }}</span>
                              <span>GST No.:{{ gstDetail?.CompanyGSTNo }}</span>
                              <span
                                >PAN No.: {{ gstDetail?.ComapnyPanNo }}</span
                              >
                              <span>
                                Phone No.:
                                {{ gstDetail?.CompanyPhoneNo }}
                              </span>
                              <span
                                >Email Id: {{ gstDetail?.ComapnyEmail }}</span
                              >
                            </div>
                          </div>
                          <div class="invoice-no">
                            <span>
                              <strong
                                >Invoice No.: {{ gstDetail?.InvoiceNo }}</strong
                              >
                            </span>
                            <span>
                              <strong>
                                Invoice Date:
                                {{ gstDetail?.InvoiceDate }}
                              </strong>
                            </span>
                          </div>
                        </div>

                        <!-- <hr /> -->
                        <div class="party-info">
                          <div class="party-details">
                            <span><strong>Invoice From:-</strong></span>
                            <span>{{ gstDetail?.PartyFrom_Name }}</span>
                            <span>{{ gstDetail?.PartyFrom_Address }}</span>
                            <span>
                              {{ gstDetail?.PartyFrom_StateName }}-{{
                                gstDetail?.PartyFrom_PINCode
                              }}
                            </span>
                            <span
                              >GST No.:{{ gstDetail?.PartyFrom_GSTNo }}</span
                            >
                          </div>
                          <!-- <div class="invoice-details">
                            <p><strong>Invoice No.: INV123</strong></p>
                            <p><strong>Invoice Date: 2023-07-07</strong></p>
                          </div> -->
                          <div class="invoice-details">
                            <span><strong>Invoice To:-</strong></span>
                            <span>{{ gstDetail?.PartyName }}</span>
                            <span>Address</span>
                            <span>
                              {{ gstDetail?.Party_State }}-{{
                                gstDetail?.PartyPinCode
                              }}
                            </span>
                            <span>GST No.: {{ gstDetail?.Acc_GSTNo }}</span>
                          </div>
                        </div>
                        <table
                          class="invoice-table"
                          *ngIf="JsonData2.length > 0"
                        >
                          <thead>
                            <tr>
                              <th colspan="6" style="text-align: left">
                                Particulars(Descriptions & Specifications)
                              </th>
                              <th style="text-align: center">HSN</th>
                              <th style="text-align: center">Amount</th>
                              <th style="text-align: center">SGST</th>
                              <th style="text-align: center">CGST</th>
                              <th style="text-align: center">IGST</th>
                              <th style="text-align: center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of JsonData2; let i = index">
                              <td style="text-align: left" colspan="6">
                                {{ x.ChargeHeadCategory }}
                              </td>

                              <td></td>
                              <td>{{ x.Amount }}</td>
                              <td>{{ x.SGST }}</td>
                              <td>{{ x.CGST }}</td>
                              <td>{{ x.IGST }}</td>
                              <td>{{ x.Total }}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="6" style="text-align: right">
                                Total:
                              </th>
                              <th></th>
                              <th>{{ totaltypewisemodal("Amount") }}</th>
                              <th>{{ totaltypewisemodal("SGST") }}</th>
                              <th>{{ totaltypewisemodal("CGST") }}</th>
                              <th>{{ totaltypewisemodal("IGST") }}</th>
                              <th>{{ totaltypewisemodal("Total") }}</th>
                            </tr>
                          </tfoot>
                        </table>

                        <div class="Total-Amount">
                          <p>Amount In Word:- {{ AmountInWord }} only</p>
                        </div>

                        <div class="footer">
                          <span>{{ gstDetail?.CompanyName }}</span>
                          <span id="sign"
                            ><strong>Authorised Signatory</strong></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-2 mb-2">
                      <button
                        type="button"
                        class="mt-3 btn font-size-12 button-btn"
                        (click)="PrintDoc('invoicePrint', 'Invoice Print')"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table
              id="gst_details_Output"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                display: none;
              "
            >
              <tr>
                <th
                  colspan="8"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Particulars
                </th>

                <th
                  colspan="5"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: center;
                  "
                >
                  Amount Output
                </th>
              </tr>
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  #
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice Date
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Loan No/App No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Party Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  GST No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Account Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  HSN Code
                </th>

                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Taxable
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  SGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CGST
                </th>

                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  IGST
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Invoice
                </th>
              </tr>

              <tr *ngFor="let x of JsonDataOutput; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.InvoiceDate }}</td>
                <td>{{ x.InvoiceNo }}</td>
                <td>{{ x.LoanacNo }}</td>
                <td>{{ x.PartyName }}</td>
                <td>{{ x.Acc_GSTNo }}</td>
                <td>{{ x.Account_Name }}</td>
                <td>{{ x.HSN_Code }}</td>

                <td>{{ x.Taxable_Amount_Output }}</td>
                <td>{{ x.SGST_Amount_Output }}</td>
                <td>{{ x.CGST_Amount_Output }}</td>
                <td>{{ x.IGST_Amount_Output }}</td>
                <td>{{ x.Invoice_Amount_Output }}</td>
              </tr>

              <tfoot>
                <tr>
                  <td
                    colspan="7"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  ></td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ totaltypewiseOutput("Taxable_Amount_Output") }}
                    </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseOutput("SGST_Amount_Output")
                    }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseOutput("CGST_Amount_Output")
                    }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{
                      totaltypewiseOutput("IGST_Amount_Output")
                    }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ totaltypewiseOutput("Invoice_Amount_Output") }}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="TabDisabled">
          <ng-template mat-tab-label><span>GST Details 3</span></ng-template>

          <div>GST Details 3</div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>

<div
  class="modal fade in"
  tabindex="-1"
  id="VoucherDetailView"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div
          cdkDragHandle
          class="modal-header"
          style="padding: 2px; border: none"
        >
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Voucher Detail
          </h6>
          <button
            type="button"
            (click)="OnCloseVoucherViewModel()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 text-left">
            <div class="col-md-12 bg-success1">
              Voucher Type -
              <span style="color: #333">
                {{ voucherModel.Voucher.Voucher_Type }} -
                {{ voucherModel.Voucher.Voucher_Sub_Type }}
              </span>
            </div>
          </div>
          <div class="row m-0 mt-1 text-left">
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Voucher No : </B>
              {{ voucherModel.Voucher.Voucher_No }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Voucher Date : </B>
              {{ voucherModel.Voucher.Voucher_Date }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">ReceiptNo Book : </B>
              {{ voucherModel.Voucher.Voucher_ReceiptNo_Book }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Receipt Date : </B>
              {{ voucherModel.Voucher.Voucher_ReceiptDate }}
            </div>
          </div>

          <div
            class="row m-0 mt-2 text-left"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != null"
          >
            <div class="col-md-12 bg-success1">
              Mode of Payment -
              <span style="color: #333">
                {{ voucherModel.Voucher.Voucher_Mode_of_Payment }}
              </span>
            </div>
          </div>
          <div class="row m-0 mt-1 text-left">
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque No : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_No }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque Date : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_Date }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Bank Name : </B
              >{{ voucherModel.Voucher.Voucher_Bank_Name }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque Clear Date : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_Clear_Date }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="
                voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != null
              "
              style="color: black"
            >
              <B class="fs-12">Ref No : </B
              >{{ voucherModel.Voucher.Voucher_E_Ref_No }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="
                voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != null
              "
              style="color: black"
            >
              <B class="fs-12">Transaction Date : </B
              >{{ voucherModel.Voucher.Voucher_E_Transaction_Date }}
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1">
              <div
                class="table-responsive"
                *ngIf="
                  voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                  voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                "
              >
                <mat-table
                  [dataSource]="voucherModel.Voucher_Detail"
                  matSort
                  matTableExporter
                >
                  <ng-container matColumnDef="Type">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 5%"
                      >Type</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 5%"
                      >{{ row.TranType }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 5%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="CaseNo">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 15%"
                      >Case No</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 15%"
                      >{{ row.CaseNo }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 15%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Customer</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.Customer }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 10%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Branch</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.Branch_Name }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 10%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Category">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 20%"
                      >Category</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 20%"
                      >{{ row.ChagesHead }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 20%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AccountHead">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 20%"
                      >Account Head</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 20%"
                      >{{ row.Account_Name }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 20%"
                      >Total</mat-footer-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Amount(DR)</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.AmountDR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 10%">
                      {{ calculateTranTypeDR() }}
                    </mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Amount(CR)</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.AmountCR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 10%">
                      {{ calculateTranTypeCR() }}
                    </mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedLoanColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedLoanColumns"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedLoanColumns"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1">
              <div
                class="table-responsive"
                *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'"
              >
                <mat-table
                  [dataSource]="voucherModel.Voucher_Detail"
                  matSort
                  matTableExporter
                >
                  <ng-container matColumnDef="AccountHead">
                    <mat-header-cell class="grid-header text-left" *matHeaderCellDef
                      >Account Head</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Account_Name }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch">
                    <mat-header-cell class="grid-header branchwidth" *matHeaderCellDef
                      >Branch</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell branchwidth">
                      {{ row.Branch_Name }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(DR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.AmountDR }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>
                      {{ calculateTranTypeDR() }}
                    </mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(CR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.AmountCR }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>
                      {{ calculateTranTypeCR() }}
                    </mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedAccountingColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedAccountingColumns"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedAccountingColumns"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0 mt-2 text-left">
            <div class="col-md-3 p-1">
              <h6 class="fs-12">Voucher Created By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_CreateBy }}
              </p>
            </div>
            <!--<div class="col-md-3 p-1">
              <h6 class="fs-12">Voucher Created On</h6>
              <p class="lead fs-12">{{voucherModel.Voucher.Voucher_CreateOn}}</p>
            </div>-->
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizBy != null"
            >
              <h6 class="fs-12">Voucher Authoriz By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizBy }}
              </p>
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizOn != null"
            >
              <h6 class="fs-12">Voucher Authoriz On</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizOn }}
              </p>
            </div>
            <div class="col-md-6 p-1">
              <h6 class="fs-12">Voucher Narration</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_Narration }}
              </p>
            </div>
          </div>

          <div class="row m-0 mt-2" *ngIf="docModal.length > 0">
            <div class="col-md-12" style="text-align: left">
              <h6 class="fs-12">Attachments</h6>
              <div *ngFor="let doc of docModal; let i = index">
                <a
                  href="{{ AccountDocUrl }}/{{ doc.DocFileName }}"
                  class="ml-3"
                  target="_blank"
                  >{{ doc.DocFileName }}</a
                >
                <!--<i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ doc.Doc_path }}-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
