import { Component, OnInit, ViewChild, EventEmitter, Input, Output, Injectable, ChangeDetectorRef } from '@angular/core';
import { LmsService } from "../services/lms.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LosService } from '../../_LOS/services/los.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
import { MatSort } from "@angular/material/sort";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { constantUrl } from '../../Shared/constantUrl';
declare var $: any;
import { ToWords } from 'to-words';
@Component({
  selector: 'app-release-authorized-list',
  templateUrl: './release-authorized-list.component.html',
  styleUrls: ['./release-authorized-list.component.scss']
})
export class ReleaseAuthorizedListComponent implements OnInit {
  showSpinner: boolean = false;
  StatusRl: any = 'Release Request';
  loading: boolean = false;
  dataSource: any = '';
  Customer: any = {};
  RepoForm: any = { AssetsId: '', ParkingYardId: '', RepossedBy: '', RepossedOn: new Date() };
  ReleaseForm: any = { Repossessed_Id: 0, Type: 'OD', ReleaseAmount: '', ReleaseBy: '', ReleaseOn: new Date(), Remark: '', Status:'' };
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorlist', { read: MatPaginator }) paginatorlist: MatPaginator;
  displayedColumns: any = [];
  currentUser: any = '';
  CustomerList: any[] = [];
  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  AssetsAttachment: any = [{}];
  assetModel: any = {};
  displayedAssetItemColumns: string[] = [];
  dataAssetItemSource: any;
  assetTotal: any;
  customerDataSource: any;
  ApplicationDetail: any = {};
  displayedCustomerColumns: string[] = [];
  EMIDetails: any;
  dataSourceCustomer: any;
  displayedAssetsColumns: any[] = [];
  dataSourceAssets: any;
  IRR_CalculateBy: any;
  Current_Voucher: any;
  totalCalculator: any;
  CustomerType: any = "0";
  totalRepossessCalculator: any;
  Bind_Voucher: any = 1;
  stepEMI: any[] = [];
  GSTModel: any = {};
  InstallmentStopInReposses: any = 0;
  stepEmiData: any;
  IsGSTEnable: any;
  AccountHeadForGST: any[] = [];
  stateDropdown: any[] = [];
  slabList: any[] = [];
  AssetsList: any[] = [];
  PartnerList: any;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  RepossessModel: any = { Voucher: {}, Voucher_Detail: [] };
  AllowExtension: any = constantUrl.AllowExtension;
  SelectType: any = '';
  docModal: any = { DocumentImages: [] };
  today: Date = new Date();

  displayedCustomerDetailColumns: string[] = [
    "CustomerId",
    "Customer",
    "CustomerType",
    "GenderAge",
    "ExistingCustomer",
    // "Customer_IsFirm",
    "Relation_With_Hirer",
    "PhoneNo",
    "Present_Address"
  ];
  @ViewChild(MatSort) sort: MatSort;
  displayedColumnsODCharges: any = [];
  ChargesHeadList: any[] = [];
  RepossessedStock: any[] = [];
  dataSourceLoanODCharges: any;
  Tax_Slab_Data: any[] = [];
  FileCheckingList: any[] = [];
  ParkingYardList: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private LmsService: LmsService,
    private _MasterService: MasterService,
    private losService: LosService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private dataSharingService: DataSharingService, private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Release Authorize List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.repossessList();
    this.ReleaseForm.ReleaseBy = this.currentUser.userId;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  repossessList() {
    this.dataSource = null;
    this.GetStateDropdown();
    // if (this.StatusRl == "Pending") {
    this.showSpinner = false;
    this.LmsService.LMS_List_Of_Repossess({ LoginUserId: this.currentUser.userId, Status: this.StatusRl })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedColumns = ['Repossessed_Id', 'LoanAcNo', 'LoanAmount', 'Customer_Name', 'VehicleRegistationNo', 'Vehicle_Model', 'Parking_Name', 'LoanOutStanding', 'Released_RequestBy', 'Action'];

          console.log(res)
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          this.showSpinner = false;
        }
      });
    /* } else { }*/

  }
  goToReposses(row) {
    this.voucherModel = { Voucher: {}, Voucher_Detail: [] };
    this.getPartnerList();
    this.cdr.detectChanges();
    this.getParkingYardList();
    this.Get_TaxSlab_Dropdown();
    this.cdr.detectChanges();
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: row.Loan_Id }).subscribe((res: any) => {
      console.log("res :", res);
      this.showSpinner = false;
      this.ApplicationDetail = res.Item1[0];
      this.InstallmentStopInReposses = res.Item1[0].InstallmentStopInReposses;
      this.customerDataSource = new MatTableDataSource(res.Item2);
      this.EMIDetails = res.Item7[0];
      this.displayedAssetsColumns = ['Collateral_Type', 'Collateral', 'ValuationType', 'ValuationAmount', 'ValuationDate', 'ActionView'];
      this.dataSourceAssets = new MatTableDataSource(res.Item6);
      this.RepoForm.RepossedOn = new Date(row.Repossessed_on);
      this.RepoForm.RepossedBy = row.Repossessed_By;
      this.RepoForm.ParkingYardId = row.Parking_Yard;
      this.RepoForm.AssetsId = row.AssetName;
      this.RepoForm.AssetsValue = row.LoanOutStanding;
      this.ReleaseForm.ReleaseAmount = row.LoanOutStanding;
      this.ReleaseForm.Repossessed_Id = row.Repossessed_Id;
      this.ReleaseForm.Type = row.Type;
      this.ReleaseForm.ReleaseAmount = row.ReleaseAmount;
      this.ReleaseForm.ReleaseOn = new Date(row.ReleaseOn);

      this.StepEmiDetail(row.Loan_Id);
      this.initializeVoucher();
      this.initializeVoucherDetail();
      // this.getAssetsList();
    

    });

  }
  StepEmiDetail(LoanId) {
    this.LmsService.FinancialDetailsForUpdate({ Loan_Id: LoanId }).subscribe((res: any) => {
      this.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
      res.Item2.forEach((obj: any) => {
        this.stepEMI.push({
          FromEMI: obj.FromEMI,
          ToEMI: obj.ToEMI,
          EMI_Amount: obj.EMIAmount,
          NoOfInstl: obj.NoOfEMI,
          TotalAmount: obj.TotalAmount,
        });
      });
      this.getTotlaStepEmiAndAmount();
    });
  }
  getTotlaStepEmiAndAmount() {
    let TotalEmi: any = 0, TotalAmount: any = 0;
    for (let i = 0; i < this.stepEMI.length; i++) {
      if (this.stepEMI[i].EMI_Amount) {
        TotalEmi = TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
        TotalAmount = TotalAmount + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) * +this.stepEMI[i].EMI_Amount;
      }
    }
    this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
  }
  onCloseAddRepossesModel() {
    this.RepoForm = { AssetsId: '', ParkingYardId: '', RepossedBy: '', RepossedOn: new Date() }
    this.ReleaseForm = { Repossessed_Id: 0, Type: 'OD', ReleaseAmount: '', ReleaseBy: this.currentUser.userId, ReleaseOn: new Date(), Remark: '', Status:'' }
    $('#AddReposses').modal('hide');
  }
  onChangeAmount(i: any, data: any) {
    this.Current_Voucher = i;

    if (data.IsGSTEnable == 1 && data.TaxSlab != '') {

      this.GSTModel = {
        Slab_Tax: '',
        AccountHeadId: '',
        PartyType: 'Existing_Party',
        Customer: this.ApplicationDetail.Customer,
        Acc_GSTNo: this.ApplicationDetail.GST_No,
        StateId: this.ApplicationDetail.StateId,
        Charge_head: data.ChargeHeadCategory,
        Amount: data.Amount,
        TranType: data.TranType,
      }
      this.Get_AccountForGST_Dropdown();


      //this.Tax_Slab_Data = null;
      $("#GSTModalWindow").modal("show");
      $("#GSTModalWindow").css("z-index", "1050");

    }


    this.getTotalCalculation();
  }

  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount == '' ? 0 : obj.Amount) || 0;
      }
      else {
        _totalDR = _totalCR + parseFloat(obj.Amount == '' ? 0 : obj.Amount) || 0;
      }
      if (obj.TaxSlab1.length > 0) {
        obj.TaxSlab1.forEach((tobj: any) => {
          if (obj.TranType == "CR") {
            _totalCR = _totalCR + parseFloat(tobj.TaxAmount == '' ? 0 : tobj.TaxAmount) || 0;
            _totalCRTX = _totalCRTX + parseFloat(tobj.TaxAmount == '' ? 0 : tobj.TaxAmount) || 0;
          }
          else {
            _totalDR = _totalCR + parseFloat(tobj.TaxAmount == '' ? 0 : tobj.TaxAmount) || 0;
            _totalDRTX = _totalDRTX + parseFloat(tobj.TaxAmount == '' ? 0 : tobj.TaxAmount) || 0;
          }
        });
      }
    });
    this.totalCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalCR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalCR))
    }
    this.voucherModel.Voucher_Detail[1].Amount = this.totalCalculator.totalCR;
  }

  initializeVoucher() {

    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Journal',
      Voucher_Sub_Type: 'Loan',
      Voucher_Tag: '',
      Voucher_Date: this.RepoForm.RepossedOn,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId
    };
    setTimeout(() => {
      this.GetVoucherNo();
      //this.Get_Acc_Accounts_By_Tag();
    }, 500)
  }
  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail = [];

    this.GSTModel = {
      Slab_Tax: '',
      AccountHeadId: '',
      PartyType: 'Existing_Party',
      Customer: this.ApplicationDetail.Customer,
      Acc_GSTNo: this.ApplicationDetail.GST_No,
      StateId: this.ApplicationDetail.StateId

    }
    this.Tax_Slab_Data = [];
    this.LmsService.Get_After_Reposess_Balance({
      productId: this.ApplicationDetail.ProductId, LoanId: this.ApplicationDetail.Loan_Id, ToDate: this.RepoForm.RepossedOn
    }).subscribe((res: any) => {
      if (res.Item1.length > 0 && res.Item1.find((tt: any) => { return tt.ChargeHeadId == 82 })) {

        if (res.Item3.length > 0) {
          this.displayedColumnsODCharges = ["ChagesHead", "TranTypeDR", "TranTypeCR", "TranTypeTotal"];
          this.ChargesHeadList = res.Item2;
          this.RepossessedStock = res.Item3;
          this.dataSourceLoanODCharges = new MatTableDataSource(JSON.parse(JSON.stringify(res.Item2)));
          this.dataSourceLoanODCharges.sort = this.sort;
          res.Item1.forEach((obj: any) => {

            this.voucherModel.Voucher_Detail.push({
              VoucherId: 0,
              CaseNo: this.ApplicationDetail.LoanAcNo,
              CustomerId: this.ApplicationDetail.CustomerId,
              Is_LOSAppliaction: "0",
              ChargeHead: obj.ChagesHead,
              ChargeHeadId: obj.ChargeHeadId,
              ChargeHeadCategory: obj.Account_Name,
              AccountId: obj.AccountId,
              BranchId: "",
              TranType: obj.TranType,
              IsTax: 0,
              Tax_Per: 0,
              Amount: obj.Amount,
              TaxSlab1: [],
              GSTModel: {},
              Bind_Voucher: this.Bind_Voucher,
              Type: "",
              IsGSTEnable: obj.IsGSTEnable
            });
            this.Bind_Voucher = this.Bind_Voucher + 1;
          });
          this.Tax_Slab_Data = [];
          this.voucherModel.Voucher_Detail.forEach((obj: any) => {
            if (obj.IsGSTEnable == 1) {
              //this.LmsService.Get_AccountHead_For_VoucherEntry({
              //  HeadId: obj.ChargeHeadId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, ProductId: this.ApplicationDetail.ProductId, TaxType: obj.TranType
              //}).subscribe((res: any) => {
              //  if (res.Item1[0]) {
              //    obj.TaxSlab = res.Item2;

              //this.IsGSTEnable = res.Item1[0].IsGSTEnable;
              let slab_name = this.slabList.filter(item => item.TaxSlab_Rate == 18).length > 0 ? this.slabList.filter(item => item.TaxSlab_Rate == 18)[0].TaxSlab_Name : '';
              this.GSTModel.Slab_Tax = slab_name;
              this.GSTModel.Amount = obj.Amount;
              this.LmsService.Get_TaxSlab_Details({ TaxSlab: slab_name }).subscribe((res1: any) => {
                let Br_StateId, BranchName;

                Br_StateId = this.ApplicationDetail.Branch_StateId;
                BranchName = this.ApplicationDetail.Branch;

                let Tax_Type = this.GSTModel.TranType == "DR" ? "Input" : "Output";
                if (Br_StateId == this.GSTModel.StateId) {
                  this.Tax_Slab_Data = res1.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'With in State');
                }
                else {
                  this.Tax_Slab_Data = res1.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'Inter state');
                }

                this.Tax_Slab_Data.forEach(x => {
                  x.BranchName = BranchName;
                  x.Amount = this.GSTModel.Amount;
                  x.TaxAmount = (Number(this.GSTModel.Amount) * Number(x.TaxSlab_Rate) / 100).toFixed(2);

                  // Search value (SGST) from a string 
                  x.TypeGST = x.TaxSlab_Type.includes('SGST');
                  x.TypeIGST = x.TaxSlab_Type.includes('IGST');
                });


                obj.TaxSlab1 = this.Tax_Slab_Data;

                this.getTotalCalculation();
              });
              //}

              // });
            }
          })
          this.getTotalCalculation();
          // this.saveGSTDetails();
          $('#AddReposses').modal('show');
          $("#AddReposses").css("z-index", "1050");
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'ERROR: Repossessed  Stock Charges Head Not Found. Ask To Admin To Create!!! ', ...this.configSuccess });
          $('#AddReposses').modal('close');
          return;
        }
      } else {

        this.snackBar.openFromComponent(SnackbarComponent, { data: 'ERROR: Seizing Charges Head Not Found. Ask To Admin To Create!!! ', ...this.configSuccess });
        $('#AddReposses').modal('close');
        return;
      }
    })
  }
  GetVoucherNo() {
    this.LmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
    })
  }
  saveGSTDetails() {
    $("#GSTModalWindow").modal("hide");
    //console.log("this.GSTModel", this.GSTModel);
    this.LmsService.Get_TaxSlab_Details({ TaxSlab: this.GSTModel.Slab_Tax }).subscribe((res: any) => {
      let Br_StateId, BranchName;

      Br_StateId = this.ApplicationDetail.Branch_StateId;
      BranchName = this.ApplicationDetail.Branch;

      let Tax_Type = this.GSTModel.TranType == "DR" ? "Input" : "Output";
      if (Br_StateId == this.GSTModel.StateId) {
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'With in State');
      }
      else {
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'Inter state');
      }

      this.Tax_Slab_Data.forEach(x => {
        x.BranchName = BranchName;
        x.Amount = this.GSTModel.Amount;
        x.TaxAmount = (Number(this.GSTModel.Amount) * Number(x.TaxSlab_Rate) / 100).toFixed(2);

        // Search value (SGST) from a string 
        x.TypeGST = x.TaxSlab_Type.includes('SGST');
        x.TypeIGST = x.TaxSlab_Type.includes('IGST');
      });


      //this.voucherModel.Voucher_Detail.forEach(x => {
      //  if (x.IsGSTEnable == 1 && x.Bind_Voucher == this.Bind_Voucher) {
      this.voucherModel.Voucher_Detail[this.Current_Voucher].TaxSlab1 = this.Tax_Slab_Data;
      //  }
      //});
      this.getTotalCalculation();
    });
  }
  OnCloseGSTModal() {

    $("#GSTModalWindow").modal("hide");

    this.voucherModel.Voucher_Detail[this.Current_Voucher].TaxSlab1 = [];
    this.getTotalCalculation();

  }

  Get_AccountForGST_Dropdown() {
    this._MasterService.Get_AccountForGST_Dropdown({ LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.AccountHeadForGST = res;
    });
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  Get_TaxSlab_Dropdown() {
    this.LmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;
      this.slabList = this.slabList.reduce((acc, current) => {
        const x = acc.find(item => item.TaxSlab_Name === current.TaxSlab_Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    })
  }
  onPartyTypeChange() {
    if (this.GSTModel.PartyType == 'New_Party') {
      this.GSTModel = {
        PartyType: this.GSTModel.PartyType,
        Slab_Tax: '',
        AccountHeadId: '',
        Customer: '',
        Acc_GSTNo: '',
        StateId: '',
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
      }
    }
    else {
      this.GSTModel = {
        Slab_Tax: '',
        AccountHeadId: '',
        PartyType: this.GSTModel.PartyType,
        Customer: this.ApplicationDetail.Customer,
        Acc_GSTNo: this.ApplicationDetail.GST_No,
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
        //StateId : this.BranchData.StateId,
      }
    }
  }
  onChangeTax() {
    this.getTotalCalculation();
  }
  calculateChargesTranTypeDR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == 'OD') {
      let sum = 0.00;
      for (let i = 0; i < this.dataSourceLoanODCharges.filteredData.length; i++) {
        sum += Number(this.dataSourceLoanODCharges.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }

    return 0;
  }
  calculateChargesTranTypeCR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == 'OD') {
      let sum = 0.00;
      for (let i = 0; i < this.dataSourceLoanODCharges.filteredData.length; i++) {
        sum += Number(this.dataSourceLoanODCharges.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }

    return 0;
  }
  calculateChargesTranTypeTotal(Type) {
    if (this.dataSourceLoanODCharges != null && Type == 'OD') {
      let sum = 0;
      let DR = this.calculateChargesTranTypeDR('OD')
      let CR = this.calculateChargesTranTypeCR('OD');
      sum = Number(DR) - Number(CR);
     // this.RepoForm.AssetsValue = sum;
    //  this.ReleaseForm.ReleaseAmount = sum;
      if (sum >= 0)
        return sum.toFixed(2).toString() + " DR"
      else
        return sum.toFixed(2).toString().split('-')[1] + " CR"
    }

    return 0;
  }
  //assetsChange() {
  //  if (!(this.InstallmentStopInReposses == 1 && (this.RepoForm.AssetsId.split('-')[1] == 'Primary Collateral' || this.RepoForm.AssetsId.split('-')[1] == 'Primary')))
  //    this.RepoForm.AssetsValue = '';
  //}
  //getAssetsList() {
  //  this.AssetsList = [];
  //  // this.CustomerAssetsList = [{CustomerId: '', Customer:'' }];
  //  this._MasterService.LMS_Assets_List_Not_Repossessed({ Loan_Id: this.ApplicationDetail.Loan_Id }).subscribe((response: any) => {
  //    console.log(response);

  //    for (let i = 0; i < response.length; i++) {
  //      this.AssetsList.push({ AssetId: response[i].AssetId + "-" + response[i].Collateral_Type, AssetName: response[i].AssetName })

  //    }

  //  });
  //}

  getParkingYardList() {

    this._MasterService.Get_ParkingYard_List({})
      .subscribe((res: any) => {
        if (res.length > 0) {

          this.ParkingYardList = res;
        }
      })
  }
  getPartnerList() {
    this.showSpinner = true;
    this.losService.Get_PartnerForDropdown({ PartnerType: 'Seizer' }).subscribe((res: any) => {
      this.PartnerList = JSON.parse(JSON.stringify(res));
      this.showSpinner = false;
    });
  }
  onRelease() {
    if (this.InstallmentStopInReposses == 1 && (this.RepoForm.AssetsId.split('-')[1] == 'Primary Collateral' || this.RepoForm.AssetsId.split('-')[1] == 'Primary')) {
      this.RepossessModel = { Voucher: {}, Voucher_Detail: [] };
    
      console.log("this.RepossessModel.Voucher_Detail", this.RepossessModel.Voucher_Detail);
      $("#RepossesFinalModal").modal("show");
      $("#RepossesFinalModal").css("z-index", "1050");
    }
    else {
      this.dialog.openConfirmDialog(`Are you sure you want to Release ?`)
        .afterClosed().subscribe(dialogResult => {
          if (dialogResult == false) {
            return;
          } else {
            this.saveReleaseDetail();
          }
        })

    }
  }
  OnCloseRepossesFinalModal() {
    $("#RepossesFinalModal").modal("hide");

  }
  saveReleaseDetail() {

    var ReleaseJSON = [];
    ReleaseJSON.push({
      LoginUserId: this.currentUser.userId,
      Remark: this.ReleaseForm.Remark,
      Repossessed_Id: this.ReleaseForm.Repossessed_Id
    })

    this.LmsService.Release_Request_Detail_Save({ ReleaseJson: JSON.stringify({ ReleaseJson: ReleaseJSON }), Status: this.ReleaseForm.Status }).subscribe((res: any) => {

      if (res[0].Code == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });

        this.OnCloseRepossesFinalModal();
        this.onCloseAddRepossesModel();
        this.repossessList();
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,

        });
        this.OnCloseRepossesFinalModal();
        this.onCloseAddRepossesModel();
        this.repossessList();
      }
    })

  }

  OnSaveDoc() {
    for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
      let _err: boolean = false;
      this.LmsService
        .UploadCrcDoc({
          DocName: this.docModal.DocumentImages[i].DocFileName,
          ApplicationNo: this.ApplicationDetail.ApplicationNo,
          DocData: this.docModal.DocumentImages[i].DocData
        })
        .subscribe(
          (res: any) => {
            if ((i + 1) == this.docModal.DocumentImages.length) {

            }
          },
          (err: any) => {
            _err = true;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "File not uploaded.",
              ...this.configSuccess,
            });
          }
        );
      if (_err) {
        break;
      }
    }
  }
  
  AddNewRepossess() {
    this.dataSourceCustomer = null;
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
  }
  goToDetail(row) {
    row.Loan_Id = row.LoanId;
    this._MasterService.LMS_Assets_List_Not_Repossessed({ Loan_Id: row.Loan_Id }).subscribe((response: any) => {
      console.log(response);
      if (response.length > 0) {
        this.goToReposses(row);
        $("#CustomerReceipt").modal("hide");
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No Vehicle found', ...this.configSuccess });
        $("#CustomerReceipt").modal("hide");
      }


    });

  }
  onSearchCustomerDetail() {
    this.showSpinner = true;

    this._MasterService.Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        if (res.length == 1) {
          this.goToDetail(res[0]);
        }
        else {
          this.displayedCustomerColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

          this.CustomerList = JSON.parse(JSON.stringify(res));
          if (this.CustomerType != "0") {
            var type;
            if (this.CustomerType == "1")
              type = "LOS";
            else
              type = "LMS";
            this.CustomerList = this.CustomerList.filter(item => item.Type == type);
          }
          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.dataSourceCustomer.paginator = this.paginator;
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.dataSourceCustomer = null;
      }
      this.showSpinner = false;
    });
  }
  onCloseReceipt() {
    $("#CustomerReceipt").modal("hide");
  }
  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId: '',
      Area: '',
      SubArea: '',
      SalesEx: '',
      CollectionEx: '',
      LoanAccountingCatId: '',
    };
  }
  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("release_authorize_list") as HTMLTableElement;
    const worksheetName = 'RELEASE AUTHORIZE LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  getAssetDetail(data: any, isEdit: boolean) {
    if (data.Collateral == "Vehicle") {
      this.LMS_GetAssetsDetailVehicle(data.AssetId, data.Collateral);
    } else if (data.Collateral == "Property") {
      this.LMS_Get_AssetsDetail_Property(data.AssetId, data.Collateral);
    } else if (data.Collateral == "Gold") {
      this.LMS_GetAssetsDetailGOLD(data.AssetId, data.Collateral);
    }
    //else if (data.Collateral == 'Consumer Durable') {
    //  this.LMS_GetAssetsDetailConsumerDurable();
    //}
  }
  LMS_GetAssetsDetailVehicle(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.LmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        this.AssetsAttachment = res.Item2;
        this.assetModel.Collateral = "Vehicle";
        this.assetModel.MefgYear = this.assetModel.MefgYear; //new Date(this.assetModel.MefgYear);
        if (this.assetModel.RegistationDate) {
          this.assetModel.RegistationDate = new Date(
            this.assetModel.RegistationDate
          );
        }
        if (this.assetModel.RegistationExpiryDate) {
          this.assetModel.RegistationExpiryDate = new Date(
            this.assetModel.RegistationExpiryDate
          );
        }
        if (this.assetModel.RoadTaxUpto) {
          this.assetModel.RoadTaxUpto = new Date(this.assetModel.RoadTaxUpto);
        }
        if (this.assetModel.FitnessUpto) {
          this.assetModel.FitnessUpto = new Date(this.assetModel.FitnessUpto);
        }
        if (this.assetModel.PermitUpto) {
          this.assetModel.PermitUpto = new Date(this.assetModel.PermitUpto);
        }
        if (this.assetModel.InvoiceDate) {
          this.assetModel.InvoiceDate = new Date(this.assetModel.InvoiceDate);
        }
        if (this.assetModel.ValuationDate) {
          this.assetModel.ValuationDate = new Date(
            this.assetModel.ValuationDate
          );
        }
        //this.GetManufactureForDropdown();
        //this.GetVehicleCategoryForDropdown(false);
        //this.GetVehicleModelForDropdown(false);
        //this.getFuelType();
        //this.LOS_GetApplicatonCustomer();
        //this.Get_PartnerForDropdown();
        //this.GetEmployeeDropdown();
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  LMS_Get_AssetsDetail_Property(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.LmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        this.AssetsAttachment = res.Item2;
        this.assetModel.Collateral = "Property";
        if (this.assetModel.GeoLocation) {
          let _loc = this.assetModel.GeoLocation.split(",");
          if (_loc[0]) {
            this.assetModel.Latitude = _loc[0];
          }
          if (_loc[1]) {
            this.assetModel.Longitude = _loc[1];
          }
        }
        //this.LOS_GetApplicatonCustomer();
        //this.GetStateDropdown();
        //this.GetEmployeeDropdown();
        //this.GetDistrickDropdown(false);
        //this.GetTehasilDropdown(false);
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  LMS_GetAssetsDetailGOLD(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.LmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        if (this.assetModel.ValuationDate) {
          this.assetModel.ValuationDate = new Date(
            this.assetModel.ValuationDate
          );
        }
        this.assetModel.Collateral = "Gold";
        //this.GetEmployeeDropdown();
        this.assetModel.Item = {};
        this.assetModel.ItemArr = res.Item2;
        this.displayedAssetItemColumns = [
          "Id",
          "ItemName",
          "ItemType",
          "Qty",
          "PurityCT",
          "GrossWt",
          "StoneWt",
          "OtherWt",
          "ValulationRate_PerCT",
          "ValulationAmount",
        ];
        this.dataAssetItemSource = new MatTableDataSource(
          this.assetModel.ItemArr
        );
        this.getAssetTotal();
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  onClosePrimaryAsset() {
    $("#assetModel").modal("hide");
  }
  getAssetTotal() {
    if (this.assetModel.ItemArr.length == 0) {
      this.assetTotal = undefined;
    } else {
      this.assetTotal = {
        Qty: this.assetModel.ItemArr.length,
        GrossWt: 0,
        StoneWt: 0,
        OtherWt: 0,
      };
      this.assetModel.ItemArr.forEach((obj: any) => {
        this.assetTotal.GrossWt += obj.GrossWt;
        this.assetTotal.StoneWt += obj.StoneWt;
        this.assetTotal.OtherWt += obj.OtherWt;
      });
    }
  }
}
