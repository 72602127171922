import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-mst-department",
  templateUrl: "./mst-department.component.html",
  styleUrls: ["./mst-department.component.scss"],
})
export class MstDepartmentComponent implements OnInit {
  private data: any;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = ["DeptId", "Dept_Name", "EditAction"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DepartmentList: any = [];
  dialogreturn: any;
  DepartmentModel: any = { Dept_Name: "", DeptId: 0 };

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Department Master");
    this.Get_DepartmentMaster();
  }

  Get_DepartmentMaster() {
    this.showSpinner = true;
    this._MasterService.Get_DepartmentMaster({}).subscribe((result) => {
      this.DepartmentList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.DepartmentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddDepartment() {
    this.DepartmentModel = {
      Dept_Name: "",
      DeptId: 0,
      LoginUserId: this.data.userId,
    };
    $("#AddNewDeptModal").modal("show");
    $("#AddNewDeptModal").css("z-index", "1050");
  }

  OnClose() {
    $("#AddNewDeptModal").modal("hide");
  }

  onSaveDepartmentMaster() {
    this._MasterService
      .Save_DepartmentMaster(this.DepartmentModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.Get_DepartmentMaster();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.Get_DepartmentMaster();
          this.OnClose();
        }
      });
  }

  OnEditDepartmentData(selectedRow: any) {
    this.DepartmentModel = {
      Dept_Name: selectedRow.Dept_Name,
      DeptId: selectedRow.DeptId,
      LoginUserId: this.data.userId,
    };
    $("#AddNewDeptModal").modal("show");
    $("#AddNewDeptModal").css("z-index", "1050");
  }

  OnDeleteDeleteData(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Delete_DepartmentMaster({ DeptId: row.DeptId })
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.Get_DepartmentMaster();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
}
