import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { LosService } from "src/app/_LOS/services/los.service";
declare var $: any;

@Component({
  selector: "app-payment-list",
  templateUrl: "./payment-list.component.html",
  styleUrls: ["./payment-list.component.scss"],
})
export class PaymentListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  status: any = "Pending";
  FinalData: any;
  dataSource: any;
  displayedColumns: string[] = [];
  FileCheckingList: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  selectedId: any;

  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private reportsService: ReportsService,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Payment  List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedColumns = [
      "Loan_Id",
      "LoanAcNo",
      "Loan_Date",
      "Branch_Name",
      "Product_Name",
      "BorrowerName",
      "Disbursment_Customer",
      "Bankname",
      "Branch",
      "AccType",

      "Ac",
      "IFSCCode",
      "Disbursment_Amount",
      "DisbursmentDR",
      "DisbursmentCR",
      "Balance",
      "SourceType",
      "SourceName",
    ];
    this.LMS_Pending_Disbursment();
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
      this.LMS_Pending_Disbursment();
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
      this.LMS_Pending_Disbursment();
    } else {
      this.LeadSourceName = "";
      this.LMS_Pending_Disbursment();
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  LMS_Pending_Disbursment() {
    this.showSpinner = true;
    this.reportsService
      .LMS_Pending_Disbursment({
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.FinalData = res;
          this.LMS_Disbursment();
          //console.log("res", res);
          //this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          //this.dataSource.sort = this.sort;
          //this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        }
      });
  }

  LMS_Disbursment() {
    let data = this.FinalData.filter(
      (item) => item.Disbursment_Status == this.status
    );
    this.FileCheckingList = JSON.parse(JSON.stringify(data));
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("payment_list") as HTMLTableElement;
    const worksheetName = "PENDING DISBURSMENT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  totaltypewise(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }
}
