<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  style="height: 270px; max-width: 100%; overflow-y: auto; overflow-x: hidden"
>
  <!-- <div class="row m-0 mt-2">
      <div class="col-md-12 p-1"><h5>Customer Details</h5></div>
  </div> -->

  <div class="row m-0 mt-1">
    <div class="col-md-12 mt-2" style="margin: 0 -18px">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-interval="false"
        data-wrap="false"
      >
        <div class="carousel-item active" *ngIf="customerData.length > 0">
          <div class="row m-0">
            <div
              class="col-md-3 p-1"
              *ngFor="let item of customerData.slice(0, 4); let i = index"
            >
              <div class="card">
                <div class="card-body p-2">
                  <div class="row m-0 mb-3 align-items-center">
                    <div class="col-md-3 p-0">
                      <img style="height: 45px; width: 45px"
                           src="{{ customerProfileBaseUrl }}{{
                          item.CustomerId
                        }}/{{ item.ProfilePic }}"
                           class="user-img rounded-circle img-thumbnail"
                           alt=""
                           onerror="this.onerror=null;this.src='assets/images/default-user.png';" />
                    </div>
                    <div class="col-md-9 p-0">
                      <h6 class="col-md-12 mb-2 fs-12">{{ item.Customer }}</h6>
                      <p class="col-md-12 card-subtitle fs-12 text-muted">
                        {{
                          item.CustomerType == "Hirer"
                            ? "Borrower"
                            : item.CustomerType
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="row m-0 mb-2 align-items-center">
                    <h6 class="m-0 col-md-5 p-0 fs-12">Total Income</h6>
                    <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                      {{ item.Income_Total_Amount || 0 }}
                    </p>
                  </div>
                  <div class="row m-0 mb-2 align-items-center">
                    <h6 class="m-0 col-md-5 p-0 fs-12">Total Expense</h6>
                    <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                      {{ item.Expenses_Total_Amount || 0 }}
                    </p>
                  </div>
                  <div class="row m-0 mb-2 align-items-center">
                    <h6 class="m-0 col-md-5 p-0 fs-12">Total Obligation</h6>
                    <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                      {{ item.Obligation_Total_Amount || 0 }}
                    </p>
                  </div>
                  <!--<div class="row m-0 mb-2 align-items-center">
                    <h6 class="m-0 col-md-5 p-0 fs-12">Total Margin</h6>
                    <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                      {{
                        item.Income_Total_Amount - item.Expenses_Total_Amount ||
                          0
                      }}
                    </p>
                  </div>-->
                  <!--<div class="row m-0 mb-2 align-items-center">
                    <h6 class="m-0 col-md-5 p-0 fs-12">Eligibility</h6>
                    <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">0</p>
                  </div>-->
                  <div class="row m-0 justify-content-center">
                    <button type="button"
                            class="btn btn-sm w100 font-size-12"
                            (click)="AddCustomerIncExp(item)"
                            [ngClass]="
                            item.Income_Total_Amount>
                      0
                      ? 'btn-info'
                      : 'btn-outline-secondary'
                      "
                      >
                      {{ item.Income_Total_Amount > 0 ? "View" : "Fill" }}
                      <!-- {{BtnAddView}} -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="customerData">
          <div class="carousel-item" *ngIf="customerData.length > 4">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(4, 8); let i = index"
              >
                <div class="card">
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img style="height: 45px; width: 45px"
                             src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                             class="user-img rounded-circle img-thumbnail"
                             alt=""
                             onerror="this.onerror=null;this.src='assets/images/default-user.png';" />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Income</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{ item.Income_Total_Amount || 0 }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Expense</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{ item.Expenses_Total_Amount || 0 }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Obligation</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                        {{ item.Obligation_Total_Amount || 0 }}
                      </p>
                    </div>
                    <!--<div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Margin</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{
                          item.Income_Total_Amount -
                            item.Expenses_Total_Amount || 0
                        }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Eligibility</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">0</p>
                    </div>-->
                    <div class="row m-0 justify-content-center">
                      <button type="button"
                              class="btn btn-sm w100 font-size-12"
                              (click)="AddCustomerIncExp(item)"
                              [ngClass]="
                              item.Income_Total_Amount>
                        0
                        ? 'btn-info'
                        : 'btn-outline-secondary'
                        "
                        >
                        {{ item.Income_Total_Amount > 0 ? "View" : "Fill" }}
                        <!-- {{BtnAddView}} -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="customerData.length > 8">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(8, 12); let i = index"
              >
                <div class="card">
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img style="height: 45px; width: 45px"
                             src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                             class="user-img rounded-circle img-thumbnail"
                             alt=""
                             onerror="this.onerror=null;this.src='assets/images/default-user.png';" />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Income</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{ item.Income_Total_Amount || 0 }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Expense</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{ item.Expenses_Total_Amount || 0 }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Obligation</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12 text-right pr-4">
                        {{ item.Obligation_Total_Amount || 0 }}
                      </p>
                    </div>
                    <!--<div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Total Margin</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">
                        {{
                          item.Income_Total_Amount -
                            item.Expenses_Total_Amount || 0
                        }}
                      </p>
                    </div>
                    <div class="row m-0 mb-2 align-items-center">
                      <h6 class="m-0 col-md-5 p-0 fs-12">Eligibility</h6>
                      <p class="lead m-0 col-md-7 p-0 fs-12">0</p>
                    </div>-->
                    <div class="row m-0 justify-content-center">
                      <button type="button"
                              class="btn btn-sm w100 font-size-12"
                              (click)="AddCustomerIncExp(item)"
                              [ngClass]="
                              item.Income_Total_Amount>
                        0
                        ? 'btn-info'
                        : 'btn-outline-secondary'
                        "
                        >
                        {{ item.Income_Total_Amount > 0 ? "View" : "Fill" }}
                        <!-- {{BtnAddView}} -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <a
          class="carousel-control-prev ml-2"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="prev"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="next"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button
    type="button"
    (click)="CheckCustomerIncomeExpInfo()"
    mat-raised-button
    class="btn font-size-12 button-btn"
    *ngIf="PageData.currentTab == 1 && Status != 'C'"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>

<div
  class="modal fade in"
  id="IncomeExpenditure"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Income & Expenditure
        </h6>
        <button
          type="button"
          (click)="onCloseIncomeExpenditure()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #df="ngForm" novalidate (keydown.enter)="onSaveIncomeExpenditure()">
        <div class="modal-body">
          <form #f="ngForm" novalidate class="formborder">
            <ng-container>
              <!--  *ngIf="!isIncomeEdit" -->
              <div class="row m-0 mr-2">
                <div class="col-md-12 ml-1 bg-success1">Income:</div>
              </div>
              <div class="row m-0"
                   *ngFor="let INc of IncomeModel; let i = index">
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Income Type</span>

                  <input type="search"
                         class="form-control input-text-css"
                         (keyup.enter)="onChangeIncomeType(INc, i)"
                         (change)="onChangeIncomeType(INc, i)"
                         [(ngModel)]="INc.Nature"
                         id="Nature{{ i }}"
                         name="Nature{{ i }}"
                         list="dynmicIncomeType"
                         autocomplete="off"
                         required
                         #refNature="ngModel"
                         placeholder="Income Type"
                         [disabled]="IsDisabled"
                         [ngClass]="{
                      'is-invalid': f.submitted && refNature.invalid,
                      'alert-warning':
                        refNature.invalid &&
                        (refNature.dirty ||
                          refNature.touched ||
                          refNature.untouched)
                    }" />
                  <datalist id="dynmicIncomeType">
                    <option *ngFor="let item of IncomeTypeDropdown"
                            [value]="item.Value"
                            title="item.Value"></option>
                  </datalist>
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Income From</span>
                  <input required
                         type="text"
                         #refInstitution_From="ngModel"
                         [(ngModel)]="INc.Institution_From"
                         [disabled]="IsDisabled"
                         placeholder="Income From"
                         name="Institution_From{{ i }}"
                         id="Institution_From{{ i }}"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': f.submitted && refInstitution_From.invalid,
                      'alert-warning':
                        refInstitution_From.invalid &&
                        (refInstitution_From.dirty ||
                          refInstitution_From.touched ||
                          refInstitution_From.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Monthly Income</span>
                  <input required
                         type="number"
                         numbersOnly
                         #refAmount="ngModel"
                         [(ngModel)]="INc.Amount"
                         [disabled]="IsDisabled"
                         (change)="onChangeAmount()"
                         (keyup.enter)="onChangeAmount()"
                         name="Amount{{ i }}"
                         id="Amount{{ i }}"
                         placeholder="Amount"
                         class="form-control input-text-css text-right pr-3"
                         [ngClass]="{
                      'is-invalid': f.submitted && refAmount.invalid,
                      'alert-warning':
                        refAmount.invalid &&
                        (refAmount.dirty ||
                          refAmount.touched ||
                          refAmount.untouched)
                    }" />
                </div>

                <div class="col-md-3 p-1">
                  <span *ngIf="i == 0">Remarks</span>
                  <textarea rows="1"
                            name="Remarks{{ i }}"
                            id="Remarks{{ i }}"
                            [(ngModel)]="INc.Remarks"
                            #refRemark="ngModel"
                            placeholder="Remark"
                            class="form-control input-text-css"
                            [disabled]="IsDisabled"></textarea>
                </div>

                <div class="col-md-2 p-1">
                  <span *ngIf="i == 0"> Document Image</span>
                  <p class="mt-1" style="overflow-wrap: break-word">
                    <i class="fa fa-paperclip fileUpload"
                       *ngIf="!INc.Doc_FileName"
                       (click)="docFile.click()"
                       aria-hidden="true"></i>
                    <ng-container *ngIf="INc.Doc_FileName">
                      <a href="{{ docBaseUrl }}{{ INc.Customer_Id }}/{{
                          INc.Doc_FileName
                        }}"
                         target="_blank">
                        <i class="fa fa-paperclip mr-2"
                           aria-hidden="true"
                           style="font-size: 18px"></i>
                      </a>
                      <!-- {{INc.Doc_FileName}} -->
                      <i class="fa fa-times ml-2 fs-16"
                         aria-hidden="true"
                         style="cursor: pointer"
                         (click)="removeFile(INc, docFile)"></i>
                    </ng-container>
                  </p>
                  <input #docFile
                         type="file"
                         [multiple]="false"
                         accept="application/pdf,application/vnd.ms-excel"
                         (change)="fileChangeListener(INc, docFile.files)"
                         style="display: none"
                         [disabled]="IsDisabled" />
                </div>
                <!-- </div>
        </div> -->
                <div class="col-md-1 pt-3" style="margin-top: 5px">
                  <button type="button"
                          class="remove"
                          (click)="removeIncome(i)"
                          *ngIf="i < IncomeModel.length && IncomeModel.length > 1"
                          [disabled]="IsDisabled">
                    -
                  </button>
                  <button type="button"
                          class="addmore"
                          (click)="addMoreIncome()"
                          *ngIf="i == IncomeModel.length - 1"
                          [disabled]="!f.form.valid && IsDisabled">
                    +
                  </button>
                </div>
              </div>
              <div class="row m-0" *ngIf="totalCalculator">
                <div class="col-md-4 p-1">
                  <div class="bg-success1">Total Monthly Income:</div>
                </div>
                <div class="col-md-2 p-1">
                  <span class="form-control1 input-text-css text-right pr-3">
                    {{
                    totalCalculator.total
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
          </form>

          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>

          <form #rf="ngForm" novalidate class="formborder">
            <ng-container>
              <!-- *ngIf="!isIncomeEdit"-->
              <div class="row m-0 mt-1 mr-2">
                <div class="col-md-12 ml-1 bg-success1">Expense:</div>
              </div>
              <div class="row m-0"
                   *ngFor="let Exp of ExpenseModel; let i = index">
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Expense Type</span>
                  <input required
                         type="text"
                         #refNature="ngModel"
                         [(ngModel)]="Exp.Nature"
                         placeholder="Expense Type"
                         name="Nature1{{ i }}"
                         id="Nature1{{ i }}"
                         class="form-control input-text-css"
                         [disabled]="IsDisabled"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refNature.invalid,
                      'alert-warning':
                        refNature.invalid &&
                        (refNature.dirty ||
                          refNature.touched ||
                          refNature.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Expense From</span>
                  <input required
                         type="text"
                         #refInstitution_From="ngModel"
                         [(ngModel)]="Exp.Institution_From"
                         [disabled]="IsDisabled"
                         placeholder="Expense From"
                         name="Institution_From1{{ i }}"
                         id="Institution_From1{{ i }}"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refInstitution_From.invalid,
                      'alert-warning':
                        refInstitution_From.invalid &&
                        (refInstitution_From.dirty ||
                          refInstitution_From.touched ||
                          refInstitution_From.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Monthly Expense</span>
                  <input required
                         type="number"
                         numbersOnly
                         #refAmount="ngModel"
                         [(ngModel)]="Exp.Amount"
                         [disabled]="IsDisabled"
                         (change)="onChangeAmount1()"
                         (keyup.enter)="onChangeAmount1()"
                         name="Amount1{{ i }}"
                         id="Amount1{{ i }}"
                         placeholder="Amount"
                         class="form-control input-text-css text-right pr-3"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refAmount.invalid,
                      'alert-warning':
                        refAmount.invalid &&
                        (refAmount.dirty ||
                          refAmount.touched ||
                          refAmount.untouched)
                    }" />
                </div>

                <div class="col-md-3 p-1">
                  <span *ngIf="i == 0">Remarks</span>
                  <textarea rows="1"
                            name="Remarks1{{ i }}"
                            id="Remarks1{{ i }}"
                            [(ngModel)]="Exp.Remarks"
                            #refRemark="ngModel"
                            placeholder="Remark"
                            class="form-control input-text-css"
                            [disabled]="IsDisabled"></textarea>
                </div>

                <div class="col-md-2 p-1">
                  <span *ngIf="i == 0"> Document Image</span>
                  <p class="mt-1" style="overflow-wrap: break-word">
                    <i class="fa fa-upload fileUpload"
                       *ngIf="!Exp.Doc_FileName"
                       (click)="docFile.click()"
                       aria-hidden="true"></i>
                    <ng-container *ngIf="Exp.Doc_FileName">
                      <a href="{{ docBaseUrl }}{{ Exp.Customer_Id }}/{{
                          Exp.Doc_FileName
                        }}"
                         target="_blank">
                        <i class="fa fa-paperclip mr-2"
                           aria-hidden="true"
                           style="font-size: 18px"></i>
                      </a>
                      <!-- {{Exp.Doc_FileName}} -->
                      <i class="fa fa-times ml-2 fs-16"
                         aria-hidden="true"
                         style="cursor: pointer"
                         (click)="removeFile1(Exp, docFile)"></i>
                    </ng-container>
                  </p>
                  <input #docFile
                         type="file"
                         [multiple]="false"
                         accept="application/pdf,application/vnd.ms-excel"
                         (change)="fileChangeListener1(Exp, docFile.files)"
                         style="display: none"
                         [disabled]="IsDisabled" />
                </div>

                <div class="col-md-1 pt-3" style="margin-top: 5px">
                  <button type="button"
                          class="remove"
                          (click)="removeExpense(i)"
                          *ngIf="i < ExpenseModel.length && ExpenseModel.length > 1"
                          [disabled]="IsDisabled">
                    -
                  </button>
                  <button type="button"
                          class="addmore"
                          (click)="addMoreExpense()"
                          *ngIf="i == ExpenseModel.length - 1"
                          [disabled]="!rf.form.valid && IsDisabled">
                    +
                  </button>
                </div>
              </div>
              <div class="row m-0" *ngIf="totalCalculator1">
                <div class="col-md-4 p-1">
                  <div class="bg-success1">Total Monthly Expense:</div>
                </div>
                <div class="col-md-2 p-1">
                  <span class="form-control1 input-text-css text-right pr-3">
                    {{
                    totalCalculator1.total
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
            <!--<div class="row m-0 mt-2">
              <div class="col-md-4 p-1">
                <div class="bg-success1">Margin:</div>
              </div>
              <div class="col-md-2 p-1">
                <input required
                       type="text"
                       #refMargin="ngModel"
                       [(ngModel)]="Margin"
                       placeholder="Margin"
                       name="Margin"
                       id="Margin"
                       class="form-control1 input-text-css text-right pr-3"
                       disabled />
              </div>
            </div>-->
          </form>





          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>

          <form #rfff="ngForm" novalidate class="formborder">
            <ng-container>
              <!-- *ngIf="!isIncomeEdit"-->
              <div class="row m-0 mt-1 mr-2">
                <div class="col-md-12 ml-1 bg-success1">Obligations :</div>
              </div>
              <div class="row m-0"
                   *ngFor="let Exp of ObligationModel; let i = index">
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Obligation Type</span>
                  <!--<input required
                 type="text"
                 #refNature2="ngModel"
                 [(ngModel)]="Exp.Nature"
                 placeholder="Obligation Type"
                 name="Nature2{{ i }}"
                 id="Nature2{{ i }}"
                 class="form-control input-text-css"
                 [disabled]="IsDisabled"
                 [ngClass]="{
    'is-invalid': rfff.submitted && refNature2.invalid,
    'alert-warning':
      refNature2.invalid &&
      (refNature2.dirty ||
        refNature2.touched ||
        refNature2.untouched)
  }" />-->
                  <select name="Nature2{{ i }}"
                          id="Nature2{{ i }}"
                          required
                          #refNature2="ngModel"
                           [disabled]="IsDisabled"
                          [ngClass]="{
                            'is-invalid': rfff.submitted && refNature2.invalid,
                            'alert-warning':
                              refNature2.invalid &&
                              (refNature2.dirty ||
                                refNature2.touched ||
                                refNature2.untouched)
                          }"
                          [(ngModel)]="Exp.Nature"
                          class="form-control input-text-css">
                    <option value="">Select Type</option>
                    <option value="Interest">Interest</option>
                    <option value="Installment">Installment</option>
                  </select>
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Obligation From</span>
                  <input required
                         type="text"
                         #refInstitution_From3="ngModel"
                         [(ngModel)]="Exp.Institution_From"
                         [disabled]="IsDisabled"
                         placeholder="Obligation From"
                         name="Institution_From3{{ i }}"
                         id="Institution_From3{{ i }}"
                         class="form-control input-text-css"
                         [ngClass]="{
            'is-invalid': rfff.submitted && refInstitution_From3.invalid,
            'alert-warning':
              refInstitution_From3.invalid &&
              (refInstitution_From3.dirty ||
                refInstitution_From3.touched ||
                refInstitution_From3.untouched)
          }" />
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable" *ngIf="i == 0">Monthly Amount</span>
                  <input required
                         type="number"
                         numbersOnly
                         #refAmount2="ngModel"
                         [(ngModel)]="Exp.Amount"
                         [disabled]="IsDisabled"
                         (change)="onChangeAmountObligation()"
                         (keyup.enter)="onChangeAmountObligation()"
                         name="Amount2{{ i }}"
                         id="Amount2{{ i }}"
                         placeholder="Amount"
                         class="form-control input-text-css text-right pr-3"
                         [ngClass]="{
            'is-invalid': rfff.submitted && refAmount2.invalid,
            'alert-warning':
              refAmount2.invalid &&
              (refAmount2.dirty ||
                refAmount2.touched ||
                refAmount2.untouched)
          }" />
                </div>

                <div class="col-md-3 p-1">
                  <span *ngIf="i == 0">Remarks</span>
                  <textarea rows="1"
                            name="Remarks2{{ i }}"
                            id="Remarks2{{ i }}"
                            [(ngModel)]="Exp.Remarks"
                            #refRemark2="ngModel"
                            placeholder="Remark"
                            class="form-control input-text-css"
                            [disabled]="IsDisabled"></textarea>
                </div>

                <div class="col-md-2 p-1">
                  <span *ngIf="i == 0"> Document Image</span>
                  <p class="mt-1" style="overflow-wrap: break-word">
                    <i class="fa fa-upload fileUpload"
                       *ngIf="!Exp.Doc_FileName"
                       (click)="docFile1.click()"
                       aria-hidden="true"></i>
                    <ng-container *ngIf="Exp.Doc_FileName">
                      <a href="{{ docBaseUrl }}{{ Exp.Customer_Id }}/{{
                Exp.Doc_FileName
              }}"
                         target="_blank">
                        <i class="fa fa-paperclip mr-2"
                           aria-hidden="true"
                           style="font-size: 18px"></i>
                      </a>
                      <!-- {{Exp.Doc_FileName}} -->
                      <i class="fa fa-times ml-2 fs-16"
                         aria-hidden="true"
                         style="cursor: pointer"
                         (click)="removeFile1(Exp, docFile1)"></i>
                    </ng-container>
                  </p>
                  <input #docFile1
                         type="file"
                         [multiple]="false"
                         accept="application/pdf,application/vnd.ms-excel"
                         (change)="fileChangeListener1(Exp, docFile1.files)"
                         style="display: none"
                         [disabled]="IsDisabled" />
                </div>

                <div class="col-md-1 pt-3" style="margin-top: 5px">
                  <button type="button"
                          class="remove"
                          (click)="removeObligation(i)"
                          *ngIf="i < ObligationModel.length && ObligationModel.length > 1"
                          [disabled]="IsDisabled">
                    -
                  </button>
                  <button type="button"
                          class="addmore"
                          (click)="addMoreObligation()"
                          *ngIf="i == ObligationModel.length - 1"
                          [disabled]="!rf.form.valid && IsDisabled">
                    +
                  </button>
                </div>
              </div>
              <div class="row m-0" *ngIf="totalCalculatorObligation">
                <div class="col-md-4 p-1">
                  <div class="bg-success1">Total Monthly obligation:</div>
                </div>
                <div class="col-md-2 p-1">
                  <span class="form-control1 input-text-css text-right pr-3">
                    {{
          totalCalculatorObligation.total
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
            
          </form>






          <!-- <div class="row m-0 mt-2">
    <div class="col-md-2 p-1">
      <div class="bg-success1">Eligibility:</div>
    </div>
    <div class="col-md-2 p-1">
      <input
        type="text"
        #refEligibility="ngModel"
        [(ngModel)]="Eligibility"
        placeholder="Eligibility Ratio %"
        name="Eligibility"
        id="Eligibility"
        class="form-control input-text-css text-right pr-3"
        maxLength="3"
        numbersOnly
        (change)="onChangeEligibilityRatio()"
        style="height: calc(1.3em + 0.75rem + 2px)"
        [disabled]="IsDisabled"
      />
    </div>
    <div class="col-md-2 p-1">
      <input
        type="text"
        #refEMIEligibility="ngModel"
        [(ngModel)]="EMIEligibility"
        placeholder="EMI Eligibility"
        name="EMIEligibility"
        id="EMIEligibility"
        class="form-control1 input-text-css text-right pr-3"
        disabled
      />
    </div>
  </div> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="onCloseIncomeExpenditure()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveIncomeExpenditure()"
            [class.spinner]="loading"
            *ngIf="IsSave && PageData.currentTab == 1 && Status != 'C'"
            class="btn font-size-12 button-btn"
            [disabled]="!df.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="onEditIncomeExpense()"
            *ngIf="
              IsEdit &&
              EditAllow == 1 &&
              PageData.currentTab == 1 &&
              Status != 'C' &&
              (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
            "
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Edit
          </button>
          <button
            type="button"
            class="btn font-size-12 button-btn"
            (click)="onSaveIncomeExpenditure()"
            *ngIf="IsUpdate"
            [disabled]="!f.form.valid"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
