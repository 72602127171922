import { Component, OnInit, ViewChild } from "@angular/core";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { PartnerService } from "src/app/_Partner/services/partner.service";
declare var $: any;

@Component({
  selector: "app-partner-receipt-import",
  templateUrl: "./partner-receipt-import.component.html",
  styleUrls: ["./partner-receipt-import.component.scss"],
})

export class PartnerReceiptImportComponent implements OnInit {
  file: any;
  loading: boolean = false;
  arrayBuffer: any;
  PostBulkProcess: any[] = [];
  displayedColumnsBulkImport: any[] = [];
  displayedColumnsNotMatchImport: any[] = [];
  currentUser: any;
  Matchhdata: any = [];
  HideBtn: any = 1;
  mode = "indeterminate";
  MisMatchdata: any = [];
  dataSourceBulkImport: any;
  progressImport: number = 0;
  dataSourceBulkImport_NotMatch: any;
  showSpinner = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  voucherModel: any = { Voucher: {} };
  accountTagDropdown: any[] = [];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private partnerService: PartnerService
    
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Partner Receipt Import");
  } 
 
  public exportAsExcelFile(excelFileName: string): void {
    // var json=[{Date:'',Rec_No:'',Rec_date:'',Loan_No:'',EMI_Amt:'',OD_Amt:'',Other_Amt:''}]
    var json = [["Voucher_Day", "Voucher_Month", "Voucher_Year", "Receipt_No", "Receipt_Day", "Receipt_Month", "Receipt_Year", "Loan_No", "EMI_Amount", "OD_Amount", "Other_Amount", "Narration"]]
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    // console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private checkValidity(data: any) {
    if (data.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Empty Spreadsheet not Allowed!",
        ...this.configSuccess,
      })._dismissAfter(1000);
      return false;
    }
    if (Object.keys(data[0]).toString() != ["Voucher_Day", "Voucher_Month", "Voucher_Year", "Receipt_No", "Receipt_Day", "Receipt_Month", "Receipt_Year", "Loan_No", "EMI_Amount", "OD_Amount", "Other_Amount", "Narration"].toString()) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Format Mismatch!",
        ...this.configSuccess,
      })._dismissAfter(1000);
      return false;
    } 
    return true;
  }

  onImportExcel(event) {
    this.HideBtn = 1;
    this.Matchhdata = [];
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);   
    this.mode="determinate"
    this.progressImport=0;
    fileReader.onprogress = (data) => { 
      if (data.lengthComputable) { 
        this.showSpinner=true
        this.progressImport = (data.loaded / data.total * 100)
        console.log(this.progressImport, "Progress");
      } 
    }
    fileReader.onload = (e) => {
      this.showSpinner=false;
      this.showSpinner = false;
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.PostBulkProcess = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd"
      });
      // console.log('filelist', this.PostBulkProcess);
      if (this.checkValidity(this.PostBulkProcess)) {
        // console.log('success filelist', JSON.stringify(this.PostBulkProcess));
        this.PostBulkProcess.forEach(x => {
          x.VoucherDate = x.Voucher_Month + '/' + x.Voucher_Day + '/' + x.Voucher_Year
          x.Receipt_Date = x.Receipt_Month + '/' + x.Receipt_Day + '/' + x.Receipt_Year
        })
        this.mode="indeterminate";
        this.progressImport=100;
        this.showSpinner=true;
        this.lmsService.LMS_Receipt_Import_Data({ JSON: JSON.stringify(this.PostBulkProcess), LoginUserId: this.currentUser.userId, ImportType:'Partner' }).subscribe((res) => {
          this.showSpinner=false;
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].Msg, ...this.configSuccess });
          } else {
            this.displayedColumnsBulkImport = ["VoucherDate", "Receipt_No", "Receipt_Date", "Loan_No", "EMI_Amount", "OD_Amount", "Other_Amount", "Narration"];
            this.displayedColumnsNotMatchImport = ["VoucherDate", "Receipt_No", "Receipt_Date", "Loan_No", "EMI_Amount", "OD_Amount", "Other_Amount", "Narration", "NotMach"];
            var ImportData = JSON.parse(JSON.stringify(res))
            // this.Matchhdata = ImportData.filter(x => console.log("logggggg", x)
            this.Matchhdata = ImportData.filter(x => x.Status == 1);
            this.MisMatchdata = ImportData.filter(x => x.Status == 0);
            this.dataSourceBulkImport = new MatTableDataSource(ImportData.filter(x => x.Status == 1));
            this.dataSourceBulkImport.sort = this.sort;
            //this.dataSourceBulkImport.paginator = this.paginator;
            this.dataSourceBulkImport_NotMatch = new MatTableDataSource(ImportData.filter(x => x.Status == 0));
            this.dataSourceBulkImport_NotMatch.sort = this.sort;
            // this.dataSourceBulkImport_NotMatch.paginator = this.paginator;
          }
        })
      }
    }
    event.value="" 
  }
  initializeVoucher() {

    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Receipt',
      Voucher_Sub_Type: 'Loan',
      Voucher_Tag: 'Bank',
      Collection_By: this.currentUser.userId,
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      LoginUserId: this.currentUser.userId
    };
  }
  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == 'Other') {

      this.lmsService.Get_Acc_Accounts_OtherThan_BankCash({}).subscribe((res: any) => {
        this.accountTagDropdown = res;
      })
    }
    else {
      this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
        this.accountTagDropdown = res;
      })
    }
  }
  saveVoucher() {
    this.dataSourceBulkImport = null;
    this.HideBtn = 0;
    this.dataSourceBulkImport_NotMatch = null;
    this.partnerService.Acc_Save_Import_Partner_Receipt_Voucher({ JSON: JSON.stringify(this.Matchhdata), VoucherJson: JSON.stringify(this.voucherModel), LoginUserId: this.currentUser.userId }).subscribe((res:any) => {
      if (res.length > 0) {
        this.HideBtn = 1;
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

      }        this.Matchhdata = [];
      this.MisMatchdata = [];
      this.dataSourceBulkImport = null;
      this.dataSourceBulkImport_NotMatch = null;
    });
  }
  exportTable(exporter: any) {
    exporter.exportTable("xlsx", {
      fileName: "ReportResExport"+Date.now(),
      sheet: "ReportResExport",
    });

  }
}
