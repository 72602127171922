<div class="col-md-2 text-right">
  <button
    type="button"
    (click)="viewKFSstatement()"
    class="btn font-size-12 button-btn mt-2"
    style="width: max-content !important"
  >
    Print KFS
  </button>
</div>

<div
  class="modal fade in"
  id="viewKeyFactsStatementAnnexA"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Key Facts Statement
        </h6>
        <button
          type="button"
          (click)="onCloseKeyFactsStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="col-md-4 text-right" style="display: flex">
          <button
            type="button"
            (click)="onDocsPrint('kfsStatementModal', 'kfsStatementModal')"
            class="btn font-size-12 button-btn"
            style="width: max-content !important"
          >
            Print KFS Statement
          </button>
        </div>

        <div
          class="col-md-10 offset-1"
          id="kfsStatementModal"
          style="color: black !important"
        >
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <div
            style="
              text-align: right;
              font-weight: 600;
              font-size: 14px;
              color: black;
              margin-bottom: 10px;
            "
          >
            Annex A
          </div>
          <div
            style="
              text-align: center;
              font-weight: 600;
              font-size: 18px;
              color: black;
              margin-bottom: 10px;
            "
          >
            Key Facts Statement
          </div>

          <div class="annexA">
            <div
              style="
                text-align: center;
                font-weight: 600;
                color: black;
                margin-bottom: 10px;
                font-size: 14px;
              "
            >
              Part 1 (Interest rate and fees/charges)
            </div>

            <table>
              <tr>
                <th style="width: 5% !important">1</th>
                <th style="width: 18% !important">
                  Loan proposal/ account No.
                </th>
                <th
                  style="
                    width: 21% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ Item1Data?.LoanAcNo }}
                </th>
                <th style="width: 15% !important">Type of Loan</th>
                <th
                  style="
                    width: 30% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ Item1Data?.typeOfLoan }}
                </th>
              </tr>
            </table>
            <table style="width: 100%">
              <tr>
                <th class="secondTable" style="width: 5.6% !important">2</th>
                <th style="width: 43.8% !important">
                  Sanctioned Loan Amount(in Rupees)
                </th>
                <th
                  style="
                    width: 75% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ Item1Data?.sanction_LoanAmount }}
                </th>
              </tr>
            </table>
            <table>
              <tr>
                <th style="width: 5.6% !important">3</th>
                <th style="width: 43.8% !important">
                  Disbursal Schedule
                  <br />
                  <span style="font-size: 14px; font-weight: 400"
                    >(i) Disbursement in stages or 100% upfront.
                  </span>
                  <br />

                  <span style="font-size: 14px; font-weight: 400">
                    (ii) If it is stage-wise, mention the clause of loan
                    agreement having relevant details.</span
                  >
                </th>
                <td colspan="2">
                  <p
                    style="
                      font-size: 14px;
                      border-bottom: 1px solid black;
                      margin-top: 24px;
                    "
                  >
                    <span style="margin-left: 50px">{{
                      disbursementType
                    }}</span>
                  </p>

                  <p
                    style="
                      font-size: 14px;
                      display: flex;
                      justify-content: space-around;
                    "
                    *ngFor="let item of Item4Data; let i = index"
                  >
                    <span>{{ item?.Amount }}</span>
                    <span>{{ item?.paymentOn }}</span>
                  </p>
                </td>
              </tr>
            </table>
            <table style="width: 100% !important">
              <tr>
                <th style="width: 5.6% !important">4</th>
                <th style="width: 43.8% !important">
                  Loan term(year/months/days)
                </th>
                <th style="font-size: 14px; font-weight: 400">
                  {{ Item1Data?.Tenure }}
                </th>
              </tr>
            </table>
            <table style="width: 100%">
              <tr>
                <th style="width: 5.6% !important">5</th>
                <th style="width: 94.4% !important">Instalment details</th>
              </tr>
            </table>
            <table>
              <tr>
                <th
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  Type of Instalments
                </th>
                <th
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  Number of EPIs
                </th>
                <th
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  EPI(Rs)
                </th>
                <th
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  Commencement of repayment, post sanction
                </th>
              </tr>
              <tr>
                <td
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  {{ Item1Data?.typeOfInstallment }}
                </td>
                <td
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  {{ Item1Data?.numberOfEPI }}
                </td>
                <td
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: right !important;
                  "
                >
                  {{ Item1Data?.EPI }}
                </td>
                <td
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center !important;
                  "
                >
                  {{ Item1Data?.CommencementOfRepayment }} days
                </td>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5.6% !important">6</th>
                <th style="width: 50.4% !important">
                  Interest rate(%) and type (fixed or floating or hybrid)
                </th>
                <td style="width: 45% !important">
                  {{ Item1Data?.InterestRate }}% fixed
                </td>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5.6% !important">7</th>
                <th style="width: 94.4% !important">
                  Additional Information in case of Floating rate of interest
                </th>
              </tr>
            </table>

            <table>
              <tr>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Reference Benchmark
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Benchmark rate(%)(B)
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Spread(%) (S)
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Final rate(%) <br />
                  R=(B)+(S)
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Reset periodicity<sup>2</sup> (Months)
                </th>
                <th
                  style="
                    width: 40% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Impact of change in the reference benchmark (for 25 bps change
                  in 'R', change in EPI (₹) & No. of EPIs)
                </th>
              </tr>
              <tr>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 40% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5.6% !important">8</th>
                <th style="width: 94.4% !important">Fee/Charges<sup>4</sup></th>
              </tr>
            </table>

            <table>
              <tr>
                <th
                  colspan="2"
                  style="
                    width: 30% !important;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Charges Head
                </th>
                <th
                  colspan="2"
                  style="
                    width: 30% !important;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Payable to the RE(A)
                </th>
                <th
                  colspan="2"
                  style="
                    width: 40% !important;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Paybale to a third party through RE(B)
                </th>
              </tr>

              <tr>
                <th style="width: 5% !important"></th>
                <th style="width: 25% !important"></th>
                <th
                  style="
                    width: 15% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  One-time/Recurring
                </th>
                <th
                  style="
                    width: 15% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Amount(in ₹) or Percentage(%) as applicable <sup>5</sup>
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  One-time/Recurring
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Amount(in ₹) or Percentage(%) as applicable <sup>5</sup>
                </th>
              </tr>

              <tr *ngFor="let item of Item3Data; let i = index">
                <th
                  style="
                    width: 5% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ i + 1 }}
                </th>
                <th
                  style="
                    width: 25% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ item.ChargeHeadCategory }}
                </th>
                <th
                  style="
                    width: 15% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  One-time
                </th>
                <th
                  style="
                    width: 15% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: right !important;
                  "
                >
                  {{ item.CRAmount - item.DRAmount }}
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  One-time
                </th>
                <th
                  style="
                    width: 20% !important;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: right !important;
                  "
                >
                  {{ item.DRAmount }}
                </th>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5% !important">9</th>
                <th style="width: 40% !important">
                  Annual Percentage Rate (APR) (%)<sup>6</sup>
                </th>
                <th style="width: 55% !important">{{ Item1Data?.APR }}%</th>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5% !important">10</th>
                <th style="width: 94.4% !important">
                  Details of Contigment Charges(in ₹ or %,as applicable )
                </th>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5% !important">(i)</th>
                <th
                  style="
                    width: 70% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Penal charges,if any,in case of delayed payment
                </th>
                <th style="width: 25% !important">
                  {{ Item1Data?.penalCharges }}
                </th>
              </tr>

              <tr>
                <th style="width: 5% !important">(ii)</th>
                <th
                  style="
                    width: 70% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Other charges,if any
                </th>
                <th style="width: 25% !important">-</th>
              </tr>

              <tr>
                <th style="width: 5% !important">(iii)</th>
                <th
                  style="
                    width: 70% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Forecolusures charges,if any
                </th>
                <th style="width: 25% !important">
                  {{ Item1Data?.Foreclosure_charges }}
                </th>
              </tr>

              <tr>
                <th style="width: 5% !important">(iv)</th>
                <th
                  style="
                    width: 70% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Charges for switching of loans from floating to fixed rate and
                  vice versa
                </th>
                <th style="width: 25% !important">-</th>
              </tr>

              <tr>
                <th style="width: 5% !important">(v)</th>
                <th
                  style="
                    width: 70% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Any other charges(please specify)
                </th>
                <th style="width: 25% !important">-</th>
              </tr>
            </table>

            <div
              style="
                text-align: center;
                font-weight: 600;
                color: black;
                margin-bottom: 10px;
                margin-top: 10px;
                font-size: 14px;
              "
            >
              Part 2 (Other qualitative information)
            </div>

            <table>
              <tr>
                <th style="width: 5% !important">1</th>
                <th
                  style="
                    width: 50% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Clause of Loan agreement relating to engagement of recovery
                  agents
                </th>
                <th style="width: 45% !important"></th>
              </tr>
              <tr>
                <th style="width: 5% !important">2</th>
                <th
                  style="
                    width: 50% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Clause of Loan agreement relating which details grievance
                  redressal mechanism
                </th>
                <th style="width: 45% !important"></th>
              </tr>

              <tr>
                <th style="width: 5% !important">3</th>
                <th
                  style="
                    width: 50% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Phone number and email id of the nodal grievance redressal
                  officer<sup>7</sup>
                </th>
                <th style="width: 45% !important"></th>
              </tr>

              <tr>
                <th style="width: 5% !important">4</th>
                <th
                  style="
                    width: 50% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Whether the loan is,or in future maybe,subject to transfer to
                  other REs or securitisation (Yes/No)
                </th>
                <th style="width: 45% !important"></th>
              </tr>
            </table>

            <table>
              <tr>
                <th style="width: 5% !important">5</th>
                <th
                  style="
                    width: 95% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  In case of lending under collaborative lending arrangements
                  (e.g., co-lending/outsourcing),following additional details
                  may be furnished:
                </th>
              </tr>
            </table>

            <table>
              <tr>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Name of the originating RE,along with its funding proportion
                </th>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Name of the partner RE,along with its funding proportion
                </th>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Blended rate of interest
                </th>
              </tr>

              <tr>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
                <th
                  style="
                    width: 33.6% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
              </tr>
            </table>

            <table style="width: 100%">
              <tr>
                <th style="width: 5% !important">6</th>
                <th
                  style="
                    width: 95% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  In case of digital loans,following specific disclosures may be
                  furnished:
                </th>
              </tr>
            </table>

            <table>
              <tr>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  (i)
                </th>
                <th
                  style="
                    width: 45% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Cooling off/look-up period, in terms of RE's board approved
                  policy,during which borrower shall not be charged any penalty
                  on prepayment of loan
                </th>
                <th
                  style="
                    width: 45% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
              </tr>

              <tr>
                <th
                  style="
                    width: 10% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  (ii)
                </th>
                <th
                  style="
                    width: 45% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  Details of LSP acting as recovery agent and authorized to
                  approach the borrower
                </th>
                <th
                  style="
                    width: 45% !important;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  -
                </th>
              </tr>
            </table>
          </div>

          <div class="annexB">
            <div
              style="
                text-align: right;
                font-weight: 600;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
              "
            >
              Annex B
            </div>
            <div
              style="
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
                margin-top: 10px;
              "
            >
              Illustration for computation of APR for Retail and MSME loans
            </div>

            <div>
              <table>
                <tr>
                  <th style="width: 5% !important; text-align: center">
                    Sr. No.
                  </th>
                  <th style="width: 70% !important; text-align: center">
                    Parameter
                  </th>
                  <th style="width: 25% !important; text-align: center">
                    Details
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    1
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Sanctioned Loan amount(in Rupees) (SI no.2 of the KFS
                    template-Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.sanction_LoanAmount }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    2
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Loan Term (in years/months/days) (SI No. 4 of the KFS
                    template-Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.Tenure }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    a)
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    No. of instalments for payment of principal, in case of
                    equated periodic loans
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.numberOfEPI }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    b)
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    <span>Type of EPI</span> <br />
                    <span> Amount of each EPI (in Rupees) and </span><br />
                    <span
                      >nos. of EPIs (e.g., no. of EMIs in case of monthly
                      instalements)<br />
                      (SI No. 5 of the KFS template-Part 1)</span
                    >
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    <span> {{ Item1Data?.typeOfInstallment }}</span>
                    <br />
                    <span> {{ Item1Data?.EPI }}</span>
                    <br />
                    <span>{{ Item1Data?.numberOfEPI }}</span>
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    c)
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    No. of instalments for payment of capitalised interest , if
                    any
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    -
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    d)
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Commencement of repayments, post sanction (SI No. 5 of the
                    KFS template)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.CommencementOfRepayment }} days
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    3
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Interest rate type(fixed or floating or hybrid) (SI No. 5 of
                    the KFS template - Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    Fixed
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    4
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Rate of Interest (SI No.6 of the KFS template - Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.InterestRate }} %
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    5
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Total Interest Amount to be charged during the entire tenor
                    of the loan as per the rate prevailing on sanction date (in
                    Rupees)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.InterestAmt | number : "1.2-2" }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    6
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Fee/Charges payable<sup>8</sup> (in Rupees)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ totalCRAmount | number : "1.2-2" }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    A
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Payable to the RE (SI No.8A of the KFS template-Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ diffCRandDR | number : "1.2-2" }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    B
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Payable to the third-party routed through RE (SI No.8B of
                    the KFS template - Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ totalDRAmount | number : "1.2-2" }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    7
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Net disbursed amount (1-6) (in Rupees)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.NetDisbursement | number : "1.2-2" }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    8
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Total amount to be paid by the borrower (sum of 1 and 5) (in
                    Rupees)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{
                      Item1Data?.Application_AgreementValue | number : "1.2-2"
                    }}
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    9
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Annual percentage rate- Effective annualized interest
                    rate(in percentage)<sup>10</sup> (SI No.9 of the KFS
                    template-Part 1)
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.APR }} %
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    10
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Schedule of disbursement as per terms and conditions
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    -
                  </th>
                </tr>

                <tr>
                  <th
                    style="
                      width: 5% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    11
                  </th>
                  <th
                    style="
                      width: 70% !important;
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    Due date of payment of instalment and interest
                  </th>
                  <th
                    style="
                      width: 25% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: right;
                    "
                  >
                    {{ Item1Data?.Application_FirstEMIDate }}
                  </th>
                </tr>
              </table>
            </div>

            <div
              style="
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
                margin-top: 10px;
              "
            >
              Illustration Repayment Schedule under Equated Periodic Instalment
              for the hypothetical loan illustrated in Annex B
            </div>

            <!-- <div
              style="
                text-align: right;
                font-weight: 600;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
              "
            >
              Annex C
            </div>
          </div> -->

            <div class="annnexC">
              <table>
                <tr>
                  <th
                    style="width: 12% !important; text-align: center !important"
                  >
                    Instalment No.
                  </th>
                  <th
                    style="width: 22% !important; text-align: center !important"
                  >
                    Outstanding Principal (in Rupees)
                  </th>
                  <th
                    style="width: 22% !important; text-align: center !important"
                  >
                    Principal (in Rupees)
                  </th>
                  <th
                    style="width: 22% !important; text-align: center !important"
                  >
                    Interest (in Rupees)
                  </th>
                  <th
                    style="width: 22% !important; text-align: center !important"
                  >
                    Instalment (in Rupees)
                  </th>
                </tr>

                <tr *ngFor="let obj of Item5Data; let i = index">
                  <th
                    style="
                      width: 12% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: center !important;
                    "
                  >
                    {{ i + 1 }}
                  </th>
                  <th
                    style="
                      width: 22% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: center !important;
                    "
                  >
                    {{ obj.Outstanding_Principle }}
                  </th>
                  <th
                    style="
                      width: 22% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: center !important;
                    "
                  >
                    {{ obj.Principle }}
                  </th>
                  <th
                    style="
                      width: 22% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: center !important;
                    "
                  >
                    {{ obj.Interest }}
                  </th>
                  <th
                    style="
                      width: 22% !important;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: center !important;
                    "
                  >
                    {{ obj.EMI_Amount }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
