<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="onSearchBulkPaymentList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span class="required-lable"> From Date </span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="FromDate"
                 [(ngModel)]="FromDate"
                 id="FromDate"
                 (dateChange)="DateChange()"
                 required
                 #refFromDate="ngModel"
                 [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
                 class="form-control input-text-css" />
          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
      name="FromDate"
      [(ngModel)]="FromDate"
      id="FromDate"
      placeholder="Start Date"
      class="form-control input-text-css"
      bsDatepicker
      (ngModelChange)="DateChange()"
      required
      #refFromDate="ngModel"
      [ngClass]="{
        'is-invalid': df.submitted && refFromDate.invalid,
        'alert-warning':
          refFromDate.invalid &&
          (refFromDate.dirty ||
            refFromDate.touched ||
            refFromDate.untouched)
      }"
    /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker2"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="ToDate"
                 [(ngModel)]="ToDate"
                 id="ToDate"
                 (dateChange)="DateChange()"
                 required
                 #refToDate="ngModel"
                 [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
                 class="form-control input-text-css" />
          <mat-datepicker-toggle matSuffix
                                 [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
      name="ToDate"
      [(ngModel)]="ToDate"
      id="ToDate"
      placeholder="End Date"
      class="form-control input-text-css"
      bsDatepicker
      (ngModelChange)="DateChange()"
      required
      #refToDate="ngModel"
      [ngClass]="{
        'is-invalid': df.submitted && refToDate.invalid,
        'alert-warning':
          refToDate.invalid &&
          (refToDate.dirty || refToDate.touched || refToDate.untouched)
      }"
    /> -->
      </div>
      <!--<div class="col-md-3">
        <span class="required-lable"> Select Type </span>
        <select name="Loan_Acc_Type"
                id="Loan_Acc_Type"
                [(ngModel)]="Loan_Acc_Type"
                (change)="onChangeType()"
                class="form-control input-text-css"
                #refLoan_Acc_Type="ngModel"
                required disabled
                [ngClass]="{
            'is-invalid': df.submitted && refLoan_Acc_Type.invalid,
            'alert-warning':
              refLoan_Acc_Type.invalid &&
              (refLoan_Acc_Type.dirty ||
                refLoan_Acc_Type.touched ||
                refLoan_Acc_Type.untouched)
          }">
          <option value="">Select Type</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-3">
        <span class="required-lable"> Partner</span>
        <select name="ACCategType"
                id="ACCategType"
                (change)="changeACCategType()"
                [(ngModel)]="ACCategType"
                class="form-control input-text-css"
                #refACCategType="ngModel"
                required
                [ngClass]="{
            'is-invalid': df.submitted && refACCategType.invalid,
            'alert-warning':
              refACCategType.invalid &&
              (refACCategType.dirty ||
                refACCategType.touched ||
                refACCategType.untouched)
          }">
          <option value="">Select Partner</option>
          <option *ngFor="let AcCategory of AcCatgList"
                  [value]="AcCategory.Loan_CategoryId">
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-1 justify-content-end">
        <button type="button"
                (click)="onSearchBulkPaymentList()"
                class="mt-3 btn font-size-12 button-btn"
                [disabled]="!df.form.valid">
          Search
        </button>
      </div>

      <div class="col-md-6  justify-content-end ml-4"  *ngIf="DataSource">
        <span > Select Payment EMI </span>
        <select name="PaymentEMI"
                id="PaymentEMI"
                [(ngModel)]="PaymentEMI"
                (change)="onChangePaymentEMI()"
                class="form-control input-text-css"
               >
          <option value="Partner">Payment Partner EMI</option>
          <option value="Customer">Payment Customer EMI</option>
        </select>
      </div>


    </div>
  </form>

  <form #f="ngForm" novalidate *ngIf="DataSource">
    <div class="table-responsive mt-3">
      <mat-table [dataSource]="DataSource"
                 matSort
                 matTableExporter
                 #exporterLoanInfo="matTableExporter">
        <ng-container matColumnDef="PLoan_Id">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           style="max-width: 50px"
                           *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px">
            {{ i + 1 }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LMS_LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer LoanNo</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LMS_LoanAcNo }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="PLoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Partner LoanNo</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.PLoanAcNo }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"></mat-footer-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="PLoan_Date">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Partner Loan Date </mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.PLoan_Date }} </mat-cell>
    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
  </ng-container> -->

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CustomerName }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"></mat-footer-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="EMI_Frequency">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>EMI Frequency</mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.EMI_Frequency}} </mat-cell>
    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
  </ng-container> -->

        <ng-container matColumnDef="EMI_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>EMI No</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMI_No }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="DueDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Due Date</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.DueDate }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"
                           style="margin-right: 34px">Total</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="LMS_EMI_AMount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer EMI</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LMS_EMI_AMount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="grid-header">
            {{ DataSource ? totaltypewise("LMS_EMI_AMount") : "" }}
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="EMI_AMount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Partner EMI</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMI_AMount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="grid-header">
            {{ DataSource ? totaltypewise("EMI_AMount") : "" }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="OverDue_EMI_AMount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Over Due</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.OverDue_EMI_AMount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"
                           style="margin-right: 17px">
            {{ DataSource ? totaltypewise("OverDue_EMI_AMount") : "" }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="OverDueAmtInput">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Payment</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            <input name="OverDueAmtInput{{ i }}"
                   id="OverDueAmtInput{{ i }}"
                   appTwoDigitDecimaNumber
                   style="height: calc(1.3em + 0.25rem + 1px) !important"
                   [(ngModel)]="row.OverDueAmtInput"
                   class="form-control input-text-css"
                   (change)="onChangeAmount(row)" />
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"
                           style="margin-right: 19px">
            {{ TotPayment }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumns; sticky: true"
                        style="background: #4dc3a3"></mat-footer-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-12 p-1 pt-2 pb-2">
          <button
            class="mt-1 btn font-size-12 button-btn"
            (click)="exportTable(exporterLoanInfo)"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <!-- <div class="col-md-8 text-right">
          <button type="button" (click)="saveVoucher($event)" style="background-color: #28a745; color: #FFFFFF; font-size: 12px;" 
            mat-raised-button color="primary">
            Save
          </button>
        </div> -->
      </div>
    </div>
    <div *ngIf="DataSource" class="formborder mt-3">
      <div class="row m-0 mt-3">
        <div class="col-md-12"><h1 class="fs-12 h-b">Payment Mode</h1></div>
      </div>

      <div class="row m-0 align-items-center">
        <div class="col-md-3">
          <span class="required-lable">Voucher Date</span>

          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker3"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="VoucherDate"
              id="VoucherDate"
              class="form-control input-text-css"
              required
              #refVoucherDate="ngModel"
              [(ngModel)]="voucherModel.Voucher.VoucherDate"
              [ngClass]="{
                'is-invalid': f.submitted && refVoucherDate.invalid,
                'alert-warning':
                  refVoucherDate.invalid &&
                  (refVoucherDate.dirty ||
                    refVoucherDate.touched ||
                    refVoucherDate.untouched)
              }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
          <!-- <input
            name="VoucherDate"
            id="VoucherDate"
            placeholder="Voucher Date"
            class="form-control input-text-css"
            bsDatepicker
            required
            #refVoucherDate="ngModel"
            [(ngModel)]="voucherModel.Voucher.VoucherDate"
            [ngClass]="{
              'is-invalid': f.submitted && refVoucherDate.invalid,
              'alert-warning':
                refVoucherDate.invalid &&
                (refVoucherDate.dirty ||
                  refVoucherDate.touched ||
                  refVoucherDate.untouched)
            }"
          /> -->
        </div>

        <div class="col-md-3">
          <span class="required-lable">Select Tag </span>
          <select
            name="Voucher_Tag"
            id="Voucher_Tag"
            required
            (change)="onVoucherTagChange()"
            class="form-control input-text-css"
            #refVoucher_Tag="ngModel"
            [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
            [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Tag.invalid,
              'alert-warning':
                refVoucher_Tag.invalid &&
                (refVoucher_Tag.dirty ||
                  refVoucher_Tag.touched ||
                  refVoucher_Tag.untouched)
            }"
          >
            <option value="Bank">Bank</option>
            <option value="CASH">CASH</option>
          </select>
        </div>

        <div class="col-md-3">
          <span class="required-lable">Select Account</span>
          <select
            name="DRAccountId"
            id="DRAccountId"
            required
            #refDRAccountId="ngModel"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refDRAccountId.invalid,
              'alert-warning':
                refDRAccountId.invalid &&
                (refDRAccountId.dirty ||
                  refDRAccountId.touched ||
                  refDRAccountId.untouched)
            }"
            [(ngModel)]="voucherModel.Voucher.DRAccountId"
          >
            <option value="">Select Account</option>
            <option
              *ngFor="let data of accountTagDropdown"
              [value]="data.AccountId"
            >
              {{ data.Account_Name }}
            </option>
          </select>
        </div>
        <div
          class="col-md-3"
          *ngIf="voucherModel.Voucher.Voucher_Tag == 'Bank'"
        >
          <span class="required-lable">Select Mode of Payment</span>
          <select name="Voucher_Mode_of_Payment"
                  id="Voucher_Mode_of_Payment"
                  required
                  (change)="onChangeMode()"
                  class="form-control input-text-css"
                  #refVoucher_Mode_of_Payment="ngModel"
                  [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                  [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Mode_of_Payment.invalid,
              'alert-warning':
                refVoucher_Mode_of_Payment.invalid &&
                (refVoucher_Mode_of_Payment.dirty ||
                  refVoucher_Mode_of_Payment.touched ||
                  refVoucher_Mode_of_Payment.untouched)
            }">
            <option value="">Select Mode Of Payment</option>
            <option value="Cheque">Cheque</option>
            <option value="RTGS">RTGS</option>
            <option value="Gateway">E-Gateway</option>
            <option value="NEFT">NEFT</option>
            <option value="IMPS">IMPS</option>
            <option value="Transfer">Transfer</option>

          </select>
        </div>
      </div>

      <div
        class="row m-0 mt-1"
        *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != ''"
      >
        <ng-container
          *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
        >
          <div class="col-md-4">
            <span class="required-lable">Cheque No</span>
            <input
              type="text"
              required
              #refVoucher_Cheque_No="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refVoucher_Cheque_No.invalid,
                'alert-warning':
                  refVoucher_Cheque_No.invalid &&
                  (refVoucher_Cheque_No.dirty ||
                    refVoucher_Cheque_No.touched ||
                    refVoucher_Cheque_No.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
              name="Voucher_Cheque_No"
              id="Voucher_Cheque_No"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <span class="required-lable">Cheque Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="pickervcd"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="Voucher_Cheque_Date"
                id="Voucher_Cheque_Date"
                #refVoucher_Cheque_Date="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid,
                  'alert-warning':
                    refVoucher_Cheque_Date.invalid &&
                    (refVoucher_Cheque_Date.dirty ||
                      refVoucher_Cheque_Date.touched ||
                      refVoucher_Cheque_Date.untouched)
                }"
                class="form-control input-text-css"
                [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickervcd"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickervcd></mat-datepicker>
            </div>
          </div>
          <div class="col-md-4">
            <span class="required-lable">Bank Name</span>
            <input
              type="text"
              required
              #refVoucher_Bank_Name="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refVoucher_Bank_Name.invalid,
                'alert-warning':
                  refVoucher_Bank_Name.invalid &&
                  (refVoucher_Bank_Name.dirty ||
                    refVoucher_Bank_Name.touched ||
                    refVoucher_Bank_Name.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
              name="Voucher_Bank_Name"
              id="Voucher_Bank_Name"
              class="form-control input-text-css"
            />
          </div>
        </ng-container>
        <ng-container
          *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
        >
          <div class="col-md-4">
            <span class="required-lable">Ref No</span>
            <input
              type="text"
              required
              #refVoucher_E_Ref_No="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refVoucher_E_Ref_No.invalid,
                'alert-warning':
                  refVoucher_E_Ref_No.invalid &&
                  (refVoucher_E_Ref_No.dirty ||
                    refVoucher_E_Ref_No.touched ||
                    refVoucher_E_Ref_No.untouched)
              }"
              [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
              name="Voucher_E_Ref_No"
              id="Voucher_E_Ref_No"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <span class="required-lable">Transaction Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="pickervetd"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="Voucher_E_Transaction_Date"
                id="Voucher_E_Transaction_Date"
                #refVoucher_E_Transaction_Date="ngModel"
                [ngClass]="{
                  'is-invalid':
                    f.submitted && refVoucher_E_Transaction_Date.invalid,
                  'alert-warning':
                    refVoucher_E_Transaction_Date.invalid &&
                    (refVoucher_E_Transaction_Date.dirty ||
                      refVoucher_E_Transaction_Date.touched ||
                      refVoucher_E_Transaction_Date.untouched)
                }"
                class="form-control input-text-css"
                     [max]="voucherModel.Voucher.Voucher_Date"
                [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickervetd"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickervetd></mat-datepicker>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row m-0 mt-1">
        <div class="col-md-12">
          <span>Voucher Narration</span>
          <input
            type="text"
            required
            #refVoucher_Narration="ngModel"
            [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
            name="Voucher_Narration"
            id="Voucher_Narration"
            class="form-control input-text-css"
          />
        </div>
      </div>

      <div class="row col-md-12 m-0 mt-2 justify-content-end">
        <!-- <button type="button" class="ml-4 btn btn-light font-size-12">Cancel</button> -->
        <button
          type="button"
          class="ml-4 mt-3 btn font-size-12 button-btn"
          (click)="saveVoucher()"
          [class.spinner]="loading"
          [disabled]="!f.form.valid || TotPayment == 0"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
