import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-portfolio-wise",
  templateUrl: "./portfolio-wise.component.html",
  styleUrls: ["./portfolio-wise.component.scss"],
})
export class PortfolioWiseComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;

  JsonData: any[] = [];
  dataSource: any;
  SlabData: any[] = [];

  AmCharts: any;
  //Form value
  ReportType: any = "";
  SlabId: any = "";
  ToDate: any = new Date();

  //div hidden show
  SlabDropdown: boolean = false;
  DataDiv: boolean = false;
  displayedColumns: string[];
  displayedColumnsModel: string[];
  DataSourceModel: any;
  selectedId: any;
  chartdata: any;
  private _MasterService;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  Types: any;
  ArrTypes: Array<{ id: number; value: string }> = [
    { id: 1, value: "Branch Wise Portfolio" },
    { id: 2, value: "Product Wise Portfolio" },
    { id: 3, value: "Amount Wise Portfolio" },
    { id: 4, value: "Tenure Wise Portfolio" },
    { id: 5, value: "IRR Wise Portfolio" },
    { id: 6, value: "LTV Wise Portfolio" },
    { id: 7, value: "Loan A/c Category Wise Portfolio" },
    { id: 8, value: "Gender Wise Portfolio" },
    { id: 9, value: "Religion Wise Portfolio" },
    { id: 10, value: "Caste Wise Portfolio" },
    { id: 11, value: "Marital Status Wise Portfolio" },
    { id: 12, value: "Profile Wise Portfolio" },
    { id: 13, value: "Nature of work Wise Portfolio" },
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  FileCheckingList: any;
  FileCheckingList1: any;

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Portfolio Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.ArrTypes;

    this.displayedColumnsModel = [
      "Id",
      "Application_No",
      "LoanAcNo",
      "Loan_Date",
      "Loan_Acc_Category",
      "CustomerName",
      "RelationName",
      "LoanAmount",
      "EMIAmount",
      "Loan_Tenure",
      "TotalEMI_NOS",
      "EMI_DUE_NOS",
      "receved_EMI",
      "OverDUE_EMI",
      "DPD_Days",
      "Total_OverDUE_EMI__Amount",
      "Total_OverDUE_Principal_Amount",
      "overDue_Interest_AMount",
      "Panel_Intrest",
      "Other_Dues",
      "Total_EMI_AMount",
      "Total_Principal_Amount",
      "Total_Interest_AMount",
      "EMI_DUE",
      "Principal_DUE",
      "Interest_DUE",
      "Total_Recv_EMI__Amount",
      "Total_Recv_Principal_Amount",
      "Total_Recv_Interest_AMount",
      "Balance_Principle",
      "Balance_Interest",
      "FuturePrincipal",
      "FutureInterest",
      "Last_Recv_Date",
      "Last_Recv_Amount",
      "Product_Name",
      "Branch_Name",
      "Area_Name",
      "Sub_Area_Name",
      "CollectionExecutive",
      "Customer_PhoneNo",
      "Address",
    ];
  }

  onChangeReportTypes() {
    this.SlabDropdown = false;
    this.SlabId = "";
    this.Types = this.ArrTypes.find((obj: any) => {
      return obj.id == this.ReportType;
    }).value;
    switch (this.ReportType) {
      case "3":
        this.SlabDropdown = true;
        this.getSlabsList("Amount");
        break;
      case "4":
        this.SlabDropdown = true;
        this.getSlabsList("Tenure");
        break;
      case "5":
        this.SlabDropdown = true;
        this.getSlabsList("IRR");
        break;
      case "6":
        this.SlabDropdown = true;
        this.getSlabsList("LTV");
        break;
    }
  }

  onSearchBusinessData() {
    this.chartdata = null;
    switch (this.ReportType) {
      case "1":
        this.getReportBranchWisePortfolio();
        break;
      case "2":
        this.getReportProductWisePortfolio();
        break;
      case "3":
        this.getReportAmountWisePortfolio();
        break;
      case "4":
        this.getReportTenureWisePortfolio();
        break;
      case "5":
        this.getReportIRRWisePortfolio();
        break;
      case "6":
        this.getReportLTVWisePortfolio();
        break;
      case "7":
        this.getReportLoanAccCategoryWisePortfolio();
        break;
      case "8":
        this.getReportGenderWisePortfolio();
        break;
      case "9":
        this.getReportReligionWisePortfolio();
        break;
      case "10":
        this.getReportCasteWisePortfolio();
        break;
      case "11":
        this.getReportMaritalStatusWisePortfolio();
        break;
      case "12":
        this.getReportProfileWisePortfolio();
        break;
      case "13":
        this.getReportNatureOfWorkWisePortfolio();
        break;
    }
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
        console.log("dtaaaa", this.SlabData);
      });
  }

  getReportBranchWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Branch_Name",
      "NoOfLoans",
      "POS_OS",
      "AvgPosPerLoan",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_BranchWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log(res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Branch");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportProductWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Product_Name",
      "NoOfLoans",
      "POS_OS",
      "AvgPosPerLoan",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_ProductWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "Product");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportLoanAccCategoryWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Loan_Acc_Category",
      "NoOfLoans",
      "POS_OS",
      "AvgPosPerLoan",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_LoanAccCategoryWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "Loan_Acc_Category");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportAmountWisePortfolio() {
    this.showSpinner = true;
    //console.log(this.SlabId);
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_Amount_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "Amount");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportTenureWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_Tenure_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "Tenure");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportIRRWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_IRR_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "IRR");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportLTVWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_LTV_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res, "LTV");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportGenderWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = ["Id", "Gender", "NoOfLoans", "POS_OS", "Action"];

    this.reportsService
      .Report_GenderWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of gender wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Gender");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getReportReligionWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = ["Id", "Religion", "NoOfLoans", "POS_OS", "Action"];

    this.reportsService
      .Report_ReligionWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Religion wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Religion");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getReportCasteWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = ["Id", "Cast", "NoOfLoans", "POS_OS", "Action"];

    this.reportsService
      .Report_Cast_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Catse wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Cast");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getReportMaritalStatusWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "MaritalStatus",
      "NoOfLoans",
      "POS_OS",
      "Action",
    ];

    this.reportsService
      .Report_MaritalStatus_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Catse wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "MaritalStatus");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getReportProfileWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Customer_Profile",
      "NoOfLoans",
      "POS_OS",
      "Action",
    ];

    this.reportsService
      .Report_Profile_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Catse wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Customer_Profile");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getReportNatureOfWorkWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Natureofwork",
      "NoOfLoans",
      "POS_OS",
      "Action",
    ];

    this.reportsService
      .Report_Natureofwork_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Catse wise api call", res);

        if (res.length > 0) {
          this.chartdata = res;
          // this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
          this.getDataforTable(res, "Natureofwork");
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }

        this.showSpinner = false;
      });
  }

  getDataforTable(res, Type) {
    console.log("Type", Type);
    var totalSum = 0;
    res.forEach((x) => {
      totalSum = totalSum + parseFloat(x.POS_OS == null ? 0 : x.POS_OS);
    });
    res.forEach((x) => {
      if (Type == "Branch") {
        x.Name = x.Branch_Name;
      } else if (Type == "Product") {
        x.Name = x.Product_Name;
      } else if (Type == "Loan_Acc_Category") {
        x.Name = x.Loan_Acc_Category;
      } else if (Type == "Gender") {
        x.Name = x.Gender;
      } else if (Type == "Religion") {
        x.Name = x.Religion;
      } else if (Type == "Cast") {
        x.Name = x.Cast;
      } else if (Type == "MaritalStatus") {
        x.Name = x.MaritalStatus;
      } else if (Type == "Customer_Profile") {
        x.Name = x.Customer_Profile;
      } else if (Type == "Natureofwork") {
        x.Name = x.Natureofwork;
      } else {
        x.Name = x.SlabName;
      }
      x.IN_Percentage = (
        (parseFloat(x.POS_OS == null ? 0 : x.POS_OS) / totalSum) *
        100
      ).toFixed(2);
    });
    this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
    console.log("File", this.FileCheckingList1);
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));

    this.dataSource.sort = this.sort;
    //this.generateChartData(res);
    this.showSpinner = false;
  }

  totaltypewise(type) {
    if (
      this.dataSource != null &&
      this.dataSource != undefined &&
      this.dataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      switch (type) {
        case "NoOfLoans":
          return sum.toFixed();
          break;
        case "POS_OS":
          return sum.toFixed(2);
          break;
        case "IN_Percentage":
          return sum.toFixed(2) + "%";
          break;
      }
    }
    return 0;
  }
  // exportTable(exporter: any) {
  //   exporter.exportTable("xls", {
  //     fileName: `portfolio-summary`,
  //     sheet: "portfolio-summary",
  //   });
  // }

  goToDueListDetails(row) {
    this.showSpinner = true;

    this.paginator.pageIndex = 0;
    let _finalData = {
      Search: {
        DueUpto: this.ToDate,
        Type: "All",
        ReceiptUpTo: this.ToDate,
        BranchId: row.BranchId,
        LoanIds: row.LoanIds,
      },
    };

    this.reportsService
      .Get_Due_List({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          res.forEach((x) => {
            x.EMIAmount = parseFloat(x.EMIAmount).toFixed(2);
          });
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          console.log("dataaa", this.FileCheckingList);
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginator;
          $("#ViewDueListDetails").modal("show");
          $("#ViewDueListDetails").css("z-index", "1050");
          this.showSpinner = false;
        }
      });
  }

  onCloseDueListDetails() {
    $("#ViewDueListDetails").modal("hide");
  }

  createPieChart(chartdata) {
    let data = this.generateChartData(chartdata);
    //console.log(data);
    if ($("#ampiechart2").length) {
      var chart = this.AmCharts.makeChart("ampiechart2", {
        type: "pie",
        theme: "light",
        labelRadius: -65,
        labelText: "[[title]]%",
        //"labelText": "[[percents]]%",
        dataProvider: data,
        titleField: "title",
        valueField: "value",
        export: {
          enabled: false,
        },
        color: "#fff",
      });
    }
  }

  generateChartData(data) {
    var chartData = [];
    for (var i = 0; i < data.length; i++) {
      chartData.push({
        title: data[i].Branch_Name,
        value: Number(data[i].POS_OS).toFixed(2),
      });
    }
    //console.log(chartData);
    this.chartdata = chartData;
    return chartData;
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  exportTableAsXLSX(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "portfolio_model"
    ) as HTMLTableElement;
    const worksheetName = "PORTFOLIO WISE DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "portfolio_report"
    ) as HTMLTableElement;
    const worksheetName = "PORTFOLIO REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.DataSourceModel.filter = filterValue.trim().toLowerCase();
  }
}
