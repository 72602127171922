<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="main-container">
  <form #f="ngForm" novalidate class="formborder" *ngIf="voucherAdd==true">
    <div class="row m-0 mb-2 align-items-center">
      <div class="col-md-2">
        <span class="required-lable">Voucher Date</span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="Voucher_Date"
                 [min]="CheckDate"
                 [max]="Days_AllowedDateMax"
                 id="Voucher_Date"
                 #refVoucher_Date="ngModel"
                 (dateChange)="onVoucherDateChange()"
                 required
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Date.invalid,
              'alert-warning':
                refVoucher_Date.invalid &&
                (refVoucher_Date.dirty ||
                  refVoucher_Date.touched ||
                  refVoucher_Date.untouched)
            }"
                 class="form-control input-text-css"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Date" />
          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Voucher No</span>
        <input type="text"
               readonly
               name="Voucher_No"
               id="Voucher_No"
               #refVoucher_No="ngModel"
               [(ngModel)]="voucherModel.Voucher.Voucher_No"
               class="form-control input-text-css" />
      </div>
    </div>

    <div class="row m-0">
      <div class="col-md-12"><hr /></div>
    </div>

    <form #df="ngForm" novalidate>
      <div class="row m-0 add-remove"
           *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index">
        <div class="col-md-12 row m-0 type-column">
          <div class="type mr_top">
            <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
              <span class="required-lable">Type</span>
            </div>
            <select name="TranType{{ i }}"
                    id="TranType{{ i }}"
                    required
                    #refTranType="ngModel"
                    [ngClass]="{
                'is-invalid': df.submitted && refTranType.invalid,
                'alert-warning':
                  refTranType.invalid &&
                  (refTranType.dirty ||
                    refTranType.touched ||
                    refTranType.untouched),
                'mt-1': i == 0
              }"
                    [(ngModel)]="vdData.TranType"
                    class="form-control pl-1 input-text-css"
                    (change)="onChangeCRDR(vdData)">
              <option [value]="'DR'">DR</option>
              <option [value]="'CR'">CR</option>
            </select>
          </div>

          <div class="row m-0 feild">
            <ng-container *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Loan'">
              <div class="col-md-2 pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span [ngClass]="{ 'required-lable': vdData.IsLoanChargeable }">Search</span>
                </div>
                <div class="input-btn">
                  <input type="text"
                         [required]="vdData.IsLoanChargeable"
                         readonly
                         name="CaseNo{{ i }}"
                         id="CaseNo{{ i }}"
                         [(ngModel)]="vdData.CaseNo"
                         class="form-control input-text-css"
                         (keyup.enter)="searchCustomer(vdData, i)"
                         [ngClass]="{ 'mt-1': i == 0 }" />
                  <button type="button"
                          (click)="searchCustomer(vdData, i)"
                          class="primary-btn ml-2 search">
                    <i class="fa fa-search"
                       aria-hidden="true"
                       style="font-size: 11px"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  Customer
                </div>
                <input type="text"
                       name="Customer{{ i }}"
                       id="Customer{{ i }}"
                       class="form-control input-text-css"
                       readonly
                       [(ngModel)]="vdData.Customer"
                       [ngClass]="{ 'mt-1': i == 0 }" />
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  Branch
                </div>
                <input type="text"
                       name="Branch_Name{{ i }}"
                       id="Branch_Name{{ i }}"
                       class="form-control input-text-css"
                       readonly
                       [(ngModel)]="vdData.Branch_Name"
                       [ngClass]="{ 'mt-1': i == 0 }" />
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span [ngClass]="{ 'required-lable': vdData.IsLoanChargeable }"
                        *ngIf="i == 0">Category</span>
                </div>
                <select name="ChargeHeadId{{ i }}"
                        id="ChargeHeadId{{ i }}"
                        [required]="vdData.IsLoanChargeable"
                        #refChargeHeadId="ngModel"
                        [(ngModel)]="vdData.ChargeHeadId"
                        class="form-control input-text-css"
                        [ngClass]="{
                    'is-invalid': df.submitted && refChargeHeadId.invalid,
                    'alert-warning':
                      refChargeHeadId.invalid &&
                      (refChargeHeadId.dirty ||
                        refChargeHeadId.touched ||
                        refChargeHeadId.untouched),
                    'mt-1': i == 0
                  }"
                        (change)="onChangeChargesHead(vdData, i)"
                        [disabled]="!vdData.ProductId">
                  <option value="">Select Category</option>
                  <option *ngFor="let item of chargesHeadDropdown[i]"
                          [value]="item.Id">
                    {{ item.ChagesHead }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  <span class="required-lable" *ngIf="i == 0">Account Head</span>
                </div>
                <select name="AccountId{{ i }}"
                        id="AccountId{{ i }}"
                        required
                        #refAccountId="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refAccountId.invalid,
                    'alert-warning':
                      refAccountId.invalid &&
                      (refAccountId.dirty ||
                        refAccountId.touched ||
                        refAccountId.untouched),
                    'mt-1': i == 0
                  }"
                        [(ngModel)]="vdData.AccountId"
                        class="form-control input-text-css"
                        (change)="onChangeAccountHead(vdData, i)"
                        [disabled]="vdData.IsLoanChargeable && vdData.ChargeHeadId">
                  <option value="">Select Head</option>
                  <option *ngFor="let item of accountHeadDropdown"
                          [value]="item.AccountId">
                    {{ item.Account_Name }}
                  </option>
                </select>
                <!-- <input type="text" name="ChargeHeadCategory{{ i }}" id="ChargeHeadCategory{{ i }}"
                class="form-control input-text-css" readonly [(ngModel)]="vdData.ChargeHeadCategory"> -->
              </div>
              <div class="col-md-1 pr-0 mr_top pl-1">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class=""
                        [ngClass]="{ 'required-lable': vdData.TranType == 'DR' }"
                        *ngIf="i == 0">Amount(DR)</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       id="Amount{{ i }}"
                       appTwoDigitDecimaNumber
                       *ngIf="vdData.TranType == 'DR'"
                       [disabled]="vdData.IsLoanChargeable && !vdData.ChargeHeadId"
                       class="form-control input-text-css text-right pr-3"
                       [required]="vdData.TranType == 'DR'"
                       #refAmount="ngModel"
                       (change)="onChangeAmount($event, vdData)"
                       (keyup.enter)="onChangeAmount($event, vdData)"
                       [(ngModel)]="vdData.Amount"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }" />
                <input type="text"
                       name="AmountD{{ i }}"
                       id="AmountD{{ i }}"
                       readonly
                       appTwoDigitDecimaNumber
                       [ngClass]="{ 'mt-1': i == 0 }"
                       class="form-control input-text-css text-right pr-3"
                       *ngIf="vdData.TranType == 'CR'" />
              </div>
              <div class="col-md-1 pr-0 mr_top pl-1">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class=""
                        [ngClass]="{ 'required-lable': vdData.TranType == 'CR' }"
                        *ngIf="i == 0">Amount(CR)</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       *ngIf="vdData.TranType == 'CR'"
                       appTwoDigitDecimaNumber
                       id="Amount{{ i }}"
                       [disabled]="vdData.IsLoanChargeable && !vdData.ChargeHeadId"
                       [(ngModel)]="vdData.Amount"
                       class="form-control input-text-css text-right pr-3"
                       [required]="vdData.TranType == 'CR'"
                       #refAmount="ngModel"
                       (change)="onChangeAmount($event, vdData)"
                       (keyup.enter)="onChangeAmount($event, vdData)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }" />
                <input type="text"
                       name="AmountD{{ i }}"
                       id="AmountD{{ i }}"
                       appTwoDigitDecimaNumber
                       readonly
                       [ngClass]="{ 'mt-1': i == 0 }"
                       class="form-control input-text-css text-right pr-3"
                       *ngIf="vdData.TranType == 'DR'" />
              </div>
            </ng-container>

            <ng-container *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'">
              <div class="col-md-4 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Account Head</span>
                </div>

                <input type="search"
                       class="form-control input-text-css"
                       (change)="onChangeChargesHead(vdData, i)"
                       [(ngModel)]="vdData.ChargeHeadCategory"
                       id="ChargeHeadId{{ i }}"
                       name="ChargeHeadId{{ i }}"
                       list="dynmicChargeHead"
                       autocomplete="off"
                       required
                       #refChargeHeadId="ngModel"
                       placeholder="Select Head"
                       [ngClass]="{
                    'is-invalid': df.submitted && refChargeHeadId.invalid,
                    'alert-warning':
                      refChargeHeadId.invalid &&
                      (refChargeHeadId.dirty ||
                        refChargeHeadId.touched ||
                        refChargeHeadId.untouched),
                    'mt-1': i == 0
                  }" />
                <datalist id="dynmicChargeHead">
                  <option *ngFor="let item of accountHeadDropdown"
                          [value]="item.Account_Name"
                          title="item.Account_Name"></option>
                </datalist>
              </div>
              <div class="col-md-4 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Branch</span>
                </div>


                <input type="search"
                       class="form-control input-text-css"
                       [(ngModel)]="vdData.Branch_Name"
                       id="BranchId{{ i }}"
                       name="BranchId{{ i }}"
                       list="dynmicBranch"
                       autocomplete="off"
                       required
                       #refBranchId="ngModel"
                       placeholder="Select Branch"
                       (change)="onChangebranch(vdData)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refBranchId.invalid,
                    'alert-warning':
                      refBranchId.invalid &&
                      (refBranchId.dirty ||
                        refBranchId.touched ||
                        refBranchId.untouched),
                    'mt-1': i == 0
                  }" />
                <datalist id="dynmicBranch">
                  <option *ngFor="let branch of empBranchDropdown"
                          [value]="branch.Branch_Name"
                          title="branch.Branch_Name"></option>
                </datalist>
              </div>
              <div class="col-md-2 mr_top pr-2">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class=""
                        [ngClass]="{ 'required-lable': vdData.TranType == 'DR' }"
                        *ngIf="i == 0">Amount(DR)</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       id="Amount{{ i }}"
                       *ngIf="vdData.TranType == 'DR'"
                       appTwoDigitDecimaNumber
                       [disabled]="!vdData.Branch_Name"
                       class="form-control input-text-css text-right pr-3"
                       required
                       #refAmount="ngModel"
                       (change)="onChangeAmount($event, vdData)"
                       (keyup.enter)="onChangeAmount($event, vdData)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }"
                       [(ngModel)]="vdData.Amount" />
                <input type="text"
                       name="AmountD{{ i }}"
                       id="AmountD{{ i }}"
                       appTwoDigitDecimaNumber
                       readonly
                       [ngClass]="{ 'mt-1': i == 0 }"
                       class="form-control input-text-css text-right pr-3"
                       *ngIf="vdData.TranType == 'CR'" />
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class=""
                        [ngClass]="{ 'required-lable': vdData.TranType == 'CR' }"
                        *ngIf="i == 0">Amount(CR)</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       id="Amount{{ i }}"
                       appTwoDigitDecimaNumber
                       *ngIf="vdData.TranType == 'CR'"
                       [disabled]="!vdData.Branch_Name"
                       class="form-control input-text-css text-right pr-3"
                       required
                       #refAmount="ngModel"
                       (change)="onChangeAmount($event, vdData)"
                       (keyup.enter)="onChangeAmount($event, vdData)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }"
                       [(ngModel)]="vdData.Amount" />
                <input type="text"
                       name="AmountD{{ i }}"
                       appTwoDigitDecimaNumber
                       readonly
                       id="AmountD{{ i }}"
                       [ngClass]="{ 'mt-1': i == 0 }"
                       class="form-control input-text-css text-right pr-3"
                       *ngIf="vdData.TranType == 'DR'" />
              </div>
            </ng-container>
          </div>
        </div>


        <button type="button"
                class="remove"
                (click)="removeData(i)"
                [ngClass]="{ 'no-head': i != 0 }"
                *ngIf="voucherModel.Voucher_Detail.length > 1">
          -
        </button>
        <button type="button"
                class="remove"
                (click)="onRefresh(vdData)"
                *ngIf="voucherModel.Voucher_Detail.length == 1">
          -
        </button>
        <button type="button"
                class="addmore"
                (click)="addMoreData()"
                *ngIf="i == voucherModel.Voucher_Detail.length - 1"
                [ngClass]="{
                both: voucherModel.Voucher_Detail.length>
          0,
          'no-head': i != 0
          }"
          [disabled]="!df.form.valid"
          >
          +
        </button>
      </div>

      <div class="row m-0">
        <div class="col-md-12"><hr /></div>
      </div>

      <div class="col-md-12 row pl-3 m-0"
           *ngIf="totalCalculator"
           style="width: 96.3%">
        <div class="col-md-12 bg-success1 p-1">
          <div class="row">
            <div class="col-md-8">Grand Total :</div>
            <div class="text-right pr-1 col-md-2">
              <span>{{ totalCalculator.totalDR.toFixed(2) }}</span>
            </div>
            <div class="text-right col-md-2">
              <span>{{ totalCalculator.totalCR.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>


    </form>
    <div class="row m-0 mt-2" *ngIf="isBankTag">
      <div class="col-md-3">
        <span class="required-lable">Select Mode of Payment</span>
        <select name="Voucher_Mode_of_Payment"
                id="Voucher_Mode_of_Payment"
                required
                #refVoucher_Mode_of_Payment="ngModel"
                [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Mode_of_Payment.invalid,
            'alert-warning':
              refVoucher_Mode_of_Payment.invalid &&
              (refVoucher_Mode_of_Payment.dirty ||
                refVoucher_Mode_of_Payment.touched ||
                refVoucher_Mode_of_Payment.untouched)
          }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                class="form-control input-text-css"
                (change)="onChangeMode()">
          <option value="">Select Mode Of Payment</option>
          <option value="Cheque">Cheque</option>
          <option value="RTGS">RTGS</option>
          <option value="Gateway">E-Gateway</option>
          <option value="NEFT">NEFT</option>
          <option value="IMPS">IMPS</option>
          <option value="Transfer">Transfer</option>
        </select>
      </div>
      <ng-container *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'">
        <div class="col-md-3">
          <span class="required-lable">Bank Name</span>
          <input type="text"
                 required
                 #refVoucher_Bank_Name="ngModel"
                 placeholder="Bank Name"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Bank_Name.invalid,
              'alert-warning':
                refVoucher_Bank_Name.invalid &&
                (refVoucher_Bank_Name.dirty ||
                  refVoucher_Bank_Name.touched ||
                  refVoucher_Bank_Name.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
                 name="Voucher_Bank_Name"
                 id="Voucher_Bank_Name"
                 class="form-control input-text-css" />
        </div>
        <div class="col-md-2">
          <span class="required-lable">Cheque No</span>
          <input type="text"
                 required
                 #refVoucher_Cheque_No="ngModel"
                 placeholder="Cheque No"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Cheque_No.invalid,
              'alert-warning':
                refVoucher_Cheque_No.invalid &&
                (refVoucher_Cheque_No.dirty ||
                  refVoucher_Cheque_No.touched ||
                  refVoucher_Cheque_No.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
                 name="Voucher_Cheque_No"
                 id="Voucher_Cheque_No"
                 class="form-control input-text-css" />
        </div>
        <div class="col-md-2">
          <span class="required-lable">Cheque Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervcd"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="Voucher_Cheque_Date"
                   id="Voucher_Cheque_Date"
                   #refVoucher_Cheque_Date="ngModel"
                   [ngClass]="{
                'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid,
                'alert-warning':
                  refVoucher_Cheque_Date.invalid &&
                  (refVoucher_Cheque_Date.dirty ||
                    refVoucher_Cheque_Date.touched ||
                    refVoucher_Cheque_Date.untouched)
              }"
                   class="form-control input-text-css"
                   [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervcd"></mat-datepicker-toggle>
            <mat-datepicker #pickervcd></mat-datepicker>
          </div>
          <!-- <input name="Voucher_Cheque_Date" id="Voucher_Cheque_Date" required #refVoucher_Cheque_Date="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid, 'alert-warning': refVoucher_Cheque_Date.invalid  && (refVoucher_Cheque_Date.dirty || refVoucher_Cheque_Date.touched || refVoucher_Cheque_Date.untouched) }"
          [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
          [(bsValue)]="voucherModel.Voucher.Voucher_Cheque_Date"
          value="{{ voucherModel.Voucher.Voucher_Cheque_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
          class="form-control input-text-css" bsDatepicker> -->
        </div>
        <div class="col-md-2">
          <span class="">Cheque Clear Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervccd"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="Voucher_Cheque_Clear_Date"
                   id="Voucher_Cheque_Clear_Date"
                   #refVoucher_Cheque_Clear_Date="ngModel"
                   [ngClass]="{
                'is-invalid':
                  f.submitted && refVoucher_Cheque_Clear_Date.invalid,
                'alert-warning':
                  refVoucher_Cheque_Clear_Date.invalid &&
                  (refVoucher_Cheque_Clear_Date.dirty ||
                    refVoucher_Cheque_Clear_Date.touched ||
                    refVoucher_Cheque_Clear_Date.untouched)
              }"
                   class="form-control input-text-css"
                   [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervccd"></mat-datepicker-toggle>
            <mat-datepicker #pickervccd></mat-datepicker>
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'">
        <div class="col-md-4">
          <span class="required-lable">Ref No</span>
          <input type="text"
                 required
                 #refVoucher_E_Ref_No="ngModel"
                 placeholder="Ref No"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_E_Ref_No.invalid,
              'alert-warning':
                refVoucher_E_Ref_No.invalid &&
                (refVoucher_E_Ref_No.dirty ||
                  refVoucher_E_Ref_No.touched ||
                  refVoucher_E_Ref_No.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
                 name="Voucher_E_Ref_No"
                 id="Voucher_E_Ref_No"
                 class="form-control input-text-css" />
        </div>
        <div class="col-md-4">
          <span class="required-lable">Transaction Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervetd"
                   dateConvert
                   [max]="voucherModel.Voucher.Voucher_Date"
                   placeholder="DD/MM/YYYY"
                   name="Voucher_E_Transaction_Date"
                   id="Voucher_E_Transaction_Date"
                   #refVoucher_E_Transaction_Date="ngModel"
                   [ngClass]="{
                'is-invalid':
                  f.submitted && refVoucher_E_Transaction_Date.invalid,
                'alert-warning':
                  refVoucher_E_Transaction_Date.invalid &&
                  (refVoucher_E_Transaction_Date.dirty ||
                    refVoucher_E_Transaction_Date.touched ||
                    refVoucher_E_Transaction_Date.untouched)
              }"
                   class="form-control input-text-css"
                   [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervetd"></mat-datepicker-toggle>
            <mat-datepicker #pickervetd></mat-datepicker>
          </div>

        </div>
      </ng-container>
    </div>
    <div class="row m-0 mt-2">
      <div class="col-md-12">
        <span class="">Voucher Narration</span>
        <input type="text"
               #refVoucher_Narration="ngModel"
               [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Narration.invalid,
            'alert-warning':
              refVoucher_Narration.invalid &&
              (refVoucher_Narration.dirty ||
                refVoucher_Narration.touched ||
                refVoucher_Narration.untouched)
          }"
               [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
               name="Voucher_Narration"
               id="Voucher_Narration"
               class="form-control input-text-css" />
      </div>
    </div>
    <div class="row m-0 mt-2 upload">
      <div class="col-md-10">
        Browse Doc:<i class="fa fa-upload ml-2"
                      (click)="docUFile.click()"
                      aria-hidden="true"></i>
        <ng-container *ngIf="docModal.DocumentImages.length > 0">
          <div *ngFor="let doc of docModal.DocumentImages; let i = index">
            <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ doc.DocFileName }}
            <i class="fa fa-times ml-2"
               aria-hidden="true"
               style="cursor: pointer"
               (click)="removeDFile(i)"></i>
          </div>
        </ng-container>
        <input #docUFile
               type="file"
               [multiple]="true"
               accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
               (change)="fileChangeListenerD(docUFile.files, docUFile)"
               style="display: none" />
      </div>
      <div class="col-md-2 text-right">
        <button type="button"
                class="mt-3 btn font-size-12 button-btn"
                (click)="saveVoucher()"
                [class.spinner]="loading"
                [disabled]="!f.form.valid || !df.form.valid">
          Save
        </button>

      </div>
    </div>
  </form>

  <div style="font-size: x-large; clear: both; text-align: center; color: red;" class="pt-5 pb-5" *ngIf="voucherAdd==false">
    <p>Access Denied</p><p> Contact Adminstrator</p>
  </div>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearch()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <form #sf="ngForm" class="col-md-12" novalidate>
            <div class="row m-0">
              <div class="col-md-12">
                <span class="">Search On</span>
                <div class="row m-0 col-md-12 p-0">
                  <div class="col-md-6">
                    <span class="form-check-inline">
                      <label class="form-check-label" for="Is_LOSAppliaction0">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="Is_LOSAppliaction0"
                          name="Is_LOSAppliaction"
                          [(ngModel)]="selectedData.Is_LOSAppliaction"
                          required
                          value="0"
                        />
                        Loan No
                      </label>
                    </span>
                  </div>
                  <div class="col-md-6">
                    <span class="form-check-inline">
                      <label class="form-check-label" for="Is_LOSAppliaction1">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="Is_LOSAppliaction1"
                          name="Is_LOSAppliaction"
                          [(ngModel)]="selectedData.Is_LOSAppliaction"
                          required
                          value="1"
                        />
                        Application No
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <span>Value </span>
                <input
                  type="text"
                  required
                  name="SearchValue"
                  id="SearchValue"
                  #refSearchValue="ngModel"
                  [ngClass]="{
                    'is-invalid': sf.submitted && refSearchValue.invalid,
                    'alert-warning':
                      refSearchValue.invalid &&
                      (refSearchValue.dirty ||
                        refSearchValue.touched ||
                        refSearchValue.untouched)
                  }"
                  [(ngModel)]="selectedData.SearchValue"
                  class="form-control input-text-css"
                  (keyup.enter)="Get_Acc_Search_LoanNo()"
                />
              </div>
              <div class="row col-md-6 m-0 align-items-center">
                <button
                  type="button"
                  class="mt-3 btn font-size-12 button-btn"
                  (click)="Get_Acc_Search_LoanNo()"
                  [class.spinner]="loading"
                  [disabled]="!sf.form.valid"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="row m-o mt-3">
              <div class="col-md-12">
                <mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="LoanId">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="LoanNo">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Application/Loan No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.LoanNo
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Product_Name">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Product Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Product_Name
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Customer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Customer
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Phone No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.PhoneNo
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch_Name">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Branch</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Branch_Name
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 75px"
                      *matHeaderCellDef
                      >Action</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 75px"
                    >
                      <button
                        type="button"
                        class="font-size-12 button-btn"
                        (click)="selectSearchData(row)"
                      >
                        Select
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    style="cursor: pointer"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </form>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              class="btn font-size-12 button-btn"
              (click)="OnCloseSearch()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
