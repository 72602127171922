import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { catchError, tap, filter } from 'rxjs/operators';
import { SnackbarComponent } from '../../snackbar/snackbar.component'
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogService } from '../../Shared/dialog.service'
import { MasterService } from '../../Shared/app.Masters.Service';

declare var $: any;

@Component({
  selector: 'app-voucher-layout',
  templateUrl: './voucher-layout.component.html',
  styleUrls: ['./voucher-layout.component.scss']
})
export class VoucherLayoutComponent implements OnInit {

  PageAcess_Receipt: any;
  PageAcess_Payment: any;
  PageAcess_journal: any;
  PageAcess_Contra: any;
  showSpinner = false;
  //#region < Constructor AppUserLayoutComponent >
  constructor(private MasterService: MasterService, private _Route: Router, public snackBar: MatSnackBar, private http: HttpClient, private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private _location: Location, private dataSharingService: DataSharingService, private dialog: DialogService) {
    var data = JSON.parse(sessionStorage.getItem('currentUser'));
    //this._Route.navigate(['/lms-accounting/voucherNew/voucher']);
    this.MasterService.Get_Page_Rights_Of_EMP({ MMID: '196', LoginUserId: data.userId }).subscribe((result: any) => {
      this.showSpinner = true;
      if (result.length > 0) {
        let AllPage = result;
        
        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Receipt") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Receipt") != null) {
          this.PageAcess_Receipt = AllPage.filter(x => x.Page_Name == "Vouchers Entry - Receipt");
        }
        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Payment") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Payment") != null) {
          this.PageAcess_Payment = AllPage.filter(x => x.Page_Name == "Vouchers Entry - Payment");
        }
        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Contra") != null) {
          this.PageAcess_Contra = AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra");
        }
        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") != null) {
          this.PageAcess_journal = AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal");
        }

        const navreceipttab = document.getElementById('nav-receipt-tab');
        const navpaymenttab = document.getElementById('nav-payment-tab');
        const navjournaltab = document.getElementById('nav-journal-tab');
        const navcontratab = document.getElementById('nav-contra-tab');

        if (navcontratab != null && this.PageAcess_Contra != undefined && this.PageAcess_Contra != null && this.PageAcess_Contra[0].Add == 1) {
          document.getElementById("nav-contra-tab").className = "nav-item tab-active";
          navcontratab.removeAttribute("hidden");
          let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));
          this._Route.navigate(['/lms-accounting/voucherNew/voucher-contra'], { queryParams: { fyear: FyearId, BranchId: 0} });
          
        }
        else {
          navcontratab.setAttribute("hidden", "true");
        }

        if (navjournaltab != null && this.PageAcess_journal != undefined && this.PageAcess_journal != null && this.PageAcess_journal[0].Add == 1) {
          document.getElementById("nav-journal-tab").className = "nav-item tab-active";
          document.getElementById("nav-contra-tab").className = "nav-item";
          let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));

          this._Route.navigate(['/lms-accounting/voucherNew/voucher-journal'], { queryParams: { fyear: FyearId, BranchId: 0 } });
          navjournaltab.removeAttribute("hidden");
        }
        else {
          navjournaltab.setAttribute("hidden", "true");
        }

        if (navpaymenttab != null && this.PageAcess_Payment != undefined && this.PageAcess_Payment != null && this.PageAcess_Payment[0].Add == 1) {
          document.getElementById("nav-payment-tab").className = "nav-item tab-active";
          document.getElementById("nav-journal-tab").className = "nav-item";
          document.getElementById("nav-contra-tab").className = "nav-item";
          navpaymenttab.removeAttribute("hidden");
          let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));

          this._Route.navigate(['/lms-accounting/voucherNew/voucher-payment'], { queryParams: { fyear: FyearId, BranchId: 0 } });
        }
        else {
          navpaymenttab.setAttribute("hidden", "true");
        }

        if (navreceipttab != null && this.PageAcess_Receipt != undefined && this.PageAcess_Receipt != null && this.PageAcess_Receipt[0].Add == 1) {
          document.getElementById("nav-receipt-tab").className = "nav-item tab-active";
          document.getElementById("nav-payment-tab").className = "nav-item";
          document.getElementById("nav-journal-tab").className = "nav-item";
          document.getElementById("nav-contra-tab").className = "nav-item";
          navreceipttab.removeAttribute("hidden");
          let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));

          this._Route.navigate(['/lms-accounting/voucherNew/voucher-receipt'], { queryParams: { fyear: FyearId, BranchId: 0 } });
        }
        else {
          navreceipttab.setAttribute("hidden", "true");
        }
        this.showSpinner = false;
      }
    });
  }

  ngOnInit() {
  }
}
