<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-3 formborder">
  <div class="col-md-4 p-1" style="display: none">
    <span class="required-lable">Product Category :</span>
    <select
      name="SelectProductCategory"
      id="SelectProductCategory"
      #refSelectProductCategory="ngModel"
      disabled
      [(ngModel)]="SelectProductCategory"
      class="form-control input-text-css"
      required
    >
      <option value="">Select Category</option>
      <option
        *ngFor="let productCategory of ProductCategoryDropdown"
        [value]="productCategory.ProductCatId"
      >
        {{ productCategory.Product_Category }}
      </option>
    </select>
  </div>
  <div class="col-md-6 p-1">
    <span class="required-lable">Product Name :</span>
    <input
      required
      type="text"
      #refProductName="ngModel"
      name="ProductName"
      id="ProductName"
      placeholder="Product Name"
      [(ngModel)]="ProductName"
      class="form-control input-text-css"
      disabled
    />
  </div>
  <div class="col-md-6 p-1">
    <span class="required-lable">Product Short Name :</span>
    <input
      required
      type="text"
      #refProductShortName="ngModel"
      name="ProductShortName"
      id="ProductShortName"
      disabled
      [(ngModel)]="ProductShortName"
      placeholder="Product Short Name"
      class="form-control input-text-css"
    />
  </div>
</div>

<form
  #dbf="ngForm"
  class="formborder mt-2"
  style="padding: 0px 0px 0px 0px !important"
  (ngSubmit)="onSaveNewProduct()"
  novalidate
>
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <h1 class="product-charges">Product Charges</h1>
    </div>
    <div class="col-md-12 row m-0 p-0 ml-0">
      <div class="col-md-1 p-1">
        <span>&nbsp;</span>
      </div>
      <div class="col-md-3 p-1">
        <span style="font-weight: bold">Charges head</span>
      </div>
      <div class="col-md-2 p-1">
        <span style="font-weight: bold">Account head</span>
      </div>
      <div class="col-md-2 p-1">
        <span style="font-weight: bold">Charges type</span>
      </div>
      <div class="col-md-2 p-1">
        <span style="font-weight: bold">Charges Value</span>
      </div>
      <div class="col-md-1 p-1"></div>
      <div class="col-md-1 p-1">
        <!--<span style="font-weight:bold">Charges ApplyOn</span>-->
      </div>
    </div>
    <div
      class="col-md-12 row m-0 p-0 ml-0"
      *ngFor="let charges of chargesHead; let i = index"
    >
      <div class="col-md-3 p-1">
        <mat-checkbox
          [(ngModel)]="charges.select"
          id="multipleTrunche1{{ i }}"
          name="multipleTrunche1{{ i }}"
          [disabled]="IsDisabledAccount && charges.Type != 'New'"
        ></mat-checkbox>

        <input
          required
          type="text"
          [(ngModel)]="charges.ChagesHead"
          style="width: 235px; display: inline-block"
          disabled
          name="ChagesHead{{ i }}"
          id="ChagesHead{{ i }}"
          class="form-control input-text-css ml-1"
        />
      </div>
      <div class="col-md-3 p-1">
        <select
          name="Account_HeadId{{ i }}"
          id="Account_HeadId{{ i }}"
          [(ngModel)]="charges.Account_HeadId"
          [disabled]="IsDisabledAccount && charges.Type != 'New'"
          class="form-control input-text-css"
          [required]="charges.select"
          #refAccount_HeadId="ngModel"
          [ngClass]="{
            'is-invalid': dbf.submitted && refAccount_HeadId.invalid,
            'alert-warning':
              refAccount_HeadId.invalid &&
              (refAccount_HeadId.dirty ||
                refAccount_HeadId.touched ||
                refAccount_HeadId.untouched)
          }"
        >
          <option value="">Select</option>
          <option *ngFor="let ca of chargableAccount" [value]="ca.AccountId">
            {{ ca.Account_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <select
          name="chargeType{{ i }}"
          id="chargeType{{ i }}"
          [(ngModel)]="charges.Charge_Type"
          class="form-control input-text-css"
          [disabled]="IsDisabled"
        >
          <option value="">Select</option>
          <option value="Fixed">Fixed</option>
          <option value="Percentage">Percentage</option>
          <option value="Open">Open</option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <input
          type="number"
          [(ngModel)]="charges.Charge_Values"
          placeholder="Charges Value"
          class="form-control input-text-css"
          name="ChargeValues{{ i }}"
          id="ChargeValues{{ i }}"
          [disabled]="IsDisabled"
        />
      </div>
      <div class="col-md-1 p-1">
        <button
          type="button"
          (click)="onSwap(charges)"
          style="padding: 7px !important; margin: 0px !important"
          class="btn font-size-12 button-btn"
          *ngIf="IsDisabledAccount && charges.Type != 'New'"
        >
          Swap A/C
        </button>
      </div>
      <div class="col-md-1 p-1">
        <!--<select name="chargeApplyOn{{i}}" id="chargeApplyOn{{i}}" [(ngModel)]="charges.Charge_ApplyOn"
          class="form-control input-text-css" [required]="charges.select&&charges.Charge_Type!='Open'" #refchargeApplyOn="ngModel"
          [ngClass]="{ 'is-invalid': dbf.submitted && refchargeApplyOn.invalid, 'alert-warning': refchargeApplyOn.invalid  && (refchargeApplyOn.dirty || refchargeApplyOn.touched || refchargeApplyOn.untouched) }">
          <option value=""> Select </option>
          <option value="Loan Amount"> Loan Amount </option>
          <option value="POS Outstanding (Only Due)"> POS Outstanding (Only Due) </option>
          <option value="POS Balance (Due + Future)"> POS Balance (Due + Future) </option>
          <option value="Installment Outstanding (Only Due)"> Installment Outstanding (Only Due) </option>
          <option value="Installment Balance (Due + Future)"> Installment Balance (Due + Future) </option>
          <option value="Per Instance"> Per Instance </option>
        </select>-->
        <button
          type="button"
          class="addmore both no-head"
          (click)="addMoreFirstData()"
          [disabled]="IsDisabled"
          *ngIf="i + 1 == DefaultData && ChargesModel.Charges_Detail == 0"
        >
          +
        </button>
      </div>
    </div>

    <form #df="ngForm" novalidate class="col-md-12 row m-0 p-0 ml-0">
      <div
        class="col-md-12 row m-0 p-0"
        *ngFor="let Charges1 of ChargesModel.Charges_Detail; let j = index"
      >
        <div class="col-md-3 p-1">
          <mat-checkbox
            [(ngModel)]="Charges1.Select"
            id="multipleTrunche1{{ j }}"
            name="multipleTrunche1{{ j }}"
            [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
          ></mat-checkbox>

          <select
            name="ChagesHeadDROP{{ j }}"
            id="ChagesHeadDROP{{ j }}"
            style="width: 235px; display: inline-block"
            [(ngModel)]="Charges1.ChagesHead"
            (change)="onChangeChargesHead(Charges1, j)"
            class="form-control input-text-css ml-1"
            [required]="Charges1.Select"
            #refChagesHead="ngModel"
            [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
            [ngClass]="{
              'is-invalid': df.submitted && refChagesHead.invalid,
              'alert-warning':
                refChagesHead.invalid &&
                (refChagesHead.dirty ||
                  refChagesHead.touched ||
                  refChagesHead.untouched)
            }"
          >
            <option value="">Select</option>
            <option *ngFor="let ca of ChargesHead1" [value]="ca.Id">
              {{ ca.ChagesHead }}
            </option>
          </select>
        </div>
        <div class="col-md-3 p-1">
          <select
            name="Account_HeadIdDROP{{ j }}"
            id="Account_HeadIdDROP{{ j }}"
            [(ngModel)]="Charges1.Account_HeadId"
            class="form-control input-text-css"
            [required]="Charges1.Select"
            #refAccount_HeadId1="ngModel"
            [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
            [ngClass]="{
              'is-invalid': df.submitted && refAccount_HeadId1.invalid,
              'alert-warning':
                refAccount_HeadId1.invalid &&
                (refAccount_HeadId1.dirty ||
                  refAccount_HeadId1.touched ||
                  refAccount_HeadId1.untouched)
            }"
          >
            <option value="">Select</option>
            <option *ngFor="let ca of chargableAccount" [value]="ca.AccountId">
              {{ ca.Account_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-2 p-1">
          <select
            name="chargeTypeDROP{{ j }}"
            id="chargeTypeDROP{{ j }}"
            [(ngModel)]="Charges1.Charge_Type"
            [disabled]="IsDisabled"
            class="form-control input-text-css"
          >
            <option value="">Select</option>
            <option value="Fixed">Fixed</option>
            <option value="Percentage">Percentage</option>
            <option value="Open">Open</option>
          </select>
        </div>
        <div class="col-md-2 p-1">
          <input
            type="number"
            [(ngModel)]="Charges1.Charge_Values"
            placeholder="Charges Value"
            [disabled]="IsDisabled"
            name="ChargeValuesDROP{{ j }}"
            id="ChargeValuesDROP{{ j }}"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-1 p-1">
          <button
            type="button"
            (click)="onSwap(Charges1)"
            style="padding: 7px !important; margin: 0px !important"
            class="btn font-size-12 button-btn"
            *ngIf="IsDisabledAccount && Charges1.Type != 'New'"
          >
            Swap A/C
          </button>
        </div>
        <!--<div class="col-md-2 p-1">
          <select name="chargeApplyOnDROP{{j}}" id="chargeApplyOnDROP{{j}}" [(ngModel)]="Charges1.Charge_ApplyOn"
                  class="form-control input-text-css" [required]="Charges1.Select && Charges1.Charge_Type != 'Open'" #refchargeApplyOn1="ngModel"
                  [ngClass]="{ 'is-invalid': df.submitted && refchargeApplyOn1.invalid, 'alert-warning': refchargeApplyOn1.invalid  && (refchargeApplyOn1.dirty || refchargeApplyOn1.touched || refchargeApplyOn1.untouched) }">
            <option value=""> Select </option>
            <option value="Loan Amount"> Loan Amount </option>
            <option value="POS Outstanding (Only Due)"> POS Outstanding (Only Due) </option>
            <option value="POS Balance (Due + Future)"> POS Balance (Due + Future) </option>
            <option value="Installment Outstanding (Only Due)"> Installment Outstanding (Only Due) </option>
            <option value="Installment Balance (Due + Future)"> Installment Balance (Due + Future) </option>
            <option value="Per Instance"> Per Instance </option>
          </select>
        </div>-->
        <div class="col-md-1 p-1">
          <button
            type="button"
            class="remove"
            (click)="removeData(j)"
            [ngClass]="{ 'no-head': j != 0 }"
            [disabled]="IsDisabled"
            [hidden]="IsDisabledAccount && Charges1.Type != 'New'"
          >
            -</button
          >&nbsp;&nbsp;&nbsp;
          <button
            type="button"
            class="addmore"
            (click)="addMoreData()"
            [ngClass]="{
              both: ChargesModel.Charges_Detail.length > 1,
              'no-head': j != 0
            }"
            [disabled]="!df.form.valid || IsDisabled"
            *ngIf="j == ChargesModel.Charges_Detail.length - 1"
          >
            +
          </button>
        </div>
      </div>
    </form>
    <div class="col-md-12 row m-0 p-0">
      <div class="col-md-12 text-right p-1">
        <!-- <button type="button" (click)="OnCloseProductModal()" class="btn btn-light font-size-12" data-dismiss="modal">
          Cancel
        </button> -->
        <button
          type="button"
          (click)="onSaveNewProduct()"
          *ngIf="IsUpdate"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!dbf.form.valid"
        >
          Update
        </button>
        <button
          type="button"
          (click)="onEditAccount()"
          *ngIf="IsEdit"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
        >
          Edit
        </button>
      </div>
    </div>
  </div>
</form>

<div
  class="modal fade in"
  id="SwapAccountModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Swap Account
        </h6>
        <button
          type="button"
          (click)="onCloseSwapAccountModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #f="ngForm" novalidate class="formborder">
          <div class="row mt-4 ml-3">
            <div class="col-md-12">
              <select
                name="ChagesHeadswap"
                id="ChagesHeadswap"
                disabled
                [(ngModel)]="SwapChanges.Id"
                class="form-control input-text-css"
              >
                <option value="">Select</option>
                <option *ngFor="let ca of mixChargesHead" [value]="ca.Id">
                  {{ ca.ChagesHead }}
                </option>
              </select>
              <!--<input [(ngModel)]="SwapChanges.ChagesHead" placeholder="Charges Value" disabled
                     name="ChargesHeadId" id="ChargesHeadId" class="form-control input-text-css">-->
            </div>
          </div>
          <div class="row mt-4 ml-3">
            <div class="col-md-6">
              <select
                name="OldAccountId"
                id="OldAccountId"
                [(ngModel)]="SwapChanges.Account_HeadId"
                disabled
                class="form-control input-text-css"
              >
                <option
                  *ngFor="let ca of chargableAccount"
                  [value]="ca.AccountId"
                >
                  {{ ca.Account_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <select
                name="NewAccountId"
                id="NewAccountId"
                [(ngModel)]="NewAccountId"
                class="form-control input-text-css"
                required
                #refNewAccountId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refNewAccountId.invalid,
                  'alert-warning':
                    refNewAccountId.invalid &&
                    (refNewAccountId.dirty ||
                      refNewAccountId.touched ||
                      refNewAccountId.untouched)
                }"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let ca of chargableAccount"
                  [value]="ca.AccountId"
                >
                  {{ ca.Account_Name }}
                </option>
              </select>
            </div>
          </div>
        </form>

        <div class="text-right mb-2" style="margin-top: 6px">
          <button
            type="button"
            (click)="Swap()"
            [disabled]="!f.form.valid"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Swap
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
