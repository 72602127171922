import { Component, OnInit, ViewChild, Input, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { ReportsService } from "../services/reports.service";
import { DatePipe } from '@angular/common';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
// import { DatePipe } from '@angular/common';

// Excel export code start here
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
// Excel export code end here

@Component({
  selector: 'app-exposure-report1',
  templateUrl: './exposure-report1.component.html',
  styleUrls: ['./exposure-report1.component.scss']
})
export class ExposureReport1Component implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  BranchesList: any = [];
  ProductList: any = [];
  dataSource: any;

  displayedColumns: string[] = [
    "Loan_Id", "LoanAcNo", "CustomerName", "Gender", "Loan_Date", "Application_AgreementValue", "Branch_Name", "Product_name","DisbursmentAmount", "Principal_Due", 
    "Principal_Mature", "Principal_Balance", "Installment_Due", "Installment_Mature",	"Installment_Balance", "Interest_Income", 
    "Future_Interest", "Other_Due", "Other_Receipt",
    "Other_Balance"


  ];//"Voucher_Inst", "Diff_Allocation"
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  DateFrom: any = new Date();
  selectedId: any;
  JsonData: any[] = [];
  AccountingCatDropdown: any[] = [];
  LoanAccountCategory:any='0'
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  
  constructor(
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private MasterService: MasterService,
    private reportsService: ReportsService,
    
    private datePipe: DatePipe,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Exposure Report By Voucher");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Loan_Accounting_Category_ForDropdown();
    
  }
  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 }).subscribe((res: any) => {
      this.AccountingCatDropdown = res;
    })
  }
  OnSearchReport_Exposure(){
    this.showSpinner = true;
    this.reportsService.Report_Exposure1({ AsOnDate: this.DateFrom, LoanAccountCategory: this.LoanAccountCategory }).subscribe((res: any) => {
      //console.log("Report :", res);
      
        this.JsonData = res;
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
     
    });
    
  }
  
  totaltypewise(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : 0;      
    }
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, excelFileName + new  Date().getTime() + EXCEL_EXTENSION);
  }
  exportexcelNew(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("exposure_report_voucher") as HTMLTableElement;
    const worksheetName = 'EXPOSURE REPORT VOUCHER'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  // Excel export in backend code end here
}
