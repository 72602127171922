import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from 'moment';
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from '../../snackbar/snackbar.component';

declare var $: any;

@Component({
  selector: "app-lms-pdc-registration",
  templateUrl: "./lms-pdc-registration.component.html",
  styleUrls: ["./lms-pdc-registration.component.scss"],
})
export class LmsPdcRegistrationComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  dataSource: any;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  displayedColumns: string[] = [];
  CurrentDate: any = new Date();
  isShowSave: boolean = true;
  isShowUpdate: boolean = false;
  NachAmount: any;
  PresentOnCompanyBank: any;
  PresentBankInCompany: any;
  loading: boolean = false;
  Reason: any;
  StatusDate: any;
  NachCode: any;
  EMIFrom: any = 0;
  EMITo: any = 0;
  amortizationId: any;
  applicationDetail: any;
  status: any;
  StatusDB: any = 'Pending';
  EMIFromDB: any;
  Int_Id: any;
  IsFillDetail: boolean = false;
  IsFillUpDetail: boolean = false;
  isShowRepresent: boolean = false;
  isShowEdit: boolean = false;
  FailCount: any = 0;
  Qtly: any;
  Mthly: any;
  HYrly: any;
  chqId: any;
  presented: boolean = true;
  isShowGenerate: boolean = false;
  LoanAcNo: any;
  Fixed: any;
  Maximum: boolean = true;
  Period: any;
  UntilCancelled:boolean = true;
  From: any;
  To: any;
  PdcType: any;
  BranchesList: any = [];
  Frequency: any = 'presented';
  DebitType: any = 'Maximum';
  caseNo: any = '';
  process_period: any;
  CaseDateFrom: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);;
  CaseDateTo: any = new Date();

  ChqFrom: any;
  ChqTo: any;
  Type: any = 'RPDC';
  LastEMI: any;
  SearchStatus: any = 'Pending For Generate';
  BankInCompany: any = '';
  SelectBranch: any = '';
  Regenerate: any = '';
  PendingRegenerate: any = '';
  BankList: any;
  CustomerId: any = 0;
  Loan_Id: any;
  NoChqs: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("PDC Generation");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.getBankDropdown();
    this.LOS_GetFileCheckingHOList();
  }
  getBankDropdown() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: 'Bank', LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.BankList = JSON.parse(JSON.stringify(res));
      });
  }
  LOS_GetFileCheckingHOList() {
    //this.showSpinner = true;

    this.displayedColumnsLoan = [
      "chqId",
      "CaseNo",
      "CaseDate",
      "CustomerName",
      "BranchBank",
      "PdcType",
      "CustomerACType",
      "PresentOn",
      "PendingDays",
      "Status",
      "Action"

    ];


    //this.lmsService
    //  .LMS_GET_NACHINFO({ LoanAcNo: '' })
    //  .subscribe((res: any) => {
    //    console.log(res);
    //    this.FileCheckingList = JSON.parse(JSON.stringify(res));
    //    console.log('dataSourceLoan', res)
    //    this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
    //    this.dataSourceLoan.sort = this.sort;
    //    this.dataSourceLoan.paginator = this.paginator;
    //    this.showSpinner = false;
    //    this.LoanAcNo = res[0].LoanAcNo;
    //    this.status = res[0].status;
    //    this.Int_Id = res[0].Int_Id;
    //    this.FailCount = res[0].FailCount;
    //    this.NachAmount = res[0].NachAmount;
    //    this.NachCode = res[0].NachCode;
    //    this.EMIFrom = res[0].EMIFrom;
    //    this.EMITo = res[0].EMITo;
    //    this.PresentBankInCompany = res[0].PresentBankInCompany;
    //    this.PresentOnCompanyBank = new Date(moment(res[0].PresentOnCompanyBank).format('MM/DD/YYYY'));
    //    this.Reason = res[0].Reason;
    //    this.StatusDate = new Date(moment(res[0].StatusDate).format('MM/DD/YYYY'));
    //  });
  }
  statusChange(event: any) {
    console.log(this.status);
    console.log(event);
    this.status = this.status;
  }
  onCloseNachDetail() {
    $('#NachDetailModel').modal('hide');
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onSaveNachDetail() {
    let data = JSON.parse(sessionStorage.getItem('currentUser'));
    var existProcess = false;
    for (var i = Number(this.EMIFrom); i <= Number(this.EMITo); i++) {
      if (Number(this.process_period) == i) {
        existProcess = true;
        break;
      }
    }
    if (Number(this.applicationDetail.NoOfEMI) < Number(this.EMITo)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI To less than NoOfEMI", ...this.configSuccess
      });
    }
    else {
      if (existProcess != true) {
    if (this.status == 'Failed') {

      this.NachCode = '';
      this.EMIFrom = 0;
      this.EMITo = 0;
    }
    console.log("this.FailCount", this.FailCount);
    if (this.FailCount == 1) {
      this.lmsService
        .LMS_SAVE_NACHINFO({
          Int_Id: this.Int_Id, status: '', NachAmount: 0, PresentOnCompanyBank: '',
          PresentBankInCompany: '', Reason: '', StatusDate: '', NachCode: '',
          FailCount: this.FailCount, EMIFrom: 0, EMITo: 0, Loan_Id: 0, CreateBy: data.userId, amortizationId: 0, Frequency: '', DebitType: '', From: '', To: '', CustomerName: 0
        })
        .subscribe((res: any) => {

        });
      this.Int_Id = 0;
    }
    if (this.status == 'Aweted' || this.status == 'Pending') {

      this.NachCode = '';
      this.EMIFrom = 0;
      this.EMITo = 0;
      this.Reason = '';
      this.StatusDate = '';
    }
    if (this.status == 'Pending')
      this.status = 'Aweted';

    if (this.status == 'Approved' && (this.StatusDate == '' || this.NachCode == '' || this.EMIFrom == '' || this.NachAmount == '' || this.PresentBankInCompany == '')) {

      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Details.", ...this.configSuccess
      });

    } else {
      this.lmsService
        .LMS_SAVE_NACHINFO({
          Int_Id: this.Int_Id, status: this.status, NachAmount: this.NachAmount, PresentOnCompanyBank: this.PresentOnCompanyBank,
          PresentBankInCompany: this.PresentBankInCompany, Reason: this.Reason, StatusDate: this.StatusDate, NachCode: this.NachCode,
          FailCount: 0, EMIFrom: this.EMIFrom, EMITo: this.EMITo, Loan_Id: this.Loan_Id, CreateBy: data.userId, amortizationId: this.amortizationId,
          Frequency: this.Frequency, DebitType: this.DebitType, From: this.From, To: this.To, CustomerName: this.CustomerId
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            this.NachAmount = '';
            this.NachCode = '';
            this.EMIFrom = '';
            this.EMITo = '';
            this.PresentBankInCompany = '';
            this.PresentOnCompanyBank = '';
            this.Reason = '';
            this.StatusDate = '';
            this.status = '';
            this.onCloseNachDetail();
            this.onSearchNachDetail();
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        });
    }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: this.process_period + ' EMI Already processed. ', ...this.configSuccess });
        this.EMIFrom = Number(this.process_period) + 1;
      }
    }
  }
  changeDate(event: any) {
    this.lmsService
      .LMS_GET_NACH_EMI_DETAI({ StatusDate: this.StatusDate })
      .subscribe((res: any) => {
        console.log(res);
        this.EMIFrom = res[0].EMI;
        this.EMITo = res[res.length - 1].EMI;
        this.LastEMI = res[res.length - 1].EMI;
        this.amortizationId = res[0].Id;
        this.EMIFromDB = res[0].EMI;
        console.log("this.amortizationId", this.amortizationId);
      })
  }
  onSaveRepresentDetail() {
    this.FailCount = 1;
    this.status = 'Aweted';
    this.IsFillUpDetail = true;
    this.isShowRepresent = false;
    this.isShowSave = true;
    this.isShowEdit = false;
  }
  goToDetail(data: any) {
    console.log(data);
    // var AppId = this.encdec.encrypt(data.AppId);
    // var ProcessId = this.encdec.encrypt(data.ProcessId);
    //var type = this.encdec.encrypt(false);
    //this.router.navigate([
    //  `/lms-dashboard/lms-nach-registration/${data.LoanAcNo}`,
    //]);
    console.log("data.chqId", data.chqId);
    this.status = data.status;
    this.PdcType = data.PdcType;
    this.lmsService
      .LMS_GET_NACHINFO({ LoanAcNo: data.LoanAcNo, Id: data.chqId, SearchStatus: data.status })
      .subscribe((res: any) => {
        console.log(res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        console.log('dataSourceLoan', res)
        this.applicationDetail = res[0];
        this.StatusDB = res[0].status;
        this.Loan_Id = res[0].Loan_Id;
        this.status = res[0].status;
        this.Int_Id = res[0].Int_Id;
        this.chqId = data.chqId;
        this.FailCount = res[0].FailCount;
        this.NachAmount = res[0].NachAmount;
        this.NachCode = res[0].NachCode;
        this.EMIFrom = res[0].EMIFrom;
        this.EMITo = res[0].EMITo;
        this.CustomerId = res[0].CustomerId;
        this.PdcType = res[0].PdcType;
        this.ChqFrom = res[0].ChFrom;
        this.ChqTo = res[0].ChTo;
        this.NoChqs = res[0].NoChqs;
        this.LastEMI = res[0].NoOfEMI;
        this.PresentBankInCompany = res[0].PresentBankInCompany;
        this.process_period = res[0].process_period;
        this.PresentOnCompanyBank = new Date(moment(res[0].PresentOnCompanyBank).format('MM/DD/YYYY'));
        this.StatusDate = new Date(moment(res[0].StatusDate).format('MM/DD/YYYY'));
        this.Reason = res[0].Reason;
        if (res[0].PresentOnCompanyBank == null)
          this.PresentOnCompanyBank = new Date();
        if (res[0].StatusDate == null || res[0].StatusDate == '01 Jan 1900')
          this.StatusDate = new Date();
        if (this.NachAmount == 0)
          this.NachAmount = '';

        if (this.status != 'Pending') {
          this.IsFillDetail = true;
          this.IsFillUpDetail = true;

        }
        if (this.status == 'Pending') {
          this.IsFillDetail = false;
          this.IsFillUpDetail = true;
          this.isShowSave = true;
          this.isShowEdit = false;
          this.NachAmount = Number(res[0].LoanAmount);
        }
          if (this.status == 'Aweted') {
          this.IsFillUpDetail = false;
            this.isShowEdit = false;
            this.isShowSave = true;
            this.isShowEdit = false;
            this.changeDate('');
        }
        if (this.status == 'Failed') {
          this.isShowRepresent = true;
          this.isShowSave = false;
          this.IsFillDetail = false;
          this.IsFillUpDetail = false;
          this.isShowEdit = true;
        }

        if (this.status == 'Approved') {
          this.IsFillDetail = false;
          this.IsFillUpDetail = false;
          this.isShowEdit = true;
          this.isShowSave = false;
          this.changeDate('');

        }
        if (this.FailCount == 1) {
          this.isShowRepresent = false;
          this.isShowSave = false;
          this.isShowEdit = false;
        }
        this.isShowGenerate = false;
        if (this.PdcType == 'RPDC') {
          this.isShowGenerate = true;
          this.isShowRepresent = false;
          this.isShowSave = false;
          this.isShowEdit = false;
          this.IsFillDetail = true;
          this.IsFillUpDetail = false;
          this.EMIFrom = 1;
          this.EMITo = this.NoChqs;
        }
      
      });
    $('#NachDetailModel').modal('show');
    $("#NachDetailModel").css("z-index", "1050");

  }
  onGenerateRPDC() {
    let data = JSON.parse(sessionStorage.getItem('currentUser'));
    var existProcess = false;
    for (var i = Number(this.EMIFrom); i <= Number(this.EMITo); i++) {
      if (Number(this.process_period) >= i) {
        existProcess = true;
        break;
      }
    }
    if (Number(this.applicationDetail.NoOfEMI) < Number(this.EMITo)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI To less than NoOfEMI", ...this.configSuccess
      });
    }
    else {
      if (existProcess != true) {
        this.lmsService
          .LMS_SAVE_NACHINFO({
            Int_Id: this.chqId, status: 'Generate', EMIFrom: this.EMIFrom, EMITo: this.EMITo, Loan_Id: this.Loan_Id, CreateBy:data.userId
          })
          .subscribe((res: any) => {
            this.onCloseNachDetail();
            this.onSearchNachDetail();
          });
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: this.process_period + ' EMI Already processed. ', ...this.configSuccess });
        this.EMIFrom = Number(this.process_period) + 1;
        this.EMITo = Number(this.EMIFrom) + Number(this.NoChqs) - 1;
      }
    }
  }
  EMIFromChange(event: any) {
    if (this.EMIFromDB > this.EMIFrom) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please EMIFrom greater or equal than " + this.EMIFromDB + ".", ...this.configSuccess
      });
      this.EMIFrom = this.EMIFromDB;
    }
    else if (this.status == 'Pending For Generate' || this.status == 'Generated') {
      //var num = (Number(this.ChqTo) - Number(this.ChqFrom)) + 1;
      this.EMITo = Number(this.EMIFrom) + Number(this.NoChqs) - 1;
    }
    if (this.EMITo > this.LastEMI) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please EMITo less or equal than " + this.LastEMI + ".", ...this.configSuccess
      });
      this.EMIFrom = 1;
      this.EMITo = Number(this.EMIFrom) + Number(this.NoChqs) - 1;
    }
    
  }
  onEditNachDetail() {
    this.isShowSave = true;
    this.isShowEdit = false;
    this.isShowRepresent = false;
    if (this.status == 'Failed') {
      this.IsFillDetail = true;
      this.IsFillUpDetail = true;
    }
    if (this.status == 'Approved') {
      this.IsFillDetail = true;
      this.IsFillUpDetail = true;
    }
  }
  checkFrequency(event) {
    event.target.id === 'Mthly' ? (this.Mthly = true, this.Qtly = false, this.HYrly = false, this.presented = false)
      : (event.target.id === 'Qtly' ? (this.Mthly = false, this.Qtly = true, this.HYrly = false, this.presented = false)
        : (event.target.id === 'HYrly' ? (this.Mthly = false, this.Qtly = false, this.HYrly = true, this.presented = false)
          : (event.target.id === 'presented' ? (this.Mthly = false, this.Qtly = false, this.HYrly = false, this.presented = true)
            : (this.Mthly = false, this.Qtly = false, this.HYrly = false, this.presented = true))));

    this.Frequency = event.target.id;
  }
  checkDebitType(event) {
    event.target.id === 'Fixed' ? (this.Fixed = true, this.Maximum = false)
      : (this.Fixed = false, this.Maximum = true);
    this.DebitType = event.target.id;
  }
  checkPeriod(event) {
    event.target.id === 'Period' ? (this.Period = true, this.UntilCancelled = false)
      : (this.Period = false, this.UntilCancelled = true);
  }

  getBranchesList() {
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.lmsService.GetBranches({ Emp_Id: user.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }
  onSearchNachDetail() {
    this.showSpinner = true;
    
    if (this.Regenerate == true)
      this.PendingRegenerate = '0';
    else
      this.PendingRegenerate = '';
    this.lmsService
      .LMS_GET_NACHINFO({
        LoanAcNo: this.caseNo, SearchBy: 'Search', LoanDateFrom: this.CaseDateFrom, LoanDateTo: this.CaseDateTo, SearchStatus: this.SearchStatus,
        CompanyBank: this.BankInCompany, BranchId: this.SelectBranch, PendingRegenerate: this.PendingRegenerate, Type: this.Type
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
          this.showSpinner = false;
        }
        else{
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.showSpinner = false;
        }
      });
  }
 
 /*  onExport(exporter: any) {
    if (this.FileCheckingList.length > 0) {
      exporter.exportTable("xls", {
        fileName: `Nach-Registration-list`,
        sheet: "Nach-Registration-list",
      });
    }
  }*/
  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_pdc_registration") as HTMLTableElement;
    const worksheetName = 'PDC REGISTRATION'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
}
