<div class="bg-image">
  <div class="login-wrapper">
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <div class="login-wrapper-inner formborder">
      <div class="logo-wrapper text-center mb-1">
        <a href="/">
          <!-- <img src="assets/images/logo.png" style="width: 40%;" alt=""> -->
          <img
            src="{{ BasePath }}{{ CompanyLogo }}"
            style="width: 40%"
            alt=""
          />
        </a>
      </div>
      <form #f="ngForm" novalidate (ngSubmit)="onSubmit()">
        <fieldset>
          <div class="login-form-fields">
            <div class="form-group">
              <input
                autofocus
                type="text"
                class="form-control"
                placeholder="Enter User ID"
                name="Username"
                [(ngModel)]="LoginModel.Username"
                maxlength="50"
                #refUsername="ngModel"
                id="Username"
                required
              />
              <div
                *ngIf="!refUsername.valid  && (refUsername.dirty || refUsername.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!refUsername.errors.required">
                  User ID is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <input
                autofocus
                type="password"
                class="form-control"
                placeholder="Password"
                name="Password"
                [(ngModel)]="LoginModel.Password"
                maxlength="50"
                #refPassword="ngModel"
                id="Password"
                required
              />
              <div
                *ngIf="!refPassword.valid  && (refPassword.dirty || refPassword.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!refPassword.errors.required">
                  Password is required
                </div>
              </div>
            </div>
          </div>
          <!-- Change this to a button or input when using this as a form -->
          <div class="row login-action">
            <div class="col-sm-6 text-right">
              <button
                type="submit"
                [disabled]="!f.form.valid"
                class="btn btn-primary btn-lg"
              >
                Submit
              </button>
              <!--<button type="submit" style="background-color: #067406;color: #FFFFFF;padding: 5px 15px;" mat-raised-button
              color="primary" [class.spinner]="loading" [disabled]="loading && !f.form.valid">Submit</button> -->
            </div>

            <div class="col-sm-6">
              <button
                type="button"
                class="linkbutton"
                (click)="OnForgetPassword()"
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </fieldset>
      </form>

      <!-- <div class="copyright">All Rights Reserved - Copyright 2020</div> -->
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ResetPasswordModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Forgot Password
        </h6>
        <button
          type="button"
          (click)="onClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #caf="ngForm" novalidate class="row m-0 p-0">
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable">Login ID</span>
              <input
                type="text"
                #refEmail="ngModel"
                maxlength="50"
                [(ngModel)]="Email"
                placeholder="Enter Login ID"
                required
                [ngClass]="{'is-invalid': caf.submitted && refEmail.invalid,'alert-warning':refEmail.invalid &&(refEmail.dirty || refEmail.touched || refEmail.untouched)}"
                name="Email"
                id="Email"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable"> Enter Mobile No</span>
              <input
                type="text"
                #refMobile="ngModel"
                numbersOnly
                maxlength="10"
                minlength="10"
                [(ngModel)]="Mobile"
                placeholder="Enter Mobile"
                required
                [ngClass]="{'is-invalid': caf.submitted && refMobile.invalid,'alert-warning':refMobile.invalid &&(refMobile.dirty || refMobile.touched || refMobile.untouched)}"
                name="Mobile"
                id="Mobile"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              (click)="onClose()"
              class="font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              (click)="onGetResetPassword()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!caf.form.valid"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="OTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Enter OTP
        </h6>
        <button
          type="button"
          (click)="onClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #cotp="ngForm"
          novalidate
          class="row m-0 p-0"
        >
          <div style="color: red; font-size: x-small">
            * Enter the One Time Password sent to your mobile phone
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refOTP="ngModel"
                numbersOnly
                maxlength="6"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{'is-invalid': cotp.submitted && refOTP.invalid,'alert-warning':refOTP.invalid &&(refOTP.dirty || refOTP.touched || refOTP.untouched)}"
                name="OTP"
                id="OTP"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable"> New Password</span>
              <input
                type="password"
                #refNewPassword="ngModel"
                maxlength="20"
                minlength="5"
                [(ngModel)]="NewPassword"
                placeholder="New Password"
                required
                [ngClass]="{'is-invalid': cotp.submitted && refNewPassword.invalid,'alert-warning':refNewPassword.invalid &&(refNewPassword.dirty || refNewPassword.touched || refNewPassword.untouched)}"
                name="NewPassword"
                id="NewPassword"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable"> Confirm Password</span>
              <input
                type="password"
                #refConfirmPassword="ngModel"
                maxlength="20"
                minlength="5"
                [(ngModel)]="ConfirmPassword"
                placeholder="Confirm Password"
                required
                [ngClass]="{'is-invalid': cotp.submitted && refConfirmPassword.invalid,'alert-warning':refConfirmPassword.invalid &&(refConfirmPassword.dirty || refConfirmPassword.touched || refConfirmPassword.untouched)}"
                name="ConfirmPassword"
                id="ConfirmPassword"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              (click)="onClose()"
              class="btn btn-light font-size-12"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              (click)="onOTP()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!cotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <!--<div class="modal-body ">
              <form #cotp="ngForm"
                    (ngSubmit)="onOTP()"
                    novalidate
                    class=" row m-0 p-0">

                <div class="row m-0" id="dvOTP" style="display:block">
                  <div class="col-md-4">
                    <span class="required-lable"> Enter OTP</span>
                    <input type="text" #refOTP="ngModel" numbersOnly maxlength="5" [(ngModel)]="OTP" placeholder="Enter OTP" required
                           [ngClass]="{'is-invalid': cotp.submitted && refOTP.invalid,'alert-warning':refOTP.invalid &&(refOTP.dirty || refOTP.touched || refOTP.untouched)}"
                           name="OTP" id="OTP" class="form-control input-text-css" />
                  </div>
                </div>
                <div class="row m-0" id="dvOTP" style="display:block">
                  <div class="col-md-6">
                    <span class="required-lable"> Enter New Password</span>
                    <input type="password" #refNewPassword="ngModel" maxlength="20" [(ngModel)]="NewPassword" placeholder="Enter New Password" required
                           [ngClass]="{'is-invalid': cotp.submitted && refNewPassword.invalid,'alert-warning':refNewPassword.invalid &&(refNewPassword.dirty || refNewPassword.touched || refNewPassword.untouched)}"
                           name="NewPassword" id="NewPassword" class="form-control input-text-css" />
                  </div>
                </div>
                  <div class="row m-0" id="dvOTP" style="display:block">
                    <div class="col-md-4">
                      <span class="required-lable"> Enter Confirm Password</span>
                      <input type="password" #refConfirmPassword="ngModel" maxlength="20" [(ngModel)]="ConfirmPassword" placeholder="Enter Confirm Password" required
                             [ngClass]="{'is-invalid': cotp.submitted && refConfirmPassword.invalid,'alert-warning':refConfirmPassword.invalid &&(refConfirmPassword.dirty || refConfirmPassword.touched || refConfirmPassword.untouched)}"
                             name="ConfirmPassword" id="ConfirmPassword" class="form-control input-text-css" />
                    </div>
                  </div>
                  <div class="row col-md-12 m-0 mt-1 justify-content-end">
                    <button type="button" (click)="onClose()" class="btn btn-light font-size-12" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="submit" (click)="onOTP()" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button
                            color="primary" [class.spinner]="loading" [disabled]="!cotp.form.valid">
                      Save
                    </button>
                  </div>
      </form>
            </div>-->
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="LoginOTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Enter OTP
        </h6>
        <button
          type="button"
          (click)="onLoginOTPModelClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #loginotp="ngForm"
          novalidate
          class="row m-0 p-0"
        >
          <div style="color: red; font-size: x-small">
            * Enter the One Time Password sent to your mobile phone
            ({{firstMobileNo}}*****{{lastMobileNo}})
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-lg-start">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refLoginOTPModelOTP="ngModel"
                numbersOnly
                maxlength="6"
                [(ngModel)]="LoginOTPOTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{'is-invalid': loginotp.submitted && refLoginOTPModelOTP.invalid,'alert-warning':refLoginOTPModelOTP.invalid &&(refLoginOTPModelOTP.dirty || refLoginOTPModelOTP.touched || refLoginOTPModelOTP.untouched)}"
                name="OTP"
                id="OTP"
                class="form-control input-text-css font-size-12"
              />
            </div>
          </div>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              (click)="onLoginOTPModelClose()"
              class="font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              (click)="onLoginOTPModelOTP()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!loginotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
