import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { QuickLoanService } from "../services/quickLoan.service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

@Component({
  selector: "app-quickloan-application-status",
  templateUrl: "./quickloan-application-status.component.html",
  styleUrls: ["./quickloan-application-status.component.scss"],
})
export class QuickloanApplicationStatusComponent implements OnInit {
  showSpinner: boolean = false;
  DateFrom: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  DateTo: any = new Date();

  JsonData: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: any;
  data = [];

  displayedColumns: string[] = [];

  dynamicColumns: string[] = [];
  fixedColumns: any;
  processColumns: any;
  subHeaders: any;
  selectedId: any;

  pageTitle: any = "APPLICATION PROCESS STATUS";

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private _QuickLoanService: QuickLoanService
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Application Process Status");
  }

  searchQuickloanApplicationStatus() {
    this.showSpinner = true;
    this._QuickLoanService
      .QuickLoan_Application_Status({
        fromDate: this.DateFrom,
        toDate: this.DateTo,
      })
      .subscribe((response: any) => {
        console.log("Api call data", response);
        if (response && response.length > 0) {
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(response))
          );
          this.dataSource.paginator = this.paginator;

          this.fixedColumns = [
            "loanId",
            "branchName",
            "customerFullName",
            "productName",
          ];

          this.dynamicColumns = Object.keys(response[0]).filter(
            (key) =>
              !this.fixedColumns.includes(key) &&
              !key.toLowerCase().includes("id")
          );

          this.displayedColumns = [
            ...this.fixedColumns,
            ...this.dynamicColumns,
          ];

          this.JsonData = response;
          console.log("Data", response);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
      });
    this.showSpinner = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public highlightRow(row) {
    this.selectedId = row.loanId;
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "pageTitle",
      sheet: "pageTitle",
    });
  }
}
