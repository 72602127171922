<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span>Select Product</span>
      <select
        name="FilterProductId"
        id="FilterProductId"
        (change)="getBranchEmployeeList()"
        [(ngModel)]="FilterProductId"
        class="form-control input-text-css"
      >
        <option value="">Select Product</option>
        <option
          *ngFor="let ProductListDropdown of ProductListDropdown"
          [value]="ProductListDropdown.ProductId"
        >
          {{ ProductListDropdown.Product_Name }}
        </option>
      </select>
    </div>
  </div>

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="DocId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Doc_Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Document Category
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Doc_Category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Doc_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Document
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Doc_Name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Doc_Ind_NI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Document Apply On</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{
            row.Doc_Ind_NI == "I"
              ? "Individual"
              : row.Doc_Ind_NI == "N"
              ? "Non-Individual"
              : row.Doc_Ind_NI == "B"
              ? "Both"
              : ""
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Selection">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Selection
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox
            [(ngModel)]="row.IsAlreadySelected"
            [checked]="row.IsAlreadySelected"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="IsHMandatory">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Borrower Mandatory
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox
            [(ngModel)]="row.IsHMandatory"
            [disabled]="!row.IsAlreadySelected"
            [checked]="row.IsHMandatory"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="IsCMandatory">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >CO-Borrower Mandatory
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox
            [(ngModel)]="row.IsCMandatory"
            [disabled]="!row.IsAlreadySelected"
            [checked]="row.IsCMandatory"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="IsGMandatory">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Guarantor Mandatory
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox
            [(ngModel)]="row.IsGMandatory"
            [disabled]="!row.IsAlreadySelected"
            [checked]="row.IsGMandatory"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>

<div class="col-md-12 p-1 text-right">
  <button class="btn font-size-12 button-btn" (click)="OnSaveLoanDocSection()">
    Save
  </button>
</div>
