// #region Import Used Components and Services
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoginModel } from '../Models/app.LoginModel';
import { Router } from '@angular/router';
import { constantUrl } from 'src/app/Shared/constantUrl';
import { PresenceService } from 'src/app/_Chatbox/_service/presence.service';
import { MasterService } from '../../Shared/app.Masters.Service';
// #endregion

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  // #region Variable Declarations and Models
  // public token: string;
  private apiUrl = constantUrl.apiEndpoint + "/api/Authenticate/";

  // #endregion

  // #region constructor LoginService
  constructor(private _MasterService: MasterService, private _http: HttpClient, private _Route: Router,private presenceService:PresenceService) {

  }
  // #endregion

  // #region validateLoginUser
  public validateLoginUser(loginmodel: LoginModel) {
    return this._http.post<any>(this.apiUrl, loginmodel).pipe(tap(data => {      
      if (data.Token != null) {
        // store username and jwt token in local storage to keep user logged in between page refreshes
        var SubscriptionDate = new Date(data.SUB_Date.split('T')[0]);
        SubscriptionDate.setDate(SubscriptionDate.getDate() + 15)
        sessionStorage.setItem('currentUser', JSON.stringify({
          userId: data.UserID, username: data.UserName, token: data.Token, FullName: data.FullName,
          ConfigurationAllow: data.ConfigurationAllow, LOSAllow: data.LOSAllow, LMSAllow: data.LMSAllow, PortfolioAllow: data.PortfolioAllow, DepositAllow: data.DepositAllow, QuickLoanAllow: data.QuickLoanAllow, ProfilePic: data.ProfilePic,
          CompanyName: data.CompanyName, CompanyAddress: data.CompanyAddress, ReportsAllow: data.ReportsAllow, AccountingAllow: data.AccountingAllow, Data_Freeze: data.Data_Freeze, DaysInYear: data.DaysInYear, UploadMaxSize_In_KB: data.UploadMaxSize_In_KB,
          Days_Allowed: data.Days_Allowed, UPI_Prefix: data.UPI_Prefix, CompanyLogo: data.CompanyLogo, SUB_Date: data.SUB_Date, RoleId: data.RoleId, IsCustomerImport: data.IsCustomerImport,
          IsLoanImport: data.IsLoanImport, SubscriptionDate: SubscriptionDate, IsMasking: data.IsMasking

        }));
        
        let usr=JSON.parse(sessionStorage.getItem('currentUser'));
        this.presenceService.currentUser.next(usr);
        // return true to indicate successful login
        return data;

      } else {
        // return false to indicate failed login
        return null;
      }
    }));
  }
  // #endregion

  // #region LogoutUser
  LogoutUser() {
    sessionStorage.removeItem('currentUser');
    sessionStorage.clear();
   
   
    
  }
  // #endregion






  // #region Error Handeling
  //   private handleError(error: HttpErrorResponse) {
  //       if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //       } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
  //       }
  //       // return an observable with a user-facing error message
  //       return throwError('Something bad happened; please try again later.');
  // };
  // #endregion
}
