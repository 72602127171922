import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LosService } from "src/app/_LOS/services/los.service";
import { QuickLoanService } from "src/app/_QuickLoan/services/quickLoan.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { DialogService } from "src/app/Shared/dialog.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
declare var $;

@Component({
  selector: "app-receipt-authentication",
  templateUrl: "./receipt-authentication.component.html",
  styleUrls: ["./receipt-authentication.component.scss"],
})
export class ReceiptAuthenticationComponent implements OnInit {
  ToDate: any = new Date();
  FromDate: any = new Date();
  Dramt = 0;
  Cramt = 0;
  currentUser: any;
  DrData: any;
  Status = "Pending";
  VID: any;
  Reason: any;
  JsonData: any[] = [];
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  // Voucher Model Variables
  dataSourceModel: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumnsModel: any = [];
  loanSummary: any;
  Tenure_Name: any;
  //Customer data
  dataSourceCustomer: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedCustomerColumns: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(
    paginator: MatPaginator
  ) {
    if (paginator != undefined) {
      this.dataSourceCustomer.paginator = paginator;
    }
  }
  constructor(
    private _losService: LosService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private quickLoanService: QuickLoanService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("IMD Authorization");
  }

  OnCloseVoucherModel() {
    $("#VoucherModel").modal("hide");
  }

  showVoucherModel() {
    $("#VoucherModel").modal("show");
  }

  OnShowVoucherModel(LoanId, VId, Status) {
    this.Get_Voucher_Data(LoanId, VId, Status);
    this.showVoucherModel();
  }

  Get_Receipt_Data_From_Date() {
    this.showSpinner = true;
    let requestData = {
      FromDate: moment(this.FromDate).format("YYYY-MM-DD").toString(),
      ToDate: moment(this.ToDate).format("YYYY-MM-DD").toString(),
      Status: this.Status,
    };
    // console.log("Request", requestData)
    this._losService.QuickLoan_Get_Fees(requestData).subscribe((res: any[]) => {
      console.log("Response", res);
      if (res.length == 0) {
        this.snackBar
          .openFromComponent(SnackbarComponent, {
            data: "No Data Found",
            ...this.configSuccess,
          })
          ._dismissAfter(500);
      }
      this.JsonData = JSON.parse(JSON.stringify(res));
      this.dataSourceCustomer = new MatTableDataSource(res);
      this.paginator = this.dataSourceCustomer.paginator;
      if (this.Status == "Rejected") {
        this.displayedCustomerColumns = [
          "Id",
          "ApplicationNo",
          "Voucher_Type",
          "Voucher_Sub_Type",
          "Voucher_Tag",
          "Voucher_Date",
          "CreatedBy",
          "Account_Name",
          "Amount",
          "Reason",
          "EditAction",
        ];
      } else
        this.displayedCustomerColumns = [
          "Id",
          "ApplicationNo",
          "Voucher_Type",
          "Voucher_Sub_Type",
          "Voucher_Tag",
          "Voucher_Date",
          "CreatedBy",
          "Account_Name",
          "Amount",
          "EditAction",
        ];

      this.showSpinner = false;
    });

    //Send Request and Fetch Data
  }

  Get_Voucher_Data(LoanId, VId, Status) {
    let requestData = { LoanId, VId, Status };
    this.showSpinner = true;
    console.log("request", requestData);
    return this._losService
      .QuickLoan_Get_Fees_Details(requestData)
      .subscribe((response) => {
        console.log("response", response);
        this.loanSummary = response["Item1"][0];
        this.DrData = (response["Item2"] as []).find(
          (x) => x["TranType"] == "DR"
        );
        this.dataSourceModel = new MatTableDataSource<any>(response["Item2"]);
        this.Dramt = (response["Item2"] as []).find(
          (x) => x["TranType"] == "DR"
        )["Amount"];
        this.Cramt =
          (response["Item2"] as []).reduce(
            (x, curr) => x + Number(curr["Amount"]),
            0.0
          ) - Number(this.Dramt);

        this.displayedColumnsModel = [
          "TranType",
          "Case_No",
          "Customer",
          "Branch",
          "Category",
          "Account_Name",
          "AmountDR",
          "AmountCR",
        ];

        this.showVoucherModel();
        this.showSpinner = false;
      });
  }
  onApproval(VID) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Approve?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.quickLoanService
            .QuickLoan_Save_Fees_Detail({
              VID: VID,
              LoginUserId: this.currentUser.userId,
              Status: "Approved",
            })
            .subscribe((resp) => {
              // console.log("Confrmesponse Data",resp)
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: resp[0]["MSG"],
                ...this.configSuccess,
              });
              if (resp[0]["CODE"] === 0) {
                this.OnCloseVoucherModel();
                this.Get_Receipt_Data_From_Date();
              }
            });
        }
      });
  }
  onRejected() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Reject?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.quickLoanService
            .QuickLoan_Save_Fees_Detail({
              VID: this.VID,
              LoginUserId: this.currentUser.userId,
              Status: "Rejected",
              Reason: this.Reason,
            })
            .subscribe((resp) => {
              this.snackBar
                .openFromComponent(SnackbarComponent, {
                  data: resp[0]["MSG"],
                  ...this.configSuccess,
                })
                ._dismissAfter(500);
              if (resp[0]["CODE"] === 0) {
                this.Reason = "";
                this.VID = null;
                this.OnCloseVoucherModel();
                this.OnCloseRejectModel();
                this.Get_Receipt_Data_From_Date();
              }
            });
        }
      });
  }
  onRejectedDone(VID) {
    this.VID = VID;
    $("#RejectModel").modal("show");
  }
  OnCloseRejectModel() {
    $("#RejectModel").modal("hide");
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("imd_auth") as HTMLTableElement;
    const worksheetName = "IMD AUTHORIZATION"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.dataSourceCustomer.filter = filterValue.trim().toLowerCase();
  }
}
