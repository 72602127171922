<i class="fas fa-comment-dots btn" aria-hidden="true" (click)="openModal()"
    style="padding: 0;font-size: 3em;color: #eeeeee;">
</i>
<sup>
    <div class="badge badge-danger">{{presenceService.totalUncount|async}}</div>
</sup>

<div class="modal" id="chat" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">

    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
            <div cdkDragHandle class="modal-header" style="padding: 10px;background-color: transparent !important;">
                <h6 class="modal-title" id="myModalLabel" name="myModalLabel">Chat</h6>
                <span style="margin-left: auto;margin-right: auto;font-weight: 900;font-size: 14px;">
                    <img  alt="img" onerror="this.onerror=null;this.src='assets/images/default-user.png';" style="width: 25px; height: 25px; border: 1px solid #dddd; border-radius: 50%;font-size: 13px;" [src]="currentUserUrl">
                    {{currentUser.FullName}}
                </span>
                <button type="button" (click)="onCloseChat()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="far text-black fa-times-circle"></i></span>
                </button>
            </div>
            <div class="modal-body text-left">
                <div class="d-flex main-container bg-transparent  py-2 mx-1 "
                    style="height: 400px;width:70dvw;flex-wrap:nowrap;">
                    <!-- For Employee  -->
                    <span class="d-inline-flex border rounded-lg mx-1 px-1 h-100 "
                        style="width: 22%; flex-direction: column;">

                        <div class="search my-2 border w-100 rounded" >
                            <input type="form-control w-100  form-control-sm rounded m-0 p-0" 
                                style="border: 0;width:100%;height:36px" placeholder="Search" [(ngModel)]="nameSearch">

                        </div>
                        <div class="list d-flex overflow-auto " style="flex-direction:column;">
                            <div class="card my-1 shadow"
                                [ngClass]="(messageService?.otherUser?.EmpId===Emp.EmpId)?'activecrd':''"
                                *ngFor="let Emp of (allEmpDetails|search:'EmpName':nameSearch)"
                                (click)="setOtherUser(Emp)">
                                <div class="card-body ">
                                    {{Emp.EmpName}}
                                    <span *ngIf="(presenceService.onlineUser|async).includes(Emp.EmpId)"><i
                                            class="fa fa-circle fa-sm " style="color:rgb(53 195 10);font-size:9px"></i></span>
                                    <span *ngIf="Emp.UnRead_Chat_Count>0" class="badge badge-success float-right">
                                        {{Emp.UnRead_Chat_Count}}</span>

                                </div>
                            </div>

                        </div>

                    </span>
                    <!-- FOr Application Id -->
                    <span class="d-inline-flex border rounded-lg mx-1 px-1 h-100 "
                        style="width: 22%;flex-direction: column;">

                        <div class="search my-2 border w-100 rounded" style="height:36px">
                            <input *ngIf="showAppAddIcon" type="form-control w-90   form-control-sm rounded m-0 p-0 h-100"
                                style="border: 0;width:85%; margin-right: 5px;" placeholder="Search" [(ngModel)]="applicationIdSearch">
                            <input *ngIf="!showAppAddIcon" type="form-control w-90  form-control-sm rounded m-0 p-0 h-100"
                                style="border: 0;width:75%; margin-right: 5px;"  placeholder="Search"
                                [(ngModel)]="newAppSearch">
                            <i *ngIf="!showAppAddIcon" class="btn btn btn-transparent p-0 fa fa-times-circle h-100" 
                                style="color:white;padding-top:8px !important" (click)="changeIconStatus()"></i>
                            <i class="btn btn btn-transparent p-0 h-100" 
                                [ngClass]="showAppAddIcon?'fa fa-plus':'fa fa-search '" style="color:white;padding-top:8px !important"
                                (click)="showAppAddIcon?changeIconStatus():SearchApplication()"></i>
                        </div>
                        <div class="list d-flex overflow-auto" style="flex-direction:column;flex-grow: 1;">
                            <div class="card my-1 shadow-lg"
                                [ngClass]="(ApplicationId===Apj.Application_No)?'activecrd':''"
                                *ngFor="let Apj of (applicationDetail|search:'Application_No':applicationIdSearch)"
                                (click)="setApplication(Apj)">
                                <div class="card-body ">
                                    {{Apj.Application_No}}
                                    <span *ngIf="Apj.LoanAcNo">/{{Apj.LoanAcNo}}</span>
                                    <span *ngIf="Apj.UnReadCount>0" class="badge badge-success badge-lg font-weight-bold float-right">{{Apj.UnReadCount}}</span>
                                    <div *ngIf="Apj.CustomerName" class="text-muted">{{Apj.CustomerName}}</div>
                                </div>
                            </div>
                            <!-- <div class="bottom-btn form-group">
                                <form>
                                    <span *ngIf="!showAppIcon"
                                        style="vertical-align: middle;box-shadow: -5px 5px #888888;" class="">
                                        <input style="height: 23px;border-width: 0;" class="" type="text"
                                            placeholder="Enter AppID or LoanNo">
                                    </span>
                                    <span>
                                        <i class="btn btn-success font-weight-bold"  style="border-radius:50%;"></i>
                                    </span>
                                </form>
                            </div> -->



                        </div>

                    </span>
                    <!-- For Messages -->
                    <span class="d-inline-flex border rounded-lg mx-1 px-1 h-100 "
                        style="width: 53%;flex-direction: column;"
                        *ngIf="!!messageService.otherUser && !!ApplicationId">

                        <div class="name-header my-2 border w-100 rounded" style="background-color: whitesmoke;">
                            <section class="px-3">

                                <span>{{messageService.otherUser?.EmpName}}({{ApplicationId}})
                                    <span
                                        *ngIf="(presenceService.onlineUser|async).includes(messageService.otherUser?.EmpId)"><i
                                            class="fa fa-circle fa-sm " style="color:rgb(53 195 10);font-size:9px"></i></span>

                                </span>

                            </section>
                        </div>
                        <div class="message-box  overflow-auto " style="flex-grow: 1;" #scroll
                            [appScrollToBottom]="scroll">
                            <div class="messagecont " style="overflow-y:auto;overflow-x: hidden;">
                                <ng-container
                                    *ngFor="let message of (messageService.messageThreadSource.asObservable()|async)">
                                    <div class="row ">
                                        <div class="col-10"
                                            [ngClass]="(currentUser?.userId===message?.senderId)?'offset-2 ':'offset-0'">
                                            <div class="card px-1 m-2" [ngClass]="{' float-right':currentUser?.userId===message?.senderId,
                                                    'justify-content-start float-left':currentUser?.userId!==message?.senderId }" style="max-width: 100%;height: fit-content;display: inline-block;" [style.background-color]="currentUser?.userId!==message?.senderId?'#93d1dd':'#a8eaa59e'">

                                                <div *ngIf="!!message.document_Attachment">
                                                    <a target="_blank" href="{{ docBaseUrl }}{{ ApplicationId}}/{{message.document_Attachment}}">
                                                        <span class="mx-1"><img src="assets/images/Doc_Image.jpg"
                                                                style="width:25px;height:25px;"> </span><span
                                                            class="mx-1"><i class="fa fa-download"></i></span>
                                                        <div> {{message.document_Attachment}}</div>
                                                    </a>
                                                </div>
                                                <div class="card-body p-1" style="cursor:default">
                                                    <p class="card-text" style="margin-bottom:4px">{{message.msg}}</p>
                                                    <p class="card-subtitle mb-2 text-dark float-right" style="font-size: 8px;">{{message.sendDate}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <!-- send bar -->
                            <div class="sendmsg d-flex m-1" id="flexmsg" #flexmsg>

                                <span class=" m-0 p-0" style="width: 80%;">
                                    <form #messageForm="ngForm" id="sndmsg" autocomplete="off"
                                        (ngSubmit)="sendMessage()" class="h-100">
                                        <textarea cdkTextareaAutosize  #msgbox  form id="sndmsgbx" type="text" class="form-control py-0 mx-1"
                                            pattern="[\W]*[\w\.]+[\w\W]*" name="message" [(ngModel)]="message"
                                            placeholder="Enter Message" required  style="resize:none;overflow:hidden;height:0" (keydown)="onKeydownMain($event)" (input)="autoGrow()"></textarea>
                                    </form>
                                </span>
                                <span class="text-center m-0 p-0" style="width: 10%;"> <label for="attach"
                                        class=" text-center fa fa-paperclip col-md-1 mx-1 btn">

                                    </label> <input type="file" hidden id="attach" #attachbtn
                                        (change)="onAttachementChange($event)"></span>
                                <span class=" m-0 p-0" style="width: 10%;"> <i (click)="sendMessage()"
                                        class="fa fa-paper-plane col-md-1 mx-1 btn"
                                        [hidden]="messageForm.invalid"></i></span>


                            </div>
                        </div>

                    </span>

                    <!-- For User Profile -->
                    <span *ngIf="!!messageService.otherUser && !!!ApplicationId"
                        class="d-inline-flex border rounded-lg mx-1 px-1 h-100 "
                        style="width: 53%;flex-direction: column;">
                        <div class="img rounded-lg text-center my-1">
                            <img [src]="EmployeeDetails?.Emp_Image" onerror="this.src='assets/images/default-user.png';"
                                style="border-radius: 50%;width: 220px;" alt="">
                        </div>
                        <div id="viewProfile" class="list d-flex mt-4 mb-3 mx-4"
                            style="flex-direction:column;flex-grow: 1; padding-top: 18px;">
                            <div class="text-center font-weight-bold my-1">
                                <div class="font-weight-bold" style="font-weight: 700;font-size: 18px;">
                                    {{EmployeeDetails?.Employee_Name}}
                                </div>
                            </div>
                            <div class="text-center my-1">
                                <div class="">
                                    {{EmployeeDetails?.Role_Name}}({{EmployeeDetails?.Emp_code}})
                                </div>
                            </div>
                            <div class="text-center my-1">
                                <span class="">
                                    <a href="mailto:{{EmployeeDetails?.Emp_Email}}">
                                        {{EmployeeDetails?.Emp_Email}}
                                    </a>
                                </span>
                                <span *ngIf="!!EmployeeDetails?.Mobile_No" class="">
                                    | {{EmployeeDetails?.Mobile_No}}
                                </span>
                                <span *ngIf="!!EmployeeDetails?.Alternet_No" class="">
                                    | {{EmployeeDetails?.Alternet_No}}
                                </span>
                            </div>
                            <div class="text-center my-1">

                            </div>



                        </div>
                    </span>

                </div>

                <div *ngIf="Attachement" id="Attachement" class=" mx-1 " style="flex-direction: column;z-index: 4;">
                    <div class="modal-header p-0">
                        <span class="pull-right btn float right" (click)="onCloseAttachement()">x</span>
                    </div>
                    <div class="frame-content p-0 " style="overflow-x:auto;overflow-y: auto;">
                        <iframe style="width:100%;height:100%"  name="AttachDoc" title="Attac" [src]="sanitizer.bypassSecurityTrustResourceUrl(AttachementDisplay)" frameborder="0">

                        </iframe>
                    </div>
                    <div class="text-center font-weight-bold my-2">
                        {{Attachement.name}}
                    </div>
                    <div class="sendmsg d-flex m-1" style="flex-direction: row;">

                        <span class="bg-whitesmoke m-0 p-0" style="width: 80%;">
                            <form #AttachForm="ngForm"  autocomplete="off"
                                 class="h-100">
                                <input form  type="text" class="form-control h-100  mx-1" 
                                    pattern="[\W]*[\w\.]+[\w\W]*" name="AttachmentMsg" [(ngModel)]="AttachmentMsg"
                                    placeholder="Enter Message" >
                            </form>
                        </span>
                        <span class=" m-0 p-0 float-right" style="width: 10%;">
                            <i (click)="sendAttachement()" class="fa fa-paper-plane col-md-1 mx-1 btn"></i></span>


                    </div>
                </div>


            </div>

        </div>
    </div>
</div>