import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { Router } from "@angular/router";
import { LmsService } from "../services/lms.service";
declare var $: any;

@Component({
  selector: "app-lms-customer-view-model",
  templateUrl: "./lms-customer-view-model.component.html",
  styleUrls: ["./lms-customer-view-model.component.scss"],
})
export class LmsCustomerViewModelComponent implements OnInit {
  @Input() customerId: any = "0";
  @Input() index: any;
  Directors: any;
  Charges: any;
  Verify_PAN_No: any;
  APIResponse: any = {
    DoucumentNumber: "",
    Full_name: "",
    IsVerified: "",
    category: "",
  };
  CustomerViewDetailRequestModel: RequestModel = new RequestModel();
  showSpinner: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  currentUser: any;
  customerData: any = {};

  zoom: number = 13;
  lat: any = 26.92207;
  lng: any = 75.778885;
  ShowMapAddress: any = "";

  // address
  addressList: any = [];
  dataSourceAddress: any;
  displayedColumnsAddress: string[] = [
    "AddressId",
    "AddressType",
    "Address",
    "LandMark",
    "Tehsil_Name",
    "District_Name",
    "PinCode",
    "AddressRentBuy",
    "TotalYearsOnAddress",
    "IsCommunicationAddress",
    "LatLong",
  ];
  @ViewChild("SortAddress", { static: true }) SortAddress: MatSort;

  @ViewChild("SortBank", { static: true }) SortBank: MatSort;

  // Category
  CategoryList: any = [];
  dataSourceCategory: any;
  @ViewChild("CategorySort", { static: true }) CategorySort: MatSort;
  displayedColumnsCategory: string[] = [
    "CategoyId",
    "Categoy",
    "Doc_Name",
    "KYC_DocNumber",
    "UploadOn",
    "LastVerfiedDate",
    "EditAction",
  ];

  bankList: any = [];
  dataSourceBank: any=null;
  displayColumnsBank: string[] = [
    "Id",
    "CustomerBankName",
    "AccountNo",
    "BankName",
    "BankBranch",
    "BankIFSC",
    "BankAcType",
  ];

  // Application
  ApplicationList: any = [];
  dataSourceApplication: any;
  @ViewChild("ApplicationSort", { static: true }) ApplicationSort: MatSort;
  displayedColumnsApplication: string[] = [
    "ApplicationId",
    "ApplicationNo",
    "LoanAcNo",
    "CustomerType",
    "Product",
    "Branch",
    "LoanAmount",
    "CreateOn",
    "Total_OverDUE_EMI_Amount",
    "Balance_Principle",
    "Application_Status",
  ];

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private encdec: EncrDecrService,
    private lmsService: LmsService
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  onOpenApplicationMapModal(data: any) {
    // console.log(data.ApplicationId);
    // var appid = this.encdec.encrypt(data.ApplicationId);
    const url = this.router.createUrlTree([
      `/#/LOS/application/${encodeURIComponent(data.ApplicationId)}`,
    ]);
    window.open(url.toString().replace("%23", "#"), "_blank");
  }

  onOpenViewDetailModal() {
    this.CustomerViewDetailRequestModel.CustomerId = this.customerId.toString();
    this.lmsService
      .Get_LMS_CustomerDetails(this.CustomerViewDetailRequestModel)
      .subscribe((result: any) => {
        if (result != null) {
          this.customerData = result.Item1[0];
          console.log(result);
          // address
          this.addressList = result.Item2;
          this.dataSourceAddress = new MatTableDataSource(this.addressList);
          this.dataSourceAddress.sort = this.SortAddress;
          // category
          this.CategoryList = result.Item3;
          this.dataSourceCategory = new MatTableDataSource(this.CategoryList);
          this.dataSourceCategory.sort = this.CategorySort;
          // application
          this.ApplicationList = result.Item4;
          this.dataSourceApplication = new MatTableDataSource(
            this.ApplicationList
          );
          this.dataSourceApplication.sort = this.ApplicationSort;

          //Bank Details

          this.bankList = result.Item5;
          if(this.bankList.length>0){
          this.dataSourceBank = new MatTableDataSource(this.bankList);
          this.dataSourceBank.sort = this.SortBank;
          }
          else{
            this.dataSourceBank=null;
          }

          $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("show");
          $(`#LosSearchCustomerViewDetailModal${this.index}`).css(
            "z-index",
            "1050"
          );
        }
       
      });
  }

  OnClose() {
    $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("hide");
    this.customerData = {};
    this.addressList = [];
    this.CategoryList = [];
    this.ApplicationList = [];
  }

  onOpenAddressMapModal(row: any) {
    if (row.LatLong) {
      this.lat = row.LatLong.split(",")[0];
      this.lng = row.LatLong.split(",")[1];
      this.ShowMapAddress =
        row.Address +
        ", " +
        row.Tehsil_Name +
        ", " +
        row.District_Name +
        " - " +
        row.PinCode;
      $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("show");
      $(`#LosSearchCustomerViewDetailModal${this.index}`).css(
        "z-index",
        "1500"
      );
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Latitude and Longitude not provided for this address.`,
        ...this.configSuccess,
      });
    }
  }

  onCloseAddressMapModal() {
    this.ShowMapAddress = "";
    $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("hide");
  }

  ViewKYC_IsVerified(KYC) {
    this._MasterService
      .Get_Alredy_Verified_KYC({
        DocumentId: KYC.KYC_DocId,
        KYC_DocNumber: KYC.KYC_DocNumber,
      })
      .subscribe((res: any) => {
        //let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
        if (res.length > 0) {
          var Masking = KYC.KYC_DocNumber;
          KYC.LastVerfiedDate = res[0].LastVerfiedDate;
          KYC.KYC_IsVerified = 1;
          Masking = Masking.toUpperCase().slice(
            0,
            KYC.KYC_DocNumber.length - 4
          );

          var n = Masking.length;
          var ch = Masking.split("");

          for (var i = 0; i < n; i++) {
            ch[i] = "X";
          }
          if (KYC.KYC_DocId == 1) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 6) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              country: JSON.parse(res[0].APIResponse).data.address.country,
              dist: JSON.parse(res[0].APIResponse).data.address.dist,
              state: JSON.parse(res[0].APIResponse).data.address.state,
              po: JSON.parse(res[0].APIResponse).data.address.po,
              loc: JSON.parse(res[0].APIResponse).data.address.loc,
              vtc: JSON.parse(res[0].APIResponse).data.address.vtc,
              subdist: JSON.parse(res[0].APIResponse).data.address.subdist,
              street: JSON.parse(res[0].APIResponse).data.address.street,
              house: JSON.parse(res[0].APIResponse).data.address.house,
              landmark: JSON.parse(res[0].APIResponse).data.address.landmark,
              Pin: JSON.parse(res[0].APIResponse).data.zip,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.care_of,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              zip: JSON.parse(res[0].APIResponse).data.zip,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
            if (res.length > 1) {
              this.Verify_PAN_No = JSON.parse(
                res[1].APIResponse
              ).data.pan_number;
            }
          } else if (KYC.KYC_DocId == 29) {
            Masking = Masking.toUpperCase().slice(
              0,
              KYC.KYC_DocNumber.length - 4
            );

            var n = Masking.length;
            var ch = Masking.split("");

            for (var i = 0; i < n; i++) {
              ch[i] = "X";
            }
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              business_name: JSON.parse(res[0].APIResponse).data.business_name,
              gst_status: JSON.parse(res[0].APIResponse).data.gstin_status,
              nature_bus_activities: JSON.parse(res[0].APIResponse).data
                .nature_bus_activities,
              Gst_Registration_Date: JSON.parse(res[0].APIResponse).data
                .date_of_registration,
              pan_number: JSON.parse(res[0].APIResponse).data.pan_number,
              SigningAutority: JSON.parse(res[0].APIResponse).data.legal_name,
              FirmType: JSON.parse(res[0].APIResponse).data
                .constitution_of_business,
              address: JSON.parse(res[0].APIResponse).data.address,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 28) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              company_name: JSON.parse(res[0].APIResponse).data.company_name,
              registration_number: JSON.parse(res[0].APIResponse).data.details
                .company_info.registration_number,
              company_sub_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_sub_category,
              class_of_company: JSON.parse(res[0].APIResponse).data.details
                .company_info.class_of_company,
              company_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_category,
              address: JSON.parse(res[0].APIResponse).data.details.company_info
                .registered_address,

              authorized_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.authorized_capital,
              paid_up_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.paid_up_capital,
              date_of_incorporation: JSON.parse(res[0].APIResponse).data.details
                .company_info.date_of_incorporation,
              company_status: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_status,

              directors: JSON.parse(res[0].APIResponse).data.details.directors,
              charges: JSON.parse(res[0].APIResponse).data.details.charges,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,

              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
            this.Directors = new MatTableDataSource(
              JSON.parse(res[0].APIResponse).data.details.directors
            );
            this.Charges = new MatTableDataSource(
              JSON.parse(res[0].APIResponse).data.details.charges
            );
          } else if (KYC.KYC_DocId == 5 || KYC.DocId == 5) {
            this.APIResponse = {
              KYC_DocId: 5,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              doi: JSON.parse(res[0].APIResponse).data.doi,
              doe: JSON.parse(res[0].APIResponse).data.doe,
              address: JSON.parse(res[0].APIResponse).data.permanent_address,
              vehicle_classes: JSON.parse(res[0].APIResponse).data
                .vehicle_classes,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              ola_name: JSON.parse(res[0].APIResponse).data.ola_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 2 || KYC.DocId == 2) {
            this.APIResponse = {
              KYC_DocId: 2,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              address: JSON.parse(res[0].APIResponse).data.address,
              mobile: JSON.parse(res[0].APIResponse).data.mobile,
              state: JSON.parse(res[0].APIResponse).data.state,
              operator_code: JSON.parse(res[0].APIResponse).data.operator_code,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),
              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              area: JSON.parse(res[0].APIResponse).data.area,
              dist: JSON.parse(res[0].APIResponse).data.district,
              state: JSON.parse(res[0].APIResponse).data.state,
              age: JSON.parse(res[0].APIResponse).data.age,
              relation_name: JSON.parse(res[0].APIResponse).data.relation_name,
              relation_type: JSON.parse(res[0].APIResponse).data.relation_type,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          }

          $(`#modalViewVerifiedData${this.index}`).modal("show");
          $(`#modalViewVerifiedData${this.index}`).css("z-index", "1090");
        }
      });
  }
}
