import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerService } from "../services/partner.service";
import { constantUrl } from "../../Shared/constantUrl";

@Component({
  selector: "app-partner-loan-info",
  templateUrl: "./partner-loan-info.component.html",
  styleUrls: ["./partner-loan-info.component.scss"],
})
export class PartnerLoanInfoComponent implements OnInit {
  showSpinner: boolean = false;
  isCalEMIForm: boolean = false;
  applicationDetail: any = "";
  isCalEMITableView: boolean = true;
  loading: boolean = false;

  @Input() loanSummary: any;
  @Input() PageType: any;
  @Input() isNew: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  dataCustomerSource: any;
  dataCustomerAddressSource: any;
  dataCustomerDocSource: any;
  data: any;

  displayedCustomerColumns: string[] = [
    "Number",
    "CustomerId",
    "Customer",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
    "Relation_With_Hirer",
    "ExistingCustomer",
  ];
  displayedCustomerAddressColumns: string[] = [
    "CustomerId",
    "Customer",
    "Present_Address",
    "Rent_Own",
    "TotalYearsOnAddress",
    "Permanent_Address",
    "Work_Address",
  ];
  displayedCustomerDocColumns: string[] = [
    "CustomerId",
    "Customer",
    "Doc_Title",
    "Doc_Number",
    "Doc_FileName",
    "UploadOn",
  ];

  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;

  constructor(
    private LmsService: LmsService,
    private dataSharingService: DataSharingService,
    private _Partnerservice: PartnerService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Info");

    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLoanFinancialDetails();
    this.GetCustomerDetails();
  }

  GetLoanFinancialDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetApplicationDetailsForSaleProcess({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.Item1.length > 0 && res.Item1[0].NetFinance > 0) {
        this.applicationDetail = res.Item1[0];
        this.dataSharingService.LmsHeaderNetFinance.next(
          this.applicationDetail.NetFinance
        );
        this.isCalEMITableView = false;
        this.isCalEMIForm = true;

        this.applicationDetail.Recommendation || "";
      } else {
        this.isCalEMITableView = true;
        this.isCalEMIForm = false;
      }
    });
  }

  onSaveLoanInfo() {
    this.action.emit("next");
  }

  GetCustomerDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      this.applicationDetail = res.Item1[0];
      this.dataCustomerSource = new MatTableDataSource(res.Item2);
      this.dataCustomerAddressSource = new MatTableDataSource(res.Item3);
      this.dataCustomerDocSource = new MatTableDataSource(res.Item4);
    });
  }
}
