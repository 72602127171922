<div class="formborder">
  <form #f="ngForm" (ngSubmit)="onSaveEmployeeDetailData()" novalidate>
    <div class="row m-0 align-items-center">
      <div class="col-md-2" style="padding-right: 0px; padding-left: 7px">
        <div class="row m-0 align-items-center">
          <div class="col-md-12">
            <img
              src="{{
                UploadImageBase64
                  ? UploadImageBase64
                  : '/assets/images/profile.png'
              }}"
              alt=""
              style="
                height: 120px;
                width: 150px;
                margin: 0px 0px 0px 16px;
                position: relative;
              "
            />
            <label
              class="btn font-size-12 button-btn choose-img"
              for="profilePicture"
              >Choose Profile</label
            >

            <input
              type="file"
              style="visibility: hidden"
              name="profilePicture"
              id="profilePicture"
              [(ngModel)]="profilePicture"
              accept=".jpg, .jpeg, .png"
              (change)="handleUpload($event)"
            />
          </div>

          <div
            class="col-md-12"
            style="padding-right: 0px !important; padding-left: 0px !important"
          >
            <span class="required-lable"> Select DOB </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="picker"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="DateOfBirth"
                id="DateOfBirth"
                #refDateOfBirth="ngModel"
                style="width: 190px !important"
                [ngClass]="{
                  'is-invalid': f.submitted && refDateOfBirth.invalid,
                  'alert-warning':
                    refDateOfBirth.invalid &&
                    (refDateOfBirth.dirty ||
                      refDateOfBirth.touched ||
                      refDateOfBirth.untouched)
                }"
                [(ngModel)]="DateOfBirth"
                class="form-control input-text-css"
                [max]="CurrentDate"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <!-- <input
              name="DateOfBirth"
              id="DateOfBirth"
              #refDateOfBirth="ngModel"
              style="width: 190px !important"
              [ngClass]="{
                'is-invalid': f.submitted && refDateOfBirth.invalid,
                'alert-warning':
                  refDateOfBirth.invalid &&
                  (refDateOfBirth.dirty ||
                    refDateOfBirth.touched ||
                    refDateOfBirth.untouched)
              }"
              [(ngModel)]="DateOfBirth"
              value="{{ DateOfBirth | date : 'dd/MM/yyyy' }}"
              placeholder="DD/MM/YYYY"
              class="form-control input-text-css"
              [maxDate]="CurrentDate"
              [(bsValue)]="DateOfBirth"
              bsDatepicker
              required
            /> -->
          </div>
        </div>
      </div>
      <div class="col-md-10" style="padding-right: 0px; padding-left: 7px">
        <div
          class="row m-0 align-items-center"
          style="padding-right: 0px; padding-left: 20px"
        >
          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span class="required-lable"> Select Department </span>
            <form [formGroup]="DeptForm" *ngIf="DeptForm">
              <ng-multiselect-dropdown
                name="Department"
                [placeholder]="'Select Department'"
                [data]="DepartmentList"
                formControlName="Department"
                [settings]="DeptDropdownSettings"
                [(ngModel)]="Department"
                (onDeSelect)="OnDepartmentSelect($event)"
                (onSelect)="OnDepartmentSelect($event)"
              >
              </ng-multiselect-dropdown>
            </form>
            <!-- <select name="SelectDeptId" id="SelectDeptId" #refSelectDeptId="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && refSelectDeptId.invalid, 'alert-warning': refSelectDeptId.invalid  && (refSelectDeptId.dirty || refSelectDeptId.touched || refSelectDeptId.untouched) }"
                [(ngModel)]="SelectDeptId" class="form-control input-text-css" required>
          <option value=""> Select Department </option>
          <option *ngFor="let dept of DepartmentList" [value]="dept.DeptId">
            {{dept.Dept_Name}}
          </option>
        </select> -->
          </div>

          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span class="required-lable"> Select Designation </span>
            <select
              name="SelectRoleId"
              id="SelectRoleId"
              #refSelectRoleId="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refSelectRoleId.invalid,
                'alert-warning':
                  refSelectRoleId.invalid &&
                  (refSelectRoleId.dirty ||
                    refSelectRoleId.touched ||
                    refSelectRoleId.untouched)
              }"
              [(ngModel)]="SelectRoleId"
              (change)="onChangeRoles()"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Designation</option>
              <option
                *ngFor="let rolesDropdownData of RolesDropdownData"
                [value]="rolesDropdownData.RoleId"
              >
                {{ rolesDropdownData.Role_Name }}
              </option>
            </select>
          </div>
          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span>Select Role</span>
            <form [formGroup]="myForm" *ngIf="myForm">
              <ng-multiselect-dropdown
                name="Role"
                [placeholder]="'Select Role'"
                [data]="RoleDropdown"
                formControlName="Role"
                [settings]="dropdownSettings"
                [(ngModel)]="RoleId"
                (onDeSelect)="onItemSelect($event)"
                (onSelect)="onItemSelect($event)"
              >
              </ng-multiselect-dropdown>
            </form>
          </div>
        </div>
        <div
          class="row m-0 align-items-center"
          style="padding-right: 0px; padding-left: 20px"
        >
          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span class="required-lable"> First Name </span>
            <input
              required
              type="text"
              #refFirstName="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refFirstName.invalid,
                'alert-warning':
                  refFirstName.invalid &&
                  (refFirstName.dirty ||
                    refFirstName.touched ||
                    refFirstName.untouched)
              }"
              [(ngModel)]="FirstName"
              placeholder="First Name"
              name="FirstName"
              id="FirstName"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span class="required-lable"> Last Name </span>
            <input
              required
              type="text"
              #refLastName="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refLastName.invalid,
                'alert-warning':
                  refLastName.invalid &&
                  (refLastName.dirty ||
                    refLastName.touched ||
                    refLastName.untouched)
              }"
              [(ngModel)]="LastName"
              placeholder="Last Name"
              name="LastName"
              id="LastName"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4" style="padding-right: 0px; padding-left: 7px">
            <span class="required-lable"> Select Gender </span>
            <select
              name="SelectGender"
              id="SelectGender"
              #refSelectGender="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refSelectGender.invalid,
                'alert-warning':
                  refSelectGender.invalid &&
                  (refSelectGender.dirty ||
                    refSelectGender.touched ||
                    refSelectGender.untouched)
              }"
              [(ngModel)]="SelectGender"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
        </div>

        <div class="row m-0 align-items-center">
          <div class="col-md-12 emp-input">
            <span class="required-lable">Present Address</span>
            <textarea
              required
              rows="2"
              #refPresentAddress="ngModel"
              placeholder="Present Address"
              name="PresentAddress"
              id="PresentAddress"
              [ngClass]="{
                'is-invalid': f.submitted && refPresentAddress.invalid,
                'alert-warning':
                  refPresentAddress.invalid &&
                  (refPresentAddress.dirty ||
                    refPresentAddress.touched ||
                    refPresentAddress.untouched)
              }"
              [(ngModel)]="PresentAddress"
              class="form-control input-text-css mt-1"
            ></textarea>
          </div>
          <div class="col-md-12 emp-input">
            <span>
              Permanent Address
              <mat-checkbox
                class="ml-4"
                style="font-size: 12px"
                id="Present-Address"
                name="Present-Address"
                (change)="onCopyPresentAdd($event)"
              >
                Same as Present Address
              </mat-checkbox>
            </span>
            <textarea
              rows="2"
              [(ngModel)]="PermanentAddress"
              placeholder="Permanent Address"
              name="PermanentAddress"
              id="PermanentAddress"
              class="form-control input-text-css"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-3 p-1">
        <span class="required-lable"> Mobile No </span>
        <input
          required
          maxlength="10"
          minlength="10"
          numbersOnly
          type="text"
          #refPhoneNo="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refPhoneNo.invalid,
            'alert-warning':
              refPhoneNo.invalid &&
              (refPhoneNo.dirty || refPhoneNo.touched || refPhoneNo.untouched)
          }"
          [(ngModel)]="PhoneNo"
          placeholder="Phone No"
          name="PhoneNo"
          id="PhoneNo"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span> Alternet Phone No </span>
        <input
          maxlength="10"
          type="text"
          numbersOnly
          [(ngModel)]="AlternetPhoneNo"
          placeholder="Alternet Phone No"
          name="AlternetPhoneNo"
          id="AlternetPhoneNo"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Email </span>
        <input
          required
          type="text"
          #refEmail="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refEmail.invalid,
            'alert-warning':
              refEmail.invalid &&
              (refEmail.dirty || refEmail.touched || refEmail.untouched)
          }"
          [(ngModel)]="EnterEmail"
          placeholder="Email"
          name="Email"
          id="Email"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span> Aadhaar No </span>
        <input
          maxlength="12"
          type="text"
          [(ngModel)]="AadharNo"
          placeholder="Aadhaar No"
          name="AadharNo"
          id="AadharNo"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span>PAN No</span>
        <input
          maxlength="10"
          type="text"
          [(ngModel)]="Emp_PAN_No"
          placeholder="PAN No"
          name="Emp_PAN_No"
          id="Emp_PAN_No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span>Employee Code</span>
        <input
          maxlength="10"
          type="text"
          [(ngModel)]="Emp_Code"
          placeholder="Employee Code"
          name="Emp_Code"
          id="Emp_Code"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Login Id </span>
        <input
          required
          type="text"
          #refEmployeeLoginId="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refEmployeeLoginId.invalid,
            'alert-warning':
              refEmployeeLoginId.invalid &&
              (refEmployeeLoginId.dirty ||
                refEmployeeLoginId.touched ||
                refEmployeeLoginId.untouched)
          }"
          [(ngModel)]="EmployeeLoginId"
          placeholder="Login Id"
          name="EmployeeLoginId"
          id="EmployeeLoginId"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Password </span>
        <input
          required
          type="password"
          #refEmployeePassword="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refEmployeePassword.invalid,
            'alert-warning':
              refEmployeePassword.invalid &&
              (refEmployeePassword.dirty ||
                refEmployeePassword.touched ||
                refEmployeePassword.untouched)
          }"
          [(ngModel)]="EmployeePassword"
          placeholder="Password"
          name="EmployeePassword"
          id="EmployeePassword"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Select Reporting Manager </span>
        <select
          name="EmpManagerId"
          id="EmpManagerId"
          #refEmpManagerId="ngModel"
          [disabled]="isEdit && isDisable"
          [ngClass]="{
            'is-invalid': f.submitted && refEmpManagerId.invalid,
            'alert-warning':
              refEmpManagerId.invalid &&
              (refEmpManagerId.dirty ||
                refEmpManagerId.touched ||
                refEmpManagerId.untouched)
          }"
          [(ngModel)]="EmpManagerId"
          class="form-control input-text-css"
          (change)="onChangeReportingManager()"
          required
        >
          <option value="">Select Reporting Manager</option>
          <option
            *ngFor="let reportingManager of ReportingManagerDropdown"
            [value]="reportingManager.EmpId"
          >
            {{ reportingManager.EmpName }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span> Base Branch </span>
        <select
          name="BaseBranchId"
          id="BaseBranchId"
          #refBaseBranchId="ngModel"
          [(ngModel)]="BaseBranchId"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option *ngFor="let item of BaseBranchesList" [value]="item.BranchId">
            {{ item.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Add/Modify/Delete Days Allowed</span>
        <input
          required
          type="text"
          #refDays_Allowed="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refDays_Allowed.invalid,
            'alert-warning':
              refDays_Allowed.invalid &&
              (refDays_Allowed.dirty ||
                refDays_Allowed.touched ||
                refDays_Allowed.untouched)
          }"
          [(ngModel)]="Days_Allowed"
          placeholder="Days Allowed"
          name="Days_Allowed"
          id="Days_Allowed"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Collection Limit</span>
        <input
          required
          type="text"
          #refCreditLimit="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refCreditLimit.invalid,
            'alert-warning':
              refCreditLimit.invalid &&
              (refCreditLimit.dirty ||
                refCreditLimit.touched ||
                refCreditLimit.untouched)
          }"
          [(ngModel)]="CreditLimit"
          placeholder="Credit Limit"
          name="CreditLimit"
          id="CreditLimit"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Cash Payment Limit</span>
        <input
          type="number"
          #refGroupName="ngModel"
          placeholder="Cash Payment List"
          [(ngModel)]="cashPaymentLimit"
          name="cashPaymentLimit"
          id="cashPaymentLimit"
          class="form-control input-text-css"
          onkeydown="notDecimal(event)"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Bank Payment Limit</span>
        <input
          type="number"
          #refGroupName="ngModel"
          placeholder="Bank Payment List"
          [(ngModel)]="bankPaymentLimit"
          name="bankPaymentLimit"
          id="bankPaymentLimit"
          class="form-control input-text-css"
          onkeydown="notDecimal(event)"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
        />
      </div>
      <div class="col-md-3 p-1" *ngIf="!IsCreateEmployee">
        <span class="required-lable">Select Account</span>
        <select
          name="Emp_AccountId"
          id="Emp_AccountId"
          required
          #refEmp_AccountId="ngModel"
          (change)="Get_Group()"
          [ngClass]="{
            'is-invalid': f.submitted && refEmp_AccountId.invalid,
            'alert-warning':
              refEmp_AccountId.invalid &&
              (refEmp_AccountId.dirty ||
                refEmp_AccountId.touched ||
                refEmp_AccountId.untouched)
          }"
          [(ngModel)]="Emp_AccountId"
          class="form-control input-text-css"
        >
          <option value="">Select Account</option>
          <option
            *ngFor="let data of AccountHeadDropdown"
            [value]="data.AccountId"
          >
            {{ data.Account_Name }}
          </option>
        </select>
      </div>

      <div class="col-md-3 p-1" *ngIf="!IsCreateEmployee">
        <span class="required-lable">A/C Group</span>
        <input
          type="text"
          #refGroupName="ngModel"
          disabled
          placeholder="GroupName"
          [(ngModel)]="GroupName"
          name="GroupName"
          id="GroupName"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3 mt-4 p-1" *ngIf="IsCreateEmployee">
        <mat-checkbox
          id="IsEmployee"
          name="IsEmployee"
          (change)="onChangeCreateEmpAcc($event)"
        >
          Do you want to create employee account ?
        </mat-checkbox>
      </div>

      <!-- <div *ngIf="EmployeeId == 0" class="col-md-3 p-0">
    <div class="row m-0 align-items-end">
      <div [ngClass]="isShowCopyEmployee ? 'col-9 p-1':'col-12 p-1'">
        <span> Copy Employee </span>
        <select name="CopyEmployeeId" id="CopyEmployeeId" [(ngModel)]="CopyEmployeeId"
          class="form-control input-text-css" (change)="onChangeCopyEmployee()">
          <option value=""> Select Employee </option>
          <option *ngFor="let employeeDropdown of CopyEmployeeDropdown" [value]="employeeDropdown.EmpId">
            {{employeeDropdown.EmpName}}
          </option>
        </select>
      </div>
      <div *ngIf="isShowCopyEmployee == true" class="col-3 p-1">
        <button type="button" class="btn btn-success font-size-12" (click)="onClickCopyEmployee()"> Copy </button>
      </div>
    </div>
  </div> -->
    </div>
    <div class="row m-0" *ngIf="IsCreateEmployee">
      <div class="col-md-3 p-1" *ngIf="Ischecked && IsCreateEmployee">
        <span class="required-lable">Select Group</span>
        <select
          name="GroupId"
          id="GroupId"
          required
          #refGroupId="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refGroupId.invalid,
            'alert-warning':
              refGroupId.invalid &&
              (refGroupId.dirty || refGroupId.touched || refGroupId.untouched)
          }"
          [(ngModel)]="GroupId"
          class="form-control input-text-css"
        >
          <option value="">Select Group</option>
          <option *ngFor="let data of groupDropdown" [value]="data.GroupId">
            {{ data.Group_Name }}
            <ng-container *ngIf="data.Group_Tag"
              >({{ data.Group_Tag }})</ng-container
            >
          </option>
        </select>
      </div>
    </div>

    <!--<div *ngIf="EmpManagerId">
      <span class="font-size-12"> <strong> Assign Branch</strong> </span>
      <div class="table-responsive mt-2" style="max-height: 195px;">
        <table class="table font-size-12">
          <thead>
            <tr>
              <th> # </th>
              <th> Branch Name </th>
              <th> Branch Code </th>
              <th> District </th>
              <th> Tehsil </th>
              <th> Select </th>
              <th> Delete </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let assignBranch of BranchesList; let i = index;" class="font-size-11">
              <td> {{i+1}} </td>
              <td> {{assignBranch.Branch_Name}} </td>
              <td> {{assignBranch.Branch_Code}} </td>
              <td> {{assignBranch.District_Name}} </td>
              <td> {{assignBranch.Tehsil_Name}} </td>
              <td>
                <mat-checkbox [checked]="assignBranch.isActiveSelect" [disabled]="assignBranch.isDisable"
                  id="checkbox-Branch-{{i}}" name="checkbox-Branch-{{i}}"
                  (change)="onChangeAssignBranch($event,assignBranch.BranchId)">
                </mat-checkbox>
              </td>
              <td> <i *ngIf="assignBranch.BranchAlradyAssigned" (click)="onDeleteAssignBranch(assignBranch.EmpBranchId)"
                  class="fa fa-minus-circle font-size-15" style="color:red;cursor: pointer;" aria-label="true"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr>
      <span class="font-size-12"> <strong> Assign Process</strong> </span>
      <div class="table-responsive mt-2" style="max-height: 195px;">
        <table class="table font-size-12">
          <thead>
            <tr>
              <th> # </th>
              <th> Process Name </th>
              <th> Select </th>
              <th> Delete </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let assignProcess of LoanProcessList; let i = index;" class="font-size-11">
              <td> {{i+1}} </td>
              <td> {{assignProcess.Process_Name}} </td>
              <td>
                <mat-checkbox [checked]="assignProcess.isActiveSelect" [disabled]="assignProcess.isDisable"
                  id="checkbox-Process-{{i}}" name="checkbox-Process-{{i}}"
                  (change)="onChangeAssignProcess($event,assignProcess.ProcessId)">
                </mat-checkbox>
              </td>
              <td> <i *ngIf="assignProcess.ProcessAlradyAssigned" class="fa fa-minus-circle font-size-15"
                  (click)="onDeleteAssignProcess(assignProcess.EmpProcessId)" style="color:red;cursor: pointer;"
                  aria-label="true"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>-->
    <div class="text-right mb-2">
      <button
        type="button"
        (click)="onSaveEmployeeDetailData()"
        class="btn font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!f.form.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
