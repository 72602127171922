import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { MasterService } from "..//../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-mst-loan-process",
  templateUrl: "./mst-loan-process.component.html",
  styleUrls: ["./mst-loan-process.component.scss"],
})
export class MstLoanProcessComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  loading: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  filterValue: any = "";
  LoanProcessList: any = [];
  IsRecommedToReject: boolean = false;
  IsRecommedToRejectMandatory: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "ProcessId",
    "Process",
    "ProcessGroup",
    "Application",
    "Sequence",
    "Level",
    "IsMandatoryText",
    "RecommedToRejectAction",
    "ProcessAt",
    "DeafultTAT",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // edit loan process data
  LoanProcessId: any = 0;
  ProcessName: any = "";
  GroupName: any = "";
  SelectMandatory: any = "1";
  ProcessAt: any = "Branch";
  DefaultTAT: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Process List");
    this.getLoanProcessList();
  }

  getLoanProcessList() {
    this.showSpinner = true;
    this._MasterService.GetProcessList().subscribe((result) => {
      this.LoanProcessList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.LoanProcessList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  onfilterValue() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  OnOpenEditLoanProcess(SelectedProcessId) {
    this.LoanProcessId = SelectedProcessId;
    this.LoanProcessList.map((item) => {
      if (item.ProcessId == SelectedProcessId) {
        this.ProcessName = item.Process;
        this.GroupName = item.ProcessGroup;
        this.SelectMandatory = item.IsMandatoryText == "NO" ? "0" : "1";
        this.ProcessAt = item.ProcessAt;
        this.DefaultTAT = item.DeafultTAT;
        this.IsRecommedToReject =
          item.IsRecommedToReject == "NO" ? false : true;
        this.IsRecommedToRejectMandatory = item.IsRecommedToRejectMandatory;
      }
    });

    $("#EditLoanProcessModal").modal("show");
    $("#EditLoanProcessModal").css("z-index", "1050");
  }

  OnClose() {
    this.LoanProcessId = 0;
    this.ProcessName = "";
    this.GroupName = "";
    this.SelectMandatory = "1";
    this.ProcessAt = "Branch";
    this.DefaultTAT = "";
    $("#EditLoanProcessModal").modal("hide");
  }

  onSaveLoanProcessData() {
    let updateJSON = {
      Process: {
        ProcessId: this.LoanProcessId,
        Process_Name: this.ProcessName,
        Process_GroupName: this.GroupName,
        Process_IsMandatory: parseInt(this.SelectMandatory),
        Process_At: this.ProcessAt,
        Process_DeafultTAT_Mint: parseInt(this.DefaultTAT),
        ModifyBy: this.data.userId,
        IsRecommedToReject: this.IsRecommedToReject == false ? "0" : "1",
      },
    };

    this.RequestModel.JSON = JSON.stringify(updateJSON);
    this._MasterService
      .UpdateProcessDetails(this.RequestModel)
      .subscribe((response) => {
        if (response == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Update Successfully.",
            ...this.configSuccess,
          });
          this.getLoanProcessList();
          this.OnClose();
        }
      });
  }
}
