<!-- <div class="d-flex align-items-center">
  <span class="flex-grow-1 text-white" style="color:white;font-size:medium">
    {{data}}
  </span>
  <button class="ml-2" mat-raised-button
          color="accent"
          (click)="snackBarRef.dismiss()">
    Close
  </button>
</div> -->

<div class="d-flex align-items-center">
  <span class="flex-grow-1 text-white fs-12">{{data}}</span>
  <i class="fa fa-times ml-2 snak" (click)="snackBarRef.dismiss()"></i>
</div>