<div>
  <div>
    <!-- [class.FixHeight]="PageAcess[0].View == 0" -->
    <div *ngIf="PageAcess[0].View == 1">
      <div class="row mt-2 mb-2">
        <div class="col-md-6">
          <button class="btn btn-outline-green btn-lg btn-block"
                  [ngClass]="{ active: tab == 'Cheque' }"
                  (click)="changeTab('Cheque')">
            Cheque Details
          </button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-outline-green btn-lg btn-block"
                  [ngClass]="{ active: tab == 'Repayment' }"
                  (click)="changeTab('Repayment')">
            Repayment Details
          </button>
        </div>
      </div>

      <div class="row mt-1" *ngIf="tab == 'Cheque'">
        <div class="col-md-12">
          <div class="table-responsive">
            <mat-table [dataSource]="dataCSource" matSort matTableExporter>
              <!--  style="height: 190px; max-width: 100%; overflow: auto;" -->
              <ng-container matColumnDef="Int_Id">
                <mat-header-cell mat-sort-header
                                 class="grid-header"
                                 style="max-width: 50px; flex: 5%"
                                 *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index"
                          class="grid-cell"
                          style="max-width: 50px">
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Pdc Type">
                <mat-header-cell class="grid-header" *matHeaderCellDef>Pdc Type</mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                  row.PdcType
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Customer Name">
                <mat-header-cell class="grid-header"
                                 style="max-width: 20%"
                                 *matHeaderCellDef>Customer Name</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 20%">
                  {{
                    row.Disbursment_Customer == null
                      ? (row.CustomerName | uppercase)
                      : (row.Disbursment_Customer | uppercase)
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Bank Name">
                <mat-header-cell class="grid-header"
                                 style="max-width: 10%"
                                 *matHeaderCellDef>Bank Name</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 10%">{{ row.Bankname | uppercase }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Branch">
                <mat-header-cell class="grid-header"
                                 style="max-width: 10%"
                                 *matHeaderCellDef>Branch</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 10%">{{ row.Branch | uppercase }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Acc Type">
                <mat-header-cell class="grid-header"
                                 style="max-width: 8%"
                                 *matHeaderCellDef>A/C Type</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 8%">{{ row.AccType }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="A/C No">
                <mat-header-cell class="grid-header" *matHeaderCellDef>A/C No</mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                  row.Ac
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ChFrom">
                <mat-header-cell class="grid-header"
                                 style="max-width: 10%"
                                 *matHeaderCellDef>Cheque No.</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 10%">{{ row.ChFrom }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="ChTo">
                <mat-header-cell class="grid-header" *matHeaderCellDef>ChTo</mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                  row.ChTo
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="No of Chqs">
                <mat-header-cell class="grid-header" *matHeaderCellDef>No of Chqs</mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                  row.NoOfChqs
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="IFSC Code">
                <mat-header-cell class="grid-header"
                                 style="max-width: 10%"
                                 *matHeaderCellDef>IFSC Code</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell"
                          style="max-width: 10%">{{ row.IFSCCode | uppercase }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Action">
                <mat-header-cell class="grid-header j-c-center"
                                 style="max-width: 8%; max-height: 5px"
                                 *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let row"
                          class="grid-cell j-c-center"
                          style="max-width: 8%; max-height: 5px">
                  <!--<i class="fa fa-pencil-alt right" (click)="goToUpdate(row)" style="cursor: pointer;color:green;font-size:16px;" aria-label="true"></i>-->
                  <i class="fa fa-trash style-delete"
                     *ngIf="
                      row.ISdelete == 1 &&
                      PageAcess[0].Delete == 1 &&
                      !loanSummary.CloseDate
                    "
                     (click)="Delete(row)"
                     aria-label="true"></i>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedCustomerColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedCustomerColumns"></mat-row>
            </mat-table>

            <div class="row m-0 align-items-center" style="background: #fff">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <mat-paginator #paginatorRef
                               [pageSizeOptions]="[20, 50]"
                               showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-1" *ngIf="tab == 'Repayment'">
        <!--<div class="col-md-12">
          <app-lms-nach-process
            [loanSummary]="loanSummary"
          ></app-lms-nach-process>
        </div>-->
        <div class="mt-3" *ngIf="dataSourceLoan">
          <div class="">
            <div class="table-responsive">
              <mat-table [dataSource]="dataSourceLoan"
                         matSort
                         matTableExporter
                         #exporter="matTableExporter"
                         [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]">
                <!-- [ngClass]="[loanSummary == undefined ? 'fixheight1' : 'fixheight2']" -->
                <!--<ng-container matColumnDef="Id" sticky>
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   style="min-width: 50px"
                                   *matHeaderCellDef>#</mat-header-cell>
                  <mat-cell *matCellDef="let row; let i = index"
                            class="grid-cell FreezColumn"
                            style="min-width: 50px; max-height: 5px">
                    {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>-->

                <ng-container matColumnDef="CaseNo">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   style="min-width: 60px"
                                   *matHeaderCellDef>Case No.</mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            style="min-width: 60px"
                            class="grid-cell FreezColumn">{{ row.LoanAcNo }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerName">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Customer Name</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell FreezColumn">
                    {{ row.CustomerName }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef style="margin-left: 16px">
                    Total:
                  </mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="EMINo">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>EMI No</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.EMINo }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="ChqueNo">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Cheque No.</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ChequeNo }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="EMI_Amount">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>EMI Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.EMI_Amount }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    {{ calculateTotal() }}
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="DueDate">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Due Date</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DueDateFormat }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerBankname">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Customer Bank</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomerBankname }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Type">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Status">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Status</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Status }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="PostDate">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef
                                   style="min-width: 130px">Post Date</mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            class="grid-cell j-c-center"
                            style="min-width: 130px">
                    {{ row.PostDate }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="ComapnyAccount">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   style="width: 350px"
                                   *matHeaderCellDef>Company Account</mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            style="width: 350px"
                            class="grid-cell">
                    {{ row.ComapnyAccount }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef
                                   style="width: 350px"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Clearing">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Clearing/Bounce</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ClearingType }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="ClearBounceDate">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Clear/ Bounce Date</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ClearBounceDate }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="BounceReason">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Bounce Reason</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BounceReason }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="BounceCharge">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Bounce Charge</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BounceCharge }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    {{ calculateBounceTotal() }}
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="HoldDate">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Hold Date</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.HoldDate }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="HoldReason">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>Hold Reason</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.HoldReason }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="NachCode">
                  <mat-header-cell mat-sort-header
                                   class="grid-header"
                                   *matHeaderCellDef>UMRN No.</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.NachCode }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsLoan; sticky: true"></mat-header-row>
                <mat-row (click)="highlightRow(row)"
                         [ngClass]="{
                    Post: row.Status == 'Post',
                    Hold: row.Status == 'Hold',
                    Bounce: row.Status == 'Bounce',
                    Clear: row.Status == 'Clear',
                    duedate: DateConvert(row.DueDate, row.Status),
                    highlight: row.Id == selectedId
                  }"
                         *matRowDef="let row; columns: displayedColumnsLoan"></mat-row>
                <mat-footer-row class="sticky-footer fontcolor"
                                *matFooterRowDef="displayedColumnsLoan; sticky: true"
                                style="background: #4dc3a3"></mat-footer-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 mt-2">
    <div class="col-md-3 p-0">
      <div *ngIf="tab == 'Cheque' && PageAcess[0].View == 1">
        <button type="button"
                (click)="OpenModel()"
                class="mt-2 btn font-size-12 button-btn"
                [class.spinner]="loading"
                *ngIf="PageAcess[0].Add == 1 && !loanSummary.CloseDate">
          Add Cheque Detail
        </button>
      </div>
    </div>
    <div class="col-md-3 p-0">
      <div *ngIf="tab == 'Cheque' && PageAcess[0].View == 1">
        <!-- <button type="button" (click)="OpenPaymentModel($event)" class="btn_default" mat-raised-button>
          Make Payment
        </button>
        <button type="button" id="btnSubmit" class="btn_default" mat-raised-button>
          Make Payment
        </button> -->
        <app-payment-api [loan_Summary]="loanSummary"
                         [TypeofPage]="'LMS'"
                         [ENachBankId]="Nach_Detail.ICICI_ENach"
                         [ENachHdfcBankId]="Nach_Detail.HDFC_ENach"
                         [ENachAUBankId]="Nach_Detail.AU_ENach"
                         [ENACHNupayHDFCId]="Nach_Detail.Nupay_HDFC"
                         *ngIf="Nach_Detail && ((Nach_Detail.ICICI_ENach !='' || Nach_Detail.ICICI_ENach> 0) || ( Nach_Detail.HDFC_ENach != '' ||Nach_Detail.HDFC_ENach > 0)|| ( Nach_Detail.AU_ENach != '' ||Nach_Detail.AU_ENach > 0) || ( Nach_Detail.Nupay_HDFC != '' ||Nach_Detail.Nupay_HDFC > 0))  && !loanSummary.CloseDate">
          <button type="button" class="mt-2 btn font-size-12 button-btn">
            NACH Registration
          </button>
        </app-payment-api>
      </div>
    </div>
    <div class="col-md-6 p-0 text-right">
      <button type="button"
              (click)="onNext()"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading">
        Next <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="BankModel"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cheque & Repayment Details
        </h6>
        <button type="button"
                (click)="onCloseBankModel()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <form #rf="ngForm"
            (ngSubmit)="onSaveBankChqDetail()"
            novalidate
            class="formborder">
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-3 p-0">
              <span class="required-lable">Customer</span>
              <select name="CustomerId"
                      id="CustomerId"
                      required
                      placeholder="Select Customer Name"
                      (change)="changeCustomer()"
                      [(ngModel)]="CustomerId"
                      class="form-control input-text-css"
                      #refCustomerId="ngModel"
                      [ngClass]="{
                  'is-invalid': rf.submitted && refCustomerId.invalid,
                  'alert-warning':
                    refCustomerId.invalid &&
                    (refCustomerId.dirty ||
                      refCustomerId.touched ||
                      refCustomerId.untouched)
                }">
                <option value="">Select Customer</option>
                <option *ngFor="let customer of CustomerList"
                        [value]="customer.CustomerId">
                  {{ customer.Customer }} ({{
                    customer.Customer_Type == "H"
                      ? "Borrower"
                      : customer.Customer_Type == "C"
                      ? "Co-Borrower"
                      : "Guarantor"
                  }})
                </option>
              </select>
            </div>
            <div class="col-md-9 p-0 text-right" *ngIf="CustomerId != ''">
              <button type="button"
                      (click)="AddCustomerBank()"
                      class="btn font-size-12 button-btn mt-3"
                      mat-raised-button
                      [disabled]="CustomerId == ''">
                Add Bank Detail
              </button>
            </div>
          </div>

          <ng-container *ngIf="CustomerId != ''">
            <div class="row m-0">
              <div class="col-md-12 p-0"><hr /></div>
            </div>

            <div class="formborder mt-2">
              <ng-container *ngIf="BankModel.length > 0">
                <!-- <div [hidden]="PDCType && PDCType.PdcType == 'Disbursment'"> -->
                <div class="row m-0">
                  <div class="col-md-12 p-1 bg-success1">
                    Bank Detail For Disbursment:
                  </div>
                </div>
                <div class="row mt-2"
                     *ngFor="let CBank of BankModel; let i = index">
                  <div class="col-md-1">
                    <span class="required-lable">Type</span>
                    <select name="Type{{ i }}"
                            id="Type{{ i }}"
                            #refType="ngModel"
                            required
                            class="form-control input-text-css"
                            [ngClass]="{
                        'is-invalid': rf.submitted && refType.invalid,
                        'alert-warning':
                          refType.invalid &&
                          (refType.dirty ||
                            refType.touched ||
                            refType.untouched)
                      }"
                            [(ngModel)]="CBank.Type"
                            [required]="!PDCType"
                            (change)="ChangeType(i)">
                      <option value="">Select Type</option>
                      <option value="Own">Own</option>
                      <option value="Other">Other</option>
                      <option value="Cash">Cash</option>
                      <option value="Dealer">Dealer</option>
                      <option value="Partner">Partner</option>

                      <!--<option value="Dealer" *ngIf="DealerId > 0">
        Dealer
      </option>-->
                    </select>
                  </div>
                  <div class="col-md-9" *ngIf="CBank.Type!='Cash' && CBank.Type!='Dealer' && CBank.Type!='Partner'" style="padding: 0 0px 0 0px">
                    <div class="row">
                      <div class="col-md-2">
                        <span class="required-lable">A/C No</span>
                        <select name="AccountNo{{ i }}"
                                id="AccountNo{{ i }}"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="CBank.BankId"
                                *ngIf="CBank.Type == '' || CBank.Type == 'Own'"
                                (change)="ChangeBankACNo(CBank, i, 'Bank')"
                                [required]="CBank.Type != ''"
                                [ngClass]="{
                            'is-invalid': rf.submitted && refAccountNo.invalid,
                            'alert-warning':
                              refAccountNo.invalid &&
                              (refAccountNo.dirty ||
                                refAccountNo.touched ||
                                refAccountNo.untouched)
                          }">
                          <!--[required]="checkDetailData.length == 0"-->
                          <option value="">Select A/C</option>
                          <option *ngFor="let CuBank of CustomerBank"
                                  [value]="CuBank.Id">
                            {{ CuBank.AccountNo }}
                          </option>
                        </select>

                        <textarea rows="1"
                                  name="AccountNo{{ i }}"
                                  id="AccountNo{{ i }}"
                                  [(ngModel)]="CBank.AccountNo"
                                  #refAccountNo="ngModel"
                                  required
                                  [disabled]="CBank.Type == 'Dealer'"
                                  placeholder="A/C No"
                                  class="form-control input-text-css"
                                  *ngIf="
                            CBank.Type == 'Other' || CBank.Type == 'Dealer'
                          "
                                  [ngClass]="{
                            'is-invalid': rf.submitted && refAccountNo.invalid,
                            'alert-warning':
                              refAccountNo.invalid &&
                              (refAccountNo.dirty ||
                                refAccountNo.touched ||
                                refAccountNo.untouched)
                          }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Beneficiary Name</span>
                        <textarea rows="1"
                                  name="BeneficiaryName{{ i }}"
                                  id="BeneficiaryName{{ i }}"
                                  [(ngModel)]="CBank.BeneficiaryName"
                                  [required]="CBank.Type == 'Other'"
                                  #refBeneficiaryName="ngModel"
                                  placeholder="Beneficiary"
                                  class="form-control input-text-css"
                                  [disabled]="
                            CBank.Type == 'Own' || CBank.Type == 'Dealer'
                          "
                                  [ngClass]="{
                            'is-invalid':
                              rf.submitted && refBeneficiaryName.invalid,
                            'alert-warning':
                              refBeneficiaryName.invalid &&
                              (refBeneficiaryName.dirty ||
                                refBeneficiaryName.touched ||
                                refBeneficiaryName.untouched)
                          }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Name</span>
                        <textarea rows="1"
                                  name="BankName{{ i }}"
                                  id="BankName{{ i }}"
                                  [(ngModel)]="CBank.BankName"
                                  #refBankName="ngModel"
                                  placeholder="Bank Name"
                                  class="form-control input-text-css"
                                  [disabled]="
                            CBank.Type == 'Own' || CBank.Type == 'Dealer'
                          "
                                  [required]="CBank.Type == 'Other'"
                                  [ngClass]="{
                            'is-invalid': rf.submitted && refBankName.invalid,
                            'alert-warning':
                              refBankName.invalid &&
                              (refBankName.dirty ||
                                refBankName.touched ||
                                refBankName.untouched)
                          }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Branch</span>
                        <textarea rows="1"
                                  name="BankBranch{{ i }}"
                                  id="BankBranch{{ i }}"
                                  [(ngModel)]="CBank.BankBranch"
                                  #refBankBranch="ngModel"
                                  placeholder="Bank Branch"
                                  class="form-control input-text-css"
                                  [disabled]="
                            CBank.Type == 'Own' || CBank.Type == 'Dealer'
                          "
                                  [required]="CBank.Type == 'Other'"
                                  [ngClass]="{
                            'is-invalid': rf.submitted && refBankBranch.invalid,
                            'alert-warning':
                              refBankBranch.invalid &&
                              (refBankBranch.dirty ||
                                refBankBranch.touched ||
                                refBankBranch.untouched)
                          }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">A/C Type</span>
                        <select name="BankAcType{{ i }}"
                                id="BankAcType{{ i }}"
                                [(ngModel)]="CBank.BankAcType"
                                #refBankAcType="ngModel"
                                class="form-control input-text-css"
                                [disabled]="CBank.Type == 'Own'"
                                [required]="
                            CBank.Type == 'Other' || CBank.Type == 'Dealer'
                          "
                                [ngClass]="{
                            'is-invalid': rf.submitted && refBankAcType.invalid,
                            'alert-warning':
                              refBankAcType.invalid &&
                              (refBankAcType.dirty ||
                                refBankAcType.touched ||
                                refBankAcType.untouched)
                          }">
                          <option value="">Select A/C Type</option>
                          <option value="SB">Saving A/C</option>
                          <option value="CC">Cash Credit A/C</option>
                          <option value="OD">Over Draft A/C</option>
                          <option value="CA">Current A/C</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">IFSC Code</span>
                        <input type="text"
                               name="BankIFSC{{ i }}"
                               id="BankIFSC{{ i }}"
                               [(ngModel)]="CBank.BankIFSC"
                               #refBankIFSC="ngModel"
                               [required]="CBank.Type == 'Other'"
                               placeholder="IFSC Code"
                               class="form-control input-text-css"
                               required
                               [disabled]="
                            CBank.Type == 'Own' || CBank.Type == 'Dealer'
                          "
                               maxlength="11"
                               [ngClass]="{
                            'is-invalid': rf.submitted && refBankIFSC.invalid,
                            'alert-warning':
                              refBankIFSC.invalid &&
                              (refBankIFSC.dirty ||
                                refBankIFSC.touched ||
                                refBankIFSC.untouched)
                          }" />
                      </div>
                    </div>
                  </div>


                  <div class="col-md-9" *ngIf="CBank.Type=='Dealer' || CBank.Type=='Partner'" style="padding: 0 0px 0 0px">
                    <div class="row">
                      <div class="col-md-2">
                        <span class="required-lable">A/C No</span>
                        <select name="AccountNo{{ i }}"
                                id="AccountNo{{ i }}"
                                *ngIf="CBank.Type=='Dealer'"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="CBank.OtherId"
                                (change)="ChangeBankACNo(CBank, i, CBank.Type)"
                                [required]="CBank.Type != ''"
                                [ngClass]="{
          'is-invalid': rf.submitted && refAccountNo.invalid,
          'alert-warning':
            refAccountNo.invalid &&
            (refAccountNo.dirty ||
              refAccountNo.touched ||
              refAccountNo.untouched)
        }">
                          <!--[required]="checkDetailData.length == 0"-->
                          <option value="">Select A/C</option>
                          <option *ngFor="let CuBank of DealerDropdown"
                                  [value]="CuBank.Id">
                            {{ CuBank.Partner_AccountHolderName+'_'+CuBank.Partner_AccountNo }}
                          </option>
                        </select>

                        <select name="AccountNo{{ i }}"
                                id="AccountNo{{ i }}"
                                *ngIf="CBank.Type=='Partner'"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="CBank.OtherId"
                                (change)="ChangeBankACNo(CBank, i, CBank.Type)"
                                [required]="CBank.Type != ''"
                                [ngClass]="{
  'is-invalid': rf.submitted && refAccountNo.invalid,
  'alert-warning':
    refAccountNo.invalid &&
    (refAccountNo.dirty ||
      refAccountNo.touched ||
      refAccountNo.untouched)
}">
                          <!--[required]="checkDetailData.length == 0"-->
                          <option value="">Select A/C</option>
                          <option *ngFor="let CuBank of PartnerDropdown"
                                  [value]="CuBank.Id">
                            {{ CuBank.Partner_AccountHolderName+'_'+CuBank.Partner_AccountNo }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Beneficiary Name</span>
                        <textarea rows="1"
                                  name="BeneficiaryName{{ i }}"
                                  id="BeneficiaryName{{ i }}"
                                  [(ngModel)]="CBank.BeneficiaryName"
                                  disabled
                                  #refBeneficiaryName="ngModel"
                                  placeholder="Beneficiary"
                                  class="form-control input-text-css"
                                  [ngClass]="{
          'is-invalid':
            rf.submitted && refBeneficiaryName.invalid,
          'alert-warning':
            refBeneficiaryName.invalid &&
            (refBeneficiaryName.dirty ||
              refBeneficiaryName.touched ||
              refBeneficiaryName.untouched)
        }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Name</span>
                        <textarea rows="1"
                                  name="BankName{{ i }}"
                                  id="BankName{{ i }}"
                                  [(ngModel)]="CBank.BankName"
                                  #refBankName="ngModel"
                                  placeholder="Bank Name"
                                  class="form-control input-text-css"
                                  disabled
                                  [ngClass]="{
          'is-invalid': rf.submitted && refBankName.invalid,
          'alert-warning':
            refBankName.invalid &&
            (refBankName.dirty ||
              refBankName.touched ||
              refBankName.untouched)
        }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Branch</span>
                        <textarea rows="1"
                                  name="BankBranch{{ i }}"
                                  id="BankBranch{{ i }}"
                                  [(ngModel)]="CBank.BankBranch"
                                  #refBankBranch="ngModel"
                                  placeholder="Bank Branch"
                                  class="form-control input-text-css"
                                  disabled
                                  [ngClass]="{
          'is-invalid': rf.submitted && refBankBranch.invalid,
          'alert-warning':
            refBankBranch.invalid &&
            (refBankBranch.dirty ||
              refBankBranch.touched ||
              refBankBranch.untouched)
        }"></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">A/C Type</span>
                        <select name="BankAcType{{ i }}"
                                id="BankAcType{{ i }}"
                                [(ngModel)]="CBank.BankAcType"
                                #refBankAcType="ngModel"
                                class="form-control input-text-css"
                                [required]="CBank.Type == 'Dealer' || CBank.Type == 'Partner'"
                                [ngClass]="{
          'is-invalid': rf.submitted && refBankAcType.invalid,
          'alert-warning':
            refBankAcType.invalid &&
            (refBankAcType.dirty ||
              refBankAcType.touched ||
              refBankAcType.untouched)
        }">
                          <option value="">Select A/C Type</option>
                          <option value="SB">Saving A/C</option>
                          <option value="CC">Cash Credit A/C</option>
                          <option value="OD">Over Draft A/C</option>
                          <option value="CA">Current A/C</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">IFSC Code</span>
                        <input type="text"
                               name="BankIFSC{{ i }}"
                               id="BankIFSC{{ i }}"
                               [(ngModel)]="CBank.BankIFSC"
                               #refBankIFSC="ngModel"
                               placeholder="IFSC Code"
                               class="form-control input-text-css"
                               required
                               disabled
                               maxlength="11"
                               [ngClass]="{
          'is-invalid': rf.submitted && refBankIFSC.invalid,
          'alert-warning':
            refBankIFSC.invalid &&
            (refBankIFSC.dirty ||
              refBankIFSC.touched ||
              refBankIFSC.untouched)
        }" />
                      </div>
                    </div>
                  </div>





                  <div class="col-md-9" *ngIf="CBank.Type=='Cash'" style="padding-right: 2px">
                    <span>Beneficiary Name</span>
                    <textarea rows="1"
                              name="BeneficiaryName{{ i }}"
                              id="BeneficiaryName{{ i }}"
                              [(ngModel)]="CBank.BeneficiaryName"
                              [required]="CBank.Type == 'Other'"
                              #refBeneficiaryName="ngModel"
                              placeholder="Beneficiary"
                              class="form-control input-text-css"
                              disabled
                              [ngClass]="{
                             'is-invalid':
                               rf.submitted && refBeneficiaryName.invalid,
                             'alert-warning':
                               refBeneficiaryName.invalid &&
                               (refBeneficiaryName.dirty ||
                                 refBeneficiaryName.touched ||
                                 refBeneficiaryName.untouched)
                           }"></textarea>
                  </div>
                  <div class="col-md-1" style="padding-right: 2px">
                    <span>Amount</span>
                    <input type="text"
                           name="AmountDisbursment{{ i }}"
                           id="AmountDisbursment{{ i }}"
                           [(ngModel)]="CBank.AmountDisbursment"
                           #refAmountDisbursment="ngModel"
                           placeholder="Amount"
                           class="form-control input-text-css"
                           numbersOnly />
                  </div>
                  <div class="col-md-1 pt-3">
                    <button type="button"
                            class="remove"
                            (click)="removeBankDetail(i)"
                            *ngIf="i < BankModel.length && BankModel.length > 1">
                      -
                    </button>
                    <button type="button"
                            class="addmore"
                            (click)="addBankDetail()"
                            *ngIf="i == BankModel.length - 1">
                      <!-- [hidden]="CustomerBank.length == 1"-->
                      +
                    </button>
                  </div>
                </div>
                <!-- </div> -->
              </ng-container>
            </div>

            <div class="formborder mt-2" *ngIf="CustomerId != '' && CustomerBank.length > 0">
              <ng-container *ngIf="SPDCModel.length > 0">
                <!--  && Customer_Data.Customer_Type != 'G' -->
                <div class="row m-0 mt-2">
                  <div class="col-md-12 p-1 bg-success1">SPDC Detail:</div>
                </div>
                <div class="row mt-2"
                     *ngFor="let SPDC of SPDCModel; let i = index">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-2">
                        <span class="required-lable">A/C No</span>
                        <select name="SAccountNo{{ i }}"
                                id="SAccountNo{{ i }}"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="SPDC.BankId"
                                (change)="ChangeBankACNo(SPDC, i, 'SPDC')">
                          <option value="">Select A/C</option>
                          <option *ngFor="let CuBank of CustomerBank"
                                  [value]="CuBank.Id">
                            {{ CuBank.AccountNo }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Beneficiary Name</span>
                        <textarea rows="1"
                                  name="SBeneficiaryName{{ i }}"
                                  id="SBeneficiaryName{{ i }}"
                                  [(ngModel)]="SPDC.BeneficiaryName"
                                  #refBeneficiaryName="ngModel"
                                  placeholder="Beneficiary"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Name</span>
                        <textarea rows="1"
                                  name="SBankName{{ i }}"
                                  id="SBankName{{ i }}"
                                  [(ngModel)]="SPDC.BankName"
                                  #refBankName="ngModel"
                                  placeholder="Bank Name"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Branch</span>
                        <textarea rows="1"
                                  name="SBankBranch{{ i }}"
                                  id="SBankBranch{{ i }}"
                                  [(ngModel)]="SPDC.BankBranch"
                                  #refBankBranch="ngModel"
                                  placeholder="Bank Branch"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">A/C Type</span>
                        <select name="SBankAcType{{ i }}"
                                id="SBankAcType{{ i }}"
                                [(ngModel)]="SPDC.BankAcType"
                                #refBankAcType="ngModel"
                                class="form-control input-text-css"
                                disabled>
                          <option value="">Select A/C Type</option>
                          <option value="SB">Saving A/C</option>
                          <option value="CC">Cash Credit A/C</option>
                          <option value="OD">Over Draft A/C</option>
                          <option value="CA">Current A/C</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">IFSC Code</span>
                        <input type="text"
                               name="SBankIFSC{{ i }}"
                               id="SBankIFSC{{ i }}"
                               [(ngModel)]="SPDC.BankIFSC"
                               #refBankIFSC="ngModel"
                               placeholder="IFSC Code"
                               class="form-control input-text-css"
                               disabled
                               maxlength="11" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1" style="padding-right: 2px">
                    <span>MICR No</span>
                    <input type="text"
                           name="SBankMICRCode{{ i }}"
                           id="SBankMICRCode{{ i }}"
                           [(ngModel)]="SPDC.BankMICRCode"
                           #refBankMICRCode="ngModel"
                           placeholder="MICR No"
                           class="form-control input-text-css"
                           disabled
                           maxlength="9"
                           numbersOnly />
                  </div>
                  <div class="col-md-1 pt-3">
                    <button type="button"
                            class="remove"
                            (click)="removeSPDCDetail(i)"
                            *ngIf="i < SPDCModel.length && SPDCModel.length > 1">
                      -
                    </button>
                    <button type="button"
                            class="addmore"
                            (click)="addSPDCDetail()"
                            *ngIf="i == SPDCModel.length - 1">
                      <!-- [hidden]="CustomerBank.length == 1"-->
                      +
                    </button>
                  </div>

                  <div class="col-md-2 mt-2">
                    <span class="required-lable">Ch. No From</span>
                    <input type="text"
                           (change)="calculateNoOfCheque(i, SPDC, 'SPDC')"
                           [required]="SPDC.BankId != ''"
                           placeholder="Ch. No From"
                           minlength="6"
                           maxlength="6"
                           numbersOnly
                           name="SChFrom{{ i }}"
                           id="SChFrom{{ i }}"
                           class="form-control input-text-css"
                           [(ngModel)]="SPDC.ChFrom"
                           #refChFrom="ngModel"
                           [ngClass]="{
                        'is-invalid': rf.submitted && refChFrom.invalid,
                        'alert-warning':
                          refChFrom.invalid &&
                          (refChFrom.dirty ||
                            refChFrom.touched ||
                            refChFrom.untouched)
                      }" />
                  </div>

                  <div class="col-md-2 mt-2">
                    <span class="required-lable">Ch. No To</span>
                    <input type="text"
                           (change)="calculateNoOfCheque(i, SPDC, 'SPDC')"
                           [required]="SPDC.BankId != ''"
                           placeholder="Ch. No To"
                           minlength="6"
                           maxlength="6"
                           numbersOnly
                           name="SChTo{{ i }}"
                           id="SChTo{{ i }}"
                           class="form-control input-text-css"
                           [(ngModel)]="SPDC.ChTo"
                           #refChTo="ngModel"
                           [ngClass]="{
                        'is-invalid': rf.submitted && refChTo.invalid,
                        'alert-warning':
                          refChTo.invalid &&
                          (refChTo.dirty ||
                            refChTo.touched ||
                            refChTo.untouched)
                      }" />
                  </div>
                  <div class="col-md-2 mt-2">
                    <span class="required-lable">No of Chqs</span>
                    <input type="text"
                           name="SNoOfChqs{{ i }}"
                           id="SNoOfChqs{{ i }}"
                           disabled
                           placeholder="No of Chqs"
                           class="form-control input-text-css"
                           [(ngModel)]="SPDC.NoOfChqs" />
                  </div>

                  <div class="col-md-12"><hr /></div>
                </div>
              </ng-container>
            </div>

            <div class="formborder mt-2" *ngIf="CustomerId != '' && CustomerBank.length > 0">
              <ng-container *ngIf="
                            RPDCModel.length>
                0 && Customer_Data.Customer_Type != 'G'
                "
                >
                <div class="row m-0 mt-2">
                  <div class="col-md-12 p-1 bg-success1">RPDC Detail:</div>
                </div>
                <div class="row mt-2"
                     *ngFor="let RPDC of RPDCModel; let i = index">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-2">
                        <span class="required-lable">A/C No</span>
                        <select name="RAccountNo{{ i }}"
                                id="RAccountNo{{ i }}"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="RPDC.BankId"
                                (change)="ChangeBankACNo(RPDC, i, 'RPDC')">
                          <option value="">Select A/C</option>
                          <option *ngFor="let CuBank of CustomerBank"
                                  [value]="CuBank.Id">
                            {{ CuBank.AccountNo }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Beneficiary Name</span>
                        <textarea rows="1"
                                  name="RBeneficiaryName{{ i }}"
                                  id="RBeneficiaryName{{ i }}"
                                  [(ngModel)]="RPDC.BeneficiaryName"
                                  #refBeneficiaryName="ngModel"
                                  placeholder="Beneficiary"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Name</span>
                        <textarea rows="1"
                                  name="RBankName{{ i }}"
                                  id="RBankName{{ i }}"
                                  [(ngModel)]="RPDC.BankName"
                                  #refBankName="ngModel"
                                  placeholder="Bank Name"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Branch</span>
                        <textarea rows="1"
                                  name="RBankBranch{{ i }}"
                                  id="RBankBranch{{ i }}"
                                  [(ngModel)]="RPDC.BankBranch"
                                  #refBankBranch="ngModel"
                                  placeholder="Bank Branch"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">A/C Type</span>
                        <select name="RBankAcType{{ i }}"
                                id="RBankAcType{{ i }}"
                                [(ngModel)]="RPDC.BankAcType"
                                #refBankAcType="ngModel"
                                class="form-control input-text-css"
                                disabled>
                          <option value="">Select A/C Type</option>
                          <option value="SB">Saving A/C</option>
                          <option value="CC">Cash Credit A/C</option>
                          <option value="OD">Over Draft A/C</option>
                          <option value="CA">Current A/C</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">IFSC Code</span>
                        <input type="text"
                               name="RBankIFSC{{ i }}"
                               id="RBankIFSC{{ i }}"
                               [(ngModel)]="RPDC.BankIFSC"
                               #refBankIFSC="ngModel"
                               placeholder="IFSC Code"
                               class="form-control input-text-css"
                               disabled
                               maxlength="11" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1" style="padding-right: 2px">
                    <span>MICR No</span>
                    <input type="text"
                           name="RBankMICRCode{{ i }}"
                           id="RBankMICRCode{{ i }}"
                           [(ngModel)]="RPDC.BankMICRCode"
                           #refBankMICRCode="ngModel"
                           placeholder="MICR No"
                           class="form-control input-text-css"
                           disabled
                           maxlength="9"
                           numbersOnly />
                  </div>
                  <div class="col-md-1 pt-3">
                    <button type="button"
                            class="remove"
                            (click)="removeRPDCDetail(i)"
                            *ngIf="i < RPDCModel.length && RPDCModel.length > 1">
                      -
                    </button>
                    <button type="button"
                            class="addmore"
                            (click)="addRPDCDetail()"
                            *ngIf="i == RPDCModel.length - 1">
                      <!-- [hidden]="CustomerBank.length == 1"-->
                      +
                    </button>
                  </div>

                  <div class="col-md-2 mt-2">
                    <span class="required-lable">Ch. No From</span>
                    <input type="text"
                           (change)="calculateNoOfCheque(i, RPDC, 'RPDC')"
                           [required]="RPDC.BankId != ''"
                           placeholder="Ch. No From"
                           minlength="6"
                           maxlength="6"
                           numbersOnly
                           name="RChFrom{{ i }}"
                           id="RChFrom{{ i }}"
                           class="form-control input-text-css"
                           [(ngModel)]="RPDC.ChFrom"
                           #refChFrom="ngModel"
                           [ngClass]="{
                        'is-invalid': rf.submitted && refChFrom.invalid,
                        'alert-warning':
                          refChFrom.invalid &&
                          (refChFrom.dirty ||
                            refChFrom.touched ||
                            refChFrom.untouched)
                      }" />
                  </div>

                  <div class="col-md-2 mt-2">
                    <span class="required-lable">Ch. No To</span>
                    <input type="text"
                           (change)="calculateNoOfCheque(i, RPDC, 'RPDC')"
                           [required]="RPDC.BankId != ''"
                           placeholder="Ch. No To"
                           minlength="6"
                           maxlength="6"
                           numbersOnly
                           name="RChTo{{ i }}"
                           id="RChTo{{ i }}"
                           class="form-control input-text-css"
                           [(ngModel)]="RPDC.ChTo"
                           #refChTo="ngModel"
                           [ngClass]="{
                        'is-invalid': rf.submitted && refChTo.invalid,
                        'alert-warning':
                          refChTo.invalid &&
                          (refChTo.dirty ||
                            refChTo.touched ||
                            refChTo.untouched)
                      }" />
                  </div>
                  <div class="col-md-2 mt-2">
                    <span class="required-lable">No of Chqs</span>
                    <input type="text"
                           name="RNoOfChqs{{ i }}"
                           id="RNoOfChqs{{ i }}"
                           disabled
                           placeholder="No of Chqs"
                           class="form-control input-text-css"
                           [(ngModel)]="RPDC.NoOfChqs" />
                  </div>

                  <div class="col-md-12"
                       [hidden]="Customer_Data.Customer_Type == 'G'">
                    <hr />
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="formborder mt-2" *ngIf="CustomerId != '' && CustomerBank.length > 0">
              <ng-container *ngIf="
                            NACHModel.length>
                0 && Customer_Data.Customer_Type != 'G'
                "
                >
                <div class="row m-0 mt-2">
                  <div class="col-md-12 p-1 bg-success1">
                    NACH Detail: &nbsp;
                    <span style="color: #ff0000">
                      <B>
                        ( Note : Only Saving and Current A/C Acceptable for
                        NACH !! )
                      </B>
                    </span>
                  </div>
                </div>
                <div class="row mt-2"
                     *ngFor="let NACH of NACHModel; let i = index">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-2">
                        <span class="required-lable">A/C No</span>
                        <select name="NAccountNo{{ i }}"
                                id="NAccountNo{{ i }}"
                                #refAccountNo="ngModel"
                                class="form-control input-text-css"
                                [(ngModel)]="NACH.BankId"
                                (change)="ChangeBankACNo(NACH, i, 'NACH')">
                          <option value="">Select A/C</option>
                          <ng-container *ngFor="let CuBank of CustomerBank">
                            <option [value]="CuBank.Id"
                                    *ngIf="
                                CuBank.BankAcType == 'CA' ||
                                CuBank.BankAcType == 'SB' ||
                                CuBank.BankAcType == ''
                              ">
                              {{ CuBank.AccountNo }}
                            </option>
                          </ng-container>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Beneficiary Name</span>
                        <textarea rows="1"
                                  name="NBeneficiaryName{{ i }}"
                                  id="NBeneficiaryName{{ i }}"
                                  [(ngModel)]="NACH.BeneficiaryName"
                                  #refBeneficiaryName="ngModel"
                                  placeholder="Beneficiary"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Name</span>
                        <textarea rows="1"
                                  name="NBankName{{ i }}"
                                  id="NBankName{{ i }}"
                                  [(ngModel)]="NACH.BankName"
                                  #refBankName="ngModel"
                                  placeholder="Bank Name"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Bank Branch</span>
                        <textarea rows="1"
                                  name="NBankBranch{{ i }}"
                                  id="NBankBranch{{ i }}"
                                  [(ngModel)]="NACH.BankBranch"
                                  #refBankBranch="ngModel"
                                  placeholder="Bank Branch"
                                  class="form-control input-text-css"
                                  disabled></textarea>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">A/C Type</span>
                        <select name="NBankAcType{{ i }}"
                                id="NBankAcType{{ i }}"
                                [(ngModel)]="NACH.BankAcType"
                                #refBankAcType="ngModel"
                                class="form-control input-text-css"
                                disabled>
                          <option value="">Select A/C Type</option>
                          <option value="SB">Saving A/C</option>
                          <option value="CC">Cash Credit A/C</option>
                          <option value="OD">Over Draft A/C</option>
                          <option value="CA">Current A/C</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">IFSC Code</span>
                        <input type="text"
                               name="NBankIFSC{{ i }}"
                               id="NBankIFSC{{ i }}"
                               [(ngModel)]="NACH.BankIFSC"
                               #refBankIFSC="ngModel"
                               placeholder="IFSC Code"
                               class="form-control input-text-css"
                               disabled
                               maxlength="11" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <!-- style="padding-right: 2px;"-->
                    <span>MICR No</span>
                    <input type="text"
                           name="NBankMICRCode{{ i }}"
                           id="NBankMICRCode{{ i }}"
                           [(ngModel)]="NACH.BankMICRCode"
                           #refBankMICRCode="ngModel"
                           placeholder="MICR No"
                           class="form-control input-text-css"
                           disabled
                           maxlength="9"
                           numbersOnly />
                  </div>
                  <!-- <div class="col-md-1 pt-3">
                    <button type="button" class="remove" (click)="removeNACHDetail(i)" *ngIf="i < NACHModel.length && NACHModel.length > 1">
                    -
                    </button>
                    <button type="button" class="addmore" (click)="addNACHDetail()" *ngIf="i==NACHModel.length-1" [hidden]="CustomerBank.length == 1">
                    +
                    </button>
                  </div> -->
                </div>
              </ng-container>
            </div>

            <div class="row m-0">
              <div class="col-md-12 p-0"><hr /></div>
            </div>
            <div class="row col-md-12 m-0 mt-1 justify-content-end">
              <button type="button"
                      (click)="onCloseBankModel()"
                      class="mt-3 btn font-size-12 button-btn mr-3"
                      data-dismiss="modal">
                Cancel
              </button>
              <button type="button"
                      (click)="onSaveBankChqDetail()"
                      class="mt-3 btn font-size-12 button-btn"
                      [class.spinner]="loading"
                      [disabled]="!rf.form.valid">
                SAVE
              </button>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="addCustomerBank"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Bank Details
        </h6>
        <button type="button"
                (click)="onCloseCustomerBankModel()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <form #ancf="ngForm" (ngSubmit)="onSaveCustomerBank()" novalidate>
          <div class="row m-0 mt-3">
            <div class="col-md-10 bg-success1">Bank Details:</div>
            <div class="col-md-2 bg-success1">
              <button type="button"
                      class="btn-warning"
                      *ngIf="Bank_Verification"
                      (click)="BankVerify()">
                Bank Add/Verify
              </button>
            </div>
          </div>
          <div class="row m-0 mt-1">
            <div class="col-md-12 p-0">
              Customer Name :
              <B>
                {{ Customer_Data ? Customer_Data.Customer : "" }}
                ({{
                  Customer_Data
                    ? Customer_Data.Customer_Type == "H"
                      ? "Borrower"
                      : Customer_Data.Customer_Type == "C"
                      ? "Co-Borrower"
                      : "Guarantor"
                    : ""
                }})
              </B>
            </div>
          </div>
          <div class="row mt-2"
               *ngFor="let CBank of BankDetails; let i = index">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-2">
                  <span class="required-lable">Beneficiary Name</span>
                  <textarea rows="1"
                            name="BeneficiaryName{{ i }}"
                            id="BeneficiaryName{{ i }}"
                            [(ngModel)]="CBank.BeneficiaryName"
                            #refBeneficiaryName="ngModel"
                            placeholder="Beneficiary"
                            class="form-control input-text-css"
                            required
                            [disabled]="CBank.IsEdit == 0"
                            [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refBeneficiaryName.invalid,
                      'alert-warning':
                        refBeneficiaryName.invalid &&
                        (refBeneficiaryName.dirty ||
                          refBeneficiaryName.touched ||
                          refBeneficiaryName.untouched)
                    }"></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">A/C No</span>
                  <textarea rows="1"
                            name="AccountNo{{ i }}"
                            id="AccountNo{{ i }}"
                            [(ngModel)]="CBank.AccountNo"
                            #refAccountNo="ngModel"
                            placeholder="A/C No"
                            class="form-control input-text-css"
                            (change)="ChangeACNo(CBank, i)"
                            required
                            [disabled]="CBank.IsEdit == 0"
                            [ngClass]="{
                      'is-invalid': ancf.submitted && refAccountNo.invalid,
                      'alert-warning':
                        refAccountNo.invalid &&
                        (refAccountNo.dirty ||
                          refAccountNo.touched ||
                          refAccountNo.untouched)
                    }"></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">Bank Name</span>
                  <textarea rows="1"
                            name="BankName{{ i }}"
                            id="BankName{{ i }}"
                            [(ngModel)]="CBank.BankName"
                            #refBankName="ngModel"
                            placeholder="Bank Name"
                            class="form-control input-text-css"
                            required
                            [disabled]="CBank.IsEdit == 0"
                            [ngClass]="{
                      'is-invalid': ancf.submitted && refBankName.invalid,
                      'alert-warning':
                        refBankName.invalid &&
                        (refBankName.dirty ||
                          refBankName.touched ||
                          refBankName.untouched)
                    }"></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">Bank Branch</span>
                  <textarea rows="1"
                            name="BankBranch{{ i }}"
                            id="BankBranch{{ i }}"
                            [(ngModel)]="CBank.BankBranch"
                            #refBankBranch="ngModel"
                            placeholder="Bank Branch"
                            class="form-control input-text-css"
                            required
                            [disabled]="CBank.IsEdit == 0"
                            [ngClass]="{
                      'is-invalid': ancf.submitted && refBankBranch.invalid,
                      'alert-warning':
                        refBankBranch.invalid &&
                        (refBankBranch.dirty ||
                          refBankBranch.touched ||
                          refBankBranch.untouched)
                    }"></textarea>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">A/C Type</span>
                  <select name="BankAcType{{ i }}"
                          id="BankAcType{{ i }}"
                          [(ngModel)]="CBank.BankAcType"
                          #refBankAcType="ngModel"
                          class="form-control input-text-css"
                          required
                          [disabled]="CBank.IsEdit == 0"
                          [ngClass]="{
                      'is-invalid': ancf.submitted && refBankAcType.invalid,
                      'alert-warning':
                        refBankAcType.invalid &&
                        (refBankAcType.dirty ||
                          refBankAcType.touched ||
                          refBankAcType.untouched)
                    }">
                    <option value="">Select A/C Type</option>
                    <option value="SB">Saving A/C</option>
                    <option value="CC">Cash Credit A/C</option>
                    <option value="OD">Over Draft A/C</option>
                    <option value="CA">Current A/C</option>
                  </select>
                </div>

                <div class="col-md-2">
                  <span class="required-lable">IFSC Code</span>
                  <input type="text"
                         name="BankIFSC{{ i }}"
                         id="BankIFSC{{ i }}"
                         [(ngModel)]="CBank.BankIFSC"
                         #refBankIFSC="ngModel"
                         placeholder="IFSC Code"
                         class="form-control input-text-css"
                         required
                         [disabled]="CBank.IsEdit == 0"
                         maxlength="11"
                         [ngClass]="{
                      'is-invalid': ancf.submitted && refBankIFSC.invalid,
                      'alert-warning':
                        refBankIFSC.invalid &&
                        (refBankIFSC.dirty ||
                          refBankIFSC.touched ||
                          refBankIFSC.untouched)
                    }" />
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <span>MICR Code</span>
              <input type="text"
                     name="BankMICRCode{{ i }}"
                     id="BankMICRCode{{ i }}"
                     [(ngModel)]="CBank.BankMICRCode"
                     #refBankMICRCode="ngModel"
                     placeholder="MICR Code"
                     class="form-control input-text-css"
                     [disabled]="CBank.IsEdit == 0"
                     maxlength="9"
                     numbersOnly />
            </div>
            <div class="col-md-1 pt-3">
              <i *ngIf="CBank.IsVerified == 1"
                 class="fa fa-check-circle"
                 style="color: green; font-size: 19px; margin-right: 2px"></i>
              <button type="button"
                      class="remove"
                      (click)="removeBankDetail1(i)"
                      *ngIf="
                      (i < BankDetails.length && BankDetails.length > 1) ||
                  (CBank.IsEdit == 1 && BankDetails.length > 0)
                "
              >
                -
              </button>
              <button type="button"
                      class="addmore"
                      (click)="addBankDetail1()"
                      *ngIf="i == BankDetails.length - 1 && !Bank_Verification">
                +
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button type="button"
                    (click)="onCloseCustomerBankModel()"
                    class="mt-3 btn font-size-12 button-btn"
                    data-dismiss="modal">
              Cancel
            </button>
            <button type="button"
                    (click)="onSaveCustomerBank()"
                    class="ml-4 mt-3 btn font-size-12 button-btn"
                    *ngIf="BankDetails.length > 0"
                     [disabled]="!ancf.form.valid "
                    [class.spinner]="loading">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="BankDetailModelVerified"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Bank Verification
        </h6>
        <button type="button"
                (click)="onCloseBankverifyModel()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #blf="ngForm"
              *ngIf="BankForm"
              novalidate
              class="col-md-12 row m-0 p-0">
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Account Number</span>
              <input type="text"
                     #refVerifyAccountNo="ngModel"
                     [(ngModel)]="VerifyAccountNo"
                     placeholder="Enter Account Number "
                     required
                     [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyAccountNo.invalid,
                  'alert-warning':
                    refVerifyAccountNo.invalid &&
                    (refVerifyAccountNo.dirty ||
                      refVerifyAccountNo.touched ||
                      refVerifyAccountNo.untouched)
                }"
                     name="VerifyAccountNo"
                     id="VerifyAccountNo"
                     class="form-control input-text-css" />
            </div>
            <div class="col-md-12">
              <span class="required-lable">IFSC Code</span>
              <input type="text"
                     #refVerifyIFSC="ngModel"
                     [(ngModel)]="VerifyIFSC"
                     placeholder="Enter IFSC "
                     required
                     [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyIFSC.invalid,
                  'alert-warning':
                    refVerifyIFSC.invalid &&
                    (refVerifyIFSC.dirty ||
                      refVerifyIFSC.touched ||
                      refVerifyIFSC.untouched)
                }"
                     name="VerifyIFSC"
                     id="VerifyIFSC"
                     class="form-control input-text-css" />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button type="button"
                    (click)="onVerifiedBankDetail()"
                    class="ml-4 mt-3 btn font-size-12 button-btn"
                    [class.spinner]="loading"
                    [disabled]="!blf.form.valid">
              Verify
            </button>
          </div>
        </form>

        <form #bbff="ngForm"
              *ngIf="!BankForm"
              novalidate
              class="col-md-12 row m-0 p-0 formborder">
          <div class="card-body"
               *ngIf="!BankForm"
               [ngClass]="{ 'pending-danger': APIResponse.IsVerified == false }">
            <div class="text-center" style="font-weight: bolder">
              {{ APIResponse.DoucumentNumber }}
            </div>
            <h6 class="card-title">Remark:</h6>
            <p class="card-text">{{ APIResponse.Remark }}</p>
            <br />

            <h6 class="card-title">contact:</h6>
            <p class="card-text">{{ APIResponse.contact }}</p>
            <br />
            <h6 class="card-title">account_exists:</h6>
            <p class="card-text">{{ APIResponse.account_exists }}</p>
            <br />
            <h6 class="card-title">address:</h6>
            <p class="card-text">{{ APIResponse.address }}</p>
            <br />
            <h6 class="card-title">Name:</h6>

            <p class="card-text"
               *ngIf="
                APIResponseData.Full_name != '' &&
                APIResponseData.Full_name != undefined &&
                APIResponseData.Full_name != null
              ">
              {{ APIResponse.Full_name }}
            </p>
            <p class="card-text"
               *ngIf="
                !(
                  APIResponseData.Full_name != '' &&
                  APIResponseData.Full_name != undefined &&
                  APIResponseData.Full_name != null
                )
              ">
              <input type="text"
                     name="BankResname"
                     id="BankResname"
                     [(ngModel)]="APIResponse.Full_name"
                     #refBankResFullname="ngModel"
                     placeholder="Full Name"
                     class="form-control input-text-css"
                     required
                     [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResFullname.invalid,
                  'alert-warning':
                    refBankResFullname.invalid &&
                    (refBankResFullname.dirty ||
                      refBankResFullname.touched ||
                      refBankResFullname.untouched)
                }" />
            </p>

            <br />

            <h6 class="card-title">Bank Name:</h6>
            <p class="card-text"
               *ngIf="
                APIResponseData.bank_name != '' &&
                APIResponseData.bank_name != undefined &&
                APIResponseData.bank_name != null
              ">
              {{ APIResponse.bank_name }}
            </p>

            <p class="card-text"
               *ngIf="
                !(
                  APIResponseData.bank_name != '' &&
                  APIResponseData.bank_name != undefined &&
                  APIResponseData.bank_name != null
                )
              ">
              <input type="text"
                     name="BankResbankname"
                     id="BankResbankname"
                     [(ngModel)]="APIResponse.bank_name"
                     #refBankResbank="ngModel"
                     placeholder="Bank Name"
                     class="form-control input-text-css"
                     required
                     [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResbank.invalid,
                  'alert-warning':
                    refBankResbank.invalid &&
                    (refBankResbank.dirty ||
                      refBankResbank.touched ||
                      refBankResbank.untouched)
                }" />
            </p>
            <br />
            <h6 class="card-title">branch:</h6>
            <p class="card-text"
               *ngIf="
                APIResponseData.branch != '' &&
                APIResponseData.branch != undefined &&
                APIResponseData.branch != null
              ">
              {{ APIResponse.branch }}
            </p>

            <p class="card-text"
               *ngIf="
                !(
                  APIResponseData.branch != '' &&
                  APIResponseData.branch != undefined &&
                  APIResponseData.branch != null
                )
              ">
              <input type="text"
                     name="BankResbranch"
                     id="BankResbranch"
                     [(ngModel)]="APIResponse.branch"
                     #refBankResbranch="ngModel"
                     placeholder="Bank Branch"
                     class="form-control input-text-css"
                     required
                     [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResbranch.invalid,
                  'alert-warning':
                    refBankResbranch.invalid &&
                    (refBankResbranch.dirty ||
                      refBankResbranch.touched ||
                      refBankResbranch.untouched)
                }" />
            </p>
            <br />

            <h6 class="card-title">micr:</h6>
            <p class="card-text"
               *ngIf="
                APIResponseData.micr != '' &&
                APIResponseData.branch != micr &&
                APIResponseData.micr != null
              ">
              {{ APIResponse.micr }}
            </p>

            <p class="card-text"
               *ngIf="
                !(
                  APIResponseData.micr != '' &&
                  APIResponseData.branch != micr &&
                  APIResponseData.micr != null
                )
              ">
              <input type="text"
                     name="micr"
                     id="micr"
                     [(ngModel)]="APIResponse.micr"
                     placeholder="micr"
                     class="form-control input-text-css" />
            </p>
            <br />
            <h6 class="card-title">ifsc:</h6>
            <p class="card-text"
               *ngIf="
                APIResponseData.ifsc != '' &&
                APIResponseData.ifsc != undefined &&
                APIResponseData.ifsc != null
              ">
              {{ APIResponse.ifsc }}
            </p>

            <p class="card-text"
               *ngIf="
                !(
                  APIResponseData.ifsc != '' &&
                  APIResponseData.ifsc != undefined &&
                  APIResponseData.ifsc != null
                )
              ">
              <input type="text"
                     name="BankResIFSC"
                     id="BankResIFSC"
                     [(ngModel)]="APIResponse.ifsc"
                     #refResBankIFSC="ngModel"
                     placeholder="IFSC Code"
                     class="form-control input-text-css"
                     required
                     maxlength="11"
                     [ngClass]="{
                  'is-invalid': bbff.submitted && refResBankIFSC.invalid,
                  'alert-warning':
                    refResBankIFSC.invalid &&
                    (refResBankIFSC.dirty ||
                      refResBankIFSC.touched ||
                      refResBankIFSC.untouched)
                }" />
            </p>
            <br />
            <h6 class="card-title">rtgs:</h6>
            <p class="card-text">{{ APIResponse.rtgs }}</p>

            <br />
            <h6 class="card-title">neft:</h6>
            <p class="card-text">{{ APIResponse.neft }}</p>

            <br />
            <h6 class="card-title">imps:</h6>
            <p class="card-text">{{ APIResponse.imps }}</p>

            <br />
            <h6 class="card-title">message:</h6>
            <p class="card-text">{{ APIResponse.message }}</p>

            <br />
            <div class="row col-md-12 m-0 mt-1 justify-content-end">
              <button type="button"
                      (click)="AddBankDetailApi()"
                      class="ml-4 mt-3 btn font-size-12 button-btn"
                      [disabled]="!bbff.form.valid"
                      [class.spinner]="loading">
                Bank Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
