<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-2">
    <div class="col-md-12 p-1">
        <div class="table-responsive">
            <mat-table [dataSource]="dataSource" matSort matTableExporter id="exportReport" 
                style="height: 290px; max-width: 100%; overflow: auto;">
                <ng-container matColumnDef="Id">
                    <mat-header-cell class="grid-header" *matHeaderCellDef style="max-width:50px">#</mat-header-cell>
                    <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width:50px">
                        {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Sent_By">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Sent By</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Sent_By}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Sent_On">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Sent On</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Sent_On}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Dispatched_Mode">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Dispatched Mode</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{row.Dispatched_Mode}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Dispached_Date">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Dispached Date</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{row.Dispached_Date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Dispached_Remark">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Dispached Remark</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Dispached_Remark}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Tracking_No">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Tracking No</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Tracking_No}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Agency">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Agency</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Agency}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CourierCompanyName">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Courier Company</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CourierCompanyName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Dispatched_Detail">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Dispached Detail</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Dispatched_Detail}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Notice_Data">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>Notice Data</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell"> 
                        <i class="fa fa-eye" (click)="ViewPDF(row)" style="font-size: large;cursor: pointer;" title="View Notice"></i>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
        </div>
    </div>
</div>