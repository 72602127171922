import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from '../../_Lms/services/lms.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ToWords } from "to-words";
import { DialogService } from "../../Shared/dialog.service";
import { QuickLoanService } from '../services/quickLoan.service';


@Component({
  selector: 'app-quickloan-documents',
  templateUrl: './quickloan-documents.component.html',
  styleUrls: ['./quickloan-documents.component.scss']
})
export class QuickloanDocumentsComponent implements OnInit {
  SelectCustomer: any;
  Bankname1: any;
  currentUser: any
  showreceiptForm: boolean = false;
  loginFeeModel: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ApplicationId; data: any;
  CustomerId: any;
  ApplicationDetail: any;
  inViewMode: boolean = false;
  customerDataSource: any;
  BankDetailShow: boolean = false;
  displayedCustomerSearchColumns: string[] = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];
  displayedCustomerColumns: string[] = [
    "CustomerId",
    "Customer",
    "CustomerType",
    "GenderAge",
    "ExistingCustomer",
    // "Customer_IsFirm",
    "Relation_With_Hirer",
    "PhoneNo",
    "Present_Address",
    "Action",
  ];//, 'Permanent_Address', 'Work_Address', 'AadharCardNo', 'PenCardNo', 'DLNo', 'ElectricityBillNo', 'PassportNo', 'VoterIDNo'];
  processDataSource: any;
  displayedProcessColumns: string[] = [
    "ProcessId",
    "Process_Name",
    "Executive",
    "Status",
    "TAT_Hr",
    "AssignedOn",
    "CompletedOn",
    "Recommendation",
    "Remark",
  ];
  docDataSource: any;
  odocDataSource: any;
  displayedDocolumns: string[] = [
    "UDId",
    "Category",
    "Type",
    "DocType",
    "DocTitle",
    "UploadBy",
    "UploadedOn",
    "IsReceipt",
    "No_Of_Pages",
    "View",
  ];
  crcDataSource: any;
  displayedCRColumns: string[] = [
    "CustomerId",
    "Customer",
    "CRC_Agency",
    "CreditVisionScore",
    "PersonalLoanScore",
    "OverdueAccounts",
    "OverdueAmount",
    "TotalAccounts",
    "TotalEnquiry",
    "CRC_Date",
    "CRC_Report",
  ];
  @Input() Revert_IsEdit: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  loading: boolean = false;
  FileProcessingModel: any = {};
  BankAndEmiData: any;
  PdcDetailData: any;
  dayList: any[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  tab: any;
  pdcDataSource: any;
  displayedPdcColumns: string[] = [
    "PDCID",
    "BankName",
    "BankBranch",
    "IFSCCode",
    "ChqNo",
    "ChqAmount",
    "AccountNo",
    "AccountHolderName",
    "Action",
  ];
  rdDataSource: any;
  displayedRdcColumns: string[] = [
    "ApplicationChargesId",
    "ChagesHead",
    "CollectedAmount",
    "CollectionBy",
    "CollectionOn",
    "CollectionType",
  ];
  displayedRefernceColumns: string[] = [
    "Id",
    "Reference",
    "PersonName",
    "PersonAddress",
    "ContactNo",
    "Ref_NoYear",
    "Remark",
    "Ref_AddOn",
  ];
  rdDetailData: any;

  ReceiptAndDeductionList: any = [];
  Voucher_Detail: any = { TaxSlab: [] };
  totalCalculator: any;
  customerdataSource: any;
  customerdisplayedColumns: string[] = ["LoanId",
    "LoanNo",
    "Product_Name",
    "Customer",
    "PhoneNo",
    "Branch_Name",
    "Action"];

  chargesDropdown: any[] = [];
  chargesDropdownD: any[] = [];
  docDropDown: any[] = [];
  docModal: any;
  pdcNew: boolean = false;
  isNach: boolean = false;
  today: Date = new Date();
  customerlist: any[] = [];
  totalCheckCount: any = 0;
  docModalDataSource: any;
  displayedDocModalColumns: string[] = [
    "UDId",
    "Category",
    "Type",
    "DocType",
    "DocTitle",
    "IsReceipt",
    "No_Of_Pages",
    "View",
  ];
  insuranceModel: any = {};
  assetDropdown: any[] = [];
  customerDropdown: any[] = [];
  assetPolicyDropDown: any[] = [];
  customerPolicyDropDown: any[] = [];
  relationDropDown: any[] = [];
  companyDropDown: any[] = [];
  companyBranchDropDown: any[] = [];
  customerList: any[] = [];
  insuranceDataSource: any;
  displayedInsuranceColumns: string[] = [
    "InsId",
    "Type",
    "Name",
    "CompanyName",
    "IDV_Assured_Amount",
    "Insurance_Date",
    "Insurance_Renewal_Date",
    "Action",
  ];
  accountTagDropdown: any[] = [];
  CustomerDropdown: any[] = [];
  CustomerAssetsDropdown: any[] = [];
  IsKYC: boolean = false;
  IsSPDC: boolean = true;
  IsNACH: boolean = true;
  IsRPDC: boolean = true;
  IsBankDetail: boolean = true;
  PdcRpdcForm: UntypedFormGroup;
  RpdcForm: UntypedFormGroup;
  SecurityPdcForm: UntypedFormGroup;
  ECSForm: UntypedFormGroup;
  NACHForm: UntypedFormGroup;
  IsUpdateButton: boolean = false;
  IsSaveButton: boolean = true;
  items: UntypedFormArray;
  RpdcItems: UntypedFormArray;
  nachItems: UntypedFormArray;
  Int_Id: any;
  dataCSource: any;
  dataRefSource: any;
  dataSourceCustomer: any;
  Customer: any = {};
  CustomerList: any[] = [];
  Type: any = "0";
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('dataCSourcePaginator', { static: true }) dataCSourcePaginator: MatPaginator;
  displayedCheckDetailColumns: string[] = ['Int_Id', 'Pdc Type', 'Customer Name', 'Bank Name', 'Branch', 'Acc Type', 'A/C No', 'ChFrom', 'ChTo', 'No of Chqs', 'IFSC Code', 'Action'];
  originalDoc: any[] = [];
  showPendingDoc: boolean = false;

  Customer_Data: any;
  BankModel: any = [];
  CustomerBank: any = [];
  SPDCModel: any = [];
  RPDCModel: any = [];
  NACHModel: any = [];
  BankDetails: any[] = [];
  PDCType: any;
  Nach_Detail: any;

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() Status: any;
  @Input() ProcessName: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private router: Router,
    private formBuilder: UntypedFormBuilder, 
    private dialog: DialogService,
    private losService: LosService, 
    private masterService: MasterService, 
    private lmsService: LmsService,
    private _QuickLoanService: QuickLoanService,
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      //this.ApplicationId = decodeURIComponent(param.get("id"));// encdec.decrypt(param.get("id"));
      this.ApplicationId = this.encdec.decrypt(decodeURIComponent(param.get("id")));
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.LOS_GetApplicationDetailsForFileProcessing();
    this.checkPendingDoc();
  }

  LOS_GetApplicationDetailsForFileProcessing() {
    //this.showSpinner = true;
    console.log("this.Status", this.Status);
    //this.ApplicationId = this.loanSummary.LoanId;
    this.losService.LOS_GetApplicationDetailsForFileProcessing({ ApplicationId: this.ApplicationId }).subscribe((res: any) => {
      console.log("LOS_GetApplicationDetailsForFileProcessing",res);
      if(res.Item5.length > 0){
        this.showSpinner = false;
        this.ApplicationDetail = res.Item1[0];
        this.customerlist = res.Item2;
        this.customerDataSource = new MatTableDataSource(res.Item2);
        this.crcDataSource = new MatTableDataSource(res.Item3);
        this.processDataSource = new MatTableDataSource(res.Item4);
        
        this.docDataSource = new MatTableDataSource(res.Item5);
       

        this.dataCSource = new MatTableDataSource(res.Item7);
        this.dataCSource.paginator = this.dataCSourcePaginator;
        this.dataRefSource = new MatTableDataSource(res.Item1);

        if (res.Item5 && res.Item5.length > 0) {
          let _doc: any[] = [];
          res.Item5.forEach((obj: any) => {
            if (obj.Type == 'Orignal') {
              _doc.push(obj);
              this.originalDoc.push(obj);
            }
          });
          this.odocDataSource = new MatTableDataSource(_doc);
        }
        this.insuranceDataSource = new MatTableDataSource(res.Item6);
        this.inViewMode = false; //this.ApplicationDetail.FileProcessing_Status == "Completed" || this.ApplicationDetail.FileProcessing_Status == "Rejected";
        //this.FileProcessingModel.Recommendation = this.ApplicationDetail.FileProcessing_Recommendation || "";
        //this.FileProcessingModel.Remarks = this.ApplicationDetail.FileProcessing_Remarks;
        this.FileProcessingModel.ApplicationId = this.ApplicationId;
        this.FileProcessingModel.LoginUserId = this.currentUser.userId;

        this.PDCType = res.Item7.filter(item => item.PdcType == "Disbursment")[0];
        this.Nach_Detail = res.Item7.filter(item => item.PdcType == "NACH")[0];
        // this.getChequeDetails();
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      }
    });
    this.QuickLoan_Save_PageProcess('Pending Document');
  }


 
  fileChangeListener(files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      this.PdcDetailData.DocModel = {};
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Please upload correct file`, ...this.configSuccess });
      files.value = "";
      this.PdcDetailData.DocModel = {};
      return;
    }
    
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    if (this.tab == "list") {
      this.PdcDetailData.New.ChqImage = _pre + "_" + files[0].name;
      this.PdcDetailData.DocModel.DocName = _pre + "_" + files[0].name;
      this.PdcDetailData.DocModel.ApplicationNo = this.loanSummary.ApplicationNo;
    } else {
      this.PdcDetailData.Nach.NACHImage = _pre + "_" + files[0].name;
      this.PdcDetailData.DocModel.DocName = _pre + "_" + files[0].name;
      this.PdcDetailData.DocModel.ApplicationNo = this.loanSummary.ApplicationNo;
    }

    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.PdcDetailData.DocModel.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(input: any) {
    input.value = "";
    if (this.tab == "list") {
      this.PdcDetailData.New.ChqImage = "";
    } else {
      this.PdcDetailData.Nach.NACHImage = "";
    }
    this.PdcDetailData.DocModel = {};
  }

  ChangeChargesType() {
    this.rdDetailData.New.ChargeHeadId = "";
    this.rdDetailData.New.Amount = "";
    this.loginFeeModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Receipt',
      Voucher_Sub_Type: 'Loan',
      Voucher_Tag: 'Bank',
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "Receipt",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId,
      Is_LOSAppliaction: 1,
      TaxSlab: []
    };
    this.showreceiptForm = false;
    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherTagChange() {
    this.loginFeeModel.Voucher.Voucher_Mode_of_Payment = "";
    this.loginFeeModel.Voucher.Voucher_E_Ref_No = "";
    this.loginFeeModel.Voucher.Voucher_E_Transaction_Date = "";
    this.loginFeeModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.loginFeeModel.Voucher.Voucher_Bank_Name = "";
    this.loginFeeModel.Voucher.Voucher_Cheque_Date = "";
    this.loginFeeModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  onChangeMode() {
    if (this.loginFeeModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
      this.loginFeeModel.Voucher.Voucher_Cheque_No = "";
      this.loginFeeModel.Voucher.Voucher_Cheque_Date = "";
      this.loginFeeModel.Voucher.Voucher_Bank_Name = "";
      this.loginFeeModel.Voucher.Voucher_Cheque_Clear_Date = "";
    }
    else {
      this.loginFeeModel.Voucher.Voucher_E_Transaction_Date = "";
      this.loginFeeModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  Get_Acc_Accounts_By_Tag() {
    this.loginFeeModel.Voucher.DRAccountId = "";
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.data.userId, Account_Tag: this.loginFeeModel.Voucher.Voucher_Tag, Voucher_Date: this.loginFeeModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }

  LOS_GetApplicationProductChargesHeadsForDropdownD() {
    this.losService
      .LOS_GetApplicationProductChargesHeadsForDropdown({
        ApplicationId: this.ApplicationId,
      })
      .subscribe((res: any) => {
        this.chargesDropdownD = res;
      });
  }
  LOS_GetChargesHeadsAmountAutoFill() {
    this.rdDetailData.New.Amount = "";
    if (this.rdDetailData.New.ChargeHeadId != "") {
      if (this.rdDetailData.New.ChargeReceiptDeduction == 'Deduction') {
        this.losService
          .LOS_GetChargesHeadsAmountAutoFill({
            ApplicationId: this.ApplicationId,
            ChargeHeadId: this.rdDetailData.New.ChargeHeadId,
          })
          .subscribe((res: any) => {
            if (res && res[0]) {
              this.rdDetailData.New.Amount = res[0].ChargeToBeTaken;
              this.loginFeeModel = { ApplicationId: this.ApplicationId, TaxPercentage: res[0].SGST_Per + res[0].CGST_Per, ChargeToBeTaken: res[0].ChargeToBeTaken, CGST_Per: res[0].CGST_Per, SGST_Per: res[0].SGST_Per, TaxAmount: '', SGST_Amount: '', CGST_Amount: '', LoginUserId: this.currentUser.userId, CollectionMode: "" };
              this.calculateTax();
              this.showreceiptForm = true;
            }
          });
      }
      else {
        this.loginFeeModel.Voucher.TaxSlab = [];
        this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: this.rdDetailData.New.ChargeHeadId, VoucherSubType: this.loginFeeModel.Voucher.Voucher_Sub_Type, ProductId: this.ApplicationDetail.ProductId, TaxType: 'CR' }).subscribe((res: any) => {
          if (res.Item1[0]) {
            this.loginFeeModel.Voucher.AccountId = res.Item1[0].AccountId;
            this.loginFeeModel.Voucher.ChargeHeadCategory = res.Item1[0].AccountName;
            this.loginFeeModel.Voucher.TaxSlab = res.Item2;
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
          }
        });
      }
    }
  }

  changePDCTab(tab: any) {
    this.tab = tab;
  }
  getCustomerId() {
    let _find = this.customerlist.find((obj: any) => { return obj.CustomerType.toLowerCase() == 'hirer' });
    if (_find) {
      return _find.CustomerId;
    }
    return 0;
  }
  
  onDocOpen() {
    this.showSpinner = true;
    this.losService
      .LOS_GetAppliactionReqDocForDropDown({
        ApplicationId: this.ApplicationId,
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.docDropDown = res;
        this.docModal = {
          ApplicationId: this.loanSummary.LoanId, //this.ApplicationDetail.ApplicationIdentity,
          LoginUserId: this.currentUser.userId,
          DocId: "0-0",
          AssetName: "",
          No_Of_Pages: 1,
          IsReceipt: false,
          IsOrignalDoc: "1",
          IsDocument: "1",
          DocumentImages: [],
          Type: 'Loan',
          ApplicationNo: this.loanSummary.ApplicationNo
        };
        this.LOSAssetInfoDropDown();

        $("#docModal").modal("show");
        $("#docModal").css("z-index", "1050");
      });
  }
  OnDocClose() {
    $("#docModal").modal("hide");
  }
  fileChangeListenerD(files: any, input: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      input.value = "";
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      input.value = "";
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let _docImg: any = {};
      let _pre = this.today.getTime();
      _docImg.DocFileName = _pre + "_" + files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  onSaveDoc() {
    let _data = { ...this.docModal };
    if (+this.docModal.No_Of_Pages <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No Of Pages required and should be grater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.docModal.DocumentImages.length) {
      this.loading = true;
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        if (this.IsKYC) {
          this.losService
            .UploadCustomerDoc({
              DocName: this.docModal.DocumentImages[i].DocFileName,
              CustomerID: this.docModal.Name.split('-')[1],
              DocData: this.docModal.DocumentImages[i].DocData,
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
        } else {
          this.losService
            .UploadCrcDoc({
              DocName: this.docModal.DocumentImages[i].DocFileName,
              ApplicationNo: this.docModal.ApplicationNo,
              DocData: this.docModal.DocumentImages[i].DocData,
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
        }
        if (_err) {
          break;
        }
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload doc file.",
        ...this.configSuccess,
      });
    }
  }
  saveDoc() {
    if (this.IsKYC) {
      let docImage = ''
      if (this.docModal.DocumentImages.length > 1) {
        docImage = this.docModal.DocumentImages[1].DocFileName;

      }
      this.losService
        .LOS_SaveCustomerKYCDocApp({ CustomerId: this.docModal.Name.split('-')[1], KYC_DocId: this.docModal.DocId.split('-')[0], KYC_DocNumber: this.docModal.DocTitle, KYC_DocFile: this.docModal.DocumentImages[0].DocFileName, KYC_DocFile1: docImage, LoginUserId: this.currentUser.userId, KYC_IsVerified: 0 })
        .subscribe((res: any) => {
          this.loading = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            //this.LOS_GetApplicationDetailsForFileProcessing();
            this.ngOnInit();
            this.OnDocClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });

    }
    else {
      var IsOrignalDoc;
      if (this.docModal.IsOrignalDoc == "1")
        IsOrignalDoc = 0;
      else
        IsOrignalDoc = 1;

      if (this.docModal.DocUId == undefined)
        this.docModal.DocUId = 0;
      if (this.docModal.Remark == undefined)
        this.docModal.Remark = "";
      let _data = {
        Document: {
          ApplicationId: this.docModal.ApplicationId,
          LoginUserId: this.docModal.LoginUserId,
          DocTitle: this.docModal.DocTitle,
          Remark: this.docModal.Remark,
          DocId: this.docModal.DocId.split('-')[0],
          AssetName: this.docModal.AssetName,
          No_Of_Pages: this.docModal.No_Of_Pages,
          IsReceipt: this.docModal.IsReceipt,
          IsOrignalDoc: IsOrignalDoc,
          DocUId: this.docModal.DocUId
        },
        DocumentImages: []
      };
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        _data.DocumentImages.push({ DocImage: this.docModal.DocumentImages[i].DocFileName });
      }
      this.losService.LOS_SaveAppliactionDocumentJson({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
          this.LOS_GetApplicationDetailsForFileProcessing();
          this.OnDocClose();
          this.OnCloseDocModal();
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        }
      });
    }
  }
  goToDetail(data: any) {
    // var appid = this.encdec.encrypt(this.ApplicationId);
    //var cid = this.encdec.encrypt(data.CustomerId);
    window.open(
      "#/LOS/customer/view/" +
      encodeURIComponent(this.ApplicationId) +
      "/" +
      encodeURIComponent(data.CustomerId),
      "_blank"
    );
  }
  calculateTax() {
    if (this.rdDetailData.New.ChargeReceiptDeduction == 'Deduction') {
      if (this.loginFeeModel) {
        let cgta = (+this.rdDetailData.New.Amount) * this.loginFeeModel.CGST_Per / 100;
        let sgta = (+this.rdDetailData.New.Amount) * this.loginFeeModel.SGST_Per / 100;
        this.loginFeeModel.TaxAmount = cgta + sgta;
        this.loginFeeModel.SGST_Amount = sgta;
        this.loginFeeModel.CGST_Amount = cgta;
        this.loginFeeModel.Amount = (+this.rdDetailData.New.Amount) + sgta + cgta;
      }
    }
    else {
      // this.onChangeAmount();
    }

  }
  ViewDoc(data: any) {
    this.loading = true;
    this.losService.LOS_GetApplicationDocAttachment({ UDId: data.UDId }).subscribe((res: any) => {
      console.log("res", res);
      
      this.loading = false;
      this.docModalDataSource = new MatTableDataSource(res);
      $("#docdetailModel").modal("show");
      $("#docdetailModel").css("z-index", "1050");
    });
  }
  OnDocDetailClose() {
    $("#docdetailModel").modal("hide");
  }
  OnInsuranceClose() {
    $("#insuranceModel").modal("hide");
  }
  onInsuranceOpen() {
    this.insuranceModel = {};
    this.insuranceModel.ApplicationId = this.loanSummary.LoanId;
    this.insuranceModel.LoginUser = this.currentUser.userId;
    this.insuranceModel.Insurance_Type = "Asset";
    this.insuranceModel.Customer_Assets_Name = "";
    this.insuranceModel.Insurance_Company = "";
    this.insuranceModel.Insurance_Company_Branch = "";
    this.insuranceModel.Insurance_Policy_Type = "";
    this.insuranceModel.Insurance_Nominee_Relation = "";
    this.insuranceModel.Insurance_Premium_IsDeducted = "";
    this.GetCommonMaster_FOR_DROPDOWN('RELATION');
    this.GetCommonMaster_FOR_DROPDOWN('ASSETS POLICY TYPE');
    this.GetCommonMaster_FOR_DROPDOWN('CUSTOMER POLICY TYPE');
    this.GetInsuranceCompanyMaster_FOR_DROPDOWN();
    this.LOS_GetApplicatonCustomer();
    $("#insuranceModel").modal("show");
    $("#insuranceModel").css("z-index", "1050");
    this.LOSAssetInfoDropDown();

  }
  saveInsurance() {
    this.loading = true;
    if (this.insuranceModel.Insurance_Type == 'Asset') {
      let _find = this.assetDropdown.find((obj: any) => { return obj.ForInsurance == this.insuranceModel.Customer_Assets_Name; })
      this.insuranceModel.Insurance_AssetType = _find.AssetType;
      this.insuranceModel.Insurance_AssetId = _find.AssetId;
    }
    else {
      let _find = this.customerList.find((obj: any) => { return obj.Customer == this.insuranceModel.Customer_Assets_Name; })
      this.insuranceModel.Insurance_AssetType = "Customer";
      this.insuranceModel.Insurance_AssetId = _find.CustomerId;
    }
    this.losService.LOS_SaveAssetsInsurance({ JSON: JSON.stringify({ Insurance: this.insuranceModel }) }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LOS_GetApplicationDetailsForFileProcessing();
        this.OnInsuranceClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  getCustomerOrAsset() {
    this.insuranceModel.Customer_Assets_Name = "";
    this.insuranceModel.Insurance_IDV_Amount = ""
  }
  LOSAssetInfoDropDown() {
    //this.losService.LOSAssetInfoDropDown({ ApplicationId: this.ApplicationId }).subscribe((result: any) => {
    //  this.assetDropdown = result;
    //});
    if (this.insuranceModel.Insurance_Type == 'Asset' || (this.docModal != undefined && this.docModal != null && this.docModal.Type == 'Assets')) {
      this.losService.LOSAssetInfoDropDown({ ApplicationId: this.ApplicationId }).subscribe((result: any) => {
        this.assetDropdown = result;
      });
    }
    if (this.insuranceModel.Insurance_Type == 'Customer' || (this.docModal != undefined && this.docModal != null && this.docModal.Type == 'Customer')) {
      this.losService.GetCustomerByApplication({ ApplicationId: this.ApplicationId }).subscribe((result) => {
        this.CustomerDropdown = JSON.parse(JSON.stringify(result));

      });
    } if (this.docModal != undefined && this.docModal != null && this.docModal.Type == 'Loan') {
      this.losService.LOS_AssetCustomer_Info_DropDown({ ApplicationId: this.ApplicationId }).subscribe((result) => {
        this.CustomerAssetsDropdown = JSON.parse(JSON.stringify(result));

      });
    }
  }
  LOS_GetApplicatonCustomer() {
    this.losService.LOS_GetApplicatonCustomer({ ApplicationId: this.ApplicationId }).subscribe((result: any) => {
      this.customerList = result;
    });
  }
  GetInsuranceCompanyMaster_FOR_DROPDOWN() {
    this.masterService.GetInsuranceCompanyMaster_FOR_DROPDOWN({}).subscribe((res: any) => {
      this.companyDropDown = res;
    })
  }
  GetCommonMaster_FOR_DROPDOWN(type: any) {
    this.masterService.GetCommonMaster_FOR_DROPDOWN({ Type: type }).subscribe((res: any) => {
      if (type == 'RELATION') {
        this.relationDropDown = res;
      }
      if (type == 'CUSTOMER POLICY TYPE') {
        this.customerPolicyDropDown = res;
      }
      if (type == 'ASSETS POLICY TYPE') {
        this.assetPolicyDropDown = res;
      }
    })
  }
  GetInsuranceBranchMaster_FOR_DROPDOWN(isChange: boolean = true) {
    if (isChange) {
      this.insuranceModel.Insurance_Company_Branch = "";
    }
    //let _select=this.companyDropDown.find((obj:any)=>{return obj.Insurance_company==this.insuranceModel.Insurance_Company})||{};
    this.masterService.GetInsuranceBranchMaster_FOR_DROPDOWN({ InsId: this.insuranceModel.Insurance_Company }).subscribe((res: any) => {
      this.companyBranchDropDown = res;
    })
  }
  deleteInsurance(data: any) {

    this.dialog.openConfirmDialog(`Are you sure you want to remove?`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      }
      else {
        this.showSpinner = true;
        this.losService.LOS_DeleteInsurance({ Id: data.InsId }).subscribe((res: any) => {
          //console.log(res);
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.LOS_GetApplicationDetailsForFileProcessing();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }
  viewInsurance(data: any) {
    this.showSpinner = true;
    this.losService.LOS_GetAssetsDetailInsurrance({ InsId: data.InsId }).subscribe((res: any) => {
      this.showSpinner = false;
      this.insuranceModel = res[0];
      this.insuranceModel.InsuranceId = res[0].InsId;
      this.insuranceModel.LoginUser = this.currentUser.userId;
      this.GetInsuranceCompanyMaster_FOR_DROPDOWN();
      this.LOS_GetApplicatonCustomer();
      this.LOSAssetInfoDropDown();
      this.GetCommonMaster_FOR_DROPDOWN('RELATION');
      this.GetCommonMaster_FOR_DROPDOWN('CUSTOMER POLICY TYPE');
      this.GetCommonMaster_FOR_DROPDOWN('ASSETS POLICY TYPE');
      this.GetInsuranceBranchMaster_FOR_DROPDOWN(false);
      $("#insuranceModel").modal("show");
      $("#insuranceModel").css("z-index", "1050");
    })
  }

  TypeChange(event) {
    this.showSpinner = true;
    this.DoucumentCategoryDrodown();
  }
  DoucumentCategoryDrodown() {
    this.losService
      .LOS_GetApplicationReqDocForDropDown({
        ApplicationId: this.ApplicationId, Type: this.docModal.Type
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.docDropDown = res;
        this.LOSAssetInfoDropDown();
        if (this.docModal.DocId.split('-')[1] == '1')
          this.IsKYC = true;
        else
          this.IsKYC = false;

      });
  }
  DocumentCategoryChange(event) {
    if (this.docModal.DocId.split('-')[1] == '1')
      this.IsKYC = true;
    else
      this.IsKYC = false;
  }

  printDoc() {
    $("#printdocModal").modal("show");
    $("#printdocModal").css("z-index", "1050");
  }
  onCloseprintDoc() {
    $("#printdocModal").modal("hide");
  }
  printDocOpen() {
    var prtContent = document.getElementById("printdocData");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }
  onClosebankPDCModel() {
    $('#bankPDCModel').modal('hide');
  }

  createPDCRPDItem(): UntypedFormGroup {
    return this.formBuilder.group({
      PdcType: 'SPDC',
      CustomerName: '',
      Bankname: '',
      Branch: '',
      AccType: '',
      Ac: '',
      ChFrom: '',
      ChTo: '',
      NoOfChqs: '',
      IFSCCode: ''
    });
  }

  createBankDetailItem(): UntypedFormGroup {
    return this.formBuilder.group({
      PdcType: 'SPDC',
      CustomerName: '',
      Bankname: '',
      Branch: '',
      AccType: '',
      Ac: '',
      ChFrom: '',
      ChTo: '',
      NoOfChqs: '',
      IFSCCode: ''
    });
  }
  RPDItem(): UntypedFormGroup {
    return this.formBuilder.group({
      PdcType: 'RPDC',
      CustomerName: '',
      Bankname: '',
      Branch: '',
      AccType: '',
      Ac: '',
      ChFrom: '',
      ChTo: '',
      NoOfChqs: '',
      IFSCCode: ''
    });
  }
  createNACHItem(): UntypedFormGroup {
    return this.formBuilder.group({
      PdcType: 'NACH',
      CustomerName: '',
      Bankname: '',
      Branch: '',
      AccType: '',
      Ac: '',
      // ChFrom: '',
      //ChTo: '',
      //NoOfChqs: '',
      //EMIFrom: '',
      //EMITo: '',
      IFSCCode: ''
    });
  }
  addItem(): void {
    this.items = this.PdcRpdcForm.get('items') as UntypedFormArray;
    this.items.push(this.createPDCRPDItem());
  }

  RPDCaddItem(): void {
    this.RpdcItems = this.RpdcForm.get('items') as UntypedFormArray;
    this.RpdcItems.push(this.RPDItem());
  }
  removeNewPdc(i) {
    if (this.items.length == 1) {
    }
    else {
      this.items.removeAt(i);
    }
  }

  removeRPdc(event, i, item) {
    if (this.RpdcItems.length == 1) {
    }
    else {
      this.RpdcItems.removeAt(i);
      // console.log(" this.RpdcItems[i]", this.RpdcForm.get('items')['controls']);
      // this.EMICalculate(i, this.RpdcForm.get('items')['controls']);
    }
  }

  calculateNoOfChequeR(event, i, item) {
    var ChFromEle = document.getElementById('ChFromR' + i)["value"];
    var ChToEle = document.getElementById('ChToR' + i)["value"];
    // var EMIFromEle = document.getElementById('EMIFromR' + i)["value"];
    //var EMIToEle = document.getElementById('EMIToR' + i)["value"];
    if (ChToEle != null) {
      if (Number(ChFromEle) > Number(ChToEle)) {
        document.getElementById('ChFromR' + i)["value"] = '';
        document.getElementById('ChToR' + i)["value"] = '';
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Ch. No To greater than Ch. No From', ...this.configSuccess });

      } else {
        item.get('NoOfChqs').setValue(Number(ChToEle) - Number(ChFromEle) + 1);
        //if (this.checkDetailData.length > 0 && i == 0) {
        //  let data = this.checkDetailData[this.checkDetailData.length - 1];
        //  //item.get('EMIFrom').setValue(data.EMITo + 1);
        //  // item.get('EMITo').setValue(data.EMITo + item.get('NoOfChqs').value);
        //} else {
        //  let items = this.RpdcForm.get('items').value;
        //  console.log("items", items.length - 2);
        //  if (items.length > 1) {
        //    let data = items[items.length - 2];
        //    console.log("data", data);
        //    // item.get('EMIFrom').setValue(data.EMITo + 1);
        //    // item.get('EMITo').setValue(data.EMITo + item.get('NoOfChqs').value);
        //  } else {
        //    //item.get('EMIFrom').setValue(1);
        //    //item.get('EMITo').setValue(item.get('NoOfChqs').value);
        //  }
        //}
      }
      //if (EMIToEle != 0 && EMIFromEle != 0) {
      //    if ((Number(EMIToEle) - Number(EMIFromEle) > Number(document.getElementById('NoOfChqsR' + i)["value"]))) {
      //        document.getElementById('EMIFromR' + i)["value"] = '';
      //        document.getElementById('EMIToR' + i)["value"] = '';
      //        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please EMI No To greater than EMI No From', ...this.configSuccess });

      //    }
      //}
    }

  }
  /*calculateNoOfCheque(event, i, item) {
    var ChFromEle = document.getElementById('ChFrom' + i)["value"];
    var ChToEle = document.getElementById('ChTo' + i)["value"];

    if (ChToEle) {
      if (Number(ChFromEle) > Number(ChToEle)) {
        document.getElementById('ChFrom' + i)["value"] = 0;
        document.getElementById('ChTo' + i)["value"] = 0;
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Ch. No To greater than Ch. No From', ...this.configSuccess });

      } else {
        item.get('NoOfChqs').setValue(Number(ChToEle) - Number(ChFromEle) + 1);
      }
    }

  }*/
  
 


  changeRADTab(stab) {
    this.tab = stab;
  } 


  GetProductChargesHeadDropDown() {
    this.masterService.Get_ChargesHeadsByProduct_ForDropdow({ ProductId: this.Voucher_Detail.ProductId }).subscribe((res: any) => {
      this.chargesDropdown = res;
    });
  }

  searchCustomer() {
    this.customerdataSource = new MatTableDataSource([]);
    $("#LOSCustomerReceipt").modal("show");
    $("#LOSCustomerReceipt").css("z-index", "1250");
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100)
  }

  Get_Acc_Search_LoanNo() {
    this.lmsService.Get_Acc_Search_LoanNo({ SearchOn: 'LOSApplicationNo', SearchValue: this.Voucher_Detail.SearchValue }).subscribe((res: any) => {
      if (res.length > 0) {
        if (res.length == 1) {
          this.selectSearchData(res[0])
        }
        this.customerdataSource = new MatTableDataSource(res);
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Search not found.", ...this.configSuccess });
      }
    });
  }

  selectSearchData(data: any) {
    if (data.Type == 'LMS')
      this.Voucher_Detail.IsLMS = 1;
    else
      this.Voucher_Detail.IsLMS = 0;
    this.Voucher_Detail.DeductionApplicationId = data.LoanId;
    this.Voucher_Detail.CaseNo = data.Application_No;
    this.Voucher_Detail.Customer = data.CustomertName;
    this.Voucher_Detail.CustomerId = data.CustomerId;
    this.Voucher_Detail.ProductId = data.ProductId;
    this.Voucher_Detail.BranchId = data.BranchId;
    this.Voucher_Detail.Branch_Name = data.Branch_Name;
    this.Voucher_Detail.Is_LOSAppliaction = data.Type == 'LMS' ? 0 : 1;
    this.Voucher_Detail.ChargeHeadId = "";
    this.Voucher_Detail.Amount = "";
    this.Voucher_Detail.TaxSlab = [];
    this.GetProductChargesHeadDropDown();
    this.OnCloseSearch();
  }
  OnCloseSearch() {
    $("#LOSCustomerReceipt").modal("hide");
    $("#Customer").focus();
    this.Voucher_Detail.SearchValue = "";
  }

  onSaveRAdDetail() {
    this.showSpinner = true;
    this.losService.LOS_SaveApplicationCharges({ JSON: JSON.stringify({ Applicationinfo: this.Voucher_Detail }) }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res ? res[0].CODE >= 0 : false) {
        this.tab = 'list';
      } else if (res) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }

  onChangeChargesHead(data: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: data.ChargeHeadId, VoucherSubType: 'Loan', ProductId: data.ProductId || 0, TaxType: data.TranType }).subscribe((res: any) => {
      if (res.Item1[0]) {
        if (res.Item1[0].AccountId) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        }
        else {
          data.ChargeHeadId = "";
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not linked with this category.", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
      }
    });
  }

  onChangeTax() {
    this.getTotalCalculation();
  }

  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    });

    this.getTotalCalculation();
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;

    _total = _total + parseFloat(this.Voucher_Detail.Amount) || 0;
    _totalCR = _totalCR + parseFloat(this.Voucher_Detail.Amount) || 0;
    this.Voucher_Detail.TaxSlab.forEach((tobj: any) => {
      _total = _total + parseFloat(tobj.TaxAmount) || 0;
      _totalTX = _totalTX + parseFloat(tobj.TaxAmount) || 0;
    });
    this.totalCalculator = {
      total: parseFloat(_total).toFixed(2),
      totalCR: parseFloat(_totalCR),
      totaltax: parseFloat(_totalTX),
      totalInWord: new ToWords().convert(parseFloat(_total))
    }
  }

  LOSGetApplicationCharges() {
    this.showSpinner = true;
    this.losService.LOS_GetApplicationCharges({ ApplicationId: this.ApplicationId }).subscribe((res: any) => {
      this.showSpinner = false;
      this.ReceiptAndDeductionList = res;
    });
  }

  removeReceiptAndDeduction(data) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove?`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
        this.losService.Delete_LOSReceiptDeduction({ Id: data.Id }).subscribe((res: any) => {
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.LOSGetApplicationCharges();
            this.QuickLoan_Save_PageProcess('Pending Document');
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  onShowPendingDocs() {
    $("#PendingDocs").modal("show");
    $("#PendingDocs").css("z-index", "1050");
  }

  onClosePendingDocs() {
    $("#PendingDocs").modal("hide");
  }

  checkPendingDoc() {
    this.losService.LOS_GetPendingDocListWEB({ ApplicationId: this.ApplicationId }).subscribe((res: any) => {
      if (res.Item2.length > 0)
        this.showPendingDoc = true;
      else
        this.showPendingDoc = false;
    });
  }
  
  changeInsuranceDate(event: any) {
    //this.AssetRenewalDate = this.AssetInsuranceDate
    var year = this.insuranceModel.Insurance_Date.getFullYear();
    var month = this.insuranceModel.Insurance_Date.getMonth();
    var day = this.insuranceModel.Insurance_Date.getDate();
    var c = new Date(year + 1, month, day - 1);
    console.log("new date", c);
    this.insuranceModel.Insurance_Renewal_Date = c;
  }
  changeExpireDate(event: any) {
    if (this.insuranceModel.Insurance_Renewal_Date < this.insuranceModel.Insurance_Date) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Customer Insurance Expire Date greater than Customer Insurance Date.",
        ...this.configSuccess,
      });
      this.insuranceModel.Insurance_Renewal_Date = '';
    }
  }
  
  EditBankDetail() {
    this.BankDetailShow = false;
  }

  OnEditApplicationDocs(data) {
    console.log("dataSss :", data);
    this.losService.LOS_GetApplicationDocAttachment({ UDId: data.UDId }).subscribe((res: any) => {
      var AllImg = [];
      for (let i = 0; i < res.length; i++) {
        AllImg.push({ DocFileName: res[i].UD_DocFileName });
      }

      this.docModal = {
        ApplicationId: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
        AssetName: "",
        No_Of_Pages: res[0].No_Of_Pages,
        IsReceipt: false,
        IsOrignalDoc: res[0].IsOrignalDoc == false ? 1 : 0,
        Type: res[0].Application_Type,
        DocTitle: res[0].DocTitle,
        Remark: res[0].Remark,
        DocumentImages: AllImg,
        KYC_DocId: 0,
        DocUId: res[0].UDId,
        ApplicationNo: this.loanSummary.ApplicationNo
      };

      console.log("docModal :", this.docModal);

      this.DoucumentCategoryDrodown_New(res);

      $("#EditDocument").modal("show");
      $("#EditDocument").css("z-index", "1050");
    });

    //this.kycModel = { CustomerId: data.CustomerId, CustomerIdentity: data.CustomerIdentity, DocName: data.DocName, KYC_DocId: data.DocumentId, KYC_DocNumber: "", KYC_DocFile: "", KYC_IssuedDate: "", KYC_ExpiredDate: "", LoginUserId: this.currentUser.userId };
  }

  OnCloseDocModal() {
    $("#EditDocument").modal("hide");
  }

  DoucumentCategoryDrodown_New(data) {
    var i = 0;
    this.losService
      .LOS_GetApplicationReqDocForDropDown({ ApplicationId: this.loanSummary.LoanId, Type: this.docModal.Type })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.docDropDown = res;

        this.docModal.DocId = data[0].DocumentId + '-' + res[i].Doc_CategoryId;
        i++;
      });
  }

  DateChange() {
    console.log(this.docModal.KYC_IssuedDate, this.docModal.KYC_ExpiredDate);
    if (this.docModal.KYC_ExpiredDate != null) {
      if (this.docModal.KYC_IssuedDate > this.docModal.KYC_ExpiredDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Date Of Expire is greater than Date Of Issue.", ...this.configSuccess });
        this.docModal.KYC_ExpiredDate = '';
      }
    }
  }

  OnUpdateDoc() {
    console.log("this.docModal", this.docModal);
    let _data = { ...this.docModal };
    if (+this.docModal.No_Of_Pages <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No Of Pages required and should be grater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.docModal.DocumentImages.length) {
      this.loading = true;
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        console.log("this.docModal.DocumentImages", this.docModal.DocumentImages[i].DocData);
        if (this.docModal.DocumentImages[i].DocData != undefined) {
          this.losService.UploadCrcDoc({
            DocName: this.docModal.DocumentImages[i].DocFileName,
            ApplicationNo: this.docModal.ApplicationNo,
            DocData: this.docModal.DocumentImages[i].DocData,
          })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.loading = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );

          if (_err) {
            break;
          }
        }
        else {
          this.saveDoc();
        }
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload doc file.",
        ...this.configSuccess,
      });
    }
  }
  GetData() {
    this.ngOnInit();
    this.onClosePendingDocs();
  }

  ToUpperText(obj) {
    for (var prop in obj) {
      obj[prop] = obj[prop].toUpperCase();
    }
    return obj;
  }

  CheckCustomerRefInfo() {
    if (this.docDataSource.filteredData.length != 0) {
      this.action.emit("next")
    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Pending Document.", ...this.configSuccess });
    }
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
}
