import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import * as moment from "moment";

@Component({
  selector: "app-rptuserlog",
  templateUrl: "./rptuserlog.component.html",
  styleUrls: ["./rptuserlog.component.scss"],
})
export class RptuserlogComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  GetEmployeeDropdownRequestModel: RequestModel = new RequestModel();
  RequestModel: RequestModel = new RequestModel();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  GetEmployeeDropdown: any = [];
  displayedColumns: string[] = [
    "Id",
    "Application_No",
    "Customer",
    "User_Name",
    "Activity",
    "Sub_Activity",
    "CompleteOn",
    "TAT_Hr",
  ];
  FromDate: any = "";
  ToDate: any = "";
  CurrentDate: any = new Date();
  EmployeeLog: any = [];
  EmployeeID: any = "0";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    let now = moment();

    this.ToDate = moment(now, "DD/MM/YYYY").toDate();
    this.FromDate = moment(now, "DD/MM/YYYY").add(0, "days").toDate();
  }
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("User Log Report");
    this.GetEmployeeDropdownRequestModel.BranchId = 0;
    this.GetEmployeeDropdownRequestModel.ProcessId = 0;
    this._MasterService
      .GetEmployeeDropdown(this.GetEmployeeDropdownRequestModel)
      .subscribe((result) => {
        this.GetEmployeeDropdown = JSON.parse(JSON.stringify(result));
      });
  }

  getUserLog() {
    if (this.EmployeeID == "0") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Employee.",
        ...this.configSuccess,
      });
      return;
    }

    if (this.FromDate == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select From Date.",
        ...this.configSuccess,
      });
      return;
    }

    if (this.ToDate == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select To Date.",
        ...this.configSuccess,
      });
      return;
    }
    this.showSpinner = true;
    this.RequestModel.LoginUserId = this.EmployeeID;
    this.RequestModel.FromDate = moment(this.FromDate).format("YYYY/MM/DD");
    this.RequestModel.ToDate = moment(this.ToDate).format("YYYY/MM/DD");
    this._MasterService
      .LOS_rptLogReport(this.RequestModel)
      .subscribe((result) => {
        this.EmployeeLog = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.EmployeeLog);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
}
