<div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'Closed'
    "
  >
    Closed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'Legal'
    "
  >
    Legal
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'NPA'
    "
  >
    NPA
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'Legal/NPA'
    "
  >
    Legal/NPA
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'Repossessed'
    "
  >
    Repossessed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'Legal/Repossessed'
    "
  >
    Legal/Repossessed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary == undefined &&
      ApplicationDetail &&
      ApplicationDetail.Watermark_Status &&
      ApplicationDetail.Watermark_Status == 'NPA/Legal/Repossessed'
    "
  >
    NPA/Legal/Repossessed
  </div>

  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <!-- <div class="row m-0" *ngIf="loanSummary == undefined">
    <div class="col-md-12 text-right">
      <button type="button" mat-raised-button class="ml-4 font-size-12" (click)="getAllList()" style="background-color: #28a745;
      color: #FFFFFF; font-size: 12px;">Click For Search</button>
    </div>
  </div> -->

  <div class="row m-0 mt-2">
    <!-- [ngClass]="{'fixheight': loanSummary != undefined && !PageType, 'fixheight2': loanSummary != undefined && PageType == 'A' }" -->
    <div class="col-md-12 formborder-statement p-0 mb-3">
      <div class="col-md-12 p-0">
        <h1 class="fs-12 h-b slideDiv">
          <div
            class="row m-0"
            style="
              padding: 0 5px;
              position: sticky;
              display: flex;
              /* align-items: center; */
              justify-content: space-between;
            "
          >
            <!-- <div class="col-md-2"></div> -->

            <span>Loan Details As on</span>
            <div class="col-md-2" style="margin-top: -8px">
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="VoucherDateTo"
                  [(ngModel)]="VoucherDateTo"
                  (dateChange)="DateChange()"
                  id="VoucherDateTo"
                  class="form-control input-text-css"
                />
                <!-- -->
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>

            <div class="col-md-4">
              <input
                style="height: 19px; width: 17px; margin-top: 0"
                (change)="ChargesChange($event)"
                class="form-check-input"
                type="checkbox"
                name="OtherDetail"
                id="OtherDetail"
                value="option2"
              />
              <label
                style="margin: 2px 0px 0px 0px; font-size: 12px"
                class="form-check-label"
                for="OtherDetail"
              >
                Show Other A/C & Income A/C Detail
              </label>
            </div>

            <div class="col-md-3">
              <input
                style="height: 19px; width: 17px; margin-top: 0"
                (change)="ODChange($event)"
                class="form-check-input"
                type="checkbox"
                name="InterestDetail"
                id="InterestDetail"
                value="option2"
              />
              <label
                style="margin: 2px 0px 0px 0px; font-size: 12px"
                class="form-check-label"
                for="InterestDetail"
              >
                Show OD Interest Detail
              </label>
            </div>
            <div class="col-md-1" *ngIf="loanSummary == undefined">
              <button
                class="staticIcon"
                style="float: right; margin-top: -15px; padding-left: 50px"
                mat-icon-button
              >
                <mat-icon *ngIf="!showLess_LoanDetail" (click)="showResult()"
                  >expand_more</mat-icon
                >
                <mat-icon *ngIf="showLess_LoanDetail" (click)="hideResult()"
                  >expand_less</mat-icon
                >
              </button>
            </div>
          </div>
        </h1>
      </div>
      <div class="col-md-12 p-0 mb-2" id="sectionToPrint">
        <div
          class="row m-0"
          *ngIf="showLess_LoanDetail && loanSummary == undefined"
        >
          <div class="col-md-3">
            <h6 class="fs-12">Application No</h6>
            <p class="lead fw-7 fs-12">{{ ApplicationDetail.ApplicationNo }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Application Date</h6>
            <p class="lead fs-12">
              {{ ApplicationDetail.ApplicationCreateOn }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan No</h6>
            <p class="lead fs-12">
              {{
                ApplicationDetail != undefined ? ApplicationDetail.LoanAcNo : ""
              }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan Date</h6>
            <p class="lead fs-12">{{ ApplicationDetail.Loan_Date }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Branch</h6>
            <p class="lead fs-12">{{ ApplicationDetail.Branch }}</p>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">Product</h6>
            <p class="lead fs-12">{{ ApplicationDetail.Product }}</p>
            <!-- <p class="fs-12 LMS_Get_AssetsDetail_Property()">{{ApplicationDetail.Product}}</p> -->
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Loan Purpose</h6>
            <p class="lead fs-12">{{ ApplicationDetail.LoanPurpose }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Expiry Date</h6>
            <p class="lead fs-12">{{ ApplicationDetail.ExpiryDate }}</p>
          </div>
          <div class="col-md-2" *ngIf="ApplicationDetail.CloseDate">
            <h6 class="fs-12">Close Date</h6>
            <p
              class="lead fs-12"
              [ngClass]="{
                'alert-danger':
                  ApplicationDetail.CloseDate != '' ||
                  ApplicationDetail.CloseDate != null
              }"
            >
              {{ ApplicationDetail.CloseDate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 p-0 mb-2" id="sectionToPrint">
      <div class="table-responsive box-shadow" *ngIf="loanSummary == undefined">
        <mat-table
          [dataSource]="customerDataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header mw200" *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw200"
            >
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
              >
                {{ row.Customer }}
              </app-lms-customer-view-model>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header mw100" *matHeaderCellDef
              >Phone no</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">
              <div *ngIf="currentUser.IsMasking">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMasking">
                {{ row.PhoneNo }}
              </div>

              <i
                *ngIf="row.PhoneNoIsVerified"
                class="fa fa-check-circle ml-2"
                style="color: green"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header mw100" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">
              {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header mw100" *matHeaderCellDef
              >Gender/Age</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.GenderAge
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ExistingCustomer">
            <mat-header-cell class="grid-header mw100" *matHeaderCellDef
              >Is Existing</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.ExistingCustomer
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Relation_With_Hirer">
            <mat-header-cell class="grid-header mw100" *matHeaderCellDef
              >Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
              row.Relation_With_Hirer
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Present_Address">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Present Address</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Present_Address
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Detail">
            <mat-header-cell
              class="grid-header justify-content-center mw50"
              *matHeaderCellDef
            >
              <button
                class="staticIcon"
                style="float: right; margin-top: -10px"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="!showLess_CustomerDetail"
                  (click)="showCustomerResult()"
                  >expand_more</mat-icon
                >
                <mat-icon
                  *ngIf="showLess_CustomerDetail"
                  (click)="hideCustomerResult()"
                  >expand_less</mat-icon
                >
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell mw50"></mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedCustomerDetailColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerDetailColumns"
          ></mat-row>
        </mat-table>
      </div>

      <div class="formborder-statement mt-3" *ngIf="loanSummary == undefined">
        <h1
          class="fs-12 h-b slideDiv"
          style="padding: 5px 7px 0 0"
          *ngIf="loanSummary == undefined"
        >
          <div
            class="row"
            style="
              display: flex;
              justify-content: space-between;
              padding: 0 5px;
            "
          >
            <div class="col-md-10">Finance Detail</div>
            <div class="col-md-2">
              <button
                class="staticIcon"
                style="float: right; margin-top: -15px; padding-left: 25px"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="!showLess_FinanceDetail"
                  (click)="showFinanceResult()"
                  >expand_more</mat-icon
                >
                <mat-icon
                  *ngIf="showLess_FinanceDetail"
                  (click)="hideFinanceResult()"
                  >expand_less</mat-icon
                >
              </button>
            </div>
          </div>
        </h1>

        <div
          class="row m-0 mt-3"
          *ngIf="showLess_FinanceDetail && loanSummary == undefined"
        >
          <div class="col-md-2">
            <h6 class="fs-12">Asset Cost</h6>
            <p class="lead fs-12 fw-7">
              ₹ {{ ApplicationDetail.AssetCost.toFixed(2) }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Net Finance</h6>
            <p class="fs-12 LMS_Get_AssetsDetail_Property()">
              ₹ {{ ApplicationDetail.NetFinance.toFixed(2) }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Interest Amount</h6>
            <p class="lead fs-12">
              ₹ {{ ApplicationDetail.InterestAmt.toFixed(2) }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Agreement Value</h6>
            <p class="lead fs-12">
              ₹ {{ ApplicationDetail.AgreementValue.toFixed(2) }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">LTV</h6>
            <p class="lead fs-12">{{ ApplicationDetail.LTV.toFixed(2) }} %</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Margin</h6>
            <p class="lead fs-12">
              ₹ {{ ApplicationDetail.Margin.toFixed(2) }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">
              Tenure ({{ ApplicationDetail != undefined ? TenureName : "" }})
            </h6>
            <p class="lead fs-12">
              {{ ApplicationDetail.Tenure }}
              {{ ApplicationDetail != undefined ? TenureName : "" }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">No of Installment</h6>
            <p class="LMS_Get_AssetsDetail_Property() fs-12">
              {{ ApplicationDetail.No_Of_Instl }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Adv. Installment</h6>
            <p class="lead fs-12">{{ ApplicationDetail.Adv_Instl }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">ROI</h6>
            <p class="lead fs-12">{{ ApplicationDetail.Flat_Rate }} %</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Case IRR</h6>
            <p class="lead fs-12">
              {{ ApplicationDetail.Case_IRR.toFixed(2) }} %
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">First EMI Date</h6>
            <p class="lead fs-12">{{ ApplicationDetail.FirstEMIDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Expiry Date</h6>
            <p class="lead fs-12">{{ ApplicationDetail.ExpiryDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">EMI Amount</h6>
            <p class="lead fs-12">
              {{ ApplicationDetail.Application_LoanEMIAmount }}
            </p>
          </div>

          <div class="col-md-2" *ngIf="ApplicationDetail.CloseDate">
            <h6 class="fs-12">Close On</h6>
            <p class="lead fs-12"></p>
          </div>
        </div>
      </div>

      <div
        class="table-responsive box-shadow"
        [hidden]="!dataSourceAssets"
        *ngIf="loanSummary == undefined"
      >
        <h1 class="fs-12 m-0 mb-1">Assets Details:</h1>
        <mat-table
          [dataSource]="dataSourceAssets"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="AssetId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Collateral_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200"
              *matHeaderCellDef
            >
              Collateral Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell mw200">
              {{ row.Collateral_Type }}
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Collateral">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200"
              *matHeaderCellDef
              >Collateral</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw200">
              {{ row.Collateral }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ValuationType">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200"
              *matHeaderCellDef
              >ValuationType</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw200">
              {{ row.ValuationType }}
            </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="ValuationAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200"
              *matHeaderCellDef
              >ValuationAmount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw200">
              {{ row.ValuationAmount }}
            </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <!--<ng-container matColumnDef="ValuationDoneBy">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>ValuationDoneBy</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ValuationDoneBy}} </mat-cell>
      </ng-container>-->
          <!-- Name Column -->
          <ng-container matColumnDef="ValuationDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200"
              *matHeaderCellDef
              >ValuationDate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw200">
              {{ row.ValuationDate }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ActionView">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <a
                href="javascript:void(0)"
                class="mr-2"
                (click)="getAssetDetail(row, false)"
                >View</a
              >
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header justify-content-center mw200"
              *matHeaderCellDef
            >
              <button
                class="staticIcon"
                style="float: right; margin-top: -10px; margin-left: 120px"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="!showLess_AssetsDetail"
                  (click)="showAssetsResult()"
                  >expand_more</mat-icon
                >
                <mat-icon
                  *ngIf="showLess_AssetsDetail"
                  (click)="hideAssetsResult()"
                  >expand_less</mat-icon
                >
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell mw200"></mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedAssetsColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedAssetsColumns"
          ></mat-row>
        </mat-table>
      </div>

      <div class="table-responsive mt-3 box-shadow" *ngIf="dataSourceLoan">
        <!--<h1 class="fs-12 m-0 mb-0 heading-text">Installment Details:</h1>-->
        <h1
          class="fs-12 h-b slideDiv"
          style="padding: 5px 7px 0 0; margin-bottom: 0px !important"
          *ngIf="loanSummary == undefined"
        >
          <div class="row m-0">
            <div class="col-md-12 text-center">Installment Details</div>
            <div class="col-md-0">
              <!--<button class="staticIcon" style="float: right; margin-top: -10px; padding-left: 20px;" mat-icon-button>
                <mat-icon *ngIf="!showLess_FinanceDetail" (click)="showFinanceResult()">expand_more</mat-icon>
                <mat-icon *ngIf="showLess_FinanceDetail" (click)="hideFinanceResult()">expand_less</mat-icon>
              </button>-->
            </div>
          </div>
        </h1>
        <!-- <mat-table [dataSource]="dataSourceLoan" matSort matTableExporter #exporter="matTableExporter">
    <ng-container matColumnDef="voucherid">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>#</mat-header-cell>
      <mat-cell *matCellDef="let row,let i = index" class="grid-cell">
        {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>&nbsp;</mat-footer-cell>

    </ng-container>
    <ng-container matColumnDef="VoucherDate">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Voucher Date </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{row.VoucherDate  }}</mat-cell>
      <mat-footer-cell *matFooterCellDef>&nbsp;</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Voucher_No">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Voucher No</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
        <app-lms-voucher-view-model [voucherid]="row.voucherid" (close)="GetData($event)" [index]="i">
          {{row.Voucher_No}}
        </app-lms-voucher-view-model>
      </mat-cell>
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Voucher No</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{row.Voucher_No}}</mat-cell>
      <mat-footer-cell *matFooterCellDef>&nbsp;</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="accHead">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>A/C Head</mat-header-cell>
       <mat-cell *matCellDef="let row" class="grid-cell"> {{row.accHead}} </mat-cell>
      <mat-cell *matCellDef="let row" class="grid-cell naration mw100" data-toggle="tooltip" data-placement="left" title="{{ row.accHead }}">
        <ng-container *ngIf="row.accHead">
          {{ row.accHead.slice(0, 10) }}{{ row.accHead.length > 10 ? "..." : "" }}
        </ng-container>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
      <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="TranTypeDR">
      <mat-header-cell class="grid-header text-right" *matHeaderCellDef>DR Amount</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeDR}} </mat-cell>
      <mat-footer-cell *matFooterCellDef class="text-right">{{ calculateTranTypeDR() }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="TranTypeCR">
      <mat-header-cell class="grid-header text-right" *matHeaderCellDef>CR Amount</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeCR}} </mat-cell>
      <mat-footer-cell *matFooterCellDef class="text-right">{{ calculateTranTypeCR() }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="TranTypeTotal">
      <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Balance</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeTotal}} </mat-cell>
      <mat-footer-cell *matFooterCellDef class="text-right">{{ calculateTranTypeTotal() }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Narration">
      <mat-header-cell mat-sort-header class="grid-header" style="width:200px;" *matHeaderCellDef>Narration</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Narration}} </mat-cell>
      <mat-cell *matCellDef="let row" class="grid-cell naration" style="width:200px;" data-toggle="tooltip" data-placement="left" title="{{ row.Narration }}">
        <ng-container *ngIf="row.Narration">
          {{ row.Narration.slice(0, 40) }}{{ row.Narration.length > 40 ? "..." : "" }}
        </ng-container>
      </mat-cell>
      <mat-footer-cell class="mw200"  *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsStatement"></mat-header-row>
    <mat-row class="maxheight" *matRowDef="let row; columns: displayedColumnsStatement"></mat-row>
    <mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedColumnsStatement"></mat-footer-row>

  </mat-table> -->
        <!--<div class="row m-0 align-items-center" style="background: #fff;">
    <div class="col-md-8">
      <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
    </div>
  </div>-->
        <mat-table
          matSort
          [dataSource]="dataSourceLoan"
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="voucherid">
            <mat-header-cell
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="width50"
              >#</mat-header-cell
            >
            <mat-cell *matCellDef="let row; let i = index" class="width50">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="VoucherDate">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
              Voucher Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.VoucherDate }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_No">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Voucher No</mat-header-cell
            >
            <mat-cell *matCellDef="let row"> {{ row.Voucher_No }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Receipt_No">
            <mat-header-cell mat-sort-header mat-header-cell *matHeaderCellDef
              >Receipt No</mat-header-cell
            >
            <mat-cell *matCellDef="let row"> {{ row.Receipt_No }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="accHead">
            <mat-header-cell mat-sort-header mat-header-cell *matHeaderCellDef
              >A/C Head</mat-header-cell
            >
            <mat-cell *matCellDef="let row"> {{ row.accHead }} </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="d-flex align-items-center justify-content-end"
            >
              DR Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateTranTypeDR()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="d-flex align-items-center justify-content-end"
              >CR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateTranTypeCR()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeTotal">
            <mat-header-cell
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
              >Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeTotal }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateTranTypeTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Narration">
            <mat-header-cell mat-sort-header mat-header-cell *matHeaderCellDef>
              Narration
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell naration"
              style="width: 200px"
              data-toggle="tooltip"
              data-placement="left"
              title="{{ row.Narration }}"
            >
              <ng-container *ngIf="row.Narration">
                {{ row.Narration }}
              </ng-container>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsStatement; sticky: true"
          ></mat-header-row>
          <mat-row
            [ngClass]="!!row.checkAccount == 0 ? 'Failed' : ''"
            *matRowDef="let row; columns: displayedColumnsStatement"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsStatement"
          ></mat-footer-row>
        </mat-table>
        <div
          *ngIf="loanSummary == undefined && dataSourceLoan"
          class="col-md-12 text-right"
          style="padding: 5px"
        >
          <button
            class="btn font-size-12 button-btn"
            (click)="exportexcelNew()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>

          <button
            type="button"
            class="btn font-size-12 button-btn"
            (click)="LoanStatementPrint()"
          >
            Print Statement
          </button>
          <!-- <a (click)="onDocsPrint('content')" target="_blank">Print</a> -->
        </div>
      </div>
    </div>

    <div class="table-responsive box-shadow" *ngIf="showLoanOD">
      <h1
        class="fs-12 h-b slideDiv"
        style="padding: 5px 7px 0 0; margin-bottom: 0px !important"
        *ngIf="loanSummary == undefined"
      >
        <div class="row m-0">
          <div class="col-md-10">OD Details</div>
          <div class="col-md-2">
            <!--<button class="staticIcon" style="float: right; margin-top: -10px; padding-left: 20px;" mat-icon-button>
          <mat-icon *ngIf="!showLess_FinanceDetail" (click)="showFinanceResult()">expand_more</mat-icon>
          <mat-icon *ngIf="showLess_FinanceDetail" (click)="hideFinanceResult()">expand_less</mat-icon>
        </button>-->
          </div>
        </div>
      </h1>
      <mat-table
        [dataSource]="dataSourceLoanOD"
        #sortList="matSort"
        matSort
        id="exportReport"
        matTableExporter
        #exporter1="matTableExporter"
      >
        <ng-container matColumnDef="voucherid">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 40px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 40px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell
            style="max-width: 40px"
            *matFooterCellDef
          ></mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="VoucherDate">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.VoucherDate }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Voucher No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_No }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="OS_Balance">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >OS Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.TT }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="DaysLate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Days</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.DaysLate }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            calculateODDays()
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ODInterest">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ODI Due</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <span
              (mouseenter)="showInfoCard($event, row)"
              (mouseleave)="hideInfoCard()"
              >{{ row.ODDebit }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ calculateODInterest() }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ODCredit">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ODI Credit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ODCredit }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ calculateODCredit() }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ODTotal">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ODI Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ODTotal }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ calculateODTotal() }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsOD"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsOD"></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsOD"
        ></mat-footer-row>
      </mat-table>

      <div
        class="row m-0 align-items-center text-right"
        *ngIf="loanSummary == undefined"
        style="background: #f1f3f3"
      >
        <div class="col-md-12">
          <button
            class="btn font-size-12 button-btn"
            *ngIf="dataSourceLoanOD"
            (click)="exportTableODDetail(exporter1)"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i
            >OD Detail Export
          </button>
        </div>
      </div>
    </div>

    <div
      class="table-responsive mt-3 box-shadow"
      *ngIf="showLoanODCharges"
      id="sectionCharges"
    >
      <mat-table
        [dataSource]="dataSourceLoanODCharges"
        #sortList="matSort"
        matSort
        id="exportReport"
        [ngClass]="{ 'fs-14': loanSummary == undefined }"
      >
        <ng-container matColumnDef="HeadId">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ChagesHead">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Charges Head</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ChagesHead
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeDR">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Debit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeDR
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeDR("OD") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeCR">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Credit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeCR
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeCR("OD") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeTotal">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeTotal
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeTotal("OD") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell class="grid-header text-center" *matHeaderCellDef
            >View Detail</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-center">
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToChargesDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsODCharges"
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let row;
            let i = index;
            columns: displayedColumnsODCharges
          "
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsODCharges"
        ></mat-footer-row>
      </mat-table>

      <div
        *ngIf="loanSummary == undefined"
        class="col-md-12 p-0 mt-1 mb-1 text-right"
        style="padding: 5px"
      >
        <!-- <button type="button" style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button
            color="primary" (click)="ViewSOA()" target="_blank">
            View
          </button> -->
        <button
          type="button"
          class="btn font-size-12 button-btn"
          (click)="LoanSOAPrint()"
          target="_blank"
        >
          Print SOA
        </button>

        <button
          type="button"
          class="btn font-size-12 button-btn"
          (click)="LoanSOALegalPrint()"
          target="_blank"
        >
          Print SOA Legal
        </button>
      </div>
    </div>

    <div class="table-responsive mt-3 box-shadow" *ngIf="showLoanIncomeCharges">
      <mat-table
        [dataSource]="dataSourceLoanIncomeCharges"
        #sortList="matSort"
        matSort
        id="exportReport"
      >
        <ng-container matColumnDef="HeadId">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ChagesHead">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Income Charges Head</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ChagesHead
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeDR">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Debit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeDR
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeDR("ID") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeCR">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Credit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeCR
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeCR("ID") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeTotal">
          <mat-header-cell class="grid-header text-right" *matHeaderCellDef
            >Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.TranTypeTotal
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            {{ calculateChargesTranTypeTotal("ID") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell class="grid-header text-center" *matHeaderCellDef
            >View Detail</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-center">
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToChargesDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsODCharges"
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let row;
            let i = index;
            columns: displayedColumnsODCharges
          "
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsODCharges"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="SearchLoanDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          (click)="onCloseSearchLoanDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (ngSubmit)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-3">
              <span>Type</span>
              <select
                name="Type"
                id="Type"
                class="form-control input-text-css"
                [(ngModel)]="Type"
              >
                <option [value]="0">All</option>
                <option [value]="1">LOS Only</option>
                <option [value]="2">LMS Only</option>
              </select>
            </div>
            <div class="col-md-3">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Vehicle No</span>
              <input
                name="VehicleNo"
                [(ngModel)]="Customer.VehicleNo"
                id="VehicleNo"
                placeholder="Vehicle No"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 col-md-12 p-0 justify-content-end">
            <button
              type="button"
              (click)="onSearchCustomerDetail()"
              class="btn font-size-12 button-btn"
            >
              Search
            </button>
          </div>
        </form>
        <div class="table-responsive mt-3">
          <mat-table
            [dataSource]="dataSourceCustomer"
            #sortList="matSort"
            matSort
            id="exportReport"
          >
            <ng-container matColumnDef="CustomerId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px; justify-content: center"
                *matHeaderCellDef
              >
                Type
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px; justify-content: center"
              >
                {{ row.Type }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Application_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px; justify-content: center"
                *matHeaderCellDef
              >
                Aplication No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px; justify-content: center"
              >
                {{ row.Application_No }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LoanAcNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px; justify-content: center"
                *matHeaderCellDef
              >
                Loan No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px; justify-content: center"
              >
                {{ row.LoanAcNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomertName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CustomertName }}({{ row.AC_CustomerType }})
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="FatherName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Relation Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.FatherName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_Gender">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >Gender</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 50px"
              >
                {{ row.Customer_Gender }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="GenderAge">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >Age</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 50px"
                >{{ row.GenderAge }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px; justify-content: center"
                *matHeaderCellDef
              >
                Phone No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px; justify-content: center"
              >
                <div *ngIf="currentUser.IsMasking">
                  {{ row.PhoneNo | mask }}
                </div>

                <div *ngIf="!currentUser.IsMasking">
                  {{ row.PhoneNo }}
                </div>

                <i
                  *ngIf="row.Customer_PhoneNo_IsVerified == true"
                  class="fa fa-check-circle ml-2"
                  style="color: green"
                ></i>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="EditAction">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 60px"
              >
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetail(row)"
                  style="cursor: pointer; color: green; font-size: 16px"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerColumns"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginatorRef
            #PaginatorList
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ChargesLoanDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ ChargesName }}
        </h6>
        <button
          type="button"
          (click)="onCloseChargesLoanDetail()"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="col-md-12">
            <div
              class="table-responsive mt-1"
              style="max-width: 100%; overflow: auto"
            >
              <!-- <mat-table [dataSource]="dataSourceChargesDetail" #sortList="matSort" matSort matTableExporter #exporter="matTableExporter"
                style="height: 410px; max-width: 100%; overflow: auto;" [hiddenColumns]="[displayedColumns.indexOf('voucher_id'),displayedColumns.indexOf('ViewAction')]">

                <ng-container matColumnDef="voucher_id">
                  <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>#</mat-header-cell>
                  <mat-cell *matCellDef="let row,let i = index" class="grid-cell">
                    {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Voucher_Date">
                  <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Voucher Date </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{row.VoucherDate  }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Voucher_No">
                  <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Voucher No</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Voucher_No}} </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="accHead">
                  <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>A/C Head</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell"> {{row.accHead}} </mat-cell>
                  <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>

                </ng-container>
                <ng-container matColumnDef="TranTypeDR">
                  <mat-header-cell class="grid-header text-right" *matHeaderCellDef>DR Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeDR}} </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" text-right">{{ calculateChargesDR() }}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="TranTypeCR">
                  <mat-header-cell class="grid-header text-right" *matHeaderCellDef>CR Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeCR}} </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" text-right">{{ calculateChargesCR() }}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="TranTypeTotal">
                  <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Balance</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell text-right"> {{row.TranTypeTotal}} </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" text-right">{{ calculateChargesTotal() }}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Narration">
                  <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Narration</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell" data-toggle="tooltip" data-placement="left" title="{{ row.Narration }}">
                    <ng-container *ngIf="row.Narration">
                      {{ row.Narration.slice(0, 15) }}{{ row.Narration.length > 15 ? "..." : "" }}
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="ViewAction">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>View Detail</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
                    <i class="fa fa-arrow-circle-right btn_detail" (click)="ViewVoucherDetail(row)"></i>
                  </mat-cell>
                  <mat-cell *matCellDef="let row ; let i = index" class="grid-cell text-center">
                    <app-lms-voucher-view-model [voucherid]="row.voucherid" (close)="GetData($event)" [index]="i">
                      <i class="fa fa-arrow-circle-right btn_detail" aria-label="true"></i>
                    </app-lms-voucher-view-model>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                <mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedColumns; sticky:true;" style="background: #4dc3a3;"></mat-footer-row>
              </mat-table> -->
              <table
                class="table-bordered"
                matSort
                mat-table
                [dataSource]="dataSourceChargesDetail"
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="voucher_id">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let row; let i = index">
                    {{
                      paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1)
                    }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="Voucher_Date">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Voucher Date
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="Voucher_No">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Voucher No
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="CaseNo">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Case No
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="Customer_Name">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Customer Name
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.Customer_Name }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="accHead">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    A/C Head
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
                  <th mat-footer-cell *matFooterCellDef>Total</th>
                </ng-container>

                <ng-container matColumnDef="TranTypeDR">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    DR Amount
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.TranTypeDR }}</td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateChargesDR() }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="TranTypeCR">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    CR Amount
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.TranTypeCR }}</td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateChargesCR() }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="TranTypeTotal">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Balance
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranTypeTotal }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateChargesTotal() }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="Narration">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    Narration
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    class="grid-cell naration"
                    style="width: 200px"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="{{ row.Narration }}"
                  >
                    <ng-container *ngIf="row.Narration">
                      {{ row.Narration }}
                    </ng-container>
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="ViewAction">
                  <th mat-sort-header mat-header-cell *matHeaderCellDef>
                    View Detail
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="text-center"
                  >
                    <app-lms-voucher-view-model
                      [voucherid]="row.voucherid"
                      (close)="GetData($event)"
                      [index]="i"
                    >
                      <i
                        class="fa fa-arrow-circle-right arrow-circle action-btn"
                        *ngIf="row.voucherid > 0"
                        aria-label="true"
                      ></i>
                    </app-lms-voucher-view-model>
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
                <tr
                  mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedColumns"
                ></tr>
              </table>

              <!-- <div class="row m-0 pt-2 pb-1" style="background: #fff;">
                <div class="col-md-12">
                  <button class="btn btn-success font-size-12" (click)="ExportExcelCharges()">
                    <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
                  </button>
                </div>
                <div class="col-md-8">
                  <mat-paginator #paginatorRef [pageSizeOptions]="[10,20,50]" showFirstLastButtons> </mat-paginator>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="row m-0 ml-3" [hidden]="!dataSourceChargesDetail">
          <div class="col-md-4 mt-3 pl-1">
            <button
              class="btn font-size-12 button-btn"
              (click)="ExportExcelCharges()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 pr-1">
            <mat-paginator
              #paginatorlist
              [pageSize]="20"
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="assetModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add {{ assetModel.Collateral_Type }} Collateral
        </h6>
        <button
          type="button"
          (click)="onClosePrimaryAsset()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Vehicle'">
            <div class="row mt-2">
              <div class="col-md-6">
                <p class="lead fs-12">
                  {{ assetModel.Vehicle_Type }} &nbsp;
                  {{ assetModel.Vehicle_SubType }} &nbsp; Vehicle
                </p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Manufacture</h6>
                <p class="lead fs-12">{{ assetModel.VehicleManufacture }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Category</h6>
                <p class="lead fs-12">{{ assetModel.VehicleCategory }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Model Name</h6>
                <p class="lead fs-12">{{ assetModel.VehicleModelName }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Variant</h6>
                <p class="lead fs-12">{{ assetModel.Variant }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Manufacture Year</h6>
                <p class="lead fs-12">
                  {{ assetModel.MefgYear | date : "yyyy" }}
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Registation No</h6>
                <p class="lead fs-12">{{ assetModel.VehicleRegistationNo }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-2">
                <h6 class="fs-12">Registation Date</h6>
                <p class="lead fs-12">{{ assetModel.RegistationDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Registation Expiry Date</h6>
                <p class="lead fs-12">{{ assetModel.RegistationExpiryDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Fuel Type</h6>
                <p class="lead fs-12">{{ assetModel.FuelType }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Road Tax Upto</h6>
                <p class="lead fs-12">{{ assetModel.RoadTaxUpto }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Color</h6>
                <p class="lead fs-12">{{ assetModel.Color }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Fitness Upto</h6>
                <p class="lead fs-12">{{ assetModel.FitnessUpto }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Permit Upto</h6>
                <p class="lead fs-12">{{ assetModel.PermitUpto }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Cost</h6>
                <p class="lead fs-12">{{ assetModel.VehicleCost }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Route</h6>
                <p class="lead fs-12">{{ assetModel.Rout }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Engine No</h6>
                <p class="lead fs-12">{{ assetModel.EngineNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Chassis No</h6>
                <p class="lead fs-12">{{ assetModel.ChassisNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Key No</h6>
                <p class="lead fs-12">{{ assetModel.KeyNo }}</p>
              </div>
            </div>
            <hr />

            <ng-container *ngIf="assetModel.Vehicle_Type == 'New'">
              <div class="row mt-2">
                <div class="col-md-12">
                  <h5 style="color: black">New Vehicle</h5>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">DO. No.</h6>
                  <p class="lead fs-12">{{ assetModel.DONo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">DO. Do Date.</h6>
                  <p class="lead fs-12">{{ assetModel.RegistationDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Estimation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.EstimationAmount }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice No.</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice Date</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice Value</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceValue }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer Contact Person</h6>
                  <p class="lead fs-12">{{ assetModel.DealerContactPerson }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer Contact No.</h6>
                  <p class="lead fs-12">{{ assetModel.DealerContactNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Quotation in favour of</h6>
                  <p class="lead fs-12">
                    {{ assetModel.DOIssuedinfavourofName }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Remark</h6>
                  <p class="lead fs-12">{{ assetModel.Remark }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer</h6>
                  <p class="lead fs-12">{{ assetModel.DealorName }}</p>
                </div>
              </div>
              <hr />
            </ng-container>

            <ng-container *ngIf="assetModel.Vehicle_Type == 'Used'">
              <div class="row mt-2">
                <div class="col-md-12">
                  <h5 style="color: black">Used Vehicles</h5>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Done By</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Date</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationAmount }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Type</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Other Valuation Type</h6>
                  <p class="lead fs-12">{{ assetModel.OtherValuationType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Other Valuation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.OtherValuationAmt }}</p>
                </div>
              </div>
              <hr />
            </ng-container>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Property'">
            <div class="row mt-2">
              <div class="col-md-4">
                <h6 class="fs-12">Property Owner Name</h6>
                <p class="lead fs-12">{{ assetModel.CustomerId }}</p>
              </div>
              <div class="col-md-8">
                <h6 class="fs-12">Address Of Property</h6>
                <p class="lead fs-12">{{ assetModel.Address }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Reg State</h6>
                <p class="lead fs-12">{{ assetModel.State }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Reg District</h6>
                <p class="lead fs-12">{{ assetModel.District }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Reg Tehsil</h6>
                <p class="lead fs-12">{{ assetModel.tasil }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Type Of Property</h6>
                <p class="lead fs-12">{{ assetModel.TypeofProperty }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Nature Of Property</h6>
                <p class="lead fs-12">{{ assetModel.NatureofProperty }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Ownership Document</h6>
                <p class="lead fs-12">{{ assetModel.OwnershipDocument }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Ownership Type</h6>
                <p class="lead fs-12">{{ assetModel.OwnershipType }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Unit Of Measurement</h6>
                <p class="lead fs-12">{{ assetModel.UnitofMeasurement }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Total Area</h6>
                <p class="lead fs-12">{{ assetModel.TotalArea }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Constructed Area</h6>
                <p class="lead fs-12">{{ assetModel.ConstructedArea }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Type Of Mortgage</h6>
                <p class="lead fs-12">{{ assetModel.TypeofMortgage }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Mortgage singed by</h6>
                <p class="lead fs-12">{{ assetModel.MortgageSingedBy }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Valution Amount</h6>
                <p class="lead fs-12">{{ assetModel.ValuationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valution Done By</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="
                  assetModel.ValuationDoneByID != '' &&
                  assetModel.ValuationDoneByID == 0
                "
              >
                <h6 class="fs-12">Valution Done By Name</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneByOther }}</p>
              </div>
              <div class="col-md-6">
                <h6 class="fs-12">Valution Remark</h6>
                <p class="lead fs-12">{{ assetModel.ValuationRemark }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Search Report Done By</h6>
                <p class="lead fs-12">{{ assetModel.SearchReportDoneBy }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="
                  assetModel.SearchReportDoneBy != '' &&
                  assetModel.SearchReportDoneBy == 0
                "
              >
                <h6 class="fs-12">Search Report Done By Name</h6>
                <p class="lead fs-12">
                  {{ assetModel.SearchReportDoneByOther }}
                </p>
              </div>
              <div class="col-md-8">
                <h6 class="fs-12">Search Report Remark</h6>
                <p class="lead fs-12">{{ assetModel.SearchReportRemark }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <b style="color: black"> Location </b>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12">Latitude</h6>
                <p class="lead fs-12">{{ assetModel.Latitude }}</p>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12">Longitude</h6>
                <p class="lead fs-12">{{ assetModel.Longitude }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6 p-1">
                <h6 class="fs-12">Valuation Report</h6>
                <p class="lead fs-12">
                  <a
                    href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                      assetModel.ValuationRemarkDoc
                    }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!assetModel.ValuationDocData"
                  >
                    {{ assetModel.ValuationRemarkDoc }}
                  </a>
                </p>
              </div>
              <div class="col-md-6 p-1">
                <h6 class="fs-12">Search Report</h6>
                <p class="lead fs-12">
                  <a
                    href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                      assetModel.SearchReportRemarkDoc
                    }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!assetModel.SearchDocData"
                  >
                    {{ assetModel.SearchReportRemarkDoc }}
                  </a>
                </p>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Gold'">
            <ng-container *ngIf="assetModel.Item && assetModel.ItemShow">
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Item Name</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemName }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Gold Type</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Qty</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Qty }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Purity CT</h6>
                  <p class="lead fs-12">{{ assetModel.Item.PurityCT }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Gross Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.GrossWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Stone Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.StoneWt }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Net Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.OtherWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Rate PerCT</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationRate_PerCT }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Amount</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationAmount }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 1</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                        assetModel.Item.Image1
                      }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image1 }}
                    </a>
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 2</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                        assetModel.Item.Image2
                      }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image2 }}
                    </a>
                  </p>
                </div>
              </div>
            </ng-container>

            <div class="table-responsive mt-4 mb-4">
              <mat-table [dataSource]="dataAssetItemSource">
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemType">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemType
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Qty">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Qty</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Qty
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PurityCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Purity CT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.PurityCT
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="GrossWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Gross Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.GrossWt
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="StoneWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Stone Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.StoneWt
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="OtherWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Other Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.OtherWt
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationRate_PerCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Rate PerCT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ValulationRate_PerCT
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationAmount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Valution Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ValulationAmount
                  }}</mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedAssetItemColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAssetItemColumns"
                ></mat-row>
              </mat-table>

              <div class="row m-0 mt-4" *ngIf="assetTotal">
                <div class="col-md-3">Total Qty:{{ assetTotal.Qty }}</div>
                <div class="col-md-3">
                  Total Gross Wt:{{ assetTotal.GrossWt }}
                </div>
                <div class="col-md-3">
                  Total Stone Wt:{{ assetTotal.StoneWt }}
                </div>
                <div class="col-md-3">
                  Total Net Wt:{{ assetTotal.OtherWt }}
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Valution Done By</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valution Date</h6>
                <p class="lead fs-12">{{ assetModel.ValulationDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Total Valution Amount</h6>
                <p class="lead fs-12">{{ assetModel.TotalValulationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuer PhoneNo</h6>
                <p class="lead fs-12">{{ assetModel.ValuerPhoneNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuer FirmName</h6>
                <p class="lead fs-12">{{ assetModel.ValuerFirmName }}</p>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Consumer Durable'">
            <ng-container *ngIf="assetModel.Item && assetModel.ItemShow">
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Item Name</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemName }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item Category</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemCategory }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Qty</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemQty }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item Make</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemMake }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item SeralNo</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemSeralNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Amount</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Amount }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <h6 class="fs-12">Remark</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Remark }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Other Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.OtherWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Rate PerCT</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationRate_PerCT }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Amount</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationAmount }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 1</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{
                        ApplicationDetail.ApplicationNo
                      }}/{{ assetModel.Item.Image1 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image1 }}
                    </a>
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 2</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{
                        ApplicationDetail.ApplicationNo
                      }}/{{ assetModel.Item.Image2 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image2Data"
                    >
                      {{ assetModel.Item.Image2 }}
                    </a>
                  </p>
                </div>
              </div>
            </ng-container>
            <div class="table-responsive mt-4 mb-4">
              <mat-table [dataSource]="dataAssetItemSource">
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemCategory">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Category</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemCategory
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemMake">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Make</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemMake
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemQty">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Qty</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemQty
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemSeralNo">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item SeralNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ItemSeralNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Amount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Amount
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Remark">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Remark</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Remark
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="OtherWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Other Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.OtherWt
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationRate_PerCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Rate PerCT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ValulationRate_PerCT
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationAmount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ValulationAmount
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                  <mat-header-cell
                    class="grid-header j-c-center"
                    style="max-width: 60px"
                    *matHeaderCellDef
                    >Action</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell j-c-center"
                    style="max-width: 60px"
                  >
                    <!-- <a href="javascript:void(0)" (click)="editAssetItem(row,i)" *ngIf="!isViewMode" style="margin-right: 10px;">Edit</a>
                    <a href="javascript:void(0)" (click)="editAssetItem(row,i)" *ngIf="isViewMode" style="margin-right: 10px;">View</a>
                    <a href="javascript:void(0)" (click)="deleteAssetItem(i)" *ngIf="!isViewMode">Delete</a> -->
                    <a href="javascript:void(0)" style="margin-right: 10px"
                      >Edit</a
                    >
                    <a href="javascript:void(0)" style="margin-right: 10px"
                      >View</a
                    >
                    <a href="javascript:void(0)">Delete</a>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedAssetItemColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAssetItemColumns"
                ></mat-row>
              </mat-table>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <h6 class="fs-12">Remark</h6>
                <p class="lead fs-12">{{ assetModel.Remark }}</p>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="SOAModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          SOA Detail
        </h6>
        <button
          type="button"
          (click)="onCloseSOAModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="4">Loan Details</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Address</th>
            </tr>
          </thead>
          <tr>
            <td>1</td>
            <td>Test Kumar</td>
            <td>9852000000</td>
            <td>Jaipur</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanStatementPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Statement
        </h6>
        <button
          type="button"
          (click)="onCloseLoanStatementPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="btn font-size-12 button-btn ml-2"
              (click)="onDocsPrint('LoanStatementcontent', 'LoanStatement')"
            >
              Print
            </button>
          </div>
        </div>
        <div class="col-md-10 offset-1" id="LoanStatementcontent">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>
          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>
          <!-- <div class="row m-0 mt-2"> -->
          <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Loan Account Statement for :
            {{
              ApplicationDetail != undefined ? ApplicationDetail.LoanAcNo : ""
            }}
            ({{
              customerData != undefined
                ? customerData[0].GenderAge.split(",")[0] == "Male"
                  ? "Mr"
                  : "Ms"
                : ""
            }}
            {{ customerData != undefined ? customerData[0].Customer : "" }})
          </div>

          <!-- </div> -->

          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="color: white; font-family: 'Times New Roman', Times, serif"
            >
              <h5>
                Loan Account Information as on -
                {{ Cur_Date | date : "dd/MM/yyyy" }}
              </h5>
            </div>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Loan Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Application No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationNo
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Application Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationCreateOn
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Loan No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanAcNo
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Loan_Date
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Branch:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Branch
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Product:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Product
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Purpose:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanPurpose
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Close Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CloseDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>
          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Customer Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Name</B></h6>
                </th>
                <th>
                  <h6><B>Type</B></h6>
                </th>
                <th>
                  <h6><B>Gender/Age</B></h6>
                </th>
                <th>
                  <h6><B>Phone No</B></h6>
                </th>
                <th>
                  <h6><B>Present Address</B></h6>
                </th>
              </tr>
              <tr *ngFor="let data of customerData; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ data.Customer }}</h6>
                </td>
                <td>
                  <h6>
                    {{
                      data.CustomerType == "Hirer"
                        ? "Borrower"
                        : data.CustomerType
                    }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.GenderAge }}</h6>
                </td>
                <td>
                  <h6 *ngIf="currentUser.IsMasking">
                    {{ data.PhoneNo | mask }}
                  </h6>

                  <h6 *ngIf="!currentUser.IsMasking">
                    {{ data.PhoneNo }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.Present_Address }}</h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Finance Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Asset Cost:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.AssetCost.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Net Finance:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.NetFinance.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Interest Amount:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.InterestAmt.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Agreement Value:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.AgreementValue.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>LTV:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LTV.toFixed(2)
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>Margin:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Margin.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6>
                    <B
                      >Tenure (
                      {{
                        ApplicationDetail != undefined ? TenureName : ""
                      }}):</B
                    >
                  </h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Tenure
                        : ""
                    }}
                    {{ ApplicationDetail != undefined ? TenureName : "" }}
                  </h6>
                </td>
                <th>
                  <h6><B>No of Installment:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.No_Of_Instl
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Adv. Installment:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Adv_Instl
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>ROI:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Flat_Rate
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>Case IRR:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Case_IRR.toFixed(2)
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>First EMI Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.FirstEMIDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Close Date:</B></h6>
                </th>
                <td colspan="3">
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CloseDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr *ngIf="IRR_CalculateBy == 'STEP_EMI'">
                <td colspan="6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          colspan="6"
                          style="background: #4dc3a3 !important; padding: 5px"
                        >
                          <B>Step EMI Details:</B>
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      <th class="text-right">
                        <h6><B>#</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>From EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>To EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>EMI Amount</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>No of EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>Total Amount</B></h6>
                      </th>
                    </tr>
                    <tr *ngFor="let StepData of stepEMI; let i = index">
                      <td>
                        <h6>{{ i + 1 }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.FromEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.ToEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.EMI_Amount }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.NoOfInstl }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">
                          {{ StepData.TotalAmount }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="4" class="text-right">
                        <h6><B>Total</B></h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalEmi }}</B>
                        </h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalAmount }}</B>
                        </h6>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div
            class="table-responsive mt-2"
            *ngIf="
              ApplicationDetail?.CloseDate == null ||
              ApplicationDetail?.CloseDate == '' ||
              ApplicationDetail?.CloseDate == undefined
            "
          >
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>EMI Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-center">
                  <h6><B>Total EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Mature EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Remaining EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Received EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Overdue EMI</B></h6>
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>EMI</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined ? EMIDetails.Remaining_EMI : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.Received_EMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>Amount</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.TotalEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.MatureEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Remaining_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI_Amount -
                          EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2" *ngIf="withAccountSummary">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Account Summary:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Account Head</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Debit</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Credit</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Balance</B></h6>
                </th>
              </tr>
              <tr *ngFor="let Acc_Head of AccHeadSummary; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ Acc_Head.ChagesHead }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeDR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeCR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeTotal }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important">&nbsp;</th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateChargesTranTypeDR("OD") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateChargesTranTypeCR("OD") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <!-- <B>{{ calculateChargesTranTypeTotal("OD") }}</B> -->
                  </h6>
                </th>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Installment Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Voucher Date</B></h6>
                </th>
                <th>
                  <h6><B>Voucher No</B></h6>
                </th>
                <th>
                  <h6><B>A/C Head</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>DR Amount</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>CR Amount</B></h6>
                </th>
                <th class="text-right" style="width: 15%">
                  <h6><B>Balance</B></h6>
                </th>
                <th style="width: 20%">
                  <h6><B>Narration</B></h6>
                </th>
              </tr>
              <tr *ngFor="let vdata of FileCheckingList; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.VoucherDate }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.Voucher_No }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.ChagesHead }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeDR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeCR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeTotal }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.Narration }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important" colspan="3">
                  &nbsp;
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeDR() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeCR() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeTotal() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important">&nbsp;</th>
              </tr>
            </table>
            <div
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date : {{ Cur_Date | date : "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanSOAPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">SOA</h6>
        <button
          type="button"
          (click)="onCloseLoanSOAPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="btn font-size-12 button-btn ml-2"
              (click)="onDocsPrint('LoanSOAcontent', 'SOA')"
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="LoanSOAcontent">
          <div>
            <div>
              <span style="float: right">
                <img
                  src="{{ EmployeeProfileBaseUrl }}logo.png"
                  alt="logo"
                  style="width: 100px; display: inline"
                />
              </span>
              <div
                style="
                  clear: both;
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>
          <!-- <div class="row m-0 mt-2"> -->
          <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Loan Account Statement for :
            {{
              ApplicationDetail != undefined ? ApplicationDetail.LoanAcNo : ""
            }}
            ({{
              customerData != undefined
                ? customerData[0].GenderAge.split(",")[0] == "Male"
                  ? "Mr"
                  : "Ms"
                : ""
            }}
            {{ customerData != undefined ? customerData[0].Customer : "" }})
          </div>

          <!-- </div> -->

          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <h5>
                Loan Account Information as on -
                {{ Cur_Date | date : "dd/MM/yyyy" }}
              </h5>
            </div>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Loan Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Application No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationNo
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Application Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationCreateOn
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Loan No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanAcNo
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Loan_Date
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Branch:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Branch
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Product:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Product
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Purpose:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanPurpose
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Close Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CloseDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>
          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Customer Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Name</B></h6>
                </th>
                <th>
                  <h6><B>Type</B></h6>
                </th>
                <th>
                  <h6><B>Gender/Age</B></h6>
                </th>
                <th>
                  <h6><B>Phone No</B></h6>
                </th>
                <th>
                  <h6><B>Present Address</B></h6>
                </th>
              </tr>
              <tr *ngFor="let data of customerData; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ data.Customer }}</h6>
                </td>
                <td>
                  <h6>
                    {{
                      data.CustomerType == "Hirer"
                        ? "Borrower"
                        : data.CustomerType
                    }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.GenderAge }}</h6>
                </td>
                <td>
                  <h6 *ngIf="currentUser.IsMasking">
                    {{ data.PhoneNo | mask }}
                  </h6>

                  <h6 *ngIf="!currentUser.IsMasking">
                    {{ data.PhoneNo }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.Present_Address }}</h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Financial Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Asset Cost:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.AssetCost.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Net Finance:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.NetFinance.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Interest Amount:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.InterestAmt.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Agreement Value:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.AgreementValue.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>LTV:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LTV.toFixed(2)
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>Margin:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Margin.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6>
                    <B>Tenure ({{ TenureName }}):</B>
                  </h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Tenure
                        : ""
                    }}
                    {{ TenureName }}
                  </h6>
                </td>
                <th>
                  <h6><B>No of Installment:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.No_Of_Instl
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Adv. Installment:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Adv_Instl
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>ROI:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Flat_Rate
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>Case IRR:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Case_IRR.toFixed(2)
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>First EMI Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.FirstEMIDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Close Date:</B></h6>
                </th>
                <td colspan="3">
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CloseDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr *ngIf="IRR_CalculateBy == 'STEP_EMI'">
                <td colspan="6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          colspan="6"
                          style="background: #4dc3a3 !important; padding: 5px"
                        >
                          <B>Step EMI Details:</B>
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      <th class="text-right">
                        <h6><B>#</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>From EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>To EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>EMI Amount</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>No of EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>Total Amount</B></h6>
                      </th>
                    </tr>
                    <tr *ngFor="let StepData of stepEMI; let i = index">
                      <td>
                        <h6>{{ i + 1 }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.FromEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.ToEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.EMI_Amount }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.NoOfInstl }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">
                          {{ StepData.TotalAmount }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="4" class="text-right">
                        <h6><B>Total</B></h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalEmi }}</B>
                        </h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalAmount }}</B>
                        </h6>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div
            class="table-responsive mt-2"
            *ngIf="
              ApplicationDetail?.CloseDate == null ||
              ApplicationDetail?.CloseDate == '' ||
              ApplicationDetail?.CloseDate == undefined
            "
          >
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>EMI Detail:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-center">
                  <h6><B>Total EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Mature EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Remaining EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Received EMI</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Overdue EMI</B></h6>
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>EMI</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined ? EMIDetails.Remaining_EMI : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.Received_EMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>Amount</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.TotalEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.MatureEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Remaining_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI_Amount -
                          EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Account Summary:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Account Head</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Debit</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Credit</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>Balance</B></h6>
                </th>
              </tr>
              <tr *ngFor="let Acc_Head of AccHeadSummary; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ Acc_Head.ChagesHead }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeDR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeCR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Acc_Head.TranTypeTotal }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important">&nbsp;</th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateChargesTranTypeDR("OD") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateChargesTranTypeCR("OD") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateChargesTranTypeTotal("OD") }}</B>
                  </h6>
                </th>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Account Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Voucher Date</B></h6>
                </th>
                <th>
                  <h6><B>Voucher No</B></h6>
                </th>
                <th>
                  <h6><B>A/C Head</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>DR Amount</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>CR Amount</B></h6>
                </th>
                <th class="text-right" style="width: 15%">
                  <h6><B>Balance</B></h6>
                </th>
                <th style="width: 20%">
                  <h6><B>Narration</B></h6>
                </th>
              </tr>
              <tr *ngFor="let Accdata of AccHeadDetails; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ Accdata.VoucherDate }}</h6>
                </td>
                <td>
                  <h6>{{ Accdata.Voucher_No }}</h6>
                </td>
                <td>
                  <h6>{{ Accdata.ChagesHead }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Accdata.TranTypeDR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Accdata.TranTypeCR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ Accdata.TranTypeTotal }}</h6>
                </td>
                <td>
                  <h6>{{ Accdata.Narration }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important" colspan="3">
                  &nbsp;
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ totaltypewise("TranTypeDR") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ totaltypewise("TranTypeCR") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ totaltypewise("TranTypeTotal") }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important">&nbsp;</th>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>OD Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Voucher Date</B></h6>
                </th>
                <th>
                  <h6><B>Voucher No</B></h6>
                </th>
                <th>
                  <h6><B>OS Balance</B></h6>
                </th>
                <th>
                  <h6><B>Days</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>ODI Due</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>ODI Credit</B></h6>
                </th>
                <th class="text-right" style="width: 15%">
                  <h6><B>ODI Balance</B></h6>
                </th>
              </tr>
              <tr *ngFor="let ODData of ODDetails; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.VoucherDate }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.Voucher_No }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.TT }}</h6>
                </td>

                <td>
                  <h6>{{ ODData.DaysLate }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODDebit }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODCredit }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODTotal }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important" colspan="4">
                  &nbsp;
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODInterest() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODCredit() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODTotal() }}</B>
                  </h6>
                </th>
              </tr>
            </table>
            <div
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date : {{ Cur_Date | date : "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanSOALegalPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          SOA Legal
        </h6>
        <button
          type="button"
          (click)="onCloseLoanSOALegalPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="btn font-size-12 button-btn ml-2"
              (click)="onDocsPrint('LoanSOALegalcontent', 'SOA Legal')"
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="LoanSOALegalcontent">
          <div>
            <div>
              <span style="float: right">
                <img
                  src="{{ EmployeeProfileBaseUrl }}logo.png"
                  alt="logo"
                  style="width: 100px; display: inline"
                />
              </span>
              <div
                style="
                  clear: both;
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>
                  <B>{{ currentUser.CompanyName }}</B>
                </h5>
              </div>

              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>
                  <B>Registered Office : {{ currentUser.CompanyAddress }}</B>
                </h6>
              </div>

              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>
                  <B
                    >Corporate Office:{{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CompanyCorporateOfficeAddress
                        : ""
                    }}
                  </B>
                </h6>
              </div>
              <!-- <div
                  style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                  "
                >
                  <h6>
                    <B
                      >Branch Address:{{
                        ApplicationDetail != undefined
                          ? ApplicationDetail.Branch_Address
                          : ""
                      }}</B
                    >
                  </h6>
                </div> -->
            </div>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20;
            "
          >
            <div
              style="
                text-align: center;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              <B>
                Loan Account Statement for :
                {{
                  ApplicationDetail != undefined
                    ? ApplicationDetail.LoanAcNo
                    : ""
                }}
                ({{
                  customerData != undefined
                    ? customerData[0].GenderAge.split(",")[0] == "Male"
                      ? "Mr"
                      : "Ms"
                    : ""
                }}
                {{ customerData != undefined ? customerData[0].Customer : "" }})
                as on - {{ Cur_Date | date : "dd/MM/yyyy" }}
              </B>
            </div>

            <div
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              <B> Issue Date : {{ Cur_Date | date : "dd/MM/yyyy" }}</B>
            </div>
          </div>

          <!-- <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="font-family: 'Times New Roman', Times, serif; color: white"
            >
              <h5>
                Loan Account Information as on -
                {{ Cur_Date | date : "dd/MM/yyyy" }}
              </h5>
            </div>
          </div> -->

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Loan Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Application No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationNo
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Application Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ApplicationCreateOn
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Loan No:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanAcNo
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Loan_Date
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Branch:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Branch
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Product:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Product
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>Loan Purpose:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.LoanPurpose
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Close Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.CloseDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>
          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Customer Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Name</B></h6>
                </th>
                <th>
                  <h6><B>Type</B></h6>
                </th>
                <th>
                  <h6><B>Gender/Age</B></h6>
                </th>
                <th>
                  <h6><B>Phone No</B></h6>
                </th>
                <th>
                  <h6><B>Present Address</B></h6>
                </th>
              </tr>
              <tr *ngFor="let data of customerData; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ data.Customer }}</h6>
                </td>
                <td>
                  <h6>
                    {{
                      data.CustomerType == "Hirer"
                        ? "Borrower"
                        : data.CustomerType
                    }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.GenderAge }}</h6>
                </td>
                <td>
                  <h6 *ngIf="currentUser.IsMasking">
                    {{ data.PhoneNo | mask }}
                  </h6>

                  <h6 *ngIf="!currentUser.IsMasking">
                    {{ data.PhoneNo }}
                  </h6>
                </td>
                <td>
                  <h6>{{ data.Present_Address }}</h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Financial Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>Loan Amount</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.NetFinance.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Interest Amount:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.InterestAmt.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Agreement Value:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.AgreementValue.toFixed(2)
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6>
                    <B>Tenure ({{ TenureName }})</B>
                  </h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Tenure
                        : ""
                    }}
                    {{ TenureName }}
                  </h6>
                </td>
                <th>
                  <h6><B>Total Installments:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.No_Of_Instl
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Adv. Installment:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Adv_Instl
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6><B>ROI:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.Flat_Rate
                        : ""
                    }}
                    %
                  </h6>
                </td>
                <th>
                  <h6><B>First Installment Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.FirstEMIDate
                        : ""
                    }}
                  </h6>
                </td>
                <th>
                  <h6><B>Expiry Date:</B></h6>
                </th>
                <td>
                  <h6>
                    {{
                      ApplicationDetail != undefined
                        ? ApplicationDetail.ExpiryDate
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr></tr>

              <!-- <tr *ngIf="IRR_CalculateBy == 'STEP_EMI'">
                <td colspan="6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          colspan="6"
                          style="background: #4dc3a3 !important; padding: 5px"
                        >
                          <B>Step EMI Details:</B>
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      <th class="text-right">
                        <h6><B>#</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>From EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>To EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>EMI Amount</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>No of EMI</B></h6>
                      </th>
                      <th class="text-right">
                        <h6><B>Total Amount</B></h6>
                      </th>
                    </tr>
                    <tr *ngFor="let StepData of stepEMI; let i = index">
                      <td>
                        <h6>{{ i + 1 }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.FromEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.ToEMI }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.EMI_Amount }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">{{ StepData.NoOfInstl }}</h6>
                      </td>
                      <td class="text-right">
                        <h6 class="form-control1">
                          {{ StepData.TotalAmount }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="4" class="text-right">
                        <h6><B>Total</B></h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalEmi }}</B>
                        </h6>
                      </th>
                      <th class="text-right" class="text-right">
                        <h6 class="form-control1">
                          <B>{{ stepEmiData.totalAmount }}</B>
                        </h6>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr> -->
            </table>
          </div>

          <div
            class="table-responsive mt-2"
            *ngIf="
              ApplicationDetail?.CloseDate == null ||
              ApplicationDetail?.CloseDate == '' ||
              ApplicationDetail?.CloseDate == undefined
            "
          >
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Installment Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-center">
                  <h6><B>Total Installment</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Mature Installment</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Remaining Installment</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Received Installment</B></h6>
                </th>
                <th class="text-center">
                  <h6><B>Overdue Installment</B></h6>
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>No. of Installment</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined ? EMIDetails.Remaining_EMI : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{ EMIDetails != undefined ? EMIDetails.Received_EMI : "" }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
              <tr>
                <th class="text-center">
                  <h6><B>Amount</B></h6>
                </th>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.TotalEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined ? EMIDetails.MatureEMI_Amount : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Remaining_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
                <td class="text-center">
                  <h6>
                    &#8377;
                    {{
                      EMIDetails != undefined
                        ? EMIDetails.MatureEMI_Amount -
                          EMIDetails.Received_EMI_Amount
                        : ""
                    }}
                  </h6>
                </td>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Disbursement Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th style="width: 95px !important">
                  <h6><B>Date</B></h6>
                </th>
                <th>
                  <h6><B>Category</B></h6>
                </th>
                <th>
                  <h6><B>DR</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>CR</B></h6>
                </th>
                <th>
                  <h6><B>Narration</B></h6>
                </th>
                <th>
                  <h6><B>Payment Detail </B></h6>
                </th>
              </tr>
              <tr
                *ngFor="
                  let DisbursementDetail of DisbursementDetails;
                  let i = index
                "
              >
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ DisbursementDetail.Voucher_Date }}</h6>
                </td>
                <td>
                  <h6>{{ DisbursementDetail.ChargeHeadCategory }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ DisbursementDetail.DRAmount }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ DisbursementDetail.CRAmount }}</h6>
                </td>
                <td>
                  <h6>
                    {{ DisbursementDetail.Voucher_Narration }}
                  </h6>
                </td>

                <td>
                  <h6>{{ DisbursementDetail.PaymentDetail }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important">
                  <h6><B></B></h6>
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B></B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>Total:</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ totalDRAmount.toFixed(2) }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ totalCRAmount.toFixed(2) }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important"></th>
                <th style="background: #4dc3a3 !important"></th>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>Installment Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Voucher Date</B></h6>
                </th>
                <th>
                  <h6><B>Voucher No</B></h6>
                </th>
                <th>
                  <h6><B>A/C Head</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>DR Amount</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>CR Amount</B></h6>
                </th>
                <th class="text-right" style="width: 15%">
                  <h6><B>Balance</B></h6>
                </th>
                <th style="width: 20%">
                  <h6><B>Narration</B></h6>
                </th>
              </tr>
              <tr *ngFor="let vdata of FileCheckingList; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.VoucherDate }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.Voucher_No }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.ChagesHead }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeDR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeCR }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ vdata.TranTypeTotal }}</h6>
                </td>
                <td>
                  <h6>{{ vdata.Narration }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important" colspan="3">
                  &nbsp;
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeDR() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeCR() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateTranTypeTotal() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important">&nbsp;</th>
              </tr>
            </table>
          </div>

          <div class="table-responsive mt-2" *ngIf="showLoanOD">
            <table
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th colspan="8" style="background: #4dc3a3 !important">
                    <h5><B>OD Details:</B></h5>
                  </th>
                </tr>
              </thead>
              <tr>
                <th>
                  <h6><B>#</B></h6>
                </th>
                <th>
                  <h6><B>Voucher Date</B></h6>
                </th>
                <th>
                  <h6><B>Voucher No</B></h6>
                </th>
                <th>
                  <h6><B>OS Balance</B></h6>
                </th>
                <th>
                  <h6><B>Days</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>ODI Due</B></h6>
                </th>
                <th class="text-right">
                  <h6><B>ODI Credit</B></h6>
                </th>
                <th class="text-right" style="width: 15%">
                  <h6><B>ODI Balance</B></h6>
                </th>
              </tr>
              <tr *ngFor="let ODData of ODDetails; let i = index">
                <td>
                  <h6>{{ i + 1 }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.VoucherDate }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.Voucher_No }}</h6>
                </td>
                <td>
                  <h6>{{ ODData.TT }}</h6>
                </td>

                <td>
                  <h6>{{ ODData.DaysLate }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODDebit }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODCredit }}</h6>
                </td>
                <td class="text-right">
                  <h6>{{ ODData.ODTotal }}</h6>
                </td>
              </tr>
              <tr>
                <th style="background: #4dc3a3 !important" colspan="3">
                  &nbsp;
                </th>
                <th style="background: #4dc3a3 !important">
                  <h6><B>Total</B></h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODDays() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODInterest() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODCredit() }}</B>
                  </h6>
                </th>
                <th style="background: #4dc3a3 !important" class="text-right">
                  <h6>
                    <B>{{ calculateODTotal() }}</B>
                  </h6>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Rashmi-->
<table
  id="excel-table"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th colspan="8" style="text-align: center">
      {{ currentUser.CompanyName }}
    </th>
  </tr>
  <tr>
    <th colspan="8">Address : {{ currentUser.CompanyAddress }}</th>
  </tr>
  <tr>
    <th colspan="6" style="text-align: left">
      Loan Account Statement for :
      {{ ApplicationDetail != undefined ? ApplicationDetail.LoanAcNo : "" }} ({{
        customerData != undefined
          ? customerData[0].GenderAge.split(",")[0] == "Male"
            ? "Mr"
            : "Ms"
          : ""
      }}
      {{ customerData != undefined ? customerData[0].Customer : "" }})
    </th>
    <th colspan="2" style="text-align: right">
      Issue Date : {{ Cur_Date | date : "dd/MM/yyyy" }}
    </th>
  </tr>
  <tr>
    <th colspan="8">
      Loan Account Information as on - {{ Cur_Date | date : "dd/MM/yyyy" }}
    </th>
  </tr>

  <thead>
    <tr>
      <th colspan="8" style="background: #4dc3a3 !important; text-align: left">
        <B>Loan Detail:</B>
      </th>
    </tr>
  </thead>
  <tr>
    <th style="text-align: left"><B>Application No:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined ? ApplicationDetail.ApplicationNo : ""
      }}
    </td>
    <th style="text-align: left"><B>Application Date:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.ApplicationCreateOn
          : ""
      }}
    </td>
    <th style="text-align: left"><B>Loan No:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.LoanAcNo : "" }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left"><B>Loan Date:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Loan_Date : "" }}
    </td>
    <th style="text-align: left"><B>Branch:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Branch : "" }}
    </td>
    <th style="text-align: left"><B>Product:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Product : "" }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left"><B>Loan Purpose:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.LoanPurpose : "" }}
    </td>
    <th style="text-align: left"><B>Expiry Date:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.ExpiryDate : "" }}
    </td>
    <th style="text-align: left"><B>Close Date:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.CloseDate : "" }}
    </td>
  </tr>
  <tr></tr>
  <thead>
    <tr>
      <th colspan="8" style="background: #4dc3a3 !important; text-align: left">
        <B>Customer Detail:</B>
      </th>
    </tr>
  </thead>
  <tr>
    <th style="text-align: left"><B>#</B></th>
    <th style="text-align: left"><B>Name</B></th>
    <th style="text-align: left"><B>Type</B></th>
    <th style="text-align: left"><B>Gender/Age</B></th>
    <th style="text-align: left"><B>Phone No</B></th>
    <th style="text-align: left" colspan="3"><B>Present Address</B></th>
  </tr>
  <tr *ngFor="let data of customerData; let i = index">
    <td style="text-align: left">{{ i + 1 }}</td>
    <td style="text-align: left">{{ data.Customer }}</td>
    <td style="text-align: left">
      {{ data.CustomerType == "Hirer" ? "Borrower" : data.CustomerType }}
    </td>
    <td style="text-align: left">{{ data.GenderAge }}</td>
    <td style="text-align: left">{{ data.PhoneNo }}</td>
    <td style="text-align: left" colspan="3">{{ data.Present_Address }}</td>
  </tr>
  <tr></tr>
  <thead>
    <tr>
      <th colspan="8" style="background: #4dc3a3 !important; text-align: left">
        <B>Finance Detail:</B>
      </th>
    </tr>
  </thead>
  <tr>
    <th style="text-align: left"><B>Asset Cost:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.AssetCost.toFixed(2)
          : ""
      }}
    </td>
    <th style="text-align: left"><B>Net Finance:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.NetFinance.toFixed(2)
          : ""
      }}
    </td>
    <th style="text-align: left"><B>Interest Amount:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.InterestAmt.toFixed(2)
          : ""
      }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left"><B>Agreement Value:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.AgreementValue.toFixed(2)
          : ""
      }}
    </td>
    <th style="text-align: left"><B>LTV:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined ? ApplicationDetail.LTV.toFixed(2) : ""
      }}
      %
    </td>
    <th style="text-align: left"><B>Margin:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.Margin.toFixed(2)
          : ""
      }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left">
      <B>Tenure ( {{ ApplicationDetail != undefined ? TenureName : "" }}):</B>
    </th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Tenure : "" }}
      {{ TenureName }}
    </td>
    <th style="text-align: left"><B>No of Installment:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.No_Of_Instl : "" }}
    </td>
    <th style="text-align: left"><B>Adv. Installment:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Adv_Instl : "" }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left"><B>ROI:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.Flat_Rate : "" }} %
    </td>
    <th style="text-align: left"><B>Case IRR:</B></th>
    <td style="text-align: left">
      {{
        ApplicationDetail != undefined
          ? ApplicationDetail.Case_IRR.toFixed(2)
          : ""
      }}
      %
    </td>
    <th style="text-align: left"><B>First EMI Date:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.FirstEMIDate : "" }}
    </td>
  </tr>
  <tr>
    <th style="text-align: left"><B>Expiry Date:</B></th>
    <td style="text-align: left">
      {{ ApplicationDetail != undefined ? ApplicationDetail.ExpiryDate : "" }}
    </td>
    <th style="text-align: left"><B>Close Date:</B></th>
    <td style="text-align: left" colspan="3">
      {{ ApplicationDetail != undefined ? ApplicationDetail.CloseDate : "" }}
    </td>
  </tr>
  <tr *ngIf="IRR_CalculateBy == 'STEP_EMI'">
    <td colspan="6">
      <table class="table">
        <thead>
          <tr>
            <th
              colspan="6"
              style="background: #4dc3a3 !important; text-align: left"
            >
              <B>Step EMI Details:</B>
            </th>
          </tr>
        </thead>
        <tr>
          <th style="text-align: left"><B>#</B></th>
          <th style="text-align: left"><B>From EMI</B></th>
          <th style="text-align: left"><B>To EMI</B></th>
          <th style="text-align: left"><B>EMI Amount</B></th>
          <th style="text-align: left"><B>No of EMI</B></th>
          <th style="text-align: left"><B>Total Amount</B></th>
        </tr>
        <tr *ngFor="let StepData of stepEMI; let i = index">
          <td style="text-align: left">{{ i + 1 }}</td>
          <td style="text-align: left">{{ StepData.FromEMI }}</td>
          <td style="text-align: left">{{ StepData.ToEMI }}</td>
          <td style="text-align: left">{{ StepData.EMI_Amount }}</td>
          <td style="text-align: left">{{ StepData.NoOfInstl }}</td>
          <td style="text-align: left">{{ StepData.TotalAmount }}</td>
        </tr>
        <tr>
          <th colspan="4" style="text-align: left"><B>Total</B></th>
          <th style="text-align: left">
            <B>{{ stepEmiData.totalEmi }}</B>
          </th>
          <th style="text-align: left">
            <B>{{ stepEmiData.totalAmount }}</B>
          </th>
        </tr>
      </table>
    </td>
  </tr>
  <tr></tr>
  <thead
    *ngIf="
      ApplicationDetail?.CloseDate == null ||
      ApplicationDetail?.CloseDate == '' ||
      ApplicationDetail?.CloseDate == undefined
    "
  >
    <tr>
      <th colspan="8" style="background: #4dc3a3 !important; text-align: left">
        <B>EMI Detail:</B>
      </th>
    </tr>
  </thead>
  <tr
    *ngIf="
      ApplicationDetail?.CloseDate == null ||
      ApplicationDetail?.CloseDate == '' ||
      ApplicationDetail?.CloseDate == undefined
    "
  >
    <th style="text-align: left">EMI</th>
    <th style="text-align: left"><B>Total EMI</B></th>
    <th style="text-align: left"><B>Mature EMI</B></th>
    <th style="text-align: left"><B>Remaining EMI</B></th>
    <th style="text-align: left"><B>Received EMI</B></th>
    <th style="text-align: left"><B>Overdue EMI</B></th>
  </tr>
  <tr
    *ngIf="
      ApplicationDetail?.CloseDate == null ||
      ApplicationDetail?.CloseDate == '' ||
      ApplicationDetail?.CloseDate == undefined
    "
  >
    <th style="text-align: left"><B>EMI</B></th>
    <td style="text-align: center">
      {{ EMIDetails != undefined ? EMIDetails.TotalEMI : "" }}
    </td>
    <td style="text-align: center">
      {{ EMIDetails != undefined ? EMIDetails.MatureEMI : "" }}
    </td>
    <td style="text-align: center">
      {{ EMIDetails != undefined ? EMIDetails.Remaining_EMI : "" }}
    </td>
    <td style="text-align: center">
      {{ EMIDetails != undefined ? EMIDetails.Received_EMI : "" }}
    </td>
    <td style="text-align: center">
      {{
        EMIDetails != undefined
          ? EMIDetails.MatureEMI - EMIDetails.Received_EMI
          : ""
      }}
    </td>
  </tr>
  <tr
    *ngIf="
      ApplicationDetail?.CloseDate == null ||
      ApplicationDetail?.CloseDate == '' ||
      ApplicationDetail?.CloseDate == undefined
    "
  >
    <th style="text-align: left"><B>Amount</B></th>
    <td>
      {{ EMIDetails != undefined ? EMIDetails.TotalEMI_Amount : "" }}
    </td>
    <td>
      {{ EMIDetails != undefined ? EMIDetails.MatureEMI_Amount : "" }}
    </td>
    <td>
      {{ EMIDetails != undefined ? EMIDetails.Remaining_EMI_Amount : "" }}
    </td>
    <td>
      {{ EMIDetails != undefined ? EMIDetails.Received_EMI_Amount : "" }}
    </td>
    <td>
      {{
        EMIDetails != undefined
          ? EMIDetails.MatureEMI_Amount - EMIDetails.Received_EMI_Amount
          : ""
      }}
    </td>
  </tr>
  <tr></tr>
  <thead>
    <tr>
      <th colspan="8" style="background: #4dc3a3 !important; text-align: left">
        <B>Installment Details:</B>
      </th>
    </tr>
  </thead>
  <tr>
    <th style="text-align: left"><B>#</B></th>
    <th style="text-align: left"><B>Voucher Date</B></th>
    <th style="text-align: left"><B>Voucher No</B></th>
    <th style="text-align: left"><B>A/C Head</B></th>
    <th style="text-align: left"><B>DR Amount</B></th>
    <th style="text-align: left"><B>CR Amount</B></th>
    <th style="text-align: left"><B>Balance</B></th>
    <th style="text-align: left"><B>Narration</B></th>
  </tr>
  <tr *ngFor="let vdata of FileCheckingList; let i = index">
    <td style="text-align: left">{{ i + 1 }}</td>
    <td style="text-align: left">{{ vdata.VoucherDate }}</td>
    <td style="text-align: left">{{ vdata.Voucher_No }}</td>
    <td style="text-align: left">{{ vdata.ChagesHead }}</td>
    <td style="text-align: left">{{ vdata.TranTypeDR }}</td>
    <td style="text-align: left">{{ vdata.TranTypeCR }}</td>
    <td style="text-align: left">{{ vdata.TranTypeTotal }}</td>
    <td style="text-align: left">{{ vdata.Narration }}</td>
  </tr>
  <tr>
    <th style="background: #4dc3a3 !important; text-align: left" colspan="3">
      &nbsp;
    </th>
    <th style="background: #4dc3a3 !important; text-align: left">
      <B>Total</B>
    </th>
    <th style="background: #4dc3a3 !important; text-align: left">
      <B>{{ calculateTranTypeDR() }}</B>
    </th>
    <th style="background: #4dc3a3 !important; text-align: left">
      <B>{{ calculateTranTypeCR() }}</B>
    </th>
    <th style="background: #4dc3a3 !important; text-align: left">
      <B>{{ calculateTranTypeTotal() }}</B>
    </th>
    <th style="background: #4dc3a3 !important; text-align: left">&nbsp;</th>
  </tr>
</table>
<!---end Rashmi-->

<!-- info card -->
<div
  class="info-card"
  [ngStyle]="{ left: hoverX + 'px', top: hoverY + 'px' }"
  *ngIf="hoveredRow"
  [class.visible]="hoveredRow"
  style="display: flex; flex-direction: column"
>
  <p
    style="
      margin-bottom: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span style="font-size: 14px; font-weight: 500">ODI:</span>
    <span style="font-size: 12px; font-weight: 500">{{
      hoveredRow?.ODInterest
    }}</span>
  </p>
  <p
    style="
      margin-bottom: 1px;
      margin-top: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span style="font-size: 14px; font-weight: 500">LPI:</span>

    <span style="font-size: 12px; font-weight: 500">
      {{ hoveredRow?.latePaymentInterest }}</span
    >
  </p>
  <p
    style="
      margin-bottom: 1px;
      margin-top: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span style="font-size: 14px; font-weight: 500">CGST:</span>

    <span style="font-size: 12px; font-weight: 500">
      {{ hoveredRow?.CGST_OnInterest }}</span
    >
  </p>
  <p
    style="
      margin-bottom: 1px;
      margin-top: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span style="font-size: 14px; font-weight: 500">SGST :</span>

    <span style="font-size: 12px; font-weight: 500">
      {{ hoveredRow?.SGST_OnInterest }}</span
    >
  </p>
  <p
    style="
      margin-bottom: 1px;
      margin-top: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span style="font-size: 14px; font-weight: 500">IGST :</span>

    <span style="font-size: 12px; font-weight: 500">
      {{ hoveredRow?.IGST_OnInterest }}</span
    >
  </p>
</div>
