<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<ng-container *ngIf="IsEdit">
  <div class="row m-0 mt-2">
    <!-- <div class="col-md-2 p-1">
            <h6 class="fs-12">Customer Name</h6>
            <p class="lead fs-12">{{ LegalInfoModel.CustomerName }}</p>
        </div> -->
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Court Name</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.CourtName : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">State</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.State_Name : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">District</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.District_Name : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Court</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Court_Name : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Nature</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Nature : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">ACT</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.UnderACTName : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Section</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.UnderSectionName : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Sub Section</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.UnderSubSectionName : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Case Filing Date</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.ShowDateOfCaseFiling : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Case Filed By</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Case_Filed_By_Name : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Demand Notice Date</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.ShowDemandNoticeDate : "" }}
      </p>
    </div>
    <div
      class="col-md-2 p-1"
      *ngIf="LegalInfoModel && LegalInfoModel.Demand_Notice_Doc != null"
    >
      <h6 class="fs-12">Demand Notice Doc</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.Demand_Notice_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.Demand_Notice_Doc }}"
        >
          {{ LegalInfoModel.Demand_Notice_Doc.slice(0, 17)
          }}{{ LegalInfoModel.Demand_Notice_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Court File No</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Court_File_No : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Power Of Attorney Type</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Power_Of_Attorney_Type : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Power Of Attorney Name</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Power_Of_Attorney_Name : "" }}
      </p>
    </div>
    <div
      class="col-md-2 p-1"
      *ngIf="LegalInfoModel && LegalInfoModel.Power_Of_Attorney_Doc != null"
    >
      <h6 class="fs-12">Power Of Attorney Doc</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.Power_Of_Attorney_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.Power_Of_Attorney_Doc }}"
        >
          {{ LegalInfoModel.Power_Of_Attorney_Doc.slice(0, 17)
          }}{{ LegalInfoModel.Power_Of_Attorney_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Customer Bank</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Customer_Bankname : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Customer Cheque</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Customer_ChequeNo : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Cheque Date</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.ShowCustomerChequeDate : "" }}
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Cheque Amount</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Customer_Cheque_Amount : "" }}
      </p>
    </div>
    <div
      class="col-md-2 p-1"
      *ngIf="LegalInfoModel && LegalInfoModel.Customer_Cheque_Doc != null"
    >
      <h6 class="fs-12">Cheque Image</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.Customer_Cheque_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.Customer_Cheque_Doc }}"
        >
          {{ LegalInfoModel.Customer_Cheque_Doc.slice(0, 17)
          }}{{ LegalInfoModel.Customer_Cheque_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Cheque Bounce Date</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.ShowCustomerBounceDate : "" }}
      </p>
    </div>
    <div class="col-md-3 p-1">
      <h6 class="fs-12">Bounce Reason</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Customer_Bounce_Reason : "" }}
      </p>
    </div>

    <div class="col-md-3 p-1">
      <h6 class="fs-12">SOA Attachement Doc</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.SOA_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.SOA_Doc }}"
        >
          {{ LegalInfoModel.SOA_Doc.slice(0, 17)
          }}{{ LegalInfoModel.SOA_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div
      class="col-md-2 p-1"
      *ngIf="LegalInfoModel && LegalInfoModel.Customer_Bounce_Doc != null"
    >
      <h6 class="fs-12">Cheque Return Memo</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.Customer_Bounce_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.Customer_Bounce_Doc }}"
        >
          {{ LegalInfoModel.Customer_Bounce_Doc.slice(0, 17)
          }}{{ LegalInfoModel.Customer_Bounce_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div
      class="col-md-2 p-1"
      *ngIf="LegalInfoModel && LegalInfoModel.Closer_Doc != null"
    >
      <h6 class="fs-12">Closer Letter</h6>
      <p class="lead fs-12">
        <a
          href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
            LegalInfoModel.Closer_Doc
          }}"
          target="_blank"
          title="{{ LegalInfoModel.Closer_Doc }}"
        >
          {{ LegalInfoModel.Closer_Doc.slice(0, 17)
          }}{{ LegalInfoModel.Closer_Doc.length > 17 ? "..." : "" }}
        </a>
      </p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Remark</h6>
      <p class="lead fs-12">
        {{ LegalInfoModel ? LegalInfoModel.Remark : "" }}
      </p>
    </div>
  </div>
  <div class="row m-0 mt-2">
    <div class="col-md-12 p-1"><hr /></div>

    <div class="col-md-12 p-1 text-right">
      <button
        type="button"
        (click)="OnClickEdit()"
        class="font-size-12 button-btn"
      >
        Edit
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="IsUpdate">
  <form #df="ngForm" novalidate>
    <div class="row m-0">
      <div class="col-md-3 p-1">
        <span class="required-lable">Court</span>
        <select
          name="CourtName"
          id="CourtName"
          #refCourtName="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refCourtName.invalid,
            'alert-warning':
              refCourtName.invalid &&
              (refCourtName.dirty ||
                refCourtName.touched ||
                refCourtName.untouched)
          }"
          [(ngModel)]="CaseModel.CourtName"
          class="form-control input-text-css"
          required
        >
          <option value="">Select Court</option>
          <option value="Supreme Court of India">Supreme Court of India</option>
          <option value="High Court">High Court</option>
          <option value="District Court">District Court</option>
          <option value="Commissions (Consumer Forum)">
            Commissions (Consumer Forum)
          </option>
          <option value="Tribunals and Authorities">
            Tribunals and Authorities
          </option>
          <option value="Revenue Court">Revenue Court</option>
          <option value="Commissionerate">Commissionerate</option>
          <option value="Department">Department</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">State</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectState(CaseModel)"
          (change)="OnSelectState(CaseModel)"
          [(ngModel)]="CaseModel.State_Name"
          id="StateId"
          name="StateId"
          list="dynmicState"
          autocomplete="off"
          required
          #refStateId="ngModel"
          placeholder="Select State"
          [ngClass]="{
            'is-invalid': df.submitted && refStateId.invalid,
            'alert-warning':
              refStateId.invalid &&
              (refStateId.dirty || refStateId.touched || refStateId.untouched)
          }"
        />
        <datalist id="dynmicState">
          <option
            *ngFor="let item of StateDropdown"
            [value]="item.State_Name"
            title="item.State_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">District</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectDistrict(CaseModel)"
          (change)="OnSelectDistrict(CaseModel)"
          [(ngModel)]="CaseModel.District_Name"
          id="DistrictId"
          name="DistrictId"
          list="dynmicDistrict"
          autocomplete="off"
          required
          #refDistrictId="ngModel"
          placeholder="Select District"
          [ngClass]="{
            'is-invalid': df.submitted && refDistrictId.invalid,
            'alert-warning':
              refDistrictId.invalid &&
              (refDistrictId.dirty ||
                refDistrictId.touched ||
                refDistrictId.untouched)
          }"
        />
        <datalist id="dynmicDistrict">
          <option
            *ngFor="let item of DistrictDropDown"
            [value]="item.District_Name"
            title="item.District_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Court Name</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectCourt(CaseModel)"
          (change)="OnSelectCourt(CaseModel)"
          [(ngModel)]="CaseModel.Court_Name"
          id="Court_EstablishmentId"
          name="Court_EstablishmentId"
          list="dynmicCourt"
          autocomplete="off"
          required
          #refCourt_EstablishmentId="ngModel"
          placeholder="Select State"
          [ngClass]="{
            'is-invalid': df.submitted && refCourt_EstablishmentId.invalid,
            'alert-warning':
              refCourt_EstablishmentId.invalid &&
              (refCourt_EstablishmentId.dirty ||
                refCourt_EstablishmentId.touched ||
                refCourt_EstablishmentId.untouched)
          }"
        />
        <datalist id="dynmicCourt">
          <option
            *ngFor="let item of CourtNameDropdown"
            [value]="item.Court_Name"
            title="item.Court_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Nature</span>
        <select
          name="Nature"
          id="Nature"
          #refNature="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refNature.invalid,
            'alert-warning':
              refNature.invalid &&
              (refNature.dirty || refNature.touched || refNature.untouched)
          }"
          [(ngModel)]="CaseModel.Nature"
          class="form-control input-text-css"
          required
        >
          <option value="">Select Nature</option>
          <option value="CIVIL">CIVIL</option>
          <option value="Criminal">Criminal</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">ACT</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectAct(CaseModel)"
          (change)="OnSelectAct(CaseModel)"
          [(ngModel)]="CaseModel.Act_Name"
          id="UnderACT_Id"
          name="UnderACT_Id"
          list="dynmicAct"
          autocomplete="off"
          required
          #refUnderACT_Id="ngModel"
          placeholder="Select Act"
          [ngClass]="{
            'is-invalid': df.submitted && refUnderACT_Id.invalid,
            'alert-warning':
              refUnderACT_Id.invalid &&
              (refUnderACT_Id.dirty ||
                refUnderACT_Id.touched ||
                refUnderACT_Id.untouched)
          }"
        />
        <datalist id="dynmicAct">
          <option
            *ngFor="let item of ActData"
            [value]="item.Short_Name"
            title="item.Short_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Section</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectSection(CaseModel)"
          (change)="OnSelectSection(CaseModel)"
          [(ngModel)]="CaseModel.Section_Name"
          id="UnderSectionId"
          name="UnderSectionId"
          list="dynmicSection"
          autocomplete="off"
          required
          #refUnderSectionId="ngModel"
          placeholder="Select Section"
          [ngClass]="{
            'is-invalid': df.submitted && refUnderSectionId.invalid,
            'alert-warning':
              refUnderSectionId.invalid &&
              (refUnderSectionId.dirty ||
                refUnderSectionId.touched ||
                refUnderSectionId.untouched)
          }"
        />
        <datalist id="dynmicSection">
          <option
            *ngFor="let item of SectionData"
            [value]="item.Short_Name"
            title="item.Short_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Sub Section</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectSubSection(CaseModel)"
          (change)="OnSelectSubSection(CaseModel)"
          [(ngModel)]="CaseModel.SubSection_Name"
          id="UnderSub_SectionId"
          name="UnderSub_SectionId"
          list="dynmicSubSection"
          autocomplete="off"
          required
          #refUnderSub_SectionId="ngModel"
          placeholder="Select Sub Section"
          [ngClass]="{
            'is-invalid': df.submitted && refUnderSub_SectionId.invalid,
            'alert-warning':
              refUnderSub_SectionId.invalid &&
              (refUnderSub_SectionId.dirty ||
                refUnderSub_SectionId.touched ||
                refUnderSub_SectionId.untouched)
          }"
        />
        <datalist id="dynmicSubSection">
          <option
            *ngFor="let item of SubSectionData"
            [value]="item.Short_Name"
            title="item.Short_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Case Filing Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Date_Of_Case_Filing"
            [(ngModel)]="CaseModel.Date_Of_Case_Filing"
            id="Date_Of_Case_Filing"
            required
            class="form-control input-text-css"
            #refDate_Of_Case_Filing="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refDate_Of_Case_Filing.invalid,
              'alert-warning':
                refDate_Of_Case_Filing.invalid &&
                (refDate_Of_Case_Filing.dirty ||
                  refDate_Of_Case_Filing.touched ||
                  refDate_Of_Case_Filing.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="Date_Of_Case_Filing"
          [(ngModel)]="CaseModel.Date_Of_Case_Filing"
          id="Date_Of_Case_Filing"
          required
          placeholder="Case Filling Date"
          class="form-control input-text-css"
          bsDatepicker
          #refDate_Of_Case_Filing="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDate_Of_Case_Filing.invalid,
            'alert-warning':
              refDate_Of_Case_Filing.invalid &&
              (refDate_Of_Case_Filing.dirty ||
                refDate_Of_Case_Filing.touched ||
                refDate_Of_Case_Filing.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Case Filed By</span>
        <input
          type="search"
          class="form-control input-text-css"
          (keyup.enter)="OnSelectCaseFiledBy(CaseModel)"
          (change)="OnSelectCaseFiledBy(CaseModel)"
          [(ngModel)]="CaseModel.Case_Filed_By_Name"
          id="Case_Filed_By"
          name="Case_Filed_By"
          list="dynmicCaseFilledBy"
          autocomplete="off"
          required
          #refCase_Filed_By="ngModel"
          placeholder="Case Filled By"
          [ngClass]="{
            'is-invalid': df.submitted && refCase_Filed_By.invalid,
            'alert-warning':
              refCase_Filed_By.invalid &&
              (refCase_Filed_By.dirty ||
                refCase_Filed_By.touched ||
                refCase_Filed_By.untouched)
          }"
        />
        <datalist id="dynmicCaseFilledBy">
          <option
            *ngFor="let item of AdvocateDropdown"
            [value]="item.Partner_Name"
            title="item.Partner_Name"
          ></option>
        </datalist>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Demand Notice Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Demand_Notice_Date"
            [(ngModel)]="CaseModel.Demand_Notice_Date"
            id="Demand_Notice_Date"
            required
            class="form-control input-text-css"
            #refDemand_Notice_Date="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refDemand_Notice_Date.invalid,
              'alert-warning':
                refDemand_Notice_Date.invalid &&
                (refDemand_Notice_Date.dirty ||
                  refDemand_Notice_Date.touched ||
                  refDemand_Notice_Date.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="Demand_Notice_Date"
          [(ngModel)]="CaseModel.Demand_Notice_Date"
          id="Demand_Notice_Date"
          required
          placeholder="Demand Notice Date"
          class="form-control input-text-css"
          bsDatepicker
          #refDemand_Notice_Date="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDemand_Notice_Date.invalid,
            'alert-warning':
              refDemand_Notice_Date.invalid &&
              (refDemand_Notice_Date.dirty ||
                refDemand_Notice_Date.touched ||
                refDemand_Notice_Date.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Demand Notice Doc</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.Demand_Notice_Doc"
            (click)="docFile.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.Demand_Notice_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.Demand_Notice_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <!-- {{CaseModel.Demand_Notice_Doc}} -->
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile(CaseModel, docFile)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener(CaseModel, docFile.files)"
          style="display: none"
          [disabled]="!CaseModel.Demand_Notice_Date"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Court File No</span>
        <input
          type="text"
          name="Court_File_No"
          [(ngModel)]="CaseModel.Court_File_No"
          id="Court_File_No"
          required
          placeholder="Court File No"
          class="form-control input-text-css"
          #refCourt_File_No="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refCourt_File_No.invalid,
            'alert-warning':
              refCourt_File_No.invalid &&
              (refCourt_File_No.dirty ||
                refCourt_File_No.touched ||
                refCourt_File_No.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Power Of Attorney Type</span>
        <select
          name="Power_Of_Attorney_Type"
          id="Power_Of_Attorney_Type"
          #refPower_Of_Attorney_Type="ngModel"
          [(ngModel)]="CaseModel.Power_Of_Attorney_Type"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refPower_Of_Attorney_Type.invalid,
            'alert-warning':
              refPower_Of_Attorney_Type.invalid &&
              (refPower_Of_Attorney_Type.dirty ||
                refPower_Of_Attorney_Type.touched ||
                refPower_Of_Attorney_Type.untouched)
          }"
        >
          <option value="">Select Power Of Attorney Type</option>
          <option value="Employee">Employee</option>
          <option value="Advocate">Advocate</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <ng-container *ngIf="CaseModel.Power_Of_Attorney_Type == 'Employee'">
          <span class="required-lable">Power Of Attorney Name</span>
          <input
            type="search"
            class="form-control input-text-css"
            (keyup.enter)="OnSelectPowerOfAttorney(CaseModel, 'Employee')"
            (change)="OnSelectPowerOfAttorney(CaseModel, 'Employee')"
            [(ngModel)]="CaseModel.Power_Of_Attorney"
            id="Power_Of_AttorneyId"
            name="Power_Of_AttorneyId"
            list="dynmicPowerOfAttorney"
            autocomplete="off"
            required
            #refPower_Of_AttorneyId="ngModel"
            placeholder="Power Of Attorney Name"
            [ngClass]="{
              'is-invalid': df.submitted && refPower_Of_AttorneyId.invalid,
              'alert-warning':
                refPower_Of_AttorneyId.invalid &&
                (refPower_Of_AttorneyId.dirty ||
                  refPower_Of_AttorneyId.touched ||
                  refPower_Of_AttorneyId.untouched)
            }"
          />
          <datalist id="dynmicPowerOfAttorney">
            <option
              *ngFor="let item of EmployeeDropdown"
              [value]="item.EmpName"
              title="item.EmpName"
            ></option>
          </datalist>
        </ng-container>

        <ng-container *ngIf="CaseModel.Power_Of_Attorney_Type == 'Advocate'">
          <span class="required-lable">Power Of Attorney Name</span>
          <input
            type="search"
            class="form-control input-text-css"
            (keyup.enter)="OnSelectPowerOfAttorney(CaseModel, 'Advocate')"
            (change)="OnSelectPowerOfAttorney(CaseModel, 'Advocate')"
            [(ngModel)]="CaseModel.Power_Of_Attorney"
            id="Power_Of_AttorneyId"
            name="Power_Of_AttorneyId"
            list="dynmicPowerOfAttorney"
            autocomplete="off"
            required
            #refPower_Of_AttorneyId="ngModel"
            placeholder="Power Of Attorney Name"
            [ngClass]="{
              'is-invalid': df.submitted && refPower_Of_AttorneyId.invalid,
              'alert-warning':
                refPower_Of_AttorneyId.invalid &&
                (refPower_Of_AttorneyId.dirty ||
                  refPower_Of_AttorneyId.touched ||
                  refPower_Of_AttorneyId.untouched)
            }"
          />
          <datalist id="dynmicPowerOfAttorney">
            <option
              *ngFor="let item of AdvocateDropdown"
              [value]="item.Partner_Name"
              title="item.Partner_Name"
            ></option>
          </datalist>
        </ng-container>
      </div>
      <div class="col-md-3 p-1">
        <span>Power Of Attorney Doc</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.Power_Of_Attorney_Doc"
            (click)="docFile1.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.Power_Of_Attorney_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.Power_Of_Attorney_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <!-- {{CaseModel.Power_Of_Attorney_Doc}} -->
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile1(CaseModel, docFile1)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile1
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener1(CaseModel, docFile1.files)"
          style="display: none"
          [disabled]="!CaseModel.Power_Of_Attorney"
        />
      </div>
      <div class="col-md-3 p-1">
        <span>Customer Bank</span>
        <select
          name="Customer_Bank_Id"
          id="Customer_Bank_Id"
          #refCustomer_Bank_Id="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="CaseModel.Customer_Bank_Id"
        >
          <option value="">Select Bank A/C</option>
          <option *ngFor="let row of CustomerBankDropdown" [value]="row.Id">
            {{ row.AccountNo }} ({{ row.BankName }})
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span>Customer Cheque</span>
        <select
          name="Customer_Cheque_Id"
          id="Customer_Cheque_Id"
          #refCustomer_Cheque_Id="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="CaseModel.Customer_Cheque_Id"
        >
          <option value="">Select Cheque</option>
          <option *ngFor="let row of CustomerChqDropdown" [value]="row.Int_Id">
            {{ row.ChFrom }} ({{ row.Bankname }}-{{ row.PdcType }})
          </option>
        </select>
      </div>
      <div class="col-md-2 p-1">
        <span>Cheque Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker3"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Customer_Cheque_Date"
            [(ngModel)]="CaseModel.Customer_Cheque_Date"
            id="Customer_Cheque_Date"
            class="form-control input-text-css"
            #refCustomer_Cheque_Date="ngModel"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
        <!-- <input
          name="Customer_Cheque_Date"
          [(ngModel)]="CaseModel.Customer_Cheque_Date"
          id="Customer_Cheque_Date"
          placeholder="Cheque Date"
          class="form-control input-text-css"
          bsDatepicker
          #refCustomer_Cheque_Date="ngModel"
        /> -->
      </div>
      <div class="col-md-2 p-1">
        <span>Cheque Amount</span>
        <input
          type="text"
          name="Customer_Cheque_Amount"
          id="Customer_Cheque_Amount"
          placeholder="Cheque Amount"
          [(ngModel)]="CaseModel.Customer_Cheque_Amount"
          numbersOnly
          class="form-control input-text-css"
          #refCustomer_Cheque_Amount="ngModel"
        />
      </div>
      <div class="col-md-2 p-1">
        <span>Cheque Image</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.Customer_Cheque_Doc"
            (click)="docFile2.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.Customer_Cheque_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.Customer_Cheque_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <!-- {{CaseModel.Customer_Cheque_Doc}} -->
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile2(CaseModel, docFile2)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile2
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener2(CaseModel, docFile2.files)"
          style="display: none"
          [disabled]="!CaseModel.Customer_Cheque_Amount"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Cheque Bounce Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker4"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Customer_Bounce_Date"
            [(ngModel)]="CaseModel.Customer_Bounce_Date"
            id="Customer_Bounce_Date"
            required
            class="form-control input-text-css"
            #refCustomer_Bounce_Date="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refCustomer_Bounce_Date.invalid,
              'alert-warning':
                refCustomer_Bounce_Date.invalid &&
                (refCustomer_Bounce_Date.dirty ||
                  refCustomer_Bounce_Date.touched ||
                  refCustomer_Bounce_Date.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker4"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
        </div>
        <!-- <input
          name="Customer_Bounce_Date"
          [(ngModel)]="CaseModel.Customer_Bounce_Date"
          id="Customer_Bounce_Date"
          required
          placeholder="Cheque Bounce Date"
          class="form-control input-text-css"
          bsDatepicker
          #refCustomer_Bounce_Date="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refCustomer_Bounce_Date.invalid,
            'alert-warning':
              refCustomer_Bounce_Date.invalid &&
              (refCustomer_Bounce_Date.dirty ||
                refCustomer_Bounce_Date.touched ||
                refCustomer_Bounce_Date.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Bounce Reason</span>
        <select
          name="Customer_Bounce_Reason"
          id="Customer_Bounce_Reason"
          #refCustomer_Bounce_Reason="ngModel"
          [(ngModel)]="CaseModel.Customer_Bounce_Reason"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refCustomer_Bounce_Reason.invalid,
            'alert-warning':
              refCustomer_Bounce_Reason.invalid &&
              (refCustomer_Bounce_Reason.dirty ||
                refCustomer_Bounce_Reason.touched ||
                refCustomer_Bounce_Reason.untouched)
          }"
        >
          <option value="">Select Bounce Reason</option>
          <option value="INSUFFICIENT FUNDS">INSUFFICIENT FUNDS</option>
          <option value="PAYMENT STOPPED BY DRAWER">
            PAYMENT STOPPED BY DRAWER
          </option>
          <option value="DRAWERS SIGN DIFFERS">DRAWERS SIGN DIFFERS</option>
          <option value="ALTERATION DRAWER SIGN REQ">
            ALTERATION DRAWER SIGN REQ.
          </option>
          <option value="DRAWER SIGN REQUIRED">DRAWER SIGN REQUIRED</option>
          <option value="NO SUCH ACCOUNT">NO SUCH ACCOUNT</option>
          <option value="ACCOUNT CLOSED">ACCOUNT CLOSED</option>
          <option value="ACCOUNT BLOCKED">ACCOUNT BLOCKED</option>
          <option value="NOT ARRANGE FOR">NOT ARRANGE FOR</option>
          <option value="REFER TO DRAWER">REFER TO DRAWER</option>
          <option value="ECS MANDATE NOT RECEIVED">
            ECS MANDATE NOT RECEIVED
          </option>
          <option value="EXCEED ARRANGEMENT">EXCEED ARRANGEMENT</option>
          <option value="PARTY DECEASED">PARTY DECEASED</option>
          <option value="WORD DIFFERENCE">WORD DIFFERENCE</option>
          <option value="PAYNAME DIFFERENCE">PAYNAME DIFFERENCE</option>
          <option value="AMOUNT DIFFERENCE">AMOUNT DIFFERENCE</option>
          <option value="SIGNATURE NOT ILLIGIBLE">
            SIGNATURE NOT ILLIGIBLE
          </option>
          <option value="ALTRATIONS">ALTRATIONS</option>
          <option value="INSTRUMENTS">INSTRUMENTS</option>
          <option value="STAMP OF ACCOUNT REQUIRED">
            STAMP OF ACCOUNT REQUIRED
          </option>
          <option value="CONNECTIVITY FAILURE">CONNECTIVITY FAILURE</option>
          <option value="DORMENT ACCOUNT">DORMENT ACCOUNT</option>
          <option value="MISE-A/C REACH MAX DR-LIMIT">
            MISE-A/C REACH MAX DR-LIMIT
          </option>
          <option value="PYMT STOP UNDER COURT OREDER">
            PYMT STOP UNDER COURT OREDER
          </option>
          <option value="ACCOUNT UNDER LITIGATION">
            ACCOUNT UNDER LITIGATION
          </option>
          <option value="POSTDATED">POSTDATED</option>
          <option value="OTHER REASON">OTHER REASON</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Cheque Return Memo</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.Customer_Bounce_Doc"
            (click)="docFile3.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.Customer_Bounce_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.Customer_Bounce_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <!-- {{CaseModel.Customer_Bounce_Doc}} -->
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile3(CaseModel, docFile3)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile3
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener3(CaseModel, docFile3.files)"
          style="display: none"
          [disabled]="!CaseModel.Customer_Bounce_Reason"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Closer Letter</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.Closer_Doc"
            (click)="docFile4.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.Closer_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.Closer_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <!-- {{CaseModel.Closer_Doc}} -->
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile4(CaseModel, docFile4)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile4
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener4(CaseModel, docFile4.files)"
          style="display: none"
          [disabled]="!CaseModel.Customer_Bounce_Reason"
        />
      </div>

      <div class="col-md-3 p-1">
        <span>SOA Attachement</span>
        <p class="mt-1">
          <i
            class="fa fa-upload fileUpload"
            *ngIf="!CaseModel.SOA_Doc"
            (click)="docFile5.click()"
            aria-hidden="true"
            style="cursor: pointer"
          ></i>
          <ng-container *ngIf="CaseModel.SOA_Doc">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                CaseModel.SOA_Doc
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            
            <i
              class="fa fa-times ml-2 fs-16"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile5(CaseModel, docFile5)"
            ></i>
          </ng-container>
        </p>
        <input
          #docFile5
          type="file"
          [multiple]="false"
          accept="application/pdf,application/vnd.ms-excel"
          (change)="fileChangeListener5(CaseModel, docFile5.files)"
          style="display: none"
        />
      </div>

      <div class="col-md-12 p-1">
        <span>Remark</span>
        <textarea
          rows="1"
          name="Remark"
          id="Remark"
          [(ngModel)]="CaseModel.Remark"
          #refRemark="ngModel"
          placeholder="Remark"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': df.submitted && refRemark.invalid,
            'alert-warning':
              refRemark.invalid &&
              (refRemark.dirty || refRemark.touched || refRemark.untouched)
          }"
        ></textarea>
      </div>

      <div class="col-md-12 p-1"><hr /></div>

      <div class="col-md-12 p-1 text-right">
        <button
          type="button"
          (click)="OnSaveLegalCase()"
          class="font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!df.form.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</ng-container>
