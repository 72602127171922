import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { constantUrl } from "../../Shared/constantUrl";
import { LmsService } from "../services/lms.service";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";

@Component({
  selector: "app-lms-loan-disbursment-detail",
  templateUrl: "./lms-loan-disbursment-detail.component.html",
  styleUrls: ["./lms-loan-disbursment-detail.component.scss"],
})
export class LmsLoanDisbursmentDetailComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Cur_Date: any = new Date();
  LoanId: any;
  customerId: any;
  customerDetail: any;
  Tenure_Name: any;
  ProcessHeadList: any[] = [];
  JsonData: any[] = [];
  JsonData1: any[] = [];
  JsonData2: any[] = [];
  JsonData3: any[] = [];
  JsonData4: any[] = [];
  JsonData7: any[] = [];
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  CrcApplicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  CustomerDataSource: any;
  @Input() loanSummary: any;
  ChargesHeadDataSource: any;
  ChargesDataSource: any;
  EmiDataSource: any;
  PDCDataSource: any;
  displayedCustomerColumns: string[] = [
    "Customer",
    "FatherName",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
  ];
  displayedChargesHeadColumns: string[] = ["Charges", "Amount", "ReceiptOn"];
  displayedChargesColumns: string[] = [
    "CaseNo",
    "Customer",
    "UpfrontCharges",
    "Deduction",
    "Receipt",
  ];
  displayedEMIColumns: string[] = [
    "EMI_Pattern",
    "NoOfEMI",
    "EMIAmount",
    "TotalAmount",
  ];
  displayedPDCColumns: string[] = [
    "Customer",
    "PdcType",
    "Bankname",
    "Branch",
    "AccountNo",
    "IFSCCode",
  ];
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  Disbursment: any;
  displayedPaymentColumns: string[] = [
    "HolderName",
    "AccountNo",
    "BankName",
    "BankBranch",
    "BankIFSC",
    "Amount",
    "PaymentStatus",
  ];

  TotalDisbursAmt: any;

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private lmsService: LmsService,
    private _commonfunctions: CommonFunctions
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id")); // encdec.decrypt(param.get("appId"));
      //console.log('sss', this.LoanId);
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Disbursment Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (this.loanSummary != "" && this.loanSummary != undefined) {
      //console.log("this.loanSummary.LoanId", this.loanSummary.LoanId);
      this.LoanId = this.loanSummary.LoanId;
    }
    this.getCustomerDetail();
  }
  getCustomerDetail() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_DV_Details_Print({ LoanAcId: this.LoanId.toString() })
      .subscribe((res: any) => {
        console.log("res", res);
        this.showSpinner = false;
        this.customerDetail = res.Item1[0];
        if (
          this.customerDetail.EMI_Type == "Daily" ||
          this.customerDetail.EMI_Type == "Weekly" ||
          this.customerDetail.EMI_Type == "Fortnightly" ||
          this.customerDetail.EMI_Type == "ByWeekly" ||
          this.customerDetail.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
        this.CustomerDataSource = new MatTableDataSource(res.Item2);
        this.JsonData = JSON.parse(JSON.stringify(res.Item2));
        this.ProcessHeadList = JSON.parse(JSON.stringify(res.Item3));

        this.ProcessHeadList.forEach((x) => {
          x.Deduction = 0;
          x.Receipt = 0;
          if (x.Type == "DR") x.Deduction = x.Amount;
          if (x.Type == "CR") x.Receipt = x.Amount;
          x.Total = x.Deduction + x.Receipt;
        });

        this.ChargesDataSource = new MatTableDataSource(this.ProcessHeadList);
        this.JsonData1 = JSON.parse(JSON.stringify(this.ProcessHeadList));
        console.log("chargesHead", this.JsonData1);
        this.ChargesHeadDataSource = new MatTableDataSource(res.Item4);
        this.JsonData3 = JSON.parse(JSON.stringify(res.Item4));

        res.Item5.forEach((x) => {
          x.EMIAmount = parseFloat(x.EMIAmount).toFixed(2);
        });

        this.EmiDataSource = new MatTableDataSource(res.Item5);
        this.JsonData2 = JSON.parse(JSON.stringify(res.Item5));

        this.PDCDataSource = new MatTableDataSource(res.Item6);
        this.JsonData4 = JSON.parse(JSON.stringify(res.Item6));

        //this.Disbursment = res.Item6.filter(item => item.PdcType == 'Disbursment')[0];
        this.Disbursment = new MatTableDataSource(res.Item7);
        this.JsonData7 = JSON.parse(JSON.stringify(res.Item7));

        this.TotalDisbursAmt = res.Item3.filter(
          (item) => item.ChargeHeadId == 28
        )[0].Amount;
        //console.log("this.TotalDisbursAmt", this.TotalDisbursAmt);
      });
  }

  calculateDeductionTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Deduction"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateAmountTotal() {
    if (this.ChargesHeadDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesHeadDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesHeadDataSource.filteredData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateReceiptTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Receipt"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Total"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalEMI() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["NoOfEMI"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalAmount() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["TotalAmount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  // Print() {
  //   window.print();
  // }

  onCloseDisbursementPrintModel() {
    $("#loan_disbursement_print_modal").modal("hide");
  }

  Print() {
    $("#loan_disbursement_print_modal").modal("show");
    $("#loan_disbursement_print_modal").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }
}
