import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { LmsService } from "src/app/_Lms/services/lms.service";
import * as moment from "moment";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { FormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
declare var $: any;

@Component({
  selector: "app-vehicle-grid",
  templateUrl: "./vehicle-grid.component.html",
  styleUrls: ["./vehicle-grid.component.scss"],
})
export class VehicleGridComponent implements OnInit {
  startyear: Date;
  getStartYear: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  FyearId: string;
  private _MasterService;
  vechileGridList: any[] = [];
  modelNameDropdown: any[] = [];
  dataSource: any;
  displayedColumnsforTable: any;
  showSpinner: boolean = false;
  currentUser: any;
  Vehicle_Model_Name: any;
  displayedColumns: string[] = ["modelId", "modelName"];
  dataSourceViewDetails = new MatTableDataSource<any>();
  Vehicle_Category_Id: any;
  modelNameId: any;
  constructor(
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private snackBar: MatSnackBar,
    private dataSharingService: DataSharingService
  ) {
    this.bsConfig = {
      dateInputFormat: "YYYY",
      minMode: "year",
      showWeekNumbers: false,
    };
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Vehicle Grid");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getVehicleGridModel();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.startyear = moment(res[0].fystart, "YYYY-MM-DD").toDate();
        });
    });
    this.LMS_GetVehicleModelMaster();
  }

  LMS_GetVehicleModelMaster() {
    this.showSpinner = true;
    this.lmsService.Get_Lms_VehicleModelMaster().subscribe((response: any) => {
      console.log(response);
      this.modelNameDropdown = response;
      // this.modelNameId = response.Vehicle_Category_Id;
      // console.log("id of vehicle id", this.modelNameId);
      // console.log("modelNameDropdown", this.modelNameDropdown);
    });
    this.showSpinner = false;
  }

  onYearChangeVehicleGridCallAPi(event: Date) {
    this.getStartYear = event;
    this.getVehicleGridModel();
  }

  onModelIdChangeVehicleGrid(event: any) {
    // console.log("Model name changed:", event);
    this.Vehicle_Model_Name = event;
    console.log("model name after change log", this.Vehicle_Model_Name);
    this.getVehicleGridModel();
  }

  getVehicleGridModel() {
    // this.getStartYear = event;
    var modelId = 0;
    if (
      this.Vehicle_Model_Name != "" &&
      this.Vehicle_Model_Name != null &&
      this.Vehicle_Model_Name != undefined
    ) {
      modelId = this.modelNameDropdown.filter(
        (x) => x.Vehicle_Model_Name == this.Vehicle_Model_Name
      )[0].Int_Id;
    }
    const selectedYearforVehicleGrid = moment(this.getStartYear).format("YYYY");
    const vehicleModelId = modelId;
    // console.log(
    //   "Selected Year for get api call outside call",
    //   selectedYearforVehicleGrid
    // );
    this.showSpinner = true;
    this.lmsService
      .Get_Vehicle_Model_Grid_List({
        year: selectedYearforVehicleGrid || 0,
        modelId: vehicleModelId || 0,
      })
      .subscribe((res: any) => {
        // if () {
        console.log("get data for vehicle grid", res);
        this.dataSourceViewDetails.data = res;
        this.updateDisplayedColumns(+selectedYearforVehicleGrid);
        // }
      });
    this.showSpinner = false;
  }

  updateDisplayedColumns(year: number): void {
    this.displayedColumns = ["modelId", "modelName"];
    for (let i = 0; i <= 9; i++) {
      this.displayedColumns.push(`Y${year - i}`);
    }
    // console.log("Displayed Column Dynamic", this.displayedColumns);
  }

  onYearChange(event: Date) {
    // console.log("enter in the on model change function");
    this.startyear = event;
    const selectedYear = moment(event).format("YYYY");

    this.lmsService
      .Get_Vehicle_Model_Grid({ year: selectedYear })
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.dataSource = new MatTableDataSource(result);
          console.log("Data", this.dataSource);
          this.displayedColumnsforTable = ["modelId", "modelName", "price"];
          this.vechileGridList = JSON.parse(JSON.stringify(result));
          console.log("Vehicle grid list", this.vechileGridList);
        }
      });
  }

  onSaveVehicleGrid() {
    console.log("Save button clicked");

    console.log(
      "Current vehicle grid list before mapping:",
      this.vechileGridList
    );

    const Grid = this.dataSource.filteredData.map((row) => ({
      ModelId: row.modelId,
      Price: row.price,
    }));

    const selectedYear = moment(this.startyear).format("YYYY");

    this.lmsService
      .Save_Vehicle_Model_Grid({
        JSON: JSON.stringify(Grid),
        year: selectedYear,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          console.log("saved data", res);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.onYearChange(this.startyear);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        $("#addVehicleGridModel").modal("hide");
      });
  }

  openAddVehicleGrid() {
    this.startyear = null;
    $("#addVehicleGridModel").modal("show");
    $("#addVehicleGridModel").css("z-index", "1050");
  }

  onCloseModal() {
    $("#addVehicleGridModel").modal("hide");
  }

  onCancelModal() {
    $("#addVehicleGridModel").modal("hide");
  }
}
