<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-3">
    <div class="col-md-6 p-0">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'plist' }" (click)="changeTabList('plist')">
        Partner Amortization
      </button>
    </div>
    <div class="col-md-6 pr-0">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'clist' }" (click)="changeTabList('clist')">
        Customer Amortization
      </button>
    </div>
  </div>

  <div class="row m-0 mt-2" *ngIf="tab=='plist'">
    <div class="col-md-12 p-0">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[]"
          [ngClass]="{'fixheight1': PageType != 'A', 'fixheight2': PageType == 'A'}">
          <ng-container matColumnDef="Period">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Period</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{ row.Period }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PEMI_Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef>EMI Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <ng-container *ngIf="row.isBold"><b> {{ row.PEMI_Amount }}</b></ng-container>
              <ng-container *ngIf="!row.isBold">{{ row.PEMI_Amount }}</ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Outstanding_Principle">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Outstanding Principle</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Outstanding_Principle }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Interest</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <ng-container *ngIf="row.isBold"><b> {{ row.Interest }}</b></ng-container>
              <ng-container *ngIf="!row.isBold">{{ row.Interest }}</ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Principle">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Principle</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <ng-container *ngIf="row.isBold"><b> {{ row.Principle }}</b></ng-container>
              <ng-container *ngIf="!row.isBold">{{ row.Principle }}</ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Due Date</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <ng-container *ngIf="row.isBold"><b> {{ row.DueDate }}</b></ng-container>
              <ng-container *ngIf="!row.isBold">{{ row.DueDate }}</ng-container>
            </mat-cell>
          </ng-container>
          
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 pt-2 pb-1 align-items-center" style="background: #fff;">
          <div class="col-md-6">
            <button class="mt-3 btn font-size-12 button-btn" (click)="exportTable(exporter)">
              <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
            </button>
          </div>
          <div class="col-md-6 text-right">
            <button type="button" (click)="onNextStep()" class="mt-3 btn font-size-12 button-btn">
              Next <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tab=='clist'">
    <app-lms-amortization [loanSummary]="loanSummary" [PageType]="PageType"></app-lms-amortization>
  </div>
</div>