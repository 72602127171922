import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { LmsService } from "../../services/lms.service";
import { constantUrl } from 'src/app/Shared/constantUrl';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-print-card',
  templateUrl: './print-card.component.html',
  styleUrls: ['./print-card.component.scss']
})


export class PrintCardComponent implements OnInit {
  @Input() loanId;
  @Input() resData;
  @Input() applicationId;
  object = Object;
  gstDetail: any;
  originalData: any={};
 
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  isShown: boolean;
  
  constructor(
    private lmsService: LmsService,
  ) {
   
   
  
  }

 


  ngOnInit(): void {
   this.getCompanyName();
   this.isShown = false;
  }
  getCompanyName(){
  this.lmsService
  .GSTReceiptDetails({ LoanId: this.loanId, VoucherId: 0 })
  .subscribe((result:any) => {
    // console.log("Receipt Details : ", result);
    this.originalData=result.Item1[0];
    console.log("Output",this.originalData)
    
  });
}
}












