<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="onSearchTrailVoucher()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <span>Voucher Date From</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="VoucherDateFrom"
          id="VoucherDateFrom"
          [min]="minDate"
          [max]="maxDate"
          (dateChange)="DateChange()"
          class="form-control input-text-css"
          [(ngModel)]="Search.VoucherDateFrom"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="VoucherDateFrom"
        [(ngModel)]="Search.VoucherDateFrom"
        id="VoucherDateFrom"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="Voucher From"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
      /> -->
    </div>
    <div class="col-md-3">
      <span>Voucher Date To</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="VoucherDateTo"
          id="VoucherDateTo"
          [min]="minDate"
          [max]="maxDate"
          class="form-control input-text-css"
          [(ngModel)]="Search.VoucherDateTo"
          (dateChange)="DateChange()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="VoucherDateTo"
        [(ngModel)]="Search.VoucherDateTo"
        id="VoucherDateTo"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="Voucher To"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
      /> -->
    </div>
    <div class="col-md-3">
      <span>Post Date From</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker3"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="PostDateFrom"
          id="PostDateFrom"
          class="form-control input-text-css"
          [(ngModel)]="Search.PostDateFrom"
          [min]="minDate"
          [max]="maxDate"
          (dateChange)="DateChange()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </div>
      <!-- <input
        name="PostDateFrom"
        [(ngModel)]="Search.PostDateFrom"
        id="PostDateFrom"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="Post From"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
      /> -->
    </div>
    <div class="col-md-3">
      <span>Post Date To</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker4"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="PostDateTo"
          id="PostDateTo"
          class="form-control input-text-css"
          [(ngModel)]="Search.PostDateTo"
          (dateChange)="DateChange()"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker4"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </div>
      <!-- <input
        name="PostDateTo"
        [(ngModel)]="Search.PostDateTo"
        id="PostDateTo"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="Post To"
        class="form-control input-text-css"
        bsDatepicker
        (ngModelChange)="DateChange()"
      /> -->
    </div>
    <div class="col-md-3">
      <span class="required-lable">Employee</span>
      <select
        name="Employee"
        id="Employee"
        [(ngModel)]="Search.SelectEmployee"
        class="form-control input-text-css"
      >
        <option value="0">Select Employee</option>
        <option *ngFor="let Type of EmployeeList" [value]="Type.EmpId">
          {{ Type.Emp_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span> Voucher Type</span>
      <select
        name="VoucherType"
        id="VoucherType"
        class="form-control input-text-css"
        [(ngModel)]="Search.VoucherType"
      >
        <option value="">Select Voucher Type</option>
        <option value="1">Receipt</option>
        <option value="2">Payment</option>
        <option value="3">Journal</option>
        <option value="4">Contra</option>
        <option value="5">Disbursement</option>
        <option value="6">Repayment</option>
      </select>
    </div>
    <div class="col-md-3">
      <span>Voucher Subtype</span>
      <select
        name="VoucherSubType"
        id="VoucherSubType"
        class="form-control input-text-css"
        [(ngModel)]="Search.VoucherSubType"
      >
        <option value="">Select Voucher Subtype</option>
        <option value="1">Loan</option>
        <option value="2">Accounting</option>
        <option value="3">Partner</option>
      </select>
    </div>
    <div class="col-md-2">
      <span>Type</span>
      <select
        name="Type"
        id="Type"
        class="form-control input-text-css"
        [(ngModel)]="Search.Type"
      >
        <option value="">Select Type</option>
        <option value="0">CREATE</option>
        <option value="1">DELETE</option>
        <option value="2">EDIT</option>
      </select>
    </div>
    <div class="col-md-2 text-right">
      <button
        type="button"
        (click)="onSearchTrailVoucher()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="col-md-12">
      <div class="col-md-3 float-right mt-2" [hidden]="!dataSource">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</form>

<div class="mt-3" *ngIf="dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="VoucherId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 60px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 60px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="VoucherDate">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Voucher No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_No }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Voucher Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Type }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_Sub_Type">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Voucher Sub Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Sub_Type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Create By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_CreateBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateOn">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Create On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_CreateOn }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_ModifyBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Modify By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_ModifyBy }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_ModifyOn">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Modify On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_ModifyOn }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_DeleteBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Delete By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_ModifyBy }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_DeleteOn">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Delete On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_ModifyOn }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ViewAction">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px; justify-content: center"
            *matHeaderCellDef
          >
            View Detail
          </mat-header-cell>

          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 100px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToDetail(row)"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsModel; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsModel"></mat-row>
      </mat-table>

      <div class="row m-0 pt-2 pb-1">
        <div class="col-md-2">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <!--<div class="col-md-2">
          <button class="btn btn-info font-size-12" (click)="printdata()">
            <i class="fa fa-print mr-1" style="font-size:medium;cursor: pointer;"></i> Print
          </button>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="VoucherDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 align-items-center">
          <div class="col-md-12">
            <h1 class="fs-12 h-b">
              Voucher Type -
              <span style="font-weight: 500; color: black"
                >{{ voucherModel.Voucher.Voucher_Type }} -
                {{ voucherModel.Voucher.Voucher_Sub_Type }}</span
              >
            </h1>
          </div>
        </div>
        <div class="row m-0 align-items-center">
          <div class="col-md-3">
            <h6 class="fs-12">Voucher No</h6>
            <p class="lead fs-12">{{ voucherModel.Voucher.Voucher_No }}</p>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">Voucher Date</h6>
            <p class="lead fs-12">{{ voucherModel.Voucher.Voucher_Date }}</p>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">Voucher ReceiptNo Book</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ReceiptNo_Book }}
            </p>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">Voucher Receipt Date</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ReceiptDate }}
            </p>
          </div>
        </div>
        <div class="row m-0 align-items-center">
          <div class="col-md-12">
            <h1 class="fs-12 h-b">
              Mode of Payment -<span style="font-weight: 500; color: black">{{
                voucherModel.Voucher.Voucher_Mode_of_Payment
              }}</span>
            </h1>
          </div>
        </div>
        <div class="row m-0 align-items-center">
          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <h6 class="fs-12">Cheque No</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_Cheque_No }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <h6 class="fs-12">Cheque Date</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_Cheque_Date }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <h6 class="fs-12">Bank Name</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_Bank_Name }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <h6 class="fs-12">Cheque Clear Date</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_Cheque_Clear_Date }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
          >
            <h6 class="fs-12">Ref No</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_E_Ref_No }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
          >
            <h6 class="fs-12">Transaction Date</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher_E_Transaction_Date }}
            </p>
          </div>
        </div>

        <div class="row m-0 align-items-center">
          <div
            class="table-responsive"
            *ngIf="
              voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
              voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
            "
          >
            <mat-table
              [dataSource]="voucherModel.Voucher_Detail"
              matSort
              matTableExporter
            >
              <ng-container matColumnDef="Type">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 5%"
                  >Type</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 5%"
                  >{{ row.TranType }}</mat-cell
                >
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 5%"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="CaseNo">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 15%"
                  >Case No</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 15%"
                  >{{ row.CaseNo }}</mat-cell
                >
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 15%"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Customer">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 10%"
                  >Customer</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 10%"
                  >{{ row.Customer }}</mat-cell
                >
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 10%"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Branch">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 10%"
                  >Branch</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 10%"
                  >{{ row.Branch_Name }}</mat-cell
                >
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 10%"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Category">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 20%"
                  >Category</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 20%"
                  >{{ row.ChagesHead }}</mat-cell
                >
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 20%"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="AccountHead">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 20%"
                  >Account Head</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 20%"
                  >{{ row.Account_Name }}</mat-cell
                >
                <mat-footer-cell *matFooterCellDef style="max-width: 20%"
                  >Total</mat-footer-cell
                >
              </ng-container>

              <ng-container matColumnDef="AmountDR">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 10%"
                  >Amount(DR)</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 10%"
                  >{{ row.AmountDR }}</mat-cell
                >
                <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                  calculateTranTypeDR()
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="AmountCR">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="max-width: 10%"
                  >Amount(CR)</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 10%"
                  >{{ row.AmountCR }}</mat-cell
                >
                <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                  calculateTranTypeCR()
                }}</mat-footer-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedLoanColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedLoanColumns"
              ></mat-row>
              <mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="displayedLoanColumns"
              ></mat-footer-row>
            </mat-table>
          </div>
        </div>
        <div class="row m-0 align-items-center">
          <div
            class="table-responsive"
            *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'"
          >
            <mat-table
              [dataSource]="voucherModel.Voucher_Detail"
              matSort
              matTableExporter
            >
              <ng-container matColumnDef="AccountHead">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Account Head</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Account_Name
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Branch">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Branch</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Branch_Name
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="AmountDR">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Amount(DR)</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.AmountDR
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  calculateTranTypeDR()
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="AmountCR">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Amount(CR)</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.AmountCR
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  calculateTranTypeCR()
                }}</mat-footer-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedAccountingColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedAccountingColumns"
              ></mat-row>
              <mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="displayedAccountingColumns"
              ></mat-footer-row>
            </mat-table>
          </div>
        </div>
        <div class="row m-0 mt-2">
          <div class="col-md-12">
            <h6 class="fs-12">Voucher Narration</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_Narration }}
            </p>
          </div>
        </div>

        <div class="row m-0 mt-2">
          <div class="col-md-3">
            <h6 class="fs-12">Voucher Created By</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_CreateBy }}
            </p>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">Voucher Created On</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_CreateOn }}
            </p>
          </div>
          <div class="col-md-3" *ngIf="Search.Type == 1">
            <h6 class="fs-12">Voucher Delete By</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ModifyBy }}
            </p>
          </div>

          <div class="col-md-3" *ngIf="Search.Type == 1">
            <h6 class="fs-12">Voucher Delete On</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ModifyOn }}
            </p>
          </div>
          <div class="col-md-3" *ngIf="Search.Type == 2">
            <h6 class="fs-12">Voucher Modify By</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ModifyBy }}
            </p>
          </div>

          <div class="col-md-3" *ngIf="Search.Type == 2">
            <h6 class="fs-12">Voucher Modify On</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_ModifyOn }}
            </p>
          </div>
          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_AuthorizBy != null"
          >
            <h6 class="fs-12">Voucher Authoriz By</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_AuthorizBy }}
            </p>
          </div>

          <div
            class="col-md-3"
            *ngIf="voucherModel.Voucher.Voucher_AuthorizOn != null"
          >
            <h6 class="fs-12">Voucher Authoriz On</h6>
            <p class="lead fs-12">
              {{ voucherModel.Voucher.Voucher_AuthorizOn }}
            </p>
          </div>
        </div>
        <div class="row col-md-12 m-0 mt-3 justify-content-end">
          <!--<button type="button" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px;"
            mat-raised-button color="primary" [hidden]="!ShowDelete" (click)="DeleteVoucher()" [class.spinner]="loading">
            Delete
          </button>
          <app-voucher-entry [voucherId]="this.voucherModel.Voucher.VoucherId" [index]="0" (action)="onAfterEdit()">
            <button type="button" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px;"
              mat-raised-button color="primary" [class.spinner]="loading">
              Edit
            </button>
          </app-voucher-entry>-->

          <button
            type="button"
            class="ml-4 mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Print
          </button>
          <button
            type="button"
            class="ml-4 mt-3 btn font-size-12 button-btn"
            (click)="OnCloseVoucherDetail()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="VoucherEditDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherEditDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let VoucherObj of voucherEditModel.Voucher">
          <div class="row m-0 align-items-center">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">
                Voucher Type -
                <span style="font-weight: 500; color: black">{{
                  VoucherObj.Voucher_Sub_Type
                }}</span>
              </h1>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-3">
              <h6 class="fs-12">Voucher No</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_No }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Voucher Date</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_Date }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Voucher ReceiptNo Book</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ReceiptNo_Book }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Voucher Receipt Date</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ReceiptDate }}</p>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">
                Mode of Payment -<span style="font-weight: 500; color: black">{{
                  VoucherObj.Voucher_Mode_of_Payment
                }}</span>
              </h1>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment == 'Cheque'"
            >
              <h6 class="fs-12">Cheque No</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_Cheque_No }}</p>
            </div>

            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment == 'Cheque'"
            >
              <h6 class="fs-12">Cheque Date</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_Cheque_Date }}</p>
            </div>

            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment == 'Cheque'"
            >
              <h6 class="fs-12">Bank Name</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_Bank_Name }}</p>
            </div>

            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment == 'Cheque'"
            >
              <h6 class="fs-12">Cheque Clear Date</h6>
              <p class="lead fs-12">
                {{ VoucherObj.Voucher_Cheque_Clear_Date }}
              </p>
            </div>

            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment != 'Cheque'"
            >
              <h6 class="fs-12">Ref No</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_E_Ref_No }}</p>
            </div>

            <div
              class="col-md-3"
              *ngIf="VoucherObj.Voucher_Mode_of_Payment != 'Cheque'"
            >
              <h6 class="fs-12">Transaction Date</h6>
              <p class="lead fs-12">{{ VoucherObj_E_Transaction_Date }}</p>
            </div>
          </div>

          <div class="row m-0 align-items-center">
            <div
              class="table-responsive"
              *ngIf="VoucherObj.Voucher_Sub_Type == 'Loan'"
            >
              <mat-table
                [dataSource]="VoucherObj.Voucher_Detail[0]"
                matSort
                matTableExporter
              >
                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 5%"
                    >Type</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 5%"
                    >{{ row.TranType }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 5%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="CaseNo">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 15%"
                    >Case No</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 15%"
                    >{{ row.CaseNo }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 15%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Customer">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >Customer</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.Customer }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 10%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >Branch</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.Branch_Name }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 10%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Category">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 20%"
                    >Category</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 20%"
                    >{{ row.ChagesHead }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 20%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="AccountHead">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 20%"
                    >Account Head</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 20%"
                    >{{ row.Account_Name }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 20%"
                    >Total</mat-footer-cell
                  >
                </ng-container>

                <ng-container matColumnDef="AmountDR">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >Amount(DR)</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.AmountDR }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                    calculateTranTypeEditDR(VoucherObj.Voucher_Detail[0])
                  }}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="AmountCR">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >Amount(CR)</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.AmountCR }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                    calculateTranTypeEditCR(VoucherObj.Voucher_Detail[0])
                  }}</mat-footer-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedLoanColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedLoanColumns"
                ></mat-row>
                <mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedLoanColumns"
                ></mat-footer-row>
              </mat-table>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div
              class="table-responsive"
              *ngIf="VoucherObj.Voucher_Sub_Type == 'Accounting'"
            >
              <mat-table
                [dataSource]="VoucherObj.Voucher_Detail[0]"
                matSort
                matTableExporter
              >
                <ng-container matColumnDef="AccountHead">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Account Head</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Account_Name
                  }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Branch_Name
                  }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="AmountDR">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount(DR)</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.AmountDR
                  }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef>{{
                    calculateTranTypeDR()
                  }}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="AmountCR">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount(CR)</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.AmountCR
                  }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef>{{
                    calculateTranTypeCR()
                  }}</mat-footer-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedAccountingColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAccountingColumns"
                ></mat-row>
                <mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedAccountingColumns"
                ></mat-footer-row>
              </mat-table>
            </div>
          </div>

          <div class="row m-0 mt-2">
            <div class="col-md-12">
              <h6 class="fs-12">Voucher Narration</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_Narration }}</p>
            </div>
          </div>

          <div class="row m-0 mt-2">
            <div class="col-md-3">
              <h6 class="fs-12">Voucher Created By</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_CreateBy }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Voucher Created On</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_CreateOn }}</p>
            </div>
            <div class="col-md-3" *ngIf="Search.Type == 1">
              <h6 class="fs-12">Voucher Delete By</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ModifyBy }}</p>
            </div>

            <div class="col-md-3" *ngIf="Search.Type == 1">
              <h6 class="fs-12">Voucher Delete On</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ModifyOn }}</p>
            </div>
            <div class="col-md-3" *ngIf="Search.Type == 2">
              <h6 class="fs-12">Voucher Modify By</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ModifyBy }}</p>
            </div>

            <div class="col-md-3" *ngIf="Search.Type == 2">
              <h6 class="fs-12">Voucher Modify On</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_ModifyOn }}</p>
            </div>
            <!--<div class="col-md-3">
              <h6 class="fs-12">Voucher Modify By</h6>
              <p class="lead fs-12">{{VoucherObj.Voucher_CreateBy}}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Voucher Modify On</h6>
              <p class="lead fs-12">{{VoucherObj.Voucher_CreateOn}}</p>
            </div>-->

            <div class="col-md-3" *ngIf="VoucherObj.Voucher_AuthorizBy != null">
              <h6 class="fs-12">Voucher Authoriz By</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_AuthorizBy }}</p>
            </div>

            <div class="col-md-3" *ngIf="VoucherObj.Voucher_AuthorizOn != null">
              <h6 class="fs-12">Voucher Authoriz On</h6>
              <p class="lead fs-12">{{ VoucherObj.Voucher_AuthorizOn }}</p>
            </div>
          </div>
          <hr />
        </ng-container>

        <div class="row col-md-12 m-0 mt-3 justify-content-end">
          <button
            type="button"
            class="ml-4 btn btn-light font-size-12"
            (click)="OnCloseVoucherEditDetail()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<table
  id="voucher_trail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Create By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Create On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Vocuher Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Sub Type
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Voucher_Date }}</td>
    <td>{{ x.Voucher_No }}</td>
    <td>{{ x.Voucher_CreateBy }}</td>
    <td>{{ x.Voucher_CreateOn }}</td>
    <td>{{ x.Voucher_Type }}</td>
    <td>{{ x.Voucher_Sub_Type }}</td>
  </tr>
</table>
