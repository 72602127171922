<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #ta="ngForm" class="formborder">
    <div class="col-md-12 row my-2" style="display: flex; align-items: center">
      <div class="col-md-2">
        <label for="DateSelected">Date From</label>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DateSelected"
            id="DateSelected"
            #ref_month_year="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="DateSelected"
            [ngClass]="{
              'is-invalid': ta.submitted && ref_month_year.invalid,
              'alert-warning':
                ref_month_year.invalid &&
                (ref_month_year.dirty ||
                  ref_month_year.touched ||
                  ref_month_year.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <label for="DateTo">Date To</label>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DateTo"
            id="DateTo"
            #ref_month_year="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="DateTo"
            [ngClass]="{
              'is-invalid': ta.submitted && ref_month_year.invalid,
              'alert-warning':
                ref_month_year.invalid &&
                (ref_month_year.dirty ||
                  ref_month_year.touched ||
                  ref_month_year.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>

      <div class="col-md-2">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': ta.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <ng-container
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive'
        "
      >
        <div class="col-md-3">
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': ta.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </ng-container>

      <div class="col-md-2">
        <div>&nbsp;</div>
        <button
          class="mt-2 btn font-size-12 button-btn"
          (click)="Get_Data()"
          [disabled]="ta.form.invalid"
        >
          Search
        </button>
      </div>
    </div>
  </form>
  <div cdkDropListGroup>
    <div
      id="DeleteContainer"
      class="scrollmenu"
      *ngIf="columns.length > 0"
      [cdkDragData]="{ name: 'DeleteCol', columIndex: 100 }"
      cdkDropList
      (cdkDropListDropped)="onColumnDrop($event)"
    >
      <span
        class="btn-group-toggle m-2"
        data-toggle="buttons"
        *ngFor="let column of columnshow; let i = index"
        cdkDrag
        [cdkDragData]="{ name: column, columIndex: i }"
      >
        <label
          class="btn py-0 px-1"
          [ngClass]="{ 'btn-secondary': displayedColumns.includes(column) }"
        >
          <input
            type="checkbox"
            [checked]="displayedColumns.includes(column)"
            autocomplete="off"
            (change)="checkedChange($event)"
            [id]="column"
          />
          {{ column }}
        </label>
      </span>
    </div>

    <div class="row m-0 col-md-12" *ngIf="this.dataSource.data.length > 0">
      <div class="table-responsive mt-3">
        <section
          class="table-container mat-elevation-z8"
          tabindex="0"
          style="height: 480px"
        >
          <mat-table
            id="TableInfo"
            [dataSource]="dataSource"
            class="mat-elevation-z8 table-striped"
            matSort
            cdkDropList
            cdkDropListOrientation="horizontal"
            style="min-width: max-content"
            matSort
            (cdkDropListDropped)="onColumnDrop($event)"
          >
            <ng-container
              *ngFor="let column of columns; let i = index"
              [matColumnDef]="column.field"
            >
              <mat-header-cell
                class="text-center columnclass"
                *matHeaderCellDef
                cdkDrag
                cdkDragPlaceholder
                [cdkDragData]="{ name: column.field, columIndex: i }"
                mat-sort-header
              >
                {{ column.field }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="columnclass">
                <div *ngIf="currentUser.IsMasking && (column.field == 'Customer_PhoneNo' || column.field =='C_Borrower_MobileNo')">{{ row[column.field]|mask}}</div>
                <div *ngIf="!currentUser.IsMasking && (column.field == 'Customer_PhoneNo' || column.field =='C_Borrower_MobileNo')">{{ row[column.field]}}</div>
                <div *ngIf="column.field != 'Customer_PhoneNo' &&  column.field !='C_Borrower_MobileNo'">{{ row[column.field]}}</div>


              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              (click)="selectHighligh(i)"
              *matRowDef="let row; let i = index; columns: displayedColumns"
              style="text-align: center"
              [ngClass]="{ highlight: selectedRow == i }"
            ></mat-row>
          </mat-table>
        </section>
      </div>
    </div>
    <div
      class="row m-0 ml-3 mr-3 pt-2 pb-1"
      [hidden]="!(this.dataSource.data.length > 0)"
    >
      <div class="col-md-4">
        <button
          class="mt-2 btn font-size-12 button-btn"
          (click)="exportTableAsXLSX()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8 text-right">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  *ngIf="dataSource.data.length > 0"
  id="collectionEfficiencyDetails_component"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th
      *ngFor="let header of displayedColumns"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      {{ header }}
    </th>
  </tr>
  <tr *ngFor="let data of dataSource.data; let ind = index">
    <td *ngFor="let header of displayedColumns">

     
      <div *ngIf="currentUser.IsMasking && (header == 'Customer_PhoneNo' || header =='C_Borrower_MobileNo')">{{ data[header]|mask}}</div>
      <div *ngIf="!currentUser.IsMasking && (header == 'Customer_PhoneNo' || header =='C_Borrower_MobileNo')">{{  data[header]}}</div>
      <div *ngIf="header != 'Customer_PhoneNo' &&  header !='C_Borrower_MobileNo'">{{  data[header]}}</div>

    </td>
  </tr>
</table>
