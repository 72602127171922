import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "src/app/Shared/dialog.service";
import { LosService } from "src/app/_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { element } from "protractor";
import { BsModalService } from "ngx-bootstrap/modal";
import * as XLSX from "xlsx";
import * as fs from "file-saver";
declare var $: any;
import * as FileSaver from "file-saver";
import { concatMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-los-search-customer",
  templateUrl: "./los-search-customer.component.html",
  styleUrls: ["./los-search-customer.component.scss"],
})
export class LosSearchCustomerComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("cpFile") cpFile: ElementRef;

  
  ElectricityStatelist: any[] = [];
  ElectricityOpCodelist: any[] = [];
  OperatorCode: any = "";
  ELState: any = "";
  check: any = false;
  checkaddress: any = false;
  removeBank: any[] = [];
  APIResponseData: any;
  private _MasterService: any;
  RequestModel: RequestModel = new RequestModel();
  CustomerViewDetailRequestModel: RequestModel = new RequestModel();
  showSpinner: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  FilterSearchBy: any = "";
  FilterSearchValue: any = "";
  dataSource: any;
  NotUpdate: boolean = false;
  InValidDataInDataBase: any = 0;
  insidetable: any;
  Verify_PAN_No: any;
  displayedColumns: string[] = [];
  @ViewChild("sortList", { static: true }) sort: MatSort;
  @ViewChild("PaginatorList", { static: true }) paginator: MatPaginator;
  currentUser: any;
  SearchCustomerList: any = [];
  customerData: any = {};
  Bank_Verification: boolean = true;
  Bank_VerificationKeys: any;
  VerifyIFSC: any;
  VerifyAccountNo: any;
  Bank_VerificationDetails: any;
  zoom: number = 13;
  lat: any = 26.92207;
  lng: any = 75.778885;
  ShowMapAddress: any = "";
  file: any;
  arrayBuffer: any;
  // address
  addressList: any = [];
  dataSourceAddress: any;
  displayedColumnsAddress: string[] = [
    "AddressId",
    "AddressType",
    "Address",
    "LandMark",
    "Tehsil_Name",
    "District_Name",
    "PinCode",
  ];
  @ViewChild("SortAddress") SortAddress: MatSort;
  // Category
  CategoryList: any = [];
  dataSourceCategory: any;
  @ViewChild("CategorySort") CategorySort: MatSort;
  displayedColumnsCategory: string[] = [
    "CategoyId",
    "Categoy",
    "Doc_Name",
    "KYC_DocNumber",
    "UploadOn",
    "EditAction",
  ];
  // Application
  ApplicationList: any = [];
  dataSourceApplication: any;
  @ViewChild("ApplicationSort") ApplicationSort: MatSort;
  displayedColumnsApplication: string[] = [
    "ApplicationId",
    "ApplicationNo",
    "CustomerType",
    "Product",
    "Branch",
    "LoanAmount",
    "CreateOn",
  ];
  cusId: any = 0;
  modalRef: any;
  Matchhdata: any;
  BlankDataFilldata: any;
  DuplicateDataFilldata: any;
  DuplicateData: boolean = false;

  ImportBulkCustomer: any[] = [];
  displayImportData: string[] = [
    "Id",
    "Customer_FirstName",
    "Customer_LastName",
    "Customer_Gender",
    "Customer_DOB",
    "Customer_PhoneNo",
    "Customer_WhatsAppNo",
    "Customer_Email",
    "Customer_Website",
    "Customer_Religion",
    "Customer_Cast",
    "Customer_MaritalStatus",
    "Customer_Relation",
    "Customer_Relation_FirstName",
    "Customer_Relation_LastName",
    "Present_Address",
    "Present_LandMark",
    "Present_TehsilId",
    "Present_DistrictId",
    "Present_StateId",
    "Present_PinCode",
    "Present_NearstBranchDistance_KM",
    "Present_TotalYearsOnAddress",
    "Present_AddressRentBuy",
    "Present_IsCommunicationAddress",
    "Permanent_Address",
    "Permanent_LandMark",
    "Permanent_TehsilId",
    "Permanent_DistrictId",
    "Permanent_StateId",
    "Permanent_PinCode",
    "Permanent_NearstBranchDistance_KM",
    "Permanent_TotalYearsOnAddress",
    "Permanent_AddressRentBuy",
    "Permanent_IsCommunicationAddress",
    "Aadhaar_No",
    "PAN_No",
    "VoterId_No",
    "Electricity_KNo",
  ];
  DL_DOB: any;
  toShowMobileOTPbutton: boolean = false;

  InValidData: boolean = false;
  constructor(
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private dialog: DialogService,
    private losService: LosService,
    private LmsService: LmsService,
    private encdec: EncrDecrService,
    private cdr: ChangeDetectorRef
  ) {
    this._MasterService = MasterService;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Search Customer");
    this.dataSharingService.LOSHeaderTitle.next("Search Customer");
    this.displayedColumns = [
      "CustomerId",
      "CustomertName",
      "FatherName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "TotalApplication",
      "EditAction",
    ];
    this.insidetable = [
      "CustomerId",
      "CustomertName",
      "RelationName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "Present_Address",
      "Select",
    ];
    //Code Copied
    this.dataSharingService.HeaderTitle.next("Customer Info");
    this.defaultDob = new Date();
    this.defaultDob.setFullYear(this.today.getUTCFullYear() - 18);

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.applicationModel = {
      Recommendation: "",
      ProcessId: this.ProcessId,
      ApplicationId: this.applicationId,
      LoginUserId: this.currentUser.userId,
    };
    this.getOccupationList();

    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Mobile_No_Validate" })
      .subscribe((res: any) => {
        if (res.length == 0) {
          this.OTPButton = false;
        }
      });
    this.FirmTypeBind();
    this.BusinessCategoryBind();
    this.BusinessTypeBind();
    this.PartnerDesignationBind();
    this.GetProductRequiredDoc();

    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Mobile_No_Validate" })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.toShowMobileOTPbutton = true;
        }
      });
  }

  // getSearchCusList() {
  //   if (this.FilterSearchBy == '') {
  //     this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Select Search By.', ...this.configSuccess });
  //     return;
  //   }
  //   if (this.FilterSearchValue == '') {
  //     this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Enter Value.', ...this.configSuccess });
  //     return;
  //   }
  //   this.showSpinner = true;
  //   this.RequestModel.SearchOn = this.FilterSearchBy;
  //   this.RequestModel.SearchValue = this.FilterSearchValue;

  //   this._MasterService.GetSearchCustomer(this.RequestModel).subscribe((result) => {
  //     if (result.length > 0) {
  //       this.SearchCustomerList = JSON.parse(JSON.stringify(result));
  //       this.dataSource = new MatTableDataSource(this.SearchCustomerList);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //     }
  //     else{
  //       this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
  //       this.dataSource =null;
  //     }
  //     this.showSpinner = false;
  //   });
  // }
  setConfig(id, value) {}

  // onCloseAddCustomer() {

  //  }

  // Copied Variable

  Directors: any;
  Charges: any;
  @ViewChild("btnRegisterNewCustomer") BtnRegisterNewCustomer: ElementRef;
  @ViewChild("btnUpdateCustomer") BtnUpdateCustomer: ElementRef;
  APIResponse: any = {
    DoucumentNumber: "",
    Full_name: "",
    IsVerified: "",
    category: "",
  };
  KYC_Configration: any[] = [];
  isUpdateAddress: boolean = false;
  isBtnSave: boolean = true;
  CurrentDate: string;
  // RequestModel: RequestModel = new RequestModel();
  clientId: any;
  ProfileData: any;
  CategoryData: any;
  SubCategoryData: any;
  NatureofworkData: any;
  record: any;
  insideDataSource = new MatTableDataSource([]);
  verify_DocNumber: any;
  verify_DocId: any;
  OTP: any;
  OTPCode: any;
  patnerData: any;

  applicationId: any;
  type: boolean = false;
  //LoanId; any;
  applicationDetail: any;
  loading: boolean = false;
  tab: any = "CD";
  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  // customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  dataCustomerSource: any;
  customerlist: any[] = [];
  PartnerDesignationList: any[] = [];
  applicationModel: any;
  today: Date = new Date();
  BusinessTypeList: any[] = [];
  BusinessCategoryList: any[] = [];
  OccupationData: any[] = [];

  defaultDob: Date;
  smsmodel: any = {};
  loginFeeModel: any = {};
  // FilterSearchBy: any = '';
  // FilterSearchValue: any = '';
  // dataSource: any;
  // displayedColumns: string[] = ['CustomerId', 'CustomertName', 'RelationName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'Present_Address', 'Select'];
  customerModel: any = {};
  isExistingCustomer: boolean = false;
  isDirectorExistingCustomer: boolean = false;
  CustomerKYCDoc: any[] = [];
  duplicateData: any;
  duplicateFirmData: any;
  duplicateType: any;
  CustomerList: any;
  FirmTypeList: any[] = [];
  Age: any;
  firmKYCDoc: any[] = [];
  firmPartners: any[] = [];
  isCustomerEdit: boolean = false;
  stateDropdown: any[] = [];
  districkDropdownReg: any[] = [];
  tehasilDropdownReg: any[] = [];
  districkDropdownCorp: any[] = [];
  tehasilDropdownCorp: any[] = [];
  districkDropdownPresent: any[] = [];
  tehasilDropdownPresent: any[] = [];
  districkDropdownPermanent: any[] = [];
  tehasilDropdownPermanent: any[] = [];
  districkDropdownWork: any[] = [];
  tehasilDropdownWork: any[] = [];
  addressModel: any = {};
  customerRefModel: any = {};
  customerAddressModel: any = {};
  customerPermanentAddressModel: any = {};
  customerWorkAddressModel: any = {};
  kycModel: any;
  isNewAddress: boolean = false;
  inViewMode: boolean = false;
  displayedDirectorsColumns: string[] = [
    "din_number",
    "director_name",
    "start_date",
    "end_date",
    "surrendered_din",
  ];
  displayedChargesColumns: string[] = [
    "assets_under_charge",
    "charge_amount",
    "date_of_creation",
    "date_of_modification",
    "status",
  ];
  BankForm: boolean = true;
  @Input() loan_Summary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  OTPButton: boolean = true;
  PageAcess: any;
  CustomerData: any;
  @Input() index: any;
  @Input() TypeFrom: any;
  @Input() ProcessId: any;
  Duplicate: any[] = [];
  DuplicateFirm: any[] = [];
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  ShowBorrower: boolean = true;
  ProductDocList: any = [];
  ProductDropdownList: any = [];
  FirmTypes: any;
  FirmHead: any;

  selectedSearchIndex: any;
  @Input() Heading: any;
  BankDetails: any[] = [];
  CustomerBank: any;
  @Input() Customer_Summary: any;
  ShowPrimary: boolean = true;

  dataSourceBlankDataFilldata: any;
  dataSourceDuplicate: any;
  dataSourceNotValiddata: any;

  // Methods Copied
  // getCustomerList() {
  //   this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loan_Summary.LoanId }).subscribe((result) => {
  //     this.CustomerList = JSON.parse(JSON.stringify(result));
  //   });
  // }
  // LMS_GetLoanDetails() {
  //   this.showSpinner = true;
  //   this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loan_Summary.LoanId }).subscribe((res: any) => {
  //     this.showSpinner = false;
  //     console.log("LMS_GetLoanDetails", res);
  //     this.applicationDetail = res.Item1[0];
  //     this.customerlist = res.Item2;

  //     if (this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' })) {
  //       this.ShowBorrower = false;
  //     }
  //   })
  // }

  // OnFinancialDetailClose(event: any) {
  //   if (event) {
  //     this.LMS_GetLoanDetails();
  //   }
  // }

  // calculateTax() {
  //   if (this.loginFeeModel.TFA) {
  //     let tta = +this.loginFeeModel.TFA * 18 / 100;
  //     let sgta = +this.loginFeeModel.TFA * 9 / 100;
  //     this.loginFeeModel.TaxAmount = tta;
  //     this.loginFeeModel.SGST_Amount = sgta;
  //     this.loginFeeModel.CGST_Amount = sgta;
  //     this.loginFeeModel.Amount = +this.loginFeeModel.TFA + tta;
  //   }
  // }

  displayAddCustomerModel() {
    $(`#addCustomer`).modal("show");
    $(`#addCustomer`).css("z-index", "1050");
    this.removeBank = [];
  }

  addCustomer() {
    this.showSpinner = true;
    this.Duplicate = [];
    this.BankDetails = [];
    this.removeBank = [];
    this.isCustomerEdit = false;
    this.cusId = 0;
    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Bank_Verification" })
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res.length == 0) {
          this.Bank_Verification = false;
        } else {
          this.Bank_Verification = true;
          this.Bank_VerificationKeys = res[0];
        }
        // this.customerModel = {
        //   ApplicationId: this.applicationId,
        //   Customer_Type: "",
        //   Customer_Relation: "",
        //   RegTotalYearsOnAddress: "",
        //   RegAddressRentBuy: "",
        //   RegNearstBranchDistance_KM: "",
        //   CorpTotalYearsOnAddress: "",
        //   CorpAddressRentBuy: "",
        //   CorpNearstBranchDistance_KM: "",
        //   BusinessType: "",
        //   BusinessCategory: "",
        //   Customer_Profile: "",
        //   Customer_Category: "",
        //   Customer_SubCategory: "",
        //   Customer_Natureofwork: "",
        // };
        this.isExistingCustomer = false;
        this.GetStateDropdown(false);

        this.addBankDetail();
        this.registereNewCustomer();
        this.displayAddCustomerModel();
      });
  }

  onCloseCustomer() {
    $(`#addCustomer`).modal("hide");
  }
  getSearchCusList() {
    if (this.FilterSearchBy == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Search By.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.FilterSearchValue == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Enter Value.",
        ...this.configSuccess,
      });
      return;
    }
    this.showSpinner = true;
    this.RequestModel.SearchOn = this.FilterSearchBy;
    this.RequestModel.SearchValue = this.FilterSearchValue;

    this._MasterService
      .GetSearchCustomer(this.RequestModel)
      .subscribe((result) => {
        let _searchData: any[] = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(_searchData);
        this.showSpinner = false;
        if (_searchData.length == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No search result found.",
            ...this.configSuccess,
          });
        }
      });
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({
      Loan_Id: this.loan_Summary.LoanId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      console.log("LMS_GetLoanDetails", res);
      this.applicationDetail = res.Item1[0];
      this.customerlist = res.Item2;

      if (
        this.customerlist.find((obj: any) => {
          return obj.CustomerType == "Hirer";
        })
      ) {
        this.ShowBorrower = false;
      }
    });
  }

  onCustomerChange(event: any, data: any) {
    this.customerModel.Customer_Relation = "";
    if (this.customerModel.CustomerId == data.CustomerId) {
      this.customerModel.CustomerId = "";
    } else {
      this.customerModel.CustomerId = data.CustomerId;
    }
    this.patnerData = data;
  }

  onAddExistingCustomer() {
    if (!this.customerModel.CustomerId) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Customer from above table.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.TypeFrom != "Deposit") {
      if (
        this.customerlist.find((obj: any) => {
          return obj.CustomerType == "Hirer";
        }) &&
        this.customerModel.Customer_Type == "H"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Borrower Already Exist.",
          ...this.configSuccess,
        });
        return;
      }
      if (this.customerModel.Customer_Type == "H") {
        this.customerModel.Customer_Relation = "Own";
      }
    } else {
      if (
        this.customerlist.find((obj: any) => {
          return obj.CustomerType == "Primary";
        }) &&
        this.customerModel.Customer_Type == "Primary"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Primary Customer Already Exist.",
          ...this.configSuccess,
        });
        return;
      }
      if (this.customerModel.Customer_Type == "Primary") {
        this.customerModel.Customer_Relation = "Own";
      }
    }

    this.loading = true;
    this.customerModel.LoginUserId = this.currentUser.userId;

    //console.log('this.customerModel', this.customerModel);

    if (this.TypeFrom == "Deposit") {
      this.patnerData.CustomerType = this.customerModel.Customer_Type;
      this.patnerData.ExistingCustomer = "Yes";
      let PassDAta = [this.patnerData];
      this.close.emit(PassDAta);
      this.loading = false;
      this.onCloseCustomer();
    } else if (this.TypeFrom == "LMS") {
      this.customerModel.Loan_Id =
        this.TypeFrom == "LMS" ? this.loan_Summary.LoanId : undefined;
      this.LmsService.LMS_SaveApplicationCustomerWEB(
        this.customerModel
      ).subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.LMS_GetLoanDetails();
          this.onCloseCustomer();
          this.close.emit(true);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
    } else {
      this.customerModel.ApplicationId =
        this.TypeFrom == "LOS"
          ? this.loan_Summary.ApplicationIdentity
          : undefined;
      this.losService
        .LOS_SaveApplicationCustomerWEB(this.customerModel)
        .subscribe((res: any) => {
          this.showSpinner = false;
          this.loading = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.LOS_GetApplicationDetailsForSaleProcess();
            this.onCloseCustomer();
            this.close.emit(true);
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
  onAddExistingCustomer_Firm() {
    this.isExistingCustomer = false;
    this.customerModel.Type = "Company";

    let length = this.firmPartners.length;
    this.firmPartners[length - 1].PartnerName = this.patnerData.CustomertName;
    this.firmPartners[length - 1].PartnerGender =
      this.patnerData.Customer_Gender;
    this.firmPartners[length - 1].PartnerAge = this.patnerData.GenderAge;
    this.firmPartners[length - 1].PartnerPhoneNo = this.patnerData.PhoneNo;
  }
  LOS_GetApplicationDetailsForSaleProcess() {
    this.customerlist = [];
    console.log("this.loan_Summary", this.loan_Summary);
    this.losService
      .LOS_GetApplicationDetailsForSaleProcess({
        ApplicationId: this.loan_Summary.ApplicationId.toString(),
        ProcessId: this.loan_Summary.ProcessId.toString(),
      })
      .subscribe((res: any) => {
        this.applicationDetail = res.Item1[0];
        this.customerlist = res.Item2;
        if (
          this.customerlist.find((obj: any) => {
            return obj.CustomerType == "Hirer";
          })
        ) {
          this.ShowBorrower = false;
        }
      });
  }

  removeCustomerFromApp(data: any) {
    this.dialog
      .openConfirmDialog(
        `Are you sure you want to remove ${data.Customer} from application.`
      )
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.LmsService.LMS_DeleteLoanCustomer({
            Loan_Id: this.loan_Summary.LoanId,
            customerId: data.CustomerId,
            LoginUserId: this.currentUser.userId,
          }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.LMS_GetLoanDetails();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
        }
      });
  }

  fileChangeListenerQ(data: any, files: any) {
    let reader = new FileReader();
    data.Answer = files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeQFile(data: any, input: any) {
    input.value = "";
    data.Answer = "";
    delete data.DocData;
  }

  registereNewCustomer() {
    this.getProfileDataList(false);
    this.CustomerKYCDoc = [];
    this.FirmTypeBind();
    this.BusinessCategoryBind();
    this.BusinessTypeBind();
    this.PartnerDesignationBind();
    // this.customerModel = {
    //   ApplicationId: this.applicationId,
    //   Type: "Indusial",
    //   Customer_Type: "",
    //   Customer_Relation: "",
    //   Customer_Relation_Type: "",
    //   Customer_PhoneNo_IsVerified: false,
    //   Customer_MaritalStatus: "",
    //   Customer_Religion: "",
    //   Customer_Cast: "",
    //   Customer_Occupation: 0,
    //   Customer_Gender: "M",
    //   Customer_Profile: "",
    //   Customer_Category: "",
    //   Customer_SubCategory: "",
    //   Customer_Natureofwork: "",
    //   BusinessCategory: "",
    // };
    this.customerAddressModel = {
      StateId: "",
      DistrictId: "",
      TehsilId: "",
      NoOfLiving: "",
      RentOwn: "",
    };
    this.customerPermanentAddressModel = {
      StateId: "",
      DistrictId: "",
      TehsilId: "",
      NoOfLiving: "",
      RentOwn: "",
    };
    this.customerWorkAddressModel = {
      StateId: "",
      DistrictId: "",
      TehsilId: "",
      NoOfLiving: "",
      RentOwn: "",
    };
    this.addMoreKYC();
    this.isExistingCustomer = !this.isExistingCustomer;
  }

  registereNewDirector() {
    this.CustomerKYCDoc = [];
    // this.customerModel = {
    //   ApplicationId: this.applicationId,
    //   Type: "Indusial",
    //   Customer_Type: "",
    //   Customer_Relation: "",
    //   Customer_PhoneNo_IsVerified: false,
    //   LoginUserId: this.currentUser.userId,
    //   Customer_MaritalStatus: "",
    //   Customer_Religion: "",
    //   Customer_Cast: "",
    // };
    this.customerAddressModel = "";
    this.addMoreKYC();
    this.isDirectorExistingCustomer = !this.isDirectorExistingCustomer;
  }
  changeCustomertype() {
    this.BankDetails = [];
    if (this.customerModel.Type == "Indusial") {
      //this.GetStateDropdown();
      this.BankDetails = [];
      this.CustomerKYCDoc = [];
      // this.customerModel = {
      //   ApplicationId: this.applicationId,
      //   Type: "Indusial",
      //   Customer_Type: "",
      //   Customer_Relation: "",
      //   Customer_Relation_Type: "",
      //   Customer_PhoneNo_IsVerified: false,
      //   LoginUserId: this.currentUser.userId,
      //   Customer_MaritalStatus: "",
      //   Customer_Religion: "",
      //   Customer_Cast: "",
      //   Customer_Occupation: 0,
      //   Customer_Profile: "",
      //   Customer_Category: "",
      //   Customer_SubCategory: "",
      //   Customer_Natureofwork: "",
      //   BusinessCategory: "",
      // };
      this.addMoreKYC();
      this.addBankDetail();
    } else {
      this.BankDetails = [];
      this.firmKYCDoc = [];
      this.firmPartners = [];
      // this.customerModel = {
      //   ApplicationId: this.applicationId,
      //   FirmId: 0,
      //   Type: "Company",
      //   SameAddress: false,
      //   Customer_Type: "",
      //   Customer_Relation: "Own",
      //   Firm_Type: "",
      //   Firm_RegState: "",
      //   Firm_RegDistrict: "",
      //   Firm_RegTehsil: "",
      //   Firm_CorpState: "",
      //   Firm_CorpDistrict: "",
      //   Firm_CorpTehsil: "",
      //   Firm_PhoneNoIsVerified: false,
      //   LoginUserId: this.currentUser.userId,
      //   Firm_RegTotalYearsOnAddress: "",
      //   Firm_RegAddressRentBuy: "",
      //   Firm_RegNearstBranchDistance_KM: "",
      //   Firm_CorpTotalYearsOnAddress: "",
      //   Firm_CorpAddressRentBuy: "",
      //   Firm_CorpNearstBranchDistance_KM: "",
      //   BusinessCategory: "",
      //   BusinessType: "",
      //   Customer_Profile: "",
      //   Customer_Category: "",
      //   Customer_SubCategory: "",
      //   Customer_Natureofwork: "",
      // };
      //this.GetStateDropdown();
      this.addFirmKyc();
      this.addFirmPartner();
      this.addBankDetail();
    }
  }

  changeCustomerRelationType() {
    this.BankDetails = [];
    this.customerModel.Customer_Relation = "";
    this.GetProductRequiredDoc();
    if (this.TypeFrom != "Deposit") {
      if (
        this.customerlist.length == 0 &&
        this.customerModel.Customer_Type != "H"
      ) {
        this.customerModel.Customer_Type = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add Borrower first !!.",
          ...this.configSuccess,
        });
        this.customerModel.Customer_Type = "";
        return;
      } else {
        this.customerModel.Customer_Relation =
          this.customerModel.Customer_Type == "H" ? "Own" : "";
      }
    } else {
      if (
        this.customerlist.length == 0 &&
        this.customerModel.Customer_Type != "Primary"
      ) {
        this.customerModel.Customer_Type = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add Primary Customer first !!.",
          ...this.configSuccess,
        });
        return;
      } else {
        this.customerModel.Customer_Relation =
          this.customerModel.Customer_Type == "Primary"
            ? "Own"
            : this.customerModel.Customer_Type;
      }
    }
    this.addBankDetail();
  }
  addFirmKyc() {
    if (this.firmKYCDoc.length > 0) {
      let _prev = this.firmKYCDoc[this.firmKYCDoc.length - 1];
      if (_prev.DocId == "" || _prev.DocNo == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.firmKYCDoc.push({
      KYC_DocId: "",
      KYC_DocNumber: "",
      KYC_DocFile: "",
      Verified_Button: false,
    });
  }
  addFirmPartner() {
    if (this.firmPartners.length > 0) {
      let _prev = this.firmPartners[this.firmPartners.length - 1];
      if (
        _prev.PartnerName == "" ||
        _prev.PartnerGender == "" ||
        _prev.PartnerAge == "" ||
        _prev.PartnerDesignation == "" ||
        _prev.PartnerPhoneNo == "" ||
        _prev.PartnerShare == ""
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.firmPartners.push({
      PartnerName: "",
      PartnerGender: "",
      PartnerAge: "",
      PartnerDesignation: "",
      PartnerPhoneNo: "",
      PartnerShare: "",
    });
  }
  removeFirmKYC(index: any) {
    this.firmKYCDoc.splice(index, 1);
  }
  removeFirmPartner(index: any) {
    this.firmPartners.splice(index, 1);
  }
  addMoreKYC() {
    if (this.CustomerKYCDoc.length > 0) {
      let _prev = this.CustomerKYCDoc[this.CustomerKYCDoc.length - 1];
      if (_prev.KYC_DocId == "" || _prev.KYC_DocNumber == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.CustomerKYCDoc.push({
      KYC_DocId: "",
      KYC_DocNumber: "",
      KYC_DocFile: "",
      KYC_DocFile1: "",
      Verified_Button: false,
    });
  }
  removeKYC(index: any) {
    this.CustomerKYCDoc.splice(index, 1);
  }
  fileChangeListener1(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.customerModel.Customer_ProfilePic = "";
      delete this.customerModel.DocData;
      return;
    }

    var AllowExt: any = ["jpg", "jpeg", "png"];
    if (!AllowExt.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.customerModel.Customer_ProfilePic = "";
      delete this.customerModel.DocData;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.customerModel.Customer_ProfilePic = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.customerModel.DocData = reader.result
        .toString()
        .split(";base64,")
        .pop();
      return;
    };
    this.cpFile.nativeElement.value = "";
  }
  removeFile1(input: any) {
    input.value = "";
    this.customerModel.Customer_ProfilePic = "";
    delete this.customerModel.DocData;
  }
  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.KYC_DocFile = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  fileChangeListener2(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile1 = "";
      delete data.DocData1;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile1 = "";
      delete data.DocData1;
      return;
    }

    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.KYC_DocFile1 = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData1 = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.KYC_DocFile = "";
    delete data.DocData;
  }
  removeFile2(data: any, input: any) {
    input.value = "";
    data.KYC_DocFile1 = "";
    delete data.DocData1;
  }
  fileChangeListenerFirm(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.KYC_DocFile = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.KYC_DocFile = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFileFirm(data: any, input: any) {
    input.value = "";
    data.DocImage = "";
    delete data.DocData;
  }
  getAge(event: any) {
    var date1 = new Date(this.customerModel.Customer_DOB);
    var date2 = new Date();

    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24); //Diference in Days
    var year = Days / 365;
    this.Age = Math.round(year);

    if (this.Age < 18) {
      this.customerModel.Customer_DOB = "";
      this.Age = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Customer age should not be less than 18 years !!",
        ...this.configSuccess,
      });
    }

    //console.log("age", this.Age);
  }
  checkPhonenumber() {
    if (
      this.customerModel.Customer_PhoneNo &&
      this.customerModel.Customer_PhoneNo.trim().length >= 10
    ) {
      this.duplicateType = "Mobile No";
      this.LOS_CheckCustomerDuplicationKYCApp(
        {
          Type: "PhoneNo",
          value: this.customerModel.Customer_PhoneNo,
        },
        ""
      );
    }
  }
  checkFirmPhonenumber() {
    if (
      this.customerModel.Firm_PhoneNo &&
      this.customerModel.Firm_PhoneNo.trim().length >= 10
    ) {
      this.duplicateType = "Mobile No";
      this.LMS_CheckFirmDuplicationKYCApp({
        Type: "PhoneNo",
        value: this.customerModel.Firm_PhoneNo,
      });
    }
  }

  checkDuplicate(data: any) {
    if (
      data.KYC_DocId != "" &&
      data.KYC_DocNumber &&
      data.KYC_DocNumber.trim().length > 0
    ) {
      let _type: any = "";
      if (+data.KYC_DocId == 1) {
        _type = "PAN Card";
      }
      if (+data.KYC_DocId == 3) {
        _type = "Passport Copy";
      }
      if (+data.KYC_DocId == 4) {
        _type = "Voter ID Card";
      }
      if (+data.KYC_DocId == 5) {
        _type = "Driving License";
      }
      if (+data.KYC_DocId == 6) {
        _type = "Aadhaar Card";
      }
      this.duplicateType = _type;
      this.LOS_CheckCustomerDuplicationKYCApp(
        { Type: _type, value: data.KYC_DocNumber },
        data
      );
    }
  }

  onRegisterNewCustomer(id = 0) {
    if (this.firmPartners.length < this.customerModel.Firm_No_Of_Partner) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `No. of ${this.FirmHead} mismatch`,
        ...this.configSuccess,
      });
      return;
    }
    if (this.TypeFrom != "Deposit") {
      var DocExist;
      let KYCDocs =
        this.customerModel.Type == "Company"
          ? this.firmKYCDoc
          : this.CustomerKYCDoc;

      if (
        this.customerlist.find((obj: any) => {
          return obj.CustomerType == "Hirer";
        }) &&
        this.customerModel.Customer_Type == "H"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Borrower Already Exist.",
          ...this.configSuccess,
        });
        return;
      }
      // this.customerModel.Id = this.TypeFrom == 'LMS' ? this.loan_Summary.LoanId : this.loan_Summary.ApplicationIdentity;
    } else {
      if (
        this.customerlist.find((obj: any) => {
          return obj.CustomerType == "Primary";
        }) &&
        this.customerModel.Customer_Type == "Primary"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Primary Customer Already Exist.",
          ...this.configSuccess,
        });
        return;
      }
    }

    this.customerModel.Customer_CreateBy = this.currentUser.userId;
    this.customerModel.TypeFrom = this.TypeFrom;

    if (this.customerModel.Type == "Company") {
      let _total: any = 0;
      this.firmPartners.forEach((obj: any) => {
        _total = _total + Number(obj.PartnerShare);
      });

      if (_total == 100) {
        this.customerModel.NearstBranchDistance_KM =
          this.customerModel.RegTotalYearsOnAddress;
        this.customerModel.TotalYearsOnAddress =
          this.customerModel.RegNearstBranchDistance_KM;
        this.customerModel.AddressRentBuy =
          this.customerModel.RegAddressRentBuy;
        this.customerModel.NearstBranchDistance_KM =
          this.customerModel.RegTotalYearsOnAddress;
        this.customerModel.TotalYearsOnAddress =
          this.customerModel.RegNearstBranchDistance_KM;
        this.customerModel.AddressRentBuy =
          this.customerModel.RegAddressRentBuy;
        var bankinfo = [];

        this.BankDetails.forEach((obj: any) => {
          bankinfo.push(obj);
        });
        if (this.removeBank.length > 0) {
          this.removeBank.forEach((obj: any) => {
            obj.isDelete = 1;
            bankinfo.push(obj);
          });
        }
        let _data = {
          Firm: {
            ...this.customerModel,
          },

          KYC_DOC: this.firmKYCDoc,
          Partners: this.firmPartners,
          address: this.customerAddressModel,
          CustomerBankDetail: bankinfo,
        };

        if (this.TypeFrom != "Deposit") {
          if (_data.Firm.Customer_Type == "H") {
            _data.Firm.Customer_Relation == "Own";
          }
        } else {
          if (_data.Firm.Customer_Type == "Primary") {
            _data.Firm.Customer_Relation == "Own";
          }
        }

        this.showSpinner = true;
        //console.log('_data Company', _data);
        _data.Firm.LoginUserId = this.currentUser.userId;

        this.losService
          .Save_Customer_FirmOutSide({ JSON: JSON.stringify(_data) })
          .subscribe((res: any) => {
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.uploadFirmDoc(res[0].CODE, this.firmKYCDoc);

              if (this.TypeFrom != "Deposit") {
                this.close.emit(true);
              } else {
                _data.Firm.CustomerId = res[0].CODE;
                _data.Firm.ExistingCustomer = "No";
                this.close.emit(_data.Firm);
              }
            }
            this.showSpinner = false;
            this.loading = false;
          });
      } else {
        let type =
          this.FirmTypes == "Company"
            ? `${this.FirmHead} Share Ratio`
            : "Partner Ship Ratio";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: `${type} should be equal to 100%.`,
          ...this.configSuccess,
        });
      }
    } else {
      //this.customerModel.Customer_Gender = this.duplicateData.customer.Customer_Gender;

      this.customerWorkAddressModel =
        this.customerWorkAddressModel.StateId != ""
          ? this.customerWorkAddressModel
          : [];
      this.customerModel.Customer_PhoneNo_IsVerified =
        this.customerModel.Customer_PhoneNo_IsVerified == false ? 0 : 1;
      var bankinfo = [];

      this.BankDetails.forEach((obj: any) => {
        bankinfo.push(obj);
      });
      if (this.removeBank.length > 0) {
        this.removeBank.forEach((obj: any) => {
          obj.isDelete = 1;
          bankinfo.push(obj);
        });
      }

      let _data = {
        ApplicationCustomer: {
          ...this.customerModel,
        },
        CustomerKYCDoc: this.CustomerKYCDoc,
        address: this.customerAddressModel,
        customerPermanent: this.customerPermanentAddressModel,
        customerWork: this.customerWorkAddressModel,
        CustomerBankDetail: bankinfo,
        Int_Id: id,
        // LoanId: this.loan_Summary.LoanId
      };

      if (this.TypeFrom != "Deposit") {
        if (_data.ApplicationCustomer.Customer_Type == "H") {
          _data.ApplicationCustomer.Customer_Relation == "Own";
        }
      } else {
        if (_data.ApplicationCustomer.Customer_Type == "Primary") {
          _data.ApplicationCustomer.Customer_Relation == "Own";
        }
      }

      this.showSpinner = true;

      _data.customerPermanent.District = Number(
        _data.customerPermanent.District
      );
      _data.customerPermanent.NoOfLiving = Number(
        _data.customerPermanent.NoOfLiving
      );
      _data.customerPermanent.Tehsil = Number(_data.customerPermanent.Tehsil);

      _data.ApplicationCustomer.DocData = "";

      /*_data.CustomerKYCDoc.forEach((obj: any) => {
        obj.DocData = '';
      });*/

      console.log("_data", _data);

      this.LmsService.Save_CustomerOutSide({
        JSON: JSON.stringify(_data),
        CustomerId: this.cusId,
      }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          if (this.customerModel.DocData) {
            this.uploadProfilePic(res[0].CODE);
          }
          this.uploadKycDoc(res[0].CODE, this.CustomerKYCDoc);
          this.uploadKycDoc1(res[0].CODE, this.CustomerKYCDoc);
          this.onCloseCustomer();

          if (this.TypeFrom != "Deposit") {
            this.close.emit(true);
          } else {
            let _Cdata = {
              CustomerId: res[0].CODE,
              CustomertName:
                this.customerModel.Customer_FirstName +
                " " +
                this.customerModel.Customer_LastName,
              CustomerType: this.customerModel.Customer_Type,
              Customer_Gender: this.customerModel.Customer_Gender,
              GenderAge: this.Age,
              PhoneNo: this.customerModel.Customer_PhoneNo,
              Relation_Type: this.customerModel.Customer_Relation,
              Present_Address: this.customerPermanentAddressModel.Address,
              LandMark: this.customerPermanentAddressModel.LandMark,
              PinCode: this.customerPermanentAddressModel.PinCode,
              ExistingCustomer: "No",
            };

            this.close.emit([_Cdata]);
          }
        }
        this.showSpinner = false;
        this.onCloseCustomer();
      });
    }

    this.onCloseCustomer();
  }

  onUpdateCustomerAddress() {
    if (this.customerModel.Type == "Company") {
      let _data = {
        Firm: {
          ...this.customerModel,
        },
        KYC_DOC: this.firmKYCDoc,
        Partners: this.firmPartners,
        address: this.customerAddressModel,
      };
      if (_data.Firm.Customer_Type == "H") {
        _data.Firm.Customer_Relation == "Own";
      }

      /*if (_data.Firm.Customer_Type == "C") {
        _data.Firm.Customer_Relation = "CO-Borrower";
      }
      if (_data.Firm.Customer_Type == "G") {
        _data.Firm.Customer_Relation = "Guarantor";
      }*/

      this.showSpinner = true;
      _data.Firm.LoanId = this.loan_Summary.LoanId;

      //console.log('_data Company', _data);
      this.LmsService.SaveCustomerFirmDetails({
        JSON: JSON.stringify(_data),
      }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          // this.LMS_GetLoanDetails();
          this.customerAddressModel = "";
          this.customerPermanentAddressModel = "";
          this.uploadFirmDoc(res[0].CODE, this.firmKYCDoc);
        }
        this.showSpinner = false;
      });
    } else {
      let _data = {
        ApplicationCustomer: {
          ...this.customerModel,
        },
        CustomerKYCDoc: this.CustomerKYCDoc,
        address: this.customerAddressModel,
        customerPermanent: this.customerPermanentAddressModel,
        customerWork: this.customerWorkAddressModel,
        Int_Id: this.customerModel.CustomerId,
        LoanId: this.loan_Summary.LoanId,
      };
      if (_data.ApplicationCustomer.Customer_Type == "H") {
        _data.ApplicationCustomer.Customer_Relation == "Own";
      }

      /*if (_data.ApplicationCustomer.Customer_Type == "C") {
        _data.ApplicationCustomer.Customer_Relation = "CO-Borrower";
      }
      if (_data.ApplicationCustomer.Customer_Type == "G") {
        _data.ApplicationCustomer.Customer_Relation = "Guarantor";
      }*/
      this.showSpinner = true;

      _data.customerPermanent.District = Number(
        _data.customerPermanent.District
      );
      _data.customerPermanent.NoOfLiving = Number(
        _data.customerPermanent.NoOfLiving
      );
      _data.customerPermanent.Tehsil = Number(_data.customerPermanent.Tehsil);

      // console.log('onUpdateCustomerAddress------', _data);
      this.LmsService.SaveCustomerInfoDetails({
        JSON: JSON.stringify(_data),
      }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          // this.LMS_GetLoanDetails();
          this.isUpdateAddress = false;
          let ele = document.getElementById("btnSaveCust");
          ele.style.display = "block";
          if (this.customerModel.DocData) {
            this.uploadProfilePic(res[0].CODE);
          }
          this.uploadKycDoc(res[0].CODE, this.CustomerKYCDoc);
          this.uploadKycDoc1(res[0].CODE, this.CustomerKYCDoc);
        }
        this.showSpinner = false;
      });
    }
  }

  onSAVEOTP() {
    //console.log("this.customerModel", this.customerModel);
    let phoneNo =
      this.customerModel.Type == "Indusial"
        ? this.customerModel.Customer_PhoneNo
        : this.customerModel.Firm_PhoneNo;

    this._MasterService
      .SAVE_OTP_Verification({ PhoneNo: phoneNo, OTPCode: this.OTP })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          if (this.customerModel.Type == "Indusial") {
            this.customerModel.Customer_PhoneNo_IsVerified = true;
          } else {
            this.customerModel.Firm_PhoneNoIsVerified = true;
          }
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OTP = "";
          $(`#OTPModel`).modal("hide");
          setTimeout(() => {
            $("body").addClass("modal-open");
          }, 1000);
        } else if (res[0].CODE != 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  ongetotp() {
    if (this.customerModel.Type == "Indusial") {
      if (this.customerModel.Customer_PhoneNo != undefined) {
        if (this.customerModel.Customer_PhoneNo.length == 10) {
          //this._MasterService.Generate_OTPPhoneNoVerification({ PhoneNo: this.customerModel.Customer_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0 }).subscribe((res: any) => {
          //  this.smsmodel.MobileNo = this.customerModel.Customer_PhoneNo;
          //  this.smsmodel.sentMessage = res[0].OTPCode + " is your OTP to validate your Mobile No. Finnaux Techsolutions Pvt. Ltd.";
          //  this._MasterService.SendSMS(this.smsmodel).subscribe((res: any) => {
          //    $('#OTPModel').modal('show');
          //    $("#OTPModel").css("z-index", "1050");
          //  })
          //})

          this._MasterService
            .Generate_OTPPhoneNoVerification({
              PhoneNo: this.customerModel.Customer_PhoneNo,
              LoginUserId: this.currentUser.userId,
              CustomerId: 0,
            })
            .subscribe((result: any) => {
              this.OTPCode = result[0].OTPCode;

              this._MasterService
                .Get_Sms_Configration({ Step_Name: "Mobile_No_Validate" })
                .subscribe((res: any) => {
                  if (res.length > 0) {
                    this.smsmodel.MobileNo =
                      this.customerModel.Customer_PhoneNo;
                    res[0].SMS_Template = res[0].SMS_Template.replace(
                      "@OTP",
                      this.OTPCode
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[KEY]",
                      res[0].Key
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[CAMPAIGN]",
                      res[0].campaign
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[ROUTE]",
                      res[0].route
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[TYPE]",
                      res[0].SubType
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[MOBILENO]",
                      this.customerModel.Customer_PhoneNo
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[SENDERID]",
                      res[0].Sender_Id
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[MSG]",
                      res[0].SubType == "unicode"
                        ? this._MasterService.utf8Encode(res[0].SMS_Template)
                        : res[0].SMS_Template
                    );

                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[TEMPLATE_ID]",
                      res[0].TemplateId
                    );
                    res[0].Sub_url = res[0].Sub_url.replace("\r", "");
                    res[0].Sub_url = res[0].Sub_url.replace("\n", "");
                    this.smsmodel.sentMessage = res[0].SMS_Template;
                    this.smsmodel.SMS_URL = res[0].SMS_URL;
                    this.smsmodel.Sub_url = res[0].Sub_url;

                    this._MasterService
                      .MessageSend(this.smsmodel)
                      .subscribe((res: any) => {
                        $(`#OTPModel`).modal("show");
                        $(`#OTPModel`).css("z-index", "1090");
                      });
                  }
                });
            });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please enter valid phone no",
            ...this.configSuccess,
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please enter phone no",
          ...this.configSuccess,
        });
      }
    } else {
      if (this.customerModel.Firm_PhoneNo != undefined) {
        if (this.customerModel.Firm_PhoneNo.length == 10) {
          //this._MasterService.Generate_OTPPhoneNoVerification({ PhoneNo: this.customerModel.Firm_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0 }).subscribe((res: any) => {
          //  this.smsmodel.MobileNo = this.customerModel.Firm_PhoneNo;
          //  this.smsmodel.sentMessage = res[0].OTPCode + " is your OTP to validate your Mobile No. Finnaux Techsolutions Pvt. Ltd.";
          //  this._MasterService.SendSMS(this.smsmodel).subscribe((res: any) => {
          //    $('#OTPModel').modal('show');
          //    $("#OTPModel").css("z-index", "1050");
          //  })
          //})
          this._MasterService
            .Generate_OTPPhoneNoVerification({
              PhoneNo: this.customerModel.Firm_PhoneNo,
              LoginUserId: this.currentUser.userId,
              CustomerId: 0,
            })
            .subscribe((result: any) => {
              this.OTPCode = result[0].OTPCode;
              this._MasterService
                .Get_Sms_Configration({ Step_Name: "Mobile_No_Validate" })
                .subscribe((res: any) => {
                  if (res.length > 0) {
                    this.smsmodel.MobileNo = this.customerModel.Firm_PhoneNo;
                    res[0].SMS_Template = res[0].SMS_Template.replace(
                      "@OTP",
                      this.OTPCode
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[KEY]",
                      res[0].Key
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[CAMPAIGN]",
                      res[0].campaign
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[ROUTE]",
                      res[0].route
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[TYPE]",
                      res[0].SubType
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[MOBILENO]",
                      this.customerModel.Firm_PhoneNo
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[SENDERID]",
                      res[0].Sender_Id
                    );
                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[MSG]",
                      res[0].SubType == "unicode"
                        ? this._MasterService.utf8Encode(res[0].SMS_Template)
                        : res[0].SMS_Template
                    );

                    res[0].Sub_url = res[0].Sub_url.replace(
                      "[TEMPLATE_ID]",
                      res[0].TemplateId
                    );
                    res[0].Sub_url = res[0].Sub_url.replace("\r", "");
                    res[0].Sub_url = res[0].Sub_url.replace("\n", "");
                    this.smsmodel.sentMessage = res[0].SMS_Template;
                    this.smsmodel.SMS_URL = res[0].SMS_URL;
                    this.smsmodel.Sub_url = res[0].Sub_url;

                    this._MasterService
                      .MessageSend(this.smsmodel)
                      .subscribe((res: any) => {
                        $(`#OTPModel`).modal("show");
                        $(`#OTPModel`).css("z-index", "1090");
                      });
                  }
                });
            });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please enter valid phone no",
            ...this.configSuccess,
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please enter phone no",
          ...this.configSuccess,
        });
      }
    }
  }

  // onUpdateCustomer() {
  //   this.customerModel.TypeFrom = this.TypeFrom;
  //   this.customerModel.Id = this.loan_Summary.LoanId;

  //   if (this.customerModel.Type == 'Company') {
  //     let _data = {
  //       Firm: {
  //         ...this.customerModel
  //       },
  //       Partners: this.firmPartners,
  //       CustomerBankDetail: this.BankDetails
  //     }
  //     if (_data.Firm.Customer_Type == "H") {
  //       _data.Firm.Customer_Relation == "Own";
  //     }
  //     //_data.Firm.LoanId = this.loan_Summary.LoanId;
  //     this.showSpinner = true;

  //     if(this.TypeFrom == 'LOS'){
  //       _data.Firm.ApplicationId = this.loan_Summary.ApplicationIdentity;
  //       //console.log('_data LOS', _data);
  //       this.losService.LOS_SaveApplicationCustomerFirm({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
  //         if (res[0].CODE >= 0) {
  //           this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
  //           this.uploadFirmDoc(this.customerModel.FirmId, this.firmKYCDoc);
  //           this.close.emit(true);
  //         }
  //         this.showSpinner = false;
  //         this.loading = false;
  //       });
  //     }
  //     else{
  //       _data.Firm.LoanId = this.loan_Summary.LoanId;
  //       //console.log('_data LMS', _data);
  //       this.LmsService.SaveCustomerFirmDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
  //         if (res[0].CODE >= 0) {
  //           this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //           this.LMS_GetLoanDetails();
  //           this.uploadFirmDoc(this.customerModel.FirmId, this.firmKYCDoc);
  //           this.close.emit(true);
  //         }
  //         this.showSpinner = false;
  //       });
  //     }
  //   }
  //   else {
  //     let _data = {
  //       ApplicationCustomer: {
  //         ...this.customerModel
  //       },
  //       address: this.customerAddressModel,
  //       customerPermanent: this.customerPermanentAddressModel,
  //       customerWork: this.customerWorkAddressModel,
  //       CustomerBankDetail: this.BankDetails
  //     }

  //     if (_data.ApplicationCustomer.Customer_Type == "H") {
  //       _data.ApplicationCustomer.Customer_Relation = "Own";
  //     }
  //     _data.ApplicationCustomer.Customer_CreateBy = this.currentUser.userId;
  //     console.log("_data", _data);
  //     this.showSpinner = true;

  //     this.losService.Update_Customer_Details_Web({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
  //       if (res[0].CODE >= 0) {
  //         this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //         this.LMS_GetLoanDetails();
  //         if (this.customerModel.DocData) {
  //           this.uploadProfilePic(_data.ApplicationCustomer.CustomerId);

  //           this.onCloseCustomer();

  //         }
  //         else {
  //           this.onCloseCustomer();
  //         }
  //         this.close.emit(true);
  //       }
  //       this.showSpinner = false;
  //     });
  //   }
  // }

  getCustomerFullDetail(row) {
    console.log(
      "Get Customer Full Detail api call, when ,edit button is clicked from table"
    );

    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Bank_Verification" })
      .subscribe((res: any) => {
        if (res.length == 0) {
          console.log("sms config api call");
          this.Bank_Verification = false;
        } else {
          console.log("sms config api call");
          this.Bank_VerificationKeys = res[0];
        }
      });
    this.cusId = row.CustomerId;
    // this.registereNewCustomer();
    this.Get_CustomerDetailsApp(row);
  }

  uploadProfilePic(id: any) {
    this.losService
      .UploadCustomerDoc({
        CustomerID: id,
        DocName: this.customerModel.Customer_ProfilePic,
        DocData: this.customerModel.DocData,
      })
      .subscribe((res: any) => {});
  }
  uploadFirmDoc(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService
          .UploadCustomerDoc({
            CustomerID: id,
            DocName: obj.DocImage,
            DocData: obj.DocData,
          })
          .subscribe((res: any) => {});
      }
    });
    this.onCloseCustomer();
  }
  uploadKycDoc(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService
          .UploadCustomerDoc({
            CustomerID: id,
            DocName: obj.KYC_DocFile,
            DocData: obj.DocData,
          })
          .subscribe((res: any) => {});
      }
    });
    this.onCloseCustomer();
  }
  uploadKycDoc1(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData1) {
        this.losService
          .UploadCustomerDoc({
            CustomerID: id,
            DocName: obj.KYC_DocFile1,
            DocData: obj.DocData1,
          })
          .subscribe((res: any) => {});
      }
    });
    this.onCloseCustomer();
  }
  LOS_CheckCustomerDuplicationKYCApp(data: any, row: any) {
    this.LmsService.LMS_CheckCustomerDuplicationKYCApp(data).subscribe(
      (res: any) => {
        //console.log('LMS_CheckCustomerDuplicationKYCApp', res);
        if (res.Item1[0].CustomerId > 0) {
          this.Duplicate = res.Item1;
          this.duplicateData = {
            customer: res.Item1[0],
            kycDetails: res.Item2,
            address: res.Item3,
            bankDetail: res.Item4,
          };
          //console.log('LOS_CheckCustomerDuplicationKYCApp======>>>', res);
          if (row != "") {
            if (
              row.KYC_DocId == 1 ||
              row.KYC_DocId == 3 ||
              row.KYC_DocId == 4 ||
              row.KYC_DocId == 5 ||
              row.KYC_DocId == 6 ||
              row.KYC_DocId == 28 ||
              row.KYC_DocId == 29
            ) {
              row.KYC_DocNumber = "";
              this.NotUpdate = true;
            } else {
              this.NotUpdate = false;
            }
          }
          $(`#duplicateData`).modal("show");
          $(`#duplicateData`).css("z-index", "1060");
        }
      }
    );
  }
  LMS_CheckFirmDuplicationKYCApp(data: any) {
    this.LmsService.LMS_CheckFirm_DuplicationApp(data).subscribe((res: any) => {
      if (res.Item1[0].CustomerId > 0) {
        this.DuplicateFirm = res.Item1;
        this.duplicateFirmData = {
          customer: res.Item1[0],
          kycDetails: res.Item2,
          address: res.Item3,
          patner: res.Item4,
        };
        console.log("LMS_CheckFirm_DuplicationApp======>>>", res);
        $(`#duplicateFirmData`).modal("show");
        $(`#duplicateFirmData`).css("z-index", "1060");
      }
    });
  }
  onNoClick() {
    $(`#duplicateData`).modal("hide");
    this.duplicateData = undefined;
    this.duplicateType = undefined;
    setTimeout(() => {
      $("body").addClass("modal-open");
    }, 1000);
  }
  onNoFirmClick() {
    $(`#duplicateFirmData`).modal("hide");
    this.duplicateFirmData = undefined;
    this.duplicateType = undefined;
    setTimeout(() => {
      $("body").addClass("modal-open");
    }, 1000);
  }
  onYesClick() {
    //console.log("this.duplicateData", this.duplicateData);
    this.isUpdateAddress = true;
    let ele = document.getElementById("btnSaveCust");
    ele.style.display = "none";
    this.customerModel.CustomerId = this.duplicateData.customer.CustomerId;
    this.customerModel.Customer_FirstName =
      this.duplicateData.customer.Customer_FirstName;
    this.customerModel.Customer_LastName =
      this.duplicateData.customer.Customer_LastName;
    this.customerModel.Customer_FatherName =
      this.duplicateData.customer.Customer_FatherName;
    this.customerModel.Customer_Gender =
      this.duplicateData.customer.Customer_Gender;
    this.customerModel.Customer_Occupation = 0;
    this.customerModel.Customer_DOB = this.duplicateData.customer.Customer_DOB
      ? new Date(this.duplicateData.customer.Customer_DOB)
      : "";
    this.customerModel.Customer_ProfilePic =
      this.duplicateData.customer.Customer_ProfilePic;
    this.customerModel.Customer_PhoneNo =
      this.duplicateData.customer.Customer_PhoneNo;
    this.customerModel.Customer_PhoneNo1 =
      this.duplicateData.customer.Customer_PhoneNo1;
    this.customerModel.Customer_PhoneNo2 =
      this.duplicateData.customer.Customer_PhoneNo2;
    this.customerModel.Customer_WhatsAppNo =
      this.duplicateData.customer.Customer_WhatsAppNo;
    this.customerModel.Customer_PhoneNo_IsVerified =
      this.duplicateData.customer.Customer_PhoneNo_IsVerified;
    this.customerModel.Customer_Email =
      this.duplicateData.customer.Customer_Email;
    this.customerModel.Customer_MaritalStatus =
      this.duplicateData.customer.Customer_MaritalStatus || "";
    this.customerModel.Customer_Religion =
      this.duplicateData.customer.Customer_Religion || "";
    this.customerModel.Customer_Cast =
      this.duplicateData.customer.Customer_Cast || "";
    this.customerModel.Customer_Relation_Type =
      this.duplicateData.customer.Customer_Relation_Type || "";
    this.customerModel.Customer_Relation_FirstName =
      this.duplicateData.customer.Customer_Relation_FirstName;
    this.customerModel.Customer_Relation_LastName =
      this.duplicateData.customer.Customer_Relation_LastName;

    this.CustomerKYCDoc = this.duplicateData.kycDetails;
    this.BankDetails = this.duplicateData.bankDetail;
    this.getAge(this.customerModel.Customer_DOB);
    this.isCustomerEdit = true;
    if (this.duplicateData.address.length > 0) {
      this.duplicateData.address.forEach((element) => {
        if (element.AddressType == "Present") {
          this.customerAddressModel.Address = element.Address;
          this.customerAddressModel.LandMark = element.Landmark;
          this.customerAddressModel.PinCode = element.Pincode;
          this.customerAddressModel.StateId = element.StateId;
          this.customerAddressModel.DistanceBranch =
            element.NearstBranchDistance_KM;
          this.losService
            .GetDistrickDropdown({ StateID: element.StateId })
            .subscribe((res: any) => {
              this.districkDropdownPresent = res;
              this.customerAddressModel.DistrictId = element.DistrictId;
            });

          this.losService
            .GetTehasilDropdown({ DistrictId: element.DistrictId })
            .subscribe((res: any) => {
              this.tehasilDropdownPresent = res;
              this.customerAddressModel.TehsilId = element.TehsilId;
            });

          this.customerAddressModel.NoOfLiving = element.TotalYearsOnAddress;
          this.customerAddressModel.RentOwn = element.AddressRentBuy;
          this.customerAddressModel.DistaneBranch =
            element.NearstBranchDistance_KM;
        }
        if (element.AddressType == "Permanent") {
          this.customerPermanentAddressModel.Address = element.Address;
          this.customerPermanentAddressModel.LandMark = element.Landmark;
          this.customerPermanentAddressModel.PinCode = element.Pincode;
          this.customerPermanentAddressModel.StateId = element.StateId;
          this.customerPermanentAddressModel.DistanceBranch =
            element.NearstBranchDistance_KM;
          this.losService
            .GetDistrickDropdown({ StateID: element.StateId })
            .subscribe((res: any) => {
              this.districkDropdownPermanent = res;
              this.customerPermanentAddressModel.DistrictId =
                element.DistrictId;
            });

          this.losService
            .GetTehasilDropdown({ DistrictId: element.DistrictId })
            .subscribe((res: any) => {
              this.tehasilDropdownPermanent = res;
              this.customerPermanentAddressModel.TehsilId = element.TehsilId;
            });

          this.customerPermanentAddressModel.NoOfLiving =
            element.TotalYearsOnAddress;
          this.customerPermanentAddressModel.RentOwn = element.AddressRentBuy;
          this.customerPermanentAddressModel.DistaneBranch =
            element.NearstBranchDistance_KM;
        }
        if (element.AddressType == "Work") {
          this.customerWorkAddressModel.Address = element.Address;
          this.customerWorkAddressModel.LandMark = element.Landmark;
          this.customerWorkAddressModel.PinCode = element.Pincode;
          this.customerWorkAddressModel.StateId = element.StateId;
          this.customerWorkAddressModel.DistanceBranch =
            element.NearstBranchDistance_KM;
          this.losService
            .GetDistrickDropdown({ StateID: element.StateId })
            .subscribe((res: any) => {
              this.districkDropdownWork = res;
              this.customerWorkAddressModel.DistrictId = element.DistrictId;
            });

          this.losService
            .GetTehasilDropdown({ DistrictId: element.DistrictId })
            .subscribe((res: any) => {
              this.tehasilDropdownWork = res;
              this.customerWorkAddressModel.TehsilId = element.TehsilId;
            });
          this.customerWorkAddressModel.NoOfLiving =
            element.TotalYearsOnAddress;
          this.customerWorkAddressModel.RentOwn = element.AddressRentBuy;
          this.customerWorkAddressModel.DistaneBranch =
            element.NearstBranchDistance_KM;
        }
      });
    }

    this.onNoClick();
  }

  onYesFirmClick() {
    this.isUpdateAddress = true;
    let ele = document.getElementById("btnSaveCust");
    ele.style.display = "none";

    // this.isExistingCustomer = false;
    // this.customerModel.Type = "Company";

    this.customerModel.CustomerId = this.duplicateFirmData.customer.CustomerId;
    this.customerModel.Firm_Type =
      this.duplicateFirmData.customer.Customer_FirmType;
    this.customerModel.Firm_Name =
      this.duplicateFirmData.customer.Customer_FirstName;
    this.customerModel.Firm_Email =
      this.duplicateFirmData.customer.Customer_Email;
    this.customerModel.Firm_Date_Of_Incorruptions =
      this.duplicateFirmData.customer.Customer_Date_Of_Incorruptions;
    this.customerModel.Firm_No_Of_Partner =
      this.duplicateFirmData.customer.Customer_NoOfPartner;
    this.customerModel.Firm_No_Of_Employee =
      this.duplicateFirmData.customer.Customer_NoofEmployee;
    this.customerModel.Firm_GrossValue =
      this.duplicateFirmData.customer.Customer_GrossValue;
    this.customerModel.Firm_Nature_Of_Business =
      this.duplicateFirmData.customer.Customer_Nature_Of_Business;
    // this.firmKYCDoc = this.duplicateFirmData.kycDetails;
    this.firmKYCDoc = [];
    this.duplicateFirmData.kycDetails.forEach((element) => {
      this.firmKYCDoc.push({
        DocId: element.DocId,
        DocNo: element.KYC_DocNumber,
        DocImage: element.KYC_DocFile,
        Doc_Name: element.Doc_Name,
        KYC_CustomerId: element.KYC_CustomerId,
      });
    });

    if (this.duplicateFirmData.address.length > 0) {
      this.duplicateFirmData.address.forEach((element) => {
        if (element.AddressType == "Registered") {
          this.customerModel.Firm_RegAddress = element.Address;
          this.customerModel.Firm_RegLandMark = element.Landmark;

          this.losService
            .GetDistrickDropdown({ StateID: element.StateId })
            .subscribe((res: any) => {
              this.districkDropdownReg = res;
              this.customerModel.Firm_RegDistrict = element.DistrictId;
            });

          this.losService
            .GetTehasilDropdown({ DistrictId: element.DistrictId })
            .subscribe((res: any) => {
              this.tehasilDropdownReg = res;
              this.customerModel.Firm_RegTehsil = element.TehsilId;
            });

          this.customerModel.Firm_RegPinCode = element.Pincode;
        }
        if (element.AddressType == "Corporate") {
          this.customerModel.Firm_CorpAddress = element.Address;
          this.customerModel.Firm_CorpLandMark = element.Landmark;

          this.losService
            .GetDistrickDropdown({ StateID: element.StateId })
            .subscribe((res: any) => {
              this.districkDropdownCorp = res;
              this.customerModel.Firm_CorpDistrict = element.DistrictId;
            });

          this.losService
            .GetTehasilDropdown({ DistrictId: element.DistrictId })
            .subscribe((res: any) => {
              this.tehasilDropdownCorp = res;
              this.customerModel.Firm_CorpTehsil = element.TehsilId;
            });

          this.customerModel.Firm_CorpPinCode = element.Pincode;
        }
      });
    }

    if (this.duplicateFirmData.patner.length > 0) {
      this.firmPartners = [];
      this.duplicateFirmData.patner.forEach((element) => {
        this.firmPartners.push({
          PartnerName: element.Partner_Name,
          PartnerGender: element.Partner_Gender,
          PartnerAge: element.Partner_Age,
          PartnerDesignation: element.Partner_Designation,
          PartnerPhoneNo: element.Partner_PhoneNo,
          PartnerShare: element.Partner_SharePer,
        });
      });
    }

    this.onNoFirmClick();
  }

  Get_CustomerDetailsApp(data: any) {
    this.BankDetails = [];
    this.removeBank = [];
    if (this.check == false) {
      this.check = true;
      this.getNatureofworkList(true);
      this.getSubCategoryList(true);
      this.getProfileDataList(true);
      this.getCategoryDataList(true);
    }
    // console.log("data", data);
    this.isExistingCustomer = false;
    let CustomerId = data.CustomerId;
   

    if (!data.Customer_IsFirm) {
      this.losService
        .Get_Customer_Details_Web({ Id: 0, Customer_Id: CustomerId, Type: "" })
        .subscribe((res: any) => {
          console.log("Customer Details web api call ");
          let _data: any = res.Item1[0];

          // console.log("_data of res.item1", _data);

          if (res.Item4.length > 0) {
            this.CustomerKYCDoc = res.Item4;
          }
          this.CustomerKYCDoc.forEach((x, index) => {
            if (x.KYC_IssuedDate != "") {
              x.KYC_IssuedDate = new Date(x.KYC_IssuedDate);
            }
            if (x.KYC_ExpiredDate != "") {
              x.KYC_ExpiredDate = new Date(x.KYC_ExpiredDate);
            }
            this.IsVerification(x, index);
          });

          this.customerModel = {
            ApplicationId: this.applicationId,
            Type: "Indusial",
            LoginUserId: this.currentUser.userId,
          };
          // console.log("Customer Model", this.customerModel);
          // this.cdr.detectChanges();

          this.customerModel.CustomerId = _data.CustomerId;
          this.customerModel.Customer_Type = _data.CustomerType;
          this.customerModel.Customer_Relation = _data.CustomerRelation;
          this.customerModel.Customer_FirstName = _data.Customer_FirstName;
          this.customerModel.Customer_LastName = _data.Customer_LastName;
          this.customerModel.Customer_FatherName = _data.Customer_FatherName;
          this.customerModel.Customer_Gender = _data.Customer_Gender;
          this.customerModel.Customer_Occupation =
            _data.Customer_Occupation || "";

          this.customerModel.Customer_Profile = _data.Customer_Profile ;

          this.customerModel.Customer_Category = _data.Customer_Category ;

          this.customerModel.Customer_SubCategory =
            _data.Customer_SubCategory ;

          this.customerModel.Customer_Natureofwork =
            _data.Customer_Natureofwork ;

          this.customerModel.BusinessCategory = _data.BusinessCategory ;

          this.customerModel.Customer_DOB = _data.Customer_DOB
            ? moment(_data.Customer_DOB, "DD/MM/YYYY").toDate()
            : moment(this.defaultDob, "DD/MM/YYYY").toDate();
          var date1 = new Date(this.customerModel.Customer_DOB);
          var date2 = new Date();

          var Time = date2.getTime() - date1.getTime();
          var Days = Time / (1000 * 3600 * 24); //Diference in Days
          var year = Days / 365;
          this.Age = Math.round(year);
          this.customerModel.Customer_ProfilePic = _data.Customer_ProfilePic;
          this.customerModel.Customer_PhoneNo =
            _data.Customer_PhoneNo != null ? _data.Customer_PhoneNo.trim() : "";
          this.customerModel.Customer_PhoneNo1 =
            _data.Customer_PhoneNo1 != null
              ? _data.Customer_PhoneNo1.trim()
              : "";
          this.customerModel.Customer_WhatsAppNo =
            _data.Customer_WhatsAppNo != null
              ? _data.Customer_WhatsAppNo.trim()
              : "";
          this.customerModel.Customer_PhoneNo_IsVerified =
            _data.Customer_PhoneNo_IsVerified;
          this.customerModel.Customer_Email = _data.Customer_Email;
          this.customerModel.Customer_MaritalStatus =
            _data.Customer_MaritalStatus || "";
          this.customerModel.Customer_Religion = _data.Customer_Religion || "";
          this.customerModel.Customer_Cast = _data.Customer_Cast || "";
          this.isCustomerEdit = true;
          this.isExistingCustomer = false;
          this.customerModel.Customer_Relation_Type = _data.Customer_Relation;
          this.customerModel.Customer_Relation_FirstName =
            _data.Customer_Relation_FirstName;
          this.customerModel.Customer_Relation_LastName =
            _data.Customer_Relation_LastName;

          res.Item2.forEach((x) => {
            this.cdr.detectChanges();
            if (x.AddressType == "Present") {
              this.customerAddressModel.Address = x.Address;
              this.customerAddressModel.LandMark = x.LandMark;
              this.customerAddressModel.PinCode = x.PinCode;
              this.customerAddressModel.StateId = x.StateId;
              this.customerAddressModel.DistrictId = x.DistrictId;
              this.customerAddressModel.TehsilId = x.TehsilId;
              this.customerAddressModel.NoOfLiving = x.TotalYearsOnAddress;
              this.customerAddressModel.RentOwn = x.AddressRentBuy;
              this.customerAddressModel.DistanceBranch =
                x.NearstBranchDistance_KM;
              this.customerAddressModel.IsCommunicationAddress =
                x.IsCommunicationAddress;
              this.customerAddressModel.AddressId = x.AddressId;
            } else if (x.AddressType == "Permanent") {
              this.customerPermanentAddressModel.Address = x.Address;
              this.customerPermanentAddressModel.LandMark = x.LandMark;
              this.customerPermanentAddressModel.PinCode = x.PinCode;
              this.customerPermanentAddressModel.StateId = x.StateId;
              this.customerPermanentAddressModel.DistrictId = x.DistrictId;
              this.customerPermanentAddressModel.TehsilId = x.TehsilId;
              this.customerPermanentAddressModel.NoOfLiving =
                x.TotalYearsOnAddress;
              this.customerPermanentAddressModel.RentOwn = x.AddressRentBuy;
              this.customerPermanentAddressModel.DistanceBranch =
                x.NearstBranchDistance_KM;
              this.customerPermanentAddressModel.IsCommunicationAddress =
                x.IsCommunicationAddress;
              this.customerPermanentAddressModel.AddressId = x.AddressId;

              this.cdr.detectChanges();
            } else if (x.AddressType == "Work") {
              this.customerWorkAddressModel.Address = x.Address;
              this.customerWorkAddressModel.LandMark = x.LandMark;
              this.customerWorkAddressModel.PinCode = x.PinCode;
              this.customerWorkAddressModel.StateId = x.StateId;
              this.customerWorkAddressModel.DistrictId = x.DistrictId;
              this.customerWorkAddressModel.TehsilId = x.TehsilId;
              this.customerWorkAddressModel.NoOfLiving = x.TotalYearsOnAddress;
              this.customerWorkAddressModel.RentOwn = x.AddressRentBuy;
              this.customerWorkAddressModel.DistanceBranch =
                x.NearstBranchDistance_KM;
              this.customerWorkAddressModel.IsCommunicationAddress =
                x.IsCommunicationAddress;
              this.customerWorkAddressModel.AddressId = x.AddressId;

              this.cdr.detectChanges();
            } else {
              this.customerWorkAddressModel = {
                StateId: "",
                DistrictId: "",
                TehsilId: "",
                NoOfLiving: "",
                RentOwn: "",
              };
            }
          });

          if (res.Item3.length > 0) {
            this.BankDetails = res.Item3;
          } else {
            if (this.Bank_Verification == false) {
              this.BankDetails.push({
                BeneficiaryName: "",
                AccountNo: "",
                BankName: "",
                BankBranch: "",
                BankAcType: "",
                BankIFSC: "",
                BankMICRCode: "",
              });
            }
          }
          //this.GetStateDropdown(false);
          if (this.checkaddress == false) {
            this.checkaddress = true;
            this.GetStateDropdown(false);
            this.GetDistrickDropdownPresent(false);
            this.GetTehasilDropdownPresent(false);
            this.GetDistrickDropdownPermanent(false);
            this.GetTehasilDropdownPermanent(false);
            this.GetDistrickDropdownWork(false);
            this.GetTehasilDropdownWork(false);
          }
         
          this.getCustomerBankList();
         
          // setTimeout(() => this.getCategoryDataList(), 5000);
      
        
         
          this.displayAddCustomerModel();
          //this.LOS_GetCustomerAddressWeb(_data);
          // this.displayAddCustomerModel();
        });
    } else {
      // if(this.TypeFrom == 'LMS'){
      this.LmsService.GetLoanDetailsForSaleProcess({
        LoanId: 0,
        CId: Number(CustomerId),
      }).subscribe((res: any) => {
        console.log("LMS RES NON Individual:", res);
        if (res.Item1.length > 0) {
          let _data: any = res.Item1[0];
          this.firmKYCDoc = res.Item4;
          this.BankDetails = res.Item3;
          // this.customerModel = {
          //   ApplicationId: this.applicationId,
          //   SameAddress: false,
          //   Type: "Company",
          //   Customer_Type: "H",
          //   Customer_Relation: "Own",
          //   LoginUserId: this.currentUser.userId,
          // };
          this.customerModel.Type = 'Company'
          this.customerModel.ApplicationNo = _data.ApplicationNo;
          this.customerModel.FirmId = _data.FirmId;
          this.customerModel.Firm_Name = _data.Firm_Name;
          this.customerModel.Firm_Type = _data.Firm_Type;
          this.customerModel.Firm_RegAddress = _data.Firm_RegAddress;
          this.customerModel.Firm_RegLandMark = _data.Firm_RegLandMark;
          this.customerModel.Firm_Date_Of_Incorruptions =
            _data.Firm_Date_Of_Incorruptions
              ? new Date(_data.Firm_Date_Of_Incorruptions)
              : "";
          this.customerModel.Firm_RegPinCode = _data.Firm_RegPinCode;
          this.customerModel.Firm_RegState = _data.Firm_RegState;
          this.customerModel.Firm_RegDistrict = _data.Firm_RegDistrict;
          this.customerModel.Firm_RegTehsil = _data.Firm_RegTehsil;
          this.customerModel.Firm_CorpAddress = _data.Firm_CorpAddress;
          this.customerModel.Firm_CorpLandMark = _data.Firm_CorpLandMark;
          this.customerModel.Firm_CorpPinCode = _data.Firm_CorpPinCode;
          this.customerModel.Firm_CorpState = _data.Firm_CorpState;
          this.customerModel.Firm_CorpDistrict = _data.Firm_CorpDistrict;
          this.customerModel.Firm_CorpTehsil = _data.Firm_CorpTehsil;
          this.customerModel.Firm_PhoneNo = _data.Firm_PhoneNo;
          this.customerModel.Firm_PhoneNoIsVerified =
            _data.Firm_PhoneNoIsVerified;
          this.customerModel.Firm_PhoneNo1 = _data.Firm_PhoneNo1;
          this.customerModel.Firm_Email = _data.Firm_Email;
          this.customerModel.Firm_Website = _data.Firm_Website;
          this.customerModel.Firm_No_Of_Partner = _data.Firm_No_Of_Partner;
          this.customerModel.Firm_No_Of_Employee = _data.Firm_No_Of_Employee;
          this.customerModel.Firm_GrossValue = _data.Firm_GrossValue;
          this.customerModel.Firm_Nature_Of_Business =
            _data.Firm_Nature_Of_Business;

          this.customerModel.Firm_RegTotalYearsOnAddress =
            _data.RegTotalYearsOnAddress;
          this.customerModel.Firm_RegAddressRentBuy = _data.RegAddressRentBuy;
          this.customerModel.Firm_RegNearstBranchDistance_KM =
            _data.RegNearstBranchDistance_KM;

          this.customerModel.Firm_CorpTotalYearsOnAddress =
            _data.CorpTotalYearsOnAddress;
          this.customerModel.Firm_CorpAddressRentBuy = _data.CorpAddressRentBuy;
          this.customerModel.Firm_CorpNearstBranchDistance_KM =
            _data.CorpNearstBranchDistance_KM;
          this.customerModel.Customer_Type = _data.Customer_Type;

          this.customerModel.Firm_CIN_No = _data.Firm_CIN_No;
          this.customerModel.BusinessCategory = _data.BusinessCategory;
          this.customerModel.BusinessType = _data.BusinessType;

          this.customerModel.Customer_Profile = _data.Customer_Profile;
          this.customerModel.Customer_Category = _data.Customer_Category;
          this.customerModel.Customer_SubCategory = _data.Customer_SubCategory;
          this.customerModel.Customer_Natureofwork =
            _data.Customer_Natureofwork;

          //this.getProfileDataList(false);
          //this.getCategoryDataList(false);
          //this.getSubCategoryList(false);
          //this.getNatureofworkList(false);
        }

        this.isCustomerEdit = true;
        this.isExistingCustomer = false;
        this.firmPartners = [];
        res.Item2.forEach((obj: any) => {
          this.firmPartners.push({
            PartnerName: obj.Partner_Name,
            PartnerGender: obj.Partner_Gender,
            PartnerAge: obj.Partner_Age,
            PartnerDesignation: obj.Partner_Designation,
            PartnerPhoneNo: obj.Partner_PhoneNo,
            PartnerShare: obj.Partner_SharePer,
            PartnerDIN_No: obj.PartnerDIN_No,
          });
        });

        if (res.Item3.length > 0) {
          this.BankDetails = res.Item3;
        } else {
          if (this.Bank_Verification == false) {
            this.BankDetails.push({
              BeneficiaryName: "",
              AccountNo: "",
              BankName: "",
              BankBranch: "",
              BankAcType: "",
              BankIFSC: "",
              BankMICRCode: "",
            });
          }
        }
        if (this.checkaddress == false) {
          this.checkaddress = true;
        this.GetDistrickDropdownReg(false);
        this.GetDistrickDropdownCorp(false);
        this.GetTehasilDropdownReg(false);
        this.GetTehasilDropdownCorp(false);
         this.GetStateDropdown(false);
        }
        this.changeCompanyFirm();
        this.getCustomerBankList();
       
        this.displayAddCustomerModel();
      });
      // // }
      // else {
      //   this.losService.Get_FirmDetailsWebForUpdate({ApplicationId: 0, CustomerId: CustomerId}).subscribe((res: any) => {
      //     //console.log("LOS RES :", res);
      //     let _data: any = res.Item1[0];
      //     //this.BankDetails = res.Item3;
      //     this.customerModel = {
      //       ApplicationId: this.applicationId,
      //       SameAddress: false,
      //       Type: "Company",
      //       Customer_Type: "H",
      //       Customer_Relation: "Own",
      //       LoginUserId: this.currentUser.userId,
      //     };
      //     this.customerModel.ApplicationNo = _data.ApplicationNo;
      //     this.customerModel.FirmId = _data.FirmId;
      //     this.customerModel.Firm_Name = _data.Firm_Name;
      //     this.customerModel.Firm_Type = _data.Firm_Type;
      //     this.customerModel.Firm_RegAddress = _data.Firm_RegAddress;
      //     this.customerModel.Firm_RegLandMark = _data.Firm_RegLandMark;
      //     this.customerModel.Firm_Date_Of_Incorruptions = _data.Firm_Date_Of_Incorruptions ? new Date(_data.Firm_Date_Of_Incorruptions) : "";
      //     this.customerModel.Firm_RegPinCode = _data.Firm_RegPinCode;
      //     this.customerModel.Firm_RegState = _data.Firm_RegState;
      //     this.customerModel.Firm_RegDistrict = _data.Firm_RegDistrict;
      //     this.customerModel.Firm_RegTehsil = _data.Firm_RegTehsil;
      //     this.customerModel.Firm_CorpAddress = _data.Firm_CorpAddress;
      //     this.customerModel.Firm_CorpLandMark = _data.Firm_CorpLandMark;
      //     this.customerModel.Firm_CorpPinCode = _data.Firm_CorpPinCode;
      //     this.customerModel.Firm_CorpState = _data.Firm_CorpState;
      //     this.customerModel.Firm_CorpDistrict = _data.Firm_CorpDistrict;
      //     this.customerModel.Firm_CorpTehsil = _data.Firm_CorpTehsil;
      //     this.customerModel.Firm_PhoneNo = _data.Firm_PhoneNo;
      //     this.customerModel.Firm_PhoneNoIsVerified = _data.Firm_PhoneNoIsVerified;
      //     this.customerModel.Firm_PhoneNo1 = _data.Firm_PhoneNo1;
      //     this.customerModel.Firm_Email = _data.Firm_Email;
      //     this.customerModel.Firm_Website = _data.Firm_Website;
      //     this.customerModel.Firm_No_Of_Partner = _data.Firm_No_Of_Partner;
      //     this.customerModel.Firm_No_Of_Employee = _data.Firm_No_Of_Employee;
      //     this.customerModel.Firm_GrossValue = _data.Firm_GrossValue;
      //     this.customerModel.Firm_Nature_Of_Business = _data.Firm_Nature_Of_Business;

      //     this.customerModel.Firm_RegTotalYearsOnAddress = _data.Firm_RegTotalYearsOnAddress;
      //     this.customerModel.Firm_RegAddressRentBuy = _data.Firm_RegAddressRentBuy;
      //     this.customerModel.Firm_RegNearstBranchDistance_KM = _data.Firm_RegNearstBranchDistance_KM;

      //     this.customerModel.Firm_CorpTotalYearsOnAddress = _data.Firm_CorpTotalYearsOnAddress;
      //     this.customerModel.Firm_CorpAddressRentBuy = _data.Firm_CorpAddressRentBuy;
      //     this.customerModel.Firm_CorpNearstBranchDistance_KM = _data.Firm_CorpNearstBranchDistance_KM;
      //     this.customerModel.Customer_Type = _data.Customer_Type;

      //     this.customerModel.Firm_CIN_No = _data.Firm_CIN_No;
      //     this.customerModel.BusinessCategory = _data.BusinessCategory;
      //     this.customerModel.BusinessType = _data.BusinessType;
      //     this.customerModel.Customer_Profile = _data.Customer_Profile;
      //     this.customerModel.Customer_Category = _data.Customer_Category;
      //     this.customerModel.Customer_SubCategory = _data.Customer_SubCategory;
      //     this.customerModel.Customer_Natureofwork = _data.Customer_Natureofwork;

      //     this.isCustomerEdit = true;
      //     this.isExistingCustomer = false;
      //     this.firmPartners = [];
      //     this.getProfileDataList(true);
      //     this.getCategoryDataList(true);
      //     this.getSubCategoryList(true);
      //     this.getNatureofworkList(true);
      //     res.Item2.forEach((obj: any) => {
      //       this.firmPartners.push({
      //         PartnerName: obj.Partner_Name,
      //         PartnerGender: obj.Partner_Gender,
      //         PartnerAge: obj.Partner_Age,
      //         PartnerDesignation: obj.Partner_Designation,
      //         PartnerPhoneNo: obj.Partner_PhoneNo,
      //         PartnerShare: obj.Partner_SharePer,
      //         PartnerDIN_No: obj.PartnerDIN_No
      //       });
      //     });

      //     if(res.Item3.length > 0){
      //       this.BankDetails = res.Item3;
      //     }
      //     else{
      //       this.BankDetails.push({
      //         BeneficiaryName: "",
      //         AccountNo: "",
      //         BankName: "",
      //         BankBranch: "",
      //         BankAcType: "",
      //         BankIFSC: "",
      //         BankMICRCode: ""
      //       });
      //     }

      //     this.changeCompanyFirm();
      //     this.GetStateDropdown(false);
      //     this.GetDistrickDropdownReg(false);
      //     this.GetTehasilDropdownReg(false);
      //     this.GetDistrickDropdownCorp(false);
      //     this.GetTehasilDropdownCorp(false);
      //     this.getCustomerBankList();
      //     //$(`#addCustomer${this.index}`).modal("show");
      //     //$(`#addCustomer${this.index}`).css("z-index", "1050");
      //   });
      // }
    }

    // this.displayAddCustomerModel();
    // setTimeout(this.displayAddCustomerModel, 5000);
  }

  GetStateDropdown(reset: boolean = true) {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
      //console.log('GetStateDropdown', res);
      if (reset) {
        this.customerModel.Firm_RegState = 29;
        this.customerModel.Firm_CorpState = 29;
      }
    });
  }
  GetDistrickDropdownReg(reset: boolean = true) {
    if (reset) {
      //this.customerModel.Firm_RegDistrict = "";
      //this.customerModel.Firm_RegTehsil = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.customerModel.Firm_RegState })
      .subscribe((res: any) => {
        this.districkDropdownReg = res;
        //this.GetTehasilDropdownReg(reset);
        //this.GetTehasilDropdownCorp(reset);
      });
  }
  GetTehasilDropdownReg(reset: boolean = true) {
    if (reset) {
      //this.customerModel.Firm_RegTehsil = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.customerModel.Firm_RegDistrict })
      .subscribe((res: any) => {
        this.tehasilDropdownReg = res;
      });
  }
  GetDistrickDropdownCorp(reset: boolean = true) {
    if (reset) {
      //this.customerModel.Firm_CorpDistrict = "";
      //this.customerModel.Firm_CorpTehsil = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.customerModel.Firm_CorpState })
      .subscribe((res: any) => {
        this.districkDropdownCorp = res;
      });
  }
  GetTehasilDropdownCorp(reset: boolean = true) {
    if (reset) {
      //this.customerModel.Firm_CorpTehsil = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.customerModel.Firm_CorpDistrict })
      .subscribe((res: any) => {
        this.tehasilDropdownCorp = res;
      });
  }
  checkAddressSame(event: any) {
    if (this.customerModel.SameAddress) {
      this.customerModel.Firm_CorpAddress = this.customerModel.Firm_RegAddress;
      this.customerModel.Firm_CorpLandMark =
        this.customerModel.Firm_RegLandMark;
      this.customerModel.Firm_CorpState = this.customerModel.Firm_RegState;
      this.customerModel.Firm_CorpDistrict =
        this.customerModel.Firm_RegDistrict;
      this.customerModel.Firm_CorpTehsil = this.customerModel.Firm_RegTehsil;
      this.customerModel.Firm_CorpPinCode = this.customerModel.Firm_RegPinCode;

      this.customerModel.CorpTotalYearsOnAddress =
        this.customerModel.RegTotalYearsOnAddress;
      this.customerModel.CorpAddressRentBuy =
        this.customerModel.RegAddressRentBuy;
      this.customerModel.CorpNearstBranchDistance_KM =
        this.customerModel.RegNearstBranchDistance_KM;

      this.districkDropdownCorp = this.districkDropdownReg;
      this.tehasilDropdownCorp = this.tehasilDropdownReg;
      this.customerModel.Firm_CorpTotalYearsOnAddress =
        this.customerModel.Firm_RegTotalYearsOnAddress;
      this.customerModel.Firm_CorpNearstBranchDistance_KM =
        this.customerModel.Firm_RegNearstBranchDistance_KM;
      this.customerModel.Firm_CorpAddressRentBuy =
        this.customerModel.Firm_RegAddressRentBuy;
    } else {
      this.customerModel.Firm_CorpAddress = "";
      this.customerModel.Firm_CorpLandMark = "";
      // this.customerModel.Firm_CorpState = "";
      this.customerModel.Firm_CorpDistrict = "";
      this.customerModel.Firm_CorpTehsil = "";
      this.customerModel.Firm_CorpPinCode = "";

      this.customerModel.CorpTotalYearsOnAddress = "";
      this.customerModel.CorpAddressRentBuy = "";
      this.customerModel.CorpNearstBranchDistance_KM = "";

      this.districkDropdownCorp = [];
      this.tehasilDropdownCorp = [];
    }
  }

  onCloseAddress() {
    $("#addressModel").modal("hide");
  }
  onAddCustomerAddress() {
    let _data: any = { Address: [] };

    //console.log('this.addressModel', this.addressModel.permanent.TehsilId, this.addressModel);
    _data.Address.push(this.addressModel.present);
    if (
      this.addressModel.permanent.Address &&
      this.addressModel.permanent.Address.trim().length > 0
    ) {
      if (
        !this.addressModel.permanent.LandMark ||
        this.addressModel.permanent.LandMark.trim().length == 0
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Landmark is required for permanent address.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        !this.addressModel.permanent.PinCode ||
        this.addressModel.permanent.PinCode.trim().length == 0
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "PinCode is required for permanent address.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        !this.addressModel.permanent.TehsilId ||
        this.addressModel.permanent.TehsilId == 0
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "State ,District and tehsil are required for permanent address.",
          ...this.configSuccess,
        });
        return;
      }
      //if (!this.addressModel.permanent.TotalYearsOnAddress || this.addressModel.permanent.TotalYearsOnAddress == 0) {
      //    this.snackBar.openFromComponent(SnackbarComponent, { data: "TotalYearsOnAddress is required for permanent address.", ...this.configSuccess });
      //    return;
      //}
      if (
        !this.addressModel.permanent.AddressRentBuy ||
        this.addressModel.permanent.AddressRentBuy.trim().length == 0
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Rent/Buy is required for permanent address.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        !this.addressModel.permanent.NearstBranchDistance_KM ||
        this.addressModel.permanent.NearstBranchDistance_KM == 0
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Nearst Branch Distance is required for permanent address.",
          ...this.configSuccess,
        });
        return;
      }
      _data.Address.push(this.addressModel.permanent);
    }
    if (!this.addressModel.customerDetail.IsFirm) {
      if (
        this.addressModel.work.Address &&
        this.addressModel.work.Address.trim().length > 0
      ) {
        if (
          !this.addressModel.work.LandMark ||
          this.addressModel.work.LandMark.trim().length == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Landmark is required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          !this.addressModel.work.PinCode ||
          this.addressModel.work.PinCode.trim().length == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "PinCode is required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          !this.addressModel.work.TehsilId ||
          this.addressModel.work.TehsilId == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "State ,District and tehsil are required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          !this.addressModel.work.TotalYearsOnAddress ||
          this.addressModel.work.TotalYearsOnAddress == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "TotalYearsOnAddress is required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          !this.addressModel.work.AddressRentBuy ||
          this.addressModel.work.AddressRentBuy == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Rent/Buy is required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          !this.addressModel.work.NearstBranchDistance_KM ||
          this.addressModel.work.NearstBranchDistance_KM == 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Nearst Branch Distance is required for work address.",
            ...this.configSuccess,
          });
          return;
        }
        _data.Address.push(this.addressModel.work);
      }
    }
    this.showSpinner = true;
    this.losService
      .LOS_UpdateCustomerAddressWeb({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          // this.LMS_GetLoanDetails();
          this.onCloseAddress();
        }
        this.showSpinner = false;
      });
  }
  GetStateDropdownAddress() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }
  GetDistrickDropdownPresent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.present.DistrictId = "";
    //     this.addressModel.present.TehsilId = "";
    // }
    this.losService
      .GetDistrickDropdown({ StateID: this.customerAddressModel.StateId || 0 })
      .subscribe((res: any) => {
        this.districkDropdownPresent = res;
      });
  }
  GetTehasilDropdownPresent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.present.TehsilId = "";
    // District
    this.losService
      .GetTehasilDropdown({
        DistrictId: this.customerAddressModel.DistrictId || 0,
      })
      .subscribe((res: any) => {
        this.tehasilDropdownPresent = res;
      });
  }

  GetDistrickDropdownPermanent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.permanent.DistrictId = "";
    //     this.addressModel.permanent.TehsilId = "";
    // }
    this.losService
      .GetDistrickDropdown({
        StateID: this.customerPermanentAddressModel.StateId,
      })
      .subscribe((res: any) => {
        this.districkDropdownPermanent = res;
      });
  }
  GetTehasilDropdownPermanent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.permanent.TehsilId = "";
    // }
    this.losService
      .GetTehasilDropdown({
        DistrictId: this.customerPermanentAddressModel.DistrictId,
      })
      .subscribe((res: any) => {
        this.tehasilDropdownPermanent = res;
      });
  }
  GetDistrickDropdownWork(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.work.DistrictId = "";
    //     this.addressModel.work.TehsilId = "";
    // }
    this.losService
      .GetDistrickDropdown({ StateID: this.customerWorkAddressModel.StateId })
      .subscribe((res: any) => {
        this.districkDropdownWork = res;
      });
  }
  GetTehasilDropdownWork(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.work.TehsilId = "";
    // }
    this.losService
      .GetTehasilDropdown({
        DistrictId: this.customerWorkAddressModel.DistrictId,
      })
      .subscribe((res: any) => {
        this.tehasilDropdownWork = res;
      });
  }

  checkCAddressSame1(event: any) {
    //console.log(this.customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress);
    if (
      this.customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress
    ) {
      this.customerPermanentAddressModel.Address =
        this.customerAddressModel.Address;
      this.customerPermanentAddressModel.LandMark =
        this.customerAddressModel.LandMark;
      this.customerPermanentAddressModel.StateId =
        this.customerAddressModel.StateId;
      this.customerPermanentAddressModel.DistrictId =
        this.customerAddressModel.DistrictId;
      this.customerPermanentAddressModel.TehsilId =
        this.customerAddressModel.TehsilId;
      this.customerPermanentAddressModel.PinCode =
        this.customerAddressModel.PinCode;
      this.customerPermanentAddressModel.DistanceBranch =
        this.customerAddressModel.DistanceBranch;
      this.customerPermanentAddressModel.NoOfLiving =
        this.customerAddressModel.NoOfLiving;
      this.customerPermanentAddressModel.RentOwn =
        this.customerAddressModel.RentOwn;
      this.districkDropdownPermanent = this.districkDropdownPresent;
      this.tehasilDropdownPermanent = this.tehasilDropdownPresent;

      /*this.customerPermanentAddressModel.NearstBranchDistance_KM = "";
      this.customerPermanentAddressModel.TotalYearsOnAddress = "";
      this.customerPermanentAddressModel.AddressRentBuy = "";*/
    } else {
      this.customerPermanentAddressModel.Address = "";
      this.customerPermanentAddressModel.LandMark = "";
      this.customerPermanentAddressModel.StateId = "";
      this.customerPermanentAddressModel.DistrictId = "";
      this.customerPermanentAddressModel.TehsilId = "";
      this.customerPermanentAddressModel.PinCode = "";
      this.customerPermanentAddressModel.NearstBranchDistance_KM = "";
      this.customerPermanentAddressModel.TotalYearsOnAddress = "";
      this.customerPermanentAddressModel.AddressRentBuy = "";
      this.districkDropdownPermanent = [];
      this.tehasilDropdownPermanent = [];
    }
  }
  checkCAddressSame(event: any) {
    if (this.addressModel.SameAddress) {
      this.addressModel.permanent.Address = this.addressModel.present.Address;
      this.addressModel.permanent.LandMark = this.addressModel.present.LandMark;
      this.addressModel.permanent.StateId = this.addressModel.present.StateId;
      this.addressModel.permanent.DistrictId =
        this.addressModel.present.DistrictId;
      this.addressModel.permanent.TehsilId = this.addressModel.present.TehsilId;
      this.addressModel.permanent.PinCode = this.addressModel.present.PinCode;
      this.addressModel.permanent.NearstBranchDistance_KM =
        this.addressModel.present.NearstBranchDistance_KM;
      this.addressModel.permanent.TotalYearsOnAddress =
        this.addressModel.present.TotalYearsOnAddress;
      this.addressModel.permanent.AddressRentBuy =
        this.addressModel.present.AddressRentBuy;
      this.districkDropdownPermanent = this.districkDropdownPresent;
      this.tehasilDropdownPermanent = this.tehasilDropdownPresent;
    } else {
      this.addressModel.permanent.Address = "";
      this.addressModel.permanent.LandMark = "";
      this.addressModel.permanent.StateId = "";
      this.addressModel.permanent.DistrictId = "";
      this.addressModel.permanent.TehsilId = "";
      this.addressModel.permanent.PinCode = "";
      this.addressModel.permanent.NearstBranchDistance_KM = "";
      this.addressModel.permanent.TotalYearsOnAddress = "";
      this.addressModel.permanent.AddressRentBuy = "";
      this.districkDropdownPermanent = [];
      this.tehasilDropdownPermanent = [];
    }
  }
  addEditCustomerKyc(data: any) {
    if (data.CustomerId) {
      this.isNewAddress = false;
      this.kycModel = {
        CustomerId: data.CustomerId,
        KYC_DocId: this.getDocId(data.Doc_Title),
        KYC_DocNumber: data.Doc_Number,
        KYC_DocFile: data.Doc_FileName,
        KYC_DocFile1: data.Doc_FileName1,
        LoginUserId: this.currentUser.userId,
      };
    } else {
      this.isNewAddress = true;
      this.kycModel = {
        CustomerId: "",
        KYC_DocId: "",
        KYC_DocNumber: "",
        KYC_DocFile: "",
        KYC_DocFile1: "",
        LoginUserId: this.currentUser.userId,
      };
    }
    $("#kycModel").modal("show");
    $("#kycModel").css("z-index", "1050");
  }

  getDocId(docType: string) {
    if (docType.toLowerCase() == "PAN Card".toLowerCase()) {
      return 1;
    } else if (docType.toLowerCase() == "Electricity Bill".toLowerCase()) {
      return 2;
    } else if (docType.toLowerCase() == "Passport Copy".toLowerCase()) {
      return 3;
    } else if (docType.toLowerCase() == "Voter ID Card".toLowerCase()) {
      return 4;
    } else if (docType.toLowerCase() == "Driving License".toLowerCase()) {
      return 5;
    } else if (docType.toLowerCase() == "Aadhaar Card".toLowerCase()) {
      return 6;
    } else if (docType.toLowerCase() == "Other Photo ID".toLowerCase()) {
      return 7;
    } else if (docType.toLowerCase() == "Ration Card".toLowerCase()) {
      return 21;
    } else if (docType.toLowerCase() == "DLl".toLowerCase()) {
      return 22;
    } else if (docType.toLowerCase() == "Firm Registration No".toLowerCase()) {
      return 28;
    } else if (docType.toLowerCase() == "GST No".toLowerCase()) {
      return 29;
    } else if (docType.toLowerCase() == "TAN No".toLowerCase()) {
      return 30;
    } else {
      return "";
    }
  }
  // deleteCustomerKyc(data: any) {
  //   this.dialog.openConfirmDialog(`Are you sure you want to remove ${data.Customer}'s KYC doc from application.`)
  //     .afterClosed().subscribe(dialogResult => {
  //       if (dialogResult == false) {
  //         return;
  //       } else {
  //         this.showSpinner = true;
  //         this.losService.LOS_DeleteCustomerDocument({ CustomerId: data.CustomerId, DocumentId: this.getDocId(data.Doc_Title), LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
  //           this.showSpinner = false;
  //           if (res[0].CODE >= 0) {
  //             this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //             // this.LMS_GetLoanDetails();
  //           } else {
  //             this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //           }
  //         });
  //       }
  //     });
  // }
  onCloseKYC() {
    $("#kycModel").modal("hide");
  }
  onCloseOTP() {
    $(`#OTPModel`).modal("hide");
    this.OTP = "";
    setTimeout(() => {
      $("body").addClass("modal-open");
    }, 1000);
  }
  onAddCustomerKYC() {
    this.showSpinner = true;
    //console.log(this.kycModel);
    this.kycModel.KYC_IsVerifie = 0;
    this.uploadKycDocs(this.kycModel);
    this.uploadKycDocs1(this.kycModel);
    this.LmsService.LMS_SaveCustomerKYCDocApp(this.kycModel).subscribe(
      (res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          // this.LMS_GetLoanDetails();
          this.onCloseKYC();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      }
    );
  }
  uploadKycDocs(data: any) {
    if (data.DocData) {
      this.losService
        .UploadCustomerDoc({
          CustomerID: data.CustomerId,
          DocName: data.KYC_DocFile,
          DocData: data.DocData,
        })
        .subscribe((res: any) => {});
    }
  }
  uploadKycDocs1(data: any) {
    if (data.DocData1) {
      this.losService
        .UploadCustomerDoc({
          CustomerID: data.CustomerId,
          DocName: data.KYC_DocFile1,
          DocData: data.DocData1,
        })
        .subscribe((res: any) => {});
    }
  }
  goToCustomerDetail(data: any) {
    var appid = this.encdec.encrypt(this.applicationId);
    var cid = this.encdec.encrypt(data.CustomerId);
    window.open(
      "#/LOS/customer/view/" +
        encodeURIComponent(appid) +
        "/" +
        encodeURIComponent(cid),
      "_blank"
    );
  }
  GetDistrickDropdownPresent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.present.DistrictId = "";
      this.addressModel.present.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.present.StateId })
      .subscribe((res: any) => {
        this.districkDropdownPresent = res;
      });
  }
  GetTehasilDropdownPresent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.present.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.addressModel.present.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdownPresent = res;
      });
  }
  GetDistrickDropdownPermanent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.permanent.DistrictId = "";
      this.addressModel.permanent.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.permanent.StateId })
      .subscribe((res: any) => {
        this.districkDropdownPermanent = res;
      });
  }
  GetTehasilDropdownPermanent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.permanent.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({
        DistrictId: this.addressModel.permanent.DistrictId,
      })
      .subscribe((res: any) => {
        this.tehasilDropdownPermanent = res;
      });
  }
  GetDistrickDropdownWork1(reset: boolean = true) {
    if (reset) {
      this.addressModel.work.DistrictId = "";
      this.addressModel.work.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.work.StateId })
      .subscribe((res: any) => {
        this.districkDropdownWork = res;
      });
  }
  GetTehasilDropdownWork1(reset: boolean = true) {
    if (reset) {
      this.addressModel.work.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.addressModel.work.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdownWork = res;
      });
  }

  getOccupationList() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "OCCUPATION",
    }).subscribe((result) => {
      this.OccupationData = JSON.parse(JSON.stringify(result));
    });
  }

  // RedirectTo(){
  //   this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
  //   this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
  //   return;
  // }

  // LOS_GetApplicationDetailsForSaleProcess() {
  //   this.customerlist = [];
  //   console.log("this.loan_Summary", this.loan_Summary);
  //   this.losService.LOS_GetApplicationDetailsForSaleProcess({
  //     ApplicationId: this.loan_Summary.ApplicationId.toString(), ProcessId: this.loan_Summary.ProcessId.toString()
  //   }).subscribe((res: any) => {
  //     this.applicationDetail = res.Item1[0];
  //     this.customerlist = res.Item2;
  //     if (this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' })) {
  //       this.ShowBorrower = false;
  //     }
  //   });
  // }

  GetProductRequiredDoc() {
    //console.log("this.applicationDetail", this.applicationDetail);

    this.RequestModel.ProductId = 0;
    this._MasterService
      .GetProductRequiredDoc(this.RequestModel)
      .subscribe((result: any) => {
        this.ProductDropdownList = result;

        // //console.log("this.ProductDropdownList", this.ProductDropdownList);

        // if(Customer_Type == 'H'){
        //   this.ProductDocList = this.ProductDropdownList.filter(item => item.IsHMandatory == true);
        // }
        // else if(Customer_Type == 'C'){
        //   this.ProductDocList = this.ProductDropdownList.filter(item => item.IsCMandatory == true);
        // }
        // else {
        //   this.ProductDocList = this.ProductDropdownList.filter((item) => item.IsGMandatory == true);
        // }
        //console.log("this.ProductDocList", this.ProductDocList);
      });
  }

  changeCompanyFirm() {
    switch (this.customerModel.Firm_Type) {
      case "ProprietorFirm":
        this.FirmTypes = "Firm";
        this.FirmHead = "Proprietor";
        break;
      case "PartnershipFirm":
        this.FirmTypes = "Firm";
        this.FirmHead = "Partner";
        break;
      case "Trust":
        this.FirmTypes = "Trust";
        this.FirmHead = "Member";
        break;
      case "HUF":
        this.FirmTypes = "HUF";
        this.FirmHead = "Chief";
        break;
      case "Institute":
        this.FirmTypes = "Institute";
        this.FirmHead = "Director";
        break;
      case "NGO":
        this.FirmTypes = "NGO";
        this.FirmHead = "Founder";
        break;
      default:
        this.FirmTypes = "Company";
        this.FirmHead = "Director";
        break;
    }
  }

  onChangeDocument(data: any, dindex: any) {
    let KYCDocs =
      this.customerModel.Type == "Company"
        ? this.firmKYCDoc
        : this.CustomerKYCDoc;
    let _find = KYCDocs.find((obj: any, index: any) => {
      this.IsVerification(data, dindex);
      if (this.customerModel.Type == "Company") {
        return index != dindex && obj.DocId == data.DocId;
      } else {
        return index != dindex && obj.KYC_DocId == data.KYC_DocId;
      }
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This document already selected.",
        ...this.configSuccess,
      });
      data.DocId = "";
      data.KYC_DocId = "";
      return;
    }
  }

  searchCustomer(data: any, index: any) {
    console.log("sdfsfdsfsf");
    console.log("index", index);

    this.selectedSearchIndex = index;
    this.index = index;
    this.dataSource = null;
    this.dataSource = new MatTableDataSource([]);
    this.FilterSearchValue = "";
    this.FilterSearchBy = "";
    $(`#CustomerData`).modal("show");
    $(`#CustomerData`).css("z-index", "2051");
  }
  OnCloseSearch() {
    $(`#CustomerData`).modal("hide");
    //$("#PartnerName" + this.selectedSearchIndex).focus();
  }

  onSearchExistingCustomer() {
    this.OnCloseSearch();
    let length = this.firmPartners.length;

    this.FirmHead =
      this.FirmTypes == "Trust" || this.FirmTypes == "NGO"
        ? "Founder"
        : this.FirmHead;
    // this.firmPartners.push({ PartnerName: "", PartnerGender: "", PartnerAge: "", PartnerDesignation: "", PartnerPhoneNo: "", PartnerShare: "" });
    this.firmPartners[this.selectedSearchIndex].PartnerName =
      this.patnerData.CustomertName;
    this.firmPartners[this.selectedSearchIndex].PartnerGender =
      this.patnerData.Customer_Gender;
    this.firmPartners[this.selectedSearchIndex].PartnerAge =
      this.patnerData.GenderAge;
    this.firmPartners[this.selectedSearchIndex].PartnerPhoneNo =
      this.patnerData.PhoneNo;
    this.firmPartners[this.selectedSearchIndex].Owner_CustomerId =
      this.patnerData.CustomerId;
    this.firmPartners[this.selectedSearchIndex].PartnerDesignation =
      this.FirmHead;
    this.dataSource = new MatTableDataSource([]);
    this.FilterSearchValue = "";
    this.FilterSearchBy = "";
  }

  addBankDetail() {
    //console.log("this.BankDetails",this.BankDetails);
    if (this.BankDetails.length > 0) {
      let _prev = this.BankDetails[this.BankDetails.length - 1];
      if (_prev.BeneficiaryName != "") {
        if (
          _prev.BeneficiaryName == "" ||
          _prev.AccountNo == "" ||
          _prev.BankName == "" ||
          _prev.BankBranch == "" ||
          _prev.BankAcType == "" ||
          _prev.BankIFSC == ""
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please First Fill Required Field to Add More.",
            ...this.configSuccess,
          });
          return;
        }
      }
    }
    if (this.Bank_Verification == false) {
      this.BankDetails.push({
        BeneficiaryName: "",
        AccountNo: "",
        BankName: "",
        BankBranch: "",
        BankAcType: "",
        BankIFSC: "",
        BankMICRCode: "",
      });
    }
  }

  removeBankDetail(index: any) {
    if (this.BankDetails[index].Id > 0) {
      this.BankDetails[index].IsEdit = 1;
      this.removeBank.push(this.BankDetails[index]);
    }
    console.log("this.BankDetails[index].Id", this.BankDetails[index].Id);

    console.log("this.removeBank", this.removeBank);
    this.BankDetails.splice(index, 1);
  }

  ChangeACNo(data: any, dindex: any) {
    let _find = this.BankDetails.find((obj: any, index: any) => {
      return index != dindex && obj.AccountNo == data.AccountNo;
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This A/C No already exist.",
        ...this.configSuccess,
      });
      data.AccountNo = "";
      return;
    }
  }

  getCustomerBankList() {
    this._MasterService
      .Get_BankAccount_Dropdown_For_Customer({
        Customer_Id: this.customerModel.CustomerId,
      })
      .subscribe((res) => {
        this.CustomerBank = res;
      });
  }
  getKycApiDetail(res, i) {
    let KYCDocs =
      this.customerModel.Type == "Company"
        ? this.firmKYCDoc
        : this.CustomerKYCDoc;
    if (res.length == 0) {
      KYCDocs[i].Verified_Button = false;
    } else {
      KYCDocs[i].Verified_Button = true;
      this.KYC_Configration[i] = {
        Key: res[0].Key,
        SMS_URL: res[0].SMS_URL,
      };
    }
  }
  IsVerification(KYC, i) {
    this.Get_Alredy_Verified_KYC(KYC, i);
    if (KYC.KYC_DocId == 1 || KYC.DocId == 1) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "PAN_Verification" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 6) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "Aadhaar_generate_otp" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 4) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "Voter_ID" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 3) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "Passport" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 5) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "License" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 29 || KYC.DocId == 29) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "POSTGSTIN" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.KYC_DocId == 2 || KYC.DocId == 2) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "Electricity" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else if (KYC.DocId == 28) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: "CompanyDetails" })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, i);
        });
    } else {
      let KYCDocs =
        this.customerModel.Type == "Company"
          ? this.firmKYCDoc
          : this.CustomerKYCDoc;
      KYCDocs[i].Verified_Button = false;
      this.KYC_Configration[i] = {
        Id: 0,
        Is_Active: false,
        Key: "",
        SMS_Template: "",
        SMS_URL: "",
        Sender_Id: null,
        Step_Name: "",
        TemplateId: null,
        TemplateName: null,
        route: 0,
      };
    }
    //.log("this.KYC_Configration", this.KYC_Configration);
  }
  onVerification(KYC, i, ReVerify) {
    if (ReVerify == 1) {
      this.dialog
        .openConfirmDialog(
          `Are you sure you want to Re-Verified  this Document ?`
        )
        .afterClosed()
        .subscribe((dialogResult) => {
          if (dialogResult == false) {
            return;
          } else {
            this.KYCVerifyByAPI(KYC, i);
          }
        });
    } else {
      this.KYCVerifyByAPI(KYC, i);
    }
  }
  KYCVerifyByAPI(KYC, i) {
    this.showSpinner = true;
    if (KYC.KYC_DocId == 1 || KYC.DocId == 1) {
      var model = {
        KYC_DocNumber:
          this.customerModel.Type == "Company" ? KYC.DocNo : KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration[i].SMS_URL,
        KYC_DocId:
          this.customerModel.Type == "Company" ? KYC.DocId : KYC.KYC_DocId,
        Key: this.KYC_Configration[i].Key,
      };
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            DocumentId: 1,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId,
          },
        };

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
              .full_name,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
              .success,
            category: JSON.parse(data.KYC_Verification.APIResponse).data
              .category,
            message_code: JSON.parse(data.KYC_Verification.APIResponse)
              .message_code,
          };
        } else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            if (Save_res[0].CODE == 0) {
              this.showSpinner = false;
              if (
                JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
              ) {
                KYC.KYC_IsVerified = 0;
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: JSON.parse(data.KYC_Verification.APIResponse).message,
                  ...this.configSuccess,
                });
              } else {
                $(`#modalOverlay`).modal("show");
                $(`#modalOverlay`).css("z-index", "1050");
              }
              this.verify_DocId = 1;
            }
          });
      });
    } else if (KYC.KYC_DocId == 6) {
      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration[i].SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration[i].Key,
      };
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        //console.log("API Result", res);
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 6,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId,
          },
        };

        //console.log("Save_Json", data);
        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            this.showSpinner = false;
            if (Save_res[0].CODE == 0) {
              if (
                JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
              ) {
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: JSON.parse(data.KYC_Verification.APIResponse).message,
                  ...this.configSuccess,
                });
              } else {
                this.clientId = JSON.parse(
                  data.KYC_Verification.APIResponse
                ).data.client_id;
                this.verify_DocNumber = KYC.KYC_DocNumber;
                this.verify_DocId = 6;
                this.record = i;
                $(`#KYCOTPModel`).modal("show");
                $(`#KYCOTPModel`).css("z-index", "1050");
              }
            }
          });
      });
    } else if (KYC.KYC_DocId == 4) {
      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration[i].SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration[i].Key,
      };
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        //console.log("API Result", res);
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 4,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId,
          },
        };

        // KYC.KYC_IsVerified = 1;
        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber: KYC.KYC_DocNumber,
            Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
              .success,
            gender: JSON.parse(data.KYC_Verification.APIResponse).data.gender,
            area: JSON.parse(data.KYC_Verification.APIResponse).data.area,
            dist: JSON.parse(data.KYC_Verification.APIResponse).data.district,
            state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
            age: JSON.parse(data.KYC_Verification.APIResponse).data.age,
            //loc: JSON.parse(data.KYC_Verification.APIResponse).data.address.loc,
            //vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
            //subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
            //street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
            //house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
            //landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
            //Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
            //profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
            care_of: JSON.parse(data.KYC_Verification.APIResponse).data
              .relation_name,
            message_code: JSON.parse(data.KYC_Verification.APIResponse)
              .message_code,
          };
        } else {
          KYC.KYC_IsVerified = 0;
          // this.showSpinner = false;
          //this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
          // return;
        }

        //console.log("Save_Json", data);
        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            if (Save_res[0].CODE == 0) {
              this.showSpinner = false;
              if (
                JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
              ) {
                KYC.KYC_IsVerified = 0;
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: JSON.parse(data.KYC_Verification.APIResponse).message,
                  ...this.configSuccess,
                });
              } else {
                $(`#modalOverlay`).modal("show");
                $(`#modalOverlay`).css("z-index", "1050");
              }
              this.verify_DocId = 4;
            }
          });
      });
    } else if (KYC.KYC_DocId == 3) {
    } else if (KYC.KYC_DocId == 5) {
      this.showSpinner = false;
      this.verify_DocNumber = KYC.KYC_DocNumber;
      this.verify_DocId = 5;
      this.record = i;
      $(`#DLModelVerified`).modal("show");
      $(`#DLModelVerified`).css("z-index", "1050");
    } else if (KYC.KYC_DocId == 29 || KYC.DocId == 29) {
      var model = {
        KYC_DocNumber:
          this.customerModel.Type == "Company" ? KYC.DocNo : KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration[i].SMS_URL,
        KYC_DocId:
          this.customerModel.Type == "Company" ? KYC.DocId : KYC.KYC_DocId,
        Key: this.KYC_Configration[i].Key,
      };
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            DocumentId: 29,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId,
          },
        };

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            business_name: JSON.parse(data.KYC_Verification.APIResponse).data
              .business_name,
            gst_status: JSON.parse(data.KYC_Verification.APIResponse).data
              .gstin_status,
            nature_bus_activities: JSON.parse(data.KYC_Verification.APIResponse)
              .data.nature_bus_activities,
            Gst_Registration_Date: JSON.parse(data.KYC_Verification.APIResponse)
              .data.date_of_registration,
            pan_number: JSON.parse(data.KYC_Verification.APIResponse).data
              .pan_number,
            SigningAutority: JSON.parse(data.KYC_Verification.APIResponse).data
              .legal_name,
            FirmType: JSON.parse(data.KYC_Verification.APIResponse).data
              .constitution_of_business,
            address: JSON.parse(data.KYC_Verification.APIResponse).data.address,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
              .success,
            message_code: JSON.parse(data.KYC_Verification.APIResponse)
              .message_code,
          };
        } else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            if (Save_res[0].CODE == 0) {
              this.showSpinner = false;
              if (
                JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
              ) {
                KYC.KYC_IsVerified = 0;
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: JSON.parse(data.KYC_Verification.APIResponse).message,
                  ...this.configSuccess,
                });
              } else {
                $(`#Reponse_Verified_Detail`).modal("show");
                $(`#Reponse_Verified_Detail`).css("z-index", "1050");
              }
              this.verify_DocId = 29;
              this.record = i;
            }
          });
      });
    } else if (KYC.DocId == 28) {
      var model = {
        KYC_DocNumber:
          this.customerModel.Type == "Company" ? KYC.DocNo : KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration[i].SMS_URL,
        KYC_DocId:
          this.customerModel.Type == "Company" ? KYC.DocId : KYC.KYC_DocId,
        Key: this.KYC_Configration[i].Key,
      };
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            DocumentId: 28,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId,
          },
        };

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber:
              this.customerModel.Type == "Company"
                ? KYC.DocNo
                : KYC.KYC_DocNumber,
            company_name: JSON.parse(data.KYC_Verification.APIResponse).data
              .company_name,
            registration_number: JSON.parse(data.KYC_Verification.APIResponse)
              .data.details.company_info.registration_number,
            company_sub_category: JSON.parse(data.KYC_Verification.APIResponse)
              .data.details.company_info.company_sub_category,
            class_of_company: JSON.parse(data.KYC_Verification.APIResponse).data
              .details.company_info.class_of_company,
            company_category: JSON.parse(data.KYC_Verification.APIResponse).data
              .details.company_info.company_category,
            address: JSON.parse(data.KYC_Verification.APIResponse).data.details
              .company_info.registered_address,

            authorized_capital: JSON.parse(data.KYC_Verification.APIResponse)
              .data.details.company_info.authorized_capital,
            paid_up_capital: JSON.parse(data.KYC_Verification.APIResponse).data
              .details.company_info.paid_up_capital,
            date_of_incorporation: JSON.parse(data.KYC_Verification.APIResponse)
              .data.details.company_info.date_of_incorporation,
            company_status: JSON.parse(data.KYC_Verification.APIResponse).data
              .details.company_info.company_status,

            directors: JSON.parse(data.KYC_Verification.APIResponse).data
              .details.directors,
            charges: JSON.parse(data.KYC_Verification.APIResponse).data.details
              .charges,

            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
              .success,
            message_code: JSON.parse(data.KYC_Verification.APIResponse)
              .message_code,
          };
          this.Directors = new MatTableDataSource(
            JSON.parse(data.KYC_Verification.APIResponse).data.details.directors
          );
          this.Charges = new MatTableDataSource(
            JSON.parse(data.KYC_Verification.APIResponse).data.details.charges
          );
        } else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            if (Save_res[0].CODE == 0) {
              this.showSpinner = false;
              if (
                JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
              ) {
                KYC.KYC_IsVerified = 0;
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: JSON.parse(data.KYC_Verification.APIResponse).message,
                  ...this.configSuccess,
                });
              } else {
                $(`#Reponse_Verified_Detail`).modal("show");
                $(`#Reponse_Verified_Detail`).css("z-index", "1050");
              }
              this.verify_DocId = 28;
              this.record = i;
            }
          });
      });
    } else if (KYC.KYC_DocId == 2) {
      this.getElectricityStatelist();
      this.showSpinner = false;
      this.verify_DocNumber = KYC.KYC_DocNumber;
      this.verify_DocId = 2;
      this.record = i;

      $(`#ElectricityModelVerified`).modal("show");
      $(`#ElectricityModelVerified`).css("z-index", "1050");
    }
  }

  Get_Alredy_Verified_KYC(KYC, i) {
    // debugger;
    this._MasterService
      .Get_Alredy_Verified_KYC({
        DocumentId:
          this.customerModel.Type == "Company" ? KYC.DocId : KYC.KYC_DocId,
        KYC_DocNumber:
          this.customerModel.Type == "Company" ? KYC.DocNo : KYC.KYC_DocNumber,
      })
      .subscribe((res: any) => {
        let KYCDocs =
          this.customerModel.Type == "Company"
            ? this.firmKYCDoc
            : this.CustomerKYCDoc;
        if (res.length > 0) {
          var Masking =
            this.customerModel.Type == "Company"
              ? KYC.DocNo
              : KYC.KYC_DocNumber;
          Masking = Masking.toUpperCase().slice(
            3,
            this.customerModel.Type == "Company"
              ? KYC.DocNo
              : KYC.KYC_DocNumber.length - 3
          );

          var n = Masking.length;
          var ch = Masking.split("");

          for (var j = 0; j < n; j++) {
            ch[j] = "X";
          }

          if (KYC.KYC_DocId == 1 || KYC.DocId == 1) {
            this.verify_DocId = 1;
            //var afterDoc = this.customerModel.Type == 'Company' ? Object.assign(KYC.DocNo) : Object.assign(KYC.KYC_DocNumber);
            //var MaskingDoc = this.masking(afterDoc);
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                this.customerModel.Type == "Company"
                  ? KYC.DocNo
                  : KYC.KYC_DocNumber,
              //  DoucumentNumber: this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(0, 3) + ch.join('') + this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else if (KYC.KYC_DocId == 6 || KYC.DocId == 6) {
            this.verify_DocId = 6;
            // var MaskingDoc = this.masking(this.verify_DocNumber);
            // var afterDoc = Object.assign(this.verify_DocNumber);
            //var MaskingDoc = this.masking(afterDoc);
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: this.verify_DocNumber,
              //DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + ch.join('') + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              country: JSON.parse(res[0].APIResponse).data.address.country,
              dist: JSON.parse(res[0].APIResponse).data.address.dist,
              state: JSON.parse(res[0].APIResponse).data.address.state,
              po: JSON.parse(res[0].APIResponse).data.address.po,
              loc: JSON.parse(res[0].APIResponse).data.address.loc,
              vtc: JSON.parse(res[0].APIResponse).data.address.vtc,
              subdist: JSON.parse(res[0].APIResponse).data.address.subdist,
              street: JSON.parse(res[0].APIResponse).data.address.street,
              house: JSON.parse(res[0].APIResponse).data.address.house,
              landmark: JSON.parse(res[0].APIResponse).data.address.landmark,
              Pin: JSON.parse(res[0].APIResponse).data.zip,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.care_of,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              zip: JSON.parse(res[0].APIResponse).data.zip,
            };
            if (res.length > 1) {
              this.Verify_PAN_No = JSON.parse(
                res[1].APIResponse
              ).data.pan_number;
            }
          } else if (KYC.KYC_DocId == 4 || KYC.DocId == 4) {
            this.verify_DocId = 4;
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.KYC_DocNumber,
              // DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + KYC.KYC_DocNumber.toUpperCase().substr( KYC.KYC_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              area: JSON.parse(res[0].APIResponse).data.area,
              dist: JSON.parse(res[0].APIResponse).data.district,
              state: JSON.parse(res[0].APIResponse).data.state,
              age: JSON.parse(res[0].APIResponse).data.age,
              relation_name: JSON.parse(res[0].APIResponse).data.relation_name,
              relation_type: JSON.parse(res[0].APIResponse).data.relation_type,

              //loc: JSON.parse(data.KYC_Verification.APIResponse).data.address.loc,
              //vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
              //subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
              //street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
              //house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
              //landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
              //Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
              //profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.relation_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else if (KYC.KYC_DocId == 28 || KYC.DocId == 28) {
            this.verify_DocId = 28;
            this.APIResponse = {
              KYC_DocId: KYC.DocId,
              DoucumentNumber:
                this.customerModel.Type == "Company"
                  ? KYC.DocNo
                  : KYC.KYC_DocNumber,
              // DoucumentNumber: this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.length - 3),

              company_name: JSON.parse(res[0].APIResponse).data.company_name,
              registration_number: JSON.parse(res[0].APIResponse).data.details
                .company_info.registration_number,
              company_sub_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_sub_category,
              class_of_company: JSON.parse(res[0].APIResponse).data.details
                .company_info.class_of_company,
              company_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_category,
              address: JSON.parse(res[0].APIResponse).data.details.company_info
                .registered_address,

              authorized_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.authorized_capital,
              paid_up_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.paid_up_capital,
              date_of_incorporation: JSON.parse(res[0].APIResponse).data.details
                .company_info.date_of_incorporation,
              company_status: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_status,

              directors: JSON.parse(res[0].APIResponse).data.details.directors,
              charges: JSON.parse(res[0].APIResponse).data.details.charges,

              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else if (KYC.KYC_DocId == 29 || KYC.DocId == 29) {
            this.verify_DocId = 29;
            this.APIResponse = {
              KYC_DocId: 29,
              DoucumentNumber:
                this.customerModel.Type == "Company"
                  ? KYC.DocNo
                  : KYC.KYC_DocNumber,
              //DoucumentNumber: this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.toUpperCase().substr(this.customerModel.Type == 'Company' ? KYC.DocNo : KYC.KYC_DocNumber.length - 3),

              business_name: JSON.parse(res[0].APIResponse).data.business_name,
              gst_status: JSON.parse(res[0].APIResponse).data.gstin_status,
              nature_bus_activities: JSON.parse(res[0].APIResponse).data
                .nature_bus_activities,
              Gst_Registration_Date: JSON.parse(res[0].APIResponse).data
                .date_of_registration,
              pan_number: JSON.parse(res[0].APIResponse).data.pan_number,
              SigningAutority: JSON.parse(res[0].APIResponse).data.legal_name,
              FirmType: JSON.parse(res[0].APIResponse).data
                .constitution_of_business,
              address: JSON.parse(res[0].APIResponse).data.address,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else if (KYC.KYC_DocId == 5 || KYC.DocId == 5) {
            this.verify_DocId = 5;
            this.APIResponse = {
              KYC_DocId: 5,
              DoucumentNumber:
                this.customerModel.Type == "Company"
                  ? KYC.DocNo
                  : KYC.KYC_DocNumber,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              doi: JSON.parse(res[0].APIResponse).data.doi,
              doe: JSON.parse(res[0].APIResponse).data.doe,
              address: JSON.parse(res[0].APIResponse).data.permanent_address,
              vehicle_classes: JSON.parse(res[0].APIResponse).data
                .vehicle_classes,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              ola_name: JSON.parse(res[0].APIResponse).data.ola_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else if (KYC.KYC_DocId == 2 || KYC.DocId == 2) {
            this.verify_DocId = 2;
            this.APIResponse = {
              KYC_DocId: 2,
              DoucumentNumber:
                this.customerModel.Type == "Company"
                  ? KYC.DocNo
                  : KYC.KYC_DocNumber,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              address: JSON.parse(res[0].APIResponse).data.address,
              mobile: JSON.parse(res[0].APIResponse).data.mobile,
              state: JSON.parse(res[0].APIResponse).data.state,
              operator_code: JSON.parse(res[0].APIResponse).data.operator_code,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          } else {
            this.verify_DocId = KYC.KYC_DocId;
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.KYC_DocNumber,
              // DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + KYC.KYC_DocNumber.toUpperCase().substr( KYC.KYC_DocNumber.length - 3),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              area: JSON.parse(res[0].APIResponse).data.area,
              dist: JSON.parse(res[0].APIResponse).data.district,
              state: JSON.parse(res[0].APIResponse).data.state,
              age: JSON.parse(res[0].APIResponse).data.age,
              relation_name: JSON.parse(res[0].APIResponse).data.relation_name,
              relation_type: JSON.parse(res[0].APIResponse).data.relation_type,

              //loc: JSON.parse(data.KYC_Verification.APIResponse).data.address.loc,
              //vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
              //subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
              //street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
              //house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
              //landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
              //Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
              //profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.relation_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
          }
          KYC.LastVerfiedDate = res[0].LastVerfiedDate;
          KYCDocs[i].KYC_IsVerified = 1;
        } else {
          KYC.LastVerfiedDate = "";
          KYCDocs[i].KYC_IsVerified = 0;
        }
        //console.log("CustomerKYCDoc", this.CustomerKYCDoc);
      });
  }
  getdata(KYC, i) {
    this.record = i;
    this.Get_Alredy_Verified_KYC(KYC, i);
    console.log(" this.APIResponse", this.APIResponse);
    //if (this.verify_DocId == 6) {
    $(`#modalOverlay`).modal("show");
    $(`#modalOverlay`).css("z-index", "1090");
    //}
    //else {
    //  $(`#Reponse_Verified_Detail`).modal("show");
    //  $(`#Reponse_Verified_Detail`).css("z-index", "1050");
    //}
  }
  onSAVEKYCOTP() {
    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Aadhaar_submit_otp" })
      .subscribe((res: any) => {
        var data = {
          Key: res[0].Key,
          SMS_URL: res[0].SMS_URL,
        };
        var model = {
          KYC_DocNumber: this.verify_DocNumber,
          SMS_URL: data.SMS_URL,
          KYC_DocId: this.verify_DocId,
          Key: data.Key,
          OTP: this.OTP,
          clientId: this.clientId,
        };

        this._MasterService
          .Get_KYC_Verification(model)
          .subscribe((res: any) => {
            //console.log("API Result", res);
            var APIResult = res;
            var data = {
              KYC_Verification: {
                DoucumentNumber: model.KYC_DocNumber,
                DocumentId: model.KYC_DocId,
                IsVerified: APIResult.success,
                APIURL: APIResult.APIURL,
                APIRequest: APIResult.request,
                APIResponse: APIResult.response,
                APIStatus: APIResult.status_code,
                LoginUserId: this.currentUser.userId,
              },
            };
            let KYCDocs =
              this.customerModel.Type == "Company"
                ? this.firmKYCDoc
                : this.CustomerKYCDoc;
            if (APIResult.success == true) {
              var clientId = JSON.parse(data.KYC_Verification.APIResponse).data
                .client_id;

              KYCDocs[this.record].KYC_IsVerified = 1;
              this.APIResponse = {
                KYC_DocId: 6,
                DoucumentNumber: this.verify_DocNumber,
                Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                  .full_name,
                IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
                  .success,
                dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
                gender: JSON.parse(data.KYC_Verification.APIResponse).data
                  .gender,
                country: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.country,
                dist: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .dist,
                state: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.state,
                po: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .po,
                loc: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .loc,
                vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .vtc,
                subdist: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.subdist,
                street: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.street,
                house: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.house,
                landmark: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.landmark,
                Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
                profile_image: JSON.parse(data.KYC_Verification.APIResponse)
                  .data.profile_image,
                care_of: JSON.parse(data.KYC_Verification.APIResponse).data
                  .care_of,
                message_code: JSON.parse(data.KYC_Verification.APIResponse)
                  .message_code,
                zip: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
              };
              //console.log("this.APIResponse", this.APIResponse);
            } else {
              KYCDocs[this.record].KYC_IsVerified = 0;
              //  this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
              // return;
            }
            //console.log("Save_Json", data);

            //this.lmsService
            //  .UploadOthersDoc({
            //    DocName: JSON.parse(data.KYC_Verification.APIResponse).data.full_name+".jpg",
            //    DocData: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
            //  })
            //  .subscribe((res: any) => { });

            this._MasterService
              .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
              .subscribe((Save_res: any) => {
                this.showSpinner = true;
                if (Save_res[0].CODE == 0) {
                  this.showSpinner = false;
                  if (APIResult.success == true) {
                    this._MasterService
                      .Get_Sms_Configration({ Step_Name: "Aadhaar_To_PAN" })
                      .subscribe((result: any) => {
                        if (result.length > 0) {
                          var data = {
                            Key: result[0].Key,
                            SMS_URL: result[0].SMS_URL,
                          };
                          var model = {
                            KYC_DocNumber: this.verify_DocNumber,
                            SMS_URL: data.SMS_URL,
                            KYC_DocId: this.verify_DocId,
                            Key: data.Key,
                          };

                          this._MasterService
                            .Get_KYC_Verification(model)
                            .subscribe((res: any) => {
                              var APIAadhaar_To_PANResult = res;

                              if (APIAadhaar_To_PANResult.success == true) {
                                var data = {
                                  KYC_Verification: {
                                    DoucumentNumber: model.KYC_DocNumber,
                                    DocumentId: model.KYC_DocId,
                                    IsVerified: APIAadhaar_To_PANResult.success,
                                    APIURL: APIAadhaar_To_PANResult.APIURL,
                                    APIRequest: APIAadhaar_To_PANResult.request,
                                    APIResponse:
                                      APIAadhaar_To_PANResult.response,
                                    APIStatus:
                                      APIAadhaar_To_PANResult.status_code,
                                    LoginUserId: this.currentUser.userId,
                                  },
                                };
                                this.Verify_PAN_No = JSON.parse(
                                  data.KYC_Verification.APIResponse
                                ).data.pan_number;
                                this.CustomerKYCDoc.push({
                                  KYC_DocId: "1",
                                  KYC_DocNumber: this.Verify_PAN_No,
                                  KYC_DocFile: "",
                                  KYC_DocFile1: "",
                                  Verified_Button: false,
                                });
                                this.checkDuplicate(
                                  this.CustomerKYCDoc[
                                    this.CustomerKYCDoc.length - 1
                                  ]
                                );
                                this._MasterService
                                  .Save_Customer_Kyc_Verification({
                                    JSON: JSON.stringify(data),
                                  })
                                  .subscribe((Save_res: any) => {
                                    this.showSpinner = false;
                                    if (Save_res[0].CODE == 0) {
                                      $(`#KYCOTPModel`).modal("hide");
                                      $(`#modalOverlay`).modal("show");
                                      $(`#modalOverlay`).css("z-index", "1050");
                                    }
                                  });
                              }
                            });
                        } else {
                          $(`#KYCOTPModel`).modal("hide");
                          $(`#modalOverlay`).modal("show");
                          $(`#modalOverlay`).css("z-index", "1050");
                        }
                      });
                  } else {
                    //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "YOUR KYC NOT VALID !!!",
                      ...this.configSuccess,
                    });
                    return;
                  }
                }
              });
          });
      });
  }
  onCloseKYCOTP() {
    $(`#KYCOTPModel`).modal("hide");
  }
  OnCloseReponse_Verified_Detail() {
    let KYCDocs =
      this.customerModel.Type == "Company"
        ? this.firmKYCDoc
        : this.CustomerKYCDoc;
    this.APIResponse = {};
    this.Get_Alredy_Verified_KYC(KYCDocs[this.record], this.record);
    $(`#modalOverlay`).modal("hide");
  }
  onGetData() {
    if (this.verify_DocId == 6) {
      let _pre = this.today.getTime();
      this.customerModel.Customer_ProfilePic =
        _pre + "_" + this.APIResponse.Full_name.split(" ")[0] + ".jpeg";
      this.customerModel.DocData = this.APIResponse.profile_image;
      this.customerModel.Customer_FirstName =
        this.APIResponse.Full_name.split(" ")[0];
      this.customerModel.Customer_LastName =
        this.APIResponse.Full_name.split(" ").length > 2
          ? this.APIResponse.Full_name.split(" ")[1] +
            " " +
            this.APIResponse.Full_name.split(" ")[2]
          : this.APIResponse.Full_name.split(" ")[1];
      this.customerModel.Customer_Relation_Type =
        this.APIResponse.care_of.split(":")[0];
      this.customerModel.Customer_Relation_FirstName =
        this.APIResponse.care_of.split(" ")[1];
      this.customerModel.Customer_Relation_LastName =
        this.APIResponse.care_of.split(" ").length > 3
          ? this.APIResponse.care_of.split(" ")[2] +
            " " +
            this.APIResponse.care_of.split(" ")[3]
          : this.APIResponse.care_of.split(" ")[2];
      this.customerModel.Customer_Gender = this.APIResponse.gender;
      this.customerModel.Customer_DOB = new Date(this.APIResponse.dob);
      var date1 = new Date(this.customerModel.Customer_DOB);
      var date2 = new Date();

      var Time = date2.getTime() - date1.getTime();
      var Days = Time / (1000 * 3600 * 24); //Diference in Days
      var year = Days / 365;
      this.Age = Math.round(year);

      this.customerAddressModel.Address =
        this.APIResponse.house +
        " " +
        this.APIResponse.street +
        " " +
        this.APIResponse.po +
        " " +
        this.APIResponse.loc +
        " " +
        this.APIResponse.vtc +
        " " +
        this.APIResponse.subdist;
      this.customerAddressModel.LandMark = this.APIResponse.landmark;

      this.customerAddressModel.PinCode = this.APIResponse.zip;
      this.customerAddressModel.StateId = this.stateDropdown.filter(
        (item) =>
          item.State_Name.trim().toUpperCase() ==
          this.APIResponse.state.trim().toUpperCase()
      )[0].StateId;
      this.GetDistrickDropdownPresent();
      this.customerAddressModel.DistrictId =
        this.districkDropdownPresent.filter(
          (item) =>
            item.District_Name.trim().toUpperCase() ==
            this.APIResponse.dist.trim().toUpperCase()
        )[0].DistrictId;
    }
    if (this.verify_DocId == 29) {
      this.customerModel.Firm_Name = this.APIResponse.business_name;
      var Address = "";
      for (let i = 0; i < this.APIResponse.address.split(",").length - 4; i++) {
        Address =
          Address +
          " " +
          this.APIResponse.address.split(",")[i] +
          Address +
          ",";
      }
      this.customerModel.Firm_RegAddress = Address;
      this.customerModel.Firm_RegLandMark =
        this.APIResponse.address.split(",")[
          this.APIResponse.address.split(",").length - 4
        ];
      this.customerModel.Firm_RegState = this.stateDropdown.filter(
        (item) =>
          item.State_Name.trim().toUpperCase() ==
          this.APIResponse.address
            .split(",")
            [this.APIResponse.address.split(",").length - 2].trim()
            .toUpperCase()
      )[0].StateId;
      this.losService
        .GetDistrickDropdown({ StateID: this.customerModel.Firm_RegState })
        .subscribe((res: any) => {
          this.districkDropdownReg = res;
          this.customerModel.Firm_RegDistrict = this.districkDropdownReg.filter(
            (item) =>
              item.District_Name.trim().toUpperCase() ==
              this.APIResponse.address
                .split(",")
                [this.APIResponse.address.split(",").length - 3].trim()
                .toUpperCase()
          )[0].DistrictId;

          this.GetTehasilDropdownReg();
        });
    }

    if (this.verify_DocId == 28) {
      this.customerModel.Firm_Name = this.APIResponse.business_name;
      //var Address = '';
      //for (let i = 0; i < this.APIResponse.address.split(',').length - 4; i++) {
      //  Address = Address + ' ' + this.APIResponse.address.split(',')[i] + Address + ',';

      //}
      //this.customerModel.Firm_RegAddress = Address;
      //this.customerModel.Firm_RegLandMark = this.APIResponse.address.split(',')[this.APIResponse.address.split(',').length - 4];
      //this.customerModel.Firm_RegState = this.stateDropdown.filter(item => item.State_Name.trim().toUpperCase() == this.APIResponse.address.split(',')[this.APIResponse.address.split(',').length - 2].trim().toUpperCase())[0].StateId;
      //this.losService.GetDistrickDropdown({ StateID: this.customerModel.Firm_RegState }).subscribe((res: any) => {
      //  this.districkDropdownReg = res;
      //  this.customerModel.Firm_RegDistrict = this.districkDropdownReg.filter(item => item.District_Name.trim().toUpperCase() == this.APIResponse.address.split(',')[this.APIResponse.address.split(',').length - 3].trim().toUpperCase())[0].DistrictId;

      //  this.GetTehasilDropdownReg();
      //});
    }
    this.OnCloseReponse_Verified_Detail();
  }

  FirmTypeBind() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "FirmType" })
      .subscribe((res: any) => {
        console.log(res);
        this.FirmTypeList = res;
      });
  }
  PartnerDesignationBind() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "FirmRelationship" })
      .subscribe((res: any) => {
        console.log(res);
        this.PartnerDesignationList = res;
      });
  }
  BusinessCategoryBind() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "BusinessCategory" })
      .subscribe((res: any) => {
        console.log(res);
        this.BusinessCategoryList = res;
      });
  }
  BusinessTypeBind() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "BusinessType" })
      .subscribe((res: any) => {
        console.log(res);
        this.BusinessTypeList = res;
      });
  }
  getProfileDataList(check: any = false) {
    this._MasterService
      .GetCustomer_Profile_Master_For_Dropdown({ Type: "Profile", P_ID: 0 })
      .subscribe((res: any) => {
        console.log("customer profile list api call");
        console.log("console for customer profile dropdown list", res);
        this.ProfileData = res;
         this.cdr.detectChanges();
        if (check == false) {
          this.CategoryData = null;
          this.SubCategoryData = null;
          this.NatureofworkData = null;
        }

        //this.CategoryData = null;
        //this.SubCategoryData = null;
        //this.NatureofworkData = null;
      });
  }

  // getProfileDataList(check: any = false): Observable<any> {
  //   return this._MasterService
  //     .GetCustomer_Profile_Master_For_Dropdown({ Type: "Profile", P_ID: 0 })
  //     .pipe(
  //       tap((res: any) => {
  //         console.log(res);
  //         this.ProfileData = res;
  //         if (check == false) {
  //           this.CategoryData = null;
  //           this.SubCategoryData = null;
  //           this.NatureofworkData = null;
  //         }
  //       })
  //     );
  // }

  // getCategoryDataList(check: any = false) {
  //   this.getProfileDataList(check)
  //     .pipe(
  //       concatMap(() =>
  //         this._MasterService.GetCustomer_Profile_Master_For_Dropdown({
  //           Type: "Category",
  //           P_ID: this.customerModel.Customer_Profile,
  //         })
  //       )
  //     )
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.CategoryData = res;
  //       if (check == false) {
  //         this.SubCategoryData = null;
  //         this.NatureofworkData = null;
  //       }
  //     });
  // }

  // getSubCategoryList(check: any = false) {
  //   this.getProfileDataList(check)
  //     .pipe(
  //       concatMap(() =>
  //         this._MasterService.GetCustomer_Profile_Master_For_Dropdown({
  //           Type: "Sub-Category",
  //           P_ID: this.customerModel.Customer_Category,
  //         })
  //       )
  //     )
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.SubCategoryData = res;
  //       if (check == false) {
  //         this.NatureofworkData = null;
  //       }
  //     });
  // }

  // getNatureofworkList(check: any = false) {
  //   this.getProfileDataList(check)
  //     .pipe(
  //       concatMap(() =>
  //         this._MasterService.GetCustomer_Profile_Master_For_Dropdown({
  //           Type: "Nature of work",
  //           P_ID: this.customerModel.Customer_Category,
  //         })
  //       )
  //     )
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.NatureofworkData = res;
  //     });
  // }

  getCategoryDataList(check: any = false) {
    this._MasterService
      .GetCustomer_Profile_Master_For_Dropdown({
        Type: "Category",
        P_ID: 0,
      })
      .subscribe((res: any) => {
        console.log("customer category list api call");
        console.log("console for customer category dropdown list", res);
        this.CategoryData = res;
        if (check == false) {
          this.SubCategoryData = null;
          this.NatureofworkData = null;
        }
      });
  }
  getSubCategoryList(check: any = false) {
    this._MasterService
      .GetCustomer_Profile_Master_For_Dropdown({
        Type: "Sub-Category",
        P_ID: 0,
      })
      .subscribe((res: any) => {
        console.log("customer sub-category api call ");
        console.log("console for customer sub category dropdown list", res);
        this.SubCategoryData = res;
        if (check == false) {
          this.NatureofworkData = null;
        }
      });
  }
  getNatureofworkList(check: any = false) {
    this._MasterService
      .GetCustomer_Profile_Master_For_Dropdown({
        Type: "Nature of work",
        P_ID: 0,
      })
      .subscribe((res: any) => {
        console.log("customer nature of work api call");
        console.log("console for nature of work api call", res);
        this.NatureofworkData = res;
      });
  }
  onCloseDLModel() {
    $(`#DLModelVerified`).modal("hide");
  }
  onVerifiedDrivingLicence() {
    var model = {
      KYC_DocNumber: this.verify_DocNumber,
      SMS_URL: this.KYC_Configration[this.record].SMS_URL,
      KYC_DocId: this.verify_DocId,
      Key: this.KYC_Configration[this.record].Key,
      DOB: moment(this.DL_DOB).format("YYYY-MM-DD"),
    };
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.verify_DocNumber,

          DocumentId: 5,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };
      let KYCDocs =
        this.customerModel.Type == "Company"
          ? this.firmKYCDoc
          : this.CustomerKYCDoc;
      if (APIResult.success == true) {
        KYCDocs[this.record].KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: this.verify_DocId,
          DoucumentNumber: this.verify_DocNumber,
          // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
            .success,
          dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
          doi: JSON.parse(data.KYC_Verification.APIResponse).data.doi,
          doe: JSON.parse(data.KYC_Verification.APIResponse).data.doe,

          address: JSON.parse(data.KYC_Verification.APIResponse).data
            .permanent_address,
          vehicle_classes: JSON.parse(data.KYC_Verification.APIResponse).data
            .vehicle_classes,
          profile_image: JSON.parse(data.KYC_Verification.APIResponse).data
            .profile_image,
          ola_name: JSON.parse(data.KYC_Verification.APIResponse).data.ola_name,
          message_code: JSON.parse(data.KYC_Verification.APIResponse)
            .message_code,
        };
        this.CustomerKYCDoc[this.record].KYC_IssuedDate = new Date(
          JSON.parse(data.KYC_Verification.APIResponse).data.doi
        );
        this.CustomerKYCDoc[this.record].KYC_ExpiredDate = new Date(
          JSON.parse(data.KYC_Verification.APIResponse).data.doe
        );
      } else {
        KYCDocs[this.record].KYC_IsVerified = 0;
      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              $(`#DLModelVerified`).modal("hide");
              $(`#modalOverlay`).modal("show");
              $(`#modalOverlay`).css("z-index", "1050");
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });
    });
  }
  onClosemodalOverlay() {
    let KYCDocs =
      this.customerModel.Type == "Company"
        ? this.firmKYCDoc
        : this.CustomerKYCDoc;
    this.APIResponse = {};
    $(`#modalOverlay`).modal("hide");
  }

  onVerifiedElectricity() {
    var model = {
      KYC_DocNumber: this.verify_DocNumber,
      SMS_URL: this.KYC_Configration[this.record].SMS_URL,
      KYC_DocId: this.verify_DocId,
      Key: this.KYC_Configration[this.record].Key,
      Op_code: this.OperatorCode,
    };

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: model.KYC_DocId,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };
      let KYCDocs =
        this.customerModel.Type == "Company"
          ? this.firmKYCDoc
          : this.CustomerKYCDoc;
      if (APIResult.success == true) {
        KYCDocs[this.record].KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: this.verify_DocId,
          DoucumentNumber: this.verify_DocNumber,
          // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
            .full_name,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
            .success,
          address: JSON.parse(data.KYC_Verification.APIResponse).data.address,
          mobile: JSON.parse(data.KYC_Verification.APIResponse).data.mobile,
          state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
          operator_code: JSON.parse(data.KYC_Verification.APIResponse).data
            .operator_code,
          message_code: JSON.parse(data.KYC_Verification.APIResponse)
            .message_code,
        };
      } else {
        KYCDocs[this.record].KYC_IsVerified = 0;
      }
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              $(`#ElectricityModelVerified`).modal("hide");
              $(`#modalOverlay`).modal("show");
              $(`#modalOverlay`).css("z-index", "1050");
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });
    });
  }
  onCloseElectricityModel() {
    $(`#ElectricityModelVerified`).modal("hide");
  }
  getElectricityStatelist() {
    this._MasterService.Get_Electricity_State().subscribe((res: any) => {
      if (res.length > 0) {
        this.ElectricityStatelist = res;
      }
    });
  }
  getElectricityOpCodelist() {
    this._MasterService
      .Get_Electricity_OperatorCode({ state: this.ELState })
      .subscribe((res: any) => {
        this.ElectricityOpCodelist = res;
      });
  }
  BankVerify() {
    this.VerifyAccountNo = "";
    this.VerifyIFSC = "";
    //this.BankDetails.forEach(val => this.AllBankDetails.push(Object.assign({}, val)));
    //console.log("bankdetails sdfdf", this.BankDetails);
    //console.log("AllBankDetails sdfdf", this.AllBankDetails);
    $(`#BankDetailModelVerified`).modal("show");
    $(`#BankDetailModelVerified`).css("z-index", "1052");
  }
  onCloseBankModel() {
    this.BankForm = true;
    $(`#BankDetailModelVerified`).modal("hide");
  }
  onVerifiedBankDetail() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.VerifyAccountNo,
      SMS_URL: this.Bank_VerificationKeys.SMS_URL,
      IFSC: this.VerifyIFSC,
      Key: this.Bank_VerificationKeys.Key,
    };
    //  console.log("AllBankDetails sdfdf", this.AllBankDetails);

    console.log("BankDetails sdfdf", this.BankDetails);
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: 0,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            //if (APIResult.success == true) {
            this.BankForm = false;

            this.APIResponse = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.account_exists,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,
              micr: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data
                .remarks,

              message_code: JSON.parse(data.KYC_Verification.APIResponse)
                .message_code,
            };
            this.APIResponseData = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data
                .account_exists,

              micr: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data
                .remarks,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,

              message_code: JSON.parse(data.KYC_Verification.APIResponse)
                .message_code,
            };
            //} else {
            //  //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
            //  this.onCloseBankModel();
            //  this.snackBar.openFromComponent(SnackbarComponent, {
            //    data: "YOUR Bank Account NOT VALID !!!",
            //    ...this.configSuccess,
            //  });
            //  return;
            //}
          }
        });
    });
  }
  AddBankDetailApi() {
    console.log("this.BankDetails", this.BankDetails);
    var isexistsAccount = false;
    this.BankDetails.forEach((obj: any) => {
      if (obj.AccountNo == this.APIResponse.DoucumentNumber) {
        isexistsAccount = true;
        if (obj.Id > 0) {
          this.dialog
            .openConfirmDialog(
              `Your Bank Account Already Exists. Are you sure you want to replace this account details`
            )
            .afterClosed()
            .subscribe((dialogResult) => {
              if (dialogResult == false) {
                return;
              } else {
                obj.BeneficiaryName = this.APIResponse.Full_name;
                obj.AccountNo = this.APIResponse.DoucumentNumber;
                obj.BankName = this.APIResponse.bank_name;
                obj.BankBranch = this.APIResponse.branch;
                obj.BankIFSC = this.VerifyIFSC;
                obj.BankMICRCode = this.APIResponse.micr;
                obj.IsVerified = this.APIResponse.success;
                var data = {
                  CustomerBankDetail: {
                    BeneficiaryName: this.APIResponse.Full_name,
                    AccountNo: this.APIResponse.DoucumentNumber,
                    BankName: this.APIResponse.bank_name,
                    BankBranch: this.APIResponse.branch,
                    BankIFSC: this.VerifyIFSC,
                    BankMICRCode: this.APIResponse.micr,
                    BankAcType: obj.BankAcType,
                    Id: obj.Id,
                  },
                };
                this._MasterService
                  .Update_CustomerBankDetail({ JSON: JSON.stringify(data) })
                  .subscribe((Save_res: any) => {
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: Save_res[0].MSG,
                      ...this.configSuccess,
                    });

                    return;
                  });
              }
            });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Your Bank Account Already Exists!! Please remove Existing Account!!!",
            ...this.configSuccess,
          });
          return;
        }
      }
    });
    if (isexistsAccount == false) {
      this.BankDetails.push({
        BeneficiaryName: this.APIResponse.Full_name,
        AccountNo: this.APIResponse.DoucumentNumber,
        BankName: this.APIResponse.bank_name,
        BankBranch: this.APIResponse.branch,
        BankAcType: "",
        BankIFSC: this.VerifyIFSC,
        BankMICRCode: this.APIResponse.micr,
        IsEdit: 1,
        Id: 0,
        IsVerified: this.APIResponse.success,
      });
    }
    console.log("this.BankDetails", this.BankDetails);
    this.onCloseBankModel();
  }
  ImportPopup() {
    $(`#Import1Model`).modal("show");
    $(`#Import1Model`).css("z-index", "1090");
  }
  onCloseImport1Model() {
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceDuplicate = null;
    this.dataSourceNotValiddata = null;
    this.ImportBulkCustomer = null;

    $(`#Import1Model`).modal("hide");
  }
  ExportTemplate() {
    var json = [
      [
        "Id",
        "Customer_FirstName",
        "Customer_LastName",
        "Customer_Gender",
        "Customer_DOB_Day",
        "Customer_DOB_Month",
        "Customer_DOB_Year",
        "Customer_PhoneNo",
        "Customer_WhatsAppNo",
        "Customer_Email",
        "Customer_Website",
        "Customer_Religion",
        "Customer_Cast",
        "Customer_MaritalStatus",
        "Customer_Relation",
        "Customer_Relation_FirstName",
        "Customer_Relation_LastName",
        "Present_Address",
        "Present_LandMark",
        "Present_TehsilId",
        "Present_DistrictId",
        "Present_StateId",
        "Present_PinCode",
        "Present_NearstBranchDistance_KM",
        "Present_TotalYearsOnAddress",
        "Present_AddressRentBuy",
        "Present_IsCommunicationAddress",
        "Permanent_Address",
        "Permanent_LandMark",
        "Permanent_TehsilId",
        "Permanent_DistrictId",
        "Permanent_StateId",
        "Permanent_PinCode",
        "Permanent_NearstBranchDistance_KM",
        "Permanent_TotalYearsOnAddress",
        "Permanent_AddressRentBuy",
        "Permanent_IsCommunicationAddress",
        "Aadhaar_No",
        "PAN_No",
        "VoterId_No",
        "Electricity_KNo",
      ],
    ];
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "CustomerImport");

  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  ImportCustomerData(event) {
    this.showSpinner = true;
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceDuplicate = null;
    this.dataSourceNotValiddata = null;
    this.ImportBulkCustomer = null;
    this.DuplicateData = false;
    this.InValidData = false;
    this.InValidDataInDataBase = 0;
    const target: HTMLInputElement = event.target as HTMLInputElement;

    this.file = target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.ImportBulkCustomer = XLSX.utils.sheet_to_json(worksheet);

      if (this.ImportBulkCustomer.length > 0) {
        this.ImportBulkCustomer.forEach((x, index) => {
          if (
            x.Customer_DOB_Day != "" &&
            x.Customer_DOB_Day != undefined &&
            x.Customer_DOB_Month != "" &&
            x.Customer_DOB_Month != undefined &&
            x.Customer_DOB_Year != "" &&
            x.Customer_DOB_Year != undefined
          )
            x.Customer_DOB =
              x.Customer_DOB_Year +
              "-" +
              x.Customer_DOB_Month +
              "-" +
              x.Customer_DOB_Day;
          else x.Customer_DOB = "";
          if (
            x.Id == "" ||
            x.Id == undefined ||
            x.Customer_FirstName == "" ||
            x.Customer_LastName == "" ||
            x.Customer_Gender == "" ||
            x.Customer_DOB == "" ||
            x.Customer_DOB_Year == "" ||
            x.Customer_DOB_Month == "" ||
            x.Customer_DOB_Day == "" ||
            x.Customer_PhoneNo == "" ||
            x.Customer_Religion == "" ||
            x.Customer_Cast == "" ||
            x.Customer_MaritalStatus == "" ||
            x.Customer_Relation == "" ||
            x.Customer_Relation_FirstName == "" ||
            x.Customer_Relation_LastName == "" ||
            x.Present_Address == "" ||
            x.Present_LandMark == "" ||
            x.Present_TehsilId == "" ||
            x.Present_DistrictId == "" ||
            x.Present_StateId == "" ||
            x.Present_PinCode == "" ||
            x.Present_AddressRentBuy == "" ||
            x.Permanent_Address == "" ||
            x.Permanent_LandMark == "" ||
            x.Permanent_TehsilId == "" ||
            x.Permanent_DistrictId == "" ||
            x.Permanent_StateId == "" ||
            x.Permanent_PinCode == "" ||
            x.Permanent_AddressRentBuy == "" ||
            x.Customer_FirstName == undefined ||
            x.Customer_LastName == undefined ||
            x.Customer_Gender == undefined ||
            x.Customer_DOB == undefined ||
            x.Customer_DOB_Year == undefined ||
            x.Customer_DOB_Month == undefined ||
            x.Customer_DOB_Day == undefined ||
            x.Customer_PhoneNo == undefined ||
            x.Customer_Religion == undefined ||
            x.Customer_Cast == undefined ||
            x.Customer_MaritalStatus == undefined ||
            x.Customer_Relation == undefined ||
            x.Customer_Relation_FirstName == undefined ||
            x.Customer_Relation_LastName == undefined ||
            x.Present_Address == undefined ||
            x.Present_LandMark == undefined ||
            x.Present_TehsilId == undefined ||
            x.Present_DistrictId == undefined ||
            x.Present_StateId == undefined ||
            x.Present_PinCode == undefined ||
            x.Present_AddressRentBuy == undefined ||
            x.Permanent_Address == undefined ||
            x.Permanent_LandMark == undefined ||
            x.Permanent_TehsilId == undefined ||
            x.Permanent_DistrictId == undefined ||
            x.Permanent_StateId == undefined ||
            x.Permanent_PinCode == undefined ||
            x.Permanent_AddressRentBuy == undefined ||
            ((x.Aadhaar_No == "" || x.Aadhaar_No == undefined) &&
              (x.PAN_No == "" || x.PAN_No == undefined) &&
              (x.VoterId_No == "" || x.VoterId_No == undefined))
          ) {
            this.displayImportData = [
              "Id",
              "Customer_FirstName",
              "Customer_LastName",
              "Customer_Gender",
              "Customer_DOB_Year",
              "Customer_DOB_Month",
              "Customer_DOB_Day",
              "Customer_PhoneNo",
              "Customer_WhatsAppNo",
              "Customer_Email",
              "Customer_Website",
              "Customer_Religion",
              "Customer_Cast",
              "Customer_MaritalStatus",
              "Customer_Relation",
              "Customer_Relation_FirstName",
              "Customer_Relation_LastName",
              "Present_Address",
              "Present_LandMark",
              "Present_TehsilId",
              "Present_DistrictId",
              "Present_StateId",
              "Present_PinCode",
              "Present_NearstBranchDistance_KM",
              "Present_TotalYearsOnAddress",
              "Present_AddressRentBuy",
              "Present_IsCommunicationAddress",
              "Permanent_Address",
              "Permanent_LandMark",
              "Permanent_TehsilId",
              "Permanent_DistrictId",
              "Permanent_StateId",
              "Permanent_PinCode",
              "Permanent_NearstBranchDistance_KM",
              "Permanent_TotalYearsOnAddress",
              "Permanent_AddressRentBuy",
              "Permanent_IsCommunicationAddress",
              "Aadhaar_No",
              "PAN_No",
              "VoterId_No",
              "Electricity_KNo",
            ];

            this.BlankDataFilldata.push(x);

            this.dataSourceBlankDataFilldata = new MatTableDataSource(
              this.BlankDataFilldata
            );
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill All Mandatory Fields !!!",
              ...this.configSuccess,
            });
          }
        });

        const seen = new Set();
        if (this.BlankDataFilldata.length == 0) {
          this.BlankDataFilldata = [];
          this.ImportBulkCustomer.filter((item) => {
            if (item.Id != undefined && item.Id != "") {
              const Id_Noduplicate = seen.has(item.Id);
              seen.add(item.Id);
              if (Id_Noduplicate == true) {
                this.BlankDataFilldata.push(item);

                this.DuplicateData = true;

                item.Id_Noduplicate = true;
              } else {
                item.PAN_Noduplicate = false;
              }
            }
            if (item.Aadhaar_No != undefined && item.Aadhaar_No != "") {
              const Aadhaar_Noduplicate = seen.has(item.Aadhaar_No);
              seen.add(item.Aadhaar_No);
              if (Aadhaar_Noduplicate == true) {
                this.DuplicateData = true;
                this.BlankDataFilldata.push(item);
                item.Aadhaar_Noduplicate = true;
              } else {
                item.Aadhaar_Noduplicate = false;
              }
            }
            if (item.PAN_No != undefined && item.PAN_No != "") {
              const PAN_Noduplicate = seen.has(item.PAN_No);
              seen.add(item.PAN_No);
              if (PAN_Noduplicate == true) {
                this.BlankDataFilldata.push(item);

                this.DuplicateData = true;

                item.PAN_Noduplicate = true;
              } else {
                item.PAN_Noduplicate = false;
              }
            }
            if (item.VoterId_No != undefined && item.VoterId_No != "") {
              const VoterId_Noduplicate = seen.has(item.VoterId_No);
              seen.add(item.VoterId_No);
              if (VoterId_Noduplicate == true) {
                this.BlankDataFilldata.push(item);

                this.DuplicateData = true;
                item.VoterId_Noduplicate = true;
              } else {
                item.VoterId_Noduplicate = false;
              }
            }
          });
          //this.displayImportData = ["Id", "Customer_FirstName", "Customer_LastName", "Customer_Gender", "Customer_DOB", "Customer_PhoneNo", "Customer_WhatsAppNo", "Customer_Email", "Customer_Website", "Customer_Religion", "Customer_Cast", "Customer_MaritalStatus", "Customer_Relation", "Customer_Relation_FirstName", "Customer_Relation_LastName", "Present_Address", "Present_LandMark", "Present_TehsilId", "Present_DistrictId", "Present_StateId", "Present_PinCode", "Present_NearstBranchDistance_KM", "Present_TotalYearsOnAddress", "Present_AddressRentBuy", "Present_IsCommunicationAddress", "Permanent_Address", "Permanent_LandMark", "Permanent_TehsilId", "Permanent_DistrictId", "Permanent_StateId", "Permanent_PinCode", "Permanent_NearstBranchDistance_KM", "Permanent_TotalYearsOnAddress", "Permanent_AddressRentBuy", "Permanent_IsCommunicationAddress", "Aadhaar_No", "PAN_No", "VoterId_No", "Electricity_KNo"];
          this.displayImportData = [
            "Id",
            "Customer_FirstName",
            "Customer_LastName",
            "Customer_Gender",
            "Customer_DOB_Year",
            "Customer_DOB_Month",
            "Customer_DOB_Day",
            "Customer_PhoneNo",
            "Customer_WhatsAppNo",
            "Customer_Email",
            "Customer_Website",
            "Customer_Religion",
            "Customer_Cast",
            "Customer_MaritalStatus",
            "Customer_Relation",
            "Customer_Relation_FirstName",
            "Customer_Relation_LastName",
            "Present_Address",
            "Present_LandMark",
            "Present_TehsilId",
            "Present_DistrictId",
            "Present_StateId",
            "Present_PinCode",
            "Present_NearstBranchDistance_KM",
            "Present_TotalYearsOnAddress",
            "Present_AddressRentBuy",
            "Present_IsCommunicationAddress",
            "Permanent_Address",
            "Permanent_LandMark",
            "Permanent_TehsilId",
            "Permanent_DistrictId",
            "Permanent_StateId",
            "Permanent_PinCode",
            "Permanent_NearstBranchDistance_KM",
            "Permanent_TotalYearsOnAddress",
            "Permanent_AddressRentBuy",
            "Permanent_IsCommunicationAddress",
            "Aadhaar_No",
            "PAN_No",
            "VoterId_No",
            "Electricity_KNo",
          ];

          if (this.DuplicateData == true) {
            this.dataSourceDuplicate = new MatTableDataSource(
              this.BlankDataFilldata.slice().sort((a, b) => a.Id - b.Id)
            );
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Duplicate data is not allowed in Id,PAN No,Aadhaar_No and VoterId No fields !!!",
              ...this.configSuccess,
            });
          } else if (
            this.dataSourceDuplicate == null &&
            this.DuplicateData == false
          ) {
            this.BlankDataFilldata = [];
            this.ImportBulkCustomer.filter((item) => {
              if (
                item.Customer_PhoneNo != "" &&
                item.Customer_PhoneNo != undefined
              )
                this.NumberValidation(item.Customer_PhoneNo, 10, item);
              if (
                item.Customer_WhatsAppNo != "" &&
                item.Customer_WhatsAppNo != undefined
              )
                this.NumberValidation(item.Customer_WhatsAppNo, 10, item);
              if (item.Aadhaar_No != "" && item.Aadhaar_No != undefined)
                this.NumberValidation(item.Aadhaar_No, 12, item);
              if (item.PAN_No != "" && item.PAN_No != undefined)
                this.isPanNumberValid(item.PAN_No, item);
              if (
                item.Customer_Gender != "" &&
                item.Customer_Gender != undefined
              )
                this.isGenderValid(item.Customer_Gender, item);
              if (
                item.Customer_DOB_Year != "" &&
                item.Customer_DOB_Year != undefined
              )
                this.NumberValidation(item.Customer_DOB_Year, 4, item);
              if (
                item.Customer_DOB_Year != "" &&
                item.Customer_DOB_Year != undefined
              )
                this.isGreaterThan(item.Customer_DOB_Year, item);
              if (
                item.Customer_DOB_Month != "" &&
                item.Customer_DOB_Month != undefined
              )
                this.isGreaterThan(item.Customer_DOB_Month, item);
              if (
                item.Customer_DOB_Day != "" &&
                item.Customer_DOB_Day != undefined
              )
                this.isGreaterThan(item.Customer_DOB_Day, item);
              if (
                item.Present_PinCode != "" &&
                item.Present_PinCode != undefined
              )
                this.NumberValidation(item.Present_PinCode, 6, item);
              if (
                item.Permanent_PinCode != "" &&
                item.Permanent_PinCode != undefined
              )
                this.NumberValidation(item.Permanent_PinCode, 6, item);
            });
            console.log("this.InValidData ", this.InValidData);
            if (this.InValidData == true) {
              console.log("this.BlankDataFilldata", this.BlankDataFilldata);
              this.displayImportData = [
                "Id",
                "Customer_FirstName",
                "Customer_LastName",
                "Customer_Gender",
                "Customer_DOB_Year",
                "Customer_DOB_Month",
                "Customer_DOB_Day",
                "Customer_PhoneNo",
                "Customer_WhatsAppNo",
                "Customer_Email",
                "Customer_Website",
                "Customer_Religion",
                "Customer_Cast",
                "Customer_MaritalStatus",
                "Customer_Relation",
                "Customer_Relation_FirstName",
                "Customer_Relation_LastName",
                "Present_Address",
                "Present_LandMark",
                "Present_TehsilId",
                "Present_DistrictId",
                "Present_StateId",
                "Present_PinCode",
                "Present_NearstBranchDistance_KM",
                "Present_TotalYearsOnAddress",
                "Present_AddressRentBuy",
                "Present_IsCommunicationAddress",
                "Permanent_Address",
                "Permanent_LandMark",
                "Permanent_TehsilId",
                "Permanent_DistrictId",
                "Permanent_StateId",
                "Permanent_PinCode",
                "Permanent_NearstBranchDistance_KM",
                "Permanent_TotalYearsOnAddress",
                "Permanent_AddressRentBuy",
                "Permanent_IsCommunicationAddress",
                "Aadhaar_No",
                "PAN_No",
                "VoterId_No",
                "Electricity_KNo",
              ];

              this.dataSourceNotValiddata = new MatTableDataSource(
                this.BlankDataFilldata.slice().sort((a, b) => a.Id - b.Id)
              );
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Valid Data !!!",
                ...this.configSuccess,
              });
            } else {
              this.displayImportData = [
                "Id",
                "Customer_FirstName",
                "Customer_LastName",
                "Customer_Gender",
                "Customer_DOB",
                "Customer_PhoneNo",
                "Customer_WhatsAppNo",
                "Customer_Email",
                "Customer_Website",
                "Customer_Religion",
                "Customer_Cast",
                "Customer_MaritalStatus",
                "Customer_Relation",
                "Customer_Relation_FirstName",
                "Customer_Relation_LastName",
                "Present_Address",
                "Present_LandMark",
                "Present_TehsilId",
                "Present_DistrictId",
                "Present_StateId",
                "Present_PinCode",
                "Present_NearstBranchDistance_KM",
                "Present_TotalYearsOnAddress",
                "Present_AddressRentBuy",
                "Present_IsCommunicationAddress",
                "Permanent_Address",
                "Permanent_LandMark",
                "Permanent_TehsilId",
                "Permanent_DistrictId",
                "Permanent_StateId",
                "Permanent_PinCode",
                "Permanent_NearstBranchDistance_KM",
                "Permanent_TotalYearsOnAddress",
                "Permanent_AddressRentBuy",
                "Permanent_IsCommunicationAddress",
                "Aadhaar_No",
                "PAN_No",
                "VoterId_No",
                "Electricity_KNo",
              ];

              this.BlankDataFilldata = [];
              //in check database
              this.losService
                .LMS_CustomerMatch_Import({
                  JSON: JSON.stringify(this.ImportBulkCustomer),
                })
                .subscribe((res: any) => {
                  if (res.length > 0) {
                    var _find = res.find((obj: any) => {
                      return obj.NotMach != "";
                    });

                    if (_find) {
                      this.InValidDataInDataBase = 1;
                      this.displayImportData = [
                        "Id",
                        "Customer_FirstName",
                        "Customer_LastName",
                        "Customer_Gender",
                        "Customer_DOB",
                        "Customer_PhoneNo",
                        "Customer_WhatsAppNo",
                        "Customer_Email",
                        "Customer_Website",
                        "Customer_Religion",
                        "Customer_Cast",
                        "Customer_MaritalStatus",
                        "Customer_Relation",
                        "Customer_Relation_FirstName",
                        "Customer_Relation_LastName",
                        "Present_Address",
                        "Present_LandMark",
                        "Present_TehsilId",
                        "Present_DistrictId",
                        "Present_StateId",
                        "Present_PinCode",
                        "Present_NearstBranchDistance_KM",
                        "Present_TotalYearsOnAddress",
                        "Present_AddressRentBuy",
                        "Present_IsCommunicationAddress",
                        "Permanent_Address",
                        "Permanent_LandMark",
                        "Permanent_TehsilId",
                        "Permanent_DistrictId",
                        "Permanent_StateId",
                        "Permanent_PinCode",
                        "Permanent_NearstBranchDistance_KM",
                        "Permanent_TotalYearsOnAddress",
                        "Permanent_AddressRentBuy",
                        "Permanent_IsCommunicationAddress",
                        "Aadhaar_No",
                        "PAN_No",
                        "VoterId_No",
                        "Electricity_KNo",
                        "NotMach",
                      ];
                      var data = JSON.parse(JSON.stringify(res));
                      data.filter((item) => {
                        if (item.NotMach != "")
                          this.BlankDataFilldata.push(item);
                      });
                      this.dataSourceNotValiddata = new MatTableDataSource(
                        this.BlankDataFilldata.slice().sort(
                          (a, b) => a.Id - b.Id
                        )
                      );
                      this.showSpinner = false;
                      this.snackBar.openFromComponent(SnackbarComponent, {
                        data: "Some Fields are Duplicate in  DataBase.Kindly Check the Error field in the Table!!!",
                        ...this.configSuccess,
                      });
                    } else {
                      this.InValidDataInDataBase = 2;
                      this.displayImportData = [
                        "Id",
                        "Customer_FirstName",
                        "Customer_LastName",
                        "Customer_Gender",
                        "Customer_DOB",
                        "Customer_PhoneNo",
                        "Customer_WhatsAppNo",
                        "Customer_Email",
                        "Customer_Website",
                        "Customer_Religion",
                        "Customer_Cast",
                        "Customer_MaritalStatus",
                        "Customer_Relation",
                        "Customer_Relation_FirstName",
                        "Customer_Relation_LastName",
                        "Present_Address",
                        "Present_LandMark",
                        "Present_TehsilId",
                        "Present_DistrictId",
                        "Present_StateId",
                        "Present_PinCode",
                        "Present_NearstBranchDistance_KM",
                        "Present_TotalYearsOnAddress",
                        "Present_AddressRentBuy",
                        "Present_IsCommunicationAddress",
                        "Permanent_Address",
                        "Permanent_LandMark",
                        "Permanent_TehsilId",
                        "Permanent_DistrictId",
                        "Permanent_StateId",
                        "Permanent_PinCode",
                        "Permanent_NearstBranchDistance_KM",
                        "Permanent_TotalYearsOnAddress",
                        "Permanent_AddressRentBuy",
                        "Permanent_IsCommunicationAddress",
                        "Aadhaar_No",
                        "PAN_No",
                        "VoterId_No",
                        "Electricity_KNo",
                      ];
                      this.dataSourceNotValiddata = new MatTableDataSource(
                        JSON.parse(JSON.stringify(res))
                          .slice()
                          .sort((a, b) => a.Id - b.Id)
                      );
                      this.showSpinner = false;
                    }
                  }
                });

              console.log(
                "this.ImportBulkCustomer",
                JSON.stringify(this.ImportBulkCustomer)
              );
            }
          }
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Fill Customer Data!!!",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    };

    this.fileInput.nativeElement.value = "";
  }

  OnSaveCustomer() {
    this.showSpinner = true;
    this.losService
      .LMS_SaveCustomer_Import({
        JSON: JSON.stringify(this.ImportBulkCustomer),
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.showSpinner = false;
          this.Matchhdata = [];
          this.BlankDataFilldata = [];
          this.dataSourceBlankDataFilldata = null;
          this.dataSourceDuplicate = null;
          this.dataSourceNotValiddata = null;
          this.ImportBulkCustomer = null;
          this.DuplicateData = false;
          this.InValidData = false;
          this.InValidDataInDataBase = 0;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  NumberValidation(phoneNumber: string, digit: number, row): boolean {
    phoneNumber = phoneNumber + "";
    const numericRegex = /^[0-9]+$/;
    if (numericRegex.test(phoneNumber)) {
      if (
        phoneNumber != undefined &&
        phoneNumber.replace(/\D/g, "").length === digit
      ) {
        return true;
      } else {
        if (
          phoneNumber != undefined &&
          phoneNumber != "" &&
          phoneNumber.replace(/\D/g, "").length != digit
        ) {
          this.InValidData = true;
          if (this.InValidData == true && row != null) {
            this.BlankDataFilldata.push(row);
          }
        }
        return false;
      }
    }
    if (
      phoneNumber != undefined &&
      phoneNumber != "" &&
      !numericRegex.test(phoneNumber)
    ) {
      this.InValidData = true;
    }
    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }

    return false;
  }
  isGreaterThan(Number: number, row) {
    if (Number > 0) {
      return true;
    }
    this.InValidData = true;

    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }
    return false;
  }
  isPanNumberValid(panNumber: string, row): boolean {
    panNumber = panNumber + "";
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    if (
      panNumber != undefined &&
      panRegex.test(panNumber.toUpperCase()) == true
    ) {
      return true;
    }
    if (
      panNumber != undefined &&
      panNumber != "" &&
      panRegex.test(panNumber.toUpperCase()) == false
    ) {
      this.InValidData = true;
    }
    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }
    return false;
  }

  isGenderValid(gender: string, row): boolean {
    if (
      gender != undefined &&
      (gender.toUpperCase() == "M" || gender.toUpperCase() == "F")
    ) {
      return true;
    }
    this.InValidData = true;

    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }
    return false;
  }

  ExportLocationData() {
    this._MasterService.Get_Location_List().subscribe((result) => {
      if (result.length > 0) {
        //const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(result);
        //const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //this.saveAsExcelFile(excelBuffer, 'LocationMaster');
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result);
        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
          data,
          "Location Master" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  }
}
