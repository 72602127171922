<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="float-right">
    <mat-form-field [hidden]="!dataSource">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ....">
    </mat-form-field>

  </div>
  

  <div class="table-responsive">
    <mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter" [hiddenColumns]="[]">
      <ng-container matColumnDef="ApplicationId">
        <mat-header-cell mat-sort-header class="grid-header" style="max-width:50px" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
          {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Application No </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Application_No}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" style="max-width:150px" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:150px"> {{row.Customer}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="MobileNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Mobile No. </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.MobileNo}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Product </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Product}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Branch </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Branch}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Loan Amount </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">₹ {{row.LoanAmount.toFixed(2)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> CreateOn </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CreateOn}} </mat-cell>
      </ng-container>



      <ng-container matColumnDef="ComplotedOn" *ngIf="status=='Completed'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> ComplotedOn </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ComplotedOn}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header j-c-center" style="max-width:60px" *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width:60px">
          <i class="fa fa-arrow-circle-right" (click)="goToDetail(row)"
             style="cursor: pointer;color:green;font-size:16px;" aria-label="true"></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button class="btn font-size-12 button-btn" (click)="exportTable(exporter)">
          <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div>
</div>
