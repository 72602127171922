import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import { ToWords } from "to-words";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import * as moment from "moment";
import { map } from "rxjs/operators";
declare var $: any;

@Component({
  selector: "app-lms-dv-authorize-detail",
  templateUrl: "./lms-dv-authorize-detail.component.html",
  styleUrls: ["./lms-dv-authorize-detail.component.scss"],
})
export class LmsDvAuthorizeDetailComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  LoanId: any;
  VoucherId: any;
  dvDetail: any;
  Tenure_Name: any;
  stepArr: any;
  cEIModel: any = {};
  tab: any = "deduction";
  deductionList: any[] = [];
  receiptList: any[] = [];
  dataSource: any;
  displayedColumns: string[] = [
    "Id",
    "Type",
    "ApplicationNo",
    "ChagesHead",
    "Account",
    "TaxPercentage",
    "Amount",
    "CollectionOn",
    "CollectionBy",
  ];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  empBranchDropdown: any[] = [];
  totalCalculator: {
    totalCR: number;
    totalDR: number;
    totalCRTX: number;
    totalDRTX: number;
    totalCRInWord: string;
    totalDRInWord: string;
  };
  selectedSearchIndex: any;
  selectedData: any = {};

  PageId: any = null;

  authRevertStatus: any = "Authorized";
  voucherRemark: any = "";

  dataSourceCustomerDetail: any;
  displayedCustomerDetailColumns: string[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  CustomerTrackList: any;
  CustomerLoanList: any;
  resData: any;
  applicationId: any;
  flat_ROI: any;

  constructor(
    private route: ActivatedRoute,
    private _Route: Router,
    public snackBar: MatSnackBar,
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private router: Router,
    private _MasterService: MasterService,
    private _EncrDecrService: EncrDecrService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.VoucherId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("vid"))
      );
      this.PageId = decodeURIComponent(param.get("page"));

      this.LMS_Get_DV_Details();
      console.log("Loan Id DV", this.LoanId);
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next(
      "Disbursement Authorization Detail"
    );
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getChargeshead();
    //this.Get_Acc_Accounts_OtherThan_BankCash();
    //this.Get_EmployeeBranchForDropdown();
  }

  LMS_Get_DV_Details() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_DV_Details({ LoanAcId: this.LoanId, VoucherId: this.VoucherId })
      .subscribe((res: any) => {
        this.dvDetail = res.Item1[0];

        this.stepArr = res.Item4;
        this.authRevertStatus = this.dvDetail.VoucherStatus
          ? this.dvDetail.VoucherStatus == "Authorized" ||
            this.dvDetail.VoucherStatus == "Reverted"
            ? this.dvDetail.VoucherStatus
            : ""
          : "";
        this.voucherRemark = this.dvDetail.VoucherStatus
          ? this.dvDetail.VoucherStatus != "Pending"
            ? this.dvDetail.Remark
            : ""
          : "";
        this.deductionList = res.Item2;
        this.receiptList = res.Item3;
        this.dataSource = new MatTableDataSource(this.receiptList);
        this.showSpinner = false;
        if (
          this.dvDetail.EMI_Type == "Daily" ||
          this.dvDetail.EMI_Type == "Weekly" ||
          this.dvDetail.EMI_Type == "Fortnightly" ||
          this.dvDetail.EMI_Type == "ByWeekly" ||
          this.dvDetail.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
        this.initializeVoucher();
        this.deductionList.map((item) => {
          if (!item.IsTax) {
            this.voucherModel.Voucher_Detail.push({
              ...item,
              TaxSlab: [],
            });
          } else if (this.voucherModel.Voucher_Detail.length > 1) {
            this.voucherModel.Voucher_Detail[
              this.voucherModel.Voucher_Detail.length - 1
            ].TaxSlab.push(item);
          }
        });
        this.GetCustomerDetails();
        this.getTotalCalculation();
      });
  }

  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  onCancel() {
    this._Route.navigate(["/lms/dvauthorize"]);
  }

  Get_EmployeeBranchForDropdown() {
    this._MasterService
      .Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId })
      .subscribe((res: any) => {
        this.empBranchDropdown = res;
      });
  }

  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService
      .Get_Acc_Accounts_ForJVandContra({ IsJournal: 1 })
      .subscribe((res: any) => {
        this.accountHeadDropdown = res;
      });
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      Voucher_LoanId: this.dvDetail.LoanId,
      VoucherId: 0,
      Voucher_Type: "Disbursement",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "",
      Voucher_Date: moment(this.dvDetail.VoucherDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      Voucher_No: this.dvDetail.VoucherNo,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: this.dvDetail.Voucher_Narration,
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId,
    };
  }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  onChangeTax() {
    this.totalCalculator = null;
    this.getTotalCalculation();
  }

  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
      }
      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalCR = _totalCR + parseFloat(tobj.Amount) || 0;
          _totalCRTX = _totalCRTX + parseFloat(tobj.Amount) || 0;
        } else {
          _totalDR = _totalDR + parseFloat(tobj.Amount) || 0;
          _totalDRTX = _totalDRTX + parseFloat(tobj.Amount) || 0;
        }
      });
    });
    this.totalCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalDR)),
    };
  }

  closeAuthorizeDetail() {
    this._Route.navigate(["/lms/dvauthorize"]);
  }

  saveAuthorizeDetail() {
    let stepEMI: any[] = [];
    for (let i = 0; i < this.stepArr.length; i++) {
      for (let j = +this.stepArr[i].FromEMI; j <= +this.stepArr[i].ToEMI; j++) {
        if (+this.stepArr[i].EMIAmount >= 0) {
          stepEMI.push(this.stepArr[i].EMIAmount);
        }
      }
    }

    this.cEIModel = {
      Disbursement_Amt:
        Number(this.dvDetail.DisbursementAmount) +
        Number(this.dvDetail.NetFinance),
      No_Of_Inst: this.dvDetail.NoOfInstallment,
      Adv_Inst: this.dvDetail.Adv_Inst,
      EMI_Type: this.dvDetail.EMI_Type,
      IRR_CalculateBy: stepEMI.length > 0 ? "STEP_EMI" : "FLAT_EMI",
      EMI_Amt: this.dvDetail.EMIAmount,
      cashflow: stepEMI,
      DaysInYear: this.currentUser.DaysInYear,
    };
    if (this.dvDetail.EMI_Type == "SingleEMI") {
      let apiRequest = {
        DV_VoucherId: this.VoucherId,
        DV_Status: this.authRevertStatus,
        DV_Remark: this.voucherRemark,
        LoginUserId: this.currentUser.userId,
        IRR: this.dvDetail.IRR,
      };
      this.lmsService
        .LMS_Authorized_DV_Voucher(apiRequest)
        .subscribe((res: any) => {
          if (res && res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this._Route.navigate(["/lms/dvauthorize"]);
          } else if (res) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.lmsService
        .Disbursment_CalculateEMIIRR(this.cEIModel)
        .subscribe((res: any) => {
          this.showSpinner = false;
          var emiIrr = res[0];
          let apiRequest = {
            DV_VoucherId: this.VoucherId,
            DV_Status: this.authRevertStatus,
            DV_Remark: this.voucherRemark,
            LoginUserId: this.currentUser.userId,
            IRR: emiIrr.Disbursement_IRR,
          };
          this.lmsService
            .LMS_Authorized_DV_Voucher(apiRequest)
            .subscribe((res: any) => {
              if (res && res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this._Route.navigate(["/lms/dvauthorize"]);
              } else if (res) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
          setTimeout(() => {
            var elmntToView = document.getElementById("scrollto");
            elmntToView.scrollIntoView();
          }, 500);
        });
    }
  }
  Print() {
    this.router.navigate([
      `/lms/printdetail/${encodeURIComponent(this.LoanId)}`,
    ]);
  }
  Cancel() {
    this.router.navigate([`/lms/dvauthorize`]);
  }

  GetCustomerDetails() {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.dvDetail.LoanId })
      .subscribe((res: any) => {
        console.log("RES of get loan details api call:", res.Item1);
        this.flat_ROI = res?.Item1[0]?.Flat_Rate;
        console.log("this.ROI====", this.flat_ROI);
        this.displayedCustomerDetailColumns = [
          "CustomerId",
          "Customer",
          "CustomerType",
          "GenderAge",
          "PhoneNo",
          "Relation_With_Hirer",
          "ExistingCustomer",
        ];
        this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
      });
  }

  OnClickTrackLoan() {
    this.lmsService
      .Customer_Track({ Loan_Id: this.dvDetail.LoanId })
      .subscribe((res: any) => {
        this.CustomerLoanList = res;
        this.CustomerTrackList = res.filter(
          (item) => item.LoanId == this.dvDetail.LoanId
        );
        $("#LoanTrack").modal("show");
        $("#LoanTrack").css("z-index", "1050");
      });
  }

  CloseTrackLoan() {
    $("#LoanTrack").modal("hide");
  }

  ShowCAMReport() {
    $("#CAMReport").modal("show");
    $("#CAMReport").css("z-index", "1050");
  }
  HideCAMReport() {
    $("#CAMReport").modal("hide");
  }
}
