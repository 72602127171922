<section class="main-content-wrapper">
  <!-- <div class="row m-0 mt-4" *ngIf="loanSummary">
    <div class="col-md-3">
      <h6 class="fs-12">Application No</h6><p class="lead fs-12">{{ loanSummary.ApplicationNo }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Branch</h6><p class="lead fs-12">{{ loanSummary.Branch }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Loan Product</h6><p class="lead fs-12">{{ loanSummary.Product }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Borrower Name</h6><p class="lead fs-12">{{ loanSummary.Customer }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Loan Amount </h6><p class="lead fs-12">₹{{ loanSummary.LoanAmount }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Tenure</h6><p class="lead fs-12">{{ loanSummary.Tenure }} Months</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Loan No</h6><p class="lead fs-12">{{ loanSummary.LoanNo }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Loan Date</h6><p class="lead fs-12">{{ loanSummary.LoanDate }}</p>
    </div>
  </div> -->
  <div *ngIf="loanSummary">
    <!-- <div class="row col-md-12 m-0 mt-0"> -->
      <div class="col-md-12 mb-1 alert loanSummary">
        <div class="row m-0 mt-0">
          <div class="col-md-3"><span class="fs-12"><B>Branch :</B> {{ loanSummary.Branch }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Borrower Name :</B> {{ loanSummary.Customer }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Relation :</B> {{ loanSummary.RelationName }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Product :</B> {{ loanSummary.Product }}</span></div>
        </div>
        <div class="row m-0 mt-0" *ngIf="Type == 'L'">
          <div class="col-md-3"><span class="fs-12"><B>Loan No :</B> {{ loanSummary.LoanNo }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span></div>
          <div class="col-md-3"><span class="fs-12"><B>Tenure :</B> {{ loanSummary.Tenure }} Months</span></div>
        </div>
      </div>
    <!-- </div> -->

    <div class="row col-md-12 m-0 mb-1 alert loanSummary" *ngIf="Type == 'A'">
      <div class="col-md-6 mb-1 alert loanSummary">
        <div class="row mt-0">
          <div class="col-md-12 text-center mb-1"><h1 class="fs-14 m-0">Partner Loan Details</h1></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan No :</B> {{ PLoanDetail ? PLoanDetail.PLoanAcNo : '' }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan Date :</B> {{ PLoanDetail ? PLoanDetail.Partner_Loan_Date : '' }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan Amount :</B> {{ PLoanDetail ? PLoanDetail.NetFinance : '' }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Expiry Date :</B> {{ PLoanDetail ? PLoanDetail.ExpiryDate : '' }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>EMI Amount :</B> {{ PLoanDetail ? PLoanDetail.EMIAmount : '' }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>No of Instl :</B> {{ PLoanDetail ? PLoanDetail.No_Of_Instl : '' }}</span></div>
        </div>
      </div>
      
      <div class="col-md-6 mb-1 alert loanSummary">
        <div class="row mt-0">
          <div class="col-md-12 text-center mb-1"><h1 class="fs-14 m-0">Customer Loan Details</h1></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan No :</B> {{ loanSummary.LoanNo }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>Expiry Date :</B> {{ loanSummary.ExpiryDate }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>EMI Amount :</B> {{ loanSummary.EMIAmount }}</span></div>
          <div class="col-md-6"><span class="fs-12"><B>No of Instl :</B> {{ loanSummary.No_Of_Instl }}</span></div>
        </div>
      </div>
    </div>
  </div>
   
  <mat-tab-group class="Loan_Layout" *ngIf="isGetDetail" [selectedIndex]="currentTab" (selectedTabChange)="onTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label><span>Loan Info</span></ng-template>
      <ng-template matTabContent>
        <app-partner-loan-info [PageType]="Type" [ApplicationId]="LoanId" [isNew]="isNew" [loanSummary]="loanSummary" (action)="onChildAction($event)">
        </app-partner-loan-info>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="lastFillTab<1">
      <ng-template mat-tab-label><span>Account Info</span></ng-template>
      <ng-template matTabContent>
        <app-partner-account-info [PageType]="Type" [loanSummary]="loanSummary" (action)="onChildAction($event)"></app-partner-account-info>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="lastFillTab<2">
      <ng-template mat-tab-label><span>Amortization Chart</span></ng-template>
      <ng-template matTabContent>
        <app-partner-amortization [PageType]="Type" [loanSummary]="loanSummary" (action)="onChildAction($event)"></app-partner-amortization>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="lastFillTab<3 || PLoan_ID == 0">
      <ng-template mat-tab-label><span>DV Detail</span></ng-template>
      <ng-template matTabContent>
        <app-partner-dv-detail [PageType]="Type" [loanSummary]="loanSummary" (action)="onChildAction($event)"></app-partner-dv-detail>
      </ng-template>
    </mat-tab>
    
    <mat-tab *ngIf="Type == 'A'">
      <ng-template mat-tab-label><span>Loan Statement</span></ng-template>
      <ng-template matTabContent>
        <app-patner-loan-statement [PageType]="Type" [loanSummary]="loanSummary" (action)="onChildAction($event)"></app-patner-loan-statement>
      </ng-template>
    </mat-tab>
  </mat-tab-group> 
</section>
