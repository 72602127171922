import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  AfterViewInit,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LosService } from "../../_LOS/services/los.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { LmsService } from "../../_Lms/services/lms.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { QuickLoanService } from "../services/quickLoan.service";

@Component({
  selector: "app-quickloan-loan-info",
  templateUrl: "./quickloan-loan-info.component.html",
  styleUrls: ["./quickloan-loan-info.component.scss"],
})
export class QuickloanLoanInfoComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  BranchesList: any[] = [];
  branchHeadData: any = [];
  ProductList: any[] = [];
  loanInfoModel: any = {};
  @Input() Revert_IsEdit: any;
  leadSourceDropdown: any = [];
  partnerDropdown: any = [];
  SalesExecutiveData: any = [];
  areaData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  subAreaData: any = [];
  InfoData: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;
  @Input() InquiryId: any;
  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  displayedDetailColumns: string[];

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() ProductId: any;
  dataSourceDetail: any = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.dataSourceDetail.sort = this.sort;
    }
  }
  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next(this.ProcessName);

    // console.log("this.Status", this.Status);
    if (!this.isNew) {
      this.IsEdit = true;
      this.IsSave = false;
      this.IsDisabled = true;

      setTimeout(() => {
        this.get_Save_LoanInfo();
      }, 300);
    } else {
      this.loanInfoModel.Branch_Id = "";
      this.loanInfoModel.LoanProduct_Id = "";
      this.loanInfoModel.SelectBranchHead = "";
      this.loanInfoModel.SelectSourceType = "";
      this.loanInfoModel.LeadSourceName = "";

      this.loanInfoModel.SelectSourceArea = "";
      this.loanInfoModel.SalesExecutive = "";
      this.loanInfoModel.CollectionExecutive = "";
      this.loanInfoModel.SelectArea = "";
      this.loanInfoModel.SelectSubarea = "";
      this.loanInfoModel.LoanPurpose = "";
      this.IsEdit = false;
      this.IsSave = true;
      this.IsDisabled = false;
      if (this.InquiryId) {
        this.losService
          .LOS_GetInquiryDetails({ InquiryId: this.InquiryId })
          .subscribe((res: any) => {
            if (res.length > 0) {
              this.loanInfoModel.LoanPurpose = res[0].Purpose;
            }
          });
      }
    }

    this.getBranchesList();
    this.getProductDropdown();
    this.getLeadSourceDropdown();

    if (this.loanSummary != undefined) this.getTATTableData();
  }

  getAppNo_AND_LoanNo() {
    this.LmsService.getAppNo_AND_LoanNo({
      Branch_Id: this.loanInfoModel.Branch_Id,
    }).subscribe((res: any) => {
      // console.log("res", res);
      if (res.length > 0) {
        this.loanInfoModel.ApplicationNo = res[0].ApplicationNo;
      }
    });
    this.loanInfoModel.SelectBranchHead = "";
    this.getBranchHead(this.loanInfoModel.Branch_Id);
    this.getAreaByBranch(this.loanInfoModel.Branch_Id);
    this.GetCollectionExecutive(
      this.loanInfoModel.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
    this.GetSalesExecutive(
      this.loanInfoModel.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
  }

  getBranchHead(value) {
    this.LmsService.Get_Lms_BranchHead_ByBranch({ Branch_Id: value }).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.branchHeadData = res;
          this.loanInfoModel.SelectBranchHead = this.branchHeadData[0].EmpId;
        }
      }
    );
  }

  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe(
      (result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
        // console.log(this.BranchesList.length==1)
        if (this.BranchesList.length == 1) {
          this.loanInfoModel.Branch_Id = this.BranchesList["0"]?.BranchId;
          this.getAppNo_AND_LoanNo();
        }
      }
    );
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result: any) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }

  getLeadSourceDropdown() {
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
    });
  }

  Get_PartnerForDropdown(isChange: boolean = true) {
    if (isChange) {
      this.loanInfoModel.LeadSourceName = "";
    }
    if (
      this.loanInfoModel.SelectSourceType == "Agent" ||
      this.loanInfoModel.SelectSourceType == "DSA" ||
      this.loanInfoModel.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.loanInfoModel.SelectSourceType,
          branchId: this.loanInfoModel.Branch_Id,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
    }

    if (this.loanInfoModel.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
    }
  }
  getAreaByBranch(value) {
    this.LmsService.Get_Lms_Area_ByBranch({ Branch_Id: value }).subscribe(
      (res: any) => {
        this.areaData = res;
      }
    );
  }

  GetCollectionExecutive(value, productID) {
    this.LmsService.Get_Lms_CollectionExecutive({
      Branch_Id: value,
      ProductId: productID,
    }).subscribe((response) => {
      this.CollectionExecutiveData = response;
    });
  }
  GetSalesExecutive(value, productID) {
    this.losService
      .getEmployeeDropdown({
        BranchId: value,
        ProcessId: 1,
        ProductId: productID,
      })
      .subscribe((res: any) => {
        this.SalesExecutiveData = res;
        //this.Get_PartnerForDropdown();
      });
  }

  changeArea(isChange: boolean = true) {
    if (isChange) {
      this.loanInfoModel.SelectSubarea = "";
    }
    this.LmsService.Get_Lms_SubArea_ByArea({
      Area_Id: Number(this.loanInfoModel.SelectArea),
    }).subscribe((res: any) => {
      this.subAreaData = res;
    });
  }

  SaveLoanInfo() {
    let Loan_Id = this.loanSummary ? this.loanSummary.LoanId : 0;
    // console.log("this.loanInfoModel", this.loanInfoModel);
    this._QuickLoanService
      .QuickLoan_Save_Application({
        BranchId: this.loanInfoModel.Branch_Id,
        PrductId: this.loanInfoModel.LoanProduct_Id,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
        LoanPurpose: this.loanInfoModel.LoanPurpose,
        Loan_Id: Loan_Id,
        CollectionExecutive: this.loanInfoModel.CollectionExecutive
          ? this.loanInfoModel.CollectionExecutive
          : 0,
        SelectSourceArea: this.loanInfoModel.SelectSourceArea
          ? this.loanInfoModel.SelectSourceArea
          : 0,
        SelectSubarea: this.loanInfoModel.SelectSubarea
          ? this.loanInfoModel.SelectSubarea
          : 0,
        SalesExecutive: this.loanInfoModel.SalesExecutive,
        SelectArea: this.loanInfoModel.SelectArea
          ? this.loanInfoModel.SelectArea
          : 0,
        LeadReference: this.loanInfoModel.LeadReference,
        LeadSourceName: this.loanInfoModel.LeadSourceName,
        SelectSourceType: this.loanInfoModel.SelectSourceType,
        SelectBranchHead: this.loanInfoModel.SelectBranchHead,
        inquiryId: this.InquiryId == "" ? 0 : this.InquiryId,
      })
      .subscribe((res) => {
        // console.log("QuickLoan_Save_Application", res);
        if (res[0].CODE > 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          let Processid = this._EncrDecrService.encrypt(
            encodeURIComponent(this.Processid)
          );
          let ProductId = this._EncrDecrService.encrypt(
            encodeURIComponent(this.loanInfoModel.LoanProduct_Id)
          );
          let Loan_Id = this._EncrDecrService.encrypt(
            encodeURIComponent(res[0].CODE)
          );
          this.QuickLoan_Save_PageProcess("Loan Info", res[0].CODE);
          this.action.emit("next");
          var Status = this.Status == null ? "P" : this.Status;
          var RevertedProcessId = this._EncrDecrService.encrypt(
            encodeURIComponent(0)
          );
          this._Route.navigate([
            `quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${Loan_Id}/${Status}/${RevertedProcessId}`,
          ]);
          //  this._Route.navigate([`/quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${Loan_Id}/${Status}`]);

          //this.action.emit("next");
          this.get_Save_LoanInfo();
          this.IsDisabled = true;
          this.IsEdit = true;
          this.IsUpdate = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  get_Save_LoanInfo() {
    // console.log("this.loanSummary 1245", this.loanSummary);
    this.LmsService.get_Saved_LoanInfo({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      // console.log("get_Saved_LoanInfo", res);
      this.loanInfoModel = res[0];
      this.loanInfoModel.Branch_Id = this.loanSummary.BranchId;
      this.loanInfoModel.ApplicationNo = this.loanSummary.ApplicationNo;
      this.loanInfoModel.LoanProduct_Id = this.loanSummary.ProductId;

      this.loanInfoModel.SelectBranchHead = res[0].BranchHead;
      this.loanInfoModel.SelectSourceType = res[0].SourceType;
      this.loanInfoModel.SelectSourceArea = res[0].SourceArea;
      this.loanInfoModel.SelectArea = res[0].Area;
      this.loanInfoModel.SelectSubarea = res[0].Subarea;

      this.getAreaByBranch(this.loanSummary.BranchId);
      this.getBranchHead(this.loanSummary.BranchId);
      this.GetCollectionExecutive(
        this.loanSummary.BranchId,
        this.loanSummary.ProductId
      );
      this.GetSalesExecutive(
        this.loanSummary.BranchId,
        this.loanSummary.ProductId
      );
      this.Get_PartnerForDropdown(false);
      this.changeArea(false);
    });
  }

  onEditLoanInfo() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  CheckCustomerLoanInfo() {
    if (this.loanSummary) {
      this.QuickLoan_Save_PageProcess("Loan Info", this.loanSummary.LoanId);
      this.action.emit("next");
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please fill all details.",
        ...this.configSuccess,
      });
    }
  }

  QuickLoan_Save_PageProcess(PageType, LoanId) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanInfoModel.LoanProduct_Id,
        Loan_Id: LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }

  //get TAT Table Data
  getTATTableData() {
    // console.log("loan Id"+this.loanSummary.LoanId)
    this._QuickLoanService
      .Quick_rpt_TAT_Report({ LoanId: this.loanSummary.LoanId })
      .subscribe((res) => {
        if (res["Item2"].length > 0) {
          this.displayedDetailColumns = [
            "ProcessId",
            "Process",
            "ProcessExecutive",
            "ProcessStatus",
            "AssignedOn",
            "CompletedOn",
            "TAT_Hr",
            "Remark",
            "LatLong",
          ];
          var TatReportsList = JSON.parse(JSON.stringify(res));
          this.dataSourceDetail = new MatTableDataSource(TatReportsList.Item2);
          this.dataSourceDetail.sort = this.sort;
        } else {
          this.dataSourceDetail = new MatTableDataSource([]);
          this.dataSourceDetail.sort = this.sort;
        }
      });
  }

  // onSelectMethods($event){
  //   console.log("Triggered")
  // }

  onChangeSalesExecutive(val: any) {
    if ((this.CollectionExecutiveData as []).find((x) => x["EmpId"] == val)) {
      this.loanInfoModel.CollectionExecutive = val;
    }
  }
}
