<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="mb-3">
  <div class="row m-0" style="display:none">
    <div class="col-md-3 p-2 box" routerLink="/lms-dashboard/lms-loan-entry">
      <!-- loan-info -->
      <div class="d-flex" style="border: 1px solid #DDDD;border-radius: 5px;">
        <div class="box-img">
          <img src="../../assets/images/app.png" alt="img" style="width: 100%;">
        </div>
        <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content" style="width: 80%;">
          <span> Loan Entry </span>
        </div>
      </div>
    </div>
    <div class="col-md-3 p-2 box" routerLink="/lms-dashboard/lms-nach-entry">
      <!-- Nach-info -->
      <div class="d-flex" style="border: 1px solid #DDDD;border-radius: 5px;">
        <div class="box-img">
          <img src="../../assets/images/app.png" alt="img" style="width: 100%;">
        </div>
        <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content" style="width: 80%;">
          <span> Nach Entry </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-0 col-md-12 p-0 mb-2">
  <div class="col-md-4 mb-2 my-2 bg-image hover-overlay ripple shadow-1-strong rounded" *ngFor="let item of dashboardData;let ind=index" (click)="getDetail(item.ShortName)">
   
      <div class="card "  style=" height: 62px; padding: 14px 2px 0px 6px;"  [style.background]="gradientColors[ind]">
        
        <!-- <div class="box-img">
          <img src="{{iconname}}{{item.IconImage}}" alt="img" style="width: 38px;height: 38px;">
        </div>
        <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content" style="width: 80%;">
            <span> {{item.IconName}} </span>
            <span> {{item.ItemCount}} </span>
        </div> -->
        <div style="color: #ffffff; display: flex; justify-content: space-between;" class="font-weight-bolder" >
          <span style="font-size: 20px;"> {{item.IconName}} </span>
          <span class="badge badge-dark " style="font-size: 18px;"> {{item.ItemCount}} </span>
         
        </div>
      </div>
     
  </div>
</div>

<div class="row m-0 col-md-12 p-0" *ngIf="dataSource">
  <div class="col-md-12">
    <h1 class="fs-12 m-0">Disbursment Details :-</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter" style="height: 243px; max-width: 100%; overflow: auto;">
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell mat-sort-header class="grid-header" style="max-width:40px" *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 40px;max-height:5px">
            {{ (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="max-width:40px"></mat-footer-cell>

        </ng-container>
        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Application No </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Application_No}} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width: 85px;">LoanAcNo</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell" style="max-width: 85px;"> {{row.LoanAcNo}} </mat-cell>
          <mat-footer-cell *matFooterCellDef style="max-width: 85px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CustumerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CustumerName}} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Product</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Product_Name}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Application_LoanAmount}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("Application_LoanAmount") }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="OpeningBalanceDR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>OP Bal(DR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.OpeningBalanceDR}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("OpeningBalanceDR") }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="OpeningBalanceCR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>OP Bal(CR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.OpeningBalanceCR}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("OpeningBalanceCR") }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeDR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>TR Bal(DR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.TranTypeDR > 0 ? row.TranTypeDR : ''}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("TranTypeDR") }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TranTypeCR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>TRBal(CR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.TranTypeCR > 0 ? row.TranTypeCR : ''}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("TranTypeCR") }}</mat-footer-cell>
        </ng-container>
        
        <ng-container matColumnDef="ClosingBalanceDR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CL Bal(DR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ClosingBalanceDR > 0 ? row.ClosingBalanceDR : ''}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("ClosingBalanceDR") }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ClosingBalanceCR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CL Bal(CR)</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ClosingBalanceCR > 0 ? row.ClosingBalanceCR : ''}} </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ totaltypewise("ClosingBalanceCR") }}</mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedColumns; sticky: true" style="background: #4dc3a3;"></mat-footer-row>

      </mat-table>
      <!-- <div class="row m-0 align-items-center" style="background: #fff;">
        <div class="col-md-2">
          <button class="btn btn-success font-size-12" (click)="exportTable(exporter)">
            <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
          </button>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
          <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
        </div>
      </div> -->

    </div>
  </div>
</div>
