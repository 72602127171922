import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";

@Component({
  selector: "app-case-type-info",
  templateUrl: "./case-type-info.component.html",
  styleUrls: ["./case-type-info.component.scss"],
})
export class CaseTypeInfoComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  dataSource: any;
  loading: boolean = false;

  displayedColumns: string[] = [
    "Id",
    "Case_Type_Name",
    "Type",
    "Short_Case_Type_Name",
    "Parent_Name",
    "Action",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @Input() CaseTypeSummary: any;
  @Input() index: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  SearchId: any = 0;
  SearchBy: any = "List";

  JsonData: any;
  CaseModel: any = [];
  ActData: any;
  SectionData: any;

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Case Type List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLegalCaseType();
  }

  GetLegalCaseType() {
    this._MasterService
      .Get_Legal_Case_Type({ Id: this.SearchId, SearchBy: this.SearchBy })
      .subscribe((res: any) => {
        this.JsonData = res;
        this.ActData = res.filter((item) => item.Type == "ACT");
        this.SectionData = res.filter((item) => item.Type == "Section");
      });
  }

  OnAddCaseType() {
    this.CaseModel = {
      Type: "",
      Short_Case_Type_Name: "",
      Case_Type_Name: "",
      ParentId: 0,
      ParentId1: 0,
      Id: 0,
      SearchBy: "Insert",
    };

    if (this.CaseTypeSummary) {
      this.CaseModel = {
        Type: this.CaseTypeSummary.Type,
        Short_Case_Type_Name: this.CaseTypeSummary.Short_Case_Type_Name,
        Case_Type_Name: this.CaseTypeSummary.Case_Type_Name,
        ParentId1: this.CaseTypeSummary.ParentId,
        Id: this.CaseTypeSummary.Id,
        SearchBy: "Update",
      };
      this.CaseModel.ParentId = this.CaseTypeSummary.ParentId;

      if (this.CaseTypeSummary.Type == "SubSection") {
        var data = this.JsonData.filter(
          (item) => item.Id == this.CaseTypeSummary.ParentId
        );
        this.CaseModel.ParentId = this.JsonData.filter(
          (item) => item.Id == data[0].ParentId
        )[0].Id;
      }
    }

    $(`#AddCaseType${this.index}`).modal("show");
    $(`#AddCaseType${this.index}`).css("z-index", "1050");
  }

  OnClose() {
    $(`#AddCaseType${this.index}`).modal("hide");
  }

  OnSaveCaseType() {
    let ParentId;
    ParentId =
      this.CaseModel.ParentId1 > 0
        ? this.CaseModel.ParentId1
        : this.CaseModel.ParentId;

    this._MasterService
      .Save_Legal_Case_Type({
        Type: this.CaseModel.Type,
        Case_Type_Name: this.CaseModel.Case_Type_Name,
        Short_Case_Type_Name: this.CaseModel.Short_Case_Type_Name,
        ParentId: ParentId,
        Id: this.CaseModel.Id,
        LoginUserId: this.currentUser.userId,
        SearchBy: this.CaseModel.SearchBy,
      })
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.OnClose();
          this.close.emit(true);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnClickSection() {
    let data;
    if (this.CaseModel.ParentId1 > 0) {
      data = this.JsonData.filter(
        (item) => item.Id == this.CaseModel.ParentId1
      );
      this.CaseModel.ParentId = this.JsonData.filter(
        (item) => item.Id == data[0].ParentId
      )[0].Id;
    }
  }
}
