import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { LosService } from '../../_LOS/services/los.service';


@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
  currentUser: any;
  Customer: any = {};
  showSpinner: boolean = false;
  @Input() index: any;
  @Input() CustomerType: any;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];
  Type: any = "0";
  CustomerList: any[] = [];
  
  selectedData: any = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  SelectType : any = '';
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = []; 
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private _MasterService: MasterService,
    private lmsService: LmsService,
    private losService: LosService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log("this.CustomerList", this.CustomerList);
    //this.LoanList();
    this.Customer.CloseCase = '';

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
  }
  onOpenViewDetailModal() {
    this.SelectType = '';
    if (this.CustomerType == "LOS") {
      this.Type = "1"
    }
    if (this.CustomerType == "LMS") {
      this.Type = "2"
    }
    this.dataSource = new MatTableDataSource([]);
    this.Customer = {};
    $(`#CustomerReceipt${this.index}`).modal("show");
    $(`#CustomerReceipt${this.index}`).css("z-index", "1050");
  }

  Get_Acc_Search_LoanNo() {
    this.showSpinner = true;
    this.displayedColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

    this._MasterService.Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
      //console.log(res);
      this.CustomerList = JSON.parse(JSON.stringify(res));
      if (this.Type != "0") {
        var type;
        if (this.Type == "1")
          type = "LOS";
        else
          type = "LMS";
        this.CustomerList = this.CustomerList.filter(item => item.Type == type);
      }
      this.dataSource = new MatTableDataSource(this.CustomerList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  selectSearchData(data: any) {
    if (data.LoanAcNo != '' && data.LoanAcNo != null)
      this.selectedData.CaseNo = data.LoanAcNo;
    else
      this.selectedData.CaseNo = data.Application_No;

    this.selectedData.Customer = data.CustomertName;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.BranchId = data.BranchId;
    this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.Is_LOSAppliaction = data.Type == 'LOS' ? '1' : '0';
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.selectedData.GST_No = data.GST_No;
    this.selectedData.StateId = data.StateId;
    this.selectedData.Branch_StateId = data.Branch_StateId;
    this.selectedData.LoanId = data.LoanId;
    this.OnCloseSearch();
  }

  OnCloseSearch() {
    $(`#CustomerReceipt${this.index}`).modal("hide");
    this.close.emit(this.selectedData);
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    })
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    })
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    })
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0}).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    })
  }

  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId : '',
      Area : '',
      SubArea : '',
      SalesEx : '',
      CollectionEx : '',
      LoanAccountingCatId : '',
    };
  }
}
