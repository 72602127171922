<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 formborder d-flex align-items-center justify-content-between">
  <div class="col-md-3 p-1">
    <select
      name="SelectStatus"
      id="SelectStatus"
      class="form-control input-text-css"
      (change)="GetDeviationApprovalList()"
      [(ngModel)]="SelectStatus"
    >
      <option value="Pending">Pending</option>
      <option value="Completed">Completed</option>
    </select>
  </div>
  <div class="col-md-6 p-1"></div>
  <div class="col-md-3 p-1 text-right">
    <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
  </div>
</div>

<div class="mt-3">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[displayedColumns.indexOf('Assign')]"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header mw50"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell mw50"
            >{{ i + 1 }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAmount
          }}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="CreatedBy">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Created By</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.CreatedBy }}</mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="ApprovedBy">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Approved By</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ApprovedBy }}</mat-cell>
                </ng-container> -->

        <ng-container matColumnDef="Deviation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >&nbsp;</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <span class="badge_success" *ngIf="row.Approved_Deviation">{{
              row.Approved_Deviation
            }}</span
            >&nbsp;&nbsp;
            <span class="badge_warning" *ngIf="row.Pending_Deviation">{{
              row.Pending_Deviation
            }}</span
            >&nbsp;&nbsp;
            <span class="badge_danger" *ngIf="row.Rejected_Deviation">{{
              row.Rejected_Deviation
            }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 60px"
            >Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 60px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              (click)="goToDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="quickloandeviationlist"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Approved Deviation
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Approved_Deviation }}</td>
  </tr>
</table>
