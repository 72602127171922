//#region < Imports Services and components >
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { DialogService } from '../Shared/dialog.service'
import { userlayoutService } from '../_LOS/_layout/Services/app.userlayout.Service';
declare var $: any;
import { DataSharingService } from '../AuthGuard/DataSharingService';
import { ReportsService } from "../_Reports/services/reports.service";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTheme,
  ApexTitleSubtitle,
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};
export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-indexpage',
  templateUrl: './indexpage.component.html',
  styleUrls: ['./indexpage.component.scss']
})


export class IndexpageComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions1: Partial<ChartOptions1>;

  username = "";
  private data: any;
  token: any;
  ConfigurationAllow: any;
  LOSAllow: any;
  LMSAllow: any;
  REPORTAllow: any;
  ACCOUNTINGAllow: any;
  PORTFOLIOAllow: any;
  DEPOSITAllow: any;
  QUICKLOANAllow: any;
  constructor(private _Route: Router, private reportsService: ReportsService, public snackBar: MatSnackBar, private http: HttpClient,private dataSharingService:DataSharingService,
    private dialog: DialogService, private _userlayoutservice: userlayoutService) {
    
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    //this.token = this.data.token;
    //console.log("data : ",this.data);
    this.username = this.data.username;
    this.ConfigurationAllow = this.data.ConfigurationAllow;
    this.LOSAllow = this.data.LOSAllow;
    this.LMSAllow = this.data.LMSAllow;

    this.REPORTAllow = this.data.ReportsAllow;
    this.ACCOUNTINGAllow = this.data.AccountingAllow;
    this.PORTFOLIOAllow = this.data.PortfolioAllow;
    this.DEPOSITAllow = this.data.DepositAllow;
    this.QUICKLOANAllow = this.data.QuickLoanAllow;
    //this._userlayoutservice.getNewMenuList(this.data.userId);
  }

  OnConfigClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Configuration' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('Configuration',res);
      this._Route.navigate(['/New/Dashboard']);
    });
  }

  OnLOSClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'LOS' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('LOS',res);
      this._Route.navigate(['/LOS/Dashboard']);
    });
  }
  OnLMSClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'LMS' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('LMS',res);
      this._Route.navigate(['/lms-dashboard/Home']);
    });
  }
  OnQuickLoanClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'QuickLoan' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('QuickLoan', res);
      this._Route.navigate(['/quickloan-dashboard/Home']);
    });
  }
  OnAccountingClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Accounting' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('Accounting',res);
      this._Route.navigate(['/lms-accounting/Home']);
    });
  }
  OnReportsClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Reports' }).subscribe((res: any) => {
      this.dataSharingService.setMenuItem('Reports',res);
      this._Route.navigate(['/reporting/Home']);
    });
  }

  OnpPartnerClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Portfolio' }).subscribe((res: any) => {
      //console.log(res);
      this.dataSharingService.setMenuItem('Portfolio',res);
      this._Route.navigate(['/partner-dashboard/Home']);
    });
  }

  OnDepositClick() {
    this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Deposit' }).subscribe((res: any) => {
      //console.log(res);
      this.dataSharingService.setMenuItem('Deposit', res);
      this._Route.navigate(['/deposit-dashboard/Home']);
    });
  }

  ngOnInit() {
    this.reportsService.Report_ProductWiseBusiness({ From: '2022-03-01', To: '2023-03-31', LoginUserId: this.data.userId }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        var TotalBusiness = res.map(function (el) {
          return Number(el.TotalBusiness);
        });
        var Product_Name = res.map(function (el) {
          return el.Product_Name;
        });
        //this.chartOptions = {
        //  series: [
        //    {
        //      name: "Business",
        //      data: TotalBusiness
        //    }
        //  ],
        //  chart: {
        //    height: 300,
        //    type: "bar"
        //  },
        //  plotOptions: {
        //    bar: {
        //      dataLabels: {
        //        position: "top" // top, center, bottom
        //      }
        //    }
        //  },
        //  dataLabels: {
        //    enabled: true,
        //    formatter: function (val) {
        //      return val + "";
        //    },
        //    offsetY: -20,
        //    style: {
        //      fontSize: "12px",
        //      colors: ["#304758"]
        //    }
        //  },

        //  xaxis: {
        //    categories: Product_Name,
        //    position: "top",
        //    labels: {
        //      offsetY: -18
        //    },
        //    axisBorder: {
        //      show: false
        //    },
        //    axisTicks: {
        //      show: false
        //    },
        //    crosshairs: {
        //      fill: {
        //        type: "gradient",
        //        gradient: {
        //          colorFrom: "#D8E3F0",
        //          colorTo: "#BED1E6",
        //          stops: [0, 100],
        //          opacityFrom: 0.4,
        //          opacityTo: 0.5
        //        }
        //      }
        //    },
        //    tooltip: {
        //      enabled: true,
        //      offsetY: -35
        //    }
        //  },
        //  fill: {
        //    type: "gradient",
        //    gradient: {
        //      shade: "light",
        //      type: "horizontal",
        //      shadeIntensity: 0.25,
        //      gradientToColors: undefined,
        //      inverseColors: true,
        //      opacityFrom: 1,
        //      opacityTo: 1,
        //      stops: [50, 0, 100, 100]
        //    }
        //  },
        //  yaxis: {
        //    axisBorder: {
        //      show: false
        //    },
        //    axisTicks: {
        //      show: false
        //    },
        //    labels: {
        //      show: false,
        //      formatter: function (val) {
        //        return val + "%";
        //      }
        //    }
        //  },
        //  title: {
        //    text: "Product Wise Business",
        //    floating: false,
        //    offsetY: 320,
        //    align: "center",
        //    style: {
        //      color: "#444"
        //    }
        //  }
        //};

        console.log("", TotalBusiness);
        this.chartOptions = {
          series: TotalBusiness,
          chart: {
            width: 530,
            type: "pie"
          },
          title: {
            text: "Product Wise Current Year Business"
          },
          labels: Product_Name,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };


        this.chartOptions1 = {
          series: [
            {
              name: "basic",
              data: TotalBusiness
            }
          ] 
          ,
          chart: {
            type: "bar",
            height: 329
          },
          plotOptions: {
            bar: {
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: 
              Product_Name
            
          }
        };
      }
    });
  }


}
