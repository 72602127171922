import { Injectable } from "@angular/core";
import { MatConfirmDialogComponent } from "../mat-confirm-dialog/mat-confirm-dialog.component";
import { MatDialogComponent } from "../mat-dialog/mat-dialog.component";

import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openConfirmDialog(msg) {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: "auto",
      panelClass: "confirm-dialog-container",
      disableClose: true,
      data: {
        message: msg,
      },
    });
  }

  openDialog(msg1) {
    return this.dialog.open(MatDialogComponent, {
      width: "390px",
      panelClass: "confirm-dialog-container",
      disableClose: true,
      data: {
        message1: msg1,
      },
    });
  }
}
