<div class="card-container" style="padding:0;height:100%">

    <div class="title-head"
        style="border-bottom: 1px solid black;background-color: #4dc3a3;border-radius: 9px 9px 0 0 ;">
        <h1 class="main-tit" style="line-height: 2.2;margin:0;text-align: center;">CRC Details</h1>
    </div>
    <div style="min-height: 100%; line-height: 100%;" class="text-center"  *ngIf="!crcData">
        No Information Available!!
    </div>
    <div class="ctn-data" style="margin:10px" *ngIf="crcData">

        <div class="Main-content" style="display: flex;flex-direction: column;">
            <div class="main-cont">
                <h1 class="Text-Head" >Customer Id:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_CustomerId"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Value:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["Value"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Loan Type:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_LoanType"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Credit Vision Score:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_CreditVisionScore"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Personal Loan Score:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_PersonalLoanScore"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Total Accounts:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_TotalAccounts"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Overdue Accounts:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_OverdueAccounts"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Overdue Amount:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_OverdueAmount"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Current Balance:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_CurrentBalanceAmount"]}}
                </div>
            </div>
            <div class="main-cont">
                <h1 class="Text-Head" >Last Enquiry Date:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_LastEnquiryDate"]| date: 'dd/MM/yyyy'}}
                </div>
            </div>
            <!-- <div class="main-cont">
            <h1 class="Text-Head" >Recommendation:</h1>
            <div class="text-content" style="overflow-wrap: break-word; display: inline;">
                {{crcData["CRC_Recommendation"]}}
            </div>
        </div> -->
            <div class="main-cont">
                <h1 class="Text-Head" >Remarks:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    {{crcData["CRC_Remarks"]}}
                </div>
            </div>
            <div class="main-cont">
              <h1 class="Text-Head">Attachment:</h1>
              <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                <a target="_blank" *ngIf="crcData.isView != 1" href="{{crcAttachement}}/{{crcData.CRC_ReportName}}"> <i class="fa fa-paperclip" aria-hidden="true"></i></a>

                <a *ngIf="crcData.isView == 1"
                   (click)="getCRCThirdDetail()">
                  <i class="fa fa-link"
                     style="font-size: large; cursor: pointer"></i>
                </a>
              </div>

            </div>
            <div class="main-cont" *ngIf="!!crcData.CRC_ReportName1">
                <h1 class="Text-Head" >Attachment:</h1>
                <div class="text-content ml-4" style="overflow-wrap: break-word; display: inline;">
                    <a target="_blank" href="{{crcAttachement}}/{{crcData.CRC_ReportName1}}"> <i class="fa fa-paperclip" aria-hidden="true"></i></a>
                </div>
            </div>


        </div>
    </div>
</div>



<div class="modal fade in"
     id="cibilReportmain{{ index }}"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cibil Report
        </h6>

        <button type="button"
                (click)="onCloseCibilmainReport()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body" style="font-size: 10px">
        <ng-container *ngIf="cibilResponseMain">
          <app-quickloan-cibil-report [cibilResponse]="cibilResponseMain">
          </app-quickloan-cibil-report>
        </ng-container>
      </div>
    </div>
  </div>
</div>
