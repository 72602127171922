import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { LegalService } from "../services/Legal.service";
declare var $: any;

@Component({
  selector: "app-legal-cases-list",
  templateUrl: "./legal-cases-list.component.html",
  styleUrls: ["./legal-cases-list.component.scss"],
})
export class LegalCasesListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;

  dataSource: any;
  displayedColumns: string[] = [];
  JsonData: any[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  Status: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private _EncrDecrService: EncrDecrService,
    private router: Router,
    private losService: LosService,
    private _LegalService: LegalService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Legal Assign");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    // this.GetLegalCasesList();
  }

  GetLegalCasesList() {
    if (this.Status == "Pending") {
      this.displayedColumns = [
        "LegalId",
        "Created_On",
        "Notice_Type",
        "LoanAcNo",
        "Loan_Date",
        "Branch_Name",
        "Product_Name",
        "CustomerName",
        "DPD_Days",
        "Total_OverDUE_EMI__Amount",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "LegalId",
        "Case_Filed_By",
        "Date_Of_Case_Filing",
        "LoanAcNo",
        "Loan_Date",
        "Branch_Name",
        "Product_Name",
        "CustomerName",
        "DPD_Days",
        "Total_OverDUE_EMI__Amount",
        "Action",
      ];
    }
    this.showSpinner = true;
    this._LegalService
      .Legal_Get_LegalCasesList({
        LoginUserId: this.currentUser.userId,
        Status: this.Status,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
        this.showSpinner = false;
      });
  }

  GetData(data) {
    if (data == true) {
      this.GetLegalCasesList();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GoToDetails(row) {
    let LegalId = this._EncrDecrService.encrypt(
      encodeURIComponent(row.LegalId)
    );
    let LoanId = this._EncrDecrService.encrypt(encodeURIComponent(row.Loan_Id));
    this.router.navigate([
      `lms-dashboard/legalcases/details/${LegalId}/${LoanId}`,
    ]);
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "legal_cases_list"
    ) as HTMLTableElement;
    const worksheetName = "LEGAL CASE LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
