import { Component, OnInit } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { constantUrl } from "src/app/Shared/constantUrl";
declare var $: any;
class MyUploadAdapter {
  loader: any;
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (
        file //{
      ) =>
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          let _data: any = {};
          _data.DocName = file.name;
          reader.readAsDataURL(file);
          let $this = this;
          reader.onload = function () {
            _data.DocData = reader.result.toString().split(";base64,").pop();
            return $this.uploadFile(resolve, reject, _data);
          };
        })
    );
  }
  uploadFile(resolve, reject, data) {
    $.ajax({
      url: `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadTemplateDoc`,
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json;",
      success: (result, status, xhr) => {
        return resolve({
          default: constantUrl.SenctionTemplateImageUrl + result,
        });
      },
      error: (error, status, xhr) => {
        return reject(`Couldn't upload file: ${data.DocName}.`);
      },
    });
  }
}
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}

@Component({
  selector: "app-sanctionletter-template",
  templateUrl: "./sanctionletter-template.component.html",
  styleUrls: ["./sanctionletter-template.component.scss"],
})
export class SanctionletterTemplateComponent implements OnInit {
  currentUser: any;
  private _encdec;
  showSpinner: boolean = false;
  public Editor = ClassicEditor;
  templateData: any = "";
  loading: boolean = false;
  productDropdown: any[] = [];
  ProductId: any = "";
  getTemplate: any[] = [];
  JsonData: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  config: any;
  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private snackBar: MatSnackBar
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.getTemplateSideMenu();
    this.dataSharingService.LOSHeaderTitle.next("Section Letter Template");
    this.config = {
      image: {
        upload: {
          panel: {
            items: ["insertImageViaUrl"],
          },
        },
        styles: ["alignLeft", "alignCenter", "alignRight"],
        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "imageTextAlternative",
        ],
      },
      extraPlugins: [MyCustomUploadAdapterPlugin],
    };
    this.getProductDropdown();
  }
  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));

      this.showSpinner = false;
    });
  }
  geSenctionletter() {
    this._MasterService
      .Get_SenctionLetter({ ProductId: this.ProductId })
      .subscribe((res: any) => {
        this.templateData = res[0].TemplateData;
      });
  }
  onSavetemplate() {
    this.templateData = this.templateData.replaceAll(
      '<a class="lead fs-14 mb-2 p_data ng-star-inserted">',
      ""
    );
    this.templateData = this.templateData.replaceAll("</a>", "");
    this._MasterService
      .Save_SenctionLetter({
        ProductId: this.ProductId,
        TemplateData: this.templateData,
      })
      .subscribe((res: any) => {
        this.loading = false;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      });
  }

  getTemplateSideMenu() {
    this._MasterService.GetTemplate_SideMenu().subscribe((res: any) => {
      this.getTemplate = res;
      //  console.log("abc",res);
      this.JsonData = JSON.parse(JSON.stringify(res));
    });
  }
}
