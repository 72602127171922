<section class="main-content-wrapper">
  <div class="row m-0">
    <div class="col-md-12">
      <nav class="">
        <div class="nav nav-tabs" id="nav-tab">
          <a routerLink="/lms-accounting/voucher/voucher-receipt" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
             class="nav-item" id="nav-receipt-tab">RECEIPT</a>
          <a routerLink="/lms-accounting/voucher/voucher-payment" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
             class="nav-item" id="nav-payment-tab">PAYMENT</a>
          <a routerLink="/lms-accounting/voucher/voucher-journal" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
             class="nav-item" id="nav-journal-tab">JOURNAL</a>
          <a routerLink="/lms-accounting/voucher/voucher-contra" hidden routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:false}"
             class="nav-item" id="nav-contra-tab">CONTRA</a>
        </div>
      </nav>
      
      <router-outlet></router-outlet>
      <!-- <div class="row m-0">
        <div class="col-md-12">
          <router-outlet></router-outlet>
        </div>
      </div> -->
    </div>
  </div>
</section>
