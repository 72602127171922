import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from '@angular/router';
declare var $: any;


@Component({
    selector: 'app-mstemployeehierarchy',
    templateUrl: './mstemployeehierarchy.component.html',
    styleUrls: ['./mstemployeehierarchy.component.scss']
})
export class MstemployeehierarchyComponent implements OnInit {
    private data: any; showSpinner: boolean = false;
    employeeList: any[] = [];
    isVertical:boolean=true;
    constructor(private _Route: Router, private dataSharingService: DataSharingService, public snackBar: MatSnackBar, private _MasterService: MasterService) {
        this.data = JSON.parse(sessionStorage.getItem('currentUser'));
        //  sessionStorage.removeItem('getEmployeeID');
    }
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };

    ngOnInit() {
        this.dataSharingService.HeaderTitle.next('Employee Hierarchy');
        this.Get_EmployeeForTreeMap();
    }
    Get_EmployeeForTreeMap() {
        this.showSpinner = true;
        this._MasterService.Get_EmployeeForTreeMap({}).subscribe((res: any) => {
            this.showSpinner = false;
            this.employeeList = res.filter((obj: any) => obj.Emp_ManagerId == 0);
            this.makeTree(res, this.employeeList);
        })
    }

    makeTree(allData: any[], parentData: any[]) {
        for (let i = 0; i < parentData.length; i++) {
            let children = allData.filter((obj: any) => obj.Emp_ManagerId == parentData[i].EmpId);
            this.makeTree(allData, children);
            parentData[i].Children = children;
        }
    }

}
