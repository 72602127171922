import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { ReportsService } from "../../_Reports/services/reports.service";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-over-due-interest-detail",
  templateUrl: "./over-due-interest-detail.component.html",
  styleUrls: ["./over-due-interest-detail.component.scss"],
})
export class OverDueInterestDetailComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  showSpinner: boolean = false;
  VoucherDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  VoucherDateTo: any = new Date();
  FyearId: any;
  maxDate: any;
  minDate: any;
  FileCheckingList: any[] = [];
  dataSource: any;
  currentUser: any;
  selectedId: any;
  JsonData: any[] = [];
  displayedColumns: string[] = [
    "Loan_Id",
    "Application_No",
    "LoanAcNo",
    "Customer_Name",
    "Product_Name",
    "Branch_Name",
    "CollectionExecutive",
    "OD_Interest_Opening",
    "OD_Interest_Addition",
    "OD_Interest_Received",
    "OD_Interest_Closing",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,

    private dataSharingService: DataSharingService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Over Due Interest Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    // this.onOverDueInterest();
  }

  DateChange() {
    if (this.VoucherDateFrom != "" && this.VoucherDateTo != "") {
      if (this.VoucherDateFrom > this.VoucherDateTo) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Date To greater than Date From.",
          ...this.configSuccess,
        });
        this.VoucherDateFrom = "";
        this.VoucherDateTo = "";
      }
    }
  }

  onOverDueInterest() {
    this.showSpinner = true;

    this.reportsService
      .ReportODInterestDetail({
        LoginUserId: this.currentUser.userId,
        FromDate: this.VoucherDateFrom,
        ToDate: this.VoucherDateTo,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData = res;
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
        } else {
          this.showSpinner = false;
        }
      });
  }
  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }
  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "overDueInterest"
    ) as HTMLTableElement;
    const worksheetName = "OVERDUE INTEREST DETAIL"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
