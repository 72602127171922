import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-questions-natureofwork",
  templateUrl: "./questions-natureofwork.component.html",
  styleUrls: ["./questions-natureofwork.component.scss"],
})
export class QuestionsNatureOfWorkComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  FilterId: any = "";
  RequestModel: RequestModel = new RequestModel();
  SaveQuestionsRequestModel: any = {};
  EditQuestionsRequestModel: RequestModel = new RequestModel();
  DeleteQuestionsRequestModel: RequestModel = new RequestModel();

  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  QuestionsList: any = [];
  FilterList: any = [];

  QuestionTypeOptions: any = [];
  //add
  QueId: number = 0;
  HeaderText: string = "Add";
  Question: any = "";
  QuestionType: any = "";
  QuestionOptions: any = "";
  QuestionNature_Of_WorkIds: any = "";
  QuestionHindi: any = "";
  dialogreturn: any;
  QuestionFor: any = "";
  Higher: boolean = false;
  CoBrowser: boolean = false;
  Guarantor: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Customer Profile Questions");
    this._MasterService
      .GetCustomer_Profile_Master_For_Dropdown({
        Type: "Nature of work",
        P_ID: 0,
      })
      .subscribe((result) => {
        this.FilterList = JSON.parse(JSON.stringify(result));
        this.FilterList.map((item) => {
          item.isAcive = false;
        });
      });
    this.getQuestionsList();
  }

  getQuestionsList() {
    this.showSpinner = true;
    if (this.FilterId == "" ? 0 : parseInt(this.FilterId) > 0) {
      this.displayedColumns = [
        "Ques_Id",
        "Question",
        "Question_Hindi",
        "QuestionType",
        "Ques_Level",
        "Is_Mandatory",
      ];
    } else {
      this.displayedColumns = [
        "Ques_Id",
        "Question",
        "Question_Hindi",
        "QuestionType",
        "EditAction",
      ];
    }
    this._MasterService
      .Get_Customer_Profile_Questions_List({
        Nature_of_Work_Id: this.FilterId == "" ? 0 : parseInt(this.FilterId),
      })
      .subscribe((result) => {
        this.QuestionsList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.QuestionsList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  onChangeQuestionType() {
    this.QuestionTypeOptions = [{ Questions_Option: "" }];
  }

  onAddNewRow() {
    this.QuestionTypeOptions.push({ Questions_Option: "" });
  }

  onDeleteRow(selectedIndex) {
    this.QuestionTypeOptions.splice(selectedIndex, 1);
  }

  onDeleteQuestion(row) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Delete_Nature_Of_Work_Question({
            QueId: row.Ques_Id,
            LoginUserId: this.data.userId,
          })
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getQuestionsList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  onOpenQuestionModal() {
    $("#addNewQuestion").modal("show");
    $("#addNewQuestion").css("z-index", "1050");
  }

  OnClose() {
    this.QueId = 0;
    this.Question = "";
    this.QuestionHindi = "";
    this.QuestionType = "";
    this.QuestionOptions = "";
    this.QuestionNature_Of_WorkIds = "";
    this.QuestionTypeOptions = [];
    this.FilterList.map((item) => {
      item.isAcive = false;
    });
    this.HeaderText = "Add";
    $("#addNewQuestion").modal("hide");
  }

  onEditQuestion(row) {
    console.log("data row", row);
    this.HeaderText = "Edit";
    this.Question = row.Question;

    this.QueId = row.Ques_Id;
    this.QuestionHindi = row.Question_Hindi;
    this.QuestionType = row.QuestionType;
    this.QuestionOptions = row.QuestionOptions;

    var Ids = row.Nature_Of_WorkIds.split(",");
    console.log("Ids", Ids);
    this.QuestionTypeOptions = [];

    this.FilterList.map((item) => {
      Ids.map((item1) => {
        if (item.Id == item1) {
          item.isAcive = true;
        }
      });
    });

    this._MasterService
      .Get_Customer_Profile_Questions_Option({ QueId: this.QueId })
      .subscribe((response) => {
        if (response.length > 0) {
          console.log(response);
          response.map((item) => {
            this.QuestionTypeOptions.push({
              Questions_Option: item.Questions_Option,
            });
          });
        }
      });

    $("#addNewQuestion").modal("show");
    $("#addNewQuestion").css("z-index", "1050");
  }

  onSaveQuestionData() {
    this.QuestionOptions = Array.prototype.map
      .call(this.QuestionTypeOptions, function (item) {
        return item.Questions_Option;
      })
      .join("@@");

    let FilterProductData = this.FilterList.filter((a) => a.isAcive == true);
    this.QuestionNature_Of_WorkIds = Array.prototype.map
      .call(FilterProductData, function (item) {
        return item.Id;
      })
      .join(",");

    this.SaveQuestionsRequestModel.QueId = this.QueId;
    this.SaveQuestionsRequestModel.Question = this.Question;
    this.SaveQuestionsRequestModel.Question_Hindi = this.QuestionHindi;
    this.SaveQuestionsRequestModel.QuestionType = this.QuestionType;
    this.SaveQuestionsRequestModel.QuestionOptions = this.QuestionOptions;
    this.SaveQuestionsRequestModel.Nature_Of_WorkIds =
      this.QuestionNature_Of_WorkIds;
    this.SaveQuestionsRequestModel.LoginUserId = this.data.userId;

    console.log(
      "this.SaveQuestionsRequestModel",
      this.SaveQuestionsRequestModel
    );
    this._MasterService
      .Save_Customer_Profile_Questions(this.SaveQuestionsRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getQuestionsList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onSaveLevel() {
    let _detail: any[] = [];
    console.log("dataSource", this.dataSource.filteredData);
    this.dataSource.filteredData.forEach((obj: any) => {
      _detail.push(
        this.dataSharingService.cleanObject({
          Ref_Ques_Id: obj.Ques_Id,
          Nature_Of_Work_Id: this.FilterId,
          Ques_Level: obj.Ques_Level,
          Is_Mandatory: obj.Is_Mandatory,
          Created_By: this.data.userId,
        })
      );
    });

    let _finalData = {
      Data: _detail,
    };
    console.log("JSON.stringify(_finalData)", JSON.stringify(_finalData));
    this._MasterService
      .Save_Customer_Profile_Questions_Level({
        JSON: JSON.stringify(_finalData),
        Nature_Of_Work_Id: this.FilterId,
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.getQuestionsList();
        }
      });
  }

  SelectAll(event) {
    if (event.checked == true)
      this.FilterList.forEach((x) => {
        x.isAcive = true;
      });
    else {
      this.FilterList.forEach((x) => {
        x.isAcive = false;
      });
    }
  }
}
