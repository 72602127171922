import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-enach-au-bank",
  templateUrl: "./enach-au-bank.component.html",
  styleUrls: ["./enach-au-bank.component.scss"],
})
export class EnachAuBankComponent implements OnInit {
  showSpinner: boolean = false;
  LoanAcNo: any;
  Status: any = "Pending";

  LoanDateFrom: any;
  LoanDateTo: any;
  FileCheckingList: any;
  dataSourceEnachAU: any;
  displayedColumnsEnachAU: string[] = [];
  StatusDate: any = new Date();
  EMIFrom: any = 0;
  EMITo: any = 0;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  // configSuccess1: MatSnackBarConfig = {
  //   panelClass: "style-success",
  //   duration: 5000,
  //   horizontalPosition: "right",
  //   verticalPosition: "top",
  // };
  currentUser: any;
  EMIFromDB: any;
  NoChqs: any;
  Loan_Id: any;
  LastEMI: any;
  status: any;
  ChequeId: MatTableDataSource<unknown>;
  applicationDetail: any;
  Id: any;
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.LoanDateFrom = new Date();
    this.LoanDateTo = new Date();
    this.dataSharingService.HeaderTitle.next("AU ENACH Genartaion");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumnsEnachAU = [
      "Id",
      "LoanAcNo",
      "CustomerName",
      "Loan_Date",
      "PresentBankInCompanyName",
      "PresentBankInCompany",
      "Status",
      "Identifier",
      "checkButton",
      "Action",
    ];
  }

  onSearchENachAU() {
    // console.log("Search Button clicked ");

    this.showSpinner = true;
    this.dataSourceEnachAU = null;

    this.lmsService
      .LMS_Get_AU_NachStatus({
        fromDate: this.LoanDateFrom,
        toDate: this.LoanDateTo,
        LoanAcNo: this.LoanAcNo,
        status: this.Status,
      })
      .subscribe((res: any) => {
        console.log("res : ", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));

          this.ChequeId = res[0].chqId;
          console.log("CehqueID", this.ChequeId);

          this.dataSourceEnachAU = new MatTableDataSource(
            this.FileCheckingList
          );
          this.dataSourceEnachAU.sort = this.sort;
          this.dataSourceEnachAU.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.dataSourceEnachAU = null;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  onExport(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `AU-ENACH-EXPORT`,
      sheet: "AU-ENACH-EXPORT",
    });
  }

  onSearchPendingStatus(row) {
    // console.log("Pending Status");

    this.lmsService
      .AU_Mandate_RegistrationEnquirybyrefId({
        RequestId: "",
        ReferenceCode: row.Identifier,
        Channel: "",
        LoanId: row.Loan_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        this.Loan_Id = row.Loan_Id;
        if (res[0].CODE == 0) {
          console.log("Loan Id", this.Loan_Id);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          //$("#addAUEnachInfo").modal("show");
          //$("#addAUEnachInfo").css("z-index", "1050");
          // this.changeDate();
          this.onSearchENachAU();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onSearchENachAU();
        }
      });
  }
  OnClose() {
    $("#addAUEnachInfo").modal("hide");
  }

  changeDate() {
    this.EMIFrom = 0;
    this.EMITo = 0;
    this.lmsService
      .LMS_GET_NACH_EMI_DETAI({
        StatusDate: this.StatusDate,
        Loan_Id: this.Loan_Id,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].CODE == 0) {
            // console.log(res);
            this.EMIFrom = res[0].EMI;
            this.EMITo = res[res.length - 1].EMI;
            this.LastEMI = res[res.length - 1].EMI;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.EMIFrom = 0;
            this.EMITo = 0;
          }
        }
      });
  }

  EMIFromChange() {}

  goToDetail(row) {
    this.Loan_Id = row.Loan_Id;
    this.changeDate();

    this.status = row.status;
    this.Id = row.Id;
    this.lmsService
      .Lms_Get_Nach_Au_Detail({
        LoanAcNo: row.LoanAcNo,
        Id: row.chqId,
        SearchStatus: row.Status,
      })
      .subscribe((res: any) => {
        // console.log(res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        console.log("Data for Get Nach Info", this.FileCheckingList);
        if (res.length > 0) {
          this.applicationDetail = res[0];
          $("#addAUEnachInfo").modal("show");
          $("#addAUEnachInfo").css("z-index", "1050");
        }
        // this.StatusDB = res[0].status;
        // this.Loan_Id = res[0].Loan_Id;
        // this.status = res[0].status;
        // this.Int_Id = res[0].Int_Id;
        // this.chqId = data.chqId;
        // this.FailCount = res[0].FailCount;
        // this.NachAmount = res[0].NachAmount;
        // this.NachCode = res[0].NachCode;
        // this.EMIFrom = res[0].EMIFrom;
        // this.EMITo = res[0].EMITo;
        // this.CustomerId = res[0].CustomerId;
        // this.PdcType = res[0].PdcType;
        // this.ChqFrom = res[0].ChFrom;
        // this.ChqTo = res[0].ChTo;
        // this.NoChqs = res[0].NoChqs;
        // this.LastEMI = res[0].NoOfEMI;
        // this.PresentBankInCompany =
        //   res[0].PresentBankInCompany == null
        //     ? ""
        //     : res[0].PresentBankInCompany;
        // this.process_period = res[0].process_period;
        // this.PresentOnCompanyBank = new Date(
        //   moment(res[0].PresentOnCompanyBank).format("MM/DD/YYYY")
        // );
        // this.StatusDate = new Date(
        //   moment(res[0].StatusDate).format("MM/DD/YYYY")
        // );
        // this.Reason = res[0].Reason;
        // if (res[0].PresentOnCompanyBank == null)
        //   this.PresentOnCompanyBank = new Date();
        // if (res[0].StatusDate == null || res[0].StatusDate == "01 Jan 1900")
        //   this.StatusDate = new Date();
        // if (this.NachAmount == 0) this.NachAmount = "";
      });
  }

  // EMIFromChange(event: any) {
  //   if (Number(this.EMIFromDB) > Number(this.EMIFrom)) {
  //     this.snackBar.openFromComponent(SnackbarComponent, {
  //       data: "Please EMIFrom greater or equal than " + this.EMIFromDB + ".",
  //       ...this.configSuccess,
  //     });
  //     this.EMIFrom = this.EMIFromDB;
  //   } else if (
  //     this.status == "Pending For Generate" ||
  //     this.status == "Generated"
  //   ) {
  //     //var num = (Number(this.ChqTo) - Number(this.ChqFrom)) + 1;
  //     this.EMITo = Number(this.EMIFrom) + Number(this.NoChqs) - 1;
  //   }
  //   if (this.EMITo > this.LastEMI) {
  //     this.snackBar.openFromComponent(SnackbarComponent, {
  //       data: "Please EMITo less or equal than " + this.LastEMI + ".",
  //       ...this.configSuccess,
  //     });
  //     this.EMIFrom = 1;
  //     this.EMITo = Number(this.EMIFrom) + Number(this.NoChqs) - 1;
  //   }
  // }

  onSaveAUEnachInfo() {
    if (this.EMIFrom == 0 || this.EMITo == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI From or EMI To not equal to 0!!",
        ...this.configSuccess,
      });
    } else {
      this.lmsService
        .LMS_Save_AU_ENachInfo({
          EMIFrom: this.EMIFrom,
          EMITo: this.EMITo,
          Loan_Id: this.Loan_Id,
          LoginUserId: this.currentUser.userId,
          chqId: this.ChequeId,
          Id: this.Id,
          StatusDate: this.StatusDate,
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            console.log("Data Saved");
            this.OnClose();
            this.onSearchENachAU();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }

  onSaveAUNachDetail() {}

  updateToken() {
    this.showSpinner = true;
    this.lmsService.AU_Mandate_authTokenGenerate({}).subscribe((res: any) => {
      console.log("res", res);
      if (res.length > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
    this.showSpinner = false;
  }
}
