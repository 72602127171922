import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { constantUrl } from 'src/app/Shared/constantUrl';
import { MasterService } from '../../../../app/Shared/app.Masters.Service';
declare var $: any;
@Component({
  selector: 'app-crc-subcard-info',
  templateUrl: './crc-subcard-info.component.html',
  styleUrls: ['./crc-subcard-info.component.scss'],

})
export class CRCSubcardInfoComponent implements OnInit {
@Input() crcData:any
crcAttachement:any;
  @Input() applicationId: any
  @Input() index: any;
  private _MasterService;
  cibilResponseMain: any;
  constructor(private MasterService: MasterService) {
    this._MasterService = MasterService;
}

  ngOnInit(): void {
    this.crcAttachement= `${constantUrl.CrcApplicationDocUrl}${this.applicationId}`
  }

  getCRCThirdDetail() {
    this._MasterService.Get_CibilAPI_Request_Response({ Loan_Id: this.crcData.CRC_LoanId, CId: this.crcData["CRC_CustomerId"] })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.cibilResponseMain = JSON.parse(res[0].response);
         
          $(`#cibilReportmain${this.index}`).modal("show");
          $(`#cibilReportmain${this.index}`).css("z-index", "1090");
        }
      })
  }
  onCloseCibilmainReport() {
    $(`#cibilReportmain${this.index}`).modal("hide");
  }

}
