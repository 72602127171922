import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-consumer-durable-product-master",
  templateUrl: "./consumer-durable-product-master.component.html",
  styleUrls: ["./consumer-durable-product-master.component.scss"],
})
export class ConsumerDurableProductMasterComponent implements OnInit {
  manufactureId: any;
  public get dialog(): DialogService {
    return this._dialog;
  }
  public set dialog(value: DialogService) {
    this._dialog = value;
  }

  showSpinner: boolean = false;
  SelectCategory: any = "0";
  SelectCategoryModel: any = "0";
  SelectManufacture: any = "0";
  SelectManufactureModel: any = "0";
  categoryId: any[] = [];
  categoryName: [] = [];
  categoryList: any[] = [];
  manufactureList: any[] = [];
  dataSourceProduct: any;
  productName: any;

  displayedColumn: string[] = [
    "Id",
    "Category_Name",
    "Manufacture_Name",
    "Product_Name",
    "Action",
  ];
  currentUser: any;
  ButtonName: string = "Add";
  SaveUpdateButton: string = "Save";
  dataSource: MatTableDataSource<unknown>;
  productId: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private _dialog: DialogService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Consumer Durable Product");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Master_Consumer_Durable_Category();
    this.OnSearchConsumerProduct();
  }

  Get_Master_Consumer_Durable_Category() {
    this.categoryId = [];
    this.categoryName = [];

    this._MasterService
      .Get_Master_Consumer_Durable_Category()
      .subscribe((response) => {
        this.categoryList = JSON.parse(JSON.stringify(response));
      });
  }

  onChangeCategory(Category_Id) {
    this.manufactureList = [];

    this._MasterService
      .Get_Master_Consumer_Durable_Manufacture({
        Category_Id: Category_Id,
      })
      .subscribe((response) => {
        this.manufactureList = JSON.parse(JSON.stringify(response));
      });
  }

  OnSearchConsumerProduct() {
    console.log("Search Button Clicked");
    this.Get_Master_Consumer_Durable_Product();
  }

  Get_Master_Consumer_Durable_Product() {
    this.showSpinner = true;
    this._MasterService
      .Get_Master_Consumer_Durable_Product({
        Category_Id: this.SelectCategory,
        Manufacture_Id: this.SelectManufacture,
      })
      .subscribe((response: any) => {
        console.log("Get_Master_Consumer_Durable_Product", response);
        if (response.length > 0) {
          this.dataSourceProduct = new MatTableDataSource(
            JSON.parse(JSON.stringify(response))
          );
        } else {
          this.dataSourceProduct = null;
        }
      });
  }

  OnAddProduct() {
    this.productId = 0;
    this.productName = "";
    this.SaveUpdateButton = "Save";
    this.ButtonName = "Add";
    this.manufactureList = [];
    $("#editConsumerDurableProduct").modal("show");
    $("#editConsumerDurableProduct").css("z-index", "1050");
  }

  applyFilter(filterValue: string) {
    this.dataSourceProduct.filter = filterValue.trim().toLowerCase();
  }

  OnClose() {
    this.productId = 0;
    this.productName = "";
    this.SelectCategory = "0";
    this.SelectManufacture = "0";

    this.SelectCategoryModel = "0";
    this.SelectManufactureModel = "0";

    $("#editConsumerDurableProduct").modal("hidden");
  }

  OnSaveAndEditDurableProduct() {
    this._MasterService
      .Save_Master_Consumer_Durable_Product({
        Id: this.productId,
        Product_Name: this.productName,
        Manufacture_Id: this.SelectManufactureModel,
        Category_Id: this.SelectCategoryModel,
        LoginUserId: this.currentUser.userId,
        SearchType: this.SaveUpdateButton == "Save" ? "Insert" : "Update",
      })
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );

        if (res[0].CODE >= 0) {
          this.Get_Master_Consumer_Durable_Product();
          this.manufactureList = [];
          this.Get_Master_Consumer_Durable_Category();
          this.OnClose();

          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  editProductName(row) {
    this.onChangeCategory(row.Category_Id);
    this.ButtonName = "Edit";

    this.SaveUpdateButton = "Update";
    this.productId = row.Id;
    this.productName = row.Product_Name;
    this.SelectManufactureModel = row.Manufacture_Id;

    this.SelectCategoryModel = row.Category_Id;
    this.SelectManufactureModel = row.Manufacture_Id;

    $("#editConsumerDurableProduct").modal("show");
    $("#editConsumerDurableProduct").css("z-index", "1050");
  }

  deleteProductName(row) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._MasterService
            .Save_Master_Consumer_Durable_Product({
              Id: row.Id,
              Product_Name: row.productName,
              Manufacture_Id: row.SelectManufactureModel,
              Category_Id: row.SelectCategoryModel,
              LoginUserId: this.currentUser.userId,
              SearchType: "Delete",
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              console.log("DELETD", res);
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.Get_Master_Consumer_Durable_Product();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
}
