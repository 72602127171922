<form #f="ngForm" (ngSubmit)="onSaveChargesHead()" novalidate>
  <div class="row m-0 align-items-end">
    <div class="col-md-4 p-1">
      <span>Charges Head </span>
      <input
        required
        type="text"
        #refChargeheadName="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refChargeheadName.invalid,
          'alert-warning':
            refChargeheadName.invalid &&
            (refChargeheadName.dirty ||
              refChargeheadName.touched ||
              refChargeheadName.untouched)
        }"
        [(ngModel)]="ChagesHead"
        placeholder="Charges Head Name"
        name="ChargesHeadName"
        id="ChargesHeadName"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-2 p-1">
      <button
        type="button"
        class="btn font-size-12 button-btn"
        [disabled]="!f.form.valid"
        (click)="onSaveChargesHead()"
      >
        {{ ButtonName }}
      </button>
    </div>
  </div>
</form>
<div class="row m-0">
  <div class="col-md-4 p-1">
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <div class="table-responsive mt-1">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ChagesHeadID">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ChagesHead">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Chages Head
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ChagesHead }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EditAction">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 100px"
          >
            <i
              class="fa fa-pencil-alt font-size-12 mr-2"
              (click)="OnEditChargesHead(row.ChagesHeadID, row.ChagesHead)"
              style="cursor: pointer"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
