<div class="card-container" style="padding: 0;height:100%">
    <div class="title-head"
        style="border-bottom: 1px solid black;background-color: #4dc3a3;border-radius: 9px 9px 0 0 ;">
      <h1 class="main-tit" style="line-height: 2.2;margin:0;">TVR Question Answer Data</h1>
    </div>
    <div style="min-height: 100%;" class="text-center"  *ngIf="!TVRQuestionData">
        No Information Available!!
    </div>
    <div class="cnt-data" *ngIf="TVRQuestionData" style="margin:10px">
        <div class="family">
            <!-- <div class="name-sub" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
            <h1 style="margin-bottom: 2px;">Income Details</h1>
        </div> -->
            <div class="cont">
                <div *ngFor="let item of TVRQuestionData">
                  <div class="cont-Card">
                    <div class="grd">
                      <div class="grd-item1">English</div>
                      <div class="grd-item">{{item.TVR_Question}}</div>

                    </div>
                    <div class="grd">
                      <div class="grd-item1">Hindi</div>
                      <div class="grd-item">{{item.TVR_Question_Hindi}}</div>

                    </div>
                    <div class="grd">
                      <div class="grd-item1">Answer</div>
                      <div class="grd-item" *ngIf="item.QuestionType!='Upload'">{{item.TVR_QuestionAnswer}} </div>
                      <div class="grd-item" *ngIf="item.QuestionType=='Upload'  && item.TVR_QuestionAnswer!=''">
                        <a href="{{ docBaseUrl }}{{ item.Application_No }}/{{item.TVR_QuestionAnswer}}" target="_blank">
                          {{item.TVR_QuestionAnswer}}
                        </a>
                      </div>

                    </div>
                  </div>
                  </div>
            </div>
        </div>
    </div>

</div>
