<button
  type="button"
  class="btn font-size-12 button-btn"
  (click)="searchCustomer()"
>
  Add Waiver
</button>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Receipt
        </h6>
        <button
          type="button"
          (click)="onCloseReceipt()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <span>Type</span>
              <select
                name="Type"
                id="Type"
                class="form-control input-text-css"
                [(ngModel)]="CustomerType"
              >
                <option [value]="0">All</option>
                <option [value]="1">LOS Only</option>
                <option [value]="2">LMS Only</option>
              </select>
            </div>
            <div class="col-md-2">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-2">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Date From</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateFrom"
                  id="LoanDateFrom"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateFrom"
                [(ngModel)]="Customer.LoanDateFrom"
                id="LoanDateFrom"
                placeholder="Date From"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>
            <div class="col-md-2">
              <span>Date To</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateTo"
                  id="LoanDateTo"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateTo"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateTo"
                [(ngModel)]="Customer.LoanDateTo"
                id="LoanDateTo"
                placeholder="Date To"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>

            <div class="col-md-3">
              <span>Select Type</span>
              <select
                name="SelectType"
                id="SelectType"
                class="form-control input-text-css"
                [(ngModel)]="SelectType"
                (change)="SearchChange()"
              >
                <option value="">Select Type</option>
                <option value="Branch">Branch</option>
                <option value="CollArea">Collection Area</option>
                <option value="CollSubArea">Collection Sub Area</option>
                <option value="CollExecutive">Collection Executive</option>
                <option value="SalesExecutive">Sales Executive</option>
                <option value="EngineNo">Engine No.</option>
                <option value="ChassisNo">Chassis No.</option>
                <option value="LoanAccCatg">Loan A/C Category</option>
              </select>
            </div>

            <div class="col-md-6 text-right">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        <div class="mt-3" [hidden]="!dataSourceCustomer">
          <div class="">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSourceCustomer"
                #sortList="matSort"
                matSort
                id="exportReport"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">
                    {{ row.Type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Application_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                    >Aplication No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    {{ row.Application_No }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    Loan No
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomertName }}({{ row.AC_CustomerType }})
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relation Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.FatherName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">
                    {{ row.Customer_Gender }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                  >
                    Age
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">
                    {{ row.GenderAge }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Phone No
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    

                    <div *ngIf="currentUser.IsMasking">
                      {{ row.PhoneNo | mask }}
                    </div>
      
                    <div *ngIf="!currentUser.IsMasking">
                      {{ row.PhoneNo }}
                    </div>




                    <i
                      *ngIf="row.Customer_PhoneNo_IsVerified == true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    View Detail
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell j-c-center mw100"
                  >
                    <app-lms-waiver-creation
                      [LoanId]="row.LoanId"
                      [Status]="Pending"
                      [index]="i"
                    >
                      <i
                        class="fa fa-arrow-circle-right arrow-circle action-btn"
                        aria-label="true"
                      ></i>
                    </app-lms-waiver-creation>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedCustomerColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedCustomerColumns"
                ></mat-row>
              </mat-table>

              <mat-paginator
                #paginatorRef
                #PaginatorList
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
