import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-mst-tahsil-list",
  templateUrl: "./mst-tahsil-list.component.html",
  styleUrls: ["./mst-tahsil-list.component.scss"],
})
export class MstTahsilListComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  SaveTehsilRequestModel: RequestModel = new RequestModel();
  GetDistrictRequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = [
    "TehsilId",
    "District_Name",
    "Tehsil_Name",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  TehsilList: any = [];
  FilterStateList: any = [];
  FilterStateId: any = 29;
  FilterDistrictId: any = "";
  FilterDistrictList: any = [];
  TehsilId: any = 0;
  DistrictId: any = "";
  StateId: any = "";
  TehsilName: string = "";
  ButtonName: string = "Add";
  DistrictList: any = [];

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Tehsil List");
    this._MasterService.GetState().subscribe((result) => {
      this.FilterStateList = JSON.parse(JSON.stringify(result));
      this.onChangeFilterState();
    });
  }

  onChangeFilterState() {
    this.showSpinner = true;
    this.FilterDistrictList = [];
    this.FilterDistrictId = "";
    this.GetDistrictRequestModel.StateId =
      this.FilterStateId == "" ? 0 : parseInt(this.FilterStateId);
    this._MasterService
      .GetDistricts(this.GetDistrictRequestModel)
      .subscribe((result) => {
        this.FilterDistrictList = JSON.parse(JSON.stringify(result));
        this.FilterDistrictId =
          this.FilterDistrictList.length > 0
            ? this.FilterDistrictList[0].DistrictId
            : "";
        this.getTahsilList();
      });
  }

  getTahsilList() {
    this.showSpinner = true;
    this.RequestModel.StateId =
      this.FilterStateId == "" ? 0 : parseInt(this.FilterStateId);
    this.RequestModel.DistrictId =
      this.FilterDistrictId == "" ? 0 : parseInt(this.FilterDistrictId);
    this._MasterService.GetTahsilList(this.RequestModel).subscribe((result) => {
      this.TehsilList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.TehsilList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  onChangeState() {
    this.DistrictList = [];
    this.DistrictId = "";
    this.GetDistrictRequestModel.StateId =
      this.StateId == "" ? 0 : parseInt(this.StateId);
    this._MasterService
      .GetDistricts(this.GetDistrictRequestModel)
      .subscribe((result) => {
        this.DistrictList = JSON.parse(JSON.stringify(result));
      });
  }

  onOpenTehsilModal() {
    $("#AddEditTehsilModal").modal("show");
    $("#AddEditTehsilModal").css("z-index", "1050");
  }

  OneditTehsil(row) {
    this.TehsilId = row.TehsilId;
    this.StateId = row.stateid ? row.stateid : "";
    this.onChangeState();
    this.DistrictId = row.DistrictID;
    this.TehsilName = row.Tehsil_Name;
    this.ButtonName = "Edit";
    $("#AddEditTehsilModal").modal("show");
    $("#AddEditTehsilModal").css("z-index", "1050");
  }

  OnClose() {
    this.TehsilId = 0;
    this.StateId = "";
    this.DistrictId = "";
    this.TehsilName = "";
    this.ButtonName = "Add";
    $("#AddEditTehsilModal").modal("hide");
  }

  onSaveTehsilData() {
    this.SaveTehsilRequestModel.Tehsil_Id = this.TehsilId;
    this.SaveTehsilRequestModel.District_ID = parseInt(this.DistrictId);
    this.SaveTehsilRequestModel.Tehsil_Name = this.TehsilName;
    this.SaveTehsilRequestModel.LoginUserId = this.data.userId;
    this._MasterService
      .SaveTehsilMaster(this.SaveTehsilRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getTahsilList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("Tehsil_List") as HTMLTableElement;
    const worksheetName = "MASTER_TEHSIL_LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
