import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { QuickLoanService } from '../services/quickLoan.service';
@Component({
  selector: 'app-quickloan-file-receive-to-ho-detail',
  templateUrl: './quickloan-file-receive-to-ho-detail.component.html',
  styleUrls: ['./quickloan-file-receive-to-ho-detail.component.scss']
})
export class QuickloanFileReceiveToHODetailComponent implements OnInit {
    currentUser: any;
    showSpinner: boolean = false;
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };
  courierId: any;
  ProcessId: any;
    courierDetail: any;
    dataSource: any;
    displayedColumns: string[] = ['ApplicationId', 'Application_No','Customer', 'Branch', 'Product', 'IsFileReceived', 'Action'];
    ApplicationData: any[] = [];
    receivedModal: any = {};
    inViewMode: boolean = false;
    loading: boolean = false;

  constructor(private dataSharingService: DataSharingService, private _QuickLoanService: QuickLoanService, private route: ActivatedRoute, private encdec: EncrDecrService, private snackBar: MatSnackBar, private router: Router, private losService: LosService) {
      this.route.paramMap.subscribe((param: ParamMap) => {
        this.ProcessId = this.encdec.decrypt(decodeURIComponent(param.get("Pid")))
        //decodeURIComponent(param.get("Pid"));// encdec.decrypt(param.get("id"));
        this.courierId = this.encdec.decrypt(decodeURIComponent(param.get("id")));// encdec.decrypt(param.get("id"));
        });
    }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("File Receiving Detail");
       // this.dataSharingService.LOSHeaderTitle.next('Courier Detail');
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.LOS_GetCourierDetailsForReceving();
        this.receivedModal.CourierId = this.courierId;
      this.receivedModal.LoginUserId = this.currentUser.userId;
    }
    LOS_GetCourierDetailsForReceving() {
        this.showSpinner = true;
        this.losService.LOS_GetCourierDetailsForReceving({ CourierId: this.courierId }).subscribe((res: any) => {
            this.showSpinner = false;
            this.courierDetail = res.Item1[0];
          this.inViewMode = this.courierDetail.CourierRecevingStatus == 'Received';
            this.receivedModal.ReceiverRemarks = this.courierDetail.ReceviedRemarks;
            res.Item2.forEach((obj: any) => {
                if (obj.IsFileReceived.trim().toLowerCase() == "received") {
                    this.ApplicationData.push(obj.ApplicationId);
                }
            });
            this.dataSource = new MatTableDataSource(res.Item2);
        })
    }
    onCheckboxChange(e: any, data: any) {
        let _idx = this.ApplicationData.indexOf(data.ApplicationId);
        if (_idx > -1) {
            this.ApplicationData.splice(_idx, 1);
        }
        else {
            this.ApplicationData.push(data.ApplicationId);
        }
    }
  OnClose() {
    this.router.navigate([`quickloan-dashboard/quick-loan/list/${this.encdec.encrypt(encodeURIComponent(this.ProcessId))}`]);
    }
    onSaveReceiveParcel() {
        if (this.ApplicationData.length > 0) {
            this.loading = true;
          this.receivedModal.ApplicationId1 = this.ApplicationData.join(',');
          this._QuickLoanService.QuickLoan_Save_FileReceivingHO(this.receivedModal).subscribe((res: any) => {
                this.loading = false;
                if (res[0].CODE == 0) {
                    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                    this.OnClose();
                } else {
                    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                }
            })
        }
        else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please select atleast one record.', ...this.configSuccess });
        }
    }
    goToDetail(data: any) {

      //  var appid = this.encdec.encrypt(data.ApplicationId);
      const url = this.router.createUrlTree([`quickloan-dashboard/quick-loan/list/${this.encdec.encrypt(encodeURIComponent(this.ProcessId))}`])
    
        window.open(url.toString().replace('%23','#'), '_blank')
       // this.router.navigate([`/LOS/application/${appid}`]);
      }
}
