import { Component, OnInit, ViewChild } from "@angular/core";
import { LmsService } from "../services/lms.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-swap-nach-source",
  templateUrl: "./swap-nach-source.component.html",
  styleUrls: ["./swap-nach-source.component.scss"],
})
export class SwapNachSourceComponent implements OnInit {
  showSpinner: boolean = false;
  swapDate: any = new Date();
  BankInCompany: any = "";
  swapBankDropdown: any = "";
  BankList: any;
  dataSource: any;
  JsonData: any[] = [];
  displayedColumns: any[] = [];

  currentUser: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private lmsService: LmsService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Swap Nach Source");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBankDropdown();

    this.displayedColumns = [
      "Select",
      "Loan_Id",
      "LoanAcNo",
      "CustomerName",
      "Branch_Name",
      "CustomerBankname",
      "CustomerAccountNo",
      "TotalNachPending",
      "NachCode",
    ];
  }

  getBankDropdown() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: "Bank", LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.BankList = JSON.parse(JSON.stringify(res));
      });
  }

  onSearchNachforSwap() {
    // console.log("Search button clicked");
    this.showSpinner = true;
    this.dataSource = "";
    this.lmsService
      .LMS_Get_Nach_Swap_Source({
        dueDate: this.swapDate,
        PresentBankInCompanyId: this.BankInCompany,
      })
      .subscribe((res: any) => {
        console.log("Loan ids", res);
        if (res.length > 0) {
          // console.log("API CALL RESULT--SWAP", res);
          this.JsonData = JSON.parse(JSON.stringify(res));

          this.JsonData.forEach((x) => {
            x.IsSelected = false;
          });
          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkUncheckAll(event) {
    this.JsonData.forEach((x) => {
      if (event.checked == true) {
        x.IsSelected = true;
      }
      if (event.checked == false) {
        x.IsSelected = false;
      }
    });
    this.dataSource = new MatTableDataSource(this.JsonData);
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "swap_nach_source"
    ) as HTMLTableElement;
    const worksheetName = "SWAP NACH SOURCE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  onClickSwapBankNach() {
    let selectedLoanIds: string[] = [];
    this.JsonData.forEach((x) => {
      if (x.IsSelected) {
        selectedLoanIds.push(x.Loan_Id);
      }
    });

    if (
      this.swapBankDropdown == "" ||
      this.swapBankDropdown == null ||
      this.swapBankDropdown == undefined
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select a bank for Nach Swap!",
        ...this.configSuccess,
      });
    } else if (selectedLoanIds.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select at least one loan for Nach Swap!",
        ...this.configSuccess,
      });
    } else if (this.BankInCompany == this.swapBankDropdown) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "The selected bank for Nach Swap cannot be the same as the previous bank!",
        ...this.configSuccess,
      });
    } else {
      console.log("else condition ");

      this.lmsService
        .LMS_Update_Nach_Swap_Source({
          dueDate: this.swapDate,
          PresentBankInCompanyId: this.swapBankDropdown,
          LoginUserId: this.currentUser.userId,
          loanId: selectedLoanIds.join(","),
        })
        .subscribe((res: any) => {
          console.log("bank swapped");
          this.showSpinner = false;
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.onSearchNachforSwap();
            this.swapBankDropdown = "";
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
}
