<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mb-3">
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="geSenctionletter()"
        [(ngModel)]="ProductId"
      >
        <option value="">Select Products</option>
        <option
          *ngFor="let product of productDropdown"
          [value]="product.ProductId"
        >
          {{ product.Product_Name }}
        </option>
      </select>
    </div>
  </div>
  <form #f="ngForm" (ngSubmit)="onSavetemplate()" novalidate *ngIf="ProductId">
    <div class="row m-0">
      <div class="col-md-9">
        <ckeditor
          [editor]="Editor"
          name="templateData"
          [config]="config"
          [(ngModel)]="templateData"
        >
        </ckeditor>
      </div>
      <div class="col-md-3 submenu">
        <a
          class="lead fs-14 mb-2 p_data"
          href="https://abc"
          target="_blank"
          *ngFor="let x of JsonData"
          ><p>{{ x.SideMenuName }}</p></a
        >
      </div>
    </div>
    <div class="text-right mb-2">
      <button
        type="button"
        (click)="onSavetemplate()"
        class="font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!f.form.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
