import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";

import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-customer-kyc-verification",
  templateUrl: "./customer-kyc-verification.component.html",
  styleUrls: ["./customer-kyc-verification.component.scss"],
})
export class CustomerKycVerificationComponent implements OnInit {
  showSpinner: boolean = false;
  VoucherDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  VoucherDateTo: any = new Date();
  FyearId: any;
  maxDate: any;
  minDate: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  DataSource: any;

  JsonData: any[] = [];
  KycDetails: any;

  displayedColumns: string[] = ["TYPE", "Step_Name", "Hit", "Rate", "Amount"];
  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("KYC Verification Summary");
    console.log("total amount", this.calculateTotalAmount);
  }

  DateChange() {
    if (this.VoucherDateFrom != "" && this.VoucherDateTo != "") {
      if (this.VoucherDateFrom > this.VoucherDateTo) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Date To greater than Date From.",
          ...this.configSuccess,
        });
        this.VoucherDateFrom = "";
        this.VoucherDateTo = "";
      }
    }
  }

  searchCustomerKycVerification() {
    this.reportsService
      .Customer_KYC_Verification_Summary({
        FromDate: this.VoucherDateFrom,
        ToDate: this.VoucherDateTo,
      })
      .subscribe((res: any) => {
        this.KycDetails = res.Item1[0];
        this.JsonData = res.Item2;
        console.log("Amount", this.JsonData);

        this.DataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res.Item2))
        );
      });
  }

  calculateTotalAmount() {
    if (this.JsonData != null) {
      let sum = 0.0;
      for (let i = 0; i < this.JsonData.length; i++) {
        sum += Number(this.JsonData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
}
