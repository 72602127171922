import { Component, OnInit } from '@angular/core';
declare var $: any;
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { MasterService } from '../../Shared/app.Masters.Service';
// import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';

@Component({
  selector: 'app-machine-mac-address',
  templateUrl: './machine-mac-address.component.html',
  styleUrls: ['./machine-mac-address.component.scss']
})
export class MachineMACAddressComponent implements OnInit {
  Machine_Id: any;
  public get dialog(): DialogService {
    return this._dialog;
  }
  public set dialog(value: DialogService) {
    this._dialog = value;
  }
  private data: any;
  RequestModel: RequestModel = new RequestModel();
  displayedColumns: string[];
  // dialog: any;
  // snackBar: any;

  UserName: string;
  empID: string;
  UpdateUserName: any;

  constructor
    (private router: Router, private fb: UntypedFormBuilder,
      private _MasterService: MasterService,
      private _dialog: DialogService, public snackBar: MatSnackBar,
      private dataSharingService: DataSharingService) {

  }


  showSpinner: boolean = false;
  dataSource: any;
  ShowFilter = true;
  mac: any;
  mac_add: any;
  MAC_add: any;
  UserDropdownSettings: any = {};
  EmployeeList: any[] = [];
  user: any = [];
  Employee: any;
  userForm: UntypedFormGroup;
  userFormUpdate: UntypedFormGroup;
  SelectDeptId: any = '';
  empIDs: any[] = [];
  Emp_Name: any;
  currentUser: any;
  DeviceType: any = '';
  DeviceName: any = '';
  JsonData: any;
  UpdateDeviceType: any = '';
  updateDeviceName: any = '';
  update_MAC_add: any = '';
  updateEmpID: any = '';
  update_empID: any = '';

  Id: number;
  value: any;




  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next('Machine MAC Address');
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getEmployeeList();
    this.getMAC();
    this.getEmployeeData();
    this.userForm = this.fb.group({
      UserName: []
    });

    this.userFormUpdate = this.fb.group({
      UpdateUserName: []
    });
    this.UserDropdownSettings = {
      singleSelection: false,
      idField: 'EmpId',
      textField: 'Emp_Name',

      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: this.ShowFilter
    };

  }

  getMAC() {
    // this.showSpinner = true;

    this._MasterService.GetMACAddress({}).subscribe((res: any) => {
      this.mac = res;
      console.log("data", res);
      this.displayedColumns = ['Machine_Id', 'MAC_Address', 'Device_Type', 'Device_Name', 'Action'];
      this.showSpinner = false;
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      this.JsonData = JSON.parse(JSON.stringify(res));
    })
  }
  OnUserSelect(e) {
    this.empIDs.push({ EmpId: Number(e.EmpId) });
    // console.log("ulta sidha",this.EmpId)


  }

  OnUserDeSelect(e) {

    let index = this.empIDs.findIndex(item => item.EmpId === e.EmpId)
    // this.empIDs.indexOf(e["EmpId"]);
    this.empIDs.splice(index, 1)
    // console.log("ulta sidhaaaaaaa",this.EmpId);


  }

  OnSelectAll(_$event) {
    this.empIDs = [];
    this.EmployeeList.forEach(x => {
      this.empIDs.push({ EmpId: x.EmpId });

    })
    // console.log("all",this.EmpId)
  }

  OnDeSelectAll(_$event) {
    this.empIDs = [];
    // console.log("remove",this.EmpId);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSaveMACAddress() {
    // console.log("user",this.user);

    this._MasterService.SaveMACAddress({ LoginUserId: this.currentUser.userId, MAC_Address: this.MAC_add, Type: 'Create', Userids: this.empIDs.map(item => item.EmpId).join(','), Device_Type: this.DeviceType, Device_Name: this.DeviceName }).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      // console.log("response",res);
      this.getMAC();

    })

  }

  // GetAllMAc(){
  //   this._MasterService
  // }
  addMACAdress() {
    this.onReset();
    $('#mac_add').modal('show');
    $("#mac_add").css("z-index", "1050");

  }
  OnClose() {
    $('#mac_add').modal('hidden');
  }

  onReset() {
    this.DeviceType = '';
    this.DeviceName = '';
    this.MAC_add = '';
    this.empIDs = [];

    this.UpdateUserName = [];
  }



  EditMACAddress(row) {
    this.empIDs = [];
    //var UserNameList =[];
    //this.EmployeeList.forEach(x => {
    //  UserNameList.push(x.Emp_Name);

    //})
    //this.userFormUpdate = this.fb.group({
    //  UserName: [JSON.parse(JSON.stringify(UserNameList))]
    //});
    if (row.Emp_Ids == "") {
      this.UpdateUserName = [];
    } else {

      var ArrDept = row.Emp_Ids.split(",");
      var arr = [];
      ArrDept.forEach((obj: any) => {
        if (obj != '' && obj != undefined && obj != 0) {
          arr.push(this.EmployeeList.filter(item => item.EmpId == obj)[0]);
        }
      });
      this.UpdateUserName = arr;
    }
    // console.log("aaaa",row);
    this.UpdateDeviceType = row.Device_Type;
    this.updateDeviceName = row.Device_Name;
    this.update_MAC_add = row.MAC_Address;
    this.Machine_Id = row.Machine_Id;
    var empIDs = row.Emp_Ids.split(',');
    empIDs.forEach((obj: any) => {
      if (obj != '' && obj != undefined && obj != 0) {
        this.empIDs.push({
          EmpId: Number(obj)
        })
      }
    })

    $('#mac_add_updated').modal('show');
    $("#mac_add_updated").css("z-index", "1050");
  }


  onUpdate() {
    let Update = {
      "Device_Type": this.UpdateDeviceType,
      "Device_Name": this.updateDeviceName,
      "MAC_Address": this.update_MAC_add,
      "Machine_Id": this.Machine_Id,
      "Userids": this.empIDs.map(item => item.EmpId).join(','),
      "Type": 'Update'
    }
    // console.log("asdcv",this.update)
    this._MasterService.SaveMACAddress(Update).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.getMAC();
        // this.EditMACAddress(row);
        this.OnClose();
        console.log("aaaaaa", res);
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });

  }


  onDelete(row) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          // this.showSpinner = true;
          this._MasterService.SaveMACAddress({ Machine_Id: row.Machine_Id, Type: 'Delete' }).subscribe((res: any) => {
            this.showSpinner = false;
            // console.log("delete",res);
            if (res[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.getMAC();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          });
        }
      });
  }

  getEmployeeList() {
    this.empIDs = [];
    this.Emp_Name = [];

    this._MasterService.GetEmployeeList(this.RequestModel).subscribe((result) => {
      this.EmployeeList = JSON.parse(JSON.stringify(result));
      // this.EmpId=JSON;
      // this.Emp_Name=JSON;
      console.log("employee", this.EmployeeList)

    });
  }
  getEmployeeData() {
    // var ArrDept = this.EmpId.split(",");
    var arr = [1, 2];

    // ArrDept.forEach((obj: any) => {
    //   arr.push(this.EmployeeList.filter(item => item.EmpId == obj)[0]);
    // });
    // this.Employee = arr;
    console.log("tetsttt", arr.toString());
    // if selected multiple Department on selection box End
  }


  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (_m, p) => c[p]);

    const table = document.getElementById("mac_address") as HTMLTableElement;
    const worksheetName = 'MAC ADDRESS'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }





}
