<div>
  <form #f="ngForm" (ngSubmit)="onSaveEmployeeDetailData()" novalidate>
    <div class="row m-0 mt-1 formborder">
      <div class="col-md-4 p-1">
        <span>Employee Name </span>
        <input
          required
          type="text"
          #refProductName="ngModel"
          name="EmployeeName"
          id="EmployeeName"
          placeholder="Employee Name"
          [(ngModel)]="EmployeeName"
          class="form-control input-text-css"
          disabled
        />
      </div>
      <div class="col-md-4 p-1">
        <span> Designation </span>
        <select
          name="SelectRoleId"
          id="SelectRoleId"
          disabled
          [(ngModel)]="SelectRoleId"
          class="form-control input-text-css"
        >
          <option
            *ngFor="let rolesDropdownData of RolesDropdownData"
            [value]="rolesDropdownData.RoleId"
          >
            {{ rolesDropdownData.Role_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-4 p-1">
        <span>Reporting Manager </span>
        <select
          name="EmpManagerId"
          id="EmpManagerId"
          disabled
          [(ngModel)]="EmpManagerId"
          class="form-control input-text-css"
        >
          <option
            *ngFor="let reportingManager of ReportingManagerDropdown"
            [value]="reportingManager.EmpId"
          >
            {{ reportingManager.EmpName }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <div class="table-responsive mt-2 simple-table">
        <table class="table font-size-12">
          <thead>
            <tr>
              <th>#</th>
              <th>Process Name</th>
              <th>Select</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let assignProcess of LoanProcessList; let i = index"
              class="font-size-11"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ assignProcess.Process_Name }}</td>
              <td>
                <mat-checkbox
                  [checked]="assignProcess.isActiveSelect"
                  [disabled]="assignProcess.isDisable"
                  id="checkbox-Process-{{ i }}"
                  name="checkbox-Process-{{ i }}"
                  (change)="
                    onChangeAssignProcess($event, assignProcess.ProcessId)
                  "
                >
                </mat-checkbox>
              </td>
              <td>
                <i
                  *ngIf="assignProcess.ProcessAlradyAssigned"
                  class="fa fa-trash style-delete"
                  (click)="onDeleteAssignProcess(assignProcess.EmpProcessId)"
                  aria-label="true"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right mb-2">
          <button
            type="button"
            (click)="onSaveEmployeeDetailData()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
