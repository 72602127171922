import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
declare var $: any;

@Component({
  selector: 'app-lms-loan-precloser',
  templateUrl: './lms-loan-precloser.component.html',
  styleUrls: ['./lms-loan-precloser.component.scss']
})
export class LmsLoanPrecloserComponent implements OnInit {

  currentUser: any;
  showSpinner: boolean = false;
  status: any = "All";
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // popup
  Type: any = "0";
  Customer: any = {};
  CustomerList: any = [];
  displayedCustomerColumns: string[] = [];
  dataSourceCustomer: any;
  @ViewChild("sortCustomerList", { static: true }) sortCustomer: MatSort;
  @ViewChild("paginatorCustomerList", { static: true }) paginatorCustomer: MatPaginator;

  isMaker: string = "";

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SelectType: any = '';
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];
  Data: any[] = [];

  constructor(private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService) {

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.isMaker = param.get("isMaker");
      this.getData();
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Pre-closer");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedCustomerColumns = ['LoanId', 'LoanNo', 'ApplicationNo', 'Product', 'Branch', 'CustomertName', 'FatherName', 'PhoneNo', 'Loan_Date', 'EditAction'];

    this.displayedColumns = [
      "CloserId", "ApplicationNo", "LoanNo", "Customer_Name", "Branch", "Product_Name", "EffectiveDate", "RequestBy", "RequestStatus", "RequestOn", "Waiver_GivenBy", "TotalWaiver_Amount", "Closer_Type", "Action"
    ];
    //this.LoanList();
    this.Customer.CloseCase = '';

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    });
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 }).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    });
  }
  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId: '',
      Area: '',
      SubArea: '',
      SalesEx: '',
      CollectionEx: '',
      LoanAccountingCatId: '',
    };
  }

  getData() {
    if (this.isMaker == "R") {
      this.dataSharingService.HeaderTitle.next("Loan Pre Closer");
      this.LMS_Get_Loan_Closer_Request_List();
    }
    else {
      this.dataSharingService.HeaderTitle.next("Loan Waier Approval");
      this.LMS_Get_Loan_Closer_Request_WaiverApproval();
    }
  }
  LMS_Get_Loan_Closer_Request_List() {
    this.showSpinner = true;
    // if (this.status == "Pending") {

    // } else {
    //   this.displayedColumns = [
    //     "ApplicationId",
    //     "Application_No",
    //     "Customer",
    //     "Branch",
    //     "Product",
    //     "LoanAmount",
    //     "TAT_Hr",
    //     "FIChecking_Status",
    //     "FI_Executive",
    //     "AssignedOn",
    //     "ComplotedOn",
    //     "Action",
    //   ];
    // }
    this.lmsService.LMS_Get_Loan_Closer_Request_List({ LoginUserId: this.currentUser.userId, LoanCloser_Status: this.status }).subscribe((res: any) => {
      this.Data = JSON.parse(JSON.stringify(res));
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    })
  }
  LMS_Get_Loan_Closer_Request_WaiverApproval() {
    this.showSpinner = true;
    this.displayedColumns = [
      "CloserId", "ApplicationNo", "LoanNo", "Branch", "Product_Name", "EffectiveDate", "RequestBy", "RequestStatus", "RequestOn", "Waiver_GivenBy", "TotalWaiver_Amount", "Closer_Type", "Action"
    ];
    this.lmsService.LMS_Get_Loan_Closer_Request_WaiverApproval({ LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  onNewPreCloserSearch() {
    this.SelectType = '';
    this.Customer.CloseCase = '';
    this.dataSourceCustomer = null;
    $('#SearchLoanPreCloserModel').modal('show');
    $("#SearchLoanPreCloserModel").css("z-index", "1050");
  }

  onCloseSearchLoanPreCloser() {
    this.CustomerList = [];
    this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
    this.dataSourceCustomer.sort = this.sortCustomer;
    this.dataSourceCustomer.paginator = this.paginatorCustomer;
    this.Type = "0";
    this.Customer = {};
    $('#SearchLoanPreCloserModel').modal('hide');
  }

  onSearchCustomerDetail() {
    this.showSpinner = true;

    let loanNO = this.Customer.LoanNo ? (this.Customer.LoanNo == null || this.Customer.LoanNo == '' || this.Customer.LoanNo == undefined) : true;
    let ApplicationNo = this.Customer.ApplicationNo ? (this.Customer.ApplicationNo == null || this.Customer.ApplicationNo == '' || this.Customer.ApplicationNo == undefined) : true;
    let CustomerName = this.Customer.CustomerName ? (this.Customer.CustomerName == null || this.Customer.CustomerName == '' || this.Customer.CustomerName == undefined) : true;
    let CustomerPhone = this.Customer.CustomerPhone ? (this.Customer.CustomerPhone == null || this.Customer.CustomerPhone == '' || this.Customer.CustomerPhone == undefined) : true;
    let PANCard = this.Customer.PANCard ? (this.Customer.PANCard == null || this.Customer.PANCard == '' || this.Customer.PANCard == undefined) : true;
    let Passport = this.Customer.Passport ? (this.Customer.Passport == null || this.Customer.Passport == '' || this.Customer.Passport == undefined) : true;
    let VoterID = this.Customer.VoterID ? (this.Customer.VoterID == null || this.Customer.VoterID == '' || this.Customer.VoterID == undefined) : true;
    let DrivingLien = this.Customer.DL ? (this.Customer.DL == null || this.Customer.DL == '' || this.Customer.DL == undefined) : true;
    let Aadhaar = this.Customer.Aadhaar ? (this.Customer.Aadhaar == null || this.Customer.Aadhaar == '' || this.Customer.Aadhaar == undefined) : true;

    let VehicleNo = this.Customer.VehicleNo ? (this.Customer.VehicleNo == null || this.Customer.VehicleNo == '' || this.Customer.VehicleNo == undefined) : true;
    let LoanDateFrom = this.Customer.LoanDateFrom ? (this.Customer.LoanDateFrom == null || this.Customer.LoanDateFrom == '' || this.Customer.LoanDateFrom == undefined) : true;
    let LoanDateTo = this.Customer.LoanDateTo ? (this.Customer.LoanDateTo == null || this.Customer.LoanDateTo == '' || this.Customer.LoanDateTo == undefined) : true;
    let CloseCase = this.Customer.CloseCase ? (this.Customer.CloseCase == null || this.Customer.CloseCase == '' || this.Customer.CloseCase == undefined) : true;
    let BranchId = this.Customer.BranchId ? (this.Customer.BranchId == null || this.Customer.BranchId == '' || this.Customer.BranchId == undefined) : true;
    let EngineNo = this.Customer.EngineNo ? (this.Customer.EngineNo == null || this.Customer.EngineNo == '' || this.Customer.EngineNo == undefined) : true;
    let ChassisNo = this.Customer.ChassisNo ? (this.Customer.ChassisNo == null || this.Customer.ChassisNo == '' || this.Customer.ChassisNo == undefined) : true;
    let Area = this.Customer.Area ? (this.Customer.Area == null || this.Customer.Area == '' || this.Customer.Area == undefined) : true;
    let SubArea = this.Customer.SubArea ? (this.Customer.SubArea == null || this.Customer.SubArea == '' || this.Customer.SubArea == undefined) : true;
    let SalesEx = this.Customer.SalesEx ? (this.Customer.SalesEx == null || this.Customer.SalesEx == '' || this.Customer.SalesEx == undefined) : true;
    let CollectionEx = this.Customer.CollectionEx ? (this.Customer.CollectionEx == null || this.Customer.CollectionEx == '' || this.Customer.CollectionEx == undefined) : true;
    let LoanAccountingCatId = this.Customer.LoanAccountingCatId ? (this.Customer.LoanAccountingCatId == null || this.Customer.LoanAccountingCatId == '' || this.Customer.LoanAccountingCatId == undefined) : true;

    if (
      loanNO && ApplicationNo && CustomerName && CustomerPhone && PANCard && Passport && VoterID && DrivingLien && Aadhaar && VehicleNo &&
      LoanDateFrom && LoanDateTo && CloseCase && BranchId && EngineNo && ChassisNo && Area && SubArea && SalesEx && CollectionEx && LoanAccountingCatId
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter atleast one fields.", ...this.configSuccess });
      this.CustomerList = [];
      this.dataSourceCustomer = null;
      /*this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
      this.dataSourceCustomer.sort = this.sortCustomer;
      this.dataSourceCustomer.paginator = this.paginatorCustomer;*/
      this.showSpinner = false;
      return false;
    }
    console.log("this.Customer", this.Customer);
    this.Customer.LoginUserId = this.currentUser.userId;
    this.lmsService.LMS_Get_Search_Loans_JSON({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
      console.log("res", res);
      if (res.length > 0) {
        if (res.length == 1) {
          this.goToDetail(res[0]);
        }
        else {
          this.CustomerList = JSON.parse(JSON.stringify(res));
          if (this.Type != "0") {
            var type;
            if (this.Type == "1")
              type = "LOS";
            else
              type = "LMS";
            this.CustomerList = this.CustomerList.filter(item => item.Type == type);
          }
          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sortCustomer;
          this.dataSourceCustomer.paginator = this.paginatorCustomer;
          this.showSpinner = false;
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
      }
    });
  }

  goToDetail(row) {
  
      this.router.navigate([`loan/precloser/${this.isMaker}/${this._EncrDecrService.encrypt(encodeURIComponent(row.LoanId))}/${this._EncrDecrService.encrypt(encodeURIComponent("0"))}`]);

  }
  goToSaveDetail(row) {
    if (row.IsNewCloser == 1) {
      this.router.navigate([`loan/precloserNew/${this.isMaker}/${this._EncrDecrService.encrypt(encodeURIComponent(row.LoanId))}/${this._EncrDecrService.encrypt(encodeURIComponent(row.CloserId))}`]);

       }
    else {
      this.router.navigate([`loan/precloser/${this.isMaker}/${this._EncrDecrService.encrypt(encodeURIComponent(row.LoanId))}/${this._EncrDecrService.encrypt(encodeURIComponent(row.CloserId))}`]);

    }
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("loan_precloser") as HTMLTableElement;
    const worksheetName = 'LOAN PRECLOSER'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

}
