<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #df="ngForm" novalidate (keyup.enter)="GetReportFutureFlow()">
  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-2">
      <span class="required-lable"> Start Month Year </span>
      <input
        name="st_month_year"
        [(ngModel)]="st_month_year"
        id="st_month_year"
        placeholder="Start Month Year"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
        (onShown)="onOpenCalendar($event)"
        required
        #refst_month_year="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refst_month_year.invalid,
          'alert-warning':
            refst_month_year.invalid &&
            (refst_month_year.dirty ||
              refst_month_year.touched ||
              refst_month_year.untouched)
        }"
      />
      <!-- (ngModelChange)="DateChange()"  -->
    </div>
    <div class="col-md-2">
      <span>Product</span>
      <select
        name="SelectProduct"
        id="SelectProduct"
        #refSelectGender="ngModel"
        [(ngModel)]="SelectProduct"
        class="form-control input-text-css"
      >
        <option value="0" selected>Select All</option>
        <option *ngFor="let product of ProductList" [value]="product.ProductId">
          {{ product.Product }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span>Accounting Category</span>
      <select
        name="LoanAcCatId"
        id="LoanAcCatId"
        [(ngModel)]="LoanAcCatId"
        class="form-control input-text-css"
      >
        <option value="0">All</option>
        <option
          *ngFor="let row of AccountingCatDropdown"
          [value]="row.Loan_CategoryId"
        >
          {{ row.Loan_category }}
        </option>
      </select>
    </div>
    <div class="col-md-4 mt-3">
      <button
        type="button"
        (click)="GetReportFutureFlow()"
        class="btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
  </div>
</form>

<div
  class="table-responsive mt-3"
  *ngIf="DataSource?.data.length > 0"
  style="overflow: auto"
>
  <table
    mat-table
    [dataSource]="DataSource"
    matSort
    matTableExporter
    #exporter="matTableExporter"
  >
    <ng-container matColumnDef="Id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="Month_Year">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Month Year</th>
      <td mat-cell *matCellDef="let row">{{ row.Month_Year }}</td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="No_Of_Case">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>No Of Case</th>
      <td mat-cell *matCellDef="let row">{{ row.No_Of_Case }}</td>
      <th mat-footer-cell *matFooterCellDef>Total</th>
    </ng-container>

    <ng-container matColumnDef="Installment_Amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>EMI Amount</th>
      <td mat-cell *matCellDef="let row">{{ row.Installment_Amount }}</td>
      <th mat-footer-cell *matFooterCellDef>
        {{ totaltypewise("Installment_Amount") }}
      </th>
    </ng-container>

    <ng-container matColumnDef="Principal">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Principal</th>
      <td mat-cell *matCellDef="let row">{{ row.Principal }}</td>
      <th mat-footer-cell *matFooterCellDef>
        {{ totaltypewise("Principal") }}
      </th>
    </ng-container>

    <ng-container matColumnDef="Interest">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Interest</th>
      <td mat-cell *matCellDef="let row">{{ row.Interest }}</td>
      <th mat-footer-cell *matFooterCellDef>{{ totaltypewise("Interest") }}</th>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row"></td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="sticky-footer fontcolor"
      style="background: #4dc3a3"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-footer-row
      class="sticky-footer fontcolor"
      *matFooterRowDef="displayedColumns; sticky: true"
      style="background: #4dc3a3"
    ></tr>
  </table>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-4 p-2">
      <button class="mt-1 btn font-size-12 button-btn" (click)="exportTable()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<table
  id="Future-flow"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Month Year
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of Case
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i }}</td>
    <td>{{ x.Month_Year }}</td>
    <td>{{ x.No_Of_Case }}</td>
    <td>{{ x.Installment_Amount }}</td>
    <td>{{ x.Principal }}</td>
    <td>{{ x.Interest }}</td>
  </tr>
</table>
