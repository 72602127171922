<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="main-container">
  <form #f="ngForm" novalidate class="formborder" *ngIf="voucherAdd==true">
    <div class="row m-0 align-items-center">
      <div class="col-md-2">
        <span class="required-lable">Receipt Type</span>
        <select name="EntryType"
                id="EntryType"
                required
                #refEntryType="ngModel"
                [ngClass]="{
            'is-invalid': df.submitted && refEntryType.invalid,
            'alert-warning':
              refEntryType.invalid &&
              (refEntryType.dirty ||
                refEntryType.touched ||
                refEntryType.untouched)
          }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Sub_Type"
                class="form-control input-text-css"
                (change)="onVoucherSubTypeChange()">
          <option value="">Select Type</option>
          <option value="Loan">Loan</option>
          <option value="Accounting">Accounting</option>
          <option value="Partner">Partner</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Voucher Date</span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="Voucher_Date"
                 id="Voucher_Date"
                 #refVoucher_Date="ngModel"
                 [min]="CheckDate"
                 [max]="Days_AllowedDateMax"
                 (dateChange)="onVoucherDateChange()"
                 required
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Date.invalid,
              'alert-warning':
                refVoucher_Date.invalid &&
                (refVoucher_Date.dirty ||
                  refVoucher_Date.touched ||
                  refVoucher_Date.untouched)
            }"
                 class="form-control input-text-css"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Date" />
          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Voucher No</span>
        <input type="text"
               readonly
               name="Voucher_No"
               id="Voucher_No"
               #refVoucher_No="ngModel"
               [(ngModel)]="voucherModel.Voucher.Voucher_No"
               class="form-control input-text-css" />
      </div>
      <div class="col-md-2">
        <span class="required-lable">Bank/Cash</span>
        <select name="Voucher_Tag"
                id="Voucher_Tag"
                required
                #refVoucher_Tag="ngModel"
                [ngClass]="{
            'is-invalid': df.submitted && refVoucher_Tag.invalid,
            'alert-warning':
              refVoucher_Tag.invalid &&
              (refVoucher_Tag.dirty ||
                refVoucher_Tag.touched ||
                refVoucher_Tag.untouched)
          }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
                class="form-control input-text-css"
                (change)="onVoucherTagChange()">
          <option value="">Select Type</option>
          <option value="Bank">Bank</option>
          <option value="CASH">Cash</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="">Manual Receipt No.</span>
        <input name="Voucher_ReceiptNo_Book"
               type="text"
               id="Voucher_ReceiptNo_Book"
               #refVoucher_ReceiptNo_Book="ngModel"
               [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptNo_Book"
               placeholder="Voucher ReceiptNo Book"
               class="form-control input-text-css" />
      </div>
      <div class="col-md-2">
        <span class="">Manual Receipt Date</span>
        <div class="datepicker_feild">
          <input [matDatepicker]="pickervrc"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="Voucher_ReceiptDate"
                 id="Voucher_ReceiptDate"
                 #refVoucher_ReceiptDate="ngModel"
                 class="form-control input-text-css"
                 [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptDate"
                 [max]="voucherModel.Voucher.Voucher_Date" />
          <mat-datepicker-toggle matSuffix
                                 [for]="pickervrc"></mat-datepicker-toggle>
          <mat-datepicker #pickervrc></mat-datepicker>
        </div>
      </div>
    </div>

    <div class="row m-0 mt-2">
      <div class="col-md-2">
        <span class="required-lable">Select Account </span>
        <select name="DRAccountId"
                id="DRAccountId"
                required
                #refDRAccountId="ngModel"
                [ngClass]="{
            'is-invalid': f.submitted && refDRAccountId.invalid,
            'alert-warning':
              refDRAccountId.invalid &&
              (refDRAccountId.dirty ||
                refDRAccountId.touched ||
                refDRAccountId.untouched)
          }"
                [(ngModel)]="voucherModel.Voucher.DRAccountId"
                class="form-control input-text-css">
          <option value="">Select Account</option>
          <option *ngFor="let data of accountTagDropdown"
                  [value]="data.AccountId">
            {{ data.Account_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2"
           *ngIf="
          voucherModel.Voucher.DRAccountId != '' &&
          voucherModel.Voucher.Voucher_Tag == 'Bank'
        ">
        <span class="required-lable">Payment Mode</span>
        <select name="Voucher_Mode_of_Payment"
                id="Voucher_Mode_of_Payment"
                required
                #refVoucher_Mode_of_Payment="ngModel"
                [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Mode_of_Payment.invalid,
            'alert-warning':
              refVoucher_Mode_of_Payment.invalid &&
              (refVoucher_Mode_of_Payment.dirty ||
                refVoucher_Mode_of_Payment.touched ||
                refVoucher_Mode_of_Payment.untouched)
          }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                class="form-control input-text-css"
                (change)="onChangeMode()">
          <option value="">Select Mode Of Payment</option>
          <option value="Cheque">Cheque</option>
          <option value="RTGS">RTGS</option>
          <option value="Gateway">E-Gateway</option>
          <option value="NEFT">NEFT</option>
          <option value="IMPS">IMPS</option>
          <option value="Transfer">Transfer</option>
        </select>
      </div>

      <ng-container *ngIf="
          voucherModel.Voucher.DRAccountId != '' &&
          voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'
        ">
        <div class="col-md-2">
          <span>Cheque No</span>
          <input type="text"
                 #refVoucher_Cheque_No="ngModel"
                 placeholder="Cheque No"
                 class="form-control input-text-css"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Cheque_No.invalid,
              'alert-warning':
                refVoucher_Cheque_No.invalid &&
                (refVoucher_Cheque_No.dirty ||
                  refVoucher_Cheque_No.touched ||
                  refVoucher_Cheque_No.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
                 name="Voucher_Cheque_No"
                 id="Voucher_Cheque_No" />
        </div>
        <div class="col-md-2">
          <span>Cheque Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervcd"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="Voucher_Cheque_Date"
                   id="Voucher_Cheque_Date"
                   #refVoucher_Cheque_Date="ngModel"
                   [ngClass]="{
                'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid,
                'alert-warning':
                  refVoucher_Cheque_Date.invalid &&
                  (refVoucher_Cheque_Date.dirty ||
                    refVoucher_Cheque_Date.touched ||
                    refVoucher_Cheque_Date.untouched)
              }"
                   class="form-control input-text-css"
                   [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervcd"></mat-datepicker-toggle>
            <mat-datepicker #pickervcd></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2">
          <span class="">Cheque Clear Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervccd"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="Voucher_Cheque_Clear_Date"
                   id="Voucher_Cheque_Clear_Date"
                   #refVoucher_Cheque_Clear_Date="ngModel"
                   class="form-control input-text-css"
                   [ngClass]="{
                'is-invalid':
                  f.submitted && refVoucher_Cheque_Clear_Date.invalid,
                'alert-warning':
                  refVoucher_Cheque_Clear_Date.invalid &&
                  (refVoucher_Cheque_Clear_Date.dirty ||
                    refVoucher_Cheque_Clear_Date.touched ||
                    refVoucher_Cheque_Clear_Date.untouched)
              }"
                   [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervccd"></mat-datepicker-toggle>
            <mat-datepicker #pickervccd></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2">
          <span>Bank Name</span>
          <input type="text"
                 #refVoucher_Bank_Name="ngModel"
                 placeholder="Bank Name"
                 class="form-control input-text-css"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Bank_Name.invalid,
              'alert-warning':
                refVoucher_Bank_Name.invalid &&
                (refVoucher_Bank_Name.dirty ||
                  refVoucher_Bank_Name.touched ||
                  refVoucher_Bank_Name.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
                 name="Voucher_Bank_Name"
                 id="Voucher_Bank_Name" />
        </div>
      </ng-container>

      <ng-container *ngIf="
          voucherModel.Voucher.Voucher_Mode_of_Payment != '' &&
          voucherModel.Voucher.DRAccountId != '' &&
          voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'
        ">
        <div class="col-md-4">
          <span>Ref No</span>
          <input type="text"
                 #refVoucher_E_Ref_No="ngModel"
                 class="form-control input-text-css"
                 [ngClass]="{
              'is-invalid': f.submitted && refVoucher_E_Ref_No.invalid,
              'alert-warning':
                refVoucher_E_Ref_No.invalid &&
                (refVoucher_E_Ref_No.dirty ||
                  refVoucher_E_Ref_No.touched ||
                  refVoucher_E_Ref_No.untouched)
            }"
                 [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
                 name="Voucher_E_Ref_No"
                 id="Voucher_E_Ref_No" />
        </div>
        <div class="col-md-4">
          <span>Transaction Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="pickervetd"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="Voucher_E_Transaction_Date"
                   id="Voucher_E_Transaction_Date"
                   [max]="voucherModel.Voucher.Voucher_Date"
                   #refVoucher_E_Transaction_Date="ngModel"
                   class="form-control input-text-css"
                   [ngClass]="{
                'is-invalid':
                  f.submitted && refVoucher_E_Transaction_Date.invalid,
                'alert-warning':
                  refVoucher_E_Transaction_Date.invalid &&
                  (refVoucher_E_Transaction_Date.dirty ||
                    refVoucher_E_Transaction_Date.touched ||
                    refVoucher_E_Transaction_Date.untouched)
              }"
                   [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date" />
            <mat-datepicker-toggle matSuffix
                                   [for]="pickervetd"></mat-datepicker-toggle>
            <mat-datepicker #pickervetd></mat-datepicker>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row m-0">
      <div class="col-md-12"><hr /></div>
    </div>

    <form #df="ngForm" novalidate>
      <div class="row m-0 add-remove"
           *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index">
        <div class="col-md-12 row m-0 type-column">
          <div class="type mr_top">
            <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">Type</div>
            <input type="text"
                   name="TranType{{ i }}"
                   id="TranType{{ i }}"
                   class="form-control input-text-css"
                   readonly
                   [(ngModel)]="vdData.TranType"
                   [ngClass]="{ 'mt-1': i == 0 }" />
          </div>
          <div class="row m-0 feild">
            <ng-container *ngIf="
                voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
              ">
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Search</span>
                </div>
                <div class="input-btn">
                  <input required
                         type="text"
                         readonly
                         name="CaseNo{{ i }}"
                         id="CaseNo{{ i }}"
                         [(ngModel)]="vdData.CaseNo"
                         class="form-control input-text-css"
                         (keyup.enter)="searchCustomer(vdData, i)"
                         [ngClass]="{ 'mt-1': i == 0 }" />
                  <button type="button"
                          (click)="searchCustomer(vdData, i)"
                          class="primary-btn ml-2 search">
                    <i class="fa fa-search"
                       aria-hidden="true"
                       style="font-size: 12px"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  Customer
                </div>
                <input type="text"
                       name="Customer{{ i }}"
                       id="Customer{{ i }}"
                       class="form-control input-text-css"
                       readonly
                       [(ngModel)]="vdData.Customer"
                       [ngClass]="{ 'mt-1': i == 0 }" />
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  Branch
                </div>
                <input type="text"
                       name="Branch_Name{{ i }}"
                       id="Branch_Name{{ i }}"
                       class="form-control input-text-css"
                       readonly
                       [(ngModel)]="vdData.Branch_Name"
                       [ngClass]="{ 'mt-1': i == 0 }" />
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Category</span>
                </div>
                <select name="ChargeHeadId{{ i }}"
                        id="ChargeHeadId{{ i }}"
                        required
                        #refChargeHeadId="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refChargeHeadId.invalid,
                    'alert-warning':
                      refChargeHeadId.invalid &&
                      (refChargeHeadId.dirty ||
                        refChargeHeadId.touched ||
                        refChargeHeadId.untouched),
                    'mt-1': i == 0
                  }"
                        [(ngModel)]="vdData.ChargeHeadId"
                        class="form-control input-text-css"
                        (change)="onChangeChargesHead(vdData, i)"
                        [disabled]="!vdData.ProductId">
                  <option value="">Select Category</option>
                  <option *ngFor="let item of chargesHeadDropdown[i]"
                          [value]="item.Id">
                    {{ item.ChagesHead }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                  Account Head
                </div>
                <input #refChargeHeadCategory="ngModel"
                       type="text"
                       name="ChargeHeadCategory{{ i }}"
                       id="ChargeHeadCategory{{ i }}"
                       class="form-control input-text-css"
                       readonly
                       required
                       [(ngModel)]="vdData.ChargeHeadCategory"
                       [ngClass]="{
                    'is-invalid': df.submitted && refChargeHeadCategory.invalid,
                    'alert-warning':
                      refChargeHeadCategory.invalid &&
                      (refChargeHeadCategory.dirty ||
                        refChargeHeadCategory.touched ||
                        refChargeHeadCategory.untouched),
                    'mt-1': i == 0
                  }" />
              </div>
              <div class="col-md-2 mr_top">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Amount</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       id="Amount{{ i }}"
                       [disabled]="!vdData.ChargeHeadId"
                       appTwoDigitDecimaNumber
                       class="form-control input-text-css text-right pr-3"
                       required
                       #refAmount="ngModel"
                       [(ngModel)]="vdData.Amount"
                       (change)="onChangeAmount($event, vdData, i)"
                       (keyup.enter)="onChangeAmount($event, vdData, i)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }" />
              </div>
            </ng-container>
            <ng-container *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'">
              <div class="col-md-5 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Account Head</span>
                </div>
                <input type="search"
                       class="form-control input-text-css"
                       (keyup.enter)="onChangeChargesHead(vdData, i)"
                       (change)="onChangeChargesHead(vdData, i)"
                       [(ngModel)]="vdData.ChargeHeadCategory"
                       id="ChargeHeadId{{ i }}"
                       name="ChargeHeadId{{ i }}"
                       list="dynmicChargeHead"
                       autocomplete="off"
                       required
                       #refChargeHeadId="ngModel"
                       placeholder="Select Head"
                       [ngClass]="{
                    'is-invalid': df.submitted && refChargeHeadId.invalid,
                    'alert-warning':
                      refChargeHeadId.invalid &&
                      (refChargeHeadId.dirty ||
                        refChargeHeadId.touched ||
                        refChargeHeadId.untouched),
                    'mt-1': i == 0
                  }" />
                <datalist id="dynmicChargeHead">
                  <option *ngFor="let item of accountHeadDropdown"
                          [value]="item.Account_Name"
                          title="item.Account_Name"></option>
                </datalist>
              </div>
              <div class="col-md-5 mr_top pr-0">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Branch</span>
                </div>
                <input type="search"
                       class="form-control input-text-css"
                       [(ngModel)]="vdData.Branch_Name"
                       id="BranchId{{ i }}"
                       name="BranchId{{ i }}"
                       list="dynmicBranch"
                       autocomplete="off"
                       required
                       #refBranchId="ngModel"
                       placeholder="Select Branch"
                       (change)="onChangebranch(vdData)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refBranchId.invalid,
                    'alert-warning':
                      refBranchId.invalid &&
                      (refBranchId.dirty ||
                        refBranchId.touched ||
                        refBranchId.untouched),
                    'mt-1': i == 0
                  }" />
                <datalist id="dynmicBranch">
                  <option *ngFor="let branch of empBranchDropdown"
                          [value]="branch.Branch_Name"
                          title="branch.Branch_Name"></option>
                </datalist>
              </div>
              <div class="col-md-2 mr_top">
                <div [ngClass]="{ 'bg-success1': i == 0 }">
                  <span class="required-lable" *ngIf="i == 0">Amount</span>
                </div>
                <input type="text"
                       min="0"
                       name="Amount{{ i }}"
                       id="Amount{{ i }}"
                       [disabled]="!vdData.Branch_Name"
                       appTwoDigitDecimaNumber
                       class="form-control input-text-css text-right pr-3"
                       required
                       #refAmount="ngModel"
                       [(ngModel)]="vdData.Amount"
                       (change)="onChangeAmount($event, vdData, i)"
                       (keyup.enter)="onChangeAmount($event, vdData, i)"
                       [ngClass]="{
                    'is-invalid': df.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched),
                    'mt-1': i == 0
                  }" />
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="vdData.TaxSlab1 && vdData.TaxSlab1.length > 0">
          <div class="col-md-12 row m-0 p-0 mt-2"
               *ngFor="let taxData of vdData.TaxSlab1; let ti = index">
            <div class="col-md-12 row m-0 type-column">
              <div class="type mr_top">
                <span class="form-control1" style="width: 50px">
                  {{
                  vdData.TranType
                  }}
                </span>
              </div>
              <div class="row m-0 feild">
                <div [ngClass]="
                    voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                      ? 'col-md-5'
                      : 'col-md-4'
                  "
                     class="mr_top pr-0">
                  <span class="form-control1">{{ taxData.TaxSlab_Type }}</span>
                </div>
                <div [ngClass]="
                    voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                      ? 'col-md-5'
                      : 'col-md-2'
                  "
                     class="mr_top pr-0">
                  <span class="form-control1">{{ taxData.BranchName }}</span>
                </div>
                <div class="col-md-2 mr_top pr-0"
                     *ngIf="
                    voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                    voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                  ">
                  <span class="form-control1">
                    {{
                      taxData.TypeIGST == true
                        ? "IGST"
                        : taxData.TypeGST == true
                        ? "SGST"
                        : "CGST"
                    }}
                  </span>
                </div>
                <div class="col-md-2 text-right mr_top pr-0"
                     *ngIf="
                    voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                    voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                  ">
                  <span class="form-control1">
                    {{ taxData.TaxSlab_Rate }}%
                  </span>
                </div>
                <div class="col-md-2 mr_top">
                  <input type="text"
                         required
                         name="TaxAmount{{ i }}{{ ti }}"
                         id="TaxAmount{{ i }}{{ ti }}"
                         appTwoDigitDecimaNumber
                         [(ngModel)]="taxData.TaxAmount"
                         (change)="onChangeTax()"
                         class="form-control input-text-css text-right pr-3" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <button type="button"
                class="remove"
                (click)="removeData(i)"
                [ngClass]="{ 'no-head': i != 0 }"
                *ngIf="voucherModel.Voucher_Detail.length > 1">
          -
        </button>
        <button type="button"
                class="addmore"
                (click)="addMoreData(i)"
                [ngClass]="{
                both: voucherModel.Voucher_Detail.length>
          1,
          'no-head': i != 0
          }"
          [disabled]="!df.form.valid"
          *ngIf="i == voucherModel.Voucher_Detail.length - 1"
          >
          +
        </button>
      </div>

      <div class="row m-0">
        <div class="col-md-12"><hr /></div>
      </div>

      <div class="col-md-12 row p-1 pl-3 m-0"
           *ngIf="totalCalculator"
           style="width: 93.8%">
        <div class="col-md-12 bg-success1 p-1">
          <div class="row">
            <div class="col-md-9">Grand Total :</div>
            <div class="col-md-3 text-right">
              {{ totalCalculator.total.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row m-0 mt-2">
      <div class="col-md-12">
        <span class="">Voucher Narration</span>
        <input type="text"
               #refVoucher_Narration="ngModel"
               [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Narration.invalid,
            'alert-warning':
              refVoucher_Narration.invalid &&
              (refVoucher_Narration.dirty ||
                refVoucher_Narration.touched ||
                refVoucher_Narration.untouched)
          }"
               [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
               name="Voucher_Narration"
               id="Voucher_Narration"
               class="form-control input-text-css" />
      </div>
    </div>

    <div class="row m-0 mt-2 upload">
      <div class="col-md-10">
        Browse Doc:<i class="fa fa-upload ml-2"
                      (click)="docUFile.click()"
                      aria-hidden="true"></i>
        <ng-container *ngIf="docModal.DocumentImages.length > 0">
          <div *ngFor="let doc of docModal.DocumentImages; let i = index">
            <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ doc.DocFileName }}
            <i class="fa fa-times ml-2"
               aria-hidden="true"
               style="cursor: pointer"
               (click)="removeDFile(i)"></i>
          </div>
        </ng-container>
        <input #docUFile
               type="file"
               [multiple]="true"
               accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
               (change)="fileChangeListenerD(docUFile.files, docUFile)"
               style="display: none" />
      </div>
      <div class="col-md-2 text-right">
        <button type="button"
                class="mt-3 btn font-size-12 button-btn"
                (click)="saveVoucher()"
                [class.spinner]="loading"
                [disabled]="!f.form.valid || !df.form.valid">
          Save
        </button>
      </div>
    </div>
  </form>

 
  <div style="font-size: x-large; clear: both; text-align: center; color: red;" class="pt-5 pb-5" *ngIf="voucherAdd==false">
    <p>Access Denied</p><p> Contact Adminstrator</p>
  </div>

</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearch()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <form
            #f="ngForm"
            (keydown.enter)="Get_Acc_Search_LoanNo()"
            novalidate
            class="row m-0 col-md-12 p-0"
          >
            <div class="row m-0 col-md-12 p-0">
              <!--<div class="col-md-2" [hidden]="voucherModel.Voucher.Voucher_Sub_Type=='Partner'">
                <span>Type</span>
                <select name="Type" id="Type"  class="form-control input-text-css" [(ngModel)]="Type">
                  <option [value]="0">All</option>
                  <option [value]="1">LOS Only</option>
                  <option [value]="2">LMS Only</option>
                  <option [value]="3" *ngIf="voucherModel.Voucher.Voucher_Sub_Type=='Partner'">Partner Only</option>
                </select>
              </div>-->
              <div class="col-md-2">
                <span>Application No.</span>
                <input
                  name="SApplicationNo"
                  [(ngModel)]="Customer.ApplicationNo"
                  id="SApplicationNo"
                  placeholder="Application No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Customer Loan No.</span>
                <input
                  name="SLoanNo"
                  [(ngModel)]="Customer.LoanNo"
                  id="SLoanNo"
                  placeholder="Loan No."
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-2"
                *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Partner'"
              >
                <span>Partner Loan No.</span>
                <input
                  name="SLoanNo"
                  [(ngModel)]="Customer.PLoanNo"
                  id="PLoanNo"
                  placeholder="Partner Loan No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Customer Name</span>
                <input
                  name="CustomerName"
                  [(ngModel)]="Customer.CustomerName"
                  id="CustomerName"
                  placeholder="Customer Name"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Customer Phone</span>
                <input
                  name="CustomerPhone"
                  [(ngModel)]="Customer.CustomerPhone"
                  id="CustomerPhone"
                  placeholder="Customer Phone"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>PAN Card</span>
                <input
                  name="PANCard"
                  [(ngModel)]="Customer.PANCard"
                  id="PANCard"
                  placeholder="PAN Card"
                  class="form-control input-text-css"
                />
              </div>

              <div class="col-md-2">
                <span>Passport No.</span>
                <input
                  name="Passport"
                  [(ngModel)]="Customer.Passport"
                  id="Passport"
                  placeholder="Passport No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Voter ID Card</span>
                <input
                  name="VoterID"
                  [(ngModel)]="Customer.VoterID"
                  id="VoterID"
                  placeholder="Voter ID Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Driving License</span>
                <input
                  name="DL"
                  [(ngModel)]="Customer.DL"
                  id="DL"
                  placeholder="Driving License"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Aadhaar Card</span>
                <input
                  name="Aadhaar"
                  [(ngModel)]="Customer.Aadhaar"
                  id="Aadhaar"
                  placeholder="Aadhaar Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Vehicle No</span>
                <input
                  name="VehicleNo"
                  [(ngModel)]="Customer.VehicleNo"
                  id="VehicleNo"
                  placeholder="Vehicle No"
                  class="form-control input-text-css"
                />
              </div>
              <!--<div class="col-md-2">
                <span>Close Case</span>
                <select name="CloseCase" id="CloseCase" class="form-control input-text-css" [(ngModel)]="Customer.CloseCase">
                  <option value="">Select Close Case</option>
                  <option value="1">Close Case</option>
                </select>
              </div>-->

              <div class="col-md-2">
                <span>Date From</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker11"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="LoanDateFrom"
                    [(ngModel)]="Customer.LoanDateFrom"
                    id="LoanDateFrom"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker11"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker11></mat-datepicker>
                </div>
                <!-- <input
                  name="LoanDateFrom"
                  [(ngModel)]="Customer.LoanDateFrom"
                  id="LoanDateFrom"
                  placeholder="Date From"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-2">
                <span>Date To</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker2"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="LoanDateTo"
                    [(ngModel)]="Customer.LoanDateTo"
                    id="LoanDateTo"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
                <!-- <input
                  name="LoanDateTo"
                  [(ngModel)]="Customer.LoanDateTo"
                  id="LoanDateTo"
                  placeholder="Date To"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-3">
                <span>Select Type</span>
                <select
                  name="SelectType"
                  id="SelectType"
                  class="form-control input-text-css"
                  [(ngModel)]="SelectType"
                  (change)="SearchChange()"
                >
                  <option value="">Select Type</option>
                  <option value="Branch">Branch</option>
                  <option value="CollArea">Collection Area</option>
                  <option value="CollSubArea">Collection Sub Area</option>
                  <option value="CollExecutive">Collection Executive</option>
                  <option value="SalesExecutive">Sales Executive</option>
                  <option value="EngineNo">Engine No.</option>
                  <option value="ChassisNo">Chassis No.</option>
                  <option value="LoanAccCatg">Loan A/C Category</option>
                </select>
              </div>
              <div class="col-md-3">
                <div *ngIf="SelectType == 'Branch'">
                  <span> Branch</span>
                  <select
                    name="BranchId"
                    id="BranchId"
                    #refSelectGender="ngModel"
                    [(ngModel)]="Customer.BranchId"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Branch</option>
                    <option
                      *ngFor="let branch of BranchesList"
                      [value]="branch.BranchId"
                    >
                      {{ branch.Branch_Name }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'EngineNo'">
                  <span>Engine No.</span>
                  <input
                    name="EngineNo"
                    [(ngModel)]="Customer.EngineNo"
                    id="EngineNo"
                    placeholder="Engine No."
                    class="form-control input-text-css"
                  />
                </div>

                <div *ngIf="SelectType == 'ChassisNo'">
                  <span>Chassis No.</span>
                  <input
                    name="ChassisNo"
                    [(ngModel)]="Customer.ChassisNo"
                    id="ChassisNo"
                    placeholder="Chassis No."
                    class="form-control input-text-css"
                  />
                </div>

                <div *ngIf="SelectType == 'CollArea'">
                  <span> Collection Area</span>
                  <select
                    name="Area"
                    id="Area"
                    [(ngModel)]="Customer.Area"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection Area</option>
                    <ng-container *ngFor="let area of areaData">
                      <option
                        *ngIf="area.Type.trim() == 'Collection Area'"
                        [value]="area.Int_Id"
                      >
                        {{ area.Area_Name }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div *ngIf="SelectType == 'CollSubArea'">
                  <span> Collection Sub Area</span>
                  <select
                    name="SubArea"
                    id="SubArea"
                    [(ngModel)]="Customer.SubArea"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection SubArea</option>
                    <option
                      *ngFor="let subarea of subAreaData"
                      [value]="subarea.Int_Id"
                    >
                      {{ subarea.Sub_Area_Name }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'SalesExecutive'">
                  <span> Sales Executive</span>
                  <select
                    name="SalesEx"
                    id="SalesEx"
                    [(ngModel)]="Customer.SalesEx"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Sales Executive</option>
                    <option
                      *ngFor="let SalesExec of SalesExecutiveData"
                      [value]="SalesExec.EmpId"
                    >
                      {{ SalesExec.EmpName }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'CollExecutive'">
                  <span> Collection Executive</span>
                  <select
                    name="CollectionEx"
                    id="CollectionEx"
                    [(ngModel)]="Customer.CollectionEx"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection Executive</option>
                    <option
                      *ngFor="let CollExec of CollectionExecutiveData"
                      [value]="CollExec.EmpId"
                    >
                      {{ CollExec.Emp_FirstName }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'LoanAccCatg'">
                  <span>Accounting Category </span>
                  <select
                    name="LoanAccountingCatId"
                    id="LoanAccountingCatId"
                    [(ngModel)]="Customer.LoanAccountingCatId"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Accounting Category</option>
                    <option
                      *ngFor="let AcCat of AccCatDropdown"
                      [value]="AcCat.Loan_CategoryId"
                    >
                      {{ AcCat.Loan_category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 text-right">
                <button
                  type="button"
                  (click)="Get_Acc_Search_LoanNo()"
                  class="mt-3 btn font-size-12 button-btn"
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          <div class="col-md-12"><hr /></div>

          <form #sf="ngForm" class="col-md-12" novalidate>
            <div class="row">
              <div class="col-md-12">
                <mat-table
                  [dataSource]="dataSource"
                  #sortList="matSort"
                  matSort
                  id="exportReport"
                  style="height: 240px; max-width: 100%; overflow: auto"
                >
                  <ng-container matColumnDef="CustomerId">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw50"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell mw50"
                    >
                      {{
                        paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                      }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Type">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw50"
                      *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                      row.Type
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Application_No">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw100"
                      *matHeaderCellDef
                      >Aplication No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell mw100">
                      {{ row.Application_No }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="Customer_LoanAcNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Loan No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Customer_LoanAcNo
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="LoanAcNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Loan No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.LoanAcNo
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Partner_LoanAcNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Partner LoanAc No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.LoanAcNo
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="CustomertName">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Customer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell"
                      >{{ row.CustomertName }}({{
                        row.AC_CustomerType
                      }})</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="FatherName">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Relation Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.FatherName
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer_Gender">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw50"
                      *matHeaderCellDef
                      >Gender</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                      row.Customer_Gender
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="GenderAge">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw50"
                      *matHeaderCellDef
                    >
                      Age</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                      row.GenderAge
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Phone No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.PhoneNo }}
                      <i
                        *ngIf="row.Customer_PhoneNo_IsVerified == true"
                        class="fa fa-check-circle ml-2"
                        style="color: green"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="EditAction">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header mw100"
                      *matHeaderCellDef
                    >
                      View Detail</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell j-c-center mw100"
                    >
                      <i
                        class="fa fa-arrow-circle-right arrow-circle action-btn"
                        (click)="selectSearchData(row)"
                        aria-label="true"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
                <mat-paginator
                  #paginatorRef
                  #PaginatorList
                  [pageSizeOptions]="[20, 50]"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </form>
          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              class="btn font-size-12 button-btn"
              (click)="OnCloseSearch()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="GSTModalWindow"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Party Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseGSTModal()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #cf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 mt-1">
            <div class="col-md-4">
              <span class="form-check-inline">
                <label class="form-check-label" for="Existing Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="Existing_Party"
                    (change)="onPartyTypeChange()"
                  />
                  Existing Party
                </label>
              </span>
            </div>
            <div class="col-md-8">
              <span class="form-check-inline">
                <label class="form-check-label" for="New Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="New_Party"
                    (change)="onPartyTypeChange()"
                  />
                  New Party
                </label>
              </span>
            </div>
          </div>

          <div class="row m-0 mt-2">
            <div class="col-md-3">
              <span class="required-lable">Tax Slab</span>
            </div>
            <div class="col-md-9">
              <select
                name="Slab_Tax"
                id="Slab_Tax"
                #refSlab_Tax="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refSlab_Tax.invalid,
                  'alert-warning':
                    refSlab_Tax.invalid &&
                    (refSlab_Tax.dirty ||
                      refSlab_Tax.touched ||
                      refSlab_Tax.untouched)
                }"
                [(ngModel)]="GSTModel.Slab_Tax"
              >
                <option value="">Select Slab</option>
                <option
                  [value]="slab.TaxSlab_Name"
                  *ngFor="let slab of slabList"
                >
                  {{ slab.TaxSlab_Name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'Existing_Party' &&
              voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
            "
          >
            <div class="col-md-3"><span>Account</span></div>
            <div class="col-md-9">
              <select
                name="AccountHeadId"
                id="AccountHeadId"
                #refAccountHeadId="ngModel"
                [(ngModel)]="GSTModel.AccountHeadId"
                class="form-control input-text-css"
                (change)="onAccountHeadChange()"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let item of AccountHeadForGST"
                  [value]="item.AccountId"
                >
                  {{ item.Account_Name }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'New_Party' ||
              (GSTModel.PartyType == 'Existing_Party' &&
                (voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                  voucherModel.Voucher.Voucher_Sub_Type == 'Partner'))
            "
          >
            <div class="col-md-3"><span class="required-lable">Name</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refCustomer="ngModel"
                class="form-control input-text-css"
                placeholder="Name"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
                [(ngModel)]="GSTModel.Customer"
                name="Customer"
                id="Customer"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              />
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>GST No.</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refAcc_GSTNo="ngModel"
                class="form-control input-text-css"
                placeholder="GST No."
                [(ngModel)]="GSTModel.Acc_GSTNo"
                name="Acc_GSTNo"
                id="Acc_GSTNo"
              />
              <!--[disabled]="GSTModel.PartyType == 'Existing_Party'"-->
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>State</span></div>
            <div class="col-md-9">
              <select
                name="State"
                id="State"
                #refState="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="GSTModel.StateId"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>HSN Code</span></div>
            <div class="col-md-9">
              <input
                name="HSNCode"
                id="HSNCode"
                [(ngModel)]="GSTModel.HSNCode"
                placeholder="HSN Code"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn font-size-12 button-btn"
            (click)="OnCloseGSTModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="ml-4 mt-3 btn font-size-12 button-btn"
            (click)="saveGSTDetails()"
            [class.spinner]="loading"
            [disabled]="!cf.form.valid"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
