<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 formborder d-flex align-items-center">
  <div class="col-md-3">
    <span>Status</span>
    <select
      name="status"
      id="status"
      class="form-control input-text-css"
      (change)="LMS_Disbursment()"
      [(ngModel)]="status"
    >
      <option value="Pending">Pending</option>
      <option value="Completed">Completed</option>
    </select>
  </div>

  <div class="col-md-3 p-1">
    <span> Source Type </span>
    <select
      name="SelectSourceType"
      id="SelectSourceType"
      #refSelectSourceType="ngModel"
      (change)="Get_PartnerForDropdown()"
      [(ngModel)]="SelectSourceType"
      class="form-control input-text-css"
    >
      <option value="">Select Source Type</option>
      <option
        *ngFor="let lead of leadSourceDropdown"
        [value]="lead.Lead_Source"
      >
        {{ lead.Lead_Source }}
      </option>
    </select>
  </div>

  <div class="col-md-3 p-1">
    <div
      *ngIf="
        SelectSourceType == 'Agent' ||
        SelectSourceType == 'DSA' ||
        SelectSourceType == 'Dealer' ||
        SelectSourceType == 'Sales Executive'
      "
    >
      <span> Source Name</span>
      <select
        name="LeadSourceName"
        id="LeadSourceName"
        #refLeadSourceName="ngModel"
        [(ngModel)]="LeadSourceName"
        class="form-control input-text-css"
        (change)="LMS_Pending_Disbursment()"
      >
        <option value="">Select Lead Reference</option>
        <option
          *ngFor="let partner of partnerDropdown"
          [value]="partner.PartnerId"
        >
          {{ partner.Partner_Name }}
        </option>
      </select>
    </div>
  </div>

  <div class="col-md-3 text-right">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Ac No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Source Type
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceType }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="SourceName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Source Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceName }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="BorrowerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.BorrowerName }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Disbursment_Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Beneficiary Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Disbursment_Customer }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Bankname">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Beneficiary Bank</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Bankname }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Beneficiary Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="AccType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >A/C Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AccType }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Ac">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Account No.</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Ac }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="IFSCCode">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >IFSC Code</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.IFSCCode }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Disbursment_Amount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amt</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Disbursment_Amount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewise("Disbursment_Amount")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="DisbursmentDR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >DISB (DR)</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.DisbursmentDR }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewise("DisbursmentDR")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="DisbursmentCR">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >DISB (CR)</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.DisbursmentCR }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewise("DisbursmentCR")
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Balance">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Balance }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            totaltypewise("Balance")
          }}</mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>

        <mat-row
          (click)="highlightRow(row)"
          [class.highlight]="row.Loan_Id == selectedId"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns"
        ></mat-footer-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[10, 20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="payment_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Beneficiary Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Beneficiary Bank
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Beneficiary Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      A/C Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      IFSC Code
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DISB (DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DISB (CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>

    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.BorrowerName }}</td>
    <td>{{ x.Disbursment_Customer }}</td>
    <td>{{ x.Bankname }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.AccType }}</td>
    <td>{{ x.Ac }}</td>
    <td>{{ x.IFSCCode }}</td>
    <td>{{ x.Disbursment_Amount }}</td>
    <td>{{ x.DisbursmentDR }}</td>
    <td>{{ x.DisbursmentCR }}</td>
    <td>{{ x.Balance }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="12"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Disbursment_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("DisbursmentDR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("DisbursmentCR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
    </tr>
  </tfoot>
</table>
