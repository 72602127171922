import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, Type } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DialogService } from '../../Shared/dialog.service'
import { LmsService } from '../../_Lms/services/lms.service';
import { DepositService } from "../services/deposit.service";

@Component({
  selector: 'app-deposit-layout-tab',
  templateUrl: './deposit-layout-tab.component.html',
  styleUrls: ['./deposit-layout-tab.component.scss']
})
export class DepositLayoutTabComponent implements OnInit {
  currentUser: any; 
  showSpinner: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;
  TabDisabled: boolean = false;
  ProductSummary: any = {
    ProductId: 0, ProductCategory: '', ProductName: '', ProductShortName:''};
  PageType: any;
  Add:any;

  constructor(private route: ActivatedRoute,
    private _Route: Router, 
    private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar,
    private _userlayoutservice: userlayoutService,
    private dataSharingService: DataSharingService, private dialog: DialogService,
    private LmsService: LmsService,
    private DepositService: DepositService,
    private _EncrDecrService: EncrDecrService,
  ) {
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductSummary.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      if (this.currentTab == 0 && this.ProductSummary.ProductName!='') {
        this.currentTab = 1;
      }
      //console.log("currentTab", this.currentTab);
    });

    if (Number(this.ProductSummary.ProductId) > 0) {
      this.getProductDetail();
      this.TabDisabled = false;
    } else {
      this.TabDisabled = true;
    }
  }

  
  getActiveTab() {
    this.currentTab = 0;
  }
  getProductDetail() {
    this.DepositService.Deposit_Get_Product_Master({ ProductId: this.ProductSummary.ProductId }).subscribe((res: any) => {
      //console.log("res", res);
      this.ProductSummary = res.Item1[0];
    });

  }

  onChildAction(event: any) {
    if (event == "next") {
   
    }
  }
  onTabChange(event: any) {
    this.currentTab = event.index;
    if (Number(this.ProductSummary.ProductId) > 0) {
      this.getProductDetail();
    }
  }

}
