import { Component, Input, OnInit, Pipe } from '@angular/core';
import { constantUrl } from 'src/app/Shared/constantUrl';



@Component({
  selector: 'app-customer-subcard-info',
  templateUrl: './customer-subcard-info.component.html',
  styleUrls: ['./customer-subcard-info.component.scss']
})
export class CustomerSubcardInfoComponent implements OnInit {
@Input() customerData:any
profileUrl:any
  constructor() { }

  ngOnInit(): void {
    this.customerData.AC_CustomerType=(this.customerData.AC_CustomerType as string).replace("H","Borrower").replace("C","CoBorrower").replace("G","Guaranter")
    this.profileUrl=`${constantUrl.customerProfilePicUrl}${this.customerData.CustomerId}/${this.customerData.Customer_ProfilePic}`
  }

}
