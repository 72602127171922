import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MasterService } from "../../Shared/app.Masters.Service";
import { PartnerService } from "../services/partner.service";
import { LmsService } from "../../_Lms/services/lms.service";

declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-partner-portfolio-wise",
  templateUrl: "./partner-portfolio-wise.component.html",
  styleUrls: ["./partner-portfolio-wise.component.scss"],
})
export class PartnerPortfolioWiseComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;

  JsonData: any[] = [];
  dataSource: any;
  SlabData: any[] = [];

  chartdata: any[] = [];
  AmCharts: any;
  //Form value
  ReportType: any = "";
  SlabId: any = "";
  ToDate: any = new Date();

  //div hidden show
  SlabDropdown: boolean = false;
  DataDiv: boolean = false;
  displayedColumns: string[];
  displayedColumnsModel: string[];
  DataSourceModel: any;
  selectedId: any;
  private _MasterService;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator, static: true })
  paginatorlist: MatPaginator;
  /*@ViewChild(MatSort) set matSort(sort: MatSort) {
    if(this.sort != undefined){
      this.dataSource.sort = this.sort;
    }
  }*/
  LoanAcCategoryId: any = 0;
  AcCatgList: any = [];
  ArrTypes: Array<{ id: number; value: string }> = [
    { id: 1, value: "Branch Wise Portfolio" },
    { id: 2, value: "Product Wise Portfolio" },
    { id: 3, value: "Amount Wise Portfolio" },
    { id: 4, value: "Tenure Wise Portfolio" },
    { id: 5, value: "IRR Wise Portfolio" },
    { id: 6, value: "LTV Wise Portfolio" },
    { id: 7, value: "Gender Wise Portfolio" },
    { id: 8, value: "Religion Wise Portfolio" },
    { id: 9, value: "Caste Wise Portfolio" },
    { id: 10, value: "Marital Status Wise Portfolio" },
    { id: 11, value: "Profile Wise Portfolio" },
    { id: 11, value: "Nature of Work Wise Portfolio" },
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar,
    private _Partnerservice: PartnerService,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Portfolio Summary");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.ArrTypes;
    this.GetAccountingCat();
    //this.displayedColumnsModel = [
    //  "Id", "Application_No", "LoanAcNo", "Loan_Date", "CustomerName", "RelationName", "LoanAmount", "EMIAmount", "Loan_Tenure",
    //  "TotalEMI_NOS", "EMI_DUE_NOS", "receved_EMI", "OverDUE_EMI", "DPD_Days", "Total_OverDUE_EMI__Amount", "Total_OverDUE_Principal_Amount",
    //  "overDue_Interest_AMount", "Panel_Intrest", "Other_Dues", "Total_EMI_AMount", "Total_Principal_Amount", "Total_Interest_AMount",
    //  "EMI_DUE", "Principal_DUE", "Interest_DUE", "Total_Recv_EMI__Amount", "Total_Recv_Principal_Amount", "Total_Recv_Interest_AMount",
    //  "Balance_Principle", "Balance_Interest", "Last_Recv_Date", "Last_Recv_Amount", "Product_Name", "Branch_Name", "Area_Name", "Sub_Area_Name",
    //  "CollectionExecutive", "Customer_PhoneNo", "Address"
    //];
    this.displayedColumnsModel = [
      "PLoan_Id",
      "PLoanAcNo",
      "LMS_LoanAcNo",
      "PLoan_Date",
      "CustomerName",
      "LoanAmount",
      "EMIAmount",
      "Loan_Tenure",
      "DPD_Days",
      "OverDUE_EMI",
      "Total_OverDUE_EMI_Amount",
      "Total_OverDUE_Principal_Amount",
      "overDue_Interest_AMount",
      "Last_Recv_Date",
      "Last_Recv_Amount",
      "TotalEMI_NOS",
      "Total_EMI_AMount",
      "Total_Principal_Amount",
      "Total_Interest_AMount",
      "EMI_DUE_NOS",
      "EMI_DUE",
      "Principal_DUE",
      "Interest_DUE",
      "receved_EMI",
      "Total_Recv_EMI_Amount",
      "Total_Recv_Principal_Amount",
      "Total_Recv_Interest_AMount",
      "current_Due_EMI_No",
      "current_Due_Amount",
      "Balance_Principle",
      "Balance_Interest",
      "Other_Charges",
      "Penal_Interest",
      "EMI_Frequency",
      "current_Due_Date",
      "Customer_PhoneNo",
      "Address",
    ];
  }

  onChangeReportTypes() {
    this.SlabDropdown = false;
    this.SlabId = "";

    switch (this.ReportType) {
      case "3":
        this.SlabDropdown = true;
        this.getSlabsList("Amount");
        break;
      case "4":
        this.SlabDropdown = true;
        this.getSlabsList("Tenure");
        break;
      case "5":
        this.SlabDropdown = true;
        this.getSlabsList("IRR");
        break;
      case "6":
        this.SlabDropdown = true;
        this.getSlabsList("LTV");
        break;
    }
  }

  onSearchBusinessData() {
    switch (this.ReportType) {
      case "1":
        this.getReportBranchWisePortfolio();
        break;
      case "2":
        this.getReportProductWisePortfolio();
        break;
      case "3":
        this.getReportAmountWisePortfolio();
        break;
      case "4":
        this.getReportTenureWisePortfolio();
        break;
      case "5":
        this.getReportIRRWisePortfolio();
        break;
      case "6":
        this.getReportLTVWisePortfolio();
        break;
      case "7":
        this.getReportGenderWisePortfolio();
        break;
      case "8":
        this.getReportReligionWisePortfolio();
        break;

      case "9":
        this.getReportCasteWisePortfolio();
        break;
      case "10":
        this.getReportMaritalStatusWisePortfolio();
        break;
      case "11":
        this.getReportProfileWisePortfolio();
        break;
      case "12":
        this.getReportNatureOfWorkWisePortfolio();
        break;
    }
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
      });
  }

  getReportBranchWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Branch_Name",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_BranchWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
          this.generateChartData(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportProductWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Product_Name",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_ProductWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportAmountWisePortfolio() {
    this.showSpinner = true;
    //console.log(this.SlabId);
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_Amount_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportTenureWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_Tenure_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportIRRWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_IRR_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }
  getReportLTVWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Report_LTV_WisePortfolio({
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportGenderWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Gender",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report_Gender_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportReligionWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Religion",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report_Religion_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportCasteWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Cast",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report_Caste_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportMaritalStatusWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "MaritalStatus",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report__Marital_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportProfileWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Customer_Profile",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report_Profile_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportNatureOfWorkWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Natureofwork",
      "NoOfLoans",
      "POS_OS",
      "IN_Percentage",
      "Action",
    ];
    this._Partnerservice
      .Partner_Report_Natureofwork_WisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        LoanAcCategoryId: this.LoanAcCategoryId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getDataforTable(res) {
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
    this.dataSource.sort = this.sort;
    //this.generateChartData(res);
    this.showSpinner = false;
  }

  totaltypewise(type) {
    if (
      this.dataSource != null &&
      this.dataSource != undefined &&
      this.dataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      switch (type) {
        case "NoOfLoans":
          return sum.toFixed();
          break;
        case "POS_OS":
          return sum.toFixed(2);
          break;
        case "IN_Percentage":
          return sum.toFixed() + "%";
          break;
      }
    }
    return 0;
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `portfolio-summary`,
      sheet: "portfolio-summary",
    });
  }

  goToDueListDetails(row) {
    this.showSpinner = true;
    /*let _finalData = {
      Search: {
        DueUpto: this.ToDate, LoanIds: row.LoanIds, Type: 'All', ReceiptUpTo : this.ToDate
      }
    } */
    // this.paginator.pageIndex = 0;
    let _finalData = {
      Search: {
        DueUpto: this.ToDate,
        Type: "All",
        ReceiptUpTo: this.ToDate,
        BranchId: row.BranchId,
        LoanIds: row.LoanIds,
      },
    };

    this._Partnerservice
      .Partner_Get_DueList({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          res.forEach((x) => {
            x.EMIAmount = parseFloat(x.EMIAmount).toFixed(2);
          });

          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginatorlist;
          $("#ViewDueListDetails").modal("show");
          $("#ViewDueListDetails").css("z-index", "1050");
          this.showSpinner = false;
        }
      });
  }

  onCloseDueListDetails() {
    $("#ViewDueListDetails").modal("hide");
  }

  createPieChart(chartdata) {
    let data = this.generateChartData(chartdata);
    //console.log(data);
    if ($("#ampiechart2").length) {
      var chart = this.AmCharts.makeChart("ampiechart2", {
        type: "pie",
        theme: "light",
        labelRadius: -65,
        labelText: "[[title]]%",
        //"labelText": "[[percents]]%",
        dataProvider: data,
        titleField: "title",
        valueField: "value",
        export: {
          enabled: false,
        },
        color: "#fff",
      });
    }
  }

  generateChartData(data) {
    var chartData = [];
    for (var i = 0; i < data.length; i++) {
      chartData.push({
        title: data[i].Branch_Name,
        value: Number(data[i].POS_OS).toFixed(2),
      });
    }
    //console.log(chartData);
    this.chartdata = chartData;
    return chartData;
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  GetAccountingCat() {
    this.lmsService
      .Get_ACC_Category_ForDropdown_By_Type({ Type: "Co-Lending" })
      .subscribe((result) => {
        this.AcCatgList = JSON.parse(JSON.stringify(result));
      });
  }
  totaltypewiseDueList(type) {
    if (this.DataSourceModel != undefined) {
      if (this.DataSourceModel != null) {
        let sum = 0;
        for (let i = 0; i < this.DataSourceModel.filteredData.length; i++) {
          sum += Number(this.DataSourceModel.filteredData[i][type]);
        }
        return sum; //(sum > 0) ? sum.toFixed(2) : '';
      }
      return "";
    }
  }
}
