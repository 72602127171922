import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DepositService } from "../services/deposit.service";
declare var $: any;

@Component({
  selector: 'app-deposit-product-list',
  templateUrl: './deposit-product-list.component.html',
  styleUrls: ['./deposit-product-list.component.scss']
})
export class DepositProductListComponent implements OnInit {
  currentUser: any; 
  showSpinner: boolean = false;
  dataSource: any; loading: boolean = false;
  status: any = 'Active';
  ProductId: any = 0;
  ProductList: any = [];
  dialogreturn: any;
  MSGActDct: any;

  displayedColumns: string[] = [
    'ProductId', 'ProductCategory', 'ProductName', 'ProductShortName', 'Amount_Min', 'Amount_Max', 'Tenure_Min', 'Tenure_Max', 
    'Interest_Rate_Min', 'Interest_Rate_Max', 'Interest_Type', 'Cummulative_Frequency', 'Payment_Frequency', 'IsPreMature', 
    'Lockin_Period', 'Action'
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private dataSharingService: DataSharingService, 
    private router: Router, 
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService, 
    public snackBar: MatSnackBar, 
    private MasterService: MasterService,
    private _DepositService: DepositService
    ) {
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit Product List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.getProductList();
  }

  changeFilter() {
    let _filterData = [];
    if (this.status == 'Active') {
      _filterData = this.ProductList.filter((obj: any) => { return obj.IsActive });
      this.MSGActDct = 'Deactivate this Product';
    }
    else {
      _filterData = this.ProductList.filter((obj: any) => { return !obj.IsActive });
      this.MSGActDct = 'Activate this Product';
    }
    
    if (_filterData) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(_filterData)));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    this.showSpinner = false;
  }

  getProductList() {
    this.showSpinner = true;
    this._DepositService.Deposit_Get_Product_Master({ProductId : this.ProductId}).subscribe((res : any) => {
      console.log("res", res);
      this.ProductList = res.Item1;
      this.showSpinner = false;
      this.changeFilter();
    });
  }

  OnAddNewProduct() {
    this.router.navigate([`deposit-dashboard/Product/`]);
  }

  onActiveDeactiveProduct(row) {
    var msg = row.IsActive == false ? 'activate' : 'deactivate';
    this.dialogreturn = this.dialog.openConfirmDialog('Do you want to ' + msg + ' selected Product ? ' + row.ProductName);

    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this._DepositService.Deposit_Active_Deactive_Product_Master({ ProductId : this.ProductId, Active: 0 }).subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            this.getProductList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  goToProductDetail(row) {
    this.router.navigate([`deposit-dashboard/Product/${this._EncrDecrService.encrypt(encodeURIComponent(row.ProductId))}`]);
  }
}
