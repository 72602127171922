<form #f="ngForm" (ngSubmit)="onSaveCourier()" novalidate>
  <div class="d-flex align-items-center justify-content-between formborder">
    <div class="col-md-6">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 d-flex flex-row justify-content-end">
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="onAddNdwCourier()"
      >
        <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
      </button>
    </div>
  </div>
</form>
<div class="mt-2">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-1">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Courierid">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CourierCompanyName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Company Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CourierCompanyName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CourierComapnyAddress">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Comapny Address
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CourierComapnyAddress }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CourierCompanyPhoneNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 120px; padding-left: 10px"
          *matHeaderCellDef
        >
          Phone No
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 120px; padding-left: 10px"
        >
          {{ row.CourierCompanyPhoneNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnEditCourierData(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewCourierModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveCourier()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ TitleName }} Courier Company
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Company Name: </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refCourierCompanyName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCourierCompanyName.invalid,
                  'alert-warning':
                    refCourierCompanyName.invalid &&
                    (refCourierCompanyName.dirty ||
                      refCourierCompanyName.touched ||
                      refCourierCompanyName.untouched)
                }"
                [(ngModel)]="CourierCompanyName"
                placeholder="Company Name"
                name="CourierCompanyName"
                id="CourierCompanyName"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Company Address: </span>
            </div>
            <div class="col-md-8 p-1">
              <textarea
                required
                type="text"
                #refCourierComapnyAddress="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCourierComapnyAddress.invalid,
                  'alert-warning':
                    refCourierComapnyAddress.invalid &&
                    (refCourierComapnyAddress.dirty ||
                      refCourierComapnyAddress.touched ||
                      refCourierComapnyAddress.untouched)
                }"
                [(ngModel)]="CourierComapnyAddress"
                placeholder="Address"
                name="CourierComapnyAddress"
                id="CourierComapnyAddress"
                class="form-control input-text-css"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Phone No: </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                maxlength="11"
                type="text"
                #refCourierCompanyPhoneNo="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCourierCompanyPhoneNo.invalid,
                  'alert-warning':
                    refCourierCompanyPhoneNo.invalid &&
                    (refCourierCompanyPhoneNo.dirty ||
                      refCourierCompanyPhoneNo.touched ||
                      refCourierCompanyPhoneNo.untouched)
                }"
                [(ngModel)]="CourierCompanyPhoneNo"
                placeholder="Phone No"
                name="CourierCompanyPhoneNo"
                id="CourierCompanyPhoneNo"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveCourier()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
