<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-2 mb-2">
    <div class="col-md-6">
      <button
        class="btn btn-outline-green btn-lg btn-block"
        [ngClass]="{ active: tab == 'Notes' }"
        (click)="changeTab('Notes')"
      >
        Notes
      </button>
    </div>
    <div class="col-md-6">
      <button
        class="btn btn-outline-green btn-lg btn-block"
        [ngClass]="{ active: tab == 'Chat' }"
        (click)="changeTab('Chat')"
      >
        Chat
      </button>
    </div>
  </div>
  <div *ngIf="tab == 'Notes'">
    <div [class.FixHeight]="PageAcess && PageAcess[0].View == 0">
      <div class="mt-3" *ngIf="PageAcess && PageAcess[0].View == 1">
        <div class="">
          <div class="table-responsive mt-1">
            <mat-table
              [dataSource]="dataSource"
              matSort
              style="height: 250px; max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell mw50"
                  >{{ i + 1 }}</mat-cell
                >
              </ng-container>
              <ng-container matColumnDef="Type">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Type
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Remarks">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Remarks</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Remarks
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Executive">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Created By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.CreatedBy
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="CreatedOn">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Created On</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.CreatedOn
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Title">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Title</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Title
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Date">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >FollowUp Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Date
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="FollowUpBy">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Followup By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.FollowupBy_Name
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="CompletedOn">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Completed On</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.CompletedOn
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Status">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Status</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Status
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="View">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Action</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <!--<i class="fa fa-pencil-alt font-size-12 mr-2" (click)="onEditNotesRecom(row)" title="Edit This" style="cursor: pointer;"
      aria-label="true" *ngIf="PageAcess[0].Edit == 1"></i>-->
                  <i
                    class="fa fa-arrow-circle-right font-size-12 mr-2"
                    (click)="onEditNotesRecom(row)"
                    title=""
                    style="cursor: pointer"
                    aria-label="true"
                    *ngIf="row.Type != 'Notes' && !loanSummary.CloseDate"
                  ></i>
                  <i
                    class="fa fa-times ml-2"
                    aria-hidden="true"
                    style="cursor: pointer"
                    *ngIf="
                      PageAcess &&
                      PageAcess[0].Delete == 1 &&
                      row.Type == 'Notes' &&
                      !loanSummary.CloseDate
                    "
                    (click)="onDeleteNote(row)"
                  ></i>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0 mt-2">
      <div class="col-md-9">
        <div *ngIf="PageAcess && PageAcess[0].View == 1">
          <button
            type="button"
            class="mt-2 btn font-size-12 button-btn"
            (click)="addNewNote()"
            *ngIf="PageAcess && PageAcess[0].Add == 1 && !loanSummary.CloseDate"
          >
            Add New
          </button>
          <!--<button type="button" (click)="ViewAll()" mat-raised-button class="btn_default ml-3">
            View All
          </button>-->
          <button
            class="mt-2 btn font-size-12 button-btn ml-3"
            (click)="exportExcel()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>

      <div class="col-md-3 text-right">
        <button
          type="button"
          (click)="onNextStep()"
          class="mt-2 btn font-size-12 button-btn"
          [class.spinner]="loading"
        >
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="tab == 'Chat'">
    <div class="col-md-12">
      <div class="table-responsive mt-1">
        <mat-table
          [dataSource]="dataSourceChat"
          style="height: 250px; max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
              >{{ i + 1 }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="Sender">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Sender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Sender
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="SendDate">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Send Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.SendDate
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Receiver">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Receiver</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Receiver
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="DateRead">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Date Read</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.DateRead
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="MSG" style="min-width: 100px">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Message</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div style="text-align: justify; text-justify: inter-word">
                {{ row.MSG }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Document_Attachment">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              ><i class="fa fa-paperclip"></i
            ></mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="!!row.Document_Attachment">
                <a
                  target="_blank"
                  href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                    row.Document_Attachment
                  }}"
                >
                  <i class="fa fa-eye btn"></i>
                </a>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedTableColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedTableColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="noteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Loan Note Recommendation
        </h6>
        <button
          type="button"
          (click)="OnNoteClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1" *ngIf="noteModel">
          <form
            #df="ngForm"
            class="row m-0 col-md-12 p-0 formborder"
            style="width: 100%"
            (ngSubmit)="onSaveNote()"
            novalidate
          >
            <div class="row m-0 col-md-12 p-0" *ngIf="TableId == 0">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Type</span>
                <select
                  name="Type"
                  id="Type"
                  [(ngModel)]="noteModel.Type"
                  [disabled]="TableId > 0"
                  (change)="ChangeType()"
                  class="form-control input-text-css"
                >
                  <option value="Notes">Notes</option>
                  <option value="Collection">Collection Follow-Up</option>
                  <option value="Follow-Up">Other Follow-Up</option>
                  <option value="Customer Request">Customer Request</option>
                  <!--<option value="Internal Request">Internal Request </option>-->
                  <option value="Customer Task">Customer Task</option>
                  <!--<option value="Internal Task">Internal Task </option>-->
                </select>
              </div>
              <div class="col-md-4 p-1" *ngIf="noteModel.Type != 'Notes'">
                <span> Select Department</span>
                <select
                  name="Department"
                  id="Department"
                  [disabled]="noteModel.Type == 'Collection' || TableId > 0"
                  required
                  #refDepartment="ngModel"
                  (change)="Get_Employee_ByBranch_Department()"
                  [(ngModel)]="noteModel.Department"
                  placeholder="Department"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDepartment.invalid,
                    'alert-warning':
                      refDepartment.invalid &&
                      (refDepartment.dirty ||
                        refDepartment.touched ||
                        refDepartment.untouched)
                  }"
                  class="form-control input-text-css"
                >
                  <option value="0">Select Department</option>
                  <option
                    *ngFor="let cat of DepartmentList"
                    [value]="cat.DeptId"
                  >
                    {{ cat.Dept_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Title</span>
                <input
                  type="text"
                  #refDocTitle="ngModel"
                  [disabled]="TableId > 0"
                  *ngIf="noteModel.Type != 'Customer Request'"
                  [(ngModel)]="noteModel.Title"
                  placeholder="Title"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                  name="DocTitle"
                  id="DocTitle"
                  class="form-control input-text-css"
                  required
                />

                <select
                  name="Title"
                  id="Title"
                  #refTitle="ngModel"
                  required
                  *ngIf="noteModel.Type == 'Customer Request'"
                  [(ngModel)]="noteModel.Title"
                  placeholder="Title"
                  [ngClass]="{
                    'is-invalid': df.submitted && refTitle.invalid,
                    'alert-warning':
                      refTitle.invalid &&
                      (refTitle.dirty || refTitle.touched || refTitle.untouched)
                  }"
                  class="form-control input-text-css"
                >
                  <option value="">Select Request</option>
                  <option *ngFor="let cat of RequestList" [value]="cat.Value">
                    {{ cat.Value }}
                  </option>
                </select>
              </div>

              <div class="col-md-4 p-1" *ngIf="noteModel.Type != 'Notes'">
                <span>Priority</span>
                <select
                  name="Priority"
                  id="Priority"
                  required
                  [disabled]="TableId > 0"
                  [(ngModel)]="noteModel.Priority"
                  #refPriority="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refPriority.invalid,
                    'alert-warning':
                      refPriority.invalid &&
                      (refPriority.dirty ||
                        refPriority.touched ||
                        refPriority.untouched)
                  }"
                  class="form-control input-text-css"
                >
                  <option value="">Select Priority</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                  <option value="Urgent">Urgent</option>
                </select>
              </div>
              <div class="col-md-4 p-1" *ngIf="noteModel.Type == 'Collection'">
                <span class="required-lable"> Followup By</span>
                <input
                  type="text"
                  disabled
                  [(ngModel)]="loanSummary.CollectionExecutiveName"
                  placeholder="Title"
                  name="CollectionExecutiveName1"
                  id="CollectionExecutiveName1"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-4 p-1"
                *ngIf="
                  noteModel.Type != 'Notes' && noteModel.Type != 'Collection'
                "
              >
                <span> Followup By </span>
                <select
                  name="FollowupBy"
                  id="FollowupBy"
                  [disabled]="TableId > 0"
                  required
                  [(ngModel)]="noteModel.FollowupBy"
                  #refFollowupBy="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refFollowupBy.invalid,
                    'alert-warning':
                      refFollowupBy.invalid &&
                      (refFollowupBy.dirty ||
                        refFollowupBy.touched ||
                        refFollowupBy.untouched)
                  }"
                  class="form-control input-text-css"
                >
                  <option value="">Followup By</option>
                  <option
                    *ngFor="let cat of FollowupByList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-4 p-1"
                *ngIf="
                  noteModel.Type == 'Follow-Up' ||
                  noteModel.Type == 'Collection'
                "
              >
                <span class="">Followup Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    [disabled]="TableId > 0"
                    [min]="today"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_Date"
                    id="Voucher_Date"
                    #refVoucher_Date="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refVoucher_Date.invalid,
                      'alert-warning':
                        refVoucher_Date.invalid &&
                        (refVoucher_Date.dirty ||
                          refVoucher_Date.touched ||
                          refVoucher_Date.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="noteModel.FollowupDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>

              <div class="col-md-12 p-1">
                <span> Remark </span>
                <textarea
                  rows="3"
                  #refRemark="ngModel"
                  [disabled]="TableId > 0"
                  [(ngModel)]="noteModel.Remark"
                  placeholder=" Remark "
                  [ngClass]="{
                    'is-invalid': df.submitted && refRemark.invalid,
                    'alert-warning':
                      refRemark.invalid &&
                      (refRemark.dirty ||
                        refRemark.touched ||
                        refRemark.untouched)
                  }"
                  name="Remark"
                  id="Remark"
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </div>

            <div
              *ngIf="AllData.length > 0 && AllData[0].Status == 'Completed'"
              class="row m-0 col-md-12 p-0 ng-star-inserted"
            >
              <ng-container>
                <div
                  class="row m-0 h-b w-b align-items-center justify-content-between"
                  style="width: 100%"
                >
                  <h1 class="fs-12 m-0">
                    <b style="color: #000000 !important">
                      {{ AllData[0].Status }}
                      {{
                        AllData[0].Priority
                          ? ""
                          : "(" + AllData[0].Priority + ")"
                      }}</b
                    >
                  </h1>
                </div>
                <div class="row m-0 col-md-12 p-0 ng-star-inserted">
                  <div class="col-md-2">
                    <h6 class="fs-12">Type</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].Type }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Title</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].Title }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Created By</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].CreatedBy }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Created On</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].CreatedOn }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Completed By</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].FollowupBy_Name }}
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    *ngIf="
                      AllData[0].Type == 'Follow-Up' ||
                      AllData[0].Type == 'Collection'
                    "
                  >
                    <h6 class="fs-12">Followup Date</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].FollowupDate }}
                    </p>
                  </div>

                  <div
                    class="col-md-2"
                    *ngIf="AllData[0].Status == 'Completed'"
                  >
                    <h6 class="fs-12">Completed On</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].CompletedOn }}
                    </p>
                  </div>

                  <div
                    class="col-md-2"
                    *ngIf="AllData[0].Status == 'Completed'"
                  >
                    <h6 class="fs-12">Completed Remark</h6>
                    <p class="lead fs-12 fw-7">
                      {{ AllData[0].Completed_Remark }}
                    </p>
                  </div>
                </div>
                <div class="row m-0 col-md-12 p-0 ng-star-inserted">
                  <div
                    class="row mt-2"
                    *ngIf="AllData[0].Status == 'Completed'"
                  >
                    <div
                      class="col-md-12 mt-2 upload"
                      *ngIf="Attachment.length > 0"
                    >
                      <b> Attachments: </b>

                      <div
                        class="row m-0 mt-2 align-items-center"
                        *ngFor="let report of Attachment; let i = index"
                      >
                        <div class="col-md-1 pl-0">
                          {{ i + 1 }}
                        </div>
                        <div class="col-md-3">
                          <p class="lead fs-12">{{ report.Title }}</p>
                        </div>
                        <div class="col-md-3">
                          <p class="m-0 row">
                            <a
                              href="{{ OthersDocUrl }}/{{ report.DocFileName }}"
                              target="_blank"
                              *ngIf="report.DocFileName"
                              >{{ report.DocFileName }}</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row m-0 col-md-12 p-0 ng-star-inserted">
              <ng-container *ngFor="let data of AllData">
                <div
                  class="row m-0 h-b w-b align-items-center justify-content-between"
                  style="width: 100%"
                >
                  <h1 class="fs-12 m-0">
                    <b style="color: #000000 !important"
                      >{{ data.Process }}
                      {{
                        data.Priority == null
                          ? ""
                          : data.Priority == ""
                          ? ""
                          : "(" + data.Priority + ")"
                      }}</b
                    >
                  </h1>
                </div>
                <div class="row m-0 col-md-12 p-0 ng-star-inserted">
                  <div class="col-md-2">
                    <h6 class="fs-12">Type</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.Type }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Title</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.Title }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Created By</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.CreatedBy }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Created On</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.CreatedOn }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <h6 class="fs-12">Followup By</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.FollowupBy_Name }}
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    *ngIf="
                      data.Type == 'Follow-Up' || data.Type == 'Collection'
                    "
                  >
                    <h6 class="fs-12">Followup Date</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.FollowupDate }}
                    </p>
                  </div>
                  <div class="col-md-2" *ngIf="data.Status == 'Forwarded'">
                    <h6 class="fs-12">Forwarded On</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.CompletedOn }}
                    </p>
                  </div>

                  <div class="col-md-2">
                    <h6 class="fs-12">Remarks</h6>
                    <p class="lead fs-12 fw-7">
                      {{ data.Remarks }}
                    </p>
                  </div>
                </div>
              </ng-container>
            </div>
            <!--<div class="row m-0 mt-3" *ngIf="TableId> 0">
              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Type</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.Type }}
              </div>
              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Title</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.Title }}
              </div>
              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Remark</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.Remarks }}
              </div>
              <div class="col-md-4 p-1 border-div">
                <span class="bold"> Followup By</span>
              </div>
              <div class="col-md-8 p-1 border-div">
                {{ ActionData.FollowupBy_Name }}
              </div>
              <div class="col-md-4 p-1 border-div" *ngIf="noteModel.Type=='Follow-Up' || noteModel.Type=='Collection'">
                <span class="bold"> Follow-up Date</span>
              </div>
              <div class="col-md-8 p-1 border-div" *ngIf="noteModel.Type=='Follow-Up' || noteModel.Type=='Collection'">
                {{ ActionData.Date }}
              </div>

            </div>-->
            <div class="row m-0 mt-3" *ngIf="TableId > 0 && IsForword">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Type</span>
                <select
                  name="updateType"
                  id="updateType"
                  [(ngModel)]="updateModel.Type"
                  (change)="Get_FolluwuBy_Department()"
                  class="form-control input-text-css"
                  required
                  #refupdate="ngModel"
                  placeholder="Department"
                  [ngClass]="{
                    'is-invalid': df.submitted && refupdate.invalid,
                    'alert-warning':
                      refupdate.invalid &&
                      (refupdate.dirty ||
                        refupdate.touched ||
                        refupdate.untouched)
                  }"
                >
                  <option
                    *ngIf="
                      noteModel.Type == 'Follow-Up' ||
                      noteModel.Type == 'Collection'
                    "
                    value="Collection"
                  >
                    Collection Follow-Up
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Follow-Up' ||
                      noteModel.Type == 'Collection'
                    "
                    value="Follow-Up"
                  >
                    Other Follow-Up
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Customer Task' ||
                      noteModel.Type == 'Customer Request'
                    "
                    value="Customer Request"
                  >
                    Customer Request
                  </option>
                  <option
                    *ngIf="
                      noteModel.Type == 'Customer Task' ||
                      noteModel.Type == 'Customer Request'
                    "
                    value="Customer Task"
                  >
                    Customer Task
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span> Select Department</span>
                <select
                  name="updateDepartment"
                  id="updateDepartment"
                  disabled
                  [(ngModel)]="noteModel.Department"
                  placeholder="Department"
                  class="form-control input-text-css"
                >
                  <option value="0">Select Department</option>
                  <option
                    *ngFor="let cat of DepartmentList"
                    [value]="cat.DeptId"
                  >
                    {{ cat.Dept_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"> Title</span>
                <input
                  type="text"
                  disabled
                  [(ngModel)]="noteModel.Title"
                  placeholder="Title"
                  name="updateDocTitle"
                  id="updateDocTitle"
                  class="form-control input-text-css"
                />
              </div>

              <div
                class="col-md-4 p-1"
                *ngIf="updateModel.Type == 'Collection'"
              >
                <span class="required-lable"> Followup By</span>
                <input
                  type="text"
                  disabled
                  [(ngModel)]="loanSummary.CollectionExecutiveName"
                  placeholder="Title"
                  name="CollectionExecutiveName"
                  id="CollectionExecutiveName"
                  class="form-control input-text-css"
                />
              </div>
              <div
                class="col-md-4 p-1"
                *ngIf="
                  updateModel.Type != 'Notes' &&
                  updateModel.Type != 'Collection'
                "
              >
                <span> Followup By </span>
                <select
                  name="updateFollowupBy"
                  id="updateFollowupBy"
                  required
                  [(ngModel)]="updateModel.FollowupBy"
                  #refupdateFollowupBy="ngModel"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': df.submitted && refupdateFollowupBy.invalid,
                    'alert-warning':
                      refupdateFollowupBy.invalid &&
                      (refupdateFollowupBy.dirty ||
                        refupdateFollowupBy.touched ||
                        refupdateFollowupBy.untouched)
                  }"
                >
                  <option value="">Followup By</option>
                  <option
                    *ngFor="let cat of FollowupByList"
                    [value]="cat.EmpId"
                  >
                    {{ cat.EmpName }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-4 p-1"
                *ngIf="
                  noteModel.Type == 'Follow-Up' ||
                  noteModel.Type == 'Collection'
                "
              >
                <span class="">Followup Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [min]="today"
                    name="updateFollowupDate"
                    id="updateFollowupDate"
                    #refupdateFollowupDate="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        df.submitted && refupdateFollowupDate.invalid,
                      'alert-warning':
                        refupdateFollowupDate.invalid &&
                        (refupdateFollowupDate.dirty ||
                          refupdateFollowupDate.touched ||
                          refupdateFollowupDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="updateModel.FollowupDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>

              <div class="col-md-12 p-1">
                <span> Remark </span>
                <textarea
                  rows="3"
                  #refRemark="ngModel"
                  [(ngModel)]="updateModel.Remark"
                  placeholder=" Remark "
                  [ngClass]="{
                    'is-invalid': df.submitted && refRemark.invalid,
                    'alert-warning':
                      refRemark.invalid &&
                      (refRemark.dirty ||
                        refRemark.touched ||
                        refRemark.untouched)
                  }"
                  name="Remark"
                  id="Remark"
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="OnNoteClose()"
                class="mt-2 btn font-size-12 button-btn mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onCompleted()"
                class="mr-3 mt-2 btn font-size-12 button-btn"
                [class.spinner]="loading"
                *ngIf="
                  TableId > 0 &&
                  !IsForword &&
                  AllData[0].Status == 'Pending' &&
                  (AllData[0].FollowupBy == currentUser.userId ||
                    AllData[0].CreatedById == currentUser.userId)
                "
              >
                Complete
              </button>
              <button
                type="button"
                (click)="onForworded()"
                class="mr-3 mt-2 btn font-size-12 button-btn"
                [class.spinner]="loading"
                *ngIf="
                  TableId > 0 &&
                  !IsForword &&
                  AllData[0].Status == 'Pending' &&
                  (AllData[0].FollowupBy == currentUser.userId ||
                    AllData[0].CreatedById == currentUser.userId)
                "
              >
                Forward
              </button>
              <button
                type="button"
                (click)="onSaveForworded()"
                class="mr-3 mt-2 btn font-size-12 button-btn"
                [disabled]="!df.form.valid"
                [class.spinner]="loading"
                *ngIf="TableId > 0 && IsForword"
              >
                Forward
              </button>
              <button
                type="button"
                (click)="onSaveNote()"
                class="mt-2 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!df.form.valid"
                *ngIf="TableId == undefined || TableId == 0"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="CompletedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          CRM Task & Request
        </h6>
        <button
          type="button"
          (click)="OnNoteClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1">
          <ng-container>
            <form
              #df="ngForm"
              class="row m-0 col-md-12 p-0"
              (ngSubmit)="OnCompletedDone()"
              class="formnborder"
            >
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Remark</span>
                  <textarea
                    rows="3"
                    #RefCompletedRemark="ngModel"
                    required
                    [(ngModel)]="CompletedRemark"
                    placeholder=" Remark "
                    [ngClass]="{
                      'is-invalid': df.submitted && RefCompletedRemark.invalid,
                      'alert-warning':
                        RefCompletedRemark.invalid &&
                        (RefCompletedRemark.dirty ||
                          RefCompletedRemark.touched ||
                          RefCompletedRemark.untouched)
                    }"
                    name="CompletedRemark"
                    id="CompletedRemark"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
                <div class="col-md-12 mt-2 upload">
                  <B> Upload Docs/Images </B>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="
                      fileChangeListenerAttachment(docFile.files, docFile)
                    "
                    style="display: none"
                  />
                  <div
                    class="row m-0 mt-2 align-items-center"
                    *ngFor="let report of Attachment; let i = index"
                  >
                    <div class="col-md-1 pl-0">
                      {{ i + 1 }}
                    </div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        placeholder="Title"
                        name="title{{ i }}"
                        id="title{{ i }}"
                        #refTitle="ngModel"
                        [(ngModel)]="report.Title"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <p class="m-0 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!report.DocFileName"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="report.DocFileName">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ report.DocFileName }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFileAttachment(i)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
                  <button
                    type="button"
                    (click)="OnNoteClose()"
                    class="mt-2 btn font-size-12 button-btn mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    (click)="OnCompletedDone()"
                    class="mr-3 mt-2 btn font-size-12 button-btn"
                    [disabled]="!df.form.valid"
                    [class.spinner]="loading"
                  >
                    Complete
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
