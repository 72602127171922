<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-3 formborder">
    <div class="col-md-4 p-1" style="display: none;">
      <span class="required-lable">Product Category :</span>
      <select name="SelectProductCategory" id="SelectProductCategory" #refSelectProductCategory="ngModel" disabled
        [(ngModel)]="SelectProductCategory" class="form-control input-text-css" required>
        <option value=""> Select Product </option>
        <option *ngFor="let productCategory of ProductCategoryDropdown" [value]="productCategory.ProductCatId">
          {{productCategory.Product_Category}}
        </option>
      </select>
    </div>
    <div class="col-md-6 p-1">
      <span class="required-lable">Product Name :</span>
      <input required type="text" #refProductName="ngModel" name="ProductName" id="ProductName" placeholder="Product Name"
        [(ngModel)]="ProductName" class="form-control input-text-css" disabled>
    </div>
    <div class="col-md-6 p-1">
      <span class="required-lable">Product Short Name :</span>
      <input required type="text" #refProductShortName="ngModel" name="ProductShortName" id="ProductShortName" disabled
        [(ngModel)]="ProductShortName" placeholder="Product Short Name" class="form-control input-text-css">
    </div>
</div>
<!-- <div class="row m-0 mt-2 p-1 mb-1">
    <div class="col-md-12 p-1 bg-success1">Process Assignment:</div>
</div> -->
<form #f="ngForm" class="formborder mt-2" novalidate>
  <div class="row m-0">
      <div class="col-md-3 p-1">
        <span class="required-lable">Process</span>
        <select name="ProcessId" id="ProcessId" required #refProcessId="ngModel" (change)="OnChangeProcess()"
          [ngClass]="{ 'is-invalid': f.submitted && refProcessId.invalid, 'alert-warning': refProcessId.invalid  && (refProcessId.dirty || refProcessId.touched || refProcessId.untouched) }"
          [(ngModel)]="ProcessId" class="form-control input-text-css">
          <option value=""> Select Process </option>
          <option *ngFor="let item of ProcessDropdown" [value]="item.ProcessId">{{item.Process_Name}}</option>
        </select>
      </div>
  </div>
  <div >
    <ng-container *ngIf="ProcessId != ''">
      <div class="row m-0">
        <div class="col-md-4 p-1">
    
        </div>
        <div class="col-md-1 p-1">

        </div>
        <div class="col-md-3 p-1">
          <b>Optional Page</b>
        </div>
      </div>
          <div class="row m-0" *ngFor="let Process of ProcessAll; let i=index;">
            <div class="col-md-4 p-1">
              <span>{{Process.MM_Name}}</span>
            </div>
            <div class="col-md-1 p-1">
              <mat-checkbox [(ngModel)]="Process.IsChecked" id="IsChecked{{i}}" name="IsChecked{{i}}" [disabled]="Process.IsMandatory"
                            (change)="OnChangeChecked($event,Process)"></mat-checkbox>
            </div>
            <div class="col-md-3 p-1">
              <mat-checkbox [(ngModel)]="Process.IsOptionalPage" id="IsOptionalPage{{i}}" name="IsOptionalPage{{i}}" [hidden]="Process.IsMandatory" [disabled]="!Process.IsChecked"></mat-checkbox>
            </div>
          </div>
    </ng-container>
  </div>

  <ng-container *ngIf="ProcessId != ''">

    <div class="row m-0 text-right">
      <div class="col-md-12 p-1">
          <button type="button" class="btn  font-size-12 button-btn" (click)="SaveProcessAssign()" 
            [disabled]="!f.form.valid || !SaveBtn">
            Save
          </button>
      </div>
    </div>
  </ng-container>
</form>
