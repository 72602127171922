<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span> Branch: </span>
      <select
        name="FilterBranchId"
        id="FilterBranchId"
        [(ngModel)]="FilterBranchId"
        class="form-control input-text-css"
        (change)="onChangeFilterStatus()"
      >
        <option value="">All Branch</option>
        <option
          *ngFor="let filterBranchList of FilterBranchList"
          [value]="filterBranchList.BranchId"
        >
          {{ filterBranchList.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-9 p-1 text-right justify-content-end">
      <mat-form-field>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          placeholder="Search ...."
        />
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="InquiryId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 30px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 30px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ContactNumber">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Contact Number
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ContactNumber }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerAddress">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Address
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerAddress }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="HoldOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Hold On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.HoldOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="HoldReason">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Hold Reason
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.HoldReason }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"
          >₹ {{ row.LoanAmount.toFixed(2) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="InquiryNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Inquiry No.
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.InquiryNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="NextFollowUp">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Next FollowUp
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.NextFollowUp }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Purpose">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Purpose
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Purpose }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SaleExecutive">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Sale Executive
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SaleExecutive }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Source">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Source }}
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
