<section class="main-content-wrapper" style="position: relative; height: 100%">
  <header id="headerId" class="main-header">
    <div class="container-fluid">
      <div class="row align-items-center" style="background-color: #565656">
        <div class="col-md-2 p-1">
          <div class="logo-weapper">
            <div class="logo">
              <a *ngIf="loginButtonShow == '/Login'" [routerLink]="['/Home']">
                <!-- <img src="../../assets/images/logo.png" alt="logo" class="logo-width" /> -->
                <img
                  src="{{ EmployeeProfileBaseUrl }}logo.png"
                  alt="logo"
                  class="logo-width"
                />
              </a>
              <a
                *ngIf="
                  loginButtonShow == '/Home' ||
                  loginButtonShow == '/' ||
                  loginButtonShow == '/index/page'
                "
              >
                <!-- <img src="../../assets/images/logo.png" alt="logo" class="logo-width" /> -->
                <img
                  src="{{ EmployeeProfileBaseUrl }}logo.png"
                  alt="logo"
                  class="logo-width"
                />
              </a>
              <a
                *ngIf="
                  loginButtonShow != '/Home' &&
                  loginButtonShow != '/' &&
                  loginButtonShow != '/Login' &&
                  loginButtonShow != '/index/page'
                "
                [routerLink]="['/index/page']"
              >
                <!-- <img src="../../assets/images/logo.png" alt="logo" class="logo-width" /> -->
                <img
                  src="{{ EmployeeProfileBaseUrl }}logo.png"
                  alt="logo"
                  class="logo-width"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 p-1"
          [ngClass]="{
            'col-md-6': SUB_Heading,
            'col-md-8': SUB_Heading == '' || SUB_Heading == null
          }"
          style="
            text-align: center;
            background-color: #f1f3f4;
            height: 66px;
            border-radius: 100px 0 100px 0;
            border-left: 20px solid #acc4d7;
            border-right: 20px solid #acc4d7;
          "
        >
          <span class="welcome-text-font" style="font-size: 20px; color: black">
            {{ CompanyName }}
          </span>
        </div>
        <div class="col-md-2 p-1 font-size-12 blink_me" *ngIf="SUB_Heading">
          {{ SUB_Heading }}
        </div>
        <div class="col-md-2 p-1 text-right font-size-12">
          <span class="float-left mx-1" *ngIf="username != ''"
            ><app-chat-modal-sheet></app-chat-modal-sheet
          ></span>
          <span class="float-left mx-1" *ngIf="username != ''"
            ><app-emi-calculator></app-emi-calculator
          ></span>
          <a
            *ngIf="
              username == '' &&
              (loginButtonShow == '/Home' || loginButtonShow == '/')
            "
            [routerLink]="['/Login']"
            style="color: #ffffff; padding: 5px"
          >
            <button class="btn btn-success font-size-12">Login</button>
          </a>

          <a id="menu">
            <img
              *ngIf="username != ''"
              src="{{ EmployeeProfileBaseUrl }}{{ ProfilePic }}"
              alt="img"
              onerror="this.onerror=null;this.src='assets/images/default-user.png';"
              style="
                width: 40px;
                height: 40px;
                border: 1px solid #dddd;
                border-radius: 50%;
              "
            />
            <span
              *ngIf="username != ''"
              style="font-size: 12px; color: #fff; cursor: pointer"
            >
              {{ FullName }}</span
            >
            <i
              *ngIf="username != ''"
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="padding: 0px 1%; color: #ffffff; cursor: pointer"
            ></i>
          </a>

          <div id="cont" *ngIf="username != ''">
            <div id="test">
              <div class="row m-0">
                <div class="col-md-12 text-left">
                  <a
                    *ngIf="username != ''"
                    (click)="ChangePassword()"
                    class="text-white"
                  >
                    <i class="fa fa-key" aria-hidden="true"></i> Change Password
                  </a>
                </div>
                <div class="col-md-12 mt-2 text-left">
                  <a
                    *ngIf="username != ''"
                    [routerLink]="['/UserLogout']"
                    class="text-white"
                  >
                    <i class="fa fa-power-off" aria-hidden="true"></i> Logout
                  </a>
                </div>
              </div>
              <!-- <div class="d-flex align-items-center justify-content-around">
          <a *ngIf="username != ''" [routerLink]="['/UserLogout']" class="d-flex align-items-center"
            style="color: #FFFFFF;padding: 5px;">
            <i class="fa fa-power-off mr-1" aria-hidden="true" style="padding: 0px 1%;color: #FFFFFF;"></i> Logout
          </a>
          <a *ngIf="username != ''" (click)="ChangePassword()" class="d-flex align-items-center"
            style="color: #FFFFFF;padding: 5px;width: 109px;">
            <i class="fa fa-key" aria-hidden="true" style="padding: 0px 1%;color: #FFFFFF;"></i> Change Password
          </a>
        </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="common-div">
    <router-outlet></router-outlet>
  </div>
  <div
    class="row m-0 align-items-center text-align-center align-bottom"
       id="footerId" 
    style="
      background-color: #293740;
      min-height: 35px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999 !important;
     "
  >
    <div class="col-md-6 p-1" style="color: #ffffff; font-size: 12px">
      © Designed and Powered By
      <a href="http://finnaux.com" target="_blank">
        Finnaux Techsolutions Pvt. Ltd.
      </a>
      2022 All Rights Reserved
    </div>
    <!--<div class="col-md-2 p-1" style="color: #FFFFFF; font-size: 12px; margin-top: -14px;">
      <a href="../../assets/app-release.apk"  *ngIf="username != ''" style="color: #FFFFFF; font-size: 12px;"  target="_blank"
         download="app-release.apk">
        Download App
      </a>
    </div>-->
    <div class="col-md-6 p-1 text-right" style="color: #ffffff">
      <span class="mx-3" style="cursor: pointer" *ngIf="username != ''">
        <span style="display: inline-block">
          <div class="menu-text">
            <a href="javascript:void(0)" *ngIf="username != ''">
              Download Utilities
            </a>
          </div>
          <span id="Utility">
            <a
              class="m-2 dropdown-content"
              href="https://demo.finnaux.com/assets/app-release.apk"
              target="_blank"
              download="app-release.apk"
              >Download Mobile App</a
            >
            <a
              class="m-2 dropdown-content"
              href="https://demo.finnaux.com/assets/FinnauxWeb.msi"
              target="_blank"
              download="FinnauxWeb.msi"
              >Download MAC Utility</a
            >
          </span>
        </span>
      </span>
      <span class="mx-3" style="cursor: pointer"
        ><a
          href="https://www.rbi.org.in/scripts/BS_ViewNBFCNotification.aspx"
          target="_blank"
          >RBI Circular</a
        >
      </span>
      <span class="mx-3" style="cursor: pointer"> Privacy Policy </span>
      <span class="mx-3" style="cursor: pointer"> Contact Us </span>
      <span class="mx-3" style="cursor: pointer"> About Us </span>
      <span class="mx-3" style="cursor: pointer">
        <a href="https://helpdesk.finnaux.com/#/Login" target="_blank"
          >Support</a
        >
      </span>
    </div>
  </div>
</section>

<div
  class="modal fade in"
  id="ChangePasswordModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ddf="ngForm" (ngSubmit)="onUpdateChangePassword()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Change Password
          </h6>
          <button
            type="button"
            (click)="OnCloseChangePasswordModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable">Old Password</span>
              <input
                type="password"
                name="Old_Password "
                id="Old_Password "
                #refOld_Password="ngModel"
                required
                placeholder="Old Password"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refOld_Password.invalid,
                  'alert-warning':
                    refOld_Password.invalid &&
                    (refOld_Password.dirty ||
                      refOld_Password.touched ||
                      refOld_Password.untouched)
                }"
                [(ngModel)]="PasswordModel.Old_Password"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable">New Password</span>
              <input
                type="password"
                name="New_Password "
                id="New_Password "
                #refNew_Password="ngModel"
                required
                placeholder="New Password"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refNew_Password.invalid,
                  'alert-warning':
                    refNew_Password.invalid &&
                    (refNew_Password.dirty ||
                      refNew_Password.touched ||
                      refNew_Password.untouched)
                }"
                [(ngModel)]="PasswordModel.New_Password"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable">Confirm Password</span>
              <input
                type="password"
                name="Confirm_Password "
                id="Confirm_Password "
                #refConfirm_Password="ngModel"
                required
                placeholder="Confirm Password"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refConfirm_Password.invalid,
                  'alert-warning':
                    refConfirm_Password.invalid &&
                    (refConfirm_Password.dirty ||
                      refConfirm_Password.touched ||
                      refConfirm_Password.untouched)
                }"
                [(ngModel)]="PasswordModel.Confirm_Password"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseChangePasswordModal()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <!-- <button type="button" (click)="onUpdateChangePassword()"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary">
            Update
          </button> -->
          <button
            type="button"
            (click)="onUpdateChangePassword()"
            class="font-size-12 button-btn"
            [disabled]="!ddf.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
