<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div style="height: 260px; max-width: 100%; overflow: auto">
  <div class="row m-0 mt-1">
    <div class="col-md-12 mt-2" style="margin: 0 -18px">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-interval="false"
        data-wrap="false"
      >
        <div class="carousel-item active" *ngIf="customerData.length > 0">
          <div class="row m-0">
            <div
              class="col-md-3 p-1"
              *ngFor="let item of customerData.slice(0, 4); let i = index"
              (click)="OnClickFamilyMember(item)"
            >
              <div
                class="card"
                style="cursor: pointer"
                [ngClass]="{ active: item.CustomerId === CustomerId }"
              >
                <div class="card-body p-2">
                  <div class="row m-0 mb-3 align-items-center">
                    <div class="col-md-3 p-0">
                      <img
                        style="height: 45px; width: 45px"
                        src="{{ customerProfileBaseUrl }}{{
                          item.CustomerId
                        }}/{{ item.ProfilePic }}"
                        class="user-img rounded-circle img-thumbnail"
                        alt=""
                        onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                      />
                    </div>
                    <div class="col-md-9 p-0">
                      <h6 class="col-md-12 mb-2 fs-12">{{ item.Customer }}</h6>
                      <p class="col-md-12 card-subtitle fs-12 text-muted">
                        {{
                          item.CustomerType == "Hirer"
                            ? "Borrower"
                            : item.CustomerType
                        }}
                      </p>
                      <!-- <div class="row m-0">
                        <div class="col-md-12">
                          <B>Status</B> : Pending
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="row m-0 col-md-12 justify-content-center">
                    <button
                      type="button"
                      class="btn btn-info btn-sm w100 font-size-12"
                      (click)="AddCustomerMember(item)"
                    >
                      Add Member
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="customerData">
          <div class="carousel-item" *ngIf="customerData.length > 4">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(4, 8); let i = index"
                (click)="OnClickFamilyMember(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row m-0 col-md-12 justify-content-center">
                      <button
                        type="button"
                        class="btn btn-info btn-sm w100 font-size-12"
                        (click)="AddCustomerMember(item)"
                      >
                        Add Member
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="customerData.length > 8">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(8, 12); let i = index"
                (click)="OnClickFamilyMember(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row m-0 col-md-12 justify-content-center">
                      <button
                        type="button"
                        class="btn btn-info btn-sm w100 font-size-12"
                        (click)="AddCustomerMember(item)"
                      >
                        Add Member
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <a
          class="carousel-control-prev ml-2"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="prev"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="next"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>

  <div class="row mt-1 m-0" *ngIf="DivView">
    <hr />
    <div class="table-responsive">
      <mat-table [dataSource]="DataSource">
        <ng-container matColumnDef="Id">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{
            i + 1
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Member Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell"
            >{{ row.MemberName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberReletion">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Member Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberReletion }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberGenderAge">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Member Gender</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberGenderAge }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberAddress">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Member Address</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberAddress }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberOccupationType">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Member Occupation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberOccupationType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberContactNumber">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Contact Number</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberContactNumber }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MemberHowMuchEarn">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >How Much Earn</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.MemberHowMuchEarn }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            class="grid-header j-c-center"
            style="max-width: 60px"
            *matHeaderCellDef
            >Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 60px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              aria-label="true"
              (click)="EditCustomerFamilyMember(row)"
            ></i>
            <i
              class="fa fa-trash style-delete"
              (click)="Delete_CustomerMember(row)"
              style="cursor: pointer"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button
    type="button"
    (click)="CheckCustomerFamilyMember()"
    class="font-size-12 button-btn"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>

<div
  class="modal fade in"
  id="FamilyMemberData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Family Member
        </h6>
        <button
          type="button"
          (click)="onCloseFamilyMemberData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          novalidate
          (keydown.enter)="onSaveCustomerFamilyMember()"
        >
          <div class="row m-0 mt-1 formborder">
            <div class="col-md-3">
              <span class="required-lable">Select Relation</span>
              <select
                name="MemberReletion"
                [(ngModel)]="customerRefModel.MemberReletion"
                id="MemberReletion"
                class="form-control input-text-css"
                required
                #refMemberReletion="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refMemberReletion.invalid,
                  'alert-warning':
                    refMemberReletion.invalid &&
                    (refMemberReletion.dirty ||
                      refMemberReletion.touched ||
                      refMemberReletion.untouched)
                }"
              >
                <option value="">Select Relation</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Spouse">Spouse</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Uncle">Uncle</option>
                <option value="Aunty">Aunty</option>
                <option value="Grandfather">Grandfather</option>
                <option value="Grandmother">Grandmother</option>
                <option value="Father In-Law">Father In-Law</option>
                <option value="Mother In-Law">Mother In-Law</option>
                <option value="Son In-Law">Son In-Law</option>
                <option value="Daughter In-Law">Daughter In-Law</option>
                <option value="Brother In-Law">Brother In-Law</option>
                <option value="Sister In-Law">Sister In-Law</option>
                <option value="Uncle In-Law">Uncle In-Law</option>
                <option value="Aunty In-Law">Aunty In-Law</option>
                <option value="Grandfather In-Law">Grandfather In-Law</option>
                <option value="Grandmother In-Law">Grandmother In-Law</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
                <option value="None">None</option>
              </select>
            </div>
            <div class="col-md-3">
              <span class="required-lable">Member Name</span>
              <input
                required
                type="text"
                [(ngModel)]="customerRefModel.MemberName"
                #refMemberName="ngModel"
                placeholder="Member Name"
                name="MemberName"
                id="MemberName"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refMemberName.invalid,
                  'alert-warning':
                    refMemberName.invalid &&
                    (refMemberName.dirty ||
                      refMemberName.touched ||
                      refMemberName.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable">Contact No.</span>
              <input
                required
                type="text"
                numbersOnly
                maxlength="10"
                minlength="10"
                #refMemberContactNumber="ngModel"
                [(ngModel)]="customerRefModel.MemberContactNumber"
                placeholder="Mobile No"
                name="MemberContactNumber"
                id="MemberContactNumber"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refMemberContactNumber.invalid,
                  'alert-warning':
                    refMemberContactNumber.invalid &&
                    (refMemberContactNumber.dirty ||
                      refMemberContactNumber.touched ||
                      refMemberContactNumber.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable">Gender</span>
              <div class="mt-1">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="MemberGender"
                    id="MemberGender1"
                    #refMemberGender="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refMemberGender.invalid,
                      'alert-warning':
                        refMemberGender.invalid &&
                        (refMemberGender.dirty ||
                          refMemberGender.touched ||
                          refMemberGender.untouched)
                    }"
                    [(ngModel)]="customerRefModel.MemberGender"
                    required
                    value="M"
                  />
                  <label class="form-check-label" for="gender1">Male</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="MemberGender"
                    id="MemberGender2"
                    #refMemberGender="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refMemberGender.invalid,
                      'alert-warning':
                        refMemberGender.invalid &&
                        (refMemberGender.dirty ||
                          refMemberGender.touched ||
                          refMemberGender.untouched)
                    }"
                    [(ngModel)]="customerRefModel.MemberGender"
                    required
                    value="F"
                  />
                  <label class="form-check-label" for="gender2">Female</label>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <span class="required-lable">Member Address</span>
              <input
                required
                type="text"
                [(ngModel)]="customerRefModel.MemberAddress"
                #refMemberAddress="ngModel"
                placeholder="Member Address"
                name="MemberAddress"
                id="MemberAddress"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refMemberAddress.invalid,
                  'alert-warning':
                    refMemberAddress.invalid &&
                    (refMemberAddress.dirty ||
                      refMemberAddress.touched ||
                      refMemberAddress.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable">Ocupation</span>
              <input
                required
                type="text"
                [(ngModel)]="customerRefModel.MemberOccupationType"
                #refMemberOccupationType="ngModel"
                placeholder="Member Occupation"
                name="MemberOccupationType"
                id="MemberOccupationType"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refMemberOccupationType.invalid,
                  'alert-warning':
                    refMemberOccupationType.invalid &&
                    (refMemberOccupationType.dirty ||
                      refMemberOccupationType.touched ||
                      refMemberOccupationType.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable">Monthly Earn</span>
              <input
                required
                type="text"
                [(ngModel)]="customerRefModel.Member_HowMuchEarn"
                #refMember_HowMuchEarn="ngModel"
                placeholder="Monthly Earn"
                name="Member_HowMuchEarn"
                id="Member_HowMuchEarn"
                class="form-control input-text-css"
                numbersOnly
                [ngClass]="{
                  'is-invalid': f.submitted && refMember_HowMuchEarn.invalid,
                  'alert-warning':
                    refMember_HowMuchEarn.invalid &&
                    (refMember_HowMuchEarn.dirty ||
                      refMember_HowMuchEarn.touched ||
                      refMember_HowMuchEarn.untouched)
                }"
              />
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col-md-12 text-right">
              <button
                type="button"
                *ngIf="!isSaveCustomerMember"
                (click)="onSaveCustomerFamilyMember()"
                [class.spinner]="loading"
                class="mt-3 btn font-size-12 button-btn"
                [disabled]="!f.form.valid"
              >
                Save
              </button>
              <button
                type="button"
                *ngIf="isSaveCustomerMember"
                (click)="onSaveCustomerFamilyMember()"
                [class.spinner]="loading"
                class="mt-3 btn font-size-12 button-btn"
                [disabled]="!f.form.valid"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
