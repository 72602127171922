<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keydown.enter)="onSearchDueList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-2">
        <span>Due Up To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DueDateFrom"
            [(ngModel)]="DueDateFrom"
            id="DueDateFrom"
            required
            #refDueDateFrom="ngModel"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': df.submitted && refDueDateFrom.invalid,
              'alert-warning':
                refDueDateFrom.invalid &&
                (refDueDateFrom.dirty ||
                  refDueDateFrom.touched ||
                  refDueDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="DueDateFrom"
          [(ngModel)]="DueDateFrom"
          id="DueDateFrom"
          required
          #refDueDateFrom="ngModel"
          placeholder="Due Up To"
          class="form-control input-text-css"
          bsDatepicker
          [ngClass]="{
            'is-invalid': df.submitted && refDueDateFrom.invalid,
            'alert-warning':
              refDueDateFrom.invalid &&
              (refDueDateFrom.dirty ||
                refDueDateFrom.touched ||
                refDueDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span>Received Up To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="DueDateTo"
            [(ngModel)]="DueDateTo"
            id="DueDateTo"
            required
            #refDueDateTo="ngModel"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': df.submitted && refDueDateTo.invalid,
              'alert-warning':
                refDueDateTo.invalid &&
                (refDueDateTo.dirty ||
                  refDueDateTo.touched ||
                  refDueDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="DueDateTo"
          [(ngModel)]="DueDateTo"
          id="DueDateTo"
          placeholder="Received Up To"
          required
          #refDueDateTo="ngModel"
          class="form-control input-text-css"
          bsDatepicker
          [ngClass]="{
            'is-invalid': df.submitted && refDueDateTo.invalid,
            'alert-warning':
              refDueDateTo.invalid &&
              (refDueDateTo.dirty ||
                refDueDateTo.touched ||
                refDueDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span>Type</span>
        <select
          name="CheckType"
          id="CheckType"
          [(ngModel)]="CheckType"
          class="form-control input-text-css"
        >
          <option value="OD Cases">OD Cases</option>
          <option value="All">All</option>
        </select>
      </div>
      <div class="col-md-2">
        <span> Branch</span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Product</span>
        <select
          name="SelectProduct"
          id="SelectProduct"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectProduct"
          class="form-control input-text-css"
        >
          <option value="" selected>Select Product</option>
          <option
            *ngFor="let product of ProductList"
            [value]="product.ProductId"
          >
            {{ product.Product }}
          </option>
        </select>
      </div>

      <!--<div class="col-md-2 mt-1">
        <span> Select Type </span>
        <select disabled
          name="Loan_Acc_Type"
          id="Loan_Acc_Type"
          [(ngModel)]="Loan_Acc_Type"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refLoan_Acc_Type="ngModel"
        >
          <option value="">Select Type</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-2 mt-1">
        <span> Partner</span>
        <select
          name="ACCategType"
          id="ACCategType"
          [(ngModel)]="ACCategType"
          class="form-control input-text-css"
          #refACCategType="ngModel"
        >
          <option value="">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>

      <div class="col-md-2 mt-4">
        <select
          name="Search"
          id="Search"
          [(ngModel)]="Search"
          (change)="SearchChange()"
          class="form-control input-text-css"
        >
          <option value="LoanNo" selected>Loan No.</option>
          <option value="ApplicationNo">Application No.</option>
          <option value="CustomerName">Customer Name</option>
          <option value="Customer_PhoneNo">Customer Mobile No.</option>
          <option value="Branch_Name">Branch Name</option>
          <option value="Area_Name">Area Name</option>
          <option value="Sub_Area_Name">Sub Area Name</option>
          <option value="CollectionExecutive">Collection Executive</option>
        </select>
      </div>
      <div class="col-md-2 mt-3">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSearchDueList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" [hidden]="!dataSource">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px; border-right: 1px #f7f9f9 solid"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell mw50">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          style="max-width: 50px"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="PLoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Partner</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.PLoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="LMS_LoanAcNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LMS_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="PLoan_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.PLoan_Date
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.CustomerName
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >Loan Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.LoanAmount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("LoanAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMIAmount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.EMIAmount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("EMIAmount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Loan_Tenure">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Loan Tenure</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Loan_Tenure
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="DPD_Days">
        <mat-header-cell
          mat-sort-header
          class="grid-header text-right"
          *matHeaderCellDef
          >DPD</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.DPD_Days
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="OverDUE_EMI">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI No.</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.OverDUE_EMI
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_OverDUE_EMI_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_OverDUE_EMI_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_OverDUE_EMI_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_OverDUE_Principal_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_OverDUE_Principal_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{
            dataSource ? totaltypewise("Total_OverDUE_Principal_Amount") : ""
          }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="overDue_Interest_AMount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.overDue_Interest_AMount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("overDue_Interest_AMount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Last_Recv_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Last Recv Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Last_Recv_Date
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Last_Recv_Amount">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Last Recv Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Last_Recv_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Last_Recv_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="TotalEMI_NOS">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI No.</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.TotalEMI_NOS
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_EMI_AMount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amt</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_EMI_AMount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_EMI_AMount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_Principal_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_Principal_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_Principal_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_Interest_AMount">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_Interest_AMount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_Interest_AMount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMI_DUE_NOS">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI No.</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.EMI_DUE_NOS
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMI_DUE">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amt</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.EMI_DUE
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Principal_DUE">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Principal_DUE
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Principal_DUE") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Interest_DUE">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Interest_DUE
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Interest_DUE") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="receved_EMI">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI No.</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.receved_EMI
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_Recv_EMI_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_Recv_EMI_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_Recv_EMI_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_Recv_Principal_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_Recv_Principal_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_Recv_Principal_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Total_Recv_Interest_AMount">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Total_Recv_Interest_AMount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Total_Recv_Interest_AMount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="current_Due_EMI_No">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.current_Due_EMI_No
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="current_Due_Amount">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >EMI Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.current_Due_Amount
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("current_Due_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Balance_Principle">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Balance_Principle
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Balance_Principle") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Balance_Interest">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Balance_Interest
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Balance_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Other_Charges">
        <mat-header-cell
          class="grid-header text-right"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Other Dues</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Other_Charges
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Other_Charges") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Penal_Interest">
        <mat-header-cell class="grid-header text-right" *matHeaderCellDef
          >LPI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
          row.Penal_Interest
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right grid-header">
          {{ dataSource ? totaltypewise("Penal_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMI_Frequency">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI Frequency</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.EMI_Frequency
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="current_Due_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Current Due Date</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.current_Due_Date
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer Phone No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Customer_PhoneNo
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Address">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Address</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Address
        }}</mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-center">
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToLoanStatement(row)"
            aria-label="true"
          ></i>
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <!-- Top header column start here -->
      <ng-container matColumnDef="row-first">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="1"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>
      <ng-container matColumnDef="row-second">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="2"
          style="border-right: 1px #f7f9f9 solid"
        >
          Loan A/C No.
        </th>
      </ng-container>
      <ng-container matColumnDef="row-third">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="5"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>

      <ng-container matColumnDef="row-fourth">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="7"
          style="border-right: 1px #f7f9f9 solid"
        >
          OVER DUE
        </th>
      </ng-container>
      <ng-container matColumnDef="row-fifth">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="4"
          style="border-right: 1px #f7f9f9 solid"
        >
          Agreement
        </th>
      </ng-container>
      <ng-container matColumnDef="row-sixth">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="4"
          style="border-right: 1px #f7f9f9 solid"
        >
          DUES
        </th>
      </ng-container>
      <ng-container matColumnDef="row-seventh">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="4"
          style="border-right: 1px #f7f9f9 solid"
        >
          Received
        </th>
      </ng-container>
      <ng-container matColumnDef="row-eighth">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="5"
          style="border-right: 1px #f7f9f9 solid"
        >
          Balances
        </th>
      </ng-container>
      <ng-container matColumnDef="row-nineth">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="6"
        >
          &nbsp;
        </th>
      </ng-container>
      <!-- Top header column end here -->

      <!-- Top header row start here -->
      <tr
        mat-header-row
        *matHeaderRowDef="
          [
            'row-first',
            'row-second',
            'row-third',
            'row-fourth',
            'row-fifth',
            'row-sixth',
            'row-seventh',
            'row-eighth',
            'row-nineth'
          ];
          sticky: true
        "
      ></tr>
      <!-- Top header row end here -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        (click)="highlightRow(row)"
        [class.highlight]="row.PLoan_Id == selectedId"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>

    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        &nbsp;
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          class="mat-paginator-sticky"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewLoanStatementModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseLoanStatementModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>
