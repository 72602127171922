import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LegalService } from "../services/Legal.service";
import { constantUrl } from "../../Shared/constantUrl";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { LosService } from "../../_LOS/services/los.service";
import * as moment from "moment";

@Component({
  selector: "app-legal-case-info",
  templateUrl: "./legal-case-info.component.html",
  styleUrls: ["./legal-case-info.component.scss"],
})
export class LegalCaseInfoComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  IsEdit: boolean = true;
  IsUpdate: boolean = false;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @Input() LegalSummary: any = {};
  @Input() LegalInfoModel: any;

  RequestModel: RequestModel = new RequestModel();

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  CaseModel: any = {};

  StateDropdown: any = [];
  DistrictDropDown: any = [];
  CourtNameDropdown: any = [];

  ActData: any = [];
  SectionData: any = [];
  SubSectionData: any = [];
  AdvocateDropdown: any = [];
  EmployeeDropdown: any = [];
  CustomerBankDropdown: any = [];
  CustomerChqDropdown: any = [];

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,
    private router: Router,
    private _LmsService: LmsService,
    public snackBar: MatSnackBar,
    private _LegalService: LegalService,
    private _MasterService: MasterService,
    private _LosService: LosService
  ) {}

  ngOnInit(): void {
    
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Legal Info");
    console.log(" this.LegalSummary", this.LegalSummary);
    //this.LegalCaseDetailById();
    // setTimeout(() => {
    //   this.LegalCaseDetailById();
    // }, 300);
  }

  LegalCaseDetailById() {
    this._LegalService
      .Legal_Case_Detail_ById({ LegalId: this.LegalSummary.LegalId })
      .subscribe((res: any) => {
        this.LegalInfoModel = res[0];
      });
  }

  OnClickEdit() {
    this.IsEdit = false;
    this.IsUpdate = true;

    this.CaseModel = this.LegalInfoModel;
    this.CaseModel.Id = this.LegalSummary.LegalId;
    this.CaseModel.CustomerId = this.LegalSummary.Customer_Id;

    /*this.CaseModel.CourtName = this.LegalInfoModel.CourtName;
    this.CaseModel.State_Name = this.LegalInfoModel.State_Name;
    this.CaseModel.District_Name = this.LegalInfoModel.District_Name;
    this.CaseModel.Court_Name = this.LegalInfoModel.Court_Name;
    this.CaseModel.Nature = this.LegalInfoModel.Nature;*/

    this.CaseModel.Act_Name = this.LegalInfoModel.UnderACTName;
    this.CaseModel.Section_Name = this.LegalInfoModel.UnderSectionName;
    this.CaseModel.SubSection_Name = this.LegalInfoModel.UnderSubSectionName;

    this.CaseModel.Date_Of_Case_Filing = new Date(
      moment(this.LegalInfoModel.Date_Of_Case_Filing).format("MM/DD/YYYY")
    );
    this.CaseModel.Demand_Notice_Date = new Date(
      moment(this.LegalInfoModel.Demand_Notice_Date).format("MM/DD/YYYY")
    );
    // this.CaseModel.Demand_Notice_Date = new Date(
    //   moment(this.LegalInfoModel.Demand_Notice_Date).format("MM/DD/YYYY")
    // );
    this.CaseModel.Customer_Bounce_Date = new Date(
      moment(this.LegalInfoModel.Customer_Bounce_Date).format("MM/DD/YYYY")
    );

    /*this.CaseModel.Date_Of_Case_Filing = this.LegalInfoModel.Date_Of_Case_Filing;
    this.CaseModel.Case_Filed_By_Name = this.LegalInfoModel.Case_Filed_By_Name;
    this.CaseModel.Demand_Notice_Date = this.LegalInfoModel.Demand_Notice_Date;
    this.CaseModel.Demand_Notice_Doc = this.LegalInfoModel.Demand_Notice_Doc;
    this.CaseModel.Court_File_No = this.LegalInfoModel.Court_File_No;
    this.CaseModel.Power_Of_Attorney_Type = this.LegalInfoModel.Power_Of_Attorney_Type;*/

    this.CaseModel.Power_Of_Attorney =
      this.LegalInfoModel.Power_Of_Attorney_Name;

    //this.CaseModel.Power_Of_Attorney_Doc = this.LegalInfoModel.Power_Of_Attorney_Doc;

    this.CaseModel.Customer_Bank_Id =
      this.LegalInfoModel.Customer_Bank_Id || "";
    this.CaseModel.Customer_Cheque_Id =
      this.LegalInfoModel.Customer_Cheque_Id || "";

    /*this.CaseModel.Customer_Cheque_Date = this.LegalInfoModel.Customer_Cheque_Date;
    this.CaseModel.Customer_Cheque_Amount = this.LegalInfoModel.Customer_Cheque_Amount;
    this.CaseModel.Customer_Cheque_Doc = this.LegalInfoModel.Customer_Cheque_Doc;
    this.CaseModel.Customer_Bounce_Date = this.LegalInfoModel.Customer_Bounce_Date;
    this.CaseModel.Customer_Bounce_Reason = this.LegalInfoModel.Customer_Bounce_Reason;
    this.CaseModel.Customer_Bounce_Doc = this.LegalInfoModel.Customer_Bounce_Doc;
    this.CaseModel.Closer_Doc = this.LegalInfoModel.Closer_Doc;
    this.CaseModel.Remark = this.LegalInfoModel.Remark;*/

    this.getCustomerBankList();
    this.getChequeDetails();
    this.GetState();
    this.Get_PartnerForDropdown();
    this.GetEmployeeDropdown();
  }

  GetState() {
    this._MasterService.GetState().subscribe((res) => {
      this.StateDropdown = res;
      if (this.LegalInfoModel) {
        this.OnSelectState(this.LegalInfoModel);
      }
    });
  }
  OnSelectState(data: any) {
    if (this.CaseModel.State_Name) {
      this.CaseModel.StateId = this.StateDropdown.filter(
        (x) => x.State_Name == data.State_Name
      )[0].StateId;
      this.getDistrictList();
    }
  }

  getDistrictList() {
    this.RequestModel.StateId =
      this.CaseModel.StateId == "" ? 0 : parseInt(this.CaseModel.StateId);
    this._MasterService.GetDistricts(this.RequestModel).subscribe((result) => {
      this.DistrictDropDown = JSON.parse(JSON.stringify(result));
      if (this.LegalInfoModel) {
        this.OnSelectDistrict(this.LegalInfoModel);
      }
    });
  }
  OnSelectDistrict(data: any) {
    if (this.CaseModel.District_Name) {
      this.CaseModel.DistrictId = this.DistrictDropDown.filter(
        (x) => x.District_Name == data.District_Name
      )[0].DistrictId;
      this.GetCourtList();
    }
  }

  GetCourtList() {
    this._MasterService
      .Get_Court_Establishment({ Id: 0, SearchBy: "List" })
      .subscribe((res: any) => {
        this.CourtNameDropdown = res.filter(
          (x) => x.DistrictId == this.CaseModel.DistrictId
        );
        if (this.LegalInfoModel) {
          this.OnSelectCourt(this.LegalInfoModel);
        }
      });
  }
  OnSelectCourt(data: any) {
    if (this.CaseModel.Court_Name) {
      this.CaseModel.Court_EstablishmentId = this.CourtNameDropdown.filter(
        (x) => x.Court_Name == data.Court_Name
      )[0].Id;
      this.GetActDropdown();
    }
  }

  GetActDropdown() {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "ACT", Id: 0 })
      .subscribe((res: any) => {
        this.ActData = res;
        console.log("res", this.ActData);
        if (this.LegalInfoModel) {
          this.OnSelectAct(this.LegalInfoModel);
        }
      });
  }
  OnSelectAct(data: any) {
    if (this.CaseModel.Act_Name) {
      this.CaseModel.UnderACT_Id = this.ActData.filter(
        (x) => x.Short_Name == this.CaseModel.Act_Name
      )[0].Id;
      this.GetSectionDropdown(this.CaseModel.UnderACT_Id);
    }
  }

  GetSectionDropdown(Id) {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "Section", Id: Id })
      .subscribe((res: any) => {
        this.SectionData = res;
        if (this.LegalInfoModel) {
          this.OnSelectSection(this.LegalInfoModel);
        }
      });
  }
  OnSelectSection(data: any) {
    if (this.CaseModel.Section_Name) {
      this.CaseModel.UnderSectionId = this.SectionData.filter(
        (x) => x.Short_Name == this.CaseModel.Section_Name
      )[0].Id;
      this.GetSubSectionDropdown(this.CaseModel.UnderSectionId);
    }
  }

  GetSubSectionDropdown(Id) {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "SubSection", Id: Id })
      .subscribe((res: any) => {
        this.SubSectionData = res;
        if (this.LegalInfoModel) {
          this.OnSelectSubSection(this.LegalInfoModel);
        }
      });
  }
  OnSelectSubSection(data: any) {
    if (this.CaseModel.SubSection_Name) {
      this.CaseModel.UnderSub_SectionId = this.SubSectionData.filter(
        (x) => x.Short_Name == this.CaseModel.SubSection_Name
      )[0].Id;
    }
  }

  Get_PartnerForDropdown() {
    this._LosService
      .Get_PartnerForDropdown({ PartnerType: "Advocate" })
      .subscribe((res: any) => {
        this.AdvocateDropdown = res;
        if (this.LegalInfoModel) {
          this.OnSelectCaseFiledBy(this.LegalInfoModel);
        }
      });
  }
  OnSelectCaseFiledBy(data: any) {
    if (this.CaseModel.Case_Filed_By_Name) {
      this.CaseModel.Case_Filed_By = this.AdvocateDropdown.filter(
        (x) => x.PartnerName == this.CaseModel.Case_Filed_By_Name
      )[0].PartnerId;
    }
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Demand_Notice_Doc = "";
      delete data.DemandNoticeDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Demand_Notice_Doc = "";
      delete data.DemandNoticeDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Demand_Notice_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DemandNoticeDocData = reader.result
        .toString()
        .split(";base64,")
        .pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Demand_Notice_Doc = "";
    delete data.DemandNoticeDocData;
  }

  GetEmployeeDropdown() {
    this.RequestModel.BranchId = 0;
    this.RequestModel.ProcessId = 0;
    this._MasterService
      .GetEmployeeDropdown(this.RequestModel)
      .subscribe((result) => {
        this.EmployeeDropdown = result;
        if (this.LegalInfoModel) {
          this.OnSelectPowerOfAttorney(
            this.LegalInfoModel,
            this.CaseModel.Power_Of_Attorney_Type
          );
        }
      });
  }

  OnSelectPowerOfAttorney(data: any, Type: any) {
    if (this.CaseModel.Power_Of_Attorney) {
      if (Type == "Advocate") {
        this.CaseModel.Power_Of_AttorneyId = this.AdvocateDropdown.filter(
          (x) => x.Partner_Name == this.CaseModel.Power_Of_Attorney
        )[0].PartnerId;
      } else {
        this.CaseModel.Power_Of_AttorneyId = this.EmployeeDropdown.filter(
          (x) => x.EmpName == this.CaseModel.Power_Of_Attorney
        )[0].EmpId;
      }
    }
  }

  fileChangeListener1(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Power_Of_Attorney_Doc = "";
      delete data.AttornyDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Power_Of_Attorney_Doc = "";
      delete data.AttornyDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Power_Of_Attorney_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.AttornyDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile1(data: any, input: any) {
    input.value = "";
    data.Power_Of_Attorney_Doc = "";
    delete data.AttornyDocData;
  }

  getCustomerBankList() {
    this._MasterService
      .Get_BankAccount_Dropdown_For_Customer({
        Customer_Id: this.CaseModel.CustomerId,
      })
      .subscribe((res: any) => {
        this.CustomerBankDropdown = res;
      });
  }

  getChequeDetails() {
    this._LmsService
      .GetChequeDetails({ Loan_Id: this.LegalSummary.Loan_Id })
      .subscribe((res: any) => {
        this.CustomerChqDropdown = res.filter(
          (item) =>
            item.PdcType == "RPDC" ||
            (item.PdcType == "SPDC" &&
              item.CustomerId == this.CaseModel.CustomerId)
        );
      });
  }

  fileChangeListener2(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Cheque_Doc = "";
      delete data.ChqDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Cheque_Doc = "";
      delete data.ChqDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Customer_Cheque_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.ChqDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile2(data: any, input: any) {
    input.value = "";
    data.Customer_Cheque_Doc = "";
    delete data.ChqDocData;
  }

  fileChangeListener3(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Bounce_Doc = "";
      delete data.ChqBounceDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Bounce_Doc = "";
      delete data.ChqBounceDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Customer_Bounce_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.ChqBounceDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile3(data: any, input: any) {
    input.value = "";
    data.Customer_Bounce_Doc = "";
    delete data.ChqBounceDocData;
  }

  fileChangeListener4(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Closer_Doc = "";
      delete data.CloserDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Closer_Doc = "";
      delete data.CloserDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Closer_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.CloserDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile4(data: any, input: any) {
    input.value = "";
    data.Closer_Doc = "";
    delete data.CloserDocData;
  }




  fileChangeListener5(data: any, files: any) {
    console.log("func clicked");
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.SOA_Doc = "";
      delete data.SOA_DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.SOA_Doc = "";
      delete data.SOA_DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.SOA_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.SOA_DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile5(data: any, input: any) {
    input.value = "";
    data.SOA_Doc = "";
    delete data.SOA_DocData;
  }





  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  OnSaveLegalCase() {
    console.log("update func call");

    this.CaseModel.VoucherId = 0;
    this.CaseModel.LoginUserId = this.currentUser.userId;

    delete this.CaseModel.Customer;
    delete this.CaseModel.State_Name;
    delete this.CaseModel.District_Name;
    delete this.CaseModel.Court_Name;
    delete this.CaseModel.Act_Name;
    delete this.CaseModel.UnderACTName;

    delete this.CaseModel.Section_Name;
    delete this.CaseModel.UnderSectionName;

    delete this.CaseModel.SubSection_Name;
    delete this.CaseModel.UnderSubSectionName;

    delete this.CaseModel.Case_Filed_By_Name;
    delete this.CaseModel.Power_Of_Attorney;
    delete this.CaseModel.Power_Of_Attorney_Name;
    delete this.CaseModel.Case_Current_Status;

    let _data = {
      Legal_Master: this.CaseModel,
    };
    console.log("_data", _data);
    this.showSpinner = true;
    this._LegalService
      .Save_Legal_Master({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res[0].CODE == 0) {
          if (this.CaseModel.DemandNoticeDocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.Demand_Notice_Doc,
              DocData: this.CaseModel.DemandNoticeDocData,
            });
          }
          if (this.CaseModel.AttornyDocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.Power_Of_Attorney_Doc,
              DocData: this.CaseModel.AttornyDocData,
            });
          }
          if (this.CaseModel.ChqDocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.Customer_Cheque_Doc,
              DocData: this.CaseModel.ChqDocData,
            });
          }
          if (this.CaseModel.ChqBounceDocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.Customer_Bounce_Doc,
              DocData: this.CaseModel.ChqBounceDocData,
            });
          }
          if (this.CaseModel.CloserDocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.Closer_Doc,
              DocData: this.CaseModel.CloserDocData,
            });
          }

          if (this.CaseModel.SOA_DocData) {
            this.uploadDoc({
              ApplicationNo: this.LegalSummary.Application_No,
              DocName: this.CaseModel.SOA_Doc,
              DocData: this.CaseModel.SOA_DocData,
            });
          }
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.IsEdit = true;
          this.IsUpdate = false;
          this.LegalCaseDetailById();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
        this.loading = false;
      });
  }
}
