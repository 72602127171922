//#region < Imports Services and components >
import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MenusModel, newMenuModal, newMenuModalNew } from '../../_LOS/_layout/Models/MenusModel';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { catchError, tap, filter } from 'rxjs/operators';
import { SnackbarComponent } from '../../snackbar/snackbar.component'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogService } from '../../Shared/dialog.service';
declare var $: any;
import { LmsService } from '../services/lms.service';

import * as moment from 'moment';
import { ReportsService } from "../../_Reports/services/reports.service";
import { MasterService } from '../../Shared/app.Masters.Service';

//#endregion

@Component({
  selector: 'lms-app-home',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // encapsulation: ViewEncapsulation.None
  // animations: [
  //   trigger('slide', [
  //     state('up', style({ height: 0 })),
  //     state('down', style({ height: '*' })),
  //     transition('up <=> down', animate(400))
  //   ])
  // ]
})
export class LMSDashboardComponent implements OnInit {

 //#region < Variables declarations >
 private data: any;
 token: any;
 showSpinner = false;
 activeIcon: string;
 private _userservice;
 MenusModel: MenusModel = new MenusModel();
 options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
 currentDate = new Date();
 todayDate = '';
//  todayDate = this.currentDate.toLocaleDateString("en-US", this.options)
 Menuslinks: any;
 CheckLoad: any;
 username = "";
 setAutoHide: boolean = false;
 autoHide: number = 2000;
 action: boolean = false;
 actionButtonLabel: string = 'Retry';
 show: boolean = false
 plusMinus: any = '+';
 checkIn: boolean = false;
 active: any = "";
 recentActiveCheckIn: boolean = false;
 recentActive: any = "";
 lastaccess: Date;
 currentlogin: Date;
 MenuslinksNew: any[];
 mysubscription: any;
 activeroute: any; dialogreturn: any;
 liveclock: any;
 //#endregion

 currentUser: any;
 dashboardData: any[] = [];
 iconname: string ="../assets/images/lms/";

 VoucherDateFrom: any;
 VoucherDateTo: any;
 FyearId: any;
 BranchId: any;
 dataSource: any;
 ShowDisbursment: boolean = false;
 displayedColumns: string[] = [];

  gradientColors = [
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
];

 @ViewChild(MatSort) sort: MatSort;
 @ViewChild(MatPaginator) paginator: MatPaginator;

 configSuccess: MatSnackBarConfig = {
   panelClass: 'style-success',
   duration: 5000,
   horizontalPosition: 'right',
   verticalPosition: 'top'
 };
 HeaderTitle: string = '';

 //#region < Constructor AppUserLayoutComponent >
 constructor(
  private _Route: Router, 
  public snackBar: MatSnackBar, private http: HttpClient, 
  private _userlayoutservice: userlayoutService,
  private EncrDecr: EncrDecrService, private _location: Location, private dataSharingService: DataSharingService, 
  private dialog: DialogService, private lmsService: LmsService,
  private reportsService: ReportsService, private route: ActivatedRoute,
  private MasterService : MasterService,
) 
{
  this.startTime();
  this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  this.username = this.data.username;
  this.lastaccess = new Date(this.data.lastaccess + 'Z');
  this.currentlogin = this.data.currentlogin;
  this._userservice = _userlayoutservice;
  //this.Menuslinks = this._userlayoutservice.getNewMenuList(this.data.userId);
  //this.MenuslinksNew = newMenuModal[0]; //JSON.parse(sessionStorage.getItem("CheckLoad"));
  //console.log('this.MenuslinksNew', this.MenuslinksNew);
}

 startTime() {
   var today = new Date();
   var h = today.getHours();
   var m = today.getMinutes();
   var s = today.getSeconds();
   m = this.checkTime(m);
   s = this.checkTime(s);
   var session = "AM"
   if (h == 0) { h = 12 }
   if (h > 12) {
     h = h - 12;
     h = this.checkTime(h);
     session = "PM"
   }
   this.liveclock = h + ":" + m + ":" + s + " " + session;
   var t = setTimeout(() => { this.startTime() }, 500);
 }
 checkTime(i) {
   if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
   return i;
 }

 tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  if(tabChangeEvent.index==0) {
    this._Route.navigate(['/lms-customer']);
  } else if(tabChangeEvent.index==1) {
    this._Route.navigate(['/financial-detail']);
  } else if(tabChangeEvent.index==2) {
    this._Route.navigate(['/lms-account']);
  }
}

tabChangeds (tab) {
  if(tab==0) {
    this._Route.navigate(['/lms-customer']);
    return true;
  }
  if(tab==1) {
    this._Route.navigate(['/financial-detail']);
    return true;
  }
  if(tab==2) {
    this._Route.navigate(['/lms-account']);
    return true;
  }
}

 //#endregion

 //#region < onsubsubmenuclick>
 onsubsubmenuclick(e) {
   $('.nav').on('click', function (e) {
     //if ($(this).hasClass('dropdown-menu-form')) {
     e.stopPropagation();
     // }
   });
 }
 //#endregion

 //#region < onclick>
 onclick(typ) {
 }
 //#endregion

 onMenuclick() {
 }

 //#region < isLinkActive>
 public isLinkActive(url: string): boolean {
   return this._Route.url.indexOf(url) > -1;
 }

 //#endregion

 ngOnInit() {
  this.dataSharingService.HeaderTitle.next("Dashboard");
  this.dataSharingService.getHeaderTitle().subscribe(value => {
    this.HeaderTitle = value;
  });
  this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

  this.route.queryParams.subscribe(
    params => {
      this.FyearId = params['fyear'];
      this.BranchId = params['BranchId'];
      //console.log("paran", this.BranchId);
      
      this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
        this.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
        this.VoucherDateTo = new Date();
        this.GetLMSDashboardCount();
      });
    }
  )

  this.dataSharingService.getMenuItem().subscribe((res:any)=>{
    //console.log("Menu res", res.LMS);
    if(res['LMS']){
      this.MenuslinksNew = res['LMS'];
    }
  });
 }

 //#region < toggle>
 toggle() {
   this.show = !this.show;
   // CHANGE THE NAME OF THE BUTTON.
   if (this.show)
     this.plusMinus = "-";
   else
     this.plusMinus = "+";

 }
 //#endregion

 //#region < recentCheck>
 recentCheck() {
   this.active = "";
   this.checkIn = false;
   this.recentActiveCheckIn = !this.recentActiveCheckIn;
   if (this.recentActiveCheckIn) {
     this.recentActive = "recentActive";
   } else {
     this.recentActive = "";
     this._Route.navigated = true;
   }
 }
 //#endregion

 //#region < check>

 check() {
   this.recentActive = "";
   this.recentActiveCheckIn = false;
   this.checkIn = !this.checkIn;
   if (this.checkIn) {
     this.active = "active";
     //   TodaysappointmentsComponent;
   }
   else {
     this.active = "";
     //  TodaysappointmentsComponent;
   }
 }
 //#endregion

 //#region < toggleNew>
 toggleNew(currentMenu) {
   this.recentActive = "";
   this.recentActiveCheckIn = false;
   if (currentMenu.MM_IsParent == true) {
     this.MenuslinksNew.forEach(element => {
       if (element === currentMenu) {
         currentMenu.MM_IsShow = !currentMenu.MM_IsShow;
         sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
       } else {
         element.MM_IsShow = true;
         sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
       }
     });
   }
 }
 //#endregion

 //#region < getStateNew>
 getStateNew(currentMenu) {
   if (!currentMenu.MM_IsShow) {

     return 'down';
   } else {
     return 'up';
   }
 }
 //#endregion

 gotoCustomerEntry() {
   this._Route.navigate(['/lms-customer']);
 }

  getDetail(ShortName: any) {
    //this.MenuslinksNew = JSON.parse(sessionStorage.getItem("CheckLoad"));
    //console.log("CheckLoad",this.MenuslinksNew);
    sessionStorage.removeItem("SESS_LOS");
    sessionStorage.removeItem("SESS_LMS");

    sessionStorage.removeItem("AllPageAcess");
    var data;
    this.ShowDisbursment = false;
    switch(ShortName){
      case 'LOSCP' : 
                    data = this.MenuslinksNew.filter(x => x.MM_Url == "/lms-dashboard/lms-updateBooking-list");
                    //console.log("data - ",data);
        this.MasterService.Get_Page_Rights_Of_EMP({ MMID: data[0].MM_ID.toString(), LoginUserId : this.data.userId}).subscribe((result: any) => {
                      sessionStorage.setItem("AllPageAcess", JSON.stringify(result));
                    });
                    this._Route.navigate(['/lms-dashboard/lms-updateBooking-list']); 
                    sessionStorage.setItem("SESS_LOS", 'LOSCP');
      break;
      case 'LMSCP' : 
                    data = this.MenuslinksNew.filter(x => x.MM_Url == "/lms-dashboard/lms-updateBooking-list");
                    //console.log("data - ",data);
        this.MasterService.Get_Page_Rights_Of_EMP({ MMID: data[0].MM_ID.toString(), LoginUserId : this.data.userId}).subscribe((result: any) => {
                      sessionStorage.setItem("AllPageAcess", JSON.stringify(result));
                    });
                    this._Route.navigate(['/lms-dashboard/lms-updateBooking-list']); 
                    sessionStorage.setItem("SESS_LMS", 'LMSCP');
      break;
      case 'DCP' : this._Route.navigate(['/lms/dvlist']); break;
      case 'DAP' : this._Route.navigate(['lms/dvauthorize']); break;
      case 'CWA' : this._Route.navigate(['/loan/precloser/A']); break;
      //case 'DP': this.GetDisbursementPendingDetails(); this.ShowDisbursment = true; break;
      case 'DP': this._Route.navigate(['lms/Pending-Disbursment']); break;

      
      case 'PDCG' : this._Route.navigate(['/lms-dashboard/lms-pdc-registration']); break;
      case 'NACHR' : this._Route.navigate(['/lms-dashboard/lms-nach-registration']); break;
      case 'RESAUTH': this._Route.navigate(['/loan/reschedule/A']); break;
      case 'FOLLOWUP': this._Route.navigate(['/lms-dashboard/request-and-task/FOLLOWUP']); break;
      case 'REQUEST': this._Route.navigate(['/lms-dashboard/request-and-task/REQUEST']); break;

      case 'TASK': this._Route.navigate(['/lms-dashboard/request-and-task/TASK']); break;
      case 'DeleteLoan': this._Route.navigate(['/lms-dashboard/loan-delete-authorization']); break;
      case 'PaymentInitiation': this._Route.navigate(['/lms/paymentinitiation']); break;
      case 'Paymentauthorization': this._Route.navigate(['/lms/paymentauthorization']); break;
      case 'PaymentHold': this._Route.navigate([`/lms/paymentauthorization/Hold`]); break;

    }
  }


  GetLMSDashboardCount() {
    this.showSpinner = true;
    this.lmsService.Get_LMS_Dashboard({ LoginUserId: this.currentUser.userId, From: this.VoucherDateTo, To: this.VoucherDateTo, Branch_Id: this.BranchId }).subscribe((res: any) => {
      this.dashboardData = res;
      this.showSpinner = false;
    })
  }


  GetDisbursementPendingDetails() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Loan_Id", "Application_No", "LoanAcNo", "CustumerName", "Product_Name", "Application_LoanAmount", "OpeningBalanceDR",
      "OpeningBalanceCR", "TranTypeDR", "TranTypeCR", "ClosingBalanceDR", "ClosingBalanceCR"
    ];
    
    this.reportsService
      .Get_Customer_Balance({ HeadId: 28, From: this.VoucherDateFrom, To: this.VoucherDateTo, ProductId:0 })
      .subscribe((res: any) => {
        console.log(res);

        if (res.length > 0) {
          this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.showSpinner = false;
        }  
        else{
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.showSpinner = false;
        }
      });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "Disbursment Pending",
      sheet: "Disbursment Pending",
    });
  }

  totaltypewise(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : '';//0;
    }
    return 0;
  }

 
}



