import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";

@Component({
  selector: 'app-product-app-tab-layout',
  templateUrl: './product-app-tab-layout.component.html',
  styleUrls: ['./product-app-tab-layout.component.scss']
})
export class ProductAppTabLayoutComponent implements OnInit {
  currentUser: any; 
  showSpinner: boolean = false;
  TabDisabled: boolean = false;
  ProductSummary: any = { ProductId:0};
  ProductId: any;
  currentProductTab: number = 0;
  lastFillTab: number = 0;
  Add:any;
  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductSummary.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      this.Add = param.get("Add");
      console.log("currentProductTab", this.currentProductTab);
     
    });}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    //this.dataSharingService.LOSHeaderTitle.next("Application Detail");
    if (this.Add == "true" && this.currentProductTab == 0) {
      this.currentProductTab = 1;
    
    }
  
    if (Number(this.ProductSummary.ProductId) > 0) {
      this.TabDisabled = false;
    } else {
      this.TabDisabled = true;
    }
   
  }

  onTabChange(event: any) {
    this.currentProductTab = event.index;
  }

}
