<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchCaseWiseAccBalance()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> Select Type </span>
        <select
          name="Type"
          id="Type"
          [(ngModel)]="Type"
          class="form-control input-text-css"
          #refType="ngModel"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refType.invalid,
            'alert-warning':
              refType.invalid &&
              (refType.dirty || refType.touched || refType.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Income">Income Account</option>
          <option value="Balance">Balance Account</option>
        </select>
      </div>

      <div class="col-md-3" *ngIf="Type == 'Income'">
        <span class="required-lable">Income Account</span>
        <input
          type="text"
          [formControl]="IncomeAccControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="AccountIncome"
          name="AccountIncome"
          required
          [class.alert-warning]="AccountIncome == ''"
          placeholder="Income Account"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="OnIncomeAccSelect($event)"
        >
          <mat-option
            *ngFor="let Item of filteredIncAccDropdown | async"
            [value]="Item"
          >
            {{ Item.Account_Name }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="col-md-3" *ngIf="Type == 'Balance'">
        <span class="required-lable">Balance Account</span>
        <input
          type="text"
          [formControl]="BalanceAccControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="AccountBalance"
          name="AccountBalance"
          required
          [class.alert-warning]="AccountBalance == ''"
          placeholder="Balance Account"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="OnBalanceAccSelect($event)"
        >
          <mat-option
            *ngFor="let Item of filteredBalAccDropdown | async"
            [value]="Item"
          >
            {{ Item.Account_Name }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="col-md-2">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            (dateChange)="DateChange()"
            required
            #refFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            (dateChange)="DateChange()"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchCaseWiseAccBalance()"
          class="mt-3 btn font-size-12 button-btn float-right"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>


      <div class="col-md-12">
        <div class="col-md-3 float-right mt-2" [hidden]="!DataSource">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>



    </div>
  </form>

  <div class="mt-3" [hidden]="!DataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="VoucherId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width:50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Voucher Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_Date }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Voucher No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_No }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CaseNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Case No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CaseNo }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ChargeHead">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Charge Head</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ChargeHead }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="TransTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Amount DR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TransTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ DataSource ? totaltypewise("TransTypeDR") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TransTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Amount CR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TransTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ DataSource ? totaltypewise("TransTypeCR") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Balance }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ DataSource ? totaltypewise("Balance") : "" }}
            </mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            [ngClass]="{ Row_Bold: row.Voucher_No == 'Opening Balance' }"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="case_wise_acc_deatils"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr style="background: #4dc3a3 !important; border: 1px solid white">
    <th>#</th>
    <th>Voucher No</th>
    <th>Voucher Date</th>
    <th>Case No.</th>
    <th>Customer</th>
    <th>Charge Head</th>
    <th>Amount DR</th>
    <th>Amount CR</th>
    <th>Balance</th>
  </tr>

  <tr *ngFor="let x of Jsondata; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Voucher_Date }}</td>
    <td>{{ x.Voucher_No }}</td>
    <td>{{ x.CaseNo }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.ChargeHead }}</td>
    <td>{{ x.TransTypeDR }}</td>
    <td>{{ x.TransTypeCR }}</td>
    <td>{{ x.Balance }}</td>
  </tr>

  <tfoot>
    <tr
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    >
      <td colspan="6">
        <b>Total:</b>
      </td>

      <td>
        <strong>{{ DataSource ? totaltypewise("TransTypeDR") : "" }}</strong>
      </td>
      <td>
        <strong>{{ DataSource ? totaltypewise("TransTypeCR") : "" }}</strong>
      </td>
      <td>
        <strong> {{ DataSource ? totaltypewise("Balance") : "" }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
