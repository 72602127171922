import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: "app-mst-tax-slab",
  templateUrl: "./mst-tax-slab.component.html",
  styleUrls: ["./mst-tax-slab.component.scss"],
})
export class MstTaxSlabComponent implements OnInit {
  private data: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  slabList: any = [];
  taxSlab: any = "";
  dataSource: any;
  displayedColumns: string[] = [
    "Id",
    "TaxSlab_Name",
    "TaxSlab_Type",
    "TaxSlab_Rate",
    "Account_Name",
    "TaxType",
    "IsInterState",
  ];
  taxSlabData: any[] = [];
  taxSlabAccountHead: any[] = [];
  taxSlabModel: any = { TaxSlab: [] };
  taxSlabState: any[] = [
    "With-In State Input (SGST)",
    "With-In State output (SGST)",
    "With-In State Input (CGST)",
    "With-In State output (CGST)",
    "Inter State Input(IGST)",
    "Inter State output (IGST)",
  ];
  isEdit: boolean = false;
  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Tax Slab Master");
    this.Get_TaxSlab_Dropdown();
  }

  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;
      this.taxSlab = res[0] ? res[0].TaxSlab_Name : "";
      this.Get_TaxSlab_Details();
      this.Get_AccountHeadForTaxSlab();
    });
  }
  Get_TaxSlab_Details() {
    if (this.taxSlab != "") {
      this.lmsService
        .Get_TaxSlab_Details({ TaxSlab: this.taxSlab })
        .subscribe((res: any) => {
          this.taxSlabData = res;
          this.dataSource = new MatTableDataSource(this.taxSlabData);
        });
    }
  }
  changeFilter() {
    this.Get_TaxSlab_Details();
  }
  Get_AccountHeadForTaxSlab() {
    this.lmsService.Get_AccountHeadForTaxSlab({}).subscribe((res: any) => {
      this.taxSlabAccountHead = res;
    });
  }
  onAddTaxSlab() {
    this.taxSlabModel = { TaxSlab_Name: "", TaxSlab: [] };
    this.taxSlabState.forEach((obj: any) => {
      this.taxSlabModel.TaxSlab.push({
        Id: 0,
        TaxSlab_Name: "",
        TaxSlab_Type: obj,
        TaxSlab_Rate: "",
        TaxSlab_AccountHead: "",
        LoginUserId: this.data.userId,
        TaxType: "",
        IsInterState: "",
        Tax_IsInterState: "",
      });
    });
    this.isEdit = false;
    $("#AddNewtaxslabModal").modal("show");
    $("#AddNewtaxslabModal").css("z-index", "1050");
  }
  onEditTaxSlab() {
    this.taxSlabModel = { TaxSlab_Name: this.taxSlab, TaxSlab: [] };
    this.taxSlabData.forEach((obj: any) => {
      this.taxSlabModel.TaxSlab.push({
        Id: obj.Id,
        TaxSlab_Name: obj.TaxSlab_Name,
        TaxSlab_Type: obj.TaxSlab_Type,
        TaxSlab_Rate: obj.TaxSlab_Rate,
        TaxSlab_AccountHead: obj.AccountId,
        LoginUserId: this.data.userId,
        TaxType: obj.TaxType,
        Tax_IsInterState: obj.IsInterState,
        IsInterState: obj.IsInterState,
      });
    });
    this.isEdit = true;
    $("#AddNewtaxslabModal").modal("show");
    $("#AddNewtaxslabModal").css("z-index", "1050");
  }
  OnClosetaxSlabModal() {
    $("#AddNewtaxslabModal").modal("hide");
  }
  onSaveTaxSlab() {
    this.loading = true;
    let _data = { ...this.taxSlabModel };
    _data.TaxSlab.forEach((obj: any) => {
      obj.TaxSlab_Name = _data.TaxSlab_Name;
    });
    delete _data.TaxSlab_Name;
    this.lmsService
      .Save_TaxSlab({ JSON: JSON.stringify(_data) })
      .subscribe((response) => {
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.OnClosetaxSlabModal();
          if (this.isEdit) {
            this.Get_TaxSlab_Details();
          } else {
            this.slabList.push({
              TaxSlab_Name: this.taxSlabModel.TaxSlab_Name,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        this.loading = false;
      });
  }
}
