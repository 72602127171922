<a href="javascript:void(0)" (click)="onOpenViewDetailModal()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in VoucherDetail{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div
          cdkDragHandle
          class="modal-header"
          style="padding: 2px; border: none"
        >
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Voucher Detail
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 text-left">
            <div class="col-md-12 bg-success1">
              Voucher Type -
              <span style="color: #333"
                >{{ voucherModel.Voucher.Voucher_Type }} -
                {{ voucherModel.Voucher.Voucher_Sub_Type }}</span
              >
            </div>
          </div>
          <div class="row m-0 mt-1 text-left">
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Voucher No : </B>
              {{ voucherModel.Voucher.Voucher_No }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Voucher Date : </B>
              {{ voucherModel.Voucher.Voucher_Date }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">ReceiptNo Book : </B>
              {{ voucherModel.Voucher.Voucher_ReceiptNo_Book }}
            </div>
            <div class="col-md-3 p-1" style="color: black">
              <B class="fs-12">Receipt Date : </B>
              {{ voucherModel.Voucher.Voucher_ReceiptDate }}
            </div>
          </div>

          <div
            class="row m-0 mt-2 text-left"
            *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != null"
          >
            <div class="col-md-12 bg-success1">
              Mode of Payment -
              <span style="color: #333">{{
                voucherModel.Voucher.Voucher_Mode_of_Payment
              }}</span>
            </div>
          </div>
          <div class="row m-0 mt-1 text-left">
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque No : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_No }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque Date : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_Date }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Bank Name : </B
              >{{ voucherModel.Voucher.Voucher_Bank_Name }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
              style="color: black"
            >
              <B class="fs-12">Cheque Clear Date : </B
              >{{ voucherModel.Voucher.Voucher_Cheque_Clear_Date }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="
                voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != null
              "
              style="color: black"
            >
              <B class="fs-12">Ref No : </B
              >{{ voucherModel.Voucher.Voucher_E_Ref_No }}
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="
                voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != null
              "
              style="color: black"
            >
              <B class="fs-12">Transaction Date : </B
              >{{ voucherModel.Voucher.Voucher_E_Transaction_Date }}
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1">
              <div
                class="table-responsive"
                *ngIf="
                  voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                  voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                "
              >
                <mat-table
                  [dataSource]="voucherModel.Voucher_Detail"
                  matSort
                  matTableExporter
                >
                  <ng-container matColumnDef="Type">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 5%"
                      >Type</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 5%"
                      >{{ row.TranType }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 5%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="CaseNo">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 15%"
                      >Case No</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 15%"
                      >{{ row.CaseNo }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 15%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Customer</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.Customer }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 10%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Branch</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.Branch_Name }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 10%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Category">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 20%"
                      >Category</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 20%"
                      >{{ row.ChagesHead }}</mat-cell
                    >
                    <mat-footer-cell
                      *matFooterCellDef
                      style="max-width: 20%"
                    ></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AccountHead">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 20%"
                      >Account Head</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 20%"
                      >{{ row.Account_Name }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 20%"
                      >Total</mat-footer-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Amount(DR)</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.AmountDR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                      calculateTranTypeDR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 10%"
                      >Amount(CR)</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 10%"
                      >{{ row.AmountCR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef style="max-width: 10%">{{
                      calculateTranTypeCR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedLoanColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedLoanColumns"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedLoanColumns"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1">
              <div
                class="table-responsive"
                *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'"
              >
                <mat-table
                  [dataSource]="voucherModel.Voucher_Detail"
                  matSort
                  matTableExporter
                >
                  <ng-container matColumnDef="AccountHead">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Account Head</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Account_Name
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Branch</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Branch_Name
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(DR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountDR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{
                      calculateTranTypeDR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(CR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountCR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{
                      calculateTranTypeCR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedAccountingColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedAccountingColumns"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedAccountingColumns"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0 mt-2 text-left">
            <div class="col-md-3 p-1">
              <h6 class="fs-12">Voucher Created By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_CreateBy }}
              </p>
            </div>
            <!--<div class="col-md-3 p-1">
              <h6 class="fs-12">Voucher Created On</h6>
              <p class="lead fs-12">{{voucherModel.Voucher.Voucher_CreateOn}}</p>
            </div>-->
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizBy != null"
            >
              <h6 class="fs-12">Voucher Authoriz By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizBy }}
              </p>
            </div>
            <div
              class="col-md-3 p-1"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizOn != null"
            >
              <h6 class="fs-12">Voucher Authoriz On</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizOn }}
              </p>
            </div>
            <div class="col-md-6 p-1">
              <h6 class="fs-12">Voucher Narration</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_Narration }}
              </p>
            </div>
          </div>

          <div class="row m-0 mt-2" *ngIf="docModal.length > 0">
            <div class="col-md-12" style="text-align: left">
              <h6 class="fs-12">Attachments</h6>
              <div *ngFor="let doc of docModal; let i = index">
                <a
                  href="{{ AccountDocUrl }}/{{ doc.DocFileName }}"
                  class="ml-3"
                  target="_blank"
                  >{{ doc.DocFileName }}</a
                >
                <!--<i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ doc.Doc_path }}-->
              </div>
            </div>
          </div>

          <div class="row col-md-12 mt-2 m-0 justify-content-end">
            <ng-container *ngIf="VoucherFrom != 'Allocation'">
              <button
                type="button"
                class="ml-4 mt-3 btn font-size-12 button-btn"
                style="height: 38px"
                [hidden]="!ShowDelete || Close_Case"
                (click)="DeleteVoucher()"
                [class.spinner]="loading"
              >
                Delete
              </button>
              <app-voucher-entry
                [voucherId]="voucherModel.Voucher.VoucherId"
                [index]="index"
                (close)="GetData($event)"
              >
                <button
                  type="button"
                  [class.spinner]="loading"
                  [hidden]="!ShowEdit"
                  class="ml-4 mt-3 btn font-size-12 button-btn"
                >
                  Edit
                </button>
              </app-voucher-entry>

              <app-lms-voucher-print-model
                [Voucher_Id]="voucherModel.Voucher.VoucherId"
                [index]="index"
              >
                <button
                  type="button"
                  class="ml-4 mt-3 btn font-size-12 button-btn"
                >
                  Print View
                </button>
              </app-lms-voucher-print-model>
            </ng-container>

            <button
              type="button"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              (click)="OnClose()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
