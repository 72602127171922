<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keyup.enter)="onSearchNachProcess()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <span class="required-lable">Start Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DueDateFrom"
          id="DueDateFrom"
          class="form-control input-text-css"
          [(ngModel)]="DueDateFrom"
          (dateChange)="DateChange()"
          required
          #refDueDateFrome="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDueDateFrome.invalid,
            'alert-warning':
              refDueDateFrome.invalid &&
              (refDueDateFrome.dirty ||
                refDueDateFrome.touched ||
                refDueDateFrome.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="DueDateFrom"
        [(ngModel)]="DueDateFrom"
        (ngModelChange)="DateChange()"
        id="DueDateFrom"
        placeholder="Start Date"
        bsDatepicker
        class="form-control input-text-css"
        required
        #refDueDateFrome="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refDueDateFrome.invalid,
          'alert-warning':
            refDueDateFrome.invalid &&
            (refDueDateFrome.dirty ||
              refDueDateFrome.touched ||
              refDueDateFrome.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-3">
      <span class="required-lable">End Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DueDateTo"
          id="DueDateTo"
          #refVoucher_Date="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="DueDateTo"
          (dateChange)="DateChange()"
          required
          #refDueDateToe="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDueDateToe.invalid,
            'alert-warning':
              refDueDateToe.invalid &&
              (refDueDateToe.dirty ||
                refDueDateToe.touched ||
                refDueDateToe.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="DueDateTo"
        [(ngModel)]="DueDateTo"
        (ngModelChange)="DateChange()"
        id="DueDateTo"
        placeholder="End Date"
        bsDatepicker
        class="form-control input-text-css"
        required
        #refDueDateToe="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refDueDateToe.invalid,
          'alert-warning':
            refDueDateToe.invalid &&
            (refDueDateToe.dirty ||
              refDueDateToe.touched ||
              refDueDateToe.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-3">
      <span class="required-lable">Status</span>
      <select
        name="Status"
        id="Status"
        [(ngModel)]="Status"
        (ngModelChange)="StatusChange()"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option value="Post">Post</option>
        <option value="Hold">Hold</option>
        <option value="Pending">Pending</option>
      </select>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="onSearchNachProcess()"
        class="font-size-12 button-btn mt-3"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
  </div>
</form>

<form #f="ngForm" novalidate [hidden]="!dataSourceLoan">
  <div class="mt-3">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
        >
          <!-- style="height: 360px; max-width: 100%; overflow: auto;" -->
          <ng-container matColumnDef="Id" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              {{ i + 1
              }}<!-- {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }} -->
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Selection">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              [hidden]="!IsSelectAll || Status != 'Pending'"
              style="padding: 2px 0 2px 0"
            >
              <mat-checkbox
                (change)="SelectAll($event)"
                *ngIf="Status == 'Pending'"
                [checked]="IsAlreadySelected"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              [hidden]="!IsSelectAll || Status != 'Pending'"
              style="padding: 2px 0 2px 0"
            >
              <mat-checkbox
                [(ngModel)]="row.IsAlreadySelected"
                name="IsAlreadySelected{{ row.Id }}"
                id="IsAlreadySelected{{ row.Id }}"
                [checked]="row.IsAlreadySelected"
              >
              </mat-checkbox>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CaseNo" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total: </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="EMINo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMINo }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CaseDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMI_Amount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DueDate | date : "dd/MM/yyyy" }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerBankname">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Bank</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerBankname }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Type }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Undo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >HOLD/UNDO</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <ng-container *ngIf="loanSummary == undefined">
                <button
                  type="button"
                  *ngIf="
                    (row.IsStop == 0 ||
                      row.IsStop == '' ||
                      row.IsStop == null) &&
                    row.Status == 'Pending'
                  "
                  (click)="onHold(row)"
                  mat-raised-button
                  class="btn font-size-12 button-btn"
                >
                  HOLD
                </button>
                <button
                  type="button"
                  (click)="Undo(row)"
                  *ngIf="row.Status != 'Pending'"
                  mat-raised-button
                  class="btn font-size-12 button-btn"
                >
                  UNDO
                </button>
              </ng-container>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Status }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsLoan; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>

        <div
          class="col-md-12"
          style="display: flex; align-items: center; justify-content: end"
        >
          <button
            class="btn font-size-12 button-btn"
            (click)="exportTable(exporter)"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>

        <!-- <div class="row m-0 align-items-center" style="background: #fff;">
                    <div class="col-md-12 text-right">
                        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" class="mat-paginator-sticky" showFirstLastButtons> </mat-paginator>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
  <div class="row m-0 p-0 mt-2" *ngIf="Status == 'Pending'">
    <div class="col-md-3">
      <span class="required-lable">Post Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker3"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="BulkPostDate"
          id="BulkPostDate"
          [min]="minDate"
          class="form-control input-text-css"
          [(ngModel)]="BulkPostDate"
          #refBulkPostDate="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refBulkPostDate.invalid,
            'alert-warning':
              refBulkPostDate.invalid &&
              (refBulkPostDate.dirty ||
                refBulkPostDate.touched ||
                refBulkPostDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </div>
      <!-- <input
        name="BulkPostDate"
        [(ngModel)]="BulkPostDate"
        required
        id="BulkPostDate"
        [minDate]="minDate"
        class="form-control input-text-css"
        bsDatepicker
        placeholder="DD/MM/YYYY"
        #refBulkPostDate="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refBulkPostDate.invalid,
          'alert-warning':
            refBulkPostDate.invalid &&
            (refBulkPostDate.dirty ||
              refBulkPostDate.touched ||
              refBulkPostDate.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-3">
      <button
        type="button"
        [disabled]="!f.form.valid"
        (click)="onBulkPost()"
        [class.spinner]="loading"
        class="font-size-12 button-btn mt-3"
      >
        Post
      </button>
    </div>
  </div>
</form>

<div
  class="modal fade in"
  id="HoldDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Hold Details
        </h6>
        <button
          type="button"
          (click)="onCloseHoldDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #ff="ngForm"
          (ngSubmit)="onHoldOk()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-6">
              <span>Hold Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker4"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="HoldDate"
                  [(ngModel)]="HoldDate"
                  required
                  id="HoldDate"
                  class="form-control input-text-css"
                  #refHoldDate="ngModel"
                  [ngClass]="{
                    'is-invalid': ff.submitted && refHoldDate.invalid,
                    'alert-warning':
                      refHoldDate.invalid &&
                      (refHoldDate.dirty ||
                        refHoldDate.touched ||
                        refHoldDate.untouched)
                  }"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker4"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </div>
              <!-- <input
                name="HoldDate"
                [(ngModel)]="HoldDate"
                required
                id="HoldDate"
                placeholder="DD/MM/YYYY"
                #refHoldDate="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refHoldDate.invalid,
                  'alert-warning':
                    refHoldDate.invalid &&
                    (refHoldDate.dirty ||
                      refHoldDate.touched ||
                      refHoldDate.untouched)
                }"
                class="form-control input-text-css"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-6">
              <span>Hold Reason</span>
              <input
                name="HoldReason"
                [(ngModel)]="HoldReason"
                required
                id="HoldReason"
                placeholder="Hold Reason"
                #refHoldReason="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refHoldReason.invalid,
                  'alert-warning':
                    refHoldReason.invalid &&
                    (refHoldReason.dirty ||
                      refHoldReason.touched ||
                      refHoldReason.untouched)
                }"
                class="form-control input-text-css"
              />
              <!-- <select name="HoldReason" id="HoldReason" #refHoldReason="ngModel" [(ngModel)]="HoldReason"
                      class="form-control input-text-css" required [ngClass]="{'is-invalid': ff.submitted && refHoldReason.invalid, 'alert-warning': refHoldReason.invalid && (refHoldReason.dirty || refHoldReason.touched || refHoldReason.untouched)}">
                <option value="" selected> Select Hold Reason </option>
                <option value="INSUFFICIENT FUNDS">INSUFFICIENT FUNDS </option>
                <option value="PAYMENT STOPPED BY DRAWER">PAYMENT STOPPED BY DRAWER </option>
                <option value="DRAWERS SIGN DIFFERS">DRAWERS SIGN DIFFERS </option>
                <option value="ALTERATION DRAWER SIGN REQ">ALTERATION DRAWER SIGN REQ. </option>
                <option value="DRAWER SIGN REQUIRED">DRAWER SIGN REQUIRED </option>
                <option value="NO SUCH ACCOUNT">NO SUCH ACCOUNT </option>
                <option value="ACCOUNT CLOSED">ACCOUNT CLOSED </option>
                <option value="ACCOUNT BLOCKED">ACCOUNT BLOCKED </option>
                <option value="NOT ARRANGE FOR">NOT ARRANGE FOR </option>
                <option value="REFER TO DRAWER">REFER TO DRAWER </option>
                <option value="ECS MANDATE NOT RECEIVED">ECS MANDATE NOT RECEIVED </option>
                <option value="EXCEED ARRANGEMENT">EXCEED ARRANGEMENT </option>
                <option value="PARTY DECEASED">PARTY DECEASED </option>
                <option value="WORD DIFFERENCE">WORD DIFFERENCE </option>
                <option value="PAYNAME DIFFERENCE">PAYNAME DIFFERENCE </option>
                <option value="AMOUNT DIFFERENCE">AMOUNT DIFFERENCE </option>
                <option value="SIGNATURE NOT ILLIGIBLE">SIGNATURE NOT ILLIGIBLE </option>
                <option value="ALTRATIONS">ALTRATIONS </option>
                <option value="INSTRUMENTS">INSTRUMENTS </option>
                <option value="STAMP OF ACCOUNT REQUIRED">STAMP OF ACCOUNT REQUIRED </option>
                <option value="CONNECTIVITY FAILURE">CONNECTIVITY FAILURE </option>
                <option value="DORMENT ACCOUNT">DORMENT ACCOUNT </option>
                <option value="MISE-A/C REACH MAX DR-LIMIT">MISE-A/C REACH MAX DR-LIMIT </option>
                <option value="PYMT STOP UNDER COURT OREDER">PYMT STOP UNDER COURT OREDER </option>
                <option value="ACCOUNT UNDER LITIGATION">ACCOUNT UNDER LITIGATION </option>
                <option value="POSTDATED">POSTDATED </option>
                <option value="OTHER REASON">OTHER REASON </option>
              </select> -->
            </div>
          </div>
          <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
            <button
              type="button"
              (click)="onHoldOk()"
              [class.spinner]="loading"
              class="font-size-12 button-btn"
              [disabled]="!ff.form.valid"
            >
              OK
            </button>
            <button
              type="button"
              (click)="onCloseHoldDetail()"
              class="font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
