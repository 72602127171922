<a href="javascript:void(0)" (click)="OpenPaymentModel()">
  <ng-content></ng-content>
</a>

<div class="modal fade in"
     id="MakePayment"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <form #ddf="ngForm" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Make Payment
          </h6>
          <button type="button"
                  (click)="onClosePaymentModel()"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-3 mb-2 p-1">
              <span class="required-lable">Select Bank</span>
              <select name="SelectBank"
                      id="SelectBank"
                      (change)="BankChange()"
                      #refSelectBank="ngModel"
                      class="form-control input-text-css"
                      required
                      [(ngModel)]="PaymentModel.SelectBank"
                      [ngClass]="{
                  'is-invalid': ddf.submitted && refSelectBank.invalid,
                  'alert-warning':
                    refSelectBank.invalid &&
                    (refSelectBank.dirty ||
                      refSelectBank.touched ||
                      refSelectBank.untouched)
                }">
                <option value="">Select A/C</option>
                <option *ngIf="ENachBankId > 0" value="ICICI">
                  ICICI Bank
                </option>
                <option *ngIf="ENachHdfcBankId > 0" value="HDFC">
                  HDFC Bank
                </option>
                <option *ngIf="ENachAUBankId > 0" value="AU">AU Bank</option>

                <option *ngIf="ENACHNupayHDFCId > 0" value="Nupay">HDFC Nupay</option>
              </select>
            </div>
            <div class="col-md-4 p-1" *ngIf="PaymentModel.SelectBank == 'AU'">
              <span class="required-lable">Mandate Registered</span>
              <select name="Mandate"
                      id="Mandate"
                      #refMandate="ngModel"
                      class="form-control input-text-css"
                      required
                      [(ngModel)]="PaymentModel.Mandate"
                      [ngClass]="{
                  'is-invalid': ddf.submitted && refMandate.invalid,
                  'alert-warning':
                    refMandate.invalid &&
                    (refMandate.dirty ||
                      refMandate.touched ||
                      refMandate.untouched)
                }">
                <option value="">Select Mandate Registered</option>
                <option value="WithUser">With User confirmation</option>
                <option value="WithoutUser">Without User confirmation</option>
              </select>
            </div>
          </div>
          <ng-container *ngIf="PaymentModel.SelectBank == 'HDFC'">
            <div class="formborder mt-2">
              <div class="row m-0" *ngIf="PaymentModel.SelectBank != ''">
                <div class="col-md-12 bg-success1">Customer Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Consumer ID (Loan Reference No.)</span>
                  <input type="text"
                         id="ApplicationNo1"
                         name="ApplicationNo1"
                         #refApplicationNo1="ngModel"
                         required
                         placeholder="Loan No."
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refApplicationNo1.invalid,
                      'alert-warning':
                        refApplicationNo1.invalid &&
                        (refApplicationNo1.dirty ||
                          refApplicationNo1.touched ||
                          refApplicationNo1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.ApplicationNo"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">Full Name</span>
                  <input type="text"
                         name="FullName1"
                         id="FullName1"
                         #refFullName1="ngModel"
                         required
                         placeholder="Full Name"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refFullName1.invalid,
                      'alert-warning':
                        refFullName1.invalid &&
                        (refFullName1.dirty ||
                          refFullName1.touched ||
                          refFullName1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.FullName"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">E-mail ID (Optional)</span>
                  <input type="text"
                         id="Email1"
                         name="Email1"
                         #refEmail1="ngModel"
                         placeholder="E-mail id"
                         [(ngModel)]="PaymentModel.Email"
                         class="form-control input-text-css"
                         disabled />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Mobile Number</span>
                  <input type="text"
                         name="MobileNo1"
                         id="MobileNo1"
                         #refMobileNo1="ngModel"
                         required
                         placeholder="MobileNo Number"
                         maxlength="10"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refMobileNo1.invalid,
                      'alert-warning':
                        refMobileNo1.invalid &&
                        (refMobileNo1.dirty ||
                          refMobileNo1.touched ||
                          refMobileNo1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.MobileNo"
                         class="form-control input-text-css"
                         numbersOnly
                         disabled />
                </div>

                <div class="col-md-4 p-1">
                  <span class="required-lable">A/C No</span>
                  <input type="text"
                         id="ACNo1"
                         name="ACNo1"
                         #refACNo1="ngModel"
                         required
                         disabled
                         placeholder="A/C No"
                         [(ngModel)]="PaymentModel.ACNo"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refACNo1.invalid,
                      'alert-warning':
                        refACNo1.invalid &&
                        (refACNo1.dirty ||
                          refACNo1.touched ||
                          refACNo1.untouched)
                    }" />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">IFSC Code</span>
                  <input type="text"
                         name="IFSCCode1"
                         id="IFSCCode1"
                         #refIFSCCode1="ngModel"
                         required
                         disabled
                         placeholder="IFSC Code"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refIFSCCode1.invalid,
                      'alert-warning':
                        refIFSCCode1.invalid &&
                        (refIFSCCode1.dirty ||
                          refIFSCCode1.touched ||
                          refIFSCCode1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.IFSCCode"
                         class="form-control input-text-css" />
                </div>
              </div>
            </div>

            <div class="formborder mt-2">
              <div class="row m-0 mt-2">
                <div class="col-md-12 bg-success1">Mandate Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Channel</span>
                  <select name="Channel1"
                          id="Channel1"
                          #refChannel="ngModel"
                          (change)="ChannelChange()"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.Channel"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refChannel.invalid,
                      'alert-warning':
                        refChannel.invalid &&
                        (refChannel.dirty ||
                          refChannel.touched ||
                          refChannel.untouched)
                    }">
                    <option value="">Select A/C</option>
                    <option value="Debit">Debit Card</option>
                    <option value="Net">Net-banking</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Your Bank Name</span>
                  <select name="BankIdHdfc"
                          id="BankIdHdfc"
                          #refBankIdHdfc="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.BankIdHdfc"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refBankIdHdfc.invalid,
                      'alert-warning':
                        refBankIdHdfc.invalid &&
                        (refBankIdHdfc.dirty ||
                          refBankIdHdfc.touched ||
                          refBankIdHdfc.untouched)
                    }">
                    <option value="">Select Bank</option>
                    <option *ngFor="let data of livebankList"
                            [value]="data.bankId">
                      {{ data.bankName }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Amount</span>
                  <input type="number"
                         name="CAmt1"
                         id="CAmt1"
                         #refCAmt1="ngModel"
                         [min]="1"
                         required
                         placeholder="Collection Amount"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refCAmt1.invalid,
                      'alert-warning':
                        refCAmt1.invalid &&
                        (refCAmt1.dirty ||
                          refCAmt1.touched ||
                          refCAmt1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.CAmt"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Max Collection Amount</span>
                  <input type="text"
                         name="MaxAmt1"
                         id="MaxAmt1"
                         #refMaxAmt1="ngModel"
                         required
                         placeholder="Maximum Amount"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refMaxAmt1.invalid,
                      'alert-warning':
                        refMaxAmt1.invalid &&
                        (refMaxAmt1.dirty ||
                          refMaxAmt1.touched ||
                          refMaxAmt1.untouched)
                    }"
                         [(ngModel)]="PaymentModel.MaxAmt"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Account Type</span>
                  <select name="ACType1"
                          id="ACType1"
                          #refACType1="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.ACType"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refACType1.invalid,
                      'alert-warning':
                        refACType1.invalid &&
                        (refACType1.dirty ||
                          refACType1.touched ||
                          refACType1.untouched)
                    }">
                    <option value="">Select A/C</option>
                    <option value="S">Saving</option>
                    <option value="C">Current</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Frequency</span>
                  <select name="CFreq1"
                          id="CFreq1"
                          #refCFreq1="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CFreq"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refCFreq1.invalid,
                      'alert-warning':
                        refCFreq1.invalid &&
                        (refCFreq1.dirty ||
                          refCFreq1.touched ||
                          refCFreq1.untouched)
                    }">
                    <option value="">Select Frequency</option>
                    <option value="DAIL">Daily</option>
                    <option value="INDA">Intra-day</option>
                    <option value="WEEK">Weekly</option>
                    <option value="MNTH">Monthly</option>
                    <option value="QURT">Quarterly</option>
                    <option value="MIAN">Semi annually</option>
                    <option value="YEAR">Yearly</option>
                    <option value="BIMN">Bi- monthly</option>
                    <option value="ADHO">As and when presented</option>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection First Date</span>

                  <div class="datepicker_feild">
                    <input [matDatepicker]="pickerStartDate"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="StartDate1"
                           id="StartDate1"
                           #refStartDate1="ngModel"
                           [(ngModel)]="PaymentModel.StartDatehdfc"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                        'is-invalid': ddf.submitted && refStartDate1.invalid,
                        'alert-warning':
                          refStartDate1.invalid &&
                          (refStartDate1.dirty ||
                            refStartDate1.touched ||
                            refStartDate1.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collectionn Last Date</span>

                  <div class="datepicker_feild">
                    <input [matDatepicker]="pickerEndDate"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="EndDate1"
                           id="EndDate1"
                           #refEndDate1="ngModel"
                           [(ngModel)]="PaymentModel.EndDatehdfc"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                        'is-invalid': ddf.submitted && refEndDate1.invalid,
                        'alert-warning':
                          refEndDate1.invalid &&
                          (refEndDate1.dirty ||
                            refEndDate1.touched ||
                            refEndDate1.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div [hidden]="true">
              <input type="text"
                     id="hdfc_key"
                     name="hdfc_key"
                     disabled
                     [(ngModel)]="hdfc_key"
                     class="form-control input-text-css" />
              <input type="text"
                     id="hdfc_Utilcode"
                     name="hdfc_Utilcode"
                     disabled
                     [(ngModel)]="hdfc_Utilcode"
                     class="form-control input-text-css" />
              <input type="text"
                     id="hdfc_Short_Code"
                     name="hdfc_Short_Code"
                     disabled
                     [(ngModel)]="hdfc_Short_Code"
                     class="form-control input-text-css" />
              <input type="text"
                     id="hdfc_Merchant_Category_Code"
                     name="hdfc_Merchant_Category_Code"
                     disabled
                     [(ngModel)]="hdfc_Merchant_Category_Code"
                     class="form-control input-text-css" />

            </div>
          </ng-container>

          <ng-container *ngIf="PaymentModel.SelectBank == 'ICICI'">
            <div class="formborder mt-2">
              <div class="row m-0" *ngIf="PaymentModel.SelectBank != ''">
                <div class="col-md-12 bg-success1">Customer Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Consumer ID (Loan Reference No.)</span>
                  <input type="text"
                         id="ApplicationNo"
                         name="ApplicationNo"
                         #refApplicationNo="ngModel"
                         required
                         placeholder="Loan No."
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refApplicationNo.invalid,
                      'alert-warning':
                        refApplicationNo.invalid &&
                        (refApplicationNo.dirty ||
                          refApplicationNo.touched ||
                          refApplicationNo.untouched)
                    }"
                         [(ngModel)]="PaymentModel.ApplicationNo"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">Full Name</span>
                  <input type="text"
                         name="FullName"
                         id="FullName"
                         #refFullName="ngModel"
                         required
                         placeholder="Full Name"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refFullName.invalid,
                      'alert-warning':
                        refFullName.invalid &&
                        (refFullName.dirty ||
                          refFullName.touched ||
                          refFullName.untouched)
                    }"
                         [(ngModel)]="PaymentModel.FullName"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">E-mail ID (Optional)</span>
                  <input type="text"
                         id="Email"
                         name="Email"
                         #refEmail="ngModel"
                         placeholder="E-mail id"
                         [(ngModel)]="PaymentModel.Email"
                         class="form-control input-text-css"
                         disabled />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Mobile Number</span>
                  <input type="text"
                         name="MobileNo"
                         id="MobileNo"
                         #refMobileNo="ngModel"
                         required
                         placeholder="MobileNo Number"
                         maxlength="10"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refMobileNo.invalid,
                      'alert-warning':
                        refMobileNo.invalid &&
                        (refMobileNo.dirty ||
                          refMobileNo.touched ||
                          refMobileNo.untouched)
                    }"
                         [(ngModel)]="PaymentModel.MobileNo"
                         class="form-control input-text-css"
                         numbersOnly
                         disabled />
                  <!-- <input type="text" name="MobileNo" id="MobileNo" #refMobileNo ="ngModel" required placeholder="MobileNo Number" pattern="\d{3}[\-]\d{3}[\-]\d{4}" maxlength="10"
        [ngClass]="{ 'is-invalid': ddf.submitted && refMobileNo .invalid, 'alert-warning': refMobileNo .invalid  && (refMobileNo .dirty || refMobileNo .touched || refMobileNo .untouched) }"
        [(ngModel)]="PaymentModel.MobileNo" class="form-control input-text-css" numbersOnly> -->
                </div>

                <div class="col-md-4 p-1">
                  <span class="required-lable">A/C No</span>
                  <input type="text"
                         id="ACNo"
                         name="ACNo"
                         #refACNo="ngModel"
                         required
                         disabled
                         placeholder="A/C No"
                         [(ngModel)]="PaymentModel.ACNo"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refACNo.invalid,
                      'alert-warning':
                        refACNo.invalid &&
                        (refACNo.dirty || refACNo.touched || refACNo.untouched)
                    }" />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">IFSC Code</span>
                  <input type="text"
                         name="IFSCCode"
                         id="IFSCCode"
                         disabled
                         #refIFSCCode="ngModel"
                         required
                         placeholder="IFSC Code"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refIFSCCode.invalid,
                      'alert-warning':
                        refIFSCCode.invalid &&
                        (refIFSCCode.dirty ||
                          refIFSCCode.touched ||
                          refIFSCCode.untouched)
                    }"
                         [(ngModel)]="PaymentModel.IFSCCode"
                         class="form-control input-text-css" />
                </div>
              </div>
            </div>

            <div class="formborder mt-2">
              <div class="row m-0 mt-2">
                <div class="col-md-12 bg-success1">Mandate Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Account Type</span>
                  <select name="ACType"
                          id="ACType"
                          #refACType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.ACType"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refACType.invalid,
                      'alert-warning':
                        refACType.invalid &&
                        (refACType.dirty ||
                          refACType.touched ||
                          refACType.untouched)
                    }">
                    <option value="">Select A/C</option>
                    <option value="Saving">Saving</option>
                    <option value="Current">Current</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Type</span>
                  <select name="CType"
                          id="CType"
                          #refCType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CType"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refCType.invalid,
                      'alert-warning':
                        refCType.invalid &&
                        (refCType.dirty ||
                          refCType.touched ||
                          refCType.untouched)
                    }">
                    <option value="">Select Collection Type</option>
                    <option value="M">Variable Amount</option>
                    <option value="F">Fixed Amount</option>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Amount</span>
                  <input type="text"
                         name="CAmt"
                         id="CAmt"
                         #refCAmt="ngModel"
                         required
                         placeholder="Collection Amount"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refCAmt.invalid,
                      'alert-warning':
                        refCAmt.invalid &&
                        (refCAmt.dirty || refCAmt.touched || refCAmt.untouched)
                    }"
                         [(ngModel)]="PaymentModel.CAmt"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Maximum Collection Amount</span>
                  <input type="text"
                         name="MaxAmt"
                         id="MaxAmt"
                         #refMaxAmt="ngModel"
                         required
                         placeholder="Maximum Amount"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refMaxAmt.invalid,
                      'alert-warning':
                        refMaxAmt.invalid &&
                        (refMaxAmt.dirty ||
                          refMaxAmt.touched ||
                          refMaxAmt.untouched)
                    }"
                         [(ngModel)]="PaymentModel.MaxAmt"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Frequency</span>
                  <select name="CFreq"
                          id="CFreq"
                          #refCFreq="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CFreq"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refCFreq.invalid,
                      'alert-warning':
                        refCFreq.invalid &&
                        (refCFreq.dirty ||
                          refCFreq.touched ||
                          refCFreq.untouched)
                    }">
                    <option value="">Select Frequency</option>
                    <option value="DAIL">Daily</option>
                    <option value="WEEK">Weekly</option>
                    <option value="MNTH">Monthly</option>
                    <option value="QURT">Quarterly</option>
                    <option value="MIAN">Semi annually</option>
                    <option value="YEAR">Yearly</option>
                    <option value="BIMN">Bi- monthly</option>
                    <option value="ADHO">As and when presented</option>
                  </select>
                </div>
                <!-- <div class="col-md-3 p-1">
        <span class="required-lable">Collection First Date</span>
        <input type="text" name="StartDate" id="StartDate" #refStartDate ="ngModel" required placeholder="Collection First Date" disabled
          [ngClass]="{ 'is-invalid': ddf.submitted && refStartDate .invalid, 'alert-warning': refStartDate .invalid  && (refStartDate .dirty || refStartDate .touched || refStartDate .untouched) }"
          [(ngModel)]="PaymentModel.StartDate" class="form-control input-text-css" data-value="{{ PaymentModel.StartDate | date: 'dd/MM/yyyy' }}" bsDatepicker>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Collection Last Date</span>
        <input type="text" name="EndDate" id="EndDate" #refEndDate ="ngModel" required placeholder="Collection Last Date"
          [ngClass]="{ 'is-invalid': ddf.submitted && refEndDate .invalid, 'alert-warning': refEndDate .invalid  && (refEndDate .dirty || refEndDate .touched || refEndDate .untouched) }"
          [(ngModel)]="PaymentModel.EndDate" class="form-control input-text-css" [minDate]="minDate"
          data-value="{{ PaymentModel.EndDate | date: 'dd/MM/yyyy' }}" bsDatepicker>
      </div> -->
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection First Date</span>
                  <input type="text"
                         name="StartDate"
                         id="StartDate"
                         #refStartDate="ngModel"
                         required
                         placeholder="Collection First Date"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refStartDate.invalid,
                      'alert-warning':
                        refStartDate.invalid &&
                        (refStartDate.dirty ||
                          refStartDate.touched ||
                          refStartDate.untouched)
                    }"
                         [(ngModel)]="PaymentModel.StartDate"
                          (ngModelChange)="StartDateChange()"
                         class="form-control input-text-css"
                         data-value="{{ PaymentModel.StartDate }}"
                         bsDatepicker />
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Last Date</span>
                  <input type="text"
                         name="EndDate"
                         id="EndDate"
                         #refEndDate="ngModel"
                         required
                         placeholder="Collection Last Date"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refEndDate.invalid,
                      'alert-warning':
                        refEndDate.invalid &&
                        (refEndDate.dirty ||
                          refEndDate.touched ||
                          refEndDate.untouched)
                    }"
                         [(ngModel)]="PaymentModel.EndDate"
                         (ngModelChange)="EndDateChange()"
                         class="form-control input-text-css"
                         data-value="{{ PaymentModel.EndDate }}"
                         bsDatepicker />
                </div>
              </div>
            </div>

            <div [hidden]="true">
              <input type="text"
                     id="ICICI_MerchantCode"
                     name="ICICI_MerchantCode"
                     disabled
                     [(ngModel)]="ICICI_MerchantCode"
                     class="form-control input-text-css" />
              <input type="text"
                     id="ICICI_Salt"
                     name="ICICI_Salt"
                     disabled
                     [(ngModel)]="ICICI_Salt"
                     class="form-control input-text-css" />
              </div>
          </ng-container>

          <ng-container *ngIf="
              PaymentModel.Mandate != '' && PaymentModel.SelectBank == 'AU'
            ">
            <div class="formborder mt-2">
              <div class="row m-0" *ngIf="PaymentModel.SelectBank != ''">
                <div class="col-md-12 bg-success1">Customer Information:</div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Consumer ID (Loan Reference No.)</span>
                  <input type="text"
                         id="ApplicationNo"
                         name="ApplicationNo"
                         #refApplicationNo="ngModel"
                         required
                         placeholder="Loan No."
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refApplicationNo.invalid,
                      'alert-warning':
                        refApplicationNo.invalid &&
                        (refApplicationNo.dirty ||
                          refApplicationNo.touched ||
                          refApplicationNo.untouched)
                    }"
                         [(ngModel)]="PaymentModel.ApplicationNo"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">Full Name</span>
                  <input type="text"
                         name="FullName"
                         id="FullName"
                         #refFullName="ngModel"
                         required
                         placeholder="Full Name"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refFullName.invalid,
                      'alert-warning':
                        refFullName.invalid &&
                        (refFullName.dirty ||
                          refFullName.touched ||
                          refFullName.untouched)
                    }"
                         [(ngModel)]="PaymentModel.FullName"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">E-mail ID</span>
                  <input type="text"
                         id="Email"
                         name="Email"
                         required
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refEmail.invalid,
                      'alert-warning':
                        refEmail.invalid &&
                        (refEmail.dirty ||
                          refEmail.touched ||
                          refEmail.untouched)
                    }"
                         #refEmail="ngModel"
                         placeholder="E-mail id"
                         [(ngModel)]="PaymentModel.Email"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Mobile Number</span>
                  <input type="text"
                         name="MobileNo"
                         id="MobileNo"
                         #refMobileNo="ngModel"
                         required
                         placeholder="MobileNo Number"
                         maxlength="10"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refMobileNo.invalid,
                      'alert-warning':
                        refMobileNo.invalid &&
                        (refMobileNo.dirty ||
                          refMobileNo.touched ||
                          refMobileNo.untouched)
                    }"
                         [(ngModel)]="PaymentModel.MobileNo"
                         class="form-control input-text-css"
                         numbersOnly
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">A/C No</span>
                  <input type="text"
                         id="ACNo"
                         name="ACNo"
                         #refACNo="ngModel"
                         required
                         disabled
                         placeholder="A/C No"
                         [(ngModel)]="PaymentModel.ACNo"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refACNo.invalid,
                      'alert-warning':
                        refACNo.invalid &&
                        (refACNo.dirty || refACNo.touched || refACNo.untouched)
                    }" />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">IFSC Code</span>
                  <input type="text"
                         name="IFSCCode3"
                         id="IFSCCode3"
                         #refIFSCCode3="ngModel"
                         required
                         disabled
                         placeholder="IFSC Code"
                         [ngClass]="{
      'is-invalid': ddf.submitted && refIFSCCode3.invalid,
      'alert-warning':
        refIFSCCode3.invalid &&
        (refIFSCCode3.dirty ||
          refIFSCCode3.touched ||
          refIFSCCode3.untouched)
    }"
                         [(ngModel)]="PaymentModel.IFSCCode"
                         class="form-control input-text-css" />
                </div>

              </div>
            </div>

            <div class="formborder mt-2">
              <div class="row m-0 mt-2">
                <div class="col-md-12 bg-success1">Mandate Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Payment Type</span>
                  <select name="Channel1"
                          id="Channel1"
                          #refChannel="ngModel"
                          (change)="ChannelChangeAU()"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.Channel"
                          [ngClass]="{
      'is-invalid': ddf.submitted && refChannel.invalid,
      'alert-warning':
        refChannel.invalid &&
        (refChannel.dirty ||
          refChannel.touched ||
          refChannel.untouched)
    }">
                    <option value="">Select A/C</option>
                    <option value="DebitCard">Debit Card</option>
                    <option value="NetBanking">NetBank</option>
                    <option value="Aadhaar">Aadhaar</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Your Bank Name</span>
                  <select name="BankIdAU"
                          id="BankIdAU"
                          #refBankIdAU="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.BankIdAU"
                          [ngClass]="{
      'is-invalid': ddf.submitted && refBankIdAU.invalid,
      'alert-warning':
        refBankIdAU.invalid &&
        (refBankIdAU.dirty ||
          refBankIdAU.touched ||
          refBankIdAU.untouched)
    }">
                    <option value="">Select Bank</option>
                    <option *ngFor="let data of livebankList"
                            [value]="data.bankId">
                      {{ data.bankName }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div class="datepicker_feild">
                    <span class="required-lable">Collection First Date</span>

                    <input [matDatepicker]="pickerStartDateAUEND"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="StartDateAUDate1"
                           id="StartDateAUDate1"
                           #refEndDate1="ngModel"
                           [(ngModel)]="PaymentModel.StartDateAU"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                        'is-invalid': ddf.submitted && refEndDate1.invalid,
                        'alert-warning':
                          refEndDate1.invalid &&
                          (refEndDate1.dirty ||
                            refEndDate1.touched ||
                            refEndDate1.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerStartDateAUEND"
                                           style="top: 26px !important"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDateAUEND></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="datepicker_feild">
                    <span class="required-lable">Collection Last Date</span>

                    <input [matDatepicker]="pickerEndDateAUEND"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="EndDate1"
                           id="EndDate1"
                           #refEndDate1="ngModel"
                           [(ngModel)]="PaymentModel.EndDateAU"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                        'is-invalid': ddf.submitted && refEndDate1.invalid,
                        'alert-warning':
                          refEndDate1.invalid &&
                          (refEndDate1.dirty ||
                            refEndDate1.touched ||
                            refEndDate1.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerEndDateAUEND"
                                           style="top: 26px !important"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDateAUEND></mat-datepicker>
                  </div>
                </div>


              </div>

              <div class="row m-0 mt-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Frequency</span>
                  <select name="CFreq"
                          id="CFreq"
                          #refCFreq="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CFreq"
                          [ngClass]="{
      'is-invalid': ddf.submitted && refCFreq.invalid,
      'alert-warning':
        refCFreq.invalid &&
        (refCFreq.dirty ||
          refCFreq.touched ||
          refCFreq.untouched)
    }">
                    <option value="">Select Frequency</option>
                    <option value="DAIL">Daily</option>
                    <option value="WEEK">Weekly</option>
                    <option value="MNTH">Monthly</option>
                    <option value="QURT">Quarterly</option>
                    <option value="MIAN">Semi annually</option>
                    <option value="YEAR">Yearly</option>
                    <option value="BIMN">Bi- monthly</option>
                    <option value="ADHO">As and when presented</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Account Type</span>
                  <select name="ACType"
                          id="ACType"
                          #refACType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.ACType"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refACType.invalid,
                      'alert-warning':
                        refACType.invalid &&
                        (refACType.dirty ||
                          refACType.touched ||
                          refACType.untouched)
                    }">
                    <option value="">Select A/C</option>
                    <option value="SB">Saving</option>
                    <option value="CA">Current</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Type</span>
                  <select name="CType"
                          id="CType"
                          #refCType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CType"
                          [ngClass]="{
                      'is-invalid': ddf.submitted && refCType.invalid,
                      'alert-warning':
                        refCType.invalid &&
                        (refCType.dirty ||
                          refCType.touched ||
                          refCType.untouched)
                    }">
                    <option value="">Select Collection Type</option>
                    <option value="N">Variable Amount</option>
                    <option value="Y">Fixed Amount</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Amount</span>
                  <input type="text"
                         name="CAmt"
                         id="CAmt"
                         #refCAmt="ngModel"
                         required
                         placeholder="Collection Amount"
                         [ngClass]="{
                      'is-invalid': ddf.submitted && refCAmt.invalid,
                      'alert-warning':
                        refCAmt.invalid &&
                        (refCAmt.dirty || refCAmt.touched || refCAmt.untouched)
                    }"
                         [(ngModel)]="PaymentModel.CAmt"
                         class="form-control input-text-css" />
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf=" PaymentModel.SelectBank == 'Nupay'">
            <div class="formborder mt-2">
              <div class="row m-0" *ngIf="PaymentModel.SelectBank != ''">
                <div class="col-md-12 bg-success1">Customer Information:</div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Consumer ID (Loan Reference No.)</span>
                  <input type="text"
                         id="ApplicationNoNupay"
                         name="ApplicationNoNupay"
                         #refApplicationNo="ngModel"
                         required
                         placeholder="Loan No."
                         [ngClass]="{
                  'is-invalid': ddf.submitted && refApplicationNo.invalid,
                  'alert-warning':
                    refApplicationNo.invalid &&
                    (refApplicationNo.dirty ||
                      refApplicationNo.touched ||
                      refApplicationNo.untouched)
                }"
                         [(ngModel)]="PaymentModel.ApplicationNo"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">Full Name</span>
                  <input type="text"
                         name="FullNameNupay"
                         id="FullNameNupay"
                         #refFullName="ngModel"
                         required
                         placeholder="Full Name"
                         [ngClass]="{
                  'is-invalid': ddf.submitted && refFullName.invalid,
                  'alert-warning':
                    refFullName.invalid &&
                    (refFullName.dirty ||
                      refFullName.touched ||
                      refFullName.untouched)
                }"
                         [(ngModel)]="PaymentModel.FullName"
                         class="form-control input-text-css"
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span >E-mail ID</span>
                  <input type="text"
                         id="EmailNupay"
                         name="EmailNupay"
                        
                         #refEmail="ngModel"
                         placeholder="E-mail id"
                         [(ngModel)]="PaymentModel.Email"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="row m-0 mt-1">
                <div class="col-md-4 p-1">
                  <span class="required-lable">Mobile Number</span>
                  <input type="text"
                         name="MobileNoNupay"
                         id="MobileNoNupay"
                         #refMobileNo="ngModel"
                         required
                         placeholder="MobileNo Number"
                         maxlength="10"
                         [ngClass]="{
                  'is-invalid': ddf.submitted && refMobileNo.invalid,
                  'alert-warning':
                    refMobileNo.invalid &&
                    (refMobileNo.dirty ||
                      refMobileNo.touched ||
                      refMobileNo.untouched)
                }"
                         [(ngModel)]="PaymentModel.MobileNo"
                         class="form-control input-text-css"
                         numbersOnly
                         disabled />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">A/C No</span>
                  <input type="text"
                         id="ACNoNupay"
                         name="ACNoNupay"
                         #refACNo="ngModel"
                         required
                         disabled
                         placeholder="A/C No"
                         [(ngModel)]="PaymentModel.ACNo"
                         class="form-control input-text-css"
                         [ngClass]="{
                  'is-invalid': ddf.submitted && refACNo.invalid,
                  'alert-warning':
                    refACNo.invalid &&
                    (refACNo.dirty || refACNo.touched || refACNo.untouched)
                }" />
                </div>
                <div class="col-md-4 p-1">
                  <span class="required-lable">IFSC Code</span>
                  <input type="text"
                         name="IFSCCodeNupay"
                         id="IFSCCodeNupay"
                         #refIFSCCode3="ngModel"
                         required
                         disabled
                         placeholder="IFSC Code"
                         [ngClass]="{
  'is-invalid': ddf.submitted && refIFSCCode3.invalid,
  'alert-warning':
    refIFSCCode3.invalid &&
    (refIFSCCode3.dirty ||
      refIFSCCode3.touched ||
      refIFSCCode3.untouched)
}"
                         [(ngModel)]="PaymentModel.IFSCCode"
                         class="form-control input-text-css" />
                </div>

              </div>
            </div>

            <div class="formborder mt-2">
              <div class="row m-0 mt-2">
                <div class="col-md-12 bg-success1">Mandate Information:</div>
              </div>
              <div class="row m-0">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Payment Type</span>
                  <select name="ChannelNupay"
                          id="ChannelNupay"
                          #refChannel="ngModel"
                          (change)="ChannelChangeNupay()"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.Channel"
                          [ngClass]="{
  'is-invalid': ddf.submitted && refChannel.invalid,
  'alert-warning':
    refChannel.invalid &&
    (refChannel.dirty ||
      refChannel.touched ||
      refChannel.untouched)
}">
                    <option value="">Select A/C</option>
                    <option value="DebitCard">Debit Card</option>
                    <option value="NetBanking">NetBank</option>
                    <option value="Aadhaar">Aadhaar</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Your Bank Name</span>
                  <select name="BankIdNupay"
                          id="BankIdNupay"
                          #refBankIdAU="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.BankIdAU"
                          [ngClass]="{
  'is-invalid': ddf.submitted && refBankIdAU.invalid,
  'alert-warning':
    refBankIdAU.invalid &&
    (refBankIdAU.dirty ||
      refBankIdAU.touched ||
      refBankIdAU.untouched)
}">
                    <option value="">Select Bank</option>
                    <option *ngFor="let data of livebankList"
                            [value]="data.id">
                      {{ data.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div class="datepicker_feild">
                    <span class="required-lable">Collection First Date</span>

                    <input [matDatepicker]="pickerStartDateNupayEND"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="StartDateNupay"
                           id="StartDateNupay"
                           #refEndDate1="ngModel"
                           [(ngModel)]="PaymentModel.StartDateAU"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                    'is-invalid': ddf.submitted && refEndDate1.invalid,
                    'alert-warning':
                      refEndDate1.invalid &&
                      (refEndDate1.dirty ||
                        refEndDate1.touched ||
                        refEndDate1.untouched)
                  }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerStartDateNupayEND"
                                           style="top: 26px !important"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDateNupayEND></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="datepicker_feild">
                    <span class="required-lable">Collection Last Date</span>

                    <input [matDatepicker]="pickerEndDateNupayEND"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="EndDateNupay"
                           id="EndDateNupay"
                           #refEndDate1="ngModel"
                           [(ngModel)]="PaymentModel.EndDateAU"
                           required
                           class="form-control input-text-css"
                           [ngClass]="{
                    'is-invalid': ddf.submitted && refEndDate1.invalid,
                    'alert-warning':
                      refEndDate1.invalid &&
                      (refEndDate1.dirty ||
                        refEndDate1.touched ||
                        refEndDate1.untouched)
                  }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="pickerEndDateNupayEND"
                                           style="top: 26px !important"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDateNupayEND></mat-datepicker>
                  </div>
                </div>


              </div>

              <div class="row m-0 mt-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Frequency</span>
                  <select name="CFreqNupay"
                          id="CFreqNupay"
                          #refCFreq="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CFreq"
                          [ngClass]="{
  'is-invalid': ddf.submitted && refCFreq.invalid,
  'alert-warning':
    refCFreq.invalid &&
    (refCFreq.dirty ||
      refCFreq.touched ||
      refCFreq.untouched)
}">
                    <option value="">Select Frequency</option>
                    <option value="DAIL">Daily</option>
                    <option value="WEEK">Weekly</option>
                    <option value="MNTH">Monthly</option>
                    <option value="QURT">Quarterly</option>
                    <option value="MIAN">Semi annually</option>
                    <option value="YEAR">Yearly</option>
                    <option value="BIMN">Bi- monthly</option>
                    <option value="ADHO">As and when presented</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Account Type</span>
                  <select name="ACTypeNupay"
                          id="ACTypeNupay"
                          #refACType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.ACType"
                          [ngClass]="{
                  'is-invalid': ddf.submitted && refACType.invalid,
                  'alert-warning':
                    refACType.invalid &&
                    (refACType.dirty ||
                      refACType.touched ||
                      refACType.untouched)
                }">
                    <option value="">Select A/C</option>
                    <option value="Savings">Saving</option>
                    <option value="Current">Current</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Collection Type</span>
                  <select name="CTypeNupay"
                          id="CTypeNupay"
                          #refCType="ngModel"
                          class="form-control input-text-css"
                          required
                          [(ngModel)]="PaymentModel.CType"
                          [ngClass]="{
                  'is-invalid': ddf.submitted && refCType.invalid,
                  'alert-warning':
                    refCType.invalid &&
                    (refCType.dirty ||
                      refCType.touched ||
                      refCType.untouched)
                }">
                    <option value="">Select Collection Type</option>
                    <option value="false">Variable Amount</option>
                    <option value="true">Fixed Amount</option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Amount</span>
                  <input type="text"
                         name="CAmtNupay"
                         id="CAmtNupay"
                         #refMaxAmt="ngModel"
                         required
                         placeholder="Collection Amount"
                         [ngClass]="{
                  'is-invalid': ddf.submitted && refMaxAmt.invalid,
                  'alert-warning':
                    refMaxAmt.invalid &&
                    (refMaxAmt.dirty || refMaxAmt.touched || refMaxAmt.untouched)
                }"
                         [(ngModel)]="PaymentModel.MaxAmt"
                         class="form-control input-text-css" />
                </div>
              </div>
            </div>
          </ng-container>

        </div>

        <div class="modal-footer" *ngIf="PaymentModel.SelectBank == 'ICICI'">
          <button type="button"
                  (click)="onClosePaymentModel()"
                  class="btn font-size-12 button-btn mr-2"
                  data-dismiss="modal">
            Cancel
          </button>
          <button type="button"
                  id="btn_Submit"
                  class="btn font-size-12 button-btn"
                  [disabled]="!ddf.form.valid">
            Pay Now
          </button>
        </div>

        <div class="modal-footer" *ngIf="PaymentModel.SelectBank == 'HDFC'">
          <button type="button"
                  (click)="onClosePaymentModel()"
                  class="btn font-size-12 button-btn mr-2"
                  data-dismiss="modal">
            Cancel
          </button>
          <button type="button"
                  id="btn_SubmitHDFC"
                  class="btn font-size-12 button-btn"
                  [disabled]="!ddf.form.valid">
            Pay Now
          </button>
        </div>

        <div class="modal-footer" *ngIf="PaymentModel.SelectBank == 'Nupay'">
          <button type="button"
                  (click)="onClosePaymentModel()"
                  class="btn font-size-12 button-btn mr-2"
                  data-dismiss="modal">
            Cancel
          </button>
          <button type="button"
                  id="btn_SubmitNupay"
                  (click)="ENachNupaySaveNachInfo()"
                  class="btn font-size-12 button-btn"
                  [disabled]="!ddf.form.valid">
            Pay Now
          </button>
        </div>

        <div class="modal-footer" *ngIf="PaymentModel.SelectBank == 'AU'">
          <button type="button"
                  (click)="onClosePaymentModel()"
                  class="btn font-size-12 button-btn mr-2"
                  data-dismiss="modal">
            Cancel
          </button>
          <button type="button"
                  id="btn_SubmitAU"
                  (click)="ENachAUSaveNachInfo()"
                  class="btn font-size-12 button-btn"
                  [disabled]="!ddf.form.valid">
            Pay Now
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Make payment Request and Response code start here -->
<div style="display: none">
  <span id="Request"></span>
  <span id="Response"></span>
  <span id="Status"></span>
  <span id="Identifier"></span>
  <span id="UMRNNumber"></span>
  <button type="button"
          id="Click"
          class="btn font-size-12 button-btn"
          (click)="SavePayment()">
    Click
  </button>
  <button type="button"
          id="ClickHdfc"
          class="btn font-size-12 button-btn"
          (click)="ENachHdfcSaveNachInfo()">
    Click
  </button>

  <!--<button type="button"
          id="ClickAU"
          class="btn font-size-12 button-btn"
          (click)="ENachAUSaveNachInfo()">
    Click
  </button>-->
</div>
<!-- Make payment Request and Response code end here -->

<form #form
      method="POST"
      style="display: none"
      target="_blank"
      enctype="application/x-www-form-urlencoded"
      action="https://emandate.hdfcbank.com/Emandate.aspx">
  <div class="main">
    <div class="header">
      <div class="container">
        <a href="https://www.hdfcbank.com/" class="logo">
          <img alt="HDFC Bank" src="images/logo.png" />
        </a>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <h2>MerchantPost</h2>
        <div class="box-gray">
          <div class="fg-middle">
            <div class="fg-group">
              <label>Utilcode *</label>

              <input name="UtilCode"
                     type="text"
                     id="UtilCode"
                     class="changeencrpt" />
            </div>
            <div class="fg-group">
              <label>Short Code *</label>

              <input name="Short_Code"
                     type="text"
                     class="changeencrpt"
                     id="Short_Code" />
            </div>

            <div class="fg-group">
              <label>Merchant_Category_Code *</label>
              <input name="Merchant_Category_Code"
                     type="text"
                     maxlength="4"
                     id="Merchant_Category_Code" />
            </div>

            <div class="fg-group">
              <label>CheckSum *</label>
              <input name="CheckSum" type="text" id="CheckSum" />
            </div>
            <div class="fg-group">
              <label>MsgId *</label>
              <input name="MsgId" type="text" id="MsgId" />
            </div>
            <div class="fg-group">
              <label>Customer_Name *</label>

              <input name="Customer_Name"
                     type="text"
                     id="Customer_Name"
                     class="changeencrpt" />
            </div>

            <div class="fg-group">
              <label>Customer_Mobile </label>

              <input name="Customer_Mobile"
                     type="text"
                     id="Customer_Mobile"
                     class="changeencrpt" />
            </div>

            <div class="fg-group">
              <label>Customer_AccountNo *</label>

              <input name="Customer_AccountNo"
                     type="text"
                     id="Customer_AccountNo"
                     class="changeencrpt" />
            </div>
            <div class="fg-group">
              <label>Customer_StartDate *</label>
              <input name="Customer_StartDate"
                     type="text"
                     id="Customer_StartDate" />
            </div>
            <div class="fg-group">
              <label>Customer_ExpiryDate </label>
              <input name="Customer_ExpiryDate"
                     type="text"
                     id="Customer_ExpiryDate" />
            </div>
            <div class="fg-group">
              <label>Customer_DebitAmount *</label>
              <input name="Customer_DebitAmount"
                     type="text"
                     id="Customer_DebitAmount" />
            </div>
            <div class="fg-group">
              <label>Customer_MaxAmount</label>
              <input name="Customer_MaxAmount"
                     type="text"
                     id="Customer_MaxAmount" />
            </div>
            <div class="fg-group">
              <label>Customer_DebitFrequency *</label>
              <input name="Customer_DebitFrequency"
                     type="text"
                     id="Customer_DebitFrequency" />
            </div>

            <div class="fg-group">
              <label>Customer_SequenceType *</label>
              <input name="Customer_SequenceType"
                     type="text"
                     id="Customer_SequenceType" />
            </div>
            <div class="fg-group">
              <label>IFSC Code * </label>
              <input name="Customer_InstructedMemberId"
                     type="text"
                     id="Customer_InstructedMemberId" />
            </div>

            <div class="fg-group">
              <label>Channel *</label>
              <input name="Channel" type="text" id="Channel" />
            </div>

            <div class="fg-group">
              <input name="Filler5" type="text" value="Filler5" id="Filler5" />
            </div>
            <div class="fg-group">
              <input name="Filler6" type="text" value="Filler6" id="Filler6" />
            </div>

            <div class="fg-group">
              <input type="submit"
                     name="GenCheckSum"
                     value="CheckSum"
                     id="GenCheckSum" />
              <input type="submit"
                     id="FinalSubmitHdfc"
                     value="Submit"
                     (click)="form.submit()" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>



