import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-los-customer-view-model",
  templateUrl: "./los-customer-view-model.component.html",
  styleUrls: ["./los-customer-view-model.component.scss"],
})
export class LosCustomerViewModelComponent implements OnInit {
  @Input() customerId: any;
  @Input() index: any;
  CustomerViewDetailRequestModel: RequestModel = new RequestModel();
  showSpinner: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  currentUser: any;
  customerData: any = {};

  zoom: number = 13;
  lat: any = 26.92207;
  lng: any = 75.778885;
  ShowMapAddress: any = "";

  // address
  addressList: any = [];
  dataSourceAddress: any;
  displayedColumnsAddress: string[] = [
    "AddressId",
    "AddressType",
    "Address",
    "LandMark",
    "Tehsil_Name",
    "District_Name",
    "PinCode",
    "AddressRentBuy",
    "TotalYearsOnAddress",
    "IsCommunicationAddress",
  ];
  @ViewChild("SortAddress", { static: true }) SortAddress: MatSort;
  // Category
  CategoryList: any = [];
  dataSourceCategory: any;
  @ViewChild("CategorySort", { static: true }) CategorySort: MatSort;
  displayedColumnsCategory: string[] = [
    "CategoyId",
    "Categoy",
    "Doc_Name",
    "KYC_DocNumber",
    "UploadOn",
    "EditAction",
  ];
  // Application
  ApplicationList: any = [];
  dataSourceApplication: any;
  @ViewChild("ApplicationSort", { static: true }) ApplicationSort: MatSort;
  displayedColumnsApplication: string[] = [
    "ApplicationId",
    "ApplicationNo",
    "LoanAcNo",
    "CustomerType",
    "Product",
    "Branch",
    "LoanAmount",
    "CreateOn",
    "Total_OverDUE_EMI_Amount",
    "Balance_Principle",
    "Application_Status"
  ];

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private encdec: EncrDecrService
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  onOpenApplicationMapModal(data: any) {
    // console.log(data.ApplicationId);
    // var appid = this.encdec.encrypt(data.ApplicationId);
    const url = this.router.createUrlTree([
      `/#/LOS/application/${encodeURIComponent(data.ApplicationId)}`,
    ]);

    window.open(url.toString().replace("%23", "#"), "_blank");
  }
  onOpenViewDetailModal() {
    this.CustomerViewDetailRequestModel.CustomerId = this.customerId;
    this._MasterService
      .Get_CustomerDetails(this.CustomerViewDetailRequestModel)
      .subscribe((result: any) => {
        if (result != null) {
          this.customerData = result.Item1[0];
          // address
          this.addressList = result.Item2;
          this.dataSourceAddress = new MatTableDataSource(this.addressList);
          this.dataSourceAddress.sort = this.SortAddress;
          // category
          this.CategoryList = result.Item3;
          this.dataSourceCategory = new MatTableDataSource(this.CategoryList);
          this.dataSourceCategory.sort = this.CategorySort;
          // application
          this.ApplicationList = result.Item4;
          this.dataSourceApplication = new MatTableDataSource(
            this.ApplicationList
          );
          this.dataSourceApplication.sort = this.ApplicationSort;

          $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("show");
          $(`#LosSearchCustomerViewDetailModal${this.index}`).css(
            "z-index",
            "1050"
          );
        }
      });
  }

  OnClose() {
    $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("hide");
    this.customerData = {};
    this.addressList = [];
    this.CategoryList = [];
    this.ApplicationList = [];
  }

  onOpenAddressMapModal(row: any) {
    if (row.LatLong) {
      this.lat = row.LatLong.split(",")[0];
      this.lng = row.LatLong.split(",")[1];
      this.ShowMapAddress =
        row.Address +
        ", " +
        row.Tehsil_Name +
        ", " +
        row.District_Name +
        " - " +
        row.PinCode;
      $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("show");
      $(`#LosSearchCustomerViewDetailModal${this.index}`).css(
        "z-index",
        "1500"
      );
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Latitude and Longitude not provided for this address.`,
        ...this.configSuccess,
      });
    }
  }

  onCloseAddressMapModal() {
    this.ShowMapAddress = "";
    $(`#LosSearchCustomerViewDetailModal${this.index}`).modal("hide");
  }
}
