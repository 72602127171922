<div class="card-container" style="padding:0;height:100%">
    <div class="title-head"
        style="border-bottom: 1px solid black;background-color: #4dc3a3;border-radius: 9px 9px 0 0 ;">
        <h1 class="main-tit" style="line-height: 2.2;margin:0;">Income-Expenses Details</h1>
    </div>
    <div style="min-height: 100%;" class="text-center" *ngIf="!incomeData">
        No Information Available!!
    </div>
    <div class="cnt-data" style="margin:10px" *ngIf="incomeData">
        <div class="income">
            <div class="name-sub" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
                <h1 style="margin-bottom: 2px;">Income Details</h1>
            </div>
            <div class="cont">
                <div *ngFor="let item of incomeData">
                    <div class="cont-Card" *ngIf="item['Type']=='Income'">
                        <div class="grd">
                            <div class="grd-item">Nature: {{item["Nature"]}}</div>
                            <div class="grd-item">Amount: {{item["Amount"]}}</div>
                            <div class="grd-item">Source: {{item["Institution_From"]}} </div>
                            <div class="grd-item"><a target="_blank" *ngIf="item.Doc_FileName" [href]="income+item['Customer_Id']+'/'+item['Doc_FileName']"><i class="fa fa-paperclip"  aria-hidden="true"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="expense">
            <div class="name-sub" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
                <h1 style="margin-bottom: 2px;">Expenses Details</h1>
            </div>
            <div class="cont">
                <div *ngFor="let item of incomeData">
                    <div class="cont-Card" *ngIf="item['Type']=='Expense'">
                        <div class="grd">
                            <div class="grd-item">Nature: {{item["Nature"]}}</div>
                            <div class="grd-item">Amount: {{item["Amount"]}}</div>
                            <div class="grd-item">Type: {{item["Institution_From"]}} </div>
                            <div class="grd-item"><a target="_blank" *ngIf="item.Doc_FileName" [href]="income+item['Customer_Id']+'/'+item['Doc_FileName']"><i class="fa fa-paperclip"  aria-hidden="true"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>