<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onOverDueInterest()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            #refVoucherDateFrom="ngModel"
            (dateChange)="DateChange()"
            required
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-2">
        <span class="required-lable">To Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            (dateChange)="DateChange()"
            required
            #reffVoucherDateTo="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            [ngClass]="{
              'is-invalid': df.submitted && reffVoucherDateTo.invalid,
              'alert-warning':
                reffVoucherDateTo.invalid &&
                (reffVoucherDateTo.dirty ||
                  reffVoucherDateTo.touched ||
                  reffVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-1 text-right">
        <button
          type="button"
          (click)="onOverDueInterest()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-4 ml-5 mt-2">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <table
          class="table-bordered"
          style="max-width: 100%; overflow: auto; height: 65vh"
          matSort
          mat-table
          [dataSource]="dataSource"
          matTableExporter
          #exporter="matTableExporter"
          id="OverDueInterest"
          [hidden]="!dataSource"
        >
          <ng-container matColumnDef="Loan_Id">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Application No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Application_No }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Loan Ac No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Product Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Product_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Branch Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="CollectionExecutive">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Collection Executive
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.CollectionExecutive }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OD_Interest_Opening">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              OD Opening
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.OD_Interest_Opening }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OD_Interest_Closing">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              OD Closing
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.OD_Interest_Closing }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OD_Interest_Received">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              OD Received
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.OD_Interest_Received }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OD_Interest_Addition">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              OD Addition
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.OD_Interest_Addition }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
          <tr
            mat-footer-row
            class="fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>

        <div class="row m-0 ml-3" [hidden]="!dataSource">
          <div class="col-md-4">
            <button
              class="mt-2 btn font-size-12 button-btn"
              (click)="exportexcelNew()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="overDueInterest"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD_Interest_Opening
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD_Interest_Closing
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD_Interest_Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD_Interest_Addition
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>{{ x.OD_Interest_Opening }}</td>
    <td>{{ x.OD_Interest_Closing }}</td>
    <td>{{ x.OD_Interest_Received }}</td>
    <td>{{ x.OD_Interest_Addition }}</td>
  </tr>
</table>
