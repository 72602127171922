<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="col-md-12 d-flex justify-content-between formborder mb-2">
  <div class="col-md-4 mt-3 ">
    <!-- [hidden]="!DataSource" -->
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-2 mt-2">
    <button
      type="button"
      (click)="updateDiffEmiAllocation()"
      class="mt-3 btn font-size-12 button-btn"
    >
      Update
    </button>
  </div>
</div>

<div class="mt-3" [hidden]="!DataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        class="table-bordered"
        matSort
        [dataSource]="DataSource"
        matTableExporter
        #exporter="matTableExporter"
        style="overflow: auto;max-width: 100%;height: 55vh;"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Loan Date</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.Loan_Date }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LoanClosed_Date">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Closed Date</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.LoanClosed_Date }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Loan Ac No</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.CustomerName }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Branch</mat-header-cell>
          <mat-cell *matCellDef="let row" style="text-align: right">
            {{ row.Branch_Name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Product</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Product_Name }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-center"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="diff">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Difference</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.diff }}</mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-center"></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>

<div class="row m-0 ml-3" [hidden]="!DataSource">
  <div class="col-md-4 mt-3">
    <button class="mt-1 btn font-size-12 button-btn" (click)="exportExcel()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-1">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="diff-emi-allocation"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Close Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Difference
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.LoanClosed_Date }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td style="text-align: center;">{{ x.Product_Name }}</td>
    <td>{{ x.diff }}</td>
  </tr>
</table>
