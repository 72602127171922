<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #f="ngForm"
    (keydown.enter)="onSearchCustomerDetail()"
    novalidate
    class="row m-0 col-md-12 p-0 formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span>Type</span>
        <select
          name="Type"
          id="Type"
          class="form-control input-text-css"
          [(ngModel)]="Type"
        >
          <option [value]="0">All</option>
          <option [value]="1">LOS Only</option>
          <option [value]="2">LMS Only</option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Application No.</span>
        <input
          name="SApplicationNo"
          [(ngModel)]="Customer.ApplicationNo"
          id="SApplicationNo"
          placeholder="Application No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Loan No.</span>
        <input
          name="SLoanNo"
          [(ngModel)]="Customer.LoanNo"
          id="SLoanNo"
          placeholder="Loan No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Customer Name</span>
        <input
          name="CustomerName"
          [(ngModel)]="Customer.CustomerName"
          id="CustomerName"
          placeholder="Customer Name"
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-2">
        <span>Customer Phone</span>
        <input
          name="CustomerPhone"
          [(ngModel)]="Customer.CustomerPhone"
          id="CustomerPhone"
          placeholder="Customer Phone"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>PAN Card</span>
        <input
          name="PANCard"
          [(ngModel)]="Customer.PANCard"
          id="PANCard"
          placeholder="PAN Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Passport No.</span>
        <input
          name="Passport"
          [(ngModel)]="Customer.Passport"
          id="Passport"
          placeholder="Passport No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Voter ID Card</span>
        <input
          name="VoterID"
          [(ngModel)]="Customer.VoterID"
          id="VoterID"
          placeholder="Voter ID Card"
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-2">
        <span>Driving License</span>
        <input
          name="DL"
          [(ngModel)]="Customer.DL"
          id="DL"
          placeholder="Driving License"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Aadhaar Card</span>
        <input
          name="Aadhaar"
          [(ngModel)]="Customer.Aadhaar"
          id="Aadhaar"
          placeholder="Aadhaar Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Vehicle No</span>
        <input
          name="VehicleNo"
          [(ngModel)]="Customer.VehicleNo"
          id="VehicleNo"
          placeholder="Vehicle No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Close Case</span>
        <select
          name="CloseCase"
          id="CloseCase"
          class="form-control input-text-css"
          [(ngModel)]="Customer.CloseCase"
        >
          <option value="">Select Close Case</option>
          <option value="1">Close Case</option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateFrom"
            id="LoanDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="Customer.LoanDateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="LoanDateFrom"
          [(ngModel)]="Customer.LoanDateFrom"
          id="LoanDateFrom"
          placeholder="Date From"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
      <div class="col-md-2">
        <span>Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateTo"
            id="LoanDateTo"
            class="form-control input-text-css"
            [(ngModel)]="Customer.LoanDateTo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="LoanDateTo"
          [(ngModel)]="Customer.LoanDateTo"
          id="LoanDateTo"
          placeholder="Date To"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
      <div class="col-md-2">
        <span>Select Type</span>
        <select
          name="SelectType"
          id="SelectType"
          class="form-control input-text-css"
          [(ngModel)]="SelectType"
          (change)="SearchChange()"
        >
          <option value="">Select Type</option>
          <option value="Branch">Branch</option>
          <option value="CollArea">Collection Area</option>
          <option value="CollSubArea">Collection Sub Area</option>
          <option value="CollExecutive">Collection Executive</option>
          <option value="SalesExecutive">Sales Executive</option>
          <option value="EngineNo">Engine No.</option>
          <option value="ChassisNo">Chassis No.</option>
          <option value="LoanAccCatg">Loan A/C Category</option>
        </select>
      </div>
      <div class="col-md-2">
        <div *ngIf="SelectType == 'Branch'">
          <span> Branch</span>
          <select
            name="BranchId"
            id="BranchId"
            #refSelectGender="ngModel"
            [(ngModel)]="Customer.BranchId"
            class="form-control input-text-css"
          >
            <option value="">Select Branch</option>
            <option
              *ngFor="let branch of BranchesList"
              [value]="branch.BranchId"
            >
              {{ branch.Branch_Name }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'EngineNo'">
          <span>Engine No.</span>
          <input
            name="EngineNo"
            [(ngModel)]="Customer.EngineNo"
            id="EngineNo"
            placeholder="Engine No."
            class="form-control input-text-css"
          />
        </div>

        <div *ngIf="SelectType == 'ChassisNo'">
          <span>Chassis No.</span>
          <input
            name="ChassisNo"
            [(ngModel)]="Customer.ChassisNo"
            id="ChassisNo"
            placeholder="Chassis No."
            class="form-control input-text-css"
          />
        </div>

        <div *ngIf="SelectType == 'CollArea'">
          <span> Collection Area</span>
          <select
            name="Area"
            id="Area"
            [(ngModel)]="Customer.Area"
            class="form-control input-text-css"
          >
            <option value="">Select Collection Area</option>
            <ng-container *ngFor="let area of areaData">
              <option
                *ngIf="area.Type.trim() == 'Collection Area'"
                [value]="area.Int_Id"
              >
                {{ area.Area_Name }}
              </option>
            </ng-container>
          </select>
        </div>

        <div *ngIf="SelectType == 'CollSubArea'">
          <span> Collection Sub Area</span>
          <select
            name="SubArea"
            id="SubArea"
            [(ngModel)]="Customer.SubArea"
            class="form-control input-text-css"
          >
            <option value="">Select Collection SubArea</option>
            <option
              *ngFor="let subarea of subAreaData"
              [value]="subarea.Int_Id"
            >
              {{ subarea.Sub_Area_Name }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'SalesExecutive'">
          <span> Sales Executive</span>
          <select
            name="SalesEx"
            id="SalesEx"
            [(ngModel)]="Customer.SalesEx"
            class="form-control input-text-css"
          >
            <option value="">Select Sales Executive</option>
            <option
              *ngFor="let SalesExec of SalesExecutiveData"
              [value]="SalesExec.EmpId"
            >
              {{ SalesExec.EmpName }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'CollExecutive'">
          <span> Collection Executive</span>
          <select
            name="CollectionEx"
            id="CollectionEx"
            [(ngModel)]="Customer.CollectionEx"
            class="form-control input-text-css"
          >
            <option value="">Select Collection Executive</option>
            <option
              *ngFor="let CollExec of CollectionExecutiveData"
              [value]="CollExec.EmpId"
            >
              {{ CollExec.Emp_FirstName }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'LoanAccCatg'">
          <span>Accounting Category </span>
          <select
            name="LoanAccountingCatId"
            id="LoanAccountingCatId"
            [(ngModel)]="Customer.LoanAccountingCatId"
            class="form-control input-text-css"
          >
            <option value="">Select Accounting Category</option>
            <option
              *ngFor="let AcCat of AccCatDropdown"
              [value]="AcCat.Loan_CategoryId"
            >
              {{ AcCat.Loan_category }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <button
          type="button"
          (click)="onSearchCustomerDetail()"
          class="mt-3 btn font-size-12 button-btn"
          style="width: 45%"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-2" [hidden]="!dataSourceCustomer">
    <div>
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceCustomer"
          #sortList="matSort"
          matSort
          id="exportReport"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Aplication No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_No
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomertName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomertName }}({{ row.AC_CustomerType }})
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FatherName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Relation Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.FatherName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw50"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
              row.Customer_Gender
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw50"
              *matHeaderCellDef
              >Age</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
              row.GenderAge
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="currentUser.IsMasking">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMasking">
                {{ row.PhoneNo }}
              </div>

              <i
                *ngIf="row.Customer_PhoneNo_IsVerified == true"
                class="fa fa-check-circle ml-2"
                style="color: green"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EditAction">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw50"
              *matHeaderCellDef
              >View</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center mw50">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedCustomerColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(i)"
            [class.highlight]="i == selectedId"
            *matRowDef="
              let row;
              let i = index;
              columns: displayedCustomerColumns
            "
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4 mt-3">
            <button class="btn font-size-12 button-btn" (click)="exportTable()">
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_loan_statement_search"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gender
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">Age</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
  </tr>
  <tr *ngFor="let x of CustomerList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Type }}</td>
    <td>{{ x.Application_No }}</td>

    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomertName }}</td>
    <td>{{ x.FatherName }}</td>
    <td>{{ x.Customer_Gender }}</td>
    <td>{{ x.GenderAge }}</td>
    <td>{{ x.PhoneNo }}</td>
  </tr>
</table>
