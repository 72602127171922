import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
import { HttpClient } from '@angular/common/http';
import { MenusModel, newMenuModal, newMenuModalNew } from '../../_LOS/_layout/Models/MenusModel';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { catchError, tap, filter } from 'rxjs/operators';
import { SnackbarComponent } from '../../snackbar/snackbar.component'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogService } from '../../Shared/dialog.service'
import { LmsService } from "../services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;

@Component({
  selector: 'app-lms-accounting-layout',
  templateUrl: './lms-accounting-layout.component.html',
  styleUrls: ['./lms-accounting-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(400))
    ])
  ]
})
export class LmsAccountingLayoutComponent implements OnInit {

  //#region < Variables declarations >
  private data: any;
  token: any;
  showSpinner = false;
  activeIcon: string;
  private _userservice;
  MenusModel: MenusModel = new MenusModel();
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  currentDate = new Date();
  todayDate = "";
  // todayDate = this.currentDate.toLocaleDateString("en-US", this.options)
  Menuslinks: any;
  CheckLoad: any;
  username = "";
  setAutoHide: boolean = false;
  autoHide: number = 2000;
  action: boolean = false;
  actionButtonLabel: string = 'Retry';
  show: boolean = false
  plusMinus: any = '+';
  checkIn: boolean = false;
  active: any = "";
  recentActiveCheckIn: boolean = false;
  recentActive: any = "";
  lastaccess: Date;
  currentlogin: Date;
  MenuslinksNew: any[];
  mysubscription: any;
  activeroute: any; dialogreturn: any;
  liveclock: any;
  FyearId: any;
  BranchId: any = 0;
  //#endregion
  BranchesList: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  HeaderTitle: string = '';
 FyearDropdown: any[] = [];
  //  sidemenu
  sidemenus = [ 
     
    {
      MM_ID: 2,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Group Master",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/group-master",
      MM_iconName: "fa fa-users"        
    },
    {
      MM_ID: 3,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Accounting Master",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/accounting-master",
      MM_iconName: "fa fa-calculator"
       

    },
    {
      MM_ID: 4,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Receipt Entry",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/losreceiptentry",
      MM_iconName: "fa fa-receipt"
    },
    {
      MM_ID: 5,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Voucher Entry",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/voucher/voucher-receipt",
      MM_iconName: "fa fa-money-check"
    },
    {
      MM_ID: 6,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Cash Book",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/accounting-statement",
      MM_iconName: "fa fa-calculator"
    },
    {
      MM_ID: 7,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Bank Book",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/accounting-statement",
      MM_iconName: "fa fa-calculator"
    },
    {
      MM_ID: 8,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Accounting Statement",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/accounting-statement",
      MM_iconName: "fa fa-calculator"
    }, {
      MM_ID: 9,
      MM_IsParent: false,
      MM_IsShow: true,
      MM_Name: "Trail Balance",
      MM_ParentID: 0,
      MM_Url: "/lms-accounting/accounting-statement",
      MM_iconName: "fa fa-calculator"
    }
  ]

  //#region < Constructor AppUserLayoutComponent >
  constructor(private _Route: Router, public snackBar: MatSnackBar, private http: HttpClient, private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private MasterService: MasterService,private lmsService: LmsService, private _location: Location, private dataSharingService: DataSharingService, private router: Router, private dialog: DialogService) {
    this.startTime();
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  //  this.username = this.data.username;
   // this.lastaccess = new Date(this.data.lastaccess + 'Z');
   // this.currentlogin = this.data.currentlogin;
    //this._userservice = _userlayoutservice;
    //this.Menuslinks = this._userlayoutservice.getNewMenuList(this.data.userId);
   // this.MenuslinksNew = newMenuModal[0]; //JSON.parse(sessionStorage.getItem("CheckLoad"));

    //console.log('this.MenuslinksNew', this.MenuslinksNew);
  }

  startTime() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();
    m = this.checkTime(m);
    s = this.checkTime(s);
    var session = "AM"
    if (h == 0) { h = 12 }
    if (h > 12) {
      h = h - 12;
      h = this.checkTime(h);
      session = "PM"
    }
    this.liveclock = h + ":" + m + ":" + s + " " + session;
    var t = setTimeout(() => { this.startTime() }, 500);
  }
  checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 0) {
      this._Route.navigate(['/lms-customer']);
    } else if (tabChangeEvent.index == 1) {
      this._Route.navigate(['/financial-detail']);
    } else if (tabChangeEvent.index == 2) {
      this._Route.navigate(['/lms-account']);
    }
  }

  tabChangeds(tab) {
    if (tab == 0) {
      this._Route.navigate(['/lms-customer']);
      return true;
    }
    if (tab == 1) {
      this._Route.navigate(['/financial-detail']);
      return true;
    }
    if (tab == 2) {
      this._Route.navigate(['/lms-account']);
      return true;
    }
  }

  //#endregion

  //#region < onsubsubmenuclick>
  onsubsubmenuclick(e) {
    $('.nav').on('click', function (e) {
      //if ($(this).hasClass('dropdown-menu-form')) {
      e.stopPropagation();
      // }
    });
  }
  //#endregion

  //#region < onclick>
  onclick(typ) {
  }
  //#endregion

  onMenuclick(row) {
    this.getPageAccess(row);
    // this.GroupMaster = true;
  }

  //#region < isLinkActive>
  public isLinkActive(url: string): boolean {
    return this._Route.url.indexOf(url) > -1;
  }

  //#endregion

  ngOnInit() {
    if (!this.dataSharingService.getMenuItemByType('Accounting')) {
      this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'Accounting' }).subscribe((res: any) => {
        this.dataSharingService.setMenuItem('Accounting', res);
      });
    }
    this.dataSharingService.getMenuItem().subscribe((res:any)=>{
      if(res['Accounting']){
        this.MenuslinksNew=res['Accounting'];
      }
    });
    this.dataSharingService.getHeaderTitle().subscribe(value => {
      this.HeaderTitle = value||"LMS Dashboard";
    });
    this.getFyear();
    this.getBranchesList();
  }
  getFyear() {
   this.lmsService.Get_FinancialYear({ Id: 0 }).subscribe((res: any) => {
     this.FyearDropdown = res;
     this.FyearId = this.FyearDropdown[this.FyearDropdown.length - 1].Id;
     sessionStorage.setItem("FyearId", JSON.stringify(this.FyearId));
     
   });
  }
  onChangeFyear(event) {
   // return this._Route.url.indexOf(url) > -1;
    console.log("event", this._Route.url);
    var Url = this._Route.url.split('?')[0];
    sessionStorage.setItem("FyearId", JSON.stringify(this.FyearId));
    //+ '? fyear=' + this.FyearId + '& BranchId=' + this.BranchId;
    this.router.navigate([Url], { queryParams: { fyear: this.FyearId, BranchId: this.BranchId } });
  }
  onChangeBranch(event) {
    var Url = this._Route.url.split('?')[0];
    //+ '? fyear=' + this.FyearId + '& BranchId=' + this.BranchId;
    this.router.navigate([Url], { queryParams: { fyear: this.FyearId, BranchId: this.BranchId } });
  }
  //#region < toggle>
  toggle() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.plusMinus = "-";
    else
      this.plusMinus = "+";

  }
  //#endregion

  //#region < recentCheck>
  recentCheck() {
    this.active = "";
    this.checkIn = false;
    this.recentActiveCheckIn = !this.recentActiveCheckIn;
    if (this.recentActiveCheckIn) {
      this.recentActive = "recentActive";
    } else {
      this.recentActive = "";
      this._Route.navigated = true;
    }
  }
  //#endregion

  //#region < check>

  check() {
    this.recentActive = "";
    this.recentActiveCheckIn = false;
    this.checkIn = !this.checkIn;
    if (this.checkIn) {
      this.active = "active";
      //   TodaysappointmentsComponent;
    }
    else {
      this.active = "";
      //  TodaysappointmentsComponent;
    }
  }
  //#endregion

  //#region < toggleNew>
  toggleNew(currentMenu) {
    this.recentActive = "";
    this.recentActiveCheckIn = false;
    if (currentMenu.MM_IsParent == true) {
      // if show only submenu then replace this.MenuslinksNew to this.sidemenus
      this.MenuslinksNew.forEach(element => {
        if (element === currentMenu) {
          currentMenu.MM_IsShow = !currentMenu.MM_IsShow;
          //sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
        } else {
          element.MM_IsShow = true;
          //sessionStorage.setItem("CheckLoad", JSON.stringify(this.MenuslinksNew));
        }
      });
    }
    this.getPageAccess(currentMenu);
  }
  //#endregion
  getPageAccess(row) {
   
  }
  //#region < getStateNew>
  getStateNew(currentMenu) {
    if (!currentMenu.MM_IsShow) {

      return 'down';
    } else {
      return 'up';
    }
  }
  //#endregion

  gotoCustomerEntry() {
    this._Route.navigate(['/lms-customer']);
  }
  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.data.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
      //this.BranchId = this.BranchesList[0].BranchId;
    });
  }

}
