<a href="javascript:void(0)" (click)="OnAddCaseType()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in"
  id="AddCaseType{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSaveCaseType()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Court
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div
          class="modal-body formborder m-3 p-0"
          style="padding: 10px !important"
        >
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select Type</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="Type"
                id="Type"
                #refType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refType.invalid,
                  'alert-warning':
                    refType.invalid &&
                    (refType.dirty || refType.touched || refType.untouched)
                }"
                [(ngModel)]="CaseModel.Type"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Type</option>
                <option value="ACT">Act</option>
                <option value="Section">Section</option>
                <option value="SubSection">Sub Section</option>
              </select>
            </div>
          </div>
          <div
            class="row m-0 font-size-12 align-items-center"
            *ngIf="CaseModel.Type == 'SubSection'"
          >
            <div class="col-md-4 p-1">
              <span class="required-lable">Select Section</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="ParentId1"
                id="ParentId1"
                #refParentId="ngModel"
                (change)="OnClickSection()"
                [ngClass]="{
                  'is-invalid': f.submitted && refParentId.invalid,
                  'alert-warning':
                    refParentId.invalid &&
                    (refParentId.dirty ||
                      refParentId.touched ||
                      refParentId.untouched)
                }"
                [(ngModel)]="CaseModel.ParentId1"
                class="form-control input-text-css"
                required
              >
                <option value="0">Select Section</option>
                <option *ngFor="let row of SectionData" [value]="row.Id">
                  {{ row.Case_Type_Name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="row m-0 font-size-12 align-items-center"
            *ngIf="CaseModel.Type != '' && CaseModel.Type != 'ACT'"
          >
            <div class="col-md-4 p-1">
              <span class="required-lable">Select Act</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="ParentId"
                id="ParentId"
                #refParentId="ngModel"
                [disabled]="CaseModel.Type == 'SubSection'"
                [ngClass]="{
                  'is-invalid': f.submitted && refParentId.invalid,
                  'alert-warning':
                    refParentId.invalid &&
                    (refParentId.dirty ||
                      refParentId.touched ||
                      refParentId.untouched)
                }"
                [(ngModel)]="CaseModel.ParentId"
                class="form-control input-text-css"
                required
              >
                <option value="0">Select Act</option>
                <option *ngFor="let row of ActData" [value]="row.Id">
                  {{ row.Case_Type_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refCase_Type_Name="ngModel"
                name="Case_Type_Name"
                id="Case_Type_Name"
                [ngClass]="{
                  'is-invalid': f.submitted && refCase_Type_Name.invalid,
                  'alert-warning':
                    refCase_Type_Name.invalid &&
                    (refCase_Type_Name.dirty ||
                      refCase_Type_Name.touched ||
                      refCase_Type_Name.untouched)
                }"
                [(ngModel)]="CaseModel.Case_Type_Name"
                placeholder="Case Type Name"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Short Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refShort_Case_Type_Name="ngModel"
                name="Short_Case_Type_Name"
                id="Short_Case_Type_Name"
                [ngClass]="{
                  'is-invalid': f.submitted && refShort_Case_Type_Name.invalid,
                  'alert-warning':
                    refShort_Case_Type_Name.invalid &&
                    (refShort_Case_Type_Name.dirty ||
                      refShort_Case_Type_Name.touched ||
                      refShort_Case_Type_Name.untouched)
                }"
                [(ngModel)]="CaseModel.Short_Case_Type_Name"
                placeholder="Short Case Type Name"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn"
            (click)="OnSaveCaseType()"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
