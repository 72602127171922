<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="d-flex align-items-center justify-content-between formborder">
    <form action="" class="search-text col-md-3">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <button
      type="button"
      class="btn font-size-12 button-btn"
      (click)="onAddAccountingCategory()"
    >
      <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
    </button>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="CategoryId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Category</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Invst_Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Invst Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Invst_Type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Own_Fund">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Own Fund
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Own_Fund }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_Fund">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Partner Fund
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_Fund }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_IRR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Partner IRR</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_IRR }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          CreateOn
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            aria-label="true"
            (click)="OnEditLoanAccountingCat(row)"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="OnDeleteAccountingCategory(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
<div
  class="modal fade in"
  id="AddLACModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveLoanAccountingCat()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Accounting Category
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-6 row m-0 p-0 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Accounting Category : </span>
              </div>
              <div class="col-md-8 p-1">
                <input
                  required
                  type="text"
                  #refLoan_Acc_Category="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Acc_Category.invalid,
                    'alert-warning':
                      refLoan_Acc_Category.invalid &&
                      (refLoan_Acc_Category.dirty ||
                        refLoan_Acc_Category.touched ||
                        refLoan_Acc_Category.untouched)
                  }"
                  [(ngModel)]="loanAccountingCatModel.Loan_Acc_Category"
                  placeholder="Loan Accounting Category"
                  name="Loan_Acc_Category"
                  id="Loan_Acc_Category"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="col-md-6 row m-0 p-0 align-items-center"></div>
            <div class="col-md-6 row m-0 p-0 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Type: </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="Loan_Acc_Type"
                  id="Loan_Acc_Type"
                  [(ngModel)]="loanAccountingCatModel.Loan_Acc_Type"
                  #refLoan_Acc_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Acc_Type.invalid,
                    'alert-warning':
                      refLoan_Acc_Type.invalid &&
                      (refLoan_Acc_Type.dirty ||
                        refLoan_Acc_Type.touched ||
                        refLoan_Acc_Type.untouched)
                  }"
                  class="form-control input-text-css"
                  required
                  (change)="onChangeType()"
                >
                  <option value="">Select Type</option>
                  <!--<option value="Channel">Channel</option>-->
                  <option value="Own Fund">Own Fund</option>
                  <!--<option value="RSP">RSP</option>-->
                  <option value="Co-Lending">Partner</option>
                </select>
              </div>
            </div>
            <div
              class="col-md-6 row m-0 p-0 align-items-center"
              *ngIf="loanAccountingCatModel.Loan_Acc_Type != 'Own Fund'"
            >
              <div class="col-md-4 p-1">
                <span class="required-lable"> Invest Type: </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="Loan_Acc_Invst_Type"
                  id="Loan_Acc_Invst_Type"
                  [(ngModel)]="loanAccountingCatModel.Loan_Acc_Invst_Type"
                  #refLoan_Acc_Invst_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Acc_Invst_Type.invalid,
                    'alert-warning':
                      refLoan_Acc_Invst_Type.invalid &&
                      (refLoan_Acc_Invst_Type.dirty ||
                        refLoan_Acc_Invst_Type.touched ||
                        refLoan_Acc_Invst_Type.untouched)
                  }"
                  class="form-control input-text-css"
                  required
                  [disabled]="
                    loanAccountingCatModel.Loan_Acc_Type == 'Channel' ||
                    loanAccountingCatModel.Loan_Acc_Type == 'Own Fund'
                  "
                >
                  <option value="">Select Invest Type</option>
                  <option value="1">Fund Base</option>
                  <option value="2">IIR Base</option>
                  <option value="3">Both</option>
                </select>
              </div>
            </div>
            <div class="col-md-12 row m-0 p-0 align-items-center">
              <div class="col-md-2 p-1">
                <span class="required-lable"> Products: </span>
              </div>
              <div class="col-md-10 p-1">
                <ng-multiselect-dropdown
                  name="Loan_Acc_ProductIds"
                  id="Loan_Acc_ProductIds"
                  [placeholder]="'Select Products'"
                  #refLoan_Acc_ProductIds="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Acc_ProductIds.invalid,
                    'alert-warning':
                      refLoan_Acc_ProductIds.invalid &&
                      (refLoan_Acc_ProductIds.dirty ||
                        refLoan_Acc_ProductIds.touched ||
                        refLoan_Acc_ProductIds.untouched)
                  }"
                  [data]="ProductDropDown"
                  [settings]="dropdownSettings"
                  [(ngModel)]="loanAccountingCatModel.Loan_Acc_ProductIds"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 m-0 p-0 pr-2 align-items-center">
              <span class=""> Own Fund : </span>
              <input
                type="number"
                [maxLength]="3"
                #refLoan_Acc_Own_Fund="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refLoan_Acc_Own_Fund.invalid,
                  'alert-warning':
                    refLoan_Acc_Own_Fund.invalid &&
                    (refLoan_Acc_Own_Fund.dirty ||
                      refLoan_Acc_Own_Fund.touched ||
                      refLoan_Acc_Own_Fund.untouched)
                }"
                [(ngModel)]="loanAccountingCatModel.Loan_Acc_Own_Fund"
                placeholder="Own Fund"
                name="Loan_Acc_Own_Fund"
                id="Loan_Acc_Own_Fund"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4 m-0 p-0 pr-2 align-items-center">
              <span class=""> Partner Fund : </span>
              <input
                type="number"
                [maxLength]="3"
                #refLoan_Acc_Partner_Fund="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refLoan_Acc_Partner_Fund.invalid,
                  'alert-warning':
                    refLoan_Acc_Partner_Fund.invalid &&
                    (refLoan_Acc_Partner_Fund.dirty ||
                      refLoan_Acc_Partner_Fund.touched ||
                      refLoan_Acc_Partner_Fund.untouched)
                }"
                [(ngModel)]="loanAccountingCatModel.Loan_Acc_Partner_Fund"
                placeholder="Partner Fund"
                name="Loan_Acc_Partner_Fund"
                id="Loan_Acc_Partner_Fund"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4 m-0 p-0 align-items-center">
              <span class="required-lable"> Partner IRR : </span>
              <input
                [required]="loanAccountingCatModel.Loan_Acc_Invst_Type != 1"
                type="number"
                min="0"
                #refLoan_Acc_Partner_IRR="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refLoan_Acc_Partner_IRR.invalid,
                  'alert-warning':
                    refLoan_Acc_Partner_IRR.invalid &&
                    (refLoan_Acc_Partner_IRR.dirty ||
                      refLoan_Acc_Partner_IRR.touched ||
                      refLoan_Acc_Partner_IRR.untouched)
                }"
                [(ngModel)]="loanAccountingCatModel.Loan_Acc_Partner_IRR"
                placeholder="Partner IRR"
                name="Loan_Acc_Partner_IRR"
                id="Loan_Acc_Partner_IRR"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 mt-1 align-items-center" *ngIf="IsCharges">
            <div class="col-md-2 m-0 p-0 pr-2 align-items-center">
              <span class=""> Is Loan Editable : </span>
              <select
                name="IsLoanEdit"
                id="IsLoanEdit"
                [(ngModel)]="IsLoanEdit"
                class="form-control input-text-css"
                [required]=""
                #refIsLoanEdit="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refIsLoanEdit.invalid,
                  'alert-warning':
                    refIsLoanEdit.invalid &&
                    (refIsLoanEdit.dirty ||
                      refIsLoanEdit.touched ||
                      refIsLoanEdit.untouched)
                }"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div class="col-md-2 m-0 p-0 pr-2 align-items-center">
              <span class="required-lable"> Contract Start Date : </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Contract_StartOn"
                  id="Contract_StartOn"
                  [(ngModel)]="loanAccountingCatModel.Contract_StartOn"
                  required
                  #refContract_StartOn="ngModel"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refContract_StartOn.invalid,
                    'alert-warning':
                      refContract_StartOn.invalid &&
                      (refContract_StartOn.dirty ||
                        refContract_StartOn.touched ||
                        refContract_StartOn.untouched)
                  }"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="Contract_StartOn"
                id="Contract_StartOn"
                [(ngModel)]="loanAccountingCatModel.Contract_StartOn"
                required
                #refContract_StartOn="ngModel"
                placeholder="Contract Start Date"
                class="form-control input-text-css"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': f.submitted && refContract_StartOn.invalid,
                  'alert-warning':
                    refContract_StartOn.invalid &&
                    (refContract_StartOn.dirty ||
                      refContract_StartOn.touched ||
                      refContract_StartOn.untouched)
                }"
              /> -->
            </div>
            <div class="col-md-2 m-0 p-0 pr-2 align-items-center">
              <span> Contract End Date : </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Contract_EndOn"
                  id="Contract_EndOn"
                  [(ngModel)]="loanAccountingCatModel.Contract_EndOn"
                  #refDueDateFrom="ngModel"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="Contract_EndOn"
                id="Contract_EndOn"
                [(ngModel)]="loanAccountingCatModel.Contract_EndOn"
                #refDueDateFrom="ngModel"
                placeholder="Contract End Date"
                class="form-control input-text-css"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-2 m-0 p-0 pr-2 align-items-center">
              <span class="required-lable">Is Cibil Updated : </span>
              <select
                name="IsCibilUpdated"
                id="IsCibilUpdated"
                [(ngModel)]="loanAccountingCatModel.IsCibilUpdated"
                class="form-control input-text-css"
                [required]=""
                #refIsCibilUpdated="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refIsCibilUpdated.invalid,
                  'alert-warning':
                    refIsCibilUpdated.invalid &&
                    (refIsCibilUpdated.dirty ||
                      refIsCibilUpdated.touched ||
                      refIsCibilUpdated.untouched)
                }"
              >
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>
            <div class="col-md-4 m-0 p-0 pr-2 align-items-center">
              <span class="">Agreement Attachment : </span>
              <p class="m-0 mt-2 row">
                <i
                  class="fa fa-upload ml-2"
                  *ngIf="!loanAccountingCatModel.AgreementDoc"
                  (click)="AgreementDoc.click()"
                  aria-hidden="true"
                ></i>
                <ng-container *ngIf="loanAccountingCatModel.AgreementDoc">
                  <div>
                    <!-- <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{loanAccountingCatModel.AgreementDoc}} -->
                    <a
                      href="{{ docBaseUrl }}{{
                        loanAccountingCatModel.AgreementDoc
                      }}"
                      target="_blank"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ loanAccountingCatModel.AgreementDoc }}
                    </a>
                    <i
                      class="fa fa-times ml-2"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile(AgreementDoc)"
                    ></i>
                  </div>
                </ng-container>
              </p>
              <input
                #AgreementDoc
                type="file"
                [multiple]="false"
                accept="application/pdf,application/vnd.ms-excel"
                (change)="fileChangeListener(AgreementDoc.files)"
                style="display: none"
              />
            </div>
          </div>

          <div class="row m-0 mt-4" *ngIf="IsCharges">
            <div class="col-md-12 p-0">
              <h1
                style="
                  font-size: 12px;
                  background: #4dc3a3;
                  margin-bottom: 10px;
                  padding: 10px;
                  color: #fff;
                "
              >
                Product Charges
              </h1>
            </div>
            <div class="col-md-12 row m-0 p-0">
              <div class="col-md-1 p-1">&nbsp;</div>
              <div class="col-md-3 p-1"><B>Charges head</B></div>
              <div class="col-md-2 p-1"><B>Account head</B></div>
              <div class="col-md-1 p-1"><B>Charges type</B></div>
              <div class="col-md-1 p-1"><B>Charges Value</B></div>
              <!--<div class="col-md-2 p-1"><B>Charges ApplyOn</B></div>-->
              <div class="col-md-2 p-1"></div>
              <div class="col-md-2 p-1">
                <!--<span style="font-weight:bold">Charges ApplyOn</span>-->
              </div>
            </div>

            <div
              class="col-md-12 row m-0 p-0"
              *ngFor="let charges of chargesHead; let i = index"
            >
              <div class="col-md-1 p-1">
                <mat-checkbox
                  [(ngModel)]="charges.select"
                  [disabled]="IsDisabledAccount && charges.Type != 'New'"
                  id="multipleTrunche{{ i }}"
                  name="multipleTrunche{{ i }}"
                ></mat-checkbox>
              </div>
              <div class="col-md-2 p-1">{{ charges.ChagesHead }}</div>
              <div class="col-md-3 p-1">
                <select
                  name="Account_HeadId{{ i }}"
                  id="Account_HeadId{{ i }}"
                  [(ngModel)]="charges.Account_HeadId"
                  [disabled]="IsDisabledAccount && charges.Type != 'New'"
                  [required]="charges.select"
                  [ngClass]="{
                    'is-invalid': f.submitted && refAccount_HeadId.invalid,
                    'alert-warning':
                      refAccount_HeadId.invalid &&
                      (refAccount_HeadId.dirty ||
                        refAccount_HeadId.touched ||
                        refAccount_HeadId.untouched)
                  }"
                  class="form-control input-text-css"
                  #refAccount_HeadId="ngModel"
                >
                  <option value="">Select</option>
                  <option
                    *ngFor="let ca of chargableAccount"
                    [value]="ca.AccountId"
                  >
                    {{ ca.Account_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-1 p-1">
                <select
                  name="chargeType{{ i }}"
                  id="chargeType{{ i }}"
                  [(ngModel)]="charges.Charge_Type"
                  class="form-control input-text-css"
                  #refchargeType="ngModel"
                >
                  <option value="">Select</option>
                  <option value="Fixed">Fixed</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Open">Open</option>
                </select>
              </div>
              <div class="col-md-1 p-1">
                <input
                  type="number"
                  [(ngModel)]="charges.Charge_Values"
                  placeholder="Charges Value"
                  name="ChargeValues{{ i }}"
                  id="ChargeValues{{ i }}"
                  class="form-control input-text-css"
                />
              </div>
              <!--<div class="col-md-2 p-1">
                <select name="chargeApplyOn{{i}}" id="chargeApplyOn{{i}}" [(ngModel)]="charges.Charge_ApplyOn"
                  class="form-control input-text-css" [required]="charges.select&&charges.Charge_Type!='Open'" #refchargeApplyOn="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && refchargeApplyOn.invalid, 'alert-warning': refchargeApplyOn.invalid  && (refchargeApplyOn.dirty || refchargeApplyOn.touched || refchargeApplyOn.untouched) }">
                  <option value=""> Select </option>
                  <option value="Loan Amount"> Loan Amount </option>
                  <option value="POS Outstanding (Only Due)"> POS Outstanding (Only Due) </option>
                  <option value="POS Balance (Due + Future)"> POS Balance (Due + Future) </option>
                  <option value="Installment Outstanding (Only Due)"> Installment Outstanding (Only Due) </option>
                  <option value="Installment Balance (Due + Future)"> Installment Balance (Due + Future) </option>
                  <option value="Per Instance"> Per Instance </option>
                </select>
              </div>-->
              <div class="col-md-2 p-1">
                <button
                  type="button"
                  (click)="onSwap(charges)"
                  class="ml-2 font-size-12 button-btn"
                  *ngIf="IsDisabledAccount && charges.Type != 'New'"
                >
                  Swap Account
                </button>
              </div>
              <div class="col-md-2 p-1"></div>
            </div>

            <form #df="ngForm" novalidate class="col-md-12 row m-0 p-0">
              <div
                class="col-md-12 row m-0 p-0"
                *ngFor="
                  let Charges1 of ChargesModel.Charges_Detail;
                  let j = index
                "
              >
                <div class="col-md-1 p-1">
                  <mat-checkbox
                    [(ngModel)]="Charges1.Select"
                    id="multipleTrunche1{{ j }}"
                    [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
                    name="multipleTrunche1{{ j }}"
                  ></mat-checkbox>
                </div>
                <div class="col-md-2 p-1">
                  <select
                    name="ChagesHeadDROP{{ j }}"
                    id="ChagesHeadDROP{{ j }}"
                    [(ngModel)]="Charges1.ChagesHead"
                    [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
                    (change)="onChangeChargesHead(Charges1, j)"
                    class="form-control input-text-css"
                    [required]="Charges1.Select"
                    #refChagesHead="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refChagesHead.invalid,
                      'alert-warning':
                        refChagesHead.invalid &&
                        (refChagesHead.dirty ||
                          refChagesHead.touched ||
                          refChagesHead.untouched)
                    }"
                  >
                    <option value="">Select</option>
                    <option *ngFor="let ca of ChargesHead1" [value]="ca.Id">
                      {{ ca.ChagesHead }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3 p-1">
                  <select
                    name="Account_HeadIdDROP{{ j }}"
                    id="Account_HeadIdDROP{{ j }}"
                    [(ngModel)]="Charges1.Account_HeadId"
                    [disabled]="IsDisabledAccount && Charges1.Type != 'New'"
                    class="form-control input-text-css"
                    [required]="Charges1.Select"
                    #refAccount_HeadId1="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refAccount_HeadId1.invalid,
                      'alert-warning':
                        refAccount_HeadId1.invalid &&
                        (refAccount_HeadId1.dirty ||
                          refAccount_HeadId1.touched ||
                          refAccount_HeadId1.untouched)
                    }"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let ca of chargableAccount"
                      [value]="ca.AccountId"
                    >
                      {{ ca.Account_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 p-1">
                  <select
                    name="chargeTypeDROP{{ j }}"
                    id="chargeTypeDROP{{ j }}"
                    [(ngModel)]="Charges1.Charge_Type"
                    class="form-control input-text-css"
                  >
                    <option value="">Select</option>
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Open">Open</option>
                  </select>
                </div>
                <div class="col-md-1 p-1">
                  <input
                    type="number"
                    [(ngModel)]="Charges1.Charge_Values"
                    placeholder="Charges Value"
                    name="ChargeValuesDROP{{ j }}"
                    id="ChargeValuesDROP{{ j }}"
                    class="form-control input-text-css"
                  />
                </div>
                <!--<div class="col-md-2 p-1">
                  <select name="chargeApplyOnDROP{{j}}" id="chargeApplyOnDROP{{j}}" [(ngModel)]="Charges1.Charge_ApplyOn"
                          class="form-control input-text-css" [required]="Charges1.Select && Charges1.Charge_Type != 'Open'" #refchargeApplyOn1="ngModel"
                          [ngClass]="{ 'is-invalid': df.submitted && refchargeApplyOn1.invalid, 'alert-warning': refchargeApplyOn1.invalid  && (refchargeApplyOn1.dirty || refchargeApplyOn1.touched || refchargeApplyOn1.untouched) }">
                    <option value=""> Select </option>
                    <option value="Loan Amount"> Loan Amount </option>
                    <option value="POS Outstanding (Only Due)"> POS Outstanding (Only Due) </option>
                    <option value="POS Balance (Due + Future)"> POS Balance (Due + Future) </option>
                    <option value="Installment Outstanding (Only Due)"> Installment Outstanding (Only Due) </option>
                    <option value="Installment Balance (Due + Future)"> Installment Balance (Due + Future) </option>
                    <option value="Per Instance"> Per Instance </option>
                  </select>
                </div>-->
                <div class="col-md-2 p-1">
                  <button
                    type="button"
                    (click)="onSwap(Charges1)"
                    class="ml-2 font-size-12 button-btn"
                    *ngIf="IsDisabledAccount && Charges1.Type != 'New'"
                  >
                    Swap Account
                  </button>
                </div>
                <div class="col-md-1 p-1">
                  <button
                    type="button"
                    class="remove"
                    (click)="removeData(j)"
                    [hidden]="IsDisabledAccount && Charges1.Type != 'New'"
                    [ngClass]="{ 'no-head': j != 0 }"
                    *ngIf="ChargesModel.Charges_Detail.length > 1"
                  >
                    -</button
                  >&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    class="addmore"
                    (click)="addMoreData()"
                    [ngClass]="{
                      both: ChargesModel.Charges_Detail.length > 1,
                      'no-head': j != 0
                    }"
                    *ngIf="j == ChargesModel.Charges_Detail.length - 1"
                  >
                    +
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveLoanAccountingCat()"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
            class="mt-3 btn font-size-12 button-btn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="SwapAccountModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Swap Account
        </h6>
        <button
          type="button"
          (click)="onCloseSwapAccountModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #f="ngForm" novalidate>
          <div class="row mt-4 ml-3">
            <div class="col-md-12">
              <!--<input [(ngModel)]="SwapChanges.ChagesHead" placeholder="Charges Value" disabled
         name="ChargesHeadId" id="ChargesHeadId" class="form-control input-text-css">-->
              <select
                name="ChagesHeadswap"
                id="ChagesHeadswap"
                disabled
                [(ngModel)]="SwapChanges.Id"
                class="form-control input-text-css"
              >
                <option value="">Select</option>
                <option *ngFor="let ca of mixChargesHead" [value]="ca.Id">
                  {{ ca.ChagesHead }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-4 ml-3">
            <div class="col-md-6">
              <select
                name="OldAccountId"
                id="OldAccountId"
                [(ngModel)]="SwapChanges.Account_HeadId"
                disabled
                class="form-control input-text-css"
              >
                <option
                  *ngFor="let ca of chargableAccount"
                  [value]="ca.AccountId"
                >
                  {{ ca.Account_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <select
                name="NewAccountId"
                id="NewAccountId"
                [(ngModel)]="NewAccountId"
                class="form-control input-text-css"
                required
                #refNewAccountId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refNewAccountId.invalid,
                  'alert-warning':
                    refNewAccountId.invalid &&
                    (refNewAccountId.dirty ||
                      refNewAccountId.touched ||
                      refNewAccountId.untouched)
                }"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let ca of chargableAccount"
                  [value]="ca.AccountId"
                >
                  {{ ca.Account_Name }}
                </option>
              </select>
            </div>
          </div>
        </form>

        <div class="text-right mb-2" style="margin-top: 6px">
          <button
            type="button"
            (click)="Swap()"
            [disabled]="!f.form.valid"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Swap
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
