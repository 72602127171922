<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div>
  <!--  [class.FixHeight]="PageAcess[0].View == 0" -->
  <div class="mt-2" *ngIf="PageAcess[0].View == 1">
    <div class="">
      <div class="table-responsive mt-1">
        <mat-table [dataSource]="dataSource">
          <!--  style="height: 250px; max-width: 100%; overflow: auto;" -->
          <ng-container matColumnDef="UDId">
            <mat-header-cell class="grid-header mw50" *matHeaderCellDef>#</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index"
                      class="grid-cell mw50">{{ i + 1 }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Category">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Doc Category</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.Category
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.Type
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="DocType">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Doc Type</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.DocType
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="DocTitle">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Doc Title</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.DocTitle
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="UploadBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Uploaded By</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.UploadBy
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="UploadedOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Uploaded On</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.UploadedOn
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="IsReceipt">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Is Receipt</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.IsReceipt
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="No_Of_Pages">
            <mat-header-cell class="grid-header" *matHeaderCellDef>No Of Pages</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
              row.No_Of_Pages
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="View">
            <mat-header-cell class="grid-header" *matHeaderCellDef>View</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <i class="fa fa-eye"
                 (click)="ViewDoc(row)"
                 style="font-size: large; cursor: pointer"></i>
              <i class="fa fa-trash style-delete ml-2"
                 aria-hidden="true"
                 style="cursor: pointer; font-size: large"
                 *ngIf="
                  !row.IsLOS &&
                  PageAcess[0].Delete == 1 &&
                  !loanSummary.CloseDate &&
                  row.Type == 'Copy'
                "
                 (click)="onDeleteDoc(row)"></i>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>







    <div class="row m-0 mb-2 mt-3 w-b p-0 col-md-12 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Pending Documents:</h1>
    </div>
    <div class="table-responsive">
      <app-los-pending-document-list [ApplicationId]="loanSummary.LoanId"
                                     [TypeLoan]="'QuickLoan'"
                                     [LoanStatus]="Status"
                                     (close)="GetData()">
      </app-los-pending-document-list>
    </div>




  </div>
</div>

<div class="row m-0 mt-2">
  <div class="col-md-6">
    <div *ngIf="PageAcess[0].View == 1">
      <button type="button"
              class="mt-2 btn font-size-12 button-btn"
              (click)="addNewDoc()"
              *ngIf="PageAcess[0].Add == 1 && !loanSummary.CloseDate">
        Add New
      </button>
    </div>
  </div>
  <div class="col-md-6 text-right">
    <button type="button"
            (click)="onNextStep()"
            class="mt-2 btn font-size-12 button-btn"
            [class.spinner]="loading">
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>

<div class="modal fade in"
     id="docdetailModel"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Doc Attachment
        </h6>
        <button type="button"
                (click)="OnDocDetailClose()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mb2">
          <div class="row m-0 col-md-12 p-1">
            <div class="table-responsive mt-3">
              <mat-table [dataSource]="docModalDataSource">
                <ng-container matColumnDef="UDId">
                  <mat-header-cell class="grid-header mw50" *matHeaderCellDef>
                    #
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row; let i = index"
                            class="grid-cell mw50">
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Category">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Doc Category
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Category }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DocType">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Doc Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DocType }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DocTitle">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Doc Title
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DocTitle }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="IsReceipt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Is Receipt
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.IsReceipt }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="No_Of_Pages">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    No Of Pages
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.No_Of_Pages }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="View">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    View
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                        row.UD_DocFileName
                      }}"
                       target="_blank"
                       *ngIf="row.IsLOS">View</a>
                    <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                        row.UD_DocFileName
                      }}"
                       target="_blank"
                       *ngIf="!row.IsLOS">View</a>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedDocModalColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedDocModalColumns"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="docModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Upload Loan Documents
        </h6>
        <button type="button"
                (click)="OnDocClose()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1" *ngIf="docModal">
          <form #df="ngForm"
                class="row m-0 col-md-12 p-0"
                (ngSubmit)="onSaveDoc()"
                novalidate
                class="formborder">
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-12 p-1">
                <span> Attachment Type</span>
                <div class="col-md-12 p-1">
                  <span class="form-check-inline">
                    <label class="form-check-label mr-4" for="Document">
                      <input type="radio"
                             class="form-check-input"
                             id="Document"
                             name="IsDocument"
                             (change)="documentChange()"
                             #refIsDocument="ngModel"
                             [ngClass]="{
                          'is-invalid': df.submitted && refIsDocument.invalid,
                          'alert-warning':
                            refIsDocument.invalid &&
                            (refIsDocument.dirty ||
                              refIsDocument.touched ||
                              refIsDocument.untouched)
                        }"
                             [(ngModel)]="docModal.IsDocument"
                             required
                             value="1" />
                      Document
                    </label>
                    <label class="form-check-label" for="Open">
                      <input type="radio"
                             class="form-check-input"
                             id="Open"
                             name="IsDocument"
                             (change)="documentChange()"
                             #refIsDocument="ngModel"
                             [ngClass]="{
                          'is-invalid': df.submitted && refIsDocument.invalid,
                          'alert-warning':
                            refIsDocument.invalid &&
                            (refIsDocument.dirty ||
                              refIsDocument.touched ||
                              refIsDocument.untouched)
                        }"
                             [(ngModel)]="docModal.IsDocument"
                             required
                             value="0" />
                      Open
                    </label>
                  </span>
                </div>
              </div>
              <div class="col-md-12 p-1" *ngIf="docModal.IsDocument == '1'">
                <span class="required-lable">Type</span>
                <select name="Type"
                        id="Type"
                        #refType="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                        [(ngModel)]="docModal.Type"
                        class="form-control input-text-css"
                        (change)="TypeChange($event)"
                        required>
                  <option value="Loan">Loan</option>
                  <option value="Customer">Customer</option>
                  <option value="Assets">Assets</option>
                </select>
              </div>
              <div class="col-md-12 p-1"
                   *ngIf="
                  docModal.Type == 'Customer' && docModal.IsDocument == '1'
                ">
                <span class="required-lable">Select Customer Name</span>
                <select name="Name"
                        id="Name"
                        #refName="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refName.invalid,
                    'alert-warning':
                      refName.invalid &&
                      (refName.dirty || refName.touched || refName.untouched)
                  }"
                        [(ngModel)]="docModal.Name"
                        class="form-control input-text-css"
                        required>
                  <option value="">Select Customer Name</option>
                  <option *ngFor="let list of CustomerDropdown"
                          [value]="list.Customer + '-' + list.CustomerId">
                    {{ list.Customer }}
                  </option>
                </select>
              </div>
              <div class="col-md-12 p-1"
                   *ngIf="docModal.Type == 'Assets' && docModal.IsDocument == '1'">
                <span class="required-lable">Select Assets Name</span>
                <select name="Name"
                        id="Name"
                        #refName="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refName.invalid,
                    'alert-warning':
                      refName.invalid &&
                      (refName.dirty || refName.touched || refName.untouched)
                  }"
                        [(ngModel)]="docModal.Name"
                        class="form-control input-text-css"
                        required>
                  <option value="">Select Assets Name</option>
                  <option [value]="asset.ForInsurance"
                          *ngFor="let asset of assetDropdown">
                    {{ asset.ForInsurance }}
                  </option>
                </select>
              </div>
              <div class="col-md-12 p-1"
                   *ngIf="docModal.Type == 'Loan' && docModal.IsDocument == '1'">
                <span class="required-lable">Select Name</span>
                <select name="Name"
                        id="Name"
                        #refName="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refName.invalid,
                    'alert-warning':
                      refName.invalid &&
                      (refName.dirty || refName.touched || refName.untouched)
                  }"
                        [(ngModel)]="docModal.Name"
                        class="form-control input-text-css"
                        required>
                  <option value="">Select Name</option>
                  <option [value]="CA.Name"
                          *ngFor="let CA of CustomerAssetsDropdown">
                    {{ CA.Name }}
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="col-md-12 p-1" *ngIf="docModal.IsDocument == '1'">
                <span class="required-lable"> Document Category</span>
                <select name="DocId"
                        id="DocId"
                        #refDocId="ngModel"
                        [ngClass]="{
                    'is-invalid': df.submitted && refDocId.invalid,
                    'alert-warning':
                      refDocId.invalid &&
                      (refDocId.dirty || refDocId.touched || refDocId.untouched)
                  }"
                        [(ngModel)]="docModal.DocId"
                        (change)="DocumentCategoryChange($event)"
                        class="form-control input-text-css"
                        required>
                  <option value="">Select Document Category</option>
                  <option [value]="doc.DocumentId + '-' + doc.Doc_CategoryId"
                          *ngFor="let doc of docDropDown">
                    {{ doc.DocumentName }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 p-1" *ngIf="docModal.IsDocument == '0'">
                <span> Document Type</span>
                <div class="col-md-12 p-1">
                  <span class="form-check-inline">
                    <label class="form-check-label mr-4" for="copyDoc">
                      <input type="radio"
                             class="form-check-input"
                             id="copyDoc"
                             name="IsOrignalDoc"
                             #refIsOrignalDoc="ngModel"
                             [ngClass]="{
                          'is-invalid': df.submitted && refIsOrignalDoc.invalid,
                          'alert-warning':
                            refIsOrignalDoc.invalid &&
                            (refIsOrignalDoc.dirty ||
                              refIsOrignalDoc.touched ||
                              refIsOrignalDoc.untouched)
                        }"
                             [(ngModel)]="docModal.IsOrignalDoc"
                             required
                             value="1" />
                      Copy
                    </label>
                    <label class="form-check-label" for="originalDoc">
                      <input type="radio"
                             class="form-check-input"
                             id="originalDoc"
                             name="IsOrignalDoc"
                             #refIsOrignalDoc="ngModel"
                             [ngClass]="{
                          'is-invalid': df.submitted && refIsOrignalDoc.invalid,
                          'alert-warning':
                            refIsOrignalDoc.invalid &&
                            (refIsOrignalDoc.dirty ||
                              refIsOrignalDoc.touched ||
                              refIsOrignalDoc.untouched)
                        }"
                             [(ngModel)]="docModal.IsOrignalDoc"
                             required
                             value="0" />
                      Mark As LOD
                    </label>
                  </span>
                </div>
              </div>
              <div class="col-md-12 p-1">
                <span class="required-lable"> Document Title/Document No.</span>
                <input type="text"
                       #refDocTitle="ngModel"
                       [(ngModel)]="docModal.DocTitle"
                       placeholder=" Document Title"
                       *ngIf="
                    docModal.DocId.split('-')[0] != 1 &&
                    docModal.DocId.split('-')[0] != 6
                  "
                       [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                       name="DocTitle"
                       id="DocTitle"
                       class="form-control input-text-css"
                       required />
                <input type="text"
                       #refDocTitle="ngModel"
                       [(ngModel)]="docModal.DocTitle"
                       placeholder=" Document Title"
                       [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                       name="DocTitle"
                       id="DocTitle"
                       required
                       *ngIf="docModal.DocId.split('-')[0] == 1"
                       maxlength="10"
                       minlength="10"
                       class="form-control input-text-css" />
                <input type="text"
                       numbersOnly
                       #refDocTitle="ngModel"
                       [(ngModel)]="docModal.DocTitle"
                       placeholder=" Document Title"
                       [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                       name="DocTitle"
                       id="DocTitle"
                       required
                       *ngIf="docModal.DocId.split('-')[0] == 6"
                       maxlength="12"
                       minlength="12"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-12 p-1">
                <span> In Favour Of/Remark </span>
                <input type="text"
                       #refRemark="ngModel"
                       [(ngModel)]="docModal.Remark"
                       placeholder=" Remark "
                       [ngClass]="{
                        'is-invalid': df.submitted && refRemark.invalid,
                        'alert-warning':
                          refRemark.invalid &&
                          (refRemark.dirty ||
                            refRemark.touched ||
                            refRemark.untouched)
                      }"
                       name="Remark"
                       id="Remark"
                       class="form-control input-text-css" />
              </div>

              <div class="col-md-12 p-1"  *ngIf="docModal.IsOrignalDoc == '0'">
                <span class="">Date Of Execution</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="DateOfExecution"
                         id="DateOfExecution"
                         required
                         #refDateOfExecution="ngModel"
                          [ngClass]="{
                          'is-invalid': df.submitted && refDateOfExecution.invalid,
                          'alert-warning':
                            refDateOfExecution.invalid &&
                            (refDateOfExecution.dirty ||
                              refDateOfExecution.touched ||
                              refDateOfExecution.untouched)
                        }"
                         [(ngModel)]="docModal.DateOfExecution"
                         class="form-control input-text-css" />
                        <mat-datepicker-toggle matSuffix
                         [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                </div>

              </div>
              <div class="col-md-12 p-1"  *ngIf="docModal.IsOrignalDoc == '0'">
                <span> Executed By</span>
                <input type="text"
                       #refExecutedBy="ngModel"
                       [(ngModel)]="docModal.ExecutedBy"
                       placeholder=" Executed By"
                      
                       [ngClass]="{
                        'is-invalid': df.submitted && refExecutedBy.invalid,
                        'alert-warning':
                          refExecutedBy.invalid &&
                          (refExecutedBy.dirty ||
                            refExecutedBy.touched ||
                            refExecutedBy.untouched)
                      }"
                       name="ExecutedBy"
                       id="ExecutedBy"
                       class="form-control input-text-css"
                       required />
              </div>
              <div class="col-md-12 p-1">
                <span> No of pages</span>
                <input type="text"
                       #refNo_Of_Pages="ngModel"
                       [(ngModel)]="docModal.No_Of_Pages"
                       placeholder=" No of pages"
                       numbersOnly="true"
                       [ngClass]="{
                    'is-invalid': df.submitted && refNo_Of_Pages.invalid,
                    'alert-warning':
                      refNo_Of_Pages.invalid &&
                      (refNo_Of_Pages.dirty ||
                        refNo_Of_Pages.touched ||
                        refNo_Of_Pages.untouched)
                  }"
                       name="No_Of_Pages"
                       id="No_Of_Pages"
                       class="form-control input-text-css"
                       required />
              </div>

              <div class="row m-0 col-md-12 p-1 align-items-center upload">
                <p class="m-0 row align-items-center">
                  Browse Doc:<i class="fa fa-upload ml-2"
                                *ngIf="docModal.DocumentImages.length == 0"
                                (click)="docUFile.click()"
                                aria-hidden="true"></i>
                  <ng-container *ngIf="docModal.DocumentImages.length > 0">
                    <div *ngFor="let doc of docModal.DocumentImages; let i = index">
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ doc.DocFileName }}
                      <i class="fa fa-times ml-2"
                         aria-hidden="true"
                         style="cursor: pointer"
                         (click)="removeDFile(i)"></i>
                    </div>
                  </ng-container>
                </p>
                <input #docUFile
                       type="file"
                       [multiple]="true"
                       accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                       (change)="fileChangeListenerD(docUFile.files, docUFile)"
                       style="display: none" />
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button type="button"
                      (click)="OnDocClose()"
                      class="mt-2 btn font-size-12 button-btn mr-3">
                Cancel
              </button>
              <button type="button"
                      (click)="onSaveDoc()"
                      class="mt-2 btn font-size-12 button-btn"
                      [class.spinner]="loading"
                      [disabled]="!df.form.valid">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
