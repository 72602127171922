<div
  class="content"
  id="prrrnth"
  style="display: none; background-color: #ffff !important"
>
  <div style="float: right">
    <img
      src="{{ EmployeeProfileBaseUrl }}logo.png"
      alt="logo"
      style="width: 100px; display: inline"
    />
  </div>

  <div style="clear: both">
    <div>
      <div
        style="font-family: 'Times New Roman', Times, serif; text-align: center"
      >
        <h5>{{ originalData?.CompanyName }}</h5>
      </div>
      <div
        style="font-family: 'Times New Roman', Times, serif; text-align: center"
      >
        <h6>Address: {{ originalData?.CompanyAddress }}</h6>
      </div>
    </div>
  </div>

  <div class="loanInfo my-1" *ngIf="resData?.Item1">
    <div style="text-align: center; font-size: medium; margin-top: 27px">
      <span class="titleHeadP">Loan Details</span>
    </div>
    <app-loan-subcard-info
      [loandata]="resData?.Item1[0]"
    ></app-loan-subcard-info>
  </div>
  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Finantial Details</span>
  </div>

  <div class="AssetInfoInfo my-2" *ngIf="resData?.Item16">
    <app-loan-financial-info
      [financialdata]="resData?.Item16"
    ></app-loan-financial-info>
  </div>
  <div class="assetInfo">
    <div style="text-align: center; font-size: medium; margin-top: 27px">
      <span class="titleHeadP">Asset Details</span>
    </div>
    <div class="AssetInfoInfo my-2">
      <app-asset-subcard-info
        [assetData]="resData?.asset[0]"
        [AssetsAttachment]="resData?.AssetsAttachment"
        [ApplicationNumber]="resData?.ApplicationNumber[0]"
        [GoldAssets]="resData?.goldAsset"
      ></app-asset-subcard-info>
    </div>
  </div>
  <div class="TATInfo">
    <div style="text-align: center; font-size: medium; margin-top: 27px">
      <span class="titleHeadP">TAT Details</span>
    </div>
    <div class="AssetInfoInfo my-2">
      <app-tat-report [loanId]="loanId"></app-tat-report>
    </div>
  </div>
  <div class="ReceiptInfo">
    <div style="text-align: center; font-size: medium; margin-top: 27px">
      <span class="titleHeadP">Deduction</span>
    </div>
    <div class="ReceiptInfo my-2">
      <app-receipt-deduction [loanId]="loanId"></app-receipt-deduction>
    </div>
  </div>
  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Deviation Details</span>
  </div>
  <div class="DeviationInfo">
    <div class="DeviationInfo my-2">
      <app-deviation-info [data]="resData?.deviation"></app-deviation-info>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Sanction Condition</span>
  </div>
  <div class="SanctionConditionInfo">
    <div class="SanctionConditionInfo my-2">
      <app-sanction-condition
        [data]="resData?.sanction"
      ></app-sanction-condition>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Documents</span>
  </div>

  <div class="attachedDocumentsInfo">
    <div class="attachedDocumentsInfo my-2">
      <app-attached-document
        [loanId]="loanId"
        [ApplicationNumber]="resData?.ApplicationNumber[0]"
      ></app-attached-document>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Foir Details</span>
  </div>

  <div class="attachedDocumentsInfo">
    <div class="attachedDocumentsInfo my-2">
      <app-foir-card [loanId]="loanId"></app-foir-card>
    </div>
  </div>

  <footer></footer>
  <div class="custInfo mt-2 card">
    <div *ngFor="let item of resData.data; let ind = index">
      <div class="row my-2" style="flex-wrap: nowrap">
        <div class="col-md-4">
          <app-customer-subcard-info
            [customerData]="resData.data[ind].Item2"
          ></app-customer-subcard-info>
        </div>
      </div>

      <div class="row my-2" style="flex-wrap: nowrap">
        <div class="col-md-4">
          <app-customer-kyc-subcard-info
            [customerkycData]="resData.data[ind].Item9"
          >
          </app-customer-kyc-subcard-info>
        </div>
      </div>

      <div class="row my-2" style="flex-wrap: nowrap">
        <div class="col-md-4">
          <app-crc-subcard-info
            [applicationId]="applicationId"
            [crcData]="resData.data[ind].Item3"
            [index]="ind"
          ></app-crc-subcard-info>
        </div>
      </div>

      <!-- <div class="" style="flex-wrap: nowrap"> -->
      <div class="col-md-12" style="margin-top: 20px">
        <app-income-subcard-info
          [incomeData]="resData.data[ind].Item5"
        ></app-income-subcard-info>
      </div>
      <div class="col-md-12" style="margin-top: 20px">
        <app-references-subcard-info [referenceData]="resData.data[ind].Item6">
        </app-references-subcard-info>
      </div>
      <div class="col-md-12" style="margin-top: 20px">
        <app-family-subcard-info
          [familyData]="resData.data[ind].Item4"
        ></app-family-subcard-info>
      </div>
      <!-- </div> -->
      <!-- <div class="row my-2" style="flex-wrap: nowrap"> -->
      <div class="col-md-12" style="margin-top: 20px">
        <app-FI-Question-subcard-info
          [FIQuestionData]="resData.data[ind].Item10"
        ></app-FI-Question-subcard-info>
      </div>
      <div class="col-md-12" style="margin-top: 20px">
        <app-TVR-Question-subcard-info
          [TVRQuestionData]="resData.data[ind].Item11"
        ></app-TVR-Question-subcard-info>
      </div>
      <div class="col-md-12" style="margin-top: 20px">
        <app-PD-Question-subcard-info
          [PDQuestionData]="resData.data[ind].Item12"
        ></app-PD-Question-subcard-info>
      </div>
      <!-- </div> -->
      <div style="flex-wrap: nowrap">
        <div class="col-md-12" style="margin-top: 20px">
          <app-pdc-details
            [PDCDetail]="resData.data[ind].Item13"
          ></app-pdc-details>
        </div>
        <div class="col-md-4 px-1"></div>
        <div class="col-md-4"></div>
      </div>
      <footer></footer>
    </div>
  </div>
</div>

<!-- [useExistingCss]="true" -->
<button
  type="button"
  class="font-size-12 button-btn ml-2"
  useExistingCss="true"
  printTitle="MyTitle"
  printSectionId="prrrnth"
  ngxPrint
>
  Print CAM Report
</button>
