<div class="grid-container ">
    <div class="  grid-row-con">
        
       
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Type</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Chages Head</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Account</div>
        </div>
      
        <div class="grid-items">
            <div class="grid-items font-weight-bold"> Amount </div>
        </div>
        <div class="grid-items">
            <div class="grid-items font-weight-bold"> Collection On </div>
        </div>
        <div class="grid-items">
            <div class="grid-items font-weight-bold"> Collection By </div>
        </div>
       
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Narration </div>
        </div>
    </div>

    <div class=" mt-1 grid-row-con" *ngFor="let row of receiptData">



       
       
        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Executive </div> -->
            <div class="grid-sub-item-value"> {{row.Type}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Status</div> -->
            <div class="grid-sub-item-value"> {{row.ChagesHead}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Assign On</div> -->
            <div class="grid-sub-item-value"> {{row.Account}} </div>
        </div>
        
        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Completed On</div> -->
            <div class="grid-sub-item-value"> {{row.Amount}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> TAT (Hrs) </div> -->
            <div class="grid-sub-item-value">{{row.CollectionOn}}</div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Remark </div> -->
            <div class="grid-sub-item-value"> {{row.CollectionBy}} </div>
        </div>
        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Executive </div> -->
            <div class="grid-sub-item-value"> {{row.Narration}} </div>
        </div>
    </div>
</div>