import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
@Component({
  selector: 'app-voucher-trail',
  templateUrl: './voucher-trail.component.html',
  styleUrls: ['./voucher-trail.component.scss']
})

export class VoucherTrailComponent implements OnInit {
  showSpinner: boolean = false;
  displayedLoanColumns: string[] = ['Type', 'CaseNo', 'Customer', 'Branch', 'Category', 'AccountHead', 'AmountDR', 'AmountCR'];
  displayedAccountingColumns: string[] = ['AccountHead', 'Branch', 'AmountDR', 'AmountCR']
  Search: any = {
    VoucherDateFrom: '', VoucherDateTo: '', PostDateFrom: '', PostDateTo: '', SelectEmployee: 0, VoucherType: "", VoucherSubType: "", Type: 0
  };
  loading: boolean = false;
  EmployeeList: any;
  FyearId: any;
  maxDate: any;
  JsonData: any[] = [];
  minDate: any;
  accountTagDropdown: any[] = [];
  dataSource: any;
  displayedColumnsModel: string[] = [];
  voucherModel: any = { Voucher: { Voucher_Detail: [] }, Voucher_Detail: [] };
  voucherEditModel: any = { Voucher: [], Voucher_Detail: [] };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatPaginator, { read: MatPaginator }) set matPaginator(paginator: MatPaginator) {
    if (paginator != undefined) {
      this.dataSource.paginator = paginator;
    }
  }

  constructor(private dataSharingService: DataSharingService,private lmsService: LmsService, private route: ActivatedRoute, private MasterService: MasterService) { }
  RequestModel: RequestModel = new RequestModel();
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Voucher Trail");
    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        //console.log("paran", this.FyearId);
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.maxDate = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
          this.Search.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.Search.VoucherDateTo = new Date();
          else
            this.Search.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
      }
    )
    this.GetEmployee();
  }
  GetEmployee() {
    this.RequestModel.BranchId =0;
    this.RequestModel.RoleId = 0;
    this.MasterService.GetEmployeeList(this.RequestModel).subscribe((res: any) => {

      this.EmployeeList = res;
    //  this.Get_PartnerForDropdown();
    })
  }
  onSearchTrailVoucher() {
    this.showSpinner = true;
    var type = "CREATE";
    var VoucherSubType = "";
    var VoucherType = "";
    if (this.Search.Type == 0)
      type = "CREATE";
    if (this.Search.Type == 1)
      type = "DELETE";
    if (this.Search.Type == 2)
      type = "EDIT";
    if (this.Search.VoucherSubType == 1)
      VoucherSubType = "Loan";
    if (this.Search.VoucherSubType == 2)
      VoucherSubType = "Accounting";
    if(this.Search.VoucherSubType == 3)
      VoucherSubType = "Partner";

    if (this.Search.VoucherType == 1)
      VoucherType = "Receipt";
    if (this.Search.VoucherType == 2)
      VoucherType = "Payment";
    if (this.Search.VoucherType == 3)
      VoucherType = "Journal";
    if (this.Search.VoucherType == 4)
      VoucherType = "Contra";
    if (this.Search.VoucherType == 5)
      VoucherType = "Disbursement";
    if (this.Search.VoucherType == 6)
      VoucherType = "Repayment";

    if (this.Search.Type == 1  )
      this.displayedColumnsModel = [
        "VoucherId", "VoucherDate", "Voucher_No", "Voucher_CreateBy", "Voucher_CreateOn", "Voucher_DeleteBy", "Voucher_DeleteOn", "Voucher_Type", "Voucher_Sub_Type", "ViewAction"];
    else if (this.Search.Type == 2) {
      this.displayedColumnsModel = [
        "VoucherId", "VoucherDate", "Voucher_No", "Voucher_CreateBy", "Voucher_CreateOn", "Voucher_ModifyBy", "Voucher_ModifyOn", "Voucher_Type", "Voucher_Sub_Type", "ViewAction"];

    }
    else
      this.displayedColumnsModel = [
        "VoucherId", "VoucherDate", "Voucher_No", "Voucher_CreateBy", "Voucher_CreateOn", "Voucher_Type", "Voucher_Sub_Type", "ViewAction"];


    this.lmsService.ACC_Voucher_Trail({
      From: this.Search.VoucherDateFrom, To: this.Search.VoucherDateTo, PostFrom: this.Search.PostDateFrom,
      PostTo: this.Search.PostDateTo, EmpId: this.Search.SelectEmployee.toString(),
      VoucherType: VoucherType, VoucherSubType: VoucherSubType, Type: type
    }).subscribe((res: any) => {
      if (res.length > 0) {
        this.JsonData = JSON.parse(JSON.stringify(res));
        

        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.dataSource = null;
      }
      this.showSpinner = false;
    });
  }

  DateChange() {
    //if (this.Search.VoucherDateFrom > this.VoucherDateTo) {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "VoucherDate To greater than VoucherDate From.", ...this.configSuccess });
    //  this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {

    //    this.Search.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
    //    this.Search.VoucherDateTo = new Date();
    //  });
    //}
  }
  OnCloseVoucherDetail() {
    $('#VoucherDetail').modal('hide');
  }
  OnCloseVoucherEditDetail() {
    $('#VoucherEditDetail').modal('hide');

  }
  goToDetail(row) {
    if (this.Search.Type != 2) {
     

      this.lmsService
        .LMS_Get_Voucher_Details({ VoucherId: row.VoucherId })
        .subscribe((res: any) => {
          //console.log("res", res);
          this.voucherModel.Voucher = res.Item1[0];
          this.voucherModel.Voucher_Detail = res.Item2;

          this.voucherModel.Voucher_Detail.forEach(x => {
            x.AmountDR = 0.00;
            x.AmountCR = 0.00;
            if (x.TranType == 'CR')
              x.AmountCR = x.Amount;
            if (x.TranType == 'DR')
              x.AmountDR = x.Amount;
          });
          if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == '' || this.voucherModel.Voucher.Voucher_Mode_of_Payment == null)
            this.voucherModel.Voucher.Voucher_Mode_of_Payment = 'CASH';
          this.onVoucherTagChange();
          $('#VoucherDetail').modal('show');
          $("#VoucherDetail").css("z-index", "1050");
        });
    }
    else {
     
      this.lmsService
        .ACC_Voucher_Trail_Detail({ VoucherId: row.VoucherId })
        .subscribe((res: any) => {
          //console.log("res", res);
          this.voucherEditModel.Voucher = res.Item1;
          this.voucherEditModel.Voucher_Detail = res.Item2;

          this.voucherEditModel.Voucher_Detail.forEach(x => {
            x.AmountDR = 0.00;
            x.AmountCR = 0.00;
            if (x.TranType == 'CR')
              x.AmountCR = x.Amount;
            if (x.TranType == 'DR')
              x.AmountDR = x.Amount;
          });
          this.voucherEditModel.Voucher.forEach(x => {
            if (x.Voucher_Mode_of_Payment == '' || x.Voucher_Mode_of_Payment == null)
              x.Voucher_Mode_of_Payment = 'CASH';
            x.Voucher_Detail = [];
            x.Voucher_Detail.push(this.voucherEditModel.Voucher_Detail.filter(result => result.ID == x.ID));
          });
          console.log(" this.voucherEditModel.Voucher", this.voucherEditModel.Voucher);
          $('#VoucherEditDetail').modal('show');
          $("#VoucherEditDetail").css("z-index", "1050");

          //if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == '' || this.voucherModel.Voucher.Voucher_Mode_of_Payment == null)
          //  this.voucherModel.Voucher.Voucher_Mode_of_Payment = 'CASH';
          //this.onVoucherTagChange();
        });

    }
  }
  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = 'CASH';
    this.lmsService.Get_Acc_Accounts_By_Tag({ Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date) }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }




  exportTable() : void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("voucher_trail") as HTMLTableElement;
    const worksheetName = 'VOUCHER TRAIL'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  calculateTranTypeEditCR(Voucher_Detail) {
    if (Voucher_Detail != null) {
     
      let sum = 0.00;
      for (let i = 0; i < Voucher_Detail.length; i++) {
        sum += Number(Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeEditDR(Voucher_Detail) {
    if (Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < Voucher_Detail.length; i++) {
        sum += Number(Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
}
