import { Component, OnInit } from '@angular/core';
import { MasterService } from "../Shared/app.Masters.Service";
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Number } from 'core-js';




@Component({
  selector: 'app-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.scss']
})

export class SubComponent implements OnInit {
  // datePipe:any;
  datePipe: DatePipe = new DatePipe('en-US');
  TextData: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  Sub_Date: any ;
  sMonth: any;
  subscriptionDate:any;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  constructor(private _MasterService: MasterService, public snackBar: MatSnackBar,
    
   ) { }

  ngOnInit() {
    this.getConfiguration()
    
   
  }
  // sMonth: number | null = null;
  min: number = -99;
  max: number = 99;

  restrictToTwoDigit() {
    if (this.sMonth !== null) {
      if (this.sMonth < this.min) {
        this.sMonth = this.min;
      } else if (this.sMonth > this.max) {
        this.sMonth = this.max;
      }
    }
  }
  addMonthsToDate(date: any, months: any): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + parseInt(months));
    return newDate;
  }


  getConfiguration(){
    this._MasterService.GetConfiguration({}).subscribe((res)=>{
      // console.log(res["SUBDate"],"hfhgfhfhf");
      
      this.subscriptionDate = new Date(res[0]["SUBDate"].split('T')[0]);
     // this.subscriptionDate.setDate(this.subscriptionDate.getDate() + 1);

    });
  }

  SaveSub() {
    this._MasterService.SaveSubscibtion({ Value: this.Sub_Date }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.getConfiguration();
        this.Sub_Date='';
        this.sMonth='';
        // this.Sub_Date=this.addMonthsToDate(this.subscriptionDate, this.sMonth);
        // console.log("wfg",this.Sub_Date)
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });


      }
    });
  }
  updateValue(newValue: string) {
    this.Sub_Date = this.addMonthsToDate(this.subscriptionDate, this.sMonth);
   // this.Sub_Date.setDate(this.Sub_Date.getDate() - 1);
    // console.log("wfg",this.Sub_Date)
    
  }
  setSubDate() {
    this.Sub_Date.setDate(this.Sub_Date.getDate() + 1);
  }
}
