import { Component, OnInit } from '@angular/core';
import { MasterService } from "../Shared/app.Masters.Service";

@Component({
  selector: 'app-ed',
  templateUrl: './ed.component.html',
  styleUrls: ['./ed.component.scss']
})
export class EDComponent implements OnInit {
  TextData: any;
  loading: boolean = false;
  ChangeTextData: any='';
  constructor(private _MasterService: MasterService,) { }

  ngOnInit() {
  }
  ToDecrypt() {
    this._MasterService.EncriptToDcript({ Value: this.TextData}).subscribe((res: any) => {
      console.log("EncriptToDcript", res);
      this.ChangeTextData = res[0].Value;
    });
  }
  ToEncrypt() {
    this._MasterService.DcriptToEncript({ Value: this.TextData}).subscribe((res: any) => {
      console.log("DcriptToEncript", res);
      this.ChangeTextData = res[0].Value;
    });
  }
}
