import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  data: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  private _MasterService;
  Bank: boolean = false;
  Cash: boolean = false;
  NewProductCategoryName: any = "";
  isShowProductCategory: boolean = false;
  IsODDetail: boolean = false;
  isEqualAmortizationSplit: boolean = false;
  Generate_Amortization_AfterPayment: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  SaveNewProductRequestModel: RequestModel = new RequestModel();
  SaveProductCategoryRequestModel: RequestModel = new RequestModel();
  DeletePoductRequestModel: RequestModel = new RequestModel();

  ProductCategoryDropdown: any = [];
  ProductId: any = 0;
  SelectProductCategory: any = "";
  ProductName: string = "";
  ProductShortName: string = "";
  CRC_Co_Browser: boolean = false;
  CRC_Guarantor: boolean = false;
  FI_Co_Browser: boolean = false;
  FI_Guarantor: boolean = false;
  TVR_Co_Browser: boolean = false;
  TVR_Guarantor: boolean = false;
  PD_Guarantor: boolean = false;
  PD_Co_Browser: boolean = false;
  multipleTrunche: boolean = false;
  ProductIsActive: boolean = false;
  roiTypeValue: any;

  Insurance: boolean = true;
  CustomerRefrence: boolean = true;
  NACHPDC: boolean = true;
  hideAdd: boolean = true;
  PreCloserCharge_Type: any;
  ForCloserChargesId: any = 33;
  LockInPeriod: any;
  showSlab: boolean = false;
  @Input() ProductSummary: any;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;

  IsDisabled: boolean = false;

  ROI_Input_mathod: string = "";

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private _Route: Router,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Product");
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("Id"))
      );
      console.log("this.ProductId", this.ProductId);
      if (Number(this.ProductId) > 0) {
        this.getProductDetail();
        this.IsEdit = true;
        this.IsSave = false;
        this.IsDisabled = true;
      } else {
        this.IsEdit = false;
        this.IsSave = true;
        this.IsDisabled = false;
      }
    });
    this.getProductCategory();
  }

  onChange(e) {
    this.roiTypeValue = e.target.value;
  }

  getProductDetail() {
    this._MasterService
      .GetProductListById({ ProductId: this.ProductId })
      .subscribe((result) => {
        if (result.length > 0) {
          this.ProductId = result[0].ProductId;
          this.SelectProductCategory = result[0].ProductCatId;
          this.ProductName = result[0].Product;
          this.ProductShortName = result[0].ShortName;
          this.CRC_Co_Browser = !(result[0].CRC.split(",").indexOf(" C") == -1);
          this.CRC_Guarantor = !(result[0].CRC.split(",").indexOf(" G") == -1);
          this.FI_Co_Browser = !(result[0].FI.split(",").indexOf(" C") == -1);
          this.FI_Guarantor = !(result[0].FI.split(",").indexOf(" G") == -1);
          this.TVR_Co_Browser = !(result[0].TVR.split(",").indexOf(" C") == -1);
          this.TVR_Guarantor = !(result[0].TVR.split(",").indexOf(" G") == -1);
          this.PD_Guarantor = !(result[0].PD.split(",").indexOf(" G") == -1);
          this.PD_Co_Browser = !(result[0].PD.split(",").indexOf(" C") == -1);

          this.multipleTrunche = !(result[0].MultipleTranche == "No");
          this.Insurance = !(result[0].Insurance == "No");
          this.CustomerRefrence = !(result[0].CustomerReference == "No");
          this.NACHPDC = !(result[0].NACHPDC == "No");
          this.Bank = result[0].Bank;
          this.Cash = result[0].Cash;
          this.IsODDetail = result[0].IsODDetail;
          this.isEqualAmortizationSplit = result[0].IsEqualSplit;
          this.Generate_Amortization_AfterPayment =
            result[0].Generate_Amortization_AfterPayment;
          this.ROI_Input_mathod = result[0].ROI_Input_mathod;
        }
      });
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  onSaveNewProduct() {
    this.SaveNewProductRequestModel.ProductId = this.ProductId;
    this.SaveNewProductRequestModel.ProductCategory =
      this.SelectProductCategory;
    this.SaveNewProductRequestModel.ProductName = this.ProductName;
    this.SaveNewProductRequestModel.ProductShortName = this.ProductShortName;
    this.SaveNewProductRequestModel.IsCoBrowser_CRC = this.CRC_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_CRC = this.CRC_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_FI = this.FI_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_FI = this.FI_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_TVR = this.TVR_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_TVR = this.TVR_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_PD = this.PD_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_PD = this.PD_Guarantor;
    this.SaveNewProductRequestModel.IsMultipleTranche = this.multipleTrunche;
    this.SaveNewProductRequestModel.LoginUserId = this.data.userId;
    this.SaveNewProductRequestModel.IsInsurance = this.Insurance;
    this.SaveNewProductRequestModel.IsNACHPDC = this.NACHPDC;
    this.SaveNewProductRequestModel.IsCustomerReference = this.CustomerRefrence;
    this.SaveNewProductRequestModel.IsLoginFeeCash = this.Cash;
    this.SaveNewProductRequestModel.IsLoginFeeBank = this.Bank;
    this.SaveNewProductRequestModel.IsODDetail = this.IsODDetail;
    this.SaveNewProductRequestModel.IsEqualSplit =
      this.isEqualAmortizationSplit;
    this.SaveNewProductRequestModel.Generate_Amortization_AfterPayment =
      this.Generate_Amortization_AfterPayment;
    this.SaveNewProductRequestModel.ROI_Input_mathod = this.roiTypeValue;

    let _data = {
      Product: this.SaveNewProductRequestModel,
      ProductCharges: [],
    };
    console.log("requested data", _data);
    /*_data.ProductCharges = JSON.parse(JSON.stringify(this.chargesHead.filter((obj: any) => { return obj.select })));
    _data.ProductCharges.forEach((obj: any) => {
      delete obj.select;
      delete obj.Id;
      delete obj.ChagesHead;
    })*/

    this._MasterService
      .SaveLoanProduct({ JSON: JSON.stringify(_data) })
      .subscribe((response) => {
        if (response[0].CODE > 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          if (this.IsSave == true) {
            this._Route.navigate([
              `/product/product/${this._EncrDecrService.encrypt(
                encodeURIComponent(response[0].CODE)
              )}/${true}`,
            ]);
          } else {
            this._Route.navigate([
              `/product/product/${this._EncrDecrService.encrypt(
                encodeURIComponent(response[0].CODE)
              )}`,
            ]);
          }
          this.IsUpdate = false;
          this.IsEdit = true;
          this.IsDisabled = true;
          //this.OnCloseProductModal();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  saveProductCategory() {
    if (this.NewProductCategoryName == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter new product category.",
        ...this.configSuccess,
      });
    } else {
      this.SaveProductCategoryRequestModel.ProductCategoryName =
        this.NewProductCategoryName;
      this._MasterService
        .SaveProductCategory(this.SaveProductCategoryRequestModel)
        .subscribe((response) => {
          //console.log('response :: ', JSON.stringify(response));
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
          if (response[0].CODE == 1) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }

  onDeleteProductCat() {
    this.NewProductCategoryName = "";
    this.isShowProductCategory = false;
  }

  onEditProduct() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }
}
