<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 formborder d-flex align-items-center">
  <div class="col-md-3 p-1">
    <span class="required-lable">Select Status</span>
    <select
      name="Status"
      id="Status"
      #refStatus="ngModel"
      class="form-control input-text-css"
      [(ngModel)]="Status"
      (change)="LoanDeleteStatusList()"
    >
      <option value="">Select Status</option>
      <option value="Pending">Pending</option>
      <option value="Deleted">Deleted</option>
      <option value="Rejected">Rejected</option>
    </select>
  </div>
  <div class="col-md-3 offset-3 p-1">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-3 p-1 text-right">
    <button
      class="mt-3 btn font-size-12 button-btn"
      (click)="OnClickNewLoanDelete()"
    >
      New Loan Delete
    </button>
  </div>
</div>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 40px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 40px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_No }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="request_Status">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Request Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.request_Status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CreatedBy_Request">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Created By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CreatedBy_Request }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Request_On">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Created On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Request_On }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>
        <!-- Header and Row Declarations -->
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="col-md-12 mt-3 d-flex justify-content-between">
        <div class="col-md-4">
          <button class="btn font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>

        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="LoanDelete"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #df="ngForm" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Delete Loan
          </h6>
          <button
            type="button"
            (click)="OnCloseModel()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #f="ngForm"
            (keydown.enter)="onSearchDetail()"
            novalidate
            class="formborder"
          >
            <div class="row m-0">
              <div class="col-md-4 p-1">
                <span class="required-lable">Type</span>
                <select
                  name="Type"
                  id="Type"
                  class="form-control input-text-css"
                  (change)="OnChangeType()"
                  [(ngModel)]="Type"
                  #refType="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': f.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                >
                  <option value="">Select Type</option>
                  <option value="Customer">Customer Loan</option>
                  <option value="Partner">Partner Loan</option>
                </select>
              </div>
              <div class="col-md-4 p-1">
                <span class="required-lable"
                  >Loan No./Application No. For Deletion</span
                >
                <input
                  name="LoanNo"
                  [(ngModel)]="LoanNo"
                  id="LoanNo"
                  placeholder="Loan No."
                  class="form-control input-text-css"
                  #refLoanNo="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoanNo.invalid,
                    'alert-warning':
                      refLoanNo.invalid &&
                      (refLoanNo.dirty ||
                        refLoanNo.touched ||
                        refLoanNo.untouched)
                  }"
                />
              </div>
              <div class="col-md-4 p-1 mt-1">
                <button
                  type="button"
                  (click)="onSearchDetail()"
                  class="mt-3 btn font-size-12 button-btn"
                  [disabled]="!f.form.valid"
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>

          <div class="row m-0" *ngIf="Detail">
            <div class="col-md-2 p-1" *ngIf="Type != 'Partner'">
              <h6 class="fs-12">Application No</h6>
              <p class="lead fs-12">{{ Detail.ApplicationNo }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Branch</h6>
              <p class="lead fs-12">{{ Detail.Branch }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead fs-12">₹{{ Detail.NetFinance }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Tenure</h6>
              <p class="lead fs-12">{{ Detail.LoanTenure }} Months</p>
            </div>

            <div class="col-md-4 p-1">
              <h6 class="fs-12">Loan Product</h6>
              <p class="lead fs-12">{{ Detail.Product }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Loan No</h6>
              <p class="lead fs-12">{{ Detail.LoanAcNo }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Loan Date</h6>
              <p class="lead fs-12">{{ Detail.Loan_Date }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">Emi Amount</h6>
              <p class="lead fs-12">{{ Detail.EMIAmount }}</p>
            </div>
            <div class="col-md-2 p-1">
              <h6 class="fs-12">No Of Inst</h6>
              <p class="lead fs-12">{{ Detail.No_Of_Instl }}</p>
            </div>
            <div class="col-md-4 p-1">
              <h6 class="fs-12">Loan A/C Category</h6>
              <p class="lead fs-12">{{ Detail.Loan_Acc_Category }}</p>
            </div>
          </div>

          <div class="row m-0 mb-2" *ngIf="dataSourceCustomerDetail">
            <div class="col-md-12 p-1">
              <div class="table-responsive">
                <mat-table [dataSource]="dataSourceCustomerDetail">
                  <ng-container matColumnDef="CustomerId">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Customer
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <app-lms-customer-view-model
                        [customerId]="row.CustomerId"
                        [index]="i"
                        >{{ row.Customer }}</app-lms-customer-view-model
                      >
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CustomerType">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Customer Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.CustomerType == "Hirer"
                        ? "Borrower"
                        : row.CustomerType
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="GenderAge">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Gender Age
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.GenderAge }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Phone No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      


                      <div *ngIf="currentUser.IsMasking">
                        {{ row.PhoneNo | mask }}
                      </div>
        
                      <div *ngIf="!currentUser.IsMasking">
                        {{ row.PhoneNo }}
                      </div>






                      <i
                        *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                        class="fa fa-check-circle ml-2"
                        style="color: green"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Relation_With_Hirer">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Relation With Hirer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Relation_With_Hirer }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ExistingCustomer">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Existing
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ExistingCustomer }}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedCustomerDetailColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="
                      let row;
                      columns: displayedCustomerDetailColumns
                    "
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0 col-md-12 p-0" *ngIf="Detail">
            <div class="col-md-12 p-1">
              <span class="required-lable">Remarks</span>
              <input
                name="Note"
                [(ngModel)]="DeleteModel.Note"
                id="Note"
                #refNote="ngModel"
                required
                placeholder="Remarks"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': df.submitted && refNote.invalid,
                  'alert-warning':
                    refNote.invalid &&
                    (refNote.dirty || refNote.touched || refNote.untouched)
                }"
              />
            </div>
            <div class="col-md-4 p-1">
              <span class="required-lable">To be Authorized By</span>
              <select
                name="AuthorizedBy"
                id="AuthorizedBy"
                required
                [(ngModel)]="DeleteModel.AuthorizedBy"
                #refAuthorizedBy="ngModel"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': df.submitted && refAuthorizedBy.invalid,
                  'alert-warning':
                    refAuthorizedBy.invalid &&
                    (refAuthorizedBy.dirty ||
                      refAuthorizedBy.touched ||
                      refAuthorizedBy.untouched)
                }"
              >
                <option value="">Select Authorized By</option>
                <option
                  *ngFor="let cat of AuthorizedByList"
                  [value]="cat.EmpId"
                >
                  {{ cat.EmpName }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span>Attachment ({{ currentUser.UploadMaxSize_In_KB }} KB)</span
              ><br />
              <i
                class="fa fa-upload"
                *ngIf="!DeleteModel.AttachURL"
                (click)="docFile.click()"
                aria-hidden="true"
              ></i>
              <ng-container *ngIf="DeleteModel.AttachURL">
                <i class="fa fa-file-pdf mt-2" aria-hidden="true"></i
                >{{ DeleteModel.AttachURL }}
                <i
                  class="fa fa-times ml-2 mt-2"
                  aria-hidden="true"
                  style="cursor: pointer"
                  (click)="removeFile(DeleteModel, docFile)"
                ></i>
              </ng-container>
              <input
                #docFile
                type="file"
                [multiple]="false"
                accept="application/pdf,application/vnd.ms-excel"
                (change)="fileChangeListener(DeleteModel, docFile.files)"
                style="display: none"
              />
            </div>
            <div class="col-md-2 p-1 mt-4 text-right">
              <button
                type="button"
                (click)="onSaveRequest_ForDelete()"
                [disabled]="!df.form.valid"
                class="font-size-12 button-btn"
                style="margin-top: 6px"
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="LoanDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #df="ngForm" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Delete Loan Detail
          </h6>
          <button
            type="button"
            (click)="OnCloseModelDetail()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-12 p-1">
              <div class="row">
                <div class="col-md-6">
                  <span
                    >Created By :
                    {{ LoanDetail ? LoanDetail.CreatedBy_Request : "" }}</span
                  >
                </div>
                <div class="col-md-6 text-right">
                  <span
                    >Created On :
                    {{ LoanDetail ? LoanDetail.Request_On : "" }}</span
                  >
                </div>
              </div>
              <textarea rows="2" class="form-control input-text-css" disabled>{{
                LoanDetail ? LoanDetail.RequestNote : ""
              }}</textarea>
            </div>
            <div class="col-md-12 p-1 mt-1">
              <div class="row">
                <div class="col-md-6">
                  <span
                    >Authorized By :
                    {{ LoanDetail ? LoanDetail.Authorized_By : "" }}</span
                  >
                </div>
                <div class="col-md-6 text-right">
                  <span
                    >Authorized On :
                    {{ LoanDetail ? LoanDetail.AuthorizedOn : "" }}</span
                  >
                </div>
              </div>
              <textarea rows="2" class="form-control input-text-css" disabled>{{
                LoanDetail ? LoanDetail.AuthorizedNote : ""
              }}</textarea>
            </div>
            <div class="col-md-12 p-1 mt-1">
              Attachment :
              <a
                href="{{ docBaseUrl }}{{
                  LoanDetail ? LoanDetail.Application_No : ''
                }}/{{ LoanDetail ? LoanDetail.Letter_AttachURL : '' }}"
                target="_blank"
              >
                {{ LoanDetail ? LoanDetail.Letter_AttachURL : "" }}
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseModelDetail()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="lms_loan_dlt_request"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Request Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created On
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>

    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.request_Status }}</td>
    <td>{{ x.CreatedBy_Request }}</td>
    <td>{{ x.Request_On }}</td>
  </tr>
</table>
