<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div [ngClass]="{ fixheight1: PageType != 'A', fixheight2: PageType == 'A' }">
  <div class="formborder">
    <div class="row m-0 mt-0">
      <div class="col-md-12">
        <h1 class="fs-12 mt-2 h-b" *ngIf="isCalEMITableView">
          Loan Financial Details
        </h1>
      </div>
    </div>

    <ng-container *ngIf="isCalEMITableView">
      <form
        #eif="ngForm"
        class="row m-0"
        (ngSubmit)="onCalculateEmiAndIRR()"
        novalidate
      >
        <div class="col-md-12 mt-1">
          <div class="table-responsive" *ngIf="cEIModel.ProductId">
            <mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="SchemeId">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Scheme">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Scheme</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Scheme
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Amount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Amount
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="ROI">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >ROI (%)</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.ROI
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Period">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Period</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Period
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="AdvanceEMI">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Advance EMI</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.AdvanceEMI
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Select">
                <mat-header-cell
                  class="grid-header j-c-center"
                  style="max-width: 60px"
                  *matHeaderCellDef
                  >Select</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell j-c-center"
                  style="max-width: 60px"
                >
                  <input
                    type="checkbox"
                    [value]="row.SchemeId"
                    [checked]="selectedScheme.SchemeId == row.SchemeId"
                    (change)="onCheckboxChange($event, row)"
                  />
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <!-- <p *ngIf="schemeArr.length==0">No Scheme Available</p> -->
          </div>
        </div>
        <div class="row m-0 p-0 col-md-12 mt-1">
          <div class="col-md-4 mb-3">
            <span class="required-lable"> Partner Loan No </span>
            <input
              type="text"
              name="part_loan_no"
              id="part_loan_no"
              [(ngModel)]="cEIModel.part_loan_no"
              [disabled]="cEIModel.Partner_LoanId > 0"
              placeholder="Partner Loan No"
              class="form-control input-text-css"
              #refpart_loan_no="ngModel"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refpart_loan_no.invalid,
                'alert-warning':
                  refpart_loan_no.invalid &&
                  (refpart_loan_no.dirty ||
                    refpart_loan_no.touched ||
                    refpart_loan_no.untouched)
              }"
            />
          </div>
          <div class="col-md-4 mb-3">
            <span class="required-lable"> Loan Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="picker"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="part_loan_date"
                id="part_loan_date"
                [(ngModel)]="cEIModel.part_loan_date"
                #refpart_loan_date="ngModel"
                required
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': eif.submitted && refpart_loan_date.invalid,
                  'alert-warning':
                    refpart_loan_date.invalid &&
                    (refpart_loan_date.dirty ||
                      refpart_loan_date.touched ||
                      refpart_loan_date.untouched)
                }"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <!-- <input
              type="text"
              name="part_loan_date"
              id="part_loan_date"
              [(ngModel)]="cEIModel.part_loan_date"
              placeholder="Partner Loan Date"
              class="form-control input-text-css"
              #refpart_loan_date="ngModel"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refpart_loan_date.invalid,
                'alert-warning':
                  refpart_loan_date.invalid &&
                  (refpart_loan_date.dirty ||
                    refpart_loan_date.touched ||
                    refpart_loan_date.untouched)
              }"
              value="{{ cEIModel.part_loan_date | date : 'dd/MM/yyyy' }}"
              [(bsValue)]="cEIModel.part_loan_date"
              bsDatepicker
            /> -->
          </div>
          <div class="col-md-4 mb-3">
            <span class="required-lable"> EMI Start Date </span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="picker2"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="part_emi_st_date"
                id="part_emi_st_date"
                [(ngModel)]="cEIModel.part_emi_st_date"
                class="form-control input-text-css"
                #refpart_emi_st_date="ngModel"
                required
                [ngClass]="{
                  'is-invalid': eif.submitted && refpart_emi_st_date.invalid,
                  'alert-warning':
                    refpart_emi_st_date.invalid &&
                    (refpart_emi_st_date.dirty ||
                      refpart_emi_st_date.touched ||
                      refpart_emi_st_date.untouched)
                }"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>
            <!-- <input
              type="text"
              name="part_emi_st_date"
              id="part_emi_st_date"
              [(ngModel)]="cEIModel.part_emi_st_date"
              placeholder="EMI Start Date"
              class="form-control input-text-css"
              #refpart_emi_st_date="ngModel"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refpart_emi_st_date.invalid,
                'alert-warning':
                  refpart_emi_st_date.invalid &&
                  (refpart_emi_st_date.dirty ||
                    refpart_emi_st_date.touched ||
                    refpart_emi_st_date.untouched)
              }"
              value="{{ cEIModel.part_emi_st_date | date : 'dd/MM/yyyy' }}"
              [(bsValue)]="cEIModel.part_emi_st_date"
              bsDatepicker
            /> -->
          </div>
          <div class="col-md-3 mb-3">
            <span>Asset Cost (Rs.) </span>
            <input
              type="text"
              numbersOnly
              #refAsset_Cost="ngModel"
              [(ngModel)]="cEIModel.Asset_Cost"
              placeholder="Asset Cost"
              [ngClass]="{
                'is-invalid': eif.submitted && refAsset_Cost.invalid,
                'alert-warning':
                  refAsset_Cost.invalid &&
                  (refAsset_Cost.dirty ||
                    refAsset_Cost.touched ||
                    refAsset_Cost.untouched)
              }"
              name="Asset_Cost"
              id="Asset_Cost"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 mb-3">
            <span> Net Finance (Rs.) </span>
            <input
              type="text"
              numbersOnly
              required
              #refNetFinance_Amt="ngModel"
              [(ngModel)]="cEIModel.NetFinance_Amt"
              placeholder="Net Finance"
              [ngClass]="{
                'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                'alert-warning':
                  refNetFinance_Amt.invalid &&
                  (refNetFinance_Amt.dirty ||
                    refNetFinance_Amt.touched ||
                    refNetFinance_Amt.untouched)
              }"
              name="NetFinance_Amt"
              id="NetFinance_Amt"
              class="form-control input-text-css"
            />
          </div>

          <div class="col-md-3 mb-3">
            <span class="required-lable">Type</span>
            <select
              name="Type"
              id="Type"
              #refType="ngModel"
              (change)="setType($event)"
              class="form-control input-text-css"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refType.invalid,
                'alert-warning':
                  refType.invalid &&
                  (refType.dirty || refType.touched || refType.untouched)
              }"
              [(ngModel)]="cEIModel.IRR_CalculateBy"
            >
              <option value="ROI">Flat ROI</option>
              <option value="FLAT_EMI">EMI Amt (₹)</option>
              <option value="STEP_EMI">Step EMI</option>
              <option value="Reducing_ROI">Reducing ROI</option>
            </select>
          </div>

          <div class="col-md-3 mb-3">
            <span
              class="required-lable"
              *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'"
            >
              {{ cEIModel.IRR_CalculateBy }}</span
            >
            <input
              type="text"
              appTwoDigitDecimaNumber="true"
              required
              #refReducing_ROI="ngModel"
              [(ngModel)]="cEIModel.Reducing_ROI"
              placeholder="Reducing_ROI"
              *ngIf="cEIModel.IRR_CalculateBy == 'Reducing_ROI'"
              [ngClass]="{
                'is-invalid': eif.submitted && refReducing_ROI.invalid,
                'alert-warning':
                  refReducing_ROI.invalid &&
                  (refReducing_ROI.dirty ||
                    refReducing_ROI.touched ||
                    refReducing_ROI.untouched)
              }"
              name="Reducing_ROI"
              id="Reducing_ROI"
              class="form-control input-text-css"
            />

            <input
              type="text"
              placeholder="Flat Rate"
              name="Flat_Rate"
              id="Flat_Rate"
              class="form-control input-text-css"
              [hidden]="true"
              *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
            />
            <input
              type="text"
              appTwoDigitDecimaNumber
              required
              #refFlat_Rate="ngModel"
              [(ngModel)]="cEIModel.Flat_Rate"
              *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
              name="Flat_Rate"
              id="Flat_Rate"
              class="form-control input-text-css"
              [ngClass]="{
                'is-invalid': eif.submitted && refFlat_Rate.invalid,
                'alert-warning':
                  refFlat_Rate.invalid &&
                  (refFlat_Rate.dirty ||
                    refFlat_Rate.touched ||
                    refFlat_Rate.untouched)
              }"
            />

            <input
              type="text"
              placeholder="EMI Amount"
              name="EMI_Amount"
              id="EMI_Amount"
              class="form-control input-text-css"
              [hidden]="true"
              *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
            />
            <input
              type="text"
              numbersOnly="true"
              required
              #refEMI_Amount="ngModel"
              [(ngModel)]="cEIModel.EMI_Amount"
              placeholder="EMI Amount"
              *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
              [ngClass]="{
                'is-invalid': eif.submitted && refEMI_Amount.invalid,
                'alert-warning':
                  refEMI_Amount.invalid &&
                  (refEMI_Amount.dirty ||
                    refEMI_Amount.touched ||
                    refEMI_Amount.untouched)
              }"
              name="EMI_Amount"
              id="EMI_Amount"
              class="form-control input-text-css"
            />
          </div>
        </div>
        <div class="row m-0 p-0 col-md-12">
          <div class="col-md-3 mb-3">
            <span> EMI Frequency</span>
            <select
              name="EMI_Type"
              id="EMI_Type"
              #refEMI_Type="ngModel"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refEMI_Type.invalid,
                'alert-warning':
                  refEMI_Type.invalid &&
                  (refEMI_Type.dirty ||
                    refEMI_Type.touched ||
                    refEMI_Type.untouched)
              }"
              [(ngModel)]="cEIModel.EMI_Type"
              class="form-control input-text-css"
              (change)="setTenure()"
            >
              <option value="Monthly">Monthly</option>
              <option value="ByMonthly">Bi-Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="HalfYearly">Half Yearly</option>
              <option value="Yearly">Yearly</option>
              <option value="Bullet">Bullet</option>
            </select>
          </div>
          <div class="col-md-3 mb-3">
            <span> No Of Installments </span>
            <input
              type="text"
              name="No_Of_Inst"
              id="No_Of_Inst"
              numbersOnly
              #refNo_Of_Inst="ngModel"
              [(ngModel)]="cEIModel.No_Of_Inst"
              placeholder="No Of Installments"
              (blur)="setTenure()"
              required
              [ngClass]="{
                'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                'alert-warning':
                  refNo_Of_Inst.invalid &&
                  (refNo_Of_Inst.dirty ||
                    refNo_Of_Inst.touched ||
                    refNo_Of_Inst.untouched)
              }"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 mb-3">
            <span> Tenure (Month) </span>
            <input
              type="text"
              numbersOnly
              required
              #refTenure="ngModel"
              [(ngModel)]="cEIModel.Tenure"
              placeholder="Tenure"
              [ngClass]="{
                'is-invalid': eif.submitted && refTenure.invalid,
                'alert-warning':
                  refTenure.invalid &&
                  (refTenure.dirty || refTenure.touched || refTenure.untouched)
              }"
              name="Tenure"
              id="Tenure"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 mb-3">
            <span> Instl In Adv. </span>
            <input
              type="text"
              numbersOnly
              required
              #refAdv_Inst="ngModel"
              [(ngModel)]="cEIModel.Adv_Inst"
              placeholder="Instl In Adv."
              (blur)="setAdvanceInstl()"
              [ngClass]="{
                'is-invalid': eif.submitted && refAdv_Inst.invalid,
                'alert-warning':
                  refAdv_Inst.invalid &&
                  (refAdv_Inst.dirty ||
                    refAdv_Inst.touched ||
                    refAdv_Inst.untouched)
              }"
              name="Adv_Inst"
              id="Adv_Inst"
              class="form-control input-text-css"
            />
          </div>

          <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
            <div class="row m-0 mt-3 justify-content-center">
              <div class="table-border">
                <div class="item-row header">
                  <div class="lead w50 fs-14">#</div>
                  <div class="lead w120 fs-14">From EMI</div>
                  <div class="lead w120 fs-14">To EMI</div>
                  <div class="lead fs-14">EMI Amount</div>
                  <div class="lead w120 fs-14">No of EMI</div>
                  <div class="lead w150 fs-14">Total Amount</div>
                </div>
                <div
                  class="item-row"
                  *ngFor="let semi of stepEMI; let i = index"
                >
                  <div class="w50">{{ i + 1 }}</div>
                  <div class="w120">
                    <input
                      type="text"
                      numbersOnly
                      readonly
                      [(ngModel)]="semi.FromEMI"
                      placeholder="From EMI"
                      name="FromEMI{{ i }}"
                      id="FromEMI{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="w120">
                    <input
                      type="text"
                      numbersOnly
                      [disabled]="semi.disable"
                      [(ngModel)]="semi.ToEMI"
                      placeholder="To EMI"
                      name="ToEMI{{ i }}"
                      id="ToEMI{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="">
                    <input
                      type="text"
                      numbersOnly
                      [disabled]="semi.disable"
                      [(ngModel)]="semi.EMI_Amount"
                      placeholder="EMI Amount"
                      name="EMI_Amount{{ i }}"
                      id="EMI_Amount{{ i }}"
                      class="form-control input-text-css mr-3 emi-amount"
                    />
                    <button
                      type="button"
                      *ngIf="!semi.disable"
                      class="primary-btn"
                      (click)="addNextStep(semi)"
                    >
                      <i class="fa fa-check-circle" style="font-size: 18px"></i>
                    </button>
                    <button
                      type="button"
                      class="primary-btn"
                      style="margin-left: 10px"
                      *ngIf="!semi.disable && i > 0"
                      (click)="removeLastStep(i)"
                    >
                      <i class="fa fa-minus-circle" style="font-size: 18px"></i>
                    </button>
                  </div>
                  <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                  <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                </div>
                <div class="item-row header" *ngIf="stepEmiData">
                  <div class="lead w50 fs-14">&nbsp;</div>
                  <div class="lead w120 fs-14"><b>Total</b></div>
                  <div class="lead w120 fs-14">&nbsp;</div>
                  <div class="lead fs-14">&nbsp;</div>
                  <div class="lead w120 fs-14">
                    <b>{{ stepEmiData.totalEmi }}</b>
                  </div>
                  <div class="lead w150 fs-14">
                    <b>{{ stepEmiData.totalAmount }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 col-md-12 justify-content-center">
          <button
            type="button"
            (click)="onCalculateEmiAndIRR()"
            class="pl-5 pr-5 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!eif.form.valid"
          >
            Calculate EMI and IRR
          </button>
        </div>
        <div class="row col-md-12 m-0 p-0 mt-3" id="scrollto" *ngIf="emiIrr">
          <div class="col-md-3">
            <h6 class="fs-12">Disbursement Amount</h6>
            <p class="lead fs-12">₹ {{ emiIrr.Disbursement_Amt.toFixed(2) }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Interest Amount</h6>
            <p class="lead fs-12">₹ {{ emiIrr.Interest_Amt.toFixed(2) }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Agreement Value</h6>
            <p class="lead fs-12">₹ {{ emiIrr.Agreement_Value.toFixed(2) }}</p>
          </div>
          <div class="row m-0 col-md-3">
            <div class="col-md-6">
              <h6 class="fs-12">LTV</h6>
              <p class="lead fs-12">{{ emiIrr.LTV.toFixed(2) }} %</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">ROI</h6>
              <p class="lead fs-12">{{ emiIrr.ROI.toFixed(2) }} %</p>
            </div>
          </div>

          <div class="col-md-3">
            <h6 class="fs-12">EMI Amount</h6>
            <p class="lead fs-12">{{ emiIrr.EMI_Amt.toFixed(2) }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Case IRR</h6>
            <p class="lead fs-12">{{ emiIrr.Case_IRR.toFixed(2) }} %</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Disbursement IRR</h6>
            <p class="lead fs-12">{{ emiIrr.Disbursement_IRR.toFixed(2) }} %</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Margin</h6>
            <p class="lead fs-12">₹ {{ emiIrr.Margin.toFixed(2) }}</p>
          </div>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              (click)="onClose()"
              [hidden]="!IsCloseBtn"
              class="btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveApplication()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!eif.form.valid"
            >
              {{ Btn_Action }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="isCalEMIForm">
      <!--<p (click)="EmiCalculator()" style="color: #fff !important;background-color: #4dc3a3 !important;text-align: right;"><a>Add Loan Emi</a></p>-->
      <div
        class="row col-md-12 m-0 mt-3 h-b w-b align-items-center justify-content-between"
      >
        <h1 class="fs-12 m-0">Loan Financial Details</h1>
        <button
          type="button"
          class="btn btn-success font-size-12"
          (click)="onEditFinanceDetail()"
        >
          <div>
            <i
              class="fa fa-edit ml-2"
              style="color: black; font-size: 13px"
            ></i>
          </div>
        </button>
      </div>

      <div class="row m-0 mt-2">
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">₹ {{ applicationDetail.AssetCost }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Loan No.</h6>
          <p class="lead fs-12">{{ applicationDetail.PLoanAcNo }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ applicationDetail.Partner_Loan_Date }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Net Finance</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.NetFinance }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ applicationDetail.Flat_Rate }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Tenure</h6>
          <p class="lead fs-12">
            {{ applicationDetail.Tenure }} {{ Tenure_Name }}
          </p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.No_Of_Instl }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Adv. Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.Adv_Instl }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Disbursement Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.DisbursementAmt }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.AgreementValue }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.InterestAmt }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.EMIAmount }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ applicationDetail.Case_IRR.toFixed(2) }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Disbursement IRR</h6>
          <p class="lead fs-12">
            {{ applicationDetail.Disbursement_IRR.toFixed(2) }}%
          </p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ applicationDetail.LTV }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Margin</h6>
          <p class="lead fs-12">
            ₹ {{ applicationDetail.Margin < 0 ? 0 : applicationDetail.Margin }}
          </p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Expiry Date</h6>
          <p class="lead fs-12">{{ applicationDetail.ExpiryDate }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row m-0 mt-2" *ngIf="cEIModel.Partner_LoanId > 0">
    <div class="col-md-12 p-0" id="scrollto">
      <h6 name="myModalLabel">Receipt & Deduction Details</h6>
    </div>

    <!-- <div class="tab12 row m-0" style="width: 100%;">
      <div style=" border: 1px solid #ddd;position: unset"
        class="m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr" (click)="changeRDTab('list')"
        [ngClass]="{'active':tab=='list'}">
        Receipt & Deduction List
      </div>
      <div style=" border: 1px solid #ddd;position: unset"
        class="m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr" (click)="changeRDTab('new')"
        [ngClass]="{'active':tab=='new'}">
        Add New Deduction
      </div>
    </div> -->

    <!-- <div class="col-md-6 p-0">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'list' }" (click)="changeRDTab('list')">
        Receipt & Deduction List
      </button>
    </div>
    <div class="col-md-6">
      <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'new' }" (click)="changeRDTab('new')">
        Add New Deduction
      </button>
    </div> -->

    <div class="col-md-12 p-0" *ngIf="tab == 'list'" style="min-height: 120px">
      <div class="table-responsive mt-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" style="display: none">Id</th>
              <th scope="col" style="display: none">LoanId</th>
              <th scope="col" style="width: 152px">Type</th>
              <th scope="col">ApplicationNo</th>
              <th scope="col">Charges Head</th>
              <th scope="col">Account</th>
              <th scope="col">Tax (%)</th>
              <th scope="col" style="width: 125px">Amount</th>
              <th scope="col">CollectionOn</th>
              <th scope="col">CollectionBy</th>
              <!-- <th scope="col">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of ReceiptDeduction; let i = index">
              <td>{{ i + 1 }}</td>
              <td style="display: none">{{ data.Id }}</td>
              <td style="display: none">{{ data.LoanId }}</td>
              <td>{{ data.Type }}</td>
              <td>{{ data.ApplicationNo }}</td>
              <td>{{ data.ChagesHead }}</td>
              <td>{{ data.Account }}</td>
              <td>{{ data.TaxPercentage }}</td>
              <td>₹ {{ data.Amount }}</td>
              <td>{{ data.CollectionOn }}</td>
              <td>{{ data.CollectionBy }}</td>
              <!-- <td>
                <i class="fa fa-pencil-alt right" style="display:none; cursor: pointer;color:green;font-size:16px;"
                  aria-label="true" (click)="Get_ReceiptDeduction(data)"></i>
                <i *ngIf="data.Type != 'Receipt' && !data.IsTax" class="fa fa-minus-circle font-size-15 ml-3" (click)="removeReceiptDeduction(data)"
                  style="color:red;cursor: pointer;" aria-label="true"></i>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-12 p-1" *ngIf="tab == 'new'" style="min-height: 120px">
      <!-- <form #rdf="ngForm" class="row m-0 col-md-12 p-0" (ngSubmit)="onSaveRdDetail()" novalidate>
        <div class="row m-0  col-md-12 p-0">
          <div class="col-md-2 p-1">
            <span> Charges</span>
            <select name="ChargeHeadId" id="ChargeHeadId" #refChargeHeadId="ngModel"
                    [ngClass]="{ 'is-invalid': rdf.submitted && refChargeHeadId.invalid, 'alert-warning': refChargeHeadId.invalid  && (refChargeHeadId.dirty || refChargeHeadId.touched || refChargeHeadId.untouched) }"
                    [(ngModel)]="ReceiptData.Charges" class="form-control input-text-css" required>
              <option value=""> Select Charges </option>
              <option value="File Charges"> File Charges </option>
              <option value="Login Fee"> Login Fee </option>
            </select>
          </div>
          <div class="col-md-2 p-1">
            <span> Charges Type</span>
            <select name="ChargeReceiptDeduction" id="ChargeReceiptDeduction" #refChargeReceiptDeduction="ngModel"
                    [ngClass]="{ 'is-invalid': rdf.submitted && refChargeReceiptDeduction.invalid, 'alert-warning': refChargeReceiptDeduction.invalid  && (refChargeReceiptDeduction.dirty || refChargeReceiptDeduction.touched || refChargeReceiptDeduction.untouched) }"
                    [(ngModel)]="ReceiptData.Type" class="form-control input-text-css" required>
              <option value=""> Select Charges Type </option>
              <option value="Receipt"> Receipt </option>
              <option value="Deduction"> Deduction </option>
            </select>
          </div>
          <div class="col-md-2 p-1">
            <span> Amount</span>
            <input type="number" #refRDAmount="ngModel" (change)="CalCGSTSGST()" [(ngModel)]="ReceiptData.Amount" placeholder="Amount"
                  [ngClass]="{ 'is-invalid': rdf.submitted && refRDAmount.invalid, 'alert-warning': refRDAmount.invalid  && (refRDAmount.dirty || refRDAmount.touched || refRDAmount.untouched) }"
                  name="Amount" id="Amount" class="form-control input-text-css" required>
          </div>
          <div class="col-md-2 p-1">
            <span> CGST</span>
            <input type="number" #refRDAmount="ngModel" [disabled]="disabled" [(ngModel)]="ReceiptData.CGST" placeholder="CGST"
                  [ngClass]="{ 'is-invalid': rdf.submitted && refRDAmount.invalid, 'alert-warning': refRDAmount.invalid  && (refRDAmount.dirty || refRDAmount.touched || refRDAmount.untouched) }"
                  name="CGST" id="CGST" class="form-control input-text-css" required>
          </div>
          <div class="col-md-2 p-1">
            <span> SGST</span>
            <input type="number" #refRDAmount="ngModel" [disabled]="disabled" [(ngModel)]="ReceiptData.SGST" placeholder="SGST"
                  [ngClass]="{ 'is-invalid': rdf.submitted && refRDAmount.invalid, 'alert-warning': refRDAmount.invalid  && (refRDAmount.dirty || refRDAmount.touched || refRDAmount.untouched) }"
                  name="SGST" id="SGST" class="form-control input-text-css" required>
          </div>
          <div class="col-md-2 p-1">
            <span>Total Amount</span>
            <input type="number" #refRDAmount="ngModel" [disabled]="disabled" [(ngModel)]="ReceiptData.TotalAmount" placeholder="Total Amount"
                  [ngClass]="{ 'is-invalid': rdf.submitted && refRDAmount.invalid, 'alert-warning': refRDAmount.invalid  && (refRDAmount.dirty || refRDAmount.touched || refRDAmount.untouched) }"
                  name="TotalAmount" id="TotalAmount" class="form-control input-text-css" required>
          </div>

        </div>
        <div class="row m-0 mt-3  col-md-12 p-0 justify-content-end">
          <button type="button" (click)="changeRDTab('list')" class="btn btn-light font-size-12 mr-3">
            Cancel
          </button>
          <button type="button" (click)="onSaveRdDetail()" *ngIf="!inViewMode" [hidden]="!IsReceiptSave"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
                  [class.spinner]="loading" [disabled]="!rdf.form.valid">
            Save
          </button>
          <button type="button" (click)="onUpdateRdDetail()" *ngIf="!inViewMode" [hidden]="!IsReceiptUpdate"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
                  [class.spinner]="loading" [disabled]="!rdf.form.valid">
            Update
          </button>
        </div>
      </form> -->
      <form #df="ngForm" class="row m-0 col-md-12 p-0" novalidate>
        <div class="row m-0 col-md-12 p-0 mt-3 add-remove">
          <div class="col-md-12 p-0 row m-0 type-column">
            <!-- <div class="type">
              <span *ngIf="i==0">Type</span>
              <input type="text" name="TranType{{ i }}" id="TranType{{ i }}" class="form-control input-text-css" readonly
                    [(ngModel)]="Voucher_Detail.TranType">
            </div> -->
            <div class="row m-0 col-md-12 p-0 feild">
              <ng-container>
                <div class="col-md-2 pr-0">
                  <span class="required-lable">Search</span>
                  <div class="input-btn">
                    <input
                      required
                      type="text"
                      readonly
                      name="CaseNo"
                      id="CaseNo"
                      [(ngModel)]="Voucher_Detail.CaseNo"
                      class="form-control input-text-css"
                      (keyup.enter)="searchCustomer()"
                    />
                    <!-- <button type="button" (click)="searchCustomer()" class="primary-btn ml-2 search">
                      <i class="fa fa-search" aria-hidden="true" style="font-size:11px"></i>
                    </button> -->
                  </div>
                </div>
                <div class="col-md-2 pr-0">
                  <span>Customer</span>
                  <input
                    type="text"
                    name="Customer"
                    id="Customer"
                    class="form-control input-text-css"
                    readonly
                    [(ngModel)]="Voucher_Detail.Customer"
                  />
                </div>
                <div class="col-md-2 pr-0">
                  <span>Branch</span>
                  <input
                    type="text"
                    name="Branch_Name"
                    id="Branch_Name"
                    class="form-control input-text-css"
                    readonly
                    [(ngModel)]="Voucher_Detail.Branch_Name"
                  />
                </div>
                <div class="col-md-2 pr-0">
                  <span class="required-lable">Category</span>
                  <select
                    name="ChargeHeadId"
                    id="ChargeHeadId"
                    required
                    #refChargeHeadId="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refChargeHeadId.invalid,
                      'alert-warning':
                        refChargeHeadId.invalid &&
                        (refChargeHeadId.dirty ||
                          refChargeHeadId.touched ||
                          refChargeHeadId.untouched)
                    }"
                    [(ngModel)]="Voucher_Detail.ChargeHeadId"
                    class="form-control input-text-css"
                    (change)="onChangeChargesHead(Voucher_Detail)"
                    [disabled]="!Voucher_Detail.ProductId"
                  >
                    <option value="">Select Category</option>
                    <option
                      *ngFor="let item of chargesHeadDropdown"
                      [value]="item.Id"
                    >
                      {{ item.ChagesHead }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2 pr-0">
                  <span> Account Head</span>
                  <input
                    type="text"
                    name="ChargeHeadCategory"
                    id="ChargeHeadCategory"
                    class="form-control input-text-css"
                    readonly
                    [(ngModel)]="Voucher_Detail.ChargeHeadCategory"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Amount</span>
                  <input
                    type="number"
                    min="0"
                    name="Amount"
                    id="Amount"
                    [disabled]="!Voucher_Detail.ChargeHeadId"
                    class="form-control input-text-css text-right pr-3"
                    required
                    #refAmount="ngModel"
                    (change)="onChangeAmount($event, Voucher_Detail)"
                    (keyup.enter)="onChangeAmount($event, Voucher_Detail)"
                    [ngClass]="{
                      'is-invalid': df.submitted && refAmount.invalid,
                      'alert-warning':
                        refAmount.invalid &&
                        (refAmount.dirty ||
                          refAmount.touched ||
                          refAmount.untouched)
                    }"
                    [(ngModel)]="Voucher_Detail.Amount"
                  />
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container
            *ngIf="Voucher_Detail.TaxSlab && Voucher_Detail.TaxSlab.length > 0"
          >
            <div
              class="col-md-12 row m-0 p-0 mt-2"
              *ngFor="let taxData of Voucher_Detail.TaxSlab; let ti = index"
            >
              <div class="col-md-2 pr-0">
                <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                <!-- <input type="text" readonly name="SlabName{{ i }}{{ti}}" id="SlabName{{ i }}{{ti}}"
                  [(ngModel)]="taxData.SlabName" class="form-control input-text-css" /> -->
              </div>
              <div class="col-md-3 pr-0">
                <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                <!-- <input type="text" readonly name="TaxType{{ i }}{{ti}}" id="TaxType{{ i }}{{ti}}"
                  [(ngModel)]="taxData.TaxType" class="form-control input-text-css" /> -->
              </div>
              <div class="col-md-2 pr-0">
                <span><b>Rate :</b>&nbsp;{{ taxData.TaxRate }} %</span>
                <!-- <input type="text" readonly name="TaxRate{{ i }}{{ti}}" id="TaxRate{{ i }}{{ti}}"
                  [(ngModel)]="taxData.TaxRate" class="form-control input-text-css" /> -->
              </div>
              <div class="col-md-3 pr-0">
                <span
                  ><b>Account Head :</b>&nbsp;{{ taxData.TaxAccountName }}</span
                >
                <!-- <input type="text" readonly name="TaxAccountName{{ i }}{{ti}}" id="TaxAccountName{{ i }}{{ti}}"
                  [(ngModel)]="taxData.TaxAccountName" class="form-control input-text-css" /> -->
              </div>
              <div class="col-md-2 text-right pr-5">
                <span>
                  <b></b>&nbsp;<ng-container *ngIf="taxData.TaxAmount">{{
                    taxData.TaxAmount
                  }}</ng-container>
                </span>
                <!-- <input type="text" readonly name="TaxAmount{{ i }}{{ti}}" id="TaxAmount{{ i }}{{ti}}"
                  [(ngModel)]="taxData.TaxAmount" class="form-control input-text-css" /> -->
              </div>
            </div>
          </ng-container>

          <!-- <button type="button" class="remove" (click)="removeData(i)" [ngClass]="{'no-head':i!=0}"
                  *ngIf="voucherModel.Voucher_Detail.length>1">
            -
          </button>
          <button type="button" class="addmore" (click)="addMoreData()"
                  [ngClass]="{'both':voucherModel.Voucher_Detail.length>1,'no-head':i!=0}" [disabled]="!df.form.valid"
                  *ngIf="i == voucherModel.Voucher_Detail.length-1">
            +
          </button> -->
        </div>
        <div class="col-md-12 row m-0 p-0 pr-4 mt-2" *ngIf="totalCalculator">
          <div class="col-md-10 pr-0">
            <span><b>Grand Total :</b></span>
          </div>
          <!-- <div class="col-md-5  pr-0">
            <span><b>In Word :</b>&nbsp;{{totalCalculator.totalInWord}} </span>
          </div> -->
          <div class="col-md-2 text-right pr-5">
            <span
              ><b>In Digit :</b>&nbsp;{{
                totalCalculator.total.toFixed(2)
              }}</span
            >
          </div>
        </div>
        <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
          <button
            type="button"
            (click)="changeRDTab('list')"
            class="btn btn-light font-size-12 mr-3"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveRdDetail()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!df.form.valid"
          >
            Save
          </button>
          <!-- <button type="button" (click)="onUpdateRdDetail()" *ngIf="!inViewMode" [hidden]="!IsReceiptUpdate"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
                  [class.spinner]="loading" [disabled]="!df.form.valid">
            Update
          </button> -->
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row col-md-12 m-0 mt-3 justify-content-end" *ngIf="isCalEMIForm">
  <button
    type="button"
    (click)="onClickNext()"
    class="ml-4"
    class="mt-3 btn font-size-12 button-btn"
    [class.spinner]="loading"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearch()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <!--<form #sf="ngForm" class="col-md-12" novalidate>
            <div class="row m-0 ">
              <div class="col-md-6">
                <span>Application No </span>
                <input type="text" required name="SearchValue" id="SearchValue" #refSearchValue="ngModel"
                  [ngClass]="{ 'is-invalid': sf.submitted && refSearchValue.invalid, 'alert-warning': refSearchValue.invalid  && (refSearchValue.dirty || refSearchValue.touched || refSearchValue.untouched) }"
                  [(ngModel)]="Voucher_Detail.SearchValue" class="form-control input-text-css"
                  (keyup.enter)="Get_Acc_Search_LoanNo()" />
              </div>
              <div class="row col-md-6 m-0  align-items-center">
                <button type="button" class="mt-3" style="background-color: #28a745; color: #ffffff; font-size: 12px"
                  mat-raised-button color="primary" (click)="Get_Acc_Search_LoanNo()" [class.spinner]="loading"
                  [disabled]="!sf.form.valid">
                  Search
                </button>
              </div>
            </div>
            <div class="row m-o mt-3">
              <div class="col-md-12">
                <mat-table [dataSource]="customerdataSource">
                  <ng-container matColumnDef="LoanId">
                    <mat-header-cell class="grid-header" style="max-width: 50px" *matHeaderCellDef>
                      #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" class="grid-cell"
                      style="max-width: 50px; max-height: 5px">
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="LoanNo">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Application No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.LoanNo }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Product_Name">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Product Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Product_Name }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Customer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Customer }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      PhoneNo
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.PhoneNo }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Branch_Name">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      Branch
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Branch_Name }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell class="grid-header" style="max-width: 75px" *matHeaderCellDef>
                      Action
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell " style="max-width: 75px">
                      <button type="button" class=""
                        style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button
                        color="primary" (click)="selectSearchData(row)">
                        Select
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="customerdisplayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: customerdisplayedColumns" style="cursor: pointer"></mat-row>
                </mat-table>
              </div>
            </div>
          </form>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button type="button" class="btn btn-light font-size-12" (click)="OnCloseSearch()">
              Close
            </button>
          </div>-->

          <form
            #f="ngForm"
            (ngSubmit)="onSearchCustomerDetail()"
            novalidate
            class="row m-0 col-md-12 p-0"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3">
                <span>Type</span>
                <select
                  name="Type"
                  id="Type"
                  class="form-control input-text-css"
                  [(ngModel)]="Type"
                >
                  <option [value]="0">All</option>
                  <option [value]="1">LOS Only</option>
                  <option [value]="2">LMS Only</option>
                </select>
              </div>
              <div class="col-md-3">
                <span>Application No.</span>
                <input
                  name="SApplicationNo"
                  [(ngModel)]="Customer.ApplicationNo"
                  id="SApplicationNo"
                  placeholder="Application No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Loan No.</span>
                <input
                  name="SLoanNo"
                  [(ngModel)]="Customer.LoanNo"
                  id="SLoanNo"
                  placeholder="Loan No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Customer Name</span>
                <input
                  name="CustomerName"
                  [(ngModel)]="Customer.CustomerName"
                  id="CustomerName"
                  placeholder="Customer Name"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Customer Phone</span>
                <input
                  name="CustomerPhone"
                  [(ngModel)]="Customer.CustomerPhone"
                  id="CustomerPhone"
                  placeholder="Customer Phone"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>PAN Card</span>
                <input
                  name="PANCard"
                  [(ngModel)]="Customer.PANCard"
                  id="PANCard"
                  placeholder="PAN Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Passport No.</span>
                <input
                  name="Passport"
                  [(ngModel)]="Customer.Passport"
                  id="Passport"
                  placeholder="Passport No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Voter ID Card</span>
                <input
                  name="VoterID"
                  [(ngModel)]="Customer.VoterID"
                  id="VoterID"
                  placeholder="Voter ID Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Driving License</span>
                <input
                  name="DL"
                  [(ngModel)]="Customer.DL"
                  id="DL"
                  placeholder="Driving License"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Aadhaar Card</span>
                <input
                  name="Aadhaar"
                  [(ngModel)]="Customer.Aadhaar"
                  id="Aadhaar"
                  placeholder="Aadhaar Card"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </form>
          <div class="table-responsive mt-3">
            <mat-table
              [dataSource]="dataSourceCustomer"
              #sortList="matSort"
              matSort
              id="exportReport"
            >
              <!--<ng-container matColumnDef="CustomerId">
                <mat-header-cell mat-sort-header class="grid-header" style="max-width: 50px" *matHeaderCellDef>
                  #
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index" class="grid-cell" style="max-width: 50px; max-height: 5px">
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>-->
              <ng-container matColumnDef="Type">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 150px; justify-content: center"
                  *matHeaderCellDef
                >
                  Type
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 150px; justify-content: center"
                >
                  {{ row.Type }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Application_No">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 150px; justify-content: center"
                  *matHeaderCellDef
                >
                  Aplication No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 150px; justify-content: center"
                >
                  {{ row.Application_No }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 150px; justify-content: center"
                  *matHeaderCellDef
                >
                  Loan No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 150px; justify-content: center"
                >
                  {{ row.LoanAcNo }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="CustomertName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Customer
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.CustomertName }}({{ row.AC_CustomerType }})
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="FatherName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Relation Name
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.FatherName }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Customer_Gender">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                >
                  Gender
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 50px"
                >
                  {{ row.Customer_Gender }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                >
                  Age
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 50px"
                >
                  {{ row.GenderAge }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 150px; justify-content: center"
                  *matHeaderCellDef
                >
                  Phone No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 150px; justify-content: center"
                >
                  {{ row.PhoneNo }}
                  <i
                    *ngIf="row.Customer_PhoneNo_IsVerified == true"
                    class="fa fa-check-circle ml-2"
                    style="color: green"
                  ></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="EditAction">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 100px; justify-content: center"
                  *matHeaderCellDef
                >
                  View Detail
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell j-c-center"
                  style="max-width: 60px"
                >
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="selectSearchData(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedCustomerSearchColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedCustomerSearchColumns"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
