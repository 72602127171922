import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { LosService } from '../../_LOS/services/los.service';

import { PartnerService } from "../../_Partner/services/partner.service";
import { Router } from '@angular/router';
declare var $: any;
import { ToWords } from 'to-words';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-voucher-entry',
  templateUrl: './voucher-entry.component.html',
  styleUrls: ['./voucher-entry.component.scss']
})
export class VoucherEntryComponent implements OnInit {
  showSpinner: boolean = false;
  isBankTag: boolean = false;
  currentindex: any;
  Tax_Slab_Data: any[] = [];
  currentUser: any;
  loading: boolean = false;
  CurrentDate: any = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  @Input() voucherId: any;
  @Input() index: any;
  IsGSTEnable: any;
  maxDate: any;
  Min_Date: any;
  today: Date = new Date();
  GSTModel: any = {};
  AccountingSelect: any = {};
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  DBVoucher_Detail: any;
  DBVoucher_Master: any;
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  accountHeadContraDropdown: any[] = [];
  accountHeadJVDropdown: any[] = [];
  empBranchDropdown: any[] = [];
  stateDropdown: any[] = [];
  selectedData: any = {};
  Bind_Voucher: any = 1;
  AccountHeadForGST: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];
  taxSlabs: any[] = [];
  totalCalculator: any;
  selectedSearchIndex: any;
  Customer: any = {};
  Type: any = "0";
  DBVoucherType: any;
  DBVoucherSub_Type: any;
  DBVoucherNo: any;
  docModal: any = { DocumentImages: [] };
  oldDocModel: any = { DocumentImages: [] };
  CustomerList: any[] = [];
  OldData: any = { Voucher: [], Voucher_Detail: [] };
  isShowPopupApplicationDetail: boolean = false;
  CheckDate: any;
  Days_Allowed: any;
  SelectType: any = '';
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  OldVoucherDate: any;
  AccCatDropdown: any[] = [];
  slabList: any[] = [];
  Data_FreezeDate: any;
  Days_AllowedDateMin: any = new Date();
  Days_AllowedDateMax: any = new Date();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  CloseCase: any = 0;
  constructor(private datepipe: DatePipe,
    private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private _MasterService: MasterService, private dialog: DialogService,
    private lmsService: LmsService, private losService: LosService, private _Partnerservice: PartnerService,
  ) {
    this.CurrentDate = new Date();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    if (this.voucherId == undefined || this.voucherId == '') {
     // this.dataSharingService.HeaderTitle.next("Voucher Edit");
    }

    this.dataSharingService.getLmsIsShowApplicationDetail().subscribe((res: any) => {
      if (res) {
        this.isShowPopupApplicationDetail = JSON.parse(res);
      }
    });

   

  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    })
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    })
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    })
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 }).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    })
  }

  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId: '',
      Area: '',
      SubArea: '',
      SalesEx: '',
      CollectionEx: '',
      LoanAccountingCatId: '',
    };
  }

  onOpenViewDetailModal() {
    this.GetStateDropdown();
    this.Get_TaxSlab_Dropdown();
    this.Data_FreezeDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    this.Days_Allowed = this.currentUser.Days_Allowed;
    //var Days_AllowedDate = new Date();
    this.Days_AllowedDateMin.setDate(this.Days_AllowedDateMin.getDate() - this.Days_Allowed);
    this.Days_AllowedDateMax.setDate(this.Days_AllowedDateMax.getDate() + this.Days_Allowed);

    if (this.Days_AllowedDateMin > this.Data_FreezeDate) {
      this.CheckDate = this.Days_AllowedDateMin;
    } else {
      this.CheckDate = this.Data_FreezeDate;
    }
    this.Customer.CloseCase = '';
    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
    this.Get_Acc_Accounts_OtherThan_BankCash();
   
    //this.getChargeshead(true);
    this.Get_EmployeeBranchForDropdown();
    this.Get_Acc_Accounts_contra();
    this.lmsService.LMS_Get_Voucher_Details({ VoucherId: this.voucherId }).subscribe((res: any) => {
      console.log("LMS_Get_Voucher_Details", res);

      this.voucherModel.Voucher = res.Item1[0];
      this.CloseCase = this.voucherModel.Voucher.closeCaseExists;

      this.OldVoucherDate = this.voucherModel.Voucher.Voucher_Date
      this.voucherModel.Voucher_Detail = res.Item2;
      this.docModal.DocumentImages = res.Item3;
      this.DBVoucher_Master = res.Item1[0];
      this.DBVoucher_Detail = res.Item2;
      this.DBVoucherType = res.Item1[0].Voucher_Type;
      this.DBVoucherSub_Type = res.Item1[0].Voucher_Sub_Type
      this.DBVoucherNo = res.Item1[0].Voucher_No;
      res.Item1[0].LoginUserId = this.currentUser.userId;
      res.Item1.forEach(val => this.OldData.Voucher.push(Object.assign({}, val)));
      res.Item2.forEach(val => this.OldData.Voucher_Detail.push(Object.assign({}, val)));
      res.Item3.forEach(val => this.oldDocModel.DocumentImages.push(Object.assign({}, val)));
      //this.Bind_Voucher = res.Item2[res.Item2.length - 1].Bind_Voucher;
      this.voucherModel.Voucher.Voucher_Date = new Date(moment(this.voucherModel.Voucher.VoucherDate).format('MM/DD/YYYY'));
      if (this.voucherModel.Voucher.Voucher_E_Transaction_Date != null)
        this.voucherModel.Voucher.Voucher_E_Transaction_Date = new Date(moment(this.voucherModel.Voucher.Voucher_E_Transaction_Date).format('MM/DD/YYYY'));
      if (this.voucherModel.Voucher.Voucher_Cheque_Date != null)
        this.voucherModel.Voucher.Voucher_Cheque_Date = new Date(moment(this.voucherModel.Voucher.VoucherChequeDate).format('MM/DD/YYYY'));
      if (this.voucherModel.Voucher.Voucher_ReceiptDate != null)
        this.voucherModel.Voucher.Voucher_ReceiptDate = new Date(moment(this.voucherModel.Voucher.VoucherReceiptDate).format('MM/DD/YYYY'));
      if (this.voucherModel.Voucher.Voucher_Effective_Date != null)
        this.voucherModel.Voucher.Voucher_Effective_Date = new Date(moment(this.voucherModel.Voucher.VoucherEffectiveDate).format('MM/DD/YYYY'));
      if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == null) {
        this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
      }
      if (this.voucherModel.Voucher.Voucher_Cheque_Clear_Date != null)
        this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = new Date(moment(this.voucherModel.Voucher.VoucherChequeClearDate).format('MM/DD/YYYY'));

      this.taxSlabs = this.voucherModel.Voucher_Detail.filter(item => item.IsTax == "True");

      if (this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'DR')?.AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");
      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Payment') {
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'CR')?.AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");
      }

      this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.IsTax != "True");
      this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
        if (obj.TaxSlab == undefined) {
          obj.TaxSlab = [];
          obj.TaxSlab1 = [];

          obj.GSTModel = {
            PartyType: obj.GST_Type,
            Acc_GSTNo: obj.GST_No,
            Customer: obj.Party_AccountName,
            Account_Name: obj.Party_AccountName,
            HSNCode: obj.HSN_Code,
            Slab_Tax: obj.Slab_Name
          };
          if (Number(obj.Bind_Voucher == null ? 0 : obj.Bind_Voucher) > this.Bind_Voucher) {
            this.Bind_Voucher = Number(obj.Bind_Voucher);
          }
        }
        if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' || this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {

          this._MasterService.Get_ChargesHeadsByCaseNo_ForDropdown({ caseNo: obj.CaseNo, Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type }).subscribe((res: any) => {
            //for (var i = 0; i < res.length; i++) {
            //  if (res[i].Id == 34) {
            //    res.splice(i, 1);
            //  }
            //}
            this.chargesHeadDropdown[index] = res;
            obj.ChargeHeadId = obj.ChargeHeadId;
          });
        }
        if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
          obj.ChargeHeadId = obj.AccountId;
        }
      })
      this.gettaxSlab(this.taxSlabs);

      this.Get_Acc_Accounts_By_Tag();
      this.Get_Acc_Accounts_ForJV();
      const financialYear = this.getFinancialYear(new Date(this.voucherModel.Voucher.Voucher_Date));
      console.log('Financial Year Start Date:', financialYear.start);
      console.log('Financial Year End Date:', financialYear.end);
      this.Min_Date = new Date(moment(financialYear.start).format("MM/DD/YYYY"));
      this.maxDate = new Date(moment(financialYear.end).format("MM/DD/YYYY"));
      $(`.VoucherModal${this.index}`).modal("show");
      $(`.VoucherModal${this.index}`).css(
        "z-index",
        "1050"
      );
    }
    );

    //this.initializeVoucher();
    // this.initializeVoucherDetail();



  }
  getFinancialYear(date: Date): { start: Date, end: Date } {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based

    if (month >= 4) {
      // Financial year starts from April (4th month)
      return { start: new Date(year, 3, 1), end: new Date(year + 1, 2, 31) };
    } else {
      // Financial year starts from April (4th month) of the previous year
      return { start: new Date(year - 1, 3, 1), end: new Date(year, 2, 31) };
    }
  }
  gettaxSlab(taxSlabs) {
    this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
      //if (Number(obj.ChargeHeadId) > 0 ) {
      var _ffing = this.taxSlabs.find(result => result.Bind_Voucher === obj.Bind_Voucher)
      if (Number(obj.ChargeHeadId) > 0 && _ffing) {

        this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: obj.ChargeHeadId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, ProductId: obj.PrductId || 0, TaxType: obj.TranType }).subscribe((res: any) => {

          if (res.Item1[0]) {
            if (res.Item1[0].AccountId) {
              obj.AccountId = res.Item1[0].AccountId;
              obj.ChargeHeadCategory = res.Item1[0].AccountName;
              obj.TaxSlab = res.Item2;

              // obj.TaxSlab1 = [];
              if (taxSlabs.length > 0) {

                //if (res.Item2.length > 0) {
                obj.TaxSlab1 = this.taxSlabs.filter(item => item.Bind_Voucher === obj.Bind_Voucher);
                obj.TaxSlab1.forEach((tx1: any) => {
                  tx1.TaxAmount = tx1.Amount;
                  tx1.TaxSlab_Type = tx1.ChargeHeadCategory;
                  tx1.BranchName = tx1.Branch_Name;
                  tx1.TaxSlab_Rate = tx1.Tax_Per;
                  //tx1.TypeIGST = tx1.ChargeHeadCategory.includes('SGST')
                  tx1.TypeGST = tx1.ChargeHeadCategory.includes('SGST');
                  tx1.TypeIGST = tx1.ChargeHeadCategory.includes('IGST');
                  this.getTotalCalculation();
                });
                // }
              }
              this.getTotalCalculation();

            } else {
              obj.TaxSlab = [];
              obj.TaxSlab1 = [];
              obj.GSTModel = {};
              this.getTotalCalculation();
            }
          }
          else {
            obj.TaxSlab = [];
            obj.TaxSlab1 = [];
            obj.GSTModel = {};
            this.getTotalCalculation();
          }

        });
      }
      else {
        obj.TaxSlab = [];
        this.getTotalCalculation();
      }

    });
  }
  taxSlab(taxSlabs) {
    this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
      if (Number(obj.ChargeHeadId) > 0) {
        this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: obj.ChargeHeadId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, ProductId: obj.PrductId || 0, TaxType: obj.TranType }).subscribe((res: any) => {

          if (res.Item1[0]) {
            if (res.Item1[0].AccountId) {
              obj.AccountId = res.Item1[0].AccountId;
              obj.ChargeHeadCategory = res.Item1[0].AccountName;
              obj.TaxSlab = res.Item2;

              // obj.TaxSlab1 = [];
              if (taxSlabs.length > 0) {

                //if (res.Item2.length > 0) {
                obj.TaxSlab1 = this.taxSlabs.filter(item => item.Bind_Voucher === obj.Bind_Voucher);
                obj.TaxSlab1.forEach((tx1: any) => {
                  tx1.TaxAmount = tx1.Amount;
                  tx1.TaxSlab_Type = tx1.ChargeHeadCategory;
                  tx1.BranchName = tx1.Branch_Name;
                  tx1.TaxSlab_Rate = tx1.Tax_Per;
                  //tx1.TypeIGST = tx1.ChargeHeadCategory.includes('SGST')
                  tx1.TypeGST = tx1.ChargeHeadCategory.includes('SGST');
                  tx1.TypeIGST = tx1.ChargeHeadCategory.includes('IGST');
                  this.getTotalCalculation();
                });
                // }
              }
              this.getTotalCalculation();

            } else {
              obj.TaxSlab = [];
              obj.TaxSlab1 = [];
              obj.GSTModel = {};
              this.getTotalCalculation();
            }
          }
          else {
            obj.TaxSlab = [];
            obj.TaxSlab1 = [];
            obj.GSTModel = {};
            this.getTotalCalculation();
          }

        });
      }
      else {
        obj.TaxSlab = [];
        this.getTotalCalculation();
      }

    });
  }
  onChangeVoucherType(SubTypeChange) {
    if (SubTypeChange == false)
      this.voucherModel.Voucher.Voucher_Sub_Type = this.DBVoucherSub_Type;
    this.voucherModel.Voucher_Detail = [];
    console.log("before", this.DBVoucher_Detail);
    this.DBVoucher_Detail.forEach(val => this.voucherModel.Voucher_Detail.push(Object.assign({}, val)));
    if (this.DBVoucherType == 'Receipt') {
      if (this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
        this.voucherModel.Voucher.Voucher_No = this.DBVoucherNo;
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'DR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");
        console.log("after receipt", this.DBVoucher_Detail);

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Payment') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          if (obj.TranType == 'CR')
            obj.TranType = 'DR';
          else if (obj.TranType == 'DR')
            obj.TranType = 'CR';
        });
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'CR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");
        console.log("after payment", this.DBVoucher_Detail);
      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Journal') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: 'Journal', VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Contra') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        this.voucherModel.Voucher.Voucher_Sub_Type = 'Accounting';
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: 'Contra', VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          obj.ChargeHeadId = obj.AccountId;
          obj.BranchId = '';
        });
      }
      this.taxSlab(this.taxSlabs);
    }
    if (this.DBVoucherType == 'Payment') {
      if (this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          if (obj.TranType == 'DR')
            obj.TranType = 'CR';
          else if (obj.TranType == 'CR')
            obj.TranType = 'DR';

        });
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType == 'DR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Payment') {
        this.voucherModel.Voucher.Voucher_No = this.DBVoucherNo;
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'CR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Journal') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
        this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
          this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
        })
      }
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Contra') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          obj.ChargeHeadId = obj.AccountId;
          obj.BranchId = '';
        });
      }
      this.taxSlab(this.taxSlabs);
    }

    if (this.DBVoucherType == 'Journal') {
      if (this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          if (obj.TranType == 'DR')
            obj.TranType = 'CR';
        });
        //this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'DR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Payment') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          if (obj.TranType == 'CR')
            obj.TranType = 'DR';
        });
        //this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'CR').AccountId;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'DR' && item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Journal') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        this.voucherModel.Voucher.Voucher_No = this.DBVoucherNo;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.IsTax != "True");

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Contra') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        this.voucherModel.Voucher.Voucher_No = this.DBVoucherNo;
        //this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.IsTax != "True");
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();

      }
      this.taxSlab(this.taxSlabs);
    }

    if (this.DBVoucherType == 'Contra') {
      if (this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        //this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        //  if (obj.TranType == 'DR')
        //    obj.TranType = 'CR';
        //});
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'DR').AccountId;
        //this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.TranType == 'CR' && item.IsTax != "True");
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();
      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Payment') {
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher.DRAccountId = this.voucherModel.Voucher_Detail.find(result => result.TranType === 'CR').AccountId;
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();
      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Journal') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        if (this.voucherModel.Voucher.Voucher_Date < new Date('2024-04-01 00:00:00')) {
          this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
            this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
          })
        }
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();

      }
      if (this.voucherModel.Voucher.Voucher_Type == 'Contra') {
        this.voucherModel.Voucher.Voucher_Tag = null;
        this.voucherModel.Voucher.Voucher_No = this.DBVoucherNo;
        this.voucherModel.Voucher_Detail = this.voucherModel.Voucher_Detail.filter(item => item.IsTax != "True");

      }
      this.taxSlab(this.taxSlabs);
    }
  }
  initializeVoucher() {
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Receipt',
      Voucher_Sub_Type: 'Loan',
      Voucher_Tag: 'Bank',
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId
    };
    setTimeout(() => {
      //this.GetVoucherNo();
      this.Get_Acc_Accounts_By_Tag();
    }, 500)
    if (this.maxDate > new Date() && this.Min_Date < new Date())
      this.voucherModel.Voucher.Voucher_Date = new Date();
    else this.voucherModel.Voucher.Voucher_Date = this.maxDate;
  }
  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      Is_LOSAppliaction: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: "",
      TranType: this.voucherModel.Voucher.Voucher_Type == "Receipt" ? "CR" : this.voucherModel.Voucher.Voucher_Type == "Payment" ? "DR" : (this.voucherModel.Voucher.Voucher_Type == "Journal" || this.voucherModel.Voucher.Voucher_Type == "Contra") ? this.totalCalculator && this.totalCalculator.totalCR > this.totalCalculator.totalDR ? 'DR' : 'CR' : "",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: [],
      GSTModel: {},
      TaxSlab1: [],
      Bind_Voucher: this.Bind_Voucher,
      Type: ""
    });
  }
  getChargeshead() {


    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
      this._Partnerservice.PartnerGet_ChargesHeads_ForDropdown({ LoanAccountingId: this.selectedData.LoanAccountingId }).subscribe((res: any) => {
        //for (var i = 0; i < res.length; i++) {
        //  if (res[i].Id == 34) {
        //    res.splice(i, 1);
        //  }
        //}
        var result = [];
        res.forEach(val => result.push(Object.assign({}, val)));
        this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
          result = [];
          res.forEach(val => result.push(Object.assign({}, val)));
          if (obj.TranType == 'CR') {
            for (var i = 0; i < result.length; i++) {
              if (result[i].Id == 34) {
                result.splice(i, 1);
              }
            }
          }
          //this.chargesHeadDropdown[index] = result;
        });
        // console.log("chargesHeadDropdown result", result);
        this.chargesHeadDropdown[this.selectedSearchIndex] = result;
      });
    }
    else {
      this._MasterService.Get_ChargesHeadsByProduct_ForDropdow({ ProductId: this.selectedData.ProductId }).subscribe((res: any) => {
        var result = [];
        res.forEach(val => result.push(Object.assign({}, val)));
        this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
          result = [];
          res.forEach(val => result.push(Object.assign({}, val)));
          if (obj.TranType == 'DR') {
            for (var i = 0; i < result.length; i++) {
              if (result[i].Id == 34) {
                result.splice(i, 1);
              }
            }
          }
          //this.chargesHeadDropdown[index] = result;
        });
        this.chargesHeadDropdown[this.selectedSearchIndex] = result;
      });
    }

  }
  Get_EmployeeBranchForDropdown() {
    this._MasterService.Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId }).subscribe((res: any) => {
      this.empBranchDropdown = res;
    });
  }
  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService.Get_Acc_Accounts_OtherThan_BankCash({}).subscribe((res: any) => {
      this.accountHeadDropdown = res;
    });
  }
  Get_Acc_Accounts_contra() {
    this.lmsService.Get_Acc_Accounts_ForJVandContra({ IsJournal: 0 }).subscribe((res: any) => {
      this.accountHeadContraDropdown = res;
    });
  }
  Get_Acc_Accounts_ForJV() {
    this.lmsService.Get_Acc_Accounts_ForJVandContra({ IsJournal: 1, isAccounting: this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting' ? 1 : 0 }).subscribe((res: any) => {
      this.accountHeadJVDropdown = res;
    });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    }
    else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  GetVoucherNo() {
    if (new Date(moment(this.OldVoucherDate).format('MM/DD/YYYY')) < new Date('2024-04-01 00:00:00')) {
      this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
        this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
      })
    }
  }

  Get_Acc_Accounts_By_Tag() {
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }
  Get_Acc_Search_LoanNo() {
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
      this.displayedColumns = ['CustomerId', 'Type', 'Partner_LoanAcNo', 'Customer_LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

      this._MasterService.Get_SearchCustomerByPartner({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));

        this.dataSource = new MatTableDataSource(this.CustomerList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
    }
    else {
      this.displayedColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

      this._MasterService.Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));
        if (this.Type != "0") {
          var type;
          if (this.Type == "1")
            type = "LOS";
          else
            type = "LMS";
          this.CustomerList = this.CustomerList.filter(item => item.Type == type);
        }
        this.dataSource = new MatTableDataSource(this.CustomerList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
    }
    //this.displayedColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

    //this._MasterService
    //  .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
    //  .subscribe((res: any) => {
    //    console.log(res);
    //    this.CustomerList = JSON.parse(JSON.stringify(res));
    //    if (this.Type != "0") {
    //      var type;
    //      if (this.Type == "1")
    //        type = "LOS";
    //      else
    //        type = "LMS";
    //      this.CustomerList = this.CustomerList.filter(item => item.Type == type);
    //    }
    //    this.dataSource = new MatTableDataSource(this.CustomerList);
    //    //this.dataSource.sort = this.sort;
    //    this.dataSource.paginator = this.paginator;
    //    this.showSpinner = false;
    //  });
    //this.lmsService.Get_Acc_Search_LoanNo({ SearchOn: this.selectedData.Is_LOSAppliaction == 1 ? 'ApplicationNo' : 'LoanNo', SearchValue: this.selectedData.SearchValue }).subscribe((res: any) => {
    //  if (res.length > 0) {
    //    if (res.length == 1) {
    //      this.selectSearchData(res[0])
    //    }
    //    this.dataSource = new MatTableDataSource(res);

    //  }
    //  else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: "Search not found.", ...this.configSuccess });
    //  }
    //});
  }
  selectSearchData(data: any, index: any) {
    if (data.LoanAcNo != '' && data.LoanAcNo != null)
      this.selectedData.CaseNo = data.LoanAcNo;
    else
      this.selectedData.CaseNo = data.Application_No;

    this.selectedData.Customer = data.CustomertName;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.BranchId = data.BranchId;
    this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.Is_LOSAppliaction = data.Type == 'LOS' ? '1' : '0';
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.selectedData.GST_No = data.GST_No;
    this.selectedData.StateId = data.StateId;
    this.selectedData.Branch_StateId = data.Branch_StateId;
    this.selectedData.LoanAccountingId = data.LoanAccountingId;
    this.getChargeshead();
    this.OnCloseSearch();
  }
  onVoucherSubTypeChange() {
    this.onChangeVoucherType(true);
    this.Get_Acc_Accounts_ForJV();
    if (this.DBVoucherSub_Type != this.voucherModel.Voucher.Voucher_Sub_Type) {
      this.voucherModel.Voucher_Detail = [];
      this.initializeVoucherDetail();
      this.totalCalculator = undefined;
    }

    //this.GetVoucherNo();

  }
  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherDateChange() {

    if (
      this.voucherModel.Voucher.Voucher_Date < this.Min_Date ||
      this.voucherModel.Voucher.Voucher_Date > this.maxDate
    ) {
      this.voucherModel.Voucher.Voucher_Date = new Date(moment(this.OldVoucherDate).format('MM/DD/YYYY'));;
      console.log("fddgdfg", this.OldVoucherDate)
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Voucher Date between " + this.datepipe.transform(this.Min_Date, 'yyyy-MM-dd') + ' and ' + this.datepipe.transform(this.maxDate, 'yyyy-MM-dd'),
        ...this.configSuccess,
      });

    } else {
      this.GetVoucherNo();
    }
   // this.Get_Acc_Accounts_By_Tag();
    //this.voucherModel.Voucher.DRAccountId = "";
  }
  searchCustomer(data: any, index: any) {
    this.selectedSearchIndex = index;
    this.selectedData = data;
    this.dataSource = new MatTableDataSource([]);
    $(`.CustomerReceipt${this.index}`).modal("show");
    $(`.CustomerReceipt${this.index}`).css(
      "z-index",
      "1500"
    );
    //$("#CustomerReceipt").modal("show");
    //$("#CustomerReceipt").css("z-index", "1050");
    //setTimeout(() => {
    //  $("#SearchValue").focus();
    //}, 100)
  }
  OnCloseSearch() {
    $(`.CustomerReceipt${this.index}`).modal("hide");
    // $("#CustomerReceipt").modal("hide");
    $("#Customer" + this.selectedSearchIndex).focus();
    this.selectedData.SearchValue = "";
  }
  //onChangeChargesHead(data: any, dindex: any) {
  //  let ChargeId;

  //  if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
  //    data.BranchId = "";
  //    let ChargeData = this.accountHeadDropdown.filter(x => x.Account_Name == data.ChargeHeadCategory);

  //    if (ChargeData.length > 0) {
  //      ChargeId = ChargeData[0].AccountId;
  //    }
  //    else {
  //      ChargeId = '';
  //      data.Amount = "";
  //      data.AccountId = "";
  //      data.ChargeHeadCategory = "";
  //      data.TaxSlab = [];
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
  //      return
  //    }
  //  }
  //  else {
  //    ChargeId = data.ChargeHeadId;
  //    let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
  //      return index != dindex && obj.CaseNo == data.CaseNo && obj.ChargeHeadId == data.ChargeHeadId
  //    });

  //    if (_find) {
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
  //      data.ChargeHeadId = "";
  //      data.Amount = "";
  //      data.AccountId = "";
  //      data.ChargeHeadCategory = "";
  //      data.TaxSlab = [];

  //      this.getTotalCalculation();
  //      return;
  //    }
  //  }
  //  data.Amount = "";
  //  data.TaxSlab = [];
  //  this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: ChargeId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, ProductId: data.ProductId || data.PrductId || 0, TaxType: data.TranType }).subscribe((res: any) => {
  //    if (res.Item1[0]) {
  //      if (res.Item1[0].AccountId) {
  //        data.AccountId = res.Item1[0].AccountId;
  //        data.ChargeHeadCategory = res.Item1[0].AccountName;
  //        data.TaxSlab = res.Item2;
  //      }
  //      else {
  //        data.ChargeHeadId = "";
  //        this.snackBar.openFromComponent(SnackbarComponent, { data: "category does not have account head.", ...this.configSuccess });
  //      }
  //    }
  //    else {
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
  //    }
  //  });
  //}

  onChangeChargesHead(data: any, dindex: any) {
    let ChargeId, ChargeData1;
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
      let ChargeData = this.accountHeadDropdown.filter(x => x.Account_Name == data.ChargeHeadCategory);
      //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';
      //ChargeData1 = ChargeData.length > 0 ? true : false;

      if (ChargeData.length > 0) {
        ChargeId = ChargeData[0].AccountId;
        ChargeData1 = true;
        this.lmsService.Get_Account_Balance({ AccountId: ChargeData[0].AccountId, ToDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {

          this.snackBar.openFromComponent(SnackbarComponent, { data: "Current Balance of " + ChargeData[0].Account_Name + ' is ' + res[0].Balance, ...this.configSuccess });

        })
      }
      else {
        ChargeId = '';
        data.ChargeHeadCategory = "";
        ChargeData1 = false;
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
        return
      }
    }
    else {
      ChargeData1 = true;
      ChargeId = data.ChargeHeadId;
    }

    if (ChargeData1) {
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        if (!data.ChargeHeadCategory) {
          data.AccountId = "";
          data.ChargeHeadCategory = "";
          data.TaxSlab = [];
          return;
        }
      }
      else {
        if (!data.ChargeHeadId) {
          data.AccountId = "";
          data.ChargeHeadCategory = "";
          data.TaxSlab = [];
          return;
        }
      }

      let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
        if (this.voucherModel.Voucher.Voucher_Sub_Type != 'Accounting') {
          return index != dindex && obj.CaseNo == data.CaseNo && obj.ChargeHeadId == Number(data.ChargeHeadId);
        }
        //if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        //  return index != dindex && obj.ChargeHeadCategory == data.ChargeHeadCategory;
        //}
        //else {
        //  return index != dindex && obj.CaseNo == data.CaseNo && obj.ChargeHeadId == Number(data.ChargeHeadId);
        //}
        //return index != dindex && Number(obj.ChargeHeadId) == Number(data.ChargeHeadId)
      });
      if (_find) {
        data.ChargeHeadId = "";
       // this.voucherModel.Voucher_Detail[dindex].ChargeHeadId = "";
        data.Amount = "";
        data.AccountId = "";
        data.ChargeHeadCategory = "";
        data.TaxSlab = [];
        if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
          data.BranchId = "";
        }
        this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });

        //this.getTotalCalculation();
        return;
      }
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      data.TaxSlab1 = [];
      data.GSTModel = {};
      //console.log("ChargeId : ",ChargeId);
      this.lmsService.Get_AccountHead_For_VoucherEntry({
        HeadId: ChargeId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        ProductId: this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner' ? data.LoanAccountingId : (data.PrductId == undefined ? data.ProductId : data.PrductId), TaxType: data.TranType
      }).subscribe((res: any) => {
        //console.log("res : ",res);
        if (res.Item1[0]) {
          // let _findA = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == res.Item1[0].AccountId });
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;

            if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
              data.ChargeHeadId = res.Item1[0].AccountId;
            }

            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
            this.IsGSTEnable = res.Item1[0].IsGSTEnable;
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Account head not found.", ...this.configSuccess });
          }
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
        }
      });
    }
  }
  onChangeChargesHeadContra(data: any, dindex: any) {
    let ChargeId, ChargeData1;
    let ChargeData = this.accountHeadContraDropdown.filter(x => x.Account_Name == data.ChargeHeadCategory);
    //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';

    //console.log("ChargeData", ChargeData);

    if (ChargeData.length > 0) {
      ChargeId = ChargeData[0].AccountId;
      this.lmsService.Get_Account_Balance({ AccountId: ChargeData[0].AccountId, ToDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {

        this.snackBar.openFromComponent(SnackbarComponent, { data: "Current Balance of " + ChargeData[0].Account_Name + ' is ' + res[0].Balance, ...this.configSuccess });

      })
    }
    else {
      ChargeId = '';
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
      return
    }

    /*if (!ChargeId) {
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      //this.getTotalCalculation();
      return;
    }*/
    let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
      //return index != dindex && Number(obj.ChargeHeadCategory) == Number(ChargeId)
      return index != dindex && obj.ChargeHeadCategory == data.ChargeHeadCategory;
    });
    //console.log("_find", _find);
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
      data.ChargeHeadId = "";
      this.voucherModel.Voucher_Detail[dindex].ChargeHeadId = "";
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        data.BranchId = "";
      }
      //this.getTotalCalculation();
      return;
    }
    data.Amount = "";
    data.AccountId = "";
    //data.ChargeHeadCategory = "";
    data.TaxSlab = [];
    //this.getTotalCalculation();
    this.checkIsBankTag();
    this.lmsService.Get_AccountHead_For_VoucherEntry({
      HeadId: ChargeId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
      ProductId: data.ProductId || 0, TaxType: data.TranType
    }).subscribe((res: any) => {
      if (res.Item1[0]) {
        let _findA = this.accountHeadContraDropdown.find((obj: any) => { return obj.Account_Name == res.Item1[0].AccountName });
        if (_findA) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Account head not found.", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
      }
    });
  }
  checkIsBankTag() {
    this.isBankTag = false;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {

      let _findAc = this.accountHeadContraDropdown.find((aobj: any) => { return aobj.Account_Name == obj.ChargeHeadCategory });
      if (_findAc && _findAc.GroupTag && _findAc.GroupTag.toLowerCase() == 'bank' && obj.TranType == 'CR') {
        this.isBankTag = true;
        return;
      }
    });
    if (!this.isBankTag) {
      this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  onChangeAmount(event: any, data: any, dindex: any) {
    if (event) {
      event.preventDefault();
    }
    this.Get_AccountForGST_Dropdown();
    //data.TaxSlab.forEach((obj: any) => {
    //  obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    //});
    //this.getTotalCalculation();

    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' && data.ChargeHeadId == 34 && this.voucherModel.Voucher.Voucher_Type == 'Receipt') {
      this.lmsService.lms_Check_InstallmentAmount({ CaseNo: data.CaseNo, VoucherId: this.voucherId }).subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].Amortization == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Generate Loan No. !!!", ...this.configSuccess
            });
            data.Amount = '';
            this.getTotalCalculation();
            return;
          }
          if (parseFloat(res[0].BalanceEMIAmount) < parseFloat(data.Amount)) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please" + (parseFloat(res[0].BalanceEMIAmount) - parseFloat(data.Amount)) + " Amount allocated on other Head", ...this.configSuccess
            });
            data.Amount = '';
            this.getTotalCalculation();
            return;
          }
        }
      });
    }
    //if (this.IsGSTEnable && data.TaxSlab != '') {
    if (((data.TaxSlab != undefined && data.TaxSlab.length > 0) || (data.TaxSlab1 != undefined && data.TaxSlab1.length > 0))) {
      //this.Tax_Slab_Data = null;
      this.currentindex = dindex;
      console.log("this.currentindex", this.currentindex);

      //this.Tax_Slab_Data = null;
      $(`.GSTModalWindow${this.index}`).modal("show");
      $(`.GSTModalWindow${this.index}`).css("z-index", "1050");

      let SelectBranch, StateId;
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        SelectBranch = this.empBranchDropdown.filter(x => x.BranchId == data.BranchId);
        this.voucherModel.Voucher_Detail.BranchId = SelectBranch[0].BranchId;
        StateId = SelectBranch[0].StateId;
        this.AccountingSelect = { StateId: StateId, Branch_Name: SelectBranch[0].Branch_Name };
        //let BranchName = this.empBranchDropdown.filter(item => item.BranchId == data.BranchId)[0].Branch_Name;
      }
      //console.log("this.voucherModel.Voucher.Voucher_Sub_Type", this.voucherModel.Voucher.Voucher_Sub_Type);
      //console.log("this.selectedData", this.selectedData);
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' || this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
        this.GSTModel = {
          Slab_Tax: '',
          AccountHeadId: '',
          PartyType: 'Existing_Party',
          Customer: this.selectedData.Customer,
          Acc_GSTNo: this.selectedData.GST_No,
          StateId: this.selectedData.StateId,
          Charge_head: data.ChargeHeadCategory,
          Amount: data.Amount,
          TranType: data.TranType,
        }
      }
      else {
        this.GSTModel = {
          Slab_Tax: '',
          AccountHeadId: '',
          PartyType: 'Existing_Party',
          Customer: data.Customer,
          StateId: StateId,
          Charge_head: data.ChargeHeadCategory,
          Amount: data.Amount,
          TranType: data.TranType,
        }
      }
    }

    /*data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    });*/
    this.getTotalCalculation();
  }
  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    console.log("Bind_VoucherBind_Voucher", this.Bind_Voucher);
    this.initializeVoucherDetail();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    this.getTotalCalculation();
  }


  onChangeAccountHead(data: any, index: any) {
    console.log("data", data);

    data.ChargeHeadCategory = data.Account_Name;
    if (data.CaseNo == '' || data.CaseNo == null || data.CaseNo == undefined) {
      data.AccountId = this.accountHeadDropdown.filter(x => x.Account_Name == data.Account_Name).length > 0 ? this.accountHeadDropdown.filter(x => x.Account_Name == data.Account_Name)[0].AccountId : "";
    }
    if (data.AccountId == "0" || data.AccountId == "" || data.AccountId == null || data.AccountId == undefined || data.AccountId == 0) {
      data.Amount = "";
      data.ChargeHeadCategory = "";
      data.Account_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Select Account Head!!!', ...this.configSuccess });
      return;
    }
    if (!data.AccountId) {
      data.Amount = "";

      delete data.IsLoanChargeable;
      //this.getTotalCalculation();
      return;
    }

    let _find = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == Number(data.AccountId) })
    data.IsLoanChargeable = _find.IsLoanChargeable;

    data.TaxSlab = [];
    //data.TaxSlab1 = [];

    if (_find.IsLoanChargeable) {
      data.AccountId = "";
      data.Account_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please search Application and choose category for this account head.", ...this.configSuccess });
    }
    data.ChargeHeadId = "";
    data.Amount = "";
    data.CaseNo = "";
    data.Customer = "";
    data.CustomerId = "";
    data.ProductId = "";
    data.BranchId = "";
    data.Branch_Name = "";
  }

  saveVoucher() {
    this.dialog.openConfirmDialog(`Are you sure you want to Update this Voucher.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          let _vouvher: any = { ...this.voucherModel.Voucher };
          let _voucheretail: any[] = [];
          let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
          // let _total: any = 0;
          //let _totalCR: any = 0;
          //let _totalTX: any = 0;
          //this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          //  _total = _total + obj.Amount;
          //  _totalCR = _totalCR + obj.Amount;
          //  _voucheretail.push(this.dataSharingService.cleanObject({
          //    VoucherId: obj.VoucherId,
          //    CaseNo: obj.CaseNo,
          //    CustomerId: obj.CustomerId,
          //    Is_LOSAppliaction: obj.Is_LOSAppliaction,
          //    ChargeHeadId: obj.ChargeHeadId,
          //    ChargeHeadCategory: obj.ChargeHeadCategory,
          //    AccountId: obj.AccountId,
          //    BranchId: obj.BranchId,
          //    TranType: obj.TranType,
          //    IsTax: obj.IsTax,
          //    Tax_Per: obj.Tax_Per,
          //    Amount: obj.Amount
          //  }));
          //  obj.TaxSlab.forEach((tobj: any) => {
          //    _total = _total + tobj.TaxAmount;
          //    _totalTX = _totalTX + tobj.TaxAmount;
          //    _voucheretail.push(this.dataSharingService.cleanObject({
          //      VoucherId: obj.VoucherId,
          //      CaseNo: obj.CaseNo,
          //      CustomerId: "",
          //      Is_LOSAppliaction: obj.Is_LOSAppliaction,
          //      ChargeHeadId: obj.ChargeHeadId,
          //      ChargeHeadCategory: tobj.TaxType,
          //      AccountId: tobj.TaxAccountId,
          //      BranchId: "",
          //      TranType: obj.TranType,
          //      IsTax: 1,
          //      Tax_Per: tobj.TaxRate,
          //      Amount: tobj.TaxAmount
          //    }))
          //  });
          //});

          //_voucheretail.push(this.dataSharingService.cleanObject({
          //  VoucherId: 0,
          //  CaseNo: "",
          //  CustomerId: "",
          //  Is_LOSAppliaction: "",
          //  ChargeHeadId: "",
          //  ChargeHeadCategory: _find.Account_Name,
          //  AccountId: _find.AccountId,
          //  BranchId: "",
          //  TranType: 'DR',
          //  IsTax: 0,
          //  Tax_Per: 0,
          //  Amount: _total
          //}));
          //_vouvher.Voucher_Total_Credit_Amount = _totalCR;
          //_vouvher.Voucher_Total_Debit_Amount = _total;
          //_vouvher.Voucher_Total_Tax = _totalTX;

          //let _finalData = {
          //  Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
          //  Voucher_Detail: _voucheretail
          //}

          //this.lmsService.Save_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
          //  if (res[0].CODE >= 0) {
          //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          //    this.initializeVoucher();
          //    this.voucherModel.Voucher_Detail = [];
          //    this.initializeVoucherDetail();
          //    this.totalCalculator = undefined;
          //    if (this.isShowPopupApplicationDetail) {
          //      this.isShowPopupApplicationDetail = false;
          //      this.dataSharingService.LmsResponseShowApplicationDetail.next('true');
          //    }
          //  } else {
          //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          //  }
          //});

          let _totalCR: any = 0;
          let _totalDR: any = 0;
          let _totalTXCR: any = 0;
          let _totalTXDR: any = 0;
          let TaxType = '';
          this.voucherModel.Voucher_Detail.forEach((obj: any) => {
            if (obj.TranType == "CR") {
              _totalCR = _totalCR + parseFloat(obj.Amount);
              TaxType = obj.TaxSlab.length > 0 ? "Output" : '';
            }
            else {
              _totalDR = _totalDR + parseFloat(obj.Amount);
              TaxType = obj.TaxSlab.length > 0 ? "Input" : '';
            }
            //if (obj.TranType == "CR") {
            //  _totalCR = _totalCR + parseFloat(obj.Amount);
            //}
            //else {
            //  _totalDR = _totalDR + parseFloat(obj.Amount);
            //}
            var BranchId, ChargeId, Party_AcName;
            if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
              BranchId = this.empBranchDropdown.filter(x => x.BranchId == obj.BranchId)[0].BranchId;
              ChargeId = '';
              // if (this.voucherModel.Voucher.Voucher_Type != 'Contra')
              Party_AcName = obj.GSTModel ? obj.GSTModel.PartyType == 'New_Party' ? obj.GSTModel.Customer : obj.GSTModel.Account_Name : '';
            }
            else if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' || this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
              BranchId = obj.BranchId;
              ChargeId = obj.ChargeHeadId;
              Party_AcName = obj.GSTModel.Customer;
            }
            _voucheretail.push(this.dataSharingService.cleanObject({
              VoucherId: obj.VoucherId,
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              Is_LOSAppliaction: obj.Is_LOSAppliaction,
              ChargeHeadId: this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting' ? '' : obj.ChargeHeadId,
              ChargeHeadCategory: obj.ChargeHeadCategory,
              AccountId: obj.AccountId,
              BranchId: obj.BranchId,
              TranType: obj.TranType,
              IsTax: obj.IsTax,
              Tax_Per: obj.Tax_Per,
              Amount: obj.Amount,
              GST_Type: obj.TaxSlab.length > 0 ? obj.GSTModel.PartyType : '',
              GST_No: obj.TaxSlab.length > 0 ? obj.GSTModel.Acc_GSTNo : '',
              Party_AccountName: obj.TaxSlab.length > 0 ? Party_AcName : '',
              HSN_Code: obj.TaxSlab.length > 0 ? obj.GSTModel.HSNCode : '',
              Slab_Name: obj.TaxSlab.length > 0 ? obj.GSTModel.Slab_Tax : '',
              Bind_Voucher: obj.Bind_Voucher,
              Type: TaxType
            }));

            //  obj.TaxSlab.forEach((tobj: any) => {
            //    if (obj.TranType == "CR") {
            //      _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
            //      _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
            //    }
            //    else {
            //      _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
            //      _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
            //    }
            //    _voucheretail.push(this.dataSharingService.cleanObject({
            //      VoucherId: obj.VoucherId,
            //      CaseNo: obj.CaseNo,
            //      CustomerId: "",
            //      Is_LOSAppliaction: obj.Is_LOSAppliaction,
            //      ChargeHeadId: this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting' ? '' : obj.ChargeHeadId,
            //      ChargeHeadCategory: tobj.TaxType,
            //      AccountId: tobj.TaxAccountId,
            //      BranchId: "",
            //      TranType: obj.TranType,
            //      IsTax: 1,
            //      Tax_Per: tobj.TaxRate,
            //      Amount: tobj.TaxAmount
            //    }))
            //  });
            //});
            if (obj.TaxSlab1) {
              obj.TaxSlab1.forEach((tobj: any) => {
                if (obj.TranType == "CR") {
                  _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
                  _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
                }
                else {
                  _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
                  _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
                }

                _voucheretail.push(this.dataSharingService.cleanObject({
                  VoucherId: obj.VoucherId,
                  CaseNo: obj.CaseNo,
                  CustomerId: obj.CustomerId,
                  Is_LOSAppliaction: obj.Is_LOSAppliaction,
                  ChargeHeadId: ChargeId,
                  ChargeHeadCategory: tobj.TaxSlab_Type,
                  AccountId: tobj.AccountId,
                  BranchId: BranchId,
                  TranType: obj.TranType,
                  IsTax: 1,
                  Tax_Per: tobj.TaxSlab_Rate,
                  Amount: tobj.TaxAmount,
                  Bind_Voucher: obj.Bind_Voucher,
                  Type: ''
                }))
              });
            }
            });
        
          if (this.voucherModel.Voucher.Voucher_Type == "Payment") {
            _voucheretail.push(this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: 'CR',
              IsTax: 0,
              Tax_Per: 0,
              Amount: _totalDR
            }));
          }
          if (this.voucherModel.Voucher.Voucher_Type == "Receipt") {
            _voucheretail.push(this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: 'DR',
              IsTax: 0,
              Tax_Per: 0,
              Amount: _totalCR
            }));
          }
          _vouvher.Voucher_Total_Credit_Amount = _totalCR;
          _vouvher.Voucher_Total_Debit_Amount = _totalDR;
          _vouvher.Voucher_Total_Tax = _totalTXCR + _totalTXDR;
          if ((_totalCR.toFixed(2) != _totalDR.toFixed(2)) && (this.voucherModel.Voucher.Voucher_Type == "Journal" || this.voucherModel.Voucher.Voucher_Type == "Disbursement" || this.voucherModel.Voucher.Voucher_Type == "Contra")) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Total Debit Amount must be equal to Total Credit Amount.", ...this.configSuccess });
            return;
          }
          let Attachment: any[] = [];
          if (this.docModal.DocumentImages.length) {
            for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
              Attachment.push({ Doc_path: this.docModal.DocumentImages[i].DocFileName });
            }
          }
          //_voucheretail.forEach((obj: any) => {
          //  if (obj.AccountId == "0" || obj.AccountId == "" || obj.AccountId == null || obj.AccountId == undefined || obj.AccountId == 0) {
          //    this.snackBar.openFromComponent(SnackbarComponent, { data:'Please Select Account Head!!!', ...this.configSuccess });
          //    return;
          //  }
          //})
          let _finalData = {
            Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
            Voucher_Detail: _voucheretail,
            Voucher_Attachment: Attachment
          }
          var FinalOldData = {
            Voucher: this.dataSharingService.cleanObject({ ...this.OldData.Voucher[0] }),
            Voucher_Detail: this.OldData.Voucher_Detail,
            Voucher_Attachment: this.oldDocModel.DocumentImages
          }

          console.log("_finalData", _finalData);
          this.lmsService.Save_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              console.log("_OldData", this.OldData);
              this.lmsService.Acc_Voucher_Audit_Trail_Update({ JSON: JSON.stringify(FinalOldData), Type: 'Add' }).subscribe((res: any) => {
              });
              this.onSaveDoc();
              this.initializeVoucher();
              this.voucherModel.Voucher_Detail = [];
              this.initializeVoucherDetail();
              this.totalCalculator = undefined;
              this.OnClose();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          });
        }
      });
  }
  OnClose() {
    $(`.VoucherModal${this.index}`).modal("hide");
    this.initializeVoucher();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    this.totalCalculator = undefined;
    this.close.emit(true);
  }
  onCancelVoucher() {
    if (this.isShowPopupApplicationDetail) {
      this.isShowPopupApplicationDetail = false;
      this.dataSharingService.LmsResponseShowApplicationDetail.next('false');
    }
  }
  onChangeTax() {

    this.getTotalCalculation();
  }
  getTotalCalculation() {
    if (this.voucherModel.Voucher.Voucher_Type == "Journal" || this.voucherModel.Voucher.Voucher_Type == "Disbursement" || this.voucherModel.Voucher.Voucher_Type == "Contra") {
      let _totalCR: any = 0;
      let _totalDR: any = 0;
      let _totalCRTX: any = 0;
      let _totalDRTX: any = 0;
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        if (obj.TranType == "CR") {
          _totalCR = _totalCR + parseFloat(obj.Amount == "" ? 0 : obj.Amount) || 0;
        }
        else {
          _totalDR = _totalDR + parseFloat(obj.Amount == "" ? 0 : obj.Amount) || 0;
        }
        if (obj.TaxSlab1 != undefined && obj.TaxSlab1 != null) {
          obj.TaxSlab1.forEach((tobj: any) => {
            if (obj.TranType == "CR") {
              _totalCR = _totalCR + parseFloat(tobj.TaxAmount) || 0;
              _totalCRTX = _totalCRTX + parseFloat(tobj.TaxAmount) || 0;
            }
            else {
              _totalDR = _totalDR + parseFloat(tobj.TaxAmount) || 0;
              _totalDRTX = _totalDRTX + parseFloat(tobj.TaxAmount) || 0;
            }
          });
        }
      });
      this.totalCalculator = {
        totalCR: parseFloat(_totalCR),
        totalDR: parseFloat(_totalDR),
        totalCRTX: parseFloat(_totalCRTX),
        totalDRTX: parseFloat(_totalDRTX),
        totalCRInWord: new ToWords().convert(Number(_totalCR)),
        totalDRInWord: new ToWords().convert(Number(_totalDR))
      }
    }
    else {
      let _total: any = 0;
      let _totalCR: any = 0;
      let _totalTX: any = 0;
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        _total = _total + parseFloat(obj.Amount == "" ? 0 : obj.Amount) || 0;
        _totalCR = _totalCR + parseFloat(obj.Amount == "" ? 0 : obj.Amount) || 0;
        obj.TaxSlab1.forEach((tobj: any) => {
          _total = _total + parseFloat(tobj.TaxAmount) || 0;
          _totalTX = _totalTX + parseFloat(tobj.TaxAmount) || 0;
        });
      });
      this.totalCalculator = {
        total: parseFloat(_total),
        totalCR: parseFloat(_totalCR),
        totaltax: parseFloat(_totalTX),
        totalInWord: new ToWords().convert(parseFloat(_total)),
        totalDR: Number(0),
        totalCRTX: Number(0),
        totalDRTX: Number(0),
        totalCRInWord: new ToWords().convert(Number(0)),
        totalDRInWord: new ToWords().convert(Number(0))
      }
    }
  }
  onChangeCRDR(data: any) {

  }
  fileChangeListenerD(files: any, input: any) {

    let $this = this;
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let _pre = this.today.getTime();
      let _docImg: any = {};
      _docImg.DocFileName = _pre + "_" + files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  onSaveDoc() {
    let _data = { ...this.docModal };

    if (this.docModal.DocumentImages.length) {


      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        this.lmsService
          .UploadAccountDoc({
            DocName: this.docModal.DocumentImages[i].DocFileName,
            DocData: this.docModal.DocumentImages[i].DocData,
          })
          .subscribe(
            (res: any) => {
              if ((i + 1) == this.docModal.DocumentImages.length) {
                //this.saveDoc();
              }
            },
            (err: any) => {
              _err = true;
              //this.snackBar.openFromComponent(SnackbarComponent, {
              //  data: "File not uploaded.",
              //  ...this.configSuccess,
              //});
            }
          );
        if (_err) {
          break;
        }

      }
      this.docModal = { DocumentImages: [] };
    }
  }
  //IsChequeDetailInContra() {

  //  this.voucherModel.Voucher_Detail.forEach((obj: any) => {

  //    if (obj.TranType == 'CR' && this.accountHeadContraDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; }).GroupTag =='Bank')
  //  })
  //}

  saveGSTDetails() {
    $(`.GSTModalWindow${this.index}`).modal("hide");
    //console.log("this.GSTModel", this.GSTModel);
    this.lmsService.Get_TaxSlab_Details({ TaxSlab: this.GSTModel.Slab_Tax }).subscribe((res: any) => {
      let Br_StateId, BranchName;
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        //var Br_Data = this.empBranchDropdown.filter(x => x.BranchId == this.BranchData.BranchId);
        // var Br_Data = this.empBranchDropdown.filter(x => x.Branch_Name == this.voucherModel.Voucher_Detail[0].Branch_Name);
        Br_StateId = this.AccountingSelect.StateId;
        BranchName = this.AccountingSelect.Branch_Name;
      }
      else {
        Br_StateId = this.selectedData.Branch_StateId;
        BranchName = this.selectedData.Branch_Name;
      }

      let Tax_Type = this.GSTModel.TranType == "DR" ? "Input" : "Output";
      if (Br_StateId == this.GSTModel.StateId) {
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'With in State');
      }
      else {
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'Inter state');
      }

      this.Tax_Slab_Data.forEach(x => {
        x.BranchName = BranchName;
        x.Amount = this.GSTModel.Amount;
        x.TaxAmount = (Number(this.GSTModel.Amount) * Number(x.TaxSlab_Rate) / 100).toFixed(2);

        // Search value (SGST) from a string 
        x.TypeGST = x.TaxSlab_Type.includes('SGST');
        x.TypeIGST = x.TaxSlab_Type.includes('IGST');
      });


      //this.voucherModel.Voucher_Detail.forEach(x => {
      //  if (x.TaxSlab.length > 0 && x.Bind_Voucher == this.Bind_Voucher) {
      //    x.TaxSlab1 = this.Tax_Slab_Data;
      //    x.GSTModel = this.GSTModel;
      //  }
      //});
      if (this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 != undefined) {
        this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
      }
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};

      this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = this.Tax_Slab_Data;
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = this.GSTModel;

      this.getTotalCalculation();
    });
  }
  OnCloseGSTModal() {
    $(`#GSTModalWindow${this.index}`).modal("hide");
    this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
    this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};
  }

  Get_AccountForGST_Dropdown() {
    this._MasterService.Get_AccountForGST_Dropdown({ LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.AccountHeadForGST = res;
    });
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;

      this.slabList = this.slabList.reduce((acc, current) => {
        const x = acc.find(item => item.TaxSlab_Name === current.TaxSlab_Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    })
  }

  onPartyTypeChange() {
    if (this.GSTModel.PartyType == 'New_Party') {
      this.GSTModel = {
        PartyType: this.GSTModel.PartyType,
        Slab_Tax: '',
        AccountHeadId: '',
        Customer: '',
        Acc_GSTNo: '',
        StateId: '',
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
      }
    }
    else {
      this.GSTModel = {
        Slab_Tax: '',
        AccountHeadId: '',
        PartyType: this.GSTModel.PartyType,
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
        //StateId : this.BranchData.StateId,
      }
    }
  }
  onAccountHeadChange() {
    let data = this.AccountHeadForGST.filter(item => item.AccountId == this.GSTModel.AccountHeadId);

    this.GSTModel = {
      AccountHeadId: this.GSTModel.AccountHeadId,
      Slab_Tax: this.GSTModel.Slab_Tax,
      Acc_GSTNo: data[0].Acc_GSTNo,
      StateId: data[0].StateId,
      HSNCode: data[0].HSNCode,
      Account_Name: data[0].Account_Name,
      PartyType: this.GSTModel.PartyType,
      Amount: this.GSTModel.Amount,
      TranType: this.GSTModel.TranType,
    }
    /*this.AccountHeadForGST.forEach((obj: any) => {
      obj.Amount = '';
    });*/
  }


  getFyear() {
    this.lmsService.Get_FinancialYear({ Id: 0 }).subscribe((res: any) => {
      let FyearDropdown = res;
      let FyearId = FyearDropdown[FyearDropdown.length - 1].Id;
      var Url = this._Route.url.split('?')[0];
      this._Route.navigate([Url], { queryParams: { fyear: FyearId, BranchId: 0 } });
    });
  }

}


