import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { LmsService } from '../../_Lms/services/lms.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LosService } from "../../_LOS/services/los.service";
import { QuickLoanService } from '../services/quickLoan.service';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
declare var $: any;


@Component({
  selector: 'app-quickloan-deviation',
  templateUrl: './quickloan-deviation.component.html',
  styleUrls: ['./quickloan-deviation.component.scss']
})
export class QuickloanDeviationComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  JsonData: any[] = [];
  dataSource: any;
  Type: any = 'Loan'
  Customer: any = 0;
  DeviationDetail: any;
  ManualRelated: any=[];
  displayedDeviationColumns: string[] = ["Id", "Type", "Value", "Minimum", "Maximum", "Col_Val", "CreatedBy", "ApprovedBy", "Status", "Action"];
  
  CustomerDropdown: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() Page_Id: any;
  @Input() isNew: boolean;
  @Input() ProcessName: any;

  Edit:boolean = true;
  UserAssignList:any = [];


  LoanCustomerRelated: any = [];
  dataSourceLoanCustomer: any;
  displayLoanCustomerColumns: string[] = ["Id", "Value", "Minimum", "Maximum", "Col_Val", "UserAssign", "Remark"];

  DocumentRelated: any=[];
  dataSourceDocument: any;
  displayDocumentColumns:string[]=["Id", "Value", "KYC_DocNumber", "UserAssign", "Remark"];
  

  LoanCustomerRelated1: any = [];
  dataSourceLoanCustomer1: any;
  displayLoanCustomerRelatedColumns:string[]=["Id", "Value", "Minimum", "Maximum", "Col_Val"];

  dataSourceManual: any;
  displayManualRelatedColumns: string[] = ["Id", "Type", "Value", "Process_Name", "CreatedOn","CreatedBy", "UserAssign", "Remark","Action"];
  DocumentRelated1: any = [];
  dataSourceDocument1: any;
  displayDocumentRelatedColumns:string[]=["Id", "Value", "KYC_DocNumber"];
  LoanId: any;
  DeviationData: any = [];
  DeviationRemark: any;
  DeviationModel: any = [];
  AllRemarks: any = [];

  constructor(
    private dataSharingService: DataSharingService, 
    private dialog: DialogService, 
    public snackBar: MatSnackBar, 
    private lmsService: LmsService,
    private MasterService: MasterService,
    private route: ActivatedRoute,
    private losService: LosService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("id")));
    
    
      if (this.loanSummary) {
        this.LoanId = this.loanSummary.LoanId;
      }
      if (this.LoanId)
        this.QuickLoan_Get_DeviationApply();
    });

  }

  QuickLoan_Get_DeviationApply() {
    this.showSpinner = true;
    this._QuickLoanService.QuickLoan_Get_DeviationApply({ Loan_Id: this.LoanId }).subscribe((res: any) => {
      console.log("RESULT : ",res);
      this.JsonData = JSON.parse(JSON.stringify(res));
      this.dataSource = new MatTableDataSource(this.JsonData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;

      this.OnClickDeviationCheck();
    });
  }

  OnClickDeviationCheck() {
    this._QuickLoanService.QuickLoan_Get_DeviationForApproval({ 
      Loan_Id: this.LoanId, productId: this.loanSummary.ProductId, ProcessId: this.Processid 
    }).subscribe((res: any) => {
      console.log("res", res);
      this.LoanCustomerRelated = res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == true);
      this.DocumentRelated = res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == true);

      this.LoanCustomerRelated.forEach((obj: any) => {
        obj.UserAssign = '';
      });

      this.DocumentRelated.forEach((obj: any) => {
        obj.UserAssign = '';
      });

      this.dataSourceLoanCustomer = new MatTableDataSource(res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == true));
      this.dataSourceDocument = new MatTableDataSource(res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == true));

      // If deviation Mismatch code start here
      this.LoanCustomerRelated1 = res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == false);
      this.DocumentRelated1 = res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == false);

      this.dataSourceLoanCustomer1 = new MatTableDataSource(res.Item1.filter(x => x.Deviation == "True" && x.IsDeviation == false));
      this.dataSourceDocument1 = new MatTableDataSource(res.Item2.filter(x => x.Deviation == "True" && x.IsDeviation == false));
      // If deviation Mismatch code end here

      this.Get_Employee_ByBranch_Department();
      this.Get_MannualDeviation();
    });
  }

  Get_MannualDeviation() {

    this._QuickLoanService.QuickLoan_GetManual_Deviation({
      LoanId: this.LoanId
    }).subscribe((res: any) => {
      if (res.length > 0) {
        //res.forEach((obj: any) => {
        //  obj.UserAssign = '';
        //});
        this.ManualRelated = res;
        this.dataSourceManual = new MatTableDataSource(res);
      }
     
    })

  }
  Get_Employee_ByBranch_Department() {
    this.MasterService.Get_Employee_By_DeviationApproval({
      LoginUserId: this.currentUser.userId, BranchId: this.loanSummary.BranchId, ProductId: this.loanSummary.ProductId}).subscribe((res: any) => {
      this.UserAssignList = res.filter(x => x.EmpId != this.currentUser.userId);
    });
  }

  onSaveDeviation(){
    var arr: any = [];
    this.LoanCustomerRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.LoanId,
          Type: x.Type,
          Value: x.Value,
          Minimum: x.Minimum,
          Maximum: x.Maximum,
          Col_Val: x.Col_Val,
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    this.DocumentRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        arr.push({
          LoanId: this.LoanId,
          Type: x.Type,
          Value: x.Value,
          Minimum: '',
          Maximum: '',
          Col_Val: '',
          CustomerId: x.CustomerId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    var Manualarr: any = [];
    this.ManualRelated.map((x) => {
      if (x.Remark != undefined && x.Remark != '') {
        Manualarr.push({
          Id: x.Id,
          LoanId: this.LoanId,
          Remark: x.Remark,
          CreatedBy: this.currentUser.userId,
          ApprovedBy: Number(x.UserAssign)
        });
      }
    });
    if (arr.length == 0 && Manualarr.length==0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please fill atleast single data", ...this.configSuccess });
      return
    }

    let _data = {
      Deviation: arr,
      ManualDeviation: Manualarr
    }
    console.log("_data", _data);
    this.losService.Deviation_Creation_sendToCredit({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.dataSourceManual = null;
        this.dataSourceDocument = null;
        this.dataSourceLoanCustomer = null;
        this.QuickLoan_Get_DeviationApply();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
    });
  
  }

  OnOpenDeviation(data: any) {
    this.GetDeviationAllRemarks(data.value);
    $("#DeviationRemarkModel").modal("show");
    $("#DeviationRemarkModel").css("z-index", "1050");
  }

  OnCloseDeviation() {
    $("#DeviationRemarkModel").modal("hide");
  }

  GetDeviationAllRemarks(Type: any){
    this.losService.Deviation_Get_AllRemarks({ LoanId: this.LoanId, Value: Type }).subscribe((res: any) => {
      this.AllRemarks = res;
      console.log("res", res)
    });
  }
  
  OnClickApprove(data: any){
    //console.log("this.data", data);
    this.DeviationData = data;

    this.DeviationModel.Status = '';
    this.DeviationModel.ForwardedBy = '';

    this.GetDeviationAllRemarks(data.value);
    this.Get_Employee_ByBranch_Department();
    $("#DeviationModel").modal("show");
    $("#DeviationModel").css("z-index", "1050");
  }

  OnCloseDeviationModel() {
    $("#DeviationModel").modal("hide");
  }

  OnUpdateDeviation(){
    let IsForwarded = this.DeviationModel.Status == 'Forward' ? true : false;
    let ForwardedBy = this.DeviationModel.ForwardedBy == ''? 0 : this.DeviationModel.ForwardedBy;

    //console.log("this.DeviationModel", this.DeviationModel);

    this.losService.Deviation_Update({
      DeviationRemark: this.DeviationModel.DeviationRemark, Id: this.DeviationData.Id, 
      IsForwarded: IsForwarded, Status: this.DeviationModel.Status, ForwardedBy: ForwardedBy
    }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.QuickLoan_Get_DeviationApply();
        this.OnCloseDeviationModel();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
    });
  }
  OnManualDeviation() {
    this.CustomerList();
    $("#ManualDeviationModel").modal("show");
    $("#ManualDeviationModel").css("z-index", "1050");
    
  }
  OnCloseManualDeviation() {
    $("#ManualDeviationModel").modal("hide");
    this.Type = 'Loan';
    this.DeviationDetail = '';

  }
  CustomerList() {
    this.losService.GetCustomerByApplication({ ApplicationId: this.LoanId }).subscribe((result) => {
      this.CustomerDropdown = JSON.parse(JSON.stringify(result));

    });
  }
  OnSaveManualDeviation() {
    var _data = {
      Data: {
        LoanId: this.LoanId,
        CustomerId: this.Type=='Loan'?'': this.Customer,
        Deviation_Value: this.DeviationDetail,
        CreatedBy: this.currentUser.userId,
        processId:this.Processid
      }
    }
    this._QuickLoanService.Deviation_Manual_Creation({ JSON: JSON.stringify(_data) }).subscribe((res) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.QuickLoan_Get_DeviationApply();
        this.OnCloseManualDeviation();
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }

    });
  }
  onDeleteManualDeviation(i) {

    this.dialog.openConfirmDialog(`Are you sure you want to remove this deviation ?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this._QuickLoanService.Delete_Application_Deviation({ Id: i}).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.dataSourceManual = null;
              this.dataSourceDocument = null;
              this.dataSourceLoanCustomer = null;
              this.QuickLoan_Get_DeviationApply();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          })
          
        }
      })
  }
}
