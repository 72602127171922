import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { constantUrl } from 'src/app/Shared/constantUrl';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  currentUser=new BehaviorSubject(null);
  hubUrl = constantUrl.hubUrl;
  hubConnection: HubConnection;
  onlineUser=new BehaviorSubject<number[]>([]);
  totalUncount=new BehaviorSubject<number>(0);

  constructor() {
    this.currentUser.subscribe(x=>{
      if(x){
        this.createConnection(x.userId,x.token)
      }
      else{
        if(this.hubConnection){
          this.hubConnection.stop()
        }
      }

    })
  }


  createConnection(userId,token) {
    if(this.hubConnection)
    {
      this.hubConnection.stop();
    }
    this.hubConnection = new HubConnectionBuilder().withUrl(`${this.hubUrl}/presence?currentUser=${userId}`,{accessTokenFactory:()=>token}).withAutomaticReconnect().build();
    this.hubConnection.start().catch(error=>console.log(error))
    
    //new User Connects
    this.hubConnection.on("UserIsOnline", user => {
      // console.log("User Connected",user);
    this.onlineUser.next([...this.onlineUser.value,user])
    })

    //Delete User from List
    this.hubConnection.on("UserIsOffline", user => {
      // console.log("user DisConnected" ,user);
    this.onlineUser.next([...this.onlineUser.value.filter(x=>x!=user)])
    })
    this.hubConnection.on('GetonlineUser',list=>{
      // console.log("online Users",list)
      this.onlineUser.next(list);
    })

    this.hubConnection.on('BadgeNotification',(EmpId,LoanId)=>{
      this.totalUncount.next(this.totalUncount.value+1);
      let audio: HTMLAudioElement = new Audio('assets/music/notification2.mp3');
      audio.play();
    })

    this.hubConnection.on('GetTotalUnreadCount',coun=>{
      // console.log("Total unread",coun)
      this.totalUncount.next(coun);
    
    })



  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(error => console.log("error", error));
    }
  }
}
