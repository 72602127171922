import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MasterService } from '../../Shared/app.Masters.Service';


//Excel_Import
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-loan-balance-multi',
  templateUrl: './loan-balance-multi.component.html',
  styleUrls: ['./loan-balance-multi.component.scss']
})


export class LoanBalanceMultiComponent implements OnInit {

  // Search:any;
  showSpinner: boolean = false;
  currentUser: any;
  selectedId:any;
  chartData: any;
  JsonData: any[] = [];


  //FormValue

  Fromdate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);;
  Todate: any = new Date();
  dataSource: any;
  displayedColumns: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
  ){} 
  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Balance Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

  }
  onSearchLoanBalance() {
    this.showSpinner = true;
    this.reportsService.Report_Loan_Charges_Balance({ Fromdate: this.Fromdate, Todate: this.Todate })
      .subscribe((res: any) => {
        if(res.length>0){
        this.displayedColumns = [
          'Loan_Id', 'LoanAcNo', 'Application_No', 'CustomerName', 'Branch_Name', 'Product_Name', 
          'Loan_Acc_Category', 'DPD', 'Opening_Stock', 'Addition_Stock', 'Deleation_Stock', 
          'Closing_Stock', 'Opening_Inst', 'Addition_Inst', 'Deleation_Inst', 'Closing_Inst', 
          'Opening_Interest', 'Addition_Interest', 'Deleation_Interest', 'Closing_Interest'
        ];
        // console.log("asd",res)
        this.JsonData=res;
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      }
      });
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, excelFileName + new  Date().getTime() + EXCEL_EXTENSION);
  }


  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("loan_balance_multi") as HTMLTableElement;
    const worksheetName = 'LOAN BALANCE MULTI'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

   
}