import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
// import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { LosService } from "../../_LOS/services/los.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from "moment";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { constantUrl } from "../../Shared/constantUrl";
import { DialogService } from "../../Shared/dialog.service";
import { QuickLoanService } from "../services/quickLoan.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-quickloan-FileChecking",
  templateUrl: "./quickloan-FileChecking.component.html",
  styleUrls: ["./quickloan-FileChecking.component.scss"],
})
export class QuickloanFileCheckingComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  configSuccess1: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  configSuccess2: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  IsSave: boolean = false;
  IsUpdate: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  @Input() Revert_IsEdit: any;


  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  today: Date = new Date();


  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  @Input() Status: any;
  @Input() ProcessName: any;

  //BtnAddView: any = 'Fill';

  // FOIRItems: { IsEligible: boolean; FOIR_Amount: number }[] = [];
  docBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  totalFOIR: number = 0;
  totalFOIRExpense: number = 0;

  resultItem1CurrentEmi: number = 0;
  isCheckboxChecked: boolean = false;
  resultItem1FoirId: any = 0;
  isEligibleVariable: any;
  totalMonthlyExpenseCalculated: { total: number };
  totalMonthlyIncomeCalculated: { total: number };
  CustomerList: any = 0;
  questionData: any[] = [];
  questionOptionData: any[] = [];
  constructor(
    private _Route: Router,
    private dialog: DialogService,
    private dataSharingService: DataSharingService,

    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.getFileCheckingData();
    console.log("PageData", this.PageData);

  }

  getFileCheckingData() {
    this._QuickLoanService.QuickLoan_GetFileChecking({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
      console.log("RES :", res);
      if (res.length > 0) {
        this.showSpinner = false;
        this.questionData = res;
        this.IsEdit = res[0].IsExists == 1 ? true : false;
        this.IsSave = res[0].IsExists == 1 ? false : true;
        this.IsDisabled = res[0].IsExists == 1 ? true : false;
        this.questionData.forEach((obj: any) => {
          obj.Remark = obj.Remark
          obj.FileCheckingName = obj.FileCheckingName

          obj.FileCheckingDropdown = obj.FileCheckingOptions.split(',');
          obj.SelectOption = obj.SelectOption

        })
      }
    })

  }
  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService.QuickLoan_Save_PageProcess({
      PageType: PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId,
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }

  onSaveCustomerFileChecking() {
    this._QuickLoanService.Quick_Save_FileChecking({ JSON: JSON.stringify(this.questionData), LoanId: this.loanSummary.LoanId, LoginUserId: this.currentUser.userId }).subscribe((response: any) => {
      //console.log(response);
      if (response[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getFileCheckingData();

        this.QuickLoan_Save_PageProcess('File Checking',);
        this.action.emit("next");
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
      this.loading = false;
    });
  }

  onEditFileChecking() {
   
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsSave = false;
    this.IsDisabled = false;
  }

}
