<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchParReportData()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            required
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> Amount Type </span>
        <select
          name="AmtType"
          id="AmtType"
          [(ngModel)]="AmtType"
          (change)="ChangeAmt()"
          class="form-control input-text-css"
          required
          #refAmtType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refAmtType.invalid,
            'alert-warning':
              refAmtType.invalid &&
              (refAmtType.dirty || refAmtType.touched || refAmtType.untouched)
          }"
        >
          <option disabled value="" selected>Select Type</option>
          <option value="Exposer_Principal">Total Principal</option>
          <option value="OD_Amount">Overdue Amount</option>
          <option value="Future_Principal">Future Principal</option>
          <option value="OD_Principal">Overdue Principal</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable"> Group By </span>
        <select
          name="Search"
          id="SearchBy"
          [(ngModel)]="SearchBy"
          (change)="SearchByChange()"
          class="form-control input-text-css"
          #refSearchBy="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSearchBy.invalid,
            'alert-warning':
              refSearchBy.invalid &&
              (refSearchBy.dirty ||
                refSearchBy.touched ||
                refSearchBy.untouched)
          }"
        >
          <option value="" selected>Select Type</option>
          <option value="Branch">Branch</option>
          <option value="Product">Product</option>
          <option value="Collection Exe">Collection Executive</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="" selected>Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-2" *ngIf="Div_Branch">
        <span> Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          #refSelectGender="ngModel"
          [(ngModel)]="BranchId"
          class="form-control input-text-css"
        >
          <option value="0" selected>Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2" *ngIf="Div_Product">
        <span>Product</span>
        <select
          name="ProductId"
          id="ProductId"
          #refSelectGender="ngModel"
          [(ngModel)]="ProductId"
          class="form-control input-text-css"
        >
          <option value="0" selected>Select Product</option>
          <option
            *ngFor="let product of ProductList"
            [value]="product.ProductId"
          >
            {{ product.Product }}
          </option>
        </select>
      </div>

      <div class="col-md-1">
        <button
          class="mt-3 btn font-size-12 button-btn"
          type="button"
          (click)="
            SearchBy == ''
              ? onReport_PAR_Detail({ Id: 0, Name: '' })
              : onSearchParReportData()
          "
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-4 mt-3" [hidden]="!dataSourceTreeDetail">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3">
    <div class="">
      <div
        class="table-responsive"
        *ngIf="div_tree"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <table
          mat-table
          [dataSource]="dataSourceTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [hiddenColumns]="[displayTreeColumns.indexOf('Action')]"
        >
          <ng-container
            [matColumnDef]="col"
            *ngFor="let col of displayTreeColumns"
          >
            <th
              mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              {{
                col == "Name"
                  ? "Name"
                  : col == "NoOfLoans"
                  ? "Total Loans"
                  : col == "Action"
                  ? "Action"
                  : col.split("A").join("")
              }}
            </th>
            <td
              mat-cell
              *matCellDef="let data; let i = index"
              class="grid-cell"
            >
              <div *ngIf="col == 'Name'; else elseBlock">
                {{ data[col] }}
              </div>
              <ng-template #elseBlock
                >{{ data[col] > 0 ? data[col] : "" }}
              </ng-template>
              <div *ngIf="col == 'Action'">
                <i
                  class="fa fa-arrow-circle-right btn_detail"
                  (click)="onReport_PAR_Detail(data)"
                  aria-label="true"
                ></i>
              </div>
            </td>

            <th
              mat-footer-cell
              *matFooterCellDef
              style="border: 1px solid #9f9696"
            >
              {{ col == "Action" ? "" : totaltypewise(col) }}
            </th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayTreeColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayTreeColumns"></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayTreeColumns; sticky: true"
            style="background: #4dc3a3; border: 1px solid #9f9696"
          ></tr>
        </table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4 p-1">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter, 'Summary')"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
      <div
        class="table-responsive"
        *ngIf="!div_tree && div_modalOut"
        style="height: 60vh; max-width: 100%; overflow: auto"
      >
        <table
          mat-table
          [dataSource]="dataSourceTreeDetail"
          style="overflow: auto"
          class="mat-elevation-z8"
          matPaginator
          matSort
          matTableExporter
          #exporter1="matTableExporter"
          [hiddenColumns]="[displayTreeColumnsDetail.indexOf('Id')]"
        >
          <ng-container
            [matColumnDef]="col"
            *ngFor="let col of displayTreeColumnsDetail"
          >
            <th
              mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              <div *ngIf="col == 'Id'; else elsedivmainF">#</div>
              <ng-template #elsedivmainF>
                {{
                  col == "LoanAcNo"
                    ? "Loan AcNo"
                    : col == "Loan_Date"
                    ? "Loan Date"
                    : col == "CustomerName"
                    ? "Customer Name"
                    : col.split("A").join("")
                }}
              </ng-template>
            </th>
            <td
              mat-cell
              *matCellDef="let data; let i = index"
              class="grid-cell"
            >
              <div
                *ngIf="
                  col == 'Loan_Date' ||
                    col == 'LoanAcNo' ||
                    col == 'CustomerName';
                  else elseBlock
                "
              >
                {{ data[col] }}
              </div>
              <ng-template #elseBlock>
                <div *ngIf="col == 'Id'; else elsedivmain">
                  {{
                    PaginatorList.pageIndex * PaginatorList.pageSize + (i + 1)
                  }}
                </div>
                <ng-template #elsedivmain>
                  {{ data[col] > 0 ? data[col] : 0 }}
                </ng-template>
              </ng-template>
            </td>
            <th
              mat-footer-cell
              *matFooterCellDef
              style="border: 1px solid #9f9696"
            >
              {{ totaltypewiseDetails(col) }}
            </th>
          </ng-container>

          <tr
            mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayTreeColumnsDetail; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayTreeColumnsDetail"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayTreeColumnsDetail; sticky: true"
            style="background: #4dc3a3; border: 1px solid #9f9696"
          ></tr>
        </table>
        <div class="row col-md-12 mt-3 formborder ml-1"
             [hidden]="!dataSourceTreeDetail">
            <div class="col-md-4 mt-2">
                <button class="mt-3 btn font-size-12 button-btn"
                        (click)="exportTable(exporter1, 'Detail')">
                    <i class="fa fa-file-export mr-1"
                       style="font-size: medium; cursor: pointer"></i>
                    Export
                </button>
            </div>
            <div class="col-md-8 text-right mt-2"
                 [hidden]="dataSourceTreeDetailModel">
                <mat-paginator #PaginatorList
                               [pageSizeOptions]="[20, 50]"
                               showFirstLastButtons></mat-paginator>
            </div>
        </div>
      </div>

      
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewParDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
      class="modal-content"
    >
      <div class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ SearchBy }} [{{ Selectname }}]
        </h6>
        <button
          type="button"
          (click)="onCloseParDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="col-md-4 mb-2" style="float: right  !important;">
            <form action="" class="search-text">
              <div class="bg-light rounded rounded-pill shadow-sm">
                <div class="input-group">
                  <input
                    type="text"
                    id="FilterInputSearch"
                    (keyup)="applyFilterModal($event.target.value)"
                    name="FilterInputSearch"
                    placeholder="What're you searching for?"
                    class="form-control border-0 bg-light"
                  />
                  <div class="input-group-append">
                    <button
                      id="button-addon1"
                      type="submit"
                      class="btn btn-link text-primary"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive">
          <table
            mat-table
            matPaginator
            #Paginglistmodel
            [dataSource]="dataSourceTreeDetailModel"
            class="mat-elevation-z8"
            matSort
            matTableExporter
            #exporter2="matTableExporter"
            [hiddenColumns]="[displayTreeColumnsDetail.indexOf('Id')]"
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayTreeColumnsDetail"
            >
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                <div *ngIf="col == 'Id'; else elsedivmainF">#</div>
                <ng-template #elsedivmainF>
                  {{
                    col == "LoanAcNo"
                      ? "Loan AcNo"
                      : col == "Loan_Date"
                      ? "Loan Date"
                      : col == "CustomerName"
                      ? "Customer Name"
                      : col.split("A").join("")
                  }}
                </ng-template>
              </th>
              <td
                mat-cell
                *matCellDef="let data; let i = index"
                class="grid-cell"
              >
                <div
                  *ngIf="
                    col == 'Loan_Date' ||
                      col == 'LoanAcNo' ||
                      col == 'CustomerName';
                    else elseBlock
                  "
                >
                  {{ data[col] }}
                </div>
                <ng-template #elseBlock>
                  <div *ngIf="col == 'Id'; else elsedivmain">
                    {{ i + 1 }}
                  </div>
                  <ng-template #elsedivmain>
                    {{ data[col] > 0 ? data[col] : 0 }}
                  </ng-template>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef>
                {{ totaltypewiseDetailsModel(col) }}
              </th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayTreeColumnsDetail"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayTreeColumnsDetail"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayTreeColumnsDetail; sticky: true"
              style="background: #4dc3a3"
            ></tr>
          </table>
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportTable(exporter2, 'Detail')"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8 text-right mt-2">
              <mat-paginator
                #Paginglistmodel
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
            <!-- <div class="col-md-8">
              <mat-paginator [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
