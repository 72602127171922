//#region < Imports Services and components >
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { MenusModel, newMenuModal, newMenuModalNew } from '../../_LOS/_layout/Models/MenusModel';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DialogService } from '../../Shared/dialog.service'
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

//#endregion

@Component({
  selector: 'app-los-app-user-layout',
  templateUrl: './los-app-user-layout.component.html',
  styleUrls: ['./los-app-user-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(400))
    ])
  ]
})

export class LosAppUserLayoutComponent implements OnInit {

  //#region < Variables declarations >
  private data: any; private data1: any;
  token: any;
  showSpinner = false;
  activeIcon: string;
  private _userservice;
  MenusModel: MenusModel = new MenusModel();
  options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  currentDate = new Date();
  todayDate = this.currentDate.toLocaleDateString("en-US", this.options)
  Menuslinks: any;
  CheckLoad: any;
  username = "";
  setAutoHide: boolean = false;
  autoHide: number = 2000;
  action: boolean = false;
  actionButtonLabel: string = 'Retry';
  show: boolean = false
  plusMinus: any = '+';
  checkIn: boolean = false;
  active: any = "";
  recentActiveCheckIn: boolean = false;
  recentActive: any = "";
  lastaccess: Date;
  currentlogin: Date;
  MenuslinksNew: any;
  mysubscription: any;
  activeroute: any; dialogreturn: any;
  liveclock: any;
  FyearId: any;
  BranchId: any;
  BranchesList: any[] = [];
  FyearDropdown: any[] = [];
  //#endregion

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  HeaderTitle: string = '';

  //#region < Constructor AppUserLayoutComponent >
  constructor(private _Route: Router, public snackBar: MatSnackBar, private lmsService: LmsService, private http: HttpClient, private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService, private _location: Location, private dataSharingService: DataSharingService, private dialog: DialogService) {
    this.startTime();
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    this.username = this.data.username;
    this.lastaccess = new Date(this.data.lastaccess + 'Z');
    this.currentlogin = this.data.currentlogin;
    this._userservice = _userlayoutservice;
    this.MenusModel.userId = this.data.userId;
    // this.Menuslinks = this._userlayoutservice.getNewMenuList(this.data.userId);
    // this.MenuslinksNew = newMenuModal[0];
    //this.data1 = JSON.parse(sessionStorage.getItem('RA'));
    //console.log(this.data1);
    //if (this.data1!= null && !this.data1.RA) {                  
    //  this.MenuslinksNew = newMenuModalNew.pop();
    //}
    //else {
    //this.MenuslinksNew = newMenuModalNew;
    //}
  }

  startTime() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();
    m = this.checkTime(m);
    s = this.checkTime(s);
    var session = "AM"
    if (h == 0) { h = 12 }
    if (h > 12) {
      h = h - 12;
      h = this.checkTime(h);
      session = "PM"
    }
    this.liveclock = h + ":" + m + ":" + s + " " + session;
    var t = setTimeout(() => { this.startTime() }, 500);
  }
  checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }


  //#endregion

  //#region < onsubsubmenuclick>
  onsubsubmenuclick(e) {
    $('.nav').on('click', function (e) {
      //if ($(this).hasClass('dropdown-menu-form')) {
      e.stopPropagation();
      // }
    });
  }
  //#endregion

  //#region < onclick>
  onclick(typ) {
  }
  //#endregion

  onMenuclick() {
  }

  //#region < isLinkActive>
  public isLinkActive(url: string): boolean {
    return this._Route.url.indexOf(url) > -1;
  }

  //#endregion

  ngOnInit() {
    if (!this.dataSharingService.getMenuItemByType('LOS')) {
      this._userlayoutservice.GetAllMenus({ UserID: this.data.userId, MenuFor: 'LOS' }).subscribe((res: any) => {
        this.dataSharingService.setMenuItem('LOS', res);
      });
    }
    this.dataSharingService.getMenuItem().subscribe((res: any) => {
      if (res['LOS']) {
        this.MenuslinksNew = res['LOS'];
      }
    })
    this.dataSharingService.getLOSHeaderTitle().subscribe(value => {
      this.HeaderTitle = value;
    });
    this.getFyear();
    this.getBranchesList();
  }

  //#region < toggle>
  toggle() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.plusMinus = "-";
    else
      this.plusMinus = "+";

  }
  //#endregion

  //#region < recentCheck>
  recentCheck() {
    this.active = "";
    this.checkIn = false;
    this.recentActiveCheckIn = !this.recentActiveCheckIn;
    if (this.recentActiveCheckIn) {
      this.recentActive = "recentActive";
    } else {
      this.recentActive = "";
      this._Route.navigated = true;
    }
  }
  //#endregion

  //#region < check>

  check() {
    this.recentActive = "";
    this.recentActiveCheckIn = false;
    this.checkIn = !this.checkIn;
    if (this.checkIn) {
      this.active = "active";
      //   TodaysappointmentsComponent;
    }
    else {
      this.active = "";
      //  TodaysappointmentsComponent;
    }
  }
  //#endregion

  //#region < toggleNew>
  toggleNew(currentMenu) {
    this.recentActive = "";
    this.recentActiveCheckIn = false;
    if (currentMenu.MM_IsParent == true) {
      this.MenuslinksNew.forEach(element => {
        if (element === currentMenu) {
          currentMenu.MM_IsShow = !currentMenu.MM_IsShow;
        } else {
          element.MM_IsShow = true;
        }
      });
    }
  }
  //#endregion

  //#region < getStateNew>
  getStateNew(currentMenu) {
    if (!currentMenu.MM_IsShow) {

      return 'down';
    } else {
      return 'up';
    }
  }
  //#endregion
  getFyear() {
    this.lmsService.Get_FinancialYear({ Id: 0 }).subscribe((res: any) => {
      this.FyearDropdown = res;
      this.FyearId = this.FyearDropdown[this.FyearDropdown.length - 1].Id;


    });
  }
  onChangeFyear(event) {
    // return this._Route.url.indexOf(url) > -1;
    console.log("event", event);
  }
  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.data.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
      this.BranchId = this.BranchesList[0].BranchId;
    });
  }
}
