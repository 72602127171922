<div class="grid-container ">
    <div class="  grid-row-con">
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Particulars</div>
        </div>
        <div class="grid-items">
            <div class="grid-sub-item-value font-weight-bold"> Detail </div>
        </div>
       
       
    </div>

    <div class=" mt-1 grid-row-con" *ngFor="let row of data">



        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Process</div> -->
            <div class="grid-sub-item-value"> {{row.Particulars}} </div>
        </div>

        <div class="grid-items">
            <!-- <div class="grid-sub-item"> Executive </div> -->
            <div class="grid-sub-item-value"> {{row.Detail}} </div>
        </div>

    

       
    </div>
</div>