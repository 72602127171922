<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchBusinessData()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> Start Month Year </span>
        <input
          name="st_month_year"
          [(ngModel)]="st_month_year"
          id="st_month_year"
          placeholder="Start Month Year"
          class="form-control input-text-css"
          bsDatepicker
          [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
          (onShown)="onOpenCalendar($event)"
          (ngModelChange)="DateChange()"
          required
          #refst_month_year="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refst_month_year.invalid,
            'alert-warning':
              refst_month_year.invalid &&
              (refst_month_year.dirty ||
                refst_month_year.touched ||
                refst_month_year.untouched)
          }"
        />
      </div>
      <div class="col-md-2">
        <span class="required-lable"> End Month Year </span>
        <input
          name="en_month_year"
          [(ngModel)]="en_month_year"
          id="en_month_year"
          placeholder="End Month Year"
          class="form-control input-text-css"
          bsDatepicker
          [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
          (onShown)="onOpenCalendar($event)"
          (ngModelChange)="DateChange()"
          required
          #refen_month_year="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refen_month_year.invalid,
            'alert-warning':
              refen_month_year.invalid &&
              (refen_month_year.dirty ||
                refen_month_year.touched ||
                refen_month_year.untouched)
          }"
        />
      </div>
      <div class="col-md-4">
        <button
          type="button"
          (click)="onSearchBusinessData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="table-responsive" [hidden]="!DataSource">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 55vh"
        >
          <ng-container
            [matColumnDef]="col"
            *ngFor="let col of displayedColumns"
          >
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              {{ col == "Id" ? "#" : col.split("_").join(" ") }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <div *ngIf="col == 'Action'; else elseBlock">
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetails(row)"
                  aria-label="true"
                ></i>
              </div>
              <ng-template #elseBlock>
                {{
                  col == "Id"
                    ? paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    : col == "Month_Year"
                    ? row[col].split(" ")[0].substr(0, 3) +
                      " " +
                      row[col].split(" ")[1]
                    : row[col]
                }}
              </ng-template>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-left grid-header">
              {{
                col == "Month_Year"
                  ? "Total"
                  : col == "Id"
                  ? ""
                  : col == "Action"
                  ? ""
                  : DataSource
                  ? totaltypewise(col)
                  : ""
              }}
            </mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-2 btn font-size-12 button-btn"
              (click)="exportexcel()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewBusinessCollectionDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Details
        </h6>
        <button
          type="button"
          (click)="onCloseBusinessCollectionDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="float-right mb-2">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceModel"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayedColumnsModel"
            >
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                {{
                  col == "Id"
                    ? "#"
                    : col == "LoanAcNo"
                    ? "Loan No."
                    : col == "CustomerName"
                    ? "Customer Name"
                    : col.split("_").join(" ")
                }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                {{
                  col == "Id"
                    ? paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1)
                    : row[col]
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="grid-header">
                {{
                  col == "LoanAcNo"
                    ? "Total"
                    : col == "Id"
                    ? ""
                    : DataSourceModel
                    ? totaltypewise1(col)
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>
            <div class="col-md-3">
              <mat-form-field [hidden]="!DataSource">
                <input
                  matInput
                  (keyup)="applyFilter($event.target.value)"
                  placeholder="Search ...."
                />
              </mat-form-field>
            </div>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsModel; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
          <div class="row m-0 align-items-center">
            <div class="col-md-4">
              <button
                class="mt-1 btn font-size-12 button-btn"
                (click)="exportexcelDetail()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8">
              <mat-paginator
                #paginatorlist
                [pageSize]="20"
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="busvsCollection"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Month Year
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Business
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Collection
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Received
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Month_Year }}</td>
    <td>{{ x.Business }}</td>
    <td>{{ x.EMI_Collection }}</td>
    <td>{{ x.Total_Principle_Received }}</td>
    <td>{{ x.Total_Interest_Received }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Business") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_Collection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Principle_Received") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Interest_Received") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="busvsCollectionDetail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Business
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Collection
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Received
    </th>
  </tr>

  <tr *ngFor="let x of JsonDataDetail; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Business }}</td>
    <td>{{ x.EMI_Collection }}</td>
    <td>{{ x.Total_Principle_Received }}</td>
    <td>{{ x.Total_Interest_Received }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="3"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Business") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("EMI_Collection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Principle_Received") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Interest_Received") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
