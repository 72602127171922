<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  class="row m-0 formborder d-flex justify-content-between align-items-center"
>
  <div class="col-md-4 mt-3 text-right">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[displayedColumns.indexOf('Action')]"
        style="overflow: auto; height: 60vh; max-width: 100%"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ApplicationNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ApplicationNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanNo">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Loan No
          </mat-header-cell>

          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAmount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="MobileNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Mobile No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <div *ngIf="currentUser.IsMasking">
              {{ row.MobileNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.MobileNo }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>

          <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[10, 20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="generate_pending_amortization"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mobile No
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.LoanNo }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.MobileNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.MobileNo }}
      </div>
    </td>
  </tr>
</table>

<div
  class="modal fade in"
  id="GenerateAmortizationInfo"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Generate Amortization
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 col-md-12 p-0" *ngIf="RowData">
            <div class="col-md-3">
              <h6 class="fs-12">Application No</h6>
              <p class="lead fs-12 fw-7">{{ RowData.ApplicationNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan No</h6>
              <p class="lead fs-12">{{ RowData.LoanNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer Name</h6>
              <p class="lead fs-12">{{ RowData.Customer }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Branch</h6>
              <p class="lead fs-12">{{ RowData.Branch }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Product</h6>
              <p class="lead fs-12">{{ RowData.Product }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">LoanAmount</h6>
              <p class="lead fs-12">{{ RowData.LoanAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Date</h6>
              <p class="lead fs-12">{{ RowData.Loan_Date }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Last Disbursement Date</h6>
              <p class="lead fs-12">{{ RowData.lastDisbursementDate }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">EMI Type</h6>
              <p class="lead fs-12">{{ RowData.EMI_Type }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">IRR</h6>
              <p class="lead fs-12">{{ RowData.IRR }}</p>
            </div>
          </div>
          <hr />

          <div class="table-responsive">
            <mat-table
              [dataSource]="dataSourceModal"
              matSort
              matTableExporter
              #exporter="matTableExporter"
              style="overflow: auto; max-width: 100%"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                >
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Head">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Head</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Head }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Voucher_Date">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Voucher_Date
                </mat-header-cell>

                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Voucher_Date }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Amount">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Amount }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsModal; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsModal"
              ></mat-row>
            </mat-table>
          </div>

          <p *ngIf="pendingAmount != 0" class="text-danger">
            The disbursement amount does not match the payment amount.
          </p>

          <div class="col-md-12 row m-0" *ngIf="pendingAmount == 0">
            <div class="col-md-4">
              <span>First EMI Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker6"
                  dateConvert
                  required
                  placeholder="DD/MM/YYYY"
                  name="firstEmiDate"
                  [(ngModel)]="firstEmiDate"
                  (dateChange)="changeDatefirstEmiDate()"
                  id="firstEmiDate"
                  #refirstEmiDate="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refirstEmiDate.invalid,
                    'alert-warning':
                      refirstEmiDate.invalid &&
                      (refirstEmiDate.dirty ||
                        refirstEmiDate.touched ||
                        refirstEmiDate.untouched)
                  }"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker6"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker6></mat-datepicker>
              </div>
            </div>
            <div class="col-md-2" *ngIf="Days > 0">
              <span>Days</span>
              <input
                type="text"
                *ngIf="Days > 0"
                disabled
                [(ngModel)]="Days"
                placeholder="Days"
                name="Days"
                id="Days"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2" *ngIf="Days > 0">
              <span>Pre EMI Interest</span>
              <input
                type="text"
                disabled
                *ngIf="Days > 0"
                [(ngModel)]="InterestAmount"
                placeholder="InterestAmount"
                name="InterestAmount"
                id="InterestAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3" *ngIf="Days > 0">
              <!-- <span> Deduction of Pre EMI Interest</span> -->
              <!-- <input
            type="checkbox"
            name="IsDeduct"
            id="IsDeduct"
            [(ngModel)]="IsDeduct"
            (change)="onIsDeductChange($event)" -->
              <mat-checkbox
                name="IsDeduct"
                (change)="onIsDeductChange($event)"
                [(ngModel)]="IsDeduct"
                class="mt-3"
              >
                Deduction of Pre EMI Interest
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="GenerateAmortization()"
            [disabled]="!f.form.valid"
            class="mt-3 btn font-size-12 button-btn"
            *ngIf="pendingAmount == 0"
          >
            Generate Amortization
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="EMICalculatorAmort"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    role="document"
    style="max-width: 900px !important"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          EMI Calculator
        </h6>
        <button
          type="button"
          (click)="onCloseEMICalculator()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-0" *ngIf="emiIrr">
          <div class="col-md-12 mb-1 alert alert-success">
            <div class="row">
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>EMI Type : {{ RowData.EMI_Type }}</B></span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>First EMI Date :</B>
                  {{ firstEmiDate | date : "dd-MM-yyyy" }}</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Loan Date :</B>
                  {{ RowData.Loan_Date | date : "dd-MM-yyyy" }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>No Of Instl :</B> {{ RowData.No_Of_Instl }}
                </span>
              </div>

              <div class="col-md-4">
                <span class="fs-12"
                  ><B
                    >Disbursement Amount : ₹
                    {{ emiIrr.Disbursement_Amt.toFixed(2) }}</B
                  ></span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Interest Amount :</B> ₹
                  {{ emiIrr.Interest_Amt.toFixed(2) }}</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Agreement Value :</B> ₹
                  {{ emiIrr.Agreement_Value.toFixed(2) }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>LTV :</B> {{ emiIrr.LTV.toFixed(2) }} %</span
                >
              </div>

              <div
                class="col-md-4"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <span class="fs-12"
                  ><B>Disbursement IRR :</B>
                  {{ emiIrr.Disbursement_IRR }} %</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>EMI Amount :</B> ₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</span
                >
              </div>
              <div
                class="col-md-3"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <span class="fs-12"
                  ><B>Case IRR :</B> {{ emiIrr.Case_IRR }} %</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>ROI :</B> {{ emiIrr.ROI.toFixed(2) }} %</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="AmortizationSource">
          <mat-table
            [dataSource]="AmortizationSource"
            matTableExporter
            #exporter="matTableExporter"
          >
            <ng-container matColumnDef="PERIOD">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 50px"
              ></mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="EMI_Amount">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >EMI_Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.EMI_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("EMI_Amount")
              }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="DueDate">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >DueDate</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.DueDate
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Principle_OS">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Principle_OS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Principle_OS
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="INTEREST">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >INTEREST</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.INTEREST
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("INTEREST")
              }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PRINCIPAL">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >PRINCIPAL</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.PRINCIPAL
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("PRINCIPAL")
              }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="export">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                <button
                  class="btn font-size-12 button-btn"
                  (click)="exportTable(exporter)"
                >
                  <i
                    class="fa fa-file-export mr-1"
                    style="font-size: medium; cursor: pointer"
                  ></i>
                  Export
                </button>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"></mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="AmortizationColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: AmortizationColumns"
              style="cursor: pointer"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="AmortizationColumns; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
        <div class="col-md-12 row m-0 mt-3 justify-content-end">
          <button
            type="button"
            (click)="OnSaveAmortization()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
