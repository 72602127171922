import { Component, OnInit, ViewChild ,Input,Output,EventEmitter} from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { LmsService } from '../services/lms.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";

@Component({
  selector: 'app-lms-crc',
  templateUrl: './lms-crc-list.component.html',
  styleUrls: ['./lms-crc-list.component.scss']
})
export class LmsCRCListComponent implements OnInit {

  currentUser: any; selectedRowIndex: number = -1;
  CrcList: any[] = [];
  showSpinner: boolean = false;
  inViewMode: boolean = false;
  status: any = 'Pending';
  dataSource: any;
  cibilResponseMain: any;
  //LoanId: any;
  //LoanAcNo: any;
  ApplicationDetail: any;
  ProductList: any = [];
  CRCTab: any;
  FITVRTab: any;
  ChequeDetailTab: any;
  CurrentDate: any = new Date();
  CibilData: any;
  PurposeList: any[] = [];
  foundItem: any;
  CibilAcore_List: any[] = [];
  OpenWindow: Window;
  private _MasterService;
  displayedColumns: string[] = ['LoanId', 'LoanAcNo', 'Customer', 'Branch', 'Product', 'LoanAmount', 'ApplicationCreatedOn', 'CRC_Executive', 'CRC_TAT_Hr', 'CRC_Status', 'Assign'];;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  private _encdec;
  customerData: any = {};
  getLmsHeaderProduct: string;
  crcModel: any = {};
  loading: boolean = false;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  first: boolean = true;
  last: boolean = true;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  selectedCustomerId:any;
  IsViewCRCFIll:boolean=false;

  PageAcess: any;

  constructor(private LmsService: LmsService, private dataSharingService: DataSharingService,
    private encdec: EncrDecrService, private snackBar: MatSnackBar,
    private router: Router, private losService: LosService,
    private route: ActivatedRoute, private MasterService: MasterService,
    private _commonfunctions: CommonFunctions
  ) {
    this._MasterService = MasterService;
    this._encdec = encdec;
  }

  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next('CRC List');
    this.dataSharingService.HeaderTitle.next('CRC List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let AllPage = JSON.parse(sessionStorage.getItem('AllPageAcess'));
    this.PageAcess = AllPage.filter(x => x.Page_Name == "CRC");

    if(this.PageAcess == '' || this.PageAcess == null || this.PageAcess == undefined){
      this.RedirectTo();
    }
    // if (this.losService.getFilterDropdownValue('crc-list')) {
    //   this.status = this.losService.getFilterDropdownValue('crc-list');
    // }
    // this.dataSharingService.getLmsHeaderLoanNo().subscribe(value => {
    //   if (value) {
    //     console.log('getLmsHeaderLoanNo', value);
    //     this.LoanAcNo = value;
      //  this.GetLMSCRCCustomer();
        //this.onOpenCustomerModel({ LoanAcNo: value });
    //     this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
    //       if (value != null && value != "" && value != 'null' && value != 'undefined') {
    //         //console.log('getLmsHeaderLoanId', value);
    //         this.LoanId = value;

    //       }
    //     });
    //   }
    // });
    // this.dataSharingService.getLmsHeaderProduct().subscribe(value => {
    //   this.getLmsHeaderProduct = value;
    //   console.log("Product id Top", this.getLmsHeaderProduct.split('-')[1]);
    //   this.setProductId(value);
    // });
    // this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
    //   if (value != null && value != "" && value != 'null' && value != 'undefined') {
    //     if (this.losService.getLastAppId()) {

    //       this.losService.setLastAppId(null);
    //     }
    //     this.LoanId = value;

    //   } else {

    //   }
    // });
    // this.dataSharingService.getLmsHeaderFITVR().subscribe(value => {
    //   console.log("FITVR", value);
    //   if (value == true) {
    //     this.ChequeDetailTab = false;
    //     this.FITVRTab = true;
    //     //this.NextRoute = '/lms/lms-fi-tvr';
    //   }
    //   else {
    //     this.FITVRTab = false;
    //     this.ChequeDetailTab = true;
    //     //this.NextRoute = '/lms/Cheque-Details';
    //   }

    //   console.log("crc", this.CRCTab);
    //   console.log("FITVR", this.FITVRTab);
    //   console.log("Cheque", this.ChequeDetailTab);
    // });
    // this.route.paramMap.subscribe((param: ParamMap) => {
      // let type = decodeURIComponent(param.get("Type"));
      // if (type == 'L' || type == 'A') {
      //   if (type == 'L') {
          // this.dataSharingService.LmsHeaderType.next(type);
          // let LoanId = decodeURIComponent(param.get("id"));
          // this.dataSharingService.LmsHeaderLoanId.next(LoanId);
          // this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
          // this.LoanAcNo = param.get("LA-No");
          this.GetLMSCRCCustomer();
    //     } else {
    //       this.dataSharingService.LmsHeaderType.next(type);
    //       let LoanId = decodeURIComponent(param.get("id"));
    //       this.dataSharingService.LmsHeaderLoanId.next(LoanId);
    //       this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
    //     }
    //   }
    // });



    //this.getCRCList();
  }
  setProductId(value) {
    this.getLmsHeaderProduct = value;
  }
  highlight(row) {
    this.selectedRowIndex = row.ApplicationId;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onNext() {
    this._MasterService.GetProductListById({ ProductId: this.loanSummary.ProductId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
      console.log('this.ProductList', this.ProductList);
      var H = '', C = '', G = '', Status = false;
      H = ("" + this.ProductList[0].CRC.split(',')[0]).trim();
      C = ("" + this.ProductList[0].CRC.split(',')[1]).trim();
      G = ("" + this.ProductList[0].CRC.split(',')[2]).trim();
      console.log("CRC Get", H + " " + C + " " + G);
      for (let i = 0; i < this.customerData.Item2.length; i++) {
        if (this.customerData.Item2[i].CustomerType.charAt(0) == H.trim()) {
          if (this.customerData.Item2[i].CRC_Status == 'Completed')
            Status = true;
          else {
            Status = false;
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Complete CRC Of Hirer", ...this.configSuccess });
            break;
          }
        }
        if (this.customerData.Item2[i].CustomerType.charAt(0) == C.trim()) {
          if (this.customerData.Item2[i].CRC_Status == 'Completed')
            Status = true;
          else {
            Status = false;
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Complete CRC Of Co-Borrower", ...this.configSuccess });
            break;
          }
        }
        if (this.customerData.Item2[i].CustomerType.charAt(0) == G.trim()) {
          if (this.customerData.Item2[i].CRC_Status == 'Completed')
            Status = true;
          else {
            Status = false;
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Complete CRC Of Guarantor", ...this.configSuccess });
            break;
          }
        }
      }
      console.log("Status", Status);
      if (Status==true) {
        this.LmsService.SaveNext_Loan({

          Loan_Id: this.loanSummary.LoanId, CommandName: 'CRC', isStatus: 1
        }).subscribe((response) => {
          // var route = '';
          // $("#CrcInfo").removeClass("btn btn-orange btn-sm");
          // $("#CrcInfo").addClass('btn btn-success btn-sm');
          // if (this.FITVRTab == true) {
          //   $("#FiTvrInfo").removeClass("tab");
          //   route = '/lms/lms-fi-tvr';
          //   let element: HTMLElement = document.getElementById("FiTvrInfo") as HTMLElement;
          //   element.click();
          // }
          // if (this.ChequeDetailTab == true) {
          //   $("#ChequeDetailInfo").removeClass("tab");
          //   route = '/lms/Cheque-Details';
          //   let element: HTMLElement = document.getElementById("ChequeDetailInfo") as HTMLElement;
          //   element.click();
          // }
          this.action.emit("next");

        });

      }
      //else {
      //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Complete CRC", ...this.configSuccess });
      //}
    });
   
  }
  getCRCList() {
    this.showSpinner = true;
    this.losService.setFilterDropdownValue({ 'crc-list': this.status });
    this.losService.GetLosCRCList({ CRC_Status: this.status, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.CrcList = JSON.parse(JSON.stringify(res));
      this.dataSource = new MatTableDataSource(this.CrcList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    })
  }

  onOpenCustomerModel(data: any) {
    this.GetLMSCRCCustomer();
  }
  OnClose() {
    $('#carouselExampleControls').carousel('dispose')
    //this.customerData = {};
    $('#customerModel').modal('hide');
  }
  GetLMSCRCCustomer() {
    this.showSpinner = true;
    this.LmsService.GetLMSCRCCustomer({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      this.showSpinner = false;
      console.log("CRC", res);
      this.customerData = res;
      this.customerData.LoanId = this.loanSummary.LoanId;
      this.crcModel = { LoanId: this.customerData.LoanId, LoginUserId: this.currentUser.userId, ProcessId: 55, Recommendation: this.customerData.Item1[0].Recommendation || "", Remarks: this.customerData.Item1[0].Remark || "" };
      console.log(" this.customerData.Item2", this.customerData.Item2);



      //this.customerData = res;
      //this.customerData.LoanId = this.customerData.Loan_Id;
      //this.crcModel = { LoanId: this.customerData.Loan_Id, LoginUserId: this.currentUser.userId, Recommendation: this.customerData.Item1[0].Recommendation || "", Remarks: this.customerData.Item1[0].Remark || "" };
      //   $('#customerModel').modal('show');
      //   $("#customerModel").css("z-index", "1050");

      let $this = this;
      this.first = true;
      this.last = true;

      setTimeout(() => {
        var carouselLength = $('.carousel-item').length - 1;
        if (carouselLength) {
          this.last = false;
        }
        $('#carouselExampleControls').on('slide.bs.carousel', function (e) {
          // First one
          if (e.to == 0) {
            $this.first = true;
            $this.last = false;
          } // Last one
          else if (e.to == carouselLength) {
            $this.last = true;
            $this.first = false;
          } // The rest
          else {
            $this.first = false;
            $this.last = false;
          }
        });
      }, 1000);

    })
  }
  ViewCustomerCrc(data: any) {
    this.IsViewCRCFIll = true;
    this.selectedCustomerId = data.CustomerId;
  }
  onCompleteCRC() {
    let _applicable = this.customerData.Item2.filter((obj) => { return obj.CRC_Applicable == 'Yes' });
    let _remain = _applicable.find((obj: any) => { return obj.CRC_Status == 'Pending' });
    if (!_remain) {
      this.losService.LosCrcProcessComplete(this.crcModel).subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.OnClose();
          this.getCRCList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please fill all customer CRC reports.", ...this.configSuccess });
    }
  }
  exportTable(exporter: any) {
    exporter.exportTable('xls', { fileName: `los-CRC-${this.status}-list`, sheet: 'CRC' });
  }
  OnCRCDetailClose() {
    this.GetLMSCRCCustomer();
    this.IsViewCRCFIll = false;
    this.selectedCustomerId = undefined;
  }
  //onAction(event: any) {
  //  if (event) {
  //    this.GetLMSCRCCustomer();
  //  }
  //  this.GetLMSCRCCustomer();
  //  this.IsViewCRCFIll = false;
  //  this.selectedCustomerId = undefined;

  //}

  RedirectTo(){
    this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
    this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }
  getCRCThirdDetail(CustomerId) {
    this._MasterService.Get_CibilAPI_Request_Response({ Loan_Id: this.loanSummary.LoanId, CId: CustomerId })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.cibilResponseMain = JSON.parse(res[0].response);
          this.losService
            .GET_CRC_MASTER_BY_CRCAGENCY({
              Crc_Agency: "1",
              Is_Firm: 0,
            })
            .subscribe((res: any) => {
              this.PurposeList = res;
            });
          this.losService
            .Get_Master_CibilAcore_List().subscribe((res: any) => {
              this.CibilAcore_List = res;
            });
          this.getCibilReports();


        }
      })
  }
  onCloseCibilmainReport() {
    $("#cibilReportmain").modal("hide");
  }



  getCibilReports() {
    this.showSpinner = true;
    // this.lmsService.Get_Lms_CIBILReports().subscribe((result) => {
    // console.log("Cibil Data", result);
    this.CibilData = this.cibilResponseMain;
    console.log("Cibil Data", this.CibilData);

    this.showSpinner = false;
    if (this.CibilData?.consumerCreditData[0]?.accounts != undefined) {
      this.CibilData?.consumerCreditData[0]?.accounts.forEach((obj) => {
        obj.payment = [];
        obj.paymentDate = [];
        obj.onedue = [];
        obj.twodue = [];
        obj.threedue = [];
        var payDate = Object.assign(this.convertToDate(obj.paymentStartDate));
        payDate.setMonth(payDate.getMonth());
        for (let i = 0; i < obj.paymentHistory.length; i += 3) {
          obj.payment.push(obj.paymentHistory.slice(i, i + 3));

        }
        for (let i = 0; i < obj.payment.length; i++) {
          const date = new Date();
          date.setMonth(payDate.getMonth() - i);
          obj.paymentDate.push(this.formatMonthYear(date));
        }
        for (let i = 0; i < 15; i++) {
          obj.onedue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
        }
        if (obj.payment.length > 15) {
          for (let i = 15; i < 30; i++) {
            obj.twodue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
          }
        }
        if (obj.payment.length > 30) {
          for (let i = 30; i < obj.payment.length; i++) {
            obj.threedue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
          }
        }
      })
      console.log("accouts", this.CibilData?.consumerCreditData[0]?.accounts)
    }
    $("#cibilReportmain").modal("show");
    $("#cibilReportmain").css("z-index", "1050");

  }
  formatMonthYear(date: Date): string {

    const month = date.getMonth();
    const year = date.getFullYear();
    return `${(month == 0 ? '12' : (month < 10 ? '0' + month.toString() : month.toString())) + '-' + (month == 0 ? ((year - 1).toString().substring(4, 2)) : (year.toString().substring(4, 2)))}`;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  convertToDate(mmddyyyy: string): Date {
    const day = parseInt(mmddyyyy.substring(0, 2));
    const month = parseInt(mmddyyyy.substring(2, 4)); // Months are zero-based in JavaScript
    const year = parseInt(mmddyyyy.substring(4, 8));

    return new Date(year, month, day);
  }
  findItemById(code): void {
    if (this.PurposeList.find(item => item.Code === code)) {
      return this.PurposeList.find(item => item.Code === code).Value;
    }
    else null;
  }

  findCibilScore(code, scoreName): void {

    //console.log("score", this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName))
    if (this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName)) {
      return this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName).Explanation;
    } else
      return null;
  }
  onDocsPrint(divName) {

    //var prtContent = document.getElementById(divName);
    //var WinPrint = window.open('', '_blank');
    //WinPrint.document.write(prtContent.innerHTML);
    //WinPrint.document.close();
    //WinPrint.focus();
    //WinPrint.print();
    this._commonfunctions.PrintCibil(divName, 'Cibil', new Date().getTime());
    //let printContents = this.TransUnionCIBIL.nativeElement.innerHTML;
    //let originalContents = document.body.innerHTML;

    //document.body.innerHTML = printContents;

    //window.print();

    //document.body.innerHTML = originalContents;
  }
}
