<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div style="display: flex; align-items: center" class="formborder">
  <div class="col-md-2">
    <span class="required-lable">Year</span>
    <input
      name="getStartYear"
      [(ngModel)]="getStartYear"
      id="getStartYear"
      placeholder="Start Year"
      class="form-control input-text-css"
      bsDatepicker
      (ngModelChange)="onYearChangeVehicleGridCallAPi($event)"
      [bsConfig]="bsConfig"
      required
      #refgetStartYear="ngModel"
    />
  </div>

  <div class="col-md-4 mr_top pr-0">
    <div>
      <span class="required-lable">Model Name</span>
    </div>

    <input
      type="search"
      class="form-control input-text-css"
      id="modelName"
      name="modelName"
      list="dynmicChargeHead"
      autocomplete="off"
      required
      placeholder="Select Model Name"
      (ngModelChange)="onModelIdChangeVehicleGrid($event)"
      [(ngModel)]="Vehicle_Model_Name"
    />
    <datalist id="dynmicChargeHead">
      <option
        *ngFor="let item of modelNameDropdown"
        [value]="item.Vehicle_Model_Name"
        title="item.Vehicle_Model_Name"
      ></option>
    </datalist>
  </div>

  <div class="col-md-6">
    <button
      class="mt-3 btn font-size-12 button-btn"
      style="float: right"
      (click)="openAddVehicleGrid()"
    >
      Add
    </button>
  </div>
</div>

<div>
  <div class="table-responsive mt-3" [hidden]="!dataSourceViewDetails">
    <table
      mat-table
      [dataSource]="dataSourceViewDetails"
      class="mat-elevation-z8"
      style="overflow: auto"
    >
      <ng-container matColumnDef="modelId">
        <th mat-header-cell *matHeaderCellDef>Model ID</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="modelName">
        <th mat-header-cell *matHeaderCellDef>Model Name</th>
        <td mat-cell *matCellDef="let element">{{ element.modelName }}</td>
      </ng-container>

      <ng-container
        *ngFor="let column of displayedColumns.slice(2)"
        [matColumnDef]="column"
      >
        <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
        <td mat-cell *matCellDef="let element" class="text-left">
          {{ element[column] || "-" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<div
  class="modal fade in"
  id="addVehicleGridModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Vehicle Grid
        </h6>
        <button
          type="button"
          (click)="onCloseModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body" style="border: none">
        <div class="table-responsive">
          <form #ta="ngForm" novalidate class="formborder">
            <div class="row m-0 col-md-12 p-0 d-flex justify-content-between">
              <div class="col-md-2">
                <span class="required-lable">Year</span>
                <input
                  name="startyear"
                  [(ngModel)]="startyear"
                  id="startyear"
                  placeholder="Start Year"
                  class="form-control input-text-css"
                  bsDatepicker
                  (ngModelChange)="onYearChange($event)"
                  [bsConfig]="bsConfig"
                  required
                  #refstartyear="ngModel"
                  [ngClass]="{
                    'is-invalid': ta.submitted && refstartyear.invalid,
                    'alert-warning':
                      refstartyear.invalid &&
                      (refstartyear.dirty ||
                        refstartyear.touched ||
                        refstartyear.untouched)
                  }"
                />
              </div>
            </div>
          </form>

          <div class="table-responsive mt-3" [hidden]="!dataSource">
            <table
              mat-table
              matSort
              [dataSource]="dataSource"
              id="vehicleGrid"
              style="max-height: 5px; height: 380px"
            >
              <ng-container matColumnDef="modelId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="modelName">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Model Name
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modelName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Price
                </th>

                <td mat-cell *matCellDef="let row; let i = index">
                  <input
                    type="number"
                    #refprice="ngModel"
                    [(ngModel)]="row.price"
                    name="price{{ i }}"
                    id="price{{ i }}"
                    class="form-control input-text-css"
                  />
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsforTable; sticky: true"
                style="height: 30px !important"
                class="sticky-footer fontcolor"
              ></tr>
              <tr
                style="height: 30px !important"
                mat-row
                *matRowDef="let row; columns: displayedColumnsforTable"
              ></tr>
            </table>

            <div
              class="col-md-12"
              style="display: flex; align-items: center; justify-content: end"
            >
              <div class="col-md-2">
                <button
                  class="mt-3 btn font-size-12 button-btn"
                  (click)="onSaveVehicleGrid()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
