<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="onSearchAccountStatement()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-2">
        <span class="required-lable">Charges Head</span>
        <!--<span class="text-danger" *ngIf="df.submitted && chhead == ''">This Field is Required</span>
        <ng-multiselect-dropdown name="chargesHead" [placeholder]="'Select Account Head'" [data]="AccountHeadDropdown"
          formControlName="Account_Head"submitted [disabled]="disabled" [settings]="dropdownSettings" [(ngModel)]="AccountHead"
          (onDeSelect)="onItemSelect($event)" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown>-->

        <input
          type="text"
          [formControl]="ChargesHeadControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="chhead"
          name="chhead"
          required
          [class.alert-warning]="chhead == ''"
          placeholder="Charges Head"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onChargesHeadhSelect($event)"
        >
          <mat-option
            *ngFor="let Item of filteredChargesHead | async"
            [value]="Item"
          >
            {{ Item.ChagesHead }}
          </mat-option>
        </mat-autocomplete>

        <!--<select name="AccountHead" id="AccountHead" #refAccountHead="ngModel" [(ngModel)]="AccountHead" class="form-control input-text-css">
          <option value="" selected> Select Account Head </option>
          <option *ngFor="let account of AccountHeadDropdown" [value]="account.AccountId">
            {{account.Account_Name}}
          </option>
        </select>-->
      </div>
      <div class="col-md-2">
        <span> Loan Acc Category </span>
        <select
          name="Loan_Acc_Category"
          id="Loan_Acc_Category"
          [(ngModel)]="Loan_Acc_Category"
          class="form-control input-text-css"
        >
          <option value="0">Select All</option>
          <ng-container *ngFor="let productg of Loan_Acc_CategoryList">
            <option [value]="productg.Loan_CategoryId">
              {{ productg.Loan_category }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            [(ngModel)]="VoucherDateFrom"
            id="VoucherDateFrom"
            
            class="form-control input-text-css"
            (dateChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          placeholder="Date From"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
            
            class="form-control input-text-css"
            (dateChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          placeholder="Date To"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2 pl-0 mt-4 text-center">
        <label class="text-nowrap">With Opening</label>
        <input
          type="checkbox"
          [(ngModel)]="checkSeeAll"
          name="checkSeeAll"
          (change)="onCheckedChanged(checkSeeAll)"
          checked
        />
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchAccountStatement()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid || !chhead"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" [hidden]="!dataSource">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 40px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 40px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          style="max-width: 40px"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="PLoanAcNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="max-width: 85px"
          >LoanAcNo</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 85px"
        >
          {{ row.PLoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          style="max-width: 85px"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustumerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustumerName }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Loan_Acc_Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan_Acc_Category</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Loan_Acc_Category }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="PEligibility_LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan Amount</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
        >
          {{ row.PEligibility_LoanAmount }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
          >{{ calculateLoanAmount() }}</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="OpeningBalanceDR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >OP Bal(DR)</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">
          {{ row.OpeningBalanceDR }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right">{{
          calculateOpeningBalanceDR()
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="OpeningBalanceCR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >OP Bal(CR)</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">
          {{ row.OpeningBalanceCR }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right">{{
          calculateOpeningBalanceCR()
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="TranTypeDR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >TR Bal(DR)</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
        >
          {{ row.TranTypeDR > 0 ? row.TranTypeDR : "" }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
          >{{ calculateACTranTypeDR() }}</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="TranTypeCR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >TRBal(CR)</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
        >
          {{ row.TranTypeCR > 0 ? row.TranTypeCR : "" }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="text-right"
          [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
          >{{ calculateACTranTypeCR() }}</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="ClosingBalanceDR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >CL Bal(DR)</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">
          {{ row.ClosingBalanceDR > 0 ? row.ClosingBalanceDR : "" }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right">{{
          calculateClosingBalanceDR()
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="ClosingBalanceCR">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >CL Bal(CR)</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell text-right">
          {{ row.ClosingBalanceCR > 0 ? row.ClosingBalanceCR : "" }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="text-right">{{
          calculateClosingBalanceCR()
        }}</mat-footer-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-2">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-2">
        <button class="mt-3 btn font-size-12 button-btn" (click)="printdata()">
          <i
            class="fa fa-print mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Print
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
