import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { LegalService } from "../services/Legal.service";
declare var $: any;
import * as moment from "moment";

@Component({
  selector: "app-legal-hearing-cases",
  templateUrl: "./legal-hearing-cases.component.html",
  styleUrls: ["./legal-hearing-cases.component.scss"],
})
export class LegalHearingCasesComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  JsonData: any[] = [];

  dataSource: any;
  displayedColumns: string[] = [
    "Id",
    "Loan_Date",
    "LoanAcNo",
    "CustomerName",
    "Case_Filed_By",
    "Legal_On",
    "Last_Hearing_Date",
    "Next_Hearing_Date",
    "Action",
  ];

  dataSourceHearing: any;
  displayedColumnsHearing: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  HearingModal: any = {};
  CurrentDate: any = new Date();
  LegalStageDropdown: any = [];

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;

  IsSave: boolean = true;
  IsUpdate: boolean = false;
  LastHearingDate: any;
  minNextHearingDate: any;
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private _MasterService: MasterService,
    private router: Router,
    private losService: LosService,
    private _LegalService: LegalService,
    private dialog: DialogService,
    private _LmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Legal Hearing");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLegalCasesList();
    this.GetLegalStage();
  }

  GetLegalCasesList() {
    this.showSpinner = true;
    this._LegalService
      .Legal_HearingList({ LoginUserId: this.currentUser.userId })
      .subscribe((res: any) => {
        if (res.length > 0) {
          console.log("EWdddd", res);

          const aValues = res.map((obj) => obj.Last_Hearing_Date);
          console.log("last hearing date", aValues);
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GoToDetails(row) {
    this.IsSave = true;
    this.IsUpdate = false;

    this.HearingModal.Hearing_Stage = 170;
    this.HearingModal.Hearing_Status = "NextDate";
    this.HearingModal.Hearing_Session = "";
    this.HearingModal.Next_Hearing_Date = "";
    this.HearingModal.LegalId = row.Id;
   
   
    if (row.Last_Hearing_Date) {
      var Last_Hearing_Date = this.convertDateFormat(row.Last_Hearing_Date);
      this.minNextHearingDate = new Date(Last_Hearing_Date);
    } else {
      var Legal_On = this.convertDateFormat(row.Legal_On);
      this.minNextHearingDate = new Date(Legal_On);

    }

    //this.HearingModal.ApplicationNo = row.Application_No;
    this.HearingModal.LoanId = row.LoanId;
    this.HearingModal.CustomerId = row.CustomerId;
    this.HearingModal.Created_By = this.currentUser.userId;

    this.GetLegalHearingDetailList(row.Id);
    console.log("Rowwwww data logggg", row);

    $(`#HearingCase`).modal("show");
    $(`#HearingCase`).css("z-index", "1050");
  }
  convertDateFormat(dateStr: string): string {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  }
  OnCloseHearingModel() {
    $(`#HearingCase`).modal("hide");
  }

  GetLegalStage() {
    this._LmsService
      .LMS_Commaon_Master({ Commands: "All" })
      .subscribe((res: any) => {
        this.LegalStageDropdown = res.filter((x) => x.Type == "LEGAL_STAGE");
      });
  }

  getMinDate(row) {

  }

  /*fileChangeListener(data: any, files: any) {
    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Hearing_Doc = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Hearing_Doc = "";
    delete data.DocData;
  }*/

  OnSaveLegalHearing() {
    //delete this.HearingModal.Customer;
    let _data = {
      Legal_Hearing: this.HearingModal,
    };

    console.log("_data", _data);
    this.showSpinner = true;
    this._LegalService
      .Save_Legal_Hearing({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnCloseHearingModel();
          this.GetLegalCasesList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  GetLegalHearingDetailList(LegalId) {
    this.displayedColumnsHearing = [
      "Hearing_Id",
      "NextHearingDate",
      "Hearing_Session",
      "Hearing_Stage",
      "Hearing_Status",
      "Hearing_Action",
      "Action",
    ];

    this.showSpinner = true;
    this._LegalService
      .Legal_Hearing_Detail_List({ LegalId: LegalId })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.dataSourceHearing = new MatTableDataSource(res);
        }
        this.showSpinner = false;
      });
  }

  EditCaseHearing(row) {
    this.IsSave = false;
    this.IsUpdate = true;
    this.HearingModal = row;
    this.HearingModal.Id = row.Hearing_Id;
    this.HearingModal.Hearing_Stage = row.Hearing_StageID;
    this.HearingModal.Next_Hearing_Date = row.NextHearingDate;
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "legal_hearingcases_list"
    ) as HTMLTableElement;
    const worksheetName = "LEGAL HEARING CASES"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
