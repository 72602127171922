import { Component, OnInit, ViewChild, EventEmitter, Input, Output, Injectable } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
//import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;

import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { DialogService } from "../../Shared/dialog.service";
import { LmsService } from '../../_Lms/services/lms.service';
import { LosService } from "../../_LOS/services/los.service";
import { MatSort } from '@angular/material/sort';
// Excel export code start here
import * as FileSaver from 'file-saver';
import { QuickLoanService } from '../../_QuickLoan/services/quickLoan.service';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
// Excel export code end here

//import { BitlyClient } from 'bitly';
//const bitly = new BitlyClient('cb5148336309a52bca6afc09cbed1ab05d4c91ef', {});

@Component({
  selector: "app-lms-case-history",
  templateUrl: "./lms-case-history.component.html",
  styleUrls: ["./lms-case-history.component.scss"],
})
export class LMSCaseHistoryComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  CurrentDate: string;
  RequestModel: RequestModel = new RequestModel();
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  displayedDetailColumns: string[];
  dataSourceDetail: any = new MatTableDataSource([]);
  dataSourceLMS: any;
  tab: any = "LMS";
  @Input() loanSummary: any;
  TatReportsList: any = {};
  LMSLogs: any = {};
  displayedColumns: string[] = [];
  displayedLMSColumns: string[] = [];
 // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.dataSourceDetail.sort = this.sort;
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private _MasterService: MasterService,
    private _QuickLoanService: QuickLoanService,
    private losService: LosService,
    private router: Router, private dialog: DialogService, private lmsService: LmsService
  ) {

  }

  ngOnInit() {
    this.displayedColumns = ["ReportId", "Process", "ProcessExecutive", "ProcessStatus", "AssignedOn", "CompletedOn", "TAT_Hr", "DeafultTAT_Hr"];
    this.displayedLMSColumns = ["Id", "processName", "UserName", "changeDate"];
    this.dataSharingService.HeaderTitle.next("Activity Log");
    this.getTatReportsList();
    this.getLMSHistory();
  }
  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }
  getTatReportsList() {
    this.showSpinner = true;
    this._QuickLoanService.Quick_rpt_TAT_Report({ LoanId: this.loanSummary.LoanId }).subscribe((res) => {
      if (res["Item2"].length > 0) {
        this.displayedDetailColumns = [
          "ProcessId", "Process", "ProcessExecutive", "ProcessStatus", "AssignedOn", "CompletedOn", "TAT_Hr", "Remark","LatLong"
        ];
        var TatReportsList = JSON.parse(JSON.stringify(res));
        this.dataSourceDetail = new MatTableDataSource(TatReportsList.Item2);
        this.dataSourceDetail.sort = this.sort;
      }
      else {
        this.dataSourceDetail = new MatTableDataSource([]);
        this.dataSourceDetail.sort = this.sort;
      }
    })
  }

  getLMSHistory() {
    this.showSpinner = true;
    this.lmsService.LMS_Get_Case_History({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      console.log("LMS_Get_Case_History :", result);
      this.LMSLogs = result;
      this.dataSourceLMS = new MatTableDataSource(JSON.parse(JSON.stringify(result)));
   //   this.dataSourceLMS.sort = this.sort;
      this.dataSourceLMS.paginator = this.paginator;
      this.showSpinner = false;
      /*this.LMSLogs.forEach((x: any) => {
        x.json_value = JSON.parse(x.Change_Values);

        this.dataSourceLMS = new MatTableDataSource(this.LMSLogs);
        this.dataSourceLMS.sort = this.sort;
        this.dataSourceLMS.paginator = this.paginator;
        this.showSpinner = false;
      });*/
     });
  }


  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
    //FileSaver.saveAs(data, excelFileName + new  Date().getTime() + EXCEL_EXTENSION);
    FileSaver.saveAs(data, excelFileName + EXCEL_EXTENSION);
  }

  exportExcelLos(){
    this.TatReportsList.Item2.forEach((obj: any) => {
      delete obj.DeafultTAT_Hr;
      delete obj.IsTATExceed;
    });
    
    console.log("this.loanSummary", this.loanSummary.LoanNo);
    this.exportAsExcelFile(this.TatReportsList.Item2, `Activity-Log-LOS${this.loanSummary.LoanNo}`);
  }
  exportExcelLms(){
    //this.renameKey(this.LMSLogs, 'processName', 'Process Name');
    /*this.LMSLogs.forEach( obj => 
      this.renameKey(obj, 'processName', 'Process Name')
    );*/
    this.exportAsExcelFile(this.LMSLogs, `Activity-Log-LMS${this.loanSummary.LoanNo}`);
  }
  // Excel export in backend code end here

  // Rename A JSON key
  /*renameKey( obj, oldKey, newKey ) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }*/
}
