<div class="content" *ngIf="resData">
  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Loan Details</span>
  </div>

  <div class="loanInfo my-1" *ngIf="resData?.Item1">
    <app-loan-subcard-info
      [loandata]="resData?.Item1[0]"
    ></app-loan-subcard-info>
  </div>
  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Financial Details</span>
  </div>

  <div class="AssetInfoInfo my-2" *ngIf="resData?.Item16">
    <app-loan-financial-info
      [financialdata]="resData?.Item16"
    ></app-loan-financial-info>
  </div>
  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Asset Details</span>
  </div>
  <div class="assetInfo">
    <div class="AssetInfoInfo my-2">
      <app-asset-subcard-info
        [assetData]="resData?.asset[0]"
        [AssetsAttachment]="resData?.AssetsAttachment"
        [ApplicationNumber]="resData?.ApplicationNumber[0]"
        [GoldAssets]="resData?.goldAsset"
      ></app-asset-subcard-info>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">TAT Details</span>
  </div>
  <div class="TATInfo">
    <div class="AssetInfoInfo my-2">
      <app-tat-report [loanId]="loanId"></app-tat-report>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Deduction Details</span>
  </div>
  <div class="ReceiptInfo">
    <div class="ReceiptInfo my-2">
      <app-receipt-deduction [loanId]="loanId"></app-receipt-deduction>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Deviation Details</span>
  </div>
  <div class="DeviationInfo">
    <div class="DeviationInfo my-2">
      <app-deviation-info [data]="resData?.deviation"></app-deviation-info>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Sanction Condition</span>
  </div>
  <div class="SanctionConditionInfo">
    <div class="SanctionConditionInfo my-2">
      <app-sanction-condition
        [data]="resData?.sanction"
      ></app-sanction-condition>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">Documents</span>
  </div>

  <div class="attachedDocumentsInfo">
    <div class="attachedDocumentsInfo my-2">
      <app-attached-document
        [loanId]="loanId"
        [ApplicationNumber]="resData?.ApplicationNumber[0]"
      ></app-attached-document>
    </div>
  </div>

  <div style="text-align: center; font-size: medium; margin-top: 27px">
    <span class="titleHeadP">FOIR Details</span>
  </div>

  <div class="attachedDocumentsInfo">
    <div class="attachedDocumentsInfo my-2">
      <app-foir-card [loanId]="loanId"></app-foir-card>
    </div>
  </div>

  <div class="custInfo mt-2 card">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span
          class="carousel-control-prev-icon"
          style="background-color: rgba(54, 42, 42, 0.842)"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span
          class="carousel-control-next-icon"
          style="background-color: rgba(54, 42, 42, 0.842)"
          s
          aria-hidden="true"
        ></span>
        <span class="sr-only">Next</span>
      </a>

      <div class="carousel-inner">
        <div
          class="carousel-item"
          [ngClass]="ind == 0 ? 'active' : ''"
          *ngFor="let item of resData.data; let ind = index"
        >
          <div
            class="row my-2"
            style="
              flex-wrap: nowrap;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="col-md-4">
              <app-customer-subcard-info
                [customerData]="resData.data[ind].Item2"
              ></app-customer-subcard-info>
            </div>
            <div class="col-md-4 px-1">
              <app-customer-kyc-subcard-info
                [customerkycData]="resData.data[ind].Item9"
              >
              </app-customer-kyc-subcard-info>
            </div>
            <div class="col-md-4">
              <app-crc-subcard-info
                [applicationId]="applicationId"
                [crcData]="resData.data[ind].Item3"
                [index]="ind"
              ></app-crc-subcard-info>
            </div>
          </div>
          <div class="row my-2" style="flex-wrap: nowrap">
            <div class="col-md-4">
              <app-family-subcard-info
                [familyData]="resData.data[ind].Item4"
              ></app-family-subcard-info>
            </div>

            <div class="col-md-4 px-1">
              <app-income-subcard-info
                [incomeData]="resData.data[ind].Item5"
                [applicationNo]="applicationId"
              ></app-income-subcard-info>
            </div>
            <div class="col-md-4">
              <app-references-subcard-info
                [referenceData]="resData.data[ind].Item6"
              >
              </app-references-subcard-info>
            </div>
          </div>

          <div class="row my-2" style="flex-wrap: nowrap">
            <div
              class="col-md-4"
              style="flex-shrink: 1; flex-grow: 1; flex-basis: auto"
            >
              <app-FI-Question-subcard-info
                [FIQuestionData]="resData.data[ind].Item10"
              ></app-FI-Question-subcard-info>
            </div>
            <div
              class="col-md-4 px-1"
              style="flex-shrink: 1; flex-grow: 1; flex-basis: auto"
            >
              <app-TVR-Question-subcard-info
                [TVRQuestionData]="resData.data[ind].Item11"
              ></app-TVR-Question-subcard-info>
            </div>
            <div
              class="col-md-4"
              style="flex-shrink: 1; flex-grow: 1; flex-basis: auto"
            >
              <app-PD-Question-subcard-info
                [PDQuestionData]="resData.data[ind].Item12"
              ></app-PD-Question-subcard-info>
            </div>
          </div>
          <div class="row my-2" style="flex-wrap: nowrap">
            <div class="col-md-4">
              <app-pdc-details
                [PDCDetail]="resData.data[ind].Item13"
              ></app-pdc-details>
            </div>
            <div class="col-md-4 px-1"></div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="my-2">
    <app-print-card
      [loanId]="loanId"
      [applicationId]="applicationId"
      [resData]="resData"
    >
    </app-print-card>
  </div>
</div>
