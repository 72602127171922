<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="getBalanceSheetData()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CaseDateFrom"
            id="VoucherDateFrom"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            required
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span>Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            #refVoucher_Date="ngModel"
            (dateChange)="DateChange()"
            [min]="minDate"
            [max]="maxDate"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            required
            #refVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateTo.invalid,
              'alert-warning':
                refVoucherDateTo.invalid &&
                (refVoucherDateTo.dirty ||
                  refVoucherDateTo.touched ||
                  refVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span>Show Nill A/C</span>
        <div style="margin-left: 22px">
          <mat-checkbox name="WithZero" [(ngModel)]="WithZero"></mat-checkbox>
        </div>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="getBalanceSheetData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-2" *ngIf="BSTab">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="onDocsPrintBalanceSheet()"
        >
          <i
            class="fa fa-print mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Print
        </button>
      </div>

      <div class="col-md-2 text-right" style="padding-left: 0px" *ngIf="BSTab">
        <button
          type="button"
          *ngIf="ExpandBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickExpandAll()"
        >
          Expand All
        </button>
        <button
          type="button"
          *ngIf="CollaspeBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickCollaspeAll()"
        >
          Collaspe All
        </button>
      </div>
      <div class="col-md-1 text-right" style="padding-left: 0px" *ngIf="PLTab">
        <button
          type="button"
          *ngIf="ExpandBtn1"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickExpandAll1()"
        >
          Expand All
        </button>
        <button
          type="button"
          *ngIf="CollaspeBtn1"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickCollaspeAll1()"
        >
          Collaspe All
        </button>
      </div>
      <div class="col-md-2 text-right">
        <ng-container>
          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn"
            (click)="onDocsPrint()"
          >
            <i
              class="fas fa-file-excel mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </ng-container>
      </div>
    </div>
  </form>

  <div class="row m-0 p-0 col-md-12 tabb mt-3">
    <div class="col-md-6">
      <button
        class="btn btn-outline-green btn-lg btn-block"
        [ngClass]="{ active: tab == 'BS' }"
        (click)="changeTab('BS')"
      >
        Balance Sheet
      </button>
    </div>
    <div class="col-md-6">
      <button
        class="btn btn-outline-green btn-lg btn-block"
        [ngClass]="{ active: tab == 'PL' }"
        (click)="changeTab('PL')"
      >
        Profit and Loss Account
      </button>
    </div>
  </div>
  <div class="row m-0 mt-3 p-0 col-md-12 mt-3" *ngIf="BSTab">
    <div class="col-md-6">
      <div class="card">
        <div
          class="card-body"
          style="height: 350px; overflow: auto; overflow-x: auto"
        >
          <table
            mat-table
            [dataSource]="dataSourceLibTree"
            class="mat-elevation-z8"
            matSort
            matTableExporter
            #exporter="matTableExporter"
          >
            <ng-container matColumnDef="Group_Name">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="text-left White_space"
              >
                Liability
              </th>
              <td mat-cell *matCellDef="let data" class="text-left White_space">
                <button
                  mat-icon-button
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 20"
                  (click)="LtreeControl.toggle(data)"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{ LtreeControl.isExpanded(data) ? "remove" : "add" }}
                  </mat-icon>
                </button>
                {{ data.Group_Name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Balance">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-right White_space"
              >
                Balance
              </th>
              <td
                mat-cell
                *matCellDef="let data"
                class="text-right White_space"
              >
                {{ data.Balance_Val }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="White_space"></th>
              <td mat-cell *matCellDef="let data" class="White_space">
                &nbsp;&nbsp;
                <span [ngClass]="{ NotShow: data.expandable }">
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetail(data)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedCTreeolumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedCTreeolumns"
            ></tr>
          </table>
          <div class="row bottom_row text-success" *ngIf="Gross_profit_Lib > 0">
            <div class="col-md-8 text-left"><B>Net Profit</B></div>
            <div class="col-md-4 text-right right_text">
              <B>{{ Gross_profit_Lib }} CR</B>
            </div>
          </div>
        </div>
        <div class="card-footer bg-green">
          <div class="row row_total">
            <div class="col-md-8 tot_text"><B>Total</B></div>
            <div class="col-md-4 text-right tot_data">
              <B>{{ calculateTRTotalByCRDR(1) }}</B>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body" style="height: 350px; overflow: auto">
          <table
            mat-table
            [dataSource]="dataSourceAssetsTree"
            class="mat-elevation-z8"
            matSort
            matTableExporter
            #exporterL="matTableExporter"
          >
            <ng-container matColumnDef="Group_Name">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="text-left White_space"
              >
                Assets
              </th>
              <td mat-cell *matCellDef="let data" class="text-left White_space">
                <button
                  mat-icon-button
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 20"
                  (click)="AtreeControl.toggle(data)"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{ AtreeControl.isExpanded(data) ? "remove" : "add" }}
                  </mat-icon>
                  <!-- <span class="expnd_font">{{treeControl.isExpanded(data) ? '-' : '+'}}</span> -->
                </button>
                {{ data.Group_Name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Balance">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-right White_space"
              >
                Balance
              </th>
              <td
                mat-cell
                *matCellDef="let data"
                class="text-right White_space"
              >
                {{ data.Balance_Val }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="White_space"></th>
              <td mat-cell *matCellDef="let data" class="White_space">
                &nbsp;&nbsp;
                <span [ngClass]="{ NotShow: data.expandable }">
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetail(data)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedCTreeolumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedCTreeolumns"
            ></tr>
          </table>
          <div
            class="row bottom_row text-success"
            *ngIf="Gross_loss_Assets > 0"
          >
            <div class="col-md-8 text-left"><B>Net Loss</B></div>
            <div class="col-md-4 text-right right_text">
              <B>{{ Gross_loss_Assets }} DR</B>
            </div>
          </div>
        </div>
        <div class="card-footer bg-green">
          <div class="row row_total">
            <div class="col-md-8 tot_text"><B>Total</B></div>
            <div class="col-md-4 text-right tot_data">
              <B>{{ calculateTRTotalByCRDR(2) }}</B>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12">
      <button class="btn btn-success font-size-12" (click)="exportTable(exporterL)">
        <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
      </button>
    </div> -->
  </div>

  <div *ngIf="PLTab">
    <div class="row m-0 mt-3 p-0 col-md-12">
      <div class="col-md-6">
        <div class="card">
          <div
            class="card-body"
            style="height: 155px; overflow: auto; overflow-x: auto"
          >
            <table
              mat-table
              [dataSource]="dataSourceDIREXPTree"
              class="mat-elevation-z8"
              matSort
              matTableExporter
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="Group_Name">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  class="text-left White_space"
                >
                  Particulars
                </th>
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-left White_space"
                >
                  <button
                    mat-icon-button
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 20"
                    (click)="DEtreeControl.toggle(data)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ DEtreeControl.isExpanded(data) ? "remove" : "add" }}
                    </mat-icon>
                  </button>
                  {{ data.Group_Name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Balance">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text-right White_space"
                >
                  Balance
                </th>
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-right White_space"
                >
                  {{ data.Balance_Val }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="White_space"></th>
                <td mat-cell *matCellDef="let data" class="White_space">
                  &nbsp;&nbsp;
                  <span [ngClass]="{ NotShow: data.expandable }">
                    <i
                      class="fa fa-arrow-circle-right"
                      (click)="goToDetail(data)"
                      style="cursor: pointer; color: green; font-size: 16px"
                      aria-label="true"
                    ></i>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedCTreeolumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCTreeolumns"
              ></tr>
            </table>
            <div
              class="row bottom_row text-success"
              *ngIf="Gross_profit_DIREXP > 0"
            >
              <div class="col-md-8 text-left"><B>Gross Profit</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_profit_DIREXP }} DR</B>
              </div>
            </div>
          </div>
          <div class="card-footer bg-green">
            <div class="row row_total">
              <div class="col-md-8 tot_text"><B>Total</B></div>
              <div class="col-md-4 text-right tot_data">
                <B>{{ TOTAL_DIR_EXP }}</B>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div
            class="card-body"
            style="height: 150px; overflow: auto; overflow-x: auto"
          >
            <table
              mat-table
              [dataSource]="dataSourceDIRINCTree"
              class="mat-elevation-z8"
              matSort
              matTableExporter
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="Group_Name">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  class="text-left White_space"
                >
                  Particulars
                </th>
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-left White_space"
                >
                  <button
                    mat-icon-button
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 20"
                    (click)="DItreeControl.toggle(data)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ DItreeControl.isExpanded(data) ? "remove" : "add" }}
                    </mat-icon>
                  </button>
                  {{ data.Group_Name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Balance">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text-right White_space"
                >
                  Balance
                </th>
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-right White_space"
                >
                  {{ data.Balance_Val }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="White_space"></th>
                <td mat-cell *matCellDef="let data" class="White_space">
                  &nbsp;&nbsp;
                  <span [ngClass]="{ NotShow: data.expandable }">
                    <i
                      class="fa fa-arrow-circle-right"
                      (click)="goToDetail(data)"
                      style="cursor: pointer; color: green; font-size: 16px"
                      aria-label="true"
                    ></i>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedCTreeolumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCTreeolumns"
              ></tr>
            </table>

            <div
              class="row bottom_row text-success"
              *ngIf="Gross_loss_DIRINC > 0"
            >
              <div class="col-md-8 text-left"><B>Gross Loss</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_loss_DIRINC }} CR</B>
              </div>
            </div>
          </div>
          <div class="card-footer bg-green">
            <div class="row row_total">
              <div class="col-md-8 tot_text"><B>Total</B></div>
              <div class="col-md-4 text-right tot_data">
                <B>{{ TOTAL_DIR_INC }}</B>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-3 p-0 col-md-12">
      <div class="col-md-6">
        <div class="card">
          <div
            class="card-body"
            style="height: 148px; overflow: auto; overflow-x: auto"
          >
            <div class="row text-success" *ngIf="Gross_loss_INDIREXP > 0">
              <div class="col-md-8 text-left"><B>Gross Loss</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_loss_INDIREXP }} DR</B>
              </div>
            </div>
            <table
              mat-table
              [dataSource]="dataSourceINDIREXPTree"
              class="mat-elevation-z8"
              matSort
              matTableExporter
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="Group_Name">
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-left White_space"
                >
                  <button
                    mat-icon-button
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 20"
                    (click)="IEtreeControl.toggle(data)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ IEtreeControl.isExpanded(data) ? "remove" : "add" }}
                    </mat-icon>
                  </button>
                  {{ data.Group_Name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Balance">
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-right White_space"
                >
                  {{ data.Balance_Val }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <td mat-cell *matCellDef="let data" class="White_space">
                  &nbsp;&nbsp;
                  <span [ngClass]="{ NotShow: data.expandable }">
                    <i
                      class="fa fa-arrow-circle-right"
                      (click)="goToDetail(data)"
                      style="cursor: pointer; color: green; font-size: 16px"
                      aria-label="true"
                    ></i>
                  </span>
                </td>
              </ng-container>

              <tr
                mat-row
                *matRowDef="let row; columns: displayedCTreeolumns"
              ></tr>
            </table>
            <div
              class="row bottom_row text-success"
              *ngIf="Gross_profit_INDIREXP > 0"
            >
              <div class="col-md-8 text-left" styl><B>Net Profit</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_profit_INDIREXP }} DR</B>
              </div>
            </div>
          </div>
          <div class="card-footer bg-green">
            <div class="row row_total">
              <div class="col-md-8 tot_text"><B>Total</B></div>
              <div class="col-md-4 text-right tot_data">
                <B>{{ TOTAL_INDIR_EXP }}</B>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div
            class="card-body"
            style="height: 148px; overflow: auto; overflow-x: auto"
          >
            <div class="row text-success" *ngIf="Gross_profit_INDIRINC > 0">
              <div class="col-md-8 text-left"><B>Gross Profit</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_profit_INDIRINC }} CR</B>
              </div>
            </div>
            <table
              mat-table
              [dataSource]="dataSourceINDIRINCTree"
              class="mat-elevation-z8"
              matSort
              matTableExporter
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="Group_Name">
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-left White_space"
                >
                  <button
                    mat-icon-button
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 20"
                    (click)="IItreeControl.toggle(data)"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ IItreeControl.isExpanded(data) ? "remove" : "add" }}
                    </mat-icon>
                  </button>
                  {{ data.Group_Name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Balance">
                <td
                  mat-cell
                  *matCellDef="let data"
                  class="text-right White_space"
                >
                  {{ data.Balance_Val }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <td mat-cell *matCellDef="let data" class="White_space">
                  &nbsp;&nbsp;
                  <span [ngClass]="{ NotShow: data.expandable }">
                    <i
                      class="fa fa-arrow-circle-right"
                      (click)="goToDetail(data)"
                      style="cursor: pointer; color: green; font-size: 16px"
                      aria-label="true"
                    ></i>
                  </span>
                </td>
              </ng-container>

              <tr
                mat-row
                *matRowDef="let row; columns: displayedCTreeolumns"
              ></tr>
            </table>
            <div
              class="row bottom_row text-success"
              *ngIf="Gross_loss_INDIRINC > 0"
            >
              <div class="col-md-8 text-left"><B>Net Loss</B></div>
              <div class="col-md-4 text-right right_text">
                <B>{{ Gross_loss_INDIRINC }} DR</B>
              </div>
            </div>
          </div>
          <div class="card-footer bg-green">
            <div class="row row_total">
              <div class="col-md-8 tot_text"><B>Total</B></div>
              <div class="col-md-4 text-right tot_data">
                <B>{{ TOTAL_INDIR_INC }}</B>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AccountingDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Accounting Statement ( {{ AccountName }} )
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseAccountingDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-0 mb-2">
          <div class="col-md-3 p-1">
            <select
              name="BalanceType"
              id="BalanceType"
              class="form-control input-text-css"
              [(ngModel)]="BalanceType"
              (change)="ChangeBalanceType()"
            >
              <option [value]="0">Annually</option>
              <option [value]="1">Monthly</option>
              <option [value]="2">Daily</option>
            </select>
          </div>
        </div>
        <div
          class="table-responsive"
          [hidden]="!DivAnnualy"
          style="height: 350px; max-width: 100%; overflow: auto"
        >
          <!-- <mat-table [dataSource]="dataSourceAccount" matSort matTableExporter #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto;">
            <ng-container matColumnDef="voucherid">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>#</mat-header-cell>
              <mat-cell *matCellDef="let row,let i = index" class="grid-cell">{{ (i + 1) }}</mat-cell>
              <mat-footer-cell *matFooterCellDef style="max-width:40px"></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Voucher_Date">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Voucher Date </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.VoucherDate}} </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Voucher No</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Voucher_No}} </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Account Head</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.accHead}} </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>DR Amount</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.TranTypeDR}} </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceAccount ? calculateACTranTypeDR() : ''}}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>CR Amount</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.TranTypeCR}} </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceAccount ? calculateACTranTypeCR() : ''}}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Balance</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.TranTypeTotal}} </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceAccount ? calculateACTranTypeTotal() : ''}}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Narration</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Narration}} </mat-cell>
              <mat-cell *matCellDef="let row" class="grid-cell mw500" data-toggle="tooltip" data-placement="left" title="{{ row.Narration }}">
                <ng-container *ngIf="row.Narration">
                  {{ row.Narration.slice(0, 20) }}{{ row.Narration.length > 20 ? "..." : "" }}
                </ng-container>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <mat-header-cell mat-sort-header class="grid-header" style="max-width: 100px; justify-content: center" *matHeaderCellDef>
                View Detail
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell j-c-center" [ngClass]="{'NotShow': row.accHead == 'Opening Balance'}" style="max-width:60px">
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">&nbsp;</div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model [voucherid]="row.voucherid" (close)="GetData($event)" [index]="i">
                    <i class="fa fa-arrow-circle-right" style="cursor: pointer;color: green; font-size: 16px" aria-label="true"></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef style="max-width:60px"></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedAccountColumns; sticky: true"></mat-header-row>
            <mat-row [ngClass]="{'Row_Bold': row.accHead == 'Opening Balance'}" *matRowDef="let row; columns: displayedAccountColumns"></mat-row>
            <mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedAccountColumns; sticky: true" style="background: #4dc3a3;"></mat-footer-row>
          </mat-table> -->
          <table
            class="table-bordered"
            mat-table
            matSort
            [dataSource]="dataSourceAccount"
          >
            <ng-container matColumnDef="voucherid">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                #
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                style="padding: 2px; font-size: 12px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_Date">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                Voucher Date
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.VoucherDate }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                Voucher No
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.Voucher_No }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <ng-container matColumnDef="CaseNo">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                Loan No
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.CaseNo }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                A/C Head
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.accHead }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px">
                Total
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
                style="padding: 2px"
              >
                DR Amount
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="text-right"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.TranTypeDR }}
              </td>
              <th
                mat-footer-cell
                *matFooterCellDef
                class="text-right"
                style="padding: 2px"
              >
                {{ dataSourceAccount ? calculateACTranTypeDR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
                style="padding: 2px"
              >
                CR Amount
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="text-right"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.TranTypeCR }}
              </td>
              <th
                mat-footer-cell
                *matFooterCellDef
                class="text-right"
                style="padding: 2px"
              >
                {{ dataSourceAccount ? calculateACTranTypeCR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
                style="padding: 2px"
              >
                Balance
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="text-right"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.TranTypeTotal }}
              </td>
              <th
                mat-footer-cell
                *matFooterCellDef
                class="text-right"
                style="padding: 2px"
              >
                {{ dataSourceAccount ? calculateACTranTypeTotal() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                style="padding: 2px"
              >
                Narration
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="padding: 2px; font-size: 12px"
              >
                {{ row.Narration }}
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                style="padding: 2px"
              >
                View Detail
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                class="text-center"
                style="padding: 2px; font-size: 12px"
              >
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model
                    [voucherid]="row.voucherid"
                    (close)="GetData($event)"
                    [index]="i"
                  >
                    <i
                      class="fa fa-arrow-circle-right btn_detail"
                      aria-label="true"
                    ></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef style="padding: 2px"></th>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedAccountColumns; sticky: true"
              style="height: 15px"
            ></tr>
            <tr
              mat-row
              [ngClass]="{ Row_Bold: row.accHead == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedAccountColumns"
              style="height: 15px; padding: 2px"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedAccountColumns; sticky: true"
              style="height: 15px"
            ></tr>
          </table>
        </div>
        <div
          class="row m-0 pt-2 pb-1"
          style="background: #fff"
          [hidden]="!DivAnnualy"
        >
          <div class="col-md-12 text-right">
            <!-- <div *ngIf="dataSourceAccount != '' && dataSourceAccount !=undefined">
              <div *ngIf="dataSourceAccount.filteredData.length >= TotRow">
                <button class="btn btn-success font-size-12" (click)="LoadMore()" [class.spinner]="loading" [disabled]="loading">
                  <i class="fa fa-spinner mr-1" style="font-size:medium;cursor: pointer;"></i> Load More
                </button>
              </div>
            </div> -->
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>

        <div class="table-responsive" [hidden]="!DivMonthly">
          <mat-table
            [dataSource]="dataSourceMonthly"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Month_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Month Year</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Month_Year }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Account Head</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.accHead }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? calculateMonthlyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetailModel(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsMonthly; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsMonthly"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsMonthly; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>

        <div class="table-responsive" [hidden]="!DivDaily">
          <mat-table
            [dataSource]="dataSourceDaily"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="VoucherDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Voucher Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Voucher_Date | date }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? totaltypeDaily("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? totaltypeDaily("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? calculateDailyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetailModel(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsDaily; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsDaily"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsDaily; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="VoucherModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherModel()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="table-responsive"
          *ngIf="dataSourceModel"
          style="height: 400px; max-width: 100%; overflow: auto"
        >
          <table
            class="table-bordered"
            mat-table
            matSort
            [dataSource]="dataSourceModel"
          >
            <ng-container matColumnDef="voucherid">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let row; let i = index">
                <!-- {{ (paginatorlist.pageIndex * paginatorlist.pageSize) + (i + 1) }} -->{{
                  i + 1
                }}
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="VoucherDate">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher Date
              </th>
              <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher No
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="CaseNo">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
              <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                A/C Head
              </th>
              <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
              <th mat-footer-cell *matFooterCellDef>Total</th>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                DR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeDR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModel("TranTypeDR") }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                CR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeCR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModel("TranTypeCR") }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                Balance
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeTotal }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModelBal() }}
              </th>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Narration
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                View Detail
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                class="text-center"
              >
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model
                    [voucherid]="row.voucherid"
                    (close)="GetData($event)"
                    [index]="i"
                  >
                    <i
                      class="fa fa-arrow-circle-right btn_detail"
                      aria-label="true"
                    ></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsModel; sticky: true"
            ></tr>
          </table>
          <div class="row m-0 pt-2 pb-1" style="background: #fff"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Rashmi--->
<div class="row">
  <!-- <div style="font-family: 'Times New Roman', Times, serif; text-align: center">
    <h5>{{ currentUser.CompanyName }}</h5>
  </div> -->

  <table id="BalanceSheetDiv" style="display: none">
    <tr>
      <th
        colspan="3"
        style="text-align: center; background-color: #4dc3a3; font-size: 20px"
      >
        {{ currentUser.CompanyName }}
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        Address : {{ currentUser.CompanyAddress }}
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        Balance Sheet
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        As On Date {{ VoucherDateTo | date }}
      </th>
    </tr>
    <tr></tr>
    <tr>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceLibTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="width: 100%"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Liability
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="LtreeControl.toggle(data)"
                style="background: none; color: inherit; border: none"
              >
                <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{LtreeControl.isExpanded(data) ? '*' : 'add'}}
              </mat-icon> -->
              </button>
              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
      <td></td>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceAssetsTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporterL="matTableExporter"
          style="width: 100%"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Assets
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="AtreeControl.toggle(data)"
                style="background: none; color: inherit; border: none"
              >
                <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{AtreeControl.isExpanded(data) ? '*' : 'add'}}
              </mat-icon> -->
              </button>
              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
    </tr>
    <tr>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_profit_Lib > 0" style="text-align: left">
              <B>Net Profit</B>
            </th>
            <th *ngIf="Gross_profit_Lib > 0" style="text-align: right">
              <B>{{ Gross_profit_Lib }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ calculateTRTotalByCRDR(1) }}</B>
            </th>
          </tr>
        </table>
      </td>
      <td></td>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_loss_Assets > 0" style="text-align: left">
              <B>Net Loss</B>
            </th>
            <th *ngIf="Gross_loss_Assets > 0" style="text-align: right">
              <B>{{ Gross_loss_Assets }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ calculateTRTotalByCRDR(2) }}</B>
            </th>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <table id="ProfitLossDiv" style="display: none">
    <tr>
      <th
        colspan="3"
        style="text-align: center; background-color: #4dc3a3; font-size: 20px"
      >
        {{ currentUser.CompanyName }}
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        Address : {{ currentUser.CompanyAddress }}
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        Profit & Loss Account
      </th>
    </tr>
    <tr>
      <th colspan="3" style="text-align: center; background-color: #4dc3a3">
        For The Period {{ VoucherDateFrom | date }} to
        {{ VoucherDateTo | date }}
      </th>
    </tr>
    <tr></tr>
    <tr>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceDIREXPTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Particulars
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="DEtreeControl.toggle(data)"
              >
                <!--<mat-icon class="mat-icon-rtl-mirror">
      {{DEtreeControl.isExpanded(data) ? 'remove' : 'add'}}
    </mat-icon>-->
              </button>

              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
      <td></td>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceDIRINCTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Particulars
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="DItreeControl.toggle(data)"
              >
                <!--<mat-icon class="mat-icon-rtl-mirror">
      {{DItreeControl.isExpanded(data) ? 'remove' : 'add'}}
    </mat-icon>-->
              </button>

              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
    </tr>
    <tr>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_profit_DIREXP > 0" style="text-align: left">
              <B>Gross Profit</B>
            </th>
            <th *ngIf="Gross_profit_DIREXP > 0" style="text-align: right">
              <B>{{ Gross_profit_DIREXP }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ TOTAL_DIR_EXP }}</B>
            </th>
          </tr>
        </table>
      </td>
      <td></td>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_loss_DIRINC > 0" style="text-align: left">
              <B>Gross Loss</B>
            </th>
            <th *ngIf="Gross_loss_DIRINC > 0" style="text-align: right">
              <B>{{ Gross_loss_DIRINC }} CR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ TOTAL_DIR_INC }}</B>
            </th>
          </tr>
        </table>
      </td>
    </tr>
    <tr></tr>

    <tr>
      <td>
        <table class="mat-elevation-z8" style="width: 100%">
          <tr>
            <th *ngIf="Gross_loss_INDIREXP > 0" style="text-align: left">
              <B>Gross Loss</B>
            </th>
            <th *ngIf="Gross_loss_INDIREXP > 0" style="text-align: right">
              <B>{{ Gross_loss_INDIREXP }} DR</B>
            </th>
          </tr>
        </table>
        <table
          mat-table
          [dataSource]="dataSourceINDIREXPTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Group_Name">
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="IEtreeControl.toggle(data)"
              >
                <!--<mat-icon class="mat-icon-rtl-mirror">
      {{IEtreeControl.isExpanded(data) ? 'remove' : 'add'}}
    </mat-icon>-->
              </button>

              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
      <td></td>
      <td>
        <table class="mat-elevation-z8" style="width: 100%">
          <tr>
            <th *ngIf="Gross_profit_INDIRINC > 0" style="text-align: left">
              <B>Gross Profit</B>
            </th>
            <th *ngIf="Gross_profit_INDIRINC > 0" style="text-align: right">
              <B>{{ Gross_profit_INDIRINC }} CR</B>
            </th>
          </tr>
        </table>

        <table
          mat-table
          [dataSource]="dataSourceINDIRINCTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Group_Name">
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="IItreeControl.toggle(data)"
              >
                <!--<mat-icon class="mat-icon-rtl-mirror">
      {{IItreeControl.isExpanded(data) ? 'remove' : 'add'}}
    </mat-icon>-->
              </button>
              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
    </tr>
    <tr>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_profit_INDIREXP > 0" style="text-align: left">
              <B>Net Profit</B>
            </th>
            <th *ngIf="Gross_profit_INDIREXP > 0" style="text-align: right">
              <B>{{ Gross_profit_INDIREXP }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ TOTAL_INDIR_EXP }}</B>
            </th>
          </tr>
        </table>
      </td>
      <td></td>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_loss_INDIRINC > 0" style="text-align: left">
              <B>Net Loss</B>
            </th>
            <th *ngIf="Gross_loss_INDIRINC > 0" style="text-align: right">
              <B>{{ Gross_loss_INDIRINC }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ TOTAL_INDIR_INC }}</B>
            </th>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>

<div class="row" style="display: none">
  <div id="companyImage" style="float: right">
    <img
      src="{{ EmployeeProfileBaseUrl }}logo.png"
      alt="logo"
      style="width: 60px; display: inline"
    />
  </div>
  <div style="clear: both" id="infoDiv">
    <div>
      <div
        style="font-family: 'Times New Roman', Times, serif; text-align: center"
      >
        <h5>{{ currentUser.CompanyName }}</h5>
      </div>
      <div
        style="font-family: 'Times New Roman', Times, serif; text-align: center"
      >
        <h6>Address : {{ currentUser.CompanyAddress }}</h6>
      </div>
    </div>

    <div class="row m-0 mb-2">
      <div
        class="col-md-12 text-center"
        style="font-family: 'Times New Roman', Times, serif; text-align: center"
      >
        Balance Sheet as on date-
        {{ VoucherDateTo | date }}
      </div>
    </div>
  </div>
  <!-- 
  -->

  <table id="printBalanceSheet" style="display: none">
    <tr>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceLibTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="width: 100%"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Liability
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="LtreeControl.toggle(data)"
                style="background: none; color: inherit; border: none"
              >
                <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{LtreeControl.isExpanded(data) ? '*' : 'add'}}
              </mat-icon> -->
              </button>
              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
      <td></td>
      <td>
        <table
          mat-table
          [dataSource]="dataSourceAssetsTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporterL="matTableExporter"
          style="width: 100%"
        >
          <ng-container matColumnDef="Group_Name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-left White_space"
            >
              Assets
            </th>
            <td mat-cell *matCellDef="let data" class="text-left White_space">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="AtreeControl.toggle(data)"
                style="background: none; color: inherit; border: none"
              >
                <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{AtreeControl.isExpanded(data) ? '*' : 'add'}}
              </mat-icon> -->
              </button>
              <span *ngIf="data.expandable" style="font-weight: 900">{{
                data.Group_Name
              }}</span>
              <span *ngIf="!data.expandable">{{ data.Group_Name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Balance
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: right">
              {{ data.Balance_Val }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCTreeolumnsPrint"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedCTreeolumnsPrint"
          ></tr>
        </table>
      </td>
    </tr>
    <tr>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_profit_Lib > 0" style="text-align: left">
              <B>Net Profit</B>
            </th>
            <th *ngIf="Gross_profit_Lib > 0" style="text-align: right">
              <B>{{ Gross_profit_Lib }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ calculateTRTotalByCRDR(1) }}</B>
            </th>
          </tr>
        </table>
      </td>
      <td></td>
      <td
        style="
          text-align: center;
          background-color: #4dc3a3;
          border: 1px solid midnightblue;
        "
      >
        <table class="mat-elevation-z8" style="width: 100%">
          <tr style="border: 1px solid black">
            <th *ngIf="Gross_loss_Assets > 0" style="text-align: left">
              <B>Net Loss</B>
            </th>
            <th *ngIf="Gross_loss_Assets > 0" style="text-align: right">
              <B>{{ Gross_loss_Assets }} DR</B>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <th style="text-align: left"><B>Total</B></th>
            <th style="text-align: right">
              <B>{{ calculateTRTotalByCRDR(2) }}</B>
            </th>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
