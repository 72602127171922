import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
import { NgForm } from '@angular/forms';
import { DialogService } from 'src/app/Shared/dialog.service';
declare var $: any;

@Component({
  selector: 'app-insurance-branch',
  templateUrl: './insurance-branch.component.html',
  styleUrls: ['./insurance-branch.component.scss']
})
export class InsuranceBranchComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();
  GetInsuranceBranchRequestModel:RequestModel=new RequestModel();
  GetInsurancecompanyRequestModel:RequestModel=new RequestModel();
  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = 0;
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  applicationId: any = 95;
  dataCustomerSource: any;
  InsuranceCompanyName: any = '';
  SelectInsuranceType: any = '';
  saveBtn:boolean = true;
  updateBtn:boolean = false;
  Id:number;
  selectedItemsList:number;
  GetInsuranceBranch:any;
  GetCompanyName:any;
  Insurancecomp:any = '';
  update_Insurancecomp:any = '';
  update_Insurance_bran: any = '';

  Insurance_bran:any;
  val:any;
  displayedCustomerColumns: string[] = ['Number', 'Insurance Branch', 'Insurance Company','Status', 'Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog: DialogService,
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Insurance Branch');
    this.GetInsuranceBranchMasters();
    this.LMS_GetInsuranceCompany();
  }

  SaveInsuranceBranch(formValue){
    console.log(formValue,'formValue');
    // let SelectInsuranceCompany ="";
    // let SelectInsuranceCompany ="";
    let formValues={Insurance_Branch:formValue.value.Insurance_branch,Insurance_Company_Id:formValue.value.SelectInsuranceCompany, Status:1, IsActive:1};
    this.LmsService.SaveInsuranceBranchMaster(formValues).subscribe((res) =>{
      if(res[0].CODE == 0){
        this.GetInsuranceBranchMasters();
      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }else{
      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    }
    });
  }

  // get LMS_GetInsuranceCompany for dropdown
  LMS_GetInsuranceCompany(){
    this.showSpinner = true;
    this.LmsService.GetInsuranceCompany().subscribe((res:any) =>{
      if(res){   
        this.GetCompanyName = res;
      }else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Server Error', ...this.configSuccess });
      }
    });
  }

  GetInsuranceBranchMasters(){
    this.showSpinner = true;
    this.LmsService.GetInsuranceBranchMasters().subscribe((res:any) =>{
      if(res){
        this.GetInsuranceBranch = new MatTableDataSource(res);
      }else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Server Error', ...this.configSuccess });
      }
    });
  }

  status(val){
    console.log(val,'val');
    if(val.Status == 1){
      this.LmsService.SaveInsuranceBranchMaster({Int_Id:val.Int_Id,Status:0,StatusVal:'STATUS'}).subscribe((res) =>{
        if(res[0].CODE == 0){
          this.GetInsuranceBranchMasters();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
      });
    }else{
      this.LmsService.SaveInsuranceBranchMaster({Int_Id:val.Int_Id,Status:1,StatusVal:'STATUS'}).subscribe((res) =>{
        if(res[0].CODE == 0){
          this.GetInsuranceBranchMasters();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
      });
    }
  }

  editInsuranceBrnc(row){
    console.log("aaa",row);
    // this.Insurance_Branch = id.Insurance_bran;
    // this.GetInsuranceBranchRequestModel.Insurance_Branch= id.Insurance_Branch;
    // this.GetInsurancecompanyRequestModel.Insurancecomp= id.Insurancecomp;
    this.Id = row.Int_Id;
    this.update_Insurance_bran = row.Insurance_Branch;
     this.update_Insurancecomp= row.Insurance_Company_Id;
$('#insuranceBranch').modal('show');
$("#insuranceBranch").css("z-index", "1050");

    // console.log(id,'edit');
    // this.saveBtn = false;
    // this.OnUpdate = true;
    // this.Id = id.Int_Id;
    // this.Insurancecomp = id.Insurance_Company_Id;
    // this.Insurance_bran = id.Insurance_Branch;
  }
  OnUpdate(){
    let update = {
      "Insurance_Company_Id": this.update_Insurancecomp,
      "Insurance_Branch": this.update_Insurance_bran,
      "Int_Id": this.Id,
    }
    this._LmsService.SaveInsuranceBranchMaster(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.GetInsuranceBranchMasters();
        this.OnClose();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });

  }
  OnClose(){
    // this.AssignedToEmployee = '';
    // this.APId = 0;
    $('#insurance-branch').modal('hide');
  }
  

  
  UpdateLoanAcct(val: NgForm) {
    this.saveBtn = true;
    this.updateBtn = false;
    let update = {
      "Insurance_Company_Id": val.value.SelectInsuranceCompany,
      "Insurance_Branch": val.value.Insurance_branch,
      "Int_Id": this.Id,
    }
    this._LmsService.SaveInsuranceBranchMaster(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.GetInsuranceBranchMasters();
        val.resetForm();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }


  DeleteInsuranceBranch(val) {
    let update = {
      "Int_Id": val.Int_Id,
      "StatusVal": "DEL",
      "IsActive": 0,
      "Insurance_Branch":"Jaipur",
      "Insurance_Company_Id":1,
    }
    this._LmsService.SaveInsuranceBranchMaster(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.GetInsuranceBranchMasters();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
  onDelete(val) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          // console.log(val,'val');
          if(val.Status == 1){
            this.LmsService.SaveInsuranceBranchMaster({Int_Id:val.Int_Id,Status:0,StatusVal:'STATUS'}).subscribe((res) =>{
              if(res[0].CODE == 0){
                this.GetInsuranceBranchMasters();
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              }else{
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
            });
          }else{
            this.LmsService.SaveInsuranceBranchMaster({Int_Id:val.Int_Id,Status:1,StatusVal:'STATUS'}).subscribe((res) =>{
              if(res[0].CODE == 0){
                this.GetInsuranceBranchMasters();
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              }else{
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
            });
          }
        }
      });
  }


  
}
