<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchAccountStatement()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            required
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher From"
          (ngModelChange)="DateChange()"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            [min]="minDate"
            [max]="maxDate"
            required
            (dateChange)="DateChange()"
            #refVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateTo.invalid,
              'alert-warning':
                refVoucherDateTo.invalid &&
                (refVoucherDateTo.dirty ||
                  refVoucherDateTo.touched ||
                  refVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          (ngModelChange)="DateChange()"
          id="VoucherDateTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher To"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateTo.invalid,
            'alert-warning':
              refVoucherDateTo.invalid &&
              (refVoucherDateTo.dirty ||
                refVoucherDateTo.touched ||
                refVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span> Show On</span>
        <select
          name="IsGroupWise"
          id="IsGroupWise"
          class="form-control input-text-css"
          [(ngModel)]="IsGroupWise"
          (change)="OPTranChange()"
        >
          <option [value]="1">Account Head Wise</option>
          <option [value]="0">Group Wise</option>
        </select>
      </div>

      <div class="col-md-2">
        <span> Transaction Detail</span>
        <select
          name="TransationDetail"
          id="TransationDetail"
          class="form-control input-text-css"
          [(ngModel)]="TransationDetail"
          (change)="OPTranChange()"
        >
          <option [value]="0">summarized</option>
          <option [value]="1">Detailed</option>
        </select>
      </div>
      <div class="col-md-2" *ngIf="IsGroupWise == 0">
        <span>Select Group</span>
        <select
          name="Group"
          id="Group"
          class="form-control input-text-css"
          [(ngModel)]="selectGroupId"
        >
          <option [value]="0">All</option>
          <option *ngFor="let data of groupDropdown" [value]="data.GroupId">
            {{ data.Group_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          (click)="onSearchAccountStatement()"
          [disabled]="!df.form.valid"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
      <div class="col-md-6 mt-2 ml-4" *ngIf="IsGroupWise == 1 && div_list">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="col-md-11 text-right ml-5"
        *ngIf="IsGroupWise == 0 && div_tree"
      >
        <button
          type="button"
          *ngIf="ExpandBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickExpandAll()"
        >
          Expand
        </button>
        <button
          type="button"
          *ngIf="CollaspeBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickCollaspeAll()"
        >
          Collapse
        </button>
        <!-- <button type="button" mat-raised-button class="btn_default mt-3" (click)="treeControl.expandAll()">Expand All</button>
        <button  type="button" mat-raised-button class="btn_default mt-3 ml-2" (click)="treeControl.collapseAll()">Collapse All</button> -->
      </div>
    </div>
  </form>

  <div class="mt-3">
    <div class="" *ngIf="div_list">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="AccountId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 40px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 40px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 40px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Account_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-center"
              *matHeaderCellDef
            >
              Account Name
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right"
              >Total:</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="OpeningBalanceDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              class="text-center"
              >Opening DR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.OpeningBalanceDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateOpeningBalanceDRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="OpeningBalanceCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Opening CR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.OpeningBalanceCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateOpeningBalanceCRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Transaction DR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.TranTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateTranTypeDRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Transaction CR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.TranTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateTranTypeCRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-center"
              *matHeaderCellDef
              >Closing DR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.ClosingBalanceDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateClosingBalanceDRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-right"
              *matHeaderCellDef
              >Closing CR</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.ClosingBalanceCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateClosingBalanceCRTotal() }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ViewAction">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-center"
              *matHeaderCellDef
            >
              View
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 60px"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.AccountId == selectedId"
            [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer"
            *matFooterRowDef="displayedColumns; sticky: true"
            [class.bg-danger]="
              calculateClosingBalanceDRTotal() !=
              calculateClosingBalanceCRTotal()
            "
            [class.fontcolor]="
              calculateClosingBalanceDRTotal() ==
              calculateClosingBalanceCRTotal()
            "
          >
          </mat-footer-row>
        </mat-table>
        <div
          class="row m-0 pt-2 pb-1"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTableAccountHead()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export</button
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div
            class="col-md-3 bold"
            *ngIf="
              calculateOpeningBalanceDRTotal() !=
              calculateOpeningBalanceCRTotal()
            "
          >
            Difference Opening
            {{
              (
                calculateOpeningBalanceDRTotal() -
                calculateOpeningBalanceCRTotal()
              ).toFixed(2)
            }}
          </div>
          <div
            class="col-md-3 bold"
            *ngIf="calculateTranTypeDRTotal() != calculateTranTypeCRTotal()"
          >
            Difference Transactione
            {{
              (calculateTranTypeDRTotal() - calculateTranTypeCRTotal()).toFixed(
                2
              )
            }}
          </div>
          <div
            class="col-md-3 bold"
            *ngIf="
              calculateClosingBalanceDRTotal() !=
              calculateClosingBalanceCRTotal()
            "
          >
            Difference Closing
            {{
              (
                calculateClosingBalanceDRTotal() -
                calculateClosingBalanceCRTotal()
              ).toFixed(2)
            }}
          </div>

          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="printtrialdataaccounthead()"
            >
              <i
                class="fa fa-print mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Print
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="div_tree">
      <div class="table-responsive" style="max-width: 100%; overflow: auto">
        <table
          mat-table
          [dataSource]="dataSourceTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Group_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <span [style.paddingLeft.px]="40"> Name </span>
            </th>
            <td mat-cell *matCellDef="let data">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="treeControl.toggle(data)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(data) ? "remove" : "add" }}
                </mat-icon>

                <!-- <span class="expnd_font">{{treeControl.isExpanded(data) ? '-' : '+'}}</span> -->
              </button>

              {{ data.Group_Name }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <span [style.paddingLeft.px]="40"> Total </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="OpeningBalanceDR">
            <th mat-header-cell *matHeaderCellDef>Opening(DR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.OpeningBalanceDR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateOpeningBalanceDR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="OpeningBalanceCR">
            <th mat-header-cell *matHeaderCellDef>Opening(CR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.OpeningBalanceCR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateOpeningBalanceCR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="TransDR">
            <th mat-header-cell *matHeaderCellDef>Transaction(DR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.TransDR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateTransDR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="TransCR">
            <th mat-header-cell *matHeaderCellDef>Transaction(CR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.TransCR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateTransCR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceDR">
            <th mat-header-cell *matHeaderCellDef>Closing(DR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.ClosingBalanceDR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateClosingBalanceDR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceCR">
            <th mat-header-cell *matHeaderCellDef>Closing(CR)</th>
            <td mat-cell *matCellDef="let data" class="text-right">
              {{ data.ClosingBalanceCR }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-right">
              {{ calculateClosingBalanceCR() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>View</th>
            <td mat-cell *matCellDef="let data">
              <span [ngClass]="{ NotShow: data.expandable }">
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetail(data)"
                  aria-label="true"
                ></i>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedCTreeolumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedCTreeolumns"></tr>
          <!-- <tr mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedCTreeolumns"></tr> -->
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedCTreeolumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
      <div class="row m-0 pt-2 pb-1">
        <div class="col-md-12">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>

          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="printtrialdata()"
            style="margin-left: 10px"
          >
            <i
              class="fa fa-print mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Print
          </button>
        </div>
        <!-- <div class="col-md-8">
          <mat-paginator #paginatorRef [pageSizeOptions]="[10,20,50]" showFirstLastButtons> </mat-paginator>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AccountingDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Accounting Statement ( {{ AccountName }} )
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseAccountingDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-0 mb-2">
          <div class="col-md-3 p-1">
            <select
              name="BalanceType"
              id="BalanceType"
              class="form-control input-text-css"
              [(ngModel)]="BalanceType"
              (change)="ChangeBalanceType()"
            >
              <option [value]="0">Annually</option>
              <option [value]="1">Monthly</option>
              <option [value]="2">Daily</option>
            </select>
          </div>
        </div>
        <div
          class="table-responsive"
          [hidden]="!DivAnnualy"
          style="max-width: 100%; overflow: auto"
        >
          <table
            class="table-bordered"
            mat-table
            matSort
            [dataSource]="dataSourceAccount"
          >
            <ng-container matColumnDef="voucherid">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_Date">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher Date
              </th>
              <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher No
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="CaseNo">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
              <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                A/C Head
              </th>
              <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
              <th mat-footer-cell *matFooterCellDef>Total</th>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                DR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeDR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeDR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                CR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeCR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeCR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                Balance
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeTotal }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeTotal() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Narration
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                View Detail
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                class="text-center"
              >
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model
                    [voucherid]="row.voucherid"
                    (close)="GetData($event)"
                    [index]="i"
                  >
                    <i
                      class="fa fa-arrow-circle-right arrow-circle action-btn"
                      aria-label="true"
                    ></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedAccountColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              [ngClass]="{ Row_Bold: row.accHead == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedAccountColumns"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedAccountColumns; sticky: true"
            ></tr>
          </table>
        </div>
        <div
          class="row m-0 pt-2 pb-1"
          style="background: #fff"
          [hidden]="!DivAnnualy"
        >
          <div class="col-md-12 text-right">
            <!-- <div *ngIf="dataSourceAccount">
              <div *ngIf="dataSourceAccount.filteredData.length >= TotRow">
                <button class="btn btn-success font-size-12" (click)="LoadMore()" [class.spinner]="loading" [disabled]="loading">
                  <i class="fa fa-spinner mr-1" style="font-size:medium;cursor: pointer;"></i> Load More
                </button>
              </div>
            </div> -->
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>

        <div class="table-responsive" [hidden]="!DivMonthly">
          <mat-table
            [dataSource]="dataSourceMonthly"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Month_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Month Year</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Month_Year }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Account Head</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.accHead }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? calculateMonthlyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetailModel(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsMonthly; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsMonthly"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsMonthly; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>

        <div class="table-responsive" [hidden]="!DivDaily">
          <mat-table
            [dataSource]="dataSourceDaily"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="VoucherDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Voucher Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Voucher_Date | date }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceDaily ? totaltypeDaily("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceDaily ? totaltypeDaily("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceDaily ? calculateDailyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="goToDetailModel(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsDaily; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsDaily"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsDaily; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="VoucherModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherModel()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="table-responsive"
          *ngIf="dataSourceModel"
          style="height: 400px; max-width: 100%; overflow: auto"
        >
          <table
            class="table-bordered"
            mat-table
            matSort
            [dataSource]="dataSourceModel"
          >
            <ng-container matColumnDef="voucherid">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let row; let i = index">
                <!-- {{ (paginatorlist.pageIndex * paginatorlist.pageSize) + (i + 1) }} -->{{
                  i + 1
                }}
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="VoucherDate">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher Date
              </th>
              <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher No
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="CaseNo">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
              <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                A/C Head
              </th>
              <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
              <th mat-footer-cell *matFooterCellDef>Total</th>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                DR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeDR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModel("TranTypeDR") }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                CR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeCR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModel("TranTypeCR") }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                Balance
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeTotal }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ totaltypeModelBal() }}
              </th>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Narration
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                View Detail
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                class="text-center"
              >
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model
                    [voucherid]="row.voucherid"
                    (close)="GetData($event)"
                    [index]="i"
                  >
                    <i
                      class="fa fa-arrow-circle-right btn_detail"
                      aria-label="true"
                    ></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsModel; sticky: true"
            ></tr>
          </table>

          <div class="row m-0 pt-2 pb-1" style="background: #fff"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="trial_balance"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Transaction(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Transaction(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closing(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closing(CR)
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>

    <!-- {{ x.Group_Name }} -->
    <td>
      <button>
        <span>+</span>
      </button>
    </td>
    <td>{{ x.OpeningBalanceDR }}</td>
    <td>{{ x.OpeningBalanceCR }}</td>
    <td>{{ x.TransDR }}</td>
    <td>{{ x.TransCR }}</td>
    <td>{{ x.ClosingBalanceDR }}</td>
    <td>{{ x.ClosingBalanceCR }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="1"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceDR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceCR() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTransDR() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTransCR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceDR() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceCR() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<div
  class="modal fade in"
  id="AccountStatementPrintModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Trail Balance
        </h6>
        <button
          type="button"
          (click)="onCloseAccountStatementPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              mat-raised-button
              class="font-size-12 button-btn ml-2"
              (click)="onDocsPrint('TrailBalanceContent', 'Trail Balance')"
            >
              Print
            </button>
          </div>
        </div>
        <div class="col-md-10 offset-1" id="TrailBalanceContent">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <!-- <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Account Statement for : {{ AccountHeadName }}
          </div> -->
          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
              "
            >
              Trail Balance for the period of -
              {{ VoucherDateFrom | date : "dd/MM/yyyy" }} to
              {{ VoucherDateTo | date : "dd/MM/yyyy" }}
            </div>
          </div>

          <div class="table-responsive mt-2">
            <div
              class="table-responsive"
              style="max-width: 100%; overflow: auto"
            >
              <table
                mat-table
                [dataSource]="dataSourceTree"
                class="mat-elevation-z8"
                matSort
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="Group_Name">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span [style.paddingLeft.px]="40"> Name </span>
                  </th>
                  <td mat-cell *matCellDef="let data">
                    <button
                      mat-icon-button
                      [style.visibility]="!data.expandable ? 'hidden' : ''"
                      [style.marginLeft.px]="data.level * 20"
                      (click)="treeControl.toggle(data)"
                    >
                      {{ treeControl.isExpanded(data) ? "-" : "+" }}

                      <!-- <span class="expnd_font">{{treeControl.isExpanded(data) ? '-' : '+'}}</span> -->
                    </button>

                    {{ data.Group_Name }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    <span [style.paddingLeft.px]="40"> Total </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="OpeningBalanceDR">
                  <th mat-header-cell *matHeaderCellDef>Opening(DR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.OpeningBalanceDR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateOpeningBalanceDR() }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="OpeningBalanceCR">
                  <th mat-header-cell *matHeaderCellDef>Opening(CR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.OpeningBalanceCR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateOpeningBalanceCR() }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="TransDR">
                  <th mat-header-cell *matHeaderCellDef>Transaction(DR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.TransDR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateTransDR() }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="TransCR">
                  <th mat-header-cell *matHeaderCellDef>Transaction(CR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.TransCR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateTransCR() }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ClosingBalanceDR">
                  <th mat-header-cell *matHeaderCellDef>Closing(DR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.ClosingBalanceDR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateClosingBalanceDR() }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ClosingBalanceCR">
                  <th mat-header-cell *matHeaderCellDef>Closing(CR)</th>
                  <td mat-cell *matCellDef="let data" class="text-right">
                    {{ data.ClosingBalanceCR }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef class="text-right">
                    {{ calculateClosingBalanceCR() }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedCTreeolumnsprint; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCTreeolumnsprint"
                ></tr>
                <!-- <tr mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedCTreeolumns"></tr> -->
                <tr
                  mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedCTreeolumnsprint; sticky: true"
                  style="background: #4dc3a3"
                ></tr>
              </table>
            </div>
            <!--<table
              id="trial_balance"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    #
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Name
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Opening(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Opening(CR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Transaction(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Transaction(CR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Closing(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Closing(CR)
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let x of JsonData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.Group_Name }}</td>
                <td>{{ x.OpeningBalanceDR }}</td>
                <td>{{ x.OpeningBalanceCR }}</td>
                <td>{{ x.TransDR }}</td>
                <td>{{ x.TransCR }}</td>
                <td>{{ x.ClosingBalanceDR }}</td>
                <td>{{ x.ClosingBalanceCR }}</td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <b>Total:</b>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateOpeningBalanceDR() }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateOpeningBalanceCR() }}</strong>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateTransDR() }}</strong>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateTransCR() }}</strong>
                </td>
                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateClosingBalanceDR() }}</strong>
                </td>

                <td
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid black;
                    text-align: right;
                  "
                >
                  <strong>{{ calculateClosingBalanceCR() }}</strong>
                </td>
              </tr>
            </table>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="trial_balance_AccountHead"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Transaction(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Transaction(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closing(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closing(CR)
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Account_Name }}</td>
    <td class="text-right">{{ x.OpeningBalanceDR }}</td>
    <td class="text-right">{{ x.OpeningBalanceCR }}</td>
    <td class="text-right">{{ x.TranTypeDR }}</td>
    <td class="text-right">{{ x.TranTypeCR }}</td>
    <td class="text-right">{{ x.ClosingBalanceDR }}</td>
    <td class="text-right">{{ x.ClosingBalanceCR }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceDRTotal() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceCRTotal() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTranTypeDRTotal() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTranTypeCRTotal() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceDRTotal() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceCRTotal() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<div
  class="modal fade in"
  id="trailbalaceaccountheadmodel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Trail Balance
        </h6>
        <button
          type="button"
          (click)="onClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              mat-raised-button
              class="font-size-12 button-btn ml-2"
              (click)="
                onDocsPrintAccounthead(
                  'TrailBalanceContentAccounthead',
                  'Trail Balance'
                )
              "
            >
              Print
            </button>
          </div>
        </div>
        <div class="col-md-10 offset-1" id="TrailBalanceContentAccounthead">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <!-- <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Account Statement for : {{ AccountHeadName }}
          </div> -->
          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
              "
            >
              Trail Balance for the period of -
              {{ VoucherDateFrom | date : "dd/MM/yyyy" }} to
              {{ VoucherDateTo | date : "dd/MM/yyyy" }}
            </div>
          </div>

          <div class="table-responsive mt-2">
            <table
              id="trial_balance_AccountHead"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <thead>
                <tr>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    #
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    Account Name
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    OP Bal(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    OP Bal(CR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    TR(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    TR(CR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    CL Bal(DR)
                  </th>
                  <th
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid white;
                    "
                  >
                    CL Bal(CR)
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.Account_Name }}</td>
                <td class="text-right">{{ x.OpeningBalanceDR }}</td>
                <td class="text-right">{{ x.OpeningBalanceCR }}</td>
                <td class="text-right">{{ x.TranTypeDR }}</td>
                <td class="text-right">{{ x.TranTypeCR }}</td>
                <td class="text-right">{{ x.ClosingBalanceDR }}</td>
                <td class="text-right">{{ x.ClosingBalanceCR }}</td>
              </tr>

              <tfoot>
                <tr>
                  <td
                    colspan="2"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateOpeningBalanceDRTotal() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateOpeningBalanceCRTotal() }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateTranTypeDRTotal() }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateTranTypeCRTotal() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateClosingBalanceDRTotal() }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateClosingBalanceCRTotal() }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
