<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <select name="FilterStatusId" id="FilterStatusId" class="form-control input-text-css" (change)="changeFilter()" [(ngModel)]="status">
        <option value="Active"> Active </option>
        <option value="Deactive"> Deactive </option>
      </select>
    </div>
    <div class="col-md-9 text-right">
      <button class="btn font-size-12 button-btn" (click)="OnAddNewProduct()"> New Product </button>
    </div>
  </div>

  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0" [hidden]="!dataSource">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="ProductId">
            <mat-header-cell mat-sort-header class="grid-header" style="max-width:40px" *matHeaderCellDef>#</mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 40px;max-height:5px">
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ProductCategory">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Product Category </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ProductCategory}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ProductName">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Product </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ProductName}} </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="ProductShortName">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Short Name </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ProductShortName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Amount_Min">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Min Amt </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Amount_Min}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Amount_Max">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Max Amt </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Amount_Max}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Tenure_Min">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Tenure Min </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Tenure_Min}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Tenure_Max">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Tenure Max </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Tenure_Max}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Rate_Min">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Interest Min </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Interest_Rate_Min}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Rate_Max">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Interest Max </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Interest_Rate_Max}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Type">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Interest Type </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Interest_Type}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Cummulative_Frequency">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Cummulative Frequency </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Cummulative_Frequency}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Payment_Frequency">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Payment Frequency </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Payment_Frequency}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="IsPreMature">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Is PreMature </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.IsPreMature}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Lockin_Period">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Lockin Period </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Lockin_Period}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <i class="fa fa-minus-circle btn_delete" (click)="onActiveDeactiveProduct(row)" aria-label="true" title="{{MSGActDct}}"></i>
              <i class="fa fa-arrow-circle-right btn_detail" (click)="goToProductDetail(row)" style=" margin-left: 16px; " aria-label="true"></i>
            </mat-cell>
          </ng-container>
          <!-- Header and Row Declarations -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div>
</div>