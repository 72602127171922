import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { ActivatedRoute, Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LosService } from "../../_LOS/services/los.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-searchapplication",
  templateUrl: "./searchapplication.component.html",
  styleUrls: ["./searchapplication.component.scss"],
})
export class SearchapplicationComponent implements OnInit {
  currentUser: any;
  private _encdec;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "ApplicationId",
    "ApplicationNo",
    "Customer",
    "Branch",
    "Product",
    "LoanAmount",
    "LoanDuration_Month",
    "ApplicationCreatedOn",
    "Application_Status",
    "EditAction",
  ];
  SearchApplicationList: any = [];
  ApplicationStatus: any = "ALL";
  ApplicationNo: any = "";
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private router: Router,
    private losService: LosService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next("Search Application");
    //this.searchApplication();
  }

  searchApplication() {
    this.showSpinner = true;
    this.losService
      .LOS_SearchApplication({
        ApplicationStatus: this.ApplicationStatus,
        ApplicationNo: this.ApplicationNo,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (res.length == 1) {
            this.goToDetail(res[0]);
          } else {
            this.SearchApplicationList = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(
              this.SearchApplicationList
            );
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
        this.showSpinner = false;
      });
  }

  goToDetail(data: any) {
    this.router.navigate([`/LOS/application/${data.ApplicationId}`]);
  }
}
