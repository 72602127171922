import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, VERSION } from "@angular/core";
import { LmsService } from "../_Lms/services/lms.service";
import { LosService } from "../_LOS/services/los.service";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { MasterService } from '../Shared/app.Masters.Service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
@Component({
  selector: "app-cibil-report-app",
  templateUrl: "./cibil-report-app.component.html",
  styleUrls: ["./cibil-report-app.component.scss"],
})
export class CibilReportAppComponent implements OnInit {
  showSpinner: boolean = false;
  CibilData: any;
  ApplicationDetail: any;
  PurposeList: any[] = [];
  foundItem: any;
  LoanId: any;
  CustomerId: any;
  private _MasterService;
  CibilAcore_List: any[] = [];
  cibilResponse: any;
  OpenWindow: Window;
  //@ViewChild('TransUnionCIBIL') TransUnionCIBIL!: ElementRef;

  constructor(private losService: LosService,
    private lmsService: LmsService,
    private _commonfunctions: CommonFunctions,
    private MasterService: MasterService,
    private route: ActivatedRoute, 

  ) {
    this._MasterService = MasterService;
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = param.get("id");// encdec.decrypt(param.get("id"));
       this.CustomerId = param.get("cid");// encdec.decrypt(param.get("cid"));
      //this.LoanId = param.get("LA-No");
    });
  }

  ngOnInit(): void {
    let ele = document.getElementById("headerId");
    ele.style.display = "none";
    let ele1 = document.getElementById("footerId");
    ele1.style.display = "none";
    this.losService
      .GET_CRC_MASTER_BY_CRCAGENCY({
        Crc_Agency: "1",
        Is_Firm: 0,
      })
      .subscribe((res: any) => {
        this.PurposeList = res;
      });
    this.losService
      .Get_Master_CibilAcore_List().subscribe((res: any) => {
        this.CibilAcore_List = res;
      });
    this.getCibilReports();
  }

  getCibilReports() {
    this.showSpinner = true;

    this._MasterService.Get_CibilAPI_Request_Response({ Loan_Id: this.LoanId, CId: this.CustomerId })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          // this.cibilResponseMain = JSON.parse(res[0].response);
          // this.lmsService.Get_Lms_CIBILReports().subscribe((result) => {
          // console.log("Cibil Data", result);
          this.CibilData = JSON.parse(res[0].response);
          console.log("Cibil Data", this.CibilData);

         
          if (this.CibilData?.consumerCreditData[0]?.accounts != undefined) {
            this.CibilData?.consumerCreditData[0]?.accounts.forEach((obj) => {
              obj.payment = [];
              obj.paymentDate = [];
              obj.onedue = [];
              obj.twodue = [];
              obj.threedue = [];
              var payDate = Object.assign(this.convertToDate(obj.paymentStartDate));
              payDate.setMonth(payDate.getMonth());
              for (let i = 0; i < obj.paymentHistory.length; i += 3) {
                obj.payment.push(obj.paymentHistory.slice(i, i + 3));

              }
              for (let i = 0; i < obj.payment.length; i++) {
                const date = new Date();
                date.setMonth(payDate.getMonth() - i);
                obj.paymentDate.push(this.formatMonthYear(date));
              }
              for (let i = 0; i < 15; i++) {
                obj.onedue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
              }
              if (obj.payment.length > 15) {
                for (let i = 15; i < 30; i++) {
                  obj.twodue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
                }
              }
              if (obj.payment.length > 30) {
                for (let i = 30; i < obj.payment.length; i++) {
                  obj.threedue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] })
                }
              }
            })
            console.log("accouts", this.CibilData?.consumerCreditData[0]?.accounts)
            this.showSpinner = false;
          }
        }
      });

  }
  formatMonthYear(date: Date): string {

    const month = date.getMonth();
    const year = date.getFullYear();
    return `${(month == 0 ? '12' : (month < 10 ? '0' + month.toString() : month.toString())) + '-' + (month == 0 ? ((year - 1).toString().substring(4, 2)) : (year.toString().substring(4, 2)))}`;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  convertToDate(mmddyyyy: string): Date {
    const day = parseInt(mmddyyyy.substring(0, 2));
    const month = parseInt(mmddyyyy.substring(2, 4)); // Months are zero-based in JavaScript
    const year = parseInt(mmddyyyy.substring(4, 8));

    return new Date(year, month, day);
  }
  findItemById(code): void {
    if (this.PurposeList.find(item => item.Code === code)) {
      return this.PurposeList.find(item => item.Code === code).Value;
    }
    else null;
  }

  findCibilScore(code, scoreName): void {

    //console.log("score", this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName))
    if (this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName)) {
      return this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName).Explanation;
    } else
      return null;
  }
  onDocsPrint(divName) {

    //var prtContent = document.getElementById(divName);
    //var WinPrint = window.open('', '_blank');
    //WinPrint.document.write(prtContent.innerHTML);
    //WinPrint.document.close();
    //WinPrint.focus();
    //WinPrint.print();
    this._commonfunctions.PrintCibil(divName, 'Cibil', new Date().getTime());
    //let printContents = this.TransUnionCIBIL.nativeElement.innerHTML;
    //let originalContents = document.body.innerHTML;

    //document.body.innerHTML = printContents;

    //window.print();

    //document.body.innerHTML = originalContents;
  }

  onCloseCibilReport() {
    $("#cibilReport").modal("hide");
  }

}
