import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../services/los.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import * as moment from "moment";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { ActivatedRoute, Params } from "@angular/router";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-losreceiptentry",
  templateUrl: "./losreceiptentry.component.html",
  styleUrls: ["./losreceiptentry.component.scss"],
})
export class LosReceiptEntryComponent implements OnInit {
  currentUser: any;
  data: any;
  selectedRowIndex: number = -1;
  SendBackToBranchList: any[] = [];
  showreceiptDetail: boolean = false;
  showSpinner: boolean = false;
  ApplicationHead: any[] = [];
  caseHead: any[] = [];
  applicationDetail: any;
  CurrentDate: any;
  CustomerId: any;
  Customer: any = {};
  loading: boolean = false;
  ReceiptDetail: any;
  customerDetail: any;
  ReceiptModel: any;
  addressModel: any;
  Voucher_Cheque_No: any;
  Type: any;
  Mode: any;
  UPI_IsActive: any = 0;
  systemconfig: any = {
    CompanyName: "",
    CompanyHeadOfficeAddress: "",
    CompanyPhoneNo: "",
    ComapnyEmail: "",
  };
  //POS: any;
  Installment: any = 0;
  dueEMIAmount: any = 0;
  Interest: any = 0;
  OtherDues: any = 0;
  CaseDetail: any;
  dataSource: any;
  AppCaseNo: any;
  CustomerType: any = "0";
  AppReceiptTotalAmount: any;
  caseDepositTotalAmount: any = 0;
  PayDepositeTotalAmount: any = 0;
  showCaseForm: boolean = false;
  showcash: boolean = false;
  showbank: boolean = false;
  showGateway: boolean = false;
  showApplicationForm: boolean = true;
  displayedColumns: string[] = [];
  displayedCustomerColumns: string[] = [];
  dataSourceCustomer: any;
  totalCalculator: any;
  chargesHeadDropdown: any[] = [];
  ReceiptEntryList: any[] = [];
  CustomerList: any[] = [];
  //PayPOS: any = 0;
  PayInstallment: any = 0;
  PayInterest: any = 0;
  PayOtherDues: any = 0;
  Application_No: any;
  LoanAcNo: any;
  CType: any;
  BranchId: any;
  qrInfo: any;
  CDate: any = new Date();
  Finalvoucheretail: any[] = [];
  AccountDetail: any[] = [];
  accountTagDropdown: any[] = [];
  //POSId: any = 27;
  InstallmentId: any = 34;
  InterestId: any = 30;
  OtherDuesId: any = 35;
  receiptNo: any;
  SelectBranchId: any;
  UPI_Id: any;
  smsmodel: any = {};
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  AmountInWord: string = "";

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  employeeDropdown: any = [];
  SelectType: any = "";
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];
  IsGSTEnable: any;
  GSTModel: any = {};
  selectedData: any = {};
  Tax_Slab_Data: any[] = [];
  Bind_Voucher: any = 1;
  AccountHeadForGST: any[] = [];
  stateDropdown: any[] = [];
  slabList: any[] = [];
  CompanyInformation: any;
  LoanId: any;
  voucherid: any;
  CompanyProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  TableData: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private losService: LosService,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this.CurrentDate = moment(new Date()).format("DD/MM/YYYY");

    this.CaseDetail = {
      OverdueEMI: "",
      OverdueEMIAmount: "",
      OverdueInterest: "",
      Ledger: "",
    };
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  getDetail(row) {
    if (this.Type == "Application") {
      this.LOS_GetApplicationDetails();
    } else {
      // this.getcaseReceiptDetail();
      this.LoanId = row.LoanId;
      this.lmsService
        .LMS_GetLoanDetails({ Loan_Id: row.LoanId })
        .subscribe((res: any) => {
          this.showSpinner = false;
          //this.ApplicationDetail = res.Item1[0];
          //this.customerData = res.Item2;

          //this.SourceAssets = res.Item6;
          //this.showLess_LoanDetail = true;

          this.ReceiptDetail = JSON.parse(JSON.stringify(res.Item1[0]));
          this.customerDetail = JSON.parse(JSON.stringify(res.Item2[0]));
          this.CustomerId = res.Item2[0].CustomerId;
          this.UPI_IsActive = row.UPI_IsActive;
          this.getChargeshead();
          this.AccountDetail = [];
          //this.getAccountDetail(this.POSId);
          this.getAccountDetail(this.InstallmentId);
          this.getAccountDetail(this.InterestId);
          this.getAccountDetail(this.OtherDuesId);

          this.losService
            .LOS_GetCustomerAddressWeb({ CustomerId: this.CustomerId })
            .subscribe((res: any) => {
              this.addressModel = res.Item2[0];
              //console.log(res.Item2[0]);
            });
        });
    }
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.SelectBranchId = params["BranchId"];
      this.initializeVoucher();
    });
    this.dataSharingService.HeaderTitle.next("Receipt Entry");

    //this.initializeVoucherDetail("0",0);
    this.ApplicationHead = [];
    this.ReceiptDetail = [];
    this.customerDetail = [];
    this.caseHead = [];
    this.addressModel = [];
    this.addMore();
    this.Type = "Application";
    this.showPaymentMode();
    this.Mode = "";
    this.AppReceiptTotalAmount = "0";
    //this.POS = "0";
    this.dueEMIAmount = "0";
    this.Interest = "0";
    this.OtherDues = "0";

    this.searchCustomer();
    this.Get_Acc_Accounts_By_Tag();

    this.Customer.CloseCase = "";
    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.Get_AccountForGST_Dropdown();
    this.GetStateDropdown();
    this.Get_TaxSlab_Dropdown();
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }
  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  LOS_GetApplicationDetails() {
    //console.log(this.AppCaseNo);
    this.losService
      .LOS_SearchApplicationByReceiptEntry({
        ApplicationNo: this.AppCaseNo,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.LoanId = res.Item1[0].ApplicationIdentity;
        this.ReceiptDetail = JSON.parse(JSON.stringify(res.Item1[0]));
        this.customerDetail = JSON.parse(JSON.stringify(res.Item2[0]));
        this.CustomerId = res.Item2[0].CustomerIdentity;
        //console.log(this.ReceiptDetail);
        //console.log(this.customerDetail);
        this.getChargeshead();
        this.losService
          .LOS_GetCustomerAddressWeb({ CustomerId: this.CustomerId })
          .subscribe((res: any) => {
            this.addressModel = res.Item2[0];
            //console.log(res.Item2[0]);
          });
        this.showSpinner = false;
      });
  }

  showPaymentMode() {
    if (this.Mode == "1") {
      this.showcash = true;
      this.showGateway = false;
      this.showbank = false;
    } else if (this.Mode == "4" || this.Mode == "3") {
      this.showcash = false;
      this.showGateway = true;
      this.showbank = false;
    } else if (this.Mode == "2") {
      this.showcash = false;
      this.showGateway = false;
      this.showbank = true;
    } else {
      this.showcash = false;
      this.showGateway = false;
      this.showbank = false;
    }
  }

  showHead() {
    if (this.Type == "Application") {
      this.showApplicationForm = true;
      this.showCaseForm = false;
    } else {
      this.showApplicationForm = false;
      this.showCaseForm = true;
    }
  }
  getcaseTotalAmount(Type: any) {
    if (Type == "Installment") {
      this.lmsService
        .lms_Check_InstallmentAmount({ CaseNo: this.AppCaseNo, VoucherId: 0 })
        .subscribe((res: any) => {
          if (res.length > 0) {
            if (res[0].Amortization == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Generate Loan No. !!!",
                ...this.configSuccess,
              });
              this.PayInstallment = "";
              this.getTotalCalculation();
              return;
            }
            if (
              parseFloat(res[0].BalanceEMIAmount) <
              parseFloat(this.PayInstallment)
            ) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data:
                  "Please" +
                  (parseFloat(res[0].BalanceEMIAmount) -
                    parseFloat(this.PayInstallment)) +
                  " Amount allocated on other Head",
                ...this.configSuccess,
              });
              this.PayInstallment = "";
              this.getTotalCalculation();
              return;
            } else {
              this.caseDepositTotalAmount =
                Number(this.Installment) +
                Number(this.Interest) +
                Number(this.OtherDues);
              this.PayDepositeTotalAmount =
                Number(this.PayInstallment) +
                Number(this.PayInterest) +
                Number(this.PayOtherDues);
              const toWords = new ToWords();
              let words = toWords.convert(Number(this.caseDepositTotalAmount));
              this.getTotalCalculation();
              this.PayInterest = Number(this.PayInterest);
              this.PayInstallment = Number(this.PayInstallment);
              this.PayOtherDues = Number(this.PayOtherDues);
            }
          }
        });
    } else {
      this.caseDepositTotalAmount =
        Number(this.Installment) +
        Number(this.Interest) +
        Number(this.OtherDues);
      this.PayDepositeTotalAmount =
        Number(this.PayInstallment) +
        Number(this.PayInterest) +
        Number(this.PayOtherDues);
      const toWords = new ToWords();
      let words = toWords.convert(Number(this.caseDepositTotalAmount));
      //console.log(words);
      this.getTotalCalculation();
      //this.PayPOS = (Number(this.PayPOS)).toFixed(2);
      /*this.PayInterest = (Number(this.PayInterest)).toFixed(2);
      this.PayInstallment = (Number(this.PayInstallment)).toFixed(2);
      this.PayOtherDues = (Number(this.PayOtherDues)).toFixed(2);*/
      this.PayInterest = Number(this.PayInterest);
      this.PayInstallment = Number(this.PayInstallment);
      this.PayOtherDues = Number(this.PayOtherDues);
    }
  }
  getAppTotalAmount() {
    if (this.ApplicationHead.length > 0) {
      this.AppReceiptTotalAmount = "0";
      let _prev = this.ApplicationHead[this.ApplicationHead.length - 1];
      _prev.total =
        Number(_prev.amount) + Number(_prev.CGST) + Number(_prev.SGST);
      for (let i = 0; i < this.ApplicationHead.length; i++) {
        this.AppReceiptTotalAmount =
          Number(this.AppReceiptTotalAmount) +
          Number(this.ApplicationHead[i].total);
      }
      const toWords = new ToWords();
      let words = toWords.convert(Number(this.AppReceiptTotalAmount));
      //console.log(words);
    }
  }
  addMore() {
    if (this.ApplicationHead.length > 0) {
      let _prev = this.ApplicationHead[this.ApplicationHead.length - 1];
      if (
        _prev.head == "" ||
        _prev.amount == "" ||
        _prev.CGST == "" ||
        _prev.SGST == ""
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.ApplicationHead.push({
      head: "",
      amount: "",
      CGST: "",
      SGST: "",
    });
  }

  remove(index: any) {
    this.ApplicationHead.splice(index, 1);
    this.getAppTotalAmount();
  }
  searchCustomer() {
    this.dataSourceCustomer = null;
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
  }

  onCloseReceipt() {
    $("#CustomerReceipt").modal("hide");
  }

  onOkReceipt() {
    $("#CustomerReceipt").modal("hide");
    this.showreceiptDetail = true;
    // this.ReceiptDetail = { fullName: "Ajay Gautam", address: "Jagatpura", Phone: "84747747444", email: "abc@gmail.com" };
    //if () {
    // this.CaseDetail = { POS: "122", OverdueEMI: "3", OverdueEMIAmount: "200000", OverdueInterest: "2.5", Ledger: "857389753" }
    //}
  }
  onSearchCustomerDetail() {
    this.showSpinner = true;

    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          if (res.length == 1) {
            this.goToDetail(res[0]);
          } else {
            this.displayedCustomerColumns = [
              "CustomerId",
              "Type",
              "Application_No",
              "LoanAcNo",
              "CustomertName",
              "FatherName",
              "Customer_Gender",
              "GenderAge",
              "PhoneNo",
              "EditAction",
            ];

            this.CustomerList = JSON.parse(JSON.stringify(res));
            if (this.CustomerType != "0") {
              var type;
              if (this.CustomerType == "1") type = "LOS";
              else type = "LMS";
              this.CustomerList = this.CustomerList.filter(
                (item) => item.Type == type
              );
            }
            this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
            this.dataSourceCustomer.sort = this.sort;
            this.dataSourceCustomer.paginator = this.paginator;
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceCustomer = null;
        }
        this.showSpinner = false;
      });
  }
  goToDetail(row) {
    this.showSpinner = true;
    this.Finalvoucheretail = [];
    this.onCloseReceipt();
    this.voucherModel.Voucher_Detail = [];
    //this.PayPOS = 0.00;
    this.PayInstallment = 0.0;
    this.PayInterest = 0.0;
    this.PayOtherDues = 0.0;
    if (row.LoanAcNo == "" || row.LoanAcNo == null)
      this.AppCaseNo = row.Application_No;
    else {
      this.AppCaseNo = row.LoanAcNo;
      this.UPI_Id = this.currentUser.UPI_Prefix + row.LoanAcNo + "@icici";
    }
    this.CType = row.Type;

    if (row.Type == "LOS" || row.Type == "QuickLoan") this.Type = "Application";

    if (row.Type == "LMS") this.Type = "case";

    this.getDetail(row);

    this.showHead();
    this.Application_No = row.Application_No;
    this.LoanAcNo = row.LoanAcNo;
    this.CustomerId = row.CustomerId;
    this.BranchId = row.BranchId;
    this.SelectBranchId = row.BranchId;

    this.selectedData.StateId = row.StateId;
    this.selectedData.Customer = row.CustomertName;
    this.selectedData.GST_No = row.GST_No;
    this.selectedData.Branch_StateId = row.Branch_StateId;
    this.losService
      .getEmployeeDropdown({ BranchId: row.BranchId, ProcessId: 0 })
      .subscribe((res: any) => {
        this.employeeDropdown = res;
      });
    this.initializeVoucher();
    //this.initializeVoucherDetail();
    if (row.Type == "LOS" || row.Type == "QuickLoan")
      this.initializeVoucherDetail();
    this.lmsService
      .Get_Receipt_Entry({
        ApplicationNo: row.Application_No,
        Loan_No: row.LoanAcNo,
        VoucherDate: new Date(),
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.ReceiptEntryList = JSON.parse(JSON.stringify(res));
          //console.log(this.ReceiptEntryList.filter(item => item.HeadId == this.POSId));
          //if (this.ReceiptEntryList.filter(item => item.HeadId == this.POSId).length > 0) {
          //  this.POS = this.ReceiptEntryList.filter(item => item.HeadId == this.POSId)[0].Balance;
          //}
          //else {
          //  this.POS = 0.00;
          //}
          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InstallmentId
            ).length > 0
          ) {
            this.Installment = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InstallmentId
            )[0].Balance;
          } else {
            this.Installment = 0.0;
          }

          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InterestId
            ).length > 0
          ) {
            this.Interest = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InterestId
            )[0].Balance;
          } else {
            this.Interest = 0.0;
          }

          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.OtherDuesId
            ).length > 0
          ) {
            this.OtherDues = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.OtherDuesId
            )[0].Balance;
          } else {
            this.OtherDues = 0.0;
          }

          this.caseHead = [];
          this.caseHead.push({
            //  POS: this.POS,
            Installment: parseFloat(this.Installment).toFixed(2),
            Interest: parseFloat(this.Interest).toFixed(2),
            OtherDues: parseFloat(this.OtherDues).toFixed(2),
          });
          this.getcaseTotalAmount("");
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
    //this.ApplicationNo = row.Application_No;
    //this.LoanNo = row.LoanAcNo;
    //this.onSearchStatementDetail();
    //this.lmsService
    //  .LMS_GetLoanDetails({
    //    Loan_Id: row.LoanId
    //  })
    //  .subscribe((res: any) => {
    //    this.showSpinner = false;
    //    this.ApplicationDetail = res.Item1[0];
    //    this.customerData = res.Item2;

    //    this.SourceAssets = res.Item6;
    //    this.showLess_LoanDetail = true;
    //  });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }

    if (this.IsGSTEnable) {
      //this.Tax_Slab_Data = null;
      $("#GSTModalWindow").modal("show");
      $("#GSTModalWindow").css("z-index", "1050");

      this.GSTModel = {
        Slab_Tax: "",
        PartyType: "Existing_Party",
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        StateId: this.selectedData.StateId,
        Charge_head: data.ChargeHeadCategory,
        Amount: data.PayAmount,
      };
    }

    /*data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = (parseInt(data.PayAmount) * obj.TaxRate) / 100;
    });
    data.PayAmount = (Number(data.PayAmount)).toFixed(2);*/
    this.getTotalCalculation();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    this.getTotalCalculation();
    this.Tax_Slab_Data = null;
  }
  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherDetail();
  }
  getTotalCalculation() {
    let _total: any = Number(this.caseDepositTotalAmount);
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    let _payTotal: any = Number(this.PayDepositeTotalAmount);
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      _total = parseFloat(_total + Number(obj.Amount)).toFixed(2) || 0;
      _payTotal = Number(_payTotal) + Number(obj.PayAmount) || 0;
      _totalCR = _totalCR + Number(obj.Amount) || 0;
      obj.TaxSlab1.forEach((tobj: any) => {
        _payTotal = Number(_payTotal) + Number(tobj.TaxAmount) || 0;
        _totalTX = Number(_totalTX) + Number(tobj.TaxAmount) || 0;
      });
    });
    var Words = new ToWords().convert(Math.round(_payTotal)).split("Point");
    var Word2 = Words[1] != undefined ? Words[1] : "zero";
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      payTotal: parseFloat(_payTotal.toFixed(2)),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
      payTotalInWord: Words[0] + " rupees.",
    };
    //console.log("payTotalInWord : ", this.totalCalculator.payTotalInWord);
    //console.log("ToWords : ", new ToWords().convert(Number(_payTotal)).split("Point"));
  }
  initializeVoucherDetail() {
    var Is_LOSAppliaction = 0;
    if (this.CType == "LMS") Is_LOSAppliaction = 0;
    if (this.CType == "LOS") Is_LOSAppliaction = 1;
    var caseno;
    if (
      this.LoanAcNo != "" &&
      this.LoanAcNo != null &&
      this.LoanAcNo != undefined
    )
      caseno = this.LoanAcNo;
    else caseno = this.Application_No;
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: caseno,
      CustomerId: this.CustomerId,
      Is_LOSAppliaction: Is_LOSAppliaction,
      ChargeHeadId: "",
      PayAmount: "",
      AccountId: "",
      BranchId: this.BranchId,
      TranType: "CR",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: [],
      TaxSlab1: [],
      Bind_Voucher: this.Bind_Voucher,
      Type: "",
    });
  }
  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == "Cheque") {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    } else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.ReceiptDetail.ProductId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res.filter((x) => x.Id != 27);
      });
  }
  onChangeChargesHead(data: any, dindex: any) {
    let ChargeData, ChargeId;
    ChargeData = this.chargesHeadDropdown.filter(
      (x) => x.ChagesHead == data.ChargeHeadCategory
    );
    if (ChargeData.length > 0) {
      ChargeId = ChargeData[0].Id;
      data.ChargeHeadId = ChargeId;
      this.lmsService
        .Get_Receipt_Entry({
          ApplicationNo: this.Application_No,
          Loan_No: this.LoanAcNo,
          VoucherDate: new Date(),
          HeadId: ChargeId,
        })
        .subscribe((res: any) => {
          if (res.length > 0) {
            data.Amount = res[0].Balance;
            this.getTotalCalculation();
          } else {
            data.Amount = 0.0;
            this.getTotalCalculation();
          }

          let _find = this.voucherModel.Voucher_Detail.find(
            (obj: any, index: any) => {
              return (
                index != dindex &&
                obj.ChargeHeadCategory == data.ChargeHeadCategory
              );
            }
          );
          if (
            (_find ||
              ChargeId == this.InstallmentId ||
              ChargeId == this.InterestId ||
              ChargeId == this.OtherDuesId) &&
            !this.showApplicationForm
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "This Account head already selected.",
              ...this.configSuccess,
            });
            data.ChargeHeadId = "";
            data.Amount = "";
            data.PayAmount = "";
            data.AccountId = "";
            data.ChargeHeadCategory = "";
            data.TaxSlab = [];
            //this.getTotalCalculation();
            if (this.voucherModel.Voucher.Voucher_Sub_Type == "Accounting") {
              data.BranchId = "";
            }
            //this.getTotalCalculation();
            return;
          }
          data.PayAmount = "";
          data.TaxSlab = [];
          data.TaxSlab1 = [];

          this.lmsService
            .Get_AccountHead_For_VoucherEntry({
              HeadId: ChargeId,
              VoucherSubType: "Loan",
              ProductId: this.ReceiptDetail.ProductId,
              TaxType: "CR",
            })
            .subscribe((res: any) => {
              if (res.Item1[0]) {
                data.AccountId = res.Item1[0].AccountId;
                //data.ChargeHeadCategory = res.Item1[0].AccountName;
                data.TaxSlab = res.Item2;
                this.IsGSTEnable = res.Item1[0].IsGSTEnable;
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "No data Found.",
                  ...this.configSuccess,
                });
              }
            });
        });
    } else {
      data.ChargeHeadId = ChargeId;
      data.ChargeHeadCategory = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Account Head not found",
        ...this.configSuccess,
      });
      return;
    }
  }
  initializeVoucher() {
    this._MasterService
      .GetReceiptNo({
        BranchId: this.SelectBranchId,
      })
      .subscribe((res: any) => {
        this.receiptNo = res[0].ReceiptNo;
        this.voucherModel.Voucher = {
          VoucherId: 0,
          Voucher_Type: "Receipt",
          Voucher_Sub_Type: "Loan",
          Voucher_Tag: "Bank",
          Collection_By: this.currentUser.userId,
          Voucher_Date: new Date(),
          DRAccountId: "",
          Voucher_Mode_of_Payment: "",
          Voucher_Total_Credit_Amount: "",
          Voucher_Total_Debit_Amount: "",
          Voucher_Total_Tax: "",
          Voucher_Narration: "",
          Voucher_Cheque_No: "",
          Voucher_Cheque_Date: "",
          Voucher_Bank_Name: "",
          Voucher_Cheque_Clear_Date: "",
          Voucher_E_Transaction_Date: "",
          Voucher_E_Ref_No: "",
          Voucher_ReceiptNo_Book: this.receiptNo,
          Voucher_ReceiptNo_Manual: "",
          Voucher_ReceiptDate: new Date(),
          LoginUserId: this.currentUser.userId,
        };
        setTimeout(() => {
          this.GetVoucherNo();
          this.Get_Acc_Accounts_By_Tag();
        }, 500);
      });
  }
  GetVoucherNo() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: this.voucherModel.Voucher.Voucher_Type,
        VoucherSubType: "Loan",
        VoucherDate: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
      });
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        LoginUserId: this.data.userId,
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  getAccountDetail(ChargeHeadId) {
    var data = { AccountId: 0, ChargeHeadCategory: "" };
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: ChargeHeadId,
        VoucherSubType: "Loan",
        ProductId: this.ReceiptDetail.ProductId,
        TaxType: "CR",
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          //data.ChargeHeadId = ChargeHeadId;
          this.AccountDetail.push({
            AccountId: data.AccountId,
            ChargeHeadCategory: data.ChargeHeadCategory,
            ChargeHeadId: ChargeHeadId,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
        //console.log(this.AccountDetail);
      });
  }
  getVoucherDetail(ChargeHeadId, Amount) {
    let _find = this.AccountDetail.find((obj: any) => {
      return obj.ChargeHeadId == ChargeHeadId;
    });
    var Is_LOSAppliaction = 0;
    if (this.CType == "LMS") Is_LOSAppliaction = 0;
    if (this.CType == "LOS") Is_LOSAppliaction = 1;
    var caseno;
    if (this.LoanAcNo != "" && this.LoanAcNo != null) caseno = this.LoanAcNo;
    else caseno = this.Application_No;
    this.Finalvoucheretail.push(
      this.dataSharingService.cleanObject({
        VoucherId: 0,
        CaseNo: caseno,
        CustomerId: this.CustomerId,
        Is_LOSAppliaction: Is_LOSAppliaction,
        ChargeHeadId: ChargeHeadId,
        ChargeHeadCategory: _find.ChargeHeadCategory,
        AccountId: _find.AccountId,
        BranchId: this.BranchId,
        TranType: "CR",
        IsTax: 0,
        Tax_Per: "",
        Amount: Amount,
      })
    );
  }
  saveVoucher() {
    if (this.totalCalculator.payTotal > 0) {
      this.Finalvoucheretail = [];
      this.voucherModel.Voucher.Voucher_Type = "Receipt";
      this.voucherModel.Voucher.Voucher_Sub_Type = "Loan";

      //if (Number(this.PayPOS) > 0)
      //  this.getVoucherDetail(this.POSId, this.PayPOS);
      if (Number(this.PayInstallment) > 0 && this.PayInstallment != null)
        this.getVoucherDetail(this.InstallmentId, this.PayInstallment);
      if (Number(this.PayInterest) > 0 && this.PayInterest != null)
        this.getVoucherDetail(this.InterestId, this.PayInterest);
      if (Number(this.PayOtherDues) > 0 && this.PayOtherDues != null)
        this.getVoucherDetail(this.OtherDuesId, this.PayOtherDues);

      this.FinalVoucherSave();
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill Amount!!",
        ...this.configSuccess,
      });
      return;
    }
  }

  FinalVoucherSave() {
    if (
      this.voucherModel.Voucher.Voucher_Sub_Type == "Loan" &&
      this.voucherModel.Voucher.Voucher_Tag == "CASH"
    ) {
      this.lmsService
        .Check_Cash_Receipt_and_Payment_Validation({
          customer_id: this.CustomerId,
          FYearId: 0,
          Account_Id: 0,
          VoucherType: "Loan_Receipt",
          LoginUserId: this.currentUser.userId,
          Amount: this.totalCalculator.payTotal,
        })
        .subscribe((res: any) => {
          if (res.length > 0 && res[0].CODE == -1) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            return;
          }
          if (res.length > 0 && res[0].CODE == 0) {
            this.ReceiptVoucherSave();
          }
        });
    } else {
      this.ReceiptVoucherSave();
    }
  }
  ReceiptVoucherSave() {
    let _vouvher: any = { ...this.voucherModel.Voucher };
    //let _voucheretail: any[] = [];
    let _find = this.accountTagDropdown.find((obj: any) => {
      return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
    });
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;

    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      //_total = _total + obj.PayAmount;
      //_totalCR = _totalCR + obj.PayAmount;
      this.Finalvoucheretail.push(
        this.dataSharingService.cleanObject({
          VoucherId: obj.VoucherId,
          CaseNo: obj.CaseNo,
          CustomerId: obj.CustomerId,
          Is_LOSAppliaction: obj.Is_LOSAppliaction,
          ChargeHeadId: obj.ChargeHeadId,
          ChargeHeadCategory: obj.ChargeHeadCategory,
          AccountId: obj.AccountId,
          BranchId: obj.BranchId,
          TranType: obj.TranType,
          IsTax: obj.IsTax,
          Tax_Per: obj.Tax_Per,
          Amount: obj.PayAmount,
          GST_Type: obj.TaxSlab1.length > 0 ? this.GSTModel.PartyType : "",
          GST_No: obj.TaxSlab1.length > 0 ? this.GSTModel.Acc_GSTNo : "",
          Party_AccountName:
            obj.TaxSlab1.length > 0 ? this.GSTModel.Customer : "",
          HSN_Code: obj.TaxSlab1.length > 0 ? this.GSTModel.HSNCode : "",
          Slab_Name: obj.TaxSlab1.length > 0 ? this.GSTModel.Slab_Tax : "",
          Bind_Voucher: obj.Bind_Voucher,
          Type: this.GSTModel.TaxType,
        })
      );
      obj.TaxSlab1.forEach((tobj: any) => {
        //_total = _total + tobj.TaxAmount;
        //_totalTX = _totalTX + tobj.TaxAmount;
        this.Finalvoucheretail.push(
          this.dataSharingService.cleanObject({
            VoucherId: obj.VoucherId,
            CaseNo: obj.CaseNo,
            CustomerId: obj.CustomerId,
            Is_LOSAppliaction: obj.Is_LOSAppliaction,
            ChargeHeadId: obj.ChargeHeadId,
            ChargeHeadCategory: tobj.TaxSlab_Type,
            AccountId: tobj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.TranType,
            IsTax: 1,
            Tax_Per: tobj.TaxRate,
            Amount: tobj.TaxAmount,
            Bind_Voucher: obj.Bind_Voucher,
            Type: "",
          })
        );
      });
    });

    this.Finalvoucheretail.push(
      this.dataSharingService.cleanObject({
        VoucherId: 0,
        CaseNo: "",
        CustomerId: "",
        Is_LOSAppliaction: "",
        ChargeHeadId: "",
        ChargeHeadCategory: _find.Account_Name,
        AccountId: _find.AccountId,
        BranchId: "",
        TranType: "DR",
        IsTax: 0,
        Tax_Per: 0,
        Amount: this.totalCalculator.payTotal,
        Bind_Voucher: this.Bind_Voucher + 1,
      })
    );

    _vouvher.Voucher_Total_Credit_Amount = this.totalCalculator.payTotal;
    _vouvher.Voucher_Total_Debit_Amount = this.totalCalculator.payTotal;
    _vouvher.Voucher_Total_Tax = this.totalCalculator.totaltax;
    _vouvher.Origin = "WEB";

    this.Finalvoucheretail = this.Finalvoucheretail.filter(
      (item) => item.Amount != 0 && item.Amount != null
    );

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: this.Finalvoucheretail,
    };
    var LoanId = this.LoanId;
    var totalCalculator = this.totalCalculator;
    var addressModel = this.addressModel;
    var customerDetail = this.customerDetail;
    var LoanAcNo = this.LoanAcNo;
    var receiptNo = this.receiptNo;
    var Application_No = this.Application_No;
    var Voucher_Cheque_No = this.voucherModel.Voucher.Voucher_Cheque_No;

    this.lmsService
      .Acc_Save_Receipt_Voucher({
        JSON: JSON.stringify(_finalData),
        BranchId: this.BranchId,
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          //this.initializeVoucher();
          this.voucherModel.Voucher_Detail = [];
          //this.initializeVoucherDetail();
          this.totalCalculator = undefined;
          this.Finalvoucheretail = [];
          this.ReceiptDetail = [];
          this.AppCaseNo = "";
          this.customerDetail = [];
          this.addressModel = [];
          this.showCaseForm = false;
          this._MasterService.GetConfigurationDetails().subscribe((result) => {
            this.systemconfig = JSON.parse(JSON.stringify(result))[0];
            this.totalCalculator = totalCalculator;
            this.addressModel = addressModel;
            this.customerDetail = customerDetail;
            this.LoanId = LoanId;
            if (LoanAcNo == "" || LoanAcNo == null) {
              this.LoanAcNo = "";
            } else this.LoanAcNo = LoanAcNo;
            this.Application_No = Application_No;
            this.Voucher_Cheque_No = Voucher_Cheque_No;
            this.receiptNo = res[0].R_No;
            // $("#ReceiptPrint").modal("show");
            // $("#ReceiptPrint").css("z-index", "1050");
            this.saveReceipt(res[0].CODE);
            this.showSpinner = false;
            this._MasterService
              .Get_ValuesOf_SendSMS({ ApplicationNo: this.Application_No })
              .subscribe((resSMS: any) => {
                if (resSMS.length > 0) {
                  var Result_SMS = resSMS;
                  this._MasterService
                    .Get_Sms_Configration({ Step_Name: "Receipt_Deposit" })
                    .subscribe((res: any) => {
                      if (res.length > 0) {
                        var loanno;
                        if (this.LoanAcNo == "")
                          loanno = "or App No." + this.Application_No;
                        else loanno = "or App No." + this.LoanAcNo;
                        this.smsmodel.MobileNo = Result_SMS[0].Customer_PhoneNo;
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@customer",
                          Result_SMS[0].Customer
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@Amount",
                          this.totalCalculator.payTotal
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@RcptNo",
                          this.receiptNo
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@LoanAcNo",
                          loanno
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@RcptDate",
                          this.CurrentDate
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "@ExeMoNo",
                          Result_SMS[0].Branch_PhoneNo
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "\r",
                          ""
                        );
                        res[0].SMS_Template = res[0].SMS_Template.replace(
                          "\n",
                          ""
                        );

                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[KEY]",
                          res[0].Key
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[CAMPAIGN]",
                          res[0].campaign
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[ROUTE]",
                          res[0].route
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[TYPE]",
                          res[0].SubType
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[MOBILENO]",
                          Result_SMS[0].Customer_PhoneNo
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[SENDERID]",
                          res[0].Sender_Id
                        );
                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[MSG]",
                          res[0].SubType == "unicode"
                            ? this._MasterService.utf8Encode(
                                res[0].SMS_Template
                              )
                            : res[0].SMS_Template
                        );

                        res[0].Sub_url = res[0].Sub_url.replace(
                          "[TEMPLATE_ID]",
                          res[0].TemplateId
                        );
                        res[0].Sub_url = res[0].Sub_url.replace("\r", "");
                        res[0].Sub_url = res[0].Sub_url.replace("\n", "");

                        // key = [KEY] & campaign=[CAMPAIGN] & routeid=[ROUTE] & type=[TYPE] & contacts=[MOBILENO] &
                        // senderid=[SENDERID] & msg=[MSG] & template_id=[TEMPLATE_ID]
                        this.smsmodel.sentMessage = res[0].SMS_Template;
                        //this.smsmodel.key = res[0].Key;
                        //this.smsmodel.route = res[0].route;
                        //this.smsmodel.Sender_Id = res[0].Sender_Id;
                        this.smsmodel.SMS_URL = res[0].SMS_URL;
                        this.smsmodel.Sub_url = res[0].Sub_url;

                        //console.log(" this.smsmodel", this.smsmodel);
                        this._MasterService
                          .MessageSend(this.smsmodel)
                          .subscribe((res: any) => {});
                      }
                    });
                }
              });
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onCloseReceiptPrint() {
    $("#ReceiptPrint").modal("hide");
    this.totalCalculator = undefined;
    this.initializeVoucher();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    this.systemconfig = {};
    this.totalCalculator = {};
    this.addressModel = [];
    this.customerDetail = [];
    this.LoanAcNo = "";
    this.receiptNo = "";
    this.Finalvoucheretail = [];
  }
  printLetter() {
    $("#ReceiptPrint").modal("hide");
    // this.loading = true;
    setTimeout(() => {
      this.downloadPdf();
    }, 1000);
  }
  downloadPdf() {
    // var data = document.getElementById('pdfSenctionDownload');
    var prtContent = document.getElementById("pdfReceiptDownload");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }

  OnCloseGSTModal() {
    $("#GSTModalWindow").modal("hide");
  }

  Get_AccountForGST_Dropdown() {
    this._MasterService
      .Get_AccountForGST_Dropdown({ LoginUserId: this.currentUser.userId })
      .subscribe((res: any) => {
        this.AccountHeadForGST = res;
      });
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;
      this.slabList = this.slabList.reduce((acc, current) => {
        const x = acc.find(
          (item) => item.TaxSlab_Name === current.TaxSlab_Name
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    });
  }

  onPartyTypeChange() {
    if (this.GSTModel.PartyType == "New_Party") {
      this.GSTModel = {
        PartyType: this.GSTModel.PartyType,
        Slab_Tax: "",
        AccountHeadId: "",
        Customer: "",
        Acc_GSTNo: "",
        StateId: "",
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType,
      };
    } else {
      this.GSTModel = {
        Slab_Tax: "",
        AccountHeadId: "",
        PartyType: this.GSTModel.PartyType,
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        StateId: this.selectedData.StateId,
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType,
      };
    }
  }
  onAccountHeadChange() {
    let data = this.AccountHeadForGST.filter(
      (item) => item.AccountId == this.GSTModel.AccountHeadId
    );

    this.GSTModel = {
      AccountHeadId: this.GSTModel.AccountHeadId,
      Slab_Tax: this.GSTModel.Slab_Tax,
      Acc_GSTNo: data[0].Acc_GSTNo,
      StateId: data[0].StateId,
      HSNCode: data[0].HSNCode,
      Account_Name: data[0].Account_Name,
      PartyType: this.GSTModel.PartyType,
      Amount: this.GSTModel.Amount,
    };
  }

  onChangeTax() {
    this.getTotalCalculation();
  }

  saveGSTDetails() {
    $("#GSTModalWindow").modal("hide");
    this.lmsService
      .Get_TaxSlab_Details({ TaxSlab: this.GSTModel.Slab_Tax })
      .subscribe((res: any) => {
        if (this.selectedData.Branch_StateId == this.GSTModel.StateId) {
          this.Tax_Slab_Data = res.filter(
            (item) =>
              item.TaxType == "Output" && item.IsInterState == "With in State"
          );
        } else {
          this.Tax_Slab_Data = res.filter(
            (item) =>
              item.TaxType == "Output" && item.IsInterState == "Inter state"
          );
        }

        this.GSTModel.TaxType = this.Tax_Slab_Data[0].TaxType;

        this.Tax_Slab_Data.forEach((x) => {
          x.BranchName = this.selectedData.Branch_Name;
          x.Amount = this.GSTModel.Amount;
          x.TaxAmount = (
            (parseFloat(this.GSTModel.Amount) * parseFloat(x.TaxSlab_Rate)) /
            100
          ).toFixed(2);

          // Search value (SGST) from a string
          x.TypeGST = x.TaxSlab_Type.includes("SGST");
          x.TypeIGST = x.TaxSlab_Type.includes("IGST");
        });

        this.voucherModel.Voucher_Detail.forEach((x) => {
          if (x.TaxSlab.length > 0 && x.Bind_Voucher == this.Bind_Voucher) {
            x.TaxSlab1 = this.Tax_Slab_Data;
          }
        });
        this.getTotalCalculation();
      });
  }
  GetQR() {
    this.qrInfo =
      "upi://pay?pa=" +
      this.currentUser.UPI_Prefix +
      this.AppCaseNo +
      "@icici&pn=Finnaux&tr=&am=&cu=INR&mc=5411";
    $("#QRModalWindow").modal("show");
    $("#QRModalWindow").css("z-index", "1050");
  }
  OnCloseQRModal() {
    $("#QRModalWindow").modal("hide");
  }

  applyFilter(filterValue: string) {
    this.dataSourceCustomer.filter = filterValue.trim().toLowerCase();
  }

  saveReceipt(voucherid) {
    // this.showSpinner = true; this.voucherModel
    console.log("dgdg", this.voucherModel);

    this.lmsService
      .GSTReceiptDetails({ LoanId: this.LoanId, VoucherId: voucherid })
      .subscribe((result: any) => {
        console.log("Receipt Details : ", result);
        this.CompanyInformation = result.Item1[0];
        this.TableData = result.Item2.filter(
          (originalData) => originalData.TranType == "CR"
        );

        // console.log("Company Information",this.CompanyInformation)

        this.AmountInWord = new ToWords().convert(
          parseInt(this.totaltypewisemodal("Total"))
        );

        $("#ReceiptBill").modal("show");
        $("#ReceiptBill").css("z-index", "1050");
      });
  }

  OnCloseVoucherModel() {
    $("#ReceiptBill").modal("hide");
  }

  totaltypewisemodal(row): string {
    // console.log("JSON Data",this.JsonData2);

    if (this.TableData != null) {
      let sum = 0;
      for (let i = 0; i < this.TableData.length; i++) {
        sum += Number(this.TableData[i][row]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    // return 0;
    return "0";
  }

  PrintDoc(divName, Header) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
        
     

          @media print {
            @page {
              
                size: A4;
                margin: 10px 20px 20px 20px; 
                content: counter(page) "/" counter(pages);

         
             
              
            }
            #foot {
              content: counter(page) "/" counter(pages);
              counter-increment: page;
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
            
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

.invoice {
  margin-top:35px;
  max-height: 100%;
  max-width: 100%;
  // margin: 0 auto;
  /* padding: 10px; */
  border: 2px solid black;
  color:black;
}

 .header {
  background-color: lightblue;
  height: 40px;
  border-bottom: 1px solid black;
  
} 

.header h2 {
  text-align: center;
  margin:0px;
  font-weight: 500;
}
  




.company-info {
  /* display: flex; */
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #000000;
  height: 180px
}
  
  
  
.info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: 130px;
}

.company-name {
  text-align: center;
  word-wrap: normal;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: bold;
  font-size: 24px;
  margin-right: 110px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  
}
.company-details{
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  // margin-right: 20px;
  top: 2px;
  right: 65px;
  
 
}
.logo {
  margin-top: 40px;
  // margin-left: 10px;
  position: absolute;
  top: 65px;
}

.logo img {
  max-height: 50px;
}

.party-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
  justify-content: start;
}
.party-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-right: 1px solid black;
  padding-right: 300px;
}

.invoice-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom:0px;

}
.invoice-no{
  display: flex;
  justify-content: space-between;

}
.invoice-details p {
  text-align: right;
 
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: right;
  width: 56.72px;
}

.invoice-table th {
  background-color: #f0f0f0;
}
.Total-Amount{
  /* width:800px;
  height:70px;
  border:1px solid black; 
  margin: 40px; 
  position: absolute;
  left: 240px; */
  margin-bottom: 50px;
}
.amount{
  text-decoration: underline;
  margin:5px;
}

.footer{
  position:relative;
  top:5px;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  padding:10px;
  align-items: end;
}
.created{
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  width:950px;
  /* margin: 15px; */
  position: relative;
  top:40px;
  
}

/* .created span{
  margin:10px;
} */


.signature{
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  bottom: 10px;
}
 

#createdOn{
  margin-top:15px;
  margin-bottom: 15px;
}

#sign{
  margin-top:30px;
  margin-left:0px;
}
         
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}
        <footer></footer>
        </body>
      </html>`);
    popupWin.document.close();
  }
}
