<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="geSchemeList()"
        [(ngModel)]="ProductId"
      >
        <option value="">Select Products</option>
        <option
          *ngFor="let product of productDropdown"
          [value]="product.ProductId"
        >
          {{ product.Product_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1"></div>
    <div class="col-md-6 p-1 text-right my-1 pr-2">
      <a
        class="text-underling-cursor"
        href="javascript:void(0)"
        (click)="onAddScheme({}, $event)"
        *ngIf="ProductId"
      >
        Add a new scheme
      </a>
    </div>
  </div>
  <div class="table-responsive" *ngIf="ProductId">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[
        displayedColumns.indexOf('Edit'),
        displayedColumns.indexOf('Delete')
      ]"
    >
      <ng-container matColumnDef="SchemeId">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw50"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell mw50"
          style="max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Scheme">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw300"
          *matHeaderCellDef
        >
          Scheme
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw300">
          {{ row.Scheme }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw200"
          *matHeaderCellDef
        >
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw200">
          {{ row.Product }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Period">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw400"
          *matHeaderCellDef
        >
          Period
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw400">
          {{ row.Period }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Amount">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw400"
          *matHeaderCellDef
        >
          Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw400">
          {{ row.Amount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ROI">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw100"
          *matHeaderCellDef
        >
          ROI (%)
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw100">
          {{ row.ROI }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SchemeState">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw200"
          *matHeaderCellDef
        >
          Scheme State</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell mw200">
          {{ row.SchemeState }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AdvanceEMI">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw100"
          *matHeaderCellDef
        >
          AdvanceEMI
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw100">
          {{ row.AdvanceEMI }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell
          mat-sort-header
          class="grid-header mw100"
          *matHeaderCellDef
        >
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell mw100">
          {{ row.Status }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Edit">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center mw50"
          *matHeaderCellDef
        >
          Edit
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center mw50">
          <i
            class="fa fa-edit"
            (click)="onAddScheme(row, $event)"
            style="cursor: pointer; color: green; font-size: 16px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Delete">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center mw50"
          *matHeaderCellDef
        >
          Delete
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell j-c-center mw50">
          <i
            class="fa fa-trash style-delete"
            (click)="onDeleteScheme(row, $event)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="schemeModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveScheme()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Scheme Details
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable"> Scheme Name </span>
              <input
                required
                type="text"
                #refScheme_Name="ngModel"
                [(ngModel)]="schemeModel.Scheme_Name"
                placeholder="Scheme Name"
                name="Scheme_Name"
                id="Scheme_Name"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_Name.invalid,
                  'alert-warning':
                    refScheme_Name.invalid &&
                    (refScheme_Name.dirty ||
                      refScheme_Name.touched ||
                      refScheme_Name.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme MinAmount </span>
              <input
                required
                type="number"
                #refScheme_MinAmount="ngModel"
                [(ngModel)]="schemeModel.Scheme_MinAmount"
                placeholder="Scheme MinAmount"
                name="Scheme_MinAmount"
                id="Scheme_MinAmount"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_MinAmount.invalid,
                  'alert-warning':
                    refScheme_MinAmount.invalid &&
                    (refScheme_MinAmount.dirty ||
                      refScheme_MinAmount.touched ||
                      refScheme_MinAmount.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme MaxAmount </span>
              <input
                required
                type="number"
                #refScheme_MaxAmount="ngModel"
                [(ngModel)]="schemeModel.Scheme_MaxAmount"
                placeholder="Scheme MaxAmount"
                name="Scheme_MaxAmount"
                id="Scheme_MaxAmount"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_MaxAmount.invalid,
                  'alert-warning':
                    refScheme_MaxAmount.invalid &&
                    (refScheme_MaxAmount.dirty ||
                      refScheme_MaxAmount.touched ||
                      refScheme_MaxAmount.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme MinROI </span>
              <input
                required
                type="number"
                #refScheme_MinROI="ngModel"
                [(ngModel)]="schemeModel.Scheme_MinROI"
                placeholder="Scheme MinROI"
                name="Scheme_MinROI"
                id="Scheme_MinROI"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_MinROI.invalid,
                  'alert-warning':
                    refScheme_MinROI.invalid &&
                    (refScheme_MinROI.dirty ||
                      refScheme_MinROI.touched ||
                      refScheme_MinROI.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme MaxROI </span>
              <input
                required
                type="number"
                #refScheme_MaxROI="ngModel"
                [(ngModel)]="schemeModel.Scheme_MaxROI"
                placeholder="Scheme MaxROI"
                name="Scheme_MaxROI"
                id="Scheme_MaxROI"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_MaxROI.invalid,
                  'alert-warning':
                    refScheme_MaxROI.invalid &&
                    (refScheme_MaxROI.dirty ||
                      refScheme_MaxROI.touched ||
                      refScheme_MaxROI.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme FromDate </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Scheme_FromDate"
                  id="Scheme_FromDate"
                  #refScheme_FromDate="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refScheme_FromDate.invalid,
                    'alert-warning':
                      refScheme_FromDate.invalid &&
                      (refScheme_FromDate.dirty ||
                        refScheme_FromDate.touched ||
                        refScheme_FromDate.untouched)
                  }"
                  [(ngModel)]="schemeModel.Scheme_FromDate"
                  [min]="CurrentDate"
                  class="form-control input-text-css"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme EndDate </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Scheme_EndDate"
                  id="Scheme_EndDate"
                  #refScheme_EndDate="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refScheme_EndDate.invalid,
                    'alert-warning':
                      refScheme_EndDate.invalid &&
                      (refScheme_EndDate.dirty ||
                        refScheme_EndDate.touched ||
                        refScheme_EndDate.untouched)
                  }"
                  [(ngModel)]="schemeModel.Scheme_EndDate"
                  [min]="schemeModel.Scheme_FromDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Scheme AdvanceEMI </span>
              <input
                required
                type="text"
                maxlength="2"
                numbersOnly
                #refScheme_AdvanceEMI="ngModel"
                [(ngModel)]="schemeModel.Scheme_AdvanceEMI"
                placeholder="Scheme AdvanceEMI"
                name="Scheme_AdvanceEMI"
                id="Scheme_AdvanceEMI"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refScheme_AdvanceEMI.invalid,
                  'alert-warning':
                    refScheme_AdvanceEMI.invalid &&
                    (refScheme_AdvanceEMI.dirty ||
                      refScheme_AdvanceEMI.touched ||
                      refScheme_AdvanceEMI.untouched)
                }"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> State </span>
              <select
                name="State"
                id="State"
                #refState="ngModel"
                multiple
                [ngClass]="{
                  'is-invalid': f.submitted && refState.invalid,
                  'alert-warning':
                    refState.invalid &&
                    (refState.dirty || refState.touched || refState.untouched)
                }"
                [(ngModel)]="schemeModel.State"
                class="form-control input-text-css"
                required
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveScheme()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
