import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { LmsService } from '../services/lms.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';

import * as moment from 'moment';

@Component({
  selector: 'app-lms-attachment',
  templateUrl: './lms-attachment.component.html',
  styleUrls: ['./lms-attachment.component.scss']
})
export class LmsAttachmentComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "UDId",
    "Category",
    "Type",
    "DocType",
    "DocTitle",
    "UploadBy",
    "UploadedOn",
    "No_Of_Pages",
    "View",];
  docModalDataSource: any;
  displayedDocModalColumns: string[] = [
    "UDId",
    "Category",
    "Type",
    "DocType",
    "DocTitle",
    "No_Of_Pages",
    "View",
  ];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loading: boolean = false;
  
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  loandocBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  docModal: any = { DocumentImages: [] };
  docDropDown: any[] = [];
  assetDropdown: any[] = [];
  CustomerDropdown: any[] = [];
  CustomerAssetsDropdown: any[] = [];
  today: Date = new Date();
  docList: any[] = [];
  IsKYC: boolean = false;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  PageAcess: any;

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute, private _MasterService: MasterService,
    private dialog: DialogService
    
  ) {
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Attachment');
    // Lms code start
    //this.dataSharingService.LOSHeaderTitle.next('CRC List');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let AllPage = JSON.parse(sessionStorage.getItem('AllPageAcess'));
    this.PageAcess = AllPage.filter(x => x.Page_Name == "Attachment");

    if(this.PageAcess == '' || this.PageAcess == null || this.PageAcess == undefined){
      this.RedirectTo();
    }

    this.showSpinner = true;
    this.LMS_Get_Loan_Documents();
    this.docModal = {
      LoanId: this.loanSummary.LoanId,
      LoginUserId: this.currentUser.userId,
      DocId: "0-0",
      Name: "",
      No_Of_Pages: 1,
      IsReceipt: false,
      IsOrignalDoc: "1",
      IsDocument:"1",
      DocumentImages: [],
      Type: 'Loan',
      ApplicationNo: this.loanSummary.ApplicationNo
    };
  }
  LMS_Get_Loan_Documents() {
    this.lmsService.LMS_Get_Loan_Documents({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      this.docList = res;
      this.dataSource = new MatTableDataSource(res);
      this.showSpinner = false;
    });
  }
  ViewDoc(data: any) {
    this.loading = true;
    this.lmsService.LMS_GetApplicationDocAttachment({
      UDId: data.UDId
    }).subscribe((res: any) => {
      this.loading = false;
      this.docModalDataSource = new MatTableDataSource(res);
      $("#docdetailModel").modal("show");
      $("#docdetailModel").css("z-index", "1050");
    });
  }
  OnDocDetailClose() {
    $("#docdetailModel").modal("hide");
  }
  addNewDoc() {
    this.reset();
    this.DoucumentCategoryDrodown();
    $("#docModal").modal("show");
    $("#docModal").css("z-index", "1050");

  }
  OnDocClose() {
    $("#docModal").modal("hide");
  }
  LMSAssetInfoDropDown() {
    if (this.docModal.Type == 'Assets') {
      this.lmsService.LMSAssetInfoDropDown({ Loan_Id: this.loanSummary.LoanId }).subscribe((result: any) => {
        this.assetDropdown = result;
      });
    }
    if (this.docModal.Type == 'Customer') {
      this.lmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
        this.CustomerDropdown= JSON.parse(JSON.stringify(result));
     
      });
    } if (this.docModal.Type == 'Loan') {
      this.lmsService.LMS_AssetCustomer_Info_DropDown({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
        this.CustomerAssetsDropdown = JSON.parse(JSON.stringify(result));

      });
    }
  }
  fileChangeListenerD(files: any, input: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages = "";
      delete this.docModal.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages = "";
      delete this.docModal.DocData;
      return;
    }

    let $this = this;
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let _pre = this.today.getTime();
      let _docImg: any = {};
      _docImg.DocFileName = _pre+"_"+files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  async onSaveDoc() {
    let re=1
    if(this.docModal.IsOrignalDoc==0){
      await this.dialog.openConfirmDialog(`Are you sure you want to add this dcoument,because this document is not deleted.`)
      .afterClosed().toPromise().then(dialogResult => {
        if (dialogResult == false) {
          re=0;
          return;
        }
      
      })
    }
    if(re==0){
      this.OnDocClose(); 
      return ;
    }
    
    let _data = { ...this.docModal };
    if (+this.docModal.No_Of_Pages <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No Of Pages required and should be grater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.docModal.DocumentImages.length) {
      this.loading = true;
      if (this.docModal.Type != 'Customer') {
        for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
          let _err: boolean = false;
          this.lmsService
            .UploadCrcDoc({
              DocName: this.docModal.DocumentImages[i].DocFileName,
              ApplicationNo: this.loanSummary.ApplicationNo,
              DocData: this.docModal.DocumentImages[i].DocData
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
          if (_err) {
            break;
          }
        }

      } else {
        for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
          let _err: boolean = false;
          this.lmsService
            .UploadCustomerDoc({
              CustomerID: this.docModal.Name.split('-')[1],
              DocName: this.docModal.DocumentImages[i].DocFileName,
              DocData: this.docModal.DocumentImages[i].DocData,
            })
            .subscribe(
              (res: any) => {
                if ((i + 1) == this.docModal.DocumentImages.length) {
                  this.saveDoc();
                }
              },
              (err: any) => {
                _err = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
          if (_err) {
            break;
          }
        }

      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload doc file.",
        ...this.configSuccess,
      });
    }
  }
  saveDoc() {
    if (this.IsKYC) {
      let docImage=''
      if (this.docModal.DocumentImages.length > 1) {
        docImage = this.docModal.DocumentImages[1].DocFileName;

      }
      this.lmsService
        .LMS_SaveCustomerKYCDocApp({ CustomerId: this.docModal.Name.split('-')[1], KYC_DocId: this.docModal.DocId.split('-')[0], KYC_DocNumber: this.docModal.DocTitle, KYC_DocFile: this.docModal.DocumentImages[0].DocFileName, KYC_DocFile1: docImage, LoginUserId: this.currentUser.userId, KYC_IsVerified: 0})
        .subscribe((res: any) => {
          this.loading = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.LMS_Get_Loan_Documents();
            this.OnDocClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });

    }
    else {
      var IsOrignalDoc;
      if (this.docModal.IsOrignalDoc == "1")
        IsOrignalDoc = 0;
      else
        IsOrignalDoc = 1;
      let _data = {
        Document: {
          LoanId: this.docModal.LoanId,
          LoginUserId: this.docModal.LoginUserId,
          DocTitle: this.docModal.DocTitle,
          Remark: this.docModal.Remark,
          DocId: this.docModal.DocId.split('-')[0],
          Name: this.docModal.Name.split('-')[0],
          No_Of_Pages: this.docModal.No_Of_Pages,
          IsReceipt: this.docModal.IsReceipt,
          IsOrignalDoc: IsOrignalDoc,
          DateOfExecution: this.docModal.IsOrignalDoc == '0'? moment(this.docModal.DateOfExecution).format("YYYY-MM-DD"):'',
          ExecutedBy: this.docModal.ExecutedBy
        },
        DocumentImages: []
      };
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        _data.DocumentImages.push({ DocImage: this.docModal.DocumentImages[i].DocFileName });
      }
      this.lmsService.LMS_SaveLoanDocumentJson({
        JSON: JSON.stringify(_data)
      }).subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.LMS_Get_Loan_Documents();
          this.OnDocClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
    }
  }
  onDeleteDoc(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ${data.DocTitle} from Loan.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService.LMS_DeleteLoanDocument({ Loan_Id: this.loanSummary.LoanId, DocumentUId: data.UDId, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.LMS_Get_Loan_Documents();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          })
        }
      });
  }
  onNextStep() {
   // if (this.docList.length != 0) {
      this.lmsService.SaveNext_Loan({
        Loan_Id: this.loanSummary.LoanId, CommandName: 'Attachment', isStatus: 1
      }).subscribe((response) => {
        this.action.emit("next")
      });

    //} else {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Atlease one Doc.", ...this.configSuccess });
    //}

  }
  DoucumentCategoryDrodown() {
    this.lmsService
      .LMS_GetLoanReqDocForDropDown({
        Loan_Id: this.loanSummary.LoanId, Type: this.docModal.Type
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.docDropDown = res;
        this.LMSAssetInfoDropDown();
        if (this.docModal.DocId.split('-')[1] == '1')
          this.IsKYC = false;
        else
          this.IsKYC = false;

      });
  }
  DocumentCategoryChange(event) {
    if (this.docModal.DocId.split('-')[1] == '1')
      this.IsKYC = false;
    else
      this.IsKYC = false;
  }
  TypeChange(event) {
    this.showSpinner = true;
    this.DoucumentCategoryDrodown();
  }
  reset() {
    this.docModal = {
      LoanId: this.loanSummary.LoanId,
      LoginUserId: this.currentUser.userId,
      DocId: "0-0",
      Name: "",
      No_Of_Pages: 1,
      IsReceipt: false,
      IsOrignalDoc: "1",
      IsDocument: "1",
      DocumentImages: [],
      Type: 'Loan',
      ApplicationNo: this.loanSummary.ApplicationNo
    };
  }

  RedirectTo(){
    this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
    this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  // openDialogBox(){
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`); // Pizza!
  //   });
  //   // this.dialog.openConfirmDialog(`Are you sure you want to delete?`)
  //   // .afterClosed().subscribe(dialogResult => {
  //   //   if(this.docModal.IsOrignalDoc==1){
  //   //     this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
  //   //     else(){
  //   //       return;
  //   //   }
  //   // });
  // }
  documentChange() {
    if (this.docModal.IsDocument == "1") {
      this.docModal.IsOrignalDoc = "1";
    }
  }

  GetData() {
    this.ngOnInit();
    this.onClosePendingDocs();
  }
  onShowPendingDocs() {
    $("#PendingDocs").modal("show");
    $("#PendingDocs").css("z-index", "1050");
  }

  onClosePendingDocs() {
    $("#PendingDocs").modal("hide");
  }
 
}
