import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-lms-schedule-regenerate-details",
  templateUrl: "./lms-schedule-regenerate-details.component.html",
  styleUrls: ["./lms-schedule-regenerate-details.component.scss"],
})
export class LmsScheduleRegenerateDetailsComponent implements OnInit {
  ReceiptData: any = {};
  currentUser: any;
  Loan_id: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  LoanCustomerList: any = [];
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  LoanAllDetail: any = [];
  LoanFinancialDetail: any = [];
  isFrequency: boolean = false;
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "ROI",
  };
  stepEMI: any[] = [];
  emiIrr: any;
  stepEmiData: any;
  selectedScheme: any = {};
  LoanDetail: any;
  stepEmiForm: boolean = true;
  isCalEMITableView: boolean = true;
  RestructureRemark: any = "";
  minDate: any;
  Tenure_Name: string;
  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Loan_id = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("loanId"))
      );
      this.getLoanRestructuredDetail();
    });
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    // this.dataSharingService.LOSHeaderTitle.next("Schedule Re-Generated");
    this.dataSharingService.HeaderTitle.next("Schedule Re-Generate");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedColumns = [
      "Int_Id",

      "Customer",
      "CustomerType",
      "GenderAge",
      "PhoneNo",
      "Relation_With_Hirer",
      "Present_Address",
      "ExistingCustomer",
    ];
  }

  getLoanRestructuredDetail() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_Loan_Restructured_Detail({ Loan_Id: this.Loan_id })
      .subscribe((res: any) => {
        if (res.Item1.length>0) {
          if(res.Item1[0].CODE==-1){
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res.Item1[0].MSG,...this.configSuccess});
            this.showSpinner = false;
            this.router.navigate([
              `schedule/regenerate`,
            ]);
          }else{
          this.LoanAllDetail = res.Item1;
          this.LoanFinancialDetail = res.Item3;
          console.log("Loan financial details", this.LoanFinancialDetail);
          if (
            this.LoanFinancialDetail.EMIType == "Daily" ||
            this.LoanFinancialDetail.EMIType == "Weekly" ||
            this.LoanFinancialDetail.EMIType == "Fortnightly" ||
            this.LoanFinancialDetail.EMI_Type == "ByWeekly" ||
            this.LoanFinancialDetail.EMI_Type == "FourthWeekly"
          )
            this.Tenure_Name = "Days";
          else this.Tenure_Name = "Months";

          this.LoanCustomerList = JSON.parse(JSON.stringify(res.Item2));
          this.dataSource = new MatTableDataSource(this.LoanCustomerList);
          this.dataSource.sort = this.sort;
        }
        this.showSpinner = false;
      }
      });
  }

  onEditFinanceDetail() {
    this.minDate = new Date(
      this.currentUser.Data_Freeze.split("T")[0] + " 00:00:00"
    );
    if (
      this.LoanFinancialDetail.filter((item) => item.Type == "Current")[0]
        .DueDate &&
      this.LoanFinancialDetail.filter((item) => item.Type == "Current")[0]
        .DueDate != "0001-01-01T00:00:00" &&
      new Date(
        this.LoanFinancialDetail.filter(
          (item) => item.Type == "Current"
        )[0].DueDate.split("T")[0] + " 00:00:00"
      ) < this.minDate
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Data Freeze On DueDate ",
        ...this.configSuccess,
      });
    } else {
      $("#loanFinancialDetailsModel").modal("show");
      $("#loanFinancialDetailsModel").css("z-index", "1050");
      this.getLoanAccount();
    }
  }

  onCloseDialog() {
    $("#loanFinancialDetailsModel").modal("hide");
  }

  getLoanAccount() {
    this.stepEMI = [];
    this.showSpinner = true;
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.Loan_id })
      .subscribe((res: any) => {
        // isCalEMITableView
        if (res.Item1.length > 0) {
          this.LoanDetail = res.Item1[0];
          //this.isCalEMITableView = false;
          //this.isCalEMIForm = true;
          if (res.Item1[0].Application_IRR_CalculateBy) {
            this.RestructureRemark = this.LoanDetail.Remark;
            this.cEIModel.Asset_Cost = res.Item1[0].AssetCost
              ? res.Item1[0].AssetCost == -1 || res.Item1[0].AssetCost == 0
                ? 0
                : res.Item1[0].AssetCost
              : 0;
            this.cEIModel.NetFinance_Amt = res.Item1[0].NetFinance;
            this.cEIModel.IRR_CalculateBy =
              res.Item1[0].Application_IRR_CalculateBy;
            this.cEIModel.EMI_Type =
              res.Item1[0].EMIType == undefined
                ? "Monthly"
                : res.Item1[0].EMIType;
            this.cEIModel.No_Of_Inst = res.Item1[0].No_Of_Instl;
            this.cEIModel.Tenure = res.Item1[0].Tenure;
            this.cEIModel.Adv_Inst = res.Item1[0].Adv_Instl;
            this.cEIModel.MgmtFee = res.Item1[0].ManagementFee;
            this.cEIModel.First_EMI_Date = new Date(res.Item1[0].FirstEMIDate);
            this.cEIModel.Loan_Date = new Date(res.Item1[0].Loan_Date);

            this.cEIModel.Flat_Rate = res.Item1[0].Flat_Rate;
            this.cEIModel.EMI_Amount = res.Item1[0].Application_LoanEMIAmount;
            this.cEIModel.IRR_CalculateBy =
              res.Item1[0].Application_IRR_CalculateBy;
            this.cEIModel.Reducing_ROI = res.Item1[0].Case_IRR;
            if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
              this.isFrequency = true;
            $(function () {
              $("#Flat_Rate").val(res.Item1[0].Flat_Rate);
            });

            $(function () {
              $("#EMI_Amounts").val(res.Item1[0].Application_LoanEMIAmount);
            });

            let data = {
              Disbursement_Amt: res.Item1[0].DisbursementAmt,
              Interest_Amt: res.Item1[0].InterestAmt,
              Agreement_Value: res.Item1[0].AgreementValue,
              LTV: res.Item1[0].LTV,
              ROI: res.Item1[0].Flat_Rate,
              EMI_Amt: res.Item1[0].Application_LoanEMIAmount,
              Case_IRR: res.Item1[0].Case_IRR,
              Disbursement_IRR: res.Item1[0].Disbursement_IRR,
              Margin: res.Item1[0].Margin,
            };
            this.emiIrr = data;
            if (res.Item1[0].Application_IRR_CalculateBy == "ROI") {
              // this.cEIModel.IsStep = true;
              this.stepEMI.push({
                FromEMI: 1,
                ToEMI: this.cEIModel.No_Of_Inst,
                EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
                NoOfInstl: this.cEIModel.No_Of_Inst,
                TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
                disable: true,
              });
              // document.getElementById("CalculateByFlat")['checked'] = true;
              //var amt = this.emiIrr.ROI.toFixed(2);
              //$(function () {
              //  $("#Flat_Rate").val(amt);
              //});
            }
            if (res.Item1[0].Application_IRR_CalculateBy == "STEP_EMI") {
              let stepArr: any[] = [];
              this.cEIModel.IsStep = true;
              this.lmsService
                .FinancialDetailsForUpdate({ Loan_Id: this.Loan_id })
                .subscribe((res: any) => {
                  this.stepEMI = res.Item2;

                  for (let i = 0; i < res.Item2.length; i++) {
                    this.stepEMI[i].EMI_Amount = res.Item2[i].EMIAmount;
                    this.stepEMI[i].NoOfInstl = res.Item2[i].NoOfEMI;
                    for (
                      let j = +this.stepEMI[i].FromEMI;
                      j <= +this.stepEMI[i].ToEMI;
                      j++
                    ) {
                      if (+this.stepEMI[i].EMI_Amount >= 0) {
                        stepArr.push(this.stepEMI[i].EMI_Amount);
                      }
                    }
                  }
                  this.getTotlaEmiAndAmount();
                });
            }
            if (res.Item1[0].Application_IRR_CalculateBy == "FLAT_EMI") {
              this.stepEMI.push({
                FromEMI: 1,
                ToEMI: this.cEIModel.No_Of_Inst,
                EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
                NoOfInstl: this.cEIModel.No_Of_Inst,
                TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
                disable: true,
              });
              // document.getElementById("CalculateByEMI")['checked'] = true;
            }
          }
        }

        this.showSpinner = false;
      });
  }

  handleByChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
    }
    this.stepEmiData = undefined;
  }

  setTenure() {
    if (this.cEIModel.No_Of_Inst) {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    } else {
      this.cEIModel.Tenure = "";
    }
    this.setAdvanceInstl();
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Advance Installment not greater than No. Of Installment !!!",
        ...this.configSuccess,
      });
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    } else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To EMI should be greater than From EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }

  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount>=0) {
          TotalEmi =
            TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
              +this.stepEMI[i].EMI_Amount;
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    } else {
      this.stepEmiData = undefined;
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }

  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
        +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "ROI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      this.calculateEmiIrr();
    } else {
      this.calculateEmiIrr();
    }
  }

  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }

      //if (this.stepEmiData != undefined || this.stepEmiData == null) {
      //  this.snackBar.openFromComponent(SnackbarComponent, {
      //    data: "Please recalculate amount.",
      //    ...this.configSuccess,
      //  });
      //  return;
      //}
      if (this.stepEmiData != undefined && this.stepEmiData != undefined && +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }
    /*if (this.cEIModel.Adv_Inst > 0) {*/
      _data.DaysInYear = this.currentUser.DaysInYear;
    //  this.lmsService.LMS_GetCalculateEMIIRR(_data).subscribe((res: any) => {
    //    if (res.length > 0) {
    //      _data.NetFinance_Amt =
    //        this.cEIModel.NetFinance_Amt -
    //        this.cEIModel.Adv_Inst * res[0].EMI_Amt;
    //      _data.No_Of_Inst = this.cEIModel.No_Of_Inst - this.cEIModel.Adv_Inst;
    //      console.log("_data.NetFinance_Amt", _data.NetFinance_Amt);
    //      this.getCalculateEMIIRR(_data);
    //    }
    //  });
    //} else {
      this.getCalculateEMIIRR(_data);
   // }
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.lmsService.LMS_GetCalculateEMIIRR(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      $("#EMI_Amounts").val(this.emiIrr.EMI_Amt.toFixed(2));
      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      $("#Flat_Rate").val(this.emiIrr.ROI.toFixed(2));
    });
  }

  onSaveApplication() {
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      new Date(this.cEIModel.First_EMI_Date).getTime() <
      new Date(this.cEIModel.Loan_Date).getTime()
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Loan Date should be less than first emi date",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.stepEmiData != undefined &&
      +this.stepEmiData.totalAmount <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI multiply with installment should be equal or grater than net finance.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_Type == "Step") {
      let length = this.stepEMI.length;
      let ToEmi = this.stepEMI[length - 1].ToEMI;
      if (+ToEmi != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Add EMI step.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
        +this.stepEmiData.totalAmount
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
          ...this.configSuccess,
        });
        return;
      }
    }
    let _stepEmi: any[] = [];
    this.stepEMI.forEach((obj: any) => {
      _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
    });
    if (
      this.emiIrr.Interest_Amt == null ||
      this.emiIrr.Interest_Amt == 0 ||
      this.emiIrr.Interest_Amt == ""
    ) {
      this.emiIrr.Interest_Amt = 0;
      this.emiIrr.Case_IRR = 0;
      this.emiIrr.Disbursement_IRR = 0;
    }

    let data = {
      Application: this.dataSharingService.cleanObject({
        //InquiryNo: this.inquiryDetail.InquiryNo,
        InquiryNo: 5457,
        LoanId: this.Loan_id,
        BranchId: 1,
        RestructureRemark: this.RestructureRemark,
        ProductId: this.cEIModel.ProductId,
        Asset_Cost: this.cEIModel.Asset_Cost,
        NetFinance_Amt: this.cEIModel.NetFinance_Amt,
        Flat_Rate: this.emiIrr.ROI,
        No_Of_Inst: this.cEIModel.No_Of_Inst,
        Tenure: this.cEIModel.Tenure,
        Adv_Inst: this.cEIModel.Adv_Inst,
        MgmtFee: 0, // this.cEIModel.MgmtFee,
        EMI_Type: this.cEIModel.EMI_Type,
        Disbursement_Amt: this.emiIrr.Disbursement_Amt,
        Interest_Amt: this.emiIrr.Interest_Amt,
        Agreement_Value: this.emiIrr.Agreement_Value,
        LTV: this.emiIrr.LTV,
        EMI_Amt: this.emiIrr.EMI_Amt,
        Case_IRR: this.emiIrr.Case_IRR,
        Disbursement_IRR: this.emiIrr.Disbursement_IRR,
        Loan_Purpose: this.cEIModel.Purpose,
        Loan_SchemeId: this.selectedScheme.SchemeId,
        FirstEMIDate: this.cEIModel.First_EMI_Date,
        Loan_Date: this.cEIModel.Loan_Date,
        IRR_Type: this.cEIModel.IRR_Type,
        LoginUserId: this.currentUser.userId,
        ROI: this.emiIrr.ROI,
        EMI_Amount: this.cEIModel.EMI_Amount,
        IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
      }),
      StepIRR: _stepEmi,
    };

    this.lmsService
      .LMS_Update_Financial_Restructured({ JSON: JSON.stringify(data) })
      .subscribe((res: any) => {
        if (res) {
          if (res[0] ? res[0].CODE >= 0 : false) {
            console.log(" this.cEIModel.ProductId", this.cEIModel.ProductId);
            console.log(" data", data);
            //this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: 34, VoucherSubType: 'Loan', ProductId: this.LoanAllDetail[0].ProductId || 0, TaxType: 'CR' }).subscribe((res: any) => {
            //  console.log("Get_AccountHead_For_VoucherEntry", res);
            //  if (res.Item1[0]) {
            //    if (res.Item1[0].AccountId) {
            //      if (this.cEIModel.Adv_Inst > 0) {
            //        for (let i = 0; i < this.cEIModel.Adv_Inst; i++) {

            //          this.ReceiptData.LoanId = this.Loan_id;
            //          this.ReceiptData.DeductionLoanId = this.Loan_id;

            //          this.ReceiptData.LoginUserId = this.currentUser.userId;
            //          this.ReceiptData.CollectionOn = new Date();
            //          this.ReceiptData.Int_Id = 0;
            //          this.ReceiptData.ChargeHeadId = 34;
            //          this.ReceiptData.ChargeHeadCategory = res.Item1[0].AccountName;
            //          this.ReceiptData.AccountId = res.Item1[0].AccountId;

            //          this.ReceiptData.IsActive = 0;
            //          this.ReceiptData.Charges = 'Advance EMI';
            //          this.ReceiptData.Type = 'Deduction';
            //          this.ReceiptData.Amount = this.emiIrr.EMI_Amt;
            //          this.ReceiptData.CGST = 0;
            //          this.ReceiptData.SGST = 0;
            //          this.ReceiptData.TotalAmount = this.emiIrr.EMI_Amt;
            //          this.lmsService.SaveReceiptDeduction({ JSON: JSON.stringify({ Loaninfo: this.ReceiptData }) }).subscribe((res: any) => {
            //            this.showSpinner = false;

            //            //this.rdDataSource = new MatTableDataSource(res.Item2);

            //          })
            //        }
            //      }
            //    }
            //  }
            //})

            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.getLoanRestructuredDetail();
            this.onCloseDialog();
          } else if (res[0]) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        }
      });
  }
  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.stepEmiForm = true;
      this.cEIModel.EMI_Type = "Monthly";
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
      this.isFrequency = true;
    else this.isFrequency = false;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
}
