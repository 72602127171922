// #region Import Used Components and Services
import { Injectable, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { MenusModel, newMenuModal } from '../Models/MenusModel'
import { constantUrl } from 'src/app/Shared/constantUrl';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
// #endregion

@Injectable({
  providedIn: 'root'
})

export class userlayoutService {

  // #region Variable Declarations and Models 
  //private data: any;
  //token: any;
  // username: any;

  MenusModel: MenusModel = new MenusModel();
  Menuslinks: any;
  CheckLoad: any;
  showSpinner = false;
  // #endregion

  // #region constructor userlayoutService
  constructor(private http: HttpClient, private _Route: Router, public snackBar: MatSnackBar) { }


  // #region getNewMenuList
  getNewMenuList(userid) {
    newMenuModal.pop();
    // console.log('call', newMenuModal[0], sessionStorage.getItem('CheckLoad'), sessionStorage.getItem('CheckLoad')=="[]");
    if (sessionStorage.getItem('CheckLoad') == null || sessionStorage.getItem('CheckLoad') == "[]") {
      this.MenusModel.userId = userid;
      this.GetAllMenus(this.MenusModel).subscribe((result) => {
        sessionStorage.setItem("CheckLoad", JSON.stringify(result));
        newMenuModal.push(JSON.parse(sessionStorage.CheckLoad));
      })

      this.showSpinner = false;
    }
    else {
      newMenuModal.push(JSON.parse(sessionStorage.CheckLoad));
      this.showSpinner = false;
    }
  }
  // #endregion

  // #region GetAllMenus
  public GetAllMenus(menusModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Menus";
    return this.http.post<MenusModel[]>(apiUrl, menusModel);
  }
  // #endregion

  // #region Snakbar Config
  // configSuccess: MatSnackBarConfig = {
  //   panelClass: 'style-success',
  //   duration: 5000,
  //   horizontalPosition: 'right',
  //   verticalPosition: 'top'
  // };
  // #endregion

  // #region Error Handeling
  // private handleError = (error: HttpErrorResponse) => {
  //   if (error.status == 401) {
  //     this.snackBar.openFromComponent(SnackbarComponent, { data: 'Your Session has been expired, Please login Again', ...this.configSuccess });
  //     this._Route.navigate(['/UserLogout']);
  //   }
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
  //   }
  //   // return an observable with a user-facing error message
  //   return throwError('Something bad happened; please try again later.');
  // };
  // #endregion
}

