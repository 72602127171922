import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  Injectable,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { getDate } from "ngx-bootstrap/chronos/utils/date-getters";

@Component({
  selector: "app-lms-enach",
  templateUrl: "./lms-enach.component.html",
  styleUrls: ["./lms-enach.component.scss"],
})
export class LmsEnachComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  DueDateFrom: any;
  DueDateTo: any;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  JsonData: any[] = [];
  dialogreturn: any;
  Status: any = "Pending";
  Id: any = 0;
  HoldReason: any;
  HoldDate: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  IsAlreadySelected: boolean = false;
  IsSelectAll: boolean = false;
  BulkPostDate: any;
  minDate: any;
  ChequeinTransitId = 32;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private lmsService: LmsService,
    private MasterService: MasterService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Repayment Process");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.DueDateFrom = new Date();
    this.DueDateTo = new Date();
    this.displayedColumnsLoan = [
      "Id",
      "Selection",
      "CaseNo",
      "CustomerName",
      "EMINo",
      "CaseDate",
      "EMI_Amount",
      "DueDate",
      "CustomerBankname",
      "Type",
      "Undo",
      "Status",
    ];
    // this.minDate = new Date(JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split('T')[0]);
    //this.onSearchNachProcess();
    this.minDate = new Date();
  }

  onSearchNachProcess() {
    this.showSpinner = true;
    this.lmsService
      .LMS_ENach_Process({
        DueDateFrom: this.DueDateFrom,
        DueDateTo: this.DueDateTo,
        Status: this.Status,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));

          this.JsonData.forEach((x) => {
            x.IsAlreadySelected = true;
          });

          this.IsSelectAll = this.JsonData.length > 0 ? true : false;

          this.dataSourceLoan = new MatTableDataSource(this.JsonData);
          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceLoan = null;
        }
        this.showSpinner = false;
      });
  }

  SelectAll(event) {
    this.JsonData.forEach((x) => {
      if (event.checked == true) {
        x.IsAlreadySelected = true;
      }
      if (event.checked == false) {
        x.IsAlreadySelected = false;
      }
    });
  }

  public calculateTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["EMI_Amount"]);
      }
      return sum;
    }
    return 0;
    //return this.dataSourceLoan.reduce((accum, curr) => accum + curr.EMI_Amount, 0);
  }
  DateChange() {
    if (this.DueDateFrom > this.DueDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Date To greater than Date From.",
        ...this.configSuccess,
      });
      this.DueDateFrom = new Date();
      this.DueDateTo = new Date();
    }
  }


  onBulkPost() {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to post this voucher ? "
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        var Ids = "",
          count = 0;
        this.JsonData.map((x) => {
          if (x.IsAlreadySelected == true) {
            Ids = Ids + x.Id + ",";
            count = count + 1;
          }
        });

        if (count > 0) {
          var count = 0;
          this.JsonData.forEach((x) => {
            count++;
            if (x.IsAlreadySelected == true) {
              var RandNo = Math.floor(Math.random() * 10000000000000);
              let _Data = {
                merchant: {
                  identifier: x.ICICI_MerchantCode,
                },
                payment: {
                  instrument: {
                    identifier: "test",
                  },
                  instruction: {
                    //"amount": "1",
                    amount: x.EMI_Amount,
                    endDateTime: moment(this.BulkPostDate).format("DDMMYYYY"),
                    identifier: x.Identifier,
                    //"identifier":"976522067"
                  },
                },
                transaction: {
                  deviceIdentifier: "S",
                  type: "002",
                  currency: "INR",
                  identifier: RandNo,
                  subType: "003",
                  requestType: "TSI",
                },
              };
              this.lmsService
                .TransactionScheduling({ JSON: JSON.stringify(_Data) })
                .subscribe((res: any) => {
                  this.SavePayment(
                    JSON.stringify(_Data),
                    x,
                    res.response,
                    RandNo, count
                  );
                  if (
                    JSON.parse(res.response).paymentMethod.paymentTransaction
                      .statusMessage == "I"
                  ) {
                    this.SaveVoucher(x, RandNo);
                  }
                });
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please atleast one process select",
            ...this.configSuccess,
          });
        }
      }
    });
  }

  SaveVoucher(row, RandNo) {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: moment(this.BulkPostDate).format("YYYY-MM-DD"),
      })
      .subscribe((result: any) => {
        //console.log("result", result);
        this.saveBulkPostVoucher(
          result[0].VoucherNo,
          row,
          moment(this.BulkPostDate).format("YYYY-MM-DD"),
          RandNo
        );
        //this.onSearchNachProcess();
      });
  }

  SavePayment(Request, row, res, RandNo,count) {
    var Response = res;
    var Status = JSON.parse(res).paymentMethod.paymentTransaction.statusMessage;

    var NACH = {
      LoanId: row.Loan_Id,
      URL: "Transaction Scheduling",
      Request: Request,
      Response: Response,
      CreatedBy: this.currentUser.userId,
      Status: Status,
      Identifier: RandNo,
    };

    let _data = {
      NACH: NACH,
    };

    //console.log('_data', _data);
    this.lmsService
      .LMS_Save_ENach_Request_Response({ JSON: JSON.stringify(_data) })
      .subscribe((res1: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: JSON.parse(res).paymentMethod.paymentTransaction.errorMessage,
          ...this.configSuccess,
        });
        if (this.JsonData.length == count) {
          this.onSearchNachProcess();
        }
      });
  }

  saveBulkPostVoucher(VoucherNumber, Row, BulkPostDate, RandNo) {
    var ComapnyAccountId = Row.ComapnyAccountId;
    var ComapnyAccount = Row.ComapnyAccount;

    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "Bank",
      Voucher_Date: BulkPostDate,
      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: "Cheque",
      Voucher_Total_Credit_Amount: Row.EMI_Amount,
      Voucher_Total_Debit_Amount: Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration:
        "Loan No. " +
        Row.LoanAcNo +
        "  / Application No. " +
        Row.Application_No +
        " Cheque No. " +
        Row.ChequeNo,
      Voucher_Cheque_No: Row.ChequeNo,
      Voucher_Cheque_Date: this.datepipe.transform(
        new Date(Row.DueDate),
        "yyyy-MM-dd"
      ),
      Voucher_Bank_Name: Row.CustomerBankname,
      Voucher_Cheque_Clear_Date: null,
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: Row.Id,
      LoginUserId: this.currentUser.userId,
    };

    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: Row.LoanAcNo,
      CustomerId: Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: Row.AccountName,
      AccountId: Row.AccountId,
      BranchId: Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: null,
      CustomerId: null,
      Is_LOSAppliaction: null,
      LOS_TransactionId: null,
      ChargeHeadId: null,
      ChargeHeadCategory: ComapnyAccount,
      AccountId: ComapnyAccountId,
      BranchId: null,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: Row.EMI_Amount,
    });

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };

    let _data: any = {};
    _data.Post = {
      PostDate: BulkPostDate,
      RPDC_ComapnyBank: "",
      Identifier: RandNo,
    };
    console.log("_data", _data);
    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: Row.Id,
        StatusVal: "Post",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE >=0) {
          this.onSearchNachProcess();
          //this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        } else {
          // this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
  }
  onHold(row) {
    this.Id = row.Id;
    $("#HoldDetailModel").modal("show");
    $("#HoldDetailModel").css("z-index", "1050");
  }
  onCloseHoldDetail() {
    this.onSearchNachProcess();
    this.Id = 0;
    this.HoldDate = "";
    this.HoldReason = "";
    $("#HoldDetailModel").modal("hide");
  }
  onHoldOk() {
    let _data: any = {};
    _data.Hold = { HoldReason: this.HoldReason, HoldDate: this.HoldDate };
    this.lmsService
      .SAVE_NACHPROCESS({
        Int_Id: this.Id,
        StatusVal: "Hold",
        JSON: JSON.stringify(_data),
      })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.onCloseHoldDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onUnHold(row) {
    this.lmsService
      .SAVE_NACHPROCESS({ Int_Id: row.Id, StatusVal: "UnHold" })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseHoldDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  Undo(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to last action will be reverted and related voucher will be deleted ? "
    );

    //this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete this employee?');
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == false) {
        return;
      } else {
        this.lmsService
          .SAVE_NACHPROCESS({
            Int_Id: row.Id,
            StatusVal: "Undo",
            JSON: JSON.stringify(""),
          })
          .subscribe((res) => {
            if (res[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.onSearchNachProcess();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
  StatusChange() {
    if (this.Status == "Pending") {
      this.displayedColumnsLoan = [
        "Id",
        "Selection",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "CaseDate",
        "EMI_Amount",
        "DueDate",
        "CustomerBankname",
        "Type",
        "Undo",
        "Status",
      ];
    }
    if (this.Status == "Post" || this.Status == "All") {
      this.displayedColumnsLoan = [
        "Id",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "CaseDate",
        "EMI_Amount",
        "DueDate",
        "CustomerBankname",
        "Type",
        "Status",
      ];
    }
    if (this.Status == "Hold") {
      this.displayedColumnsLoan = [
        "Id",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "CaseDate",
        "EMI_Amount",
        "DueDate",
        "CustomerBankname",
        "Type",
        "Undo",
        "Status",
      ];
    }
    this.onSearchNachProcess();
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `ENACH REPAYMENT`,
      sheet: "ENACH REPAYMENT",
    });
  }
}
