import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { MasterService } from './Shared/app.Masters.Service';
import devtools from 'devtools-detect';
import { BnNgIdleService } from 'bn-ng-idle';
import { PresenceService } from '../../src/app/_Chatbox/_service/presence.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./../assets/Content/vendor/bootstrap/css/bootstrap.min.css',
    './../assets/Content/vendor/metisMenu/metisMenu.min.css',
    './../assets/Content/dist/css/sb-admin-2.css',
    './../assets/Content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})
export class AppComponent implements OnInit {
  title = 'FINNAUX TECHSOLUTIONS PRIVATE LIMITED';

  constructor(private _Route: Router, private _masterService: MasterService, private bnIdle: BnNgIdleService, private presenceService: PresenceService) {
    this.bnIdle.startWatching(1800).subscribe((res) => {
      if (res) {
        sessionStorage.removeItem('currentUser');
        sessionStorage.clear();
        this.presenceService.currentUser.next(null);

        this._Route.navigate(['Login']);
      }
    })
  }

  ngOnInit() {

    $(document).on('click', '#sidebar-toggle', function (e) {
      e.preventDefault();
      document.querySelector('#wrapper').classList.toggle('toggled');
    });
    if (environment.production) {
      var allow;
      if (isNaN(+allow)) allow = 100;
      var start = +new Date();
      debugger;
      var end = +new Date();
      if (isNaN(start) || isNaN(end) || end - start > allow) {
        this._Route.navigate([`Page403Error`]);
      }

    }
    //if ( devtools.isOpen) 
    //  {
    //  this._Route.navigate([`Page403Error`]);
    //  }

    if (environment.production) {

      document.addEventListener('contextmenu', (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }
      document.onkeydown = (e) => {
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, 'I') ||
          ctrlShiftKey(e, 'J') ||
          ctrlShiftKey(e, 'C') ||
          (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
          return false;
      };
      document.addEventListener('contextmenu',
        event => event.preventDefault());

      window.addEventListener("keyup", kPress, false);
      function kPress(e) {
        var c = e.keyCode || e.charCode;
        if (c == 44) event.preventDefault();
      }

      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    
    this._masterService.CheckMACAllowed({"MacAddresss":this._masterService.getMacAddress()}).subscribe(response=>{
      if(!(response[0]["CODE"]==0 && response[0]["MSG"]=="Allow")){
        this._Route.navigate([`Page403Error`]);
      }
      console.log("H Start h",response)
    })


  }

  
}
