<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form #ta="ngForm" novalidate class="formborder">
  <div class="row m-0 col-md-12 p-0 d-flex justify-content-between">
    <div class="col-md-2">
      <span class="required-lable"> Start Month Year </span>
      <input
        name="st_month_year"
        [(ngModel)]="st_month_year"
        id="st_month_year"
        placeholder="Start Month Year"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
        (onShown)="onOpenCalendar($event)"
        (ngModelChange)="DateChange()"
        required
        #refst_month_year="ngModel"
        [ngClass]="{
          'is-invalid': ta.submitted && refst_month_year.invalid,
          'alert-warning':
            refst_month_year.invalid &&
            (refst_month_year.dirty ||
              refst_month_year.touched ||
              refst_month_year.untouched)
        }"
      />
    </div>
    <div class="col-md-2">
      <span class="required-lable"> End Month Year </span>
      <input
        name="en_month_year"
        [(ngModel)]="en_month_year"
        id="en_month_year"
        placeholder="End Month Year"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
        (onShown)="onOpenCalendar($event)"
        (ngModelChange)="DateChange()"
        required
        #refen_month_year="ngModel"
        [ngClass]="{
          'is-invalid': ta.submitted && refen_month_year.invalid,
          'alert-warning':
            refen_month_year.invalid &&
            (refen_month_year.dirty ||
              refen_month_year.touched ||
              refen_month_year.untouched)
        }"
      />
    </div>
    <div class="col-md-2 align-self-end">
      <button
        type="button"
        class="mt-3 btn font-size-12 button-btn"
        (click)="Get_EmployeeForTreeMap()"
        [disabled]="!ta.form.valid"
      >
        Search
      </button>
    </div>
    <div class="col-md-2 align-self-end">
      <button
        type="button"
        (click)="showTarget()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Target
      </button>
    </div>
    <!--<div class="col-md-2 align-self-end">
          <button type="button" (click)="isVertical=!isVertical" class="mt-3 btn font-size-12 button-btn">
            Toggle
            View
          </button>
        </div>-->
  </div>
</form>

<div
  style="display: flex; flex-direction: column; align-items: center"
  class="formborder mt-2"
>
  <div
    class="grid-container"
    *ngIf="Level1.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 1</span>
    </div>

    <div>
      <table
        mat-table
        *ngIf="C_Level1"
        [dataSource]="Level1"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level1[iCol][iRow].span"
            [style.display]="C_Level1[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>
  </div>

  <div
    class="grid-container"
    *ngIf="Level2.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 2</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level2"
      [dataSource]="Level2"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef style="height: 32px">
          {{ columnName }}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level2[iCol][iRow].span"
          [style.display]="C_Level2[iCol][iRow].span === 0 ? 'none' : ''"
        >
          <div *ngIf="columnName != 'Emp_Name'">{{ row[columnName] }}</div>
          <a
            href="javascript:void(0)"
            (click)="goToEmp_NameDetail(row)"
            *ngIf="columnName == 'Emp_Name'"
            >{{ row[columnName] }}</a
          >
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level3.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 3</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level3"
      [dataSource]="Level3"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level3[iCol][iRow].span"
          [style.display]="C_Level3[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level4.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 4</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level4"
      [dataSource]="Level4"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level4[iCol][iRow].span"
          [style.display]="C_Level4[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level5.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 5</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level5"
      [dataSource]="Level5"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level5[iCol][iRow].span"
          [style.display]="C_Level5[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level6.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 6</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level6"
      [dataSource]="Level6"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level6[iCol][iRow].span"
          [style.display]="C_Level6[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level7.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 7</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level7"
      [dataSource]="Level7"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level7[iCol][iRow].span"
          [style.display]="C_Level7[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level8.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 8</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level8"
      [dataSource]="Level8"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level8[iCol][iRow].span"
          [style.display]="C_Level8[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>

  <div
    class="grid-container"
    *ngIf="Level9.length > 0"
    style="margin-bottom: 10px"
  >
    <div class="tableDiv">
      <span class="levelSpan">Level 9</span>
    </div>
    <table
      mat-table
      *ngIf="C_Level9"
      [dataSource]="Level9"
      class="mat-elevation-z8"
    >
      <ng-container
        *ngFor="let columnName of columnNames; let iCol = index"
        [matColumnDef]="columnName"
      >
        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
        <td
          mat-cell
          *matCellDef="let row; let iRow = index"
          [attr.rowspan]="C_Level9[iCol][iRow].span"
          [style.display]="C_Level9[iCol][iRow].span === 0 ? 'none' : ''"
        >
          {{ row[columnName] }}
        </td>
      </ng-container>
      <tr mat-header-row class="table-row" *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>
</div>

<div
  class="modal fade in"
  id="modalTable"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 800px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">Month</h6>
        <button
          type="button"
          class="close"
          (click)="OnClose()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <form #modelform="ngForm" novalidate>
        <div class="row m-0 col-md-12 p-0 d-flex justify-content-between">
          <div class="col-md-3">
            <span class="required-lable"> Start Month Year </span>
            <input
              name="modalst_month_year"
              [(ngModel)]="modalst_month_year"
              id="modalst_month_year"
              placeholder="Start Month Year"
              class="form-control input-text-css"
              bsDatepicker
              [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
              (onShown)="onOpenCalendar($event)"
              (ngModelChange)="modalDateChange()"
              required
              #refmodalst_month_year="ngModel"
              [ngClass]="{
                'is-invalid':
                  modelform.submitted && refmodalst_month_year.invalid,
                'alert-warning':
                  refmodalst_month_year.invalid &&
                  (refmodalst_month_year.dirty ||
                    refmodalst_month_year.touched ||
                    refmodalst_month_year.untouched)
              }"
            />
          </div>
        </div>
      </form>

      <div class="row m-0 col-md-12 p-0">
        <div class="col-md-12">
          <hr />
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div
              class="table-responsive"
              style="height: 363px; width: 100%; overflow: auto"
            >
              <mat-table
                [dataSource]="dataSourceModel"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="height: 380px; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="Employee_Id">
                  <th
                    mat-sort-header
                    mat-header-cell
                    *matHeaderCellDef
                    class="width50"
                  >
                    #
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="width50"
                  >
                    {{ i + 1 }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="Emp_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Emoployee Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Emp_Name }}
                  </mat-cell>
                  <mat-footer-cell
                    *matFooterCellDef
                    class="grid-header"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Target_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Target Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div>
                      <input
                        name="targetAmount {{ row.Target_Amount }}"
                        [disabled]="row.Target_Amount > 0 && row.TargetId > 0"
                        [(ngModel)]="row.Target_Amount"
                        id="targetAmount{{ row.Target_Amount }}"
                        class="form-control input-text-css"
                        type="number"
                        min="0"
                        required
                        #reftargetAmount="ngModel"
                      />
                    </div>
                  </mat-cell>
                  <mat-footer-cell
                    *matFooterCellDef
                    class="grid-header"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Action</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <button
                      type="button"
                      *ngIf="row.TargetId > 0"
                      style="
                        background-color: #28a745;
                        color: #ffffff;
                        font-size: 12px;
                      "
                      class="btn btn-light font-size-18"
                      (click)="ReviseTargetAmount(row)"
                    >
                      Revise
                    </button>
                  </mat-cell>
                  <mat-footer-cell
                    *matFooterCellDef
                    class="grid-header"
                  ></mat-footer-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsModel; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsModel"
                ></mat-row>
                <mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedColumnsModel; sticky: true"
                  style="background: #4dc3a3"
                ></mat-footer-row>
              </mat-table>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end">
          <button class="btn btn-success font-size-12" (click)="saveButton()">
            <i
              class="fa fa-save mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="reviseTargetmodal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Revise Target
        </h6>
        <button
          type="button"
          (click)="OnCloseReviseModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #ff="ngForm" novalidate>
          <div>
            <div class="col-md-12 p-1">
              <span class="required-lable">Revise Target</span>

              <input
                type="number"
                name="reviseTarget"
                id="reviseTarget"
                [(ngModel)]="reviseTarget"
                class="form-control input-text-css"
                required
                #refreviseTarget="ngModel"
                placeholder="Revise Target Amount"
                [ngClass]="{
                  'is-invalid': ff.submitted && refreviseTarget.invalid,
                  'alert-warning':
                    refreviseTarget.invalid &&
                    (refreviseTarget.dirty ||
                      refreviseTarget.touched ||
                      refreviseTarget.untouched)
                }"
              />
            </div>

            <div class="col-md-12 p-1">
              <span class="required-lable">Remarks</span>
              <input
                type="text"
                name="reviseRemarks"
                id="reviseRemarks"
                [(ngModel)]="reviseRemarks"
                class="form-control input-text-css"
                required
                #refreviseRemarks="ngModel"
                placeholder="Remarks"
                [ngClass]="{
                  'is-invalid': ff.submitted && refreviseRemarks.invalid,
                  'alert-warning':
                    refreviseRemarks.invalid &&
                    (refreviseRemarks.dirty ||
                      refreviseRemarks.touched ||
                      refreviseRemarks.untouched)
                }"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="background-color: #28a745; color: #ffffff; font-size: 12px"
          [disabled]="!ff.form.valid"
          class="btn btn-light font-size-12"
          (click)="saveReviseTarget()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
