import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { LmsService } from "../../services/lms.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { map } from "rxjs/operators";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-main-card-info",
  templateUrl: "./main-card-info.component.html",
  styleUrls: ["./main-card-info.component.scss"],
})
export class MainCardInfoComponent implements OnInit {
  @Input() loanId: any;
  resData: any;
  applicationId: any;
  object = Object;
  AssetsAttachment: any[] = [];
  DocModel: any;
  ApplicationNumber: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;

  constructor(
    private LmsService: LmsService,
    private router: ActivatedRoute,
    private _EncrDecrService: EncrDecrService
  ) {
    console.log("LoanIf in maincard:", this.loanId);
    this.router.paramMap.subscribe((param: ParamMap) => {
      if (this.loanId == null)
        this.loanId = Number(
          this._EncrDecrService.decrypt(decodeURIComponent(param.get("id")))
        );
    });
  }

  ngOnInit(): void {
    this.LmsService.LMS_Get_Loan_Card_Info({ LoanId: this.loanId })
      .pipe(
        map((res: any) => {
          console.log("response:", res);

          this.AssetsAttachment = res.Item17;
          console.log("AssetsAttachment", this.AssetsAttachment);

          var data: any = {};
          //value set item2

          for (let item of res?.Item2) {
            // console.log("item"+item)
            data[item.CustomerId] = { Item2: item };
          }
          for (let item of res?.Item3) {
            data[item.CRC_CustomerId].Item3 = item;
          }

          for (let item of res?.Item4) {
            if (!!!data[item.CustomerId].Item4) {
              data[item.CustomerId].Item4 = [item];
            } else {
              data[item.CustomerId].Item4.push(item);
            }
          }

          for (let item of res?.Item5) {
            // data[item.Customer_Id].Item5=item
            if (!!!data[item.Customer_Id].Item5) {
              data[item.Customer_Id].Item5 = [item];
            } else {
              data[item.Customer_Id].Item5.push(item);
            }
          }
          for (let item of res?.Item6) {
            // data[item.CR_CustomerId].Item6=item
            if (!!!data[item.CR_CustomerId].Item6) {
              data[item.CR_CustomerId].Item6 = [item];
            } else {
              data[item.CR_CustomerId].Item6.push(item);
            }
          }
          for (let item of res?.Item9) {
            if (!!!data[item.KYC_CustomerId].Item9) {
              data[item.KYC_CustomerId].Item9 = [item];
            } else {
              data[item.KYC_CustomerId].Item9.push(item);
            }
          }
          for (let item of res?.Item10) {
            if (!!!data[item.CustomerId].Item10) {
              data[item.CustomerId].Item10 = [item];
            } else {
              data[item.CustomerId].Item10.push(item);
            }
          }
          for (let item of res?.Item11) {
            if (!!!data[item.TVR_CusrtomerId].Item11) {
              data[item.TVR_CusrtomerId].Item11 = [item];
            } else {
              data[item.TVR_CusrtomerId].Item11.push(item);
            }
          }
          for (let item of res?.Item12) {
            if (!!!data[item.Customer_Id].Item12) {
              data[item.Customer_Id].Item12 = [item];
            } else {
              data[item.Customer_Id].Item12.push(item);
            }
          }
          for (let item of res?.Item13) {
            if (!data[item.CustomerName].Item13) {
              data[item.CustomerName].Item13 = [item];
            } else {
              data[item.CustomerName].Item13.push(item);
            }
          }

          //sort data as per Borrower or CoBorrower and Gu...
          var arr = [];
          Object.keys(data).forEach((x) => {
            arr.push(data[x]);
            // console.log("new Dta",arr)
          });
          arr.sort((a: any, b: any) => {
            // console.log("A value:",a.Item2.AC_CustomerType)
            // console.log("b value:",b.Item2.AC_CustomerType)
            if (a.Item2.AC_CustomerType == "H") return -7;
            if (
              a.Item2.AC_CustomerType == "C" &&
              b.Item2.AC_CustomerType == "H"
            )
              return 1;
            if (
              a.Item2.AC_CustomerType == "C" &&
              b.Item2.AC_CustomerType == "G"
            )
              return -1;
            if (a.Item2.AC_CustomerType == "G") return 877;
          });

          return {
            Item1: res?.Item1,
            data: arr,
            asset: res?.Item7,
            goldAsset:res?.Item20,
            deviation: res?.Item14,
            sanction: res?.Item15,
            Item16: res?.Item16,
            AssetsAttachment: res?.Item17,
            ApplicationNumber: res?.Item1,
          };
        })
      )
      .subscribe((response) => {
        console.log("Final data:", response);

        this.resData = response;
        this.applicationId = response?.Item1[0].Application_No;
      });
  }

  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.AssetsAttachment.splice(i, 1);
  }
}
