<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="searchApplication()">
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span> Application Status: </span>
        <select name="ApplicationStatus" id="ApplicationStatus" class="form-control input-text-css" [(ngModel)]="ApplicationStatus">
          <option value="ALL"> All Status </option>
          <option value="Created"> Created </option>
          <option value="Inprogress"> Inprogress </option>
          <option value="Approved"> Approved </option>
          <option value="Rejected"> Rejected </option>
          <option value="ConvertToLoan"> Converted To Loan </option>
        </select>
      </div>
      <div class="col-md-3">
        <span> Application No: </span>
        <input type="text" name="ApplicationNo" id="ApplicationNo" class="form-control input-text-css" [(ngModel)]="ApplicationNo">
      </div>
      <div class="col-md-3">
        <button type="button" (click)="searchApplication()" class="btn font-size-12 button-btn" style="margin-top:18px;"> Search </button>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ....">
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0 col-md-12 p-0" [hidden]="!dataSource">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource" matSort style="height: 370px; max-width: 100%; overflow: auto;">
          <ng-container matColumnDef="ApplicationId">
            <mat-header-cell mat-sort-header class="grid-header" style="max-width:50px" *matHeaderCellDef> #
            </mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:100px"> Application No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:100px"> {{row.ApplicationNo}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Customer </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Customer}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Branch}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:350px"> Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:350px"> {{row.Product}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="text-align: right"> Loan Amount </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="text-align:right"> {{row.LoanAmount.toFixed(2)}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanDuration_Month">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:100px"> Duration(Month)
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:100px"> {{row.LoanDuration_Month}} Months </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationCreatedOn">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:100px"> Created On </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:100px"> {{row.ApplicationCreatedOn}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Application_Status">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:100px"> Status </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:100px"> {{row.Application_Status}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EditAction">
            <mat-header-cell mat-sort-header class="grid-header" style="max-width:60px" *matHeaderCellDef> Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width:60px">
              <i class="fa fa-arrow-circle-right" (click)="goToDetail(row)" style="cursor: pointer;color: green;  font-size: 16px" aria-label="true"></i>
            </mat-cell>
          </ng-container>

          <mat-header-row class="sticky-footer" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div>
</div>
