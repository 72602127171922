<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div style="height: 250px; max-width: 100%; overflow: auto">
    <form #f="ngForm" novalidate>
      <div class="row m-0">
        <div class="col-md-4 p-1" *ngIf="LoanSeries == 'Automatic'">
          <span class="required-lable">Loan Series</span>
          <select
            name="Loan_Series"
            (change)="LMS_Get_Loan_No()"
            id="Loan_Series"
            #refLoan_Series="ngModel"
            required
            [disabled]="isDisabled"
            [(ngModel)]="loanNoModel.Loan_Series"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refLoan_Series.invalid,
              'alert-warning':
                refLoan_Series.invalid &&
                (refLoan_Series.dirty ||
                  refLoan_Series.touched ||
                  refLoan_Series.untouched)
            }"
          >
            <option value="">Select Loan Series</option>
            <option
              *ngFor="let LoanSeries of LoanSeriesList"
              [value]="LoanSeries.Series"
            >
              {{ LoanSeries.Series }}
            </option>
          </select>
        </div>
        <div class="col-md-4 p-1">
          <span class="required-lable">Loan No</span>
          <!--<input name="Loan_No" id="Loan_No" #refLoan_No="ngModel" minlength="10" maxlength="10" pattern="[a-zA-Z0-9-_.]{3}[0-9]{7}"
                     [ngClass]="{ 'is-invalid': f.submitted && refLoan_No.invalid, 'alert-warning': refLoan_No.invalid  && (refLoan_No.dirty || refLoan_No.touched || refLoan_No.untouched) }"
                     [(ngModel)]="loanNoModel.Loan_No" required [disabled]="(loanSummary.LoanNo != '' && loanSummary.LoanNo != null)||LoanSeries == 'Automatic'  "
                     placeholder="Loan No" class="form-control input-text-css Uppe_Case">-->
          <input
            name="Loan_No"
            id="Loan_No"
            required
            #refLoan_No="ngModel"
            minlength="10"
            maxlength="10"
            *ngIf="LoanSeries == 'Automatic'"
            (keypress)="keyPressAlphanumeric($event)"
            pattern="[a-zA-Z0-9-_.]{3}[0-9]{7}"
            [ngClass]="{
              'is-invalid': f.submitted && refLoan_No.invalid,
              'alert-warning':
                refLoan_No.invalid &&
                (refLoan_No.dirty || refLoan_No.touched || refLoan_No.untouched)
            }"
            [(ngModel)]="loanNoModel.Loan_No"
            [disabled]="
              (loanSummary.LoanNo != '' && loanSummary.LoanNo != null) ||
              LoanSeries == 'Automatic'
            "
            placeholder="Loan No"
            class="form-control input-text-css Uppe_Case"
          />
          <input
            name="Loan_No"
            id="Loan_No"
            #refLoan_No="ngModel"
            minlength="1"
            maxlength="12"
            required
            [ngClass]="{
              'is-invalid': f.submitted && refLoan_No.invalid,
              'alert-warning':
                refLoan_No.invalid &&
                (refLoan_No.dirty || refLoan_No.touched || refLoan_No.untouched)
            }"
            *ngIf="LoanSeries != 'Automatic'"
            [(ngModel)]="loanNoModel.Loan_No"
            [disabled]="
              (loanSummary.LoanNo != '' && loanSummary.LoanNo != null) ||
              LoanSeries == 'Automatic'
            "
            placeholder="Loan No"
            class="form-control input-text-css Uppe_Case"
          />
        </div>
        <div class="col-md-4 p-1">
          <span class="required-lable">Loan Date</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="Loan_Date"
              id="Loan_Date"
              required
              [min]="minDate"
              #refLoan_Date="ngModel"
              [disabled]="isDisabled"
              class="form-control input-text-css"
              [(ngModel)]="loanNoModel.Loan_Date"
              [ngClass]="{
                'is-invalid': f.submitted && refLoan_Date.invalid,
                'alert-warning':
                  refLoan_Date.invalid &&
                  (refLoan_Date.dirty ||
                    refLoan_Date.touched ||
                    refLoan_Date.untouched)
              }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-4 p-1">
          <span class="required-lable">First EMI Date</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker1"
              [min]="loanNoModel.Loan_Date"
              dateConvert
              name="Loan_First_EMIDate"
              id="Loan_First_EMIDate"
              required
              placeholder="DD/MM/YYYY"
              #refLoan_First_EMIDate="ngModel"
              [disabled]="isDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refLoan_First_EMIDate.invalid,
                'alert-warning':
                  refLoan_First_EMIDate.invalid &&
                  (refLoan_First_EMIDate.dirty ||
                    refLoan_First_EMIDate.touched ||
                    refLoan_First_EMIDate.untouched)
              }"
              class="form-control input-text-css"
              [(ngModel)]="loanNoModel.Loan_First_EMIDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>
        </div>
        <div class="col-md-4 p-1">
          <span class="required-lable">Accounting Category</span>
          <select
            name="Loan_AccountingCategoryId"
            id="Loan_AccountingCategoryId"
            #refLoan_AccountingCategoryId="ngModel"
            required
            [disabled]="isDisabled"
            [(ngModel)]="loanNoModel.Loan_AccountingCategoryId"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refLoan_AccountingCategoryId.invalid,
              'alert-warning':
                refLoan_AccountingCategoryId.invalid &&
                (refLoan_AccountingCategoryId.dirty ||
                  refLoan_AccountingCategoryId.touched ||
                  refLoan_AccountingCategoryId.untouched)
            }"
          >
            <option value="">Select Accounting Category</option>
            <option
              *ngFor="let AccountingCat of AccountingCatDropdown"
              [value]="AccountingCat.Loan_CategoryId"
            >
              {{ AccountingCat.Loan_category }}
            </option>
          </select>
        </div>
        <div class="col-md-4 p-1">
          <span class="required-lable">Loan Category </span>
          <select
            name="SelectCRCType"
            id="SelectCRCType"
            #refSelectCRCType="ngModel"
            [disabled]="isDisabled"
            [(ngModel)]="loanNoModel.CRC_LoanType"
            [ngClass]="{
              'is-invalid': f.submitted && refSelectCRCType.invalid,
              'alert-warning':
                refSelectCRCType.invalid &&
                (refSelectCRCType.dirty ||
                  refSelectCRCType.touched ||
                  refSelectCRCType.untouched)
            }"
            class="form-control input-text-css"
            required
          >
            <option value="">Select Loan Type</option>
            <option *ngFor="let Type of CRC_Type" [value]="Type.Id">
              {{ Type.Value + "[" + Type.Code + "]" }}
            </option>
          </select>
        </div>
        <div
          class="col-md-4 p-1"
          *ngIf="SelectAssignEmp != '' && SelectAssignEmp != '0'"
        >
          <span>Assign Employee</span>
          <select
            name="SelectAssignEmp"
            id="SelectAssignEmp"
            [disabled]="!loanSummary.Mannual_Assignment || loanSummary.LoanNo"
            class="form-control input-text-css"
            [(ngModel)]="SelectAssignEmp"
          >
            <option value="">Select Assign Employee</option>
            <option *ngFor="let row of AsignEmpData" [value]="row.EmpId">
              {{ row.EmpName }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="row m-0 mt-3" *ngIf="!loanSummary.LoanNo">
    <div class="col-md-10 p-1">
      <span>Remarks</span>
      <textarea
        rows="1"
        name="Remarks"
        id="Remark"
        [(ngModel)]="Remarks"
        #refRemark="ngModel"
        placeholder="Remarks"
        class="form-control input-text-css"
      ></textarea>
    </div>
    <div class="col-md-2 p-1 mt-3 text-right">
      <button
        type="button"
        class="btn font-size-12 button-btn mt-3"
        [disabled]="!f.form.valid"
        (click)="onCalculateEmiAndIRR()"
      >
        Submit
      </button>
    </div>
    <!-- <button type="button" (click)="onNextStep()" mat-raised-button class="btn_default" [class.spinner]="loading"
            [disabled]="!f.form.valid">
            Next <i class="fa fa-arrow-right"></i>
        </button> -->
  </div>
</div>
<div
  class="modal fade in"
  id="EMICalculatorAmort"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    role="document"
    style="max-width: 900px !important"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          EMI Calculator
        </h6>
        <button
          type="button"
          (click)="onCloseEMICalculator()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-0" *ngIf="emiIrr">
          <div class="col-md-12 mb-1 alert alert-success">
            <div class="row">
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>EMI Type : {{ loanSummary.EMI_Type }}</B></span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>First EMI Date :</B>
                  {{
                    loanNoModel.Loan_First_EMIDate | date : "dd-MM-yyyy"
                  }}</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Loan Date :</B>
                  {{ loanNoModel.Loan_Date | date : "dd-MM-yyyy" }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>No Of Instl :</B> {{ loanSummary.No_Of_Instl }}
                </span>
              </div>

              <div class="col-md-4">
                <span class="fs-12"
                  ><B
                    >Disbursement Amount : ₹
                    {{ emiIrr.Disbursement_Amt.toFixed(2) }}</B
                  ></span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Interest Amount :</B> ₹
                  {{ emiIrr.Interest_Amt.toFixed(2) }}</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>Agreement Value :</B> ₹
                  {{ emiIrr.Agreement_Value.toFixed(2) }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>LTV :</B> {{ emiIrr.LTV.toFixed(2) }} %</span
                >
              </div>

              <div
                class="col-md-4"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <span class="fs-12"
                  ><B>Disbursement IRR :</B>
                  {{ emiIrr.Disbursement_IRR }} %</span
                >
              </div>
              <div class="col-md-3">
                <span class="fs-12"
                  ><B>EMI Amount :</B> ₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</span
                >
              </div>
              <div
                class="col-md-3"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly'
                "
              >
                <span class="fs-12"
                  ><B>Case IRR :</B> {{ emiIrr.Case_IRR }} %</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>ROI :</B> {{ emiIrr.ROI.toFixed(2) }} %</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="AmortizationSource">
          <mat-table
            [dataSource]="AmortizationSource"
            matTableExporter
            #exporter="matTableExporter"
          >
            <ng-container matColumnDef="PERIOD">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 50px"
              ></mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="EMI_Amount">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >EMI_Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.EMI_Amount
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("EMI_Amount")
              }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="DueDate">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >DueDate</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.DueDate
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Principle_OS">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Principle_OS</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Principle_OS
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="INTEREST">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >INTEREST</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.INTEREST
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("INTEREST")
              }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PRINCIPAL">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >PRINCIPAL</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.PRINCIPAL
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                totaltypewise("PRINCIPAL")
              }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="export">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                <button
                  class="btn font-size-12 button-btn"
                  (click)="exportTable()"
                >
                  <i
                    class="fa fa-file-export mr-1"
                    style="font-size: medium; cursor: pointer"
                  ></i>
                  Export
                </button>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell"></mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="AmortizationColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: AmortizationColumns"
              style="cursor: pointer"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="AmortizationColumns; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
        <div class="col-md-12 row m-0 mt-3 justify-content-end">
          <button
            type="button"
            (click)="onNextStep()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Final Submit <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="quickloan_generate_loan"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Emi Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle OS
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.DueDate }}</td>
    <td>{{ x.EMI_Amount }}</td>
    <td>{{ x.PRINCIPAL }}</td>
    <td>{{ x.INTEREST }}</td>
    <td>{{ x.Principle_OS }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_Amount") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ totaltypewise("PRINCIPAL") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("INTEREST") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
    </tr>
  </tfoot>
</table>
