<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="searchQuickloanApplicationStatusReport()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateFrom"
          [(ngModel)]="DateFrom"
          id="DateFrom"
          class="form-control input-text-css"
          required
          #refDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateFrom.invalid,
            'alert-warning':
              refDateFrom.invalid &&
              (refDateFrom.dirty ||
                refDateFrom.touched ||
                refDateFrom.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateTo"
          [(ngModel)]="DateTo"
          id="DateTo"
          class="form-control input-text-css"
          required
          #refDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateTo.invalid,
            'alert-warning':
              refDateTo.invalid &&
              (refDateTo.dirty || refDateTo.touched || refDateTo.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="searchQuickloanApplicationStatusReport()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="float-end col-md-4 mt-2" [hidden]="!DataSource">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!DataSource">
  <div class="">
    <div class="table-responsive" style="height: 350px; overflow: auto">
      <table
        mat-table
        class="table-bordered"
        matSort
        [dataSource]="DataSource"
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="Id">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + i + 1 }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Application No
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Application_No }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.CustomerName }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Ac No</th>
          <td mat-cell *matCellDef="let row">
            {{ row.LoanAcNo }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Process_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Process Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.Process_Name }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="empName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Employee Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.empName }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="BranchName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let row">
            {{ row.BranchName }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="ProductName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">
            {{ row.ProductName }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="ApplicationCreatedOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Application Created On
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.ApplicationCreatedOn }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Source Type</th>
          <td mat-cell *matCellDef="let row">
            {{ row.SourceType }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="SourceName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Source Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row.SourceName }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="SalesExecutive">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Sales Executive
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.SalesExecutive }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="RequiredAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Required Amount
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.RequiredAmount }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="Sanction_Or_ApprovedAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Sanctioned/Approved Amount
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.Sanction_Or_ApprovedAmount }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="TAT_Hr">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>TAT Hr</th>
          <td mat-cell *matCellDef="let row">
            {{ row.TAT_Hr }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="highlightRow(row)"
          [class.highlight]="row.Loan_Id == selectedId"
        ></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
  </div>
</div>

<div class="row m-0 ml-3" [hidden]="!DataSource">
  <div class="col-md-4 mt-3">
    <button class="mt-2 btn font-size-12 button-btn" (click)="exportexcelNew()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-2">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="application_status_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Process Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application Created On
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Required Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Employee Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sanctioned/Approved Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT_Hr
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Process_Name }}</td>
    <td>{{ x.empName }}</td>
    <td>{{ x.BranchName }}</td>
    <td>{{ x.ProductName }}</td>
    <td>{{ x.ApplicationCreatedOn }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.RequiredAmount }}</td>
    <td>{{ x.Sanction_Or_ApprovedAmount }}</td>
    <td>{{ x.TAT_Hr }}</td>
  </tr>
</table>
