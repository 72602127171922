import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-403-error',
  templateUrl: './page-403-error.component.html',
  styleUrls: ['./page-403-error.component.scss']
})
export class AppPage403ErrorComponent implements OnInit {
    constructor() {

    }

    ngOnInit() {
    }

}
