<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchReport()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3 p-1">
        <span class="required-lable"> Member ID</span>
        <input
          name="MemberId"
          [(ngModel)]="CRCModel.MemberId"
          id="MemberId"
          placeholder="MemberId"
          class="form-control input-text-css"
          required
          #refMemberId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refMemberId.invalid,
            'alert-warning':
            refMemberId.invalid &&
              (refMemberId.dirty || refMemberId.touched || refMemberId.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span > Previous Member Id</span>
        <input
          name="PreviousMemberId"
          [(ngModel)]="CRCModel.PreviousMemberId"
          id="PreviousMemberId"
          placeholder="Previous Member Id"
          class="form-control input-text-css"
         
          #refPreviousMemberId="ngModel"
          
        />
      </div>
      <div class="col-md-3">
        <span class="required-lable">To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>

      <div class="col-md-3 text-right">
        <button
          type="button"
          (click)="onSearchReport()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <mat-tab-group *ngIf="Show" class="mt-2">
    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>BS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="table-responsive">
              <mat-table
                [dataSource]="DataSourceBS"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >LoanAcNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Flag">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Flag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Flag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Segment_Identifier">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Segment Identifier</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Segment_Identifier }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Member_Branch_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Member Branch Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Member_Branch_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Previous_Member_Branch_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Previous Member Branch Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Previous_Member_Branch_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Borrowers_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Borrowers Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Borrowers_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Borrower_Short_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Borrower Short Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Borrower_Short_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Company_Registration_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Company Registration Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Company_Registration_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Incorporation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Incorporation</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Incorporation }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PAN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >PAN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.PAN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >CIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >TIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.TIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Service_Tax">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Service Tax</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Service_Tax }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Other_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Other ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Other_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Borrowers_Legal_Constitution">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Borrowers Legal Constitution</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Borrowers_Legal_Constitution }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessCategory">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Business Category</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessCategory }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Business Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessType }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Borrowers_Legal_Constitution_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Borrowers Legal Constitution Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Borrowers_Legal_Constitution_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessCategory_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BusinessCategory Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessCategory_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessType_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BusinessType Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessType_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Class_of_Activity_1">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Class of Activity 1</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Class_of_Activity_1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Class_of_Activity_2">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Class of Activity 2</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Class_of_Activity_2 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Class_of_Activity_3">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Class of Activity 3</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Class_of_Activity_3 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SIC_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >SIC Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.SIC_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Sales_Figure">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Sales Figure</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Sales_Figure }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Financial_Year">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Financial Year</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Financial_Year }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Number_of_Employees">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Number of Employees</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Number_of_Employees }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Credit_Rating">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Credit Rating</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Credit_Rating }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Assessment_Agency_Authority">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Assessment Agency Authority</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Assessment_Agency_Authority }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Credit_Rating_As_On">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Credit Rating As On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Credit_Rating_As_On }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Credit_Rating_Expiry_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Credit Rating Expiry Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Credit_Rating_Expiry_Date }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumnsBS; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsBS"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>AS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <mat-table
              [dataSource]="DataSourceAS"
              matSort
              matTableExporter
              #exporter="matTableExporter"
              style="max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >LoanAcNo</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.LoanAcNo }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Flag">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Flag</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Flag }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Segment_Identifier">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Segment Identifier</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Segment_Identifier }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Borrower_Office_Location_Type">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Borrower Office Location Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Borrower_Office_Location_Type }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Borrower_Office_DUNS_Number">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Borrower Office DUNS Number</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Borrower_Office_DUNS_Number }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Address_Line_1">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Address Line 1</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Address_Line_1 }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Address_Line_2">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Address Line 1</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Address_Line_2 }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Address_Line_3">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Address Line 3</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Address_Line_3 }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="City_Town">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >City Town</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.City_Town }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="District">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >District</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.District }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="State_Union_Territory">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >State Union Territory</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.State_Union_Territory }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Pin_Code">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Pin Code</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Pin_Code }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Country">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Country</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Country }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Mobile_Number">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Mobile Number</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Mobile_Number }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Telephone_Area_Code">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Telephone Area Code</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Telephone_Area_Code }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Telephone_Number">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Telephone Number</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Telephone_Number }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Fax_Area_Code">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Fax Area Code</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Fax_Area_Code }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Fax_Number">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Fax Number</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Fax_Number }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                class="sticky-footer"
                *matHeaderRowDef="displayedColumnsAS; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsAS"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>RS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="table-responsive">
              <mat-table
                [dataSource]="DataSourceRS"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >LoanAcNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Flag">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Flag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Flag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Segment_Identifier">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Segment Identifier</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Segment_Identifier }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Relationship_DUNS_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relationship DUNS Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Relationship_DUNS_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Related_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Related Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Related_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Relationship">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relationship</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Relationship }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Relationship_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relationship Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Relationship_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Business_Entity_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Business Entity Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Business_Entity_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessCategory_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BusinessCategory Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessCategory_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BusinessType_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BusinessType Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.BusinessType_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Individual_Name_Prefix">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Individual Name Prefix</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Individual_Name_Prefix }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Full_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Full Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Full_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Gender">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Gender }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Company_Registration_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Company Registration Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Company_Registration_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Incorporation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Incorporation</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Incorporation }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Birth">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Birth</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Birth }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PAN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >PAN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.PAN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Voter_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Voter ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voter_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Passport_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Passport Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Passport_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Driving_Licence_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Driving Licence ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Driving_Licence_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >UID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.UID }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Ration_Card_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Ration Card No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Ration_Card_No }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >CIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >DIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >TIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.TIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Service_Tax">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Service Tax</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Service_Tax }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Other_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Other ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Other_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Percentage_of_Control">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Percentage of Control</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Percentage_of_Control }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address_Line_1">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 1</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_1 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address_Line_2">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 1</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_2 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address_Line_3">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 3</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_3 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="City_Town">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >City Town</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.City_Town }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="District">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >District</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.District }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="State_Union_Territory">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >State Union Territory</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.State_Union_Territory }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Pin_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Pin Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Pin_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Country">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Country</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Country }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Mobile_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Mobile Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Mobile_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Telephone_Area_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Telephone Area Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Telephone_Area_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Telephone_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Telephone Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Telephone_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Fax_Area_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Fax Area Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Fax_Area_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Fax_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Fax Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Fax_Number }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumnsRS; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsRS"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>CR Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="table-responsive">
              <mat-table
                [dataSource]="DataSourceCR"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="Ac_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >A/c No.</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Ac_No }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Flag">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Flag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Flag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Segment_Identifier">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Segment Identifier</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Segment_Identifier }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Account_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Account Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Account_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Previous_Account_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Previous Account Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Previous_Account_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Facility_Loan_Activation_Sanction_Date"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Facility Loan Activation Sanction Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Facility_Loan_Activation_Sanction_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Sanctioned_Amount_Notional_Amount_of_Contract"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Sanctioned Amount Notional Amount of
                    Contract</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Sanctioned_Amount_Notional_Amount_of_Contract }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Currency_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Currency Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Currency_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Credit_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Credit Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Credit_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Tenure_Weighted_Average_maturity_period_of_Contracts"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Tenure Weighted Average maturity period of
                    Contracts</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.Tenure_Weighted_Average_maturity_period_of_Contracts
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Repayment_Frequency">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Repayment Frequency</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Repayment_Frequency }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Drawing_Power">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Drawing Power</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Drawing_Power }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Current_Balance_Limit_Utilized_Mark_to_Market"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Current Balance Limit Utilized Mark to
                    Market</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Current_Balance_Limit_Utilized_Mark_to_Market }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Notional_Amount_of_Out_standing_Restructured_Contracts"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Notional Amount of Out standing Restructured
                    Contracts</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.Notional_Amount_of_Out_standing_Restructured_Contracts
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Loan_Expiry_Maturity_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan Expiry Maturity Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Loan_Expiry_Maturity_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Loan_Renewal_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan Renewal Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Loan_Renewal_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Asset_Classification_Number_of_days_past_due_NDPD"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Asset Classification Number of days past due
                    NDPD</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Asset_Classification_Number_of_days_past_due_NDPD }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Asset_Classification_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Asset Classification Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Asset_Classification_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Amount_Overdue_Limit_Overdue">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Amount Overdue Limit Overdue</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Amount_Overdue_Limit_Overdue }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Overdue_Bucket_01_1_30_days">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Overdue Bucket 01 1 30 days</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Overdue_Bucket_01_1_30_days }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Overdue_Bucket_02_31_60_days">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Overdue Bucket 02 31 60 days</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Overdue_Bucket_02_31_60_days }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Overdue_Bucket_03_61_90_days">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Overdue Bucket 03 61 90 days</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Overdue_Bucket_03_61_90_days }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Overdue_Bucket_04_91_180_days">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Overdue Bucket 04 91 180 days</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Overdue_Bucket_04_91_180_days }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Overdue_Bucket_05_Above_180_days">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Overdue Bucket 05 Above 180 days</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Overdue_Bucket_05_Above_180_days }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="High_Credit">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >High Credit</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.High_Credit }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Installment_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Installment Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Installment_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Last_Repaid_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Last Repaid Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Last_Repaid_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Account_Status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Account Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Account_Status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Account_Status_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Account Status Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Account_Status_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Written_Off_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Written Off Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Written_Off_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Settled_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Settled Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Settled_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Major_reasons_for_Restructuring">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Major reasons for Restructuring</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Major_reasons_for_Restructuring }}
                  </mat-cell>
                </ng-container>
                <ng-container
                  matColumnDef="Amount_of_Contracts_Classified_as_NPA"
                >
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Amount of Contracts Classified as NPA</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Amount_of_Contracts_Classified_as_NPA }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Asset_based_Security_coverage">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Asset based Security coverage</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Asset_based_Security_coverage }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Guarantee_Coverage">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Guarantee Coverage</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Guarantee_Coverage }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Bank_Remark_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Bank Remark Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Bank_Remark_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Wilful_Default_Status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Wilful Default Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Wilful_Default_Status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_Classified_as_Wilful_Default">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date Classified as Wilful Default</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_Classified_as_Wilful_Default }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Suit_Filed_Status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Suit Filed Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Suit_Filed_Status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Suit_Reference_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Suit Reference Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Suit_Reference_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Suit_Amount_in_Rupees">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Suit Amount in Rupees</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Suit_Amount_in_Rupees }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Date_of_Suit">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Suit</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Suit }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Dispute_ID_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Dispute ID No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Dispute_ID_No }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Transaction_Type_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Transaction Type Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Transaction_Type_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="OTHER_BK">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >OTHER BK</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.OTHER_BK }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UFCE_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >UFCE Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.UFCE_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UFCE_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >UFCE Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.UFCE_Date }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumnsCR; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsCR"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>GS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="table-responsive">
              <mat-table
                [dataSource]="DataSourceGS"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="A_c_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >A/c No.</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.A_c_No }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Flag">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Flag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Flag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Segment_Identifier">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Segment Identifier</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Segment_Identifier }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Guarantor_DUNS_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Guarantor DUNS Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Guarantor_DUNS_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Guarantor_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Guarantor Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Guarantor_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Business_Category">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Business Category</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Business_Category }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Business_Industry_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Business Industry Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Business_Industry_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Guarantor_Entity_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Guarantor Entity Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Guarantor_Entity_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Individual_Name_Prefix">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Individual Name Prefix</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Individual_Name_Prefix }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Full_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Full Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Full_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Gender">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Gender }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Company_Registration_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Company Registration Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Company_Registration_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Incorporation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Incorporation</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Incorporation }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Birth">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Birth</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Birth }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PAN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >PAN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.PAN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Voter_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Voter ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Voter_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Passport_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Passport Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Passport_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Driving_Licence_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Driving Licence ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Driving_Licence_ID }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >UID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.UID }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Ration_Card_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Ration Card No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Ration_Card_No }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >CIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >DIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TIN">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >TIN</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.TIN }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Service_Tax">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Service Tax</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Service_Tax }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Other_ID">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Other ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Other_ID }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Address_Line_1">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 1</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_1 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address_Line_2">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 1</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_2 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address_Line_3">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Line 3</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Address_Line_3 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="City_Town">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >City Town</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.City_Town }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="District">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >District</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.District }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="State_Union_Territory">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >State Union Territory</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.State_Union_Territory }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Pin_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Pin Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Pin_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Country">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Country</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Country }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Mobile_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Mobile Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Mobile_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Telephone_Area_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Telephone Area Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Telephone_Area_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Telephone_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Telephone Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Telephone_Number }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Fax_Area_Code">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Fax Area Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Fax_Area_Code }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Fax_Number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Fax Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Fax_Number }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumnsGS; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsGS"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>SS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="table-responsive">
              <mat-table
                [dataSource]="DataSourceSS"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="A_c_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >A/c No.</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.A_c_No }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Flag">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Flag</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Flag }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Segment_Identifier">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Segment Identifier</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Segment_Identifier }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Value_of_Security">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Value of Security</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Value_of_Security }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Currency_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Currency Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Currency_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type_of_Security">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Type of Security</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type_of_Security }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Security_Classification">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Security Classification</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Security_Classification }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date_of_Valuation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date of Valuation</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Date_of_Valuation }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedColumnsSS; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsSS"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>CD Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <mat-table
              [dataSource]="DataSourceCD"
              matSort
              matTableExporter
              #exporter="matTableExporter"
              style="max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="A_c_No">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >A/c No.</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.A_c_No }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Flag">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Flag</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Flag }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Segment_Identifier">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Segment Identifier</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Segment_Identifier }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Date_of_Dishonour">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Date of Dishonour</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Date_of_Dishonour }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Amount">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Amount }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Instrument_Cheque_Number">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Instrument Cheque Number</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Instrument_Cheque_Number }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Number_of_times">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Number of times</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Number_of_times }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="dishonoured_Cheque_Issue_Date">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >dishonoured Cheque Issue Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.dishonoured_Cheque_Issue_Date }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Reason_for_Dishonour">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Reason for Dishonour</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Reason_for_Dishonour }}
                </mat-cell>
              </ng-container>
              <mat-header-row
                class="sticky-footer"
                *matHeaderRowDef="displayedColumnsCD; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsCD"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="width-set">
        <span>TS Report</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <mat-table
              [dataSource]="DataSourceTS"
              matSort
              matTableExporter
              #exporter="matTableExporter"
              style="max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="Segment_identifier">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Segment identifier</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Segment_identifier }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Number_of_Borrower_Segments">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Number of Borrower Segments</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Number_of_Borrower_Segments }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Number_of_Credit_Facility_Segments">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Number of Credit Facility Segments</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Number_of_Credit_Facility_Segments }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                class="sticky-footer"
                *matHeaderRowDef="displayedColumnsTS; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsTS"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button
    class="mt-3 btn font-size-12 button-btn"
    (click)="exportToExcel()"
    *ngIf="Show"
  >
    <i
      class="fa fa-file-export mr-1"
      style="font-size: medium; cursor: pointer"
    ></i>
    Export
  </button>

    <button
      class="btn font-size-12 button-btn"
      style="width: max-content !important"
      (click)="dowloadTxtFile()"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Dowload Text File
    </button>
 
</div>
