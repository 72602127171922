import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LegalService } from "../services/Legal.service";

@Component({
  selector: "app-legal-tab-layout",
  templateUrl: "./legal-tab-layout.component.html",
  styleUrls: ["./legal-tab-layout.component.scss"],
})
export class LegalTabLayoutComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;

  LegalId: any;
  LoanId: any;
  LegalSummary: any={};
  LegalInfoModel: any;

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,
    private router: Router,
    private LmsService: LmsService,
    public snackBar: MatSnackBar,
    private _LegalService: LegalService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LegalId = Number(
        this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")))
      );
      this.LoanId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("LoanId"))
      );

      if (this.LegalId > 0) {
        this._LegalService
        .Legal_Get_LegalCasesList({
          LoginUserId: this.currentUser.userId,
          Status: "Legal",
        })
        .subscribe((res: any) => {
          this.LegalSummary = res.filter((x) => x.LegalId == this.LegalId)[0];
          console.log("this.LegalSummary789", this.LegalSummary);
        });
      }
      this._LegalService
      .Legal_Case_Detail_ById({ LegalId: this.LegalId })
      .subscribe((res: any) => {
        this.LegalInfoModel = res[0];
      });
    });
    console.log("this.LegalId", this.LegalId);
    console.log("this.LoanId", this.LoanId);
  }

  ngOnInit(): void {}

  onTabChange(event: any) {
    this.currentTab = event.index;
  }

  onChildAction(event: any) {
    if (event == "next") {
      if (this.currentTab - 1 == this.lastFillTab) {
        this.lastFillTab = this.lastFillTab + 1;
        this.GetLegalCasesList();
      }
      if (this.currentTab == 1) {
        this.currentTab = this.currentTab + 1;
        this.GetLegalCasesList();
      }
      if (this.currentTab == 2) {
        this.currentTab = this.currentTab + 1;
        this.GetLegalCasesList();
      }
      this.currentTab = this.currentTab + 1;
      this.GetLegalCasesList();
    }
  }

  GetLegalCasesList() {
    this._LegalService
      .Legal_Get_LegalCasesList({
        LoginUserId: this.currentUser.userId,
        Status: "Legal",
      })
      .subscribe((res: any) => {
        this.LegalSummary = res.filter((x) => x.LegalId == this.LegalId)[0];
        console.log("this.LegalSummary789", this.LegalSummary);
      });
  }
}
