<div>
  <form #f="ngForm" (ngSubmit)="onSaveArea()" novalidate class="formborder">
    <div class="row m-0 col-md-12 align-items-end">
      <div class="col-md-2">
        <span> Area </span>
        <input
          type="text"
          name="AreaName"
          id="AreaName"
          #refSelectGender="ngModel"
          [(ngModel)]="AreaName"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span> Select Type </span>
        <select
          name="SelectGender"
          id="SelectGender"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectArea"
          class="form-control input-text-css"
          required
        >
          <option *ngFor="let data of collectionArea" [value]="data.name">
            {{ data.name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span> Branch </span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
          required
        >
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>

      <div class="float-right row m-0 col-md-3">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-3 d-flex justify-content-end">
        <button
          type="button"
          (click)="onSaveArea()"
          *ngIf="saveBtn"
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
        <button
          type="button"
          (click)="UpdateArea()"
          *ngIf="updateBtn"
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
      </div>
    </div>

    <!-- <div class="row mt-4">
      
    </div> -->

    <!-- <div class="row">
      <div class="col-md-3">
        <span> Select Type </span>
        <select name="SelectGender" id="SelectGender" #refSelectGender="ngModel" [(ngModel)]="SelectArea"
          class="form-control input-text-css" required>
          <option *ngFor="let data of collectionArea" [value]="data.name">{{data.name}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <span> Branch </span>
        <select name="SelectBranch" id="SelectBranch" #refSelectGender="ngModel" [(ngModel)]="SelectBranch"
          class="form-control input-text-css" required>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">{{branch.Branch_Name}}</option>
        </select>
      </div>
    </div> -->

    <!-- <div class="mb-2">
      <button type="button" (click)="onSaveArea()" *ngIf="saveBtn"
        style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
        [class.spinner]="loading" [disabled]="!f.form.valid">
        Save
      </button>
      <button type="button" (click)="UpdateArea()" *ngIf="updateBtn"
        style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
        [class.spinner]="loading" [disabled]="!f.form.valid">
        Update
      </button>
    </div> -->
  </form>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerSource">
          <ng-container matColumnDef="Number">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              #
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Area">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Area
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="SubArea">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Source
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>
              Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                aria-label="true"
                (click)="editCollectionArea(row)"
              ></i>
              <i
                class="fa fa-trash style-delete"
                (click)="removeCollectionArea(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>

      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportexcelNew()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
    </div>
  </div>
</div>

<table
  id="collection-area-table"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Name }}</td>
    <td>{{ x.Type }}</td>
    <td>{{ x.Branch_Name }}</td>
  </tr>
</table>
