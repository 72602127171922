<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #dbf="ngForm" (ngSubmit)="onSaveProductCharges()" novalidate>
  <div class="row m-0 mt-3">
    <div class="col-md-12 p-0">
      <h1 style="font-size: 12px;  background: #4dc3a3; margin-bottom: 10px; padding: 10px; color: #fff;">
        Product Charges
      </h1>
    </div>
    <div class="col-md-12 row m-0 p-0">
      <div class="col-md-1 p-1">
        <span>&nbsp;</span>
      </div>
      <div class="col-md-3 p-1">
        <span style="font-weight:bold">Charges head</span>
      </div>
      <div class="col-md-2 p-1">
        <span style="font-weight:bold">Account head</span>
      </div>
      <div class="col-md-2 p-1">
        <!--<span style="font-weight:bold">Charges ApplyOn</span>-->
      </div>
    </div>
    <div class="col-md-12 row m-0 p-0" *ngFor="let charges of chargesHead;let i=index">
      <div class="col-md-1 p-1">
        <mat-checkbox [(ngModel)]="charges.select" id="multipleTrunche1{{i}}" name="multipleTrunche1{{i}}"></mat-checkbox>
      </div>
      <div class="col-md-2 p-1">
        <span> {{charges.ChagesHead}} </span>
      </div>
      <div class="col-md-3 p-1">
        <select name="Account_HeadId{{i}}" id="Account_HeadId{{i}}" [(ngModel)]="charges.Account_HeadId"
                class="form-control input-text-css" [required]="charges.select" #refAccount_HeadId="ngModel"
                [ngClass]="{ 'is-invalid': dbf.submitted && refAccount_HeadId.invalid, 'alert-warning': refAccount_HeadId.invalid  && (refAccount_HeadId.dirty || refAccount_HeadId.touched || refAccount_HeadId.untouched) }">
          <option value=""> Select </option>
          <option *ngFor="let ca of chargableAccount" [value]="ca.AccountId">
            {{ca.Account_Name}}
          </option>
        </select>
      </div>
    
    </div>

    
    <div class="col-md-12 row m-0 p-0">
      <div class="col-md-12 text-right p-1">
        <!-- <button type="button" (click)="OnCloseProductModal()" class="btn btn-light font-size-12" data-dismiss="modal">
          Cancel
        </button> -->
        <button type="button" (click)="onSaveProductCharges()" [class.spinner]="loading"
                class="btn font-size-12 button-btn" [disabled]="!dbf.form.valid">
          Update
        </button>
      </div>
    </div>
  </div>
</form>
