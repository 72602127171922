<div class="row m-0  mt-2">
  <div class="col-md-6">
    <span>TEXT</span>
    <input type="text" [(ngModel)]="TextData" style="height: 200px; font-size: 20px;"
           name="TextData" id="TextData" class="form-control input-text-css">
  </div>
  <div class="col-md-6">
    <span>TEXT</span>
    <input type="text" [(ngModel)]="ChangeTextData" disabled style="height: 200px; font-size: 20px;"
           name="ChangeTextData" id="ChangeTextData" class="form-control input-text-css">
  </div>
</div>
<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button type="button" (click)="ToDecrypt()"
          class=" font-size-12 button-btn" data-dismiss="modal">
    To Decrypt
  </button>
  <button type="button" (click)="ToEncrypt()" class="ml-4 font-size-12 button-btn"
          [class.spinner]="loading">
    To Encrypt
  </button>
</div>
