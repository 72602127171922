<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-3 formborder">
    <div class="col-md-4 p-1" style="display: none;">
      <span class="required-lable">Product Category :</span>
      <select name="SelectProductCategory" id="SelectProductCategory" #refSelectProductCategory="ngModel" disabled
              [(ngModel)]="SelectProductCategory" class="form-control input-text-css" required>
        <option value=""> Select Product </option>
        <option *ngFor="let productCategory of ProductCategoryDropdown" [value]="productCategory.ProductCatId">
          {{productCategory.Product_Category}}
        </option>
      </select>
    </div>
    <div class="col-md-6 p-1">
      <span >Product Name :</span>
      <input required type="text" #refProductName="ngModel" name="ProductName" id="ProductName" placeholder="Product Name"
             [(ngModel)]="ProductName" class="form-control input-text-css" disabled>
    </div>
    <div class="col-md-6 p-1">
      <span >Product Short Name :</span>
      <input required type="text" #refProductShortName="ngModel" name="ProductShortName" id="ProductShortName" disabled
             [(ngModel)]="ProductShortName" placeholder="Product Short Name" class="form-control input-text-css">
    </div>
    <div class="col-md-6 p-1">
      <span >Workflow Created On:  {{WorkflowCreatedOn}}</span>

    </div>

    <div class="col-md-6 p-1">
      <span >Workflow Created By :{{WorkflowCreatedBy}}</span>
    </div>
    <div class="col-md-6 p-1">
      <span>Workflow Last Modify On :{{WorkflowModifiedOn}}</span>
    </div>
    <div class="col-md-6 p-1">
      <span >Workflow Modify By :{{WorkflowModifiedBy}}</span>
    </div>
  </div>



  <!--<div class="row m-0">
    <div class="col-md-3 p-1">
      <span> Select Product </span>
      <select name="FilterProuctId" id="FilterProuctId" (change)="getProductList()" [(ngModel)]="FilterProuctId"
              class="form-control input-text-css">
        <option value=""> Select Product </option>
        <option *ngFor="let ProductListDropdown of ProductListDropdown" [value]="ProductListDropdown.ProductId">
          {{ProductListDropdown.Product_Name}}
        </option>
      </select>
    </div>
  </div>-->


  <!--<div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <td><b>Workflow Created On :</b></td>
          <td> {{WorkflowCreatedOn}}</td>
          <td><b>Workflow Created By:</b></td>
          <td> {{WorkflowCreatedBy}}</td>
        </tr>
        <tr>
          <td><b>Workflow Last Modify On:</b></td>
          <td> {{WorkflowModifiedOn}}</td>
          <td><b>Workflow Modify By:</b></td>
          <td> {{WorkflowModifiedBy}}</td>
        </tr>
      </thead>
    </table>
  </div>-->


  <div class="table-responsive">
    <mat-table [dataSource]="dataSource" matSort style="height: 250px; max-width: 100%; overflow: auto;">
      <ng-container matColumnDef="ProcessId">
        <mat-header-cell mat-sort-header class="grid-header" style="max-width:100px" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 100px;max-height:5px">
          {{(i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GroupName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> GroupName </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.GroupName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Process Name </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Process}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Squance">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Sequence </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Squance}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="IsMandatory">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> IsMandatory </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.IsMandatory}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Section">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Section </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <mat-checkbox (change)="onChangeSelection($event,row)" [disabled]="row.isDisable" [checked]="row.isChecked">
          </mat-checkbox>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="EditAction">
        <mat-header-cell mat-sort-header class="grid-header" style="max-width:100px" *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell" style="max-width:100px">
          <i class="fa fa-trash style-delete" *ngIf="row.isShowDelete" (click)="onDeleteProcess(row)" aria-label="true"></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row class="sticky-footer" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <!--<mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>-->
  </div>

  <div class="row m-0">
    <div class="col-md-12 p-1 text-right">
      <button class="btn  font-size-12 button-btn" (click)="OnSaveProductSection()"> Update </button>
    </div>
  </div>
</div>

