<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-4">
      <span> Select Branch </span>
      <select
        name="FilterBranchId"
        id="FilterBranchId"
        [(ngModel)]="FilterBranchId"
        class="form-control input-text-css"
      >
        <option value="">Select Branch</option>
        <option
          *ngFor="let filterBranchList of FilterBranchList"
          [value]="filterBranchList.BranchId"
        >
          {{ filterBranchList.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-4">
      <span> Select Product </span>
      <select
        name="FilterBranchId"
        id="FilterBranchId"
        [(ngModel)]="ProductId"
        class="form-control input-text-css"
      >
        <option value="">Select Product</option>
        <option *ngFor="let Product of productList" [value]="Product.ProductId">
          {{ Product.Product }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <button
        type="button"
        (click)="getBranchEmployeeList()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Search
      </button>
    </div>
  </div>

  <div class="table-responsive mt-2" [hidden]="!dataSource">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ProcessId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ProcessGroup">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Process Group
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.ProcessGroup }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 250px"
          *matHeaderCellDef
        >
          Process
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 250px"
        >
          {{ row.Process }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Employee">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Employee
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Employee }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
