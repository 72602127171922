<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<h5 class="fs-12 h-b" style="margin-top: 32px;">Repayment schedule</h5>

<div class="row m-0 mt-3">
  <div class="row m-0 col-md-12 p-1 " *ngIf="tab=='list'" style="min-height: 120px;">
    <div class="table-responsive mt-3">
      <!-- <mat-table [dataSource]="rdDataSource">
          <ng-container matColumnDef="ApplicationChargesId">
            <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>
              #
            </mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell"
              style="max-width: 50px;max-height:5px">
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ChagesHead">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Chages
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ChagesHead}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectedAmount">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{row.CollectedAmount}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CollectionBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Collection By
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CollectionBy}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Collection On
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CollectionOn}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionType">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{row.CollectionType}}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedRdcColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedRdcColumns"></mat-row>
        </mat-table> -->

      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">EMI No</th>
            <th scope="col">Due Date</th>
            <th scope="col">EMI Amount</th>
            <th scope="col">Interest Amount</th>
            <th scope="col">Principle Amount</th>
            <th scope="col">Outstanding</th>
            <th scope="col">Repayment Mode</th>
            <th scope="col">Payment Received On</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>214525</td>
            <td>02/03/2021</td>
            <td>4000</td>
            <td>1500</td>
            <td>10000</td>
            <td>5000</td>
            <td>
              <select name="SelectGender" id="SelectGender" class="form-control input-text-css" required>
                <option value="married"> NACH </option>
                <option value="unmarried"> PDC </option>
                <option value="married"> ECS </option>
                <option value="unmarried"> NON PDC </option>
                <option value="unmarried"> OTHER </option>
              </select>
            </td>
            <td>5000</td>
          </tr>
          <tr>
            <td>1</td>
            <td>214525</td>
            <td>02/03/2021</td>
            <td>4000</td>
            <td>1500</td>
            <td>10000</td>
            <td>5000</td>
            <td>
              <select name="SelectGender" id="SelectGender" class="form-control input-text-css" required>
                <option value="married"> NACH </option>
                <option value="unmarried"> PDC </option>
                <option value="married"> ECS </option>
                <option value="unmarried"> NON PDC </option>
                <option value="unmarried"> OTHER </option>
              </select>
            </td>
            <td>5000</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>