<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0">
    <div class="col-md-3 p-1">
      <span> Select Product </span>
      <select
        name="FilterProductId"
        id="FilterProductId"
        (change)="getProductChagesList()"
        [(ngModel)]="FilterProductId"
        class="form-control input-text-css"
      >
        <option value="" disabled>Select Product</option>
        <option
          *ngFor="let productList of FilterProductList"
          [value]="productList.ProductId"
        >
          {{ productList.Product_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-9 p-1 text-right">
      <button
        class="btn font-size-12 button-btn"
        (click)="onOpenLoanProductModal()"
      >
        Add New
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ChargeHeadId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ChagesHead">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Charges Head
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ChagesHead }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Account_HeadId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Charges Account
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Account_Name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ChargeType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Charges Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ChargeType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ChargeValues">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Value
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ChargeValues }} {{ row.Unit }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Charge_ApplyOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Charge ApplyOn
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Charge_ApplyOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fa fa-pencil-alt font-size-12 mr-2"
            (click)="OneditLoanProduct(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onDeleteLoanProduct(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="addNewLoanProduct"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveLoanProductData()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ HeaderText }} Product Charges
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Charges Head </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                [disabled]="isEditable"
                name="ChargeHeadId"
                id="ChargeHeadId"
                #refChargeHeadId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refChargeHeadId.invalid,
                  'alert-warning':
                    refChargeHeadId.invalid &&
                    (refChargeHeadId.dirty ||
                      refChargeHeadId.touched ||
                      refChargeHeadId.untouched)
                }"
                [(ngModel)]="ChargeHeadId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Charges Head</option>
                <option
                  *ngFor="let ChargesHeadData of ChargesHeadDropdown"
                  [value]="ChargesHeadData.Id"
                >
                  {{ ChargesHeadData.ChagesHead }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Charges Account head </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="Account_HeadId"
                id="Account_HeadId"
                required
                [(ngModel)]="Account_HeadId"
                class="form-control input-text-css"
                #refAccount_HeadId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAccount_HeadId.invalid,
                  'alert-warning':
                    refAccount_HeadId.invalid &&
                    (refAccount_HeadId.dirty ||
                      refAccount_HeadId.touched ||
                      refAccount_HeadId.untouched)
                }"
              >
                <option value="">Select Account head</option>
                <option
                  *ngFor="let ca of chargableAccount"
                  [value]="ca.AccountId"
                >
                  {{ ca.Account_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Charges Type </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="ChargeType"
                id="ChargeType"
                #refChargeType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refChargeType.invalid,
                  'alert-warning':
                    refChargeType.invalid &&
                    (refChargeType.dirty ||
                      refChargeType.touched ||
                      refChargeType.untouched)
                }"
                [(ngModel)]="ChargeType"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Charges Type</option>
                <option value="Fixed">Fixed</option>
                <option value="Percentage">Percentage</option>
                <option value="Open">Open</option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span
                class=""
                [ngClass]="{ 'required-lable': ChargeType != 'Open' }"
                >Value
              </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                [required]="ChargeType != 'Open'"
                type="number"
                #refChargeValues="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refChargeValues.invalid,
                  'alert-warning':
                    refChargeValues.invalid &&
                    (refChargeValues.dirty ||
                      refChargeValues.touched ||
                      refChargeValues.untouched)
                }"
                [(ngModel)]="ChargeValues"
                placeholder="Charges Value"
                name="ChargeValues"
                id="ChargeValues"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span
                class=""
                [ngClass]="{ 'required-lable': ChargeType != 'Open' }"
                >Charges Apply On
              </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="Charge_ApplyOn"
                id="Charge_ApplyOn"
                [(ngModel)]="Charge_ApplyOn"
                class="form-control input-text-css"
                [required]="ChargeType != 'Open'"
                #refCharge_ApplyOn="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCharge_ApplyOn.invalid,
                  'alert-warning':
                    refCharge_ApplyOn.invalid &&
                    (refCharge_ApplyOn.dirty ||
                      refCharge_ApplyOn.touched ||
                      refCharge_ApplyOn.untouched)
                }"
              >
                <option value="">Select Charges Apply On</option>
                <option value="Loan Amount">Loan Amount</option>
                <option value="Balance Amount">Balance Amount</option>
                <option value="Out Standing Amount">Out Standing Amount</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveLoanProductData()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
