<a
  href="javascript:void(0)"
  class="customer-a"
  (click)="onOpenViewDetailModal()"
>
  <ng-content></ng-content>
</a>

<div
  class="modal fade in"
  id="LosSearchCustomerViewDetailModal{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    color: #222 !important;
  "
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div
          cdkDragHandle
          class="modal-header"
          style="padding: 2px; border: none"
        >
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Customer Information
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div style="overflow: auto">
            <table class="formborder">
              <tbody>
                <tr>
                  <th rowspan="6">
                    <img
                      src="{{ customerProfileBaseUrl }}{{
                        customerData.CustomerId
                      }}/{{ customerData.ProfilePic }}"
                      alt="img"
                      onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                      height="70"
                    />
                    <!-- style="width: 70px; height: 70px; border: 1px solid #dddd; border-radius: 50%;" -->
                  </th>
                  <th>Name:</th>
                  <th>Relation</th>
                  <th>Gender, Age</th>
                  <th>Phone No:</th>
                  <th>Email:</th>
                  <!--<th>Occupation:</th>-->
                  <th>Last Update On:</th>
                </tr>
                <tr>
                  <td>{{ customerData.CustomerName }}</td>
                  <td>{{ customerData.RelationName }}</td>
                  <td>{{ customerData.Gender }}, {{ customerData.Age }}</td>
                  <td *ngIf="currentUser.IsMasking">{{ customerData.PhoneNo |mask }}</td>
                  <td *ngIf="!currentUser.IsMasking">{{ customerData.PhoneNo }}</td>

                  <td>{{ customerData.Email }}</td>
                  <!--<td>{{ customerData.Occupation }}</td>-->
                  <td>{{ customerData.CreateOn }}</td>
                </tr>
                <tr>
                  <th>Religion</th>
                  <th>Caste</th>
                  <th>MaritalStatus</th>
                  <th>DOB</th>
                  <th>Profile</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                </tr>
                <tr>
                  <td>{{ customerData.Religion }}</td>
                  <td>{{ customerData.Cast }}</td>
                  <td>{{ customerData.MaritalStatus }}</td>
                  <td>{{ customerData.Customer_DOB }}</td>
                  <td>{{ customerData.Profile }}</td>
                  <td>{{ customerData.Category }}</td>
                  <td>{{ customerData.Sub_Category }}</td>
                </tr>
                <tr>
                  <th>BusinessCategory</th>
                  <th>Nature of work</th>
                </tr>
                <tr>
                  <td>{{ customerData.BusinessCategory }}</td>
                  <td>{{ customerData.Nature_of_work }}</td>
                </tr>
              </tbody>
            </table>

            <!-- address list -->
            <div class="table-responsive customer-view mt-2">
              <mat-table
                [dataSource]="dataSourceAddress"
                #SortAddress="matSort"
                matSort
              >
                <ng-container matColumnDef="AddressId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                    style="max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="AddressType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Type</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    (click)="onOpenAddressMapModal(row)"
                    style="
                      color: blue;
                      text-decoration-line: underline;
                      cursor: pointer;
                    "
                  >
                    {{ row.AddressType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Address">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw300"
                    *matHeaderCellDef
                    >Address</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw300"
                    >{{ row.Address }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LandMark">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >LandMark</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                    row.LandMark
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Tehsil_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Tehsil</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Tehsil_Name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="District_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >District</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.District_Name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PinCode">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                    >PinCode</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.PinCode
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="AddressRentBuy">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw150"
                    *matHeaderCellDef
                    >Rent/Own</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw150">{{
                    row.AddressRentBuy
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="TotalYearsOnAddress">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    How many years
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.TotalYearsOnAddress
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="IsCommunicationAddress">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Comm. Address</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.IsCommunicationAddress
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="LatLong">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw300"
                    *matHeaderCellDef
                    >LatLong</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw300">
                    <a
                      target="_blank"
                      *ngIf="
                        row.LatLong != '' &&
                        row.LatLong != null &&
                        row.LatLong != ','
                      "
                      [href]="'http://maps.google.com/maps?q=' + row.LatLong"
                    >
                      <i class="fa-solid fa-location-dot"></i>
                    </a>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumnsAddress"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsAddress"
                ></mat-row>
              </mat-table>
            </div>

            <!-- Categoy list -->
            <div class="table-responsive customer-view mt-3">
              <mat-table
                [dataSource]="dataSourceCategory"
                #CategorySort="matSort"
                matSort
              >
                <ng-container matColumnDef="CategoyId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Categoy">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Categoy</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Categoy
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Doc_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Document Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <!--<a href="{{customerProfileBaseUrl}}{{customerId}}/{{row.KYC_DocFile}}" target="_blank"
                       *ngIf="row.KYC_DocFile">
                      <img src="assets/images/aadhar.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Aadhaar Card'" />
                      <img src="assets/images/dl.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Driving License'" />
                      <img src="assets/images/electricity.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Electricity Bill'" />
                      <img src="assets/images/emp-id-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Other Photo ID'" />
                      <img src="assets/images/pan-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'PAN Card'" />
                      <img src="assets/images/passport.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Passport Copy'" />
                      <img src="assets/images/rasan-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Ration Card'" />
                      <img src="assets/images/voter-id.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                           *ngIf="row.Doc_Name == 'Voter ID Card'" />
                      <img src="assets/images/doc.png" alt="" style="max-width: 50px; margin-right: 5px" *ngIf="
                          row.Doc_Name !== 'Aadhaar Card' &&
                          row.Doc_Name !== 'Driving License' &&
                          row.Doc_Name !== 'Electricity Bill' &&
                          row.Doc_Name !== 'Voter ID Card' &&
                          row.Doc_Name !== 'Ration Card' &&
                          row.Doc_Name !== 'Passport Copy' &&
                          row.Doc_Name !== 'PAN Card' &&
                          row.Doc_Name !== 'Other Photo ID'
                        " />

                    </a>-->
                    {{ row.Doc_Name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="KYC_DocNumber">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Document Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            class="grid-cell"
                            style="text-transform: uppercase">

                    <div *ngIf="currentUser.IsMasking">{{row.KYC_DocNumber |mask}}</div>
                    <div *ngIf="!currentUser.IsMasking"> {{row.KYC_DocNumber}}</div>
               
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="UploadOn">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Uploaded On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.UploadOn
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="LastVerfiedDate">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Verified</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <a
                      href="javascript:void(0)"
                      (click)="ViewKYC_IsVerified(row)"
                      *ngIf="row.LastVerfiedDate"
                    >
                      <i
                        *ngIf="row.LastVerfiedDate"
                        class="fa fa-eye"
                        style="font-size: large"
                      ></i>
                    </a>
                    <i
                      *ngIf="row.LastVerfiedDate"
                      class="fa fa-check ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 70px; justify-content: center"
                    *matHeaderCellDef
                  >
                    View
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 70px; justify-content: left"
                  >
                    <a
                      href="{{ customerProfileBaseUrl }}{{
                        customerData.CustomerId
                      }}/{{ row.KYC_DocFile }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="row.KYC_DocFile"
                    >
                      <i
                        class="fa fa-eye"
                        style="
                          font-size: medium;
                          cursor: pointer;
                          text-align: center;
                        "
                      ></i>
                    </a>
                    <a
                      href="{{ customerProfileBaseUrl }}{{
                        customerData.CustomerId
                      }}/{{ row.KYC_DocFile1 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="row.KYC_DocFile1"
                    >
                      <i
                        class="fa fa-eye"
                        style="
                          font-size: medium;
                          cursor: pointer;
                          text-align: center;
                        "
                      ></i>
                    </a>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsCategory"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsCategory"
                ></mat-row>
              </mat-table>
            </div>

            <!-- Bank Details -->

            <div class="table-responsive customer-view mt-3" 
             *ngIf="dataSourceBank">
              <mat-table
                [dataSource]="dataSourceBank"
                #CategorySort="matSort"
                matSort
              >
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerBankName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Customer Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CustomerBankName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="AccountNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Account No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.AccountNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="BankName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Bank Name
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="text-transform: uppercase"
                  >
                    {{ row.BankName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="BankBranch">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Bank Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.BankBranch
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="BankIFSC">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Bank IFSC</mat-header-cell
                  >

                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.BankIFSC
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="BankAcType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Bank Ac Type
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="text-transform: uppercase"
                  >
                    {{ row.BankAcType }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayColumnsBank"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayColumnsBank"
                ></mat-row>
              </mat-table>
            </div>

            <!-- application list -->
            <div
              class="table-responsive customer-view mt-3"
            >
              <mat-table
                [dataSource]="dataSourceApplication"
                #ApplicationSort="matSort"
                matSort
              >
                <ng-container matColumnDef="ApplicationId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ApplicationNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >Application No</mat-header-cell
                  >
                  <!-- <mat-cell *matCellDef="let row" class="grid-cell" (click)="onOpenApplicationMapModal(row)" style="color: blue; text-decoration-line: underline; cursor: pointer;">
                    {{ row.ApplicationNo }}
                  </mat-cell> -->
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                    row.ApplicationNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan A/C No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.LoanAcNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Total_OverDUE_EMI_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >OverDUE EMI
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Total_OverDUE_EMI_Amount
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Balance_Principle">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Principle</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Balance_Principle
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.CustomerType == "Hirer"
                        ? "Borrower"
                        : row.CustomerType
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Product">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Product</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Product
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Branch
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAmount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.LoanAmount > 0 ? "₹" + row.LoanAmount : ""
                    }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="CreateOn">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CreateOn
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Application_Status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell"
                    >{{ row.Application_Type }}-{{
                      row.LoanAcNo != "" &&
                      row.LoanAcNo != null &&
                      row.Status == "Completed"
                        ? "Live"
                        : row.Status
                    }}</mat-cell
                  >
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsApplication"
                ></mat-header-row>
                <mat-row
                  [ngClass]="{
                    Completed: row.Status == 'Completed',
                    Closed: row.Status == 'Closed'
                  }"
                  *matRowDef="let row; columns: displayedColumnsApplication"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="LosSearchCustomerViewMapModal{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 5px">
          <p class="modal-title" id="myModalLabel" name="myModalLabel">
            Address: {{ ShowMapAddress }}
          </p>
          <button
            type="button"
            (click)="onCloseAddressMapModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div id="map" style="border: 2px solid #3872ac">
            <agm-map
              [latitude]="lat"
              [longitude]="lng"
              [zoom]="zoom"
              [disableDefaultUI]="true"
              [zoomControl]="true"
            >
              <agm-marker [latitude]="lat" [longitude]="lng"> </agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  id="modalViewVerifiedData{{ index }}"
  class="modal fade in"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  style="font-size: small"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow-y: auto"
>
  <div class="modal-dialog modal-dialog-centered" style="width: 400px">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle>
        <div class="modal-content">
          <div class="modal-header" style="padding: 6px">
            <h5 class="modal-title" style="font-size: small">
              Customer Information
            </h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body" style="padding: 13px">
            <div class="card">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
                alt="Customer Image"
                class="mt-2"
                style="
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  margin: auto;
                "
              />
              <div
                class="card-body"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 1"
              >
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Relation
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.care_of | slice : 0 : 4 }}
                  {{ APIResponse.care_of | slice : 4 }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  DOB:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.dob }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Gender:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.gender }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Address:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.house }},{{ APIResponse.street }},
                  {{ APIResponse.po }},{{ APIResponse.loc }},{{
                    APIResponse.vtc
                  }},{{ APIResponse.subdist }},
                  {{ APIResponse.landmark }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Pin Code:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.zip }}
                </p>
                <br />
                <h6
                  class="card-title"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  Verify PAN No
                </h6>
                <p
                  class="card-text"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  {{ Verify_PAN_No }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 4">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Relation:</h6>
                <p class="card-text">
                  {{ APIResponse.relation_type + " " }}
                  {{ APIResponse.relation_name }}
                </p>
                <br />

                <h6 class="card-title">age:</h6>
                <p class="card-text">{{ APIResponse.age }}</p>
                <br />
                <h6 class="card-title">Gender:</h6>
                <p class="card-text">
                  {{ APIResponse.gender == "F" ? "Female" : "Male" }}
                </p>
                <br />
                <h6 class="card-title">Area:</h6>
                <p class="card-text">
                  {{
                    APIResponse.area +
                      " " +
                      APIResponse.dist +
                      " " +
                      APIResponse.state
                  }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 29">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />

                <h6 class="card-title">PAN Number:</h6>
                <p class="card-text">{{ APIResponse.pan_number }}</p>
                <br />

                <h6 class="card-title">Orgnization Name:</h6>
                <p class="card-text">{{ APIResponse.business_name }}</p>
                <br />

                <h6 class="card-title">Signing Autority:</h6>
                <p class="card-text">{{ APIResponse.SigningAutority }}</p>
                <br />

                <h6 class="card-title">Firm Type:</h6>
                <p class="card-text">{{ APIResponse.FirmType }}</p>
                <br />

                <h6 class="card-title">Gst Registration Date:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">{{ APIResponse.gst_status }}</p>
                <br />

                <h6 class="card-title">Nature Bus Activities:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />

                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 28">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
                <h6 class="card-title">Company Name</h6>
                <p class="card-text">{{ APIResponse.company_name }}</p>
                <br />
                <h6 class="card-title">Registration Number</h6>
                <p class="card-text">{{ APIResponse.registration_number }}</p>
                <br />
                <h6 class="card-title">Company Category</h6>
                <p class="card-text">{{ APIResponse.company_category }}</p>
                <br />
                <h6 class="card-title">Company Sub Category</h6>
                <p class="card-text">{{ APIResponse.company_sub_category }}</p>
                <br />
                <h6 class="card-title">Class Of Company</h6>
                <p class="card-text">{{ APIResponse.class_of_company }}</p>
                <br />
                <h6 class="card-title">Company Status</h6>
                <p class="card-text">{{ APIResponse.company_status }}</p>
                <br />
                <h6 class="card-title">Authorized Capital</h6>
                <p class="card-text">{{ APIResponse.authorized_capital }}</p>
                <br />
                <h6 class="card-title">Paid Up Capital</h6>
                <p class="card-text">{{ APIResponse.paid_up_capital }}</p>
                <br />
                <h6 class="card-title">Date Of Incorporation</h6>
                <p class="card-text">{{ APIResponse.date_of_incorporation }}</p>
                <br />
                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <h1 class="fs-12 m-0">Directors Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Directors"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="din_number">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Din Number</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.din_number
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="director_name">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Director Name</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.director_name
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Start Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.start_date
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >End Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.end_date
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="surrendered_din">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Surrendered Din
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.surrendered_din
                          }}</mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedDirectorsColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedDirectorsColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                  style="margin-top: 25px !important; font-weight: bold"
                >
                  <h1 class="fs-12 m-0">Charges Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Charges"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="assets_under_charge">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Assets Under Charge
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.assets_under_charge
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="charge_amount">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Charge Amount</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.charge_amount
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_creation">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Creation
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.date_of_creation
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_modification">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Modification
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.date_of_modification
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Status</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.status
                          }}</mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedChargesColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedChargesColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 5">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <b class="card-title">permanent address:</b>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
                <h6 class="card-title">DOB:</h6>
                <p class="card-text">{{ APIResponse.dob }}</p>
                <br />
                <h6 class="card-title">Issue Date:</h6>
                <p class="card-text">{{ APIResponse.doi }}</p>
                <br />
                <h6 class="card-title">Expiry Date:</h6>
                <p class="card-text">{{ APIResponse.doe }}</p>
                <br />
                <h6 class="card-title">Issued By:</h6>
                <p class="card-text">{{ APIResponse.ola_name }}</p>
                <br />

                <h6 class="card-title">vehicle classes:</h6>
                <p class="card-text">{{ APIResponse.vehicle_classes }}</p>
                <br />
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 2">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Address:</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <h6 class="card-title">Mobile:</h6>
                <p class="card-text">{{ APIResponse.mobile }}</p>
                <br />
                <h6 class="card-title">State:</h6>
                <p class="card-text">{{ APIResponse.state }}</p>
                <br />
                <h6 class="card-title">Operator Code:</h6>
                <p class="card-text">{{ APIResponse.operator_code }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row m-0 col-md-12">
              <div class="col-md-4">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified By
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedBy }}
                </p>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified On
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedDate }}
                </p>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="mt-3 btn font-size-12 button-btn"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
