import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../services/lms.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';

@Component({
    selector: 'app-lms-repayment-schedule-report',
    templateUrl: './lms-repayment-schedule-report.component.html',
    styleUrls: ['./lms-repayment-schedule-report.component.scss']
})
export class LmsRepaymentScheduleReportComponent implements OnInit {

    currentUser: any;
    showSpinner: boolean = false;
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };
    tab: any;
    rdDetailData: any;
    inquiryId: any;
    inquiryDetail: any;
    loading: boolean = false;
    rdDataSource: any = '';
    CurrentDate: any = new Date();
    ApplicationId: any;
    productDropdown: any[] = [];
    chargesDropdown: any[] = [];
    dataSource: any;
    displayedColumns: string[] = ['SchemeId', 'Scheme', 'Amount', 'ROI', 'Period', 'AdvanceEMI', 'Select'];
    selectedScheme: any = {};
    cEIModel: any = { IRR_Type: 'Flat', EMI_Type: 'Monthly', ProductId: "", IRR_CalculateBy: 'ROI' };
    stepEMI: any[] = [];
    emiIrr: any;
    stepEmiData: any;
    constructor(private dataSharingService: DataSharingService, private _MasterService: MasterService,
        private route: ActivatedRoute, private snackBar: MatSnackBar, private encdec: EncrDecrService,
      private router: Router, private losService: LosService ,  private LmsService: LmsService) {

        this.route.paramMap.subscribe((param: ParamMap) => {
            let type = decodeURIComponent(param.get("Type"));
            this.dataSharingService.LmsHeaderType.next(type);
            if (type == 'L') {
                let LoanId = decodeURIComponent(param.get("id"));
                this.dataSharingService.LmsHeaderLoanId.next(LoanId);
                this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
            } else {
                let LoanId = decodeURIComponent(param.get("id"));
                this.dataSharingService.LmsHeaderLoanId.next(LoanId);
                this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
            }
            this.ApplicationId = 106;
            this.inquiryId = 10;
        });
    }

    ngOnInit() {
        this.dataSharingService.LOSHeaderTitle.next('Application Number Generation');
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        //this.LOS_GetInquiryDetails();
        this.getProductDropdown();
        this.tab = 'list';

        this.onRDOpen(true);
    }

    LOS_GetInquiryDetails() {
        this.showSpinner = true;
        this.losService.LOS_GetInquiryDetails({ InquiryId: this.inquiryId }).subscribe((res: any) => {
            this.showSpinner = false;
            this.inquiryDetail = res[0];
            this.cEIModel.Purpose = this.inquiryDetail.Purpose;
            this.cEIModel.NetFinance_Amt = this.inquiryDetail.LoanAmount;
        })
    }
    getProductDropdown() {
        this.showSpinner = true;
        this._MasterService.GetProductForDropdown().subscribe((result: any) => {
            this.productDropdown = JSON.parse(JSON.stringify(result));
            this.showSpinner = false;
        });
    }
    geSchemeList() {
        this.showSpinner = false;
        this._MasterService.LOS_GetSchemeToValidateLoan({ ProductId: this.cEIModel.ProductId }).subscribe((result: any) => {
            this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(result)));
            this.showSpinner = false;
        });
    }
    onCheckboxChange(e: any, data: any) {
        if (this.selectedScheme.SchemeId == data.SchemeId) {
            this.selectedScheme = {};
        }
        else {
            this.selectedScheme = data;
            this.cEIModel.Adv_Inst = data.AdvanceEMI;
        }
    }
    onClose() {
        this.router.navigate(['/LOS/my-lead']);
    }
    changeRDTab(tab: any) {
        if (tab != this.tab) {
            this.tab = tab;
            this.onRDOpen(false);
        }
    }

    onRDOpen(isOpen: boolean = true) {
        this.showSpinner = true;
        this.losService.LOS_GetApplicationCharges({ ApplicationId: this.ApplicationId }).subscribe((res: any) => {
            this.showSpinner = false;
            this.rdDetailData = res;
            this.rdDataSource = new MatTableDataSource(res.Item2);
            // console.log('res=',res.Item2);
            this.rdDetailData.New = { ApplicationId: this.ApplicationId, LoginUserId: this.currentUser.userId, ChargeHeadId: "", ChargeReceiptDeduction: "" };

            //this.LOS_GetApplicationProductChargesHeadsForDropdown();

            // if (isOpen) {
            //    this.tab = 'list';
            //     this.LOS_GetApplicationProductChargesHeadsForDropdown();
            //     $('#rddetailModel').modal('show');
            //     $("#rddetailModel").css("z-index", "1050");
            // }
        });
    }

    LOS_GetApplicationProductChargesHeadsForDropdown() {
        this.losService.LOS_GetApplicationProductChargesHeadsForDropdown({ ApplicationId: this.ApplicationId }).subscribe((res: any) => {
            this.chargesDropdown = res;
        })
    }
    onCalculateEmiAndIRR() {
        let netAmount: any[] = [];
        let roi: any[] = [];

        if (+this.cEIModel.ProductId <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Please select a product.", ...this.configSuccess });
            return;
        }
        // if (+this.cEIModel.Asset_Cost <= 0) {
        //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be greater than 0.", ...this.configSuccess });
        //     return;
        // }
        if (+this.cEIModel.NetFinance_Amt <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Net Finance Amount should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.Flat_Rate < 0 && this.cEIModel.IRR_CalculateBy == 'ROI') {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Flate rate should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.EMI_Amount <= 0 && this.cEIModel.IRR_CalculateBy == 'EMI') {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI amount should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.No_Of_Inst <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "No of Installment should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.Tenure <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Tenure should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (this.cEIModel.IRR_CalculateBy == 'EMI' && (+this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst < +this.cEIModel.NetFinance_Amt)) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.", ...this.configSuccess });
            return;
        }
        if (this.selectedScheme.Amount) {
            netAmount = this.selectedScheme.Amount.split('-');
            roi = this.selectedScheme.ROI.split('-');
            if (+this.cEIModel.NetFinance_Amt > +netAmount[1] || +this.cEIModel.NetFinance_Amt < +netAmount[0]) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Net Finance Amount should be in between Scheme Amount.", ...this.configSuccess });
                return;
            }
            if (this.cEIModel.IRR_CalculateBy == 'ROI' && (+this.cEIModel.Flat_Rate > +roi[1] || +this.cEIModel.Flat_Rate < +roi[0])) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Flate Rate should be in between Scheme ROI.", ...this.configSuccess });
                return;
            }
            if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Advance Installment can not be less than Scheme Advance EMI.", ...this.configSuccess });
                return;
            }
            // if (+this.cEIModel.NetFinance_Amt < +this.selectedScheme.Asset_Cost) {
            //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be less than Net Finance Amount.", ...this.configSuccess });
            //     return;
            // }
            this.calculateEmiIrr();
        }
        else {
            // if (+this.cEIModel.NetFinance_Amt < +this.selectedScheme.Asset_Cost) {
            //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be less than Net Finance Amount.", ...this.configSuccess });
            //     return;
            // }
            this.calculateEmiIrr();
        }
    }
    calculateEmiIrr() {
        if (this.cEIModel.IRR_Type == 'Step') {
            if (this.stepEMI.length < 2) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Please add step EMI first", ...this.configSuccess });
                return;
            }
            let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
            if (+lastEMI != +this.cEIModel.No_Of_Inst) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Total EMI Should Be Equal To No of Installment", ...this.configSuccess });
                return;
            }
            // if (+lastEMI != +this.cEIModel.No_Of_Inst) {
            //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Total EMI Should Be Equal To No of Installment", ...this.configSuccess });
            //     return;
            // }
        }
        this.showSpinner = true;
      this.LmsService.GetEMICalculate(this.cEIModel).subscribe((res: any) => {
            this.showSpinner = false;
            this.emiIrr = res[0];
            setTimeout(() => {
                var elmntToView = document.getElementById("scrollto");
                elmntToView.scrollIntoView();
            }, 500)
        });
    }
    handleByChange(event: any) {
        this.emiIrr = undefined;
        if (this.cEIModel.IRR_CalculateBy == 'ROI') {
            delete this.cEIModel.EMI_Amount;
        }
        else {
            delete this.cEIModel.Flat_Rate;
        }
    }
    setTenure() {
        if (this.cEIModel.No_Of_Inst) {
            if (this.cEIModel.EMI_Type == 'ByMonthly') {
                this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
            }
            else if (this.cEIModel.EMI_Type == 'Quarterly') {
                this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
            }
            else if (this.cEIModel.EMI_Type == 'HalfYearly') {
                this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
            }
            else if (this.cEIModel.EMI_Type == 'Yearly') {
                this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
            }
            else {
                this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
            }
        }
    this.setAdvanceInstl();
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Advance Installment not greater than No. Of Installment !!!", ...this.configSuccess });

    }
  }
    handleChange(event: any) {
        this.emiIrr = undefined;
        if (this.cEIModel.IRR_Type == 'Flat') {
            this.stepEMI = [];
        }
        else {
            this.stepEMI.push({ FromEMI: 1, ToEMI: '', EMI_Amount: '', disable: false });
            this.stepEmiData = undefined
        }
    }
    addNextStep(data: any) {
        if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill All Data.", ...this.configSuccess });
            return;
        }
        else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "To Emi can not be greater than number of installment.", ...this.configSuccess });
            return;
        }
        // if (+data.ToEMI == +this.cEIModel.No_Of_Inst) {
        //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Another Step can not be added. You can add till number of installment.", ...this.configSuccess });
        //     return;
        // }
        else {
            if (+data.FromEMI > +data.ToEMI) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "To EMI should be greater than From EMI.", ...this.configSuccess });
                return;
            }
            data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
            data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
            if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
                data.disable = true;
                this.stepEMI.push({ FromEMI: +data.ToEMI + 1, ToEMI: '', EMI_Amount: '', disable: false });
            }
            this.getTotlaEmiAndAmount();
        }
    }
    getTotlaEmiAndAmount() {
        if (this.stepEMI.length > 1) {
            let TotalEmi: any = 0, TotalAmount: any = 0
            for (let i = 0; i < this.stepEMI.length; i++) {
                if (this.stepEMI[i].EMI_Amount) {
                    TotalEmi = TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
                    TotalAmount = TotalAmount + ((+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) * +this.stepEMI[i].EMI_Amount);
                }
            }
            this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
        }
    }
    removeLastStep(index: any) {
        this.stepEMI.splice(index, 1);
        this.stepEMI[index - 1].disable = false;
        this.getTotlaEmiAndAmount();
    }
    onSaveApplication() {

        // if (+this.cEIModel.Asset_Cost <= 0) {
        //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be greater than 0.", ...this.configSuccess });
        //     return;
        // }
        if (+this.cEIModel.NetFinance_Amt <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Net Finance Amount should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.Flat_Rate < 0 && this.cEIModel.IRR_CalculateBy == 'ROI') {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Flate rate should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.EMI_Amount <= 0 && this.cEIModel.IRR_CalculateBy == 'EMI') {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI amount should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.No_Of_Inst <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "No of Installment should be greater than 0.", ...this.configSuccess });
            return;
        }
        if (+this.cEIModel.Tenure <= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Tenure should be greater than 0.", ...this.configSuccess });
            return;
        }
      if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI" && +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt < +this.cEIModel.NetFinance_Amt) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI multiply with installment should be equal or grater than net finance.", ...this.configSuccess });
            return;
        }
        if (this.cEIModel.IRR_Type == "Step") {
            let length = this.stepEMI.length;
            let ToEmi = this.stepEMI[length - 1].ToEMI;
            if (+ToEmi != +this.cEIModel.No_Of_Inst) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: "Add EMI step.", ...this.configSuccess });
                return;
            }
        }
        let data = {
            Application: {
                InquiryNo: this.inquiryDetail.InquiryNo,
                BranchId: this.inquiryDetail.BranchId,
                ProductId: this.cEIModel.ProductId,
                Asset_Cost: this.cEIModel.Asset_Cost,
                NetFinance_Amt: this.cEIModel.NetFinance_Amt,
                Flat_Rate: this.cEIModel.Flat_Rate,
                No_Of_Inst: this.cEIModel.No_Of_Inst,
                Tenure: this.cEIModel.Tenure,
                Adv_Inst: this.cEIModel.Adv_Inst,
                MgmtFee: this.cEIModel.MgmtFee,
                EMI_Type: this.cEIModel.EMI_Type,
                Disbursement_Amt: this.emiIrr.Disbursement_Amt,
                Interest_Amt: this.emiIrr.Interest_Amt,
                Agreement_Value: this.emiIrr.Agreement_Value,
                LTV: this.emiIrr.LTV,
                EMI_Amt: this.emiIrr.EMI_Amt,
                Case_IRR: this.emiIrr.Case_IRR,
                Disbursement_IRR: this.emiIrr.Disbursement_IRR,
                Loan_Purpose: this.cEIModel.Purpose,
                Loan_SchemeId: this.selectedScheme.SchemeId,
                FirstEMIDate: this.cEIModel.First_EMI_Date,
                IRR_Type: this.cEIModel.IRR_Type,
                LoginUserId: this.currentUser.userId,
                ROI: this.emiIrr.ROI,
                EMI_Amount: this.cEIModel.EMI_Amount,
                IRR_CalculateBy: this.cEIModel.IRR_CalculateBy
            },
            StepIRR: this.stepEMI
        }
        this.losService.LOS_SaveGenerateApplicationNo({ JSON: JSON.stringify(data) }).subscribe((res: any) => {
            this.loading = false;
            if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                this.router.navigate(['/LOS/my-application']);
            } else {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
        })
    }


}
