<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<!-- <div class="row m-0 mt-3" *ngIf="courierDetail">
  <div class="col-md-2">
    <h6 class="fs-12">Parcel send by</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.CourierBy }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Agency</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.Courier }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Tracking No</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.TrackingNo }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Sending On</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.SendDate }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Sender</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.Sender }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Total Application</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.TotalApplications }}</p>
  </div>
  <div class="col-md-2 mt-3">
    <h6 class="fs-12">Status</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.CourierRecevingStatus }}</p>
  </div>
  <div class="col-md-6 mt-3">
    <h6 class="fs-12">Sender Remarks</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.SenderRemarks }}</p>
  </div>
  <div class="col-md-2 mt-3">
    <h6 class="fs-12">Sender PhoneNo</h6>
    <p class="lead m-0 fs-12">{{ courierDetail.Sender_PhoneNo }}</p>
  </div>
</div> -->
<div class="row m-0 col-md-12 mt-0" *ngIf="courierDetail">
  <div class="col-md-12 mb-1"  [ngClass]="'alert alert-success'">
    <div class="row">       
      <div class="col-md-4"><span class="fs-12"><B>Parcel send by :</B> {{ courierDetail.CourierBy }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Agency :</B> {{ courierDetail.Courier }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Tracking No :</B> {{ courierDetail.TrackingNo }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Sending On :</B> {{ courierDetail.SendDate }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Sender :</B> {{ courierDetail.Sender }}</span></div>     
      <div class="col-md-4"><span class="fs-12"><B>Total Application :</B> {{ courierDetail.TotalApplications }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Status :</B> {{ courierDetail.CourierRecevingStatus }}</span></div>
      <div class="col-md-4"><span class="fs-12"><B>Sender Remarks :</B> {{ courierDetail.SenderRemarks }}</span></div>   
      <div class="col-md-4"><span class="fs-12"><B>Sender PhoneNo :</B> {{ courierDetail.Sender_PhoneNo }}</span></div>
    </div>
  </div>
</div>
<!-- Copied Till Here-->
<hr />
<div class="row m-0 mb-2 mt-3 w-b p-0 col-md-12 align-items-center justify-content-between">
  <h1 class="fs-12 m-0">Application Details</h1>
</div>

<div class="table-responsive">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="ApplicationId">
      <mat-header-cell class="grid-header"
                       style="max-width: 50px"
                       *matHeaderCellDef>
        #
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px">
        {{ i + 1 }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Application_No">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Application No
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <a href="javascript:void(0)" (click)="goToDetail(row)">
          {{
          row.Application_No
          }}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Customer">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Customer
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Customer }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Branch">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Branch
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Branch }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Product">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Product
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Product }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="IsFileReceived">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.IsFileReceived }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell class="grid-header"
                       style="max-width: 60px"
                       *matHeaderCellDef>
        Action
      </mat-header-cell>
      <mat-cell *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 60px">
        <input type="checkbox"
               [value]="row.ApplicationId"
               [disabled]="inViewMode"
               [checked]="ApplicationData.indexOf(row.ApplicationId) > -1"
               (change)="onCheckboxChange($event, row)" />
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<hr />
<form #f="ngForm" (ngSubmit)="onSaveReceiveParcel()" novalidate>
  <div class="row m-0 mt-2">
    <div class="col-md-12 p-1">
      <span class="required-lable"> Remarks </span>
      <textarea name="Remarks"
                id="Remarks"
                #refRemarks="ngModel"
                [disabled]="inViewMode"
                rows="2"
                maxlength="500"
                [ngClass]="{
          'is-invalid': f.submitted && refRemarks.invalid,
          'alert-warning':
            refRemarks.invalid &&
            (refRemarks.dirty || refRemarks.touched || refRemarks.untouched)
        }"
                [(ngModel)]="receivedModal.ReceiverRemarks"
                class="form-control input-text-css"
                placeholder="Remarks"
                required>
      </textarea>
    </div>
  </div>
  <hr />
  <div class="text-right mb-2">
    <button type="button"
            (click)="OnClose()"
            class="btn btn-light font-size-12 mr-3">
      Cancel
    </button>
    <button type="button"
            (click)="onSaveReceiveParcel()"
            *ngIf="!inViewMode"class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid">
      Submit
    </button>
  </div>
</form>
