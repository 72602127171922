<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #f="ngForm"
    (keydown.enter)="onSearchDVDetail()"
    novalidate
    class="row m-0 col-md-12 p-0 formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span>Loan No.</span>
        <input
          name="SLoanNo"
          [(ngModel)]="LoanNo"
          id="SLoanNo"
          placeholder="Loan No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="onSearchDVDetail()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
    </div>
  </form>
  <hr />
  <div class="mt-3" *ngIf="dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="VoucherId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 60px; max-height: 5px"
            >
              <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CaseNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CaseNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Voucher Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_Date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ViewAction">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 100px; justify-content: center"
              *matHeaderCellDef
            >
              View Detail
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell text-center"
              style="max-width: 100px"
            >
              <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                &nbsp;
              </div>
              <ng-template #elseBlock>
                <app-lms-voucher-view-model
                  [voucherid]="row.VoucherId"
                  [DVEdit]="true"
                  (close)="GetData($event)"
                  [index]="i"
                >
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                    aria-label="true"
                  ></i>
                </app-lms-voucher-view-model>
              </ng-template>
            </mat-cell>
            <!--<mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width:60px">
              <i class="fa fa-arrow-circle-right btn_detail" aria-label="true" (click)="goToDetail(row)"></i>
            </mat-cell>-->
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <!--<div class="row m-0 pt-2 pb-1" style="background: #fff;">
          <div class="col-md-2">
            <button class="btn btn-success font-size-12" (click)="exportExcel()">
              <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
            </button>
          </div>
          <div class="col-md-2">
            <button class="btn btn-info font-size-12" (click)="printdata()">
              <i class="fa fa-print mr-1" style="font-size:medium;cursor: pointer;"></i> Print
            </button>
          </div>
          <div class="col-md-8 text-right">
            <div *ngIf="dataSource.filteredData.length >= TotRow">
              <button class="btn btn-success font-size-12" (click)="LoadMore()">
                <i class="fa fa-spinner mr-1" style="font-size:medium;cursor: pointer;"></i> Load More
              </button>
            </div>-->
        <!-- <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator> -->
        <!--</div>
        </div>-->
      </div>
    </div>
  </div>
</div>
