import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { PartnerService } from "../services/partner.service";
import { LmsService } from "src/app/_Lms/services/lms.service";
declare var $: any;

@Component({
  selector: "app-partner-loan-pre-closer-details",
  templateUrl: "./partner-loan-pre-closer-details.component.html",
  styleUrls: ["./partner-loan-pre-closer-details.component.scss"],
})
export class PartnerLoanPreCloserDetailsComponent implements OnInit {
  data: any;
  LoanId: any;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  LoanAllDetail: any = [];
  LoanCustomerList: any = [];
  MinEffectiveDate = new Date();
  _prevDate: any;
  loanSummary: any = {};
  PageType: any = "A";

  // @Input() loanSummary: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  PreCloserDetails: any = { LoanCloser_EffectiveDate: new Date() };
  PreCloserMethodList: any = [];
  totalCalculator: any = {
    totalDueAmount: 0,
    totalReceiveAmount: 0,
    totalWaiverAmount: 0,
    totalBalance: 0,
  };
  isExpired: boolean = false;
  isMyCloserRequest: boolean = false;
  isWavierGivenByMe: boolean = false;
  isUndoRemark: boolean = false;
  PLoanType: any = "Partner";
  FirstEMIDate: any;
  isShowForward: boolean = false;
  wavieruserList: any[] = [];
  closerId: any;
  RequestDocName: any = "";
  RequestDocBase64: any = "";
  loandocBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  undoRemark: any = "";
  Partner_LoanNo: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private _Partnerservice: PartnerService,
    private location: Location,
    private lmsService: LmsService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Pre Closer Details");
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("loanId"))
      );
      this.closerId = Number(
        this._EncrDecrService.decrypt(decodeURIComponent(param.get("closerId")))
      );
      console.log("this.LoanId :", this.LoanId);
      this.getLoanRescheduleDetail();
    });

    this.displayedColumns = [
      "Int_Id",
      "Customer",
      "CustomerType",
      "GenderAge",
      "PhoneNo",
      "Relation_With_Hirer",
      "Present_Address",
      "ExistingCustomer",
    ];
  }

  getLoanRescheduleDetail() {
    //  this.showSpinner = true;
    this._Partnerservice
      .Partner_Get_Loan_Closer_Detail({ LoanId: this.LoanId })
      .subscribe((res: any) => {
        console.log("RES :", res);
        if (res != null) {
          this.LoanAllDetail = res.Item1;
          console.log("this.loanAllDetails", this.LoanAllDetail);

          //this.MinEffectiveDate = new Date(this.LoanAllDetail[0].LoanDate);
          this.Partner_LoanNo = this.LoanAllDetail[0].Partner_LoanNo;
          // this.FirstEMIDate = this.LoanAllDetail[0].EMI_DueDate	;
          this.FirstEMIDate = new Date(
            this.LoanAllDetail[0].EMI_DueDate
          ).toLocaleDateString();

          this.LoanCustomerList = JSON.parse(JSON.stringify(res.Item2));
          this.dataSource = new MatTableDataSource(this.LoanCustomerList);
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
          this.Partner_Get_Loan_Closer_Due_Detail(null);
        }
      });
  }

  onViewLoanStatement() {
    console.log("loan");
    if (this.LoanAllDetail[0]) {
      this.loanSummary.LoanId = parseInt(this.LoanId);
      this.loanSummary.ApplicationNo = this.LoanAllDetail[0].ApplicationNo;
      this.loanSummary.LoanNo = this.LoanAllDetail[0].Partner_LoanNo;
      this.loanSummary.LoanDate = this.LoanAllDetail[0].LoanDate;

      this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
      console.log(" this.FirstEMIDate ", this.FirstEMIDate);
      console.log("this.loanSummary", this.loanSummary);
      $("#LoanStatementPrintModel").modal("show");
      $("#LoanStatementPrintModel").css("z-index", "1050");
    }
  }

  onCloseLoanStatementPrintModel() {
    $("#LoanStatementPrintModel").modal("hide");
  }
  onEffectiveDateChange() {
    this.Partner_Get_Loan_Closer_Due_Detail(this.MinEffectiveDate);
  }

  Partner_Get_Loan_Closer_Due_Detail(date: any) {
    console.log(" this.Partner_LoanNo :", this.Partner_LoanNo);
    //this.showSpinner = true;
    this._Partnerservice
      .Partner_Get_Loan_Closer_Due_Detail({
        LoanAcNo: this.Partner_LoanNo,
        StartDate: date != null ? date : this.MinEffectiveDate,
        LoginUserId: this.data.userId,
        LoanCloserId: this.closerId,
      })
      .subscribe((res: any) => {
        console.log("RES :", res);
        if (res != null) {
          let _data = res.Item1[0] || {};
          if (this.closerId > 0) {
            this.MinEffectiveDate = new Date(_data.LoanCloser_EffectiveDate);
          }

          _data.NextDueDate = new Date(_data.NextDueDate);

          _data.LoanCloser_EffectiveDate = new Date(this.MinEffectiveDate);

          this._prevDate = this.MinEffectiveDate;
          _data.CloserType = _data.CloserType || "";
          this.PreCloserDetails = _data;
          this.PreCloserMethodList = res.Item2 ? res.Item2 : [];
          this.PreCloserMethodList.map((item) => {
            if (this.closerId == 0) {
              item.Balance =
                item.DueAmount - item.Payment < 0
                  ? item.DueAmount - item.Payment
                  : item.DueAmount - item.Payment;

              item.WaiverAmount = 0;
            }
          });
          this.PreCloserDetails.Waiver_GivenBy =
            this.PreCloserDetails.Waiver_GivenBy;
          if (
            this.PreCloserDetails.Closer_RequestStatus != "Closed" &&
            new Date().toLocaleDateString("fr-CA") !=
              new Date(
                this.PreCloserDetails.LoanCloser_EffectiveDate
              ).toLocaleDateString("fr-CA")
          ) {
            this.isExpired =
              this.PreCloserDetails.LoanCloser_EffectiveDate.getTime() <
              new Date().getTime();
          }
          this.isMyCloserRequest =
            (this.PreCloserDetails.LoanCloser_RequestBy || "").toString() ==
            this.data.userId.toString();
          this.isWavierGivenByMe =
            (this.PreCloserDetails.Waiver_GivenBy || "").toString() ==
            this.data.userId.toString();
          this.onCalculateAmount();
          this.showSpinner = false;
        }
      });
  }

  onCalculateAmount() {
    let _totalDue: any = 0;
    let _totalReceived: any = 0;
    let _totalWaiver: any = 0;
    let _totalBalance: any = 0;
    let _maxWaiver: any = 0;

    this.PreCloserMethodList.forEach((obj) => {
      _totalDue = _totalDue + obj.DueAmount;
      _totalReceived = _totalReceived + obj.Payment;
      _totalWaiver = _totalWaiver + Number(obj.WaiverAmount);
      _totalBalance = _totalBalance + obj.Balance;
      obj.initialWaiverAmount = obj.WaiverAmount || 0;
      obj.lastWaiverAmount = obj.WaiverAmount || 0;
      obj.initialBalance = obj.initialBalance || obj.Balance + obj.WaiverAmount;
      if (obj.IsWaiver_Eligible) {
        _maxWaiver = _maxWaiver + obj.Balance;
      }
    });

    this.totalCalculator = {
      totalDueAmount: Number(_totalDue),
      totalReceiveAmount: Number(_totalReceived),
      totalWaiverAmount: Number(_totalWaiver),
      totalBalance: Number(_totalBalance),
      maxWaiver: Number(_maxWaiver),
    };
    this.PreCloserDetails.Waiver_Limit_Per_Amt = Number(
      ((this.totalCalculator.totalDueAmount -
        (this.totalCalculator.totalReceiveAmount +
          this.totalCalculator.totalWaiverAmount)) *
        this.PreCloserDetails.Waiver_Limit_Per) /
        100
    );
    this.PreCloserDetails.Waiver_Limit_Per_Amt_R =
      this.PreCloserDetails.Waiver_Limit_Per_Amt <
      this.PreCloserDetails.Waiver_Limit_Amt
        ? this.PreCloserDetails.Waiver_Limit_Per_Amt
        : this.PreCloserDetails.Waiver_Limit_Amt;

    if (
      !this.PreCloserDetails.isFirstTimeCalculate &&
      this.PreCloserDetails.CloserId
    ) {
      let _tr =
        this.totalCalculator.totalBalance < 0
          ? this.totalCalculator.totalBalance * -1
          : this.totalCalculator.totalBalance;
      this.PreCloserDetails.CustomerAgreed_Amount =
        this.totalCalculator.totalBalance < 0
          ? this.totalCalculator.totalBalance * -1
          : this.totalCalculator.totalBalance;
      this.PreCloserDetails.Prev_CustomerAgreed_Amount =
        this.PreCloserDetails.CustomerAgreed_Amount;
      this.PreCloserDetails.totalWaiverAmount =
        _tr - this.PreCloserDetails.CustomerAgreed_Amount; // <= 0 ? this.totalCalculator.totalWaiverAmount || 0 : (this.totalCalculator.totalWaiverAmount || 0) + (_tr - this.PreCloserDetails.CustomerAgreed_Amount);

      this.Get_User_For_Waiver_Approval();
      if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Approved") {
        this.PreCloserDetails.totalWaiverAmount =
          this.PreCloserDetails.TotalWaiver_Approved;
      }
      if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Pending") {
        this.isShowForward = true;
      } else if (
        this.PreCloserDetails.totalWaiverAmount >
        this.PreCloserDetails.Waiver_Limit_Per_Amt_R
      ) {
        this.isShowForward =
          this.PreCloserDetails.Closer_RequestStatus !== "Waiver Approved";
      }
      this.PreCloserDetails.isFirstTimeCalculate = true;
    }
    if (
      !this.PreCloserDetails.isFirstTimeCalculate &&
      !this.PreCloserDetails.CloserId
    ) {
      let _tr =
        this.totalCalculator.totalBalance < 0
          ? this.totalCalculator.totalBalance * -1
          : this.totalCalculator.totalBalance;
      this.PreCloserDetails.CustomerAgreed_Amount =
        this.totalCalculator.totalBalance < 0
          ? this.totalCalculator.totalBalance * -1
          : this.totalCalculator.totalBalance;
      this.PreCloserDetails.Prev_CustomerAgreed_Amount =
        this.PreCloserDetails.CustomerAgreed_Amount;
      this.PreCloserDetails.totalWaiverAmount =
        _tr - this.PreCloserDetails.CustomerAgreed_Amount;
      this.PreCloserDetails.isFirstTimeCalculate = true;
    }
  }
  resetCalculation() {
    this.PreCloserMethodList.forEach((obj) => {
      obj.WaiverAmount = 0; //obj.initialWaiverAmount;
      obj.Balance = obj.initialBalance - obj.WaiverAmount;
    });
    this.onCalculateAmount();
  }

  onChangeAgreeAmount(isReset: boolean = true) {
    if (
      this.PreCloserDetails.Prev_CustomerAgreed_Amount ==
      +this.PreCloserDetails.CustomerAgreed_Amount
    ) {
      return;
    }
    if (isReset) this.resetCalculation();
    let _tr =
      this.totalCalculator.totalBalance < 0
        ? this.totalCalculator.totalBalance * -1
        : this.totalCalculator.totalBalance;
    let _totalWaiverAmount =
      _tr - this.PreCloserDetails.CustomerAgreed_Amount < 0
        ? 0
        : _tr - this.PreCloserDetails.CustomerAgreed_Amount;
    if (this.PreCloserDetails.CustomerAgreed_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Deposit amount can not be less than zero.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        this.PreCloserDetails.CustomerAgreed_Amount =
          this.PreCloserDetails.Prev_CustomerAgreed_Amount;
      });
      return;
    }
    if (_tr - this.PreCloserDetails.CustomerAgreed_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Deposit amount can not be greater than ${this.totalCalculator.totalBalance}.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        this.PreCloserDetails.CustomerAgreed_Amount =
          this.PreCloserDetails.Prev_CustomerAgreed_Amount;
      });
      return;
    }
    this.PreCloserDetails.totalWaiverAmount = _totalWaiverAmount;
    if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Pending") {
      this.isShowForward = true;
      this.Get_User_For_Waiver_Approval();
    } else {
      if (
        this.PreCloserDetails.totalWaiverAmount >
        this.PreCloserDetails.Waiver_Limit_Per_Amt_R
      ) {
        this.isShowForward =
          this.PreCloserDetails.Closer_RequestStatus !== "Waiver Approved";
        this.Get_User_For_Waiver_Approval();
      } else {
        this.isShowForward = false;
      }
    }
    this.PreCloserDetails.Prev_CustomerAgreed_Amount =
      +this.PreCloserDetails.CustomerAgreed_Amount;
  }
  onChangeWaiverAmt(event, item) {
    item.Balance = +item.initialBalance - +item.WaiverAmount;
    if (item.Is_Tax) {
      let _find =
        this.PreCloserMethodList.filter((obj: any) => {
          return obj.HeadId == item.HeadId;
        }) || [];
      _find.forEach((obj: any) => {
        if (!obj.Is_Tax) {
          obj.WaiverAmount = Math.round(
            (item.WaiverAmount * obj.Tax_Per) / 100
          );
          obj.Balance = Math.round((item.Balance * obj.Tax_Per) / 100);
        }
      });
    }
    this.onCalculateAmount();
  }

  fileChangeEvent(fileInput) {
    if (fileInput.target.files && fileInput.target.files.length) {
      let files = fileInput.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.onload = (e) => {
        this.RequestDocName = "IMG_" + new Date().getTime() + ".png";
        this.RequestDocBase64 = fileReader.result
          .toString()
          .split(";base64,")
          .pop();
      };
    } else {
      this.PreCloserDetails.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
    }
  }

  onRemoveImg() {
    this.PreCloserDetails.CloserRequestDoc = "";
    this.RequestDocName = "";
    this.RequestDocBase64 = "";
    this.PreCloserDetails.Request_Doc = "";
  }

  onCancel() {
    this.location.back();
  }
  onDeleteCloserRequest() {}
  onUndoCloserRequest() {}
  onClickPrintStatement() {}
  onSaveAsDraft() {
    this.saveLoanCloser("Created");
  }
  saveLoanCloser(status: any) {
    console.log("this.PreCloserMethodList", this.PreCloserMethodList);
    if (!this.PreCloserDetails.CloserType) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Please select closer type.`,
        ...this.configSuccess,
      });
      return;
    }
    if (!this.PreCloserDetails.LoanCloser_Remark) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Please enter remark.`,
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.totalCalculator.totalWaiverAmount > 0 &&
      this.PreCloserMethodList[0].WaiverAccountId == 0
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Please bind Waiver account.`,
        ...this.configSuccess,
      });
      return;
    }
    var AccountHeadIdexist = true;
    var ChagesHead = "";
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: "Closer",
      Voucher_Sub_Type: "Partner",
      Voucher_Tag: "",
      Voucher_Date: this.MinEffectiveDate,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: this.totalCalculator.totalBalance,
      Voucher_Total_Debit_Amount: this.totalCalculator.totalBalance,
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      Voucher_LoanId: parseInt(this.LoanId),
      Origin: "WEB",
      LoginUserId: this.data.userId,
    };
    this.PreCloserMethodList.forEach((obj) => {
      if (obj.AccountHeadId > 0) {
        if (obj.Balance > 0) {
          this.voucherModel.Voucher_Detail.push({
            VoucherId: 0,
            CaseNo: this.LoanAllDetail[0].Partner_LoanNo,
            CustomerId: "",
            Is_LOSAppliaction: "0",
            ChargeHeadId: obj.HeadId,
            ChargeHeadCategory: "",
            AccountId: obj.AccountHeadId,
            BranchId: this.LoanAllDetail[0].BranchId,
            TranType: "DR",
            IsTax: 0,
            Tax_Per: 0,
            Amount: obj.Balance,
            Type: "",
          });
        } else if (obj.Balance < 0) {
          this.voucherModel.Voucher_Detail.push({
            VoucherId: 0,
            CaseNo: this.LoanAllDetail[0].Partner_LoanNo,
            CustomerId: "",
            Is_LOSAppliaction: "0",
            ChargeHeadId: obj.HeadId,
            ChargeHeadCategory: "",
            AccountId: obj.AccountHeadId,
            BranchId: this.LoanAllDetail[0].BranchId,
            TranType: "CR",
            IsTax: 0,
            Tax_Per: 0,
            Amount: obj.Balance * -1,
            Type: "",
          });
        }
      } else {
        AccountHeadIdexist = false;
        ChagesHead = ChagesHead + obj.ChagesHead;
      }
    });
    if (AccountHeadIdexist == true) {
      console.log("this.voucherModel", this.voucherModel);
      this.saveVoucher();
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Please Account Bind of  ` + ChagesHead + ` !!!.`,
        ...this.configSuccess,
      });
      this.voucherModel.Voucher = {};
      this.voucherModel.Voucher_Detail = [];
    }
  }

  Get_User_For_Waiver_Approval() {}

  onPreClosureClose() {
    if (
      this.totalCalculator.totalBalance <=
      this.PreCloserDetails.CustomerAgreed_Amount +
        this.totalCalculator.totalWaiverAmount
    ) {
      this.dialog
        .openConfirmDialog(`Alert!! Are you sure you want to close this Loan?`)
        .afterClosed()
        .subscribe((dialogResult) => {
          if (dialogResult == false) {
            return;
          } else {
            this.saveLoanCloser("Closed");
          }
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Sum of customer agree amount  and waiver amount should be equal to received amount`,
        ...this.configSuccess,
      });
    }
  }
  onWaieverApproved() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Approve?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.saveLoanCloser("Waiver Approved");
        }
      });
  }

  onWaieverReject() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Reject.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.saveLoanCloser("Waiver Rejected");
        }
      });
  }
  onForwardToSeniorWaiverApproval() {
    if (this.PreCloserDetails.Waiver_GivenBy != "") {
      this.saveLoanCloser("Waiver Pending");
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Pleasse select user to waiver.`,
        ...this.configSuccess,
      });
    }
  }

  saveVoucher() {
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _Closer: any = {
      CloserId: this.PreCloserDetails.CloserId,
      LoanId: JSON.parse(this.LoanId),
      Request_Status: "Closed",
      EffectiveDate: new Date(
        this.PreCloserDetails.LoanCloser_EffectiveDate
      ).toLocaleDateString("fr-CA"),
      DueAmount: this.totalCalculator.totalDueAmount,
      ReceivedAmount: this.totalCalculator.totalReceiveAmount,
      BalanceAmount: this.totalCalculator.totalBalance,
      CustomerAgreedAmount: this.PreCloserDetails.CustomerAgreed_Amount,
      WaiverAmount: this.totalCalculator.totalWaiverAmount,
      WaiverLimit: this.PreCloserDetails.Waiver_Limit_Amt,
      Closer_Remark: this.PreCloserDetails.LoanCloser_Remark,
      Waiver_Remark: this.PreCloserDetails.Waiver_Remark,
      CloserDoc: this.RequestDocName || this.PreCloserDetails.CloserRequestDoc,
      LoginUserId: this.data.userId,
      WavierGivenBy: this.PreCloserDetails.Waiver_GivenBy,
      CloserType: this.PreCloserDetails.CloserType,
    };
    let _CloserDetail: any[] = [];
    this.PreCloserMethodList.forEach((element: any) => {
      if (element.WaiverAmount > 0 && element.AccountHeadId <= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: `Pleasse Bind Waiver Account.`,
          ...this.configSuccess,
        });
        return;
      } else {
        _CloserDetail.push({
          HeadId: element.HeadId,
          AccountHeadId: element.AccountHeadId,
          DueAmount: element.DueAmount,
          ReceivedAmount: element.Payment,
          WaiverAmount: element.WaiverAmount,
          IsWaiver_Eligible: element.IsWaiver_Eligible,
        });
      }
    });

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: this.voucherModel.Voucher_Detail,
      Closer: _Closer,
      CloserDetail: _CloserDetail,
    };
    //if (this.RequestDocBase64) {
    //  let saveObj = {
    //    "DocName": this.RequestDocName,
    //    "LoanId": JSON.parse(this.LoanId),
    //    "DocData": this.RequestDocBase64
    //  }
    //  this.lmsService.UploadLoanDoc(saveObj).subscribe((respose: any) => {
    //    if (respose) {
    //    }
    //  });
    //}
    console.log("_finalData :", JSON.stringify(_finalData));
    this._Partnerservice
      .Partner_Save_Closer_Voucher({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          console.log(
            "this.totalCalculator.totalWaiverAmount :",
            this.totalCalculator.totalWaiverAmount
          );
          if (this.totalCalculator.totalWaiverAmount > 0) {
            var Voucher = {
              VoucherId: 0,
              Voucher_Type: "Closer",
              Voucher_Sub_Type: "Partner",
              Voucher_Tag: "",
              Voucher_Date: this.MinEffectiveDate,
              DRAccountId: "",
              Voucher_Mode_of_Payment: "",
              Voucher_Total_Credit_Amount:
                this.totalCalculator.totalWaiverAmount,
              Voucher_Total_Debit_Amount:
                this.totalCalculator.totalWaiverAmount,
              Voucher_Total_Tax: "",
              Voucher_Narration: "",
              Voucher_Cheque_No: "",
              Voucher_Cheque_Date: "",
              Voucher_Bank_Name: "",
              Voucher_Cheque_Clear_Date: "",
              Voucher_E_Transaction_Date: "",
              Voucher_E_Ref_No: "",
              Voucher_ReceiptNo_Book: "",
              Voucher_ReceiptDate: "",
              Voucher_LoanId: parseInt(this.LoanId),
              Origin: "WEB",
              LoginUserId: this.data.userId,
            };

            var Voucher_Detail = [];
            this.PreCloserMethodList.forEach((obj) => {
              if (Number(obj.WaiverAmount) > 0) {
                Voucher_Detail.push({
                  VoucherId: 0,
                  CaseNo: this.LoanAllDetail[0].Partner_LoanNo,
                  CustomerId: "",
                  Is_LOSAppliaction: "0",
                  ChargeHeadId: obj.HeadId,
                  ChargeHeadCategory: "",
                  AccountId: obj.AccountHeadId,
                  BranchId: this.LoanAllDetail[0].BranchId,
                  TranType: "DR",
                  IsTax: 0,
                  Tax_Per: 0,
                  Amount: obj.WaiverAmount,
                  Type: "",
                });
              }
            });
            Voucher_Detail.push({
              VoucherId: 0,
              CaseNo: this.LoanAllDetail[0].Partner_LoanNo,
              CustomerId: "",
              Is_LOSAppliaction: "0",
              ChargeHeadId: 37,
              ChargeHeadCategory: "",
              AccountId: this.PreCloserMethodList[0].WaiverAccountId,
              BranchId: this.LoanAllDetail[0].BranchId,
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount: this.totalCalculator.totalWaiverAmount,
              Type: "",
            });

            let _vouvher: any = { ...Voucher };

            let _finalDatawaiwer = {
              Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
              Voucher_Detail: Voucher_Detail,
            };
            console.log("this._finalDatawaiwer", _finalDatawaiwer);
            //if (this.RequestDocBase64) {
            //  let saveObj = {
            //    "DocName": this.RequestDocName,
            //    "LoanId": JSON.parse(this.LoanId),
            //    "DocData": this.RequestDocBase64
            //  }
            //  this.lmsService.UploadLoanDoc(saveObj).subscribe((respose: any) => {
            //    if (respose) {
            //    }
            //  });
            //}
            console.log("_finalDatawaiwer :", JSON.stringify(_finalDatawaiwer));
            this._Partnerservice
              .Partner_Save_Closer_Voucher({
                JSON: JSON.stringify(_finalDatawaiwer),
              })
              .subscribe((res11: any) => {
                if (res11[0].CODE >= 0) {
                  this.onCancel();
                }
              });
          } else {
            this.onCancel();
          }
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
