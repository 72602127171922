<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="row m-0 col-md-12 mt-0 formborder" *ngIf="inquiryDetail">
  <div class="col-md-12 mb-1" [ngClass]="'alert alert-success'">
    <div class="row">
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Inquiry No :</B> {{ inquiryDetail.InquiryNo }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Customer :</B> {{ inquiryDetail.CustomerName }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Address :</B> {{ inquiryDetail.CustomerAddress }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Contact Number :</B> {{ inquiryDetail.ContactNumber }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"><B>Email :</B> {{ inquiryDetail.Inq_Email }}</span>
      </div>
      <div class="col-md-4">
        <span class="fs-12"><B>Source :</B> {{ inquiryDetail.Source }}</span>
      </div>
      <div class="col-md-4">
        <span class="fs-12"><B>Branch :</B> {{ inquiryDetail.Branch }}</span>
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Product :</B> {{ inquiryDetail.Product_Name }}</span
        >
      </div>

      <div class="col-md-4">
        <span class="fs-12"
          ><B>Loan Amount :</B> {{ inquiryDetail.LoanAmount.toFixed(2) }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Inquiry Status :</B> {{ inquiryDetail.Status }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Inquiry CreateOn :</B> {{ inquiryDetail.CreateOn }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Inquiry AssignOn :</B> {{ inquiryDetail.AssignedOn }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"><B>TAT :</B> {{ inquiryDetail.TAT }}</span>
      </div>
      <div class="col-md-4">
        <span class="fs-12"><B>Purpose :</B> {{ inquiryDetail.Purpose }}</span>
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Next Followup Date :</B> {{ inquiryDetail.NextFollowUp }}</span
        >
      </div>
      <div class="col-md-4">
        <span class="fs-12"
          ><B>Hold Reason :</B> {{ inquiryDetail.Reason }}</span
        >
      </div>
    </div>
  </div>
</div>
<!--Copied Till Here-->
<hr />
<div class="row m-0">
  <div class="row m-0 col-md-1"></div>
  <div
    class="row m-0 col-md-2 align-items-center justify-content-center"
    *ngIf="!inViewMode || inquiryDetail.Status == 'Hold'"
  >
    <button
      type="button"
      (click)="onHold($event)"
      class="mt-3 btn font-size-12 button-btn"
    >
      Hold
    </button>
  </div>
  <div
    class="row m-0 col-md-2 align-items-center justify-content-center"
    *ngIf="!inViewMode"
  >
    <button
      type="button"
      (click)="onRevert($event)"
      class="mt-3 btn font-size-12 button-btn"
    >
      Revert
    </button>
  </div>
  <div
    class="row m-0 col-md-2 align-items-center justify-content-center"
    *ngIf="!inViewMode || inquiryDetail.Status == 'Hold'"
  >
    <button
      type="button"
      (click)="onReject($event)"
      class="mt-3 btn font-size-12 button-btn"
    >
      Reject
    </button>
  </div>
  <div
    class="row m-0 col-md-2 align-items-center justify-content-center"
    *ngIf="!inViewMode || inquiryDetail.Status == 'Hold'"
  >
    <button
      type="button"
      (click)="onProceed()"
      class="mt-3 btn font-size-12 button-btn"
    >
      Proceed
    </button>
  </div>
  <div class="row m-0 col-md-2 align-items-center justify-content-center">
    <button
      type="button"
      (click)="OnClose()"
      class="mt-3 btn font-size-12 button-btn"
    >
      Cancel
    </button>
  </div>
  <div class="row m-0 col-md-1"></div>
</div>

<div
  class="modal fade in"
  id="hold"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ihf="ngForm" (ngSubmit)="onHoldInquiry()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Hold Inquiry
          </h6>
          <button
            type="button"
            (click)="onHoldClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-12 p-1">
              <span> Inquiry Number </span>
              <input
                type="text"
                readonly
                #refInquiryNo="ngModel"
                [(ngModel)]="holdModel.InquiryNo"
                placeholder="Inquiry No"
                [ngClass]="{
                  'is-invalid': ihf.submitted && refInquiryNo.invalid,
                  'alert-warning':
                    refInquiryNo.invalid &&
                    (refInquiryNo.dirty ||
                      refInquiryNo.touched ||
                      refInquiryNo.untouched)
                }"
                name="InquiryNo"
                id="InquiryNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Reason </span>
              <textarea
                required
                rows="3"
                #refHoldReason="ngModel"
                maxlength="500"
                [ngClass]="{
                  'is-invalid': ihf.submitted && refHoldReason.invalid,
                  'alert-warning':
                    refHoldReason.invalid &&
                    (refHoldReason.dirty ||
                      refHoldReason.touched ||
                      refHoldReason.untouched)
                }"
                [(ngModel)]="holdModel.HoldReason"
                placeholder="Reason"
                name="HoldReason"
                id="HoldReason"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Next Followup Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  required
                  name="NextFollowUp"
                  id="NextFollowUp"
                  #refNextFollowUp="ngModel"
                  [ngClass]="{
                    'is-invalid': ihf.submitted && refNextFollowUp.invalid,
                    'alert-warning':
                      refNextFollowUp.invalid &&
                      (refNextFollowUp.dirty ||
                        refNextFollowUp.touched ||
                        refNextFollowUp.untouched)
                  }"
                  [(ngModel)]="holdModel.NextFollowUp"
                  [min]="tomorrowDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                required
                name="NextFollowUp"
                id="NextFollowUp"
                #refNextFollowUp="ngModel"
                [ngClass]="{
                  'is-invalid': ihf.submitted && refNextFollowUp.invalid,
                  'alert-warning':
                    refNextFollowUp.invalid &&
                    (refNextFollowUp.dirty ||
                      refNextFollowUp.touched ||
                      refNextFollowUp.untouched)
                }"
                [(ngModel)]="holdModel.NextFollowUp"
                [minDate]="tomorrowDate"
                value="{{ holdModel.NextFollowUp | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="holdModel.NextFollowUp"
                bsDatepicker
              /> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="onHoldClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onHoldInquiry()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!ihf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  class="modal fade in"
  id="revert"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #irf="ngForm" (ngSubmit)="onRevertInquiry()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Revert Inquiry
          </h6>
          <button
            type="button"
            (click)="onRevertClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-12 p-1">
              <span> Inquiry Number </span>
              <input
                type="text"
                readonly
                #refInquiryNo="ngModel"
                [(ngModel)]="revertModel.InquiryNo"
                placeholder="Inquiry No"
                [ngClass]="{
                  'is-invalid': irf.submitted && refInquiryNo.invalid,
                  'alert-warning':
                    refInquiryNo.invalid &&
                    (refInquiryNo.dirty ||
                      refInquiryNo.touched ||
                      refInquiryNo.untouched)
                }"
                name="InquiryNo"
                id="InquiryNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Reason </span>
              <textarea
                required
                rows="3"
                #refRevertReason="ngModel"
                maxlength="500"
                [ngClass]="{
                  'is-invalid': irf.submitted && refRevertReason.invalid,
                  'alert-warning':
                    refRevertReason.invalid &&
                    (refRevertReason.dirty ||
                      refRevertReason.touched ||
                      refRevertReason.untouched)
                }"
                [(ngModel)]="revertModel.RevertReason"
                placeholder="Reason"
                name="RevertReason"
                id="RevertReason"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="onRevertClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onRevertInquiry()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!irf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  class="modal fade in"
  id="reject"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #irjf="ngForm" (ngSubmit)="onRejectInquiry()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Reject Inquiry
          </h6>
          <button
            type="button"
            (click)="onRejectClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-12 p-1">
              <span> Inquiry Number </span>
              <input
                type="text"
                readonly
                #refInquiryNo="ngModel"
                [(ngModel)]="rejectModel.InquiryNo"
                placeholder="Inquiry No"
                [ngClass]="{
                  'is-invalid': irjf.submitted && refInquiryNo.invalid,
                  'alert-warning':
                    refInquiryNo.invalid &&
                    (refInquiryNo.dirty ||
                      refInquiryNo.touched ||
                      refInquiryNo.untouched)
                }"
                name="InquiryNo"
                id="InquiryNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Reason </span>
              <textarea
                required
                rows="3"
                #refRejectReason="ngModel"
                maxlength="500"
                [ngClass]="{
                  'is-invalid': irjf.submitted && refRejectReason.invalid,
                  'alert-warning':
                    refRejectReason.invalid &&
                    (refRejectReason.dirty ||
                      refRejectReason.touched ||
                      refRejectReason.untouched)
                }"
                [(ngModel)]="rejectModel.RejectReason"
                placeholder="Reason"
                name="RejectReason"
                id="RejectReason"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="onRejectClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onRejectInquiry()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!irjf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
