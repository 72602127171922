import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";

import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-mst-district-list",
  templateUrl: "./mst-district-list.component.html",
  styleUrls: ["./mst-district-list.component.scss"],
})
export class MstDistrictListComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  FilterStateId: any = 29;
  RequestModel: RequestModel = new RequestModel();
  SaveDistictRequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = [
    "DistrictId",
    "State_Name",
    "District_Name",
    "TehsilCount",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ZoneList: any = [];
  FilterStateList: any = [];
  // add
  DistrictId: any = 0;
  StateId: any = "";
  DistrictName: any = "";
  ButtonName: string = "Add";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("District List");
    this._MasterService.GetState().subscribe((result) => {
      this.FilterStateList = JSON.parse(JSON.stringify(result));
    });
    this.getDistrictList();
  }

  getDistrictList() {
    this.showSpinner = true;
    this.RequestModel.StateId =
      this.FilterStateId == "" ? 0 : parseInt(this.FilterStateId);
    this._MasterService
      .GetDistrictsList(this.RequestModel)
      .subscribe((result) => {
        this.ZoneList = JSON.parse(JSON.stringify(result));

        this.dataSource = new MatTableDataSource(this.ZoneList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  onOpenDistrictModal() {
    $("#AddEditDistrictModal").modal("show");
    $("#AddEditDistrictModal").css("z-index", "1050");
  }

  OneditDistrict(row) {
    this.DistrictId = row.DistrictId;
    this.StateId = row.StateId;
    this.DistrictName = row.District_Name;
    this.ButtonName = "Edit";
    $("#AddEditDistrictModal").modal("show");
    $("#AddEditDistrictModal").css("z-index", "1050");
  }

  OnClose() {
    this.DistrictId = 0;
    this.StateId = "";
    this.DistrictName = "";
    this.ButtonName = "Add";
    $("#AddEditDistrictModal").modal("hide");
  }

  onSaveDistrictData() {
    this.SaveDistictRequestModel.District_ID = this.DistrictId;
    this.SaveDistictRequestModel.State_Id = parseInt(this.StateId);
    this.SaveDistictRequestModel.District_Name = this.DistrictName;
    this.SaveDistictRequestModel.LoginUserId = this.data.userId;
    this._MasterService
      .SaveDistrictMaster(this.SaveDistictRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getDistrictList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("District_List") as HTMLTableElement;
    const worksheetName = "MASTER_DISTRICT_LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
