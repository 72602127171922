import { Component, OnInit, ViewChild, Input, EventEmitter,Output } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { DialogService } from '../../Shared/dialog.service';
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, Router } from "@angular/router";
import { LmsService } from "../services/lms.service";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
declare var $: any;

@Component({
  selector: 'app-lms-voucher-print-model',
  templateUrl: './lms-voucher-print-model.component.html',
  styleUrls: ['./lms-voucher-print-model.component.scss']
})
export class LmsVoucherPrintModelComponent implements OnInit,RouteReuseStrategy {
  @Input() Voucher_Id: any;
  @Input() index: any;
  @Input() DVEdit: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  currentUser: any;
  AccountDocUrl: any =  constantUrl.AccountDocUrl;
  displayedLoanColumns: string[] = ['CaseNo', 'Customer', 'Branch', 'Category', 'AccountHead', 'AmountDR', 'AmountCR'];
  displayedAccountingColumns: string[] = ['AccountHead', 'Branch', 'AmountDR', 'AmountCR']
  loading: boolean = false;
  ShowEdit: boolean = false;
  ShowDelete: boolean = false;
  minDate: any;
  docModal: any = [];
  FileCheckingList: any[] = [];
  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private encdec: EncrDecrService, private lmsService: LmsService, private dialog: DialogService,private _commonfunctions: CommonFunctions,
  ) { }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    throw new Error("Method not implemented.");
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    throw new Error("Method not implemented.");
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    throw new Error("Method not implemented.");
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    throw new Error("Method not implemented.");
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return true;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  BaseUrl: any = constantUrl.apiProfilePicUrl;
  CompanyLogo: any;

  CompanyProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  VoucherPrintModel: any = { Voucher: {}, Voucher_Detail: [] };

  ngOnInit() {
    console.log("index",this.index)
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.minDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    //console.log("Voucher_Id", this.Voucher_Id);
    this.CompanyLogo = this.currentUser.CompanyLogo;
    console.log("this.CompanyLogo", this.CompanyLogo);
    
  }

  onOpenViewPrintModal() {
    console.log("this.Voucher_Id", this.Voucher_Id);
    //this.CustomerViewDetailRequestModel.CustomerId = this.customerId;
    this.lmsService.LMS_Get_Voucher_Details({ VoucherId: this.Voucher_Id }).subscribe((res: any) => {
      console.log("res", res);
      this.minDate = new Date(JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split('T')[0] + ' 00:00:00');
      this.voucherModel.Voucher = res.Item1[0];
      this.voucherModel.Voucher_Detail = res.Item2;
      this.voucherModel.Voucher_Detail.forEach(x => {
        x.AmountDR = 0.00;
        x.AmountCR = 0.00;
        if (x.TranType == 'CR')
          x.AmountCR = x.Amount;
        if (x.TranType == 'DR')
          x.AmountDR = x.Amount;
      });
      this.FileCheckingList = JSON.parse(JSON.stringify(this.voucherModel.Voucher_Detail));
       console.log("File Checking List",this.FileCheckingList);
      this.docModal = res.Item3;
      this._MasterService.Get_Page_Rights_Of_EMP({ MMID: '69', LoginUserId: this.currentUser.userId }).subscribe((result: any) => {
        let AllPage = result;
        if (result.length > 0) {
          if (this.voucherModel.Voucher.Voucher_Type == 'Receipt' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != null) {
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt")[0].Edit == 1) {
              this.ShowEdit = true;
            } else {
              this.ShowEdit = false;
            }
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt")[0].Delete == 1) {
              this.ShowDelete = true;
            } else {
              this.ShowDelete = false;
            }
          }

          if (this.voucherModel.Voucher.Voucher_Type == 'Journal' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != null) {
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal")[0].Edit == 1) {
              this.ShowEdit = true;
            } else {
              this.ShowEdit = false;
            }
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal")[0].Delete == 1) {
              this.ShowDelete = true;
            } else {
              this.ShowDelete = false;
            }
          }

          if (this.voucherModel.Voucher.Voucher_Type == 'Payment' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != null) {
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment")[0].Edit == 1) {
              this.ShowEdit = true;
            } else {
              this.ShowEdit = false;
            }
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment")[0].Delete == 1) {
              this.ShowDelete = true;
            } else {
              this.ShowDelete = false;
            }
          }

          if (this.voucherModel.Voucher.Voucher_Type == 'Contra' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != null) {
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra")[0].Edit == 1) {
              this.ShowEdit = true;
            } else {
              this.ShowEdit = false;
            }
            if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra")[0].Delete == 1) {
              this.ShowDelete = true;
            } else {
              this.ShowDelete = false;
            }
          }
        }
      });
      if (this.voucherModel.Voucher.Voucher_No.substring(0, 2) == 'SL') {
        this.ShowEdit = false;
        this.ShowDelete = false;
      }
      //else {
      //  this.ShowEdit = false;
      //  this.ShowDelete = false;
      //}
      if (this.voucherModel.Voucher.Voucher_Type == 'Disbursement' && this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' && this.voucherModel.Voucher.Voucher_Status == 'Authorized' && this.DVEdit==true) {
        this.ShowEdit = true;
      }
      if (new Date(this.voucherModel.Voucher.Voucher_Date) < this.minDate) {
        this.ShowEdit = false;
        this.ShowDelete = false;
      }
      // this.voucherModel.Voucher_Detail.forEach(x => {
      //   x.AmountDR = 0.00;
      //   x.AmountCR = 0.00;
      //   if (x.TranType == 'CR')
      //     x.AmountCR = x.Amount;
      //   if (x.TranType == 'DR')
      //     x.AmountDR = x.Amount;
      // });
      //if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == '' || this.voucherModel.Voucher.Voucher_Mode_of_Payment == null)
      //  this.voucherModel.Voucher.Voucher_Mode_of_Payment = 'CASH';
      this.onVoucherTagChange();
      this.VoucherPrintModel = { Voucher: this.voucherModel.Voucher, Voucher_Detail: this.voucherModel.Voucher_Detail };
      $(`.VoucherPrint${this.index}`).modal("show");
      $(`.VoucherPrint${this.index}`).css(
        "z-index",
        "1050"
      );
    });
  }


  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = 'CASH';
    this.lmsService.Get_Acc_Accounts_By_Tag({ Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date) }).subscribe((res: any) => {
      //this.accountTagDropdown = res;
    })
  }

  OnClose() {
    $(`.VoucherPrint${this.index}`).modal("hide");
    //this.close.emit(true);
    $('#ChargesLoanDetailModel').modal('show');
    $("#ChargesLoanDetailModel").css("z-index", "1050");
    //this.customerData = {};
    //this.addressList = [];
    //this.CategoryList = [];
    //this.ApplicationList = [];
  }

  GetData(data) {
    if (data == true) {
      this.OnClose();
    }
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "Accounting Statement",
      sheet: "Accounting Statement",
    });
  }

  printdata() {

  }
  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  PrintDocs(){
    
    $(`#lmsPrintModelPrint${this.index}`).modal('show');
    $(`#lmsPrintModelPrint${this.index}`).css("z-index", "1050");
     
  }


  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }


  OnCloseVoucherModel(){
    $(`#lmsPrintModelPrint${this.index}`).modal("hide");
  }
}
