import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import * as moment from 'moment';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-asset-info-master',
  templateUrl: './asset-info-master.component.html',
  styleUrls: ['./asset-info-master.component.scss']
})
export class AssetInfoMasterComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  Manufacture: any = '';


  EmployeeId: any = 0;
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  // copy employee
  CopyEmployeeId: any = '';
  applicationId: any = 95;
  dataCustomerSource: any;
  Id: number;
  saveBtn: boolean = true;
  updateBtn: boolean = false;

  displayedCustomerColumns: string[] = ['Number', 'Manufacture', 'Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Asset Info master');
    // this._MasterService.GetRoleForDropdown().subscribe((result) => {
    //   this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    // });

    this.LMS_GetAssetInfoMaster();
  }

  LMS_GetAssetInfoMaster() {
    this.showSpinner = true;
    this._LmsService.Get_Lms_AssetInfo().subscribe((response) => {
      console.log('Get_Lms_AssetInfo', response);
      this.dataCustomerSource = new MatTableDataSource(response);
    });
  }

  onSaveManufacture() {
    let saveAreaDetail = {
      "Int_Id": 0,
      "IsActive": 1,
      "Manufacture": this.Manufacture,
    }
    this._LmsService.Save_Lms_AssetInfo(saveAreaDetail).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetAssetInfoMaster();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  removeAssetInfo(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete this Asset Info ${data.Manufacture}.`)
    .afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
      this._LmsService.Delete_Lms_AssetInfoMaster({ Int_Id: data.Int_Id }).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetAssetInfoMaster();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
      }
    });
  }

  editAssetInfo(data: any) {
    this.Manufacture = data.Manufacture.trim();
    this.Id = data.Int_Id;
    this.saveBtn = false;
    this.updateBtn = true;
  }


  UpdateAssetInfo() {
    this.saveBtn = true;
    this.updateBtn = false;
    console.log('this.areaId', this.Id);
    let updateAreaDetail = {
      "Int_Id": this.Id,
      "IsActive": 1,
      "Manufacture": this.Manufacture,
    }
    this._LmsService.Save_Lms_AssetInfo(updateAreaDetail).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.Id = 0;
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.LMS_GetAssetInfoMaster();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }



  applyFilter(filterValue: string) {
    this.dataCustomerSource.filter = filterValue.trim().toLowerCase();
  }

}
