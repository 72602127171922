import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
import { ActivatedRoute, Params } from "@angular/router";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTheme,
  ApexTitleSubtitle,
} from "ng-apexcharts";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.scss"],
})
export class ChartsComponent implements OnInit {
  showSpinner: boolean = false;
  data: any;
  @Input() chartdata: any;
  @Input() Types: any = "";
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  show: boolean = false;
  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService
  ) {
    console.log("hello");
  }

  ngOnInit() {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log("this.chartdata", this.chartdata);
    console.log("this.Types", this.Types);
    this.search();
  }
  search() {
    if (this.chartdata.length > 0 && this.Types == "Product Wise Business") {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Product_Name = this.chartdata.map(function (el) {
        return el.Product_Name;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Product_Name,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Branch Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Branch_Name = this.chartdata.map(function (el) {
        return el.Branch_Name;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Branch_Name,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Sales Executive Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Employee_Name = this.chartdata.map(function (el) {
        return el.Employee_Name;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Employee_Name,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Loan A/c Category Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Loan_Acc_Category = this.chartdata.map(function (el) {
        return el.Loan_Acc_Category;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Loan_Acc_Category,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Gender Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Gender = this.chartdata.map(function (el) {
        return el.Gender;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Gender,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Religion Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Religion = this.chartdata.map(function (el) {
        return el.Religion;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Religion,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Caste Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Cast = this.chartdata.map(function (el) {
        return el.Cast;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Cast,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Marital Status Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var MaritalStatus = this.chartdata.map(function (el) {
        return el.MaritalStatus;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: MaritalStatus,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Profile Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Customer_Profile = this.chartdata.map(function (el) {
        return el.Customer_Profile;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Customer_Profile,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Nature of Work Wise Business"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness);
      });
      var Natureofwork = this.chartdata.map(function (el) {
        return el.Natureofwork;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Natureofwork,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Product Wise Portfolio"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.POS_OS);
      });
      var Product_Name = this.chartdata.map(function (el) {
        return el.Product_Name;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Product_Name,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Branch Wise Portfolio"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.value);
      });
      var Product_Name = this.chartdata.map(function (el) {
        return el.title;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Product_Name,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else if (
      this.chartdata.length > 0 &&
      this.Types == "Loan A/c Category Wise Portfolio"
    ) {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.POS_OS);
      });
      var Loan_Acc_Category = this.chartdata.map(function (el) {
        return el.Loan_Acc_Category;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: Loan_Acc_Category,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    } else {
      this.show = true;
      var TotalBusiness = this.chartdata.map(function (el) {
        return Number(el.TotalBusiness == null ? el.POS_OS : el.TotalBusiness);
      });
      var SlabName = this.chartdata.map(function (el) {
        return el.SlabName;
      });

      this.chartOptions = {
        series: TotalBusiness,
        chart: {
          width: 530,
          type: "pie",
        },
        title: {
          text: this.Types,
        },
        labels: SlabName,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    }
  }
}
