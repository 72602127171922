import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import { DatePipe } from "@angular/common";
import { ToWords } from "to-words";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import * as moment from "moment";
declare var $: any;
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-lms-dv-detail",
  templateUrl: "./dv-detail.component.html",
  styleUrls: ["./dv-detail.component.scss"],
})
export class LmsAppDvDetailComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  LoanId: any;
  VoucherId: any;
  dvDetail: any;
  ACDetailList: any;
  Bind_Voucher: any = 1;
  tab: any = "deduction";
  deductionList: any[] = [];
  receiptList: any[] = [];
  dataSource: any;
  displayedColumns: string[] = [
    "Id",
    "Type",
    "ApplicationNo",
    "ChagesHead",
    "Account",
    "TaxPercentage",
    "Amount",
    "CollectionOn",
    "CollectionBy",
  ];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  //empBranchDropdown: any[] = [];
  totalCalculator: {
    totalCR: number;
    totalDR: number;
    totalCRTX: number;
    totalDRTX: number;
    totalCRInWord: string;
    totalDRInWord: string;
  };
  selectedSearchIndex: any;
  selectedData: any = {};
  dataSourcePop: any = new MatTableDataSource([]);
  displayedColumnsPop: string[] = [
    "LoanId",
    "LoanNo",
    "Product_Name",
    "Customer",
    "PhoneNo",
    "Branch_Name",
    "Action",
  ];
  OldData: any = { Voucher: [], Voucher_Detail: [] };
  Voucher_Detail_NEW_DEDUCTION: any = { TaxSlab: [] };
  totalCalculatorNewDeduction: any;
  displayedCustomerColumns: string[] = [];
  DVEdit: any = "false";
  Customer: any = {};
  CustomerList: any[] = [];
  dataSourceCustomer: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  chargesHeadDropdownData: any[] = [];

  dataSourceCustomerDetail: any;
  displayedCustomerDetailColumns: string[] = [];
  CustomerTrackList: any;
  CustomerLoanList: any;
  Tenure_Name: string;
  flat_ROI: any;

  constructor(
    private route: ActivatedRoute,
    private _Route: Router,
    public snackBar: MatSnackBar,
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private _MasterService: MasterService,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.VoucherId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("vid"))
      );
      this.DVEdit = param.get("Edit");
      this.LMS_Get_DV_Details();
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Disbursement Voucher Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    //this.Get_Acc_Accounts_OtherThan_BankCash();
    //this.Get_EmployeeBranchForDropdown();
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  LMS_Get_DV_Details() {
    this.showSpinner = true;
    var edit = false;
    if (this.DVEdit == "true") edit = true;
    this.voucherModel.Voucher_Detail = [];
    this.OldData.Voucher_Detail = [];
    this.lmsService
      .LMS_Get_DV_Details({
        LoanAcId: this.LoanId,
        VoucherId: this.VoucherId,
        IsEdit: edit,
      })
      .subscribe((res: any) => {
        if (res.Item1[0].CODE == -1) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res.Item1[0].Msg,
            ...this.configSuccess,
          });
          this._Route.navigate(["/lms/dvlist"]);
        } else {
          this.chargesHeadDropdownData = [];
          this.dvDetail = res.Item1[0];
          console.log("DV DEtails", this.dvDetail);

          if (
            this.dvDetail.EMI_Type == "Daily" ||
            this.dvDetail.EMI_Type == "Weekly" ||
            this.dvDetail.EMI_Type == "Fortnightly" ||
            this.dvDetail.EMI_Type == "ByWeekly" ||
            this.dvDetail.EMI_Type == "FourthWeekly"
          )
            this.Tenure_Name = "Days";
          else this.Tenure_Name = "Months";

          this.ACDetailList = res.Item2;
          this.deductionList = res.Item2;
          this.receiptList = res.Item3;
          this.dataSource = new MatTableDataSource(this.receiptList);
          this.showSpinner = false;
          this.initializeVoucher();
          this.deductionList.map((item) => {
            this.chargesHeadDropdownData.push({
              Id: item.ChagesHeadId,
              ChagesHead: item.ChagesHead,
            });
            if (!item.IsTax) {
              this.voucherModel.Voucher_Detail.push({
                ...item,
                isDisbled: true,
                IsLoanChargeable: true,
                isShowIcon: false,
                TaxSlab: [],
              });
              this.OldData.Voucher_Detail.push({
                ...item,
                isDisbled: true,
                IsLoanChargeable: true,
                isShowIcon: false,
                TaxSlab: [],
              });
            } else if (
              this.voucherModel.Voucher_Detail.length > 1 ||
              this.OldData.Voucher_Detail.length > 1
            ) {
              this.voucherModel.Voucher_Detail[
                this.voucherModel.Voucher_Detail.length - 1
              ].TaxSlab.push(item);
              this.OldData.Voucher_Detail[
                this.OldData.Voucher_Detail.length - 1
              ].TaxSlab.push(item);
            }
          });
        }
        this.voucherModel.Voucher_Detail.forEach((obj: any, index) => {
          if (
            Number(obj.Bind_Voucher == null ? 0 : Number(obj.Bind_Voucher)) >
            this.Bind_Voucher
          ) {
            this.Bind_Voucher = Number(obj.Bind_Voucher);
          }
          if (
            this.deductionList.filter(
              (result) =>
                result.Bind_Voucher === obj.Bind_Voucher &&
                result.IsTax === true
            ).length > 0
          ) {
            obj.GSTModel = {
              PartyType: "Existing_Party",
              Acc_GSTNo: obj.GST_No,
              Customer: obj.Customer,
              Account_Name: obj.Customer,
              Slab_Tax: this.deductionList.filter(
                (result) =>
                  result.Bind_Voucher === obj.Bind_Voucher &&
                  result.IsTax === true
              )[0].SlabName,
            };
          }
        });
        console.log(
          "this.voucherModel.Voucher_Detailsegrgerdf",
          this.voucherModel.Voucher_Detail
        );
        //this.voucherModel.Voucher.forEach(val => this.OldData.Voucher.push(Object.assign({}, val)));
        //this.voucherModel.Voucher_Detail.forEach(val => this.OldData.Voucher_Detail.push(Object.assign({}, val)));
        this.GetCustomerDetails();
        this.getTotalCalculation();
      });
  }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      Voucher_LoanId: this.dvDetail.LoanId,
      VoucherId: this.VoucherId,
      Voucher_Type: "Disbursement",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "",
      Voucher_Date: moment(this.dvDetail.VoucherDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      //Voucher_Date: moment(this.dvDetail.LoanDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Voucher_No: this.dvDetail.VoucherNo,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: this.dvDetail.Voucher_Narration,
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId,
    };

    this.OldData.Voucher = {
      Voucher_LoanId: this.dvDetail.LoanId,
      VoucherId: this.VoucherId,
      Voucher_Type: "Disbursement",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "",
      Voucher_Date: moment(this.dvDetail.VoucherDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      Voucher_No: this.dvDetail.VoucherNo,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: this.dvDetail.Voucher_Narration,
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId,
    };
    setTimeout(() => {
      this.GetVoucherNo();
    }, 500);
  }

  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.splice(
      this.voucherModel.Voucher_Detail.length,
      0,
      {
        Id: 0,
        LoanId: null,
        Type: "CR",
        SlabName: "",
        TaxType: "",
        ApplicationNo: "",
        BranchId: "",
        Branch: "",
        ChagesHeadId: "",
        ChagesHead: "",
        AccountId: "",
        Account: "",
        CustomerID: "",
        Customer: "",
        TaxPercentage: "",
        Amount: "",
        CollectionOn: "",
        CollectionBy: "",
        IsTax: false,
        TaxSlab: [],
        isShowIcon: true,
        isDisbled: false,
        IsLoanChargeable: false,
        GSTModel: {},
        Bind_Voucher: this.Bind_Voucher,
      }
    );
  }

  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.Voucher_Detail_NEW_DEDUCTION.ProductId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }

  //Get_EmployeeBranchForDropdown() {
  //  this._MasterService
  //    .Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId })
  //    .subscribe((res: any) => {
  //      this.empBranchDropdown = res;
  //    });
  //}

  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService
      .Get_Acc_Accounts_ForJVandContra({ IsJournal: 1 })
      .subscribe((res: any) => {
        this.accountHeadDropdown = res;
      });
  }

  GetVoucherNo() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: this.voucherModel.Voucher.Voucher_Type,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        VoucherDate: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        if (res) {
          this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
        }
      });
  }

  // onVoucherSubTypeChange() {
  //     this.GetVoucherNo();
  //     this.voucherModel.Voucher_Detail = [];
  //     this.initializeVoucherDetail();
  //     this.getTotalCalculation();
  // }

  onVoucherDateChange() {
    this.GetVoucherNo();
  }

  onChangeCRDR(data: any) {
    data.ApplicationNo = "";
    data.CustomerID = "";
    data.Is_LOSAppliaction = "0";
    data.ChagesHeadId = "";
    data.ChagesHead = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer = "";
    data.Branch = "";
    data.isDisbled = false;
    (data.isShowIcon = true), (data.TaxSlab = []);
    data.IsLoanChargeable = false;
    this.getTotalCalculation();
  }

  // searchCustomer(data: any,index:any) {
  //     this.selectedSearchIndex=index;
  //     this.selectedData = data;
  //     this.selectedData.Is_LOSAppliaction = '0';

  //     $("#dvDetailCustomerReceipt").modal("show");
  //     $("#dvDetailCustomerReceipt").css("z-index", "1050");
  //     setTimeout(()=>{
  //       $("#SearchValue").focus();
  //     },100)
  // }

  // onChangeChargesHead(data: any, dindex: any) {
  //     if (!data.ChagesHeadId) {
  //       data.AccountId = "";
  //       data.ChagesHead = "";
  //       data.TaxSlab = [];
  //       return;
  //     }
  //     let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
  //       return index != dindex && Number(obj.ChagesHeadId) == Number(data.ChagesHeadId)
  //     });
  //     if (_find) {
  //       this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
  //       data.ChagesHeadId = "";
  //       this.voucherModel.Voucher_Detail[dindex].ChagesHeadId = "";
  //       data.Amount = "";
  //       data.AccountId = "";
  //       data.ChagesHead = "";
  //       data.TaxSlab = [];
  //       if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
  //         data.BranchId = "";
  //       }
  //       this.getTotalCalculation();
  //       return;
  //     }
  //     data.Amount = "";
  //     data.AccountId = "";
  //     data.ChagesHead = "";
  //     data.TaxSlab = [];
  //     this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: data.ChagesHeadId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, ProductId: data.ProductId || 0, TaxType: data.Type }).subscribe((res: any) => {
  //       if (res.Item1[0]) {
  //         let _findA = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == res.Item1[0].AccountId });
  //         if (_findA) {
  //           data.AccountId = res.Item1[0].AccountId;
  //           data.ChagesHead = res.Item1[0].AccountName;
  //           let taxslabData = [];
  //           if(res.Item2 ? res.Item2.length > 0 : false){
  //             res.Item2.map((item)=>{
  //               taxslabData.push({
  //                 "SlabName": item.SlabName,
  //                 "TaxType": item.TaxType,
  //                 "TaxPercentage": item.TaxRate,
  //                 "Account": item.TaxAccountName,
  //                 "AccountId": item.TaxAccountId,
  //                 "isSub": true
  //               });
  //             })
  //           }
  //           data.TaxSlab = taxslabData;
  //         }
  //         else {
  //           this.snackBar.openFromComponent(SnackbarComponent, { data: "Account head not found.", ...this.configSuccess });
  //         }
  //       }
  //       else {
  //         this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
  //       }
  //     });
  // }

  onChangeAccountHead(data: any, index: any) {
    if (!data.AccountId) {
      data.Amount = "";
      delete data.IsLoanChargeable;
      this.getTotalCalculation();
      return;
    }
    let _find = this.accountHeadDropdown.find((obj: any) => {
      return obj.AccountId == Number(data.AccountId);
    });
    data.IsLoanChargeable = _find.IsLoanChargeable;
    if (_find.IsLoanChargeable) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please search Application and choose category for this account head.",
        ...this.configSuccess,
      });
    }
    data.ChagesHeadId = "";
    data.Amount = "";
    data.ApplicationNo = "";
    data.Customer = "";
    data.CustomerID = "";
    data.ProductId = "";
    data.BranchId = "";
    data.Branch = "";
    this.getTotalCalculation();
  }

  // onChangeAmount(event: any, data: any) {
  //     if (event) {
  //       event.preventDefault();
  //     }
  //     data.TaxSlab.forEach((obj: any) => {
  //       obj.Amount = (parseInt(data.Amount) * obj.TaxPercentage) / 100;
  //     });
  //     this.getTotalCalculation();
  // }

  removeData(index: any, data: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService
            .Delete_ReceiptDeduction({ Id: data.Id })
            .subscribe((res: any) => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.LMS_Get_DV_Details();
            });
        }
      });
    // this.voucherModel.Voucher_Detail.splice(index, 1);
    // this.getTotalCalculation();
  }

  // addMoreData() {
  //     this.initializeVoucherDetail();
  // }

  saveVoucher() {
    let _vouvher: any = { ...this.voucherModel.Voucher };

    let _voucheretail: any[] = [];
    // let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //let _total: any = 0;
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalTXCR: any = 0;
    let _totalTXDR: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = parseFloat(_totalCR) + parseFloat(obj.Amount);
      } else {
        _totalDR = parseFloat(_totalDR) + parseFloat(obj.Amount);
      }

      _voucheretail.push(
        this.dataSharingService.cleanObject({
          VoucherId: obj.Id,
          CaseNo: obj.ApplicationNo,
          CustomerId: obj.CustomerID,
          Is_LOSAppliaction: 0,
          ChargeHeadId: obj.ChagesHeadId,
          ChargeHeadCategory: obj.ChagesHead,
          AccountId: obj.AccountId,
          BranchId: obj.BranchId,
          TranType: obj.Type,
          IsTax: obj.IsTax ? 1 : 0,
          Tax_Per: obj.TaxPercentage,
          Amount: obj.Amount,
          GST_Type: obj.TaxSlab.length > 0 ? obj.GSTModel.PartyType : "",
          GST_No: obj.TaxSlab.length > 0 ? obj.GSTModel.Acc_GSTNo : "",
          Party_AccountName:
            obj.TaxSlab.length > 0 ? obj.GSTModel.Customer : "",
          HSN_Code: obj.TaxSlab.length > 0 ? obj.GSTModel.HSNCode : "",
          Slab_Name: obj.TaxSlab.length > 0 ? obj.GSTModel.Slab_Tax : "",
          Bind_Voucher: obj.Bind_Voucher,
          Type: obj.TaxSlab.length > 0 ? "Output" : "",
        })
      );

      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalTXCR = parseFloat(_totalTXCR) + parseFloat(tobj.Amount);
          _totalCR = parseFloat(_totalCR) + parseFloat(tobj.Amount);
        } else {
          _totalTXDR = parseFloat(_totalTXDR) + parseFloat(tobj.Amount);
          _totalDR = parseFloat(_totalDR) + parseFloat(tobj.Amount);
        }
        _voucheretail.push(
          this.dataSharingService.cleanObject({
            VoucherId: obj.Id,
            CaseNo: obj.ApplicationNo,
            CustomerId: "",
            Is_LOSAppliaction: 0,
            ChargeHeadId: obj.ChagesHeadId,
            ChargeHeadCategory: tobj.TaxType,
            AccountId: tobj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.Type,
            IsTax: 1,
            Tax_Per: tobj.TaxPercentage,
            Amount: tobj.Amount,
            Bind_Voucher: obj.Bind_Voucher,
            Type: "",
          })
        );
      });
    });

    _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    _vouvher.Voucher_Total_Debit_Amount = _totalDR;
    _vouvher.Voucher_Total_Tax = _totalTXCR + _totalTXDR;
    if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Total Debit Amount must be equal to Total Credit Amount.",
        ...this.configSuccess,
      });
      return;
    }

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: _voucheretail,
    };
    var FinalOldData = {
      Voucher: this.dataSharingService.cleanObject({ ...this.OldData.Voucher }),
      Voucher_Detail: this.OldData.Voucher_Detail,
    };
    if (this.DVEdit == "true") {
      this.lmsService
        .Acc_Voucher_Audit_Trail_Update({
          JSON: JSON.stringify(FinalOldData),
          Type: "Add",
        })
        .subscribe((res: any) => {});
    }
    this.lmsService
      .Save_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res && res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.voucherModel.Voucher_Detail = [];
          this.totalCalculator = undefined;
          this._Route.navigate(["/lms/dvlist"]);
        } else if (res) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = parseFloat(_totalCR) + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = parseFloat(_totalDR) + parseFloat(obj.Amount) || 0;
      }

      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalCR = parseFloat(_totalCR) + parseFloat(tobj.Amount) || 0;
          _totalCRTX = parseFloat(_totalCRTX) + parseFloat(tobj.Amount) || 0;
        } else {
          _totalDR = parseFloat(_totalDR) + parseFloat(tobj.Amount) || 0;
          _totalDRTX = parseFloat(_totalDRTX) + parseFloat(tobj.Amount) || 0;
        }
      });
    });

    this.totalCalculator = {
      totalCR: Number(_totalCR),
      totalDR: Number(_totalDR),
      totalCRTX: Number(_totalCRTX),
      totalDRTX: Number(_totalDRTX),
      totalCRInWord: new ToWords().convert(Number(_totalCR)),
      totalDRInWord: new ToWords().convert(Number(_totalDR)),
    };
  }

  // OnCloseSearch() {
  //     $("#dvDetailCustomerReceipt").modal("hide");
  //     $("#Customer"+this.selectedSearchIndex).focus();
  //     this.selectedData.SearchValue = "";
  // }

  // Get_Acc_Search_LoanNo() {
  //     this.lmsService.Get_Acc_Search_LoanNo({ SearchOn: this.selectedData.Is_LOSAppliaction == 1 ? 'ApplicationNo' : 'LoanNo', SearchValue: this.selectedData.SearchValue }).subscribe((res: any) => {
  //       if (res.length > 0) {
  //         if(res.length==1){
  //           this.selectSearchData(res[0])
  //         }
  //         this.dataSourcePop = new MatTableDataSource(res);
  //       }
  //       else {
  //         this.snackBar.openFromComponent(SnackbarComponent, { data: "Search not found.", ...this.configSuccess });
  //       }
  //     });
  // }

  // selectSearchData(data: any) {
  //     this.selectedData.ApplicationNo = data.LoanNo;
  //     this.selectedData.Customer = data.Customer;
  //     this.selectedData.CustomerId = data.CustomerID;
  //     this.selectedData.ProductId = data.ProductId;
  //     this.selectedData.BranchId = data.BranchId;
  //     this.selectedData.Branch = data.Branch_Name;
  //     this.selectedData.ChagesHeadId = "";
  //     this.selectedData.Amount = "";
  //     this.selectedData.TaxSlab = [];
  //     this.OnCloseSearch();
  // }

  closeDisDetail() {
    this._Route.navigate(["/lms/dvlist"]);
  }

  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherNewDetail();
    $("#dvDetailNewDeduction").modal("show");
    $("#dvDetailNewDeduction").css("z-index", "1050");
  }

  initializeVoucherNewDetail() {
    //console.log("POP : ", this.ACDetailList);
    let data, PrdId;
    this.Customer.LoanNo = this.dvDetail.LoanNo;
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        data = res.filter((x) => x.LoanId == this.dvDetail.LoanId);
        PrdId = data[0].ProductId;

        this.Voucher_Detail_NEW_DEDUCTION = {
          LoanId: this.dvDetail.LoanId,
          LoginUserId: this.currentUser.userId,
          DeductionLoanId: this.dvDetail.LoanId,
          CaseNo: this.dvDetail.LoanNo,
          CustomerId: this.ACDetailList[0].CustomerID,
          Is_LOSAppliaction: "0",
          ChargeHeadId: "",
          ChargeHeadCategory: "",
          AccountId: "",
          BranchId: this.ACDetailList[0].BranchId,
          TranType: "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: "",
          Customer: this.dvDetail.Customer,
          Branch_Name: this.dvDetail.Branch,
          ProductId: PrdId,
          TaxSlab: [],
          GSTModel: {},
          Bind_Voucher: this.Bind_Voucher,
          Type: "",
        };
        this.getChargeshead();
      });
  }

  OnCloseSearchNew() {
    $("#dvDetailNewDeduction").modal("hide");
  }

  onSaveNewRdDetail() {
    //console.log("Voucher123 : ", this.Voucher_Detail_NEW_DEDUCTION);
    this.showSpinner = true;
    this.lmsService
      .SaveReceiptDeduction({
        JSON: JSON.stringify({ Loaninfo: this.Voucher_Detail_NEW_DEDUCTION }),
      })
      .subscribe((res: any) => {
        //console.log("RES : ", res);
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnCloseSearchNew();
          this.LMS_Get_DV_Details();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  searchCustomer() {
    this.dataSourcePop = new MatTableDataSource([]);
    $("#dvDetailCustomerReceipt").modal("show");
    $("#dvDetailCustomerReceipt").css("z-index", "1150");
    this.Voucher_Detail_NEW_DEDUCTION.SearchValue = "";
    this.Voucher_Detail_NEW_DEDUCTION.Is_LOSAppliaction = "0";
    this.Customer = {};
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100);
  }
  Get_Acc_Search_LoanNo() {
    this.lmsService
      .Get_Acc_Search_LoanNo({
        SearchOn: "LoanNo",
        SearchValue: this.Voucher_Detail_NEW_DEDUCTION.SearchValue,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (res.length == 1) {
            this.selectSearchData(res[0]);
          }
          this.dataSourcePop = new MatTableDataSource(res);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Search not found.",
            ...this.configSuccess,
          });
        }
      });
  }
  selectSearchData(data: any) {
    this.Voucher_Detail_NEW_DEDUCTION.DeductionLoanId = data.LoanId;
    this.Voucher_Detail_NEW_DEDUCTION.CaseNo = data.LoanAcNo;
    this.Voucher_Detail_NEW_DEDUCTION.Customer = data.CustomertName;
    this.Voucher_Detail_NEW_DEDUCTION.CustomerId = data.CustomerId;
    this.Voucher_Detail_NEW_DEDUCTION.ProductId = data.ProductId;
    this.Voucher_Detail_NEW_DEDUCTION.BranchId = data.BranchId;
    this.Voucher_Detail_NEW_DEDUCTION.Branch_Name = data.Branch_Name;
    this.Voucher_Detail_NEW_DEDUCTION.ChargeHeadId = "";
    this.Voucher_Detail_NEW_DEDUCTION.Amount = "";
    this.Voucher_Detail_NEW_DEDUCTION.TaxSlab = [];
    this.getChargeshead();
    this.OnCloseSearch();
  }
  OnCloseSearch() {
    $("#dvDetailCustomerReceipt").modal("hide");
    this.Voucher_Detail_NEW_DEDUCTION.SearchValue = "";
  }
  onChangeChargesHead(data: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: "Loan",
        ProductId: data.ProductId || 0,
        TaxType: data.TranType,
        LoanNo: data.CaseNo,
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;
            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
          } else {
            data.ChargeHeadId = "";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "category does not have account head.",
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
      });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(
        2
      );
    });
    this.getTotalCalculationNew();
  }

  getTotalCalculationNew() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    _total =
      parseFloat(_total) +
        parseFloat(this.Voucher_Detail_NEW_DEDUCTION.Amount) || 0;
    _totalCR =
      parseFloat(_totalCR) +
        parseFloat(this.Voucher_Detail_NEW_DEDUCTION.Amount0) || 0;
    this.Voucher_Detail_NEW_DEDUCTION.TaxSlab.forEach((tobj: any) => {
      _total = parseFloat(_total) + parseFloat(tobj.TaxAmount) || 0;
      _totalTX = parseFloat(_totalTX) + parseFloat(tobj.TaxAmount) || 0;
    });
    this.totalCalculatorNewDeduction = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }
  onSearchCustomerDetail() {
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedCustomerColumns = [
            "CustomerId",
            "Type",
            "Application_No",
            "LoanAcNo",
            "CustomertName",
            "FatherName",
            "Customer_Gender",
            "GenderAge",
            "PhoneNo",
            "EditAction",
          ];

          this.CustomerList = JSON.parse(JSON.stringify(res));
          this.CustomerList = this.CustomerList.filter(
            (item) => item.Type == "LMS"
          );
          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.showSpinner = false;
        }
      });
  }

  GetCustomerDetails() {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.dvDetail.LoanId })
      .subscribe((res: any) => {
        console.log(res);

        this.flat_ROI = res?.Item1[0]?.Flat_Rate;
        console.log("this.ROI====", this.flat_ROI);

        this.displayedCustomerDetailColumns = [
          "CustomerId",
          "Customer",
          "CustomerType",
          "GenderAge",
          "PhoneNo",
          "Relation_With_Hirer",
          "ExistingCustomer",
        ];
        this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
      });
  }

  OnClickTrackLoan() {
    this.lmsService
      .Customer_Track({ Loan_Id: this.dvDetail.LoanId })
      .subscribe((res: any) => {
        console.log("res", res);
        this.CustomerLoanList = res;
        this.CustomerTrackList = res.filter(
          (item) => item.LoanId == this.dvDetail.LoanId
        );
        $("#LoanTrack").modal("show");
        $("#LoanTrack").css("z-index", "1050");
      });
  }

  CloseTrackLoan() {
    $("#LoanTrack").modal("hide");
  }
}
