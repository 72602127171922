<div class="card-container" style="padding: 0; height: 100%">
  <div
    class="title-head"
    style="
      border-bottom: 1px solid black;
      background-color: #4dc3a3;
      border-radius: 9px 9px 0 0;
    "
  >
    <h1 class="main-tit" style="line-height: 2.2; margin: 0">
        Customer KYC Document
    </h1>
  </div>
  <div style="min-height: 100%" class="text-center" *ngIf="!customerkycData">
    No Information Available!!
  </div>
  <div class="cnt-data" *ngIf="customerkycData" style="margin: 10px">
    <div class="family">
      <!-- <div class="name-sub" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
          <h1 style="margin-bottom: 2px;">Income Details</h1>
      </div> -->
      <div class="cont">
        <div *ngFor="let item of customerkycData;index as i">
          <div class="cont-Card">
            <div class="grd">
              <div class="grd-item">{{ item.Doc_Name }}</div>
              <div class="grd-item">{{ item.KYC_DocNumber }}
                <i class="fa fa-check-square ml-2"  *ngIf="item.KYC_IsVerified==1" (click)="ViewKYC_IsVerified(item,i)"  style="color: green;font-size: 15px;cursor:pointer"></i>
              
              </div>
              <div class="grd-item">
                <a
                  href="{{ customerProfileBaseUrl }}{{ item.KYC_CustomerId }}/{{
                    item.KYC_DocFile
                  }}"
                  class="ml-3"
                  target="_blank"
                  *ngIf="item.KYC_DocFile"
                >
                  <i
                    class="fa fa-eye"
                    style="
                      font-size: medium;
                      cursor: pointer;
                      text-align: center;
                    "
                  ></i>
                </a>
              </div>
              <div class="grd-item">
                <a
                  href="{{ customerProfileBaseUrl }}{{ item.KYC_CustomerId }}/{{
                    item.KYC_DocFile1
                  }}"
                  class="ml-3"
                  target="_blank"
                  *ngIf="item.KYC_DocFile1"
                >
                  <i
                    class="fa fa-eye"
                    style="
                      font-size: medium;
                      cursor: pointer;
                      text-align: center;
                    "
                  ></i>
                </a>
              </div>
              <!-- <div class="grd-item"  *ngIf="item.KYC_IsVerified==1">KYC Verified
                <a href="javascript:void(0)"   (click)="ViewKYC_IsVerified(item)">
                  <i class="fa fa-eye" style="font-size: large"></i>
                </a>
                <i class="fa fa-check ml-2"  (click)="ViewKYC_IsVerified(item)"  style="color: green"></i>
              </div>
              -->
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="modalViewVerifiedData{{ index }}"
  class="modal fade in"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  style="font-size: small"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow-y: auto"
>
  <div class="modal-dialog modal-dialog-centered" style="width: 400px">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle>
        <div class="modal-content">
          <div class="modal-header" style="padding: 6px">
            <h5 class="modal-title" style="font-size: small">
              Customer Information
            </h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body" style="padding: 13px">
            <div class="card">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
                alt="Customer Image"
                class="mt-2"
                style="
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  margin: auto;
                "
              />
              <div
                class="card-body"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 1"
              >
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Relation
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.care_of | slice : 0 : 4 }}
                  {{ APIResponse.care_of | slice : 4 }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  DOB:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.dob }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Gender:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.gender }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Address:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.house }},{{ APIResponse.street }},
                  {{ APIResponse.po }},{{ APIResponse.loc }},{{
                    APIResponse.vtc
                  }},{{ APIResponse.subdist }},
                  {{ APIResponse.landmark }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Pin Code:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.zip }}
                </p>
                <br />
                <h6
                  class="card-title"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  Verify PAN No
                </h6>
                <p
                  class="card-text"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  {{ Verify_PAN_No }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 4">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Relation:</h6>
                <p class="card-text">
                  {{ APIResponse.relation_type + " " }}
                  {{ APIResponse.relation_name }}
                </p>
                <br />

                <h6 class="card-title">age:</h6>
                <p class="card-text">{{ APIResponse.age }}</p>
                <br />
                <h6 class="card-title">Gender:</h6>
                <p class="card-text">
                  {{ APIResponse.gender == "F" ? "Female" : "Male" }}
                </p>
                <br />
                <h6 class="card-title">Area:</h6>
                <p class="card-text">
                  {{
                    APIResponse.area +
                      " " +
                      APIResponse.dist +
                      " " +
                      APIResponse.state
                  }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 29">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />

                <h6 class="card-title">PAN Number:</h6>
                <p class="card-text">{{ APIResponse.pan_number }}</p>
                <br />

                <h6 class="card-title">Orgnization Name:</h6>
                <p class="card-text">{{ APIResponse.business_name }}</p>
                <br />

                <h6 class="card-title">Signing Autority:</h6>
                <p class="card-text">{{ APIResponse.SigningAutority }}</p>
                <br />

                <h6 class="card-title">Firm Type:</h6>
                <p class="card-text">{{ APIResponse.FirmType }}</p>
                <br />

                <h6 class="card-title">Gst Registration Date:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">{{ APIResponse.gst_status }}</p>
                <br />

                <h6 class="card-title">Nature Bus Activities:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />

                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 28">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
                <h6 class="card-title">Company Name</h6>
                <p class="card-text">{{ APIResponse.company_name }}</p>
                <br />
                <h6 class="card-title">Registration Number</h6>
                <p class="card-text">{{ APIResponse.registration_number }}</p>
                <br />
                <h6 class="card-title">Company Category</h6>
                <p class="card-text">{{ APIResponse.company_category }}</p>
                <br />
                <h6 class="card-title">Company Sub Category</h6>
                <p class="card-text">{{ APIResponse.company_sub_category }}</p>
                <br />
                <h6 class="card-title">Class Of Company</h6>
                <p class="card-text">{{ APIResponse.class_of_company }}</p>
                <br />
                <h6 class="card-title">Company Status</h6>
                <p class="card-text">{{ APIResponse.company_status }}</p>
                <br />
                <h6 class="card-title">Authorized Capital</h6>
                <p class="card-text">{{ APIResponse.authorized_capital }}</p>
                <br />
                <h6 class="card-title">Paid Up Capital</h6>
                <p class="card-text">{{ APIResponse.paid_up_capital }}</p>
                <br />
                <h6 class="card-title">Date Of Incorporation</h6>
                <p class="card-text">{{ APIResponse.date_of_incorporation }}</p>
                <br />
                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <h1 class="fs-12 m-0">Directors Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Directors"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="din_number">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Din Number</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.din_number
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="director_name">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Director Name</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.director_name
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Start Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.start_date
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >End Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.end_date
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="surrendered_din">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Surrendered Din
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.surrendered_din
                          }}</mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedDirectorsColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedDirectorsColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                  style="margin-top: 25px !important; font-weight: bold"
                >
                  <h1 class="fs-12 m-0">Charges Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Charges"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="assets_under_charge">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Assets Under Charge
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.assets_under_charge
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="charge_amount">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Charge Amount</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.charge_amount
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_creation">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Creation
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.date_of_creation
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_modification">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Modification
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.date_of_modification
                          }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Status</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">{{
                            row.status
                          }}</mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedChargesColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedChargesColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 5">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <b class="card-title">permanent address:</b>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
                <h6 class="card-title">DOB:</h6>
                <p class="card-text">{{ APIResponse.dob }}</p>
                <br />
                <h6 class="card-title">Issue Date:</h6>
                <p class="card-text">{{ APIResponse.doi }}</p>
                <br />
                <h6 class="card-title">Expiry Date:</h6>
                <p class="card-text">{{ APIResponse.doe }}</p>
                <br />
                <h6 class="card-title">Issued By:</h6>
                <p class="card-text">{{ APIResponse.ola_name }}</p>
                <br />

                <h6 class="card-title">vehicle classes:</h6>
                <p class="card-text">{{ APIResponse.vehicle_classes }}</p>
                <br />
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 2">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Address:</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <h6 class="card-title">Mobile:</h6>
                <p class="card-text">{{ APIResponse.mobile }}</p>
                <br />
                <h6 class="card-title">State:</h6>
                <p class="card-text">{{ APIResponse.state }}</p>
                <br />
                <h6 class="card-title">Operator Code:</h6>
                <p class="card-text">{{ APIResponse.operator_code }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row m-0 col-md-12">
              <div class="col-md-4">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified By
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedBy }}
                </p>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified On
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedDate }}
                </p>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="mt-3 btn font-size-12 button-btn"
                  data-dismiss="modal"
                  style="font-size: smaller"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
