<div class="row mt-12">
<div class="col-md-12 p-1">
  <span>Geo Code </span>
  <label class="mx-3 ml-4"> lat: {{lat}}, lng: {{lng}} </label>

  <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (mapClick)="mapClicked($event)">
    <agm-marker [latitude]="lat" [longitude]="lng" [animation]="animation" (mouseOver)="markerOver()"
                (mouseOut)="markerOut()"></agm-marker>
  </agm-map>
</div>
  </div>
