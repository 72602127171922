<i
  class="fa fa-calculator"
  aria-hidden="true"
  (click)="openModal()"
  style="color: #1da69d; padding: 0; font-size: 3em"
></i>

<div
  class="modal fade in"
  id="EMICalculator"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    role="document"
    style="max-width: 900px !important"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          EMI Calculator
        </h6>
        <button
          type="button"
          (click)="onCloseEMICalculator()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-1">
          <ng-container>
            <form
              #eif="ngForm"
              class="row m-0"
              (ngSubmit)="onCalculateEmiAndIRR()"
              novalidate
              class="formborder"
              style="text-align: left !important; width: 100%"
            >
              <div class="row m-0 col-md-12 p-1">
                <div class="col-md-3 p-1">
                  <span>Asset Cost (Rs.) </span>
                  <input
                    type="text"
                    numbersOnly
                    #refAsset_Cost="ngModel"
                    [(ngModel)]="cEIModel.Asset_Cost"
                    placeholder="Asset Cost"
                    name="Asset_Cost"
                    id="Asset_Cost"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refAsset_Cost.invalid,
                      'alert-warning':
                        refAsset_Cost.invalid &&
                        (refAsset_Cost.dirty ||
                          refAsset_Cost.touched ||
                          refAsset_Cost.untouched)
                    }"
                  />
                </div>
                <div class="col-md-3 p-1">
                  <span> Net Finance (Rs.) </span>
                  <input
                    type="text"
                    numbersOnly
                    required
                    #refNetFinance_Amt="ngModel"
                    [(ngModel)]="cEIModel.NetFinance_Amt"
                    placeholder="Net Finance"
                    name="NetFinance_Amt"
                    id="NetFinance_Amt"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                      'alert-warning':
                        refNetFinance_Amt.invalid &&
                        (refNetFinance_Amt.dirty ||
                          refNetFinance_Amt.touched ||
                          refNetFinance_Amt.untouched)
                    }"
                  />
                </div>
                <div class="col-md-2 p-1">
                  <span class="required-lable"> Type</span>
                  <select
                    name="Type"
                    id="Type"
                    #refType="ngModel"
                    [(ngModel)]="cEIModel.IRR_CalculateBy"
                    class="form-control input-text-css"
                    (change)="setType($event)"
                    required
                    [ngClass]="{
                      'is-invalid': eif.submitted && refType.invalid,
                      'alert-warning':
                        refType.invalid &&
                        (refType.dirty || refType.touched || refType.untouched)
                    }"
                  >
                    <option value="ROI">Flat ROI</option>
                    <option value="FLAT_EMI">EMI Amt (₹)</option>
                    <option value="STEP_EMI"  >  Step EMI </option>
                    <option value="Reducing_ROI" >Reducing ROI</option>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span
                    class="required-lable"
                    *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'"
                  >
                    {{ cEIModel.IRR_CalculateBy }}</span
                  >
                  <input
                    type="text"
                    appTwoDigitDecimaNumber
                    required
                    #refReducing_ROI="ngModel"
                    [(ngModel)]="cEIModel.Reducing_ROI"
                    placeholder="Reducing_ROI"
                    *ngIf="cEIModel.IRR_CalculateBy == 'Reducing_ROI'"
                    name="Reducing_ROI"
                    id="Reducing_ROI"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refReducing_ROI.invalid,
                      'alert-warning':
                        refReducing_ROI.invalid &&
                        (refReducing_ROI.dirty ||
                          refReducing_ROI.touched ||
                          refReducing_ROI.untouched)
                    }"
                  />
                  <input
                    type="text"
                    placeholder="Flat Rate"
                    name="Flat_Rate"
                    id="Flat_Rate"
                    class="form-control input-text-css"
                    [hidden]="true"
                    *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
                  />
                  <input
                    type="text"
                    appTwoDigitDecimaNumber
                    required
                    #refFlat_Rate="ngModel"
                    [(ngModel)]="cEIModel.Flat_Rate"
                    placeholder="Flat Rate"
                    *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
                    name="Flat_Rate"
                    id="Flat_Rate"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refFlat_Rate.invalid,
                      'alert-warning':
                        refFlat_Rate.invalid &&
                        (refFlat_Rate.dirty ||
                          refFlat_Rate.touched ||
                          refFlat_Rate.untouched)
                    }"
                  />

                  <input
                    type="text"
                    placeholder="EMI Amount"
                    name="EMI_Amount"
                    id="EMI_Amount"
                    class="form-control input-text-css"
                    [hidden]="true"
                    *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
                  />
                  <input
                    type="text"
                    numbersOnly="true"
                    required
                    #refEMI_Amount="ngModel"
                    [(ngModel)]="cEIModel.EMI_Amount"
                    placeholder="EMI Amount"
                    *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
                    name="EMI_Amount"
                    id="EMI_Amount"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refEMI_Amount.invalid,
                      'alert-warning':
                        refEMI_Amount.invalid &&
                        (refEMI_Amount.dirty ||
                          refEMI_Amount.touched ||
                          refEMI_Amount.untouched)
                    }"
                  />
                </div>
              </div>
              <div class="row m-0 p-0 col-md-12">
                <div class="col-md-3 p-1 mb-3">
                  <span> EMI Frequency</span>
                  <select
                    name="EMI_Type"
                    id="EMI_Type"
                    #refEMI_Type="ngModel"
                    [(ngModel)]="cEIModel.EMI_Type"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refEMI_Type.invalid,
                      'alert-warning':
                        refEMI_Type.invalid &&
                        (refEMI_Type.dirty ||
                          refEMI_Type.touched ||
                          refEMI_Type.untouched)
                    }"
                    class="form-control input-text-css"
                    (change)="setTenure()"
                    required
                  >
                    <!-- <option value=""> EMI Frequancy </option> -->
                    <option
                      value="Daily"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Daily
                    </option>
                    <option
                      value="Weekly"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Weekly
                    </option>

                    <option
                      value="ByWeekly"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Two-Weekly
                    </option>

                    <option
                      value="FourthWeekly"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Four-Weekly
                    </option>

                    <option
                      value="Fortnightly"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Fortnightly
                    </option>
                    <option value="Monthly">Monthly</option>
                    <option
                      value="ByMonthly"
                      [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                    >
                      Bi-Monthly
                    </option>
                    <option
                      value="Quarterly"
                      [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                    >
                      Quarterly
                    </option>
                    <option
                      value="HalfYearly"
                      [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'"
                    >
                      Half Yearly
                    </option>
                    <option
                      value="Yearly"
                      [hidden]="
                        cEIModel.IRR_CalculateBy == 'STEP_EMI' 
                      "
                    >
                      Yearly
                    </option>
                  </select>
                </div>
                <div class="col-md-3 p-1 mb-3">
                  <span> No Of Installments </span>
                  <input
                    type="text"
                    numbersOnly
                    required
                    #refNo_Of_Inst="ngModel"
                    [(ngModel)]="cEIModel.No_Of_Inst"
                    placeholder="No Of Installments"
                    (blur)="setTenure()"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                      'alert-warning':
                        refNo_Of_Inst.invalid &&
                        (refNo_Of_Inst.dirty ||
                          refNo_Of_Inst.touched ||
                          refNo_Of_Inst.untouched)
                    }"
                    name="No_Of_Inst"
                    id="No_Of_Inst"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2 p-1 mb-3">
                  <span> Tenure ({{ Name }}) </span>
                  <input
                    type="text"
                    numbersOnly
                    #refTenure="ngModel"
                    [(ngModel)]="cEIModel.Tenure"
                    placeholder="Tenure"
                    disabled
                    [ngClass]="{
                      'is-invalid': eif.submitted && refTenure.invalid,
                      'alert-warning':
                        refTenure.invalid &&
                        (refTenure.dirty ||
                          refTenure.touched ||
                          refTenure.untouched)
                    }"
                    name="Tenure"
                    id="Tenure"
                    class="form-control input-text-css"
                  />
                </div>
                <div
                  class="col-md-3 p-1 mb-3"
                  *ngIf="cEIModel.EMI_Type == 'Monthly'"
                >
                  <span> Instl In Adv. </span>
                  <input
                    type="text"
                    numbersOnly
                    required
                    #refAdv_Inst="ngModel"
                    [(ngModel)]="cEIModel.Adv_Inst"
                    placeholder="Instl In Adv."
                    (blur)="setAdvanceInstl()"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refAdv_Inst.invalid,
                      'alert-warning':
                        refAdv_Inst.invalid &&
                        (refAdv_Inst.dirty ||
                          refAdv_Inst.touched ||
                          refAdv_Inst.untouched)
                    }"
                    name="Adv_Inst"
                    id="Adv_Inst"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span>Loan Date</span>

                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [(ngModel)]="cEIModel.Loan_Date"
                      required
                      #refLoan_Date="ngModel"
                      name="Loan_Date"
                      id="Loan_Date"
                      (dateChange)="setTenure()"
                      [ngClass]="{
                        'is-invalid': eif.submitted && refLoan_Date.invalid,
                        'alert-warning':
                          refLoan_Date.invalid &&
                          (refLoan_Date.dirty ||
                            refLoan_Date.touched ||
                            refLoan_Date.untouched)
                      }"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                  <!-- <input
                    type="text"
                    [(ngModel)]="cEIModel.Loan_Date"
                    required
                    #refLoan_Date="ngModel"
                    name="Loan_Date"
                    id="Loan_Date"
                    value="{{ cEIModel.Loan_Date | date : 'dd/MM/yyyy' }}"
                    placeholder="DD/MM/YYYY"
                    [(bsValue)]="cEIModel.Loan_Date"
                    class="form-control input-text-css"
                    bsDatepicker
                    (ngModelChange)="setTenure()"
                    [ngClass]="{
                      'is-invalid': eif.submitted && refLoan_Date.invalid,
                      'alert-warning':
                        refLoan_Date.invalid &&
                        (refLoan_Date.dirty ||
                          refLoan_Date.touched ||
                          refLoan_Date.untouched)
                    }"
                  /> -->
                </div>
                <div class="col-md-3 mb-3">
                  <span> Due Date </span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker2"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      #refFirst_EMI_Date="ngModel"
                      name="DueDate"
                      id="DueDate"
                      class="form-control input-text-css"
                      (dateChange)="setTenure()"
                      [(ngModel)]="cEIModel.DueDate"
                      [ngClass]="{
                        'is-invalid':
                          eif.submitted && refFirst_EMI_Date.invalid,
                        'alert-warning':
                          refFirst_EMI_Date.invalid &&
                          (refFirst_EMI_Date.dirty ||
                            refFirst_EMI_Date.touched ||
                            refFirst_EMI_Date.untouched)
                      }"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <!-- <input
                    type="text"
                    [(ngModel)]="cEIModel.DueDate"
                    required
                    #refFirst_EMI_Date="ngModel"
                    name="DueDate"
                    id="DueDate"
                    value="{{ cEIModel.DueDate | date : 'dd/MM/yyyy' }}"
                    placeholder="DD/MM/YYYY"
                    [(bsValue)]="cEIModel.DueDate"
                    (ngModelChange)="setTenure()"
                    class="form-control input-text-css"
                    bsDatepicker
                    [ngClass]="{
                      'is-invalid': eif.submitted && refFirst_EMI_Date.invalid,
                      'alert-warning':
                        refFirst_EMI_Date.invalid &&
                        (refFirst_EMI_Date.dirty ||
                          refFirst_EMI_Date.touched ||
                          refFirst_EMI_Date.untouched)
                    }"
                  /> -->
                </div>
                <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
                  <div class="row m-0 mt-3 justify-content-center">
                    <div class="table-border">
                      <div class="item-row header">
                        <div class="lead w50 fs-14">#</div>
                        <div class="lead w120 fs-14">From EMI</div>
                        <div class="lead w120 fs-14">To EMI</div>
                        <div class="lead fs-14">EMI Amount</div>
                        <div class="lead w120 fs-14">No of EMI</div>
                        <div class="lead w150 fs-14">Total Amount</div>
                      </div>
                      <div
                        class="item-row"
                        *ngFor="let semi of stepEMI; let i = index"
                      >
                        <div class="w50">{{ i + 1 }}</div>
                        <div class="w120">
                          <input
                            type="text"
                            numbersOnly
                            readonly
                            [(ngModel)]="semi.FromEMI"
                            placeholder="From EMI"
                            name="FromEMI{{ i }}"
                            id="FromEMI{{ i }}"
                            class="form-control input-text-css"
                          />
                        </div>
                        <div class="w120">
                          <input
                            type="text"
                            numbersOnly
                            [disabled]="semi.disable"
                            [(ngModel)]="semi.ToEMI"
                            placeholder="To EMI"
                            name="ToEMI{{ i }}"
                            id="ToEMI{{ i }}"
                            class="form-control input-text-css"
                          />
                        </div>
                        <div class="">
                          <input
                            type="text"
                            numbersOnly
                            [disabled]="semi.disable"
                            [(ngModel)]="semi.EMI_Amount"
                            placeholder="EMI Amount"
                            name="EMI_Amount{{ i }}"
                            id="EMI_Amount{{ i }}"
                            class="form-control input-text-css mr-3 emi-amount"
                          />
                          <button
                            type="button"
                            *ngIf="!semi.disable"
                            class="primary-btn"
                            (click)="addNextStep(semi)"
                          >
                            <i
                              class="fa fa-check-circle"
                              style="font-size: 18px"
                            ></i>
                          </button>
                          <button
                            type="button"
                            class="primary-btn"
                            style="margin-left: 10px"
                            *ngIf="!semi.disable && i > 0"
                            (click)="removeLastStep(i)"
                          >
                            <i
                              class="fa fa-minus-circle"
                              style="font-size: 18px"
                            ></i>
                          </button>
                        </div>
                        <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                        <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                      </div>
                      <div class="item-row header" *ngIf="stepEmiData">
                        <div class="lead w50 fs-14">&nbsp;</div>
                        <div class="lead w120 fs-14"><b>Total</b></div>
                        <div class="lead w120 fs-14">&nbsp;</div>
                        <div class="lead fs-14">&nbsp;</div>
                        <div class="lead w120 fs-14">
                          <b>{{ stepEmiData.totalEmi }}</b>
                        </div>
                        <div class="lead w150 fs-14">
                          <b>{{ stepEmiData.totalAmount }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 col-md-12 justify-content-center">
                <button
                  type="button"
                  (click)="onCalculateEmiAndIRR()"
                  class="pl-5 pr-5 btn font-size-12 button-btn"
                  [class.spinner]="loading"
                  [disabled]="!eif.form.valid"
                >
                  Calculate
                </button>
              </div>

              <div class="row m-0 mt-0" *ngIf="emiIrr">
                <div class="col-md-12 mb-1 alert alert-success">
                  <div class="row">
                    <div class="col-md-4">
                      <span class="fs-12"
                        ><B
                          >Disbursement Amount : ₹
                          {{ emiIrr.Disbursement_Amt.toFixed(2) }}</B
                        ></span
                      >
                    </div>
                    <div class="col-md-3">
                      <span class="fs-12"
                        ><B>Interest Amount :</B> ₹
                        {{ emiIrr.Interest_Amt.toFixed(2) }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <span class="fs-12"
                        ><B>Agreement Value :</B> ₹
                        {{ emiIrr.Agreement_Value.toFixed(2) }}</span
                      >
                    </div>
                    <div class="col-md-2">
                      <span class="fs-12"
                        ><B>LTV :</B> {{ emiIrr.LTV.toFixed(2) }} %</span
                      >
                    </div>

                    <div
                      class="col-md-4"
                      [hidden]="
                        cEIModel.EMI_Type == 'Daily' ||
                        cEIModel.EMI_Type == 'Weekly' ||
                        cEIModel.EMI_Type == 'Fortnightly' ||
                        cEIModel.EMI_Type == 'ByWeekly' ||
                        cEIModel.EMI_Type == 'FourthWeekly'
                      "
                    >
                      <span class="fs-12">
                        <B>Effective IRR :</B>
                        {{ effectiveIrrData.toFixed(2) }} %
                      </span>
                    </div>
                    <div class="col-md-3">
                      <span class="fs-12"
                        ><B>EMI Amount :</B> ₹
                        {{ emiIrr.EMI_Amt.toFixed(2) }}</span
                      >
                    </div>
                    <div
                      class="col-md-3"
                      
                    >
                      <span class="fs-12"><B>Case IRR :</B> {{ emiIrr.Case_IRR.toFixed(2) }} %</span>
                    </div>
                    <div class="col-md-2">
                      <span class="fs-12"
                        ><B>ROI :</B> {{ emiIrr.ROI.toFixed(2) }} %</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>

          <div class="col-md-12 formborder mt-3" *ngIf="AmortizationSource">
            <mat-table
              [dataSource]="AmortizationSource"
              matTableExporter
              #exporter="matTableExporter"
            >
              <ng-container matColumnDef="PERIOD">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 50px"
                ></mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="EMI_Amount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >EMI_Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.EMI_Amount
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("EMI_Amount")
                }}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="DueDate">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >DueDate</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.DueDate
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Principle_OS">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Principle_OS</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Principle_OS
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="INTEREST">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >INTEREST</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.INTEREST
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("INTEREST")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="PRINCIPAL">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >PRINCIPAL</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.PRINCIPAL
                }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("PRINCIPAL")
                }}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="export">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  <button
                    class="btn font-size-12 button-btn"
                    (click)="exportTable(exporter)"
                  >
                    <i
                      class="fa fa-file-export mr-1"
                      style="font-size: medium; cursor: pointer"
                    ></i>
                    Export
                  </button>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell"></mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="AmortizationColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: AmortizationColumns"
                style="cursor: pointer"
              ></mat-row>
              <mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="AmortizationColumns; sticky: true"
                style="background: #4dc3a3"
              ></mat-footer-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
