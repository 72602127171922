import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
import { MasterService } from "src/app/Shared/app.Masters.Service";
declare var $: any;

@Component({
  selector: "app-future-flow-detail",
  templateUrl: "./future-flow-detail.component.html",
  styleUrls: ["./future-flow-detail.component.scss"],
})
export class FutureFlowDetailComponent implements OnInit {
  showSpinner: boolean = false;
  st_month_year: any;
  en_month_year: any;
  FyearId: any;
  Classification: any = "0";
  NPAList: any;
  LoanAcCatId: any = "0";
  AccountingCatDropdown: any[] = [];
  searchType: any = "Interest";

  futureFlowText: any[] = [];

  futureFlowTextDetail: any[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild("paginatorRef", { static: true }) paginator: MatPaginator;

  DataSource: any = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectProduct: any = 0;
  ProductList: any;
  currentUser: any;
  displayTreeColumns: string[];
  displayTreeColumnsDetail: string[];
  dataSourceTreeDetail: any;
  dataSourceTreeDetailforFiltering: MatTableDataSource<unknown>;
  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private _MasterService: MasterService
  ) {}

  pageTitle: any = "Future Flow Detail";

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.pageTitle);

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.st_month_year = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.en_month_year = new Date(
            moment(res[0].fyent).format("MM/DD/YYYY")
          );
          this.DataSource = null;
        });
    });

    this.Get_Loan_Accounting_Category_ForDropdown();
    this.getProductDropdown();
    this.getNPAList();

    this.getColumnHeading();
  }

  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccountingCatDropdown = res;
      });
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }

  getNPAList() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "NPA" })
      .subscribe((res: any) => {
        console.log(res);
        this.NPAList = res;
        console.log("NPA LIST", this.NPAList);
      });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  public GetInterfaceData() {
    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();

    return this.reportsService.Report_Column_Future_Flow_Detail({
      StartMonth: StMonth,
      StartYear: StYear,
      ProductId: this.SelectProduct,
      LoanAcCatId: parseInt(
        this.LoanAcCatId == "" && this.LoanAcCatId == null
          ? "0"
          : this.LoanAcCatId
      ),
      NPAType: this.Classification,
    });
  }

  getColumnHeading() {
    console.log("Column api call ");

    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();

    this.displayTreeColumnsDetail = [
      "Loan_Id",
      "Loanacno",
      "Customer",
      "Branch",
      "Loan_Acc_Category",
    ];
    this.reportsService
      .Report_Column_Future_Flow_Detail({
        StartMonth: StMonth,
        StartYear: StYear,
        ProductId: this.SelectProduct,
        LoanAcCatId: parseInt(
          this.LoanAcCatId == "" && this.LoanAcCatId == null
            ? "0"
            : this.LoanAcCatId
        ),
        NPAType: this.Classification,
      })
      .subscribe((res: any) => {
        console.log("res of column heading api call", res);
        for (var i = 0; i < res.length; i++) {
          this.futureFlowText.push(res[i].Month_Year);
          this.displayTreeColumnsDetail.push(res[i].Month_Year);
        }
        this.displayTreeColumns[this.displayTreeColumns.length] = "Action";
      });
  }

  GetReportFutureFlowDetail() {
    this.showSpinner = true;
    console.log("Future flow detail");

    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();

    let EnMonth = this.en_month_year.getMonth() + 1;
    let EnYear = this.en_month_year.getFullYear();

    this.reportsService
      .Report_Future_Flow_Detail({
        StartMonth: StMonth,
        StartYear: StYear,
        ProductId: this.SelectProduct,
        LoanAcCatId: parseInt(
          this.LoanAcCatId == "" && this.LoanAcCatId == null
            ? "0"
            : this.LoanAcCatId
        ),
        NPAType: this.Classification,
        SearchType: this.searchType,
      })
      .subscribe((res: any) => {
        console.log("res of future flow detail", res);
        if (res.length > 0) {
          console.log("I am in");
          // const jsonArray = JSON.parse(res);

          this.GetInterfaceData().subscribe((response: any) => {
            console.log("Res", res);
            var i = 0;
            var nestedJsonString = "";
            for (i = 0; i < res.length; i++) {
              nestedJsonString =
                nestedJsonString +
                res[i]["JSON_F52E2B61-18A1-11d1-B105-00805F49916B"].toString();
              // res[i]["JSON_F52E2B61-18A1-11d1-B105-00805F49916B"];
            }

            var parsedJson = JSON.parse(nestedJsonString);
            console.log("Parsed json", parsedJson);

            this.dataSourceTreeDetail = parsedJson;
            // console.log("Data Source Detail", this.dataSourceTreeDetail);

            this.displayTreeColumnsDetail = [
              "Loan_Id",
              "Loanacno",
              "Customer",
              "Branch",
              "Loan_Acc_Category",
            ];

            for (var i = 0; i < response.length; i++) {
              this.displayTreeColumnsDetail.push(response[i].Month_Year);
            }

            this.dataSourceTreeDetailforFiltering = new MatTableDataSource(
              parsedJson
            );
            this.dataSourceTreeDetailforFiltering.paginator = this.paginator;

            // console.log(
            //   "Ddata sourxe for filter",
            //   this.dataSourceTreeDetailforFiltering
            // );
          });
          this.showSpinner = false;
        }
        // console.log("log exit from if condition");
        this.showSpinner = false;
      });
  }

  totaltypewiseDetails(type) {
    if (
      type == "Loan_Id" ||
      type == "Loanacno" ||
      type == "Product" ||
      type == "Customer" ||
      type == "Branch"
    ) {
      return "";
    }
    if (type == "Loan_Acc_Category") return "Total:";

    if (this.dataSourceTreeDetail != null) {
      // console.log("under if condition");
      let sum = 0;
      for (let i = 0; i < this.dataSourceTreeDetail.length; i++) {
        sum += Number(
          this.dataSourceTreeDetail[i][type] == undefined
            ? 0
            : this.dataSourceTreeDetail[i][type]
        );
      }
      // console.log("sum", sum);
      return sum.toFixed(2);
    }
    return "";
  }

  applyFilter(filterValue: string) {
    console.log("Filter value", filterValue);
    this.dataSourceTreeDetailforFiltering.filter = filterValue
      .trim()
      .toLowerCase();
  }

  exportTable(exporter: any) {
    var Title = "Future Flow Detail";
    exporter.exportTable("xls", {
      fileName: Title,
      sheet: Title,
    });
  }
}
