import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-mst-loan-document",
  templateUrl: "./mst-loan-document.component.html",
  styleUrls: ["./mst-loan-document.component.scss"],
})
export class MstLoanDocumentComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetBranchesRequestModel: RequestModel = new RequestModel();
  SaveProductDocListRequestModel: RequestModel = new RequestModel();
  dataSource: any;
  displayedColumns: string[] = [
    "DocId",
    "Doc_Category",
    "Doc_Name",
    "Doc_Ind_NI",
    "Selection",
    "IsHMandatory",
    "IsCMandatory",
    "IsGMandatory",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ProductDocList: any = [];
  ProductListDropdown: any = [];
  FilterProductId: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Document");
    this._MasterService.GetProductForDropdown().subscribe((result) => {
      this.ProductListDropdown = JSON.parse(JSON.stringify(result));
    });
    this.getBranchEmployeeList();
  }

  getBranchEmployeeList() {
    this.showSpinner = true;
    this.RequestModel.ProductId =
      this.FilterProductId == "" ? 0 : parseInt(this.FilterProductId);
    this._MasterService
      .GetProductRequiredDoc(this.RequestModel)
      .subscribe((result) => {
        this.ProductDocList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.ProductDocList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  OnSaveLoanDocSection() {
    if (this.FilterProductId != "") {
      let productDoc = [];
      this.ProductDocList.map((item) => {
        if (item.IsAlreadySelected == true) {
          var IsHMandatory = 0;
          if (item.IsHMandatory == true) IsHMandatory = 1;
          else IsHMandatory = 0;

          var IsCMandatory = 0;
          if (item.IsCMandatory == true) IsCMandatory = 1;
          else IsCMandatory = 0;

          var IsGMandatory;
          if (item.IsGMandatory == true) IsGMandatory = 1;
          else IsGMandatory = 0;
          productDoc.push({
            ProductId: this.FilterProductId,
            DocumentId: item.DocId,
            IsHMandatory: IsHMandatory,
            IsCMandatory: IsCMandatory,
            IsGMandatory: IsGMandatory,
          });
        }
      });

      if (productDoc.length > 0) {
        this.SaveProductDocListRequestModel.JSON = JSON.stringify({
          ReqDoc: productDoc,
        });
        this._MasterService
          .SaveProductReqDoc(this.SaveProductDocListRequestModel)
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getBranchEmployeeList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Atleast one document select.",
          ...this.configSuccess,
        });
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select product.",
        ...this.configSuccess,
      });
    }
  }
}
