import { Component, Input, OnInit } from "@angular/core";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
declare var $: any;
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-key-facts-statement",
  templateUrl: "./key-facts-statement.component.html",
  styleUrls: ["./key-facts-statement.component.scss"],
})
export class KeyFactsStatementComponent implements OnInit {
  private _MasterService;
  @Input() loanId: any;
  Item1Data: any = {};
  Item2Data: any = {};
  Item3Data: any = {};
  Item4Data: any = {};
  Item5Data: any[] = [];
  currentUser: any;
  totalDRAmount = 0;
  totalCRAmount = 0;
  diffCRandDR = 0;
  disbursementType: any;
  constructor(
    private _commonfunctions: CommonFunctions,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
  }

  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  onCloseKeyFactsStatement() {
    $("#viewKeyFactsStatementAnnexA").modal("hide");
  }

  getKFSData() {
    this.lmsService
      .Get_KFS_Statement({ loanId: this.loanId })
      .subscribe((res: any) => {
        console.log("item 1 loanac no", res?.Item1[0].LoanAcNo);

        this.Item1Data = res?.Item1[0];
        this.Item2Data = res?.Item2;
        this.Item3Data = res?.Item3;
        this.Item4Data = res?.Item4;
        this.Item5Data = res?.Item5;

        this.disbursementType = res?.Item4[0].DisbursmentType;

        this.calculateTotalAmounts();
      });
  }

  calculateTotalAmounts() {
    this.totalDRAmount = 0;
    this.totalCRAmount = 0;

    this.Item3Data.forEach((item) => {
      this.totalDRAmount += parseFloat(item.DRAmount);
      this.totalCRAmount += parseFloat(item.CRAmount);
    });

    this.diffCRandDR = this.totalCRAmount - this.totalDRAmount;
  }

  viewKFSstatement() {
    this.getKFSData();
    $("#viewKeyFactsStatementAnnexA").modal("show");
    $("#viewKeyFactsStatementAnnexA").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    console.log("Print button clicked");
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }
}
