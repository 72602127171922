<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keyup.enter)="GetReportFutureFlow()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-2">
      <span class="required-lable"> Start Month Year </span>
      <input name="st_month_year"
             [(ngModel)]="st_month_year"
             id="st_month_year"
             placeholder="Start Month Year"
             class="form-control input-text-css"
             bsDatepicker
             [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
             (onShown)="onOpenCalendar($event)"
             required
             #refst_month_year="ngModel"
             [ngClass]="{
          'is-invalid': df.submitted && refst_month_year.invalid,
          'alert-warning':
            refst_month_year.invalid &&
            (refst_month_year.dirty ||
              refst_month_year.touched ||
              refst_month_year.untouched)
        }" />
      <!-- (ngModelChange)="DateChange()"  -->
    </div>
    <div class="col-md-2">
      <span>Product</span>
      <select name="SelectProduct"
              id="SelectProduct"
              #refSelectGender="ngModel"
              [(ngModel)]="SelectProduct"
              class="form-control input-text-css">
        <option value="0" selected>Select All</option>
        <option *ngFor="let product of ProductList" [value]="product.ProductId">
          {{ product.Product }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span>Accounting Category</span>
      <select name="LoanAcCatId"
              id="LoanAcCatId"
              [(ngModel)]="LoanAcCatId"
              class="form-control input-text-css">
        <option value="0">All</option>
        <option *ngFor="let row of AccountingCatDropdown"
                [value]="row.Loan_CategoryId">
          {{ row.Loan_category }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span> NPA Type</span>
     
      <select name="NPA"
              id="NPA"
              [(ngModel)]="Classification"
              class="form-control input-text-css">
        <option value="0" selected>All</option>
        <option *ngFor="let NPA of NPAList" [value]="NPA.Id">
          {{ NPA.Value }}
        </option>
      </select>
    </div>

    <div class="col-md-4">
      <button type="button"
              (click)="GetReportFutureFlow()"
              class="mt-3 btn font-size-12 button-btn"
              [disabled]="!df.form.valid">
        Search
      </button>
    </div>
  </div>
</form>

<div class="mt-3">
  <div class="" *ngIf="DataSource?.data?.length > 0" >
    <div class="table-responsive" >
      
        <mat-table
        class="table-bordered"
        [dataSource]="DataSource"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width:100%;overflow: auto;height:65vh;"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Month_Year">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Month Year</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Month_Year }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="No_Of_Case">
          <mat-header-cell mat-sort-header *matHeaderCellDef>No Of Case</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.No_Of_Case }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Installment_Amount">
          <mat-header-cell mat-sort-header *matHeaderCellDef>EMI Amount</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Installment_Amount }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ totaltypewise("Installment_Amount") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Principal">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Principal</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Principal }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ totaltypewise("Principal") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Interest">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Interest</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Interest }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ totaltypewise("Interest") }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></mat-footer-row>
        </mat-table>
    </div>
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-4 p-2">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8 mt-3">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  id="Future-flow"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Month Year
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of Case
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i }}</td>
    <td>{{ x.Month_Year }}</td>
    <td>{{ x.No_Of_Case }}</td>
    <td>{{ x.Installment_Amount }}</td>
    <td>{{ x.Principal }}</td>
    <td>{{ x.Interest }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="3"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Installment_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Interest") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
