import { Component, Input, OnInit } from '@angular/core';
import { constantUrl } from '../../../Shared/constantUrl';
@Component({
  selector: 'app-PD-Question-subcard-info',
  templateUrl: './PD-Question-subcard-info.component.html',
  styleUrls: ['./PD-Question-subcard-info.component.scss']
})
export class PDQuestionSubcardInfoComponent implements OnInit {
  @Input() PDQuestionData: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
