import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { LegalService } from '../services/Legal.service';
import { constantUrl } from "../../Shared/constantUrl";
import { LosService } from "../../_LOS/services/los.service";
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-legal-documents-info',
  templateUrl: './legal-documents-info.component.html',
  styleUrls: ['./legal-documents-info.component.scss']
})
export class LegalDocumentsInfoComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any; 
  loading: boolean = false;

  dataSource: any;
  displayedColumns: string[] =['LegalId', 'Doc_Title', 'Doc_Name', 'Created_By', 'Created_On', 'Action'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  
  @Input() LegalSummary: any;

  DocumentModel: any = [];
  
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  JsonData: any;

  IsSave: boolean = false;
  IsUpdate: boolean = false;


  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _LegalService: LegalService,
    private dialog: DialogService,
    private _LosService: LosService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next('Legal Documents');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.GetLegalDocument();
  }

  GetLegalDocument() {
    this.showSpinner = true;
    console.log("this.LegalSummary", this.LegalSummary);
    
    this._LegalService.Get_Legal_Document({ LegalId: this.LegalSummary.LegalId }).subscribe((res: any) => {
      if(res.length > 0){
        //this.JsonData = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.DocumentModel = res;
        this.IsSave = false;
        this.IsUpdate = true;
      }
      else{
        this.IsSave = true;
        this.IsUpdate = false;
        this.DocumentModel = [];
        this.InitializeIncomeDetail();
      }
      this.showSpinner = false;
    });
  }

  InitializeIncomeDetail(){
    this.DocumentModel.push({ 
      ApplicationNo: this.LegalSummary.Application_No,
      LegalId: this.LegalSummary.LegalId,
      Doc_Title: "",
      Doc_Name: "",
      Created_By: this.currentUser.userId
    });
  }

  AddLegalDocuments(){    
    $('#Documents').modal('show');
    $("#Documents").css("z-index", "1050");
  }

  onCloseDocument(){
    $('#Documents').modal('hide');
  }

  addMoreDocuments() {
    if (this.DocumentModel.length > 0) {
      let _prev = this.DocumentModel[this.DocumentModel.length - 1];
      if (_prev.Doc_Title == "" || _prev.Doc_Name == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.InitializeIncomeDetail();
  }
  removeDocuments(index: any) {
    this.DocumentModel.splice(index, 1);
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.Doc_Name = "";
      delete data.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      data.Doc_Name = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Doc_Name = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Doc_Name = "";
    delete data.DocData;
  }

  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {
    });
  }

  onSaveDocuments(){
    this.DocumentModel.forEach((obj: any) => {
      if (obj.DocData) {
        this.uploadDoc({ ApplicationNo: this.LegalSummary.Application_No, DocName: obj.Doc_Name, DocData: obj.DocData });
      }
    });

    let _data = {
      Legal_Doc: this.DocumentModel
    }
    
    //console.log("_data", _data);
    this._LegalService.Save_Legal_Document({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.onCloseDocument();
        this.GetLegalDocument();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
    });
  }
}
