<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keyup.enter)="GetReportFutureFlowDetail()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-2">
      <span class="required-lable"> Start Month Year </span>
      <input
        name="st_month_year"
        [(ngModel)]="st_month_year"
        id="st_month_year"
        placeholder="Start Month Year"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
        (onShown)="onOpenCalendar($event)"
        required
        #refst_month_year="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refst_month_year.invalid,
          'alert-warning':
            refst_month_year.invalid &&
            (refst_month_year.dirty ||
              refst_month_year.touched ||
              refst_month_year.untouched)
        }"
      />
      <!-- (ngModelChange)="DateChange()"  -->
    </div>
    <div class="col-md-3">
      <span>Product</span>
      <select
        name="SelectProduct"
        id="SelectProduct"
        #refSelectGender="ngModel"
        [(ngModel)]="SelectProduct"
        class="form-control input-text-css"
      >
        <option value="0" selected>Select All</option>
        <option *ngFor="let product of ProductList" [value]="product.ProductId">
          {{ product.Product }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <span>Accounting Category</span>
      <select
        name="LoanAcCatId"
        id="LoanAcCatId"
        [(ngModel)]="LoanAcCatId"
        class="form-control input-text-css"
      >
        <option value="0">All</option>
        <option
          *ngFor="let row of AccountingCatDropdown"
          [value]="row.Loan_CategoryId"
        >
          {{ row.Loan_category }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span> NPA Type</span>

      <select
        name="NPA"
        id="NPA"
        [(ngModel)]="Classification"
        #refClassification="ngModel"
        class="form-control input-text-css"
      >
        <option value="0" selected>All</option>
        <option *ngFor="let NPA of NPAList" [value]="NPA.Id">
          {{ NPA.Value }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <span>Search Type</span>
      <select
        name="searchType"
        id="searchType"
        #refsearchType="ngModel"
        [(ngModel)]="searchType"
        [ngClass]="{
          'is-invalid': df.submitted && refsearchType.invalid,
          'alert-warning':
            refsearchType.invalid &&
            (refsearchType.dirty ||
              refsearchType.touched ||
              refsearchType.untouched)
        }"
        class="form-control input-text-css"
        required
      >
        <option value="EMI_Amount">EMI Amount</option>
        <option value="Principle">Principle</option>
        <option value="Interest">Interest</option>
      </select>
    </div>

    <div class="col-md-4">
      <button
        type="button"
        (click)="GetReportFutureFlowDetail()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="col-md-3 mt-3" [hidden]="!dataSourceTreeDetailforFiltering">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div
  class="table-responsive"
  style="height: 60vh; max-width: 100%; overflow: auto; margin-top: 10px"
  [hidden]="!dataSourceTreeDetailforFiltering"
>
  <table
    mat-table
    [dataSource]="dataSourceTreeDetailforFiltering"
    style="overflow: auto"
    class="mat-elevation-z8"
    matPaginator
    #paginatorRef
    matSort
    matTableExporter
    #exporter1="matTableExporter"
  >
    <ng-container
      [matColumnDef]="col"
      *ngFor="let col of displayTreeColumnsDetail"
    >
      <th mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        <div *ngIf="col == 'Loan_Id'; else elsedivmainF">#</div>
        <ng-template style="width: 140px !important" #elsedivmainF>
          {{
            col == "Loanacno"
              ? "Loan Ac No"
              : col == "Product"
              ? "Product"
              : col == "Customer"
              ? "Customer Name"
              : col == "Branch"
              ? "Branch"
              : col == "Loan_Acc_Category"
              ? "Loan Acc Category"
              : col
          }}
        </ng-template>
      </th>
      <td mat-cell *matCellDef="let data; let i = index" class="grid-cell">
        <div
          style="width: 140px !important"
          *ngIf="
            col == 'Loanacno' ||
              col == 'Product' ||
              col == 'Customer' ||
              col == 'Branch' ||
              col == 'Loan_Acc_Category';
            else elseBlock
          "
        >
          {{ data[col] }}
        </div>
        <ng-template #elseBlock>
          <div *ngIf="col == 'Loan_Id'; else elsedivmain">
            {{ paginator.pageIndex * paginator.pageSize + (i + 1) }}
          </div>
          <ng-template #elsedivmain style="width: 140px !important">
            {{ data[col] }}
          </ng-template>
        </ng-template>
      </td>
      <th
        mat-footer-cell
        *matFooterCellDef
        style="border: 1px solid #9f9696; width: 140px !important"
      >
        {{ totaltypewiseDetails(col) }}
      </th>
    </ng-container>

    <tr
      mat-header-row
      class="sticky-footer"
      *matHeaderRowDef="displayTreeColumnsDetail; sticky: true"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayTreeColumnsDetail"></tr>
    <tr
      mat-footer-row
      class="sticky-footer fontcolor"
      *matFooterRowDef="displayTreeColumnsDetail; sticky: true"
      style="background: #4dc3a3; border: 1px solid #9f9696"
    ></tr>
  </table>
</div>

<div
  class="col-md-12 mt-3 formborder row ml-1"
  [hidden]="!dataSourceTreeDetailforFiltering"
>
  <div class="col-md-4 mt-3">
    <button
      class="mt-3 btn font-size-12 button-btn"
      (click)="exportTable(exporter1)"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 text-right mt-3">
    <mat-paginator
      #Paginglist
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
