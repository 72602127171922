import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
declare var $: any;

@Component({
  selector: "app-quickloan-my-lead-list",
  templateUrl: "./quickloan-my-lead-list.component.html",
  styleUrls: ["./quickloan-my-lead-list.component.scss"],
})
export class QuickLoanMyleadListComponent implements OnInit {
  currentUser: any;
  CreditAssignmentList: any[] = [];
  showSpinner: boolean = false;
  status: any = "Assigned";
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next("My Lead List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.losService.getFilterDropdownValue("my-lead-list")) {
      this.status = this.losService.getFilterDropdownValue("my-lead-list");
    }
    this.LOS_GetMyLeadsList();
  }

  LOS_GetMyLeadsList() {
    this.showSpinner = true;
    this.losService.setFilterDropdownValue({ "my-lead-list": this.status });
    if (this.status == "Assigned") {
      this.displayedColumns = [
        "InquiryId",
        "InquiryNo",
        "CustomerName",
        "CustomerAddress",
        "ContactNumber",
        "LoanAmount",
        "Purpose",
        "Source",
        "CreateOn",
        "Branch",
        "Action",
      ];
    } else if (this.status == "Hold") {
      this.displayedColumns = [
        "InquiryId",
        "InquiryNo",
        "CustomerName",
        "CustomerAddress",
        "ContactNumber",
        "LoanAmount",
        "Purpose",
        "Source",
        "CreateOn",
        "Branch",
        "Action",
      ];
    } else if (this.status == "Completed") {
      this.displayedColumns = [
        "InquiryId",
        "InquiryNo",
        "ApplicationNo",
        "CustomerName",
        "CustomerAddress",
        "ContactNumber",
        "LoanAmount",
        "Purpose",
        "Source",
        "CreateOn",
        "Branch",
      ];
    } else {
      this.displayedColumns = [
        "InquiryId",
        "InquiryNo",
        "CustomerName",
        "CustomerAddress",
        "ContactNumber",
        "LoanAmount",
        "Purpose",
        "Source",
        "CreateOn",
        "Branch",
        "RejectionReason",
      ];
    }
    this.losService
      .LOS_GetMyLeadsList({
        InqStatus: this.status,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.CreditAssignmentList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(this.CreditAssignmentList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(data: any) {
    // var inquiryId = this.encdec.encrypt(data.InquiryId);

    this.router.navigate([
      `/quickloan-dashboard/quick-loan/my-lead/${encodeURIComponent(data.InquiryId)}`,
    ]);
  }
  exportTable() : void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("quickloan_my_lead_list") as HTMLTableElement;
    const worksheetName = 'QUICK LOAN MY LEAD LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
    
  }
}
