<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  class="row m-0 mb-2 mt-3 w-b p-0 col-md-12 align-items-center justify-content-between"
>
  <h1 class="fs-12 m-0">Documents Details</h1>
  <button
    type="button"
    (click)="AddLegalDocuments()"
    class="btn_green"
    *ngIf="IsSave"
  >
    Add Documents
  </button>
  <button
    type="button"
    (click)="AddLegalDocuments()"
    class="btn_green"
    *ngIf="IsUpdate"
  >
    Update Documents
  </button>
</div>

<div class="row m-0 mt-2" [hidden]="!dataSource">
  <div class="col-md-12 p-1">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        id="exportReport"
        style="height: 250px; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="LegalId">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Doc_Title">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Document Title</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Doc_Title }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Doc_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Document</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Doc_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Created_By">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Created By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Created_By
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Created_On">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Created On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell"
            >{{ row.Created_On }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >View</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a
              href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                row.Doc_Name
              }}"
              target="_blank"
            >
              <i class="fa fa-eye fs-16" aria-hidden="true"></i>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Documents"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Upload Document
        </h6>
        <button
          type="button"
          (click)="onCloseDocument()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #df="ngForm" novalidate (keydown.enter)="onSaveDocuments()">
        <div class="modal-body">
          <form #f="ngForm" novalidate>
            <div class="row m-0 mr-2">
              <div class="col-md-12 ml-1 bg-success1">Document Details:</div>
            </div>
            <div
              class="row m-0"
              *ngFor="let row of DocumentModel; let i = index"
            >
              <div class="col-md-4 p-1">
                <span class="required-lable" *ngIf="i == 0"
                  >Document Title</span
                >
                <input
                  required
                  type="text"
                  #refDoc_Title="ngModel"
                  [(ngModel)]="row.Doc_Title"
                  placeholder="Document Title"
                  name="Doc_Title{{ i }}"
                  id="Doc_Title{{ i }}"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refDoc_Title.invalid,
                    'alert-warning':
                      refDoc_Title.invalid &&
                      (refDoc_Title.dirty ||
                        refDoc_Title.touched ||
                        refDoc_Title.untouched)
                  }"
                />
              </div>

              <div class="col-md-6 p-1">
                <span *ngIf="i == 0"> Document Image</span>
                <p class="mt-1" style="overflow-wrap: break-word">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!row.Doc_Name"
                    (click)="docFile.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="row.Doc_Name">
                    <a
                      href="{{ docBaseUrl }}{{ LegalSummary.Application_No }}/{{
                        row.Doc_Name
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2"
                        aria-hidden="true"
                        style="font-size: 18px"
                      ></i>
                      {{ row.Doc_Name }}
                    </a>
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile(row, docFile)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener(row, docFile.files)"
                  style="display: none"
                />
              </div>

              <div class="col-md-2 pt-3" style="margin-top: 5px">
                <button
                  type="button"
                  class="remove"
                  (click)="removeDocuments(i)"
                  *ngIf="i < DocumentModel.length && DocumentModel.length > 1"
                >
                  -
                </button>
                <button
                  type="button"
                  class="addmore"
                  (click)="addMoreDocuments()"
                  *ngIf="i == DocumentModel.length - 1"
                  [disabled]="!f.form.valid"
                >
                  +
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="onCloseDocument()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveDocuments()"
            [class.spinner]="loading"
            class="font-size-12 button-btn"
            [disabled]="!df.form.valid && !f.form.valid"
            *ngIf="IsSave"
          >
            Save
          </button>
          <button
            type="button"
            (click)="onSaveDocuments()"
            [class.spinner]="loading"
            class="font-size-12 button-btn"
            [disabled]="!df.form.valid && !f.form.valid"
            *ngIf="IsUpdate"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
