<div>
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <div class="row m-0 mt-2" *ngIf="dvDetail">
        <div class="col-md-2">
          <h6 class="fs-12">Application No</h6>
          <p class="lead fs-12">{{ dvDetail.ApplicationNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ dvDetail.Branch_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Product</h6>
          <p class="lead fs-12">{{ dvDetail.Product }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Borrower Name</h6>
          <p class="lead fs-12"><app-lms-customer-view-model [customerId]="dvDetail.CustomerId"  [index]="1000087452">{{ dvDetail.Customer }}</app-lms-customer-view-model></p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead fs-12">{{ dvDetail.LoanAcNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ dvDetail.Loan_Date }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Amount </h6>
          <p class="lead fs-12">₹{{ dvDetail.NetFinance }}</p>
        </div>
        <div class="col-md-2">
            <h6 class="fs-12">Disbursement Amount </h6>
            <p class="lead fs-12">₹{{ dvDetail.DisbursementAmt }}</p>
          </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure</h6>
          <p class="lead fs-12">{{ dvDetail.Tenure }} Months</p>
        </div>
        
        <div class="col-md-2">
          <h6 class="fs-12">Emi Amount</h6>
          <p class="lead fs-12">{{ dvDetail.Application_LoanEMIAmount }}</p>
        </div>
        <div class="col-md-2">
            <h6 class="fs-12">Case IRR</h6>
            <p class="lead fs-12">{{dvDetail.Case_IRR}}</p>
        </div>
        <div class="col-md-2">
            <h6 class="fs-12">LTV</h6>
            <p class="lead fs-12">{{dvDetail.LTV}}</p>
          </div>
        <div class="col-md-2">
            <h6 class="fs-12">Disbursement IRR</h6>
            <p class="lead fs-12">{{dvDetail.Disbursement_IRR}}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No Of Inst</h6>
          <p class="lead fs-12">{{ dvDetail.No_Of_Instl }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Adv.Instl</h6>
          <p class="lead fs-12">{{ dvDetail.Adv_Instl }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Created On</h6>
          <p class="lead fs-12">{{ dvDetail.ApplicationCreateOn }}</p>
        </div>
    </div>
  
    <div class="row m-0 col-md-12">
      <div class="table-responsive ">
        <mat-table [dataSource]="dataSourceCustomerDetail">
          <ng-container matColumnDef="Int_Id">
            <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>#</mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
              {{ (i + 1) }}
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef> Customer </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <app-lms-customer-view-model [customerId]="row.CustomerId" [index]="i">{{ row.Customer }}</app-lms-customer-view-model>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Customer Type</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{ row.CustomerType == 'Hirer' ? 'Borrower' : row.CustomerType}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header" *matHeaderCellDef> Gender Age </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.GenderAge}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Phone No</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{row.PhoneNo}} <i *ngIf="row.PhoneNoIsVerified =='true'" class="fa fa-check-circle ml-2" style="color: green;"></i>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Relation_With_Hirer">
            <mat-header-cell class="grid-header" *matHeaderCellDef> Relation With Hirer</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Relation_With_Hirer}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="ExistingCustomer">
            <mat-header-cell class="grid-header" *matHeaderCellDef> Existing </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ExistingCustomer}} </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedCustomerDetailColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedCustomerDetailColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  
    <div class="row m-0 col-md-12">
        <div class="main-container">
          <form #df="ngForm" novalidate>
            <div class="row m-0 mt-2 add-remove" *ngFor="let PData of PaymentDetail;let i=index">
                <div class="col-md-2">
                    <span *ngIf="i==0">Bank Name</span>
                    <input type="text" name="Bankname{{ i }}" id="Bankname{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.Bankname">
                </div>

                <div class="col-md-10">
                    <div class="row m-0">
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">Branch</span>
                            <input type="text" name="Branch{{ i }}" id="Branch{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.Branch">
                        </div>
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">A/C Type</span>
                            <input type="text" name="AccType{{ i }}" id="AccType{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.AccType">
                        </div>
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">A/C No</span>
                            <input type="text" name="AccountNo{{ i }}" id="AccountNo{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.AccountNo">
                        </div>
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">IFSC Code</span>
                            <input type="text" name="IFSCCode{{ i }}" id="IFSCCode{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.IFSCCode">
                        </div>
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">Disb. Customer</span>
                            <input type="text" name="Disbursment_Customer{{ i }}" id="Disbursment_Customer{{ i }}" class="form-control input-text-css" readonly [(ngModel)]="PData.Disbursment_Customer">
                        </div>
                        <div class="col-md-2 pr-0">
                            <span *ngIf="i==0">Disb. Amount</span>
                            <input type="text" name="Disbursment_Amount{{ i }}" id="Disbursment_Amount{{ i }}" class="form-control input-text-css" 
                            [(ngModel)]="PData.Disbursment_Amount" required #refDisbursment_Amount="ngModel" (change)="ChangeDisbAmt()"
                            [ngClass]="{ 'is-invalid': df.submitted && refDisbursment_Amount.invalid, 'alert-warning': refDisbursment_Amount.invalid  && (refDisbursment_Amount.dirty || refDisbursment_Amount.touched || refDisbursment_Amount.untouched) }">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0 m-0 mt-3 mb-2">
                <div class="col-md-12">
                    <h6 class="fs-12">Remark</h6>
                    <input type="text" #refPaymentRemark="ngModel" placeholder="Remark"
                        [ngClass]="{ 'is-invalid': df.submitted && refPaymentRemark.invalid, 'alert-warning': refPaymentRemark.invalid  && (refPaymentRemark.dirty || refPaymentRemark.touched || refPaymentRemark.untouched) }"
                        [(ngModel)]="PaymentRemark" name="PaymentRemark" id="PaymentRemark" class="form-control input-text-css" [required]="dvDetail && TotalAmt < dvDetail.DisbursementAmt">
                </div>
            </div>

            <div class="row col-md-12 m-0 mt-1 mb-2 justify-content-end">
                <button type="button" class="ml-4 font-size-12 button-btn"  (click)="saveAuthorizeDetail()" 
                    [disabled]="!df.form.valid" [class.spinner]="loading">
                    Save
                </button>
                <button type="button" (click)="closeAuthorizeDetail()" class="ml-4 font-size-12 button-btn">Cancel</button>
            </div>
          </form>
        </div>
    </div>
</div>
