import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
// import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { LosService } from "../../_LOS/services/los.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from "moment";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { constantUrl } from "../../Shared/constantUrl";
import { QuickLoanService } from "../../_QuickLoan/services/quickLoan.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-lms-loan-foir",
  templateUrl: "./lms-loan-foir.component.html",
  styleUrls: ["./lms-loan-foir.component.scss"],
})
export class LmsLoanFOIRComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalMonthlyExpense: number = 0;
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  customerData: any = {};
  IncExpData: any;
  CustomerId: any;
  TotalObligation: any;
  IncomeTypeDropdown: any = [];
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;

  isIncomeEdit: boolean = false;
  IncomeModel: any = [];
  ExpenseModel: any = [];
  resultforcondition: any = [];
  isEnabled: boolean = false;
  ObligationModel: any = [];

  totalCalculator: any = { total: 0.0 };
  totalCalculator1: any = { total: 0.0 };
  today: Date = new Date();
  totalCalculatorObligation: any = { total: 0.0 };
  Margin: any;
  Eligibility: any;
  EMIEligibility: number = 0;
  CurrentEmi: any;
  SavingAmount: number = 0;

  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() loanSummary: any;
  //BtnAddView: any = 'Fill';

  // FOIRItems: { IsEligible: boolean; FOIR_Amount: number }[] = [];
  docBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  totalFOIR: number = 0;
  totalFOIRExpense: number = 0;
  totalMonthlyObligationCalculated: { total: number };
  resultItem1CurrentEmi: number = 0;
  isCheckboxChecked: boolean = false;
  resultItem1FoirId: any = 0;
  isEligibleVariable: any;
  totalMonthlyExpenseCalculated: { total: number };
  totalMonthlyIncomeCalculated: { total: number };

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,

    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService
  ) {}

  ngOnInit(): void {
    // this.updateFOIR();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("FOIR");

    this.QuickLoan_Get_FOIR_Detail();
    // this.onChangeEligibilityRatio();

    this.onChangeEligibilityRatio();
  }

  QuickLoan_Get_FOIR_Detail() {
    this.IncomeModel = [];
    this.ExpenseModel = [];
    this.ObligationModel = [];
    this._QuickLoanService
      .QuickLoan_Get_FOIR_Detail({ LoanId: this.loanSummary.LoanId })
      .subscribe((result: any) => {
        console.log("FOIR LIST", result.Item1[0]);

        //this.isEligibleVariable = result.Item2[0].IsEligible;
        //console.log("is Eligible", this.isEligibleVariable);
        // console.log(typeof result);
        // var resultItem1 = result.item1[2];
        // console.log("Result .for item1", resultItem1);
        // console.log("Length", result.Item1.length);

        if (result.Item1 && result.Item1.length > 0) {
          // console.log("if started", result?.Item1[0]?.CurrentEMI);

          this.resultItem1CurrentEmi = result?.Item1[0]?.CurrentEMI;
          this.Eligibility =
            result?.Item1[0]?.Eligibility_Percentage == 0
              ? 0
              : result?.Item1[0]?.Eligibility_Percentage;
          // this.CurrentEmi = this.resultItem1CurrentEmi;
          this.CurrentEmi = this.resultItem1CurrentEmi ?? 0;

          console.log("this.CurrentEmi", this.CurrentEmi);

          this.resultItem1FoirId = result?.Item1[0]?.FOIRId;

          if (this.resultItem1FoirId > 0) {
            this.IsEdit = true;
          }

          console.log("resultItem1FoirId", this.resultItem1FoirId);
        }

        if (result.Item2 && result.Item2.length > 0) {
          this.IncomeModel = result.Item2.filter((x) => x.Type == "Income");
          // console.log("INCOME MODEL", this.IncomeModel);
          this.ExpenseModel = result.Item2.filter((x) => x.Type == "Expense");
          this.ObligationModel = result.Item2.filter(
            (x) => x.Type == "Obligation"
          );
          this.resultforcondition = result.Item2;
          // console.log("ExpenseModel", this.ExpenseModel);
          let _total: any = 0;

          let totalMonthlyIncome: any = 0;
          this.IncomeModel.forEach((obj: any) => {
            _total =
              _total +
                parseFloat(
                  obj.FOIR_Amount == undefined || obj.FOIR_Amount == ""
                    ? 0
                    : obj.FOIR_Amount
                ) || 0;
            totalMonthlyIncome =
              totalMonthlyIncome + parseFloat(obj.Amount) || 0;
          });

          this.totalCalculator = {
            total: parseFloat(_total),
          };
          this.totalMonthlyIncomeCalculated = {
            total: parseFloat(totalMonthlyIncome),
          };
          let _totalExp: any = 0;
          let totalMonthlyExpense: any = 0;
          this.ExpenseModel.forEach((obj: any) => {
            _totalExp = _totalExp + parseFloat(obj.FOIR_Amount) || 0;
            totalMonthlyExpense =
              totalMonthlyExpense + parseFloat(obj.Amount) || 0;
          });
          this.totalCalculator1 = {
            expAmount: parseFloat(_totalExp),
          };
          this.totalMonthlyExpenseCalculated = {
            total: parseFloat(totalMonthlyExpense),
          };

          let _totalObligation: any = 0;
          let totalMonthlyObligation: any = 0;
          this.ObligationModel.forEach((obj: any) => {
            _totalObligation =
              _totalObligation + parseFloat(obj.FOIR_Amount) || 0;
            totalMonthlyObligation =
              totalMonthlyObligation + parseFloat(obj.Amount) || 0;
          });
          this.totalCalculatorObligation = {
            total: parseFloat(_totalObligation),
          };
          this.TotalObligation =
            parseFloat(_totalObligation) + parseFloat(this.CurrentEmi);
          this.totalMonthlyObligationCalculated = {
            total: parseFloat(totalMonthlyObligation),
          };
          this.IsDisabled = true;
        } else {
          this.IncomeModel = [];
          this.ExpenseModel = [];
          this.ObligationModel = [];
          this.IsDisabled = false;
        }

        this.onChangeEligibilityRatio();
      });
  }

  toggleCheckbox(income, expense, Obligation) {
    this.isCheckboxChecked = !this.isCheckboxChecked;
    if (income != "" && income.IsEligible == true) {
      income.FOIR_Amount = income.Amount;
      this.onChangeIncomeAmount(income);
    }
    if (income != "" && income.IsEligible == false) {
      income.FOIR_Amount = 0;
      this.onChangeIncomeAmount(income);
    }
    if (expense != "" && expense.IsEligible == true) {
      expense.FOIR_Amount = expense.Amount;
      this.onChangeExpenseAmount(expense);
    }
    if (expense != "" && expense.IsEligible == false) {
      expense.FOIR_Amount = 0;
      this.onChangeExpenseAmount(expense);
    }
    if (Obligation != "" && Obligation.IsEligible == true) {
      Obligation.FOIR_Amount = Obligation.Amount;
      this.onChangeObligationAmount(Obligation);
    }

    if (Obligation != "" && Obligation.IsEligible == false) {
      Obligation.FOIR_Amount = 0;
      this.onChangeObligationAmount(Obligation);
    }

    this.onChangeEligibilityRatio();
  }
  onChangeObligationAmount(Obligation) {
    let _totalObligation: any = 0;

    this.ObligationModel.forEach((obj: any) => {
      _totalObligation = _totalObligation + parseFloat(obj.FOIR_Amount) || 0;
    });

    this.totalCalculatorObligation = {
      total: parseFloat(_totalObligation),
    };
    this.TotalObligation =
      parseFloat(_totalObligation) + parseFloat(this.CurrentEmi);
    console.log("Total Exp amount", this.totalCalculatorObligation.total);
    this.onChangeEligibilityRatio();
  }
  onChangeIncomeAmount(income) {
    console.log("Income Row", income);
    if (Number(income.FOIR_Amount) > Number(income.Amount)) {
      // alert("Foir Amount is grater than Monthly Amount");
      // console.log("income.FOIR_Amount", income.FOIR_Amount);
      // console.log("income.Amount", income.Amount);
      income.FOIR_Amount = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Foir Amount is greater than Monthly Amount",
        ...this.configSuccess,
      });
      income.FOIR_Amount = 0;
    }
    let _total: any = 0;

    this.IncomeModel.forEach((obj: any) => {
      _total =
        _total +
          parseFloat(
            obj.FOIR_Amount == undefined || obj.FOIR_Amount == ""
              ? 0
              : obj.FOIR_Amount
          ) || 0;
    });

    this.totalCalculator = {
      total: parseFloat(_total),
    };

    this.onChangeEligibilityRatio();
  }

  onChangeExpenseAmount(expense) {
    let _totalExp: any = 0;

    this.ExpenseModel.forEach((obj: any) => {
      _totalExp = _totalExp + parseFloat(obj.FOIR_Amount) || 0;
    });

    this.totalCalculator1 = {
      expAmount: parseFloat(_totalExp),
    };

    console.log("Total Exp amount", this.totalCalculator1.total);
    this.onChangeEligibilityRatio();
  }

  onChangeEligibilityRatio() {
    this.Margin = this.totalCalculator.total - this.totalCalculator1.expAmount;
    console.log("MArgin", this.Margin);
    this.EMIEligibility = (this.Margin * this.Eligibility) / 100;

    //(this.Margin * this.Eligibility ? this.Eligibility : 0) / 100;

    this.SavingAmount = 0;
    this.SavingAmount = this.EMIEligibility - this.CurrentEmi;
    console.log("Saving Amount", this.SavingAmount);
  }

  QuickLoan_Save_FOIR_Detail() {
    console.log("Save Function me aagya");
    let FoirData = [];
    this.IncomeModel.forEach((obj: any) => {
      if (obj.IsEligible) {
        FoirData.push({ Id: obj.Id, FOIR_Amount: obj.FOIR_Amount });
      }
    });

    this.ExpenseModel.forEach((obj: any) => {
      if (obj.IsEligible) {
        FoirData.push({ Id: obj.Id, FOIR_Amount: obj.FOIR_Amount });
      }
    });
    this.ObligationModel.forEach((obj: any) => {
      if (obj.IsEligible) {
        FoirData.push({ Id: obj.Id, FOIR_Amount: obj.FOIR_Amount });
      }
    });
    let _data = {
      FOIR_Detail: FoirData,
    };
    console.log("request jaon", JSON.stringify(_data));

    this._QuickLoanService
      .QuickLoan_Save_FOIR_Detail({
        LoginUserId: this.currentUser.userId,
        CurrentEMI: Number(this.CurrentEmi),
        Eligibility_Percentage: this.Eligibility,
        Total_Exp: this.totalCalculator1.total,
        Total_Inc: this.totalCalculator.total,
        Total_Obligation: this.totalCalculatorObligation.total,
        LoanId: this.loanSummary.LoanId,
        FOIRId: this.resultItem1FoirId,
        JSON: JSON.stringify(_data),
      })
      .subscribe((response) => {
        // console.log("Save Api Response", response);\
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });

          this.QuickLoan_Get_FOIR_Detail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onEditFoirAmount() {
    this.IsEdit = false;
  }
  CheckCustomerFOIRInfo() {
    this.LmsService.SaveNext_Loan({
      Loan_Id: this.loanSummary.LoanId,
      CommandName: "FOIR",
      isStatus: 1,
    }).subscribe((response) => {
      this.action.emit("next");
    });
  }
}
