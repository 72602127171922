import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
declare var $: any;

interface ParReport {
  Name: string;
  children?: ParReport[];
  ViewDetail: string;
}

interface ParReportFlatNode {
  expandable: boolean;
  Name: string;
  level: number;
  ViewDetail: string;
}

@Component({
  selector: "app-par-report",
  templateUrl: "./par-report.component.html",
  styleUrls: ["./par-report.component.scss"],
})
export class ParReportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  SlabData: any[] = [];
  Div_Branch: boolean = false;
  Div_Product: boolean = false;
  DataSource: any;
  Selectname: any;
  AmtType: any = "OD_Amount";
  partext: any[] = [];
  partextDetail: any[] = [];
  //Form value
  SlabId: any = "";
  ToDate: any = new Date();
  SearchBy: any = "";
  BranchId: any = 0;
  ProductId: any = 0;
  BranchesList: any = [];
  ProductList: any = [];
  div_modalOut = false;
  TREE_DATA: ParReport[] = [];
  displayTreeColumns: string[] = [];
  displayTreeColumnsDetail: string[] = [];
  div_tree: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("Paginglistmodel", { read: MatPaginator })
  Paginglistmodel: MatPaginator;

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private reportsService: ReportsService
  ) {}
  hasChild = (_: number, node: ParReportFlatNode) => node.expandable;

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("PAR Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getSlabsList("DPD");
    this.getBranchesList();
    this.getProductDropdown();
  }

  treeControl = new FlatTreeControl<ParReportFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  private transformer = (node: ParReport, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Name: node.Name,

      level: level,
      ViewDetail: node.ViewDetail,
    };
  };
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSourceTree: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  dataSourceTreeDetail: any;
  dataSourceTreeDetailModel: any = null;
  SearchByChange() {
    if (this.SearchBy == "Branch") {
      this.Div_Branch = true;
      this.Div_Product = false;
    } else if (this.SearchBy == "Product") {
      this.Div_Product = true;
      this.Div_Branch = false;
    } else {
      this.Div_Branch = false;
      this.Div_Product = false;
    }
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }
  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
      });
  }
  public GetInterfaceData() {
    return this._MasterService.Get_Report_Slab_Config({
      Id: this.SlabId,
      Type: "DPD",
      LoginUserId: this.currentUser.userId,
    });
  }
  GetParTextData() {
    // this.GetInterfaceData();
    this.partext = ["Name", "NoOfLoans"];
    this.displayTreeColumns = this.partext;

    this.partextDetail = ["LoanAcNo"];
    this.partextDetail = ["Loan_Date"];
    this.partextDetail = ["CustomerName"];

    this.displayTreeColumnsDetail = [
      "Id",
      "LoanAcNo",
      "Loan_Date",
      "CustomerName",
    ];
    this._MasterService
      .Get_Report_Slab_Config({
        Id: this.SlabId,
        Type: "DPD",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("res", res);
        for (var i = 0; i < res.length; i++) {
          this.partext.push("A" + res[i].Slab);
          this.displayTreeColumnsDetail.push("A" + res[i].Slab);

          this.partext.push("C" + res[i].Slab);
          //this.displayTreeColumnsDetail.push(
          //  "C" + res[i].Slab,
          //);
        }
        this.displayTreeColumns[this.displayTreeColumns.length] = "Action";
      });
    console.log("this.displayTreeColumnsDetail", this.displayTreeColumnsDetail);
  }

  onSearchParReportData() {
    this.showSpinner = true;
    this.reportsService
      .Report_PAR({
        To: this.ToDate,
        SlabId: this.SlabId,
        SearchBy: this.SearchBy,
        Branch_Id: this.BranchId,
        ProductId: this.ProductId,
        Amount_Type: this.AmtType,
      })
      .subscribe((res: any) => {
        this.GetParTextData();

        this.dataSourceTree = res;
        this.dataSourceTreeDetailModel = null;
        this.showSpinner = false;
        this.div_tree = true;
      });
  }
  onReport_PAR_Detail(data) {
    this.showSpinner = true;
    this.Selectname = data.Name;
    this.reportsService
      .Report_PAR_Detail({
        To: this.ToDate,
        SlabId: this.SlabId,
        SearchBy: this.SearchBy == "Product" ? "Product" : "Branch",
        Branch_Id: data.Id,
        ProductId: data.Id,
        Amount_Type: this.AmtType,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (this.SearchBy != "") {
            this.div_tree = true;
            this.dataSourceTreeDetail = null;
            this.dataSourceTreeDetailModel = new MatTableDataSource(res);
            this.dataSourceTreeDetailModel.paginator = this.Paginglistmodel;
            $("#ViewParDetails").modal("show");
            $("#ViewParDetails").css("z-index", "1050");
          } else {
            this.dataSourceTreeDetailModel = null;
            this.dataSourceTreeDetail = new MatTableDataSource(res);

            this.dataSourceTreeDetail.paginator = this.paginator;

            this.GetInterfaceData().subscribe((res: any) => {
              this.displayTreeColumnsDetail = [
                "Id",
                "LoanAcNo",
                "Loan_Date",
                "CustomerName",
              ];
              for (var i = 0; i < res.length; i++) {
                this.displayTreeColumnsDetail.push("A" + res[i].Slab);
                //this.displayTreeColumnsDetail.push("C" + res[i].Slab,);
              }
              this.div_tree = false;
              this.div_modalOut = true;
            });
          }
          this.showSpinner = false;
        } else {
          this.dataSourceTreeDetail = null;
          this.dataSourceTreeDetailModel = null;
          this.showSpinner = false;
        }
      });
  }
  onCloseParDetail() {
    this.Selectname = "";
    $("#ViewParDetails").modal("hide");
  }
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    let sh;
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.Id] = arrElem;
      mappedArr[arrElem.Id]["children"] = [];
    }

    for (var Id in mappedArr) {
      if (mappedArr.hasOwnProperty(Id)) {
        mappedElem = mappedArr[Id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.ParentId) {
          mappedArr[mappedElem["ParentId"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  exportTable(exporter: any, Type) {
    var Title = Type == "Summary" ? "Par-Report-Summary" : "Par-Report-Details";
    exporter.exportTable("xls", {
      fileName: Title,
      sheet: Title,
    });
  }

  totaltypewise(type) {
    //   console.log("dataSourceTree", this.dataSourceTree.filteredData)
    if (type != "Name" && type != "Action") {
      if (this.dataSourceTree != null) {
        let sum = 0;
        for (let i = 0; i < this.dataSourceTree.length; i++) {
          sum += Number(this.dataSourceTree[i][type]);
        }
        return sum > 0 ? sum.toFixed(2) : 0;
      }
    }
    return "Total";
  }

  totaltypewiseDetails(type) {
    if (type == "Id" || type == "LoanAcNo" || type == "Loan_Date") {
      return "";
    }

    if (type == "CustomerName") {
      return "Total";
    }

    if (this.dataSourceTreeDetail != null) {
      // console.log("under if condition");
      let sum = 0;
      for (let i = 0; i < this.dataSourceTreeDetail.length; i++) {
        sum += Number(
          this.dataSourceTreeDetail[i][type] == undefined
            ? 0
            : this.dataSourceTreeDetail[i][type]
        );
      }
      // console.log("sum", sum);
      return sum.toFixed(2);
    } 
    return "";
  }
  totaltypewiseDetailsModel(type) {
    if (type == "Id" || type == "LoanAcNo" || type == "Loan_Date") {
      return "";
    }

    if (type == "CustomerName") {
      return "Total";
    }

    if (this.dataSourceTreeDetailModel != null) {
      // console.log("under if condition");
      let sum = 0;
      for (let i = 0; i < this.dataSourceTreeDetailModel.length; i++) {
        sum += Number(
          this.dataSourceTreeDetailModel[i][type] == undefined
            ? 0
            : this.dataSourceTreeDetailModel[i][type]
        );
      }
      // console.log("sum", sum);
      return sum.toFixed(2);
    }
    return "";
  }
  // totaltypewiseDetails(type) {
  //   //'Id','LoanAcNo', 'Loan_Date', 'CustomerName'
  //   if (type == "Id" || type == "LoanAcNo" || type == "Loan_Date") {
  //     return "";
  //   }
  //   if (type == "CustomerName") return "Total";

  //   console.log("Data source in total type", this.dataSourceTreeDetail);

  //   if (this.dataSourceTreeDetail != null) {
  //     let sum = 0;
  //     // for (let i = 0; i < this.dataSourceTreeDetail.length; i++) {
  //     //   sum += Number(this.dataSourceTreeDetail[i][type]);
  //     // }

  //     for (let i = 0; i < this.dataSourceTreeDetail.length; i++) {
  //       sum += Number(
  //         this.dataSourceTreeDetail[i][type]
  //         // ? 0
  //         // : this.dataSourceTreeDetail[i][type]
  //       );
  //     }

  //     return sum.toFixed(2);
  //   }
  //   return "";
  // }

  ChangeAmt() {
    this.div_tree = false;
    this.div_modalOut = false;
    this.dataSourceTree = new MatTableDataSource([]);
  }

  applyFilter(filterValue: string) {
    this.dataSourceTreeDetail.filter = filterValue.trim().toLowerCase();
  }

  applyFilterModal(filterValue: string) {
    this.dataSourceTreeDetailModel.filter = filterValue.trim().toLowerCase();
  }
}
