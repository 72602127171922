import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";

@Component({
  selector: "app-mst-product-list",
  templateUrl: "./mst-product-list.component.html",
  styleUrls: ["./mst-product-list.component.scss"],
})
export class MstProductListComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  SaveNewProductRequestModel: RequestModel = new RequestModel();
  SaveProductCategoryRequestModel: RequestModel = new RequestModel();
  DeletePoductRequestModel: RequestModel = new RequestModel();
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "ProductId",
    "Product",
    "ShortName",
    "CRC",
    "FI",
    "TVR",
    "CustomerReference",
    "NACHPDC",
    "Insurance",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ProductList: any[] = [];
  Broken_Period_Interest: boolean = false;
  // add product popup
  ProductCategoryDropdown: any = [];
  ProductId: any = 0;
  SelectProductCategory: any = "";
  ProductName: string = "";
  ProductShortName: string = "";
  CRC_Co_Browser: boolean = false;
  CRC_Guarantor: boolean = false;
  FI_Co_Browser: boolean = false;
  FI_Guarantor: boolean = false;
  TVR_Co_Browser: boolean = false;
  TVR_Guarantor: boolean = false;
  multipleTrunche: boolean = false;
  ProductIsActive: boolean = false;
  // add new product
  NewProductCategoryName: any = "";
  isShowProductCategory: boolean = false;
  dialogreturn: any;
  status: any = false;
  chargesHead: any[] = [];
  pre_CloserCharges: any[] = [];
  chargableAccount: any[] = [];
  Insurance: boolean = true;
  CustomerRefrence: boolean = true;
  NACHPDC: boolean = true;
  hideAdd: boolean = true;
  PreCloserCharge_Type: any;
  ForCloserChargesId: any = 33;
  LockInPeriod: any;
  showSlab: boolean = false;
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Product List");
    this.getProductCategory();
    this.getProductList();
    this.Get_DefaultChargeHeadsForDropdown();
    this.Get_ChargeableAccountForDropdown();
  }
  changeFilter() {
    let _filterData = [];
    if (this.status == false) {
      _filterData = this.ProductList.filter((obj: any) => {
        return obj.Product_IsActive;
      });
    } else {
      _filterData = this.ProductList.filter((obj: any) => {
        return !obj.Product_IsActive;
      });
    }
    this.dataSource = new MatTableDataSource(_filterData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.showSpinner = false;
  }

  getProductList() {
    this.showSpinner = true;
    this._MasterService.GetProductList({}).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
      this.changeFilter();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  onOpenProductCatBox() {
    this.isShowProductCategory = true;
  }
  onDeleteProductCat() {
    this.NewProductCategoryName = "";
    this.isShowProductCategory = false;
  }

  saveProductCategory() {
    if (this.NewProductCategoryName == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter new product category.",
        ...this.configSuccess,
      });
    } else {
      this.SaveProductCategoryRequestModel.ProductCategoryName =
        this.NewProductCategoryName;
      this._MasterService
        .SaveProductCategory(this.SaveProductCategoryRequestModel)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
          if (response[0].CODE == 1) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }

  OnOpenProductModal() {
    this.router.navigate([`product/product/`]);
  }

  onSaveNewProduct() {
    this.SaveNewProductRequestModel.ProductId = this.ProductId;
    this.SaveNewProductRequestModel.ProductCategory =
      this.SelectProductCategory;
    this.SaveNewProductRequestModel.ProductName = this.ProductName;
    this.SaveNewProductRequestModel.ProductShortName = this.ProductShortName;
    this.SaveNewProductRequestModel.IsCoBrowser_CRC = this.CRC_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_CRC = this.CRC_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_FI = this.FI_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_FI = this.FI_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_TVR = this.TVR_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_TVR = this.TVR_Guarantor;
    this.SaveNewProductRequestModel.IsMultipleTranche = this.multipleTrunche;
    this.SaveNewProductRequestModel.LoginUserId = this.data.userId;
    this.SaveNewProductRequestModel.IsInsurance = this.Insurance;
    this.SaveNewProductRequestModel.IsNACHPDC = this.NACHPDC;
    this.SaveNewProductRequestModel.IsCustomerReference = this.CustomerRefrence;
    let _data = {
      Product: this.SaveNewProductRequestModel,
      ProductCharges: [],
    };
    _data.ProductCharges = JSON.parse(
      JSON.stringify(
        this.chargesHead.filter((obj: any) => {
          return obj.select;
        })
      )
    );
    _data.ProductCharges.forEach((obj: any) => {
      delete obj.select;
      delete obj.Id;
      delete obj.ChagesHead;
    });
    this._MasterService
      .SaveLoanProduct({ JSON: JSON.stringify(_data) })
      .subscribe((response) => {
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.OnCloseProductModal();
          this.getProductList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnCloseProductModal() {
    this.ProductId = 0;
    this.SelectProductCategory = "";
    this.ProductName = "";
    this.ProductShortName = "";
    this.CRC_Co_Browser = false;
    this.CRC_Guarantor = false;
    this.FI_Co_Browser = false;
    this.FI_Guarantor = false;
    this.TVR_Co_Browser = false;
    this.TVR_Guarantor = false;
    this.multipleTrunche = false;

    $("#AddNewProductModal").modal("hide");
  }

  onEditProduct(row) {
    this.ProductId = row.ProductId;
    this.SelectProductCategory = row.ProductCatId;
    this.ProductName = row.Product;
    this.ProductShortName = row.ShortName;
    this.CRC_Co_Browser = !(row.CRC.split(",").indexOf(" C") == -1);
    this.CRC_Guarantor = !(row.CRC.split(",").indexOf(" G") == -1);
    this.FI_Co_Browser = !(row.FI.split(",").indexOf(" C") == -1);
    this.FI_Guarantor = !(row.FI.split(",").indexOf(" G") == -1);
    this.TVR_Co_Browser = !(row.TVR.split(",").indexOf(" C") == -1);
    this.TVR_Guarantor = !(row.TVR.split(",").indexOf(" G") == -1);
    this.multipleTrunche = !(row.MultipleTranche == "No");
    this.Insurance = !(row.Insurance == "No");
    this.CustomerRefrence = !(row.CustomerReference == "No");
    this.NACHPDC = !(row.NACHPDC == "No");
    this.Get_ProductDefaultChargeableDetails();
    $("#AddNewProductModal").modal("show");
    $("#AddNewProductModal").css("z-index", "1050");
  }

  onDeleteProduct(row) {
    var msg = row.Product_IsActive == false ? "activate" : "deactivate";
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to " + msg + " selected Product ? " + row.Product
    );

    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this.DeletePoductRequestModel.ProductId = row.ProductId;
        this.DeletePoductRequestModel.LoginEmpId = parseInt(this.data.userId);
        this.DeletePoductRequestModel.Product_IsActive =
          row.Product_IsActive == false ? true : false;
        this._MasterService
          .DeleteProduct(this.DeletePoductRequestModel)
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getProductList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  Get_DefaultChargeHeadsForDropdown() {
    this.lmsService
      .Get_DefaultChargeHeadsForDropdown({})
      .subscribe((res: any) => {
        this.chargesHead = res;
      });
  }
  Get_ChargeableAccountForDropdown() {
    this.lmsService
      .Get_ChargeableAccountForDropdown({})
      .subscribe((res: any) => {
        this.chargableAccount = res;
      });
  }
  Get_ProductDefaultChargeableDetails() {
    this.lmsService
      .Get_ProductDefaultChargeableDetails({
        ProductId: this.ProductId,
        isDefault: true,
      })
      .subscribe((res: any) => {
        this.chargesHead.forEach((obj: any) => {
          let _find = res.find((ele: any) => {
            return ele.Charge_HeadId == obj.Id;
          });
          if (_find) {
            obj.select = true;
            obj.ChargeHeadId = obj.Id;
            obj.Account_HeadId = _find.Charge_Account_HeadId;
            obj.Charge_Type = _find.Charge_Type;
            obj.Charge_Values = _find.Charge_Values;
            obj.Charge_ApplyOn = _find.Charge_ApplyOn;
          } else {
            obj.ChargeHeadId = obj.Id;
            obj.select = res.count > 0 ? false : true;
            obj.Account_HeadId = "";
            obj.Charge_Type = "";
            obj.Charge_Values = "";
            obj.Charge_ApplyOn = "";
          }
        });
      });
  }
  check_PreCloserCharges_Type() {
    this.chargesHead.forEach((obj: any) => {
      let _find = this.chargesHead.find((ele: any) => {
        return ele.ChargeHeadId == this.ForCloserChargesId;
      });
      if (_find) {
        if (_find.Charge_Type == "" || _find.Charge_Type == undefined) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please Select Charges Type of Foreclosure charges",
            ...this.configSuccess,
          });
          return "";
        }
        return _find.Charge_Type;
      }
      return "";
    });
  }
  Get_PreCloserCharges_Type() {
    let _find = this.chargesHead.find((ele: any) => {
      return ele.ChargeHeadId == this.ForCloserChargesId;
    });
    if (_find) {
      return _find.Charge_Type;
    }
    return "";
  }
  preCloser() {
    let _find = this.chargesHead.find((ele: any) => {
      return ele.ChargeHeadId == this.ForCloserChargesId;
    });
    if (_find) {
      if (_find.Charge_Type == "" || _find.Charge_Type == undefined) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Select Charges Type of Foreclosure charges",
          ...this.configSuccess,
        });
      } else {
        this._MasterService
          .Get_Product_PreCloserCharges({ ProductId: this.ProductId })
          .subscribe((res: any) => {
            if (res.length > 0) {
              this.pre_CloserCharges = res;
              this.LockInPeriod = res[0].LockInPeriod;
              this.Broken_Period_Interest = res[0].Broken_Period_Interest;
              for (let i = 0; i < this.pre_CloserCharges.length; i++) {
                this.pre_CloserCharges[i].Name = "Slab_" + Number(i + 1);
              }
              this.showSlab = true;
            }
          });

        $("#AddPreColserModal").modal("show");
        $("#AddPreColserModal").css("z-index", "1050");
      }
    }
  }
  OnClosePreColserModal() {
    this.pre_CloserCharges = [];
    this.LockInPeriod = "";
    $("#AddPreColserModal").modal("hide");
  }

  removeData(index: any) {
    this.pre_CloserCharges.splice(index, 1);

    for (let i = 0; i < this.pre_CloserCharges.length; i++) {
      if (i != 0) {
        this.pre_CloserCharges[i].From_Values =
          Number(this.pre_CloserCharges[i - 1].To_Values) + 1;
        this.pre_CloserCharges[i].Name = "Slab_" + Number(i + 1);
      }
    }
  }

  initializepre_CloserDetail(i) {
    this.pre_CloserCharges.push({
      Name: "Slab_" + Number(i + 2),
      From_Values: Number(this.pre_CloserCharges[i].To_Values) + 1,
      To_Values: "",
      Charge_Values: "",
      chargeApplyOn: "POS",
      ProductId: this.ProductId,
      ChargeHeadId: this.ForCloserChargesId,
      ChargeType: this.Get_PreCloserCharges_Type(),
      CreateBy: this.data.userId,
    });
  }
  addMoreData(index: any) {
    this.initializepre_CloserDetail(index);
  }
  onSavePreColserCharges() {
    console.log("this.pre_CloserCharges", this.pre_CloserCharges);

    let _finalData = {
      PreCloser: this.pre_CloserCharges,
    };
    console.log("FinalJson", _finalData);
    this._MasterService
      .Save_Product_PreCloserCharges({
        JSON: JSON.stringify(_finalData),
        LockInPeriod: this.LockInPeriod,
        Broken_Period_Interest: this.Broken_Period_Interest,
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnClosePreColserModal();
        }
      });
  }
  LockInPeriodChange() {
    if (Number(this.LockInPeriod) >= 0) {
      this.showSlab = true;
      this.pre_CloserCharges = [];
      this.pre_CloserCharges.push({
        Name: "Slab_1",
        From_Values: Number(this.LockInPeriod) + 1,
        To_Values: "",
        Charge_Values: "",
        chargeApplyOn: "POS",
        ProductId: this.ProductId,
        ChargeHeadId: this.ForCloserChargesId,
        ChargeType: this.Get_PreCloserCharges_Type(),
        CreateBy: this.data.userId,
      });
    } else this.showSlab = false;
  }
  ToValueChange(i) {
    if (
      this.pre_CloserCharges[i].To_Values <=
      this.pre_CloserCharges[i].From_Values
    ) {
      this.pre_CloserCharges[i].To_Values = "";
      if (i + 1 < this.pre_CloserCharges.length)
        this.pre_CloserCharges[i + 1].From_Values = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To not less than From",
        ...this.configSuccess,
      });
    } else {
      if (i + 1 < this.pre_CloserCharges.length) {
        this.pre_CloserCharges[i + 1].From_Values =
          this.pre_CloserCharges[i].To_Values + 1;
        for (let i = 0; i < this.pre_CloserCharges.length; i++) {
          if (
            this.pre_CloserCharges[i].To_Values <=
            this.pre_CloserCharges[i].From_Values
          ) {
            this.pre_CloserCharges[i].To_Values = "";
            this.pre_CloserCharges[i].From_Values =
              this.pre_CloserCharges[i - 1].To_Values + 1;
          }
        }
      }
    }
  }
  goToProductDetail(row) {
    this.router.navigate([
      `product/product/${this._EncrDecrService.encrypt(
        encodeURIComponent(row.ProductId)
      )}`,
    ]);
  }
}
