<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="searchCustomerKycVerification()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          class="form-control input-text-css"
          (dateChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          class="form-control input-text-css"
          (dateChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="searchCustomerKycVerification()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
  </div>
</form>

<div class="col-md-12">
  <hr />
</div>

<div class="outerContainer" [hidden]="!DataSource">
  <div class="topSection">
    <table>
      <tr>
        <th style="width: 45px"></th>
        <th style="width: 132px">Opening Balance:-</th>
        <th>
          <span>{{ KycDetails?.OP_Balance }}</span>
        </th>
      </tr>
      <tr>
        <td style="font-weight: 700">Add:</td>
        <td>Recharge Amount:-</td>
        <td>{{ KycDetails?.Recharge_Amount }}</td>
      </tr>
      <tr>
        <td></td>
        <td>Total:-</td>
        <td style="text-decoration-line: underline overline">
          {{ KycDetails?.Total }}
        </td>
      </tr>

      <tr>
        <td style="font-weight: 700">Less:</td>
        <td>Used During Period:-</td>
        <td>{{ KycDetails?.Used_During_Period_Amount }}</td>
      </tr>
      <tr>
        <td></td>
        <td style="font-weight: 700">Closing Balance:-</td>
        <td style="font-weight: 700; text-decoration-line: underline overline">
          {{ KycDetails?.CL_Balance }}
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="col-md-12">
  <hr />
</div>

<div class="row m-0 col-md-12 p-0" [hidden]="!DataSource">
  <div class="col-md-12">
    <div class="table-responsive" style="height: 350px; overflow: auto">
      <table
        mat-table
        class="table-bordered"
        matSort
        [dataSource]="DataSource"
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="TYPE">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let row">{{ row.TYPE }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Step_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Step Name</th>
          <td mat-cell *matCellDef="let row">{{ row.Step_Name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Hit">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>HIT</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            {{ row.Hit }}
          </td>
          <th mat-footer-cell *matFooterCellDef>Total:</th>
        </ng-container>

        <ng-container matColumnDef="Rate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Rate</th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            {{ row.Rate }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            {{ row.Amount }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right">
            {{ calculateTotalAmount() }}
          </th>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
  </div>
</div>
