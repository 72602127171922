import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-asset-subcard-info",
  templateUrl: "./asset-subcard-info.component.html",
  styleUrls: ["./asset-subcard-info.component.scss"],
})
export class AssetSubcardInfoComponent implements OnInit {
  @Input() assetData: any;
  @Input() AssetsAttachment: any;
  @Input() ApplicationNumber: any;
  @Input() GoldAssets: any;
  displayedAssetItemColumns: any;
  assetTotal: any;
  jsonDataForGold: any;
  fixrate24ct: any = 100 / 24;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  constructor() { }

  ngOnInit(): void {

    this.jsonDataForGold = new MatTableDataSource(this.GoldAssets)
    this.getAssetTotal();

    this.displayedAssetItemColumns = [
      "Id",
      "ItemName",
      "ItemType",
      "Qty",
      "PurityCT",
      "GrossWt",
      "StoneWt",
      "OtherWt",
      "NetWt",
      "PureWt",
      "ValulationRate_PerCT",
    ];
  }




  getAssetTotal() {
    if (this.jsonDataForGold.filteredData[0].length == 0) {
      this.assetTotal = undefined;
    } else {
      this.assetTotal = {
        Qty: 0,
        GrossWt: 0,
        StoneWt: 0,
        OtherWt: 0,
        NetWt: 0,
        TotalAmount: 0,
      };
      this.jsonDataForGold?.filteredData?.forEach((obj: any) => {
        this.assetTotal.Qty = Number(this.assetTotal.Qty) + Number(obj.Qty);
        this.assetTotal.GrossWt =
          Number(this.assetTotal.GrossWt) + Number(obj.GrossWt);
        this.assetTotal.StoneWt =
          Number(this.assetTotal.StoneWt) + Number(obj.StoneWt);
        this.assetTotal.NetWt =
          Number(this.assetTotal.NetWt) + Number(obj.NetWt);
        this.assetTotal.TotalAmount =
          Number(this.assetTotal.TotalAmount) + Number(obj.ValulationAmount);
      });
      // this.assetModel.TotalValulationAmount = this.assetTotal.TotalAmount;
    }
  }

}
