import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { LegalService } from "../services/Legal.service";
declare var $: any;
import * as XLSX from "xlsx";
import { Workbook } from "exceljs";
import { DatePipe } from "@angular/common";
import * as fs from "file-saver";

import jspdf, { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ChangeDetectorRef } from "@angular/core";

// Create multiple PDF file
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import * as html2pdf from "html2pdf.js";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";

@Component({
  selector: "app-legal-assign-cases",
  templateUrl: "./legal-assign-cases.component.html",
  styleUrls: ["./legal-assign-cases.component.scss"],
})
export class LegalAssignCasesComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;

  dataSource: any;
  displayedColumns: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator })
  paginatorlist: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  Customer: any = {};
  SelectType: any = "";

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  IsLoanSearch: boolean = false;
  IsLegalCase: boolean = false;

  FileCheckingList: any[] = [];
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];

  LoanSummary: any;

  dataCustomerSource: any;
  displayedCustomerColumns: string[] = [
    "Action",
    "Number",
    "Customer",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
    "Relation_With_Hirer",
    "ExistingCustomer",
  ];

  CaseModel: any = {};

  arrayBuffer: any;
  file: any;
  dataSourceExcel: any;
  displayedExcelColumns: string[] = [];

  Customer_Type: any = "";
  JsonData: any[] = [];
  JsonData1: any[] = [];
  SelectJsonData: any[] = [];

  IsSelected: boolean = false;

  SelectAll: any;

  TemplateDropdown: any[] = [];
  TemplateId: any = "";
  TemplateName: any;

  selectedId: any;

  templateData: any;
  content: SafeHtml;

  SelectedData: any[] = [];
  Status: any = "Pending";

  dataSourcePrint: any;
  displayedColumnsPrint: string[] = [];

  SendModal: any = {};
  AgencyDropDown: any[] = [];
  CurrentDate: any = new Date();
  JsonData2: any[] = [];

  JsonDataPrint: any[] = [];
  LegalData: any;
  IsDisabled: boolean = false;
  modalTemplateData: any;
  ApplicationDetail: any;
  FileName: any;

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private _MasterService: MasterService,
    private router: Router,
    private losService: LosService,
    private _LegalService: LegalService,
    private dialog: DialogService,
    private lmsService: LmsService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private _commonfunctions: CommonFunctions,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Legal Assign");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    // this.GetLegalAssignList();

    this.Customer.CloseCase = "";

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.displayedColumnsLoan = [
      "LoanId",
      "LoanAcNo",
      "Application_No",
      "Customer",
      "Branch",
      "Product",
      "LoanAMount",
      "LoanCreatedOn",
      "Assign",
    ];

    this.LOS_GetCourierForDropdown();
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  LoanList() {
    this.showSpinner = true;
    this.SelectJsonData = [];
    this.dataSourceExcel = null;

    this.lmsService
      .LMS_Get_ModifyBooking_List({
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(this.Customer),
      })
      .subscribe((res: any) => {
        //console.log("RESULT : ",res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginatorlist;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceLoan = null;
        }
        this.showSpinner = false;
      });
  }

  GetLegalAssignList() {
    if (this.Status == "Print") {
      this.displayedColumns = [
        "Loan_Id",
        "LoanAcNo",
        "Loan_Date",
        "Branch_Name",
        "Product_Name",
        "CustomerName",
        "DPD_Days",
        "Total_OverDUE_EMI__Amount",
        "Noticecount",
        "Created_On",
        "Notice_Type",
        "Action",
      ];
    } else if (this.Status == "Send") {
      this.displayedColumns = [
        "Loan_Id",
        "LoanAcNo",
        "Loan_Date",
        "Branch_Name",
        "Product_Name",
        "CustomerName",
        "DPD_Days",
        "Total_OverDUE_EMI__Amount",
        "Created_On",
        "Notice_Type",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "Loan_Id",
        "LoanAcNo",
        "Loan_Date",
        "Branch_Name",
        "Product_Name",
        "CustomerName",
        "DPD_Days",
        "Total_OverDUE_EMI__Amount",
        "Action",
      ];
    }
    this.showSpinner = true;
    this._LegalService
      .Legal_Get_AssignList({
        LoginUserId: this.currentUser.userId,
        Status: this.Status,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData2 = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = null;
        }
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  OnAddDemandNotice() {
    this.IsLoanSearch = true;
    this.IsLegalCase = false;
    this.dataSourceLoan = null;
    this.SelectAll = false;
    this.SelectJsonData = [];
    this.JsonData1 = [];
    $(`#AddDemandNotice`).modal("show");
    $(`#AddDemandNotice`).css("z-index", "1050");
  }

  OnCloseDemandNotice() {
    $(`#AddDemandNotice`).modal("hide");
  }

  goToDetailLoan(row) {
    this.IsLoanSearch = false;
    this.IsLegalCase = true;
    this.LoanSummary = row;
    //this.LMS_GetLoanDetails(row.LoanIdentity);
    this.Get_Customer_For_Legal_Notice(row.LoanAcNo);
  }

  GoToDetails(row) {
    console.log("row", row);
    this.LoanSummary = row;
    this.IsLoanSearch = false;
    this.IsLegalCase = true;
    this.dataSourceLoan = null;
    this.SelectAll = false;
    this.SelectJsonData = [];
    this.TemplateId = "";
    this.LoanSummary.LoanAcNo = row.LoanAcNo;
    this.LoanSummary.Product = row.Product_Name;
    this.LoanSummary.Branch = row.Branch_Name;

    $(`#AddDemandNotice`).modal("show");
    $(`#AddDemandNotice`).css("z-index", "1050");
    this.Get_Customer_For_Legal_Notice(row.LoanAcNo);
  }

  // LMS_GetLoanDetails(LoanId) {
  //   this.showSpinner = true;
  //   this.lmsService.LMS_GetLoanDetails({ Loan_Id: LoanId }).subscribe((res: any) => {
  //     this.showSpinner = false;
  //     this.dataCustomerSource = new MatTableDataSource(res.Item2);
  //   });
  // }

  // onCustomerChange(event: any, data: any) {
  //   console.log("data", data);
  //   console.log("this.LoanSummary", this.LoanSummary);
  //   this.SelectJsonData = [];
  //   this.SelectJsonData = data;
  //   this.CaseModel.CustomerId = data.CustomerId;
  // }

  onImport(event) {
    this.LoanSummary = null;
    this.dataSourceExcel = null;
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      var ExcelData: any = [];
      ExcelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      let LoanNo = ExcelData.map((element) => element.LoanNo).join(",");
      LoanNo = LoanNo.replace(/,\s*$/, "");
      console.log("LoanNo", LoanNo);

      if (ExcelData.length > 0) {
        this.Get_Customer_For_Legal_Notice(LoanNo);
      }

      this.IsLoanSearch = false;
      this.IsLegalCase = true;
    };
  }

  Get_Customer_For_Legal_Notice(LoanNo) {
    this.GetTemplateType();
    this.showSpinner = true;
    this.displayedExcelColumns = [
      "Select",
      "Loan_Id",
      "LoanAcNo",
      "Loan_Date",
      "AC_CustomerType",
      "CustomerName",
      "Branch_Name",
      "Product_Name",
      "Application_LoanAmount",
      "Address",
      "Last_Recv_Date",
      "Last_Recv_Amount",
    ];
    this._LegalService
      .Get_Customer_For_Legal_Notice({ LoanAcNo: LoanNo })
      .subscribe((res: any) => {
        console.log("res", res);

        this.JsonData = res;
        this.JsonData.forEach((x) => {
          x.IsSelected = false;
        });

        this.dataSourceExcel = new MatTableDataSource(
          JSON.parse(JSON.stringify(this.JsonData))
        );
        this.showSpinner = false;
      });
  }

  OnchangeCustomerType() {
    this.SelectAll = false;
    if (this.Customer_Type) {
      this.JsonData1 = this.JsonData.filter(
        (x) => x.AC_CustomerType == this.Customer_Type
      );
      this.dataSourceExcel = new MatTableDataSource(
        JSON.parse(JSON.stringify(this.JsonData1))
      );
      this.SelectJsonData = [];
      this.JsonData1.forEach((x) => {
        if (x.checked == true) {
          x.IsSelected = true;
          this.SelectJsonData.push(x);
        }
        if (x.checked == false) {
          x.IsSelected = false;
        }
      });
    } else {
      this.JsonData1 = this.JsonData;
      this.dataSourceExcel = new MatTableDataSource(
        JSON.parse(JSON.stringify(this.JsonData1))
      );
    }
  }

  checkUncheckAll(event) {
    this.SelectJsonData = [];
    let data = [];
    this.SelectJsonData = [];
    data = this.JsonData1.length > 0 ? this.JsonData1 : this.JsonData;
    data.forEach((x) => {
      if (event.checked == true) {
        x.IsSelected = true;
        this.SelectJsonData.push(x);
      }
      if (event.checked == false) {
        x.IsSelected = false;
      }
    });
    this.dataSourceExcel = new MatTableDataSource(
      JSON.parse(JSON.stringify(data))
    );
  }

  GetTemplateType() {
    this._MasterService
      .Get_Template_Type({ Type: "Legal" })
      .subscribe((result: any) => {
        this.TemplateDropdown = result;
      });
  }

  public highlightRow(index) {
    this.selectedId = index;
  }

  OnchangeTemplateType() {
    this.getDemandletter();
  }

  getDemandletter() {
    this.TemplateName = this.TemplateDropdown.filter(
      (x) => x.Id == this.TemplateId
    )[0].Template_Name;
    // this._MasterService.Get_Template({ Type: 'Legal', TemplateName: this.TemplateName }).subscribe((res: any) => {
    this._MasterService
      .Get_Template_Data({ Id: this.TemplateId, ProductId: 0 })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.templateData = res[0].TemplateData;
          //this.content = this.sanitizer.bypassSecurityTrustHtml(this.templateData);
        }
      });
  }

  exportAsPDF(FileName, Data) {
    //var html = htmlToPdfmake(Data);
    //const documentDefinition = { content: html, font:"DejaVu Serif", defaultStyle: { fontSize: 10 } };
    //pdfMake.createPdf(documentDefinition).download(`${FileName}.pdf`);
    html2pdf(Data).from(Data).save(`${FileName}.pdf`);
  }

  onChangeChecked(row, i) {
    console.log("row", row);
    this.SelectJsonData = [];
    this.dataSourceExcel.filteredData.forEach((x, index) => {
      if (index != i) x.IsSelected = false;
    });
    // row.IsSelected == true;

    if (row.IsSelected == true) this.SelectJsonData.push(row);
    console.log("SelectJsonData", this.SelectJsonData);
  }

  DownloadPDF() {
    console.log("Button Clicked");
    this.showSpinner = true;
    let Data = [];
    this.SelectedData = [];
    //Data = this.SelectJsonData.length > 0 ? this.SelectJsonData :( this.JsonData1.length > 0 ? this.JsonData1 : this.JsonData);
    Data = this.SelectJsonData.length > 0 ? this.SelectJsonData : [];

    console.log("DataData", Data);
    if (
      Data.length == 0 ||
      Data.filter((x) => x.IsSelected == true).length == 0
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please check atleast single row.",
        ...this.configSuccess,
      });
      this.showSpinner = false;
      return;
    } else {
      let TemplData;
      TemplData = this.templateData;
      Data.map((x) => {
        if (x.IsSelected == true) {
          TemplData = this.templateData;
          //TemplData = TemplData.replace( /\[Date\]/g,  this.datePipe.transform(new  Date(), 'dd/M/yyyy'));
          //TemplData = TemplData.replace( /\[Loan_No\]/g, x.LoanAcNo );
          //TemplData = TemplData.replace( /\[Application_No\]/g, x.LoanAcNo );
          //TemplData = TemplData.replace( /\[Customer_Name\]/g, x.CustomerName );
          //TemplData = TemplData.replace( /\[Customer_Relation\]/g, x.CustomerRelation );
          //TemplData = TemplData.replace( /\[Customer_PhoneNo\]/g, x.Customer_PhoneNo );
          //TemplData = TemplData.replace( /\[Customer_Address\]/g, x.Address );
          //TemplData = TemplData.replace(/\[Loan_Product\]/g, x.Product_Name);

          this._MasterService
            .Get_Template_Details({
              Loan_Id: x.Loan_Id,
            })
            .subscribe((res: any) => {
              TemplData = this.templateData;
              var applicationDetail = res.Item1[0];
              this.FileName =
                x.LoanAcNo.toString() +
                "_" +
                x.CustomerName +
                "_" +
                applicationDetail.Date;
              console.log("Response Details Application ", res);
              var coborrower1Details = res.Item2[0];
              var coborrower2Details = res.Item2[1];
              var guaranter1Details = res.Item3[0];
              var guaranter2Details = res.Item3[1];

              TemplData = TemplData.replace(
                /\[Application_No\]/g,
                applicationDetail.Application_No == null
                  ? ""
                  : applicationDetail.Application_No
              );

              TemplData = TemplData.replace(
                /\[Source_Type\]/g,
                applicationDetail.SourceType == null
                  ? ""
                  : applicationDetail.SourceType
              );

              TemplData = TemplData.replace(
                /\[Source_Name\]/g,
                applicationDetail.SourceName == null
                  ? ""
                  : applicationDetail.SourceName
              );

              TemplData = TemplData.replace(
                /\[Branch_Name\]/g,
                applicationDetail.BranchName == null
                  ? ""
                  : applicationDetail.BranchName
              );
              TemplData = TemplData.replace(
                /\[Property_Owner_Name\]/g,
                applicationDetail.Property_Owner_Name == null
                  ? ""
                  : applicationDetail.Property_Owner_Name
              );
              TemplData = TemplData.replace(
                /\[Loan_No\]/g,
                applicationDetail.Loan_No == null
                  ? ""
                  : applicationDetail.Loan_No
              );
              TemplData = TemplData.replace(
                /\[Customer_Name\]/g,
                applicationDetail.Customer_Name == null
                  ? ""
                  : applicationDetail.Customer_Name
              );
              TemplData = TemplData.replace(
                /\[Father_Name\]/g,
                applicationDetail.Father_Name == null
                  ? ""
                  : applicationDetail.Father_Name
              );
              TemplData = TemplData.replace(
                /\[Customer_Address\]/g,
                applicationDetail.Customer_Address == null
                  ? ""
                  : applicationDetail.Customer_Address
              );
              TemplData = TemplData.replace(
                /\[Customer_PhoneNo\]/g,
                applicationDetail.Customer_PhoneNo == null
                  ? ""
                  : applicationDetail.Customer_PhoneNo
              );
              TemplData = TemplData.replace(
                /\[Loan_Product\]/g,
                applicationDetail.Loan_Product == null
                  ? ""
                  : applicationDetail.Loan_Product
              );
              TemplData = TemplData.replace(
                /\[Loan_Amount\]/g,
                applicationDetail.Loan_Amount == null
                  ? ""
                  : applicationDetail.Loan_Amount
              );
              TemplData = TemplData.replace(
                /\[Margin_Amount\]/g,
                applicationDetail.Margin_Amount == null
                  ? ""
                  : applicationDetail.Margin_Amount
              );
              TemplData = TemplData.replace(
                /\[Tenure_Month\]/g,
                applicationDetail.Tenure_Month == null
                  ? ""
                  : applicationDetail.Tenure_Month
              );
              TemplData = TemplData.replace(
                /\[No_Of_instalment\]/g,
                applicationDetail.No_Of_instalment == null
                  ? ""
                  : applicationDetail.No_Of_instalment
              );
              TemplData = TemplData.replace(
                /\[EMI_Amount\]/g,
                applicationDetail.EMI_Amount == null
                  ? ""
                  : applicationDetail.EMI_Amount
              );
              TemplData = TemplData.replace(
                /\[EMI_Advance\]/g,
                applicationDetail.EMI_Advance == null
                  ? ""
                  : applicationDetail.EMI_Advance
              );
              TemplData = TemplData.replace(
                /\[ROI\]/g,
                applicationDetail.ROI == null ? "" : applicationDetail.ROI
              );
              TemplData = TemplData.replace(
                /\[IRR\]/g,
                applicationDetail.IRR == null ? "" : applicationDetail.IRR
              );
              TemplData = TemplData.replace(
                /\[Date\]/g,
                applicationDetail.Date == null ? "" : applicationDetail.Date
              );
              TemplData = TemplData.replace(
                /\[Date_Of_EMI\]/g,
                applicationDetail.Date_Of_EMI == null
                  ? ""
                  : applicationDetail.Date_Of_EMI
              );
              TemplData = TemplData.replace(
                /\[Day_Of_EMI\]/g,
                applicationDetail.Day_Of_EMI == null
                  ? ""
                  : applicationDetail.Day_Of_EMI
              );
              TemplData = TemplData.replace(
                /\[EMI_Frequency\]/g,
                applicationDetail.EMI_Frequency == null
                  ? ""
                  : applicationDetail.EMI_Frequency
              );
              TemplData = TemplData.replace(
                /\[Customer_Relation\]/g,
                applicationDetail.Customer_Relation == null
                  ? ""
                  : applicationDetail.Customer_Relation
              );
              TemplData = TemplData.replace(
                /\[Customer_Tehsil\]/g,
                applicationDetail.Customer_Tehsil == null
                  ? ""
                  : applicationDetail.Customer_Tehsil
              );
              TemplData = TemplData.replace(
                /\[Customer_District\]/g,
                applicationDetail.Customer_District == null
                  ? ""
                  : applicationDetail.Customer_District
              );
              TemplData = TemplData.replace(
                /\[Customer_State\]/g,
                applicationDetail.Customer_State == null
                  ? ""
                  : applicationDetail.Customer_State
              );
              TemplData = TemplData.replace(
                /\[Customer_Pin_Code\]/g,
                applicationDetail.Customer_Pin_Code == null
                  ? ""
                  : applicationDetail.Customer_Pin_Code
              );
              TemplData = TemplData.replace(
                /\[Exposure_Amount\]/g,
                applicationDetail.Exposure_Amount == null
                  ? ""
                  : applicationDetail.Exposure_Amount
              );
              TemplData = TemplData.replace(
                /\[Overdue_Amount\]/g,
                applicationDetail.Overdue_Amount == null
                  ? ""
                  : applicationDetail.Overdue_Amount
              );
              //extra
              TemplData = TemplData.replace(
                /\[DisbursementAmount\]/g,
                applicationDetail.DisbursementAmount == null
                  ? ""
                  : applicationDetail.DisbursementAmount
              );
              TemplData = TemplData.replace(
                /\[DealerName\]/g,
                applicationDetail.DealerName == null
                  ? ""
                  : applicationDetail.DealerName
              );
              TemplData = TemplData.replace(
                /\[VehicleModel\]/g,
                applicationDetail.Vehicle_Model == null
                  ? ""
                  : applicationDetail.Vehicle_Model
              );
              TemplData = TemplData.replace(
                /\[SalesExecutive\]/g,
                applicationDetail.SalesExecutive == null
                  ? ""
                  : applicationDetail.SalesExecutive
              );
              TemplData = TemplData.replace(
                /\[AssetsValue\]/g,
                applicationDetail.Application_AssetCost == null
                  ? ""
                  : applicationDetail.Application_AssetCost
              );
              TemplData = TemplData.replace(
                /\[LTV\]/g,
                applicationDetail.LTV == null ? "" : applicationDetail.LTV
              );
              TemplData = TemplData.replace(
                /\[LoanDate\]/g,
                applicationDetail.Loan_Date == null
                  ? ""
                  : applicationDetail.Loan_Date
              );
              TemplData = TemplData.replace(
                /\[CloseDate\]/g,
                applicationDetail.LoanCloseDate == null
                  ? ""
                  : applicationDetail.LoanCloseDate
              );

              TemplData = TemplData.replace(
                /\[Total_Deduction\]/g,
                applicationDetail.Total_Deduction == null
                  ? ""
                  : applicationDetail.Total_Deduction
              );

              //new

              TemplData = TemplData.replace(
                /\[Expiry_Date\]/g,
                applicationDetail.Expiry_Date == null
                  ? ""
                  : applicationDetail.Expiry_Date
              );
              TemplData = TemplData.replace(
                /\[Customer_Nach_Bank\]/g,
                applicationDetail.Customer_Nach_Bank == null
                  ? ""
                  : applicationDetail.Customer_Nach_Bank
              );
              TemplData = TemplData.replace(
                /\[Loan_Purpose\]/g,
                applicationDetail.Loan_Purpose == null
                  ? ""
                  : applicationDetail.Loan_Purpose
              );
              TemplData = TemplData.replace(
                /\[Loan_Amount_InWord\]/g,
                applicationDetail.Loan_Amount_InWord == null
                  ? ""
                  : applicationDetail.Loan_Amount_InWord
              );
              TemplData = TemplData.replace(
                /\[Property_Address\]/g,
                applicationDetail.Property_Address == null
                  ? ""
                  : applicationDetail.Property_Address
              );
              TemplData = TemplData.replace(
                /\[Type_of_Property\]/g,
                applicationDetail.Type_of_Property == null
                  ? ""
                  : applicationDetail.Type_of_Property
              );
              TemplData = TemplData.replace(
                /\[Nature_of_Property\]/g,
                applicationDetail.Nature_of_Property == null
                  ? ""
                  : applicationDetail.Nature_of_Property
              );
              TemplData = TemplData.replace(
                /\[Ownership_Type\]/g,
                applicationDetail.Ownership_Type == null
                  ? ""
                  : applicationDetail.Ownership_Type
              );

              TemplData = TemplData.replace(
                /\[Ownership_Document\]/g,
                applicationDetail.Ownership_Document == null
                  ? ""
                  : applicationDetail.Ownership_Document
              );
              TemplData = TemplData.replace(
                /\[Unit_of_Measurement\]/g,
                applicationDetail.Unit_of_Measurement == null
                  ? ""
                  : applicationDetail.Unit_of_Measurement
              );
              TemplData = TemplData.replace(
                /\[Total_Area\]/g,
                applicationDetail.Total_Area == null
                  ? ""
                  : applicationDetail.Total_Area
              );
              TemplData = TemplData.replace(
                /\[Constructed_Area\]/g,
                applicationDetail.Constructed_Area == null
                  ? ""
                  : applicationDetail.Constructed_Area
              );

              TemplData = TemplData.replace(
                /\[Engine_No\]/g,
                applicationDetail.Engine_No == null
                  ? ""
                  : applicationDetail.Engine_No
              );
              TemplData = TemplData.replace(
                /\[Chassis_No\]/g,
                applicationDetail.Chassis_No == null
                  ? ""
                  : applicationDetail.Chassis_No
              );
              TemplData = TemplData.replace(
                /\[Vehicle_Registation_No\]/g,
                applicationDetail.Vehicle_Registation_No == null
                  ? ""
                  : applicationDetail.Vehicle_Registation_No
              );
              TemplData = TemplData.replace(
                /\[Identification_1\]/g,
                applicationDetail.Identification_1 == null
                  ? ""
                  : applicationDetail.Identification_1
              );
              TemplData = TemplData.replace(
                /\[Identification_2\]/g,
                applicationDetail.Identification_2 == null
                  ? ""
                  : applicationDetail.Identification_2
              );

              //Replace Coborrower Details
              TemplData = TemplData.replace(
                /\[COBorrower_Name1\]/g,
                coborrower1Details ? coborrower1Details["Customer_Name"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Relation1\]/g,
                coborrower1Details
                  ? coborrower1Details["Customer_Relation"]
                  : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Address1\]/g,
                coborrower1Details ? coborrower1Details["Customer_Address"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_PhoneNo1\]/g,
                coborrower1Details ? coborrower1Details["Customer_PhoneNo"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Tehsil1\]/g,
                coborrower1Details ? coborrower1Details["Customer_Tehsil"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_District1\]/g,
                coborrower1Details
                  ? coborrower1Details["Customer_District"]
                  : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_State1\]/g,
                coborrower1Details ? coborrower1Details["Customer_State"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Pin_Code1\]/g,
                coborrower1Details
                  ? coborrower1Details["Customer_Pin_Code"]
                  : ""
              );

              //Replace Coborrower Details

              TemplData = TemplData.replace(
                /\[COBorrower_Name2\]/g,
                coborrower2Details ? coborrower2Details["Customer_Name"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Relation2\]/g,
                coborrower2Details
                  ? coborrower2Details["Customer_Relation"]
                  : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Address2\]/g,
                coborrower2Details ? coborrower2Details["Customer_Address"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_PhoneNo2\]/g,
                coborrower2Details ? coborrower2Details["Customer_PhoneNo"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Tehsil2\]/g,
                coborrower2Details ? coborrower2Details["Customer_Tehsil"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_District2\]/g,
                coborrower2Details
                  ? coborrower2Details["Customer_District"]
                  : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_State2\]/g,
                coborrower2Details ? coborrower2Details["Customer_State"] : ""
              );
              TemplData = TemplData.replace(
                /\[COBorrower_Pin_Code2\]/g,
                coborrower2Details
                  ? coborrower2Details["Customer_Pin_Code"]
                  : ""
              );

              //Replace Guaranter1
              TemplData = TemplData.replace(
                /\[Guarantor_Name1\]/g,
                guaranter1Details ? guaranter1Details["Customer_Name"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Relation1\]/g,
                guaranter1Details ? guaranter1Details["Customer_Relation"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Address1\]/g,
                guaranter1Details ? guaranter1Details["Customer_Address"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_PhoneNo1\]/g,
                guaranter1Details ? guaranter1Details["Customer_PhoneNo"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Tehsil1\]/g,
                guaranter1Details ? guaranter1Details["Customer_Tehsil"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_District1\]/g,
                guaranter1Details ? guaranter1Details["Customer_District"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_State1\]/g,
                guaranter1Details ? guaranter1Details["Customer_State"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Pin_Code1\]/g,
                guaranter1Details ? guaranter1Details["Customer_Pin_Code"] : ""
              );

              //Replace Guaranter2

              TemplData = TemplData.replace(
                /\[Guarantor_Name2\]/g,
                guaranter2Details ? guaranter2Details["Customer_Name"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Relation2\]/g,
                guaranter2Details ? guaranter2Details["Customer_Relation"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Address2\]/g,
                guaranter2Details ? guaranter2Details["Customer_Address"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_PhoneNo2\]/g,
                guaranter2Details ? guaranter2Details["Customer_PhoneNo"] : ""
              );
              this.templateData = this.templateData.replace(
                /\[Guarantor_Tehsil2\]/g,
                guaranter2Details ? guaranter2Details["Customer_Tehsil"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_District2\]/g,
                guaranter2Details ? guaranter2Details["Customer_District"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_State2\]/g,
                guaranter2Details ? guaranter2Details["Customer_State"] : ""
              );
              TemplData = TemplData.replace(
                /\[Guarantor_Pin_Code2\]/g,
                guaranter2Details ? guaranter2Details["Customer_Pin_Code"] : ""
              );

              TemplData = TemplData.replace(/\[LOD\]/g, "");

              //this.modalTemplateData = TemplData;
              this.modalTemplateData =
                this.sanitizer.bypassSecurityTrustHtml(TemplData);
              console.log("Model Template Data", this.modalTemplateData);

              $(`#ViewDemandNotice`).modal("show");
              $(`#ViewDemandNotice`).css("z-index", "1050");

              this.SelectedData.push({
                Loan_Id: x.Loan_Id,
                Customer_Id: x.CustomerId,
                Notice_Data: TemplData,
                Created_By: this.currentUser.userId,
                Notice_Type: this.TemplateId,
              });
              this.OnSaveLegalNotice();
              //const options = {
              //  filename: `${FileName}.pdf`,
              //  html2canvas: { scale: 2 },
              //  jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
              //};
              // this.exportAsPDF(FileName, TemplData);
              //  html2pdf(TemplData).from(TemplData).set(options).save();
            });
        }
      });
      console.log("this.SelectedData", this.SelectedData);

      this.showSpinner = false;
    }
  }

  OnSaveLegalNotice() {
    //let _data = {
    //  Legal_Notice: this.SelectedData[0],
    //};
    //console.log("_data", _data);
    this.SelectedData.forEach((x) => {
      this._LegalService
        .Save_Legal_Notice({
          Loan_Id: x.Loan_Id,
          Customer_Id: x.Customer_Id,
          Notice_Data: x.Notice_Data,
          Created_By: this.currentUser.userId,
          Notice_Type: this.TemplateId,
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            // this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    });
    this.OnCloseDemandNotice();
  }

  OnClickSendNotice(row) {
    this.LegalData = row;
    this.SendModal.Dispatched_Mode = "";
    this.SendModal.Agency = "";
    this.SendModal.Dispatched_Detail = "";
    this.SendModal.Tracking_No = "";
    this.SendModal.Dispached_Date = "";
    this.SendModal.Dispached_Remark = "";

    this.IsDisabled = false;
    this.SendLegalNoticeDetailByLoanId();
    $(`#SendNotice`).modal("show");
    $(`#SendNotice`).css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, this.FileName);
  }

  OnCloseModel() {
    $(`#SendNotice`).modal("hide");
    $(`#ViewDemandNotice`).modal("hide");
  }

  SendLegalNoticeDetailByLoanId() {
    this.displayedColumnsPrint = [
      "Select",
      "Loan_Id",
      "LoanAcNo",
      "LoanAmount",
      "CustomerName",
      "NoticeType",
      "CreatedBY",
      "CreatedOn",
      "Action",
      "Download",
    ];
    this.showSpinner = true;
    this._LegalService
      .Send_Legal_NoticeDetail_By_LoanId({
        LoanId: this.LegalData.Loan_Id,
        Status: this.Status,
        CustomerId: this.LegalData.Customer_Id,
      })
      .subscribe((res: any) => {
        //console.log("res", res);
        if (res.length > 0) {
          this.JsonDataPrint = res;

          this.JsonDataPrint.forEach((x) => {
            x.IsSelectedPrint = false;
          });

          this.dataSourcePrint = new MatTableDataSource(res);
          this.dataSourcePrint.sort = this.sort;
          this.dataSourcePrint.paginator = this.paginator;
        } else {
          this.dataSourcePrint = null;
        }
        this.showSpinner = false;
      });
  }

  checkUncheckAllPrint(event) {
    this.JsonDataPrint.forEach((x) => {
      if (event.checked == true) {
        x.IsSelectedPrint = true;
      }
      if (event.checked == false) {
        x.IsSelectedPrint = false;
      }
    });
  }

  LOS_GetCourierForDropdown() {
    this.losService.LOS_GetCourierForDropdown({}).subscribe((res: any) => {
      this.AgencyDropDown = res;
    });
  }

  OnSendLegalNotice() {
    this.SendModal.Sent_By = this.currentUser.userId;
    let Ids = this.JsonDataPrint.filter((x) => x.IsSelectedPrint == true)
      .map((element) => element.Id)
      .join(",");
    if (Ids == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please check atleast single row.",
        ...this.configSuccess,
      });
      return;
    }

    let _data = {
      Notice_Send: this.SendModal,
    };

    //console.log("_data", _data);
    this._LegalService
      .Send_OR_Delete_Legal_Notice({
        JSON: JSON.stringify(_data),
        DeletedId: Ids,
        Status: "Send",
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.GetLegalAssignList();
          this.OnCloseModel();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnDeleteLegalNotice(row) {
    this.dialog
      .openConfirmDialog(`Are you sure? you want to delete this.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this._LegalService
            .Send_OR_Delete_Legal_Notice({
              JSON: "",
              DeletedId: row.Id,
              Status: "Delete",
            })
            .subscribe((res: any) => {
              console.log("res", res);

              this.showSpinner = false;
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.SendLegalNoticeDetailByLoanId();
                this.GetLegalAssignList();
                // If delete all row from dataSourcePrint
                if (this.JsonDataPrint.length == 1) {
                  this.OnCloseModel();
                }
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  OnClickViewSendNotice(row) {
    this.IsDisabled = true;
    this.dataSourcePrint = null;
    this.GetLegalNoticeSendDetail(row.Id);

    $(`#SendNotice`).modal("show");
    $(`#SendNotice`).css("z-index", "1050");
  }

  GetLegalNoticeSendDetail(Id) {
    this._LegalService
      .Get_Legal_Notice_Send_Detail({ Id: Id })
      .subscribe((res: any) => {
        console.log("res", res);

        this.SendModal = res[0];
        const dateString = res[0].Sent_On;
        const [day, month, year] = dateString.split("-").map(Number);
        this.SendModal.Sent_On = new Date(year, month - 1, day); // month - 1 because Date months are 0-based
        this.SendModal.Dispached_Date = new Date(year, month - 1, day);
      });
  }

  pdfDownload(row) {
    var FileName =
      row.LoanAcNo.toString() +
      "_" +
      row.CustomerName +
      "_" +
      this.datePipe.transform(new Date(), "yyyy-MM-dd");
    html2pdf(row.Notice_Data).from(row.Notice_Data).save(`${FileName}.pdf`);
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "lms_waiver_list"
    ) as HTMLTableElement;
    const worksheetName = "WAIVER LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
