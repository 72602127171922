import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MobileService } from "../../Shared/app.Mobile.Service";
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MasterService } from '../../Shared/app.Masters.Service';
import { QuickLoanService } from '../services/quickLoan.service';
import { DialogService } from '../../Shared/dialog.service';
declare var $: any;
import { constantUrl } from '../../Shared/constantUrl';

@Component({
  selector: 'app-quickloan-family-member',
  templateUrl: './quickloan-family-member.component.html',
  styleUrls: ['./quickloan-family-member.component.scss']
})
export class QuickloanFamilyMemberComponent implements OnInit {
  showSpinner: boolean = false;
  ApplicationModel: any = {};
  customerRefModel: any = {};
  loading: boolean = false;
  currentUser: any;
  AddressType: any = 'Present';
  DivView: boolean = false;
  DataSource: any;
  isSaveCustomerMember: boolean = false;
  @Input() Revert_IsEdit: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'Id', 'MemberName', 'MemberReletion', 'MemberGenderAge', 'MemberAddress', 'MemberOccupationType', 'MemberContactNumber', 
    'MemberHowMuchEarn', 'Action'
  ];

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;

  CustomerId: any;
  customerData: any = {};
  FamilyData: any;

  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;

  constructor(
    private dataSharingService: DataSharingService,
    private _MobileService: MobileService,
    private LmsService: LmsService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private _QuickLoanService: QuickLoanService,
    private dialog: DialogService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LMS_GetLoanDetails();
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      console.log("LMS_GetLoanDetails", res);
      this.showSpinner = false;
      this.customerData = res.Item2;
      if(res.Item2[0]){
        this.OnClickFamilyMember(res.Item2[0]);
      }
    })
  }

  OnClickFamilyMember(row){
    console.log("row", row);
    
    this.CustomerId = row.CustomerId;
    this.GetCustomerFamilyMember();
  }

  GetCustomerFamilyMember(){
    this._MobileService.LOS_GetCustomerMemberApp({ CustomerId: this.CustomerId }).subscribe((res: any) => {
      console.log("Result123 : ", res);
      //this.customerRefModel = res;
      if(res.length > 0){
        this.FamilyData = res;
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.sort = this.sort;
        this.DataSource.paginator = this.paginator;
        this.showSpinner = false;
        this.DivView = true;
      }
      else{
        this.DataSource = [];
        this.DivView = false;
      }
    });
  }

  EditCustomerFamilyMember(row: any){
    this.customerRefModel = row;
    this.customerRefModel.Member_HowMuchEarn = row.MemberHowMuchEarn;
    console.log("this.customerRefModel : ", this.customerRefModel);
    this.isSaveCustomerMember = true;
    
    $('#FamilyMemberData').modal('show');
    $("#FamilyMemberData").css("z-index", "1050");
  }
  AddCustomerMember() {
    this.customerRefModel = {MemberReletion : ''};
    $('#FamilyMemberData').modal('show');
    $("#FamilyMemberData").css("z-index", "1050");
  }
  onCloseFamilyMemberData() {
    $('#FamilyMemberData').modal('hide');
  }

  onSaveCustomerFamilyMember(){
    this.loading = true;
    this.customerRefModel.CustomerId = this.CustomerId;
    this.customerRefModel.LoginUserId = this.currentUser.userId;
    
    var CustomerMember = {"CustomerMember": this.customerRefModel};
    //console.log("this.customerRefModel : ", CustomerMember);
    this._MobileService.LOS_SaveCustomerMemberApp({ JSON : JSON.stringify(CustomerMember) }).subscribe((response: any) => {
      console.log(response);
      if (response[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.QuickLoan_Save_PageProcess('Family Member');
        this.action.emit("next");
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
      this.onCloseFamilyMemberData();
      this.GetCustomerFamilyMember();
      this.loading = false;
    });
  }

  Delete_CustomerMember(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete this member.`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } 
      else {
        this.showSpinner = true;
        this.LmsService.Delete_CustomerMember({ Id: data.MemberId, CustomerId: data.CustomerId }).subscribe((res: any) => {
          this.showSpinner = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.GetCustomerFamilyMember();
            this.QuickLoan_Save_PageProcess('Family Member');
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        })
      }
    });
  }

  CheckCustomerFamilyMember() {
    this.action.emit("next");
    /*if (this.FamilyData && this.FamilyData.length > 0) {
      this.action.emit("next");
    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Family Member.", ...this.configSuccess });
    }*/
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
}
