<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="row m-0 formborder">
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        id="exportReport"
        style="max-width: 100%; overflow: auto; height: 55vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Case_Filed_By">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Case Filed By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Case_Filed_By }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Legal_On">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Legal On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Legal_On }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Last_Hearing_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Last Hearing Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Last_Hearing_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Next_Hearing_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Next Hearing Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Next_Hearing_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 60px"
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 60px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              aria-label="true"
              (click)="GoToDetails(row)"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div class="col-md-4">
          <button class="btn font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="HearingCase"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSaveLegalHearing()" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Legal Hearing
          </h6>
          <button
            type="button"
            (click)="OnCloseHearingModel()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-4 p-1">
              <span class="required-lable">Hearing Stage</span>
              <select
                name="Hearing_Stage"
                id="Hearing_Stage"
                #refHearing_Stage="ngModel"
                class="form-control input-text-css"
                required
                [(ngModel)]="HearingModal.Hearing_Stage"
                [ngClass]="{
                  'is-invalid': f.submitted && refHearing_Stage.invalid,
                  'alert-warning':
                    refHearing_Stage.invalid &&
                    (refHearing_Stage.dirty ||
                      refHearing_Stage.touched ||
                      refHearing_Stage.untouched)
                }"
              >
                <option value="">Select Hearing Stage</option>
                <option *ngFor="let row of LegalStageDropdown" [value]="row.Id">
                  {{ row.Value }}
                </option>
              </select>
            </div>
            <div class="col-md-4 p-1">
              <span class="required-lable">Status</span>
              <select
                name="Hearing_Status"
                id="Hearing_Status"
                #refHearing_Status="ngModel"
                class="form-control input-text-css"
                required
                [(ngModel)]="HearingModal.Hearing_Status"
                [ngClass]="{
                  'is-invalid': f.submitted && refHearing_Status.invalid,
                  'alert-warning':
                    refHearing_Status.invalid &&
                    (refHearing_Status.dirty ||
                      refHearing_Status.touched ||
                      refHearing_Status.untouched)
                }"
              >
                <option value="">Select Status</option>
                <option value="NextDate">Next Date</option>
                <option value="Revoke">Revoke</option>
                <option value="Settled">Settled</option>
              </select>
            </div>
            <div
              class="col-md-4 p-1"
              *ngIf="HearingModal.Hearing_Status == 'NextDate'"
            >
              <span class="required-lable">Next Hearing Date</span>
              <div class="input-group mb-3">
                <!-- <span class="input-group-text" id="basic-addon1"
                  ><i class="fa fa-calendar-day"></i
                ></span> -->

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Next_Hearing_Date"
                    id="Next_Hearing_Date"
                    #refNext_Hearing_Date="ngModel"
                    [(ngModel)]="HearingModal.Next_Hearing_Date"
                    class="form-control input-text-css"
                    required
                    [min]="minNextHearingDate"
                    [ngClass]="{
                      'is-invalid': f.submitted && refNext_Hearing_Date.invalid,
                      'alert-warning':
                        refNext_Hearing_Date.invalid &&
                        (refNext_Hearing_Date.dirty ||
                          refNext_Hearing_Date.touched ||
                          refNext_Hearing_Date.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>

                <!-- <input
                  name="Next_Hearing_Date"
                  id="Next_Hearing_Date"
                  #refNext_Hearing_Date="ngModel"
                  [(ngModel)]="HearingModal.Next_Hearing_Date"
                  class="form-control input-text-css"
                  value="{{
                    HearingModal.Next_Hearing_Date | date : 'dd/MM/yyyy'
                  }}"
                  [(bsValue)]="HearingModal.Next_Hearing_Date"
                  bsDatepicker
                  placeholder="Next Hearing Date"
                  required
                  [minDate]="CurrentDate"
                  [ngClass]="{
                    'is-invalid': f.submitted && refNext_Hearing_Date.invalid,
                    'alert-warning':
                      refNext_Hearing_Date.invalid &&
                      (refNext_Hearing_Date.dirty ||
                        refNext_Hearing_Date.touched ||
                        refNext_Hearing_Date.untouched)
                  }"
                /> -->
              </div>
            </div>
            <div class="col-md-4 p-1">
              <span class="required-lable">Session</span>
              <select
                name="Hearing_Session"
                id="Hearing_Session"
                #refHearing_Session="ngModel"
                class="form-control input-text-css"
                required
                [(ngModel)]="HearingModal.Hearing_Session"
                [ngClass]="{
                  'is-invalid': f.submitted && refHearing_Session.invalid,
                  'alert-warning':
                    refHearing_Session.invalid &&
                    (refHearing_Session.dirty ||
                      refHearing_Session.touched ||
                      refHearing_Session.untouched)
                }"
              >
                <option value="">Select Session</option>
                <option value="Morning">Morning</option>
                <option value="Evening">Evening</option>
              </select>
            </div>

            <div class="col-md-12 p-1">
              <span class="required-lable">Hearing Action</span>
              <textarea
                required
                rows="3"
                name="Hearing_Action"
                id="Hearing_Action"
                #refHearing_Action="ngModel"
                [(ngModel)]="HearingModal.Hearing_Action"
                placeholder="Hearing Action"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refHearing_Action.invalid,
                  'alert-warning':
                    refHearing_Action.invalid &&
                    (refHearing_Action.dirty ||
                      refHearing_Action.touched ||
                      refHearing_Action.untouched)
                }"
              ></textarea>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-12 p-1">
              <div class="table-responsive">
                <mat-table
                  [dataSource]="dataSourceHearing"
                  matSort
                  matTableExporter
                  id="exportReport"
                >
                  <ng-container matColumnDef="Hearing_Id">
                    <mat-header-cell
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 50px"
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="NextHearingDate">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Next Hearing Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.NextHearingDate }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Hearing_Session">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Hearing Session</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Hearing_Session }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Hearing_Stage">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Hearing Stage</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Hearing_Stage
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Hearing_Status">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Hearing Status</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Hearing_Status
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Hearing_Action">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Hearing Action</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Hearing_Action }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      style="max-width: 60px"
                      >Action</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 60px"
                    >
                      <i
                        class="fa fa-pencil-alt font-size-12 mr-2"
                        (click)="EditCaseHearing(row)"
                        style="cursor: pointer"
                        aria-label="true"
                        *ngIf="i == 0"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsHearing"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsHearing"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseHearingModel()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnSaveLegalHearing()"
            class="btn font-size-12 button-btn"
            *ngIf="IsSave"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="OnSaveLegalHearing()"
            class="btn font-size-12 button-btn"
            *ngIf="IsUpdate"
            [disabled]="!f.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="legal_hearingcases_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case Filed By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Legal On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Hearing Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Next Hearing Date
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.LoanAcNo }}</td>

    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Case_Filed_By }}</td>
    <td>{{ x.Legal_On }}</td>
    <td>{{ x.Last_Hearing_Date }}</td>
    <td>{{ x.Next_Hearing_Date }}</td>
  </tr>
</table>
