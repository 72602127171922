<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mb-3">
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="getNOCLetter()"
        [(ngModel)]="ProductId"
      >
        <option value="">Select Products</option>
        <option
          *ngFor="let product of productDropdown"
          [value]="product.ProductId"
        >
          {{ product.Product_Name }}
        </option>
      </select>
    </div>
  </div>
  <form #f="ngForm" (ngSubmit)="onSavetemplate()" novalidate *ngIf="ProductId">
    <div class="row m-0">
      <div class="col-md-10">
        <div class="container">
          <form [formGroup]="form">
            <angular-editor
              autofocus="true"
              id="editor2"
              formControlName="signature"
              [config]="config2"
              [(ngModel)]="templateData"
            ></angular-editor>
          </form>
        </div>
      </div>
      <div class="col-md-2">
        <p class="lead fs-14 mb-2">[Current_Date]</p>
        <p class="lead fs-14 mb-2">[Application_No]</p>
        <p class="lead fs-14 mb-2">[Customer_Name]</p>
        <p class="lead fs-14 mb-2">[Relation_Name]</p>
        <p class="lead fs-14 mb-2">[Customer_Address]</p>
        <p class="lead fs-14 mb-2">[Customer_PhoneNo]</p>
        <p class="lead fs-14 mb-2">[Loan_Product]</p>
        <p class="lead fs-14 mb-2">[Loan_Amount]</p>
        <p class="lead fs-14 mb-2">[Margin_Amount]</p>
        <p class="lead fs-14 mb-2">[Tenure_Month]</p>
        <p class="lead fs-14 mb-2">[No_Of_instalment]</p>
        <p class="lead fs-14 mb-2">[EMI_Amount]</p>
        <p class="lead fs-14 mb-2">[EMI_Advance]</p>
        <p class="lead fs-14 mb-2">[ROI]</p>
        <p class="lead fs-14 mb-2">[IRR]</p>
        <p class="lead fs-14 mb-2">[Date]</p>
        <p class="lead fs-14 mb-2">[Date_Of_EMI]</p>
        <p class="lead fs-14 mb-2">[Loan_No]</p>
        <p class="lead fs-14 mb-2">[Close_Date]</p>
        <p class="lead fs-14 mb-2">[Vehicle_No]</p>
        <p class="lead fs-14 mb-2">[Chassis_No]</p>
        <p class="lead fs-14 mb-2">[Engine_No]</p>
      </div>
    </div>
    <div class="text-right mb-2">
      <button
        type="button"
        (click)="onSavetemplate()"
        class="font-size-12 button-btn"
        [class.spinner]="loading"
        [disabled]="!f.form.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
