import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { LosService } from 'src/app/_LOS/services/los.service';
import { MasterService } from '../../Shared/app.Masters.Service';

@Component({
  selector: 'app-quickloan-inquiry-list',
  templateUrl: './quickloan-inquiry-list.component.html',
  styleUrls: ['./quickloan-inquiry-list.component.scss']
})
export class QuickLoanInquiryListComponent implements OnInit {
    currentUser: any; selectedRowIndex: number = -1;
    InquiryList: any[] = [];
    showSpinner: boolean = false;
    status: any = 'Pending';
    dataSource: any;
    displayedColumns: string[] = [];
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    assignModel: any = {};
    loading: boolean = false;
    branchDropdown: any[] = [];
    employeeDropdown: any[] = [];
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };
  rejectModel: any = {};
  ProductList: any[] = [];
  constructor(private MasterService: MasterService, private dataSharingService: DataSharingService, private snackBar: MatSnackBar, private router: Router, private losService: LosService) { }

    ngOnInit() {
        this.dataSharingService.LOSHeaderTitle.next('Inquiry List');
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.getInquiryList();
    }
    highlight(row) {
        this.selectedRowIndex = row.InquiryId;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getInquiryList() {
        this.showSpinner = true;
        if (this.status == 'Assigned') {
            this.displayedColumns = ['InquiryId', 'InquiryNo', 'CustomerName', 'CustomerAddress', 'ContactNumber', 'LoanAmount', 'Purpose', 'Source', 'TAT', 'CreateOn', 'Branch', 'SaleExcutive'];
        }
        else if (this.status == 'Rejected') {
            this.displayedColumns = ['InquiryId', 'InquiryNo', 'CustomerName', 'CustomerAddress', 'ContactNumber', 'LoanAmount', 'Purpose', 'Source', 'TAT', 'CreateOn', 'RejectedBy', 'RejectionReason'];
        }
        else if (this.status == 'Pending') {
          this.displayedColumns = ['InquiryId', 'InquiryNo', 'CustomerName', 'CustomerAddress', 'ContactNumber', 'LoanAmount', 'Purpose', 'Source', 'TAT', 'Status', 'Remarks', 'CreateOn','CreatedBy', 'Assign', 'Reject'];
        }
        else {
          this.displayedColumns = ['InquiryId', 'InquiryNo', 'CustomerName', 'CustomerAddress', 'ContactNumber', 'LoanAmount', 'Purpose', 'Source', 'TAT', 'CreateOn','CreatedBy'];
        }
        this.losService.GetLosInquiryList({ InqStatus: this.status,LoginUserId:this.currentUser.userId }).subscribe((res: any) => {
          this.InquiryList = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(this.InquiryList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.showSpinner = false;
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip()
            }, 1000)
        })
    }

    OnOpenAssignModal(data: any, e: any) {
        if (e) {
            e.stopPropagation();
      }
      this.assignModel = {};
        this.assignModel.InquiryId = data.InquiryId;
        this.assignModel.LoginUserId = this.currentUser.userId;
        this.assignModel.InquiryNo = data.InquiryNo;
      this.assignModel.BranchId = "";
      this.assignModel.BranchId = "";
      this.assignModel.Product_Id = "";
      this.getBranchDropdown();
      this.getProductDropdown();
        $('#assignToInquiry').modal('show');
        $("#assignToInquiry").css("z-index", "1050");
    }
    OnClose() {
        
        $('#assignToInquiry').modal('hide');
    }
    onOpenRejectModal(data: any, e: any) {
        e.stopPropagation();
        this.rejectModel.InquiryId = data.InquiryId;
        this.rejectModel.LoginUserId = this.currentUser.userId;
        this.rejectModel.Reason = '';
        $('#rejectInquiry').modal('show');
        $("#rejectInquiry").css("z-index", "1050");
    }
    OnCloseReject() {
        this.rejectModel = {};
        $('#rejectInquiry').modal('hide');
    }

    getBranchDropdown() {
      this.losService.getBranchDropdown({ DistrictId: 0, ZoneId: 0, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            this.branchDropdown = res;
        })
    }
    getEmployeeDropdown() {
        this.assignModel.AssignedTo = "";
        this.employeeDropdown = [];
        if (this.assignModel.BranchId) {
          this.losService.getEmployeeDropdown({ BranchId: this.assignModel.BranchId, ProcessId: 1, ProductId: this.assignModel.Product_Id }).subscribe((res: any) => {
                this.employeeDropdown = res;
                console.log('res', res);
            })
        }
    }

    onSaveAssignment() {
        this.loading = true;
      //  console.log(this.assignModel.AssignedTo);
      this.OnClose();
        this.losService.assignToInquiry(this.assignModel).subscribe((res: any) => {
          this.loading = false;
          
            if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                this.losService.LOS_EmployeeMobileDeviceID({ EmployeeID: this.assignModel.AssignedTo }).subscribe((res: any) => {
                    console.log(res);

                  this.OnClose();
                  this.getInquiryList();
                    for (var i = 0; i < res.length; i++) {
                        this.assignModel.deviceID = res[i].MobileId;
                        this.assignModel.Notificationmessage = "New Leads has been assigned to you";
                        this.assignModel.type = "1";
                        console.log(this.assignModel);
                        this.losService.sendFCMnotification(this.assignModel).subscribe((res: any) => {
                            this.getInquiryList();
                          
                        })
                    }
                })

            } else {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
        })
    }
    onRejectInquiry() {
        this.loading = true;
        this.losService.rejectInquiry(this.rejectModel).subscribe((res: any) => {
            this.loading = false;
            if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                this.getInquiryList();
                this.OnCloseReject();
            } else {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
        })
    }
    exportTable() : void {
        const uri = 'data:application/vnd.ms-excel;base64,';
        const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
        const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
        const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
      
        const table = document.getElementById("quickloan_inquiry_list") as HTMLTableElement;
        const worksheetName = 'QUICK LOAN INQUIRY LIST'; // Set the desired worksheet name here
        const ctx = { worksheet: worksheetName, table: table.innerHTML };
        const downloadLink = document.createElement("a");
      
        // Generate the data URI for the Excel file
        downloadLink.href = uri + base64(format(template, ctx));
      
        // Set the file name for the download
        downloadLink.download = worksheetName + ".xls";
      
        // Trigger the download
        downloadLink.click();
        
      }
  getProductDropdown() {
    this.MasterService.GetProductList({ Emp_Id: this.currentUser.userId }).subscribe((result: any) => {
      this.ProductList = JSON.parse(JSON.stringify(result));

    });
  }

}
