import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;

@Component({
  selector: "app-enach-generated-list",
  templateUrl: "./enach-generated-list.component.html",
  styleUrls: ["./enach-generated-list.component.scss"],
})
export class EnachGeneratedListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dataSource: any;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  displayedColumns: string[] = [];
  FileCheckingList: any[] = [];
  loading: boolean = false;
  SearchModel: any = {};

  BankList: any;
  BranchesList: any = [];
  applicationDetail: any;
  EMIFrom: any;
  EMITo: any;
  EMIFromDB: any;
  LoanId: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("ENACH Generation");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumnsLoan = [
      "Id",
      "LoanAcNo",
      "Loan_date",
      "CustomerName",
      "Bankname",
      "Branch",
      "AccType",
      "Ac",
      "status",
      "PresentBankInCompanyName",
      "PresentOnCompanyBank",
      "PendingDays",
      "EMIFrom",
      "EMITo",
      "Action",
    ];

    this.SearchModel.Status = "";
    this.SearchModel.CompanyBank = "";
    this.SearchModel.CompanyBranch = "";
    this.getBankDropdown();
    this.getBranchesList();
  }

  getBankDropdown() {
    this.lmsService
      .Get_Account_Master_By_NACH_Enable({ TagName: "Bank" })
      .subscribe((res: any) => {
        this.BankList = JSON.parse(JSON.stringify(res));
      });
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  onSearchNachDetail() {
    this.showSpinner = true;
    this.dataSourceLoan = null;
    let _data = {
      Search: this.SearchModel,
    };
    //console.log("_data : ",_data);
    this.lmsService
      .ENach_Get_NachInfo({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        //console.log("res : ",res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.dataSourceLoan = null;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  onExport(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `ENACH-Genartaion-List`,
      sheet: "ENACH-Genartaion-List",
    });
  }

  goToDetail(data: any) {
    //console.log("data", data);
    this.applicationDetail = data;
    this.LoanId = data.Loan_Id;
    this.LMS_GET_NACH_EMI_DETAIL();
    $("#NachDetailModel").modal("show");
    $("#NachDetailModel").css("z-index", "1050");
  }

  onCloseNachDetail() {
    $("#NachDetailModel").modal("hide");
  }

  LMS_GET_NACH_EMI_DETAIL() {
    this.lmsService
      .LMS_GET_NACH_EMI_DETAI({ StatusDate: new Date(), Loan_Id: this.LoanId })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          if (res[0].CODE == 0) {
            this.EMIFrom = res[0].EMI;
            this.EMITo = res[res.length - 1].EMI;
            this.EMIFromDB = res[0].EMI;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.EMIFrom = 0;
            this.EMITo = 0;
          }
        }
      });
  }

  EMIFromChange() {
    //console.log("this.EMIFrom", this.EMIFrom, this.EMIFromDB);
    if (Number(this.EMIFrom) < Number(this.EMIFromDB)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMIFrom greater or equal to " + this.EMIFromDB + ".",
        ...this.configSuccess,
      });
      this.EMIFrom = this.EMIFromDB;
    } else if (Number(this.EMIFrom) > Number(this.EMITo)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMIFrom less or equal to " + this.EMITo + ".",
        ...this.configSuccess,
      });
      this.EMIFrom = this.EMIFromDB;
    }
  }

  onSaveNachDetail() {
    this._MasterService
      .ENach_Save_NachGenerate({
        Loan_Id: this.LoanId,
        EMIFrom: this.EMIFrom,
        EMITo: this.EMITo,
        CreateBy: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseNachDetail();
          this.onSearchNachDetail();
        }
      });
  }
}
