<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchPartnerBalance()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            required
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher From"
          (ngModelChange)="DateChange()"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            [min]="minDate"
            [max]="maxDate"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            required
            #refVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateTo.invalid,
              'alert-warning':
                refVoucherDateTo.invalid &&
                (refVoucherDateTo.dirty ||
                  refVoucherDateTo.touched ||
                  refVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          (ngModelChange)="DateChange()"
          id="VoucherDateTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher To"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateTo.invalid,
            'alert-warning':
              refVoucherDateTo.invalid &&
              (refVoucherDateTo.dirty ||
                refVoucherDateTo.touched ||
                refVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> Partner type </span>
        <select
          name="Partner_Type"
          id="Partner_Type"
          #refPartner_Type="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refPartner_Type.invalid,
            'alert-warning':
              refPartner_Type.invalid &&
              (refPartner_Type.dirty ||
                refPartner_Type.touched ||
                refPartner_Type.untouched)
          }"
          [(ngModel)]="Partner_Type"
          class="form-control input-text-css"
          required
        >
          <option value="">Select Partner type</option>
          <option value="DSA">DSA</option>
          <option value="Agent">Agent</option>
          <option value="Dealer">Dealer</option>
          <option value="Advocate">Advocate</option>
          <option value="Valuer">Valuer</option>
          <option value="Seizer">Seizer</option>
          <option value="Collection">Collection Agency</option>
        </select>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchPartnerBalance()"
          [disabled]="!df.form.valid"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>

      <div class="col-md-3 p-1 text-right">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!DataSource">
    <div class="">
      <div class="table-responsive" style="overflow: auto">
        <table
          class="table-bordered"
          mat-table
          matSort
          [dataSource]="DataSource"
        >
          <ng-container matColumnDef="AccountId">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ i + 1 }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Account_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Account Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Account_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OpeningBalance">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Opening Balance
            </th>
            <td mat-cell *matCellDef="let row">{{ row.OpeningBalance }}</td>
            <th mat-footer-cell *matFooterCellDef>
              {{ calculateOPBalanceTotal() }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              TranType DR
            </th>
            <td mat-cell *matCellDef="let row">{{ row.TranTypeDR }}</td>
            <th mat-footer-cell *matFooterCellDef>
              {{ calculateTranTypeDRTotal() }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              TranType CR
            </th>
            <td mat-cell *matCellDef="let row">{{ row.TranTypeCR }}</td>
            <th mat-footer-cell *matFooterCellDef>
              {{ calculateTranTypeCRTotal() }}
            </th>
          </ng-container>

          <ng-container matColumnDef="ClosingBalance">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Closing Balance
            </th>
            <td mat-cell *matCellDef="let row">{{ row.ClosingBalance }}</td>
            <th mat-footer-cell *matFooterCellDef>
              {{ calculateCLBalanceTotal() }}
            </th>
          </ng-container>

          <ng-container matColumnDef="ViewAction">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              View Action
            </th>
            <td mat-cell *matCellDef="let row">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </td>
            <th mat-footer-cell *matFooterCellDef>
              {{ calculateCLBalanceTotal() }}
            </th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AccountingDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Staff Balance
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseAccountingDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-0 mb-2">
          <div class="col-md-3 p-1">
            <select
              name="BalanceType"
              id="BalanceType"
              class="form-control input-text-css"
              [(ngModel)]="BalanceType"
              (change)="ChangeBalanceType()"
            >
              <option [value]="0">Annually</option>
              <option [value]="1">Monthly</option>
              <option [value]="2">Daily</option>
            </select>
          </div>
        </div>
        <div
          class="table-responsive"
          [hidden]="!DivAnnualy"
          style="max-width: 100%; overflow: auto"
        >
          <table
            class="table-bordered"
            mat-table
            matSort
            [dataSource]="dataSourceAccount"
          >
            <ng-container matColumnDef="voucherid">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_Date">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher Date
              </th>
              <td mat-cell *matCellDef="let row">{{ row.VoucherDate }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="Voucher_No">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Voucher No
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Voucher_No }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="CaseNo">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
              <td mat-cell *matCellDef="let row">{{ row.CaseNo }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                A/C Head
              </th>
              <td mat-cell *matCellDef="let row">{{ row.accHead }}</td>
              <th mat-footer-cell *matFooterCellDef>Total</th>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                DR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeDR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeDR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                CR Amount
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeCR }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeCR() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <th
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
                class="text-right"
              >
                Balance
              </th>
              <td mat-cell *matCellDef="let row" class="text-right">
                {{ row.TranTypeTotal }}
              </td>
              <th mat-footer-cell *matFooterCellDef class="text-right">
                {{ dataSourceAccount ? calculateACTranTypeTotal() : "" }}
              </th>
            </ng-container>

            <ng-container matColumnDef="Narration">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                Narration
              </th>
              <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="ViewAction">
              <th
                mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                View Detail
              </th>
              <td
                mat-cell
                *matCellDef="let row; let i = index"
                class="text-center"
              >
                <div *ngIf="row.accHead == 'Opening Balance'; else elseBlock">
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <app-lms-voucher-view-model
                    [voucherid]="row.voucherid"
                    (close)="GetData($event)"
                    [index]="i"
                  >
                    <i
                      class="fa fa-arrow-circle-right arrow-circle action-btn"
                      aria-label="true"
                    ></i>
                  </app-lms-voucher-view-model>
                </ng-template>
              </td>
              <th mat-footer-cell *matFooterCellDef></th>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedAccountColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              [ngClass]="{ Row_Bold: row.accHead == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedAccountColumns"
            ></tr>
            <tr
              mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedAccountColumns; sticky: true"
            ></tr>
          </table>
        </div>
        <div
          class="row m-0 pt-2 pb-1"
          style="background: #fff"
          [hidden]="!DivAnnualy"
        >
          <div class="col-md-12 text-right">
            <!-- <div *ngIf="dataSourceAccount">
              <div *ngIf="dataSourceAccount.filteredData.length >= TotRow">
                <button class="btn btn-success font-size-12" (click)="LoadMore()" [class.spinner]="loading" [disabled]="loading">
                  <i class="fa fa-spinner mr-1" style="font-size:medium;cursor: pointer;"></i> Load More
                </button>
              </div>
            </div> -->
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>

        <div class="table-responsive" [hidden]="!DivMonthly">
          <mat-table
            [dataSource]="dataSourceMonthly"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Month_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Month Year</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Month_Year }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accHead">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Account Head</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.accHead }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? totaltypeMonthly("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceMonthly ? calculateMonthlyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                    (click)="goToDetailModel(row)"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsMonthly; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsMonthly"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsMonthly; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>

        <div class="table-responsive" [hidden]="!DivDaily">
          <mat-table
            [dataSource]="dataSourceDaily"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 60px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 60px; max-height: 5px"
              >
                <!-- {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 60px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Account_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Account Head
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Account_Name }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="VoucherDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Voucher Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Voucher_Date | date }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >DR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeDR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? totaltypeDaily("TranTypeDR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >CR Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeCR }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? totaltypeDaily("TranTypeCR") : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Balance</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TranTypeTotal }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                {{ dataSourceDaily ? calculateDailyBalance() : "" }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px; justify-content: center"
                *matHeaderCellDef
              >
                View Detail
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell text-center"
                style="max-width: 100px"
              >
                <div
                  *ngIf="row.Account_Name == 'Opening Balance'; else elseBlock"
                >
                  &nbsp;
                </div>
                <ng-template #elseBlock>
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                    (click)="goToDetailModel(row)"
                    aria-label="true"
                  ></i>
                </ng-template>
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 100px"
              ></mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsDaily; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ Row_Bold: row.Account_Name == 'Opening Balance' }"
              *matRowDef="let row; columns: displayedColumnsDaily"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsDaily; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="partner_balance"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TranType DR
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TranType CR
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closing Balance
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.OpeningBalance }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>

    <td>{{ x.ClosingBalance }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        {{ calculateOPBalanceTotal() }}
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTranTypeDRTotal() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ calculateTranTypeCRTotal() }} </strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateCLBalanceTotal() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
