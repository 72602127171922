<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #dbf="ngForm" (ngSubmit)="onSaveNewProduct()" novalidate>
  <div class="row m-0 mt-3">
    <div class="col-md-4 p-1">
      <span class="required-lable"> Product Category : </span>
      <select
        name="ProductCategory"
        id="ProductCategory"
        #refProductCategory="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refProductCategory.invalid,
          'alert-warning':
            refProductCategory.invalid &&
            (refProductCategory.dirty ||
              refProductCategory.touched ||
              refProductCategory.untouched)
        }"
        [(ngModel)]="ProductModel.ProductCategory"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Category</option>
        <option value="FD">Fixed Deposit (FD)</option>
        <option value="RD">Recurring Deposit (RD)</option>
      </select>
    </div>
    <div class="col-md-4 p-1">
      <span class="required-lable"> Product Name : </span>
      <input
        required
        type="text"
        #refProductName="ngModel"
        name="ProductName"
        id="ProductName"
        placeholder="Product Name"
        [(ngModel)]="ProductModel.ProductName"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refProductName.invalid,
          'alert-warning':
            refProductName.invalid &&
            (refProductName.dirty ||
              refProductName.touched ||
              refProductName.untouched)
        }"
      />
    </div>
    <div class="col-md-4 p-1">
      <span class="required-lable"> Product Short Name : </span>
      <input
        required
        type="text"
        #refProductShortName="ngModel"
        name="ProductShortName"
        id="ProductShortName"
        [(ngModel)]="ProductModel.ProductShortName"
        placeholder="Product Short Name"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refProductShortName.invalid,
          'alert-warning':
            refProductShortName.invalid &&
            (refProductShortName.dirty ||
              refProductShortName.touched ||
              refProductShortName.untouched)
        }"
      />
    </div>
  </div>

  <div class="row m-0 mt-2 p-1">
    <div class="col-md-12 p-1 bg-success1">Scheme Configuration:</div>
  </div>
  <div class="row m-0">
    <div class="col-md-2 p-1">
      <span class="required-lable">FD Minimum Amount</span>
      <input
        required
        type="text"
        #refAmount_Min="ngModel"
        name="Amount_Min"
        id="Amount_Min"
        numbersOnly
        [(ngModel)]="ProductModel.Amount_Min"
        placeholder="Fixed Deposit Minimum Amount"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refAmount_Min.invalid,
          'alert-warning':
            refAmount_Min.invalid &&
            (refAmount_Min.dirty ||
              refAmount_Min.touched ||
              refAmount_Min.untouched)
        }"
      />
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable">FD Maximum Amount</span>
      <input
        required
        type="text"
        #refAmount_Max="ngModel"
        name="Amount_Max"
        id="Amount_Max"
        numbersOnly
        [(ngModel)]="ProductModel.Amount_Max"
        placeholder="Fixed Deposit Maximum Amount"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refAmount_Max.invalid,
          'alert-warning':
            refAmount_Max.invalid &&
            (refAmount_Max.dirty ||
              refAmount_Max.touched ||
              refAmount_Max.untouched)
        }"
      />
    </div>

    <div class="col-md-2 p-1">
      <span class="required-lable">Tenure Minimum</span>
      <input
        required
        type="text"
        #refTenure_Min="ngModel"
        name="Tenure_Min"
        id="Tenure_Min"
        numbersOnly
        [(ngModel)]="ProductModel.Tenure_Min"
        placeholder="Tenure Minimum"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refTenure_Min.invalid,
          'alert-warning':
            refTenure_Min.invalid &&
            (refTenure_Min.dirty ||
              refTenure_Min.touched ||
              refTenure_Min.untouched)
        }"
      />
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable">Tenure Maximum</span>
      <input
        required
        type="text"
        #refTenure_Max="ngModel"
        name="Tenure_Max"
        id="Tenure_Max"
        numbersOnly
        (change)="onChangeTenureMax()"
        [(ngModel)]="ProductModel.Tenure_Max"
        placeholder="Tenure Maximum"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refTenure_Max.invalid,
          'alert-warning':
            refTenure_Max.invalid &&
            (refTenure_Max.dirty ||
              refTenure_Max.touched ||
              refTenure_Max.untouched)
        }"
      />
    </div>

    <div class="col-md-2 p-1">
      <span class="required-lable"> Intrest Type</span>
      <select
        name="Interest_Type"
        id="Interest_Type"
        #refInterest_Type="ngModel"
        (change)="onChangeIntrestType()"
        [ngClass]="{
          'is-invalid': dbf.submitted && refInterest_Type.invalid,
          'alert-warning':
            refInterest_Type.invalid &&
            (refInterest_Type.dirty ||
              refInterest_Type.touched ||
              refInterest_Type.untouched)
        }"
        [(ngModel)]="ProductModel.Interest_Type"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Category</option>
        <option value="Simple">Simple</option>
        <option value="Cumulative">Cumulative</option>
      </select>
    </div>

    <div
      class="col-md-2 p-1"
      *ngIf="ProductModel.Interest_Type == 'Cumulative'"
    >
      <span class="required-lable">Cummulative Frequency</span>
      <select
        name="Cummulative_Frequency"
        id="Cummulative_Frequency"
        #refCummulative_Frequency="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refCummulative_Frequency.invalid,
          'alert-warning':
            refCummulative_Frequency.invalid &&
            (refCummulative_Frequency.dirty ||
              refCummulative_Frequency.touched ||
              refCummulative_Frequency.untouched)
        }"
        [(ngModel)]="ProductModel.Cummulative_Frequency"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Category</option>
        <option value="Monthly">Monthly</option>
        <option value="Quarterly">Quarterly</option>
        <option value="HalfYearly">Half-Yearly</option>
        <option value="Yearly">Yearly</option>
      </select>
    </div>

    <div class="col-md-2 p-1">
      <span class="required-lable">Payment Frequency</span>
      <select
        name="Payment_Frequency"
        id="Payment_Frequency"
        #refPayment_Frequency="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refPayment_Frequency.invalid,
          'alert-warning':
            refPayment_Frequency.invalid &&
            (refPayment_Frequency.dirty ||
              refPayment_Frequency.touched ||
              refPayment_Frequency.untouched)
        }"
        [(ngModel)]="ProductModel.Payment_Frequency"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Category</option>
        <option
          value="Monthly"
          [hidden]="
            ProductModel.Interest_Type == 'Cumulative' &&
            (ProductModel.Cummulative_Frequency == 'Monthly' ||
              ProductModel.Cummulative_Frequency == 'Quarterly' ||
              ProductModel.Cummulative_Frequency == 'HalfYearly' ||
              ProductModel.Cummulative_Frequency == 'Yearly')
          "
        >
          Monthly
        </option>
        <option
          value="Quarterly"
          [hidden]="
            (ProductModel.Interest_Type == 'Cumulative' &&
              ProductModel.Cummulative_Frequency == 'Quarterly') ||
            ProductModel.Cummulative_Frequency == 'HalfYearly' ||
            ProductModel.Cummulative_Frequency == 'Yearly'
          "
        >
          Quarterly
        </option>
        <option
          value="HalfYearly"
          [hidden]="
            (ProductModel.Interest_Type == 'Cumulative' &&
              ProductModel.Cummulative_Frequency == 'HalfYearly') ||
            ProductModel.Cummulative_Frequency == 'Yearly'
          "
        >
          Half-Yearly
        </option>
        <option
          value="Yearly"
          [hidden]="
            ProductModel.Interest_Type == 'Cumulative' &&
            ProductModel.Cummulative_Frequency == 'Yearly'
          "
        >
          Yearly
        </option>
        <option value="OnMaturity">On Maturity</option>
      </select>
    </div>

    <div class="col-md-2 p-1">
      <span class="required-lable">Product Start Date</span>
      
<div class="datepicker_feild">
  <input
    [matDatepicker]="picker"
    dateConvert
    placeholder="DD/MM/YYYY"
    name="Start_Date"
    id="Start_Date"
    [(ngModel)]="ProductModel.Start_Date"
    
    #refStart_Date="ngModel"
        required
        [(ngModel)]="ProductModel.Start_Date"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refStart_Date.invalid,
          'alert-warning':
            refStart_Date.invalid &&
            (refStart_Date.dirty ||
              refStart_Date.touched ||
              refStart_Date.untouched)
        }"
    
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
      <!-- <input
        name="Start_Date"
        id="Start_Date"
        [(ngModel)]="ProductModel.Start_Date"
        placeholder="Product Start Date"
        bsDatepicker
        class="form-control input-text-css"
        #refStart_Date="ngModel"
        required
        [(ngModel)]="ProductModel.Start_Date"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refStart_Date.invalid,
          'alert-warning':
            refStart_Date.invalid &&
            (refStart_Date.dirty ||
              refStart_Date.touched ||
              refStart_Date.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable">Product End Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="End_Date"
        id="End_Date"
        [(ngModel)]="ProductModel.End_Date"
          class="form-control input-text-css"
          #refEnd_Date="ngModel"
          
        />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="End_Date"
        id="End_Date"
        [(ngModel)]="ProductModel.End_Date"
        placeholder="Product End Date"
        bsDatepicker
        class="form-control input-text-css"
        #refEnd_Date="ngModel"
        [(ngModel)]="ProductModel.End_Date"
      /> -->
    </div>
    <div class="col-md-2 mt-4 p-1">
      <div class="form-check">
        <input
          type="checkbox"
          name="IsPreMature"
          class="form-check-input"
          id="IsPreMature"
          (change)="onChangePreMature()"
          [(ngModel)]="ProductModel.IsPreMature"
        />
        <label class="form-check-label" for="IsPreMature"
          >Premature Allow</label
        >
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable">Lockin Period</span>
      <input
        required
        type="text"
        name="Lockin_Period"
        id="Lockin_Period"
        numbersOnly
        [disabled]="ProductModel.IsPreMature != true"
        [(ngModel)]="ProductModel.Lockin_Period"
        placeholder="Lockin Period"
        class="form-control input-text-css"
        #refLockin_Period="ngModel"
        (change)="onChangeLockinPeriod()"
        [ngClass]="{
          'is-invalid': dbf.submitted && refLockin_Period.invalid,
          'alert-warning':
            refLockin_Period.invalid &&
            (refLockin_Period.dirty ||
              refLockin_Period.touched ||
              refLockin_Period.untouched)
        }"
      />
    </div>
  </div>

  <ng-container
    *ngIf="ProductModel.Tenure_Max != '' && ProductModel.Tenure_Max != null"
  >
    <div class="row m-0 mt-2 p-1">
      <div class="col-md-12 p-1 bg-success1">Rate of Intrest:</div>
    </div>

    <div
      class="row m-0 p-0"
      *ngFor="let Intrest of Product_SchemeModel; let i = index"
    >
      <div class="col-md-2 p-1">
        <span class="required-lable" *ngIf="i == 0">From Month</span>
        <input
          type="number"
          [(ngModel)]="Intrest.From_Month"
          placeholder="From Month"
          numbersOnly
          name="From_Month{{ i }}"
          id="From_Month{{ i }}"
          class="form-control input-text-css"
          required
          #refFrom_Month="ngModel"
          disabled
          [ngClass]="{
            'is-invalid': dbf.submitted && refFrom_Month.invalid,
            'alert-warning':
              refFrom_Month.invalid &&
              (refFrom_Month.dirty ||
                refFrom_Month.touched ||
                refFrom_Month.untouched)
          }"
        />
      </div>
      <div class="col-md-2 p-1">
        <span class="required-lable" *ngIf="i == 0">To Month</span>
        <input
          type="number"
          [(ngModel)]="Intrest.To_Month"
          placeholder="To Month"
          numbersOnly
          name="To_Month{{ i }}"
          id="To_Month{{ i }}"
          (change)="ToValueChange(i)"
          class="form-control input-text-css"
          required
          #refTo_Month="ngModel"
          [ngClass]="{
            'is-invalid': dbf.submitted && refTo_Month.invalid,
            'alert-warning':
              refTo_Month.invalid &&
              (refTo_Month.dirty ||
                refTo_Month.touched ||
                refTo_Month.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable" *ngIf="i == 0"
          >Rate of Intrest % (Standard)</span
        >
        <input
          type="number"
          [(ngModel)]="Intrest.Rate_Of_Interest"
          placeholder="Rate Of Interest"
          name="Rate_Of_Interest{{ i }}"
          id="Rate_Of_Interest{{ i }}"
          class="form-control input-text-css"
          required
          #refRate_Of_Interest="ngModel"
          [ngClass]="{
            'is-invalid': dbf.submitted && refRate_Of_Interest.invalid,
            'alert-warning':
              refRate_Of_Interest.invalid &&
              (refRate_Of_Interest.dirty ||
                refRate_Of_Interest.touched ||
                refRate_Of_Interest.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable" *ngIf="i == 0"
          >Rate of Intrest % (Senior Citizen)</span
        >
        <input
          type="number"
          [(ngModel)]="Intrest.Rate_Of_Interest_SC"
          placeholder="Rate Of Interest"
          name="Rate_Of_Interest_SC{{ i }}"
          id="Rate_Of_Interest_SC{{ i }}"
          class="form-control input-text-css"
          required
          #refRate_Of_Interest_SC="ngModel"
          [ngClass]="{
            'is-invalid': dbf.submitted && refRate_Of_Interest_SC.invalid,
            'alert-warning':
              refRate_Of_Interest_SC.invalid &&
              (refRate_Of_Interest_SC.dirty ||
                refRate_Of_Interest_SC.touched ||
                refRate_Of_Interest_SC.untouched)
          }"
        />
      </div>
      <div class="col-md-2 p-1">
        <button
          type="button"
          class="remove"
          (click)="removeData(i)"
          [ngClass]="{ 'no-head': i != 0 }"
          *ngIf="Product_SchemeModel.length > 1"
        >
          -
        </button>
        <button
          type="button"
          class="addmore"
          (click)="addMoreData(i)"
          [ngClass]="{
            both: Product_SchemeModel.length > 1,
            'no-head': i != 0
          }"
          *ngIf="i == Product_SchemeModel.length - 1"
        >
          +
        </button>
      </div>
    </div>
  </ng-container>

  <div class="row m-0">
    <div class="col-md-12 p-1"><hr /></div>
  </div>

  <div class="row m-0">
    <div class="col-md-12 p-1 text-right">
      <button
        type="button"
        (click)="onSaveNewProduct()"
        *ngIf="IsSave"
        mat-raised-button
        [class.spinner]="loading"
        class="btn_default"
        [disabled]="!dbf.form.valid"
      >
        Save
      </button>
      <button
        type="button"
        (click)="onSaveNewProduct()"
        *ngIf="IsUpdate"
        mat-raised-button
        [class.spinner]="loading"
        class="btn_default"
        [disabled]="!dbf.form.valid"
      >
        Update
      </button>
    </div>
  </div>
</form>
