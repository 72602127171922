<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keydown.enter)="searchQuickloanApplicationStatus()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateFrom"
          [(ngModel)]="DateFrom"
          id="DateFrom"
          class="form-control input-text-css"
          required
          #refDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateFrom.invalid,
            'alert-warning':
              refDateFrom.invalid &&
              (refDateFrom.dirty ||
                refDateFrom.touched ||
                refDateFrom.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateTo"
          [(ngModel)]="DateTo"
          id="DateTo"
          class="form-control input-text-css"
          required
          #refDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateTo.invalid,
            'alert-warning':
              refDateTo.invalid &&
              (refDateTo.dirty || refDateTo.touched || refDateTo.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="searchQuickloanApplicationStatus()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="float-end col-md-4 mt-2" [hidden]="!dataSource">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div [hidden]="!dataSource" class="mt-3">
  <div class="table-responsive" style="overflow: auto; height: 65vh">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="loanId">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="branchName">
        <th mat-header-cell *matHeaderCellDef>Branch Name</th>
        <td mat-cell *matCellDef="let element">{{ element.branchName }}</td>
      </ng-container>

      <ng-container matColumnDef="customerFullName">
        <th mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerFullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product Name</th>
        <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
      </ng-container>

      <ng-container
        *ngFor="let dynamicColumn of dynamicColumns"
        [matColumnDef]="dynamicColumn"
      >
        <th mat-header-cell *matHeaderCellDef>{{ dynamicColumn }}</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="text-align: left !important"
        >
          {{ element[dynamicColumn] }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="highlightRow(row)"
        [class.highlight]="row.loanId == selectedId"
      ></tr>
    </table>
  </div>
</div>

<div class="row m-0 ml-3" [hidden]="!dataSource">
  <div class="col-md-4 mt-3">
    <button
      class="mt-2 btn font-size-12 button-btn"
      (click)="exportTable(exporter)"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-2">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
