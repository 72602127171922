import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
declare var $: any;


@Component({
    selector: 'app-mstemployee-managerswap',
    templateUrl: './mstemployee-managerswap.component.html',
    styleUrls: ['./mstemployee-managerswap.component.scss']
})
export class MstemployeeManagerSwapComponent implements OnInit {
    private data: any; showSpinner: boolean = false;

    dataSource: any;
    displayedColumns: string[] = ['EmpId', 'EmployeeName', 'Designation', 'GenderAge', 'PhoneNo', 'ReportingManger', 'Emp_RegOn', 'EditAction'];
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    loading: boolean = false;
    FilterEmployeeId: any = '';
    GetEmployeeDropdown: any = [];
    PendingList: any = [];
    dialogreturn: any;
    // swap employee
    AssignedToEmployee: any = '';
    employeeSwapDropdown: any = [];
    selectedData: any;

    constructor(private _Route: Router, private dialog: DialogService, private dataSharingService: DataSharingService, public snackBar: MatSnackBar, private _MasterService: MasterService) {

        this.data = JSON.parse(sessionStorage.getItem('currentUser'));
        //  sessionStorage.removeItem('getEmployeeID');
    }
    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };

    ngOnInit() {
        this.dataSharingService.HeaderTitle.next('Manager Swap on Employee');
        this._MasterService.GetEmployeeDropdown({ BranchId: 0, ProcessId: 0 }).subscribe((result) => {
            this.GetEmployeeDropdown = JSON.parse(JSON.stringify(result));
            this.FilterEmployeeId = this.GetEmployeeDropdown.length > 1 ? this.GetEmployeeDropdown[0].EmpId : '';
            this.onselectemployee();
        });

    }

    onselectemployee() {
        if (this.FilterEmployeeId == "") {
            this.dataSource = [];
            return;
        }

        this.showSpinner = true;
        this._MasterService.Get_Employee_Subordinate_SWAP({ ManagerId: parseInt(this.FilterEmployeeId) }).subscribe((result) => {
            this.PendingList = JSON.parse(JSON.stringify(result));
            this.dataSource = new MatTableDataSource(this.PendingList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.showSpinner = false;
        });

    }

    onOpenUpdateSwapModal(selectedRow) {
        this.selectedData = selectedRow;
        this._MasterService.Get_ReportingManager_SWAP({ EmployeeId: this.selectedData.EmpId }).subscribe((result) => {
            this.employeeSwapDropdown = JSON.parse(JSON.stringify(result));
        });

        $('#employeeSwap').modal('show');
        $("#employeeSwap").css("z-index", "1050");
    }

    OnClose() {
        this.AssignedToEmployee = '';
        this.selectedData = null;
        $('#employeeSwap').modal('hide');
    }

    onSaveSwapData() {
        this._MasterService.Update_ReportingManager({ EmployeeId: this.selectedData.EmpId, NewReportingManagerId: this.AssignedToEmployee, LoginUserId: this.data.userId }).subscribe((response) => {
            if (response[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
                this.onselectemployee();
                this.OnClose();
            } else {
                this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            }
        });
    }

}
