import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(empList:any[],prop:string,str:string): any[] {
    if(!!!str){
      return empList;
    }
     str = str.toLowerCase();
    return empList.filter(
        x =>x[prop].toLowerCase().includes(str)
    )
  }

}
