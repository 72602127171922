<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div>
    <form
      #df="ngForm"
      *ngIf="loanSummary == undefined"
      novalidate
      (keyup.enter)="onSearchNachProcess()"
      class="formborder"
    >
      <div class="row m-0 col-md-12 p-0 my-3">
        <div
          style="display: none"
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-3': SearchStatus == 'Pending' || SearchStatus == ''
          }"
        >
          <label>Type</label>
          <select
            name="CheckType"
            id="CheckType"
            [(ngModel)]="CheckType"
            disabled
            class="form-control input-text-css"
          >
            <option value="" selected>All</option>
            <option value="RPDC">RPDC</option>
            <option value="NACH">NACH</option>
          </select>
        </div>
        <div
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-3': SearchStatus == 'Pending' || SearchStatus == ''
          }"
        >
          <label>Status</label>
          <select
            name="SearchStatus"
            id="SearchStatus"
            [(ngModel)]="SearchStatus"
            (change)="StatusChange()"
            class="form-control input-text-css"
          >
            <option value="">Select Status</option>
            <option value="Post">Post</option>
            <option value="Bounce">Bounce</option>
            <option value="Clear">Clear</option>
            <option value="Hold">Hold</option>
            <option value="Pending">Pending</option>
          </select>
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Post'">
          <label>Post Date From</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="PostDateFrom"
              id="PostDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="PostDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <!-- <input
            name="PostDateFrom"
            [(ngModel)]="PostDateFrom"
            id="PostDateFrom"
            placeholder="Post From"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Post'">
          <label>Post Date To</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker2"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="PostDateTo"
              id="PostDateTo"
              class="form-control input-text-css"
              [(ngModel)]="PostDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
          <!-- <input
            name="PostDateTo"
            [(ngModel)]="PostDateTo"
            id="PostDateTo"
            placeholder="Post To"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Bounce'">
          <label>Bounce Date From</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker3"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="BounceDateFrom"
              id="BounceDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="BounceDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
          <!-- <input
            name="BounceDateFrom"
            [(ngModel)]="BounceDateFrom"
            id="BounceDateFrom"
            placeholder="Bounce From"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Bounce'">
          <label>Bounce Date To</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker4"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="BounceDateTo"
              id="BounceDateTo"
              class="form-control input-text-css"
              [(ngModel)]="BounceDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker4"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </div>
          <!-- <input
            name="BounceDateTo"
            [(ngModel)]="BounceDateTo"
            id="BounceDateTo"
            placeholder="Bounce To"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Clear'">
          <label>Clear Date From</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker5"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ClearDateFrom"
              id="ClearDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="ClearDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker5"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </div>
          <!-- <input
            name="ClearDateFrom"
            [(ngModel)]="ClearDateFrom"
            id="ClearDateFrom"
            placeholder="Clear From"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Clear'">
          <label>Clear Date To</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker6"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ClearDateTo"
              id="ClearDateTo"
              class="form-control input-text-css"
              [(ngModel)]="ClearDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker6"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
          </div>
          <!-- <input
            name="ClearDateTo"
            [(ngModel)]="ClearDateTo"
            id="ClearDateTo"
            placeholder="Clear To"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Hold'">
          <label>Hold Date From</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker7"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="HoldDateFrom"
              id="HoldDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="HoldDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker7"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker7></mat-datepicker>
          </div>
          <!-- <input
            name="HoldDateFrom"
            [(ngModel)]="HoldDateFrom"
            id="HoldDateFrom"
            placeholder="Hold From"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Hold'">
          <label>Hold Date To</label>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker8"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="HoldDateTo"
              id="HoldDateTo"
              class="form-control input-text-css"
              [(ngModel)]="HoldDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker8"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker8></mat-datepicker>
          </div>
          <!-- <input
            name="HoldDateTo"
            [(ngModel)]="HoldDateTo"
            id="HoldDateTo"
            placeholder="Hold To"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-3': SearchStatus == 'Pending' || SearchStatus == '',
            'is-invalid alert-warning': DueDateFrom == null || DueDateFrom == ''
          }"
        >
          <label for="DueDateFrom">Due Date From</label>
          <div class="datepicker_feild">
            <input
              required
              [matDatepicker]="picker9"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="DueDateFrom"
              id="DueDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="DueDateFrom"
              [ngclass]="{
                'is-invalid alert-warning':
                  DueDateFrom == null || DueDateFrom == ''
              }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker9"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker9></mat-datepicker>
          </div>
          <!-- <input
            name="DueDateFrom"
            [(ngModel)]="DueDateFrom"
            id="DueDateFrom"
            placeholder="Due From"
            class="form-control input-text-css"
            required
            bsDatepicker
            [ngclass]="{
              'is-invalid alert-warning':
                DueDateFrom == null || DueDateFrom == ''
            }"
          /> -->
        </div>
        <div
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-3': SearchStatus == 'Pending' || SearchStatus == '',
            'is-invalid alert-warning': DueDateTo == null || DueDateTo == ''
          }"
        >
          <label for="DueDateTo">Due Date To</label>
          <div class="datepicker_feild">
            <input
              required
              [matDatepicker]="picker10"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="DueDateTo"
              id="DueDateTo"
              class="form-control input-text-css"
              [(ngModel)]="DueDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker10"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker10></mat-datepicker>
          </div>
          <!-- <input
            name="DueDateTo"
            [(ngModel)]="DueDateTo"
            id="DueDateTo"
            placeholder="Due To"
            class="form-control input-text-css"
            required
            bsDatepicker
            [ngclass]="{
              'is-invalid alert-warning': DueDateTo == null || DueDateTo == ''
            }"
          /> -->
        </div>
      </div>

      <div class="row m-0 col-md-12 p-0">
        <div class="col-md-3">
          <label>Company Bank</label>
          <select
            name="BankInCompany"
            id="BankInCompany"
            #refPresentBankInCompany="ngModel"
            [(ngModel)]="BankInCompany"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Present Bank In Company</option>
            <option
              *ngFor="let bank of BankList"
              [value]="bank.AccountId + '_' + bank.Account_Name"
            >
              {{ bank.Account_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Source Branch</label>
          <select
            name="SelectBranch"
            id="SelectBranch"
            #refSelectGender="ngModel"
            [(ngModel)]="SelectBranch"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Source Branch</option>
            <option
              *ngFor="let branch of BranchesList"
              [value]="branch.BranchId"
            >
              {{ branch.Branch_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Product</label>
          <select
            name="SelectProduct"
            id="SelectProduct"
            #refSelectGender="ngModel"
            [(ngModel)]="SelectProduct"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Product-</option>
            <option
              *ngFor="let product of ProductList"
              [value]="product.ProductId"
            >
              {{ product.Product }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Customer Bank</label>
          <input
            name="CustomerBank"
            [(ngModel)]="CustomerBank"
            id="CustomerBank"
            placeholder="Customer Bank"
            class="form-control input-text-css"
          />
        </div>
      </div>

      <div class="row m-0 col-md-12 p-0 mt-2">
        <div class="col-md-3">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-1">
          <button
            [disabled]="df.form.invalid"
            type="button"
            (click)="onSearchNachProcess()"
            class="mt-2 btn font-size-12 button-btn"
          >
            Search
          </button>
        </div>
        <div class="col-md-8 text-right">
          <button
            type="button"
            class="mt-2 btn font-size-12 button-btn"
            (click)="bulkImportTemplate('NachTemplate')"
          >
            Bulk Import Template
          </button>
          <span class="button-wrap">
            <label class="mt-2 btn font-size-12 button-btn" for="bulkImport"
              >Bulk Import</label
            >
            <input
              type="file"
              id="bulkImport"
              (change)="bulkImport($event)"
              style="display: none"
              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </span>

          <button
            type="button"
            class="mt-2 btn font-size-12 button-btn"
            (click)="bulkPost()"
          >
            Bulk Post
          </button>
          <button
            type="button"
            (click)="bulkClearing()"
            class="mt-2 btn font-size-12 button-btn"
          >
            Bulk Clearing
          </button>
          <button
            type="button"
            *ngIf="dataSourceLoan"
            (click)="exportExcel()"
            [class.spinner]="loading"
            class="mt-2 btn font-size-12 button-btn"
          >
            Export
          </button>
        </div>
      </div>
    </form>

    <div class="mt-3" *ngIf="dataSourceLoan">
      <div class="">
        <div class="table-responsive">
          <mat-table
            style="height: 480px !important"
            [dataSource]="dataSourceLoan"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
          >
            <!-- [ngClass]="[loanSummary == undefined ? 'fixheight1' : 'fixheight2']" -->
            <ng-container matColumnDef="Id" sticky>
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="min-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell FreezColumn"
                style="min-width: 50px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CaseNo" sticky>
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="min-width: 60px"
                *matHeaderCellDef
                >Loan No.</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                style="min-width: 60px"
                class="grid-cell FreezColumn"
                >{{ row.LoanAcNo }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerName" sticky>
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="width: 60px"
                *matHeaderCellDef
                >Customer Name</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                style="width: 60px"
                class="grid-cell FreezColumn"
              >
                {{ row.CustomerName }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef style="margin-left: 16px"
                >Total:
              </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="EMINo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="min-width: 65px"
                *matHeaderCellDef
                >EMI No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="min-width: 65px"
              >
                {{ row.EMINo }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="ChqueNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Cheque No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ChequeNo }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMI_Amount">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.EMI_Amount }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                calculateTotal()
              }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="DueDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Due Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.DueDateFormat }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerBankname">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer Bank</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CustomerBankname }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="OD_Amount">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >OD Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.OD_Amount }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Last_Recv_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Last Receive Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Last_Recv_Date }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Type }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Status</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Status }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PostDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                style="min-width: 130px"
                >Post Date</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="min-width: 130px"
              >
                <!--<input name="PostDate{{row.Id}}" id="PostDate{{row.Id}}" style="height: calc(1.0em + 0.25rem + 1px) !important; width:90px;" disabled={{row.Posthold}} [(ngModel)]="row.PostDate" (ngModelChange)="PostDateChange(row)"
      placeholder="DD/MM/YYYY" [minDate]="minDate" refPostDate="ngModel" class="form-control input-text-css" bsDatepicker>-->
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker11"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="PostDate{{ row.Id }}"
                    [min]="minDate"
                    id="PostDate{{ row.Id }}"
                    disabled="{{ row.Posthold }}"
                    [(ngModel)]="row.PostDate"
                    (dateChange)="PostDateChange(row)"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker11"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker11></mat-datepicker>
                </div>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="ComapnyAccount">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Company Account</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ComapnyAccount }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Clearing">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Clearing/Bounce</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <mat-select
                  [(value)]="row.ClearingType"
                  style="
                    height: calc(1.3em + 0.25rem + 1px) !important;
                    padding: 0;
                    text-align: center;
                  "
                  name="type{{ row.Id }}"
                  id="type{{ row.Id }}"
                  disabled="{{ row.Clearinghold }}"
                  class="form-control input-text-css"
                  (selectionChange)="ClearingChange($event, row)"
                >
                  <mat-option value=""> select </mat-option>
                  <mat-option value="Bounce"> Bounce </mat-option>
                  <mat-option value="Clear"> Clear </mat-option>
                </mat-select>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="ClearBounceDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Clear/ Bounce Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ClearBounceDate }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="BounceReason">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bounce Reason</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BounceReason }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="BounceCharge">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Bounce Charge</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.BounceCharge }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>{{
                calculateBounceTotal()
              }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Undo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >HOLD/UNDO</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
                <ng-container *ngIf="loanSummary == undefined">
                  <button
                    type="button"
                    *ngIf="
                      (row.IsStop == 0 ||
                        row.IsStop == '' ||
                        row.IsStop == null) &&
                      row.PostDate == '' &&
                      row.RepresentId == 0
                    "
                    (click)="onHold(row)"
                    style="
                      background-color: #ee2005;
                      color: #ffffff;
                      line-height: 15px !important;
                      font-size: 11px;
                      margin: 4px;
                    "
                    mat-raised-button
                    color="primary"
                  >
                    HOLD
                  </button>
                  <button
                    type="button"
                    (click)="Undo(row)"
                    *ngIf="row.Status != '' && row.RepresentId == 0"
                    mat-raised-button
                    color="primary"
                    style="
                      background-color: #506b97;
                      color: #ffffff;
                      font-size: 11px;
                      line-height: 18px !important;
                      margin: 4px;
                    "
                  >
                    UNDO
                  </button>
                </ng-container>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="HoldDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Hold Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.HoldDate }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="HoldReason">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Hold Reason</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.HoldReason }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="NachCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >UMRN No.</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.NachCode }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="Represent">
              <mat-header-cell
                mat-sort-headRepresenter
                class="grid-header"
                *matHeaderCellDef
                >Represent</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <button
                  type="button"
                  *ngIf="
                    (row.RepresentId == 0 ||
                      row.RepresentId == '' ||
                      row.RepresentId == null) &&
                    row.Status == 'Bounce'
                  "
                  (click)="onRepresent(row)"
                  class="btn font-size-12 button-btn represent"
                >
                  Represent
                </button>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsLoan; sticky: true"
            ></mat-header-row>
            <mat-row
              (click)="highlightRow(row)"
              [ngClass]="{
                Post: row.Status == 'Post',
                Hold: row.Status == 'Hold',
                Bounce: row.Status == 'Bounce',
                Clear: row.Status == 'Clear',
                duedate: DateConvert(row.DueDate, row.Status),
                highlight: row.Id == selectedId
              }"
              *matRowDef="let row; columns: displayedColumnsLoan"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsLoan; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="HoldDetailModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Hold Details
          </h6>
          <button
            type="button"
            (click)="onCloseHoldDetail()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #ff="ngForm"
            (ngSubmit)="onHoldOk()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-6">
                <span>Hold Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker12"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="HoldDate"
                    [(ngModel)]="HoldDate"
                    required
                    id="HoldDate"
                    class="form-control input-text-css"
                    #refHoldDate="ngModel"
                    [ngClass]="{
                      'is-invalid': ff.submitted && refHoldDate.invalid,
                      'alert-warning':
                        refHoldDate.invalid &&
                        (refHoldDate.dirty ||
                          refHoldDate.touched ||
                          refHoldDate.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker12"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker12></mat-datepicker>
                </div>
                <!-- <input
                  name="HoldDate"
                  [(ngModel)]="HoldDate"
                  required
                  id="HoldDate"
                  placeholder="DD/MM/YYYY"
                  #refHoldDate="ngModel"
                  [ngClass]="{
                    'is-invalid': ff.submitted && refHoldDate.invalid,
                    'alert-warning':
                      refHoldDate.invalid &&
                      (refHoldDate.dirty ||
                        refHoldDate.touched ||
                        refHoldDate.untouched)
                  }"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-6">
                <span>Hold Reason</span>
                <input
                  name="HoldReason"
                  [(ngModel)]="HoldReason"
                  required
                  id="HoldReason"
                  placeholder="Hold Reason"
                  #refHoldReason="ngModel"
                  [ngClass]="{
                    'is-invalid': ff.submitted && refHoldReason.invalid,
                    'alert-warning':
                      refHoldReason.invalid &&
                      (refHoldReason.dirty ||
                        refHoldReason.touched ||
                        refHoldReason.untouched)
                  }"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="onHoldOk()"
                [class.spinner]="loading"
                class="btn font-size-12 button-btn"
                [disabled]="!ff.form.valid"
              >
                OK
              </button>
              <button
                type="button"
                (click)="onCloseHoldDetail()"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="BounceDetailModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ Type }} Details
          </h6>
          <button
            type="button"
            (click)="onCloseBounceDetail()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #fr="ngForm"
            (ngSubmit)="onBounce()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="row m-0 col-md-12 p-0" *ngIf="Type == 'Bounce'">
              <div class="col-md-4">
                <span>Bounce Date</span>
                <!--<div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker13"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    [(ngModel)]="BounceDate"
                  required
                  id="BounceDate"
                    class="form-control input-text-css"
                    #refBounceDate="ngModel"
                  [min]="Row.PostDate > minDate ? Row.PostDate : minDate"
                  [ngClass]="{
                    'is-invalid': fr.submitted && refBounceDate.invalid,
                    'alert-warning':
                      refBounceDate.invalid &&
                      (refBounceDate.dirty ||
                        refBounceDate.touched ||
                        refBounceDate.untouched)
                  }"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
                  <mat-datepicker #picker13></mat-datepicker>
                </div>-->
                <input
                  name="BounceDate"
                  [(ngModel)]="BounceDate"
                  required
                  id="BounceDate"
                  placeholder="DD/MM/YYYY"
                  #refBounceDate="ngModel"
                  [min]="Row.PostDate > minDate ? Row.PostDate : minDate"
                  [ngClass]="{
                    'is-invalid': fr.submitted && refBounceDate.invalid,
                    'alert-warning':
                      refBounceDate.invalid &&
                      (refBounceDate.dirty ||
                        refBounceDate.touched ||
                        refBounceDate.untouched)
                  }"
                  class="form-control input-text-css"
                  bsDatepicker
                />
              </div>
              <div class="col-md-4">
                <span>Bounce Reason</span>

                <select
                  name="BounceReason"
                  id="BounceReason"
                  #refBounceReason="ngModel"
                  [(ngModel)]="BounceReason"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid': fr.submitted && refBounceReason.invalid,
                    'alert-warning':
                      refBounceReason.invalid &&
                      (refBounceReason.dirty ||
                        refBounceReason.touched ||
                        refBounceReason.untouched)
                  }"
                >
                  <option value="" selected>Select Bounce Reason</option>
                  <option
                    *ngFor="let BounceR of Bounce_ReasonList"
                    [value]="BounceR.Value"
                  >
                    {{ BounceR.Value }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <span>Bounce Charge</span>
                <input
                  name="BounceCharge"
                  [(ngModel)]="BounceCharge"
                  #refBounceCharge="ngModel"
                  id="refBounceCharge"
                  class="form-control input-text-css"
                  disabled
                />
              </div>
            </div>
            <div
              class="row m-0 mt-3 col-md-12 p-0 justify-content-end"
              *ngIf="Type == 'Bounce'"
            >
              <button
                type="button"
                [disabled]="!fr.form.valid"
                (click)="onBounce()"
                [class.spinner]="loading"
                class="btn font-size-12 button-btn"
              >
                Save
              </button>
              <button
                type="button"
                (click)="onCloseBounceDetail()"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Cancel
              </button>
            </div>
          </form>
          <form
            #f="ngForm"
            (ngSubmit)="onClear()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="row m-0 col-md-12 p-0" *ngIf="Type == 'Clear'">
              <div class="col-md-6">
                <span>Clear Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker14"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="ClearDate"
                    [(ngModel)]="ClearDate"
                    required
                    id="ClearDate"
                    class="form-control input-text-css"
                    #refClearDate="ngModel"
                    [min]="Row.PostDate > minDate ? Row.PostDate : minDate"
                    [ngClass]="{
                      'is-invalid': f.submitted && refClearDate.invalid,
                      'alert-warning':
                        refClearDate.invalid &&
                        (refClearDate.dirty ||
                          refClearDate.touched ||
                          refClearDate.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker14"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker14></mat-datepicker>
                </div>
                <!-- <input
                  name="ClearDate"
                  [(ngModel)]="ClearDate"
                  required
                  id="ClearDate"
                  placeholder="DD/MM/YYYY"
                  #refClearDate="ngModel"
                  [min]="Row.PostDate > minDate ? Row.PostDate : minDate"
                  [ngClass]="{
                    'is-invalid': f.submitted && refClearDate.invalid,
                    'alert-warning':
                      refClearDate.invalid &&
                      (refClearDate.dirty ||
                        refClearDate.touched ||
                        refClearDate.untouched)
                  }"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-6 justify-content-end" *ngIf="Type == 'Clear'">
                <button
                  type="button"
                  [disabled]="!f.form.valid"
                  (click)="onClear()"
                  [class.spinner]="loading"
                  class="btn font-size-12 button-btn"
                >
                  Save
                </button>
                <button
                  type="button"
                  (click)="onCloseBounceDetail()"
                  class="btn font-size-12 button-btn"
                  [class.spinner]="loading"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="PostDetailModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div class="modal-dialog modal-bg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Post Voucher
          </h6>
          <button
            type="button"
            (click)="onClosePostVoucher()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #f="ngForm"
            (ngSubmit)="onPost()"
            novalidate
            class="row m-0 col-md-12 p-0"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-12" *ngIf="AMOR_Type == 'RPDC'">
                <span>Present Bank In Company</span>
                <select
                  name="PresentBankInCompany"
                  id="PresentBankInCompany"
                  #refPresentBankInCompany="ngModel"
                  [(ngModel)]="PresentBankInCompany"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refPresentBankInCompany.invalid,
                    'alert-warning':
                      refPresentBankInCompany.invalid &&
                      (refPresentBankInCompany.dirty ||
                        refPresentBankInCompany.touched ||
                        refPresentBankInCompany.untouched)
                  }"
                >
                  <option value="" selected>
                    Select Present Bank In Company
                  </option>
                  <option
                    *ngFor="let bank of BankList"
                    [value]="bank.AccountId"
                  >
                    {{ bank.Account_Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <button
                  type="button"
                  [disabled]="!f.form.valid"
                  (click)="onPost()"
                  [class.spinner]="loading"
                  class="btn font-size-12 button-btn"
                >
                  Yes
                </button>
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  (click)="onClosePostVoucher()"
                  class="btn font-size-12 button-btn"
                  [class.spinner]="loading"
                >
                  No
                </button>
              </div>
              <div class="col-md-3"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="bulkPostDetailModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div
      class="modal-dialog modal-xl modal-x2 modal-dialog-centered"
      role="document"
    >
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Post Voucher
          </h6>
          <button
            type="button"
            (click)="onCloseBulkPostVoucher()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #f="ngForm"
            (ngSubmit)="SearchBulkPost()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3" style="display: none">
                <span>Type</span>
                <select
                  name="bulkCheckType"
                  id="bulkCheckType"
                  disabled
                  [(ngModel)]="bulkCheckType"
                  class="form-control input-text-css"
                >
                  <option value="NACH">NACH</option>
                  <option value="RPDC">RPDC</option>
                </select>
              </div>
              <div class="col-md-3">
                <span>Due Date From</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker15"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="bulkDueDateFrom"
                    [(ngModel)]="bulkDueDateFrom"
                    id="bulkDueDateFrom"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker15"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker15></mat-datepicker>
                </div>
                <!-- <input
                  name="bulkDueDateFrom"
                  [(ngModel)]="bulkDueDateFrom"
                  id="bulkDueDateFrom"
                  placeholder="Due From"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3">
                <span>Due Date To</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker16"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="bulkDueDateTo"
                    [(ngModel)]="bulkDueDateTo"
                    id="bulkDueDateTo"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker16"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker16></mat-datepicker>
                </div>
                <!-- <input
                  name="bulkDueDateTo"
                  [(ngModel)]="bulkDueDateTo"
                  id="bulkDueDateTo"
                  placeholder="Due To"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  (click)="SearchBulkPost()"
                  class="btn font-size-12 button-btn"
                >
                  Search
                </button>
              </div>
            </div>
            <hr />
            <div class="table-responsive" style="margin-top: 20px">
              <mat-table
                [dataSource]="dataSourceBulkPost"
                matSort
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Selection">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    [hidden]="!IsSelectAll"
                  >
                    <mat-checkbox
                      (change)="SelectAll($event)"
                      [checked]="IsAlreadySelected"
                    >
                    </mat-checkbox>
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    [hidden]="!IsSelectAll"
                  >
                    <mat-checkbox
                      [(ngModel)]="row.IsAlreadySelected"
                      name="IsAlreadySelected{{ row.Id }}"
                      id="IsAlreadySelected{{ row.Id }}"
                      [checked]="row.IsAlreadySelected"
                    >
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CaseNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Case No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CaseDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Case Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Loan_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomerName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ChqueNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Cheque No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ChequeNo }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="EMI_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    EMI Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.EMI_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DueDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Due Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DueDate }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerBankname">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer Bank
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomerBankname }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ComapnyAccount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Company Account
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ComapnyAccount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PostDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Post Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.PostDate
                  }}</mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsBulkPost"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsBulkPost"
                ></mat-row>
              </mat-table>
              <div class="row m-0 align-items-center" style="background: #fff">
                <div class="col-md-8">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
            <hr />
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3" *ngIf="bulkCheckType == 'RPDC'">
                <span>Present Bank In Company</span>
                <select
                  name="bulkPresentBankInCompany"
                  id="bulkPresentBankInCompany"
                  #refbulkPresentBankInCompany="ngModel"
                  [(ngModel)]="bulkPresentBankInCompany"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refbulkPresentBankInCompany.invalid,
                    'alert-warning':
                      refbulkPresentBankInCompany.invalid &&
                      (refbulkPresentBankInCompany.dirty ||
                        refbulkPresentBankInCompany.touched ||
                        refbulkPresentBankInCompany.untouched)
                  }"
                >
                  <option value="" selected>
                    Select Present Bank In Company
                  </option>
                  <option
                    *ngFor="let bank of BankList"
                    [value]="bank.AccountId"
                  >
                    {{ bank.Account_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <span>Post Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker17"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="BulkPostDate"
                    [(ngModel)]="BulkPostDate"
                    required
                    id="BulkPostDate"
                    [min]="minDate"
                    class="form-control input-text-css"
                    #refBulkPostDate="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refBulkPostDate.invalid,
                      'alert-warning':
                        refBulkPostDate.invalid &&
                        (refBulkPostDate.dirty ||
                          refBulkPostDate.touched ||
                          refBulkPostDate.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker17"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker17></mat-datepicker>
                </div>
                <!-- <input
                  name="BulkPostDate"
                  [(ngModel)]="BulkPostDate"
                  required
                  id="BulkPostDate"
                  [min]="minDate"
                  placeholder="DD/MM/YYYY"
                  #refBulkPostDate="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refBulkPostDate.invalid,
                    'alert-warning':
                      refBulkPostDate.invalid &&
                      (refBulkPostDate.dirty ||
                        refBulkPostDate.touched ||
                        refBulkPostDate.untouched)
                  }"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3" style="margin-top: 18px">
                <button
                  type="button"
                  [disabled]="!f.form.valid"
                  (click)="onBulkPost()"
                  [class.spinner]="loading"
                  class="btn font-size-12 button-btn"
                >
                  Post
                </button>
                <button
                  type="button"
                  (click)="onCloseBulkPostVoucher()"
                  class="btn font-size-12 button-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="bulkClearingDetailModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div
      class="modal-dialog modal-xl modal-x2 modal-dialog-centered"
      role="document"
    >
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Bulk Clearing
          </h6>
          <button
            type="button"
            (click)="onCloseBulkClearing()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #f="ngForm"
            (ngSubmit)="SearchBulkClearing()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3" style="display: none">
                <span>Type</span>
                <select
                  name="bulkCheckType"
                  id="bulkCheckType"
                  disabled
                  [(ngModel)]="bulkCheckType"
                  class="form-control input-text-css"
                >
                  <option value="NACH">NACH</option>
                  <option value="RPDC">RPDC</option>
                </select>
              </div>
              <div class="col-md-3">
                <span>Post Date From</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker18"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="bulkPostDateFrom"
                    [(ngModel)]="bulkPostDateFrom"
                    id="bulkPostDateFrom"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker18"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker18></mat-datepicker>
                </div>
                <!-- <input
                  name="bulkPostDateFrom"
                  [(ngModel)]="bulkPostDateFrom"
                  id="bulkPostDateFrom"
                  placeholder="Post From"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3">
                <span>Post Date To</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker19"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="bulkPostDateTo"
                    [(ngModel)]="bulkPostDateTo"
                    id="bulkPostDateTo"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker19"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker19></mat-datepicker>
                </div>
                <!-- <input
                  name="bulkPostDateTo"
                  [(ngModel)]="bulkPostDateTo"
                  id="bulkPostDateTo"
                  placeholder="Post To"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  (click)="SearchBulkClearing()"
                  class="btn font-size-12 button-btn"
                >
                  Search
                </button>
              </div>
            </div>
            <hr />
            <div
              class="table-responsive"
              style="margin-top: 20px"
              [hidden]="!IsExportShow"
            >
              <mat-table
                [dataSource]="dataSourceBulkPost"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                [hiddenColumns]="[displayedColumnsBulkPostImport.indexOf('Id')]"
              >
                <ng-container matColumnDef="IdC">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    ID
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Id }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CaseNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Case No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CaseDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Case Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Loan_Date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomerName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ChqueNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Cheque No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ChequeNo }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="EMI_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    EMI Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.EMI_Amount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DueDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Due Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.DueDate }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerBankname">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer Bank
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomerBankname }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ComapnyAccount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Company Account
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ComapnyAccount }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PostDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Post Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.PostDate
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Clearing">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Clearing</mat-header-cell
                  >

                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <mat-select
                      [(value)]="row.ClearingType"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        padding: 0;
                        text-align: center;
                      "
                      name="type1{{ row.Id }}"
                      id="type1{{ row.Id }}"
                      class="form-control input-text-css"
                    >
                      <mat-option value=""> select </mat-option>
                      <mat-option value="Bounce"> Bounce </mat-option>
                      <mat-option value="Clear"> Clear </mat-option>
                    </mat-select>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker20"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="Date1{{ row.Id }}"
                        id="Date1{{ row.Id }}"
                        style="
                          height: calc(1em + 0.25rem + 1px) !important;
                          width: 90px;
                        "
                        [(ngModel)]="row.Date"
                        class="form-control input-text-css"
                        #refDate="ngModel"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker20"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker20></mat-datepicker>
                    </div>
                    <!-- <input
                      name="Date1{{ row.Id }}"
                      id="Date1{{ row.Id }}"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        width: 90px;
                      "
                      [(ngModel)]="row.Date"
                      placeholder="DD/MM/YYYY"
                      refDate="ngModel"
                      class="form-control input-text-css"
                      bsDatepicker
                    /> -->
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BounceReason">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BounceReason</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <input
                      name="BounceReason1{{ row.Id }}"
                      id="BounceReason1{{ row.Id }}"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        width: 90px;
                      "
                      [(ngModel)]="row.BounceReason"
                      [disabled]="
                        row.ClearingType == 'Clear' || row.ClearingType == ''
                      "
                      class="form-control input-text-css"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsBulkPostImport"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsBulkPostImport"
                ></mat-row>
              </mat-table>
              <div class="row m-0 align-items-center" style="background: #fff">
                <div class="col-md-8">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>

            <div
              class="table-responsive"
              style="margin-top: 20px"
              [hidden]="!IsImportShow"
            >
              <mat-table
                [dataSource]="dataSourceBulkPost"
                matSort
                matTableExporter
                #exporterTable="matTableExporter"
              >
                <ng-container matColumnDef="IdC">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >ID</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Id
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >LoanAcNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.LoanAcNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Loan_Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan_Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Loan_Date
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >CustomerName</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CustomerName
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="ChequeNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >ChequeNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ChequeNo
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="EMI_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >EMI_Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.EMI_Amount
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="DueDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >DueDate</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.DueDate
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerBankname">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >CustomerBankname</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CustomerBankname
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="ComapnyAccount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >ComapnyAccount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ComapnyAccount
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Type
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PostDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >PostDate</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.PostDate
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Clearing">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Clearing</mat-header-cell
                  >

                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <mat-select
                      [(value)]="row.ClearingType"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        padding: 0;
                        text-align: center;
                      "
                      name="type{{ row.Id }}"
                      id="type{{ row.Id }}"
                      class="form-control input-text-css"
                    >
                      <mat-option value=""> select </mat-option>
                      <mat-option value="Bounce"> Bounce </mat-option>
                      <mat-option value="Clear"> Clear </mat-option>
                    </mat-select>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker21"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="Date{{ row.Id }}"
                        id="Date{{ row.Id }}"
                        style="
                          height: calc(1em + 0.25rem + 1px) !important;
                          width: 90px;
                        "
                        [(ngModel)]="row.Date"
                        class="form-control input-text-css"
                        #refDate="ngModel"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker21"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker21></mat-datepicker>
                    </div>
                    <!-- <input
                      matInput
                      name="Date{{ row.Id }}"
                      id="Date{{ row.Id }}"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        width: 90px;
                      "
                      [(ngModel)]="row.Date"
                      placeholder="DD/MM/YYYY"
                      refDate="ngModel"
                      class="form-control input-text-css"
                      bsDatepicker
                    /> -->
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BounceReason">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >BounceReason</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <input
                      matInput
                      name="BounceReason2{{ row.Id }}"
                      id="BounceReason2{{ row.Id }}"
                      style="
                        height: calc(1em + 0.25rem + 1px) !important;
                        width: 90px;
                      "
                      [(ngModel)]="row.BounceReason"
                      [disabled]="
                        row.ClearingType == 'Clear' || row.ClearingType == ''
                      "
                      class="form-control input-text-css"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsBulkClearing"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsBulkClearing"
                ></mat-row>
              </mat-table>
              <div class="row m-0 align-items-center" style="background: #fff">
                <div class="col-md-8">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
            <hr />
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                [hidden]="!IsExportbtnShow"
                (click)="onExport(exporterTable)"
                [class.spinner]="loading"
                class="btn font-size-12 button-btn"
              >
                Export
              </button>
              <div class="button-wrap" [hidden]="!IsImportbtnShow">
                <label class="btn font-size-12 button-btn" for="upload"
                  >Import</label
                >
                <input
                  type="file"
                  id="upload"
                  (change)="onImport($event)"
                  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
              <button
                type="button"
                [hidden]="!IsSavebtnShow"
                (click)="onBulkClearing()"
                [class.spinner]="loading"
                class="btn font-size-12 button-btn"
              >
                Save
              </button>
              <button
                type="button"
                (click)="onCloseBulkClearing()"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="bulkImportModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Bulk Import
          </h6>
          <button
            type="button"
            (click)="onCloseBulkImport()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <h6>Match Data</h6>
          <div
            class="table-responsive"
            style="margin-top: 20px; scroll-behavior: auto; height: 300px"
          >
            <mat-table
              [dataSource]="dataSourceBulkImport"
              matSort
              matTableExporter
              #exporterTable="matTableExporter"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="width: 50px"
                  *matHeaderCellDef
                >
                  #
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="width: 50px; max-height: 5px"
                >
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >LoanAcNo</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.LoanAcNo
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="UMRN">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >UMRN</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.UMRN
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="DueDate">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >Due Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.DueDate
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Date">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >Bank Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.Date
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="ReasonCode">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >ReasonCode</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.ReasonCode
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Amount">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Amount
                }}</mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsBulkImport"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsBulkImport"
              ></mat-row>
            </mat-table>
          </div>
          <hr />
          <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
            <button
              type="button"
              (click)="onBulkVoucherSave()"
              *ngIf="dataSourceBulkImport"
              [class.spinner]="loading"
              class="btn font-size-12 button-btn"
            >
              Save
            </button>
            <button
              type="button"
              (click)="onCloseBulkImport()"
              class="btn font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Cancel
            </button>
          </div>
          <h6>MisMatch Data</h6>
          <div
            class="table-responsive"
            style="margin-top: 20px; scroll-behavior: auto; height: 300px"
          >
            <mat-table
              [dataSource]="dataSourceBulkImport_NotMatch"
              matSort
              matTableExporter
              #exporterTable1="matTableExporter"
            >
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="width: 50px"
                  *matHeaderCellDef
                >
                  #
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="width: 50px; max-height: 5px"
                >
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >LoanAcNo</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.LoanAcNo
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="UMRN">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >UMRN</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.UMRN
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="DueDate">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >DueDate</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.DueDate
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Date">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >Bank Date</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.Date
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="ReasonCode">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >ReasonCode</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.ReasonCode
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Amount">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.Amount
                }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="NotMach">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Not Match Data</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.NotMach
                }}</mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsNotMatchImport"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsNotMatchImport"
              ></mat-row>
            </mat-table>
          </div>

          <div class="col-md-12 p-0 mt-1 text-right">
            <button
              type="button"
              (click)="onExportMisMatch(exporterTable1)"
              [class.spinner]="loading"
              class="btn font-size-12 button-btn"
            >
              Export MisMatch data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade in"
    id="RepresentModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div class="modal-dialog modal-bg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Represent
          </h6>
          <button
            type="button"
            (click)="onCloseRepresent()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <form
            #fgfgf="ngForm"
            (ngSubmit)="onRepresentBounce()"
            novalidate
            class="row m-0 col-md-12 p-0 formborder"
          >
            <div class="col-md-12 p-0">
              <div class="col-md-12">
                <span>Post Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="RepresentPostDate"
                    [min]="minRepresentPostDate"
                    id="RepresentPostDate"
                    [(ngModel)]="RepresentPostDate"
                    #refRepresentPostDate="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        fgfgf.submitted && refRepresentPostDate.invalid,
                      'alert-warning':
                        refRepresentPostDate.invalid &&
                        (refRepresentPostDate.dirty ||
                          refRepresentPostDate.touched ||
                          refRepresentPostDate.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex gap-1 m-2">
              <div class="col-md-3"></div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <button
                  type="button"
                  [disabled]="!fgfgf.form.valid"
                  (click)="onRepresentBounce()"
                  [class.spinner]="loading"
                  class="btn font-size-12 button-btn"
                >
                  Yes
                </button>
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  (click)="onCloseRepresent()"
                  class="btn font-size-12 button-btn"
                  [class.spinner]="loading"
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
