import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { DepositService } from "../services/deposit.service";
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-deposit-product-details',
  templateUrl: './deposit-product-details.component.html',
  styleUrls: ['./deposit-product-details.component.scss']
})
export class DepositProductDetailsComponent implements OnInit {
  data: any; 
  showSpinner: boolean = false;
  loading: boolean = false;
  ProductModel: any = {};
  ProductId: any = 0;
  Product_SchemeModel: any[] = [];

  @Input() ProductSummary: any;
  IsUpdate: boolean = false;
  IsSave: boolean = false;


  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private dataSharingService: DataSharingService,
    private _Route: Router,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private _DepositService: DepositService
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Deposit Product');
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
    
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      //console.log("this.ProductId", this.ProductId);
      if (Number(this.ProductId) > 0) {
        this.getProductDetail();
        this.IsUpdate = true;
        this.IsSave = false;
      }
      else {
        this.IsUpdate = false;
        this.IsSave = true;
      }
    });

    this.ProductModel = { 
      ProductCategory: '', 
      Interest_Type: '', 
      Cummulative_Frequency: '', 
      Payment_Frequency: '',
      Lockin_Period: 0,
      End_Date: ''
    };
    this.ProductModel.Start_Date = new Date();
  }

  onChangeTenureMax(){
    this.Product_SchemeModel = [];
    this.Product_SchemeModel.push({
      From_Month: 1,
      To_Month: this.ProductModel.Tenure_Max,
      Rate_Of_Interest: "",
      Rate_Of_Interest_SC: ""
    });
  }

  onSaveNewProduct() {
    this.ProductModel.IsPreMature = this.ProductModel.IsPreMature == true ? 1 : 0;
    let _data = {
      Product: this.ProductModel,
      Product_Scheme: this.Product_SchemeModel
    }
    
    _data.Product.LoginUserId = this.data.userId;
    _data.Product.ProductId = this.ProductId;
    console.log('_data', _data);
    this._DepositService.Deposit_Save_Product_Master({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this._Route.navigate([`/deposit-dashboard/Product-List`]);
        //this._Route.navigate([`/deposit-dashboard/Product/${this._EncrDecrService.encrypt(encodeURIComponent(res[0].CODE))}`]);
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
      this.loading = false;
    });
  }

  getProductDetail() {
    this._DepositService.Deposit_Get_Product_Master({ProductId : this.ProductId}).subscribe((res: any) => {
      //console.log("res", res);
      this.ProductModel = res.Item1[0];
      this.Product_SchemeModel = res.Item2;
    });
    
  }

  initializeSlabDetail(i) {
    this.Product_SchemeModel.push({
      From_Month: Number(this.Product_SchemeModel[i].To_Month) + 1,
      To_Month: this.ProductModel.Tenure_Max,
      Rate_Of_Interest: "",
      Rate_Of_Interest_SC: ""
    });
  }

  addMoreData(index: any) {
    if (this.Product_SchemeModel.length > 0) {
      let _prev = this.Product_SchemeModel[this.Product_SchemeModel.length - 1];
      //console.log("_prev", _prev);
      if (_prev.From_Month == "" || _prev.To_Month == "" || _prev.To_Month == null || _prev.Rate_Of_Interest == "" || _prev.Rate_Of_Interest == null || _prev.Rate_Of_Interest_SC == "" || _prev.Rate_Of_Interest_SC == null) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
      else if(_prev.To_Month == this.ProductModel.Tenure_Max){
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Month should be less than Maximum Tenure.", ...this.configSuccess });
        return;
      }
    }
    this.initializeSlabDetail(index);
  }

  removeData(index: any) {
    this.Product_SchemeModel.splice(index, 1);
    for (let i = 0; i < this.Product_SchemeModel.length; i++) {
      if (i != 0) {
        this.Product_SchemeModel[i].From_Month = Number(this.Product_SchemeModel[i - 1].To_Month) + 1;
      }
    }
  }

  ToValueChange(i) {
    //console.log("i", i);
    //console.log("this.Product_SchemeModel[i]", this.Product_SchemeModel[i]);
    if(this.Product_SchemeModel[0].To_Month < this.ProductModel.Tenure_Min){
      this.Product_SchemeModel[0].To_Month = '';
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'To Month not less than Minimum Tenure', ...this.configSuccess });
    }
    else{
      if (this.Product_SchemeModel[i].To_Month <= this.Product_SchemeModel[i].From_Month) {
        this.Product_SchemeModel[i].To_Month = '';
        if ((i + 1) < this.Product_SchemeModel.length)
          this.Product_SchemeModel[i + 1].From_Month = '';
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'To Month not less than From Month', ...this.configSuccess });
      } 
      else {
        if ((i + 1) < this.Product_SchemeModel.length) {
          this.Product_SchemeModel[i + 1].From_Month = this.Product_SchemeModel[i].To_Month + 1
          for (let i = 0; i < this.Product_SchemeModel.length; i++) {
            if (this.Product_SchemeModel[i].To_Month <= this.Product_SchemeModel[i].From_Month) {
              this.Product_SchemeModel[i].To_Month = '';
              this.Product_SchemeModel[i].From_Month = this.Product_SchemeModel[i - 1].To_Month + 1
            }
          }
        }
      }
    }
  }

  onChangeIntrestType(){
    this.ProductModel.Cummulative_Frequency = '';
    this.ProductModel.Payment_Frequency = '';
  }

  onChangeLockinPeriod(){
    if(Number(this.ProductModel.Lockin_Period) > Number(this.ProductModel.Tenure_Max)){
      this.ProductModel.Lockin_Period = '';
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Lockin Period should not be greater than Maximum Tenure', ...this.configSuccess });
    }
  }
  onChangePreMature() {
    if (this.ProductModel.IsPreMature == false) {
      this.ProductModel.Lockin_Period = '';
    }
  }
}
