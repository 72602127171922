import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-Common_Master',
  templateUrl: './Common_Master.component.html',
  styleUrls: ['./Common_Master.component.scss']
})
export class CommonMasterComponent implements OnInit {
  private data: any; 
  private _MasterService; 
  showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  Id: any;
  dataMasterSource: any;
  Value: any;
  Type: any;
  Commands: any;
  saveBtn: boolean = true;
  updateBtn: boolean = false;
  displayedMasterColumns: string[] = ['Id', 'Type', 'Value', 'Action'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Common Master');
    // this._MasterService.GetRoleForDropdown().subscribe((result) => {
    //   this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    // });
    this.Id = 0;
    this.LMS_GetMaster();
  }

  LMS_GetMaster() {
    this.Id = 0;
    this.showSpinner = true;
    this._LmsService.LMS_Commaon_Master({ Commands: 'All' }).subscribe((response) => {
      //console.log("Master Data",response);
      this.dataMasterSource = new MatTableDataSource(response);
      this.dataMasterSource.sort = this.sort;
      this.dataMasterSource.paginator = this.paginator;
    });
  }


  onSaveMaster(event: any) {
    let data = JSON.parse(sessionStorage.getItem('currentUser'));
    this._LmsService.LMS_Commaon_Master({ Commands: event, Type: this.Type.toUpperCase(), Value: this.Value, CreatedBy: data.userId,Id:this.Id}).subscribe(() => {
      if (event=="Insert"){
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Insert Successfuly", ...this.configSuccess });
        this.LMS_GetMaster();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Update Successfuly", ...this.configSuccess });
      }
    });
  }

  removeModel(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete.`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } 
      else {
        this.showSpinner = true;
        this._LmsService.LMS_Commaon_Master({ Commands: 'Delete', Id: data.Id }).subscribe((res: any) => {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Delete Successfuly", ...this.configSuccess });
          this.LMS_GetMaster();
        })
      }
    });
  }

  edit(data:any) {
    console.log('this.areaId',data);
    this.Type = data.Type.trim();
    this.Value = data.Value;
    this.Id = data.Id;
    this.saveBtn = false;
    this.updateBtn = true;
  }

  applyFilter(filterValue: string) {
    this.dataMasterSource.filter = filterValue.trim().toLowerCase();
  }

  //UpdateCrc() {
  //  this.saveBtn = true;
  //  this.updateBtn = false;
  //  let saveData = {
  //    "Int_Id": this.Id,
  //    "Crc_Type": this.CrcType,
  //    "Crc_Agency": this.CRCAgency,
  //    "IsActive": 0,
  //  }

  //  this._LmsService.Save_Lms_Crc_Master(saveData).subscribe((response) => {
  //    if (response[0].CODE == 0) {
  //      this.Id = 0;
  //      this.LMS_GetCrcMaster();
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //    } else {
  //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
  //    }
  //  });
  //}


}
