import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';

@Component({
  selector: 'app-lms-loan-closed-list',
  templateUrl: './lms-loan-closed-list.component.html',
  styleUrls: ['./lms-loan-closed-list.component.scss']
})
export class LmsLoanClosedListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  DataSource: any;
  BranchesList: any[] = [];
  ProductList: any = [];
  BranchId: any = 0;
  ProductId: any = 0;
  FileCheckingList: any[] = [];

  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    'Loan_Id', 'LoanAcNo', 'Loan_Date', 'Customer_Name', 'Branch_Name', 'Product_Name', 'Application_LoanAmount', 
    'Application_ExpiryDate', 'LoanClosed_Date', 'TotalWaiver_Amount', 'LoanClosed_By', 'NoOfNOC_Print', 'LastNOC_PrintBy','LastNOC_PrintOn', 'Action'
  ];

  selectedId: any;


  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _MasterService: MasterService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('NOC Print');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getBranchesList();
    this.getProductDropdown();
  }

  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  getProductDropdown() {
    this._MasterService.GetProductList({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
    });
  }

  DateChange() {
    if (this.FromDate != '' && this.ToDate != '') {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Date will be greater than to From Date.", ...this.configSuccess });
        this.FromDate = '';
        this.ToDate = '';
      }
    }
  }

  OnSearchLoanClosedList(){
    this.showSpinner = true;

    this.lmsService.LMS_Loan_Closed_List({ 
      LoginUserId: this.currentUser.userId, From: this.FromDate, To: this.ToDate, Branch_Id: this.BranchId, ProductId: this.ProductId
    })
    .subscribe((res: any) => {
      //console.log("Result123 : ", res);
      if (res.length > 0) {
        //debugger;
        this.FileCheckingList = JSON.parse(JSON.stringify(res));    
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.sort = this.sort;
        this.DataSource.paginator = this.paginator;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
      }
      this.showSpinner = false;
    });
  }
  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_loan_closed_list") as HTMLTableElement;
    const worksheetName = 'LOAN CLOSED LIST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

 
  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  }

  goToLoanClosedDetails(row: any){
    this.router.navigate([`loan/precloser/${'N'}/${this._EncrDecrService.encrypt(encodeURIComponent(row.Loan_Id))}/${this._EncrDecrService.encrypt(encodeURIComponent(row.CloserId))}`]);
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }
}
