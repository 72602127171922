import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
import * as moment from "moment";

@Component({
  selector: "app-request-and-task-detail",
  templateUrl: "./request-and-task-detail.component.html",
  styleUrls: ["./request-and-task-detail.component.scss"],
})
export class RequestAndTaskDetailComponent implements OnInit {
  currentUser: any;
  loading: boolean = false;
  showSpinner: boolean = false;
  showBtn: any = false;
  noteModel: any = {
    Loan_Id: 0,
    Title: "",
    Remark: "",
    FollowupBy: "",
    FollowupDate: "",
    LoginUserId: "",
    Type: "",
    StatusVal: "",
    Department: "",
    Priority: "",
    AddressType: "",
    Customer: "",
    CustomerResponse: "",
  };
  TableId: any = 0;
  ActionData: any = {};
  AddForm: any = false;
  FollowupByList: any[] = [];
  DepartmentList: any[] = [];
  RequestList: any[] = [];
  CustomerResponseList: any[] = [];
  updateModel: any = {};
  Tenure_Name: any;
  IRR_CalculateBy: any;
  OthersDocUrl: any = constantUrl.OthersDocUrl;

  stepEMI: any[] = [];
  DocModel: any[] = [];
  ApplicationDetail: any;
  today: Date = new Date();
  IsForword: boolean = false;
  stepEmiData: any;
  EMIDetails: any = {
    TotalEMI: 0,
    MatureEMI: 0,
    Remaining_EMI: 0,
    Received_EMI: 0,
    TotalEMI_Amount: 0,
    MatureEMI_Amount: 0,
    Remaining_EMI_Amount: 0,
    Received_EMI_Amount: 0,
  };
  LoanId: any = 0;
  customerDataSource: any;
  customerData: any;
  searchdata: any;
  LMS: any = "LMS";
  IsShowFollowup: boolean = true;
  Attachment: any = [{}];
  CompletedRemark: any;
  private _MasterService;
  AllData: any = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private _EncrDecrService: EncrDecrService,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private MasterService: MasterService,
    private dialog: DialogService
  ) {
    this._MasterService = MasterService;
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.TableId = decodeURIComponent(param.get("id")); // decodeURIComponent(param.get("id")); //encdec.decrypt(param.get("id"));
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Request And Task");
    this.noteModel.LoginUserId = this.currentUser.userId;
    this.ActionData = {};
    this.Get_DepartmentMaster();
    //this.getRequestType();
    //  this.dataSharingService.getLmsHeaderId().subscribe(value => {
    this.MasterService.EncriptToDcript({ Value: this.TableId }).subscribe(
      (res: any) => {
        console.log("DcriptToEncript", res);
        this.TableId = res[0].Value;

        if (Number(this.TableId) > 0) {
          this.lmsService
            .LMS_Get_CRM_Detail({ Id: this.TableId })
            .subscribe((res: any) => {
              this.AllData = res.Item1;
              console.log("AllData", this.AllData);
              this.ActionData = res.Item1[0];
              this.noteModel.Type = res.Item1[0].Type;
              console.log("note model type",this.noteModel.Type)
              this.TableId = res.Item1[0].Id;
              this.LoanId = res.Item1[0].LoanId;
              this.getloanDetail(res.Item1[0].LoanId);
              this.Attachment = res.Item2;
              if (this.Attachment.length == 0) {
                this.Attachment = [{}];
              }
              console.log("TableId", this.TableId);
              console.log("LoanId", this.LoanId);
              //let newdate;
              //if (res.item[0].FollowupDate != '' && res.item[0].FollowupDate != null && res.item[0].FollowupDate != undefined)
              //  newdate = new Date(res.item[0].FollowupDate);
              //this.noteModel = { Loan_Id: this.LoanId, Title: res.item[0].Title, Remark: res.item[0].Remarks, FollowupBy: res.item[0].FollowupBy, FollowupDate: newdate, LoginUserId: this.currentUser.userId, Type: res.item[0].Type, Department: res.item[0].Department }
            });
        } else {
          this.TableId = 0;
        }
      }
    );
    // });

    this.updateModel = {
      LoanId: this.LoanId,
      Type: "",
      Remark: "",
      FollowupBy: "0",
      FollowupDate: "",
    };
  }
  ChangeType() {
    this.IsShowFollowup = true;
    var type = this.noteModel.Type;
    var Loan_Id = this.noteModel.Loan_Id;
    this.noteModel = {
      Loan_Id: Loan_Id,
      Title: "",
      Remark: "",
      FollowupBy: "",
      FollowupDate: "",
      LoginUserId: this.currentUser.userId,
      Type: type,
      StatusVal: "",
      Department: "",
      Priority: "",
      AddressType: "",
      Customer: "",
      CustomerResponse: "",
    };

    if (
      this.noteModel.Type == "Notes" ||
      this.noteModel.Type == "Collection" ||
      this.noteModel.Type == "Follow-Up" ||
      this.noteModel.Type == "Customer Request" ||
      this.noteModel.Type == "Customer Task"
    ) {
      this.showBtn = true;
      this.AddForm = false;
    } else {
      this.showBtn = false;
    }
    if (
      this.noteModel.Type == "Internal Request" ||
      this.noteModel.Type == "Internal Task"
    ) {
      this.noteModel.Loan_Id = 0;
      this.LoanId = 0;
      this.AddForm = true;
    }
    this.getRequestType();
  }
  Get_DepartmentMaster() {
    this.loading = true;
    this.MasterService.Get_DepartmentMaster({}).subscribe((result) => {
      this.DepartmentList = JSON.parse(JSON.stringify(result));
      this.loading = false;
    });
  }
  getloanDetail(loanId) {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: loanId })
      .subscribe((res: any) => {
        //console.log("res :", res);
        this.loading = false;
        this.ApplicationDetail = res.Item1[0];
        if (
          this.ApplicationDetail.EMIType == "Daily" ||
          this.ApplicationDetail.EMIType == "Weekly" ||
          this.ApplicationDetail.EMIType == "Fortnightly" ||
          this.ApplicationDetail.EMI_Type == "ByWeekly" ||
          this.ApplicationDetail.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
        this.customerData = res.Item2;
        this.EMIDetails = res.Item7[0];

        if (this.noteModel.Type == "Collection") {
          this.noteModel.Department = "5";
          this.Get_Employee_ByBranch_Department();
        }
        this.StepEmiDetail(loanId);
      });
  }

  GetData(data) {
    console.log("data", data);
    this.searchdata = data;
    console.log("this.searchdata ", this.searchdata);
    var type = this.noteModel.Type;
    var Loan_Id = data.LoanId;
    this.LoanId = data.LoanId;
    this.noteModel = {
      Loan_Id: Loan_Id,
      Title: "",
      Remark: "",
      FollowupBy: "",
      FollowupDate: "",
      LoginUserId: this.currentUser.userId,
      Type: type,
      StatusVal: "",
      Department: "",
      Priority: "",
      AddressType: "",
      Customer: "",
      CustomerResponse: "",
    };

    this.getloanDetail(Loan_Id);
    if (
      data.LoanId > 0 &&
      (this.noteModel.Type == "Notes" ||
        this.noteModel.Type == "Collection" ||
        this.noteModel.Type == "Follow-Up" ||
        this.noteModel.Type == "Customer Request" ||
        this.noteModel.Type == "Customer Task")
    ) {
      this.AddForm = true;
    } else {
      this.AddForm = false;
    }
  }
  OnNoteClose() {
    this.OnCompletedModalClose();
    this._Route.navigate(["lms-dashboard/request-and-task"]);
  }
  OnCompletedModalClose() {
    $("#CompletedModal").modal("hide");
  }

  onSaveNote() {
    this.loading = true;
    this.noteModel.Id = 0;
    if (this.noteModel.FollowupBy == "") this.noteModel.FollowupBy = "0";

    if (this.noteModel.Department == "") this.noteModel.Department = "0";
    if (this.noteModel.Type == "Collection") {
      this.noteModel.FollowupBy = this.ApplicationDetail.CollectionExecutiveId;
    }
    this.noteModel.FollowupDate == "" ? null : this.noteModel.FollowupDate;
    //this.noteModel.FollowupBy = this.currentUser.userId;
    console.log("this.noteModel", this.noteModel);
    this.lmsService
      .LMS_Save_NoteRecommendation(this.noteModel)
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this._Route.navigate(["lms-dashboard/request-and-task"]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  Get_Employee_ByBranch_Department() {
    var DepartmentId = 0;
    var BrnachId = 0;
    var ProductId = 0;
    var Type = "";
    if (this.searchdata != undefined && this.searchdata.BranchId != "") {
      BrnachId = this.searchdata.BranchId;
    }
    if (this.searchdata != undefined && this.searchdata.ProductId != "") {
      ProductId = this.searchdata.ProductId;
    }
    if (this.noteModel.Department != "")
      DepartmentId = this.noteModel.Department;
    if (
      this.noteModel.Type == "Customer Request" ||
      this.noteModel.Type == "Internal Request"
    )
      Type = "Upper";
    if (
      this.noteModel.Type == "Customer Task" ||
      this.noteModel.Type == "Internal Task" ||
      this.noteModel.Type == "Follow-Up" ||
      this.noteModel.Type == "Collection"
    )
      Type = "Lower";
    if (DepartmentId > 0) {
      this.MasterService.Get_Employee_ByBranch_Department({
        BranchId: BrnachId,
        DeptId: DepartmentId,
        LoginUserId: this.currentUser.userId,
        Type: Type,
        ProductId: ProductId,
      }).subscribe((res: any) => {
        this.FollowupByList = res;
      });
    } else {
      this.FollowupByList = [];
      this.noteModel.FollowupBy = "0";
    }
  }

  Get_FolluwuBy_Department() {
    var DepartmentId = 0;
    var BrnachId = 0;
    var ProductId = 0;
    var Type = "";
    if (this.searchdata != undefined && this.searchdata.BranchId != "") {
      BrnachId = this.searchdata.BranchId;
    }
    if (this.searchdata != undefined && this.searchdata.ProductId != "") {
      ProductId = this.searchdata.ProductId;
    }
    if (this.updateModel.Department != "")
      DepartmentId = this.updateModel.Department;
    if (
      this.updateModel.Type == "Customer Request" ||
      this.updateModel.Type == "Internal Request"
    )
      Type = "Upper";
    if (
      this.updateModel.Type == "Customer Task" ||
      this.updateModel.Type == "Internal Task" ||
      this.updateModel.Type == "Follow-Up" ||
      this.updateModel.Type == "Collection"
    )
      Type = "Lower";
    this.MasterService.Get_Employee_ByBranch_Department({
      BranchId: BrnachId,
      DeptId: DepartmentId,
      LoginUserId: this.currentUser.userId,
      Type: Type,
      ProductId: ProductId,
    }).subscribe((res: any) => {
      this.FollowupByList = res;
    });
  }
  //Get_FolluwuBy_Department() {
  //  var DepartmentId = 0;
  //  var BrnachId=0
  //  var Type = "";
  //  if (this.searchdata.BranchId !="") {
  //    BrnachId = this.searchdata.BranchId;
  //  }
  //  if (this.noteModel.Department != "")
  //    DepartmentId = this.noteModel.Department
  //  if (this.noteModel.Type == 'Customer Request' || this.noteModel.Type == 'Internal Request')
  //    Type = "Upper";
  //  if (this.noteModel.Type == 'Customer Task' || this.noteModel.Type == 'Internal Task' || this.noteModel.Type == 'Follow-Up' || this.noteModel.Type == 'Collection')
  //    Type = "Lower";
  //  this.MasterService.Get_Employee_ByBranch_Department({ BranchId: BrnachId, DeptId: DepartmentId, LoginUserId: this.currentUser.userId, Type: Type }).subscribe((res: any) => {
  //    this.FollowupByList = res;
  //  });
  //}
  getRequestType() {
    if (this.noteModel.Type == "Collection") {
      this.lmsService
        .LMS_Commaon_Master({
          Commands: "Select",
          Type: "CustomerDefaultReason",
        })
        .subscribe((res: any) => {
          this.RequestList = res;
        });
    } else {
      this.lmsService
        .LMS_Commaon_Master({ Commands: "Select", Type: "REQUEST" })
        .subscribe((res: any) => {
          this.RequestList = res;
        });
    }
    this.lmsService
      .LMS_Commaon_Master({
        Commands: "Select",
        Type: "CustomerDefaultResponse",
      })
      .subscribe((res: any) => {
        this.CustomerResponseList = res;
      });
  }
  StepEmiDetail(LoanId) {
    this.lmsService
      .FinancialDetailsForUpdate({ Loan_Id: LoanId })
      .subscribe((res: any) => {
        if (res.Item1.length > 0) {
          this.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
          res.Item2.forEach((obj: any) => {
            this.stepEMI.push({
              FromEMI: obj.FromEMI,
              ToEMI: obj.ToEMI,
              EMI_Amount: obj.EMIAmount,
              NoOfInstl: obj.NoOfEMI,
              TotalAmount: obj.TotalAmount,
            });
          });
          this.getTotlaStepEmiAndAmount();
        }
      });
  }
  getTotlaStepEmiAndAmount() {
    let TotalEmi: any = 0,
      TotalAmount: any = 0;
    for (let i = 0; i < this.stepEMI.length; i++) {
      if (this.stepEMI[i].EMI_Amount) {
        TotalEmi =
          TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
        TotalAmount =
          TotalAmount +
          (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
            +this.stepEMI[i].EMI_Amount;
      }
    }
    this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
  }

  onSaveForworded() {
    if (this.updateModel.Type == "Collection") {
      this.updateModel.FollowupBy =
        this.ApplicationDetail.CollectionExecutiveId;
    }
    var JSONData = {
      Data: {
        LoanId: this.LoanId,
        Type: this.updateModel.Type,
        Remark: this.updateModel.Remark,
        FollowupBy: this.updateModel.FollowupBy,
        FollowupDate: this.updateModel.FollowupDate,
      },
    };
    this.lmsService
      .CRM_Request_Creation({
        Id: this.TableId,
        LoginUserId: this.currentUser.userId,
        Type: "Forward",
        JSON: JSON.stringify(JSONData),
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.IsForword = false;
          this.updateModel = {};

          this.OnNoteClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onForworded() {
    this.updateModel.Type = this.noteModel.Type;
    this.Get_DepartmentMaster();
    this.updateModel.Department = this.ActionData.Department;
    this.updateModel.Title = this.ActionData.Title;
    this.updateModel.Remark = this.ActionData.Remarks;
    this.updateModel.FollowupDate = this.ActionData.FollowupDate;
    this.Get_FolluwuBy_Department();
    this.updateModel.FollowupBy = this.ActionData.FollowupBy;
    if (this.noteModel.Type == "Collection") {
      this.updateModel.FollowupBy =
        this.ApplicationDetail.CollectionExecutiveId;
    }
    //this.IsForword = true;
    $("#noteModal").modal("show");
    $("#noteModal").css("z-index", "1050");
  }
  OnClose() {
    $("#noteModal").modal("hide");
  }
  OnCompletedDone() {
    this.Attachment.splice(this.Attachment.length - 1, 1);
    var saveData = { Attachment: this.Attachment };
    for (let i = 0; i < this.DocModel.length; i++) {
      this._MasterService
        .SaveOtherDocPath({
          DocName: this.DocModel[i].DocName,
          DocData: this.DocModel[i].DocData,
        })
        .subscribe((res: any) => {
          console.log("res", res);
        });
    }
    this.lmsService
      .CRM_Request_Creation({
        Id: this.TableId,
        LoginUserId: this.currentUser.userId,
        Type: "Complete",
        JSON: JSON.stringify(saveData),
        Remark: this.CompletedRemark,
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.OnNoteClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onCompleted() {
    $("#CompletedModal").modal("show");
    $("#CompletedModal").css("z-index", "1050");
    //this.lmsService.CRM_Request_Creation({ Id: this.TableId, LoginUserId: this.currentUser.userId, Type: "Complete", JSON: "",Remark:this.CompletedRemark }).subscribe((res: any) => {
    //  this.loading = false;
    //  if (res[0].CODE >= 0) {
    //    this.snackBar.openFromComponent(SnackbarComponent, {
    //      data: res[0].MSG,
    //      ...this.configSuccess,
    //    });

    //    this.OnNoteClose();
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, {
    //      data: res[0].MSG,
    //      ...this.configSuccess,
    //    });
    //  }
    //});
  }

  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.Attachment.splice(i, 1);
  }

  fileChangeListenerAttachment(files: any, input: any) {
    if (!this.Attachment[this.Attachment.length - 1].Title) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter  title.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.Attachment.find((obj) => {
      return obj.DocFileName == _fn;
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same file can not be allow again.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.Attachment[this.Attachment.length - 1].DocFileName =
      _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    input.value = "";
    this.Attachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };
  }
  CustomerResponseChange() {
    if (this.noteModel.Type == "Collection") {
      var _find = this.CustomerResponseList.find((obj) => {
        return obj.Value == this.noteModel.CustomerResponse;
      });
      if (_find.Code == "01") {
        this.IsShowFollowup = false;
        this.noteModel.FollowupDate = "";
        this.noteModel.FollowupBy = "0";
      } else {
        this.IsShowFollowup = true;
      }
    }
  }
}
