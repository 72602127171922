<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 align-items-end formborder">
    <div class="col-md-3 p-1">
      <span> Select Type </span>
      <select
        name="FilterType"
        id="FilterType"
        [(ngModel)]="FilterType"
        class="form-control input-text-css"
      >
        <option value="LOS">LOS</option>
        <option value="QuickLoan" selected>Quick Loan</option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span> Select Employee </span>
      <select
        name="FilterEmployeeId"
        id="FilterEmployeeId"
        [(ngModel)]="FilterEmployeeId"
        class="form-control input-text-css"
        (change)="onselectemployee()"
      >
        <option value="">Select Employee</option>
        <option *ngFor="let emp of GetEmployeeDropdown" [value]="emp.EmpId">
          {{ emp.EmpName }}
        </option>
      </select>
    </div>

    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="APId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ApplicationNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ApplicationNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanProduct">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanProduct }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Process }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssignedOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Assigned On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AssignedOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Status }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AP_ApplicationId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          AP_EmpId
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AP_ApplicationId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AP_ProcessId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          AP_ProcessId
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AP_ProcessId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_BranchId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application_BranchId
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_BranchId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AP_EmpId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          AP_EmpId
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AP_EmpId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="onOpenUpdateSwapModal(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="employeeSwap"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveSwapData()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Select New Employee For Swap
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-3 p-1">
              <span class="required-lable"> Employee </span>
            </div>
            <div class="col-md-9 p-1">
              <select
                name="AssignedToEmployee"
                id="AssignedToEmployee"
                #refAssignedToEmployee="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAssignedToEmployee.invalid,
                  'alert-warning':
                    refAssignedToEmployee.invalid &&
                    (refAssignedToEmployee.dirty ||
                      refAssignedToEmployee.touched ||
                      refAssignedToEmployee.untouched)
                }"
                [(ngModel)]="AssignedToEmployee"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Employee</option>
                <option
                  *ngFor="let employeeSwap of employeeSwapDropdown"
                  [value]="employeeSwap.EmpId"
                >
                  {{ employeeSwap.EmpName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveSwapData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
