<div class="formborder">
  <div>
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <div class="row m-0 mt-1">
      <div class="col-md-12"><h1 class="fs-12 h-b">Loan Information</h1></div>
    </div>

    <div class="row m-0 mt-1">
      <!--<div class="col-md-2">
      <select name="Type" id="Type" class="form-control input-text-css" [(ngModel)]="Type" (change)="showHead()" required>
        <option value="Application" selected>Application No.</option>
        <option value="case">Case No.</option>
      </select>
    </div>-->
      <div class="col-md-4">
        <input
          required
          type="text"
          placeholder=""
          name="AppCaseNo"
          id="AppCaseNo"
          [(ngModel)]="AppCaseNo"
          class="form-control input-text-css"
        />
        <button
          type="button"
          (click)="searchCustomer()"
          class="primary-btn ml-2 search"
          [disabled]="!AppCaseNo"
        >
          <i
            class="fa fa-search"
            aria-hidden="true"
            style="font-size: 11px"
          ></i>
        </button>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Loan Product</h6>
        <p class="fs-12">{{ ReceiptDetail.Product_Name }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Branch</h6>
        <p class="fs-12">{{ ReceiptDetail.Branch_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">CreateOn</h6>
        <p class="fs-12">{{ ReceiptDetail.PLoanCreatedOn }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Net Finance</h6>
        <p class="fs-12">{{ ReceiptDetail.LoanAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Interest Amount</h6>
        <p class="fs-12">{{ ReceiptDetail.InterestAmt }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Agreement Value</h6>
        <p class="fs-12">{{ ReceiptDetail.AgreementValue }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">First EMI Date</h6>
        <p class="fs-12">{{ ReceiptDetail.FirstEMIDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Expiry Date</h6>
        <p class="fs-12">{{ ReceiptDetail.ExpiryDate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">No of Installment</h6>
        <p class="fs-12">{{ ReceiptDetail.No_Of_Instl }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">EMI Amount</h6>
        <p class="fs-12">{{ ReceiptDetail.LoanEMIAmount }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Asset Cost</h6>
        <p class="fs-12">{{ ReceiptDetail.AssetCost }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">ROI</h6>
        <p class="fs-12">{{ ReceiptDetail.Flat_Rate }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Case IRR</h6>
        <p class="fs-12">{{ ReceiptDetail.Case_IRR }}</p>
      </div>
    </div>

    <div class="row m-0 mt-1">
      <div class="col-md-12">
        <h1 class="fs-12 h-b">Customer Information</h1>
      </div>
    </div>

    <div class="row m-0 mt-1">
      <div class="col-md-3">
        <h6 class="fs-12">Customer Name</h6>
        <p class="fs-12">{{ ReceiptDetail.CustomerName }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Mobile No.</h6>
        <p class="fs-12">{{ ReceiptDetail.Customer_PhoneNo }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Customer Address</h6>
        <p class="fs-12" style="line-height: normal">
          {{ ReceiptDetail.Address }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Email Id</h6>
        <p class="fs-12">{{ ReceiptDetail.Customer_Email }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">State</h6>
        <p class="fs-12">{{ ReceiptDetail.State_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">District</h6>
        <p class="fs-12">{{ ReceiptDetail.District_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Tehsil</h6>
        <p class="fs-12">{{ ReceiptDetail.Tehsil_Name }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Pin-Code</h6>
        <p class="fs-12">{{ ReceiptDetail.PinCode }}</p>
      </div>
    </div>

    <div class="row m-0 mt-1">
      <div class="col-md-12"><h1 class="fs-12 h-b">Receipt Detail</h1></div>
    </div>

    <div class="row m-0 mt-1">
      <div class="col-md-4">
        <h6 class="fs-12">Receipt Date</h6>
        <h6 class="fs-12">{{ CurrentDate }}</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12">Receipt No.</h6>
        <h6 class="fs-12">{{ receiptNo }}</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12">Voucher No.</h6>
        <h6 class="fs-12">{{ voucherModel.Voucher.Voucher_No }}</h6>
      </div>
    </div>

    <div
      class="row m-0 mt-3"
      [hidden]="!showCaseForm"
      *ngFor="let head of caseHead; let i = index"
    >
      <div class="col-md-4">
        <h6 class="fs-12 bold">Account Head</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12 bold" style="text-align: center">Balance Amount</h6>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12 bold" style="text-align: center">Deposit Amount</h6>
      </div>

      <div class="col-md-4">
        <h6 class="fs-12 form-control input-text-css">Installment</h6>
      </div>
      <div class="col-md-4">
        <input
          required
          type="text"
          placeholder=""
          [(ngModel)]="head.Installment"
          name="Installment{{ i }}"
          id="Installment{{ i }}"
          class="form-control input-text-css right-text pr-3"
          disabled
        />
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">
          <input
            required
            type="number"
            placeholder="overdue EMI"
            [(ngModel)]="PayInstallment"
            (change)="getcaseTotalAmount()"
            name="overdueEMI"
            id="overdueEMI"
            class="form-control input-text-css pr-3 text-right"
          />
        </h6>
      </div>

      <div class="col-md-4">
        <h6 class="fs-12 form-control input-text-css">Overdue Interest</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="head.Interest"
            name="Interest{{ i }}"
            id="Interest{{ i }}"
            class="form-control input-text-css right-text pr-3"
            disabled
          />
        </h6>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">
          <input
            required
            type="number"
            placeholder=""
            name="overdueInt"
            [(ngModel)]="PayInterest"
            (change)="getcaseTotalAmount()"
            id="overdueInt"
            class="form-control input-text-css text-right pr-3"
          />
        </h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12 form-control input-text-css">Other Dues</h6>
      </div>
      <div class="col-md-4">
        <h6 class="fs-12">
          <input
            required
            type="text"
            placeholder=""
            [(ngModel)]="head.OtherDues"
            name="OtherDues{{ i }}"
            id="OtherDues{{ i }}"
            class="form-control input-text-css right-text pr-3"
            disabled
          />
        </h6>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">
          <input
            required
            type="number"
            placeholder=""
            name="OtherDues"
            id="OtherDues"
            [(ngModel)]="PayOtherDues"
            (change)="getcaseTotalAmount()"
            class="form-control input-text-css text-right pr-3"
          />
        </h6>
      </div>
    </div>
    <!-- <div class="row m-0 mt-3" [hidden]="!showApplicationForm">
    <div class="col-md-4">
      <h6 class="fs-12 bold">Account Head</h6>
    </div>
    <div class="col-md-4">
      <h6 class="fs-12 bold" style=" text-align: center;">Balance Amount</h6>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12 bold" style=" text-align: center;">Deposit Amount</h6>
    </div>
  </div> -->

    <form #df="ngForm" novalidate>
      <div
        class="row m-0 add-remove-btn"
        *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index"
      >
        <div class="col-md-12 row m-0 type-column">
          <div class="row feild">
            <ng-container>
              <div class="col-md-4">
                <!--<span class="required-lable" [hidden]="!showCaseForm">Account Head</span>-->
                <h6 class="fs-12 bold">
                  <select
                    name="ChargeHeadId{{ i }}"
                    id="ChargeHeadId{{ i }}"
                    required
                    #refChargeHeadId="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refChargeHeadId.invalid,
                      'alert-warning':
                        refChargeHeadId.invalid &&
                        (refChargeHeadId.dirty ||
                          refChargeHeadId.touched ||
                          refChargeHeadId.untouched)
                    }"
                    [(ngModel)]="vdData.ChargeHeadId"
                    class="form-control input-text-css"
                    (change)="onChangeChargesHead(vdData, i)"
                  >
                    <option value="">Select Category</option>
                    <option
                      *ngFor="let item of chargesHeadDropdown"
                      [value]="item.Id"
                    >
                      {{ item.ChagesHead }}
                    </option>
                  </select>
                </h6>
              </div>
              <div class="col-md-4">
                <!--<span [hidden]="!showCaseForm"> Balance Amount</span>-->
                <h6 class="fs-12 bold">
                  <input
                    type="text"
                    name="Amount{{ i }}"
                    id="Amount{{ i }}"
                    class="form-control input-text-css right-text pr-3"
                    (change)="onChangeAmount($event, vdData)"
                    readonly
                    [(ngModel)]="vdData.Amount"
                  />
                </h6>
              </div>
              <div class="col-md-2">
                <!--<span class="required-lable" [hidden]="!showCaseForm">Amount</span>-->
                <h6 class="fs-12 bold">
                  <input
                    type="number"
                    min="0"
                    name="PayAmount{{ i }}"
                    id="PayAmount{{ i }}"
                    [(ngModel)]="vdData.PayAmount"
                    class="form-control input-text-css text-right pr-3"
                    required
                    #refAmount="ngModel"
                    (change)="onChangeAmount($event, vdData)"
                    (keyup.enter)="onChangeAmount($event, vdData)"
                    [ngClass]="{
                      'is-invalid': df.submitted && refAmount.invalid,
                      'alert-warning':
                        refAmount.invalid &&
                        (refAmount.dirty ||
                          refAmount.touched ||
                          refAmount.untouched)
                    }"
                  />
                </h6>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="vdData.TaxSlab && vdData.TaxSlab.length > 0">
          <div
            class="col-md-12 row m-0 p-0"
            *ngFor="let taxData of vdData.TaxSlab; let ti = index"
          >
            <div class="col-md-4">
              <h6 class="fs-12 form-control input-text-css">
                {{ taxData.TaxType }}
              </h6>
              <!--<span><b>Slab :</b>&nbsp;{{taxData.SlabName}}</span>-->
            </div>
            <div class="col-md-4">
              <h6
                class="fs-12 form-control input-text-css text-right pr-3"
                readonly
              >
                0.00
              </h6>
              <!--<span><b>Type :</b>&nbsp;{{taxData.TaxType}} </span>-->
            </div>
            <div class="col-md-2">
              <h6
                class="fs-12 form-control input-text-css text-right pr-3"
                readonly
                *ngIf="taxData.TaxAmount"
              >
                {{ taxData.TaxAmount.toFixed(2) }}
              </h6>
              <!--<span><b>Rate :</b>&nbsp;{{taxData.TaxRate}} %</span>-->
            </div>
            <!--<div class="col-md-2 pr-0">
            <span><b>Account Head :</b>&nbsp;{{taxData.TaxAccountName}}</span>
          </div>
          <div class="col-md-2 text-right pr-5">
            <span><b></b>&nbsp;<ng-container *ngIf="taxData.TaxAmount">{{taxData.TaxAmount.toFixed(2)}}</ng-container></span>
          </div>-->
          </div>
        </ng-container>

        <button
          type="button"
          class="remove"
          (click)="removeData(i)"
          [ngClass]="{ 'no-head': i != 0 }"
          *ngIf="voucherModel.Voucher_Detail.length > 1"
        >
          -
        </button>
        <button
          type="button"
          class="addmore"
          (click)="addMoreData()"
          [ngClass]="{
            both: voucherModel.Voucher_Detail.length > 1,
            'no-head': i != 0
          }"
          [disabled]="!df.form.valid"
          *ngIf="i == voucherModel.Voucher_Detail.length - 1"
        >
          +
        </button>
      </div>
      <div class="row m-0 mt-2" *ngIf="totalCalculator">
        <div class="col-md-4">
          <h6 class="fs-12 form-control input-text-css bold">Grand Total</h6>
        </div>
        <div class="col-md-4">
          <h6 class="fs-12 form-control input-text-css text-right bold pr-3">
            {{ totalCalculator.total.toFixed(2) }}
          </h6>
          <!--<p style=" line-height: normal;"><b>In Word :</b>&nbsp;{{totalCalculator.totalInWord}} </p>
        <p><b>In Digit :</b>&nbsp;{{totalCalculator.total.toFixed(2)}}</p>-->
        </div>
        <div class="col-md-2 text-right">
          <h6 class="fs-12 form-control input-text-css text-right bold pr-3">
            {{ totalCalculator.payTotal.toFixed(2) }}
          </h6>
          <!--<p style=" line-height: normal;"><b>In Word :</b>&nbsp;{{totalCalculator.payTotalInWord}} </p>
        <p><b>In Digit :</b>&nbsp;{{totalCalculator.payTotal.toFixed(2)}}</p>-->
        </div>
      </div>
      <div class="col-md-12 row m-0 p-0 pr-4 mt-0" *ngIf="totalCalculator">
        <div class="col-md-12">
          <p class="bold" style="line-height: normal">
            <b>Deposite Amount In Word :</b>&nbsp;{{
              totalCalculator.payTotalInWord
            }}
          </p>
        </div>
      </div>
    </form>

    <div class="row m-0">
      <div class="col-md-12"><h1 class="fs-12 h-b">Payment Mode</h1></div>
    </div>

    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <span class="required-lable">Select Tag </span>
        <select
          name="Voucher_Tag"
          id="Voucher_Tag"
          required
          (change)="onVoucherTagChange()"
          class="form-control input-text-css"
          #refVoucher_Tag="ngModel"
          [(ngModel)]="voucherModel.Voucher.Voucher_Tag"
          [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Tag.invalid,
            'alert-warning':
              refVoucher_Tag.invalid &&
              (refVoucher_Tag.dirty ||
                refVoucher_Tag.touched ||
                refVoucher_Tag.untouched)
          }"
        >
          <option value="Bank">Bank</option>
          <option value="CASH">CASH</option>
        </select>
      </div>

      <div class="col-md-4">
        <span class="required-lable">Select Account</span>
        <select
          name="DRAccountId"
          id="DRAccountId"
          required
          #refDRAccountId="ngModel"
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': f.submitted && refDRAccountId.invalid,
            'alert-warning':
              refDRAccountId.invalid &&
              (refDRAccountId.dirty ||
                refDRAccountId.touched ||
                refDRAccountId.untouched)
          }"
          [(ngModel)]="voucherModel.Voucher.DRAccountId"
        >
          <option value="">Select Account</option>
          <option
            *ngFor="let data of accountTagDropdown"
            [value]="data.AccountId"
          >
            {{ data.Account_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-4" *ngIf="voucherModel.Voucher.Voucher_Tag == 'Bank'">
        <span class="required-lable">Select Mode of Payment</span>
        <select
          name="Voucher_Mode_of_Payment"
          id="Voucher_Mode_of_Payment"
          required
          (change)="onChangeMode()"
          class="form-control input-text-css"
          #refVoucher_Mode_of_Payment="ngModel"
          [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
          [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Mode_of_Payment.invalid,
            'alert-warning':
              refVoucher_Mode_of_Payment.invalid &&
              (refVoucher_Mode_of_Payment.dirty ||
                refVoucher_Mode_of_Payment.touched ||
                refVoucher_Mode_of_Payment.untouched)
          }"
        >
          <option value="">Select Mode Of Payment</option>
          <option value="Cheque">Cheque</option>
          <option value="RTGS">RTGS</option>
          <option value="Gateway">E-Gateway</option>
          <option value="NEFT">NEFT</option>
          <option value="IMPS">IMPS</option>
          <option value="Transfer">Transfer</option>
        </select>
      </div>
    </div>
  </div>
  <div
    class="row m-0 mt-1"
    *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != ''"
  >
    <ng-container
      *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'"
    >
      <div class="col-md-4">
        <span class="required-lable">Cheque No</span>
        <input
          type="text"
          required
          #refVoucher_Cheque_No="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Cheque_No.invalid,
            'alert-warning':
              refVoucher_Cheque_No.invalid &&
              (refVoucher_Cheque_No.dirty ||
                refVoucher_Cheque_No.touched ||
                refVoucher_Cheque_No.untouched)
          }"
          [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
          name="Voucher_Cheque_No"
          id="Voucher_Cheque_No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-4">
        <span class="required-lable">Cheque Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="pickervcd"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Voucher_Cheque_Date"
            id="Voucher_Cheque_Date"
            #refVoucher_Cheque_Date="ngModel"
            [ngClass]="{
              'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid,
              'alert-warning':
                refVoucher_Cheque_Date.invalid &&
                (refVoucher_Cheque_Date.dirty ||
                  refVoucher_Cheque_Date.touched ||
                  refVoucher_Cheque_Date.untouched)
            }"
            class="form-control input-text-css"
            [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickervcd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickervcd></mat-datepicker>
        </div>
        <!-- <input name="Voucher_Cheque_Date" id="Voucher_Cheque_Date" required #refVoucher_Cheque_Date="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Date.invalid, 'alert-warning': refVoucher_Cheque_Date.invalid  && (refVoucher_Cheque_Date.dirty || refVoucher_Cheque_Date.touched || refVoucher_Cheque_Date.untouched) }"
        [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
        [(bsValue)]="voucherModel.Voucher.Voucher_Cheque_Date"
        value="{{ voucherModel.Voucher.Voucher_Cheque_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" bsDatepicker> -->
      </div>
      <div class="col-md-4">
        <span class="required-lable">Bank Name</span>
        <input
          type="text"
          required
          #refVoucher_Bank_Name="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refVoucher_Bank_Name.invalid,
            'alert-warning':
              refVoucher_Bank_Name.invalid &&
              (refVoucher_Bank_Name.dirty ||
                refVoucher_Bank_Name.touched ||
                refVoucher_Bank_Name.untouched)
          }"
          [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
          name="Voucher_Bank_Name"
          id="Voucher_Bank_Name"
          class="form-control input-text-css"
        />
      </div>
      <!--<div class="col-md-3">
      <span class="">Cheque Clear Date </span>
      <div class="datepicker_feild">
        <input [matDatepicker]="pickervccd" dateConvert placeholder="DD/MM/YYYY" name="Voucher_Cheque_Clear_Date"
          id="Voucher_Cheque_Clear_Date" #refVoucher_Cheque_Clear_Date="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Clear_Date.invalid, 'alert-warning': refVoucher_Cheque_Clear_Date.invalid  && (refVoucher_Cheque_Clear_Date.dirty || refVoucher_Cheque_Clear_Date.touched || refVoucher_Cheque_Clear_Date.untouched) }"
          class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date">
        <mat-datepicker-toggle matSuffix [for]="pickervccd"></mat-datepicker-toggle>
        <mat-datepicker #pickervccd></mat-datepicker>
      </div>-->
      <!-- <input name="Voucher_Cheque_Clear_Date" id="Voucher_Cheque_Clear_Date" #refVoucher_Cheque_Clear_Date="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Clear_Date.invalid, 'alert-warning': refVoucher_Cheque_Clear_Date.invalid  && (refVoucher_Cheque_Clear_Date.dirty || refVoucher_Cheque_Clear_Date.touched || refVoucher_Cheque_Clear_Date.untouched) }"
        [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date"
        [(bsValue)]="voucherModel.Voucher.Voucher_Cheque_Clear_Date"
        value="{{ voucherModel.Voucher.Voucher_Cheque_Clear_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" bsDatepicker> -->
      <!--</div>-->
    </ng-container>
    <ng-container
      *ngIf="voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'"
    >
      <div class="col-md-4">
        <span class="required-lable">Ref No</span>
        <input
          type="text"
          required
          #refVoucher_E_Ref_No="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refVoucher_E_Ref_No.invalid,
            'alert-warning':
              refVoucher_E_Ref_No.invalid &&
              (refVoucher_E_Ref_No.dirty ||
                refVoucher_E_Ref_No.touched ||
                refVoucher_E_Ref_No.untouched)
          }"
          [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
          name="Voucher_E_Ref_No"
          id="Voucher_E_Ref_No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-4">
        <span class="required-lable">Transaction Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="pickervetd"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="Voucher_E_Transaction_Date"
            id="Voucher_E_Transaction_Date"
            #refVoucher_E_Transaction_Date="ngModel"
            [max]="voucherModel.Voucher.Voucher_Date"
            [ngClass]="{
              'is-invalid':
                f.submitted && refVoucher_E_Transaction_Date.invalid,
              'alert-warning':
                refVoucher_E_Transaction_Date.invalid &&
                (refVoucher_E_Transaction_Date.dirty ||
                  refVoucher_E_Transaction_Date.touched ||
                  refVoucher_E_Transaction_Date.untouched)
            }"
            class="form-control input-text-css"
            [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickervetd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickervetd></mat-datepicker>
        </div>
        <!-- <input name="Voucher_E_Transaction_Date" id="Voucher_E_Transaction_Date" required
        #refVoucher_E_Transaction_Date="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && refVoucher_E_Transaction_Date.invalid, 'alert-warning': refVoucher_E_Transaction_Date.invalid  && (refVoucher_E_Transaction_Date.dirty || refVoucher_E_Transaction_Date.touched || refVoucher_E_Transaction_Date.untouched) }"
        [(ngModel)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
        [(bsValue)]="voucherModel.Voucher.Voucher_E_Transaction_Date"
        value="{{ voucherModel.Voucher.Voucher_E_Transaction_Date | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" bsDatepicker> -->
      </div>
    </ng-container>
  </div>
  <div class="row m-0 mt-1">
    <div class="col-md-12">
      <span class="">Voucher Narration</span>
      <input
        type="text"
        #refVoucher_Narration="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refVoucher_Narration.invalid,
          'alert-warning':
            refVoucher_Narration.invalid &&
            (refVoucher_Narration.dirty ||
              refVoucher_Narration.touched ||
              refVoucher_Narration.untouched)
        }"
        [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
        name="Voucher_Narration"
        id="Voucher_Narration"
        class="form-control input-text-css"
      />
    </div>
  </div>
  <div class="row col-md-12 m-0 mt-2 justify-content-end">
    <button type="button" class="mt-3 btn font-size-12 button-btn">
      Cancel
    </button>

    <button
      type="button"
      class="ml-4 mt-3 btn font-size-12 button-btn"
      (click)="saveVoucher()"
      [class.spinner]="loading"
      [disabled]="!f.form.valid"
    >
      Save
    </button>
  </div>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Receipt
        </h6>
        <button
          type="button"
          (click)="onCloseReceipt()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-3">
              <span>Type</span>
              <select
                name="Type"
                id="Type"
                class="form-control input-text-css"
                [(ngModel)]="CustomerType"
              >
                <option [value]="0">All</option>
                <option [value]="1">LOS Only</option>
                <option [value]="2">LMS Only</option>
              </select>
            </div>
            <div class="col-md-3">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        <div class="table-responsive mt-3">
          <mat-table
            [dataSource]="dataSourceCustomer"
            #sortList="matSort"
            matSort
            id="exportReport"
          >
            <ng-container matColumnDef="CustomerId">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw50"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw50"
                *matHeaderCellDef
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                row.Type
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Application_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw100"
                *matHeaderCellDef
                >Aplication No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw100">
                {{ row.Application_No }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="LoanAcNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw100"
                *matHeaderCellDef
              >
                Loan No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                row.LoanAcNo
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomertName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Customer</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell"
                >{{ row.CustomertName }}({{ row.AC_CustomerType }})</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="FatherName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Relation Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.FatherName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_Gender">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw50"
                *matHeaderCellDef
                >Gender</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                row.Customer_Gender
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="GenderAge">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw50"
                *matHeaderCellDef
              >
                Age</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                row.GenderAge
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Phone No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.PhoneNo }}
                <i
                  *ngIf="row.Customer_PhoneNo_IsVerified == true"
                  class="fa fa-check-circle ml-2"
                  style="color: green"
                ></i>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="EditAction">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw100"
                *matHeaderCellDef
              >
                View Detail</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center mw100"
              >
                <i
                  class="fa fa-arrow-circle-right"
                  (click)="goToDetail(row)"
                  style="cursor: pointer; color: green; font-size: 16px"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerColumns"
            ></mat-row>
          </mat-table>

          <mat-paginator
            #paginatorRef
            #PaginatorList
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
      <!--<div class="modal-body">
        <div class="row m-0 mt-3">
          <form #lff="ngForm"  novalidate>
            <div class="row m-0 ">
              <div class="col-md-6 mb-3">
                <span> Search By: </span>
                <select name="FilterSearchBy" id="FilterSearchBy" class="form-control input-text-css" required>
                  <option value="">Select</option>
                  <option value="Pan">PAN Card</option>
                  <option value="Passport">Passport Copy</option>
                  <option value="VoterId">Voter ID Card</option>
                  <option value="DL">Driving License</option>
                  <option value="Aadhar">Aadhaar Card</option>
                </select>
              </div>
              <div class="col-md-6">
                <span>Doc No </span>
                <input type="text" required name="number" id="number" class="form-control input-text-css" />
              </div>
            </div>
          </form>
          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button type="button" (click)="onCloseReceipt()" class="btn btn-light font-size-12" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" (click)="onOkReceipt()" class="ml-4" style="background-color: #28a745; color: #ffffff; font-size: 12px"
              mat-raised-button color="primary" [class.spinner]="loading" [disabled]="!lff.form.valid">
              Search
            </button>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ReceiptPrint"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel"></h6>
        <button
          type="button"
          (click)="onCloseReceiptPrint()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          (click)="printLetter()"
        >
          Print
        </button>
        <div id="pdfReceiptDownload">
          <div>
            <p style="text-align: center; letter-spacing: 7px">OFFICE COPY</p>
            <p style="text-align: center">
              In case Contract is Terminated this Receipt is issued strictly
              without prejudice
            </p>
            <h3 style="text-align: center">{{ systemconfig.CompanyName }}</h3>
            <p style="text-align: center">
              {{ systemconfig.CompanyHeadOfficeAddress }}
            </p>
            <p style="text-align: center">
              Ph. {{ systemconfig.CompanyPhoneNo }} Email.
              {{ systemconfig.ComapnyEmail }}
            </p>
            <p style="text-align: center; text-decoration: underline">
              <i>RECEIPT</i>
            </p>
          </div>
          <div style="font-style: italic; margin: 10px">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
              "
            >
              <p
                style="
                  float: left;
                  flex: 0 0 66.66666667%;
                  max-width: 66.66666667%;
                "
              >
                Receipt No:{{ receiptNo }}
              </p>
              <p
                style="
                  float: right;
                  width: 100%;
                  flex: 0 0 33.33333333%;
                  max-width: 33.33333333%;
                "
              >
                Dated:{{ CurrentDate }}
              </p>
            </div>
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
              "
            >
              <p
                style="
                  float: left;
                  width: 100%;
                  flex: 0 0 33.33333333%;
                  max-width: 33.33333333%;
                "
              >
                Loan File No: {{ LoanAcNo }}
              </p>
            </div>

            <hr />
            <div>
              <p>Received with thanks from: {{ customerDetail.Customer }}</p>
              <p>Address: {{ addressModel.Address }}</p>
              <p *ngIf="totalCalculator">
                a sum of Rs. {{ totalCalculator.payTotalInWord }}
              </p>
              <p>
                By: Cash/Cheque/DD no.
                <span *ngIf="Voucher_Cheque_No">{{ Voucher_Cheque_No }}</span>
              </p>
            </div>
            <div>
              <div style="text-align: right">
                For {{ systemconfig.CompanyName }}
              </div>
              <div
                *ngIf="totalCalculator"
                style="
                  text-decoration: underline;
                  padding: 19px;
                  box-sizing: border-box;
                  border: 2px solid #565656;
                  border-radius: 5px;
                  width: 100px;
                "
              >
                ****{{ totalCalculator.payTotal }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
