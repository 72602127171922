import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  Injectable,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { DialogService } from "../../Shared/dialog.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
import { constantUrl } from "../../Shared/constantUrl";
import { PartnerService } from "../../_Partner/services/partner.service";

import { CommonFunctions } from "../../CommonComponent/function/commonfunctions";

declare var $: any;
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DatePipe } from "@angular/common";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-lms-loanStatement",
  templateUrl: "./lms-loanStatement.component.html",
  styleUrls: ["./lms-loanStatement.component.scss"],
})
export class LmsLoanStatementComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  ChargesJson: any[] = [];
  CustomerList: any[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  ApplicationDetail: any;
  showSpinner: boolean = false;
  tableshow: boolean = false;
  TenureName: any = "Months";
  showLoanOD: boolean = false;
  showLoanODCharges: boolean = true;
  showLoanIncomeCharges: boolean = true;
  showLess_LoanDetail: boolean = false;
  showLess_FinanceDetail: boolean = false;
  showLess_CustomerDetail: boolean = false;
  showLess_AssetsDetail: boolean = false;
  LoanDetail: boolean = false;
  dataSource: any;
  assetModel: any = {};
  dataSourceLoan: any;
  displayedColumnsStatement: any;
  Collateral: any;
  displayedColumns: string[] = [];
  displayedColumnsOD: string[] = [];
  displayedColumnsODCharges: string[] = [];
  displayedChargesColumns: string[] = [];
  dataSourceLoanODCharges: any;
  dataSourceLoanIncomeCharges: any;
  dataSourceChargesDetail: any;
  dataSourceLoanOD: any;
  ChargeHeadId: any[] = [];
  selectedItems: any[] = [];
  Customer: any = {};
  loading: boolean = false;
  withAccountSummary: boolean = false;
  ChargesName: any = "";
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  myForm: UntypedFormGroup;
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  dropdownSettings: any = {};
  chargesHeadDropdown: any[] = [];
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  ApplicationNo: any;
  LoanNo: any;
  dataSourceCustomer: any;
  assetTotal: any;
  displayedCustomerColumns: string[] = [];
  dataAssetItemSource: any;
  customerDataSource: any;
  customerData: any;
  ROW: any;
  displayedAssetItemColumns: string[] = [];
  VoucherDateTo: any = new Date();
  hoveredRow: any = null;
  hoverX: number = 0;
  hoverY: number = 0;

  displayedCustomerDetailColumns: string[] = [
    "CustomerId",
    "Customer",
    "CustomerType",
    "GenderAge",
    "ExistingCustomer",
    // "Customer_IsFirm",
    "Relation_With_Hirer",
    "PhoneNo",
    "Present_Address",
    "Detail",
  ];
  displayedLoanColumns: string[] = [
    "Type",
    "CaseNo",
    "Customer",
    "Branch",
    "Category",
    "AccountHead",
    "AmountDR",
    "AmountCR",
  ];

  displayedAccountingColumns: string[] = [
    "AccountHead",
    "Branch",
    "AmountDR",
    "AmountCR",
  ];
  ShowDelete: boolean = false;
  displayedAssetsColumns: any[] = [
    "AssetId",
    "Collateral_Type",
    "Collateral",
    "ValuationType",
    "ValuationAmount",
    "ValuationDate",
    "ActionView",
    "Action",
  ];
  dataSourceAssets: any;
  Type: any = "0";
  @Input() loanSummary: any;
  @Input() isShowHeader: boolean = true;
  SourceAssets: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator })
  paginatorlist: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SessionLoanSummary: any;
  @Input() FirstEMIDate: any;
  @Input() PLoanType: any;
  @Input() PageType: any;

  HiddenContent: boolean = false;
  Cur_Date: any = new Date();
  AccHeadSummary: any[] = [];
  AccHeadDetails: any[] = [];
  ODDetails: any[] = [];
  DataSourceAccHeadD: any;
  EMIDetails: any;
  DisbursementDetails: any[] = [];
  IRR_CalculateBy: any;
  stepEMI: any[] = [];
  stepEmiData: any;
  LoanId: any;
  totalCRAmount: number = 0;
  totalDRAmount: number = 0;

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private _MasterService: MasterService,
    private dialog: DialogService,
    private lmsService: LmsService,
    private fb: UntypedFormBuilder,
    private _Partnerservice: PartnerService,
    private _commonfunctions: CommonFunctions
  ) {}

  ngOnInit() {
   // this.onSearchStatementDetail();
    if (this.isShowHeader) {
      this.dataSharingService.HeaderTitle.next("Loan Statement");
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getChargeshead();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "Id",
      textField: "ChagesHead",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };
    this.myForm = this.fb.group({
      chargesHead: [],
    });
    this.ChargeHeadId = [{ Id: 34, ChagesHead: "Installment" }];
    this.displayedColumns = [
      "voucher_id",
      "Voucher_Date",
      "Voucher_No",
      "CaseNo",
      "Customer_Name",
      "accHead",
      "TranTypeDR",
      "TranTypeCR",
      "TranTypeTotal",
      "Narration",
      "ViewAction",
    ];
    this.displayedColumnsStatement = [
      "voucherid",
      "VoucherDate",
      "Voucher_No",
      "Receipt_No",
      "accHead",
      "TranTypeDR",
      "TranTypeCR",
      "TranTypeTotal",
      "Narration",
    ];
    this.displayedColumnsODCharges = [
      "HeadId",
      "ChagesHead",
      "TranTypeDR",
      "TranTypeCR",
      "TranTypeTotal",
      "Action",
    ];
    this.displayedColumnsOD = [
      //"voucherid", "VoucherDate", "Voucher_No",
      "voucherid",
      "VoucherDate",
      "Voucher_No",
      "OS_Balance",
      "DaysLate",
      "ODInterest",
      "ODCredit",
      "ODTotal",
    ];

    //This code for Loan Statment Page only
    if (JSON.parse(sessionStorage.getItem("LoanSummary"))) {
      this.SessionLoanSummary = JSON.parse(
        sessionStorage.getItem("LoanSummary")
      );
      //console.log("SessionLoanSummary : ",this.SessionLoanSummary);
      this.goToDetail(this.SessionLoanSummary);
    } else {
      sessionStorage.removeItem("LoanSummary");
    }

    this.getAllList();

    this.dataSharingService.getLmsViewLoanStatement().subscribe((res) => {
      if (res) {
        this.loanSummary = res;
        this.getAllList();
      }
    });

    if (this.PLoanType != undefined && this.PLoanType == "Partner") {
      //this.VoucherDateTo = new Date(this.FirstEMIDate);
      //console.log("this.FirstEMIDate :", this.FirstEMIDate);
    }

    // this.calculateTotal();
  }

  getAllList() {
    //console.log("this.loanSummary :", this.loanSummary);
    if (this.loanSummary == "" || this.loanSummary == undefined) {
      if (
        this.SessionLoanSummary != undefined ||
        this.SessionLoanSummary != ""
      ) {
        $("#SearchLoanDetailModel").modal("hide");
      } else {
        $("#SearchLoanDetailModel").modal("show");
        $("#SearchLoanDetailModel").css("z-index", "1050");
      }
    } else if (this.loanSummary.LoanNo) {
      this.ApplicationNo = this.loanSummary.ApplicationNo;
      this.LoanNo = this.loanSummary.LoanNo;
      //console.log("this.this.loanSummary :", this.loanSummary);
      this.lmsService
        .LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId })
        .subscribe((res: any) => {
          if (res.Item1.length > 0) {
            console.log("res :", res.Item1[0]);
            this.showSpinner = false;
            this.ApplicationDetail = res.Item1[0];
            if (
              this.ApplicationDetail.EMIType == "Daily" ||
              this.ApplicationDetail.EMIType == "Weekly" ||
              this.ApplicationDetail.EMIType == "Fortnightly" ||
              this.ApplicationDetail.EMI_Type == "ByWeekly" ||
              this.ApplicationDetail.EMI_Type == "FourthWeekly"
            )
              this.TenureName = "Days";
            else this.TenureName = "Months";
            this.customerData = res.Item2;

            this.SourceAssets = res.Item6;
            this.showLess_LoanDetail = true;
            //this.showLoanOD = res.Item1[0].IsODDetail;
          }
        });

      this.showLoanODCharges = true;
      this.showLoanIncomeCharges = true;
      //var InterestDetail = <HTMLInputElement>(
      //  document.getElementById("InterestDetail")
      //);
      //InterestDetail.checked = true;

      var OtherDetail = <HTMLInputElement>(
        document.getElementById("OtherDetail")
      );
      OtherDetail.checked = true;

      //var incomeDetail = <HTMLInputElement>document.getElementById("incomeDetail");
      //incomeDetail.checked = true;
      this.onSearchStatementDetail();
    }
  }

  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
        //for (var i = 0; i < res.length; i++) {
        //  this.chargesHeadDropdown.push({ id: res[i]["Id"], name: res[i]["ChagesHead"]});
        //}
      });
  }
  onItemSelect(item: any) {
    // console.log('onItemSelect', item);
    this.onSearchStatementDetail();
  }
  onSelectAll(items: any) {
    // console.log('onSelectAll', items);
    this.onSearchStatementDetail();
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: this.ShowFilter,
    });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
        limitSelection: 2,
      });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
        limitSelection: null,
      });
    }
  }
  onSearchStatementDetail() {
    //this.showSpinner = true;
    var VDate;
    // VDate = this.FirstEMIDate != undefined ? this.FirstEMIDate : this.VoucherDateTo;
    VDate = this.VoucherDateTo;
    //if (this.PLoanType != undefined && this.PLoanType == "Partner") {
    //  //this.VoucherDateTo = this.FirstEMIDate;
    //  this._Partnerservice
    //    .Partner_Get_ACC_Leadger_Loan_OD({
    //      LoanAcNo: this.LoanNo,
    //      ToDate: VDate,
    //    })
    //    .subscribe((res: any) => {
    //      if (res.length > 0) {
    //        console.log("OD RESPONSE", res);

    //        this.dataSourceLoanOD = new MatTableDataSource(
    //          JSON.parse(JSON.stringify(res))
    //        );
    //        this.dataSourceLoanOD.sort = this.sort;
    //        this.ODDetails = res;
    //        //this.dataSourceLoanOD.paginator= this.paginator;
    //        this.showSpinner = false;
    //      }
    //    });
    //} else {
    //  this.lmsService
    //    .Get_ACC_Leadger_Loan_OD({
    //      ApplicationNo: this.ApplicationNo,
    //      Loan_No: this.LoanNo,
    //      VoucherDate: VDate,
    //    })
    //    .subscribe((res: any) => {
    //      if (res.length > 0) {
    //        this.dataSourceLoanOD = new MatTableDataSource(
    //          JSON.parse(JSON.stringify(res))
    //        );
    //        this.dataSourceLoanOD.sort = this.sort;
    //        this.ODDetails = res;
    //        //this.dataSourceLoanOD.paginator= this.paginator;
    //        this.showSpinner = false;
    //      } else {
    //        this.dataSourceLoanOD = null;
    //        this.showSpinner = false;
    //      }
    //    });
    //}
    this.onClickStatementDetail();
    this.lmsService
      .Get_Acc_Leadger_Loan({
        HeadId: 34,
        ApplicationNo: this.ApplicationNo,
        Loan_No: this.LoanNo,
        VoucherDate: VDate,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          console.log("Receipt No", this.FileCheckingList);
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
          this.dataSourceLoan.sort = this.sort;
          //this.dataSourceLoan[this.FileCheckingList[0].HeadId].paginator = this.paginator;
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 1000);
          this.showSpinner = false;
        } else {
          this.dataSourceLoan = null;
        }
      });
   // this.getAccHeadDetails(this.ApplicationNo, this.LoanNo, VDate);
    //this.tableshow = true;
  }

  formatDate(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, "dd-MMM-yy");
    return formattedDate || ""; // return empty string if datePipe returns null
  }

  onClickStatementDetail() {
    //this.showSpinner = true;
    var VDate;
    //VDate = this.FirstEMIDate != undefined ? this.FirstEMIDate : this.VoucherDateTo;
    VDate = this.VoucherDateTo;

    this.lmsService
      .Get_ACC_Leadger_Loan_OD_Charges({
        ApplicationNo: this.ApplicationNo,
        Loan_No: this.LoanNo,
        VoucherDate: VDate,
        SubType: this.PLoanType,
      })
      .subscribe((res: any) => {
        this.AccHeadSummary = res.Item1;
        this.dataSourceLoanODCharges = new MatTableDataSource(
          JSON.parse(JSON.stringify(res.Item1))
        );
        this.dataSourceLoanODCharges.sort = this.sort;
        //this.dataSourceLoanOD.paginator= this.paginator;
        this.dataSourceLoanIncomeCharges = new MatTableDataSource(
          JSON.parse(JSON.stringify(res.Item2))
        );
        this.dataSourceLoanIncomeCharges.sort = this.sort;
        this.showSpinner = false;
      });
   // this.getAccHeadDetails(this.ApplicationNo, this.LoanNo, VDate);
    //this.tableshow = true;
  }

  onCloseNachDetail() {
    $("#NachDetailModel").modal("hide");
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onCloseSearchLoanDetail() {
    $("#SearchLoanDetailModel").modal("hide");
  }
  onSearchCustomerDetail() {
    this.Customer.LoginUserId = this.currentUser.userId;
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedCustomerColumns = [
            "CustomerId",
            "Type",
            "Application_No",
            "LoanAcNo",
            "CustomertName",
            "FatherName",
            "Customer_Gender",
            "GenderAge",
            "PhoneNo",
            "EditAction",
          ];

          this.CustomerList = JSON.parse(JSON.stringify(res));
          if (this.Type != "0") {
            var type;
            if (this.Type == "1") type = "LOS";
            else type = "LMS";
            this.CustomerList = this.CustomerList.filter(
              (item) => item.Type == type
            );
          }
          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.dataSourceCustomer.paginator = this.paginator;
          this.showSpinner = false;
        }
      });
  }
  goToDetail(row) {
    this.onCloseSearchLoanDetail();
    //this.ApplicationNo = row.Application_No;
    //this.LoanNo = row.LoanAcNo;
    this.LoanId = row.LoanId;
    this.ApplicationNo = row.Application_No
      ? row.Application_No
      : row.ApplicationNo;
    this.LoanNo = row.LoanAcNo == undefined ? this.LoanNo : row.LoanAcNo;

    if (this.LoanNo == undefined) {
      this.LoanNo = row.LoanNo;
    }

    var OtherDetail = <HTMLInputElement>document.getElementById("OtherDetail");
    OtherDetail.checked = true;

    //var incomeDetail = <HTMLInputElement>document.getElementById("incomeDetail");
    //incomeDetail.checked = true;

    this.onSearchStatementDetail();

    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: row.LoanId })
      .subscribe((res: any) => {
        console.log("res :", res);
        this.showSpinner = false;
        this.ApplicationDetail = res.Item1[0];
        //this.showLoanOD = res.Item1[0].IsODDetail;
        //var InterestDetail = <HTMLInputElement>(
        //  document.getElementById("InterestDetail")
        //);
        //InterestDetail.checked = this.showLoanOD;
        if (
          this.ApplicationDetail.EMIType == "Daily" ||
          this.ApplicationDetail.EMIType == "Weekly" ||
          this.ApplicationDetail.EMIType == "Fortnightly" ||
          this.ApplicationDetail.EMI_Type == "ByWeekly" ||
          this.ApplicationDetail.EMI_Type == "FourthWeekly"
        )
          this.TenureName = "Days";
        else this.TenureName = "Months";
        this.customerData = res.Item2;

        this.SourceAssets = res.Item6;
        this.EMIDetails = res.Item7[0];
        this.showLess_LoanDetail = true;
        this.showCustomerResult();
        this.showLess_FinanceDetail = true;
        this.StepEmiDetail(row.LoanId);
      });
  }

  showResult() {
    this.showLess_LoanDetail = true;
  }
  hideResult() {
    this.showLess_LoanDetail = false;
  }
  showCustomerResult() {
    this.customerDataSource = new MatTableDataSource(this.customerData);
    this.showLess_CustomerDetail = true;
  }
  hideCustomerResult() {
    this.showLess_CustomerDetail = false;
    this.customerDataSource = new MatTableDataSource();
  }
  showFinanceResult() {
    this.showLess_FinanceDetail = true;
  }
  hideFinanceResult() {
    this.showLess_FinanceDetail = false;
  }
  showAssetsResult() {
    this.showLess_AssetsDetail = true;
    this.dataSourceAssets = new MatTableDataSource(this.SourceAssets);
  }

  hideAssetsResult() {
    this.showLess_AssetsDetail = false;
    this.dataSourceAssets = new MatTableDataSource();
  }

  ODChange(event) {
    if (event.srcElement.checked == true) {
      this.showLoanOD = true;
      if (this.PLoanType != undefined && this.PLoanType == "Partner") {
        //this.VoucherDateTo = this.FirstEMIDate;
        this._Partnerservice
          .Partner_Get_ACC_Leadger_Loan_OD({
            LoanAcNo: this.LoanNo,
            ToDate: this.VoucherDateTo,
          })
          .subscribe((res: any) => {
            if (res.length > 0) {
              console.log("OD RESPONSE", res);

              this.dataSourceLoanOD = new MatTableDataSource(
                JSON.parse(JSON.stringify(res))
              );
              this.dataSourceLoanOD.sort = this.sort;
              this.ODDetails = res;
              //this.dataSourceLoanOD.paginator= this.paginator;
              this.showSpinner = false;
            }
          });
      } else {
        this.lmsService
          .Get_ACC_Leadger_Loan_OD({
            ApplicationNo: this.ApplicationNo,
            Loan_No: this.LoanNo,
            VoucherDate: this.VoucherDateTo,
          })
          .subscribe((res: any) => {
            if (res.length > 0) {
              this.dataSourceLoanOD = new MatTableDataSource(
                JSON.parse(JSON.stringify(res))
              );
              this.dataSourceLoanOD.sort = this.sort;
              this.ODDetails = res;
              //this.dataSourceLoanOD.paginator= this.paginator;
              this.showSpinner = false;
            } else {
              this.dataSourceLoanOD = null;
              this.showSpinner = false;
            }
          });
      }
    }
    else this.showLoanOD = false;
  }

  ChargesChange(event) {
    if (event.srcElement.checked == true) {
      //this.onClickStatementDetail();
      this.showLoanODCharges = true;
      this.showLoanIncomeCharges = true;
    } else {
      this.showLoanODCharges = false;
      this.showLoanIncomeCharges = false;
    }
  }

  IncomeChargesChange(event) {
    if (event.srcElement.checked == true) this.showLoanIncomeCharges = true;
    else this.showLoanIncomeCharges = false;
  }

  goToChargesDetail(row) {
    this.showSpinner = true;
    this.ROW = row;
    this.FileCheckingList = [];
    this.lmsService
      .Get_Acc_Leadger_Loan_Charges_Detail({
        HeadId: row.HeadId,
        ApplicationNo: this.ApplicationNo,
        Loan_No: this.LoanNo,
        VoucherDate: this.VoucherDateTo,
        SubType: this.PLoanType,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.ChargesJson = res;
          this.dataSourceChargesDetail = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSourceChargesDetail.sort = this.sort;
          this.dataSourceChargesDetail.paginator = this.paginatorlist;
          //this.dataSourceLoan[this.FileCheckingList[0].HeadId].paginator = this.paginator;
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 1000);
          this.showSpinner = false;
          this.ChargesName = row.ChagesHead;
          $("#ChargesLoanDetailModel").modal("show");
          $("#ChargesLoanDetailModel").css("z-index", "1050");
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No Data Found!!!",
            ...this.configSuccess,
          });

          this.showSpinner = false;
        }
      });
  }
  onCloseChargesLoanDetail() {
    this.ChargesName = "";
    $("#ChargesLoanDetailModel").modal("hide");
  }
  calculateODTotal() {
    if (this.dataSourceLoanOD != null) {
      let sum = 0;
      let DR = this.calculateODInterest();
      let CR = this.calculateODCredit();
      sum = Number(DR) - Number(CR);
      if (sum >= 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    return 0;
  }
  calculateODInterest() {
    if (this.dataSourceLoanOD != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceLoanOD.filteredData.length; i++) {
        sum += Number(this.dataSourceLoanOD.filteredData[i]["ODDebit"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  calculateODDays() {
    if (this.dataSourceLoanOD != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceLoanOD.filteredData.length; i++) {
        sum += Number(this.dataSourceLoanOD.filteredData[i]["DaysLate"]);
      }
      return sum;
    }
    return 0;
  }

  calculateODCredit() {
    if (this.dataSourceLoanOD != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceLoanOD.filteredData.length; i++) {
        sum += Number(this.dataSourceLoanOD.filteredData[i]["ODCredit"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateChargesDR() {
    if (this.dataSourceChargesDetail != null) {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceChargesDetail.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceChargesDetail.filteredData[i]["TranTypeDR"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateChargesCR() {
    if (this.dataSourceChargesDetail != null) {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceChargesDetail.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceChargesDetail.filteredData[i]["TranTypeCR"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateChargesTotal() {
    if (this.dataSourceChargesDetail != null) {
      let sum = 0;
      let DR = this.calculateChargesDR();
      let CR = this.calculateChargesCR();
      sum = Number(DR) - Number(CR);
      if (sum >= 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    return 0;
  }
  calculateChargesTranTypeTotal(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0;
      let DR = this.calculateChargesTranTypeDR("OD");
      let CR = this.calculateChargesTranTypeCR("OD");
      sum = Number(DR) - Number(CR);
      if (sum >= 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    if (this.dataSourceLoanIncomeCharges != null && Type == "ID") {
      let sum = 0;
      let DR = this.calculateChargesTranTypeDR("ID");
      let CR = this.calculateChargesTranTypeCR("ID");
      sum = Number(DR) - Number(CR);
      if (sum >= 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    return 0;
  }
  calculateChargesTranTypeCR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanODCharges.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceLoanODCharges.filteredData[i]["TranTypeCR"]
        );
      }
      return sum.toFixed(2);
    }
    if (this.dataSourceLoanIncomeCharges != null && Type == "ID") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanIncomeCharges.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceLoanIncomeCharges.filteredData[i]["TranTypeCR"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateChargesTranTypeDR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanODCharges.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceLoanODCharges.filteredData[i]["TranTypeDR"]
        );
      }
      return sum.toFixed(2);
    }
    if (this.dataSourceLoanIncomeCharges != null && Type == "OD") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanIncomeCharges.filteredData.length;
        i++
      ) {
        sum += Number(
          this.dataSourceLoanIncomeCharges.filteredData[i]["TranTypeDR"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.dataSourceLoan != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeCR() {
    if (this.dataSourceLoan != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      let DR = this.calculateTranTypeDR();
      let CR = this.calculateTranTypeCR();
      sum = Number(DR) - Number(CR);
      if (sum >= 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    return 0;
  }
  onCloseVoucherDetail() {
    $("#VoucherDetail").modal("hide");
  }
  ViewVoucherDetail(row) {
    $("#VoucherDetail").modal("show");
    $("#VoucherDetail").css("z-index", "1050");

    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        //console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        if (
          this.voucherModel.Voucher.Voucher_Type == "Receipt" ||
          this.voucherModel.Voucher.Voucher_Type == "Journal" ||
          this.voucherModel.Voucher.Voucher_Type == "Payment"
        )
          this.ShowDelete = true;
        else this.ShowDelete = false;
        this.voucherModel.Voucher_Detail.forEach((x) => {
          x.AmountDR = 0.0;
          x.AmountCR = 0.0;
          if (x.TranType == "CR") x.AmountCR = x.Amount;
          if (x.TranType == "DR") x.AmountDR = x.Amount;
        });
        if (
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == "" ||
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == null
        )
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = "CASH";
        // this.onVoucherTagChange();
      });
  }

  getAssetDetail(data: any, isEdit: boolean) {
    if (data.Collateral == "Vehicle") {
      this.LMS_GetAssetsDetailVehicle(data.AssetId, data.Collateral);
    } else if (data.Collateral == "Property") {
      this.LMS_Get_AssetsDetail_Property(data.AssetId, data.Collateral);
    } else if (data.Collateral == "Gold") {
      this.LMS_GetAssetsDetailGOLD(data.AssetId, data.Collateral);
    }
    //else if (data.Collateral == 'Consumer Durable') {
    //  this.LMS_GetAssetsDetailConsumerDurable();
    //}
  }
  LMS_GetAssetsDetailVehicle(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.lmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        this.assetModel.Collateral = "Vehicle";
        this.assetModel.MefgYear = this.assetModel.MefgYear; //new Date(this.assetModel.MefgYear);
        if (this.assetModel.RegistationDate) {
          this.assetModel.RegistationDate = new Date(
            this.assetModel.RegistationDate
          );
        }
        if (this.assetModel.RegistationExpiryDate) {
          this.assetModel.RegistationExpiryDate = new Date(
            this.assetModel.RegistationExpiryDate
          );
        }
        if (this.assetModel.RoadTaxUpto) {
          this.assetModel.RoadTaxUpto = new Date(this.assetModel.RoadTaxUpto);
        }
        if (this.assetModel.FitnessUpto) {
          this.assetModel.FitnessUpto = new Date(this.assetModel.FitnessUpto);
        }
        if (this.assetModel.PermitUpto) {
          this.assetModel.PermitUpto = new Date(this.assetModel.PermitUpto);
        }
        if (this.assetModel.InvoiceDate) {
          this.assetModel.InvoiceDate = new Date(this.assetModel.InvoiceDate);
        }
        if (this.assetModel.ValuationDate) {
          this.assetModel.ValuationDate = new Date(
            this.assetModel.ValuationDate
          );
        }
        //this.GetManufactureForDropdown();
        //this.GetVehicleCategoryForDropdown(false);
        //this.GetVehicleModelForDropdown(false);
        //this.getFuelType();
        //this.LOS_GetApplicatonCustomer();
        //this.Get_PartnerForDropdown();
        //this.GetEmployeeDropdown();
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  LMS_Get_AssetsDetail_Property(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.lmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        this.assetModel.Collateral = "Property";
        if (this.assetModel.GeoLocation) {
          let _loc = this.assetModel.GeoLocation.split(",");
          if (_loc[0]) {
            this.assetModel.Latitude = _loc[0];
          }
          if (_loc[1]) {
            this.assetModel.Longitude = _loc[1];
          }
        }
        //this.LOS_GetApplicatonCustomer();
        //this.GetStateDropdown();
        //this.GetEmployeeDropdown();
        //this.GetDistrickDropdown(false);
        //this.GetTehasilDropdown(false);
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  LMS_GetAssetsDetailGOLD(AssetId, Collateral_Type) {
    this.showSpinner = true;
    this.lmsService
      .ViewAssetInfo({ SearchBy: Collateral_Type, Id: AssetId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.assetModel = res.Item1[0];
        if (this.assetModel.ValuationDate) {
          this.assetModel.ValuationDate = new Date(
            this.assetModel.ValuationDate
          );
        }
        this.assetModel.Collateral = "Gold";
        //this.GetEmployeeDropdown();
        this.assetModel.Item = {};
        this.assetModel.ItemArr = res.Item2;
        this.displayedAssetItemColumns = [
          "Id",
          "ItemName",
          "ItemType",
          "Qty",
          "PurityCT",
          "GrossWt",
          "StoneWt",
          "OtherWt",
          "ValulationRate_PerCT",
          "ValulationAmount",
        ];
        this.dataAssetItemSource = new MatTableDataSource(
          this.assetModel.ItemArr
        );
        this.getAssetTotal();
        $("#assetModel").modal("show");
        $("#assetModel").css("z-index", "1050");
      });
  }
  onClosePrimaryAsset() {
    $("#assetModel").modal("hide");
  }
  getAssetTotal() {
    if (this.assetModel.ItemArr.length == 0) {
      this.assetTotal = undefined;
    } else {
      this.assetTotal = {
        Qty: this.assetModel.ItemArr.length,
        GrossWt: 0,
        StoneWt: 0,
        OtherWt: 0,
      };
      this.assetModel.ItemArr.forEach((obj: any) => {
        this.assetTotal.GrossWt += obj.GrossWt;
        this.assetTotal.StoneWt += obj.StoneWt;
        this.assetTotal.OtherWt += obj.OtherWt;
      });
    }
  }

  //LMS_GetAssetsDetailConsumerDurable() {
  //  this.showSpinner = true;
  //  this.losService
  //    .LOS_GetAssetsDetailConsumerDurable({
  //      AssetId: this.selectedAsset.AssetId
  //    })
  //    .subscribe((res: any) => {
  //      this.showSpinner = false;
  //      this.assetModel = res.Item1[0];
  //      //   this.GetEmployeeDropdown();
  //      this.assetModel.Collateral = 'Consumer Durable';
  //      this.assetModel.Item = {};
  //      this.assetModel.ItemArr = res.Item2;
  //      this.displayedAssetItemColumns = [
  //        "Id",
  //        "ItemCategory",
  //        "ItemName",
  //        "ItemMake",
  //        "ItemQty",
  //        "ItemSeralNo",
  //        "Amount",
  //        "Remark",
  //        "OtherWt",
  //        "ValulationRate_PerCT",
  //        "ValulationAmount",
  //        "Action"
  //      ];
  //      this.dataAssetItemSource = new MatTableDataSource(this.assetModel.ItemArr);
  //      $('#assetModel').modal('show');
  //      $("#assetModel").css("z-index", "1050");
  //    });
  //}
  DateChange() {
    this.FirstEMIDate = undefined;
    this.showLoanOD = false;
    var InterestDetail = <HTMLInputElement>(
      document.getElementById("InterestDetail")
    );
    InterestDetail.checked = false;
    this.onSearchStatementDetail();
  }
  DeleteVoucher() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove this Voucher.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService
            .Delete_Voucher({
              VoucherId: this.voucherModel.Voucher.VoucherId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                if (
                  this.voucherModel.Voucher_Detail.find((obj: any) => {
                    return obj.ChargeHeadId == 34;
                  })
                )
                  this.lmsService
                    .LMS_Update_EMI_Allocation({
                      VoucherId: this.voucherModel.Voucher.VoucherId,
                    })
                    .subscribe((res1: any) => {});

                this.onCloseVoucherDetail();
                this.onCloseChargesLoanDetail();
                this.onSearchStatementDetail();
              }
            });
        }
      });
  }

  calculateVoucherTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateVoucherTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  //GetData(data) {
  //  if (data == true) {
  //    this.onCloseChargesLoanDetail();
  //    this.onSearchStatementDetail();
  //  }
  //}
  GetData(data) {
    if (data == true) {
      debugger;
      //this.onCloseChargesLoanDetail();
      this.goToChargesDetail(this.ROW);
      this.onSearchStatementDetail();
    }
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Charges-Details`,
      sheet: "Charges-Details",
    });
  }
  exportTableODDetail(exporter1: any) {
    exporter1.exportTable("xls", {
      fileName: `OD-Details`,
      sheet: "OD-Details",
    });
  }

  datePipe: DatePipe = new DatePipe("en-US");

  onPrint(divName, type) {
    var File_Name;
    File_Name = type == 1 ? "loan-statement.pdf" : "SOA.pdf";
    this.HiddenContent = true;
    let DATA: any = document.getElementById(divName);
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      canvas.getContext("webgl");
      const FILEURI = canvas.toDataURL("image/jpg");
      let PDF = new jsPDF("p", "mm", "a4");
      let position = 30;

      var pageHeight = PDF.internal.pageSize.getHeight();
      var pagecount = Math.ceil(fileHeight / pageHeight);
      //console.log(pagecount);
      PDF.addImage(FILEURI, "JPEG", 1, position, fileWidth, 0);

      if (pagecount > 0) {
        var j = 1;
        while (j != pagecount) {
          PDF.addPage("p");
          PDF.addImage(FILEURI, "JPEG", 2, -(j * pageHeight), fileWidth - 4, 0);
          j++;
        }
      }

      PDF.setFontSize(8);
      PDF.setTextColor(33, 37, 41);
      PDF.text(this.currentUser.CompanyName, 3, 20);
      PDF.text("Printed By : " + this.currentUser.username, 3, 285);
      PDF.text(
        "Printed On : " +
          this.datePipe.transform(new Date(), "dd/MM/yyyy hh:mm:ss"),
        160,
        285
      );
      PDF.save(File_Name);
    });
  }

  ViewSOA() {
    $("#SOAModel").modal("show");
    $("#SOAModel").css("z-index", "1050");
  }
  onCloseSOAModel() {
    $("#SOAModel").modal("hide");
  }

  openNewWindow(data) {
    this.router.navigate([
      `/print/${encodeURIComponent(
        this._EncrDecrService.encrypt(data.Loan_Id)
      )}`,
    ]);
  }

  totaltypewise(type) {
    if (
      this.DataSourceAccHeadD != null &&
      this.DataSourceAccHeadD != undefined &&
      this.DataSourceAccHeadD != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.DataSourceAccHeadD.filteredData.length; i++) {
        sum += Number(this.DataSourceAccHeadD.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  getAccHeadDetails(ApplicationNo, LoanNo, VDate) {
    this.lmsService
      .Get_ACC_LeadgerLoancharges_For_print({
        ApplicationNo: ApplicationNo,
        LoanAcNo: LoanNo,
        To: VDate,
      })
      .subscribe((res: any) => {
        this.AccHeadDetails = res;
        this.DataSourceAccHeadD = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
      });
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(
      divName,
      Header,
      this.ApplicationDetail.LoanAcNo
    );
  }

  StepEmiDetail(LoanId) {
    this.lmsService
      .FinancialDetailsForUpdate({ Loan_Id: LoanId })
      .subscribe((res: any) => {
        this.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
        res.Item2.forEach((obj: any) => {
          this.stepEMI.push({
            FromEMI: obj.FromEMI,
            ToEMI: obj.ToEMI,
            EMI_Amount: obj.EMIAmount,
            NoOfInstl: obj.NoOfEMI,
            TotalAmount: obj.TotalAmount,
          });
        });
        this.getTotlaStepEmiAndAmount();
      });
  }

  getTotlaStepEmiAndAmount() {
    let TotalEmi: any = 0,
      TotalAmount: any = 0;
    for (let i = 0; i < this.stepEMI.length; i++) {
      if (this.stepEMI[i].EMI_Amount) {
        TotalEmi =
          TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
        TotalAmount =
          TotalAmount +
          (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
            +this.stepEMI[i].EMI_Amount;
      }
    }
    this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  ExportExcel() {
    let JsonData = this.FileCheckingList;
    JsonData.forEach((obj: any) => {
      delete obj.voucherid;
      delete obj.ChagesHead;
      delete obj.Voucher_Type;
      delete obj.HeadId;
      delete obj.DaysLate;
      delete obj.ODInterest;
      delete obj.ODCredit;
      delete obj.ODTotal;
      delete obj.Balance;
      delete obj.Customer_Name;
      delete obj.Voucher_Cheque_Clear_Date;

      obj.TotalBalance = obj.TranTypeTotal;

      delete obj.TranTypeTotal;
    });

    JsonData.push({
      VoucherDate: "",
      Voucher_No: "",
      CaseNo: "",
      accHead: "Total",
      TranTypeDR: this.calculateTranTypeDR(),
      TranTypeCR: this.calculateTranTypeCR(),
      TotalBalance: this.calculateTranTypeTotal(),
      Narration: "",
    });
    this.exportAsExcelFile(JsonData, "Loan-Statement");
  }
  ExportExcelCharges() {
    this.ChargesJson.forEach((obj: any) => {
      delete obj.voucherid;
      delete obj.ChagesHead;
      delete obj.Voucher_Type;
      delete obj.HeadId;
      delete obj.DaysLate;
      delete obj.ODInterest;
      delete obj.ODCredit;
      delete obj.ODTotal;
      delete obj.Balance;
      delete obj.Voucher_Cheque_Clear_Date;

      obj.TotalBalance = obj.TranTypeTotal;

      delete obj.TranTypeTotal;
    });
    this.exportAsExcelFile(this.ChargesJson, "Charges Details");
  }
  // Excel export in backend code end here

  //exportexcelNew() {
  //  debugger;
  //  /* pass here the table id */
  //  let element = document.getElementById('excel-table');
  //  element.style.backgroundColor = "#4dc3a3";
  //  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  //  /* generate workbook and add the worksheet */
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  // // ws.Sheets.Sheet1.A1.s = { font: { bold: true } };
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //  /* save to file */
  //  XLSX.writeFile(wb, 'Statement.xlsx');

  //}

  exportexcelNew() {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };

    var table = document.getElementById("excel-table");
    var ctx = {
      worksheet: "LoanStatement" || "Worksheet",
      table: table.innerHTML,
    };
    window.location.href = uri + base64(format(template, ctx));
  }
  onCloseLoanStatementPrintModel() {
    $("#LoanStatementPrintModel").modal("hide");
  }

  onCloseLoanSOAPrintModel() {
    $("#LoanSOAPrintModel").modal("hide");
  }
  LoanSOAPrint() {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.LoanId, DueDate: this.VoucherDateTo })
      .subscribe((res: any) => {
        console.log("res :", res);
        this.showSpinner = false;
        this.getAccHeadDetails(this.ApplicationNo, this.LoanNo, this.VoucherDateTo);
        this.EMIDetails = res.Item7[0];
        console.log("Emi Details", this.EMIDetails);
        $("#LoanSOAPrintModel").modal("show");
        $("#LoanSOAPrintModel").css("z-index", "1050");
      });
  }

  LoanStatementPrint() {
    this.dialog
      .openConfirmDialog("Show with Account Summary")
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          this.lmsService
            .LMS_GetLoanDetails({
              Loan_Id: this.LoanId,
              DueDate: this.VoucherDateTo,
            })
            .subscribe((res: any) => {
              console.log("res :", res);
              this.showSpinner = false;

              this.EMIDetails = res.Item7[0];

              $("#LoanStatementPrintModel").modal("show");
              $("#LoanStatementPrintModel").css("z-index", "1050");
              this.withAccountSummary = false;
            });
        } else {
          this.lmsService
            .LMS_GetLoanDetails({
              Loan_Id: this.LoanId,
              DueDate: this.VoucherDateTo,
            })
            .subscribe((res: any) => {
              console.log("res :", res);
              this.showSpinner = false;

              this.EMIDetails = res.Item7[0];

              $("#LoanStatementPrintModel").modal("show");
              $("#LoanStatementPrintModel").css("z-index", "1050");
            });
          this.withAccountSummary = true;
        }
      });
  }

  onCloseLoanSOALegalPrintModel() {
    $("#LoanSOALegalPrintModel").modal("hide");
  }

  LoanSOALegalPrint() {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.LoanId, DueDate: this.VoucherDateTo })
      .subscribe((res: any) => {
        console.log("res :", res);
        this.showSpinner = false;

        this.EMIDetails = res.Item7[0];
        this.DisbursementDetails = res.Item8;
        this.calculateTotalDR();
        this.calculateTotalCR();
        console.log("Dis Amount", this.DisbursementDetails);
        $("#LoanSOALegalPrintModel").modal("show");
        $("#LoanSOALegalPrintModel").css("z-index", "1050");
      });
  }

  calculateTotalDR() {
    for (let DisbursementDetail of this.DisbursementDetails) {
      const DRAmount = parseFloat(DisbursementDetail.DRAmount);
      this.totalDRAmount += DRAmount;
    }
    console.log("Total DR Amount", this.totalDRAmount.toFixed(2));
  }
  calculateTotalCR() {
    for (let DisbursementDetail of this.DisbursementDetails) {
      const CRAmount = parseFloat(DisbursementDetail.CRAmount);
      this.totalCRAmount += CRAmount;
    }
    console.log("Total CR Amount", this.totalCRAmount);
  }

  // showInfoCard(event: MouseEvent, row: any) {
  //   this.hoveredRow = row;
  //   this.hoverX = event.clientX;
  //   this.hoverY = event.clientY;
  // }

  hideInfoCard() {
    this.hoveredRow = null;
  }

  showInfoCard(event: MouseEvent, row: any) {
    this.hoveredRow = row;

    const spanElement = event.target as HTMLElement; // Get the span element
    const rect = spanElement.getBoundingClientRect(); // Get its position

    this.hoverX = rect.left + window.scrollX + 30;
    this.hoverY = rect.top + window.scrollY - 120;
  }
}
