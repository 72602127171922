import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DialogService } from 'src/app/Shared/dialog.service';
import { LmsService } from '../services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { constantUrl } from 'src/app/Shared/constantUrl';
declare var $: any;

@Component({
    selector: 'app-lms-loan-undo-details',
    templateUrl: './lms-loan-undo-detail.component.html',
    styleUrls: ['./lms-loan-undo-detail.component.scss']
})
export class LmsLoanUndoDetailsComponent implements OnInit {

    data: any; LoanId: any;
    showSpinner: boolean = false;

    LoanAllDetail: any = [];
    LoanCustomerList: any = [];
    dataSource: any;
    displayedColumns: string[] = [];
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    loanSummary: any = {};
    isMaker: string = "";
    PreCloserDetails: any = { LoanCloser_EffectiveDate: new Date() };
    PreCloserMethodList: any = [];
    totalCalculator: any = { totalDueAmount: 0, totalReceiveAmount: 0, totalWaiverAmount: 0, totalBalance: 0 }
    RequestDocName: any = "";
    RequestDocBase64: any = "";
    isShowForward: boolean = false;
    wavieruserList: any[] = [];
    today: Date = new Date();
    _prevDate: any;

    loandocBaseUrl: any = constantUrl.CrcApplicationDocUrl;
    AllowExtension: any = constantUrl.AllowExtension;

    closerId: any;
    isExpired: boolean = false;
    isMyCloserRequest: boolean = false;
    isWavierGivenByMe: boolean = false;
    isUndoRemark: boolean = false;
    undoRemark: any = "";


    constructor(
        private dataSharingService: DataSharingService,
        private _EncrDecrService: EncrDecrService,
        private snackBar: MatSnackBar,
        private router: Router,
        private dialog: DialogService,
        private route: ActivatedRoute,
        private lmsService: LmsService,
        private location: Location
    ) {
        this.data = JSON.parse(sessionStorage.getItem("currentUser"));
        this.route.paramMap.subscribe((param: ParamMap) => {
            this.LoanId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("loanId")));
            this.closerId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("closerId")));
            this.isMaker = param.get("isMaker");

            this.getLoanRescheduleDetail();
            this.LMS_Get_Loan_Closer_Due_Detail(null);
        });
    }

    configSuccess: MatSnackBarConfig = {
        panelClass: 'style-success',
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };

    ngOnInit() {
        this.dataSharingService.HeaderTitle.next("Loan Closer Undo Details");
        this.displayedColumns = [
            "Int_Id", "CustomerId", "Customer", "CustomerType", "GenderAge", "PhoneNo", "Relation_With_Hirer", "Present_Address", "ExistingCustomer"
        ];
    }
    onEffectiveDateChange() {
        if (!this._prevDate || !this.PreCloserDetails.LoanCloser_EffectiveDate) {
            return;
        }
        if (this._prevDate.toLocaleDateString() != this.PreCloserDetails.LoanCloser_EffectiveDate.toLocaleDateString()) {
            this.LMS_Get_Loan_Closer_Due_Detail(this.PreCloserDetails.LoanCloser_EffectiveDate.toLocaleDateString());
        }
    }

    LMS_Get_Loan_Closer_Due_Detail(date: any) {
        this.showSpinner = true;
        this.lmsService.LMS_Get_Loan_Closer_Due_Detail({ "Loan_Id": JSON.parse(this.LoanId), "LoginUserId": this.data.userId, EffictiveDate: date, "LoanCloserId": JSON.parse(this.closerId) }).subscribe((res: any) => {
            if (res != null) {
                let _data = res.Item1[0] || {};
                _data.LoanCloser_EffectiveDate = new Date(_data.LoanCloser_EffectiveDate);
                _data.NextDueDate = new Date(_data.NextDueDate);
                this._prevDate = _data.LoanCloser_EffectiveDate;
                this.PreCloserDetails = _data;
                this.PreCloserMethodList = res.Item2 ? res.Item2 : [];
                this.PreCloserMethodList.map((item) => {
                    item.newBalance = item.Balance < 0 ? item.Balance * -1 : item.Balance;
                })
                this.PreCloserDetails.Waiver_GivenBy = this.PreCloserDetails.Waiver_GivenBy;
                if (this.PreCloserDetails.Closer_RequestStatus != "Closed"&&new Date().toLocaleDateString() != this.PreCloserDetails.LoanCloser_EffectiveDate.toLocaleDateString()) {
                    this.isExpired = this.PreCloserDetails.LoanCloser_EffectiveDate.getTime() < new Date().getTime();
                }
                this.isMyCloserRequest = this.PreCloserDetails.LoanCloser_RequestBy.toString() == this.data.userId.toString();
                this.isWavierGivenByMe = this.PreCloserDetails.Waiver_GivenBy.toString() == this.data.userId.toString();
                this.onCalculateAmount();
                this.showSpinner = false;
            }
        });
    }
    LMS_Get_User_For_Waiver_Approval() {
        this.showSpinner = true;
        let _due=this.totalCalculator.totalDueAmount-this.totalCalculator.totalReceiveAmount;
        this.lmsService.LMS_Get_User_For_Waiver_Approval({ "LoginUserId": this.data.userId, WaiverAmount: this.PreCloserDetails.totalWaiverAmount,DueAmount:_due }).subscribe((res: any) => {
            if (res != null) {
                this.wavieruserList = res;
                this.showSpinner = false;
            }
        });
    }

    getLoanRescheduleDetail() {
        //  this.showSpinner = true;
        this.lmsService.LMS_Get_Loan_Closer_Detail({ "Loan_Id": JSON.parse(this.LoanId), "LoginUserId": this.data.userId }).subscribe((res: any) => {
            if (res != null) {
                this.LoanAllDetail = res.Item1;
                this.LoanCustomerList = JSON.parse(JSON.stringify(res.Item2));
                this.dataSource = new MatTableDataSource(this.LoanCustomerList);
                this.dataSource.sort = this.sort;
                this.showSpinner = false;
            }
        });
    }

    onCalculateAmount() {
        let _totalDue: any = 0;
        let _totalReceived: any = 0;
        let _totalWaiver: any = 0;
        let _totalBalance: any = 0;

        this.PreCloserMethodList.forEach(obj => {
            _totalDue = _totalDue + obj.DueAmount;
            _totalReceived = _totalReceived + obj.ReceiveAmount;
            _totalWaiver = _totalWaiver + Number(obj.WaiverAmount);
            _totalBalance = _totalBalance + obj.Balance;
        });

        this.totalCalculator = {
            totalDueAmount: Number(_totalDue),
            totalReceiveAmount: Number(_totalReceived),
            totalWaiverAmount: Number(_totalWaiver),
            totalBalance: Number(_totalBalance),
        }
        this.PreCloserDetails.Waiver_Limit_Per_Amt = Number(((this.totalCalculator.totalDueAmount - this.totalCalculator.totalReceiveAmount) * this.PreCloserDetails.Waiver_Limit_Per) / 100);
        this.PreCloserDetails.Waiver_Limit_Per_Amt_R = this.PreCloserDetails.Waiver_Limit_Per_Amt < this.PreCloserDetails.Waiver_Limit_Amt ? this.PreCloserDetails.Waiver_Limit_Per_Amt : this.PreCloserDetails.Waiver_Limit_Amt;

        if (!this.PreCloserDetails.isFirstTimeCalculate && !this.PreCloserDetails.CustomerAgreed_Amount) {
            this.PreCloserDetails.CustomerAgreed_Amount = this.totalCalculator.totalBalance < 0 ? this.totalCalculator.totalBalance * -1 : this.totalCalculator.totalBalance;
            this.PreCloserDetails.isFirstTimeCalculate = true;
        }
        this.onChangeAgreeAmount();
    }
    onChangeAgreeAmount() {
        let _tr = this.totalCalculator.totalBalance < 0 ? this.totalCalculator.totalBalance * -1 : this.totalCalculator.totalBalance;
        this.PreCloserDetails.totalWaiverAmount = _tr - this.PreCloserDetails.CustomerAgreed_Amount < 0 ? 0 : _tr - this.PreCloserDetails.CustomerAgreed_Amount;
        if (this.PreCloserDetails.Closer_RequestStatus == 'Waiver Pending') {
            this.isShowForward = true;
            this.LMS_Get_User_For_Waiver_Approval();
        }
        else {
            if (this.PreCloserDetails.totalWaiverAmount > this.PreCloserDetails.Waiver_Limit_Per_Amt_R) {
                this.isShowForward = true;
                this.LMS_Get_User_For_Waiver_Approval();
            }
            else {
                this.isShowForward = false;
            }
        }
    }

    onChangeWaiverAmt(index) {
        if (+this.PreCloserMethodList[index].DueAmount - (+this.PreCloserMethodList[index].ReceiveAmount) >= +this.PreCloserMethodList[index].WaiverAmount) {
            this.PreCloserMethodList[index].Balance = (+this.PreCloserMethodList[index].DueAmount - (+this.PreCloserMethodList[index].ReceiveAmount)) - this.PreCloserMethodList[index].WaiverAmount;
            this.onCalculateAmount();
        } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: 'Invalid Amount.', ...this.configSuccess });
        }
    }

    fileChangeEvent(fileInput) {
        let Ext = fileInput.target.files[0].name.split('.').pop().toLowerCase();
        var FileSize = Math.floor(fileInput.target.files[0].size / Math.pow(1024,1));
        
        if(FileSize > this.data.UploadMaxSize_In_KB){
        this.PreCloserDetails.Request_Doc = "";
        this.RequestDocName = "";
        this.RequestDocBase64 = "";
        var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
        this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
        return;
        }

        if(!this.AllowExtension.includes(Ext)){
        this.PreCloserDetails.Request_Doc = "";
        this.RequestDocName = "";
        this.RequestDocBase64 = "";
        this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
        return;
        }
        
        if (fileInput.target.files && fileInput.target.files.length) {
            let files = fileInput.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsDataURL(files);
            fileReader.onload = (e) => {
                this.RequestDocName = "IMG_" + new Date().getTime() + '.png';
                this.RequestDocBase64 = fileReader.result.toString().split(";base64,").pop();
            };
        } else {
            this.PreCloserDetails.Request_Doc = "";
            this.RequestDocName = "";
            this.RequestDocBase64 = "";
        }
    }

    onRemoveImg() {
        this.PreCloserDetails.CloserRequestDoc = "";
        this.RequestDocName = "";
        this.RequestDocBase64 = "";
        this.PreCloserDetails.Request_Doc = "";
    }

    onCancel() {
        this.location.back();
    }

    onSaveAsDraft() {
        this.saveLoanCloser("Created")
    }
    onDeleteCloserRequest() {
        this.dialog.openConfirmDialog(`Are You Sure To Delete This Closer Request?`)
            .afterClosed().subscribe(dialogResult => {
                if (dialogResult == false) {
                    return;
                } else {
                    this.showSpinner = true;
                    this.lmsService.LMS_Delete_Loan_Closer_Request({ Loan_Id: JSON.parse(this.LoanId), CloserId: this.PreCloserDetails.CloserId, LoginUserId: this.data.userId }).subscribe((res: any) => {
                        if (res[0].CODE >= 0) {
                            this.snackBar.openFromComponent(SnackbarComponent, {
                                data: res[0].MSG,
                                ...this.configSuccess,
                            });
                            this.onCancel();
                        } else {
                            this.snackBar.openFromComponent(SnackbarComponent, {
                                data: res[0].MSG,
                                ...this.configSuccess,
                            });
                        }
                        this.showSpinner = false;
                    });
                }
            });
    }
    onUndoCloserRequest() {
        if (this.isUndoRemark) {
            if (this.undoRemark == "") {
                this.snackBar.openFromComponent(SnackbarComponent, { data: `Enter undo remark.`, ...this.configSuccess });
                return;
            }
            this.dialog.openConfirmDialog(`Are You Sure To Undo This Loan Closer?`)
                .afterClosed().subscribe(dialogResult => {
                    if (dialogResult == false) {
                        return;
                    } else {
                        this.showSpinner = true;
                        this.lmsService.LMS_Loan_Closer_Request_Undo({ Loan_Id: JSON.parse(this.LoanId), Remark: this.undoRemark, LoginUserId: this.data.userId }).subscribe((res: any) => {
                            if (res[0].CODE >= 0) {
                                this.snackBar.openFromComponent(SnackbarComponent, {
                                    data: res[0].MSG,
                                    ...this.configSuccess,
                                });
                                this.onCancel();
                            } else {
                                this.snackBar.openFromComponent(SnackbarComponent, {
                                    data: res[0].MSG,
                                    ...this.configSuccess,
                                });
                            }
                            this.showSpinner = false;
                        });
                    }
                });
        }
        else {
            this.isUndoRemark = true;
        }
    }
    onPreClosureClose() {
        if (this.totalCalculator.totalBalance <= (this.PreCloserDetails.CustomerAgreed_Amount + this.totalCalculator.totalWaiverAmount)) {
            this.dialog.openConfirmDialog(`Are you sure you want to close this loan?`)
                .afterClosed().subscribe(dialogResult => {
                    if (dialogResult == false) {
                        return;
                    } else {
                        this.saveLoanCloser("Closed");
                    }
                });
        }
        else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: `Sum of customer agree amount  and waiver amount should be equal to received amount`, ...this.configSuccess });
        }
    }
    onWaieverApproved() {
        this.dialog.openConfirmDialog(`Are you sure you want to Approve?`)
            .afterClosed().subscribe(dialogResult => {
                if (dialogResult == false) {
                    return;
                } else {
                    this.saveLoanCloser("Waiver Approved");
                }
            });
    }

    onPrintPreClosureStatement() {
        var prtContent = document.getElementById("printData");
        var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
    }

    onWaieverReject() {
        this.dialog.openConfirmDialog(`Are you sure you want to Reject.`)
            .afterClosed().subscribe(dialogResult => {
                if (dialogResult == false) {
                    return;
                } else {
                    this.saveLoanCloser("Waiver Rejected");
                }
            });
    }

    onForwardToSeniorWaiverApproval() {
        if (this.PreCloserDetails.Waiver_GivenBy != "") {
            this.saveLoanCloser("Waiver Pending");
        }
        else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: `Pleasse select user to waiver.`, ...this.configSuccess });
        }
    }
    saveLoanCloser(status: any) {
        if (this.RequestDocBase64) {
            let saveObj = {
                "DocName": this.RequestDocName,
                "LoanId": JSON.parse(this.LoanId),
                "DocData": this.RequestDocBase64
            }
            this.lmsService.UploadLoanDoc(saveObj).subscribe((respose: any) => {
                if (respose) {
                }
            });
        }
        let _Closer: any = {
            "CloserId": this.PreCloserDetails.CloserId,
            "LoanId": JSON.parse(this.LoanId),
            "Request_Status": status,
            "EffectiveDate": this.PreCloserDetails.LoanCloser_EffectiveDate.toLocaleDateString(),
            "DueAmount": this.totalCalculator.totalDueAmount,
            "ReceivedAmount": this.totalCalculator.totalReceiveAmount,
            "BalanceAmount": this.totalCalculator.totalBalance,
            "CustomerAgreedAmount": this.PreCloserDetails.CustomerAgreed_Amount,
            "WaiverAmount": this.totalCalculator.totalWaiverAmount,
            "WaiverLimit": this.PreCloserDetails.Waiver_Limit_Amt,
            "Closer_Remark": this.PreCloserDetails.LoanCloser_Remark,
            "Waiver_Remark": this.PreCloserDetails.Waiver_Remark,
            "CloserDoc": this.RequestDocName || this.PreCloserDetails.CloserRequestDoc,
            "LoginUserId": this.data.userId,
            "WavierGivenBy": this.PreCloserDetails.Waiver_GivenBy
        };
        let _CloserDetail: any[] = [];
        this.PreCloserMethodList.forEach((element: any) => {
            _CloserDetail.push({
                "HeadId": element.HeadId,
                "DueAmount": element.DueAmount,
                "ReceivedAmount": element.ReceiveAmount,
                "WaiverAmount": element.WaiverAmount,
                "IsWaiver_Eligible": element.IsWaiver_Eligible
            });
        });
        let _data: any = {
            Closer: _Closer,
            CloserDetail: _CloserDetail
        }
        this.showSpinner = true;
        this.lmsService.LMS_Save_Loan_Closer_Request({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
            if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
                this.onCancel();
            } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
            }
            this.showSpinner = false;
        })
    }

    onViewLoanStatement() {
        if (this.LoanAllDetail[0]) {
            this.loanSummary.LoanId = parseInt(this.LoanId);
            this.loanSummary.ApplicationNo = this.LoanAllDetail[0].ApplicationNo;
            this.loanSummary.LoanNo = this.LoanAllDetail[0].LoanNo;
            this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
            $('#ViewLoanStatementPreCloserModel').modal('show');
            $("#ViewLoanStatementPreCloserModel").css("z-index", "1050");
        }
    }

    onCloseViewLoanStatement() {
        $('#ViewLoanStatementPreCloserModel').modal('hide');
    }

}
