<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <!-- Form Starting Point -->

  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchAccountInterest()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-4">
        <span class="required-lable">Account Head</span>
        <input
          type="text"
          [formControl]="AccountControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="chhead"
          name="chhead"
          required
          [class.alert-warning]="chhead == ''"
          placeholder="Account Head"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onAccountHeadhSelect($event)"
        >
          <mat-option
            *ngFor="let account of filteredAccountHead | async"
            [value]="account"
          >
            {{ account.Account_Name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Rate of Interest(%)</span>
        <input
          required
          rows="1"
          #refroi="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refroi.invalid,
            'alert-warning':
              refroi.invalid &&
              (refroi.dirty || refroi.touched || refroi.untouched)
          }"
          [(ngModel)]="roi"
          placeholder="Rate of Interest"
          name="roi"
          id="roi"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            (dateChange)="DateChange()"
            required
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          placeholder="Voucher From"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-2">
        <span class="required-lable">Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            #refVoucher_Date="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            (dateChange)="DateChange()"
            required
            #reffVoucherDateTo="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && reffVoucherDateTo.invalid,
              'alert-warning':
                reffVoucherDateTo.invalid &&
                (reffVoucherDateTo.dirty ||
                  reffVoucherDateTo.touched ||
                  reffVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          placeholder="Voucher To"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2 text-right">
        <button
          type="button"
          (click)="onSearchAccountInterest()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid || !chhead"
        >
          Search
        </button>
      </div>
      <div class="col-md-12 ">
        <div class="col-md-3 mt-2 float-right" [hidden]="!dataSource">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </form>

  <!-- Form Ending Point -->

  <!-- Table Starting Point -->

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive" style="overflow: auto">
        <table
          mat-table
          class="table-bordered"
          matSort
          [dataSource]="dataSource"
          id="account_interest"
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="AccountId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ i + 1 }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Account_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Account Name
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ row.Account_Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Voucher_Date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Voucher Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Voucher_Date | date : "dd/MM/YYYY" }}
            </td>
            <th mat-footer-cell *matFooterCellDef>Total:</th>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-right"
            >
              DR Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeDR }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeDR() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-right"
            >
              CR Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeCR }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeCR() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Month_Year">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Month Year
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Month_Year }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="TranTypeTotal">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
            >
              Balance
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.TranTypeTotal }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACTranTypeTotal() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Interest_Days">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              class="text-right"
            >
              Interest Days
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Interest_Days }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACInsterestDays() : "" }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Interest_Amount" class="text-right">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Interest Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Interest_Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ dataSource ? calculateACInsterestAmount() : "" }}
            </th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>

  <!-- Table Ends Here -->

  <div class="row m-0 pt-2 pb-1" [hidden]="!dataSource">
    <div class="col-md-2">
      <button class="mt-3 btn font-size-12 button-btn" (click)="exportTable()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
  </div>
</div>

<table
  id="account_interest_calculation"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Amount
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.Voucher_Date | date : "dd/MM/YYYY" }}</td>
    <td style="text-align: right">{{ x.TranTypeDR }}</td>
    <td style="text-align: right">{{ x.TranTypeCR }}</td>
    <td style="text-align: right">{{ x.TranTypeTotal }}</td>
    <td style="text-align: right">{{ x.Interest_Days }}</td>
    <td style="text-align: right">{{ x.Interest_Amount }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="3"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeDR() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeCR() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACTranTypeTotal() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACInsterestDays() : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? calculateACInsterestAmount() : "" }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
