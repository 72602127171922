<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keydown.enter)="onSearchLoanCustomerList()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable"> From Date </span>

      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromDate="ngModel"
          (dateChange)="DateChange()"
          required
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span class="required-lable"> To Date </span>

      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refToDate="ngModel"
          (dateChange)="DateChange()"
          required
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span> Branch</span>
      <select
        name="SelectBranch"
        id="SelectBranch"
        #refSelectGender="ngModel"
        [(ngModel)]="SelectBranch"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
          {{ branch.Branch_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-2">
      <span> Product</span>
      <select
        name="ProductId"
        id="ProductId"
        #refProductId="ngModel"
        [(ngModel)]="ProductId"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': df.submitted && refProductId.invalid,
          'alert-warning':
            refProductId.invalid &&
            (refProductId.dirty ||
              refProductId.touched ||
              refProductId.untouched)
        }"
      >
        >
        <option value="All" disabled>All</option>
        <option *ngFor="let product of ProductList" [value]="product.ProductId">
          {{ product.Product }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <span>Loan Accounting Category</span>
      <select
        name="Loan_AccountingCategoryId"
        id="Loan_AccountingCategoryId"
        [(ngModel)]="LoanAccountCategory"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option
          *ngFor="let AccountingCat of AccountingCatDropdown"
          [value]="AccountingCat.Loan_CategoryId"
        >
          {{ AccountingCat.Loan_category }}
        </option>
      </select>
    </div>

    <div class="col-md-2">
      <span>Sales Executive</span>
      <select
        name="SalesEx"
        id="SalesEx"
        [(ngModel)]="SalesEx"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option
          *ngFor="let SalesExec of SalesExecutiveData"
          [value]="SalesExec.EmpId"
        >
          {{ SalesExec.EmpName }}
        </option>
      </select>
    </div>

    <div class="col-md-4">
      <span class="required-lable">Assign To</span>
      <input
        type="text"
        [formControl]="AccountControl"
        [matAutocomplete]="auto"
        class="form-control input-text-css"
        [(ngModel)]="chhead"
        name="chhead"
        [class.alert-warning]="chhead == ''"
        placeholder="Account Head"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="onAccountHeadhSelect($event)"
      >
        <mat-option
          *ngFor="let account of filteredAccountHead | async"
          [value]="account"
        >
          {{ account.Account_Name }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <div class="col-md-2">
      <button
        type="button"
        (click)="onSearchLoanCustomerList()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
    <div class="col-md-3 mt-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="row mt-3" [hidden]="!DataSource">
  <div class="col-md-12">
    <div class="table-responsive">
      <mat-table
        class="table-bordered table-striped"
        matSort
        [dataSource]="DataSource"
        matTableExporter
        #exporter="matTableExporter"
        style="overflow: auto; height: 60vh; max-width: 100%"
      >
        <ng-container matColumnDef="#">
          <mat-header-cell mat-sort-header *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            {{ row.Loan_Date | date : "dd/MM/YYYY" }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Branch Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.Branch_Name }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Product Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Product_Name }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" style="text-align: right">
            {{ row.Application_LoanAmount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="SalesExecutive">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Sales Executive
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.SalesExecutive }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CollectionExecutive">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Collection Executive
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.CollectionExecutive
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="AC_CustomerType">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            {{
              row.AC_CustomerType === "G"
                ? "Gurantor"
                : row.AC_CustomerType === "H"
                ? "Borrower"
                : row.AC_CustomerType === "C"
                ? "Co-Borrower"
                : ""
            }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="AssignTo">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Assign To
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.AssignTo }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_CreditVisionScore">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            CRC CreditVisionScore
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center">{{
            row.CRC_CreditVisionScore
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_PersonalLoanScore">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            CRC PersonalLoanScore
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center">{{
            row.CRC_PersonalLoanScore
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_Remark">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            CRC Remark
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.CRC_Remark }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="FI_Remark">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            FI Remark
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.FI_Remark }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TVR_Remark">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            TVR Remark
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.TVR_Remark }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Total_Expense">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Total Expense
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-right">{{
            row.Total_Expense
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Total_Income">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Total Income
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-right">{{
            row.Total_Income
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="AC_HirerRelation">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            AC Hirer Relation
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.AC_HirerRelation }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container> -->

        <ng-container matColumnDef="Customer_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Customer_Name }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Relation">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="text-center">
            {{ row.Customer_Relation }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Relation_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Relation Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.Relation_Name }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_PhoneNo">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Phone No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="text-right">
            <div *ngIf="currentUser.IsMasking">
              {{ row.Customer_PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.Customer_PhoneNo }}
            </div>
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-center"
          ></mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="Customer_WhatsAppNo">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >WhatsApp No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="text-right">
            <div *ngIf="currentUser.IsMasking">
              {{ row.Customer_WhatsAppNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.Customer_WhatsAppNo }}
            </div>
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-right"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Email">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Email</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="text-right">
            {{ row.Customer_Email }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-right"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Address1">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Address 1</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="text-left">
            {{ row.Address1 }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-right"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Address2">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Address 2</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            {{ row.Address2 }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            class="text-right"
          ></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></mat-footer-row>
      </mat-table>
    </div>
    <div class="row m-0 ml-3" [hidden]="!DataSource">
      <div class="col-md-4 mt-2">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportExcel()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8 mt-2">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  id="loan-customer-deatils"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type
    </th>

    <!-- <th style="background: #4dc3a3 !important; border: 1px solid white">
      AC_ Hirer Relation
    </th> -->
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address 1
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address 2
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      WhatsApp No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Email
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CRC CreditVisionScore
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CRC PersonalLoanScore
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CRC Remark
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      FI Remark
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TVR Remark
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Expense
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Income
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign To
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date | date : "dd/MM/YYYY" }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>
      {{
        x.AC_CustomerType === "G"
          ? "Gurantor"
          : x.AC_CustomerType === "H"
          ? "Borrower"
          : x.AC_CustomerType === "C"
          ? "Co-Borrower"
          : ""
      }}
    </td>

    <!-- <td>{{ x.AC_HirerRelation }}</td> -->
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.Customer_Relation }}</td>
    <td>{{ x.Relation_Name }}</td>
    <td>{{ x.Address1 }}</td>
    <td>{{ x.Address2 }}</td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.Customer_WhatsAppNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.Customer_WhatsAppNo }}
      </div>
    </td>
    <td>{{ x.Customer_Email }}</td>
    <td>{{ x.CRC_CreditVisionScore }}</td>
    <td>{{ x.CRC_PersonalLoanScore }}</td>
    <td>{{ x.CRC_Remark }}</td>
    <td>{{ x.FI_Remark }}</td>
    <td>{{ x.TVR_Remark }}</td>
    <td>{{ x.Total_Expense }}</td>
    <td>{{ x.Total_Income }}</td>
    <td>{{ x.AssignTo }}</td>
  </tr>
</table>
