<a href="javascript:void(0)" (click)="OnClickDeviationCheck()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in"
  id="DeviationModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Deviation Detail
        </h6>
        <button
          type="button"
          (click)="OnCloseDeviation()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #ddf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 mb2">
            <div
              class="row m-0 col-md-12 p-1"
              [hidden]="LoanRelated && LoanRelated.length == 0"
            >
              <div class="col-md-12 bg-success1">Loan Related Deviation:</div>
            </div>
            <div
              class="row m-0 col-md-12 p-1"
              [hidden]="LoanRelated && LoanRelated.length == 0"
            >
              <div class="table-responsive">
                <mat-table [dataSource]="LoanRelated_DataSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell mw50"
                      >{{ i + 1 }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="Value">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Value
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Minimum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Minimum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Minimum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Maximum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Maximum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Maximum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Col_Val">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Value</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Col_Val
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="UserAssign">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >User Assign</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <select
                        name="UserAssignBy{{ i }}"
                        id="UserAssignBy{{ i }}"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        [(ngModel)]="row.UserAssign"
                        #refUserAssignBy="ngModel"
                        class="form-control input-text-css"
                      >
                        <option value="" selected="selected">
                          Select User
                        </option>
                        <option
                          *ngFor="let cat of UserAssignList"
                          [value]="cat.EmpId"
                        >
                          {{ cat.EmpName }}
                        </option>
                      </select>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Remark">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Remarks</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <input
                        type="text"
                        #reRemark="ngModel"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        placeholder="Remarks"
                        [(ngModel)]="row.Remark"
                        name="Remark{{ i }}"
                        id="Remark{{ i }}"
                        class="form-control input-text-css"
                        [required]="row.UserAssign != ''"
                        [ngClass]="{
                          'is-invalid': ddf.submitted && reRemark.invalid,
                          'alert-warning':
                            reRemark.invalid &&
                            (reRemark.dirty ||
                              reRemark.touched ||
                              reRemark.untouched)
                        }"
                      />
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayLoanRelatedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayLoanRelatedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>

            <div
              class="row m-0 col-md-12 p-1"
              [ngClass]="{ 'mt-3': LoanRelated && LoanRelated.length > 0 }"
              [hidden]="customerRelated && customerRelated.length == 0"
            >
              <div class="col-md-12 bg-success1">
                Customer Related Deviation:
              </div>
            </div>
            <div
              class="row m-0 col-md-12 p-1"
              [hidden]="customerRelated && customerRelated.length == 0"
            >
              <div class="table-responsive">
                <mat-table [dataSource]="customerDataSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell mw50"
                      >{{ i + 1 }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="Value">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Value
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Minimum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Minimum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Minimum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Maximum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Maximum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Maximum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Col_Val">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Value</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Col_Val
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="UserAssign">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >User Assign</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <select
                        name="UserAssignBy1{{ i }}"
                        id="UserAssignBy1{{ i }}"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        [(ngModel)]="row.UserAssign"
                        #refUserAssignBy1="ngModel"
                        class="form-control input-text-css"
                      >
                        <option value="" selected="selected">
                          Select User
                        </option>
                        <option
                          *ngFor="let cat of UserAssignList"
                          [value]="cat.EmpId"
                        >
                          {{ cat.EmpName }}
                        </option>
                      </select>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Remark">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Remarks</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <input
                        type="text"
                        #reRemark1="ngModel"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        placeholder="Remarks"
                        [(ngModel)]="row.Remark"
                        name="Remark1{{ i }}"
                        id="Remark1{{ i }}"
                        class="form-control input-text-css"
                        [required]="row.UserAssign != ''"
                        [ngClass]="{
                          'is-invalid': ddf.submitted && reRemark1.invalid,
                          'alert-warning':
                            reRemark1.invalid &&
                            (reRemark1.dirty ||
                              reRemark1.touched ||
                              reRemark1.untouched)
                        }"
                      />
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayCustomerRelatedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayCustomerRelatedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>

            <div
              class="row m-0 col-md-12 p-1"
              [ngClass]="{
                'mt-3': customerRelated && customerRelated.length > 0
              }"
              [hidden]="DocumentRelated && DocumentRelated.length == 0"
            >
              <div class="col-md-12 bg-success1">
                Document Related Deviation:
              </div>
            </div>
            <div
              class="row m-0 col-md-12 p-1"
              [hidden]="DocumentRelated && DocumentRelated.length == 0"
            >
              <div class="table-responsive">
                <mat-table [dataSource]="DocumentRelated_DataSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell mw50"
                      >{{ i + 1 }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="Value">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Value
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="KYC_DocNumber">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >KYC Doc Number</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.KYC_DocNumber
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="UserAssign">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >User Assign</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <select
                        name="UserAssignBy2{{ i }}"
                        id="UserAssignBy2{{ i }}"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        [(ngModel)]="row.UserAssign"
                        #refUserAssignBy2="ngModel"
                        class="form-control input-text-css"
                      >
                        <option value="" selected="selected">
                          Select User
                        </option>
                        <option
                          *ngFor="let cat of UserAssignList"
                          [value]="cat.EmpId"
                        >
                          {{ cat.EmpName }}
                        </option>
                      </select>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Remark">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Remarks</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <input
                        type="text"
                        #reRemark2="ngModel"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        placeholder="Remarks"
                        [(ngModel)]="row.Remark"
                        name="Remark2{{ i }}"
                        id="Remark2{{ i }}"
                        class="form-control input-text-css"
                        [required]="row.UserAssign != ''"
                        [ngClass]="{
                          'is-invalid': ddf.submitted && reRemark2.invalid,
                          'alert-warning':
                            reRemark2.invalid &&
                            (reRemark2.dirty ||
                              reRemark2.touched ||
                              reRemark2.untouched)
                        }"
                      />
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayDocumentRelatedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayDocumentRelatedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>

            <div
              class="row m-0 col-md-12 p-1"
              [ngClass]="{ 'mt-3': CRCRelated && CRCRelated.length > 0 }"
              [hidden]="CRCRelated && CRCRelated.length == 0"
            >
              <div class="col-md-12 bg-success1">CRC Related Deviation:</div>
            </div>
            <div
              class="row m-0 col-md-12 p-1"
              [hidden]="CRCRelated && CRCRelated.length == 0"
            >
              <div class="table-responsive">
                <mat-table [dataSource]="CRCRelated_DataSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell mw50"
                      >{{ i + 1 }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="Value">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Value
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Minimum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Minimum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Minimum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Maximum">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Maximum</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Maximum
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Col_Val">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Value</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Col_Val
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="UserAssign">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >User Assign</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <select
                        name="UserAssignBy1{{ i }}"
                        id="UserAssignBy1{{ i }}"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        [(ngModel)]="row.UserAssign"
                        #refUserAssignBy1="ngModel"
                        class="form-control input-text-css"
                      >
                        <option value="" selected="selected">
                          Select User
                        </option>
                        <option
                          *ngFor="let cat of UserAssignList"
                          [value]="cat.EmpId"
                        >
                          {{ cat.EmpName }}
                        </option>
                      </select>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Remark">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Remarks</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <input
                        type="text"
                        #reRemark3="ngModel"
                        *ngIf="
                          row.IsDeviation == 'True' && row.Deviation == 'True'
                        "
                        placeholder="Remarks"
                        [(ngModel)]="row.Remark"
                        name="Remark1{{ i }}"
                        id="Remark1{{ i }}"
                        class="form-control input-text-css"
                        [required]="row.UserAssign != ''"
                        [ngClass]="{
                          'is-invalid': ddf.submitted && reRemark3.invalid,
                          'alert-warning':
                            reRemark3.invalid &&
                            (reRemark3.dirty ||
                              reRemark3.touched ||
                              reRemark3.untouched)
                        }"
                      />
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayCRCRelatedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayCRCRelatedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseDeviation()"
            class="font-size-12 button-btn mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveDeviation()"
            class="font-size-12 button-btn"
            [disabled]="!ddf.form.valid"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
