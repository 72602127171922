import { Component, OnInit } from '@angular/core';
import { constantUrl } from "../Shared/constantUrl";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  BasePath: any = constantUrl.apiProfilePicUrl;
  currentUser: any;
  CompanyLogo: any; 

  ngOnInit() { 
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CompanyLogo = this.currentUser.CompanyLogo;
  }

}
