import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../services/lms.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lms-insurance',
  templateUrl: './lms-insurance.component.html',
  styleUrls: ['./lms-insurance.component.scss']
})
export class LmsInsuranceComponent implements OnInit {

  currentUser: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  displayedInsuranceColumns: string[] = ['Int_Id', 'Type', 'Name', 'Company Name', 'IDV/Sum Assured', 'Expire Date', 'View Detail', 'Action'];
  dataCSource: any;
  AssetInsuranceCompany: any = ''; AssetInsuranceCompanyName: any = ''; AssetInsuranceCompanyBranch: any = ''; AssetInsuranceCompanyBranchName: any = ''; AssetAgentName: any = '';
  AssetInsuranceDate: any = ''; AssetRenewalDate: any = ''; AssetCoverNoteNo: any = '';
  AssetPolicyType: any = ''; AssetPolicyTypeName: any = ''; AssetIDVAmount: any = '0'; AssetPremiumAmount: any = '0';
  AssetNomineeName: any = ''; AssetNomineeRelationwithHirer: any = ''; AssetNomineeRelationwithHirerName: any = ''; AssetPremiumAmountLoan: any = '';

  CustomerInsHirerLifeInsCmny: any = ''; CustomerInsAssetInsuranceCompanyBranch: any = ''; CustomerInsHirerLifeInsCmnyName: any = ''; CustomerInsAssetInsuranceCompanyBranchName: any = ''; CustomerInsAgentName: any = '';
  CustomerInsInsuranceDate: any = ''; CustomerInsExpireDate: any = ''; CustomerInsPolicyNo: any = '';
  CustomerInsPolicyType: any = ''; CustomerInsPolicyTypeName: any = ''; CustomerInsSumAssured: any = '0'; CustomerInsPremiumAmount: any = '';
  CustomerInsPremiumNextDueDate: any = ''; CustomerInsNomineeName: any = ''; CustomerInsPremiumAmountDeducted: any = '';
  AssetInsuranceEndorsed: any = ''; CustomerInsNomineeRelationHirer: any = ''; Name: any = '';
  Insurance_Company_List: any;
  Insurance_Branch_List: any;
  tab: any;
  rdDetailData: any;
  inquiryId: any;
  inquiryDetail: any;
  loading: boolean = false;
  FormShow: boolean = false;
  DetailShow: boolean = true;
  isShowSave: boolean = true;
  isShowUpdate: boolean = false;
  CustomerShow: boolean = false;
  AssetsShow: boolean = true;
  AssetsDetailShow: boolean = false;
  CustomerDetailShow: boolean = false;
  rdDataSource: any = '';
  CurrentDate: any = new Date();
  ReceiptData: any = {};
  ApplicationId: any;
  productDropdown: any[] = [];
  chargesDropdown: any[] = [];
  InsuranceInfo: any[] = [];
  dataSource: any;
  // LoanNo: any;
  Type: any;
  IntId = 0;
  RelationList: any;
  CustomerAssetsList: any = [];
  Id: any;
  displayedColumns: string[] = ['SchemeId', 'Scheme', 'Amount', 'ROI', 'Period', 'AdvanceEMI', 'Select'];
  selectedScheme: any = {};
  cEIModel: any = { IRR_Type: 'Flat', EMI_Type: 'Monthly', ProductId: "", IRR_CalculateBy: 'ROI' };
  stepEMI: any[] = [];
  emiIrr: any;
  PolicyTypeAssetsOption: any;
  PolicyTypeCustomerOption: any;
  stepEmiData: any;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  PageAcess: any;

  constructor(private dataSharingService: DataSharingService, private _MasterService: MasterService,
    private route: ActivatedRoute, private snackBar: MatSnackBar, private encdec: EncrDecrService,
    private router: Router, private losService: LosService,
    private LmsService: LmsService
  ) {

    // this.route.paramMap.subscribe((param: ParamMap) => {
    //   let type = decodeURIComponent(param.get("Type"));
    //   this.dataSharingService.LmsHeaderType.next(type);
    //   if (type == 'L') {
    //     let LoanId = decodeURIComponent(param.get("id"));
    //     this.dataSharingService.LmsHeaderLoanId.next(LoanId);
    //     this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
    //   } else {
    //     let LoanId = decodeURIComponent(param.get("id"));
    //     this.dataSharingService.LmsHeaderLoanId.next(LoanId);
    //     this.dataSharingService.LmsHeaderLoanOR_APP_No.next(param.get("LA-No"));
    //   }
    //   this.ApplicationId = 106;
    //   this.inquiryId = 10;
    this.Type = "Assets";
    //});
  }
  onCloseInsurance() {
    $('#InsuranceModel').modal('hide');
  }
  onCloseInsuranceDetail() {
    $('#InsuranceDetailModel').modal('hide');
  }
  OpenModelInsurance() {
    this.isShowSave = true;
    this.isShowUpdate = false;
    this.Type = "Assets";
    this.Id = "";
    this.IntId = 0;
    this.AssetInsuranceCompany = "";
    this.AssetInsuranceCompanyBranch = "";
    this.AssetAgentName = "";
    this.AssetInsuranceDate = "";
    this.AssetRenewalDate = "";
    this.AssetCoverNoteNo = "";
    this.AssetPolicyType = "";
    this.AssetIDVAmount = "0";
    this.AssetPremiumAmount = "0";
    this.AssetNomineeName = "";
    this.AssetNomineeRelationwithHirer = "";
    this.AssetPremiumAmountLoan = "";

    this.CustomerInsHirerLifeInsCmny = "";
    this.CustomerInsAssetInsuranceCompanyBranch = "";
    this.CustomerInsAgentName = "";
    this.CustomerInsInsuranceDate = "";
    this.CustomerInsExpireDate = "";
    this.CustomerInsPolicyNo = "";
    this.CustomerInsPolicyType = "";
    this.CustomerInsSumAssured = "";
    this.CustomerInsPremiumAmount = "";
    this.CustomerInsPremiumNextDueDate = "";
    this.CustomerInsNomineeName = "";
    this.CustomerInsPremiumAmountDeducted = "";
    this.CustomerInsNomineeRelationHirer = "";
    this.AssetInsuranceEndorsed = "";
    this.CustomerInsNomineeRelationHirer = ""

    this.getAssetsList();
    this.AssetsShow = true;
    this.CustomerShow = false;
    this.OpenModel();
  }
  OpenModel() {
    $('#InsuranceModel').modal('show');
    $("#InsuranceModel").css("z-index", "1050");
  }
  changeAssetRenewalDate(event: any) {
    //this.AssetRenewalDate = this.AssetInsuranceDate
    var year = this.AssetInsuranceDate.getFullYear();
    var month = this.AssetInsuranceDate.getMonth();
    var day = this.AssetInsuranceDate.getDate();
    var c = new Date(year + 1, month, day - 1);
    console.log("new date", c);
    this.AssetRenewalDate = c;
  }
  changeInsuranceDate(event: any) {
    //this.AssetRenewalDate = this.AssetInsuranceDate
    var year = this.CustomerInsInsuranceDate.getFullYear();
    var month = this.CustomerInsInsuranceDate.getMonth();
    var day = this.CustomerInsInsuranceDate.getDate();
    var c = new Date(year + 1, month, day - 1);
    console.log("new date", c);
    this.CustomerInsExpireDate = c;
  }
  changeExpireDate(event: any) {
    if (this.CustomerInsExpireDate < this.CustomerInsInsuranceDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Customer Insurance Expire Date greater than Customer Insurance Date.",
        ...this.configSuccess,
      });
      this.CustomerInsExpireDate = '';
    }
  }
  changeRenewalDate(event: any) {
    if (this.AssetRenewalDate < this.AssetInsuranceDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Asset Renewal Date greater than Asset Insurance Date.",
        ...this.configSuccess,
      });
      this.AssetRenewalDate = '';
    }
  }
  ShowForm(event) {
    console.log(event);
    console.log(this.Type);
    if (this.Type == "Assets") {
      this.AssetsShow = true;
      this.CustomerShow = false;
      this.getAssetsList();
    }
    if (this.Type == "Customer") {
      this.AssetsShow = false;
      this.CustomerShow = true;
      this.getCustomerList();
    }
  }
  getAssetsList() {
    this.CustomerAssetsList = [];
    // this.CustomerAssetsList = [{CustomerId: '', Customer:'' }];
    this.LmsService.LMS_GetAllUserAssetInfo({ Loan_Id: this.loanSummary.LoanId }).subscribe((response: any) => {
      console.log(response);

      for (let i = 0; i < response.length; i++) {
        this.CustomerAssetsList.push({ CustomerId: response[i].AssetId + "-" + response[i].Collateral, Customer: response[i].ForInsurance })
        // this.CustomerAssetsList[i].Customer = response[i].ForInsurance;
      }
      //this.CustomerAssetsList = JSON.parse(JSON.stringify(response));
      console.log(this.CustomerAssetsList);
    });
  }
  getCustomerList() {
    this.CustomerAssetsList = [];
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      this.CustomerAssetsList = JSON.parse(JSON.stringify(result));
      console.log(this.CustomerAssetsList);
    });
  }
  onSaveCustomerDetails() {
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));


    let data = {
      Int_Id: this.IntId,
      IsActive: 0,
      ModifyBy: localUser.userId,
      LoanId: this.loanSummary.LoanId,
      AssetInsuranceCompany: '',
      AssetInsuranceCompanyBranch: '',
      AssetAgentName: '',
      AssetInsuranceDate: '',
      AssetRenewalDate: '',
      AssetCoverNoteNo: '',
      AssetPolicyType: '', AssetIDVAmount: '0',
      AssetPremiumAmount: '0',
      AssetNomineeName: '',
      //AssetNomineeRelation: '',
      AssetPremiumAmountDeducted: '',
      AssetNomineeRelation: this.AssetNomineeRelationwithHirer,
      CustomerInsHirerLifeInsurance: this.CustomerInsHirerLifeInsCmny,
      CustomerInsAssetInsuranceCompany: this.CustomerInsAssetInsuranceCompanyBranch,
      CustomerInsAgentName: this.CustomerInsAgentName,
      CustomerInsInsuranceDate: this.CustomerInsInsuranceDate,
      CustomerInsExpireDate: this.CustomerInsExpireDate,
      CustomerInsPolicyNo: this.CustomerInsPolicyNo,
      CustomerInsPolicyType: this.CustomerInsPolicyType,
      CustomerInsSumAssured: this.CustomerInsSumAssured,
      CustomerInsPremiumAmount: this.CustomerInsPremiumAmount,
      CustomerInsPremiumNextDueDate: this.CustomerInsPremiumNextDueDate,
      CustomerInsNomineeName: this.CustomerInsNomineeName,
      CustomerInsPremiumAmountDeducted: this.CustomerInsPremiumAmountDeducted,
      CustomerInsNomineeRelationHirer: this.CustomerInsNomineeRelationHirer,
      AssetInsuranceEndorsed: '',
      CustomerOrAssetsId: Number(this.Id),
      InsuranceAssets: '',
      Type: this.Type,
    }
    console.log('data', data);
    this.LmsService.SaveInsurance(data).subscribe((res: any) => {
      this.loading = false;
      this.get_InsuranceInfo();
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
      if (this.CustomerInsPremiumAmountDeducted == 'Daily') {
        this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
        this.ReceiptData.CollectionBy = 'Amit';
        this.ReceiptData.CollectionOn = new Date();
        this.ReceiptData.Int_Id = 0;
        this.ReceiptData.IsActive = 0;
        this.ReceiptData.Charges = 'Advance EMI';
        this.ReceiptData.Type = 'Deduction';
        this.ReceiptData.Amount = this.CustomerInsPremiumAmount;
        this.ReceiptData.CGST = 0;
        this.ReceiptData.SGST = 0;
        this.ReceiptData.TotalAmount = this.CustomerInsPremiumAmount;
        this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe((res: any) => {
          this.showSpinner = false;


          //this.rdDataSource = new MatTableDataSource(res.Item2);
        });
      }

    })
  }
  Delete(data: any) {
    console.log(data.Int_Id);
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LmsService.DeleteInsuranceDetails({ Int_Id: data.Int_Id, LoginUserId: localUser.userId }).subscribe((res: any) => {
      this.loading = false;
      this.get_InsuranceInfo();
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }

    })
  }
  onSaveAssetsDetail() {
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let data = {
      Int_Id: this.IntId,
      IsActive: 0,
      ModifyBy: localUser.userId,
      LoanId: this.loanSummary.LoanId,
      AssetInsuranceCompany: this.AssetInsuranceCompany,
      AssetInsuranceCompanyBranch: this.AssetInsuranceCompanyBranch,
      AssetAgentName: this.AssetAgentName,
      AssetInsuranceDate: this.AssetInsuranceDate,
      AssetRenewalDate: this.AssetRenewalDate,
      AssetCoverNoteNo: this.AssetCoverNoteNo,
      AssetPolicyType: this.AssetPolicyType, AssetIDVAmount: this.AssetIDVAmount,
      AssetPremiumAmount: this.AssetPremiumAmount,
      AssetNomineeName: this.AssetNomineeName,
      AssetNomineeRelation: this.AssetNomineeRelationwithHirer,
      AssetPremiumAmountDeducted: this.AssetPremiumAmountLoan,
      // CustomerOrAssetsId: this.Id,
      CustomerOrAssetsId: Number(this.Id.split('-')[0]),
      InsuranceAssets: this.Id.split('-')[1],
      Type: this.Type,
      CustomerInsHirerLifeInsurance: '',
      CustomerInsAssetInsuranceCompany: '',
      CustomerInsAgentName: '',
      CustomerInsInsuranceDate: '',
      CustomerInsExpireDate: '',
      CustomerInsPolicyNo: '',
      CustomerInsPolicyType: '',
      CustomerInsSumAssured: '',
      CustomerInsPremiumAmount: '',
      CustomerInsPremiumNextDueDate: '',
      CustomerInsNomineeName: '',
      CustomerInsPremiumAmountDeducted: '',
      CustomerInsNomineeRelationHirer: '',
      AssetInsuranceEndorsed: this.AssetInsuranceEndorsed
    }
    console.log('data', data);
    this.LmsService.SaveInsurance(data).subscribe((res: any) => {
      this.loading = false;
      this.get_InsuranceInfo();
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
      if (this.AssetPremiumAmountLoan == 'Daily') {
        this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
        this.ReceiptData.CollectionBy = 'Amit';
        this.ReceiptData.CollectionOn = new Date();
        this.ReceiptData.Int_Id = 0;
        this.ReceiptData.IsActive = 0;
        this.ReceiptData.Charges = 'Advance EMI';
        this.ReceiptData.Type = 'Deduction';
        this.ReceiptData.Amount = this.AssetPremiumAmount;
        this.ReceiptData.CGST = 0;
        this.ReceiptData.SGST = 0;
        this.ReceiptData.TotalAmount = this.AssetPremiumAmount;
        this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe((res: any) => {
          this.showSpinner = false;


          //this.rdDataSource = new MatTableDataSource(res.Item2);
        });
      }

    })

  }
  goToUpdate(data: any) {
    console.log(data.Int_Id);
    this.LmsService.GetInsuranceDetailsById({ Int_Id: data.Int_Id }).subscribe((res: any) => {
      this.loading = false;

      //this.get_InsuranceInfo(this.LoanNo);
      this.showSpinner = false;
      this.InsuranceInfo = res[0];
      console.log("edit", this.InsuranceInfo);
      //this.LoanNo = this.InsuranceInfo["LoanAcNo"];
      this.Type = this.InsuranceInfo["Type"];

      this.IntId = data.Int_Id;
      this.AssetInsuranceCompany = this.InsuranceInfo["AssetInsuranceCompany"];
      this.AssetInsuranceCompanyBranch = this.InsuranceInfo["AssetInsuranceCompanyBranch"];
      this.AssetAgentName = this.InsuranceInfo["AssetAgentName"];
      this.AssetInsuranceDate = new Date(moment(this.InsuranceInfo["AssetInsuranceDate"]).format('MM/DD/YYYY'));
      this.AssetRenewalDate = new Date(moment(this.InsuranceInfo["AssetRenewalDate"]).format('MM/DD/YYYY'));
      this.AssetCoverNoteNo = this.InsuranceInfo["AssetCoverNoteNo"];
      this.AssetPolicyType = this.InsuranceInfo["AssetPolicyType"];
      this.AssetIDVAmount = this.InsuranceInfo["AssetIdvAmount"];
      this.AssetPremiumAmount = this.InsuranceInfo["AssetPremiumAmount"];
      this.AssetNomineeName = this.InsuranceInfo["AssetNomineeName"];
      this.AssetNomineeRelationwithHirer = this.InsuranceInfo["AssetNomineeRelation"];
      this.AssetPremiumAmountLoan = this.InsuranceInfo["AssetPremiumAmountDeducted"];

      this.CustomerInsHirerLifeInsCmny = this.InsuranceInfo["CustomerInsHirerLifeInsurance"];
      this.CustomerInsAssetInsuranceCompanyBranch = this.InsuranceInfo["CustomerInsAssetInsuranceCompany"];
      this.CustomerInsAgentName = this.InsuranceInfo["CustomerInsAgentName"];
      this.CustomerInsInsuranceDate = new Date(moment(this.InsuranceInfo["CustomerInsInsuranceDate"]).format('MM/DD/YYYY'));
      this.CustomerInsExpireDate = new Date(moment(this.InsuranceInfo["CustomerInsExpireDate"]).format('MM/DD/YYYY'));
      this.CustomerInsPolicyNo = this.InsuranceInfo["CustomerInsPolicyNo"];
      this.CustomerInsPolicyType = this.InsuranceInfo["CustomerInsPolicyType"];
      this.CustomerInsSumAssured = this.InsuranceInfo["CustomerInsSumAssured"];
      this.CustomerInsPremiumAmount = this.InsuranceInfo["CustomerInsPremiumAmount"];
      this.CustomerInsPremiumNextDueDate = new Date(moment(this.InsuranceInfo["CustomerInsPremiumNextDueDate"]).format('MM/DD/YYYY'));
      this.CustomerInsNomineeName = this.InsuranceInfo["CustomerInsNomineeName"];
      this.CustomerInsPremiumAmountDeducted = this.InsuranceInfo["CustomerInsPremiumAmountDeducted"];
      this.CustomerInsNomineeRelationHirer = this.InsuranceInfo["CustomerInsNomineeRelationHirer"];
      this.AssetInsuranceEndorsed = this.InsuranceInfo["AssetInsuranceEndorsed"];
      let Company_Id;
      if (this.Type == "Assets") {
        Company_Id = this.AssetInsuranceCompany;
      }
      if (this.Type == "Customer") {
        Company_Id = this.CustomerInsHirerLifeInsCmny;
      }
      this.LmsService.GetInsuranceBranchList({ Insurance_Company_Id: Company_Id }).subscribe((result) => {
        this.Insurance_Branch_List = JSON.parse(JSON.stringify(result));
        console.log(result);
      });
      if (this.CustomerInsNomineeRelationHirer == "Yes") {

        var element1 = <HTMLInputElement>document.getElementById("NomineeRelation2");
        element1.checked = true;
      }

      if (this.AssetInsuranceEndorsed == "Yes") {

        var element1 = <HTMLInputElement>document.getElementById("Endorsed1");
        element1.checked = true;
      }
      if (data.Type == "Assets") {

        this.AssetsShow = true;
        this.CustomerShow = false;
        this.isShowUpdate = true;
        this.getAssetsList();
        this.isShowSave = false;
        this.Id = this.InsuranceInfo["CustomerOrAssetsId"] + "-" + this.InsuranceInfo["InsuranceAssets"];
        this.OpenModel();
      }
      if (data.Type == "Customer") {


        this.AssetsShow = false;
        this.CustomerShow = true;
        this.isShowUpdate = true;
        this.isShowSave = false;
        this.getCustomerList();
        this.Id = this.InsuranceInfo["CustomerOrAssetsId"];
        this.OpenModel();
      }

    })
  }

  goToDetail(data: any) {
    console.log(data.Int_Id);
    this.LmsService.GetInsuranceDetailsById({ Int_Id: data.Int_Id }).subscribe((res: any) => {
      this.loading = false;

      //this.get_InsuranceInfo(this.LoanNo);
      this.showSpinner = false;
      this.InsuranceInfo = res[0];
      console.log("edit", this.InsuranceInfo);
      //this.LoanNo = this.InsuranceInfo["LoanAcNo"];
      this.Type = this.InsuranceInfo["Type"];
      if (this.Type == "Assets") {
        this.CustomerDetailShow = false;
        this.AssetsDetailShow = true;
      }
      if (this.Type == "Customer") {
        this.CustomerDetailShow = true;
        this.AssetsDetailShow = false;
      }

      this.IntId = data.Int_Id;
      this.Name = this.InsuranceInfo["Name"];
      this.AssetInsuranceCompany = this.InsuranceInfo["AssetInsuranceCompany"];
      this.AssetInsuranceCompanyName = this.InsuranceInfo["AssetInsuranceCompanyName"];
      this.AssetInsuranceCompanyBranch = this.InsuranceInfo["AssetInsuranceCompanyBranch"];
      this.AssetInsuranceCompanyBranchName = this.InsuranceInfo["AssetInsuranceCompanyBranchName"];
      this.AssetAgentName = this.InsuranceInfo["AssetAgentName"];
      this.AssetInsuranceDate = this.InsuranceInfo["AssetInsuranceDate"];
      this.AssetRenewalDate = this.InsuranceInfo["AssetRenewalDate"];
      this.AssetCoverNoteNo = this.InsuranceInfo["AssetCoverNoteNo"];
      this.AssetPolicyType = this.InsuranceInfo["AssetPolicyType"];
      this.AssetPolicyTypeName = this.InsuranceInfo["AssetPolicyTypeName"];
      this.AssetIDVAmount = this.InsuranceInfo["AssetIdvAmount"];
      this.AssetPremiumAmount = this.InsuranceInfo["AssetPremiumAmount"];
      this.AssetNomineeName = this.InsuranceInfo["AssetNomineeName"];
      this.AssetNomineeRelationwithHirer = this.InsuranceInfo["AssetNomineeRelation"];
      this.AssetNomineeRelationwithHirerName = this.InsuranceInfo["AssetNomineeRelationName"];
      this.AssetPremiumAmountLoan = this.InsuranceInfo["AssetPremiumAmountDeducted"];

      this.CustomerInsHirerLifeInsCmny = this.InsuranceInfo["CustomerInsHirerLifeInsurance"];
      this.CustomerInsHirerLifeInsCmnyName = this.InsuranceInfo["CustomerInsHirerLifeInsuranceName"];
      this.CustomerInsAssetInsuranceCompanyBranch = this.InsuranceInfo["CustomerInsAssetInsuranceCompany"];
      this.CustomerInsAssetInsuranceCompanyBranchName = this.InsuranceInfo["CustomerInsAssetInsuranceCompanyName"];
      this.CustomerInsAgentName = this.InsuranceInfo["CustomerInsAgentName"];
      this.CustomerInsInsuranceDate = this.InsuranceInfo["CustomerInsInsuranceDate"];
      this.CustomerInsExpireDate = this.InsuranceInfo["CustomerInsExpireDate"];
      this.CustomerInsPolicyNo = this.InsuranceInfo["CustomerInsPolicyNo"];
      this.CustomerInsPolicyType = this.InsuranceInfo["CustomerInsPolicyType"];
      this.CustomerInsPolicyTypeName = this.InsuranceInfo["CustomerInsPolicyTypeName"];
      this.CustomerInsSumAssured = this.InsuranceInfo["CustomerInsSumAssured"];
      this.CustomerInsPremiumAmount = this.InsuranceInfo["CustomerInsPremiumAmount"];
      this.CustomerInsPremiumNextDueDate = this.InsuranceInfo["CustomerInsPremiumNextDueDate"];
      this.CustomerInsNomineeName = this.InsuranceInfo["CustomerInsNomineeName"];
      this.CustomerInsPremiumAmountDeducted = this.InsuranceInfo["CustomerInsPremiumAmountDeducted"];
      this.CustomerInsNomineeRelationHirer = this.InsuranceInfo["CustomerInsNomineeRelationHirer"];
      this.AssetInsuranceEndorsed = this.InsuranceInfo["AssetInsuranceEndorsed"];
      $('#InsuranceDetailModel').modal('show');
      $("#InsuranceDetailModel").css("z-index", "1050");
    })



  }
  EditInsuranceInfo() {
    //this.LmsService.GetInsuranceDetails({ LoanAcNo: this.LoanNo }).subscribe((res: any) => {
    //  if (res[0] != undefined) {

    //    this.showSpinner = false;
    //    this.InsuranceInfo = res[0];
    //    console.log("edit", this.InsuranceInfo);
    //    this.LoanNo = this.InsuranceInfo["LoanAcNo"];
    //    this.IntId = this.InsuranceInfo["Int_Id"];
    //    this.AssetInsuranceCompany = this.InsuranceInfo["AssetInsuranceCompany"];
    //    this.AssetInsuranceCompanyBranch = this.InsuranceInfo["AssetInsuranceCompanyBranch"];
    //    this.AssetAgentName = this.InsuranceInfo["AssetAgentName"];
    //    this.AssetInsuranceDate = new Date(moment(this.InsuranceInfo["AssetInsuranceDate"]).format('MM/DD/YYYY'));
    //    this.AssetRenewalDate = new Date(moment(this.InsuranceInfo["AssetRenewalDate"]).format('MM/DD/YYYY'));
    //    this.AssetCoverNoteNo = this.InsuranceInfo["AssetCoverNoteNo"];
    //    this.AssetPolicyType = this.InsuranceInfo["AssetPolicyType"];
    //    this.AssetIDVAmount = this.InsuranceInfo["AssetIdvAmount"];
    //    this.AssetPremiumAmount = this.InsuranceInfo["AssetPremiumAmount"];
    //    this.AssetNomineeName = this.InsuranceInfo["AssetNomineeName"];
    //    this.AssetNomineeRelationwithHirer = this.InsuranceInfo["AssetNomineeRelation"];
    //    this.AssetPremiumAmountLoan = this.InsuranceInfo["AssetPremiumAmountDeducted"];

    //    this.CustomerInsHirerLifeInsCmny = this.InsuranceInfo["CustomerInsHirerLifeInsurance"];
    //    this.CustomerInsAssetInsuranceCompanyBranch = this.InsuranceInfo["CustomerInsAssetInsuranceCompany"];
    //    this.CustomerInsAgentName = this.InsuranceInfo["CustomerInsAgentName"];
    //    this.CustomerInsInsuranceDate = new Date(moment(this.InsuranceInfo["CustomerInsInsuranceDate"]).format('MM/DD/YYYY'));
    //    this.CustomerInsExpireDate = new Date(moment(this.InsuranceInfo["CustomerInsExpireDate"]).format('MM/DD/YYYY'));
    //    this.CustomerInsPolicyNo = this.InsuranceInfo["CustomerInsPolicyNo"];
    //    this.CustomerInsPolicyType = this.InsuranceInfo["CustomerInsPolicyType"];
    //    this.CustomerInsSumAssured = this.InsuranceInfo["CustomerInsSumAssured"];
    //    this.CustomerInsPremiumAmount = this.InsuranceInfo["CustomerInsPremiumAmount"];
    //    this.CustomerInsPremiumNextDueDate = new Date(moment(this.InsuranceInfo["CustomerInsPremiumNextDueDate"]).format('MM/DD/YYYY'));
    //    this.CustomerInsNomineeName = this.InsuranceInfo["CustomerInsNomineeName"];
    //    this.CustomerInsPremiumAmountDeducted = this.InsuranceInfo["CustomerInsPremiumAmountDeducted"];
    //    this.CustomerInsNomineeRelationHirer = this.InsuranceInfo["CustomerInsNomineeRelationHirer"];
    //    this.AssetInsuranceEndorsed = this.InsuranceInfo["AssetInsuranceEndorsed"];
    //    if (this.CustomerInsNomineeRelationHirer == "Yes") {

    //      var element1 = <HTMLInputElement>document.getElementById("NomineeRelation");
    //      element1.checked = true;
    //    }

    //    if (this.AssetInsuranceEndorsed == "Yes") {

    //      var element1 = <HTMLInputElement>document.getElementById("Endorsed");
    //      element1.checked = true;
    //    }
    //    this.FormShow = true;
    //    this.DetailShow = false;
    //  }
    //});
  }
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Insurance');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let AllPage = JSON.parse(sessionStorage.getItem('AllPageAcess'));
    this.PageAcess = AllPage.filter(x => x.Page_Name == "Insurance");

    if (this.PageAcess == '' || this.PageAcess == null || this.PageAcess == undefined) {
      this.RedirectTo();
    }

    // this.dataSharingService.getLmsHeaderLoanNo().subscribe(value => {
    //  if (value) {
    this.FormShow = false;
    this.DetailShow = true;
    this.AssetsShow = true;
    this.CustomerShow = false;
    // this.LoanNo = value;
    //console.log(this.LoanNo);
    this.get_InsuranceInfo()
    this.IntId = 0;
    this.isShowSave = true;
    this.isShowUpdate = false;
    this.getAssetsList();
    this.getInsuranceCompany();
    this.getAssetsPolicyType();
    this.getCustomerPolicyType();
    this.getRelationList();
    // }
    // });
  }
  get_InsuranceInfo() {
    //console.log("InsuranceInfo", LoanNo);
    this.onCloseInsurance();
    // this.LoanNo = LoanNo
    this.showSpinner = true;
    this.LmsService.GetInsuranceDetails({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
      if (res[0] != undefined) {
        this.dataCSource = new MatTableDataSource(res);
        //    this.FormShow = false;
        //    this.DetailShow = true;
        //    this.showSpinner = false;
        //    this.InsuranceInfo = res[0];
        //    this.LoanNo = res[0].LoanAcNo;
        //    this.IntId = res[0].Int_Id;
        //    this.AssetInsuranceCompany = res[0].AssetInsuranceCompany;
        //    this.AssetInsuranceCompanyBranch = res[0].AssetInsuranceCompanyBranch;
        //    this.AssetAgentName = res[0].AssetAgentName;
        //    this.AssetInsuranceDate = new Date(moment(res[0].AssetInsuranceDate).format('MM/DD/YYYY'));
        //    this.AssetRenewalDate = new Date(moment(res[0].AssetRenewalDate).format('MM/DD/YYYY'));
        //    this.AssetCoverNoteNo = res[0].AssetCoverNoteNo;
        //    this.AssetPolicyType = res[0].AssetPolicyType;
        //    this.AssetIDVAmount = res[0].AssetIdvAmount;
        //    this.AssetPremiumAmount = res[0].AssetPremiumAmount;
        //    this.AssetNomineeName = res[0].AssetNomineeName;
        //    this.AssetNomineeRelationwithHirer = res[0].AssetNomineeRelation;
        //    this.AssetPremiumAmountLoan = res[0].AssetPremiumAmountDeducted;

        //    this.CustomerInsHirerLifeInsCmny = res[0].CustomerInsHirerLifeInsurance;
        //    this.CustomerInsAssetInsuranceCompanyBranch = res[0].CustomerInsAssetInsuranceCompany;
        //    this.CustomerInsAgentName = res[0].CustomerInsAgentName;
        //    this.CustomerInsInsuranceDate = new Date(moment(res[0].CustomerInsInsuranceDate).format('MM/DD/YYYY'));
        //    this.CustomerInsExpireDate = new Date(moment(res[0].CustomerInsExpireDate).format('MM/DD/YYYY'));
        //    this.CustomerInsPolicyNo = res[0].CustomerInsPolicyNo;
        //    this.CustomerInsPolicyType = res[0].CustomerInsPolicyType;
        //    this.CustomerInsSumAssured = res[0].CustomerInsSumAssured;
        //    this.CustomerInsPremiumAmount = res[0].CustomerInsPremiumAmount;
        //    this.CustomerInsPremiumNextDueDate = new Date(moment(res[0].CustomerInsPremiumNextDueDate).format('MM/DD/YYYY'));
        //    this.CustomerInsNomineeName = res[0].CustomerInsNomineeName;
        //    this.CustomerInsPremiumAmountDeducted = res[0].CustomerInsPremiumAmountDeducted;
        //    this.CustomerInsNomineeRelationHirer = res[0].CustomerInsNomineeRelationHirer;
        //    this.AssetInsuranceEndorsed = res[0].AssetInsuranceEndorsed
        //    //console.log(document.getElementById('NomineeRelationHirer'));
        //    if (this.CustomerInsNomineeRelationHirer == "Yes") {

        //      var element = <HTMLInputElement>document.getElementById("NomineeRelationHirer")
        //      element.checked = true;
        //      //var element1 = <HTMLInputElement>document.getElementById("NomineeRelation");
        //      //element1.checked = true;
        //    }

        //    if (this.AssetInsuranceEndorsed == "Yes") {

        //      var element = <HTMLInputElement>document.getElementById("InsuranceEndorsedChange")
        //      element.checked = true;
        //      //var element1 = <HTMLInputElement>document.getElementById("Endorsed");
        //      //element1.checked = true;
        //    }


        //    this.isShowUpdate = true;
        //    this.isShowSave = false;
        //    //console.log(this.InsuranceInfo);
        //    //console.log(res[0].AssetIdvAmount);
        //  } else {
        //    this.FormShow = true;
        //    this.DetailShow = false;
      }
    })
  }
  InsuranceEndorsedChange(event) {
    if (event.srcElement.checked == true)
      this.AssetInsuranceEndorsed = 'Yes';
    else
      this.AssetInsuranceEndorsed = 'No';
  }

  NomineeRelationHirerChange(event) {
    if (event.srcElement.checked == true)
      this.CustomerInsNomineeRelationHirer = 'Yes';
    else
      this.CustomerInsNomineeRelationHirer = 'No';
  }

  onSaveApplication() {
    if (this.CustomerInsNomineeRelationHirer == null || this.CustomerInsNomineeRelationHirer == '') {
      alert('Please Check Relation With Hirer');
    }
    if (this.AssetInsuranceEndorsed == null || this.AssetInsuranceEndorsed == '') {
      alert('Please Check Insurance Endorsed');
    }
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let data = {
      Int_Id: this.IntId,
      IsActive: 0,
      ModifyBy: localUser.userId,
      LoanId: this.loanSummary.LoanId,
      AssetInsuranceCompany: this.AssetInsuranceCompany,
      AssetInsuranceCompanyBranch: this.AssetInsuranceCompanyBranch,
      AssetAgentName: this.AssetAgentName,
      AssetInsuranceDate: this.AssetInsuranceDate,
      AssetRenewalDate: this.AssetRenewalDate,
      AssetCoverNoteNo: this.AssetCoverNoteNo,
      AssetPolicyType: this.AssetPolicyType, AssetIDVAmount: this.AssetIDVAmount,
      AssetPremiumAmount: this.AssetPremiumAmount,
      AssetNomineeName: this.AssetNomineeName,
      AssetNomineeRelation: this.AssetNomineeRelationwithHirer,
      AssetPremiumAmountDeducted: this.AssetPremiumAmountLoan,

      CustomerInsHirerLifeInsurance: this.CustomerInsHirerLifeInsCmny,
      CustomerInsAssetInsuranceCompany: this.CustomerInsAssetInsuranceCompanyBranch,
      CustomerInsAgentName: this.CustomerInsAgentName,
      CustomerInsInsuranceDate: this.CustomerInsInsuranceDate,
      CustomerInsExpireDate: this.CustomerInsExpireDate,
      CustomerInsPolicyNo: this.CustomerInsPolicyNo,
      CustomerInsPolicyType: this.CustomerInsPolicyType,
      CustomerInsSumAssured: this.CustomerInsSumAssured,
      CustomerInsPremiumAmount: this.CustomerInsPremiumAmount,
      CustomerInsPremiumNextDueDate: this.CustomerInsPremiumNextDueDate,
      CustomerInsNomineeName: this.CustomerInsNomineeName,
      CustomerInsPremiumAmountDeducted: this.CustomerInsPremiumAmountDeducted,
      CustomerInsNomineeRelationHirer: this.CustomerInsNomineeRelationHirer,
      AssetInsuranceEndorsed: this.AssetInsuranceEndorsed
    }
    console.log('data', data);
    this.LmsService.SaveInsurance(data).subscribe((res: any) => {
      this.loading = false;
      this.get_InsuranceInfo();
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }

    })
  }
  getInsuranceBranch(event: any) {
    let Company_Id;
    if (this.Type == "Assets") {
      Company_Id = this.AssetInsuranceCompany;
    }
    if (this.Type == "Customer") {
      Company_Id = this.CustomerInsHirerLifeInsCmny;
    }
    this.LmsService.GetInsuranceBranchList({ Insurance_Company_Id: Company_Id }).subscribe((result) => {
      this.Insurance_Branch_List = JSON.parse(JSON.stringify(result));
      console.log(result);
    });
  }
  getInsuranceCompany() {
    this.LmsService.GetInsuranceCompanyList().subscribe((result) => {
      this.Insurance_Company_List = JSON.parse(JSON.stringify(result));
      console.log(result);
    });
  }
  getCustomerPolicyType() {
    this.LmsService.LMS_Commaon_Master({
      Commands: 'Select', Type: 'CUSTOMER POLICY TYPE'
    }).subscribe((res: any) => {
      console.log(res);
      this.PolicyTypeCustomerOption = res;
    });

  }
  getAssetsPolicyType() {
    this.LmsService.LMS_Commaon_Master({
      Commands: 'Select', Type: 'ASSETS POLICY TYPE'
    }).subscribe((res: any) => {
      console.log(res);
      this.PolicyTypeAssetsOption = res;
    });

  }
  getRelationList() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'RELATION' }).subscribe((res: any) => {
      console.log(res);
      this.RelationList = res;
    });

  }
  onNext() {
    console.log("this.dataCSource", this.dataCSource);
    // if (this.dataCSource != undefined && this.dataCSource.filteredData.length > 0) {
    //   $("#InsuranceInfo").removeClass("btn btn-orange btn-sm");
    //   $("#InsuranceInfo").addClass('btn btn-success btn-sm');
    // }
    if ((this.dataCSource == undefined || this.dataCSource.filteredData.length == 0) && this.loanSummary.IsInsurance) {

      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Insurance detail.", ...this.configSuccess });

    }
    else {
      this.LmsService.SaveNext_Loan({
        Loan_Id: this.loanSummary.LoanId, CommandName: 'Insurance', isStatus: 1
      }).subscribe((response) => {

        // $("#AmortizationInfo").removeClass("tab");
        // let element: HTMLElement = document.getElementById("AmortizationInfo") as HTMLElement;
        // element.click();
        this.action.emit("next")
      });
    }
  }

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
    this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  notDecimal(event) {
    if (event.key === '.') { 
      event.preventDefault(); 
    }
  }
}
