<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #fdf="ngForm"
    (ngSubmit)="OnSaveBranchEmployeeProcessModel()"
    novalidate
  >
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-3">
        <span> Select Branch </span>
        <select
          name="FilterBranchId"
          id="FilterBranchId"
          [(ngModel)]="FilterBranchId"
          required
          #refFilterBranchId="ngModel"
          [ngClass]="{
            'is-invalid': fdf.submitted && refFilterBranchId.invalid,
            'alert-warning':
              refFilterBranchId.invalid &&
              (refFilterBranchId.dirty ||
                refFilterBranchId.touched ||
                refFilterBranchId.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option
            *ngFor="let filterBranchList of FilterBranchList"
            [value]="filterBranchList.BranchId"
          >
            {{ filterBranchList.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <span> Select Product </span>
        <select
          name="FilterProductId"
          id="FilterProductId"
          [(ngModel)]="ProductId"
          required
          #refFilterProductId="ngModel"
          [ngClass]="{
            'is-invalid': fdf.submitted && refFilterProductId.invalid,
            'alert-warning':
              refFilterProductId.invalid &&
              (refFilterProductId.dirty ||
                refFilterProductId.touched ||
                refFilterProductId.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Product</option>
          <option
            *ngFor="let Product of productList"
            [value]="Product.ProductId"
          >
            {{ Product.Product }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="getBranchEmployeeProcessList()"
          [disabled]="!fdf.form.valid"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="AddBranchEmployeeProcess()"
          [disabled]="!fdf.form.valid"
          class="mt-3 btn font-size-12 button-btn"
        >
          Add+
        </button>
      </div>
    </div>
  </form>

  <div
    class="formborder mt-3"
    style="
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 8px;
      overflow-x: scroll;
    "
    *ngIf="showResults"
  >
    <div class="cards-container" style="display: flex; gap: 8px">
      <div *ngFor="let card of cardsData" class="card" style="width: 351px">
        <div
          class="card-header"
          style="
            display: flex;
            background-color: #0e5c81;
            color: white;
            justify-content: space-between;
            align-items: center;
            border-radius: 7px;
          "
        >
          <span>Branch Product Process</span>

          <span
            ><i
              style="color: #fff !important; cursor: pointer; font-size: 18px"
              class="fas fa-lg fa-edit margin-right-2xs"
              aria-label="true"
              (click)="AddBranchEmployeeProcess()"
            ></i
          ></span>

          <span>
            <i
              class="fa fa-trash style-delete"
              style="cursor: pointer; font-size: 18px"
              aria-label="true"
            ></i>
          </span>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="info">
            <span>From Amount:</span> {{ card.fromAmount }}
          </div>
          <div class="info"><span>To Amount:</span> {{ card.toAmount }}</div>
        </div>

        <div class="info">
          <table>
            <tr>
              <th>Process Name</th>
              <th>Employee Name</th>
            </tr>

            <tr
              *ngFor="let processAndEmployee of card.processAndEmployee"
              style="border: 1px solid black"
            >
              <td>{{ processAndEmployee.processName }}</td>
              <td>{{ processAndEmployee.employeeName }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="BranchEmployeeProcessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ff="ngForm" novalidate>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ isEditMode ? "Edit" : "Add" }} Branch Product Process
          </h6>
          <button
            type="button"
            (click)="OnCloseBranchEmployeeProcess()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div
            class="row m-0 font-size-12 align-items-center d-flex flex-column"
          >
            <div class="col-md-12">
              <span class="required-lable">From Amount</span>

              <input
                required
                type="number"
                #refFromAmount="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refFromAmount.invalid,
                  'alert-warning':
                    refFromAmount.invalid &&
                    (refFromAmount.dirty ||
                      refFromAmount.touched ||
                      refFromAmount.untouched)
                }"
                [(ngModel)]="FromAmount"
                placeholder="From Amount"
                disabled
                name="FromAmount"
                id="FromAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12">
              <span class="required-lable">To Amount</span>

              <input
                required
                type="number"
                #refToAmount="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refToAmount.invalid,
                  'alert-warning':
                    refToAmount.invalid &&
                    (refToAmount.dirty ||
                      refToAmount.touched ||
                      refToAmount.untouched)
                }"
                [(ngModel)]="ToAmount"
                placeholder="To Amount"
                name="ToAmount"
                id="ToAmount"
                class="form-control input-text-css"
              />
              <div
                *ngIf="
                  FromAmount !== undefined &&
                  ToAmount !== undefined &&
                  ToAmount !== null &&
                  !validateAmounts()
                "
                class="alert alert-danger mt-1"
              >
                To Amount must be greater than From Amount.
              </div>
            </div>
            <div class="table-responsive mt-2" [hidden]="!dataSource">
              <table mat-table [dataSource]="dataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="Process">
                  <th mat-header-cell *matHeaderCellDef>Process</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Process }}
                  </td>
                </ng-container>

                <!-- Custom Multi-Select Column -->
                <ng-container matColumnDef="Employee">
                  <th mat-header-cell *matHeaderCellDef>Employee</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <mat-form-field>
                      <mat-select
                        name="SelectionEmps{{ i }}"
                        id="SelectionEmps{{ i }}"
                        multiple
                        (selectionChange)="
                          onSelectionChange($event.value, element)
                        "
                      >
                        <mat-option
                          *ngFor="let option of element.EmployeeProcessList"
                          [value]="option.EmpId"
                          >{{ option.Emp_Name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </td>
                </ng-container>

                <!-- Set up Column Definitions -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnSaveBranchEmployeeProcessModel()"
            class="mt-3 btn font-size-12 button-btn"
            [disabled]="!ff.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
