<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>


  <form #f="ngForm" novalidate (keydown.enter)="SaveSub()">
    <div class="row m-0 mt-2 col-md-12 p-0">
      <div class="col-md-3">
        <span>Subscription Date</span>
        <!--<div class="datepicker_feild">
    <input  [disabled]="true" dateConvert placeholder="DD/MM/YYYY" name="subscriptionDate"
      id="subscriptionDate" #refsubscriptionDate="ngModel" [(ngModel)]="subscriptionDate" required
      [ngClass]="{ 'is-invalid': f.submitted && refsubscriptionDate.invalid, 'alert-warning': refsubscriptionDate.invalid  && (refsubscriptionDate.dirty || refsubscriptionDate.touched || refsubscriptionDate.untouched) }"
      class="form-control input-text-css" >-->
        <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker> -->
        <!--</div>-->

        <div class="datepicker_feild">
          <input [matDatepicker]="picker1" [disabled]="true"  dateConvert placeholder="DD/MM/YYYY"
                 name="subscriptionDate"    id="subscriptionDate" 
                 [(ngModel)]="subscriptionDate" 
                 class="form-control input-text-css">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
        <div class="col-md-3">
          <span class="required-lable">Select Month</span>
          <input  type="number"  #refsMonth="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && refsMonth.invalid, 'alert-warning': refsMonth.invalid  && (refsMonth.dirty || refsMonth.touched || refsMonth.untouched) }"
            [(ngModel)]="sMonth" placeholder="Select Month" name="sMonth" id="sMonth"
            class="form-control input-text-css" (input)="updateValue($event.target.value)" (input)="restrictToTwoDigit()"/>
        </div>
        <div class="col-md-3">
          <span>Select Date</span>
          <div class="datepicker_feild">
            <input  [matDatepicker]="picker" dateConvert placeholder="DD/MM/YYYY" name="Sub_Date" id="Sub_Date"
              #refSub_Date="ngModel" [(ngModel)]="Sub_Date" required (dateChange)="setSubDate()"
              [ngClass]="{ 'is-invalid': f.submitted && refSub_Date.invalid, 'alert-warning': refSub_Date.invalid  && (refSub_Date.dirty || refSub_Date.touched || refSub_Date.untouched) }"
              class="form-control input-text-css" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-3 ">
          <button type="button" (click)="SaveSub()" class="font-size-12 button-btn" data-dismiss="modal">
            Save
          </button>
        </div>

      
    </div>
  </form>
</div>
