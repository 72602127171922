<a href="javascript:void(0)" (click)="AddNewLegal()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in"
  id="AddLegalCase{{ dindex }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #df="ngForm" (ngSubmit)="OnSaveLegalCase()" novalidate>
    <div class="modal-dialog modal-xl" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Legal Case
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <div class="modal-body text-left">
          <ng-container *ngIf="IsLoanSearch">
            <form
              #f="ngForm"
              (keydown.enter)="LoanList()"
              novalidate
              class="row m-0 col-md-12 p-0 formborder"
            >
              <div class="row m-0 col-md-12 p-0">
                <div class="col-md-2">
                  <span>Loan No.</span>
                  <input
                    name="LoanNo"
                    [(ngModel)]="Customer.LoanNo"
                    id="LoanNo"
                    placeholder="Loan No."
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Application No.</span>
                  <input
                    name="ApplicationNo"
                    [(ngModel)]="Customer.ApplicationNo"
                    id="ApplicationNo"
                    placeholder="Application No."
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Customer Name</span>
                  <input
                    name="CustomerName"
                    [(ngModel)]="Customer.CustomerName"
                    id="CustomerName"
                    placeholder="Customer Name"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Customer Phone</span>
                  <input
                    name="CustomerPhone"
                    [(ngModel)]="Customer.CustomerPhone"
                    id="CustomerPhone"
                    placeholder="Customer Phone"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>PAN Card</span>
                  <input
                    name="PANCard"
                    [(ngModel)]="Customer.PANCard"
                    id="PANCard"
                    placeholder="PAN Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Passport No.</span>
                  <input
                    name="Passport"
                    [(ngModel)]="Customer.Passport"
                    id="Passport"
                    placeholder="Passport No."
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-2">
                  <span>Voter ID Card</span>
                  <input
                    name="VoterID"
                    [(ngModel)]="Customer.VoterID"
                    id="VoterID"
                    placeholder="Voter ID Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Driving License</span>
                  <input
                    name="DrivingLicence"
                    [(ngModel)]="Customer.DrivingLicence"
                    id="DL"
                    placeholder="Driving License"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Aadhaar Card</span>
                  <input
                    name="Aadhaar"
                    [(ngModel)]="Customer.Aadhaar"
                    id="Aadhaar"
                    placeholder="Aadhaar Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Vehicle No</span>
                  <input
                    name="VehicleNo"
                    [(ngModel)]="Customer.VehicleNo"
                    id="VehicleNo"
                    placeholder="Vehicle No"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Date From</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="LoanDateFrom"
                      id="LoanDateFrom"
                      class="form-control input-text-css"
                      [(ngModel)]="Customer.LoanDateFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>

                  <!-- <input
                    name="LoanDateFrom"
                    [(ngModel)]="Customer.LoanDateFrom"
                    id="LoanDateFrom"
                    placeholder="Date From"
                    bsDatepicker
                    class="form-control input-text-css"
                  /> -->
                </div>
                <div class="col-md-2">
                  <span>Date To</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker2"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="LoanDateTo"
                      id="LoanDateTo"
                      #refVoucher_Date="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="Customer.LoanDateTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <!-- <input
                    name="LoanDateTo"
                    [(ngModel)]="Customer.LoanDateTo"
                    id="LoanDateTo"
                    placeholder="Date To"
                    bsDatepicker
                    class="form-control input-text-css"
                  /> -->
                </div>

                <div class="col-md-3">
                  <span>Close Case</span>
                  <select
                    name="CloseCase"
                    id="CloseCase"
                    class="form-control input-text-css"
                    [(ngModel)]="Customer.CloseCase"
                  >
                    <option value="">Select Close Case</option>
                    <option value="1">Close Case</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <span>Select Type</span>
                  <select
                    name="SelectType"
                    id="SelectType"
                    class="form-control input-text-css"
                    [(ngModel)]="SelectType"
                    (change)="SearchChange()"
                  >
                    <option value="">Select Type</option>
                    <option value="Branch">Branch</option>
                    <option value="CollArea">Collection Area</option>
                    <option value="CollSubArea">Collection Sub Area</option>
                    <option value="CollExecutive">Collection Executive</option>
                    <option value="SalesExecutive">Sales Executive</option>
                    <option value="EngineNo">Engine No.</option>
                    <option value="ChassisNo">Chassis No.</option>
                    <option value="LoanAccCatg">Loan A/C Category</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div *ngIf="SelectType == 'Branch'">
                    <span> Branch</span>
                    <select
                      name="BranchId"
                      id="BranchId"
                      #refSelectGender="ngModel"
                      [(ngModel)]="Customer.BranchId"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Branch</option>
                      <option
                        *ngFor="let branch of BranchesList"
                        [value]="branch.BranchId"
                      >
                        {{ branch.Branch_Name }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'EngineNo'">
                    <span>Engine No.</span>
                    <input
                      name="EngineNo"
                      [(ngModel)]="Customer.EngineNo"
                      id="EngineNo"
                      placeholder="Engine No."
                      class="form-control input-text-css"
                    />
                  </div>

                  <div *ngIf="SelectType == 'ChassisNo'">
                    <span>Chassis No.</span>
                    <input
                      name="ChassisNo"
                      [(ngModel)]="Customer.ChassisNo"
                      id="ChassisNo"
                      placeholder="Chassis No."
                      class="form-control input-text-css"
                    />
                  </div>

                  <div *ngIf="SelectType == 'CollArea'">
                    <span> Collection Area</span>
                    <select
                      name="Area"
                      id="Area"
                      [(ngModel)]="Customer.Area"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection Area</option>
                      <ng-container *ngFor="let area of areaData">
                        <option
                          *ngIf="area.Type.trim() == 'Collection Area'"
                          [value]="area.Int_Id"
                        >
                          {{ area.Area_Name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'CollSubArea'">
                    <span> Collection Sub Area</span>
                    <select
                      name="SubArea"
                      id="SubArea"
                      [(ngModel)]="Customer.SubArea"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection SubArea</option>
                      <option
                        *ngFor="let subarea of subAreaData"
                        [value]="subarea.Int_Id"
                      >
                        {{ subarea.Sub_Area_Name }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'SalesExecutive'">
                    <span> Sales Executive</span>
                    <select
                      name="SalesEx"
                      id="SalesEx"
                      [(ngModel)]="Customer.SalesEx"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Sales Executive</option>
                      <option
                        *ngFor="let SalesExec of SalesExecutiveData"
                        [value]="SalesExec.EmpId"
                      >
                        {{ SalesExec.EmpName }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'CollExecutive'">
                    <span> Collection Executive</span>
                    <select
                      name="CollectionEx"
                      id="CollectionEx"
                      [(ngModel)]="Customer.CollectionEx"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection Executive</option>
                      <option
                        *ngFor="let CollExec of CollectionExecutiveData"
                        [value]="CollExec.EmpId"
                      >
                        {{ CollExec.Emp_FirstName }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'LoanAccCatg'">
                    <span>Accounting Category </span>
                    <select
                      name="LoanAccountingCatId"
                      id="LoanAccountingCatId"
                      [(ngModel)]="Customer.LoanAccountingCatId"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Accounting Category</option>
                      <option
                        *ngFor="let AcCat of AccCatDropdown"
                        [value]="AcCat.Loan_CategoryId"
                      >
                        {{ AcCat.Loan_category }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 text-right">
                  <button
                    type="button"
                    (click)="LoanList()"
                    class="mt-3 btn font-size-12 button-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>

            <div class="mt-2" *ngIf="dataSourceLoan">
              <div class="">
                <div class="table-responsive">
                  <mat-table
                    [dataSource]="dataSourceLoan"
                    matSort
                    matTableExporter
                    #exporter="matTableExporter"
                    [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
                    style="max-width: 100%; overflow: auto"
                  >
                    <ng-container matColumnDef="LoanId">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        style="max-width: 50px"
                        *matHeaderCellDef
                        >#
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                        style="max-width: 50px; max-height: 5px"
                      >
                        {{
                          paginatorRef.pageIndex * paginatorRef.pageSize +
                            (i + 1)
                        }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanAcNo">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Loan No</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanAcNo }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Application_No">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Application No</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Application_No }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Customer">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Customer</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Customer }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Branch">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Branch
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Branch }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Product">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Product</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Product }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanAMount">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Amount</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanAMount }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanCreatedOn">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Date
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanCreatedOn }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Assign">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        style="max-width: 60px"
                        *matHeaderCellDef
                        >Action
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row"
                        class="grid-cell j-c-center"
                        style="max-width: 60px"
                      >
                        <i
                          class="fa fa-arrow-circle-right arrow-circle action-btn"
                          (click)="goToDetailLoan(row)"
                          aria-label="true"
                        ></i>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      class="sticky-footer"
                      *matHeaderRowDef="displayedColumnsLoan; sticky: true"
                    >
                    </mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumnsLoan"
                    ></mat-row>
                  </mat-table>
                  <div class="row m-0 align-items-center">
                    <div class="col-md-12">
                      <mat-paginator
                        #paginatorRef
                        [pageSizeOptions]="[20, 50]"
                        showFirstLastButtons
                      >
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="IsLegalCase">
            <div class="row m-0" *ngIf="LoanSummary">
              <div class="col-md-4 p-1">
                <B>Loan No:</B> {{ LoanSummary.LoanAcNo }}
              </div>
              <div class="col-md-4 p-1">
                <B>Loan Product:</B> {{ LoanSummary.Product }}
              </div>
              <div class="col-md-4 p-1">
                <B>Branch:</B> {{ LoanSummary.Branch }}
              </div>
              <div class="col-md-12 p-1"><hr /></div>
            </div>
            <!-- <div class="row m-0">
                    <div class="col-md-3 p-1">
                        <span class="required-lable">Customer</span>
                        <input type="search" class="form-control input-text-css" (keyup.enter)="OnSelectCustomer(CaseModel)" (change)="OnSelectCustomer(CaseModel)"
                            [(ngModel)]="CaseModel.Customer" id="CustomerId" name="CustomerId" list="dynmicCustomer" autocomplete="off"
                            required #refCustomerId="ngModel" placeholder="Select Customer"
                            [ngClass]="{ 'is-invalid': df.submitted && refCustomerId.invalid, 'alert-warning': refCustomerId.invalid  && (refCustomerId.dirty || refCustomerId.touched || refCustomerId.untouched)}" />
                        <datalist id="dynmicCustomer">
                            <option *ngFor="let item of CustomerDropdown" [value]="item.Customer" title="item.Customer"> </option>
                        </datalist>
                    </div>
                </div> -->

            <div class="row m-0 mb-2">
              <div class="col-md-12 p-1">
                <div class="table-responsive" [hidden]="!dataCustomerSource">
                  <mat-table [dataSource]="dataCustomerSource">
                    <ng-container matColumnDef="Action">
                      <mat-header-cell
                        class="grid-header j-c-center"
                        *matHeaderCellDef
                        >Select</mat-header-cell
                      >
                      <mat-cell
                        *matCellDef="let row"
                        class="grid-cell j-c-center"
                      >
                        <mat-checkbox
                          [value]="row.CustomerId"
                          [checked]="CaseModel.CustomerId == row.CustomerId"
                          (change)="onCustomerChange($event, row)"
                        ></mat-checkbox>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Number">
                      <mat-header-cell
                        class="grid-header"
                        style="max-width: 50px"
                        *matHeaderCellDef
                        >#</mat-header-cell
                      >
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                        style="max-width: 50px"
                      >
                        {{ i + 1 }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Customer">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Customer
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                      >
                        <app-lms-customer-view-model
                          [customerId]="row.CustomerId"
                          [index]="i"
                          >{{ row.Customer }}
                        </app-lms-customer-view-model>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="CustomerType">
                      <mat-header-cell class="grid-header" *matHeaderCellDef
                        >Customer Type</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">{{
                        row.CustomerType == "Hirer"
                          ? "Borrower"
                          : row.CustomerType
                      }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="GenderAge">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Gender Age
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.GenderAge }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="PhoneNo">
                      <mat-header-cell class="grid-header" *matHeaderCellDef
                        >Phone No</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        <div *ngIf="currentUser.IsMasking">
                          {{ row.PhoneNo | mask }}
                        </div>

                        <div *ngIf="!currentUser.IsMasking">
                          {{ row.PhoneNo }}
                        </div>

                        <i
                          *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                          class="fa fa-check-circle ml-2"
                          style="color: green"
                        ></i>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Relation_With_Hirer">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Relation With Borrower</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Relation_With_Hirer }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ExistingCustomer">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Existing
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.ExistingCustomer }}
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedCustomerColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedCustomerColumns"
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>

            <div class="row m-0 formborder" *ngIf="CaseModel.Customer">
              <div class="col-md-12 p-1"><hr /></div>

              <div class="col-md-3 p-1">
                <span class="required-lable">Court</span>
                <select
                  name="CourtName"
                  id="CourtName"
                  #refCourtName="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refCourtName.invalid,
                    'alert-warning':
                      refCourtName.invalid &&
                      (refCourtName.dirty ||
                        refCourtName.touched ||
                        refCourtName.untouched)
                  }"
                  [(ngModel)]="CaseModel.CourtName"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Court</option>
                  <option value="Supreme Court of India">
                    Supreme Court of India
                  </option>
                  <option value="High Court">High Court</option>
                  <option value="District Court">District Court</option>
                  <option value="Commissions (Consumer Forum)">
                    Commissions (Consumer Forum)
                  </option>
                  <option value="Tribunals and Authorities">
                    Tribunals and Authorities
                  </option>
                  <option value="Revenue Court">Revenue Court</option>
                  <option value="Commissionerate">Commissionerate</option>
                  <option value="Department">Department</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">State</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectState(CaseModel)"
                  (change)="OnSelectState(CaseModel)"
                  [(ngModel)]="CaseModel.State_Name"
                  id="StateId"
                  name="StateId"
                  list="dynmicState"
                  autocomplete="off"
                  required
                  #refStateId="ngModel"
                  placeholder="Select State"
                  [ngClass]="{
                    'is-invalid': df.submitted && refStateId.invalid,
                    'alert-warning':
                      refStateId.invalid &&
                      (refStateId.dirty ||
                        refStateId.touched ||
                        refStateId.untouched)
                  }"
                />
                <datalist id="dynmicState">
                  <option
                    *ngFor="let item of StateDropdown"
                    [value]="item.State_Name"
                    title="item.State_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">District</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectDistrict(CaseModel)"
                  (change)="OnSelectDistrict(CaseModel)"
                  [(ngModel)]="CaseModel.District_Name"
                  id="DistrictId"
                  name="DistrictId"
                  list="dynmicDistrict"
                  autocomplete="off"
                  required
                  #refDistrictId="ngModel"
                  placeholder="Select District"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDistrictId.invalid,
                    'alert-warning':
                      refDistrictId.invalid &&
                      (refDistrictId.dirty ||
                        refDistrictId.touched ||
                        refDistrictId.untouched)
                  }"
                />
                <datalist id="dynmicDistrict">
                  <option
                    *ngFor="let item of DistrictDropDown"
                    [value]="item.District_Name"
                    title="item.District_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Court Name</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectCourt(CaseModel)"
                  (change)="OnSelectCourt(CaseModel)"
                  [(ngModel)]="CaseModel.Court_Name"
                  id="Court_EstablishmentId"
                  name="Court_EstablishmentId"
                  list="dynmicCourt"
                  autocomplete="off"
                  required
                  #refCourt_EstablishmentId="ngModel"
                  placeholder="Select Court"
                  [ngClass]="{
                    'is-invalid':
                      df.submitted && refCourt_EstablishmentId.invalid,
                    'alert-warning':
                      refCourt_EstablishmentId.invalid &&
                      (refCourt_EstablishmentId.dirty ||
                        refCourt_EstablishmentId.touched ||
                        refCourt_EstablishmentId.untouched)
                  }"
                />
                <datalist id="dynmicCourt">
                  <option
                    *ngFor="let item of CourtNameDropdown"
                    [value]="item.Court_Name"
                    title="item.Court_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Nature</span>
                <select
                  name="Nature"
                  id="Nature"
                  #refNature="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refNature.invalid,
                    'alert-warning':
                      refNature.invalid &&
                      (refNature.dirty ||
                        refNature.touched ||
                        refNature.untouched)
                  }"
                  [(ngModel)]="CaseModel.Nature"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Nature</option>
                  <option value="CIVIL">CIVIL</option>
                  <option value="Criminal">Criminal</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">ACT</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectAct(CaseModel)"
                  (change)="OnSelectAct(CaseModel)"
                  [(ngModel)]="CaseModel.Act_Name"
                  id="UnderACT_Id"
                  name="UnderACT_Id"
                  list="dynmicAct"
                  autocomplete="off"
                  required
                  #refUnderACT_Id="ngModel"
                  placeholder="Select Act"
                  [ngClass]="{
                    'is-invalid': df.submitted && refUnderACT_Id.invalid,
                    'alert-warning':
                      refUnderACT_Id.invalid &&
                      (refUnderACT_Id.dirty ||
                        refUnderACT_Id.touched ||
                        refUnderACT_Id.untouched)
                  }"
                />
                <datalist id="dynmicAct">
                  <option
                    *ngFor="let item of ActData"
                    [value]="item.Short_Name"
                    title="item.Short_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span>Section</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectSection(CaseModel)"
                  (change)="OnSelectSection(CaseModel)"
                  [(ngModel)]="CaseModel.Section_Name"
                  id="UnderSectionId"
                  name="UnderSectionId"
                  list="dynmicSection"
                  autocomplete="off"
                  #refUnderSectionId="ngModel"
                  placeholder="Select Section"
                />
                <datalist id="dynmicSection">
                  <option
                    *ngFor="let item of SectionData"
                    [value]="item.Short_Name"
                    title="item.Short_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span>Sub Section</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectSubSection(CaseModel)"
                  (change)="OnSelectSubSection(CaseModel)"
                  [(ngModel)]="CaseModel.SubSection_Name"
                  id="UnderSub_SectionId"
                  name="UnderSub_SectionId"
                  list="dynmicSubSection"
                  autocomplete="off"
                  #refUnderSub_SectionId="ngModel"
                  placeholder="Select Sub Section"
                />
                <datalist id="dynmicSubSection">
                  <option
                    *ngFor="let item of SubSectionData"
                    [value]="item.Short_Name"
                    title="item.Short_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Case Filing Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker3"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Date_Of_Case_Filing"
                    [(ngModel)]="CaseModel.Date_Of_Case_Filing"
                    id="Date_Of_Case_Filing"
                    required
                    class="form-control input-text-css"
                    #refDate_Of_Case_Filing="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        df.submitted && refDate_Of_Case_Filing.invalid,
                      'alert-warning':
                        refDate_Of_Case_Filing.invalid &&
                        (refDate_Of_Case_Filing.dirty ||
                          refDate_Of_Case_Filing.touched ||
                          refDate_Of_Case_Filing.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Case Filed By</span>
                <input
                  type="search"
                  class="form-control input-text-css"
                  (keyup.enter)="OnSelectCaseFiledBy(CaseModel)"
                  (change)="OnSelectCaseFiledBy(CaseModel)"
                  [(ngModel)]="CaseModel.Case_Filed_By_Name"
                  id="Case_Filed_By"
                  name="Case_Filed_By"
                  list="dynmicCaseFilledBy"
                  autocomplete="off"
                  required
                  #refCase_Filed_By="ngModel"
                  placeholder="Case Filled By"
                  [ngClass]="{
                    'is-invalid': df.submitted && refCase_Filed_By.invalid,
                    'alert-warning':
                      refCase_Filed_By.invalid &&
                      (refCase_Filed_By.dirty ||
                        refCase_Filed_By.touched ||
                        refCase_Filed_By.untouched)
                  }"
                />
                <datalist id="dynmicCaseFilledBy">
                  <option
                    *ngFor="let item of AdvocateDropdown"
                    [value]="item.Partner_Name"
                    title="item.Partner_Name"
                  ></option>
                </datalist>
              </div>
              <div class="col-md-3 p-1">
                <span>Demand Notice Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker4"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Demand_Notice_Date"
                    [(ngModel)]="CaseModel.Demand_Notice_Date"
                    id="Demand_Notice_Date"
                    class="form-control input-text-css"
                    #refDemand_Notice_Date="ngModel"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
                <!-- <input
                  name="Demand_Notice_Date"
                  [(ngModel)]="CaseModel.Demand_Notice_Date"
                  id="Demand_Notice_Date"
                  placeholder="Demand Notice Date"
                  class="form-control input-text-css"
                  bsDatepicker
                  #refDemand_Notice_Date="ngModel"
                /> -->
              </div>
              <div class="col-md-3 p-1">
                <span>Demand Notice Doc</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.Demand_Notice_Doc"
                    (click)="docFile.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.Demand_Notice_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.Demand_Notice_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Demand_Notice_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile(CaseModel, docFile)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener(CaseModel, docFile.files)"
                  style="display: none"
                  [disabled]="!CaseModel.Demand_Notice_Date"
                />
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Court File No</span>
                <input
                  type="text"
                  name="Court_File_No"
                  [(ngModel)]="CaseModel.Court_File_No"
                  id="Court_File_No"
                  required
                  placeholder="Court File No"
                  class="form-control input-text-css"
                  #refCourt_File_No="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refCourt_File_No.invalid,
                    'alert-warning':
                      refCourt_File_No.invalid &&
                      (refCourt_File_No.dirty ||
                        refCourt_File_No.touched ||
                        refCourt_File_No.untouched)
                  }"
                />
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Power Of Attorney Type</span>
                <select
                  name="Power_Of_Attorney_Type"
                  id="Power_Of_Attorney_Type"
                  #refPower_Of_Attorney_Type="ngModel"
                  [(ngModel)]="CaseModel.Power_Of_Attorney_Type"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid':
                      df.submitted && refPower_Of_Attorney_Type.invalid,
                    'alert-warning':
                      refPower_Of_Attorney_Type.invalid &&
                      (refPower_Of_Attorney_Type.dirty ||
                        refPower_Of_Attorney_Type.touched ||
                        refPower_Of_Attorney_Type.untouched)
                  }"
                >
                  <option value="">Select Power Of Attorney Type</option>
                  <option value="Employee">Employee</option>
                  <option value="Advocate">Advocate</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <ng-container
                  *ngIf="CaseModel.Power_Of_Attorney_Type == 'Employee'"
                >
                  <span class="required-lable">Power Of Attorney Name</span>
                  <input
                    type="search"
                    class="form-control input-text-css"
                    (keyup.enter)="
                      OnSelectPowerOfAttorney(CaseModel, 'Employee')
                    "
                    (change)="OnSelectPowerOfAttorney(CaseModel, 'Employee')"
                    [(ngModel)]="CaseModel.Power_Of_Attorney"
                    id="Power_Of_AttorneyId"
                    name="Power_Of_AttorneyId"
                    list="dynmicPowerOfAttorney"
                    autocomplete="off"
                    required
                    #refPower_Of_AttorneyId="ngModel"
                    placeholder="Power Of Attorney Name"
                    [ngClass]="{
                      'is-invalid':
                        df.submitted && refPower_Of_AttorneyId.invalid,
                      'alert-warning':
                        refPower_Of_AttorneyId.invalid &&
                        (refPower_Of_AttorneyId.dirty ||
                          refPower_Of_AttorneyId.touched ||
                          refPower_Of_AttorneyId.untouched)
                    }"
                  />
                  <datalist id="dynmicPowerOfAttorney">
                    <option
                      *ngFor="let item of EmployeeDropdown"
                      [value]="item.EmpName"
                      title="item.EmpName"
                    ></option>
                  </datalist>
                </ng-container>

                <ng-container
                  *ngIf="CaseModel.Power_Of_Attorney_Type == 'Advocate'"
                >
                  <span class="required-lable">Power Of Attorney Name</span>
                  <input
                    type="search"
                    class="form-control input-text-css"
                    (keyup.enter)="
                      OnSelectPowerOfAttorney(CaseModel, 'Advocate')
                    "
                    (change)="OnSelectPowerOfAttorney(CaseModel, 'Advocate')"
                    [(ngModel)]="CaseModel.Power_Of_Attorney"
                    id="Power_Of_AttorneyId"
                    name="Power_Of_AttorneyId"
                    list="dynmicPowerOfAttorney"
                    autocomplete="off"
                    required
                    #refPower_Of_AttorneyId="ngModel"
                    placeholder="Power Of Attorney Name"
                    [ngClass]="{
                      'is-invalid':
                        df.submitted && refPower_Of_AttorneyId.invalid,
                      'alert-warning':
                        refPower_Of_AttorneyId.invalid &&
                        (refPower_Of_AttorneyId.dirty ||
                          refPower_Of_AttorneyId.touched ||
                          refPower_Of_AttorneyId.untouched)
                    }"
                  />
                  <datalist id="dynmicPowerOfAttorney">
                    <option
                      *ngFor="let item of AdvocateDropdown"
                      [value]="item.Partner_Name"
                      title="item.Partner_Name"
                    ></option>
                  </datalist>
                </ng-container>
              </div>
              <div class="col-md-3 p-1">
                <span>Power Of Attorney Doc</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.Power_Of_Attorney_Doc"
                    (click)="docFile1.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.Power_Of_Attorney_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.Power_Of_Attorney_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Power_Of_Attorney_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile1(CaseModel, docFile1)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile1
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener1(CaseModel, docFile1.files)"
                  style="display: none"
                  [disabled]="!CaseModel.Power_Of_Attorney"
                />
              </div>
              <div class="col-md-3 p-1">
                <span>Customer Bank</span>
                <select
                  name="Customer_Bank_Id"
                  id="Customer_Bank_Id"
                  #refCustomer_Bank_Id="ngModel"
                  class="form-control input-text-css"
                  [(ngModel)]="CaseModel.Customer_Bank_Id"
                >
                  <option value="">Select Bank A/C</option>
                  <option
                    *ngFor="let row of CustomerBankDropdown"
                    [value]="row.Id"
                  >
                    {{ row.AccountNo }} ({{ row.BankName }})
                  </option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <span>Customer Cheque</span>
                <select
                  name="Customer_Cheque_Id"
                  id="Customer_Cheque_Id"
                  #refCustomer_Cheque_Id="ngModel"
                  class="form-control input-text-css"
                  [(ngModel)]="CaseModel.Customer_Cheque_Id"
                >
                  <option value="">Select Cheque</option>
                  <option
                    *ngFor="let row of CustomerChqDropdown"
                    [value]="row.Int_Id"
                  >
                    {{ row.ChFrom }} ({{ row.Bankname }}-{{ row.PdcType }})
                  </option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <span>Cheque Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker5"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Customer_Cheque_Date"
                    [(ngModel)]="CaseModel.Customer_Cheque_Date"
                    id="Customer_Cheque_Date"
                    class="form-control input-text-css"
                    #refCustomer_Cheque_Date="ngModel"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker5"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker5></mat-datepicker>
                </div>
                <!-- <input
                  name="Customer_Cheque_Date"
                  [(ngModel)]="CaseModel.Customer_Cheque_Date"
                  id="Customer_Cheque_Date"
                  placeholder="Cheque Date"
                  class="form-control input-text-css"
                  bsDatepicker
                  #refCustomer_Cheque_Date="ngModel"
                /> -->
              </div>
              <div class="col-md-2 p-1">
                <span>Cheque Amount</span>
                <input
                  type="text"
                  name="Customer_Cheque_Amount"
                  id="Customer_Cheque_Amount"
                  placeholder="Cheque Amount"
                  [(ngModel)]="CaseModel.Customer_Cheque_Amount"
                  numbersOnly
                  class="form-control input-text-css"
                  #refCustomer_Cheque_Amount="ngModel"
                />
              </div>
              <div class="col-md-2 p-1">
                <span>Cheque Image</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.Customer_Cheque_Doc"
                    (click)="docFile2.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.Customer_Cheque_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.Customer_Cheque_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Customer_Cheque_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile2(CaseModel, docFile2)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile2
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener2(CaseModel, docFile2.files)"
                  style="display: none"
                  [disabled]="!CaseModel.Customer_Cheque_Amount"
                />
              </div>
              <div class="col-md-3 p-1">
                <span>Cheque Bounce Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker6"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Customer_Bounce_Date"
                    [(ngModel)]="CaseModel.Customer_Bounce_Date"
                    id="Customer_Bounce_Date"
                    class="form-control input-text-css"
                    #refCustomer_Bounce_Date="ngModel"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker6"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker6></mat-datepicker>
                </div>
                <!-- <input
                  name="Customer_Bounce_Date"
                  [(ngModel)]="CaseModel.Customer_Bounce_Date"
                  id="Customer_Bounce_Date"
                  placeholder="Cheque Bounce Date"
                  class="form-control input-text-css"
                  bsDatepicker
                  #refCustomer_Bounce_Date="ngModel"
                /> -->
              </div>
              <div class="col-md-3 p-1">
                <span>Bounce Reason</span>
                <select
                  name="Customer_Bounce_Reason"
                  id="Customer_Bounce_Reason"
                  #refCustomer_Bounce_Reason="ngModel"
                  [(ngModel)]="CaseModel.Customer_Bounce_Reason"
                  class="form-control input-text-css"
                >
                  <option value="">Select Bounce Reason</option>
                  <option value="INSUFFICIENT FUNDS">INSUFFICIENT FUNDS</option>
                  <option value="PAYMENT STOPPED BY DRAWER">
                    PAYMENT STOPPED BY DRAWER
                  </option>
                  <option value="DRAWERS SIGN DIFFERS">
                    DRAWERS SIGN DIFFERS
                  </option>
                  <option value="ALTERATION DRAWER SIGN REQ">
                    ALTERATION DRAWER SIGN REQ.
                  </option>
                  <option value="DRAWER SIGN REQUIRED">
                    DRAWER SIGN REQUIRED
                  </option>
                  <option value="NO SUCH ACCOUNT">NO SUCH ACCOUNT</option>
                  <option value="ACCOUNT CLOSED">ACCOUNT CLOSED</option>
                  <option value="ACCOUNT BLOCKED">ACCOUNT BLOCKED</option>
                  <option value="NOT ARRANGE FOR">NOT ARRANGE FOR</option>
                  <option value="REFER TO DRAWER">REFER TO DRAWER</option>
                  <option value="ECS MANDATE NOT RECEIVED">
                    ECS MANDATE NOT RECEIVED
                  </option>
                  <option value="EXCEED ARRANGEMENT">EXCEED ARRANGEMENT</option>
                  <option value="PARTY DECEASED">PARTY DECEASED</option>
                  <option value="WORD DIFFERENCE">WORD DIFFERENCE</option>
                  <option value="PAYNAME DIFFERENCE">PAYNAME DIFFERENCE</option>
                  <option value="AMOUNT DIFFERENCE">AMOUNT DIFFERENCE</option>
                  <option value="SIGNATURE NOT ILLIGIBLE">
                    SIGNATURE NOT ILLIGIBLE
                  </option>
                  <option value="ALTRATIONS">ALTRATIONS</option>
                  <option value="INSTRUMENTS">INSTRUMENTS</option>
                  <option value="STAMP OF ACCOUNT REQUIRED">
                    STAMP OF ACCOUNT REQUIRED
                  </option>
                  <option value="CONNECTIVITY FAILURE">
                    CONNECTIVITY FAILURE
                  </option>
                  <option value="DORMENT ACCOUNT">DORMENT ACCOUNT</option>
                  <option value="MISE-A/C REACH MAX DR-LIMIT">
                    MISE-A/C REACH MAX DR-LIMIT
                  </option>
                  <option value="PYMT STOP UNDER COURT OREDER">
                    PYMT STOP UNDER COURT OREDER
                  </option>
                  <option value="ACCOUNT UNDER LITIGATION">
                    ACCOUNT UNDER LITIGATION
                  </option>
                  <option value="POSTDATED">POSTDATED</option>
                  <option value="OTHER REASON">OTHER REASON</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <span>Cheque Return Memo</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.Customer_Bounce_Doc"
                    (click)="docFile3.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.Customer_Bounce_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.Customer_Bounce_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Customer_Bounce_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile3(CaseModel, docFile3)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile3
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener3(CaseModel, docFile3.files)"
                  style="display: none"
                  [disabled]="!CaseModel.Customer_Bounce_Reason"
                />
              </div>
              <div class="col-md-3 p-1">
                <span>Closer Letter</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.Closer_Doc"
                    (click)="docFile4.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.Closer_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.Closer_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Closer_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile4(CaseModel, docFile4)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile4
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener4(CaseModel, docFile4.files)"
                  style="display: none"
                  [disabled]="!CaseModel.Customer_Bounce_Reason"
                />
              </div>

              <div class="col-md-3 p-1">
                <span>SOA Attachement</span>
                <p class="mt-1">
                  <i
                    class="fa fa-upload fileUpload"
                    *ngIf="!CaseModel.SOA_Doc"
                    (click)="docFile5.click()"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i>
                  <ng-container *ngIf="CaseModel.SOA_Doc">
                    <a
                      href="{{ docBaseUrl }}{{ CaseModel.CustomerId }}/{{
                        CaseModel.SOA_Doc
                      }}"
                      target="_blank"
                    >
                      <i
                        class="fa fa-file-pdf mr-2 fs-16"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <!-- {{CaseModel.Closer_Doc}} -->
                    <i
                      class="fa fa-times ml-2 fs-16"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFile5(CaseModel, docFile5)"
                    ></i>
                  </ng-container>
                </p>
                <input
                  #docFile5
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener5(CaseModel, docFile5.files)"
                  style="display: none"
                />
              </div>

              <div class="col-md-12 p-1">
                <span>Remark</span>
                <textarea
                  rows="1"
                  name="Remark"
                  id="Remark"
                  [(ngModel)]="CaseModel.Remark"
                  #refRemark="ngModel"
                  placeholder="Remark"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': df.submitted && refRemark.invalid,
                    'alert-warning':
                      refRemark.invalid &&
                      (refRemark.dirty ||
                        refRemark.touched ||
                        refRemark.untouched)
                  }"
                ></textarea>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer" *ngIf="CaseModel.Customer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnSaveLegalCase()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!df.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!--  -->
