<div *ngIf="assetData">
  <!--Product Type Vehicle-->

  <div style="display: flex; flex-direction: column"
       class="grid-container-main" *ngIf="assetData['Product_Category'] === 'Vehicle Loan'">
    <div class="grid-container"
         *ngIf="assetData['Product_Category'] === 'Vehicle Loan'">
      <div class="grid-item">
        <div class="grid-sub-item">Customer Name:</div>
        <div class="grid-sub-item-value">{{ assetData["CustomerName"] }}</div>
      </div>

      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Type :</div>
        <div class="grid-sub-item-value">{{ assetData["Vehicle_Type"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Vehicle SubType :</div>
        <div class="grid-sub-item-value">
          {{ assetData["Vehicle_SubType"] }}
        </div>
      </div>

      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Model :</div>
        <div class="grid-sub-item-value">
          {{ assetData["VehicleModel_Name"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Manufacturer :</div>
        <div class="grid-sub-item-value">
          {{ assetData["VehicleManufactureName"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Category :</div>
        <div class="grid-sub-item-value">
          {{ assetData["VehicleCategoryName"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Variant :</div>
        <div class="grid-sub-item-value">{{ assetData["Variant"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Registation No :</div>
        <div class="grid-sub-item-value">
          {{ assetData["VehicleRegistationNo"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Manufacturing Year :</div>
        <div class="grid-sub-item-value">{{ assetData["MefgYear"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Registation Date :</div>
        <div class="grid-sub-item-value">
          {{ assetData["RegistationDate"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">FuelType :</div>
        <div class="grid-sub-item-value">{{ assetData["FuelType"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Color :</div>
        <div class="grid-sub-item-value">{{ assetData["Color"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Registation Expiry Date :</div>
        <div class="grid-sub-item-value">
          {{ assetData["RegistationExpiryDate"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Engine No :</div>
        <div class="grid-sub-item-value">{{ assetData["EngineNo"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Chassis No :</div>
        <div class="grid-sub-item-value">{{ assetData["ChassisNo"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Vehicle Cost :</div>
        <div class="grid-sub-item-value">{{ assetData["VehicleCost"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Dealor Name :</div>
        <div class="grid-sub-item-value">{{ assetData["DealorName"] }}</div>
      </div>
    </div>

    <div *ngIf="assetData['Product_Category'] === 'Vehicle Loan'">
      <div class="row m-0 mt-2">
        <div class="col-md-12 p-1 mt-2 upload">
          <b> Upload Property Images </b>
          <input #docFile5
                 type="file"
                 [multiple]="false"
                 accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                 style="display: none" />
          <div class="row m-0 mt-2 align-items-center"
               *ngFor="let report of AssetsAttachment; let i = index">
            <div class="col-md-1 pl-0">{{ i + 1 }}</div>
            <div class="col-md-3">
              <input type="text"
                     placeholder="Title"
                     name="Title{{ i }}"
                     id="Title{{ i }}"
                     disabled
                     #refTitle="ngModel"
                     [(ngModel)]="report.Title"
                     class="form-control input-text-css" />
            </div>
            <div class="col-md-3">
              <p class="m-0 row">
                <ng-container *ngIf="report.Doc_Path">
                  <div>
                    <ng-container *ngIf="report.Doc_Path">
                      {{ report.Doc_Path }}
                    </ng-container>
                    <a href="{{ Doc_Path }}{{ ApplicationNumber }}/{{
                        report.Doc_Path
                      }}"
                       class="ml-3"
                       target="_blank"
                       *ngIf="report.Doc_Path">
                      <i class="fa fa-paperclip mr-2"
                         aria-hidden="true"
                         style="font-size: 18px"></i>
                    </a>
                  </div>
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Product type Property Info-->

  <div style="display: flex; flex-direction: column"
       class="grid-container-main" *ngIf="assetData['Product_Category'] === 'Property Loan'">
    <div class="grid-container"
         *ngIf="assetData['Product_Category'] === 'Property Loan'">
      <div class="grid-item">
        <div class="grid-sub-item">Customer Name:</div>
        <div class="grid-sub-item-value">{{ assetData["CustomerName"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Type of Property :</div>
        <div class="grid-sub-item-value">
          {{ assetData["TypeofProperty_Name"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Nature of Property :</div>
        <div class="grid-sub-item-value">
          {{ assetData["NatureofProperty_Name"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Ownership Type :</div>
        <div class="grid-sub-item-value">
          {{ assetData["OwnershipType_Name"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Ownership Document :</div>
        <div class="grid-sub-item-value">
          {{ assetData["OwnershipDocument_Name"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Unit of Measurement :</div>
        <div class="grid-sub-item-value">
          {{ assetData["UnitofMeasurement"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Address :</div>
        <div class="grid-sub-item-value">{{ assetData["Address"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">TotalArea :</div>
        <div class="grid-sub-item-value">{{ assetData["TotalArea"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Constructed Area :</div>
        <div class="grid-sub-item-value">
          {{ assetData["ConstructedArea"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Type of Mortgage :</div>
        <div class="grid-sub-item-value">{{ assetData["TypeofMortgage"] }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">Mortgage Singed By :</div>
        <div class="grid-sub-item-value">
          {{ assetData["MortgageSingedByName"] }}
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-sub-item">GeoLocation :</div>
        <div class="grid-sub-item-value">{{ assetData["GeoLocation"] }}</div>
      </div>
    </div>
    <div *ngIf="assetData['Product_Category'] === 'Property Loan'">
      <div class="row m-0 mt-2">
        <div class="col-md-12 p-1 mt-2 upload">
          <b> Upload Property Images </b>
          <input #docFile5
                 type="file"
                 [multiple]="false"
                 accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                 style="display: none" />
          <div class="row m-0 mt-2 align-items-center"
               *ngFor="let report of AssetsAttachment; let i = index">
            <div class="col-md-1 pl-0">{{ i + 1 }}</div>
            <div class="col-md-3">
              <input type="text"
                     placeholder="Title"
                     name="Title{{ i }}"
                     id="Title{{ i }}"
                     disabled
                     #refTitle="ngModel"
                     [(ngModel)]="report.Title"
                     class="form-control input-text-css" />
            </div>
            <div class="col-md-3">
              <p class="m-0 row">
                <ng-container *ngIf="report.Doc_Path">
                  <div>
                    <ng-container *ngIf="report.Doc_Path">
                      {{ report.Doc_Path }}
                    </ng-container>
                    <a href="{{ docBaseUrl }}{{
                        ApplicationNumber.Application_No
                      }}/{{ report.Doc_Path }}"
                       class="ml-3"
                       target="_blank"
                       *ngIf="report.Doc_Path">
                      <i class="fa fa-paperclip mr-2"
                         aria-hidden="true"
                         style="font-size: 18px"></i>
                    </a>
                  </div>
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div style="display: flex; flex-direction: column"
       class="grid-container-main" *ngIf="jsonDataForGold">
    <div class="table-responsive mt-4 mb-4" *ngIf="jsonDataForGold">
      <mat-table [dataSource]="jsonDataForGold">
        <ng-container matColumnDef="Id">
          <mat-header-cell class="grid-header"
                           style="max-width: 50px"
                           *matHeaderCellDef>
            #
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px">
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ItemName">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Item Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.ItemName
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ItemType">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Item Type</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.ItemType
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Qty">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Qty</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.Qty
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PurityCT">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Purity CT</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.PurityCT
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GrossWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Gross Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.GrossWt
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="StoneWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Stone Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.StoneWt
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="OtherWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Other Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.OtherWt
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NetWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Net Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.NetWt
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PureWt">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Pure Wt</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          ((row.NetWt * (row.PurityCT * fixrate24ct)) / 100).toFixed(2)
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValulationRate_PerCT">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Rate</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
          row.ValulationRate_PerCT
            }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedAssetItemColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedAssetItemColumns"></mat-row>
      </mat-table>

      <div class="row m-0 mt-4" *ngIf="assetTotal">
        <div class="col-md-3 p-1"><B>Total Qty:</B> {{ assetTotal.Qty }}</div>
        <div class="col-md-3 p-1">
          <B>Total Gross Wt:</B> {{ assetTotal.GrossWt }}
        </div>
        <div class="col-md-3 p-1">
          <B>Total Stone Wt:</B> {{ assetTotal.StoneWt }}
        </div>
        <div class="col-md-3 p-1">
          <B>Total Net Wt:</B> {{ assetTotal.NetWt }}
        </div>
      </div>
    </div>
    </div>
  </div>
