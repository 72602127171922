<section class="main-content-wrapper">
  <div *ngIf="LegalSummary">
    <div class="row m-0 col-md-12 mt-0">
      <div class="col-md-12 mb-1 alert alert-info">
        <div class="row">
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan No :</B> {{ LegalSummary.LoanAcNo }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan Date :</B> {{ LegalSummary.Loan_Date }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Application No :</B> {{ LegalSummary.Application_No }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Branch :</B> {{ LegalSummary.Branch_Name }}</span
            >
          </div>

          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan Amount :</B> {{ LegalSummary.LoanAmount }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Customer :</B> {{ LegalSummary.CustomerName }}</span
            >
          </div>
          <div class="col-md-6">
            <span class="fs-12"
              ><B>Product :</B> {{ LegalSummary.Product_Name }}</span
            >
          </div>

          <div class="col-md-3">
            <span class="fs-12"
              ><B>Case Filed By :</B> {{ LegalSummary.Case_Filed_By }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Case Filed Date :</B>
              {{ LegalSummary.Date_Of_Case_Filing }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row col-md-12 m-0 mt-0">
    <div class="col-md-12 p-0">
      <mat-tab-group
        class="loan_tab"
        [selectedIndex]="currentTab"
        (selectedTabChange)="onTabChange($event)"
      >
        <mat-tab>
          <ng-template mat-tab-label><span>Legal Info</span></ng-template>
          <ng-template matTabContent>
            <app-legal-case-info
              [LegalSummary]="LegalSummary"
              [LegalInfoModel]="LegalInfoModel"
              (action)="onChildAction($event)"
            ></app-legal-case-info>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label><span>Hearing Info</span></ng-template>
          <ng-template matTabContent>
            <app-legal-hearing-info
              [LegalSummary]="LegalSummary"
              (action)="onChildAction($event)"
            ></app-legal-hearing-info>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label><span>Documents</span></ng-template>
          <ng-template matTabContent>
            <app-legal-documents-info
              [LegalSummary]="LegalSummary"
              (action)="onChildAction($event)"
            ></app-legal-documents-info>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label
            ><span>Notice Attachment</span></ng-template
          >
          <ng-template matTabContent>
            <app-legal-notice-attachments
              [LegalSummary]="LegalSummary"
              (action)="onChildAction($event)"
            ></app-legal-notice-attachments>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
