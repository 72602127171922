import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lms-due-list',
  templateUrl: './lms-due-list.component.html',
  styleUrls: ['./lms-due-list.component.scss']
})
export class LmsDueListComponent implements OnInit {
  DueListfrom: any = "LMS";
  constructor() { }

  ngOnInit() {
  }

}
