import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";

@Component({
  selector: "app-customer-cibil-verification",
  templateUrl: "./customer-cibil-verification.component.html",
  styleUrls: ["./customer-cibil-verification.component.scss"],
})
export class CustomerCibilVerificationComponent implements OnInit {
  showSpinner: boolean = false;
  DateFrom: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  DateTo: any = new Date();

  displayedColumns: string[] = [
    "Id",
    "Application_No",
    "CustomerName",
    "CustomerType",
    "CreatedBy",
    "CreateOn",
    "Status",
    "Score",
    "Pscore",
    "totalAccounts",
    "overdueAccounts",
    "zeroBalanceAccounts",
    "highCreditAmount",
    "currentBalance",
    "overdueBalance",
    "recentDateOpened",
    "oldestDateOpened",
  ];
  DataSource: any;
  JsonData: any;
  recentDateOpened: any;
  oldestDateOpened: any;

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {}

  // formatDate(dateString: string): string {
  //   if (dateString.length !== 8) {
  //     throw new Error("Invalid date format. Expected 'DDMMYYYY'.");
  //   }

  //   const day = dateString.substring(0, 2);
  //   const month = dateString.substring(2, 4);
  //   const year = dateString.substring(4, 8);

  //   return `${day}-${month}-${year}`;
  // }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Customer Cibil Verification");
  }

  searchCustomerCibilVerification() {
    // console.log("Search button clicked");
    this.showSpinner = true;
    this.reportsService
      .Report_CibilAPI_Request_Response({
        FromDate: this.DateFrom,
        ToDate: this.DateTo,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.JsonData = res;

          this.JsonData = this.JsonData.map((item) => {
            return {
              ...item,
              recentDateOpened: this.formatDate(item.recentDateOpened),
              oldestDateOpened: this.formatDate(item.oldestDateOpened),
            };
          });

          // console.log("updated json data", this.JsonData);

          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(this.JsonData))
          );
          // console.log("DataSourceLog", this.DataSource);
          this.DataSource.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataSource = null;
        }
      });
  }

  formatDate(dateString: string): string {
    if (dateString.length === 8) {
      const day = dateString.substring(0, 2);
      const month = dateString.substring(2, 4);
      const year = dateString.substring(4);
      return `${day}-${month}-${year}`;
    }
    return dateString; // Return the original string if it's not 8 characters
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "customer_cibil_report"
    ) as HTMLTableElement;
    const worksheetName = "CUSTOMER CIBIL REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
