<div class="d-flex align-items-center justify-content-between formborder">
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button
    type="button"
    class="btn font-size-12 button-btn"
    (click)="onAddRole()"
  >
    <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
  </button>
</div>
<div class="mt-2">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-1">
    <mat-table
      [dataSource]="dataSource"
      matSort
      style="overflow: auto; max-width: 100%; height: 60vh"
    >
      <ng-container matColumnDef="RoleId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Role_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Designation
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Role_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Role_Level">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Level
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Role_Level }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Waiver_Amount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Waiver &#8377;
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Waiver_Amount }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Waiver_Percentage">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Waiver %
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Waiver_Percentage }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Configuration">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Configuration
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Configuration }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Accounting">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Accounting
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Accounting }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="LMS">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          LMS
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LMS }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="LOS">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          LOS
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LOS }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Reports">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Reports
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Reports }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Portfolio">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Portfolio
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Portfolio }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Deposit">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Deposit
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Deposit }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="QuickLoan">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          QuickLoan
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.QuickLoan }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Mobile">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Mobile
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Mobile }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnEditRoleData(row)"
            *ngIf="data['userId'] == 1 || row.Role_Level != 0"
            aria-label="true"
          ></i>
          <i
            aria-label="true"
            class="fa fa-trash style-delete"
            (click)="OnDeleteRoleData(row)"
            *ngIf="row.Role_Level != 0"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewRoleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveRoleMaster()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ TitleName }} Designation Master
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Designation </span>
              <input
                required
                type="text"
                #refRoleName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refRoleName.invalid,
                  'alert-warning':
                    refRoleName.invalid &&
                    (refRoleName.dirty ||
                      refRoleName.touched ||
                      refRoleName.untouched)
                }"
                [(ngModel)]="RoleModel.RoleName"
                placeholder="Designation Name"
                name="RoleName"
                id="RoleName"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2 p-1">
              <span class="required-lable"> Level </span>
              <select
                name="RoleLevel"
                id="RoleLevel"
                [(ngModel)]="RoleModel.RoleLevel"
                #refRoleLevel="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refRoleLevel.invalid,
                  'alert-warning':
                    refRoleLevel.invalid &&
                    (refRoleLevel.dirty ||
                      refRoleLevel.touched ||
                      refRoleLevel.untouched)
                }"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Level</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Maximum Waiver Amount </span>
              <input
                required
                type="number"
                #refWaiver_Amount="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refWaiver_Amount.invalid,
                  'alert-warning':
                    refWaiver_Amount.invalid &&
                    (refWaiver_Amount.dirty ||
                      refWaiver_Amount.touched ||
                      refWaiver_Amount.untouched)
                }"
                [(ngModel)]="RoleModel.Waiver_Amount"
                placeholder="Waiver Amount"
                name="Waiver_Amount"
                id="Waiver_Amount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Maximum Waiver Percentage </span>
              <input
                required
                type="number"
                #refWaiver_Percentage="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refWaiver_Percentage.invalid,
                  'alert-warning':
                    refWaiver_Percentage.invalid &&
                    (refWaiver_Percentage.dirty ||
                      refWaiver_Percentage.touched ||
                      refWaiver_Percentage.untouched)
                }"
                [(ngModel)]="RoleModel.Waiver_Percentage"
                placeholder="Waiver Percentage"
                name="Waiver_Percentage"
                id="Waiver_Percentage"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 mt-2 align-items-start rolemenu">
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsConfigurationAllow"
                    id="IsConfigurationAllow"
                    #refIsConfigurationAllow="ngModel"
                    [(ngModel)]="RoleModel.IsConfigurationAllow"
                  >
                    <B>Is Configuration Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsConfigurationAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Configuration">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Configuration' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsLMSAllow"
                    id="IsLMSAllow"
                    #refIsLMSAllow="ngModel"
                    [(ngModel)]="RoleModel.IsLMSAllow"
                  >
                    <B>Is LMS Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsLMSAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.LMS">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'LMS' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsAccountingAllow"
                    id="IsAccountingAllow"
                    #refIsAccountingAllow="ngModel"
                    [(ngModel)]="RoleModel.IsAccountingAllow"
                  >
                    <B>Is Accounting Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsAccountingAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Accounting">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Accounting' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsReportsAllow"
                    id="IsReportsAllow"
                    #refIsReportsAllow="ngModel"
                    [(ngModel)]="RoleModel.IsReportsAllow"
                  >
                    <B>Is Reports Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsReportsAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Reports">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Reports' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsPortfolioAllow"
                    id="IsPortfolioAllow"
                    #refIsPortfolioAllow="ngModel"
                    [(ngModel)]="RoleModel.IsPortfolioAllow"
                  >
                    <B>Is Portfolio Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsPortfolioAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Portfolio">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Portfolio' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsDepositAllow"
                    id="IsDepositAllow"
                    #refIsDepositAllow="ngModel"
                    [(ngModel)]="RoleModel.IsDepositAllow"
                  >
                    <B>Is Deposit Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsDepositAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Deposit">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Deposit' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsQuickLoanAllow"
                    id="IsQuickLoanAllow"
                    #refIsQuickLoanAllow="ngModel"
                    [(ngModel)]="RoleModel.IsQuickLoanAllow"
                  >
                    <B>Is Quick Loan Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsQuickLoanAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.QuickLoan">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'QuickLoan' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-2 p-1">
              <div class="card">
                <div class="card-body">
                  <mat-checkbox
                    class="example-margin"
                    name="IsMobileAllow"
                    id="IsMobileAllow"
                    #refIsMobileAllow="ngModel"
                    [(ngModel)]="RoleModel.IsMobileAllow"
                  >
                    <B>Is Mobile Allow</B>
                  </mat-checkbox>
                  <ul *ngIf="RoleModel.IsMobileAllow" class="main-menu">
                    <ng-container *ngFor="let menu of RoleMenu.Mobile">
                      <ng-template
                        [ngTemplateOutlet]="nodeTemplateRef"
                        [ngTemplateOutletContext]="{
                          $implicit: { menu: menu, type: 'Mobile' }
                        }"
                      >
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveRoleMaster()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #nodeTemplateRef let-node>
  <li>
    <mat-checkbox
      class="example-margin"
      name="{{ node.type }}{{ node.menu.MenuName }}"
      id="{{ node.type }}{{ node.menu.MenuName }}"
      [(ngModel)]="node.menu.Menu_Assigned"
      (change)="onChangeMenuSelect(node.menu, node.parent)"
    >
      {{ node.menu.MenuName }}
    </mat-checkbox>
    <ul *ngIf="node.menu.Children.length" class="submenu">
      <ng-container *ngFor="let menu of node.menu.Children">
        <ng-template
          [ngTemplateOutlet]="nodeTemplateRef"
          [ngTemplateOutletContext]="{
            $implicit: { menu: menu, type: node.type, parent: node.menu }
          }"
        >
        </ng-template>
      </ng-container>
    </ul>
  </li>
</ng-template>
