<div
  class="col-md-12 p-0 d-flex justify-content-between align-items-center formborder"
>
  <div class="col-md-6">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-6 d-flex justify-content-end">
    <button
      type="button"
      class="btn font-size-12 button-btn"
      (click)="addParkingYard()"
    >
      Add Parking Yard
    </button>
  </div>
</div>

<div class="table-responsive mt-3">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    id="Parking_Yard"
    style="max-height: 5px"
  >
    <ng-container matColumnDef="Parking_Name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Parking Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Parking_Name }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Yard_Address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Yard Address</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Yard_Address }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="State_Name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>State Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.State_Name }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="District_Name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>District Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.District_Name }}
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Tehsil_Name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Tehsil Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.Tehsil_Name }}
      </td>

      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>
    <ng-container matColumnDef="Action">
      <th mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>
        Action
      </th>
      <td mat-cell *matCellDef="let row" class="grid-cell j-c-center">
        <i
          *ngIf="!inViewMode"
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          aria-label="true"
          (click)="editParkingYard(row)"
        ></i>
        <i
          class="fa fa-trash style-delete"
          (click)="deleteParkingDetails(row)"
          *ngIf="!inViewMode"
          aria-label="true"
        ></i>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="sticky-footer fontcolor"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div
  class="modal fade in"
  id="parking-yard"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ parkValue }} Parking Yard
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body formborder m-3">
        <form #py="ngForm" (ngSubmit)="onSaveparkingYard()" novalidate>
          <div class="modal-body">
            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Parking Name </span>
              </div>
              <div class="col-md-8 p-1">
                <input
                  type="text"
                  name="parking_name"
                  id=""
                  [(ngModel)]="parking_name"
                  class="form-control input-text-css"
                  required
                  #refparking_name="ngModel"
                  placeholder="Add Parking Name"
                  [ngClass]="{
                    'is-invalid': py.submitted && refparking_name.invalid,
                    'alert-warning':
                      refparking_name.invalid &&
                      (refparking_name.dirty ||
                        refparking_name.touched ||
                        refparking_name.untouched)
                  }"
                />
              </div>
            </div>

            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Parking Yard Address</span>
              </div>
              <div class="col-md-8 p-1">
                <textarea
                  required
                  rows="3"
                  #refparking_yard_add="ngModel"
                  [ngClass]="{
                    'is-invalid': py.submitted && refparking_yard_add.invalid,
                    'alert-warning':
                      refparking_yard_add.invalid &&
                      (refparking_yard_add.dirty ||
                        refparking_yard_add.touched ||
                        refparking_yard_add.untouched)
                  }"
                  [(ngModel)]="parking_yard_add"
                  placeholder="Parking Yard Address"
                  name="parking_yard_add"
                  id=""
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </div>
            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Select State </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="StateId"
                  id="StateId"
                  (change)="onChangeState()"
                  #refStateId="ngModel"
                  [ngClass]="{
                    'is-invalid': py.submitted && refStateId.invalid,
                    'alert-warning':
                      refStateId.invalid &&
                      (refStateId.dirty ||
                        refStateId.touched ||
                        refStateId.untouched)
                  }"
                  [(ngModel)]="StateId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select State</option>
                  <option
                    *ngFor="let StateList of FilterStateList"
                    [value]="StateList.StateId"
                  >
                    {{ StateList.State_Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Select District</span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  required
                  name="DistrictId"
                  (change)="onChangeDistrict()"
                  id="DistrictId"
                  #refDistrictId="ngModel"
                  [ngClass]="{
                    'is-invalid': py.submitted && refDistrictId.invalid,
                    'alert-warning':
                      refDistrictId.invalid &&
                      (refDistrictId.dirty ||
                        refDistrictId.touched ||
                        refDistrictId.untouched)
                  }"
                  [(ngModel)]="DistrictId"
                  class="form-control input-text-css"
                >
                  <option value="">Select District</option>
                  <option
                    *ngFor="let districtList of DistrictList"
                    [value]="districtList.DistrictId"
                  >
                    {{ districtList.District_Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Teshil Name </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="Tehsil_Id"
                  id="Tehsil_Id"
                  #refTehsil_Id="ngModel"
                  [ngClass]="{
                    'is-invalid': py.submitted && refTehsil_Id.invalid,
                    'alert-warning':
                      refTehsil_Id.invalid &&
                      (refTehsil_Id.dirty ||
                        refTehsil_Id.touched ||
                        refTehsil_Id.untouched)
                  }"
                  [(ngModel)]="Tehsil_Id"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Tehsil</option>
                  <option
                    *ngFor="let tehsil of tehasilDropdown"
                    [value]="tehsil.TehsilId"
                  >
                    {{ tehsil.Tehsil_Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="border: none">
        <button
          type="button"
          (click)="onSaveparkingYard()"
          [disabled]="!py.form.valid"
          class="mt-3 btn font-size-12 button-btn"
          data-dismiss="modal"
        >
          {{ BtnName }}
        </button>
      </div>
    </div>
  </div>
</div>
