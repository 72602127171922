import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
declare var $: any;

@Component({
  selector: 'app-questions-fi',
  templateUrl: './questions-fi.component.html',
  styleUrls: ['./questions-fi.component.scss']
})
export class QuestionsFiComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  dataSource: any; loading: boolean = false; FilterProductId: any = '';
  RequestModel: RequestModel = new RequestModel();
  SaveFIQuestionsRequestModel: RequestModel = new RequestModel();
  EditFIQuestionsRequestModel: RequestModel = new RequestModel();
  DeleteFIQuestionsRequestModel: RequestModel = new RequestModel();
  displayedColumns: string[] = ['QueId', 'Question', 'Question_Hindi', 'QuestionType', 'Question_For', 'CreateOn', 'LoanProduct', 'EditAction'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  FIQuestionsList: any = []; FilterProductList: any = [];

  QuestionTypeOptions: any = [];
  //add 
  FIQueId: number = 0;
  HeaderText: string = 'Add';
  FIQuestion: any = ''; FIQuestionType: any = '';
  FIQuestionOptions: any = ''; FIQuestionProducts: any = '';
  FIQuestionHindi: any = '';
  dialogreturn: any;
  FIQuestionFor: any = '';
  Higher: boolean = false;
  CoBrowser: boolean = false;
  Guarantor: boolean = false;

  constructor(private dataSharingService: DataSharingService, private dialog: DialogService, public snackBar: MatSnackBar, private MasterService: MasterService) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };


  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('FI Questions')
    this._MasterService.GetProductForDropdown().subscribe((result) => {
      this.FilterProductList = JSON.parse(JSON.stringify(result));
      this.FilterProductList.map((item) => {
        item.isAcive = false;
      });
    });
    this.getFIQuestionsList();

  }

  getFIQuestionsList() {
    this.showSpinner = true;
    this.RequestModel.ProductId = this.FilterProductId == '' ? 0 : parseInt(this.FilterProductId);
    this._MasterService.LOSGetFIQuestionsList(this.RequestModel).subscribe((result) => {
      this.FIQuestionsList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.FIQuestionsList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }

  onChangeFIQuestionType() {
    this.QuestionTypeOptions = [{ FI_Questions_Option: '' }];
  }

  onAddNewRow() {
    this.QuestionTypeOptions.push({ FI_Questions_Option: '' });
  }

  onDeleteRow(selectedIndex) {
    this.QuestionTypeOptions.splice(selectedIndex, 1);
  }

  onDeleteFIQuestion(row) {
    this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete?');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.DeleteFIQuestionsRequestModel.FIQueId = row.QueId;
        this.DeleteFIQuestionsRequestModel.LoginUserId = this.data.userId;
        this._MasterService.LOSDeleteFIQuestion(this.DeleteFIQuestionsRequestModel).subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            this.getFIQuestionsList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  onOpenFIQuestionModal() {
    $('#addNewFIQuestion').modal('show');
    $("#addNewFIQuestion").css("z-index", "1050");
  }

  OnClose() {
    this.FIQueId = 0;
    this.FIQuestion = '';
    this.FIQuestionHindi = '';
    this.FIQuestionType = '';
    this.FIQuestionOptions = '';
    this.FIQuestionProducts = '';
    this.Higher = false;
    this.CoBrowser = false;
    this.Guarantor = false;
    this.QuestionTypeOptions = [];
    this.FilterProductList.map((item) => {
      item.isAcive = false;
    });
    this.HeaderText = 'Add';
    $('#addNewFIQuestion').modal('hide');
  }

  onEditFIQuestion(row) {
    this.HeaderText = 'Edit';
    this.EditFIQuestionsRequestModel.FIQueId = row.QueId;
    this._MasterService.LOSGetFIQuestionDetails(this.EditFIQuestionsRequestModel).subscribe((response) => {
      console.log(response);
      this.FIQueId = response.Item1[0].FI_QueId;
      this.FIQuestion = response.Item1[0].FI_Question;
      this.FIQuestionHindi = response.Item1[0].FI_Question_Hindi;
      this.FIQuestionType = response.Item1[0].FI_QuestionType;
      this.FIQuestionFor = response.Item1[0].FI_QuestionFor;
      console.log(this.FIQuestionFor);
      if (this.FIQuestionFor.includes('C')) {
        this.CoBrowser = true;
      }
      if (this.FIQuestionFor.includes('G')) {
        this.Guarantor = true;
      }
      if (this.FIQuestionFor.includes('H')) {
        this.Higher = true;
      }
      this.FilterProductList.map((item) => {
        response.Item2.map((item1) => {
          if (item.ProductId == item1.ProductId) {
            item.isAcive = true;
          }
        })
      });

      response.Item3.map((item) => {
        this.QuestionTypeOptions.push({ FI_Questions_Option: item.FI_Questions_Option });
      });

      $('#addNewFIQuestion').modal('show');
      $("#addNewFIQuestion").css("z-index", "1050");
    });
  }

  onSaveFIQuestionData() {
    this.FIQuestionOptions = Array.prototype.map.call(this.QuestionTypeOptions, function (item) { return item.FI_Questions_Option }).join("@@");

    let FilterProductData = this.FilterProductList.filter(a => a.isAcive == true);
    this.FIQuestionProducts = Array.prototype.map.call(FilterProductData, function (item) { return item.ProductId }).join(",");

    this.SaveFIQuestionsRequestModel.FIQueId = this.FIQueId;
    this.SaveFIQuestionsRequestModel.FIQuestion = this.FIQuestion;
    this.SaveFIQuestionsRequestModel.FIQuestion_Hindi = this.FIQuestionHindi;
    this.SaveFIQuestionsRequestModel.FIQuestionType = this.FIQuestionType;    
    this.SaveFIQuestionsRequestModel.FIQuestionOptions = this.FIQuestionOptions;
    this.SaveFIQuestionsRequestModel.FIQuestionProducts = this.FIQuestionProducts;
    this.SaveFIQuestionsRequestModel.LoginUserId = this.data.userId;
    this.FIQuestionFor = '';
    if (this.Higher == true) {
      this.FIQuestionFor = 'H,';
    }
    if (this.CoBrowser == true) {
      this.FIQuestionFor = this.FIQuestionFor + 'C,';
    }
    if (this.Guarantor == true) {
      this.FIQuestionFor = this.FIQuestionFor + 'G';
    }
    this.SaveFIQuestionsRequestModel.FIQuestionFor = this.FIQuestionFor.trim();
    this._MasterService.SaveFIQuestions(this.SaveFIQuestionsRequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        this.getFIQuestionsList();
        this.OnClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

}
