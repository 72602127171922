// #region Import Used Components and Services
import { Injectable } from "@angular/core";
import { throwError, Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { GetrSaveresponseModel } from "../../Shared/Models/app.MasterResponseModel";
// #endregion

@Injectable({
  providedIn: "root",
})
export class LmsService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(
    private _Route: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  // #region Save_Area
  public Save_Lms_Area(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveAreaMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Area
  public Save_Lms_SubArea(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveSubAreaMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Area
  public Save_Lms_AssetInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveAssetInfoMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Area
  public Save_Lms_VehicleCategoryMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveVehicleCategoryMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Area
  public Save_Lms_VehicleModelMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveVehicleModelMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_loanAccounting
  public Save_Lms_loanAccounting(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/SaveLoanAccountingModelMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Crc
  public Save_Lms_Crc_Master(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveCrcTypeModelMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Crc
  public Save_Lms_InsuranceCompany_Master(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/SaveInsuranceCompanyMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Lms_SaveLoanInfo
  public SaveLoanInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveloanInfo";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveLoanAccounting
  public SaveLoanAccounting(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveLoanAccount";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveUserAssetInfo
  public SaveUserAssetInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveUserAssetInfo";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region ViewAssetInfo
  public ViewAssetInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/ViewAssetInfo";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveChequeDetailsPdcRpdc
  public SaveChequeDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveChequeDetailsPdcRpdc";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveChequeECSDetails
  public SaveChequeECSDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveChequeECSDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveNACHDetails
  public SaveNACHDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveNACHDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveInsurance
  public SaveInsurance(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveInsurance";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion
  // #region SaveInsurance
  public SaveAccountInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveAccountInfo";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion
  // #region SaveCustomerInfoDetails
  public SaveCustomerInfoDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveCustomerInfoDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion
  // #region SaveCustomerFirmDetails
  public SaveCustomerFirmDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveCustomerFirm";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveCustomerInfoDetails
  public SaveReceiptDeduction(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveReceiptDeduction";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveCustomerInfoDetails
  public onSaveCustomerRef(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveCustomerRef";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LMS_SaveApplicationCustomerWEB
  public LMS_SaveApplicationCustomerWEB(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/LMS_SaveApplicationCustomerWEB";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LMS_GetCalculateEMIIRR
  public LMS_GetCalculateEMIIRR(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCalculateEMIIRR`,
      requestData
    );
  }
  // #endregion
  // #region GetEMICalculate
  public GetEMICalculate(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetEMICalculate`,
      requestData
    );
  }
  // #endregion

  // #region LMS_CheckCustomerDuplicationKYCApp
  public LMS_CheckCustomerDuplicationKYCApp(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_CheckCustomerDuplicationKYCApp`,
      requestData
    );
  }
  // #endregion
  // #region LMS_CheckFirm_DuplicationApp
  public LMS_CheckFirm_DuplicationApp(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_CheckFirm_DuplicationApp`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetCalculateEMIIRR
  public LMS_GetCustomerRef(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerRef`,
      requestData
    );
  }
  // #endregion

  // #region Get_VehicleModelMaster
  public Get_Lms_InsuranceCompanyMaster() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetInsuranceCompanyMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region GetReceiptDeduction
  public GetReceiptDeduction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetReceiptDeduction`,
      requestData
    );
  }
  // #endregion

  // #region Get_VehicleModelMaster
  public Get_Lms_UpdateBookingList() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetUpdateBookingsList";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_VehicleModelMaster
  public Get_Lms_VehicleModelMaster() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetVehicleModelMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_Crc
  public Get_Lms_CrcMaster() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetCrcTypeModelMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_Area
  public Get_Lms_Area() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetAreaMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_AssetInfo
  public Get_Lms_AssetInfo() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetAssetInfoMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_SubArea
  public Get_Lms_SubArea() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetSubAreaMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_VehicleMaster
  public Get_Lms_VehicleMaster() {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetVehicleCategoryMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region GetLoanAccountingModelMaster
  public Get_Lms_loanAccounting() {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/GetLoanAccountingModelMaster";
    return this.http.get(apiUrl);
  }
  // #endregion

  // #region Get_Lms_loanAccountingHeader
  public Get_Lms_loanAccountingHeader(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/Get_Lms_loanAccountingHeader";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_Lms_loanAccountingHeader
  public GetBranches(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetBranchesList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_Lms_loanAccountingHeader
  public Get_Lms_ProductByCatId(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/Get_Lms_ProductByCatId";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetBranchByArea
  public Get_Lms_Branch_ByArea(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetBranchByArea";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetAreaByBranch
  public Get_Lms_Area_ByBranch(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetAreaByBranch";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  public LMS_GetAllUserAssetInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/LMS_GetUserAssetInfo";
    return this.http.post(apiUrl, requestModel);
  }

  public Get_Lms_CollectionExecutive(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetCollectionExecutive";
    return this.http.post(apiUrl, requestModel);
  }

  // #region GetAreaByBranch
  public get_Saved_LoanInfo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetSavedLoanInfo";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetChequeDetails
  public GetChequeDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetChequeDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetAreaByBranch
  public getLoanAccount(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetLoanAccount";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_Lms_BranchHead_ByBranch
  public Get_Lms_BranchHead_ByBranch(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetBranchHeadByBranch";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_Lms_SubArea_ByArea
  public Get_Lms_SubArea_ByArea(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetSubAreaByArea";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_Lms_Page_Submitted
  public getAppNo_AND_LoanNo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/getAppNo_AND_LoanNo";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_AreaMaster
  public Delete_Lms_Area(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteArea";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region Delete_Lms_SubAreaMaster
  public Delete_Lms_SubAreaMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteSubArea";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_SubAreaMaster
  public Delete_Lms_AssetInfoMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteAssetInfo";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_VehicleCatMaster
  public Delete_Lms_VehicleCatMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteVehicleCategory";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_VehicleModelMaster
  public Delete_Lms_VehicleModelMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteVehicleModel";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_VehicleModelMaster
  public Delete_Lms_LoanActMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteLoanAccounting";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_CrcModelMaster
  public Delete_Lms_CrcModelMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteCrcType";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Lms_CrcModelMaster
  public Delete_Lms_InsuranceCompnyMaster(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteInsuranceCompany";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_GetFileCheckingHOLists
  public LMS_GetUpdateBookingList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetUpdateBookingList`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_ModifyBooking_List
  public LMS_Get_ModifyBooking_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_ModifyBooking_List`,
      requestData
    );
  }
  // #endregion

  // #region LOS_SaveGenerateApplicationNo
  public LMS_SaveGenerateApplicationNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveGenerateApplicationNo`,
      requestData
    );
  }
  // #endregion

  // #region LOS_SaveGenerateApplicationNo
  public LMS_SaveAmortization(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveAmortization`,
      requestData
    );
  }
  // #endregion

  // #region IsExistLoanAccounting
  public IsExistLoanAccounting(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/IsExistLoanAccounting";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_GetApplicationDetailsForSaleProcess
  public LMS_GetLoanDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetLoanDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_DeleteLoanCustomer
  public LMS_DeleteLoanCustomer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_DeleteLoanCustomer`,
      requestData
    );
  }
  // #endregion
  // #region get CRC Cuatomer
  public GetLMSCRCCustomer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCRCCustomer`,
      requestData
    );
  }
  // #endregion
  // #region get CRC Cuatomer detail
  public GetLmsCRCCustomerDetail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerCRCDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetCustomerTVRDetails
  public LMS_GetCustomerTVRDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerTVRDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetTVRCustomer
  public LMS_GetTVRCustomer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetTVRCustomer`,
      requestData
    );
  }
  // #endregion

  // #region SaveLMSChequeDetails
  public SaveLMSChequeDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveChequeDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LMS_GetTVRCustomer
  public LMS_GetLoanDetail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetLoanDetail`,
      requestData
    );
  }
  // #endregion

  // #region LOS_GetApplicationDetailsForSaleProcess
  public LMS_GetApplicationDetailsForSaleProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetApplicationDetailsForSaleProcess`,
      requestData
    );
  }
  // #endregion

  // #region FinancialDetailsForUpdate
  public FinancialDetailsForUpdate(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMSFinancialDetailsForUpdate`,
      requestData
    );
  }
  // #endregion

  public Save_InsuranceCompany(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/SaveInsuranceCompanyMaster`,
      requestData
    );
  }

  // #region #GetInsuranceCompanyMasters
  public GetInsuranceCompany() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/LMS/GetInsuranceCompanyMasters`
    );
  }

  // #region GetChequeDetails
  public GetChequeDetailsById(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetChequeDetailsById`,
      requestData
    );
  }
  // #endregion
  // #region GetCustomerByLoanNo
  public GetCustomerByLoanNo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetCustomerByLoanNo";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region #SaveInsuranceBranchMaster
  public SaveInsuranceBranchMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/SaveInsuranceBranchMaster`,
      requestData
    );
  }

  // GetInsuranceBranchMasters
  public GetInsuranceBranchMasters() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/LMS/GetInsuranceBranchMasters`
    );
  }

  // #region DeleteChequeDetails
  public DeleteChequeDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteChequeDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region GetInsuranceDetails
  public GetInsuranceDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetInsuranceDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region LMS_GetTVRCustomers
  public LMS_GetTVRCustomers(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetTVRCustomers`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetTVRCustomerDetails
  public LMS_GetTVRCustomerDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerTVRDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_SaveTVRCustomerDetails
  public LMS_SaveTVRCustomerDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveTVRCustomerDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_SaveTVRCustomerDetails
  public LMS_GetFICustomers(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetFICustomer`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetFICustomerDetails
  public LMS_GetCustomerFIDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerFIDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_SaveFICustomerDetails
  public LMS_SaveFICustomerDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveFICustomerDetails`,
      requestData
    );
  }
  // #endregion

  // #region Upload crc doc
  public UploadCrcDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadApplicationDoc`,
      requestData
    );
  }
  // #endregion

  // #region GetDeductionDetails
  public GetDeductionDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetDeductionDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region save CRC Cuatomer detail
  public LmsSaveCrc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveCRC`,
      requestData
    );
  }
  // #endregion

  // #region getLMS_Repay_Sechule
  public getLMS_Repay_Sechule(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/getLMS_Repay_Sechule";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region getLMS_Repay_Sechule
  // public getLMS_AssetInfo(requestModel: any) {
  //   var apiUrl = constantUrl.apiEndpoint + "/api/LMS/getLMS_AssetInfo";
  //   return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  // }
  // #endregion

  // #region getLMS_Repay_Sechule_StepEMI
  public getLMS_Repay_Sechule_StepEMI(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/LMS/getLMS_Repay_Sechule_StepEMI";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteInsuranceDetails
  public DeleteInsuranceDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/DeleteInsuranceDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region GetInsuranceDetailsById
  public GetInsuranceDetailsById(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/GetInsuranceDetailsById";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region LMS_GetFileCheckingHOList
  public LOS_GetFileCheckingHOList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetFileCheckingHOList`,
      requestData
    );
  }
  // #endregion
  // #region SaveNext_Loan
  public SaveNext_Loan(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/SaveNext_Loan";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region Get_Customer
  public Get_Customer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Customer`,
      requestData
    );
  }
  // #endregion
  // #region LMS_GetCustomerAddressWeb
  public LMS_GetCustomerAddressWeb(requestData: any) {
    // return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerAddressWeb`, requestData);
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetCustomerAddress`,
      requestData
    );
  }
  // #endregion
  // #region LOS_SaveCustomerKYCDocApp
  public LMS_SaveCustomerKYCDocApp(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveCustomerKYCDocApp`,
      requestData
    );
  }
  // #endregion
  // #region GET_VEHICLEMODEL_MASTER
  public GET_VEHICLEMODEL_MASTER(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_VEHICLEMODEL_MASTER`,
      requestData
    );
  }
  // #endregion
  // #region GET_CRC_MASTER_BY_CRCAGENCY
  public GET_CRC_MASTER_BY_CRCAGENCY(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_CRC_MASTER_BY_CRCAGENCY`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Commaon_Master
  public LMS_Commaon_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Commaon_Master`,
      requestData
    );
  }
  // #endregion

  // #region GetInsuranceBranchList
  public GetInsuranceBranchList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetInsuranceBranchList`,
      requestData
    );
  }
  // #endregion

  // #region #GetInsuranceCompanyList
  public GetInsuranceCompanyList() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/LMS/GetInsuranceCompanyList`
    );
  }
  // #endregion

  // #region GET_Partner_List
  public GET_Partner_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_Partner_List`,
      requestData
    );
  }
  // #endregion
  // #region GET_VEHICLEREGISTRATION_DETAILS
  public GET_VEHICLEREGISTRATION_DETAILS(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_VEHICLEREGISTRATION_DETAILS`,
      requestData
    );
  }
  // #endregion

  // #region Update_AssetsCost
  public Update_AssetsCost(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Update_AssetsCost`,
      requestData
    );
  }
  // #endregion
  // #region Delete_ReceiptDeduction
  public Delete_ReceiptDeduction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_ReceiptDeduction`,
      requestData
    );
  }
  // #endregion
  // #region save CRC Cuatomer detail
  public LmsUpdateCrc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_UpdateCRC`,
      requestData
    );
  }
  // #endregion
  // #region LMS_GET_NACHINFO
  public LMS_GET_NACHINFO(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GET_NACHINFO`,
      requestData
    );
  }
  // #endregion
  // #region LMS_SAVE_NACHINFO
  public LMS_SAVE_NACHINFO(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SAVE_NACHINFO`,
      requestData
    );
  }
  // #endregion
  // #region LMS_GET_NACH_EMI_DETAI
  public LMS_GET_NACH_EMI_DETAI(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GET_NACH_EMI_DETAI`,
      requestData
    );
  }
  // #endregion

  // #region GET_LOANINFOBYLOS
  public GET_LOANINFOBYLOS(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_LOANINFOBYLOS`,
      requestData
    );
  }
  // #endregion
  // #region LMS_GETLOS_UPDATEBOOKING_LIST
  public LMS_GETLOS_UPDATEBOOKING_LIST(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GETLOS_UPDATEBOOKING_LIST`,
      requestData
    );
  }
  // #endregion

  // #region Get_DefaultChargeHeadsForDropdown
  public Get_DefaultChargeHeadsForDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_DefaultChargeHeadsForDropdown`,
      requestData
    );
  }
  // #endregion

  // #region Get_ChargeableAccountForDropdown
  public Get_ChargeableAccountForDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ChargeableAccountForDropdown`,
      requestData
    );
  }
  // #endregion

  // #region Get_ProductDefaultChargeableDetails
  public Get_ProductDefaultChargeableDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ProductDefaultChargeableDetails`,
      requestData
    );
  }
  // #endregion

  // #region Get_AccountHeadForTaxSlab
  public Get_AccountHeadForTaxSlab(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_AccountHeadForTaxSlab`,
      requestData
    );
  }
  // #endregion

  // #region Save_TaxSlab
  public Save_TaxSlab(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_TaxSlab`,
      requestData
    );
  }
  // #endregion

  // #region Get_TaxSlab_Details
  public Get_TaxSlab_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_TaxSlab_Details`,
      requestData
    );
  }
  // #endregion

  // #region Get_TaxSlab_Dropdown
  public Get_TaxSlab_Dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_TaxSlab_Dropdown`,
      requestData
    );
  }
  // #endregion

  // #region Get_Acc_Grouplist
  public Get_Acc_Grouplist(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Grouplist`,
      requestData
    );
  }
  // #endregion

  // #region Save_AccGroupMasters
  public Save_AccGroupMasters(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_AccGroupMasters`,
      requestData
    );
  }
  // #endregion

  // #region Get_Acc_GroupDropdown
  public Get_Acc_GroupDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_GroupDropdown`,
      requestData
    );
  }
  // #endregion

  // #region Delete_AccGroup
  public Delete_AccGroup(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_AccGroup`,
      requestData
    );
  }
  // #endregion

  // #region GetAccAccountingList
  public GetAccAccountingList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetAccAccountingList`,
      requestData
    );
  }
  // #endregion

  // #region Save_AccAccountMasters
  public Save_AccAccountMasters(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_AccAccountMasters`,
      requestData
    );
  }
  // #endregion

  // #region Getacc_AccountDetail
  public Getacc_AccountDetail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Getacc_AccountDetail`,
      requestData
    );
  }
  // #endregion

  // #region Delete_Acc_Account_Master
  public Delete_Acc_Account_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_Acc_Account_Master`,
      requestData
    );
  }
  // #endregion

  // #region Save_Acc_VoucherMaster
  public Save_Acc_VoucherMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Acc_VoucherMaster`,
      requestData
    );
  }
  // #endregion

  // #region GetVoucherNo
  public GetVoucherNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetVoucherNo`,
      requestData
    );
  }
  // #endregion

  // #region Get_Acc_Accounts_By_Tag
  public Get_Acc_Accounts_By_Tag(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Accounts_By_Tag`,
      requestData
    );
  }
  // #endregionng

  // #region Get_Acc_Search_LoanNo
  public Get_Acc_Search_LoanNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Search_LoanNo`,
      requestData
    );
  }
  // #endregion

  // #region Get_AccountHead_For_VoucherEntry
  public Get_AccountHead_For_VoucherEntry(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_AccountHead_For_VoucherEntry`,
      requestData
    );
  }
  // #region GET_ACCOUNT_MASTER_BY_TAG
  public GET_ACCOUNT_MASTER_BY_TAG(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_ACCOUNT_MASTER_BY_TAG`,
      requestData
    );
  }
  // #endregion

  // #region Get_Acc_Accounts_OtherThan_BankCash
  public Get_Acc_Accounts_OtherThan_BankCash(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Accounts_OtherThan_BankCash`,
      requestData
    );
  }
  // #endregion
  // #region Get_Acc_Accounts_ForJVandContra
  public Get_Acc_Accounts_ForJVandContra(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Accounts_ForJVandContra`,
      requestData
    );
  }
  // #endregion

  // #region #GET_LMS_NACH_PROCESS
  public GET_LMS_NACH_PROCESS() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/LMS/GET_LMS_NACH_PROCESS`
    );
  }
  // #endregion

  // #region SAVE_NACHPROCESS
  public GET_LMS_NACH_PROCESSSearch(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GET_LMS_NACH_PROCESSSearch`,
      requestData
    );
  }
  // #endregion
  // #region SAVE_NACHPROCESS
  public SAVE_NACHPROCESS(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/SAVE_NACHPROCESS`,
      requestData
    );
  }
  // #endregion
  // #region Get_Acc_Leadger_Loan
  public Get_Acc_Leadger_Loan(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Leadger_Loan`,
      requestData
    );
  }
  // #endregion
  // #region Get_Acc_Leadger
  public Get_Acc_Leadger(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Leadger`,
      requestData
    );
  }
  // #endregion
  // #region Get_SearchCustomer
  public Get_SearchCustomer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_SearchCustomer`,
      requestData
    );
  }
  // #endregion

  // #region Get_SearchCustomer
  public Get_ACC_Leadger_Loan_OD(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ACC_Leadger_Loan_OD`,
      requestData
    );
  }
  // #endregion
  // #region Get_Account_Master
  public Get_Account_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Account_Master`,
      requestData
    );
  }
  // #endregion

  // #region Get_ACC_Leadger_Loan_OD_Charges
  public Get_ACC_Leadger_Loan_OD_Charges(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ACC_Leadger_Loan_OD_Charges`,
      requestData
    );
  }
  // #endregion
  // #region Get_Acc_Leadger_Loan_Charges_Detail
  public Get_Acc_Leadger_Loan_Charges_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_Leadger_Loan_Charges_Detail`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Series_For_List
  public LMS_Get_Loan_Series_For_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Series_For_List`,
      requestData
    );
  }
  // #endregion

  // #region Get_Loan_Accounting_Category_ForDropdown
  public Get_Loan_Accounting_Category_ForDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Loan_Accounting_Category_ForDropdown`,
      requestData
    );
  }
  // #endregion

  // #region Save_Loan_NO_Series
  public Save_Loan_NO_Series(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Loan_NO_Series`,
      requestData
    );
  }
  // #endregion

  // #region Delete_Loan_No_Series
  public Delete_Loan_No_Series(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_Loan_No_Series`,
      requestData
    );
  }
  // #endregion

  // #region Get_Loan_Accounting_Category_For_List
  public Get_Loan_Accounting_Category_For_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Loan_Accounting_Category_For_List`,
      requestData
    );
  }
  // #endregion

  // #region Save_Loan_Accounting_Category
  public Save_Loan_Accounting_Category(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Loan_Accounting_Category`,
      requestData
    );
  }
  // #endregion

  // #region Delete_Loan_Accounting_Category
  public Delete_Loan_Accounting_Category(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_Loan_Accounting_Category`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Summary
  public LMS_Get_Loan_Summary(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Summary`,
      requestData
    );
  }
  // #endregion
  // #region Get_LMS_CustomerDetails
  public Get_LMS_CustomerDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/LMS/Get_LMS_CustomerDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region Save_Acc_VoucherMaster_By_NachProcess
  public Save_Acc_VoucherMaster_By_NachProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LOS/Save_Acc_VoucherMaster_By_NachProcess`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_ACC_By_Loan_ChargesHead
  public LMS_Get_ACC_By_Loan_ChargesHead(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_ACC_By_Loan_ChargesHead`,
      requestData
    );
  }
  // #endregion
  // #region Update_Voucher_Clear_OR_Bounce
  public Update_Voucher_Clear_OR_Bounce(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Update_Voucher_Clear_OR_Bounce`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Documents
  public LMS_Get_Loan_Documents(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Documents`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_Documents
  public LMS_GetLoanReqDocForDropDown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetLoanReqDocForDropDown`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_Documents
  public LMSAssetInfoDropDown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMSAssetInfoDropDown`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_Documents
  public LMS_SaveLoanDocumentJson(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveLoanDocumentJson`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_Documents
  public LMS_DeleteLoanDocument(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_DeleteLoanDocument`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_NoteRecommendation
  public LMS_Get_NoteRecommendation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_NoteRecommendation`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Save_NoteRecommendation
  public LMS_Save_NoteRecommendation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_NoteRecommendation`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Delete_NoteRecommendation
  public LMS_Delete_NoteRecommendation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Delete_NoteRecommendation`,
      requestData
    );
  }
  // #endregion
  // #region LOS_GetApplicationDocAttachment
  public LMS_GetApplicationDocAttachment(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetApplicationDocAttachment`,
      requestData
    );
  }
  // #endregion

  // #region Upload crc doc
  public UploadLoanDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadLoanDoc`,
      requestData
    );
  }

  // #endregion

  // #region LMS_Get_Loan_Series_For_Dropdown
  public LMS_Get_Loan_Series_For_Dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Series_For_Dropdown`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_No
  public LMS_Get_Loan_No(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_No`,
      requestData,
      { responseType: "text" }
    );
  }
  // #endregion

  // #region LMS_AssetCustomer_Info_DropDown
  public LMS_AssetCustomer_Info_DropDown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_AssetCustomer_Info_DropDown`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Generate_LoanNo
  public LMS_Generate_LoanNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Generate_LoanNo`,
      requestData
    );
  }
  // #endregion

  // #region GetLoanDetailsForSaleProcess
  public GetLoanDetailsForSaleProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetLoanDetailsForSaleProcess`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Amortization_Chart
  public LMS_Get_Amortization_Chart(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Amortization_Chart`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_DV_List
  public LMS_Get_DV_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_DV_List`,
      requestData
    );
  }
  // #endregion

  // #region LMS_SaveEMIDetails
  public LMS_SaveEMIDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_SaveEMIDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetAppliactionEMIDetails
  public LMS_GetAppliactionEMIDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetAppliactionEMIDetails`,
      requestData
    );
  }
  // #endregion

  // #region LMS_GetModifyBookingList
  public LMS_GetModifyBookingList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetModifyBookingList`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_DV_Details
  public LMS_Get_DV_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_DV_Details`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_DV_List_Authorization
  public LMS_Get_DV_List_Authorization(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_DV_List_Authorization`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Authorized_DV_Voucher
  public LMS_Authorized_DV_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Authorized_DV_Voucher`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_DV_Details_Print
  public LMS_Get_DV_Details_Print(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_DV_Details_Print`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Voucher_Details
  public LMS_Get_Voucher_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Voucher_Details`,
      requestData
    );
  }
  // #endregion
  // #region Get_FinancialYear
  public Get_FinancialYear(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_FinancialYear`,
      requestData
    );
  }
  // #endregion

  // #region Get_TrialBalance
  public Get_TrialBalance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_TrialBalance`,
      requestData
    );
  }
  // #endregion

  // #region Get_Reconcile_Balance
  public Get_Reconcile_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Reconcile_Balance`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Update_Voucher_Cleardate
  public LMS_Update_Voucher_Cleardate(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_Voucher_Cleardate`,
      requestData
    );
  }
  // #endregion
  // #region Get_ACC_Leadger_AsBank
  public Get_ACC_Leadger_AsBank(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ACC_Leadger_AsBank`,
      requestData
    );
  }
  // #endregion

  // #region Get_Receipt_Entry
  public Get_Receipt_Entry(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Receipt_Entry`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Restructured_Loan_List
  public LMS_Get_Restructured_Loan_List() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Restructured_Loan_List`,
      {}
    );
  }
  // #endregion

  // #region LMS_Get_Search_Loans_JOSN
  public LMS_Get_Search_Loans_JSON(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Search_Loans_JSON`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Restructured_Detail
  public LMS_Get_Loan_Restructured_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Restructured_Detail`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Update_Financial_Restructured
  public LMS_Update_Financial_Restructured(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_Financial_Restructured`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Reschedule_Request_List
  public LMS_Get_Loan_Reschedule_Request_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Reschedule_Request_List`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Reschedule_Detail
  public LMS_Get_Loan_Reschedule_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Reschedule_Detail`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Reschedule_Proposed_Values
  public LMS_Get_Loan_Reschedule_Proposed_Values(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Reschedule_Proposed_Values`,
      requestData
    );
  }
  // #endregion

  // #region Get_Account_Master_By_NACH_Enable
  public Get_Account_Master_By_NACH_Enable(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Account_Master_By_NACH_Enable`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Save_Reschedule_Request
  public LMS_Save_Reschedule_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_Reschedule_Request`,
      requestData
    );
  }
  // #endregion

  // #region Save_Case_Logs
  public Save_Case_Logs(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Case_Logs`,
      requestData
    );
  }
  // #endregion

  // #region Get_Case_Logs
  //public Get_Case_Logs(requestData: any) {
  //  return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/Get_Case_Logs`, requestData);
  //}
  // #endregion

  // #region LMS_Authorized_Reschedule_Request
  public LMS_Authorized_Reschedule_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Authorized_Reschedule_Request`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Delete_Reschedule_Request
  public LMS_Delete_Reschedule_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Delete_Reschedule_Request`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Closer_Detail
  public LMS_Get_Loan_Closer_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Detail`,
      requestData
    );
  }
  // #endregion

  // #region Trail_Balance_GroupBY
  public Trail_Balance_GroupBY(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Trail_Balance_GroupBY`,
      requestData
    );
  }
  // #endregion

  // #region Delete_Voucher
  public Delete_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_Voucher`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_User_For_Waiver_Approval
  public LMS_Get_User_For_Waiver_Approval(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_User_For_Waiver_Approval`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Update_EMI_Allocation
  public LMS_Update_EMI_Allocation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_EMI_Allocation`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Save_Loan_Closer_Request
  public LMS_Save_Loan_Closer_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_Loan_Closer_Request`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_Closer_Due_Detail
  public LMS_Get_Loan_Closer_Due_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Due_Detail`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_WriteOff_Settled_Detail
  public LMS_Get_Loan_WriteOff_Settled_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_WriteOff_Settled_Detail`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Closer_Request_List
  public LMS_Get_Loan_Closer_Request_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Request_List`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Closer_Request_WaiverApproval
  public LMS_Get_Loan_Closer_Request_WaiverApproval(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Request_WaiverApproval`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Delete_Loan_Closer_Request
  public LMS_Delete_Loan_Closer_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Delete_Loan_Closer_Request`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Loan_Closer_Request_Undo
  public LMS_Loan_Closer_Request_Undo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Loan_Closer_Request_Undo`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_For_Auto_Closer
  public LMS_Get_Loan_For_Auto_Closer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_For_Auto_Closer`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_Loan_Closer_Statement
  public LMS_Get_Loan_Closer_Statement(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Statement`,
      requestData
    );
  }
  // #endregion

  // #region UploadCustomerDoc
  public UploadCustomerDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadCustomerDoc`,
      requestData
    );
  }
  // #endregion
  // #Acc_Day_Book
  public Acc_Day_Book(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Day_Book`,
      requestData
    );
  }
  // #end Acc_Day_Book

  // #Get_ACC_Category_ForDropdown_By_Type
  public Get_ACC_Category_ForDropdown_By_Type(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ACC_Category_ForDropdown_By_Type`,
      requestData
    );
  }
  // #end Get_ACC_Category_ForDropdown_By_Type

  // #Get_All_DV_List
  public Get_All_DV_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_All_DV_List`,
      requestData
    );
  }
  // #end Get_All_DV_List

  // #Get_LMS_Dashboard
  public Get_LMS_Dashboard(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_LMS_Dashboard`,
      requestData
    );
  }
  // #end Get_LMS_Dashboard

  // #LMS_Get_PDD_Detail
  public LMS_Get_PDD_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_PDD_Detail`,
      requestData
    );
  }
  // #end LMS_Get_PDD_Detail

  // #Acc_Voucher_Audit_Trail_Update
  public Acc_Voucher_Audit_Trail_Update(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Voucher_Audit_Trail_Update`,
      requestData
    );
  }
  // #end

  // #Acc_Voucher_Audit_Trail_Delete
  public Acc_Voucher_Audit_Trail_Delete(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Voucher_Audit_Trail_Delete`,
      requestData
    );
  }
  // #end

  // #ACC_Voucher_Trail_Detail
  public ACC_Voucher_Trail_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Voucher_Trail_Detail`,
      requestData
    );
  }
  // #end
  // #ACC_Voucher_Trail
  public ACC_Voucher_Trail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Voucher_Trail`,
      requestData
    );
  }
  // #end

  // #LMS_Get_PDC_Cheque_List
  public LMS_Get_PDC_Cheque_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_PDC_Cheque_List`,
      requestData
    );
  }
  // #end LMS_Get_PDC_Cheque_List

  // #LMS_Get_Case_History
  public LMS_Get_Case_History(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Case_History`,
      requestData
    );
  }
  // #end LMS_Get_Case_History

  // #LMS_Loan_Closed_List
  public LMS_Loan_Closed_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Loan_Closed_List`,
      requestData
    );
  }
  // #end LMS_Loan_Closed_List

  // #GST_Detail
  public GST_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GST_Detail`,
      requestData
    );
  }
  // #end GST_Detail

  // #Get_Loan_Acc_Category_Details
  public Get_Loan_Acc_Category_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Loan_Acc_Category_Details`,
      requestData
    );
  }
  // #end Get_Loan_Acc_Category_Details

  // #region Upload Others doc
  public UploadOthersDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadOthersDoc`,
      requestData
    );
  }
  // #end Upload Others doc

  // #LMS_Get_DV_Voucher
  public LMS_Get_DV_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_DV_Voucher`,
      requestData
    );
  }
  // #end LMS_Get_DV_Voucher

  // #Get_Collection_Ex_By_UserId
  public Get_Collection_Ex_By_UserId(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Collection_Ex_By_UserId`,
      requestData
    );
  }
  // #end Get_Collection_Ex_By_UserId

  // #Get_Area_By_UserId
  public Get_Area_By_UserId(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Area_By_UserId`,
      requestData
    );
  }
  // #end Get_Area_By_UserId

  // #start Get_SubArea_By_UserId
  public Get_SubArea_By_UserId(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_SubArea_By_UserId`,
      requestData
    );
  }
  // #end Get_SubArea_By_UserId

  // #start LMS_Get_Assignment_DueList
  public LMS_Get_Assignment_DueList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Assignment_DueList`,
      requestData
    );
  }
  // #end LMS_Get_Assignment_DueList

  // #start LMS_Save_Assignment_CollectionEx
  public LMS_Save_Assignment_CollectionEx(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_Assignment_CollectionEx`,
      requestData
    );
  }
  // #end LMS_Save_Assignment_CollectionEx

  // #start LMS_Save_DueList
  public LMS_Save_DueList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_DueList`,
      requestData
    );
  }
  // #end LMS_Save_DueList

  // #start Customer_Get_DueList
  public Customer_Get_DueList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Customer_Get_DueList`,
      requestData
    );
  }
  // #end Customer_Get_DueList

  // #start LMS_Get_NotExists_Branch_By_Emp
  public LMS_Get_NotExists_Branch_By_Emp(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_NotExists_Branch_By_Emp`,
      requestData
    );
  }
  // #end LMS_Get_NotExists_Branch_By_Emp

  // #start LMS_Loan_Swap
  public LMS_Loan_Swap(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Loan_Swap`,
      requestData
    );
  }
  // #end LMS_Loan_Swap

  // #start Get_WithoutDeafult_ChargeHeads
  public Get_WithoutDeafult_ChargeHeads(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_WithoutDeafult_ChargeHeads`,
      requestData
    );
  }
  // #end Get_WithoutDeafult_ChargeHeads

  // #start Get_Income_Account
  public Get_Income_Account(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Income_Account`,
      requestData
    );
  }
  // #end Get_Income_Account

  // #start Get_Balance_Account
  public Get_Balance_Account(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Balance_Account`,
      requestData
    );
  }
  // #end Get_Balance_Account

  // #start ACC_CaseWise_Account_Balance
  public ACC_CaseWise_Account_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_CaseWise_Account_Balance`,
      requestData
    );
  }
  // #end ACC_CaseWise_Account_Balance

  // #start Acc_save_schedule_transaction
  public Acc_save_schedule_transaction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_save_schedule_transaction`,
      requestData
    );
  }
  // #end Acc_save_schedule_transaction

  // #start Acc_get_schedule_transaction_list
  public Acc_get_schedule_transaction_list(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_get_schedule_transaction_list`,
      requestData
    );
  }
  // #end Acc_get_schedule_transaction_list

  // #start ACC_Get_Schedule_Transaction_Detail
  public ACC_Get_Schedule_Transaction_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Get_Schedule_Transaction_Detail`,
      requestData
    );
  }
  // #end ACC_Get_Schedule_Transaction_Detail

  // #start ACC_Deactivate_Schedule_Transaction
  public ACC_Deactivate_Schedule_Transaction(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Deactivate_Schedule_Transaction`,
      requestData
    );
  }
  // #end ACC_Deactivate_Schedule_Transaction

  // #start ACC_Delete_Schedule_Voucher
  public ACC_Delete_Schedule_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Delete_Schedule_Voucher`,
      requestData
    );
  }
  // #end ACC_Delete_Schedule_Voucher

  // #start Save_Acc_Schedule_VoucherMaster
  public Save_Acc_Schedule_VoucherMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Acc_Schedule_VoucherMaster`,
      requestData
    );
  }
  // #end Save_Acc_Schedule_VoucherMaster

  // #region UploadAccountDoc
  public UploadAccountDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadAccountDoc`,
      requestData
    );
  }
  // #endregion

  //Acc_Save_Voucher
  public Acc_Save_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Save_Voucher`,
      requestData
    );
  }
  //end Acc_Save_Voucher

  // #start LMS_GetLatterDetailsViewModel
  public LMS_GetLatterDetailsViewModel(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_GetLatterDetailsViewModel`,
      requestData
    );
  }
  // #end ACC_Delete_Schedule_Voucher

  // #start LMS_Save_NOC_Detail
  public LMS_Save_NOC_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_NOC_Detail`,
      requestData
    );
  }
  // #end ACC_Delete_Schedule_Voucher

  // #start CRM_Request_Creation
  public CRM_Request_Creation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/CRM_Request_Creation`,
      requestData
    );
  }
  // #end CRM_Request_Creation

  // #start Get_ACC_LeadgerLoancharges_For_print
  public Get_ACC_LeadgerLoancharges_For_print(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ACC_LeadgerLoancharges_For_print`,
      requestData
    );
  }
  // #end Get_ACC_LeadgerLoancharges_For_print

  // #start LMS_CRM_Request_Task
  public LMS_CRM_Request_Task(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_CRM_Request_Task`,
      requestData
    );
  }
  // #end LMS_CRM_Request_Task

  // #start LMS_Get_CRM_Detail
  public LMS_Get_CRM_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_CRM_Detail`,
      requestData
    );
  }
  // #end LMS_Get_CRM_Detail

  // #start LMS_RECEIPT_BOOK
  public LMS_RECEIPT_BOOK(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_RECEIPT_BOOK`,
      requestData
    );
  }
  // #end LMS_RECEIPT_BOOK

  // #start LMS_Dashboard_CRM_Request_Task
  public LMS_Dashboard_CRM_Request_Task(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Dashboard_CRM_Request_Task`,
      requestData
    );
  }
  // #end LMS_Dashboard_CRM_Request_Task

  // #start ACC_Get_schedule_Master
  public ACC_Get_schedule_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ACC_Get_schedule_Master`,
      requestData
    );
  }
  // #end ACC_Get_schedule_Master

  // #start LMS_Save_PDCChequeDetails
  public LMS_Save_PDCChequeDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_PDCChequeDetails`,
      requestData
    );
  }
  // #end LMS_Save_PDCChequeDetails

  // #start LMS_Get_Payment_List_ByStatus
  public LMS_Get_Payment_List_ByStatus(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Payment_List_ByStatus`,
      requestData
    );
  }
  // #end LMS_Get_Payment_List_ByStatus

  // #start LMS_Get_Payment_Detail
  public LMS_Get_Payment_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Payment_Detail`,
      requestData
    );
  }
  // #end LMS_Get_Payment_Detail

  // #start LMS_Update_Payment_Disbursment
  public LMS_Update_Payment_Disbursment(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_Payment_Disbursment`,
      requestData
    );
  }
  // #end LMS_Update_Payment_Disbursment

  // #start Customer_Track
  public Customer_Track(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Customer_Track`,
      requestData
    );
  }
  // #end Customer_Track

  // #start LMS_Save_ENach_Request_Response
  public LMS_Save_ENach_Request_Response(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_ENach_Request_Response`,
      requestData
    );
  }
  // #end LMS_Save_ENach_Request_Response

  // #start Disbursment_CalculateEMIIRR
  public Disbursment_CalculateEMIIRR(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Disbursment_CalculateEMIIRR`,
      requestData
    );
  }
  // #end Disbursment_CalculateEMIIRR

  // #start LMS_ENach_Process
  public LMS_ENach_Process(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_ENach_Process`,
      requestData
    );
  }
  // #end LMS_ENach_Process

  // #start TransactionScheduling
  public TransactionScheduling(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/TransactionScheduling`,
      requestData
    );
  }
  // #end TransactionScheduling

  // #start ENach_Get_NachInfo
  public ENach_Get_NachInfo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ENach_Get_NachInfo`,
      requestData
    );
  }
  // #end ENach_Get_NachInfo

  // #start ENach_Pending_Status
  public ENach_Pending_Status(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ENach_Pending_Status`,
      requestData
    );
  }
  // #end ENach_Pending_Status

  // #start ENach_GetTransactionVerification
  public ENach_GetTransactionVerification() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/ENach_GetTransactionVerification`,
      null
    );
  }
  // #end ENach_GetTransactionVerification

  // #start Report_Lending_Eligibility
  public Report_Lending_Eligibility(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Report_Lending_Eligibility`,
      requestData
    );
  }
  // #end Report_Lending_Eligibility

  // #start Loan_Lending_List
  public Loan_Lending_List() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Loan_Lending_List`,
      null
    );
  }
  // #end Loan_Lending_List

  // #start Get_LendingList
  public Get_LendingList() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_LendingList`,
      null
    );
  }
  // #end Get_LendingList

  // #start Report_Lending_Eligibility
  public Save_Loan_Assign_Lending(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Loan_Assign_Lending`,
      requestData
    );
  }
  // #end Save_Loan_Assign_Lending

  // #region LMS_Get_Loan_No_Mannual
  public LMS_Get_Loan_No_Mannual(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_No_Mannual`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Save_BulkClearing_Process
  public LMS_Save_BulkClearing_Process(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_BulkClearing_Process`,
      requestData
    );
  }
  // #endregion

  // #endregion LMS_Get_Loan_No_Mannual

  // #start Delete_AssetInfo
  public Delete_AssetInfo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_AssetInfo`,
      requestData
    );
  }
  // #end Delete_AssetInfo

  // #start Search_For_Loan_Delete_Request
  public Search_For_Loan_Delete_Request(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Search_For_Loan_Delete_Request`,
      requestData
    );
  }
  // #end Search_For_Loan_Delete_Request

  // #start Request_For_Loan_Delete
  public Request_For_Loan_Delete(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Request_For_Loan_Delete`,
      requestData
    );
  }
  // #end Request_For_Loan_Delete

  // #start Authorized_For_Loan_Delete
  public Authorized_For_Loan_Delete(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Authorized_For_Loan_Delete`,
      requestData
    );
  }
  // #end Authorized_For_Loan_Delete

  // #start Loan_Delete_Or_Rejected
  public Loan_Delete_Or_Rejected(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Loan_Delete_Or_Rejected`,
      requestData
    );
  }
  // #end Loan_Delete_Or_Rejected

  // #start Delete_CustomerMember
  public Delete_CustomerMember(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_CustomerMember`,
      requestData
    );
  }
  // #end Delete_CustomerMember

  // #start Loan_Delete_Status_List
  public Loan_Delete_Status_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Loan_Delete_Status_List`,
      requestData
    );
  }
  // #end Loan_Delete_Status_List

  // #start Get_BankDetail_Customer_Partner_Account
  public Get_BankDetail_Customer_Partner_Account(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_BankDetail_Customer_Partner_Account`,
      requestData
    );
  }
  // #end Get_BankDetail_Customer_Partner_Account

  // #start Get_Payment_Detail_By_Loan
  public Get_Payment_Detail_By_Loan(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Payment_Detail_By_Loan`,
      requestData
    );
  }
  // #end Get_Payment_Detail_By_Loan

  // #start Save_Disbursment_Payment_Detail
  public Save_Disbursment_Payment_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Disbursment_Payment_Detail`,
      requestData
    );
  }
  // #end Save_Disbursment_Payment_Detail

  // #start LMS_CaseMatch_Nach_Import
  public LMS_CaseMatch_Nach_Import(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_CaseMatch_Nach_Import`,
      requestData
    );
  }
  // #end LMS_CaseMatch_Nach_Import
  // #start LMS_Case_Save_Voucher_Nach_Import
  public LMS_Case_Save_Voucher_Nach_Import(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Case_Save_Voucher_Nach_Import`,
      requestData
    );
  }
  // #end LMS_Case_Save_Voucher_Nach_Import

  // #start Get_Disbursment_Payment_Detail
  public Get_Disbursment_Payment_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Disbursment_Payment_Detail`,
      requestData
    );
  }
  // #end Get_Disbursment_Payment_Detail

  // #start Update_Disbursment_Payment_Hold_UnHold
  public Update_Disbursment_Payment_Hold_UnHold(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Update_Disbursment_Payment_Hold_UnHold`,
      requestData
    );
  }
  // #end Update_Disbursment_Payment_Hold_UnHold

  // #start LMS_Get_PaymentInitiation_List
  public LMS_Get_PaymentInitiation_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_PaymentInitiation_List`,
      requestData
    );
  }
  // #end LMS_Get_PaymentInitiation_List
  // #start Get_Payment_Initiation_Detail
  public Get_Payment_Initiation_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Payment_Initiation_Detail`,
      requestData
    );
  }
  // #end Get_Payment_Initiation_Detail

  // #start Update_Payment_Disbursment_Voucher
  public Update_Payment_Disbursment_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Update_Payment_Disbursment_Voucher`,
      requestData
    );
  }
  // #end Update_Payment_Disbursment_Voucher

  // #start lms_Check_InstallmentAmount
  public lms_Check_InstallmentAmount(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/lms_Check_InstallmentAmount`,
      requestData
    );
  }
  // #end lms_Check_InstallmentAmount

  // #start LMS_Get_EMI_Allocation
  public LMS_Get_EMI_Allocation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_EMI_Allocation`,
      requestData
    );
  }
  // #end LMS_Get_EMI_Allocation

  // #start LMS_Update_Loan_EMIAllocation
  public LMS_Update_Loan_EMIAllocation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_Loan_EMIAllocation`,
      requestData
    );
  }
  // #end LMS_Update_Loan_EMIAllocation

  // #start Report_NPA_Marking
  public Report_NPA_Marking(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Report_NPA_Marking`,
      requestData
    );
  }
  // #end Report_NPA_Marking

  // #start NPA_View_Acc_Voucher
  public NPA_View_Acc_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/NPA_View_Acc_Voucher`,
      requestData
    );
  }
  // #end NPA_View_Acc_Voucher

  // #start NPA_Save_Acc_Voucher
  public NPA_Save_Acc_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/NPA_Save_Acc_Voucher`,
      requestData
    );
  }
  // #end NPA_Save_Acc_Voucher

  // #start LMS_Get_Loan_Card_Info
  public LMS_Get_Loan_Card_Info(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Card_Info`,
      requestData
    );
  }
  // #end LMS_Get_Loan_Card_Info

  // #start ACC_Get_ChargesHead_Amount_Details
  public ACC_Get_ChargesHead_Amount_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/ACC_Get_ChargesHead_Amount_Details`,
      requestData
    );
  }
  // #end ACC_Get_ChargesHead_Amount_Details

  // #start LMS_Receipt_Import_Data
  public LMS_Receipt_Import_Data(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Receipt_Import_Data`,
      requestData
    );
  }
  // #end LMS_Receipt_Import_Data
  // #start Acc_Save_Import_Receipt_Voucher
  public Acc_Save_Import_Receipt_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Save_Import_Receipt_Voucher`,
      requestData
    );
  }
  // #end Acc_Save_Import_Receipt_Voucher

  // #start LMS_List_Of_Repossess
  public LMS_List_Of_Repossess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_List_Of_Repossess`,
      requestData
    );
  }
  // #end LMS_List_Of_Repossess
  // #start Get_After_Reposess_Balance
  public Get_After_Reposess_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_After_Reposess_Balance`,
      requestData
    );
  }
  // #end Get_After_Reposess_Balance

  // #start Repossess_Save_Voucher
  public Repossess_Save_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Repossess_Save_Voucher`,
      requestData
    );
  }
  // #end Repossess_Save_Voucher

  // #start Save_CustomerOutSide
  public Save_CustomerOutSide(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/SaveCustomerOutside `,
      requestData
    );
  }
  // #end Repossess_Save_Voucher
  // #start LMS_Update_PDD_Detail
  public LMS_Update_PDD_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_PDD_Detail `,
      requestData
    );
  }
  // #end Repossess_Save_Voucher
  // #start Release_Request_Detail_Save
  public Release_Request_Detail_Save(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Release_Request_Detail_Save `,
      requestData
    );
  }
  // #end Release_Request_Detail_Save
  // #start Get_Loan_Chat
  public Get_Loan_Chat(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Loan_Chat `,
      requestData
    );
  }
  // #end Check_Cash_Receipt_and_Payment_Validation
  public Check_Cash_Receipt_and_Payment_Validation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Check_Cash_Receipt_and_Payment_Validation `,
      requestData
    );
  }
  // #end Check_Cash_Receipt_and_Payment_Validation

  // start GST Receipt
  public GSTReceiptDetails(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Get_Receipt_print_Detail `,
      requestData
    );
  }
  //End GST Receipt Details

  // #end Partner_Detail
  public Partner_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Partner_Detail `,
      requestData
    );
  }
  // #end Check_Cash_Receipt_and_Payment_Validation
  // #end Acc_Save_Receipt_Voucher
  public Acc_Save_Receipt_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Save_Receipt_Voucher `,
      requestData
    );
  }
  // #end Acc_Save_Receipt_Voucher

  // start Get_Account_Balance
  public Get_Account_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Get_Account_Balance `,
      requestData
    );
  }
  //End Get_Account_Balance

  // start Get_Account_Balance
  public LMS_Receipt_detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Receipt_detail `,
      requestData
    );
  }
  //End Get_Account_Balance

  // #region LMS_LMS_Get_Loan_Detail_with_Waiver
  public LMS_Get_Loan_Detail_with_Waiver(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Detail_with_Waiver`,
      requestData
    );
  }
  // #endregion

  // #region LMS_LMS_Get_Loan_Detail_with_Waiver
  public Get_Charges_Head_For_Waiver_Dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Charges_Head_For_Waiver_Dropdown`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Duplication_Vehicle
  public LMS_Get_Duplication_Vehicle(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Duplication_Vehicle`,
      requestData
    );
  }
  // #endregion

  // #region Save_Waiver_Created
  public Save_Waiver_Created(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Waiver_Created`,
      requestData
    );
  }
  // #endregion

  // #region Get_Waiver_List_By_Status
  public Get_Waiver_List_By_Status(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Waiver_List_By_Status`,
      requestData
    );
  }
  // #endregion

  // #region Get_Waiver_Employee_Upper_Level
  public Get_Waiver_Employee_Upper_Level(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Waiver_Employee_Upper_Level`,
      requestData
    );
  }
  // #endregion
  // #region Get_Waiver_Detail_By_Id
  public Get_Waiver_Detail_By_Id(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Waiver_Detail_By_Id`,
      requestData
    );
  }
  // #endregion

  // #region Save_Waiver_Authorized
  public Save_Waiver_Authorized(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Save_Waiver_Authorized`,
      requestData
    );
  }
  // #endregion

  // #region GetPDCProcess
  public GetPDCProcess(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GetPDCProcess`,
      requestData
    );
  }
  // #endregion
  // #region GePendingPDCCheque
  public GePendingPDCCheque(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/GePendingPDCCheque`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_NextEMI
  public LMS_Get_NextEMI(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_NextEMI`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Saved_Is_Noc_Block
  public LMS_Saved_Is_Noc_Block(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Saved_Is_Noc_Block`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Get_Loan_WriteOff_Settled_List
  public LMS_Get_Loan_WriteOff_Settled_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_WriteOff_Settled_List`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Loan_WriteOff_Settled_Undo
  public LMS_Loan_WriteOff_Settled_Undo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Loan_WriteOff_Settled_Undo`,
      requestData
    );
  }
  // #endregion
  // #LMS_Get_PDC_Cheque_Details
  public LMS_Get_PDC_Cheque_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_PDC_Cheque_Details`,
      requestData
    );
  }
  // #end LMS_Get_PDC_Cheque_Details

  // #LMS_CollectionExecuitive_Check_Import
  public LMS_CollectionExecuitive_Check_Import(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_CollectionExecuitive_Check_Import`,
      requestData
    );
  }
  // #end LMS_CollectionExecuitive_Check_Import

  // #LMS_Save_CollectionExecuitive_Import
  public LMS_Save_CollectionExecuitive_Import(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_CollectionExecuitive_Import`,
      requestData
    );
  }
  // #end LMS_Save_CollectionExecuitive_Import

  // #region FetchCivilData
  public FetchCivilData(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/FetchCivilData`,
      requestData
    );
  }
  // #endregion

  // #LMS_Nach_PDC_List
  public LMS_Nach_PDC_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Nach_PDC_List`,
      requestData
    );
  }
  // #end LMS_Nach_PDC_List

  // #start ACC_CaseWise_Account_Balance_Loan
  public ACC_CaseWise_Account_Balance_Loan(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/ACC_CaseWise_Account_Balance_Loan`,
      requestData
    );
  }
  // #end ACC_CaseWise_Account_Balance_Loan

  // #region #SaveInsuranceBranchMaster
  public Release_Update_ParkingYard(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Release_Update_ParkingYard`,
      requestData
    );
  }

  // #region #SaveInsuranceBranchMaster
  public LMS_Get_Loan_Balance_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Balance_Detail`,
      requestData
    );
  }

  // #region #SaveInsuranceBranchMaster
  public Get_Vehicle_Model_Grid(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Vehicle_Model_Grid`,
      requestData
    );
  }

  // #Save_Vehicle_Model_Grid
  public Save_Vehicle_Model_Grid(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Vehicle_Model_Grid`,
      requestData
    );
  }
  // #end Save_Vehicle_Model_Grid

  // #region #SaveInsuranceBranchMaster
  public Get_Vehicle_Model_Grid_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Vehicle_Model_Grid_List`,
      requestData
    );
  }

  // #start LMS_Get_AU_NachStatus
  public LMS_Get_AU_NachStatus(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_AU_NachStatus`,
      requestData
    );
  }
  // #end LMS_Get_AU_NachStatus

  // #start AU_Mandate_RegistrationEnquirybyrefId
  public AU_Mandate_RegistrationEnquirybyrefId(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/AU_Mandate_RegistrationEnquirybyrefId`,
      requestData
    );
  }
  // #end AU_Mandate_RegistrationEnquirybyrefId

  // #start LMS_Save_AU_ENachInfo
  public LMS_Save_AU_ENachInfo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_AU_ENachInfo`,
      requestData
    );
  }
  // #end LMS_Save_AU_ENachInfo

  // #start Get_KFS_Statement
  public Get_KFS_Statement(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_KFS_Statement`,
      requestData
    );
  }
  // #end Get_KFS_Statement

  // #start LMS_Get_Nach_Swap_Source
  public LMS_Get_Nach_Swap_Source(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Nach_Swap_Source`,
      requestData
    );
  }
  // #end LMS_Get_Nach_Swap_Source

  // #start LMS_Update_Nach_Swap_Source
  public LMS_Update_Nach_Swap_Source(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Update_Nach_Swap_Source`,
      requestData
    );
  }
  // #end LMS_Update_Nach_Swap_Source

  // #start Lms_Get_Nach_Au_Detail
  public Lms_Get_Nach_Au_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Lms_Get_Nach_Au_Detail`,
      requestData
    );
  }
  // #end Lms_Get_Nach_Au_Detail

  // #region FetchTransunionDecisionCentreData
  public FetchTransunionDecisionCentreData(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/FetchTransunionDecisionCentreData`,
      requestData
    );
  }
  // #endregion

  // #start LMS_Get_Nupay_NachStatus
  public LMS_Get_Nupay_NachStatus(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Nupay_NachStatus`,
      requestData
    );
  }
  // #end LMS_Get_Nupay_NachStatus

  // #start Nupay_Mandate_Registration_CheckStatus
  public Nupay_Mandate_Registration_CheckStatus(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/Nupay_Mandate_Registration_CheckStatus`,
      requestData
    );
  }
  // #end Nupay_Mandate_Registration_CheckStatus

  // #start LMS_Save_Nupay_ENachInfo
  public LMS_Save_Nupay_ENachInfo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_Nupay_ENachInfo`,
      requestData
    );
  }
  // #end LMS_Save_Nupay_ENachInfo

  // #start FetchTransunionDecisionDocument
  public FetchTransunionDecisionDocument(requestData: any) {
    const headers = new HttpHeaders().set("Accept", "application/pdf");
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyApi/FetchTransunionDecisionDocument`,
      requestData,
      { headers, responseType: "blob" }
    );
  }

  // #start Get_LMS_Loan_Restructure_Charges_Detail
  public Get_LMS_Loan_Restructure_Charges_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_LMS_Loan_Restructure_Charges_Detail`,
      requestData
    );
  }
  // #end Get_LMS_Loan_Restructure_Charges_Detail
  // #end FetchTransunionDecisionDocument

  // #start Get_Cibil_Data_Femina
  // public Get_Cibil_Data_Femina(requestData: any) {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer lOLj9ZQWFVihkPa4YeHds14TWYLbwTspHsj7IxI00OwP3M5hJkVH7AuZnmA6hB2Xj3QnlyDBepSUC1xdtWePN1eFfe9swu9QCVyLDFUl-hmFxketabyq3cxHES4ui8n3Jn_K6_3JFbPJr50OeMIYCSb1S2A6f6eeQwYBV8um6hSOXIQggJLCCVyhkKOMJ8pTEG3gUla3Us_APjXsMpbkkHP3fFHec0RiGuZfbrDjIn7WTDT7cnThXyf3hEkDxCJt8r_P6rAuAeGREhOwXwVBE2LamTm38fvwfcnNS7NBxnTJLAc-TLmEm7OvBvRYNokcDm4389mAFiO_J-NYL2WIR99QFHA3Zvni4xc83ItuJK47r7bK77iSmiDeLq-KzbBT`,
  //   });

  //   return this.http.get(
  //     "https://cors-anywhere.herokuapp.com/https://www.test.transuniondecisioncentre.co.in/DC/TUCL/TU.DE.Pont/Documents/15984469",
  //     {
  //       headers,
  //       responseType: "blob",
  //     }
  //   );
  // }
  // // #end Get_Cibil_Data_Femina

  // #start LMS_Get_Generate_Amortization_AfterPayment_List
  public LMS_Get_Generate_Amortization_AfterPayment_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Generate_Amortization_AfterPayment_List`,
      requestData
    );
  }
  // #end LMS_Get_Generate_Amortization_AfterPayment_List

  // #start LMS_Generate_Pending_Amortization
  public LMS_Generate_Pending_Amortization(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Generate_Pending_Amortization`,
      requestData
    );
  }
  // #end LMS_Generate_Pending_Amortization

  // #start LMS_Get_Disbursement_Loan_Detail
  public LMS_Get_Disbursement_Loan_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Disbursement_Loan_Detail`,
      requestData
    );
  }
  // #end LMS_Get_Disbursement_Loan_Detail

  // #start LMS_PreEMI_Interest_Statement_Loan
  public LMS_PreEMI_Interest_Statement_Loan(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_PreEMI_Interest_Statement_Loan`,
      requestData
    );
  }
  // #end LMS_PreEMI_Interest_Statement_Loan

  // #start DueList_PreEMI_Interest
  public DueList_PreEMI_Interest(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/DueList_PreEMI_Interest`,
      requestData
    );
  }
  // #end DueList_PreEMI_Interest

  // #start LMS_Receipt_Book_By_Team
  public LMS_Receipt_Book_By_Team(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Receipt_Book_By_Team`,
      requestData
    );
  }
  // #end LMS_Receipt_Book_By_Team

  // #start LMS_Receipt_Book_By_Team
  public AU_Mandate_authTokenGenerate({}) {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/LMS/AU_Mandate_authTokenGenerate`
    );
  }
  // #end AU_Mandate_authTokenGenerate

  // #start Get_Acc_UnAccount_Voucher
  public Get_Acc_UnAccount_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_Acc_UnAccount_Voucher`,
      requestData
    );
  }
  // #end Get_Acc_UnAccount_Voucher

  // #start Get_ChargesHeads_ForUnAccountVoucher
  public Get_ChargesHeads_ForUnAccountVoucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Get_ChargesHeads_ForUnAccountVoucher`,
      requestData
    );
  }
  // #end Get_ChargesHeads_ForUnAccountVoucher

  // #start Acc_Save_UnAccount_Voucher
  public Acc_Save_UnAccount_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Acc_Save_UnAccount_Voucher`,
      requestData
    );
  }
  // #end Acc_Save_UnAccount_Voucher
  // #start Delete_Acc_UnAccount_Voucher
  public Delete_Acc_UnAccount_Voucher(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/Delete_Acc_UnAccount_Voucher`,
      requestData
    );
  }
  // #end Delete_Acc_UnAccount_Voucher

  // #region LMS_Get_Loan_Closer_Due_Detail_New
  public LMS_Get_Loan_Closer_Due_Detail_New(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_Loan_Closer_Due_Detail_New`,
      requestData
    );
  }
  // #endregion
  // #region LMS_Save_Loan_Closer_RequestNew
  public LMS_Save_Loan_Closer_RequestNew(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_Loan_Closer_RequestNew`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Save_InsuranceClaim_Theft_Loass
  public LMS_Save_InsuranceClaim_Theft_Loass(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Save_InsuranceClaim_Theft_Loass`,
      requestData
    );
  }
  // #endregion

  // #region LMS_Get_InsuranceClaim_Theft_Loass
  public LMS_Get_InsuranceClaim_Theft_Loass(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Get_InsuranceClaim_Theft_Loass`,
      requestData
    );
  }
  // #endregion



   // #region LMS_Delete_InsuranceClaim_Theft_Loass
   public LMS_Delete_InsuranceClaim_Theft_Loass(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Delete_InsuranceClaim_Theft_Loass`,
      requestData
    );
  }
  // #endregion
  // #region   Save_Acc_VoucherMasterWithoutRateLimit

  public Save_Acc_VoucherMasterWithoutRateLimit(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LOS/Save_Acc_VoucherMasterWithoutRateLimit`,
      requestData
    );
  }
  // #endregion

  // #region FetchCrifData
  public FetchCrifData(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/FetchCrifData`,
      requestData, { responseType: 'text' }
    );
  }
  // #endregion

}
