import { Component, OnInit, ViewChild, ElementRef, } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { LmsService } from "../services/lms.service";
import { ReportsService } from "../../_Reports/services/reports.service";
import { DatePipe } from "@angular/common";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { MasterService } from "../../Shared/app.Masters.Service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from '../../Shared/dialog.service';
import { LosService } from '../../_LOS/services/los.service';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
declare var $: any;
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-lms-unaccounting-voucher",
  templateUrl: "./lms-unaccounting-voucher.component.html",
  styleUrls: ["./lms-unaccounting-voucher.component.scss"],
})
export class LmsUnaccountingVoucherComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  showSpinner: boolean = false;
  currentUser: any;
  BranchesList: any = [];
  ProductList: any = [];
  FromDate: any;
  ToDate: any;
  loading: boolean = false;
  LoanNo: any;
  SelectBranch: any = "";
  SelectProduct: any = "";
  dataSource: any;
  IsActive: boolean = false;
  displayedColumns: string[] = [];
  FyearId: any;
  maxDate: any;
  minDate: any;
  stateDropdown: any[] = [];
  selectedId: any;
  JsonData: any[] = [];
  Voucher_Date = new Date();
  TranType: any;
  dataSourceNotValiddata: any;
  Customer: any = {};

  InValidData: boolean = false;
  AccountingSelect: any = {};

  GSTModel: any = {};
  displayImportData: string[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };

  chargesHeadDropdown: any = [];
  accountHeadDropdown: any[] = [];
  slabList: any[] = [];
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatPaginator) paginatorModal: MatPaginator;
  selectedSearchIndex: any;
  selectedData: any = {};
  CustomerList: any;

  totalCalculator: any;

  dataSourceModal: any = new MatTableDataSource([]);
  SelectType: any = "";

  Bind_Voucher: any = 1;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  IsGSTEnable: any;
  currentindex: any;
  Matchhdata: any;
  BlankDataFilldata: any;
  dataSourceBlankDataFilldata: any;
  ImportBulkUnAccountVoucher: any;
  file: any;
  arrayBuffer: any;
  InValidDataInDataBase: any = 0;
  constructor(private losService: LosService,
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private _MasterService: MasterService,
    private dialog: DialogService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }
  displayedColumnsModal: string[] = [
    "CustomerId",
    "Type",
    "Application_No",
    "LoanAcNo",
    "CustomertName",
    "FatherName",
    "Customer_Gender",
    "GenderAge",
    "PhoneNo",
    "EditAction",
  ];

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Unaccounting Voucher");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          //this.DueDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));

          if (this.maxDate >= new Date() && this.minDate <= new Date()) {
            this.ToDate = new Date();
            this.FromDate = new Date();
          } else {
            this.ToDate = this.maxDate;
            this.FromDate = this.maxDate;
          }
          this.dataSource = null;
        });
    });
    this.getBranchesList();
    this.getProductDropdown();
    this.GetStateDropdown();
    this.Get_TaxSlab_Dropdown();
    // this.addUncountedVoucher();

    this.displayedColumns = [
      "Id",
      "LoanAcNo",
      "Product_Name",
      "Branch_Name",
      "CustomerName",
      "voucher_Date",
      "CreatedBy",
      "ChagesHead",
      "Amount",
      "TransType",
      "Narration",
    ];
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }
  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
        console.log("Result ", result);
      });
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result: any) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
    console.log("this.productList", this.ProductList);
  }

  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;
      console.log("this.slabList", this.slabList);
      this.slabList = this.slabList.reduce((acc, current) => {
        const x = acc.find(
          (item) => item.TaxSlab_Name === current.TaxSlab_Name
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    });
  }

  onSearchUnaccoutedVoucher() {
    if (this.IsActive == true) {
      this.displayedColumns = [
        "Id",
        "LoanAcNo",
        "Product_Name",
        "Branch_Name",
        "CustomerName",
        "voucher_Date",
        "CreatedBy",
        "deletedBy",
        "ChagesHead",
        "Amount",
        "TransType",
        "Narration",
      ];
    } else {
      this.displayedColumns = [
        "Id",
        "LoanAcNo",
        "Product_Name",
        "Branch_Name",
        "CustomerName",
        "voucher_Date",
        "CreatedBy",
        "ChagesHead",
        "Amount",
        "TransType",
        "Narration",
        "Delete"
      ];
    }
    this.showSpinner = true;
    this.lmsService
      .Get_Acc_UnAccount_Voucher({
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        LoanNo: this.LoanNo,
        ProductId: this.SelectProduct || 0,
        Branchid: this.SelectBranch || 0,
        LoginUserId: this.currentUser.userId,
        IsActive: this.IsActive == true ? 0 : 1
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          console.log("Result", res);

          this.JsonData = JSON.parse(JSON.stringify(res));

          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          console.log("dataSource", this.dataSource);
          this.dataSource.sort = this.sort;

          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = null;
        }
      });

    this.showSpinner = false;
  }



  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      Loan_Id: "",
      ChargeHeadId: "",
      TranType: "CR",
      Amount: "",
      Type: "",
      GST_Type: "",
      GST_No: "",
      Party_AccountName: "",
      HSN_Code: "",
      Slab_Name: "",
      TaxSlab1: [],
      Tax_Per: "",
      SGST: "",
      CGST: "",
      IGST: "",
      SGST_AccountId: "",
      CGST_AccountId: "",
      IGST_AccountId: "",
      CustomerId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      Bind_Voucher: this.Bind_Voucher,
    });
  }

  addUncountedVoucher() {
    // this.initializeVoucher();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    $("#AddunaccountingVoucher").modal("show");
    $("#AddunaccountingVoucher").css("z-index", "1050");
  }

  searchCustomer(data: any, index: any) {
    this.selectedSearchIndex = index;
    // console.log("this.selectedSearchIndex", this.selectedSearchIndex);
    this.selectedData = data;
    // console.log("this.selectedData", this.selectedData);

    this.dataSourceModal = null;
    // console.log();
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    // setTimeout(() => {
    //   $("#SearchValue").focus();
    // }, 100);
  }

  OnCloseSearchModal() {
    $("#CustomerReceipt").modal("hide");
  }

  Get_Acc_Search_LoanNo() {
    this.displayedColumnsModal = [
      "CustomerId",
      "Type",
      "Application_No",
      "LoanAcNo",
      "CustomertName",
      "FatherName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "EditAction",
    ];

    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));
        // if (this.Type != "0") {
        //   var type;
        //   if (this.Type == "1") type = "LOS";
        //   else type = "LMS";
        //   this.CustomerList = this.CustomerList.filter(
        //     (item) => item.Type == type
        //   );
        // }
        this.dataSourceModal = new MatTableDataSource(this.CustomerList);
        this.dataSourceModal.sort = this.sort;
        this.dataSourceModal.paginator = this.paginatorModal;
        this.showSpinner = false;
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  selectSearchData(data: any) {
    console.log("Row data of modal table", data);
    if (data.LoanAcNo != "" && data.LoanAcNo != null) {
      this.selectedData.Loan_Id = data.LoanAcNo;
    } else {
      this.selectedData.Loan_Id = data.Application_No;
    }

    // this.selectedData.LoanAccountingId = data.LoanAccountingId;

    this.selectedData.Customer = data.CustomertName;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.LoanId = data.LoanId;
    // this.selectedData.BranchId = data.BranchId;
    // this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.Is_LOSAppliaction = data.Type == "LOS" ? "1" : "0";
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.selectedData.GST_No = data.GST_No;
    this.selectedData.StateId = data.StateId;
    this.selectedData.Branch_StateId = data.Branch_StateId;
    this.selectedData.Application_CreateOn = new Date(data.Application_CreateOn);
    this.getChargeshead();
    this.OnCloseSearchModal();
    console.log("Voucher detail", this.voucherModel)
  }

  getChargeshead() {
    this.lmsService
      .Get_ChargesHeads_ForUnAccountVoucher({
        productId: this.selectedData.ProductId,
      })
      .subscribe((res: any) => {
        // console.log("Charges HEAD iD", res);
        this.chargesHeadDropdown = res;
      });
  }

  onChangeChargesHead(data: any, dindex: any) {
    console.log("Data value", data);

    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: "Loan",
        ProductId: data.ProductId,
        TaxType: data.TranType,
      })
      .subscribe((res: any) => {
        console.log("res : ", res);

        data.ChargeHeadCategory = res.Item1[0].AccountName;
        console.log("charges head category", data.ChargeHeadCategory);
        console.log("this.accountHeadDropdown", this.accountHeadDropdown);
        data.IsGSTEnable = res.Item1[0].IsGSTEnable;
        data.AccountId = res.Item1[0].AccountId;
        // if (res.Item1[0]) {
        //   let _findA = this.accountHeadDropdown.find((obj: any) => {
        //     return obj.AccountId == res.Item1[0].AccountId;
        //   });
        //   if (_findA) {


        //     if (this.voucherModel.Voucher.Voucher_Sub_Type == "Accounting") {
        //       data.ChargeHeadId = res.Item1[0].AccountId;
        //     }

        //     data.ChargeHeadCategory = res.Item1[0].AccountName;
        //     data.TaxSlab = res.Item2;
        //   } else {
        //     this.snackBar.openFromComponent(SnackbarComponent, {
        //       data: "Account head not found.",
        //       ...this.configSuccess,
        //     });
        //   }
        // } else {
        //   this.snackBar.openFromComponent(SnackbarComponent, {
        //     data: "No data Found.",
        //     ...this.configSuccess,
        //   });
        // }
      });
  }

  onChangeAmount(event: any, data: any, dindex: any) {
    if (event) {
      event.preventDefault();
    }
    if (data.IsGSTEnable == 1) {

      this.currentindex = dindex;
      console.log("this.currentindex", this.currentindex);


      $("#GSTModalWindow").modal("show");
      $("#GSTModalWindow").css("z-index", "1050");

      this.GSTModel = {
        Slab_Tax: '',
        AccountHeadId: '',
        PartyType: 'Existing_Party',
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        StateId: this.selectedData.StateId,
        Charge_head: data.ChargeHeadCategory,
        Amount: data.Amount,
        TranType: data.TranType,
      };

    }

  }



  OnCloseGSTModal() {
    $("#GSTModalWindow").modal("hide");
    this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
    this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};
    this.voucherModel.Voucher_Detail[this.currentindex].IsGSTEnable = 0;
  }


  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherDetail();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    // this.getTotalCalculation();
    // this.Tax_Slab_Data = null;
  }

  OnCloseSearch() {
    $("#AddunaccountingVoucher").modal("hide");
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public highlightRow(row) {
    this.selectedId = row.LoanId;
  }

  exportexcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "export_unaccouting_voucher"
    ) as HTMLTableElement;
    const worksheetName = "UNACCOUNTING VOUCHER"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  saveGSTDetails() {
    $("#GSTModalWindow").modal("hide");
    //console.log("this.GSTModel", this.GSTModel);
    this.lmsService.Get_TaxSlab_Details({ TaxSlab: this.GSTModel.Slab_Tax }).subscribe((res: any) => {
      let Br_StateId, BranchName;

      Br_StateId = this.selectedData.Branch_StateId;
      BranchName = this.selectedData.Branch_Name;

      var Tax_Slab_Data = [];
      let Tax_Type = this.GSTModel.TranType == "DR" ? "Input" : "Output";
      if (Br_StateId == this.GSTModel.StateId) {
        Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'With in State');
      }
      else {
        Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'Inter state');
      }

      Tax_Slab_Data.forEach(x => {
        x.BranchName = BranchName;
        x.Amount = this.GSTModel.Amount;
        x.TaxAmount = (Number(this.GSTModel.Amount) * Number(x.TaxSlab_Rate) / 100).toFixed(2);

        // Search value (SGST) from a string 
        x.TypeGST = x.TaxSlab_Type.includes('SGST');
        x.TypeIGST = x.TaxSlab_Type.includes('IGST');
      });


      if (this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 != undefined) {
        this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
      }
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};

      this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = Tax_Slab_Data;
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = this.GSTModel;


    });
  }
  onPartyTypeChange() {
    if (this.GSTModel.PartyType == 'New_Party') {
      this.GSTModel = {
        PartyType: this.GSTModel.PartyType,
        Slab_Tax: '',
        AccountHeadId: '',
        Customer: '',
        Acc_GSTNo: '',
        StateId: '',
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
      }
    }
    else {
      this.GSTModel = {
        Slab_Tax: '',
        AccountHeadId: '',
        PartyType: this.GSTModel.PartyType,
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
        //StateId : this.BranchData.StateId,
      }
    }
  }


  saveVoucher() {

    let _voucheretail: any[] = [];

    this.voucherModel.Voucher_Detail.forEach((obj: any) => {

      console.log("this.voucherModel.Voucher_Detail", this.voucherModel.Voucher_Detail);
      var Tax_Per = '', SGST = 0, CGST = 0, IGST = 0, SGST_AccountId = 0, CGST_AccountId = 0, IGST_AccountId = 0;
      if (obj.TaxSlab1 != undefined) {
        obj.TaxSlab1.forEach((tobj: any) => {
          //_total = _total + parseFloat(tobj.TaxAmount);
          //_totalTX = _totalTX + parseFloat(tobj.TaxAmount);
          //_voucheretail.push(this.dataSharingService.cleanObject({
          //  VoucherId: obj.VoucherId,
          //  CaseNo: obj.CaseNo,
          //  CustomerId: obj.CustomerId,
          //  Is_LOSAppliaction: obj.Is_LOSAppliaction,
          //  ChargeHeadId: ChargeId,
          //  ChargeHeadCategory: tobj.TaxSlab_Type,
          //  AccountId: tobj.AccountId,
          //  BranchId: BranchId,
          //  TranType: obj.TranType,
          //  IsTax: 1,
          //  Tax_Per: tobj.TaxSlab_Rate,
          //  Amount: tobj.TaxAmount,
          //  Bind_Voucher: obj.Bind_Voucher,
          //  Type: ''
          //}))

          Tax_Per = tobj.TaxSlab_Rate;
          if (obj.TaxSlab1[0].TypeGST && tobj.TaxSlab_Type.includes('SGST')) {
            SGST = tobj.TaxAmount;
            SGST_AccountId = tobj.AccountId;
          }
          if (obj.TaxSlab1[0].TypeGST && tobj.TaxSlab_Type.includes('CGST')) {
            CGST = tobj.TaxAmount;
            CGST_AccountId = tobj.AccountId;
          }
          if (tobj.TypeIGST) {
            IGST = tobj.TaxAmount;
            IGST_AccountId = tobj.AccountId;
          }

        });
      }
      _voucheretail.push(this.dataSharingService.cleanObject({
        Loan_Id: this.selectedData.LoanId,
        ChargeHeadId: obj.ChargeHeadId,
        AccountId: obj.AccountId,
        TranType: obj.TranType,
        Amount: obj.Amount,
        Type: obj.TaxSlab1.length > 0 ? 'Output' : '',
        GST_Type: obj.TaxSlab1.length > 0 ? obj.GSTModel.PartyType : '',
        GST_No: obj.TaxSlab1.length > 0 ? obj.GSTModel.Acc_GSTNo : '',
        Party_AccountName: obj.TaxSlab1.length > 0 ? obj.GSTModel.Customer : '',
        HSN_Code: obj.TaxSlab1.length > 0 ? obj.GSTModel.HSNCode : '',
        Slab_Name: obj.TaxSlab1.length > 0 ? obj.GSTModel.Slab_Tax : '',
        Tax_Per: Tax_Per,
        SGST: SGST,
        CGST: CGST,
        IGST: IGST,
        SGST_AccountId: SGST_AccountId,
        CGST_AccountId: CGST_AccountId,
        IGST_AccountId: IGST_AccountId,
      }));
    });

    let _finalData = {
      Voucher_Detail: _voucheretail
    }
    console.log("_finalData :", _finalData);
    this.lmsService.Acc_Save_UnAccount_Voucher({ JSON: JSON.stringify(_finalData), Narration: this.voucherModel.Voucher.Voucher_Narration, VoucherDate: this.Voucher_Date, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

        //this.initializeVoucher();
        this.voucherModel.Voucher_Detail = [];
        this.voucherModel.Voucher = {};
        this.initializeVoucherDetail();
        this.onSearchUnaccoutedVoucher();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  onDeleteUnAccountVoucher(row) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService.Delete_Acc_UnAccount_Voucher({ Id: row.Id, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

              //this.initializeVoucher();
              this.voucherModel.Voucher_Detail = [];
              this.voucherModel.Voucher = {};
              this.initializeVoucherDetail();
              this.onSearchUnaccoutedVoucher();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          });
        }
      })
  }
  ExportTemplate() {

    var json = [
      [
        "LoanNo",
        "ChargesHeadId",
        "Voucher_Year",
        "Voucher_Month",
        "Voucher_Day",
        "Amount",
        "Narration"

      ],
    ];


    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "UnAccountVoucherImport");

  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  ExportChargesHead() {
    this.lmsService
      .Get_ChargesHeads_ForUnAccountVoucher({
        productId: 0,
      })
      .subscribe((res: any) => {
      if (res.length > 0) {
        //const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(result);
        //const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //this.saveAsExcelFile(excelBuffer, 'LocationMaster');
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res);
        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
          data,
          "Charges Head Master" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  }

  onCloseImport1Model() {
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceNotValiddata = null;
    this.ImportBulkUnAccountVoucher = null;

    $(`#ImportUnAccountVoucherModel`).modal("hide");
  }
  ImportTemplate(event) {
    this.showSpinner = true;
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceNotValiddata = null;
    this.ImportBulkUnAccountVoucher = null;
    this.InValidData = false;
    //this.InValidData = false;
    this.InValidDataInDataBase = 0;
    const target: HTMLInputElement = event.target as HTMLInputElement;

    this.file = target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.ImportBulkUnAccountVoucher = XLSX.utils.sheet_to_json(worksheet);

      if (this.ImportBulkUnAccountVoucher.length > 0) {
        this.ImportBulkUnAccountVoucher.forEach((x, index) => {
          if (
            x.Voucher_Day != "" &&
            x.Voucher_Day != undefined &&
            x.Voucher_Month != "" &&
            x.Voucher_Month != undefined &&
            x.Voucher_Year != "" &&
            x.Voucher_Year != undefined
          )
            x.Voucher_Date =
              x.Voucher_Year +
              "-" +
              x.Voucher_Month +
              "-" +
              x.Voucher_Day;
          else x.Voucher_Date = "";
          if (
            x.ChargesHeadId == "" ||
            x.ChargesHeadId == undefined ||
            x.LoanNo == "" || x.LoanNo == undefined ||
            x.Voucher_Day == "" || x.Voucher_Day == 0 || x.Voucher_Day == undefined ||
            x.Voucher_Month == "" || x.Voucher_Month == 0 || x.Voucher_Month == undefined ||
            x.Voucher_Year == "" || x.Voucher_Year == 0 || x.Voucher_Year == undefined ||
            x.Amount == "" || x.Amount == 0 || x.Amount == undefined ||
            x.Narration == "" || x.Narration == undefined) {
            this.displayImportData = [
              "LoanNo",
              "ChargesHeadId",
              "Voucher_Year",
              "Voucher_Month",
              "Voucher_Day",
              "Amount",
              "Narration"]

            this.BlankDataFilldata.push(x);

            this.dataSourceBlankDataFilldata = new MatTableDataSource(
              this.BlankDataFilldata
            );
            $(`#ImportUnAccountVoucherModel`).modal("show");
            $(`#ImportUnAccountVoucherModel`).css("z-index", "1090");
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill All  Fields !!!",
              ...this.configSuccess,
            });
          }
        })


        if (this.BlankDataFilldata.length == 0) {
          this.BlankDataFilldata = [];
          this.ImportBulkUnAccountVoucher.filter((item) => {


            if (
              item.Voucher_Year != "" &&
              item.Voucher_Year != undefined
            )
              this.NumberValidation(item.Voucher_Year, 4, item);
            if (
              item.Voucher_Year != "" &&
              item.Voucher_Year != undefined
            )
              this.isGreaterThan(item.Voucher_Year, item, 'Year');
            if (
              item.Voucher_Month != "" &&
              item.Voucher_Month != undefined
            )
              this.isGreaterThan(item.Voucher_Month, item,'Month');
            if (
              item.Voucher_Day != "" &&
              item.Voucher_Day != undefined
            )
              this.isGreaterThan(item.Voucher_Day, item,'Day');


          })

          if (this.InValidData == true) {
            console.log("this.BlankDataFilldata", this.BlankDataFilldata);
            this.displayImportData = ["LoanNo",
              "ChargesHeadId",
              "Voucher_Year",
              "Voucher_Month",
              "Voucher_Day",
              "Amount",
              "Narration"];


            this.dataSourceNotValiddata = new MatTableDataSource(
              this.BlankDataFilldata.slice().sort((a, b) => a.ChargesHeadId - b.ChargesHeadId)
            );
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill Valid Data !!!",
              ...this.configSuccess,
            });
            $(`#ImportUnAccountVoucherModel`).modal("show");
            $(`#ImportUnAccountVoucherModel`).css("z-index", "1090");
          } else {
            //call api
            this.displayImportData = [
              "LoanNo",
              "ChargesHeadId",
              "Voucher_Date",
              "Amount",
              "Narration"
            ];

            this.BlankDataFilldata = [];
            //in check database
            console.log("JSON.stringify(this.ImportBulkUnAccountVoucher)", JSON.stringify(this.ImportBulkUnAccountVoucher));
            this.losService
              .LMS_UnaccountVoucherMatch_Import({
                JSON: JSON.stringify(this.ImportBulkUnAccountVoucher),
              })
              .subscribe((res: any) => {
                if (res.length > 0) {
                  var _find = res.find((obj: any) => {
                    return obj.NotMach != "";
                  });

                  if (_find) {
                    this.InValidDataInDataBase = 1;
                    this.displayImportData = [
                      "LoanNo",
                      "ChargesHeadId",
                      "Voucher_Date",
                      "Amount",
                      "Narration",
                      "NotMach",
                    ];
                    var data = JSON.parse(JSON.stringify(res));
                    data.filter((item) => {
                      if (item.NotMach != "")
                        this.BlankDataFilldata.push(item);
                    });
                    this.dataSourceNotValiddata = new MatTableDataSource(
                      this.BlankDataFilldata.slice().sort(
                        (a, b) => a.ChargesHeadId - b.ChargesHeadId
                      )
                    );
                    $(`#ImportUnAccountVoucherModel`).modal("show");
                    $(`#ImportUnAccountVoucherModel`).css("z-index", "1090");
                    this.showSpinner = false;
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "Some Fields are Not Valid.Kindly Check the Error field in the Table!!!",
                      ...this.configSuccess,
                    });
                  } else {
                    this.InValidDataInDataBase = 2;
                    this.displayImportData = [
                      "LoanNo",
                      "ChargesHeadId",
                      "Voucher_Date",
                      "Amount",
                      "Narration",
                      "NotMach",
                    ];
                    this.dataSourceNotValiddata = new MatTableDataSource(
                      JSON.parse(JSON.stringify(res))
                        .slice()
                        .sort((a, b) => a.ChargesHeadId - b.ChargesHeadId)
                    );
                    $(`#ImportUnAccountVoucherModel`).modal("show");
                    $(`#ImportUnAccountVoucherModel`).css("z-index", "1090");
                    this.showSpinner = false;
                  }
                }
              });

          }
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Fill Un-Account Voucher Data!!!",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }


    }
    this.fileInput.nativeElement.value = "";
  }

  NumberValidation(phoneNumber: string, digit: number, row): boolean {
    phoneNumber = phoneNumber + "";
    const numericRegex = /^[0-9]+$/;
    if (numericRegex.test(phoneNumber)) {
      if (
        phoneNumber != undefined &&
        phoneNumber.replace(/\D/g, "").length === digit
      ) {
        return true;
      } else {
        if (
          phoneNumber != undefined &&
          phoneNumber != "" &&
          phoneNumber.replace(/\D/g, "").length != digit
        ) {
          this.InValidData = true;
          if (this.InValidData == true && row != null) {
            this.BlankDataFilldata.push(row);
          }
        }
        return false;
      }
    }
    if (
      phoneNumber != undefined &&
      phoneNumber != "" &&
      !numericRegex.test(phoneNumber)
    ) {
      this.InValidData = true;
    }
    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }

    return false;
  }
  isGreaterThan(NNumber: number, row, type) {
    if (type = 'Year' &&  NNumber > 199) {
      return true;
    }
    if (type = 'Month' && NNumber <= 12 && NNumber > 0) {
      return true;
    }
    if (type = 'Day' && NNumber <= 31 && NNumber > 0) {
      return true;
    }
      this.InValidData = true;

    if (this.InValidData == true && row != null) {
      this.BlankDataFilldata.push(row);
    }
    return false;
  }
  OnSaveImportData() {
    this.showSpinner = true;
    this.losService
      .LMS_Save_UnaccountVoucher_Import({
        JSON: JSON.stringify(this.ImportBulkUnAccountVoucher),
        LoginUserId: this.currentUser.userId 
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.showSpinner = false;
          this.Matchhdata = [];
          this.BlankDataFilldata = [];
          this.dataSourceBlankDataFilldata = null;
         // this.dataSourceDuplicate = null;
          this.dataSourceNotValiddata = null;
          this.ImportBulkUnAccountVoucher = null;
          //this.DuplicateData = false;
          this.InValidData = false;
          this.InValidDataInDataBase = 0;
          this.OnCloseImportUnAccountVoucherModel();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });

  }
  OnCloseImportUnAccountVoucherModel() {
    $("#ImportUnAccountVoucherModel").modal("hide");
  }
}
