import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";

@Component({
  selector: "app-mst-branch-employee",
  templateUrl: "./mst-branch-employee.component.html",
  styleUrls: ["./mst-branch-employee.component.scss"],
})
export class MstBranchEmployeeComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetBranchesRequestModel: RequestModel = new RequestModel();
  dataSource: any;
  displayedColumns: string[] = ["ProcessId", "Process", "Employee"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  FilterBranchId: any = "";
  FilterBranchList: any = [];
  productList: any[] = [];
  ProductId: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Branch Employee");
    this.GetBranchesRequestModel.DistrictId = 0;
    this.GetBranchesRequestModel.TehsilId = 0;
    this._MasterService
      .GetBranchesDropdown(this.GetBranchesRequestModel)
      .subscribe((result) => {
        this.FilterBranchList = JSON.parse(JSON.stringify(result));
      });
    this.getProductList();
  }

  getProductList() {
    this._MasterService.GetProductList({}).subscribe((result) => {
      this.productList = result;
    });
  }

  getBranchEmployeeList() {
    this.showSpinner = true;
    this.RequestModel.BranchId =
      this.FilterBranchId == "" ? 0 : parseInt(this.FilterBranchId);
    this.RequestModel.ProductId =
      this.ProductId == "" ? 0 : parseInt(this.ProductId);
    this._MasterService
      .GetProcesswisemployeeList(this.RequestModel)
      .subscribe((result) => {
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(result))
        );
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
}
