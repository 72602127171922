<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 align-items-end formborder">
    <div class="col-md-3 p-1">
      <span> Select Nature Of Work </span>
      <select
        name="FilterId"
        id="FilterId"
        (change)="getQuestionsList()"
        [(ngModel)]="FilterId"
        class="form-control input-text-css"
      >
        <option value="">Select Nature Of Work</option>
        <option *ngFor="let List of FilterList" [value]="List.Id">
          {{ List.Value }}
        </option>
      </select>
    </div>
    <div class="col-md-9 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="onOpenQuestionModal()"
      >
        Add New
      </button>
    </div>
  </div>
  <form #ddf="ngForm" (ngSubmit)="onSaveLevel()" novalidate>
    <div class="table-responsive mt-3">
      <mat-table
        [dataSource]="dataSource"
        matSort
        style="overflow: auto; max-width: 100%; height: 60vh"
      >
        <ng-container matColumnDef="Ques_Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Question">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Question
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Question }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Question_Hindi">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Question Hindi</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Question_Hindi }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="QuestionType">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Question Type
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.QuestionType }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Ques_Level">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Level
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 150px"
          >
            <input
              type="text"
              name="level{{ i }}"
              id="level{{ i }}"
              style="width: 60px; text-align: center !important"
              [(ngModel)]="row.Ques_Level"
              #refQues_Level="ngModel"
              class="form-control input-text-css text-right pr-3"
              required
              [ngClass]="{
                'is-invalid': ddf.submitted && refQues_Level.invalid,
                'alert-warning':
                  refQues_Level.invalid &&
                  (refQues_Level.dirty ||
                    refQues_Level.touched ||
                    refQues_Level.untouched)
              }"
            />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Is_Mandatory">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            Is_Mandatory
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px"
          >
            <mat-checkbox
              class="example-margin"
              name="IsSelected{{ i }}"
              id="IsSelected{{ i }}"
              [(ngModel)]="row.Is_Mandatory"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EditAction">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 100px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              (click)="onEditQuestion(row)"
              aria-label="true"
            ></i>
            <i
              class="fa fa-trash style-delete"
              *ngIf="
                row.Nature_Of_WorkIds == '' ||
                row.Nature_Of_WorkIds == null ||
                row.Nature_Of_WorkIds == undefined
              "
              (click)="onDeleteQuestion(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center" style="background: #fff">
        <div class="col-md-4">
          <button
            type="button"
            class="ml-4 font-size-12 button-btn"
            (click)="onSaveLevel()"
            [class.spinner]="loading"
            *ngIf="(FilterId == '' ? 0 : FilterId) > 0"
            [disabled]="!ddf.form.valid"
          >
            Save
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="addNewQuestion"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveQuestionData()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ HeaderText }} Question
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Question </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refQuestion="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refQuestion.invalid,
                  'alert-warning':
                    refQuestion.invalid &&
                    (refQuestion.dirty ||
                      refQuestion.touched ||
                      refQuestion.untouched)
                }"
                [(ngModel)]="Question"
                placeholder=" Question"
                name="Question"
                id="Question"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">Question Hindi</div>
            <div class="col-md-8 p-1">
              <input
                type="text"
                [(ngModel)]="QuestionHindi"
                placeholder=" Question Hindi"
                name="QuestionHindi"
                id="QuestionHindi"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Question Type</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="QuestionType"
                id="QuestionType"
                #refQuestionType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refQuestionType.invalid,
                  'alert-warning':
                    refQuestionType.invalid &&
                    (refQuestionType.dirty ||
                      refQuestionType.touched ||
                      refQuestionType.untouched)
                }"
                [(ngModel)]="QuestionType"
                (change)="onChangeQuestionType()"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Type</option>
                <option value="Boolean">Boolean</option>
                <option value="Integer">Integer</option>
                <option value="Text">Text</option>
                <option value="Selection">Selection</option>
                <option value="Multi-Selection">Multi-Selection</option>
                <option value="Upload">Upload</option>
              </select>
            </div>
          </div>
          <div
            *ngIf="
              QuestionType == 'Selection' || QuestionType == 'Multi-Selection'
            "
            class="row m-0"
          >
            <div class="col-md-4 p-0"></div>
            <div class="col-md-8 p-0">
              <div class="p-1 text-right" style="width: 100%">
                <i
                  class="fa fa-plus-circle font-size-15"
                  (click)="onAddNewRow()"
                  style="color: green; cursor: pointer"
                  aria-label="true"
                ></i>
              </div>
              <div
                *ngFor="let question of QuestionTypeOptions; let i = index"
                class="row m-0 font-size-12 align-items-center"
              >
                <div class="col-11 p-1">
                  <input
                    type="text"
                    [(ngModel)]="question.Questions_Option"
                    placeholder=""
                    name="question{{ i }}"
                    id="question{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-1 p-1">
                  <i
                    *ngIf="QuestionTypeOptions.length > 1"
                    (click)="onDeleteRow(i)"
                    class="fa fa-trash style-delete"
                    style="cursor: pointer"
                    aria-label="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 font-size-12">
            <div class="col p-1">
              <mat-checkbox
                id="SelectAll"
                name="SelectAll"
                class="example-margin font-size-12"
                (change)="SelectAll($event)"
                >Select All </mat-checkbox
              ><br />
              <div class="wrapper">
                <div *ngFor="let List of FilterList; let i = index">
                  <mat-checkbox
                    id="List{{ i }}"
                    name="List{{ i }}"
                    [(ngModel)]="List.isAcive"
                    class="example-margin font-size-12"
                  >
                    {{ List.Value }} </mat-checkbox
                  ><br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveQuestionData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Submitt
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
