import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { LmsService } from '../../_Lms/services/lms.service';
import * as moment from "moment";
import { DialogService } from '../../Shared/dialog.service';
import { QuickLoanService } from '../services/quickLoan.service';
declare var $: any;


@Component({
  selector: 'app-quickloan-customer-reference',
  templateUrl: './quickloan-customer-reference.component.html',
  styleUrls: ['./quickloan-customer-reference.component.scss']
})
export class QuickloanCustomerReferenceComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  isSaveCustomerRef: boolean = false;
  customerRefModel: any = {};
  @Input() loanSummary: any;
  @Input() Revert_IsEdit: any;
  dataCustomerRefSource: any;
  displayedCustomerRefColumns: string[] = ['Neighbour', 'Name', 'Mobile', 'Ref_Relation', 'Association', 'Address', 'CreatedOn', 'Action'];
  CustomerList: any;
  customerlist: any[] = [];


  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute, 
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService, 
    private _MasterService: MasterService,
    private router: Router, 
    private losService: LosService,
    private LmsService: LmsService,
    private dialog: DialogService,
    private _QuickLoanService: QuickLoanService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.LMS_GetLoanDetails();
    this.getCustomerRef();
  }

  EditCustomerRef(data: any) {
    this.isSaveCustomerRef = true;
    this.customerRefModel.SelectNeighbour = data.ReferenceType;
    this.customerRefModel.RefName = data.Name;
    this.customerRefModel.RefMobile = data.ContactNo;
    this.customerRefModel.RefRelation = data.Customer_Id;
    this.customerRefModel.Association = data.KnowYear;
    this.customerRefModel.Address = data.Address;
    this.customerRefModel.RefRemarks = data.Comments;
    this.customerRefModel.Int_Id = data.Int_Id;

    $('#ReferenceData').modal('show');
    $("#ReferenceData").css("z-index", "1050");
  }
  addCustomerRef() {
    $('#ReferenceData').modal('show');
    $("#ReferenceData").css("z-index", "1050");
  }
  onCloseReferenceData() {
    $('#ReferenceData').modal('hide');
  }

  onSaveCustomerRef(ref) {
    this.showSpinner = true;
    this.customerRefModel.Int_Id = 0;
    this.customerRefModel.CustomerId = (this.customerRefModel.RefRelation);
    this.customerRefModel.IsActive = 0;
    this.customerRefModel.Loan_Id = this.loanSummary.LoanId;
    this.customerRefModel.CreatedBy = this.currentUser.userId;

    this.LmsService.onSaveCustomerRef(this.customerRefModel).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        //ref.resetForm();
        this.customerRefModel = {};
        this.getCustomerRef();
        this.onCloseReferenceData();
        this.QuickLoan_Save_PageProcess('Customer Reference');
        this.action.emit("next");
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }
  
  onUpdateCustomerRef(ref) {
    this.customerRefModel.CustomerId = this.customerRefModel.RefRelation.toString();
    this.customerRefModel.IsActive = 0;
    this.customerRefModel.Loan_Id = this.loanSummary.LoanId;
    this.customerRefModel.CreatedBy = this.currentUser.userId;
    this.customerRefModel.RefRelation = this.customerRefModel.RefRelation.toString();
    console.log("this.customerRefModel", this.customerRefModel);
    
    this.LmsService.onSaveCustomerRef(this.customerRefModel).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.isSaveCustomerRef = false;
        //ref.resetForm();
        this.onCloseReferenceData();
        this.customerRefModel = {};
        this.getCustomerRef();
        this.QuickLoan_Save_PageProcess('Customer Reference');
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }


  getCustomerRef() {
    let data = {
      Loan_Id: this.loanSummary.LoanId, Int_Id: 0, LoginUserId: this.currentUser.userId
    }
    this.LmsService.LMS_GetCustomerRef(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.dataCustomerRefSource = new MatTableDataSource(res);
      this.customerRefModel.SelectNeighbour = 'Neighbour';
      this.customerRefModel.RefRelation = '';
      this.customerRefModel.Association = '';
    })
  }

  deleteCustomerRef(data) {
    let refdata = {
      Loan_Id: this.loanSummary.LoanId, Int_Id: data.Int_Id, LoginUserId: this.currentUser.userId
    }
    
    this.LmsService.LMS_GetCustomerRef(refdata).subscribe((res: any) => {
      this.showSpinner = false;
      this.getCustomerRef();
      this.QuickLoan_Save_PageProcess('Customer Reference');
    });
  }

  getCustomerRefDetails(data) {
    this.customerRefModel.Int_Id = data.Int_Id;
    this.customerRefModel.SelectNeighbour = data.ReferenceType;
    this.customerRefModel.RefName = data.Name;
    this.customerRefModel.RefMobile = data.ContactNo;
    this.customerRefModel.RefRelation = data.Customer_Id;
    this.customerRefModel.Association = data.KnowYear;
    this.customerRefModel.Address = data.Address;
    this.customerRefModel.RefRemarks = data.Comments;
    this.getCustomerList();
  }
  
  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
    });
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      this.showSpinner = false;
      this.customerlist = res.Item2;
    })
  }

  CheckCustomerRefInfo() {
    if (this.dataCustomerRefSource.filteredData.length != 0) {
      this.QuickLoan_Save_PageProcess('Customer Reference');
      this.action.emit("next")

    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Atlease one Customer refrence.", ...this.configSuccess });
    }
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
}
