export class RequestModel {
  public District: number;
  public DistrictName: string;
  public CourtComplex: number;
  public CourtId: number;
  public CourtName: string;
  public ComplexId: number;
  public ID: number;
  public ComplexName: string;
  public CategoryName: string;
  public CaseStatus: string;
  public CaseType: string;
  public ProductId: number;
  public PartnerType: string;
  public TehsilId: number;
  public Tehsil_Id: number;
  public StateId: number;
  public StateID: number;
  public State_Id: number;
  public Tehsil_Name: string;
  public DistrictId: number;
  public District_ID: number;
  public District_Name: string;
  public BranchId: number;
  public RoleId: number;
  public ZoneId: number;
  public Zone_Name: string;
  public JSON: string;
  public NPA_DPD: number;
  public RoleIds: string;
  public ProcessId: number;
  public APId: number;
  public AssignedTo: number;
  public LoginUserId: number;
  public DeletionReason: string;
  public EmployeeRole: number;
  public EmpRoleId: number;
  public EmpId: number;
  public DocId: number;
  public LoginEmpId: number;
  public Emp_IsActive: boolean;
  public EmpBranchId: number;
  public EmpProcessId: number;
  public Emp_Id: number;
  public ProductCategory: string;
  public ProductName: string;
  public ProductShortName: string;
  public LoanSeries: string;
  public UploadMaxSize_In_KB: number;
  public IsCoBrowser_CRC: boolean;
  public IsGuarantor_CRC: boolean;
  public IsCoBrowser_FI: boolean;
  public IsGuarantor_FI: boolean;
  public IsCoBrowser_TVR: boolean;
  public IsGuarantor_TVR: boolean;
  public IsCoBrowser_PD: boolean;
  public IsGuarantor_PD: boolean;
  public IsMultipleTranche: boolean;
  public Product_IsActive: boolean;
  public IsInsurance: boolean;
  public IsNACHPDC: boolean;
  public IsCustomerReference: boolean;
  public IsEqualSplit: boolean;
  public IsLoginFeeCash: boolean;
  public IsLoginFeeBank: boolean;
  public IsODDetail: boolean;
  public Generate_Amortization_AfterPayment: boolean;
  public ROI_Input_mathod	: any;
  public LoanId: number;
  public DocData: string;
  public DocName: string;
  public DocCategory: string;
  public ChargeHeadId: number;
  public ChargeType: string;
  public ChargeValues: any;

  // questions
  public FIQueId: number;
  public FIQuestion: string;
  public FIQuestion_Hindi: string;
  public FIQuestionType: string;
  public FIQuestionOptions: string;
  public FIQuestionProducts: string;
  public FIQuestionFor: string;
  public FI_QuestionFor: string;

  // fileCheckingMaster

  public Id: number;
  public Name: string;
  public Options: string;
  public Products: string;

  // tvr question
  public TVRQueId: number;
  public TVRQuestion: string;
  public TVRQuestion_Hindi: string;
  public TVRQuestionType: string;
  public TVRQuestionOptions: string;
  public TVRQuestionProducts: string;
  public TVRQuestionFor: string;
  public ProductCategoryName: string;
  public TVR_QuestionFor: string;
  // search customer
  public SearchOn: string;
  public SearchValue: string;
  public CustomerId: number;

  public ChagesHeadID: number;
  public ChagesHead: string;

  public CompanyName: string;
  public CompanyRegNo: string;
  public RejectReopen_Days: number;
  public ApplicationNo_Prefix: string;
  public BranchCode_Prefix: string;
  public EnquireNo_Prefix: string;
  public LoanAppNo_Prefix: string;
  public DaysInYear: any;

  public ComapnyEmail: string;
  public CompanyDateofIncorporation: string;
  public CompanyGSTNo: string;
  public CompanyHeadOfficeAddress: string;
  public CompanyCorporateOfficeAddress: string;
  public CompanyPANNo: string;
  public CompanyPhoneNo: string;
  public CompanyPhoneNo1: string;
  public CompanyStateCode: string;
  public CompanyTANNo: string;
  public CompanyWhatAppNo: string;
  public DefaultCRCAgency: string;
  public Data_Freeze: Date;
  public CompanyLogo: string;
  public LimitOfCashPayment: number;
  public Is_NOC_Block: number;
  public IsMasking: number;
  public LimitOfCashReceipt: number;
  // courier

  public Courierid: number;
  public CourierCompanyName: string;
  public CourierComapnyAddress: string;
  public CourierCompanyPhoneNo: string;

  public FromDate: string;
  public ToDate: string;
  // assets
  public AssetId: number;
  public Asset_ProductId: string;
  public Asset_Question: string;
  public Asset_QuestionType: string;
  public Asset_QuestionOptions: string;
  public Asset_IsMandatory: string;
  public Charge_AccountHeadId: number;
  public ChargeApplyOn: string;
  public ParentIds: string;
}
