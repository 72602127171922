import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { LmsService } from '../../_Lms/services/lms.service';
import { Router } from '@angular/router';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { constantUrl } from "../../Shared/constantUrl";


@Component({
  selector: 'app-accounting-master',
  templateUrl: './loan-accounting-category.component.html',
  styleUrls: ['./loan-accounting-category.component.scss']
})
export class LoanAccountingCategoryComponent implements OnInit {
  data: any; loading: boolean= false;
  showSpinner: boolean = false;
  displayedColumns: string[] = ['CategoryId', 'Category', 'Type', 'Invst_Type', 'Own_Fund', 'Partner_Fund', 'Partner_IRR', 'Product', 'CreateOn', 'Action'];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  LoanAccountingCategoryList: any[] = [];
  dialogreturn: any;
  ProductDropDown: any[] = [];
  chargesHead: any[] = [];
  chargableAccount: any[] = [];
  multipleTrunche: boolean = false;
  IsLoanEdit: any = 1;
  IsCharges: boolean = true;
  RequestModel: RequestModel = new RequestModel();
  SaveNewProductRequestModel: RequestModel = new RequestModel();
  SaveProductCategoryRequestModel: RequestModel = new RequestModel();
  DeletePoductRequestModel: RequestModel = new RequestModel();
  private _MasterService;
  ProductId: any = 0;

  today: Date = new Date();

  docBaseUrl: any = constantUrl.OthersDocUrl;

  dropdownSettings = {
    singleSelection: false,
    idField: 'ProductId',
    textField: 'Product_Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  loanAccountingCatModel: any = {};
  ChargesModel: any = { Charges_Detail: [] };
  ChargesHead1: any[] = [];
  multipleTrunche1: boolean = false;
  IsDisabledAccount: boolean = false;
  IsDisabled: boolean = false;
  ChargesHeadId: any = 0;
  OldAccountId: any = 0;
  NewAccountId: any = "";
  SwapChanges: any = {};
  mixChargesHead: any[] = [];
  constructor(
    private _Route: Router, private lmsService: LmsService, private dialog: DialogService, private masterService: MasterService,
    public snackBar: MatSnackBar, private dataSharingService: DataSharingService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Loan Accounting Category');
    this.GetProductForDropdown();
    this.getLoanAcoountingCategoryList();

    this.Get_DefaultChargeHeadsForDropdown();
    this.Get_ChargeableAccountForDropdown();

    //this.initializeChargesModel();
    this.Get_WithoutDeafult_ChargeHeads();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getLoanAcoountingCategoryList() {
    this.showSpinner = true;
    this.lmsService.Get_Loan_Accounting_Category_For_List({}).subscribe((result) => {
      //console.log(result);
      this.LoanAccountingCategoryList = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(this.LoanAccountingCategoryList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }
  GetProductForDropdown() {
    this.masterService.GetProductForDropdown().subscribe((res: any) => {
      this.ProductDropDown = res;
    })
  }
  onChangeType(){
    if(this.loanAccountingCatModel.Loan_Acc_Type=='Channel'||this.loanAccountingCatModel.Loan_Acc_Type=='Own Fund'){
      this.loanAccountingCatModel.Loan_Acc_Invst_Type=2;
    }
    else{
      this.loanAccountingCatModel.Loan_Acc_Invst_Type="";
    }
    if (this.loanAccountingCatModel.Loan_Acc_Type == 'Own Fund') {
      this.IsCharges = false;
    } else {
      this.IsCharges = true;
    }
  }
  onAddAccountingCategory() {
    this.loanAccountingCatModel = { Loan_Acc_Category: "", Loan_Acc_Type: "", Loan_Acc_Invst_Type: "", Loan_Acc_Own_Fund: "", Loan_Acc_Partner_Fund: "", Loan_Acc_Partner_IRR: "", Loan_Acc_ProductIds: [], LoginUserId: this.data.userId };
    
    this.ChargesModel = { Charges_Detail: [] };
    this.initializeChargesModel();
    
    this.Get_ProductDefaultChargeableDetails();
    $('#AddLACModal').modal('show');
    $("#AddLACModal").css("z-index", "1050");
  }
  OnClose() {
    $('#AddLACModal').modal('hide');
    this.loanAccountingCatModel = {};
  }
  onSaveLoanAccountingCat() {
    let _productid: any[] = [];
    this.loanAccountingCatModel.Loan_Acc_ProductIds.forEach((obj: any) => {
      _productid.push(obj.ProductId);
    });
    if (_productid.length == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please select atleast one product.", ...this.configSuccess });
      return;
    }
    if(+this.loanAccountingCatModel.Loan_Acc_Own_Fund!=0||+this.loanAccountingCatModel.Loan_Acc_Partner_Fund!=0){
      if((+this.loanAccountingCatModel.Loan_Acc_Own_Fund) + (+this.loanAccountingCatModel.Loan_Acc_Partner_Fund)!=100){
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Sum of Own Fund and Partner Fund should be 100.", ...this.configSuccess });
        return;
      }
    }


    this.loanAccountingCatModel.LoginUserId = this.data.userId;
    this.loanAccountingCatModel.IsLoanEdit = this.IsLoanEdit;
    let _data = { 
      ...this.loanAccountingCatModel, Loan_Acc_ProductIds: _productid.join(',')
    }

    let ACC_Category_Charges: any = [];
    if (this.loanAccountingCatModel.Loan_Acc_Type == 'Own Fund') {
      ACC_Category_Charges = [];
    } 
    else {
      ACC_Category_Charges = JSON.parse(JSON.stringify(this.chargesHead.filter((obj: any) => { return obj.select })));
      ACC_Category_Charges.forEach((obj: any) => {
        delete obj.select;
        delete obj.Id;
        delete obj.ChagesHead;
      });

      this.ChargesModel.Charges_Detail.forEach((obj: any) => {
        ACC_Category_Charges.push({
          Id: Number(obj.ChagesHead),
          ChargeHeadId: Number(obj.ChagesHead),
          Account_HeadId: Number(obj.Account_HeadId),
          Charge_Type: obj.Charge_Type,
          Charge_Values: obj.Charge_Values.toString(),
          Charge_ApplyOn: obj.Charge_ApplyOn,
        });
      });
    }
    //console.log("ACC_Category_Charges : ", ACC_Category_Charges);
    
    this.uploadAgreementDocs(this.loanAccountingCatModel);
    this.lmsService.Save_Loan_Accounting_Category({ JSON: JSON.stringify({ Loan_Accounting_Category: this.dataSharingService.cleanObject(_data), ACC_Category_Charges: ACC_Category_Charges }) })
      .subscribe((response) => {
        //console.log(response);
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          this.OnClose();
          this.getLoanAcoountingCategoryList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        }
    });
  }

  OnEditLoanAccountingCat(row: any){
    var arr = row.Product.split(',');
    var arr2 = row.ProductIds.split(',');
    var ProductData = [];
    for (let i = 0; i < arr.length; i++) {
      ProductData.push({ 
        Product_Name: arr[i],
        ProductId : arr2[i]
      });
    }
    this.IsDisabled = true;
    this.IsDisabledAccount = true;
    //console.log("this.ProductData : ", ProductData);
    this.lmsService.Get_Loan_Acc_Category_Details({ LoanAccounting_Id : row.CategoryId }).subscribe((res: any) => {
      //console.log("this.res : ", res);
      this.loanAccountingCatModel = {
        Loan_Acc_Category: res.Item1[0].Loan_Acc_Category,
        Loan_Acc_Type: res.Item1[0].Loan_Acc_Type,
        Loan_Acc_Invst_Type: res.Item1[0].Loan_Acc_Invst_Type,
        Loan_Acc_Own_Fund: res.Item1[0].Loan_Acc_Own_Fund,
        Loan_Acc_Partner_Fund: res.Item1[0].Loan_Acc_Partner_Fund,
        Loan_Acc_Partner_IRR: res.Item1[0].Loan_Acc_Partner_IRR,
        LoginUserId: this.data.userId,
        Loan_Acc_CategoryId : res.Item1[0].Loan_Acc_CategoryId,

        Contract_StartOn : res.Item1[0].Contract_StartOn != null ? new Date(res.Item1[0].Contract_StartOn) : '',
        Contract_EndOn : res.Item1[0].Contract_EndOn != null ? new Date(res.Item1[0].Contract_EndOn) : '',
        IsCibilUpdated : res.Item1[0].IsCibilUpdated,
        AgreementDoc : res.Item1[0].AgreementDoc,
      };
      if (this.loanAccountingCatModel.Loan_Acc_Type == 'Own Fund') {
        this.IsCharges = false;
      } else {
        this.IsCharges = true;
      }
      this.loanAccountingCatModel.Loan_Acc_ProductIds = ProductData;

      this.chargesHead.forEach((obj: any) => {
        let _find = res.Item2.find((ele: any) => { return ele.Charge_HeadId == obj.Id });
        if (_find) {
          obj.select = true;
          obj.ChargeHeadId = obj.Id;
          obj.Account_HeadId = _find.Charge_Account_HeadId;
          obj.Charge_Type = _find.Charge_Type;
          obj.Charge_Values = _find.Charge_Values;
          obj.Charge_ApplyOn = _find.Charge_ApplyOn;
          obj.Type = "Old"
        }
        else {
          obj.ChargeHeadId = obj.Id;
          obj.select = res.count > 0 ? false : true;
          obj.Account_HeadId = "";
          obj.Charge_Type = "";
          obj.Charge_Values = "";
          obj.Charge_ApplyOn = "";
          obj.Type = res.Item2.count > 0 ? "Old" : "New"
        }
      });
      

      let data;
      data = res.Item2.filter(x => x.Is_Deafult != 1);
      if(data.length > 0){
        this.ChargesModel.Charges_Detail = data.map(obj => {
            //Rename json key like Charge_HeadId to ChagesHead and more like this
            return {
              "Select": true,
              "Id": obj.Charge_HeadId,
              "ChagesHead" : obj.Charge_HeadId,
              "Account_HeadId":obj.Charge_Account_HeadId,
              "Charge_Type":obj.Charge_Type,
              "Charge_Values":obj.Charge_Values,
              "Charge_ApplyOn": obj.Charge_ApplyOn,
              "Type": obj.Type
            }
          }
        );
        this.IsDisabled = true;
        this.IsDisabledAccount = true;
      }
      else{
        this.ChargesModel = { Charges_Detail: [] };
        this.initializeChargesModel();
      }
      //console.log("this.ChargesModel : ", this.ChargesModel);
    });
   
    $('#AddLACModal').modal('show');
    $("#AddLACModal").css("z-index", "1050");
  }

  OnDeleteAccountingCategory(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete?');
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.lmsService.Delete_Loan_Accounting_Category({ Loan_Accounting_CategoryId: row.CategoryId, LoginUserId: this.data.userId }).subscribe((response) => {
          if (response[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            this.getLoanAcoountingCategoryList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }

  Get_DefaultChargeHeadsForDropdown() {
    this.lmsService.Get_DefaultChargeHeadsForDropdown({}).subscribe((res: any) => {
      this.chargesHead = res;
      this.mixChargesHead.push(...this.chargesHead);
    })
  }
  Get_ChargeableAccountForDropdown() {
    this.lmsService.Get_ChargeableAccountForDropdown({}).subscribe((res: any) => {
      this.chargableAccount = res;
    })
  }

  Get_ProductDefaultChargeableDetails() {
    this.lmsService.Get_ProductDefaultChargeableDetails({ ProductId: this.ProductId, isDefault:true }).subscribe((res: any) => {
      this.chargesHead.forEach((obj: any) => {
        let _find = res.find((ele: any) => { return ele.Charge_HeadId == obj.Id })
        if (_find) {
          obj.select = true;
          obj.ChargeHeadId = obj.Id;
          obj.Account_HeadId = _find.Charge_Account_HeadId;
          obj.Charge_Type = _find.Charge_Type;
          obj.Charge_Values = _find.Charge_Values;
          obj.Charge_ApplyOn = _find.Charge_ApplyOn;
        }
        else {
          obj.ChargeHeadId = obj.Id;
          obj.select = res.count > 0 ? false : true;
          obj.Account_HeadId = "";
          obj.Charge_Type = "";
          obj.Charge_Values = "";
          obj.Charge_ApplyOn = "";
        }
      });
    })
  }

  fileChangeListener(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.loanAccountingCatModel.AgreementDoc = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.loanAccountingCatModel.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }

  removeFile(input: any) {
    input.value = "";
    this.loanAccountingCatModel.AgreementDoc = "";
    delete this.loanAccountingCatModel.DocData;
  }

  uploadAgreementDocs(data: any) {
    if (data.DocData) {
      this.lmsService.UploadOthersDoc({ DocData: this.loanAccountingCatModel.DocData, DocName: data.AgreementDoc }).subscribe((res: any) => {
        //console.log("res :", res);
      });
    }
  }

  initializeChargesModel() {
    this.ChargesModel.Charges_Detail.push({
      Select: true,
      ChagesHead: "",
      Account_HeadId: "",
      Charge_Type: "",
      Charge_Values: "",
      Charge_ApplyOn: "",
      Type: "New",
      Id: "",
    });
  }
  addMoreData() {
    this.initializeChargesModel();
  }
  removeData(index: any) {
    this.ChargesModel.Charges_Detail.splice(index, 1);
  }

  Get_WithoutDeafult_ChargeHeads () {
    this.lmsService.Get_WithoutDeafult_ChargeHeads ({}).subscribe((res: any) => {
      this.ChargesHead1 = res;
      this.mixChargesHead.push(...this.ChargesHead1);
    })
  }

  onChangeChargesHead(data: any, dindex: any) {
    if(dindex > 0){
      let _find = this.ChargesModel.Charges_Detail.find((obj: any, index: any) => {
        return index != dindex && obj.ChagesHead == data.ChagesHead
      });
      if (_find!=undefined && _find.ChagesHead == data.ChagesHead) {
        this.ChargesModel.Charges_Detail[dindex].ChagesHead = "";
        this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
      }
    }
  }

  onSwap(charges) {
    this.ChargesHeadId = charges.Id;
    this.OldAccountId = charges.Account_HeadId;
    this.SwapChanges = charges;
    $('#SwapAccountModel').modal('show');
    $("#SwapAccountModel").css("z-index", "1050");
  }
  onCloseSwapAccountModel() {
    this.ChargesHeadId = 0;
    this.OldAccountId = 0;
    this.SwapChanges = {};
    this.SwapChanges = { Id: 0, ChagesHead: "", Account_HeadId: "" };
    $('#SwapAccountModel').modal('hide');
  }
  Swap() {
    this.dialog.openConfirmDialog(`Are You Sure To Swap Account?`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.masterService.Partner_ACC_Charges_Swap({ CategoryId: this.loanAccountingCatModel.Loan_Acc_CategoryId, ChargesheadId: this.ChargesHeadId, OldAccountId: this.OldAccountId, NewAccountId: this.NewAccountId }).subscribe((response) => {
            if (response[0].CODE = 0) {
              this.onCloseSwapAccountModel();
              this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
              this.OnClose();
            } else {
              this.OnClose();
              this.onCloseSwapAccountModel();
              this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
            }
          });
        }
      });
  }
}
