<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div>
  <!--  [class.fix_height]="loanSummary != undefined" -->
  <div *ngIf="loanSummary == undefined">
    <div class="row m-0 mt-3" *ngIf="customerDetail">
      <div class="row m-0 col-md-11 p-1">
        <div class="col-md-12 p-1">
          <h6 class="fs-12">Company Name</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.CompanyName }}</p>
        </div>
      </div>
      <div class="row m-0 col-md-11 p-1">
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Application No.</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ApplicationNo }}</p>
        </div>
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LoanNo }}</p>
        </div>
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Application Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ApplicationDate }}</p>
        </div>
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
        </div>
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Branch</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.Branch }}</p>
        </div>
        <div class="col-md-2 p-1">
          <h6 class="fs-12">Product</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.Product }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="table-responsive mt-3">
      <mat-table [dataSource]="CustomerDataSource">
        <ng-container matColumnDef="Customer">
          <mat-header-cell class="grid-header mw350" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell mw350"
          >
            <app-lms-customer-view-model
              [customerId]="row.CustomerId"
              [index]="i"
            >
              {{ row.Customer }}
            </app-lms-customer-view-model>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="FatherName">
          <mat-header-cell class="grid-header mw250" *matHeaderCellDef
            >Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell mw250">{{
            row.FatherName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerType">
          <mat-header-cell class="grid-header mw250" *matHeaderCellDef
            >Customer Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell mw250">{{
            row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GenderAge">
          <mat-header-cell class="grid-header mw250" *matHeaderCellDef
            >Gender/Age</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell mw250">{{
            row.GenderAge
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PhoneNo">
          <mat-header-cell class="grid-header mw250" *matHeaderCellDef
            >Mobile No.</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell mw250">
            <div *ngIf="currentUser.IsMasking">
              {{ row.PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.PhoneNo }}
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedCustomerColumns"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedCustomerColumns"
        ></mat-row>
      </mat-table>
    </div>

    <hr />

    <div class="row m-0 mt-3" *ngIf="customerDetail">
      <div class="col-md-2 p-1">
        <h6 class="fs-12">Loan Amount</h6>
        <p class="lead m-0 fs-12">₹ {{ customerDetail.LoanAmount }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">Interest Amount</h6>
        <p class="lead m-0 fs-12">₹ {{ customerDetail.InterestAmount }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">Agree Amount</h6>
        <p class="lead m-0 fs-12">₹ {{ customerDetail.AgreedAmount }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">First EMI Date</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.FirstEMIDate }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">Last EMI Date</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.LastEMIDate }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">IRR (%)</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.IRR }} %</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">ROI (%)</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.ROI }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">LTV</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.LTV }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">No. of Installment</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.NoOfInstallment }}</p>
      </div>
      <div class="col-md-2 p-1">
        <h6 class="fs-12">Tenure({{ Tenure_Name }})</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.Tenure }}</p>
      </div>
    </div>

    <hr />
  </div>
  <div class="row m-0 mt-2" *ngIf="customerDetail">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="ChargesDataSource">
          <ng-container matColumnDef="CaseNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Case No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CaseNo
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="UpfrontCharges">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Upfront Charges</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ChagesHead
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="font-size: 12px"
              >Total:</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Deduction">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Debit</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Deduction
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
              class="text-right"
              >{{ calculateDeductionTotal() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Receipt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Credit</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Receipt
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
              class="text-right"
              >{{ calculateReceiptTotal() }}</mat-footer-cell
            >
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedChargesColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedChargesColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer"
            style="font-weight: bold; font-size: 12px"
            *matFooterRowDef="displayedChargesColumns"
          ></mat-footer-row>
        </mat-table>
      </div>
    </div>
    <div class="col-md-12" style="margin-top: 10px">
      <div class="table-responsive">
        <mat-table [dataSource]="EmiDataSource">
          <ng-container matColumnDef="EMI_Pattern">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >EMI Pattern</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.EMI_Pattern
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="font-size: 12px"
              >Total:</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="NoOfEMI">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >No. Of EMI</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.NoOfEMI
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
              class="text-right"
              >{{ calculateTotalEMI() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.EMIAmount
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalAmount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Total</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.TotalAmount
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
              class="text-right"
              >{{ calculateTotalAmount() }}</mat-footer-cell
            >
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedEMIColumns"
          ></mat-header-row>
          <mat-row
            [ngClass]="{ 'bold-text': row.ChargeHeadId == 28 }"
            *matRowDef="let row; columns: displayedEMIColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer bold-text"
            style="font-weight: bold; font-size: 12px"
            *matFooterRowDef="displayedEMIColumns"
          ></mat-footer-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-md-12">
      <div class="table-responsive mt-3">
        <mat-table [dataSource]="ChargesHeadDataSource">
          <ng-container matColumnDef="Charges">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Charges</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ChagesHead
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="font-size: 12px"
              >Total:</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Amount
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
              class="text-right"
              >{{ calculateAmountTotal() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="ReceiptOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Receipt On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ReceiptOn
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="font-size: 12px"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedChargesHeadColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedChargesHeadColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer"
            style="font-weight: bold"
            *matFooterRowDef="displayedChargesHeadColumns"
          ></mat-footer-row>
        </mat-table>
      </div>
    </div>
    <!--<div class="row m-0 mt-3" *ngIf="customerDetail">
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Net Disbursment Amount</h6>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">₹ {{ customerDetail.NetDisbursementAmount }}</h6>
      </div>
    </div>-->
  </div>

  <div class="row m-0">
    <div class="col-md-12">
      <h1 class="fs-12 mt-2">Disbursment Detail</h1>
      <div class="table-responsive mt-2">
        <mat-table [dataSource]="PDCDataSource">
          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PdcType">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.PdcType
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Bankname">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Bank Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Bankname
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Branch</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Branch
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="AccountNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Account No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.AccountNo
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="IFSCCode">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >IFSCCode</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.IFSCCode
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ChFrom">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Chq From</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ChFrom
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ChTo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Chq To</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ChTo
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="NoOfChqs">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >No Of Chqs</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NoOfChqs
            }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedPDCColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedPDCColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div class="row m-0" *ngIf="JsonData7">
    <div class="col-md-12">
      <h1 class="fs-12 mt-2">Payment Detail :</h1>

      <div class="table-responsive mt-2">
        <mat-table [dataSource]="Disbursment">
          <ng-container matColumnDef="Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PaymentStatus">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Payment Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.PaymentStatus }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AccountNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Account No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AccountNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="BankName">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Bank Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.BankName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="BankBranch">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Bank Branch</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.BankBranch }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="BankIFSC">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Bank IFSC</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.BankIFSC }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="HolderName">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Holder Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.HolderName }}
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedPaymentColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedPaymentColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
  <!--<div class="row m-0 col-md-12" *ngIf="Disbursment">
    <div class="col-md-2 p-1">
      <h6 class="fs-12">In Favour of</h6>
      <p class="lead m-0 fs-12">{{ Disbursment.Customer }}</p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Bank Name</h6>
      <p class="lead m-0 fs-12">{{ Disbursment.Bankname }}</p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Branch</h6>
      <p class="lead m-0 fs-12">{{ Disbursment.Branch }}</p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">A/c No.</h6>
      <p class="lead m-0 fs-12">{{ Disbursment.AccountNo }}</p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">IFSC</h6>
      <p class="lead m-0 fs-12">{{ Disbursment.IFSCCode }}</p>
    </div>
    <div class="col-md-2 p-1">
      <h6 class="fs-12">Amount</h6>
      <p class="lead m-0 fs-12">{{ TotalDisbursAmt }}</p>
    </div>
  </div>-->

  <div class="row m-0 formborder mt-3">
    <div class="col-md-12 mt-4">
      <h1 class="fs-12 pl-1">User Detail :</h1>
    </div>
    <div class="row m-0 col-md-12" *ngIf="customerDetail">
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Application Created By</h6>
        <p class="lead m-0 fs-12">
          {{ customerDetail.Application_CreateBy }}
        </p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">FI/PD Done By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.FIDoneBy }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Credit Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.CreditApprovedBy }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Loan Converted By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.LoanConvertBy }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">DV Created By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_CreateBy }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">DV Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_AuthorizBy }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Payment Created by</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.Authorized_By }}</p>
      </div>
      <div class="col-md-3 p-1">
        <h6 class="fs-12">Payment Aproved by</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.Payment_By }}</p>
      </div>
    </div>
  </div>

  <div class="row m-0 mt-1 col-md-12 p-0 justify-content-end">
    <button
      type="button"
      (click)="Print()"
      [class.spinner]="loading"
      class="mt-3 btn font-size-12 button-btn"
    >
      Print
    </button>
  </div>
</div>

<div
  class="modal fade in"
  id="loan_disbursement_print_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Disbursment
        </h6>
        <button
          type="button"
          (click)="onCloseDisbursementPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="mt-2 btn font-size-12 button-btn ml-2"
              (click)="
                onDocsPrint('LoanDisbursement_innerDiv', 'Loan Disbursement')
              "
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="LoanDisbursement_innerDiv">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h2>{{ customerDetail.CompanyName }}</h2>
              </div>
            </div>
          </div>

          <div
            style="
              font-family: 'Times New Roman', Times, serif;
              text-align: center;
              margin-top: 10px;
            "
          >
            <h3>Disbursement Voucher</h3>
          </div>

          <div class="table-responsive mt-2">
            <table
              *ngIf="customerDetail"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Application No
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Loan No
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Application Date
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Loan Date
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Branch
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Product
                  </th>
                </tr>
              </thead>
              <tr style="text-align: left">
                <td>{{ customerDetail.ApplicationNo }}</td>

                <td style="text-align: left">
                  {{ customerDetail.LoanNo }}
                </td>

                <td style="text-align: left">
                  {{ customerDetail.ApplicationDate }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.LoanDate }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.Branch }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.Product }}
                </td>
              </tr>
            </table>

            <hr />

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Customer Name
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Relation
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    Customer Type
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Gender/Age
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Mobile No.
                  </th>
                </tr>
              </thead>

              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData"
              >
                <td>{{ row.Customer }}</td>

                <td style="text-align: left">{{ row.FatherName }}</td>

                <td style="text-align: left">
                  {{
                    row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
                  }}
                </td>
                <td style="text-align: left">{{ row.GenderAge }}</td>
                <td style="text-align: left">{{ row.PhoneNo }}</td>
              </tr>
            </table>

            <hr />

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Loan Amount
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Interest Amount
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Agree Amount
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    First EMI Date
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Last EMI Date
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    IRR(%)
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    ROI(%)
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    LTV
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    No. of Installment
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Tenure({{ Tenure_Name }})
                  </th>
                </tr>
              </thead>
              <tr style="text-align: left">
                <td>{{ customerDetail.LoanAmount }}</td>
                <td style="text-align: left">
                  {{ customerDetail.InterestAmount }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.AgreedAmount }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.FirstEMIDate }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.LastEMIDate }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.IRR }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.ROI }}
                </td>
                <td style="text-align: left">{{ customerDetail.LTV }}</td>
                <td style="text-align: left">
                  {{ customerDetail.NoOfInstallment }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.Tenure }}
                </td>
              </tr>
            </table>
            <hr />

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Case No
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Customer
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    Upfront Charges
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Debit
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Credit
                  </th>
                </tr>
              </thead>

              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData1"
              >
                <td>{{ row.CaseNo }}</td>

                <td style="text-align: left">{{ row.Customer }}</td>

                <td style="text-align: left">
                  {{ row.ChagesHead }}
                </td>
                <td style="text-align: right">{{ row.Deduction }}</td>
                <td style="text-align: right">{{ row.Receipt }}</td>
              </tr>
              <tfoot>
                <tr>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                    colspan="3"
                  >
                    <strong>Total: </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ calculateDeductionTotal() }}
                    </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateReceiptTotal() }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            <hr />

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    EMI Pattern
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    No of Emi
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    EMI Amount
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Total
                  </th>
                </tr>
              </thead>

              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData2"
              >
                <td>{{ row.EMI_Pattern }}</td>

                <td style="text-align: right">{{ row.NoOfEMI }}</td>

                <td style="text-align: right">
                  {{ row.EMIAmount }}
                </td>
                <td style="text-align: right">{{ row.TotalAmount }}</td>
              </tr>
              <tfoot>
                <tr>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>Total: </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;

                      text-align: right;
                    "
                  >
                    <strong>
                      {{ calculateTotalEMI() }}
                    </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong></strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateTotalAmount() }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
            <hr />

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Charges
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Amount
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    Receipt On
                  </th>
                </tr>
              </thead>

              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData3"
              >
                <td>{{ row.ChagesHead }}</td>

                <td style="text-align: right">{{ row.Amount }}</td>

                <td style="text-align: left">
                  {{ row.ReceiptOn }}
                </td>
              </tr>
              <tfoot>
                <tr>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>Total: </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong>
                      {{ calculateAmountTotal() }}
                    </strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      text-align: left;
                      text-align: right;
                    "
                  >
                    <strong></strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            <hr />

            <h5 class="fs-12 mt-2">Disbursment Detail</h5>

            <table
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Customer Name
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Type
                  </th>

                  <th style="background: #4dc3a3 !important; text-align: left">
                    Bank Name
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Branch
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Account No.
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    IFSC Code
                  </th>
                  <!--<th style="background: #4dc3a3 !important; text-align: left">
                    Chq From
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Chq To
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    No Of Chqs
                  </th>-->
                </tr>
              </thead>

              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData4"
              >
                <td>{{ row.Customer }}</td>

                <td style="text-align: left">{{ row.PdcType }}</td>

                <td style="text-align: left">
                  {{ row.Bankname }}
                </td>
                <td style="text-align: left">{{ row.Branch }}</td>
                <td style="text-align: left">{{ row.AccountNo }}</td>
                <td style="text-align: left">{{ row.IFSCCode }}</td>
                <!--<td style="text-align: left">{{ row.ChFrom }}</td>
                <td style="text-align: left">{{ row.ChTo }}</td>
                <td style="text-align: left">{{ row.NoOfChqs }}</td>-->
              </tr>
            </table>

            <hr />

            <h5 class="fs-12 pl-1">Payment Detail :</h5>

            <table
              *ngIf="JsonData7"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <thead>
                <tr>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Holder Name
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Account No
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Bank Name
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Bank Branch
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Bank IFSC
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Amount
                  </th>
                  <th style="background: #4dc3a3 !important; text-align: left">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tr
                style="text-align: left"
                *ngFor="let row; let i = index; of: JsonData7"
              >
                <td>{{ row.HolderName }}</td>

                <td style="text-align: left">
                  {{ row.AccountNo }}
                </td>

                <td style="text-align: left">
                  {{ row.BankName }}
                </td>
                <td style="text-align: left">
                  {{ row.BankBranch }}
                </td>
                <td style="text-align: left">
                  {{ row.BankIFSC }}
                </td>
                <td style="text-align: left">
                  {{ row.Amount }}
                </td>
                <td style="text-align: left">
                  {{ row.PaymentStatus }}
                </td>
              </tr>
            </table>

            <hr />
            <h5 class="fs-12 pl-1">User Detail :</h5>
            <table
              *ngIf="customerDetail"
              class="table table-bordered bg-white"
              style="
                font-family: 'Times New Roman', Times, serif;
                margin-top: 20px;
              "
            >
              <tr>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Application Created By
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  FI/PD Done By
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Credit Approved By
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Loan Converted By
                </th>
              </tr>
              <tr>
                <td style="text-align: left">
                  {{ customerDetail.Application_CreateBy }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.FIDoneBy }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.CreditApprovedBy }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.LoanConvertBy }}
                </td>
              </tr>

              <tr>
                <th style="background: #4dc3a3 !important; text-align: left">
                  DV Created By
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  DV Approved By
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Payment Created by
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Payment Aproved by
                </th>
              </tr>

              <tr>
                <td style="text-align: left">
                  {{ customerDetail.DV_CreateBy }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.DV_AuthorizBy }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.Authorized_By }}
                </td>
                <td style="text-align: left">
                  {{ customerDetail.Payment_By }}
                </td>
              </tr>
            </table>

            <div
              id="abc"
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date & Time: {{ Cur_Date | date : "dd/MM/yyyy hh:mm:ss" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
