<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <form
    class="formborder"
    #df="ngForm"
    novalidate
    (keydown.enter)="getDueListPreEmi()"
  >
    <div class="col-md-12 row">
      <div class="datepicker_feild" style="padding-left: 15px !important">
        <span>Due Date</span>
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="dueDate"
          id="dueDate"
          #refdueDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="dueDate"
          [ngClass]="{
            'is-invalid': df.submitted && refdueDate.invalid,
            'alert-warning':
              refdueDate.invalid &&
              (refdueDate.dirty || refdueDate.touched || refdueDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
          style="top: 24px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div class="col-md-2 text-right mt-3">
        <button
          type="button"
          (click)="getDueListPreEmi()"
          class="btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-3 mt-3" [hidden]="!dataSource">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div
        class="table-responsive table-striped"
        style="overflow: auto; height: 65vh"
      >
        <table
          class="table-bordered"
          matSort
          mat-table
          [dataSource]="dataSource"
          matTableExporter
          #exporter="matTableExporter"
          id="DueListPreEmi"
        >
          <ng-container matColumnDef="Loan_Id">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
            <td mat-cell *matCellDef="let row">{{ row.LoanNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Application No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.ApplicationNo }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Product">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Product</th>
            <td mat-cell *matCellDef="let row">{{ row.Product }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Branch</th>
            <td mat-cell *matCellDef="let row">{{ row.Branch }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="MobileNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Mobile No</th>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="currentUser.IsMasking">
                {{ row.MobileNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMasking">
                {{ row.MobileNo }}
              </div>
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-center"></th>
          </ng-container>

          <ng-container matColumnDef="No_Of_Instl">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              No Of Instalment
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.No_Of_Instl }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right"></th>
          </ng-container>

          <ng-container matColumnDef="Tenure">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Tenure</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Tenure }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right"></th>
          </ng-container>

          <ng-container matColumnDef="EMI_Type">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI Type</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMI_Type }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">Total:</th>
          </ng-container>

          <ng-container matColumnDef="IRR">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>IRR</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.IRR | number : "1.2-2" }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMIAmount | number : "1.2-2" }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("EMIAmount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Loan Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.LoanAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("LoanAmount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="DisbursementPayble">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Disbursement Payable
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.DisbursementPayble }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("DisbursementPayble") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="DisbursementPayment">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Disbursement Payment
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.DisbursementPayment }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("DisbursementPayment") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="DisbursementPending">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Disbursement Pending
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.DisbursementPending }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("DisbursementPending") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="InterestDue">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Interest Due
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.InterestDue }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("InterestDue") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="InterestReceived">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Interest Received
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.InterestReceived }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("InterestReceived") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="InterestBalance">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Interest Balance
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.InterestBalance }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("InterestBalance") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="LastPaymentDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Payment Date
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              {{ row.LastPaymentDate }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8 mt-3">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<table
  id="export-due-list-pre-emi"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mobile No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of Instalment
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">IRR</th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursement Payble
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursement Payment
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursement Pending
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Recv Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Payment Date
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanNo }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.Branch }}</td>

    <td>{{ x.Customer }}</td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.MobileNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.MobileNo }}
      </div>
    </td>
    <td>{{ x.No_Of_Instl }}</td>
    <td>{{ x.Tenure }}</td>
    <td>{{ x.EMI_Type }}</td>
    <td>{{ x.IRR | number : "1.2-2" }}</td>

    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.DisbursementPayble }}</td>
    <td>{{ x.DisbursementPayment }}</td>
    <td>{{ x.DisbursementPending }}</td>
    <td>{{ x.InterestDue }}</td>
    <td>{{ x.InterestReceived }}</td>

    <td>{{ x.InterestBalance }}</td>
    <td>{{ x.LastPaymentDate }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="10"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMIAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("DisbursementPayble") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("DisbursementPayment") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("DisbursementPending") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("InterestDue") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("InterestReceived") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("InterestBalance") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
