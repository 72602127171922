<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 col-md-12 p-0 mb-2">
  <div
    class="col-md-4 mb-2 my-2 bg-image hover-overlay ripple shadow-1-strong rounded"
    *ngFor="let item of dashboardData; let ind = index"
    (click)="getDetail(item.ShortName)"
  >
    <div
      class="card"
      style="height: 62px; padding: 14px 2px 0px 6px"
      [style.background]="gradientColors[ind]"
    >
      <div
        style="color: #ffffff; display: flex; justify-content: space-between"
        class="font-weight-bolder"
      >
        <span style="font-size: 20px"> {{ item.IconName }} </span>
        <span class="badge badge-dark" style="font-size: 18px">
          {{ item.ItemCount }}
        </span>
      </div>
    </div>
  </div>
</div>
