<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-8"></div>
    <div class="col-md-2 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="OnOpenReportModal()"
      >
        Add Slab
      </button>
    </div>
    <div class="col-md-2 p-1 text-right">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="ConfigurationSlabModal()"
        style="width: max-content;"
      >
        Configuration Slab
      </button>
    </div>
  </div>

  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="slabdataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell mw50">{{
          i + 1
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Slab_name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Slab Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Slab_name
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Slab_Type">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Slab Type</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Slab_Type
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="onEditSlabList(row)"
            title="Edit This"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onDeleteSlabList(row)"
            title="Delete This"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnslist"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnslist"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-12">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[10, 20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewReportModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #fdf="ngForm" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Slab
          </h6>
          <button
            type="button"
            (click)="OnCloseReportModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 row m-0 p-0 formborder">
            <div class="col-md-9 p-1">
              <span style="font-weight: bold">Slab Name</span>
              <input
                type="text"
                class="form-control input-text-css"
                [(ngModel)]="slabName"
                id="SlabName"
                name="SlabName"
                placeholder="Slab Name"
                required
                #refslabName="ngModel"
                [ngClass]="{
                  'is-invalid': fdf.submitted && refslabName.invalid,
                  'alert-warning':
                    refslabName.invalid &&
                    (refslabName.dirty ||
                      refslabName.touched ||
                      refslabName.untouched)
                }"
              />
            </div>
            <div class="col-md-3 mt-3 p-1">
              <div *ngIf="div_add_btn">
                <button
                  type="button"
                  (click)="onSaveReportSlab()"
                  class="btn font-size-12 button-btn"
                  [class.spinner]="loading"
                  [disabled]="!fdf.form.valid"
                >
                  Save
                </button>
              </div>
              <div *ngIf="div_updt_btn">
                <button
                  type="button"
                  (click)="onupdateReportSlab(SlabId)"
                  class="btn font-size-12 button-btn"
                  [class.spinner]="loading"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <form #df="ngForm" novalidate>
            <div class="table-responsive mt-3" [hidden]="!showSlab">
              <mat-table
                [dataSource]="dataSource"
                matSort
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                    >{{ i + 1 }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="Slab_name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >Slab Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                    row.Slab_name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Action</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <i
                      class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                      (click)="onEditSlab(row)"
                      aria-label="true"
                    ></i>
                    <i
                      class="fa fa-trash style-delete"
                      (click)="onDeleteSlab(row)"
                      aria-label="true"
                    ></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <div class="row m-0 align-items-center" style="background: #fff">
                <div class="col-md-12">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[10, 20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnCloseReportModal()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="ConfigurationSLModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ddf="ngForm" (ngSubmit)="onSaveSlabConfig()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Slab Data
          </h6>
          <button
            type="button"
            class="close"
            (click)="closeConfigurationSLModal()"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="col-md-12 row m-0 p-0">
            <div class="col-md-5 p-1">
              <B>Select Slab Name</B>
              <select
                name="SelectBranch"
                id="SelectSlab"
                [(ngModel)]="SelectSlab"
                class="form-control input-text-css"
                required
                [disabled]="isDisabled"
              >
                <option value="" selected>Select Slab</option>
                <option *ngFor="let Slab of SlabList" [value]="Slab.Id">
                  {{ Slab.Slab_name }}
                </option>
              </select>
            </div>
            <div class="col-md-5 p-1">
              <B>Select Type</B>
              <select
                name="Type"
                id="Type"
                [(ngModel)]="Type"
                (change)="ChangeType()"
                class="form-control input-text-css"
                required
                [disabled]="isDisabled"
              >
                <option value="">Select Type</option>
                <option value="Amount">Amount</option>
                <option value="Tenure">Tenure</option>
                <option value="IRR">IRR</option>
                <option value="LTV">LTV</option>
                <option value="DPD">DPD</option>
                <option value="NPA">NPA</option>
              </select>
            </div>
          </div>
          <form #df="ngForm" novalidate>
            <div class="row m-0 add-remove-btn">
              <div class="col-md-12 row m-0 p-0">
                <div class="col-md-5 p-1" *ngIf="Type != 'NPA'">
                  <B>From</B>
                </div>
                <div class="col-md-5 p-1" *ngIf="Type != 'NPA'"><B>To</B></div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <B>NPA Type</B>
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <B>From</B>
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'"><B>To</B></div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <B>Provision Secured</B>
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <B>Provision UnSecured</B>
                </div>
              </div>
              <div class="col-md-12 row m-0 p-0"
                   *ngFor="let charges of SlabCharges; let i = index">
                <div class="col-md-5 p-1" *ngIf="Type != 'NPA'">
                  <span class="greaterThan" *ngIf="i == 0">> =</span>
                  <span class="greaterThan" *ngIf="i != 0">></span>
                  <input type="number"
                         [(ngModel)]="charges.From"
                         placeholder="From"
                         disabled
                         name="From_Values{{ i }}"
                         id="From_Values{{ i }}"
                         class="form-control input-text-css"
                         required
                         #refFrom_Values="ngModel"
                         [ngClass]="{
                      'is-invalid': df.submitted && refFrom_Values.invalid,
                      'alert-warning':
                        refFrom_Values.invalid &&
                        (refFrom_Values.dirty ||
                          refFrom_Values.touched ||
                          refFrom_Values.untouched),
                      'padding-input-Equal': i == 0,
                      'padding-input-greater': i != 0
                    }" />
                </div>
                <div class="col-md-5 p-1" *ngIf="Type != 'NPA'">
                  <input type="number"
                         [(ngModel)]="charges.To"
                         placeholder="To"
                         name="To_Values{{ i }}"
                         id="To_Values{{ i }}"
                         (change)="ToValueChange(i)"
                         class="form-control input-text-css"
                         required
                         #refTo_Values="ngModel"
                         min="{{ charges.From_Values }}"
                         [ngClass]="{
                      'is-invalid': df.submitted && refTo_Values.invalid,
                      'alert-warning':
                        refTo_Values.invalid &&
                        (refTo_Values.dirty ||
                          refTo_Values.touched ||
                          refTo_Values.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <select name="NPA{{ i }}"
                          id="NPA{{ i }}"
                          required
                          #refNPA="ngModel"
                          [(ngModel)]="charges.Classification"
                          class="form-control input-text-css">
                    <option value="" selected>Select NPA</option>
                    <option *ngFor="let NPA of NPAList" [value]="NPA.Id">
                      {{ NPA.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <input type="number"
                         [(ngModel)]="charges.FromNPA"
                         placeholder="From"
                         name="From_ValuesNPA{{ i }}"
                         id="From_ValuesNPA{{ i }}"
                         class="form-control input-text-css"
                         required
                         #refFrom_ValuesNPA="ngModel"
                         [ngClass]="{
                      'is-invalid': df.submitted && refFrom_ValuesNPA.invalid,
                      'alert-warning':
                        refFrom_ValuesNPA.invalid &&
                        (refFrom_ValuesNPA.dirty ||
                          refFrom_ValuesNPA.touched ||
                          refFrom_ValuesNPA.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <input type="number"
                         [(ngModel)]="charges.ToNPA"
                         placeholder="To"
                         name="To_ValuesNPA{{ i }}"
                         id="To_ValuesNPA{{ i }}"
                         (change)="ToValueChange(i)"
                         class="form-control input-text-css"
                         required
                         #refTo_ValuesNPA="ngModel"
                         min="{{ charges.From_ValuesNPA }}"
                         [ngClass]="{
                      'is-invalid': df.submitted && refTo_ValuesNPA.invalid,
                      'alert-warning':
                        refTo_ValuesNPA.invalid &&
                        (refTo_ValuesNPA.dirty ||
                          refTo_ValuesNPA.touched ||
                          refTo_ValuesNPA.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <input type="text"
                         [(ngModel)]="charges.Provision_Secured"
                         placeholder="Provision_Secured"
                         name="Provision_Secured{{ i }}"
                         id="Provision_Secured{{ i }}"
                         class="form-control input-text-css"
                         required
                         #refProvision_Secured="ngModel"
                         [ngClass]="{
                      'is-invalid': df.submitted && refProvision_Secured.invalid,
                      'alert-warning':
                        refProvision_Secured.invalid &&
                        (refProvision_Secured.dirty ||
                          refProvision_Secured.touched ||
                          refProvision_Secured.untouched)
                    }" />
                </div>
                <div class="col-md-2 p-1" *ngIf="Type == 'NPA'">
                  <input type="text"
                         [(ngModel)]="charges.Provision_UnSecured"
                         placeholder="Provision_UnSecured"
                         name="Provision_UnSecured{{ i }}"
                         id="Provision_UnSecured{{ i }}"
                         class="form-control input-text-css"
                         required
                         #refProvision_UnSecured="ngModel"
                         [ngClass]="{
      'is-invalid': df.submitted && refProvision_UnSecured.invalid,
      'alert-warning':
        refProvision_UnSecured.invalid &&
        (refProvision_UnSecured.dirty ||
          refProvision_UnSecured.touched ||
          refProvision_UnSecured.untouched)
    }" />
                </div>
                <button type="button"
                        class="remove"
                        (click)="removeData(i)"
                        [ngClass]="{ 'no-head': i != 0 }"
                        *ngIf="SlabCharges.length > 1"
                        [hidden]="SlabCharges[i] == SlabCharges[0]">
                  -
                </button>
                <button type="button"
                        class="addmore"
                        (click)="addMoreData(i)"
                        [ngClass]="{
                        both: SlabCharges.length>
                  1,
                  'no-head': i != 0
                  }"
                  [disabled]="!df.form.valid"
                  *ngIf="i == SlabCharges.length - 1"
                  >
                  +
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn"
            (click)="closeConfigurationSLModal()"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            *ngIf="slb_add_btn"
            type="button"
            (click)="onSaveSlabConfig()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!df.form.valid || !ddf.form.valid"
          >
            Save
          </button>
          <button
            *ngIf="slb_updt_btn"
            type="button"
            (click)="onUpdateSlabConfig(SelectSlab)"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!df.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
