<div class="row m-0">
  <div class="col-md-12 formborder">
    <div class="col-md-4 float-right">
      <button
        class="mt-3 btn font-size-12 button-btn float-right"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
  </div>

  <div class="col-md-12 p-1">
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <div class="table-responsive mt-1">
      <mat-table
        [dataSource]="dataSource"
        matSort
        style="overflow: auto; max-width: 100%; height: 60vh"
      >
        <ng-container matColumnDef="StateId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="State_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            State
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.State_Name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Code">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Code
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Code }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="StateCode">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            State Code
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.StateCode }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="GSTCode">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            GST Code
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.GSTCode }}
          </mat-cell>
        </ng-container>
        <!-- Header and Row Declarations -->
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<table
  id="State_List"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      State
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Code
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      State Code
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      GST Code
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.State_Name }}</td>
    <td>{{ x.Code }}</td>
    <td>{{ x.StateCode }}</td>
    <td>{{ x.GSTCode }}</td>
  </tr>
</table>
