import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss',
    '../../../assets/Content/vendor/bootstrap/css/bootstrap.min.css']
})

export class ButtonComponent   {
  @Input() label: string;
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: string;
  @Output() onClick = new EventEmitter<any>();

  constructor() { }


  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
 
  }
}
