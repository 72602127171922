import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-bus-vs-collection',
  templateUrl: './bus-vs-collection.component.html',
  styleUrls: ['./bus-vs-collection.component.scss']
})
export class BusVsCollectionComponent implements OnInit {
  showSpinner: boolean = false;
  pageTitle: any = "Business vs Collection";

  DataSource: any = [];
  DataSourceModel: any = [];
  displayedColumns: string[] = ["Id", "Month_Year", "Business", "EMI_Collection", "Total_Principle_Received", "Total_Interest_Received", "Action"];
  displayedColumnsModel: string[] = [];
  //Form value
  st_month_year: any;
  en_month_year: any;
  FyearId: any;
  maxDate: any;
  minDate: any;
  JsonData: any[] = [];
  JsonDataDetail:any[]=[];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorlist', { read: MatPaginator, static: true }) paginatorlist: MatPaginator;

  
  
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 55000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Business vs Collection");

    

    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        //console.log("paran", this.FyearId);
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.st_month_year = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.en_month_year = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
          this.DataSource = null;
        });
      }
    )
  }

  onSearchBusinessData() {
    this.showSpinner = true;
    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();

    let EnMonth = this.en_month_year.getMonth() + 1;
    let EnYear = this.en_month_year.getFullYear();
    
    
    this.reportsService.Report_Business_VS_Collection({ StartMonth: StMonth, StartYear: StYear,EndMonth: EnMonth, EndYear: EnYear })
    .subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        this.JsonData = JSON.parse(JSON.stringify(res));
        this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSource.sort = this.sort;
        this.DataSource.paginator = this.paginator;
        this.showSpinner = false;
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
      }
    });
  }

  

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }
  spiltData(value){
    return value.split(",");
  }

 

  DateChange() {
    if (this.st_month_year != '' && this.en_month_year != '') {
      if (this.st_month_year > this.en_month_year) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "End Month Year will be greater than to Start Month Year.", ...this.configSuccess });
        this.st_month_year = '';
        this.en_month_year = '';
      }
    }
  }

  totaltypewise(type) {
    if (this.DataSource != null && this.DataSource != undefined && this.DataSource != '') {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  totaltypewise1(type) {
    if (this.DataSourceModel != null && this.DataSourceModel != undefined && this.DataSourceModel != '') {
      let sum = 0;

      for (let i = 0; i < this.DataSourceModel.filteredData.length; i++) {
        sum += Number(this.DataSourceModel.filteredData[i][type]);
        console.log("hgfhgfhg",this.DataSourceModel.filteredData[i][type]);
        
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  goToDetails(row){
    this.showSpinner = true;
    var currentDate = new Date(row.Month_Year);

    let StMonth = currentDate.getMonth()+1;
    let StYear = currentDate.getFullYear();

    this.paginatorlist.pageIndex = 0;

    this.displayedColumnsModel = ["Id", "LoanAcNo", "CustomerName", "Business", "EMI_Collection", "Total_Principle_Received", "Total_Interest_Received"];
    
    this.reportsService.Report_Business_VS_Collection_Detail({ StartMonth: StMonth, StartYear: StYear }).subscribe((res: any) => {
      //console.log(res);
      if (res.length > 0) {
        this.JsonDataDetail = JSON.parse(JSON.stringify(res));
        this.DataSourceModel = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.DataSourceModel.sort = this.sort;
        this.DataSourceModel.paginator = this.paginatorlist;
        $('#ViewBusinessCollectionDetails').modal('show');
        $("#ViewBusinessCollectionDetails").css("z-index", "1050");
        this.showSpinner = false;
      }
     
    });
  }
  applyFilter(filterValue: string) {
    this.DataSourceModel.filter = filterValue.trim().toLowerCase();
  }
   
    

  onCloseBusinessCollectionDetails() {
    $('#ViewBusinessCollectionDetails').modal('hide');
  }
  exportexcel(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("busvsCollection") as HTMLTableElement;
    const worksheetName = 'BUSINESS Vs. COLLECTION'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
  exportexcelDetail(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("busvsCollectionDetail") as HTMLTableElement;
    const worksheetName = 'BUSINESS Vs. COLLECTIONN DETAIL'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
}
