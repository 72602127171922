import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { DialogService } from '../../Shared/dialog.service';
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { Router } from "@angular/router";
import { LmsService } from "../services/lms.service";
declare var $: any;

@Component({
  selector: "app-lms-voucher-view-model",
  templateUrl: "./lms-voucher-view-model.component.html",
  styleUrls: ["./lms-voucher-view-model.component.scss"],
})
export class LmsVoucherViewModelComponent implements OnInit {
  @Input() voucherid: any;
  @Input() index: any;
  @Input() DVEdit: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  currentUser: any;
  AccountDocUrl: any = constantUrl.AccountDocUrl;
  displayedLoanColumns: string[] = ['Type', 'CaseNo', 'Customer', 'Branch', 'Category', 'AccountHead', 'AmountDR', 'AmountCR'];
  displayedAccountingColumns: string[] = ['AccountHead', 'Branch', 'AmountDR', 'AmountCR']
  loading: boolean = false;
  ShowEdit: boolean = false;
  Close_Case: any = 0;
  ShowDelete: boolean = false;
  minDate: any;
  CheckDate: any;
  docModal: any = [];
  Data_FreezeDate: any;
  Days_Allowed: any;
  Days_AllowedDateMin: any = new Date();;
  Days_AllowedDateMax: any = new Date();;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  VoucherPrintModel: any = { Voucher: {}, Voucher_Detail: [] };

  @Input() VoucherFrom: any;

  BaseUrl: any = constantUrl.apiProfilePicUrl;
  CompanyLogo: any;



  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private encdec: EncrDecrService, private lmsService: LmsService, private dialog: DialogService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.minDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    this.CompanyLogo = this.currentUser.CompanyLogo;
  }

  onOpenViewDetailModal() {
    this.Days_Allowed = this.currentUser.Days_Allowed;
    this.Data_FreezeDate = new Date(this.currentUser.Data_Freeze.split("T")[0]);
    //if (this.Days_AllowedDateMin > this.Data_FreezeDate) {
    //  this.Days_AllowedDateMin = this.Days_AllowedDateMin;
    //  this.Days_AllowedDateMin = new Date(this.Days_AllowedDateMin.setDate(this.Days_AllowedDateMin.getDate() - this.Days_Allowed));

    //} else {
    //  this.Days_AllowedDateMin = this.Data_FreezeDate;
    //}
    this.Days_AllowedDateMin.setDate(
      this.Days_AllowedDateMin.getDate() - this.Days_Allowed
    );
    this.Days_AllowedDateMax.setDate(
      this.Days_AllowedDateMax.getDate() + this.Days_Allowed
    );

    if (this.Days_AllowedDateMin > this.Data_FreezeDate) {
      this.Days_AllowedDateMin = this.Days_AllowedDateMin;
    } else {
      this.Days_AllowedDateMin = this.Data_FreezeDate;
    }
    console.log("Data_FreezeDate", this.Days_AllowedDateMin)
    console.log("this.Data_FreezeDate", this.Data_FreezeDate)
    //var Days_AllowedDate = new Date();
    this.Days_AllowedDateMax = new Date(this.Days_AllowedDateMax.setDate(this.Days_AllowedDateMax.getDate() + this.Days_Allowed));
    //this.CustomerViewDetailRequestModel.CustomerId = this.customerId;
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: this.voucherid })
      .subscribe((res: any) => {
        console.log("res", res);

        this.minDate = new Date(JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split('T')[0] + ' 00:00:00');
        this.voucherModel.Voucher = res.Item1[0];
        var VoucherDate = new Date(this.voucherModel.Voucher.VoucherDate.split('T')[0] + ' 00:00:00');
        this.voucherModel.Voucher_Detail = res.Item2;
        this.Close_Case = this.voucherModel.Voucher.closeCaseExists;
        this.docModal = res.Item3;
        this._MasterService.Get_Page_Rights_Of_EMP({ MMID: '69,196', LoginUserId: this.currentUser.userId }).subscribe((result: any) => {
          let AllPage = result;
          //debugger;


          if (result.length > 0) {
            if (this.voucherModel.Voucher.Voucher_Type == 'Receipt' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt") != null) {
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Receipt")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Journal' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal") != null) {
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Journal")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Payment' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment") != null) {
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Payment")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Contra' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != '' && AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra") != null) {
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Voucher Entry - Contra")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }


            if (this.voucherModel.Voucher.Voucher_Type == 'Receipt' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Receipt") != '' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Receipt") != null) {
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Receipt")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Receipt")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Journal' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal") != '' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal") != null) {
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Payment' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Payment") != '' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Payment") != null) {
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Payment")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Payment")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }

            if (this.voucherModel.Voucher.Voucher_Type == 'Contra' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra") != '' && AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra") != null) {
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra")[0].Edit == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowEdit = true;
                }
                else {
                  this.ShowEdit = false;
                }
              }
              if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Contra")[0].Delete == 1) {
                if (VoucherDate >= this.Days_AllowedDateMin && VoucherDate <= this.Days_AllowedDateMax) {
                  this.ShowDelete = true;
                }
                else {
                  this.ShowDelete = false;
                }
              }
            }
            if (this.voucherModel.Voucher.Voucher_No.substring(0, 2) == 'SL') {
              this.ShowEdit = false;
              if (this.voucherModel.Voucher.IsNachDelete == 0)
                this.ShowDelete = false;
              else
                this.ShowDelete = true;

            }
          }
          else {
            if (this.voucherModel.Voucher.Voucher_No.substring(0, 2) == 'SL') {
              this.ShowEdit = false;
              if (this.voucherModel.Voucher.IsNachDelete == 0)
                this.ShowDelete = false;
              else
                this.ShowDelete = true;
            }
          }
        });

        //else {
        //  this.ShowEdit = false;
        //  this.ShowDelete = false;
        //}
        if (this.voucherModel.Voucher.Voucher_Type == 'Disbursement' && this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' && this.voucherModel.Voucher.Voucher_Status == 'Authorized' && this.DVEdit == true) {
          this.ShowEdit = true;
        }
        if (new Date(this.voucherModel.Voucher.Voucher_Date) < this.minDate) {
          this.ShowEdit = false;
          this.ShowDelete = false;
        }
        this.voucherModel.Voucher_Detail.forEach(x => {
          x.AmountDR = 0.00;
          x.AmountCR = 0.00;
          if (x.TranType == 'CR')
            x.AmountCR = x.Amount;
          if (x.TranType == 'DR')
            x.AmountDR = x.Amount;
        });
        //if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == '' || this.voucherModel.Voucher.Voucher_Mode_of_Payment == null)
        //  this.voucherModel.Voucher.Voucher_Mode_of_Payment = 'CASH';
        this.onVoucherTagChange();
        this.VoucherPrintModel = { Voucher: this.voucherModel.Voucher, Voucher_Detail: this.voucherModel.Voucher_Detail };
        $(`.VoucherDetail${this.index}`).modal("show");
        $(`.VoucherDetail${this.index}`).css(
          "z-index",
          "1050"
        );
      });


  }


  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = 'CASH';
    this.lmsService.Get_Acc_Accounts_By_Tag({ Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date) }).subscribe((res: any) => {
      //this.accountTagDropdown = res;
    })
  }

  OnClose() {
    $(`.VoucherDetail${this.index}`).modal("hide");
    //this.close.emit(true);
    $('#ChargesLoanDetailModel').modal('show');
    $("#ChargesLoanDetailModel").css("z-index", "1050");
    //this.customerData = {};
    //this.addressList = [];
    //this.CategoryList = [];
    //this.ApplicationList = [];
  }

  GetData(data) {
    if (data == true) {
      this.OnClose();
      this.close.emit(true);
    }
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "Accounting Statement",
      sheet: "Accounting Statement",
    });
  }

  printdata() {

  }
  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  DeleteVoucher() {
    this.dialog.openConfirmDialog(`Are you sure you want to remove this Voucher.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.lmsService.Delete_Voucher({ VoucherId: this.voucherModel.Voucher.VoucherId, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            if (res[0].CODE == 0) {

              this.lmsService.Acc_Voucher_Audit_Trail_Delete({ VoucherId: this.voucherModel.Voucher.VoucherId, Type: 'Delete' }).subscribe((res: any) => {
              });
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              if (this.voucherModel.Voucher_Detail.find((obj: any) => { return obj.ChargeHeadId == 34 }))
                this.lmsService.LMS_Update_EMI_Allocation({ VoucherId: this.voucherModel.Voucher.VoucherId }).subscribe((res1: any) => { });
              this.OnClose();
              //this.onOpenViewDetailModal();
            }
          });
        }
      });
  }

  //OnPrint(){
  // // this.onOpenViewDetailModal();
  // // this.VoucherPrintModel = this.voucherModel.Voucher;
  // $(`.VoucherDetailPrint`).modal("hide");
  //  console.log("this.VoucherPrintModel", this.VoucherPrintModel);
  //  $(`.VoucherDetailPrint`).modal("show");
  //  $(`.VoucherDetailPrint`).css("z-index", "1050");
  //}

  //OnClosePrint(){
  //  $(`.VoucherDetailPrint`).modal("hide");
  //}
}
