<a href="javascript:void(0)" (click)="onOpenViewPrintModal()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in VoucherPrint{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div
          cdkDragHandle
          class="modal-header non-printable"
          style="padding: 2px; border: none"
        >
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Voucher
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body" id="printable">
          <div class="row m-0 mt-2">
            <div class="col-md-3 p-1">
              <!-- <img src="../../assets/images/logo.png" alt="logo" width="100" /> -->
              <img
                src="{{ CompanyProfileBaseUrl }}logo.png"
                alt="logo"
                width="100"
              />
              <!-- <img src="{{ BaseUrl }}{{ CompanyLogo }}" alt="logo" width="100" /> -->
            </div>
            <div class="col-md-5 text-left" style="color: black">
              <h1 class="fs-24">{{ currentUser.CompanyName }}</h1>
              <B>Regd. Office :</B> {{ currentUser.CompanyAddress }}
            </div>
            <div class="col-md-4 mt-3 p-1 text-right" style="color: black">
              <B class="fs-12">Voucher Type : </B>
              {{ voucherModel.Voucher.Voucher_Type }} ({{
                voucherModel.Voucher.Voucher_Sub_Type
              }})
            </div>
          </div>
          <div class="row m-0 mt-4">
            <div class="col-md-4 p-1 text-left" style="color: black">
              <B class="fs-12">Voucher No. : </B>
              {{ voucherModel.Voucher.Voucher_No }}
            </div>
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 p-1 text-right" style="color: black">
              <B class="fs-12">Voucher Date : </B>
              {{ voucherModel.Voucher.Voucher_Date }}
            </div>
          </div>

          <div
            class="row m-0"
            *ngIf="
              voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
              voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
            "
          >
            <div class="table-responsive">
              <mat-table
                [dataSource]="voucherModel.Voucher_Detail"
                matSort
                matTableExporter
                class="fs-14"
              >
                <ng-container matColumnDef="CaseNo">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 15%; background: #4dc3a3"
                    >Case No</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 15%"
                    >{{ row.CaseNo }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 15%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Customer">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%; background: #4dc3a3"
                    >Customer</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.Customer }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 10%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%; background: #4dc3a3"
                    >Branch</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.Branch_Name }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 10%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Category">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 20%; background: #4dc3a3"
                    >Category</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 20%"
                    >{{ row.ChagesHead }}</mat-cell
                  >
                  <mat-footer-cell
                    *matFooterCellDef
                    style="max-width: 20%"
                  ></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="AccountHead">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 20%"
                    >Account Head</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 20%"
                    >{{ row.Account_Name }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 20%"
                    ><B>Total</B></mat-footer-cell
                  >
                </ng-container>

                <ng-container matColumnDef="AmountDR">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >DR</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.AmountDR }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 10%"
                    ><B>{{ calculateTranTypeDR() }}</B></mat-footer-cell
                  >
                </ng-container>

                <ng-container matColumnDef="AmountCR">
                  <mat-header-cell
                    class="grid-header"
                    *matHeaderCellDef
                    style="max-width: 10%"
                    >CR</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 10%"
                    >{{ row.AmountCR }}</mat-cell
                  >
                  <mat-footer-cell *matFooterCellDef style="max-width: 10%"
                    ><B>{{ calculateTranTypeCR() }}</B></mat-footer-cell
                  >
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedLoanColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedLoanColumns"
                ></mat-row>
                <mat-footer-row
                  class="sticky-footer fontcolor"
                  *matFooterRowDef="displayedLoanColumns"
                ></mat-footer-row>
              </mat-table>
            </div>
          </div>

          <div
            class="row m-0"
            *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'"
          >
            <div class="col-md-12">
              <div class="table-responsive">
                <mat-table
                  [dataSource]="voucherModel.Voucher_Detail"
                  matSort
                  matTableExporter
                  class="fs-14"
                >
                  <ng-container matColumnDef="AccountHead">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Account Head</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Account_Name
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Branch</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.Branch_Name
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef
                      ><B>Total</B></mat-footer-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >DR</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountDR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef
                      ><B>{{ calculateTranTypeDR() }}</B></mat-footer-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >CR</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountCR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef
                      ><B>{{ calculateTranTypeCR() }}</B></mat-footer-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedAccountingColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedAccountingColumns"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedAccountingColumns"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-12 p-1 text-left" style="color: black">
              <B class="fs-12">Voucher Narration</B> :
              {{ voucherModel.Voucher.Voucher_Narration }}
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-1"><hr /></div>
          </div>

          <div class="row m-0 mt-1">
            <div class="col-md-3 p-1 text-left">
              <h6 class="fs-12">Voucher Created By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_CreateBy }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Voucher Created On</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_CreateOn }}
              </p>
            </div>
            <div
              class="col-md-3"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizBy != null"
            >
              <h6 class="fs-12">Voucher Authoriz By</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizBy }}
              </p>
            </div>
            <div
              class="col-md-3"
              *ngIf="voucherModel.Voucher.Voucher_AuthorizOn != null"
            >
              <h6 class="fs-12">Voucher Authoriz On</h6>
              <p class="lead fs-12">
                {{ voucherModel.Voucher.Voucher_AuthorizOn }}
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer non-printable">
          <button
            type="button"
            class="ml-4 mt-3 btn font-size-12 button-btn"
            (click)="OnClose()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="mt-3 btn font-size-12 button-btn"
            (click)="onDocsPrint('voucherPrinthhh' + index, 'Voucher Print')"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  </form>
  <div id="voucherPrinthhh{{ index }}" style="display: none">
    <div class="row m-0 mt-2">
      <div class="col-md-3 p-1">
        <img src="{{ CompanyProfileBaseUrl }}logo.png" alt="logo" width="100" />
      </div>
      <div class="col-md-5 text-left" style="color: black">
        <h1>{{ currentUser.CompanyName }}</h1>
        <B>Regd. Office :</B> {{ currentUser.CompanyAddress }}
      </div>
      <div class="col-md-4 mt-3 p-1 text-right" style="color: black">
        <B class="fs-12">Voucher Type : </B>
        {{ voucherModel.Voucher.Voucher_Type }} ({{
          voucherModel.Voucher.Voucher_Sub_Type
        }})
      </div>
    </div>
    <div class="row m-0 mt-4">
      <div class="col-md-4 p-1 text-left" style="color: black">
        <B class="fs-12">Voucher No. : </B>
        {{ voucherModel.Voucher.Voucher_No }}
      </div>
      <div class="col-md-4">&nbsp;</div>
      <div class="col-md-4 p-1 text-right" style="color: black">
        <B class="fs-12">Voucher Date : </B>
        {{ voucherModel.Voucher.Voucher_Date }}
      </div>
    </div>

    <div
      class="row m-0"
      *ngIf="
        voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
        voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
      "
    >
      <div class="table-responsive">
        <table
          class="table table-bordered bg-white"
          style="font-family: 'Times New Roman', Times, serif; margin-top: 20px"
        >
          <thead>
            <tr>
              <th style="background: #4dc3a3 !important; text-align: left">
                #
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                Case No.
              </th>

              <th style="background: #4dc3a3 !important; text-align: left">
                Customer
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                Branch
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                Category
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                Account Head
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                DR
              </th>
              <th style="background: #4dc3a3 !important; text-align: left">
                CR
              </th>
            </tr>
          </thead>

          <tr
            style="text-align: left"
            *ngFor="let row; let i = index; of: FileCheckingList"
          >
            <td>{{ i + 1 }}</td>

            <td style="text-align: left">{{ row.CaseNo }}</td>

            <td style="text-align: left">{{ row.Customer }}</td>
            <td style="text-align: left">{{ row.Branch_Name }}</td>
            <td style="text-align: left">{{ row.ChagesHead }}</td>
            <td style="text-align: left">{{ row.Account_Name }}</td>
            <td style="text-align: left">{{ row.AmountDR }}</td>
            <td style="text-align: left">{{ row.AmountCR }}</td>
          </tr>

          <tr>
            <td
              style="background: #4dc3a3 !important; text-align: right"
              colspan="6"
            >
              <strong>Total: </strong>
            </td>
            <td style="background: #4dc3a3 !important; text-align: left">
              <strong>{{ calculateTranTypeDR() }}</strong>
            </td>
            <td style="background: #4dc3a3 !important; text-align: left">
              <strong>{{ calculateTranTypeCR() }}</strong>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div
      class="row m-0"
      *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'"
    >
      <div class="col-md-12">
        <div class="table-responsive">
          <table
            class="table table-bordered bg-white"
            style="
              font-family: 'Times New Roman', Times, serif;
              margin-top: 20px;
            "
          >
            <thead>
              <tr>
                <th style="background: #4dc3a3 !important; text-align: left">
                  #
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  Account Head
                </th>

                <th style="background: #4dc3a3 !important; text-align: left">
                  Branch
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  DR
                </th>
                <th style="background: #4dc3a3 !important; text-align: left">
                  CR
                </th>
              </tr>
            </thead>

            <tr
              style="text-align: left"
              *ngFor="let row; let i = index; of: FileCheckingList"
            >
              <td>{{ i + 1 }}</td>

              <td style="text-align: left">{{ row.Account_Name }}</td>

              <td style="text-align: left">{{ row.Branch_Name }}</td>
              <td style="text-align: left">{{ row.AmountDR }}</td>
              <td style="text-align: left">{{ row.AmountCR }}</td>
            </tr>

            <tr>
              <td
                style="background: #4dc3a3 !important; text-align: right"
                colspan="3"
              >
                <strong>Total: </strong>
              </td>
              <td style="background: #4dc3a3 !important; text-align: left">
                <strong>{{ calculateTranTypeDR() }}</strong>
              </td>
              <td style="background: #4dc3a3 !important; text-align: left">
                <strong>{{ calculateTranTypeCR() }}</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row m-0 mt-2">
      <div class="col-md-12 p-1 text-left" style="color: black">
        <B class="fs-12">Voucher Narration</B> :
        {{ voucherModel.Voucher.Voucher_Narration }}
      </div>
    </div>

    <div class="row m-0">
      <div class="col-md-12 p-1"><hr /></div>
    </div>

    <div class="row m-0 mt-1">
      <div class="col-md-3 p-1 text-left">
        <h6 class="fs-12">Voucher Created By</h6>
        <p class="lead fs-12">
          {{ voucherModel.Voucher.Voucher_CreateBy }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Voucher Created On</h6>
        <p class="lead fs-12">
          {{ voucherModel.Voucher.Voucher_CreateOn }}
        </p>
      </div>
    </div>
  </div>
</div>
