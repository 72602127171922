import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-mst-documents",
  templateUrl: "./mst-documents.component.html",
  styleUrls: ["./mst-documents.component.scss"],
})
export class MstDocumentsComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  DeleteDocumentRequestModel: RequestModel = new RequestModel();
  dataSource: any;
  displayedColumns: string[] = ["DocId", "Type", "Category", "Documnet"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DocumentList: any = [];
  FilterDocument: any = "";

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Documents");
    this.getDocumentsList();
  }

  getDocumentsList() {
    this.showSpinner = true;
    this.RequestModel.DocCategory = null;
    this._MasterService
      .GetDocumentMaster(this.RequestModel)
      .subscribe((result) => {
        this.DocumentList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.DocumentList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  filterDocuments() {
    this.dataSource.filter = this.FilterDocument.trim().toLowerCase();
  }

  OnEditDocuments(selectedDocId) {}

  OnDeleteDocuments(selectedDocId) {
    this.DeleteDocumentRequestModel.DocId = selectedDocId;
    this._MasterService
      .DeleteDocumentMaster(this.DeleteDocumentRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getDocumentsList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
