<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #f="ngForm" (ngSubmit)="onSaveZone()" novalidate class="formborder">
    <div class="row m-0 row m-0 flex flex-row justify-content-between">
      <div class="col-md-4 p-1">
        <span class="required-lable"> Name </span>
        <input
          required
          type="text"
          #refZoneName="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refZoneName.invalid,
            'alert-warning':
              refZoneName.invalid &&
              (refZoneName.dirty ||
                refZoneName.touched ||
                refZoneName.untouched)
          }"
          [(ngModel)]="ZoneName"
          placeholder="Zone Name"
          name="ZoneName"
          id="ZoneName"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2 p-1">
        <button
          type="button"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!f.form.valid"
          (click)="onSaveZone()"
        >
          {{ ButtonName }}
        </button>
      </div>
    </div>
  </form>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="ZoneId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Zone_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Zone Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Zone_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="BranchCount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch Count
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.BranchCount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnEditZone(row.ZoneId, row.Zone_Name)"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="OnDeleteZoneData(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
