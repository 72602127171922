import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MessageService } from '../../_service/message.service';
import { PresenceService } from '../../_service/presence.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Form, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { constantUrl } from 'src/app/Shared/constantUrl';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $;

@Component({
  selector: 'app-chat-modal-sheet',
  templateUrl: './chat-modal-sheet.component.html',
  styleUrls: ['./chat-modal-sheet.component.scss']
})
export class ChatModalSheetComponent implements OnInit {
  currentUser: any = {};
  message: any;
  nameSearch: any;
  newAppSearch: any = "";
  applicationIdSearch: any;
  ApplicationId: any;
  applicationDetail = [];
  allEmpDetails: any[];
  showAppAddIcon = true;
  EmployeeDetails: any
  Attachement: any;
  AttachmentMsg: any;
  AttachementDisplay: any;
  docBaseUrl = constantUrl.CrcApplicationDocUrl;
  currentUserUrl: any;

  @ViewChild('message') msgbx: ElementRef;
  @ViewChild('msgbox') msgbox: ElementRef;
  @ViewChild('flexmsg') flexmsg: ElementRef;
  @ViewChild('attachbtn') attachbtn: ElementRef;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(public messageService: MessageService, public presenceService: PresenceService, public snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
    this.presenceService?.hubConnection.on('BadgeNotification', (EmpId, LoanId) => {
      let empIndex = this.allEmpDetails.findIndex(x => x.EmpId == EmpId)
      this.allEmpDetails[empIndex].UnRead_Chat_Count += 1;
      this.allEmpDetails.splice(0, 0, this.allEmpDetails.splice(empIndex, 1)[0])
      let applicationIndex = this.applicationDetail.findIndex(x => x.Loan_Id == LoanId)
      console.log("other user",messageService.otherUser.EmpId)
      if (messageService.otherUser.EmpId == EmpId && applicationIndex >= 0) {
        this.applicationDetail[applicationIndex].UnReadCount += 1;
        this.applicationDetail.splice(0, 0, this.applicationDetail.splice(applicationIndex, 1)[0])
      }
      else {
        this.GetApplicationDetailsFromEmp(this.messageService.otherUser.EmpId)
      }

    })
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"))
    this.currentUserUrl = `${constantUrl.apiProfilePicUrl}/${this.currentUser.ProfilePic}`
  }

  openModal() {
    $('#chat').modal('show')
    if (this.allEmpDetails.length == 0)
      this.GetAllEmployeeDetails();
  }
  onCloseChat() {
    $('#chat').modal('hide')
    this.messageService.stopHubConnection()
    this.ApplicationId = undefined;
  }

  sendMessage() {

    this.messageService.sendMessage(this.message, this.currentUser,this.ApplicationId);
    let ApplicationIndex = this.applicationDetail.findIndex(x => x.Application_No == this.ApplicationId)
    if(ApplicationIndex>0)
    this.applicationDetail.splice(0, 0, this.applicationDetail.splice(ApplicationIndex, 1)[0])
    //messageService.otherUser.EmpId ==
    let empindex=this.allEmpDetails.findIndex(x => x.EmpId == this.messageService.otherUser.EmpId)
    if(empindex>0)
    this.allEmpDetails.splice(0, 0, this.allEmpDetails.splice(empindex, 1)[0])
    this.resetForm()
    this.flexmsg.nativeElement.style.height = "28px"

  }
  resetForm() {
    this.message = ""
  }

  setOtherUser(emp) {
    this.messageService.otherUser = emp;
    this.messageService.stopHubConnection()
    this.ApplicationId = undefined
    console.log("setOther User", emp)
    this.GetChat_GetEmployee_Detail(emp.EmpId);
    console.log("Emp Detail", this.EmployeeDetails)
    this.GetApplicationDetailsFromEmp(emp.EmpId);

  }

  setApplication(Apj) {
    this.messageService.loanId = Apj.Loan_Id;
    this.ApplicationId = Apj.Application_No;
    if (Apj.UnReadCount > 0)
      this.presenceService.totalUncount.next(this.presenceService.totalUncount.value - Apj.UnReadCount)
    this.allEmpDetails.find(x => x.EmpId == this.messageService.otherUser.EmpId).UnRead_Chat_Count -= Apj.UnReadCount
    Apj.UnReadCount = 0;
    this.messageService.createHubConnection(this.currentUser)

  }


  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetAllEmployeeDetails()
  }

  GetAllEmployeeDetails() {
    this.messageService.GetEmployeeDropdown(this.currentUser).subscribe(x => {
      this.allEmpDetails = x;
    })
  }

  GetApplicationDetailsFromEmp(empId) {
    this.messageService.GetApplicationDetailsFromEmp(empId, this.currentUser).subscribe((x: any[]) => {
      this.applicationDetail = x;
      console.log("Application Details", this.applicationDetail)
    })
  }
  GetChat_GetEmployee_Detail(EmpId) {
    this.messageService.Chat_GetEmployee_Detail(EmpId).subscribe(x => {
      // console.log("xx value", x);
      this.EmployeeDetails = x[0]

    })
  }

  onAttachementChange(event$) {


    if (event$.target.files && event$.target.files[0]) {
      let Ext = event$.target.files[0].name.split('.').pop().toLowerCase();
      if (this.validatefile(event$.target.files))
        this.Attachement = event$.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.Attachement);
      reader.onload = (e) => {
        console.log("e", e)

        if (["doc", "docx", "pdf", "jpg", "jpeg", "png"].includes(Ext))
          this.AttachementDisplay = e.target.result
        // reader.result.toString();
        else {
          this.AttachementDisplay = "/assets/images/Doc_Image.jpg"
        }
      }
      // }
    }
    // console.log(event$)
  }

  onCloseAttachement() {
    this.Attachement = null;
    this.AttachementDisplay = null
    this.AttachmentMsg = "";
    this.attachbtn.nativeElement.value = null
    // console.log("Attach", this.attachbtn.nativeElement.files[0])
  }


  showAppSearchModal() {
    $('#appIdSearch').modal('show')
  }

  hideAppSearchModal() {
    $('#appIdSearch').modal('hide')
    this.newAppSearch = ""
  }

  SearchApplication() {
    this.messageService.searchNewApplication(this.newAppSearch).subscribe((x: any[]) => {
      console.log("Assigned", x)
      if (Number(x[0]["Loan_Id"]) > 0) {
        if (this.applicationDetail.findIndex(y => y["Loan_Id"] === x[0]["Loan_Id"]) <= 0) {
          this.applicationDetail.splice(0, 0, x[0]);
          console.log(x);

        }
        // console.log("Assigned")
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: x[0]["Application_No"], ...this.configSuccess })._dismissAfter(700);
      }
      this.changeIconStatus()
      // this.applicationIdSearch="";
      // this.showAppAddIcon=true;
    });
  }

  changeIconStatus() {
    this.newAppSearch = ""
    this.applicationIdSearch = "";
    this.showAppAddIcon = !this.showAppAddIcon;
  }

  validatefile(files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      return false;
    }

    var AllowExt: any = constantUrl.AllowExtension;
    if (!AllowExt.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      return false;
    }
    return true;
  }

  sendAttachement() {
    
    if (this.Attachement) {
      console.log("Attachement", this.Attachement);
      let DocData: any;
      const reader = new FileReader();
      reader.readAsDataURL(this.Attachement);
      reader.onload = e => {
        DocData = reader.result.toString().split(';base64,').pop();
        let _pre = new Date().getTime();
        let fileName = (_pre + '_' + this.Attachement.name).toString()
        let requestData = { DocData: DocData, DocName: fileName, ApplicationNo: this.ApplicationId }
        this.messageService.UploadChatDoc(requestData).subscribe(x => {
          if (!x) {

            this.snackBar.openFromComponent(SnackbarComponent, { data: "File Upload Failed", ...this.configSuccess });
          }
          else {
            console.log("Attach message",this.AttachmentMsg)
            console.log("sdfjsjfhdsf")
            this.messageService.sendMessage(this.AttachmentMsg, this.currentUser,this.ApplicationId, fileName);
            this.snackBar.openFromComponent(SnackbarComponent, { data: "File Upload Successfull", ...this.configSuccess });
          }
        }).add(()=>{
          this.onCloseAttachement()
        })
        
      };
      // console.log("Doc Data after",DocData)
    }


  }

  autoGrow() {
    this.msgbox.nativeElement.style.overflow = 'hidden';
    this.msgbox.nativeElement.style.height = '0px';
    this.flexmsg.nativeElement.style.height = this.msgbox.nativeElement.scrollHeight + 'px';
  }
  onKeydownMain(event){
  
    if (event.ctrlKey && (event.key === "Enter" )){
      event.preventDefault();
      this.sendMessage()
}
  }

}
