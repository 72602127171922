import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
declare var $: any;

@Component({
  selector: "app-mst-branch-product-process",
  templateUrl: "./mst-branch-product-process.component.html",
  styleUrls: ["./mst-branch-product-process.component.scss"],
})
export class MstBranchProductProcessComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetBranchesRequestModel: RequestModel = new RequestModel();
  dataSource: any;
  FromAmount: any = 0;
  ToAmount: any;
  finalList: any = [];
  displayedColumns: string[] = ["Process", "Employee"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  FilterBranchId: any = "";
  FilterBranchList: any = [];
  productList: any[] = [];
  currentUser: any;
  ProductId: any = "";
  EmployeeList = [];
  ID: any = 0;
  cardsData: any[];
  lastToLoanAmount: any;
  showResults: boolean = false;
  isEditMode: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private dataSharingService: DataSharingService,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit() {
    this.FromAmount = this.lastToLoanAmount;
    this.dataSharingService.HeaderTitle.next("Employee Branch Product Process");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetBranchesRequestModel.DistrictId = 0;
    this.GetBranchesRequestModel.TehsilId = 0;
    this._MasterService
      .GetBranchesDropdown(this.GetBranchesRequestModel)
      .subscribe((result) => {
        this.FilterBranchList = JSON.parse(JSON.stringify(result));
      });
    this.getProductList();
    this.RequestModel.BranchId = 0;
    this.RequestModel.RoleId = 0;
    this.MasterService.GetEmployeeList(this.RequestModel).subscribe(
      (res: any) => {
        this.EmployeeList = res;
      }
    );
  }

  getProductList() {
    this._MasterService.GetProductList({}).subscribe((result) => {
      this.productList = result;
    });
  }

  getBranchEmployeeProcessList() {
    this.showResults = true;
    this._MasterService
      .Get_Employee_Branch_Product_Process({
        branchId: this.FilterBranchId,
        productId: this.ProductId,
      })
      .subscribe((result) => {
        console.log("result", result);
        if (result.Item2.length > 0) {
          this.lastToLoanAmount =
            result.Item2[result.Item2.length - 1].To_LoanAmount;
          console.log("Last To_LoanAmount", this.lastToLoanAmount);

          const cardsData = [];

          result.Item2.forEach((item2) => {
            const matchingItem1 = result.Item1.find((item1) => {
              return item1.To_LoanAmount === item2.To_LoanAmount;
            });

            if (matchingItem1) {
              const processNames = matchingItem1.Process_Name.split(", ");
              const employeeNames = matchingItem1.Employees.split(", ");

              const cardData = {
                fromAmount: item2.From_LoanAmount,
                toAmount: item2.To_LoanAmount,
                processAndEmployee: [],
              };

              result.Item1.forEach((row) => {
                if (
                  row.From_LoanAmount == item2.From_LoanAmount &&
                  row.To_LoanAmount == item2.To_LoanAmount
                ) {
                  cardData.processAndEmployee.push({
                    processName: row.Process_Name,
                    employeeName: row.Employees,
                  });
                }
              });

              cardsData.push(cardData);
            }
          });

          this.cardsData = cardsData;
          this.FromAmount = this.lastToLoanAmount + 1;
          console.log("Cards", this.cardsData);
        } else {
          this.cardsData = [];
          this.FromAmount = 0;
        }
      });
  }

  AddBranchEmployeeProcess() {
    this.isEditMode = true;
    this.getBranchEmployeeProcessList();
    this.FromAmount = this.RequestModel.BranchId =
      this.FilterBranchId == "" ? 0 : parseInt(this.FilterBranchId);
    this.RequestModel.ProductId =
      this.ProductId == "" ? 0 : parseInt(this.ProductId);
    this._MasterService
      .GetProcesswisemployeeList(this.RequestModel)
      .subscribe((result) => {
        if (result.length > 0) {
          this.finalList = JSON.parse(JSON.stringify(result));
          this.finalList.forEach((obj: any) => {
            const idsArray = obj.EmpIds.split(",").map((item) =>
              parseInt(item.trim())
            );
            obj.EmployeeProcessList = this.EmployeeList.filter((item) =>
              idsArray.includes(item.EmpId)
            );
            obj.selectedOptions = [];
          });
          let index = this.finalList.findIndex((e) => e.ProcessId === 1);
          if (index !== -1) {
            this.finalList.splice(index, 1);
          }
          this.dataSource = new MatTableDataSource(this.finalList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          $("#BranchEmployeeProcessModel").modal("show");
          $("#BranchEmployeeProcessModel").css("z-index", "1050");
          this.showSpinner = false;
        }
      });
  }

  validateAmounts(): boolean {
    return this.ToAmount > this.FromAmount;
  }

  OnSaveBranchEmployeeProcessModel() {
    var _processList = [];
    var Error = 0;
    this.finalList.forEach((obj: any) => {
      if (obj.selectedOptions.length == 0) {
        Error = 1;
      }
      _processList.push({
        processId: obj.ProcessId,
        empids: obj.selectedOptions.join(","),
      });
    });
    if (Error == 0) {
      let _finalData = {
        Process: _processList,
      };

      this._MasterService
        .Save_Employee_Branch_Product_Process({
          branchId: this.FilterBranchId,
          productId: this.ProductId,
          loginUserId: this.currentUser.userId,
          Id: this.ID,
          json: JSON.stringify(_finalData),
          From_LoanAmount: this.FromAmount,
          To_LoanAmount: this.ToAmount,
        })
        .subscribe((result) => {
          this.OnCloseBranchEmployeeProcess();
        });
      console.log("_finalData", _finalData);
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: " Please select at least one Employee in all Process",
        ...this.configSuccess,
      });
    }
  }
  OnCloseBranchEmployeeProcess() {
    $("#BranchEmployeeProcessModel").modal("hide");
  }
  onSelectionChange(row: any, item) {
    item.selectedOptions = row;
  }
}
