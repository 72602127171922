import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { QuickLoanService } from "../../_QuickLoan/services/quickLoan.service";
import * as XLSX from "xlsx";
import * as fs from "file-saver";
declare var $: any;
import * as FileSaver from "file-saver";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-lms-collection-ex-assign-list",
  templateUrl: "./lms-collection-ex-assign-list.component.html",
  styleUrls: ["./lms-collection-ex-assign-list.component.scss"],
})
export class LmsCollectionExAssignListComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  loading: boolean = false;
  showSpinner: boolean = false;
  currentUser: any;
  ShowFilter = true;
  dataSource: any;
  displayedColumns: string[] = [];
  selectedId: any;
  SelectAll: any;
  SelectChk: any;
  JsonData: any[] = [];
  IsSelected: boolean = false;
  collection_exe_id: any = "";

  BranchForm: UntypedFormGroup;
  AreaForm: UntypedFormGroup;
  SubAreaForm: UntypedFormGroup;
  ProductForm: UntypedFormGroup;
  CollExecForm: UntypedFormGroup;

  BrancheDropdown: any = [];
  AreaDropdown: any = [];
  SubAreaDropdown: any = [];
  ProductDropdown: any = [];
  CollectionExDropdown: any = [];
  CollectionExecutive_Dropdown: any = [];

  BranchesId: any = "";
  AreasId: any = "";
  SubAreasId: any = "";
  ProductId: any = "";
  CollExId: any = "";

  Branches: any;
  BranchDropdownSettings: any = {};

  Areas: any;
  AreaDropdownSettings: any = {};

  SubAreas: any;
  SubAreaDropdownSettings: any = {};

  Products: any;
  ProductDropdownSettings: any = {};

  CollExec: any;
  CollExecDropdownSettings: any = {};

  DPDFrom: any;
  DPDTo: any;
  LoanNo: any;
  LoanDateFrom: any;
  LoanDateTo: any;
  NonStarterCase: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  exportedData: any;
  Matchhdata: any[];
  BlankDataFilldata: any[];
  dataSourceBlankDataFilldata: any;
  dataSourceNotValiddata: null;
  dataSourceDuplicate: null;
  InValidData: boolean;
  // ImportCollectionExecutive: null;
  DuplicateData: boolean;
  InValidDataInDataBase: any = 0;
  file: any;
  arrayBuffer: any;
  ImportCollectionExecutive: any[] = [];
  displayImportData: string[] = ["Emp_Id", "Loan_No"];
  resultsssssss: any;
  resultCheckImport: any;
  showSaveButton: boolean = false;
  columnWidth: string;

  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private lmsService: LmsService,
    private quickLoanService: QuickLoanService
  ) {}

  ngOnInit() {
    if (this.InValidDataInDataBase === 1) {
      this.columnWidth = "50%";
    } else if (this.InValidDataInDataBase === 2) {
      this.columnWidth = "25%";
    } else if (this.InValidDataInDataBase === 3) {
      this.columnWidth = "33.33%";
    }

    this.dataSharingService.HeaderTitle.next("Collection Ex. Assign List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getProductDropdown();
    this.getCollectionExecutive();
    this.getCollectionExecutive1();
    this.Get_Employee_ExportData();
    // Branch Settings
    this.BranchForm = this.fb.group({
      Branches: [],
    });
    this.BranchDropdownSettings = {
      singleSelection: false,
      idField: "BranchId",
      textField: "Branch_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    // Area Settings
    this.AreaForm = this.fb.group({
      Areas: [],
    });
    this.AreaDropdownSettings = {
      singleSelection: false,
      idField: "Int_Id",
      textField: "Area_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    // SubArea Settings
    this.SubAreaForm = this.fb.group({
      SubAreas: [],
    });
    this.SubAreaDropdownSettings = {
      singleSelection: false,
      idField: "Int_Id",
      textField: "Sub_Area_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    // Product Settings
    this.ProductForm = this.fb.group({
      Products: [],
    });
    this.ProductDropdownSettings = {
      singleSelection: false,
      idField: "ProductId",
      textField: "Product",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    // Collection Executive Settings
    this.CollExecForm = this.fb.group({
      CollExec: [],
    });
    this.CollExecDropdownSettings = {
      singleSelection: false,
      idField: "EmpId",
      textField: "EmpName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    this.displayedColumns = [
      "Select",
      "Loan_Id",
      "Application_No",
      "LoanAcNo",
      "Loan_Date",
      "Product_Name",
      "Branch_Name",
      "CollectionExecutive",
      "Area_Name",
      "Sub_Area_Name",
      "CustomerName",
      "Customer_PhoneNo",
      "Address",
      "RelationName",
      "LoanAmount",
      "EMIAmount",
      "Loan_Tenure",
      "EMI_Frequency",
      "TotalEMI_NOS",
      "EMI_DUE",
      "Total_Recv_EMI_Amount",
      "Total_OverDUE_EMI__Amount",
      "Last_Recv_Date",
      "Last_Recv_Amount",
      "current_Due_Date",
      "current_Due_Amount",
      "current_Due_EMI_No",
      "Total_EMI_AMount",
      "Total_Principal_Amount",
      "Total_Interest_AMount",
      "EMI_DUE_NOS",
      "Principal_DUE",
      "Interest_DUE",
      "Total_Recv_Principal_Amount",
      "Total_Recv_Interest_AMount",
      "receved_EMI",
      "OverDUE_EMI",
      "Total_OverDUE_Principal_Amount",
      "overDue_Interest_AMount",
      "Penal_Interest",
      "Other_Charges",
      "Balance_Principle",
      "Balance_Interest",
      "DPD_Days",
    ];

    // this.LMS_CollectionExecuitive_Check_Import();
  }

  getBranchesList() {
    this.BrancheDropdown = [];
    this.BranchesId = "";
    this.Branches = [];
    this.lmsService
      .GetBranches({ Emp_Id: this.collection_exe_id })
      .subscribe((result) => {
        this.BrancheDropdown = JSON.parse(JSON.stringify(result));
        this.BranchesId = this.BrancheDropdown.map(
          (element) => element.BranchId
        ).join(",");
        this.getCollectionExecutive();
      });
  }

  GetAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({ BranchId: this.BranchesId })
      .subscribe((result) => {
        this.AreaDropdown = JSON.parse(JSON.stringify(result));
      });
  }

  GetSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({ Area_Ids: this.AreasId })
      .subscribe((result) => {
        this.SubAreaDropdown = JSON.parse(JSON.stringify(result));
      });
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductDropdown = JSON.parse(JSON.stringify(result));
      });
  }

  getCollectionExecutive() {
    this.CollectionExDropdown = [];
    this.CollExId = "";
    //this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: this.BranchesId }).subscribe((result) => {
    //  this.CollectionExDropdown = JSON.parse(JSON.stringify(result));
    //});
    this.quickLoanService
      .LOS_GetManagerDashboardByTeam({
        ManagerId: this.currentUser.userId,
        TeamMemberId: 0,
      })
      .subscribe((res: any) => {
        if (res.Item1.length > 0) {
          res.Item1.push({ EmpId: 0, EmpName: ' Un-Assigned Cases', ApplicationCount: 0 })
          this.CollectionExDropdown = res.Item1.slice().sort((a, b) => a.EmpName.localeCompare(b.EmpName));
          this.CollExId = this.CollectionExDropdown.map(
            (element) => element.EmpId
          ).join(",");
        }
      });
  }

  getCollectionExecutive1() {
    this.quickLoanService
      .LOS_GetManagerDashboardByTeam({
        ManagerId: this.currentUser.userId,
        TeamMemberId: 0,
      })
      .subscribe((res: any) => {
        this.CollectionExecutive_Dropdown = res.Item1;
      });
    //this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId }).subscribe((result) => {
    //  this.CollectionExecutive_Dropdown = JSON.parse(JSON.stringify(result));
    //});
  }

  onBranchSelect(item: any) {
    this.BranchesId = this.Branches.map((element) => element.BranchId).join(
      ","
    );
    this.GetAreaByUserId();
    this.getCollectionExecutive();
  }

  onAresSelect(item: any) {
    this.AreasId = this.Areas.map((element) => element.Int_Id).join(",");
    this.GetSubAreaByUserId();
  }

  onSubAreaSelect(item: any) {
    this.SubAreasId = this.SubAreas.map((element) => element.Int_Id).join(",");
  }

  onProductsSelect(item: any) {
    this.ProductId = this.Products.map((element) => element.ProductId).join(
      ","
    );
  }

  onCollExecSelect(item: any) {
    this.CollExId = this.CollExec.map((element) => element.EmpId).join(",");
  }

  onSearchCollExAssign() {
    this.showSpinner = true;
    this.dataSource = "";
    let _finalData = {
      Search: {
        BranchId: this.BranchesId,
        LoginUserId: this.currentUser.userId,
        DPDFrom: this.DPDFrom,
        DPDTo: this.DPDTo,
        ProductId: this.ProductId,
        CollectionExecutive: this.CollExId,
        Area: this.AreasId,
        SubArea: this.SubAreasId,
        LoanAcNo: this.LoanNo,
        LoanDateFrom: this.LoanDateFrom,
        LoanDateTo: this.LoanDateTo,
        NonStarterCase: this.NonStarterCase == true ? 1 : 0,
      },
    };
    //console.log(_finalData);
    this.lmsService
      .LMS_Get_Assignment_DueList({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.JsonData.forEach((x) => {
            x.IsSelected = false;
          });
          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  totaltypewise(type) {
    if (this.dataSource != undefined) {
      if (this.dataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i][type]);
        }
        return sum > 0 ? sum.toFixed(2) : "";
      }
      return "";
    }
  }

  public highlightRow(row) {
    var RowIndex = this.dataSource.data.indexOf(row, 0);
    this.selectedId = RowIndex;
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "collection_executive_assignment"
    ) as HTMLTableElement;
    const worksheetName = "COLLECTION EXECUTIVE ASSIGNMENT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  checkUncheckAll(event) {
    //let type = event.source.name;
    //console.log("type", type);
    this.JsonData.forEach((x) => {
      if (event.checked == true) {
        x.IsSelected = true;
      }
      if (event.checked == false) {
        x.IsSelected = false;
      }
    });
    this.dataSource = new MatTableDataSource(this.JsonData);
  }

  OnSaveCollExAssign() {
    if (
      this.collection_exe_id == "" ||
      this.collection_exe_id == null ||
      this.collection_exe_id == undefined
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select Collection Executive to Assign!!",
        ...this.configSuccess,
      });
    } else {
      var LoanIds = "",
        json;
      var arr: any = [];
      var Branch_Name: any = [];
      this.JsonData.map((x) => {
        if (x.IsSelected == true) {
          LoanIds = LoanIds + x.Loan_Id + ",";

          arr.push({
            Loan_Id: x.Loan_Id,
            DPD_Days: x.DPD_Days,
            OverDue: x.Total_OverDUE_EMI__Amount,
            CollectionExecutive: x.CollectionExecutiveId,
          });
        }
      });

      json = { CollectionEx_Assign: arr };

      if (LoanIds != "") {
        this.lmsService
          .LMS_Get_NotExists_Branch_By_Emp({
            Loan_Ids: LoanIds,
            CollectionExecutive: this.collection_exe_id,
          })
          .subscribe((res: any) => {
            res.map((x) => {
              Branch_Name = Branch_Name + x.Branch_Name + ",";
            });

            //console.log("RES : ",res);

            if (res.length == 0) {
              this.lmsService
                .LMS_Save_Assignment_CollectionEx({
                  JSON: JSON.stringify(json),
                  CollectionExecutive: this.collection_exe_id,
                  Ids: LoanIds,
                  LoginUserId: this.currentUser.userId,
                })
                .subscribe((res: any) => {
                  this.showSpinner = false;
                  if (res[0].CODE >= 0) {
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: res[0].MSG,
                      ...this.configSuccess,
                    });
                    //this.onSearchCollExAssign();
                    this.dataSource = null;
                  } else {
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: res[0].MSG,
                      ...this.configSuccess,
                    });
                  }
                });
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "You have access only these branch" + Branch_Name,
                ...this.configSuccess,
              });
            }
          });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please select atleast one checkbox",
          ...this.configSuccess,
        });
      }
    }
  }

  GenerateDueList() {
    this.showSpinner = true;
    this.lmsService.LMS_Save_DueList({}).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.showSpinner = false;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      } else {
        this.showSpinner = false;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  ChangeAssigncollection_exe() {
    this.getBranchesList();
    this.dataSource = null;
  }

  Get_Employee_ExportData() {
    this._MasterService.Get_Employee_ExportData().subscribe((res: any) => {
      this.exportedData = res;
      // console.log("exported", this.exportedData);
    });
  }

  onClickExportTemplate() {
    this.Get_Employee_ExportData();
    var json = [["Emp_Id", "Loan_No"]];
    var table = document.getElementById("exported_data");

    var worksheet1: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    var worksheet2 = XLSX.utils.table_to_sheet(table);
    // console.log("Worksheet Table", worksheet2);

    var workbook: XLSX.WorkBook = {
      Sheets: {
        Template: worksheet1,
        CollectionExecutiveData: worksheet2,
      },
      SheetNames: ["Template", "CollectionExecutiveData"],
    };
    var excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Collection Executive Assignment");
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  onCloseImport1Model() {
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceDuplicate = null;
    this.dataSourceNotValiddata = null;
    this.ImportCollectionExecutive = null;

    $(`#Import1Model`).modal("hide");
  }

  openImport1Model() {
    $(`#Import1Model`).modal("show");
    $(`#Import1Model`).css("z-index", "1090");
  }

  onClickImportTemplate(event) {
    // this.showSpinner = true;
    this.Matchhdata = [];
    this.BlankDataFilldata = [];
    this.dataSourceBlankDataFilldata = null;
    this.dataSourceDuplicate = null;
    this.dataSourceNotValiddata = null;
    this.ImportCollectionExecutive = null;
    this.DuplicateData = false;
    this.InValidData = false;
    this.InValidDataInDataBase = 0;

    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.file = target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.ImportCollectionExecutive = XLSX.utils.sheet_to_json(worksheet);

      if (this.ImportCollectionExecutive.length > 0) {
        this.openImport1Model();
        this.ImportCollectionExecutive.forEach((x, index) => {
          if (
            x.Emp_Id == "" ||
            x.Emp_Id == undefined ||
            x.Loan_No == "" ||
            x.Loan_No == undefined
          ) {
            this.InValidDataInDataBase = 1;
            this.displayImportData = ["Emp_Id", "Loan_No"];
            this.BlankDataFilldata.push(x);
            console.log("BlankDataFilldata", this.BlankDataFilldata);
            this.dataSourceBlankDataFilldata = new MatTableDataSource(
              this.BlankDataFilldata
            );
            console.log(
              "dataSourceBlankDataFilldata",
              this.dataSourceBlankDataFilldata
            );
            this.showSpinner = false;
            // this.snackBar.openFromComponent(SnackbarComponent, {
            //   data: "Please Fill All Mandatory Fields !!!",
            //   ...this.configSuccess,
            // });
          }
        });

        if (this.BlankDataFilldata.length == 0) {
          this.LMS_CollectionExecuitive_Check_Import();
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Fill Emp Id and Loan No !!!",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    };
    this.fileInput.nativeElement.value = "";
  }

  LMS_CollectionExecuitive_Check_Import() {
    this.lmsService
      .LMS_CollectionExecuitive_Check_Import({
        JSON: JSON.stringify(this.ImportCollectionExecutive),
      })
      .subscribe((result) => {
        this.resultCheckImport = JSON.parse(JSON.stringify(result));
        // console.log("result", this.resultCheckImport);
        if (this.resultCheckImport.length > 0) {
          // console.log("Atleast Loop started");
          // this.BlankDataFilldata = [];
          this.resultCheckImport.forEach((x, index) => {
            if (x.Error != "") {
              console.log("displayImportData", this.displayImportData);
              this.BlankDataFilldata.push(x);
              console.log("BlankDataFilldata", this.BlankDataFilldata);
            }
          });
          if (this.BlankDataFilldata.length > 0) {
            this.InValidDataInDataBase = 2;
            // console.log("this is code 2,and i have to write Please fill all the details corr")
            this.displayImportData = ["Emp_Id", "Loan_No", "Emp_Name", "Error"];
            this.dataSourceBlankDataFilldata = new MatTableDataSource(
              this.BlankDataFilldata
            );
            this.showSaveButton = false;
          } else if (this.BlankDataFilldata.length == 0) {
            this.showSaveButton = true;
            this.InValidDataInDataBase = 3;
            this.displayImportData = ["Emp_Id", "Loan_No", "Emp_Name"];
            this.dataSourceBlankDataFilldata = new MatTableDataSource(
              this.resultCheckImport
            );
          }
          this.showSpinner = false;
        }
      });
  }

  OnSaveCollExec() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Save_CollectionExecuitive_Import({
        JSON: JSON.stringify(this.ImportCollectionExecutive),
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        // console.log("result of save api call", this.res);
        if (res[0].CODE == 0) {
          this.showSpinner = false;
          this.Matchhdata = [];
          this.BlankDataFilldata = [];
          this.dataSourceBlankDataFilldata = null;

          this.ImportCollectionExecutive = null;

          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
    this.onCloseImport1Model();
  }
}
