import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { LosService } from '../../_LOS/services/los.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarComponent } from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-loginfeereceipt',
  templateUrl: './loginfeereceipt.component.html',
  styleUrls: ['./loginfeereceipt.component.scss']
})
export class LoginfeereceiptComponent implements OnInit {
  applicationId: any;
  currentUser: any;
  receiptDetail: any;
  showSpinner: boolean = false;
  FileId: any;
  MobileNo: any;
  showreceiptDetail: boolean = false;
  showreceiptForm: boolean = true;
  detail: any;
  constructor(private route: ActivatedRoute, public snackBar: MatSnackBar, private encdec: EncrDecrService, private losService: LosService) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      console.log(param.get("id"));
      //this.applicationId = encdec.decrypt(param.get("id"));
      this.applicationId = decodeURIComponent(param.get("id"));
      console.log('this.applicationId ', this.applicationId);
    });
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  ngOnInit() {
    console.log(this.applicationId);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.getLoginFeeReceipt(); 
  }
  searchLoginFeeReceipt() {
    this.showSpinner = true;
    this.losService.LOS_GetLoginFee_Receipt({ FileId: this.FileId, PhoneNo: this.MobileNo }).subscribe((res: any) => {
      console.log(res);
      if (res[0].CODE == 0) {
        this.showreceiptForm = false;
        this.showreceiptDetail = true;
        this.receiptDetail = res[0];
        
      } else {
        console.log(res[0].CODE);
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].Msg, ...this.configSuccess });

        this.MobileNo = '';
        this.FileId = '';
      }

    })
  }
  printLetter() {
    // this.loading = true;
    setTimeout(() => {
      this.downloadPdf();
    }, 1000);
  }
  downloadPdf() {
    // var data = document.getElementById('pdfSenctionDownload');
    var prtContent = document.getElementById("pdfReceiptDownload");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();

  }
}
