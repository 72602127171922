<app-mat-spinner-overlay *ngIf="loading"></app-mat-spinner-overlay>

<!-- DropDown for Printing Sanction Letter -->
<div class="dropdown">
  <button
    class="mt-2 btn font-size-12 button-btn dropdown-toggle"
    style="padding: 10px"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Print Welcome Letter
  </button>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    style="max-height: 95px; overflow: auto"
  >
    <a
      class="dropdown-item"
      *ngFor="let x of dropdownLanguages"
      (click)="Get_Template(x.Id)"
      >{{ x.Template_Name }}</a
    >
  </div>
</div>

<!-- Print Sanction Letter Modal -->
<div
  class="modal fade in"
  id="Welcomemodel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <button
          type="button"
          class="font-size-12 button-btn"
          [class.spinner]="loading"
          (click)="downloadPdf()"
        >
          Print Welcome Letter
        </button>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div
        class="modal-body"
        id="pdfWelcomeDownload"
        style="overflow-wrap: anywhere"
      >
        <div class="row mb-0">
          <div class="col-md-12 ck-content">
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
