<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="OnSearchReport_Exposure()"
  class="formborder"
>
  <div class="row m-0 col-md-12 p-0" style="display: flex; align-items: center;">
    <div class="col-md-2">
      <span class="required-lable">As On Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateFrom"
          id="DateFrom"
          required
          #refDateFrom="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="DateFrom"
          [ngClass]="{
            'is-invalid': df.submitted && refDateFrom.invalid,
            'alert-warning':
              refDateFrom.invalid &&
              (refDateFrom.dirty || refDateFrom.touched || refDateFrom.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="DateFrom"
        [(ngModel)]="DateFrom"
        id="DateFrom"
        required
        #refDateFrom="ngModel"
        placeholder="Date"
        class="form-control input-text-css"
        bsDatepicker
        [ngClass]="{
          'is-invalid': df.submitted && refDateFrom.invalid,
          'alert-warning':
            refDateFrom.invalid &&
            (refDateFrom.dirty || refDateFrom.touched || refDateFrom.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-2">
      <span class="required-lable"> Accounting Category </span>
      <select
        name="Loan_AccountingCategoryId"
        id="Loan_AccountingCategoryId"
        [(ngModel)]="LoanAccountCategory"
        class="form-control input-text-css"
      >
        <option value="0">All</option>
        <option
          *ngFor="let AccountingCat of AccountingCatDropdown"
          [value]="AccountingCat.Loan_CategoryId"
        >
          {{ AccountingCat.Loan_category }}
        </option>
      </select>
    </div>
    <div class="col-md-4">
      <button
        type="button"
        (click)="OnSearchReport_Exposure()"
        [disabled]="!df.form.valid"
       
        class="mt-3 btn font-size-12 button-btn"
        
      >
        Search
      </button>
    </div>
    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>


<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive" style="overflow: auto;height: 65vh;">
      <table mat-table
             class="table-bordered"
             matSort
             [dataSource]="dataSource"
             matTableExporter
             #exporter="matTableExporter"
             id="DueList">
        <ng-container matColumnDef="Loan_Id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_AgreementValue">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Application Agreement Value
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Application_AgreementValue }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Branch Name</th>
          <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>


        <ng-container matColumnDef="Gender">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Gender
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Gender }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="DisbursmentAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Disbursment Amount
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">{{ row.DisbursmentAmount }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-right">{{ totaltypewise("DisbursmentAmount") }}</th>
        </ng-container>
        <ng-container matColumnDef="ExpiryDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiry Date</th>
          <td mat-cell *matCellDef="let row">{{ row.ExpiryDate }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Future_Interest">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Future Interest
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Future_Interest }}
          </td>
          <th mat-footer-cell *matFooterCellDef>
            {{ totaltypewise("Future_Interest") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Installment_Balance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Installment Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Installment_Balance }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">
            {{ totaltypewise("Installment_Balance") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Installment_Due">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Installment Due
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Installment_Due }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">
            {{ totaltypewise("Installment_Due") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Installment_Mature">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Installment Receipt
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Installment_Mature }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">
            {{ totaltypewise("Installment_Mature") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Interest_Income">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Interest Income
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Interest_Income }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">
            {{ totaltypewise("Interest_Income") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>LoanAcNo</th>
          <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Acc_Category">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Loan_Acc_Category
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Acc_Category }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Loan_Date  }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Other_Balance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Other Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Other_Balance }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center">
            {{ totaltypewise("Other_Balance") }}
          </th>
        </ng-container>
        <ng-container matColumnDef="Other_Due">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Other Due</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Other_Due }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Other_Due") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Other_Receipt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Other Receipt
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Other_Receipt }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Other_Receipt") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Principal_Balance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Principal Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Principal_Balance }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Principal_Balance") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Principal_Due">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Principal Due
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Principal_Due }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Principal_Due") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Principal_Mature">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Principal Mature
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Principal_Mature }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Principal_Mature") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Product_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Product name
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Product_name }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">Total:</th>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"></tr>
        <tr mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"></tr>
      </table>
    </div>
  </div>
</div>
<div class="row m-0 ml-3"  [hidden]="!dataSource">
  <div class="col-md-4 mt-3">
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportexcelNew()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-3">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="exposure_report_voucher"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gender
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application Agreement Value
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursment Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle Mature
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Installment Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Installment Receipt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Installment Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Income
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Receipt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Balance
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td style="text-align: right">{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Gender }}</td>
    <td>{{ x.Loan_Date  }}</td>
    <td>{{ x.Application_AgreementValue }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_name }}</td>
    <td>{{ x.DisbursmentAmount }}</td>
    <td>{{ x.Principal_Due }}</td>
    <td>{{ x.Principal_Mature }}</td>
    <td>{{ x.Principal_Balance }}</td>
    <td>{{ x.Installment_Due }}</td>
    <td>{{ x.Installment_Mature }}</td>
    <td>{{ x.Installment_Balance }}</td>
    <td>{{ x.Interest_Income }}</td>
    <td>{{ x.Future_Interest }}</td>
    <td>{{ x.Other_Due }}</td>
    <td>{{ x.Other_Receipt }}</td>
    <td>{{ x.Other_Balance }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="8"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_Mature") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_Balance") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Installment_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Installment_Mature") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Installment_Balance") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Interest_Income") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Future_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Other_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Other_Receipt") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Other_Balance") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
