import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from './Models/app.LoginModel';
import { LoginService } from './Services/app.LoginService';
import { MasterService } from '../Shared/app.Masters.Service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { IpServiceService } from '../../app/ip-service.service';
import { DataSharingService } from '../AuthGuard/DataSharingService';
import { EncrDecrService } from "../AuthGuard/EncrDecrService";
import { constantUrl } from "../Shared/constantUrl";
import { PresenceService } from '../_Chatbox/_service/presence.service';
declare var $: any;
import devtools from 'devtools-detect';
import { environment } from '../../environments/environment';
@Component({
  templateUrl: './app.login.html',
  styleUrls: ['../../../node_modules/bootstrap/dist/css/bootstrap.min.css',
    '../../assets/Content/vendor/metisMenu/metisMenu.min.css',
    '../../assets/Content/dist/css/sb-admin-2.css',
    '../../assets/Content/vendor/font-awesome/css/font-awesome.min.css',
    './login.component.scss',
  ]
})

export class LoginComponent implements OnInit {
  currentUser: any;
  ConfirmPassword: string; NewPassword: string; OTPCode: string;
  private _loginservice; private _Masterservice;
  output: any; Email: string; Mobile: string;
  showSpinner = false;
  loading: boolean = false; ipAddress: string;
  smsmodel: any = {}; OTP: string;
  OTPMODEL: any = {}; EmpId: number;
  LoginOTPOTP: any;
  LoginOTPOTPEncript: any;
  lastMobileNo: any;
  firstMobileNo: any;
  constructor(private _Route: Router, private dataSharingService: DataSharingService, public snackBar: MatSnackBar, masterservice: MasterService, loginservice: LoginService, private ip: IpServiceService, private EncrDecrService: EncrDecrService,) {
    this._loginservice = loginservice;
    this._Masterservice = masterservice;
    this._Masterservice.CheckMACAllowed({ "MacAddresss": this._Masterservice.getMacAddress() }).subscribe(response => {
      if (!(response[0]["CODE"] == 0 && response[0]["MSG"] == "Allow")) {
        this._Route.navigate([`Page403Error`]);
      }
      // console.log("H Start h", response)
    })
    //if (environment.production && devtools.isOpen) {
    //  this._Route.navigate([`Page403Error`]);
    //}
    if (environment.production) {
      var allow;
      if (isNaN(+allow)) allow = 100;
      var start = +new Date();
      debugger;
      var end = +new Date();
      if (isNaN(start) || isNaN(end) || end - start > allow) {
        this._Route.navigate([`Page403Error`]);
      }

    }
  }

  LoginModel: LoginModel = new LoginModel();
  BasePath: any = constantUrl.apiProfilePicUrl;
  CompanyLogo: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 10000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit(): void {
    sessionStorage.clear();
    // this.getMacList();
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CompanyLogo = this.currentUser.CompanyLogo;

  }


  onClose() {

  }
  onhome() {
    this._Route.navigate(['/Home']);
  }

  onOTP() {
    if (this.NewPassword != this.ConfirmPassword) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "New and Confirm Password Must be Same", ...this.configSuccess, });
      return;
    } else {
      this._Masterservice.ForgotOTPValid({ OTPCode: this.OTP, UserId: this.LoginModel.UserLoginID, NewPassword: this.NewPassword, MobileNo: this.Mobile }).subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].CODE != 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "OTP is not valid", ...this.configSuccess, });
            return;
          }
          else {
            $("#OTPModel").modal("hide");
            $("#ResetPasswordModel").modal("hide");
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
             return;
          }
          //else if (this.NewPassword != this.ConfirmPassword) {
          //  this.snackBar.openFromComponent(SnackbarComponent, { data: "New and Confirm Password Must be Same", ...this.configSuccess, });
          //  return;
          //}
          //else {
          //  this.OTPMODEL.UserId = this.EmpId;
          //  this.OTPMODEL.NewPassword = this.NewPassword;
          //  this._Masterservice.UpdateUserPass(this.OTPMODEL).subscribe(response => {
          //    if (response[0].CODE == 0) {
          //      this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess, });
          //      $("#OTPModel").modal("hide");
          //      $("#ResetPasswordModel").modal("hide");
          //    }

          //  });
          //}
        }
      })
    }

  }

  onGetResetPassword() {
    this.LoginModel.UserLoginID = this.Email;
    this.LoginModel.MobileNo = this.Mobile;
    //this._Masterservice.GetResetPassword(this.LoginModel).subscribe(response => {
    //  if (response[0].CODE == 1) {
       // this.EmpId = response[0].EmpId;
        //this.OTPCode = response[0].OTPCode;
        this._Masterservice
          .Get_SMS_Detail_Exists({ stepName: 'FORGET_PASSWORD' })
          .subscribe((res: any) => {
            if (res.length > 0 && res[0].CODE == 0) {


              //this.smsmodel.MobileNo = this.Mobile;
              ////res[0].SMS_Template = res[0].SMS_Template.replace('@OTP', this.OTPCode);
              //res[0].SMS_Template = res[0].SMS_Template.replace('@UserName', this.Email);

              //res[0].SMS_Template = res[0].SMS_Template.replace('\n', '');
              //res[0].Sub_url = res[0].Sub_url.replace('[KEY]', res[0].Key);
              //res[0].Sub_url = res[0].Sub_url.replace('[CAMPAIGN]', res[0].campaign);
              //res[0].Sub_url = res[0].Sub_url.replace('[ROUTE]', res[0].route);
              //res[0].Sub_url = res[0].Sub_url.replace('[TYPE]', res[0].SubType);
              //res[0].Sub_url = res[0].Sub_url.replace('[MOBILENO]', this.Mobile);
              //res[0].Sub_url = res[0].Sub_url.replace('[SENDERID]', res[0].Sender_Id);
              //res[0].Sub_url = res[0].Sub_url.replace('[MSG]', res[0].SubType == 'unicode' ? this.utf8Encode(res[0].SMS_Template) : res[0].SMS_Template);
              //res[0].Sub_url = res[0].Sub_url.replace('[TEMPLATE_ID]', res[0].TemplateId);
              //res[0].Sub_url = res[0].Sub_url.replace('\r', '');
              //res[0].Sub_url = res[0].Sub_url.replace('\n', '');

              //// key = [KEY] & campaign=[CAMPAIGN] & routeid=[ROUTE] & type=[TYPE] & contacts=[MOBILENO] &
              //// senderid=[SENDERID] & msg=[MSG] & template_id=[TEMPLATE_ID]
              //this.smsmodel.sentMessage = res[0].SMS_Template;
              ////this.smsmodel.key = res[0].Key;
              ////this.smsmodel.route = res[0].route;
              ////this.smsmodel.Sender_Id = res[0].Sender_Id;
              //this.smsmodel.SMS_URL = res[0].SMS_URL;
              //this.smsmodel.Sub_url = res[0].Sub_url;

              this._Masterservice.MessageSendForgotOTP({ EmpId: this.EmpId, stepName: 'FORGET_PASSWORD', UserId: this.LoginModel.UserLoginID, MobileNo: this.LoginModel.MobileNo }).subscribe((res: any) => {
                if (res.length > 0 && res[0].CODE == 1) {
                  // this.OTPCode = res[0].OTP;
                  $("#OTPModel").modal("show");
                  $("#OTPModel").css("z-index", "1050");
                }
              });
            }
            else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
              return;
            }
          });
        //$("#OTPModel").modal("show");
        //$("#OTPModel").css("z-index", "1050");
    //  }
    //  else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].Msg, ...this.configSuccess, });
    //    return;
    //  }
    //});
  }
  utf8Encode(unicodeString) {
    if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
    const utf8String = unicodeString.replace(
      /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
      function (c) {
        var cc = c.charCodeAt(0);
        return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f);
      }
    ).replace(
      /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
      function (c) {
        var cc = c.charCodeAt(0);
        return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3F, 0x80 | cc & 0x3f);
      }
    );
    return utf8String;
  }
  OnForgetPassword() {
    $("#ResetPasswordModel").modal("show");
    $("#ResetPasswordModel").css("z-index", "1050");
  }

  onSubmit() {
    this.showSpinner = true;
    this.loading = true;
    sessionStorage.clear();
    this.LoginModel.Password = this.EncrDecrService.encrypt1(this.LoginModel.Password);
    this.LoginModel.IPAddreess = this.ipAddress;
    this.LoginModel.MacAddresss = this._Masterservice.getMacAddress();

    this._loginservice.validateLoginUser(this.LoginModel).subscribe(response => {
      console.log("responseresponse", response)
      if (response.Token == null && response.Usertype == "0") {
        this.loading = false;
        this.showSpinner = false;
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'Invalid Username or Password', ...this.configSuccess });
        this.showSpinner = false;
        this._Route.navigate(['Login']);
      }
      else if (response.Usertype != "0") {
        this.Mobile = response.Emp_PhoneNo;
        this._Masterservice.GetResetPassword({ UserLoginID: this.LoginModel.Username, MobileNo: response.Emp_PhoneNo }).subscribe(result => {
          if (result[0].CODE == 1) {
            this.EmpId = result[0].EmpId;
            //this.OTPCode = result[0].OTPCode;
            this._Masterservice
              .Get_SMS_Detail_Exists({ stepName: 'Login OTP' })
              .subscribe((res: any) => {
                if (res.length > 0 && res[0].CODE==0) {
                  this.lastMobileNo = this.Mobile.substring(7, 10);
                  this.firstMobileNo = this.Mobile.substring(0, 2);
                  //this.smsmodel.MobileNo = this.Mobile;
                  ////res[0].SMS_Template = res[0].SMS_Template.replace('@OTP', this.OTPCode);
                  //res[0].SMS_Template = res[0].SMS_Template.replace('\n', '');
                  //res[0].Sub_url = res[0].Sub_url.replace('[KEY]', res[0].Key);
                  //res[0].Sub_url = res[0].Sub_url.replace('[CAMPAIGN]', res[0].campaign);
                  //res[0].Sub_url = res[0].Sub_url.replace('[ROUTE]', res[0].route);
                  //res[0].Sub_url = res[0].Sub_url.replace('[TYPE]', res[0].SubType);
                  //res[0].Sub_url = res[0].Sub_url.replace('[MOBILENO]', this.Mobile);
                  //res[0].Sub_url = res[0].Sub_url.replace('[SENDERID]', res[0].Sender_Id);
                  //res[0].Sub_url = res[0].Sub_url.replace('[MSG]', res[0].SubType == 'unicode' ? this.utf8Encode(res[0].SMS_Template) : res[0].SMS_Template);
                  //res[0].Sub_url = res[0].Sub_url.replace('[TEMPLATE_ID]', res[0].TemplateId);
                  //res[0].Sub_url = res[0].Sub_url.replace('\r', '');
                  //res[0].Sub_url = res[0].Sub_url.replace('\n', '');

                  //this.smsmodel.sentMessage = res[0].SMS_Template;
                  //this.smsmodel.SMS_URL = res[0].SMS_URL;
                  //this.smsmodel.Sub_url = res[0].Sub_url;
                  this.loading = false;
                  this.showSpinner = false;

                  this._Masterservice.MessageSendOTP({ EmpId: this.EmpId, stepName:'Login OTP' }).subscribe((res: any) => {
                    if (res.length > 0 && res[0].CODE==1) {
                      //this.OTPCode = res[0].OTP;
                      $("#LoginOTPModel").modal("show");
                      $("#LoginOTPModel").css("z-index", "1050");
                    }
                  });
                }
                else {

                  this.loading = false;
                  this.showSpinner = false;

                  this.snackBar.openFromComponent(SnackbarComponent, { data: 'Logged in Successfully', ...this.configSuccess });
                  this.dataSharingService.RefreshLoginPage.next('true');
                  this._Route.navigate(['/index/page']);


                }
              });
          }
          else {
            this.loading = false;
            this.showSpinner = false;

            this.snackBar.openFromComponent(SnackbarComponent, { data: result[0].Msg, ...this.configSuccess, });
            return;
          }
        });


      }
    });
  }
  onLoginOTPModelClose() {
    $("#LoginOTPModel").modal("hide");
    this.OTPCode = '';
    this.Mobile = '';
    this.lastMobileNo = '';
    this.firstMobileNo = '';
    this._Route.navigate([`/UserLogout`]);

  }

  onLoginOTPModelOTP() {
    this._Masterservice.OTPValid({ OTPCode: this.LoginOTPOTP.toString() }).subscribe((res: any) => {
      if (res.length > 0 ) {
        if (res[0].CODE!=0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "OTP is not valid", ...this.configSuccess, });
          return;
        } else {
          this.loading = false;
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'Logged in Successfully', ...this.configSuccess });
          this.dataSharingService.RefreshLoginPage.next('true');
          this._Route.navigate(['/index/page']);
          $("#LoginOTPModel").modal("hide");
          this.OTPCode = '';
          this.Mobile = '';
          this.lastMobileNo = '';
          this.firstMobileNo = '';
          //this.onLoginOTPModelClose();
        }
      }
    });

  }
}
