<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #df="ngForm" novalidate (keyup.enter)="onSearchPartnerBookingList()">
    <div class="row m-0 col-md-12 p-0 formborder">
      <div class="col-md-2">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            [(ngModel)]="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            [(ngModel)]="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <!--<div class="col-md-3">
        <span class="required-lable"> Select Type </span>
        <select
          name="Loan_Acc_Type"
          id="Loan_Acc_Type"
          [(ngModel)]="Loan_Acc_Type"
          (change)="onChangeType()"
          class="form-control input-text-css"
          #refLoan_Acc_Type="ngModel"
          required disabled
          [ngClass]="{
            'is-invalid': df.submitted && refLoan_Acc_Type.invalid,
            'alert-warning':
              refLoan_Acc_Type.invalid &&
              (refLoan_Acc_Type.dirty ||
                refLoan_Acc_Type.touched ||
                refLoan_Acc_Type.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Channel">Channel</option>
          <option value="RSP">RSP</option>
          <option value="Co-Lending">Partner</option>
        </select>
      </div>-->
      <div class="col-md-3">
        <span class="required-lable"> Partner</span>
        <select
          name="ACCategType"
          id="ACCategType"
          [(ngModel)]="ACCategType"
          class="form-control input-text-css"
          #refACCategType="ngModel"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refACCategType.invalid,
            'alert-warning':
              refACCategType.invalid &&
              (refACCategType.dirty ||
                refACCategType.touched ||
                refACCategType.untouched)
          }"
        >
          <option value="">Select Partner</option>
          <option
            *ngFor="let AcCategory of AcCatgList"
            [value]="AcCategory.Loan_CategoryId"
          >
            {{ AcCategory.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchPartnerBookingList()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" [hidden]="!DataSource">
    <mat-table
      [dataSource]="DataSource"
      matSort
      matTableExporter
      #exporterLoanInfo="matTableExporter"
      style="max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px; border-right: 1px #f7f9f9 solid"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1) }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Partner</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_LoanAcNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_LoanAcNo }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Co">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Co
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Co }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Co_name">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Co Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Co_name }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="Customer_DUE_EMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_DUE_EMI }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_DUE_EMI") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_DUE_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_DUE_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_DUE_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_DUE_Interest">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_DUE_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_DUE_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_DUE_EMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_DUE_EMI }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_DUE_EMI") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_DUE_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_DUE_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_DUE_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_DUE_Interest">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_DUE_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_DUE_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="DUE_Diff_EMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.DUE_Diff_EMI }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("DUE_Diff_EMI") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="DUE_Diff_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.DUE_Diff_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("DUE_Diff_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="DUE_Diff_Interest">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.DUE_Diff_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("DUE_Diff_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_Recv_EMI_Amount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_Recv_EMI_Amount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_Recv_EMI_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_Recv_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_Recv_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_Recv_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_Recv_Interest">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_Recv_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Customer_Recv_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_Recv_EMI_Amount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_Recv_EMI_Amount }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_Recv_EMI_Amount") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_Recv_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_Recv_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_Recv_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Partner_Recv_Interest">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="border-right: 1px #f7f9f9 solid"
          >Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Partner_Recv_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Partner_Recv_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Recv_Diff_EMI">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >EMI</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Recv_Diff_EMI }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Recv_Diff_EMI") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Recv_Diff_Principal">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >POS</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Recv_Diff_Principal }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Recv_Diff_Principal") : "" }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Recv_Diff_Interest">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Interest</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Recv_Diff_Interest }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header">
          {{ DataSource ? totaltypewise("Recv_Diff_Interest") : "" }}
        </mat-footer-cell>
      </ng-container>

      <!-- Top header column start here -->
      <ng-container matColumnDef="row-first">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="6"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>

      <ng-container matColumnDef="row-second">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="9"
          style="border-right: 1px #f7f9f9 solid"
        >
          Due-Detail
        </th>
      </ng-container>

      <ng-container matColumnDef="row-third">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="9"
          style="border-right: 1px #f7f9f9 solid"
        >
          Received-Detail
        </th>
      </ng-container>

      <ng-container matColumnDef="row2-first">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="1"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>
      <ng-container matColumnDef="row2-second">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="2"
          style="border-right: 1px #f7f9f9 solid"
        >
          Loan A/C No.
        </th>
      </ng-container>

      <ng-container matColumnDef="row2-third">
        <th
          mat-header-cell
          class="grid-header"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          &nbsp;
        </th>
      </ng-container>

      <ng-container matColumnDef="row2-fourth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Receivable From Customer
        </th>
      </ng-container>
      <ng-container matColumnDef="row2-fifth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Payable To Partner
        </th>
      </ng-container>
      <ng-container matColumnDef="row2-sixth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Margin/Difference
        </th>
      </ng-container>

      <ng-container matColumnDef="row2-seventh">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Received From Customer
        </th>
      </ng-container>
      <ng-container matColumnDef="row2-eighth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Paid To Partner
        </th>
      </ng-container>
      <ng-container matColumnDef="row2-nineth">
        <th
          mat-header-cell
          class="grid-header text-center"
          *matHeaderCellDef
          [attr.colspan]="3"
          style="border-right: 1px #f7f9f9 solid"
        >
          Margin/Difference
        </th>
      </ng-container>
      <!-- Top header column end here -->

      <!-- Top header row start here -->
      <tr
        mat-header-row
        *matHeaderRowDef="
          ['row-first', 'row-second', 'row-third'];
          sticky: true
        "
      ></tr>
      <tr
        mat-header-row
        *matHeaderRowDef="
          [
            'row2-first',
            'row2-second',
            'row2-third',
            'row2-fourth',
            'row2-fifth',
            'row2-sixth',
            'row2-seventh',
            'row2-eighth',
            'row2-nineth'
          ];
          sticky: true
        "
      ></tr>
      <!-- Top header row end here -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        (click)="highlightRow(row)"
        [class.highlight]="row.PLoan_Id == selectedId"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable(exporterLoanInfo)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorlist
          [pageSize]="20"
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
