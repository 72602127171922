<div class="formborder">
  <div class="row m-0 mt-2">
    <div class="col-md-12 p-1">
      <div class="bg-success1">Assets Info:</div>
    </div>
  </div>
  <form #f="ngForm" novalidate *ngIf="AssetsForm == 'Form'&&(PageData.currentTab==1|| (PageData.currentTab==0 && Info!=null &&   Info!=[]))">
    <div class="row m-0" *ngIf="AgricultureForm == 'AgricultureForm'">
      <div class="col-md-6 p-1">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            checked
            (change)="selectAgricultureForm('Property')"
            type="radio"
            name="inlineRadioOptionsA"
            id="inlineRadioA1"
            value="Property"
          />
          <label class="form-check-label" for="inlineRadioA1">Property</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            (change)="selectAgricultureForm('Vehicle')"
            type="radio"
            name="inlineRadioOptionsA"
            id="inlineRadioA2"
            value="Vehicle"
          />
          <label class="form-check-label" for="inlineRadioA2">Vehicle</label>
        </div>
      </div>
    </div>

    <ng-container *ngIf="propertyForm == 'Vehicle'">
      <div class="row m-0">
        <div class="col-md-5 p-1">
          <div class="form-check-inline">
            <input type="radio"
                   class="form-check-input"
                   [(ngModel)]="vehicle_type"
                   (change)="selectVehicleType('New')"
                   name="inlineRadioOptions"
                   id="NewVehicle"
                   value="New"
                   [disabled]="IsDisabled" />
            <label class="form-check-label" for="NewVehicle">New Vehicle</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio"
                   class="form-check-input"
                   [(ngModel)]="vehicle_type"
                   (change)="selectVehicleType('Used')"
                   name="inlineRadioOptions"
                   id="UsedVehicle"
                   value="Used"
                   [disabled]="IsDisabled" />
            <label class="form-check-label" for="UsedVehicle">Used Vehicle</label>
          </div>
        </div>
        <div class="col-md-5 p-1">
          <div class="form-check-inline">
            <input type="radio"
                   class="form-check-input"
                   [(ngModel)]="commercialVehicle"
                   [disabled]="IsDisabled"
                   (change)="selectVehicleType('Commercial')"
                   name="inlineRadioOptions1"
                   id="Commercial"
                   value="Commercial" />
            <label class="form-check-label" for="Commercial">Commercial</label>
          </div>
          <div class="form-check-inline">
            <input type="radio"
                   class="form-check-input"
                   [(ngModel)]="commercialVehicle"
                   [disabled]="IsDisabled"
                   (change)="selectVehicleType('Non-Commercial')"
                   name="inlineRadioOptions1"
                   id="NonCommercial"
                   value="Non-Commercial" />
            <label class="form-check-label" for="NonCommercial">Non Commercial</label>
          </div>
        </div>

        <div class="col-md-2" *ngIf="usedVehicle">
          <button class="btn font-size-12 button-btn"
                  (click)="viewVehicleGridModal()">
            Grid
          </button>
        </div>
      </div>
      <!--Information showing Tab-->
      <div class="row m-0 mt-3" *ngIf="IsDisabled">
        <div class="col-md-2">
          <h6 class="fs-12">Vehicle Manufacture</h6>
          <p class="lead fs-12">{{ Info.VehicleManufacture }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Vehicle Category</h6>
          <p class="lead fs-12">{{ Info.VehicleCategory }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Vehicle Model Name</h6>
          <p class="lead fs-12">{{ Info.VehicleModelName }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Variant</h6>
          <p class="lead fs-12">{{ Info.Variant }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Manufacture Year</h6>
          <p class="lead fs-12">{{ Info.MefgYear }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Vehicle Registation No</h6>
          <p class="lead fs-12">
            {{ Info.VehicleRegistationNo }}<i *ngIf="Info.VehicleRegistationNo != '' &&(RCKYC_IsVerified == 1 || RCLastVerfiedDate)"
                                                   class="fa fa-check ml-2"
                                                   style="color: green"></i>
          </p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Registation Date</h6>
          <p class="lead fs-12">{{ Info.RegistationDateMobile }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Registation Expiry Date</h6>
          <p class="lead fs-12">{{ Info.RegistationExpiryDateMobile }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Fuel Type</h6>
          <p class="lead fs-12">{{ Info.FuelType }}</p>
        </div>
        <div class="col-md-2" *ngIf="commercialVehicle == 'Commercial'">
          <h6 class="fs-12">Road Tax Upto</h6>
          <p class="lead fs-12">{{ Info.RoadTaxUptoMobile }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Colour</h6>
          <p class="lead fs-12">{{ Info.Color }}</p>
        </div>
        <div class="col-md-2" *ngIf="commercialVehicle == 'Commercial'">
          <h6 class="fs-12">Fitness Upto</h6>
          <p class="lead fs-12">{{ Info.FitnessUptoMobile }}</p>
        </div>
        <div class="col-md-2" *ngIf="commercialVehicle == 'Commercial'">
          <h6 class="fs-12">Permit Upto</h6>
          <p class="lead fs-12">{{ Info.PermitUpto }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Vehicle Cost</h6>
          <p class="lead fs-12">{{ Info.VehicleCost }}</p>
        </div>
        <div class="col-md-2" *ngIf="commercialVehicle == 'Commercial'">
          <h6 class="fs-12">Route</h6>
          <p class="lead fs-12">{{ Info.Rout }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Engine No</h6>
          <p class="lead fs-12">{{ Info.EngineNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Chassis No</h6>
          <p class="lead fs-12">{{ Info.ChassisNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Key No</h6>
          <p class="lead fs-12">{{ Info.KeyNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">RC HPNEndorsment</h6>
          <p class="lead fs-12">
            {{ Info.RC_HPNEndorsment == true ? "YES" : "NO" }}
          </p>
        </div>
        <div class="col-md-2" *ngIf="Info.Vehicle_Type == 'New'">
          <h6 class="fs-12">Invoice HPNEndorsment</h6>
          <p class="lead fs-12">
            {{ Info.Invoice_HPNEndorsment == true ? "YES" : "NO" }}
          </p>
        </div>
      </div>
      <!-- Information showing Tab ends-->
      <div class="row m-0 mt-1" *ngIf="!IsDisabled">
        <div class="col-md-3 p-1">
          <span class="required-lable">Vehicle Manufacture</span>

          <select name="VehicleManufacture1"
                  id="VehicleManufacture1"
                  #refVehicleManufacture1="ngModel"
                  [disabled]="IsDisabled"
                  (change)="LMS_GetVehicleModelMaster($event)"
                  [(ngModel)]="VehicleManufacture"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
              'is-invalid': f.submitted && refVehicleManufacture1.invalid,
              'alert-warning':
                refVehicleManufacture1.invalid &&
                (refVehicleManufacture1.dirty ||
                  refVehicleManufacture1.touched ||
                  refVehicleManufacture1.untouched)
            }">
            <option value="">Vehicle Manufacture</option>
            <option *ngFor="let data of ManufactureData" [value]="data.Id">
              {{ data.Manufacture }}
            </option>
          </select>
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable">Vehicle Category</span>
          <select name="SelectVehicleCategory"
                  id="SelectVehicleCategory"
                  [disabled]="IsDisabled"
                  #refSelectVehicleCategory="ngModel"
                  (change)="LMS_GetVehicleModelMaster($event)"
                  [(ngModel)]="SelectVehicleCategory"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
              'is-invalid': f.submitted && refSelectVehicleCategory.invalid,
              'alert-warning':
                refSelectVehicleCategory.invalid &&
                (refSelectVehicleCategory.dirty ||
                  refSelectVehicleCategory.touched ||
                  refSelectVehicleCategory.untouched)
            }">
            <option value="">Vehicle Category</option>
            <option *ngFor="let cat of vehicleCategoryData" [value]="cat.Id">
              {{ cat.Vehicle_Category }}
            </option>
          </select>
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable">Vehicle Model Name</span>
          <select name="VehicleModel"
                  id="VehicleModel"
                  #refVehicleModel="ngModel"
                  [disabled]="IsDisabled"
                  [ngClass]="{
              'is-invalid': f.submitted && refVehicleModel.invalid,
              'alert-warning':
                refVehicleModel.invalid &&
                (refVehicleModel.dirty ||
                  refVehicleModel.touched ||
                  refVehicleModel.untouched)
            }"
                  [(ngModel)]="VehicleModel"
                  (ngModelChange)="OnChangeVehicleMOdel()"
                  class="form-control input-text-css"
                  required>
            <option value="">Vehicle Model Name</option>
            <option *ngFor="let data of vehicleModelData" [value]="data.Id">
              {{ data.Model_Name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 p-1">
          <span class="required-lable">Variant</span>
          <input required
                 type="text"
                 #refVariant="ngModel"
                 name="Variant"
                 id="Variant"
                 [disabled]="IsDisabled"
                 [ngClass]="{
              'is-invalid': f.submitted && refVariant.invalid,
              'alert-warning':
                refVariant.invalid &&
                (refVariant.dirty || refVariant.touched || refVariant.untouched)
            }"
                 [(ngModel)]="Variant"
                 class="form-control input-text-css"
                 placeholder="Variant" />
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable">Manufacture Year</span>

          <!--<input
        name="ManufactureYear"
        [(ngModel)]="ManufactureYear"
        id="ManufactureYear"
        placeholder="ManufactureYear"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="bsConfig"
        required
        #refManufactureYear="ngModel"
        (ngModelChange)="OnChangeyear()"
        [disabled]="IsDisabled"
      />-->
          <input name="MefgYear1"
                 [(ngModel)]="ManufactureYear"
                 id="MefgYear1"
                 placeholder="Start Year"
                 class="form-control input-text-css"
                 bsDatepicker
                 [bsConfig]="bsConfig"
                 required
                 [disabled]="IsDisabled"
                 #refMefgYear1="ngModel"
                 (ngModelChange)="OnChangeyear()"
                 [ngClass]="{
                        'is-invalid': f.submitted && refMefgYear1.invalid,
                        'alert-warning':
                          refMefgYear1.invalid &&
                          (refMefgYear1.dirty ||
                            refMefgYear1.touched ||
                            refMefgYear1.untouched)
                      }" />
          <!-- <input
        name="ManufactureYear"
        id="ManufactureYear"
        #refManufactureYear="ngModel"
        maxlength="4"
        [disabled]="IsDisabled"
        minlength="4"
        numbersOnly
        [(ngModel)]="ManufactureYear"
        class="form-control input-text-css"
        placeholder="Manufacture Year"
      /> -->
          <!-- <input name="ManufactureYear" id="ManufactureYear" #refManufactureYear="ngModel" value="{{ ManufactureYear | date:'dd/MM/yyyy' }}"
      [ngClass]="{ 'is-invalid': f.submitted && refManufactureYear.invalid, 'alert-warning': ''  && (refManufactureYear.dirty || refManufactureYear.touched || refManufactureYear.untouched) }"
      [(ngModel)]="ManufactureYear" class="form-control input-text-css" [(bsValue)]="ManufactureYear" bsDatepicker> -->
        </div>
        <div class="col-md-3 p-1">
          <span class="">Vehicle Registation No</span>
          <input type="text"
                 #refVehicleRegistationNo="ngModel"
                 (blur)="checkDuplication_Vehicle('RegistationNo')"
                 [disabled]="IsDisabled"
                 (keypress)="onKeyPress($event)"
                 [(ngModel)]="VehicleRegistationNo"
                 name="VehicleRegistationNo"
                 id="VehicleRegistationNo"
                 class="form-control input-text-css"
                 placeholder="Vehicle Registation No" />
        </div>

        <div class="col-md-3 p-1">
          <span class="">Registation Date</span>
          <div class="datepicker_feild">
              <input [matDatepicker]="picker"
                     dateConvert
                     placeholder="DD/MM/YYYY"
                     name="RegistationDate"
                     id="RegistationDate"
                     #refRegistationDate="ngModel"
                     [disabled]="IsDisabled"
                     (ngModelChange)="ChangeRegistationDate($event)"
                     [min]="ManufactureYear"
                     [(ngModel)]="RegistationDate"
                     class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <!-- <input
        name="RegistationDate"
        id="RegistationDate"
        #refRegistationDate="ngModel"
        [disabled]="IsDisabled"
        (ngModelChange)="ChangeRegistationDate($event)"
        [minDate]="ManufactureYear"
        [(ngModel)]="RegistationDate"
        value="{{ RegistationDate | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="RegistationDate"
        bsDatepicker
        placeholder="Registation Date"
      /> -->
        </div>
        <div class="col-md-3 p-1">
          <span class="">Registation Expiry Date</span>
          <div class="datepicker_feild">
              <input [matDatepicker]="picker2"
                     dateConvert
                     placeholder="DD/MM/YYYY"
                     name="RegistationExpiryDate"
                     id="RegistationExpiryDate"
                     #refRegistationExpiryDate="ngModel"
                     (ngModelChange)="ChangeExpiryDate($event)"
                     [min]="RegistationDate"
                     [disabled]="IsDisabled"
                     [(ngModel)]="RegistationExpiryDate"
                     class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
          <!-- <input
        name="RegistationExpiryDate"
        id="RegistationExpiryDate"
        #refRegistationExpiryDate="ngModel"
        (ngModelChange)="ChangeExpiryDate($event)"
        [minDate]="RegistationDate"
        [disabled]="IsDisabled"
        [(ngModel)]="RegistationExpiryDate"
        value="{{ RegistationExpiryDate | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="RegistationExpiryDate"
        bsDatepicker
        placeholder="Registation Expiry Date"
      /> -->
        </div>
        <div class="col-md-3 p-1">
          <span class="required-lable">Fuel Type</span>
          <select name="FuelType"
                  id="SelectGender"
                  #refFuelType="ngModel"
                  [(ngModel)]="SelectFuelType"
                  [disabled]="IsDisabled"
                  required
                  [ngClass]="{
              'is-invalid': f.submitted && refFuelType.invalid,
              'alert-warning':
                refFuelType.invalid &&
                (refFuelType.dirty ||
                  refFuelType.touched ||
                  refFuelType.untouched)
            }"
                  class="form-control input-text-css">
            <option value="">Select Fuel Type</option>
            <option *ngFor="let Type of FuelTypeOption" [value]="Type.Id">
              {{ Type.Value }}
            </option>
          </select>
        </div>

        <div class="col-md-3 p-1" *ngIf="commercialVehicle == 'Commercial'">
          <span class="">Road Tax Upto</span>
          <div class="datepicker_feild">
            <input [matDatepicker]="picker3"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="RoadTaxUpto"
                   id="RoadTaxUpto"
                   #refRoadTaxUpto="ngModel"
                   [disabled]="IsDisabled"
                   [(ngModel)]="RoadTaxUpto"
                   class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
          <!-- <input
        name="RoadTaxUpto"
        id="RoadTaxUpto"
        #refRoadTaxUpto="ngModel"
        [disabled]="IsDisabled"
        [(ngModel)]="RoadTaxUpto"
        value="{{ RoadTaxUpto | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="RoadTaxUpto"
        bsDatepicker
        placeholder="DD/MM/YYYY"
      /> -->
        </div>
        <div class="col-md-3 p-1">
          <span>Colour</span>
          <input type="text"
                 #refClr="ngModel"
                 [disabled]="IsDisabled"
                 [(ngModel)]="Color"
                 name="Clr"
                 id="Clr"
                 class="form-control input-text-css"
                 placeholder="Color" />
        </div>
        <div class="col-md-3 p-1" *ngIf="commercialVehicle == 'Commercial'">
          <span class="">Fitness Upto</span>
          <div class="datepicker_feild">
            <input [matDatepicker]="picker4"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="FitnessUpto"
                   id="FitnessUpto"
                   #refFitnessUpto="ngModel"
                   [disabled]="IsDisabled"
                   [(ngModel)]="FitnessUpto"
                   class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </div>
          <!-- <input
        name="FitnessUpto"
        id="FitnessUpto"
        #refFitnessUpto="ngModel"
        [disabled]="IsDisabled"
        [(ngModel)]="FitnessUpto"
        value="{{ FitnessUpto | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="FitnessUpto"
        bsDatepicker
        placeholder="Fitness Upto"
      /> -->
        </div>

        <div class="col-md-3 p-1" *ngIf="commercialVehicle == 'Commercial'">
          <span class="">Permit Upto</span>
          <div class="datepicker_feild">
            <input [matDatepicker]="picker5"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   name="PermitUpto"
                   id="PermitUpto"
                   #refPermitUpto="ngModel"
                   [disabled]="IsDisabled"
                   [(ngModel)]="PermitUpto"
                   class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </div>
          <!-- <input
        name="PermitUpto"
        id="PermitUpto"
        #refPermitUpto="ngModel"
        [disabled]="IsDisabled"
        [(ngModel)]="PermitUpto"
        value="{{ PermitUpto | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="PermitUpto"
        bsDatepicker
        placeholder="Permit Upto"
      /> -->
        </div>

        <div class="col-md-3 p-1">
          <span class="required-lable">Vehicle Cost</span>
          <input required
                 type="number"
                 #reVC="ngModel"
                 min="0"
                 numbersOnly
                 [disabled]="IsDisabled"
                 [ngClass]="{
              'is-invalid': f.submitted && reVC.invalid,
              'alert-warning':
                reVC.invalid && (reVC.dirty || reVC.touched || reVC.untouched)
            }"
                 [(ngModel)]="VehicleCost"
                 name="VC"
                 id="VC"
                 class="form-control input-text-css"
                 placeholder="Vehicle Cost" />
        </div>

        <div class="col-md-3 p-1" *ngIf="commercialVehicle == 'Commercial'">
          <span class="">Route</span>
          <input type="text"
                 #refRoute="ngModel"
                 [disabled]="IsDisabled"
                 [(ngModel)]="Route"
                 name="Route"
                 id="Route"
                 class="form-control input-text-css"
                 placeholder="Route" />
        </div>
        <div class="col-md-3 p-1">
          <span class="">Engine No</span>
          <input type="text"
                 #refEngineNo="ngModel"
                 [disabled]="IsDisabled"
                 [required]="
              (InvoiceNo != '' && InvoiceNo != null) ||
              (VehicleRegistationNo != '' && VehicleRegistationNo != null)
            "
                 [ngClass]="{
              'is-invalid': f.submitted && refEngineNo.invalid,
              'alert-warning':
                refEngineNo.invalid &&
                (refEngineNo.dirty ||
                  refEngineNo.touched ||
                  refEngineNo.untouched)
            }"
                 (blur)="checkDuplication_Vehicle('EngineNo')"
                 [(ngModel)]="EngineNo"
                 name="ENO"
                 id="ENO"
                 class="form-control input-text-css"
                 placeholder="Engine No" />
        </div>
        <div class="col-md-3 p-1">
          <span class="">Chassis No</span>
          <input type="text"
                 #refChassisNo="ngModel"
                 [disabled]="IsDisabled"
                 [required]="
              (InvoiceNo != '' && InvoiceNo != null) ||
              (VehicleRegistationNo != '' && VehicleRegistationNo != null)
            "
                 [ngClass]="{
              'is-invalid': f.submitted && refChassisNo.invalid,
              'alert-warning':
                refChassisNo.invalid &&
                (refChassisNo.dirty ||
                  refChassisNo.touched ||
                  refChassisNo.untouched)
            }"
                 (blur)="checkDuplication_Vehicle('ChassisNo')"
                 [(ngModel)]="ChassisNo"
                 name="CNO"
                 id="CNO"
                 class="form-control input-text-css"
                 placeholder="Chassis No" />
        </div>
        <div class="col-md-3 p-1">
          <span class="">Key No</span>
          <input type="text"
                 #refKeyNo="ngModel"
                 [disabled]="IsDisabled"
                 [(ngModel)]="KeyNo"
                 name="KEYNO"
                 id="KEYNO"
                 class="form-control input-text-css"
                 placeholder="Key No" />
        </div>
        <div class="col-md-3 mt-4">
          <mat-checkbox [(ngModel)]="RC_HPNEndorsment"
                        id="RC_HPNEndorsment"
                        name="RC_HPNEndorsment">
            RC HPNEndorsment
          </mat-checkbox>
        </div>
        <div class="col-md-3 mt-4" *ngIf="newVehicle">
          <mat-checkbox [(ngModel)]="Invoice_HPNEndorsment"
                        id="Invoice_HPNEndorsment"
                        name="Invoice_HPNEndorsment">
            Invoice HPNEndorsment
          </mat-checkbox>
        </div>
      </div>
      <div class="row mt-4" *ngIf="!isEdit">
        <div class="col-md-12">
          <button type="button"
                  *ngIf="RCVerified_Button"
                  [hidden]=" RCKYC_IsVerified == 1 || RCLastVerfiedDate"
                  (click)="onVerification(0)"
                  style=" margin-left: 5px;font-size: 12px; width: 105px; height: 35px; margin-top: 16px; padding: 0px;"
                  class="btn font-size-12 button-btn">
            RC Verification
          </button>
          <!--<i class="fa fa-eye ml-2"
      (click)="getdata()"
      *ngIf="VehicleRegistationNo != '' && RCVerified_Button &&(RCKYC_IsVerified == 1 || RCLastVerfiedDate) "
      style="color: green"></i>-->
          <button type="button"
                  *ngIf="VehicleRegistationNo != '' && RCVerified_Button &&  (RCKYC_IsVerified == 1 || RCLastVerfiedDate)"
                  (click)="onVerification(1)"
                  class="btn font-size-6 button-btn"
                  style="width: max-content !important">
            RC RE-Verification
          </button>
          <span *ngIf=" VehicleRegistationNo != '' && RCVerified_Button && RCLastVerfiedDate"
                style="margin-top: 25px">
            VERIFIED {{ RCLastVerfiedDate }}
          </span>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12 p-1">
          <hr />
        </div>
      </div>

      <ng-container *ngIf="newVehicle">
        <div class="row m-0">
          <div class="col-md-12 p-1">
            <div class="bg-success1">New Vehicles:</div>
          </div>
        </div>

        <!--Information showing Tab-->
        <div class="row m-0 mt-3" *ngIf="IsDisabled">
          <div class="col-md-2">
            <h6 class="fs-12">Dealer</h6>
            <p class="lead fs-12">{{ Info.DealerName }}</p>
          </div>

          <div class="col-md-2">
            <h6 class="fs-12">Dealer Contact Person</h6>
            <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Dealer Contact No.</h6>
            <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Quotation No.</h6>
            <p class="lead fs-12">{{ Info.DONo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Quotation Date</h6>
            <p class="lead fs-12">{{ Info.DODoDateMobile }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Estimation Amount</h6>
            <p class="lead fs-12">{{ Info.EstimationAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Invoice No.</h6>
            <p class="lead fs-12">{{ Info.InvoiceNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Invoice Date</h6>
            <p class="lead fs-12">{{ Info.InvoiceDateMobile }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Invoice Value</h6>
            <p class="lead fs-12">{{ Info.InvoiceValue }}</p>
          </div>

          <div class="col-md-2">
            <h6 class="fs-12">Quotation in favour of</h6>
            <p class="lead fs-12">{{ Info.DOIssuedinfavourof }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Remark</h6>
            <p class="lead fs-12">{{ Info.Remark }}</p>
          </div>
        </div>
        <!-- Information showing Tab ends-->

        <div class="row m-0" *ngIf="!IsDisabled">
          <div class="col-md-3 p-1">
            <span>Dealer</span>
            <select name="DealerId"
                    id="DealerId"
                    #refDealerId="ngModel"
                    (change)="DealerChange(Dealer)"
                    [(ngModel)]="Dealer"
                    [disabled]="IsDisabled"
                    class="form-control input-text-css">
              <option value="">Select Dealer</option>
              <option *ngFor="let collection of DealerList"
                      [value]="collection.PartnerId">
                {{ collection.Partner }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span>Dealer Contact Person</span>
            <input type="text"
                   #refDealerContactPerson="ngModel"
                   [(ngModel)]="DealerContactPerson"
                   placeholder="Dealer Contact Person"
                   name="DealerContactPerson"
                   [disabled]="IsDisabled"
                   id="DealerContactPerson"
                   class="form-control input-text-css" />
          </div>
          <div class="col-md-3 p-1">
            <span>Dealer Contact No.</span>
            <input type="text"
                   #refDealerContactNo="ngModel"
                   [(ngModel)]="DealerContactNo"
                   [disabled]="IsDisabled"
                   placeholder="Dealer Contact No."
                   numbersOnly
                   maxlength="10"
                   minlength="10"
                   name="DealerContactNo"
                   id="DealerContactNo"
                   class="form-control input-text-css" />
          </div>
          <div class="col-md-3 p-1">
            <span>Quotation No.</span>
            <input type="text"
                   #refDONo="ngModel"
                   [(ngModel)]="DeliveryorderNo"
                   [disabled]="IsDisabled"
                   name="DoNO"
                   id="DoNO"
                   class="form-control input-text-css"
                   placeholder="Quotation No." />
          </div>
          <div class="col-md-3 p-1">
            <span>Quotation Date</span>
            <div class="datepicker_feild">
                <input [matDatepicker]="picker6"
                       dateConvert
                       placeholder="DD/MM/YYYY"
                       #refDODoDate="ngModel"
                       [(ngModel)]="DeliveryorderDate"
                       name="DODoDate"
                       id="DODoDate"
                       [disabled]="IsDisabled"
                       (ngModelChange)="ChangeDeliveryorderDate($event)"
                       class="form-control input-text-css" />
              <mat-datepicker-toggle matSuffix
                                     [for]="picker6"></mat-datepicker-toggle>
              <mat-datepicker #picker6></mat-datepicker>
            </div>
            <!-- <input
          type="text"
          #refDODoDate="ngModel"
          [(ngModel)]="DeliveryorderDate"
          name="DODoDate"
          id="DODoDate"
          [disabled]="IsDisabled"
          (ngModelChange)="ChangeDeliveryorderDate($event)"
          [maxDate]="RegistationDate"
          value="{{ DeliveryorderDate | date : 'dd/MM/yyyy' }}"
          placeholder="DD/MM/YYYY"
          placeholder="Quotation Date"
          class="form-control input-text-css"
          [(bsValue)]="DeliveryorderDate"
          bsDatepicker
        /> -->
          </div>
          <div class="col-md-3 p-1">
            <span>Estimation Amount</span>
            <input type="number"
                   min="0"
                   numbersOnly
                   #refEstimationAmount="ngModel"
                   [(ngModel)]="EstimationAmount"
                   [disabled]="IsDisabled"
                   name="EstimationAmount"
                   id="EstimationAmount"
                   class="form-control input-text-css"
                   placeholder="Estimation Amount" />
          </div>
          <div class="col-md-3 p-1">
            <span>Invoice No.</span>
            <input type="text"
                   #refInvoiceNo="ngModel"
                   [(ngModel)]="InvoiceNo"
                   [disabled]="IsDisabled"
                   name="InvoiceNo"
                   id="InvoiceNo"
                   class="form-control input-text-css"
                   placeholder="Invoice No." />
          </div>
          <div class="col-md-3 p-1">
            <span>Invoice Date</span>
            <div class="datepicker_feild">
                <input [matDatepicker]="picker7"
                       dateConvert
                       placeholder="DD/MM/YYYY"
                       #refInvoiceDate="ngModel"
                       [(ngModel)]="InvoiceDate"
                       name="InvoiceDate"
                       [disabled]="IsDisabled"
                       id="InvoiceDate"
                       (ngModelChange)="ChangeInvoiveDateDate($event)"
                       class="form-control input-text-css" />
              <mat-datepicker-toggle matSuffix
                                     [for]="picker7"></mat-datepicker-toggle>
              <mat-datepicker #picker7></mat-datepicker>
            </div>
            <!-- <input
          type="text"
          #refInvoiceDate="ngModel"
          [(ngModel)]="InvoiceDate"
          name="InvoiceDate"
          [disabled]="IsDisabled"
          id="InvoiceDate"
          (ngModelChange)="ChangeInvoiveDateDate($event)"
          [minDate]="DeliveryorderDate"
          [maxDate]="RegistationDate"
          value="{{ InvoiveDate | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="InvoiveDate"
          bsDatepicker
          placeholder="Invoice Date"
        /> -->
          </div>
          <div class="col-md-3 p-1">
            <span>Invoice Value</span>
            <input type="text"
                   numbersOnly
                   #refInvoiceValue="ngModel"
                   [(ngModel)]="InvoiceValue"
                   [disabled]="IsDisabled"
                   name="InvoiceValue"
                   id="InvoiceValue"
                   class="form-control input-text-css"
                   placeholder="Invoice Value" />
          </div>

          <div class="col-md-3 p-1">
            <span>Quotation in favour of</span>
            <select name="DOIssuedinfavourof"
                    #refDOIssuedinfavourof="ngModel"
                    [disabled]="IsDisabled"
                    [(ngModel)]="IssuedInFavourOf"
                    id="DOIssuedinfavourof"
                    class="form-control input-text-css"
                    placeholder="Quotation in favour of">
              <option value="">Select Customer</option>
              <option [value]="cus.CustomerId" *ngFor="let cus of CustomerList">
                {{ cus.Customer }}
              </option>
            </select>
          </div>

          <div class="col-md-3 p-1">
            <span>Remark</span>
            <input type="text"
                   #refRemark="ngModel"
                   [(ngModel)]="Remark"
                   [disabled]="IsDisabled"
                   placeholder="Remark"
                   name="Remark"
                   id="Remark"
                   class="form-control input-text-css" />
          </div>
        </div>
      </ng-container>

      <ng-container>
        <div class="row m-0 mt-2">
          <div class="col-md-12 p-1 mt-2 upload">
            <B> Upload Vehicle Images </B>
            <input #docFile
                   type="file"
                   [multiple]="false"
                   accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                   (change)="fileChangeListenerAttachment(docFile.files, docFile)"
                   style="display: none" />
            <div class="row m-0 mt-2 align-items-center"
                 *ngFor="let report of AssetsAttachment; let i = index">
              <div class="col-md-1 pl-0">{{ i + 1 }}</div>
              <div class="col-md-3">
                <input [disabled]="IsDisabled"
                       type="text"
                       placeholder="Title"
                       name="title{{ i }}"
                       id="title{{ i }}"
                       #refTitle="ngModel"
                       [(ngModel)]="report.Title"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                <p class="m-0 row">
                  <i class="fa fa-upload ml-2"
                     *ngIf="!report.DocFileName"
                     (click)="docFile.click()"
                     aria-hidden="true"></i>
                  <!--<ng-container *ngIf="report.DocFileName">
                  <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ report.DocFileName }}
                      <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer" (click)="removeFileAttachment(i)"></i>
                  </div>
              </ng-container>-->
                  <ng-container *ngIf="report.DocFileName">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                      <ng-container *ngIf="report.DocFileName && !IsDisabled">
                        {{
                        report.DocFileName
                        }}
                      </ng-container>
                      <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                          report.DocFileName
                        }}"
                         class="ml-3"
                         target="_blank"
                         *ngIf="report.DocFileName && IsDisabled">
                        {{ report.DocFileName }}
                      </a>
                      <i class="fa fa-times ml-2"
                         aria-hidden="true"
                         style="cursor: pointer"
                         *ngIf="!IsDisabled"
                         (click)="removeFileAttachment(i)"></i>
                    </div>
                  </ng-container>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="row mt-2 formborder ml-2 mr-2 mt-2" style=" background-color: #d2ebec;" *ngIf="VehicleRegistationNo != '' && RCVerified_Button &&(RCKYC_IsVerified == 1 || RCLastVerfiedDate) ">

        <div class="col-md-12 bg-success1">Verified RC Data</div>

        <div class="col-md-2 border-div">
          <h6>Vehicle Registation No</h6>
          <p style=" font-weight: 800;">
            {{ APIResponse.DoucumentNumber }}
          </p>
        </div>

        <div class="col-md-2 border-div">
          <h6>Fit Up To</h6>
          <p>
            {{ APIResponse.fit_up_to }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Registration Date</h6>
          <p>
            {{ APIResponse.registration_date }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Owner Name</h6>
          <p>
            {{ APIResponse.owner_name }}
          </p>
        </div>

        <div class="col-md-2 border-div">
          <h6>Father Name</h6>
          <p>
            {{ APIResponse.father_name }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Mobile Number</h6>
          <p>
            {{ APIResponse.mobile_number }}
          </p>
        </div>


        <div class="col-md-2 border-div">
          <h6>Vehicle Category</h6>
          <p>
            {{ APIResponse.vehicle_category }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Vehicle Chasi Number</h6>
          <p>
            {{ APIResponse.vehicle_chasi_number }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Vehicle Engine Number</h6>
          <p>
            {{ APIResponse.vehicle_engine_number }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Maker Description</h6>
          <p>
            {{ APIResponse.maker_description }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Maker Model</h6>
          <p>
            {{ APIResponse.maker_model }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Body Type</h6>
          <p>
            {{ APIResponse.body_type }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Fuel Type</h6>
          <p>
            {{ APIResponse.fuel_type }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Color</h6>
          <p>
            {{ APIResponse.color }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Norms Type</h6>
          <p>
            {{ APIResponse.norms_type }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Financer</h6>
          <p>
            {{ APIResponse.financer }}
          </p>
        </div>

        <div class="col-md-2 border-div">
          <h6>financed</h6>
          <p>
            {{ APIResponse.financed }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Insurance Company</h6>
          <p>
            {{ APIResponse.insurance_company }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Insurance Policy Number</h6>
          <p>
            {{ APIResponse.insurance_policy_number }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Insurance Upto</h6>
          <p>
            {{ APIResponse.insurance_upto }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Manufacturing Date</h6>
          <p>
            {{ APIResponse.manufacturing_date }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Registered At</h6>
          <p>
            {{ APIResponse.registered_at }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Tax Upto</h6>
          <p>
            {{ APIResponse.tax_upto }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Tax Paid Upto</h6>
          <p>
            {{ APIResponse.tax_paid_upto }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Seat Capacity</h6>
          <p>
            {{ APIResponse.seat_capacity }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Vehicle Category Description</h6>
          <p>
            {{ APIResponse.vehicle_category_description }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Pucc Number</h6>
          <p>
            {{ APIResponse.pucc_number }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Pucc Upto</h6>
          <p>
            {{ APIResponse.pucc_upto }}
          </p>
        </div>
        <div class="col-md-2 border-div">
          <h6>Owner Number</h6>
          <p>
            {{ APIResponse.owner_number }}
          </p>
        </div>
        <div class="col-md-12 border-div">
          <h6>Permanent Address</h6>
          <p>
            {{ APIResponse.permanent_address }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="propertyForm == 'Property'">
      <!--Information showing Tab-->
      <div class="row m-0 mt-3" *ngIf="IsDisabled">
        <div class="col-md-2">
          <h6 class="fs-12">Property Owner Name</h6>
          <p class="lead fs-12">{{ Info.CustomerId }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Address Of Property</h6>
          <p class="lead fs-12">{{ Info.Address }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Reg State</h6>
          <p class="lead fs-12">{{ Info.State }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Reg District</h6>
          <p class="lead fs-12">{{ Info.District }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Reg Tehsil</h6>
          <p class="lead fs-12">{{ Info.tasil }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Pincode</h6>
          <p class="lead fs-12">{{ Info.PinCode }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Type Of Property</h6>
          <p class="lead fs-12">{{ Info.TypeofProperty }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Nature Of Property</h6>
          <p class="lead fs-12">{{ Info.NatureofProperty }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Ownership Document</h6>
          <p class="lead fs-12">{{ Info.OwnershipDocument }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Ownership Type</h6>
          <p class="lead fs-12">{{ Info.OwnershipType }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Unit Of Measurement</h6>
          <p class="lead fs-12">{{ Info.UnitofMeasurement }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Total Area</h6>
          <p class="lead fs-12">{{ Info.TotalArea }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Constructed Area</h6>
          <p class="lead fs-12">{{ Info.ConstructedArea }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Type Of Mortgage</h6>
          <p class="lead fs-12">{{ Info.TypeofMortgage }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Mortgage singed by</h6>
          <p class="lead fs-12">{{ Info.MortgageSingedBy }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Assets Value</h6>
          <p class="lead fs-12">{{ Info.PValuationAmount }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Location</h6>
          <p class="lead fs-12">
            <a
              target="_blank"
              *ngIf="
                Info.GeoLocation != '' &&
                Info.GeoLocation != null &&
                Info.GeoLocation != ','
              "
              [href]="'http://maps.google.com/maps?q=' + Info.GeoLocation"
            >
              <i class="fa-solid fa-location-dot"></i>
            </a>
          </p>
        </div>
      </div>
      <!-- Information showing Tab ends-->
      <div *ngIf="!IsDisabled">
        <div class="row m-0">
          <div class="col-md-4 p-1">
            <span class="required-lable">Property Owner Name</span>
            <select
              name="CustomerId"
              id="CustomerId"
              #refCustomerId="ngModel"
              [disabled]="IsDisabled"
              [(ngModel)]="PropertyOwnerName"
              [ngClass]="{
                'is-invalid': f.submitted && refCustomerId.invalid,
                'alert-warning':
                  refCustomerId.invalid &&
                  (refCustomerId.dirty ||
                    refCustomerId.touched ||
                    refCustomerId.untouched)
              }"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Owner</option>
              <option [value]="cus.CustomerId" *ngFor="let cus of CustomerList">
                {{ cus.Customer }}
              </option>
            </select>
          </div>
          <div class="col-md-8 p-1">
            <span class="required-lable">Address Of Property</span>
            <textarea
              required
              type="text"
              #reAddress="ngModel"
              placeholder="Address Of Property"
              rows="1"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && reAddress.invalid,
                'alert-warning':
                  reAddress.invalid &&
                  (reAddress.dirty || reAddress.touched || reAddress.untouched)
              }"
              [(ngModel)]="AddressOfProperty"
              name="Address"
              id="Address"
              class="form-control input-text-css"
            ></textarea>
          </div>
        </div>
        <div class="row m-0 mt-2">
          <div class="col-md-3 p-1">
            <span class="required-lable">Reg State</span>
            <select
              name="State"
              id="State"
              #refState="ngModel"
              [(ngModel)]="customerModel.Firm_RegState"
              required
              [disabled]="IsDisabled"
              class="form-control input-text-css"
              (change)="GetDistrickDropdownReg(customerModel.Firm_RegState)"
              [ngClass]="{
                'is-invalid': f.submitted && refState.invalid,
                'alert-warning':
                  refState.invalid &&
                  (refState.dirty || refState.touched || refState.untouched)
              }"
            >
              <option value="">Select State</option>
              <option
                *ngFor="let state of stateDropdown"
                [value]="state.StateId"
              >
                {{ state.State_Name }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Reg District</span>
            <select
              name="District"
              id="District"
              #refDistrict="ngModel"
              [disabled]="IsDisabled"
              class="form-control input-text-css"
              required
              [(ngModel)]="customerModel.Firm_RegDistrict"
              (change)="GetTehasilDropdownReg(customerModel.Firm_RegDistrict)"
              [ngClass]="{
                'is-invalid': f.submitted && refDistrict.invalid,
                'alert-warning':
                  refDistrict.invalid &&
                  (refDistrict.dirty ||
                    refDistrict.touched ||
                    refDistrict.untouched)
              }"
            >
              <option value="">Select District</option>
              <option
                *ngFor="let district of districkDropdownReg"
                [value]="district.DistrictId"
              >
                {{ district.District_Name }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Reg Tehsil</span>
            <select
              name="Tasil"
              id="Tasil"
              #refTasil="ngModel"
              [disabled]="IsDisabled"
              class="form-control input-text-css"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refTasil.invalid,
                'alert-warning':
                  refTasil.invalid &&
                  (refTasil.dirty || refTasil.touched || refTasil.untouched)
              }"
              [(ngModel)]="customerModel.Firm_RegTehsil"
            >
              <option value="">Select Tehsil</option>
              <option
                *ngFor="let tehsil of tehasilDropdownReg"
                [value]="tehsil.TehsilId"
              >
                {{ tehsil.Tehsil_Name }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Pincode</span>
            <input
              name="Pincode"
              id="Pincode"
              #refPincode="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refPincode.invalid,
                'alert-warning':
                  refPincode.invalid &&
                  (refPincode.dirty ||
                    refPincode.touched ||
                    refPincode.untouched)
              }"
              [(ngModel)]="Pincode"
              class="form-control input-text-css"
              required
            />
          </div>
        </div>

        <div class="row m-0 mt-1">
          <div class="col-md-3 p-1">
            <span class="required-lable">Type Of Property</span>
            <select
              name="TypeofProperty"
              id="TypeofProperty"
              #refTypeofProperty="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refTypeofProperty.invalid,
                'alert-warning':
                  refTypeofProperty.invalid &&
                  (refTypeofProperty.dirty ||
                    refTypeofProperty.touched ||
                    refTypeofProperty.untouched)
              }"
              [(ngModel)]="TypeOfProperty"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Type Of Property</option>
              <option *ngFor="let Type of TypeOfPropertyList" [value]="Type.Id">
                {{ Type.Value }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Nature Of Property</span>
            <select
              name="NatureofProperty"
              id="NatureofProperty"
              #refNatureofProperty="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refNatureofProperty.invalid,
                'alert-warning':
                  refNatureofProperty.invalid &&
                  (refNatureofProperty.dirty ||
                    refNatureofProperty.touched ||
                    refNatureofProperty.untouched)
              }"
              [(ngModel)]="NatureOfProperty"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Type Of Property</option>
              <option
                *ngFor="let Type of NatureOfPropertyList"
                [value]="Type.Id"
              >
                {{ Type.Value }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Ownership Document</span>
            <select
              name="OwnershipDocument"
              id="OwnershipDocument"
              #refOwnershipDocument="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refOwnershipDocument.invalid,
                'alert-warning':
                  refOwnershipDocument.invalid &&
                  (refOwnershipDocument.dirty ||
                    refOwnershipDocument.touched ||
                    refOwnershipDocument.untouched)
              }"
              [(ngModel)]="OwnershipDocument"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Ownership Document</option>
              <option
                *ngFor="let Type of OwnershipDocumentList"
                [value]="Type.Id"
              >
                {{ Type.Value }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Ownership Type</span>
            <select
              name="OwnershipType"
              id="OwnershipType"
              #refOwnershipType="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refOwnershipType.invalid,
                'alert-warning':
                  refOwnershipType.invalid &&
                  (refOwnershipType.dirty ||
                    refOwnershipType.touched ||
                    refOwnershipType.untouched)
              }"
              [(ngModel)]="OwnershipType"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Ownership Type</option>
              <option *ngFor="let Type of OwnershipTypeList" [value]="Type.Id">
                {{ Type.Value }}
              </option>
            </select>
          </div>
        </div>

        <div class="row m-0 mt-1">
          <div class="col-md-3 p-1">
            <span class="required-lable">Unit Of Measurement </span>
            <select
              name="UnitofMeasurement"
              id="UnitofMeasurement"
              #refUnitofMeasurement="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refUnitofMeasurement.invalid,
                'alert-warning':
                  refUnitofMeasurement.invalid &&
                  (refUnitofMeasurement.dirty ||
                    refUnitofMeasurement.touched ||
                    refUnitofMeasurement.untouched)
              }"
              [(ngModel)]="UnitOfMeasurement"
              class="form-control input-text-css"
              required
            >
              <option value="">Select Unit Of Measurement</option>
              <option value="Sq. Ft">Sq. Ft</option>
              <option value="Sq. Yards">Sq. Yards</option>
              <option value="Sq. Metre">Sq. Metre</option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Total Area </span>
            <input
              required
              type="text"
              appTwoDigitDecimaNumber
              #reTotalArea="ngModel"
              placeholder="Total Area"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && reTotalArea.invalid,
                'alert-warning':
                  reTotalArea.invalid &&
                  (reTotalArea.dirty ||
                    reTotalArea.touched ||
                    reTotalArea.untouched)
              }"
              [(ngModel)]="TotalArea"
              name="TotalArea"
              id="TotalArea"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Constructed Area </span>
            <input
              required
              type="text"
              appTwoDigitDecimaNumber
              #reConstructedArea="ngModel"
              [disabled]="IsDisabled"
              [(ngModel)]="ConstructedArea"
              placeholder="Constructed Area"
              name="ConstructedArea"
              id="ConstructedArea"
              [ngClass]="{
                'is-invalid': f.submitted && reConstructedArea.invalid,
                'alert-warning':
                  reConstructedArea.invalid &&
                  (reConstructedArea.dirty ||
                    reConstructedArea.touched ||
                    reConstructedArea.untouched)
              }"
              class="form-control input-text-css"
            />
            <!--  (change)="GetConstructedArea($event)" -->
          </div>
          <div class="col-md-3 p-1">
            <span class="">Type Of Mortgage </span>
            <select
              name="TypeofMortgage"
              id="TypeofMortgage"
              #refTypeofMortgage="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refTypeofMortgage.invalid,
                'alert-warning':
                  refTypeofMortgage.invalid &&
                  (refTypeofMortgage.dirty ||
                    refTypeofMortgage.touched ||
                    refTypeofMortgage.untouched)
              }"
              [(ngModel)]="TypeOfMortgage"
              class="form-control input-text-css"
            >
              <option value="">Select Type Of Mortgage</option>
              <option value="Registered">Registered</option>
              <option value="Equitable">Equitable</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div class="row m-0 mt-1">
          <div class="col-md-3 p-1">
            <span class="">Mortgage singed by</span>
            <select
              name="MortgageSingedBy"
              id="MortgageSingedBy"
              #refMortgageSingedBy="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refMortgageSingedBy.invalid,
                'alert-warning':
                  refMortgageSingedBy.invalid &&
                  (refMortgageSingedBy.dirty ||
                    refMortgageSingedBy.touched ||
                    refMortgageSingedBy.untouched)
              }"
              [(ngModel)]="MortgageSingedBy"
              class="form-control input-text-css"
            >
              <option value="">Select Mortgage singed by</option>
              <option
                *ngFor="let collection of CollectionExecutiveData"
                [value]="collection.EmpId"
              >
                {{ collection.Emp_FirstName }}
              </option>
            </select>
          </div>
          <div class="col-md-3 p-1">
            <span class="required-lable">Estimated Valution Amount</span>
            <input
              required
              type="number"
              min="0"
              numbersOnly
              #refValuationAmount="ngModel"
              placeholder="Valution Amount"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refValuationAmount.invalid,
                'alert-warning':
                  refValuationAmount.invalid &&
                  (refValuationAmount.dirty ||
                    refValuationAmount.touched ||
                    refValuationAmount.untouched)
              }"
              [(ngModel)]="ValutionAmount"
              name="ValuationAmount"
              id="ValuationAmount"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 p-1">
            <span class="">Latitude</span>
            <input
              type="number"
              step="00.000001"
              #refLatitude="ngModel"
              placeholder="Latitude"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refLatitude.invalid,
                'alert-warning':
                  refLatitude.invalid &&
                  (refLatitude.dirty ||
                    refLatitude.touched ||
                    refLatitude.untouched)
              }"
              [(ngModel)]="latitude"
              name="Latitude"
              id="Latitude"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-3 p-1">
            <span class="">Longitude</span>
            <input
              type="number"
              step="00.000001"
              #refLongitude="ngModel"
              placeholder="Longitude"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refLongitude.invalid,
                'alert-warning':
                  refLongitude.invalid &&
                  (refLongitude.dirty ||
                    refLongitude.touched ||
                    refLongitude.untouched)
              }"
              [(ngModel)]="longitude"
              name="Longitude"
              id="Longitude"
              class="form-control input-text-css"
            />
          </div>
        </div>

        <!-- <div class="row m-0 mt-2">
         
        </div> -->
      </div>
      <div class="row m-0 mt-2">
        <div class="col-md-12 p-1 mt-2 upload">
          <b> Upload Property Images </b>
          <input
            #docFile5
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
            (change)="fileChangeListenerAttachment(docFile5.files, docFile5)"
            style="display: none"
          />
          <div
            class="row m-0 mt-2 align-items-center"
            *ngFor="let report of AssetsAttachment; let i = index"
          >
            <div class="col-md-1 pl-0">{{ i + 1 }}</div>
            <div class="col-md-3">
              <input
                type="text"
                placeholder="Title"
                name="title{{ i }}"
                id="title{{ i }}"
                [disabled]="IsDisabled"
                #refTitle="ngModel"
                [(ngModel)]="report.Title"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <p class="m-0 row">
                <i
                  class="fa fa-upload ml-2"
                  *ngIf="!report.DocFileName"
                  (click)="docFile5.click()"
                  aria-hidden="true"
                ></i>
                <ng-container *ngIf="report.DocFileName">
                  <div>
                    <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                    <ng-container *ngIf="report.DocFileName && !IsDisabled">{{
                      report.DocFileName
                    }}</ng-container>
                    <a
                      href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                        report.DocFileName
                      }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="report.DocFileName && IsDisabled"
                    >
                      {{ report.DocFileName }}
                    </a>
                    <i
                      class="fa fa-times ml-2"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFileAttachment(i)"
                      *ngIf="!IsDisabled"
                    ></i>
                    <!--<i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ report.DocFileName }}
                   <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer" (click)="removeFileAttachment(i)"></i>-->
                  </div>
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="ConsumerGoldForm == 'ConsumerGoldForm'">
      <form #ff="ngForm" novalidate class>
        <div class="row m-0 mt-2">
          <div class="col-md-2 p-1">
            <span class="required-lable">Item Name</span>
            <input
              required
              type="text"
              #refItemName="ngModel"
              name="ItemName"
              id="ItemName"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refItemName.invalid,
                'alert-warning':
                  refItemName.invalid &&
                  (refItemName.dirty ||
                    refItemName.touched ||
                    refItemName.untouched)
              }"
              [(ngModel)]="Item.ItemName"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Gold Type</span>
            <select
              required
              name="ItemType"
              id="ItemType"
              #refItemType="ngModel"
              [disabled]="IsDisabled"
              [(ngModel)]="Item.ItemType"
              class="form-control input-text-css"
            >
              <option value="">Select Type</option>
              <option value="Sold">Sold</option>
              <option value="Un-Sold">Un-Sold</option>
            </select>
            <!--<input required type="text" #refItemType="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && refItemType.invalid, 'alert-warning': refItemType.invalid  && (refItemType.dirty || refItemType.touched || refItemType.untouched) }"
                [(ngModel)]="Item.ItemType" name="ItemType" id="ItemType"
                class="form-control input-text-css">-->
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Qty</span>
            <input
              required
              type="number"
              min="0"
              numbersOnly
              #refQty="ngModel"
              [disabled]="IsDisabled"
              name="Qty"
              id="Qty"
              [ngClass]="{
                'is-invalid': f.submitted && refQty.invalid,
                'alert-warning':
                  refQty.invalid &&
                  (refQty.dirty || refQty.touched || refQty.untouched)
              }"
              [(ngModel)]="Item.Qty"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Purity CT</span>
            <input
              required
              type="number"
              #refPurityCT="ngModel"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refPurityCT.invalid,
                'alert-warning':
                  refPurityCT.invalid &&
                  (refPurityCT.dirty ||
                    refPurityCT.touched ||
                    refPurityCT.untouched)
              }"
              [(ngModel)]="Item.PurityCT"
              name="PurityCT"
              id="PurityCT"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Gross Wt</span>
            <input
              required
              type="text"
              #refGrossWt="ngModel"
              (change)="ChangeWT()"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refGrossWt.invalid,
                'alert-warning':
                  refGrossWt.invalid &&
                  (refGrossWt.dirty ||
                    refGrossWt.touched ||
                    refGrossWt.untouched)
              }"
              [(ngModel)]="Item.GrossWt"
              name="GrossWt"
              id="GrossWt"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Stone Wt</span>
            <input
              required
              type="text"
              #refStoneWt="ngModel"
              (change)="ChangeWT()"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': f.submitted && refStoneWt.invalid,
                'alert-warning':
                  refStoneWt.invalid &&
                  (refStoneWt.dirty ||
                    refStoneWt.touched ||
                    refStoneWt.untouched)
              }"
              [(ngModel)]="Item.StoneWt"
              name="StoneWt"
              id="StoneWt"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Other Wt</span>
            <input
              required
              type="text"
              #refOtherWt="ngModel"
              (change)="ChangeWT()"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': ff.submitted && refOtherWt.invalid,
                'alert-warning':
                  refOtherWt.invalid &&
                  (refOtherWt.dirty ||
                    refOtherWt.touched ||
                    refOtherWt.untouched)
              }"
              [(ngModel)]="Item.OtherWt"
              name="OtherWt"
              id="OtherWt"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Net Wt</span>
            <input
              required
              type="text"
              #refNetWt="ngModel"
              disabled
              [ngClass]="{
                'is-invalid': ff.submitted && refNetWt.invalid,
                'alert-warning':
                  refNetWt.invalid &&
                  (refNetWt.dirty || refNetWt.touched || refNetWt.untouched)
              }"
              [(ngModel)]="Item.NetWt"
              name="NetWt"
              id="NetWt"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Pure Wt</span>
            <input
              required
              type="text"
              #refPureWt="ngModel"
              disabled
              [ngClass]="{
                'is-invalid': ff.submitted && refPureWt.invalid,
                'alert-warning':
                  refPureWt.invalid &&
                  (refPureWt.dirty || refPureWt.touched || refPureWt.untouched)
              }"
              [(ngModel)]="Item.PureWt"
              name="PureWt"
              id="PureWt"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-1 p-1">
            <span class="required-lable">Rate</span>
            <input
              required
              type="text"
              #refValulationRate_PerCT="ngModel"
              (change)="ChangeWT()"
              [disabled]="IsDisabled"
              [ngClass]="{
                'is-invalid': ff.submitted && refValulationRate_PerCT.invalid,
                'alert-warning':
                  refValulationRate_PerCT.invalid &&
                  (refValulationRate_PerCT.dirty ||
                    refValulationRate_PerCT.touched ||
                    refValulationRate_PerCT.untouched)
              }"
              [(ngModel)]="Item.ValulationRate_PerCT"
              name="ValulationRate_PerCT"
              id="ValulationRate_PerCT"
              class="form-control input-text-css"
            />
          </div>
          <!--<div class="col-md-1 p-1">
            <span class="required-lable">Amount</span>
            <input required type="number" step="0.01" min="0" numbersOnly #refValulationAmount="ngModel" disabled
                   [ngClass]="{ 'is-invalid': ff.submitted && refValulationAmount.invalid, 'alert-warning': refValulationAmount.invalid  && (refValulationAmount.dirty || refValulationAmount.touched || refValulationAmount.untouched) }"
                   [(ngModel)]="Item.ValulationAmount" name="ValulationAmount" id="ValulationAmount"
                   class="form-control input-text-css">
          </div>-->
        </div>

        <div class="row mt-2">
          <div class="col-md-4 p-1">
            <p class="m-0 row">
              Upload Image 1:<i
                class="fa fa-upload ml-2"
                *ngIf="!Item.Image1"
                (click)="imgFile.click()"
                aria-hidden="true"
              ></i>
              <ng-container *ngIf="Item.Image1">
                <div>
                  <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                  <ng-container *ngIf="Item.Image1Data">{{
                    Item.Image1
                  }}</ng-container>
                  <a
                    href="{{ applicationDocUrl }}{{
                      loanSummary.ApplicationNo
                    }}/{{ Item.Image2 }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!Item.Image1Data"
                  >
                    {{ Item.Image1 }}
                  </a>
                  <i
                    class="fa fa-times ml-2"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="removeImage(imgFile)"
                  ></i>
                </div>
              </ng-container>
            </p>
            <input
              #imgFile
              type="file"
              [multiple]="false"
              accept="image/*"
              (change)="imageChangeListener(imgFile.files)"
              style="display: none"
            />
          </div>
          <div class="col-md-4 p-1">
            <p class="m-0 row">
              Upload Image 2:<i
                class="fa fa-upload ml-2"
                *ngIf="!Item.Image2"
                (click)="imgFile1.click()"
                aria-hidden="true"
              ></i>
              <ng-container *ngIf="Item.Image2">
                <div>
                  <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                  <ng-container *ngIf="Item.Image2Data">
                    {{ Item.Image2 }}</ng-container
                  >
                  <a
                    href="{{ applicationDocUrl }}{{
                      loanSummary.ApplicationNo
                    }}/{{ Item.Image2 }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!Item.Image2Data"
                  >
                    {{ Item.Image2 }}
                  </a>
                  <i
                    class="fa fa-times ml-2"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="removeImage1(imgFile1)"
                  ></i>
                </div>
              </ng-container>
            </p>
            <input
              #imgFile1
              type="file"
              [multiple]="false"
              accept="image/*"
              (change)="imageChangeListener1(imgFile1.files)"
              style="display: none"
            />
          </div>
          <div class="col-md-4 p-1 text-right">
            <button
              type="button"
              (click)="saveAssetItem()"
              class="btn font-size-12 button-btn ml-4"
              [class.spinner]="loading"
              [disabled]="!ff.form.valid"
            >
              Add Item
            </button>
          </div>
        </div>
      </form>
      <hr />
      <div class="table-responsive mt-4 mb-4">
        <mat-table [dataSource]="dataAssetItemSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ItemName">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Item Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ItemName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ItemType">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Item Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ItemType
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Qty">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Qty</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Qty
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PurityCT">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Purity CT</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.PurityCT
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="GrossWt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Gross Wt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.GrossWt
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="StoneWt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Stone Wt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.StoneWt
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="OtherWt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Other Wt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.OtherWt
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="NetWt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Net Wt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.NetWt
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PureWt">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Pure Wt</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              ((row.NetWt * (row.PurityCT * fixrate24ct)) / 100).toFixed(2)
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ValulationRate_PerCT">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Rate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ValulationRate_PerCT
            }}</mat-cell>
          </ng-container>

          <!--<ng-container matColumnDef="ValulationAmount">
            <mat-header-cell class="grid-header" *matHeaderCellDef>Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">{{(row.ValulationAmount).toFixed(2)}}</mat-cell>
          </ng-container>-->

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <!--<a href="javascript:void(0)" (click)="editAssetItem(row,i)" style="margin-right: 10px;">Edit</a>
              <a href="javascript:void(0)" (click)="deleteAssetItem(i)">Delete</a>-->
              <i
                class="fa fa-pencil-alt right"
                (click)="editAssetItem(row, i)"
                *ngIf="
                  Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true
                "
                style="cursor: pointer; color: green; font-size: 16px"
                aria-label="true"
              ></i>
              <i
                class="fa fa-trash style-delete ml-3"
                (click)="deleteAssetItem(i)"
                *ngIf="
                  Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true
                "
                style="cursor: pointer"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedAssetItemColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedAssetItemColumns"
          ></mat-row>
        </mat-table>

        <div class="row m-0 mt-4" *ngIf="assetTotal">
          <div class="col-md-3 p-1"><B>Total Qty:</B> {{ assetTotal.Qty }}</div>
          <div class="col-md-3 p-1">
            <B>Total Gross Wt:</B> {{ assetTotal.GrossWt }}
          </div>
          <div class="col-md-3 p-1">
            <B>Total Stone Wt:</B> {{ assetTotal.StoneWt }}
          </div>
          <div class="col-md-3 p-1">
            <B>Total Net Wt:</B> {{ assetTotal.NetWt }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="PersonalForm == 'PersonalForm'">
      <div class="mt-2" *ngIf="!IsDisabled">
        <div class="row m-0 mt-2">
          <div class="col-md-3">
            <span class="required-lable"> Select Category </span>
            <select
              name="Category_Id"
              id="Category_Id"
              #refCategory_Id="ngModel"
              [(ngModel)]="Category_Id"
              class="form-control input-text-css"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refCategory_Id.invalid,
                'alert-warning':
                  refCategory_Id.invalid &&
                  (refCategory_Id.dirty ||
                    refCategory_Id.touched ||
                    refCategory_Id.untouched)
              }"
              placeholder="Enter Category"
              (change)="onChangeCategory(Category_Id)"
            >
              <option value="">Select Category</option>
              <option
                *ngFor="let category of categoryList"
                [value]="category.Id"
              >
                {{ category.Category_Name }}
              </option>
            </select>
          </div>

          <div class="col-md-3">
            <span class="required-lable"> Select Manufacture </span>
            <select
              name="Manufacture_Id"
              id="Manufacture_Id"
              #refManufacture_Id="ngModel"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refManufacture_Id.invalid,
                'alert-warning':
                  refManufacture_Id.invalid &&
                  (refManufacture_Id.dirty ||
                    refManufacture_Id.touched ||
                    refManufacture_Id.untouched)
              }"
              [(ngModel)]="Manufacture_Id"
              class="form-control input-text-css"
              (change)="getProductName(Category_Id, Manufacture_Id)"
            >
              <option value="">Select Manufacture</option>
              <option
                *ngFor="let manufacture of manufactureList"
                [value]="manufacture.Id"
              >
                {{ manufacture.Manufacture_Name }}
              </option>
            </select>
          </div>

          <div class="col-md-3">
            <span class="required-lable"> Select Product </span>
            <select
              name="Product_Id"
              id="Product_Id"
              #refProduct_Id="ngModel"
              [(ngModel)]="Product_Id"
              class="form-control input-text-css"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refProduct_Id.invalid,
                'alert-warning':
                  refProduct_Id.invalid &&
                  (refProduct_Id.dirty ||
                    refProduct_Id.touched ||
                    refProduct_Id.untouched)
              }"
              (change)="getModelName(Product_Id)"
            >
              <option value="">Select Product</option>

              <option *ngFor="let product of productList" [value]="product.Id">
                {{ product.Product_Name }}
              </option>
            </select>
          </div>

          <div class="col-md-3">
            <span class="required-lable"> Select Model </span>
            <select
              name="Model_Id"
              id="Model_Id"
              #refrefModel_Id="ngModel"
              [(ngModel)]="Model_Id"
              class="form-control input-text-css"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refrefModel_Id.invalid,
                'alert-warning':
                  refrefModel_Id.invalid &&
                  (refrefModel_Id.dirty ||
                    refrefModel_Id.touched ||
                    refrefModel_Id.untouched)
              }"
            >
              <option value="">Select Model</option>

              <option *ngFor="let model of modelList" [value]="model.Id">
                {{ model.Model_Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row m-0 mt-2">
          <div class="col-md-3">
            <span>Variant</span>
            <input
              type="text"
              #refVarient="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refVarient.invalid,
                'alert-warning':
                  refVarient.invalid &&
                  (refVarient.dirty ||
                    refVarient.touched ||
                    refVarient.untouched)
              }"
              [(ngModel)]="Varient"
              name="Varient"
              id="Varient"
              class="form-control input-text-css"
              placeholder="Enter Variant"
            />
          </div>

          <div class="col-md-3">
            <span>Identification 1</span>
            <input
              type="text"
              #refIdentification_No1="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refIdentification_No1.invalid,
                'alert-warning':
                  refIdentification_No1.invalid &&
                  (refIdentification_No1.dirty ||
                    refIdentification_No1.touched ||
                    refIdentification_No1.untouched)
              }"
              [(ngModel)]="Identification_No1"
              name="Identification_No1"
              id="Identification_No1"
              class="form-control input-text-css"
              placeholder="Enter Identification 1"
            />
          </div>
          <div class="col-md-3">
            <span>Identification 2</span>
            <input
              type="text"
              #refIdentification_No2="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refIdentification_No2.invalid,
                'alert-warning':
                  refIdentification_No2.invalid &&
                  (refIdentification_No2.dirty ||
                    refIdentification_No2.touched ||
                    refIdentification_No2.untouched)
              }"
              [(ngModel)]="Identification_No2"
              name="Identification_No2"
              id="Identification_No2"
              class="form-control input-text-css"
              placeholder="Enter Identification 2"
            />
          </div>

          <div class="col-md-3">
            <span class="required-lable">Cost</span>
            <input
              required
              type="number"
              #refCost="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refCost.invalid,
                'alert-warning':
                  refCost.invalid &&
                  (refCost.dirty || refCost.touched || refCost.untouched)
              }"
              [(ngModel)]="Cost"
              name="Cost"
              id="Cost"
              class="form-control input-text-css"
              placeholder="Enter Cost"
            />
          </div>
        </div>

        <div class="row m-0 mt-2">
          <div class="col-md-3">
            <span>Color</span>
            <input
              type="text"
              #refColorName="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refColorName.invalid,
                'alert-warning':
                  refColorName.invalid &&
                  (refColorName.dirty ||
                    refColorName.touched ||
                    refColorName.untouched)
              }"
              [(ngModel)]="ColorName"
              name="ColorName"
              id="ColorName"
              class="form-control input-text-css"
              placeholder="Enter Color"
            />
          </div>
          <div class="col-md-3">
            <span class="required-lable">Dealer Name</span>
            <select
              name="Dealer_Id"
              (change)="DealerChange(Dealer_Id)"
              id="Dealer_Id"
              #refDealer_Id="ngModel"
              required
              [(ngModel)]="Dealer_Id"
              [ngClass]="{
                'is-invalid': f.submitted && refDealer_Id.invalid,
                'alert-warning':
                  refDealer_Id.invalid &&
                  (refDealer_Id.dirty ||
                    refDealer_Id.touched ||
                    refDealer_Id.untouched)
              }"
              class="form-control input-text-css"
            >
              <option value="">Select Dealer</option>
              <option
                *ngFor="let collection of DealerList"
                [value]="collection.PartnerId"
              >
                {{ collection.Partner }}
              </option>
            </select>
          </div>

          <div class="col-md-3">
            <span>Dealer Contact Person</span>
            <input
              type="text"
              #refDealerPerson="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refDealerPerson.invalid,
                'alert-warning':
                  refDealerPerson.invalid &&
                  (refDealerPerson.dirty ||
                    refDealerPerson.touched ||
                    refDealerPerson.untouched)
              }"
              [(ngModel)]="DealerPerson"
              name="DealerPerson"
              id="DealerPerson"
              class="form-control input-text-css"
              placeholder="Enter Dealer Number"
            />
          </div>
          <div class="col-md-3">
            <span>Dealer Contact Number</span>
            <input
              type="number"
              #refDealerPhoneNo="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refDealerPhoneNo.invalid,
                'alert-warning':
                  refDealerPhoneNo.invalid &&
                  (refDealerPhoneNo.dirty ||
                    refDealerPhoneNo.touched ||
                    refDealerPhoneNo.untouched)
              }"
              [(ngModel)]="DealerPhoneNo"
              name="DealerPhoneNo"
              id="DealerPhoneNo"
              class="form-control input-text-css"
              placeholder="Enter Dealer Number"
            />
          </div>

          <div class="col-md-3">
            <span class="required-lable">Invoice No.</span>
            <input
              required
              type="text"
              #refInvoice_No="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refInvoice_No.invalid,
                'alert-warning':
                  refInvoice_No.invalid &&
                  (refInvoice_No.dirty ||
                    refInvoice_No.touched ||
                    refInvoice_No.untouched)
              }"
              [(ngModel)]="Invoice_No"
              name="Invoice_No"
              id="Invoice_No"
              class="form-control input-text-css"
              placeholder="Enter Invoice No."
            />
          </div>

          <div class="col-md-3">
            <span class="required-lable">Invoice Date</span>
            <div class="datepicker_feild">
              <input
                [matDatepicker]="picker1"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="Invoice_Date"
                id="Invoice_Date"
                #refInvoice_Date="ngModel"
                [max]="today"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refInvoice_Date.invalid,
                  'alert-warning':
                    refInvoice_Date.invalid &&
                    (refInvoice_Date.dirty ||
                      refInvoice_Date.touched ||
                      refInvoice_Date.untouched)
                }"
                class="form-control input-text-css"
                [(ngModel)]="Invoice_Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!IsDisabled">
        <div class="row m-0">
          <div class="col-md-12 p-1">
            <div class="bg-success1">Upload Images:</div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-md-12 upload">
            <!-- <B> Upload Vehicle Images </B> -->
            <input
              #docFile5
              type="file"
              [multiple]="false"
              accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
              (change)="fileChangeListenerAttachment(docFile5.files, docFile5)"
              style="display: none"
            />
            <div
              class="row m-0 mt-2 align-items-center"
              *ngFor="let report of AssetsAttachment; let i = index"
            >
              <div class="col-md-1 pl-0">{{ i + 1 }}</div>
              <div class="col-md-3">
                <input
                  type="text"
                  placeholder="Title"
                  name="title{{ i }}"
                  id="title{{ i }}"
                  #refTitle="ngModel"
                  [(ngModel)]="report.Title"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <p class="m-0 row">
                  <i
                    class="fa fa-upload ml-2"
                    *ngIf="!report.DocFileName"
                    (click)="docFile5.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="report.DocFileName">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ report.DocFileName }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFileAttachment(i)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row m-0 mt-3" *ngIf="IsDisabled">
        <div class="col-md-2">
          <h6 class="fs-12">Category Name</h6>
          <p class="lead fs-12">{{ Info.Category_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Manufacture Name</h6>
          <p class="lead fs-12">{{ Info.Manufacture_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Product_Name</h6>
          <p class="lead fs-12">{{ Info.Product_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Model Name</h6>
          <p class="lead fs-12">{{ Info.Model_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Varient</h6>
          <p class="lead fs-12">{{ Info.Varient }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Identification No1</h6>
          <p class="lead fs-12">{{ Info.Identification_No1 }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Identification No2</h6>
          <p class="lead fs-12">{{ Info.Identification_No2 }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Cost</h6>
          <p class="lead fs-12">{{ Info.Cost }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Color</h6>
          <p class="lead fs-12">{{ Info.Color }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Invoice_No</h6>
          <p class="lead fs-12">{{ Info.Invoice_No }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Invoice Date</h6>
          <p class="lead fs-12">{{ Info.Invoice_Date }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Dealer Name</h6>
          <p class="lead fs-12">{{ Info.Dealer_Name }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Dealer Contact Person</h6>
          <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Dealer Contact No</h6>
          <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
        </div>
      </div>
      <div class="row m-0 mt-2" *ngIf="IsDisabled">
        <div class="col-md-12 mt-2 upload" *ngIf="AssetsAttachment.length > 0">
          <b> Uploaded Consumer Durable Images </b>

          <div
            class="row m-0 mt-2 align-items-center"
            *ngFor="let report of AssetsAttachment; let i = index"
          >
            <div class="col-md-1 pl-0">
              {{ i + 1 }}
            </div>
            <div class="col-md-3">
              <p class="lead fs-12">{{ report.Title }}</p>
            </div>
            <div class="col-md-3">
              <p class="m-0 row">
                <a
                  href="{{ applicationDocUrl }}{{
                    loanSummary.ApplicationNo
                  }}/{{ report.DocFileName }}"
                  target="_blank"
                  *ngIf="report.DocFileName"
                  >{{ report.DocFileName }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      class="row m-0 mt-2"
      *ngIf="Processid != 16 && Processid != 17 && dataLegalSource"
    >
      <div class="col-md-12 p-1">
        <div class="bg-success1">Legal Info:</div>
      </div>
    </div>

    <ng-container *ngIf="Processid != 16 && Processid != 17 && dataLegalSource">
      <div class="table-responsive mt-4 mb-4">
        <mat-table [dataSource]="dataLegalSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Process_VendorName">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Vendor Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Process_VendorName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ProcessDone_on">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Done on</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ProcessDone_on
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Attachment1">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Attachment1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                href="{{ applicationDocUrl }}{{ loanSummary.ApplicationNo }}/{{
                  row.Attachment1
                }}"
                target="_blank"
                *ngIf="row.Attachment1"
                >{{ row.Attachment1 }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Attachment2">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Attachment2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                href="{{ applicationDocUrl }}{{ loanSummary.ApplicationNo }}/{{
                  row.Attachment2
                }}"
                target="_blank"
                *ngIf="row.Attachment2"
                >{{ row.Attachment2 }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Process_Remark">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Remark</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Process_Remark
            }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedLegalColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedLegalColumns"
          ></mat-row>
        </mat-table>
      </div>
    </ng-container>

    <div
      class="row m-0 mt-2"
      *ngIf="Processid != 16 && Processid != 17 && dataValuationSource"
    >
      <div class="col-md-12 p-1">
        <div class="bg-success1">Valuation Info:</div>
      </div>
    </div>
    <ng-container
      *ngIf="Processid != 16 && Processid != 17 && dataValuationSource"
    >
      <div class="table-responsive mt-4 mb-4">
        <mat-table [dataSource]="dataValuationSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Process_VendorName">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Vendor Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Process_VendorName
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Valuation_Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Valuation Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Valuation_Type
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Valuation_Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Valuation Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Valuation_Amount
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Valuation_Type2">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Valuation Type2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Valuation_Type2
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Valuation_Amount2">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Valuation Amount2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Valuation_Amount2
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ProcessDone_on">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Done on</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ProcessDone_on
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Attachment1">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Attachment1</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                href="{{ applicationDocUrl }}{{ loanSummary.ApplicationNo }}/{{
                  row.Attachment1
                }}"
                target="_blank"
                *ngIf="row.Attachment1"
                >{{ row.Attachment1 }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Attachment2">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Attachment2</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                href="{{ applicationDocUrl }}{{ loanSummary.ApplicationNo }}/{{
                  row.Attachment2
                }}"
                target="_blank"
                *ngIf="row.Attachment2"
                >{{ row.Attachment2 }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Process_Remark">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Remark</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Process_Remark
            }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedValuationColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedValuationColumns"
          ></mat-row>
        </mat-table>
      </div>
    </ng-container>

    <div class="row m-0 mt-4 ml-2">
      <div class="col-md-12 p-1 text-right">
        <button
          type="button"
          (click)="saveAssetInfo('Primary Collateral')"
          [disabled]="!f.form.valid"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          *ngIf="saveBtn"
        >
          Save
        </button>

        <button
          type="button"
          (click)="onEditAssetsInfo()"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          *ngIf="
            isEdit &&
            PageData.currentTab == 1 &&
            Status != 'C' &&
            (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
          "
        >
          Edit
        </button>

        <button
          type="button"
          (click)="saveAssetInfo('Primary Collateral')"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
          *ngIf="updateBtn"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</div>
<div
  class="modal fade in"
  id="duplicateData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ DuplicateType }} already exist .
        </h6>
        <button
          type="button"
          (click)="onCloseduplicateData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3" *ngIf="duplicateData">
          <div class="table-responsive mt-3">
            <table class="table table-bordered">
              <thead>
                <tr
                  style="
                    color: #fff !important;
                    background-color: #4dc3a3 !important;
                  "
                >
                  <th scope="col">#</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Application Number</th>
                  <th scope="col">Loan Number</th>
                  <th scope="col">Loan Date</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Branch Name</th>
                  <th scope="col">Registation No</th>
                  <th scope="col">Chassis No</th>
                  <th scope="col">Engine No</th>
                  <th scope="col">Loan Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of duplicateData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.Customer_Name }}</td>
                  <td>{{ data.Application_No }}</td>
                  <td>{{ data.LoanAcNo }}</td>
                  <td>{{ data.Loan_Date }}</td>
                  <td>{{ data.Product_Name }}</td>
                  <td>{{ data.Branch_Name }}</td>
                  <td>{{ data.VehicleRegistationNo.toUpperCase() }}</td>
                  <td>{{ data.ChassisNo }}</td>
                  <td>{{ data.EngineNo }}</td>
                  <td>{{ data.LoanStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--<div class="row col-md-12 m-0 mt-3 justify-content-center">
            <button type="button" (click)="onNoClick()" class="btn btn-light font-size-12">
              No
            </button>
            <button type="button" (click)="onYesClick()" class="ml-4"
                    style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary">
              yes
            </button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="viewVehicleGridModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Vehicle Grid Model
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div
          style="display: flex; align-items: center; gap: 40px"
          class="formborder"
        >
          <div class="col-md-2">
            <span class="required-lable">Year</span>
            <input
              name="getStartYear"
              [(ngModel)]="ManufactureYearModal"
              id="getStartYear"
              placeholder="Start Year"
              class="form-control input-text-css"
              bsDatepicker
              [bsConfig]="bsConfig"
              (ngModelChange)="onYearChangeVehicleGridCallAPi($event)"
              required
              #refgetStartYear="ngModel"
            />
          </div>

          <div class="col-md-4 mr_top pr-0">
            <div>
              <span class="required-lable">Model Name</span>
            </div>

            <input
              [(ngModel)]="Vehicle_Model_Name"
              type="search"
              class="form-control input-text-css"
              id="modelName"
              name="modelName"
              list="dynmicChargeHead"
              autocomplete="off"
              required
              placeholder="Select Model Name"
              (ngModelChange)="onModelIdChangeVehicleGrid($event)"
            />
            <datalist id="dynmicChargeHead">
              <option
                *ngFor="let item of modelNameDropdown"
                [value]="item.Vehicle_Model_Name"
                title="item.Vehicle_Model_Name"
              ></option>
            </datalist>
          </div>
        </div>

        <div>
          <div class="table-responsive mt-3" [hidden]="!dataSourceViewDetails">
            <table
              mat-table
              [dataSource]="dataSourceViewDetails"
              class="mat-elevation-z8"
              style="overflow: auto"
            >
              <ng-container matColumnDef="modelId">
                <th mat-header-cell *matHeaderCellDef>Model ID</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="modelName">
                <th mat-header-cell *matHeaderCellDef>Model Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.modelName }}
                </td>
              </ng-container>

              <ng-container
                *ngFor="let column of displayedColumnsVehicleGridModel.slice(2)"
                [matColumnDef]="column"
              >
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let element" class="text-left">
                  {{ element[column] || "-" }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsVehicleGridModel"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsVehicleGridModel"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
