import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;
import * as moment from "moment";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import {
  RowSpanComputer,
  Span,
} from "src/app/_Reports/services/row-span-computer";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-collection-target-achievement",
  templateUrl: "./collection-target-achievement.component.html",
  styleUrls: ["./collection-target-achievement.component.scss"],
})
export class CollectionTargetAchievementComponent implements OnInit {
  isVertical: boolean = false;
  showSpinner: boolean = false;
  en_month_year: any = new Date();
  reviseRemarks: any;
  Target_month_year: any = new Date();
  ModelDetail: any = [];
  ModelDetail1: Array<Span[]>;
  C_Level1: Array<Span[]>;
  C_Level2: Array<Span[]>;
  C_Level3: Array<Span[]>;
  C_Level4: Array<Span[]>;
  C_Level5: Array<Span[]>;
  C_Level6: Array<Span[]>;
  C_Level7: Array<Span[]>;
  C_Level8: Array<Span[]>;
  C_Level9: Array<Span[]>;
  C_Level10: Array<Span[]>;

  private rowSpanComputer = new RowSpanComputer();

  FyearId: any;
  lmsService: any;
  ReportsService: any;
  currentUser: any;
  dataCSource: any;
  loading: boolean = false;
  Level1: any = [];
  Level2: any = [];
  Level3: any = [];
  Level4: any = [];
  Level5: any = [];
  Level6: any = [];
  Level7: any = [];
  Level8: any = [];
  Level9: any = [];
  Level10: any = [];
  targetId: any = 0;
  columnNames = [
    "EmpId",
    "Emp_Name",
    "Manager",
    "Type",
    "SubType",
    "Total",
    "DPD_001_030",
    "DPD_031_060",
    "DPD_061_090",
    "DPD_091_120",
    "DPD_121_150",
    "DPD_151_180",
    "DPD_181_360",
    "DPD_361_720",
    "DPD_721_1000",
    "DPD_Above_1000",
  ];
  modelcolumnNames = [
    "C_Month",
    "Type",
    "SubType",
    "Total",
    "DPD_001_030",
    "DPD_031_060",
    "DPD_061_090",
    "DPD_091_120",
    "DPD_121_150",
    "DPD_151_180",
    "DPD_181_360",
    "DPD_361_720",
    "DPD_721_1000",
    "DPD_Above_1000",
  ];

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _ReportsService: ReportsService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.en_month_year = new Date(
            moment(res[0].fyent).format("MM/DD/YYYY")
          );
          // this.DataSource = null;
        });
    });
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(
      "Collection Target and Achievement"
    );
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Collection_Target_VS_Achievement();
    // this.getDataDetail();
  }

  Get_Collection_Target_VS_Achievement() {
    let EnMonth = this.en_month_year.getMonth() + 1;
    let EnYear = this.en_month_year.getFullYear();

    this.Level1 = [];
    this.Level2 = [];
    this.Level3 = [];
    this.Level4 = [];
    this.Level5 = [];
    this.Level6 = [];
    this.Level7 = [];
    this.Level8 = [];
    this.Level9 = [];
    this.Level10 = [];
    this.targetId = 0;
    this.showSpinner = true;
    this._ReportsService
      .Report_Collection_Target_VS_Achievement({
        LoginUserId: this.currentUser.userId,
        EndtMonth: EnMonth,
        EndYear: EnYear,
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.Level1 = res.filter((r) => r.EmpId === this.currentUser.userId);
        console.log("Level 1",this.Level1);

        this.setLevel1();
        this.setLevel(res, this.Level2, this.Level1);
        this.setLevel(res, this.Level3, this.Level2);
        this.setLevel(res, this.Level4, this.Level3);
        this.setLevel(res, this.Level5, this.Level4);
        this.setLevel(res, this.Level6, this.Level5);
        this.setLevel(res, this.Level7, this.Level6);
        this.setLevel(res, this.Level8, this.Level7);
        this.setLevel(res, this.Level9, this.Level8);
        this.setLevel(res, this.Level10, this.Level9);

        this.Level1 = this.Level1.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level2 = this.Level2.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level3 = this.Level3.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level4 = this.Level4.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level5 = this.Level5.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level6 = this.Level6.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level7 = this.Level7.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level8 = this.Level8.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level9 = this.Level9.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level10 = this.Level10.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );

        this.C_Level1 = this.rowSpanComputer.compute(
          this.Level1,
          this.columnNames
        );
        this.C_Level2 = this.rowSpanComputer.compute(
          this.Level2,
          this.columnNames
        );
        this.C_Level3 = this.rowSpanComputer.compute(
          this.Level3,
          this.columnNames
        );
        this.C_Level4 = this.rowSpanComputer.compute(
          this.Level4,
          this.columnNames
        );
        this.C_Level5 = this.rowSpanComputer.compute(
          this.Level5,
          this.columnNames
        );
        this.C_Level6 = this.rowSpanComputer.compute(
          this.Level6,
          this.columnNames
        );
        this.C_Level7 = this.rowSpanComputer.compute(
          this.Level7,
          this.columnNames
        );
        this.C_Level8 = this.rowSpanComputer.compute(
          this.Level8,
          this.columnNames
        );
        this.C_Level9 = this.rowSpanComputer.compute(
          this.Level9,
          this.columnNames
        );
        this.C_Level10 = this.rowSpanComputer.compute(
          this.Level10,
          this.columnNames
        );

        // this.Level3 = res.filter(r => r.Managerid === this.currentUser.userId && r.Type == 'Target')
      });
  }
  setLevel1() {
    let _findNumber = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Percentage" &&
        obj.SubType == "Numbers" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    let CheckTargetAmount = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Target" &&
        obj.SubType == "Amount" &&
        obj.EmpId == this.currentUser.userId
      );
    });

    let _findTargetNumber = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Target" &&
        obj.SubType == "Numbers" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    let _findAchivementNumber = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Achivement" &&
        obj.SubType == "Numbers" &&
        obj.EmpId == this.currentUser.userId
      );
    });

    if (
      _findTargetNumber &&
      _findAchivementNumber &&
      !_findNumber &&
      CheckTargetAmount
    ) {
      this.Level1.push({
        EmpId: this.Level1[0].EmpId,
        Emp_Name: this.Level1[0].Emp_Name,
        Designation: this.Level1[0].Designation,
        Managerid: this.Level1[0].Managerid,
        Manager: this.Level1[0].Manager,
        Type: "Percentage",
        SubType: "Numbers",
        Total:
          _findTargetNumber.Total > 0
            ? (
                100 -
                (_findAchivementNumber.Total / _findTargetNumber.Total) * 100
              ).toFixed(2)
            : 0,
        DPD_001_030:
          _findTargetNumber.DPD_001_030 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_001_030 /
                  _findTargetNumber.DPD_001_030) *
                  100
              ).toFixed(2)
            : 0,
        DPD_031_060:
          _findTargetNumber.DPD_031_060 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_031_060 /
                  _findTargetNumber.DPD_031_060) *
                  100
              ).toFixed(2)
            : 0,
        DPD_061_090:
          _findTargetNumber.DPD_061_090 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_061_090 /
                  _findTargetNumber.DPD_061_090) *
                  100
              ).toFixed(2)
            : 0,
        DPD_091_120:
          _findTargetNumber.DPD_091_120 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_091_120 /
                  _findTargetNumber.DPD_091_120) *
                  100
              ).toFixed(2)
            : 0,
        DPD_121_150:
          _findTargetNumber.DPD_121_150 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_121_150 /
                  _findTargetNumber.DPD_121_150) *
                  100
              ).toFixed(2)
            : 0,
        DPD_151_180:
          _findTargetNumber.DPD_151_180 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_151_180 /
                  _findTargetNumber.DPD_151_180) *
                  100
              ).toFixed(2)
            : 0,
        DPD_181_360:
          _findTargetNumber.DPD_181_360 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_181_360 /
                  _findTargetNumber.DPD_181_360) *
                  100
              ).toFixed(2)
            : 0,
        DPD_361_720:
          _findTargetNumber.DPD_361_720 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_361_720 /
                  _findTargetNumber.DPD_361_720) *
                  100
              ).toFixed(2)
            : 0,
        DPD_721_1000:
          _findTargetNumber.DPD_721_1000 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_721_1000 /
                  _findTargetNumber.DPD_721_1000) *
                  100
              ).toFixed(2)
            : 0,
        DPD_Above_1000:
          _findTargetNumber.DPD_Above_1000 > 0
            ? (
                100 -
                (_findAchivementNumber.DPD_Above_1000 /
                  _findTargetNumber.DPD_Above_1000) *
                  100
              ).toFixed(2)
            : 0,
      });
    }

    let _findAmount = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Percentage" &&
        obj.SubType == "Amount" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    let CheckTargetNumber = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Target" &&
        obj.SubType == "Numbers" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    let _findTargetAmount = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Target" &&
        obj.SubType == "Amount" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    let _findAchivementAmount = this.Level1.find((obj: any) => {
      return (
        obj.Type == "Achivement" &&
        obj.SubType == "Amount" &&
        obj.EmpId == this.currentUser.userId
      );
    });
    if (
      _findTargetAmount &&
      _findAchivementAmount &&
      !_findAmount &&
      CheckTargetNumber
    ) {
      this.Level1.push({
        EmpId: this.Level1[0].EmpId,
        Emp_Name: this.Level1[0].Emp_Name,
        Designation: this.Level1[0].Designation,
        Managerid: this.Level1[0].Managerid,
        Manager: this.Level1[0].Manager,
        Type: "Percentage",
        SubType: "Amount",
        Total:
          _findTargetAmount.Total > 0
            ? (
                100 -
                (_findAchivementAmount.Total / _findTargetAmount.Total) * 100
              ).toFixed(2)
            : 0,
        DPD_001_030:
          _findTargetAmount.DPD_001_030 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_001_030 /
                  _findTargetAmount.DPD_001_030) *
                  100
              ).toFixed(2)
            : 0,
        DPD_031_060:
          _findTargetAmount.DPD_031_060 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_031_060 /
                  _findTargetAmount.DPD_031_060) *
                  100
              ).toFixed(2)
            : 0,
        DPD_061_090:
          _findTargetAmount.DPD_061_090 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_061_090 /
                  _findTargetAmount.DPD_061_090) *
                  100
              ).toFixed(2)
            : 0,
        DPD_091_120:
          _findTargetAmount.DPD_091_120 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_091_120 /
                  _findTargetAmount.DPD_091_120) *
                  100
              ).toFixed(2)
            : 0,
        DPD_121_150:
          _findTargetAmount.DPD_121_150 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_121_150 /
                  _findTargetAmount.DPD_121_150) *
                  100
              ).toFixed(2)
            : 0,
        DPD_151_180:
          _findTargetAmount.DPD_151_180 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_151_180 /
                  _findTargetAmount.DPD_151_180) *
                  100
              ).toFixed(2)
            : 0,
        DPD_181_360:
          _findTargetAmount.DPD_181_360 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_181_360 /
                  _findTargetAmount.DPD_181_360) *
                  100
              ).toFixed(2)
            : 0,
        DPD_361_720:
          _findTargetAmount.DPD_361_720 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_361_720 /
                  _findTargetAmount.DPD_361_720) *
                  100
              ).toFixed(2)
            : 0,
        DPD_721_1000:
          _findTargetAmount.DPD_721_1000 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_721_1000 /
                  _findTargetAmount.DPD_721_1000) *
                  100
              ).toFixed(2)
            : 0,
        DPD_Above_1000:
          _findTargetAmount.DPD_Above_1000 > 0
            ? (
                100 -
                (_findAchivementAmount.DPD_Above_1000 /
                  _findTargetAmount.DPD_Above_1000) *
                  100
              ).toFixed(2)
            : 0,
      });
    }
  }
  setLevel(res, PushLevel, Level) {
    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < Level.length; j++) {
        let _find = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Achivement" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });
        if (
          res[i].Managerid === Level[j].EmpId &&
          res[i].Type == "Achivement" &&
          res[i].SubType == "Numbers" &&
          !_find
        ) {
          PushLevel.push(res[i]);
        }

        let _find1 = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Achivement" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });
        if (
          res[i].Managerid === Level[j].EmpId &&
          res[i].Type == "Achivement" &&
          res[i].SubType == "Amount" &&
          !_find1
        ) {
          PushLevel.push(res[i]);
        }

        let _findTarget = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });
        if (
          res[i].Managerid === Level[j].EmpId &&
          res[i].Type == "Target" &&
          res[i].SubType == "Numbers" &&
          res.find((obj: any) => {
            return obj.Type == "Achivement" && obj.EmpId == res[i].EmpId;
          }) &&
          !_findTarget
        ) {
          PushLevel.push(res[i]);
        }

        let _findTarget1 = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });
        if (
          res[i].Managerid === Level[j].EmpId &&
          res[i].Type == "Target" &&
          res[i].SubType == "Amount" &&
          res.find((obj: any) => {
            return obj.Type == "Achivement" && obj.EmpId == res[i].EmpId;
          }) &&
          !_findTarget1
        ) {
          PushLevel.push(res[i]);
        }

        let _findNumber = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Percentage" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });
        let CheckTargetAmount = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });

        let _findTargetNumber = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });
        let _findAchivementNumber = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Achivement" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });

        if (
          _findTargetNumber &&
          _findAchivementNumber &&
          !_findNumber &&
          CheckTargetAmount
        ) {
          PushLevel.push({
            EmpId: res[i].EmpId,
            Emp_Name: res[i].Emp_Name,
            Designation: res[i].Designation,
            Managerid: res[i].Managerid,
            Manager: res[i].Manager,
            Type: "Percentage",
            SubType: "Numbers",
            Total:
              _findTargetNumber.Total > 0
                ? (
                    100 -
                    (_findAchivementNumber.Total / _findTargetNumber.Total) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_001_030:
              _findTargetNumber.DPD_001_030 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_001_030 /
                      _findTargetNumber.DPD_001_030) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_031_060:
              _findTargetNumber.DPD_031_060 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_031_060 /
                      _findTargetNumber.DPD_031_060) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_061_090:
              _findTargetNumber.DPD_061_090 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_061_090 /
                      _findTargetNumber.DPD_061_090) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_091_120:
              _findTargetNumber.DPD_091_120 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_091_120 /
                      _findTargetNumber.DPD_091_120) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_121_150:
              _findTargetNumber.DPD_121_150 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_121_150 /
                      _findTargetNumber.DPD_121_150) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_151_180:
              _findTargetNumber.DPD_151_180 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_151_180 /
                      _findTargetNumber.DPD_151_180) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_181_360:
              _findTargetNumber.DPD_181_360 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_181_360 /
                      _findTargetNumber.DPD_181_360) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_361_720:
              _findTargetNumber.DPD_361_720 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_361_720 /
                      _findTargetNumber.DPD_361_720) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_721_1000:
              _findTargetNumber.DPD_721_1000 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_721_1000 /
                      _findTargetNumber.DPD_721_1000) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_Above_1000:
              _findTargetNumber.DPD_Above_1000 > 0
                ? (
                    100 -
                    (_findAchivementNumber.DPD_Above_1000 /
                      _findTargetNumber.DPD_Above_1000) *
                      100
                  ).toFixed(2)
                : 0,
          });
        }

        let _findAmount = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Percentage" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });
        let CheckTargetNumber = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Numbers" &&
            obj.EmpId == res[i].EmpId
          );
        });
        let _findTargetAmount = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Target" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });
        let _findAchivementAmount = PushLevel.find((obj: any) => {
          return (
            obj.Type == "Achivement" &&
            obj.SubType == "Amount" &&
            obj.EmpId == res[i].EmpId
          );
        });
        if (
          _findTargetAmount &&
          _findAchivementAmount &&
          !_findAmount &&
          CheckTargetNumber
        ) {
          PushLevel.push({
            EmpId: res[i].EmpId,
            Emp_Name: res[i].Emp_Name,
            Designation: res[i].Designation,
            Managerid: res[i].Managerid,
            Manager: res[i].Manager,
            Type: "Percentage",
            SubType: "Amount",
            Total:
              _findTargetAmount.Total > 0
                ? (
                    100 -
                    (_findAchivementAmount.Total / _findTargetAmount.Total) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_001_030:
              _findTargetAmount.DPD_001_030 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_001_030 /
                      _findTargetAmount.DPD_001_030) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_031_060:
              _findTargetAmount.DPD_031_060 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_031_060 /
                      _findTargetAmount.DPD_031_060) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_061_090:
              _findTargetAmount.DPD_061_090 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_061_090 /
                      _findTargetAmount.DPD_061_090) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_091_120:
              _findTargetAmount.DPD_091_120 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_091_120 /
                      _findTargetAmount.DPD_091_120) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_121_150:
              _findTargetAmount.DPD_121_150 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_121_150 /
                      _findTargetAmount.DPD_121_150) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_151_180:
              _findTargetAmount.DPD_151_180 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_151_180 /
                      _findTargetAmount.DPD_151_180) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_181_360:
              _findTargetAmount.DPD_181_360 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_181_360 /
                      _findTargetAmount.DPD_181_360) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_361_720:
              _findTargetAmount.DPD_361_720 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_361_720 /
                      _findTargetAmount.DPD_361_720) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_721_1000:
              _findTargetAmount.DPD_721_1000 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_721_1000 /
                      _findTargetAmount.DPD_721_1000) *
                      100
                  ).toFixed(2)
                : 0,
            DPD_Above_1000:
              _findTargetAmount.DPD_Above_1000 > 0
                ? (
                    100 -
                    (_findAchivementAmount.DPD_Above_1000 /
                      _findTargetAmount.DPD_Above_1000) *
                      100
                  ).toFixed(2)
                : 0,
          });
        }
      }
    }
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  goToEmp_NameDetail(row) {
    this.Target_month_year = this.en_month_year;
    let EnMonth = this.Target_month_year.getMonth() + 1;
    let EnYear = this.Target_month_year.getFullYear();
    this.showSpinner = true;
    this._ReportsService
      .Report_Collection_By_Employee({
        LoginUserId: this.currentUser.userId,
        EndtMonth: EnMonth,
        EndYear: EnYear,
        EmpId: row.EmpId,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.ModelDetail = res;
          this.targetId = this.ModelDetail.filter(
            (r) => r.Type === "Collection" && r.SubType == "Amount"
          )[0].TargetId;

          this.ModelDetail[4]["Total"] =
            this.ModelDetail[0]["Total"] - this.ModelDetail[2]["Total"];
          this.ModelDetail[5]["Total"] =
            this.ModelDetail[1]["Total"] - this.ModelDetail[3]["Total"];
          this.ModelDetail[4]["DPD_001_030"] =
            this.ModelDetail[0]["DPD_001_030"] -
            this.ModelDetail[2]["DPD_001_030"];
          this.ModelDetail[5]["DPD_001_030"] =
            this.ModelDetail[1]["DPD_001_030"] -
            this.ModelDetail[3]["DPD_001_030"];

          this.ModelDetail[4]["DPD_031_060"] =
            this.ModelDetail[0]["DPD_031_060"] -
            this.ModelDetail[2]["DPD_031_060"];
          this.ModelDetail[5]["DPD_031_060"] =
            this.ModelDetail[1]["DPD_031_060"] -
            this.ModelDetail[3]["DPD_031_060"];

          this.ModelDetail[4]["DPD_061_090"] =
            this.ModelDetail[0]["DPD_061_090"] -
            this.ModelDetail[2]["DPD_061_090"];
          this.ModelDetail[5]["DPD_061_090"] =
            this.ModelDetail[1]["DPD_061_090"] -
            this.ModelDetail[3]["DPD_061_090"];

          this.ModelDetail[4]["DPD_091_120"] =
            this.ModelDetail[0]["DPD_091_120"] -
            this.ModelDetail[2]["DPD_091_120"];
          this.ModelDetail[5]["DPD_091_120"] =
            this.ModelDetail[1]["DPD_091_120"] -
            this.ModelDetail[3]["DPD_091_120"];

          this.ModelDetail[4]["DPD_121_150"] =
            this.ModelDetail[0]["DPD_121_150"] -
            this.ModelDetail[2]["DPD_121_150"];
          this.ModelDetail[5]["DPD_121_150"] =
            this.ModelDetail[1]["DPD_121_150"] -
            this.ModelDetail[3]["DPD_121_150"];

          this.ModelDetail[4]["DPD_151_180"] =
            this.ModelDetail[0]["DPD_151_180"] -
            this.ModelDetail[2]["DPD_151_180"];
          this.ModelDetail[5]["DPD_151_180"] =
            this.ModelDetail[1]["DPD_151_180"] -
            this.ModelDetail[3]["DPD_151_180"];

          this.ModelDetail[4]["DPD_181_360"] =
            this.ModelDetail[0]["DPD_181_360"] -
            this.ModelDetail[2]["DPD_181_360"];
          this.ModelDetail[5]["DPD_181_360"] =
            this.ModelDetail[1]["DPD_181_360"] -
            this.ModelDetail[3]["DPD_181_360"];

          this.ModelDetail[4]["DPD_361_720"] =
            this.ModelDetail[0]["DPD_361_720"] -
            this.ModelDetail[2]["DPD_361_720"];
          this.ModelDetail[5]["DPD_361_720"] =
            this.ModelDetail[1]["DPD_361_720"] -
            this.ModelDetail[3]["DPD_361_720"];

          this.ModelDetail[4]["DPD_721_1000"] =
            this.ModelDetail[0]["DPD_721_1000"] -
            this.ModelDetail[2]["DPD_721_1000"];
          this.ModelDetail[5]["DPD_721_1000"] =
            this.ModelDetail[1]["DPD_721_1000"] -
            this.ModelDetail[3]["DPD_721_1000"];

          this.ModelDetail[4]["DPD_Above_1000"] =
            this.ModelDetail[0]["DPD_Above_1000"] -
            this.ModelDetail[2]["DPD_Above_1000"];
          this.ModelDetail[5]["DPD_Above_1000"] =
            this.ModelDetail[1]["DPD_Above_1000"] -
            this.ModelDetail[3]["DPD_Above_1000"];
          this.dataCSource = new MatTableDataSource(this.ModelDetail);

          // this.ModelDetail1 = this.rowSpanComputer.compute(this.ModelDetail, this.modelcolumnNames);
          $(`#CollectionTarget`).modal("show");
          $(`#CollectionTarget`).css("z-index", "1050");
        }
        this.showSpinner = false;
      });
  }

  onCloseCollectionTarget() {
    $(`#CollectionTarget`).modal("hide");
  }
  ChangeData(row, DPD, Type) {
    if (
      DPD >
      this.ModelDetail.filter(
        (r) => r.Type === "Opening" && r.SubType == row.SubType
      )[0][Type]
    ) {
      row[Type] = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "Collection " +
          row.SubType +
          " not greater than Opening " +
          row.SubType,
        ...this.configSuccess,
      });
    } else {
      this.ModelDetail[4][Type] =
        this.ModelDetail[0][Type] - this.ModelDetail[2][Type];
      this.ModelDetail[5][Type] =
        this.ModelDetail[1][Type] - this.ModelDetail[3][Type];
    }
    row.Total = parseInt(
      row.DPD_001_030 +
        row.DPD_031_060 +
        row.DPD_061_090 +
        row.DPD_091_120 +
        row.DPD_121_150 +
        row.DPD_151_180 +
        row.DPD_181_360 +
        row.DPD_361_720 +
        row.DPD_721_1000 +
        row.DPD_Above_1000
    );
  }
  OnSave() {
    this.showSpinner = true;
    $("#CollectionTarget").modal("hide");
    let _finalData = { Collection_Target: this.ModelDetail };

    console.log("jsondata", JSON.stringify(_finalData));

    this._ReportsService
      .Save_Collection_Target({
        JSON: JSON.stringify(_finalData),
        TargetId: 0,
        Revise_Remarks: "",
        LoginUserId: this.currentUser.userId,
        Type: "Create",
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        $("#CollectionTarget").modal("hide");
        this.Get_Collection_Target_VS_Achievement();
      });
  }

  OnRevised() {
    $("#reviseTargetmodal").modal("show");
    $("#reviseTargetmodal").css("z-index", "1050");
  }
  saveReviseTarget() {
    this.showSpinner = true;
    $("#CollectionTarget").modal("hide");
    let _finalData = { Collection_Target: this.ModelDetail };

    console.log("jsondata", JSON.stringify(_finalData));

    this._ReportsService
      .Save_Collection_Target({
        JSON: JSON.stringify(_finalData),
        TargetId: this.targetId,
        Revise_Remarks: this.reviseRemarks,
        LoginUserId: this.currentUser.userId,
        Type: "Revised",
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        $("#CollectionTarget").modal("hide");
        this.OnCloseReviseModal();
        this.Get_Collection_Target_VS_Achievement();
      });
  }
  OnCloseReviseModal() {
    $("#reviseTargetmodal").modal("hide");
  }





  exportexcelNew(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("collectionTarget") as HTMLTableElement;
    const worksheetName = 'COLLECTION TARGET ACHIEVEMENT'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

}
