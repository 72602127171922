import { Component, OnInit,ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { PartnerService } from '../services/partner.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-partner-auto-closer',
  templateUrl: './partner-auto-closer.component.html',
  styleUrls: ['./partner-auto-closer.component.scss']
})
export class PartnerAutoCloserComponent implements OnInit {
  apiDateString = "05/10/2022 00:00:00";


  formattedDate: string;


  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;



  constructor(
    private dataSharingService: DataSharingService,
    private _Partnerservice: PartnerService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private datePipe: DatePipe
    
  ) {
    const parsedDate = new Date(this.apiDateString);
    this.formattedDate = this.datePipe.transform(parsedDate, 'longDate');
   }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Auto Closer");
    this.displayedColumns = ["PLoan_Id", "PLoan_Date", "PLoanAcNo", "LMS_LoanAcNo","LoanAmount","PLoanCreatedOn",  "Action"];
    this.GetPartnerAutoCloser()
  }



 

  GetPartnerAutoCloser() {
    this.showSpinner = true;
    this._Partnerservice.Partner_Get_Auto_Closer_List({ }).subscribe((res: any) => {
      console.log("result",res);
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(row){
    this.router.navigate([`partner-dashboard/pre-closer/${this._EncrDecrService.encrypt(encodeURIComponent(row.PLoan_Id))}/${this._EncrDecrService.encrypt(encodeURIComponent(0))}`]);
  }



}
