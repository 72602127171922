import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-deviation-info',
  templateUrl: './deviation-info.component.html',
  styleUrls: ['./deviation-info.component.scss']
})
export class DeviationInfoComponent implements OnInit {
  @Input() data;
  TatReportsList: any;
  showSpinner: boolean;
  constructor() { }

  ngOnInit(): void {
   
  }

}
