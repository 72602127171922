<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <select name="status" id="status" class="form-control input-text-css" (change)="DepositFDR_Pending_Authorization()" [(ngModel)]="status">
        <option value="Pending">Pending</option>
        <option value="Authorized">Authorized</option>
        <option value="Reverted">Reverted</option>
      </select>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-3 text-right">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search ....">
      </mat-form-field>
    </div>
  </div>

  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-12"><hr /></div>
  </div>

  <div class="row m-0 col-md-12 p-0" *ngIf="dataSource">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter"
          style="height: 220px; max-width: 100%; overflow: auto;">
          <ng-container matColumnDef="Deposit_Id">
            <mat-header-cell mat-sort-header class="grid-header" style="max-width:50px" *matHeaderCellDef>#</mat-header-cell>
            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Deposit_Type">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Deposit Type</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Deposit_Type}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Deposit_No">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>FDR No</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Deposit_No}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_of_FDRD">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Date Of FDR</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Date_of_FDRD}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> CustomerName </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CustomerName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Deposit_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Deposit_Amount}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Interest Amount </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Interest_Amount}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Maturity_Amount">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Maturity Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Maturity_Amount}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Date_of_Maturity">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Date Of Maturity</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Date_of_Maturity}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Auto_Renewal">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Auto Renewal</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Auto_Renewal == true ? "Yes" : "No"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Created_By">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Created By</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Created_By}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Created_Date">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Created Date</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Created_Date | date:'dd/MM/yyyy' }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Modify_By">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>{{status}} By</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Modify_By}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Modify_On">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>{{status}} On</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Modify_On | date:'dd/MM/yyyy' }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell mat-sort-header class="grid-header" style="max-width:60px" *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width:60px">
              <i class="fa fa-arrow-circle-right btn_detail" (click)="goToDetail(row)" aria-label="true"></i>
              <i class="fa fa-print btn_detail ml-2" *ngIf="status == 'Authorized'" aria-label="true" 
                (click)="PrintDeposit(row, 'content', 'Deposit Certificate')" title="Print this certificate"></i>
            </mat-cell>
          </ng-container>
          <mat-header-row class="sticky-footer" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button class="btn font-size-12 button-btn" (click)="exportTable(exporter)">
              <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- style="display: none" -->
<div class="row" style="display: none">
  <div class="col-md-10 offset-1" id="content">
    <!-- Customer Copy -->
    <div style="border: 3px solid #098e6b; border-radius: 5px; height: auto; margin-top: 20px;">
      <div class="row">
        <div class="col-md-12 text-center">
          <div style="color: #FFF; background-color: #4dc3a3; border-color: #17a57f; padding: 0">
            <h5>{{currentUser.CompanyName}}</h5>
            <span>{{currentUser.CompanyAddress}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <B class="fs-14">जमा-पुष्टि / CONFIRMATION OF DEPOSIT</B>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12 fs-12">
          शाखा / Branch : <span class="text-primary">{{FDRDetail ? FDRDetail.Branch_Name : ''}}</span>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12"><hr style="margin: 0.1rem 0 0.1rem 0 ;" /></div>
      </div>
      <div class="row m-0">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td class="text-left">शाखा क्रमांक / Branch No.</td>
                <td>ग्राहक आईडी सं. / Customer I.D. No.</td>
                <td>खाता सं. / A/C No.</td>
                <td>श्रेणी / Category</td>
                <td>परिचालन का तरीका / Mode of Operation</td>
              </tr>
              <tr class="text-center">
                <td class="text-primary">{{FDRDetail ? FDRDetail.Branch_Code : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.CustomerId : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Deposit_No : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Is_seniorcitizen == 1 ? "Senior Citizen" :  "Standard" : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Deposit_ModeOfOperation : ''}}</td>
              </tr>
            </table>
            <table class="table table-borderless bg-white">
              <tr>
                <td>श्री / श्रीमती / कुमारी / Received From Mr./Mrs./Ms. &nbsp; <span class="text-primary">{{FDRDetail ? FDRDetail.CustomerName : ''}}</span></td>
                <td rowspan="3" class="text-center" style="width :220px">
                  केवल अवयस्क हेतु / For Minor Only <br />
                  जन्मतिथि / Date of Birth <br />
                  <p *ngIf="Age < 18" class="text-primary">{{FDRDetail ? (FDRDetail.Customer_DOB | date: 'dd/MM/YYY') : ''}}</p> 
                  वयस्कता की तिथि / Date of Majority <br />
                  <p *ngIf="Age < 18" class="text-primary">{{MajorityDate ? (MajorityDate | date: 'dd/MM/YYY') : ''}}</p>
                </td>
              </tr>
              <tr>
                <td class="mt-1">निवासी / Resident of &nbsp; <span class="text-primary">{{FDRDetail ? FDRDetail.Present_Address : ''}}</span></td>
              </tr>
            </table>
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td>
                  रुपये / Rupees <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Amount : ''}} ₹</span>
                </td>
                <td>
                  For a Period of <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Tenure : ''}} Month</span>
                </td>
                <td colspan="2">
                  की अवधि के लिए / At The Rate of <br />
                  <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Interest_Rate : ''}} % </span> <br />
                  प्रति वर्ष की दर से  / Per Annum
                </td>
                <td rowspan="2">
                  विकल्प / Options <br />देय ब्याज अंतराल / Periodicity of Interest Payble
                </td>
              </tr>
              <tr class="text-center">
                <td> <br />जारी करने की तिथि / Date of Issue <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Date_of_FDRD : ''}}</span></td>
                <td> <br />प्रभावी तिथि / Effective Date <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Received_Date : ''}}</span></td>
                <td> <br />परिपक्वता की तिथि / Date of Maturity <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Date_of_Maturity : ''}}</span></td>
                <td> <br />परिपक्वता मूल्य / Maturity Values <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Maturity_Amount : ''}} ₹</span></td>
              </tr>
              <tr class="text-center">
                <td colspan="2"> <br />योजना / Scheme <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_ProductName : ''}}</span></td>
                <td colspan="2"> <br />खाता सं. में ब्याज जमा  करना / Interest to be Credited to A/C No. <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.AccountNo : ''}}</span></td>
                <td colspan="2"> <br />नामांकन / Nomination <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Nominee_Name : ''}}</span></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row m-0 mt-2">
        <div class="col-md-12 fs-10">
          हम आपसे उपरोक्त जमा राशि स्वीकार करने की पुष्टि करते हैं। <br />
          We confirm having accepted from you the above deposit.
        </div>
      </div>
      <div class="row m-0 mt-4">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td class="text-left" style="width : 400px">
                  ***Customer Copy*** <br />
                  <span class="fs-10">Printed On {{TodayDate | date: 'dd/MM/YYYY h:mm:ss'}}</span>
                </td>
                <td>प्राधिकृत हस्ताक्षारकर्ता (सं.) <br /> Authorised Signatory (Emp. No.)</td>
                <td>प्राधिकृत हस्ताक्षारकर्ता (सं.) <br /> Authorised Signatory (Emp. No.)</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Office Copy -->
    <div style="border: 3px solid #098e6b; border-radius: 5px; height: auto; margin-top: 20px;">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="alert" style="color: #FFF; background-color: #4dc3a3; border-color: #17a57f; padding: 0">
            <h5>{{currentUser.CompanyName}}</h5>
            <span>{{currentUser.CompanyAddress}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <B class="fs-14">जमा-पुष्टि / CONFIRMATION OF DEPOSIT</B>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12 fs-12">
          शाखा / Branch : <span class="text-primary">{{FDRDetail ? FDRDetail.Branch_Name : ''}}</span>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12"><hr style="margin: 0.1rem 0 0.1rem 0 ;" /></div>
      </div>
      <div class="row m-0">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td class="text-left">शाखा क्रमांक / Branch No.</td>
                <td>ग्राहक आईडी सं. / Customer I.D. No.</td>
                <td>खाता सं. / A/C No.</td>
                <td>श्रेणी / Category</td>
                <td>परिचालन का तरीका / Mode of Operation</td>
              </tr>
              <tr class="text-center">
                <td class="text-primary">{{FDRDetail ? FDRDetail.Branch_Code : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.CustomerId : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Deposit_No : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Is_seniorcitizen == 1 ? "Senior Citizen" :  "Standard" : ''}}</td>
                <td class="text-primary">{{FDRDetail ? FDRDetail.Deposit_ModeOfOperation : ''}}</td>
              </tr>
              <tr><td colspan="5">&nbsp;</td></tr>
            </table>
            <table class="table table-borderless bg-white mt-2">
              <tr>
                <td>श्री / श्रीमती / कुमारी / Received From Mr./Mrs./Ms. &nbsp; <span class="text-primary">{{FDRDetail ? FDRDetail.CustomerName : ''}}</span></td>
                <td rowspan="3" class="text-center" style="width :220px">
                  केवल अवयस्क हेतु / For Minor Only <br />
                  जन्मतिथि / Date of Birth <br />
                  <p *ngIf="Age < 18" class="text-primary">{{FDRDetail ? (FDRDetail.Customer_DOB | date: 'dd/MM/YYY') : ''}}</p> 
                  वयस्कता की तिथि / Date of Majority <br />
                  <p *ngIf="Age < 18" class="text-primary">{{MajorityDate ? (MajorityDate | date: 'dd/MM/YYY') : ''}}</p>
                </td>
              </tr>
              <tr>
                <td class="mt-1">निवासी / Resident of &nbsp; {{FDRDetail ? FDRDetail.Present_Address : ''}}</td>
              </tr>
            </table>
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td>
                  रुपये / Rupees <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Amount : ''}} ₹</span>
                </td>
                <td>
                  For a Period of <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Tenure : ''}} Month</span>
                </td>
                <td colspan="2">
                  की अवधि के लिए / At The Rate of <br />
                  <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_Interest_Rate : ''}} %</span>  <br />
                  प्रति वर्ष की दर से  / Per Annum
                </td>
                <td rowspan="2">
                  विकल्प / Options <br />देय ब्याज अंतराल / Periodicity of Interest Payble
                </td>
              </tr>
              <tr class="text-center">
                <td> <br />जारी करने की तिथि / Date of Issue <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Date_of_FDRD : ''}}</span></td>
                <td> <br />प्रभावी तिथि / Effective Date <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Received_Date : ''}}</span></td>
                <td> <br />परिपक्वता की तिथि / Date of Maturity <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Date_of_Maturity : ''}}</span></td>
                <td> <br />परिपक्वता मूल्य / Maturity Values <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Maturity_Amount : ''}} ₹</span></td>
              </tr>
              <tr class="text-center">
                <td colspan="2"> <br />योजना / Scheme <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Deposit_ProductName : ''}}</span></td>
                <td colspan="2"> <br />खाता सं. में ब्याज जमा  करना / Interest to be Credited to A/C No. <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.AccountNo : ''}}</span></td>
                <td colspan="2"> <br />नामांकन / Nomination <br /> <span class="text-primary">{{FDRDetail ? FDRDetail.Nominee_Name : ''}}</span></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row m-0 mt-2">
        <div class="col-md-12 fs-10">
          हम आपसे उपरोक्त जमा राशि स्वीकार करने की पुष्टि करते हैं। <br />
          We confirm having accepted from you the above deposit.
        </div>
      </div>
      <div class="row m-0 mt-4">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless bg-white">
              <tr class="text-center">
                <td class="text-left" style="width : 400px">
                  ***Office Copy*** <br />
                  <span class="fs-10">Printed On {{TodayDate | date: 'dd/MM/YYYY h:mm:ss'}}</span>
                </td>
                <td>प्राधिकृत हस्ताक्षारकर्ता (सं.) <br /> Authorised Signatory (Emp. No.)</td>
                <td>प्राधिकृत हस्ताक्षारकर्ता (सं.) <br /> Authorised Signatory (Emp. No.)</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
