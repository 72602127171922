import { Component, Input, OnInit } from "@angular/core";
import { constantUrl } from "../../../Shared/constantUrl";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
@Component({
  selector: "app-customer-kyc-subcard-info",
  templateUrl: "./customer-kyc-subcard-info.component.html",
  styleUrls: ["./customer-kyc-subcard-info.component.scss"],
})
export class CustomerKycSubcardInfoComponent implements OnInit {
  @Input() customerkycData: any;

  index: any;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  APIResponse: any = {
    DoucumentNumber: "",
    Full_name: "",
    IsVerified: "",
    category: "",
  };
  Verify_PAN_No: any;
  Directors: any;
  Charges: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  constructor(private _MasterService: MasterService) {}

  ngOnInit(): void {
    console.log("kyc data id ", this.customerkycData[0].KYC_CustomerId);
    console.log("kyc data ", this.customerkycData);
  }

  ViewKYC_IsVerified(KYC,index) {
    this.index=index;
    this._MasterService
      .Get_Alredy_Verified_KYC({
        DocumentId: KYC.KYC_DocId,
        KYC_DocNumber: KYC.KYC_DocNumber,
      })
      .subscribe((res: any) => {
        console.log("Result of api calling ", res);
        //let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
        if (res.length > 0) {
          var Masking = KYC.KYC_DocNumber;
          KYC.LastVerfiedDate = res[0].LastVerfiedDate;
          KYC.KYC_IsVerified = 1;
          Masking = Masking.toUpperCase().slice(
            3,
            KYC.KYC_DocNumber.length - 3
          );

          var n = Masking.length;
          var ch = Masking.split("");

          for (var i = 0; i < n; i++) {
            ch[i] = "X";
          }
          if (KYC.KYC_DocId == 1) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 6) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              country: JSON.parse(res[0].APIResponse).data.address.country,
              dist: JSON.parse(res[0].APIResponse).data.address.dist,
              state: JSON.parse(res[0].APIResponse).data.address.state,
              po: JSON.parse(res[0].APIResponse).data.address.po,
              loc: JSON.parse(res[0].APIResponse).data.address.loc,
              vtc: JSON.parse(res[0].APIResponse).data.address.vtc,
              subdist: JSON.parse(res[0].APIResponse).data.address.subdist,
              street: JSON.parse(res[0].APIResponse).data.address.street,
              house: JSON.parse(res[0].APIResponse).data.address.house,
              landmark: JSON.parse(res[0].APIResponse).data.address.landmark,
              Pin: JSON.parse(res[0].APIResponse).data.zip,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.care_of,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              zip: JSON.parse(res[0].APIResponse).data.zip,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
            if (res.length > 1) {
              this.Verify_PAN_No = JSON.parse(
                res[1].APIResponse
              ).data.pan_number;
            }
          } else if (KYC.KYC_DocId == 29) {
            Masking = Masking.toUpperCase().slice(
              3,
              KYC.KYC_DocNumber.length - 3
            );

            var n = Masking.length;
            var ch = Masking.split("");

            for (var i = 0; i < n; i++) {
              ch[i] = "X";
            }
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              business_name: JSON.parse(res[0].APIResponse).data.business_name,
              gst_status: JSON.parse(res[0].APIResponse).data.gstin_status,
              nature_bus_activities: JSON.parse(res[0].APIResponse).data
                .nature_bus_activities,
              Gst_Registration_Date: JSON.parse(res[0].APIResponse).data
                .date_of_registration,
              pan_number: JSON.parse(res[0].APIResponse).data.pan_number,
              SigningAutority: JSON.parse(res[0].APIResponse).data.legal_name,
              FirmType: JSON.parse(res[0].APIResponse).data
                .constitution_of_business,
              address: JSON.parse(res[0].APIResponse).data.address,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 28) {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              company_name: JSON.parse(res[0].APIResponse).data.company_name,
              registration_number: JSON.parse(res[0].APIResponse).data.details
                .company_info.registration_number,
              company_sub_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_sub_category,
              class_of_company: JSON.parse(res[0].APIResponse).data.details
                .company_info.class_of_company,
              company_category: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_category,
              address: JSON.parse(res[0].APIResponse).data.details.company_info
                .registered_address,

              authorized_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.authorized_capital,
              paid_up_capital: JSON.parse(res[0].APIResponse).data.details
                .company_info.paid_up_capital,
              date_of_incorporation: JSON.parse(res[0].APIResponse).data.details
                .company_info.date_of_incorporation,
              company_status: JSON.parse(res[0].APIResponse).data.details
                .company_info.company_status,

              directors: JSON.parse(res[0].APIResponse).data.details.directors,
              charges: JSON.parse(res[0].APIResponse).data.details.charges,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,

              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
            };
            this.Directors = new MatTableDataSource(
              JSON.parse(res[0].APIResponse).data.details.directors
            );
            this.Charges = new MatTableDataSource(
              JSON.parse(res[0].APIResponse).data.details.charges
            );
          } else if (KYC.KYC_DocId == 5 || KYC.DocId == 5) {
            this.APIResponse = {
              KYC_DocId: 5,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              doi: JSON.parse(res[0].APIResponse).data.doi,
              doe: JSON.parse(res[0].APIResponse).data.doe,
              address: JSON.parse(res[0].APIResponse).data.permanent_address,
              vehicle_classes: JSON.parse(res[0].APIResponse).data
                .vehicle_classes,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              ola_name: JSON.parse(res[0].APIResponse).data.ola_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else if (KYC.KYC_DocId == 2 || KYC.DocId == 2) {
            this.APIResponse = {
              KYC_DocId: 2,
              DoucumentNumber:
                KYC.KYC_DocNumber.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.KYC_DocNumber.toUpperCase().substr(
                  KYC.KYC_DocNumber.length - 4
                ),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              address: JSON.parse(res[0].APIResponse).data.address,
              mobile: JSON.parse(res[0].APIResponse).data.mobile,
              state: JSON.parse(res[0].APIResponse).data.state,
              operator_code: JSON.parse(res[0].APIResponse).data.operator_code,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          } else {
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber:
                KYC.Doc_Number.toUpperCase().substr(0, 0) +
                ch.join("") +
                KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),
              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              area: JSON.parse(res[0].APIResponse).data.area,
              dist: JSON.parse(res[0].APIResponse).data.district,
              state: JSON.parse(res[0].APIResponse).data.state,
              age: JSON.parse(res[0].APIResponse).data.age,
              relation_name: JSON.parse(res[0].APIResponse).data.relation_name,
              relation_type: JSON.parse(res[0].APIResponse).data.relation_type,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,
            };
          }

          $(`#modalViewVerifiedData${this.index }`).modal("show");
          $(`#modalViewVerifiedData${this.index }`).css("z-index", "1090");

          console.log("console.log(this.index);",this.index);



        


          // $(`#modalViewVerifiedData${this.index}`).modal("show");
          // $(`#modalViewVerifiedData${this.index}`).css("z-index", "1090");
        }
      });
  }
}
