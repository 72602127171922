import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { MasterService } from 'src/app/Shared/app.Masters.Service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { DialogService } from 'src/app/Shared/dialog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
declare var $: any;
import { LmsService } from '../../_Lms/services/lms.service';

interface MasterPolicy {
  name?: string;
  Maximum?: number;
  Minimum?: number;
  IsDeviation?: boolean;
  ChargesAmount?: number;
  Applicable?: boolean;
}

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss']
})


export class ProductConfigurationComponent implements OnInit {
  PolicyDetail: Array<MasterPolicy> = [];
  PolicyNameList: any;
  SelectPolicyType: any;
  currentUser: any;
  ProductId: any;
  private _MasterService;
  dataSource: any;
  Edit: boolean = false;
  PolicyId: any = 0;
  showSpinner: boolean = false;
  pre_CloserCharges: any[] = [];
  Broken_Period_Interest:any='0';
  IsCloserConfig: boolean = false;
  Charge_Type: any;
  ForCloserChargesId: any = 33
  displayedColumns: any[] = ['Type', 'PolicyName', 'Minimum', 'Maximum', 'ChargesAmount', 'IsApplicable', 'IsDeviation', 'View_Detail'];
  SearchPolicyType: any = '';

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  
  SelectProductCategory: any = ''; 
  ProductName: string = "";
  ProductShortName: string = '';
  ProductCategoryDropdown: any = [];

  Legal: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  LegalDPDDays: any;


  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService
  ) { 
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));

      this.getAlreadyAddedConig(this.SearchPolicyType, true);
      if (Number(this.ProductId) > 0) {
        this.getProductDetail();
      }
      
    });
    this.getProductCategory();
  }

  GetConfigList() {
    this.getAlreadyAddedConig(this.SearchPolicyType, true);
  }

  onSavePolicy() {
    if (this.SelectPolicyType == 'CLOSER') {
      console.log("this.pre_CloserCharges", this.pre_CloserCharges);

      let _finalData = {
        PreCloser: this.pre_CloserCharges
      }
      console.log("this.Broken_Period_Interest", this.Broken_Period_Interest);
      this.MasterService.Save_Product_PreCloserCharges({ JSON: JSON.stringify(_finalData), LockInPeriod: 0, Broken_Period_Interest: Number(this.Broken_Period_Interest) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.getAlreadyAddedConig(this.SearchPolicyType, true);
          this.OnCloseProductConfigModal();
        }
      });
    }
    else {
      console.log("this.PolicyDetail", this.PolicyDetail);
      let _finalData = {
        Config: this.PolicyDetail,
        Type: this.SelectPolicyType,
        LoginUserId: this.currentUser.userId,
        ProductId: this.ProductId,
        Id: this.PolicyId
      }
      this.MasterService.Save_Product_Config({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {

          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.getAlreadyAddedConig(this.SearchPolicyType, true);
          this.OnCloseProductConfigModal();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
    }
  }
  
  onSaveLegal() {
    this.MasterService.Update_Legal_Config({ 
      ProductId: this.ProductId, DPDDays: this.Legal.DPDDays, OverdueAmount: this.Legal.OverdueAmount 
    }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.getAlreadyAddedConig(this.SearchPolicyType, true);
        this.OnCloseProductConfigModal();
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
}

  getAlreadyAddedConig(PolicyType, val) {
    this.showSpinner = true;
    this.MasterService.Get_Product_Config({ ProductId: this.ProductId, Type: PolicyType, Id: 0 }).subscribe((res: any) => {
      if (res.length > 0) {
        this.showSpinner = false;
        if (val == true){
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
          
        if (this.PolicyNameList != undefined && this.PolicyNameList.length > 0) {
          res.forEach(item => {
            const i = this.PolicyNameList.findIndex(skill => skill.Id == item.Name);
            if (i > -1) {
              this.PolicyNameList.splice(i, 1)
            }
          })
        }
      }
      else {
        this.showSpinner = false;
        if (val == true)
          this.dataSource = new MatTableDataSource();
      }
    });
  }
  OnOpenProductConfigModal() {
    this.Edit = false;
    this.SelectPolicyType = '';
    this.check_PreCloserCharges_Type();
    $('#AddProductConfigModal').modal('show');
    $("#AddProductConfigModal").css("z-index", "1050");

  }
  OnCloseProductConfigModal() {
    this.Edit = false;
    this.PolicyId = 0;
    this.PolicyDetail = [];
    $('#AddProductConfigModal').modal('hide');
  }
  ChangePolicyType() {
    if (this.SelectPolicyType != '' && this.SelectPolicyType != null) {
      if (this.SelectPolicyType == 'CLOSER') {
        this.pre_CloserCharges = [];
        this.pre_CloserCharges.push(
          {
            Name: "Lock-IN Period",
            From_Values: 0,
            To_Values: "",
            Charge_Values: "0",
            chargeApplyOn: "",
            ProductId: this.ProductId,
            ChargeHeadId: this.ForCloserChargesId,
            ChargeType:'',
            CreateBy: this.currentUser.userId
          });

        this.MasterService.Get_Product_PreCloserCharges({ ProductId: this.ProductId }).subscribe((res: any) => {
          if (res.length > 0) {
            this.pre_CloserCharges[0].To_Values = res[0].LockInPeriod;
          //  this.pre_CloserCharges.push(res);
          
            this.Broken_Period_Interest = res[0].Broken_Period_Interest;
            for (let i = 0; i < res.length; i++) {
              //this.pre_CloserCharges[i].Name = "Slab_" + Number(i);
              this.pre_CloserCharges.push(
                {
                  Name: "Slab_" + Number(i+1),
                  From_Values: res[i].From_Values,
                  To_Values: res[i].To_Values,
                  Charge_Values: res[i].Charge_Values,
                  chargeApplyOn: res[i].chargeApplyOn,
                  ProductId: this.ProductId,
                  ChargeHeadId: this.ForCloserChargesId,
                  ChargeType: res[i].ChargeType,
                  CreateBy: this.currentUser.userId
                });
            }
          }
          // this.LockInPeriodChange();
        });

          } else {
          this.GetPolicyList();
          this.PolicyDetail = [];
          var PolicyObj = { name: '', ChargesAmount: 0, Maximum: 0, Minimum: 0, Applicable: false, IsDeviation: false };
          this.PolicyDetail.push(PolicyObj);
        }
    }
    else {
      this.PolicyDetail = [];
    }

  }
  initializepre_CloserDetail(i) {


    this.pre_CloserCharges.push(
      {
        Name: "Slab_" + Number(i +1),
        From_Values: Number(this.pre_CloserCharges[i].To_Values),
        To_Values: "",
        Charge_Values: "",
        chargeApplyOn: "POS",
        ProductId: this.ProductId,
        ChargeHeadId: this.ForCloserChargesId,
        ChargeType: this.Charge_Type,
        CreateBy: this.currentUser.userId
      });

  }
  addMoreSlab(index: any) {
    this.initializepre_CloserDetail(index);
  }
  removeSlab(index: any) {
    this.pre_CloserCharges.splice(index, 1);

    for (let i = 0; i < this.pre_CloserCharges.length; i++) {
      if (i != 0) {
        this.pre_CloserCharges[i].From_Values = Number(this.pre_CloserCharges[i - 1].To_Values);
        this.pre_CloserCharges[i].Name = "Slab_" + Number(i + 1);

      }
    }

  }
  ToValueChange(i) {
    if (this.pre_CloserCharges[i].To_Values <= this.pre_CloserCharges[i].From_Values && this.pre_CloserCharges[i].Name !='Lock-IN Period') {
      this.pre_CloserCharges[i].To_Values = '';
      if ((i + 1) < this.pre_CloserCharges.length)
        this.pre_CloserCharges[i + 1].From_Values = '';
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'To not less than From', ...this.configSuccess });

    } else {
      if ((i + 1) < this.pre_CloserCharges.length) {
        this.pre_CloserCharges[i + 1].From_Values = this.pre_CloserCharges[i].To_Values
        for (let i = 0; i < this.pre_CloserCharges.length; i++) {
          if (this.pre_CloserCharges[i].To_Values <= this.pre_CloserCharges[i].From_Values && this.pre_CloserCharges[i].Name != 'Lock-IN Period') {
            this.pre_CloserCharges[i].To_Values ='';
            this.pre_CloserCharges[i].From_Values = this.pre_CloserCharges[i - 1].To_Values
          }

        }
      }
    }
  }
  addMoreData() {
    var PolicyObj = { name: '', ChargesAmount: 0, Maximum: 0, Minimum: 0, Applicable: false, IsDeviation: false };
    this.PolicyDetail.push(PolicyObj);
  }
  removeData(index: any) {
    this.PolicyDetail.splice(index, 1);
  }
  GetPolicyList() {
    if (this.SelectPolicyType == 'FEES RELATED') {
      this.MasterService.Get_Product_FeesCharges({ ProductId: this.ProductId }).subscribe((res: any) => {
        this.PolicyNameList = res;
        this.getAlreadyAddedConig(this.SelectPolicyType, false);
      });
    }
    else {
      this.lmsService.LMS_Commaon_Master({ Commands: 'Select', Type: this.SelectPolicyType }).subscribe((res: any) => {
        this.PolicyNameList = res;
        this.getAlreadyAddedConig(this.SelectPolicyType, false);
      });
    }
  }
  onChangePolicyName(data, dindex) {
    let _find = this.PolicyDetail.find((obj: any, index: any) => {

      return index != dindex && obj.name == data.name;

    });

    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This Policy already selected.", ...this.configSuccess });
      this.PolicyDetail[dindex].name = null;
      this.PolicyDetail[dindex].ChargesAmount = 0;
      this.PolicyDetail[dindex].Maximum = 0;
      this.PolicyDetail[dindex].Minimum = 0;
      this.PolicyDetail[dindex].Applicable = false;
      this.PolicyDetail[dindex].IsDeviation = false;
      return;
    }
    else {
      if (this.SelectPolicyType == 'FEES RELATED') {
        this.PolicyDetail[dindex].ChargesAmount = this.PolicyNameList.filter(item => item.Id == this.PolicyDetail[dindex].name)[0].Charge_Values;
      }
    }
  }
  onChangeMinMax(data, dindex) {
    if (this.PolicyDetail[dindex].Maximum == 0) {
      this.PolicyDetail[dindex].Maximum = this.PolicyDetail[dindex].Minimum + 1;
    }
    if (this.PolicyDetail[dindex].Maximum < this.PolicyDetail[dindex].Minimum) {
      this.PolicyDetail[dindex].Maximum = 0;
      this.PolicyDetail[dindex].Minimum = 0;
    }
  }
  onChangeApplicable(data, dindex) {
    if (this.PolicyDetail[dindex].Applicable == false) {
      this.PolicyDetail[dindex].IsDeviation = false;
    }
  }
  ViewData(row) {
    this.SelectPolicyType = row.Type;
    this.Edit = true;
    this.PolicyId = row.Id;
    if (this.SelectPolicyType == 'FEES RELATED') {
      this.MasterService.Get_Product_FeesCharges({ ProductId: this.ProductId }).subscribe((res: any) => {
        this.PolicyNameList = res;
        this.PolicyDetail = [];
        var PolicyObj = { name: row.Name, ChargesAmount: row.ChargesAmount, Maximum: row.Maximum, Minimum: row.Minimum, Applicable: row.IsApplicable, IsDeviation: row.IsDeviation };
        this.PolicyDetail.push(PolicyObj);
      });
    }
    else {
      this.lmsService.LMS_Commaon_Master({ Commands: 'Select', Type: this.SelectPolicyType }).subscribe((res: any) => {
        this.PolicyNameList = res;
        this.PolicyDetail = [];
        var PolicyObj = { name: row.Name, ChargesAmount: row.ChargesAmount, Maximum: row.Maximum, Minimum: row.Minimum, Applicable: row.IsApplicable, IsDeviation: row.IsDeviation };
        this.PolicyDetail.push(PolicyObj);
      });
    }

    $('#AddProductConfigModal').modal('show');
    $("#AddProductConfigModal").css("z-index", "1050");


  }
  DeleteData(row) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ${row.PolicyName} Policy.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          console.log("this.PolicyDetail", this.PolicyDetail);
          let _finalData = {
            Config: null,
            Type: 'Deleted',
            LoginUserId: this.currentUser.userId,
            ProductId: this.ProductId,
            Id: row.Id
          }
          this.MasterService.Save_Product_Config({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
            if (res[0].CODE >= 0) {

              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.getAlreadyAddedConig(this.SearchPolicyType, true);
              this.OnCloseProductConfigModal();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          });
        }
      });
  }

  check_PreCloserCharges_Type() {
    this.MasterService.Get_ChargesHeadsByProduct_ForDropdow({ ProductId: this.ProductId }).subscribe((res: any) => {
      if (res.length > 0) {
        let _find = res.find((ele: any) => { return ele.Id == this.ForCloserChargesId })
        if (_find) {
          if (_find.Charge_Type == '' || _find.Charge_Type == undefined) {
            this.IsCloserConfig = false;
            this.Charge_Type = _find.Charge_Type;
            //this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Select Charges Type of Foreclosure charges', ...this.configSuccess });
            //return '';
          }
          else {
            this.IsCloserConfig = true;
            this.Charge_Type = _find.Charge_Type;
          }
         
        }
      }
    });
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }
  getProductDetail() {
    this._MasterService.GetProductListById({ ProductId: this.ProductId }).subscribe((result) => {
      if (result.length > 0) {
        this.SelectProductCategory = result[0].ProductCatId;
        this.ProductName = result[0].Product;
        this.ProductShortName = result[0].ShortName;

        this.LegalDPDDays = result[0].DPD_Days_For_Legal;
        this.Legal.DPDDays = result[0].DPD_Days_For_Legal;
        this.Legal.OverdueAmount = result[0].OverdueAmount_For_Legal;
      }
    });
  }
}
