import { Component, OnInit, ViewChild } from "@angular/core";
import { LosService } from "src/app/_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatSelectChange } from "@angular/material/select";
import { DatePipe } from "@angular/common";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DialogService } from "src/app/Shared/dialog.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Component({
  selector: "app-pdc-repayment-process",
  templateUrl: "./pdc-repayment-process.component.html",
  styleUrls: ["./pdc-repayment-process.component.scss"],
})
export class PdcRepaymentProcessComponent implements OnInit {
  ChequeDetail: any = "";
  ChequeList: any = [];
  SelectBranch: any = "";
  BankInCompany: any = "";
  Bounce_ReasonList: any;
  showSpinner: any = false;
  SearchStatus: any = "Pending";
  DueDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  DueDateTo: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  IsRepresentAllow: any = true;
  BankList: any;
  BranchesList: any;
  ProductList: any;
  SelectProduct: any = "";
  CustomerBank: any;
  filterValue: any;
  PostDateFrom: any;
  PostDateTo: any;
  ClearDateFrom: any;
  ClearDateTo: any;
  HoldDateFrom: any;
  HoldDateTo: any;
  BounceDateFrom: any;
  BounceDateTo: any;
  ProcessList: any;
  JsonData: any;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];
  Id: any;
  HoldDate: string;
  HoldReason: string;
  Type: any;
  PostDate: any;
  Row: any;
  PresentBankInCompany: any;
  BounceChargeID: any;
  BounceCharge: any;
  LoanChargesRecoverableId = 35;
  BouncingChargesId = 31;
  BounceDate: any;
  BounceReason: any = "";
  ClearDate: string;
  minDate: Date;
  minClearDate: Date;
  currentUser: any;
  AMOR_Type: any;
  ChequeinTransitId: any = 32;
  dialogreturn: any;
  CurrentDate: Date;
  selectedId: any;
  RepresentId: any;
  RepresentPostDate: Date;
  minRepresentPostDate: Date;
  IsnextEMIData: boolean = false;
  nextEMIData: any = { Period: "", Emi_Amount: "", DueDate: "" };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectedData: any;
  IsSameEMI: any = "1";
  constructor(
    private datepipe: DatePipe,
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private losService: LosService,
    private lmsService: LmsService,
    private _MasterService: MasterService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("PDC Repayment Process");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.getProductDropdown();
    this.getBankDropdown();
    this.lmsService
      .LMS_Commaon_Master({ Commands: "Select", Type: "CHQ_Bounce_Reason" })
      .subscribe((res: any) => {
        console.log(res);
        this.Bounce_ReasonList = res;
      });
    this.displayedColumnsLoan = [
      "Id",
      "CaseNo",
      "CustomerName",
      "EMINo",
      "DueDate",
      "EMI_Amount",
      "PostDate",
      "Customer_PhoneNo",
      "Customer_Address",
    ];
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }

  getBranchesList() {
    let user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService.GetBranches({ Emp_Id: user.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  getBankDropdown() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: "Bank", LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.BankList = JSON.parse(JSON.stringify(res));
      });
  }

  onSearchProcess() {
    this.dataSourceLoan = null;
    this.showSpinner = true;
    var bankCompany = this.BankInCompany;
    if (bankCompany != "") {
      bankCompany = this.BankInCompany.split("_")[0];
    }
    let searchData;
    searchData = {
      DueDateFrom: this.DueDateFrom,
      DueDateTo: this.DueDateTo,
      BankInCompany: bankCompany,
      Branch: this.SelectBranch,
      Product: this.SelectProduct,
      status: this.SearchStatus,
      CustomerBank: this.CustomerBank,
      PostDateFrom: this.PostDateFrom,
      PostDateTo: this.PostDateTo,
      ClearDateFrom: this.ClearDateFrom,
      ClearDateTo: this.ClearDateTo,
      HoldDateFrom: this.HoldDateFrom,
      HoldDateTo: this.HoldDateTo,
      BounceDateFrom: this.BounceDateFrom,
      BounceDateTo: this.BounceDateTo,
    };
    console.log("searchData : ", searchData);
    this.lmsService
      .GetPDCProcess({ JSON: JSON.stringify(searchData) })
      .subscribe((res: any) => {
        console.log("RESHH : ", res);

        // for (var i = 0; i < res.length; i++) {
        //   if (res.length == 10) {
        //     break;
        //   }
        // }
        if (res.length > 0) {
          this.ProcessList = JSON.parse(JSON.stringify(res));
          this.JsonData = res;
          var i = 0;
          this.ProcessList.forEach((x) => {
            x.FPostDate = x.PostDate;
            x.PostDate =
              x.PostDate == null || x.PostDate == ""
                ? ""
                : new Date(x.PostDate);
            x.Status = x.Status == null ? "" : x.Status;
            x.ClearingType = "";
            x.Clearinghold = false;
            x.Posthold = false;
            if (x.IsStop == 1) {
              x.Posthold = true;
              x.Clearinghold = true;
            }
            if (x.PostDate != null && x.PostDate != "") {
              x.Posthold = true;
            }
            if (
              x.PostDate == null ||
              x.PostDate == "" ||
              (x.ClearDate != null && x.ClearDate != "") ||
              (x.BounceDate != "" && x.BounceDate != null)
            ) {
              x.Clearinghold = true;
            }
            if (
              x.BounceCharge != "" &&
              x.BounceCharge != null &&
              x.BounceCharge != 0
            ) {
              x.BCharge = x.Charge_Values;
              x.ClearingType = "Bounce";
            } else {
              x.BCharge = "";
            }
            if (x.ClearDate != "" && x.ClearDate != null) {
              x.ClearingType = "Clear";
            }
            if (x.Id == 0) {
              i = i + 1;
              x.Id = i;
            }
          });

          this.dataSourceLoan = new MatTableDataSource(this.ProcessList);

          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
        console.log("Sdfsdfsdfsdfsdffsdfsdf", this.dataSourceLoan.data);
      })
      .add(() => {
        this.showSpinner = false;
      });
  }

  onHold(row) {
    this.Id = row.Id;
    $("#HoldDetailModel").modal("show");
    $("#HoldDetailModel").css("z-index", "1050");
  }
  onCloseHoldDetail() {
    this.onSearchProcess();
    this.Id = 0;
    this.HoldDate = "";
    this.HoldReason = "";
    $("#HoldDetailModel").modal("hide");
  }

  onHoldOk() {
    let _data: any = {};
    _data.Hold = { HoldReason: this.HoldReason, HoldDate: this.HoldDate };
    this.lmsService
      .SAVE_NACHPROCESS({
        Int_Id: this.Id,
        StatusVal: "Hold",
        JSON: JSON.stringify(_data),
      })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.onCloseHoldDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onUnHold(row) {
    this.lmsService
      .SAVE_NACHPROCESS({ Int_Id: row.Id, StatusVal: "UnHold" })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseHoldDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  ClearingChange(event: MatSelectChange, row) {
    this.minDate = new Date(
      this.currentUser.Data_Freeze.split("T")[0] + " 00:00:00"
    );

    this.Id = row.Id;
    this.Type = event.value;
    this.PostDate = row.PostDate;
    this.Row = this.ProcessList.filter((x) => x.Id == row.Id)[0];
    this.PresentBankInCompany = this.Row.ComapnyAccountId;
    if (new Date(this.Row.PostDate) > this.minDate) {
      this.minClearDate = new Date(this.Row.PostDate);
    } else {
      this.minClearDate = this.minDate;
    }
    if (event.value == "Bounce") {
      if (row.ChargeId != null && row.ChargeId != "") {
        this.BounceChargeID = row.ChargeId;
        this.BounceCharge = row.Charge_Values;
      } else {
        this.BounceChargeID = 0;
        this.BounceCharge = 0;
      }
      $("#BounceDetailModel").modal("show");
      $("#BounceDetailModel").css("z-index", "1050");
    }
    if (event.value == "Clear") {
      $("#BounceDetailModel").modal("show");
      $("#BounceDetailModel").css("z-index", "1050");
    }
  }
  onBounce() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.Row.FPostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.lmsService
          .LMS_Get_ACC_By_Loan_ChargesHead({
            HeadId: this.BouncingChargesId,
            LoanAcNo: this.Row.LoanAcNo,
          })
          .subscribe((rres: any) => {
            let _data: any = {};
            console.log("RRes : ", rres);
            _data.Bounce = {
              BounceDate: this.BounceDate,
              BounceCharge: this.BounceChargeID,
              BounceReason: this.BounceReason,
            };

            var id = this.Id;

            this.lmsService
              .Get_AccountHead_For_VoucherEntry({
                HeadId: this.BouncingChargesId,
                VoucherSubType: "Loan",
                ProductId: this.Row.PrductId || 0,
                TaxType: "CR",
              })
              .subscribe((res: any) => {
                console.log("Res : ", res);
                let TaxSlab = [];
                TaxSlab = res.Item2;
                this.saveBounceVoucher(
                  result[0].VoucherNo,
                  rres,
                  TaxSlab,
                  _data,
                  id
                );
              });
          });
      });
  }
  onClear() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.PostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.lmsService
          .LMS_Get_ACC_By_Loan_ChargesHead({
            HeadId: 34,
            LoanAcNo: this.Row.LoanAcNo,
          })
          .subscribe((rres: any) => {
            this.saveClearVoucher(
              result[0].VoucherNo,
              rres[0].Account_Name,
              rres[0].AccountId
            );
          });
      });
  }
  onCloseBounceDetail() {
    this.onSearchProcess();
    var element1 = <HTMLInputElement>document.getElementById("type" + this.Id);
    element1.value = "";
    this.Id = "";
    this.BounceChargeID = "";
    this.BounceDate = "";
    this.BounceReason = "";
    this.Type = "";
    this.ClearDate = "";

    $("#BounceDetailModel").modal("hide");
  }
  PostDateChange(row: any) {
    this.SelectedData = row;
    this.minDate = new Date(
      this.currentUser.Data_Freeze.split("T")[0] + " 00:00:00"
    );
    if (new Date(row.PostDate) < this.minDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Data Freeze On Date ",
        ...this.configSuccess,
      });
      row.PostDate = null;
    } else if (new Date(row.PostDate) < new Date(row.DueDate)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Post Date less than Due Date!!! ",
        ...this.configSuccess,
      });
      row.PostDate = null;
    } else {
      this.PostDate = row.PostDate;
      this.Id = row.Id;
      this.AMOR_Type = row.Type;
      // this.Row = this.ProcessList.filter(x => x.Id == row.Id)[0];
      this.Row = row;
      this.getBankDropdown();
      console.log("this.Row", this.Row);
      this.lmsService
        .GePendingPDCCheque({ Loanid: row.Loan_Id })
        .subscribe((x) => {
          this.ChequeList = x;
          $("#PostDetailModel").modal("show");
          $("#PostDetailModel").css("z-index", "1050");
        });
    }
  }
  onPost() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.PostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.savePostVoucher(
          result[0].VoucherNo,
          this.PostDate,
          0,
          this.Id,
          this.SelectedData?.Period
        );
      });
  }
  savePostVoucher(VoucherNumber, PostDate, Represent, Id, Period) {
    var ComapnyAccountId = this.Row.ComapnyAccountId;
    var ComapnyAccount = this.Row.ComapnyAccount;
    // if (this.AMOR_Type == 'RPDC') {

    ComapnyAccountId = this.PresentBankInCompany;
    ComapnyAccount = this.BankList.find((obj: any) => {
      return obj.AccountId == this.PresentBankInCompany;
    }).Account_Name;

    // }
    console.log("company bank", ComapnyAccountId, ComapnyAccount);
    // return ;
    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "Bank",
      Voucher_Date: PostDate,
      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: "Cheque",
      Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration:
        "Loan No. " +
        this.Row.LoanAcNo +
        "  / Application No. " +
        this.Row.Application_No +
        " Cheque No. " +
        this.Row.ChequeNo,
      Voucher_Cheque_No: this.Row.ChequeNo,
      Voucher_Cheque_Date: this.datepipe.transform(
        new Date(this.Row.DueDate).toString(),
        "yyyy-MM-dd"
      ),
      Voucher_Bank_Name: this.Row.CustomerBankname,
      Voucher_Cheque_Clear_Date: null,
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: this.Row.Id,
      LoginUserId: this.currentUser.userId,
    };

    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: this.Row.AccountName,
      AccountId: this.Row.AccountId,
      BranchId: this.Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: null,
      CustomerId: null,
      Is_LOSAppliaction: null,
      LOS_TransactionId: null,
      ChargeHeadId: null,
      ChargeHeadCategory: ComapnyAccount,
      AccountId: ComapnyAccountId,
      BranchId: null,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };

    let _data: any = {};
    let cheqid = this.ChequeList.filter((x) => x.Chq_No == this.ChequeDetail);
    console.log("cheqid", cheqid);
    cheqid = cheqid.length > 0 ? cheqid[0]["Int_Id"] : "";
    _data.Post = {
      PostDate: PostDate,
      RPDC_ComapnyBank: parseInt(this.PresentBankInCompany),
      Represent: Represent,
      ChequeNo: this.ChequeDetail,
      CD_Id: parseInt(cheqid),
      Period: parseInt(Period),
      LoginUserId: parseInt(this.currentUser.userId),
      Loan_Id: this.SelectedData.Loan_Id,
    };
    console.log("final VOucher data", {
      JSON: JSON.stringify(_finalData),
      Int_Id: Id,
      StatusVal: "Post",
      Repayment_JSON: JSON.stringify(_data),
    });

    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: Represent == 0 ? 0 : Id,
        StatusVal: "Post",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onClosePostVoucher();
          this.onCloseRepresent();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  saveClearVoucher(VoucherNumber, AccountName, AccountId) {
    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    //console.log("voucherno", VoucherNo);
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: null,
      Voucher_Date: this.datepipe.transform(
        new Date(this.Row.FPostDate).toString(),
        "yyyy-MM-dd"
      ),

      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: null,
      Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration:
        "Loan No. " +
        this.Row.LoanAcNo +
        "  / Application No. " +
        this.Row.Application_No +
        " Cheque No. " +
        this.Row.ChequeNo,
      Voucher_Cheque_No: null,
      Voucher_Cheque_Date: null,
      Voucher_Bank_Name: null,
      Voucher_Cheque_Clear_Date: this.datepipe.transform(
        new Date(this.Row.FPostDate).toString(),
        "yyyy-MM-dd"
      ),
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: null,
      LoginUserId: this.currentUser.userId,
    };
    //this.Row.Id
    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: this.Row.AccountName,
      AccountId: this.Row.AccountId,
      BranchId: this.Row.BranchId,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: 34,
      ChargeHeadCategory: AccountName,
      AccountId: AccountId,
      BranchId: this.Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    // //let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //// let _total: any = 0;
    //// let _totalCR: any = 0;
    //// let _totalTX: any = 0;
    // this.voucherModel.Voucher_Detail.forEach((obj: any) => {
    //   _total = _total + obj.Amount;
    //   _totalCR = _totalCR + obj.Amount;
    //   _voucheretail.push(this.dataSharingService.cleanObject({
    //     VoucherId: obj.VoucherId,
    //     CaseNo: obj.CaseNo,
    //     CustomerId: obj.CustomerId,
    //     Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //     ChargeHeadId: obj.ChargeHeadId,
    //     ChargeHeadCategory: obj.ChargeHeadCategory,
    //     AccountId: obj.AccountId,
    //     BranchId: obj.BranchId,
    //     TranType: obj.TranType,
    //     IsTax: obj.IsTax,
    //     Tax_Per: obj.Tax_Per,
    //     Amount: obj.Amount
    //   }));
    //   obj.TaxSlab.forEach((tobj: any) => {
    //     _total = _total + tobj.TaxAmount;
    //     _totalTX = _totalTX + tobj.TaxAmount;
    //     _voucheretail.push(this.dataSharingService.cleanObject({
    //       VoucherId: obj.VoucherId,
    //       CaseNo: obj.CaseNo,
    //       CustomerId: "",
    //       Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //       ChargeHeadId: obj.ChargeHeadId,
    //       ChargeHeadCategory: tobj.TaxType,
    //       AccountId: tobj.TaxAccountId,
    //       BranchId: "",
    //       TranType: obj.TranType,
    //       IsTax: 1,
    //       Tax_Per: tobj.TaxRate,
    //       Amount: tobj.TaxAmount
    //     }))
    //   });
    // });

    // _voucheretail.push(this.dataSharingService.cleanObject({
    //   VoucherId: 0,
    //   CaseNo: "",
    //   CustomerId: "",
    //   Is_LOSAppliaction: "",
    //   ChargeHeadId: "",
    //   ChargeHeadCategory: _find.Account_Name,
    //   AccountId: _find.AccountId,
    //   BranchId: "",
    //   TranType: 'DR',
    //   IsTax: 0,
    //   Tax_Per: 0,
    //   Amount: _total
    // }));
    // _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    // _vouvher.Voucher_Total_Debit_Amount = _total;
    // _vouvher.Voucher_Total_Tax = _totalTX;

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };
    let _data: any = {};
    _data.Clear = { ClearDate: this.ClearDate };
    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: this.Id,
        StatusVal: "Clear",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseBounceDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.onCloseBounceDetail();
      });
    //this.lmsService.Save_Acc_VoucherMaster_By_NachProcess({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
    //  if (res[0].CODE >= 0) {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: 'Voucher No: ' + VoucherNumber + ' Successfully Saved !!!', ...this.configSuccess });
    //    let _data: any = {};
    //    _data.Clear = { ClearDate: this.ClearDate, VoucherId: res[0].CODE };
    //    this.lmsService.SAVE_NACHPROCESS({ Int_Id: this.Id, StatusVal: 'Clear', JSON: JSON.stringify(_data) }).subscribe((res) => {
    //      if (res[0].CODE == 0) {
    //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

    //      } else {
    //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //      }
    //    });

    //    this.Update_Voucher_Clear_OR_Bounce();
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //  }
    //})
  }
  saveBounceVoucher(VoucherNumber, bouncing, TaxSlab, data, Id) {
    this.lmsService
      .LMS_Get_ACC_By_Loan_ChargesHead({
        HeadId: this.LoanChargesRecoverableId,
        LoanAcNo: this.Row.LoanAcNo,
      })
      .subscribe((rres: any) => {
        var ComapnyAccountId = this.Row.ComapnyAccountId;
        var ComapnyAccount = this.Row.ComapnyAccount;
        if (this.Row.Type == "RPDC") {
          ComapnyAccountId = this.PresentBankInCompany;
          ComapnyAccount = this.BankList.find((obj: any) => {
            return obj.AccountId == this.PresentBankInCompany;
          }).Account_Name;
        }
        let _vouvher: any = {};
        let VoucherNo = VoucherNumber;
        //console.log("voucherno", VoucherNo);
        _vouvher = {
          VoucherId: 0,
          Voucher_No: VoucherNo,
          Voucher_ReceiptNo_Book: null,
          Voucher_ReceiptDate: null,
          Voucher_Type: "Journal",
          Voucher_Sub_Type: "Loan",
          Voucher_Tag: "Bank",
          Voucher_Date: this.datepipe.transform(
            new Date(this.Row.FPostDate).toString(),
            "yyyy-MM-dd"
          ),

          Voucher_Effective_Date: null,
          Voucher_Mode_of_Payment: "Cheque",
          Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
          Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
          Voucher_Total_Tax: null,
          Voucher_Narration:
            "Loan No. " +
            this.Row.LoanAcNo +
            "  / Application No. " +
            this.Row.Application_No +
            " Cheque No. " +
            this.Row.ChequeNo,
          Voucher_Cheque_No: this.Row.ChequeNo,
          Voucher_Cheque_Date: this.datepipe.transform(
            new Date(this.Row.DueDate).toString(),
            "yyyy-MM-dd"
          ),
          Voucher_Bank_Name: this.Row.CustomerBankname,
          Voucher_Cheque_Clear_Date: this.datepipe.transform(
            new Date(this.BounceDate).toString(),
            "yyyy-MM-dd"
          ),
          Voucher_E_Transaction_Date: null,
          Voucher_E_Ref_No: null,
          LoginUserId: this.currentUser.userId,
        };
        //this.Row.Id
        let _voucheretail: any[] = [];
        //Cheque in Transit
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.ChequeinTransitId,
          ChargeHeadCategory: this.Row.AccountName,
          AccountId: this.Row.AccountId,
          BranchId: this.Row.BranchId,
          TranType: "DR",
          IsTax: null,
          Tax_Per: null,
          Amount: this.Row.EMI_Amount,
          Bind_Voucher: 1,
        });
        //Comapny Acc
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: null,
          CustomerId: null,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: null,
          ChargeHeadCategory: ComapnyAccount,
          AccountId: ComapnyAccountId,
          BranchId: null,
          TranType: "CR",
          IsTax: null,
          Tax_Per: null,
          Amount: this.Row.EMI_Amount,
          Bind_Voucher: 2,
        });
        //Bouncing Charges
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.BouncingChargesId,
          ChargeHeadCategory: bouncing[0].Account_Name,
          AccountId: bouncing[0].AccountId,
          BranchId: this.Row.BranchId,
          TranType: "CR",
          IsTax: null,
          Tax_Per: null,
          Amount: bouncing[0].Amount,
          Bind_Voucher: 3,
        });

        //tax

        let _total = Number(bouncing[0].Amount);
        TaxSlab.forEach((tobj: any) => {
          var TaxAmount = (parseFloat(bouncing[0].Amount) * tobj.TaxRate) / 100;
          _total = _total + Number(TaxAmount);
          // _totalTX = _totalTX + tobj.TaxAmount;
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: this.Row.LoanAcNo,
              CustomerId: "",
              Is_LOSAppliaction: 0,
              ChargeHeadId: this.BouncingChargesId,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: TaxAmount,
              Bind_Voucher: 3,
            })
          );
        });

        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.LoanChargesRecoverableId,
          ChargeHeadCategory: rres[0].Account_Name,
          AccountId: rres[0].AccountId,
          BranchId: this.Row.BranchId,
          TranType: "DR",
          IsTax: null,
          Tax_Per: null,
          Amount: _total,
        });

        let _finalData = {
          Voucher: _vouvher,
          Voucher_Detail: _voucheretail,
        };
        //console.log("_finalData", _finalData);

        this.lmsService
          .Save_Acc_VoucherMaster_By_NachProcess({
            JSON: JSON.stringify(_finalData),
            Int_Id: Id,
            StatusVal: "Bounce",
            Repayment_JSON: JSON.stringify(data),
          })
          .subscribe((res: any) => {
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.onCloseBounceDetail();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
            this.onCloseBounceDetail();
          });
      });
  }
  Update_Voucher_Clear_OR_Bounce() {
    this.lmsService
      .Update_Voucher_Clear_OR_Bounce({
        Id: this.Row.Id,
        ClearDate: this.ClearDate,
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.onCloseBounceDetail();
        }
      });
  }
  onClosePostVoucher() {
    this.onSearchProcess();
    $("#PostDetailModel").modal("hide");
    var element1 = <HTMLInputElement>(
      document.getElementById("PostDate" + this.Id)
    );
    element1.value = "";
    this.PostDate = "";
    this.Row = {};
    this.Id = "";
    this.AMOR_Type = "";
  }

  Undo(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to last action will be reverted and related voucher will be deleted ? "
    );

    //this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete this employee?');
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == false) {
        return;
      } else {
        this.lmsService
          .SAVE_NACHPROCESS({
            Int_Id: row.Id,
            StatusVal: "Undo",
            JSON: JSON.stringify(""),
          })
          .subscribe((res) => {
            if (res[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.onClosePostVoucher();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
  bulkPost() {
    $("#bulkPostDetailModel").modal("show");
    $("#bulkPostDetailModel").css("z-index", "1050");
  }

  // SelectAll(event) {
  //   this.PostBulkProcess.forEach(x => {
  //     if (event.checked == true) {
  //       x.IsAlreadySelected = true;
  //     }
  //     if (event.checked == false) {
  //       x.IsAlreadySelected = false;
  //     }
  //   });
  //   this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);

  //   this.dataSourceBulkPost.sort = this.sort;
  //   this.dataSourceBulkPost.paginator = this.paginator;
  //   this.showSpinner = false;
  // }

  // onExport(exporter: any) {
  //   exporter.exportTable("xls", {
  //     fileName: `lms-Process-BulkClearing-${this.bulkCheckType}-list`,
  //     sheet: "Bulk Clearing",

  //   });
  //   this.IsExportbtnShow = false;
  //   this.IsImportbtnShow = true;
  // }

  DateConvert(date, status) {
    let newDate = new Date(date);
    if (this.CurrentDate > newDate && (status == "Pending" || status == ""))
      return true;
    else return false;
  }
  StatusChange() {
    this.dataSourceLoan = null;
    if (this.SearchStatus == "Pending")
      this.displayedColumnsLoan = [
        "Id",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "DueDate",
        "EMI_Amount",
        "PostDate",
        "Customer_PhoneNo",
        "Customer_Address",
      ];
    else {
      this.displayedColumnsLoan = [
        "Id",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "EMI_Amount",
        "DueDate",
        "ChqueNo",
        "PostDate",
        "ComapnyAccount",
        "Clearing",
        "ClearBounceDate",
        "BounceReason",
        "BounceCharge",
        "Undo",
        "HoldDate",
        "HoldReason",
        "Represent",
        //"Id", "CaseNo", "CustomerName", "EMINo", "CaseDate", "ChqueNo", "EMI_Amount", "DueDate", "CustomerBankname", "Type", "Status",
        //"PostDate", "ComapnyAccount", "Clearing", "ClearBounceDate", "BounceReason", "BounceCharge", "Undo", "HoldDate", "HoldReason"
      ];
    }
    if (this.SearchStatus == "Post") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
      //this.displayedColumnsLoan = ["Id","CaseNo","CustomerName","Period","Loan_Date","DueDateFormat","RPDC_ComapnyBank","Clearing","BounceDateFormate","BounceReason","BounceCharge","HoldDate","HoldReason","Undo"]
    }
    if (this.SearchStatus == "Hold") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.PostDateFrom = "";
      this.PostDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
      // this.displayedColumnsLoan = ["Id","CaseNo","CustomerName","Period","Loan_Date","DueDateFormat","RPDC_ComapnyBank","Clearing","BounceDateFormate","BounceReason","BounceCharge","HoldDate","HoldReason","Undo"]
    }
    if (this.SearchStatus == "Clear") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.PostDateFrom = "";
      this.PostDateTo = "";
      //this.displayedColumnsLoan = ["Id","CaseNo","CustomerName","Period","Loan_Date","DueDateFormat","RPDC_ComapnyBank","Clearing","BounceDateFormate","BounceReason","BounceCharge","HoldDate","HoldReason","Undo"]
    }
    if (this.SearchStatus == "Bounce") {
      this.PostDateFrom = "";
      this.PostDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
      //this.displayedColumnsLoan = ["Id","CaseNo","CustomerName","Period","Loan_Date","DueDateFormat","RPDC_ComapnyBank","Clearing","BounceDateFormate","BounceReason","BounceCharge","HoldDate","HoldReason","Undo"]
    }
  }

  // Excel export in backend code end here

  applyFilter(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }

  createFilter() {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.LoanAcNo.indexOf(searchTerms.CaseNo) !== -1 &&
        data.EMI_Amount.indexOf(searchTerms.EMI_Amount) !== -1 &&
        data.ChequeNo.indexOf(searchTerms.ChqueNo) !== -1
      );
    };
    return filterFunction;
  }
  public calculateTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["EMI_Amount"]);
      }
      return sum;
    }
    return 0;
    //return this.dataSourceLoan.reduce((accum, curr) => accum + curr.EMI_Amount, 0);
  }
  public highlightRow(row) {
    this.selectedId = row.Id;
  }

  public calculateBounceTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["BounceCharge"]);
      }
      return sum;
    }
    return 0;
  }

  onRepresent(row) {
    this.SelectedData = Object.assign({}, row);
    this.IsSameEMI = "1";
    //this.Row = this.ProcessList.filter(x => x.Id == row.Id)[0];
    this.Row = Object.assign({}, row);
    // LMS_Get_NextEMI
    this.RepresentId = row.Id;
    this.PresentBankInCompany = row.ComapnyAccountId;
    //if (new Date() <= new Date(row.BounceDateFormate)) {
    //  this.RepresentPostDate = new Date(row.BounceDateFormate);
    //}
    //else
    //  this.RepresentPostDate = new Date();
    this.minRepresentPostDate = new Date(row.BounceDateFormate);
    //  this.BounceDateFormate = row.BounceDateFormate;
    this.IsnextEMIData = false;
    this.lmsService
      .LMS_Get_NextEMI({
        Loan_Id: this.Row.Loan_Id,
        DueDateTo: this.DueDateTo,
        Period: this.Row.Period,
      })
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.IsnextEMIData = true;
          this.nextEMIData = result[0];
          //  this.SelectedData.Period = result[0].Period;
          // this.minRepresentPostDate = new Date(result[0].DueDate);
          // this.Row.EMI_Amount = result[0].Emi_Amount;
        }
      });
    $("#RepresentModel").modal("show");
    $("#RepresentModel").css("z-index", "1050");
  }
  RepresentPostDateChange() {
    if (
      this.SelectedData.First_RepresentDate != "" &&
      this.SelectedData.First_RepresentDate != null
    ) {
      var Days = this.getDiffDays(
        new Date(this.SelectedData.First_RepresentDate),
        this.RepresentPostDate
      );
      if (Days > 90) {
        this.IsRepresentAllow = false;
        //this.RepresentPostDate = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Not Represent this Cheque More than 90 days.",
          ...this.configSuccess,
        });
      } else {
        this.IsRepresentAllow = true;
      }
    }
  }
  getDiffDays(startDate, endDate) {
    if (startDate > endDate) {
      return 0;
    } else {
      return Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));
    }
  }
  ChangeIsSameEMI(IsSameEMI) {
    if (IsSameEMI == "0") {
      this.SelectedData.Period = this.nextEMIData.Period;
      this.minRepresentPostDate = new Date(this.nextEMIData.DueDate);
      this.Row.EMI_Amount = this.nextEMIData.Emi_Amount;
      //this.lmsService.LMS_Get_NextEMI({ Loan_Id: this.Row.Loan_Id, DueDateTo: this.DueDateTo, Period: this.Row.Period }).subscribe((result: any) => {
      //  if (result.length > 0) {
      //    this.nextEMIData = result[0];
      //    this.SelectedData.Period = result[0].Period;
      //    this.minRepresentPostDate = new Date(result[0].DueDate);
      //    this.Row.EMI_Amount = result[0].Emi_Amount;
      //  }
      //}
      //);
    } else {
      this.SelectedData.Period = this.Row.Period;
      this.minRepresentPostDate = new Date(this.SelectedData.BounceDateFormate);
      this.Row.EMI_Amount = this.SelectedData.EMI_Amount;
    }
  }
  onCloseRepresent() {
    this.RepresentId = 0;
    this.minRepresentPostDate = new Date();
    this.Row = {};
    $("#RepresentModel").modal("hide");
  }
  onRepresentBounce() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: new Date(this.RepresentPostDate),
      })
      .subscribe((result: any) => {
        this.savePostVoucher(
          result[0].VoucherNo,
          this.RepresentPostDate,
          1,
          this.RepresentId,
          this.SelectedData?.Period
        );
        this.onCloseRepresent();
      });
  }
  exportExcel() {
    //Remove key and value from below JSON (this.ProcessList)
    this.JsonData.forEach((obj: any) => {
      delete obj.Id;
      delete obj.amortization_Loan;
      delete obj.CustomerId;
      delete obj.BranchId;
      delete obj.AccountId;
      delete obj.ComapnyAccountId;
      delete obj.ComapnyAccount;
      delete obj.IsStop;
      delete obj.PrductId;
      delete obj.ChargeId;
      delete obj.ClearingType;
      delete obj.RPDC_ComapnyBank;
      delete obj.ClearBounceDate;
    });
    this.exportAsExcelFile(this.JsonData, "PDC Process List");
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
