import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { UntypedFormBuilder, FormGroup, FormArray } from '@angular/forms';
import { constantUrl } from '../../Shared/constantUrl';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../../_Lms/services/lms.service';
import * as moment from 'moment';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';
import { LosService } from '../../_LOS/services/los.service';
import { QuickLoanService } from '../services/quickLoan.service';


const url1 = 'https://www.paynimo.com/Paynimocheckout/client/lib/jquery.min.js';
const url2 = 'https://www.paynimo.com/Paynimocheckout/server/lib/checkout.js';

@Component({
  selector: 'app-quickloan-bank-pdc',
  templateUrl: './quickloan-bank-pdc.component.html',
  styleUrls: ['./quickloan-bank-pdc.component.scss']
})
export class QuickloanBankPdcComponent implements OnInit {
  dataCSource: any;
  showSpinner: boolean = false;
  loading: boolean = false; 
  CurrentDate: any = new Date();
  APIResponseData: any;
  CustomerList: any = [];
  currentUser: any;
  checkDetailData: any = [];
  tab: any = 'Cheque';
  @Input() Revert_IsEdit: any;
  displayedCustomerColumns: string[] = ['Int_Id', 'Pdc Type', 'Customer Name', 'Bank Name', 'Branch', 'Acc Type', 'A/C No', 'ChFrom', 'IFSC Code', 'Action'];
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  DealerId: any = 0
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  dialogreturn: any;
  PageAcess: any;
  CustomerId: any;
  Customer_Data: any;
  BankModel: any = [];
  CustomerBank: any = [];
  SPDCModel: any = [];
  RPDCModel: any = [];
  NACHModel: any = [];

  BankDetails: any[] = [];
  PDCType: any;

  //eNACH MENDATE VARIABLES
  accountNo: any = '054601509297';
  PaymentModel: any = [];
  Nach_Detail: any;

  @Input() Processid: any;
  @Input() PageData: any;
  @Input() Status: any;
  @Input() ProcessName: any;
  Bank_Verification: boolean = true;
  Bank_VerificationKeys: any;
  VerifyIFSC: any;
  VerifyAccountNo: any;
  Bank_VerificationDetails: any;
  APIResponse: any = { DoucumentNumber: "", Full_name: "", IsVerified: "", category: "" };
  BankForm: boolean = true;
  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private LmsService: LmsService,
    private activedRoute: ActivatedRoute,
    private dialog: DialogService,
    private _MasterService: MasterService,
    private losService: LosService,
    private _QuickLoanService: QuickLoanService,
  ) {
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    
    this.getChequeDetails();
    this.getCustomerList();
  }
  
  Delete(data: any) {
    this.dialogreturn = this.dialog.openConfirmDialog('Do you want to delete this Cheque Info ? ');
    let localUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.dialogreturn.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.LmsService.DeleteChequeDetails({ Int_Id: data.Int_Id, LoginUserId: localUser.userId }).subscribe((res: any) => {
          this.loading = false;
          
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.getChequeDetails();
            this.QuickLoan_Save_PageProcess('PDC');
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.getChequeDetails();
            this.QuickLoan_Save_PageProcess('PDC');
          }
        });
      }
    });
  }
  
  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
      this.DealerId = result[0].DealerId;
    });
  }

  getChequeDetails() {
    this.LmsService.GetChequeDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((response: any) => {
      //console.log("response", response);
      if (response.length > 0) {
        this.checkDetailData = response;
        this.dataCSource = new MatTableDataSource(response);
        this.PDCType = response.filter(item => item.PdcType == "Disbursment")[0];
        this.Nach_Detail = response.filter(item => item.PdcType == "NACH")[0];
        //console.log("this.Nach_Detail", this.Nach_Detail);
      }
      else {
        this.checkDetailData = [];
        this.dataCSource = null;
      }
    });
  }

  calculateNoOfCheque(i, item, Type) {
    let TotChq;
    if(Type == 'SPDC'){
      if(item.ChTo){
        TotChq = 0;
        TotChq = Number(item.ChTo) - Number(item.ChFrom) + 1;
        if (TotChq > this.loanSummary.No_Of_Instl) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "No of Cheques can not be greater than No of Installment.", ...this.configSuccess });
          item.ChFrom = '';
          item.ChTo = '';
          this.SPDCModel[i].NoOfChqs = '';
          return;
        }
        if (Number(item.ChTo) < Number(item.ChFrom)) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Cheques No TO should be greater than Chq No FROM.", ...this.configSuccess });
          item.ChFrom = '';
          item.ChTo = '';
          this.SPDCModel[i].NoOfChqs = '';
          return;
        }
        this.SPDCModel[i].NoOfChqs = TotChq;
      }
    }
    if(Type == 'RPDC'){
      if(item.ChTo){
        //this.RPDCModel[i].NoOfChqs = Number(item.ChTo) - Number(item.ChFrom) + 1;
        TotChq = 0;
        TotChq = Number(item.ChTo) - Number(item.ChFrom) + 1;
        if (TotChq > this.loanSummary.No_Of_Instl) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "No of Cheques can not be greater than No of Installment.", ...this.configSuccess });
          item.ChFrom = '';
          item.ChTo = '';
          this.RPDCModel[i].NoOfChqs = '';
          return;
        }
        if (Number(item.ChTo) < Number(item.ChFrom)) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Cheques No TO should be greater than Chq No FROM.", ...this.configSuccess });
          item.ChFrom = '';
          item.ChTo = '';
          this.RPDCModel[i].NoOfChqs = '';
          return;
        }
        this.RPDCModel[i].NoOfChqs = TotChq;
      }
    }
  }

  OpenModel() {
    $('#BankModel').modal('show');
    $("#BankModel").css("z-index", "1050");
    this.CustomerId = '';
    this.BankModel = [];
    this.SPDCModel = [];
    this.RPDCModel = [];
    this.NACHModel = [];
  }

  onCloseBankModel() {
    $(`#BankModel`).modal("hide");
  }

  onNext() {
    if ((this.dataCSource != undefined && this.dataCSource.filteredData.length != 0) || !this.loanSummary.IsNACHPDC) {
      this.LmsService.SaveNext_Loan({Loan_Id: this.loanSummary.LoanId, CommandName: 'Cheque', isStatus: 1}).subscribe((res) => {
        this.action.emit("next");
      });
    }
    else {
    }
  }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  addBankDetail() {
    if (this.BankModel.length > 0) {
      let _prev = this.BankModel[this.BankModel.length - 1];
      console.log("_prev", _prev);
      let BankAC = _prev.Type == "Own" ? _prev.BankId : _prev.AccountNo; 
      if (_prev.BeneficiaryName == "" || BankAC == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.BankModel.push({
      Type: "",
      BeneficiaryName: "", 
      BankId: "",
      AccountNo: "",
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: ""
    });
  }
  removeBankDetail(index: any) {
    this.BankModel.splice(index, 1);
  }

  changeCustomer(){
    this.Customer_Data = this.CustomerList.filter(item => item.CustomerId == this.CustomerId)[0];
    //console.log("this.Customer_Data", this.Customer_Data);
    this.getCustomerBankList();
  }

  getCustomerBankList() {
    this.BankModel = [];
    this.SPDCModel = [];
    this.RPDCModel = [];
    this.NACHModel = [];
    this.CustomerBank = [];
    this._MasterService.Get_BankAccount_Dropdown_For_Customer({ Customer_Id: this.CustomerId }).subscribe((res :any) => {
      //console.log("res", res);
      //if (res.length == 0) {
      //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please add Bank Detail.", ...this.configSuccess });
      //  return;
      //}
      this.CustomerBank = res;

      this.BankModel.push({
        BankId: "",
        Type: "",
        BeneficiaryName: "", 
        AccountNo: "", 
        BankName: "", 
        BankBranch: "", 
        BankAcType: "", 
        BankIFSC: "",
        BankMICRCode: ""
      });

      this.SPDCModel.push({
        BankId: "",
        BeneficiaryName: "", 
        AccountNo: "", 
        BankName: "", 
        BankBranch: "", 
        BankAcType: "", 
        BankIFSC: "",
        BankMICRCode: "",
        ChFrom: "",
        ChTo: ""
      });

      this.RPDCModel.push({
        BankId: "",
        BeneficiaryName: "", 
        AccountNo: "", 
        BankName: "", 
        BankBranch: "", 
        BankAcType: "", 
        BankIFSC: "",
        BankMICRCode: "",
        ChFrom: "",
        ChTo: ""
      });

      this.NACHModel.push({
        BankId: "",
        BeneficiaryName: "", 
        AccountNo: "", 
        BankName: "", 
        BankBranch: "", 
        BankAcType: "", 
        BankIFSC: "",
        BankMICRCode: ""
      });
    });
  }

  ChangeBankACNo(data: any, dindex: any, Type: any){
    //console.log("data", data);
    let ModelType = [];

    ModelType = Type == 'Bank' ? this.BankModel : Type == 'SPDC' ? this.SPDCModel : Type == 'RPDC' ? this.RPDCModel : this.NACHModel;

    let _find = ModelType.find((obj: any, index: any) => {
      return index != dindex && obj.BankId == data.BankId;
    });
    
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This A/C No already exist.", ...this.configSuccess });
      data.BankId = "";
      data.BeneficiaryName = "";
      data.BankName = "";
      data.BankBranch = "";
      data.BankAcType = "";
      data.BankIFSC = "";
      data.BankMICRCode = "";
      return;
    }
    if(data.BankId != ''){
      let _BData = this.CustomerBank.filter(item => item.Id == data.BankId)[0];
      if(Type == 'Bank'){
        this.BankModel[dindex].BankId = _BData.Id;
        this.BankModel[dindex].AccountNo = _BData.AccountNo;
        this.BankModel[dindex].BeneficiaryName = _BData.BeneficiaryName;
        this.BankModel[dindex].BankName = _BData.BankName;
        this.BankModel[dindex].BankBranch = _BData.BankBranch;
        this.BankModel[dindex].BankAcType = _BData.BankAcType;
        this.BankModel[dindex].BankIFSC = _BData.BankIFSC;
        this.BankModel[dindex].BankMICRCode = _BData.BankMICRCode;
      }

      if(Type == 'SPDC'){
        this.SPDCModel[dindex].BankId = _BData.Id;
        this.SPDCModel[dindex].AccountNo = _BData.AccountNo;
        this.SPDCModel[dindex].BeneficiaryName = _BData.BeneficiaryName;
        this.SPDCModel[dindex].BankName = _BData.BankName;
        this.SPDCModel[dindex].BankBranch = _BData.BankBranch;
        this.SPDCModel[dindex].BankAcType = _BData.BankAcType;
        this.SPDCModel[dindex].BankIFSC = _BData.BankIFSC;
        this.SPDCModel[dindex].BankMICRCode = _BData.BankMICRCode;
        this.SPDCModel[dindex].ChFrom = _BData.ChFrom;
        this.SPDCModel[dindex].ChTo = _BData.ChTo;
      }

      if(Type == 'RPDC'){
        this.RPDCModel[dindex].BankId = _BData.Id;
        this.RPDCModel[dindex].AccountNo = _BData.AccountNo;
        this.RPDCModel[dindex].BeneficiaryName = _BData.BeneficiaryName;
        this.RPDCModel[dindex].BankName = _BData.BankName;
        this.RPDCModel[dindex].BankBranch = _BData.BankBranch;
        this.RPDCModel[dindex].BankAcType = _BData.BankAcType;
        this.RPDCModel[dindex].BankIFSC = _BData.BankIFSC;
        this.RPDCModel[dindex].BankMICRCode = _BData.BankMICRCode;
        this.RPDCModel[dindex].ChFrom = _BData.ChFrom;
        this.RPDCModel[dindex].ChTo = _BData.ChTo;
      }

      if(Type == 'NACH'){
        this.NACHModel[dindex].BankId = _BData.Id;
        this.NACHModel[dindex].AccountNo = _BData.AccountNo;
        this.NACHModel[dindex].BeneficiaryName = _BData.BeneficiaryName;
        this.NACHModel[dindex].BankName = _BData.BankName;
        this.NACHModel[dindex].BankBranch = _BData.BankBranch;
        this.NACHModel[dindex].BankAcType = _BData.BankAcType;
        this.NACHModel[dindex].BankIFSC = _BData.BankIFSC;
        this.NACHModel[dindex].BankMICRCode = _BData.BankMICRCode;
      }
    }
    else{
      data.BankId = "";
      data.BeneficiaryName = "";
      data.BankName = "";
      data.BankBranch = "";
      data.BankAcType = "";
      data.BankIFSC = "";
      data.BankMICRCode = "";
    }
  }

  ChangeType(dindex){
    this.BankModel[dindex].BankId = '';
    this.BankModel[dindex].AccountNo = '';
    this.BankModel[dindex].BeneficiaryName = '';
    this.BankModel[dindex].BankName = '';
    this.BankModel[dindex].BankBranch = '';
    this.BankModel[dindex].BankAcType = '';
    this.BankModel[dindex].BankIFSC = '';
    this.BankModel[dindex].BankMICRCode = '';
    if (this.BankModel[dindex].Type == 'Dealer') {
      this._MasterService
        .Get_PartnerDetail({ PartnerId: this.DealerId })
        .subscribe((res: any) => {
          if (res.Item1.length > 0) {
            if (res.Item1[0].Partner_AccountNo == '' || res.Item1[0].Partner_AccountNo == null) {
              this.BankModel[dindex].Type = '';
              this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Partner Account First.", ...this.configSuccess });
              return;

            } else {
              this.BankModel[dindex].AccountNo = res.Item1[0].Partner_AccountNo;
              this.BankModel[dindex].BeneficiaryName = res.Item1[0].Partner_AccountHolderName + '(Dealer)';
              this.BankModel[dindex].BankName = res.Item1[0].Partner_BankName;
              this.BankModel[dindex].BankBranch = res.Item1[0].Partner_BankBranch;
              this.BankModel[dindex].BankAcType = '';
              this.BankModel[dindex].BankIFSC = res.Item1[0].Partner_BankIFSCCode;
              this.BankModel[dindex].BankMICRCode = '';
            }
          }
        })
    }



    if (this.BankModel[dindex].Type == 'Cash') {

      this.BankModel[dindex].AccountNo = 'Cash';
      this.BankModel[dindex].BeneficiaryName = this.CustomerList.filter(item => item.CustomerId == this.CustomerId)[0].Customer;
      this.BankModel[dindex].BankName = 'Cash';
      this.BankModel[dindex].BankBranch = 'Cash';
      this.BankModel[dindex].BankAcType = 'Cash';
      this.BankModel[dindex].BankIFSC = 'Cash';
      this.BankModel[dindex].BankMICRCode = '';

    }
  }

  addSPDCDetail() {
    if (this.SPDCModel.length > 0) {
      let _prev = this.SPDCModel[this.SPDCModel.length - 1];
      if (_prev.BeneficiaryName == "" || _prev.BankId == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "" || _prev.ChFrom == "" || _prev.ChFrom == undefined || _prev.ChTo == "" || _prev.ChTo == undefined) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.SPDCModel.push({
      BankId: "",
      BeneficiaryName: "", 
      AccountNo: "", 
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: "",
      ChFrom: "",
      ChTo: ""
    });
  }
  removeSPDCDetail(index: any) {
    this.SPDCModel.splice(index, 1);
  }

  addRPDCDetail() {
    if (this.RPDCModel.length > 0) {
      let _prev = this.RPDCModel[this.RPDCModel.length - 1];
      if (_prev.BeneficiaryName == "" || _prev.BankId == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "" || _prev.ChFrom == "" || _prev.ChFrom == undefined || _prev.ChTo == "" || _prev.ChTo == undefined) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.RPDCModel.push({
      BankId: "",
      BeneficiaryName: "", 
      AccountNo: "", 
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: "",
      ChFrom: "",
      ChTo: ""
    });
  }
  removeRPDCDetail(index: any) {
    this.RPDCModel.splice(index, 1);
  }

  addNACHDetail() {
    if (this.NACHModel.length > 0) {
      let _prev = this.NACHModel[this.NACHModel.length - 1];
      if (_prev.BeneficiaryName == "" || _prev.BankId == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.NACHModel.push({
      BankId: "",
      BeneficiaryName: "", 
      AccountNo: "", 
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: ""
    });
  }
  removeNACHDetail(index: any) {
    this.NACHModel.splice(index, 1);
  }

  onSaveBankChqDetail(){
    let CData = {
      LoanId : this.loanSummary.LoanId,
      LoginUserId : this.currentUser.userId
    };
    
    if(this.BankModel[0].BeneficiaryName == '' && this.SPDCModel[0].BankId == '' && this.RPDCModel[0].BankId == '' && this.NACHModel[0].BankId == ''){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please fill atleast single module (SPDC, RPDC, NACH)", ...this.configSuccess });
      return;
    }
    this.BankModel.forEach((obj: any) => {
      //obj.BankId = obj.Type == 'Other' ? 0 : obj.BankId;
      if (obj.Type == 'Other' || obj.Type == 'Dealer'){
        obj.BankId = 0;
        obj.Disbursment_Customer = obj.BeneficiaryName;
      }
      delete obj.Type;
      obj.CustomerId = this.Customer_Data.CustomerId;
    });

    this.SPDCModel.forEach((obj: any) => {
      delete obj.BeneficiaryName;
      delete obj.AccountNo;
      delete obj.BankName;
      delete obj.BankBranch;
      delete obj.BankAcType;
      delete obj.BankIFSC;
      delete obj.BankMICRCode;
    });

    this.RPDCModel.forEach((obj: any) => {
      delete obj.BeneficiaryName;
      delete obj.AccountNo;
      delete obj.BankName;
      delete obj.BankBranch;
      delete obj.BankAcType;
      delete obj.BankIFSC;
      delete obj.BankMICRCode;
    });

    this.NACHModel.forEach((obj: any) => {
      delete obj.BeneficiaryName;
      delete obj.AccountNo;
      delete obj.BankName;
      delete obj.BankBranch;
      delete obj.BankAcType;
      delete obj.BankIFSC;
      delete obj.BankMICRCode;
    });
    
    let _data = {
      Data: CData,
      Disbursment: this.BankModel[0].AccountNo != '' ? this.BankModel : '',
      SPDC: this.SPDCModel[0].BankId != '' ? this.SPDCModel : '',
      RPDC: this.RPDCModel[0].BankId != '' ? this.RPDCModel : '',
      NACH: this.NACHModel[0].BankId != '' ? this.NACHModel : ''
    }
    //if(this.Customer_Data.Customer_Type == 'C'){
    //  delete _data.Disbursment;
    //}
    if(this.Customer_Data.Customer_Type == 'G'){
      delete _data.Disbursment;
      delete _data.RPDC;
      delete _data.NACH;
    }
    
    console.log('_data', _data);
    if(this.Nach_Detail){
      delete _data.NACH;
    }
    this.LmsService.LMS_Save_PDCChequeDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
        this.onCloseBankModel();
        this.getChequeDetails();
        this.QuickLoan_Save_PageProcess('PDC');
        this.action.emit("next");
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
      }
      this.showSpinner = false;
      this.loading = false;
    });
  }

  ChangeACNo(data: any, dindex: any){
    let _find = this.BankDetails.find((obj: any, index: any) => {
      return index != dindex && obj.AccountNo == data.AccountNo;
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "This A/C No already exist.", ...this.configSuccess });
      data.AccountNo = "";
      return;
    }
  }

  AddCustomerBank() {
    this.BankDetails = [];
    this._MasterService.Get_Sms_Configration({ Step_Name: 'Bank_Verification' }).subscribe((res: any) => {
      if (res.length == 0) {
        this.Bank_Verification = false;
        this.BankDetails = [];
        this.BankDetails.push({
          BeneficiaryName: "",
          AccountNo: "",
          BankName: "",
          BankBranch: "",
          BankAcType: "",
          BankIFSC: "",
          BankMICRCode: ""
        });
        $(`#addCustomerBank`).modal("show");
        $(`#addCustomerBank`).css("z-index", "1050");

      } else {
        this.Bank_VerificationKeys = res[0];
        $(`#addCustomerBank`).modal("show");
        $(`#addCustomerBank`).css("z-index", "1050");
      }
    });

  }

  onCloseCustomerBankModel() {
    $(`#addCustomerBank`).modal("hide");
  }

  addBankDetail1() {
    if (this.BankDetails.length > 0) {
      let _prev = this.BankDetails[this.BankDetails.length - 1];
      if (_prev.BeneficiaryName == "" || _prev.AccountNo == "" || _prev.BankName == "" || _prev.BankBranch == "" || _prev.BankAcType == "" || _prev.BankIFSC == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Field to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.BankDetails.push({ 
      CustomerId: this.Customer_Data.CustomerId,
      BeneficiaryName: "",
      AccountNo: "", 
      BankName: "", 
      BankBranch: "", 
      BankAcType: "", 
      BankIFSC: "",
      BankMICRCode: ""
    });
  }
  
  removeBankDetail1(index: any) {
    this.BankDetails.splice(index, 1);
  }
  
  onSaveCustomerBank(){
    let UpperData = [];
    this.BankDetails.forEach((obj: any) => {
      UpperData.push({ 
        BeneficiaryName : obj.BeneficiaryName.toUpperCase(),
        AccountNo : obj.AccountNo.toUpperCase(),
        BankName : obj.BankName.toUpperCase(),
        BankBranch : obj.BankBranch.toUpperCase(),
        BankAcType : obj.BankAcType.toUpperCase(),
        BankIFSC : obj.BankIFSC.toUpperCase(),
        BankMICRCode : obj.BankMICRCode,
      });
    });
    
    let _data = {
      CustomerBankDetail: UpperData
    }
    
    //console.log('_data', _data);
    this.MasterService.Save_CustomerBankDetail({ 
      JSON: JSON.stringify(_data), Customer_Id: this.CustomerId, LoginUserId: this.currentUser.userId, Type: "CHQ"
    }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.getChequeDetails();
        this.onCloseCustomerBankModel();
        this.getCustomerBankList();
      }
      this.showSpinner = false;
    });
  }

  ToUpperText(obj) {
    for (var prop in obj) {
      obj[prop] = obj[prop].toUpperCase();
    }
    return obj;
  }

  OpenPaymentModel(event){
    this.PaymentModel = {
      ACType: '',
      CType: '',
      CFreq: ''
    };
    $(`#MakePayment`).modal("show");
    $(`#MakePayment`).css("z-index", "1050");
  }
  onClosePaymentModel() {
    $(`#MakePayment`).modal("hide");
  }

  ngAfterViewInit(){
    $(document).ready(function() {
      function handleResponse(res) {
        if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0300') {
          // success block
        } else if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0398') {
          // initiated block
          return null;
        } else {
          // error block
          return null;
        }
      };
    
      $(document).on('click', '#btnSubmit', function(e) {
        e.preventDefault();
        var accountNo = this.accountNo;
        var accountNo = $('#accountNo').val();
        console.log("accountNo", accountNo);
        var configJson = {
          'tarCall': false,
          'features': {
            'showPGResponseMsg': true,
            'enableAbortResponse': true,
            'enableNewWindowFlow': true,    //for hybrid applications please disable this by passing false
            'enableExpressPay':true,
            'siDetailsAtMerchantEnd':true,   
            'enableSI':true
            // 'payDetailsAtMerchantEnd':true,
            //  'hideSIDetails': true,
            //'hideSIConfirmation': true,
            //'expandSIDetails': true
          },
          //merchantid|txnId|totalamount|accountNo|consumerId|consumerMobileNo|consumerEmailId |debitStartDate|debitEndDate|maxAmount|amountType|frequency|cardNumber| expMonth|expYear|cvvCode|SALT
    
          // T818856|14822975811|1|054601509297|c964636|9876543210|test@test.com|10-07-2022|01-03-2047|100|M|ADHO|||||2907673365APFCYY
    
          'consumerData': {
            'deviceId': 'WEBSH2',	//possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
            'token': '17a15962fce2326c3c9f8ffd052a336a37a50c25509daf95a8e5374aae5af94fa4ea975471343b19076dbb752072e7c27286cdd465d949804280e5438910dae1',
            'returnUrl': 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp',    //merchant response page URL //file:///D:/Test/Return.html
            'responseHandler': handleResponse,
            'paymentMode': 'netBanking',   //netbanking
            'merchantLogoUrl': 'https://www.paynimo.com/CompanyDocs/company-logo-md.png',  //provided merchant logo will be displayed
            'merchantId': 'L818856',
            'currency': 'INR',
            'consumerId': 'c964636',  //Your unique consumer identifier to register a eMandate/eNACH
            'consumerMobileNo': '9876543210',
            'consumerEmailId': 'test@test.com',
            'txnId': '14822975811',   //Unique merchant transaction ID
            'items': [{
              'itemId': 'First',
              'amount': '1',
              'comAmt': '0' 
            }],
            'customStyle': {
              'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
              'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
              'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
              'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
            },
    
            //'bankCode':'10010',
            'accountNo': '054601509297',    //Pass this if accountNo is captured at merchant side for eMandate/eNACH
            'accountHolderName': 'test',  //Pass this if accountHolderName is captured at merchant side for ICICI eMandate & eNACH registration this is mandatory field, if not passed from merchant Customer need to enter in Checkout UI.
            'ifscCode': 'ICIC0000546',        //Pass this if ifscCode is captured at merchant side.
            'accountType': 'Saving',  //Required for eNACH registration this is mandatory field
            'debitStartDate': '10-07-2022',
            'debitEndDate': '01-03-2047',
            //'pan': 'CWXPP6777Q'
            'maxAmount': '100',
            'amountType': 'M',
            'frequency': 'ADHO'	//  Available options DAIL, WEEK, MNTH, QURT, MIAN, YEAR, BIMN and ADHO
          }
        };
    
        $.pnCheckout(configJson);
        if(configJson.features.enableNewWindowFlow){
          e.pnCheckoutShared.openNewWindow();
        }
      });
    });
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
  BankVerify() {
    this.VerifyAccountNo = '';
    this.VerifyIFSC = '';
    //this.BankDetails.forEach(val => this.AllBankDetails.push(Object.assign({}, val)));
    //console.log("bankdetails sdfdf", this.BankDetails);
    //console.log("AllBankDetails sdfdf", this.AllBankDetails);
    $(`#BankDetailModelVerified`).modal("show");
    $(`#BankDetailModelVerified`).css("z-index", "1052");
  }
  onCloseBankverifyModel() {
    this.BankForm = true;
    $(`#BankDetailModelVerified`).modal("hide");


  }
  onVerifiedBankDetail() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.VerifyAccountNo,
      SMS_URL: this.Bank_VerificationKeys.SMS_URL,
      IFSC: this.VerifyIFSC,
      Key: this.Bank_VerificationKeys.Key
    }
    //  console.log("AllBankDetails sdfdf", this.AllBankDetails);

    console.log("BankDetails sdfdf", this.BankDetails);
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {

      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: 0,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId
        }
      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {

          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            // if (APIResult.success == true) {

            this.BankForm = false;

            this.APIResponse = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data.account_exists,

              micr: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data.ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data.remarks,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,


              message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
            };
            this.APIResponseData = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data
                .account_exists,

              micr: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data
                .remarks,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,

              message_code: JSON.parse(data.KYC_Verification.APIResponse)
                .message_code,
            };

            //}
            //else {
            //  //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
            //  this.onCloseBankverifyModel();
            //  this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR Bank Account NOT VALID !!!", ...this.configSuccess });
            //  return;
            //}
          }
        });




    })
  }
  AddBankDetailApi() {
    console.log("this.BankDetails", this.BankDetails);
    var isexistsAccount = false;
    this.CustomerBank.forEach((obj: any) => {
      if (obj.AccountNo == this.APIResponse.DoucumentNumber) {
        isexistsAccount = true;


        this.dialog.openConfirmDialog(`Your Bank Account Already Exists. Are you sure you want to replace this account details`)
          .afterClosed().subscribe(dialogResult => {
            if (dialogResult == false) {
              return;
            } else {
              obj.BeneficiaryName = this.APIResponse.Full_name;
              obj.AccountNo = this.APIResponse.DoucumentNumber;
              obj.BankName = this.APIResponse.bank_name;
              obj.BankBranch = this.APIResponse.branch;
              obj.BankIFSC = this.VerifyIFSC;
              obj.BankMICRCode = this.APIResponse.micr;
              obj.IsVerified = this.APIResponse.success;
              var data = {
                CustomerBankDetail: {
                  BeneficiaryName: this.APIResponse.Full_name,
                  AccountNo: this.APIResponse.DoucumentNumber,
                  BankName: this.APIResponse.bank_name,
                  BankBranch: this.APIResponse.branch,
                  BankIFSC: this.VerifyIFSC,
                  BankMICRCode: this.APIResponse.micr,

                  Id: obj.Id
                }
              }
              this._MasterService.Update_CustomerBankDetail({ JSON: JSON.stringify(data) })
                .subscribe((Save_res: any) => {

                  this.snackBar.openFromComponent(SnackbarComponent, { data: Save_res[0].MSG, ...this.configSuccess });

                  return;
                })


            }
          })
        return;

      }
    })
    if (isexistsAccount == false) {
      this.BankDetails.push({
        BeneficiaryName: this.APIResponse.Full_name,
        AccountNo: this.APIResponse.DoucumentNumber,
        BankName: this.APIResponse.bank_name,
        BankBranch: this.APIResponse.branch,
        BankAcType: "",
        BankIFSC: this.VerifyIFSC,
        BankMICRCode: this.APIResponse.micr,
        IsEdit: 1,
        Id: 0,
        IsVerified: this.APIResponse.success
      });
    }
    console.log("this.BankDetails", this.BankDetails);
    this.onCloseBankverifyModel();
  }
}
