import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rptssrs',
  templateUrl: './rptssrs.component.html',
  styleUrls: ['./rptssrs.component.scss']
})
export class RptssrsComponent implements OnInit {

  
  reportServer: string = "http://localhost/ReportServer";
  reportUrl: string = "Pages/ReportViewer.aspx?/Report/Report1";
  showParameters: string = "true";
  parameters: any = {
    "SampleStringParameter": null,
    "SampleBooleanParameter": false,
    "SampleDateTimeParameter": "11/1/2020",
    "SampleIntParameter": 1,
    "SampleFloatParameter": "123.1234",
    "SampleMultipleStringParameter": ["Parameter1", "Parameter2"]
  };
  language: string = "en-us";
  width: number = 100;
  height: number = 100;
  toolbar: string = "true";

  constructor() {

  }

  ngOnInit() {

  
  }



}
