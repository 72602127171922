import { Component, OnInit, ViewChild } from "@angular/core";
import { ReportsService } from "../services/reports.service";
import { LmsService } from "src/app/_Lms/services/lms.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LosService } from "src/app/_LOS/services/los.service";

@Component({
  selector: "app-future-due",
  templateUrl: "./future-due.component.html",
  styleUrls: ["./future-due.component.scss"],
})
export class FutureDueComponent implements OnInit {
  showSpinner = false;
  FromDate: any;
  ToDate: any;
  BranchId: any = 0;
  ProductId: any = 0;
  SearchBy = "";
  ReportFor: any = "0";
  CollectionEx: any = 0;
  Div_Branch = false;
  Div_Product = false;
  Div_CollectionExecutive = false;
  BranchesList = [];
  ProductList: any = [];
  displayedColumns = [];
  div_list: boolean = false;
  selectedId: any;
  selectedRow: any = null;
  private _MasterService;

  JsonData: any[] = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild("paginatorRef", { read: MatPaginator }) paginatorRef: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser: any;
  CollectionExList: any;
  dataSourceTree: any = null;
  leadSourceDropdown: any;
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;

  constructor(
    private reportService: ReportsService,
    private lmsService: LmsService,
    private snackBar: MatSnackBar,
    private dataSharingService: DataSharingService,
    private MasterService: MasterService,
    private losService: LosService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Future Due");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getProductDropdown();
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  SearchByChange() {
    if (this.SearchBy == "CollectionExecutive") {
      this.GetCollectionExecutive();
      this.Div_Branch = false;
      this.Div_Product = false;
      this.Div_CollectionExecutive = true;
      this.CollectionEx = 0;
      this.BranchId = 0;
      this.ProductId = 0;
    } else if (this.SearchBy == "Branch") {
      this.GetBranchList();
      this.Div_Branch = true;
      this.Div_Product = false;
      this.CollectionEx = 0;
      this.BranchId = 0;
      this.ProductId = 0;
    } else if (this.SearchBy == "Product") {
      this.getProductDropdown();
      this.Div_Branch = false;
      this.Div_Product = true;
      this.Div_CollectionExecutive = false;
      this.CollectionEx = 0;
      this.BranchId = 0;
      this.ProductId = 0;
    } else {
      this.CollectionEx = 0;
      this.BranchId = 0;
      this.ProductId = 0;
      this.Div_Branch = false;
      this.Div_Product = false;
      this.Div_CollectionExecutive = false;
    }
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Lms_CollectionExecutive({ Branch_Id: 0 })
      .subscribe((x) => {
        console.log("list of Colle", x);
        this.CollectionExList = x;
      });
  }

  GetBranchList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((x: any) => {
        console.log("list of Branches", x);
        this.BranchesList = x;
      });
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
        console.log("Product List", this.ProductList);
      });
  }

  onSearchReport() {
    this.showSpinner = true;
    let FromDate = moment(new Date(this.FromDate)).format("YYYY-MM-DD");
    let ToDate = moment(new Date(this.ToDate)).format("YYYY-MM-DD");
    let requestData = {
      FromDate: FromDate,
      ToDate: ToDate,
      LoginUserId: this.currentUser.userId,
      BranchId: this.BranchId,
      CollectionEx: this.CollectionEx,
      ProductId: this.ProductId,
      OnlyDue: this.ReportFor == "All" ? 1 : 0,
      SourceType: this.SelectSourceType || "",
      SourceId: this.LeadSourceName || 0,
    };
    console.log(requestData);
    this.reportService.Report_FutureDue(requestData).subscribe((x: []) => {
      console.log("Data", x);
      if (x.length > 0) {
        this.displayedColumns = [
          "Id",
          "Application_No",
          "Loan_Date",
          "Branch_Name",
          "Product_Name",
          "LoanAcNo",
          "CustomerName",
          "SalesExecutive",
          "CollectionExecutive",
          "LoanAmount",
          "LoanTenure",
          "Period",
          "DueDate",
          "EMI_Amount",
          "OD_Amount",
          "PDC_Type",
          "SourceType",
          "SourceName",
          "Customer_PhoneNo",
          "Address",
        ];
        //  this.dataSourceTree=new MatTableDataSource(x);
        //  this.dataSourceTree.paginator=this.paginator
        this.JsonData = JSON.parse(JSON.stringify(x));
        this.dataSourceTree = new MatTableDataSource(
          JSON.parse(JSON.stringify(x))
        );

        this.dataSourceTree.paginator = this.paginator;
        this.showSpinner = false;
        this.div_list = true;
        // this.dataSourceTree.table = this.table;
      } else {
        this.dataSourceTree = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
        this.div_list = false;
      }
      this.showSpinner = false;
      //  console.log("asdf",this.Table)
    });
  }

  totaltypewise(type) {
    if (this.dataSourceTree != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceTree.filteredData.length; i++) {
        sum += Number(this.dataSourceTree.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  applyFilter(filterValue: string) {
    this.dataSourceTree.filter = filterValue.trim().toLowerCase();
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "future_due_report"
    ) as HTMLTableElement;
    const worksheetName = "FUTURE DUE REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  selectRow(row: any): void {
    this.selectedRow = row;
  }
}
