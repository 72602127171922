<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="changeFilter()"
        [(ngModel)]="taxSlab"
      >
        <option [value]="slab.TaxSlab_Name" *ngFor="let slab of slabList">
          {{ slab.TaxSlab_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-9 p-1 text-right">
      <button
        type="button"
        style="margin-right: 20px"
        *ngIf="taxSlab != ''"
        class="btn font-size-12 button-btn"
        (click)="onEditTaxSlab()"
      >
        <i class="fa fa-pencil-alt font-size-12" aria-label="true"></i> Edit
      </button>
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="onAddTaxSlab()"
      >
        <i class="fa fa-plus font-size-12" aria-label="true"></i> Add New
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="Id">
        <mat-header-cell
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TaxSlab_Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          TaxSlab Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TaxSlab_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TaxSlab_Type">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          TaxSlab Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TaxSlab_Type }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="TaxSlab_Rate">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          TaxSlab Rate
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TaxSlab_Rate }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Account_Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Account Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Account_Name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="TaxType">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Tax Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TaxType }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="IsInterState">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Inter State
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.IsInterState }}
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
<div
  class="modal fade in"
  id="AddNewtaxslabModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveTaxSlab()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add New Tax Slab
          </h6>
          <button
            type="button"
            (click)="OnClosetaxSlabModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0">
            <div class="col-md-4 p-1">
              <span class="required-lable"> Tax Slab Name : </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                type="text"
                required
                [(ngModel)]="taxSlabModel.TaxSlab_Name"
                #refTaxSlab_Name="ngModel"
                placeholder="Tax Slab Name"
                name="TaxSlab_Name"
                [ngClass]="{
                  'is-invalid': f.submitted && refTaxSlab_Name.invalid,
                  'alert-warning':
                    refTaxSlab_Name.invalid &&
                    (refTaxSlab_Name.dirty ||
                      refTaxSlab_Name.touched ||
                      refTaxSlab_Name.untouched)
                }"
                id="TaxSlab_Name"
                class="form-control input-text-css mt-2"
              />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <h1
                style="
                  font-size: 12px;
                  background: #0e5c86;
                  margin-bottom: 10px;
                  padding: 10px;
                  color: #fff;
                "
              >
                Tax Slabs
              </h1>
            </div>
            <div class="col-md-12 row m-0 p-0">
              <div class="col-md-1 p-1">
                <span>&nbsp;</span>
              </div>
              <div class="col-md-4 p-1">
                <span>TaxSlab Type</span>
              </div>
              <div class="col-md-2 p-1">
                <span>Tax Type</span>
              </div>
              <div class="col-md-1 p-1">
                <span>Inter State</span>
              </div>
              <div class="col-md-1 p-1">
                <span>TaxSlab Rate(%)</span>
              </div>
              <div class="col-md-3 p-1">
                <span>Account Head</span>
              </div>
            </div>
            <div
              class="col-md-12 row m-0 p-0"
              *ngFor="let slab of taxSlabModel.TaxSlab; let i = index"
            >
              <div class="col-md-1 p-1">
                {{ i + 1 }}
              </div>
              <div class="col-md-3 p-1">
                <span> {{ slab.TaxSlab_Type }} </span>
              </div>
              <div class="col-md-2 p-1">
                <select
                  name="TaxType{{ i }}"
                  id="TaxType{{ i }}"
                  [(ngModel)]="slab.TaxType"
                  class="form-control input-text-css"
                  required
                  #refTaxType="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refTaxType.invalid,
                    'alert-warning':
                      refTaxType.invalid &&
                      (refTaxType.dirty ||
                        refTaxType.touched ||
                        refTaxType.untouched)
                  }"
                >
                  <option value="">Select Tax Type</option>
                  <option value="Input">Input</option>
                  <option value="Output">Output</option>
                </select>
              </div>

              <div class="col-md-2 p-1">
                <select
                  name="IsInterState{{ i }}"
                  id="IsInterState{{ i }}"
                  [(ngModel)]="slab.IsInterState"
                  class="form-control input-text-css"
                  required
                  #refIsInterState="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refIsInterState.invalid,
                    'alert-warning':
                      refIsInterState.invalid &&
                      (refIsInterState.dirty ||
                        refIsInterState.touched ||
                        refIsInterState.untouched)
                  }"
                >
                  <option value="">Select</option>
                  <option value="Inter state">Inter state</option>
                  <option value="With in State">With in State</option>
                </select>
              </div>

              <div class="col-md-1 p-1">
                <input
                  type="number"
                  min="0"
                  maxlength="2"
                  [(ngModel)]="slab.TaxSlab_Rate"
                  placeholder="TaxSlab Rate"
                  name="TaxSlab_Rate{{ i }}"
                  id="TaxSlab_Rate{{ i }}"
                  class="form-control input-text-css"
                  required
                  #refTaxSlab_Rate="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refTaxSlab_Rate.invalid,
                    'alert-warning':
                      refTaxSlab_Rate.invalid &&
                      (refTaxSlab_Rate.dirty ||
                        refTaxSlab_Rate.touched ||
                        refTaxSlab_Rate.untouched)
                  }"
                />
              </div>
              <div class="col-md-3 p-1">
                <select
                  name="TaxSlab_AccountHead{{ i }}"
                  id="TaxSlab_AccountHead{{ i }}"
                  [(ngModel)]="slab.TaxSlab_AccountHead"
                  class="form-control input-text-css"
                  required
                  #refTaxSlab_AccountHead="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refTaxSlab_AccountHead.invalid,
                    'alert-warning':
                      refTaxSlab_AccountHead.invalid &&
                      (refTaxSlab_AccountHead.dirty ||
                        refTaxSlab_AccountHead.touched ||
                        refTaxSlab_AccountHead.untouched)
                  }"
                >
                  <option value="">Select Account head</option>
                  <option
                    *ngFor="let ca of taxSlabAccountHead"
                    [value]="ca.AccountId"
                  >
                    {{ ca.Account_Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClosetaxSlabModal()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveTaxSlab()"
            class="mt-3 btn font-size-12 button-btn"
            color="primary"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
