<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="col-md-12 m-0 d-flex align-items-center justify-content-end formborder"> 
    <div class="col-md-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input type="text" id="FilterInputSearch" (keyup)="applyFilter($event.target.value)" name="FilterInputSearch" placeholder="What're you searching for?"  class="form-control border-0 bg-light">
            <div class="input-group-append">
              <button id="button-addon1" type="submit" class="btn btn-link text-primary"><i class="fa fa-search"></i></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div> 

  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="PLoan_Id">
        <mat-header-cell mat-sort-header class="grid-header" style="max-width:50px" *matHeaderCellDef>
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
          {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PLoan_Date">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Date</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.PLoan_Date | date:'dd/MM/yyyy'  }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PLoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Partner A/C</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.PLoanAcNo}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LMS_LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer A/C</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.LMS_LoanAcNo}} </mat-cell>
      </ng-container>

      

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Amount</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.LoanAmount}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PLoanCreatedOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Create On</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell"> {{row.PLoanCreatedOn}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <i class="fa fa-arrow-circle-right arrow-circle action-btn" (click)="goToDetail(row)" aria-label="true"></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff;">
      <div class="col-md-12">
        <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
      </div>
    </div>
  </div> 
</div>
