<div [ngClass]="{ fixheight: PageType != 'A', fixheight2: PageType == 'A' }">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-4 mb-1 loanSummary formborder" *ngIf="dvDetail">
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Application No</h6>
      <p class="lead fs-12">{{ dvDetail.ApplicationNo }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Branch</h6>
      <p class="lead fs-12">{{ dvDetail.Branch }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Loan Product</h6>
      <p class="lead fs-12">{{ dvDetail.Product }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Borrower Name</h6>
      <p class="lead fs-12">{{ dvDetail.Customer }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Loan Amount</h6>
      <p class="lead fs-12">₹{{ dvDetail.LoanAmount }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Tenure</h6>
      <p class="lead fs-12">{{ dvDetail.LoanTenure }} Months</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Loan No</h6>
      <p class="lead fs-12">{{ dvDetail.LoanNo }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Loan Date</h6>
      <p class="lead fs-12">{{ dvDetail.LoanDate }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Emi Amount</h6>
      <p class="lead fs-12">{{ dvDetail.EMIAmount }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">No Of Inst</h6>
      <p class="lead fs-12">{{ dvDetail.NoOfInstallment }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">TAT</h6>
      <p class="lead fs-12">{{ dvDetail.TAT_Hr }} Hr.</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Voucher Status</h6>
      <p class="lead fs-12">{{ dvDetail.VoucherStatus }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Created On</h6>
      <p class="lead fs-12">{{ dvDetail.CreatedOn }}</p>
    </div>
    <div class="col-md-2 p-0">
      <h6 class="fs-12">Created By</h6>
      <p class="lead fs-12">{{ dvDetail.CreatedBy }}</p>
    </div>

    <div class="col-md-2" *ngIf="dvDetail.VoucherStatus == 'Reverted'">
      <h6 class="fs-12">Reverted On</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedOn }}</p>
    </div>
    <div class="col-md-2" *ngIf="dvDetail.VoucherStatus == 'Reverted'">
      <h6 class="fs-12">Reverted By</h6>
      <p class="lead fs-12">{{ dvDetail.RevertedBy }}</p>
    </div>
    <div class="col-md-4" *ngIf="dvDetail.VoucherStatus == 'Reverted'">
      <h6 class="fs-12">Remark</h6>
      <p class="lead fs-12">{{ dvDetail.Remark }}</p>
    </div>
  </div>
  <div class="row m-0 mb-4">
    <div
      class="row m-0 col-md-12 p-0 mt-2 h-b w-b align-items-center justify-content-between"
    >
      <div><h1 class="fs-12 m-0">Disbursement Voucher Date</h1></div>
      <div>
        <!--<input type="text" name="VoucherDateTo" [(ngModel)]="VoucherDateTo" (ngModelChange)="DateChange()" [disabled]="IsEdit" style=" width: 100px;" id="VoucherDateTo"
         placeholder="Date" class="form-control input-text-css" bsDatepicker>-->
        <div class="datepicker_feild" style="width: 113px">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            (dateChange)="DateChange()"
            [disabled]="!IsEdit"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
    </div>
    <!-- deduction -->
    <div
      class="row m-0 col-md-12 p-0 mt-2 formborder"
      *ngIf="tab == 'deduction'"
    >
      <div class="row m-0 col-md-12 p-0">
        <form #f="ngForm" novalidate class="row m-0 col-md-12 p-0">
          <form #df="ngForm" novalidate class="row m-0 col-md-12 p-0">
            <div
              class="row m-0 mt-2 col-md-12 add-remove"
              *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index"
            >
              <div class="col-md-12 row m-0 type-column">
                <div class="type">
                  <span class="required-lable" *ngIf="i == 0">Type</span>
                  <select
                    name="Type{{ i }}"
                    id="Type{{ i }}"
                    required
                    disabled
                    #refType="ngModel"
                    [ngClass]="{
                      'is-invalid': df.submitted && refType.invalid,
                      'alert-warning':
                        refType.invalid &&
                        (refType.dirty || refType.touched || refType.untouched)
                    }"
                    [(ngModel)]="vdData.Type"
                    class="form-control pl-1 input-text-css"
                    (change)="onChangeCRDR(vdData)"
                  >
                    <option [value]="'DR'">DR</option>
                    <option [value]="'CR'">CR</option>
                  </select>
                </div>
                <div class="row m-0 feild">
                  <ng-container
                    *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Partner'"
                  >
                    <div class="col-md-2 pr-0">
                      <span
                        class=""
                        [ngClass]="{
                          'required-lable': vdData.IsLoanChargeable
                        }"
                        *ngIf="i == 0"
                        >Loan/App No.</span
                      >
                      <div class="input-btn">
                        <input
                          type="text"
                          [required]="vdData.IsLoanChargeable"
                          readonly
                          name="ApplicationNo{{ i }}"
                          id="ApplicationNo{{ i }}"
                          [(ngModel)]="vdData.ApplicationNo"
                          class="form-control input-text-css"
                        />
                        <!-- <button type="button" class="primary-btn ml-2 search">
                            <i class="fa fa-search" aria-hidden="true" style="font-size:11px"></i>
                          </button> -->
                      </div>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span *ngIf="i == 0">Customer</span>
                      <input
                        type="text"
                        name="Customer{{ i }}"
                        id="Customer{{ i }}"
                        class="form-control input-text-css"
                        data-toggle="tooltip"
                        title="{{ vdData.Customer }}"
                        readonly
                        [(ngModel)]="vdData.Customer"
                      />
                    </div>
                    <div class="col-md-2 pr-0">
                      <span *ngIf="i == 0">Branch</span>
                      <input
                        type="text"
                        name="Branch{{ i }}"
                        id="Branch{{ i }}"
                        class="form-control input-text-css"
                        data-toggle="tooltip"
                        title="{{ vdData.Branch }}"
                        readonly
                        [(ngModel)]="vdData.Branch"
                      />
                    </div>
                    <div class="col-md-2 pr-0">
                      <span
                        class=""
                        [ngClass]="{
                          'required-lable': vdData.IsLoanChargeable
                        }"
                        *ngIf="i == 0"
                        >Category</span
                      >
                      <select
                        name="ChagesHeadId{{ i }}"
                        disabled
                        id="ChagesHeadId{{ i }}"
                        [required]="vdData.IsLoanChargeable"
                        data-toggle="tooltip"
                        title="{{ vdData.ChagesHead }}"
                        #refChagesHeadId="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refChagesHeadId.invalid,
                          'alert-warning':
                            refChagesHeadId.invalid &&
                            (refChagesHeadId.dirty ||
                              refChagesHeadId.touched ||
                              refChagesHeadId.untouched)
                        }"
                        [(ngModel)]="vdData.ChagesHeadId"
                        class="form-control input-text-css"
                      >
                        <!-- (change)="onChangeChargesHead(vdData,i)" -->
                        <option value="">Select Category</option>
                        <option
                          *ngFor="let item of chargesHeadDropdownData"
                          [value]="item.Id"
                        >
                          {{ item.ChagesHead }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span class="required-lable" *ngIf="i == 0">Account</span>
                      <select
                        name="AccountId{{ i }}"
                        id="AccountId{{ i }}"
                        required
                        #refAccountId="ngModel"
                        data-toggle="tooltip"
                        title="{{ vdData.Account }}"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAccountId.invalid,
                          'alert-warning':
                            refAccountId.invalid &&
                            (refAccountId.dirty ||
                              refAccountId.touched ||
                              refAccountId.untouched)
                        }"
                        [(ngModel)]="vdData.AccountId"
                        class="form-control input-text-css"
                        (change)="onChangeAccountHead(vdData, i)"
                        disabled
                      >
                        <option value="">Select Head</option>
                        <option
                          *ngFor="let item of accountHeadDropdown"
                          [value]="item.AccountId"
                        >
                          {{ item.Account_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-1 pr-0 pl-1">
                      <span
                        class=""
                        [ngClass]="{ 'required-lable': vdData.Type == 'DR' }"
                        *ngIf="i == 0"
                        >Amount(DR)</span
                      >
                      <input
                        type="number"
                        min="0"
                        name="Amount{{ i }}"
                        id="Amount{{ i }}"
                        *ngIf="vdData.Type == 'DR'"
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.Type == 'DR'"
                        #refAmount="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched)
                        }"
                        [(ngModel)]="vdData.Amount"
                        readonly
                      />
                      <!-- (change)="onChangeAmount($event,vdData)" (keyup.enter)="onChangeAmount($event,vdData)" -->
                      <input
                        type="number"
                        name="AmountD{{ i }}"
                        id="AmountD{{ i }}"
                        readonly
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.Type == 'CR'"
                      />
                    </div>
                    <div class="col-md-1 pr-0 pl-1">
                      <span
                        class=""
                        [ngClass]="{ 'required-lable': vdData.Type == 'CR' }"
                        *ngIf="i == 0"
                        >Amount(CR)</span
                      >
                      <input
                        type="number"
                        min="0"
                        name="Amount{{ i }}"
                        *ngIf="vdData.Type == 'CR'"
                        id="Amount{{ i }}"
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.Type == 'CR'"
                        #refAmount="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched)
                        }"
                        [(ngModel)]="vdData.Amount"
                        readonly
                      />
                      <!--  (change)="onChangeAmount($event,vdData)" (keyup.enter)="onChangeAmount($event,vdData)"  [disabled]="vdData.IsLoanChargeable && !vdData.ChagesHeadId && vdData.isShowIcon" -->
                      <input
                        type="number"
                        name="AmountD{{ i }}"
                        id="AmountD{{ i }}"
                        readonly
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.Type == 'DR'"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
              <ng-container *ngIf="vdData.TaxSlab && vdData.TaxSlab.length > 0">
                <div
                  class="col-md-12 row m-0 p-0 mt-2"
                  *ngFor="let taxData of vdData.TaxSlab; let ti = index"
                >
                  <div class="col-md-2 pr-0">
                    <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                  </div>
                  <div class="col-md-3 pr-0">
                    <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                  </div>
                  <div class="col-md-1 p-0">
                    <span
                      ><b>Rate :</b>&nbsp;{{ taxData.TaxPercentage }} %</span
                    >
                  </div>
                  <div class="col-md-4 pr-0">
                    <span><b>Account :</b>&nbsp;{{ taxData.Account }}</span>
                  </div>
                  <div class="col-md-1 text-right pl-0 pr-3">
                    <span
                      ><b></b>&nbsp;<ng-container
                        *ngIf="vdData.Type == 'DR' && taxData.Amount"
                        >{{ taxData.Amount }}
                      </ng-container></span
                    >
                  </div>
                  <div class="col-md-1 text-right pl-1 pr-3">
                    <span
                      ><b></b>&nbsp;<ng-container
                        *ngIf="vdData.Type == 'CR' && taxData.Amount"
                        >{{ taxData.Amount }}
                      </ng-container></span
                    >
                  </div>
                </div>
              </ng-container>

              <button
                type="button"
                class="remove"
                (click)="removeData(i, vdData)"
                [ngClass]="{ 'no-head': i != 0 }"
                *ngIf="i != voucherModel.Voucher_Detail.length - 1 && i != 0"
                [hidden]="!IsEdit"
              >
                -
              </button>
              <button
                type="button"
                class="addmore"
                (click)="addMoreData()"
                [hidden]="!IsEdit"
                [ngClass]="{
                  both: voucherModel.Voucher_Detail.length > 0,
                  'no-head': i != 0
                }"
                [disabled]="!df.form.valid"
                *ngIf="
                  i == voucherModel.Voucher_Detail.length - 1 && !vdData.IsTax
                "
              >
                +
              </button>
            </div>

            <div
              class="row m-0 col-md-12 pr-5 mt-2 mb-2"
              *ngIf="totalCalculator && voucherModel.Voucher_Detail.length > 0"
            >
              <div
                class="pr-0"
                [ngClass]="{
                  'col-md-10':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Partner',
                  'col-md-8':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                }"
              >
                <span><b>Grand Total :</b></span>
              </div>
              <div
                class="text-right pr-1"
                [ngClass]="{
                  'col-md-1':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Partner',
                  'col-md-2':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                }"
              >
                <span>{{ totalCalculator.totalDR.toFixed(2) }}</span>
              </div>
              <div
                class="text-right pr-1"
                [ngClass]="{
                  'col-md-1':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Partner',
                  'col-md-2':
                    voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                }"
              >
                <span>{{ totalCalculator.totalCR.toFixed(2) }}</span>
              </div>
            </div>
          </form>
          <div
            class="row m-0 mt-1 mb-2 col-md-12"
            *ngIf="voucherModel.Voucher_Detail.length > 0"
          >
            <div class="col-md-12">
              <span class="">Voucher Narration</span>
              <input
                type="text"
                #refVoucher_Narration="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refVoucher_Narration.invalid,
                  'alert-warning':
                    refVoucher_Narration.invalid &&
                    (refVoucher_Narration.dirty ||
                      refVoucher_Narration.touched ||
                      refVoucher_Narration.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
                name="Voucher_Narration"
                id="Voucher_Narration"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-2 mb-2 justify-content-end">
            <button
              type="button"
              class="ml-4 btn font-size-12 button-btn"
              (click)="saveVoucher()"
              [class.spinner]="loading"
              [disabled]="!f.form.valid || !df.form.valid"
              *ngIf="IsEdit"
            >
              {{
                voucherModel.Voucher_Detail.ApplicationNo != ""
                  ? "Update"
                  : "Save"
              }}
            </button>
            <button
              type="button"
              class="ml-4"
              class="mt-3 btn font-size-12 button-btn"
              (click)="EditVoucher()"
              *ngIf="!IsEdit"
            >
              Edit
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- receipt -->
    <div class="row m-0 col-md-12 p-0 mt-2" *ngIf="tab == 'receipt'">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ChagesHead">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >ChagesHead</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ChagesHead }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Account">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Account
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TaxPercentage">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Tax(%)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TaxPercentage }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Amount">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Amount }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              CollectionOn
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CollectionOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionBy">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              CollectionBy
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CollectionBy }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="dvDetailNewDeduction"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          New Deduction
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearchNew()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <form #snf="ngForm" class="col-md-12" novalidate>
            <div class="row m-0 col-md-12 p-0 mt-3 add-remove">
              <div class="col-md-12 p-0 row m-0 type-column">
                <div class="row m-0 col-md-12 p-0 feild">
                  <ng-container>
                    <div class="col-md-2 pr-0">
                      <span class="required-lable">Search</span>
                      <div class="input-btn">
                        <input
                          required
                          type="text"
                          readonly
                          name="CaseNo"
                          id="CaseNo"
                          [(ngModel)]="Voucher_Detail_NEW_DEDUCTION.CaseNo"
                          class="form-control input-text-css"
                        />
                      </div>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span>Customer</span>
                      <input
                        type="text"
                        name="Customer"
                        id="Customer"
                        class="form-control input-text-css"
                        readonly
                        [(ngModel)]="Voucher_Detail_NEW_DEDUCTION.Customer"
                      />
                    </div>
                    <div class="col-md-2 pr-0">
                      <span>Branch</span>
                      <input
                        type="text"
                        name="Branch_Name"
                        id="Branch_Name"
                        class="form-control input-text-css"
                        readonly
                        [(ngModel)]="Voucher_Detail_NEW_DEDUCTION.Branch_Name"
                      />
                    </div>
                    <div class="col-md-2 pr-0">
                      <span class="required-lable">Category</span>
                      <select
                        name="ChargeHeadId"
                        id="ChargeHeadId"
                        required
                        #refChargeHeadId="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            snf.submitted && refChargeHeadId.invalid,
                          'alert-warning':
                            refChargeHeadId.invalid &&
                            (refChargeHeadId.dirty ||
                              refChargeHeadId.touched ||
                              refChargeHeadId.untouched)
                        }"
                        [(ngModel)]="Voucher_Detail_NEW_DEDUCTION.ChargeHeadId"
                        class="form-control input-text-css"
                        (change)="
                          onChangeChargesHead(Voucher_Detail_NEW_DEDUCTION)
                        "
                      >
                        <option value="">Select Category</option>
                        <option
                          *ngFor="let item of chargesHeadDropdown"
                          [value]="item.Id"
                        >
                          {{ item.ChagesHead }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span> Account Head</span>
                      <input
                        type="text"
                        name="ChargeHeadCategory"
                        id="ChargeHeadCategory"
                        class="form-control input-text-css"
                        readonly
                        [(ngModel)]="
                          Voucher_Detail_NEW_DEDUCTION.ChargeHeadCategory
                        "
                      />
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Amount</span>
                      <input
                        type="number"
                        min="0"
                        name="Amount"
                        id="Amount"
                        [disabled]="!Voucher_Detail_NEW_DEDUCTION.ChargeHeadId"
                        class="form-control input-text-css text-right pr-3"
                        required
                        #refAmount="ngModel"
                        (change)="
                          onChangeAmount($event, Voucher_Detail_NEW_DEDUCTION)
                        "
                        (keyup.enter)="
                          onChangeAmount($event, Voucher_Detail_NEW_DEDUCTION)
                        "
                        [ngClass]="{
                          'is-invalid': snf.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched)
                        }"
                        [(ngModel)]="Voucher_Detail_NEW_DEDUCTION.Amount"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
              <ng-container
                *ngIf="
                  Voucher_Detail_NEW_DEDUCTION.TaxSlab &&
                  Voucher_Detail_NEW_DEDUCTION.TaxSlab.length > 0
                "
              >
                <div
                  class="col-md-12 row m-0 p-0 mt-2"
                  *ngFor="
                    let taxData of Voucher_Detail_NEW_DEDUCTION.TaxSlab;
                    let ti = index
                  "
                >
                  <div class="col-md-2 pr-0">
                    <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                  </div>
                  <div class="col-md-3 pr-0">
                    <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                  </div>
                  <div class="col-md-2 pr-0">
                    <span><b>Rate :</b>&nbsp;{{ taxData.TaxRate }} %</span>
                  </div>
                  <div class="col-md-3 pr-0">
                    <span
                      ><b>Account Head :</b>&nbsp;{{
                        taxData.TaxAccountName
                      }}</span
                    >
                  </div>
                  <div class="col-md-2 text-right pr-5">
                    <!--<span><b></b>&nbsp;<ng-container *ngIf="taxData.TaxAmount">{{taxData.TaxAmount}}</ng-container></span>-->
                    <input
                      type="text"
                      *ngIf="taxData.TaxAmount"
                      name="TaxAmount{{ ti }}"
                      id="TaxAmount{{ ti }}"
                      [(ngModel)]="taxData.TaxAmount"
                      class="form-control input-text-css"
                      (change)="getTotalCalculationNew()"
                    />
                  </div>
                </div>
              </ng-container>
            </div>
            <div
              class="col-md-12 row m-0 p-0 pr-4 mt-2"
              *ngIf="totalCalculatorNewDeduction"
            >
              <div class="col-md-8 pr-0">
                <span><b>Grand Total :</b></span>
              </div>
              <div class="col-md-4 text-right pr-1">
                <span
                  ><b>In Digit :</b>&nbsp;{{
                    totalCalculatorNewDeduction.total.toFixed(2)
                  }}</span
                >
              </div>
            </div>

            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="OnCloseSearchNew()"
                class="btn btn-light font-size-12 mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onSaveNewRdDetail()"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!snf.form.valid"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
