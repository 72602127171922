<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div style="height: 270px; max-width: 100%; overflow: auto">
  <div>
    <div class="row m-0 mt-1 p-1 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Customer Details</h1>
      <!-- <button type="button" class="primary-btn" (click)="addCustomer()"> Add Customer </button> -->

      <app-add-customer-info
        [TypeFrom]="TypeFrom"
        [loan_Summary]="loanSummary"
        (close)="GetData($event)"
        [index]="999978465123102"
        [Heading]="'Add'"
        *ngIf="PageData.currentTab == 1 && Status != 'C'"
      >
        <button type="button" class="btn font-size-12 button-btn">
          Add Customer
        </button>
      </app-add-customer-info>
    </div>
    <div class="row m-0">
      <div class="col-md-12 p-1">
        <div class="table-responsive">
          <mat-table [dataSource]="dataCustomerSource">
            <ng-container matColumnDef="Number">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerId" style="display: none">
              <mat-header-cell
                class="grid-header"
                style="max-width: 100px; max-height: 5px; display: none"
                *matHeaderCellDef
              >
                CustomerId
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 100px; display: none; max-height: 5px"
              >
                {{ row.CustomerId }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Customer
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <!--<app-los-customer-view-model [customerId]="row.CustomerId">{{row.Customer}}</app-los-customer-view-model>-->
                <app-lms-customer-view-model
                  [customerId]="row.CustomerId"
                  [index]="i"
                  *ngIf="Processid != 16 && Processid != 17"
                >
                  {{ row.Customer }}
                </app-lms-customer-view-model>
                <span *ngIf="Processid == 16 || Processid == 17">
                  {{ row.Customer }}</span
                >
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerType">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Customer Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{
                  row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="GenderAge">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Gender Age
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.GenderAge }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="PhoneNo">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Phone No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <div *ngIf="currentUser.IsMasking">{{row.PhoneNo |mask}}</div>
                <div *ngIf="!currentUser.IsMasking"> {{row.PhoneNo}}</div>
                <i *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                   class="fa fa-check-circle ml-2"
                   style="color: green"></i>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Relation_With_Hirer">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Relation With Borrower
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Relation_With_Hirer }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ExistingCustomer">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Existing
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ExistingCustomer }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                class="grid-header j-c-center"
                style="max-width: 80px"
                *matHeaderCellDef
              >
                Action
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell j-c-center"
                style="max-width: 80px"
              >
                <!-- <i class="fa fa-pencil-alt right" style="cursor: pointer;color:green;font-size:16px;" aria-label="true"
                  (click)="Get_CustomerDetailsApp(row)"></i> -->
                <app-add-customer-info
                  [TypeFrom]="TypeFrom"
                  [loan_Summary]="loanSummary"
                  [CustomerData]="row"
                  [index]="i"
                  [Heading]="'Edit'"
                  (close)="GetData($event)"
                  *ngIf="row.IsCustomerEditDelete==1 && 
                    PageData.currentTab == 1 &&
                    Status != 'C' &&
                    (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                  "
                >
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                  ></i>
                </app-add-customer-info>
                <i
                  class="fa fa-trash style-delete fas ml-2"
                  (click)="removeCustomerFromApp(row)"
                  *ngIf="row.IsCustomerEditDelete==1 && 
                    PageData.currentTab == 1 &&
                    Status != 'C' &&
                    (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                  "
                  aria-label="true"
                ></i>
                <a
                  href="javascript:void(0)"
                  (click)="goToCustomerDetail(row)"
                  *ngIf="inViewMode && row.IsCustomerEditDelete==1"
                  >view</a
                >
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-1 p-1 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Customer Address Details</h1>
      <!-- <button type="button" class="primary-btn"> Add Customer Address </button> -->
    </div>
    <div class="row m-0">
      <div class="col-md-12 p-1">
        <div class="table-responsive">
          <mat-table [dataSource]="dataCustomerAddressSource">
            <ng-container matColumnDef="CustomerId">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Customer
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Present_Address">
              <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef
                >Present Address</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
                <span
                  *ngIf="
                    row.Present_LatLong != '' &&
                    row.Present_LatLong != null &&
                    row.Present_LatLong != ','
                  "
                >
                  {{ row.Present_Address }}
                  Location-
                  <a
                    target="_blank"
                    [href]="
                      'http://maps.google.com/maps?q=' + row.Present_LatLong
                    "
                    ><i class="fa-solid fa-location-dot"></i
                  ></a>
                </span>
                <span
                  *ngIf="
                    row.Present_LatLong == '' ||
                    row.Present_LatLong == null ||
                    row.Present_LatLong != ','
                  "
                >
                  {{ row.Present_Address }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Rent_Own">
              <mat-header-cell
                class="grid-header"
                style="max-width: 80px"
                *matHeaderCellDef
                >Rent/Own</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 80px"
              >
                {{ row.Rent_Own }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TotalYearsOnAddress">
              <mat-header-cell
                class="grid-header"
                style="max-width: 100px"
                *matHeaderCellDef
              >
                How many years
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 100px"
              >
                {{ row.TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Permanent_Address">
              <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef>
                Permanent Address
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
                <span
                  *ngIf="
                    row.Permanent_LatLong != '' &&
                    row.Permanent_LatLong != null &&
                    row.Permanent_LatLong != ','
                  "
                >
                  {{ row.Permanent_Address }}
                  Location-
                  <a
                    target="_blank"
                    [href]="
                      'http://maps.google.com/maps?q=' + row.Permanent_LatLong
                    "
                    ><i class="fa-solid fa-location-dot"></i
                  ></a>
                </span>
                <span
                  *ngIf="
                    row.Permanent_LatLong == '' ||
                    row.Permanent_LatLong == null ||
                    row.Permanent_LatLong != ','
                  "
                >
                  {{ row.Permanent_Address }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Work_Address">
              <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef>
                Work Address
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
                <span
                  *ngIf="
                    row.Work_LatLong != '' &&
                    row.Work_LatLong != null &&
                    row.Work_LatLong != ','
                  "
                >
                  {{ row.Work_Address }}
                  Location-
                  <a
                    target="_blank"
                    [href]="'http://maps.google.com/maps?q=' + row.Work_LatLong"
                    ><i class="fa-solid fa-location-dot"></i
                  ></a>
                </span>
                <span
                  *ngIf="
                    row.Work_LatLong == '' ||
                    row.Work_LatLong == null ||
                    row.Work_LatLong != ','
                  "
                >
                  {{ row.Work_Address }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                class="grid-header j-c-center"
                style="max-width: 60px"
                *matHeaderCellDef
              >
                Action
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 60px"
              >
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  *ngIf="
                    !inViewMode &&
                    PageData.currentTab == 1 &&
                    Status != 'C' &&
                    (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                  "
                  aria-label="true"
                  (click)="LOS_GetCustomerAddressWeb(row)"
                ></i>
                <!-- <i class="fa fa-minus-circle font-size-15 ml-3" style="color:red;cursor: pointer;" aria-label="true"></i> -->
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedCustomerAddressColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerAddressColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
    <div
      class="row m-0 mt-1 p-1 align-items-center justify-content-between"
      *ngIf="Processid != 16 && Processid != 17"
    >
      <h1 class="fs-12 m-0">Customer KYC Details</h1>
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="addEditCustomerKyc({})"
        *ngIf="!inViewMode && PageData.currentTab == 1 && Status != 'C'"
        [hidden]="customerlist.length == 0"
      >
        Add KYC Doc
      </button>
    </div>
    <div class="row m-0" *ngIf="Processid != 16 && Processid != 17">
      <div class="col-md-12 p-1">
        <div class="table-responsive">
          <mat-table [dataSource]="dataCustomerDocSource">
            <ng-container matColumnDef="CustomerId">
              <mat-header-cell
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
              >
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Customer
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Doc_Title">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Doc Title</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Doc_Title }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Doc_Number">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                Doc Number
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                <div *ngIf="currentUser.IsMasking">{{row.Doc_Number |mask}}</div>
                <div *ngIf="!currentUser.IsMasking"> {{row.Doc_Number}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Doc_FileName">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >View</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <a
                  href="{{ customerProfileBaseUrl }}{{ row.CustomerId }}/{{
                    row.Doc_FileName
                  }}"
                  target="_blank"
                  *ngIf="row.Doc_FileName"
                >
                  <i
                    *ngIf="row.Doc_FileName"
                    class="fa fa-eye"
                    style="font-size: large"
                  ></i>
                </a>
                <a
                  href="{{ customerProfileBaseUrl }}{{ row.CustomerId }}/{{
                    row.Doc_FileName1
                  }}"
                  class="ml-2"
                  target="_blank"
                  *ngIf="row.Doc_FileName1"
                >
                  <i
                    *ngIf="row.Doc_FileName1"
                    class="fa fa-eye"
                    style="font-size: large"
                  ></i>
                </a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LastVerfiedDate">
              <mat-header-cell class="grid-header" *matHeaderCellDef
                >Verfied</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <a
                  href="javascript:void(0)"
                  (click)="ViewKYC_IsVerified(row)"
                  *ngIf="row.KYC_IsVerified"
                >
                  <i
                    *ngIf="row.KYC_IsVerified"
                    class="fa fa-eye"
                    style="font-size: large"
                  ></i>
                </a>
                <i
                  *ngIf="row.KYC_IsVerified == true"
                  class="fa fa-check ml-2"
                  style="color: green"
                ></i>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="UploadOn">
              <mat-header-cell class="grid-header" *matHeaderCellDef>
                UploadOn
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.UploadOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell
                class="grid-header j-c-center"
                style="max-width: 70px"
                *matHeaderCellDef
              >
                Action
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 70px"
              >
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  *ngIf="
                    !inViewMode &&
                    PageData.currentTab == 1 &&
                    Status != 'C' &&
                    (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                  "
                  aria-label="true"
                  (click)="addEditCustomerKyc(row)"
                ></i>
                <i
                  class="fa fa-trash style-delete fas ml-3"
                  aria-label="true"
                  *ngIf="
                    !inViewMode &&
                    PageData.currentTab == 1 &&
                    Status != 'C' &&
                    (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
                  "
                  (click)="deleteCustomerKyc(row)"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedCustomerDocColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerDocColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button
    type="button"
    (click)="CheckCustomerInfo()"
    mat-raised-button
    class="btn font-size-12 button-btn"
    [class.spinner]="loading"
    *ngIf="PageData.currentTab == 1"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>

<!-- update area below -->

<div
  class="modal fade in"
  id="ReferenceData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Reference
        </h6>
        <button
          type="button"
          (click)="onCloseReferenceData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #ldff="ngForm" novalidate class="formborder">
          <div class="row m-0 mt-1">
            <div class="col-md-2">
              <span class="required-lable"> Select Relation </span>
              <select
                name="SelectNeighbour"
                id="SelectNeighbour"
                [(ngModel)]="customerRefModel.SelectNeighbour"
                class="form-control input-text-css"
                required
                #refSelectNeighbour="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refSelectNeighbour.invalid,
                  'alert-warning':
                    refSelectNeighbour.invalid &&
                    (refSelectNeighbour.dirty ||
                      refSelectNeighbour.touched ||
                      refSelectNeighbour.untouched)
                }"
              >
                <option value="Neighbour">Neighbour</option>
                <option value="Office">Office</option>
                <option value="Friends">Friends</option>
                <option value="Colleague">Colleague</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="required-lable"> Name </span>
              <input
                type="text"
                #refFirstName="ngModel"
                [(ngModel)]="customerRefModel.RefName"
                required
                placeholder="Name"
                name="RefName"
                id="RefName"
                class="form-control input-text-css"
                #refRefName="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefName.invalid,
                  'alert-warning':
                    refRefName.invalid &&
                    (refRefName.dirty ||
                      refRefName.touched ||
                      refRefName.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable"> Mobile </span>
              <input
                type="text"
                numbersOnly
                maxlength="10"
                minlength="10"
                #refRefMobile="ngModel"
                required
                [(ngModel)]="customerRefModel.RefMobile"
                placeholder="Mobile No"
                name="RefMobile"
                id="RefMobile"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefMobile.invalid,
                  'alert-warning':
                    refRefMobile.invalid &&
                    (refRefMobile.dirty ||
                      refRefMobile.touched ||
                      refRefMobile.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <span class="required-lable"> Ref Relation </span>
              <select
                name="RefRelation"
                id="RefRelation"
                [(ngModel)]="customerRefModel.RefRelation"
                #refRefRelation="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ldff.submitted && refRefRelation.invalid,
                  'alert-warning':
                    refRefRelation.invalid &&
                    (refRefRelation.dirty ||
                      refRefRelation.touched ||
                      refRefRelation.untouched)
                }"
              >
                <option value="">Select Customer</option>
                <option
                  [value]="cus.CustomerId"
                  *ngFor="let cus of customerlist"
                >
                  {{ cus.Customer }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="col-md-3">
              <span class="required-lable"> Year of Association </span>
              <select
                name="Association"
                id="Association"
                [(ngModel)]="customerRefModel.Association"
                class="form-control input-text-css"
                required
                #refAssociation="ngModel"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refAssociation.invalid,
                  'alert-warning':
                    refAssociation.invalid &&
                    (refAssociation.dirty ||
                      refAssociation.touched ||
                      refAssociation.untouched)
                }"
              >
                <option value="">Select</option>
                <option value="1">1 Year</option>
                <option value="2">2 Years</option>
                <option value="3">3 Years</option>
                <option value="4">4 Years</option>
                <option value="5">5 Years</option>
                <option value="6">6 Years</option>
                <option value="7">7 Years</option>
                <option value="8">8 Years</option>
                <option value="9">9 Years</option>
                <option value="10">10 Years</option>
                <option value="11">11 Years</option>
                <option value="12">12 Years</option>
                <option value="13">13 Years</option>
                <option value="14">14 Years</option>
                <option value="15">15 Years</option>
                <option value="16">16 Years</option>
                <option value="17">17 Years</option>
                <option value="18">18 Years</option>
                <option value="19">19 Years</option>
                <option value="20">20 Years</option>
                <option value="21">21 Years</option>
                <option value="22">22 Years</option>
                <option value="23">23 Years</option>
                <option value="24">24 Years</option>
                <option value="25">25 Years</option>
                <option value="26">26 Years</option>
                <option value="27">27 Years</option>
                <option value="28">28 Years</option>
                <option value="29">29 Years</option>
                <option value="30">30 Years</option>
                <option value="31">31 Years</option>
                <option value="32">32 Years</option>
                <option value="33">33 Years</option>
                <option value="34">34 Years</option>
                <option value="35">35 Years</option>
                <option value="36">36 Years</option>
                <option value="37">37 Years</option>
                <option value="38">38 Years</option>
                <option value="39">39 Years</option>
                <option value="40">40 Years</option>
                <option value="41">41 Years</option>
                <option value="42">42 Years</option>
                <option value="43">43 Years</option>
                <option value="44">44 Years</option>
                <option value="45">45 Years</option>
                <option value="46">46 Years</option>
                <option value="47">47 Years</option>
                <option value="48">48 Years</option>
                <option value="49">49 Years</option>
                <option value="50">50 Years</option>
              </select>
            </div>
            <div class="col-md-9">
              <span class="required-lable"> Address </span>
              <input
                type="text"
                name="Address"
                id="Address"
                #refAddress="ngModel"
                [(ngModel)]="customerRefModel.Address"
                required
                placeholder="Address"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ldff.submitted && refAddress.invalid,
                  'alert-warning':
                    refAddress.invalid &&
                    (refAddress.dirty ||
                      refAddress.touched ||
                      refAddress.untouched)
                }"
              />
            </div>

            <div class="col-md-12">
              <hr />
            </div>

            <div class="col-md-12 text-right">
              <button
                type="button"
                (click)="OnCloseRef()"
                class="btn font-size-12 button-btn mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                *ngIf="!isSaveCustomerRef"
                (click)="onSaveCustomerRef('ref')"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ldff.form.valid"
              >
                Submit
              </button>
              <button
                type="button"
                *ngIf="isSaveCustomerRef"
                (click)="onUpdateCustomerRef('ref')"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ldff.form.valid"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addLoginFee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Login Fee
        </h6>
        <button
          type="button"
          (click)="onCloseFee()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <form
            #lff="ngForm"
            (ngSubmit)="onSubmitFee()"
            novalidate
            class="formborder mt-2"
          >
            <div class="row m-0 mt-3">
              <div class="col-md-12 mb-3">
                <span>Fee Amount (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refAmount="ngModel"
                  (blur)="calculateTax()"
                  [(ngModel)]="loginFeeModel.TFA"
                  placeholder="Fee Amount"
                  [ngClass]="{
                    'is-invalid': lff.submitted && refAmount.invalid,
                    'alert-warning':
                      refAmount.invalid &&
                      (refAmount.dirty ||
                        refAmount.touched ||
                        refAmount.untouched)
                  }"
                  name="Amount"
                  id="Amount"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-12 mb-3">
                <div class="row m-0">
                  <h6 class="fs-12 col-md-4">SGST(9%)</h6>
                  <p
                    class="lead fs-12 col-md-6 m-0"
                    *ngIf="loginFeeModel.SGST_Amount"
                  >
                    ₹ {{ loginFeeModel.SGST_Amount.toFixed(2) }}
                  </p>
                </div>
                <div class="row m-0">
                  <h6 class="fs-12 col-md-4">CGST(9%)</h6>
                  <p
                    class="lead fs-12 m-0 col-md-6"
                    *ngIf="loginFeeModel.CGST_Amount"
                  >
                    ₹ {{ loginFeeModel.CGST_Amount.toFixed(2) }}
                  </p>
                </div>
                <div class="row m-0">
                  <h6 class="fs-12 col-md-4">Tax Amount(18%)</h6>
                  <p
                    class="lead fs-12 m-0 col-md-6"
                    *ngIf="loginFeeModel.TaxAmount"
                  >
                    ₹ {{ loginFeeModel.TaxAmount.toFixed(2) }}
                  </p>
                </div>
                <div class="row m-0">
                  <h6 class="fs-12 col-md-4">Total Fee Amount</h6>
                  <p
                    class="lead fs-12 m-0 col-md-6"
                    *ngIf="loginFeeModel.Amount"
                  >
                    ₹ {{ loginFeeModel.Amount.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <span> Collection Mode </span>
                <select
                  name="CollectionMode"
                  id="CollectionMode"
                  #refCollectionMode="ngModel"
                  [ngClass]="{
                    'is-invalid': lff.submitted && refCollectionMode.invalid,
                    'alert-warning':
                      refCollectionMode.invalid &&
                      (refCollectionMode.dirty ||
                        refCollectionMode.touched ||
                        refCollectionMode.untouched)
                  }"
                  [(ngModel)]="loginFeeModel.CollectionMode"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select</option>
                  <option value="Cash">Cash</option>
                  <option value="Bank">Bank</option>
                  <option value="E-Gateway">E-Gateway</option>
                </select>
              </div>
              <div
                class="col-md-12 mb-3"
                *ngIf="
                  loginFeeModel.CollectionMode == 'Bank' ||
                  loginFeeModel.CollectionMode == 'E-Gateway'
                "
              >
                <span> Reference No</span>
                <input
                  type="text"
                  #refRemark="ngModel"
                  [(ngModel)]="loginFeeModel.Remark"
                  placeholder="Reference"
                  required
                  [ngClass]="{
                    'is-invalid': lff.submitted && refRemark.invalid,
                    'alert-warning':
                      refRemark.invalid &&
                      (refRemark.dirty ||
                        refRemark.touched ||
                        refRemark.untouched)
                  }"
                  name="Remark"
                  id="Remark"
                  class="form-control input-text-css"
                />
              </div>
            </div>
          </form>
          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              (click)="onCloseFee()"
              class="btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSubmitFee()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!lff.form.valid"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addCustomer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Customer To Application
        </h6>
        <button
          type="button"
          (click)="onCloseCustomer()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="applicationDetail">
          <div class="col-md-4">
            <B>Application No:</B> {{ applicationDetail.ApplicationNo }}
          </div>
          <div class="col-md-4">
            <B>Loan Product:</B> {{ applicationDetail.Product }}
          </div>
          <div class="col-md-4">
            <B>Branch:</B> {{ applicationDetail.Branch }}
          </div>
        </div>

        <div class="row m-0">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <ng-container *ngIf="isExistingCustomer">
          <form
            #abcf="ngForm"
            (keyup.enter)="getSearchCusList()"
            novalidate
            class="formborder"
          >
            <div class="row m-0 align-items-end">
              <div class="col-md-3 p1">
                <span> Search By: </span>
                <select
                  name="FilterSearchBy"
                  id="FilterSearchBy"
                  class="form-control input-text-css"
                  #refFilterSearchBy="ngModel"
                  [(ngModel)]="FilterSearchBy"
                  required
                  [ngClass]="{
                    'is-invalid': abcf.submitted && refFilterSearchBy.invalid,
                    'alert-warning':
                      refFilterSearchBy.invalid &&
                      (refFilterSearchBy.dirty ||
                        refFilterSearchBy.touched ||
                        refFilterSearchBy.untouched)
                  }"
                >
                  <option value="">Select</option>
                  <option value="Name">Customer Name</option>
                  <option value="Phone">Customer Phone</option>
                  <option value="Pan">PAN Card</option>
                  <option value="Passport">Passport Copy</option>
                  <option value="VoterId">Voter ID Card</option>
                  <option value="DL">Driving License</option>
                  <option value="Aadhar">Aadhaar Card</option>
                </select>
              </div>
              <div class="col-md-3">
                <span> Value: </span>
                <input
                  name="FilterSearchValue"
                  id="FilterSearchValue"
                  placeholder="Value"
                  [(ngModel)]="FilterSearchValue"
                  #refFilterSearchValue="ngModel"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid':
                      abcf.submitted && refFilterSearchValue.invalid,
                    'alert-warning':
                      refFilterSearchValue.invalid &&
                      (refFilterSearchValue.dirty ||
                        refFilterSearchValue.touched ||
                        refFilterSearchValue.untouched)
                  }"
                />
              </div>
              <div class="col-md-3">
                <button
                  class="btn font-size-12 button-btn"
                  (click)="getSearchCusList()"
                  [disabled]="!abcf.form.valid"
                >
                  Search
                </button>
              </div>
              <div class="col-md-3 text-right">
                <button
                  class="btn font-size-12 button-btn"
                  style="width: max-content"
                  (click)="registereNewCustomer()"
                >
                  Register a New Customer
                </button>
              </div>
            </div>
          </form>

          <div class="row m-0">
            <div class="col-md-12">
              <hr />
            </div>
          </div>

          <div class="mt-2" [hidden]="!dataSource">
            <div class="">
              <div class="table-responsive mt-1">
                <mat-table
                  [dataSource]="dataSource"
                  id="exportReport"
                  style="height: 330px; max-width: 100%; overflow: auto"
                >
                  <ng-container matColumnDef="CustomerId">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CustomertName">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Customer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.CustomertName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="RelationName">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Relation Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.RelationName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer_Gender">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                    >
                      Gender
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 50px"
                    >
                      {{ row.Customer_Gender }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="GenderAge">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                    >
                      Age
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 50px"
                    >
                      {{ row.GenderAge }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 150px; justify-content: center"
                      *matHeaderCellDef
                    >
                      Phone No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row"
                              class="grid-cell"
                              style="max-width: 150px; justify-content: center">
                      <div *ngIf="currentUser.IsMasking">{{row.PhoneNo |mask}}</div>
                      <div *ngIf="!currentUser.IsMasking"> {{row.PhoneNo}}</div>
                      <i *ngIf="row.Customer_PhoneNo_IsVerified == true"
                         class="fa fa-check-circle ml-2"
                         style="color: green"></i>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Present_Address">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 150px; justify-content: center"
                      *matHeaderCellDef
                    >
                      Present Address
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 150px; justify-content: center"
                    >
                      {{ row.Present_Address }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Select">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 100px; justify-content: center"
                      *matHeaderCellDef
                    >
                      Select
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 100px; justify-content: center"
                    >
                      <input
                        type="checkbox"
                        [value]="row.CustomerId"
                        [checked]="customerModel.CustomerId == row.CustomerId"
                        (change)="onCustomerChange($event, row)"
                      />
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>

          <form
            #axcf="ngForm"
            (ngSubmit)="onAddExistingCustomer()"
            novalidate
            [hidden]="!dataSource"
            class="formborder"
          >
            <div class="row m-0">
              <div class="col-md-4">
                <span class="required-lable">Customer Type</span>
                <select
                  name="Customer_Type"
                  id="Customer_Type"
                  #refCustomer_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': axcf.submitted && refCustomer_Type.invalid,
                    'alert-warning':
                      refCustomer_Type.invalid &&
                      (refCustomer_Type.dirty ||
                        refCustomer_Type.touched ||
                        refCustomer_Type.untouched)
                  }"
                  [(ngModel)]="customerModel.Customer_Type"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Customer type</option>
                  <option value="H">Borrower</option>
                  <option value="C">CO-Borrower</option>
                  <option value="G">Guarantor</option>
                </select>
              </div>
              <div class="col-md-4">
                <ng-container
                  *ngIf="
                    customerModel.Customer_Type == 'C' ||
                    customerModel.Customer_Type == 'G'
                  "
                >
                  <span class="required-lable"> Relation With Customer </span>
                  <select
                    name="Customer_Relation"
                    id="Customer_Relation"
                    #refCustomer_Relation="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        axcf.submitted && refCustomer_Relation.invalid,
                      'alert-warning':
                        refCustomer_Relation.invalid &&
                        (refCustomer_Relation.dirty ||
                          refCustomer_Relation.touched ||
                          refCustomer_Relation.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Relation"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Uncle">Uncle</option>
                    <option value="Aunty">Aunty</option>
                    <option value="Grandfather">Grandfather</option>
                    <option value="Grandmother">Grandmother</option>
                    <option value="Father In-Law">Father In-Law</option>
                    <option value="Mother In-Law">Mother In-Law</option>
                    <option value="Son In-Law">Son In-Law</option>
                    <option value="Daughter In-Law">Daughter In-Law</option>
                    <option value="Brother In-Law">Brother In-Law</option>
                    <option value="Sister In-Law">Sister In-Law</option>
                    <option value="Uncle In-Law">Uncle In-Law</option>
                    <option value="Aunty In-Law">Aunty In-Law</option>
                    <option value="Grandfather In-Law">
                      Grandfather In-Law
                    </option>
                    <option value="Grandmother In-Law">
                      Grandmother In-Law
                    </option>
                    <option value="Friend">Friend</option>
                    <option value="Other">Other</option>
                    <option value="None">None</option>
                  </select>
                </ng-container>
              </div>
              <div class="col-md-4 mt-3 text-right">
                <span>&nbsp;</span>
                <button
                  type="button"
                  (click)="onCloseCustomer()"
                  class="btn font-size-12 button-btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  (click)="onAddExistingCustomer()"
                  class="ml-4 btn font-size-12 button-btn"
                  [class.spinner]="loading"
                  [disabled]="!axcf.form.valid"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-conatainer *ngIf="!isExistingCustomer">
          <form
            #ancf="ngForm"
            class="formborder"
            (ngSubmit)="onRegisterNewCustomer()"
            novalidate
          >
            <div class="row m-0">
              <div class="col-md-4">
                <span>Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [(ngModel)]="customerModel.Type"
                  class="form-control input-text-css"
                  (change)="changeCustomertype()"
                  [disabled]="isCustomerEdit"
                >
                  <option value="Indusial">Individual</option>
                  <option value="Company">Non-Individual</option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="required-lable">Customer Type</span>
                <select
                  name="Customer_Type"
                  id="Customer_Type"
                  #refCustomer_Type="ngModel"
                  [ngClass]="{
                    'is-invalid': ancf.submitted && refCustomer_Type.invalid,
                    'alert-warning':
                      refCustomer_Type.invalid &&
                      (refCustomer_Type.dirty ||
                        refCustomer_Type.touched ||
                        refCustomer_Type.untouched)
                  }"
                  [(ngModel)]="customerModel.Customer_Type"
                  (change)="changeCustomerRelationType()"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Customer type</option>
                  <option value="H">Borrower</option>
                  <option value="C">CO-Borrower</option>
                  <option value="G">Guarantor</option>
                </select>
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  customerModel.Customer_Type == 'C' ||
                  customerModel.Customer_Type == 'G'
                "
              >
                <span class="required-lable">Relation With Customer</span>
                <select
                  name="Customer_Relation"
                  id="Customer_Relation"
                  #refCustomer_Relation="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      ancf.submitted && refCustomer_Relation.invalid,
                    'alert-warning':
                      refCustomer_Relation.invalid &&
                      (refCustomer_Relation.dirty ||
                        refCustomer_Relation.touched ||
                        refCustomer_Relation.untouched)
                  }"
                  [(ngModel)]="customerModel.Customer_Relation"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Relation</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Uncle">Uncle</option>
                  <option value="Aunty">Aunty</option>
                  <option value="Grandfather">Grandfather</option>
                  <option value="Grandmother">Grandmother</option>
                  <option value="Father In-Law">Father In-Law</option>
                  <option value="Mother In-Law">Mother In-Law</option>
                  <option value="Son In-Law">Son In-Law</option>
                  <option value="Daughter In-Law">Daughter In-Law</option>
                  <option value="Brother In-Law">Brother In-Law</option>
                  <option value="Sister In-Law">Sister In-Law</option>
                  <option value="Uncle In-Law">Uncle In-Law</option>
                  <option value="Aunty In-Law">Aunty In-Law</option>
                  <option value="Grandfather In-Law">Grandfather In-Law</option>
                  <option value="Grandmother In-Law">Grandmother In-Law</option>
                  <option value="Friend">Friend</option>
                  <option value="Other">Other</option>
                  <option value="None">None</option>
                </select>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-md-12">
                <hr />
              </div>
            </div>

            <ng-container *ngIf="customerModel.Type == 'Indusial'">
              <ng-container *ngIf="!isCustomerEdit">
                <div class="row m-0 col-md-12">
                  <div class="col-md-12 bg-success1">KYC Documents:</div>
                </div>
                <div
                  class="row m-0 mt-2"
                  *ngFor="let kyc of CustomerKYCDoc; let i = index"
                >
                  <div class="col-md-2">
                    <span class="required-lable">Document Type</span>
                    <select
                      name="KYC_DocId{{ i }}"
                      id="KYC_DocId{{ i }}"
                      #refKYC_DocId="ngModel"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refKYC_DocId.invalid,
                        'alert-warning':
                          refKYC_DocId.invalid &&
                          (refKYC_DocId.dirty ||
                            refKYC_DocId.touched ||
                            refKYC_DocId.untouched)
                      }"
                      [(ngModel)]="kyc.KYC_DocId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Document type</option>
                      <option value="1">PAN Card</option>
                      <option value="2">Electricity Bill</option>
                      <option value="3">Passport Copy</option>
                      <option value="4">Voter ID Card</option>
                      <option value="5">Driving License</option>
                      <option value="6">Aadhaar Card</option>
                      <option value="7">Other Photo ID</option>
                      <option value="21">Ration Card</option>
                      <option value="22">DLl</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Document Number</span>
                    <input
                      type="text"
                      #refKYC_DocNumber="ngModel"
                      [(ngModel)]="kyc.KYC_DocNumber"
                      placeholder="Document Number"
                      required
                      (blur)="checkDuplicate(kyc)"
                      *ngIf="kyc.KYC_DocId != 1 && kyc.KYC_DocId != 6"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refKYC_DocNumber.invalid,
                        'alert-warning':
                          refKYC_DocNumber.invalid &&
                          (refKYC_DocNumber.dirty ||
                            refKYC_DocNumber.touched ||
                            refKYC_DocNumber.untouched)
                      }"
                      name="KYC_DocNumber{{ i }}"
                      id="KYC_DocNumber{{ i }}"
                      class="form-control input-text-css"
                    />
                    <input
                      type="text"
                      #refKYC_DocNumber="ngModel"
                      [(ngModel)]="kyc.KYC_DocNumber"
                      *ngIf="kyc.KYC_DocId == 1"
                      placeholder="Document Number"
                      required
                      maxlength="10"
                      minlength="10"
                      (blur)="checkDuplicate(kyc)"
                      name="KYC_DocNumber{{ i }}"
                      id="KYC_DocNumber{{ i }}"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refKYC_DocNumber.invalid,
                        'alert-warning':
                          refKYC_DocNumber.invalid &&
                          (refKYC_DocNumber.dirty ||
                            refKYC_DocNumber.touched ||
                            refKYC_DocNumber.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                    <input
                      type="text"
                      #refKYC_DocNumber="ngModel"
                      numbersOnly
                      [(ngModel)]="kyc.KYC_DocNumber"
                      *ngIf="kyc.KYC_DocId == 6"
                      placeholder="Document Number"
                      required
                      (blur)="checkDuplicate(kyc)"
                      maxlength="12"
                      minlength="12"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refKYC_DocNumber.invalid,
                        'alert-warning':
                          refKYC_DocNumber.invalid &&
                          (refKYC_DocNumber.dirty ||
                            refKYC_DocNumber.touched ||
                            refKYC_DocNumber.untouched)
                      }"
                      name="KYC_DocNumber{{ i }}"
                      id="KYC_DocNumber{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> Document Image</span>
                    <p class="mt-1">
                      <i
                        class="fa fa-upload"
                        *ngIf="!kyc.KYC_DocFile"
                        (click)="docFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="kyc.KYC_DocFile">
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                        >{{ kyc.KYC_DocFile }}
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile(kyc, docFile)"
                        ></i>
                      </ng-container>
                    </p>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel"
                      (change)="fileChangeListener(kyc, docFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> Document Image1</span>
                    <p class="mt-1">
                      <i
                        class="fa fa-upload"
                        *ngIf="!kyc.KYC_DocFile1"
                        (click)="docFile1.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="kyc.KYC_DocFile1">
                        <i class="fa fa-file-pdf mr-1" aria-hidden="true"></i
                        >{{ kyc.KYC_DocFile1 }}
                        <i
                          class="fa fa-times ml-1"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile2(kyc, docFile1)"
                        ></i>
                      </ng-container>
                    </p>
                    <input
                      #docFile1
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel"
                      (change)="fileChangeListener2(kyc, docFile1.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-1 pt-3" style="margin-top: 5px">
                    <button
                      type="button"
                      class="remove"
                      (click)="removeKYC(i)"
                      *ngIf="
                        i < CustomerKYCDoc.length && CustomerKYCDoc.length > 1
                      "
                    >
                      -
                    </button>
                    <button
                      type="button"
                      class="addmore"
                      (click)="addMoreKYC()"
                      *ngIf="i == CustomerKYCDoc.length - 1"
                    >
                      +
                    </button>
                  </div>
                </div>
              </ng-container>

              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-12 bg-success1">
                  Demographic Information:
                </div>
              </div>

              <div class="row m-0 mt-2">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-3">
                      <span class="required-lable">First Name</span>
                      <input
                        type="text"
                        #refCustomer_FirstName="ngModel"
                        [(ngModel)]="customerModel.Customer_FirstName"
                        placeholder="First Name"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_FirstName.invalid,
                          'alert-warning':
                            refCustomer_FirstName.invalid &&
                            (refCustomer_FirstName.dirty ||
                              refCustomer_FirstName.touched ||
                              refCustomer_FirstName.untouched)
                        }"
                        name="Customer_FirstName"
                        id="Customer_FirstName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>Last Name</span>
                      <input
                        type="text"
                        #refCustomer_LastName="ngModel"
                        [(ngModel)]="customerModel.Customer_LastName"
                        placeholder="Last Name"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_LastName.invalid,
                          'alert-warning':
                            refCustomer_LastName.invalid &&
                            (refCustomer_LastName.dirty ||
                              refCustomer_LastName.touched ||
                              refCustomer_LastName.untouched)
                        }"
                        name="Customer_LastName"
                        id="Customer_LastName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Relation</span>
                      <select
                        name="Customer_Relation_Type"
                        id="Customer_Relation_Type"
                        #refCustomer_Relation_Type="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Relation_Type.invalid,
                          'alert-warning':
                            refCustomer_Relation_Type.invalid &&
                            (refCustomer_Relation_Type.dirty ||
                              refCustomer_Relation_Type.touched ||
                              refCustomer_Relation_Type.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Relation_Type"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select</option>
                        <option value="C/O">C/O</option>
                        <option value="S/O">S/O</option>
                        <option value="D/O">D/O</option>
                        <option value="W/O">W/O</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">First Name</span>
                      <input
                        type="text"
                        #refCustomer_Relation_FirstName="ngModel"
                        [(ngModel)]="customerModel.Customer_Relation_FirstName"
                        placeholder="First Name"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refCustomer_Relation_FirstName.invalid,
                          'alert-warning':
                            refCustomer_Relation_FirstName.invalid &&
                            (refCustomer_Relation_FirstName.dirty ||
                              refCustomer_Relation_FirstName.touched ||
                              refCustomer_Relation_FirstName.untouched)
                        }"
                        name="Customer_Relation_FirstName"
                        id="Customer_Relation_FirstName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>Last Name</span>
                      <input
                        type="text"
                        #refCustomer_Relation_LastName="ngModel"
                        [(ngModel)]="customerModel.Customer_Relation_LastName"
                        placeholder="Last Name"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refCustomer_Relation_LastName.invalid,
                          'alert-warning':
                            refCustomer_Relation_LastName.invalid &&
                            (refCustomer_Relation_LastName.dirty ||
                              refCustomer_Relation_LastName.touched ||
                              refCustomer_Relation_LastName.untouched)
                        }"
                        name="Customer_Relation_LastName"
                        id="Customer_Relation_LastName"
                        class="form-control input-text-css"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-3">
                      <span class="required-lable">Gender</span>
                      <select
                        name="Customer_Gender"
                        id="Customer_Gender"
                        #refCustomer_Gender="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Gender.invalid,
                          'alert-warning':
                            refCustomer_Gender.invalid &&
                            (refCustomer_Gender.dirty ||
                              refCustomer_Gender.touched ||
                              refCustomer_Gender.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Gender"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>

                      <!-- <div class="">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="gender" id="gender1" #refCustomer_Gender="ngModel"
                            [ngClass]="{ 'is-invalid': ancf.submitted && refCustomer_Gender.invalid, 'alert-warning': refCustomer_Gender.invalid  && (refCustomer_Gender.dirty || refCustomer_Gender.touched || refCustomer_Gender.untouched) }"
                            [(ngModel)]="customerModel.Customer_Gender" required value="M">
                          <label class="form-check-label" for="gender1">Male</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="gender" id="gender2" #refCustomer_Gender="ngModel"
                            [ngClass]="{ 'is-invalid': ancf.submitted && refCustomer_Gender.invalid, 'alert-warning': refCustomer_Gender.invalid  && (refCustomer_Gender.dirty || refCustomer_Gender.touched || refCustomer_Gender.untouched) }"
                            [(ngModel)]="customerModel.Customer_Gender" required value="F">
                          <label class="form-check-label" for="gender2">Female</label>
                        </div>
                      </div> -->
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">DOB</span>
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          name="Customer_DOB"
                          id="Customer_DOB"
                          #refCustomer_DOB="ngModel"
                          required
                          (dateChange)="getAge($event)"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_DOB.invalid,
                            'alert-warning':
                              refCustomer_DOB.invalid &&
                              (refCustomer_DOB.dirty ||
                                refCustomer_DOB.touched ||
                                refCustomer_DOB.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_DOB"
                          class="form-control input-text-css"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </div>
                      <!-- <input
                        name="Customer_DOB"
                        id="Customer_DOB"
                        #refCustomer_DOB="ngModel"
                        required
                        (change)="getAge($event)"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_DOB.invalid,
                          'alert-warning':
                            refCustomer_DOB.invalid &&
                            (refCustomer_DOB.dirty ||
                              refCustomer_DOB.touched ||
                              refCustomer_DOB.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_DOB"
                        value="{{
                          customerModel.Customer_DOB | date : 'dd/MM/yyyy'
                        }}"
                        placeholder="DD/MM/YYYY"
                        class="form-control input-text-css"
                        [(bsValue)]="customerModel.Customer_DOB"
                        bsDatepicker
                      /> -->
                      <!-- <input name="Customer_DOB" id="Customer_DOB" #refCustomer_DOB="ngModel" required (ngModelChange)="getAge($event)"
                        [ngClass]="{ 'is-invalid': ancf.submitted && refCustomer_DOB.invalid, 'alert-warning': refCustomer_DOB.invalid  && (refCustomer_DOB.dirty || refCustomer_DOB.touched || refCustomer_DOB.untouched) }"
                        [(ngModel)]="customerModel.Customer_DOB"
                        value="{{ customerModel.Customer_DOB | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                        class="form-control input-text-css" [(bsValue)]="customerModel.Customer_DOB" bsDatepicker> -->
                    </div>
                    <div class="col-md-2">
                      <span> Age</span>
                      <input
                        name="Age"
                        id="Age"
                        disabled
                        value="{{ Age }}"
                        placeholder="Age"
                        class="form-control input-text-css"
                      />
                    </div>

                    <div
                      [ngClass]="
                        OTPButton || customerModel.Customer_PhoneNo_IsVerified
                          ? 'col-md-2'
                          : 'col-md-3'
                      "
                    >
                      <span class="required-lable">Primary Contact No.</span>
                      <!-- (blur)="checkPhonenumber()" -->
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        (blur)="checkPhonenumber()"
                        #refCustomer_PhoneNo="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo"
                        placeholder="Primary Contact No"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_PhoneNo.invalid,
                          'alert-warning':
                            refCustomer_PhoneNo.invalid &&
                            (refCustomer_PhoneNo.dirty ||
                              refCustomer_PhoneNo.touched ||
                              refCustomer_PhoneNo.untouched)
                        }"
                        name="Customer_PhoneNo"
                        id="Customer_PhoneNo"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-1" [hidden]="!OTPButton">
                      <span> &nbsp;</span>
                      <button
                        type="button"
                        (click)="ongetotp()"
                        [hidden]="!OTPButton"
                        *ngIf="!customerModel.Customer_PhoneNo_IsVerified"
                        class="btn font-size-12 button-btn"
                        style="width: max-width"
                      >
                        Get OTP
                      </button>
                    </div>
                    <div
                      class="col-md-1"
                      *ngIf="customerModel.Customer_PhoneNo_IsVerified"
                    >
                      <i
                        class="fa fa-check-circle"
                        style="color: green; font-size: 24px"
                      ></i>
                    </div>
                    <div class="col-md-2">
                      <span> Alternate No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_PhoneNo1="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo1"
                        placeholder="Alternate No."
                        name="Customer_PhoneNo1"
                        id="Customer_PhoneNo1"
                        class="form-control input-text-css"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <span>Customer Photo</span>
                  <input
                    #cpFile
                    type="file"
                    [multiple]="false"
                    accept="image/*"
                    (change)="fileChangeListener1(cpFile.files)"
                    style="display: none"
                  />
                  <p class="mt-1" *ngIf="!customerModel.Customer_ProfilePic">
                    Upload Photo:
                    <i
                      class="fa fa-upload ml-2 mt-1"
                      (click)="cpFile.click()"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <ng-container *ngIf="customerModel.Customer_ProfilePic">
                    <div class="c-img mr-2">
                      <img
                        src="data:image/png;base64,{{ customerModel.DocData }}"
                        alt=""
                        *ngIf="customerModel.DocData"
                        height="85"
                      />

                      <img
                        src="{{ customerProfileBaseUrl }}{{
                          customerModel.CustomerId
                        }}/{{ customerModel.Customer_ProfilePic }}"
                        alt=""
                        *ngIf="!customerModel.DocData"
                        height="85"
                      />
                      <i
                        class="fa fa-times ml-1"
                        aria-hidden="true"
                        style="cursor: pointer; vertical-align: top"
                        (click)="removeFile1(cpFile)"
                      ></i>
                    </div>
                    <!-- <div class="mt-1">
                      {{customerModel.Customer_ProfilePic}}
                      <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer;" (click)="removeFile1(cpFile)"></i>
                    </div> -->
                  </ng-container>
                </div>
              </div>
              <div class="row m-0 mt-2">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-3">
                      <span>Email</span>
                      <input
                        type="email"
                        #refCustomer_Email="ngModel"
                        [(ngModel)]="customerModel.Customer_Email"
                        placeholder="Email"
                        name="Customer_Email"
                        id="Customer_Email"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> WhatsApp No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_WhatsAppNo="ngModel"
                        [(ngModel)]="customerModel.Customer_WhatsAppNo"
                        placeholder="WhatsApp No"
                        name="Customer_WhatsAppNo"
                        id="Customer_WhatsAppNo"
                        class="form-control input-text-css"
                      />
                    </div>

                    <div class="col-md-2">
                      <span class="required-lable">Marital Status</span>
                      <select
                        name="Customer_MaritalStatus"
                        id="Customer_MaritalStatus"
                        #refCustomer_MaritalStatus="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_MaritalStatus.invalid,
                          'alert-warning':
                            refCustomer_MaritalStatus.invalid &&
                            (refCustomer_MaritalStatus.dirty ||
                              refCustomer_MaritalStatus.touched ||
                              refCustomer_MaritalStatus.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_MaritalStatus"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                        <option value="Widow">Widow/Widower</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Separated">Separated</option>
                        <option value="Never Married">Never Married</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">Occupation</span>
                      <select
                        name="Customer_Occupation"
                        id="Customer_Occupation"
                        #refCustomer_Occupation="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Occupation.invalid,
                          'alert-warning':
                            refCustomer_Occupation.invalid &&
                            (refCustomer_Occupation.dirty ||
                              refCustomer_Occupation.touched ||
                              refCustomer_Occupation.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Occupation"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Occupation</option>
                        <option
                          *ngFor="let row of OccupationData"
                          [value]="row.Id"
                        >
                          {{ row.Value }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Customer Religion</span>
                      <select
                        name="Customer_Religion"
                        id="Customer_Religion"
                        #refCustomer_Religion="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Religion.invalid,
                          'alert-warning':
                            refCustomer_Religion.invalid &&
                            (refCustomer_Religion.dirty ||
                              refCustomer_Religion.touched ||
                              refCustomer_Religion.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Religion"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Religion</option>
                        <option value="Hindus">Hindus</option>
                        <option value="Muslims">Muslims</option>
                        <option value="Christians">Christians</option>
                        <option value="Sikhs">Sikhs</option>
                        <option value="Buddhists">Buddhists</option>
                        <option value="Jains">Jains</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Customer Cast</span>
                  <select
                    name="Customer_Cast"
                    id="Customer_Cast"
                    #refCustomer_Cast="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refCustomer_Cast.invalid,
                      'alert-warning':
                        refCustomer_Cast.invalid &&
                        (refCustomer_Cast.dirty ||
                          refCustomer_Cast.touched ||
                          refCustomer_Cast.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Cast"
                    required
                  >
                    <option value="">Select Cast</option>
                    <option value="General">General</option>
                    <option value="OBC">OBC</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>

              <!-------------------------------------Customer address div start---------------------------------------------->
              <ng-container *ngIf="!isCustomerEdit">
                <div class="row m-0 mt-2 col-md-12">
                  <div class="col-md-12 bg-success1">Customer Address:</div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-12">
                    <h3 class="fw-7 fs-14">Present Address :</h3>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-md-4">
                    <span class="required-lable">Address</span>
                    <input
                      type="text"
                      #refPresentAddress="ngModel"
                      [(ngModel)]="customerAddressModel.Address"
                      placeholder="Address"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPresentAddress.invalid,
                        'alert-warning':
                          refPresentAddress.invalid &&
                          (refPresentAddress.dirty ||
                            refPresentAddress.touched ||
                            refPresentAddress.untouched)
                      }"
                      name="PresentAddress"
                      id="PresentAddress"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">LandMark</span>
                    <input
                      type="text"
                      #refPresentLandMark="ngModel"
                      [(ngModel)]="customerAddressModel.LandMark"
                      placeholder="LandMark"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPresentLandMark.invalid,
                        'alert-warning':
                          refPresentLandMark.invalid &&
                          (refPresentLandMark.dirty ||
                            refPresentLandMark.touched ||
                            refPresentLandMark.untouched)
                      }"
                      name="PresentCustLandMark"
                      id="PresentCustLandMark"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">PinCode</span>
                    <input
                      type="text"
                      #refPresentPinCode="ngModel"
                      [(ngModel)]="customerAddressModel.PinCode"
                      placeholder="PinCode"
                      required
                      maxlength="6"
                      minlength="6"
                      numbersOnly
                      name="PresentPinCode"
                      id="PresentPinCode"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          caf.submitted && refPresentPinCode.invalid,
                        'alert-warning':
                          refPresentPinCode.invalid &&
                          (refPresentPinCode.dirty ||
                            refPresentPinCode.touched ||
                            refPresentPinCode.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">State</span>
                    <select
                      name="PresentState"
                      id="PresentState"
                      #refPresentState="ngModel"
                      (change)="GetDistrickDropdownPresent()"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refPresentState.invalid,
                        'alert-warning':
                          refPresentState.invalid &&
                          (refPresentState.dirty ||
                            refPresentState.touched ||
                            refPresentState.untouched)
                      }"
                      [(ngModel)]="customerAddressModel.StateId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">District</span>
                    <select
                      name="PresentsDistrict"
                      id="PresentsDistrict"
                      #refPresentDistrict="ngModel"
                      (change)="GetTehasilDropdownPresent()"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPresentDistrict.invalid,
                        'alert-warning':
                          refPresentDistrict.invalid &&
                          (refPresentDistrict.dirty ||
                            refPresentDistrict.touched ||
                            refPresentDistrict.untouched)
                      }"
                      [(ngModel)]="customerAddressModel.DistrictId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownPresent"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Tehsil</span>
                    <select
                      name="PresentsTehsil"
                      id="PresentsTehsil"
                      #refPresentTehsil="ngModel"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPresentTehsil.invalid,
                        'alert-warning':
                          refPresentTehsil.invalid &&
                          (refPresentTehsil.dirty ||
                            refPresentTehsil.touched ||
                            refPresentTehsil.untouched)
                      }"
                      [(ngModel)]="customerAddressModel.TehsilId"
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownPresent"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">No of Year Living Here</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="3"
                      name="PresentTotalYearsOnAddress"
                      id="PresentTotalYearsOnAddress"
                      class="form-control input-text-css"
                      required
                      #refPresentTotalYearsOnAddress="ngModel"
                      [(ngModel)]="customerAddressModel.NoOfLiving"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refPresentTotalYearsOnAddress.invalid,
                        'alert-warning':
                          refPresentTotalYearsOnAddress.invalid &&
                          (refPresentTotalYearsOnAddress.dirty ||
                            refPresentTotalYearsOnAddress.touched ||
                            refPresentTotalYearsOnAddress.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Rent/Own</span>
                    <select
                      name="PresentAddressRentBuy"
                      id="PresentAddressRentBuy"
                      #refPresentAddressRentBuy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPresentAddressRentBuy.invalid,
                        'alert-warning':
                          refPresentAddressRentBuy.invalid &&
                          (refPresentAddressRentBuy.dirty ||
                            refPresentAddressRentBuy.touched ||
                            refPresentAddressRentBuy.untouched)
                      }"
                      [(ngModel)]="customerAddressModel.RentOwn"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Own">Own</option>
                      <option value="Rent">Rent</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Distance To Branch (KM)</span>
                    <input
                      type="text"
                      #refPresentNearstBranchDistance_KM="ngModel"
                      [(ngModel)]="customerAddressModel.DistaneBranch"
                      placeholder="Distance From Branch"
                      required
                      maxlength="3"
                      minlength="1"
                      numbersOnly
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refPresentNearstBranchDistance_KM.invalid,
                        'alert-warning':
                          refPresentNearstBranchDistance_KM.invalid &&
                          (refPresentNearstBranchDistance_KM.dirty ||
                            refPresentNearstBranchDistance_KM.touched ||
                            refPresentNearstBranchDistance_KM.untouched)
                      }"
                      name="PresentNearstBranchDistance_KM"
                      id="PresentNearstBranchDistance_KM"
                    />
                  </div>
                  <div class="col-md-3 pt-1">
                    <span> &nbsp;</span>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="PresentIsCommunicationAddress"
                        class="form-check-input"
                        id="PresentIsCommunicationAddress"
                        [(ngModel)]="
                          customerAddressModel.PresentIsCummunicationAddress
                        "
                      />
                      <label
                        class="form-check-label"
                        for="PresentIsCommunicationAddress"
                        style="margin-top: 2px"
                      >
                        Is This Cummunication Address
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12">
                    <hr />
                  </div>
                </div>

                <div class="row m-0 mt-1">
                  <div class="col-md-2">
                    <h3 class="fw-7 fs-14">Permanent Address :</h3>
                  </div>
                  <div class="col-md-10">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="exampleCheck2"
                        class="form-check-input"
                        id="exampleCheck2"
                        [(ngModel)]="
                          customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress
                        "
                        (change)="checkCAddressSame1($event)"
                      />
                      <label
                        class="form-check-label"
                        for="exampleCheck2"
                        style="margin-top: 2px"
                      >
                        Select if Permanent Address same as Present Address
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-md-4">
                    <span class="required-lable">Address</span>
                    <input
                      type="text"
                      #refPermanentAddress="ngModel"
                      [(ngModel)]="customerPermanentAddressModel.Address"
                      placeholder="Address"
                      name="PermanentAddress"
                      id="PermanentAddress"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentAddress.invalid,
                        'alert-warning':
                          refPermanentAddress.invalid &&
                          (refPermanentAddress.dirty ||
                            refPermanentAddress.touched ||
                            refPermanentAddress.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">LandMark</span>
                    <input
                      type="text"
                      #refPermanentLandMark="ngModel"
                      [(ngModel)]="customerPermanentAddressModel.LandMark"
                      placeholder="LandMark"
                      name="PermanentLandMark"
                      id="PermanentLandMark"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentLandMark.invalid,
                        'alert-warning':
                          refPermanentLandMark.invalid &&
                          (refPermanentLandMark.dirty ||
                            refPermanentLandMark.touched ||
                            refPermanentLandMark.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">PinCode</span>
                    <input
                      type="text"
                      #refPermanentPinCode="ngModel"
                      [(ngModel)]="customerPermanentAddressModel.PinCode"
                      placeholder="PinCode"
                      maxlength="6"
                      minlength="6"
                      numbersOnly
                      name="PermanentPinCode"
                      id="PermanentPinCode"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentPinCode.invalid,
                        'alert-warning':
                          refPermanentPinCode.invalid &&
                          (refPermanentPinCode.dirty ||
                            refPermanentPinCode.touched ||
                            refPermanentPinCode.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">State</span>
                    <select
                      name="PermanentState"
                      id="PermanentState"
                      #refPermanentState="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="customerPermanentAddressModel.StateId"
                      (change)="GetDistrickDropdownPermanent()"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentState.invalid,
                        'alert-warning':
                          refPermanentState.invalid &&
                          (refPermanentState.dirty ||
                            refPermanentState.touched ||
                            refPermanentState.untouched)
                      }"
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">District</span>
                    <select
                      name="PermanentDistrict"
                      id="PermanentDistrict"
                      #refPermanentDistrict="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="customerPermanentAddressModel.DistrictId"
                      (change)="GetTehasilDropdownPermanent()"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentDistrict.invalid,
                        'alert-warning':
                          refPermanentDistrict.invalid &&
                          (refPermanentDistrict.dirty ||
                            refPermanentDistrict.touched ||
                            refPermanentDistrict.untouched)
                      }"
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownPermanent"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Tehsil</span>
                    <select
                      name="PermanentTehsil"
                      id="PermanentTehsil"
                      #refPermanentTehsil="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="customerPermanentAddressModel.TehsilId"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentTehsil.invalid,
                        'alert-warning':
                          refPermanentTehsil.invalid &&
                          (refPermanentTehsil.dirty ||
                            refPermanentTehsil.touched ||
                            refPermanentTehsil.untouched)
                      }"
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownPermanent"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">No of Year Living Here</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="3"
                      name="PermanentTotalYearsOnAddress"
                      id="PermanentTotalYearsOnAddress"
                      class="form-control input-text-css"
                      #refPermanentTotalYearsOnAddress="ngModel"
                      [(ngModel)]="customerPermanentAddressModel.NoOfLiving"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refPermanentTotalYearsOnAddress.invalid,
                        'alert-warning':
                          refPermanentTotalYearsOnAddress.invalid &&
                          (refPermanentTotalYearsOnAddress.dirty ||
                            refPermanentTotalYearsOnAddress.touched ||
                            refPermanentTotalYearsOnAddress.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Rent/Own</span>
                    <select
                      name="PermanentAddressRentBuy"
                      id="PermanentAddressRentBuy"
                      class="form-control input-text-css"
                      #refPermanentAddressRentBuy="ngModel"
                      [(ngModel)]="customerPermanentAddressModel.RentOwn"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPermanentAddressRentBuy.invalid,
                        'alert-warning':
                          refPermanentAddressRentBuy.invalid &&
                          (refPermanentAddressRentBuy.dirty ||
                            refPermanentAddressRentBuy.touched ||
                            refPermanentAddressRentBuy.untouched)
                      }"
                    >
                      <option value="">Select</option>
                      <option value="Own">Own</option>
                      <option value="Rent">Rent</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Distance To Branch (KM)</span>
                    <input
                      type="text"
                      #refPermanentNearstBranchDistance_KM="ngModel"
                      placeholder="Distance From Branch"
                      [(ngModel)]="customerPermanentAddressModel.DistanceBranch"
                      maxlength="3"
                      minlength="1"
                      numbersOnly
                      name="PermanentNearstBranchDistance_KM"
                      id="PermanentNearstBranchDistance_KM"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refPermanentNearstBranchDistance_KM.invalid,
                        'alert-warning':
                          refPermanentNearstBranchDistance_KM.invalid &&
                          (refPermanentNearstBranchDistance_KM.dirty ||
                            refPermanentNearstBranchDistance_KM.touched ||
                            refPermanentNearstBranchDistance_KM.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> &nbsp;</span>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="PermanentIsCommunicationAddress"
                        id="PermanentIsCommunicationAddress"
                        [(ngModel)]="
                          customerPermanentAddressModel.IsCummunicationAddress
                        "
                        class="form-check-input"
                      />
                      <label
                        class="form-check-label"
                        for="PermanentIsCommunicationAddress"
                        style="margin-top: 2px"
                      >
                        Is This Cummunication Address
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12">
                    <hr />
                  </div>
                </div>

                <div class="row m-0 mt-1">
                  <div class="col-md-12">
                    <h3 class="fw-7 fs-14">Work Address :</h3>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-md-4">
                    <span> Address</span>
                    <input
                      type="text"
                      #refWorkAddress="ngModel"
                      [(ngModel)]="customerWorkAddressModel.Address"
                      placeholder="Address"
                      name="WorkAddress"
                      id="WorkAddress"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> LandMark</span>
                    <input
                      type="text"
                      #refWorkLandMark="ngModel"
                      [(ngModel)]="customerWorkAddressModel.LandMark"
                      placeholder="LandMark"
                      name="WorkLandMark"
                      id="WorkLandMark"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-2">
                    <span> PinCode</span>
                    <input
                      type="text"
                      #refWorkPinCode="ngModel"
                      [(ngModel)]="customerWorkAddressModel.PinCode"
                      placeholder="PinCode"
                      maxlength="6"
                      minlength="6"
                      numbersOnly
                      name="WorkPinCode"
                      id="WorkPinCode"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> State</span>
                    <select
                      name="WorkState"
                      id="WorkState"
                      #refWorkState="ngModel"
                      [(ngModel)]="customerWorkAddressModel.StateId"
                      class="form-control input-text-css"
                      (change)="GetDistrickDropdownWork()"
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-2">
                    <span>District</span>
                    <select
                      name="WorkDistrict"
                      id="WorkDistrict"
                      #refWorkDistrict="ngModel"
                      [(ngModel)]="customerWorkAddressModel.DistrictId"
                      class="form-control input-text-css"
                      (change)="GetTehasilDropdownWork()"
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownWork"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span>Tehsil</span>
                    <select
                      name="WorkTehsil"
                      id="WorkTehsil"
                      #refWorkTehsil="ngModel"
                      [(ngModel)]="customerWorkAddressModel.TehsilId"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownWork"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span>No of Year Working Here</span>
                    <!--<select
    name="WorkTotalYearsOnAddress"
    id="WorkTotalYearsOnAddress"
    class="form-control input-text-css"
    #refWorkTotalYearsOnAddress="ngModel"
    [(ngModel)]="customerWorkAddressModel.NoOfLiving"
  >
    <option value="">Select</option>
    <option
      value="0"
      *ngIf="customerModel.Type == 'Indusial'"
    >
      By birth
    </option>
    <option value="1">1 Year</option>
    <option value="2">2 Years</option>
    <option value="3">3 Years</option>
    <option value="4">4 Years</option>
    <option value="5">5 Years</option>
    <option value="6">6 Years</option>
    <option value="7">7 Years</option>
    <option value="8">8 Years</option>
    <option value="9">9 Years</option>
    <option value="10">10 Years</option>
    <option value="11">11 Years</option>
    <option value="12">12 Years</option>
    <option value="13">13 Years</option>
    <option value="14">14 Years</option>
    <option value="15">15 Years</option>
    <option value="16">16 Years</option>
    <option value="17">17 Years</option>
    <option value="18">18 Years</option>
    <option value="19">19 Years</option>
    <option value="20">20 Years</option>
  </select>-->
                    <input type="text"
                           numbersOnly
                           maxlength="3"
                           name="WorkTotalYearsOnAddress4"
                           id="WorkTotalYearsOnAddress4"
                           [(ngModel)]="customerWorkAddressModel.NoOfLiving"
                           class="form-control input-text-css" />
                  </div>
                  <div class="col-md-1">
                    <span>Rent/Own</span>
                    <select
                      name="WorkAddressRentBuy"
                      id="WorkAddressRentBuy"
                      #refWorkAddressRentBuy="ngModel"
                      [(ngModel)]="customerWorkAddressModel.RentOwn"
                      class="form-control input-text-css"
                    >
                      <option value="">Select</option>
                      <option value="Own">Own</option>
                      <option value="Rent">Rent</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span>Distance To Branch (KM)</span>
                    <input
                      type="text"
                      #refWorkNearstBranchDistance_KM="ngModel"
                      placeholder="Distance From Branch"
                      [(ngModel)]="customerWorkAddressModel.DistanceBranch"
                      maxlength="3"
                      minlength="1"
                      numbersOnly
                      name="WorkNearstBranchDistance_KM"
                      id="WorkNearstBranchDistance_KM"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span> &nbsp;</span>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="WorkIsCommunicationAddress"
                        id="WorkIsCommunicationAddress"
                        class="form-check-input"
                        [(ngModel)]="
                          customerWorkAddressModel.IsCummunicationAddress
                        "
                      />
                      <label
                        class="form-check-label"
                        for="WorkIsCommunicationAddress"
                        style="margin-top: 2px"
                      >
                        Is This Cummunication Address
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="customerModel.Type == 'Company'">
              <ng-container *ngIf="!isCustomerEdit">
                <div class="row m-0 col-md-12">
                  <div class="col-md-12 bg-success1">KYC Documents:</div>
                </div>
                <div
                  class="row mt-2 m-0"
                  *ngFor="let kyc of firmKYCDoc; let i = index"
                >
                  <div class="col-md-3">
                    <span class="required-lable">Document Type</span>
                    <select
                      name="DocId{{ i }}"
                      id="DocId{{ i }}"
                      #refDocId="ngModel"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refDocId.invalid,
                        'alert-warning':
                          refDocId.invalid &&
                          (refDocId.dirty ||
                            refDocId.touched ||
                            refDocId.untouched)
                      }"
                      [(ngModel)]="kyc.DocId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Document type</option>
                      <option value="1">PAN Card</option>
                      <option value="28">Firm Registration No</option>
                      <option value="29">GST No</option>
                      <option value="30">TAN No</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Document Number</span>
                    <input
                      type="text"
                      #refDocNo="ngModel"
                      [(ngModel)]="kyc.DocNo"
                      placeholder="Document Number"
                      required
                      *ngIf="kyc.DocId != 1"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refDocNo.invalid,
                        'alert-warning':
                          refDocNo.invalid &&
                          (refDocNo.dirty ||
                            refDocNo.touched ||
                            refDocNo.untouched)
                      }"
                      name="DocNo{{ i }}"
                      id="DocNo{{ i }}"
                      class="form-control input-text-css"
                    />
                    <input
                      type="text"
                      #refDocNo="ngModel"
                      [(ngModel)]="kyc.DocNo"
                      placeholder="Document Number"
                      required
                      name="DocNo{{ i }}"
                      id="DocNo{{ i }}"
                      *ngIf="kyc.DocId == 1"
                      maxlength="10"
                      minlength="10"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refDocNo.invalid,
                        'alert-warning':
                          refDocNo.invalid &&
                          (refDocNo.dirty ||
                            refDocNo.touched ||
                            refDocNo.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span> Document Image</span>
                    <p class="m-0 mt-2 row">
                      <i
                        class="fa fa-upload ml-2"
                        *ngIf="!kyc.DocImage"
                        (click)="docFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="kyc.DocImage">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                          >{{ kyc.DocImage }}
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeFileFirm(kyc, docFile)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel"
                      (change)="fileChangeListenerFirm(kyc, docFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-2 pt-3" style="margin-top: 5px">
                    <button
                      type="button"
                      class="remove"
                      (click)="removeFirmKYC(i)"
                      *ngIf="i < firmKYCDoc.length && firmKYCDoc.length > 1"
                    >
                      -
                    </button>
                    <button
                      type="button"
                      class="addmore"
                      (click)="addFirmKyc()"
                      *ngIf="i == firmKYCDoc.length - 1"
                    >
                      +
                    </button>
                  </div>
                </div>
              </ng-container>

              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-12 bg-success1">Director's Information:</div>
              </div>
              <div
                class="row m-0 mt-2"
                *ngFor="let partner of firmPartners; let i = index"
              >
                <div class="col-md-2">
                  <span class="required-lable">Director Name</span>
                  <input
                    type="text"
                    #refPartnerName="ngModel"
                    [(ngModel)]="partner.PartnerName"
                    placeholder="Director Name"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refPartnerName.invalid,
                      'alert-warning':
                        refPartnerName.invalid &&
                        (refPartnerName.dirty ||
                          refPartnerName.touched ||
                          refPartnerName.untouched)
                    }"
                    name="PartnerName{{ i }}"
                    id="PartnerName{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Gender </span>
                  <select
                    name="PartnerGender{{ i }}"
                    id="PartnerGender{{ i }}"
                    #refPartnerGender="ngModel"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refPartnerGender.invalid,
                      'alert-warning':
                        refPartnerGender.invalid &&
                        (refPartnerGender.dirty ||
                          refPartnerGender.touched ||
                          refPartnerGender.untouched)
                    }"
                    [(ngModel)]="partner.PartnerGender"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
                <div class="col-md-1">
                  <span class="required-lable">Age</span>
                  <input
                    type="text"
                    numbersOnly
                    #refPartnerAge="ngModel"
                    maxlength="2"
                    minlength="2"
                    [(ngModel)]="partner.PartnerAge"
                    placeholder="Age"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refPartnerAge.invalid,
                      'alert-warning':
                        refPartnerAge.invalid &&
                        (refPartnerAge.dirty ||
                          refPartnerAge.touched ||
                          refPartnerAge.untouched)
                    }"
                    name="PartnerAge{{ i }}"
                    id="PartnerAge{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Designation</span>
                  <input
                    type="text"
                    #refPartnerDesignation="ngModel"
                    [(ngModel)]="partner.PartnerDesignation"
                    placeholder="Director Designation"
                    required
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refPartnerDesignation.invalid,
                      'alert-warning':
                        refPartnerDesignation.invalid &&
                        (refPartnerDesignation.dirty ||
                          refPartnerDesignation.touched ||
                          refPartnerDesignation.untouched)
                    }"
                    name="PartnerDesignation{{ i }}"
                    id="PartnerDesignation{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">PhoneNo</span>
                  <input
                    type="text"
                    #refPartnerPhoneNo="ngModel"
                    numbersOnly
                    maxlength="10"
                    minlength="10"
                    [(ngModel)]="partner.PartnerPhoneNo"
                    placeholder="Director PhoneNo"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refPartnerPhoneNo.invalid,
                      'alert-warning':
                        refPartnerPhoneNo.invalid &&
                        (refPartnerPhoneNo.dirty ||
                          refPartnerPhoneNo.touched ||
                          refPartnerPhoneNo.untouched)
                    }"
                    name="PartnerPhoneNo{{ i }}"
                    id="PartnerPhoneNo{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Share %</span>
                  <input
                    type="text"
                    numbersOnly
                    #refPartnerShare="ngModel"
                    maxlength="3"
                    [(ngModel)]="partner.PartnerShare"
                    placeholder="Director Share"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refPartnerShare.invalid,
                      'alert-warning':
                        refPartnerShare.invalid &&
                        (refPartnerShare.dirty ||
                          refPartnerShare.touched ||
                          refPartnerShare.untouched)
                    }"
                    name="PartnerShare{{ i }}"
                    id="PartnerShare{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-1 pt-3">
                  <button
                    type="button"
                    class="remove"
                    (click)="removeFirmPartner(i)"
                    *ngIf="i < firmPartners.length && firmPartners.length > 1"
                  >
                    -
                  </button>
                  <button
                    type="button"
                    class="addmore"
                    (click)="addFirmPartner()"
                    *ngIf="i == firmPartners.length - 1"
                  >
                    +
                  </button>
                </div>
              </div>

              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-12 bg-success1">Company Information:</div>
              </div>
              <div class="row m-0 mt-2">
                <div class="col-md-2">
                  <span class="required-lable">Firm Type</span>
                  <select
                    name="Firm_Type"
                    id="Firm_Type"
                    #refFirm_Type="ngModel"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_Type.invalid,
                      'alert-warning':
                        refFirm_Type.invalid &&
                        (refFirm_Type.dirty ||
                          refFirm_Type.touched ||
                          refFirm_Type.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_Type"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Firm type</option>
                    <option value="Firm">Firm</option>
                    <option value=" Company-LTD(P)">Company-LTD (P)</option>
                    <option value="Company–LLP">Company–LLP</option>
                    <option value="Company-LTD">Company-LTD</option>
                    <option value="Trust">Trust</option>
                    <option value="HUF">HUF</option>
                    <option value="Institute">Institute</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Firm Name</span>
                  <input
                    type="text"
                    #refFirm_Name="ngModel"
                    [(ngModel)]="customerModel.Firm_Name"
                    placeholder="Firm Name"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_Name.invalid,
                      'alert-warning':
                        refFirm_Name.invalid &&
                        (refFirm_Name.dirty ||
                          refFirm_Name.touched ||
                          refFirm_Name.untouched)
                    }"
                    name="Firm_Name"
                    id="Firm_Name"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Firm Date of Incorporation</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker2"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="Firm_Date_Of_Incorruptions"
                      id="Firm_Date_Of_Incorruptions"
                      #refFirm_Date_Of_Incorruptions="ngModel"
                      required
                      [(ngModel)]="customerModel.Firm_Date_Of_Incorruptions"
                      #refVoucher_Date="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_Date_Of_Incorruptions.invalid,
                        'alert-warning':
                          refFirm_Date_Of_Incorruptions.invalid &&
                          (refFirm_Date_Of_Incorruptions.dirty ||
                            refFirm_Date_Of_Incorruptions.touched ||
                            refFirm_Date_Of_Incorruptions.untouched)
                      }"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <!-- <input
                    name="Firm_Date_Of_Incorruptions"
                    id="Firm_Date_Of_Incorruptions"
                    #refFirm_Date_Of_Incorruptions="ngModel"
                    required
                    [(ngModel)]="customerModel.Firm_Date_Of_Incorruptions"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_Date_Of_Incorruptions.invalid,
                      'alert-warning':
                        refFirm_Date_Of_Incorruptions.invalid &&
                        (refFirm_Date_Of_Incorruptions.dirty ||
                          refFirm_Date_Of_Incorruptions.touched ||
                          refFirm_Date_Of_Incorruptions.untouched)
                    }"
                    value="{{
                      customerModel.Firm_Date_Of_Incorruptions
                        | date : 'dd/MM/yyyy'
                    }}"
                    [(bsValue)]="customerModel.Firm_Date_Of_Incorruptions"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-2">
                  <span class="required-lable">No. of Partner</span>
                  <input
                    type="text"
                    numbersOnly
                    #refFirm_No_Of_Partner="ngModel"
                    required
                    [(ngModel)]="customerModel.Firm_No_Of_Partner"
                    placeholder="No of Partner"
                    maxlength="2"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_No_Of_Partner.invalid,
                      'alert-warning':
                        refFirm_No_Of_Partner.invalid &&
                        (refFirm_No_Of_Partner.dirty ||
                          refFirm_No_Of_Partner.touched ||
                          refFirm_No_Of_Partner.untouched)
                    }"
                    name="Firm_No_Of_Partner"
                    id="Firm_No_Of_Partner"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Firm No of Employee</span>
                  <input
                    type="text"
                    numbersOnly
                    #refFirm_No_Of_Employee="ngModel"
                    required
                    placeholder="No of Employee"
                    maxlength="5"
                    [(ngModel)]="customerModel.Firm_No_Of_Employee"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_No_Of_Employee.invalid,
                      'alert-warning':
                        refFirm_No_Of_Employee.invalid &&
                        (refFirm_No_Of_Employee.dirty ||
                          refFirm_No_Of_Employee.touched ||
                          refFirm_No_Of_Employee.untouched)
                    }"
                    name="Firm_No_Of_Employee"
                    id="Firm_No_Of_Employee"
                  />
                </div>
              </div>
              <div class="row m-0 mt-2">
                <div class="col-md-2">
                  <span class="required-lable">Firm Gross Value</span>
                  <input
                    type="text"
                    numbersOnly
                    #refFirm_GrossValue="ngModel"
                    required
                    [(ngModel)]="customerModel.Firm_GrossValue"
                    placeholder="Gross Value"
                    maxlength="12"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_GrossValue.invalid,
                      'alert-warning':
                        refFirm_GrossValue.invalid &&
                        (refFirm_GrossValue.dirty ||
                          refFirm_GrossValue.touched ||
                          refFirm_GrossValue.untouched)
                    }"
                    name="Firm_GrossValue"
                    id="Firm_GrossValue"
                    class="form-control input-text-css"
                  />
                </div>
                <div
                  [ngClass]="
                    OTPButton || customerModel.Firm_PhoneNoIsVerified
                      ? 'col-md-2'
                      : 'col-md-3'
                  "
                >
                  <span class="required-lable">Primary Contact No.</span>
                  <input
                    type="text"
                    numbersOnly
                    maxlength="10"
                    minlength="10"
                    (blur)="checkFirmPhonenumber()"
                    #refFirm_PhoneNo="ngModel"
                    [(ngModel)]="customerModel.Firm_PhoneNo"
                    placeholder="Primary Contact No"
                    required
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_PhoneNo.invalid,
                      'alert-warning':
                        refFirm_PhoneNo.invalid &&
                        (refFirm_PhoneNo.dirty ||
                          refFirm_PhoneNo.touched ||
                          refFirm_PhoneNo.untouched)
                    }"
                    name="Firm_PhoneNo"
                    id="Firm_PhoneNo"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-1" [hidden]="!OTPButton">
                  <span> &nbsp;</span>
                  <button
                    type="button"
                    (click)="ongetotp()"
                    [hidden]="!OTPButton"
                    *ngIf="!customerModel.Firm_PhoneNoIsVerified"
                    style="
                      background-color: #28a745;
                      color: #ffffff;
                      font-size: 12px;
                      width: 100%;
                    "
                    mat-raised-button
                    color="primary"
                  >
                    Get OTP
                  </button>
                </div>
                <div
                  class="col-md-1"
                  *ngIf="customerModel.Firm_PhoneNoIsVerified"
                >
                  <i
                    class="fa fa-check-circle ml-2"
                    style="color: green; font-size: 24px"
                  ></i>
                </div>

                <div class="col-md-3">
                  <span>Alternet Contact No.</span>
                  <input
                    type="text"
                    numbersOnly
                    maxlength="10"
                    minlength="10"
                    #refFirm_PhoneNo1="ngModel"
                    [(ngModel)]="customerModel.Firm_PhoneNo1"
                    placeholder="Alternet Contact No"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_PhoneNo1.invalid,
                      'alert-warning':
                        refFirm_PhoneNo1.invalid &&
                        (refFirm_PhoneNo1.dirty ||
                          refFirm_PhoneNo1.touched ||
                          refFirm_PhoneNo1.untouched)
                    }"
                    name="Firm_PhoneNo1"
                    id="Firm_PhoneNo1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span> Firm Email</span>
                  <input
                    type="email"
                    #refFirm_Email="ngModel"
                    [(ngModel)]="customerModel.Firm_Email"
                    placeholder="Firm Email"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_Email.invalid,
                      'alert-warning':
                        refFirm_Email.invalid &&
                        (refFirm_Email.dirty ||
                          refFirm_Email.touched ||
                          refFirm_Email.untouched)
                    }"
                    name="Firm_Email"
                    id="Firm_Email"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span> Firm Website</span>
                  <input
                    type="text"
                    #refFirm_Websitel="ngModel"
                    [(ngModel)]="customerModel.Firm_Website"
                    placeholder="Firm Website"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_Websitel.invalid,
                      'alert-warning':
                        refFirm_Websitel.invalid &&
                        (refFirm_Websitel.dirty ||
                          refFirm_Websitel.touched ||
                          refFirm_Websitel.untouched)
                    }"
                    name="Firm_Website"
                    id="Firm_Website"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row m-0 mt-2">
                <div class="col-md-5">
                  <span class="required-lable">Firm Nature Of Business</span>
                  <input
                    type="text"
                    #refFirm_Nature_Of_Business="ngModel"
                    required
                    [(ngModel)]="customerModel.Firm_Nature_Of_Business"
                    placeholder="Nature Of Business"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_Nature_Of_Business.invalid,
                      'alert-warning':
                        refFirm_Nature_Of_Business.invalid &&
                        (refFirm_Nature_Of_Business.dirty ||
                          refFirm_Nature_Of_Business.touched ||
                          refFirm_Nature_Of_Business.untouched)
                    }"
                    name="Firm_Nature_Of_Business"
                    id="Firm_Nature_Of_Business"
                    class="form-control input-text-css"
                  />
                </div>
              </div>

              <div class="row m-0">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>

              <div class="row m-0 mt-2">
                <div class="col-md-12">
                  <h3 class="fw-7 fs-14">Registered Address :</h3>
                </div>
              </div>

              <div class="row m-0">
                <div class="col-md-4">
                  <span class="required-lable">Address</span>
                  <input
                    type="text"
                    #refFirm_RegAddress="ngModel"
                    [(ngModel)]="customerModel.Firm_RegAddress"
                    placeholder="Reg Address"
                    required
                    name="Firm_RegAddress"
                    id="Firm_RegAddress"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_RegAddress.invalid,
                      'alert-warning':
                        refFirm_RegAddress.invalid &&
                        (refFirm_RegAddress.dirty ||
                          refFirm_RegAddress.touched ||
                          refFirm_RegAddress.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">LandMark</span>
                  <input
                    type="text"
                    #refFirm_RegLandMark="ngModel"
                    [(ngModel)]="customerModel.Firm_RegLandMark"
                    placeholder="Reg LandMark"
                    required
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_RegLandMark.invalid,
                      'alert-warning':
                        refFirm_RegLandMark.invalid &&
                        (refFirm_RegLandMark.dirty ||
                          refFirm_RegLandMark.touched ||
                          refFirm_RegLandMark.untouched)
                    }"
                    name="Firm_RegLandMark"
                    id="Firm_RegLandMark"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">State</span>
                  <select
                    name="Firm_RegState"
                    id="Firm_RegState"
                    #refFirm_RegState="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_RegState.invalid,
                      'alert-warning':
                        refFirm_RegState.invalid &&
                        (refFirm_RegState.dirty ||
                          refFirm_RegState.touched ||
                          refFirm_RegState.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_RegState"
                    required
                    (change)="GetDistrickDropdownReg()"
                  >
                    <option value="">Select State</option>
                    <option
                      *ngFor="let state of stateDropdown"
                      [value]="state.StateId"
                    >
                      {{ state.State_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">District</span>
                  <select
                    name="Firm_RegDistrict"
                    id="Firm_RegDistrict"
                    #refFirm_RegDistrict="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_RegDistrict.invalid,
                      'alert-warning':
                        refFirm_RegDistrict.invalid &&
                        (refFirm_RegDistrict.dirty ||
                          refFirm_RegDistrict.touched ||
                          refFirm_RegDistrict.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_RegDistrict"
                    class="form-control input-text-css"
                    required
                    (change)="GetTehasilDropdownReg()"
                  >
                    <option value="">Select District</option>
                    <option
                      *ngFor="let district of districkDropdownReg"
                      [value]="district.DistrictId"
                    >
                      {{ district.District_Name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-0 mt-2">
                <div class="col-md-2">
                  <span class="required-lable">Tehsil</span>
                  <select
                    name="Firm_RegTehsil"
                    id="Firm_RegTehsil"
                    #refFirm_RegTehsil="ngModel"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_RegTehsil.invalid,
                      'alert-warning':
                        refFirm_RegTehsil.invalid &&
                        (refFirm_RegTehsil.dirty ||
                          refFirm_RegTehsil.touched ||
                          refFirm_RegTehsil.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_RegTehsil"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Tehsil</option>
                    <option
                      *ngFor="let tehsil of tehasilDropdownReg"
                      [value]="tehsil.TehsilId"
                    >
                      {{ tehsil.Tehsil_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">PinCode</span>
                  <input
                    type="text"
                    #refFirm_RegPinCode="ngModel"
                    [(ngModel)]="customerModel.Firm_RegPinCode"
                    placeholder="Reg PinCode"
                    required
                    maxlength="6"
                    minlength="6"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_RegPinCode.invalid,
                      'alert-warning':
                        refFirm_RegPinCode.invalid &&
                        (refFirm_RegPinCode.dirty ||
                          refFirm_RegPinCode.touched ||
                          refFirm_RegPinCode.untouched)
                    }"
                    name="Firm_RegPinCode"
                    id="Firm_RegPinCode"
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-3">
                  <span class="required-lable">No of Year Living Here</span>
                  <input
                    type="text"
                    numbersOnly
                    maxlength="3"
                    name="RegTotalYearsOnAddress"
                    id="RegTotalYearsOnAddress"
                    #refRegTotalYearsOnAddress="ngModel"
                    [(ngModel)]="customerModel.RegTotalYearsOnAddress"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refRegTotalYearsOnAddress.invalid,
                      'alert-warning':
                        refRegTotalYearsOnAddress.invalid &&
                        (refRegTotalYearsOnAddress.dirty ||
                          refRegTotalYearsOnAddress.touched ||
                          refRegTotalYearsOnAddress.untouched)
                    }"
                    class="form-control input-text-css"
                    required
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Distance From Branch (KM)</span>
                  <input
                    type="text"
                    name="RegNearstBranchDistance_KM"
                    id="RegNearstBranchDistance_KM"
                    maxlength="3"
                    minlength="1"
                    #refRegNearstBranchDistance_KM="ngModel"
                    placeholder="Distance From Branch"
                    required
                    numbersOnly
                    [(ngModel)]="customerModel.RegNearstBranchDistance_KM"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refRegNearstBranchDistance_KM.invalid,
                      'alert-warning':
                        refRegNearstBranchDistance_KM.invalid &&
                        (refRegNearstBranchDistance_KM.dirty ||
                          refRegNearstBranchDistance_KM.touched ||
                          refRegNearstBranchDistance_KM.untouched)
                    }"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Rent/Own</span>
                  <select
                    name="RegAddressRentBuy"
                    id="RegAddressRentBuy"
                    #refRegAddressRentBuy="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refRegAddressRentBuy.invalid,
                      'alert-warning':
                        refRegAddressRentBuy.invalid &&
                        (refRegAddressRentBuy.dirty ||
                          refRegAddressRentBuy.touched ||
                          refRegAddressRentBuy.untouched)
                    }"
                    [(ngModel)]="customerModel.RegAddressRentBuy"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Own">Own</option>
                    <option value="Rent">Rent</option>
                  </select>
                </div>
              </div>

              <div class="row m-0">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>

              <div class="row m-0 mt-1">
                <div class="col-md-2">
                  <h3 class="fw-7 fs-14">Corporate Address :</h3>
                </div>
                <div class="col-md-10">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      name="exampleCheck1"
                      class="form-check-input"
                      id="exampleCheck1"
                      [(ngModel)]="customerModel.SameAddress"
                      (change)="checkAddressSame($event)"
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck1"
                      style="margin-top: 2px"
                    >
                      Corporate Address Same as Registered Address
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-md-4">
                  <span class="required-lable">Address</span>
                  <input
                    type="text"
                    #refFirm_CorpAddress="ngModel"
                    [(ngModel)]="customerModel.Firm_CorpAddress"
                    placeholder="Corp Address"
                    required
                    name="Firm_CorpAddress"
                    id="Firm_CorpAddress"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_CorpAddress.invalid,
                      'alert-warning':
                        refFirm_CorpAddress.invalid &&
                        (refFirm_CorpAddress.dirty ||
                          refFirm_CorpAddress.touched ||
                          refFirm_CorpAddress.untouched)
                    }"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Landmark</span>
                  <input
                    type="text"
                    #refFirm_CorpLandMark="ngModel"
                    [(ngModel)]="customerModel.Firm_CorpLandMark"
                    placeholder="Corp LandMark"
                    required
                    name="Firm_CorpLandMark"
                    id="Firm_CorpLandMark"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_CorpLandMark.invalid,
                      'alert-warning':
                        refFirm_CorpLandMark.invalid &&
                        (refFirm_CorpLandMark.dirty ||
                          refFirm_CorpLandMark.touched ||
                          refFirm_CorpLandMark.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">State</span>
                  <select
                    name="Firm_CorpState"
                    id="Firm_CorpState"
                    #refFirm_CorpState="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refFirm_CorpState.invalid,
                      'alert-warning':
                        refFirm_CorpState.invalid &&
                        (refFirm_CorpState.dirty ||
                          refFirm_CorpState.touched ||
                          refFirm_CorpState.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_CorpState"
                    required
                    (change)="GetDistrickDropdownCorp()"
                  >
                    <option value="">Select State</option>
                    <option
                      *ngFor="let state of stateDropdown"
                      [value]="state.StateId"
                    >
                      {{ state.State_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">District</span>
                  <select
                    name="Firm_CorpDistrict"
                    id="Firm_CorpDistrict"
                    #refFirm_CorpDistrict="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_CorpDistrict.invalid,
                      'alert-warning':
                        refFirm_CorpDistrict.invalid &&
                        (refFirm_CorpDistrict.dirty ||
                          refFirm_CorpDistrict.touched ||
                          refFirm_CorpDistrict.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_CorpDistrict"
                    required
                    (change)="GetTehasilDropdownCorp()"
                  >
                    <option value="">Select District</option>
                    <option
                      *ngFor="let district of districkDropdownCorp"
                      [value]="district.DistrictId"
                    >
                      {{ district.District_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Tehsil</span>
                  <select
                    name="Firm_CorpTehsil"
                    id="Firm_CorpTehsil"
                    #refFirm_CorpTehsil="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_CorpTehsil.invalid,
                      'alert-warning':
                        refFirm_CorpTehsil.invalid &&
                        (refFirm_CorpTehsil.dirty ||
                          refFirm_CorpTehsil.touched ||
                          refFirm_CorpTehsil.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_CorpTehsil"
                    required
                  >
                    <option value="">Select Tehsil</option>
                    <option
                      *ngFor="let tehsil of tehasilDropdownCorp"
                      [value]="tehsil.TehsilId"
                    >
                      {{ tehsil.Tehsil_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">PinCode</span>
                  <input
                    type="text"
                    #refFirm_CorpPinCode="ngModel"
                    [(ngModel)]="customerModel.Firm_CorpPinCode"
                    placeholder="Corp PinCode"
                    required
                    maxlength="6"
                    minlength="6"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refFirm_CorpPinCode.invalid,
                      'alert-warning':
                        refFirm_CorpPinCode.invalid &&
                        (refFirm_CorpPinCode.dirty ||
                          refFirm_CorpPinCode.touched ||
                          refFirm_CorpPinCode.untouched)
                    }"
                    name="Firm_CorpPinCode"
                    id="Firm_CorpPinCode"
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-3">
                  <span class="required-lable">No of Year Living Here</span>
                  <input
                    type="text"
                    numbersOnly
                    maxlength="3"
                    name="CorpTotalYearsOnAddress"
                    id="CorpTotalYearsOnAddress"
                    #refCorpTotalYearsOnAddress="ngModel"
                    [(ngModel)]="customerModel.CorpTotalYearsOnAddress"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refCorpTotalYearsOnAddress.invalid,
                      'alert-warning':
                        refCorpTotalYearsOnAddress.invalid &&
                        (refCorpTotalYearsOnAddress.dirty ||
                          refCorpTotalYearsOnAddress.touched ||
                          refCorpTotalYearsOnAddress.untouched)
                    }"
                    class="form-control input-text-css"
                    required
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Distance From Branch (KM)</span>
                  <input
                    type="text"
                    name="CorpNearstBranchDistance_KM"
                    id="CorpNearstBranchDistance_KM"
                    maxlength="3"
                    minlength="1"
                    #refCorpNearstBranchDistance_KM="ngModel"
                    placeholder="Distance From Branch"
                    required
                    numbersOnly
                    [(ngModel)]="customerModel.CorpNearstBranchDistance_KM"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refCorpNearstBranchDistance_KM.invalid,
                      'alert-warning':
                        refCorpNearstBranchDistance_KM.invalid &&
                        (refCorpNearstBranchDistance_KM.dirty ||
                          refCorpNearstBranchDistance_KM.touched ||
                          refCorpNearstBranchDistance_KM.untouched)
                    }"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Rent/Own</span>
                  <select
                    name="CorpAddressRentBuy"
                    id="CorpAddressRentBuy"
                    #refCorpAddressRentBuy="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        caf.submitted && refCorpAddressRentBuy.invalid,
                      'alert-warning':
                        refCorpAddressRentBuy.invalid &&
                        (refCorpAddressRentBuy.dirty ||
                          refCorpAddressRentBuy.touched ||
                          refCorpAddressRentBuy.untouched)
                    }"
                    [(ngModel)]="customerModel.CorpAddressRentBuy"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Own">Own</option>
                    <option value="Rent">Rent</option>
                  </select>
                </div>
              </div>

              <!--<div class="row m-0 mb-2 w-b p-0 col-md-12 align-items-center justify-content-between">
                <h1 class="fs-12 m-0">Director's Information</h1>
                <button type="button" class="primary-btn" (click)="addDirector()"> Add Director </button>
              </div>-->
            </ng-container>
            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseCustomer()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="btnSaveCust"
                #btnRegisterNewCustomer
                (click)="onRegisterNewCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="!isCustomerEdit"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Save
              </button>
              <button
                type="button"
                id="btnUpdateCust"
                #btnUpdateCustomer
                (click)="onUpdateCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="isCustomerEdit"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Update
              </button>

              <!-- if updating full information of customer -->
              <button
                type="button"
                *ngIf="isUpdateAddress"
                (click)="onUpdateCustomerAddress()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Update
              </button>
            </div>
          </form>
        </ng-conatainer>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addressModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Customer Address
        </h6>
        <button
          type="button"
          (click)="onCloseAddress()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="addressModel.customerDetail">
          <div class="col-md-3 pl-0">
            <h6 class="fs-12">Customer</h6>
            <p class="lead fs-12 fw-7">
              {{ addressModel.customerDetail.CustomerName }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Father/Spouse Name</h6>
            <p class="lead fs-12">
              {{ addressModel.customerDetail.FatherName }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Gender/Age</h6>
            <p class="lead fs-12">
              {{ addressModel.customerDetail.GenderAge }}
            </p>
          </div>
        </div>
        <div class="row m-0">
          <form
            #caf="ngForm"
            (ngSubmit)="onAddCustomerAddress()"
            novalidate
            class="col-md-12 row m-0 p-0 formborder"
          >
            <h3
              class="fw-7 fs-14 m-0"
              *ngIf="
                addressModel.customerDetail &&
                !addressModel.customerDetail.IsFirm
              "
            >
              Present Address
            </h3>
            <h3
              class="fw-7 fs-14 m-0"
              *ngIf="
                addressModel.customerDetail &&
                addressModel.customerDetail.IsFirm
              "
            >
              Registered Address
            </h3>
            <div class="row m-0 mt-2" *ngIf="addressModel.present">
              <div class="col-md-4">
                <span> Address</span>
                <input
                  type="text"
                  #refPresentAddress="ngModel"
                  [(ngModel)]="addressModel.present.Address"
                  placeholder="Address"
                  required
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentAddress.invalid,
                    'alert-warning':
                      refPresentAddress.invalid &&
                      (refPresentAddress.dirty ||
                        refPresentAddress.touched ||
                        refPresentAddress.untouched)
                  }"
                  name="PresentAddress"
                  id="PresentAddress"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> LandMark</span>
                <input
                  type="text"
                  #refPresentLandMark="ngModel"
                  [(ngModel)]="addressModel.present.LandMark"
                  placeholder="LandMark"
                  required
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentLandMark.invalid,
                    'alert-warning':
                      refPresentLandMark.invalid &&
                      (refPresentLandMark.dirty ||
                        refPresentLandMark.touched ||
                        refPresentLandMark.untouched)
                  }"
                  name="PresentLandMark"
                  id="PresentLandMark"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> PinCode</span>
                <input
                  type="text"
                  #refPresentPinCode="ngModel"
                  [(ngModel)]="addressModel.present.PinCode"
                  placeholder="PinCode"
                  required
                  maxlength="6"
                  minlength="6"
                  numbersOnly
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentPinCode.invalid,
                    'alert-warning':
                      refPresentPinCode.invalid &&
                      (refPresentPinCode.dirty ||
                        refPresentPinCode.touched ||
                        refPresentPinCode.untouched)
                  }"
                  name="PresentPinCode"
                  id="PresentPinCode"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 mt-2">
                <span> State</span>
                <select
                  name="PresentState"
                  id="PresentState"
                  #refPresentState="ngModel"
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentState.invalid,
                    'alert-warning':
                      refPresentState.invalid &&
                      (refPresentState.dirty ||
                        refPresentState.touched ||
                        refPresentState.untouched)
                  }"
                  [(ngModel)]="addressModel.present.StateId"
                  class="form-control input-text-css"
                  required
                  (change)="GetDistrickDropdownPresent1()"
                >
                  <option value="">Select State</option>
                  <option
                    *ngFor="let state of stateDropdown"
                    [value]="state.StateId"
                  >
                    {{ state.State_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> District</span>
                <select
                  name="PresentDistrict1"
                  id="PresentDistrict1"
                  #refPresentDistrict="ngModel"
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentDistrict.invalid,
                    'alert-warning':
                      refPresentDistrict.invalid &&
                      (refPresentDistrict.dirty ||
                        refPresentDistrict.touched ||
                        refPresentDistrict.untouched)
                  }"
                  [(ngModel)]="addressModel.present.DistrictId"
                  class="form-control input-text-css"
                  required
                  (change)="GetTehasilDropdownPresent1()"
                >
                  <option value="">Select District</option>
                  <option
                    *ngFor="let district of districkDropdownPresent"
                    [value]="district.DistrictId"
                  >
                    {{ district.District_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> Tehsil</span>
                <select
                  name="PresentTehsil1"
                  id="PresentTehsil1"
                  #refPresentTehsil="ngModel"
                  [ngClass]="{
                    'is-invalid': caf.submitted && refPresentTehsil.invalid,
                    'alert-warning':
                      refPresentTehsil.invalid &&
                      (refPresentTehsil.dirty ||
                        refPresentTehsil.touched ||
                        refPresentTehsil.untouched)
                  }"
                  [(ngModel)]="addressModel.present.TehsilId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Tehsil</option>
                  <option
                    *ngFor="let tehsil of tehasilDropdownPresent"
                    [value]="tehsil.TehsilId"
                  >
                    {{ tehsil.Tehsil_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> No of Year Living Here</span>
                <input
                  type="text"
                  numbersOnly
                  maxlength="3"
                  name="PresentTotalYearsOnAddress1"
                  id="PresentTotalYearsOnAddress1"
                  #refPresentTotalYearsOnAddress="ngModel"
                  [(ngModel)]="addressModel.present.TotalYearsOnAddress"
                  [ngClass]="{
                    'is-invalid':
                      caf.submitted && refPresentTotalYearsOnAddress.invalid,
                    'alert-warning':
                      refPresentTotalYearsOnAddress.invalid &&
                      (refPresentTotalYearsOnAddress.dirty ||
                        refPresentTotalYearsOnAddress.touched ||
                        refPresentTotalYearsOnAddress.untouched)
                  }"
                  class="form-control input-text-css"
                  required
                />
              </div>
              <div class="col-md-3 mt-2">
                <span> Rent/Own</span>
                <select
                  name="PresentAddressRentBuy"
                  id="PresentAddressRentBuy"
                  #refPresentAddressRentBuy="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      caf.submitted && refPresentAddressRentBuy.invalid,
                    'alert-warning':
                      refPresentAddressRentBuy.invalid &&
                      (refPresentAddressRentBuy.dirty ||
                        refPresentAddressRentBuy.touched ||
                        refPresentAddressRentBuy.untouched)
                  }"
                  [(ngModel)]="addressModel.present.AddressRentBuy"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select</option>
                  <option value="Own">Own</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> Distance From Branch (KM)</span>
                <input
                  type="text"
                  #refPresentNearstBranchDistance_KM="ngModel"
                  [(ngModel)]="addressModel.present.NearstBranchDistance_KM"
                  placeholder="Distance From Branch"
                  required
                  maxlength="3"
                  minlength="1"
                  numbersOnly
                  [ngClass]="{
                    'is-invalid':
                      caf.submitted &&
                      refPresentNearstBranchDistance_KM.invalid,
                    'alert-warning':
                      refPresentNearstBranchDistance_KM.invalid &&
                      (refPresentNearstBranchDistance_KM.dirty ||
                        refPresentNearstBranchDistance_KM.touched ||
                        refPresentNearstBranchDistance_KM.untouched)
                  }"
                  name="PresentNearstBranchDistance_KM"
                  id="PresentNearstBranchDistance_KM"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mt-2">
                <span> &nbsp;</span>
                <div class="form-check">
                  <input
                    type="checkbox"
                    name="PresentIsCommunicationAddress"
                    class="form-check-input"
                    id="PresentIsCommunicationAddress"
                    [(ngModel)]="addressModel.present.IsCommunicationAddress"
                  />
                  <label
                    class="form-check-label"
                    for="PresentIsCommunicationAddress"
                  >
                    Is This Cummunication Address
                  </label>
                </div>
              </div>
            </div>
            <div
              class="row m-0 mt-3 col-md-12 align-items-center justify-content-between"
            >
              <h3
                class="fw-7 fs-14 m-0 col-md-3 p-0"
                *ngIf="
                  addressModel.customerDetail &&
                  !addressModel.customerDetail.IsFirm
                "
              >
                Permanent Address
              </h3>
              <h3
                class="fw-7 fs-14 m-0 col-md-3 p-0"
                *ngIf="
                  addressModel.customerDetail &&
                  addressModel.customerDetail.IsFirm
                "
              >
                Corporate Address
              </h3>
              <div class="col-md-9">
                <div class="form-check">
                  <input
                    type="checkbox"
                    name="exampleCheck2"
                    class="form-check-input"
                    id="exampleCheck2"
                    [(ngModel)]="addressModel.SameAddress"
                    (change)="checkCAddressSame($event)"
                  />
                  <label class="form-check-label" for="exampleCheck2">
                    Select if Permanent Address same as Present Address
                  </label>
                </div>
              </div>
            </div>
            <div class="row m-0 mt-2" *ngIf="addressModel.permanent">
              <div class="col-md-4">
                <span> Address</span>
                <input
                  type="text"
                  #refPermanentAddress="ngModel"
                  [(ngModel)]="addressModel.permanent.Address"
                  placeholder="Address"
                  name="PermanentAddress"
                  id="PermanentAddress"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> LandMark</span>
                <input
                  type="text"
                  #refPermanentLandMark="ngModel"
                  [(ngModel)]="addressModel.permanent.LandMark"
                  placeholder="LandMark"
                  name="PermanentLandMark"
                  id="PermanentLandMark"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> PinCode</span>
                <input
                  type="text"
                  #refPermanentPinCode="ngModel"
                  [(ngModel)]="addressModel.permanent.PinCode"
                  placeholder="PinCode"
                  maxlength="6"
                  minlength="6"
                  numbersOnly
                  name="PermanentPinCode"
                  id="PermanentPinCode"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 mt-2">
                <span> State</span>
                <select
                  name="PermanentState"
                  id="PermanentState"
                  #refPermanentState="ngModel"
                  [(ngModel)]="addressModel.permanent.StateId"
                  class="form-control input-text-css"
                  (change)="GetDistrickDropdownPermanent1()"
                >
                  <option value="">Select State</option>
                  <option
                    *ngFor="let state of stateDropdown"
                    [value]="state.StateId"
                  >
                    {{ state.State_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> District</span>
                <select
                  name="PermanentDistrict"
                  id="PermanentDistrict"
                  #refPermanentDistrict="ngModel"
                  [(ngModel)]="addressModel.permanent.DistrictId"
                  class="form-control input-text-css"
                  (change)="GetTehasilDropdownPermanent1()"
                >
                  <option value="">Select District</option>
                  <option
                    *ngFor="let district of districkDropdownPermanent"
                    [value]="district.DistrictId"
                  >
                    {{ district.District_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> Tehsil</span>
                <select
                  name="PermanentTehsil"
                  id="PermanentTehsil"
                  #refPermanentTehsil="ngModel"
                  [(ngModel)]="addressModel.permanent.TehsilId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Tehsil</option>
                  <option
                    *ngFor="let tehsil of tehasilDropdownPermanent"
                    [value]="tehsil.TehsilId"
                  >
                    {{ tehsil.Tehsil_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> No of Year Living Here</span>
                <input
                  type="text"
                  numbersOnly
                  maxlength="3"
                  name="PermanentTotalYearsOnAddress"
                  id="PermanentTotalYearsOnAddress"
                  #refPermanentTotalYearsOnAddress="ngModel"
                  [(ngModel)]="addressModel.permanent.TotalYearsOnAddress"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mt-2">
                <span> Rent/Own</span>
                <select
                  name="PermanentAddressRentBuy"
                  id="PermanentAddressRentBuy"
                  #refPermanentAddressRentBuy="ngModel"
                  [(ngModel)]="addressModel.permanent.AddressRentBuy"
                  class="form-control input-text-css"
                >
                  <option value="">Select</option>
                  <option value="Own">Own</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> Distance From Branch (KM)</span>
                <input
                  type="text"
                  #refPermanentNearstBranchDistance_KM="ngModel"
                  [(ngModel)]="addressModel.permanent.NearstBranchDistance_KM"
                  placeholder="Distance From Branch"
                  maxlength="3"
                  minlength="1"
                  numbersOnly
                  name="PermanentNearstBranchDistance_KM"
                  id="PermanentNearstBranchDistance_KM"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mt-2">
                <span> &nbsp;</span>
                <div class="form-check">
                  <input
                    type="checkbox"
                    name="PermanentIsCommunicationAddress"
                    class="form-check-input"
                    id="PermanentIsCommunicationAddress"
                    [(ngModel)]="addressModel.permanent.IsCommunicationAddress"
                  />
                  <label
                    class="form-check-label"
                    for="PermanentIsCommunicationAddress"
                  >
                    Is This Cummunication Address
                  </label>
                </div>
              </div>
            </div>
            <h3
              class="fw-7 fs-14 m-0 mt-3"
              *ngIf="
                addressModel.customerDetail &&
                !addressModel.customerDetail.IsFirm
              "
            >
              Work Address
            </h3>
            <div
              class="row m-0 mt-2"
              *ngIf="
                addressModel.customerDetail &&
                addressModel.work &&
                !addressModel.customerDetail.IsFirm
              "
            >
              <div class="col-md-4">
                <span> Address</span>
                <input
                  type="text"
                  #refWorkAddress="ngModel"
                  [(ngModel)]="addressModel.work.Address"
                  placeholder="Address"
                  name="WorkAddress"
                  id="WorkAddress"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> LandMark</span>
                <input
                  type="text"
                  #refWorkLandMark="ngModel"
                  [(ngModel)]="addressModel.work.LandMark"
                  placeholder="LandMark"
                  name="WorkLandMark"
                  id="WorkLandMark"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4">
                <span> PinCode</span>
                <input
                  type="text"
                  #refWorkPinCode="ngModel"
                  [(ngModel)]="addressModel.work.PinCode"
                  placeholder="PinCode"
                  maxlength="6"
                  minlength="6"
                  numbersOnly
                  name="WorkPinCode"
                  id="WorkPinCode"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-4 mt-2">
                <span> State</span>
                <select
                  name="WorkState"
                  id="WorkState"
                  #refWorkState="ngModel"
                  [(ngModel)]="addressModel.work.StateId"
                  class="form-control input-text-css"
                  (change)="GetDistrickDropdownWork1()"
                >
                  <option value="">Select State</option>
                  <option
                    *ngFor="let state of stateDropdown"
                    [value]="state.StateId"
                  >
                    {{ state.State_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> District</span>
                <select
                  name="WorkDistrict"
                  id="WorkDistrict"
                  #refWorkDistrict="ngModel"
                  [(ngModel)]="addressModel.work.DistrictId"
                  class="form-control input-text-css"
                  (change)="GetTehasilDropdownWork1()"
                >
                  <option value="">Select District</option>
                  <option
                    *ngFor="let district of districkDropdownWork"
                    [value]="district.DistrictId"
                  >
                    {{ district.District_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mt-2">
                <span> Tehsil</span>
                <select
                  name="WorkTehsil"
                  id="WorkTehsil"
                  #refWorkTehsil="ngModel"
                  [(ngModel)]="addressModel.work.TehsilId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Tehsil</option>
                  <option
                    *ngFor="let tehsil of tehasilDropdownWork"
                    [value]="tehsil.TehsilId"
                  >
                    {{ tehsil.Tehsil_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> No of Year Working Here</span>
                <!--<select
    name="WorkTotalYearsOnAddress"
    id="WorkTotalYearsOnAddress"
    #refWorkTotalYearsOnAddress="ngModel"
    [(ngModel)]="addressModel.work.TotalYearsOnAddress"
    class="form-control input-text-css"
  >
    <option value="">Select</option>
    <option value="0" *ngIf="customerModel.Type == 'Indusial'">
      By birth
    </option>
    <option value="1">1 Year</option>
    <option value="2">2 Years</option>
    <option value="3">3 Years</option>
    <option value="4">4 Years</option>
    <option value="5">5 Years</option>
    <option value="6">6 Years</option>
    <option value="7">7 Years</option>
    <option value="8">8 Years</option>
    <option value="9">9 Years</option>
    <option value="10">10 Years</option>
    <option value="11">11 Years</option>
    <option value="12">12 Years</option>
    <option value="13">13 Years</option>
    <option value="14">14 Years</option>
    <option value="15">15 Years</option>
    <option value="16">16 Years</option>
    <option value="17">17 Years</option>
    <option value="18">18 Years</option>
    <option value="19">19 Years</option>
    <option value="20">20 Years</option>
  </select>-->
                <input type="text"
                       numbersOnly
                       maxlength="3"
                       name="WorkTotalYearsOnAddress1"
                       id="WorkTotalYearsOnAddress1"
                       [(ngModel)]="addressModel.work.TotalYearsOnAddress"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3 mt-2">
                <span> Rent/Own</span>
                <select
                  name="WorkAddressRentBuy"
                  id="WorkAddressRentBuy"
                  #refWorkAddressRentBuy="ngModel"
                  [(ngModel)]="addressModel.work.AddressRentBuy"
                  class="form-control input-text-css"
                >
                  <option value="">Select</option>
                  <option value="Own">Own</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>
              <div class="col-md-3 mt-2">
                <span> Distance From Branch (KM)</span>
                <input
                  type="text"
                  #refWorkNearstBranchDistance_KM="ngModel"
                  [(ngModel)]="addressModel.work.NearstBranchDistance_KM"
                  placeholder="Distance From Branch"
                  maxlength="3"
                  minlength="1"
                  numbersOnly
                  name="WorkNearstBranchDistance_KM"
                  id="WorkNearstBranchDistance_KM"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3 mt-2">
                <span> &nbsp;</span>
                <div class="form-check">
                  <input
                    type="checkbox"
                    name="WorkIsCommunicationAddress"
                    class="form-check-input"
                    id="WorkIsCommunicationAddress"
                    [(ngModel)]="addressModel.work.IsCommunicationAddress"
                  />
                  <label
                    class="form-check-label"
                    for="WorkIsCommunicationAddress"
                  >
                    Is This Cummunication Address
                  </label>
                </div>
              </div>
            </div>
            <div class="row col-md-12 m-0 mt-2 justify-content-end">
              <button
                type="button"
                (click)="onCloseAddress()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onAddCustomerAddress()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!caf.form.valid"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="kycModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Customer KYC Doc
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="addressModel.customerDetail">
          <div class="col-md-3 pl-0">
            <h6 class="fs-12">Customer</h6>
            <p class="lead fs-12 fw-7">
              {{ addressModel.customerDetail.CustomerName }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Father/Spouse Name</h6>
            <p class="lead fs-12">
              {{ addressModel.customerDetail.FatherName }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Gender/Age</h6>
            <p class="lead fs-12">
              {{ addressModel.customerDetail.GenderAge }}
            </p>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <form
            #ckyc="ngForm"
            (ngSubmit)="onAddCustomerKYC()"
            novalidate
            class="col-md-12 row m-0 p-0 formborder"
          >
            <div class="row mb-2" *ngIf="kycModel">
              <div class="col-md-12" *ngIf="isNewAddress">
                <span> Customer </span>
                <select
                  name="CustomerId"
                  id="CustomerId"
                  #refCustomerId="ngModel"
                  (change)="OnChangeCustomer(kycModel)"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refCustomerId.invalid,
                    'alert-warning':
                      refCustomerId.invalid &&
                      (refCustomerId.dirty ||
                        refCustomerId.touched ||
                        refCustomerId.untouched)
                  }"
                  [(ngModel)]="kycModel.CustomerId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Customer</option>
                  <option
                    [value]="cus.CustomerId"
                    *ngFor="let cus of customerlist"
                  >
                    {{ cus.Customer }}
                  </option>
                </select>
              </div>
              <div class="col-md-12">
                <span> Document Type </span>
                <select
                  name="KYC_DocId"
                  id="KYC_DocId"
                  #refKYC_DocId="ngModel"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_DocId.invalid,
                    'alert-warning':
                      refKYC_DocId.invalid &&
                      (refKYC_DocId.dirty ||
                        refKYC_DocId.touched ||
                        refKYC_DocId.untouched)
                  }"
                  [(ngModel)]="kycModel.KYC_DocId"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Document type</option>
                  <option
                    *ngFor="let Doc of ProductDropdownList"
                    [value]="Doc.DocId"
                  >
                    {{ Doc.Doc_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-12">
                <span> Document Number</span>
                <input
                  type="text"
                  #refKYC_DocNumber="ngModel"
                  [(ngModel)]="kycModel.KYC_DocNumber"
                  placeholder="Document Number"
                  required
                  *ngIf="kycModel.KYC_DocId != 1 && kycModel.KYC_DocId != 6"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_DocNumber.invalid,
                    'alert-warning':
                      refKYC_DocNumber.invalid &&
                      (refKYC_DocNumber.dirty ||
                        refKYC_DocNumber.touched ||
                        refKYC_DocNumber.untouched)
                  }"
                  name="KYC_DocNumber"
                  id="KYC_DocNumber"
                  class="form-control input-text-css"
                  maxlength="50"
                  (blur)="Get_Alredy_Verified_KYC(kycModel)"
                  (change)="IsVerification(kycModel)"
                />
                <input
                  type="text"
                  #refKYC_DocNumber="ngModel"
                  [(ngModel)]="kycModel.KYC_DocNumber"
                  placeholder="Document Number"
                  required
                  *ngIf="kycModel.KYC_DocId == 1"
                  maxlength="10"
                  minlength="10"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_DocNumber.invalid,
                    'alert-warning':
                      refKYC_DocNumber.invalid &&
                      (refKYC_DocNumber.dirty ||
                        refKYC_DocNumber.touched ||
                        refKYC_DocNumber.untouched)
                  }"
                  name="KYC_DocNumber"
                  id="KYC_DocNumber"
                  class="form-control input-text-css"
                  (blur)="Get_Alredy_Verified_KYC(kycModel)"
                  (change)="IsVerification(kycModel)"
                />
                <input
                  type="text"
                  #refKYC_DocNumber="ngModel"
                  numbersOnly
                  [(ngModel)]="kycModel.KYC_DocNumber"
                  placeholder="Document Number"
                  required
                  *ngIf="kycModel.KYC_DocId == 6"
                  maxlength="12"
                  minlength="12"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_DocNumber.invalid,
                    'alert-warning':
                      refKYC_DocNumber.invalid &&
                      (refKYC_DocNumber.dirty ||
                        refKYC_DocNumber.touched ||
                        refKYC_DocNumber.untouched)
                  }"
                  name="KYC_DocNumber"
                  id="KYC_DocNumber"
                  class="form-control input-text-css"
                  (blur)="Get_Alredy_Verified_KYC(kycModel)"
                  (change)="IsVerification(kycModel)"
                />
              </div>
              <div
                class="col-md-12"
                *ngIf="kycModel.KYC_DocId == 3 || kycModel.KYC_DocId == 5"
              >
                <span> Date Of Issue </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker3"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="KYC_IssuedDate"
                    id="KYC_IssuedDate"
                    #refKYC_IssuedDate="ngModel"
                    required
                    [(ngModel)]="kycModel.KYC_IssuedDate"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid': ckyc.submitted && refKYC_IssuedDate.invalid,
                      'alert-warning':
                        refKYC_IssuedDate.invalid &&
                        (refKYC_IssuedDate.dirty ||
                          refKYC_IssuedDate.touched ||
                          refKYC_IssuedDate.untouched)
                    }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
                <!-- <input
                  name="KYC_IssuedDate"
                  id="KYC_IssuedDate"
                  #refKYC_IssuedDate="ngModel"
                  required
                  [(ngModel)]="kycModel.KYC_IssuedDate"
                  value="{{ kycModel.KYC_IssuedDate | date : 'dd/MM/yyyy' }}"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_IssuedDate.invalid,
                    'alert-warning':
                      refKYC_IssuedDate.invalid &&
                      (refKYC_IssuedDate.dirty ||
                        refKYC_IssuedDate.touched ||
                        refKYC_IssuedDate.untouched)
                  }"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  [(bsValue)]="kycModel.KYC_IssuedDate"
                  bsDatepicker
                /> -->
              </div>
              <div
                class="col-md-12"
                *ngIf="kycModel.KYC_DocId == 3 || kycModel.KYC_DocId == 5"
              >
                <span> Date Of Expire </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker4"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="KYC_ExpiredDate"
                    id="KYC_ExpiredDate"
                    #refKYC_ExpiredDate="ngModel"
                    required
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ckyc.submitted && refKYC_ExpiredDate.invalid,
                      'alert-warning':
                        refKYC_ExpiredDate.invalid &&
                        (refKYC_ExpiredDate.dirty ||
                          refKYC_ExpiredDate.touched ||
                          refKYC_ExpiredDate.untouched)
                    }"
                    [(ngModel)]="kycModel.KYC_ExpiredDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
                <!-- <input
                  name="KYC_ExpiredDate"
                  id="KYC_ExpiredDate"
                  #refKYC_ExpiredDate="ngModel"
                  required
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  [(bsValue)]="kycModel.KYC_ExpiredDate"
                  [ngClass]="{
                    'is-invalid': ckyc.submitted && refKYC_ExpiredDate.invalid,
                    'alert-warning':
                      refKYC_ExpiredDate.invalid &&
                      (refKYC_ExpiredDate.dirty ||
                        refKYC_ExpiredDate.touched ||
                        refKYC_ExpiredDate.untouched)
                  }"
                  [(ngModel)]="kycModel.KYC_ExpiredDate"
                  value="{{ kycModel.KYC_ExpiredDate | date : 'dd/MM/yyyy' }}"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-12">
                <span> Document Image</span>
                <p class="m-0 mt-2 row">
                  <i
                    class="fa fa-upload ml-2"
                    *ngIf="!kycModel.KYC_DocFile"
                    (click)="docFile.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="kycModel.KYC_DocFile">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ kycModel.KYC_DocFile }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFile(kycModel, docFile)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docFile
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener(kycModel, docFile.files)"
                  style="display: none"
                />
              </div>
              <div class="col-md-12">
                <span> Document Image 1</span>
                <p class="m-0 mt-2 row">
                  <i
                    class="fa fa-upload ml-2"
                    *ngIf="!kycModel.KYC_DocFile1"
                    (click)="docFile1.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="kycModel.KYC_DocFile1">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ kycModel.KYC_DocFile1 }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFile2(kycModel, docFile1)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docFile1
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel"
                  (change)="fileChangeListener2(kycModel, docFile1.files)"
                  style="display: none"
                />
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  kycModel.KYC_DocId == 1 ||
                  kycModel.KYC_DocId == 6 ||
                  kycModel.KYC_DocId == 4 ||
                  kycModel.KYC_DocId == 28 ||
                  kycModel.KYC_DocId == 29 ||
                  kycModel.KYC_DocId == 5 ||
                  kycModel.KYC_DocId == 2
                "
              >
                <button
                  type="button"
                  *ngIf="kycModel.Verified_Button"
                  [hidden]="kycModel.KYC_IsVerified == 1"
                  (click)="onVerification(kycModel, 0)"
                  class="btn font-size-12 button-btn"
                >
                  Verification
                </button>

                <button
                  type="button"
                  *ngIf="
                    kycModel.Verified_Button &&
                    (kycModel.KYC_IsVerified == 1 || kycModel.LastVerfiedDate)
                  "
                  (click)="onVerification(kycModel, 1)"
                  class="btn font-size-12 button-btn"
                >
                  RE-Verification
                </button>
              </div>
            </div>
            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseKYC()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onAddCustomerKYC()"
                class="btn font-size-12 button-btn ml-4"
                mat-raised-button
                [class.spinner]="loading"
                [disabled]="!ckyc.form.valid"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="OTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          OTP Verification
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #cotp="ngForm"
          (ngSubmit)="onSAVEOTP()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span> Enter OTP</span>
              <input
                type="text"
                #refOTP="ngModel"
                numbersOnly
                maxlength="5"
                minlength="5"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{
                  'is-invalid': caf.submitted && refOTP.invalid,
                  'alert-warning':
                    refOTP.invalid &&
                    (refOTP.dirty || refOTP.touched || refOTP.untouched)
                }"
                name="OTP"
                id="OTP"
                class="form-control input-text-css"
              />
            </div>
          </div>

          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onCloseOTP()"
              class="btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSAVEOTP()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!cotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addDirector"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Director To Application
        </h6>
        <button
          type="button"
          (click)="onCloseDirector()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="applicationDetail">
          <div class="col-md-3 pl-0">
            <h6 class="fs-12">Application No</h6>
            <p class="lead fs-12 fw-7">{{ applicationDetail.ApplicationNo }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Loan Product</h6>
            <p class="lead fs-12">{{ applicationDetail.Product }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Branch</h6>
            <p class="lead fs-12">{{ applicationDetail.Branch }}</p>
          </div>
        </div>

        <ng-container *ngIf="isDirectorExistingCustomer">
          <div class="row m-0 align-items-end">
            <div class="col-md-3 p-1">
              <span> Search By: </span>
              <select
                name="FilterSearchBy"
                id="FilterSearchBy"
                class="form-control input-text-css"
                [(ngModel)]="FilterSearchBy"
              >
                <option value="">Select</option>
                <option value="Name">Customer Name</option>
                <option value="Phone">Customer Phone</option>
                <option value="Pan">PAN Card</option>
                <option value="Passport">Passport Copy</option>
                <option value="VoterId">Voter ID Card</option>
                <option value="DL">Driving License</option>
                <option value="Aadhar">Aadhaar Card</option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span> Value: </span>
              <input
                name="FilterSearchValue"
                id="FilterSearchValue"
                placeholder="Value"
                [(ngModel)]="FilterSearchValue"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <button
                class="btn font-size-12 button-btn"
                (click)="getSearchCusList()"
              >
                Search
              </button>
            </div>
            <div class="col-md-3 p-1">
              <button
                style="width: max-content"
                class="btn font-size-12 button-btn"
                (click)="registereNewDirector()"
              >
                Register a New Director
              </button>
            </div>
            <div class="table-responsive mt-3">
              <mat-table [dataSource]="dataSource" id="exportReport">
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Customer
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomertName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Father Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.FatherName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    Gender
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                  >
                    {{ row.Customer_Gender }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    Age
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                  >
                    {{ row.GenderAge }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Phone No
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            class="grid-cell"
                            style="max-width: 150px; justify-content: center">
                    <div *ngIf="currentUser.IsMasking">{{row.PhoneNo |mask}}</div>
                    <div *ngIf="!currentUser.IsMasking"> {{row.PhoneNo}}</div>
                    <i *ngIf="row.Customer_PhoneNo_IsVerified == true"
                       class="fa fa-check-circle ml-2"
                       style="color: green"></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Present_Address">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Present Address
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px; justify-content: center"
                  >
                    {{ row.Present_Address }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Select">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 100px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Select
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 100px; justify-content: center"
                  >
                    <input
                      type="checkbox"
                      [value]="row.CustomerId"
                      [checked]="customerModel.CustomerId == row.CustomerId"
                      (change)="onCustomerChange($event, row)"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseDirector()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onAddExistingCustomer_Firm()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Save
              </button>
            </div>
          </div>
        </ng-container>

        <ng-conatainer *ngIf="!isDirectorExistingCustomer">
          <div class="row m-0">
            <form
              #ancf="ngForm"
              (ngSubmit)="onRegisterNewCustomer()"
              novalidate
              class="col-md-12 p-0"
            >
              <div class="row">
                <div class="col-md-4">
                  <span> Type </span>
                  <select
                    name="Type"
                    id="Type"
                    #refType="ngModel"
                    [(ngModel)]="customerModel.Type"
                    class="form-control input-text-css"
                    (change)="changeCustomertype()"
                    [disabled]="isCustomerEdit"
                  >
                    <option value="Indusial">Individual</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span> Customer Type </span>
                  <select
                    name="Customer_Type"
                    id="Customer_Type"
                    #refCustomer_Type="ngModel"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refCustomer_Type.invalid,
                      'alert-warning':
                        refCustomer_Type.invalid &&
                        (refCustomer_Type.dirty ||
                          refCustomer_Type.touched ||
                          refCustomer_Type.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Type"
                    (change)="changeCustomerRelationType()"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Customer type</option>
                    <option value="H">Borrower</option>
                    <option value="C">CO-Borrower</option>
                    <option value="G">Guarantor</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="
                    customerModel.Customer_Type == 'C' ||
                    customerModel.Customer_Type == 'G'
                  "
                >
                  <span> Relation With Customer </span>
                  <select
                    name="Customer_Relation"
                    id="Customer_Relation"
                    #refCustomer_Relation="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refCustomer_Relation.invalid,
                      'alert-warning':
                        refCustomer_Relation.invalid &&
                        (refCustomer_Relation.dirty ||
                          refCustomer_Relation.touched ||
                          refCustomer_Relation.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Relation"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Uncle">Uncle</option>
                    <option value="Aunty">Aunty</option>
                    <option value="Grandfather">Grandfather</option>
                    <option value="Grandmother">Grandmother</option>
                    <option value="Father In-Law">Father In-Law</option>
                    <option value="Mother In-Law">Mother In-Law</option>
                    <option value="Son In-Law">Son In-Law</option>
                    <option value="Daughter In-Law">Daughter In-Law</option>
                    <option value="Brother In-Law">Brother In-Law</option>
                    <option value="Sister In-Law">Sister In-Law</option>
                    <option value="Uncle In-Law">Uncle In-Law</option>
                    <option value="Aunty In-Law">Aunty In-Law</option>
                    <option value="Grandfather In-Law">
                      Grandfather In-Law
                    </option>
                    <option value="Grandmother In-Law">
                      Grandmother In-Law
                    </option>
                    <option value="Friend">Friend</option>
                    <option value="Other">Other</option>
                    <option value="None">None</option>
                  </select>
                </div>
              </div>
              <hr />
              <ng-container *ngIf="customerModel.Type == 'Indusial'">
                <h1 class="fs-12">Demographic Information</h1>
                <div class="row">
                  <div class="col-md-3 row m-0 align-items-center">
                    <input
                      #cpFile
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="fileChangeListener1(cpFile.files)"
                      style="display: none"
                    />
                    <p
                      class="m-0 row"
                      *ngIf="!customerModel.Customer_ProfilePic"
                    >
                      Upload Customer Image:<i
                        class="fa fa-upload ml-2"
                        (click)="cpFile.click()"
                        aria-hidden="true"
                      ></i>
                    </p>
                    <ng-container *ngIf="customerModel.Customer_ProfilePic">
                      <div
                        class="c-img col-md-12"
                        *ngIf="customerModel.DocData"
                      >
                        <img
                          src="data:image/png;base64,{{
                            customerModel.DocData
                          }}"
                          alt=""
                        />
                      </div>
                      <div
                        class="c-img mr-2 col-md-12"
                        *ngIf="!customerModel.DocData"
                      >
                        <img
                          src="{{ customerProfileBaseUrl }}{{
                            customerModel.CustomerId
                          }}/{{ customerModel.Customer_ProfilePic }}"
                          alt=""
                        />
                      </div>
                      <div class="col-md-12 row m-0 mt-2 align-items-center">
                        {{ customerModel.Customer_ProfilePic }}
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile1(cpFile)"
                        ></i>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row col-md-9 m-0">
                    <div class="col-md-4">
                      <span> First Name</span>
                      <input
                        type="text"
                        #refCustomer_FirstName="ngModel"
                        [(ngModel)]="customerModel.Customer_FirstName"
                        placeholder="First Name"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_FirstName.invalid,
                          'alert-warning':
                            refCustomer_FirstName.invalid &&
                            (refCustomer_FirstName.dirty ||
                              refCustomer_FirstName.touched ||
                              refCustomer_FirstName.untouched)
                        }"
                        name="Customer_FirstName"
                        id="Customer_FirstName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> Last Name</span>
                      <input
                        type="text"
                        #refCustomer_LastName="ngModel"
                        [(ngModel)]="customerModel.Customer_LastName"
                        placeholder="Last Name"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_LastName.invalid,
                          'alert-warning':
                            refCustomer_LastName.invalid &&
                            (refCustomer_LastName.dirty ||
                              refCustomer_LastName.touched ||
                              refCustomer_LastName.untouched)
                        }"
                        name="Customer_LastName"
                        id="Customer_LastName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> Father/Spouse</span>
                      <input
                        type="text"
                        #refCustomer_FatherName="ngModel"
                        [(ngModel)]="customerModel.Customer_FatherName"
                        placeholder="Father/Spouse"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_FatherName.invalid,
                          'alert-warning':
                            refCustomer_FatherName.invalid &&
                            (refCustomer_FatherName.dirty ||
                              refCustomer_FatherName.touched ||
                              refCustomer_FatherName.untouched)
                        }"
                        name="Customer_FatherName"
                        id="Customer_FatherName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> Gender</span>
                      <div class="">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="gender1"
                            #refCustomer_Gender="ngModel"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refCustomer_Gender.invalid,
                              'alert-warning':
                                refCustomer_Gender.invalid &&
                                (refCustomer_Gender.dirty ||
                                  refCustomer_Gender.touched ||
                                  refCustomer_Gender.untouched)
                            }"
                            [(ngModel)]="customerModel.Customer_Gender"
                            required
                            value="M"
                          />
                          <label class="form-check-label" for="gender1"
                            >Male</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="gender2"
                            #refCustomer_Gender="ngModel"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refCustomer_Gender.invalid,
                              'alert-warning':
                                refCustomer_Gender.invalid &&
                                (refCustomer_Gender.dirty ||
                                  refCustomer_Gender.touched ||
                                  refCustomer_Gender.untouched)
                            }"
                            [(ngModel)]="customerModel.Customer_Gender"
                            required
                            value="F"
                          />
                          <label class="form-check-label" for="gender2"
                            >Female</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> DOB</span>
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker5"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          name="Customer_DOB"
                          id="Customer_DOB"
                          #refCustomer_DOB="ngModel"
                          required
                          (dateChange)="getAge($event)"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_DOB.invalid,
                            'alert-warning':
                              refCustomer_DOB.invalid &&
                              (refCustomer_DOB.dirty ||
                                refCustomer_DOB.touched ||
                                refCustomer_DOB.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_DOB"
                          class="form-control input-text-css"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker5"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker5></mat-datepicker>
                      </div>
                      <!-- <input
                        name="Customer_DOB"
                        id="Customer_DOB"
                        #refCustomer_DOB="ngModel"
                        required
                        (ngModelChange)="getAge($event)"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_DOB.invalid,
                          'alert-warning':
                            refCustomer_DOB.invalid &&
                            (refCustomer_DOB.dirty ||
                              refCustomer_DOB.touched ||
                              refCustomer_DOB.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_DOB"
                        value="{{
                          customerModel.Customer_DOB | date : 'dd/MM/yyyy'
                        }}"
                        placeholder="DD/MM/YYYY"
                        class="form-control input-text-css"
                        [(bsValue)]="customerModel.Customer_DOB"
                        bsDatepicker
                      /> -->
                    </div>
                    <div class="col-md-2 mt-3">
                      <span> Age</span>
                      <input
                        name="Age"
                        id="Age"
                        disabled
                        value="{{ Age }}"
                        placeholder="Age"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> Primary Contact No.</span>
                      <!-- (blur)="checkPhonenumber()" -->
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        (blur)="checkPhonenumber()"
                        #refCustomer_PhoneNo="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo"
                        placeholder="Primary Contact No"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_PhoneNo.invalid,
                          'alert-warning':
                            refCustomer_PhoneNo.invalid &&
                            (refCustomer_PhoneNo.dirty ||
                              refCustomer_PhoneNo.touched ||
                              refCustomer_PhoneNo.untouched)
                        }"
                        name="Customer_PhoneNo"
                        id="Customer_PhoneNo"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-1 mt-3 p-0">
                      <span> &nbsp;</span>
                      <button
                        type="button"
                        (click)="ongetotp()"
                        [hidden]="!OTPButton"
                        *ngIf="!customerModel.Customer_PhoneNo_IsVerified"
                        class="btn font-size-12 button-btn"
                        style="width: max-content"
                      >
                        Get OTP
                      </button>
                      <div
                        class="col-md-12"
                        *ngIf="customerModel.Customer_PhoneNo_IsVerified"
                      >
                        <i
                          class="fa fa-check-circle ml-2"
                          style="color: green; font-size: 24px"
                        ></i>
                      </div>
                    </div>

                    <div class="col-md-4 mt-3">
                      <span> Alternate Contact No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_PhoneNo1="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo1"
                        placeholder="Alternate Contact No."
                        name="Customer_PhoneNo1"
                        id="Customer_PhoneNo1"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> WhatsApp Contact No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_WhatsAppNo="ngModel"
                        [(ngModel)]="customerModel.Customer_WhatsAppNo"
                        placeholder="WhatsApp Contact No"
                        name="Customer_WhatsAppNo"
                        id="Customer_WhatsAppNo"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Email</span>
                      <input
                        type="email"
                        #refCustomer_Email="ngModel"
                        [(ngModel)]="customerModel.Customer_Email"
                        placeholder="Email"
                        name="Customer_Email"
                        id="Customer_Email"
                        class="form-control input-text-css"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <span> Customer MaritalStatus </span>
                      <select
                        name="Customer_MaritalStatus"
                        id="Customer_MaritalStatus"
                        #refCustomer_MaritalStatus="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_MaritalStatus.invalid,
                          'alert-warning':
                            refCustomer_MaritalStatus.invalid &&
                            (refCustomer_MaritalStatus.dirty ||
                              refCustomer_MaritalStatus.touched ||
                              refCustomer_MaritalStatus.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_MaritalStatus"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select MaritalStatus</option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                        <option value="Widow">Widow/Widower</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Separated">Separated</option>
                        <option value="Never Married">Never Married</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Customer Religion </span>
                      <select
                        name="Customer_Religion"
                        id="Customer_Religion"
                        #refCustomer_Religion="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Religion.invalid,
                          'alert-warning':
                            refCustomer_Religion.invalid &&
                            (refCustomer_Religion.dirty ||
                              refCustomer_Religion.touched ||
                              refCustomer_Religion.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Religion"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Religion</option>
                        <option value="Hindus">Hindus</option>
                        <option value="Muslims">Muslims</option>
                        <option value="Christians">Christians</option>
                        <option value="Sikhs">Sikhs</option>
                        <option value="Buddhists">Buddhists</option>
                        <option value="Jains">Jains</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Customer Caste </span>
                      <select
                        name="Customer_Cast"
                        id="Customer_Cast"
                        #refCustomer_Cast="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Cast.invalid,
                          'alert-warning':
                            refCustomer_Cast.invalid &&
                            (refCustomer_Cast.dirty ||
                              refCustomer_Cast.touched ||
                              refCustomer_Cast.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Cast"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Caste</option>
                        <option value="General">General</option>
                        <option value="OBC">OBC</option>
                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!isCustomerEdit">
                  <hr />
                  <h1 class="fs-12">KYC Documents</h1>
                  <div
                    class="row mb-2"
                    *ngFor="let kyc of CustomerKYCDoc; let i = index"
                  >
                    <div class="col-md-3">
                      <span> Document Type </span>
                      <select
                        name="KYC_DocId{{ i }}"
                        id="KYC_DocId{{ i }}"
                        #refKYC_DocId="ngModel"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refKYC_DocId.invalid,
                          'alert-warning':
                            refKYC_DocId.invalid &&
                            (refKYC_DocId.dirty ||
                              refKYC_DocId.touched ||
                              refKYC_DocId.untouched)
                        }"
                        [(ngModel)]="kyc.KYC_DocId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Document type</option>
                        <option value="1">PAN Card</option>
                        <option value="2">Electricity Bill</option>
                        <option value="3">Passport Copy</option>
                        <option value="4">Voter ID Card</option>
                        <option value="5">Driving License</option>
                        <option value="6">Aadhaar Card</option>
                        <option value="7">Other Photo ID</option>
                        <option value="21">Ration Card</option>
                        <option value="22">DLl</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span> Document Number</span>
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        placeholder="Document Number"
                        required
                        (blur)="checkDuplicate(kyc)"
                        *ngIf="kyc.KYC_DocId != 1 && kyc.KYC_DocId != 6"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        *ngIf="kyc.KYC_DocId == 1"
                        placeholder="Document Number"
                        required
                        maxlength="10"
                        minlength="10"
                        (blur)="checkDuplicate(kyc)"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        numbersOnly
                        [(ngModel)]="kyc.KYC_DocNumber"
                        *ngIf="kyc.KYC_DocId == 6"
                        placeholder="Document Number"
                        required
                        (blur)="checkDuplicate(kyc)"
                        maxlength="12"
                        minlength="12"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> Document Image</span>
                      <p class="m-0 mt-2 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!kyc.KYC_DocFile"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="kyc.KYC_DocFile">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ kyc.KYC_DocFile }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFile(kyc, docFile)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                      <input
                        #docFile
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel"
                        (change)="fileChangeListener(kyc, docFile.files)"
                        style="display: none"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> Document Image1</span>
                      <p class="m-0 mt-2 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!kyc.KYC_DocFile1"
                          (click)="docFile1.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="kyc.KYC_DocFile1">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ kyc.KYC_DocFile1 }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFile2(kyc, docFile1)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                      <input
                        #docFile1
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel"
                        (change)="fileChangeListener2(kyc, docFile1.files)"
                        style="display: none"
                      />
                    </div>
                    <div class="col-md-2 row m-0 align-items-center">
                      <button
                        type="button"
                        class="remove"
                        (click)="removeKYC(i)"
                        *ngIf="
                          i < CustomerKYCDoc.length && CustomerKYCDoc.length > 1
                        "
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="addmore"
                        (click)="addMoreKYC()"
                        *ngIf="i == CustomerKYCDoc.length - 1"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-------------------------------------Customer address div start---------------------------------------------->

              <h1 class="fs-12 mt-3">Director Address</h1>
              <div class="row m-0">
                <div class="row m-0">
                  <h3 class="fw-7 fs-14 mt-2">Present Address</h3>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refPresentAddress="ngModel"
                        [(ngModel)]="customerAddressModel.Address"
                        placeholder="Address"
                        required
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentAddress.invalid,
                          'alert-warning':
                            refPresentAddress.invalid &&
                            (refPresentAddress.dirty ||
                              refPresentAddress.touched ||
                              refPresentAddress.untouched)
                        }"
                        name="PresentAddress"
                        id="PresentAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refPresentLandMark="ngModel"
                        [(ngModel)]="customerAddressModel.LandMark"
                        placeholder="LandMark"
                        required
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentLandMark.invalid,
                          'alert-warning':
                            refPresentLandMark.invalid &&
                            (refPresentLandMark.dirty ||
                              refPresentLandMark.touched ||
                              refPresentLandMark.untouched)
                        }"
                        name="PresentCustLandMark"
                        id="PresentCustLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refPresentPinCode="ngModel"
                        [(ngModel)]="customerAddressModel.PinCode"
                        placeholder="PinCode"
                        required
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentPinCode.invalid,
                          'alert-warning':
                            refPresentPinCode.invalid &&
                            (refPresentPinCode.dirty ||
                              refPresentPinCode.touched ||
                              refPresentPinCode.untouched)
                        }"
                        name="PresentPinCode"
                        id="PresentPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="PresentState"
                        id="PresentState"
                        #refPresentState="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentState.invalid,
                          'alert-warning':
                            refPresentState.invalid &&
                            (refPresentState.dirty ||
                              refPresentState.touched ||
                              refPresentState.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.StateId"
                        class="form-control input-text-css"
                        required
                        (change)="GetDistrickDropdownPresent()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="PresentsDistrict"
                        id="PresentsDistrict"
                        #refPresentDistrict="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentDistrict.invalid,
                          'alert-warning':
                            refPresentDistrict.invalid &&
                            (refPresentDistrict.dirty ||
                              refPresentDistrict.touched ||
                              refPresentDistrict.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.DistrictId"
                        class="form-control input-text-css"
                        required
                        (change)="GetTehasilDropdownPresent()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPresent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="PresentsTehsil"
                        id="PresentsTehsil"
                        #refPresentTehsil="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentTehsil.invalid,
                          'alert-warning':
                            refPresentTehsil.invalid &&
                            (refPresentTehsil.dirty ||
                              refPresentTehsil.touched ||
                              refPresentTehsil.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.TehsilId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPresent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Living Here</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PresentTotalYearsOnAddress"
                        id="PresentTotalYearsOnAddress"
                        #refPresentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerAddressModel.NoOfLiving"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted &&
                            refPresentTotalYearsOnAddress.invalid,
                          'alert-warning':
                            refPresentTotalYearsOnAddress.invalid &&
                            (refPresentTotalYearsOnAddress.dirty ||
                              refPresentTotalYearsOnAddress.touched ||
                              refPresentTotalYearsOnAddress.untouched)
                        }"
                        class="form-control input-text-css"
                        required
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="PresentAddressRentBuy"
                        id="PresentAddressRentBuy"
                        #refPresentAddressRentBuy="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentAddressRentBuy.invalid,
                          'alert-warning':
                            refPresentAddressRentBuy.invalid &&
                            (refPresentAddressRentBuy.dirty ||
                              refPresentAddressRentBuy.touched ||
                              refPresentAddressRentBuy.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.RentOwn"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refPresentNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="customerAddressModel.DistaneBranch"
                        placeholder="Distance From Branch"
                        required
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted &&
                            refPresentNearstBranchDistance_KM.invalid,
                          'alert-warning':
                            refPresentNearstBranchDistance_KM.invalid &&
                            (refPresentNearstBranchDistance_KM.dirty ||
                              refPresentNearstBranchDistance_KM.touched ||
                              refPresentNearstBranchDistance_KM.untouched)
                        }"
                        name="PresentNearstBranchDistance_KM"
                        id="PresentNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PresentIsCommunicationAddress"
                          class="form-check-input"
                          id="PresentIsCommunicationAddress"
                          [(ngModel)]="
                            customerAddressModel.PresentIsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="PresentIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row m-0 mt-3 col-md-12 align-items-center justify-content-between"
                  >
                    <h3 class="fw-7 fs-14 m-0 col-md-3 p-0">
                      Permanent Address
                    </h3>
                    <h3 class="fw-7 fs-14 m-0 col-md-3 p-0">
                      Corporate Address
                    </h3>
                    <div class="col-md-9">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="exampleCheck2"
                          class="form-check-input"
                          id="exampleCheck2"
                          [(ngModel)]="
                            customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress
                          "
                          (change)="checkCAddressSame1($event)"
                        />
                        <label class="form-check-label" for="exampleCheck2">
                          Select if Permanent Address same as Present Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refPermanentAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.Address"
                        placeholder="Address"
                        name="PermanentAddress"
                        id="PermanentAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refPermanentLandMark="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.LandMark"
                        placeholder="LandMark"
                        name="PermanentLandMark"
                        id="PermanentLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refPermanentPinCode="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="PermanentPinCode"
                        id="PermanentPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="PermanentState"
                        id="PermanentState"
                        #refPermanentState="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.StateId"
                        class="form-control input-text-css"
                        (change)="GetDistrickDropdownPermanent()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="PermanentDistrict"
                        id="PermanentDistrict"
                        #refPermanentDistrict="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.DistrictId"
                        class="form-control input-text-css"
                        (change)="GetTehasilDropdownPermanent()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPermanent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="PermanentTehsil"
                        id="PermanentTehsil"
                        #refPermanentTehsil="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.TehsilId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPermanent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Living Here</span>
                      <input
                        *ngIf="customerModel.Type == 'Indusial'"
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PermanentTotalYearsOnAddress"
                        id="PermanentTotalYearsOnAddress"
                        #refPermanentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.NoOfLiving"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="PermanentAddressRentBuy"
                        id="PermanentAddressRentBuy"
                        #refPermanentAddressRentBuy="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.RentOwn"
                        class="form-control input-text-css"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refPermanentNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="
                          customerPermanentAddressModel.DistanceBranch
                        "
                        placeholder="Distance From Branch"
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        name="PermanentNearstBranchDistance_KM"
                        id="PermanentNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PermanentIsCommunicationAddress"
                          class="form-check-input"
                          id="PermanentIsCommunicationAddress"
                          [(ngModel)]="
                            customerPermanentAddressModel.IsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="PermanentIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <h3 class="fw-7 fs-14 m-0 mt-3">Work Address</h3>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refWorkAddress="ngModel"
                        [(ngModel)]="customerWorkAddressModel.Address"
                        placeholder="Address"
                        name="WorkAddress"
                        id="WorkAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refWorkLandMark="ngModel"
                        [(ngModel)]="customerWorkAddressModel.LandMark"
                        placeholder="LandMark"
                        name="WorkLandMark"
                        id="WorkLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refWorkPinCode="ngModel"
                        [(ngModel)]="customerWorkAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="WorkPinCode"
                        id="WorkPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="WorkState"
                        id="WorkState"
                        #refWorkState="ngModel"
                        [(ngModel)]="customerWorkAddressModel.StateId"
                        class="form-control input-text-css"
                        (change)="GetDistrickDropdownWork()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="WorkDistrict"
                        id="WorkDistrict"
                        #refWorkDistrict="ngModel"
                        [(ngModel)]="customerWorkAddressModel.DistrictId"
                        class="form-control input-text-css"
                        (change)="GetTehasilDropdownWork()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownWork"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="WorkTehsil"
                        id="WorkTehsil"
                        #refWorkTehsil="ngModel"
                        [(ngModel)]="customerWorkAddressModel.TehsilId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownWork"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Working Here</span>
                      <!--<select
    name="WorkTotalYearsOnAddress"
    id="WorkTotalYearsOnAddress"
    #refWorkTotalYearsOnAddress="ngModel"
    [(ngModel)]="customerWorkAddressModel.NoOfLiving"
    class="form-control input-text-css"
  >
    <option value="">Select</option>
    <option
      value="0"
      *ngIf="customerModel.Type == 'Indusial'"
    >
      By birth
    </option>
    <option value="1">1 Year</option>
    <option value="2">2 Years</option>
    <option value="3">3 Years</option>
    <option value="4">4 Years</option>
    <option value="5">5 Years</option>
    <option value="6">6 Years</option>
    <option value="7">7 Years</option>
    <option value="8">8 Years</option>
    <option value="9">9 Years</option>
    <option value="10">10 Years</option>
    <option value="11">11 Years</option>
    <option value="12">12 Years</option>
    <option value="13">13 Years</option>
    <option value="14">14 Years</option>
    <option value="15">15 Years</option>
    <option value="16">16 Years</option>
    <option value="17">17 Years</option>
    <option value="18">18 Years</option>
    <option value="19">19 Years</option>
    <option value="20">20 Years</option>
  </select>-->
                      <input type="text"
                             numbersOnly
                             maxlength="3"
                             name="WorkTotalYearsOnAddress2"
                             id="WorkTotalYearsOnAddress2"
                             [(ngModel)]="customerWorkAddressModel.NoOfLiving"
                             class="form-control input-text-css" />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="WorkAddressRentBuy"
                        id="WorkAddressRentBuy"
                        #refWorkAddressRentBuy="ngModel"
                        [(ngModel)]="customerWorkAddressModel.RentOwn"
                        class="form-control input-text-css"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refWorkNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="customerWorkAddressModel.DistanceBranch"
                        placeholder="Distance From Branch"
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        name="WorkNearstBranchDistance_KM"
                        id="WorkNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="WorkIsCommunicationAddress"
                          class="form-check-input"
                          id="WorkIsCommunicationAddress"
                          [(ngModel)]="
                            customerWorkAddressModel.IsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="WorkIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseCustomer()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="btnSaveCust"
                #btnRegisterNewCustomer
                (click)="onRegisterNewCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="!isCustomerEdit"
                [class.spinner]="loading"
              >
                Save
              </button>
              <button
                type="button"
                id="btnUpdateCust"
                #btnUpdateCustomer
                (click)="onUpdateCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="isCustomerEdit"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Update
              </button>

              <!-- if updating full information of customer -->
              <button
                type="button"
                *ngIf="isUpdateAddress"
                (click)="onUpdateCustomerAddress()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Update
              </button>
            </div>
          </div>
        </ng-conatainer>
      </div>
    </div>
  </div>
</div>

<div
  id="modalVerifiedData"
  class="modal fade in"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  style="font-size: small"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow-y: auto"
>
  <div class="modal-dialog modal-dialog-centered" style="width: 400px">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle>
        <div class="modal-content">
          <div class="modal-header" style="padding: 6px">
            <h5 class="modal-title" style="font-size: small">
              Customer Information
            </h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body" style="padding: 13px">
            <div class="card">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
                alt="Customer Image"
                class="mt-2"
                style="
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  margin: auto;
                "
              />
              <div
                class="card-body"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 1"
              >
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Relation
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.care_of | slice : 0 : 4 }}
                  {{ APIResponse.care_of | slice : 4 }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  DOB:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.dob }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Gender:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.gender }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Address:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.house }},{{ APIResponse.street }},
                  {{ APIResponse.po }},{{ APIResponse.loc }},{{
                    APIResponse.vtc
                  }},{{ APIResponse.subdist }},
                  {{ APIResponse.landmark }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Pin Code:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.zip }}
                </p>
                <br />
                <h6
                  class="card-title"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  Verify PAN No
                </h6>
                <p
                  class="card-text"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  {{ Verify_PAN_No }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 4">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Relation:</h6>
                <p class="card-text">
                  {{ APIResponse.relation_type + " " }}
                  {{ APIResponse.relation_name }}
                </p>
                <br />

                <h6 class="card-title">age:</h6>
                <p class="card-text">{{ APIResponse.age }}</p>
                <br />
                <h6 class="card-title">Gender:</h6>
                <p class="card-text">
                  {{ APIResponse.gender == "F" ? "Female" : "Male" }}
                </p>
                <br />
                <h6 class="card-title">Area:</h6>
                <p class="card-text">
                  {{
                    APIResponse.area +
                      " " +
                      APIResponse.dist +
                      " " +
                      APIResponse.state
                  }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 29">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />

                <h6 class="card-title">PAN Number:</h6>
                <p class="card-text">{{ APIResponse.pan_number }}</p>
                <br />

                <h6 class="card-title">Orgnization Name:</h6>
                <p class="card-text">{{ APIResponse.business_name }}</p>
                <br />

                <h6 class="card-title">Signing Autority:</h6>
                <p class="card-text">{{ APIResponse.SigningAutority }}</p>
                <br />

                <h6 class="card-title">Firm Type:</h6>
                <p class="card-text">{{ APIResponse.FirmType }}</p>
                <br />

                <h6 class="card-title">Gst Registration Date:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">{{ APIResponse.gst_status }}</p>
                <br />

                <h6 class="card-title">Nature Bus Activities:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />

                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 28">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
                <h6 class="card-title">Company Name</h6>
                <p class="card-text">{{ APIResponse.company_name }}</p>
                <br />
                <h6 class="card-title">Registration Number</h6>
                <p class="card-text">{{ APIResponse.registration_number }}</p>
                <br />
                <h6 class="card-title">Company Category</h6>
                <p class="card-text">{{ APIResponse.company_category }}</p>
                <br />
                <h6 class="card-title">Company Sub Category</h6>
                <p class="card-text">{{ APIResponse.company_sub_category }}</p>
                <br />
                <h6 class="card-title">Class Of Company</h6>
                <p class="card-text">{{ APIResponse.class_of_company }}</p>
                <br />
                <h6 class="card-title">Company Status</h6>
                <p class="card-text">{{ APIResponse.company_status }}</p>
                <br />
                <h6 class="card-title">Authorized Capital</h6>
                <p class="card-text">{{ APIResponse.authorized_capital }}</p>
                <br />
                <h6 class="card-title">Paid Up Capital</h6>
                <p class="card-text">{{ APIResponse.paid_up_capital }}</p>
                <br />
                <h6 class="card-title">Date Of Incorporation</h6>
                <p class="card-text">{{ APIResponse.date_of_incorporation }}</p>
                <br />
                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <h1 class="fs-12 m-0">Directors Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Directors"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="din_number">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Din Number</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.din_number }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="director_name">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Director Name</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.director_name }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Start Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.start_date }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >End Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.end_date }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="surrendered_din">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Surrendered Din
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.surrendered_din }}
                          </mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedDirectorsColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedDirectorsColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                  style="margin-top: 25px !important; font-weight: bold"
                >
                  <h1 class="fs-12 m-0">Charges Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Charges"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="assets_under_charge">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Assets Under Charge
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.assets_under_charge }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="charge_amount">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Charge Amount</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.charge_amount }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_creation">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Creation
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.date_of_creation }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_modification">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Modification
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.date_of_modification }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Status</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.status }}
                          </mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedChargesColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedChargesColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 5">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <b class="card-title">permanent address:</b>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
                <h6 class="card-title">DOB:</h6>
                <p class="card-text">{{ APIResponse.dob }}</p>
                <br />
                <h6 class="card-title">Issue Date:</h6>
                <p class="card-text">{{ APIResponse.doi }}</p>
                <br />
                <h6 class="card-title">Expiry Date:</h6>
                <p class="card-text">{{ APIResponse.doe }}</p>
                <br />
                <h6 class="card-title">Issued By:</h6>
                <p class="card-text">{{ APIResponse.ola_name }}</p>
                <br />

                <h6 class="card-title">vehicle classes:</h6>
                <p class="card-text">{{ APIResponse.vehicle_classes }}</p>
                <br />
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 2">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Address:</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <h6 class="card-title">Mobile:</h6>
                <p class="card-text">{{ APIResponse.mobile }}</p>
                <br />
                <h6 class="card-title">State:</h6>
                <p class="card-text">{{ APIResponse.state }}</p>
                <br />
                <h6 class="card-title">Operator Code:</h6>
                <p class="card-text">{{ APIResponse.operator_code }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row m-0 col-md-12">
              <div class="col-md-5">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified By
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedBy }}
                </p>
              </div>
              <div class="col-md-5">
                <h6 class="fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  Verified On
                </h6>
                <p class="lead fs-12" *ngIf="APIResponse.LastVerfiedBy">
                  {{ APIResponse.LastVerfiedDate }}
                </p>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  class="btn font-size-12 button-btn"
                  data-dismiss="modal"
                  mat-raised-button
                  style="font-size: smaller"
                  color="secondary"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Verified_Detail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Document Detail
        </h6>
        <button
          type="button"
          (click)="OnClosemodalVerifiedData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1">
          <div
            class="col-md-12"
            *ngIf="
              verify_DocId == 1 || verify_DocId == 29 || verify_DocId == 28
            "
          >
            <h6 class="fs-12">Document Number</h6>
            <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 1">
            <h6 class="fs-12">Full Name</h6>
            <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 6">
            <h6 class="fs-12"></h6>
            <p class="lead fs-12">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                class="rounded-circle"
                style="width: 122px"
                alt="user image"
              />
            </p>
          </div>
          <div class="col-md-8 margin-user-detail" *ngIf="verify_DocId == 6">
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">Document Number</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">Full Name</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">C/O</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.care_of }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            *ngIf="verify_DocId == 4 || verify_DocId == 6 || verify_DocId == 1"
          >
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Document Number</h6>
            <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Full Name</h6>
            <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">C/O</h6>
            <p class="lead fs-12">{{ APIResponse.care_of }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">DOB</h6>
            <p class="lead fs-12">{{ APIResponse.dob }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 1">
            <h6 class="fs-12">category</h6>
            <p class="lead fs-12">{{ APIResponse.category }}</p>
          </div>

          <div class="col-md-3" *ngIf="verify_DocId == 6 || verify_DocId == 4">
            <h6 class="fs-12">Gender</h6>
            <p class="lead fs-12">{{ APIResponse.gender }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Age</h6>
            <p class="lead fs-12">{{ APIResponse.age }}</p>
          </div>

          <div class="col-md-12" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">
              {{ APIResponse.house }},{{ APIResponse.street }},
              {{ APIResponse.po }},{{ APIResponse.loc }},{{
                APIResponse.vtc
              }},{{ APIResponse.subdist }},
              {{ APIResponse.landmark }}
            </p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">Pincode</h6>
            <p class="lead fs-12">{{ APIResponse.zip }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Area</h6>
            <p class="lead fs-12">{{ APIResponse.area }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">District</h6>
            <p class="lead fs-12">{{ APIResponse.dist }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 6 || verify_DocId == 4">
            <h6 class="fs-12">state</h6>
            <p class="lead fs-12">{{ APIResponse.state }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-1" *ngIf="verify_DocId == 29">
          <div class="col-md-3" *ngIf="verify_DocId == 29">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">PAN Number</h6>
            <p class="lead fs-12">{{ APIResponse.pan_number }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Orgnization Name</h6>
            <p class="lead fs-12">{{ APIResponse.business_name }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Signing Autority</h6>
            <p class="lead fs-12">{{ APIResponse.SigningAutority }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Firm Type</h6>
            <p class="lead fs-12">{{ APIResponse.FirmType }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Gst Registration Date</h6>
            <p class="lead fs-12">{{ APIResponse.Gst_Registration_Date }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">{{ APIResponse.gst_status }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Nature Bus Activities</h6>
            <p class="lead fs-12">{{ APIResponse.nature_bus_activities }}</p>
          </div>
          <div class="col-md-12">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">{{ APIResponse.address }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-1" *ngIf="verify_DocId == 28">
          <div class="col-md-3">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Name</h6>
            <p class="lead fs-12">{{ APIResponse.company_name }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Registration Number</h6>
            <p class="lead fs-12">{{ APIResponse.registration_number }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Category</h6>
            <p class="lead fs-12">{{ APIResponse.company_category }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Sub Category</h6>
            <p class="lead fs-12">{{ APIResponse.company_sub_category }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Class Of Company</h6>
            <p class="lead fs-12">{{ APIResponse.class_of_company }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Status</h6>
            <p class="lead fs-12">{{ APIResponse.company_status }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Authorized Capital</h6>
            <p class="lead fs-12">{{ APIResponse.authorized_capital }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Paid Up Capital</h6>
            <p class="lead fs-12">{{ APIResponse.paid_up_capital }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Date Of Incorporation</h6>
            <p class="lead fs-12">{{ APIResponse.date_of_incorporation }}</p>
          </div>
          <div class="col-md-12">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">{{ APIResponse.address }}</p>
          </div>
        </div>
        <div
          class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
          [hidden]="!Directors"
          *ngIf="verify_DocId == 28"
        >
          <h1 class="fs-12 m-0">Directors Details</h1>
        </div>
        <div class="row m-0" [hidden]="!Directors" *ngIf="verify_DocId == 28">
          <div class="col-md-12">
            <div class="table-responsive">
              <mat-table
                [dataSource]="Directors"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="din_number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Din Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.din_number }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="director_name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Director Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.director_name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="start_date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Start Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.start_date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="end_date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >End Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.end_date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="surrendered_din">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Surrendered Din
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.surrendered_din }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedDirectorsColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="
                    let row;
                    let i = index;
                    columns: displayedDirectorsColumns
                  "
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div
          class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
          [hidden]="!Charges"
          *ngIf="verify_DocId == 28"
          style="margin-top: 25px !important; font-weight: bold"
        >
          <h1 class="fs-12 m-0">Charges Details</h1>
        </div>
        <div class="row m-0" [hidden]="!Charges" *ngIf="verify_DocId == 28">
          <div class="col-md-12">
            <div class="table-responsive">
              <mat-table
                [dataSource]="Charges"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="assets_under_charge">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Assets Under Charge
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.assets_under_charge }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="charge_amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Charge Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.charge_amount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date_of_creation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Date Of Creation
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.date_of_creation }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date_of_modification">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Date Of Modification
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.date_of_modification }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.status }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedChargesColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="
                    let row;
                    let i = index;
                    columns: displayedChargesColumns
                  "
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div
          class="row col-md-12 m-0 mt-1 justify-content-end"
          *ngIf="verify_DocId == 6 || verify_DocId == 29"
        >
          <button
            type="button"
            (click)="OnClosemodalVerifiedData()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AdhaarOTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Adhaar OTP Verification
        </h6>
        <button
          type="button"
          (click)="onCloseAdhaarOTP()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #aaotp="ngForm"
          (ngSubmit)="onSAVEAdhaaKYCOTP()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refAdhaarOtp="ngModel"
                id="AdhaarOtp"
                numbersOnly
                maxlength="6"
                minlength="6"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{
                  'is-invalid': aaotp.submitted && refAdhaarOtp.invalid,
                  'alert-warning':
                    refAdhaarOtp.invalid &&
                    (refAdhaarOtp.dirty ||
                      refAdhaarOtp.touched ||
                      refAdhaarOtp.untouched)
                }"
                name="AdhaarOtp"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onSAVEAdhaaKYCOTP()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!aaotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="duplicateViewData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Details
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="Duplicate.length > 0">
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <div class="alert alert-danger" *ngIf="!NotUpdate">
                Customer already exist with this entered {{ duplicateType }}. Do
                you want to update this customer detail?
              </div>
              <div class="alert alert-danger" *ngIf="NotUpdate">
                Customer already exist with this entered
                {{ duplicateType }}.Please add existing customer.
              </div>
            </div>
          </div>

          <table class="table table-bordered">
            <tr>
              <th style="width: 130px">
                <h6>Name</h6>
              </th>
              <td colspan="2">
                <h6>
                  {{ duplicateData.customer.Customer_FirstName }}
                  {{ duplicateData.customer.Customer_LastName }}
                </h6>
              </td>
              <td rowspan="2">
                <div class="profileImg">
                  <img
                    src="{{ customerProfileBaseUrl }}{{
                      duplicateData.customer.CustomerId
                    }}/{{ duplicateData.customer.Customer_ProfilePic }}"
                    alt=""
                    onerror="this.onerror=null;this.src='assets/images/default-user.png'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <h6>Relation Name</h6>
              </th>
              <td colspan="2">
                <h6>
                  {{ duplicateData.customer.Customer_Relation_Type }}
                  {{ duplicateData.customer.Customer_Relation_FirstName }}
                  {{ duplicateData.customer.Customer_Relation_LastName }}
                </h6>
              </td>
            </tr>

            <tr>
              <th>
                <h6>Gender</h6>
              </th>
              <td>
                <h6>
                  {{
                    duplicateData.customer.Customer_Gender == "M"
                      ? "Male"
                      : "Female"
                  }}
                </h6>
              </td>
              <th>
                <h6>DOB</h6>
              </th>
              <td>
                <h6>
                  {{
                    duplicateData.customer.Customer_DOB | date : "dd/MM/yyyy"
                  }}
                </h6>
              </td>
            </tr>
            <tr>
              <th>
                <h6>Alternate Number</h6>
              </th>
              <td>
                <h6>{{ duplicateData.customer.Customer_PhoneNo1 }}</h6>
              </td>
              <th>
                <h6>WhatsApp Number</h6>
              </th>
              <td>
                <h6>{{ duplicateData.customer.Customer_WhatsAppNo }}</h6>
              </td>
            </tr>
            <tr>
              <th>
                <h6>E-mail</h6>
              </th>
              <td colspan="3">
                <h6>{{ duplicateData.customer.Customer_Email }}</h6>
              </td>
            </tr>
            <tr *ngIf="duplicateData.address[0].AddressType == 'Present'">
              <th>
                <h6>Present Address</h6>
              </th>
              <td colspan="3">
                <h6>
                  {{ duplicateData.address[0].Address }}
                  {{ duplicateData.address[0].Landmark }},{{
                    duplicateData.address[0].Tehsil_Name
                  }},{{ duplicateData.address[0].District_Name }},{{
                    duplicateData.address[0].State_Name
                  }}- {{ duplicateData.address[0].Pincode }}
                </h6>
              </td>
            </tr>
            <tr *ngIf="duplicateData.address[1].AddressType == 'Permanent'">
              <th>
                <h6>Permanent Address</h6>
              </th>
              <td colspan="3">
                <h6>
                  {{ duplicateData.address[1].Address
                  }}{{ duplicateData.address[1].Landmark }},{{
                    duplicateData.address[1].Tehsil_Name
                  }},{{ duplicateData.address[1].District_Name }},{{
                    duplicateData.address[1].State_Name
                  }}-{{ duplicateData.address[1].Pincode }}
                </h6>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          *ngIf="!NotUpdate"
          (click)="onNoClick()"
          class="btn font-size-12 button-btn"
        >
          No
        </button>
        <button
          type="button"
          *ngIf="!NotUpdate"
          (click)="onYesClick()"
          class="ml-4 btn font-size-12 button-btn"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="duplicateViewFirmData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Details
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0 mt-3" *ngIf="DuplicateFirm.length > 0">
          <div class="row m-0 col-md-12">
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              Customer already exist with this entered {{ duplicateType }}. Are
              you want to update this customer details?
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="profileImg" *ngIf="duplicateFirmData">
              <img
                src="{{ customerProfileBaseUrl }}{{
                  duplicateFirmData.customer.CustomerId
                }}/{{ duplicateFirmData.customer.Customer_ProfilePic }}"
                alt=""
                onerror="this.onerror=null;this.src='assets/images/default-user.png';"
              />
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">First Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_FirstName }}
              </p>
            </div>
          </div>
          <!--<div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Last Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12">{{duplicateFirmData.customer.Customer_LastName}}</p>
            </div>
          </div>-->
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Father/Spouse Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_FatherName }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Gender</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_Gender }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">DOB</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_DOB }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Primary Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData && currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_PhoneNo |mask}}
              </p>
              <p class="lead fs-12" *ngIf="duplicateFirmData && !currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_PhoneNo }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Alternate Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData && currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_PhoneNo1 |mask}}
              </p>
              <p class="lead fs-12" *ngIf="duplicateFirmData && !currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_PhoneNo1 }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">WhatsApp Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData && currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_WhatsAppNo |mask }}
              </p>
              <p class="lead fs-12" *ngIf="duplicateFirmData && !currentUser.IsMasking">
                {{ duplicateFirmData.customer.Customer_WhatsAppNo }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Email</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_Email }}
              </p>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-center">
            <button
              type="button"
              (click)="onNoFirmClick()"
              class="btn font-size-12 button-btn"
            >
              No
            </button>
            <button
              type="button"
              (click)="onYesFirmClick()"
              class="ml-4 btn font-size-12 button-btn"
            >
              yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--------------------------------- Firm popup model------------------------------------- -->

<div
  class="modal fade in"
  id="addDirector"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Director To Application
        </h6>
        <button
          type="button"
          (click)="onCloseDirector()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="applicationDetail">
          <div class="col-md-3 pl-0">
            <h6 class="fs-12">Application No</h6>
            <p class="lead fs-12 fw-7">{{ applicationDetail.ApplicationNo }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Loan Product</h6>
            <p class="lead fs-12">{{ applicationDetail.Product }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Branch</h6>
            <p class="lead fs-12">{{ applicationDetail.Branch }}</p>
          </div>
        </div>

        <ng-container *ngIf="isDirectorExistingCustomer">
          <div class="row m-0 align-items-end">
            <div class="col-md-3 p-1">
              <span> Search By: </span>
              <select
                name="FilterSearchBy"
                id="FilterSearchBy"
                class="form-control input-text-css"
                [(ngModel)]="FilterSearchBy"
              >
                <option value="">Select</option>
                <option value="Name">Customer Name</option>
                <option value="Phone">Customer Phone</option>
                <option value="Pan">PAN Card</option>
                <option value="Passport">Passport Copy</option>
                <option value="VoterId">Voter ID Card</option>
                <option value="DL">Driving License</option>
                <option value="Aadhar">Aadhaar Card</option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span> Value: </span>
              <input
                name="FilterSearchValue"
                id="FilterSearchValue"
                placeholder="Value"
                [(ngModel)]="FilterSearchValue"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <button
                class="btn font-size-12 button-btn"
                (click)="getSearchCusList()"
              >
                Search
              </button>
            </div>
            <div class="col-md-3 p-1">
              <button
                class="btn font-size-12 button-btn"
                (click)="registereNewDirector()"
              >
                Register a New Director
              </button>
            </div>
            <div class="table-responsive mt-3">
              <mat-table [dataSource]="dataSource" id="exportReport">
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Customer
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomertName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Father Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.FatherName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    Gender
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                  >
                    {{ row.Customer_Gender }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    Age
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                  >
                    {{ row.GenderAge }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Phone No
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"
                            class="grid-cell"
                            style="max-width: 150px; justify-content: center">
                    <div *ngIf="currentUser.IsMasking">{{row.PhoneNo |mask}}</div>
                    <div *ngIf="!currentUser.IsMasking"> {{row.PhoneNo}}</div>
                    <i *ngIf="row.Customer_PhoneNo_IsVerified == true"
                       class="fa fa-check-circle ml-2"
                       style="color: green"></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Present_Address">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Present Address
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px; justify-content: center"
                  >
                    {{ row.Present_Address }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Select">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 100px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Select
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 100px; justify-content: center"
                  >
                    <input
                      type="checkbox"
                      [value]="row.CustomerId"
                      [checked]="customerModel.CustomerId == row.CustomerId"
                      (change)="onCustomerChange($event, row)"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseDirector()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onAddExistingCustomer_Firm()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Save
              </button>
            </div>
          </div>
        </ng-container>

        <ng-conatainer *ngIf="!isDirectorExistingCustomer">
          <div class="row m-0">
            <form
              #ancf="ngForm"
              (ngSubmit)="onRegisterNewCustomer()"
              novalidate
              class="col-md-12 p-0"
            >
              <div class="row">
                <div class="col-md-4">
                  <span> Type </span>
                  <select
                    name="Type"
                    id="Type"
                    #refType="ngModel"
                    [(ngModel)]="customerModel.Type"
                    class="form-control input-text-css"
                    (change)="changeCustomertype()"
                    [disabled]="isCustomerEdit"
                  >
                    <option value="Indusial">Individual</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span> Customer Type </span>
                  <select
                    name="Customer_Type"
                    id="Customer_Type"
                    #refCustomer_Type="ngModel"
                    [ngClass]="{
                      'is-invalid': ancf.submitted && refCustomer_Type.invalid,
                      'alert-warning':
                        refCustomer_Type.invalid &&
                        (refCustomer_Type.dirty ||
                          refCustomer_Type.touched ||
                          refCustomer_Type.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Type"
                    (change)="changeCustomerRelationType()"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Customer type</option>
                    <option value="H">Borrower</option>
                    <option value="C">CO-Borrower</option>
                    <option value="G">Guarantor</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="
                    customerModel.Customer_Type == 'C' ||
                    customerModel.Customer_Type == 'G'
                  "
                >
                  <span> Relation With Customer </span>
                  <select
                    name="Customer_Relation"
                    id="Customer_Relation"
                    #refCustomer_Relation="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        ancf.submitted && refCustomer_Relation.invalid,
                      'alert-warning':
                        refCustomer_Relation.invalid &&
                        (refCustomer_Relation.dirty ||
                          refCustomer_Relation.touched ||
                          refCustomer_Relation.untouched)
                    }"
                    [(ngModel)]="customerModel.Customer_Relation"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Uncle">Uncle</option>
                    <option value="Aunty">Aunty</option>
                    <option value="Grandfather">Grandfather</option>
                    <option value="Grandmother">Grandmother</option>
                    <option value="Father In-Law">Father In-Law</option>
                    <option value="Mother In-Law">Mother In-Law</option>
                    <option value="Son In-Law">Son In-Law</option>
                    <option value="Daughter In-Law">Daughter In-Law</option>
                    <option value="Brother In-Law">Brother In-Law</option>
                    <option value="Sister In-Law">Sister In-Law</option>
                    <option value="Uncle In-Law">Uncle In-Law</option>
                    <option value="Aunty In-Law">Aunty In-Law</option>
                    <option value="Grandfather In-Law">
                      Grandfather In-Law
                    </option>
                    <option value="Grandmother In-Law">
                      Grandmother In-Law
                    </option>
                    <option value="Friend">Friend</option>
                    <option value="Other">Other</option>
                    <option value="None">None</option>
                  </select>
                </div>
              </div>
              <hr />
              <ng-container *ngIf="customerModel.Type == 'Indusial'">
                <h1 class="fs-12">Demographic Information</h1>
                <div class="row">
                  <div class="col-md-3 row m-0 align-items-center">
                    <input
                      #cpFile
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="fileChangeListener1(cpFile.files)"
                      style="display: none"
                    />
                    <p
                      class="m-0 row"
                      *ngIf="!customerModel.Customer_ProfilePic"
                    >
                      Upload Customer Image:<i
                        class="fa fa-upload ml-2"
                        (click)="cpFile.click()"
                        aria-hidden="true"
                      ></i>
                    </p>
                    <ng-container *ngIf="customerModel.Customer_ProfilePic">
                      <div
                        class="c-img col-md-12"
                        *ngIf="customerModel.DocData"
                      >
                        <img
                          src="data:image/png;base64,{{
                            customerModel.DocData
                          }}"
                          alt=""
                        />
                      </div>
                      <div
                        class="c-img mr-2 col-md-12"
                        *ngIf="!customerModel.DocData"
                      >
                        <img
                          src="{{ customerProfileBaseUrl }}{{
                            customerModel.CustomerId
                          }}/{{ customerModel.Customer_ProfilePic }}"
                          alt=""
                        />
                      </div>
                      <div class="col-md-12 row m-0 mt-2 align-items-center">
                        {{ customerModel.Customer_ProfilePic }}
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile1(cpFile)"
                        ></i>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row col-md-9 m-0">
                    <div class="col-md-4">
                      <span> First Name</span>
                      <input
                        type="text"
                        #refCustomer_FirstName="ngModel"
                        [(ngModel)]="customerModel.Customer_FirstName"
                        placeholder="First Name"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_FirstName.invalid,
                          'alert-warning':
                            refCustomer_FirstName.invalid &&
                            (refCustomer_FirstName.dirty ||
                              refCustomer_FirstName.touched ||
                              refCustomer_FirstName.untouched)
                        }"
                        name="Customer_FirstName"
                        id="Customer_FirstName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> Last Name</span>
                      <input
                        type="text"
                        #refCustomer_LastName="ngModel"
                        [(ngModel)]="customerModel.Customer_LastName"
                        placeholder="Last Name"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_LastName.invalid,
                          'alert-warning':
                            refCustomer_LastName.invalid &&
                            (refCustomer_LastName.dirty ||
                              refCustomer_LastName.touched ||
                              refCustomer_LastName.untouched)
                        }"
                        name="Customer_LastName"
                        id="Customer_LastName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> Father/Spouse</span>
                      <input
                        type="text"
                        #refCustomer_FatherName="ngModel"
                        [(ngModel)]="customerModel.Customer_FatherName"
                        placeholder="Father/Spouse"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_FatherName.invalid,
                          'alert-warning':
                            refCustomer_FatherName.invalid &&
                            (refCustomer_FatherName.dirty ||
                              refCustomer_FatherName.touched ||
                              refCustomer_FatherName.untouched)
                        }"
                        name="Customer_FatherName"
                        id="Customer_FatherName"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> Gender</span>
                      <div class="">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="gender1"
                            #refCustomer_Gender="ngModel"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refCustomer_Gender.invalid,
                              'alert-warning':
                                refCustomer_Gender.invalid &&
                                (refCustomer_Gender.dirty ||
                                  refCustomer_Gender.touched ||
                                  refCustomer_Gender.untouched)
                            }"
                            [(ngModel)]="customerModel.Customer_Gender"
                            required
                            value="M"
                          />
                          <label class="form-check-label" for="gender1"
                            >Male</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="gender2"
                            #refCustomer_Gender="ngModel"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refCustomer_Gender.invalid,
                              'alert-warning':
                                refCustomer_Gender.invalid &&
                                (refCustomer_Gender.dirty ||
                                  refCustomer_Gender.touched ||
                                  refCustomer_Gender.untouched)
                            }"
                            [(ngModel)]="customerModel.Customer_Gender"
                            required
                            value="F"
                          />
                          <label class="form-check-label" for="gender2"
                            >Female</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> DOB</span>
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker6"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          name="Customer_DOB"
                          id="Customer_DOB"
                          #refCustomer_DOB="ngModel"
                          required
                          (dateChange)="getAge($event)"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_DOB.invalid,
                            'alert-warning':
                              refCustomer_DOB.invalid &&
                              (refCustomer_DOB.dirty ||
                                refCustomer_DOB.touched ||
                                refCustomer_DOB.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_DOB"
                          class="form-control input-text-css"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker6"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker6></mat-datepicker>
                      </div>
                      <!-- <input
                        name="Customer_DOB"
                        id="Customer_DOB"
                        #refCustomer_DOB="ngModel"
                        required
                        (ngModelChange)="getAge($event)"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_DOB.invalid,
                          'alert-warning':
                            refCustomer_DOB.invalid &&
                            (refCustomer_DOB.dirty ||
                              refCustomer_DOB.touched ||
                              refCustomer_DOB.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_DOB"
                        value="{{
                          customerModel.Customer_DOB | date : 'dd/MM/yyyy'
                        }}"
                        placeholder="DD/MM/YYYY"
                        class="form-control input-text-css"
                        [(bsValue)]="customerModel.Customer_DOB"
                        bsDatepicker
                      /> -->
                    </div>
                    <div class="col-md-2 mt-3">
                      <span> Age</span>
                      <input
                        name="Age"
                        id="Age"
                        disabled
                        value="{{ Age }}"
                        placeholder="Age"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-3">
                      <span> Primary Contact No.</span>
                      <!-- (blur)="checkPhonenumber()" -->
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        (blur)="checkPhonenumber()"
                        #refCustomer_PhoneNo="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo"
                        placeholder="Primary Contact No"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_PhoneNo.invalid,
                          'alert-warning':
                            refCustomer_PhoneNo.invalid &&
                            (refCustomer_PhoneNo.dirty ||
                              refCustomer_PhoneNo.touched ||
                              refCustomer_PhoneNo.untouched)
                        }"
                        name="Customer_PhoneNo"
                        id="Customer_PhoneNo"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-1 mt-3 p-0">
                      <span> &nbsp;</span>
                      <button
                        type="button"
                        (click)="ongetotp()"
                        [hidden]="!OTPButton"
                        *ngIf="!customerModel.Customer_PhoneNo_IsVerified"
                        class="btn font-size-12 button-btn"
                        style="width: max-content"
                      >
                        Get OTP
                      </button>
                      <div
                        class="col-md-12"
                        *ngIf="customerModel.Customer_PhoneNo_IsVerified"
                      >
                        <i
                          class="fa fa-check-circle ml-2"
                          style="color: green; font-size: 24px"
                        ></i>
                      </div>
                    </div>

                    <div class="col-md-4 mt-3">
                      <span> Alternate Contact No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_PhoneNo1="ngModel"
                        [(ngModel)]="customerModel.Customer_PhoneNo1"
                        placeholder="Alternate Contact No."
                        name="Customer_PhoneNo1"
                        id="Customer_PhoneNo1"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> WhatsApp Contact No.</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        #refCustomer_WhatsAppNo="ngModel"
                        [(ngModel)]="customerModel.Customer_WhatsAppNo"
                        placeholder="WhatsApp Contact No"
                        name="Customer_WhatsAppNo"
                        id="Customer_WhatsAppNo"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Email</span>
                      <input
                        type="email"
                        #refCustomer_Email="ngModel"
                        [(ngModel)]="customerModel.Customer_Email"
                        placeholder="Email"
                        name="Customer_Email"
                        id="Customer_Email"
                        class="form-control input-text-css"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <span> Customer MaritalStatus </span>
                      <select
                        name="Customer_MaritalStatus"
                        id="Customer_MaritalStatus"
                        #refCustomer_MaritalStatus="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_MaritalStatus.invalid,
                          'alert-warning':
                            refCustomer_MaritalStatus.invalid &&
                            (refCustomer_MaritalStatus.dirty ||
                              refCustomer_MaritalStatus.touched ||
                              refCustomer_MaritalStatus.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_MaritalStatus"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select MaritalStatus</option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                        <option value="Widow">Widow/Widower</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Separated">Separated</option>
                        <option value="Never Married">Never Married</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Customer Religion </span>
                      <select
                        name="Customer_Religion"
                        id="Customer_Religion"
                        #refCustomer_Religion="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Religion.invalid,
                          'alert-warning':
                            refCustomer_Religion.invalid &&
                            (refCustomer_Religion.dirty ||
                              refCustomer_Religion.touched ||
                              refCustomer_Religion.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Religion"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Religion</option>
                        <option value="Hindus">Hindus</option>
                        <option value="Muslims">Muslims</option>
                        <option value="Christians">Christians</option>
                        <option value="Sikhs">Sikhs</option>
                        <option value="Buddhists">Buddhists</option>
                        <option value="Jains">Jains</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <span> Customer Caste </span>
                      <select
                        name="Customer_Cast"
                        id="Customer_Cast"
                        #refCustomer_Cast="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refCustomer_Cast.invalid,
                          'alert-warning':
                            refCustomer_Cast.invalid &&
                            (refCustomer_Cast.dirty ||
                              refCustomer_Cast.touched ||
                              refCustomer_Cast.untouched)
                        }"
                        [(ngModel)]="customerModel.Customer_Cast"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Caste</option>
                        <option value="General">General</option>
                        <option value="OBC">OBC</option>
                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!isCustomerEdit">
                  <hr />
                  <h1 class="fs-12">KYC Documents</h1>
                  <div
                    class="row mb-2"
                    *ngFor="let kyc of CustomerKYCDoc; let i = index"
                  >
                    <div class="col-md-3">
                      <span> Document Type </span>
                      <select
                        name="KYC_DocId{{ i }}"
                        id="KYC_DocId{{ i }}"
                        #refKYC_DocId="ngModel"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refKYC_DocId.invalid,
                          'alert-warning':
                            refKYC_DocId.invalid &&
                            (refKYC_DocId.dirty ||
                              refKYC_DocId.touched ||
                              refKYC_DocId.untouched)
                        }"
                        [(ngModel)]="kyc.KYC_DocId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Document type</option>
                        <option value="1">PAN Card</option>
                        <option value="2">Electricity Bill</option>
                        <option value="3">Passport Copy</option>
                        <option value="4">Voter ID Card</option>
                        <option value="5">Driving License</option>
                        <option value="6">Aadhaar Card</option>
                        <option value="7">Other Photo ID</option>
                        <option value="21">Ration Card</option>
                        <option value="22">DLl</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span> Document Number</span>
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        placeholder="Document Number"
                        required
                        (blur)="checkDuplicate(kyc)"
                        *ngIf="kyc.KYC_DocId != 1 && kyc.KYC_DocId != 6"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        *ngIf="kyc.KYC_DocId == 1"
                        placeholder="Document Number"
                        required
                        maxlength="10"
                        minlength="10"
                        (blur)="checkDuplicate(kyc)"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                      <input
                        type="text"
                        #refKYC_DocNumber="ngModel"
                        numbersOnly
                        [(ngModel)]="kyc.KYC_DocNumber"
                        *ngIf="kyc.KYC_DocId == 6"
                        placeholder="Document Number"
                        required
                        (blur)="checkDuplicate(kyc)"
                        maxlength="12"
                        minlength="12"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refKYC_DocNumber.invalid,
                          'alert-warning':
                            refKYC_DocNumber.invalid &&
                            (refKYC_DocNumber.dirty ||
                              refKYC_DocNumber.touched ||
                              refKYC_DocNumber.untouched)
                        }"
                        name="KYC_DocNumber{{ i }}"
                        id="KYC_DocNumber{{ i }}"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> Document Image</span>
                      <p class="m-0 mt-2 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!kyc.KYC_DocFile"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="kyc.KYC_DocFile">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ kyc.KYC_DocFile }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFile(kyc, docFile)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                      <input
                        #docFile
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel"
                        (change)="fileChangeListener(kyc, docFile.files)"
                        style="display: none"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> Document Image1</span>
                      <p class="m-0 mt-2 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!kyc.KYC_DocFile1"
                          (click)="docFile1.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="kyc.KYC_DocFile1">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ kyc.KYC_DocFile1 }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFile2(kyc, docFile1)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                      <input
                        #docFile1
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel"
                        (change)="fileChangeListener2(kyc, docFile1.files)"
                        style="display: none"
                      />
                    </div>
                    <div class="col-md-2 row m-0 align-items-center">
                      <button
                        type="button"
                        class="remove"
                        (click)="removeKYC(i)"
                        *ngIf="
                          i < CustomerKYCDoc.length && CustomerKYCDoc.length > 1
                        "
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="addmore"
                        (click)="addMoreKYC()"
                        *ngIf="i == CustomerKYCDoc.length - 1"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-------------------------------------Customer address div start---------------------------------------------->

              <h1 class="fs-12 mt-3">Director Address</h1>
              <div class="row m-0">
                <div class="row m-0">
                  <h3 class="fw-7 fs-14 mt-2">Present Address</h3>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refPresentAddress="ngModel"
                        [(ngModel)]="customerAddressModel.Address"
                        placeholder="Address"
                        required
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentAddress.invalid,
                          'alert-warning':
                            refPresentAddress.invalid &&
                            (refPresentAddress.dirty ||
                              refPresentAddress.touched ||
                              refPresentAddress.untouched)
                        }"
                        name="PresentAddress"
                        id="PresentAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refPresentLandMark="ngModel"
                        [(ngModel)]="customerAddressModel.LandMark"
                        placeholder="LandMark"
                        required
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentLandMark.invalid,
                          'alert-warning':
                            refPresentLandMark.invalid &&
                            (refPresentLandMark.dirty ||
                              refPresentLandMark.touched ||
                              refPresentLandMark.untouched)
                        }"
                        name="PresentCustLandMark"
                        id="PresentCustLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refPresentPinCode="ngModel"
                        [(ngModel)]="customerAddressModel.PinCode"
                        placeholder="PinCode"
                        required
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentPinCode.invalid,
                          'alert-warning':
                            refPresentPinCode.invalid &&
                            (refPresentPinCode.dirty ||
                              refPresentPinCode.touched ||
                              refPresentPinCode.untouched)
                        }"
                        name="PresentPinCode"
                        id="PresentPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="PresentState"
                        id="PresentState"
                        #refPresentState="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentState.invalid,
                          'alert-warning':
                            refPresentState.invalid &&
                            (refPresentState.dirty ||
                              refPresentState.touched ||
                              refPresentState.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.StateId"
                        class="form-control input-text-css"
                        required
                        (change)="GetDistrickDropdownPresent()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="PresentsDistrict"
                        id="PresentsDistrict"
                        #refPresentDistrict="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentDistrict.invalid,
                          'alert-warning':
                            refPresentDistrict.invalid &&
                            (refPresentDistrict.dirty ||
                              refPresentDistrict.touched ||
                              refPresentDistrict.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.DistrictId"
                        class="form-control input-text-css"
                        required
                        (change)="GetTehasilDropdownPresent()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPresent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="PresentsTehsil"
                        id="PresentsTehsil"
                        #refPresentTehsil="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentTehsil.invalid,
                          'alert-warning':
                            refPresentTehsil.invalid &&
                            (refPresentTehsil.dirty ||
                              refPresentTehsil.touched ||
                              refPresentTehsil.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.TehsilId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPresent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Living Here</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PresentTotalYearsOnAddress"
                        id="PresentTotalYearsOnAddress"
                        #refPresentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerAddressModel.NoOfLiving"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted &&
                            refPresentTotalYearsOnAddress.invalid,
                          'alert-warning':
                            refPresentTotalYearsOnAddress.invalid &&
                            (refPresentTotalYearsOnAddress.dirty ||
                              refPresentTotalYearsOnAddress.touched ||
                              refPresentTotalYearsOnAddress.untouched)
                        }"
                        class="form-control input-text-css"
                        required
                        *ngIf="customerModel.Type == 'Indusial'"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="PresentAddressRentBuy"
                        id="PresentAddressRentBuy"
                        #refPresentAddressRentBuy="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted && refPresentAddressRentBuy.invalid,
                          'alert-warning':
                            refPresentAddressRentBuy.invalid &&
                            (refPresentAddressRentBuy.dirty ||
                              refPresentAddressRentBuy.touched ||
                              refPresentAddressRentBuy.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.RentOwn"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refPresentNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="customerAddressModel.DistaneBranch"
                        placeholder="Distance From Branch"
                        required
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid':
                            caf.submitted &&
                            refPresentNearstBranchDistance_KM.invalid,
                          'alert-warning':
                            refPresentNearstBranchDistance_KM.invalid &&
                            (refPresentNearstBranchDistance_KM.dirty ||
                              refPresentNearstBranchDistance_KM.touched ||
                              refPresentNearstBranchDistance_KM.untouched)
                        }"
                        name="PresentNearstBranchDistance_KM"
                        id="PresentNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PresentIsCommunicationAddress"
                          class="form-check-input"
                          id="PresentIsCommunicationAddress"
                          [(ngModel)]="
                            customerAddressModel.PresentIsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="PresentIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row m-0 mt-3 col-md-12 align-items-center justify-content-between"
                  >
                    <h3 class="fw-7 fs-14 m-0 col-md-3 p-0">
                      Permanent Address
                    </h3>
                    <h3 class="fw-7 fs-14 m-0 col-md-3 p-0">
                      Corporate Address
                    </h3>
                    <div class="col-md-9">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="exampleCheck2"
                          class="form-check-input"
                          id="exampleCheck2"
                          [(ngModel)]="
                            customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress
                          "
                          (change)="checkCAddressSame1($event)"
                        />
                        <label class="form-check-label" for="exampleCheck2">
                          Select if Permanent Address same as Present Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refPermanentAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.Address"
                        placeholder="Address"
                        name="PermanentAddress"
                        id="PermanentAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refPermanentLandMark="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.LandMark"
                        placeholder="LandMark"
                        name="PermanentLandMark"
                        id="PermanentLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refPermanentPinCode="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="PermanentPinCode"
                        id="PermanentPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="PermanentState"
                        id="PermanentState"
                        #refPermanentState="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.StateId"
                        class="form-control input-text-css"
                        (change)="GetDistrickDropdownPermanent()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="PermanentDistrict"
                        id="PermanentDistrict"
                        #refPermanentDistrict="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.DistrictId"
                        class="form-control input-text-css"
                        (change)="GetTehasilDropdownPermanent()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPermanent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="PermanentTehsil"
                        id="PermanentTehsil"
                        #refPermanentTehsil="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.TehsilId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPermanent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Living Here</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PermanentTotalYearsOnAddress"
                        id="PermanentTotalYearsOnAddress"
                        #refPermanentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.NoOfLiving"
                        class="form-control input-text-css"
                        *ngIf="customerModel.Type == 'Indusial'"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="PermanentAddressRentBuy"
                        id="PermanentAddressRentBuy"
                        #refPermanentAddressRentBuy="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.RentOwn"
                        class="form-control input-text-css"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refPermanentNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="
                          customerPermanentAddressModel.DistanceBranch
                        "
                        placeholder="Distance From Branch"
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        name="PermanentNearstBranchDistance_KM"
                        id="PermanentNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PermanentIsCommunicationAddress"
                          class="form-check-input"
                          id="PermanentIsCommunicationAddress"
                          [(ngModel)]="
                            customerPermanentAddressModel.IsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="PermanentIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <h3 class="fw-7 fs-14 m-0 mt-3">Work Address</h3>
                  <div class="row m-0 mt-2">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        type="text"
                        #refWorkAddress="ngModel"
                        [(ngModel)]="customerWorkAddressModel.Address"
                        placeholder="Address"
                        name="WorkAddress"
                        id="WorkAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> LandMark</span>
                      <input
                        type="text"
                        #refWorkLandMark="ngModel"
                        [(ngModel)]="customerWorkAddressModel.LandMark"
                        placeholder="LandMark"
                        name="WorkLandMark"
                        id="WorkLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refWorkPinCode="ngModel"
                        [(ngModel)]="customerWorkAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="WorkPinCode"
                        id="WorkPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> State</span>
                      <select
                        name="WorkState"
                        id="WorkState"
                        #refWorkState="ngModel"
                        [(ngModel)]="customerWorkAddressModel.StateId"
                        class="form-control input-text-css"
                        (change)="GetDistrickDropdownWork()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> District</span>
                      <select
                        name="WorkDistrict"
                        id="WorkDistrict"
                        #refWorkDistrict="ngModel"
                        [(ngModel)]="customerWorkAddressModel.DistrictId"
                        class="form-control input-text-css"
                        (change)="GetTehasilDropdownWork()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownWork"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-2">
                      <span> Tehsil</span>
                      <select
                        name="WorkTehsil"
                        id="WorkTehsil"
                        #refWorkTehsil="ngModel"
                        [(ngModel)]="customerWorkAddressModel.TehsilId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownWork"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> No of Year Working Here</span>
                      <!--<select
    name="WorkTotalYearsOnAddress"
    id="WorkTotalYearsOnAddress"
    #refWorkTotalYearsOnAddress="ngModel"
    [(ngModel)]="customerWorkAddressModel.NoOfLiving"
    class="form-control input-text-css"
  >
    <option value="">Select</option>
    <option
      value="0"
      *ngIf="customerModel.Type == 'Indusial'"
    >
      By birth
    </option>
    <option value="1">1 Year</option>
    <option value="2">2 Years</option>
    <option value="3">3 Years</option>
    <option value="4">4 Years</option>
    <option value="5">5 Years</option>
    <option value="6">6 Years</option>
    <option value="7">7 Years</option>
    <option value="8">8 Years</option>
    <option value="9">9 Years</option>
    <option value="10">10 Years</option>
    <option value="11">11 Years</option>
    <option value="12">12 Years</option>
    <option value="13">13 Years</option>
    <option value="14">14 Years</option>
    <option value="15">15 Years</option>
    <option value="16">16 Years</option>
    <option value="17">17 Years</option>
    <option value="18">18 Years</option>
    <option value="19">19 Years</option>
    <option value="20">20 Years</option>
  </select>-->

                      <input type="text"
                             numbersOnly
                             maxlength="3"
                             name="WorkTotalYearsOnAddress3"
                             id="WorkTotalYearsOnAddress3"
                             [(ngModel)]="customerWorkAddressModel.NoOfLiving"
                             class="form-control input-text-css" />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Rent/Own</span>
                      <select
                        name="WorkAddressRentBuy"
                        id="WorkAddressRentBuy"
                        #refWorkAddressRentBuy="ngModel"
                        [(ngModel)]="customerWorkAddressModel.RentOwn"
                        class="form-control input-text-css"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> Distance From Branch (KM)</span>
                      <input
                        type="text"
                        #refWorkNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="customerWorkAddressModel.DistanceBranch"
                        placeholder="Distance From Branch"
                        maxlength="3"
                        minlength="1"
                        numbersOnly
                        name="WorkNearstBranchDistance_KM"
                        id="WorkNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3 mt-2">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="WorkIsCommunicationAddress"
                          class="form-check-input"
                          id="WorkIsCommunicationAddress"
                          [(ngModel)]="
                            customerWorkAddressModel.IsCummunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="WorkIsCommunicationAddress"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseCustomer()"
                class="btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="btnSaveCust"
                #btnRegisterNewCustomer
                (click)="onRegisterNewCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="!isCustomerEdit"
                [class.spinner]="loading"
              >
                Save
              </button>
              <button
                type="button"
                id="btnUpdateCust"
                #btnUpdateCustomer
                (click)="onUpdateCustomer()"
                class="ml-4 btn font-size-12 button-btn"
                *ngIf="isCustomerEdit"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Update
              </button>

              <!-- if updating full information of customer -->
              <button
                type="button"
                *ngIf="isUpdateAddress"
                (click)="onUpdateCustomerAddress()"
                class="ml-4 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Update
              </button>
            </div>
          </div>
        </ng-conatainer>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="DLModelVerifiedData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Driving License Verification
        </h6>
        <button
          type="button"
          (click)="onCloseDLModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #dlf="ngForm"
          (ngSubmit)="onVerifiedDrivingLicence()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter DOB</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker7"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="DL_DOB"
                  id="DL_DOB"
                  #refDL_DOB="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': dlf.submitted && refDL_DOB.invalid,
                    'alert-warning':
                      refDL_DOB.invalid &&
                      (refDL_DOB.dirty ||
                        refDL_DOB.touched ||
                        refDL_DOB.untouched)
                  }"
                  [(ngModel)]="DL_DOB"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker7"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker7></mat-datepicker>
              </div>
              <!-- <input
                name="DL_DOB"
                id="DL_DOB"
                #refDL_DOB="ngModel"
                required
                [ngClass]="{
                  'is-invalid': dlf.submitted && refDL_DOB.invalid,
                  'alert-warning':
                    refDL_DOB.invalid &&
                    (refDL_DOB.dirty ||
                      refDL_DOB.touched ||
                      refDL_DOB.untouched)
                }"
                [(ngModel)]="DL_DOB"
                value="{{ DL_DOB | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="DL_DOB"
                bsDatepicker
              /> -->
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedDrivingLicence()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!dlf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="ElectricityModelVerifiedData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Electricity Verification
        </h6>
        <button
          type="button"
          (click)="onCloseElectricityModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #elf="ngForm"
          (ngSubmit)="onVerifiedElectricity()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Select State</span>
              <select
                name="ELState"
                id="ELState"
                [(ngModel)]="ELState"
                #refELState="ngModel"
                class="form-control input-text-css"
                required
                (change)="getElectricityOpCodelist()"
                [ngClass]="{
                  'is-invalid': elf.submitted && refELState.invalid,
                  'alert-warning':
                    refELState.invalid &&
                    (refELState.dirty ||
                      refELState.touched ||
                      refELState.untouched)
                }"
              >
                <option value="">Select State</option>
                <option
                  [value]="cus.state"
                  *ngFor="let cus of ElectricityStatelist"
                >
                  {{ cus.state }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <span class="required-lable">Select Operator Code</span>
              <select
                name="OperatorCode"
                id="OperatorCode"
                [(ngModel)]="OperatorCode"
                #refOperatorCode="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': elf.submitted && refOperatorCode.invalid,
                  'alert-warning':
                    refOperatorCode.invalid &&
                    (refOperatorCode.dirty ||
                      refOperatorCode.touched ||
                      refOperatorCode.untouched)
                }"
              >
                <option value="">Select Operator Code</option>
                <option
                  [value]="cus.Operator_code"
                  *ngFor="let cus of ElectricityOpCodelist"
                >
                  {{ cus.Operator_code }}
                </option>
              </select>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedElectricity()"
              class="ml-4 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!elf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
