import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { MobileService } from "../../Shared/app.Mobile.Service";
@Component({
  selector: "app-lms-fi",
  templateUrl: "./lms-fi.component.html",
  styleUrls: ["./lms-fi.component.scss"],
})
export class LmsFIComponent implements OnInit {
  currentUser: any;
  // TvrProcessList: any[] = [];
  showSpinner: boolean = false;
  //TvrinViewMode: boolean = true;
  view: boolean = false;
  status: any = "Pending";
  applicationCreatedDate: any = new Date();
  FIQuestionModel: any = {};
  loading: boolean = false;
  ProductList: any = [];
  CollectionExecutiveData: any;
  FI_DoneByName: any;
  questionData: any[] = [];
  questionOptionData: any[] = [];
  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  @Input() loanSummary: any;
  //Final Submit
  Recommendation: any = "";
  Remarks: any;
  AllAnswer: any;
  FI_DoneBy: any;
  FI_DoneOn: any = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  today: Date = new Date();
  displayedColumns: string[] = [];
  CurrentDate: any = new Date();

  customerBaseUrl: any = constantUrl.customerProfilePicUrl;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  customerData: any = {};
  //customerFIData: any = {};
  private _encdec;
  FamilyMemberModel: any = {};
  CustomerId: any;
  CustomerFI: any = {};
  ShowDiv: boolean = false;
  PageAcess: any;

  _MasterService: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private _MobileService: MobileService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this._encdec = encdec;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("FI");

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.GetCollectionExecutive();
    this.LMS_GetLoanDetails();
    sessionStorage.removeItem("curFIpage");
    sessionStorage.removeItem("curTVRpage");

    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    this.PageAcess = AllPage.filter((x) => x.Page_Name == "FI & TVR");

    if (
      this.PageAcess == "" ||
      this.PageAcess == null ||
      this.PageAcess == undefined
    ) {
      this.RedirectTo();
    }
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Lms_CollectionExecutive({
        Branch_Id: this.loanSummary.BranchId,
        ProductId: this.loanSummary.ProductId,
      })
      .subscribe((response) => {
        this.CollectionExecutiveData = response;
        console.log(
          "Collection Executive Data  name",
          this.CollectionExecutiveData
        );
      });
  }

  onNext() {
    this._MasterService
      .GetProductListById({ ProductId: this.loanSummary.ProductId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
        var H = "",
          C = "",
          G = "";
        H = ("" + this.ProductList[0].TVR.split(",")[0]).trim();
        C = ("" + this.ProductList[0].TVR.split(",")[1]).trim();
        G = ("" + this.ProductList[0].TVR.split(",")[2]).trim();
        var HFI = "",
          CFI = "",
          GFI = "",
          StatusFI = false;
        HFI = ("" + this.ProductList[0].FI.split(",")[0]).trim();
        CFI = ("" + this.ProductList[0].FI.split(",")[1]).trim();
        GFI = ("" + this.ProductList[0].FI.split(",")[2]).trim();

        for (let i = 0; i < this.customerData.length; i++) {
          if (this.customerData[i].CustomerType.charAt(0) == HFI.trim()) {
            if (this.customerData[i].FI_Status == "Completed") StatusFI = true;
            else {
              StatusFI = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete FI Of Hirer",
                ...this.configSuccess,
              });
              break;
            }
          }
          if (this.customerData[i].CustomerType.charAt(0) == CFI.trim()) {
            if (this.customerData[i].FI_Status == "Completed") StatusFI = true;
            else {
              StatusFI = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete FI Of Co-Borrower",
                ...this.configSuccess,
              });
              break;
            }
          }
          if (this.customerData[i].CustomerType.charAt(0) == GFI.trim()) {
            if (this.customerData[i].FI_Status == "Completed") StatusFI = true;
            else {
              StatusFI = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete FI Of Guarantor",
                ...this.configSuccess,
              });
              break;
            }
          }
        }
        var Next = false;

        if (StatusFI == true) Next = true;
        else Next = false;

        if (Next == true) {
          this.lmsService
            .SaveNext_Loan({
              Loan_Id: this.loanSummary.LoanId,
              CommandName: "FI",
              isStatus: 1,
            })
            .subscribe((response) => {
              this.action.emit("next");
            });
        }
      });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `los-tvr-process-${this.status}-list`,
      sheet: "tvr process",
    });
  }

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: "You have not authorize to access this page ?",
      ...this.configSuccess,
    });
    this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("LMS_GetLoanDetails", res);
        this.showSpinner = false;
        this.customerData = res.Item2;
        this.applicationCreatedDate = res.Item1[0].Application_CreateDate;
        if (res.Item2[0]) {
          this.OnClickFiQuestions(res.Item2[0]);
        }
      });
  }
  OnClickFiQuestions(row) {
    this.ShowDiv = true;
    console.log("row", row);

    this.CustomerId = row.CustomerId;

    this.GetLosCustomerFIQuestionAnswer();
  }

  GetLosCustomerFIQuestionAnswer() {
    //this.showSpinner = true;
    this._MobileService
      .LOS_GetFIQuestionsApp({
        ApplicationId: this.loanSummary.LoanId,
        CustomerId: this.CustomerId,
      })
      .subscribe((res: any) => {
        console.log("RES :", res);
        this.showSpinner = false;
        this.questionData = res;
        this.Recommendation = res[0].Recommendation;
        this.Remarks = res[0].Remark;
        this.FI_DoneBy = res[0].FI_DoneBy;

        this.FI_DoneByName = res[0].FI_DoneByName;

        this.FI_DoneOn = res[0].FI_DoneOn
          ? new Date(res[0].FI_DoneOn)
          : new Date();
        this.AllAnswer = res.filter(
          (x) => x.FIAnswer != "" && x.FIAnswer != null
        );
        console.log("this.AllAnswer", this.AllAnswer);
        if (this.Remarks != "" && this.Remarks != null) {
          this.IsEdit = true;
          this.IsSave = false;
          this.IsDisabled = true;
          this.IsUpdate = false;
        } else {
          this.IsEdit = false;
          this.IsSave = true;
          this.IsDisabled = false;
        }

        this.questionData.forEach((obj: any) => {
          if (obj.QuestionType == "Multi-Selection" && obj.FIAnswer != null) {
            obj.FIAnswer = obj.FIAnswer.split(",");
          }
        });
        this.questionData.forEach((obj: any) => {
          if (
            obj.QuestionType == "Selection" ||
            obj.QuestionType == "Multi-Selection"
          ) {
            obj.questionOptionData = obj.QuestionOptions.split("@@");
          }
        });
      });
  }

  fileChangeListener(data: any, files: any) {
    /*console.log("this.questionData[ this.questionData.length - 1].title", this.questionData[ this.questionData.length - 1].title);
    
    if (!this.questionData[ this.questionData.length - 1].title ) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter title.", ...this.configSuccess});
      files.value = "";
      return;
    }*/
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.FIAnswer = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.FIAnswer = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.FIAnswer = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.FIAnswer = "";
    delete data.DocData;
  }

  onSaveCustomerFIAnswer() {
    this.loading = true;
    var FIAnswer;
    this.FIQuestionModel = [];
    var arr = [];
    console.log("this.questionData : ", this.questionData);
    this.questionData.forEach((obj: any) => {
      if (obj.QuestionType == "Multi-Selection") {
        arr.push({
          FI_ApplicationId: this.loanSummary.LoanId,
          FI_CustomerId: this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer: obj.FIAnswer.join(","),
          FI_LoginUserId: this.currentUser.userId,
        });
      } else if (obj.QuestionType == "Upload") {
        arr.push({
          FI_ApplicationId: this.loanSummary.LoanId,
          FI_CustomerId: this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer: obj.FIAnswer,
          DocData: obj.DocData,
          FI_LoginUserId: this.currentUser.userId,
        });
        this.uploadDoc({
          ApplicationNo: this.loanSummary.ApplicationNo,
          DocName: obj.FIAnswer,
          DocData: obj.DocData,
        });
      } else {
        arr.push({
          FI_ApplicationId: this.loanSummary.LoanId,
          FI_CustomerId: this.CustomerId,
          FI_QuestionId: obj.QueId,
          FI_Answer: obj.FIAnswer,
          FI_LoginUserId: this.currentUser.userId,
        });
      }
    });
    this.FIQuestionModel = arr;
    this.CustomerFI.FI_ApplicationId = this.loanSummary.LoanId;
    this.CustomerFI.FI_CustomerId = this.CustomerId;
    this.CustomerFI.FI_LoginUserId = this.currentUser.userId;
    this.CustomerFI.FI_Remark = this.Remarks;
    this.CustomerFI.FI_Recommendation = this.Recommendation;
    this.CustomerFI.FI_DoneBy = this.FI_DoneBy;
    this.CustomerFI.FI_DoneOn = this.FI_DoneOn;
    FIAnswer = { FIAnswer: this.FIQuestionModel, CustomerFI: this.CustomerFI };

    console.log("this.FIAnswer : ", FIAnswer);

    if (new Date(this.applicationCreatedDate) <= new Date(this.FI_DoneOn)) {
      this._MobileService
        .LOS_SaveCustomerFIAnswerApp({ JSON: JSON.stringify(FIAnswer) })
        .subscribe((response: any) => {
          //console.log(response);
          if (response[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
            this.GetLosCustomerFIQuestionAnswer();
            this.LMS_GetLoanDetails();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
          this.loading = false;
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "FI Date always greater than Application Date.",
        ...this.configSuccess,
      });

      this.loading = false;
    }
  }

  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  onEditCustomerFIAnswer() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  CheckCustomerFIQuestions() {
    this.action.emit("next");
  }

  getExecutiveName(empId): string {
    // console.log("Finding executive name for ID:", empId);
    const executive = this.CollectionExecutiveData.find(
      (exec) => exec.EmpId == empId
    );
    // console.log("Found executive:", executive);
    return executive ? executive.Emp_FirstName : "";
  }
}
