import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  Injectable,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;
import * as moment from "moment";

@Component({
  selector: "app-lms-enach-response",
  templateUrl: "./lms-enach-response.component.html",
  styleUrls: ["./lms-enach-response.component.scss"],
})
export class LmsEnachResponseComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  loading: boolean = false;

  PostDateFrom: any;
  PostDateTo: any;
  LoanAcNo: any;

  dataSource: any;
  displayedColumns: string[] = [];
  JsonData: any[] = [];
  dialogreturn: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private lmsService: LmsService,
    private MasterService: MasterService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("E-Nach Response");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumns = [
      "Loan_Id",
      "LoanAcNo",
      "CustomerName",
      "Loan_Date",
      "Identifier",
      "AccountName",
      "DueDate",
      "EMI_Amount",
      "CustomerBankname",
    ];
  }

  onSearchENachResponse() {
    this.showSpinner = true;
    this.lmsService
      .ENach_Pending_Status({
        PostDateFrom: this.PostDateFrom,
        PostDateTo: this.PostDateTo,
        LoanAcNo: this.LoanAcNo,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
        this.showSpinner = false;
      });
  }

  public calculateTotal() {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["EMI_Amount"]);
      }
      return sum;
    }
    return 0;
  }

  TransactionVerification() {
    this.lmsService.ENach_GetTransactionVerification().subscribe((res: any) => {
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.onSearchENachResponse();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
}
