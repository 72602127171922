import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../services/los.service";
import { Router } from "@angular/router";
declare var $: any;
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";

@Component({
  selector: "app-los-pending-document-application-list",
  templateUrl: "./los-pending-document-application-list.component.html",
  styleUrls: ["./los-pending-document-application-list.component.scss"],
})
export class LosPendingDocumentApplicationListComponent implements OnInit {
  currentUser: any;
  selectedRowIndex: number = -1;
  PendingDocumentList: any[] = [];
  showSpinner: boolean = false;
  status: any = "Pending";
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next(
      "Pending Document Application List"
    );
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.LOS_GetPendingDocApplicationListWEB();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  LOS_GetPendingDocApplicationListWEB() {
    this.showSpinner = true;

    this.displayedColumns = [
      "ApplicationId",
      "Application_No",
      "Customer",
      "MobileNo",
      "Product",
      "Branch",
      "LoanAmount",
      "CreateOn",
      "Action",
    ];

    this.losService
      .LOS_GetPendingDocApplicationListWEB({
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.PendingDocumentList = JSON.parse(JSON.stringify(res));
        console.log(res);
        this.dataSource = new MatTableDataSource(this.PendingDocumentList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      });
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `PendingDocument-${this.status}-list`,
      sheet: "PendingDocuments",
    });
  }
  goToDetail(data: any) {
    // var applicationId = this.encdec.encrypt(data.ApplicationId);

    this.router.navigate([
      `/LOS/pending-documents/${encodeURIComponent(data.ApplicationId)}/`,
    ]);
  }
}
