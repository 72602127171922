import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { LmsService } from "../../_Lms/services/lms.service";

import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-loan-balance",
  templateUrl: "./loan-balance.component.html",
  styleUrls: ["./loan-balance.component.scss"],
})
export class LoanBalanceComponent implements OnInit {
  chhead: any = "";
  showSpinner: boolean = false;
  currentUser: any;
  checkSeeAll: any = true;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalCalculator: any;
  loading: boolean = false;
  FileCheckingList: any[] = [];
  dataSource: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  ChargesHead: any;
  chargesHeadDropdown: any[] = [];
  displayedColumns: string[] = [
    "Loan_Id",
    "Application_No",
    "LoanAcNo",
    "CustumerName",
    "Product_Name",
    "Application_LoanAmount",
    "OpeningBalanceDR",
    "OpeningBalanceCR",
    "TranTypeDR",
    "TranTypeCR",
    "ClosingBalanceDR",
    "ClosingBalanceCR",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  groupList: any[] = [];
  groupDropdown: any[] = [];
  BankList: any[] = [];
  groupModel: any = {};
  dropdownSettings: any = {};
  ProductList: any[] = [];
  myForm: UntypedFormGroup;
  ChargesHeadControl = new UntypedFormControl();
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  ProductId: any = 0;
  filteredChargesHead: Observable<any[]>;

  pageTitle: any = "Loan Balance";
  FyearId: any;
  maxDate: any;
  minDate: any;
  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  Cur_Date: any = new Date();
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private lmsService: LmsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder,
    private _commonfunctions: CommonFunctions
  ) {}
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Charges Balance");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getChargeshead();
    this.getProductDropdown();

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });
  }
  onChargesHeadhSelect(event: any) {
    this.ChargesHead = event.option.value.Id;
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result: any) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.chargesHeadDropdown.filter(
      (option) => option.ChagesHead.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(ChargesHead: any): string {
    return ChargesHead && ChargesHead.ChagesHead ? ChargesHead.ChagesHead : "";
  }
  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;

        this.filteredChargesHead = this.ChargesHeadControl.valueChanges.pipe(
          startWith(""),
          map((value) =>
            typeof value === "string" ? value : value.ChagesHead
          ),
          map((name) =>
            name ? this._filter(name) : this.chargesHeadDropdown.slice()
          )
        );
      });
  }
  onSearchAccountStatement() {
    this.showSpinner = true;
    if (this.ChargesHead != null) {
      this.reportsService
        .Get_Customer_Balance({
          HeadId: this.ChargesHead,
          From: this.VoucherDateFrom,
          To: this.VoucherDateTo,
          ProductId: this.ProductId,
        })
        .subscribe((res: any) => {
          //console.log(res);
          if (res.length > 0) {
            this.FileCheckingList = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(this.FileCheckingList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.getChargeshead();
            this.showSpinner = false;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.showSpinner = false;
          }
        });
    }
  }

  calculateACTranTypeDR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceDR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceCR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceCR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceDR() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("loan_balance") as HTMLTableElement;
    const worksheetName = "LOAN BALANCE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  onCloseLoanChargesPrintModel() {
    $("#loanChargesBalancePrint").modal("hide");
  }

  printdata() {
    $("#loanChargesBalancePrint").modal("show");
    $("#loanChargesBalancePrint").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }

  DateChange() {
    if (this.VoucherDateFrom != "" && this.VoucherDateTo != "") {
      if (this.VoucherDateFrom > this.VoucherDateTo) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.VoucherDateFrom = "";
        this.VoucherDateTo = "";
      }
    }
  }
  calculateLoanAmount() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(
          this.dataSource.filteredData[i]["Application_LoanAmount"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  onCheckedChanged(value) {
    console.log("Value ", value);
    if (value) {
      this.displayedColumns = [
        "Loan_Id",
        "Application_No",
        "LoanAcNo",
        "CustumerName",
        "Product_Name",
        "Application_LoanAmount",
        "OpeningBalanceDR",
        "OpeningBalanceCR",
        "TranTypeDR",
        "TranTypeCR",
        "ClosingBalanceDR",
        "ClosingBalanceCR",
      ];
    } else {
      this.displayedColumns = [
        "Loan_Id",
        "Application_No",
        "LoanAcNo",
        "CustumerName",
        "Product_Name",
        "Application_LoanAmount",
        "TranTypeDR",
        "TranTypeCR",
      ];
    }
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
