import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { FlatTreeControl } from "@angular/cdk/tree";
import { LosService } from '../../_LOS/services/los.service';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { ReportsService } from "../../_Reports/services/reports.service";

import { CommonFunctions } from "../../CommonComponent/function/commonfunctions";

interface TrialBalance {
  Group_Name: string;
  Balance?: number;
  Balance_Val: string;
  AccountId?: number;
  children?: TrialBalance[];
}

interface TrialBalanceFlatNode {
  expandable: boolean;
  Group_Name: string;
  Balance?: number;
  Balance_Val: string;
  level: number;
  AccountId: number;
}

@Component({
  selector: "app-branch-wise-pl",
  templateUrl: "./branch-wise-pl.component.html",
  styleUrls: ["./branch-wise-pl.component.scss"],
})
export class BranchWisePlComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  displayedCTreeolumns: string[] = [];
  displayedCTreeolumnsPrint: string[] = [];
  FileCheckingList: any[] = [];
  JSONData: any[] = [];
  SelectState: any='0';
  dataSourceAccount: any = '';
  dataSourceMonthly: any = '';
  dataSourceDaily: any = '';
  WithZero:  boolean = false;
  displayedColumnsMonthly: string[] = ['Id', 'Month_Year', 'Account_Name', 'TranTypeDR', 'TranTypeCR', 'TranTypeTotal', 'Action'];
  displayedColumnsDaily: string[] = ['Id', 'VoucherDate', 'Account_Name', 'TranTypeDR', 'TranTypeCR', 'TranTypeTotal', 'Action'];

  AccountName: any;
  DBData: any;
  BindData: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  loading: boolean = false;
  tab: any = "PL";
  IsGroupWise: any = "1";
  TREE_DATA: TrialBalance[] = [];
  SelectBranch: any = "0";
  stateDropdown: any[] = [];
  BranchesList: any = [];
  BSTab: boolean = false;
  PLTab: boolean = false;

  tot_dir_exp: number = 0;
  tot_dir_inc: number = 0;
  tot_indir_exp: number = 0;
  tot_indir_inc: number = 0;

  dir_exp: number = 0;
  dir_inc: number = 0;
  indir_exp: number = 0;
  indir_inc: number = 0;

  TOTAL_DIR_EXP: any = 0;
  TOTAL_DIR_INC: any = 0;
  TOTAL_INDIR_EXP: any = 0;
  TOTAL_INDIR_INC: any = 0;
  TOTAL_LIB: any = 0;
  TOTAL_Assets: any = 0;
  Gross_loss: number = 0;
  Gross_profit: number = 0;

  displayedAccountColumns: string[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  ShowDelete: boolean = false;
  accountTagDropdown: any[] = [];
  displayedAccountingColumns: string[] = ['AccountHead', 'Branch', 'AmountCR', 'AmountDR'];
  displayedLoanColumns: string[] = ['Type', 'CaseNo', 'Customer', 'Branch', 'Category', 'AccountHead', 'AmountCR', 'AmountDR'];
  VoucherDateFrom: any;
  VoucherDateTo: any;
  maxDate: any;
  minDate: any;
  FyearId: any;
  Gross_profit_DIRINC: any = 0;
  Gross_profit_DIREXP: any = 0;
  Gross_loss_DIRINC: any = 0;
  Gross_loss_DIREXP: any = 0;
  Gross_profit_INDIRINC: any = 0;
  Gross_loss_INDIRINC: any = 0;
  Gross_profit_Lib: any = 0;
  Gross_loss_Assets: any = 0;
  Gross_profit_INDIREXP: any = 0;
  Gross_loss_INDIREXP: any = 0;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  TotRow: any = 50;
  rowdata: any;

  treeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );
  LtreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );
  AtreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );

  DEtreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );

  IEtreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );

  DItreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );

  IItreeControl = new FlatTreeControl<TrialBalanceFlatNode>(
    node => node.level, node => node.expandable
  );
  
  private transformer = (node: TrialBalance, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Group_Name: node.Group_Name,
      Balance: node.Balance,
      Balance_Val: node.Balance_Val,
      AccountId: node.AccountId,
      level: level,
    };
  }
  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level,
    node => node.expandable, node => node.children
  );

  dataSourceLibTree: any = new MatTreeFlatDataSource(this.LtreeControl, this.treeFlattener);
  dataSourceAssetsTree: any = new MatTreeFlatDataSource(this.AtreeControl, this.treeFlattener);
  dataSourceDIREXPTree: any = new MatTreeFlatDataSource(this.DEtreeControl, this.treeFlattener);
  dataSourceINDIREXPTree: any = new MatTreeFlatDataSource(this.IEtreeControl, this.treeFlattener);
  dataSourceDIRINCTree: any = new MatTreeFlatDataSource(this.DItreeControl, this.treeFlattener);
  dataSourceINDIRINCTree: any = new MatTreeFlatDataSource(this.IItreeControl, this.treeFlattener);


  BalanceType: any = 0;
  DivAnnualy: boolean = true;
  DivMonthly: boolean = false;
  DivDaily: boolean = false;
  dataSourceModel: any;

  displayedColumnsModel: string[] = [
    "voucherid", "VoucherDate", "Voucher_No", "accHead", "TranTypeDR", "TranTypeCR", "TranTypeTotal", "Narration", "ViewAction"
  ];
  
  Fyears: any;
  ExpandBtn: boolean = true;
  CollaspeBtn: boolean = false;
  ExpandBtn1: boolean = true;
  CollaspeBtn1: boolean = false;

  constructor(private losService: LosService, private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar, private _MasterService: MasterService,
    private router: Router, private route: ActivatedRoute,
    private lmsService: LmsService,
    private dialog: DialogService, private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private _commonfunctions: CommonFunctions,
  ) {
    this.BSTab = true;
  }
  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Branch Wise P&L");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.GetStateDropdown();
    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        //console.log("paran", this.FyearId);
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.Fyears = res[0].fyear;
          this.minDate = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.maxDate = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
          this.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          
          if (this.maxDate > new Date() && this.minDate < new Date()){
            this.VoucherDateTo = new Date();
          }
          else{
            this.VoucherDateTo = this.maxDate;
          }
          this.getBranchWisePl();
        });
      }
    )

    this.displayedAccountColumns = [
      "voucherid", "Voucher_Date", "Voucher_No", "CaseNo", "accHead", "TranTypeDR", "TranTypeCR", "TranTypeTotal", "Narration", "ViewAction"
    ];
  }
  getBranchesList() {
    this.SelectBranch = '0';
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId, StateId :this.SelectState==''?0:this.SelectState})
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    let sh;
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.GroupId] = arrElem;
      mappedArr[arrElem.GroupId]['children'] = [];
    }


    for (var GroupId in mappedArr) {
      if (mappedArr.hasOwnProperty(GroupId)) {
        mappedElem = mappedArr[GroupId];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.Parent_GroupId) {
          mappedArr[mappedElem['Parent_GroupId']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  changeTab(tab: any) {


    if (tab != this.tab) {
      this.tab = tab;
    }

    if (tab == "BS") {
      this.BSTab = true;
      this.PLTab = false;

      this.showSpinner = false;
    }
    else {
      this.BSTab = false;
      this.PLTab = true;
    }
    // this.tot_dir_exp = this.calculateTRTotalByCRDR(3);
    // this.tot_dir_inc = this.calculateTRTotalByCRDR(5);
    // this.tot_indir_exp = this.calculateTRTotalByCRDR(4);
    // this.tot_indir_inc = this.calculateTRTotalByCRDR(6);
    if (this.BSTab) {
      this.ExpandBtn = true;
      this.CollaspeBtn = false;

    }
    if (this.PLTab) {
      this.ExpandBtn1 = true;
      this.CollaspeBtn1 = false;

    }
    var LIB = [];
    this.DBData.forEach(val => LIB.push(Object.assign({}, val)));
    LIB = LIB.filter(item => item.GroupId == 1);

    //console.log(tree_LIB);
    var LIB_Bal = 0
    if (LIB.length > 0) {
      LIB_Bal = LIB[0].Balance;
    }
    var assets = [];
    this.DBData.forEach(val => assets.push(Object.assign({}, val)));
    assets = assets.filter(item => item.GroupId == 2);

    //console.log(tree_assets);
    var assets_Bal = 0
    if (assets.length > 0) {
      assets_Bal = assets[0].Balance;
    }

    var DIREXP = [];
    this.DBData.forEach(val => DIREXP.push(Object.assign({}, val)));
    DIREXP = DIREXP.filter(item => item.GroupId == 3);
    if (DIREXP.length > 0) {
      this.dir_exp = DIREXP[0].Balance;
    }

    var INDIREXP = [];
    this.DBData.forEach(val => INDIREXP.push(Object.assign({}, val)));
    INDIREXP = INDIREXP.filter(item => item.GroupId == 4);



    var DIRINC = [];
    this.DBData.forEach(val => DIRINC.push(Object.assign({}, val)));
    DIRINC = DIRINC.filter(item => item.GroupId == 5);



    var INDIRINC = [];
    this.DBData.forEach(val => INDIRINC.push(Object.assign({}, val)));
    INDIRINC = INDIRINC.filter(item => item.GroupId == 6);



    if (INDIREXP.length > 0) {
      this.indir_exp = INDIREXP[0].Balance;
    }
    if (DIRINC.length > 0) {
      this.dir_inc = DIRINC[0].Balance;
    }
    if (INDIRINC.length > 0) {
      this.indir_inc = INDIRINC[0].Balance;
    }
    var DB_LIB = [];
    LIB.forEach(val => DB_LIB.push(Object.assign({}, val)));

    var DB_Assets = [];
    assets.forEach(val => DB_Assets.push(Object.assign({}, val)));

    var DB_DIRINC = [];
    DIRINC.forEach(val => DB_DIRINC.push(Object.assign({}, val)));

    var DB_DIREXP = [];
    DIREXP.forEach(val => DB_DIREXP.push(Object.assign({}, val)));

    var DB_INDIREXP = [];
    INDIREXP.forEach(val => DB_INDIREXP.push(Object.assign({}, val)));

    var DB_INDIRINC = [];
    INDIRINC.forEach(val => DB_INDIRINC.push(Object.assign({}, val)));

    if (LIB_Bal > 0) {
      DB_LIB.forEach(val => assets.push(val));
      LIB.splice(0, 1);

    }

    if (assets_Bal < 0) {
      DB_Assets.forEach(val => LIB.push(val));
      assets.splice(0, 1);
    }

    if (this.dir_exp < 0) {
      DB_DIREXP.forEach(val => DIRINC.push(val));
      DIREXP.splice(0, 1);
    }
    if (this.dir_inc > 0) {
      DB_DIRINC.forEach(val => DIREXP.push(val));
      DIRINC.splice(0, 1);
    }
    if (this.indir_exp < 0) {
      DB_INDIREXP.forEach(val => INDIRINC.push(val));
      INDIREXP.splice(0, 1);
    }
    if (this.indir_inc > 0) {
      DB_INDIRINC.forEach(val => INDIREXP.push(val));
      INDIRINC.splice(0, 1);
    }
    let tree_DIREXP = JSON.parse(JSON.stringify(DIREXP, null, " "));
    let tree_INDIREXP = JSON.parse(JSON.stringify(INDIREXP, null, " "));
    let tree_DIRINC = JSON.parse(JSON.stringify(DIRINC, null, " "));
    let tree_INDIRINC = JSON.parse(JSON.stringify(INDIRINC, null, " "));
    let tree_LIB = JSON.parse(JSON.stringify(LIB, null, " "));
    let tree_assets = JSON.parse(JSON.stringify(assets, null, " "));

    this.dataSourceAssetsTree.data = tree_assets;
    this.dataSourceLibTree.data = tree_LIB;
    this.dataSourceDIREXPTree.data = tree_DIREXP;
    this.dataSourceINDIREXPTree.data = tree_INDIREXP;
    this.dataSourceDIRINCTree.data = tree_DIRINC;
    this.dataSourceINDIRINCTree.data = tree_INDIRINC;

    //console.log(this.dir_exp+"---"+ this.indir_exp+"---"+ this.dir_inc+"---"+ this.indir_inc);
    let SUM_INDIREXP = INDIREXP.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0);
    let SUM_INDIRINC = (INDIRINC.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0));
    if (Number(SUM_INDIRINC) < 0) {
      SUM_INDIRINC = SUM_INDIRINC.toString().split("-")[1];
    }
    if (SUM_INDIRINC == undefined || SUM_INDIRINC == NaN)
      SUM_INDIRINC = 0;
    let SUM_DIREXP = DIREXP.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0);
    let SUM_DIRINC = (DIRINC.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0));
    if (Number(SUM_DIRINC) < 0) {
      SUM_DIRINC = SUM_DIRINC.toString().split("-")[1];
    }
    let SUM_LIB = LIB.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0);
    let SUM_Assets = assets.filter(item => item)
      .reduce((sum, current) => sum + Number(current.Balance), 0);


    if (SUM_DIREXP == undefined || SUM_DIREXP == NaN)
      SUM_DIREXP = 0;
    if (SUM_DIRINC == undefined || SUM_DIRINC == NaN)
      SUM_DIRINC = 0;


    if (Number(Number(SUM_DIREXP) - Number(SUM_DIRINC)) >= 0) {
      this.Gross_loss_DIRINC = (Number(SUM_DIREXP) - Number(SUM_DIRINC)).toString();
    }
    else {
      this.Gross_profit_DIREXP = (Number(SUM_DIREXP) - Number(SUM_DIRINC)).toString().split("-")[1];
    }

    this.Gross_loss_INDIREXP = Number(this.Gross_loss_DIRINC).toFixed(2);
    this.Gross_profit_INDIRINC = Number(this.Gross_profit_DIREXP).toFixed(2);

    

    SUM_INDIREXP = Number(SUM_INDIREXP) + Number(this.Gross_loss_INDIREXP);
    SUM_INDIRINC = Number(SUM_INDIRINC) + Number(this.Gross_profit_INDIRINC)
    
    if (SUM_INDIREXP == undefined || SUM_INDIREXP == NaN)
      SUM_INDIREXP = 0;
    if (SUM_INDIRINC == undefined || SUM_INDIRINC == NaN)
      SUM_INDIRINC = 0;

    //console.log("this.SUM_INDIREXP1", SUM_INDIREXP);
    //console.log("this.SUM_INDIRINC1", SUM_INDIRINC);

    this.Gross_loss_INDIRINC = 0;
    this.Gross_profit_INDIREXP = 0;
    if (Number(Number(SUM_INDIREXP) - Number(SUM_INDIRINC)) >= 0){
      this.Gross_loss_INDIRINC = Number((Number(SUM_INDIREXP) - Number(SUM_INDIRINC)).toString()).toFixed(2);
    }
    else{
      this.Gross_profit_INDIREXP = Number((Number(SUM_INDIREXP) - Number(SUM_INDIRINC)).toString().split("-")[1]).toFixed(2);
    }
    
    //let rest_amt, DIrInc, DIrExp;
    //DIrInc = (this.dir_inc > 0) ? Number(this.dir_inc) : Number(this.dir_inc.toString().split("-")[1]);
    //DIrExp = (this.dir_exp > 0) ? Number(this.dir_exp) : Number(this.dir_exp.toString().split("-")[1]);

    //if(this.dir_exp > 0 && this.dir_inc < 0){
    //  rest_amt = DIrInc - DIrExp;

    //  if(rest_amt > 0){
    //    this.Gross_profit = rest_amt;
    //    this.Gross_loss = 0;
    //  }
    //  else{
    //    this.Gross_loss = Number(rest_amt.toString().split("-")[1]);
    //    this.Gross_profit = 0;
    //  }
    //  //this.TOTAL_DIR_EXP = (DIrExp + this.Gross_profit).toFixed(2);
    // // this.TOTAL_DIR_INC = (DIrInc + this.Gross_loss).toFixed(2);
    //}
    //else if(this.dir_exp < 0 && this.dir_inc > 0){
    //  rest_amt = DIrExp - DIrInc;
    //  console.log(rest_amt);
    //  if(rest_amt > 0){
    //    this.Gross_profit = rest_amt;
    //    this.Gross_loss = 0;
    //  }
    //  else{
    //    this.Gross_loss = Number(rest_amt.toString().split("-")[1]);
    //    this.Gross_profit = 0;
    //  }
    //  //this.TOTAL_DIR_EXP = (DIrExp - this.Gross_profit).toFixed(2);
    //  //this.TOTAL_DIR_INC = (DIrInc - this.Gross_loss).toFixed(2);

    //}
    //else{
    //  if(this.dir_exp < 0 && this.dir_inc < 0){
    //    this.Gross_profit = Number(DIrInc) + Number(DIrExp);
    //    this.Gross_loss = 0;
    //  }
    //  else{
    //    this.Gross_loss = Number(DIrInc) + Number(DIrExp);
    //    this.Gross_profit = 0;
    //  }
    // // this.TOTAL_DIR_EXP = (DIrExp + this.Gross_profit).toFixed(2);
    // // this.TOTAL_DIR_INC = (Number(this.Gross_loss) - DIrInc).toFixed(2);
    //}
    this.Gross_profit_Lib = Number(this.Gross_profit_INDIREXP).toFixed(2);
    this.Gross_loss_Assets = Number(this.Gross_loss_INDIRINC).toFixed(2);

    console.log("this.Gross_profit_Lib", this.Gross_profit_Lib);

    //console.log(this.Gross_loss);

    if (this.Gross_profit_DIREXP == undefined || this.Gross_profit_DIREXP == NaN)
      this.Gross_profit_DIREXP = 0;


    if (this.Gross_loss_DIRINC == undefined || this.Gross_loss_DIRINC == NaN)
      this.Gross_loss_DIRINC = 0;


    if (this.Gross_profit_INDIREXP == undefined || this.Gross_profit_INDIREXP == NaN)
      this.Gross_profit_INDIREXP = 0;


    if (this.Gross_loss_INDIRINC == undefined || this.Gross_loss_INDIRINC == NaN)
      this.Gross_loss_INDIRINC = 0;
    this.TOTAL_DIR_EXP = (Number(SUM_DIREXP) + Number(this.Gross_profit_DIREXP)).toFixed(2);
    this.TOTAL_DIR_INC = (Number(SUM_DIRINC) + Number(this.Gross_loss_DIRINC)).toFixed(2);
    if (SUM_INDIRINC == undefined || SUM_INDIRINC == NaN)
      SUM_INDIRINC = 0;
    this.TOTAL_INDIR_EXP = (Number(SUM_INDIREXP) + Number(this.Gross_profit_INDIREXP)).toFixed(2);
    this.TOTAL_INDIR_INC = (Number(SUM_INDIRINC) + Number(this.Gross_loss_INDIRINC)).toFixed(2);


    //if(Number(this.indir_exp) < Number(this.indir_inc)){
    //  this.tot_indir_exp = (Number(this.indir_inc) + Number(this.tot_dir_exp)) - Number(this.indir_exp);
    //}
    //else{
    //  this.tot_indir_inc = Number(this.indir_exp) - Number(this.indir_inc);
    //}

    //this.TOTAL_INDIR_EXP = Number(this.indir_exp) + Number(this.tot_indir_exp);
    //this.TOTAL_INDIR_INC = Number(this.indir_inc) + Number(this.tot_dir_exp);

    /*this.tot_dir_exp = DIREXP[0].Balance;
    this.tot_indir_exp = INDIREXP[0].Balance;
    this.tot_dir_inc = DIRINC[0].Balance;
    this.tot_indir_inc = INDIRINC[0].Balance;

    let dir_exp = DIREXP[0].Balance;
    let indir_exp = INDIREXP[0].Balance;
    let dir_inc = DIRINC[0].Balance;
    let indir_inc = INDIRINC[0].Balance;
    console.log(this.tot_dir_exp);*/
  }

  getBranchWisePl() {
    console.log("WithZero", this.WithZero);
    //this.showSpinner = true;
    this.displayedCTreeolumns = ['Group_Name', 'Balance', 'Action'];
    this.displayedCTreeolumnsPrint = ['Group_Name', 'Balance'];
    this.reportsService
    .Trail_Balance_GroupBY_Branch({
      From: this.VoucherDateFrom,
      To: this.VoucherDateTo,
      WithZero: 0,
      BranchId: this.SelectBranch,
      StateId: this.SelectState
    }).subscribe((res: any) => {
        console.log(res);
      if (res.length > 0) {
        this.PLTab = true;
          this.JSONData = res;
          this.DBData = this.unflatten(res);
          this.changeTab(this.tab);
          // this.DBData.forEach(val => this.BindData.push(Object.assign({}, val)));
          // this.BindData = this.BindData.filter(item => item.GroupId == 1);
          // let tree_data = JSON.parse(JSON.stringify(this.BindData, null, " "));
          // console.log(tree_data);

          // this.dataSourceTree.data =tree_data ;
          this.showSpinner = false;
          if (this.BSTab) {
            this.ExpandBtn = true;
            this.CollaspeBtn = false;

          }
          if (this.PLTab) {
            this.ExpandBtn1 = true;
            this.CollaspeBtn1 = false;

          }
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.showSpinner = false;
          this.PLTab = false;
          //this.dataSourceLibTree = null;
          //this.dataSourceAssetsTree = null;
          //this.dataSourceDIREXPTree = null;
          //this.dataSourceDIRINCTree = null;
          //this.dataSourceINDIREXPTree = null;
          //this.dataSourceINDIRINCTree = null;
          //this.Gross_loss_INDIRINC = 0;
          //this.Gross_profit_DIRINC= 0;
          //this.Gross_profit_DIREXP= 0;
          //this.Gross_loss_DIRINC= 0;
          //this.Gross_loss_DIREXP= 0;
          //this.Gross_profit_INDIRINC= 0;
          //this.Gross_profit_Lib= 0;
          //this.Gross_loss_Assets= 0;
          //this.Gross_profit_INDIREXP= 0;
          //this.Gross_loss_INDIREXP = 0;
          //this.TOTAL_DIR_EXP= 0;
          //this.TOTAL_DIR_INC= 0;
          //this.TOTAL_INDIR_EXP= 0;
          //this.TOTAL_INDIR_INC= 0;
          //this.TOTAL_LIB= 0;
          //this.TOTAL_Assets= 0;
          //this.Gross_loss= 0;
          //this.Gross_profit= 0;
        }
      });
  }
  goToDetail(row) {
    //this.showSpinner = true;
    this.rowdata = '';
    this.rowdata = row;
    this.dataSourceAccount = '';
    this.onSearchAccountStatement(false);
    //console.log(row.AccountId);
    //$('#AccountingDetail').modal('show');
    //$("#AccountingDetail").css("z-index", "1050");
  }
  onSearchAccountStatement(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = '';
    this.dataSourceDaily = '';
    this.DivAnnualy = true;
    this.DivMonthly = false;
    this.DivDaily = false;

    this.showSpinner = true;
    this.loading = true;

    this.lmsService.Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId, From: this.VoucherDateFrom, To: this.VoucherDateTo, StartYear: this.minDate.getFullYear()
      })
      .subscribe((res: any) => {
        console.log("RES", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));

        $('#AccountingDetail').modal('show');
        $("#AccountingDetail").css("z-index", "1050");

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/

        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.dataSourceAccount.paginator = this.paginator;
        this.AccountName = this.rowdata.Group_Name;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip()
        }, 1000)
        this.showSpinner = false;
        this.loading = false;
      });
  }
  LoadMore() {
    console.log("this.dataSourceAccount", this.dataSourceAccount);
    this.onSearchAccountStatement(true);
  }

  OnCloseVoucherDetail() {
    $('#VoucherDetail').modal('hide');
  }
  OnCloseAccountingDetail() {
    $('#AccountingDetail').modal('hide');
  }
  goToDetailVoucher(row) {
    $('#VoucherDetail').modal('show');
    $("#VoucherDetail").css("z-index", "1060");
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        //console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        if (this.voucherModel.Voucher.Voucher_Type == 'Receipt' || this.voucherModel.Voucher.Voucher_Type == 'Journal' || this.voucherModel.Voucher.Voucher_Type == 'Payment')
          this.ShowDelete = true;
        else
          this.ShowDelete = false;
        this.voucherModel.Voucher_Detail.forEach(x => {
          x.AmountDR = 0.00;
          x.AmountCR = 0.00;
          if (x.TranType == 'CR')
            x.AmountCR = x.Amount;
          if (x.TranType == 'DR')
            x.AmountDR = x.Amount;
        });
        if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == '' || this.voucherModel.Voucher.Voucher_Mode_of_Payment == null)
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = 'CASH';
        this.onVoucherTagChange();
      });
  }
  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = 'CASH';
    this.lmsService.Get_Acc_Accounts_By_Tag({ Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date) }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }


  calculateTRTotalByCRDR(type) {
    let data_type = (type == 1) ? this.dataSourceLibTree
      : (type == 2) ? this.dataSourceAssetsTree
        : (type == 3) ? this.dataSourceDIREXPTree
          : (type == 4) ? this.dataSourceINDIREXPTree
            : (type == 5) ? this.dataSourceDIRINCTree
              : this.dataSourceINDIRINCTree;
    if (data_type != null) {
      let sum = 0.00;

      for (let i = 0; i < data_type.data.length; i++) {
        sum += Number(data_type.data[i]['Balance']);
      }
      let tot = 0.00;
      if (Number(sum) < 0)
        tot = Number(sum.toString().split("-")[1]);
      else
        tot = Number(sum);
      if (type == 1 && this.Gross_profit_Lib != undefined)
        tot = tot + Number(this.Gross_profit_Lib);
      if (type == 2 && this.Gross_loss_Assets != undefined)
        tot = tot + Number(this.Gross_loss_Assets);
      return tot.toFixed(2);
    }
    return 0;
  }

  getdatabalance_and_pl(type) {
    var arr = [];
    this.DBData.forEach(val => arr.push(Object.assign({}, val)));
    arr = arr.filter(item => item.GroupId == type);
    let tree_arr = JSON.parse(JSON.stringify(arr, null, " "));
    //console.log(tree_arr);
    this.dataSourceDIREXPTree.data = tree_arr;
    switch (type) {
      case 1: this.dataSourceLibTree = tree_arr;; break;
      case 2: this.dataSourceAssetsTree = tree_arr;; break;
      case 3: this.dataSourceDIREXPTree = tree_arr;; break;
      case 4: this.dataSourceINDIREXPTree = tree_arr;; break;
      case 5: this.dataSourceDIRINCTree = tree_arr;; break;
      case 6: this.dataSourceINDIRINCTree = tree_arr;; break;
    }
  }
  //split_string(str){
  //  return Number(str.split('-')[1]).toFixed(2);
  //}



  calculateACTranTypeDR() {
    if (this.dataSourceAccount != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
        sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSourceAccount != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
        sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeTotal() {
    if (this.dataSourceAccount != null) {
      /*let sum = 0;
      let DR = this.calculateACTranTypeDR()
      let CR = this.calculateACTranTypeCR();
      sum = Number(DR) - Number(CR);
      if (sum > 0)
        return sum.toFixed(2).toString() + " DR"
      else
        return sum.toFixed(2).toString().split('-')[1] + " CR"*/
      var lastBalance = this.dataSourceAccount.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }

  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.00;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "VoucherDate To greater than VoucherDate From.", ...this.configSuccess });
      this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {

        this.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
        this.VoucherDateTo = new Date();
      });
    }
  }
  GetData(data) {
    if (data == true) {
      this.changeTab(this.tab);
    }
  }

  ChangeBalanceType() {
    if (this.BalanceType == 0) {
      this.DivAnnualy = true;
      this.DivMonthly = false;
      this.DivDaily = false;
      this.GetACCLeadgerByAnnualy(false);
    }
    else if (this.BalanceType == 1) {
      this.DivMonthly = true;
      this.DivAnnualy = false;
      this.DivDaily = false;
      this.GetACCLeadgerByMonthly();
    }
    else {
      this.DivDaily = true;
      this.DivAnnualy = false;
      this.DivMonthly = false;
      this.GetACCLeadgerByDaily();
    }
  }



  totaltypeDaily(type) {
    if (this.dataSourceDaily != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceDaily.filteredData.length; i++) {
        sum += Number(this.dataSourceDaily.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";
    }
    return 0;
  }
  totaltypeMonthly(type) {
    if (this.dataSourceMonthly != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceMonthly.filteredData.length; i++) {
        sum += Number(this.dataSourceMonthly.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";
    }
    return 0;
  }
  calculateMonthlyBalance() {
    if (this.dataSourceMonthly != null) {
      var lastBalance = this.dataSourceMonthly.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  calculateDailyBalance() {
    if (this.dataSourceDaily != null) {
      var lastBalance = this.dataSourceDaily.filteredData.at(-1).Balance;
      return lastBalance;
    }
    return 0;
  }

  GetACCLeadgerByAnnualy(Addmore: boolean) {
    let NextCnt = 0;
    this.BalanceType = 0;
    this.dataSourceMonthly = '';
    this.dataSourceDaily = '';

    this.FileCheckingList = [];
    /*if (Addmore == false) {
      this.FileCheckingList = [];
    }

    if (this.FileCheckingList.length > 0) {
      NextCnt = this.dataSourceAccount.filteredData.length;
    }*/

    this.showSpinner = true;
    this.loading = true;

    this.lmsService
      .Get_Acc_Leadger({
        AccountId: this.rowdata.AccountId, From: this.VoucherDateFrom, To: this.VoucherDateTo, StartYear: this.minDate.getFullYear()
      })
      .subscribe((res: any) => {
        console.log("RES", res);

        /*var ResData = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < ResData.length; i++) {
          this.FileCheckingList.push(ResData[i]);
        }*/
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.dataSourceAccount.paginator = this.paginator;
        this.showSpinner = false;
        this.loading = false;
      });
  }
  GetACCLeadgerByMonthly() {
    this.FileCheckingList = [];
    this.showSpinner = true;

    this.reportsService.Report_ACC_Leadger_By_Monthly({
      AccountId: this.rowdata.AccountId, From: this.VoucherDateFrom, To: this.VoucherDateTo, StartYear: this.minDate.getFullYear()
    })
      .subscribe((res: any) => {
        console.log("Report_ACC_Leadger_By_Monthly :", res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceMonthly = new MatTableDataSource(this.FileCheckingList);
          this.showSpinner = false;
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.showSpinner = false;
        }
      });
  }
  GetACCLeadgerByDaily() {
    this.FileCheckingList = [];
    this.showSpinner = true;
    this.reportsService.Report_ACC_Leadger_By_Daily({
      AccountId: this.rowdata.AccountId, From: this.VoucherDateFrom, To: this.VoucherDateTo, StartYear: this.minDate.getFullYear()
    }).subscribe((res: any) => {
      console.log("Report_ACC_Leadger_By_Daily :", res);
      if (res.length > 0) {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceDaily = new MatTableDataSource(this.FileCheckingList);
        this.showSpinner = false;
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
      }
    });
  }

  goToDetailModel(row) {
    this.FileCheckingList = [];
    //this.dataSourceModel = '';

    var firstDayOfMonth, lastDayOfMonth, RowIndex, DayMonth;

    if (this.BalanceType == 1) {
      RowIndex = this.dataSourceMonthly.data.indexOf(row, 0);
      if (RowIndex == 1) {
        firstDayOfMonth = this.VoucherDateFrom;
        lastDayOfMonth = new Date(this.VoucherDateFrom.getFullYear(), this.VoucherDateFrom.getMonth() + 1, 0);
      }
      else {
        DayMonth = new Date(row.Month_Year);
        firstDayOfMonth = new Date(DayMonth.getFullYear(), DayMonth.getMonth(), 1);
        lastDayOfMonth = new Date(DayMonth.getFullYear(), DayMonth.getMonth() + 1, 0);
      }
    }
    else {
      firstDayOfMonth = row.Voucher_Date;
      lastDayOfMonth = row.Voucher_Date;
    }

    //console.log("firstDayOfMonth", firstDayOfMonth, "----- lastDayOfMonth", lastDayOfMonth);
    this.showSpinner = true;
    this.lmsService.Get_Acc_Leadger({ AccountId: row.AccountId, From: firstDayOfMonth, To: lastDayOfMonth, StartYear: this.minDate.getFullYear() })
      .subscribe((res: any) => {
        console.log("Get_Acc_Leadger :", res);
        $('#VoucherModel').modal('show');
        $("#VoucherModel").css("z-index", "1050");
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceModel = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceModel.sort = this.sort;
        this.dataSourceModel.paginator = this.paginator;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip()
        }, 100)
        this.showSpinner = false;
      });
  }
  OnCloseVoucherModel() {
    $('#VoucherModel').modal('hide');
  }

  totaltypeModel(type) {
    if (this.dataSourceModel != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceModel.filteredData.length; i++) {
        sum += Number(this.dataSourceModel.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypeModelBal() {
    if (this.dataSourceModel != null) {
      var lastBalance = this.dataSourceModel.filteredData.at(-1).TranTypeTotal;
      return lastBalance;
    }
    return 0;
  }
  
  OnClickExpandAll(){
    this.ExpandBtn = false;
    this.CollaspeBtn = true;
    this.LtreeControl.expandAll();
    this.AtreeControl.expandAll();
  }

  OnClickCollaspeAll(){
    this.ExpandBtn = true;
    this.CollaspeBtn = false;
    this.LtreeControl.collapseAll();
    this.AtreeControl.collapseAll();
  }

  OnClickExpandAll1(){
    this.ExpandBtn1 = false;
    this.CollaspeBtn1 = true;
    
    this.DEtreeControl.expandAll();
    this.IEtreeControl.expandAll();
    this.DItreeControl.expandAll();
    this.IItreeControl.expandAll();
  }

  OnClickCollaspeAll1(){
    this.ExpandBtn1 = true;
    this.CollaspeBtn1 = false;
    this.DEtreeControl.collapseAll();
    this.IEtreeControl.collapseAll();
    this.DItreeControl.collapseAll();
    this.IItreeControl.collapseAll();
  }

  exportTable(exporter: any) {
    console.log("exporter : ", exporter);
    exporter.exportTable("xls", {
      fileName: "Balance-Sheet",
      sheet: "Balance-Sheet",
    });
  }

  //onDocsPrint(divName, Header) {
  //  this._commonfunctions.PrintBalanceSheet(divName, Header, new Date().getTime());
  //}

  onDocsPrint() {

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }

    var table;
    // if (this.tab == "BS") {
    //    table = document.getElementById('BalanceSheetDiv')
    // } 
     {
      table = document.getElementById('ProfitLossDiv')
    }
    var ctx = { worksheet: 'BalanceSheet' || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))


  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
     
    });
  }
}
