<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"></app-mat-spinner-overlay>

  <form
    #f="ngForm"
    (keydown.enter)="onSearchLoanNumber()"
    novalidate
    class="row m-0 col-md-12 p-0 formborder"
  >
    <div
      class="row m-0 col-md-12 p-0"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div class="col-md-3">
        <span>Loan No.</span>
        <input
          name="LoanNo"
          [(ngModel)]="LoanNo"
          id="LoanNo"
          placeholder="Loan No."
          class="form-control input-text-css"
          (paste)="handleOldLoanNo($event)"
          (input)="loanNochange()"
          required
          #refLoanNo="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refLoanNo.invalid,
            'alert-warning':
              refLoanNo.invalid &&
              (refLoanNo.dirty || refLoanNo.touched || refLoanNo.untouched)
          }"
        />
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="onSearchLoanNumber()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!f.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="showDetails">
    <div *ngIf="customerDetail" class="formborder mt-3">
      <div class="row m-0 mt-2" *ngIf="customerDetail">
        <div class="col-md-2">
          <h6 class="fs-12">Application No.</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ApplicationNo }}</p>
        </div>

        <div id="CAM" class="col-md-2">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LoanNo }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Application Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ApplicationDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.Branch }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Product</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.Product }}</p>
        </div>

        <div class="col-md-12"><hr /></div>
      </div>

      <div class="mt-1">
        <div class="">
          <div class="table-responsive">
            <mat-table [dataSource]="CustomerDataSource">
              <ng-container matColumnDef="Customer">
                <mat-header-cell class="grid-header mw350" *matHeaderCellDef
                  >Customer Name</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell mw350"
                >
                  <app-lms-customer-view-model
                    [customerId]="row.CustomerId"
                    [index]="i"
                  >
                    {{ row.Customer }}
                  </app-lms-customer-view-model>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="FatherName">
                <mat-header-cell class="grid-header mw250" *matHeaderCellDef
                  >Relation</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw250">
                  {{ row.FatherName }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="CustomerType">
                <mat-header-cell class="grid-header mw250" *matHeaderCellDef
                  >Customer Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw250">
                  {{
                    row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell class="grid-header mw250" *matHeaderCellDef
                  >Gender/Age</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw250">
                  {{ row.GenderAge }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell class="grid-header mw250" *matHeaderCellDef
                  >Mobile No.</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw250">
                  {{ row.PhoneNo }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedCustomerColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedCustomerColumns"
              ></mat-row>
            </mat-table>
          </div>
          <hr />
        </div>
      </div>

      <ng-container *ngIf="customerDetail">
        <div class="">
          <div class="row m-0 mt-1">
            <div class="col-md-2">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead m-0 fs-12">₹ {{ customerDetail.LoanAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Interest Amount</h6>
              <p class="lead m-0 fs-12">
                ₹ {{ customerDetail.InterestAmount }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Agree Amount</h6>
              <p class="lead m-0 fs-12">₹ {{ customerDetail.AgreedAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">First EMI Date</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.FirstEMIDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Last EMI Date</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.LastEMIDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">IRR (%)</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.IRR }} %</p>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="col-md-2">
              <h6 class="fs-12">ROI (%)</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.ROI }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">LTV</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.LTV }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">No. of Installment</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.NoOfInstallment }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tenure(Months)</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.Tenure }}</p>
            </div>
            <div class="col-md-12"><hr /></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <form
    #f="ngForm"
    (keydown.enter)="onUpdateLoanNo()"
    novalidate
    class="row m-0 col-md-12 p-0 formborder mt-3"
    *ngIf="customerDetail && showDetails"
  >
    <div
      class="row m-0 col-md-12 p-0"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div class="col-md-3">
        <span>New Loan No.</span>
        <input
          name="newLoanNo"
          [(ngModel)]="newLoanNo"
          id="newLoanNo"
          placeholder="New Loan No."
          class="form-control input-text-css"
          (paste)="handleNewLoanNo($event)"
          minlength="1"
          maxlength="12"
          pattern="[a-zA-Z0-9-_.]*"
          required
          #refnewLoanNo="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refnewLoanNo.invalid,
            'alert-warning':
              refnewLoanNo.invalid &&
              (refnewLoanNo.dirty ||
                refnewLoanNo.touched ||
                refnewLoanNo.untouched)
          }"
          (keydown.space)="$event.preventDefault()"
        />
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="onUpdateLoanNo()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</div>
