<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 formborder">
  <div class="col-md-3 p-1">
    <span> Select State </span>
    <select
      name="FilterStateId"
      id="FilterStateId"
      [(ngModel)]="FilterStateId"
      class="form-control input-text-css"
    >
      <option value="">Select State</option>
      <option
        *ngFor="let StateList of FilterStateList"
        [value]="StateList.StateId"
      >
        {{ StateList.State_Name }}
      </option>
    </select>
  </div>
  <div class="col-md-9 p-1 text-right">
    <button class="mt-3 btn font-size-12 button-btn" (click)="OnAddCourt()">
      Add New Court
    </button>
  </div>
</div>

<div class="row m-0" [hidden]="!dataSource">
  <div class="col-md-12 p-1">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 100px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="State_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >State Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.State_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="District_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            District Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.District_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Court_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Court Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Court_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 100px"
          >
            <i
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              (click)="OnEditCourt(row)"
              style="cursor: pointer"
              aria-label="true"
            ></i>
            <i
              class="fa fa-trash style-delete"
              style="cursor: pointer"
              aria-label="true"
              (click)="OnDeleteCourt(row)"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddCourt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSaveCourt()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Court
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select State</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="StateId"
                id="StateId"
                #refStateId="ngModel"
                (change)="getDistrictList()"
                [ngClass]="{
                  'is-invalid': f.submitted && refStateId.invalid,
                  'alert-warning':
                    refStateId.invalid &&
                    (refStateId.dirty ||
                      refStateId.touched ||
                      refStateId.untouched)
                }"
                [(ngModel)]="CourtModel.StateId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let StateList of FilterStateList"
                  [value]="StateList.StateId"
                >
                  {{ StateList.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select District</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="DistrictId"
                id="DistrictId"
                #refDistrictId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDistrictId.invalid,
                  'alert-warning':
                    refDistrictId.invalid &&
                    (refDistrictId.dirty ||
                      refDistrictId.touched ||
                      refDistrictId.untouched)
                }"
                [(ngModel)]="CourtModel.DistrictId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let District of DistrictDropDown"
                  [value]="District.DistrictId"
                >
                  {{ District.District_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Court Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refCourt_Name="ngModel"
                name="Court_Name"
                id="Court_Name"
                [ngClass]="{
                  'is-invalid': f.submitted && refCourt_Name.invalid,
                  'alert-warning':
                    refCourt_Name.invalid &&
                    (refCourt_Name.dirty ||
                      refCourt_Name.touched ||
                      refCourt_Name.untouched)
                }"
                [(ngModel)]="CourtModel.Court_Name"
                placeholder="Court Name"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="OnSaveCourt()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
