import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { LmsService } from "../_Lms/services/lms.service";
import { ReportsService } from "../_Reports/services/reports.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { LosService } from "../_LOS/services/los.service";
import { DatePipe } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from "../snackbar/snackbar.component";

@Component({
  selector: "app-lms-due-list-pre-emi",
  templateUrl: "./lms-due-list-pre-emi.component.html",
  styleUrls: ["./lms-due-list-pre-emi.component.scss"],
})
export class LmsDueListPreEmiComponent implements OnInit {
  showSpinner: boolean = false;
  dueDate: Date;

  displayedColumns: string[] = [];
  JsonData: any[] = [];
  dataSource: MatTableDataSource<unknown>;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selectedId: any;
  currentUser: any;

  constructor(
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,

    private reportsService: ReportsService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private losService: LosService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Due List Pre Emi");
    this.dueDate = new Date();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedColumns = [
      "Loan_Id",
      "LoanNo",
      "ApplicationNo",
      "Product",
      "Branch",
      "Customer",
      "MobileNo",
      "No_Of_Instl",
      "Tenure",
      "EMI_Type",
      "IRR",
      "EMIAmount",
      "LoanAmount",
      "DisbursementPayble",
      "DisbursementPayment",
      "DisbursementPending",
      "InterestDue",
      "InterestReceived",
      "InterestBalance",
      "LastPaymentDate",
    ];
  }

  getDueListPreEmi() {
    console.log("search button logg");
    this.showSpinner = true;

    this.lmsService
      .DueList_PreEMI_Interest({ dueDate: this.dueDate })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );

          this.dataSource.paginator = this.paginator;

          this.dataSource.sort = this.sort;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }


  totaltypewise(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }


  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("export-due-list-pre-emi") as HTMLTableElement;
    const worksheetName = "DUE LIST PRE EMI"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }



}
