import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;
import * as moment from "moment";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { MatTableDataSource } from "@angular/material/table";
import {
  RowSpanComputer,
  Span,
} from "src/app/_Reports/services/row-span-computer";

@Component({
  selector: "app-sales-target-achievement",
  templateUrl: "./sales-target-achievement.component.html",
  styleUrls: ["./sales-target-achievement.component.scss"],
})
export class SalesTargetAchievementComponent implements OnInit {
  isVertical: boolean = false;
  showSpinner: boolean = false;
  private data: any;
  employeeList: any[] = [];
  st_month_year: any = new Date();
  en_month_year: any = new Date();
  targetAmount: any;
  reviseTarget: any;
  reviseRemarks: any;
  value: any;

  modalst_month_year: any = new Date();
  FyearId: any;
  lmsService: any;
  ReportsService: any;
  currentUser: any;
  dataSourceModel: any;

  displayedColumnsModel: string[] = [
    "Employee_Id",
    "Emp_Name",
    "Target_Amount",
    "Action",
  ];

  columnNames = [
    
    "EmpName",
    "Emp_Manager",
    "Target",
    "Achievement",
    "Achievement_Per",
  ];

  C_Level1: Array<Span[]>;
  C_Level2: Array<Span[]>;
  C_Level3: Array<Span[]>;
  C_Level4: Array<Span[]>;
  C_Level5: Array<Span[]>;
  C_Level6: Array<Span[]>;
  C_Level7: Array<Span[]>;
  C_Level8: Array<Span[]>;
  C_Level9: Array<Span[]>;
  C_Level10: Array<Span[]>;

  private rowSpanComputer = new RowSpanComputer();

  FileCheckingList: any;
  filteredData: any;
  private _finalData: any;
  Target_Amount: any;
  Level1: any = [];
  Level2: any = [];
  Level3: any = [];
  Level4: any = [];
  Level5: any = [];
  Level6: any = [];
  Level7: any = [];
  Level8: any = [];
  Level9: any = [];
  Level10: any = [];

  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _ReportsService: ReportsService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.st_month_year = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.en_month_year = new Date(
            moment(res[0].fyent).format("MM/DD/YYYY")
          );
          // this.DataSource = null;
        });
    });
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Sales Target and Achievement");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser")!);
    this.Get_EmployeeForTreeMap();
    // this.getDataDetail();
  }

  Get_EmployeeForTreeMap() {
    let StMonth = this.st_month_year.getMonth() + 1;
    let StYear = this.st_month_year.getFullYear();
    let EnMonth = this.en_month_year.getMonth() + 1;
    let EnYear = this.en_month_year.getFullYear();

    this.Level1 = [];
    this.Level2 = [];
    this.Level3 = [];
    this.Level4 = [];
    this.Level5 = [];
    this.Level6 = [];
    this.Level7 = [];
    this.Level8 = [];
    this.Level9 = [];
    this.Level10 = [];

    // this.showSpinner = true;
    this._ReportsService
      .Report_Sales_Target_VS_Achievement({
        LoginUserId: this.currentUser.userId,
        StartMonth: StMonth,
        StartYear: StYear,
        EndMonth: EnMonth,
        EndYear: EnYear,
      })
      .subscribe((res: any) => {
        // this.showSpinner = false;
         var employeeList = res.filter((obj: any) => obj.Target > 0 || obj.Achievement > 0);
        // this.makeTree(res, this.employeeList);

        this.Level1 = res.filter((r) => r.EmpId === this.currentUser.userId);
        // console.log("Level 1", this.Level1);
        // this.setLevel1(); 
        this.setLevel(employeeList, this.Level2, this.Level1);
        this.setLevel(employeeList, this.Level3, this.Level2);
        this.setLevel(employeeList, this.Level4, this.Level3);
        this.setLevel(employeeList, this.Level5, this.Level4);
        this.setLevel(employeeList, this.Level6, this.Level5);
        this.setLevel(employeeList, this.Level7, this.Level6);
        this.setLevel(employeeList, this.Level8, this.Level7);
        this.setLevel(employeeList, this.Level9, this.Level8);
        this.setLevel(employeeList, this.Level10, this.Level9);

        this.Level1 = this.Level1.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level2 = this.Level2.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level3 = this.Level3.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level4 = this.Level4.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level5 = this.Level5.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level6 = this.Level6.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level7 = this.Level7.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level8 = this.Level8.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level9 = this.Level9.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );
        this.Level10 = this.Level10.sort((a, b) => a.EmpId - b.EmpId).sort(
          (a, b) => b.Type - a.Type
        );

        this.C_Level1 = this.rowSpanComputer.compute(
          this.Level1,
          this.columnNames
        );
        this.C_Level2 = this.rowSpanComputer.compute(
          this.Level2,
          this.columnNames
        );
        this.C_Level3 = this.rowSpanComputer.compute(
          this.Level3,
          this.columnNames
        );
        this.C_Level4 = this.rowSpanComputer.compute(
          this.Level4,
          this.columnNames
        );
        this.C_Level5 = this.rowSpanComputer.compute(
          this.Level5,
          this.columnNames
        );
        this.C_Level6 = this.rowSpanComputer.compute(
          this.Level6,
          this.columnNames
        );
        this.C_Level7 = this.rowSpanComputer.compute(
          this.Level7,
          this.columnNames
        );
        this.C_Level8 = this.rowSpanComputer.compute(
          this.Level8,
          this.columnNames
        );
        this.C_Level9 = this.rowSpanComputer.compute(
          this.Level9,
          this.columnNames
        );
        this.C_Level10 = this.rowSpanComputer.compute(
          this.Level10,
          this.columnNames
        );
      });
      console.log("Level2",this.Level2);
  }

  setLevel1() {
    // let employeeid = this.Level1.find((obj: any) => {
    //   return obj.EmpId == this.currentUser.userId;
    // });

    // if (employeeid) {

   
  
      this.Level1.push({
        EmpId: this.Level1[0].EmpId,
        Emp_Name: this.Level1[0].Emp_Name,
        Emp_ManagerId: this.Level1[0].Emp_ManagerId,
        Target: this.Level1[0].Target,
        Achievement: this.Level1[0].Achievement,
        Achievement_Per: this.Level1[0].Achievement_Per,
        Role_Level: this.Level1[0].Role_Level,
        Role_Name: this.Level1[0].Role_Name,
        Total_Case: this.Level1[0].Total_Case,
      });
 

    // }
    // console.log("Set Level 1", this.setLevel1);
  }

  setLevel(res, PushLevel, Level) {
    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < Level.length; j++) {
        let _find = PushLevel.find((obj: any) => {
          return obj.EmpId == res[i].EmpId;
        });
        if (res[i].Emp_ManagerId === Level[j].EmpId && !_find) {
          PushLevel.push(res[i]);
        }

        let _find1 = PushLevel.find((obj: any) => {
          return obj.EmpId == res[i].EmpId;
        });
        if (res[i].Emp_ManagerId === Level[j].EmpId && !_find1) {
          PushLevel.push(res[i]);
        }
      }
    }
  }

  DateChange() {
    if (this.st_month_year != "" && this.en_month_year != "") {
      if (this.st_month_year > this.en_month_year) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "End Month Year will be greater than to Start Month Year.",
          ...this.configSuccess,
        });
        this.st_month_year = "";
        this.en_month_year = "";
      }
    }
  }
  modalDateChange() {
    if (this.modalst_month_year != "" && this.en_month_year != "") {
      if (this.modalst_month_year > this.en_month_year) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "End Month Year will be greater than to Start Month Year.",
          ...this.configSuccess,
        });
        this.modalst_month_year = "";
        this.modalst_month_year = "";
      }
    }
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  showTarget() {
    let modalStMonth = this.modalst_month_year.getMonth() + 1;
    let modalStYear = this.st_month_year.getFullYear();

    this._ReportsService
      .Get_Sales_Target({
        LoginUserId: this.currentUser.userId,
        StartMonth: modalStMonth,
        StartYear: modalStYear,
      })
      .subscribe((res: any) => {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceModel = new MatTableDataSource(
          JSON.parse(JSON.stringify(this.FileCheckingList))
        );
        // console.log("ressss", this.FileCheckingList);
      });
    $("#modalTable").modal("show");
    $("#modalTable").css("z-index", "1050");
    // this.saveReviseTarget();
  }

  OnClose() {
    $("#modalTable").modal("hide");
  }
  OnCloseReviseModal() {
    $("#reviseTargetmodal").modal("hide");
  }

  saveButton() {
    let modalStMonth = this.modalst_month_year.getMonth() + 1;
    let modalStYear = this.st_month_year.getFullYear();

    let _Data = [];
    this.dataSourceModel.filteredData.forEach((obj: any) => {
      if (obj.Target_Amount > 0) {
        _Data.push({
          Employee_Id: obj.Employee_Id,
          Target_Amount: obj.Target_Amount,
          TargetId: obj.TargetId,
        });
      }
    });

    let _finalData = { Sales_Target: _Data };

    console.log("jsondata", JSON.stringify(_finalData));

    // console.log("asdsa", this.dataSourceModel);

    this._ReportsService
      .Save_Sales_Target({
        JSON: JSON.stringify(_finalData),
        StartYear: modalStYear,
        StartMonth: modalStMonth,
        LoginUserId: this.currentUser.userId,
        Type: "Create",
      })
      .subscribe((res: any) => {
        //this.FileCheckingList = JSON.parse(JSON.stringify(res));
        //this.dataSourceModel = new MatTableDataSource(JSON.parse(JSON.stringify(this.FileCheckingList)));
        console.log("result", this.FileCheckingList);
        $("#modalTable").modal("hide");
      });


      this.Get_EmployeeForTreeMap();
  }

  ReviseTargetAmount(row) {
    this.value = row;
    console.log("wef", this.value);

    $("#modalTable").modal("show");
    $("#modalTable").css("z-index", "700");

    $("#reviseTargetmodal").modal("show");
    $("#reviseTargetmodal").css("z-index", "800");
    // this.OnClose();

    this.reviseTarget = row.Target_Amount;
  }

  saveReviseTarget() {
    // this.value=row;
    let modalStMonth = this.modalst_month_year.getMonth() + 1;
    let modalStYear = this.st_month_year.getFullYear();

    let revisedData = [];
    revisedData.push({
      Employee_Id: this.value.Employee_Id,
      Target_Amount: this.reviseTarget,
      TargetId: this.value.TargetId,
      Revise_Remark: this.reviseRemarks,
    });

    // let _revisedfinalData = { Sales_Target: revisedData }

    console.log("revised", revisedData);
    let _revisedfinalData = { Sales_Target: revisedData };

    this._ReportsService
      .Save_Sales_Target({
        JSON: JSON.stringify(_revisedfinalData),
        StartYear: modalStYear,
        StartMonth: modalStMonth,
        LoginUserId: this.currentUser.userId,
        Type: "Revised",
      })
      .subscribe((res: any) => {
        // this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.showTarget();
        // console.log("Revised",this.FileCheckingList)

        $("#reviseTargetmodal").modal("hide");
        this.reviseTarget = "";
        this.reviseRemarks = "";
      });
  }
}
