import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-financial-info',
  templateUrl: './loan-financial-info.component.html',
  styleUrls: ['./loan-financial-info.component.scss']
})
export class LoanFinancialInfoComponent implements OnInit {
  @Input() financialdata: any

  constructor() { }

  ngOnInit(): void {
    console.log("financialdata",this.financialdata)
  }

}
