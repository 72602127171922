<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #df="ngForm"
  novalidate
  (keydown.enter)="searchCustomerCibilVerification()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateFrom"
          [(ngModel)]="DateFrom"
          id="DateFrom"
          class="form-control input-text-css"
          required
          #refDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateFrom.invalid,
            'alert-warning':
              refDateFrom.invalid &&
              (refDateFrom.dirty ||
                refDateFrom.touched ||
                refDateFrom.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DateTo"
          [(ngModel)]="DateTo"
          id="DateTo"
          class="form-control input-text-css"
          required
          #refDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refDateTo.invalid,
            'alert-warning':
              refDateTo.invalid &&
              (refDateTo.dirty || refDateTo.touched || refDateTo.untouched)
          }"
        />
        <!-- (dateChange)="DateChange()" -->
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        (click)="searchCustomerCibilVerification()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="float-end col-md-4 mt-2" [hidden]="!DataSource">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!DataSource">
  <div class="">
    <div class="table-responsive" style="height: 350px; overflow: auto">
      <table
        mat-table
        class="table-bordered"
        matSort
        [dataSource]="DataSource"
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="Id">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Application No
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Application_No }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.CustomerName }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <ng-container matColumnDef="CustomerType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Type
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.CustomerType }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let row">
            {{ row.CreatedBy }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CreateOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Create On</th>
          <td mat-cell *matCellDef="let row">
            {{ row.CreateOn }}
          </td>
          <th mat-footer-cell *matFooterCellDef style="text-align: right"></th>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Status }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Score">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Score</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Score }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Pscore">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Pscore</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Pscore }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="totalAccounts">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Total Accounts
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.totalAccounts }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="overdueAccounts">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Overdue Accounts
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.overdueAccounts }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="zeroBalanceAccounts">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Zero Balance Accounts
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.zeroBalanceAccounts }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="highCreditAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            High Credit Amount
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.highCreditAmount }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="currentBalance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Current Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.currentBalance }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="overdueBalance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Overdue Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.overdueBalance }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="recentDateOpened">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Recent Date Opened
          </th>
          <td mat-cell *matCellDef="let row" class="text-center">
            {{ row.recentDateOpened }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <ng-container matColumnDef="oldestDateOpened">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Oldest Date Opened
          </th>
          <td mat-cell *matCellDef="let row" class="text-center">
            {{ row.oldestDateOpened }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
  </div>
</div>

<div class="row col-md-12 mt-2 ml-1 formborder" [hidden]="!DataSource">
  <div class="col-md-4">
    <button class="mt-2 btn font-size-12 button-btn" (click)="exportexcelNew()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="customer_cibil_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Score
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Pscore
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Accounts
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Overdue Accounts
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      ZeroBalance Accounts
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      HighCredit Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Overdue Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Recent Date Opened
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Oldest Date Opened
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.CustomerType }}</td>
    <td>{{ x.CreatedBy }}</td>
    <td>{{ x.CreateOn }}</td>
    <td>{{ x.Status }}</td>
    <td>{{ x.Score }}</td>
    <td>{{ x.Pscore }}</td>
    <td>{{ x.totalAccounts }}</td>
    <td>{{ x.overdueAccounts }}</td>
    <td>{{ x.zeroBalanceAccounts }}</td>
    <td>{{ x.highCreditAmount }}</td>
    <td>{{ x.currentBalance }}</td>
    <td>{{ x.overdueBalance }}</td>
    <td>{{ x.recentDateOpened }}</td>
    <td>{{ x.oldestDateOpened }}</td>
  </tr>
</table>
