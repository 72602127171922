<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchAccountStatement()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span class="required-lable">Charges Head</span>

        <input
          type="text"
          [formControl]="ChargesHeadControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="chhead"
          name="chhead"
          required
          [class.alert-warning]="chhead == ''"
          placeholder="Charges Head"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onChargesHeadhSelect($event)"
        >
          <mat-option
            *ngFor="let Item of filteredChargesHead | async"
            [value]="Item"
          >
            {{ Item.ChagesHead }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-2">
        <span> Loan Product </span>
        <select
          name="LoanProduct_Id"
          id="LoanProduct_Id"
          [(ngModel)]="ProductId"
          class="form-control input-text-css"
        >
          <option value="0">Select All</option>
          <ng-container *ngFor="let productg of ProductList">
            <option
              *ngIf="productg.Product_IsActive == true"
              [value]="productg.ProductId"
            >
              {{ productg.Product }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateFrom"
            id="VoucherDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
            (dateChange)="DateChange()"
            required
            #refVoucherDateFrom="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refVoucherDateFrom.invalid,
              'alert-warning':
                refVoucherDateFrom.invalid &&
                (refVoucherDateFrom.dirty ||
                  refVoucherDateFrom.touched ||
                  refVoucherDateFrom.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          placeholder="Date From"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #refVoucherDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refVoucherDateFrom.invalid,
            'alert-warning':
              refVoucherDateFrom.invalid &&
              (refVoucherDateFrom.dirty ||
                refVoucherDateFrom.touched ||
                refVoucherDateFrom.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            (dateChange)="DateChange()"
            required
            #reffVoucherDateTo="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            [ngClass]="{
              'is-invalid': df.submitted && reffVoucherDateTo.invalid,
              'alert-warning':
                reffVoucherDateTo.invalid &&
                (reffVoucherDateTo.dirty ||
                  reffVoucherDateTo.touched ||
                  reffVoucherDateTo.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          placeholder="Date To"
          class="form-control input-text-css"
          bsDatepicker
          (ngModelChange)="DateChange()"
          required
          #reffVoucherDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && reffVoucherDateTo.invalid,
            'alert-warning':
              reffVoucherDateTo.invalid &&
              (reffVoucherDateTo.dirty ||
                reffVoucherDateTo.touched ||
                reffVoucherDateTo.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-1 pl-0">
        <label class="text-nowrap">With Opening</label>
        <input
          type="checkbox"
          [(ngModel)]="checkSeeAll"
          name="checkSeeAll"
          (change)="onCheckedChanged(checkSeeAll)"
          checked
        />
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchAccountStatement()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid || !chhead"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Loan_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 40px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 40px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 40px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Application No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Application_No }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 85px"
              >LoanAcNo</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 85px"
            >
              {{ row.LoanAcNo }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 85px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustumerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustumerName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-center"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
            >
              {{ row.Application_LoanAmount }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
              >{{ calculateLoanAmount() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="OpeningBalanceDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >OP Bal(DR)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.OpeningBalanceDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateOpeningBalanceDR()
            }}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="OpeningBalanceCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >OP Bal(CR)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.OpeningBalanceCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateOpeningBalanceCR()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >TR Bal(DR)</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
            >
              {{ row.TranTypeDR > 0 ? row.TranTypeDR : "" }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
              >{{ calculateACTranTypeDR() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >TR Bal(CR)</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
            >
              {{ row.TranTypeCR > 0 ? row.TranTypeCR : "" }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="text-right"
              [ngClass]="!checkSeeAll ? 'text-center' : 'text-right'"
              >{{ calculateACTranTypeCR() }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >CL Bal(DR)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.ClosingBalanceDR > 0 ? row.ClosingBalanceDR : "" }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateClosingBalanceDR()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ClosingBalanceCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >CL Bal(CR)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.ClosingBalanceCR > 0 ? row.ClosingBalanceCR : "" }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-right">{{
              calculateClosingBalanceCR()
            }}</mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="printdata()"
            >
              <i
                class="fa fa-print mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Print
            </button>
          </div>

          <div class="col-md-2 mt-2">
            <form action="" class="search-text">
              <div class="bg-light rounded rounded-pill shadow-sm">
                <div class="input-group">
                  <input
                    type="text"
                    id="FilterInputSearch"
                    (keyup)="applyFilter($event.target.value)"
                    name="FilterInputSearch"
                    placeholder="What're you searching for?"
                    class="form-control border-0 bg-light"
                  />
                  <div class="input-group-append">
                    <button
                      id="button-addon1"
                      type="submit"
                      class="btn btn-link text-primary"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="loan_balance"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc no
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP BAl(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP Bal(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TR Bal(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TR Bal(CR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CL Bal(DR)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CL Bal(CR)
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustumerName }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.OpeningBalanceDR }}</td>
    <td>{{ x.OpeningBalanceCR }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.ClosingBalanceDR }}</td>
    <td>{{ x.ClosingBalanceCR }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="5"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateLoanAmount() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceDR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateOpeningBalanceCR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateACTranTypeDR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateACTranTypeCR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceDR() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateClosingBalanceCR() }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<div
  class="modal fade in"
  id="loanChargesBalancePrint"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Charges Balance
        </h6>
        <button
          type="button"
          (click)="onCloseLoanChargesPrintModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="font-size-12 button-btn ml-2"
              (click)="
                onDocsPrint('loanChargesPrintModel', 'Loan Charges Balance')
              "
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="loanChargesPrintModel">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <div class="row m-0 mt-4 mb-4">
            <div
              class="col-md-12 text-center bg-success1"
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
              "
            >
              Loan Charges Balance for the period of -
              {{ VoucherDateFrom | date : "dd/MM/yyyy" }} to
              {{ VoucherDateTo | date : "dd/MM/yyyy" }}
            </div>
          </div>

          <div class="table-responsive mt-2">
            <table
              id="loan_balance"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif; width: 100%"
            >
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  #
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Application No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Loan Acc no
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Customer Name
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Product
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Loan Amount
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  OP BAl(DR)
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  OP Bal(CR)
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  TR Bal(DR)
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  TR Bal(CR)
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CL Bal(DR)
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CL Bal(CR)
                </th>
              </tr>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.Application_No }}</td>
                <td>{{ x.LoanAcNo }}</td>
                <td>{{ x.CustumerName }}</td>
                <td>{{ x.Product_Name }}</td>
                <td>{{ x.Application_LoanAmount }}</td>
                <td>{{ x.OpeningBalanceDR }}</td>
                <td>{{ x.OpeningBalanceCR }}</td>
                <td>{{ x.TranTypeDR }}</td>
                <td>{{ x.TranTypeCR }}</td>
                <td>{{ x.ClosingBalanceDR }}</td>
                <td>{{ x.ClosingBalanceCR }}</td>
              </tr>
              <tfoot>
                <tr>
                  <td
                    colspan="5"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateLoanAmount() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateOpeningBalanceDR() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateOpeningBalanceCR() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateACTranTypeDR() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateACTranTypeCR() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateClosingBalanceDR() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateClosingBalanceCR() }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            <div
              id="abc"
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date & Time: {{ Cur_Date | date : "dd/MM/yyyy hh:mm:ss" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
