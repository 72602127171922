<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-3 mt-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-3 text-right">
      <button
        type="button"
        (click)="AddShedule()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Add Shedule
      </button>
    </div>
  </div>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Schedule_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Schedule_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Schedule_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_Type }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Schedule_Start">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Start</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_Start }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Schedule_End">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >End</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_End }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Schedule_Voucher_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Voucher Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_Voucher_Date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Schedule_Frequency">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Frequency</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_Frequency }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Schedule_CreateBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Create By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_CreateBy }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Schedule_CreateOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Create On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Schedule_CreateOn }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header j-c-center"
              *matHeaderCellDef
              >De-Activate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                class="fa fa-trash style-delete"
                aria-label="true"
                style="color: red !important; cursor: pointer"
                (click)="DeactivateShedule(row)"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8 mt-3">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="VoucherPopupModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow-y: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Schedule
        </h6>
        <button
          type="button"
          (click)="onCloseVoucher()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #f="ngForm" novalidate class="formborder">
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-2">
              <span class="required-lable">Schedule Name</span>
              <input
                name="Schedule_Name"
                [(ngModel)]="voucherModel.Voucher.Schedule_Name"
                id="Schedule_Name"
                placeholder="Schedule Name"
                class="form-control input-text-css"
                required
                #refSchedule_Name="ngModel"
                [disabled]="Isedit"
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_Name.invalid,
                  'alert-warning':
                    refSchedule_Name.invalid &&
                    (refSchedule_Name.dirty ||
                      refSchedule_Name.touched ||
                      refSchedule_Name.untouched)
                }"
              />
            </div>
            <div class="col-md-2">
              <span class="required-lable">Shedule Start</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Schedule_Start"
                  [(ngModel)]="voucherModel.Voucher.Schedule_Start"
                  id="Schedule_Start"
                  class="form-control input-text-css"
                  (dateChange)="DateChange()"
                  required
                  #refSchedule_Start="ngModel"
                  [disabled]="Isedit"
                  [ngClass]="{
                    'is-invalid': f.submitted && refSchedule_Start.invalid,
                    'alert-warning':
                      refSchedule_Start.invalid &&
                      (refSchedule_Start.dirty ||
                        refSchedule_Start.touched ||
                        refSchedule_Start.untouched)
                  }"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="Schedule_Start"
                [(ngModel)]="voucherModel.Voucher.Schedule_Start"
                id="Schedule_Start"
                placeholder="Shedule Start"
                (ngModelChange)="DateChange()"
                class="form-control input-text-css"
                bsDatepicker
                required
                #refSchedule_Start="ngModel"
                [disabled]="Isedit"
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_Start.invalid,
                  'alert-warning':
                    refSchedule_Start.invalid &&
                    (refSchedule_Start.dirty ||
                      refSchedule_Start.touched ||
                      refSchedule_Start.untouched)
                }"
              /> -->
            </div>
            <div class="col-md-2">
              <span class="required-lable">Shedule End</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Schedule_End"
                  [(ngModel)]="voucherModel.Voucher.Schedule_End"
                  id="Schedule_End"
                  (dateChange)="DateChange()"
                  class="form-control input-text-css"
                  required
                  #refSchedule_End="ngModel"
                  [disabled]="Isedit"
                  [ngClass]="{
                    'is-invalid': f.submitted && refSchedule_End.invalid,
                    'alert-warning':
                      refSchedule_End.invalid &&
                      (refSchedule_End.dirty ||
                        refSchedule_End.touched ||
                        refSchedule_End.untouched)
                  }"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <inpu
                name="Schedule_End"
                [(ngModel)]="voucherModel.Voucher.Schedule_End"
                id="Schedule_End"
                placeholder="Shedule End"
                (ngModelChange)="DateChange()"
                class="form-control input-text-css"
                bsDatepicker
                required
                #refSchedule_End="ngModel"
                [disabled]="Isedit"
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_End.invalid,
                  'alert-warning':
                    refSchedule_End.invalid &&
                    (refSchedule_End.dirty ||
                      refSchedule_End.touched ||
                      refSchedule_End.untouched)
                }"
              /> -->
            </div>
            <div class="col-md-2">
              <span class="required-lable">Shedule Amount</span>
              <input
                type="number"
                name="Schedule_Amount"
                [(ngModel)]="voucherModel.Voucher.Schedule_Amount"
                id="Schedule_Amount"
                placeholder="Shedule Amount"
                class="form-control input-text-css"
                required
                #refSchedule_Amount="ngModel"
                [disabled]="Isedit"
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_Amount.invalid,
                  'alert-warning':
                    refSchedule_Amount.invalid &&
                    (refSchedule_Amount.dirty ||
                      refSchedule_Amount.touched ||
                      refSchedule_Amount.untouched)
                }"
              />
            </div>
            <div class="col-md-2">
              <span class="required-lable">Shedule Frequency</span>
              <select
                name="Schedule_Frequency"
                id="Schedule_Frequency"
                #refSchedule_Frequency="ngModel"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_Frequency.invalid,
                  'alert-warning':
                    refSchedule_Frequency.invalid &&
                    (refSchedule_Frequency.dirty ||
                      refSchedule_Frequency.touched ||
                      refSchedule_Frequency.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Schedule_Frequency"
                required
                [disabled]="Isedit"
              >
                <option value="">Select Frequancy</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Fortnightly">Fortnightly</option>
                <option value="Monthly">Monthly</option>
                <option value="ByMonthly">Bi-Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="HalfYearly">Half Yearly</option>
                <option value="Yearly">Yearly</option>
                <!--<option value="Bullet">Bullet</option>-->
              </select>
            </div>
            <div class="col-md-2">
              <span class="required-lable">Shedule Type</span>
              <select
                name="Schedule_Type"
                id="Schedule_Type"
                #refSchedule_Type="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refSchedule_Type.invalid,
                  'alert-warning':
                    refSchedule_Type.invalid &&
                    (refSchedule_Type.dirty ||
                      refSchedule_Type.touched ||
                      refSchedule_Type.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Schedule_Type"
                (change)="onChangeSchedule_Type()"
                [disabled]="Isedit"
              >
                <option value="">Select Schedule Type</option>
                <option value="Receipt">Receipt</option>
                <option value="Payment">Payment</option>
                <option value="Journal">Journal</option>
              </select>
            </div>
          </div>
          <div [hidden]="!voucherModel.Voucher.Schedule_Type">
            <div class="row m-0 mt-2 align-items-center">
              <div class="col-md-2">
                <span class="form-check-inline">
                  <label class="form-check-label" for="Loan">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="Loan"
                      name="Voucher_Sub_Type"
                      (change)="onVoucherSubTypeChange()"
                      [(ngModel)]="voucherModel.Voucher.Voucher_Sub_Type"
                      value="Loan"
                      [disabled]="Isedit"
                    />
                    Loan Entry
                  </label>
                </span>
              </div>
              <div class="col-md-2">
                <span class="form-check-inline">
                  <label class="form-check-label" for="Accounting">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="Accounting"
                      name="Voucher_Sub_Type"
                      value="Accounting"
                      [(ngModel)]="voucherModel.Voucher.Voucher_Sub_Type"
                      (change)="onVoucherSubTypeChange()"
                      [disabled]="Isedit"
                    />
                    Accounting Entry
                  </label>
                </span>
              </div>
              <div class="col-md-3">
                <span class="required-lable">Voucher Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    [min]="voucherModel.Voucher.Schedule_Start"
                    [max]="voucherModel.Voucher.Schedule_End"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_Date"
                    id="Voucher_Date"
                    #refVoucher_Date="ngModel"
                    class="form-control input-text-css"
                    [(ngModel)]="Voucher_Date"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVoucher_Date.invalid,
                      'alert-warning':
                        refVoucher_Date.invalid &&
                        (refVoucher_Date.dirty ||
                          refVoucher_Date.touched ||
                          refVoucher_Date.untouched)
                    }"
                    (dateChange)="onVoucherDateChange()"
                    [disabled]="Isedit"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
              <div class="col-md-3" [hidden]="SheduleStatus == null">
                <span class="required-lable">Voucher No</span>
                <input
                  type="text"
                  readonly
                  name="Voucher_No"
                  id="Voucher_No"
                  #refVoucher_No="ngModel"
                  class="form-control input-text-css"
                  [(ngModel)]="voucherModel.Voucher.Voucher_No"
                />
              </div>
              <!--<div class="col-md-2" [hidden]="!Isedit || (SheduleStatus != null && SheduleStatus != 'Pending')">
                <button type="button" (click)="DeactivateShedule()" mat-raised-button color="primary" class="mt-3"
                        style="background-color: #ff0000;color: #FFFFFF;font-size: 12px;">
                  Deactivate
                </button>
              </div>-->
            </div>
            <div
              class="row m-0 mt-2 align-items-center"
              *ngIf="voucherModel.Voucher.Schedule_Type != 'Journal'"
            >
              <div class="col-md-2">
                <span class="form-check-inline">
                  <label class="form-check-label" for="Bank">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="Bank"
                      name="Voucher_Tag"
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                      [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Tag"
                      required
                      value="Bank"
                      (change)="onVoucherTagChange()"
                    />
                    Bank
                  </label>
                </span>
              </div>
              <div class="col-md-2">
                <span class="form-check-inline">
                  <label class="form-check-label" for="CASH">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="CASH"
                      name="Voucher_Tag"
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                      [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Tag"
                      required
                      value="CASH"
                      (change)="onVoucherTagChange()"
                    />
                    Cash
                  </label>
                </span>
              </div>
              <!-- <div class="col-md-3" *ngIf="voucherModel.Voucher.Schedule_Type == 'Receipt'">
                  <span class="">Voucher ReceiptNo Book</span>
                  <input name="Voucher_ReceiptNo_Book" type="text" id="Voucher_ReceiptNo_Book" class="form-control input-text-css"
                  #refVoucher_ReceiptNo_Book="ngModel" [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptNo_Book" placeholder="Voucher ReceiptNo Book">
              </div>
              <div class="col-md-3" *ngIf="voucherModel.Voucher.Schedule_Type == 'Receipt'">
                  <span class="">Voucher Receipt Date</span>
                  <div class="datepicker_feild">
                  <input [matDatepicker]="pickervrc" dateConvert placeholder="DD/MM/YYYY" name="Voucher_ReceiptDate" id="Voucher_ReceiptDate"
                      #refVoucher_ReceiptDate="ngModel" class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher.Voucher_ReceiptDate">
                  <mat-datepicker-toggle matSuffix [for]="pickervrc"></mat-datepicker-toggle>
                  <mat-datepicker #pickervrc></mat-datepicker>
                  </div>
              </div> -->

              <div class="col-md-4">
                <span class="required-lable">Select Account </span>
                <select
                  name="DRAccountId"
                  id="DRAccountId"
                  required
                  #refDRAccountId="ngModel"
                  [disabled]="
                    SheduleStatus != null && SheduleStatus != 'Pending'
                  "
                  [ngClass]="{
                    'is-invalid': f.submitted && refDRAccountId.invalid,
                    'alert-warning':
                      refDRAccountId.invalid &&
                      (refDRAccountId.dirty ||
                        refDRAccountId.touched ||
                        refDRAccountId.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.DRAccountId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Account</option>
                  <option
                    *ngFor="let data of accountTagDropdown"
                    [value]="data.AccountId"
                  >
                    {{ data.Account_Name }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-4"
                *ngIf="voucherModel.Voucher_Detail.Voucher_Tag == 'Bank'"
              >
                <span class="required-lable">Select Mode of Payment</span>
                <select
                  name="Voucher_Mode_of_Payment"
                  id="Voucher_Mode_of_Payment"
                  required
                  #refVoucher_Mode_of_Payment="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refVoucher_Mode_of_Payment.invalid,
                    'alert-warning':
                      refVoucher_Mode_of_Payment.invalid &&
                      (refVoucher_Mode_of_Payment.dirty ||
                        refVoucher_Mode_of_Payment.touched ||
                        refVoucher_Mode_of_Payment.untouched)
                  }"
                  [(ngModel)]="
                    voucherModel.Voucher_Detail.Voucher_Mode_of_Payment
                  "
                  class="form-control input-text-css"
                  (change)="onChangeMode()"
                  [disabled]="
                    SheduleStatus != null && SheduleStatus != 'Pending'
                  "
                >
                  <option value="">Select Mode Of Payment</option>
                  <option value="Cheque">Cheque</option>
                  <!-- <option value="RTGS">RTGS</option>
                  <option value="Gateway">E-Gateway</option>
                  <option value="NEFT">NEFT</option> -->
                </select>
              </div>
            </div>

            <div
              class="row m-0 mt-2"
              [hidden]="SheduleStatus == null"
              *ngIf="
                voucherModel.Voucher_Detail.Voucher_Mode_of_Payment != '' &&
                voucherModel.Voucher_Detail.Voucher_Tag == 'Bank'
              "
            >
              <ng-container
                *ngIf="
                  voucherModel.Voucher_Detail.Voucher_Mode_of_Payment ==
                  'Cheque'
                "
              >
                <div class="col-md-3">
                  <span>Cheque No</span>
                  <input
                    type="text"
                    #refVoucher_Cheque_No="ngModel"
                    name="Voucher_Cheque_No"
                    id="Voucher_Cheque_No"
                    [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Cheque_No"
                    class="form-control input-text-css"
                    placeholder="Cheque No"
                    [disabled]="
                      SheduleStatus != null && SheduleStatus != 'Pending'
                    "
                  />
                </div>
                <div class="col-md-3">
                  <span>Cheque Date </span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="pickervcd"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="Voucher_Cheque_Date"
                      id="Voucher_Cheque_Date"
                      #refVoucher_Cheque_Date="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="
                        voucherModel.Voucher_Detail.Voucher_Cheque_Date
                      "
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickervcd"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickervcd></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3">
                  <span>Bank Name</span>
                  <input
                    type="text"
                    #refVoucher_Bank_Name="ngModel"
                    placeholder="Bank Name"
                    [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Bank_Name"
                    name="Voucher_Bank_Name"
                    id="Voucher_Bank_Name"
                    class="form-control input-text-css"
                    [disabled]="
                      SheduleStatus != null && SheduleStatus != 'Pending'
                    "
                  />
                </div>
                <!-- <div class="col-md-3">
                    <span class="">Cheque Clear Date </span>
                    <div class="datepicker_feild">
                    <input [matDatepicker]="pickervccd" dateConvert placeholder="DD/MM/YYYY" name="Voucher_Cheque_Clear_Date" id="Voucher_Cheque_Clear_Date"
                        #refVoucher_Cheque_Clear_Date="ngModel" class="form-control input-text-css" [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Cheque_Clear_Date"
                        [ngClass]="{ 'is-invalid': f.submitted && refVoucher_Cheque_Clear_Date.invalid, 'alert-warning': refVoucher_Cheque_Clear_Date.invalid  && (refVoucher_Cheque_Clear_Date.dirty || refVoucher_Cheque_Clear_Date.touched || refVoucher_Cheque_Clear_Date.untouched) }">
                    <mat-datepicker-toggle matSuffix [for]="pickervccd"></mat-datepicker-toggle>
                    <mat-datepicker #pickervccd></mat-datepicker>
                    </div>
                </div> -->
              </ng-container>
              <ng-container
                *ngIf="
                  voucherModel.Voucher_Detail.Voucher_Mode_of_Payment !=
                  'Cheque'
                "
              >
                <div class="col-md-4">
                  <span class="required-lable">Ref No</span>
                  <input
                    type="text"
                    required
                    #refVoucher_E_Ref_No="ngModel"
                    class="form-control input-text-css"
                    placeholder="Ref No"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVoucher_E_Ref_No.invalid,
                      'alert-warning':
                        refVoucher_E_Ref_No.invalid &&
                        (refVoucher_E_Ref_No.dirty ||
                          refVoucher_E_Ref_No.touched ||
                          refVoucher_E_Ref_No.untouched)
                    }"
                    [(ngModel)]="voucherModel.Voucher_Detail.Voucher_E_Ref_No"
                    name="Voucher_E_Ref_No"
                    id="Voucher_E_Ref_No"
                    [disabled]="
                      SheduleStatus != null && SheduleStatus != 'Pending'
                    "
                  />
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Transaction Date </span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="pickervetd"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="Voucher_E_Transaction_Date"
                      id="Voucher_E_Transaction_Date"
                      #refVoucher_E_Transaction_Date="ngModel"
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refVoucher_E_Transaction_Date.invalid,
                        'alert-warning':
                          refVoucher_E_Transaction_Date.invalid &&
                          (refVoucher_E_Transaction_Date.dirty ||
                            refVoucher_E_Transaction_Date.touched ||
                            refVoucher_E_Transaction_Date.untouched)
                      }"
                      class="form-control input-text-css"
                      [(ngModel)]="
                        voucherModel.Voucher_Detail.Voucher_E_Transaction_Date
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickervetd"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickervetd></mat-datepicker>
                  </div>
                </div>
              </ng-container>
            </div>
            <form #df="ngForm" novalidate>
              <div
                class="row m-0 mt-3 add-remove"
                *ngFor="
                  let vdData of voucherModel.Voucher_Detail;
                  let i = index
                "
              >
                <div class="col-md-12 row m-0 type-column">
                  <div class="type">
                    <span *ngIf="i == 0">Type</span>
                    <input
                      type="text"
                      name="TranType{{ i }}"
                      id="TranType{{ i }}"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="vdData.TranType"
                      *ngIf="voucherModel.Voucher.Schedule_Type != 'Journal'"
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                    />
                    <!-- [value]="voucherModel.Voucher.Schedule_Type == 'Payment' ? 'DR' : 'CR'"-->
                    <select
                      name="TranType{{ i }}"
                      id="TranType{{ i }}"
                      required
                      #refTranType="ngModel"
                      *ngIf="voucherModel.Voucher.Schedule_Type == 'Journal'"
                      [ngClass]="{
                        'is-invalid': df.submitted && refTranType.invalid,
                        'alert-warning':
                          refTranType.invalid &&
                          (refTranType.dirty ||
                            refTranType.touched ||
                            refTranType.untouched)
                      }"
                      [(ngModel)]="vdData.TranType"
                      class="form-control pl-1 input-text-css"
                      (change)="onChangeCRDR(vdData)"
                      [disabled]="
                        SheduleStatus != null && SheduleStatus != 'Pending'
                      "
                    >
                      <option [value]="'DR'">DR</option>
                      <option [value]="'CR'">CR</option>
                    </select>
                  </div>
                  <div class="row m-0 feild">
                    <ng-container
                      *ngIf="voucherModel.Voucher.Voucher_Sub_Type == 'Loan'"
                    >
                      <div class="col-md-2 pr-0">
                        <span class="required-lable" *ngIf="i == 0"
                          >Search</span
                        >
                        <div class="input-btn">
                          <input
                            required
                            type="text"
                            readonly
                            name="CaseNo{{ i }}"
                            id="CaseNo{{ i }}"
                            [(ngModel)]="vdData.CaseNo"
                            class="form-control input-text-css"
                            (keyup.enter)="searchCustomer(vdData, i)"
                            [disabled]="
                              SheduleStatus != null &&
                              SheduleStatus != 'Pending'
                            "
                          />
                          <button
                            type="button"
                            (click)="searchCustomer(vdData, i)"
                            class="primary-btn ml-2 search"
                            [disabled]="
                              SheduleStatus != null &&
                              SheduleStatus != 'Pending'
                            "
                          >
                            <i
                              class="fa fa-search"
                              aria-hidden="true"
                              style="font-size: 11px"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-md-2 pr-0">
                        <span *ngIf="i == 0">Customer</span>
                        <input
                          type="text"
                          name="Customer{{ i }}"
                          id="Customer{{ i }}"
                          class="form-control input-text-css"
                          readonly
                          [(ngModel)]="vdData.Customer"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                        />
                      </div>
                      <div class="col-md-2 pr-0">
                        <span *ngIf="i == 0">Branch</span>
                        <input
                          type="text"
                          name="Branch_Name{{ i }}"
                          id="Branch_Name{{ i }}"
                          class="form-control input-text-css"
                          readonly
                          [(ngModel)]="vdData.Branch_Name"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                        />
                      </div>
                      <div class="col-md-2 pr-0">
                        <span class="required-lable" *ngIf="i == 0"
                          >Category</span
                        >
                        <select
                          name="ChargeHeadId{{ i }}"
                          id="ChargeHeadId{{ i }}"
                          required
                          #refChargeHeadId="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              df.submitted && refChargeHeadId.invalid,
                            'alert-warning':
                              refChargeHeadId.invalid &&
                              (refChargeHeadId.dirty ||
                                refChargeHeadId.touched ||
                                refChargeHeadId.untouched)
                          }"
                          [(ngModel)]="vdData.ChargeHeadId"
                          class="form-control input-text-css"
                          (change)="onChangeChargesHead(vdData, i)"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                        >
                          <option value="">Select Category</option>
                          <option
                            *ngFor="let item of chargesHeadDropdown"
                            [value]="item.Id"
                          >
                            {{ item.ChagesHead }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2 pr-0">
                        <span *ngIf="i == 0"> Account Head</span>
                        <input
                          type="text"
                          name="ChargeHeadCategory{{ i }}"
                          id="ChargeHeadCategory{{ i }}"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                          class="form-control input-text-css"
                          readonly
                          [(ngModel)]="vdData.ChargeHeadCategory"
                        />
                      </div>

                      <div
                        class="col-md-2"
                        *ngIf="voucherModel.Voucher.Schedule_Type != 'Journal'"
                      >
                        <span class="required-lable" *ngIf="i == 0"
                          >Amount</span
                        >
                        <input
                          type="number"
                          min="0"
                          name="Amount{{ i }}"
                          id="Amount{{ i }}"
                          [disabled]="
                            !vdData.ChargeHeadId ||
                            (SheduleStatus != null &&
                              SheduleStatus != 'Pending')
                          "
                          class="form-control input-text-css text-right pr-3"
                          required
                          #refAmount="ngModel"
                          (change)="onChangeAmount($event, vdData)"
                          (keyup.enter)="onChangeAmount($event, vdData)"
                          [ngClass]="{
                            'is-invalid': df.submitted && refAmount.invalid,
                            'alert-warning':
                              refAmount.invalid &&
                              (refAmount.dirty ||
                                refAmount.touched ||
                                refAmount.untouched)
                          }"
                          [(ngModel)]="vdData.Amount"
                        />
                      </div>
                      <ng-container
                        *ngIf="voucherModel.Voucher.Schedule_Type == 'Journal'"
                      >
                        <div class="col-md-1 pr-0 pl-1">
                          <span
                            class=""
                            [ngClass]="{
                              'required-lable': vdData.TranType == 'DR'
                            }"
                            *ngIf="i == 0"
                            >Amount(DR)</span
                          >
                          <input
                            type="number"
                            min="0"
                            name="Amount{{ i }}"
                            id="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'DR'"
                            [disabled]="
                              (vdData.IsLoanChargeable &&
                                !vdData.ChargeHeadId) ||
                              (SheduleStatus != null &&
                                SheduleStatus != 'Pending')
                            "
                            class="form-control input-text-css text-right pr-3"
                            [required]="vdData.TranType == 'DR'"
                            #refAmount="ngModel"
                            (change)="onChangeAmount($event, vdData)"
                            (keyup.enter)="onChangeAmount($event, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched)
                            }"
                            [(ngModel)]="vdData.Amount"
                          />
                          <input
                            type="number"
                            name="AmountD{{ i }}"
                            id="AmountD{{ i }}"
                            readonly
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'CR'"
                          />
                        </div>
                        <div class="col-md-1 pr-0 pl-1">
                          <span
                            class=""
                            [ngClass]="{
                              'required-lable': vdData.TranType == 'CR'
                            }"
                            *ngIf="i == 0"
                            >Amount(CR)</span
                          >
                          <input
                            type="number"
                            min="0"
                            name="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'CR'"
                            id="Amount{{ i }}"
                            [disabled]="
                              (vdData.IsLoanChargeable &&
                                !vdData.ChargeHeadId) ||
                              (SheduleStatus != null &&
                                SheduleStatus != 'Pending')
                            "
                            class="form-control input-text-css text-right pr-3"
                            [required]="vdData.TranType == 'CR'"
                            #refAmount="ngModel"
                            (change)="onChangeAmount($event, vdData)"
                            (keyup.enter)="onChangeAmount($event, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched)
                            }"
                            [(ngModel)]="vdData.Amount"
                          />
                          <input
                            type="number"
                            name="AmountD{{ i }}"
                            id="AmountD{{ i }}"
                            readonly
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'DR'"
                          />
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                      "
                    >
                      <div class="col-md-4 pr-0">
                        <span class="required-lable" *ngIf="i == 0"
                          >Account Head</span
                        >
                        <select
                          name="ChargeHeadId{{ i }}"
                          id="ChargeHeadId{{ i }}"
                          required
                          #refChargeHeadId="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              df.submitted && refChargeHeadId.invalid,
                            'alert-warning':
                              refChargeHeadId.invalid &&
                              (refChargeHeadId.dirty ||
                                refChargeHeadId.touched ||
                                refChargeHeadId.untouched)
                          }"
                          [(ngModel)]="vdData.ChargeHeadId"
                          class="form-control input-text-css"
                          (change)="onChangeChargesHead(vdData, i)"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                        >
                          <option value="">Select Head</option>
                          <option
                            *ngFor="let item of accountHeadDropdown"
                            [value]="item.AccountId"
                          >
                            {{ item.Account_Name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4 pr-0">
                        <span class="required-lable" *ngIf="i == 0"
                          >Branch</span
                        >
                        <select
                          name="BranchId{{ i }}"
                          id="BranchId{{ i }}"
                          required
                          #refBranchId="ngModel"
                          [ngClass]="{
                            'is-invalid': df.submitted && refBranchId.invalid,
                            'alert-warning':
                              refBranchId.invalid &&
                              (refBranchId.dirty ||
                                refBranchId.touched ||
                                refBranchId.untouched)
                          }"
                          [(ngModel)]="vdData.BranchId"
                          class="form-control input-text-css"
                          [disabled]="
                            SheduleStatus != null && SheduleStatus != 'Pending'
                          "
                        >
                          <option value="">Select Branch</option>
                          <option
                            *ngFor="let branch of empBranchDropdown"
                            [value]="branch.BranchId"
                          >
                            {{ branch.Branch_Name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-4"
                        *ngIf="voucherModel.Voucher.Schedule_Type != 'Journal'"
                      >
                        <span class="required-lable" *ngIf="i == 0"
                          >Amount</span
                        >
                        <input
                          type="number"
                          min="0"
                          name="Amount{{ i }}"
                          id="Amount{{ i }}"
                          [disabled]="
                            !vdData.ChargeHeadId &&
                            SheduleStatus != null &&
                            SheduleStatus != 'Pending'
                          "
                          class="form-control input-text-css text-right pr-3"
                          required
                          #refAmount="ngModel"
                          (change)="onChangeAmount($event, vdData)"
                          (keyup.enter)="onChangeAmount($event, vdData)"
                          [ngClass]="{
                            'is-invalid': df.submitted && refAmount.invalid,
                            'alert-warning':
                              refAmount.invalid &&
                              (refAmount.dirty ||
                                refAmount.touched ||
                                refAmount.untouched)
                          }"
                          [(ngModel)]="vdData.Amount"
                        />
                      </div>
                      <ng-container
                        *ngIf="voucherModel.Voucher.Schedule_Type == 'Journal'"
                      >
                        <div class="col-md-2 pr-2">
                          <span
                            class=""
                            [ngClass]="{
                              'required-lable': vdData.TranType == 'DR'
                            }"
                            *ngIf="i == 0"
                            >Amount(DR)</span
                          >
                          <input
                            type="number"
                            min="0"
                            name="Amount{{ i }}"
                            id="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'DR'"
                            [disabled]="
                              !vdData.ChargeHeadId ||
                              (SheduleStatus != null &&
                                SheduleStatus != 'Pending')
                            "
                            class="form-control input-text-css text-right pr-3"
                            required
                            #refAmount="ngModel"
                            (change)="onChangeAmount($event, vdData)"
                            (keyup.enter)="onChangeAmount($event, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched)
                            }"
                            [(ngModel)]="vdData.Amount"
                          />
                          <input
                            type="number"
                            name="AmountD{{ i }}"
                            id="AmountD{{ i }}"
                            readonly
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'CR'"
                          />
                        </div>
                        <div class="col-md-2 pr-0">
                          <span
                            class=""
                            [ngClass]="{
                              'required-lable': vdData.TranType == 'CR'
                            }"
                            *ngIf="i == 0"
                            >Amount(CR)</span
                          >
                          <input
                            type="number"
                            min="0"
                            name="Amount{{ i }}"
                            id="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'CR'"
                            [disabled]="
                              !vdData.ChargeHeadId ||
                              (SheduleStatus != null &&
                                SheduleStatus != 'Pending')
                            "
                            class="form-control input-text-css text-right pr-3"
                            required
                            #refAmount="ngModel"
                            (change)="onChangeAmount($event, vdData)"
                            (keyup.enter)="onChangeAmount($event, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched)
                            }"
                            [(ngModel)]="vdData.Amount"
                          />
                          <input
                            type="number"
                            name="AmountD{{ i }}"
                            readonly
                            id="AmountD{{ i }}"
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'DR'"
                          />
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    vdData.TaxSlab &&
                    vdData.TaxSlab.length > 0 &&
                    voucherModel.Voucher.Schedule_Type != 'Journal'
                  "
                >
                  <div
                    class="col-md-12 row m-0 p-0 mt-2"
                    *ngFor="let taxData of vdData.TaxSlab; let ti = index"
                  >
                    <div class="col-md-2 pr-0">
                      <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                    </div>
                    <div class="col-md-3 pr-0">
                      <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span><b>Rate :</b>&nbsp;{{ taxData.TaxRate }} %</span>
                    </div>
                    <div class="col-md-3 pr-0">
                      <span
                        ><b>Account Head :</b>&nbsp;{{
                          taxData.TaxAccountName
                        }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 text-right"
                      style="padding: 0 1.8rem 0 2.8rem"
                    >
                      <input
                        type="text"
                        required
                        name="TaxAmount{{ i }}{{ ti }}"
                        id="TaxAmount{{ i }}{{ ti }}"
                        [(ngModel)]="taxData.TaxAmount"
                        (change)="onChangeTax()"
                        class="form-control input-text-css text-right pr-3"
                        [disabled]="
                          SheduleStatus != null && SheduleStatus != 'Pending'
                        "
                      />
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="
                    vdData.TaxSlab &&
                    vdData.TaxSlab.length > 0 &&
                    voucherModel.Voucher.Schedule_Type == 'Journal'
                  "
                >
                  <div
                    class="col-md-12 row m-0 p-0 mt-2"
                    *ngFor="let taxData of vdData.TaxSlab; let ti = index"
                  >
                    <div class="col-md-2 pr-0">
                      <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                    </div>
                    <div class="col-md-3 pr-0">
                      <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                    </div>
                    <div class="col-md-2 pr-0">
                      <span><b>Rate :</b>&nbsp;{{ taxData.TaxRate }} %</span>
                    </div>
                    <div class="col-md-3 pr-0">
                      <span
                        ><b>Account Head :</b>&nbsp;{{
                          taxData.TaxAccountName
                        }}</span
                      >
                    </div>
                    <div class="col-md-1 text-right pl-0 pr-3">
                      <input
                        type="text"
                        required
                        *ngIf="vdData.TranType == 'DR'"
                        name="TaxAmount{{ i }}{{ ti }}"
                        id="TaxAmount{{ i }}{{ ti }}"
                        [(ngModel)]="taxData.TaxAmount"
                        (change)="onChangeTax()"
                        class="form-control input-text-css text-right pr-3"
                        [disabled]="
                          SheduleStatus != null && SheduleStatus != 'Pending'
                        "
                      />
                    </div>
                    <div class="col-md-1 text-right pl-1 pr-3">
                      <input
                        type="text"
                        required
                        *ngIf="vdData.TranType == 'CR'"
                        name="TaxAmount{{ i }}{{ ti }}"
                        id="TaxAmount{{ i }}{{ ti }}"
                        [(ngModel)]="taxData.TaxAmount"
                        (change)="onChangeTax()"
                        class="form-control input-text-css text-right pr-3"
                        [disabled]="
                          SheduleStatus != null && SheduleStatus != 'Pending'
                        "
                      />
                    </div>
                  </div>
                </ng-container>

                <button
                  type="button"
                  class="remove"
                  (click)="removeData(i)"
                  [ngClass]="{ 'no-head': i != 0 }"
                  *ngIf="voucherModel.Voucher_Detail.length > 1"
                  [disabled]="
                    SheduleStatus != null && SheduleStatus != 'Pending'
                  "
                >
                  -
                </button>
                <button
                  type="button"
                  class="addmore"
                  (click)="addMoreData(i)"
                  *ngIf="i == voucherModel.Voucher_Detail.length - 1"
                  [ngClass]="{
                    both: voucherModel.Voucher_Detail.length > 1,
                    'no-head': i != 0
                  }"
                  [disabled]="
                    !df.form.valid ||
                    (SheduleStatus != null && SheduleStatus != 'Pending')
                  "
                >
                  +
                </button>
              </div>
              <div
                class="col-md-12 row m-0 p-0 pr-5 mt-2"
                *ngIf="totalCalculator"
              >
                <ng-container
                  *ngIf="voucherModel.Voucher.Schedule_Type != 'Journal'"
                >
                  <div class="col-md-8 pr-0">
                    <span><b>Grand Total :</b></span>
                  </div>
                  <div class="col-md-4 text-right pr-3">
                    <span
                      ><b>In Digit :</b>&nbsp;{{ totalCalculator.total }}</span
                    >
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="voucherModel.Voucher.Schedule_Type == 'Journal'"
                >
                  <div
                    class="pr-0"
                    [ngClass]="{
                      'col-md-10':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                      'col-md-8':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                    }"
                  >
                    <span><b>Grand Total :</b></span>
                  </div>
                  <div
                    class="text-right pr-1"
                    [ngClass]="{
                      'col-md-1':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                      'col-md-2':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                    }"
                  >
                    <span>{{ totalCalculator.totalDR }}</span>
                  </div>
                  <div
                    class="text-right pr-1"
                    [ngClass]="{
                      'col-md-1':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Loan',
                      'col-md-2':
                        voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
                    }"
                  >
                    <span>{{ totalCalculator.totalCR }}</span>
                  </div>
                </ng-container>
              </div>
              <!-- <div class="col-md-12 row m-0 p-0 pr-4 mt-2" *ngIf="totalCalculator">
                  <div class="col-md-7 pr-0"><span><b>Grand Total :</b></span></div>
                  <div class="col-md-2 text-right pr-5"><span><b>In Digit :</b>&nbsp;{{totalCalculator.total.toFixed(2)}}</span></div>
              </div> -->
            </form>
            <div class="row m-0 mt-2">
              <div class="col-md-12">
                <span class="">Voucher Narration</span>
                <input
                  type="text"
                  #refVoucher_Narration="ngModel"
                  name="Voucher_Narration"
                  id="Voucher_Narration"
                  [ngClass]="{
                    'is-invalid': f.submitted && refVoucher_Narration.invalid,
                    'alert-warning':
                      refVoucher_Narration.invalid &&
                      (refVoucher_Narration.dirty ||
                        refVoucher_Narration.touched ||
                        refVoucher_Narration.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher_Detail.Voucher_Narration"
                  class="form-control input-text-css"
                  [disabled]="
                    SheduleStatus != null && SheduleStatus != 'Pending'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="ml-4 mt-3 btn font-size-12 button-btn"
          [disabled]="!f.form.valid || !df.form.valid"
          (click)="saveVoucher()"
          [class.spinner]="loading"
          [hidden]="Isedit"
        >
          Save
        </button>

        <button
          type="button"
          (click)="onCloseVoucher()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearch()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <form
            #ff="ngForm"
            (keydown.enter)="Get_Acc_Search_LoanNo()"
            novalidate
            class="row m-0 col-md-12 p-0"
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-2">
                <span>Type</span>
                <select
                  name="Type"
                  id="Type"
                  class="form-control input-text-css"
                  [(ngModel)]="Type"
                >
                  <option [value]="0">All</option>
                  <option [value]="1">LOS Only</option>
                  <option [value]="2">LMS Only</option>
                </select>
              </div>
              <div class="col-md-2">
                <span>Application No.</span>
                <input
                  name="SApplicationNo"
                  [(ngModel)]="Customer.ApplicationNo"
                  id="SApplicationNo"
                  placeholder="Application No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Loan No.</span>
                <input
                  name="SLoanNo"
                  [(ngModel)]="Customer.LoanNo"
                  id="SLoanNo"
                  placeholder="Loan No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Customer Name</span>
                <input
                  name="CustomerName"
                  [(ngModel)]="Customer.CustomerName"
                  id="CustomerName"
                  placeholder="Customer Name"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Customer Phone</span>
                <input
                  name="CustomerPhone"
                  [(ngModel)]="Customer.CustomerPhone"
                  id="CustomerPhone"
                  placeholder="Customer Phone"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>PAN Card</span>
                <input
                  name="PANCard"
                  [(ngModel)]="Customer.PANCard"
                  id="PANCard"
                  placeholder="PAN Card"
                  class="form-control input-text-css"
                />
              </div>

              <div class="col-md-2">
                <span>Passport No.</span>
                <input
                  name="Passport"
                  [(ngModel)]="Customer.Passport"
                  id="Passport"
                  placeholder="Passport No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Voter ID Card</span>
                <input
                  name="VoterID"
                  [(ngModel)]="Customer.VoterID"
                  id="VoterID"
                  placeholder="Voter ID Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Driving License</span>
                <input
                  name="DL"
                  [(ngModel)]="Customer.DL"
                  id="DL"
                  placeholder="Driving License"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Aadhaar Card</span>
                <input
                  name="Aadhaar"
                  [(ngModel)]="Customer.Aadhaar"
                  id="Aadhaar"
                  placeholder="Aadhaar Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Vehicle No</span>
                <input
                  name="VehicleNo"
                  [(ngModel)]="Customer.VehicleNo"
                  id="VehicleNo"
                  placeholder="Vehicle No"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Close Case</span>
                <select
                  name="CloseCase"
                  id="CloseCase"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.CloseCase"
                >
                  <option value="">Select Close Case</option>
                  <option value="1">Close Case</option>
                </select>
              </div>

              <div class="col-md-2">
                <span>Date From</span>
                <input
                  name="LoanDateFrom"
                  [(ngModel)]="Customer.LoanDateFrom"
                  id="LoanDateFrom"
                  placeholder="Date From"
                  bsDatepicker
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2">
                <span>Date To</span>
                <input
                  name="LoanDateTo"
                  [(ngModel)]="Customer.LoanDateTo"
                  id="LoanDateTo"
                  placeholder="Date To"
                  bsDatepicker
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Select Type</span>
                <select
                  name="SelectType"
                  id="SelectType"
                  class="form-control input-text-css"
                  [(ngModel)]="SelectType"
                  (change)="SearchChange()"
                >
                  <option value="">Select Type</option>
                  <option value="Branch">Branch</option>
                  <option value="CollArea">Collection Area</option>
                  <option value="CollSubArea">Collection Sub Area</option>
                  <option value="CollExecutive">Collection Executive</option>
                  <option value="SalesExecutive">Sales Executive</option>
                  <option value="EngineNo">Engine No.</option>
                  <option value="ChassisNo">Chassis No.</option>
                  <option value="LoanAccCatg">Loan A/C Category</option>
                </select>
              </div>
              <div class="col-md-3">
                <div *ngIf="SelectType == 'Branch'">
                  <span> Branch</span>
                  <select
                    name="BranchId"
                    id="BranchId"
                    #refSelectGender="ngModel"
                    [(ngModel)]="Customer.BranchId"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Branch</option>
                    <option
                      *ngFor="let branch of BranchesList"
                      [value]="branch.BranchId"
                    >
                      {{ branch.Branch_Name }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'EngineNo'">
                  <span>Engine No.</span>
                  <input
                    name="EngineNo"
                    [(ngModel)]="Customer.EngineNo"
                    id="EngineNo"
                    placeholder="Engine No."
                    class="form-control input-text-css"
                  />
                </div>

                <div *ngIf="SelectType == 'ChassisNo'">
                  <span>Chassis No.</span>
                  <input
                    name="ChassisNo"
                    [(ngModel)]="Customer.ChassisNo"
                    id="ChassisNo"
                    placeholder="Chassis No."
                    class="form-control input-text-css"
                  />
                </div>

                <div *ngIf="SelectType == 'CollArea'">
                  <span> Collection Area</span>
                  <select
                    name="Area"
                    id="Area"
                    [(ngModel)]="Customer.Area"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection Area</option>
                    <ng-container *ngFor="let area of areaData">
                      <option
                        *ngIf="area.Type.trim() == 'Collection Area'"
                        [value]="area.Int_Id"
                      >
                        {{ area.Area_Name }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div *ngIf="SelectType == 'CollSubArea'">
                  <span> Collection Sub Area</span>
                  <select
                    name="SubArea"
                    id="SubArea"
                    [(ngModel)]="Customer.SubArea"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection SubArea</option>
                    <option
                      *ngFor="let subarea of subAreaData"
                      [value]="subarea.Int_Id"
                    >
                      {{ subarea.Sub_Area_Name }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'SalesExecutive'">
                  <span> Sales Executive</span>
                  <select
                    name="SalesEx"
                    id="SalesEx"
                    [(ngModel)]="Customer.SalesEx"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Sales Executive</option>
                    <option
                      *ngFor="let SalesExec of SalesExecutiveData"
                      [value]="SalesExec.EmpId"
                    >
                      {{ SalesExec.EmpName }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'CollExecutive'">
                  <span> Collection Executive</span>
                  <select
                    name="CollectionEx"
                    id="CollectionEx"
                    [(ngModel)]="Customer.CollectionEx"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Collection Executive</option>
                    <option
                      *ngFor="let CollExec of CollectionExecutiveData"
                      [value]="CollExec.EmpId"
                    >
                      {{ CollExec.Emp_FirstName }}
                    </option>
                  </select>
                </div>

                <div *ngIf="SelectType == 'LoanAccCatg'">
                  <span>Accounting Category </span>
                  <select
                    name="LoanAccountingCatId"
                    id="LoanAccountingCatId"
                    [(ngModel)]="Customer.LoanAccountingCatId"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Accounting Category</option>
                    <option
                      *ngFor="let AcCat of AccCatDropdown"
                      [value]="AcCat.Loan_CategoryId"
                    >
                      {{ AcCat.Loan_category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 text-right">
                <button
                  type="button"
                  (click)="Get_Acc_Search_LoanNo()"
                  class="mt-3 font-size-12 button-btn"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
          <form #sf="ngForm" class="col-md-12" novalidate>
            <hr />
            <mat-table
              [dataSource]="dataSourceCustomer"
              #sortList="matSort"
              matSort
              id="exportReport"
              style="height: 240px; max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="CustomerId">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw50"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell mw50"
                >
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Type">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw50"
                  *matHeaderCellDef
                  >Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                  row.Type
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Application_No">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                  >Aplication No</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">
                  {{ row.Application_No }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                >
                  Loan No</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                  row.LoanAcNo
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="CustomertName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Customer</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell"
                  >{{ row.CustomertName }}({{ row.AC_CustomerType }})</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="FatherName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Relation Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.FatherName
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="Customer_Gender">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw50"
                  *matHeaderCellDef
                  >Gender</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                  row.Customer_Gender
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw50"
                  *matHeaderCellDef
                >
                  Age</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                  row.GenderAge
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                >
                  Phone No</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.PhoneNo }}
                  <i
                    *ngIf="row.Customer_PhoneNo_IsVerified == true"
                    class="fa fa-check-circle ml-2"
                    style="color: green"
                  ></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="EditAction">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header mw100"
                  *matHeaderCellDef
                >
                  View Detail</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center mw100"
                >
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="selectSearchData(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </mat-cell>
              </ng-container>

              <mat-header-row
                class="sticky-footer"
                *matHeaderRowDef="displayedColumnsCustomer; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsCustomer"
              ></mat-row>
            </mat-table>
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            ></mat-paginator>
          </form>

          <div class="row col-md-12 m-0 mt-3 justify-content-end">
            <button
              type="button"
              class="btn btn-light font-size-12"
              (click)="OnCloseSearch()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="schedule_master_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr style="background: #4dc3a3 !important; border: 1px solid white">
    <th>#</th>
    <th>Name</th>
    <th>Type</th>
    <th>Start</th>
    <th>End</th>
    <th>Voucher Date</th>
    <th>Frequency</th>
    <th>Create By</th>
    <th>Create On</th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Schedule_Name }}</td>
    <td>{{ x.Schedule_Type }}</td>
    <td>{{ x.Schedule_Start }}</td>
    <td>{{ x.Schedule_End }}</td>
    <td>{{ x.Schedule_Voucher_Date }}</td>
    <td>{{ x.Schedule_Frequency }}</td>
    <td>{{ x.Schedule_CreateBy }}</td>
    <td>{{ x.Schedule_CreateOn }}</td>
  </tr>
</table>
