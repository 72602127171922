import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { constantUrl } from "../../Shared/constantUrl";
import { LmsService } from "../services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-lms-loan-disbursment-payment-detail",
  templateUrl: "./lms-loan-disbursment-payment-detail.component.html",
  styleUrls: ["./lms-loan-disbursment-payment-detail.component.scss"],
})
export class LmsLoanDisbursmentPaymentDetailComponent implements OnInit {
  currentUser: any;
  CurrentDate: any = new Date();
  showSpinner: boolean = false;
  loading: boolean = false;
  IsFullyPayment: boolean = false;
  IsDeductLoan: any = "0";
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  BankDetaildata: any;
  InterestDays: any = 0;
  InterestpayAmount: any = 0;
  TotalOtherAmount: any = 0;
  LoanId: any;
  customerId: any;
  ChangeFirstEMIDate: boolean = false;
  customerDetail: any;
  ProcessHeadList: any[] = [];
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  CrcApplicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  CustomerDataSource: any;
  @Input() loanSummary: any;
  ChargesHeadDataSource: any;
  ChargesDataSource: any;
  EmiDataSource: any;
  PDCDataSource: any;
  displayedCustomerColumns: string[] = [
    "Customer",
    "FatherName",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
  ];
  displayedChargesHeadColumns: string[] = ["Charges", "Amount", "ReceiptOn"];
  displayedChargesColumns: string[] = [
    "UpfrontCharges",
    "Deduction",
    "Receipt",
  ];
  displayedEMIColumns: string[] = [
    "EMI_Pattern",
    "NoOfEMI",
    "EMIAmount",
    "TotalAmount",
  ];
  displayedPDCColumns: string[] = [
    "Customer",
    "Bankname",
    "Branch",
    "AccountNo",
    "IFSCCode",
    "AmountDisbursment",
  ];
  Status: any;
  Disbursment: any;
  TotalDisbursAmt: any = 0;
  BankDetail: any;
  BankModel: any = [];
  EMIDate: any;
  FinalFirstEMIDate: any;
  BankDetailDropdown: any[] = [];
  DealerDropdown: any[] = [];
  PartnerDropdown: any[] = [];
  OtherDropdown: any[] = [];
  totalCalculator: any;
  minFinalFirstEMIDate: any;
  CustomerBorrower: any;
  DataSourceDisbursment: any;
  AdvancePayment: any;
  TotalInterest: any = 0;
  displayedColumnsDisbursment: string[] = [];
  DisbursedAmt: any = 0;
  Disbursment_Payment_Detail: any;
  _RestAmt: any;
  InterestDate: any;
  RemainingAmount: any = 0;
  Reason: any;
  Hold_Unhold: any;
  constructor(
    private datepipe: DatePipe,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this.encdec.decrypt(decodeURIComponent(param.get("id"))); // encdec.decrypt(param.get("appId"));
      this.Status = param.get("status");
      console.log("sss", this.Status);
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Disbursment Payment Allocation");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (this.loanSummary != "" && this.loanSummary != undefined) {
      //console.log("this.loanSummary.LoanId", this.loanSummary.LoanId);
      this.LoanId = this.loanSummary.LoanId;
    }
    // this.BankModel = [];
    this.getCustomerDetail();
    // this.getTotalCalculation();
    /*this.BankModel.push({
      Type: "",
      Customer: "",
      SubType: "",
      Amount:"",
    });*/
  }
  getCustomerDetail() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_DV_Details_Print({ LoanAcId: this.LoanId.toString() })
      .subscribe((res: any) => {
        console.log("res", res);
        this.showSpinner = false;
        this.customerDetail = res.Item1[0];
        this.CustomerDataSource = new MatTableDataSource(res.Item2);
        this.ProcessHeadList = JSON.parse(JSON.stringify(res.Item3));
        this.ProcessHeadList.forEach((x) => {
          x.Deduction = 0;
          x.Receipt = 0;
          if (x.Type == "DR") x.Deduction = x.Amount;
          if (x.Type == "CR") x.Receipt = x.Amount;
          x.Total = x.Deduction + x.Receipt;
        });
        this.ChargesDataSource = new MatTableDataSource(this.ProcessHeadList);
        this.ChargesHeadDataSource = new MatTableDataSource(res.Item4);
        this.EmiDataSource = new MatTableDataSource(res.Item5);
        this.PDCDataSource = new MatTableDataSource(
          res.Item6.filter((x) => x.PdcType == "Disbursment")
        );
        this.customerDetail.Application_FirstEMIDate = new Date(
          this.customerDetail.Application_FirstEMIDate
        );
        console.log(
          "this.customerDetail.Application_FirstEMIDate",
          this.customerDetail.Application_FirstEMIDate
        );
        this.Disbursment = res.Item6.filter(
          (item) => item.PdcType == "Disbursment"
        )[0];

        if (res.Item3.filter((item) => item.ChargeHeadId == 28).length > 0)
          this.TotalDisbursAmt = res.Item3.filter(
            (item) => item.ChargeHeadId == 28
          )[0].Amount;
        //console.log("this.TotalDisbursAmt", this.TotalDisbursAmt);

        this.GetPaymentDetailByLoan();
        this.GetBankDetailCustomerPartnerAccount();

        this.CustomerBorrower = res.Item2.filter(
          (x) => x.CustomerType == "Hirer"
        )[0];
        this.FinalFirstEMIDate = new Date();
        this.setFinalFirstEmiDate(this.FinalFirstEMIDate);

        // this.FinalFirstEMIDate = this.customerDetail.Application_FirstEMIDate;
      });
  }

  calculateDeductionTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Deduction"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateAmountTotal() {
    if (this.ChargesHeadDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesHeadDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesHeadDataSource.filteredData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateReceiptTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Receipt"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Total"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalEMI() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["NoOfEMI"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalAmount() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["TotalAmount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  Print() {
    window.print();
  }

  GetPaymentDetailByLoan() {
    this.displayedColumnsDisbursment = [
      "VoucherId",
      "Voucher_Date",
      "Voucher_No",
      "Transaction_Date",
      "Voucher_E_Ref_No",
      "Amount",
      "Voucher_CreateOn",
      "Voucher_CreateBy",
      "Account_CR",
    ];

    this.lmsService
      .Get_Payment_Detail_By_Loan({ LoanId: this.customerDetail.LoanId })
      .subscribe((res: any) => {
        console.log("Get_Payment_Detail_By_Loan", res);
        this.AdvancePayment = JSON.parse(JSON.stringify(res.Item1));

        this.AdvancePayment.forEach((obj: any) => {
          obj.InterestAmount = 0;
        });
        this.DataSourceDisbursment = new MatTableDataSource(
          this.AdvancePayment
        );
        var EMIDate = this.customerDetail.Application_FirstEMIDate;
        this.GetAdvancePaymentInterest(new Date(EMIDate));
        res.Item1.forEach((obj: any) => {
          if (obj.Origin != "Interest")
            this.DisbursedAmt = this.DisbursedAmt + parseFloat(obj.Amount) || 0;
        });
        this.IsDeductLoan = res.Item1.find((obj: any) => {
          return obj.Origin == "Interest";
        })
          ? "1"
          : "0";
        console.log("this.DisbursedAmt", this.DisbursedAmt);
        this._RestAmt = this.DisbursedAmt
          ? this.TotalDisbursAmt - this.DisbursedAmt
          : this.TotalDisbursAmt;
        this.RemainingAmount = parseFloat(this._RestAmt);
      });
  }
  onIsDeductLoan() {
    this.getTotalCalculation();
  }
  GetAdvancePaymentInterest(EMIDate) {
    var EDate = Object.assign(EMIDate);

    if (this.AdvancePayment) {
      if (this.customerDetail.EMI_Type == "Monthly") {
        EDate.setMonth(EDate.getMonth() - 1);
      } else if (this.customerDetail.EMI_Type == "ByMonthly") {
        EDate.setMonth(EDate.getMonth() - 2);
      } else if (this.customerDetail.EMI_Type == "Quarterly") {
        EDate.setMonth(EDate.getMonth() - 3);
      } else if (this.customerDetail.EMI_Type == "HalfYearly") {
        EDate.setMonth(EDate.getMonth() - 6);
      } else if (this.customerDetail.EMI_Type == "Yearly") {
        EDate.setMonth(EDate.getMonth() - 12);
      } else if (this.customerDetail.EMI_Type == "Weekly") {
        EDate.setDate(EDate.getDate() - 7);
      } else if (this.customerDetail.EMI_Type == "Fortnightly") {
        EDate.setDate(EDate.getDate() - 15);
      } else if (this.customerDetail.EMI_Type == "ByWeekly") {
        EDate.setDate(EDate.getDate() - 14);
      } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
        EDate.setDate(EDate.getDate() - 28);
      } else {
        EDate.setDate(EDate.getDate() - 1);
      }
      this.InterestDate = EDate;
      this.AdvancePayment.forEach((obj: any) => {
        if (new Date(obj.VoucherDate) > EDate) {
          obj.InterestAmount = 0;
        } else {
          var Days = this.getDiffDays(new Date(obj.VoucherDate), EDate);
          obj.InterestAmount = Math.round(
            ((obj.Amount * this.customerDetail.IRR) /
              100 /
              this.currentUser.DaysInYear) *
              Days
          );
        }
      });
    }
  }
  getDiffDays(startDate, endDate) {
    return Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));
  }
  addBankDetail() {
    
    if (this.BankModel.length > 0) {
      let _prev = this.BankModel[this.BankModel.length - 1];
      console.log("_prev", _prev);

      if (_prev.SubType == "Cash") {
        if (
          _prev.CustomerName == "" ||
          _prev.Type == "" ||
          _prev.Amount == "" ||
          _prev.SubType == ""
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please Fill Required Field to Add More.",
            ...this.configSuccess,
          });
          return;
        }
      } else {
        if (_prev.Type == "Dealer") {
          if (
            _prev.Type == "" ||
            _prev.Amount == "" ||
            _prev.Dealer_Name == ""
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill Required Field to Add More.",
              ...this.configSuccess,
            });
            return;
          }
        } else if (_prev.SubType == "Partner") {
          if (
            _prev.Type == "" ||
            _prev.CustomerType == "" ||
            _prev.Amount == "" ||
            _prev.AcHead == ""
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill Required Field to Add More.",
              ...this.configSuccess,
            });
            return;
          }
        } else if (_prev.SubType == "Other" || _prev.SubType == "Dealer") {
          if (
            _prev.Type == "" ||
            _prev.CustomerType == "" ||
            _prev.Amount == "" ||
            _prev.AcHead == ""
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill Required Field to Add More.",
              ...this.configSuccess,
            });
            return;
          }
        } else {
          if (
            _prev.Type == "" ||
            _prev.CustomerType == "" ||
            _prev.Amount == "" ||
            _prev.Customer == ""
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Please Fill Required Field to Add More.",
              ...this.configSuccess,
            });
            return;
          }
        }
      }
    }
    this.BankModel.push({
      Id: 0,
      LoanId: this.customerDetail.LoanId,
      Type: "Customer",
      Customer: "",
      SubType: "",
      Amount: "",
      PayDate: new Date(),
      CreatedBy: this.currentUser.userId,
      IsHold: false,
      Voucher_Id: 0,
      disable: false,
    });
    this.FinalFirstEMIDate = new Date(
      Math.max(...this.BankModel.map((e) => new Date(e.PayDate)))
    );
    this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
  }

  removeBankDetail(index: any) {
    this.BankModel.splice(index, 1);
    this.getTotalCalculation();
    if (Math.max(...this.BankModel.map((e) => new Date(e.PayDate))) == 0) {
      this.FinalFirstEMIDate = this.customerDetail.Application_FirstEMIDate;
      this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
      this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
    } else {
      this.FinalFirstEMIDate = new Date(
        Math.max(...this.BankModel.map((e) => new Date(e.PayDate)))
      );
      this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
      this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
    }
  }

  GetBankDetailCustomerPartnerAccount() {
    this.lmsService
      .Get_BankDetail_Customer_Partner_Account({
        LoanId: this.customerDetail.LoanId,
      })
      .subscribe((res: any) => {
        console.log("Get_BankDetail_Customer_Partner_Account", res);
        this.BankDetailDropdown = res.Item1;
        this.DealerDropdown = res.Item2;
        this.PartnerDropdown = res.Item4;
        this.OtherDropdown = res.Item3;
        this.showSpinner = true;

        if (this.Status == "Completed" || this.Status == "Hold") {
          this.lmsService
            .Get_Disbursment_Payment_Detail({ LoanId: this.LoanId })
            .subscribe((res: any) => {
              if (res.length > 0) {
                this.showSpinner = false;
                console.log("Get_Disbursment_Payment_Detail", res);
                this.Disbursment_Payment_Detail = res[0];
                this.BankModel = res;
                //var PaymentDoneCount = 0;
                this.BankModel.forEach((data: any) => {
                  data.CreatedBy = this.currentUser.userId;
                  if (data.Voucher_Id > 0) {
                    data.disable = true;
                    //PaymentDoneCount = PaymentDoneCount + 1;
                  } else {
                    data.disable = false;
                  }
                  if (data.SubType == "Dealer") {
                    let _DData = this.DealerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    // data.AcHead = _DData.Account_Name;
                    data.AcHead =
                      _DData.AccountId + "_" + _DData.Partner_AccountHolderName;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Partner") {
                    let _DData = this.PartnerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    // data.AcHead = _DData.Account_Name;
                    data.AcHead =
                      _DData.AccountId + "_" + _DData.Partner_AccountHolderName;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Other") {
                    let _OData = this.OtherDropdown.filter(
                      (x) => x.Account_Name == data.Account_Id_CR
                    )[0];
                    console.log("_OData", _OData);
                    // data.disable = true;
                    // data.AcHead = _OData.Account_Name;
                    data.AcHead =
                      _OData.AccountId + "_" + _OData.Partner_AccountHolderName;
                    data.BankName = _OData.Bank_BankName;
                    data.AccountNo = _OData.Bank_AccountNo;
                    data.BankIFSC = _OData.Bank_IFSCCode;
                    data.AcType = _OData.BankAcType;
                    data.BankBranch = _OData.Bank_BranchName;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = 0;
                    data.Account_Id_CR = _OData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Bank") {
                    // data.disable = false;
                    let _BData = this.BankDetailDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_BData", _BData);
                    //data.Customer = _BData.B_customerId + '_' + _BData.Type;
                    data.Customer = _BData.Id;

                    data.BankName = _BData.BankName;
                    data.AccountNo = _BData.AccountNo;
                    data.BankIFSC = _BData.BankIFSC;
                    data.AcType = _BData.BankAcType;
                    data.BankBranch = _BData.BankBranch;
                    data.Customer_Id = _BData.B_customerId;
                    data.Type = data.Type;
                    data.Reference_Id = _BData.Id;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Cash") {
                    // data.disable = false;
                    console.log("this.CustomerBorrower", this.CustomerBorrower);
                    data.CustomerName = this.CustomerBorrower.Customer;

                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                    data.Type = data.Type;
                  }
                  if (data.Type == "Customer" && data.SubType == "OtherParty") {
                    data.Customer = data.Other_Customer_Name;
                    data.BankName = data.Other_Bank;
                    data.AccountNo = data.Other_AccountNo;
                    data.BankIFSC = data.Other_Ifsc;
                    data.BankBranch = data.Other_Branch;
                    data.Type = data.Type;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                });

                this.getTotalCalculation();
              } else {
                this.showSpinner = false;
              }
            });
        } else {
          this.BankModel.push({
            Id: 0,
            LoanId: this.customerDetail.LoanId,
            Type: "Customer",
            Customer: "",
            SubType: "",
            Amount: "",
            PayDate: new Date(),
            disable: false,
            IsHold: false,
            Voucher_Id: 0,
            CreatedBy: this.currentUser.userId,
          });
        }
        this.showSpinner = false;
      });

    console.log("BankModelBankModelBankModel", this.BankModel);
  }

  onChangePaymentType(data, index) {
    //data.Customer = "";
    //data.Amount = "";
    //this.getTotalCalculation();
    this.BankModel[index].BankName = "";
    this.BankModel[index].AccountNo = "";
    this.BankModel[index].BankIFSC = "";
    this.BankModel[index].AcType = "";
    this.BankModel[index].BankBranch = "";
    this.BankModel[index].SubType = "";
    this.BankModel[index].Dealer_Name = "";
    this.BankModel[index].Customer = "";
  }

  ChangeBankACNo(data: any, index: any) {
    //  var arr = data.Customer.split("_");
    if (data.Type == "Customer" && data.SubType == "Bank") {
      let _BData = this.BankDetailDropdown.filter(
        (x) => x.Id == data.Customer
      )[0];
      console.log("_BData", _BData);
      this.BankModel[index].BankName = _BData.BankName;
      this.BankModel[index].AccountNo = _BData.AccountNo;
      this.BankModel[index].BankIFSC = _BData.BankIFSC;
      this.BankModel[index].AcType = _BData.BankAcType;
      this.BankModel[index].BankBranch = _BData.BankBranch;
      this.BankModel[index].Customer_Id = _BData.B_customerId;
      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = _BData.Id;
      this.BankModel[index].Account_Id_CR = 0;
    }

    if (data.SubType == "Dealer") {
      let _DData = this.DealerDropdown.filter(
        (x) =>
          x.AccountId == data.AcHead.split("_")[0] &&
          x.Partner_AccountHolderName == data.AcHead.split("_")[1]
      )[0];
      console.log("_DData", _DData);
      if (_DData == undefined || _DData == null) {
        data.AcHead = "";
      } else {
        this.BankModel[index].BankName = _DData.Partner_BankName;
        this.BankModel[index].AccountNo = _DData.Partner_AccountNo;
        this.BankModel[index].BankIFSC = _DData.Partner_BankIFSCCode;
        this.BankModel[index].AcType = _DData.BankAcType;
        this.BankModel[index].BankBranch = _DData.Partner_BankBranch;
        this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
        this.BankModel[index].Type = data.Type;
        this.BankModel[index].Reference_Id = _DData.Id;
        this.BankModel[index].Account_Id_CR = _DData.AccountId;
      }
    }

    if (data.SubType == "Partner") {
      let _DData = this.PartnerDropdown.filter(
        (x) =>
          x.AccountId == data.AcHead.split("_")[0] &&
          x.Partner_AccountHolderName == data.AcHead.split("_")[1]
      )[0];
      console.log("_DData", _DData);
      if (_DData == undefined || _DData == null) {
        data.AcHead = "";
      } else {
        this.BankModel[index].BankName = _DData.Partner_BankName;
        this.BankModel[index].AccountNo = _DData.Partner_AccountNo;
        this.BankModel[index].BankIFSC = _DData.Partner_BankIFSCCode;
        this.BankModel[index].AcType = _DData.BankAcType;
        this.BankModel[index].BankBranch = _DData.Partner_BankBranch;
        this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
        this.BankModel[index].Type = data.Type;
        this.BankModel[index].Reference_Id = _DData.Id;
        this.BankModel[index].Account_Id_CR = _DData.AccountId;
      }
    }
    //if (data.Type == 'Customer' && data.SubType == 'Dealer') {
    //  let _DData = this.DealerDropdown.filter(x => x.Account_Name == data.Dealer_Name)[0];
    //  console.log('_DData', _DData);
    //  this.BankModel[index].BankName = _DData.Partner_BankName;
    //  this.BankModel[index].AccountNo = _DData.Partner_AccountNo;
    //  this.BankModel[index].BankIFSC = _DData.Partner_BankIFSCCode;
    //  this.BankModel[index].AcType = _DData.BankAcType;
    //  this.BankModel[index].BankBranch = _DData.Partner_BankBranch;
    //  this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
    //  this.BankModel[index].Type = data.Type;
    //  this.BankModel[index].Reference_Id = _DData.Id;
    //  this.BankModel[index].Account_Id_CR = _DData.AccountId;
    //}

    if (data.Type == "Customer" && data.SubType == "Other") {
      let _OData = this.OtherDropdown.filter(
        (x) => x.Account_Name == data.AcHead
      )[0];
      console.log("_OData", _OData);
      if (_OData == undefined || _OData == null) {
        data.AcHead = "";
      } else {
        this.BankModel[index].BankName = _OData.Bank_BankName;
        this.BankModel[index].AccountNo = _OData.Bank_AccountNo;
        this.BankModel[index].BankIFSC = _OData.Bank_IFSCCode;
        this.BankModel[index].AcType = _OData.BankAcType;
        this.BankModel[index].BankBranch = _OData.Bank_BranchName;
        this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
        this.BankModel[index].Type = data.Type;
        this.BankModel[index].Reference_Id = 0;
        this.BankModel[index].Account_Id_CR = _OData.AccountId;
      }
    }
    //this.getTotalCalculation();
  }

  ChangeSubType(data: any, index: any) {
    if (data.SubType == "Cash") {
      console.log("this.CustomerBorrower", this.CustomerBorrower);
      this.BankModel[index].CustomerName = this.CustomerBorrower.Customer;

      this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;

      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = 0;
      this.BankModel[index].Account_Id_CR = 0;
    }
    if (data.SubType == "OtherParty") {
      console.log("this.CustomerBorrower", this.CustomerBorrower);

      this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;

      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = 0;
      this.BankModel[index].Account_Id_CR = 0;
    }
  }

  getTotalCalculation() {
    let _total: any = 0;
    this.BankModel.forEach((obj: any) => {
      if (
        (obj.disable == false || obj.disable == undefined) &&
        obj.Amount != null
      ) {
        _total = _total + parseFloat(obj.Amount) || 0;
      }
    });

    this.totalCalculator = {
      total: parseFloat(_total),
    };

    if (this.IsDeductLoan == "1") {
      this.RemainingAmount =
        parseFloat(this._RestAmt) -
        this.totalCalculator.total -
        this.TotalInterest;
    } else {
      this.RemainingAmount =
        parseFloat(this._RestAmt) - this.totalCalculator.total;
    }

    if (this.RemainingAmount < 0) {
      this.IsFullyPayment = true;
    } else {
      this.IsFullyPayment = false;
    }
  }

  SaveDisbursment() {
    if (this.RemainingAmount != 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Alert!! You can not Allocate More than Disbursment Amount.",
        ...this.configSuccess,
      });
      return;
    }

    var TotalOtherAmount = 0;
    this.BankModel.forEach((obj: any) => {
      if (obj.Type == "Dealer") {
        obj.SubType = "Other";
      }
      if (obj.SubType == "Other") {
        TotalOtherAmount = TotalOtherAmount + obj.Amount;
      }
    });
    this.TotalOtherAmount = TotalOtherAmount;

    this.GetInterestOnPay(
      new Date(this.customerDetail.Application_FirstEMIDate),
      TotalOtherAmount
    );

    if (this.InterestDays > 0 && this.TotalOtherAmount > 0) {
      $("#PreEmiModel").modal("show");
      $("#PreEmiModel").css("z-index", "1050");
    } else {
      this.dialog
        .openConfirmDialog(`Do you want to Save.`)
        .afterClosed()
        .subscribe((dialogResult) => {
          if (dialogResult == false) {
            return;
          } else {
            this.BankModel.forEach((obj: any) => {
              obj.CustomerName = obj.Customer;
              obj.ChangeFirstEMIDate = this.ChangeFirstEMIDate;
              delete obj.Customer;
              //delete obj.BankName;
              //delete obj.AccountNo;
              //delete obj.BankIFSC;
              //delete obj.AcType;
              //delete obj.BankBranch;
              delete obj.Dealer_Name;
            });

            let _data = {
              Payment_Detail: this.BankModel,
            };
            console.log("_data", _data);
            this.lmsService
              .Save_Disbursment_Payment_Detail({
                JSON: JSON.stringify(_data),
                TotalInterest: this.TotalInterest,
                FirstEMIDate: this.FinalFirstEMIDate,
                InterestDate: this.InterestDate,
                IsDeductLoan: Number(this.IsDeductLoan),
                IsPreEMI: 0,
                PreEmiAmount: 0,
              })
              .subscribe((res: any) => {
                if (res[0].CODE == 0) {
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });
                  this.router.navigate([`/lms/paymentauthorization`]);
                  //this.getCustomerDetail();
                } else {
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });
                }
                this.showSpinner = false;
              });
          }
        });
    }
  }

  onSaveWithOutPreEmi() {
    this.BankModel.forEach((obj: any) => {
      if (obj.Type == "Dealer") {
        obj.SubType = "Other";
      }
      obj.ChangeFirstEMIDate = this.ChangeFirstEMIDate;
      delete obj.Customer;
      delete obj.BankName;
      delete obj.AccountNo;
      delete obj.BankIFSC;
      delete obj.AcType;
      delete obj.BankBranch;
      delete obj.Dealer_Name;
      delete obj.CustomerName;
    });
    let _data = {
      Payment_Detail: this.BankModel,
    };
    console.log("_data", this.TotalInterest);
    this.lmsService
      .Save_Disbursment_Payment_Detail({
        JSON: JSON.stringify(_data),
        TotalInterest: this.TotalInterest,
        FirstEMIDate: this.FinalFirstEMIDate,
        InterestDate: this.InterestDate,
        IsDeductLoan: Number(this.IsDeductLoan),
        IsPreEMI: 0,
        PreEmiAmount: 0,
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.router.navigate([`/lms/paymentauthorization`]);
          //this.getCustomerDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }
  onSaveWithPreEmi() {
    this.dialog
      .openConfirmDialog(`Do you want to Save With Pre Emi Interest.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.BankModel.forEach((obj: any) => {
            if (obj.Type == "Dealer") {
              obj.SubType = "Other";
              obj.PreEmidays = this.InterestDays;
            }
            obj.ChangeFirstEMIDate = this.ChangeFirstEMIDate;
            delete obj.Customer;
            delete obj.BankName;
            delete obj.AccountNo;
            delete obj.BankIFSC;
            delete obj.AcType;
            delete obj.BankBranch;
            delete obj.Dealer_Name;
            delete obj.CustomerName;
          });
          let _data = {
            Payment_Detail: this.BankModel,
          };
          console.log("_data", _data);
          this.lmsService
            .Save_Disbursment_Payment_Detail({
              JSON: JSON.stringify(_data),
              TotalInterest: this.TotalInterest,
              FirstEMIDate: this.FinalFirstEMIDate,
              InterestDate: this.InterestDate,
              IsDeductLoan: Number(this.IsDeductLoan),
              IsPreEMI: 1,
              PreEmiAmount: this.InterestpayAmount,
            })
            .subscribe((res: any) => {
              if (res[0].CODE == 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.router.navigate([`/lms/paymentauthorization`]);
                //this.getCustomerDetail();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
              this.showSpinner = false;
            });
        }
      });
  }
  GetInterestOnPay(EMIDate, TotalOtherAmount) {
    var EDate = Object.assign(EMIDate);
    var interestcal = false;
    var Frequency = 30;
    //const dateParts = this.customerDetail.LoanDate.split('-');
    //const LoanDate = new Date(+dateParts[2], +dateParts[0] , +dateParts[1]);

    var diffDays = this.getDiffDays(
      new Date(this.customerDetail.Application_Loan_Date),
      EMIDate
    );

    if (this.customerDetail.EMI_Type == "Monthly") {
      EDate.setMonth(EDate.getMonth() - 1);
      if (diffDays >= 30) {
        interestcal = true;
        Frequency = Frequency * 1;
      }
    } else if (this.customerDetail.EMI_Type == "ByMonthly") {
      EDate.setMonth(EDate.getMonth() - 2);
      if (diffDays >= 60) {
        interestcal = true;
        Frequency = Frequency * 2;
      }
    } else if (this.customerDetail.EMI_Type == "Quarterly") {
      EDate.setMonth(EDate.getMonth() - 3);
      if (diffDays >= 90) {
        interestcal = true;
        Frequency = Frequency * 3;
      }
    } else if (this.customerDetail.EMI_Type == "HalfYearly") {
      EDate.setMonth(EDate.getMonth() - 6);
      if (diffDays >= 180) {
        interestcal = true;
        Frequency = Frequency * 6;
      }
    } else if (this.customerDetail.EMI_Type == "Yearly") {
      EDate.setMonth(EDate.getMonth() - 12);
      if (diffDays >= 360) {
        interestcal = true;
        Frequency = Frequency * 12;
      }
    } else if (this.customerDetail.EMI_Type == "Weekly") {
      EDate.setDate(EDate.getDate() - 7);
      if (diffDays >= 7) {
        interestcal = true;
        Frequency = 7;
      }
    } else if (this.customerDetail.EMI_Type == "Fortnightly") {
      EDate.setDate(EDate.getDate() - 15);
      if (diffDays >= 15) {
        interestcal = true;
        Frequency = 15;
      }
    } else if (this.customerDetail.EMI_Type == "ByWeekly") {
      EDate.setDate(EDate.getDate() - 14);
      if (diffDays >= 14) {
        interestcal = true;
        Frequency = 14;
      }
    } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
      EDate.setDate(EDate.getDate() - 28);
      if (diffDays >= 28) {
        interestcal = true;
        Frequency = 28;
      }
    } else {
      EDate.setDate(EDate.getDate() - 1);
      if (diffDays >= 1) {
        interestcal = true;
        Frequency = 1;
      }
    }

    //var Days = this.getDiffDays(new Date(this.customerDetail.LoanDate), EDate)
    diffDays = diffDays - Frequency;
    if (interestcal == true) {
      this.InterestpayAmount = Math.round(
        ((TotalOtherAmount * parseFloat(this.customerDetail.IRR)) /
          100 /
          this.currentUser.DaysInYear) *
          diffDays
      );
      this.InterestDays = diffDays;
    }
  }
  calculateDisbursmentTotal() {
    if (this.DataSourceDisbursment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.DataSourceDisbursment.filteredData.length; i++) {
        sum += Number(this.DataSourceDisbursment.filteredData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateInterestTotal() {
    if (this.DataSourceDisbursment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.DataSourceDisbursment.filteredData.length; i++) {
        sum += Number(
          this.DataSourceDisbursment.filteredData[i]["InterestAmount"]
        );
      }
      this.TotalInterest = sum.toFixed(2);
      return sum.toFixed(2);
    }
    return 0;
  }

  onPayDateChange(Bank) {
    console.log(
      "new Date(this.customerDetail.LoanDate)",
      new Date(
        this.customerDetail.LoanDate.split("-").reverse().join("-") +
          " 00:00:00"
      )
    );
    console.log("Bank.PayDate", new Date(Bank.PayDate));

    if (
      new Date(
        this.customerDetail.LoanDate.split("-").reverse().join("-") +
          " 00:00:00"
      ) > new Date(Bank.PayDate)
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Payment can not be done before Loan Date!",
        ...this.configSuccess,
      });
      Bank.PayDate = this.CurrentDate;
    } else {
      if (Math.max(...this.BankModel.map((e) => new Date(e.PayDate))) == 0) {
        this.FinalFirstEMIDate = this.customerDetail.Application_FirstEMIDate;
        this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
        this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
      } else {
        this.FinalFirstEMIDate = new Date(
          Math.max(...this.BankModel.map((e) => new Date(e.PayDate)))
        );
        this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
        this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
      }
    }
  }
  //onCloseEMIDateModel() {
  //  this.EMIDate = '';
  //  $('#EMIDateModel').modal('hide');
  //}
  onFinalFirstEMIDateChange() {
    var EMIDate = new Date(this.FinalFirstEMIDate);
    this.GetAdvancePaymentInterest(EMIDate);
    //this.dialog.openConfirmDialog(`Are you sure you want to Change FirstEMI Date ${new Date(this.EMIDate).toDateString()}?`)
    //  .afterClosed().subscribe(dialogResult => {
    //    if (dialogResult == false) {
    //      this.FinalFirstEMIDate = '';
    //      return;
    //    } else {
    //this.FinalFirstEMIDate = this.EMIDate;

    // this.onCloseEMIDateModel();
    //}
    // });
  }

  setFinalFirstEmiDate(EDate) {
    console.log("EDate", EDate);
    this.minFinalFirstEMIDate = new Date(Object.assign(EDate));
    console.log("minFinalFirstEMIDate", this.minFinalFirstEMIDate);
    if (this.customerDetail.EMI_Type == "Monthly") {
      EDate.setMonth(EDate.getMonth() + 1);
    } else if (this.customerDetail.EMI_Type == "ByMonthly") {
      EDate.setMonth(EDate.getMonth() + 2);
    } else if (this.customerDetail.EMI_Type == "Quarterly") {
      EDate.setMonth(EDate.getMonth() + 3);
    } else if (this.customerDetail.EMI_Type == "HalfYearly") {
      EDate.setMonth(EDate.getMonth() + 6);
    } else if (this.customerDetail.EMI_Type == "Yearly") {
      EDate.setMonth(EDate.getMonth() + 12);
    } else if (this.customerDetail.EMI_Type == "Weekly") {
      EDate.setDate(EDate.getDate() + 7);
    } else if (this.customerDetail.EMI_Type == "Fortnightly") {
      EDate.setDate(EDate.getDate() + 15);
    } else if (this.customerDetail.EMI_Type == "ByWeekly") {
      EDate.setDate(EDate.getDate() + 14);
    } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
      EDate.setDate(EDate.getDate() + 28);
    } else {
      EDate.setDate(EDate.getDate() + 1);
    }
  }
  Hold(i, Bank) {
    this.BankDetail = i;
    this.BankDetaildata = Bank;
    this.Hold_Unhold = "Hold";
    $("#Hold_UnHoldModel").modal("show");
    $("#Hold_UnHoldModel").css("z-index", "1050");
    // Bank.IsHold = true;
    //if (Bank.Id > 0) {
    //  this.lmsService.Update_Disbursment_Payment_Hold_UnHold({ Id: Bank.Id, IsHold: true }).subscribe((res: any) => {
    //    if (res[0].CODE == 0) {
    //      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
    //      Bank.IsHold = true;
    //    }
    //    else {
    //      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
    //    }
    //    this.showSpinner = false;
    //  });
    //} else {
    //  Bank.IsHold = true;
    //}
  }
  Releasel(i, Bank) {
    this.BankDetail = i;
    this.BankDetaildata = Bank;
    this.Hold_Unhold = "Release";
    $("#Hold_UnHoldModel").modal("show");
    $("#Hold_UnHoldModel").css("z-index", "1050");
    //Bank.IsHold = false;
    //if (Bank.Id > 0) {
    //  this.lmsService.Update_Disbursment_Payment_Hold_UnHold({ Id: Bank.Id, IsHold: false }).subscribe((res: any) => {
    //    if (res[0].CODE == 0) {
    //      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
    //      Bank.IsHold = false;
    //    }
    //    else {
    //      this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess, });
    //    }
    //    this.showSpinner = false;
    //  });
    //} else {
    //  Bank.IsHold = false;
    //}
  }

  onCloseUnHoldModel() {
    this.BankDetail = 0;
    this.Hold_Unhold = "";
    $("#Hold_UnHoldModel").modal("hide");
  }
  onSaveUnHoldModel() {
    if (this.Hold_Unhold == "Hold") {
      this.BankModel[this.BankDetail].IsHold = true;
      this.BankModel[this.BankDetail].HoldReason = this.Reason;
    }
    if (this.Hold_Unhold == "Release") {
      this.BankModel[this.BankDetail].IsHold = false;
      this.BankModel[this.BankDetail].UnHoldReason = this.Reason;
    }
    this.onCloseUnHoldModel();
  }
  ShowCAMReport() {
    console.log("sdfjhsfhskjfhskjfhskjfhsjkhf");

    $("#CAMReport").modal("show");
    $("#CAMReport").css("z-index", "1050");
  }
  HideCAMReport() {
    $("#CAMReport").modal("hide");
  }

  onClosePreEmiModel() {
    $("#PreEmiModel").modal("hide");
  }
}
