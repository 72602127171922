import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../services/lms.service";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import * as moment from "moment";
import { LosService } from "../../_LOS/services/los.service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "app-lms-asset-info",
  templateUrl: "./asset-info.component.html",
  styleUrls: ["./asset-info.component.scss"],
})
export class PostAssetInfoComponent implements OnInit {
  @ViewChild("CArea") ConstArea: ElementRef;
  @ViewChild("CAddArea") ConstAddArea: ElementRef;

  private data: any;
  RC_HPNEndorsment: boolean = false;
  Invoice_HPNEndorsment: boolean = false;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  applicationDetail: any = "";
  InvoiceNo: any = "";
  DealerContactPerson: any = "";
  InvoiceValue: any = "";
  DealerContactNo: any = "";
  IssuedInFavourOf: any = "";
  Remark: any = "";
  FuelTypeOption: any;
  Collateral: any;
  loading: boolean = false;
  CurrentDate: any = new Date();
  // register new role
  RCVerified_Button: boolean = false;
  APIResponse: any;
  EmployeeId: any = 0;
  Addi_ProductCat: boolean = false;
  SelectRoleId: any = "";
  FirstName: any = "";
  LastName: any = "";
  SelectGender: any = "";
  DateOfBirth: any = "";
  PresentAddress: any = "";
  PermanentAddress: any = "";
  PhoneNo: any = "";
  AlternetPhoneNo: any = "";
  EnterEmail: any = "";
  AadharNo: any = "";
  EmployeeLoginId: any = "";
  EmployeePassword: any = "";
  EmpManagerId: any = "";
  UploadImageBase64: any = "";
  UploadImageName: string = "";
  profilePicture: string = "";
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  duplicateData: any;
  Category_Id: any = "";
  Manufacture_Id: any = "";
  Product_Id: any = "";
  Model_Id: any = "";
  Varient: any;
  Identification_No1: any;
  Identification_No2: any;
  Cost: any;
  ColorName: any;
  Invoice_No: any;
  Dealer_Id: any = "";
  DealerPhoneNo: any;
  DealerPerson: any;
  Invoice_Date: any;

  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  // copy employee
  CopyEmployeeDropdown: any = [];
  vehicleCategoryData: any = [];
  CopyEmployeeId: any = "";
  vehicleModelData: any = [];
  isShowCopyEmployee: boolean = false;
  ManufactureData: any = [];
  commercialVehicle: any = "Non-Commercial";
  RCLastVerfiedDate: any;
  RCKYC_IsVerified: any;
  SelectVehicleCategory: any = "";
  FuelType: any = "";
  RoadTaxUpto: any = "";
  RegistationExpiryDate: any = "";
  SelectFuelType: any = "";
  Color: any = "";
  FitnessUpto: any = "";
  Dealer: any = "0";
  PermitUpto: any = "";
  VehicleCost: any = "";
  Route: any = "";
  EngineNo: any = "";
  ChassisNo: any = "";
  KeyNo: any = "";
  DeliveryorderNo: any = "";
  DeliveryorderDate: any = "";
  DoIssuedFavour: any = "";
  DealerContact: any = "";
  LTVBase: any = "";
  ValuationGrid: any = "";
  ValuationDoneBy: any = "0";
  GridValue: any = "";
  PermitNo: any = "";
  PermitIssuedDate: any = "";
  PermitExpireDate: any = "";
  PermitNature: any = "";
  RouteDescription: any = "";
  VehicleManufacture: any = "0";
  VehicleModel: any = "0";
  Variant: any = "";
  RCKey: any;
  RCSMS_URL: any;
  ManufactureYear: any = "";
  VehicleRegistationNo: any = "";
  RegistationDate: any = "";
  InvoiveNo: any = "";
  InvoiveDate: any = "";
  ValuationType: any = "";
  OtherValuationType: any = "";
  OtherValuationAmount: any = "";

  // property form
  PropertyOwnerName: any = "";
  AddressOfProperty: any = "";
  Location: any = "";
  TypeOfProperty: any = "";
  NatureOfProperty: any = "";
  OwnershipDocument: any = "";
  OwnershipType: any = "";
  UnitOfMeasurement: any = "";
  TotalArea: any = "";
  ConstructedArea: any = "";
  ValutionAmount: any = "";
  SearchReportDoneBy: any = "";
  TypeOfMortgage: any = "";
  MortgageSingedBy: any = "";
  OwnershipTypeList: any;
  NatureOfPropertyList: any = "";
  OwnershipDocumentList: any = "";
  TypeOfPropertyList: any = "";
  EstimationAmount: any = "";
  ValuationRemarkDoc: any = "";
  SearchReportRemarkDoc: any = "";
  Pincode: any = "";
  propertyForm: string = "";
  usedVehicle: boolean = false;
  newVehicle: boolean = true;
  Showdditional: boolean = false;
  ConsumerGoldForm: any;
  PersonalForm: any;
  AgricultureForm: any;
  today: Date = new Date();
  // LoanAcNo: any;
  vehicleCategoryId: any;
  LoanId: any;
  type: any;
  CustomerList: any = [];
  DealerList: any;
  isUpdate: boolean = false;
  isEdit: boolean = false;
  AssetTableView: boolean = false;
  Info: any;
  AssetsForm: any = "Form";
  saveBtn: boolean = true;
  updateBtn: boolean = true;
  nextBtn: boolean = true;
  customerModel: any = {};
  latitude: any = "";
  longitude: any = "";
  SearchReportRemark: any;
  ValuationRemark: any;
  Collateral_Type: any;
  ValuationDate: any;
  InvoiceDate: any;
  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource:any;

  displayedColumns: any[] = [
    "Collateral_Type",
    "Collateral",
    "ValuationType",
    "ValuationAmount",
    "ValuationDate",
    "View_Detail",
  ];

  displayedColumnsVehicleGridModel: any[] = ["modelId", "modelName"];
  dataSource: any;
  getLmsHeaderProduct: string;
  LoanAccounting: Object;
  ProductList: any = [];
  ProductCatId: any = "";
  AgricultureForms: string;
  AssetsForms: string;
  PersonalForms: string;
  ConsumerGoldForms: string;
  propertyForms: string;
  vehicleCategoryIds: any;
  AssetsList: any = [];
  ProductCategoryDropdown: any = [];
  SearchReportDoneByData: any = [];
  ValuationDoneByData: any = [];

  stateDropdown: any[] = [];
  districkDropdownReg: any[] = [];
  tehasilDropdownReg: any[] = [];
  NextRoute: any;
  CRCTab: any;
  FITVRTab: any;
  ChequeDetailTab: any;
  DocModel: any[] = [];
  DocModel1: any = {};
  vehicle_type: any = "New";
  SearchDocData: any;
  ValuationDocData: any;
  SearchReportDoneByOther: any;
  ValuationDoneByOther: any;
  CollectionExecutiveData: any;
  //Gold
  Item: any = {};
  dataAssetItemSource: any;
  displayedAssetItemColumns: string[] = [];
  ItemArr: any = [];
  AssetsAttachment: any = [{}];
  assetTotal: any;
  assetModel: any = {};
  displayedViewAssetItemColumns: any;
  dataviewAssetItemSource: any;
  ID: any = 0;
  PrimaryCount: any = 0;
  val_sum: any = 0;
  DuplicateType: any;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  SelectProductModel: any = "";
  productList: any[] = [];

  PageAcess: any;
  Application_Date: any;
  fixrate24ct: any = 100 / 24;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  categoryList: any;
  manufactureList: any[];
  modelList: any;
  dataSourceViewDetails: any;
  bsConfig: Partial<BsDatepickerConfig>;
  modelNameDropdown: any;
  ManufactureYearModal;
  Vehicle_Model_Name: any;
  VehicleModelGrid: any;

  constructor(
    private _Route: Router,
    private activedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private LmsService: LmsService,
    private DecriptService: EncrDecrService,
    private losService: LosService,
    private dialog: DialogService,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.bsConfig = {
      dateInputFormat: "YYYY",
      minMode: "year",
      showWeekNumbers: false,
    };
  }

  ngOnInit() {
    this.Get_Master_Consumer_Durable_Category();
    this.dataSharingService.HeaderTitle.next("Asset Info");
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
    this.Item.PurityCT = 12;
    this.Item.ValulationAmount = "";
    this.Item.ItemType = "";
    console.log(
      "loanSummary.Product_Category",
      this.loanSummary.Product_Category
    );
    //this.activedRoute.paramMap.subscribe((param: ParamMap) => {
    this.displayedAssetItemColumns = [
      "Id",
      "ItemName",
      "ItemType",
      "Qty",
      "PurityCT",
      "GrossWt",
      "StoneWt",
      "OtherWt",
      "NetWt",
      "PureWt",
      "ValulationRate_PerCT",
      "ValulationAmount",
      "Action",
    ];
    this.displayedViewAssetItemColumns = [
      "Id",
      "ItemName",
      "ItemType",
      "Qty",
      "PurityCT",
      "GrossWt",
      "StoneWt",
      "OtherWt",
      "NetWt",
      "PureWt",
      "ValulationRate_PerCT",
      "ValulationAmount",
      "Image",
    ];
    this.updateBtn = false;
    this.Application_Date = new Date(
      this.loanSummary.Application_Date.split("-").reverse().join("-") +
      " 00:00:00"
    );
    // if (this.type == 'L' || this.type == 'A') {
    //   this.type = decodeURIComponent(param.get("Type"));
    //   this.dataSharingService.LmsHeaderType.next(this.type);
    //   this.LoanId = decodeURIComponent(param.get("id"));
    //   this.dataSharingService.LmsHeaderLoanId.next(this.LoanId);
    //   if (this.type == 'L') {
    this.isEdit = false;
    this.saveBtn = true;

    this.AssetsForm = "";

    this.LMS_GetAllUserAssetInfo();

    this.getProductDropdown();

    this.getProductCategory();
    this.getCustomerList();
    this.getFuelType();
    this.getDealerList();
    this.getNatureOfProperty();
    this.getOwnershipType();
    this.getOwnershipDocument();
    this.getTypeOfProperty();

    this.Get_ValutionDoneByForDropdown();
    this.Get_SearchReportDoneByForDropdown();
    this.GetCollectionExecutive();

    this.GetStateDropdown();
    this.LMS_GetAssetInfoMaster();
    this.LMS_GetVehicleMaster();
    //this.LMS_GetVehicleModelMaster();

    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    this.PageAcess = AllPage.filter((x) => x.Page_Name == "Asset Info");

    if (
      this.PageAcess == "" ||
      this.PageAcess == null ||
      this.PageAcess == undefined
    ) {
      this.RedirectTo();
    }

    this.LMS_GetVehicleModelMasterAsset();
  }
  setProductId(value) {
    this.getLmsHeaderProduct = value;
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  GetDistrickDropdownReg(event: any) {
    this.losService
      .GetDistrickDropdown({
        StateID: parseInt(this.customerModel.Firm_RegState),
      })
      .subscribe((res: any) => {
        this.districkDropdownReg = res;
      });
  }
  GetTehasilDropdownReg(event: any) {
    this.losService
      .GetTehasilDropdown({
        DistrictId: parseInt(this.customerModel.Firm_RegDistrict),
      })
      .subscribe((res: any) => {
        this.tehasilDropdownReg = res;
      });
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  onCopyPresentAdd(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.PermanentAddress = this.PresentAddress;
    } else {
      this.PermanentAddress = "";
    }
  }

  LMS_GetVehicleMaster() {
    this._MasterService
      .GetVehicleCategoryForDropdown({})
      .subscribe((response: any) => {
        this.vehicleCategoryData = response;
      });
  }

  LMS_GetVehicleModelMaster(event) {
    this.showSpinner = true;

    if (
      typeof this.VehicleManufacture != "undefined" &&
      this.VehicleManufacture &&
      typeof this.SelectVehicleCategory != "undefined" &&
      this.SelectVehicleCategory
    ) {
      this._MasterService
        .GetVehicleModelForDropdown({
          ManufactureId: this.VehicleManufacture,
          CategoryId: this.SelectVehicleCategory,
        })
        .subscribe((response: any) => {
          this.vehicleModelData = response;
        });
    } else {
      this.vehicleModelData = [];
    }
  }

  LMS_GetAssetInfoMaster() {
    this.showSpinner = true;

    this._MasterService
      .GetManufactureForDropdown({})
      .subscribe((response: any) => {
        this.ManufactureData = response;
      });
  }

  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
    });
  }
  getDealerList() {
    this.LmsService.GET_Partner_List({ Type: "Dealer" }).subscribe((result) => {
      this.DealerList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.LmsService.Get_Lms_CollectionExecutive({
      Branch_Id: this.loanSummary.BranchId,
      ProductId: this.loanSummary.ProductId,
    }).subscribe((response) => {
      this.CollectionExecutiveData = response;
    });
  }

  LMS_GetAllUserAssetInfo() {
    //this.LoanAcNo = LoanNo;
    let data = {
      Loan_Id: this.loanSummary.LoanId,
    };
    this.LmsService.LMS_GetAllUserAssetInfo(data).subscribe((response: any) => {
      this.Showdditional = true;
      this.AssetsList = response;
      this.dataSource = new MatTableDataSource(response);
      for (let i = 0; i < response.length; i++) {
        if (
          response[i]["Collateral_Type"] == "Primary Collateral" ||
          response[i]["Collateral_Type"] == "Primary"
        ) {
          this.val_sum += Number(response[i]["ValuationAmount"]);
          this.PrimaryCount = i + 1;
        }
      }
      console.log("PrimaryCount", this.PrimaryCount);
      // }
    });
  }

  selectVehicleCategory(event) {
    this.commercialVehicle = this.SelectVehicleCategory;
  }

  onCloseKYC() {
    $("#kycModel").modal("hide");
    $("#additionalcollateral").modal("hide");
  }
  fileChangeListenerAttachment(files: any, input: any) {
    if (!this.AssetsAttachment[this.AssetsAttachment.length - 1].Title) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter  title.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }

    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.AssetsAttachment.find((obj) => {
      return obj.DocFileName == _fn;
    });

    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same file can not be allow again.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }

    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.AssetsAttachment[this.AssetsAttachment.length - 1].DocFileName =
      _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;
    _data.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    input.value = "";
    this.AssetsAttachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };
  }

  fileChangeListener(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.ValuationDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.ValuationDocData;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.ValuationRemarkDoc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.ValuationDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  fileChangeListener1(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.SearchDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      delete this.SearchDocData;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.SearchReportRemarkDoc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.SearchDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.AssetsAttachment.splice(i, 1);
  }
  removeFile(input: any) {
    input.value = "";
    this.ValuationRemarkDoc = "";
    this.ValuationDocData = "";
  }
  removeFile1(input: any) {
    input.value = "";
    this.SearchReportRemarkDoc = "";
    this.SearchDocData = "";
  }
  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => { });
  }
  UploadCrcDoc() {
    this.showSpinner = true;
    for (let i = 0; i < this.DocModel.length; i++) {
      this.losService.UploadCrcDoc(this.DocModel[i]).subscribe(
        (res: any) => { },
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }
  saveAssetInfo(Collateral_Type) {
    // console.log("this.ManufactureYear.getFullYear()", this.ManufactureYear.getFullYear());
    let localUser = JSON.parse(sessionStorage.getItem("currentUser"));
    let saveData: any = {};
    this.AssetsAttachment.splice(this.AssetsAttachment.length - 1, 1);
    //console.log("this.ManufactureYear.getFullYear()", this.ManufactureYear.getFullYear());
    let updateAssets;
    this.UploadCrcDoc();
    if (
      Collateral_Type == "Primary Collateral" ||
      Collateral_Type == "Primary"
    ) {
      if (
        this.propertyForm == "Property" ||
        this.AddressOfProperty ||
        this.TypeOfProperty
      ) {
        // if Property detail adding in Primary Collateral
        if (this.ValuationDocData) {
          this.uploadDoc({
            ApplicationNo: this.loanSummary.ApplicationNo,
            DocName: this.ValuationRemarkDoc,
            DocData: this.ValuationDocData,
          });
        }
        if (this.SearchDocData) {
          this.uploadDoc({
            ApplicationNo: this.loanSummary.ApplicationNo,
            DocName: this.SearchReportRemarkDoc,
            DocData: this.SearchDocData,
          });
        }
        let GeoLocation = this.latitude + "," + this.longitude;
        saveData = {
          Property: this.dataSharingService.cleanObject({
            CustomerId: this.PropertyOwnerName,
            TypeofProperty: this.TypeOfProperty,
            NatureofProperty: this.NatureOfProperty,
            OwnershipType: this.OwnershipType,
            OwnershipDocument: this.OwnershipDocument,
            UnitofMeasurement: this.UnitOfMeasurement,
            Address: this.AddressOfProperty,
            TotalArea: this.TotalArea,
            ConstructedArea: this.ConstructedArea,
            TypeofMortgage: this.TypeOfMortgage,
            MortgageSingedBy: this.MortgageSingedBy,
            ValuationDoneBy: parseInt(this.ValuationDoneBy),
            ValuationAmount: this.ValutionAmount,
            GeoLocation: GeoLocation,
            LegalReportBy: localUser.userId,
            ValuationRemark: this.ValuationRemark,
            ValuationRemarkDoc: this.ValuationRemarkDoc,
            SearchReportRemark: this.SearchReportRemark,
            SearchReportRemarkDoc: this.SearchReportRemarkDoc,
            Collateral_Type: Collateral_Type,
            LoanId: this.loanSummary.LoanId,
            CreatedBy: localUser.userId,
            state: this.customerModel.Firm_RegState,
            District: this.customerModel.Firm_RegDistrict,
            tasil: this.customerModel.Firm_RegTehsil,
            ValuationDoneByOther: this.ValuationDoneByOther,
            SearchReportDoneByOther: this.SearchReportDoneByOther,
            SearchReportDoneBy: this.SearchReportDoneBy,
            PinCode: this.Pincode,
          }),
          data: {
            LoanId: this.loanSummary.LoanId,
            AssetType: "Property",
            AssetId: this.ID,
            CreatedBy: localUser.userId,
          },
          Attachment: this.AssetsAttachment,
        };
        //var RemoveAmount = this.AssetsList.find((obj: any) => { return obj.AssetId == this.ID }).ValuationAmount;
        var RemoveAmount = 0;
        var AmountList = this.AssetsList.find((obj: any) => {
          return obj.AssetId == this.ID;
        });
        if (AmountList) RemoveAmount = AmountList.ValuationAmount;
        this.val_sum =
          Number(this.ValutionAmount) +
          Number(this.val_sum) -
          Number(RemoveAmount);
        let LTV =
          this.ValutionAmount == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      } else if (this.propertyForm == "Vehicle") {
        saveData = {
          Vehicle: this.dataSharingService.cleanObject({
            VehicleManufacture: parseInt(this.VehicleManufacture),
            VehicleCategory: parseInt(this.SelectVehicleCategory),
            VehicleModelName: parseInt(this.VehicleModel),
            Variant: this.Variant,
            FuelType: this.SelectFuelType,
            Color: this.Color,
            VehicleRegistationNo:
              this.VehicleRegistationNo == null
                ? ""
                : this.VehicleRegistationNo.toUpperCase(),
            RegistationDate: this.RegistationDate,
            RegistationExpiryDate: this.RegistationExpiryDate,
            EngineNo: this.EngineNo,
            ChassisNo: this.ChassisNo,
            KeyNo: this.KeyNo,

            DateofEndors: this.PermitIssuedDate,
            MefgYear: this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear(),
            Category: "",
            VehicleCondition: "",
            DONo: this.DeliveryorderNo,
            DODoDate: this.DeliveryorderDate,
            EstimationAmount: this.EstimationAmount,
            InvoiceNo: this.InvoiceNo,
            InvoiceDate: this.InvoiceDate,
            InvoiceValue: this.InvoiceValue,
            DealerContactPerson: this.DealerContactPerson,
            DealerContactNo: this.DealerContactNo,
            DOIssuedinfavourof: this.IssuedInFavourOf,
            DealerId: parseInt(this.Dealer),
            Remark: this.Remark,
            ValuationDoneBy: this.ValuationDoneBy,
            ValuationDate: this.ValuationDate,
            ValuationAmount: this.ValutionAmount,
            ValuationType: this.ValuationType,
            OtherValuationType: this.OtherValuationType,
            OtherValuationAmt: this.OtherValuationAmount,
            FitnessAuthority: "",
            FitnessUpto: this.FitnessUpto,
            PermitAuthority: "",
            PermitUpto: this.PermitUpto,
            PermitType: "",
            ExtraInfo: "",
            Collateral_Type: Collateral_Type,
            LoanId: this.loanSummary.LoanId,
            CreatedBy: localUser.userId,
            RoadTaxUpto: this.RoadTaxUpto,
            VehicleCost: this.VehicleCost,
            Route: this.Route,
            vehicle_type: this.vehicle_type,
            Vehicle_Subtype: this.commercialVehicle,
            InvoiceHPNEndorsment: this.Invoice_HPNEndorsment == true ? 1 : 0,
            RCHPNEndorsment: this.RC_HPNEndorsment == true ? 1 : 0,
          }),
          data: {
            LoanId: this.loanSummary.LoanId,
            AssetType: "Vehicle",
            AssetId: this.ID,
            CreatedBy: localUser.userId,
          },
          Attachment: this.AssetsAttachment,
        };
        console.log("this.AssetsList", this.AssetsList);
        var RemoveAmount = 0;
        var AmountList = this.AssetsList.find((obj: any) => {
          return obj.AssetId == this.ID;
        });
        if (AmountList) RemoveAmount = AmountList.ValuationAmount;
        this.val_sum =
          Number(this.VehicleCost) +
          Number(this.val_sum) -
          Number(RemoveAmount);
        let LTV =
          this.VehicleCost == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
        //if (saveData.Vehicle.RegistationDate) {
        //  saveData.Vehicle.RegistationDate = moment(
        //    this.RegistationDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.RegistationDate = null;
        //}

        //if (saveData.Vehicle.RegistationExpiryDate) {
        //  saveData.Vehicle.RegistationExpiryDate = moment(
        //    this.RegistationExpiryDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.RegistationExpiryDate = null;
        //}

        //if (saveData.Vehicle.DateofEndors) {
        //  saveData.Vehicle.DateofEndors = moment(
        //    this.RegistationExpiryDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.DateofEndors = null;
        //}

        if (saveData.Vehicle.MefgYear) {
          saveData.Vehicle.MefgYear = this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear();
        } else {
          saveData.Vehicle.MefgYear = null;
        }

        //if (saveData.Vehicle.DODoDate) {
        //  saveData.Vehicle.DODoDate = moment(this.DeliveryorderDate).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.DODoDate = null;
        //}

        //if (saveData.Vehicle.FitnessUpto) {
        //  saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.FitnessUpto = null;
        //}
        //if (saveData.Vehicle.PermitUpto) {
        //  saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.PermitUpto = null;
        //}
      }

      if (this.ConsumerGoldForm == "ConsumerGoldForm") {
        saveData = this.dataSharingService.cleanObject(
          this.save_GoldAsset(Collateral_Type)
        );
        var RemoveAmount = 0;
        var AmountList = this.AssetsList.find((obj: any) => {
          return obj.AssetId == this.ID;
        });
        if (AmountList) RemoveAmount = AmountList.ValuationAmount;
        this.val_sum =
          Number(this.assetModel.TotalValulationAmount) +
          Number(this.val_sum) -
          Number(RemoveAmount);
        let LTV =
          this.val_sum == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      }
      if (this.PersonalForm == "PersonalForm") {
        saveData = {
          Consumer_Durable: this.dataSharingService.cleanObject({
            Category_Id: this.Category_Id,
            Manufacture_Id: this.Manufacture_Id,
            Product_Id: this.Product_Id,
            Model_Id: this.Model_Id,
            Varient: this.Varient,
            Identification_No1: this.Identification_No1,
            Identification_No2: this.Identification_No2,
            Cost: this.Cost,
            Color: this.ColorName,
            Invoice_No: this.Invoice_No,
            Invoice_Date: this.Invoice_Date,
            Dealer_Id: this.Dealer_Id,
            DealerContactPerson: this.DealerPerson,
            DealerContactNo: this.DealerPhoneNo,
            Collateral_Type: Collateral_Type,
          }),
          data: {
            LoanId: this.loanSummary.LoanId,
            AssetType: "Consumer_Durable",
            AssetId: this.ID,
            CreatedBy: localUser.userId,
          },
          Attachment: this.AssetsAttachment,
        };

        let LTV =
          this.Cost == 0
            ? 100
            : (this.loanSummary.LoanAmount / (this.Cost == 0 ? 1 : this.Cost)) *
            100;
        updateAssets = { Asset_Cost: this.Cost, LTVAmount: LTV };
      }
      // if vehicle detail adding in Primary Collateral
      // if (this.VehicleManufacture || this.SelectVehicleCategory || this.commercialVehicle == 'Commercial' || this.commercialVehicle == 'Non-Commercial') {

      // } else {

      // }
    }

    if (
      Collateral_Type == "Additional Collateral" ||
      Collateral_Type == "Additional"
    ) {
      // if Property detail adding in Primary Collateral
      // propertyForms
      if (
        this.propertyForms == "Propertys" ||
        this.AddressOfProperty ||
        this.TypeOfProperty
      ) {
        if (this.ValuationDocData) {
          this.uploadDoc({
            ApplicationNo: this.loanSummary.ApplicationNo,
            DocName: this.ValuationRemarkDoc,
            DocData: this.ValuationDocData,
          });
        }
        if (this.SearchDocData) {
          this.uploadDoc({
            ApplicationNo: this.loanSummary.ApplicationNo,
            DocName: this.SearchReportRemarkDoc,
            DocData: this.SearchDocData,
          });
        }
        let GeoLocation = this.latitude + "," + this.longitude;
        saveData = {
          Property: this.dataSharingService.cleanObject({
            CustomerId: this.PropertyOwnerName,
            TypeofProperty: this.TypeOfProperty,
            NatureofProperty: this.NatureOfProperty,
            OwnershipType: this.OwnershipType,
            OwnershipDocument: this.OwnershipDocument,
            UnitofMeasurement: this.UnitOfMeasurement,
            Address: this.AddressOfProperty,
            TotalArea: this.TotalArea,
            ConstructedArea: this.ConstructedArea,
            TypeofMortgage: this.TypeOfMortgage,
            MortgageSingedBy: this.MortgageSingedBy,
            ValuationDoneBy: parseInt(this.ValuationDoneBy),
            ValuationAmount: this.ValutionAmount,
            GeoLocation: GeoLocation,
            LegalReportBy: localUser.userId,
            ValuationRemark: this.ValuationRemark,
            ValuationRemarkDoc: this.ValuationRemarkDoc,
            SearchReportRemark: this.SearchReportRemark,
            SearchReportRemarkDoc: this.SearchReportRemarkDoc,
            Collateral_Type: Collateral_Type,
            LoanId: this.loanSummary.LoanId,
            CreatedBy: localUser.userId,
            state: this.customerModel.Firm_RegState,
            District: this.customerModel.Firm_RegDistrict,
            tasil: this.customerModel.Firm_RegTehsil,
            PinCode: this.Pincode,
            ValuationDoneByOther: this.ValuationDoneByOther,
            SearchReportDoneByOther: this.SearchReportDoneByOther,
            SearchReportDoneBy: this.SearchReportDoneBy,
          }),
          data: {
            LoanId: this.loanSummary.LoanId,
            AssetType: "Property",
            AssetId: this.ID,
            CreatedBy: localUser.userId,
          },
          Attachment: this.AssetsAttachment,
        };
      } else if (this.propertyForms == "Vehicles") {
        // if Vehicle detail adding in Primary Collateral
        saveData = {
          Vehicle: this.dataSharingService.cleanObject({
            VehicleManufacture: parseInt(this.VehicleManufacture),
            VehicleCategory: parseInt(this.SelectVehicleCategory),
            VehicleModelName: parseInt(this.VehicleModel),
            Variant: this.Variant,
            FuelType: this.SelectFuelType,
            Color: this.Color,
            VehicleRegistationNo:
              this.VehicleRegistationNo == null
                ? ""
                : this.VehicleRegistationNo.toUpperCase(),
            RegistationDate: this.RegistationDate,
            RegistationExpiryDate: this.RegistationExpiryDate,
            EngineNo: this.EngineNo,
            ChassisNo: this.ChassisNo,
            KeyNo: this.KeyNo,
            DateofEndors: this.PermitIssuedDate,
            MefgYear: this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear(),
            Category: "",
            VehicleCondition: "",
            DONo: this.DeliveryorderNo,
            DODoDate: this.DeliveryorderDate,
            EstimationAmount: this.EstimationAmount,
            InvoiceNo: this.InvoiceNo,
            InvoiceDate: this.InvoiceDate,
            InvoiceValue: this.InvoiceValue,
            DealerContactPerson: this.DealerContactPerson,
            DealerContactNo: this.DealerContactNo,
            DOIssuedinfavourof: this.IssuedInFavourOf,
            DealerId: parseInt(this.Dealer),
            Remark: this.Remark,
            ValuationDoneBy: this.ValuationDoneBy,
            ValuationDate: this.ValuationDate,
            ValuationAmount: this.ValutionAmount,
            ValuationType: this.ValuationType,
            OtherValuationType: this.OtherValuationType,
            OtherValuationAmt: this.OtherValuationAmount,
            FitnessAuthority: "",
            FitnessUpto: this.FitnessUpto,
            PermitAuthority: "",
            PermitUpto: this.PermitUpto,
            PermitType: "",
            ExtraInfo: "",
            Collateral_Type: Collateral_Type,
            LoanId: this.loanSummary.LoanId,
            CreatedBy: localUser.userId,
            RoadTaxUpto: this.RoadTaxUpto,
            VehicleCost: this.VehicleCost,
            Route: this.Route,
            vehicle_type: this.vehicle_type,
            Vehicle_Subtype: this.commercialVehicle,
            InvoiceHPNEndorsment: this.Invoice_HPNEndorsment == true ? 1 : 0,
            RCHPNEndorsment: this.RC_HPNEndorsment == true ? 1 : 0,
          }),
          data: {
            LoanId: this.loanSummary.LoanId,
            AssetType: "Vehicle",
            AssetId: this.ID,
            CreatedBy: localUser.userId,
          },
          Attachment: this.AssetsAttachment,
        };

        //if (saveData.Vehicle.RegistationDate) {
        //  saveData.Vehicle.RegistationDate = moment(
        //    this.RegistationDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.RegistationDate = null;
        //}

        //if (saveData.Vehicle.RegistationExpiryDate) {
        //  saveData.Vehicle.RegistationExpiryDate = moment(
        //    this.RegistationExpiryDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.RegistationExpiryDate = null;
        //}

        //if (saveData.Vehicle.DateofEndors) {
        //  saveData.Vehicle.DateofEndors = moment(
        //    this.RegistationExpiryDate
        //  ).format("YYYY-MM-DD");
        //} else {
        //  saveData.Vehicle.DateofEndors = null;
        //}

        if (saveData.Vehicle.MefgYear) {
          saveData.Vehicle.MefgYear = this.ManufactureYear.getFullYear() == undefined ? this.ManufactureYear : this.ManufactureYear.getFullYear();
        } else {
          saveData.Vehicle.MefgYear = null;
        }

        //if (saveData.Vehicle.DODoDate) {
        //  saveData.Vehicle.DODoDate = moment(this.DeliveryorderDate).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.DODoDate = null;
        //}

        //if (saveData.Vehicle.FitnessUpto) {
        //  saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.FitnessUpto = null;
        //}
        //if (saveData.Vehicle.PermitUpto) {
        //  saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.PermitUpto = null;
        //}//if (saveData.Vehicle.DODoDate) {
        //  saveData.Vehicle.DODoDate = moment(this.DeliveryorderDate).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.DODoDate = null;
        //}

        //if (saveData.Vehicle.FitnessUpto) {
        //  saveData.Vehicle.FitnessUpto = moment(this.FitnessUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.FitnessUpto = null;
        //}
        //if (saveData.Vehicle.PermitUpto) {
        //  saveData.Vehicle.PermitUpto = moment(this.PermitUpto).format(
        //    "YYYY-MM-DD"
        //  );
        //} else {
        //  saveData.Vehicle.PermitUpto = null;
        //}
      }
      if (this.ConsumerGoldForms == "ConsumerGoldForms") {
        saveData = this.dataSharingService.cleanObject(
          this.save_GoldAsset(Collateral_Type)
        );
      }
    }
    console.log("saveData for property details of assets", saveData);

    this.LmsService.SaveUserAssetInfo({
      JSON: JSON.stringify(saveData),
    }).subscribe((response) => {
      this.onCloseKYC();
      this.LMS_GetAllUserAssetInfo();
      this.AssetsAttachment = [{}];
      this.DocModel = [];
      this.VehicleManufacture = null;
      this.SelectVehicleCategory = null;
      this.AddressOfProperty = null;
      this.TypeOfProperty = null;
      this.propertyForms == "";
      this.propertyForm = "";
      this.resetForm();
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: response[0].MSG,
          ...this.configSuccess,
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: response[0].MSG,
          ...this.configSuccess,
        });
      }
      if (updateAssets != null && updateAssets != undefined) {
        this.LmsService.Update_AssetsCost({
          Loan_Id: this.loanSummary.LoanId,
          AssetsCost: updateAssets.Asset_Cost,
          LTV: updateAssets.LTVAmount,
        }).subscribe((response) => { });
      }
    });
  }

  save_GoldAsset(Collateral_Type) {
    if (this.ItemArr.length <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Add atleast 1 item.",
        ...this.configSuccess,
      });
      return;
    }
    this.assetModel.Collateral = "Gold";
    this.assetModel.Collateral_Type = Collateral_Type;
    this.assetModel.LoginUserId = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).userId;
    let _data: any = {};
    _data.GOLD = { ...this.assetModel, TotalItem_Qty: this.ItemArr.length };
    _data.GOLD_ITEM = this.ItemArr;
    delete _data.GOLD.Item;
    delete _data.GOLD.ItemArr;
    _data.GOLD_ITEM.forEach((obj: any) => {
      if (obj.Image1Data) {
        this.uploadDoc({
          ApplicationNo: this.loanSummary.ApplicationNo,
          DocName: obj.Image1,
          DocData: obj.Image1Data,
        });
      }
      if (obj.Image2Data) {
        this.uploadDoc({
          ApplicationNo: this.loanSummary.ApplicationNo,
          DocName: obj.Image2,
          DocData: obj.Image2Data,
        });
      }
    });
    _data.data = {
      LoanId: this.loanSummary.LoanId,
      AssetType: "Gold",
      AssetId: this.ID,
    };
    //this.losService.LOS_SaveAssetsGOLD({ JSON: JSON.stringify(_data), AssetId: 0 }).subscribe((res: any) => {
    //  this.loading = false;
    //  if (res[0].CODE >= 0) {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //    this.LMS_GetAllUserAssetInfo(this.LoanAcNo);
    //    this.VehicleManufacture = null; this.SelectVehicleCategory = null;
    //    this.AddressOfProperty = null; this.TypeOfProperty = null;
    //    this.propertyForms == ''; this.propertyForm = ''; this.ConsumerGoldForms = '';
    //    this.ConsumerGoldForm='';
    //    this.resetForm();
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //  }
    //});
    return _data;
  }
  ViewData(row) {
    this.LmsService.ViewAssetInfo({
      SearchBy: row.Collateral,
      Id: row.AssetId,
    }).subscribe((response: any) => {
      console.log("response :", response);
      this.Collateral = row.Collateral;
      this.Collateral_Type = row.Collateral_Type;
      this.Info = response.Item1[0];
      console.log("View data api call---View Info", this, this.Info);
      this.AssetsAttachment = response.Item2;
      this.RC_HPNEndorsment = response.Item1[0].RC_HPNEndorsment;
      this.Invoice_HPNEndorsment = response.Item1[0].Invoice_HPNEndorsment;

      if (this.Collateral == "Gold") {
        this.dataviewAssetItemSource = new MatTableDataSource(response.Item3);
        this.dataAssetItemSource = new MatTableDataSource(response.Item3);
        this.ItemArr = response.Item3;
      }
      if (this.Info.VehicleRegistationNo) {
        this.VehicleRegistationNo = this.Info.VehicleRegistationNo;
        this.Get_Alredy_Verified_KYC();
      }
      $("#View").modal("show");
      $("#View").css("z-index", "1050");

    });
  }
  onCloseView() {
    $("#View").modal("hide");
  }
  GetConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      //this.ConstArea.nativeElement.focus();
      this.ConstructedArea = 0;
      return;
    }
  }
  GetAdtConstructedArea($event) {
    if (this.ConstructedArea > this.TotalArea) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Constructed area must be less than or equal to Total Area.",
        ...this.configSuccess,
      });
      this.ConstAddArea.nativeElement.focus();
      this.ConstAddArea.nativeElement.value = 0;
      return;
    }
  }
  editAssetItem(data: any, index: any) {
    this.Item = data;
    this.ItemArr.selectedIndex = index;
    

  }
  deleteAssetItem(index: any) {
    this.ItemArr.splice(index, 1);
    this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
    if (this.ItemArr.selectedIndex == index) {
      delete this.ItemArr.selectedIndex;
    }
    this.getAssetTotal();
  }
  getDataConsumerDurableLoan() {
    this.saveBtn = false;
    this.updateBtn = false;
    this.ID = this.Info.Id;
    this.onChangeCategory(this.Info.Category_Id);
    this.getModelName(this.Info.Product_Id);
    this.getProductName(this.Info.Category_Id, this.Info.Manufacture_Id);
    this.Get_Master_Consumer_Durable_Category();
    this.Category_Id = this.Info.Category_Id;
    this.Manufacture_Id = this.Info.Manufacture_Id;
    this.Product_Id = this.Info.Product_Id;
    this.Model_Id = this.Info.Model_Id;
    this.Varient = this.Info.Varient;
    this.Identification_No1 = this.Info.Identification_No1;
    this.Identification_No2 = this.Info.Identification_No2;
    this.Cost = this.Info.Cost;
    this.ColorName = this.Info.Color;
    this.Invoice_No = this.Info.Invoice_No;
    this.Invoice_Date = new Date(this.Info.Invoice_Date);
    this.Dealer_Id = this.Info.Dealer_Id;
    this.DealerPerson = this.Info.DealerContactPerson;
    this.DealerPhoneNo = this.Info.DealerContactNo;
  }
  getDataProperty() {
    this.saveBtn = false;
    this.updateBtn = true;
    this.ID = this.Info.Id;
    this.PropertyOwnerName = this.Info.PropertyOwnerID;
    this.AddressOfProperty = this.Info.Address;
    this.Location = this.Info.GeoLocation;
    this.latitude = this.Info.GeoLocation.split(",")[0];
    this.longitude = this.Info.GeoLocation.split(",")[1];
    this.TypeOfProperty = this.Info.TypeofPropertyID;
    this.NatureOfProperty = this.Info.NatureofPropertyID;
    this.OwnershipDocument = this.Info.OwnershipDocumentID;
    this.OwnershipType = this.Info.OwnershipTypeID;
    this.UnitOfMeasurement = this.Info.UnitofMeasurement;
    this.TotalArea = this.Info.TotalArea;
    this.ConstructedArea = this.Info.ConstructedArea;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationDoneByOther = this.Info.ValuationDoneByOther;
    this.ValuationRemark = this.Info.ValuationRemark;
    this.SearchReportDoneByOther = this.Info.SearchReportDoneByOther;
    this.SearchReportRemark = this.Info.SearchReportRemark;
    this.ValuationRemarkDoc = this.Info.ValuationRemarkDoc;
    this.SearchReportRemarkDoc = this.Info.SearchReportRemarkDoc;
    this.SearchReportDoneBy = this.Info.SearchReportDoneByID;
    this.TypeOfMortgage = this.Info.TypeofMortgage;
    this.MortgageSingedBy = this.Info.MortgageSingedByID;
    this.customerModel.Firm_RegState = this.Info.StateID;
    this.customerModel.Firm_RegDistrict = this.Info.DistrictID;
    this.customerModel.Firm_RegTehsil = this.Info.tasilID;
    this.Pincode = this.Info.PinCode;
    this.GetDistrickDropdownReg(this.customerModel.Firm_RegState);
    this.GetTehasilDropdownReg(this.customerModel.Firm_RegDistrict);
  }

  getDataVehicle() {
    this.saveBtn = false;
    this.updateBtn = true;
    this.ID = this.Info.Id;

    this.VehicleManufacture = this.Info.VehicleManufactureID;
    this.SelectVehicleCategory = this.Info.VehicleCategoryID;
    this.VehicleModel = this.Info.VehicleModelNameID;
    this.LMS_GetVehicleModelMaster(event);

    this.Variant = this.Info.Variant;
    this.ManufactureYear = this.Info.MefgYear == null ? "" : new Date(this.Info.MefgYear + '-01-01');
    this.VehicleRegistationNo = this.Info.VehicleRegistationNo;
    this.RegistationDate =
      this.Info.RegistationDate == null
        ? ""
        : new Date(this.Info.RegistationDate);
    this.RegistationExpiryDate =
      this.Info.RegistationExpiryDate == null
        ? ""
        : new Date(this.Info.RegistationExpiryDate);
    this.SelectFuelType = this.Info.FuelTypeID;

    // if (this.Info.TypeofProperty == 'Commercial') {
    this.RoadTaxUpto =
      this.Info.RoadTaxUpto == null ? "" : new Date(this.Info.RoadTaxUpto);
    this.Color = this.Info.Color;
    this.FitnessUpto =
      this.Info.FitnessUpto == null ? "" : new Date(this.Info.FitnessUpto);
    this.PermitUpto =
      this.Info.PermitUpto == null ? "" : new Date(this.Info.PermitUpto);
    this.VehicleCost = this.Info.VehicleCost;
    this.Route = this.Info.Rout;
    this.EngineNo = this.Info.EngineNo;
    this.ChassisNo = this.Info.ChassisNo;
    this.KeyNo = this.Info.KeyNo;
    // }EngineNo

    this.DeliveryorderNo = this.Info.DONo;
    this.DeliveryorderDate =
      this.Info.DODoDate == null ? "" : new Date(this.Info.DODoDate);
    this.InvoiceNo = this.Info.InvoiceNo;
    this.InvoiceValue = this.Info.InvoiceValue;
    this.DealerContactPerson = this.Info.DealerContactPerson;
    this.DealerContactNo = this.Info.DealerContactNo;
    this.IssuedInFavourOf = this.Info.DOIssuedinfavourofID;
    this.Remark = this.Info.Remark;
    this.Dealer = this.Info.DealerId;
    this.EstimationAmount = this.Info.EstimationAmount;
    this.InvoiveDate =
      this.Info.InvoiceDate == null ? "" : new Date(this.Info.InvoiceDate);
    this.InvoiceDate =
      this.Info.InvoiceDate == null ? "" : new Date(this.Info.InvoiceDate);
    this.ValuationDoneBy = this.Info.ValuationDoneByID;
    this.ValuationType = this.Info.ValuationType;
    this.ValutionAmount = this.Info.ValuationAmount;
    this.OtherValuationAmount = this.Info.OtherValuationAmt;
    this.OtherValuationType = this.Info.OtherValuationType;
    this.ValuationDate =
      this.Info.ValuationDate == null ? "" : new Date(this.Info.ValuationDate);
    if (this.Info.Vehicle_Type == "New") {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = "New";
    }
    if (this.Info.Vehicle_Type == "Used") {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = "Used";
    }
    if (this.Info.Vehicle_SubType == "Commercial") {
      this.commercialVehicle = "Commercial";
    }

    if (this.Info.Vehicle_SubType == "Non-Commercial") {
      this.commercialVehicle = "Non-Commercial";
    }
  }
  getDataGold() {
    this.saveBtn = false;
    this.updateBtn = true;
    this.ID = this.Info.Id;
    this.assetModel.Collateral = "Gold";
    this.assetModel.Collateral_Type = this.Info.Collateral_Type;
    this.assetModel.LoginUserId = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).userId;
    this.assetModel.ValulationDoneBy = this.Info.ValulationDoneByID;
    this.assetModel.ValulationDate =
      this.Info.ValulationDate == null
        ? ""
        : new Date(this.Info.ValulationDate);
    this.assetModel.TotalValulationAmount = this.Info.TotalValulationAmount;
    this.assetModel.ValuerPhoneNo = this.Info.ValuerPhoneNo;
    this.assetModel.ValuerFirmName = this.Info.ValuerFirmName;

    this.getAssetTotal();
  }
  editAssetInfo(Id: any) {
    this.isEdit = false;
    this.isUpdate = true;
    this.saveBtn = true;
    this.RCVerification();
    $("#View").modal("hide");
    this.AssetsAttachment.push({});
    if (
      this.Info.Collateral_Type == "Primary Collateral" ||
      this.Info.Collateral_Type == "Primary"
    ) {
      //var value = this.loanSummary.ProductId;
      if (this.loanSummary.Product_Category == "Property Loan") {
        this.AssetsForm = "Form";
        this.propertyForm = "Property";
        this.ConsumerGoldForm = "";
        this.PersonalForm = "";
      }
      if (this.loanSummary.Product_Category == "Vehicle Loan") {
        this.AssetsForm = "Form";
        this.propertyForm = "Vehicle";
        this.ConsumerGoldForm = "";
        this.PersonalForm = "";
      }
      if (this.loanSummary.Product_Category == "Gold Loan") {
        this.AssetsForm = "Form";
        this.ConsumerGoldForm = "ConsumerGoldForm";
        this.propertyForm = "";
      }
      if (this.loanSummary.Product_Category == "Consumer Durable Loan") {
        this.AssetsForm = "Form";
        this.ConsumerGoldForm = "";
        this.propertyForm = "";
        this.PersonalForm = "PersonalForm";
      }
      if (this.Info.Collateral == "Consumer_Durable") {
        this.PersonalForm = "PersonalForm";

        this.getDataConsumerDurableLoan();
        $("#kycModel").modal("show");
        $("#kycModel").css("z-index", "1050");
      }
      if (this.Info.Collateral == "Property") {
        this.propertyForm == "Property";
        $("#kycModel").modal("show");
        $("#kycModel").css("z-index", "1050");

        this.getDataProperty();
      }

      if (this.Info.Collateral == "Vehicle") {
        this.propertyForm == "Vehicle";

        $("#kycModel").modal("show");
        $("#kycModel").css("z-index", "1050");
        this.getDataVehicle();
      }
      if (this.Info.Collateral == "Gold") {
        this.ConsumerGoldForm == "ConsumerGoldForm";

        $("#kycModel").modal("show");
        $("#kycModel").css("z-index", "1050");
        this.getDataGold();
      }
    }

    if (
      this.Info.Collateral_Type == "Additional Collateral" ||
      this.Info.Collateral_Type == "Additional"
    ) {
      this.Addi_ProductCat = true;
      this.additional_collateral_category(this.Info.Collateral + " Loan");
      if (this.Info.Collateral == "Property") {
        this.propertyForms == "Propertys";
        $("#additionalcollateral").modal("show");
        $("#additionalcollateral").css("z-index", "1050");
        this.getDataProperty();
      }

      if (this.Info.Collateral == "Vehicle") {
        //this.additional_collateral_category('5-0');
        this.propertyForms == "Vehicles";
        $("#additionalcollateral").modal("show");
        $("#additionalcollateral").css("z-index", "1050");
        this.getDataVehicle();
      }
      if (this.Info.Collateral == "Gold") {
        this.ConsumerGoldForms == "ConsumerGoldForms";
        //this.additional_collateral_category('9-0');
        $("#additionalcollateral").modal("show");
        $("#additionalcollateral").css("z-index", "1050");
        this.getDataGold();
      }
    }
  }

  next() {
    let route = "";
    if (
      this.loanSummary.Product_Category == "Un-Secured Loan" ||
      (this.dataSource != undefined &&
        this.dataSource.filteredData.length != 0 &&
        (this.dataSource.filteredData.some(
          (item) => item.Collateral_Type == "Primary Collateral"
        ) ||
          this.dataSource.filteredData.some(
            (item) => item.Collateral_Type == "Primary"
          )))
    ) {
      this.LmsService.SaveNext_Loan({
        Loan_Id: this.loanSummary.LoanId,
        CommandName: "Asset",
        isStatus: 1,
      }).subscribe((response) => {
        // $("#AssetInfo").removeClass("btn btn-orange btn-sm");
        // $("#AssetInfo").addClass('btn btn-success btn-sm');
        //this.dataSharingService.getLmsHeaderCRC().subscribe(value => {
        //  if (value == true) {
        //    $("#CrcInfo").removeClass("tab");
        //    route = '/lms/lms-crc-list';
        //  }
        //  else {
        //    this.dataSharingService.getLmsHeaderFITVR().subscribe(value => {
        //      if (value == true) {
        //        $("#FiTvrInfo").removeClass("tab");
        //        route = '/lms/lms-fi-tvr';
        //      }
        //      else {
        //        $("#ChequeDetailInfo").removeClass("tab");
        //        route = '/lms/Cheque-Details';
        //      }
        //    });
        //  }
        //});
      });
      // if (this.CRCTab == true) {
      //   $("#CrcInfo").removeClass("tab");
      //   route = '/lms/lms-crc-list';
      //   let element: HTMLElement = document.getElementById("CrcInfo") as HTMLElement;
      //   element.click();
      // }
      // if (this.FITVRTab == true) {
      //   $("#FiTvrInfo").removeClass("tab");
      //   route = '/lms/lms-fi-tvr';
      //   let element: HTMLElement = document.getElementById("FiTvrInfo") as HTMLElement;
      //   element.click();
      // }
      // if (this.ChequeDetailTab == true) {
      //   $("#ChequeDetailInfo").removeClass("tab");
      //   route = '/lms/Cheque-Details';
      //   let element: HTMLElement = document.getElementById("ChequeDetailInfo") as HTMLElement;
      //   element.click();
      // }
      // if (this.type == 'L' || this.type == 'A') {
      //   if (this.type == 'L') {
      //     this._Route.navigate([
      //       `${route}/${encodeURIComponent(this.LoanId)}/${this.LoanAcNo}/${"L"}`,
      //     ]);
      //   } else {
      //     this._Route.navigate([
      //       `${route}/${encodeURIComponent(this.LoanId)}/${this.LoanAcNo}/${"A"}`,
      //     ]);
      //   }
      // } else {

      //   //this._Route.navigateByUrl(route);
      // }
      this.action.emit("next");
    }
  }

  updateAssetInfo() {
    // this.isUpdate = false;
    // this.isEdit = true;
    // this.AssetForm = 'Form';
    // this.AssetTableView = true;

    let saveData = {
      Int_Id: 1,
      VehicleManufacture: parseInt(this.VehicleManufacture),
      VehicleCategory: this.vehicleCategoryId,
      VehicleModel: parseInt(this.VehicleModel),
      Variant: this.Variant,
      ManufactureYear: this.ManufactureYear,
      VehicleRegistationNo:
        this.VehicleRegistationNo == null
          ? ""
          : this.VehicleRegistationNo.toUpperCase(),
      RegistationDate: moment(this.RegistationDate).format("DD-MM-YYYY"),
      RegistationExpiryDate: moment(this.RegistationExpiryDate).format(
        "DD-MM-YYYY HH:mm"
      ),
      FuelType: this.SelectFuelType,
      RoadTaxUpto: moment(this.RoadTaxUpto).format("DD-MM-YYYY HH:mm"),
      Color: this.Color,
      FitnessUpto: this.FitnessUpto,
      Dealer: this.Dealer,
      PermitUpto: moment(this.PermitUpto).format("DD-MM-YYYY HH:mm"),
      VehicleCost: this.VehicleCost,
      Route: this.Route,
      EngineNo: this.EngineNo,
      ChassisNo: this.ChassisNo,
      KeyNo: this.KeyNo,
      DeliveryOrderNo: this.DeliveryorderNo,
      DeliveryOrderDate: this.DeliveryorderDate,
      DoIssuedFavourOf: this.DoIssuedFavour,
      DealerContactPerson: this.DealerContact,
      LtvBase: this.LTVBase,
      ValuationGridIDV: this.ValuationGrid,
      ValuationDoneBy: this.ValuationDoneBy,
      GridValue: this.GridValue,
      PermitNo: this.PermitNo,
      PermitIssuedDate: this.PermitIssuedDate,
      PermitExpiryDate: this.PermitExpireDate,
      PermitNature: this.PermitNature,
      RouteDescription: this.RouteDescription,
      IsActive: 0,

      PropertyOwnerName: this.PropertyOwnerName,
      AddressOfProperty: this.AddressOfProperty,
      Location: this.Location,
      TypeOfProperty: this.TypeOfProperty,
      NatureOfProperty: this.NatureOfProperty,
      OwnershipDocument: this.OwnershipDocument,
      OwnershipType: this.OwnershipType,
      UnitOfMeasurement: this.UnitOfMeasurement,
      TotalArea: this.TotalArea,
      ConstructedArea: this.ConstructedArea,
      ValutionAmount: this.ValutionAmount,
      SearchReportDoneBy: this.SearchReportDoneBy,
      TypeOfMortgage: this.TypeOfMortgage,
      MortgageSingedBy: this.MortgageSingedBy,

      JSON: "",
    };

    if (saveData.DeliveryOrderDate) {
      saveData.DeliveryOrderDate = moment(this.DeliveryorderDate).format(
        "DD-MM-YYYY HH:mm"
      );
    } else {
      saveData.DeliveryOrderDate = "";
    }

    if (saveData.FitnessUpto) {
      saveData.FitnessUpto = moment(this.FitnessUpto).format(
        "DD-MM-YYYY HH:mm"
      );
    } else {
      saveData.FitnessUpto = "";
    }

    if (saveData.PermitIssuedDate) {
      saveData.PermitIssuedDate = moment(this.PermitIssuedDate).format(
        "DD-MM-YYYY HH:mm"
      );
    } else {
      saveData.PermitIssuedDate = "";
    }
    if (saveData.PermitExpiryDate) {
      saveData.PermitExpiryDate = moment(this.PermitExpireDate).format(
        "DD-MM-YYYY HH:mm"
      );
    } else {
      saveData.PermitExpiryDate = "";
    }

    let localUser = JSON.parse(sessionStorage.getItem("currentUser"));
    let Data = {
      AssetInfo: {
        LoanId: this.loanSummary.LoanId,
        ModifyBy: localUser.userId,
        UpdatedAt: moment(new Date()).format("DD-MM-YYYY HH:mm"),
      },
    };

    let asset = JSON.stringify(Data);
    saveData.JSON = asset;

    if (this.propertyForm == "Property") {
      saveData.VehicleManufacture = 0;
      saveData.VehicleModel = 0;
      saveData.FitnessUpto = "";
      saveData.ManufactureYear = "";
      saveData.PermitExpiryDate = "";
      saveData.PermitIssuedDate = "";
      saveData.PermitUpto = "";
      saveData.RoadTaxUpto = "";
      saveData.RegistationDate = "";
      saveData.RegistationExpiryDate = "";
      saveData.Dealer = 0;
    } else {
    }
    let updateAssets;
    if (
      this.Info.Collateral_Type == "Primary Collateral" ||
      this.Info.Collateral_Type == "Primary"
    ) {
      if (this.loanSummary.Product_Category == "Property Loan") {
        this.val_sum = this.ValutionAmount + this.val_sum;
        let LTV =
          this.ValutionAmount == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      }
      if (this.loanSummary.Product_Category == "Vehicle Loan") {
        this.val_sum = this.VehicleCost + this.val_sum;
        let LTV =
          this.VehicleCost == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      }
      if (this.loanSummary.Product_Category == "Gold Loan") {
        this.val_sum = this.assetModel.TotalValulationAmount + this.val_sum;
        let LTV =
          this.val_sum == 0
            ? 100
            : (this.loanSummary.LoanAmount /
              (this.val_sum == 0 ? 1 : this.val_sum)) *
            100;
        updateAssets = { Asset_Cost: this.val_sum, LTVAmount: LTV };
      }
    }
    this.LmsService.SaveUserAssetInfo(saveData).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.isUpdate = false;
        this.isEdit = true;
        this.AssetTableView = true;
        this.AssetsForm = "";
        this.saveBtn = false;
        this.updateBtn = false;
        if (updateAssets != null && updateAssets != undefined) {
          this.LmsService.Update_AssetsCost({
            Loan_Id: this.loanSummary.LoanId,
            AssetsCost: updateAssets.Asset_Cost,
            LTV: updateAssets.LTVAmount,
          }).subscribe((response) => {
            this.ngOnInit();
          });
        }

        this.snackBar.openFromComponent(SnackbarComponent, {
          data: response[0].MSG,
          ...this.configSuccess,
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: response[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }

  selectVehicleType(type: string) {
    if (type == "New") {
      this.newVehicle = true;
      this.usedVehicle = false;
      this.vehicle_type = "New";
    }
    if (type == "Used") {
      this.usedVehicle = true;
      this.newVehicle = false;
      this.vehicle_type = "Used";
      this.Invoice_HPNEndorsment = false;
    }
    if (type == "Commercial") {
      this.commercialVehicle = "Commercial";
    }

    if (type == "Non-Commercial") {
      this.commercialVehicle = "Non-Commercial";
    }
    if (this.RegistationDate != "") {
      var year = this.RegistationDate.getFullYear();
      var month = this.RegistationDate.getMonth();
      var day = this.RegistationDate.getDate();
      if (this.commercialVehicle == "Commercial") {
        var c = new Date(year + 10, month, day - 1);
        this.RegistationExpiryDate = c;
      }
      if (this.commercialVehicle == "Non-Commercial") {
        var c = new Date(year + 15, month, day - 1);
        this.RegistationExpiryDate = c;
      }
    }
  }

  selectAgricultureForm(type: string) {
    if (type == "Property") {
      this.propertyForm = "Property";
    }
    if (type == "Vehicle") {
      this.propertyForm = "Vehicle";
    }
  }

  OpenModel() {
    this.resetForm();
    $("#kycModel").modal("show");
    $("#kycModel").css("z-index", "1050");

    if (this.loanSummary.Product_Category == "Property Loan") {
      this.AssetsForm = "Form";
      this.propertyForm = "Property";
      this.ConsumerGoldForm = "";
      this.PersonalForm = "";
    }
    if (this.loanSummary.Product_Category == "Vehicle Loan") {
      this.AssetsForm = "Form";
      this.propertyForm = "Vehicle";
      this.ConsumerGoldForm = "";
      this.PersonalForm = "";
    }
    if (this.loanSummary.Product_Category == "Gold Loan") {
      this.AssetsForm = "Form";
      this.ConsumerGoldForm = "ConsumerGoldForm";
      this.propertyForm = "";
    }
    //if (this.loanSummary.Product_Category == 'Consumer Durable Loan') {
    //  this.AssetsForm = 'Form';
    //  this.ConsumerGoldForm = 'ConsumerGoldForm';
    //  this.propertyForm = '';
    //}

    if (
      this.loanSummary.Product_Category == "Un-Secured Loan" ||
      this.loanSummary.Product_Category == "Consumer Durable Loan" ||
      this.loanSummary.Product_Category == "Loan Against Securities" ||
      this.loanSummary.Product_Category == "Agricultural Loan"
    ) {
      this.AssetsForm = "Form";
      this.ConsumerGoldForm = "";
      this.propertyForm = "";
      this.PersonalForm = "PersonalForm";
    }
  }

  // start form  additional collateral
  OpenModel_Additional_Collateral() {
    this.resetForm();
    $("#additionalcollateral").modal("show");
    $("#additionalcollateral").css("z-index", "1050");
  }

  // dropdown value get
  getProductDropdown() {
    let localUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this._MasterService
      .GetProductList({ Emp_Id: localUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }

  selectProducts(event: any) {
    this.additional_collateral_category(event.target.selectedOptions[0].label);
  }

  additional_collateral_category(value) {
    if (value) {
      //this.vehicleCategoryIds = value.split('-')[0];

      let productCat = value.trim();
      this.ProductCatId = this.ProductCategoryDropdown.filter(
        (x) => x.Product_Category == value
      )[0].ProductCatId;
      if (productCat == "Property Loan") {
        this.AssetsForms = "Forms";
        this.propertyForms = "Propertys";
        this.ConsumerGoldForms = "";
        this.PersonalForms = "";
      }
      if (productCat == "Vehicle Loan") {
        this.AssetsForms = "Forms";
        this.propertyForms = "Vehicles";
        this.ConsumerGoldForms = "";
        this.PersonalForms = "";
      }
      if (productCat == "Gold Loan") {
        this.AssetsForms == "Forms";
        this.ConsumerGoldForms = "ConsumerGoldForms";
        this.propertyForms = "";
      }
      if (
        productCat == "Un-Secured Loan" ||
        productCat == "Consumer Durable Loan" ||
        productCat == "Loan Against Securities" ||
        productCat == "Agricultural Loan"
      ) {
        this.AssetsForms = "Forms";
        this.ConsumerGoldForms = "";
        this.propertyForms = "";
        this.PersonalForms = "PersonalForms";
      }

      this.customerModel.Firm_RegState = "";
      this.customerModel.Firm_RegDistrict = "";
      this.customerModel.Firm_RegTehsil = "";
    }
  }
  // end form  additional collateral

  ChangeRegistationDate(event: any) {
    if (this.RegistationDate && this.ManufactureYear) {
      //if (this.RegistationDate < this.ManufactureYear) {
      //  this.snackBar.openFromComponent(SnackbarComponent, {
      //    data: "Registation Date greater than ManufactureYear.",
      //    ...this.configSuccess,
      //  });
      //  this.RegistationDate = "";
      //}
      var year = this.RegistationDate.getFullYear();
      var month = this.RegistationDate.getMonth();
      var day = this.RegistationDate.getDate();
      if (this.commercialVehicle == "Commercial") {
        var c = new Date(year + 10, month, day - 1);
        this.RegistationExpiryDate = c;
      }
      if (this.commercialVehicle == "Non-Commercial") {
        var c = new Date(year + 15, month, day - 1);
        this.RegistationExpiryDate = c;
      }
    }
  }
  ChangeExpiryDate(event: any) {
    if (this.RegistationExpiryDate < this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Registation Expiry Date greater than Registation Date.",
        ...this.configSuccess,
      });
      this.RegistationExpiryDate = "";
    }
  }
  ChangeDeliveryorderDate(event: any) {
    if (this.RegistationDate != null && this.RegistationDate != undefined && this.RegistationDate != '' && this.DeliveryorderDate > this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Delivery Order Date less than Registation Date.",
        ...this.configSuccess,
      });
      this.DeliveryorderDate = "";
    }
    else if (this.DeliveryorderDate > this.CurrentDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Delivery Order Date less than Current Date.",
        ...this.configSuccess,
      });
      this.DeliveryorderDate = "";
    }
  }
  ChangeInvoiveDateDate(event: any) {
    if (event < this.DeliveryorderDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Invoive  Date greater than Delivery Order Date.",
        ...this.configSuccess,
      });
      this.InvoiveDate = "";
    }
    if (this.RegistationDate != '' && this.RegistationDate != null && this.RegistationDate != undefined && event > this.RegistationDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Invoive  Date less than Registation Date.",
        ...this.configSuccess,
      });
      this.InvoiveDate = "";
    }
  }
  getFuelType() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "FUEL TYPE",
    }).subscribe((res: any) => {
      this.FuelTypeOption = res;
    });
  }
  resetForm() {
    this.saveBtn = true;
    this.updateBtn = false;
    this.isEdit = false;
    this.ID = 0;
    this.VehicleManufacture = "";
    this.SelectVehicleCategory = "";
    this.VehicleModel = "";
    this.Variant = "";
    this.ManufactureYear = "";
    this.VehicleRegistationNo = "";
    this.RegistationDate = "";
    this.RegistationExpiryDate = "";
    this.SelectFuelType = "";
    this.RoadTaxUpto = "";
    this.Color = "";
    this.FitnessUpto = "";
    this.PermitUpto = "";
    this.VehicleCost = "";
    this.Route = "";
    this.EngineNo = "";
    this.ChassisNo = "";
    this.KeyNo = "";
    this.DeliveryorderNo = "";
    this.DeliveryorderDate = "";
    this.EstimationAmount = "";
    this.InvoiceNo = "";
    this.InvoiceValue = "";
    this.InvoiveDate = "";
    this.InvoiveNo = "";
    this.DealerContactPerson = "";
    this.DealerContactNo = "";
    this.IssuedInFavourOf = "";
    this.Remark = "";
    this.Dealer = "";
    this.ValuationDoneBy = "";
    this.ValutionAmount = "";
    this.ValuationGrid = "";
    this.ValuationType = "";
    this.OtherValuationType = "";
    this.OtherValuationAmount = "";
    this.PropertyOwnerName = "";
    this.AddressOfProperty = "";
    this.Location = "";
    this.customerModel = [];
    this.TypeOfProperty = "";
    this.NatureOfProperty = "";
    this.OwnershipDocument = "";
    this.OwnershipType = "";
    this.UnitOfMeasurement = "";
    this.TotalArea = "";
    this.ConstructedArea = "";
    this.SearchReportDoneBy = "";
    this.TypeOfMortgage = "";
    this.MortgageSingedBy = "";
    this.latitude = "";
    this.longitude = "";
    this.newVehicle = true;
    this.usedVehicle = false;
    this.vehicle_type = "New";
    this.commercialVehicle = "Non-Commercial";
    this.ValuationDocData = "";
    this.SearchDocData = "";
    this.SearchReportDoneByOther = "";
    this.ValuationDoneByOther = "";
    this.SearchReportDoneBy = "";
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
    this.Item.PurityCT = 12;
    this.ItemArr = [];
    this.dataAssetItemSource = "";
    this.assetModel = [];
    this.Addi_ProductCat = false;
    this.Category_Id = "";
    this.Manufacture_Id = "";
    this.Product_Id = "";
    this.Model_Id = "";
    this.Varient = "";
    this.Identification_No1 = "";
    this.Identification_No2 = "";
    this.Cost = "";
    this.ColorName = "";
    this.Invoice_No = "";
    this.Invoice_Date = new Date();
    this.Dealer_Id = "";
    this.DealerPerson = "";
    this.DealerPhoneNo = "";
    this.Pincode = "";
  }
  checkDuplication_Vehicle(Type: any) {
    this.DuplicateType = Type;
    var value = "";
    if (Type == "RegistationNo") {
      value = this.VehicleRegistationNo;
    }
    if (Type == "EngineNo") {
      value = this.EngineNo;
    }
    if (Type == "ChassisNo") {
      value = this.ChassisNo;
    }
    this.LmsService.LMS_Get_Duplication_Vehicle({
      Value: value,
      SearchType: Type,
      LoanId: this.loanSummary.LoanId,
      AssetsId: this.ID,
    }).subscribe((res: any) => {
      this.duplicateData = res;
      if (res.length > 0) {
        if (
          Type == "RegistationNo" &&
          res.find((obj) => {
            return obj.LoanStatus == "Live";
          })
        ) {
          this.VehicleRegistationNo = "";
        }
        if (
          Type == "EngineNo" &&
          res.find((obj) => {
            return obj.LoanStatus == "Live";
          })
        ) {
          this.EngineNo = "";
        }
        if (
          Type == "ChassisNo" &&
          res.find((obj) => {
            return obj.LoanStatus == "Live";
          })
        ) {
          this.ChassisNo = "";
        }
        $("#duplicateData").modal("show");
        $("#duplicateData").css("z-index", "1050");
      } else {
        this.RCVerification();
      }
    });
  }
  onCloseduplicateData() {
    $("#duplicateData").modal("hide");
  }
  getOwnershipType() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "OWNERSHIP TYPE",
    }).subscribe((res: any) => {
      this.OwnershipTypeList = res;
    });
  }
  getOwnershipDocument() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "OWNERSHIP DOCUMENT",
    }).subscribe((res: any) => {
      this.OwnershipDocumentList = res;
    });
  }
  getNatureOfProperty() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "NATURE OF PROPERTY",
    }).subscribe((res: any) => {
      this.NatureOfPropertyList = res;
    });
  }
  getTypeOfProperty() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "TYPE OF PROPERTY",
    }).subscribe((res: any) => {
      this.TypeOfPropertyList = res;
    });
  }
  imageChangeListener(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.Item.Image1 = "";
      delete this.Item.Image1Data;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.Item.Image1 = "";
      delete this.Item.Image1Data;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    $this.Item.Image1 = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.Item.Image1Data = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeImage(input: any) {
    input.value = "";
    this.Item.Image1 = "";
    this.Item.Image1Data = "";
  }
  imageChangeListener1(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.Item.Image2 = "";
      delete this.Item.Image2Data;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.Item.Image2 = "";
      delete this.Item.Image2Data;
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    $this.Item.Image2 = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.Item.Image2Data = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeImage1(input: any) {
    input.value = "";
    this.Item.Image2 = "";
    this.Item.Image2Data = "";
  }
  saveAssetItem() {
    if (!this.Item.Image1) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select Image1.",
        ...this.configSuccess,
      });
      return;
    }
    //if (!this.Item.Image2) {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please select Image2.', ...this.configSuccess });
    //  return;
    //}
    if (this.ItemArr.hasOwnProperty("selectedIndex")) {
      this.ItemArr[this.ItemArr.selectedIndex] = JSON.parse(
        JSON.stringify(this.Item)
      );
      this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
      this.Item.Image2 = "";
      this.Item.Image2Data = "";
      this.Item.Image1 = "";
      this.Item.Image1Data = "";
      this.Item = {};
      this.Item.PurityCT = 12;
      delete this.ItemArr.selectedIndex;
      this.getAssetTotal();
    } else {
      this.ItemArr.push(JSON.parse(JSON.stringify(this.Item)));
      this.dataAssetItemSource = new MatTableDataSource(this.ItemArr);
      this.Item = {};
      this.Item.Image2 = "";
      this.Item.Image2Data = "";
      this.Item.Image1 = "";
      this.Item.Image1Data = "";
      this.Item.PurityCT = 12;
      this.getAssetTotal();
    }
  }
  getAssetTotal() {
    if (this.ItemArr.length == 0) {
      this.assetTotal = undefined;
    } else {
      this.assetTotal = {
        Qty: 0,
        GrossWt: 0,
        StoneWt: 0,
        OtherWt: 0,
        NetWt: 0,
        TotalAmount: 0,
      };
      this.ItemArr.forEach((obj: any) => {
        this.assetTotal.Qty = Number(this.assetTotal.Qty) + Number(obj.Qty);
        this.assetTotal.GrossWt =
          Number(this.assetTotal.GrossWt) + Number(obj.GrossWt);
        this.assetTotal.StoneWt =
          Number(this.assetTotal.StoneWt) + Number(obj.StoneWt);
        this.assetTotal.NetWt =
          Number(this.assetTotal.NetWt) + Number(obj.NetWt);
        this.assetTotal.TotalAmount =
          Number(this.assetTotal.TotalAmount) + Number(obj.ValulationAmount);
      });
      this.assetModel.TotalValulationAmount = this.assetTotal.TotalAmount;
    }
  }
  ChangeWT() {
    if (
      Number(this.Item.GrossWt) <
      Number(this.Item.StoneWt) + Number(this.Item.OtherWt)
    ) {
      //this.Item.GrossWt = '';
      //this.Item.StoneWt = '';
      this.Item.OtherWt = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Gross Wt greater than (StoneWT+OtherWT) ",
        ...this.configSuccess,
      });
      return;
    } else {
      if (
        this.Item.GrossWt &&
        this.Item.StoneWt != undefined &&
        this.Item.OtherWt != undefined &&
        this.Item.GrossWt != undefined
      ) {
        this.Item.NetWt = (
          Number(this.Item.GrossWt) -
          (Number(this.Item.StoneWt) + Number(this.Item.OtherWt))
        ).toFixed(2);
        this.Item.PureWt = Number(
          (this.Item.NetWt * (this.Item.PurityCT * this.fixrate24ct)) / 100
        ).toFixed(2);
        //console.log("Item.PureWt2", this.Item.PureWt);
      } else {
        this.Item.NetWt = "";
      }
      if (
        this.Item.NetWt &&
        this.Item.NetWt != undefined &&
        this.Item.PurityCT &&
        this.Item.PurityCT != undefined
      ) {
        (this.Item.ValulationAmount =
          Number(this.Item.PureWt) *
          Number(this.Item.ValulationRate_PerCT)).toFixed(2);
      } else {
        this.Item.ValulationAmount = "";
      }
    }
  }

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: "You have not authorize to access this page ?",
      ...this.configSuccess,
    });
    this._Route.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  Delete_AssetInfo(data: any) {
    let localUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete this member.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.LmsService.Delete_AssetInfo({
            Id: data.AssetId,
            AssetType: data.Collateral,
            loginUserId: localUser.userId,
          }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.LMS_GetAllUserAssetInfo();
              this.PrimaryCount = 0;
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
        }
      });
  }

  Get_SearchReportDoneByForDropdown() {
    this.losService
      .Get_PartnerForDropdown({ PartnerType: "Advocate" })
      .subscribe((res: any) => {
        this.SearchReportDoneByData = res;
      });
  }
  Get_ValutionDoneByForDropdown() {
    this.losService
      .Get_PartnerForDropdown({ PartnerType: "Valuer" })
      .subscribe((res: any) => {
        this.ValuationDoneByData = res;
      });
  }
  ValuationDateChange() {
    if (this.Application_Date > new Date(this.ValuationDate)) {
      this.ValuationDate = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Valuation Date can not be prior to Application Date!!",
        ...this.configSuccess,
      });
    }
  }
  DealerChange(Dealer) {
    this.DealerContactPerson = this.DealerList.filter(
      (x) => x.PartnerId == Dealer
    )[0].Partner;
    this.DealerContactNo = this.DealerList.filter(
      (x) => x.PartnerId == Dealer
    )[0].MobileNo;

    this.DealerPerson = this.DealerList.filter(
      (x) => x.PartnerId == Dealer
    )[0].Partner;
    this.DealerPhoneNo = this.DealerList.filter(
      (x) => x.PartnerId == Dealer
    )[0].MobileNo;
  }

  Get_Master_Consumer_Durable_Category() {
    this._MasterService
      .Get_Master_Consumer_Durable_Category()
      .subscribe((response) => {
        this.categoryList = JSON.parse(JSON.stringify(response));
        // this.dataSourceCat = new MatTableDataSource(this.categoryList);
        // console.log("Cateogry List", this.categoryList);
      });
  }

  onChangeCategory(Category_Id) {
    this.manufactureList = [];

    this._MasterService
      .Get_Master_Consumer_Durable_Manufacture({
        Category_Id: Category_Id,
      })
      .subscribe((response) => {
        this.manufactureList = JSON.parse(JSON.stringify(response));
      });
  }

  getProductName(Category_Id, Manufacture_Id) {
    console.log("Manufacture Change");

    this._MasterService
      .Get_Master_Consumer_Durable_Product({
        Category_Id: Category_Id,
        Manufacture_Id: Manufacture_Id,
      })
      .subscribe((response) => {
        console.log("Get_Master_Consumer_Durable_Product", response);
        this.productList = JSON.parse(JSON.stringify(response));
        console.log();
      });
  }

  getModelName(Product_Id) {
    // if (IsBlank) {
    //   this.SelectProductModel = "";
    // }
    this._MasterService
      .Get_Master_Consumer_Durable_Model({
        ProductId: Product_Id,
      })
      .subscribe((response) => {
        console.log("Get_Master_Consumer_Durable_Model", response);
        this.modelList = JSON.parse(JSON.stringify(response));
        console.log("Model Data", this.modelList);
      });
  }
  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    const charUpper = char.toUpperCase();

    this.VehicleRegistationNo += charUpper;

    event.preventDefault();
  }

  onYearChangeVehicleGridCallAPi(event: Date) {
    this.ManufactureYearModal = event;
    this.viewVehicleGridModal();
  }

  onModelIdChangeVehicleGrid(event: any) {
    this.Vehicle_Model_Name = event;
    console.log("model name after change log", this.Vehicle_Model_Name);
    this.viewVehicleGridModal();
  }

  OnChangeyear() {
    this.ManufactureYearModal = this.ManufactureYear;
  }

  OnChangeVehicleMOdel() {
    // this.Vehicle_Model_Name = this.VehicleModel;
    this.Vehicle_Model_Name = this.vehicleModelData.filter((x) => x.Id == this.VehicleModel)[0].Model_Name;

  }

  viewVehicleGridModal() {
    var modelId = 0;
    if (
      this.Vehicle_Model_Name != "" &&
      this.Vehicle_Model_Name != null &&
      this.Vehicle_Model_Name != undefined
    ) {
      modelId = this.modelNameDropdown.filter(
        (x) => x.Vehicle_Model_Name == this.Vehicle_Model_Name
      )[0].Int_Id;
    }
    const selectedYearforVehicleGrid = moment(this.ManufactureYearModal).format(
      "YYYY"
    );
    const vehicleModelId = modelId;
    this.lmsService
      .Get_Vehicle_Model_Grid_List({
        year: selectedYearforVehicleGrid || 0,
        modelId: vehicleModelId || 0,
      })
      .subscribe((res: any) => {
        console.log("get data for vehicle grid  in asset details", res);
        this.dataSourceViewDetails = res;
        this.updateDisplayedColumns(+selectedYearforVehicleGrid);
      });

    $("#viewVehicleGridModel").modal("show");
    $("#viewVehicleGridModel").css("z-index", "1050");
  }

  updateDisplayedColumns(year: number): void {
    this.displayedColumnsVehicleGridModel = ["modelId", "modelName"];
    for (let i = 0; i <= 9; i++) {
      this.displayedColumnsVehicleGridModel.push(`Y${year - i}`);
    }
    // console.log("Displayed Column Dynamic", this.displayedColumns);
  }

  LMS_GetVehicleModelMasterAsset() {
    this.showSpinner = true;
    this.lmsService.Get_Lms_VehicleModelMaster().subscribe((response: any) => {
      console.log(response);
      this.modelNameDropdown = response;
      console.log("modelNameDropdown", this.modelNameDropdown);
    });
    this.showSpinner = false;
  }

  RCVerification() {
    if (this.VehicleRegistationNo) { 
    this._MasterService
      .Get_Sms_Configration({ Step_Name: "RC" })
      .subscribe((res: any) => {
        if (res.length == 0) {
        
          this.RCVerified_Button = false;
        } else {
          this.Get_Alredy_Verified_KYC();
          this.RCVerified_Button = true;
          this.RCKey = res[0].Key;
          this.RCSMS_URL = res[0].SMS_URL;
        }

      });
      }
  }
  Get_Alredy_Verified_KYC() {
    this._MasterService
      .Get_Alredy_Verified_KYC({
        DocumentId: 17,
        KYC_DocNumber: this.VehicleRegistationNo
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.APIResponse = {
            KYC_DocId: 17,
            DoucumentNumber: JSON.parse(res[0].APIResponse).data.rc_number,
            IsVerified: JSON.parse(res[0].APIResponse).success,
            rc_number: JSON.parse(res[0].APIResponse).data.rc_number,
            fit_up_to: JSON.parse(res[0].APIResponse).data.fit_up_to,
            registration_date: JSON.parse(res[0].APIResponse).data.registration_date,
            owner_name: JSON.parse(res[0].APIResponse).data.owner_name,
            father_name: JSON.parse(res[0].APIResponse).data.father_name,
            present_address: JSON.parse(res[0].APIResponse).data.present_address,
            permanent_address: JSON.parse(res[0].APIResponse).data.permanent_address,
            mobile_number: JSON.parse(res[0].APIResponse).data.mobile_number,
            vehicle_category: JSON.parse(res[0].APIResponse).data.vehicle_category,
            vehicle_chasi_number: JSON.parse(res[0].APIResponse).data.vehicle_chasi_number,
            vehicle_engine_number: JSON.parse(res[0].APIResponse).data.vehicle_engine_number,
            maker_description: JSON.parse(res[0].APIResponse).data.maker_description,
            maker_model: JSON.parse(res[0].APIResponse).data.maker_model,
            body_type: JSON.parse(res[0].APIResponse).data.body_type,
            fuel_type: JSON.parse(res[0].APIResponse).data.fuel_type,
            color: JSON.parse(res[0].APIResponse).data.color,
            norms_type: JSON.parse(res[0].APIResponse).data.norms_type,
            financer: JSON.parse(res[0].APIResponse).data.financer,
            financed: JSON.parse(res[0].APIResponse).data.financed,
            insurance_company: JSON.parse(res[0].APIResponse).data.insurance_company,
            insurance_policy_number: JSON.parse(res[0].APIResponse).data.insurance_policy_number,
            insurance_upto: JSON.parse(res[0].APIResponse).data.insurance_upto,
            manufacturing_date: JSON.parse(res[0].APIResponse).data.manufacturing_date,
            manufacturing_date_formatted: JSON.parse(res[0].APIResponse).data.manufacturing_date_formatted,
            registered_at: JSON.parse(res[0].APIResponse).data.registered_at,
            latest_by: JSON.parse(res[0].APIResponse).data.latest_by,
            less_info: JSON.parse(res[0].APIResponse).data.less_info,
            tax_upto: JSON.parse(res[0].APIResponse).data.tax_upto,
            tax_paid_upto: JSON.parse(res[0].APIResponse).data.tax_paid_upto,
            cubic_capacity: JSON.parse(res[0].APIResponse).data.cubic_capacity,
            vehicle_gross_weight: JSON.parse(res[0].APIResponse).data.vehicle_gross_weight,
            no_cylinders: JSON.parse(res[0].APIResponse).data.no_cylinders,
            seat_capacity: JSON.parse(res[0].APIResponse).data.seat_capacity,
            sleeper_capacity: JSON.parse(res[0].APIResponse).data.sleeper_capacity,
            standing_capacity: JSON.parse(res[0].APIResponse).data.standing_capacity,
            wheelbase: JSON.parse(res[0].APIResponse).data.wheelbase,
            unladen_weight: JSON.parse(res[0].APIResponse).data.unladen_weight,
            vehicle_category_description: JSON.parse(res[0].APIResponse).data.vehicle_category_description,
            pucc_number: JSON.parse(res[0].APIResponse).data.pucc_number,
            pucc_upto: JSON.parse(res[0].APIResponse).data.pucc_upto,
            permit_number: JSON.parse(res[0].APIResponse).data.permit_number,
            permit_issue_date: JSON.parse(res[0].APIResponse).data.permit_issue_date,
            permit_valid_from: JSON.parse(res[0].APIResponse).data.permit_valid_from,
            permit_valid_upto: JSON.parse(res[0].APIResponse).data.permit_valid_upto,
            permit_type: JSON.parse(res[0].APIResponse).data.permit_type,
            national_permit_number: JSON.parse(res[0].APIResponse).data.national_permit_number,
            national_permit_upto: JSON.parse(res[0].APIResponse).data.national_permit_upto,
            national_permit_issued_by: JSON.parse(res[0].APIResponse).data.national_permit_issued_by,
            non_use_status: JSON.parse(res[0].APIResponse).data.non_use_status,
            non_use_from: JSON.parse(res[0].APIResponse).data.non_use_from,
            non_use_to: JSON.parse(res[0].APIResponse).data.non_use_to,
            blacklist_status: JSON.parse(res[0].APIResponse).data.blacklist_status,
            noc_details: JSON.parse(res[0].APIResponse).data.noc_details,
            owner_number: JSON.parse(res[0].APIResponse).data.owner_number,
            rc_status: JSON.parse(res[0].APIResponse).data.rc_status,
            masked_name: JSON.parse(res[0].APIResponse).data.masked_name,
            challan_details: JSON.parse(res[0].APIResponse).data.challan_details,
            variant: JSON.parse(res[0].APIResponse).data.variant,
            rto_code: JSON.parse(res[0].APIResponse).data.rto_code,

            message_code: JSON.parse(res[0].APIResponse).message_code,
          }
          this.RCLastVerfiedDate = res[0].LastVerfiedDate;
          this.RCKYC_IsVerified = 1;
        } else {
          this.RCLastVerfiedDate = "";
          this.RCKYC_IsVerified = 0;
        }


      })
  }
  onVerification(ReVerify) {
    if (ReVerify == 1) {
      this.dialog
        .openConfirmDialog(
          `Are you sure you want to Re-Verified this RC ?`
        )
        .afterClosed()
        .subscribe((dialogResult) => {
          if (dialogResult == false) {
            return;
          } else {
            this.KYCVerifyByAPI();
          }
        });
    } else {
      this.KYCVerifyByAPI();
    }
  }

  KYCVerifyByAPI() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.VehicleRegistationNo,
      KYC_DocId: 17,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
    };
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.VehicleRegistationNo,

          DocumentId: 17,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.data.userId,
        },
      };

      if (APIResult.success == true) {
        this.RCKYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 17,
          //DoucumentNumber: this.VehicleRegistationNo,
          //Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .full_name,
          //IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .success,
          //category: JSON.parse(data.KYC_Verification.APIResponse).data
          //  .category,
          //message_code: JSON.parse(data.KYC_Verification.APIResponse)
          //  .message_code,



          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data.rc_number,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
          rc_number: JSON.parse(data.KYC_Verification.APIResponse).data.rc_number,
          fit_up_to: JSON.parse(data.KYC_Verification.APIResponse).data.fit_up_to,
          registration_date: JSON.parse(data.KYC_Verification.APIResponse).data.registration_date,
          owner_name: JSON.parse(data.KYC_Verification.APIResponse).data.owner_name,
          father_name: JSON.parse(data.KYC_Verification.APIResponse).data.father_name,
          present_address: JSON.parse(data.KYC_Verification.APIResponse).data.present_address,
          permanent_address: JSON.parse(data.KYC_Verification.APIResponse).data.permanent_address,
          mobile_number: JSON.parse(data.KYC_Verification.APIResponse).data.mobile_number,
          vehicle_category: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_category,
          vehicle_chasi_number: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_chasi_number,
          vehicle_engine_number: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_engine_number,
          maker_description: JSON.parse(data.KYC_Verification.APIResponse).data.maker_description,
          maker_model: JSON.parse(data.KYC_Verification.APIResponse).data.maker_model,
          body_type: JSON.parse(data.KYC_Verification.APIResponse).data.body_type,
          fuel_type: JSON.parse(data.KYC_Verification.APIResponse).data.fuel_type,
          color: JSON.parse(data.KYC_Verification.APIResponse).data.color,
          norms_type: JSON.parse(data.KYC_Verification.APIResponse).data.norms_type,
          financer: JSON.parse(data.KYC_Verification.APIResponse).data.financer,
          financed: JSON.parse(data.KYC_Verification.APIResponse).data.financed,
          insurance_company: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_company,
          insurance_policy_number: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_policy_number,
          insurance_upto: JSON.parse(data.KYC_Verification.APIResponse).data.insurance_upto,
          manufacturing_date: JSON.parse(data.KYC_Verification.APIResponse).data.manufacturing_date,
          manufacturing_date_formatted: JSON.parse(data.KYC_Verification.APIResponse).data.manufacturing_date_formatted,
          registered_at: JSON.parse(data.KYC_Verification.APIResponse).data.registered_at,
          latest_by: JSON.parse(data.KYC_Verification.APIResponse).data.latest_by,
          less_info: JSON.parse(data.KYC_Verification.APIResponse).data.less_info,
          tax_upto: JSON.parse(data.KYC_Verification.APIResponse).data.tax_upto,
          tax_paid_upto: JSON.parse(data.KYC_Verification.APIResponse).data.tax_paid_upto,
          cubic_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.cubic_capacity,
          vehicle_gross_weight: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_gross_weight,
          no_cylinders: JSON.parse(data.KYC_Verification.APIResponse).data.no_cylinders,
          seat_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.seat_capacity,
          sleeper_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.sleeper_capacity,
          standing_capacity: JSON.parse(data.KYC_Verification.APIResponse).data.standing_capacity,
          wheelbase: JSON.parse(data.KYC_Verification.APIResponse).data.wheelbase,
          unladen_weight: JSON.parse(data.KYC_Verification.APIResponse).data.unladen_weight,
          vehicle_category_description: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_category_description,
          pucc_number: JSON.parse(data.KYC_Verification.APIResponse).data.pucc_number,
          pucc_upto: JSON.parse(data.KYC_Verification.APIResponse).data.pucc_upto,
          permit_number: JSON.parse(data.KYC_Verification.APIResponse).data.permit_number,
          permit_issue_date: JSON.parse(data.KYC_Verification.APIResponse).data.permit_issue_date,
          permit_valid_from: JSON.parse(data.KYC_Verification.APIResponse).data.permit_valid_from,
          permit_valid_upto: JSON.parse(data.KYC_Verification.APIResponse).data.permit_valid_upto,
          permit_type: JSON.parse(data.KYC_Verification.APIResponse).data.permit_type,
          national_permit_number: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_number,
          national_permit_upto: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_upto,
          national_permit_issued_by: JSON.parse(data.KYC_Verification.APIResponse).data.national_permit_issued_by,
          non_use_status: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_status,
          non_use_from: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_from,
          non_use_to: JSON.parse(data.KYC_Verification.APIResponse).data.non_use_to,
          blacklist_status: JSON.parse(data.KYC_Verification.APIResponse).data.blacklist_status,
          noc_details: JSON.parse(data.KYC_Verification.APIResponse).data.noc_details,
          owner_number: JSON.parse(data.KYC_Verification.APIResponse).data.owner_number,
          rc_status: JSON.parse(data.KYC_Verification.APIResponse).data.rc_status,
          masked_name: JSON.parse(data.KYC_Verification.APIResponse).data.masked_name,
          challan_details: JSON.parse(data.KYC_Verification.APIResponse).data.challan_details,
          variant: JSON.parse(data.KYC_Verification.APIResponse).data.variant,
          rto_code: JSON.parse(data.KYC_Verification.APIResponse).data.rto_code,

          message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code,
        };
      } else {
        this.RCKYC_IsVerified = 0;
      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (
              JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
            ) {
              this.RCKYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: JSON.parse(data.KYC_Verification.APIResponse).message,
                ...this.configSuccess,
              });
            }
          }
        });
    });

  }


  getdata() {
    this.Get_Alredy_Verified_KYC();
    console.log(" this.APIResponse", this.APIResponse);

    setTimeout(() => {
      $(`#modalOverlay`).modal("show");
      $(`#modalOverlay`).css("z-index", "1090");
    }, 1000);
  }
}
