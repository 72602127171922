import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { LmsService } from "../services/lms.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MasterService } from "../../Shared/app.Masters.Service";
import { Row } from "@syncfusion/ej2-angular-charts";
declare var $: any;

@Component({
  selector: "app-generate-pending-amortization",
  templateUrl: "./generate-pending-amortization.component.html",
  styleUrls: ["./generate-pending-amortization.component.scss"],
})
export class GeneratePendingAmortizationComponent implements OnInit {
  currentUser: any;
  loading: boolean = false;
  effectiveIrr: any;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];
  AmortizationColumns: string[] = [];
  FileCheckingList: any[] = [];
  firstEmiDate: any;
  RowData: any;
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "FLAT_EMI",
    Adv_Inst: 0,
    DueDate: new Date(),
  };
  emiIrr: any;
  AmortizationSource: any;
  InterestAmount: any;
  IsDeduct: boolean = true;
  Days: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  dataSourceModal: MatTableDataSource<unknown>;
  FileCheckingListModal: any;
  displayedColumnsModal: any[];
  errorMessage: string;
  pendingAmount: any;
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private router: Router,
    private MasterService: MasterService,
    private _EncrDecrService: EncrDecrService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Generate Pending Amortization");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getGeneratePendingAmortization();

    this.displayedColumns = [
      "Loan_Id",
      "Customer",
      "ApplicationNo",
      "LoanNo",
      "Branch",
      "Product",
      "LoanAmount",
      "MobileNo",
      "Action",
    ];

    this.displayedColumnsModal = ["Id", "Head", "Voucher_Date", "Amount"];
  }

  getGeneratePendingAmortization() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_Generate_Amortization_AfterPayment_List({})
      .subscribe((res) => {
        console.log("result", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSource.Sort = this.sort;
        this.dataSource.pagintor = this.paginator;
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "generate_pending_amortization"
    ) as HTMLTableElement;
    const worksheetName = "GENERATE PENDING AMORTIZATION"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  goToDetail(row) {
    // if(row.Pending_Amount!=0){
    //   this.snackBar.openFromComponent(SnackbarComponent, {
    //     data: "The disbursement amount does not match the payment amount.",
    //     ...this.configSuccess,
    //   });
    //   return;
    // }else{
    this.RowData = row;
    this.firstEmiDate = "";
    this.Days = 0;
    this.InterestAmount = 0;
    this.pendingAmount = this.RowData.Pending_Amount;
    console.log("Pending Amount", this.pendingAmount);

    console.log("loan id of clicked row", this.RowData.Loan_Id);

    this.lmsService
      .LMS_Get_Disbursement_Loan_Detail({ loanId: this.RowData.Loan_Id })
      .subscribe((res) => {
        console.log("result of modal api call", res);
        this.FileCheckingListModal = JSON.parse(JSON.stringify(res));
        this.dataSourceModal = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );

        // this.dataSourceModal.Sort = this.sort;
        // this.dataSourceModal.pagintor = this.paginator;
      });

    // if (row.Pending_Amount != 0) {
    //   this.errorMessage =
    //     "The disbursement amount does not match the payment amount.";

    //   return this.errorMessage;
    // }

    $("#GenerateAmortizationInfo").modal("show");
    $("#GenerateAmortizationInfo").css("z-index", "1050");
    // }
  }
  GenerateAmortization() {
    if (this.IsDeduct == false) {
      this.InterestAmount = 0;
    }
    // this.lmsService
    // .LMS_Generate_Pending_Amortization({LoanId:this.RowData.Loan_Id,Loan_First_EMIDate:this.firstEmiDate,effectiveIrrData:effectiveIrr,LoginUserId:this.currentUser.userId,InterestAmount:this.InterestAmount})
    // .subscribe((res) => {

    // })

    if (
      new Date(this.firstEmiDate).getTime() <
      new Date(this.RowData.Loan_Date).getTime()
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Loan Date should be less than first emi date",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.RowData.EMI_Type == "ByMonthly" ||
      this.RowData.EMI_Type == "Quarterly" ||
      this.RowData.EMI_Type == "HalfYearly" ||
      this.RowData.EMI_Type == "Yearly"
    ) {
      if (
        new Date(this.firstEmiDate).getMonth() ==
        new Date(this.RowData.Loan_Date).getMonth()
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "first EMI Date should be 1 Month greater than Loan Date",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.cEIModel = {
      Asset_Cost: this.RowData.Asset_Cost,
      Adv_Inst: this.RowData.AdvInstl,
      DueDate: this.firstEmiDate,
      EMI_Amount: this.RowData.EMIAmount,
      EMI_Type: this.RowData.EMI_Type,
      IRR_CalculateBy: "FLAT_EMI",
      Loan_Date: this.RowData.Loan_Date,
      IsStep: false,
      NetFinance_Amt: this.RowData.LoanAmount,
      No_Of_Inst: this.RowData.No_Of_Instl,
      Tenure: this.RowData.Tenure,
    };
    this.setTenure();
    console.log("cEIModel", this.cEIModel);
    if (this.RowData.IRR_CalculateBy == "STEP_EMI") {
      this.GenerateAmortization();
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
      let _data = { ...this.cEIModel };
      this.getCalculateEMIIRR(_data);
    }
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst) {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    }
    var diffMonth =
      (new Date(this.cEIModel.DueDate).getFullYear() -
        new Date(this.cEIModel.Loan_Date).getFullYear()) *
        12 +
      (new Date(this.cEIModel.DueDate).getMonth() -
        new Date(this.cEIModel.Loan_Date).getMonth());

    if (this.cEIModel.EMI_Type == "ByMonthly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (2 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Quarterly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (3 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "HalfYearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (6 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Yearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (12 - diffMonth);
    }
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.lmsService.GetEMICalculate(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        var stepEMI = [];

        stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }

      var ReqModel = {};

      ReqModel = {
        LoanAmount: this.cEIModel.NetFinance_Amt,
        LoanPeriod: this.cEIModel.No_Of_Inst,
        Application_EMIType: this.cEIModel.EMI_Type,
        EMIAmount: this.emiIrr.EMI_Amt,
        RateOfInterest: this.emiIrr.ROI,
        NoOfEMI: this.cEIModel.Tenure,
        AdvanceInst:
          this.cEIModel.EMI_Type == "Monthly" ? this.cEIModel.Adv_Inst : 0,
        DisbIRR: this.emiIrr.Disbursement_IRR,
        InterestAmount: this.emiIrr.Interest_Amt,
        DueDate: this.cEIModel.DueDate,
        LoanDate: this.cEIModel.Loan_Date,
      };
      console.log("ReqModel", ReqModel);
      this.MasterService.LMS_EMI_Calculator_Without_StepEmi(ReqModel).subscribe(
        (resAmor: any) => {
          console.log("resAmor", resAmor);
          this.AmortizationColumns = [
            "PERIOD",
            "DueDate",
            "EMI_Amount",
            "PRINCIPAL",
            "INTEREST",
            "Principle_OS",
            "export",
          ];
          this.AmortizationSource = new MatTableDataSource(resAmor);
          $("#GenerateAmortizationInfo").modal("hide");
          $("#EMICalculatorAmort").modal("show");
        }
      );
    });
  }
  OnClose() {
    $("#GenerateAmortizationInfo").modal("hide");
    this.RowData = null;
  }
  onCloseEMICalculator() {
    $("#EMICalculatorAmort").modal("hide");
    $("#GenerateAmortizationInfo").modal("hide");
    this.RowData = null;
  }
  changeDatefirstEmiDate() {
    var EDate = Object.assign(new Date(this.firstEmiDate));

    if (this.RowData.EMI_Type == "Monthly") {
      EDate.setMonth(EDate.getMonth() - 1);
    } else if (this.RowData.EMI_Type == "ByMonthly") {
      EDate.setMonth(EDate.getMonth() - 2);
    } else if (this.RowData.EMI_Type == "Quarterly") {
      EDate.setMonth(EDate.getMonth() - 3);
    } else if (this.RowData.EMI_Type == "HalfYearly") {
      EDate.setMonth(EDate.getMonth() - 6);
    } else if (this.RowData.EMI_Type == "Yearly") {
      EDate.setMonth(EDate.getMonth() - 12);
    } else if (this.RowData.EMI_Type == "Weekly") {
      EDate.setDate(EDate.getDate() - 7);
    } else if (this.RowData.EMI_Type == "Fortnightly") {
      EDate.setDate(EDate.getDate() - 15);
    } else if (this.RowData.EMI_Type == "ByWeekly") {
      EDate.setDate(EDate.getDate() - 14);
    } else if (this.RowData.EMI_Type == "FourthWeekly") {
      EDate.setDate(EDate.getDate() - 28);
    } else {
      EDate.setDate(EDate.getDate() - 1);
    }
    this.InterestAmount = 0;
    if (new Date(this.RowData.lastDisbursementDate) > EDate) {
      this.InterestAmount = 0;
    } else {
      this.Days = this.getDiffDays(
        new Date(this.RowData.lastDisbursementDate),
        EDate
      );
      this.InterestAmount = Math.round(
        ((this.RowData.LoanAmount * this.RowData.IRR) /
          100 /
          this.currentUser.DaysInYear) *
          this.Days
      );
    }
    console.log("InterestAmount", this.InterestAmount);
    console.log("Days", this.Days);
  }
  getDiffDays(startDate, endDate) {
    return Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));
  }
  onIsDeductChange(event) {
    this.IsDeduct = event.checked;
    console.log("IsDeduct", this.IsDeduct);
  }
  OnSaveAmortization() {
    this.lmsService
      .LMS_Generate_Pending_Amortization({
        LoanId: this.RowData.Loan_Id,
        Loan_First_EMIDate: this.firstEmiDate,
        IRR: this.RowData.IRR,
        DaysInYear: this.currentUser.DaysInYear,
        LoginUserId: this.currentUser.userId,
        InterestAmount: this.InterestAmount,
        EMI_Type: this.RowData.EMI_Type,
        Loan_Date: this.RowData.Loan_Date,
        EMI_Amount: this.RowData.EMI_Amount,
        AdvInstl: this.RowData.AdvInstl,
        No_Of_Instl: this.RowData.No_Of_Instl,
        IRR_CalculateBy: this.RowData.IRR_CalculateBy,
        DisbursementAmt: this.RowData.DisbursementAmt,
      })
      .subscribe((res) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.getGeneratePendingAmortization();
        }
      });
  }
  totaltypewise(type) {
    if (
      this.AmortizationSource &&
      this.AmortizationSource != null &&
      this.AmortizationSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.AmortizationSource.filteredData.length; i++) {
        sum += Number(this.AmortizationSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
}
