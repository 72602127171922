<div>
  <form
    #f="ngForm"
    (ngSubmit)="OnSearchConsumerDurableModel()"
    novalidate
    class="row m-0 formborder"
  >
    <div class="col-md-2">
      <span> Select Category </span>
      <select
        name="SelectCategory"
        id="SelectCategory"
        #refSelectCategory="ngModel"
        [(ngModel)]="SelectCategory"
        class="form-control input-text-css"
        required
        [ngClass]="{
          'is-invalid': f.submitted && refSelectCategory.invalid,
          'alert-warning':
            refSelectCategory.invalid &&
            (refSelectCategory.dirty ||
              refSelectCategory.touched ||
              refSelectCategory.untouched)
        }"
        (change)="getManufactureNames(SelectCategory)"
      >
        <option value="0">Select Category</option>
        <option *ngFor="let category of categoryList" [value]="category.Id">
          {{ category.Category_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-2">
      <span> Select Manufacture </span>
      <select
        name="SelectManufacture"
        id="SelectManufacture"
        #refSelectManufacture="ngModel"
        [(ngModel)]="SelectManufacture"
        class="form-control input-text-css"
        required
        [ngClass]="{
          'is-invalid': f.submitted && refSelectManufacture.invalid,
          'alert-warning':
            refSelectManufacture.invalid &&
            (refSelectManufacture.dirty ||
              refSelectManufacture.touched ||
              refSelectManufacture.untouched)
        }"
        (change)="getProductName(SelectCategory, SelectManufacture)"
      >
        <option value="0">Select Manufacture</option>
        <option
          *ngFor="let manufacture of manufactureList"
          [value]="manufacture.Id"
        >
          {{ manufacture.Manufacture_Name }}
        </option>
      </select>
    </div>

    <div class="col-md-2">
      <span> Select Product </span>
      <select
        name="SelectProduct"
        id="SelectProduct"
        #refSelectProduct="ngModel"
        [(ngModel)]="SelectProduct"
        class="form-control input-text-css"
        required
        [ngClass]="{
          'is-invalid': f.submitted && refSelectProduct.invalid,
          'alert-warning':
            refSelectProduct.invalid &&
            (refSelectProduct.dirty ||
              refSelectProduct.touched ||
              refSelectProduct.untouched)
        }"
      >
        <option value="0">Select Product</option>

        <option *ngFor="let product of productList" [value]="product.Id">
          {{ product.Product_Name }}
        </option>
      </select>
    </div>

    <div class="text-right col-md-1 my-1 pr-2">
      <button
        (click)="OnSearchConsumerDurableModel()"
        type="button"
        class="mt-3 btn font-size-12 button-btn"
      >
        Search
      </button>
    </div>

    <div class="text-right col-md-1 my-1 pr-2 ml-3">
      <button
        (click)="OnAddDurableModel()"
        type="button"
        class="mt-3 btn font-size-12 button-btn"
      >
        Add
      </button>
    </div>

    <div class="col-md-3 mt-3 text-right">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              (keyup)="applyFilter($event.target.value)"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </form>
</div>

<div class="table-responsive mt-2">
  <mat-table [dataSource]="dataSourceModel">
    <ng-container matColumnDef="Id">
      <mat-header-cell
        class="grid-header"
        style="max-width: 50px"
        *matHeaderCellDef
        >#</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row; let i = index"
        class="grid-cell"
        style="max-width: 50px; max-height: 5px"
        >{{ i + 1 }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="Category_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef>
        Category
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Category_Name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Manufacture_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef
        >Manufacture Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Manufacture_Name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Product_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef
        >Product Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Product_Name
      }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Model_Name">
      <mat-header-cell class="grid-header" *matHeaderCellDef
        >Model Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.Model_Name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef
        >Action</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
        <i
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          aria-label="true"
          (click)="editModelName(row)"
        ></i>
        <i
          class="fa fa-trash style-delete"
          (click)="deleteModelName(row)"
          aria-label="true"
        ></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumn"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumn"></mat-row>
  </mat-table>
</div>

<div
  class="modal fade in"
  id="editConsumerDurableModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #ff="ngForm" (ngSubmit)="OnSaveAndEditDurableModel()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ ButtonName }} Durable Model
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div
            class="row m-0 font-size-12 align-items-center d-flex flex-column"
          >
            <div class="col-md-12">
              <span> Select Category </span>
              <select
                name="SelectCategoryModel"
                id="SelectCategoryModel"
                #refSelectCategoryModel="ngModel"
                [(ngModel)]="SelectCategoryModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ff.submitted && refSelectCategoryModel.invalid,
                  'alert-warning':
                    refSelectCategoryModel.invalid &&
                    (refSelectCategoryModel.dirty ||
                      refSelectCategoryModel.touched ||
                      refSelectCategoryModel.untouched)
                }"
                (change)="getManufactureNames(SelectCategoryModel)"
              >
                <option value="">Select Category</option>
                <option
                  *ngFor="let category of categoryList"
                  [value]="category.Id"
                >
                  {{ category.Category_Name }}
                </option>
              </select>
            </div>

            <div class="col-md-12">
              <span> Select Manufacture </span>
              <select
                name="SelectManufactureModel"
                id="SelectManufactureModel"
                #refSelectManufactureModel="ngModel"
                [(ngModel)]="SelectManufactureModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid':
                    ff.submitted && refSelectManufactureModel.invalid,
                  'alert-warning':
                    refSelectManufactureModel.invalid &&
                    (refSelectManufactureModel.dirty ||
                      refSelectManufactureModel.touched ||
                      refSelectManufactureModel.untouched)
                }"
                (change)="
                  getProductName(SelectCategoryModel, SelectManufactureModel)
                "
              >
                <option value="">Select Manufacture</option>
                <option
                  *ngFor="let manufacture of manufactureList"
                  [value]="manufacture.Id"
                >
                  {{ manufacture.Manufacture_Name }}
                </option>
              </select>
            </div>

            <div class="col-md-12">
              <span> Select Product </span>
              <select
                name="SelectProductModel"
                id="SelectProductModel"
                #refSelectProductModel="ngModel"
                [(ngModel)]="SelectProductModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': ff.submitted && refSelectProductModel.invalid,
                  'alert-warning':
                    refSelectProductModel.invalid &&
                    (refSelectProductModel.dirty ||
                      refSelectProductModel.touched ||
                      refSelectProductModel.untouched)
                }"
              >
                <option value="">Select Product</option>

                <option
                  *ngFor="let product of productList"
                  [value]="product.Id"
                >
                  {{ product.Product_Name }}
                </option>
              </select>
            </div>

            <div class="col-md-12">
              <span class="required-lable">Model Name</span>

              <input
                required
                type="text"
                #refmodelName="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refmodelName.invalid,
                  'alert-warning':
                    refmodelName.invalid &&
                    (refmodelName.dirty ||
                      refmodelName.touched ||
                      refmodelName.untouched)
                }"
                [(ngModel)]="modelName"
                placeholder="Add Model"
                name="modelName"
                id="modelName"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnSaveAndEditDurableModel()"
            class="mt-3 btn font-size-12 button-btn"
            [disabled]="!ff.form.valid"
            data-dismiss="modal"
            aria-label="Close"
          >
            {{ SaveUpdateButton }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
