<div class="card-container ">
  <div class="image container mb-2 ">
    <!-- <img [src]="!!customerData.Customer_ProfilePic?profileUrl:'assets/images/default-user.png'" style="display:block;left:0;right: 0;margin-left: auto;margin-right: auto;margin-bottom: auto;"> -->
    <img [src]="!!customerData.Customer_ProfilePic?profileUrl:'assets/images/default-user.png'" >
  </div>
  <div class="name" style="margin-left: auto;margin-right: auto;left:0;right: 0;">
    <h1 style="margin-bottom: 2px;">{{customerData['Customer_Name']}}</h1>
    <div class="text-content" style="text-align: center;">
      {{customerData.AC_CustomerType}}

    </div>
  </div>
  <div class="Main-content" style="display: flex;flex-direction: column;">
    <div class="main-cont">
      <h1 class="Text-Head" *ngIf="customerData.Customer_IsFirm=='False'">Permanent Address:</h1>
      <h1 class="Text-Head" *ngIf="customerData.Customer_IsFirm=='True'">Registered Address:</h1>
      <div class="text-content" style="overflow-wrap: break-word;">
        {{customerData.Address1}}, {{customerData.Tehsil1}}, {{customerData.District1}},
        {{customerData.State1}}, {{customerData.PIN1}}
      </div>
    </div>

    <div class="main-cont">

      <h1 class="Text-Head" *ngIf="customerData.Customer_IsFirm=='False'">Present Address:</h1>
      <h1 class="Text-Head" *ngIf="customerData.Customer_IsFirm=='True'">Corporate Address:</h1>
      <div style="overflow-wrap: break-word;">
        <div class="text-content" style="overflow-wrap: break-word;">
          {{customerData.Address2}}, {{customerData.Tehsil2}}, {{customerData.District2}},
          {{customerData.State2}}, {{customerData.PIN2}}
        </div>

      </div>
    </div>
    <div class="main-cont">
      <h1 class="Text-Head" >Date of Birth:</h1>
      <div class="text-content" style="overflow-wrap: break-word; display: inline;">
        {{customerData.Customer_DOB| date: 'dd/MM/yyyy'}}
      </div>
    </div>
    <div class="main-cont">
      <h1 class="Text-Head" >{{customerData.Gender=="Firm"?'Type':'Gender'}}:</h1>
      <div class="text-content" style="overflow-wrap: break-word; display: inline;">
        {{customerData.Gender}}
      </div>
    </div>
    <div class="main-cont">
      <h1 class="Text-Head" >Mobile No.:</h1>
      <div class="text-content" style="overflow-wrap: break-word; display: inline;">
        {{customerData.Customer_PhoneNo}}
      </div>
    </div>
    <div class="main-cont">

      <h1 class="Text-Head" >Profile: </h1>
      <div style="display: inline;"
           class="text-content">
        {{customerData.Profile}}
      </div>
    </div>
    <div class="main-cont">

      <h1 class="Text-Head" >Category: </h1>
      <div style="display: inline;"
           class="text-content">{{customerData.Category}}</div>

    </div>
    <div class="main-cont">

      <h1 class="Text-Head" >Sub Category: </h1>
      <div style="display: inline;"
           class="text-content">{{customerData.Sub_Category}}</div>

    </div>
    <div class="main-cont">

      <h1 class="Text-Head" >Business Category: </h1>
      <div style="display: inline;"
           class="text-content">{{customerData.BusinessCategory}}</div>

    </div>
    <div class="main-cont">

      <h1 class="Text-Head" >Nature Of Work: </h1>
      <div style="display: inline;"
           class="text-content">{{customerData.Nature_of_work}}</div>

    </div>
  </div>
</div>
