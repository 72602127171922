<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #df="ngForm" novalidate (keydown.enter)="GetReportNPAMarking()">
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <span class="required-lable">Slab Name</span>
      <select
        name="SlabId"
        id="SlabId"
        [(ngModel)]="SlabId"
        class="form-control input-text-css"
        required
        #refSlabId="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refSlabId.invalid,
          'alert-warning':
            refSlabId.invalid &&
            (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
        }"
      >
        <option value="">Select Slab Name</option>
        <option *ngFor="let row of SlabData" [value]="row.Id">
          {{ row.Slab_name }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <span class="required-lable">As On Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="AsOnDate"
          [(ngModel)]="AsOnDate"
          id="AsOnDate"
          class="form-control input-text-css"
          required
          #refAsOnDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refAsOnDate.invalid,
            'alert-warning':
              refAsOnDate.invalid &&
              (refAsOnDate.dirty ||
                refAsOnDate.touched ||
                refAsOnDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="AsOnDate"
        [(ngModel)]="AsOnDate"
        id="AsOnDate"
        placeholder="To Date"
        class="form-control input-text-css"
        bsDatepicker
        required
        #refAsOnDate="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refAsOnDate.invalid,
          'alert-warning':
            refAsOnDate.invalid &&
            (refAsOnDate.dirty || refAsOnDate.touched || refAsOnDate.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-6 mt-3">
      <button
        type="button"
        (click)="GetReportNPAMarking()"
        mat-raised-button
        class="btn_default"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>
  </div>
</form>

<div class="row m-0 p-0">
  <div class="col-md-12"><hr /></div>
</div>

<div class="row m-0">
  <!-- <div class="col-md-12 p-1 ml-1 mr-1 bg-success1">NPA Info:</div> -->
  <div class="col-md-12 mt-2" [hidden]="!dataSourceNPA">
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="dataSourceNPA"
        matSort
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="Loan_Id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan No</th>
          <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Date</th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Date }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiry Date</th>
          <td mat-cell *matCellDef="let row">{{ row.ExpiryDate }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Close_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Close Date</th>
          <td mat-cell *matCellDef="let row">{{ row.Close_Date }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Product_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">{{ row.Product_name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Acc_Category">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Loan A/C Category
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Acc_Category }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_Case_IRR">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Case IRR</th>
          <td mat-cell *matCellDef="let row">{{ row.Application_Case_IRR }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_LoanTenure">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Tenure</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Application_LoanTenure }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_LTV">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>LTV</th>
          <td mat-cell *matCellDef="let row">{{ row.Application_LTV }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="DPD_Days">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD Days</th>
          <td mat-cell *matCellDef="let row">{{ row.DPD_Days }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Due_On">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Due On</th>
          <td mat-cell *matCellDef="let row">{{ row.Due_On }}</td>
          <th mat-footer-cell *matFooterCellDef>Total</th>
        </ng-container>

        <ng-container matColumnDef="FutureDue_POS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Future Due POS
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.FutureDue_POS }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("FutureDue_POS") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="OD_Installment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ODI</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.OD_Installment }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("OD_Installment") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Charges_Balance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Charges Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Charges_Balance }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Charges_Balance") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Exposer">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Exposer</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Exposer }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Exposer") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Interest_Reversal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Interest Reversal
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Interest_Reversal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Interest_Reversal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Charges_Reversal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Charges Reversal
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Charges_Reversal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Charges_Reversal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Gross_NPA">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Gross NPA</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Gross_NPA }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Gross_NPA") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Provision_Rate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Provision Rate
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Provision_Rate }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Assets_Type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assets Type</th>
          <td mat-cell *matCellDef="let row">{{ row.Assets_Type }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Provision_Amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Provision Amount
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Provision_Amount }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Provision_Amount") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Net_NPA">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Net NPA</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Net_NPA }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Net_NPA") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="text-center">
            <i
              class="fa fa-arrow-circle-right btn_detail"
              (click)="GoToDetails(row)"
            ></i>
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsNPA; sticky: true"
        ></tr>
        <tr
          mat-row
          (click)="highlightRow(row)"
          [class.highlight]="row.Loan_Id == selectedId"
          *matRowDef="let row; columns: displayedColumnsNPA"
        ></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsNPA; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-4">
        <button class="btn btn-success font-size-12" (click)="exportTable()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[15, 30, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-3" [hidden]="!dataSourceRelation">
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="dataSourceRelation"
        matSort
        matTableExporter
        #exporter1="matTableExporter"
      >
        <ng-container matColumnDef="Loan_Id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan No</th>
          <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Date</th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Date }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiry Date</th>
          <td mat-cell *matCellDef="let row">{{ row.ExpiryDate }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Close_Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Close Date</th>
          <td mat-cell *matCellDef="let row">{{ row.Close_Date }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Product_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">{{ row.Product_name }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Loan_Acc_Category">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Loan A/C Category
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Loan_Acc_Category }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_Case_IRR">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Case IRR</th>
          <td mat-cell *matCellDef="let row">{{ row.Application_Case_IRR }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_LoanTenure">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Tenure</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Application_LoanTenure }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Application_LTV">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>LTV</th>
          <td mat-cell *matCellDef="let row">{{ row.Application_LTV }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="DPD_Days">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD Days</th>
          <td mat-cell *matCellDef="let row">{{ row.DPD_Days }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Due_On">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Due On</th>
          <td mat-cell *matCellDef="let row">{{ row.Due_On }}</td>
          <th mat-footer-cell *matFooterCellDef>Total</th>
        </ng-container>

        <ng-container matColumnDef="FutureDue_POS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Future Due POS
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.FutureDue_POS }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("FutureDue_POS") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="OD_Installment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ODI</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.OD_Installment }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("OD_Installment") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Charges_Balance">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Charges Balance
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Charges_Balance }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Charges_Balance") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Exposer">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Exposer</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Exposer }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Exposer") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Interest_Reversal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Interest Reversal
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Interest_Reversal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Interest_Reversal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Charges_Reversal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Charges Reversal
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Charges_Reversal }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Charges_Reversal") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Gross_NPA">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Gross NPA</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Gross_NPA }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Gross_NPA") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Provision_Rate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Provision Rate
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Provision_Rate }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Assets_Type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assets Type</th>
          <td mat-cell *matCellDef="let row">{{ row.Assets_Type }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Provision_Amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Provision Amount
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Provision_Amount }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Provision_Amount") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Net_NPA">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Net NPA</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Net_NPA }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right">
            {{ totaltypewise("Net_NPA") }}
          </th>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="text-center">
            <i
              class="fa fa-arrow-circle-right btn_detail"
              (click)="GoToDetails(row)"
            ></i>
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsRelation; sticky: true"
        ></tr>
        <tr
          mat-row
          (click)="highlightRow1(row)"
          [class.highlight]="row.Loan_Id == selectedId"
          *matRowDef="let row; columns: displayedColumnsRelation"
        ></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumnsRelation; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-4">
        <button class="btn btn-success font-size-12" (click)="exportTable1()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorlist
          [pageSizeOptions]="[15, 30, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewVoucher"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="NPA_Save_Acc_Voucher()" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Send Notice
          </h6>
          <button
            type="button"
            (click)="OnCloseVoucher()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0" *ngIf="dataSourceVoucher">
            <div class="col-md-12 p-1">
              <div class="table-responsive">
                <mat-table
                  [dataSource]="dataSourceVoucher"
                  matSort
                  matTableExporter
                  #exporter="matTableExporter"
                >
                  <ng-container matColumnDef="TranType">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.TranType }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="CaseNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Case No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.CaseNo }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Customer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Customer }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Branch_Name">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Branch</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Branch_Name }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="ChagesHead">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Category</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ChagesHead }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Account_Name">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Account Head</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Account_Name }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountDR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(DR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountDR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{
                      totalAmountDR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="AmountCR">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount(CR)</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.AmountCR
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{
                      totalAmountCR()
                    }}</mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    class="sticky-footer"
                    *matHeaderRowDef="displayedColumnsVoucher; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsVoucher"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedColumnsVoucher"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseVoucher()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="NPA_Save_Acc_Voucher()"
            *ngIf="TotalAmountDR == TotalAmountCR"
            mat-raised-button
            class="btn_default"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="row m-0 mt-3" [hidden]="!dataSourceRelation">
    <div class="col-md-12 mt-2">
        <div class="table-responsive">
            <mat-table [dataSource]="dataSourceRelation" matSort matTableExporter #exporter1="matTableExporter">
                <ng-container matColumnDef="Loan_Id">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef style="max-width:50px">#</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" class="grid-cell" style="max-width:50px">
                        {{ (paginatorlist.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header" style="max-width:50px"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan No</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell">{{row.LoanAcNo}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Loan_Date">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan Date</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Loan_Date}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="ExpiryDate">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Expiry Date</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.ExpiryDate}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Close_Date">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Close Date</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Close_Date}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerName">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Customer Name</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.CustomerName}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Branch_Name">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Branch</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Branch_Name}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Product_name">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Product</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Product_name}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Loan_Acc_Category">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Loan A/C Category</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Loan_Acc_Category}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Application_Case_IRR">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Case IRR</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Application_Case_IRR}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Application_LoanTenure">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Tenure</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Application_LoanTenure}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Application_LTV">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>LTV</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Application_LTV}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="DPD_Days">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>DPD Days</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.DPD_Days}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Due_On">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Due On</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Due_On}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header">Total</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="FutureDue_POS">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Future Due POS</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.FutureDue_POS}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("FutureDue_POS")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="OD_Installment">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>ODI</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.OD_Installment}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("OD_Installment")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Charges_Balance">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Charges Balance</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Charges_Balance}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Charges_Balance")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Exposer">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Exposer</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Exposer}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Exposer")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Interest_Reversal">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Interest Reversal</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Interest_Reversal}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Interest_Reversal")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Charges_Reversal">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Charges Reversal</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Charges_Reversal}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Charges_Reversal")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Gross_NPA">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Gross NPA</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Gross_NPA}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Gross_NPA")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Provision_Rate">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Provision Rate</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Provision_Rate}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Provision_Rate")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Assets_Type">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Assets Type</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Assets_Type}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right"></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Provision_Amount">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Provision Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Provision_Amount}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Provision_Amount")}}</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="Net_NPA">
                    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Net NPA</mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="grid-cell text-right">{{row.Net_NPA}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef class="grid-header text-right">{{totaltypewise("Net_NPA")}}</mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsRelation; sticky: true"></mat-header-row>
                <mat-row (click)="highlightRow1(row)" [class.highlight]="row.Loan_Id == selectedId" *matRowDef="let row; columns: displayedColumnsRelation"></mat-row>
                <mat-footer-row class="sticky-footer fontcolor" *matFooterRowDef="displayedColumnsRelation; sticky: true" style="background: #4dc3a3;"></mat-footer-row>
            </mat-table>
            <div class="row m-0 align-items-center" style="background: #fff;">
                <div class="col-md-4">
                    <button class="btn btn-success font-size-12" (click)="exportTable1(exporter1)">
                        <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
                    </button>
                </div>
                <div class="col-md-8">
                    <mat-paginator #paginatorlist [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div> -->

<table
  id="npa_marking"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Close Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan A/C Category
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case IRR
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">LTV</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Due POS
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">ODI</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charges Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Exposer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Reversal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charges Reversal
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gross NPA
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Rate
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assets Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Net NPA
    </th>
  </tr>

  <tr *ngFor="let x of TypeNPA; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Close_Date }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_name }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.Application_Case_IRR }}</td>

    <td>{{ x.Application_LoanTenure }}</td>
    <td>{{ x.Application_LTV }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.Due_On }}</td>
    <td>{{ x.FutureDue_POS }}</td>
    <td>{{ x.OD_Installment }}</td>
    <td>{{ x.Charges_Balance }}</td>
    <td>{{ x.Exposer }}</td>
    <td>{{ x.Interest_Reversal }}</td>
    <td>{{ x.Charges_Reversal }}</td>
    <td>{{ x.Gross_NPA }}</td>
    <td>{{ x.Provision_Rate }}</td>
    <td>{{ x.Assets_Type }}</td>
    <td>{{ x.Provision_Amount }}</td>
    <td>{{ x.Net_NPA }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="14"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureDue_POS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("OD_Installment") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Charges_Balance") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Exposer") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Interest_Reversal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Charges_Reversal") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Gross_NPA") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Provision_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Net_NPA") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="npa_marking_relation"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Close Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan A/C Category
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case IRR
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">LTV</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Due POS
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">ODI</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charges Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Exposer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Reversal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charges Reversal
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gross NPA
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Rate
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assets Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Net NPA
    </th>
  </tr>

  <tr *ngFor="let x of TypeRelationNPA; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Close_Date }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_name }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.Application_Case_IRR }}</td>

    <td>{{ x.Application_LoanTenure }}</td>
    <td>{{ x.Application_LTV }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.Due_On }}</td>
    <td>{{ x.FutureDue_POS }}</td>
    <td>{{ x.OD_Installment }}</td>
    <td>{{ x.Charges_Balance }}</td>
    <td>{{ x.Exposer }}</td>
    <td>{{ x.Interest_Reversal }}</td>
    <td>{{ x.Charges_Reversal }}</td>
    <td>{{ x.Gross_NPA }}</td>
    <td>{{ x.Provision_Rate }}</td>
    <td>{{ x.Assets_Type }}</td>
    <td>{{ x.Provision_Amount }}</td>
    <td>{{ x.Net_NPA }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="14"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureDue_POS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("OD_Installment") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Charges_Balance") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Exposer") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Interest_Reversal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Charges_Reversal") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Gross_NPA") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Provision_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Net_NPA") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
