import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { LosService } from '../../_LOS/services/los.service';
import { LmsService } from '../../_Lms/services/lms.service';
declare var $: any;
import { DialogService } from '../../Shared/dialog.service';

@Component({
  selector: 'app-loan-account-category',
  templateUrl: './loan-account-category.component.html',
  styleUrls: ['./loan-account-category.component.scss']
})
export class LoanAccountCategoryComponent implements OnInit {

  private data: any; private _MasterService; showSpinner: boolean = false;
  private _LmsService;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();


  toppings = new UntypedFormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  profilePicture: string = '';
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  applicationId: any = 95;
  dataCustomerSource: any;
  SelectLoanAccountingCategory: any = '';
  SelectProduct: any = '';
  saveBtn: boolean = true;
  updateBtn: boolean = false;
  Id: number;
  ProductList: any = [];

  displayedCustomerColumns: string[] = ['Number', 'Accounting Category', 'Product', 'Action'];

  constructor(private _Route: Router, private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar, private MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private dialog:DialogService
  ) {
    this._MasterService = MasterService;
    this._LmsService = LmsService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  ngOnInit() {
    this.LMS_GetloanAccounting();
    this.getProductList();
  }


  LMS_GetloanAccounting() {
    this.showSpinner = true;
    this.LmsService.Get_Lms_loanAccountingHeader({ ProductId: 0 }).subscribe((response: any) => {
      this.dataCustomerSource = new MatTableDataSource(response);
    });
  }

  getProductList() {
    this.showSpinner = true;
    this._MasterService.GetProductList({ Emp_Id: this.data.userId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
  }

  onChangeAssignBranch(event: MatCheckboxChange, selectedBranchId) {
    this.BranchesList.map((item) => {
      if (item.BranchId == selectedBranchId) {
        item.isActiveSelect = event.checked;
      }
    });
  }

  SelectLoanProduct(value: string) {
    this.SelectProduct = value;
    $('.check').click(function () {
      $('.check').not(this).prop('checked', false);
    });
  }

  onSaveData() {
    let saveEmployeeDetail = {
      "Loan_Accounting_Category": this.SelectLoanAccountingCategory,
      "Product": this.toppings.value,
      "Int_Id": 0,
      "IsActive": 0
    }
    console.log('saveEmployeeDetail', this.toppings);
    this._LmsService.Save_Lms_loanAccounting({ JSON: JSON.stringify(saveEmployeeDetail) }).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetloanAccounting();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  removeAccountModel(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to delete this Vehicle Model ${data.Loan_Accounting_Category}.`)
    .afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        this.showSpinner = true;
      this._LmsService.Delete_Lms_LoanActMaster({ Int_Id: data.Int_Id }).subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetloanAccounting();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      })
      }
    });
  }

  editLoanAcct(data: any) {

    console.log('editLoanAcct===', data);
    this._LmsService.Get_Lms_ProductByCatId({ Int_Id: data.Int_Id }).subscribe((response) => {
      console.log('Get_Lms_ProductByCatId', response);
    });
    this.SelectLoanAccountingCategory = data.Loan_Accounting_Category.trim();
    //this.SelectProduct = data.Vehicle_Manufacture;
    // if(data.Product=='LAP') {
    //    $('.LAP').attr('checked', 'checked');
    // }
    // else if(data.Product=='LAS') {
    //   $('.LAS').attr('checked', 'checked');
    // }
    // else if(data.Product=='Vehicle') {
    //   $('.Vehicle').attr('checked', 'checked');
    // }
    // else if(data.Product=='Two Wheel') {
    //   $('.TwoWheel').attr('checked', 'checked');
    // }
    // else if(data.Product=='Mortgage') {
    //   $('.Mortgage').attr('checked', 'checked');
    // }
    // else if(data.Product=='MSME') {
    //   $('.MSME').attr('checked', 'checked');
    // }
    this.Id = data.Int_Id;
    this.saveBtn = false;
    this.updateBtn = true;
  }

  UpdateLoanAcct() {
    this.saveBtn = true;
    this.updateBtn = false;
    let update = {
      "Loan_Accounting_Category": this.SelectLoanAccountingCategory,
      "Product": '',
      "Int_Id": this.Id,
      "IsActive": 0
    }
    this._LmsService.Save_Lms_loanAccounting(update).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.LMS_GetloanAccounting();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }



}
