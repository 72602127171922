import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {

    if (value == undefined) {
      return ''
    }
    const mm = value.substr(0, 2);
    const dd = value.substr(2, 2);
    const yyyy = value.substr(4, 4);

    return mm + '-' + dd + '-' + yyyy;
  }
}
