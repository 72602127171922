import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router } from "@angular/router";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MasterService } from "../../Shared/app.Masters.Service";

@Component({
  selector: "app-rpt-hold-inquiry",
  templateUrl: "./rpt-hold-inquiry.component.html",
  styleUrls: ["./rpt-hold-inquiry.component.scss"],
})
export class RptHoldInquiryComponent implements OnInit {
  private _MasterService;
  FilterBranchId: any = "";
  currentUser: any;
  FilterBranchList: any = [];
  private data: any;
  showSpinner: boolean = false;
  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "InquiryId",
    "InquiryNo",
    "Branch",
    "SaleExecutive",
    "CustomerName",
    "CustomerAddress",
    "ContactNumber",
    "Source",
    "LoanAmount",
    "Purpose",
    "HoldOn",
    "HoldReason",
    "NextFollowUp",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  HoldInquiryList: any = [];
  GetBranchesRequestModel: RequestModel = new RequestModel();
  constructor(
    private dataSharingService: DataSharingService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this._MasterService = MasterService;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  ngOnInit() {
    this.dataSharingService.LOSHeaderTitle.next("Hold Inquiry List");
    this.GetBranchesRequestModel.LoginUserId = this.data.userId;
    this._MasterService
      .GetBranchesDropdown(this.GetBranchesRequestModel)
      .subscribe((result) => {
        console.log(result);
        this.FilterBranchList = JSON.parse(JSON.stringify(result));
        this.onChangeFilterStatus();
      });
    this.getInquiryList();
  }

  onChangeFilterStatus() {
    this.getInquiryList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getInquiryList() {
    this.showSpinner = true;
    this.losService
      .GetHoldInquiryList({
        BranchId: this.FilterBranchId == "" ? 0 : parseInt(this.FilterBranchId),
        LoginUserId: this.data.userId,
      })
      .subscribe((res: any) => {
        this.HoldInquiryList = JSON.parse(JSON.stringify(res));

        this.dataSource = new MatTableDataSource(this.HoldInquiryList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", { fileName: `Rpt-hold-list`, sheet: "Hold" });
  }
}
