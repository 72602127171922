import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { PartnerService } from "../services/partner.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-partner-due-list',
  templateUrl: './partner-due-list.component.html',
  styleUrls: ['./partner-due-list.component.scss']
})
export class PartnerDueListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  BranchesList: any = [];
  ProductList: any = [];
  dataSource: any;
  Search: any;
  CheckType: any = "All";
  DueUpto: any;
  ReceiptUpTo: any;

  //DueDateFrom: any = new Date();
  //DueDateTo: any = new Date();

  DueDateFrom: any = new Date();;
  DueDateTo: any = new Date();;
  SelectBranch: any = "";
  SelectProduct: any = "";
  collection_exec: any = "";
  SearchStatus: any = "";
  CollectionExecutiveData: any = [];
  loanSummary: any = {};
  
  Loan_Statement: boolean = false;
  filterValues: any = {
    LoanNo: '',
    ApplicationNo: '',
    CustomerName: '',
    Customer_PhoneNo: '',
    Branch_Name: '',
    Area_Name: '',
    Sub_Area_Name: '',
    CollectionExecutive: ''
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  FyearId: any;
  maxDate: any;
  minDate: any;

  selectedId: any;

  AcCatgList: any = [];
  ACCategType: any = '';
  Loan_Acc_Type: any = 'Co-Lending';

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  displayedColumns: string[] = [
    "PLoan_Id", "PLoanAcNo", "LMS_LoanAcNo", "PLoan_Date", "CustomerName", "LoanAmount", "EMIAmount", "Loan_Tenure", "DPD_Days",
    "OverDUE_EMI", "Total_OverDUE_EMI_Amount", "Total_OverDUE_Principal_Amount",	"overDue_Interest_AMount", "Last_Recv_Date", 
    "Last_Recv_Amount", "TotalEMI_NOS", "Total_EMI_AMount", "Total_Principal_Amount", "Total_Interest_AMount", "EMI_DUE_NOS", "EMI_DUE", 
    "Principal_DUE", "Interest_DUE", "receved_EMI", "Total_Recv_EMI_Amount", "Total_Recv_Principal_Amount",	"Total_Recv_Interest_AMount", 
    "current_Due_EMI_No",	"current_Due_Amount", "Balance_Principle", "Balance_Interest", "Other_Charges", "Penal_Interest", 
    "EMI_Frequency", "current_Due_Date", "Customer_PhoneNo", "Address", "Action"
  ];

  constructor( 
    private lmsService: LmsService,
    private partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private masterService: MasterService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Due List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.getProductDropdown();
    this.getCollectionExecutive();
    this.onChangeType();
  }

  getProductDropdown() {
    this.masterService.GetProductList({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.ProductList = JSON.parse(JSON.stringify(result));
    });
  }
  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }
  getCollectionExecutive() {
    this.lmsService.Get_Lms_CollectionExecutive({ Branch_Id: 0 }).subscribe((result) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(result));
    });
  }

  onSearchDueList() {
    this.showSpinner = true;
    let _finalData = {
      Search: {
        DueUpto: this.DueDateFrom, LoginUserId: this.currentUser.userId, ReceiptUpTo: this.DueDateTo, 
        Type: this.CheckType, BranchId: this.SelectBranch, ProductId: this.SelectProduct, 
        CollectionExecutive: this.collection_exec, AccountingCategory: this.ACCategType
      }
    }
    
    this.partnerservice.Partner_Get_DueList({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      console.log(res);
      if(res.length > 0){
        this.dataSource = new MatTableDataSource( JSON.parse(JSON.stringify(res)) );
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
        this.dataSource.filterPredicate = this.createFilter();
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
        this.showSpinner = false;
        this.dataSource = '';
      }
    });
  }

  onChangeType() {
    this.ACCategType = '';
    this.lmsService.Get_ACC_Category_ForDropdown_By_Type({ Type: this.Loan_Acc_Type }).subscribe((result) => {
      this.AcCatgList = JSON.parse(JSON.stringify(result));
    });
  }

  applyFilter(filterValues: string) {
    if (this.Search == 'LoanNo') {
      this.filterValues.LoanNo = filterValues;
      this.filterValues.ApplicationNo = '';
      this.filterValues.CustomerName = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'ApplicationNo') {
      this.filterValues.ApplicationNo = filterValues;
      this.filterValues.LoanNo = '';
      this.filterValues.CustomerName = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'CustomerName') {
      this.filterValues.CustomerName = filterValues;
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'Customer_PhoneNo') {
      this.filterValues.CustomerName = '';
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = filterValues;
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'Branch_Name') {
      this.filterValues.CustomerName = '';
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = filterValues;
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'Area_Name') {
      this.filterValues.CustomerName = '';
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = filterValues;
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'Sub_Area_Name') {
      this.filterValues.CustomerName = '';
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = filterValues;
      this.filterValues.CollectionExecutive = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    if (this.Search == 'CollectionExecutive') {
      this.filterValues.CustomerName = '';
      this.filterValues.LoanNo = '';
      this.filterValues.ApplicationNo = '';
      this.filterValues.Customer_PhoneNo = '';
      this.filterValues.Branch_Name = '';
      this.filterValues.Area_Name = '';
      this.filterValues.Sub_Area_Name = '';
      this.filterValues.CollectionExecutive = filterValues;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
  }

  createFilter() {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log(searchTerms);
      return data.LoanAcNo.indexOf(searchTerms.LoanNo) !== -1
        && data.Application_No.indexOf(searchTerms.ApplicationNo) !== -1
        && data.CustomerName.indexOf(searchTerms.CustomerName) !== -1
        && data.Customer_PhoneNo.indexOf(searchTerms.Customer_PhoneNo) !== -1
        && data.Branch_Name.indexOf(searchTerms.Branch_Name) !== -1
        && data.Area_Name.indexOf(searchTerms.Area_Name) !== -1
        && data.Sub_Area_Name.indexOf(searchTerms.Sub_Area_Name) !== -1
        && data.CollectionExecutive.indexOf(searchTerms.CollectionExecutive) !== -1;
    }
    return filterFunction;
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Due-List`,
      sheet: "Due-List",
    });
  }

  goToLoanStatement(row) {
    this.loanSummary.LoanNo = row.PLoanAcNo;
    this.loanSummary.LoanId = row.PLoan_Id;
    this.Loan_Statement = true;
    this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
    $('#ViewLoanStatementModel').modal('show');
    $("#ViewLoanStatementModel").css("z-index", "1050");
  }
  onCloseLoanStatementModel() {
    $('#ViewLoanStatementModel').modal('hide');
  }

  totaltypewise(type) {
    if(this.dataSource != undefined){
      if (this.dataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
          sum += Number(this.dataSource.filteredData[i][type]);
        }
        return sum; //(sum > 0) ? sum.toFixed(2) : '';      
      }
      return '';
    }
  }

  public highlightRow(row) {
    this.selectedId = row.PLoan_Id;
  }
  SearchChange() {

  }
}
