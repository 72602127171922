import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from "../../Shared/dialog.service";
import { ToWords } from 'to-words';
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerService } from "../services/partner.service";

@Component({
  selector: 'app-patner-loan-statement',
  templateUrl: './patner-loan-statement.component.html',
  styleUrls: ['./patner-loan-statement.component.scss']
})
export class PatnerLoanStatementComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  tab: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  @Input() loanSummary: any;
  @Input() PageType: any;
  Partner_LoanId: any;

  PLoanSummary: any;
  PLoanType: any;
  FirstEMIDate: any;
  
  constructor(
    private dataSharingService: DataSharingService, 
    private dialog: DialogService, 
    private _Partnerservice: PartnerService,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Loan Statement');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if(this.tab == 'clist'){
      //this.PLoanSummary={"LoanNo":'', "LoanDate": '', "LoanAmount": '' };
      this.PLoanSummary={};
      this.PLoanType = undefined;
    }
    else{
      console.log("loanSummary", this.loanSummary);
      this._Partnerservice.Partner_Get_Loan_Details({ LoanId: this.loanSummary.LoanId }).subscribe((res: any) => {
        console.log("HBNJHBNJ", res);
        this.PLoanSummary = {
          LoanNo : res[0].PLoanAcNo,
          LoanDate : res[0].PLoan_Date,
          LoanAmount : res[0].NetFinance,
          LoanId : this.loanSummary.LoanId,
          ApplicationNo : this.loanSummary.ApplicationNo,
        };   
        this.PLoanType = 'Partner';
        this.FirstEMIDate = res[0].FirstEMIDate; 
        this.tab = 'plist';
      }); 
    }
  }

  changeTabList(tab: any) {
    console.log("Tab", tab);
    if (tab != this.tab) {
      this.tab = tab;
    }
  }
}
