import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
import { ToWords } from "to-words";
import { DialogService } from "../../Shared/dialog.service";

@Component({
  selector: "app-schedule-transaction",
  templateUrl: "./schedule-transaction.component.html",
  styleUrls: ["./schedule-transaction.component.scss"],
})
export class ScheduleTransactionComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  dataSource: any;
  dataSourceCustomer: any;
  displayedColumns: string[] = [];
  displayedColumnsCustomer: string[] = [];
  loading: boolean = false;

  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  voucherModel1: any = { Voucher1: {}, Voucher_Detail1: [] };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SelectType: any = "";
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  //Voucher Variables
  selectedData: any = {};
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  empBranchDropdown: any[] = [];
  Customer: any = {};
  Type: any = "0";
  CustomerList: any[] = [];
  isShowPopupApplicationDetail: boolean = false;
  totalCalculator: any;

  selectedSearchIndex: any;
  JsonData: any[] = [];

  Status: any = "Pending";
  FromDate: any;
  ToDate: any;
  Isedit: boolean = false;
  taxSlabs: any[] = [];
  Shedule_Voucher_Id: any;
  SheduleStatus: any;
  ScheduleId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Schedule Voucher");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumns = [
      "Schedule_Id",
      "Schedule_Name",
      "Schedule_Type",
      "Schedule_Voucher_Date",
      "Schedule_Frequency",
      "Action",
    ];
    this.displayedColumnsCustomer = [
      "CustomerId",
      "Type",
      "Application_No",
      "LoanAcNo",
      "CustomertName",
      "FatherName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "EditAction",
    ];
    //this.GetScheduleTransactionList();

    this.initializeVoucher();
    this.initializeVoucherDetail();
    this.Get_Acc_Accounts_OtherThan_BankCash();
    this.Get_EmployeeBranchForDropdown();

    this.dataSharingService
      .getLmsIsShowApplicationDetail()
      .subscribe((res: any) => {
        if (res) {
          this.isShowPopupApplicationDetail = JSON.parse(res);
        }
      });

    this.Customer.CloseCase = "";
    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
  }

  GetScheduleTransactionList() {
    this.showSpinner = true;
    this.lmsService
      .Acc_get_schedule_transaction_list({
        StatusVal: this.Status,
        From: this.FromDate,
        To: this.ToDate,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));

          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
        this.showSpinner = false;
      });
  }

  AddShedule() {
    this.initializeVoucher();
    this.voucherModel.Voucher_Detail = [];
    this.voucherModel.Voucher.Voucher_Sub_Type = "Loan";
    this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher_Detail.DRAccountId = "";
    this.voucherModel.Voucher_Detail.Voucher_Date = new Date();
    this.Isedit = false;
    this.SheduleStatus = null;

    this.Get_Acc_Accounts_By_Tag();
    this.getTotalCalculation();
    $("#VoucherPopupModel").modal("show");
    $("#VoucherPopupModel").css("z-index", "1050");
  }

  onCloseVoucher() {
    $("#VoucherPopupModel").modal("hide");
  }

  DateChange() {
    if (
      this.voucherModel.Voucher.Schedule_Start != "" &&
      this.voucherModel.Voucher.Schedule_End != ""
    ) {
      if (
        this.voucherModel.Voucher.Schedule_Start >
        this.voucherModel.Voucher.Schedule_End
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Start Date will be greater than to End Date.",
          ...this.configSuccess,
        });
        this.voucherModel.Voucher.Schedule_Start = "";
        this.voucherModel.Voucher.Schedule_End = "";
      }
    }
  }

  //Voucher Popup Data Here
  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      Schedule_Name: "",
      Schedule_Type: "",
      Schedule_Start: "",
      Schedule_End: "",
      Schedule_Amount: "",
      Schedule_Frequency: "",
      LoginUserId: this.currentUser.userId,
    };
    this.voucherModel.Voucher_Detail.Voucher_Tag = "Bank";
    this.voucherModel.Voucher_Detail.Voucher_Date = new Date();
  }
  initializeVoucherDetail() {
    var trtype;
    if (this.voucherModel.Voucher.Schedule_Type == "Journal") {
      trtype =
        this.totalCalculator &&
        this.totalCalculator.totalCR > this.totalCalculator.totalDR
          ? "DR"
          : "CR";
    } else {
      trtype =
        this.voucherModel.Voucher.Schedule_Type == "Payment" ? "DR" : "CR";
    }
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      Voucher_Sub_Type: "Loan",
      AccountId: "",
      BranchId: "",
      TranType: trtype,
      Amount: "",
      IsTax: 0,
      Tax_Per: 0,
      Voucher_Tag: "Bank",
      Voucher_Date: new Date(),
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Narration: "",

      TaxSlab: [],
    });
    setTimeout(() => {
      this.GetVoucherNo();
      this.Get_Acc_Accounts_By_Tag();
    }, 500);
  }

  onChangeSchedule_Type() {
    this.voucherModel.Voucher_Detail = [
      {
        TranType:
          this.voucherModel.Voucher.Schedule_Type == "Payment" ? "DR" : "CR",
      },
    ];
    this.voucherModel.Voucher_Detail.Voucher_Tag = "Bank";
    this.voucherModel.Voucher.Voucher_Sub_Type = "Loan";
    this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.DRAccountId = "";
    this.voucherModel.Voucher_Detail.Voucher_Date = new Date();
    this.Get_Acc_Accounts_By_Tag();
    this.GetVoucherNo();
    this.getTotalCalculation();
    //this.voucherModel.Voucher_Detail.TranType = this.voucherModel.Voucher.Schedule_Type == "Payment" ? "DR" : "CR";
    //this.voucherModel.Voucher_Detail.TranType = this.voucherModel.Voucher.Schedule_Type == "Payment" ? "DR" : "CR";
  }

  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.selectedData.ProductId || 20,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }

  Get_EmployeeBranchForDropdown() {
    this._MasterService
      .Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId })
      .subscribe((res: any) => {
        this.empBranchDropdown = res;
      });
  }
  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService
      .Get_Acc_Accounts_OtherThan_BankCash({})
      .subscribe((res: any) => {
        this.accountHeadDropdown = res;
      });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == "Cheque") {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    } else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  GetVoucherNo() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: this.voucherModel.Voucher.Schedule_Type,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        VoucherDate: this.voucherModel.Voucher_Detail.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
      });
  }
  Get_Acc_Accounts_By_Tag() {
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        LoginUserId: this.currentUser.userId,
        Account_Tag: this.voucherModel.Voucher_Detail.Voucher_Tag,
        Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }

  Get_Acc_Search_LoanNo() {
    this.showSpinner = true;
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));
        if (this.Type != "0") {
          var type;
          if (this.Type == "1") type = "LOS";
          else type = "LMS";
          this.CustomerList = this.CustomerList.filter(
            (item) => item.Type == type
          );
        }
        this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
        this.dataSourceCustomer.sort = this.sort;
        this.dataSourceCustomer.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  selectSearchData(data: any) {
    if (data.LoanAcNo != "" && data.LoanAcNo != null)
      this.selectedData.CaseNo = data.LoanAcNo;
    else this.selectedData.CaseNo = data.Application_No;

    this.selectedData.Customer = data.CustomertName;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.BranchId = data.BranchId;
    this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.Is_LOSAppliaction = data.Type == "LOS" ? "1" : "0";
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.getChargeshead();
    this.OnCloseSearch();
  }
  onVoucherSubTypeChange() {
    this.GetVoucherNo();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    this.voucherModel.Voucher_Detail.Voucher_Tag = "Bank";
    this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher_Detail.Voucher_Date = new Date();

    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherTagChange() {
    this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment =
      this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment || "";
    this.voucherModel.Voucher_Detail.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher_Detail.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher_Detail.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher_Detail.Voucher_Bank_Name =
      this.voucherModel.Voucher_Detail.Voucher_Bank_Name || "";
    this.voucherModel.Voucher_Detail.Voucher_Cheque_Date =
      this.voucherModel.Voucher_Detail.Voucher_Cheque_Date || "";
    this.voucherModel.Voucher_Detail.Voucher_Cheque_No =
      this.voucherModel.Voucher_Detail.Voucher_Cheque_No || "";
    this.voucherModel.Voucher_Detail.Voucher_Date = new Date();
    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherDateChange() {
    this.GetVoucherNo();
    this.Get_Acc_Accounts_By_Tag();
  }

  searchCustomer(data: any, index: any) {
    this.selectedSearchIndex = index;
    this.selectedData = data;
    this.dataSource = null; //new MatTableDataSource([]);
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100);
  }
  OnCloseSearch() {
    $("#CustomerReceipt").modal("hide");
    $("#Customer" + this.selectedSearchIndex).focus();
    this.selectedData.SearchValue = "";
  }
  onChangeChargesHead(data: any, dindex: any) {
    let _find = this.voucherModel.Voucher_Detail.find(
      (obj: any, index: any) => {
        return (
          index != dindex &&
          obj.CaseNo == data.CaseNo &&
          obj.ChargeHeadId == data.ChargeHeadId
        );
      }
    );
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This Account head already selected.",
        ...this.configSuccess,
      });
      data.ChargeHeadId = "";
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = [];
      if (this.voucherModel.Voucher.Voucher_Sub_Type == "Accounting") {
        data.BranchId = "";
      }
      this.getTotalCalculation();
      return;
    }
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        ProductId: data.ProductId || 0,
        TaxType: data.TranType,
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;
            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
          } else {
            data.ChargeHeadId = "";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "category does not have account head.",
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
      });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(
        2
      );
    });
    this.getTotalCalculation();
  }
  onChangeTax() {
    this.getTotalCalculation();
  }
  addMoreData() {
    this.initializeVoucherDetail();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    this.getTotalCalculation();
  }
  saveVoucher() {
    //let _vouvher: any [] = [];
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _voucheretail: any[] = [];
    let _find = this.accountTagDropdown.find((obj: any) => {
      return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
    });

    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    let _totalDR: any = 0;
    let _totalTXDR: any = 0;
    let _totalTXCR: any = 0;

    if (this.voucherModel.Voucher.Schedule_Type != "Journal") {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        _total = _total + parseFloat(obj.Amount);
        _totalCR = _totalCR + parseFloat(obj.Amount);
        _voucheretail.push(
          this.dataSharingService.cleanObject({
            CaseNo: obj.CaseNo,
            CustomerId: obj.CustomerId,
            ChargeHeadId: obj.ChargeHeadId,
            Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
            ChargeHeadCategory: obj.ChargeHeadCategory,
            AccountId: obj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.TranType,
            IsTax: obj.IsTax,
            Tax_Per: obj.Tax_Per,
            Amount: obj.Amount,
            Voucher_Tag: this.voucherModel.Voucher_Detail.Voucher_Tag,
            Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
            Voucher_Mode_of_Payment:
              this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
            Voucher_Total_Credit_Amount: _totalCR,
            Voucher_Total_Debit_Amount: _totalCR,
            Voucher_Total_Tax: _totalTX,
            Voucher_Cheque_No:
              this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
            Voucher_Cheque_Date:
              this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
            Voucher_Bank_Name:
              this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
            //Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
          })
        );
        obj.TaxSlab.forEach((tobj: any) => {
          _total = _total + parseFloat(tobj.TaxAmount);
          _totalTX = _totalTX + parseFloat(tobj.TaxAmount);
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              ChargeHeadId: obj.ChargeHeadId,
              Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: obj.BranchId,
              TranType: obj.TranType,
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: tobj.TaxAmount,
              Voucher_Tag: this.voucherModel.Voucher_Detail.Voucher_Tag,
              Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
              Voucher_Mode_of_Payment:
                this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
              Voucher_Total_Credit_Amount: _totalCR,
              Voucher_Total_Debit_Amount: _totalCR,
              Voucher_Total_Tax: _totalTX,
              Voucher_Cheque_No:
                this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
              Voucher_Cheque_Date:
                this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
              Voucher_Bank_Name:
                this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
              //Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
            })
          );
        });
      });

      _voucheretail.push(
        this.dataSharingService.cleanObject({
          CaseNo: "",
          CustomerId: "",
          ChargeHeadId: "",
          Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
          ChargeHeadCategory: _find.Account_Name,
          AccountId: _find.AccountId,
          BranchId: "",
          TranType:
            this.voucherModel.Voucher.Schedule_Type == "Receipt" ? "DR" : "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: _total,
          Voucher_Tag: this.voucherModel.Voucher_Detail.Voucher_Tag,
          Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
          Voucher_Mode_of_Payment:
            this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
          Voucher_Total_Credit_Amount: _totalCR,
          Voucher_Total_Debit_Amount: _totalCR,
          Voucher_Total_Tax: _totalTX,
          Voucher_Cheque_No: this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
          Voucher_Cheque_Date:
            this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
          Voucher_Bank_Name: this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
          //Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
        })
      );
    } else {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        if (obj.TranType == "CR") {
          _totalCR = _totalCR + parseFloat(obj.Amount);
        } else {
          _totalDR = _totalDR + parseFloat(obj.Amount);
        }

        _voucheretail.push(
          this.dataSharingService.cleanObject({
            CaseNo: obj.CaseNo,
            CustomerId: obj.CustomerId,
            ChargeHeadId: obj.ChargeHeadId,
            Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
            ChargeHeadCategory: obj.ChargeHeadCategory,
            AccountId: obj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.TranType,
            IsTax: obj.IsTax,
            Tax_Per: obj.Tax_Per,
            Amount: obj.Amount,
            Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
            Voucher_Mode_of_Payment:
              this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
            Voucher_Total_Credit_Amount: obj.TranType == "CR" ? _totalCR : "",
            Voucher_Total_Debit_Amount: obj.TranType == "DR" ? _totalDR : "",
            Voucher_Total_Tax: _totalTX,
            Voucher_Cheque_No:
              this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
            Voucher_Cheque_Date:
              this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
            Voucher_Bank_Name:
              this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
            //Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
          })
        );
        obj.TaxSlab.forEach((tobj: any) => {
          if (obj.TranType == "CR") {
            _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
            _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
          } else {
            _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
            _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
          }
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              ChargeHeadId: obj.ChargeHeadId,
              Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: obj.BranchId,
              TranType: obj.TranType,
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: tobj.TaxAmount,
              Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
              Voucher_Mode_of_Payment:
                this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
              Voucher_Total_Credit_Amount: obj.TranType == "CR" ? _totalCR : "",
              Voucher_Total_Debit_Amount: obj.TranType == "DR" ? _totalDR : "",
              Voucher_Total_Tax: _totalTX,
              Voucher_Cheque_No:
                this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
              Voucher_Cheque_Date:
                this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
              Voucher_Bank_Name:
                this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
              //Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
            })
          );
        });
      });

      if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total Debit Amount must be equal to Total Credit Amount.",
          ...this.configSuccess,
        });
        return;
      }
    }

    if (this.voucherModel.Voucher.Schedule_Type != "Journal") {
      _voucheretail.forEach(function (r) {
        r.Voucher_Total_Credit_Amount = _totalCR;
        r.Voucher_Total_Debit_Amount = _totalCR;
      });
    } else {
      _voucheretail.forEach(function (r) {
        r.Voucher_Total_Credit_Amount = _totalCR;
        r.Voucher_Total_Debit_Amount = _totalDR;
      });
    }

    delete _vouvher.DRAccountId;
    delete _vouvher.Voucher_No;
    delete _vouvher.Voucher_Sub_Type;

    //_vouvher = JSON.parse(this.voucherModel.Voucher);

    //_vouvher = { ...this.voucherModel.Voucher };
    //_vouvher.Voucher_Total_Credit_Amount = _totalCR;
    //_vouvher.Voucher_Total_Debit_Amount = _total;
    //_vouvher.Voucher_Total_Tax = _totalTX;

    let _finalData = {
      schedule_transaction: this.dataSharingService.cleanObject({
        ..._vouvher,
      }),
      schedule_transaction_Detail: _voucheretail,
    };
    //console.log("_finalData", JSON.stringify(_finalData));
    this.lmsService
      .Acc_save_schedule_transaction({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.initializeVoucher();
          this.voucherModel.Voucher_Detail = [];
          this.initializeVoucherDetail();
          this.totalCalculator = undefined;
          this.onCloseVoucher();
          //this.GetScheduleTransactionList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;

    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;

    if (this.voucherModel.Voucher.Schedule_Type != "Journal") {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        _total = _total + parseFloat(obj.Amount) || 0;
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
        obj.TaxSlab.forEach((tobj: any) => {
          _total = _total + parseFloat(tobj.TaxAmount) || 0;
          _totalTX = _totalTX + parseFloat(tobj.TaxAmount) || 0;
        });
      });
      this.totalCalculator = {
        total: parseFloat(_total),
        totalCR: parseFloat(_totalCR),
        totaltax: parseFloat(_totalTX),
        totalInWord: new ToWords().convert(parseFloat(_total)),
      };
    } else {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        if (obj.TranType == "CR") {
          _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
        } else {
          _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
        }
        obj.TaxSlab.forEach((tobj: any) => {
          if (obj.TranType == "CR") {
            _totalCR = _totalCR + parseFloat(tobj.TaxAmount) || 0;
            _totalCRTX = _totalCRTX + parseFloat(tobj.TaxAmount) || 0;
          } else {
            _totalDR = _totalDR + parseFloat(tobj.TaxAmount) || 0;
            _totalDRTX = _totalDRTX + parseFloat(tobj.TaxAmount) || 0;
          }
        });
      });
      this.totalCalculator = {
        totalCR: parseFloat(_totalCR),
        totalDR: parseFloat(_totalDR),
        totalCRTX: parseFloat(_totalCRTX),
        totalDRTX: parseFloat(_totalDRTX),
        totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
        totalDRInWord: new ToWords().convert(parseFloat(_totalDR)),
      };
    }
  }

  onChangeCRDR(data: any) {
    data.CaseNo = "";
    data.CustomerId = "";
    data.ChargeHeadId = "";
    data.ChargeHeadCategory = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer = "";
    data.Branch_Name = "";
    data.TaxSlab = [];
    this.getTotalCalculation();
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "schedule_transaction"
    ) as HTMLTableElement;
    const worksheetName = "SCHEDULE TRANSACTION"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  goToDetail(row: any) {
    this.showSpinner = true;
    this.Shedule_Voucher_Id = row.Id;
    this.ScheduleId = row.Schedule_Id;

    this.SheduleStatus = row.Schedule_Voucher_Status;
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucher1();
    this.lmsService
      .ACC_Get_Schedule_Transaction_Detail({ VoucherId: row.Id })
      .subscribe((res: any) => {
        //console.log("RESDDD : ",res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        this.voucherModel.Voucher.Schedule_Start = new Date(
          res.Item1[0].Schedule_Start
        );
        this.voucherModel.Voucher.Schedule_End = new Date(
          res.Item1[0].Schedule_End
        );

        this.voucherModel.Voucher.Voucher_Sub_Type =
          res.Item2[0].Voucher_Sub_Type == null
            ? ""
            : res.Item2[0].Voucher_Sub_Type;
        this.Isedit = true;

        if (this.voucherModel.Voucher_Detail.Voucher_Cheque_Date != null)
          this.voucherModel.Voucher_Detail.Voucher_Cheque_Date = new Date(
            moment(this.voucherModel.Voucher_Detail.VoucherChequeDate).format(
              "MM/DD/YYYY"
            )
          );

        if (this.voucherModel.Voucher_Detail.Voucher_ReceiptDate != null)
          this.voucherModel.Voucher_Detail.Voucher_ReceiptDate = new Date(
            moment(this.voucherModel.Voucher_Detail.VoucherReceiptDate).format(
              "MM/DD/YYYY"
            )
          );

        if (this.voucherModel.Voucher_Detail.Voucher_Effective_Date != null)
          this.voucherModel.Voucher_Detail.Voucher_Effective_Date = new Date(
            moment(
              this.voucherModel.Voucher_Detail.VoucherEffectiveDate
            ).format("MM/DD/YYYY")
          );

        /*if (this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment == null) {
        this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment = "";
      }*/
        if (this.voucherModel.Voucher_Detail.Voucher_Cheque_Clear_Date != null)
          this.voucherModel.Voucher_Detail.Voucher_Cheque_Clear_Date = new Date(
            moment(
              this.voucherModel.Voucher_Detail.VoucherChequeClearDate
            ).format("MM/DD/YYYY")
          );

        this.taxSlabs = this.voucherModel.Voucher_Detail.filter(
          (item) => item.IsTax === true
        );

        if (this.voucherModel.Voucher.Schedule_Type == "Receipt") {
          this.voucherModel.Voucher.DRAccountId =
            this.voucherModel.Voucher_Detail.find(
              (result) => result.TranType === "DR"
            ).AccountId;
          this.voucherModel.Voucher_Detail =
            this.voucherModel.Voucher_Detail.filter(
              (item) => item.TranType === "CR" && item.IsTax != true
            );
        }
        if (this.voucherModel.Voucher.Schedule_Type == "Payment") {
          this.voucherModel.Voucher.DRAccountId =
            this.voucherModel.Voucher_Detail.find(
              (result) => result.TranType === "CR"
            ).AccountId;
          this.voucherModel.Voucher_Detail =
            this.voucherModel.Voucher_Detail.filter(
              (item) => item.TranType === "DR" && item.IsTax != true
            );
        }

        this.voucherModel.Voucher_Detail =
          this.voucherModel.Voucher_Detail.filter((item) => item.IsTax != true);
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          if (obj.TaxSlab == undefined) obj.TaxSlab = [];
          if (this.voucherModel.Voucher.Voucher_Sub_Type == "Accounting") {
            obj.ChargeHeadId = obj.AccountId;
          }
        });

        this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment =
          res.Item2[0].Voucher_Mode_of_Payment == null
            ? ""
            : res.Item2[0].Voucher_Mode_of_Payment;
        this.voucherModel.Voucher_Detail.Voucher_Cheque_No =
          res.Item2[0].Voucher_Cheque_No == null
            ? ""
            : res.Item2[0].Voucher_Cheque_No;
        this.voucherModel.Voucher_Detail.Voucher_Cheque_Date =
          res.Item2[0].Voucher_Cheque_Date == null
            ? ""
            : new Date(
                moment(
                  this.voucherModel.Voucher_Detail.Voucher_Cheque_Date
                ).format("MM/DD/YYYY")
              );
        this.voucherModel.Voucher_Detail.Voucher_Bank_Name =
          res.Item2[0].Voucher_Bank_Name == null
            ? ""
            : res.Item2[0].Voucher_Bank_Name;

        this.voucherModel.Voucher_Detail.Voucher_Date = new Date(
          moment(this.voucherModel.Voucher.Schedule_Voucher_Date).format(
            "MM/DD/YYYY"
          )
        );
        this.voucherModel.Voucher_Detail.Voucher_Tag =
          res.Item2[0].Voucher_Tag == null ? "" : res.Item2[0].Voucher_Tag;

        this.taxSlab(this.taxSlabs);

        this.Get_Acc_Accounts_OtherThan_BankCash();
        this.getChargeshead();
        this.Get_Acc_Accounts_By_Tag();
        this.GetVoucherNo();

        //this.getTotalCalculation();

        $("#VoucherPopupModel").modal("show");
        $("#VoucherPopupModel").css("z-index", "1050");

        this.showSpinner = false;
      });
  }

  taxSlab(taxSlabs) {
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      this.lmsService
        .Get_AccountHead_For_VoucherEntry({
          HeadId: obj.ChargeHeadId,
          VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
          ProductId: obj.PrductId || 20,
          TaxType: obj.TranType,
        })
        .subscribe((res: any) => {
          if (res.Item1[0]) {
            if (res.Item1[0].AccountId) {
              obj.AccountId = res.Item1[0].AccountId;
              obj.ChargeHeadCategory = res.Item1[0].AccountName;
              obj.TaxSlab = res.Item2;
              if (taxSlabs != undefined && taxSlabs.length > 0) {
                obj.TaxSlab.forEach((data: any) => {
                  data.TaxAmount = taxSlabs.find(
                    (result) =>
                      result.CaseNo === obj.CaseNo &&
                      result.AccountId == data.TaxAccountId
                  ).Amount;
                });
              }
            } else {
              obj.TaxSlab = [];
            }
            this.getTotalCalculation();
          } else {
            obj.TaxSlab = [];
          }
        });
    });
  }

  initializeVoucher1() {
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      Voucher_Type: "",
      Voucher_Sub_Type: "",
      Voucher_Tag: "",
      Voucher_Date: "",
      Voucher_Effective_Date: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      LoginUserId: this.currentUser.userId,
      Voucher_LoanId: "",
    };
  }
  /*initializeVoucherDetail1() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      Is_LOSAppliaction: "0",
      LOS_TransactionId: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: "",
      TranType: this.voucherModel.Voucher.Voucher_Type == "Receipt" ? "CR" : this.voucherModel.Voucher.Voucher_Type == "Payment" ? "DR" : this.voucherModel.Voucher.Voucher_Type == "Journal" ? this.totalCalculator && this.totalCalculator.totalCR > this.totalCalculator.totalDR ? 'DR' : 'CR':"",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: []
    });
  }*/
  saveVoucher1() {
    let _vouvher: any = {};
    //let _vouvher: any = { ...this.voucherModel.Voucher };
    let _voucheretail: any[] = [];
    let _find = this.accountTagDropdown.find((obj: any) => {
      return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
    });

    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    let _totalDR: any = 0;
    let _totalTXDR: any = 0;
    let _totalTXCR: any = 0;

    if (this.voucherModel.Voucher.Schedule_Type != "Journal") {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        _total = _total + parseFloat(obj.Amount);
        _totalCR = _totalCR + parseFloat(obj.Amount);
        _voucheretail.push(
          this.dataSharingService.cleanObject({
            VoucherId: 0,
            CaseNo: obj.CaseNo,
            CustomerId: obj.CustomerId,
            Is_LOSAppliaction: obj.Is_LOSAppliaction,
            LOS_TransactionId: obj.Is_LOSAppliaction,
            ChargeHeadId: obj.ChargeHeadId,
            ChargeHeadCategory: obj.ChargeHeadCategory,
            AccountId: obj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.TranType,
            IsTax: obj.IsTax,
            Tax_Per: obj.Tax_Per,
            Amount: obj.Amount,
          })
        );
        obj.TaxSlab.forEach((tobj: any) => {
          _total = _total + parseFloat(tobj.TaxAmount);
          _totalCR = _totalCR + parseFloat(obj.TaxAmount);
          _totalTX = _totalTX + parseFloat(tobj.TaxAmount);
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              Is_LOSAppliaction: obj.Is_LOSAppliaction,
              LOS_TransactionId: obj.Is_LOSAppliaction,
              ChargeHeadId: obj.ChargeHeadId,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: obj.BranchId,
              TranType: obj.TranType,
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: tobj.TaxAmount,
            })
          );
        });
      });

      _voucheretail.push(
        this.dataSharingService.cleanObject({
          VoucherId: 0,
          CaseNo: "",
          CustomerId: "",
          Is_LOSAppliaction: "",
          ChargeHeadId: "",
          ChargeHeadCategory: _find.Account_Name,
          AccountId: _find.AccountId,
          BranchId: "",
          TranType:
            this.voucherModel.Voucher.Schedule_Type == "Receipt" ? "DR" : "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: _total,
        })
      );
    } else {
      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        if (obj.TranType == "CR") {
          _totalCR = _totalCR + parseFloat(obj.Amount);
        } else {
          _totalDR = _totalDR + parseFloat(obj.Amount);
        }

        _voucheretail.push(
          this.dataSharingService.cleanObject({
            VoucherId: 0,
            CaseNo: obj.CaseNo,
            CustomerId: obj.CustomerId,
            Is_LOSAppliaction: obj.Is_LOSAppliaction,
            LOS_TransactionId: obj.Is_LOSAppliaction,
            ChargeHeadId: obj.ChargeHeadId,
            ChargeHeadCategory: obj.ChargeHeadCategory,
            AccountId: obj.AccountId,
            BranchId: obj.BranchId,
            TranType: obj.TranType,
            IsTax: obj.IsTax,
            Tax_Per: obj.Tax_Per,
            Amount: obj.Amount,
          })
        );
        obj.TaxSlab.forEach((tobj: any) => {
          if (obj.TranType == "CR") {
            _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
            _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
          } else {
            _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
            _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
          }
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              Is_LOSAppliaction: obj.Is_LOSAppliaction,
              LOS_TransactionId: obj.Is_LOSAppliaction,
              ChargeHeadId: obj.ChargeHeadId,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: obj.BranchId,
              TranType: obj.TranType,
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: tobj.TaxAmount,
            })
          );
        });
      });

      if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total Debit Amount must be equal to Total Credit Amount.",
          ...this.configSuccess,
        });
        return;
      }
    }

    //delete _vouvher.DRAccountId;
    //delete _vouvher.Voucher_No;
    //delete _vouvher.Voucher_Sub_Type;

    _vouvher = {
      VoucherId: 0,
      Voucher_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      Voucher_Type: this.voucherModel.Voucher.Schedule_Type,
      Voucher_Sub_Type: this.voucherModel.Voucher.Voucher_Sub_Type,
      Voucher_Tag: this.voucherModel.Voucher_Detail.Voucher_Tag,
      Voucher_Date: this.voucherModel.Voucher_Detail.Voucher_Date,
      Voucher_Effective_Date: "",
      Voucher_Mode_of_Payment:
        this.voucherModel.Voucher_Detail.Voucher_Mode_of_Payment,
      Voucher_Total_Credit_Amount:
        this.voucherModel.Voucher.Schedule_Type == "Journal"
          ? _totalCR
          : _total,
      Voucher_Total_Debit_Amount:
        this.voucherModel.Voucher.Schedule_Type == "Journal"
          ? _totalDR
          : _total,
      Voucher_Total_Tax: _totalTX,
      Voucher_Narration: this.voucherModel.Voucher_Detail.Voucher_Narration,
      Voucher_Cheque_No: this.voucherModel.Voucher_Detail.Voucher_Cheque_No,
      Voucher_Cheque_Date: this.voucherModel.Voucher_Detail.Voucher_Cheque_Date,
      Voucher_Bank_Name: this.voucherModel.Voucher_Detail.Voucher_Bank_Name,
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      LoginUserId: this.currentUser.userId,
      Voucher_LoanId: "",
      Schedule_VoucherId: this.Shedule_Voucher_Id,
    };

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: _voucheretail,
    };
    //console.log("_finalData", JSON.stringify(_finalData));
    this.lmsService
      .Save_Acc_Schedule_VoucherMaster({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.totalCalculator = undefined;
          this.onCloseVoucher();
          this.GetScheduleTransactionList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  deleteSheduleVoucher() {
    this.dialog
      .openConfirmDialog(
        `Are you sure you want to remove this Shedule Voucher.`
      )
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .ACC_Delete_Schedule_Voucher({ VoucherId: this.Shedule_Voucher_Id })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.totalCalculator = undefined;
                this.onCloseVoucher();
                this.GetScheduleTransactionList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  DeactivateShedule() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to deactivate this Shedule.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .ACC_Deactivate_Schedule_Transaction({
              Schedule_Id: this.ScheduleId,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.totalCalculator = undefined;
                this.onCloseVoucher();
                this.GetScheduleTransactionList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
