import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { MasterService } from "../../Shared/app.Masters.Service";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

import { Workbook } from "exceljs";
import * as fs from "file-saver";
import * as moment from 'moment';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-cibil-commercial",
  templateUrl: "./cibil-commercial.component.html",
  styleUrls: ["./cibil-commercial.component.scss"],
})
export class CibilCommercialReportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  textString:any;
  ToDate: any = new Date();
  DataSourceBS: any;
  DataSourceAS: any;
  DataSourceRS: any;
  DataSourceCR: any;
  DataSourceGS: any;
  DataSourceSS: any;
  DataSourceCD: any;
  DataSourceTS: any;
  Show: any = false;
  JSONData: any;
  JSONASData: any[] = [];
  CRCModel: any = { MemberId: "", PreviousMemberId: ""};
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumnsBS: string[] = [
    "LoanAcNo",
    "Flag",
    "Segment_Identifier",
    "Member_Branch_Code",
    "Previous_Member_Branch_Code",
    "Borrowers_Name",
    "Borrower_Short_Name",
    "Company_Registration_Number",
    "Date_of_Incorporation",
    "PAN",
    "CIN",
    "TIN",
    "Service_Tax",
    "Other_ID",
    "Borrowers_Legal_Constitution",
    "BusinessCategory",
    "BusinessType",
    "Borrowers_Legal_Constitution_Code",
    "BusinessCategory_Code",
    "BusinessType_Code",
    "Class_of_Activity_1",
    "Class_of_Activity_2",
    "Class_of_Activity_3",
    "SIC_Code",
    "Sales_Figure",
    "Financial_Year",
    "Number_of_Employees",
    "Credit_Rating",
    "Assessment_Agency_Authority",
    "Credit_Rating_As_On",
    "Credit_Rating_Expiry_Date",
  ];

  displayedColumnsAS: string[] = [
    "LoanAcNo",
    "Flag",
    "Segment_Identifier",
    "Borrower_Office_Location_Type",
    "Borrower_Office_DUNS_Number",
    "Address_Line_1",
    "Address_Line_2",
    "Address_Line_3",
    "City_Town",
    "District",
    "State_Union_Territory",
    "Pin_Code",
    "Country",
    "Mobile_Number",
    "Telephone_Area_Code",
    "Telephone_Number",
    "Fax_Area_Code",
    "Fax_Number",
  ];

  displayedColumnsRS: string[] = [
    "LoanAcNo",
    "Flag",
    "Segment_Identifier",
    "Relationship_DUNS_Number",
    "Related_Type",
    "Relationship",
    "Relationship_Name",
    "Business_Entity_Name",
    "BusinessCategory_Code",
    "BusinessType_Code",
    "Individual_Name_Prefix",
    "Full_Name",
    "Gender",
    "Company_Registration_Number",
    "Date_of_Incorporation",
    "Date_of_Birth",
    "PAN",
    "Voter_ID",
    "Passport_Number",
    "Driving_Licence_ID",
    "UID",
    "Ration_Card_No",
    "CIN",
    "DIN",
    "TIN",
    "Service_Tax",
    "Other_ID",
    "Percentage_of_Control",
    "Address_Line_1",
    "Address_Line_2",
    "Address_Line_3",
    "City_Town",
    "District",
    "State_Union_Territory",
    "Pin_Code",
    "Country",
    "Mobile_Number",
    "Telephone_Area_Code",
    "Telephone_Number",
    "Fax_Area_Code",
    "Fax_Number",
  ];

  displayedColumnsCR: string[] = [
    "Ac_No",
    "Flag",
    "Segment_Identifier",
    "Account_Number",
    "Previous_Account_Number",
    "Facility_Loan_Activation_Sanction_Date",
    "Sanctioned_Amount_Notional_Amount_of_Contract",
    "Currency_Code",
    "Credit_Type",
    "Tenure_Weighted_Average_maturity_period_of_Contracts",
    "Repayment_Frequency",
    "Drawing_Power",
    "Current_Balance_Limit_Utilized_Mark_to_Market",
    "Notional_Amount_of_Out_standing_Restructured_Contracts",
    "Loan_Expiry_Maturity_Date",
    "Loan_Renewal_Date",
    "Asset_Classification_Number_of_days_past_due_NDPD",
    "Asset_Classification_Date",
    "Amount_Overdue_Limit_Overdue",
    "Overdue_Bucket_01_1_30_days",
    "Overdue_Bucket_02_31_60_days",
    "Overdue_Bucket_03_61_90_days",
    "Overdue_Bucket_04_91_180_days",
    "Overdue_Bucket_05_Above_180_days",
    "High_Credit",
    "Installment_Amount",
    "Last_Repaid_Amount",
    "Account_Status",
    "Account_Status_Date",
    "Written_Off_Amount",
    "Settled_Amount",
    "Major_reasons_for_Restructuring",
    "Amount_of_Contracts_Classified_as_NPA",
    "Asset_based_Security_coverage",
    "Guarantee_Coverage",
    "Bank_Remark_Code",
    "Wilful_Default_Status",
    "Date_Classified_as_Wilful_Default",
    "Suit_Filed_Status",
    "Suit_Reference_Number",
    "Suit_Amount_in_Rupees",
    "Date_of_Suit",
    "Dispute_ID_No",
    "Transaction_Type_Code",
    "OTHER_BK",
    "UFCE_Amount",
    "UFCE_Date",
  ];

  displayedColumnsGS: string[] = [
    "A_c_No",
    "Flag",
    "Segment_Identifier",
    "Guarantor_DUNS_Number",
    "Guarantor_Type",
    "Business_Category",
    "Business_Industry_Type",
    "Guarantor_Entity_Name",
    "Individual_Name_Prefix",
    "Full_Name",
    "Gender",
    "Company_Registration_Number",
    "Date_of_Incorporation",
    "Date_of_Birth",
    "PAN",
    "Voter_ID",
    "Passport_Number",
    "Driving_Licence_ID",
    "UID",
    "Ration_Card_No",
    "CIN",
    "DIN",
    "TIN",
    "Service_Tax",
    "Other_ID",
    "Address_Line_1",
    "Address_Line_2",
    "Address_Line_3",
    "City_Town",
    "District",
    "State_Union_Territory",
    "Pin_Code",
    "Country",
    "Mobile_Number",
    "Telephone_Area_Code",
    "Telephone_Number",
    "Fax_Area_Code",
    "Fax_Number",
  ];

  displayedColumnsSS: string[] = [
    "A_c_No",
    "Flag",
    "Segment_Identifier",
    "Value_of_Security",
    "Currency_Type",
    "Type_of_Security",
    "Security_Classification",
    "Date_of_Valuation",
  ];

  displayedColumnsCD: string[] = [
    "A_c_No",
    "Flag",
    "Segment_Identifier",
    "Date_of_Dishonour",
    "Amount",
    "Instrument_Cheque_Number",
    "Number_of_times",
    "dishonoured_Cheque_Issue_Date",
    "Reason_for_Dishonour",
  ];

  displayedColumnsTS: string[] = [
    "Segment_identifier",
    "Number_of_Borrower_Segments",
    "Number_of_Credit_Facility_Segments",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private masterService: MasterService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Cibil Commercial");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  onSearchReport() {
    this.showSpinner = true;
    this.reportsService
      .Report_Cibil_Commercial({ ReportDate: this.ToDate })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.Item1.length > 0) {
          this.Show = true;

          this.JSONData = res;

          this.DataSourceBS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item1))
          );
          this.DataSourceAS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item2))
          );
          this.DataSourceRS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item3))
          );
          this.DataSourceCR = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item4))
          );
          this.DataSourceGS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item5))
          );
          this.DataSourceSS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item6))
          );
          this.DataSourceCD = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item7))
          );
          this.DataSourceTS = new MatTableDataSource(
            JSON.parse(JSON.stringify(res.Item8))
          );
        } else {
          this.Show = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  // Excel export in backend start here
  public exportToExcel(): void {
    const worksheetBS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item1
    );
    const worksheetAS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item2
    );
    const worksheetRS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item3
    );
    const worksheetCR: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item4
    );
    const worksheetGS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item5
    );
    const worksheetSS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item6
    );
    const worksheetCD: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item7
    );
    const worksheetTS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.JSONData.Item8
    );

    const workbook: XLSX.WorkBook = {
      Sheets: {
        BS: worksheetBS,
        AS: worksheetAS,
        RS: worksheetRS,
        CR: worksheetCR,
        GS: worksheetGS,
        SS: worksheetSS,
        CD: worksheetCD,
        TS: worksheetTS,
      },
      SheetNames: ["BS", "AS", "RS", "CR", "GS", "SS", "CD", "TS"],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      "Cibil_Commercial" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  dowloadTxtFile(){
    this.textString = 'HD|';
    this.textString = this.textString + this.CRCModel.MemberId+'|';
    this.textString = this.textString + this.CRCModel.PreviousMemberId+'|';
    this.textString = this.textString + moment(new Date()).format('DDMMYYYY')+'|';
    this.textString =this.textString +  moment(this.ToDate).format('DDMMYYYY')+'|';
    this.textString = this.textString + '01||';

    for (var i = 0; i < this.JSONData.Item1.length; i++) {

      this.textString = this.textString +'BS|';
      this.textString = this.textString +this.JSONData.Item1[i].Member_Branch_Code!='' && this.JSONData.Item1[i].Member_Branch_Code!=null?this.JSONData.Item1[i].Member_Branch_Code+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Previous_Member_Branch_Code!='' && this.JSONData.Item1[i].Previous_Member_Branch_Code!=null?this.JSONData.Item1[i].Previous_Member_Branch_Code.toString()+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Borrowers_Name!='' && this.JSONData.Item1[i].Borrowers_Name!=null?this.JSONData.Item1[i].Borrowers_Name.toString()+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Borrower_Short_Name!='' && this.JSONData.Item1[i].Borrower_Short_Name!=null?this.JSONData.Item1[i].Borrower_Short_Name.toString()+'|':'|';
      
      this.textString = this.textString +this.JSONData.Item1[i].Company_Registration_Number!='' && this.JSONData.Item1[i].Company_Registration_Number!=null?this.JSONData.Item1[i].Company_Registration_Number.toString()+'|':+'|';
      this.textString = this.textString +this.JSONData.Item1[i].Date_of_Incorporation!='' && this.JSONData.Item1[i].Date_of_Incorporation!=null? moment(this.JSONData.Item1[i].Date_of_Incorporation).format('DDMMYYYY').toString()+'|':+'|';

      this.textString = this.textString +this.JSONData.Item1[i].PAN!='' && this.JSONData.Item1[i].PAN!=null?this.JSONData.Item1[i].PAN+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].CIN!='' && this.JSONData.Item1[i].CIN!=null?this.JSONData.Item1[i].CIN+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].TIN!='' && this.JSONData.Item1[i].TIN!=null?this.JSONData.Item1[i].TIN+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Service_Tax!='' && this.JSONData.Item1[i].Service_Tax!=null?this.JSONData.Item1[i].Service_Tax+'|':'|';

      this.textString = this.textString +this.JSONData.Item1[i].Other_ID!='' && this.JSONData.Item1[i].Other_ID!=null?this.JSONData.Item1[i].Other_ID+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Borrowers_Legal_Constitution_Code!='' && this.JSONData.Item1[i].Borrowers_Legal_Constitution_Code!=null?this.JSONData.Item1[i].Borrowers_Legal_Constitution_Code+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].BusinessCategory_Code!='' && this.JSONData.Item1[i].BusinessCategory_Code!=null?this.JSONData.Item1[i].BusinessCategory_Code+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].BusinessType_Code!='' && this.JSONData.Item1[i].BusinessType_Code!=null?this.JSONData.Item1[i].BusinessType_Code+'|':'|';
      
      this.textString = this.textString +this.JSONData.Item1[i].Class_of_Activity_1!='' && this.JSONData.Item1[i].Class_of_Activity_1!=null?this.JSONData.Item1[i].Class_of_Activity_1+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Class_of_Activity_2!='' && this.JSONData.Item1[i].Class_of_Activity_2!=null?this.JSONData.Item1[i].Class_of_Activity_2+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Class_of_Activity_3!='' && this.JSONData.Item1[i].Class_of_Activity_3!=null?this.JSONData.Item1[i].Class_of_Activity_3+'|':'|';
      	
      this.textString = this.textString +this.JSONData.Item1[i].SIC_Code!='' && this.JSONData.Item1[i].SIC_Code!=null?this.JSONData.Item1[i].SIC_Code+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Sales_Figure!='' && this.JSONData.Item1[i].Sales_Figure!=null?this.JSONData.Item1[i].Sales_Figure+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Financial_Year!='' && this.JSONData.Item1[i].Financial_Year!=null?this.JSONData.Item1[i].Financial_Year+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Number_of_Employees!='' && this.JSONData.Item1[i].Number_of_Employees!=null?this.JSONData.Item1[i].Number_of_Employees+'|':'|';


      this.textString = this.textString +this.JSONData.Item1[i].Credit_Rating!='' && this.JSONData.Item1[i].Credit_Rating!=null?this.JSONData.Item1[i].Credit_Rating+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Assessment_Agency_Authority!='' && this.JSONData.Item1[i].Assessment_Agency_Authority!=null?this.JSONData.Item1[i].Assessment_Agency_Authority+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Credit_Rating_As_On!='' && this.JSONData.Item1[i].Credit_Rating_As_On!=null?moment(this.JSONData.Item1[i].Credit_Rating_As_On).format('DDMMYYYY').toString()+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].Credit_Rating_Expiry_Date!='' && this.JSONData.Item1[i].Credit_Rating_Expiry_Date!=null?this.JSONData.Item1[i].Credit_Rating_Expiry_Date+'|':'|';
      this.textString = this.textString +this.JSONData.Item1[i].CKYC!='' && this.JSONData.Item1[i].CKYC!=null?this.JSONData.Item1[i].CKYC+'|':'|';
      var ASSegment= this.JSONData.Item2.filter(x=>x.Loan_Id==this.textString +this.JSONData.Item1[i].Loan_Id);
    
      ASSegment.forEach((obj: any) => {
        this.textString = this.textString +'AS|';
        this.textString = this.textString +obj.Borrower_Office_Location_Type!='' && obj.Borrower_Office_Location_Type!=null?obj.Borrower_Office_Location_Type+'|':'|';
        this.textString = this.textString +obj.Borrower_Office_DUNS_Number!='' && obj.Borrower_Office_DUNS_Number!=null?obj.Borrower_Office_DUNS_Number+'|':'|';
        this.textString = this.textString +obj.Address_Line_1!='' && obj.Address_Line_1!=null?obj.Address_Line_1+'|':'|';
        this.textString = this.textString +obj.Address_Line_2!='' && obj.Address_Line_2!=null?obj.Address_Line_2+'|':'|';
        this.textString = this.textString +obj.Address_Line_3!='' && obj.Address_Line_3!=null?obj.Address_Line_3+'|':'|';
        this.textString = this.textString +obj.City_Town!='' && obj.City_Town!=null?obj.City_Town+'|':'|';

        this.textString = this.textString +obj.District!='' && obj.District!=null?obj.District+'|':'|';
        this.textString = this.textString +obj.State_Union_Territory!='' && obj.State_Union_Territory!=null?obj.State_Union_Territory+'|':'|';
        this.textString = this.textString +obj.Pin_Code!='' && obj.Pin_Code!=null?obj.Pin_Code+'|':'|';
        this.textString = this.textString +obj.Country!='' && obj.Country!=null?obj.Country+'|':'|';
        this.textString = this.textString +obj.Mobile_Number!='' && obj.Mobile_Number!=null?obj.Mobile_Number+'|':'|';

        this.textString = this.textString +obj.Telephone_Area_Code!='' && obj.Telephone_Area_Code!=null?obj.Telephone_Area_Code+'|':'|';
        this.textString = this.textString +obj.Telephone_Number!='' && obj.Telephone_Number!=null?obj.Telephone_Number+'|':'|';
        this.textString = this.textString +obj.Fax_Area_Code!='' && obj.Fax_Area_Code!=null?obj.Fax_Area_Code+'|':'|';
        this.textString = this.textString +obj.Fax_Number!='' && obj.Fax_Number!=null?obj.Fax_Number+'||':'||';
        
      })
      }




    const blob = new Blob([this.textString], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'TextFile-CibilCommercial';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
