import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MobileService } from "../../Shared/app.Mobile.Service";
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MasterService } from '../../Shared/app.Masters.Service';
declare var $: any;
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { constantUrl } from '../../Shared/constantUrl';
import { LosService } from '../../_LOS/services/los.service';
import { QuickLoanService } from '../services/quickLoan.service';


@Component({
  selector: 'app-quickloan-tvr-questions',
  templateUrl: './quickloan-tvr-questions.component.html',
  styleUrls: ['./quickloan-tvr-questions.component.scss']
})
export class QuickloanTvrQuestionsComponent implements OnInit {
  showSpinner: boolean = false;
  tvrModel: any = { CustomerTVR: {}, TVRQuestionAnswer: [] };
  loading: boolean = false;
  currentUser: any;

  questionData: any[] = [];
  questionOptionData: any[] = [];
  inViewMode: boolean = false;

  //Final Submit
  Recommendation: any = '';
  Remarks: any;
  AllAnswer: any;
  @Input() Revert_IsEdit: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;

  CustomerId: any;
  customerData: any = {};
  ShowDiv: boolean = false;

  ApplicationDetail: any;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private _MobileService: MobileService,
    private LmsService: LmsService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private losService: LosService,
    private _QuickLoanService: QuickLoanService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LMS_GetLoanDetails();
    console.log("Revert_IsEdit", this.Revert_IsEdit);
   
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      console.log("LMS_GetLoanDetails", res);
      this.showSpinner = false;
      this.customerData = res.Item2;
      if(res.Item2[0]){
        this.OnClickTVRQuestions(res.Item2[0]);
      }
    })
  }
  OnClickTVRQuestions(row) {
    this.ShowDiv = true;
    console.log("row", row);

    this.CustomerId = row.CustomerId;
    this.tvrModel.CustomerTVR.TVR_ApplicationId = this.loanSummary.LoanId;
    this.tvrModel.CustomerTVR.TVR_CustomerId = this.CustomerId;
    this.tvrModel.CustomerTVR.TVR_LoginUserId = this.currentUser.userId;
    this.tvrModel.CustomerTVR.TVR_Remark = this.Remarks;
    this.tvrModel.CustomerTVR.TVR_Recommendation = this.Recommendation;
    this.GetLosCustomerTVRQuestionAnswer();
  }

  GetLosCustomerTVRQuestionAnswer() {
    //this.showSpinner = true;
    this.losService.LOS_GetCustomerTVRDetails({ ApplicationId: this.loanSummary.LoanId, CustomerId: this.CustomerId }).subscribe((res: any) => {
      this.showSpinner = false;
      this.ApplicationDetail = res.Item1[0];
      this.Recommendation = this.ApplicationDetail.Recommendation;
      this.Remarks = this.ApplicationDetail.Remark;
      this.inViewMode = this.ApplicationDetail.TVR_Status == 'Completed' || this.ApplicationDetail.TVR_Status == 'Rejected';
      this.questionData = res.Item3;
      console.log("this.questionData", this.questionData);

      this.AllAnswer = res.Item3.filter(x => x.TVR_QuestionAnswer != "" && x.TVR_QuestionAnswer != null);
      console.log("this.AllAnswer", this.AllAnswer);
      if(this.AllAnswer.length > 0){
        this.IsEdit = true;
        this.IsSave = false;
        this.IsDisabled = true;
      }
      else {
        var currentTab = this.PageData.currentTab == 1 && this.Status != 'C' && (this.Status == 'R' ? this.Revert_IsEdit == 1 ? true : false : true);
        if (currentTab == true) {
          this.IsEdit = false;
          this.IsSave = true;
          this.IsDisabled = false;
        }
        else {
          this.IsDisabled = true;
        }
       
      }
      
      this.questionOptionData = res.Item4;

      this.questionData.forEach((obj: any) => {
        if (obj.QuestionType == 'Multi-Selection' && obj.TVR_QuestionAnswer != null) {
          obj.TVR_QuestionAnswer = obj.TVR_QuestionAnswer.split(',');
        }
      });
    })
    console.log("this.IsDisabled", this.IsDisabled);
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.TVR_QuestionAnswer = "";
      delete data.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      data.TVR_QuestionAnswer = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.TVR_QuestionAnswer = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.TVR_QuestionAnswer = "";
    delete data.DocData;
  }

  onSaveTvr() {
    this.questionData.forEach((obj: any) => {
      if (obj.QuestionType == 'Multi-Selection') {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer.join(',')
        });
      }
      else if (obj.QuestionType == 'Upload') {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer
        });
        this.uploadDoc({ ApplicationNo: this.loanSummary.ApplicationNo, DocName: obj.TVR_QuestionAnswer, DocData: obj.DocData });
      }
      else {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer
        });
      }
    })
    if (this.tvrModel.TVRQuestionAnswer.length == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "TVR Question is required.", ...this.configSuccess });
      return false;
    }
    this.tvrModel.CustomerTVR.TVR_ApplicationId = this.loanSummary.LoanId;
    this.tvrModel.CustomerTVR.TVR_CustomerId = this.CustomerId;
    this.tvrModel.CustomerTVR.TVR_LoginUserId = this.currentUser.userId;
    this.tvrModel.CustomerTVR.TVR_Remark = this.Remarks;
    this.tvrModel.CustomerTVR.TVR_Recommendation = this.Recommendation;
    this.tvrModel.CustomerTVR.TVR_DoneBy = this.currentUser.userId;
    this.tvrModel.CustomerTVR.TVR_DoneOn = new Date();
    this.loading = true;
    this.losService.LOS_SaveCustomerTVR({ JSON: JSON.stringify(this.tvrModel) }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.GetLosCustomerTVRQuestionAnswer();
        this.QuickLoan_Save_PageProcess('TVR Questions');
        this.action.emit("next");
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }

  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {      
    });
  }

  onEditCustomerTVRAnswer(){
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  QuickLoan_Save_PageProcess(PageType){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }
}
