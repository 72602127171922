<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div
  class="row m-0 formborder d-flex align-items-center justify-content-between col-md-12"
>
  <div class="col-md-3 p-0">
    <select
      name="Status"
      id="Status"
      #refStatus="ngModel"
      class="form-control input-text-css"
      [(ngModel)]="Status"
      (change)="GetLegalAssignList()"
    >
      <option value="">Select Status</option>
      <option value="Pending">Pending</option>
      <option value="Print">Print</option>
      <option value="Send">Send</option>
    </select>
  </div>

  <div class="">
    <button
      type="button"
      (click)="GetLegalAssignList()"
      class="btn font-size-12 button-btn"
    >
      Search
    </button>
  </div>
  <div class="">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="">
    <button
      type="button"
      class="btn font-size-8 button-btn"
      (click)="OnAddDemandNotice()"
      style="width: max-content"
    >
      Print New Notice
    </button>
  </div>
</div>

<!-- <div class="row m-0 mb-1 mt-2 col-md-12 align-items-center justify-content-between">
    <h1 class="fs-12 m-0"></h1>
    <button type="button" class="btn_green" color="primary" (click)="OnAddDemandNotice()">Add New Legal Notice</button>
</div>

<div class="row m-0 mt-2 col-md-12 p-0">
    <div class="col-md-12"><hr /></div>
</div> -->

<div class="mt-2" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive mt-1">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matTableExporter
        id="exportReport"
        style="height: 60vh; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="Loan_Id">
          <mat-header-cell
            class="grid-header"
            *matHeaderCellDef
            style="width: 100px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="width: 100px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DPD_Days">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >DPD Days</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.DPD_Days
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Total_OverDUE_EMI__Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Overdue EMI Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Total_OverDUE_EMI__Amount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Noticecount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Notice Count</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell"
            ><span class="badge badge-success">{{
              row.Noticecount
            }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="Created_On">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Last {{ Status }} Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Created_On
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Notice_Type">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Last Notice Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Notice_Type
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 60px"
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 60px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              aria-label="true"
              (click)="GoToDetails(row)"
              *ngIf="Status == 'Pending'"
            ></i>
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              aria-label="true"
              (click)="OnClickSendNotice(row)"
              *ngIf="Status == 'Print'"
            ></i>
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              aria-label="true"
              (click)="OnClickViewSendNotice(row)"
              *ngIf="Status == 'Send'"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div class="col-md-4">
          <button class="btn font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddDemandNotice"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #df="ngForm" (ngSubmit)="OnSaveLegalNotice()" novalidate>
    <div class="modal-dialog modal-xl" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Demand Notice
          </h6>
          <button
            type="button"
            (click)="OnCloseDemandNotice()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="IsLoanSearch">
            <form
              #f="ngForm"
              (keydown.enter)="LoanList()"
              novalidate
              class="row m-0 col-md-12 p-0 formborder"
            >
              <div class="row m-0 col-md-12 p-0">
                <div class="col-md-2">
                  <span>Loan No.</span>
                  <input
                    name="LoanNo"
                    [(ngModel)]="Customer.LoanNo"
                    id="LoanNo"
                    placeholder="Loan No."
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Application No.</span>
                  <input
                    name="ApplicationNo"
                    [(ngModel)]="Customer.ApplicationNo"
                    id="ApplicationNo"
                    placeholder="Application No."
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Customer Name</span>
                  <input
                    name="CustomerName"
                    [(ngModel)]="Customer.CustomerName"
                    id="CustomerName"
                    placeholder="Customer Name"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Customer Phone</span>
                  <input
                    name="CustomerPhone"
                    [(ngModel)]="Customer.CustomerPhone"
                    id="CustomerPhone"
                    placeholder="Customer Phone"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>PAN Card</span>
                  <input
                    name="PANCard"
                    [(ngModel)]="Customer.PANCard"
                    id="PANCard"
                    placeholder="PAN Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Passport No.</span>
                  <input
                    name="Passport"
                    [(ngModel)]="Customer.Passport"
                    id="Passport"
                    placeholder="Passport No."
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-2">
                  <span>Voter ID Card</span>
                  <input
                    name="VoterID"
                    [(ngModel)]="Customer.VoterID"
                    id="VoterID"
                    placeholder="Voter ID Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Driving License</span>
                  <input
                    name="DrivingLicence"
                    [(ngModel)]="Customer.DrivingLicence"
                    id="DL"
                    placeholder="Driving License"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Aadhaar Card</span>
                  <input
                    name="Aadhaar"
                    [(ngModel)]="Customer.Aadhaar"
                    id="Aadhaar"
                    placeholder="Aadhaar Card"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Vehicle No</span>
                  <input
                    name="VehicleNo"
                    [(ngModel)]="Customer.VehicleNo"
                    id="VehicleNo"
                    placeholder="Vehicle No"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span>Date From</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="LoanDateFrom"
                      id="LoanDateFrom"
                      class="form-control input-text-css"
                      [(ngModel)]="Customer.LoanDateFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                  <!-- <input
                    name="LoanDateFrom"
                    [(ngModel)]="Customer.LoanDateFrom"
                    id="LoanDateFrom"
                    placeholder="Date From"
                    bsDatepicker
                    class="form-control input-text-css"
                  /> -->
                </div>
                <div class="col-md-2">
                  <span>Date To</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker2"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="LoanDateTo"
                      id="LoanDateTo"
                      class="form-control input-text-css"
                      [(ngModel)]="Customer.LoanDateTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <!-- <input
                    name="LoanDateTo"
                    [(ngModel)]="Customer.LoanDateTo"
                    id="LoanDateTo"
                    placeholder="Date To"
                    bsDatepicker
                    class="form-control input-text-css"
                  /> -->
                </div>

                <div class="col-md-3">
                  <span>Close Case</span>
                  <select
                    name="CloseCase"
                    id="CloseCase"
                    class="form-control input-text-css"
                    [(ngModel)]="Customer.CloseCase"
                  >
                    <option value="">Select Close Case</option>
                    <option value="1">Close Case</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <span>Select Type</span>
                  <select
                    name="SelectType"
                    id="SelectType"
                    class="form-control input-text-css"
                    [(ngModel)]="SelectType"
                    (change)="SearchChange()"
                  >
                    <option value="">Select Type</option>
                    <option value="Branch">Branch</option>
                    <option value="CollArea">Collection Area</option>
                    <option value="CollSubArea">Collection Sub Area</option>
                    <option value="CollExecutive">Collection Executive</option>
                    <option value="SalesExecutive">Sales Executive</option>
                    <option value="EngineNo">Engine No.</option>
                    <option value="ChassisNo">Chassis No.</option>
                    <option value="LoanAccCatg">Loan A/C Category</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div *ngIf="SelectType == 'Branch'">
                    <span> Branch</span>
                    <select
                      name="BranchId"
                      id="BranchId"
                      #refSelectGender="ngModel"
                      [(ngModel)]="Customer.BranchId"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Branch</option>
                      <option
                        *ngFor="let branch of BranchesList"
                        [value]="branch.BranchId"
                      >
                        {{ branch.Branch_Name }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'EngineNo'">
                    <span>Engine No.</span>
                    <input
                      name="EngineNo"
                      [(ngModel)]="Customer.EngineNo"
                      id="EngineNo"
                      placeholder="Engine No."
                      class="form-control input-text-css"
                    />
                  </div>

                  <div *ngIf="SelectType == 'ChassisNo'">
                    <span>Chassis No.</span>
                    <input
                      name="ChassisNo"
                      [(ngModel)]="Customer.ChassisNo"
                      id="ChassisNo"
                      placeholder="Chassis No."
                      class="form-control input-text-css"
                    />
                  </div>

                  <div *ngIf="SelectType == 'CollArea'">
                    <span> Collection Area</span>
                    <select
                      name="Area"
                      id="Area"
                      [(ngModel)]="Customer.Area"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection Area</option>
                      <ng-container *ngFor="let area of areaData">
                        <option
                          *ngIf="area.Type.trim() == 'Collection Area'"
                          [value]="area.Int_Id"
                        >
                          {{ area.Area_Name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'CollSubArea'">
                    <span> Collection Sub Area</span>
                    <select
                      name="SubArea"
                      id="SubArea"
                      [(ngModel)]="Customer.SubArea"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection SubArea</option>
                      <option
                        *ngFor="let subarea of subAreaData"
                        [value]="subarea.Int_Id"
                      >
                        {{ subarea.Sub_Area_Name }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'SalesExecutive'">
                    <span> Sales Executive</span>
                    <select
                      name="SalesEx"
                      id="SalesEx"
                      [(ngModel)]="Customer.SalesEx"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Sales Executive</option>
                      <option
                        *ngFor="let SalesExec of SalesExecutiveData"
                        [value]="SalesExec.EmpId"
                      >
                        {{ SalesExec.EmpName }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'CollExecutive'">
                    <span> Collection Executive</span>
                    <select
                      name="CollectionEx"
                      id="CollectionEx"
                      [(ngModel)]="Customer.CollectionEx"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Collection Executive</option>
                      <option
                        *ngFor="let CollExec of CollectionExecutiveData"
                        [value]="CollExec.EmpId"
                      >
                        {{ CollExec.Emp_FirstName }}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="SelectType == 'LoanAccCatg'">
                    <span>Accounting Category </span>
                    <select
                      name="LoanAccountingCatId"
                      id="LoanAccountingCatId"
                      [(ngModel)]="Customer.LoanAccountingCatId"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Accounting Category</option>
                      <option
                        *ngFor="let AcCat of AccCatDropdown"
                        [value]="AcCat.Loan_CategoryId"
                      >
                        {{ AcCat.Loan_category }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2 mt-4">
                  <div class="button-wrap">
                    <label class="btn font-size-8 button-btn" for="upload"
                      >Import Excel</label
                    >
                    <input
                      type="file"
                      id="upload"
                      (change)="onImport($event)"
                      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </div>
                </div>
                <div class="col-md-1 text-right mt-4">
                  <button
                    type="button"
                    (click)="LoanList()"
                    class="btn font-size-8 button-btn"
                    style="margin-left: -20px"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>

            <div class="mt-3" *ngIf="dataSourceLoan">
              <div class="">
                <div class="table-responsive">
                  <mat-table
                    [dataSource]="dataSourceLoan"
                    matSort
                    matTableExporter
                    #exporter="matTableExporter"
                    [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
                    style="height: 265px; max-width: 100%; overflow: auto"
                  >
                    <ng-container matColumnDef="LoanId">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        style="max-width: 50px"
                        *matHeaderCellDef
                        >#
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                        style="max-width: 50px; max-height: 5px"
                      >
                        {{
                          paginatorlist.pageIndex * paginatorlist.pageSize +
                            (i + 1)
                        }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanAcNo">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Loan No</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanAcNo }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Application_No">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Application No</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Application_No }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Customer">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                        >Customer</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Customer }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Branch">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Branch
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Branch }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Product">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Product</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Product }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanAMount">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Amount</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanAMount }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanCreatedOn">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        *matHeaderCellDef
                      >
                        Loan Date
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.LoanCreatedOn }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Assign">
                      <mat-header-cell
                        mat-sort-header
                        class="grid-header"
                        style="max-width: 60px"
                        *matHeaderCellDef
                        >Action
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row"
                        class="grid-cell j-c-center"
                        style="max-width: 60px"
                      >
                        <i
                          class="fa fa-arrow-circle-right arrow-circle action-btn"
                          (click)="goToDetailLoan(row)"
                          aria-label="true"
                        ></i>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      class="sticky-footer"
                      *matHeaderRowDef="displayedColumnsLoan; sticky: true"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumnsLoan"
                    ></mat-row>
                  </mat-table>
                  <div
                    class="row m-0 align-items-center"
                    style="background: #fff"
                  >
                    <div class="col-md-12">
                      <mat-paginator
                        #paginatorlist
                        [pageSize]="20"
                        [pageSizeOptions]="[20, 50]"
                        showFirstLastButtons
                      >
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="IsLegalCase">
            <div class="row m-0" *ngIf="LoanSummary">
              <div class="col-md-4 p-1">
                <B>Loan No:</B> {{ LoanSummary.LoanAcNo }}
              </div>
              <div class="col-md-4 p-1">
                <B>Loan Product:</B> {{ LoanSummary.Product }}
              </div>
              <div class="col-md-4 p-1">
                <B>Branch:</B> {{ LoanSummary.Branch }}
              </div>
              <div class="col-md-12 p-1">
                <hr />
              </div>
            </div>
            <div class="row m-0 mb-2">
              <div class="col-md-12 p-1">
                <!-- <div class="table-responsive" [hidden]="!dataCustomerSource">
                                    <mat-table [dataSource]="dataCustomerSource">
                                        <ng-container matColumnDef="Action">
                                            <mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>Select</mat-header-cell>
                                            <mat-cell *matCellDef="let row;" class="grid-cell j-c-center">
                                                <mat-checkbox [value]="row.CustomerId" [checked]="CaseModel.CustomerId == row.CustomerId" 
                                                    (change)="onCustomerChange($event,row)"></mat-checkbox>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="Number">
                                            <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>#</mat-header-cell>
                                            <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;">
                                                {{ (i + 1) }}
                                            </mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="Customer">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef> Customer </mat-header-cell>
                                            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                                                <app-lms-customer-view-model [customerId]="row.CustomerId" [index]="i">{{ row.Customer }}
                                                </app-lms-customer-view-model>
                                            </mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="CustomerType">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef>Customer Type</mat-header-cell>
                                            <mat-cell *matCellDef="let row" class="grid-cell">{{ row.CustomerType == 'Hirer' ? 'Borrower' :
                                                row.CustomerType}}</mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="GenderAge">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef> Gender Age </mat-header-cell>
                                            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.GenderAge}} </mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="PhoneNo">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef>Phone No</mat-header-cell>
                                            <mat-cell *matCellDef="let row" class="grid-cell">
                                                {{row.PhoneNo}} <i *ngIf="row.PhoneNoIsVerified.toLowerCase()=='true'"
                                                    class="fa fa-check-circle ml-2" style="color: green;"></i>
                                            </mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="Relation_With_Hirer">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef> Relation With Borrower</mat-header-cell>
                                            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Relation_With_Hirer}} </mat-cell>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="ExistingCustomer">
                                            <mat-header-cell class="grid-header" *matHeaderCellDef> Existing </mat-header-cell>
                                            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ExistingCustomer}} </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedCustomerColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedCustomerColumns"></mat-row>
                                    </mat-table>
                                </div> -->

                <div class="table-responsive" [hidden]="!dataSourceExcel">
                  <mat-table
                    [dataSource]="dataSourceExcel"
                    style="height: 420px; max-width: 100%; overflow: auto"
                  >
                    <ng-container matColumnDef="Select">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        <!-- <mat-checkbox
                          name="SelectAll"
                          id="SelectAll"
                          (change)="checkUncheckAll($event)"
                          [checked]="IsSelected"
                          [(ngModel)]="SelectAll"
                        ></mat-checkbox> -->
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                      >
                        <mat-checkbox
                          name="IsSelected{{ i }}"
                          id="IsSelected{{ i }}"
                          [(ngModel)]="row.IsSelected"
                          (change)="onChangeChecked(row, i)"
                        ></mat-checkbox>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Loan_Id">
                      <mat-header-cell
                        class="grid-header"
                        style="max-width: 50px"
                        *matHeaderCellDef
                        >#</mat-header-cell
                      >
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                        style="max-width: 50px"
                      >
                        {{ i + 1 }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LoanAcNo">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Loan No
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                      >
                        {{ row.LoanAcNo }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Loan_Date">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Loan Date
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                      >
                        {{ row.Loan_Date }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="CustomerName">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Customer
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let row; let i = index"
                        class="grid-cell"
                      >
                        <app-lms-customer-view-model
                          [customerId]="row.CustomerId"
                          [index]="i"
                          >{{ row.CustomerName }}
                        </app-lms-customer-view-model>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="AC_CustomerType">
                      <mat-header-cell class="grid-header" *matHeaderCellDef
                        >Customer Type</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{
                          row.AC_CustomerType == "H"
                            ? "Borrower"
                            : row.AC_CustomerType == "C"
                            ? "Co-Borrower"
                            : "Guarantor"
                        }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Branch_Name">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Branch
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Branch_Name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Product_Name">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Product
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Product_Name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Application_LoanAmount">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Loan Amount
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Application_LoanAmount }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Address">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Address
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Address }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Last_Recv_Date">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Last Recv Date
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Last_Recv_Date }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Last_Recv_Amount">
                      <mat-header-cell class="grid-header" *matHeaderCellDef>
                        Last Recv Amount</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row" class="grid-cell">
                        {{ row.Last_Recv_Amount }}
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedExcelColumns"
                    ></mat-header-row>
                    <mat-row
                      (click)="highlightRow(i)"
                      [class.highlight]="i == selectedId"
                      *matRowDef="
                        let row;
                        let i = index;
                        columns: displayedExcelColumns
                      "
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
            <div class="row m-0 formborder" [hidden]="!dataSourceExcel">
              <div class="col-md-3 p-1">
                <span class="required-lable">Customer type</span>
                <select
                  name="Customer_Type"
                  id="Customer_Type"
                  #refCustomer_Type="ngModel"
                  (change)="OnchangeCustomerType()"
                  [(ngModel)]="Customer_Type"
                  class="form-control input-text-css"
                >
                  <option value="">Select Customer type</option>
                  <option value="H">Borrower</option>
                  <option value="C">CO-Borrower</option>
                  <option value="G">Guarantor</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable">Demand Notice</span>
                <select
                  name="TemplateId"
                  id="TemplateId"
                  class="form-control input-text-css"
                  [(ngModel)]="TemplateId"
                  #refTemplateId="ngModel"
                  required
                  (change)="OnchangeTemplateType()"
                  [ngClass]="{
                    'is-invalid': df.submitted && refTemplateId.invalid,
                    'alert-warning':
                      refTemplateId.invalid &&
                      (refTemplateId.dirty ||
                        refTemplateId.touched ||
                        refTemplateId.untouched)
                  }"
                >
                  <option value="">Select Template</option>
                  <option *ngFor="let row of TemplateDropdown" [value]="row.Id">
                    {{ row.Template_Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-4">
                <span></span>
                <button
                  type="button"
                  mat-raised-button
                  class="btn font-size-12 button-btn"
                  [disabled]="!df.form.valid"
                  (click)="DownloadPDF()"
                >
                  Print Demand Notice
                </button>
                <!-- <app-print-demand-notice [LoanSummary]="LoanSummary" [index]="0"></app-print-demand-notice> -->
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="SendNotice"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSendLegalNotice()" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Send Notice
          </h6>
          <button
            type="button"
            (click)="OnCloseModel()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0" *ngIf="dataSourcePrint">
            <div class="col-md-12 p-1">
              <div class="table-responsive">
                <mat-table
                  [dataSource]="dataSourcePrint"
                  matSort
                  matTableExporter
                  #exporter="matTableExporter"
                >
                  <ng-container matColumnDef="Select">
                    <mat-header-cell class="grid-header" *matHeaderCellDef>
                      <mat-checkbox
                        name="SelectAllPrint"
                        id="SelectAllPrint"
                        (change)="checkUncheckAllPrint($event)"
                        [checked]="SelectAllPrint"
                        [(ngModel)]="SelectAllPrint"
                      ></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                    >
                      <mat-checkbox
                        name="IsSelectedPrint{{ i }}"
                        id="IsSelectedPrint{{ i }}"
                        [(ngModel)]="row.IsSelectedPrint"
                        [checked]="row.IsSelectedPrint"
                      ></mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Loan_Id">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      >{{ i + 1 }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="LoanAcNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Loan No</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.LoanAcNo }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="LoanAmount">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Loan Amount</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.LoanAmount }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CustomerName">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Customer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.CustomerName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="NoticeType">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                    >
                      Notice Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.NoticeType }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CreatedBY">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Created BY</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.CreatedBY }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CreatedOn">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Created On</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.CreatedOn }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Action</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell j-c-center"
                    >
                      <i
                        class="fa fa-minus-circle btn_delete"
                        (click)="OnDeleteLegalNotice(row)"
                        aria-label="true"
                      ></i>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Download">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Download</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell j-c-center"
                    >
                      <i
                        class="fa fa-download"
                        (click)="pdfDownload(row)"
                        aria-label="true"
                      ></i>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    class="sticky-footer"
                    *matHeaderRowDef="displayedColumnsPrint; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsPrint"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 p-1">
              <hr />
            </div>
          </div>

          <div class="row m-0 formborder">
            <div class="col-md-6 p-1">
              <span class="required-lable"> Dispatched Mode</span>
              <select
                name="Dispatched_Mode"
                id="Dispatched_Mode"
                #refDispatched_Mode="ngModel"
                [(ngModel)]="SendModal.Dispatched_Mode"
                [ngClass]="{
                  'is-invalid': f.submitted && refDispatched_Mode.invalid,
                  'alert-warning':
                    refDispatched_Mode.invalid &&
                    (refDispatched_Mode.dirty ||
                      refDispatched_Mode.touched ||
                      refDispatched_Mode.untouched)
                }"
                class="form-control input-text-css"
                required
                [disabled]="IsDisabled"
              >
                <option value="">Select Dispatched Mode</option>
                <option value="Courier">Courier</option>
                <option value="ByPost">By Post</option>
                <option value="ByBus">By Bus</option>
                <option value="ByHand">By Hand</option>
              </select>
            </div>
            <div
              class="col-md-6 p-1"
              *ngIf="
                !SendModal.Dispatched_Mode ||
                SendModal.Dispatched_Mode == 'Courier'
              "
            >
              <span class="required-lable"> Agency </span>
              <select
                name="Agency"
                id="Agency"
                #refAgency="ngModel"
                [(ngModel)]="SendModal.Agency"
                [ngClass]="{
                  'is-invalid': f.submitted && refAgency.invalid,
                  'alert-warning':
                    refAgency.invalid &&
                    (refAgency.dirty ||
                      refAgency.touched ||
                      refAgency.untouched)
                }"
                class="form-control input-text-css"
                required
                [disabled]="IsDisabled"
              >
                <option value="">Select Agency</option>
                <option
                  *ngFor="let agency of AgencyDropDown"
                  [value]="agency.CourierId"
                >
                  {{ agency.CourierCompanyName }}
                </option>
              </select>
            </div>
            <div
              class="col-md-6 p-1"
              *ngIf="
                SendModal.Dispatched_Mode &&
                SendModal.Dispatched_Mode != 'Courier'
              "
            >
              <span class="required-lable">
                {{
                  SendModal.Dispatched_Mode == "ByPost"
                    ? "Post Detail"
                    : SendModal.Dispatched_Mode == "ByBus"
                    ? "Bus Detail"
                    : "Detail"
                }}
              </span>
              <input
                required
                type="text"
                #refDispatched_Detail="ngModel"
                [(ngModel)]="SendModal.Dispatched_Detail"
                [ngClass]="{
                  'is-invalid': f.submitted && refDispatched_Detail.invalid,
                  'alert-warning':
                    refDispatched_Detail.invalid &&
                    (refDispatched_Detail.dirty ||
                      refDispatched_Detail.touched ||
                      refDispatched_Detail.untouched)
                }"
                placeholder="{{
                  SendModal.Dispatched_Mode == 'ByPost'
                    ? 'Post Detail'
                    : SendModal.Dispatched_Mode == 'ByBus'
                    ? 'Bus Detail'
                    : 'Detail'
                }}"
                name="Dispatched_Detail"
                id="Dispatched_Detail"
                class="form-control input-text-css"
                [disabled]="IsDisabled"
              />
            </div>

            <div class="p-1" [ngClass]="IsDisabled ? 'col-md-4' : 'col-md-6'">
              <span class="required-lable"> Tracking Number </span>
              <input
                required
                type="text"
                #refTracking_No="ngModel"
                [(ngModel)]="SendModal.Tracking_No"
                [disabled]="IsDisabled"
                [ngClass]="{
                  'is-invalid': f.submitted && refTracking_No.invalid,
                  'alert-warning':
                    refTracking_No.invalid &&
                    (refTracking_No.dirty ||
                      refTracking_No.touched ||
                      refTracking_No.untouched)
                }"
                placeholder="Tracking Number"
                name="Tracking_No"
                id="Tracking_No"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4 p-1" *ngIf="IsDisabled">
              <span> Sent Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker3"
                  placeholder="DD/MM/YYYY"
                  name="Sent_On"
                  id="Sent_On"
                  #refSent_On="ngModel"
                  [(ngModel)]="SendModal.Sent_On"
                  class="form-control input-text-css"
                  [disabled]="IsDisabled"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </div>
            </div>
            <div class="p-1" [ngClass]="IsDisabled ? 'col-md-4' : 'col-md-6'">
              <span class="required-lable"> Dispached Date </span>

              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker4"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Dispached_Date"
                  id="Dispached_Date"
                  #refDispached_Date="ngModel"
                  required
                  [(ngModel)]="SendModal.Dispached_Date"
                  [ngClass]="{
                    'is-invalid': f.submitted && refDispached_Date.invalid,
                    'alert-warning':
                      refDispached_Date.invalid &&
                      (refDispached_Date.dirty ||
                        refDispached_Date.touched ||
                        refDispached_Date.untouched)
                  }"
                  class="form-control input-text-css"
                  [disabled]="IsDisabled"
                  [max]="CurrentDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker4"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </div>
              <!-- <input
                name="Dispached_Date"
                id="Dispached_Date"
                #refDispached_Date="ngModel"
                required
                [(ngModel)]="SendModal.Dispached_Date"
                [ngClass]="{
                  'is-invalid': f.submitted && refDispached_Date.invalid,
                  'alert-warning':
                    refDispached_Date.invalid &&
                    (refDispached_Date.dirty ||
                      refDispached_Date.touched ||
                      refDispached_Date.untouched)
                }"
                value="{{ SendModal.Dispached_Date | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                [maxDate]="CurrentDate"
                class="form-control input-text-css"
                [(bsValue)]="SendModal.Dispached_Date"
                bsDatepicker
                [disabled]="IsDisabled"
              /> -->
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Remarks </span>
              <textarea
                required
                rows="3"
                #refDispached_Remark="ngModel"
                [(ngModel)]="SendModal.Dispached_Remark"
                [disabled]="IsDisabled"
                [ngClass]="{
                  'is-invalid': f.submitted && refDispached_Remark.invalid,
                  'alert-warning':
                    refDispached_Remark.invalid &&
                    (refDispached_Remark.dirty ||
                      refDispached_Remark.touched ||
                      refDispached_Remark.untouched)
                }"
                placeholder="Remark"
                name="Dispached_Remark"
                id="Dispached_Remark"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseModel()"
            class="font-size-12 button-btn"
            data-dismiss="modal"
          >
            {{ IsDisabled ? "Close" : "Cancel" }}
          </button>
          <button
            type="button"
            (click)="OnSendLegalNotice()"
            class="font-size-12 button-btn"
            [disabled]="!f.form.valid"
            *ngIf="!IsDisabled"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="legal_assign_cases"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Overdue EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Notice Count
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Print Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Notice Type
    </th>
  </tr>

  <tr *ngFor="let x of JsonData2; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date | date : "dd/MM/yyyy" }}</td>

    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.Total_OverDUE_EMI__Amount }}</td>
    <td>{{ x.Noticecount }}</td>
    <td>{{ x.Created_On }}</td>
    <td>{{ x.Notice_Type }}</td>
  </tr>
</table>

<div
  class="modal fade in"
  id="ViewDemandNotice"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="OnSendLegalNotice()" novalidate>
    <div class="modal-dialog modal-lg" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            View Demand Notice
          </h6>
          <button
            type="button"
            (click)="OnCloseModel()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              class="btn font-size-12 button-btn ml-2"
              (click)="onDocsPrint('printTemplate', 'Legal Notice')"
            >
              Print
            </button>
          </div>

          <div [innerHTML]="modalTemplateData" id="printTemplate"></div>
        </div>
        <!-- <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseModel()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            {{ IsDisabled ? "Close" : "Cancel" }}
          </button>
          <button
            type="button"
            (click)="OnSendLegalNotice()"
            class="font-size-12 button-btn"
            [disabled]="!f.form.valid"
            *ngIf="!IsDisabled"
          >
            Save
          </button>
        </div> -->
      </div>
    </div>
  </form>
</div>
