import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constantUrl } from 'src/app/Shared/constantUrl';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, ReplaySubject, Subject, from } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Message } from '../ChatboxPopup/entities/message';
import { PresenceService } from './presence.service';

// import { from } from 'core-js/core/array';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  

  hubConnection: HubConnection;
  hubUrl = constantUrl.hubUrl;
  otherUser: any;
  loanId: any;
  headerSource = new BehaviorSubject<any>('name');
  messageThreadSource = new BehaviorSubject<any[]>([]);
  
  constructor(private http: HttpClient,private presenceService:PresenceService) {
  }

  GetEmployeeDropdown(currentUser) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Get_Employee_ChatDropdown`, { LoginUserId:currentUser.userId }).pipe(map(
      (x: any[]) => {
        // console.log("employee Dropdown", x)
        x.splice(x.findIndex(y => y.EmpId === Number(currentUser.userId)), 1)
        return x;
      }
    ))
  }

  GetApplicationDetailsFromEmp(empId,currentUser) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Chat_List_Of_Application`, { EmpId:empId,LoginUserId:currentUser["userId"] })
  }

  GetPreviousChatEmployeeList(currentUser) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Chat_GetEmployee_List_By_Loan`, { LoginUserId: currentUser["userId"], LoanId: this.loanId })
  }

  GetPreviousChat(currentUser) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Get_Previous_Chat`, {
      LoanId: this.loanId,
      SenderId: currentUser["userId"],
      ReceiverId: this.otherUser.EmpId
    })

  }

  createHubConnection(currentUser) {

    if(this.hubConnection){
      this.stopHubConnection()
    }

    this.hubConnection = new HubConnectionBuilder().withUrl
      (`${this.hubUrl}/message?currentUser=${currentUser.userId}&otherUser=${this.otherUser.EmpId}&loanId=${this.loanId}`,{ accessTokenFactory: () => currentUser.token }).withAutomaticReconnect().build()
      this.hubConnection.start().catch(error => console.log(error))
      //Get Previous Chat
      this.GetPreviousChat(currentUser).subscribe((res: any[]) => {
        // console.log("response",res)
        this.messageThreadSource.next(res)
      })
   
    this.hubConnection.on('NewMessage', (mess) => {
      let prev = this.messageThreadSource.value;
      console.log("New Message receive",mess)
      
      this.messageThreadSource.next([...prev, mess])
      // console.log("new Value",this.messageThreadSource.value)
    })
  }

  stopHubConnection() {
    if (this.hubConnection)
      this.hubConnection.stop().catch(error => console.log(error))
  }

  sendMessage(content: string,currentUser,applicationNo:any,AttachementName?:any) {
    // console.log(this.otherUser)

    var message = {
      msg: content,
      receiverID: this.otherUser.EmpId,
      senderId: currentUser["userId"],
      loanId: this.loanId,
      senderName:currentUser["FullName"],
      applicationNo:applicationNo,
      document_Attachment:AttachementName
    }
    console.log("message",this.hubConnection)
    if (this.hubConnection) {
      this.hubConnection.invoke('SendMessage', message).catch(error => { console.log("Error", error) })
    }
  }

  searchNewApplication(appOrLoanId){
    // console.log("App",appOrLoanId)
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Check_App_Or_LoanNo_Exists`, {
      App_Or_LoanNo:appOrLoanId
    })

  }

  Chat_GetEmployee_Detail(EmpId){
    return this.http.post(`${constantUrl.apiEndpoint}/api/Message/Chat_GetEmployee_Detail`, {
      EmpId:EmpId
    }).pipe(map(x=>{
      x[0]["Emp_Image"]=constantUrl.apiProfilePicUrl+x[0]["Emp_Image"]
      return x;
    }))
  }

  public UploadChatDoc(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadApplicationDoc`, requestData);
  }

  


}
