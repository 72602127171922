<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div style="background-color: #ffff">
  <div class="text-right mt-2 mb-2">
    <button
      type="button"
      (click)="isVertical = !isVertical"
      class="mt-3 btn font-size-12 button-btn ml-4"
    >
      Toggle View
    </button>
  </div>
  <div class="main-tree mt-4" [ngClass]="{ horizontal: isVertical }">
    <ng-container *ngFor="let emp of employeeList">
      <ng-template
        [ngTemplateOutlet]="nodeTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: emp }"
      ></ng-template>
    </ng-container>
  </div>

  <ng-template #nodeTemplateRef let-node>
    <div class="child" [ngClass]="{ parent: node.Emp_ManagerId == 0 }">
      <div
        class="child-label"
        [ngClass]="{ 'no-child': node.Children.length == 0 }"
      >
        <div class="name">{{ node.EmpName }}</div>
        <div class="rol-app">
          <span>({{ node.Role_Name }})</span>
        </div>
      </div>
      <div *ngIf="node.Children.length" class="child-tree">
        <ng-template
          ngFor
          [ngForOf]="node.Children"
          [ngForTemplate]="nodeTemplateRef"
        ></ng-template>
      </div>
    </div>
  </ng-template>
</div>
