import { AfterContentInit, Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from "@angular/material/table";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  AbstractControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";

declare var $;

@Component({
  selector: "app-nature-of-work",
  templateUrl: "./nature-of-work.component.html",
  styleUrls: ["./nature-of-work.component.scss"],
})
export class NatureOfWorkComponent implements OnInit {
  dataCSource: any = [];
  userId: any;
  categoryList = [];
  textNature: any;
  natureId: any = 0;
  @ViewChild("NatureAdd") form: NgForm;

  @ViewChild("paginatorList", { read: MatPaginator, static: true })
  paginatorList: MatPaginator;
  @ViewChild("paginatorRef", { read: MatPaginator, static: true })
  paginatorRef: MatPaginator;

  allSelected: boolean = false;
  displayedNatureColumns = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  modalSheetName: any = "";
  btnName: any = "";
  dataSource: MatTableDataSource<any>;
  displayedNatureModelColumns: string[];

  constructor(
    private masterService: MasterService,
    public snackBar: MatSnackBar,
    private dataSharingService: DataSharingService
  ) {
    this.userId = JSON.parse(sessionStorage.getItem("currentUser")).userId;
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Customer Profile");
    this.updateTable();
    this.getCategoryList();
  }

  customCheckBoxValidator(): boolean {
    for (let category of this.categoryList) {
      if (category.isActive == true) return true;
    }
    return false;
  }

  applyFiltermodel(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectAll() {
    this.categoryList.forEach((x) => (x.isActive = true));
  }
  deSelectAll() {
    this.categoryList.forEach((x) => (x.isActive = false));
  }

  onAddNature() {
    this.modalSheetName = "Add Nature of Work";
    this.btnName = "Save";
    this.resetForm();

    this.onOpenNature();
  }

  //Method to close Sheet
  onCloseNature() {
    this.resetForm();
    $("#NatureModel").modal("hide");
  }

  onOpenNature() {
    $("#NatureModel").modal("show");
    $("#NatureModel").css("z-index", "1050");
  }

  setCategoryFalse() {
    this.categoryList.forEach((x) => (x.isActive = false));
  }

  getCategoryList() {
    this.masterService
      .GetCustomer_Profile_Master_For_Dropdown({ Type: "Category", P_ID: 0 })
      .subscribe(
        (response) => {
          this.categoryList = JSON.parse(JSON.stringify(response));
          this.displayedNatureModelColumns = ["Id", "Value", "ParentIds"];
          this.dataSource = new MatTableDataSource(this.categoryList);

          this.setCategoryFalse();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //convert to comma Separated String
  convertToStringList(categoryList: any[]) {
    var array: any = [];
    categoryList.forEach((x) => {
      if (x.isActive) array.push(x.Id);
    });
    return array.toString();
  }

  //convert comma seperated to list of value
  convertToArray(parentId: string) {
    var array: any[] = [];
    parentId.split(",").forEach((x) => {
      array.push(x);
    });
    return array;
  }

  resetForm() {
    this.form.reset();
    this.setCategoryFalse();
    this.natureId = 0;
  }

  onSave() {
    if (this.customCheckBoxValidator()) {
      var parentId = this.convertToStringList(this.categoryList);
      var requestModel: any = {};
      requestModel.Name = this.textNature;
      requestModel.ParentIds = parentId;
      requestModel.LoginUserId = this.userId;
      requestModel.Id = this.natureId;
      this.masterService
        .Save_Customer_Profile_Master(requestModel)
        .subscribe((response) => {
          this.updateTable();
          this.snackBar
            .openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            })
            ._dismissAfter(700);
          this.onCloseNature();
          this.resetForm();
        });
    } else {
      this.snackBar
        .openFromComponent(SnackbarComponent, {
          data: "Minimum 1 Category Selection required!",
          ...this.configSuccess,
        })
        ._dismissAfter(500);
    }
  }

  updateTable() {
    this.masterService
      .GetCustomer_Profile_Master_For_Dropdown({
        Type: "Nature of work",
        P_ID: 0,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedNatureColumns = ["Int_Id", "Nature", "Action"];
          this.dataCSource = new MatTableDataSource(res);
          this.dataCSource.paginator = this.paginatorRef;
        } else {
          this.dataCSource = [];
        }
      });
  }

  deleteNature(id) {
    this.masterService
      .Delete_Customer_Profile_Master({ LoginUserId: this.userId, Id: id })
      .subscribe(
        (resp) => {
          this.updateTable();
          this.snackBar
            .openFromComponent(SnackbarComponent, {
              data: resp[0].MSG,
              ...this.configSuccess,
            })
            ._dismissAfter(500);
        },
        (error) => console.log(error)
      );
  }

  deSelectAllCheckBox(isChecked: boolean) {
    if (isChecked) {
      this.allSelected = false;
    }
  }
  applyFilter(filterValue: string) {
    this.dataCSource.filter = filterValue.trim().toLowerCase();
  }

  editNature(id) {
    this.resetForm();
    this.modalSheetName = "Edit Nature of Work";
    this.btnName = "Update";
    var modelValue = this.dataCSource.data.find((x) => {
      return x.Id == id;
    });
    this.textNature = modelValue.Value;

    this.natureId = modelValue.Id;
    var array = this.convertToArray(modelValue.ParentIds);

    array.forEach((x) => {
      if (x != "") this.categoryList.find((y) => y.Id == x).isActive = true;
    });
    this.onOpenNature();
  }
}
