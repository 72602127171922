import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollToBottom]'
})
export class ScrollToBottomDirective implements OnInit, OnDestroy {
  private observer = new MutationObserver(() => {
    this.scroll();
    console.log("Called");
    
  })
  constructor(private element: ElementRef) { }

  ngOnInit(): void {

    this.observer.observe(this.element.nativeElement, { childList: true,subtree:true });
  }
  ngOnDestroy(): void {
    this.observer.disconnect();
    console.log('Destroyed')
  }

  scroll() {
    this.element.nativeElement.scrollTop = this.element.nativeElement.scrollHeight;
    console.log("Scroll Top",this.element.nativeElement.scrollTop)
    console.log("Scroll height",this.element.nativeElement.scrollHeight)
    
  }

}
