import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from 'rxjs';
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { PartnerService } from "../services/partner.service";

import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from '../../Shared/dialog.service';
import { map, startWith } from 'rxjs/operators';
import { ToWords } from 'to-words';
import { MasterService } from '../../Shared/app.Masters.Service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner-loan-balance',
  templateUrl: './partner-loan-balance.component.html',
  styleUrls: ['./partner-loan-balance.component.scss']
})
export class PartnerLoanBalanceComponent implements OnInit {
  chhead: any = '';
  showSpinner: boolean = false;
  currentUser: any;
  checkSeeAll:any=true;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalCalculator: any;
  loading: boolean = false;
  FileCheckingList: any[] = [];
  dataSource: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  ChargesHead: any;
  chargesHeadDropdown: any[] = [];
  displayedColumns: string[] = [
    "PLoan_Id", "PLoanAcNo", "CustumerName", "Loan_Acc_Category", "PEligibility_LoanAmount", "OpeningBalanceDR",
    "OpeningBalanceCR", "TranTypeDR", "TranTypeCR", "ClosingBalanceDR", "ClosingBalanceCR"
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  groupList: any[] = [];
  groupDropdown: any[] = [];
  BankList: any[] = [];
  groupModel: any = {};
  dropdownSettings: any = {};
  Loan_Acc_CategoryList: any[] = [];
  myForm: UntypedFormGroup;
  ChargesHeadControl = new UntypedFormControl();
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  Loan_Acc_Category: any = 0;
  filteredChargesHead: Observable<any[]>;

  pageTitle: any = "Loan Balance";
  FyearId: any;
  maxDate: any;
  minDate: any;

  constructor(private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar, private _MasterService: MasterService,
    private router: Router, private route: ActivatedRoute,
    private lmsService: LmsService, private partnerService: PartnerService,
    private dialog: DialogService, private fb: UntypedFormBuilder
  ) {

  }
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Charges Balance");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getChargeshead();
    this.getLoan_Acc_CategoryDropdown();
    //this.dropdownSettings = {
    //  singleSelection: true,
    //  idField: 'AccountId',
    //  textField: 'Account_Name',
    // // selectAllText: 'Select All',
    ////  unSelectAllText: 'UnSelect All',
    //  allowSearchFilter: this.ShowFilter
    //};
    //this.filteredAccountHead = this.AccountControl.valueChanges.pipe(startWith(''),
    //  map(value => typeof value === 'string' ? value : value.name),
    //  map(name => name ? this._filter(name) : this.AccountHeadDropdown.slice())
    //);

    /*this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
      this.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
      this.VoucherDateTo = new Date();
    });*/

    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        //console.log("paran", this.FyearId);
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          if (res.length > 1) {
            this.minDate = new Date(moment(res[res.length-1].fystart).format('MM/DD/YYYY'));
            this.maxDate = new Date(moment(res[res.length -1].fyent).format('MM/DD/YYYY'));
            this.VoucherDateFrom = new Date(moment(res[res.length -1].fystart).format('MM/DD/YYYY'));
            if (this.maxDate > new Date() && this.minDate < new Date())
              this.VoucherDateTo = new Date();
            else
              this.VoucherDateTo = this.maxDate;
          }
          else {

            this.minDate = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
            this.maxDate = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));
            this.VoucherDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
            if (this.maxDate > new Date() && this.minDate < new Date())
              this.VoucherDateTo = new Date();
            else
              this.VoucherDateTo = this.maxDate;
}
          this.dataSource = null;
        });
      }
    )
  
  }
  onChargesHeadhSelect(event: any) {
    this.ChargesHead = event.option.value.Id;

  }
  getLoan_Acc_CategoryDropdown() {
    this.lmsService.Get_ACC_Category_ForDropdown_By_Type({ Type: '' }).subscribe((result: any) => {
     
      this.Loan_Acc_CategoryList = JSON.parse(JSON.stringify(result));
      this.Loan_Acc_CategoryList.filter(option => option.Loan_CategoryId !=1)
    });
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.chargesHeadDropdown.filter(option => option.ChagesHead.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(ChargesHead: any): string {
    return ChargesHead && ChargesHead.ChagesHead ? ChargesHead.ChagesHead : "";
  }
  getChargeshead() {
    this._MasterService.GetProductChargesHeadDropDown().subscribe((res: any) => {
      this.chargesHeadDropdown = res;
      //for (var i = 0; i < res.length; i++) {
      //  this.chargesHeadDropdown.push({ id: res[i]["AccountId"], name: res[i]["Account_Name"]});
      //}
      this.filteredChargesHead = this.ChargesHeadControl.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.ChagesHead)),
        map((name) =>
          name ? this._filter(name) : this.chargesHeadDropdown.slice()
        )
      );
    });
  }
  onSearchAccountStatement() {
    this.showSpinner = true;
    if (this.ChargesHead != null) {
      this.partnerService.Get_Partner_Loan_Balance({ HeadId: this.ChargesHead, From: this.VoucherDateFrom, To: this.VoucherDateTo, Loan_Acc_Category: this.Loan_Acc_Category }).subscribe((res: any) => {
        //console.log(res);
        if(res.length > 0){
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.FileCheckingList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.getChargeshead();
          this.showSpinner = false;
        }
        else{
          this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
          this.showSpinner = false;
        }
      });
    }
  }
 
  calculateACTranTypeDR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceDR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceCR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceCR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceDR() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: this.pageTitle,
      sheet: this.pageTitle,
    });
  }

  printdata(){

  }

  DateChange() {
    if (this.VoucherDateFrom != '' && this.VoucherDateTo != '') {
      if (this.VoucherDateFrom > this.VoucherDateTo) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To Date will be greater than to From Date.", ...this.configSuccess });
        this.VoucherDateFrom = '';
        this.VoucherDateTo = '';
      }
    }
  }
  calculateLoanAmount() {
    if (this.dataSource != null) {
      let sum = 0.00;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["PEligibility_LoanAmount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  onCheckedChanged(value){
console.log("Value ",value);
if(value){
  this.displayedColumns= [
    "PLoan_Id",  "PLoanAcNo", "CustumerName", "Loan_Acc_Category", "PEligibility_LoanAmount", "OpeningBalanceDR",
    "OpeningBalanceCR", "TranTypeDR", "TranTypeCR", "ClosingBalanceDR", "ClosingBalanceCR"
  ]
}
else{
  this.displayedColumns= [
    "PLoan_Id", "PLoanAcNo", "CustumerName", "Loan_Acc_Category", "PEligibility_LoanAmount", 
    "TranTypeDR", "TranTypeCR"
  ]
}
  }
}
