import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { LmsService } from "../../_Lms/services/lms.service";
import { LosService } from "../../_LOS/services/los.service";
import { constantUrl } from "../../Shared/constantUrl";
import { LegalService } from "../services/Legal.service";

@Component({
  selector: "app-legal-cases-model",
  templateUrl: "./legal-cases-model.component.html",
  styleUrls: ["./legal-cases-model.component.scss"],
})
export class LegalCasesModelComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  FileCheckingList: any[] = [];
  loading: boolean = false;
  dataSourceLoan: any;
  displayedColumnsLoan: string[] = [];

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  RequestModel: RequestModel = new RequestModel();

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  Customer: any = {};
  SelectType: any = "";

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  IsLoanSearch: boolean = false;
  IsLegalCase: boolean = false;

  JsonData: any;
  CaseModel: any = {};
  LoanSummary: any;

  CustomerDropdown: any = [];
  StateDropdown: any = [];
  DistrictDropDown: any = [];
  CourtNameDropdown: any = [];

  ActData: any = [];
  SectionData: any = [];
  SubSectionData: any = [];
  AdvocateDropdown: any = [];
  EmployeeDropdown: any = [];
  CustomerBankDropdown: any = [];
  CustomerChqDropdown: any = [];

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @Input() LoanData: any;
  @Input() dindex: any;
  LoanId: any;

  dataCustomerSource: any;
  displayedCustomerColumns: string[] = [
    "Number",
    "Customer",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
    "Relation_With_Hirer",
    "ExistingCustomer",
    "Action",
  ];
  SelectJsonData: any[] = [];
  CibilData: any;

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private dialog: DialogService,
    private lmsService: LmsService,
    private _LosService: LosService,
    private _LegalService: LegalService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Legal Cases");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.Customer.CloseCase = "";

    // console.log("this.LoanData", this.LoanData);

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.displayedColumnsLoan = [
      "LoanId",
      "LoanAcNo",
      "Application_No",
      "Customer",
      "Branch",
      "Product",
      "LoanAMount",
      "LoanCreatedOn",
      "Assign",
    ];
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  LoanList() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_ModifyBooking_List({
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(this.Customer),
      })
      .subscribe((res: any) => {
        //console.log("RESULT : ",res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.dataSourceLoan = new MatTableDataSource(this.FileCheckingList);
          this.dataSourceLoan.sort = this.sort;
          this.dataSourceLoan.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSourceLoan = null;
        }
        this.showSpinner = false;
      });
  }

  AddNewLegal() {
    this.LoanSummary = null;
    this.dataCustomerSource = null;
    this.dataSourceLoan = null;

    if (this.LoanData) {
      this.goToDetailLoan(this.LoanData);
      this.LoanData.Product = this.LoanData.Product_Name;
      this.LoanData.Branch = this.LoanData.Branch_Name;
    } else {
      this.IsLoanSearch = true;
      this.IsLegalCase = false;
    }

    this.CaseModel.CourtName = "";
    this.CaseModel.Nature = "";
    this.CaseModel.Power_Of_Attorney_Type = "";
    this.CaseModel.Customer_Bank_Id = "";
    this.CaseModel.Customer_Cheque_Id = "";
    this.CaseModel.Customer_Bounce_Reason = "";

    this.CaseModel.Customer = "";

    // console.log("LoanData", this.LoanData);

    $(`#AddLegalCase${this.dindex}`).modal("show");
    $(`#AddLegalCase${this.dindex}`).css("z-index", "1050");
  }
  OnClose() {
    $(`#AddLegalCase${this.dindex}`).modal("hide");
  }

  goToDetailLoan(row) {
    this.IsLoanSearch = false;
    this.IsLegalCase = true;
    this.LoanSummary = row;
    console.log("row", row);

    this.LoanId = this.LoanData ? this.LoanData.Loan_Id : row.LoanIdentity;
    this.CaseModel.LoanId = this.LoanId;
    this.CaseModel.State_Name = "";
    this.CaseModel.District_Name = "";
    this.CaseModel.Court_Name = "";
    this.CaseModel.Act_Name = "";
    this.CaseModel.Section_Name = "";
    this.CaseModel.SubSection_Name = "";
    this.CaseModel.SOA_Doc = "";

    this.LMS_GetLoanDetails(this.LoanId);
    this.GetState();
    this.Get_PartnerForDropdown();
    this.GetEmployeeDropdown();
  }

  /*LMS_Get_Customer_By_LoanNo(LoanId) {
    this.CustomerDropdown = [];
    this.lmsService.GetCustomerByLoanNo({ Loan_Id: LoanId }).subscribe((res: any) => {
      console.log("GetCustomerByLoanNo", res);
      this.CustomerDropdown = res;
    })
  }
  
  OnSelectCustomer(data: any) {
    this.CaseModel.CustomerId = this.CustomerDropdown.filter(x => x.Customer == data.Customer)[0].CustomerId;
    this.getCustomerBankList();
    this.getChequeDetails();
  }*/

  LMS_GetLoanDetails(LoanId) {
    this.showSpinner = true;
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: LoanId })
      .subscribe((res: any) => {
        console.log("LMS_GetLoanDetails", res);
        this.showSpinner = false;
        let Data = [];
        if (this.LoanData) {
          Data = res.Item2.filter(
            (x) => x.CustomerId == this.LoanData.Customer_Id
          );
        } else {
          Data = res.Item2;
        }
        this.dataCustomerSource = new MatTableDataSource(Data);
      });
  }

  onCustomerChange(event: any, data: any) {
    if (event.checked == true) {
      this.SelectJsonData = [];
      this.SelectJsonData = data;
      this.CaseModel.CustomerId = data.CustomerId;
      this.CaseModel.Customer = data.Customer;
      this.getCustomerBankList();
      this.getChequeDetails();
    } else {
      this.CaseModel.CustomerId = "";
      this.CaseModel.Customer = "";
    }
  }

  GetState() {
    this._MasterService.GetState().subscribe((res) => {
      this.StateDropdown = res;
    });
  }

  OnSelectState(data: any) {
    this.CaseModel.District_Name = "";
    this.CaseModel.StateId = 0;
    if (this.CaseModel.State_Name) {
      if (this.StateDropdown.find((x) => x.State_Name == data.State_Name)) {
        this.CaseModel.StateId = this.StateDropdown.filter(
          (x) => x.State_Name == data.State_Name
        )[0].StateId;
        this.getDistrictList();
      }
    }

    if (this.CaseModel.StateId > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.State_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "State not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  getDistrictList() {
    this.RequestModel.StateId =
      this.CaseModel.StateId == "" ? 0 : parseInt(this.CaseModel.StateId);
    this._MasterService.GetDistricts(this.RequestModel).subscribe((result) => {
      this.DistrictDropDown = JSON.parse(JSON.stringify(result));
    });
  }

  OnSelectDistrict(data: any) {
    this.CaseModel.DistrictId = 0;
    if (this.CaseModel.District_Name) {
      if (
        this.DistrictDropDown.find((x) => x.District_Name == data.District_Name)
      ) {
        this.CaseModel.DistrictId = this.DistrictDropDown.filter(
          (x) => x.District_Name == data.District_Name
        )[0].DistrictId;
        this.GetCourtList();
      }
    }

    if (this.CaseModel.DistrictId > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.District_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "District not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  GetCourtList() {
    this._MasterService
      .Get_Court_Establishment({ Id: 0, SearchBy: "List" })
      .subscribe((res: any) => {
        this.CourtNameDropdown = res.filter(
          (x) => x.DistrictId == this.CaseModel.DistrictId
        );
      });
  }

  OnSelectCourt(data: any) {
    this.CaseModel.Court_EstablishmentId = 0;
    if (this.CaseModel.Court_Name) {
      if (this.CourtNameDropdown.find((x) => x.Court_Name == data.Court_Name)) {
        this.CaseModel.Court_EstablishmentId = this.CourtNameDropdown.filter(
          (x) => x.Court_Name == data.Court_Name
        )[0].Id;
        this.GetActDropdown();
      }
    }
    if (this.CaseModel.Court_EstablishmentId > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.Court_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Court not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  GetActDropdown() {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "ACT", Id: 0 })
      .subscribe((res: any) => {
        this.ActData = res;
      });
  }
  OnSelectAct(data: any) {
    this.CaseModel.UnderACT_Id = 0;

    if (this.CaseModel.Act_Name) {
      if (this.ActData.find((x) => x.Short_Name == data.Act_Name)) {
        this.CaseModel.UnderACT_Id = this.ActData.filter(
          (x) => x.Short_Name == data.Act_Name
        )[0].Id;
        this.GetSectionDropdown(this.CaseModel.UnderACT_Id);
      }
    }

    if (this.CaseModel.UnderACT_Id > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.Act_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Act not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  GetSectionDropdown(Id) {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "Section", Id: Id })
      .subscribe((res: any) => {
        this.SectionData = res;
      });
  }
  OnSelectSection(data: any) {
    this.CaseModel.UnderSectionId = 0;
    if (this.CaseModel.Section_Name) {
      if (this.SectionData.find((x) => x.Short_Name == data.Section_Name)) {
        this.CaseModel.UnderSectionId = this.SectionData.filter(
          (x) => x.Short_Name == data.Section_Name
        )[0].Id;
        this.GetSubSectionDropdown(this.CaseModel.UnderSectionId);
      }
    }

    if (this.CaseModel.UnderSectionId > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.Section_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Section not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  GetSubSectionDropdown(Id) {
    this._MasterService
      .Get_Legal_Master_Form_Dropdown({ Type: "SubSection", Id: Id })
      .subscribe((res: any) => {
        this.SubSectionData = res;
      });
  }
  OnSelectSubSection(data: any) {
    this.CaseModel.UnderSub_SectionId = 0;
    if (this.CaseModel.SubSection_Name) {
      if (
        this.SubSectionData.find((x) => x.Short_Name == data.SubSection_Name)
      ) {
        this.CaseModel.UnderSub_SectionId = this.SubSectionData.filter(
          (x) => x.Short_Name == data.SubSection_Name
        )[0].Id;
      }
    }

    if (this.CaseModel.UnderSub_SectionId > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.SubSection_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Sub section not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  Get_PartnerForDropdown() {
    this._LosService
      .Get_PartnerForDropdown({ PartnerType: "Advocate" })
      .subscribe((res: any) => {
        this.AdvocateDropdown = res;
      });
  }
  OnSelectCaseFiledBy(data: any) {
    this.CaseModel.Case_Filed_By = 0;
    if (this.CaseModel.Case_Filed_By_Name) {
      if (
        this.AdvocateDropdown.find(
          (x) => x.Partner_Name == data.Case_Filed_By_Name
        )
      ) {
        this.CaseModel.Case_Filed_By = this.AdvocateDropdown.filter(
          (x) => x.Partner_Name == data.Case_Filed_By_Name
        )[0].PartnerId;
      }
    }

    if (this.CaseModel.Case_Filed_By > 0) {
      console.log(" same");
    } else {
      console.log("not same");
      data.Case_Filed_By_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Case Filed by not found",
        ...this.configSuccess,
      });
    }
    return;
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Demand_Notice_Doc = "";
      delete data.DemandNoticeDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Demand_Notice_Doc = "";
      delete data.DemandNoticeDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Demand_Notice_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DemandNoticeDocData = reader.result
        .toString()
        .split(";base64,")
        .pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.Demand_Notice_Doc = "";
    delete data.DemandNoticeDocData;
  }

  GetEmployeeDropdown() {
    this.RequestModel.BranchId = 0;
    this.RequestModel.ProcessId = 0;
    this._MasterService
      .GetEmployeeDropdown(this.RequestModel)
      .subscribe((result) => {
        this.EmployeeDropdown = result;
      });
  }

  OnSelectPowerOfAttorney(data: any, Type: any) {
    if (this.CaseModel.Power_Of_Attorney) {
      if (Type == "Advocate") {
        this.CaseModel.Power_Of_AttorneyId = this.AdvocateDropdown.filter(
          (x) => x.Partner_Name == data.Power_Of_Attorney
        )[0].PartnerId;
      } else {
        this.CaseModel.Power_Of_AttorneyId = this.EmployeeDropdown.filter(
          (x) => x.EmpName == data.Power_Of_Attorney
        )[0].EmpId;
      }
    }
    console.log("this.CaseModel", this.CaseModel);
  }

  fileChangeListener1(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Power_Of_Attorney_Doc = "";
      delete data.AttornyDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Power_Of_Attorney_Doc = "";
      delete data.AttornyDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Power_Of_Attorney_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.AttornyDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile1(data: any, input: any) {
    input.value = "";
    data.Power_Of_Attorney_Doc = "";
    delete data.AttornyDocData;
  }

  getCustomerBankList() {
    this._MasterService
      .Get_BankAccount_Dropdown_For_Customer({
        Customer_Id: this.CaseModel.CustomerId,
      })
      .subscribe((res: any) => {
        console.log("this.res", res);
        this.CustomerBankDropdown = res;
      });
  }

  getChequeDetails() {
    this.lmsService
      .GetChequeDetails({ Loan_Id: this.LoanId })
      .subscribe((res: any) => {
        this.CustomerChqDropdown = res.filter(
          (item) =>
            item.PdcType == "RPDC" ||
            (item.PdcType == "SPDC" &&
              item.CustomerId == this.CaseModel.CustomerId)
        );
        console.log("this.CustomerChqDropdown", this.CustomerChqDropdown);
      });
  }

  fileChangeListener2(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Cheque_Doc = "";
      delete data.ChqDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Cheque_Doc = "";
      delete data.ChqDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Customer_Cheque_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.ChqDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile2(data: any, input: any) {
    input.value = "";
    data.Customer_Cheque_Doc = "";
    delete data.ChqDocData;
  }

  fileChangeListener3(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Bounce_Doc = "";
      delete data.ChqBounceDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Customer_Bounce_Doc = "";
      delete data.ChqBounceDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Customer_Bounce_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.ChqBounceDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile3(data: any, input: any) {
    input.value = "";
    data.Customer_Bounce_Doc = "";
    delete data.ChqBounceDocData;
  }

  fileChangeListener4(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.Closer_Doc = "";
      delete data.CloserDocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.Closer_Doc = "";
      delete data.CloserDocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.Closer_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.CloserDocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile4(data: any, input: any) {
    input.value = "";
    data.Closer_Doc = "";
    delete data.CloserDocData;
  }

  fileChangeListener5(data: any, files: any) {
    console.log("func clicked");
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.SOA_Doc = "";
      delete data.SOA_DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.SOA_Doc = "";
      delete data.SOA_DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.SOA_Doc = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.SOA_DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile5(data: any, input: any) {
    input.value = "";
    data.SOA_Doc = "";
    delete data.SOA_DocData;
  }

  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  OnSaveLegalCase() {
    this.CaseModel.VoucherId = 0;
    this.CaseModel.LoginUserId = this.currentUser.userId;

    delete this.CaseModel.Customer;
    delete this.CaseModel.State_Name;
    delete this.CaseModel.District_Name;
    delete this.CaseModel.Court_Name;
    delete this.CaseModel.Act_Name;
    delete this.CaseModel.Section_Name;
    delete this.CaseModel.SubSection_Name;
    delete this.CaseModel.Case_Filed_By_Name;
    delete this.CaseModel.Power_Of_Attorney;

    //console.log('this.SelectJsonData', this.SelectJsonData);

    let _data = {
      Legal_Master: this.CaseModel,
    };
    console.log("_data", this.LoanSummary);
    this.showSpinner = true;
    this._LegalService
      .Save_Legal_Master({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          if (this.CaseModel.DemandNoticeDocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.Demand_Notice_Doc,
              DocData: this.CaseModel.DemandNoticeDocData,
            });
          }
          if (this.CaseModel.AttornyDocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.Power_Of_Attorney_Doc,
              DocData: this.CaseModel.AttornyDocData,
            });
          }
          if (this.CaseModel.ChqDocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.Customer_Cheque_Doc,
              DocData: this.CaseModel.ChqDocData,
            });
          }
          if (this.CaseModel.ChqBounceDocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.Customer_Bounce_Doc,
              DocData: this.CaseModel.ChqBounceDocData,
            });
          }
          if (this.CaseModel.CloserDocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.Closer_Doc,
              DocData: this.CaseModel.CloserDocData,
            });
          }

          if (this.CaseModel.SOA_DocData) {
            this.uploadDoc({
              ApplicationNo: this.LoanSummary.Application_No,
              DocName: this.CaseModel.SOA_Doc,
              DocData: this.CaseModel.SOA_DocData,
            });
          }

          console.log("this.CaseModel.SOA_DocData", this.CaseModel.SOA_DocData);

          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnClose();
          this.close.emit(true);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
        this.loading = false;
      });
  }
}
