import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
declare var $: any;


@Component({
  selector: 'app-lms-npa-marking',
  templateUrl: './lms-npa-marking.component.html',
  styleUrls: ['./lms-npa-marking.component.scss']
})
export class LmsNpaMarkingComponent implements OnInit {
  currentUser: any;
  TypeNPA: any[] = [];
  TypeRelationNPA: any[] = [];
  showSpinner: boolean = false;
  dataSourceNPA: any;
  displayedColumnsNPA: string[] = [
    "Loan_Id", "LoanAcNo", "Loan_Date", "ExpiryDate",	"Close_Date",	"CustomerName",	"Branch_Name", "Product_name",
    "Loan_Acc_Category", "Application_Case_IRR", "Application_LoanTenure", "Application_LTV", "DPD_Days",
    "Due_On",	"FutureDue_POS",	"OD_Installment",	"Charges_Balance", "Exposer","Interest_Reversal", "Charges_Reversal",	
    "Gross_NPA", "Provision_Rate", "Assets_Type",	"Provision_Amount",	"Net_NPA", "Action"
  ];

  dataSourceRelation: any;
  displayedColumnsRelation: string[] = [
    "Loan_Id", "LoanAcNo", "Loan_Date",	"ExpiryDate",	"Close_Date",	"CustomerName",	"Branch_Name", "Product_name",
    "Loan_Acc_Category", "Application_Case_IRR", "Application_LoanTenure", "Application_LTV", "DPD_Days",
    "Due_On",	"FutureDue_POS",	"OD_Installment",	"Charges_Balance", "Exposer","Interest_Reversal", "Charges_Reversal",	
    "Gross_NPA", "Provision_Rate", "Assets_Type", "Provision_Amount",	"Net_NPA", "Action"
  ];
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('paginatorlist', { read: MatPaginator }) paginatorlist: MatPaginator;
  @ViewChild('SortTable1', { read: MatSort }) sort1: MatSort;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SlabData: any[] = [];
  AsOnDate: any = new Date();
  SlabId: any = "";
  selectedId: any;

  dataSourceVoucher: any;
  SelectedData: any = {};
  displayedColumnsVoucher: string[] = ['TranType', 'CaseNo', 'Customer', 'Branch_Name', 'ChagesHead', 'Account_Name', 'AmountDR', 'AmountCR'];
  TotalAmountDR: any;
  TotalAmountCR: any;

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private _MasterService: MasterService,
    private dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("NPA Summary Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getSlabsList();
  }

  getSlabsList() {
    this._MasterService.Get__Slab_dropdown({ Type: "NPA", LoginUserId: this.currentUser.userId }).subscribe((res) => {
      this.SlabData = JSON.parse(JSON.stringify(res));
    });
  }

  GetReportNPAMarking(){
    this.showSpinner = true;
    this.lmsService.Report_NPA_Marking({ AsOnDate: this.AsOnDate, SlabId: this.SlabId }).subscribe((res: any) => {
      //console.log("RESULT : ",res);      
      // this.JsonData = JSON.parse(JSON.stringify(res));
      this.TypeNPA = res.filter(x => x.Type == 'NPA');
      this.TypeRelationNPA = res.filter(x => x.Type != 'NPA');
      
      this.dataSourceNPA = new MatTableDataSource(this.TypeNPA);
      this.dataSourceNPA.sort = this.sort;
      this.dataSourceNPA.paginator = this.paginator;  
      
      this.dataSourceRelation = new MatTableDataSource(this.TypeRelationNPA);
      this.dataSourceRelation.sort = this.sort1;
      this.dataSourceRelation.paginator = this.paginatorlist; 
      this.showSpinner = false;
    });
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("npa_marking") as HTMLTableElement;
    const worksheetName = 'NPA MARKING'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  exportTable1(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("npa_marking_relation") as HTMLTableElement;
    const worksheetName = 'NPA MARKING RELATION'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }

  totaltypewise(type) {
    if (this.dataSourceNPA != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceNPA.filteredData.length; i++) {
        sum += Number(this.dataSourceNPA.filteredData[i][type]);
      }
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  } 

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  public highlightRow1(row) {
    this.selectedId = row.Loan_Id;
  }

  GoToDetails(row){
    console.log("row", row);
    this.SelectedData = {
      ToDate : this.AsOnDate,
      Loan_Id: row.Loan_Id,
      LoginUserId: this.currentUser.userId,
      OD_Inst: row.OD_Installment,
      OD_Principal: row.OD_Principal,
      Interest_Reversal: row.Interest_Reversal,
      Provision_Amount: row.Provision_Amount,
      AssetsType_Id: row.Assets_TypeId
    };

    this.NPA_View_Acc_Voucher();
  }
  OnCloseVoucher(){
    $(`#ViewVoucher`).modal("hide");
  }

  NPA_View_Acc_Voucher(){
    let _data = {
      NPA: this.SelectedData
    }
    console.log('_data', _data);
    this.lmsService.NPA_View_Acc_Voucher({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      //console.log("RESULT : ",res);   
      res.forEach(x => {
        x.AmountDR = 0.00;
        x.AmountCR = 0.00;
        if (x.TranType == 'CR')
          x.AmountCR = x.Amount;
        if (x.TranType == 'DR')
          x.AmountDR = x.Amount;
      });
      
      if(res[0].CODE == 0){
        this.dataSourceVoucher = new MatTableDataSource(res);
        $(`#ViewVoucher`).modal('show');
        $(`#ViewVoucher`).css("z-index", "1050");
      }
      this.showSpinner = false;
    });
  }

  totalAmountDR() {
    if (this.dataSourceVoucher != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceVoucher.filteredData.length; i++) {
        sum += Number(this.dataSourceVoucher.filteredData[i]['AmountDR']);
      }
      this.TotalAmountDR = sum;
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  }
  totalAmountCR() {
    if (this.dataSourceVoucher != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceVoucher.filteredData.length; i++) {
        sum += Number(this.dataSourceVoucher.filteredData[i]['AmountCR']);
      }
      this.TotalAmountCR = sum;
      return (sum > 0) ? sum.toFixed(2) : "";      
    }
    return 0;
  }

  NPA_Save_Acc_Voucher(){
    let _data = {
      NPA: this.SelectedData
    }
    console.log('_data', _data);

    this.dialog.openConfirmDialog(`Are you sure? you want to regenerate allocation.`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } 
      else {
        this.showSpinner = true;
        this.lmsService.NPA_Save_Acc_Voucher({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
          this.showSpinner = false;
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.GetReportNPAMarking();
            this.OnCloseVoucher();
          } 
          else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        });
      }
    });
  }
}
