<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div class="row m-0 align-items-end formborder">
  <div class="col-md-3 p-1">
    <span> Select Product </span>
    <select
      name="FilterProductId"
      id="FilterProductId"
      [(ngModel)]="FilterProductId"
      class="form-control input-text-css"
      (change)="GetFileCheckingMaster_List()"
    >
      <option value="">Select Product</option>
      <option
        *ngFor="let productList of productDropdown"
        [value]="productList.ProductId"
      >
        {{ productList.Product_Name }}
      </option>
    </select>
  </div>

  <div class="col-md-4">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-2 p-1 text-right">
    <button
      class="mt-3 btn font-size-12 button-btn"
      (click)="onOpenFileCheckingMasterModal()"
    >
      Add New
    </button>
  </div>
</div>

<div class="table-responsive mt-3">
  <mat-table
    [dataSource]="dataSource"
    matSort
    style="overflow: auto; max-width: 100%; height: 60vh"
  >
    <ng-container matColumnDef="Id">
      <mat-header-cell
        mat-sort-header
        class="grid-header"
        style="max-width: 100px"
        *matHeaderCellDef
      >
        #
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row; let i = index"
        class="grid-cell"
        style="max-width: 100px; max-height: 5px"
      >
        {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Name">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Name }}
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="CreateOn">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Create On</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.CreateOn }}
      </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="LoanProduct">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Loan Product
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.LoanProduct }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="EditAction">
      <mat-header-cell
        mat-sort-header
        class="grid-header"
        style="max-width: 100px"
        *matHeaderCellDef
      >
        Action
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="grid-cell"
        style="max-width: 100px"
      >
        <i
          class="fas fa-lg fa-edit margin-right-4xs cursor-style"
          (click)="onEditFileCheckingMaster(row)"
          aria-label="true"
        ></i>
        <i
          class="fa fa-minus-circle font-size-12"
          (click)="onDeleteFileCheckingMaster(row)"
          class="fa fa-trash style-delete"
          aria-label="true"
        ></i>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginatorRef
    [pageSizeOptions]="[20, 50]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<div
  class="modal fade in"
  id="addNewFileCheckingMasterData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveFileCheckingMaster()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ HeaderText }} File Checking Master
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Check Point Name</span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refFIQuestion="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refFIQuestion.invalid,
                  'alert-warning':
                    refFIQuestion.invalid &&
                    (refFIQuestion.dirty ||
                      refFIQuestion.touched ||
                      refFIQuestion.untouched)
                }"
                [(ngModel)]="FIQuestion"
                placeholder="Check Point Name"
                name="FIQuestion"
                id="FIQuestion"
                class="form-control input-text-css"
              />
            </div>
          </div>

          <div
            class="row m-0 font-size-12 align-items-center"
            style="display: none"
          >
            <div class="col-md-4 p-1">
              <span class="required-lable">File Checking Type</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="FIQuestionType"
                id="FIQuestionType"
                #refFIQuestionType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refFIQuestionType.invalid,
                  'alert-warning':
                    refFIQuestionType.invalid &&
                    (refFIQuestionType.dirty ||
                      refFIQuestionType.touched ||
                      refFIQuestionType.untouched)
                }"
                [(ngModel)]="FIQuestionType"
                class="form-control input-text-css"
                required
                disabled
              >
                <option value="Selection">Selection</option>
              </select>
            </div>
          </div>
          <div *ngIf="FIQuestionType == 'Selection'" class="row m-0">
            <div class="col-md-4 p-0"></div>
            <div class="col-md-8 p-0">
              <div class="p-1 text-right" style="width: 100%">
                <i
                  class="fa fa-plus-circle font-size-15"
                  (click)="onAddNewRow()"
                  style="color: green; cursor: pointer"
                  aria-label="true"
                ></i>
              </div>
              <div
                *ngFor="let question of QuestionTypeOptions; let i = index"
                class="row m-0 font-size-12 align-items-center"
              >
                <div class="col-11 p-1">
                  <input
                    type="text"
                    [(ngModel)]="question.FI_Questions_Option"
                    placeholder=""
                    name="question{{ i }}"
                    id="question{{ i }}"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-1 p-1">
                  <i
                    *ngIf="QuestionTypeOptions.length > 1"
                    (click)="onDeleteRow(i)"
                    class="fa fa-trash style-delete"
                    style="cursor: pointer"
                    aria-label="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-0 font-size-12">
            <div class="col-md-4 p-1">Product</div>
            <div
              class="col-md-8 p-1"
              style="max-height: 170px; overflow: auto; border: 1px solid #dddd"
            >
              <div *ngFor="let productList of productDropdown; let i = index">
                <mat-checkbox
                  id="productList{{ i }}"
                  name="productList{{ i }}"
                  [(ngModel)]="productList.isAcive"
                  class="example-margin font-size-12"
                >
                  {{ productList.Product_Name }} </mat-checkbox
                ><br />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveFileCheckingMaster()"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
            class="mt-3 btn font-size-12 button-btn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
