<button
  type="button"
  mat-raised-button
  class="font-size-12 button-btn"
  (click)="LMS_GetNocLatterDetailsViewModel()"
>
  Print Letter
</button>
<!--<i class="fa fa-print btn_detail" (click)="LMS_GetNocLatterDetailsViewModel()" aria-label="true"></i>-->

<div
  class="modal fade in"
  id="NOCmodel{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="
    background-color: rgba(0, 0, 0, 0.5);
    text-align: initial;
    overflow: auto;
  "
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          NOC Letter
        </h6>
        <button
          type="button"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          (click)="printLetter()"
        >
          download
        </button>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body" id="pdfDemandNotice">
        <div class="row mb-0" style="font-size: 10px">
          <div class="col-md-12 ck-content">
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
