<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div *ngIf="loanSummary == undefined">
  <div class="row m-0 mt-2 formborder" *ngIf="customerDetail">
    <div class="col-md-2">
      <h6 class="fs-12">Application No.</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.ApplicationNo }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan No</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.LoanNo }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Application Date</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.ApplicationDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan Date</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Branch</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.Branch }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Product</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.Product }}</p>
    </div>
  </div>

  <div class="mt-2">
    <div class="">
      <div class="table-responsive">
        <mat-table [dataSource]="CustomerDataSource">
          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header mw350" *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw350"
            >
              <!--{{ row.Customer }}-->
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
              >
                {{ row.Customer }}
              </app-lms-customer-view-model>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FatherName">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">{{
              row.FatherName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Customer Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
              {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Gender/Age</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">{{
              row.GenderAge
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Mobile No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
            <div *ngIf="currentUser.IsMasking">
              {{ row.PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMasking">
              {{ row.PhoneNo }}
            </div>
          
          
          </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>
      <hr />
    </div>
  </div>

  <div class="formborder mt-2">
    <ng-container *ngIf="customerDetail">
      <div class="row m-0 mt-1">
        <div class="col-md-2">
          <h6 class="fs-12">Loan Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.InterestAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Agree Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.AgreedAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">First EMI Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.FirstEMIDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Last EMI Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LastEMIDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">IRR (%)</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.IRR }} %</p>
        </div>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">ROI (%)</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ROI }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LTV }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No. of Installment</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.NoOfInstallment }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure(Months)</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.Tenure }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="row m-0 formborder mt-2" *ngIf="customerDetail">
  <div class="col-md-6">
    <div class="table-responsive">
      <mat-table [dataSource]="ChargesDataSource">
        <ng-container matColumnDef="UpfrontCharges">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Upfront Charges</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ChagesHead
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px"
            >Total:</mat-footer-cell
          >
        </ng-container>

        <ng-container matColumnDef="Deduction">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Debit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Deduction
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">{{
            calculateDeductionTotal()
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Receipt">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Credit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Receipt
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">{{
            calculateReceiptTotal()
          }}</mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedChargesColumns"
        ></mat-header-row>
        <mat-row
          [ngClass]="{ 'bold-text': row.ChargeHeadId == 28 }"
          *matRowDef="let row; columns: displayedChargesColumns"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer"
          style="font-weight: bold; font-size: 12px"
          *matFooterRowDef="displayedChargesColumns"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
  <div class="col-md-6">
    <div class="table-responsive">
      <mat-table [dataSource]="EmiDataSource">
        <ng-container matColumnDef="EMI_Pattern">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI Pattern</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.EMI_Pattern
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px"
            >Total:</mat-footer-cell
          >
        </ng-container>

        <ng-container matColumnDef="NoOfEMI">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >No. Of EMI</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.NoOfEMI
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">{{
            calculateTotalEMI()
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="EMIAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.EMIAmount
          }}</mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            style="font-size: 12px"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TotalAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Total</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TotalAmount
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">{{
            calculateTotalAmount()
          }}</mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedEMIColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedEMIColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer bold-text"
          style="font-weight: bold; font-size: 12px"
          *matFooterRowDef="displayedEMIColumns"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>

<div class="mt-2 formborder" *ngIf="PDCDataSource.filteredData.length > 0">
  <div class="col-md-12">
    <h1 class="fs-12 mt-2">Disbursment Detail</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="PDCDataSource">
        <ng-container matColumnDef="Customer">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer }}
          </mat-cell>
        </ng-container>

        <!--<ng-container matColumnDef="PdcType">
    <mat-header-cell class="grid-header" *matHeaderCellDef>Type</mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.PdcType }}</mat-cell>
  </ng-container>-->

        <ng-container matColumnDef="Bankname">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Bank Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Bankname }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AccountNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Account No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AccountNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="IFSCCode">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >IFSCCode</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.IFSCCode }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AmountDisbursment">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AmountDisbursment }}
          </mat-cell>
        </ng-container>
        <!--<ng-container matColumnDef="ChFrom">
    <mat-header-cell class="grid-header" *matHeaderCellDef>Chq From</mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ChFrom }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ChTo">
    <mat-header-cell class="grid-header" *matHeaderCellDef>Chq To</mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.ChTo }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="NoOfChqs">
    <mat-header-cell class="grid-header" *matHeaderCellDef>No Of Chqs</mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell">{{ row.NoOfChqs }}</mat-cell>
  </ng-container>-->

        <mat-header-row *matHeaderRowDef="displayedPDCColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedPDCColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="row m-0 formborder mt-2"
  *ngIf="PreEMIInterestPayment && PreEMIInterestPayment.filteredData.length > 0"
>
  <div class="col-md-12">
    <h1 class="fs-12 mt-2">PreEMI Interest Detail</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="PreEMIInterestPayment">
        <ng-container matColumnDef="VoucherId">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{
            i + 1
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="VoucherDate">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.VoucherDate
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="PaymentDone">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Payment Done</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PaymentDone
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="PreEMIDue">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Pre-EMI Due</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PreEMIDue
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            calculatePreEMIDue()
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="PreEMIRecvd">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Pre-EMI Recvd</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PreEMIRecvd
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            calculatePreEMIRecvd()
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="PreEmiBalance">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PreEmiBalance
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{
            PreEmiBalance
          }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="InterestDays">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >InterestDays</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InterestDays
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateOn">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Create On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Voucher_CreateOn
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateBy">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Create By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Voucher_CreateBy
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsPreEMI"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsPreEMI"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor mat-header-cell"
          *matFooterRowDef="displayedColumnsPreEMI"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="row m-0 formborder mt-2"
  *ngIf="
    PreEMIInterestPaymentNew && PreEMIInterestPaymentNew.filteredData.length > 0
  "
>
  <div class="col-md-12">
    <h1 class="fs-12 mt-2">PreEMI Interest Detail</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="PreEMIInterestPaymentNew">
        <ng-container matColumnDef="Id">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">{{
            i + 1
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Voucher_Date
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="ChargeHeadCategory">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Head</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ChargeHeadCategory
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Amount
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <!-- {{
            calculatePreEMIDue()
          }} -->
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Balance">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Balance
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <!-- {{
            calculatePreEMIRecvd()
          }} -->
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Days">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Days</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Days
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="InterestDue">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >InterestDue</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InterestDue
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="InterestReceived">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Interest Received</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InterestReceived
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="InterestBalance">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Interest Balance</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InterestBalance
          }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsPreEMINew"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsPreEMINew"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor mat-header-cell"
          *matFooterRowDef="displayedColumnsPreEMINew"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>
<div class="Payment-Detail formborder mt-2">
  <div class="row m-0 col-md-12 mt-3">
    <div class="col-md-12 bg-success1">Payment Detail :</div>
  </div>
  <!-- Payment Type Bank -->
  <ng-container>
    <form #f="ngForm" novalidate>
      <div class="row m-0 mt-2" *ngFor="let Bank of BankModel; let i = index">
        <div class="col-md-1 pr-1">
          <span class="required-lable" *ngIf="i == 0">Type</span>
          <select
            name="Type{{ i }}"
            id="Type{{ i }}"
            #refType="ngModel"
            required
            [(ngModel)]="Bank.Type"
            disabled
            class="form-control input-text-css"
            (change)="onChangePaymentType(Bank, i)"
            [ngClass]="{
              'is-invalid': f.submitted && refType.invalid,
              'alert-warning':
                refType.invalid &&
                (refType.dirty || refType.touched || refType.untouched)
            }"
          >
            <option value="">Select Type</option>
            <option value="Customer">Customer</option>
            <option value="Dealer">Dealer</option>
            <!-- <option value="Cash">Cash</option>
            <option value="Other">Other</option> -->
          </select>
        </div>
        <div class="col-md-7 pl-1 pr-1">
          <div class="row m-0">
            <div class="col-md-2 pr-1 pl-1" *ngIf="Bank.Type == 'Customer'">
              <span class="required-lable" *ngIf="i == 0">Sub Type</span>
              <select
                name="SubType{{ i }}"
                id="SubType{{ i }}"
                #refSubType="ngModel"
                required
                [(ngModel)]="Bank.SubType"
                disabled
                class="form-control input-text-css"
                (change)="ChangeSubType(Bank, i)"
                [ngClass]="{
                  'is-invalid': f.submitted && refSubType.invalid,
                  'alert-warning':
                    refSubType.invalid &&
                    (refSubType.dirty ||
                      refSubType.touched ||
                      refSubType.untouched)
                }"
              >
                <option value="">Select Type</option>
                <option value="Bank">Bank</option>
                <option value="Cash">Cash</option>
                <option value="OtherParty">Direct Payment</option>
                <option value="Other">Adjust To Accounts</option>
                <option value="Dealer">Dealer</option>
                <option value="Partner">Partner</option>
              </select>
            </div>

            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="Bank.Type == 'Customer' && Bank.SubType == 'Bank'"
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <select
                name="Customer{{ i }}"
                id="Customer{{ i }}"
                #refCustomer="ngModel"
                required
                [(ngModel)]="Bank.Customer"
                disabled
                class="form-control input-text-css"
                (change)="ChangeBankACNo(Bank, i)"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              >
                <option value="">Select A/C</option>
                <option
                  *ngFor="let row of BankDetailDropdown"
                  [value]="row.B_customerId + '_' + row.Type"
                >
                  {{ row.CustomerBankName }} ({{ row.Type }})
                </option>
              </select>
            </div>

            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="
                (Bank.Type == 'Customer' && Bank.SubType == 'Other') ||
                (Bank.Type == 'Customer' && Bank.SubType == 'Dealer')
              "
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.AcHead"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                list="dynmicACHead"
                autocomplete="off"
                disabled
                required
                #refCustomer="ngModel"
                placeholder="Select Dealer"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicACHead">
                <option
                  *ngFor="let x of OtherDropdown"
                  [value]="x.AccountId + '_' + x.Partner_AccountHolderName"
                ></option>
              </datalist>
            </div>
            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="Bank.Type == 'Customer' && Bank.SubType == 'OtherParty'"
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <input
                type="text"
                class="form-control input-text-css"
                [(ngModel)]="Bank.Customer"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                required
                disabled
                #refCustomer="ngModel"
                placeholder="Customer Name"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
            </div>
            <div class="col-md-5 pl-1 pr-1" *ngIf="Bank.Type == 'Dealer'">
              <span class="required-lable" *ngIf="i == 0">Dealer</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.Dealer_Name"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                list="dynmicBank"
                autocomplete="off"
                required
                #refCustomer="ngModel"
                placeholder="Select Dealer"
                disabled
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicBank">
                <option
                  *ngFor="let x of DealerDropdown"
                  [value]="x.Account_Name"
                  title="x.Account_Name"
                ></option>
              </datalist>
            </div>
            <div class="col-md-3 pl-1 pr-1" *ngIf="Bank.SubType == 'Partner'">
              <span class="required-lable" *ngIf="i == 0">Partner</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.AcHead"
                id="Customer1{{ i }}"
                name="Customer1{{ i }}"
                list="dynmicBank1"
                autocomplete="off"
                required
                disabled
                #refCustomer="ngModel"
                placeholder="Select Partner"
                [disabled]="Bank.disable"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicBank1">
                <option
                  *ngFor="let x of PartnerDropdown"
                  [value]="x.AccountId + '_' + x.Partner_AccountHolderName"
                ></option>
              </datalist>
            </div>
            <ng-container
              *ngIf="
                (Bank.SubType == 'Bank' && Bank.Customer) ||
                (Bank.SubType == 'Other' && Bank.AcHead) ||
                Bank.SubType == 'OtherParty' ||
                (Bank.SubType == 'Dealer' && Bank.AcHead) ||
                (Bank.SubType == 'Partner' && Bank.AcHead)
              "
            >
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">A/C No.</span>
                <input
                  type="text"
                  name="AccountNo{{ i }}"
                  id="AccountNo{{ i }}"
                  [(ngModel)]="Bank.AccountNo"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-1 pl-1 pr-1">
                <span *ngIf="i == 0">Bank</span>
                <input
                  type="text"
                  name="BankName{{ i }}"
                  id="BankName{{ i }}"
                  [(ngModel)]="Bank.BankName"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">IFSC</span>
                <input
                  type="text"
                  name="BankIFSC{{ i }}"
                  id="BankIFSC{{ i }}"
                  [(ngModel)]="Bank.BankIFSC"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">Branch</span>
                <input
                  type="text"
                  name="BankBranch{{ i }}"
                  id="BankBranch{{ i }}"
                  [(ngModel)]="Bank.BankBranch"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>

            <ng-container *ngIf="Bank.SubType == 'Cash'">
              <div class="col-md-10 pl-1 pr-1">
                <span *ngIf="i == 0">Customer Name</span>
                <input
                  type="text"
                  name="CustomerName{{ i }}"
                  id="CustomerName{{ i }}"
                  [(ngModel)]="Bank.CustomerName"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="Bank.SubType == 'Other'">
    <div class="col-md-10 pl-1 pr-1">
      Other
    </div>
  </ng-container> -->
          </div>
        </div>
        <div class="col-md-2 pl-1 pr-1 text-right">
          <span class="required-lable" *ngIf="i == 0">Pay Date</span>
          <!--<input type="number" min="0" name="Amount{{i}}" id="Amount{{i}}" class="form-control input-text-css text-right pr-1" required
          #refAmount="ngModel" [(ngModel)]="Bank.Amount" placeholder="Amount" (change)="getTotalCalculation()" (keyup.enter)="getTotalCalculation()"
          [ngClass]="{ 'is-invalid': f.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched) }">-->
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              [max]="
                Bank.SubType == 'Other' || Bank.Type == 'Dealer'
                  ? CurrentDate
                  : ''
              "
              placeholder="DD/MM/YYYY"
              name="PayDate{{ i }}"
              id="PayDate{{ i }}"
              #refPayDate="ngModel"
              (dateChange)="onPayDateChange()"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refPayDate.invalid,
                'alert-warning':
                  refPayDate.invalid &&
                  (refPayDate.dirty ||
                    refPayDate.touched ||
                    refPayDate.untouched)
              }"
              class="form-control input-text-css"
              [(ngModel)]="Bank.PayDate"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-1 pl-1 pr-1 text-right">
          <span class="required-lable" *ngIf="i == 0">Amount</span>
          <input
            type="number"
            min="0"
            name="Amount{{ i }}"
            id="Amount{{ i }}"
            class="form-control input-text-css text-right pr-1"
            required
            #refAmount="ngModel"
            [(ngModel)]="Bank.Amount"
            placeholder="Amount"
            disabled
            (change)="getTotalCalculation()"
            (keyup.enter)="getTotalCalculation()"
            [ngClass]="{
              'is-invalid': f.submitted && refAmount.invalid,
              'alert-warning':
                refAmount.invalid &&
                (refAmount.dirty || refAmount.touched || refAmount.untouched)
            }"
          />
        </div>

        <div
          class="col-md-1 pl-1 pr-1"
          [ngStyle]="{ 'padding-top': i == 0 ? '20px' : '0' }"
        >
          <button
            type="button"
            *ngIf="
              Bank.PayDate <= CurrentDate &&
              Status == 'Pending' &&
              Bank.Voucher_Id == 0
            "
            name="PaymentVoucher{{ i }}"
            id="PaymentVoucher{{ i }}"
            (click)="PaymentModel(Bank)"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Pay
          </button>
          <i
            *ngIf="Status == 'Completed' || Bank.Voucher_Id > 0"
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 22px"
          ></i>
        </div>
      </div>
      <ng-container>
        <div class="row m-0 col-md-12 mt-3">
          <div class="row col-md-12" style="height: 43px">
            <div class="col-md-10 pr-1 bg-success1">
              <div>
                <div style="float: left">
                  <div style="float: left">
                    <div class="Text-align Float-left">
                      Current First EMI Date
                    </div>
                    <div class="datepicker_feild Float-Right">
                      <input
                        [matDatepicker]="picker"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        disabled
                        name="Application_FirstEMIDate"
                        id="Application_FirstEMIDate"
                        #refApplication_FirstEMIDate="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refApplication_FirstEMIDate.invalid,
                          'alert-warning':
                            refApplication_FirstEMIDate.invalid &&
                            (refApplication_FirstEMIDate.dirty ||
                              refApplication_FirstEMIDate.touched ||
                              refApplication_FirstEMIDate.untouched)
                        }"
                        class="form-control input-text-css"
                        [(ngModel)]="customerDetail.Application_FirstEMIDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>

                  <!--<div style=" float: right; margin-left: 17px;" *ngIf="!IsFullyPayment">
                    <div class="Float-left">
                      <mat-checkbox name="ChangeFirstEMIDate" [(ngModel)]="ChangeFirstEMIDate" class="mt-1"  >
                        Change First EMI Date
                      </mat-checkbox>-->
                  <!--{{FinalFirstEMIDate | date}}-->
                  <!--<div class="datepicker_feild Float-Right" *ngIf="ChangeFirstEMIDate">
                      <input [matDatepicker]="picker" dateConvert placeholder="DD/MM/YYYY" [min]="minFinalFirstEMIDate" (dateChange)="onFinalFirstEMIDateChange()" name="FinalFirstEMIDate" id="FinalFirstEMIDate" #refFinalFirstEMIDate="ngModel" required
                             [ngClass]="{ 'is-invalid': f.submitted && refFinalFirstEMIDate.invalid, 'alert-warning': refFinalFirstEMIDate.invalid  && (refFinalFirstEMIDate.dirty || refFinalFirstEMIDate.touched || refFinalFirstEMIDate.untouched) }"
                             class="form-control input-text-css" [(ngModel)]="FinalFirstEMIDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>-->
                  <!--<div class="Text-align Float-Right" *ngIf="RemainingAmount!=0">
                    Remaining Amount
                    <span class="form-control1 input-text-css text-right" style="width: 85px;" *ngIf="RemainingAmount!=0">{{RemainingAmount}}</span>
                  </div>-->
                  <!--</div>-->
                </div>
                <div class="Text-align" style="text-align: right; float: right">
                  Total:
                </div>
              </div>
            </div>
            <div class="col-md-2 pr-2 bg-success1">
              <span
                class="form-control1 input-text-css text-right"
                style="margin-left: 34px; width: 85px"
                *ngIf="totalCalculator"
                >{{ totalCalculator.total }}</span
              >
            </div>
          </div>
        </div>
        <!--<div class="row m-0 mt-2" *ngIf="!IsFullyPayment">

          <div class="col-md-10">
            <h6 class="fs-12" *ngIf="RemainingAmount!=0">Remaining Amount {{RemainingAmount}}</h6>

          </div>-->
        <!--<div class="col-md-2 pr-1 text-right" *ngIf="totalCalculator">
          <button type="button" (click)="SaveDisbursment()" mat-raised-button class="btn_default"
                  [class.spinner]="loading" [disabled]="!f.form.valid && FinalFirstEMIDate">
            Save
          </button>
        </div>-->
        <!--</div>-->
      </ng-container>
    </form>
  </ng-container>
</div>

<br />

<div class="formborder mt-2">
  <div class="row m-0 col-md-12 mt-2">
    <div class="col-md-12 bg-success1">User Detail :</div>
  </div>
  <ng-container *ngIf="customerDetail">
    <div class="row m-0 mt-2">
      <div class="col-md-3">
        <h6 class="fs-12">Application Created By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.Application_CreateBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">FI/PD Done By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.FIDoneBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Credit Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.CreditApprovedBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Loan Converted By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.LoanConvertBy }}</p>
      </div>
    </div>
    <div class="row m-0 mt-3">
      <div class="col-md-3">
        <h6 class="fs-12">DV Created By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_CreateBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">DV Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_AuthorizBy }}</p>
      </div>

      <div class="col-md-3">
        <h6 class="fs-12">Payment Aproved by</h6>
        <p class="lead m-0 fs-12">
          {{ Disbursment_Payment_Detail?.Authorized_By }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Payment Aproved On</h6>
        <p class="lead m-0 fs-12">
          {{ Disbursment_Payment_Detail?.Authorized_On }}
        </p>
      </div>
    </div>
  </ng-container>
</div>

<div
  class="modal fade in"
  id="PayModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Payment
        </h6>
        <button
          type="button"
          (click)="onClosePayModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-2 formborder" *ngIf="customerDetail">
          <div class="col-md-2">
            <h6 class="fs-12">Application No.</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.ApplicationNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan No</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.LoanNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Application Date</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.ApplicationDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan Date</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Branch</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.Branch }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Product</h6>
            <p class="lead m-0 fs-12">{{ customerDetail.Product }}</p>
          </div>
        </div>
        <form #ddf="ngForm" novalidate class="formborder mt-2">
          <div class="row m-0 align-items-center">
            <div class="col-md-4" *ngIf="InterestpayAmount > 0">
              <span>Select Pre-EMI Interest </span>
              <div>
                <mat-radio-group
                  id="IsLoanDeduct"
                  [(ngModel)]="IsLoanDeduct"
                  (ngModelChange)="onIsDeductLoan($event)"
                  *ngIf="InterestpayAmount > 0"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button value="1">Deduct</mat-radio-button>
                  <mat-radio-button value="0" checked>Due</mat-radio-button>
                  <mat-radio-button value="2" checked>Skip</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="col-md-3">
              <span class="required-lable">Select Account </span>
              <select
                name="DRAccountId"
                id="DRAccountId"
                required
                #refDRAccountId="ngModel"
                (change)="accountTagChange()"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refDRAccountId.invalid,
                  'alert-warning':
                    refDRAccountId.invalid &&
                    (refDRAccountId.dirty ||
                      refDRAccountId.touched ||
                      refDRAccountId.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.DRAccountId"
                class="form-control input-text-css"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let data of accountTagDropdown"
                  [value]="data.AccountId"
                >
                  {{ data.Account_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-3" *ngIf="InterestDays > 0">
              <span>InterestDays</span>
              <input
                type="text"
                placeholder="Cheque No"
                disabled
                [(ngModel)]="InterestDays"
                name="InterestDays"
                id="InterestDays"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div
            class="row m-0 align-items-center"
            *ngIf="voucherModel.Voucher.Voucher_Tag == 'Bank'"
          >
            <div
              class="col-md-3"
              *ngIf="
                voucherModel.Voucher.DRAccountId != '' &&
                voucherModel.Voucher.Voucher_Tag == 'Bank'
              "
            >
              <span>Mode</span>
              <select
                name="Voucher_Mode_of_Payment"
                id="Voucher_Mode_of_Payment"
                class="form-control input-text-css"
                #refVoucher_Mode_of_Payment="ngModel"
                [(ngModel)]="voucherModel.Voucher.Voucher_Mode_of_Payment"
                (change)="onChangeMode()"
              >
                <option value="Cheque">Cheque</option>
                <option value="RTGS">RTGS</option>
                <option value="Gateway">E-Gateway</option>
                <option value="NEFT">NEFT</option>
              </select>
            </div>
            <ng-container
              *ngIf="
                voucherModel.Voucher.DRAccountId != '' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque'
              "
            >
              <div class="col-md-3">
                <span class="required-lable">Cheque No</span>
                <input
                  type="text"
                  required
                  #refVoucher_Cheque_No="ngModel"
                  placeholder="Cheque No"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && refVoucher_Cheque_No.invalid,
                    'alert-warning':
                      refVoucher_Cheque_No.invalid &&
                      (refVoucher_Cheque_No.dirty ||
                        refVoucher_Cheque_No.touched ||
                        refVoucher_Cheque_No.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_No"
                  name="Voucher_Cheque_No"
                  id="Voucher_Cheque_No"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span class="required-lable">Cheque Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="pickervcd"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="Voucher_Cheque_Date"
                    id="Voucher_Cheque_Date"
                    #refVoucher_Cheque_Date="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        ddf.submitted && refVoucher_Cheque_Date.invalid,
                      'alert-warning':
                        refVoucher_Cheque_Date.invalid &&
                        (refVoucher_Cheque_Date.dirty ||
                          refVoucher_Cheque_Date.touched ||
                          refVoucher_Cheque_Date.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="voucherModel.Voucher.Voucher_Cheque_Date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervcd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervcd></mat-datepicker>
                </div>
              </div>

              <div class="col-md-3">
                <span class="required-lable">Bank Name</span>
                <input
                  type="text"
                  required
                  #refVoucher_Bank_Name="ngModel"
                  placeholder="Bank Name"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && refVoucher_Bank_Name.invalid,
                    'alert-warning':
                      refVoucher_Bank_Name.invalid &&
                      (refVoucher_Bank_Name.dirty ||
                        refVoucher_Bank_Name.touched ||
                        refVoucher_Bank_Name.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_Bank_Name"
                  name="Voucher_Bank_Name"
                  id="Voucher_Bank_Name"
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                voucherModel.Voucher.Voucher_Tag == 'Bank' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != '' &&
                voucherModel.Voucher.DRAccountId != '' &&
                voucherModel.Voucher.Voucher_Mode_of_Payment != 'Cheque'
              "
            >
              <div class="col-md-3">
                <span class="required-lable">Ref No</span>
                <input
                  type="text"
                  required
                  #refVoucher_E_Ref_No="ngModel"
                  class="form-control input-text-css"
                  placeholder="Ref No"
                  [ngClass]="{
                    'is-invalid': ddf.submitted && refVoucher_E_Ref_No.invalid,
                    'alert-warning':
                      refVoucher_E_Ref_No.invalid &&
                      (refVoucher_E_Ref_No.dirty ||
                        refVoucher_E_Ref_No.touched ||
                        refVoucher_E_Ref_No.untouched)
                  }"
                  [(ngModel)]="voucherModel.Voucher.Voucher_E_Ref_No"
                  name="Voucher_E_Ref_No"
                  id="Voucher_E_Ref_No"
                />
              </div>
              <div class="col-md-3">
                <span class="required-lable">Transaction Date </span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="pickervetd"
                    dateConvert
                    required
                    placeholder="DD/MM/YYYY"
                    name="Voucher_E_Transaction_Date"
                    id="Voucher_E_Transaction_Date"
                    #refVoucher_E_Transaction_Date="ngModel"
                    class="form-control input-text-css"
                    [ngClass]="{
                      'is-invalid':
                        ddf.submitted && refVoucher_E_Transaction_Date.invalid,
                      'alert-warning':
                        refVoucher_E_Transaction_Date.invalid &&
                        (refVoucher_E_Transaction_Date.dirty ||
                          refVoucher_E_Transaction_Date.touched ||
                          refVoucher_E_Transaction_Date.untouched)
                    }"
                    [(ngModel)]="
                      voucherModel.Voucher.Voucher_E_Transaction_Date
                    "
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickervetd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickervetd></mat-datepicker>
                </div>
              </div>
            </ng-container>
          </div>

          <hr />

          <form #df="ngForm" novalidate>
            <div
              class="row m-0 add-remove"
              *ngFor="let vdData of voucherModel1.Voucher_Detail; let i = index"
            >
              <div class="col-md-12 row m-0 type-column">
                <div class="type mr_top">
                  <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                    Type
                  </div>
                  <input
                    type="text"
                    name="TranType1{{ i }}"
                    id="TranType1{{ i }}"
                    class="form-control input-text-css"
                    readonly
                    [(ngModel)]="vdData.TranType"
                    [ngClass]="{ 'mt-1': i == 0 }"
                  />
                </div>
                <div class="row m-0 feild">
                  <ng-container
                    *ngIf="
                      voucherModel1.Voucher.Voucher_Sub_Type == 'Loan' ||
                      voucherModel1.Voucher.Voucher_Sub_Type == 'Partner'
                    "
                  >
                    <div class="col-md-4 mr_top pr-0">
                      <div [ngClass]="{ 'bg-success1': i == 0 }">
                        <span class="required-lable" *ngIf="i == 0"
                          >Category</span
                        >
                      </div>
                      <select
                        name="ChargeHeadId1{{ i }}"
                        id="ChargeHeadId1{{ i }}"
                        disabled
                        required
                        #refChargeHeadId="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refChargeHeadId.invalid,
                          'alert-warning':
                            refChargeHeadId.invalid &&
                            (refChargeHeadId.dirty ||
                              refChargeHeadId.touched ||
                              refChargeHeadId.untouched),
                          'mt-1': i == 0
                        }"
                        [(ngModel)]="vdData.ChargeHeadId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Category</option>
                        <option
                          *ngFor="let item of chargesHeadDropdown"
                          [value]="item.Id"
                        >
                          {{ item.ChagesHead }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mr_top pr-0">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        Account Head
                      </div>
                      <input
                        type="text"
                        name="ChargeHeadCategory1{{ i }}"
                        id="ChargeHeadCategory1{{ i }}"
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css"
                        readonly
                        [(ngModel)]="vdData.ChargeHeadCategory"
                      />
                    </div>
                    <!--<div class="col-md-4 mr_top">
              <div [ngClass]="{'bg-success1' : i==0}"><span class="required-lable" *ngIf="i==0">Amount</span></div>
              <input type="text" min="0" name="Amount{{ i }}" id="Amount{{ i }}" readonly [disabled]="!vdData.ChargeHeadId" appTwoDigitDecimaNumber
                     class="form-control input-text-css text-right pr-3" required #refAmount="ngModel" [(ngModel)]="vdData.Amount"
                     [ngClass]="{ 'is-invalid': df.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched), 'mt-1' : i==0 }">
            </div>-->
                    <div class="col-md-2 mr_top pr-0 pl-1">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        <span
                          class=""
                          [ngClass]="{
                            'required-lable': vdData.TranType == 'DR'
                          }"
                          >Amt (DR)</span
                        >
                      </div>
                      <input
                        type="text"
                        min="0"
                        name="Amount1{{ i }}"
                        id="Amount1{{ i }}"
                        *ngIf="vdData.TranType == 'DR'"
                        appTwoDigitDecimaNumber
                        disabled
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.TranType == 'DR'"
                        #refAmount="ngModel"
                        [(ngModel)]="vdData.Amount"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                      <input
                        type="text"
                        name="AmountD1{{ i }}"
                        id="AmountD1{{ i }} "
                        appTwoDigitDecimaNumber
                        readonly
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.TranType == 'CR'"
                      />
                    </div>
                    <div class="col-md-2 mr_top pr-0 pl-1">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        <span
                          class=""
                          [ngClass]="{
                            'required-lable': vdData.TranType == 'CR'
                          }"
                          >Amt (CR)</span
                        >
                      </div>
                      <input
                        type="text"
                        min="0"
                        name="Amount1{{ i }}"
                        id="Amount1{{ i }}"
                        *ngIf="vdData.TranType == 'CR'"
                        appTwoDigitDecimaNumber
                        disabled
                        [(ngModel)]="vdData.Amount"
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.TranType == 'CR'"
                        #refAmount="ngModel"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                      <input
                        type="text"
                        name="AmountD1{{ i }}"
                        id="AmountD1{{ i }}"
                        appTwoDigitDecimaNumber
                        readonly
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.TranType == 'DR'"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-md-12"></div>
            </div>
            <div
              class="col-md-12 row pl-3 m-0"
              *ngIf="totalvoucherCalculator1.totalDR > 0"
              style="width: 96.3%"
            >
              <div class="col-md-12 bg-success1 p-1">
                <div class="row">
                  <div class="col-md-8">Grand Total :</div>
                  <div class="text-right pr-1 col-md-2">
                    <span>{{
                      totalvoucherCalculator1.totalDR.toFixed(2)
                    }}</span>
                  </div>
                  <div class="text-right col-md-2">
                    <span>{{
                      totalvoucherCalculator1.totalCR.toFixed(2)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form #df="ngForm" novalidate class="mt-2">
            <div
              class="row m-0 add-remove"
              *ngFor="let vdData of voucherModel.Voucher_Detail; let i = index"
            >
              <div class="col-md-12 row m-0 type-column">
                <div class="type mr_top">
                  <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                    Type
                  </div>
                  <input
                    type="text"
                    name="TranType{{ i }}"
                    id="TranType{{ i }}"
                    class="form-control input-text-css"
                    readonly
                    [(ngModel)]="vdData.TranType"
                    [ngClass]="{ 'mt-1': i == 0 }"
                  />
                </div>
                <div class="row m-0 feild">
                  <ng-container
                    *ngIf="
                      voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                      voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                    "
                  >
                    <div class="col-md-4 mr_top pr-0">
                      <div [ngClass]="{ 'bg-success1': i == 0 }">
                        <span class="required-lable" *ngIf="i == 0"
                          >Category</span
                        >
                      </div>
                      <select
                        name="ChargeHeadId{{ i }}"
                        id="ChargeHeadId{{ i }}"
                        disabled
                        required
                        #refChargeHeadId="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refChargeHeadId.invalid,
                          'alert-warning':
                            refChargeHeadId.invalid &&
                            (refChargeHeadId.dirty ||
                              refChargeHeadId.touched ||
                              refChargeHeadId.untouched),
                          'mt-1': i == 0
                        }"
                        [(ngModel)]="vdData.ChargeHeadId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Category</option>
                        <option
                          *ngFor="let item of chargesHeadDropdown"
                          [value]="item.Id"
                        >
                          {{ item.ChagesHead }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mr_top pr-0">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        Account Head
                      </div>
                      <input
                        type="text"
                        name="ChargeHeadCategory{{ i }}"
                        id="ChargeHeadCategory{{ i }}"
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css"
                        readonly
                        [(ngModel)]="vdData.ChargeHeadCategory"
                      />
                    </div>
                    <!--<div class="col-md-4 mr_top">
              <div [ngClass]="{'bg-success1' : i==0}"><span class="required-lable" *ngIf="i==0">Amount</span></div>
              <input type="text" min="0" name="Amount{{ i }}" id="Amount{{ i }}" readonly [disabled]="!vdData.ChargeHeadId" appTwoDigitDecimaNumber
                     class="form-control input-text-css text-right pr-3" required #refAmount="ngModel" [(ngModel)]="vdData.Amount"
                     [ngClass]="{ 'is-invalid': df.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched), 'mt-1' : i==0 }">
            </div>-->
                    <div class="col-md-2 mr_top pr-0 pl-1">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        <span
                          class=""
                          [ngClass]="{
                            'required-lable': vdData.TranType == 'DR'
                          }"
                          >Amt (DR)</span
                        >
                      </div>
                      <input
                        type="text"
                        min="0"
                        name="Amount{{ i }}"
                        id="Amount{{ i }}"
                        *ngIf="vdData.TranType == 'DR'"
                        appTwoDigitDecimaNumber
                        disabled
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.TranType == 'DR'"
                        #refAmount="ngModel"
                        [(ngModel)]="vdData.Amount"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                      <input
                        type="text"
                        name="AmountD{{ i }}"
                        id="AmountD{{ i }} "
                        appTwoDigitDecimaNumber
                        readonly
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.TranType == 'CR'"
                      />
                    </div>
                    <div class="col-md-2 mr_top pr-0 pl-1">
                      <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                        <span
                          class=""
                          [ngClass]="{
                            'required-lable': vdData.TranType == 'CR'
                          }"
                          >Amt (CR)</span
                        >
                      </div>
                      <input
                        type="text"
                        min="0"
                        name="Amount{{ i }}"
                        id="Amount{{ i }}"
                        *ngIf="vdData.TranType == 'CR'"
                        appTwoDigitDecimaNumber
                        disabled
                        [(ngModel)]="vdData.Amount"
                        class="form-control input-text-css text-right pr-3"
                        [required]="vdData.TranType == 'CR'"
                        #refAmount="ngModel"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                      <input
                        type="text"
                        name="AmountD{{ i }}"
                        id="AmountD{{ i }}"
                        appTwoDigitDecimaNumber
                        readonly
                        [ngClass]="{ 'mt-1': i == 0 }"
                        class="form-control input-text-css text-right pr-3"
                        *ngIf="vdData.TranType == 'DR'"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-md-12"></div>
            </div>
            <div
              class="col-md-12 row pl-3 m-0"
              *ngIf="totalvoucherCalculator.totalDR > 0"
              style="width: 96.3%"
            >
              <div class="col-md-12 bg-success1 p-1">
                <div class="row">
                  <div class="col-md-8">Grand Total :</div>
                  <div class="text-right pr-1 col-md-2">
                    <span>{{ totalvoucherCalculator.totalDR.toFixed(2) }}</span>
                  </div>
                  <div class="text-right col-md-2">
                    <span>{{ totalvoucherCalculator.totalCR.toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div
            class="row m-0 mt-2"
            *ngIf="voucherModel.Voucher.DRAccountId != ''"
          >
            <div class="col-md-12">
              <span class="">Remark</span>
              <input
                type="text"
                #refVoucher_Narration="ngModel"
                [ngClass]="{
                  'is-invalid': ddf.submitted && refVoucher_Narration.invalid,
                  'alert-warning':
                    refVoucher_Narration.invalid &&
                    (refVoucher_Narration.dirty ||
                      refVoucher_Narration.touched ||
                      refVoucher_Narration.untouched)
                }"
                [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
                name="Voucher_Narration"
                id="Voucher_Narration"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </form>
        <div class="col-md-12 p-0 mt-1 text-right">
          <button
            type="button"
            (click)="SavePaymentVoucher()"
            [class.spinner]="loading"
            class="mt-3 btn font-size-12 button-btn"
            [disabled]="!ddf.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="onClosePayModel()"
            [class.spinner]="loading"
            class="mt-3 btn font-size-12 button-btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
