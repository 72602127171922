import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import * as moment from "moment";

import * as XLSX from "xlsx";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-assets-details",
  templateUrl: "./assets-details.component.html",
  styleUrls: ["./assets-details.component.scss"],
})
export class AssetsDetailsComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;

  DataSourceProperty: any;
  DataSourceVehicle: any;
  DataSourceGoldLoan: any;

  displayedColumnsProperty: string[];
  displayedColumnsVehicle: string[];
  displayedColumnsGoldLoan: string[];

  ProductCatgDropdown: any = [];
  AssetsModel: any = [];
  BranchesList: any = [];
  JsonData: any[] = [];

  selectedId: any;

  @ViewChild("assets_details") table: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatPaginator) paginator2: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Assets Details");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.getProductCategory();
    this.getBranchesList();

    this.AssetsModel = {
      Category: "",
      Branch: 0,
    };

    this.displayedColumnsProperty = [
      "Id",
      "LoanAcNo",
      "Application_No",
      "Loan_Date",
      "Branch_Name",
      "Product_Name",
      "SalesExecutive",
      "Customer",
      "TypeofProperty",
      "Collateral_Type",
      "Application_LoanAmount",
      "PropertyOwnerName",
      "NatureofProperty",
      "OwnershipType",
      "OwnershipDocument",
      "Address",
      "TotalArea",
      "ConstructedArea",
      "TypeofMortgage",
      "MortgageSingedBy",
      "MarketValue",
      "ValuationAmount",
      "ValuationDoneBy",
      "ValuationDoneByOther",
      "LegalReportBy",
      "LegalReportDoneByOther",
      "GeoLocation",
      "State",

      "Tasil",
      "District",
      "ValuationRemark",
      "ValuationRemarkDoc",
      "SearchReportRemark",
      "SearchReportRemarkDoc",
    ];

    this.displayedColumnsVehicle = [
      "Id",
      "LoanAcNo",
      "Application_No",
      "Loan_Date",
      "Branch_Name",
      "Product_Name",
      "SalesExecutive",
      "CollectionExecutive",
      "Customer",
      "Type",
      "Collateral_Type",
      "Application_LoanAmount",
      "VehicleManufacture",
      "VehicleCategory",
      "VehicleModelName",
      "Variant",
      "VehicleRegistationNo",
      "EngineNo",
      "ChassisNo",
      "KeyNo",
      "RegistationDate",
      "RegistationExpiryDate",
      "FuelType",
      "Color",
      "Vehicle_Type",
      "Vehicle_SubType",
      "MefgYear",
      "VehicleCost",
      "Dealer",
      "DealerContactPerson",
      "DealerContactNo",
      "Category",
      "VehicleCondition",
      "DONo",
      "DODoDate",
      "EstimationAmount",
      "InvoiceNo",
      "InvoiceDate",
      "InvoiceValue",
      "DOIssuedinfavourof",
      "Remark",
      "ValuationDoneBy",
      "ValuationDate",
      "ValuationAmount",
      "ValuationType",
      "OtherValuationType",
      "OtherValuationAmt",
      "FitnessAuthority",
      "FitnessUpto",
      "PermitAuthority",
      "PermitUpto",
      "PermitType",
      "ExtraInfo",
      "RoadTaxUpto",
      "Rout",
      "HPNEndorsment",
      "DateofEndorsment",
    ];

    this.displayedColumnsGoldLoan = [
      "Id",
      "LoanAcNo",
      "Application_No",
      "Loan_Date",
      "Branch_Name",
      "Product_Name",
      "SalesExecutive",
      "Customer",
      "Collateral_Type",
      "TotalItem_Qty",
      "ValuationDoneBy",
      "ValuationDate",
      "ValuerPhoneNo",
      "ValuerFirmName",
      "TotalValuationAmount",
    ];
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCatgDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  onSearchAssetsDetails() {
    this.showSpinner = true;
    this.DataSourceProperty = null;
    this.DataSourceVehicle = null;
    this.DataSourceGoldLoan = null;
    this.reportsService
      .Report_Get_Assets_List({
        LoanAcNo: this.AssetsModel.LoanNo,
        ProductCatg: this.AssetsModel.Category,
        Branch_Id: this.AssetsModel.Branch,
        From: this.AssetsModel.FromDate,
        To: this.AssetsModel.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          if (this.AssetsModel.Category == "Property Loan") {
            this.DataSourceProperty = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceProperty.paginator = this.paginator;
          } else if (this.AssetsModel.Category == "Vehicle Loan") {
            this.DataSourceVehicle = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceVehicle.paginator = this.paginator1;
          } else {
            this.DataSourceGoldLoan = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );

            this.DataSourceGoldLoan.paginator = this.paginator2;
          }
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  public highlightRow(row) {
    this.selectedId = row.Id;
  }

  applyFilterProperty(filterValue: any) {
    this.DataSourceProperty.filter = filterValue.trim().toLowerCase();
  }

  applyFilterVehicle(filterValue: any) {
    this.DataSourceVehicle.filter = filterValue.trim().toLowerCase();
  }

  applyFilterGold(filterValue: any) {
    this.DataSourceGoldLoan.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const table = document.getElementById("assets_details") as HTMLTableElement;
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "ASSETS DETAILS.xlsx");
  }
}
