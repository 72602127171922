import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

declare var $: any;

@Component({
  selector: "app-mst-de-dupe",
  templateUrl: "./mst-de-dupe.component.html",
  styleUrls: ["./mst-de-dupe.component.scss"],
})
export class MstDeDupeComponent implements OnInit {
  private _MasterService: any;
  FilterSearchBySource: any = "";
  FilterSearchByDestination: any = "";
  FilterSearchValueSource: any = "";
  FilterSearchValueDestination: any = "";
  dataSource: any;
  currentUser: any;
  CustomerIdSource: any = 0;
  CustomerIdDestination: any = 0;
  IsFirmSource: any;
  IsFirmDestination: any;
  Type: any;
  showSpinner: boolean = false;
  selectAll: boolean = false;
  SearchCustomerList: any = [];
  customerSourceData: any = {};
  customerDestinationData: any = {};
  displayedColumns: string[] = [];
  RequestModel: RequestModel = new RequestModel();

  // address
  addressSourceList: any = [];
  dataSourceAddress: any;
  KYC: any = [];
  DeDupeData: any = {
    CustomerName: false,
    RelationName: false,
    Gender: false,
    Occupation: false,
    Customer_DOB: false,
    PrimaryContact: false,
    AlternateContact: false,
    WhatsAppNo: false,
    Email: false,
    MaritalStatus: false,
    Religion: false,
    Cast: false,
    ProfilePic: false,
    Permanent: false,
    Present: false,
    Work: false,
    Registered: false,
    Corporate: false,
    PANCard: false,
    ElectricityBill: false,
    PassportCopy: false,
    VoterIDCard: false,
    DrivingLicense: false,
    AadhaarCard: false,
    OtherPhotoID: false,
  };
  addressDestinationList: any = [];
  dataDestinationAddress: any;
  displayedColumnsAddress: string[] = [
    "AddressId",
    "AddressType",
    "Address",
    "LandMark",
    "Tehsil_Name",
    "District_Name",
    "PinCode",
    "AddressRentBuy",
    "TotalYearsOnAddress",
    "IsCommunicationAddress",
  ];

  // Category
  CategorySourceList: any = [];
  dataSourceCategory: any;
  CategoryDestinationList: any = [];
  dataDestinationCategory: any;
  displayedColumnsCategory: string[] = [
    "CategoyId",
    "Categoy",
    "Doc_Name",
    "KYC_DocNumber",
    "UploadOn",
    "EditAction",
  ];
  CustomerViewDetailRequestModel: RequestModel = new RequestModel();
  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("De-Dupe Customer");
    this.dataSharingService.LOSHeaderTitle.next("De-Dupe Customer");
    this.displayedColumns = [
      "CustomerId",
      "CustomertName",
      "RelationName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "TotalApplication",
      "EditAction",
    ];
  }

  getSearchCusList(Type) {
    this.Type = Type;
    if (Type == "Source") {
      if (this.FilterSearchBySource == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Select Search By.",
          ...this.configSuccess,
        });
        return;
      }
      if (this.FilterSearchValueSource == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Enter Value.",
          ...this.configSuccess,
        });
        return;
      }
      this.showSpinner = true;
      this.RequestModel.SearchOn = this.FilterSearchBySource;
      this.RequestModel.SearchValue = this.FilterSearchValueSource;
    }

    if (Type == "Destination") {
      if (this.FilterSearchByDestination == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Select Search By.",
          ...this.configSuccess,
        });
        return;
      }
      if (this.FilterSearchValueDestination == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Enter Value.",
          ...this.configSuccess,
        });
        return;
      }
      this.showSpinner = true;
      this.RequestModel.SearchOn = this.FilterSearchByDestination;
      this.RequestModel.SearchValue = this.FilterSearchValueDestination;
    }

    this._MasterService
      .GetSearchCustomer(this.RequestModel)
      .subscribe((result) => {
        this.SearchCustomerList = JSON.parse(JSON.stringify(result));
        if (result.length > 0) {
          console.log("result", result);
          if (Type == "Source") {
            this.SearchCustomerList = this.SearchCustomerList.filter(
              (item) =>
                item.CustomerId != this.CustomerIdDestination &&
                (this.IsFirmDestination == undefined ||
                  item.Customer_IsFirm == this.IsFirmDestination)
            );
          }
          if (Type == "Destination") {
            this.SearchCustomerList = this.SearchCustomerList.filter(
              (item) =>
                item.CustomerId != this.CustomerIdSource &&
                (this.IsFirmSource == undefined ||
                  item.Customer_IsFirm == this.IsFirmSource)
            );
          }
          this.dataSource = new MatTableDataSource(this.SearchCustomerList);
          Type = this.Type;
          this.showSpinner = false;
          $("#CustomerList").modal("show");
          $("#CustomerList").css("z-index", "1050");
        } else {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
      });
  }

  OnClose() {
    $("#CustomerList").modal("hide");
  }

  onCustomerChange(event: any, data: any) {
    console.log("this.CustomerIdDestination", this.CustomerIdDestination);
    console.log("this.CustomerIdSource", this.CustomerIdSource);
    if (this.Type == "Source") {
      this.CustomerIdSource = data.CustomerId;
      this.IsFirmSource = data.Customer_IsFirm;
      this.CustomerViewDetailRequestModel.CustomerId = this.CustomerIdSource;
      this._MasterService
        .Get_CustomerDetails(this.CustomerViewDetailRequestModel)
        .subscribe((result: any) => {
          if (result != null) {
            this.customerSourceData = result.Item1[0];
            // address
            this.addressSourceList = result.Item2;
            if (
              this.addressSourceList.find((obj: any) => {
                return obj.AddressType == "Permanent";
              })
            )
              this.customerSourceData.Permanent = this.addressSourceList.find(
                (obj: any) => {
                  return obj.AddressType == "Permanent";
                }
              ).Address;
            if (
              this.addressSourceList.find((obj: any) => {
                return obj.AddressType == "Present";
              })
            )
              this.customerSourceData.Present = this.addressSourceList.find(
                (obj: any) => {
                  return obj.AddressType == "Present";
                }
              ).Address;
            if (
              this.addressSourceList.find((obj: any) => {
                return obj.AddressType == "Work";
              })
            )
              this.customerSourceData.Work = this.addressSourceList.find(
                (obj: any) => {
                  return obj.AddressType == "Work";
                }
              ).Address;

            if (
              this.addressSourceList.find((obj: any) => {
                return obj.AddressType == "Registered";
              })
            )
              this.customerSourceData.Registered = this.addressSourceList.find(
                (obj: any) => {
                  return obj.AddressType == "Registered";
                }
              ).Address;
            if (
              this.addressSourceList.find((obj: any) => {
                return obj.AddressType == "Corporate";
              })
            )
              this.customerSourceData.Corporate = this.addressSourceList.find(
                (obj: any) => {
                  return obj.AddressType == "Corporate";
                }
              ).Address;

            this.CategorySourceList = result.Item3;
            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 1;
              })
            )
              this.customerSourceData.PANCard = this.CategorySourceList.find(
                (obj: any) => {
                  return obj.Id == 1;
                }
              ).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 2;
              })
            )
              this.customerSourceData.ElectricityBill =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 2;
                }).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 3;
              })
            )
              this.customerSourceData.PassportCopy =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 3;
                }).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 4;
              })
            )
              this.customerSourceData.VoterIDCard =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 4;
                }).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 5;
              })
            )
              this.customerSourceData.DrivingLicense =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 5;
                }).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 6;
              })
            )
              this.customerSourceData.AadhaarCard =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 6;
                }).KYC_DocNumber;

            if (
              this.CategorySourceList.find((obj: any) => {
                return obj.Id == 7;
              })
            )
              this.customerSourceData.OtherPhotoID =
                this.CategorySourceList.find((obj: any) => {
                  return obj.Id == 7;
                }).KYC_DocNumber;
          }
        });
    }
    if (this.Type == "Destination") {
      this.CustomerIdDestination = data.CustomerId;
      this.IsFirmDestination = data.Customer_IsFirm;
      this.CustomerViewDetailRequestModel.CustomerId =
        this.CustomerIdDestination;
      this._MasterService
        .Get_CustomerDetails(this.CustomerViewDetailRequestModel)
        .subscribe((result: any) => {
          if (result != null) {
            this.customerDestinationData = result.Item1[0];
            // address
            this.addressDestinationList = result.Item2;
            if (
              this.addressDestinationList.find((obj: any) => {
                return obj.AddressType == "Permanent";
              })
            )
              this.customerDestinationData.Permanent =
                this.addressDestinationList.find((obj: any) => {
                  return obj.AddressType == "Permanent";
                }).Address;
            if (
              this.addressDestinationList.find((obj: any) => {
                return obj.AddressType == "Present";
              })
            )
              this.customerDestinationData.Present =
                this.addressDestinationList.find((obj: any) => {
                  return obj.AddressType == "Present";
                }).Address;
            if (
              this.addressDestinationList.find((obj: any) => {
                return obj.AddressType == "Work";
              })
            )
              this.customerDestinationData.Work =
                this.addressDestinationList.find((obj: any) => {
                  return obj.AddressType == "Work";
                }).Address;

            if (
              this.addressDestinationList.find((obj: any) => {
                return obj.AddressType == "Registered";
              })
            )
              this.customerDestinationData.Registered =
                this.addressDestinationList.find((obj: any) => {
                  return obj.AddressType == "Registered";
                }).Address;
            if (
              this.addressDestinationList.find((obj: any) => {
                return obj.AddressType == "Corporate";
              })
            )
              this.customerDestinationData.Corporate =
                this.addressDestinationList.find((obj: any) => {
                  return obj.AddressType == "Corporate";
                }).Address;

            this.CategoryDestinationList = result.Item3;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 1;
              })
            )
              this.customerDestinationData.PANCard =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 1;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 2;
              })
            )
              this.customerDestinationData.ElectricityBill =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 2;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 3;
              })
            )
              this.customerDestinationData.PassportCopy =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 3;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 4;
              })
            )
              this.customerDestinationData.VoterIDCard =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 4;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 5;
              })
            )
              this.customerDestinationData.DrivingLicense =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 5;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 6;
              })
            )
              this.customerDestinationData.AadhaarCard =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 6;
                }).KYC_DocNumber;

            if (
              this.CategoryDestinationList.find((obj: any) => {
                return obj.Id == 7;
              })
            )
              this.customerDestinationData.OtherPhotoID =
                this.CategoryDestinationList.find((obj: any) => {
                  return obj.Id == 7;
                }).KYC_DocNumber;
          }
        });
    }

    this.OnClose();
  }
  Reset() {
    console.log("DeDupeData", this.DeDupeData);
    let _finalData = {
      DeDupeData: this.dataSharingService.cleanObject({ ...this.DeDupeData }),
    };
    console.log("_finalData", _finalData);
    this.CategoryDestinationList = [];
    this.CategorySourceList = [];
    this.CustomerIdSource = 0;
    this.CustomerIdDestination = 0;
    this.customerSourceData = {};
    this.customerDestinationData = {};
    this.FilterSearchBySource = "";
    this.FilterSearchByDestination = "";
    this.FilterSearchValueSource = "";
    this.FilterSearchValueDestination = "";

    this.DeDupeData = {
      CustomerName: false,
      RelationName: false,
      Gender: false,
      Occupation: false,
      Customer_DOB: false,
      PrimaryContact: false,
      AlternateContact: false,
      WhatsAppNo: false,
      Email: false,
      MaritalStatus: false,
      Religion: false,
      Cast: false,
      ProfilePic: false,
      Permanent: false,
      Present: false,
      Work: false,
      Registered: false,
      Corporate: false,
      PANCard: false,
      ElectricityBill: false,
      PassportCopy: false,
      VoterIDCard: false,
      DrivingLicense: false,
      AadhaarCard: false,
      OtherPhotoID: false,
    };
  }
  De_DupeCustomer() {
    let _finalData = {
      DeDupeData: this.dataSharingService.cleanObject({ ...this.DeDupeData }),
    };
    this._MasterService
      .De_DupeCustomer({
        SourceId: this.CustomerIdSource,
        DesinationId: this.CustomerIdDestination,
        JSON: JSON.stringify(_finalData),
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result: any) => {
        if (result[0].CODE >= 0) {
          this._MasterService
            .UploadCopyCustomerDoc({
              SourceCustomerID: this.CustomerIdSource,
              DestintionCustomerID: this.CustomerIdDestination,
            })
            .subscribe((res: any) => {});
          this.Reset();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: result[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: result[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  checkUncheckAll(evt) {
    if (this.selectAll == true) {
      if (this.customerSourceData.CustomerName)
        this.DeDupeData.CustomerName = true;

      if (this.customerSourceData.RelationName)
        this.DeDupeData.RelationName = true;

      if (this.customerSourceData.Gender) this.DeDupeData.Gender = true;

      if (this.customerSourceData.Occupation) this.DeDupeData.Occupation = true;

      if (this.customerSourceData.Customer_DOB)
        this.DeDupeData.Customer_DOB = true;

      if (this.customerSourceData.PrimaryContact)
        this.DeDupeData.PrimaryContact = true;

      if (this.customerSourceData.AlternateContact)
        this.DeDupeData.AlternateContact = true;

      if (this.customerSourceData.WhatsAppNo) this.DeDupeData.WhatsAppNo = true;

      if (this.customerSourceData.Email) this.DeDupeData.Email = true;

      if (this.customerSourceData.MaritalStatus)
        this.DeDupeData.MaritalStatus = true;

      if (this.customerSourceData.Religion) this.DeDupeData.Religion = true;

      if (this.customerSourceData.Cast) this.DeDupeData.Cast = true;

      if (this.customerSourceData.ProfilePic) this.DeDupeData.ProfilePic = true;

      if (this.customerSourceData.Permanent) this.DeDupeData.Permanent = true;

      if (this.customerSourceData.Present) this.DeDupeData.Present = true;

      if (this.customerSourceData.Work) this.DeDupeData.Work = true;

      if (this.customerSourceData.Registered) this.DeDupeData.Registered = true;

      if (this.customerSourceData.Corporate) this.DeDupeData.Corporate = true;

      if (this.customerSourceData.PANCard) this.DeDupeData.PANCard = true;

      if (this.customerSourceData.ElectricityBill)
        this.DeDupeData.ElectricityBill = true;

      if (this.customerSourceData.PassportCopy)
        this.DeDupeData.PassportCopy = true;

      if (this.customerSourceData.VoterIDCard)
        this.DeDupeData.VoterIDCard = true;

      if (this.customerSourceData.DrivingLicense)
        this.DeDupeData.DrivingLicense = true;

      if (this.customerSourceData.AadhaarCard)
        this.DeDupeData.AadhaarCard = true;

      if (this.customerSourceData.OtherPhotoID)
        this.DeDupeData.OtherPhotoID = true;
    } else {
      this.selectAll = false;
      this.DeDupeData = {
        CustomerName: false,
        RelationName: false,
        Gender: false,
        Occupation: false,
        Customer_DOB: false,
        PrimaryContact: false,
        AlternateContact: false,
        WhatsAppNo: false,
        Email: false,
        MaritalStatus: false,
        Religion: false,
        Cast: false,
        ProfilePic: false,
        Permanent: false,
        Present: false,
        Work: false,
        Registered: false,
        Corporate: false,
        PANCard: false,
        ElectricityBill: false,
        PassportCopy: false,
        VoterIDCard: false,
        DrivingLicense: false,
        AadhaarCard: false,
        OtherPhotoID: false,
      };
    }
  }
}
