import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, Type } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { userlayoutService } from '../../_LOS/_layout/Services/app.userlayout.Service';

import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";



import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { DialogService } from '../../Shared/dialog.service'
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { DatePipe } from '@angular/common';
import { DepositService } from "../services/deposit.service";

@Component({
  selector: 'app-deposit-info-layout-tab',
  templateUrl: './deposit-info-layout-tab.component.html',
  styleUrls: ['./deposit-info-layout-tab.component.scss']
})
export class DepositInfoLayoutTabComponent implements OnInit {
  currentUser: any; 
  showSpinner: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;
  TabDisabled: boolean = false;
  ProductSummary: any = { ProductId:0};
  PageType: any;
  Add:any;

  constructor(private route: ActivatedRoute,
    private _Route: Router, 
    private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar,
    private _userlayoutservice: userlayoutService,
    private dataSharingService: DataSharingService, private dialog: DialogService,
    private LmsService: LmsService,
    private DepositService: DepositService,
    private _EncrDecrService: EncrDecrService,
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
     
    });

  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductSummary.ProductId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));
      this.Add = param.get("Add");
      console.log("currentTab", this.currentTab);
    });
  }

  
  getActiveTab() {
    this.currentTab = 0;
  }
  onChildAction(event: any) {
    if (event == "next") {
    
    }
  }
  onTabChange(event: any) {
    this.currentTab = event.index;
  }
}
