<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>


<div class="row m-0 mt-2 mb-2">
  <div class="col-md-6">
    <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'LMS' }" (click)="changeTab('LMS')">LMS</button>
  </div>
  <div class="col-md-6">
    <button class="btn btn-outline-green btn-lg btn-block" [ngClass]="{ active: tab == 'LOS' }" (click)="changeTab('LOS')">LOS</button>
  </div>
</div>
<div class="mt-2" *ngIf="tab == 'LOS'">
  <div class="">
    <div class="table-responsive mt-3" *ngIf="dataSourceDetail?.data.length">
      <mat-table #TATTable [dataSource]="dataSourceDetail" matSort style="max-width: 100%; overflow: auto;">
        <ng-container matColumnDef="ProcessId">
          <mat-header-cell mat-sort-header class="grid-header" style="max-width:50px" *matHeaderCellDef>
            #
          </mat-header-cell>
          <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;">
            {{ (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Process">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Process</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Process}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ProcessExecutive">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Executive </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ProcessExecutive}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ProcessStatus">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Status</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.ProcessStatus}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssignedOn">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Assign On</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.AssignedOn}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CompletedOn">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Completed On</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.CompletedOn}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="TAT_Hr">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> TAT (Hrs) </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{row.TAT_Hr}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Remark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Remark </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Remark}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LatLong">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>
            LatLong
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <a target="_blank"  *ngIf="row.LatLong!='' && row.LatLong!=null && row.LatLong!=','" [href]="'http://maps.google.com/maps?q=' + row.LatLong">
              <i class="fa-solid fa-location-dot"></i>
            </a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedDetailColumns"></mat-header-row>
        <mat-row [ngClass]="row.ProcessStatus=='Pending'?'Pending':''"
                 *matRowDef="let row; columns: displayedDetailColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="col-md-12 mt-3">
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportExcelLos()">
      <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
    </button>
  </div>
</div>
<div class="mt-2" *ngIf="tab == 'LMS'">
  <div class="">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSourceLMS">
        <!--  matSort style="height: 210px; max-width: 100%; overflow: auto;" -->
        <ng-container matColumnDef="Id">
          <mat-header-cell  class="grid-header" style="max-width:50px" *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
            <!-- {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }} -->
            {{ (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="processName">
          <mat-header-cell  class="grid-header" *matHeaderCellDef>Process Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.processName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="UserName">
          <mat-header-cell  class="grid-header" *matHeaderCellDef>User Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.UserName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changeDate">
          <mat-header-cell  class="grid-header" *matHeaderCellDef>Date Time</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell"> {{row.changeDate}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedLMSColumns; sticky: true"></mat-header-row>
        <mat-row  *matRowDef="let row; columns: displayedLMSColumns"></mat-row>
      </mat-table>
      <!-- <mat-paginator #paginatorRef [pageSizeOptions]="[50]" showFirstLastButtons> </mat-paginator> -->
    </div>
  
  </div>

  <div class="col-md-12 mt-3">
    <button class="mt-3 btn font-size-12 button-btn " (click)="exportExcelLms()" mat-raised-button>
      <i class="fa fa-file-export mr-1" style="font-size:medium;cursor: pointer;"></i> Export
    </button>
  </div>
</div>
