import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-business-wise",
  templateUrl: "./business-wise.component.html",
  styleUrls: ["./business-wise.component.scss"],
})
export class BusinessWiseComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  chartdata: any;
  JsonData: any[] = [];
  dataSource: any;
  SlabData: any[] = [];

  //Form value
  ReportType: any = "";
  SlabId: any = "";
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  //div hidden show
  SlabDropdown: boolean = false;
  DataDiv: boolean = false;
  displayedColumns: string[];
  DataSourceModel: any = [];
  FileCheckingList: any;

  displayedColumnsModel: string[] = [];

  selectedId: any;

  private _MasterService;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  FyearId: any;
  maxDate: any;
  minDate: any;
  Types: any;
  ArrTypes: Array<{ id: number; value: string }> = [
    { id: 1, value: "Branch Wise Business" },
    { id: 2, value: "Product Wise Business" },
    { id: 3, value: "Amount Wise Business" },
    { id: 4, value: "Tenure Wise Business" },
    { id: 5, value: "IRR Wise Business" },
    { id: 6, value: "LTV Wise Business" },
    { id: 7, value: "Sales Executive Wise Business" },
    { id: 8, value: "Loan A/c Category Wise Business" },
    { id: 9, value: "Gender Wise Business" },
    { id: 10, value: "Religion Wise Business" },
    { id: 11, value: "Caste Wise Business" },
    { id: 12, value: "Marital Status Wise Business" },
    { id: 13, value: "Profile Wise Business" },
    { id: 14, value: "Nature of Work Wise Business" },
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Business Summary");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.ArrTypes;

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          this.dataSource = null;
        });
    });
  }

  onChangeReportTypes() {
    this.SlabDropdown = false;
    this.SlabId = "";
    this.Types = this.ArrTypes.find((obj: any) => {
      return obj.id == this.ReportType;
    }).value;
    switch (this.ReportType) {
      case "3":
        this.SlabDropdown = true;
        this.getSlabsList("Amount");
        break;
      case "4":
        this.SlabDropdown = true;
        this.getSlabsList("Tenure");
        break;
      case "5":
        this.SlabDropdown = true;
        this.getSlabsList("IRR");
        break;
      case "6":
        this.SlabDropdown = true;
        this.getSlabsList("LTV");
        break;
    }
  }

  onSearchBusinessData() {
    this.chartdata = null;
    switch (this.ReportType) {
      case "1":
        this.getReportBranchWiseBusiness();
        break;
      case "2":
        this.getReportProductWiseBusiness();
        break;
      case "3":
        this.getReportAmountWiseBusiness();
        break;
      case "4":
        this.getReportTenureWiseBusiness();
        break;
      case "5":
        this.getReportIRRWiseBusiness();
        break;
      case "6":
        this.getReportLTVWiseBusiness();
        break;
      case "7":
        this.getReportSalesExecutiveWiseBusiness();
        break;
      case "8":
        this.getReportLoanAccCategoryWiseBusiness();
        break;
      case "9":
        this.getReportGenderWiseBusiness();
        break;
      case "10":
        this.getReportReligionWiseBusiness();
        break;
      case "11":
        this.getReportCasteWiseBusiness();
        break;
      case "12":
        this.getReportMaritalStatusWiseBusiness();
        break;
      case "13":
        this.getReportProfileWiseBusiness();
        break;
      case "14":
        this.getReportNatureOfWorkWiseBusiness();
        break;
    }
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
      });
  }

  getReportBranchWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Branch_Name",
      "NoOfLoans",
      "TotalBusiness",
      "AvgLoanSize",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_BranchWiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }
  getReportLoanAccCategoryWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Loan_Acc_Category",
      "NoOfLoans",
      "TotalBusiness",
      "AvgLoanSize",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_LoanAccCategoryWiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportProductWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Product_Name",
      "NoOfLoans",
      "TotalBusiness",
      "AvgLoanSize",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_ProductWiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }
  getReportAmountWiseBusiness() {
    this.showSpinner = true;
    //console.log(this.SlabId);
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_Amount_WiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("Amount",res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }
  getReportTenureWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_Tenure_WiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("Tenure",res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }
  getReportIRRWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_IRR_WiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("IRR",res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }
  getReportLTVWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_LTV_WiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("LTV",res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportGenderWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Gender",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_GenderWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Gender wise business report", res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportReligionWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Religion",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_ReligionWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportCasteWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Cast",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_CastWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportMaritalStatusWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "MaritalStatus",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_MaritalStatusWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportProfileWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Customer_Profile",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_ProfileWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getReportNatureOfWorkWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Natureofwork",
      "NoOfLoans",
      "TotalBusiness",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_NatureofworkWiseBusiness({
        FromDate: this.FromDate,
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  getDataforTable(res) {
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
    this.dataSource.sort = this.sort;
    this.showSpinner = false;
  }
  totaltypewise(type) {
    if (
      this.dataSource != null &&
      this.dataSource != undefined &&
      this.dataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      switch (type) {
        case "NoOfLoans":
          return sum.toFixed();
          break;
        case "TotalBusiness":
          return sum.toFixed(2);
          break;
        case "IN_Percentage":
          return sum.toFixed() + "%";
          break;
      }
    }
    return 0;
  }
  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `business-summary`,
      sheet: "business-summary",
    });
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }

  goToBookingListDetails(row) {
    this.displayedColumnsModel = [
      "Id",
      "Application_No",
      "Application_Date",
      "LoanAcNo",
      "Loan_Date",
      "Loan_Acc_Category",
      "CustomerName",
      "Customer_PhoneNo",
      "Address",
      "LatLong",
      "Co",
      "Co_name",
      "LoanAmount",
      "Application_InterestAmt",
      "Application_AgreementValue",
      "Application_LoanEMIAmount",
      "No_of_EMI",
      "Application_LoanTenure",
      "LTV",
      "ROI",
      "Application_Case_IRR",
      "Application_Disbursement_IRR",
      "Due_Date",
      "Application_ExpiryDate",
      "Application_AdvInstl",
      "EMI_Frexvency",
      "No_of_Ref",
      "Primary_Assets_Type",
      "Additional_Assets_Type",
      "SourceType",
      "Product_Name",
      "Branch_Name",
      "SourceArea",
      "SalesExecutive",
      "CollectionExecutive",
      "CollectionArea",
      "CollectionSubarea",
      "Disbursment_Bank",
      "Disbursment_Branch",
      "Disbursment_Amount",
      "SPDC_Bank",
      "SPDC_Cheque",
      "RPDC_Bank",
      "RPDC_Cheque",
      "Application_LoanPurpose",
      "EMI_Structure",
    ];
    this.paginator.pageIndex = 0;
    this.reportsService
      .Report_Booking_List({
        From: this.FromDate,
        To: this.ToDate,
        LoanAcId: row.LoanIds,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.FileCheckingList = JSON.parse(JSON.stringify(res));
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginator;
          $("#ViewBookingListDetails").modal("show");
          $("#ViewBookingListDetails").css("z-index", "1050");
        }
      });
  }
  onCloseDueListDetails() {
    $("#ViewBookingListDetails").modal("hide");
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  getReportSalesExecutiveWiseBusiness() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Employee_Name",
      "NoOfLoans",
      "TotalBusiness",
      "AvgLoanSize",
      "IN_Percentage",
      "Action",
    ];
    this.reportsService
      .Report_SalesExecutiveWiseBusiness({
        From: this.FromDate,
        To: this.ToDate,
        SlabId: this.SlabId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        //console.log("LTV",res);
        this.chartdata = res;
        if (res.length > 0) {
          this.getDataforTable(res);
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
          this.showSpinner = false;
        }
      });
  }

  exportTableAsXLSX(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "businesswise_model"
    ) as HTMLTableElement;
    const worksheetName = "BUSINESS WISE DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.DataSourceModel.filter = filterValue.trim().toLowerCase();
  }
}
