<div
  class="col-md-12 d-flex justify-content-between align-items-center formborder"
>
  <div class="col-md-3 p-1">
    <span class="required-lable">Select Status</span>
    <select
      name="SearchStatus"
      id="SearchStatus"
      #refSearchStatus="ngModel"
      class="form-control input-text-css"
      [(ngModel)]="SearchStatus"
      style="margin-bottom: 10px"
      (change)="onSearchDetail()"
    >
      <option value="">Select Status</option>
      <option value="Pending">Pending</option>
      <option value="Deleted">Deleted</option>
      <option value="Rejected">Rejected</option>
    </select>
  </div>

  <div class="col-md-4">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-3" [hidden]="!dataSourceDetail">
  <div class="table-responsive">
    <mat-table [dataSource]="dataSourceDetail">
      <ng-container matColumnDef="Deleted_Id">
        <mat-header-cell
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_No }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAcNo">
        <mat-header-cell class="grid-header" *matHeaderCellDef
          >LoanAc No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.LoanAcNo
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer_name">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Customer Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Branch Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product_Name">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Product Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_LoanAmount">
        <mat-header-cell class="grid-header" *matHeaderCellDef>
          Loan Amount</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_LoanAmount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell
          class="grid-header"
          style="max-width: 60px"
          *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToDetail(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedDetailColumns"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedDetailColumns"></mat-row>
    </mat-table>

    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>

    <!-- <div class="col-md-12 d-flex justify-content-between align-items-center">
      <div class="col-md-4">
        <button class="btn btn-success font-size-12" (click)="exportTable()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>

      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div> -->
  </div>
</div>

<div
  class="modal fade in"
  *ngIf="Row.request_Status == 'Pending'"
  id="LoanDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Delete Loan Detail
        </h6>
        <button
          type="button"
          (click)="onCloseLoanDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body modalbg">
        <div class="row m-0 mt-2 mb-4 box-style" *ngIf="Detail">
          <div class="col-md-2" *ngIf="Type != 'Partner'">
            <h6 class="fs-12">Application No</h6>
            <p class="lead fs-12">{{ Detail.ApplicationNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Branch</h6>
            <p class="lead fs-12">{{ Detail.Branch }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan Amount</h6>
            <p class="lead fs-12">₹{{ Detail.NetFinance }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Tenure</h6>
            <p class="lead fs-12">{{ Detail.LoanTenure }} Months</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan No</h6>
            <p class="lead fs-12">{{ Detail.LoanAcNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan Date</h6>
            <p class="lead fs-12">{{ Detail.Loan_Date }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Emi Amount</h6>
            <p class="lead fs-12">{{ Detail.EMIAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">No Of Inst</h6>
            <p class="lead fs-12">{{ Detail.No_Of_Instl }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Loan Product</h6>
            <p class="lead fs-12">{{ Detail.Product }}</p>
          </div>
        </div>
        <div class="row m-0 mt-2 mb-4 box-style">
          <div class="col-md-4">
            <h6 class="fs-12">Loan Delete Request By</h6>
            <p class="lead fs-12">{{ Row.Delete_requestBy }}</p>
          </div>
          <div class="col-md-4">
            <h6 class="fs-12">Loan Delete Request On</h6>
            <p class="lead fs-12">{{ Row.Delete_Loan_requestOn }}</p>
          </div>
          <div class="col-md-4">
            <h6 class="fs-12">Loan Delete Request Note</h6>
            <p class="lead fs-12">{{ Row.DeleterequestNote }}</p>
          </div>
        </div>

        <div
          class="row m-0 mb-4 col-md-12 box-style"
          *ngIf="dataSourceCustomerDetail"
        >
          <div class="table-responsive">
            <mat-table [dataSource]="dataSourceCustomerDetail">
              <ng-container matColumnDef="CustomerId">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Customer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Customer
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                >
                  <!--<app-los-customer-view-model [customerId]="row.CustomerId">{{row.Customer}}</app-los-customer-view-model>-->
                  <app-lms-customer-view-model
                    [customerId]="row.CustomerId"
                    [index]="i"
                    >{{ row.Customer }}</app-lms-customer-view-model
                  >
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="CustomerType">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Customer Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">{{
                  row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Gender Age
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.GenderAge }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Phone No</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.PhoneNo }}
                  <i
                    *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                    class="fa fa-check-circle ml-2"
                    style="color: green"
                  ></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Relation_With_Hirer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Relation With Hirer</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Relation_With_Hirer }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ExistingCustomer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Existing
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ExistingCustomer }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedCustomerDetailColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedCustomerDetailColumns"
              ></mat-row>
            </mat-table>
          </div>
        </div>

        <form
          #df="ngForm"
          (keydown.enter)="onSaveRequest_ForDelete()"
          novalidate
          class="row m-0 mb-4 col-md-12 p-0 box-style"
          *ngIf="Detail"
        >
          <div class="col-md-12">
            <span>Note</span>
            <input
              name="Note"
              [(ngModel)]="Note"
              id="Note"
              #refNote="ngModel"
              required
              placeholder="Note"
              class="form-control input-text-css"
              [ngClass]="{
                'is-invalid': df.submitted && refNote.invalid,
                'alert-warning':
                  refNote.invalid &&
                  (refNote.dirty || refNote.touched || refNote.untouched)
              }"
            />
          </div>
          <div class="col-md-3">
            <span>Status</span>
            <select
              name="Status"
              id="Status"
              required
              [(ngModel)]="Status"
              #refStatus="ngModel"
              class="form-control input-text-css"
              [ngClass]="{
                'is-invalid': df.submitted && refStatus.invalid,
                'alert-warning':
                  refStatus.invalid &&
                  (refStatus.dirty || refStatus.touched || refStatus.untouched)
              }"
            >
              <option value="">Status</option>
              <option value="Rejected">Rejected</option>
              <option value="Deleted">Approved</option>
            </select>
          </div>
          <!--<div class="col-md-4">
            <h6 class="fs-12">AttachURL</h6>
          </div>-->
          <div class="col-md-3 mt-1">
            <button
              type="button"
              (click)="onSaveForDelete()"
              [disabled]="!df.form.valid"
              class="font-size-12 button-btn mt-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="status_rejected"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #df="ngForm" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Delete Loan Detail
          </h6>
          <button
            type="button"
            (click)="onCloseStatusRejectedModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <div class="row">
                <!-- Deleted By : {{LoanDetail}} -->
                <!-- {{row.request_Status=="Deleted"?Deleted By : Rejected By }} -->
                <div class="col-md-6">
                  <span>Created By :{{ Row.Delete_requestBy }}</span>
                </div>
                <div class="col-md-6 text-right">
                  <span>Created On : {{ Row.Delete_Loan_requestOn }}</span>
                </div>
              </div>
              <textarea rows="2" class="form-control input-text-css" disabled>
                {{ Row ? Row.DeleterequestNote : "" }}</textarea
              >
            </div>
            <div class="col-md-12 p-1 mt-1">
              <div class="row">
                <!-- {{row.request_Status=="Deleted"?Deleted By : ''}} -->
                <div class="col-md-6">
                  <span
                    >{{
                      Row.request_Status == "Deleted"
                        ? "Deleted By"
                        : "Rejected By"
                    }}
                    : {{ Row.Delete_AuthorizedBy }}</span
                  >
                </div>
                <div class="col-md-6 text-right">
                  <span
                    >{{
                      Row.request_Status == "Deleted"
                        ? "Deleted On"
                        : "Rejected On"
                    }}
                    : {{ Row.Delete_AuthorizedOn }}</span
                  >
                </div>
              </div>
              <textarea rows="2" class="form-control input-text-css" disabled>
                {{ Row ? Row.Delete_AuthorizedNote : "" }}</textarea
              >
            </div>
            <!-- <div class="col-md-12 p-1 mt-1">
              Attachment : <a href="{{ docBaseUrl }}{{ LoanDetail ? LoanDetail.Application_No : '' }}/{{LoanDetail ? LoanDetail.Letter_AttachURL : ''}}" target="_blank">
                {{ LoanDetail ? LoanDetail.Letter_AttachURL : '' }}
              </a>
            </div> -->
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button
            type="button"
            (click)="onCloseLoanDetail()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div> -->
      </div>
    </div>
  </form>
</div>

<table
  id="lms_loan_dlt"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Customer_name }}</td>

    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
  </tr>
</table>
