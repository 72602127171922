<div>
  <div
    class="col-md-12 d-flex align-items-center justify-content-between formborder"
  >
    <div class="col-md-4 p-1">
      <label>Waiver Status</label>
      <select
        (change)="Get_Waiver_List_By_Status()"
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        [(ngModel)]="status"
      >
        <option value="Pending">Pending</option>
        <option value="Approved">Approved</option>
        <option value="Rejected">Rejected</option>
      </select>
    </div>

    <div class="col-md-4 p-1 text-center" style="margin-top: 13px">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="mt-3">
    <div class="">
      <div class="table-responsive setwidth">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Waivar_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Ac No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Creation_Remark">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Creation Remark</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Creation_Remark }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CreateOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Create On
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CreateOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AuthorizBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Authorize By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AuthorizBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RejectedBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Rejected By</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AuthorizBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Request_Total_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Request Amount</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.Request_Total_Amount }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Approved_Total_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Approved Amount</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.Approved_Total_Amount }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="AuthorizRemark">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Authorize Remark</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.AuthorizRemark }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RejectedRemark">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Rejected Remark</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.AuthorizRemark }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AuthorizOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Authorize On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell textset">
              {{ row.AuthorizOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RejectedOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Rejected On</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell textset">
              {{ row.AuthorizOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Status }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <app-lms-waiver-creation
                [LoanId]="row.Loan_Id"
                [Waivar_Id]="row.Waivar_Id"
                [Status]="row.Status"
                (close)="GetData($event)"
                [index]="i"
              >
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  aria-label="true"
                ></i>
              </app-lms-waiver-creation>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center" style="background: #fff">
          <div class="col-md-4">
            <button
              class="btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
