import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from '../../Shared/app.Masters.Service';
import { Router } from "@angular/router";
import { SnackbarComponent } from '../../snackbar/snackbar.component';
declare var $: any;
import { constantUrl } from '../../Shared/constantUrl';
import { LosService } from "../../_LOS/services/los.service";


@Component({
  selector: 'app-loan-delete-request',
  templateUrl: './loan-delete-request.component.html',
  styleUrls: ['./loan-delete-request.component.scss']
})
export class LoanDeleteRequestComponent implements OnInit {
  showSpinner: boolean = false;
  LoanNo: any; loading: boolean = false;
  currentUser: any;
  dataSourceCustomerDetail: any;
  Type: any = '';
  //Note: any;
  //AuthorizedBy: any = '';
  AuthorizedByList: any[] = [];

  customerlist: any[] = [];
  Detail: any;
  FileCheckingList: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedCustomerDetailColumns: string[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  Status: any = 'Pending';
  dataSource: any;
  displayedColumns: string[] = [];
  DeleteModel: any = {};

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  LoanDetail: any;

  constructor(private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private snackBar: MatSnackBar,
    private _EncrDecrService: EncrDecrService,
    private lmsService: LmsService,
    private router: Router,
    private encdec: EncrDecrService,
    private _LosService: LosService,
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Loan Delete Request");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedColumns = ['Loan_Id', 'Application_No', 'LoanAcNo', 'Loan_Date', 'Branch_Name', 'Product_Name', 'request_Status', 'CreatedBy_Request', 'Request_On', 'Action'];
    this.LoanDeleteStatusList();
    this.AuthorizedList();

    this.DeleteModel.AuthorizedBy = '';
  }
  onSearchDetail() {
    this.showSpinner = true;
    this.lmsService.Search_For_Loan_Delete_Request({ LoanAcAppNo: this.LoanNo, Type: this.Type }).subscribe((res: any) => {
      console.log("res", res);
      if (res.Item1.length > 0) {
        this.displayedCustomerDetailColumns = ['CustomerId', 'Customer', 'CustomerType', 'GenderAge', 'PhoneNo', 'Relation_With_Hirer', 'ExistingCustomer'];
        this.Detail = res.Item1[0];
        this.customerlist = res.Item2;
        this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
      }
      else{
        this.snackBar.openFromComponent(SnackbarComponent, { data: res.Item3[0].MSG, ...this.configSuccess});
      }
      this.showSpinner = false;
    });
  }

  OnChangeType() {
    this.displayedCustomerDetailColumns = null;
    this.dataSourceCustomerDetail = null;
    this.Detail = null;
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      data.AttachURL = "";
      delete data.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Please upload correct file`, ...this.configSuccess });
      files.value = "";
      data.AttachURL = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.AttachURL = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.AttachURL = "";
    delete data.DocData;
  }

  onSaveRequest_ForDelete() {
    console.log("this.DeleteModel", this.DeleteModel);
    
    this.lmsService.Request_For_Loan_Delete({
      LoanId: this.Detail.Loan_Id, LoginUserId: this.currentUser.userId, 
      AuthorizedBy: this.DeleteModel.AuthorizedBy, Note: this.DeleteModel.Note,
      CustumerId: this.customerlist.length > 0 && this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' }) != undefined ? this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' }).CustomerId : 0, 
      Type: this.Type, AttachURL: this.DeleteModel.AttachURL
    }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.uploadDoc({ ApplicationNo: this.Detail.ApplicationNo, DocName: this.DeleteModel.AttachURL, DocData: this.DeleteModel.DocData });
        this.OnChangeType();
        this.OnCloseModel();
        this.LoanDeleteStatusList();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess});
        this.LoanNo = '';
        this.Type = '';
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }

  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => { });
  }

  AuthorizedList() {
    this._MasterService.Get_Employee_ByBranch_Department({ BranchId: 0, DeptId: 0, LoginUserId: this.currentUser.userId, Type: "Upper" }).subscribe((res: any) => {
      this.AuthorizedByList = res.filter(x => x.EmpId != this.currentUser.userId);;
    });
  }

  LoanDeleteStatusList(){
    console.log("this.Status", this.Status);
    this.showSpinner = true;    
    this.lmsService.Loan_Delete_Status_List({ LoginUserId: this.currentUser.userId, Status: this.Status }).subscribe((res: any) => {
      console.log("res", res);
      if (res.length > 0) {
        this.FileCheckingList = JSON.parse(JSON.stringify(res));      
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else{
        this.dataSource = null;
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No data found', ...this.configSuccess });
      }
      this.showSpinner = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(row: any){
    this.LoanDetail = row;
    console.log("row", row);
    
    $('#LoanDetail').modal('show');
    $("#LoanDetail").css("z-index", "1050");
  }
  OnCloseModelDetail(){
    $(`#LoanDetail`).modal("hide");
  }

  OnClickNewLoanDelete(){
    this.LoanNo = '';
    this.Type = '';
    this.displayedCustomerDetailColumns = null;
    this.dataSourceCustomerDetail = null;
    this.Detail = null;
    this.DeleteModel.Note = '';
    this.DeleteModel.AuthorizedBy = '';
    this.DeleteModel.AttachURL = '';
    $('#LoanDelete').modal('show');
    $("#LoanDelete").css("z-index", "1050");
  }
  OnCloseModel(){
    $(`#LoanDelete`).modal("hide");
  }

  exportTable(): void {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string => window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string => s.replace(/{(\w+)}/g, (m, p) => c[p]);
  
    const table = document.getElementById("lms_loan_dlt_request") as HTMLTableElement;
    const worksheetName = 'LOAN DELETE REQUEST'; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");
  
    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));
  
    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";
  
    // Trigger the download
    downloadLink.click();
  }
}
