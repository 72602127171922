import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { PartnerService } from "../services/partner.service";
declare var $: any;

@Component({
  selector: 'app-partner-pre-closer',
  templateUrl: './partner-loan-pre-closer.component.html',
  styleUrls: ['./partner-loan-pre-closer.component.scss']
})
export class PartnerLoanPreCloserComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  caseType: any=0;

  constructor(
    private lmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _Partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Pre Closer");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    
    this.displayedColumns = ["PLoan_Id", "PLoan_Date", "PLoanAcNo", "LMS_LoanAcNo", "LoanAmount", "PLoanCreatedOn", "Action"];
    
    this.GetPartnerPreCloser();
  }

  GetPartnerPreCloser() {
    this.showSpinner = true;
    this._Partnerservice.Partner_Get_Pre_Closer_List({ IsClose:this.caseType }).subscribe((res: any) => {
      console.log(res);
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(row){
    this.router.navigate([`partner-dashboard/pre-closer/${this._EncrDecrService.encrypt(encodeURIComponent(row.PLoan_Id))}/${this._EncrDecrService.encrypt(encodeURIComponent(row.CloserId)) }`]);
  }
}
