<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchOwnManagedFunds()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Report Type </span>
        <select
          name="ReportType"
          id="ReportType"
          [(ngModel)]="ReportType"
          class="form-control input-text-css"
          (change)="onChangeReportTypes()"
          required
          #refReportType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refReportType.invalid,
            'alert-warning':
              refReportType.invalid &&
              (refReportType.dirty ||
                refReportType.touched ||
                refReportType.untouched)
          }"
        >
          <option value="">Select Report Type</option>
          <option *ngFor="let row of ArrTypes" [value]="row.id">
            {{ row.value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" *ngIf="SlabDropdown">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="''">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchOwnManagedFunds()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="DataDiv">
    <div class="">
      <div class="table-responsive mt-3">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="width: 10px !important"
              >#</mat-header-cell
            >
            <!-- style="max-width: 40px"x -->
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="width: 10px !important"
              >{{ i + 1 }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              style="width: 10px !important"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product_Name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Gender }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Religion">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Religion</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Religion }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>
          <ng-container matColumnDef="Caste">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Caste</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Caste }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="MaritalStatus">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Marital Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.MaritalStatus }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Customer_Profile">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Profile</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Profile }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Natureofwork">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Nature of work</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Natureofwork }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="TotalNoOfLoans">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Total(No Of Loans)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              {{ row.TotalNoOfLoans }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header text-center"
              >{{ totaltypewise("TotalNoOfLoans") }}</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="SlabName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Slab Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.SlabName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-center"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="NoOfLoansOwn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Own(No Of Loans)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              {{ row.NoOfLoansOwn }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-center">
              {{ totaltypewise("NoOfLoansOwn") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="NoOfLoansPartner">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Partner(No Of Loans)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-center">
              {{ row.NoOfLoansPartner }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-center">
              {{ totaltypewise("NoOfLoansPartner") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalPOS_OS">
            <mat-header-cell
              mat-sort-header
              class="grid-header mw200 text-right"
              *matHeaderCellDef
              >Total(POS)</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell mw200 text-right"
              >{{ row.TotalPOS_OS }}</mat-cell
            >
            <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              {{ totaltypewise("TotalPOS_OS") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="POS_OSOwn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Own(POS)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.POS_OSOwn }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-right">
              {{ totaltypewise("POS_OSOwn") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="POS_OSPartner">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Partner(POS)</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.POS_OSPartner }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-right">
              {{ totaltypewise("POS_OSPartner") }}
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-12 p-2">
            <button
              class="mt-3 btn font-size-12 button-btn float-right"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="own_managed_funds"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr class="table-header">
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total(No of Loans)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Own(No of Loans)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Partner(No of Loans)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total(POS)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Own(POS)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Partner(POS)
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList1; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Name }}</td>
    <td style="text-align: center">{{ x.TotalNoOfLoans }}</td>
    <td style="text-align: center">{{ x.NoOfLoansOwn }}</td>
    <td style="text-align: center">{{ x.NoOfLoansPartner }}</td>
    <td>{{ x.TotalPOS_OS }}</td>
    <td>{{ x.POS_OSOwn }}</td>
    <td>{{ x.POS_OSPartner }}</td>
  </tr>

  <tfoot>
    <tr class="table-footer">
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: center;
        "
      >
        <strong>{{ totaltypewise("TotalNoOfLoans") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: center;
        "
      >
        <strong>{{ totaltypewise("NoOfLoansOwn") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: center;
        "
      >
        {{ totaltypewise("NoOfLoansPartner") }}
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("TotalPOS_OS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("POS_OSOwn") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("POS_OSPartner") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
