<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div
    class="formborder col-md-12"
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    "
  >
    <div class="pt-1">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <!-- <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div> -->
          </div>
        </div>
      </form>
    </div>
    <div class="">
      <button
        type="button"
        *ngIf="AddLoan"
        class="btn font-size-12 button-btn"
        (click)="AddNewLoan()"
      >
        Add New Loan
      </button>
    </div>
  </div>

  <div class="mt-2" *ngIf="DivLMSCP">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporterLoanInfo="matTableExporter"
          [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
          #SortTable1
          style="overflow: auto; height: 60vh; max-width: 100%"
        >
          <ng-container matColumnDef="LoanId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >

            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Application_No }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_Executive">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Executive</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Executive }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Status }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan Product
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanCreatedOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Assign On
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanCreatedOn }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Assign">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetailLoan(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center" style="background: #fff">
          <div class="col-md-4">
            <button
              class="mt-2 btn font-size-12 button-btn"
              (click)="exportTable2()"
            >
              <i
                class="fa fa-file-export"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorlist
              [pageSize]="20"
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  *ngIf="DivLOSCP"
  id="lms_update_booking_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(hr.)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Completed On
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.LoanCreatedOn }}</td>
    <td>{{ x.FileCheckingHO_Executive }}</td>
    <td>{{ x.FileCheckingHO_Status }}</td>
    <td>
      <span *ngIf="row.Is_TAT_Over == true" style="color: red">{{
        x.FileCheckingHO_TAT_Hr
      }}</span>
      <span *ngIf="row.Is_TAT_Over == false">{{
        x.FileCheckingHO_TAT_Hr
      }}</span>
    </td>

    <td>{{ x.ComplotedOn }}</td>
  </tr>
</table>

<table
  *ngIf="DivLMSCP"
  id="lms_update_booking_list1"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList1; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product }}</td>
    <td>{{ x.Customer_Executive }}</td>
    <td>{{ x.LoanCreatedOn }}</td>

    <td>{{ x.Status }}</td>
    <td>{{ x.Assign }}</td>
  </tr>
</table>
