import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { LmsService } from '../_Lms/services/lms.service';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
declare var $: any;
import * as moment from 'moment';
import * as shajs from 'sha.js';
import { AnyObject } from 'chart.js/types/basic';
import { DatePipe } from '@angular/common'
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { MasterService } from '../Shared/app.Masters.Service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Token } from '@angular/compiler';
import { DateTime } from '@syncfusion/ej2-angular-charts';
//const url1 = 'https://www.paynimo.com/Paynimocheckout/client/lib/jquery.min.js';
//const url2 = 'https://www.paynimo.com/Paynimocheckout/server/lib/checkout.js';

@Component({
  selector: 'app-payment-api',
  templateUrl: './payment-api.component.html',
  styleUrls: ['./payment-api.component.scss']
})
export class PaymentApiComponent implements OnInit {
  loading: boolean = false;
  currentUser: any;
  PaymentModel: any = [];
  hdfc_Utilcode: any;
  hdfc_Short_Code: any;
  hdfc_key: any;
  hdfc_Merchant_Category_Code: any;
  ICICI_MerchantCode: any;
  ICICI_Salt: any;
  @Input() loan_Summary: any;
  @Input() TypeofPage: any;
  @Input() ENachBankId: any;
  @Input() ENachHdfcBankId: any;
  @Input() ENachAUBankId: any;
  @Input() ENACHNupayHDFCId: any;
  NACH: any = {};
  livebankList: any = []
  Status: any;
  minDate: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };


  constructor(private datepipe: DatePipe, private router: Router,
    private LmsService: LmsService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    //var aa = JSON.parse(JSON.stringify("stringResponse: {itc:~mandateData{UMRNNumber:ICIC7010710220006172~IFSCCode:ICIC0000650~amount_type:M~frequency:ADHO~account_number:065001512236~expiry_date:10-10-2022~ifsc_code:ICIC0000650~amount:20~identifier:~schedule_date:01-10-2022~debitDay:~debitFlag:N~aadharNo:~accountHolderName:ajay gautam~accountType:Saving~dateOfBirth:~mandatePurpose:~utilityNo:~helpdeskNo:~helpdeskEmail:~pan:~phoneNumber:~emailID:}}{mob:9887067424}"));
    //console.log("AA", eval(aa));
    //console.log("this.TypeofPage", eval(this.TypeofPage));
  }

  OpenPaymentModel() {
    //console.log("loan_Summary", this.loan_Summary);
    this.PaymentModel = {
      SelectBank: '',
      ACType: '',
      CType: 'M',
      CFreq: 'ADHO',
      StartDate: moment(new Date()).format('DD/MM/YYYY'),
      StartDatehdfc: new Date(),
      StartDateAU: new Date(),
      Channel: '',
      BankIdHdfc: '',
      BankIdAU: '',
      Mandate: ''
    };


    var Loan_Id;
    if (this.TypeofPage == 'LMS') {
      Loan_Id = this.loan_Summary.LoanId;
    }
    else {
      Loan_Id = this.loan_Summary.ApplicationIdentity;
    }
    this._MasterService.ENach_Get_Registration_Deatil({ Loan_Id: Loan_Id }).subscribe((res: any) => {
      //console.log("res", res);
      this.PaymentModel.ApplicationNo = res[0].Application_No;
      this.PaymentModel.FullName = res[0].CustomerName;
      this.PaymentModel.Email = res[0].Customer_Email;
      this.PaymentModel.MobileNo = res[0].Customer_PhoneNo;
      this.PaymentModel.ACNo = res[0].AccountNumber;
      this.PaymentModel.IFSCCode = res[0].IFSCCode;
      this.PaymentModel.ACType = res[0].AccountType;
      this.PaymentModel.MaxAmt = res[0].MaximumCollectionAmount;
      this.PaymentModel.CAmt = res[0].CollectionAmount == 0 ? '' : res[0].CollectionAmount;
      this.PaymentModel.CustomerId = res[0].CustomerId;
      this.PaymentModel.Loan_Id = Loan_Id;
      this.PaymentModel.EndDate = moment(res[0].ExpiryDate).format('DD/MM/YYYY');
      this.PaymentModel.EndDatehdfc = new Date(res[0].ExpiryDate);
      this.PaymentModel.StartDateAU = new Date();
      this.PaymentModel.EndDateAU = new Date(res[0].ExpiryDate);
      this.hdfc_Utilcode = res[0].hdfc_Utilcode;
      this.hdfc_Short_Code = res[0].hdfc_Short_Code;
      this.hdfc_key = res[0].hdfc_key;
      this.hdfc_Merchant_Category_Code = res[0].hdfc_Merchant_Category_Code;
      this.ICICI_MerchantCode = res[0].ICICI_MerchantCode;
      this.ICICI_Salt = res[0].ICICI_Salt;
      this.minDate = new Date(moment(res[0].ExpiryDate).format());
    });

    $(`#MakePayment`).modal("show");
    $(`#MakePayment`).css("z-index", "1050");
  }

  BankChange() {
    if (this.PaymentModel.SelectBank == 'HDFC') {
      if (this.PaymentModel.ACType == 'Saving')
        this.PaymentModel.ACType = 'S';
      else if (this.PaymentModel.ACType == 'Current')
        this.PaymentModel.ACType = 'C'
      else if (this.PaymentModel.ACType == '')
        this.PaymentModel.ACType = ''


    }
    else {
      this.PaymentModel.ACType = '';
    }
    if (this.PaymentModel.SelectBank == 'AU') {
      this.PaymentModel.CType = 'Y'
    }
    if (this.PaymentModel.SelectBank == 'Nupay') {
      this.PaymentModel.CType = 'true'
    }
  }
  ChannelChange() {
    this.livebankList = []
    if (this.PaymentModel.Channel == 'Debit') {
      this._MasterService.getLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.debitcardFlag == "Active").sort((a, b) => a.bankName.localeCompare(b.bankName));
          console.log("live bank", res);
        }
      })
    }
    if (this.PaymentModel.Channel == 'Net') {
      this._MasterService.getLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.netbankFlag == "Active").sort((a, b) => a.bankName.localeCompare(b.bankName));
          console.log("live bank", res);
        }
      })
    }

  }

  ChannelChangeNupay() {
    this.livebankList = []
    this.PaymentModel.BankIdAU = '';
   
    if (this.PaymentModel.Channel == 'DebitCard') {
      this._MasterService.getNupayLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.mode.toLowerCase().includes('debit card'.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
          console.log("live bank", res);
        }
      })
    }
    if (this.PaymentModel.Channel == 'NetBanking') {
      this._MasterService.getNupayLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.mode.toLowerCase().includes('NetBanking'.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
          console.log("live bank", res);
        }
      })
    }
    if (this.PaymentModel.Channel == 'Aadhaar') {
      this._MasterService.getNupayLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.mode.toLowerCase().includes('Aadhaar'.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
          console.log("live bank", res);
        }
      })
    }

  }
  getNupayLiveBankDtls
  onClosePaymentModel() {
    $(`#MakePayment`).modal("hide");
  }

  ngAfterViewInit() {
    var TokenRequest;
    $(document).ready(function () {
      function handleResponse(res) {
        $('#Request').text(JSON.stringify(TokenRequest));
        $('#Response').text(JSON.stringify(res));
        $('#Status').text(res.paymentMethod.paymentTransaction.statusMessage);
        $('#Identifier').text(res.paymentMethod.paymentTransaction.instruction.id);
        $('#Click').click();
        if (res.paymentMethod.paymentTransaction.statusMessage == 'success') {
          var code = res.merchantAdditionalDetails;
          var UMRNNumber = code.split(':')[2].split('~')[0];
          console.log("codearray", UMRNNumber);
          $('#UMRNNumber').text(UMRNNumber);
        }
        if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0300') {
          // success block
        } else if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0398') {
          // initiated block
        } else {
          // error block
        }
      };

      $(document).on('click', '#btn_Submit', function (e) {
        e.preventDefault();
        var ApplicationNo = $('#ApplicationNo').val();
        var FullName = $('#FullName').val();
        var MobileNo = $('#MobileNo').val().trim();
        var Email = $('#Email').val();
        var ACType = $('#ACType').val();
        var CAmt = $('#CAmt').val();
        var MaxAmt = $('#MaxAmt').val();
        var CType = $('#CType').val();
        var CFreq = $('#CFreq').val();
        var StartDate = $('#StartDate').val();
        var EndDate = $('#EndDate').val();
        var ACNo = $('#ACNo').val();
        var IFSCCode = $('#IFSCCode').val();



        var MerchantCode = $('#ICICI_MerchantCode').val();
        var Salt = $('#ICICI_Salt').val();

        var StDate = StartDate.toString().replace('/', '-').replace('/', '-')
        // moment(StartDate).format('MM-DD-YYYY');
        var En_Date = EndDate.toString().replace('/', '-').replace('/', '-')
        // moment(EndDate).format('MM-DD-YYYY');

        var TrnNo = Math.floor(Math.random() * 100000000000);
        console.log("TrnNo", TrnNo);

        // TokenRequest = "L818856|"+TrnNo+"|"+CAmt+"|"+ACNo+"|"+ApplicationNo+"|"+MobileNo+"|"+Email+"|"+StDate+"|"+En_Date+"|"+MaxAmt+"|"+CType+"|"+CFreq+"|||||2907673365APFCYY";
       // TokenRequest = "L818856|" + TrnNo + "|1|" + ACNo + "|" + ApplicationNo + "|" + MobileNo + "|" + Email + "|" + StDate + "|" + En_Date + "|" + MaxAmt + "|" + CType + "|" + CFreq + "|||||2907673365APFCYY";
        TokenRequest = MerchantCode + "|" + TrnNo + "|1|" + ACNo + "|" + ApplicationNo + "|" + MobileNo + "|" + Email + "|" + StDate + "|" + En_Date + "|" + MaxAmt + "|" + CType + "|" + CFreq + "|||||" + Salt;
        console.log("TokenRequest", TokenRequest);
        var hash = shajs('sha512').update(TokenRequest).digest('hex');
        console.log("hash", hash);

        var configJson = {
          'tarCall': false,
          'features': {
            'showPGResponseMsg': true,
            'enableAbortResponse': true,
            'enableNewWindowFlow': true,    //for hybrid applications please disable this by passing false
            'enableExpressPay': true,
            'siDetailsAtMerchantEnd': true,
            'enableSI': true
            //'payDetailsAtMerchantEnd':true,
            //'hideSIDetails': true,
            //'hideSIConfirmation': true,
            //'expandSIDetails': true
          },
          //merchantid|txnId|totalamount|accountNo|consumerId|consumerMobileNo|consumerEmailId |debitStartDate|debitEndDate|maxAmount|amountType|frequency|cardNumber| expMonth|expYear|cvvCode|SALT

          // L818856|14822975811|1|054601509297|c964636|9876543210|test@test.com|10-07-2022|01-03-2047|100|M|ADHO|||||2907673365APFCYY

          'consumerData': {
            'deviceId': 'WEBSH2',	//possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
            'token': hash,
            'returnUrl': '',    //merchant response page URL //file:///D:/Test/Return.html
            'responseHandler': handleResponse,
            'paymentMode': 'all',   //netbanking
            'merchantLogoUrl': 'https://www.paynimo.com/CompanyDocs/company-logo-md.png',  //provided merchant logo will be displayed
            //'merchantId': 'L818856',
            'merchantId': MerchantCode,
            'currency': 'INR',
            'consumerId': ApplicationNo,  //Your unique consumer identifier to register a eMandate/eNACH
            'consumerMobileNo': MobileNo.trim(),
            'consumerEmailId': Email,
            'txnId': TrnNo,   //Unique merchant transaction ID
            'items': [{
              'itemId': 'First',
              'amount': '1',
              'comAmt': '0'
            }],
            'customStyle': {
              'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
              'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
              'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
              'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
            },

            //'bankCode':'10010',
            'accountNo': ACNo,    //Pass this if accountNo is captured at merchant side for eMandate/eNACH
            'accountHolderName': FullName,  //Pass this if accountHolderName is captured at merchant side for ICICI eMandate & eNACH registration this is mandatory field, if not passed from merchant Customer need to enter in Checkout UI.
            'ifscCode': IFSCCode,        //Pass this if ifscCode is captured at merchant side.
            'accountType': ACType,  //Required for eNACH registration this is mandatory field
            'debitStartDate': StDate,
            'debitEndDate': En_Date,
            //'pan': 'CWXPP6777Q'
            'maxAmount': MaxAmt,
            'amountType': CType,
            'frequency': CFreq	//  Available options DAIL, WEEK, MNTH, QURT, MIAN, YEAR, BIMN and ADHO
          }
        };
        //console.log("Status", Status);
        //console.log("configJson", configJson.consumerData);
        $.pnCheckout(configJson);
        if (configJson.features.enableNewWindowFlow) {
          e.pnCheckoutShared.openNewWindow();
        }
      });



      $(document).on('click', '#btn_SubmitHDFC', function (e) {
        e.preventDefault();

        var ACNo = $('#ACNo1').val();
        var StartDate = $('#StartDate1').val();
        var EndDate = $('#EndDate1').val();

        var hdfc_key = $('#hdfc_key').val();
        var hdfc_Utilcode = $('#hdfc_Utilcode').val();
        var hdfc_Short_Code = $('#hdfc_Short_Code').val();
        var hdfc_Merchant_Category_Code = $('#hdfc_Merchant_Category_Code').val();



        var Customer_StartDate = StartDate.toString().replace('/', '-').replace('/', '-').split("-").reverse().join("-")
        var Customer_ExpiryDate = EndDate.toString().replace('/', '-').replace('/', '-').split("-").reverse().join("-")
        var Customer_DebitAmount = parseFloat($('#CAmt1').val()).toFixed(2);
        //var Customer_MaxAmount = parseFloat($('#MaxAmt1').val()).toFixed(2);
        TokenRequest = ACNo + "|" + Customer_StartDate + "|" + Customer_ExpiryDate + "|" + Customer_DebitAmount + "|";
        console.log("TokenRequest", TokenRequest);
        var CheckSum = shajs('sha256').update(TokenRequest).digest('hex');
        console.log("CheckSum", CheckSum);
        //var key = CryptoJS.enc.Utf8.parse("k2hLr4X0ozNyZByj5DT66edtCEee1x+6");
        //var Utilcode = ("\\x" + CryptoJS.AES.encrypt("NACH00000000022141", key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));
        //var Merchant_Category_Code = "L001";
        // var Short_Code = "\\x" + CryptoJS.AES.encrypt("MSFIN", key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex);

        var key = CryptoJS.enc.Utf8.parse(hdfc_key);
        var Utilcode = ("\\x" + CryptoJS.AES.encrypt(hdfc_Utilcode, key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));
        var Merchant_Category_Code = hdfc_Merchant_Category_Code;
        var Short_Code = "\\x" + CryptoJS.AES.encrypt(hdfc_Short_Code, key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex);

        var Customer_Name = "\\x" + CryptoJS.AES.encrypt($('#FullName1').val(), key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex);

        var Customer_Mobile = $('#MobileNo1').val() != '' ? "\\x" + CryptoJS.AES.encrypt($('#MobileNo1').val(), key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex) : '';
        //var Customer_EmailId = $('#Email1').val() != '' ? "\/x" + CryptoJS.AES.encrypt($('#Email1').val(), key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex):'';
        var Customer_AccountNo = "\\x" + CryptoJS.AES.encrypt(ACNo, key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex);

        var MsgId = $('#ApplicationNo1').val();
        //var MsgId = Math.random() * 100;
        var Customer_SequenceType = "RCUR";
        var IFSC_Code = $('#IFSCCode1').val();
        var Channel = $('#Channel1').val();
        var Customer_DebitFrequency = $('#CFreq1').val();
        var ACType = $('#ACType1').val();
        var bankCode = $('#BankIdHdfc').val();



        //form send
        //$('#UtilCode').val("\\x" + CryptoJS.AES.encrypt("NACH00000000022141", key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));
        //$('#Merchant_Category_Code').val("L001");
        //$('#Short_Code').val("\\x" + CryptoJS.AES.encrypt("MSFIN", key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));
        $('#UtilCode').val("\\x" + CryptoJS.AES.encrypt(hdfc_Utilcode, key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));
        $('#Merchant_Category_Code').val(hdfc_Merchant_Category_Code);
        $('#Short_Code').val("\\x" + CryptoJS.AES.encrypt(hdfc_Short_Code, key, { mode: CryptoJS.mode.ECB, }).ciphertext.toString(CryptoJS.enc.Hex));


        $('#Filler6').val(bankCode);
        $('#Customer_SequenceType').val("RCUR");
        $('#Filler5').val(ACType);
        $('#Channel').val(Channel);
        $('#CheckSum').val(CheckSum);
        $('#MsgId').val(getCurrentDateTimeFormatted());
        $('#Customer_Name').val(Customer_Name);
        $('#Customer_Mobile').val(Customer_Mobile);
        $('#Customer_AccountNo').val(Customer_AccountNo);
        $('#Customer_StartDate').val(Customer_StartDate);
        $('#Customer_ExpiryDate').val(Customer_ExpiryDate);
        $('#Customer_DebitAmount').val(Customer_DebitAmount);
        $('#Customer_DebitFrequency').val(Customer_DebitFrequency);
        $('#Customer_InstructedMemberId').val(IFSC_Code);



        $('#Request').text(JSON.stringify(TokenRequest));
        $('#Response').text(JSON.stringify(""));
        $('#Status').text('Aweted');
        $('#Identifier').text($('#MsgId').val());
        $('#ClickHdfc').click();




      })



    })

    function getCurrentDateTimeFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }

  }

  SavePayment() {
    var Request = $('#Request').text();
    var Response = $('#Response').text();
    var Status = $('#Status').text();
    var Identifier = $('#Identifier').text();

    this.NACH.LoanId = this.loan_Summary.LoanId;
    this.NACH.URL = 'Registration';
    this.NACH.Request = Request;
    this.NACH.Response = Response;
    this.NACH.CreatedBy = this.currentUser.userId;
    this.NACH.Status = Status;
    this.NACH.Identifier = Identifier;
    this.NACH.Type = 'ICICI'

    let _data = {
      NACH: this.NACH
    }

    //console.log('_data', _data);
    this.LmsService.LMS_Save_ENach_Request_Response({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      this.loading = false;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Status, ...this.configSuccess });

      this.ENachSaveNachInfo(this.NACH.Status);
    });
  }
  ENachHdfcSaveNachInfo() {
    var ENach: any = {
      Loan_Id: this.PaymentModel.Loan_Id,
      NachAmount: this.PaymentModel.CAmt,
      PresentBankInCompany: this.ENachHdfcBankId,
      CustomerId: this.PaymentModel.CustomerId,
      CreateBy: this.currentUser.userId,
      Frequency: this.PaymentModel.CFreq,
      Status: 'Pending',
      Identifier: $('#Identifier').text(),
      BankName: 'HDFC'
    };

    let _data = {
      ENACH: ENach
    }
    //console.log('_data', _data);
    this._MasterService.ENach_Save_NachInfo({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res.length > 0) {
        console.log("res", res);
        this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
        document.getElementById('FinalSubmitHdfc').click();

      }

    });
  }
  ENachAUSaveNachInfo() {
    var StartDate;
    var PaymentStartDate;
    var EndDate;
    var PaymentEndDate;
    if (this.PaymentModel.Mandate == 'WithUser') {
      StartDate = new Date(this.PaymentModel.StartDateAU);
      PaymentStartDate = this.datepipe.transform(StartDate, 'yyyyMMdd').toString().split("T")[0].replace('-', '').replace('-', '');
      EndDate = new Date(this.PaymentModel.EndDateAU);
      PaymentEndDate = this.datepipe.transform(EndDate, 'yyyyMMdd').toString().split("T")[0].replace('-', '').replace('-', '');

    } else {
      StartDate = new Date(this.PaymentModel.StartDateAU);
      PaymentStartDate = this.datepipe.transform(StartDate, 'MM/dd/yyyy').toString().split("T")[0].replace('-', '/').replace('-', '/');
      EndDate = new Date(this.PaymentModel.EndDateAU);
      PaymentEndDate = this.datepipe.transform(EndDate, 'MM/dd/yyyy').toString().split("T")[0].replace('-', '/').replace('-', '/');

    }

    var nachAUJson = {
      //"mbSvcProviderName": "VISHAWAS FINVEST PRIVATE LIMITED",
      "mbDateFrom": PaymentStartDate,
      "mbCustMailId": this.PaymentModel.Email.trim(),
      "mbCustFonCellNum": this.PaymentModel.MobileNo.trim(),
      "mbFrequencyType": "RCUR",
      "mbCustName": this.PaymentModel.FullName,
      "mbRefNumber": this.PaymentModel.ApplicationNo,

      "mbDRAccountNumber": this.PaymentModel.ACNo,
      "mbMandateType": "DEBIT",
      "mbDateTo": PaymentEndDate,
      "mbRelRefNumber": this.PaymentModel.ApplicationNo,
      "mbCustFonLandNum": "",
      "mbDRBankCode": this.PaymentModel.BankIdAU,
      "mbAmount": this.PaymentModel.CAmt.toString(),
      "mbPaymentType": this.PaymentModel.Channel,
      "responseURL": "",
      "mbDRAccountType": this.PaymentModel.ACType,
      "mbFrequencyCode": this.PaymentModel.CFreq,
      "mbFixedAmount": this.PaymentModel.CType,
      "referenceCode": Math.floor(Math.random() * 10000000000).toString(),
      "mbCustPAN": ""
    }
    console.log("nachJson", JSON.stringify(this.dataSharingService.cleanObject(nachAUJson)));

    var nachData = {
      LoanId: this.PaymentModel.Loan_Id,
      URL: '',
      Request: '',
      Response: '',
      CreatedBy: this.currentUser.userId,
      Status: 'Pending',
      Identifier: nachAUJson.referenceCode,
      Type: 'AU',
      Mandate: this.PaymentModel.Mandate
    }
    let _dataSave = {
      NACH: nachData
    }
    var ENach: any = {
      Loan_Id: this.PaymentModel.Loan_Id,
      NachAmount: this.PaymentModel.CAmt,
      PresentBankInCompany: this.ENachAUBankId,
      CustomerId: this.PaymentModel.CustomerId,
      CreateBy: this.currentUser.userId,
      Frequency: this.PaymentModel.CFreq,
      Status: 'Pending',
      Identifier: nachAUJson.referenceCode,
      BankName: 'AU'
    };

    let _data = {
      ENACH: ENach
    }
    if (this.PaymentModel.Mandate == 'WithUser') {
      this._MasterService.AU_Mandate_RegistrationByUser({ AURequestJson: JSON.stringify(nachAUJson), Json: JSON.stringify(_dataSave) }).subscribe((res: any) => {
        if (res.status != '') {
          console.log("res", res);
          this.snackBar.openFromComponent(SnackbarComponent, { data: res.MSG, ...this.configSuccess });
          if (res.status == 'Accepted') {

            this._MasterService.ENach_Save_NachInfo({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
              if (res.length > 0) {
                console.log("res", res);
                this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
              }

            });
          }


        }

      });
    }
    else {
      this._MasterService.AU_Mandate_RegistrationWithoutUser({ AURequestJson: JSON.stringify(nachAUJson), Json: JSON.stringify(_dataSave) }).subscribe((result11: any) => {
        if (result11.status != '') {
          console.log("res", result11);
          this.snackBar.openFromComponent(SnackbarComponent, { data: result11.ResponseMessage, ...this.configSuccess });
          if (result11.ResponseMessage == 'Success') {
            var htmlContent = result11.Messgae;
            this._MasterService.ENach_Save_NachInfo({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
              if (res.length > 0) {
                console.log("res", res);
                const newWindow = window.open('', '_blank');
                newWindow.document.open();
                newWindow.document.write(htmlContent);
                newWindow.document.close();

                this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
              }

            });
          }


        }

      });
    }


  }
  ENachSaveNachInfo(Status: any) {
    var ENach: any = {
      Loan_Id: this.PaymentModel.Loan_Id,
      NachAmount: this.PaymentModel.CAmt,
      PresentBankInCompany: this.ENachBankId,
      NachCode: $('#UMRNNumber').text(),
      CustomerId: this.PaymentModel.CustomerId,
      CreateBy: this.currentUser.userId,
      Frequency: this.PaymentModel.CFreq,
      Status: Status == 'success' ? 'Aweted' : 'Failed',
      Identifier: this.NACH.Identifier,
      BankName: 'ICICI'
    };

    let _data = {
      ENACH: ENach
    }

    //console.log('_data', _data);
    this._MasterService.ENach_Save_NachInfo({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      //console.log("res", res);
    });
  }

  ChannelChangeAU() {
    this.livebankList = []
    if (this.PaymentModel.Channel == 'DebitCard') {
      this._MasterService.getLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.debitcardFlag == "Active").sort((a, b) => a.bankName.localeCompare(b.bankName));
          console.log("live bank", res);
        }
      })
    }
    if (this.PaymentModel.Channel == 'NetBanking') {
      this._MasterService.getLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.netbankFlag == "Active").sort((a, b) => a.bankName.localeCompare(b.bankName));
          console.log("live bank", res);
        }
      })
    }
    if (this.PaymentModel.Channel == 'Aadhaar') {
      this._MasterService.getLiveBankDtls().subscribe((res: any) => {
        if (res.length > 0) {
          this.livebankList = res.filter((x) => x.aadhaarFlag == "Active").sort((a, b) => a.bankName.localeCompare(b.bankName));
          console.log("live bank", res);
        }
      })
    }

  }
  ENachNupaySaveNachInfo() {
    //this.PaymentModel.StartDateAU = new Date(this.PaymentModel.StartDateAU);
    this.PaymentModel.StartDateAU = this.datepipe.transform(this.PaymentModel.StartDateAU, 'yyyy-MM-dd').toString().split("T")[0];
    //this.PaymentModel.EndDateAU = new Date(this.PaymentModel.EndDateAU);
    this.PaymentModel.EndDateAU = this.datepipe.transform(this.PaymentModel.EndDateAU, 'yyyy-MM-dd').toString().split("T")[0];

    let _datajson = {
      NACH: { LoanId: this.loan_Summary.LoanId, CreatedBy: this.currentUser.userId }
    }
    let requestBode = {
      "loan_no": this.PaymentModel.ApplicationNo,
      "seq_tp": "RCUR",
      "frst_colltn_dt": this.PaymentModel.StartDateAU,
      "fnl_colltn_dt": this.PaymentModel.EndDateAU,
      "colltn_until_cncl": false,
      "colltn_amt": this.PaymentModel.MaxAmt,
      "mobile_no": this.PaymentModel.MobileNo,
      "tel_no": "",
      "email": this.PaymentModel.Email == null ? '' : this.PaymentModel.Email,
      "category_id": 7,
      "account_holder_name": this.PaymentModel.FullName,
      "bank_id": Number(this.PaymentModel.BankIdAU),
      "auth_type": "",
      "account_type": this.PaymentModel.ACType,
      "ifsc_code": this.PaymentModel.IFSCCode,
      "bank_account_no": this.PaymentModel.ACNo,
      "bank_account_no_confirmation": this.PaymentModel.ACNo,
      "frqcy": this.PaymentModel.CFreq,
      "debit_type": this.PaymentModel.CType == "true" ? true : false,
      "JSON": JSON.stringify(_datajson)

    }
    console.log("this.PaymentModel", requestBode);
    

    this._MasterService.Nupay_Mandate_Registration(requestBode).subscribe((res: any) => {
      if (res.length > 0) {
        console.log("res", res);
        if (res[0].StatusCode == 'NP000') {
          window.open(res[0].URL, "_blank");
          var ENach: any = {
            Loan_Id: this.PaymentModel.Loan_Id,
            NachAmount: this.PaymentModel.CAmt,
            PresentBankInCompany: this.ENachAUBankId,
            CustomerId: this.PaymentModel.CustomerId,
            CreateBy: this.currentUser.userId,
            Frequency: this.PaymentModel.CFreq,
            Status: 'Pending',
            Identifier: res[0].Identifier,
            BankName: 'Nupay'
          };

          let _data = {
            ENACH: ENach
          }
          this._MasterService.ENach_Save_NachInfo({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
            if (res.length > 0) {
              console.log("res", res);
              this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
            }

          });
          //this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].notice, ...this.configSuccess });
        }
      }
      //console.log("res", res);
    });



  }
  EndDateChange() {
    this.PaymentModel.EndDate = moment(this.PaymentModel.EndDate).format('DD/MM/YYYY');
    console.log("PaymentModel.EndDate", this.PaymentModel.EndDate);
  }
  StartDateChange() {
    this.PaymentModel.StartDate = moment(this.PaymentModel.StartDate).format('DD/MM/YYYY');
    console.log("PaymentModel.StartDate", this.PaymentModel.StartDate);
  }
}
