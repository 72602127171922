<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #f="ngForm"
    (keydown.enter)="LoanList()"
    novalidate
    class="row m-0 col-md-12 p-0 formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span>Loan No.</span>
        <input
          name="LoanNo"
          [(ngModel)]="Customer.LoanNo"
          id="LoanNo"
          placeholder="Loan No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Application No.</span>
        <input
          name="ApplicationNo"
          [(ngModel)]="Customer.ApplicationNo"
          id="ApplicationNo"
          placeholder="Application No."
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Customer Name</span>
        <input
          name="CustomerName"
          [(ngModel)]="Customer.CustomerName"
          id="CustomerName"
          placeholder="Customer Name"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Customer Phone</span>
        <input
          name="CustomerPhone"
          [(ngModel)]="Customer.CustomerPhone"
          id="CustomerPhone"
          placeholder="Customer Phone"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>PAN Card</span>
        <input
          name="PANCard"
          [(ngModel)]="Customer.PANCard"
          id="PANCard"
          placeholder="PAN Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Passport No.</span>
        <input
          name="Passport"
          [(ngModel)]="Customer.Passport"
          id="Passport"
          placeholder="Passport No."
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-2">
        <span>Voter ID Card</span>
        <input
          name="VoterID"
          [(ngModel)]="Customer.VoterID"
          id="VoterID"
          placeholder="Voter ID Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Driving License</span>
        <input
          name="DrivingLicence"
          [(ngModel)]="Customer.DrivingLicence"
          id="DL"
          placeholder="Driving License"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Aadhaar Card</span>
        <input
          name="Aadhaar"
          [(ngModel)]="Customer.Aadhaar"
          id="Aadhaar"
          placeholder="Aadhaar Card"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Vehicle No</span>
        <input
          name="VehicleNo"
          [(ngModel)]="Customer.VehicleNo"
          id="VehicleNo"
          placeholder="Vehicle No"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateFrom"
            id="LoanDateFrom"
            class="form-control input-text-css"
            [(ngModel)]="Customer.LoanDateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
      name="LoanDateFrom"
      [(ngModel)]="Customer.LoanDateFrom"
      id="LoanDateFrom"
      placeholder="Date From"
      bsDatepicker
      class="form-control input-text-css"
    /> -->
      </div>
      <div class="col-md-2">
        <span>Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateTo"
            id="LoanDateTo"
            class="form-control input-text-css"
            [(ngModel)]="Customer.LoanDateTo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
      name="LoanDateTo"
      [(ngModel)]="Customer.LoanDateTo"
      id="LoanDateTo"
      placeholder="Date To"
      bsDatepicker
      class="form-control input-text-css"
    /> -->
      </div>
      <div class="col-md-2">
        <span>Ration Card</span>
        <input
          name="DrivingLicence"
          [(ngModel)]="Customer.RationCard"
          id="DL"
          placeholder="Ration Card"
          class="form-control input-text-css"
        />
      </div>

      <div class="col-md-2">
        <span>Electricity Bill</span>
        <input
          name="DrivingLicence"
          [(ngModel)]="Customer.ElectricityBill"
          id="DL"
          placeholder="Electricity Bill"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-2">
        <span>Close Case</span>
        <select
          name="CloseCase"
          id="CloseCase"
          class="form-control input-text-css"
          [(ngModel)]="Customer.CloseCase"
        >
          <option value="">Select Close Case</option>
          <option value="1">Close Case</option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Select Type</span>
        <select
          name="SelectType"
          id="SelectType"
          class="form-control input-text-css"
          [(ngModel)]="SelectType"
          (change)="SearchChange()"
        >
          <option value="">Select Type</option>
          <option value="Branch">Branch</option>
          <option value="CollArea">Collection Area</option>
          <option value="CollSubArea">Collection Sub Area</option>
          <option value="CollExecutive">Collection Executive</option>
          <option value="SalesExecutive">Sales Executive</option>
          <option value="EngineNo">Engine No.</option>
          <option value="ChassisNo">Chassis No.</option>
          <option value="LoanAccCatg">Loan A/C Category</option>

          <option value="SourceType">Source Type</option>
        </select>
      </div>
      <div class="col-md-2">
        <div *ngIf="SelectType == 'Branch'">
          <span> Branch</span>
          <select
            name="BranchId"
            id="BranchId"
            #refSelectGender="ngModel"
            [(ngModel)]="Customer.BranchId"
            class="form-control input-text-css"
          >
            <option value="">Select Branch</option>
            <option
              *ngFor="let branch of BranchesList"
              [value]="branch.BranchId"
            >
              {{ branch.Branch_Name }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'EngineNo'">
          <span>Engine No.</span>
          <input
            name="EngineNo"
            [(ngModel)]="Customer.EngineNo"
            id="EngineNo"
            placeholder="Engine No."
            class="form-control input-text-css"
          />
        </div>

        <div *ngIf="SelectType == 'ChassisNo'">
          <span>Chassis No.</span>
          <input
            name="ChassisNo"
            [(ngModel)]="Customer.ChassisNo"
            id="ChassisNo"
            placeholder="Chassis No."
            class="form-control input-text-css"
          />
        </div>

        <div *ngIf="SelectType == 'CollArea'">
          <span> Collection Area</span>
          <select
            name="Area"
            id="Area"
            [(ngModel)]="Customer.Area"
            class="form-control input-text-css"
          >
            <option value="">Select Collection Area</option>
            <ng-container *ngFor="let area of areaData">
              <option
                *ngIf="area.Type.trim() == 'Collection Area'"
                [value]="area.Int_Id"
              >
                {{ area.Area_Name }}
              </option>
            </ng-container>
          </select>
        </div>

        <div *ngIf="SelectType == 'CollSubArea'">
          <span> Collection Sub Area</span>
          <select
            name="SubArea"
            id="SubArea"
            [(ngModel)]="Customer.SubArea"
            class="form-control input-text-css"
          >
            <option value="">Select Collection SubArea</option>
            <option
              *ngFor="let subarea of subAreaData"
              [value]="subarea.Int_Id"
            >
              {{ subarea.Sub_Area_Name }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'SalesExecutive'">
          <span> Sales Executive</span>
          <select
            name="SalesEx"
            id="SalesEx"
            [(ngModel)]="Customer.SalesEx"
            class="form-control input-text-css"
          >
            <option value="">Select Sales Executive</option>
            <option
              *ngFor="let SalesExec of SalesExecutiveData"
              [value]="SalesExec.EmpId"
            >
              {{ SalesExec.EmpName }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'CollExecutive'">
          <span> Collection Executive</span>
          <select
            name="CollectionEx"
            id="CollectionEx"
            [(ngModel)]="Customer.CollectionEx"
            class="form-control input-text-css"
          >
            <option value="">Select Collection Executive</option>
            <option
              *ngFor="let CollExec of CollectionExecutiveData"
              [value]="CollExec.EmpId"
            >
              {{ CollExec.Emp_FirstName }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'LoanAccCatg'">
          <span>Accounting Category </span>
          <select
            name="LoanAccountingCatId"
            id="LoanAccountingCatId"
            [(ngModel)]="Customer.LoanAccountingCatId"
            class="form-control input-text-css"
          >
            <option value="">Select Accounting Category</option>
            <option
              *ngFor="let AcCat of AccCatDropdown"
              [value]="AcCat.Loan_CategoryId"
            >
              {{ AcCat.Loan_category }}
            </option>
          </select>
        </div>

        <div *ngIf="SelectType == 'SourceType'">
          <span> Source Type </span>
          <select
            name="SourceType"
            id="SourceType"
            #refSourceType="ngModel"
            (change)="Get_PartnerForDropdown()"
            [(ngModel)]="Customer.SourceType"
            class="form-control input-text-css"
          >
            <option value="">Select Source Type</option>
            <option
              *ngFor="let lead of leadSourceDropdown"
              [value]="lead.Lead_Source"
            >
              {{ lead.Lead_Source }}
            </option>
          </select>
        </div>
      </div>

      <ng-container
        *ngIf="
          Customer.SourceType == 'Agent' ||
          Customer.SourceType == 'DSA' ||
          Customer.SourceType == 'Dealer' ||
          Customer.SourceType == 'Sales Executive'
        "
      >
        <div class="col-md-2">
          <span> Source Name</span>
          <select
            name="SourceId"
            id="SourceId"
            #refLeadSourceName="ngModel"
            [(ngModel)]="Customer.SourceId"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>

    <div class="col-md-12 row">
      <div class="col-md-4">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light mt-2"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-4"></div>

      <div class="col-md-4 float-right">
        <button
          type="button"
          (click)="LoanList()"
          class="btn font-size-12 button-btn mt-3 float-right"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-2" *ngIf="dataSourceLoan">
    <mat-table
      [dataSource]="dataSourceLoan"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="LoanId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAcNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Loan No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanAcNo }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Application No</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_No }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="text-transform: capitalize"
        >
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAMount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanAMount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanCreatedOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Date
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanCreatedOn }}
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="Customer_Executive">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Executive</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Customer_Executive}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanCreatedOn">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> Assign On </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.LoanCreatedOn}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Status}} </mat-cell>
          </ng-container> -->

      <ng-container matColumnDef="Assign">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 60px"
          *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToDetailLoan(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        class="sticky-footer"
        *matHeaderRowDef="displayedColumnsLoan; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsLoan"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-4">
        <button class="btn font-size-12 button-btn" (click)="exportTable()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>

  <table
    *ngIf="dataSourceLoan"
    id="lms_modify_booking_list"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Application No
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Customer
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Branch
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Product
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Amount
      </th>
      <th style="background: #4dc3a3 !important; border: 1px solid white">
        Loan Date
      </th>
    </tr>
    <tr *ngFor="let x of FileCheckingList; let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ x.LoanAcNo }}</td>
      <td>{{ x.Application_No }}</td>
      <td>{{ x.Customer }}</td>
      <td>{{ x.Branch }}</td>
      <td>{{ x.Product }}</td>
      <td>{{ x.LoanAMount }}</td>
      <td>{{ x.LoanCreatedOn }}</td>
    </tr>
  </table>
</div>
