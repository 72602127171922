// #region Import Used Components and Services
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../../snackbar/snackbar.component'
import { constantUrl } from 'src/app/Shared/constantUrl';
import {
  GetrSaveresponseModel
} from '../../Shared/Models/app.MasterResponseModel';
// #endregion

@Injectable({
  providedIn: 'root'
})

export class DepositService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(private _Route: Router, private http: HttpClient) {
  }

  // Deposit_Get_Product_Master
  public Deposit_Get_Product_Master(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_Product_Master";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Save_Product_Master
  public Deposit_Save_Product_Master(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Save_Product_Master";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Active_Deactive_Product_Master
  public Deposit_Active_Deactive_Product_Master(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Active_Deactive_Product_Master";
    return this.http.post(apiUrl, requestModel);
  }
  
  // GetCalculateInterest_Maturity
  public GetCalculateInterest_Maturity(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/GetCalculateInterest_Maturity";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_Interest_And_Frequency
  public Deposit_Get_Interest_And_Frequency(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_Interest_And_Frequency";
    return this.http.post(apiUrl, requestModel);
  }
  
  // Deposit_GetFDRNo
  public Deposit_GetFDRNo(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_GetFDRNo";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_FDR_List
  public Deposit_FDR_List(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_FDR_List";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_Interest_By_paymentFrequency
  public Deposit_Get_Interest_By_paymentFrequency(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_Interest_By_paymentFrequency";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Save_FDRD
  public Deposit_Save_FDRD(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Save_FDRD";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Save_Product_Charges
  public Deposit_Save_Product_Charges(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Save_Product_Charges";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_ChargeHeads_ForDropdown
  public Deposit_Get_ChargeHeads_ForDropdown(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_ChargeHeads_ForDropdown";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_Product_Charges
  public Deposit_Get_Product_Charges(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_Product_Charges";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_CustomerBankDetail
  public Deposit_CustomerBankDetail(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_CustomerBankDetail";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_FDR_Detail
  public Deposit_Get_FDR_Detail(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_FDR_Detail";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_FDR_Pending_Authorization
  public Deposit_FDR_Pending_Authorization(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_FDR_Pending_Authorization";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Update_Authorization_FDRD
  public Deposit_Update_Authorization_FDRD(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Update_Authorization_FDRD";
    return this.http.post(apiUrl, requestModel);
  }

  // Deposit_Get_All_Product_By_Type
  public Deposit_Get_All_Product_By_Type(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Deposit/Deposit_Get_All_Product_By_Type";
    return this.http.post(apiUrl, requestModel);
  }

}
