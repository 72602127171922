import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { ReportsService } from "../services/reports.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;

@Component({
  selector: "app-own-managed-funds",
  templateUrl: "./own-managed-funds.component.html",
  styleUrls: ["./own-managed-funds.component.scss"],
})
export class OwnManagedFundsComponent implements OnInit {
  showSpinner: boolean = false;
  private _MasterService;
  ToDate: any = new Date();
  currentUser: any;
  SlabData: any[] = [];
  SlabDropdown: boolean = false;
  ReportType: any = "";
  SlabId: any = "";
  Types: any;
  displayedColumns: string[];
  DataDiv: boolean = false;
  FileCheckingList: any;
  FileCheckingList1: any;
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ArrTypes: Array<{ id: number; value: string }> = [
    { id: 1, value: "Branch Wise Portfolio" },
    { id: 2, value: "Product Wise Portfolio" },
    { id: 3, value: "Amount Wise Portfolio" },
    { id: 4, value: "Tenure Wise Portfolio" },
    { id: 5, value: "IRR Wise Portfolio" },
    { id: 6, value: "LTV Wise Portfolio" },
    { id: 7, value: "Gender Wise Portfolio" },
    { id: 8, value: "Religion Wise Portfolio" },
    { id: 9, value: "Caste Wise Portfolio" },
    { id: 10, value: "Marital Status Wise Portfolio" },
    { id: 11, value: "Profile Wise Portfolio" },
    { id: 12, value: "Nature of Work Wise Portfolio" },
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,
    private snackBar: MatSnackBar
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Own/Managed Funds");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.ArrTypes;
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
        console.log("dtaaaa", this.SlabData);
      });
  }

  onChangeReportTypes() {
    console.log("change Reports Types function call");

    this.SlabDropdown = false;
    this.SlabId = "";
    this.Types = this.ArrTypes.find((obj: any) => {
      return obj.id == this.ReportType;
    }).value;
    switch (this.ReportType) {
      case "3":
        this.SlabDropdown = true;
        this.getSlabsList("Amount");
        break;
      case "4":
        this.SlabDropdown = true;
        this.getSlabsList("Tenure");
        break;
      case "5":
        this.SlabDropdown = true;
        this.getSlabsList("IRR");
        break;
      case "6":
        this.SlabDropdown = true;
        this.getSlabsList("LTV");
        break;
    }
  }

  onSearchOwnManagedFunds() {
    console.log("Search button clicked");

    switch (this.ReportType) {
      case "1":
        this.getReportBranchWisePortfolio();
        break;
      case "2":
        this.getReportProductWisePortfolio();
        break;
      case "3":
        this.getReportAmountWisePortfolio();
        break;
      case "4":
        this.getReportTenureWisePortfolio();
        break;
      case "5":
        this.getReportIRRWisePortfolio();
        break;
      case "6":
        this.getReportLTVWisePortfolio();
        break;
      case "7":
        this.getReportGenderWisePortfolio();
        break;
      case "8":
        this.getReportReligionWisePortfolio();
        break;
      case "9":
        this.getReportCasteWisePortfolio();
        break;
      case "10":
        this.getReportMaritalStatusWisePortfolio();
        break;
      case "11":
        this.getReportProfileWisePortfolio();
        break;
      case "12":
        this.getReportNatureOfWorkWisePortfolio();
        break;
    }
  }

  getReportBranchWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Branch_Name",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_BranchWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Branch");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportProductWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Product_Name",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_ProductWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Product Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Product");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportAmountWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_AmountWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        SlabId: this.SlabId,
      })
      .subscribe((res: any) => {
        console.log("Result of Account Wise Portfolio", res);
        if (res.length > 0) {
          this.getDataforTable(res, "Amount");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportTenureWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_TenureWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        SlabId: this.SlabId,
      })
      .subscribe((res: any) => {
        console.log("Result of Tenure Wise Portfolio", res);
        if (res.length > 0) {
          this.getDataforTable(res, "Tenure");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportIRRWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_IRRWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        SlabId: this.SlabId,
      })
      .subscribe((res: any) => {
        console.log("Result of IRR Wise Portfolio", res);
        if (res.length > 0) {
          this.getDataforTable(res, "IRR");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportLTVWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "SlabName",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_LTVWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
        SlabId: this.SlabId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);
        if (res.length > 0) {
          this.getDataforTable(res, "Branch");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportGenderWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Gender",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_GenderWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Gender");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportReligionWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Religion",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_ReligionWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Religion");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportCasteWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Caste",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_CasteWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Caste");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportMaritalStatusWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "MaritalStatus",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_MaritalStatusWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "MaritalStatus");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportProfileWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Customer_Profile",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_ProfileWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Customer_Profile");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getReportNatureOfWorkWisePortfolio() {
    this.showSpinner = true;
    this.displayedColumns = [
      "Id",
      "Natureofwork",
      "TotalNoOfLoans",
      "NoOfLoansOwn",
      "NoOfLoansPartner",
      "TotalPOS_OS",
      "POS_OSOwn",
      "POS_OSPartner",
    ];
    this.reportsService
      .Report_OwnPartner_NatureofworkWisePortfolio({
        To: this.ToDate,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log("Result of Branch Wise Portfolio", res);

        if (res.length > 0) {
          this.getDataforTable(res, "Natureofwork");
          this.DataDiv = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.DataDiv = false;
        }
        this.showSpinner = false;
      });
  }

  getDataforTable(res, Type) {
    console.log("Type", Type);
    var totalSum = 0;
    res.forEach((x) => {
      totalSum = totalSum + parseFloat(x.POS_OS == null ? 0 : x.POS_OS);
    });
    res.forEach((x) => {
      if (Type == "Branch") {
        x.Name = x.Branch_Name;
      } else if (Type == "Product") {
        x.Name = x.Product_Name;
      } else if (Type == "Gender") {
        x.Name = x.Gender;
      } else if (Type == "Religion") {
        x.Name = x.Religion;
      } else if (Type == "Caste") {
        x.Name = x.Caste;
      } else if (Type == "MaritalStatus") {
        x.Name = x.MaritalStatus;
      } else if (Type == "Customer_Profile") {
        x.Name = x.Customer_Profile;
      } else if (Type == "Natureofwork") {
        x.Name = x.Natureofwork;
      } else {
        x.Name = x.SlabName;
      }
      x.IN_Percentage = (
        (parseFloat(x.POS_OS == null ? 0 : x.POS_OS) / totalSum) *
        100
      ).toFixed(2);
    });
    this.FileCheckingList1 = JSON.parse(JSON.stringify(res));
    console.log("File", this.FileCheckingList1);
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));

    this.dataSource.sort = this.sort;

    this.showSpinner = false;
  }

  totaltypewise(type) {
    if (
      this.dataSource != null &&
      this.dataSource != undefined &&
      this.dataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      switch (type) {
        case "TotalNoOfLoans":
          return sum;
          break;
        case "NoOfLoansOwn":
          return sum;
          break;
        case "NoOfLoansPartner":
          return sum;
          break;
        case "TotalPOS_OS":
          return sum.toFixed(2);
          break;
        case "POS_OSOwn":
          return sum.toFixed(2);
          break;
        case "POS_OSPartner":
          return sum.toFixed(2);
          break;
      }
    }
    return 0;
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "own_managed_funds"
    ) as HTMLTableElement;
    const worksheetName = "OWN/MANAGED FUNDS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
