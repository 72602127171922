<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchNPADetailReport()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            required
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <button
          type="button"
          (click)="onSearchNPADetailReport()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-4 float-right">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light mt-2"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="DataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 50px"
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAcNo }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date | date }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DPD Days</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DPD_Days }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DPD_Month">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DPD Month</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DPD_Month }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Exposure">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Exposure</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{
                ConvertToNumber(row.Principal_OS) +
                  ConvertToNumber(row.Interest_Reversal)
              }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{
                (
                  ConvertToNumber(totaltypewise("Principal_OS")) +
                  ConvertToNumber(totaltypewise("Interest_Reversal"))
                ).toFixed(2)
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Principal_OS">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Principal OS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Principal_OS }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("Principal_OS") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_Reversal">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Interest Reversal</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Interest_Reversal }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Charges_OS">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Charges OS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Charges_OS }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="GrossPortfolio">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Gross Portfolio</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Principal_OS }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("Principal_OS") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Provision_Rate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Provision Rate</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Provision_Rate }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Provision_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Provision Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Provision_Amount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("Provision_Amount") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="NetPortfolio">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Net Portfolio</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Principal_OS - row.Provision_Amount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{
                ConvertToNumber(totaltypewise("Principal_OS")) -
                  ConvertToNumber(totaltypewise("Provision_Amount")).toFixed(2)
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="NPA_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Case Status</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.NPA_Type }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DUE_ON">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DUE ON</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DUE_ON }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalCollection">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collection Total</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TotalCollection }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("TotalCollection") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Collection_Principal">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collection Principal</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Collection_Principal }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("Collection_Principal") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Collection_Interest">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collection Interest</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Collection_Interest }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("Collection_Interest") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product_Name }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Name }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_PhoneNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Phone</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="currentUser.IsMasking">
                {{ row.Customer_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMasking">
                {{ row.Customer_PhoneNo }}
              </div>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Address">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Address }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.LoanAmount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("LoanAmount") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Frequency">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Frequency</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMI_Frequency }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMIAmount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header text-left">
              {{ totaltypewise("EMIAmount") }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Tenure">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Tenure</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Tenure }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalEMI_NOS">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Total EMI NOS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TotalEMI_NOS }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Future_Interst">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Future Interst</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Future_Interst }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Last_Recv_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Last Receipt Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">
              {{ row.Last_Recv_Date }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header text-right"
            ></mat-footer-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="npa_detail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Month
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Exposure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal OS
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Reversal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charge OS
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gross Portfolio
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Rate
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Provision Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Net Portfolio
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DUE ON
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Phone
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total EMI Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Interst
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>

    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.DPD_Month }}</td>
    <td>
      {{
        ConvertToNumber(x.Principal_OS) + ConvertToNumber(x.Interest_Reversal)
      }}
    </td>
    <td>{{ x.Principal_OS }}</td>
    <td>{{ x.Interest_Reversal }}</td>
    <td>{{ x.Charges_OS }}</td>
    <td>{{ x.Principal_OS }}</td>
    <td>{{ x.Provision_Rate }}</td>
    <td>{{ x.Provision_Amount }}</td>
    <td>{{ x.Principal_OS - x.Provision_Amount }}</td>
    <td>{{ x.NPA_Type }}</td>
    <td>{{ x.DUE_ON }}</td>
    <td>{{ x.TotalCollection }}</td>
    <td>{{ x.Collection_Principal }}</td>
    <td>{{ x.Collection_Interest }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>
      <div *ngIf="currentUser.IsMasking">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMasking">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td>{{ x.Address }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.EMI_Frequency }}</td>
    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.Loan_Tenure }}</td>
    <td>{{ x.TotalEMI_NOS }}</td>
    <td>{{ x.Future_Interst }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="6"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{
            (
              ConvertToNumber(totaltypewise("Principal_OS")) +
              ConvertToNumber(totaltypewise("Interest_Reversal"))
            ).toFixed(2)
          }}</strong
        >
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_OS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_OS") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          ConvertToNumber(totaltypewise("Principal_OS")) -
            ConvertToNumber(totaltypewise("Provision_Amount"))
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("TotalCollection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Collection_Principal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Collection_Interest") }} </strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
    </tr>
  </tfoot>
</table>
