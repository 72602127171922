<div>
    <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
    <form #df="ngForm"
          novalidate
          (keydown.enter)="onSearchUnaccoutedVoucher()"
          class="formborder">
        <div class="row p-3"> 
            <div class="col-md-2">
                <div class="datepicker_feild">
                    <div class="">
                        <span>From Date</span>
                        <input [matDatepicker]="picker1"
                            dateConvert
                            placeholder="DD/MM/YYYY"
                            name="FromDate"
                            id="FromDate"
                            #refFromDate="ngModel"
                            class="form-control input-text-css"
                            [(ngModel)]="FromDate"
                            [ngClass]="{
                                'is-invalid': df.submitted && refFromDate.invalid,
                                'alert-warning':
                                refFromDate.invalid &&
                                (refFromDate.dirty ||
                                    refFromDate.touched ||
                                    refFromDate.untouched)
                            }" 
                        />
                        <mat-datepicker-toggle matSuffix
                            [for]="picker1"
                            style="top: 24px"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="datepicker_feild">
                    <div class="">
                        <span>To Date</span>
                        <input [matDatepicker]="picker2"
                            dateConvert
                            placeholder="DD/MM/YYYY"
                            name="ToDate"
                            id="ToDate"
                            #refToDate="ngModel"
                            class="form-control input-text-css"
                            [(ngModel)]="ToDate"
                            [ngClass]="{
                            'is-invalid': df.submitted && refToDate.invalid,
                            'alert-warning':
                                refToDate.invalid &&
                                (refToDate.dirty || refToDate.touched || refToDate.untouched)
                            }" 
                        />
                        <mat-datepicker-toggle matSuffix
                            [for]="picker2"
                            style="top: 24px"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <span>Loan No.</span>
                <input name="LoanNo"
                    [(ngModel)]="LoanNo"
                    id="LoanNo"
                    placeholder="Loan No."
                    class="form-control input-text-css" 
                />
            </div>
            <div class="col-md-2">
                <span> Branch</span>
                <select name="SelectBranch"
                        id="SelectBranch"
                        #refSelectGender="ngModel"
                        [(ngModel)]="SelectBranch"
                        class="form-control input-text-css">
                    <option value="">Select Branch</option>
                    <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
                        {{ branch.Branch_Name }}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
                <span>Product</span>
                <select name="SelectProduct"
                        id="SelectProduct"
                        #refSelectGender="ngModel"
                        [(ngModel)]="SelectProduct"
                        class="form-control input-text-css">
                    <option value="" selected>Select Product</option>
                    <option *ngFor="let product of ProductList"
                            [value]="product.ProductId">
                        {{ product.Product }}
                    </option>
                </select>
            </div>
            <div class="col-md-2 mt-5">
                <div class="toggle-button-cover ">
                    <div class="button-cover">
                        <div class="button r" id="button-1">
                            <input type="checkbox"
                                   class="checkbox"
                                   name="FilterStatusId"
                                   id="FilterStatusId"
                                   [(ngModel)]="IsActive" />
                            <div class="knobs"></div>
                            <div class="layer"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 row m-3 p-0">
                <div class="col-md-2" [hidden]="!dataSource" style="width: 195px; margin-left: -20px; margin-right: 20px;">
                    <form action="" class="search-text">
                        <div class="bg-light rounded rounded-pill shadow-sm">
                            <div class="input-group">
                                <input type="text"
                                    id="FilterInputSearch"
                                    (keyup)="applyFilter($event.target.value)"
                                    name="FilterInputSearch"
                                    placeholder="Type here.."
                                    class="form-control border-0 bg-light" 
                                />
                                <div class="input-group-append">
                                    <button id="button-addon1"
                                            type="submit"
                                            class="btn btn-link text-primary">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row" style="width: 850px; display: flex; justify-content: space-between; align-items: center; margin: 0;">
                    <div class="">
                        <button type="button"
                                (click)="onSearchUnaccoutedVoucher()"
                                class="btn font-size-12 button-btn float-left"
                                [disabled]="!df.form.valid">
                            Search   
                        </button>
                    </div>
                    <div class="">
                        <button type="button"
                                (click)="addUncountedVoucher()"
                                class="btn font-size-12 button-btn float-left"
                                style="width: max-content">
                            Add Unaccouting Voucher
                        </button>
                    </div>
                    <div class="">
                        <button type="button"
                                (click)="ExportTemplate()"
                                class="btn font-size-12 button-btn float-left"
                                style="width: max-content">
                            Export Template
                        </button>
                    </div>
                    <div class="">
                        <button type="button"
                                (click)="ExportChargesHead()"
                                class="btn font-size-12 button-btn float-left"
                                style="width: max-content">
                            Export ChargesHead
                        </button>
                    </div>        
                    <div class="">
                        <span class="button-wrap">
                            <label class="btn font-size-12 button-btn"
                                for="ImportTemplate"> Import Template</label>
                            <input #fileInput
                                type="file"
                                id="ImportTemplate"
                                (change)="ImportTemplate($event)"
                                style="display: none"
                                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="mt-3" [hidden]="!dataSource">
        <div class="">
            <div class="table-responsive table-striped"
                 style="overflow: auto; height: 65vh">
                <table class="table-bordered"
                       matSort
                       mat-table
                       [dataSource]="dataSource"
                       matTableExporter
                       #exporter="matTableExporter">
                    <ng-container matColumnDef="Id">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            {{ i + 1 }}
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="LoanAcNo">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Loan Ac No
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>
                    <ng-container matColumnDef="Product_Name">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Product Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.Product_Name }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>
                    <ng-container matColumnDef="Branch_Name">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Branch Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>
                    <ng-container matColumnDef="CreatedBy">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Created By
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.CreatedBy }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>
                    <ng-container matColumnDef="deletedBy">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Deleted By
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.deletedBy }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="CustomerName">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Customer Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="voucher_Date">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Voucher Date
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.voucher_Date }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="ChagesHead">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Charges Head
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.ChagesHead }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>
                    <ng-container matColumnDef="Amount">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.Amount }}
                        </td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="TransType">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>
                            Trans Type
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.TransType }}</td>
                        <th mat-footer-cell *matFooterCellDef></th>
                    </ng-container>

                    <ng-container matColumnDef="Narration">
                        <th mat-sort-header mat-header-cell *matHeaderCellDef>Narration</th>
                        <td mat-cell *matCellDef="let row">{{ row.Narration }}</td>
                        <th mat-footer-cell *matFooterCellDef class="text-center"></th>
                    </ng-container>
                    <ng-container matColumnDef="Delete">
                        <mat-header-cell mat-sort-header
                                         class="grid-header"
                                         style="max-width: 100px"
                                         *matHeaderCellDef>
                            Delete
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row"
                                  class="grid-cell"
                                  style="max-width: 100px">
                            <i class="fa fa-minus-circle font-size-12"
                               (click)="onDeleteUnAccountVoucher(row)"
                               class="fa fa-trash style-delete"
                               aria-label="true"></i>
                        </mat-cell>
                    </ng-container>
                    <tr mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                        class="sticky-footer fontcolor"
                        style="background: #4dc3a3"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <!-- (click)="highlightRow(row)"
                    [class.highlight]="row.LoanId == selectedId" -->

                    <tr mat-footer-row
                        class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumns; sticky: true"
                        style="background: #4dc3a3"></tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row m-0 ml-3" [hidden]="!dataSource">
        <div class="col-md-4 mt-3">
            <button class="mt-3 btn font-size-12 button-btn" (click)="exportexcel()">
                <i class="fa fa-file-export mr-1"
                   style="font-size: medium; cursor: pointer"></i>
                Export
            </button>
        </div>
        <div class="col-md-8 mt-3">
            <mat-paginator #paginatorRef
                           [pageSizeOptions]="[20, 50]"
                           showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

<table id="export_unaccouting_voucher"
       class="table table-bordered bg-white"
       style="font-family: 'Times New Roman', Times, serif; display: none">
    <tr>
        <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            LoanAcNo
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Product Name
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Branch Name
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Customer Name
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            voucher Date
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Created By
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Deleted By
        </th>

        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Charges Head
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Amount
        </th>

        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Trans Type
        </th>
        <th style="background: #4dc3a3 !important; border: 1px solid white">
            Narration
        </th>
    </tr>
    <tr *ngFor="let x of JsonData; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ x.LoanAcNo }}</td>
        <td>{{ x.Product_Name }}</td>
        <td>{{ x.Branch_Name }}</td>

        <td>{{ x.CustomerName }}</td>
        <td>{{ x.voucher_Date }}</td>
        <td>{{ x.CreatedBy }}</td>
        <td>{{ x.deletedBy }}</td>



        <td>{{ x.ChagesHead }}</td>
        <td>{{ x.Amount }}</td>

        <td>{{ x.TransType }}</td>
        <td>{{ x.Narration }}</td>
    </tr>
</table>



<div class="modal fade in"
     id="AddunaccountingVoucher"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
            <div cdkDragHandle class="modal-header" style="padding: 10px">
                <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
                    Add Unaccouting Voucher
                </h6>
                <button type="button"
                        class="close"
                        (click)="OnCloseSearch()"
                        aria-label="Close">
                    <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="main-container"
                     style="padding: 0px !important; margin: 0px !important">
                    <form #f="ngForm" novalidate class="formborder">
                        <div class="row m-0 mb-2">
                            <div class="col-md-2">
                                <span class="required-lable">Voucher Date</span>
                                <div class="datepicker_feild">
                                    <!-- [min]="CheckDate"
                                    [max]="Days_AllowedDateMax" -->
                                    <input [matDatepicker]="picker3"
                                           dateConvert
                                           placeholder="DD/MM/YYYY"
                                           name="Voucher_Date"
                                           id="Voucher_Date"
                                           #refVoucher_Date="ngModel"
                                           [min]="selectedData.Application_CreateOn"
                                           [(ngModel)]="Voucher_Date"
                                           required
                                           [ngClass]="{
                      'is-invalid': f.submitted && refVoucher_Date.invalid,
                      'alert-warning':
                        refVoucher_Date.invalid &&
                        (refVoucher_Date.dirty ||
                          refVoucher_Date.touched ||
                          refVoucher_Date.untouched)
                    }"
                                           class="form-control input-text-css" />
                                    <!-- (dateChange)="onVoucherDateChange()" -->
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0">
                            <div class="col-md-12"><hr /></div>
                        </div>

                        <form #df="ngForm" novalidate>
                            <div class="row m-0 add-remove"
                                 *ngFor="
                  let vdData of voucherModel.Voucher_Detail;
                  let i = index
                ">
                                <div class="col-md-12 row m-0 type-column">
                                    <div class="type mr_top">
                                        <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                                            <span class="required-lable">Type</span>
                                        </div>
                                        <select name="TranType{{ i }}"
                                                id="TranType{{ i }}"
                                                required
                                                #refTranType="ngModel"
                                                [ngClass]="{
                        'is-invalid': df.submitted && refTranType.invalid,
                        'alert-warning':
                          refTranType.invalid &&
                          (refTranType.dirty ||
                            refTranType.touched ||
                            refTranType.untouched),
                        'mt-1': i == 0
                      }"
                                                [(ngModel)]="vdData.TranType"
                                                class="form-control pl-1 input-text-css">
                                            <!-- (change)="onChangeCRDR(vdData)" -->
                                            <option [value]="'DR'">DR</option>
                                            <option [value]="'CR'">CR</option>
                                        </select>
                                    </div>

                                    <div class="row m-0 feild">
                                        <ng-container>
                                            <div class="col-md-2 mr_top pr-0">
                                                <div [ngClass]="{ 'bg-success1': i == 0 }"
                                                     *ngIf="i == 0">
                                                    <span class="">Search</span>
                                                </div>
                                                <div class="input-btn">
                                                    <input type="text"
                                                           readonly
                                                           name="Loan_Id{{ i }}"
                                                           id="Loan_Id{{ i }}"
                                                           [(ngModel)]="vdData.Loan_Id"
                                                           class="form-control input-text-css"
                                                           (keyup.enter)="searchCustomer(vdData, i)"
                                                           [ngClass]="{ 'mt-1': i == 0 }" />
                                                    <button type="button"
                                                            (click)="searchCustomer(vdData, i)"
                                                            class="primary-btn ml-2 search">
                                                        <i class="fa fa-search"
                                                           aria-hidden="true"
                                                           style="font-size: 11px"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-md-3 mr_top pr-0">
                                                <div [ngClass]="{ 'bg-success1': i == 0 }"
                                                     *ngIf="i == 0">
                                                    Customer
                                                </div>
                                                <input type="text"
                                                       name="Customer{{ i }}"
                                                       id="Customer{{ i }}"
                                                       class="form-control input-text-css"
                                                       readonly
                                                       [(ngModel)]="vdData.Customer"
                                                       [ngClass]="{ 'mt-1': i == 0 }" />
                                            </div>

                                            <div class="col-md-2 mr_top pr-0">
                                                <div [ngClass]="{ 'bg-success1': i == 0 }"
                                                     *ngIf="i == 0">
                                                    <span class=""
                                                          [ngClass]="{
                              'required-lable': vdData.IsLoanChargeable
                            }">Category</span>
                                                </div>
                                                <select name="ChargeHeadId{{ i }}"
                                                        id="ChargeHeadId{{ i }}"
                                                        required
                                                        #refChargeHeadId="ngModel"
                                                        [ngClass]="{
                            'is-invalid':
                              df.submitted && refChargeHeadId.invalid,
                            'alert-warning':
                              refChargeHeadId.invalid &&
                              (refChargeHeadId.dirty ||
                                refChargeHeadId.touched ||
                                refChargeHeadId.untouched),
                            'mt-1': i == 0
                          }"
                                                        [(ngModel)]="vdData.ChargeHeadId"
                                                        class="form-control input-text-css"
                                                        [disabled]="!vdData.ProductId"
                                                        (change)="onChangeChargesHead(vdData, i)">
                                                    <!--  -->
                                                    <option value="">Select Category</option>

                                                    <option *ngFor="let chargeHead of chargesHeadDropdown"
                                                            [value]="chargeHead.Id">
                                                        {{ chargeHead.ChagesHead }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-2 mr_top pr-0">
                                                <div [ngClass]="{ 'bg-success1': i == 0 }"
                                                     *ngIf="i == 0">
                                                    <span class="required-lable">Account Head</span>
                                                </div>
                                                <!-- (keyup)="onChangeAccountHead(vdData, i)"
                                                (onchange)="onChangeAccountHead(vdData, i)" -->
                                                <input #refChargeHeadCategory="ngModel"
                                                       type="text"
                                                       name="ChargeHeadCategory{{ i }}"
                                                       id="ChargeHeadCategory{{ i }}"
                                                       class="form-control input-text-css"
                                                       readonly
                                                       required
                                                       [(ngModel)]="vdData.ChargeHeadCategory"
                                                       [ngClass]="{
                            'is-invalid':
                              df.submitted && refChargeHeadCategory.invalid,
                            'alert-warning':
                              refChargeHeadCategory.invalid &&
                              (refChargeHeadCategory.dirty ||
                                refChargeHeadCategory.touched ||
                                refChargeHeadCategory.untouched),
                            'mt-1': i == 0
                          }" />
                                            </div>

                                            <div class="col-md-2 mr_top pr-0 pl-1">
                                                <div [ngClass]="{ 'bg-success1': i == 0 }"
                                                     *ngIf="i == 0">
                                                    <span class=""
                                                          [ngClass]="{
                              'required-lable': vdData.TranType == 'CR'
                            }">Amount</span>
                                                </div>

                                                <!-- (change)="onChangeAmount($event, vdData, i)"
                                                (keyup.enter)="onChangeAmount($event, vdData, i)" -->
                                                <input (change)="onChangeAmount($event, vdData, i)"
                                                       (keyup.enter)="onChangeAmount($event, vdData, i)"
                                                       type="text"
                                                       required
                                                       min="0"
                                                       name="Amount{{ i }}"
                                                       id="Amount{{ i }}"
                                                       appTwoDigitDecimaNumber
                                                       [(ngModel)]="vdData.Amount"
                                                       class="form-control input-text-css text-right pr-3"
                                                       [disabled]="!vdData.ChargeHeadCategory"
                                                       #refAmount="ngModel"
                                                       [ngClass]="{
                            'is-invalid': df.submitted && refAmount.invalid,
                            'alert-warning':
                              refAmount.invalid &&
                              (refAmount.dirty ||
                                refAmount.touched ||
                                refAmount.untouched),
                            'mt-1': i == 0
                          }" />

                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                                <ng-container *ngIf="vdData.TaxSlab1 && vdData.TaxSlab1.length > 0">
                                    <div class="col-md-12 row m-0 p-0 mt-2"
                                         *ngFor="let taxData of vdData.TaxSlab1; let ti = index">
                                        <div class="col-md-12 row m-0 type-column">
                                            <div class="type mr_top">
                                                <span class="form-control1" style="width: 44px">
                                                    {{
                                                    vdData.TranType
                                                    }}
                                                </span>
                                            </div>
                                            <div class="row m-0 feild">
                                                <div class="col-md-5 mr_top pr-0">
                                                    <span class="form-control1">{{ taxData.TaxSlab_Type }}</span>
                                                </div>

                                                <div class="col-md-2 mr_top pr-0">
                                                    <span class="form-control1">
                                                        {{
                                                        taxData.TypeIGST == true
                                                        ? "IGST"
                                                        : taxData.TypeGST == true
                                                        ? "SGST"
                                                        : "CGST"
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2 text-right mr_top pr-0">

                                                    <span class="form-control1">
                                                        {{ taxData.TaxSlab_Rate }}%
                                                    </span>
                                                </div>

                                                <div class="col-md-2 mr_top pr-0 pl-1">
                                                    <input type="text"
                                                           required
                                                           name="TaxAmount{{ i }}{{ ti }}"
                                                           id="TaxAmount{{ i }}{{ ti }}"
                                                           appTwoDigitDecimaNumber
                                                           [(ngModel)]="taxData.TaxAmount"
                                                           class="form-control input-text-css text-right pr-3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <!--  <button type="button"
                                         class="remove"
                                         (click)="removeData(i)"
                                         [ngClass]="{ 'no-head': i != 0 }"
                                         *ngIf="voucherModel.Voucher_Detail.length > 1">
                                   -
                                 </button>
                                 <button type="button"
                                         class="addmore"
                                         (click)="addMoreData(i)"
                                         [ngClass]="{
                                         both: voucherModel.Voucher_Detail.length>
                                   1,
                                   'no-head': i != 0
                                   }"
                                   [disabled]="!df.form.valid"
                                   *ngIf="i == voucherModel.Voucher_Detail.length - 1"
                                   [disabled]="!vdData.Amount"
                                   >
                                   +
                                 </button> -->
                            </div>
                        </form>

                        <div class="row m-0 mt-2">
                            <div class="col-md-12">
                                <span class="">Voucher Narration</span>
                                <input type="text"
                                       #refVoucher_Narration="ngModel"
                                       [ngClass]="{
        'is-invalid': f.submitted && refVoucher_Narration.invalid,
        'alert-warning':
          refVoucher_Narration.invalid &&
          (refVoucher_Narration.dirty ||
            refVoucher_Narration.touched ||
            refVoucher_Narration.untouched)
      }"
                                       [(ngModel)]="voucherModel.Voucher.Voucher_Narration"
                                       name="Voucher_Narration"
                                       id="Voucher_Narration"
                                       class="form-control input-text-css" />
                            </div>
                        </div>
                        <div class="row m-0 mt-2 upload">

                            <div class="col-md-2 text-right">
                                <button type="button"
                                        class="ml-4 mt-3 btn font-size-12 button-btn"
                                        (click)="saveVoucher()"
                                        [class.spinner]="loading"
                                        [disabled]="!f.form.valid || !df.form.valid">
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade in"
     id="CustomerReceipt"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
            <div cdkDragHandle class="modal-header" style="padding: 10px">
                <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
                    Search
                </h6>
                <button type="button"
                        class="close"
                        (click)="OnCloseSearchModal()"
                        aria-label="Close">
                    <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row m-0">
                    <form #f="ngForm"
                          (keydown.enter)="Get_Acc_Search_LoanNo()"
                          novalidate
                          class="row m-0 col-md-12 p-0 formborder">
                        <div class="row m-0 col-md-12 p-0">
                            <div class="col-md-2">
                                <span>Application No.</span>
                                <input name="SApplicationNo"
                                       [(ngModel)]="Customer.ApplicationNo"
                                       id="SApplicationNo"
                                       placeholder="Application No."
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Customer Loan No.</span>
                                <input name="SLoanNo"
                                       [(ngModel)]="Customer.LoanNo"
                                       id="SLoanNo"
                                       placeholder="Loan No."
                                       class="form-control input-text-css" />
                            </div>

                            <div class="col-md-2">
                                <span>Customer Name</span>
                                <input name="CustomerName"
                                       [(ngModel)]="Customer.CustomerName"
                                       id="CustomerName"
                                       placeholder="Customer Name"
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Customer Phone</span>
                                <input name="CustomerPhone"
                                       [(ngModel)]="Customer.CustomerPhone"
                                       id="CustomerPhone"
                                       placeholder="Customer Phone"
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>PAN Card</span>
                                <input name="PANCard"
                                       [(ngModel)]="Customer.PANCard"
                                       id="PANCard"
                                       placeholder="PAN Card"
                                       class="form-control input-text-css" />
                            </div>

                            <div class="col-md-2">
                                <span>Passport No.</span>
                                <input name="Passport"
                                       [(ngModel)]="Customer.Passport"
                                       id="Passport"
                                       placeholder="Passport No."
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Voter ID Card</span>
                                <input name="VoterID"
                                       [(ngModel)]="Customer.VoterID"
                                       id="VoterID"
                                       placeholder="Voter ID Card"
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Driving License</span>
                                <input name="DL"
                                       [(ngModel)]="Customer.DL"
                                       id="DL"
                                       placeholder="Driving License"
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Aadhaar Card</span>
                                <input name="Aadhaar"
                                       [(ngModel)]="Customer.Aadhaar"
                                       id="Aadhaar"
                                       placeholder="Aadhaar Card"
                                       class="form-control input-text-css" />
                            </div>
                            <div class="col-md-2">
                                <span>Vehicle No</span>
                                <input name="VehicleNo"
                                       [(ngModel)]="Customer.VehicleNo"
                                       id="VehicleNo"
                                       placeholder="Vehicle No"
                                       class="form-control input-text-css" />
                            </div>
                            <!--<div class="col-md-2">
                              <span>Close Case</span>
                              <select name="CloseCase" id="CloseCase" class="form-control input-text-css" [(ngModel)]="Customer.CloseCase">
                                <option value="">Select Close Case</option>
                                <option value="1">Close Case</option>
                              </select>
                            </div>-->

                            <div class="col-md-2">
                                <span>Date From</span>
                                <div class="datepicker_feild">
                                    <input [matDatepicker]="picker11"
                                           dateConvert
                                           placeholder="DD/MM/YYYY"
                                           name="LoanDateFrom"
                                           [(ngModel)]="Customer.LoanDateFrom"
                                           id="LoanDateFrom"
                                           class="form-control input-text-css" />
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="picker11"></mat-datepicker-toggle>
                                    <mat-datepicker #picker11></mat-datepicker>
                                </div>
                                <!-- <input
                                  name="LoanDateFrom"
                                  [(ngModel)]="Customer.LoanDateFrom"
                                  id="LoanDateFrom"
                                  placeholder="Date From"
                                  bsDatepicker
                                  class="form-control input-text-css"
                                /> -->
                            </div>
                            <div class="col-md-2">
                                <span>Date To</span>
                                <div class="datepicker_feild">
                                    <input [matDatepicker]="picker2"
                                           dateConvert
                                           placeholder="DD/MM/YYYY"
                                           name="LoanDateTo"
                                           [(ngModel)]="Customer.LoanDateTo"
                                           id="LoanDateTo"
                                           class="form-control input-text-css" />
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <span>Select Type</span>
                                <select name="SelectType"
                                        id="SelectType"
                                        class="form-control input-text-css"
                                        [(ngModel)]="SelectType"
                                        (change)="SearchChange()">
                                    <option value="">Select Type</option>
                                    <option value="Branch">Branch</option>
                                    <option value="CollArea">Collection Area</option>
                                    <option value="CollSubArea">Collection Sub Area</option>
                                    <option value="CollExecutive">Collection Executive</option>
                                    <option value="SalesExecutive">Sales Executive</option>
                                    <option value="EngineNo">Engine No.</option>
                                    <option value="ChassisNo">Chassis No.</option>
                                    <option value="LoanAccCatg">Loan A/C Category</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <div *ngIf="SelectType == 'Branch'">
                                    <span> Branch</span>
                                    <select name="BranchId"
                                            id="BranchId"
                                            #refSelectGender="ngModel"
                                            [(ngModel)]="Customer.BranchId"
                                            class="form-control input-text-css">
                                        <option value="">Select Branch</option>
                                        <option *ngFor="let branch of BranchesList"
                                                [value]="branch.BranchId">
                                            {{ branch.Branch_Name }}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="SelectType == 'EngineNo'">
                                    <span>Engine No.</span>
                                    <input name="EngineNo"
                                           [(ngModel)]="Customer.EngineNo"
                                           id="EngineNo"
                                           placeholder="Engine No."
                                           class="form-control input-text-css" />
                                </div>

                                <div *ngIf="SelectType == 'ChassisNo'">
                                    <span>Chassis No.</span>
                                    <input name="ChassisNo"
                                           [(ngModel)]="Customer.ChassisNo"
                                           id="ChassisNo"
                                           placeholder="Chassis No."
                                           class="form-control input-text-css" />
                                </div>

                                <div *ngIf="SelectType == 'CollArea'">
                                    <span> Collection Area</span>
                                    <select name="Area"
                                            id="Area"
                                            [(ngModel)]="Customer.Area"
                                            class="form-control input-text-css">
                                        <option value="">Select Collection Area</option>
                                        <ng-container *ngFor="let area of areaData">
                                            <option *ngIf="area.Type.trim() == 'Collection Area'"
                                                    [value]="area.Int_Id">
                                                {{ area.Area_Name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div *ngIf="SelectType == 'CollSubArea'">
                                    <span> Collection Sub Area</span>
                                    <select name="SubArea"
                                            id="SubArea"
                                            [(ngModel)]="Customer.SubArea"
                                            class="form-control input-text-css">
                                        <option value="">Select Collection SubArea</option>
                                        <option *ngFor="let subarea of subAreaData"
                                                [value]="subarea.Int_Id">
                                            {{ subarea.Sub_Area_Name }}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="SelectType == 'SalesExecutive'">
                                    <span> Sales Executive</span>
                                    <select name="SalesEx"
                                            id="SalesEx"
                                            [(ngModel)]="Customer.SalesEx"
                                            class="form-control input-text-css">
                                        <option value="">Select Sales Executive</option>
                                        <option *ngFor="let SalesExec of SalesExecutiveData"
                                                [value]="SalesExec.EmpId">
                                            {{ SalesExec.EmpName }}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="SelectType == 'CollExecutive'">
                                    <span> Collection Executive</span>
                                    <select name="CollectionEx"
                                            id="CollectionEx"
                                            [(ngModel)]="Customer.CollectionEx"
                                            class="form-control input-text-css">
                                        <option value="">Select Collection Executive</option>
                                        <option *ngFor="let CollExec of CollectionExecutiveData"
                                                [value]="CollExec.EmpId">
                                            {{ CollExec.Emp_FirstName }}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="SelectType == 'LoanAccCatg'">
                                    <span>Accounting Category </span>
                                    <select name="LoanAccountingCatId"
                                            id="LoanAccountingCatId"
                                            [(ngModel)]="Customer.LoanAccountingCatId"
                                            class="form-control input-text-css">
                                        <option value="">Select Accounting Category</option>
                                        <option *ngFor="let AcCat of AccCatDropdown"
                                                [value]="AcCat.Loan_CategoryId">
                                            {{ AcCat.Loan_category }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 text-right">
                                <button type="button"
                                        (click)="Get_Acc_Search_LoanNo()"
                                        class="mt-3 font-size-12 button-btn">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="col-md-12"><hr /></div>

                    <!-- <form #sf="ngForm" class="col-md-12" novalidate> -->
                    <div class="mt-3" style="width: 100% !important">
                        <div class="table-responsive table-striped"
                             [hidden]="!dataSourceModal">
                            <mat-table [dataSource]="dataSourceModal"
                                       #sortList="matSort"
                                       matSort
                                       id="exportReport"
                                       style="width: 100% !important; overflow: auto">
                                <ng-container matColumnDef="CustomerId">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw50"
                                                     *matHeaderCellDef>#</mat-header-cell>
                                    <mat-cell *matCellDef="let row; let i = index"
                                              class="grid-cell mw50">
                                        {{
                                        paginatorReff.pageIndex * paginatorReff.pageSize + (i + 1)
                                        }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Type">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw50"
                                                     *matHeaderCellDef>Type</mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell mw50">
                                        {{
                                        row.Type
                                        }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Application_No">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw100"
                                                     *matHeaderCellDef>Aplication No</mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell mw100">
                                        {{ row.Application_No }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Customer_LoanAcNo">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header"
                                                     *matHeaderCellDef>
                                        Loan No
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell">
                                        {{
                                        row.Customer_LoanAcNo
                                        }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="LoanAcNo">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header"
                                                     *matHeaderCellDef>
                                        Loan No
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell">
                                        {{
                                        row.LoanAcNo
                                        }}
                                    </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="CustomertName">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header"
                                                     *matHeaderCellDef>
                                        Customer
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell">
                                        {{ row.CustomertName }}({{
                                        row.AC_CustomerType
                                        }})
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="FatherName">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header"
                                                     *matHeaderCellDef>Relation Name</mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell">
                                        {{
                                        row.FatherName
                                        }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Customer_Gender">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw50"
                                                     *matHeaderCellDef>Gender</mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell mw50">
                                        {{
                                        row.Customer_Gender
                                        }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="GenderAge">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw50"
                                                     *matHeaderCellDef>
                                        Age
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell mw50">
                                        {{
                                        row.GenderAge
                                        }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="PhoneNo">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header"
                                                     *matHeaderCellDef>
                                        Phone No
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" class="grid-cell">
                                        {{ row.PhoneNo }}
                                        <i *ngIf="row.Customer_PhoneNo_IsVerified == true"
                                           class="fa fa-check-circle ml-2"
                                           style="color: green"></i>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="EditAction">
                                    <mat-header-cell mat-sort-header
                                                     class="grid-header mw100"
                                                     *matHeaderCellDef>
                                        View Detail
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row"
                                              class="grid-cell j-c-center mw100">
                                        <i class="fa fa-arrow-circle-right btn_detail"
                                           (click)="selectSearchData(row)"
                                           aria-label="true"></i>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumnsModal"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsModal"></mat-row>
                            </mat-table>
                            <mat-paginator #paginatorReff
                                           #PaginatorList
                                           [pageSizeOptions]="[20, 50]"
                                           showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    <!-- </form> -->
                    <div class="row col-md-12 m-0 mt-3 justify-content-end">
                        <button type="button"
                                class="mt-2 font-size-12 button-btn"
                                (click)="OnCloseSearchModal()">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade in"
     id="GSTModalWindow"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
            <div cdkDragHandle class="modal-header" style="padding: 10px">
                <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
                    Party Detail
                </h6>
                <button type="button"
                        class="close"
                        (click)="OnCloseGSTModal()"
                        aria-label="Close">
                    <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                    </span>
                </button>
            </div>
            <form #cf="ngForm" novalidate>
                <div class="modal-body">
                    <div class="row m-0 mt-1">
                        <div class="col-md-4">
                            <span class="form-check-inline">
                                <label class="form-check-label" for="Existing Party">
                                    <input type="radio"
                                           class="form-check-input"
                                           id="PartyType"
                                           name="PartyType"
                                           [(ngModel)]="GSTModel.PartyType"
                                           required
                                           value="Existing_Party"
                                           (change)="onPartyTypeChange()" />
                                    Existing Party
                                </label>
                            </span>
                        </div>
                        <div class="col-md-8">
                            <span class="form-check-inline">
                                <label class="form-check-label" for="New Party">
                                    <input type="radio"
                                           class="form-check-input"
                                           id="PartyType"
                                           name="PartyType"
                                           [(ngModel)]="GSTModel.PartyType"
                                           required
                                           value="New_Party"
                                           (change)="onPartyTypeChange()" />
                                    New Party
                                </label>
                            </span>
                        </div>
                    </div>

                    <div class="row m-0 mt-2">
                        <div class="col-md-3">
                            <span class="required-lable">Tax Slab</span>
                        </div>
                        <div class="col-md-9">
                            <select name="Slab_Tax"
                                    id="Slab_Tax"
                                    #refSlab_Tax="ngModel"
                                    class="form-control input-text-css"
                                    required
                                    [ngClass]="{
                  'is-invalid': cf.submitted && refSlab_Tax.invalid,
                  'alert-warning':
                    refSlab_Tax.invalid &&
                    (refSlab_Tax.dirty ||
                      refSlab_Tax.touched ||
                      refSlab_Tax.untouched)
                }"
                                    [(ngModel)]="GSTModel.Slab_Tax">
                                <option value="">Select Slab</option>
                                <option [value]="slab.TaxSlab_Name"
                                        *ngFor="let slab of slabList">
                                    {{ slab.TaxSlab_Name }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="row m-0 mt-2"
                         *ngIf="
              GSTModel.PartyType == 'New_Party' ||
              (GSTModel.PartyType == 'Existing_Party' )
            ">
                        <div class="col-md-3"><span class="required-lable">Name</span></div>
                        <div class="col-md-9">
                            <input type="text"
                                   #refCustomer="ngModel"
                                   class="form-control input-text-css"
                                   placeholder="Name"
                                   required
                                   [ngClass]="{
                  'is-invalid': cf.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
                                   [(ngModel)]="GSTModel.Customer"
                                   name="Customer"
                                   id="Customer"
                                   [disabled]="GSTModel.PartyType == 'Existing_Party'" />
                        </div>
                    </div>
                    <div class="row m-0 mt-2">
                        <div class="col-md-3"><span>GST No.</span></div>
                        <div class="col-md-9">
                            <input type="text"
                                   #refAcc_GSTNo="ngModel"
                                   class="form-control input-text-css"
                                   placeholder="GST No."
                                   [(ngModel)]="GSTModel.Acc_GSTNo"
                                   name="Acc_GSTNo"
                                   id="Acc_GSTNo" />

                        </div>
                    </div>
                    <div class="row m-0 mt-2">
                        <div class="col-md-3"><span>State</span></div>
                        <div class="col-md-9">
                            <select name="State"
                                    id="State"
                                    #refState="ngModel"
                                    class="form-control input-text-css"
                                    [(ngModel)]="GSTModel.StateId"
                                    [disabled]="GSTModel.PartyType == 'Existing_Party'">
                                <option value="">Select State</option>
                                <option *ngFor="let state of stateDropdown"
                                        [value]="state.StateId">
                                    {{ state.State_Name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row m-0 mt-2">
                        <div class="col-md-3"><span>HSN Code</span></div>
                        <div class="col-md-9">
                            <input name="HSNCode"
                                   id="HSNCode"
                                   [(ngModel)]="GSTModel.HSNCode"
                                   placeholder="HSN Code"
                                   class="form-control input-text-css" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="mt-3 btn font-size-12 button-btn"
                            (click)="onCloseImport1Model()">
                        Cancel
                    </button>
                    <button type="button"
                            class="mt-3 btn font-size-12 button-btn"
                            (click)="saveGSTDetails()"
                            [class.spinner]="loading"
                            [disabled]="!cf.form.valid">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade in"
     id="ImportUnAccountVoucherModel"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
            <div cdkDragHandle class="modal-header" style="padding: 10px">
                <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
                    Data
                </h6>
                <button type="button"
                        class="close"
                        (click)="OnCloseImportUnAccountVoucherModel()"
                        aria-label="Close">
                    <span aria-hidden="true">
                        <i class="far text-black fa-times-circle"></i>
                    </span>
                </button>
            </div>
            <div class="modal-body">

                <h6 *ngIf="dataSourceBlankDataFilldata != null">
                    Please Fill Blank Data
                </h6>
                <div class="table-responsive"
                     *ngIf="dataSourceBlankDataFilldata != null">
                    <mat-table [dataSource]="dataSourceBlankDataFilldata"
                               matSort
                               matTableExporter
                               #exporterTable="matTableExporter">
                        <ng-container matColumnDef="LoanNo">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>LoanNo</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.LoanNo == null || row.LoanNo == '' ? 'pending-danger' : ''
                "
                                      class="grid-cell">
                                {{ row.LoanNo }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ChargesHeadId">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>ChargesHeadId</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.ChargesHeadId == null || row.ChargesHeadId == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.ChargesHeadId }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Voucher_Day">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>Voucher_Day</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Voucher_Day == null || row.Voucher_Day == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Voucher_Day }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Voucher_Month">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>Voucher_Month</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Voucher_Month == null || row.Voucher_Month == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Voucher_Month }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Voucher_Year">
                            <mat-header-cell mat-sort-header
                                             class="grid-header 0"
                                             *matHeaderCellDef>Voucher_Year</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Voucher_Year == null || row.Voucher_Year == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Voucher_Year }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Amount">
                            <mat-header-cell mat-sort-header
                                             class="grid-header 0"
                                             *matHeaderCellDef>Amount</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Amount == null || row.Amount == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Amount }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Narration">
                            <mat-header-cell mat-sort-header
                                             class="grid-header 0"
                                             *matHeaderCellDef>Narration</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Narration == null || row.Narration == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Narration }}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayImportData"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayImportData"></mat-row>
                    </mat-table>
                </div>



                <h6 *ngIf="
            dataSourceNotValiddata != null &&
            (InValidDataInDataBase == 0 || InValidDataInDataBase == 1)
          ">
                    Please Fill Valid Data
                </h6>
                <h6 *ngIf="dataSourceNotValiddata != null && InValidDataInDataBase == 2">
                    Correct Data
                </h6>

                <div class="table-responsive" *ngIf="dataSourceNotValiddata != null">
                    <mat-table [dataSource]="dataSourceNotValiddata"
                               matSort
                               matTableExporter
                               #exporterTable="matTableExporter">
                        <ng-container matColumnDef="LoanNo">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>LoanNo</mat-header-cell>
                            <mat-cell *matCellDef="let row" [ngClass]=" row.LoanNo == null || row.LoanNo == ''? 'pending-danger'  : ''" class="grid-cell">
                                {{ row.LoanNo }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ChargesHeadId">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>ChargesHeadId</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.ChargesHeadId == null || row.ChargesHeadId == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.ChargesHeadId }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Voucher_Date">
                            <mat-header-cell mat-sort-header
                                             class="grid-header 0"
                                             *matHeaderCellDef>Voucher_Date</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]="
                  row.Voucher_Date == null || row.Voucher_Date == ''
                    ? 'pending-danger'
                    : ''
                "
                                      class="grid-cell">
                                {{ row.Voucher_Date }}
                            </mat-cell>
                        </ng-container>
                            <ng-container matColumnDef="Voucher_Year">
                                <mat-header-cell mat-sort-header
                                                 class="grid-header 0"
                                                 *matHeaderCellDef>Voucher_Year</mat-header-cell>
                                <mat-cell *matCellDef="let row"
                                          [ngClass]="{
                    'pending-danger':
                      !NumberValidation(row.Voucher_Year, 4, null) ||
                      (!isGreaterThan(row.Voucher_Year, null,'Year') &&
                        row.Voucher_Year != '' &&
                        row.Voucher_Year != null)
                  }"
                                          class="grid-cell">
                                    {{ row.Voucher_Year }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Voucher_Month">
                                <mat-header-cell mat-sort-header
                                                 class="grid-header 0"
                                                 *matHeaderCellDef>Voucher_Month</mat-header-cell>
                                <mat-cell *matCellDef="let row"
                                          [ngClass]="{
                    'pending-danger':
                      !isGreaterThan(row.Voucher_Month, null,'Month') &&
                      row.Voucher_Month != '' &&
                      row.Voucher_Month != null
                  }"
                                          class="grid-cell">
                                    {{ row.Voucher_Month }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Voucher_Day">
                                <mat-header-cell mat-sort-header
                                                 class="grid-header 0"
                                                 *matHeaderCellDef>Voucher_Day</mat-header-cell>
                                <mat-cell *matCellDef="let row"
                                          [ngClass]="{
                    'pending-danger':
                      !isGreaterThan(row.Voucher_Day, null,'Day') &&
                      row.Voucher_Day != '' &&
                      row.Voucher_Day != null
                  }"
                                          class="grid-cell">
                                    {{ row.Voucher_Day }}
                                </mat-cell>
                            </ng-container>
                
                        <ng-container matColumnDef="Amount">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>Amount</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]=" row.Amount == null || row.Amount == '' || row.Amount == 0? 'pending-danger'  : ''"
                                      class="grid-cell">
                                {{ row.Amount }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Narration">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>Narration</mat-header-cell>
                            <mat-cell *matCellDef="let row"
                                      [ngClass]=" row.Narration == null || row.Narration == '' || row.Narration == 0? 'pending-danger'  : ''"
                                      class="grid-cell">
                                {{ row.Narration }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="NotMach">
                            <mat-header-cell mat-sort-header
                                             class="grid-header"
                                             *matHeaderCellDef>Error</mat-header-cell>
                            <mat-cell *matCellDef="let row" class="grid-cell">
                                {{ row.NotMach }}
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayImportData"></mat-header-row>
                        <mat-row [ngClass]="
                row.NotMach != '' && row.NotMach != null ? 'pending-danger' : ''
              "
                                 *matRowDef="let row; columns: displayImportData"></mat-row>
                    </mat-table>
                </div>

                <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
                    <button type="button"
                            *ngIf="InValidDataInDataBase == 2"
                            (click)="OnSaveImportData()"
                            [class.spinner]="loading"
                            class="btn font-size-12 button-btn">
                        Save Import Data
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>