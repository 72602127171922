import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable()
export class DataSharingService {
  public FilterAppt = new Subject<string>();
  //public RefreshLoginPage = new Subject<string>();
  public HeaderTitle = new Subject<string>();
  public LOSHeaderTitle = new Subject<string>();


  public CustomerName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public RefreshLoginPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public LmsHeaderBranch = new Subject<string>();
  public LmsHeaderProduct = new Subject<string>();
  public LmsHeaderLoanNo = new Subject<string>();
  public LmsHeaderLoanOR_APP_No = new Subject<string>();
  public LmsHeaderLoanId = new Subject<string>();
  public LmsHeaderType = new Subject<string>();
  public LmsHeaderApplicationId = new Subject<string>();
  public LmsHeaderFI = new Subject<boolean>();
  public LmsHeaderTVR = new Subject<boolean>();
  public LmsHeaderCRC = new Subject<boolean>();
  public LmsHeaderFITVR = new Subject<boolean>();
  public LmsHeaderNetFinance = new Subject<string>();
  public MenuItem: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public LmsIsShowApplicationDetail = new Subject<string>();
  public LmsResponseShowApplicationDetail = new Subject<string>();

  public LmsViewLoanStatement = new Subject<string>();

  getLmsHeaderBranch(): Observable<string> {
    return this.LmsHeaderBranch.asObservable();
  }

  getLmsHeaderProduct(): Observable<string> {
    return this.LmsHeaderProduct.asObservable();
  }

  getLmsHeaderLoanNo(): Observable<string> {
    return this.LmsHeaderLoanNo.asObservable();
  }
  getLmsHeaderLoanNo_Or_AppNo(): Observable<string> {
    return this.LmsHeaderLoanOR_APP_No.asObservable();
  }
  getLmsHeaderLoanId(): Observable<string> {
    return this.LmsHeaderLoanId.asObservable();
  }
  getLmsHeaderType(): Observable<string> {
    return this.LmsHeaderType.asObservable();
  }
  getLmsHeaderApplicationId(): Observable<string> {
    return this.LmsHeaderApplicationId.asObservable();
  }

  getFilter(): Observable<string> {
    return this.FilterAppt.asObservable();
  }

  getRefreshLoginPage(): Observable<string> {
    return this.RefreshLoginPage.asObservable();
  }

  getHeaderTitle(): Observable<string> {
    return this.HeaderTitle.asObservable();
  }

  getLOSHeaderTitle(): Observable<string> {
    return this.LOSHeaderTitle.asObservable();
  }
  getLmsHeaderFI(): Observable<boolean> {
    return this.LmsHeaderFI.asObservable();
  }
  getLmsHeaderTVR(): Observable<boolean> {
    return this.LmsHeaderTVR.asObservable();
  }
  getLmsHeaderCRC(): Observable<boolean> {
    return this.LmsHeaderCRC.asObservable();
  }
  getLmsHeaderFITVR(): Observable<boolean> {
    return this.LmsHeaderFITVR.asObservable();
  }
  getLmsHeaderNetFinance(): Observable<string> {
    return this.LmsHeaderNetFinance.asObservable();
  }
  cleanObject(obj: any) {
    for (var propName in obj) {
      if (!obj[propName]) {
        obj[propName] = null;
      }
    }
    return obj;
  }
  setMenuItem(type: any, data: any) {
    let _menu = this.MenuItem.value;
    _menu[type] = data;
    this.MenuItem.next(_menu);
  }
  getMenuItem() {
    return this.MenuItem.asObservable();
  }
  getMenuItemByType(type: any) {
    let _menu = this.MenuItem.value;
    return _menu[type]!=undefined;
  }

  
  getLmsIsShowApplicationDetail(): Observable<string> {
    return this.LmsIsShowApplicationDetail.asObservable();
  }
  getLmsResponseShowApplicationDetail(): Observable<string> {
    return this.LmsResponseShowApplicationDetail.asObservable();
  }

  getLmsViewLoanStatement(): Observable<string> {
    return this.LmsViewLoanStatement.asObservable();
  }
}

