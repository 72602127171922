<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 formborder">
    <div class="col-md-3 p-1">
      <span> Select State </span>
      <select
        name="FilterStateId"
        id="FilterStateId"
        (change)="onChangeFilterState()"
        [(ngModel)]="FilterStateId"
        class="form-control input-text-css"
      >
        <option value="">Select State</option>
        <option
          *ngFor="let StateList of FilterStateList"
          [value]="StateList.StateId"
        >
          {{ StateList.State_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-3 p-1">
      <span> Select District </span>
      <select
        name="FilterDistrictId"
        id="FilterDistrictId"
        (change)="getTahsilList()"
        [(ngModel)]="FilterDistrictId"
        class="form-control input-text-css"
      >
        <option value="">Select District</option>
        <option
          *ngFor="let districtList of FilterDistrictList"
          [value]="districtList.DistrictId"
        >
          {{ districtList.District_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-6 p-1 d-flex align-items-end justify-content-end">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="onOpenTehsilModal()"
      >
        Add New Tahsil
      </button>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="TehsilId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="District_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          District Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.District_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Tehsil_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Tehsil Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Tehsil_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OneditTehsil(row)"
            style="cursor: pointer"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div class="col-md-12 d-flex align-items-center justify-content-between">
    <div class="col-md-4">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>

    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="AddEditTehsilModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveTehsilData()" novalidate>
    <div class="modal-dialog" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ ButtonName }} Teshil
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select State </span>
            </div>
            <div class="col-md-8 p-1">
              <select
                name="StateId"
                id="StateId"
                (change)="onChangeState()"
                #refStateId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refStateId.invalid,
                  'alert-warning':
                    refStateId.invalid &&
                    (refStateId.dirty ||
                      refStateId.touched ||
                      refStateId.untouched)
                }"
                [(ngModel)]="StateId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let StateList of FilterStateList"
                  [value]="StateList.StateId"
                >
                  {{ StateList.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Select District</span>
            </div>
            <div class="col-md-8 p-1">
              <select
                required
                name="DistrictId"
                id="DistrictId"
                #refDistrictId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDistrictId.invalid,
                  'alert-warning':
                    refDistrictId.invalid &&
                    (refDistrictId.dirty ||
                      refDistrictId.touched ||
                      refDistrictId.untouched)
                }"
                [(ngModel)]="DistrictId"
                class="form-control input-text-css"
              >
                <option value="">Select District</option>
                <option
                  *ngFor="let districtList of DistrictList"
                  [value]="districtList.DistrictId"
                >
                  {{ districtList.District_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1">
              <span class="required-lable">Teshil Name </span>
            </div>
            <div class="col-md-8 p-1">
              <input
                required
                type="text"
                #refTehsilName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refTehsilName.invalid,
                  'alert-warning':
                    refTehsilName.invalid &&
                    (refTehsilName.dirty ||
                      refTehsilName.touched ||
                      refTehsilName.untouched)
                }"
                [(ngModel)]="TehsilName"
                placeholder="Teshil Name"
                name="TehsilName"
                id="TehsilName"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveTehsilData()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="Tehsil_List"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      District Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tehsil Name
    </th>
  </tr>

  <tr *ngFor="let x of TehsilList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.District_Name }}</td>
    <td>{{ x.Tehsil_Name }}</td>
  </tr>
</table>
