<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  style="height: 240px; max-width: 100%; overflow-y: auto; overflow-x: hidden"
>
  <!-- <div class="row m-0 mt-2">
      <div class="col-md-12 p-1"><h5>Customer Details</h5></div>
  </div> -->

  <div class="row m-0 mt-1">
    <div class="col-md-12" style="margin: 0 -18px">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-interval="false"
        data-wrap="false"
      >
        <div class="carousel-item active" *ngIf="customerData.length > 0">
          <div class="row m-0">
            <div
              class="col-md-3 p-1"
              *ngFor="let item of customerData.slice(0, 4); let i = index"
              (click)="OnClickTVRQuestions(item)"
            >
              <div
                class="card"
                style="cursor: pointer"
                [ngClass]="{ active: item.CustomerId === CustomerId }"
              >
                <div class="card-body p-2">
                  <div class="row m-0 mb-3 align-items-center">
                    <div class="col-md-3 p-0">
                      <img
                        style="height: 45px; width: 45px"
                        src="{{ customerProfileBaseUrl }}{{
                          item.CustomerId
                        }}/{{ item.ProfilePic }}"
                        class="user-img rounded-circle img-thumbnail"
                        alt=""
                        onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                      />
                    </div>
                    <div class="col-md-9 p-0">
                      <h6 class="col-md-12 mb-2 fs-12">{{ item.Customer }}</h6>
                      <p class="col-md-12 card-subtitle fs-12 text-muted">
                        {{
                          item.CustomerType == "Hirer"
                            ? "Borrower"
                            : item.CustomerType
                        }}
                      </p>
                      <div class="row m-0">
                        <div class="col-md-12">
                          <B>Status</B> : {{ item.TVR_Status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="customerData">
          <div class="carousel-item" *ngIf="customerData.length > 4">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(4, 8); let i = index"
                (click)="OnClickTVRQuestions(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                        <div class="row m-0">
                          <div class="col-md-12">
                            <B>Status</B> : {{ item.TVR_Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="customerData.length > 8">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(8, 12); let i = index"
                (click)="OnClickTVRQuestions(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.Customer }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                        <div class="row m-0">
                          <div class="col-md-12">
                            <B>Status</B> : {{ item.TVR_Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <a
          class="carousel-control-prev ml-2"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="prev"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="next"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <form
    #f="ngForm"
    class="formborder"
    (ngSubmit)="onSaveTvr()"
    novalidate
    *ngIf="ShowDiv"
  >
    <div class="row m-0 mt-2 h-b">
      <div class="col-md-1 pl-1"><b>#</b></div>
      <div class="col-md-4 pl-1"><b>Question</b></div>
      <div class="col-md-4 pl-1"><b>Question (Hindi)</b></div>
      <div class="col-md-3 pl-1"><b>Response</b></div>
    </div>
    <div
      class="row m-0 mt-2"
      *ngFor="let question of questionData; let i = index"
    >
      <div class="col-md-1 pl-1">{{ i + 1 }}</div>
      <div class="col-md-4 pl-1">{{ question.Question }}</div>
      <div class="col-md-4 pl-1">{{ question.Question_Hindi }}</div>
      <div class="col-md-3 pl-1">
        <ng-container *ngIf="question.QuestionType == 'Boolean'">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              [disabled]="IsDisabled || Processid == 5"
              #refQR="ngModel"
              [(ngModel)]="question.TVR_QuestionAnswer"
              required
              value="true"
              [ngClass]="{
                'is-invalid': f.submitted && refQR.invalid,
                'alert-warning':
                  refQR.invalid &&
                  (refQR.dirty || refQR.touched || refQR.untouched)
              }"
            />
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              [disabled]="IsDisabled || Processid == 5"
              #refQR="ngModel"
              [(ngModel)]="question.TVR_QuestionAnswer"
              required
              value="false"
              [ngClass]="{
                'is-invalid': f.submitted && refQR.invalid,
                'alert-warning':
                  refQR.invalid &&
                  (refQR.dirty || refQR.touched || refQR.untouched)
              }"
            />
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </ng-container>
        <ng-container *ngIf="question.QuestionType == 'Selection'">
          <span> </span>
          <select
            name="QS{{ i }}"
            id="QS{{ i }}"
            #refQS="ngModel"
            [(ngModel)]="question.TVR_QuestionAnswer"
            class="form-control input-text-css"
            required
            [disabled]="IsDisabled || Processid == 5"
            [ngClass]="{
              'is-invalid': f.submitted && refQS.invalid,
              'alert-warning':
                refQS.invalid &&
                (refQS.dirty || refQS.touched || refQS.untouched)
            }"
          >
            <option value="null">Select</option>
            <ng-container *ngFor="let option of questionOptionData">
              <option
                *ngIf="option.TVR_QueId == question.TVR_QueId"
                [value]="option.TVR_Que_Option"
              >
                {{ option.TVR_Que_Option }}
              </option>
            </ng-container>
          </select>
        </ng-container>
        <ng-container *ngIf="question.QuestionType == 'Multi-Selection'">
          <span> </span>
          <select
            multiple
            name="QMS{{ i }}"
            id="QMS{{ i }}"
            #refQMS="ngModel"
            [(ngModel)]="question.TVR_QuestionAnswer"
            class="form-control input-text-css"
            required
            [disabled]="IsDisabled || Processid == 5"
            [ngClass]="{
              'is-invalid': f.submitted && refQMS.invalid,
              'alert-warning':
                refQMS.invalid &&
                (refQMS.dirty || refQMS.touched || refQMS.untouched)
            }"
          >
            <ng-container *ngFor="let option of questionOptionData">
              <option
                *ngIf="option.TVR_QueId == question.TVR_QueId"
                [value]="option.TVR_Que_Option"
              >
                {{ option.TVR_Que_Option }}
              </option>
            </ng-container>
          </select>
        </ng-container>
        <ng-container *ngIf="question.QuestionType == 'Integer'">
          <span></span>
          <input
            required
            type="number"
            #refQI="ngModel"
            [(ngModel)]="question.TVR_QuestionAnswer"
            [disabled]="IsDisabled || Processid == 5"
            placeholder="Your Answer"
            name="QI{{ i }}"
            id="QI{{ i }}"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refQI.invalid,
              'alert-warning':
                refQI.invalid &&
                (refQI.dirty || refQI.touched || refQI.untouched)
            }"
            min="0"
          />
        </ng-container>
        <ng-container *ngIf="question.QuestionType == 'Text'">
          <span></span>
          <textarea
            required
            rows="2"
            #refQT="ngModel"
            [(ngModel)]="question.TVR_QuestionAnswer"
            [disabled]="IsDisabled || Processid == 5"
            placeholder="Your Answer"
            name="QT{{ i }}"
            id="QT{{ i }}"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refQT.invalid,
              'alert-warning':
                refQT.invalid &&
                (refQT.dirty || refQT.touched || refQT.untouched)
            }"
          ></textarea>
        </ng-container>
        <ng-container
          *ngIf="question.QuestionType == 'Upload'"
          [disabled]="IsDisabled || Processid == 5"
        >
          <i
            class="fa fa-upload"
            *ngIf="!question.TVR_QuestionAnswer"
            (click)="docFile.click()"
            aria-hidden="true"
          ></i>
          <ng-container *ngIf="question.TVR_QuestionAnswer">
            <a
              href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                question.TVR_QuestionAnswer
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i
              >{{ question.TVR_QuestionAnswer }}
            </a>
            <!-- <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{question.TVR_QuestionAnswer}} -->
            <i
              class="fa fa-times ml-2"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile(question, docFile)"
            ></i>
          </ng-container>
          <input
            #docFile
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel"
            (change)="fileChangeListener(question, docFile.files)"
            style="display: none"
          />
          <!-- <div class="row">
              <div class="col-md-6">
                  <input type="text" required placeholder="Document Title" name="title{{ i }}" id="title{{ i }}" #refTitle="ngModel"
                      [(ngModel)]="question.TVR_QuestionAnswer" class="form-control input-text-css" [disabled]="IsDisabled"
                      [ngClass]="{'is-invalid': f.submitted && refTitle.invalid, 'alert-warning': refTitle.invalid && (refTitle.dirty || refTitle.touched || refTitle.untouched)}" />
              </div>
              <div class="col-md-6">
                  <i class="fa fa-upload" *ngIf="!question.TVR_DocFile" (click)="docFile.click()" aria-hidden="true"></i>
                  <ng-container *ngIf="question.DocData">
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{question.TVR_DocFile}}
                      <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer;" (click)="removeFile(question, docFile)"></i>
                  </ng-container>
                  <input #docFile type="file" [multiple]="false" accept="application/pdf,application/vnd.ms-excel"
                      (change)="fileChangeListener(question, docFile.files)" style="display: none;">
              </div>
          </div> -->
        </ng-container>
      </div>
    </div>

    <hr />
    <div class="row m-0" *ngIf="Processid != 5">
      <div class="col-md-4 p-1">
        <span class="required-lable">Recommendation</span>
        <select
          name="Recommendation"
          id="Recommendation"
          #refRecommendation="ngModel"
          [disabled]="IsEdit"
          [(ngModel)]="Recommendation"
          [ngClass]="{
            'is-invalid': f.submitted && refRecommendation.invalid,
            'alert-warning':
              refRecommendation.invalid &&
              (refRecommendation.dirty ||
                refRecommendation.touched ||
                refRecommendation.untouched)
          }"
          class="form-control input-text-css"
          required
        >
          <option value="">Select Recommendation</option>
          <option value="Positive">Positive</option>
          <option value="Negative">Negative</option>
          <option value="Average">Average</option>
          <option value="Below Average">Below Average</option>
          <option value="Not Conducted">Not Conducted</option>
        </select>
      </div>
      <div class="col-md-6 p-1">
        <span class="required-lable">Remarks</span>
        <textarea
          name="Remarks"
          id="Remarks"
          class="form-control input-text-css"
          required
          [disabled]="IsEdit"
          #refRemarks="ngModel"
          [(ngModel)]="Remarks"
          placeholder="Remarks"
          rows="1"
          [ngClass]="{
            'is-invalid': f.submitted && refRemarks.invalid,
            'alert-warning':
              refRemarks.invalid &&
              (refRemarks.dirty || refRemarks.touched || refRemarks.untouched)
          }"
        ></textarea>
      </div>
      <div class="text-right" [ngClass]="'col-md-2'" *ngIf="Processid != 5">
        <button
          type="button"
          (click)="onEditCustomerTVRAnswer()"
          *ngIf="
            IsEdit &&
            PageData.currentTab == 1 &&
            Status != 'C' &&
            (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
          "
          class="btn font-size-12 button-btn mt-4"
        >
          Edit Answer
        </button>
        <button
          type="button"
          (click)="onSaveTvr()"
          *ngIf="
            IsSave &&
            PageData.currentTab == 1 &&
            Status != 'C' &&
            (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
          "
          class="btn font-size-12 button-btn mt-4"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save Answer
        </button>
        <button
          type="button"
          (click)="onSaveTvr()"
          *ngIf="IsUpdate"
          style="width: max-content"
          class="btn font-size-12 button-btn mt-4"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Update Answer
        </button>
      </div>
    </div>
  </form>
</div>
