import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { LmsService } from '../services/lms.service';
import { MasterService } from '../../Shared/app.Masters.Service';
import { DialogService } from '../../Shared/dialog.service';
import * as moment from 'moment';

// Excel export code start here
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
// Excel export code end here

@Component({
    selector: 'app-lms-Note-Recommendation',
    templateUrl: './lms-Note-Recommendation.component.html',
    styleUrls: ['./lms-Note-Recommendation.component.scss']
})
export class LmsNoteRecommendationComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  OthersDocUrl: any = constantUrl.OthersDocUrl;
  dataSource: any;
  today: Date = new Date();
  DocModel: any[] = [];
  displayedColumns: string[] = ["Id", "Type", "Remarks", "Executive", "CreatedOn", "Title", "Date", "FollowUpBy", "Status","CompletedOn", "View",];
  configSuccess: MatSnackBarConfig = {
      panelClass: "style-success",
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
  };
  CompletedRemark: any;
  docBaseUrl = constantUrl.CrcApplicationDocUrl;
  loading: boolean = false;
  IsForword: boolean = false;
  noteList: any[] = [];
  Attachment: any = [{}];
  noteModel: any = {};
  ActionData: any = {};
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  updateModel: any = {};
  AllData: any = [];
  FollowupByList: any[] = [];
  DepartmentList: any[] = [];
  RequestList: any[] = [];
  TableId: any;
  PageAcess: any;
  tab: any ='Notes'
  displayedTableColumns=[]
  dataSourceChat=new MatTableDataSource([]);

    
  constructor(
      private dataSharingService: DataSharingService,
      private encdec: EncrDecrService,
      private snackBar: MatSnackBar,
      private router: Router,
      private losService: LosService,
      private lmsService: LmsService,
      private route: ActivatedRoute, private MasterService: MasterService, private dialog: DialogService
  ) {

  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Notes-Chat');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.updateModel = { LoanId: this.loanSummary.LoanId, Type: "", Remark: "", FollowupBy: "", FollowupDate: "" };

    let AllPage = JSON.parse(sessionStorage.getItem('AllPageAcess'));
    if(AllPage != null){
      this.PageAcess = AllPage.filter(x => x.Page_Name == "Notes-Recommendation");

      if(this.PageAcess == '' || this.PageAcess == null || this.PageAcess == undefined){
          this.RedirectTo();
      }
    }
    this.showSpinner = true;
    // this.Get_Employee_ByBranch_Department();
    this.Get_DepartmentMaster();
    this.ViewAll();
    this.getRequestType();
    console.log
    ("Loan Summary",this.loanSummary);
    this.fetchChat()
  }
  LMS_Get_NoteRecommendation() {
    console.log(this.TableId);
    this.lmsService.LMS_Get_NoteRecommendation({ Loan_Id: this.loanSummary.LoanId, LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      this.noteList = res;
      this.dataSource = new MatTableDataSource(res);
      this.showSpinner = false;
    });
  }
  ViewAll() {
    this.lmsService.LMS_Get_NoteRecommendation({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      this.noteList = res;
      this.dataSource = new MatTableDataSource(res);
      this.showSpinner = false;
    });
  }
  Get_Employee_ByBranch_Department() {
    var DepartmentId = 0;
    var Type = "";
    if (this.noteModel.Department != "")
      DepartmentId = this.noteModel.Department
    if (this.noteModel.Type == 'Customer Request' )
      Type = "Upper";
    if (this.noteModel.Type == 'Customer Task' || this.noteModel.Type == 'Follow-Up' || this.noteModel.Type == 'Collection')
      Type = "Lower";
    this.MasterService.Get_Employee_ByBranch_Department({ BranchId: this.loanSummary.BranchId, DeptId: DepartmentId, LoginUserId: this.currentUser.userId, Type: Type, ProductId: this.loanSummary.ProductId }).subscribe((res: any) => {
      this.FollowupByList = res;
    });
  }

  Get_FolluwuBy_Department() {
    var DepartmentId = 0;
    var Type = "";
    if (this.noteModel.Department != "")
      DepartmentId = this.noteModel.Department
    if (this.updateModel.Type == 'Customer Request')
      Type = "Upper";
    if (this.updateModel.Type == 'Customer Task' || this.updateModel.Type == 'Follow-Up' || this.updateModel.Type == 'Collection' )
      Type = "Lower";
    this.MasterService.Get_Employee_ByBranch_Department({ BranchId: this.loanSummary.BranchId, DeptId: DepartmentId, LoginUserId: this.currentUser.userId, Type: Type, ProductId: this.loanSummary.ProductId }).subscribe((res: any) => {
      this.FollowupByList = res;
    });
  }
  ChangeType() {
    this.noteModel.Department = "";
    this.noteModel.FollowupBy = "";
    this.noteModel.Title = "";
    this.FollowupByList = [];
    if (this.noteModel.Type == 'Collection') {
      this.noteModel.Department = "5";
      this.Get_Employee_ByBranch_Department();
    }
  }
  Get_DepartmentMaster() {
    this.showSpinner = true;
    this.MasterService.Get_DepartmentMaster({}).subscribe((result) => {
      this.DepartmentList = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
  }

  getRequestType() {
    this.lmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'REQUEST' }).subscribe((res: any) => {
      this.RequestList = res;
    });

  }
    onDeleteNote(data: any) {
        this.dialog.openConfirmDialog(`Are you sure you want to remove ${data.Title} from Loan.`)
            .afterClosed().subscribe(dialogResult => {
                if (dialogResult == false) {
                    return;
                } else {
                    this.showSpinner = true;
                    this.lmsService.LMS_Delete_NoteRecommendation({ Id: data.Id, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
                        this.showSpinner = false;
                        if (res[0].CODE >= 0) {
                            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                            this.ViewAll();
                        } else {
                            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                        }
                    })
                }
            });
    }
    onNextStep() {
        //if (this.noteList.length != 0) {
            this.lmsService.SaveNext_Loan({
                Loan_Id: this.loanSummary.LoanId, CommandName: 'Notes', isStatus: 1
            }).subscribe((response) => {
                this.action.emit("next")
            });

        //} else {
        //    this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Atlease one Note.", ...this.configSuccess });
        //}

    }
  addNewNote() {
    this.TableId = 0;
    this.AllData = [];
    this.noteModel = { Loan_Id: this.loanSummary.LoanId, Title: "", Remark: "", FollowupBy: 0, FollowupDate: "", LoginUserId: this.currentUser.userId, Type: "Notes", StatusVal: "Pending", Department:"0"}
        $("#noteModal").modal("show");
        $("#noteModal").css("z-index", "1050");
    }
    
  OnNoteClose() {
    this.TableId = 0;
    this.AllData = [];
    this.IsForword = false;
    this.ActionData = {};
    this.updateModel = { LoanId: this.loanSummary.LoanId, Type: "", Remark: "", FollowupBy: "", FollowupDate: "" };
    $("#noteModal").modal("hide");
    $("#CompletedModal").modal("hide");
    }
    onSaveNote(){
        this.loading = true;
      this.noteModel.Id = 0;
      this.noteModel.FollowupDate == "" ? null : this.noteModel.FollowupDate;
      if (this.noteModel.Type == 'Collection') {
        this.noteModel.FollowupBy = this.loanSummary.CollectionExecutiveId;
      }
      //this.noteModel.FollowupBy = this.currentUser.userId;
        this.lmsService.LMS_Save_NoteRecommendation(this.noteModel).subscribe((res: any) => {
            this.loading = false;
            if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
              this.OnNoteClose();
                this.ViewAll();
               
            } else {
              this.OnNoteClose();
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
            }
        });
    }
    
    onEditNotesRecom(row){
        //this.lmsService.LMS_Get_NoteRecommendation({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
        //    this.noteList = res;
        //    let data;
        //  data = res.filter(x => x.Id == row.Id);
        //  this.ActionData = data[0];
          this.TableId = row.Id;
          //let newdate;
          //if (data[0].FollowupDate != '' && data[0].FollowupDate != null && data[0].FollowupDate != undefined)
          //  newdate = new Date(data[0].FollowupDate);
          //this.noteModel = { Loan_Id: this.loanSummary.LoanId, Title: data[0].Title, Remark: data[0].Remarks, FollowupBy: data[0].FollowupBy, FollowupDate: newdate, LoginUserId: this.currentUser.userId, Type: data[0].Type, Department: data[0].Department}

          if (Number(this.TableId) > 0) {
            this.lmsService.LMS_Get_CRM_Detail({ Id: this.TableId }).subscribe((res: any) => {

              this.AllData = res.Item1;
              console.log("AllData", this.AllData);
              this.ActionData = res.Item1[0];
              this.noteModel.Type = res.Item1[0].Type;
              this.TableId = res.Item1[0].Id;
              this.Attachment = res.Item2;
              if (this.Attachment.length == 0) {
                this.Attachment = [{}];
              }
             let newdate;
              if (res.item[0].FollowupDate != '' && res.item[0].FollowupDate != null && res.item[0].FollowupDate != undefined)
                newdate = new Date(res.item[0].FollowupDate);
              this.noteModel = { Loan_Id: this.loanSummary.LoanId, Title: res.item[0].Title, Remark: res.item[0].Remarks, FollowupBy: res.item[0].FollowupBy, FollowupDate: newdate, LoginUserId: this.currentUser.userId, Type: res.item[0].Type, Department: res.item[0].Department }


            });

          }



          $("#noteModal").modal("show");
            $("#noteModal").css("z-index", "1050");
            
       // });
    }

    onUpdateNotesRecom(){
        this.noteModel.Id = 0;

        this.loading = true;
      this.noteModel.Id = this.TableId;
      //this.noteModel.Type = "Notes";
        this.lmsService.LMS_Save_NoteRecommendation(this.noteModel).subscribe((res: any) => {
            this.loading = false;
            if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
                this.ViewAll();
                this.OnNoteClose();
            } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                });
            }
        });
    }

    RedirectTo(){
        this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
        this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
        return;
  }
  onCompleted() {
    //this.lmsService.CRM_Request_Creation({ Id: this.TableId, LoginUserId: this.currentUser.userId, Type: "Complete", JSON: "" }).subscribe((res: any) => {
    //  this.loading = false;
    //  if (res[0].CODE >= 0) {
    //    this.snackBar.openFromComponent(SnackbarComponent, {
    //      data: res[0].MSG,
    //      ...this.configSuccess,
    //    });
    //    this.LMS_Get_NoteRecommendation();
    //    this.OnNoteClose();
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, {
    //      data: res[0].MSG,
    //      ...this.configSuccess,
    //    });
    //  }
    //});
    $("#CompletedModal").modal("show");
    $("#CompletedModal").css("z-index", "1050");
  }
  onSaveForworded() {
    if (this.updateModel.Type == 'Collection') {
      this.updateModel.FollowupBy = this.loanSummary.CollectionExecutiveId;
    }
    var JSONData = { "Data": { LoanId: this.loanSummary.LoanId, Type: this.updateModel.Type, Remark: this.updateModel.Remark, FollowupBy: this.updateModel.FollowupBy, FollowupDate: this.updateModel.FollowupDate } }
    this.lmsService.CRM_Request_Creation({ Id: this.TableId, LoginUserId: this.currentUser.userId, Type: "Forward", JSON: JSON.stringify(JSONData) }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,

        });
        this.IsForword = false;
        this.updateModel = { LoanId: this.loanSummary.LoanId,Type: "", Remark: "", FollowupBy: "", FollowupDate: "" };
        this.ViewAll();
        this.OnNoteClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  onForworded() {
    this.updateModel.Type = this.noteModel.Type;
    this.Get_FolluwuBy_Department();
    this.IsForword = true;
  
  }

  OnCompletedDone() {
    this.Attachment.splice(this.Attachment.length - 1, 1);
    var saveData = { Attachment: this.Attachment };
    for (let i = 0; i < this.DocModel.length; i++) {
      this.MasterService.SaveOtherDocPath( this.DocModel[i]).subscribe((res: any) => {
        console.log("res", res);
      });
    }
    this.lmsService.CRM_Request_Creation({ Id: this.TableId, LoginUserId: this.currentUser.userId, Type: "Complete", JSON: JSON.stringify(saveData), Remark: this.CompletedRemark }).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.ViewAll();
        this.OnNoteClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }


  removeFileAttachment(i: any) {
    this.DocModel.splice(i, 1);
    this.Attachment.splice(i, 1);
  }

  fileChangeListenerAttachment(files: any, input: any) {
    if (
      !this.Attachment[
        this.Attachment.length - 1
      ].Title
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please enter  title.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let _pre = this.today.getTime();
    let _fn = _pre + "_" + files[0].name;
    let _find = this.Attachment.find((obj) => {
      return obj.DocFileName == _fn;
    });
    if (_find) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same file can not be allow again.",
        ...this.configSuccess,
      });
      input.value = "";
      return;
    }
    let $this = this;
    let reader = new FileReader();
    let _data: any = {};
    this.Attachment[
      this.Attachment.length - 1
    ].DocFileName = _pre + "_" + files[0].name;
    _data.DocName = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    input.value = "";
    this.Attachment.push({});
    reader.onload = function () {
      _data.DocData = reader.result.toString().split(";base64,").pop();
      $this.DocModel.push(_data);
      return;
    };
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, excelFileName + new  Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcel(){
    this.noteList.forEach((obj: any) => {
      delete obj.Id;
      delete obj.IdDcrpt;
      delete obj.Process;
      delete obj.Executive;
      delete obj.FollowupBy;
      delete obj.Department;
      delete obj.LoanId;
      delete obj.Completed_Remark;
      delete obj.CompletedOn;
      delete obj.Priority;
    });
    this.exportAsExcelFile(this.noteList, "Notes-Recomendation");
  }
  // Excel export in backend code end here


  changeTab(tab){
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  //Fetch Chat
  fetchChat(){
    this.lmsService.Get_Loan_Chat({Loan_id :this.loanSummary.LoanId}).subscribe((x:any[])=>{
      console.log("Chat Data",x);
      this.displayedTableColumns=["Id","Sender","SendDate","Receiver","DateRead","MSG","Document_Attachment"]
      this.dataSourceChat=new MatTableDataSource(x)
    })
  }
}
