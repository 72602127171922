import { Component, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
declare var $: any;
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-productworkflow",
  templateUrl: "./productworkflow.component.html",
  styleUrls: ["./productworkflow.component.scss"],
})
export class ProductworkflowComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  DeletePoductRequestModel: RequestModel = new RequestModel();
  SaveProductWorkflowRequestModel: RequestModel = new RequestModel();
  SaveNewProductRequestModel: RequestModel = new RequestModel();

  dataSource: any;
  loading: boolean = false;
  displayedColumns: string[] = [
    "ProcessId",
    "GroupName",
    "Process",
    "Squance",
    "IsMandatory",
    "Section",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ProductList: any = [];
  WorkflowCreatedOn: string;
  WorkflowCreatedBy: string;
  WorkflowModifiedOn: string = "";
  WorkflowModifiedBy: string;
  // filter
  FilterProuctId: any = "";
  ProductListDropdown: any = [];
  // add product popup
  ProductCategoryDropdown: any = [];
  ProductId: any = 0;
  SelectProductCategory: any = "";
  ProductName: string = "";
  ProductShortName: string = "";
  CRC_Co_Browser: boolean = false;
  CRC_Guarantor: boolean = false;
  FI_Co_Browser: boolean = false;
  FI_Guarantor: boolean = false;
  TVR_Co_Browser: boolean = false;
  TVR_Guarantor: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Product Work Flow");
    this._MasterService.Get_ALLProductDropdown().subscribe((result) => {
      this.ProductListDropdown = JSON.parse(JSON.stringify(result));
      this.FilterProuctId =
        this.ProductListDropdown.length > 0
          ? this.ProductListDropdown[0].ProductId
          : "";
      this.getProductList();
    });
  }

  getProductList() {
    if (this.FilterProuctId == "") {
      this.dataSource = [];
      return;
    }
    this.showSpinner = true;
    this.RequestModel.ProductId = parseInt(this.FilterProuctId);
    this._MasterService
      .GetProductWorkflow(this.RequestModel)
      .subscribe((result) => {
        var obj = JSON.parse(JSON.stringify(result)).Item1[0];
        this.WorkflowCreatedOn = "";
        this.WorkflowCreatedBy = "";
        this.WorkflowModifiedOn = "";
        this.WorkflowModifiedBy = "";
        if (obj) {
          this.WorkflowCreatedOn = obj.Workflow_CreateOn;
          this.WorkflowCreatedBy = obj.Workflow_CreateBy;
          this.WorkflowModifiedOn = obj.Workflow_ModifyOn;
          this.WorkflowModifiedBy = obj.Workflow_ModifyBy;
        }

        this.ProductList = JSON.parse(JSON.stringify(result.Item2));
        this.ProductList.map((item) => {
          item.isChecked = true;
          if (item.Section == 1 || item.Section == 3) {
            item.isDisable = true;
          } else if (item.Section == 4) {
            item.isDisable = false;
            item.isChecked = false;
          } else {
            item.isDisable = false;
          }
          if (item.Section == 3) {
            item.isShowDelete = true;
          } else {
            item.isShowDelete = false;
          }
        });

        this.dataSource = new MatTableDataSource(this.ProductList);

        this.showSpinner = false;
      });
  }

  onChangeSelection(event: MatCheckboxChange, row) {
    this.ProductList.map((item) => {
      if (
        item.ProcessId == row.ProcessId ||
        (item.GroupName == row.GroupName && item.Section != 1)
      ) {
        item.isChecked = event.checked;
      }
    });
    this.dataSource = new MatTableDataSource(this.ProductList);
  }

  onDeleteProcess(row) {
    this.DeletePoductRequestModel.ProductId = row.ProcessId;
    this._MasterService
      .DeleteProduct(this.DeletePoductRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.ProductList.map((item) => {
            if (
              item.ProcessId == row.ProcessId ||
              (item.GroupName == row.GroupName && item.Section != 1)
            ) {
              item.isDisable = false;
              item.isChecked = false;
              item.isShowDelete = false;
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnSaveProductSection() {
    if (this.FilterProuctId != "") {
      let workFlowData = [];
      this.ProductList.map((item) => {
        workFlowData.push({
          ProductId: this.FilterProuctId,
          ProcessId: item.ProcessId,
          IsActive: item.isChecked == true ? 1 : 0,
          Createby: this.data.userId,
        });
      });

      this.SaveProductWorkflowRequestModel.JSON = JSON.stringify({
        Workflow: workFlowData,
      });
      this._MasterService
        .SaveProductWorkflow(this.SaveProductWorkflowRequestModel)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
            this.getProductList();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }

  OnOpenProductModal(selectedProductId) {
    this.ProductId = selectedProductId;
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
    $("#AddProductModal").modal("show");
    $("#AddProductModal").css("z-index", "1050");
  }

  onSaveNewProduct() {
    this.SaveNewProductRequestModel.ProductId = this.ProductId;
    this.SaveNewProductRequestModel.ProductCategory =
      this.SelectProductCategory;
    this.SaveNewProductRequestModel.ProductName = this.ProductName;
    this.SaveNewProductRequestModel.ProductShortName = this.ProductShortName;
    this.SaveNewProductRequestModel.IsCoBrowser_CRC = this.CRC_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_CRC = this.CRC_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_FI = this.FI_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_FI = this.FI_Guarantor;
    this.SaveNewProductRequestModel.IsCoBrowser_TVR = this.TVR_Co_Browser;
    this.SaveNewProductRequestModel.IsGuarantor_TVR = this.TVR_Guarantor;
    this.SaveNewProductRequestModel.LoginUserId = this.data.userId;

    this._MasterService
      .SaveLoanProduct(this.SaveNewProductRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.OnCloseProductModal();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  OnCloseProductModal() {
    this.ProductId = 0;
    this.SelectProductCategory = "";
    this.ProductName = "";
    this.ProductShortName = "";
    this.CRC_Co_Browser = false;
    this.CRC_Guarantor = false;
    this.FI_Co_Browser = false;
    this.FI_Guarantor = false;
    this.TVR_Co_Browser = false;
    this.TVR_Guarantor = false;
    $("#AddProductModal").modal("hide");
  }
}
