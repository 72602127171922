import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LosService } from '../../_LOS/services/los.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { EncrDecrService } from '../../AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { LmsService } from '../../_Lms/services/lms.service';
import * as moment from "moment";
import { DialogService } from '../../Shared/dialog.service';
import { ThisReceiver } from '@angular/compiler';
import { QuickLoanService } from '../services/quickLoan.service';
declare var $: any;

@Component({
  selector: 'app-quickloan-customer-detail',
  templateUrl: './quickloan-customer-detail.component.html',
  styleUrls: ['./quickloan-customer-detail.component.scss']
})
export class QuickloanCustomerDetailComponent implements OnInit {
  currentUser: any;
  ElectricityStatelist: any[] = [];
  ElectricityOpCodelist: any[] = [];
  OperatorCode: any = ''
  ELState: any = ''
  Verify_PAN_No: any;
  @Input() Revert_IsEdit: any;
  ProductDropdownList: any = [];
  isSaveCustomerRef: boolean = false;
  @ViewChild('btnRegisterNewCustomer') BtnRegisterNewCustomer: ElementRef;
  @ViewChild('btnUpdateCustomer') BtnUpdateCustomer: ElementRef;
  isUpdateAddress: boolean = false;
  isBtnSave: boolean = true;
  showSpinner: boolean = false; CurrentDate: string;
  RequestModel: RequestModel = new RequestModel();
  NotUpdate: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  Duplicate: any[] = [];
  DuplicateFirm: any[] = [];
  OTP: any;
  OTPCode: any;
  patnerData: any;

  applicationId: any; ProcessId: any; type: boolean = false;
  //LoanId; any;
  applicationDetail: any;
  loading: boolean = false;
  tab: any = 'CD';
  applicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  dataCustomerSource: any;
  customerlist: any[] = [];
  displayedCustomerColumns: string[] = ['Number', 'CustomerId', 'Customer', 'CustomerType', 'GenderAge', 'PhoneNo', 'Relation_With_Hirer', 'ExistingCustomer', 'Action'];
  dataCustomerAddressSource: any;
  displayedCustomerAddressColumns: string[] = ['CustomerId', 'Customer', 'Present_Address', 'Rent_Own', 'TotalYearsOnAddress', 'Permanent_Address', 'Work_Address', 'Action'];
  dataCustomerDocSource: any;
  dataCustomerRefSource: any;
  displayedCustomerDocColumns: string[] = ['CustomerId', 'Customer', 'Doc_Title', 'Doc_Number', 'Doc_FileName', 'UploadOn','LastVerfiedDate', 'Action'];
  displayedCustomerRefColumns: string[] = ['Neighbour', 'Name', 'Mobile', 'Ref_Relation', 'Association', 'Address', 'CreatedOn', 'Action'];
  applicationModel: any;
  questionData: any[] = [];
  today: Date = new Date();

  OccupationData: any[] = [];
  DL_DOB: any;
  // productDropdown: any[] = [];
  // dataSchemeSource: any;
  // displayedSchemeColumns: string[] = ['SchemeId', 'Scheme', 'Amount', 'ROI', 'Period', 'AdvanceEMI', 'Select'];
  // selectedScheme: any = {};
  //cEIModel: any = {};
  //stepEMI: any[] = [];
  // emiIrr: any;
  //stepEmiData: any;
  defaultDob: Date;
  smsmodel: any = {}
  loginFeeModel: any = {};
  FilterSearchBy: any = '';
  FilterSearchValue: any = '';
  dataSource: any;
  displayedColumns: string[] = ['CustomerId', 'CustomertName', 'RelationName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'Present_Address', 'Select'];
  customerModel: any = {};
  isExistingCustomer: boolean = false;
  isDirectorExistingCustomer: boolean = false;
  CustomerKYCDoc: any[] = [];
  duplicateData: any;
  duplicateFirmData: any;
  duplicateType: any;
  CustomerList: any;
  Age: any;
  firmKYCDoc: any[] = [];
  firmPartners: any[] = [];
  isCustomerEdit: boolean = false;
  stateDropdown: any[] = [];
  districkDropdownReg: any[] = [];
  tehasilDropdownReg: any[] = [];
  districkDropdownCorp: any[] = [];
  tehasilDropdownCorp: any[] = [];
  districkDropdownPresent: any[] = [];
  tehasilDropdownPresent: any[] = [];
  districkDropdownPermanent: any[] = [];
  tehasilDropdownPermanent: any[] = [];
  districkDropdownWork: any[] = [];
  tehasilDropdownWork: any[] = [];
  addressModel: any = {};
  customerRefModel: any = {};
  customerAddressModel: any = {};
  customerPermanentAddressModel: any = {};
  customerWorkAddressModel: any = {};
  kycModel: any;
  isNewAddress: boolean = false;
  inViewMode: boolean = false;
  // @Input() Type: any = '';
  //@Input() LoanId: any;
  //@Input() applicationNo: any;
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  OTPButton: boolean = true;
  PageAcess: any;
  TypeFrom: any = 'LMS';

  @Input() Processid: any;
  @Input() PageData: any;
  @Input() Status: any;
  @Input() ProcessName: any;
  

  KYC_Configration: any = {
    Id: 0,
    Is_Active: false,
    Key: "",
    SMS_Template: "",
    SMS_URL: "",
    Sender_Id: null,
    Step_Name: "",
    TemplateId: null,
    TemplateName: null,
    route: 0
  };
  Directors: any;
  Charges: any;
  APIResponse: any = { DoucumentNumber: "", Full_name: "", IsVerified: "", category: "" };
  verify_DocNumber: any;
  verify_DocId: any;
  clientId: any;
  constructor(private _Route: Router,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute, 
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService, 
    private _MasterService: MasterService,
    private router: Router, 
    private losService: LosService,
    private LmsService: LmsService,
    private dialog: DialogService,
    private _QuickLoanService: QuickLoanService,
  ) {

  }

  ngOnInit() {
    console.log("this.ProcessName", this.ProcessName);
    
    // this.dataSharingService.getLmsHeaderCRC().subscribe(value => {
    //   console.log("CRCjjkZChkzjckz=====", value);
    // });
    //this.dataSharingService.HeaderTitle.next('Customer Details');
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.defaultDob = new Date();
    this.defaultDob.setFullYear(this.today.getUTCFullYear() - 18);
    this.LMS_GetLoanDetails();
    this.getCustomerRef();

    console.log("this.loanSummary", this.loanSummary);

    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.applicationModel = { Recommendation: "", ProcessId: this.ProcessId, ApplicationId: this.applicationId, LoginUserId: this.currentUser.userId };
    this.getOccupationList();

    this._MasterService.Get_Sms_Configration({ Step_Name: 'Mobile_No_Validate' }).subscribe((res: any) => {
      if (res.length == 0) {
        this.OTPButton = false;
      }
    });
  }

  getCustomerList() {
    this.LmsService.GetCustomerByLoanNo({ Loan_Id: this.loanSummary.LoanId }).subscribe((result) => {
      this.CustomerList = JSON.parse(JSON.stringify(result));
    });
  }

  LMS_GetLoanDetails() {
    //this.showSpinner = true;
    this.LmsService.LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId }).subscribe((res: any) => {
      this.showSpinner = false;
      this.applicationDetail = res.Item1[0];
      this.customerlist = res.Item2;
      //console.log(res.Item2);
      //console.log('this.customerlistthis.customerlistthis.customerlist', res.Item2);
      this.dataCustomerSource = new MatTableDataSource(res.Item2);
      this.dataCustomerAddressSource = new MatTableDataSource(res.Item3);
      this.dataCustomerDocSource = new MatTableDataSource(res.Item4);
      this.questionData = res.Item5;
      this.questionData.forEach((obj) => {
        if (obj.QuestionType == 'Calendar') {
          obj.Answer = obj.Answer ? new Date(obj.Answer) : null;
        }
      })
    })
  }

  OnClose() {
    this.router.navigate(['/LOS/my-application']);
  }

  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
    }
  }

  onSaveApplication() {
    this.showSpinner = true;
    this.losService.LOS_ValidateSalesProcessApp({ ApplicationId: this.applicationId }).subscribe((res: any) => {
      if (res[0].CODE == -1) {
        this.showSpinner = false;
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
      else {
        this.losService.LOS_CompleteProcessAPP(this.applicationModel).subscribe((res: any) => {
          this.showSpinner = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.OnClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          }
        });
      }
    })
  }

  onSaveCustomerRef(ref) {
    this.showSpinner = true;
    let data = JSON.parse(sessionStorage.getItem('currentUser'));
    this.customerRefModel.Int_Id = 0;
    this.customerRefModel.CustomerId = (this.customerRefModel.RefRelation);
    this.customerRefModel.IsActive = 0;
    this.customerRefModel.Loan_Id = this.loanSummary.LoanId;
    this.customerRefModel.CreatedBy = data.userId;

    this.LmsService.onSaveCustomerRef(this.customerRefModel).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        //ref.resetForm();
        this.customerRefModel = {};
        this.getCustomerRef();
        this.onCloseReferenceData();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }

  getCustomerRef() {
    let User = JSON.parse(sessionStorage.getItem('currentUser'));

    let data = {
      Loan_Id: this.loanSummary.LoanId,
      Int_Id: 0,
      LoginUserId: User.userId
    }
    this.LmsService.LMS_GetCustomerRef(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.dataCustomerRefSource = new MatTableDataSource(res);
      this.customerRefModel.SelectNeighbour = 'Neighbour';
      this.customerRefModel.RefRelation = '';
      this.customerRefModel.Association = '';
    })
  }

  deleteCustomerRef(data) {
    let User = JSON.parse(sessionStorage.getItem('currentUser'));
    let refdata = {
      Loan_Id: this.loanSummary.LoanId,
      Int_Id: data.Int_Id,
      LoginUserId: User.userId
    }
    
    this.LmsService.LMS_GetCustomerRef(refdata).subscribe((res: any) => {
      this.showSpinner = false;
      this.getCustomerRef();

    });
  }

  getCustomerRefDetails(data) {
    this.customerRefModel.Int_Id = data.Int_Id;
    this.customerRefModel.SelectNeighbour = data.ReferenceType;
    this.customerRefModel.RefName = data.Name;
    this.customerRefModel.RefMobile = data.ContactNo;
    this.customerRefModel.RefRelation = data.Customer_Id;
    this.customerRefModel.Association = data.KnowYear;
    this.customerRefModel.Address = data.Address;
    this.customerRefModel.RefRemarks = data.Comments;
    this.getCustomerList();

  }

  OnFinancialDetailClose(event: any) {
    if (event) {
      this.LMS_GetLoanDetails();
    }
  }

  addLoginFee() {
    this.loginFeeModel = { ApplicationId: this.applicationId, TFA: this.applicationDetail.Applicable_LoginFeeAmount, TaxPercentage: 18, TaxAmount: '', SGST_Per: 9, CGST_Per: 9, SGST_Amount: '', CGST_Amount: '', CollectionMode: '', Remark: '', LoginUserId: this.currentUser.userId };
    this.calculateTax();
    $('#addLoginFee').modal('show');
    $("#addLoginFee").css("z-index", "1050");
  }


  async onSubmitFee() {

    if (+this.loginFeeModel.TFA < this.applicationDetail.Applicable_LoginFeeAmount) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Login Fee can not be less than ${this.applicationDetail.Applicable_LoginFeeAmount}`, ...this.configSuccess });
      return;
    }
    if (this.loginFeeModel.CollectionMode != 'Cash' && this.loginFeeModel.Remark.trim().length == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please enter reference number.', ...this.configSuccess });
      return;
    }
    this.loading = true;
    var shorturl;
    //var url = 'http://localhost:49509/#/fee/' + this.encdec.encrypt(this.loginFeeModel.ApplicationId)
    var url = 'http://finnaux.com/#/f/' + this.loginFeeModel.ApplicationId;

    //const response = await bitly.shorten(url);
    // console.log(response);
    //this._MasterService.MakeShortURL({ longURL: url }).subscribe((res: any) => {
    //  shorturl = res[0];
    //  console.log('res', shorturl, res[0]);

    //})

    this.losService.LOS_SaveLoginFeeweb(this.loginFeeModel).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {

        this.losService.LOS_GetLoginFeeSMS({ ApplicationId: this.loginFeeModel.ApplicationId }).subscribe((res: any) => {
          this.smsmodel.MobileNo = res[0].PhoneNo;
          this.smsmodel.sentMessage = res[0].MSG + ' ' + url; //+ ' ' + response.url;
          this._MasterService.SendSMS(this.smsmodel).subscribe((res: any) => {

          })
        })

        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LMS_GetLoanDetails();
        this.onCloseFee();

      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }

    })
  }
  calculateTax() {
    if (this.loginFeeModel.TFA) {
      let tta = +this.loginFeeModel.TFA * 18 / 100;
      let sgta = +this.loginFeeModel.TFA * 9 / 100;
      this.loginFeeModel.TaxAmount = tta;
      this.loginFeeModel.SGST_Amount = sgta;
      this.loginFeeModel.CGST_Amount = sgta;
      this.loginFeeModel.Amount = +this.loginFeeModel.TFA + tta;
    }
  }
  onCloseFee() {
    $('#addLoginFee').modal('hide');
  }
  addCustomer() {
    this.isCustomerEdit = false;
    this.customerModel = { 
      ApplicationId: this.applicationId, Customer_Type: '', Customer_Relation: '', LoginUserId: this.currentUser.userId, 
      RegTotalYearsOnAddress: '', RegAddressRentBuy: '', RegNearstBranchDistance_KM: '',
      CorpTotalYearsOnAddress: '', CorpAddressRentBuy: '', CorpNearstBranchDistance_KM: ''
    };
    this.isExistingCustomer = true;
    this.GetStateDropdown(false);
    $('#addCustomer').modal('show');
    $("#addCustomer").css("z-index", "1050");
  }
  addDirector() {
    this.isCustomerEdit = false;
    this.customerModel = { ApplicationId: this.applicationId, Customer_Type: '', Customer_Relation: '', LoginUserId: this.currentUser.userId };
    this.isDirectorExistingCustomer = true;
    $('#addDirector').modal('show');
    $("#addDirector").css("z-index", "1050");
  }
  onCloseDirector() {
    $('#addDirector').modal('hide');
    this.isUpdateAddress = false;
    //let _searchData: any[] = [];
    //this.dataSource = new MatTableDataSource(_searchData);
  }
  onCloseCustomer() {
    $('#addCustomer').modal('hide');
    this.isUpdateAddress = false;
  }
  getSearchCusList() {
    if (this.FilterSearchBy == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Select Search By.', ...this.configSuccess });
      return;
    }
    if (this.FilterSearchValue == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Enter Value.', ...this.configSuccess });
      return;
    }
    this.showSpinner = true;
    this.RequestModel.SearchOn = this.FilterSearchBy;
    this.RequestModel.SearchValue = this.FilterSearchValue;
    this._MasterService.GetSearchCustomer(this.RequestModel).subscribe((result) => {
      let _searchData: any[] = JSON.parse(JSON.stringify(result));
      this.dataSource = new MatTableDataSource(_searchData);
      this.showSpinner = false;
      if (_searchData.length == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: 'No search result found.', ...this.configSuccess });
      }
    });
  }
  onCustomerChange(event: any, data: any) {
    if (this.customerModel.CustomerId == data.CustomerId) {
      this.customerModel.CustomerId = '';
    }
    else {
      this.customerModel.CustomerId = data.CustomerId;
    }
    this.patnerData = data;
  }
  onAddExistingCustomer() {
    if (!this.customerModel.CustomerId) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Please Select Customer from above table.', ...this.configSuccess });
      return;
    }
    if (this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' }) && this.customerModel.Customer_Type == 'H') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Borrower Already Exist.', ...this.configSuccess });
      return;
    }
    if (this.customerModel.Customer_Type == 'H') {
      this.customerModel.Customer_Relation = 'Own';
    }
    this.loading = true;
    this.customerModel.Loan_Id = this.loanSummary.LoanId;
    //console.log('this.customerModel', this.customerModel);
    this.LmsService.LMS_SaveApplicationCustomerWEB(this.customerModel).subscribe((res: any) => {
      this.loading = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LMS_GetLoanDetails();
        this.onCloseCustomer();
        this.onCloseDirector();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }
  onAddExistingCustomer_Firm() {

    // if (this.customerModel.Customer_Type == 'H') {
    //this.customerModel.Customer_Relation = '';
    // }
    //this.loading = true;
    this.onCloseDirector();
    // this.addMoreKYC();

    this.isExistingCustomer = false;
    this.customerModel.Type = "Company";

    let length = this.firmPartners.length;
    this.firmPartners[length - 1].PartnerName = this.patnerData.CustomertName;
    this.firmPartners[length - 1].PartnerGender = this.patnerData.Customer_Gender;
    this.firmPartners[length - 1].PartnerAge = this.patnerData.GenderAge;
    this.firmPartners[length - 1].PartnerPhoneNo = this.patnerData.PhoneNo;


    // this.LmsService.LMS_SaveApplicationCustomerWEB(this.customerModel).subscribe((res: any) => {
    //     this.loading = false;
    //     if (res[0].CODE >= 0) {
    //         this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //         this.onCloseCustomer();
    //         this.onCloseDirector();
    //     } else {
    //         this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //     }
    // })
  }
  removeCustomerFromApp(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ${data.Customer} from application.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.LmsService.LMS_DeleteLoanCustomer({ Loan_Id: this.loanSummary.LoanId, customerId: data.CustomerId, LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.LMS_GetLoanDetails();
              this.QuickLoan_Save_PageProcess('Customer Details', this.PageData.MM_Id);
              this.QuickLoan_Save_PageProcess('Customer Reference', 3);
              this.QuickLoan_Save_PageProcess('Family Member', 10);
              this.QuickLoan_Delete_Income_Expenses_Detail(data.CustomerId);
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          })
        }
      });
  }


  fileChangeListenerQ(data: any, files: any) {
    let reader = new FileReader();
    data.Answer = files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeQFile(data: any, input: any) {
    input.value = "";
    data.Answer = "";
    delete data.DocData;
  }
  uploadAssetsDoc(data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService.UploadCrcDoc({ ApplicationNo: this.applicationDetail.ApplicationNo, DocName: obj.Answer, DocData: obj.DocData }).subscribe((res: any) => {

        });
      }
    });
  }
  onSaveAssets() {
    let AssetDetails: any[] = [];
    this.questionData.forEach((obj: any) => {
      AssetDetails.push({
        ApplicationId: this.applicationId,
        LoginUserId: this.currentUser.userId,
        QuestionId: obj.AssetId,
        Answer: obj.Answer
      })
    });
    this.showSpinner = true;
    let _data: any = {
      AssetDetails: AssetDetails
    }
    this.losService.LOS_SaveAssetDetailsApp({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LMS_GetLoanDetails();
        this.uploadAssetsDoc(this.questionData);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }
  registereNewCustomer() {
    this.CustomerKYCDoc = [];
    this.customerModel = { 
      ApplicationId: this.applicationId, Type: "Indusial", Customer_Type: '', Customer_Relation: '', Customer_Relation_Type : '',
      Customer_PhoneNo_IsVerified: false, LoginUserId: this.currentUser.userId, Customer_MaritalStatus: "", 
      Customer_Religion: "", Customer_Cast: "", Customer_Occupation: "", Customer_Gender: 'M',
    };
    this.customerAddressModel = {
      StateId : '',
      DistrictId : '',
      TehsilId : '',
      NoOfLiving : '',
      RentOwn : '',
      
    };
    this.customerPermanentAddressModel = {
      StateId : '',
      DistrictId : '',
      TehsilId : '',
      NoOfLiving : '',
      RentOwn : '',
    };
    this.customerWorkAddressModel = {
      StateId : '',
      DistrictId : '',
      TehsilId : '',
      NoOfLiving : '',
      RentOwn : '',
    };
    this.addMoreKYC();
    this.isExistingCustomer = !this.isExistingCustomer;
  }
  registereNewDirector() {
    this.CustomerKYCDoc = [];
    this.customerModel = { 
      ApplicationId: this.applicationId, Type: "Indusial", Customer_Type: '', Customer_Relation: '', Customer_PhoneNo_IsVerified: false, 
      LoginUserId: this.currentUser.userId, Customer_MaritalStatus: "", Customer_Religion: "", Customer_Cast: "" 
    };
    this.customerAddressModel = '';
    this.addMoreKYC();
    this.isDirectorExistingCustomer = !this.isDirectorExistingCustomer;
  }
  changeCustomertype() {
    if (this.customerModel.Type == 'Indusial') {
      this.GetStateDropdown();
      this.CustomerKYCDoc = [];
      this.customerModel = { 
        ApplicationId: this.applicationId, Type: "Indusial", Customer_Type: '', Customer_Relation: '', Customer_Relation_Type : '',
        Customer_PhoneNo_IsVerified: false, LoginUserId: this.currentUser.userId, Customer_MaritalStatus: "", Customer_Religion: "", 
        Customer_Cast: "", Customer_Occupation:"" 
      };
      this.addMoreKYC();
    }
    else {
      this.firmKYCDoc = [];
      this.firmPartners = [];
      this.customerModel = { 
        ApplicationId: this.applicationId, FirmId: 0, Type: "Company", SameAddress: false, 
        Customer_Type: '', Customer_Relation: 'Own', Firm_Type: "", Firm_RegState: "", Firm_RegDistrict: "", 
        Firm_RegTehsil: "", Firm_CorpState: "", Firm_CorpDistrict: "", Firm_CorpTehsil: "", Firm_PhoneNoIsVerified: false, 
        LoginUserId: this.currentUser.userId ,
        RegTotalYearsOnAddress: '', RegAddressRentBuy: '', RegNearstBranchDistance_KM: '',
        CorpTotalYearsOnAddress: '', CorpAddressRentBuy: '', CorpNearstBranchDistance_KM: ''
      };
      this.GetStateDropdown();
      this.addFirmKyc();
      this.addFirmPartner();
    }
  }
  changeCustomerRelationType() {
    if (this.customerModel.Customer_Type == "H") {
      this.customerModel.Customer_Relation == "Own";
    }
    else {
      this.customerModel.Customer_Relation == "";
    }
  }
  addFirmKyc() {
    if (this.firmKYCDoc.length > 0) {
      let _prev = this.firmKYCDoc[this.firmKYCDoc.length - 1];
      if (_prev.DocId == "" || _prev.DocNo == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.firmKYCDoc.push({ DocId: "", DocNo: "", DocImage: "" });
  }
  addFirmPartner() {
    if (this.firmPartners.length > 0) {
      let _prev = this.firmPartners[this.firmPartners.length - 1];
      if (_prev.PartnerName == "" || _prev.PartnerGender == "" || _prev.PartnerAge == "" || _prev.PartnerDesignation == "" || _prev.PartnerPhoneNo == "" || _prev.PartnerShare == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.firmPartners.push({ PartnerName: "", PartnerGender: "", PartnerAge: "", PartnerDesignation: "", PartnerPhoneNo: "", PartnerShare: "" });
  }
  removeFirmKYC(index: any) {
    this.firmKYCDoc.splice(index, 1);
  }
  removeFirmPartner(index: any) {
    this.firmPartners.splice(index, 1);
  }
  addMoreKYC() {
    if (this.CustomerKYCDoc.length > 0) {
      let _prev = this.CustomerKYCDoc[this.CustomerKYCDoc.length - 1];
      if (_prev.KYC_DocId == "" || _prev.KYC_DocNumber == "") {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please First Fill Required Feild to Add More.", ...this.configSuccess });
        return;
      }
    }
    this.CustomerKYCDoc.push({ KYC_DocId: "", KYC_DocNumber: "", KYC_DocFile: "", KYC_DocFile1: "" });
  }
  removeKYC(index: any) {
    this.CustomerKYCDoc.splice(index, 1);
  }
  fileChangeListener1(files: any) {
    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.customerModel.Customer_ProfilePic = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.customerModel.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile1(input: any) {
    input.value = "";
    this.customerModel.Customer_ProfilePic = "";
    delete this.customerModel.DocData;
  }
  fileChangeListener(data: any, files: any) {
    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.KYC_DocFile = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  fileChangeListener2(data: any, files: any) {
    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.KYC_DocFile1 = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData1 = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFile(data: any, input: any) {
    input.value = "";
    data.KYC_DocFile = "";
    delete data.DocData;
  }
  removeFile2(data: any, input: any) {
    input.value = "";
    data.KYC_DocFile1 = "";
    delete data.DocData1;
  }
  fileChangeListenerFirm(data: any, files: any) {
    let reader = new FileReader();
    let _pre = this.today.getTime();
    data.DocImage = _pre+"_"+files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(';base64,').pop();
      return;
    };
  }
  removeFileFirm(data: any, input: any) {
    input.value = "";
    data.DocImage = "";
    delete data.DocData;
  }
  getAge(event: any) {
    var date1 = new Date(this.customerModel.Customer_DOB);
    var date2 = new Date();

    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24); //Diference in Days
    var year = Days / 365;
    this.Age = Math.round(year);

    if(this.Age < 18){
      this.customerModel.Customer_DOB = '';
      this.Age = 0;
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Customer age should not be less than 18 years !!', ...this.configSuccess });
    }
    
    //console.log("age", this.Age);
  }
  checkPhonenumber() {
    if (
      this.customerModel.Customer_PhoneNo &&
      this.customerModel.Customer_PhoneNo.trim().length >= 10
    ) {
      this.duplicateType = "Mobile No";
      this.LOS_CheckCustomerDuplicationKYCApp({
        Type: "PhoneNo",
        value: this.customerModel.Customer_PhoneNo,
      },'');
    }
  }
  checkFirmPhonenumber() {
    if (
      this.customerModel.Firm_PhoneNo &&
      this.customerModel.Firm_PhoneNo.trim().length >= 10
    ) {
      this.duplicateType = "Mobile No";
      this.LMS_CheckFirmDuplicationKYCApp({
        Type: "PhoneNo",
        value: this.customerModel.Firm_PhoneNo,
      });
    }
  }

  checkDuplicate(data: any) {
    if (data.KYC_DocId != "" && data.KYC_DocNumber && data.KYC_DocNumber.trim().length > 0) {
      let _type: any = "";
      if (+data.KYC_DocId == 1) {
        _type = "PAN Card";
      }
      if (+data.KYC_DocId == 3) {
        _type = "Passport Copy";
      }
      if (+data.KYC_DocId == 4) {
        _type = "Voter ID Card";
      }
      if (+data.KYC_DocId == 5) {
        _type = "Driving License";
      }
      if (+data.KYC_DocId == 6) {
        _type = "Aadhaar Card";
      }
      this.duplicateType = _type;
      this.LOS_CheckCustomerDuplicationKYCApp({ Type: _type, value: data.KYC_DocNumber }, data);
    }
  }
  onRegisterNewCustomer() {
    if (this.customerlist.find((obj: any) => { return obj.CustomerType == 'Hirer' }) && this.customerModel.Customer_Type == 'H') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: 'Borrower Already Exist.', ...this.configSuccess });
      return;
    }
    this.customerModel.LoginUserId= this.currentUser.userId;
    if (this.customerModel.Type == 'Company') {
      let _data = {
        Firm: {
          ...this.customerModel
        },
        KYC_DOC: this.firmKYCDoc,
        Partners: this.firmPartners,
        address: this.customerAddressModel
      }
      if (_data.Firm.Customer_Type == "H") {
        _data.Firm.Customer_Relation == "Own";
      }
      /*if (_data.Firm.Customer_Type == "C") {
        _data.Firm.Customer_Relation = "CO-Borrower";
      }
      if (_data.Firm.Customer_Type == "G") {
        _data.Firm.Customer_Relation = "Guarantor";
      }*/

      this.showSpinner = true;
      _data.Firm.LoanId = this.loanSummary.LoanId;

      //console.log('_data Company', _data);
      this.LmsService.SaveCustomerFirmDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          this.customerAddressModel = '';
          this.customerPermanentAddressModel = '';
          this.uploadFirmDoc(res[0].CODE, this.firmKYCDoc);
          this.onCloseCustomer();
          this.onCloseDirector();
        }
        this.showSpinner = false;
      })
    }
    else {
      //this.customerModel.Customer_Gender = this.duplicateData.customer.Customer_Gender;
      this.customerWorkAddressModel = this.customerWorkAddressModel.StateId != '' ? this.customerWorkAddressModel : [];
      let _data = {
        ApplicationCustomer: {
          ...this.customerModel
        },
        CustomerKYCDoc: this.CustomerKYCDoc,
        address: this.customerAddressModel,
        customerPermanent: this.customerPermanentAddressModel,
        customerWork: this.customerWorkAddressModel,
        Int_Id: 0,
        LoanId: this.loanSummary.LoanId

      }
      if (_data.ApplicationCustomer.Customer_Type == "H") {
        _data.ApplicationCustomer.Customer_Relation == "Own";
      }
      
      /*if (_data.ApplicationCustomer.Customer_Type == "C") {
        _data.ApplicationCustomer.Customer_Relation = "CO-Borrower";
      }
      if (_data.ApplicationCustomer.Customer_Type == "G") {
        _data.ApplicationCustomer.Customer_Relation = "Guarantor";
      }*/

      this.showSpinner = true;


      _data.customerPermanent.District = Number(_data.customerPermanent.District);
      _data.customerPermanent.NoOfLiving = Number(_data.customerPermanent.NoOfLiving);
      _data.customerPermanent.Tehsil = Number(_data.customerPermanent.Tehsil);

      //console.log('_data', _data);
      this.LmsService.SaveCustomerInfoDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          if (this.customerModel.DocData) {
            this.uploadProfilePic(res[0].CODE);
          }
          this.uploadKycDoc(res[0].CODE, this.CustomerKYCDoc);
          this.uploadKycDoc1(res[0].CODE, this.CustomerKYCDoc);
          this.onCloseCustomer();
          this.onCloseDirector();
        }
        this.showSpinner = false;
      });
    }
  }

  onUpdateCustomerAddress() {
    if (this.customerModel.Type == 'Company') {
      let _data = {
        Firm: {
          ...this.customerModel
        },
        KYC_DOC: this.firmKYCDoc,
        Partners: this.firmPartners,
        address: this.customerAddressModel
      }
      if (_data.Firm.Customer_Type == "H") {
        _data.Firm.Customer_Relation == "Own";
      }

      /*if (_data.Firm.Customer_Type == "C") {
        _data.Firm.Customer_Relation = "CO-Borrower";
      }
      if (_data.Firm.Customer_Type == "G") {
        _data.Firm.Customer_Relation = "Guarantor";
      }*/

      this.showSpinner = true;
      _data.Firm.LoanId = this.loanSummary.LoanId;

      //console.log('_data Company', _data);
      this.LmsService.SaveCustomerFirmDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          this.customerAddressModel = '';
          this.customerPermanentAddressModel = '';
          this.uploadFirmDoc(res[0].CODE, this.firmKYCDoc);
        }
        this.showSpinner = false;
      })
    } else {
      let _data = {
        ApplicationCustomer: {
          ...this.customerModel
        },
        CustomerKYCDoc: this.CustomerKYCDoc,
        address: this.customerAddressModel,
        customerPermanent: this.customerPermanentAddressModel,
        customerWork: this.customerWorkAddressModel,
        Int_Id: this.customerModel.CustomerId,
        LoanId: this.loanSummary.LoanId
      }
      if (_data.ApplicationCustomer.Customer_Type == "H") {
        _data.ApplicationCustomer.Customer_Relation == "Own";
      }
      
      /*if (_data.ApplicationCustomer.Customer_Type == "C") {
        _data.ApplicationCustomer.Customer_Relation = "CO-Borrower";
      }
      if (_data.ApplicationCustomer.Customer_Type == "G") {
        _data.ApplicationCustomer.Customer_Relation = "Guarantor";
      }*/
      this.showSpinner = true;


      _data.customerPermanent.District = Number(_data.customerPermanent.District);
      _data.customerPermanent.NoOfLiving = Number(_data.customerPermanent.NoOfLiving);
      _data.customerPermanent.Tehsil = Number(_data.customerPermanent.Tehsil);

      // console.log('onUpdateCustomerAddress------', _data);
      this.LmsService.SaveCustomerInfoDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {

        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          this.isUpdateAddress = false;
          let ele = document.getElementById('btnSaveCust');
          ele.style.display = 'block';
          if (this.customerModel.DocData) {
            this.uploadProfilePic(res[0].CODE);
          }
          this.uploadKycDoc(res[0].CODE, this.CustomerKYCDoc);
          this.uploadKycDoc1(res[0].CODE, this.CustomerKYCDoc);
        }
        this.showSpinner = false;
      })
    }
  }

  onSAVEOTP() {
    let phoneNo = this.customerModel.Type == 'Indusial' ? this.customerModel.Customer_PhoneNo : this.customerModel.Firm_PhoneNo;
    this._MasterService.SAVE_OTP_Verification({ PhoneNo: phoneNo, OTPCode: this.OTP }).subscribe((res: any) => {
      if (res[0].CODE == 0) {
        if (this.customerModel.Type == 'Indusial') {
          this.customerModel.Customer_PhoneNo_IsVerified = true;
        }
        else {
          this.customerModel.Firm_PhoneNoIsVerified = true;
        }
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.OTP = "";
        $('#OTPModel').modal('hide');
        setTimeout(() => {
          $('body').addClass("modal-open");
        }, 1000)
      }
      else if (res[0].CODE != 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })

  }
  ongetotp() {
    if (this.customerModel.Type == 'Indusial') {
      if (this.customerModel.Customer_PhoneNo != undefined) {
        if (this.customerModel.Customer_PhoneNo.length == 10) {
          //this._MasterService.Generate_OTPPhoneNoVerification({ PhoneNo: this.customerModel.Customer_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0 }).subscribe((res: any) => {
          //  this.smsmodel.MobileNo = this.customerModel.Customer_PhoneNo;
          //  this.smsmodel.sentMessage = res[0].OTPCode + " is your OTP to validate your Mobile No. Finnaux Techsolutions Pvt. Ltd.";
          //  this._MasterService.SendSMS(this.smsmodel).subscribe((res: any) => {
          //    $('#OTPModel').modal('show');
          //    $("#OTPModel").css("z-index", "1050");
          //  })
          //})

          this._MasterService.Generate_OTPPhoneNoVerification({
            PhoneNo: this.customerModel.Customer_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0,
          }).subscribe((result: any) => {
            this.OTPCode = result[0].OTPCode;
            
            this._MasterService.Get_Sms_Configration({ Step_Name: 'Mobile_No_Validate' }).subscribe((res: any) => {
              if (res.length > 0) {
                this.smsmodel.MobileNo = this.customerModel.Customer_PhoneNo;
                res[0].SMS_Template = res[0].SMS_Template.replace('@OTP', this.OTPCode)
                res[0].Sub_url = res[0].Sub_url.replace('[KEY]', res[0].Key);
                res[0].Sub_url = res[0].Sub_url.replace('[CAMPAIGN]', res[0].campaign);
                res[0].Sub_url = res[0].Sub_url.replace('[ROUTE]', res[0].route);
                res[0].Sub_url = res[0].Sub_url.replace('[TYPE]', res[0].SubType);
                res[0].Sub_url = res[0].Sub_url.replace('[MOBILENO]', this.customerModel.Customer_PhoneNo);
                res[0].Sub_url = res[0].Sub_url.replace('[SENDERID]', res[0].Sender_Id);
                res[0].Sub_url = res[0].Sub_url.replace('[MSG]', res[0].SubType == 'unicode' ? this._MasterService.utf8Encode(res[0].SMS_Template) : res[0].SMS_Template);

                res[0].Sub_url = res[0].Sub_url.replace('[TEMPLATE_ID]', res[0].TemplateId);
                res[0].Sub_url = res[0].Sub_url.replace('\r', '');
                res[0].Sub_url = res[0].Sub_url.replace('\n', '');
                this.smsmodel.sentMessage = res[0].SMS_Template;
                this.smsmodel.SMS_URL = res[0].SMS_URL;
                this.smsmodel.Sub_url = res[0].Sub_url;
                
                this._MasterService.MessageSend(this.smsmodel).subscribe((res: any) => {
                  $("#OTPModel").modal("show");
                  $("#OTPModel").css("z-index", "1050");
                });
              }
            });
          });
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter valid phone no", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter phone no", ...this.configSuccess });
      }
    }
    else {
      if (this.customerModel.Firm_PhoneNo != undefined) {
        if (this.customerModel.Firm_PhoneNo.length == 10) {
          //this._MasterService.Generate_OTPPhoneNoVerification({ PhoneNo: this.customerModel.Firm_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0 }).subscribe((res: any) => {
          //  this.smsmodel.MobileNo = this.customerModel.Firm_PhoneNo;
          //  this.smsmodel.sentMessage = res[0].OTPCode + " is your OTP to validate your Mobile No. Finnaux Techsolutions Pvt. Ltd.";
          //  this._MasterService.SendSMS(this.smsmodel).subscribe((res: any) => {
          //    $('#OTPModel').modal('show');
          //    $("#OTPModel").css("z-index", "1050");
          //  })
          //})
          this._MasterService.Generate_OTPPhoneNoVerification({
              PhoneNo: this.customerModel.Firm_PhoneNo, LoginUserId: this.currentUser.userId, CustomerId: 0,
          }).subscribe((result: any) => {
            this.OTPCode = result[0].OTPCode;
            this._MasterService.Get_Sms_Configration({ Step_Name: 'Mobile_No_Validate' }).subscribe((res: any) => {
                if (res.length > 0) {
                  this.smsmodel.MobileNo = this.customerModel.Firm_PhoneNo;
                  res[0].SMS_Template = res[0].SMS_Template.replace('@OTP', this.OTPCode)
                  res[0].Sub_url = res[0].Sub_url.replace('[KEY]', res[0].Key);
                  res[0].Sub_url = res[0].Sub_url.replace('[CAMPAIGN]', res[0].campaign);
                  res[0].Sub_url = res[0].Sub_url.replace('[ROUTE]', res[0].route);
                  res[0].Sub_url = res[0].Sub_url.replace('[TYPE]', res[0].SubType);
                  res[0].Sub_url = res[0].Sub_url.replace('[MOBILENO]', this.customerModel.Firm_PhoneNo);
                  res[0].Sub_url = res[0].Sub_url.replace('[SENDERID]', res[0].Sender_Id);
                  res[0].Sub_url = res[0].Sub_url.replace('[MSG]', res[0].SubType == 'unicode' ? this._MasterService.utf8Encode(res[0].SMS_Template) : res[0].SMS_Template);

                  res[0].Sub_url = res[0].Sub_url.replace('[TEMPLATE_ID]', res[0].TemplateId);
                  res[0].Sub_url = res[0].Sub_url.replace('\r', '');
                  res[0].Sub_url = res[0].Sub_url.replace('\n', '');
                  this.smsmodel.sentMessage = res[0].SMS_Template;
                  this.smsmodel.SMS_URL = res[0].SMS_URL;
                  this.smsmodel.Sub_url = res[0].Sub_url;
                  
                  this._MasterService.MessageSend(this.smsmodel).subscribe((res: any) => {
                    $("#OTPModel").modal("show");
                    $("#OTPModel").css("z-index", "1050");
                  });
                }
              });
          });
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter valid phone no", ...this.configSuccess });
        }
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter phone no", ...this.configSuccess });
      }
    }
  }


  onUpdateCustomer() {

    if (this.customerModel.Type == 'Company') {
      let _data = {
        Firm: {
          ...this.customerModel
        },
        Partners: this.firmPartners
      }
      if (_data.Firm.Customer_Type == "H") {
        _data.Firm.Customer_Relation == "Own";
      }
      _data.Firm.LoanId = this.loanSummary.LoanId;

      this.showSpinner = true;
      this.LmsService.SaveCustomerFirmDetails({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          this.uploadFirmDoc(this.customerModel.FirmId, this.firmKYCDoc);
        }
        this.showSpinner = false;
      })
    }
    else {
      let _data = {
        ApplicationCustomer: {
          ...this.customerModel
        }
      }
      if (_data.ApplicationCustomer.Customer_Type == "H") {
        _data.ApplicationCustomer.Customer_Relation == "Own";
      }
      this.showSpinner = true;
      this.losService.LOS_UpdateCustomerDetailsApp({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
          this.LMS_GetLoanDetails();
          if (this.customerModel.DocData) {
            this.uploadProfilePic(_data.ApplicationCustomer.CustomerId);
            this.onCloseCustomer();
          }
          else {
            this.onCloseCustomer();
          }
        }
        this.showSpinner = false;
      })
    }
  }
  uploadProfilePic(id: any) {
    this.losService.UploadCustomerDoc({ CustomerID: id, DocName: this.customerModel.Customer_ProfilePic, DocData: this.customerModel.DocData }).subscribe((res: any) => {

    });
  }
  uploadFirmDoc(id: any, data: any[]) {
    this.onCloseCustomer();
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService.UploadCustomerDoc({ CustomerID: id, DocName: obj.DocImage, DocData: obj.DocData }).subscribe((res: any) => {

        });
      }
    });
  }
  uploadKycDoc(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData) {
        this.losService.UploadCustomerDoc({ CustomerID: id, DocName: obj.KYC_DocFile, DocData: obj.DocData }).subscribe((res: any) => {

        });
      }
    });
    this.onCloseCustomer();
  }
  uploadKycDoc1(id: any, data: any[]) {
    data.forEach((obj: any) => {
      if (obj.DocData1) {
        this.losService.UploadCustomerDoc({ CustomerID: id, DocName: obj.KYC_DocFile, DocData: obj.DocData1 }).subscribe((res: any) => {

        });
      }
    });
    this.onCloseCustomer();
  }
  LOS_CheckCustomerDuplicationKYCApp(data: any, row: any) {
    this.LmsService.LMS_CheckCustomerDuplicationKYCApp(data).subscribe((res: any) => {
      //console.log('LMS_CheckCustomerDuplicationKYCApp', res);
      if (res.Item1[0].CustomerId > 0) {
        this.Duplicate = res.Item1;
        this.duplicateData = { customer: res.Item1[0], kycDetails: res.Item2, address: res.Item3, bankDetail: res.Item4 };
        //console.log('LOS_CheckCustomerDuplicationKYCApp======>>>', res);
        if (row != '') {
          if (row.KYC_DocId == 1 || row.KYC_DocId == 3 || row.KYC_DocId == 4 || row.KYC_DocId == 5 || row.KYC_DocId == 6 || row.KYC_DocId == 28 || row.KYC_DocId == 29) {
            row.KYC_DocNumber = '';
            this.NotUpdate = true

          } else {
            this.NotUpdate = false


          }
        }
        this.onCloseKYC();
        $(`#duplicateViewData`).modal('show');
        $(`#duplicateViewData`).css("z-index", "1060");

      }
    })
  }
  LMS_CheckFirmDuplicationKYCApp(data: any) {
    this.LmsService.LMS_CheckFirm_DuplicationApp(data).subscribe((res: any) => {
      if (res.Item1[0].CustomerId > 0) {
        this.duplicateFirmData = { customer: res.Item1[0], kycDetails: res.Item2, address: res.Item3, patner: res.Item4 };
        //console.log('LMS_CheckFirm_DuplicationApp======>>>', res);
        $('#duplicateViewFirmData').modal('show');
        $("#duplicateViewFirmData").css("z-index", "1060");
      }
    })
  }
  onNoClick() {
    $('#duplicateViewData').modal('hide');
    this.duplicateData = undefined;
    this.duplicateType = undefined;
    setTimeout(() => {
      $('body').addClass("modal-open");
    }, 1000)
  }
  onNoFirmClick() {
    $('#duplicateViewFirmData').modal('hide');
    this.duplicateFirmData = undefined;
    this.duplicateType = undefined;
    setTimeout(() => {
      $('body').addClass("modal-open");
    }, 1000)
  }
  onYesClick() {
    this.isUpdateAddress = true;
    let ele = document.getElementById('btnSaveCust');
    ele.style.display = 'none';
    this.customerModel.CustomerId = this.duplicateData.customer.CustomerId;
    this.customerModel.Customer_FirstName = this.duplicateData.customer.Customer_FirstName;
    this.customerModel.Customer_LastName = this.duplicateData.customer.Customer_LastName;
    this.customerModel.Customer_FatherName = this.duplicateData.customer.Customer_FatherName;
    this.customerModel.Customer_Gender = this.duplicateData.customer.Customer_Gender;
    this.customerModel.Customer_Occupation = this.duplicateData.customer.Customer_Occupation || "";
    this.customerModel.Customer_DOB = this.duplicateData.customer.Customer_DOB ? new Date(this.duplicateData.customer.Customer_DOB) : "";
    this.customerModel.Customer_ProfilePic = this.duplicateData.customer.Customer_ProfilePic;
    this.customerModel.Customer_PhoneNo = this.duplicateData.customer.Customer_PhoneNo;
    this.customerModel.Customer_PhoneNo1 = this.duplicateData.customer.Customer_PhoneNo1;
    this.customerModel.Customer_PhoneNo2 = this.duplicateData.customer.Customer_PhoneNo2;
    this.customerModel.Customer_WhatsAppNo = this.duplicateData.customer.Customer_WhatsAppNo;
    this.customerModel.Customer_PhoneNo_IsVerified = this.duplicateData.customer.Customer_PhoneNo_IsVerified;
    this.customerModel.Customer_Email = this.duplicateData.customer.Customer_Email;
    this.customerModel.Customer_MaritalStatus = this.duplicateData.customer.Customer_MaritalStatus || "";
    this.customerModel.Customer_Religion = this.duplicateData.customer.Customer_Religion || "";
    this.customerModel.Customer_Cast = this.duplicateData.customer.Customer_Cast || "";
    this.customerModel.Customer_Relation_Type = this.duplicateData.customer.Customer_Relation_Type || "";
    this.CustomerKYCDoc = this.duplicateData.kycDetails;

    if (this.duplicateData.address.length > 0) {
      this.duplicateData.address.forEach(element => {
        if (element.AddressType == "Present") {
          this.customerAddressModel.Address = element.Address;
          this.customerAddressModel.LandMark = element.Landmark;
          this.customerAddressModel.PinCode = element.Pincode;
          this.customerAddressModel.StateId = element.StateId;

          this.losService.GetDistrickDropdown({ StateID: element.StateId }).subscribe((res: any) => {
            this.districkDropdownPresent = res;
            this.customerAddressModel.DistrictId = element.DistrictId;
          });

          this.losService.GetTehasilDropdown({ DistrictId: element.DistrictId }).subscribe((res: any) => {
            this.tehasilDropdownPresent = res;
            this.customerAddressModel.TehsilId = element.TehsilId;
          });

          this.customerAddressModel.NoOfLiving = element.TotalYearsOnAddress;
          this.customerAddressModel.RentOwn = element.AddressRentBuy;
          this.customerAddressModel.DistaneBranch = element.NearstBranchDistance_KM;
        }
        if (element.AddressType == "Permanent") {
          this.customerPermanentAddressModel.Address = element.Address;
          this.customerPermanentAddressModel.LandMark = element.Landmark;
          this.customerPermanentAddressModel.PinCode = element.Pincode;
          this.customerPermanentAddressModel.StateId = element.StateId;

          this.losService.GetDistrickDropdown({ StateID: element.StateId }).subscribe((res: any) => {
            this.districkDropdownPermanent = res;
            this.customerPermanentAddressModel.DistrictId = element.DistrictId;
          });

          this.losService.GetTehasilDropdown({ DistrictId: element.DistrictId }).subscribe((res: any) => {
            this.tehasilDropdownPermanent = res;
            this.customerPermanentAddressModel.TehsilId = element.TehsilId;
          });

          this.customerPermanentAddressModel.NoOfLiving = element.TotalYearsOnAddress;
          this.customerPermanentAddressModel.RentOwn = element.AddressRentBuy;
          this.customerAddressModel.DistaneBranch = element.NearstBranchDistance_KM;
        }
        if (element.AddressType == "Work") {
          this.customerWorkAddressModel.Address = element.Address;
          this.customerWorkAddressModel.LandMark = element.Landmark;
          this.customerWorkAddressModel.PinCode = element.Pincode;
          this.customerWorkAddressModel.StateId = element.StateId;
          this.losService.GetDistrickDropdown({ StateID: element.StateId }).subscribe((res: any) => {
            this.districkDropdownWork = res;
            this.customerWorkAddressModel.DistrictId = element.DistrictId;
          });

          this.losService.GetTehasilDropdown({ DistrictId: element.DistrictId }).subscribe((res: any) => {
            this.tehasilDropdownWork = res;
            this.customerWorkAddressModel.TehsilId = element.TehsilId;
          });
          this.customerWorkAddressModel.NoOfLiving = element.TotalYearsOnAddress;
          this.customerWorkAddressModel.RentOwn = element.AddressRentBuy;
          this.customerWorkAddressModel.DistaneBranch = element.NearstBranchDistance_KM;
        }
      });
    }

    this.onNoClick();
  }

  onYesFirmClick() {
    this.isUpdateAddress = true;
    let ele = document.getElementById('btnSaveCust');
    ele.style.display = 'none';

    // this.isExistingCustomer = false;
    // this.customerModel.Type = "Company";

    this.customerModel.CustomerId = this.duplicateFirmData.customer.CustomerId;
    this.customerModel.Firm_Type = this.duplicateFirmData.customer.Customer_FirmType;
    this.customerModel.Firm_Name = this.duplicateFirmData.customer.Customer_FirstName;
    this.customerModel.Firm_Email = this.duplicateFirmData.customer.Customer_Email;
    this.customerModel.Firm_Date_Of_Incorruptions = this.duplicateFirmData.customer.Customer_Date_Of_Incorruptions;
    this.customerModel.Firm_No_Of_Partner = this.duplicateFirmData.customer.Customer_NoOfPartner;
    this.customerModel.Firm_No_Of_Employee = this.duplicateFirmData.customer.Customer_NoofEmployee;
    this.customerModel.Firm_GrossValue = this.duplicateFirmData.customer.Customer_GrossValue;
    this.customerModel.Firm_Nature_Of_Business = this.duplicateFirmData.customer.Customer_Nature_Of_Business;
    // this.firmKYCDoc = this.duplicateFirmData.kycDetails;
    this.firmKYCDoc = [];
    this.duplicateFirmData.kycDetails.forEach(element => {
      this.firmKYCDoc.push({
        DocId: element.DocId,
        DocNo: element.KYC_DocNumber,
        DocImage: element.KYC_DocFile,
        Doc_Name: element.Doc_Name,
        KYC_CustomerId: element.KYC_CustomerId,
      });
    });

    if (this.duplicateFirmData.address.length > 0) {
      this.duplicateFirmData.address.forEach(element => {
        if (element.AddressType == "Registered") {
          this.customerModel.Firm_RegAddress = element.Address;
          this.customerModel.Firm_RegLandMark = element.Landmark;

          this.losService.GetDistrickDropdown({ StateID: element.StateId }).subscribe((res: any) => {
            this.districkDropdownReg = res;
            this.customerModel.Firm_RegDistrict = element.DistrictId;
          });

          this.losService.GetTehasilDropdown({ DistrictId: element.DistrictId }).subscribe((res: any) => {
            this.tehasilDropdownReg = res;
            this.customerModel.Firm_RegTehsil = element.TehsilId;
          });

          this.customerModel.Firm_RegPinCode = element.Pincode;
        }
        if (element.AddressType == "Corporate") {
          this.customerModel.Firm_CorpAddress = element.Address;
          this.customerModel.Firm_CorpLandMark = element.Landmark;

          this.losService.GetDistrickDropdown({ StateID: element.StateId }).subscribe((res: any) => {
            this.districkDropdownCorp = res;
            this.customerModel.Firm_CorpDistrict = element.DistrictId;
          });

          this.losService.GetTehasilDropdown({ DistrictId: element.DistrictId }).subscribe((res: any) => {
            this.tehasilDropdownCorp = res;
            this.customerModel.Firm_CorpTehsil = element.TehsilId;
          });

          this.customerModel.Firm_CorpPinCode = element.Pincode;
        }
      });
    }

    if (this.duplicateFirmData.patner.length > 0) {
      this.firmPartners = [];
      this.duplicateFirmData.patner.forEach(element => {
        this.firmPartners.push({
          PartnerName: element.Partner_Name,
          PartnerGender: element.Partner_Gender,
          PartnerAge: element.Partner_Age,
          PartnerDesignation: element.Partner_Designation,
          PartnerPhoneNo: element.Partner_PhoneNo,
          PartnerShare: element.Partner_SharePer,
        });
      });

    }

    this.onNoFirmClick();
  }


  Get_CustomerDetailsApp(data: any) {
    this.GetStateDropdown(false);
    this.GetTehasilDropdownReg(false);
    this.GetDistrickDropdownCorp(false);
    this.GetTehasilDropdownCorp(false);
    if (!data.Customer_IsFirm) {
      this.LmsService.Get_Customer({ Loan_Id: this.loanSummary.LoanId, CustomerId: data.CustomerId }).subscribe((res: any) => {
        let _data: any = res[0];
        console.log("Get_Customer", res[0]);
        this.customerModel = { ApplicationId: this.applicationId, Type: "Indusial", LoginUserId: this.currentUser.userId };
        this.customerModel.CustomerId = _data.CustomerId;
        this.customerModel.Customer_Type = _data.CustomerType;
        this.customerModel.Customer_Relation = _data.CustomerRelation;
        this.customerModel.Customer_FirstName = _data.Customer_FirstName;
        this.customerModel.Customer_LastName = _data.Customer_LastName;
        this.customerModel.Customer_FatherName = _data.Customer_FatherName;
        this.customerModel.Customer_Gender = _data.Customer_Gender;
        this.customerModel.Customer_Occupation = _data.Customer_Occupation || "";
        this.customerModel.Customer_DOB = _data.Customer_DOB
          ? moment(_data.Customer_DOB, "DD/MM/YYYY").toDate()
          : moment(this.defaultDob, "DD/MM/YYYY").toDate()
        var date1 = new Date(this.customerModel.Customer_DOB);
        var date2 = new Date();

        var Time = date2.getTime() - date1.getTime();
        var Days = Time / (1000 * 3600 * 24); //Diference in Days
        var year = Days / 365;
        this.Age = Math.round(year);
        this.customerModel.Customer_ProfilePic = _data.Customer_ProfilePic;
        this.customerModel.Customer_PhoneNo = _data.Customer_PhoneNo;
        this.customerModel.Customer_PhoneNo1 = _data.Customer_PhoneNo1;
        this.customerModel.Customer_PhoneNo2 = _data.Customer_PhoneNo2;
        this.customerModel.Customer_WhatsAppNo = _data.Customer_WhatsAppNo;
        this.customerModel.Customer_PhoneNo_IsVerified = _data.Customer_PhoneNo_IsVerified;
        this.customerModel.Customer_Email = _data.Customer_Email;
        this.customerModel.Customer_MaritalStatus = _data.Customer_MaritalStatus || "";
        this.customerModel.Customer_Religion = _data.Customer_Religion || "";
        this.customerModel.Customer_Cast = _data.Customer_Cast || "";
        this.isCustomerEdit = true;
        this.isExistingCustomer = false;
        this.customerModel.Customer_Relation_Type = _data.Customer_Relation;
        this.customerModel.Customer_Relation_FirstName = _data.Customer_Relation_FirstName;
        this.customerModel.Customer_Relation_LastName = _data.Customer_Relation_LastName;
        $('#addCustomer').modal('show');
        $("#addCustomer").css("z-index", "1050");
      })
    }
    else {
      this.LmsService.GetLoanDetailsForSaleProcess({ LoanId: this.loanSummary.LoanId, CId: Number(data.CustomerId) }).subscribe((res: any) => {
        let _data: any = res.Item1[0];
        this.customerModel = { ApplicationId: this.applicationId, SameAddress: false, Type: "Company", Customer_Type: 'H', Customer_Relation: 'Own', LoginUserId: this.currentUser.userId };
        this.customerModel.ApplicationNo = _data.ApplicationNo;
        this.customerModel.FirmId = _data.FirmId;
        this.customerModel.Firm_Name = _data.Firm_Name;
        this.customerModel.Firm_Type = _data.Firm_Type;
        this.customerModel.Firm_RegAddress = _data.Firm_RegAddress;
        this.customerModel.Firm_RegLandMark = _data.Firm_RegLandMark;
        this.customerModel.Firm_Date_Of_Incorruptions = _data.Firm_Date_Of_Incorruptions ? new Date(_data.Firm_Date_Of_Incorruptions) : "";
        this.customerModel.Firm_RegPinCode = _data.Firm_RegPinCode;
        this.customerModel.Firm_RegState = _data.Firm_RegState;
        this.customerModel.Firm_RegDistrict = _data.Firm_RegDistrict;
        this.customerModel.Firm_RegTehsil = _data.Firm_RegTehsil;
        this.customerModel.Firm_CorpAddress = _data.Firm_CorpAddress;
        this.customerModel.Firm_CorpLandMark = _data.Firm_CorpLandMark;
        this.customerModel.Firm_CorpPinCode = _data.Firm_CorpPinCode;
        this.customerModel.Firm_CorpState = _data.Firm_CorpState;
        this.customerModel.Firm_CorpDistrict = _data.Firm_CorpDistrict;
        this.customerModel.Firm_CorpTehsil = _data.Firm_CorpTehsil;
        this.customerModel.Firm_PhoneNo = _data.Firm_PhoneNo;
        this.customerModel.Firm_PhoneNoIsVerified = _data.Firm_PhoneNoIsVerified;
        this.customerModel.Firm_PhoneNo1 = _data.Firm_PhoneNo1;
        this.customerModel.Firm_Email = _data.Firm_Email;
        this.customerModel.Firm_Website = _data.Firm_Website;
        this.customerModel.Firm_No_Of_Partner = _data.Firm_No_Of_Partner;
        this.customerModel.Firm_No_Of_Employee = _data.Firm_No_Of_Employee;
        this.customerModel.Firm_GrossValue = _data.Firm_GrossValue;
        this.customerModel.Firm_Nature_Of_Business = _data.Firm_Nature_Of_Business;

        this.customerModel.RegTotalYearsOnAddress = _data.RegTotalYearsOnAddress;
        this.customerModel.RegAddressRentBuy = _data.RegAddressRentBuy;
        this.customerModel.RegNearstBranchDistance_KM = _data.RegNearstBranchDistance_KM;

        this.customerModel.CorpTotalYearsOnAddress = _data.CorpTotalYearsOnAddress;
        this.customerModel.CorpAddressRentBuy = _data.CorpAddressRentBuy;
        this.customerModel.CorpNearstBranchDistance_KM = _data.CorpNearstBranchDistance_KM;

        this.isCustomerEdit = true;
        this.isExistingCustomer = false;
        this.firmPartners = [];
        res.Item2.forEach((obj: any) => {
          this.firmPartners.push({ PartnerName: obj.Partner_Name, PartnerGender: obj.Partner_Gender, PartnerAge: obj.Partner_Age, PartnerDesignation: obj.Partner_Designation, PartnerPhoneNo: obj.Partner_PhoneNo, PartnerShare: obj.Partner_SharePer });
        });
        this.GetStateDropdown(false);
        this.GetTehasilDropdownReg(false);
        this.GetDistrickDropdownCorp(false);
        this.GetTehasilDropdownCorp(false);
        $('#addCustomer').modal('show');
        $("#addCustomer").css("z-index", "1050");

      })

    }
  }
  GetStateDropdown(reset: boolean = true) {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
      //console.log('GetStateDropdown', res);
      if (reset) {
        this.customerModel.Firm_RegState = 29;
        this.customerModel.Firm_CorpState = 29;
      }
      this.GetDistrickDropdownReg(reset);
      this.GetDistrickDropdownCorp(reset);
    });
  }
  GetDistrickDropdownReg(reset: boolean = true) {
    if (reset) {
      this.customerModel.Firm_RegDistrict = "";
      this.customerModel.Firm_RegTehsil = "";
    }
    this.losService.GetDistrickDropdown({ StateID: this.customerModel.Firm_RegState }).subscribe((res: any) => {
      this.districkDropdownReg = res;
      this.GetTehasilDropdownReg(reset);
      this.GetTehasilDropdownCorp(reset);
    });
  }
  GetTehasilDropdownReg(reset: boolean = true) {
    if (reset) {
      this.customerModel.Firm_RegTehsil = "";
    }
    this.losService.GetTehasilDropdown({ DistrictId: this.customerModel.Firm_RegDistrict }).subscribe((res: any) => {
      this.tehasilDropdownReg = res;
    });
  }
  GetDistrickDropdownCorp(reset: boolean = true) {
    if (reset) {
      this.customerModel.Firm_CorpDistrict = "";
      this.customerModel.Firm_CorpTehsil = "";
    }
    this.losService.GetDistrickDropdown({ StateID: this.customerModel.Firm_CorpState }).subscribe((res: any) => {
      this.districkDropdownCorp = res;
    });
  }
  GetTehasilDropdownCorp(reset: boolean = true) {
    if (reset) {
      this.customerModel.Firm_CorpTehsil = "";
    }
    this.losService.GetTehasilDropdown({ DistrictId: this.customerModel.Firm_CorpDistrict }).subscribe((res: any) => {
      this.tehasilDropdownCorp = res;
    });
  }
  checkAddressSame(event: any) {
    if (this.customerModel.SameAddress) {
      this.customerModel.Firm_CorpAddress = this.customerModel.Firm_RegAddress;
      this.customerModel.Firm_CorpLandMark = this.customerModel.Firm_RegLandMark;
      this.customerModel.Firm_CorpState = this.customerModel.Firm_RegState;
      this.customerModel.Firm_CorpDistrict = this.customerModel.Firm_RegDistrict;
      this.customerModel.Firm_CorpTehsil = this.customerModel.Firm_RegTehsil;
      this.customerModel.Firm_CorpPinCode = this.customerModel.Firm_RegPinCode;

      this.customerModel.CorpTotalYearsOnAddress = this.customerModel.RegTotalYearsOnAddress;
      this.customerModel.CorpAddressRentBuy = this.customerModel.RegAddressRentBuy;
      this.customerModel.CorpNearstBranchDistance_KM = this.customerModel.RegNearstBranchDistance_KM;

      this.districkDropdownCorp = this.districkDropdownReg;
      this.tehasilDropdownCorp = this.tehasilDropdownReg;
    }
    else {
      this.customerModel.Firm_CorpAddress = "";
      this.customerModel.Firm_CorpLandMark = "";
      // this.customerModel.Firm_CorpState = "";
      this.customerModel.Firm_CorpDistrict = "";
      this.customerModel.Firm_CorpTehsil = "";
      this.customerModel.Firm_CorpPinCode = "";

      this.customerModel.CorpTotalYearsOnAddress = "";
      this.customerModel.CorpAddressRentBuy = "";
      this.customerModel.CorpNearstBranchDistance_KM = "";

      this.districkDropdownCorp = [];
      this.tehasilDropdownCorp = [];
    }
  }
  LOS_GetCustomerAddressWeb(data: any) {
    this.LmsService.LMS_GetCustomerAddressWeb({ CustomerId: data.CustomerId }).subscribe((res: any) => {
      //console.log("address edit", res.Item2);
      this.addressModel.customerDetail = res.Item1[0] || {};
      if (!data.Customer_IsFirm) {
        let _SA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Permanent' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Permanent', AddressRentBuy: "", IsCommunicationAddress: false, StateId: "", DistrictId: "", TehsilId: "" };
        let _PA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Present' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Present', AddressRentBuy: "", IsCommunicationAddress: false, StateId: 29, DistrictId: "", TehsilId: "" };
        let _WA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Work' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Work', AddressRentBuy: "", IsCommunicationAddress: false, StateId: "", DistrictId: "", TehsilId: "" };
        //console.log(res.Item2);
        //console.log("_SA", _SA);
        //console.log("PA", _PA);
        //console.log("WA", _WA);
        this.addressModel.permanent = _SA;
        this.addressModel.SameAddress = false;
        this.addressModel.present = _PA;
        this.addressModel.work = _WA;
        this.GetStateDropdownAddress();
        if (_SA.StateId) {
          this.GetDistrickDropdownPermanent1(false);
        }
        if (_PA.StateId) {
          this.GetDistrickDropdownPresent1(false);
        }
        if (_WA.StateId) {
          this.GetDistrickDropdownWork1(false);
        }
        if (_SA.DistrictId) {
          this.GetTehasilDropdownPermanent1(false);
        }
        if (_PA.DistrictId) {
          this.GetTehasilDropdownPresent1(false);
        }
        if (_WA.DistrictId) {
          this.GetTehasilDropdownWork1(false);
        }
        $('#addressModel').modal('show');
        $("#addressModel").css("z-index", "1050");
      }
      else {
        this.addressModel.customerDetail.IsFirm = true;
        let _SA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Registered' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Registered', AddressRentBuy: "", IsCommunicationAddress: false, StateId: "", DistrictId: "", TehsilId: "" };
        let _PA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Corporate' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Corporate', AddressRentBuy: "", IsCommunicationAddress: false, StateId: 29, DistrictId: "", TehsilId: "" };
        //let _WA: any = res.Item2.find((obj: any) => { return obj.AddressType == 'Work' }) || { AddressId: 0, CustomerId: data.CustomerId, AddressType: 'Work', AddressRentBuy: "", IsCommunicationAddress: false, StateId: "", DistrictId: "", TehsilId: "" };
        this.addressModel.permanent = _SA;
        this.addressModel.SameAddress = false;
        this.addressModel.present = _PA;

        this.GetStateDropdownAddress();
        if (_SA.StateId) {
          this.GetDistrickDropdownPermanent(false);
        }
        if (_PA.StateId) {
          this.GetDistrickDropdownPresent(false);
        }
        if (_SA.DistrictId) {
          this.GetTehasilDropdownPermanent(false);
        }
        if (_PA.DistrictId) {
          this.GetTehasilDropdownPresent(false);
        }
        $('#addressModel').modal('show');
        $("#addressModel").css("z-index", "1050");
      }
    })
  }
  onCloseAddress() {
    $('#addressModel').modal('hide');
  }
  onAddCustomerAddress() {
    let _data: any = { Address: [] };

    //console.log('this.addressModel', this.addressModel.permanent.TehsilId, this.addressModel);
    _data.Address.push(this.addressModel.present);
    if (this.addressModel.permanent.Address && this.addressModel.permanent.Address.trim().length > 0) {
      if (!this.addressModel.permanent.LandMark || this.addressModel.permanent.LandMark.trim().length == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Landmark is required for permanent address.", ...this.configSuccess });
        return;
      }
      if (!this.addressModel.permanent.PinCode || this.addressModel.permanent.PinCode.trim().length == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "PinCode is required for permanent address.", ...this.configSuccess });
        return;
      }
      if (!this.addressModel.permanent.TehsilId || this.addressModel.permanent.TehsilId == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "State ,District and tehsil are required for permanent address.", ...this.configSuccess });
        return;
      }
      //if (!this.addressModel.permanent.TotalYearsOnAddress || this.addressModel.permanent.TotalYearsOnAddress == 0) {
      //    this.snackBar.openFromComponent(SnackbarComponent, { data: "TotalYearsOnAddress is required for permanent address.", ...this.configSuccess });
      //    return;
      //}
      if (!this.addressModel.permanent.AddressRentBuy || this.addressModel.permanent.AddressRentBuy.trim().length == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Rent/Buy is required for permanent address.", ...this.configSuccess });
        return;
      }
      if (!this.addressModel.permanent.NearstBranchDistance_KM || this.addressModel.permanent.NearstBranchDistance_KM == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Nearst Branch Distance is required for permanent address.", ...this.configSuccess });
        return;
      }
      _data.Address.push(this.addressModel.permanent);
    }
    if (!this.addressModel.customerDetail.IsFirm) {
      if (this.addressModel.work.Address && this.addressModel.work.Address.trim().length > 0) {
        if (!this.addressModel.work.LandMark || this.addressModel.work.LandMark.trim().length == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Landmark is required for work address.", ...this.configSuccess });
          return;
        }
        if (!this.addressModel.work.PinCode || this.addressModel.work.PinCode.trim().length == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "PinCode is required for work address.", ...this.configSuccess });
          return;
        }
        if (!this.addressModel.work.TehsilId || this.addressModel.work.TehsilId == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "State ,District and tehsil are required for work address.", ...this.configSuccess });
          return;
        }
        if (!this.addressModel.work.TotalYearsOnAddress || this.addressModel.work.TotalYearsOnAddress == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "TotalYearsOnAddress is required for work address.", ...this.configSuccess });
          return;
        }
        if (!this.addressModel.work.AddressRentBuy || this.addressModel.work.AddressRentBuy == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Rent/Buy is required for work address.", ...this.configSuccess });
          return;
        }
        if (!this.addressModel.work.NearstBranchDistance_KM || this.addressModel.work.NearstBranchDistance_KM == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "Nearst Branch Distance is required for work address.", ...this.configSuccess });
          return;
        }
        _data.Address.push(this.addressModel.work);
      }
    }
    this.showSpinner = true;
    this.losService.LOS_UpdateCustomerAddressWeb({ JSON: JSON.stringify(_data) }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LMS_GetLoanDetails();
        this.onCloseAddress();
      }
      this.showSpinner = false;
    })
  }
  GetStateDropdownAddress() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }
  GetDistrickDropdownPresent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.present.DistrictId = "";
    //     this.addressModel.present.TehsilId = "";
    // }
    this.losService.GetDistrickDropdown({ StateID: this.customerAddressModel.StateId }).subscribe((res: any) => {
      this.districkDropdownPresent = res;
    });
  }
  GetTehasilDropdownPresent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.present.TehsilId = "";
    // District
    this.losService.GetTehasilDropdown({ DistrictId: this.customerAddressModel.DistrictId }).subscribe((res: any) => {
      this.tehasilDropdownPresent = res;
    });
  }


  GetDistrickDropdownPermanent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.permanent.DistrictId = "";
    //     this.addressModel.permanent.TehsilId = "";
    // }
    this.losService.GetDistrickDropdown({ StateID: this.customerPermanentAddressModel.StateId }).subscribe((res: any) => {
      this.districkDropdownPermanent = res;
    });
  }
  GetTehasilDropdownPermanent(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.permanent.TehsilId = "";
    // }
    this.losService.GetTehasilDropdown({ DistrictId: this.customerPermanentAddressModel.DistrictId }).subscribe((res: any) => {
      this.tehasilDropdownPermanent = res;
    });
  }
  GetDistrickDropdownWork(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.work.DistrictId = "";
    //     this.addressModel.work.TehsilId = "";
    // }
    this.losService.GetDistrickDropdown({ StateID: this.customerWorkAddressModel.StateId }).subscribe((res: any) => {
      this.districkDropdownWork = res;
    });
  }
  GetTehasilDropdownWork(reset: boolean = true) {
    // if (reset) {
    //     this.addressModel.work.TehsilId = "";
    // }
    this.losService.GetTehasilDropdown({ DistrictId: this.customerWorkAddressModel.DistrictId }).subscribe((res: any) => {
      this.tehasilDropdownWork = res;
    });
  }

  checkCAddressSame1(event: any) {
    //console.log(this.customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress);
    if (this.customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress) {
      this.customerPermanentAddressModel.Address = this.customerAddressModel.Address;
      this.customerPermanentAddressModel.LandMark = this.customerAddressModel.LandMark;
      this.customerPermanentAddressModel.StateId = this.customerAddressModel.StateId;
      this.customerPermanentAddressModel.DistrictId = this.customerAddressModel.DistrictId;
      this.customerPermanentAddressModel.TehsilId = this.customerAddressModel.TehsilId;
      this.customerPermanentAddressModel.PinCode = this.customerAddressModel.PinCode;
      this.customerPermanentAddressModel.DistanceBranch = this.customerAddressModel.DistaneBranch;
      this.customerPermanentAddressModel.NoOfLiving = this.customerAddressModel.NoOfLiving;
      this.customerPermanentAddressModel.RentOwn = this.customerAddressModel.RentOwn;
      this.districkDropdownPermanent = this.districkDropdownPresent;
      this.tehasilDropdownPermanent = this.tehasilDropdownPresent;

      this.customerPermanentAddressModel.NearstBranchDistance_KM = "";
      this.customerPermanentAddressModel.TotalYearsOnAddress = "";
      this.customerPermanentAddressModel.AddressRentBuy = "";
    }
    else {
      this.customerPermanentAddressModel.Address = "";
      this.customerPermanentAddressModel.LandMark = "";
      this.customerPermanentAddressModel.StateId = "";
      this.customerPermanentAddressModel.DistrictId = "";
      this.customerPermanentAddressModel.TehsilId = "";
      this.customerPermanentAddressModel.PinCode = "";
      this.customerPermanentAddressModel.NearstBranchDistance_KM = "";
      this.customerPermanentAddressModel.TotalYearsOnAddress = "";
      this.customerPermanentAddressModel.AddressRentBuy = "";
      this.districkDropdownPermanent = [];
      this.tehasilDropdownPermanent = [];
    }
  }
  checkCAddressSame(event: any) {
    if (this.addressModel.SameAddress) {
      this.addressModel.permanent.Address = this.addressModel.present.Address;
      this.addressModel.permanent.LandMark = this.addressModel.present.LandMark;
      this.addressModel.permanent.StateId = this.addressModel.present.StateId;
      this.addressModel.permanent.DistrictId = this.addressModel.present.DistrictId;
      this.addressModel.permanent.TehsilId = this.addressModel.present.TehsilId;
      this.addressModel.permanent.PinCode = this.addressModel.present.PinCode;
      this.addressModel.permanent.NearstBranchDistance_KM = this.addressModel.present.NearstBranchDistance_KM;
      this.addressModel.permanent.TotalYearsOnAddress = this.addressModel.present.TotalYearsOnAddress;
      this.addressModel.permanent.AddressRentBuy = this.addressModel.present.AddressRentBuy;
      this.districkDropdownPermanent = this.districkDropdownPresent;
      this.tehasilDropdownPermanent = this.tehasilDropdownPresent;
    }
    else {
      this.addressModel.permanent.Address = "";
      this.addressModel.permanent.LandMark = "";
      this.addressModel.permanent.StateId = "";
      this.addressModel.permanent.DistrictId = "";
      this.addressModel.permanent.TehsilId = "";
      this.addressModel.permanent.PinCode = "";
      this.addressModel.permanent.NearstBranchDistance_KM = "";
      this.addressModel.permanent.TotalYearsOnAddress = "";
      this.addressModel.permanent.AddressRentBuy = "";
      this.districkDropdownPermanent = [];
      this.tehasilDropdownPermanent = [];
    }
  }
  addEditCustomerKyc(data: any) {
    this.OnChangeCustomer(data);
    if (data.CustomerId) {
      this.isNewAddress = false;

      this.kycModel = { CustomerId: data.CustomerId, KYC_DocId: data.KYC_DocId, KYC_DocNumber: data.Doc_Number, KYC_DocFile: data.Doc_FileName, KYC_DocFile1: data.Doc_FileName1, LoginUserId: this.currentUser.userId, KYC_IsVerified: data.KYC_IsVerified, KYC_IssuedDate: data.KYC_IssuedDate ? new Date(data.KYC_IssuedDate) : '', KYC_ExpiredDate: data.KYC_ExpiredDate ? new Date(data.KYC_ExpiredDate) : '' };
      this.IsVerification(this.kycModel);
      // this.Get_Alredy_Verified_KYC(this.kycModel)
      console.log("this.kycModel", this.kycModel);

    }
    else {
      this.isNewAddress = true;
      this.kycModel = { CustomerId: "", KYC_DocId: "", KYC_DocNumber: "", KYC_DocFile: "", KYC_DocFile1: "", LoginUserId: this.currentUser.userId, KYC_IsVerified: 0 };
    }

    $('#kycModel').modal('show');
    $("#kycModel").css("z-index", "1050");
  }
  IsVerification(KYC) {
    // this.Get_Alredy_Verified_KYC(KYC, i)
    if (KYC.KYC_DocId == 1) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'PAN_Verification' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 6) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'Aadhaar_generate_otp' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 4) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'Voter_ID' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 3) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'Passport' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 5) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'License' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 29 || KYC.DocId == 29) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'POSTGSTIN' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.KYC_DocId == 2) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'Electricity' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }
    else if (KYC.DocId == 28) {
      this._MasterService
        .Get_Sms_Configration({ Step_Name: 'CompanyDetails' })
        .subscribe((res: any) => {
          this.getKycApiDetail(res, KYC);
        });
    }

    else {

      KYC.Verified_Button = false;
      this.KYC_Configration =
      {
        Id: 0,
        Is_Active: false,
        Key: "",
        SMS_Template: "",
        SMS_URL: "",
        Sender_Id: null,
        Step_Name: "",
        TemplateId: null,
        TemplateName: null,
        route: 0
      }
    }
    //.log("this.KYC_Configration", this.KYC_Configration);
  }

  getKycApiDetail(res, KYC) {
    if (res.length == 0) {

      KYC.Verified_Button = false;
    }
    else {
      KYC.Verified_Button = true;
      this.KYC_Configration =
      {
        Key: res[0].Key,
        SMS_URL: res[0].SMS_URL
      }
    }
  }

  EditCustomerRef(data: any) {
    this.isSaveCustomerRef = true;
    this.customerRefModel.SelectNeighbour = data.ReferenceType;
    this.customerRefModel.RefName = data.Name;
    this.customerRefModel.RefMobile = data.ContactNo;
    this.customerRefModel.RefRelation = data.Customer_Id;
    this.customerRefModel.Association = data.KnowYear;
    this.customerRefModel.Address = data.Address;
    this.customerRefModel.RefRemarks = data.Comments;
    this.customerRefModel.Int_Id = data.Int_Id;
    $('#ReferenceData').modal('show');
    $("#ReferenceData").css("z-index", "1050");
  }
  addCustomerRef() {
    $('#ReferenceData').modal('show');
    $("#ReferenceData").css("z-index", "1050");
  }
  onUpdateCustomerRef(ref) {
    let data = JSON.parse(sessionStorage.getItem('currentUser'));
    this.customerRefModel.CustomerId = Number(this.customerRefModel.RefRelation);
    this.customerRefModel.IsActive = 0;
    this.customerRefModel.Loan_Id = this.loanSummary.LoanId;
    this.customerRefModel.CreatedBy = data.userId;

    this.LmsService.onSaveCustomerRef(this.customerRefModel).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.isSaveCustomerRef = false;
        //ref.resetForm();
        this.onCloseReferenceData();
        this.customerRefModel = {};
        this.getCustomerRef();
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }

  getDocId(docType: string) {
    if (docType.toLowerCase() == "PAN Card".toLowerCase()) {
      return 1;
    }
    else if (docType.toLowerCase() == "Electricity Bill".toLowerCase()) {
      return 2;
    }
    else if (docType.toLowerCase() == "Passport Copy".toLowerCase()) {
      return 3;
    }
    else if (docType.toLowerCase() == "Voter ID Card".toLowerCase()) {
      return 4;
    }
    else if (docType.toLowerCase() == "Driving License".toLowerCase()) {
      return 5;
    }
    else if (docType.toLowerCase() == "Aadhaar Card".toLowerCase()) {
      return 6;
    }
    else if (docType.toLowerCase() == "Other Photo ID".toLowerCase()) {
      return 7;
    }
    else if (docType.toLowerCase() == "Ration Card".toLowerCase()) {
      return 21;
    }
    else if (docType.toLowerCase() == "DLl".toLowerCase()) {
      return 22;
    }
    else if (docType.toLowerCase() == "Firm Registration No".toLowerCase()) {
      return 28;
    }
    else if (docType.toLowerCase() == "GST No".toLowerCase()) {
      return 29;
    }
    else if (docType.toLowerCase() == "TAN No".toLowerCase()) {
      return 30;
    }
    else {
      return "";
    }
  }
  deleteCustomerKyc(data: any) {
    this.dialog.openConfirmDialog(`Are you sure you want to remove ${data.Customer}'s KYC doc from application.`)
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.losService.LOS_DeleteCustomerDocument({ CustomerId: data.CustomerId, DocumentId: this.getDocId(data.Doc_Title), LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
              this.LMS_GetLoanDetails();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            }
          });
        }
      });
  }
  onCloseKYC() {
    $('#kycModel').modal('hide');
  }
  onCloseOTP() {
    $('#OTPModel').modal('hide');
    this.OTP = "";
    setTimeout(() => {
      $('body').addClass("modal-open");
    }, 1000)
  }
  onAddCustomerKYC() {
    this.showSpinner = true;

    console.log(this.kycModel);
    this.kycModel.KYC_IsVerifie = 0;
    this.uploadKycDocs(this.kycModel);
    this.uploadKycDocs1(this.kycModel);
    this.LmsService.LMS_SaveCustomerKYCDocApp(this.kycModel).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.LMS_GetLoanDetails();
        this.onCloseKYC();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    })
  }
  uploadKycDocs(data: any) {
    if (data.DocData) {
      this.losService.UploadCustomerDoc({ CustomerID: data.CustomerId, DocName: data.KYC_DocFile, DocData: data.DocData }).subscribe((res: any) => {

      });
    }
  }
  uploadKycDocs1(data: any) {
    if (data.DocData1) {
      this.losService.UploadCustomerDoc({ CustomerID: data.CustomerId, DocName: data.KYC_DocFile1, DocData: data.DocData1 }).subscribe((res: any) => {

      });
    }
  }
  goToCustomerDetail(data: any) {
    var appid = this.encdec.encrypt(this.applicationId);
    var cid = this.encdec.encrypt(data.CustomerId);
    window.open("#/LOS/customer/view/" + encodeURIComponent(appid) + "/" + encodeURIComponent(cid), "_blank");
  }
  GetDistrickDropdownPresent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.present.DistrictId = "";
      this.addressModel.present.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.present.StateId })
      .subscribe((res: any) => {
        this.districkDropdownPresent = res;
      });
  }
  GetTehasilDropdownPresent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.present.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.addressModel.present.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdownPresent = res;
      });
  }
  GetDistrickDropdownPermanent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.permanent.DistrictId = "";
      this.addressModel.permanent.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.permanent.StateId })
      .subscribe((res: any) => {
        this.districkDropdownPermanent = res;
      });
  }
  GetTehasilDropdownPermanent1(reset: boolean = true) {
    if (reset) {
      this.addressModel.permanent.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({
        DistrictId: this.addressModel.permanent.DistrictId,
      })
      .subscribe((res: any) => {
        this.tehasilDropdownPermanent = res;
      });
  }
  GetDistrickDropdownWork1(reset: boolean = true) {
    if (reset) {
      this.addressModel.work.DistrictId = "";
      this.addressModel.work.TehsilId = "";
    }
    this.losService
      .GetDistrickDropdown({ StateID: this.addressModel.work.StateId })
      .subscribe((res: any) => {
        this.districkDropdownWork = res;
      });
  }
  GetTehasilDropdownWork1(reset: boolean = true) {
    if (reset) {
      this.addressModel.work.TehsilId = "";
    }
    this.losService
      .GetTehasilDropdown({ DistrictId: this.addressModel.work.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdownWork = res;
      });
  }
  CheckCustomerInfo() {
    //console.log("dataCustomerRefSource", this.dataCustomerRefSource);
    //console.log("dataCustomerSource", this.dataCustomerSource);
    //if (this.dataCustomerRefSource != undefined && this.dataCustomerSource.filteredData.length != 0) {
   // if (this.loanSummary.IsCustomerReference == false || this.loanSummary.IsReferenceTab == false || (this.dataCustomerRefSource != undefined &&  this.dataCustomerRefSource.filteredData.length > 0 && this.loanSummary.IsCustomerReference == true)) {
        this.LmsService.SaveNext_Loan({
          Loan_Id: this.loanSummary.LoanId, CommandName: 'Customer', isStatus: 1
        }).subscribe((response) => {
          // $("#CustomerInfo").removeClass("btn btn-orange btn-sm");
          // $("#CustomerInfo").addClass('btn btn-success btn-sm');
          // $("#AccountInfo").removeClass("tab");
          // let element: HTMLElement = document.getElementById("AccountInfo") as HTMLElement;
          // element.click();
          this.QuickLoan_Save_PageProcess('Customer Details', this.PageData.MM_Id);
          this.QuickLoan_Save_PageProcess('Customer Reference', 3);
          this.QuickLoan_Save_PageProcess('Family Member', 10);
          this.action.emit("next")
          // this._Route.navigateByUrl('/lms/lms-account');
        });
      //}
      //else {
      //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Atlease one Customer refrence.", ...this.configSuccess });
      //}

    //} else {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add Atlease one Customer.", ...this.configSuccess });
    //}

  }
  onCloseReferenceData() {
    $('#ReferenceData').modal('hide');
  }

  getOccupationList() {
    this.LmsService.LMS_Commaon_Master({ Commands: 'Select', Type: 'OCCUPATION' })
      .subscribe((result) => {
        this.OccupationData = JSON.parse(JSON.stringify(result));
    });
  }

  GetData(data) {
    if (data == true) {
      this.LMS_GetLoanDetails();
      this.QuickLoan_Save_PageProcess('Customer Details', this.PageData.MM_Id);
    //  this.action.emit("next")
    }
  }

  OnCloseRef(){
    $("#ReferenceData").modal("hide");
  }
  
  QuickLoan_Save_PageProcess(PageType, Page_Id){
    this._QuickLoanService.QuickLoan_Save_PageProcess({ 
      PageType : PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId, 
      Process_Id: this.Processid, Page_Id: Page_Id, LoginUserId: this.currentUser.userId
    }).subscribe((res) => {
      //return res[0].CODE;
    });
  }

  QuickLoan_Delete_Income_Expenses_Detail(CustomerId){
    this._QuickLoanService.QuickLoan_Delete_Income_Expenses_Detail({ LoanId: this.loanSummary.LoanId, CustomerId: CustomerId}).subscribe((res) => {
      this.QuickLoan_Save_PageProcess('Income Info', 4);
    });
  }

  //OnChangeCustomer(data) {
  //  var find = this.customerlist.filter(x => x.CustomerId == data?.CustomerId);
  //  if (find.length > 0) {
  //    let Customer_Type = this.customerlist.filter(x => x.CustomerId == data.CustomerId)[0].Customer_IsFirm;
  //    this.GetProductRequiredDoc(Customer_Type);
  //  }
  //}
  ViewKYC_IsVerified(KYC) {
    this._MasterService
      .Get_Alredy_Verified_KYC({ DocumentId: KYC.KYC_DocId, KYC_DocNumber: KYC.Doc_Number })
      .subscribe((res: any) => {
        //let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
        if (res.length > 0) {
          var Masking = KYC.Doc_Number;
          KYC.LastVerfiedDate = res[0].LastVerfiedDate;
          KYC.KYC_IsVerified = 1;
          Masking = Masking.toUpperCase().slice(3, KYC.Doc_Number.length - 3);

          var n = Masking.length;
          var ch = Masking.split('');

          for (var i = 0; i < n; i++) {
            ch[i] = 'X';
          }
          if (KYC.KYC_DocId == 1) {
            this.verify_DocId = 1;
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };

          }
          else if (KYC.KYC_DocId == 6) {
            this.verify_DocId = 6;
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              country: JSON.parse(res[0].APIResponse).data.address.country,
              dist: JSON.parse(res[0].APIResponse).data.address.dist,
              state: JSON.parse(res[0].APIResponse).data.address.state,
              po: JSON.parse(res[0].APIResponse).data.address.po,
              loc: JSON.parse(res[0].APIResponse).data.address.loc,
              vtc: JSON.parse(res[0].APIResponse).data.address.vtc,
              subdist: JSON.parse(res[0].APIResponse).data.address.subdist,
              street: JSON.parse(res[0].APIResponse).data.address.street,
              house: JSON.parse(res[0].APIResponse).data.address.house,
              landmark: JSON.parse(res[0].APIResponse).data.address.landmark,
              Pin: JSON.parse(res[0].APIResponse).data.zip,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              care_of: JSON.parse(res[0].APIResponse).data.care_of,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              zip: JSON.parse(res[0].APIResponse).data.zip,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };
            if (res.length > 1) {
              this.Verify_PAN_No = JSON.parse(res[1].APIResponse).data.pan_number;
            }
          }
          else if (KYC.KYC_DocId == 29) {
            Masking = Masking.toUpperCase().slice(3, KYC.Doc_Number.length - 3);

            var n = Masking.length;
            var ch = Masking.split('');

            for (var i = 0; i < n; i++) {
              ch[i] = 'X';
            }
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),

              business_name: JSON.parse(res[0].APIResponse).data.business_name,
              gst_status: JSON.parse(res[0].APIResponse).data.gstin_status,
              nature_bus_activities: JSON.parse(res[0].APIResponse).data.nature_bus_activities,
              Gst_Registration_Date: JSON.parse(res[0].APIResponse).data.date_of_registration,
              pan_number: JSON.parse(res[0].APIResponse).data.pan_number,
              SigningAutority: JSON.parse(res[0].APIResponse).data.legal_name,
              FirmType: JSON.parse(res[0].APIResponse).data.constitution_of_business,
              address: JSON.parse(res[0].APIResponse).data.address,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };
          }
          else if (KYC.KYC_DocId == 28) {

            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),

              company_name: JSON.parse(res[0].APIResponse).data.company_name,
              registration_number: JSON.parse(res[0].APIResponse).data.details.company_info.registration_number,
              company_sub_category: JSON.parse(res[0].APIResponse).data.details.company_info.company_sub_category,
              class_of_company: JSON.parse(res[0].APIResponse).data.details.company_info.class_of_company,
              company_category: JSON.parse(res[0].APIResponse).data.details.company_info.company_category,
              address: JSON.parse(res[0].APIResponse).data.details.company_info.registered_address,

              authorized_capital: JSON.parse(res[0].APIResponse).data.details.company_info.authorized_capital,
              paid_up_capital: JSON.parse(res[0].APIResponse).data.details.company_info.paid_up_capital,
              date_of_incorporation: JSON.parse(res[0].APIResponse).data.details.company_info.date_of_incorporation,
              company_status: JSON.parse(res[0].APIResponse).data.details.company_info.company_status,

              directors: JSON.parse(res[0].APIResponse).data.details.directors,
              charges: JSON.parse(res[0].APIResponse).data.details.charges,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate,

              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              message_code: JSON.parse(res[0].APIResponse).message_code
            };
            this.Directors = new MatTableDataSource(JSON.parse(res[0].APIResponse).data.details.directors);
            this.Charges = new MatTableDataSource(JSON.parse(res[0].APIResponse).data.details.charges);

          }
          else if (KYC.KYC_DocId == 5 || KYC.DocId == 5) {
            this.verify_DocId = 5;
            this.APIResponse = {
              KYC_DocId: 5,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),

              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              dob: JSON.parse(res[0].APIResponse).data.dob,
              doi: JSON.parse(res[0].APIResponse).data.doi,
              doe: JSON.parse(res[0].APIResponse).data.doe,
              address: JSON.parse(res[0].APIResponse).data.permanent_address,
              vehicle_classes: JSON.parse(res[0].APIResponse).data.vehicle_classes,
              profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
              ola_name: JSON.parse(res[0].APIResponse).data.ola_name,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };
          }

          else if (KYC.KYC_DocId == 2 || KYC.DocId == 2) {
            this.verify_DocId = 2;
            this.APIResponse = {
              KYC_DocId: 2,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),

              Full_name: JSON.parse(res[0].APIResponse).data.full_name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              address: JSON.parse(res[0].APIResponse).data.address,
              mobile: JSON.parse(res[0].APIResponse).data.mobile,
              state: JSON.parse(res[0].APIResponse).data.state,
              operator_code: JSON.parse(res[0].APIResponse).data.operator_code,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };
          }
          else {
            this.verify_DocId = KYC.KYC_DocId;
            this.APIResponse = {
              KYC_DocId: KYC.KYC_DocId,
              DoucumentNumber: KYC.Doc_Number.toUpperCase().substr(0, 0) + ch.join('') + KYC.Doc_Number.toUpperCase().substr(KYC.Doc_Number.length - 4),
              Full_name: JSON.parse(res[0].APIResponse).data.name,
              IsVerified: JSON.parse(res[0].APIResponse).data.success,
              category: JSON.parse(res[0].APIResponse).data.category,
              message_code: JSON.parse(res[0].APIResponse).message_code,
              gender: JSON.parse(res[0].APIResponse).data.gender,
              area: JSON.parse(res[0].APIResponse).data.area,
              dist: JSON.parse(res[0].APIResponse).data.district,
              state: JSON.parse(res[0].APIResponse).data.state,
              age: JSON.parse(res[0].APIResponse).data.age,
              relation_name: JSON.parse(res[0].APIResponse).data.relation_name,
              relation_type: JSON.parse(res[0].APIResponse).data.relation_type,
              LastVerfiedBy: res[0].LastVerfiedBy,
              LastVerfiedDate: res[0].LastVerfiedDate
            };

          }
          this.verify_DocId = KYC.KYC_DocId;
          if (KYC.KYC_DocId == 28 || KYC.KYC_DocId == 29) {
   
            $(`#modalVerifiedData`).modal("show");
            $(`#modalVerifiedData`).css("z-index", "1090");
          } else {
            $(`#modalVerifiedData`).modal("show");
            $(`#modalVerifiedData`).css("z-index", "1090");
          }
          this.LMS_GetLoanDetails();

        }
        else {
          KYC.LastVerfiedDate = "";
          KYC.KYC_IsVerified = 0;
        }
      });

  }
  onVerification(KYC, ReVerify) {
    if (ReVerify == 1) {
      this.dialog.openConfirmDialog(`Are you sure you want to Re-Verified this Document ?`)
        .afterClosed().subscribe(dialogResult => {
          if (dialogResult == false) {
            return;
          } else {

            this.KYCVerifyByAPI(KYC);
          }
        })
    } else {
      this.KYCVerifyByAPI(KYC);
    }

  }
  KYCVerifyByAPI(KYC) {
    this.showSpinner = true;
    var Masking = KYC.KYC_DocNumber;
    Masking = Masking.toUpperCase().slice(3, KYC.KYC_DocNumber.length - 3);

    var n = Masking.length;
    var ch = Masking.split('');

    for (var i = 0; i < n; i++) {
      ch[i] = 'X';
    }
    if (KYC.KYC_DocId == 1 || KYC.DocId == 1) {

      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration.SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration.Key
      }
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {

        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 1,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),

            Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
            category: JSON.parse(data.KYC_Verification.APIResponse).data.category,
            message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
          };
        }
        else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService.Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) }).subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (JSON.parse(data.KYC_Verification.APIResponse).status_code != 200) {
              KYC.KYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, { data: JSON.parse(data.KYC_Verification.APIResponse).message, ...this.configSuccess });
            }
            else {
              $(`#modalVerifiedData`).modal("show");
              $(`#modalVerifiedData`).css("z-index", "1050");
              this.LMS_GetLoanDetails();
            }
            this.verify_DocId = 1;
          }
        });
      });
    }
    else if (KYC.KYC_DocId == 6) {
      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration.SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration.Key
      }
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        //console.log("API Result", res);
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 6,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }

        //console.log("Save_Json", data);
        this._MasterService.Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) }).subscribe((Save_res: any) => {
          this.showSpinner = false;
          if (Save_res[0].CODE == 0) {
            if (JSON.parse(data.KYC_Verification.APIResponse).status_code != 200) {
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, { data: JSON.parse(data.KYC_Verification.APIResponse).message, ...this.configSuccess });
            }
            else {
              this.clientId = JSON.parse(data.KYC_Verification.APIResponse).data.client_id;
              this.verify_DocNumber = KYC.KYC_DocNumber;
              this.verify_DocId = 6;
              //this.record = i;
              $(`#AdhaarOTPModel`).modal("show");
              $(`#AdhaarOTPModel}`).css("z-index", "1050");
            }
          }
        });
      });
    }
    else if (KYC.KYC_DocId == 4) {

      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration.SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration.Key
      }
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        //console.log("API Result", res);
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 4,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }

        // KYC.KYC_IsVerified = 1;
        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),

            Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
            gender: JSON.parse(data.KYC_Verification.APIResponse).data.gender,
            area: JSON.parse(data.KYC_Verification.APIResponse).data.area,
            dist: JSON.parse(data.KYC_Verification.APIResponse).data.district,
            state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
            age: JSON.parse(data.KYC_Verification.APIResponse).data.age,
            relation_name: JSON.parse(data.KYC_Verification.APIResponse).data.relation_name,
            relation_type: JSON.parse(data.KYC_Verification.APIResponse).data.relation_type,

            //vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
            //subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
            //street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
            //house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
            //landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
            //Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
            //profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
            care_of: JSON.parse(data.KYC_Verification.APIResponse).data.relation_name,
            message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
          };
        }
        else {
          KYC.KYC_IsVerified = 0;
          // this.showSpinner = false;
          //this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
          // return;
        }

        //console.log("Save_Json", data);
        this._MasterService.Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) }).subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (JSON.parse(data.KYC_Verification.APIResponse).status_code != 200) {
              KYC.KYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, { data: JSON.parse(data.KYC_Verification.APIResponse).message, ...this.configSuccess });
            }
            else {
              $(`#modalVerifiedData`).modal("show");
              $(`#modalVerifiedData`).css("z-index", "1050");
              this.LMS_GetLoanDetails();
            }
            this.verify_DocId = 4;
          }
        });
      });
    }
    else if (KYC.KYC_DocId == 3) {

    }
    else if (KYC.KYC_DocId == 5) {
      this.showSpinner = false;
      this.verify_DocNumber = KYC.KYC_DocNumber;
      this.verify_DocId = 5;
      $(`#DLModelVerifiedData`).modal("show");
      $(`#DLModelVerifiedData`).css("z-index", "1050");
    }

    else if (KYC.KYC_DocId == 29 || KYC.DocId == 29) {


      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration.SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration.Key
      }
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {

        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 29,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),

            business_name: JSON.parse(data.KYC_Verification.APIResponse).data.business_name,
            gst_status: JSON.parse(data.KYC_Verification.APIResponse).data.gstin_status,
            nature_bus_activities: JSON.parse(data.KYC_Verification.APIResponse).data.nature_bus_activities,
            Gst_Registration_Date: JSON.parse(data.KYC_Verification.APIResponse).data.date_of_registration,
            pan_number: JSON.parse(data.KYC_Verification.APIResponse).data.pan_number,
            SigningAutority: JSON.parse(data.KYC_Verification.APIResponse).data.legal_name,
            FirmType: JSON.parse(data.KYC_Verification.APIResponse).data.constitution_of_business,
            address: JSON.parse(data.KYC_Verification.APIResponse).data.address,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
            message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
          };
        }
        else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService.Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) }).subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (JSON.parse(data.KYC_Verification.APIResponse).status_code != 200) {
              KYC.KYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, { data: JSON.parse(data.KYC_Verification.APIResponse).message, ...this.configSuccess });
            }
            else {
              $(`#DLModelVerifiedData`).modal("show");
              $(`#DLModelVerifiedData`).css("z-index", "1050");
              this.LMS_GetLoanDetails();
            }
            this.verify_DocId = 29;
            //this.record = i;
          }
        });
      });
    }
    else if (KYC.DocId == 28) {


      var model = {
        KYC_DocNumber: KYC.KYC_DocNumber,
        SMS_URL: this.KYC_Configration.SMS_URL,
        KYC_DocId: KYC.KYC_DocId,
        Key: this.KYC_Configration.Key
      }
      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {

        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: KYC.KYC_DocNumber,
            DocumentId: 28,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }

        if (APIResult.success == true) {
          KYC.KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: KYC.KYC_DocId,
            DoucumentNumber: KYC.KYC_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + KYC.KYC_DocNumber.toUpperCase().substr(KYC.KYC_DocNumber.length - 4),

            company_name: JSON.parse(data.KYC_Verification.APIResponse).data.company_name,
            registration_number: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.registration_number,
            company_sub_category: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.company_sub_category,
            class_of_company: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.class_of_company,
            company_category: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.company_category,
            address: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.registered_address,

            authorized_capital: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.authorized_capital,
            paid_up_capital: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.paid_up_capital,
            date_of_incorporation: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.date_of_incorporation,
            company_status: JSON.parse(data.KYC_Verification.APIResponse).data.details.company_info.company_status,

            directors: JSON.parse(data.KYC_Verification.APIResponse).data.details.directors,
            charges: JSON.parse(data.KYC_Verification.APIResponse).data.details.charges,


            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
            message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
          };
          this.Directors = new MatTableDataSource(JSON.parse(data.KYC_Verification.APIResponse).data.details.directors);
          this.Charges = new MatTableDataSource(JSON.parse(data.KYC_Verification.APIResponse).data.details.charges);
        }
        else {
          KYC.KYC_IsVerified = 0;
        }

        this._MasterService.Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) }).subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (JSON.parse(data.KYC_Verification.APIResponse).status_code != 200) {
              KYC.KYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, { data: JSON.parse(data.KYC_Verification.APIResponse).message, ...this.configSuccess });
            }
            else {
              $(`#DLModelVerifiedData`).modal("show");
              $(`#DLModelVerifiedData`).css("z-index", "1050");
             
              this.LMS_GetLoanDetails();
            }
            this.verify_DocId = 28;
            //this.record = i;
          }
        });
      });
    }
    else if (KYC.KYC_DocId == 2) {
      this.getElectricityStatelist();
      this.showSpinner = false;
      this.verify_DocNumber = KYC.KYC_DocNumber;
      this.verify_DocId = 2;

      $(`#ElectricityModelVerifiedData`).modal("show");
      $(`#ElectricityModelVerifiedData`).css("z-index", "1050");
    }
  }

  //onSAVEAdhaaKYCOTP() {
  //  this._MasterService.Get_Sms_Configration({ Step_Name: 'Aadhaar_submit_otp' }).subscribe((res: any) => {
  //    var data = {
  //      Key: res[0].Key,
  //      SMS_URL: res[0].SMS_URL
  //    }
  //    var model = {
  //      KYC_DocNumber: this.verify_DocNumber,
  //      SMS_URL: data.SMS_URL,
  //      KYC_DocId: this.verify_DocId,
  //      Key: data.Key,
  //      OTP: this.OTP,
  //      clientId: this.clientId
  //    }

  //    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
  //      //console.log("API Result", res);
  //      var APIResult = res;
  //      var data = {
  //        KYC_Verification: {
  //          DoucumentNumber: model.KYC_DocNumber,
  //          DocumentId: model.KYC_DocId,
  //          IsVerified: APIResult.success,
  //          APIURL: APIResult.APIURL,
  //          APIRequest: APIResult.request,
  //          APIResponse: APIResult.response,
  //          APIStatus: APIResult.status_code,
  //          LoginUserId: this.currentUser.userId
  //        }
  //      }
  //      // let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
  //      if (APIResult.success == true) {
  //        var clientId = JSON.parse(data.KYC_Verification.APIResponse).data.client_id;
  //        var Masking = this.verify_DocNumber;
  //        Masking = Masking.toUpperCase().slice(3, this.verify_DocNumber.length - 3);

  //        var n = Masking.length;
  //        var ch = Masking.split('');

  //        for (var i = 0; i < n; i++) {
  //          ch[i] = 'X';
  //        }
  //        // KYCDocs[this.record].KYC_IsVerified = 1;
  //        this.APIResponse = {
  //          KYC_DocId: 6,
  //          // DoucumentNumber: this.verify_DocNumber,
  //          DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + ch.join('') + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

  //          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
  //          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
  //          dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
  //          gender: JSON.parse(data.KYC_Verification.APIResponse).data.gender,
  //          country: JSON.parse(data.KYC_Verification.APIResponse).data.address.country,
  //          dist: JSON.parse(data.KYC_Verification.APIResponse).data.address.dist,
  //          state: JSON.parse(data.KYC_Verification.APIResponse).data.address.state,
  //          po: JSON.parse(data.KYC_Verification.APIResponse).data.address.po,
  //          loc: JSON.parse(data.KYC_Verification.APIResponse).data.address.loc,
  //          vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
  //          subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
  //          street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
  //          house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
  //          landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
  //          Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
  //          profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
  //          care_of: JSON.parse(data.KYC_Verification.APIResponse).data.care_of,
  //          message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code,
  //          zip: JSON.parse(data.KYC_Verification.APIResponse).data.zip
  //        };
  //        //console.log("this.APIResponse", this.APIResponse);
  //      }
  //      else {
  //        //KYCDocs[this.record].KYC_IsVerified = 0;
  //        //  this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
  //        // return;
  //      }
  //      //console.log("Save_Json", data);

  //      //this.lmsService
  //      //  .UploadOthersDoc({
  //      //    DocName: JSON.parse(data.KYC_Verification.APIResponse).data.full_name+".jpg",
  //      //    DocData: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
  //      //  })
  //      //  .subscribe((res: any) => { });

  //      this._MasterService
  //        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
  //        .subscribe((Save_res: any) => {
  //          this.showSpinner = true;
  //          if (Save_res[0].CODE == 0) {
  //            this.showSpinner = false;
  //            if (APIResult.success == true) {
  //              $(`#AdhaarOTPModel`).modal("hide");
  //              $(`#modalVerifiedData`).modal("show");
  //              $(`#modalVerifiedData`).css("z-index", "1050");
  //              this.LMS_GetLoanDetails();
  //            }
  //            else {
  //              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
  //              this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
  //              return;
  //            }
  //          }
  //        });
  //    });
  //  });
  //}


  onSAVEAdhaaKYCOTP() {
    this._MasterService.Get_Sms_Configration({ Step_Name: 'Aadhaar_submit_otp' }).subscribe((res: any) => {
      var data = {
        Key: res[0].Key,
        SMS_URL: res[0].SMS_URL
      }
      var model = {
        KYC_DocNumber: this.verify_DocNumber,
        SMS_URL: data.SMS_URL,
        KYC_DocId: this.verify_DocId,
        Key: data.Key,
        OTP: this.OTP,
        clientId: this.clientId
      }

      this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
        //console.log("API Result", res);
        var APIResult = res;
        var data = {
          KYC_Verification: {
            DoucumentNumber: model.KYC_DocNumber,
            DocumentId: model.KYC_DocId,
            IsVerified: APIResult.success,
            APIURL: APIResult.APIURL,
            APIRequest: APIResult.request,
            APIResponse: APIResult.response,
            APIStatus: APIResult.status_code,
            LoginUserId: this.currentUser.userId
          }
        }
        // let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
        if (APIResult.success == true) {
          var clientId = JSON.parse(data.KYC_Verification.APIResponse).data.client_id;
          var Masking = this.verify_DocNumber;
          Masking = Masking.toUpperCase().slice(3, this.verify_DocNumber.length - 3);

          var n = Masking.length;
          var ch = Masking.split('');

          for (var i = 0; i < n; i++) {
            ch[i] = 'X';
          }
          // KYCDocs[this.record].KYC_IsVerified = 1;
          this.APIResponse = {
            KYC_DocId: 6,
            // DoucumentNumber: this.verify_DocNumber,
            DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 0) + ch.join('') + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 4),

            Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
            IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
            dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
            gender: JSON.parse(data.KYC_Verification.APIResponse).data.gender,
            country: JSON.parse(data.KYC_Verification.APIResponse).data.address.country,
            dist: JSON.parse(data.KYC_Verification.APIResponse).data.address.dist,
            state: JSON.parse(data.KYC_Verification.APIResponse).data.address.state,
            po: JSON.parse(data.KYC_Verification.APIResponse).data.address.po,
            loc: JSON.parse(data.KYC_Verification.APIResponse).data.address.loc,
            vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address.vtc,
            subdist: JSON.parse(data.KYC_Verification.APIResponse).data.address.subdist,
            street: JSON.parse(data.KYC_Verification.APIResponse).data.address.street,
            house: JSON.parse(data.KYC_Verification.APIResponse).data.address.house,
            landmark: JSON.parse(data.KYC_Verification.APIResponse).data.address.landmark,
            Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
            profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
            care_of: JSON.parse(data.KYC_Verification.APIResponse).data.care_of,
            message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code,
            zip: JSON.parse(data.KYC_Verification.APIResponse).data.zip
          };
          //console.log("this.APIResponse", this.APIResponse);
        }
        else {
          //KYCDocs[this.record].KYC_IsVerified = 0;
          //  this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
          // return;
        }
        //console.log("Save_Json", data);

        //this.lmsService
        //  .UploadOthersDoc({
        //    DocName: JSON.parse(data.KYC_Verification.APIResponse).data.full_name+".jpg",
        //    DocData: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
        //  })
        //  .subscribe((res: any) => { });

        this._MasterService
          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
          .subscribe((Save_res: any) => {
            this.showSpinner = true;
            //if (Save_res[0].CODE == 0) {
            //  this.showSpinner = false;
            //  if (APIResult.success == true) {
            //    $(`#AdhaarOTPModel`).modal("hide");
            //    $(`#modalVerifiedData`).modal("show");
            //    $(`#modalVerifiedData`).css("z-index", "1050");
            //    this.LMS_GetLoanDetails();
            //  }
            //  else {
            //    //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
            //    this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
            //    return;
            //  }
            //}
            if (Save_res[0].CODE == 0) {
              this.showSpinner = false;
              if (APIResult.success == true) {

                this._MasterService.Get_Sms_Configration({ Step_Name: 'Aadhaar_To_PAN' }).subscribe((result: any) => {

                  if (result.length > 0) {
                    var data = {
                      Key: result[0].Key,
                      SMS_URL: result[0].SMS_URL
                    }
                    var model = {
                      KYC_DocNumber: this.verify_DocNumber,
                      SMS_URL: data.SMS_URL,
                      KYC_DocId: this.verify_DocId,
                      Key: data.Key
                    }

                    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
                      var APIAadhaar_To_PANResult = res;

                      if (APIAadhaar_To_PANResult.success == true) {
                        var data = {
                          KYC_Verification: {
                            DoucumentNumber: model.KYC_DocNumber,
                            DocumentId: model.KYC_DocId,
                            IsVerified: APIAadhaar_To_PANResult.success,
                            APIURL: APIAadhaar_To_PANResult.APIURL,
                            APIRequest: APIAadhaar_To_PANResult.request,
                            APIResponse: APIAadhaar_To_PANResult.response,
                            APIStatus: APIAadhaar_To_PANResult.status_code,
                            LoginUserId: this.currentUser.userId
                          }
                        }
                        this.Verify_PAN_No = JSON.parse(data.KYC_Verification.APIResponse).data.pan_number;
                        this.CustomerKYCDoc.push({ KYC_DocId: "1", KYC_DocNumber: this.Verify_PAN_No, KYC_DocFile: "", KYC_DocFile1: "", Verified_Button: false });
                        this.checkDuplicate(this.CustomerKYCDoc[this.CustomerKYCDoc.length - 1])
                        this._MasterService
                          .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
                          .subscribe((Save_res: any) => {
                            this.showSpinner = false;
                            if (Save_res[0].CODE == 0) {

                              $(`#AdhaarOTPModel`).modal("hide");
                              $(`#modalVerifiedData`).modal("show");
                              $(`#modalVerifiedData`).css("z-index", "1050");
                              this.LMS_GetLoanDetails();
                            }
                          })


                      }


                    })
                  } else {
                    $(`#AdhaarOTPModel`).modal("hide");
                    $(`#modalVerifiedData`).modal("show");
                    $(`#modalVerifiedData`).css("z-index", "1050");
                    this.LMS_GetLoanDetails();
                  }

                })



              }
              else {
                //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
                this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
                return;
              }
            }
          });
      });
    });
  }
  onCloseAdhaarOTP() {
    $(`#AdhaarOTPModel`).modal("hide");

  }
  OnClosemodalVerifiedData() {
    this.APIResponse = {};
    $(`#modalVerifiedData`).modal("hide");

  }

  Get_Alredy_Verified_KYC(KYC) {
    // debugger;
    this.checkDuplicate(KYC);
    if (KYC.KYC_DocNumber!='') {
      this._MasterService
        .Get_Alredy_Verified_KYC({ DocumentId: KYC.KYC_DocId, KYC_DocNumber: KYC.KYC_DocNumber })
        .subscribe((res: any) => {

          if (res.length > 0) {
            KYC.LastVerfiedDate = res[0].LastVerfiedDate;
            KYC.KYC_IsVerified = 1;

            if (KYC.KYC_DocId == 1) {
              this.verify_DocId = 1;
              this.APIResponse = {
                KYC_DocId: KYC.KYC_DocId,
                DoucumentNumber: KYC.KYC_DocNumber,
                Full_name: JSON.parse(res[0].APIResponse).data.full_name,
                IsVerified: JSON.parse(res[0].APIResponse).data.success,
                category: JSON.parse(res[0].APIResponse).data.category,
                message_code: JSON.parse(res[0].APIResponse).message_code
              };

            }
            if (KYC.KYC_DocId == 6) {
              this.verify_DocId = 6;
              this.APIResponse = {
                KYC_DocId: KYC.KYC_DocId,
                //DoucumentNumber: verify_DocNumber,
                DoucumentNumber: this.verify_DocNumber,
                Full_name: JSON.parse(res[0].APIResponse).data.full_name,
                IsVerified: JSON.parse(res[0].APIResponse).data.success,
                dob: JSON.parse(res[0].APIResponse).data.dob,
                gender: JSON.parse(res[0].APIResponse).data.gender,
                country: JSON.parse(res[0].APIResponse).data.address.country,
                dist: JSON.parse(res[0].APIResponse).data.address.dist,
                state: JSON.parse(res[0].APIResponse).data.address.state,
                po: JSON.parse(res[0].APIResponse).data.address.po,
                loc: JSON.parse(res[0].APIResponse).data.address.loc,
                vtc: JSON.parse(res[0].APIResponse).data.address.vtc,
                subdist: JSON.parse(res[0].APIResponse).data.address.subdist,
                street: JSON.parse(res[0].APIResponse).data.address.street,
                house: JSON.parse(res[0].APIResponse).data.address.house,
                landmark: JSON.parse(res[0].APIResponse).data.address.landmark,
                Pin: JSON.parse(res[0].APIResponse).data.zip,
                profile_image: JSON.parse(res[0].APIResponse).data.profile_image,
                care_of: JSON.parse(res[0].APIResponse).data.care_of,
                message_code: JSON.parse(res[0].APIResponse).message_code,
                zip: JSON.parse(res[0].APIResponse).data.zip
              };
              if (res.length > 1) {
                this.Verify_PAN_No = JSON.parse(res[1].APIResponse).data.pan_number;
              }
            }


          }
          else {
            KYC.LastVerfiedDate = "";
            KYC.KYC_IsVerified = 0;
          }
        });
    }
  }
  GetProductRequiredDoc(Customer_Type) {
    this.RequestModel.ProductId = (this.applicationDetail == undefined || this.applicationDetail.ProductId == '') ? 0 : this.applicationDetail.ProductId;

    this._MasterService.GetProductRequiredDoc(this.RequestModel).subscribe((result: any) => {
      this.ProductDropdownList = [];
      if (!Customer_Type) {
        this.ProductDropdownList = result.filter(item => item.IsAlreadySelected == true && item.Doc_Ind_NI != 'N' && item.Doc_Ind_NI != null);
      }
      else {
        this.ProductDropdownList = result.filter(item => item.IsAlreadySelected == true && item.Doc_Ind_NI != 'I' && item.Doc_Ind_NI != null);
      }
      //console.log("this.ProductDropdownList", this.ProductDropdownList);
    });
  }
  OnChangeCustomer(data) {
    var find = this.customerlist.filter(x => x.CustomerId == data?.CustomerId);
    if (find.length > 0) {
      let Customer_Type = this.customerlist.filter(x => x.CustomerId == data.CustomerId)[0].Customer_IsFirm;
      this.GetProductRequiredDoc(Customer_Type);
    }
  }


  onCloseDLModel() {
    $(`#DLModelVerifiedData`).modal("hide");

  }
  onVerifiedDrivingLicence() {

    var model = {
      KYC_DocNumber: this.verify_DocNumber,
      SMS_URL: this.KYC_Configration.SMS_URL,
      KYC_DocId: this.verify_DocId,
      Key: this.KYC_Configration.Key,
      DOB: moment(this.DL_DOB).format('YYYY-MM-DD')
    }
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {

      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.verify_DocNumber,

          DocumentId: 5,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId
        }
      }
      let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
      if (APIResult.success == true) {


        this.APIResponse = {
          KYC_DocId: this.verify_DocId,
          DoucumentNumber: this.verify_DocNumber,
          // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
          dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
          doi: JSON.parse(data.KYC_Verification.APIResponse).data.doi,
          doe: JSON.parse(data.KYC_Verification.APIResponse).data.doe,

          address: JSON.parse(data.KYC_Verification.APIResponse).data.permanent_address,
          vehicle_classes: JSON.parse(data.KYC_Verification.APIResponse).data.vehicle_classes,
          profile_image: JSON.parse(data.KYC_Verification.APIResponse).data.profile_image,
          ola_name: JSON.parse(data.KYC_Verification.APIResponse).data.ola_name,
          message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
        };
        this.kycModel.KYC_IssuedDate = new Date(JSON.parse(data.KYC_Verification.APIResponse).data.doi)
        this.kycModel.KYC_ExpiredDate = new Date(JSON.parse(data.KYC_Verification.APIResponse).data.doe)

      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              $(`#DLModelVerifiedData`).modal("hide");
              $(`#modalVerifiedData`).modal("show");
              $(`#modalVerifiedData`).css("z-index", "1050");
            }
            else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
              return;
            }
          }
        });
    });
  }
  onVerifiedElectricity() {
    var model = {
      KYC_DocNumber: this.verify_DocNumber,
      SMS_URL: this.KYC_Configration.SMS_URL,
      KYC_DocId: this.verify_DocId,
      Key: this.KYC_Configration.Key,
      Op_code: this.OperatorCode
    }

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var afterDoc = Object.assign(this.verify_DocNumber);
   
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: model.KYC_DocId,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId
        }
      }
      let KYCDocs = this.customerModel.Type == 'Company' ? this.firmKYCDoc : this.CustomerKYCDoc;
      if (APIResult.success == true) {

        this.KYC_Configration.KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: this.verify_DocId,
          DoucumentNumber: this.verify_DocNumber,
          // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data.success,
          address: JSON.parse(data.KYC_Verification.APIResponse).data.address,
          mobile: JSON.parse(data.KYC_Verification.APIResponse).data.mobile,
          state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
          operator_code: JSON.parse(data.KYC_Verification.APIResponse).data.operator_code,
          message_code: JSON.parse(data.KYC_Verification.APIResponse).message_code
        };

      }
     
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              $(`#ElectricityModelVerifiedData`).modal("hide");
              $(`#modalVerifiedData`).modal("show");
              $(`#modalVerifiedData`).css("z-index", "1050");
            }
            else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, { data: "YOUR KYC NOT VALID !!!", ...this.configSuccess });
              return;
            }
          }
        });
    });

  }
  onCloseElectricityModel() {
    $(`#ElectricityModelVerifiedData`).modal("hide");
  }
  getElectricityStatelist() {
    this._MasterService.Get_Electricity_State()
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.ElectricityStatelist = res;
        }
      })
  }
  getElectricityOpCodelist() {
    this._MasterService.Get_Electricity_OperatorCode({ state: this.ELState })
      .subscribe((res: any) => {
        this.ElectricityOpCodelist = res;

      })
  }
}
