import { Component, Input, OnInit } from '@angular/core';
import { constantUrl } from '../../../Shared/constantUrl';
@Component({
  selector: 'app-TVR-Question-subcard-info',
  templateUrl: './TVR-Question-subcard-info.component.html',
  styleUrls: ['./TVR-Question-subcard-info.component.scss']
})
export class TVRQuestionSubcardInfoComponent implements OnInit {
  @Input() TVRQuestionData: any;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
