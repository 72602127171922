<div id="chart" *ngIf="show && chartOptions">
  <apx-chart
    [chart]="chartOptions.chart"
    [series]="chartOptions.series"
    [title]="chartOptions.title"
    [theme]="chartOptions.theme"
    [responsive]="chartOptions.responsive"
    [labels]="chartOptions.labels"
  >
    <ng-template #titleTemplate>
      <div class="chart-title">{{ chartOptions.title.text }}</div>
    </ng-template>
    <ng-template #labelTemplate>
      <div class="label-title">{{ chartOptions.title.labels }}</div>
    </ng-template>
  </apx-chart>
</div>
