import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import * as moment from 'moment';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { constantUrl } from '../../Shared/constantUrl';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
@Component({
  selector: 'app-employee_assign_product',
  templateUrl: './employee_assign_product.component.html',
  styleUrls: ['./employee_assign_product.component.scss']
})
export class EmployeeAssignProductComponent implements OnInit {
  private data: any; showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProductRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();

  loading: boolean = false; CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = "0";
  popupValue: any;
  SelectedProductsData: any = [];
  // bind data
  ProductsList: any = [];
  dataSource: any;
  dataSourceProductAssign: any;
  displayedColumns: any = [];
  selectedProductId: any;
  SelectProduct: any = '';
  AssignProductList: any=[];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = '';
  isShowCopyEmployee: boolean = false;
  isEdit: boolean = false;
  SelectedEmpIds: any = '';
  isDisable: boolean = true;

  ShowFilter = true;
  SelectAll: boolean = false;
  limitSelection = false;

  RoleDropdown: any[] = [];

  BaseProductsList: any[] = [];
  BaseProductId: any = '';
  SelectRoleId: any = '';
  EmpManagerId: any;
  EmployeeName: any;
  GroupId: any = '';
  Ischecked: boolean = false;
  groupDropdown: any[] = [];
  IsCreateEmployee: boolean = true;

  DepartmentList: any[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    private lmsService: LmsService,
  ) {
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Employee Register');

    this.EmployeeId = sessionStorage.getItem('getEmployeeID');
    if (this.EmployeeId != "0") {
      this.isEdit = true;
      this.IsCreateEmployee = false;
      this.Get_Master();
      this.getEmployeeData();
    }


    this.Get_Acc_GroupDropdown();
    this.getnew_ProductList();

  }
  getnew_ProductList() {

    this._MasterService.Get_Employee_Product_NotAssigned_List({ EmpId:this.EmployeeId }).subscribe((res: any) => {
      this.AssignProductList = res;
    });
  }
  Get_Master() {
    this.showSpinner = true;
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });
    this._MasterService.ReportingManagerForDropdown(this.ReportingManagerRequestModel).subscribe((result) => {
      this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getEmployeeData() {
    this.ProductsList = [];
    this.RequestModel.EmpId = (this.EmployeeId);
    this._MasterService.GetEmployeeDetails(this.RequestModel).subscribe((result) => {
      //console.log("RESULT :",result);

      this.EmployeeDetailData = result;
      if (this.EmployeeDetailData.Item1[0]) {

        this.BaseProductsList = this.EmployeeDetailData.Item6;
        this.EmployeeName = this.EmployeeDetailData.Item1[0].Emp_FirstName + ' ' + this.EmployeeDetailData.Item1[0].Emp_LastName;
        // this.LastName = this.EmployeeDetailData.Item1[0].Emp_LastName;
        this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
        this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;

      }


      this.ProductsList = this.EmployeeDetailData.Item6;
      this.ProductsList.map((item) => {
        item.isActiveSelect = item.ProductAlradyAssigned;
        item.isDisable = item.ProductAlradyAssigned;
      });




    });
  }







  onChangeAssignProduct(event: MatCheckboxChange, selectedProductId) {
    this.ProductsList.map((item) => {
      if (item.ProductId == selectedProductId) {
        item.isActiveSelect = event.checked;
      }
    });
  }


  OnClose() {
    this._Route.navigate(['employee/list']);
  }



  onSaveEmployeeDetailData() {

    this.ProductsList.map((item) => {
      // if (item.ProductAlradyAssigned == true) {
      this.SelectedProductsData.push({
        "Id": item.EmpProductId,
        "EmpProduct_EmpId": (this.EmployeeId),
        "EmpProduct_ProductId": item.ProductId,
        "EmpProduct_IsActive": item.isActiveSelect == true ? 1 : 0,
        "EmpProduct_CreateBy": this.data.userId,
        "EmpProduct_ModifyBy": this.data.userId
      });
      // }
    });

    //this.LoanProcessList.map((item) => {
    //  // if (item.ProcessAlradyAssigned == true) {
    //  this.SelectedLoanProcessData.push({
    //    "Id": item.EmpProcessId,
    //    "EmpProcess_EmpId": parseInt(this.EmployeeId),
    //    "EmpProcess_ProcessId": item.ProcessId,
    //    "EmpProcess_IsActive": item.isActiveSelect == true ? 1 : 0,
    //    "EmpProcess_CreateBy": this.data.userId,
    //    "EmpProcess_ModifyBy": this.data.userId
    //  });
    //  // }
    //});
    //console.log('DeptId', DeptId);
    let saveEmployeeDetail = {
      "Employee": {
        "EmpId": (this.EmployeeId)
      },
      //"Employee": {
      //  "EmpId": parseInt(this.EmployeeId),
      //  "Emp_FirstName": this.FirstName,
      //  "Emp_LastName": this.LastName,
      //  "Emp_Gender": this.SelectGender,
      //  "Emp_DOB": moment(this.DateOfBirth).format('YYYY-MM-DD'),
      //  "Emp_PhoneNo": this.PhoneNo,
      //  "Emp_PhoneNo1": this.AlternetPhoneNo,
      //  "Emp_RoleId": parseInt(this.SelectRoleId),
      //  "Emp_DepartmentId": DeptId, //parseInt(this.SelectDeptId),
      //  "Emp_Email": this.EnterEmail,
      //  "Emp_LoginId": this.EmployeeLoginId,
      //  "Emp_Password": this.EmployeePassword,
      //  "Emp_PresentAddress": this.PresentAddress,
      //  "Emp_PermanentAddress": this.PermanentAddress,
      //  "Emp_AadharNo": this.AadharNo,
      //  "Emp_Image": this.profilePicture != '' ? this.CurrentDate.getTime() + '_' + this.UploadImageName : this.UploadImageName,
      //  "Emp_ManagerId": parseInt(this.EmpManagerId),
      //  "Emp_Createby": this.data.userId,
      //  "Emp_ModifyBy": this.data.userId,
      //  "Base_ProductId": this.BaseProductId,
      //  "GroupId": this.GroupId,
      //  "Emp_PAN_No": this.Emp_PAN_No,
      //  "Emp_Code": this.Emp_Code
      //},
      "EmployeeProduct": this.SelectedProductsData,
      // "EmployeeProcess": this.SelectedLoanProcessData
    }


    this.SaveEmployeeDataRequestModel.JSON = JSON.stringify(saveEmployeeDetail);

    this._MasterService.SaveEmployeDetails(this.SaveEmployeeDataRequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        //  this.OnClose();
        this.getEmployeeData();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  onDeleteAssignProduct(selectedProductId) {
    this.popupValue = 'Delete';
    //this.EmpDeleteAssProductRequestModel.EmpProductId = selectedProductId;
    //this._MasterService.DeleteEmployeeProduct(this.EmpDeleteAssProductRequestModel).subscribe((response) => {
    //  if (response[0].CODE == 1) {
    //    this.ProductesList.map((item) => {
    //      if (item.EmpProductId == selectedProductId) {
    //        item.isDisable = false;
    //        item.ProductAlradyAssigned = false;
    //        item.isActiveSelect = false;
    //      }
    //    });
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
    //  }
    //});
    this.selectedProductId = selectedProductId;
    this._MasterService.Get_Hierarchy_Upper_Lower({ LoginUserId: (this.EmployeeId), Type: 'Lower', ProductId: selectedProductId }).subscribe((response: any) => {
      //this.SelectedEmpIds = this.EmployeeId;
      if (response.length > 0) {
        var empIds = JSON.parse(JSON.stringify(response));
        empIds.map((item) => {
          this.SelectedEmpIds = this.SelectedEmpIds+','+item.EmpId ;

        })
        this.displayedColumns = ["Emp_Name", "Designation"];
        this.dataSource = JSON.parse(JSON.stringify(response));
        $('#DeleteProductModel').modal('show');
        $("#DeleteProductModel").css("z-index", "1050");
      }
      else {
        this.DeleteAllAssignmentProduct();
      }
    })

  }
  onCloseDeleteProductModel() {
    this.selectedProductId = null;
    this.SelectedEmpIds = '';
    $('#DeleteProductModel').modal('hide');
  }


  onChangeCreateEmpAcc(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.Ischecked = true;
    } else {
      this.Ischecked = false;
    }
  }

  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  DeleteAllAssignmentProduct() {

    this._MasterService.Delete_Employee_Assign_Product({ EmpIds: this.SelectedEmpIds, currentEmpId: this.EmployeeId, ProductId: this.selectedProductId }).subscribe((response) => {
      if (response[0].CODE == 1) {
        this.ProductsList.map((item) => {
          if (item.ProductId == this.selectedProductId) {
            item.isDisable = false;
            item.ProductAlradyAssigned = false;
            item.isActiveSelect = false;
            this.onCloseDeleteProductModel();
            this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }
  onNewProductAssign() {
    this.getnew_ProductList();
    this._MasterService.Get_Hierarchy_Upper_Lower({ LoginUserId: (this.EmployeeId), Type: 'Upper', ProductId: 0 }).subscribe((response: any) => {
      if (response.length > 0) {
        var empIds = JSON.parse(JSON.stringify(response));
        empIds.map((item) => {
          this.SelectedEmpIds = this.SelectedEmpIds + ',' + item.EmpId; 

        })
        this.displayedColumns = ["Emp_Name", "Designation"];
        this.dataSourceProductAssign = JSON.parse(JSON.stringify(response));

      }
    })
    this.SelectProduct = '';
    $('#AssignProductModel').modal('show');
    $("#AssignProductModel").css("z-index", "1050");
  }
  onCloseAssignProductModel() {
    this.getnew_ProductList();
    this.SelectedEmpIds = '';
    this.SelectProduct = '';
    $('#AssignProductModel').modal('hide');

  }
  ProductAssignment() {
    $('#AssignProductModel').modal('hide');
    this._MasterService.Save_Upper_Employee_ProductAssign({ EmpIds: this.SelectedEmpIds, currentEmpId: this.EmployeeId,  ProductId: this.SelectProduct, LoginUserId: this.data.userId }).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.SelectedEmpIds = '';
        this.SelectProduct = '';
        this.getEmployeeData();
        this.getnew_ProductList();
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });

      } else {
        this.SelectedEmpIds = '';
        this.SelectProduct = '';
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });

  }
  onChangeSelectAll() {
    for (let i = 0; i < this.ProductsList.length; i++) {
      if (!this.ProductsList[i].isDisable) {
        this.ProductsList[i].isActiveSelect = this.SelectAll;
      }
    
    }
  }
}
