<section class="main-content-wrapper">
  <div class="row col-md-12 m-0 mt-0">
    <div class="col-md-12 p-0">
      <mat-tab-group class="loan_tab" [selectedIndex]="currentTab" (selectedTabChange)="onTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label><span>Deposit</span></ng-template>
          <ng-template matTabContent>
            <app-deposit-add [DepositSummary]="12"></app-deposit-add>
          </ng-template>
        </mat-tab>

        <!-- <mat-tab [disabled]="TabDisabled">
          <ng-template mat-tab-label><span>Account Add</span></ng-template>
          <ng-template matTabContent>
            <app-product-account-details [ProductSummary]="12"></app-product-account-details>
          </ng-template>
        </mat-tab> -->
        
      </mat-tab-group>
    </div>
  </div>
</section>
  