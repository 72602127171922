import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-consumer-durable-manufacture",
  templateUrl: "./consumer-durable-manufacture.component.html",
  styleUrls: ["./consumer-durable-manufacture.component.scss"],
})
export class ConsumerDurableManufactureComponent implements OnInit {
  public get dialog(): DialogService {
    return this._dialog;
  }
  public set dialog(value: DialogService) {
    this._dialog = value;
  }
  showSpinner: boolean = false;
  currentUser: any;
  ButtonName: string = "Add";
  ShowFilter = true;

  manufactureId: any;
  SelectCategory: any = 0;

  SaveUpdateButton: string = "Save";
  manufactureName: any;

  categoryList: any[] = [];
  dataSourceManufacture: any;
  displayedColumn: string[] = [
    "Id",
    "Manufacture_Name",
    "Category_Names",
    "Action",
  ];
  dataSourceCat: MatTableDataSource<unknown>;
  dataSource: MatTableDataSource<unknown>;
  displayedcategoryColumns: string[] = ["Id", "Category_Name", "ParentIds"];
  allSelected: boolean = false;
  constructor(
    private _MasterService: MasterService,
    private dataSharingService: DataSharingService,
    private _dialog: DialogService,
    public snackBar: MatSnackBar
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Consumer Durable Manufacture");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Master_Consumer_Durable_Category();
    this.Get_Master_Consumer_Durable_Manufacture();
  }

  Get_Master_Consumer_Durable_Category() {
    this._MasterService
      .Get_Master_Consumer_Durable_Category()
      .subscribe((response) => {
        this.categoryList = JSON.parse(JSON.stringify(response));
        this.dataSourceCat = new MatTableDataSource(this.categoryList);
        console.log("Cateogry List", this.categoryList);
      });
  }

  Get_Master_Consumer_Durable_Manufacture() {
    this.showSpinner = true;
    this._MasterService
      .Get_Master_Consumer_Durable_Manufacture({
        Category_Id: this.SelectCategory,
      })
      .subscribe((response) => {
        console.log("Get_Master_Consumer_Durable_Manufacture", response);

        this.dataSourceManufacture = new MatTableDataSource(
          JSON.parse(JSON.stringify(response))
        );
      });
  }

  OnClose() {
    this.manufactureName = "";
    this.manufactureId = "0";
    $("#editConsumerDurableManufacturing").modal("hidden");
  }

  OnSearchDurableManufacture() {
    this.Get_Master_Consumer_Durable_Manufacture();
  }

  OnAddDurableManufacture() {
    this.SaveUpdateButton = "Save";
    this.ButtonName = "Add";
    this.categoryList.forEach((x) => {
      x.isActive = false;
      x.isDisabled = false;
    });
    this.dataSourceCat = new MatTableDataSource(this.categoryList);
    $("#editConsumerDurableManufacturing").modal("show");
    $("#editConsumerDurableManufacturing").css("z-index", "1050");
    this.manufactureName = "";
  }

  applyFilter(filterValue: string) {
    this.dataSourceManufacture.filter = filterValue.trim().toLowerCase();
  }

  OnSaveDurableManufacture() {
    console.log("categoryList", this.categoryList);
    this._MasterService
      .Save_Master_Consumer_Durable_Manufacture({
        Id: this.manufactureId,
        Category_Ids: this.convertToStringList(this.categoryList),
        Manufacture_Name: this.manufactureName,
        LoginUserId: this.currentUser.userId,
        SearchType: this.SaveUpdateButton == "Save" ? "Insert" : "Update",
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].CODE >= 0) {
            this.Get_Master_Consumer_Durable_Manufacture();
            console.log("SAVED", res);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.OnClose();
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  editManufactureName(row) {
    this.ButtonName = "Edit";
    this.SaveUpdateButton = "Update";
    this.manufactureName = row.Manufacture_Name;
    this.manufactureId = row.Id;
    var Idd =
      row.Category_Ids != "" && row.Category_Ids != null
        ? row.Category_Ids.split(",")
        : [];
    var disableCat =
      row.Category_Ids != "" && row.Category_Ids != null
        ? row.Disable_CategoryIds.split(",")
        : [];
    this.categoryList.forEach((x) => {
      x.isActive = false;
      x.isDisabled = false;
    });
    this.categoryList.forEach((x) => {
      if (
        Idd.find((obj: any) => {
          return Number(obj) == x.Id;
        })
      ) {
        x.isActive = true;
      }
      if (
        disableCat.find((obj: any) => {
          return Number(obj) == x.Id;
        })
      ) {
        x.isDisabled = true;
      }
    });
    this.dataSourceCat = new MatTableDataSource(this.categoryList);
    $("#editConsumerDurableManufacturing").modal("show");
    $("#editConsumerDurableManufacturing").css("z-index", "1050");
  }

  deleteManufactureName(row) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to delete?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._MasterService
            .Save_Master_Consumer_Durable_Manufacture({
              Id: row.Id,
              Category_Ids: row.Id.toString(),
              Manufacture_Name: row.manufactureName,
              LoginUserId: this.currentUser.userId,
              SearchType: "Delete",
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              console.log("DELETD", res);
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.Get_Master_Consumer_Durable_Manufacture();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  deSelectAllCheckBox(isChecked: boolean) {
    if (isChecked) {
      this.allSelected = false;
    }
  }
  selectAll() {
    this.categoryList.forEach((x) => (x.isActive = true));
  }
  deSelectAll() {
    this.categoryList.forEach((x) => {
      if (x.isDisabled == false) {
        x.isActive = false;
      }
    });
  }
  convertToStringList(categoryList: any[]) {
    var array: any = [];
    categoryList.forEach((x) => {
      if (x.isActive) array.push(x.Id);
    });
    return array.toString();
  }

  //convert comma seperated to list of value
  convertToArray(parentId: string) {
    var array: any[] = [];
    parentId.split(",").forEach((x) => {
      array.push(x);
    });
    return array;
  }
}
