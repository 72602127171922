import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestModel } from '../../Shared/Models/app.MasterRequestModel';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { DialogService } from '../../Shared/dialog.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { LmsService } from '../../_Lms/services/lms.service';
@Component({
  selector: 'app-product-work-flow',
  templateUrl: './product-work-flow.component.html',
  styleUrls: ['./product-work-flow.component.scss']
})
export class ProductWorkFlowComponent implements OnInit {
  private data: any; 
  private _MasterService; 
  showSpinner: boolean = false;
  FilterProuctId: any = ''; 
  ProductListDropdown: any = [];

  dataSource: any; loading: boolean = false;
  displayedColumns: string[] = ['ProcessId', 'GroupName', 'Process', 'Squance', 'IsMandatory', 'Section', 'EditAction'];
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  ProductList: any = [];
  WorkflowCreatedOn: string; 
  WorkflowCreatedBy: string; 
  WorkflowModifiedOn: string = ""; 
  WorkflowModifiedBy: string;

  RequestModel: RequestModel = new RequestModel();
  DeletePoductRequestModel: RequestModel = new RequestModel();
  SaveProductWorkflowRequestModel: RequestModel = new RequestModel();

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  SelectProductCategory: any = ''; 
  ProductName: string = "";
  ProductShortName: string = '';
  ProductCategoryDropdown: any = [];
  

  constructor(private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next('Product Work Flow');
    //this._MasterService.Get_ALLProductDropdown().subscribe((result) => {
    //  this.ProductListDropdown = JSON.parse(JSON.stringify(result));
    //  this.FilterProuctId = this.ProductListDropdown.length > 0 ? this.ProductListDropdown[0].ProductId : '';
    //  this.getProductList();
    //});
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.FilterProuctId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("Id")));

      if (Number(this.FilterProuctId) > 0) {
        this.getProductList();
        this.getProductDetail();
      }
    });
    this.getProductCategory();
  }

  getProductList() {
    if (this.FilterProuctId == '') {
      this.dataSource = [];
      return;
    }
    this.showSpinner = true;
    this.RequestModel.ProductId = parseInt(this.FilterProuctId);
    this._MasterService.GetProductWorkflow(this.RequestModel).subscribe((result) => {
      var obj = JSON.parse(JSON.stringify(result)).Item1[0];
      this.WorkflowCreatedOn = "";
      this.WorkflowCreatedBy = "";
      this.WorkflowModifiedOn = "";
      this.WorkflowModifiedBy = "";
      if (obj) {
        this.WorkflowCreatedOn = obj.Workflow_CreateOn;
        this.WorkflowCreatedBy = obj.Workflow_CreateBy;
        this.WorkflowModifiedOn = obj.Workflow_ModifyOn;
        this.WorkflowModifiedBy = obj.Workflow_ModifyBy;
      }

      this.ProductList = JSON.parse(JSON.stringify(result.Item2));
      this.ProductList.map((item) => {
        item.isChecked = true;
        if (item.Section == 1 || item.Section == 3) {
          item.isDisable = true;
        } else if (item.Section == 4) {
          item.isDisable = false;
          item.isChecked = false;
        }
        else {
          item.isDisable = false;
        }
        if (item.Section == 3) {
          item.isShowDelete = true;
        } else {
          item.isShowDelete = false;
        }

      });

      this.dataSource = new MatTableDataSource(this.ProductList);
      //  this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }
  onChangeSelection(event: MatCheckboxChange, row) {
    this.ProductList.map((item) => {
      if (item.ProcessId == row.ProcessId || (item.GroupName == row.GroupName && item.Section != 1)) {
        item.isChecked = event.checked;
      }
    });
    this.dataSource = new MatTableDataSource(this.ProductList);
  }

  onDeleteProcess(row) {
    this.DeletePoductRequestModel.ProductId = row.ProcessId;
    this._MasterService.DeleteProduct(this.DeletePoductRequestModel).subscribe((response) => {
      if (response[0].CODE == 0) {
        this.ProductList.map((item) => {
          if (item.ProcessId == row.ProcessId || (item.GroupName == row.GroupName && item.Section != 1)) {
            item.isDisable = false;
            item.isChecked = false;
            item.isShowDelete = false;
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
      }
    });
  }

  OnSaveProductSection() {
    if (this.FilterProuctId != '') {
      let workFlowData = [];
      this.ProductList.map((item) => {
        workFlowData.push({
          "ProductId": this.FilterProuctId,
          "ProcessId": item.ProcessId,
          "IsActive": item.isChecked == true ? 1 : 0,
          "Createby": this.data.userId
        });
      });

      this.SaveProductWorkflowRequestModel.JSON = JSON.stringify({ "Workflow": workFlowData });
      this._MasterService.SaveProductWorkflow(this.SaveProductWorkflowRequestModel).subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
          this.getProductList();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: response[0].MSG, ...this.configSuccess });
        }
      });
    }
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }
  getProductDetail() {
    this._MasterService.GetProductListById({ ProductId: this.FilterProuctId }).subscribe((result) => {
      if (result.length > 0) {
        this.SelectProductCategory = result[0].ProductCatId;
        this.ProductName = result[0].Product;
        this.ProductShortName = result[0].ShortName;
      }
    });
  }
}
